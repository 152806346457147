import React, { useEffect, useState } from 'react';
import { DownloadIcon } from "@heroicons/react/outline";
import { LibraryTypeEnum, LIBRARY_ICONS } from 'components/Library/LibraryContants';
import { dowloadFile, downloadURI } from 'utils/commonFun';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { useTranslation } from 'react-i18next';

import './FilePreview.scss';
import { IframePdfViewer } from '_shared/PreviewContent/IframePDFViewer';
import { hasCheckedStream } from 'constant/file.constant';
import resourcesService from 'services/resources.service';
import VideoPlayer from '_shared/components/VideoPlayer/VideoPlayer';
let TIMER_DOWNLOAD;

export const FilePreview = ({ data, isPrivate = true }) => {
    const [downloading, setDownloading] = useState(false);
    const { t: translator } = useTranslation();
    const download = () => {
        if (downloading) {
            return;
        }
        setDownloading(true);
        if (hasCheckedStream(data?.file)) {
            TIMER_DOWNLOAD = setInterval(async () => {
                if (!TIMER_DOWNLOAD) {
                    return;
                }
                const res = await resourcesService.getDownloadUrl(data?.id, data?.object_id);
                if (res?.url) {
                    downloadURI(res?.url, data?.name);
                    setDownloading(false);
                    clearInterval(TIMER_DOWNLOAD);
                }
            }, 1000);

        } else {
            dowloadFile(data?.file, data?.name, () => {
                setDownloading(false);
            });
        }

    }
    useEffect(() => {
        return () => {
            clearInterval(TIMER_DOWNLOAD);
        }
    }, [])
    return (
        <div className='filePreview'>
            <div className='filePreviewHeader'>
                <div className='filePreviewHeader-icon'>
                    {LibraryTypeEnum.image == data?.object_type ?
                        <img src={LIBRARY_ICONS(translator)?.find((item) => item?.value === data?.sub_object_type?.split('.')[1])?.icon} className="w-auto h-5" alt='Icon' /> :
                        <img src={LIBRARY_ICONS(translator)?.find((item) => item?.value === data?.object_type)?.icon} className="w-auto h-5" alt='Icon' />
                    }
                </div>
                <div className='filePreviewHeader-name'>
                    {data?.name}
                </div>
                {isPrivate && <button className='filePreviewHeader-actions' onClick={download}>
                    {downloading ? <CircleSpin /> : <DownloadIcon className='filePreviewHeader-download' />}
                </button>}
            </div>
            <div className="filePreview-container">
                {
                    data?.id ?
                        <>
                            {LibraryTypeEnum.image == data?.object_type && <img src={data?.file} alt={data?.name} />}
                            {LibraryTypeEnum.video == data?.object_type && <>
                                {hasCheckedStream(data?.file) ? <VideoPlayer data={data} isPrivate={isPrivate} /> : <video controls muted>
                                    <source src={data?.file} type='video/mp4'></source>
                                </video>}
                            </>}
                            {(LibraryTypeEnum.document == data?.object_type ||
                                LibraryTypeEnum.presentation == data?.object_type ||
                                LibraryTypeEnum.spreadsheet == data?.object_type) &&
                                <iframe className="document" src={`https://view.officeapps.live.com/op/embed.aspx?src=${data?.file}`} title="View document" />
                            }
                            {
                                LibraryTypeEnum.audio == data?.object_type &&
                                <audio controls src={data?.file} className="w-full"></audio>

                            }
                            {
                                LibraryTypeEnum.pdf == data?.object_type &&
                                <IframePdfViewer url={data?.file} />

                            }
                        </> :
                        <div className="filePreview-nocontent">
                            {translator("LIBRARY.COULDN'T_PREVIEW_FILE")}
                        </div>
                }
            </div>
        </div>
    )
}