export interface RewardsType {
    items: RewardsItemsType[];
    page: number;
    per_page: number;
    total: number;
    sort_by: string;
    order: string;
}

export interface RewardsItemsType {
    id: number;
    name: string;
    thumbnail: string;
    is_featured: boolean;
    description: string;
    start_date: string;
    end_date: string;
    total_quantity: number;
    available_quantity: number;
    price: number;
    status: string;
    reward_level: RewardLevelType;
    student?: any;
    redeemed_user?: any;
    transaction_type?: string;
    refund_reason?: string;
    created_at?: string;
    updated_at?: string;
}
export interface RewardLevelType {
    id: number;
    name: string;
    point_required: number;
    color: string;
}

export interface RewardsState {
    listRewards?: RewardsType;
    listRewardsHistory?: RewardsType;
    listRewardsFutured?: RewardsType;
    loading: boolean;
    isLoading?: boolean;
}

export interface RewardsAction extends RewardsState {
    type: string;
}

export const initRewardsState: RewardsState = {
    loading: false,
};

export interface LevelRewardType {
    color: string;
    id: number;
    name: string;
    point_required: number;
}

export interface DetailsRewardsType {
    id?: number;
    reward_level?: LevelRewardType;
    name?: string;
    thumbnail?: string;
    is_featured?: boolean;
    description?: string;
    start_date?: any;
    end_date?: any;
    total_quantity?: number;
    available_quantity?: number;
    price?: number;
    status?: string;
    created_at?: string;
    updated_at?: string;
    reward_level_id?: string | number;
}
