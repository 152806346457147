import { AuthContext } from 'contexts/Auth/AuthContext';
import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const FaviconChanger: React.FC = () => {
    const authCtx = React.useContext(AuthContext);
    const addFavicon = (faviconLink?: string) => {
        const link: HTMLLinkElement = document.createElement('link');
        const oldLink = document.getElementById('dynamic-favicon');
        link.id = 'dynamic-favicon';
        link.rel = 'icon';
        link.href = faviconLink;
        if (oldLink) {
            document.head.removeChild(oldLink);
        }
        document.head.appendChild(link);
    }

    useEffect(() => {
        if (authCtx?.config) {
            const whitelabel = authCtx?.config?.find(item => item?.page_name == "login");
            addFavicon(whitelabel?.data?.web?.ico ?? '/favicon.ico');
        }
    }, [authCtx]);

    return (
        <HelmetProvider>
            <Helmet>
                <link id="dynamic-favicon" rel="icon" href="" />
            </Helmet>
        </HelmetProvider>
    );
};

export default FaviconChanger;
