import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import { debounce } from 'lodash';
import './FilterUserHistory.scss';

interface IFilter {
    onChangeFilter: (value: any) => void;
    isFirstLoad: boolean;
    location: string;
    setLocation: any
}

const FilterUserHistory = ({ onChangeFilter, isFirstLoad, location, setLocation }: IFilter) => {
    const { t } = useTranslation();
    const [keyword, setKeyword] = useDebouncedState('', 0);
    const [roleSelected, setRoleSelected] = useState([]);
    const [statusSelected, setStatusSelected] = useState([]);
    const [statusList, setStatusList] = useState([
        {name: 'In Progress', value: 'in_progress'},
        {name: 'Success', value: 'success'},
        {name: 'Failed', value: 'failed'},
    ]);
    
    const updateFilter = useCallback(
        debounce((listFilter) => onChangeFilter(listFilter), 500),
        []
    );

    useEffect(() => {
        setRoleSelected([])
    }, [location]);

    useEffect(() => {
        if (!isFirstLoad) {
            let listFilter: any = {
                location: roleSelected?.map((role) => role?.value),
                q: keyword?.trim(),
                state: statusSelected?.map((role) => role?.value),
            };
            listFilter.location = roleSelected?.length > 0 ? roleSelected?.map((role) => role?.value) : null
            if (!listFilter?.state) delete listFilter.state;
            if (!listFilter?.location) delete listFilter.location;
            updateFilter(listFilter);
        }
    }, [roleSelected, keyword, statusSelected, location]);

    return (
        <>
            <div className="filterBar">
                <SearchBox className="filterBar-searchInput" onSubmit={(value) => setKeyword(value)} />
                <SelectBox
                    label={'Type'}
                    onSelect={setRoleSelected}
                    data={[
                        {name: t("USER.LEARNER_PORTAL"), value: 'learner_portal'},
                        {name: 'CLMS', value: 'clms'}
                    ]}
                    selectedItems={roleSelected}
                    total={2}
                />
                
                <SelectBox
                    label={t('USER.STATUS')}
                    onSelect={setStatusSelected}
                    selectedItems={statusSelected}
                    data={statusList}
                    total={3}
                />

                <div className="line"></div>

                <button
                    onClick={() => {
                        setRoleSelected([]);
                        onChangeFilter({});
                        setStatusSelected([]);
                        setLocation('clms')
                    }}
                    className="btn-reset"
                    disabled={!roleSelected?.length && !statusSelected?.length}
                >
                    {t('RESET')}
                </button>
            </div>
        </>
    );
};

export default FilterUserHistory;
