import React from 'react';
import ManagementMasterPage from 'pages/RouteMasterPage';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';
import UserList from 'components/Management/User/UserList/UserList';

const UsertListPage = () => {
    return (
        <ManagePermissionWrapper>
            <UserList>
                <ManagementMasterPage />
            </UserList>
        </ManagePermissionWrapper>
    );
};

export default UsertListPage;
