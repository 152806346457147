export const USER_STATUS = {
  INVITE: "invite",
  ACTIVE: "active",
  DEACTIVATE: "deactivate",
  REGISTERED: "registered"
}

export const USER_ROLE = {
  instructor: "Instructor",
  teaching_assistant: "Teaching Assistant",
  viewer: "Viewer",
  editor: "Editor",
  admin: "Admin",
  super_admin: "Super Admin",
}

export enum LOCATION {
  clms = "clms",
  learner_portal= "learner_portal"
}

export const LEARNER_PORTAL = {
  learner: "Learner",
  parent: "Parent"
}

export const USER_STATUS_LABEL = {
  invite: "INVITED",
  active: "ACTIVE",
  deactivate: "DEACTIVATE",
  registered: "REGISTER"
}

export const USER_STATUS_LIST = (translator) =>  [
  {
    name: translator('USER.STATUSES.INVITED'), value: USER_STATUS.INVITE
  },
  {
    name: translator('USER.STATUSES.ACTIVE'), value: USER_STATUS.ACTIVE
  },
  {
    name: translator('USER.STATUSES.DEACTIVATE'), value: USER_STATUS.DEACTIVATE
  },
  {
    name: translator('USER.STATUSES.REGISTERED'), value: USER_STATUS.REGISTERED
  }
]

export const optionsGender = (translator) => [
  { value: 'male', label: translator("USER.GENDERS.MALE") },
  { value: 'female', label: translator("USER.GENDERS.FEMALE") },
];