import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { useTranslation } from 'react-i18next';
import { NavbarTop } from '../components/NavbarTop/NavbarTop';
import { OverviewWorkspaceTab } from './OverviewWorkspaceTab';
import { AcademicsTab } from './AcademicsTab';
import { SessionsTab } from './SessionsTab';

export interface WorkspaceDashboardProps {}

const WorkspaceDashboard: React.FC<WorkspaceDashboardProps> = () => {
    const { t } = useTranslation();
    const params: { id: string } = useParams();
    const searchParams = useLocation();
    const SUB_MENU = [
        {
            name: t('REPORTS.OVERVIEW'),
            url: `/workspace/${params.id}/report/dashboard-workspace`,
            tabName: 'overview'
        },
        {
            name: t("REPORTS.Academics"),
            url: `/workspace/${params.id}/report/dashboard-workspace?tab=academics`,
            tabName: 'academics'
        },
        {
            name: t("REPORTS.Scheduling"),
            url: `/workspace/${params.id}/report/dashboard-workspace?tab=scheduling`,
            tabName: 'scheduling'
        }
    ];
    const [tabActive, setTabActive] = useState<string>('');

    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);

    useEffect(() => {
        if (searchParams?.search) {
            setTabActive(searchParams?.search?.split('=')[1])
        } else {
            setTabActive('overview');
        }
    }, [searchParams]);

    return (
        <div className="w-full">
            <PageBreadcrumb
                pageNameDefault={translator('ANALYTICS')}
                pages={[{ name: translator('WORKSPACE_DASHBOARD'), href: '', className: '' }]}
                containerClassName=" z-10 sticky top-0 w-full"
            />
            <div>
                <NavbarTop data={SUB_MENU} actived={tabActive} />
                <div className='p-5 bg-gray-50 min-h-[calc(100vh-107px)]'>
                    {
                        tabActive === 'overview' && <OverviewWorkspaceTab />
                    }
                    {
                        tabActive === 'academics' && <AcademicsTab />
                    }
                    {
                        tabActive === 'scheduling' && <SessionsTab />
                    }
                </div>
            </div>
        </div>
    );
};

export default WorkspaceDashboard;
