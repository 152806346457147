import { SALESFORCE } from 'constant/api.const';
import axios from './restful.service';

const getListSalesforce = (workspaceId: string, params?: Record<any, any>) => {
    if (workspaceId) {
        const url = SALESFORCE.getListSalesforce(workspaceId);
        return axios.get(url, { params });
    }
};

const salesforceSync = (workspaceId: string, params?: Record<any, any>) => {
    if (workspaceId) {
        const url = SALESFORCE.salesforceSync(workspaceId);
        return axios.get(url, { params });
    }
};

export default { getListSalesforce, salesforceSync };
