import React, { FC, useState, useContext, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import './stylesCalendar.scss';
import DialogComponent from 'components/Dialog/DialogComponent';
import { useBoolean } from 'hooks/custom';
import DashboardService from 'services/teach.services';
import { UserContext } from 'contexts/User/UserContext';
import dayjs from 'dayjs';
import { getLocalTimeZone, timeConvert } from 'utils/commonFun';
import { PlusIcon, SearchIcon, TrashIcon } from '@heroicons/react/outline';
import Select, { StylesConfig, components } from 'react-select';
import classService from 'services/class.service';
import { debounce } from 'lodash';
import { ClockIcon } from 'assets/icon';
import { SettingsIcon } from 'assets/icon/SettingIcon';
import ModalCheckinAvailableTime from 'components/Dashboard/InstructorAvailability/ModalCheckinAvailableTime';
import ButtonComponent from 'components/Button/ButtonComponent';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { Toastify } from '_shared';

const customFilter = (option) => {
    return option.label;
};

const Control = ({ children, ...props }: any) => {
    const style = { cursor: 'pointer' };
    return (
        <components.Control {...props}>
            <span style={style}>
                <SearchIcon className="w-5 h-5 pl-1 text-dark-300" />
            </span>
            {children}
        </components.Control>
    );
};

const customSelectStyle: StylesConfig<any, true> = {
    clearIndicator: (base) => ({ ...base, display: 'none' }),
    indicatorSeparator: (base) => ({ ...base, display: 'none' }),
    dropdownIndicator: (base, { selectProps }) => ({
        ...base,
        padding: 6,
        transition: 'all 0.3s ease-in-out',
        transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    }),
    control: (styles) => ({
        ...styles,
        borderColor: '#EBEBF0',
        minHeight: '32px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        borderRadius: 4,
        paddingLeft: 12,
        fontSize: 14,
    }),
    menuList: (styles) => ({
        ...styles,
        fontSize: 14,
    }),
};

const dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';
const dateFormatString = 'YYYY-MM-DDTHH:mm:ss';

const index: FC = ({ children }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const params: any = useParams();
    const calendarRef = useRef(null);

    const { booleanValue: modalCheckinAvailable, toggleBooleanValue: toggleModalCheckinAvailable } = useBoolean();
    const { booleanValue: modalDetailClass, toggleBooleanValue: toggleModalDetailClass } = useBoolean();
    const { booleanValue: modalConfirmRemove, toggleBooleanValue: toggleModalConfirmRemove } = useBoolean();
    const [listTeachers, setListTeachers] = useState([]);
    const [inputSearchTeachers, setInputSearchTeachers] = useState<string>(null);
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [loadingTeachers, setLoadingTeachers] = React.useState<boolean>(false);
    const [paginationTeachers, setPaginationTeachers] = useState({
        page: 1,
        per_page: 20,
        total: 0,
    });

    const [loadFirstTime, setLoadFirstTime] = useState<boolean>(false);

    const [tzOffset, setTzOffset] = React.useState(dayjs().tz('UTC').format(dateFormat).slice(-6));
    const [calendarChange, setCalendarChange] = useState<{
        view: string;
        start: string;
        end: string;
    }>(null);
    const [selectedDetail, setSelectedDetail] = useState<any>({});
    const [availableEvents, setAvailableEvents] = useState<any>([]);
    const debounceInput = useCallback(
        debounce((nextValue: string, asyncFunction: (p: string) => void) => {
            if (nextValue?.length) {
                asyncFunction(nextValue);
            }
        }, 1000),
        []
    );
    const {
        userState: { result },
    } = useContext(UserContext);

    const workspaceId = params.id;

    const translator = (str: string, newKey?: boolean, opt?: any) =>
        newKey ? t(str, opt) : t(`DASHBOARD.AVAILABILITY_DASHBOARD.${str}`, opt);
    const fetchEvents = (_tzOffset?: string) => {
        if (!calendarChange?.start || (!calendarChange.end && !selectedTeacher)) return;
        const paramsRequest = {
            includes_past: true,
            start_time: dayjs(calendarChange.start).format('YYYY-MM-DDT00:00:00') + (_tzOffset || tzOffset),
            end_time: dayjs(calendarChange.end).format('YYYY-MM-DDT00:00:00') + (_tzOffset || tzOffset),
        };
        DashboardService.getTeacherEvents(workspaceId, selectedTeacher?.value, paramsRequest)
            .then((res) => {
                const arr2DData = res?.data?.map((d) => d?.calendar_time_ranges);
                const arrData = arr2DData?.flat() || [];
                setAvailableEvents(
                    arrData?.map((el) => ({
                        ...el,
                        title: 'Event',
                        start: el?.start_time,
                        end:
                            dayjs(el?.start_time).add(el.duration, 'minutes').tz(result?.time_zone).format(dateFormatString) +
                            (_tzOffset || tzOffset),
                        calendar_event_id: el.calendar_event_id,
                        duration: el.duration,
                        extendedProps: {
                            ...el,
                            endDate: dayjs(el?.start_time).add(el.duration, 'minutes').format(dateFormatString),
                            objParent: res?.data?.find((item) => item?.id === el?.calendar_event_id),
                        },
                    }))
                );
            })
            .catch()
            .finally();
    };

    useEffect(() => {
        if (result?.time_zone) {
            const _tzOffset = dayjs().tz(result?.time_zone).format(dateFormat).slice(-6);
            setTzOffset(_tzOffset);
            fetchEvents(_tzOffset);
        }
    }, [result?.time_zone]);

    useEffect(() => {
        fetchEvents();
    }, [calendarChange]);

    const onRemoveEvent = (details) => {
        if (details) {
            DashboardService.removeEventTeacher(workspaceId, selectedTeacher?.value, details.calendar_event_id, {
                schedule_time_range_id: details?.schedule_time_range_id,
                start_time: details?.start_time,
            })
                .then((res) => {
                    if (res?.data) {
                        Toastify.success();
                        setTimeout(() => {
                            fetchEvents();
                        }, 500);
                    }
                })
                .catch((err) => Toastify.error())
                .finally(() => toggleModalConfirmRemove());
        }
    };

    const getListTeachers = (page?: number, e?: string) => {
        setLoadingTeachers(true);
        const paramsRequest = {
            page: page || paginationTeachers.page,
            per_page: paginationTeachers.per_page,
            q: e || inputSearchTeachers,
        };
        classService
            .getTeacherListService(params?.id, paramsRequest)
            .then((res) => {
                const formatedListTeachers = res?.data?.items.map((instructor) => ({
                    label: instructor?.user?.display_name,
                    value: instructor?.user?.id,
                }));
                if (page === 1) {
                    setListTeachers(formatedListTeachers);
                } else {
                    setListTeachers([...listTeachers, ...formatedListTeachers]);
                }
                setPaginationTeachers({
                    page: res?.data?.page,
                    per_page: res?.data?.per_page,
                    total: res?.data?.total,
                });
            })
            .finally(() => setLoadingTeachers(false));
    };

    const renderEventContent = (eventInfo) => {
        if (eventInfo?.view?.type === 'timeGridWeek') {
            const value = eventInfo?.event._def;
            return (
                <>
                    <div className="fc-event-main-frame fc-sticky">
                        <div className="fc-event-title-container">
                            <div className="fc-event-title text-[10px] font-semibold">
                                {value?.extendedProps?.objParent?.display_name}
                            </div>
                        </div>
                        <div className="text-dark-300">
                            {dayjs(value?.extendedProps?.start_time).tz(result?.time_zone).format('MMM DD')}
                        </div>
                        <div className="fc-event-time flex items-center justify-start space-x-2  pb-2">
                            {dayjs(value?.extendedProps?.start_time).tz(result?.time_zone).format('HH:mm')}
                            <span className="mx-1"> - </span>
                            {dayjs(value?.extendedProps?.endDate).tz(result?.time_zone).format('HH:mm')}
                            <span className="fc-event-lock absolute right-0">
                                <ClockIcon />
                            </span>
                        </div>
                    </div>
                </>
            );
        }
    };

    const loadMoreTeachers = () => {
        if (paginationTeachers.total > paginationTeachers.page * paginationTeachers.per_page) {
            getListTeachers(paginationTeachers.page + 1);
        }
    };

    useEffect(() => {
        const fetchFirstTime = async () => {
            setLoadingTeachers(true);
            const paramsRequest = {
                page: 1,
                per_page: 20,
            };
            await classService
                .getTeacherListService(params?.id, paramsRequest)
                .then((res) => {
                    const formatedListTeachers = res?.data?.items.map((instructor) => ({
                        label: instructor?.user?.display_name,
                        value: instructor?.user?.id,
                    }));
                    setPaginationTeachers({
                        page: res?.data?.page,
                        per_page: res?.data?.per_page,
                        total: res?.data?.total,
                    });
                    if (formatedListTeachers?.length) {
                        setListTeachers(formatedListTeachers);
                        setSelectedTeacher(formatedListTeachers[0]);
                    }
                })
                .finally(() => setLoadingTeachers(false));
        };
        fetchFirstTime();
        setLoadFirstTime(true);
    }, []);

    useEffect(() => {
        if (listTeachers?.length && selectedTeacher && loadFirstTime) {
            fetchEvents();
        }
    }, [selectedTeacher]);


    return (
        <div className="text-dark-300 text-xs font-normal">
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT', true)}
                containerClassName=" z-10 sticky top-0" 
                pages={[
                    { name: translator('DASHBOARD.SIDEBAR.AVAILABILITY', true), href: ``, className: '' }, 
                ]}           
            />
            <div className="relative mt-8">
                <div className="absolute w-[50px] text-center top-[115px] left-[25px] z-[1] text-xs">
                    <button
                        type="button"
                        className="text-primary-500 hover:opacity-80 block text-center m-auto"
                        onClick={() => {
                            history.push(`/workspace/${workspaceId}/user-setting`);
                        }}
                    >
                        <SettingsIcon />
                    </button>
                    <span className="text-[10px]">UTC{dayjs(new Date()).tz(result?.time_zone).format('Z')}</span>
                </div>
                <div className="px-5 pb-5 grid grid-cols-2 gap-6">
                    <div className="col-span-2">
                        <div className="flex items-center">
                            <div className="w-3/5 flex items-center">
                                <div className="flex items-center justify-between mt-[0.5] relative w-[320px] mr-8">
                                    <Select
                                        className=" text-dark-300 font-normal  rounded-20 z-60 w-full"
                                        styles={customSelectStyle}
                                        onInputChange={(inputValue) => {
                                            debounceInput(inputValue, () => getListTeachers(1, inputValue));
                                            setInputSearchTeachers(inputValue);
                                        }}
                                        value={selectedTeacher}
                                        options={listTeachers}
                                        components={{ Control }}
                                        menuPlacement="auto"
                                        isLoading={loadingTeachers}
                                        placeholder={translator('SEARCH_INSTRUCTOR')}
                                        maxMenuHeight={200}
                                        onChange={(val) => setSelectedTeacher(val)}
                                        filterOption={customFilter}
                                        onMenuScrollToBottom={loadMoreTeachers}
                                        onMenuClose={() => {
                                            setInputSearchTeachers(null);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center justify-end w-2/5">
                                <ButtonComponent
                                    title={translator('ADD_AVAILABILITY')}
                                    classStyle="xs:p-2 xs:text-sm primary-button"
                                    icon={<PlusIcon className="w-4 h-4 mr-1" />}                                    
                                    onClickButton={() => {
                                        toggleModalCheckinAvailable();
                                    }}
                                    disable={loadingTeachers}
                                />
                            </div>
                        </div>
                        <div className="mt-6 calendar-wrapper-available">
                            <FullCalendar
                                ref={calendarRef}
                                initialView="timeGridWeek"
                                allDaySlot={false}
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                headerToolbar={{
                                    left: 'today prev,next title',
                                    right: '',
                                }}
                                events={availableEvents}
                                eventContent={renderEventContent}
                                eventMaxStack={2}
                                eventClick={(e) => {
                                    setSelectedDetail(e?.event?._def?.extendedProps);
                                    toggleModalDetailClass();
                                }}
                                slotLabelFormat={{
                                    hour: '2-digit',
                                    minute: '2-digit',
                                }}
                                dayMaxEventRows={3}
                                slotEventOverlap={false}
                                slotDuration={'00:15:00'}
                                slotLabelInterval={15}
                                datesSet={(arg) => {
                                    const { startStr, endStr, view } = arg;
                                    setCalendarChange({
                                        start: startStr,
                                        end: endStr,
                                        view: view.type,
                                    });
                                }}
                                timeZone={result?.time_zone || getLocalTimeZone()}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <DialogComponent
                isOpen={modalCheckinAvailable}
                title={translator('ADD_AVAILABLE')}
                onCloseModal={toggleModalCheckinAvailable}
                styles="max-w-xl"
                stylesTitle={'text-base text-gray-900 font-semibold'}
                maskClosable={false}
                child={
                    <ModalCheckinAvailableTime
                        workspaceId={workspaceId}
                        translator={translator}
                        data={undefined}
                        fetchData={() => {
                            toggleModalCheckinAvailable();
                            setTimeout(() => {
                                fetchEvents();
                            }, 500);
                        }}
                        isAdmin={true}
                        TeacherId={selectedTeacher?.value}
                    />
                }
            />
            <DialogComponent
                isOpen={modalDetailClass}
                onCloseModal={toggleModalDetailClass}
                maskClosable={true}
                hasNonTitle={true}
                child={
                    <div className="text-[#6B7280] text-sm px-4 mb-2 -mt-2">
                        <div className="flex items-center">
                            <div className="text-[#6B7280] text-sm flex-none w-[80px] !font-normal">{translator('DATE')}: </div>
                            <div className="w-3/4 text-sm text-[#1F2937]">
                                {dayjs(selectedDetail?.start_time).tz(result?.time_zone).format('dddd, MMMM D hh:mma')} -{' '}
                                {dayjs(selectedDetail?.endDate).tz(result?.time_zone).format('hh:mma')}{' '}
                            </div>
                        </div>
                        <div className="flex items-center mt-0.5">
                            <div className="text-[#6B7280] text-sm flex-none w-[80px] !font-normal">{translator('DURATION')}: </div>
                            <div className="w-3/4 text-sm text-[#1F2937]">
                                <span className="">{timeConvert(selectedDetail?.duration)}</span>
                            </div>
                        </div>
                        {
                            <div className="flex items-center mt-0.5">
                                <div className="text-[#6B7280] text-sm flex-none w-[80px] !font-normal">
                                    {' '}
                                    {translator('RECURRING')}:{' '}
                                </div>
                                <div className="w-3/4 text-sm text-[#1F2937]">
                                    {selectedDetail?.recurring_start_at ? (
                                        <>
                                            {translator('WEEKLY_ON')}
                                            <span>
                                                {dayjs(selectedDetail?.recurring_start_at)?.tz(result?.time_zone)?.format('dddd') ||
                                                    ''}
                                            </span>{' '}
                                            {translator('UNTIL')}{' '}
                                            <span>
                                                {dayjs(selectedDetail?.recurring_end_at)
                                                    ?.tz(result?.time_zone)
                                                    ?.format('YYYY/MM/DD') || ''}
                                            </span>
                                        </>
                                    ) : (
                                        'No'
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                }
                width={'min-w-[45vw] 2xl:min-w-[338px] !p-0'}
                subTitle={
                    <div className="flex justify-between items-center w-full border-b px-4 py-2">
                        <div className="text-[#111827 text-base font-semibold]">
                            {selectedDetail?.objParent?.display_name}
                        </div>
                        <button
                            type="button"
                            onClick={() => {
                                toggleModalConfirmRemove();
                                toggleModalDetailClass();
                            }}
                        >
                            <TrashIcon className="w-5 h-5 text-[#EF4444]" />
                        </button>
                    </div>
                }
                stylesTitle="w-full"
                isShowClose={false}
            />
            <DialogComponent
                isOpen={modalConfirmRemove}
                onCloseModal={toggleModalConfirmRemove}
                title={translator('CONFIRMATION')}
                maskClosable={true}
                child={
                    <div className="text-center">
                        <div className="text-dark-300 text-sm">{translator('REMOVE_AVAILABLE')}</div>
                        <div className="mt-8">
                            <button type="button" className="text-dark-100 text-sm" onClick={() => toggleModalConfirmRemove()}>
                                {translator('CANCEL')}
                            </button>
                            <button
                                type="button"
                                className="ml-5 text-white rounded px-2 py-1 bg-[#E7443C] text-sm"
                                onClick={() => onRemoveEvent(selectedDetail)}
                            >
                                {translator('REMOVE')}
                            </button>
                        </div>
                    </div>
                }
                width={'min-w-[35vw] 2xl:min-w-[30vw]'}
            />
        </div>
    );
};

export default index;
