import React from "react";
import { StudentOverviewCoin } from "./components/StudentOverview/StudentOverviewCoin";
import { StudentSpecificOverview } from "./components/StudentOverview/StudentSpecificOverview";

export const StudentDashboardOveriew = () => {
    return<>
        <StudentSpecificOverview />
        <div className="mt-4">
            <StudentOverviewCoin />
        </div>
    </>
}