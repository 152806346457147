import React, { useEffect } from 'react';
import { CloseIcon } from 'assets/icon/CloseIcon';
import './TagList.scss';
import { useTranslation } from 'react-i18next';

interface TagFieldsProps {
    title: string;
    data: any;
    id: string;
    customFields: any;
    setCustomFields: Function;
    isEdit?: boolean;
}

const MAX_LENGTH = 255;

export const TagItem: React.FC<TagFieldsProps> = ({ title, data, id, customFields, setCustomFields, isEdit = true }: TagFieldsProps) => {
    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState<boolean>(false);

    useEffect(() => {
        setValue('');
        setError(false);
    }, [isEdit]);

    const handleAddTagField = (e: any) => {
        if (e.key === 'Enter') {
            const valueKeyDown = e.target.value.trim();
            const temp = [...customFields];
            const currentCustomfield = temp.find((item) => item.id === id);

            if (currentCustomfield?.data === null || typeof currentCustomfield?.data == 'undefined') {
                currentCustomfield.data = [];
            }

            const isDisableEnter = currentCustomfield?.data?.includes(valueKeyDown);
            if (isDisableEnter || valueKeyDown === '' || valueKeyDown?.length > MAX_LENGTH) {
                return setError(true);
            } else {
                setError(false);
            }

            currentCustomfield?.data?.push(valueKeyDown);
            setTimeout(() => {
                setValue('');
            });
            setCustomFields(temp);
            e?.preventDefault();
        }
    };

    const isTagAlreadyExists = () => {
        if (value?.trim() === '') return false;
        const currentCustomfield = customFields.find((item) => item.id === id);
        if (currentCustomfield?.data === null || typeof currentCustomfield?.data == 'undefined') {
            currentCustomfield.data = [];
        }
        return currentCustomfield?.data?.includes(value);
    }

    const handleRemoveTagField = (value: string) => {
        const temp = [...customFields];
        const currentCustomfield = temp?.find((item) => item.id === id);
        currentCustomfield.data = [...data]?.filter((el) => el !== value);
        setCustomFields(temp);
    };
    const {t} = useTranslation()
    return (
        <div key={id} className="tagItem">
            <div className="tagItem-title">{title}</div>
            <div className="tagItem-list">
                {data?.map((tag, index) => {
                    return (
                        <div key={index} className="tagItem-item">
                            <div className="item-name">{tag?.length > 25 ? `${tag?.slice(0,25)}...` : tag}</div>
                            <div className="item-icon" onClick={() => handleRemoveTagField(tag)}>
                                <CloseIcon w={8} h={8} className='!text-enterprise hover:!text-red-500' />
                            </div>
                        </div>
                    );
                })}
                {isEdit && (
                    <div className='wrapper-input'>
                        <input
                            type="text"
                            value={value}
                            placeholder={t("ENTER_VALUE")}
                            className="item-input"
                            onKeyDown={handleAddTagField}
                            onChange={(e) => setValue(e.target.value)}
                        />
                        {
                          value?.length > MAX_LENGTH &&  <div className='error'>{t('FORM_CONST.MAX_LENGTH_TEXT', {maxLength: MAX_LENGTH})}</div>
                        }
                        {
                          isTagAlreadyExists() && <div className='error'>{t('FORM_CONST.TAG_ALREADY_EXISTS')}</div>
                        }
                    </div>
                )}
            </div>
            
        </div>
    );
};
