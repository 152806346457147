import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import ButtonComponent from 'components/Button/ButtonComponent';
import Dropdown from './Dropdown';
import { useForm, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ClassService from 'services/class.service';
import ErrorMessageForm from 'components/ErrorMessageForm';
import classService from 'services/class.service';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import DialogComponent from 'components/Dialog/DialogComponent';
import PreviewReport from './GradebookComponents/PreviewReport';
import PublishSchedule from './GradebookComponents/PublishSchedule';
import dayjs from 'dayjs';
import { get, isEqual } from 'lodash';
import { UserContext } from 'contexts/User/UserContext';
import { checkAdminTeachersComments } from './GradebookComponents/constantsGradebook';
import { Toastify } from '_shared';

const styleBtn = `py-2 border  rounded-lg  hover:text-white text-base font-semibold`;
const checkTeachersCompleted = (initValue: any, membership: any) => {
    let check = false;
    if (initValue?.comments && membership?.id) {
        const arrCompleted: any[] = initValue?.comments.filter((i) => i.is_completed && i?.id === membership?.user_id);
        if (arrCompleted?.length > 0) {
            check = true;
        }
    }
    return check;
};
const renderCompleted = (instructor: any) => {
    return (
        <>
            {instructor.is_completed ? (
                <>
                    <img src={instructor?.avatar_url} alt="ava" className="w-6 h-6  rounded-full  mx-2" />
                    <div className="text-[#8083A3] text-xs">{instructor?.display_name} </div>
                </>
            ) : (
                ''
            )}
        </>
    );
};

interface CreateProgressReportProps {
    detailStudent: any;
    setReloadData: React.Dispatch<React.SetStateAction<boolean>>;
    editId?: number;
    setStatusReport?: Dispatch<any>;
    statusReport?: any;
    studentName?: string;
    setPublishDate?: Dispatch<any>;
}

const CreateProgressReport: React.FC<CreateProgressReportProps> = ({
    detailStudent,
    setReloadData,
    editId = undefined,
    setStatusReport,
    statusReport = '',
    studentName = '',
    setPublishDate,
}) => {
    const [loading, setLoading] = useState(false);
    const { t: translator } = useTranslation();
    const [selectTabs, setSelectTabs] = useState<number>(1);
    const [previewProgress, setPreviewProgress] = useState<boolean>(false);
    const [publishSchedule, setPublishSchedule] = useState<boolean>(false);
    const workspace: any = useParams();
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const [listHistory, setListHistory] = useState<any>();
    const [dataVersion, setDataVersion] = useState<any>();
    const [isChange, setIsChange] = useState(false);
    const [originalData, setOriginalData] = useState<any>();
    const [haveHistory, setHaveHistory] = useState<boolean>(false);

    const {
        userState: { result },
    } = useContext(UserContext);

    const [initValue, setInitValue] = React.useState<{
        name?: string;
        vocabulary?: string;
        grammar?: string;
        speaking?: string;
        listening?: string;
        reading?: string;
        pronunciation?: string;
        writing?: string;
        future_skills?: string;
        progress?: string;
        behavior?: string;
        participation?: string;
        comments?: any[];
        reportDate?: any;
        reportTime?: any;
        scheduleProgress?: any;
        instructorComment?: string;
    }>({
        name: '',
        vocabulary: '',
        grammar: '',
        speaking: '',
        listening: '',
        reading: '',
        pronunciation: '',
        writing: '',
        future_skills: '',
        progress: '',
        behavior: '',
        participation: '',
        comments: [],
        reportDate: new Date(),
        reportTime: new Date(),
        scheduleProgress: null,
        instructorComment: '',
    });
    const {
        result: { membership },
    } = getWorkspaceDetailState;
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, dirtyFields, touchedFields },
        setValue,
        getValues,
        watch,
        clearErrors,
        setError,
        trigger,
        reset,
    } = useForm({
        defaultValues: React.useMemo(() => {
            return {
                ...initValue,
            };
        }, [initValue]),
    });

    useEffect(() => {
        reset(initValue);
        setOriginalData({
            name: initValue.name,
            vocabulary: initValue.vocabulary,
            grammar: initValue.grammar,
            speaking: initValue.speaking,
            listening: initValue.listening,
            reading: initValue.reading,
            pronunciation: initValue.pronunciation,
            writing: initValue.writing,
            future_skills: initValue.future_skills,
            progress: initValue.progress,
            behavior: initValue.behavior,
            participation: initValue.participation,
            comments: initValue.comments,
            instructorComment: initValue?.instructorComment,
        });
    }, [initValue]);

    const { fields, replace } = useFieldArray({
        control,
        name: 'comments',
    });

    const { id: workspaceId, classId } = workspace;
    const { id, student } = detailStudent;

    const getTeachersInClassApi = () => {
        ClassService.getTeacherListOfStudentService(
            { workspaceId, classId, membershipId: student?.id },
            {
                skip_pagination: true,
            }
        ).then((res) => {
            setValue('comments', res?.data?.items);
        });
    };

    useEffect(() => {
        checkSaveBtn();
    }, [watch()]);

    const checkSaveBtn = () => {
        setIsChange(
            !isEqual(originalData, {
                name: watch().name,
                vocabulary: watch().vocabulary,
                grammar: watch().grammar,
                speaking: watch().speaking,
                listening: watch().listening,
                reading: watch().reading,
                pronunciation: watch().pronunciation,
                writing: watch().writing,
                future_skills: watch().future_skills,
                progress: watch().progress,
                behavior: watch().behavior,
                participation: watch().participation,
                comments: watch().comments,
                instructorComment: initValue?.instructorComment,
            })
        );
    };

    const createReportApi = (data: any) => {
        setLoading(true);
        ClassService.createProgressReportService(
            {
                workspaceId,
                classId,
            },
            data
        )
            .then((res) => {
                setReloadData(true);
                Toastify.success();
            })
            .catch((err) => {
                console.log(err.response);
            })
            .finally(() => setLoading(false));
    };

    const updateReportApi = (data: any) => {
        setLoading(true);
        ClassService.UpdateProgressReportService(
            {
                workspaceId,
                classId,
                reportId: editId,
            },
            data
        )
            .then((res) => {
                setReloadData(true);
                Toastify.success('Report successfully saved!');
            })
            .catch((err) => {
                if (err?.response?.data?.error?.code === 400) {
                    if (err?.response?.data?.error?.name === 'NOTHING_CHANGED') {
                        Toastify.error('Progress report not changed!');
                    }
                } else {
                    Toastify.error('Error occurred while saving');
                }
            })
            .finally(() => setLoading(false));
    };

    const onSubmitForm = (values: any) => {
        const bodyData = {
            id,
            student_id: student.id,
            name: values.name || null,
            behavior: values.behavior || null,
            future_skills: values.future_skills || null,
            grammar: values.grammar || null,
            listening: values.listening || null,
            participation: values.participation || null,
            progress: values.progress || null,
            pronunciation: values.pronunciation || null,
            reading: values.reading || null,
            speaking: values.speaking || null,
            vocabulary: values.vocabulary || null,
            writing: values.writing || null,
            comments: [values?.instructorComment] || [],
        };
        const bodyDataWithUpdateProgressReport = {
            name: values.name || null,
            behavior: values.behavior || null,
            future_skills: values.future_skills || null,
            grammar: values.grammar || null,
            listening: values.listening || null,
            participation: values.participation || null,
            progress: values.progress || null,
            pronunciation: values.pronunciation || null,
            reading: values.reading || null,
            speaking: values.speaking || null,
            vocabulary: values.vocabulary || null,
            writing: values.writing || null,
            comments: [values?.instructorComment] || [],
        };
        if (editId) updateReportApi(bodyDataWithUpdateProgressReport);
        else createReportApi(bodyData);
    };

    React.useEffect(() => {
        if (!editId) {
            getTeachersInClassApi();
        }
    }, []);

    const togglePreviewProgress = () => {
        setPreviewProgress(!previewProgress);
    };

    const togglePublishSchedule = () => {
        setPublishSchedule(!publishSchedule);
    };

    const handlePreviewReport = (i: any) => {
        if (i) {
            setDataVersion(i);
            togglePreviewProgress();
        }
    };

    React.useEffect(() => {
        if (editId) {
            classService
                .getReportDetailsOfClass(workspaceId, classId, editId)
                .then((res) => {
                    if (res) {
                        setInitValue({
                            ...initValue,
                            name: res?.name,
                            vocabulary: res?.data?.vocabulary,
                            grammar: res?.data?.grammar,
                            speaking: res?.data?.speaking,
                            listening: res?.data?.listening,
                            reading: res?.data?.reading,
                            pronunciation: res?.data?.pronunciation,
                            writing: res?.data?.writing,
                            future_skills: res?.data?.future_skills,
                            progress: res?.data?.progress,
                            behavior: res?.data?.behavior,
                            participation: res?.data?.participation,
                            comments: res?.data?.comments,
                            instructorComment: res?.data?.comments[0],
                        });
                        setPublishDate(res?.published_at);

                        setStatusReport(res?.status);
                    }
                })
                .catch((err) => console.log(err));
            classService.getReportVersion(workspaceId, classId, editId, { skip_pagination: true }).then((res) => {
                if (res?.data?.items?.length > 0) {
                    setHaveHistory(true);
                }
            });
        }
    }, [editId]);

    const handleCompleteReport = (reportId) => {
        classService
            .completeReportClass(workspaceId, classId, reportId)
            .then((res) => {
                setReloadData(true);
                Toastify.success();
            })
            .catch(() => Toastify.error());
    };

    const onCompleteReport = async (reportId) => {
        if (isChange) {
            const values = getValues();
            await onSubmitForm(values);
            setTimeout(() => {
                handleCompleteReport(reportId);
            }, 700);
        } else {
            handleCompleteReport(reportId);
        }
    };

    const handlePublishReport = (values: any, reportId: any) => {
        const date = dayjs(values.reportDate).format('YYYY-MM-DD');
        const time = dayjs(values.reportTime).format('HH:mm:ss');
        const dateTime = date + 'T' + time;
        const bodyData = { schedule_at: dateTime };

        classService
            .publishReportClass(workspaceId, classId, reportId, values?.scheduleProgress.value === 1 ? {} : bodyData)
            .then((res) => {
                setReloadData(true);
                Toastify.success();
            })
            .catch((err) => {
                const error: any = get(err, 'response.data.error');
                if (error && error?.code === 400 && error?.name === 'INVALID_ACTION') {
                    Toastify.error('Invalid Report');
                } else Toastify.error();
            });
    };

    const onPublishReport = async (reportId) => {
        const values = getValues();
        if (values) {
            if (isChange) {
                await onSubmitForm(values);
                setTimeout(() => {
                    handlePublishReport(values, reportId);
                }, 700);
            } else {
                handlePublishReport(values, reportId);
            }
        }
    };

    useEffect(() => {
        if (selectTabs === 2) {
            classService
                .getReportVersion(workspaceId, classId, editId, { skip_pagination: true })
                .then((res) => setListHistory(res?.data));
        }
    }, [selectTabs]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmitForm)}>
                {editId ? (
                    <div className="flex ml-2 items-center mb-6">
                        <div className="bg-dark-300 w-1 h-1 rounded-full mr-2"></div>
                        <p className="text-xs font-semibold text-dark-300 flex items-center">
                            Completed: {initValue?.comments ? initValue?.comments.map((i) => renderCompleted(i)) : null}
                        </p>
                    </div>
                ) : null}

                {/* {selectTabs === 2 ? (
                    <div className="flex ml-2 items-center mb-6">
                        <div className="bg-dark-300 w-1 h-1 rounded-full mr-2"></div>
                        <p className="text-xs font-semibold text-dark-300">Version:</p>
                    </div>
                ) : null} */}
                {statusReport === 'publish' || haveHistory ? (
                    <div className="flex items-center justify-center ">
                        <button
                            className={`${selectTabs === 1 ? 'text-[#024FEE]' : 'text-dark-100 '} mr-5 text-base font-bold`}
                            onClick={() => {
                                setSelectTabs(1);
                            }}
                            type="button"
                            disabled={selectTabs === 1}
                        >
                            Information
                        </button>
                        <button
                            className={`${selectTabs === 2 ? 'text-[#024FEE]' : 'text-dark-100 '} text-base font-bold `}
                            onClick={() => {
                                setSelectTabs(2);
                            }}
                            type="button"
                            disabled={selectTabs === 2}
                        >
                            History
                        </button>
                    </div>
                ) : null}
                {selectTabs === 1 ? (
                    <>
                        {' '}
                        <div className="grid grid-cols-1 pb-5">
                            <div>
                                <label htmlFor="reportName" className="block text-gray-900 font-semibold text-sm">
                                    {translator('GRADEBOOK_APOLLO.CREATE_REPORT.REPORT_NAME')}
                                </label>
                                <input
                                    type="text"
                                    id="reportName"
                                    {...register('name', {
                                        required: translator('FORM_CONST.REQUIRED_FIELD'),
                                        maxLength: {
                                            value: 1000,
                                            message: translator('FORM_CONST.MAX_LENGTH'),
                                        },
                                    })}
                                    className="w-full border rounded border-ooolab_gray_6 px-3 py-2 mt-1 text-sm font-normal text-ooolab_dark_1"
                                />
                                <ErrorMessageForm errors={errors} name={'name'} />
                            </div>
                            <div className="hidden lg:block"></div>
                        </div>
                        <h1 className="text-ooolab_dark_1 hidden font-semibold text-sm text-center my-5">
                            {translator('GRADEBOOK_APOLLO.CREATE_REPORT.TEACHER_ASSESSMENT')}
                        </h1>
                        <div>
                            <div className="grid grid-cols-1 pb-5">
                                <div className="mb-5">
                                    <Dropdown
                                        // inputProps={{
                                        //     ...register('progress'),
                                        // }}
                                        label="PROGRESS"
                                        skill="Progress"
                                        studentName={`${student.display_name} ${student?.nick_name && ` / ${student?.nick_name}`}`}
                                        setValue={setValue}
                                        clearErrors={clearErrors}
                                        defaultValue={getValues('progress')}
                                        position={fields.length === 0 ? 'top' : 'bottom'}
                                    />
                                    {/* <ErrorMessageForm errors={errors} name={'progress'} /> */}
                                </div>
                                <div className="mt-5 lg:mt-0">
                                    <Dropdown
                                        // inputProps={{
                                        //     ...register('behavior'),
                                        // }}
                                        label="BEHAVIOUR"
                                        skill="Behavior"
                                        studentName={`${student.display_name} ${student?.nick_name && ` / ${student?.nick_name}`}`}
                                        setValue={setValue}
                                        clearErrors={clearErrors}
                                        defaultValue={getValues('behavior')}
                                        position={fields.length === 0 ? 'top' : 'bottom'}
                                    />
                                    {/* <ErrorMessageForm errors={errors} name={'behavior'} /> */}
                                </div>
                            </div>
                            <div className="grid grid-cols-1 ">
                                <div>
                                    <Dropdown
                                        // inputProps={{
                                        //     ...register('participation'),
                                        // }}
                                        label="PARTICIPATION"
                                        skill="Participation"
                                        studentName={''}
                                        setValue={setValue}
                                        clearErrors={clearErrors}
                                        defaultValue={getValues('participation')}
                                        position={fields.length === 0 ? 'top' : 'bottom'}
                                    />
                                    {/* <ErrorMessageForm errors={errors} name={'participation'} /> */}
                                </div>
                                <div className="hidden lg:block"></div>
                            </div>
                        </div>
                        <div className=" grid grid-cols-1">
                            <div className="mt-5">
                                <div className="flex text-gray-900 font-semibold text-sm ">
                                    <label className=" truncate max-w-[320px]  ">
                                        {translator('GRADEBOOK_APOLLO.CREATE_REPORT.TEACHER_COMMENTS')}
                                    </label>
                                </div>
                                <textarea
                                    id={'instructorComment'}
                                    rows={4}
                                    cols={50}
                                    placeholder={translator('GRADEBOOK_APOLLO.CREATE_REPORT.TEACHER_IS_COMMENTS')}
                                    className="w-full border rounded border-ooolab_gray_6 px-3 py-2 mt-1 text-sm font-normal text-gray-900 outline-blue-200"
                                    {...register(`instructorComment`, {
                                        maxLength: {
                                            value: 1000,
                                            message: translator('FORM_CONST.MAX_LENGTH'),
                                        },
                                    })}
                                />
                                <ErrorMessageForm errors={errors} name={`instructorComment`} />
                            </div>
                        </div>
                        <h1 hidden={fields.length === 0} className="text-ooolab_dark_1 hidden font-semibold text-sm text-center mt-5">
                            {translator('GRADEBOOK_APOLLO.CREATE_REPORT.TEACHER_COMMENTS')}
                        </h1>
                        <div hidden={fields.length === 0} className="hidden grid grid-cols-1 lg:grid-cols-2 lg:gap-x-5">
                            {fields.map((item, index) => (
                                <div key={item.id}>
                                    <div className="mt-5">
                                        <div className="flex text-ooolab_dark_1 font-semibold text-sm ">
                                            <label className=" truncate max-w-[320px]  ">
                                                {translator('GRADEBOOK_APOLLO.CREATE_REPORT.TEACHER') + ' ' + item.display_name + ' '}
                                            </label>
                                            <p>(EN)</p>
                                        </div>
                                        <textarea
                                            id={'teachercomment' + item.id}
                                            rows={4}
                                            cols={50}
                                            placeholder={translator('GRADEBOOK_APOLLO.CREATE_REPORT.TEACHER_IS_COMMENTS')}
                                            className="w-full border rounded border-ooolab_gray_6 px-3 py-2 mt-1 text-sm font-normal text-ooolab_dark_1 outline-blue-200"
                                            {...register(`comments.${index}.comment`, {
                                                maxLength: {
                                                    value: 1000,
                                                    message: translator('FORM_CONST.MAX_LENGTH'),
                                                },
                                            })}
                                        />
                                        <ErrorMessageForm errors={errors} name={`comments.${index}.comment`} />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div hidden={fields.length === 0} className="hidden grid grid-cols-1 lg:grid-cols-2 lg:gap-x-5">
                            {fields.map((item, index) => (
                                <div key={item.id}>
                                    <div className="mt-5">
                                        <div className="flex text-ooolab_dark_1 font-semibold text-sm ">
                                            <label className=" truncate max-w-[320px] ">
                                                {translator('GRADEBOOK_APOLLO.CREATE_REPORT.TEACHER') + ' ' + item.display_name + ' '}
                                            </label>
                                            <p>(VI)</p>
                                        </div>
                                        <textarea
                                            id={'teachercommentvn' + item.id}
                                            rows={4}
                                            cols={50}
                                            placeholder={translator('GRADEBOOK_APOLLO.CREATE_REPORT.TEACHER_IS_COMMENTS')}
                                            className="w-full border rounded border-ooolab_gray_6 px-3 py-2 mt-1 text-sm font-normal text-ooolab_dark_1 outline-blue-200"
                                            {...register(`comments.${index}.comment_vn`, {
                                                maxLength: {
                                                    value: 1000,
                                                    message: translator('FORM_CONST.MAX_LENGTH'),
                                                },
                                            })}
                                        />
                                        <ErrorMessageForm errors={errors} name={`comments.${index}.comment_vn`} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <div className="max-h-[50vh] py-3 overflow-auto">
                        {listHistory?.items?.map((i) => (
                            <div className="grid grid-cols-3 gap-7 my-4 mx-5 ">
                                <div className="col-span-1">
                                    <div className="text-xs text-ooolab_dark_2 font-semibold">Published by</div>
                                    <div className="text-xs text-ooolab_dark_1 font-normal">{i?.publisher?.display_name}</div>
                                </div>
                                <div className="col-span-1">
                                    <div className="text-xs text-ooolab_dark_2 font-semibold">Publish date</div>
                                    <div className="text-xs text-ooolab_dark_1 font-normal">
                                        {dayjs(i?.published_at).tz(result?.time_zone).format('DD/MM/YYYY HH:mm')}
                                    </div>
                                </div>
                                <div className="col-span-1 flex justify-end">
                                    <button
                                        className={`${styleBtn} border-ooolab_selected_bar_item text-primary-500 hover:bg-[#2576B9] px-4`}
                                        type="button"
                                        onClick={() => handlePreviewReport(i)}
                                    >
                                        Preview
                                    </button>
                                </div>
                            </div>
                        ))}

                        <DialogComponent
                            isOpen={previewProgress}
                            width={'min-w-ooolab_w_50'}
                            title={'Report Preview'}
                            onCloseModal={togglePreviewProgress}
                            maskClosable={true}
                            child={<PreviewReport reportId={editId} studentName={studentName} dataVersion={dataVersion} />}
                            styles={'max-w-min'}
                            stylesTitle={' !text-base text-gray-900'}
                        />
                    </div>
                )}

                {selectTabs === 1 ? (
                    <div className="mt-9 text-center">
                        {editId ? (
                            <div className="flex items-center justify-center">
                                <ButtonComponent
                                    title={'Save'}
                                    type="submit"
                                    classStyle={` text-xs font-semibold px-3 py-2 rounded mx-1 ${
                                        isChange && (checkAdminTeachersComments(initValue, membership) || membership?.role === 'admin')
                                            ? ' text-white bg-[#2576B9]'
                                            : ' text-white bg-gray_0'
                                    }`}
                                    disable={
                                        !isChange ||
                                        (!checkAdminTeachersComments(initValue, membership) && membership?.role !== 'admin')
                                    }
                                />
                                {membership?.role !== 'admin' ||
                                (membership?.role === 'admin' &&
                                    membership?.type === 'instructor' &&
                                    checkAdminTeachersComments(initValue, membership)) ? (
                                    <button
                                        className={`text-xs font-semibold px-3 py-2 rounded  text-white mx-1 ${
                                            checkTeachersCompleted(initValue, membership) ||
                                            !checkAdminTeachersComments(initValue, membership)
                                                ? 'bg-[#9ca3af] '
                                                : 'bg-[#2576B9] '
                                        }`}
                                        disabled={
                                            checkTeachersCompleted(initValue, membership) ||
                                            !checkAdminTeachersComments(initValue, membership)
                                        }
                                        type="button"
                                        onClick={() => onCompleteReport(editId)}
                                    >
                                        Complete
                                    </button>
                                ) : null}
                                {membership?.role === 'admin' ? (
                                    <button
                                        className={`text-xs font-semibold px-3 py-2 rounded  text-white ] mx-1 ${
                                            statusReport === 'publish' ? 'bg-[#9ca3af] ' : 'bg-[#2576B9] '
                                        } `}
                                        onClick={togglePublishSchedule}
                                        type="button"
                                        disabled={statusReport === 'publish'}
                                    >
                                        Publish
                                    </button>
                                ) : null}

                                <a
                                    href="https://docs.google.com/forms/d/e/1FAIpQLSfFehSr9yZp81EcefQzbM2iLa8y-b_7NqVyAH_vOgTos4d30g/viewform"
                                    target="_blank"
                                    className="hidden text-xs font-semibold px-3 py-2 rounded  text-white bg-[#2576B9] mx-1"
                                    rel="noopener noreferrer"
                                >
                                    Tutoring Request
                                </a>
                            </div>
                        ) : (
                            <ButtonComponent
                                title={translator('GRADEBOOK_APOLLO.CREATE_REPORT.CREATE')}
                                type="submit"
                                classStyle="bg-[#2576B9] text-xs font-semibold px-3 py-2 text-white rounded"
                            />
                        )}
                    </div>
                ) : null}
            </form>
            {publishSchedule && (
                <DialogComponent
                    isOpen={publishSchedule}
                    width={'min-w-[20vw]'}
                    title={'Schedule Publish'}
                    onCloseModal={togglePublishSchedule}
                    maskClosable={true}
                    child={
                        <PublishSchedule
                            control={control}
                            register={register}
                            setValue={setValue}
                            trigger={trigger}
                            reportId={editId}
                            nameFields={'scheduleProgress'}
                            onPublishReport={onPublishReport}
                            getValues={getValues}
                        />
                    }
                    styles={'max-w-min'}
                />
            )}
        </>
    );
};

export default CreateProgressReport;
