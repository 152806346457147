import React from 'react'

export default function UserCheckIcon({className = 'w-5 h-5'}) {
  return (
    <svg className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.22039 12.8871C2.00179 12.1057 3.0616 11.6667 4.16667 11.6667H10C11.1051 11.6667 12.1649 12.1057 12.9463 12.8871C13.7277 13.6685 14.1667 14.7283 14.1667 15.8334V17.5001C14.1667 17.9603 13.7936 18.3334 13.3333 18.3334C12.8731 18.3334 12.5 17.9603 12.5 17.5001V15.8334C12.5 15.1704 12.2366 14.5345 11.7678 14.0656C11.2989 13.5968 10.663 13.3334 10 13.3334H4.16667C3.50363 13.3334 2.86774 13.5968 2.3989 14.0656C1.93006 14.5345 1.66667 15.1704 1.66667 15.8334V17.5001C1.66667 17.9603 1.29357 18.3334 0.833333 18.3334C0.373096 18.3334 0 17.9603 0 17.5001V15.8334C0 14.7283 0.438987 13.6685 1.22039 12.8871Z" fill="#111827"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.08329 3.33341C5.70258 3.33341 4.58329 4.4527 4.58329 5.83341C4.58329 7.21413 5.70258 8.33341 7.08329 8.33341C8.464 8.33341 9.58329 7.21413 9.58329 5.83341C9.58329 4.4527 8.464 3.33341 7.08329 3.33341ZM2.91663 5.83341C2.91663 3.53223 4.78211 1.66675 7.08329 1.66675C9.38448 1.66675 11.25 3.53223 11.25 5.83341C11.25 8.1346 9.38448 10.0001 7.08329 10.0001C4.78211 10.0001 2.91663 8.1346 2.91663 5.83341Z" fill="#111827"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.756 6.91083C20.0814 7.23626 20.0814 7.7639 19.756 8.08934L16.4226 11.4227C16.0972 11.7481 15.5696 11.7481 15.2441 11.4227L13.5775 9.756C13.252 9.43057 13.252 8.90293 13.5775 8.57749C13.9029 8.25206 14.4305 8.25206 14.756 8.57749L15.8334 9.6549L18.5775 6.91083C18.9029 6.58539 19.4305 6.58539 19.756 6.91083Z" fill="#111827"/>
    </svg>
  )
}
