import React, { Fragment, ReactNode, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon, XIcon } from "@heroicons/react/outline";
import { CheckboxType } from "types/Lesson.type";
import { ResponseData } from "types/ApiData.type";
import { CheckboxFill } from "assets/icon";
import SearchBox from "components/SearchBox";
import Spinner from "components/Spinner";
import useComponentVisible from "utils/UseComponentVisible";
import './DropdownAsync.scss';
let deplayChange;
let deplaySearch;

interface DropdownAsyncProps {
    items?: CheckboxType[],
    loadOptions?: (arg?: any) => Promise<any>;
    paramsApi?: {
        types?: string,
        skip_pagination?: boolean,
        order?: string,
        sort_by?: string,
    },
    children?: ReactNode,
    className?: string,
    hasArrow?: boolean,
    placeholder?: string,
    displayKey?: string,
    isSearchable?: boolean,
    onChange: (value: CheckboxType[]) => void,
    onRemove?: () => void,
    isReset?: boolean,
    selectedValue?: any;
    isRemoved?: boolean;
    isAll?: boolean;
    customOption?: boolean;
}
export const DropdownAsync = ({
    items,
    loadOptions,
    paramsApi,
    className,
    hasArrow = true,
    children,
    placeholder,
    displayKey = 'name',
    isSearchable = true,
    onChange,
    onRemove,
    isReset,
    selectedValue,
    isRemoved,
    isAll,
    customOption = false
}: DropdownAsyncProps) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [searchText, setSearchText] = useState<string>('');
    const [data, setData] = useState<ResponseData>();

    const handleLoadOptions = async (params?: any) => {
        const payload = {
            ...paramsApi,
            ...params,
            q: params?.q
        }
        try {
            if (!loadOptions) {
                return;
            };
            setLoading(true);
            const response = await loadOptions(payload);
            if (response?.data) {
                setData(response?.data);
            } else {
                setData(response);
            }

        } finally {
            setLoading(false);
        }
    };

    const getMore = () => {
        handleLoadOptions({
            page: data?.page + 1,
            q: searchText
        })
    }

    const onSearch = (value: string): void => {
        clearTimeout(deplaySearch);
        setSearchText(value);
        deplaySearch = setTimeout(() => {
            if (loadOptions) {
                setOptions([]);
                handleLoadOptions({ page: 1, q: value });
            } else {
                search(value);
            }
        }, 600);
    }

    const search = (value: string) => {
        const searchItem: CheckboxType[] = items.filter((d) =>
            d?.value?.toLowerCase().includes(value.toLowerCase())
        );
        setOptions(searchItem);
    }

    const handleRemove = (event) => {
        setSelected([]);
        if (onRemove) {
            onRemove();
        }

        event.preventDefault();
        event.stopPropagation();
    }

    const onClear = (event) => {
        setSelected([]);
        onChange([]);
    }

    const handleChange = (value) => {
        let prevSelected = selected.filter(item => item?.id == value?.id);
        if (prevSelected.length === 0) {
            prevSelected = [
                ...selected,
                ...[value]
            ];
        } else {
            prevSelected = selected.filter(item => item?.id != value?.id);
        }
        setSelected(prevSelected);
        clearTimeout(deplayChange);
        //Debounce when select on the value to call some actions with 600ms
        deplayChange = setTimeout(() => {
            onChange(prevSelected);
        }, 600);
    }

    useEffect(() => {
        if (items) {
            setOptions(items);
            setHasMore(false);
            setLoading(false);
        }

    }, [items]);

    useEffect(() => {
        const abortController = new AbortController();
        if (isComponentVisible && options?.length == 0) {
            handleLoadOptions({ page: 1 });
        }
        if (isComponentVisible && searchText) {
            setSearchText('');
            setOptions([]);
            handleLoadOptions({ page: 1 });
        }
        return () => {
            abortController.abort();
        }
    }, [isComponentVisible]);

    useEffect(() => {
        if (data?.items?.length > 0) {
            setOptions(prev => {
                let newData;
                if (customOption) {
                    newData = data?.items?.map((item, index) => {
                        const format = {
                            id: index,
                            value: item,
                            name: item,
                        }
                        return format
                    });

                } else {
                    newData = data?.items;
                }
                if (data?.page > 1) {
                    newData = [
                        ...prev,
                        ...data?.items
                    ];
                }
                if (newData?.length >= data?.total) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                }
                return newData;
            });
        } else {
            setHasMore(false);
        }
    }, [data]);

    useEffect(() => {
        if (isReset) {
            setSelected([]);
            setOptions([]);
        }

    }, [isReset]);

    useEffect(() => {
        setSelected(selectedValue || []);
    }, [selectedValue])

    return (
        <div className={`dropdownAsync ${className ? className : ''} ${selected?.length > 0 ? 'dropdownAsync--selected' : ''}
        ${isComponentVisible ? 'dropdownAsync--actived' : 'border-gray-200'}`} ref={ref}>
            <Listbox value={selected} onChange={() => { }} multiple>
                <div className='relative'>
                    <Listbox.Button
                        className={`dropdownAsync-button min-h-[36px]`}
                        onClick={() => { setIsComponentVisible(!isComponentVisible) }}>
                        {children ? children : <>
                            <span className="truncate max-w-[280px]">{placeholder}
                                {selected?.length > 0 && <>: {selected[0]?.[displayKey]}</>}
                                {isAll && selected?.length == 0 && ': All'}
                            </span>
                            {selected?.length > 1 && <span className="dropdownAsync-number">+{selected?.length - 1}</span>}
                        </>}
                        {(hasArrow && !isRemoved) || (selected?.length == 0 && isRemoved) ? <span className="dropdownAsync-arrow text-[8px]">
                            <ChevronDownIcon className={`w-4 h-4 transition ${isComponentVisible ? '-rotate-180' : ''}`} />
                        </span> : ''}
                        {isRemoved &&
                            <span className="ml-2 hover:text-primary-900" onClick={handleRemove}>
                                <XIcon className="w-4 h-4 text-gray-500" />
                            </span>
                        }
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        show={isComponentVisible}
                    >
                        <div className='dropdownAsync-panel w-[calc(100%)] min-w-[248px]'>
                            {isSearchable && <div className="dropdownAsync-searchbox">
                                <SearchBox onSubmit={onSearch} className="lg:w-full" value={searchText} />
                            </div>}
                            <Listbox.Options className="max-h-[205px] overflow-auto  focus:outline-none mt-1">
                                {options?.length > 0 ? options?.map((item: any, index) => (
                                    item[displayKey] && <>
                                        <Listbox.Option
                                            key={'dropdownAsync_' + item?.id}
                                            className={({ active }) => `relative cursor-default py-0 ${active ? '' : 'text-gray-900'}`}
                                            value={item}
                                        >
                                            {() => (
                                                <>
                                                    <div className={`dropdownAsync-item`} key={'dropdownAsync_' + item?.id + index}
                                                        onClick={() => handleChange(item)}
                                                    >
                                                        <div className={`dropdownAsync-checkbox ${selected?.find(select => select?.id == item?.id) ? 'dropdownAsync-checkbox--checked' : ''}`}>
                                                            <label>
                                                                <CheckboxFill />
                                                            </label>
                                                        </div>
                                                        <span className="max-w-[332px]">
                                                            {item[displayKey]?.length > 25 ? `${item[displayKey]?.slice(0, 25)}...` : item[displayKey]}
                                                        </span>
                                                    </div>
                                                </>
                                            )}
                                        </Listbox.Option>
                                    </>
                                )) : !loading && <p className="my-2 text-center text-sm text-dark-300">No data found.</p>}
                                {hasMore && !loading ?
                                    <button
                                        className="dropdownAsync-showMore"
                                        onClick={getMore}
                                    >Show more</button> : ''}
                                {loading ? <Spinner /> : ''}
                            </Listbox.Options>
                            <div className="dropdownAsync-footer">
                                <button
                                    className={`dropdownAsync-footer-button ${selected?.length > 0 ? 'dropdownAsync-footer-button--active' : ''}`}
                                    onClick={onClear}
                                >
                                    Clear selection
                                </button>
                                <span className="dropdownAsync-footer-content">{selected?.length}/{data?.total || options.length} Selected</span>
                            </div>
                        </div>
                    </Transition>
                </div>
            </Listbox>
        </div>
    )
}