import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { useParams } from 'react-router-dom';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { useTranslation } from 'react-i18next';
import { trapSpacesForRequiredFields } from 'utils/commonFun';
import clsx from 'clsx';
import uploadService from 'services/upload.service';
import lessonService from 'services/lesson.service';
import './SCORMPackage.scss';

type SCORMPackage = {
    onClose: (value?: boolean) => void;
};

type SCORMPackageValue = {
    file: File;
    title: string;
    status: string;
};

export const SCORMPackage = ({ onClose }: SCORMPackage) => {
    const { t: translator } = useTranslation();
    const params: { id: string; folderId: string } = useParams();
    const {
        handleSubmit,
        register,
        setValue,
        watch,
        formState: { isDirty, isValid, errors },
    } = useForm({});

    const refFile = useRef(null);
    const [file, seFile] = useState<any>();
    const [loading, setLoading] = useState(false);

    const onChange = useCallback(async (event) => {
        let slectFile = event.target.files?.[0];
        if (!slectFile?.name?.endsWith('.zip')) {
            return Toastify.error(translator('FORM_CONST.FILE_TYPE_IS_NOT_ALLOWED'));
        }
        if (slectFile) {
            seFile(slectFile);
        }
    }, []);

    const onSubmit = (data: SCORMPackageValue) => {
        if (!file) {
            return;
        }
        setLoading(true);
        uploadService.uploadFile(
            file,
            async (path: string) => {
                try {
                    const payload: any = {
                        title: data?.title,
                        file: path,
                        type: 'scorm',
                        status: data?.status,
                        parent_id: params?.folderId,
                    };
                    if (!payload?.parent_id) delete payload.parent_id;

                    const response = await lessonService.createSCORM(params.id, payload);
                    if (!response?.data?.error) {
                        Toastify.success('SCORM was created successfully!');
                        onClose(true);
                    } else {
                        Toastify.error('SCORM create failure!');
                    }
                    setLoading(false);
                } catch {
                    Toastify.error('SCORM create failure!');
                    setLoading(false);
                }
            },
            () => {
                Toastify.error('SCORM create failure!');
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        setValue('status', 'draft');
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="SCORMPackage">
                    <div className="SCORMPackage-item">
                        <label className="SCORMPackage-label">
                            {translator('SCORM.Package')} <span className="SCORMPackage-required">*</span>
                        </label>
                        <div className="flex">
                            <div className="SCORMPackage-inputFile">
                                <input
                                    className="SCORMPackage-input"
                                    placeholder={translator('SCORM.No package selected')}
                                    type="file"
                                    ref={refFile}
                                    onChange={onChange}
                                />
                                <div className="grid pt-[2px]">
                                    <span
                                        className={clsx('truncate', {
                                            '!text-gray-800': file?.name,
                                        })}
                                    >
                                        {file?.name ? file?.name : translator('SCORM.No package selected')}
                                    </span>
                                </div>
                            </div>
                            <div className="SCORMPackage-file" onClick={() => refFile.current.click()}>
                                <span>{translator('SCORM.Choose a Package')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="SCORMPackage-item">
                        <label className="SCORMPackage-label">
                            {translator('SCORM.Lesson Name')} <span className="SCORMPackage-required">*</span>
                        </label>
                        <input
                            {...register('title', {
                                required: true,
                                maxLength: 255,
                                validate: trapSpacesForRequiredFields,
                            })}
                            className="SCORMPackage-input"
                            placeholder={translator('SCORM.Enter Lesson Name')}
                        />
                        <span className="error">
                            {errors?.title && errors?.title.type == 'required' && translator('SCORM.Lesson Name is required')}
                            {errors?.title &&
                                errors?.title.type == 'maxLength' &&
                                translator('FORM_CONST.MAX_LENGTH_TEXT', { maxLength: 255 })}
                        </span>
                    </div>
                    <div className="SCORMPackage-item">
                        <label className="SCORMPackage-label">{translator('SCORM.Import With Status')}</label>
                        <input {...register('status')} type="text" hidden />
                        <div className="SCORMPackage-toggle">
                            <span
                                className={clsx({
                                    actived: watch('status') == 'draft',
                                })}
                                onClick={() => {
                                    setValue('status', 'draft');
                                }}
                            >
                                {translator('SCORM.As Draft')}
                            </span>
                            <span
                                className={clsx({
                                    actived: watch('status') == 'published',
                                })}
                                onClick={() => {
                                    setValue('status', 'published');
                                }}
                            >
                                {translator('SCORM.As Published')}
                            </span>
                        </div>
                    </div>
                    <div>
                        <p>Notes:</p>
                        <ul>
                            <li>Test your package before importing!</li>
                            <li>
                                To import a package, it needs to be zipped up (.zip) and needs to be either a SCORM, AICC or XAPI
                                course package.
                            </li>
                            <li>A SCORM package needs to have a imsmanifest.xml file which describes the course and its content.</li>
                            <li>
                                A XAPI package needs to have a tincan.xml file and needs to send xAPI statements to the SCORM Cloud
                                Learning Record Store.
                                <a href="https://xapi.com/" target="_blank" className="text-primary-500 hover:underline">
                                    Click here
                                </a>{' '}
                                to learn more about XAPI.
                            </li>
                            <li>
                                An AICC package needs to be zipped up with the 4 AICC descriptor files (AU, CRS, CST, & DES).• A cmi5
                                package must include a 'cmi5.xml' file which includes a list of assignable units (AU) which when
                                launched will communicate with the SCORM Cloud Learning Record Store via xAPI.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="SCORMPackage-action">
                    <ButtonOutline type="button" onClick={() => onClose(false)}>
                        Cancel
                    </ButtonOutline>

                    <ButtonPrimary
                        className="flex"
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                        disabled={!isDirty || !isValid || !file || loading}
                    >
                        {loading ? <CircleSpin className="mr-2" /> : null}
                        Add
                    </ButtonPrimary>
                </div>
            </form>
        </>
    );
};
