import { ButtonPrimary } from '_shared';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const ModalConfirmCourseUseCertificateTemplate = ({ onClose, data = [] }) => {
    const { t } = useTranslation();
    const params: any = useParams();

    const workspaceId = params.id;

    const handleLinkCourse = (id) => window.open(`/workspace/${workspaceId}/management/courses/${id}`, '_blank');

    return (
        <div className="text-sm font-semibold text-gray-800 mt-4">
            {data?.length === 1 ? (
                <h1>
                    <span className="font-normal">{t('CERTIFICATE_MANAGEMENT.MESSAGE_TEMPLATE_IN_USE') + ' '}</span>
                    <span className="text-enterprise cursor-pointer" onClick={() => handleLinkCourse(data?.[0]?.id)}>
                        {data?.[0]?.title}
                    </span>
                </h1>
            ) : (
                <>
                    <h1 className="font-normal">{t('CERTIFICATE_MANAGEMENT.MESSAGE_TEMPLATE_IN_USE')}:</h1>
                    <div className="mt-1 max-h-[220px] overflow-y-auto">
                        {data?.map((item, index) => (
                            <div key={index} className="text-enterprise pl-1 mt-1">
                                <span>● </span>
                                <span className='cursor-pointer' onClick={() => handleLinkCourse(item?.id)}>{item?.title}</span>
                            </div>
                        ))}
                    </div>
                </>
            )}
            <div className="mt-4 flex justify-center">
                <ButtonPrimary type="button" className="" onClick={onClose}>
                    {t('OK')}
                </ButtonPrimary>
            </div>
        </div>
    );
};
