import React from 'react';
import ManagementMasterPage from 'pages/RouteMasterPage';
import ClassList from 'components/Management/Class/ClassList';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';

const ClassListPage = () => {
    return (
        <ManagePermissionWrapper>
            <ClassList>
                <ManagementMasterPage />
            </ClassList>
        </ManagePermissionWrapper>
    );
};

export default ClassListPage;
