import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Section.scss';
import { debounce } from 'lodash';
import lessonService from 'services/lesson.service';
import { useParams } from 'react-router-dom';
import { ConfirmDeleteModal } from '_shared';
import Section from '../DisclosureSection/Section/Section';
import DisclosureButton from '../DisclosureSection/DisclosureButton';
import DisclosurePanel from '../DisclosureSection/DisclosurePanel/DisclosurePanel';
import Disclosure from '_shared/components/Disclosure/Disclosure';
import FileWithAction from '../DisclosureSection/DisclosurePanel/FileWithAction/FileWithAction';

const MAX_LENGTH = 225;

export const ItemSection = ({
    sectionItem,
    sectionSelected,
    onDragStart,
    onDragEnd,
    isActive,
    handleSelectContentToPreview,
    contentSelected,
    handleDeleteFileOfSection,
    handleDeleteSection,
    setSections,
    sections,
    isDraggingSection,
    setIsDraggingContent,
    onDragOver,
    setOnSaving,
    index,
    setSectionSelected,
    isDraggingContent
}) => {
    const [isOpenModalDeleteSection, setOpenModalDeleteSection] = useState(false);
    const deleteSection = (value: boolean) => {
        if (value) {
            handleDeleteSection(sectionItem?.id);
        }
        setOpenModalDeleteSection(false);
    };

    const { t: translator } = useTranslation();
    const [draggedContent, setDraggedContent] = useState<any>();
    const params: any = useParams();
    const [sectionName, setSectionName] = useState(sectionItem?.title);

    const onDragContentStart = (e, index) => {
        setOnSaving(true);
        setIsDraggingContent(true);
        setDraggedContent(sectionItem?.section_contents?.[index]);
    };

    const onDragOverContent = (index) => {
        const draggedOverContent = sectionItem?.section_contents?.[index];
        if (draggedContent?.id === draggedOverContent?.id || !draggedContent?.id) {
            return;
        }
        let section_contents = sectionItem?.section_contents?.filter((item) => item?.id !== draggedContent?.id);
        section_contents?.splice(index, 0, draggedContent);
        const newSection = { ...sectionItem, section_contents };
        const newSections = sections.map((section) => {
            if (section?.id === newSection?.id) return newSection;
            return section;
        });
        setSections(newSections);
    };

    const onDragContentEnd = () => {
        setIsDraggingContent(false)
        lessonService
            .sortContent(params?.id, params?.lessonId, sectionItem?.id, {
                ids: sectionItem?.section_contents?.map((content) => content?.id),
            })
            .then((res) => {
                setDraggedContent(undefined);
                setOnSaving(false);
            });
    };

    const handleUpdateNameSection = useCallback(        
        debounce((nextValue = '') => {
            if (nextValue?.length > MAX_LENGTH || !nextValue?.length) {
                setSectionName(sectionItem?.title);
                return;
            }
            setOnSaving(true);
            lessonService.renameSection(params?.id, params?.lessonId, sectionItem?.id, { title: nextValue }).then(() => {
                const newSection = { ...sectionItem, title: nextValue };
                const newSections = sections.map((section) => {
                    if (section?.id === newSection?.id) return newSection;
                    return section;
                });
                setSections(newSections);
                setSectionSelected(newSection);
            });
            setOnSaving(false);
        }, 800),
        [sectionItem, sections]
    );

    return (
        <>
            <Section
                isActive={isActive}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSectionSelected(sectionItem);
                    if (!sectionItem?.section_contents?.find((content) => content?.id === contentSelected?.id)) {
                        handleSelectContentToPreview(sectionItem?.section_contents?.[0]);
                    }
                }}
            >
                <Disclosure
                    index={index}
                    button={(open) => (
                        <DisclosureButton
                            index={index}
                            item={sectionItem}
                            isActiveSection={isActive}
                            sectionName={sectionName}
                            onDragOver={onDragOver}
                            onDragEnd={onDragEnd}
                            onDragStart={onDragStart}
                            onRename={handleUpdateNameSection}
                            onDelete={() => setOpenModalDeleteSection(true)}
                            open={open}
                            numberOfFile={sectionItem?.section_contents?.length}
                            setSectionName={setSectionName}
                            isDraggingContent={isDraggingContent}
                            featureName='libraryContentAssignment'
                        />
                    )}
                    panel={
                       () => {
                        return (
                            <DisclosurePanel>
                                <div
                                    className={`${
                                        isDraggingSection ? 'opacity-50 pointer-events-none' : 'pointer-events-auto opacity-100'
                                    }`}
                                >
                                    {sectionItem?.section_contents?.map((file, idx) => {
                                        return (
                                            <FileWithAction
                                                key={file?.id}
                                                file={file}
                                                index={idx}
                                                contentSelected={contentSelected}
                                                isSectionActive={isActive}
                                                isStandalone={false}
                                                sectionSelected={sectionSelected}
                                                handleSelectContentToPreview={handleSelectContentToPreview}
                                                setSectionSelected={setSectionSelected}
                                                sectionItem={sectionItem}
                                                onDragOver={onDragOverContent}
                                                onDragStart={onDragContentStart}
                                                onDragEnd={onDragContentEnd}
                                                translator={translator}
                                                sections={sections}
                                                setSections={setSections}
                                                setOnSaving={setOnSaving}
                                                handleDeleteFileOfSection={handleDeleteFileOfSection}
                                            />
                                        );
                                    })}
                                </div>
                            </DisclosurePanel>
                        )
                       }
                    }
                />
            </Section>

            {sectionName?.length > MAX_LENGTH && (
                <div className="maxLengthText maxLength-section">
                    {translator('FORM_CONST.MAX_LENGTH_TEXT', { maxLength: MAX_LENGTH })}
                </div>
            )}

            <ConfirmDeleteModal
                isOpen={isOpenModalDeleteSection}
                onClose={deleteSection}
                titleModal={translator('LIBRARY.DELETE_SECTION')}
            >
                <div className="text-sm">{translator('LIBRARY.DELETE_SECTION_MESSAGE')}</div>
            </ConfirmDeleteModal>
        </>
    );
};
