import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';
import { AsyncSelectField } from 'components/Select';
import { RichTextField } from 'components/InputForm/InputForm';
import { checkPermission, UUID } from 'utils/commonFun';

export const ProfileDetail = ({ control, errors }) => {
    const params: any = useParams();
    const { t } = useTranslation();

    const workspaceId = params.id;

    const fetchSubjects = async (payload: any) => {
        let response = {
            items: [],
            total: 0,
        };

        await workspaceService.getFieldDataInstructorWorkspace(workspaceId, payload).then((res) => {
            response = {
                items: res?.data?.items,
                total: res?.data?.total,
            };
        });
        return response;
    };

    const handleLoadOptionsSubject = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional.page,
            per_page: 20,
            q: inputValue || null,
            field: 'subject',
            skip_pagination: true,
        };

        const { items, total } = await fetchSubjects(payload);
        const newOptions = items.map((el: any, index) => ({
            id: UUID() + index + el,
            label: el,
            value: el,
        }));

        return {
            options: newOptions,
            hasMore: additional.page < total / 20,
            additional: {
                page: additional.page + 1,
            },
        };
    };

    const handleLoadOptionsLanguage = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional.page,
            per_page: 20,
            q: inputValue || null,
            field: 'language',
            skip_pagination: true,
        };

        const { items, total } = await fetchSubjects(payload);
        const newOptions = items.map((el: any, index) => ({
            id: UUID() + index + el,
            label: el,
            value: el,
        }));

        return {
            options: newOptions,
            hasMore: additional.page < total / 20,
            additional: {
                page: additional.page + 1,
            },
        };
    };

    const handleLoadOptionsCertificate = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional.page,
            per_page: 20,
            q: inputValue || null,
            field: 'certification',
            skip_pagination: true,
        };

        const { items, total } = await fetchSubjects(payload);
        const newOptions = items.map((el: any, index) => ({
            id: UUID() + index + el,
            label: el,
            value: el,
        }));

        return {
            options: newOptions,
            hasMore: additional.page < total / 20,
            additional: {
                page: additional.page + 1,
            },
        };
    };

    return (
        <div className="p-4 bg-gray-50 col-span-6 rounded-lg">
            <h1 className="text-sm font-semibold">{t("USER.PROFILE_DETAILS")}</h1>
            <p className="text-xs mt-1">{t("USER.PROFILE_DETAILS_DESC")}</p>
            <AsyncSelectField
                control={control}
                label={t("USER.SUBJECTS")}
                name="subjects"
                className="mt-4"
                placeholder={t("USER.SUBJECTS")}
                isMulti
                hideSelectedOptions
                // closeMenuOnSelect={false}
                errors={errors}
                loadOptions={handleLoadOptionsSubject}
                searchIcon={true}
                customStyles={{
                    menu: (base) => ({
                        ...base,
                        zIndex: 2,
                    }),
                }}
                isDisabled={!checkPermission('user','U')}
            />
            <AsyncSelectField
                control={control}
                label={t("USER.LANGUAGES")}
                name="languages"
                className="mt-4"
                placeholder={t("USER.LANGUAGES")}
                isMulti
                hideSelectedOptions
                // closeMenuOnSelect={false}
                errors={errors}
                loadOptions={handleLoadOptionsLanguage}
                searchIcon={true}
                customStyles={{
                    menu: (base) => ({
                        ...base,
                        zIndex: 2,
                    }),
                }}
                isDisabled={!checkPermission('user','U')}
            />
            <AsyncSelectField
                control={control}
                label={t("USER.CERTIFICATIONS")}
                name="certifications"
                className="mt-4"
                placeholder={t("USER.CERTIFICATIONS")}
                isMulti
                hideSelectedOptions
                // closeMenuOnSelect={false}
                errors={errors}
                loadOptions={handleLoadOptionsCertificate}
                searchIcon={true}
                customStyles={{
                    menu: (base) => ({
                        ...base,
                        zIndex: 2,
                    }),
                }}
                isDisabled={!checkPermission('user','U')}
            />
            <RichTextField
                label={t("USER.BIOGRAPHY")}
                name="biography"
                className="mt-5"
                control={control}
                errors={errors}
                placeholder={t("USER.ENTER_BIOGRAPHY")}
                rules={{
                    maxLength: {
                        value: 1000,
                        message: t('VALIDATE_NUMBERS_CHARACTERS', { character: 1000 }),
                    },
                }}
                disabled={!checkPermission('user','U')}
            />
        </div>
    );
};
