import { useEffect, useState } from 'react';
import DialogComponent from 'components/Dialog/DialogComponent';
import libraryService from 'services/library.service';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Popover } from '@headlessui/react';
import { ArrowLeftIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { FolderIcon } from 'assets/icon';
import workspaceService from 'services/workspace.service';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import './ModalMoveTo.scss';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import { LibraryTypeEnum } from 'components/Library/LibraryContants';

export default function ModalMoveFile({ onClose, isOpen, refreshData, arrItem = [], setLoadingMoveFile = undefined }) {
    const params: any = useParams();
    const { t } = useTranslation();
    const [rootFolder, setRootFolder] = useState<any>({});
    const [rootData, setRootData] = useState([]);
    const [currentFolder, setCurrentFolder] = useState(undefined);
    const [path, setPath] = useState([]);
    const [location, setLocation] = useState([]);

    const getListFolder = () => {
        libraryService.getlistFolder(params?.id, { object_type: LibraryTypeEnum.folder }).then((res) => {
            if (arrItem?.[0]?.object_type === LibraryTypeEnum.folder && arrItem?.length === 1) {
                const folders = res.data?.filter((folder) => folder?.id !== arrItem?.[0]?.id) || [];
                return setRootData([...[{id:0, name: 'Library'}],...folders]);
            }
            return setRootData([...[{id:0, name: 'Library'}],...res?.data]);
        });
    };

    const handleSelectFolder = (folder) => {
        if (path?.length === 0) {
            setRootFolder(folder);
            setLocation(() => [...path, folder]);
        }
        setCurrentFolder(folder);
        if (folder?.children?.length) {
            setPath(() => [...path, folder]);
            setLocation(() => [...path, folder]);
            if (arrItem?.[0]?.object_type === LibraryTypeEnum.folder && arrItem?.length === 1) {
                const folders = folder?.children?.filter((child) => child?.id !== arrItem?.[0]?.id) || [];
                return setRootData(folders);
            }
            setRootData(folder?.children);
        }
    };

    const handleBackFolder = () => {
        const newPath = path.slice(0, path?.length - 1);
        setPath(newPath);
        if (path?.length > 1) {
            if (arrItem?.[0]?.object_type === LibraryTypeEnum.folder && arrItem?.length === 1) {
                const folders = newPath[newPath?.length - 1]?.children?.filter((child) => child?.id !== arrItem?.[0]?.id) || [];
                return setRootData(folders);
            }
            setRootData(newPath[newPath?.length - 1]?.children);
        } else getListFolder();
    };

    useEffect(() => {
        getListFolder();
    }, [arrItem]);

    const handleMoveFile = (file) => {
        switch (file?.object_type) {
            case LibraryTypeEnum.lesson: {
                const id = file?.object_id;
                workspaceService.updatePartialLesson(params?.id, id, { parent_id: currentFolder?.id });
                break;
            }
            case LibraryTypeEnum.h5p: {
                libraryService.moveH5p(params?.id, file?.object_id, { parent_id: currentFolder?.id });
                break;
            }
            case LibraryTypeEnum.assignment: {
                libraryService.moveToAssignment(params?.id, file?.object_id, { parent_id: currentFolder?.id });
                break;
            }
            default: {
                const action = file?.object_type === LibraryTypeEnum.folder ? 'updateFolder' : 'updateFile';
                const id = file?.object_type === LibraryTypeEnum.folder ? file?.id : file?.object_id;
                libraryService[action](params?.id, id, { parent_id: currentFolder?.id });
            }
        }
    };

    const handleMoveMultiFiles = () => {
        setLoadingMoveFile?.(true);
        Promise.all(arrItem?.map((item) => handleMoveFile(item)))
            .then(() => {
                Toastify.success();
            })
            .catch(() => Toastify.error())
            .finally(() => {
                setTimeout(() => {
                    refreshData();
                    setLoadingMoveFile?.(false);
                    handleCloseModal();
                }, 300);
            });
    };

    const handleCloseModal = () => {
        onClose(false);
        setRootData([]);
        setRootFolder(undefined);
        setCurrentFolder(undefined);
        setPath([]);
        setLocation([]);
    };

    return (
        <DialogComponent
            title={t('MODALS.MOVE_TO_MODAL.MOVE_TO')}
            isOpen={isOpen}
            onCloseModal={handleCloseModal}
            child={
                <div className="modalmoveto">
                    <Popover className="modalmoveto-popover">
                        <Popover.Button className="modalmoveto-popover-button">
                            <div className="folder-name">{currentFolder?.name || t('MODALS.MOVE_TO_MODAL.SELECT_FOLDER')}</div>
                            <ChevronDownIcon className="icon" />
                        </Popover.Button>
                        <Popover.Panel className={`modalmoveto-popover-panel custom-scrollbar`}>
                        {({ close }) => (
                            <div>
                                {path?.length >= 1 && (
                                    <div className="back-btn" onClick={handleBackFolder}>
                                        <ArrowLeftIcon className="icon" /> <span>{t('BACK')}</span>
                                    </div>
                                )}
                                {rootData?.length === 0 && (
                                    <div>
                                        <NoContentWithImage />
                                    </div>
                                )}
                                {rootData?.map((folder) => {
                                    const isActive =
                                        //!!location?.find((item) => item?.id === folder?.id) ||
                                        currentFolder?.id === folder?.id ||
                                        rootFolder?.id === folder?.id;
                                    return (
                                        <div
                                            key={folder?.id}
                                            className={`folder-item ${isActive ? 'folder-item-active' : 'folder-item-normal'} ${
                                                path?.length >= 1 ? 'pl-6' : 'px-2'
                                            }`}
                                            onClick={() => {
                                                handleSelectFolder(folder);
                                                if (folder?.children?.length === 0) {
                                                    close();
                                                }
                                            }}
                                        >
                                            <div className={`folder-item-detail`}>
                                                <FolderIcon /> <span className="folder-item-detail-name">{folder?.name}</span>
                                            </div>
                                            {folder?.children?.length > 0 && <ChevronRightIcon className={`icon`} />}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        </Popover.Panel>
                    </Popover>

                    <div className="modalmoveto-group-button">
                        <ButtonOutline type="button" onClick={handleCloseModal}>
                            {t('MODALS.CANCEL')}
                        </ButtonOutline>
                        <ButtonPrimary onClick={handleMoveMultiFiles} type="button" disabled={!currentFolder}>
                            {t('MODALS.CONFIRM')}
                        </ButtonPrimary>
                    </div>
                </div>
            }
            stylesTitle="text-base"
            styles="max-w-md overflow-visible"
        />
    );
}
