export const StripeIcon = ({ width = '76px', height = '32px' }: { width?: string; height?: string }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 76 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M75.9994 16.5346C75.9994 11.066 73.3815 6.75098 68.3781 6.75098C63.3535 6.75098 60.3135 11.066 60.3135 16.4918C60.3135 22.9217 63.9024 26.1686 69.0537 26.1686C71.5659 26.1686 73.466 25.5919 74.9016 24.7801V20.5078C73.466 21.2341 71.8193 21.6827 69.7292 21.6827C67.6814 21.6827 65.8658 20.9564 65.6336 18.4358H75.9571C75.9571 18.1581 75.9994 17.0473 75.9994 16.5346ZM65.5703 14.5052C65.5703 12.0914 67.0269 11.0874 68.357 11.0874C69.6448 11.0874 71.017 12.0914 71.017 14.5052H65.5703Z" fill="#635BFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M52.1653 6.75098C50.0963 6.75098 48.7663 7.73361 48.0274 8.41718L47.7529 7.09276H43.1084V32.0003L48.3863 30.8682L48.4074 24.8229C49.1674 25.3783 50.2863 26.1686 52.1441 26.1686C55.9231 26.1686 59.3643 23.0926 59.3643 16.321C59.3432 10.1261 55.8598 6.75098 52.1653 6.75098ZM50.8986 21.4691C49.653 21.4691 48.9141 21.0205 48.4074 20.4651L48.3863 12.54C48.9352 11.9205 49.6952 11.4932 50.8986 11.4932C52.8197 11.4932 54.1497 13.6721 54.1497 16.4705C54.1497 19.3329 52.8408 21.4691 50.8986 21.4691Z" fill="#635BFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M35.8486 5.48992L41.1476 4.3364V0L35.8486 1.13216V5.48992Z" fill="#635BFF" />
            <path d="M41.1476 7.11377H35.8486V25.8051H41.1476V7.11377Z" fill="#635BFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M30.1684 8.69414L29.8306 7.11338H25.2705V25.8048H30.5484V13.1373C31.794 11.4925 33.9051 11.7916 34.5596 12.0265V7.11338C33.884 6.85704 31.414 6.38709 30.1684 8.69414Z" fill="#635BFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6137 2.47803L14.4625 3.58883L14.4414 20.6994C14.4414 23.861 16.7848 26.1894 19.9093 26.1894C21.6404 26.1894 22.9071 25.8689 23.6038 25.4844V21.148C22.9283 21.4257 19.5926 22.4084 19.5926 19.2469V11.6635H23.6038V7.11349H19.5926L19.6137 2.47803Z" fill="#635BFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.34123 12.54C5.34123 11.7069 6.0168 11.3864 7.13571 11.3864C8.74019 11.3864 10.7669 11.8778 12.3714 12.7536V7.73361C10.6191 7.02868 8.88797 6.75098 7.13571 6.75098C2.85006 6.75098 0 9.0153 0 12.7963C0 18.6921 8.02239 17.7522 8.02239 20.2942C8.02239 21.2768 7.17793 21.5973 5.99568 21.5973C4.24342 21.5973 2.0056 20.871 0.232227 19.8883V24.9724C2.1956 25.8269 4.18009 26.19 5.99568 26.19C10.3869 26.19 13.4058 23.9898 13.4058 20.166C13.3847 13.8003 5.34123 14.9325 5.34123 12.54Z" fill="#635BFF" />
        </svg>

    )
}