
import React, { useEffect, useMemo, useState } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { SessionBreakdown } from "constant/workspace.const";
import { format1Decimal } from "utils/commonFun";
interface ChartDonutProps {
  data: number[], 
  dataLabel?: any; 
  value?: string;
  label?: string; 
  colors?: any;
  hideLabel?: boolean;
  config?: {
    width: number;
    height: number;
  };
  hasPercent?:boolean;
  countTotal?: number;
}
const colorsDefault = ['#22C55E', '#F59E0B', '#6B7280', '#EF4444'];
const ChartDonut = ({ data, colors, dataLabel, label, hideLabel = false, config = {width: 250, height: 250}, hasPercent, countTotal=null }:ChartDonutProps ) => {
  const [colorsChart, setColorsChart] = useState(colors || colorsDefault);

  const total = useMemo(() =>  {
    if (countTotal) {
      return countTotal;
    }
    return data?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }, [data, countTotal]);

  const chartData = useMemo(() =>  {
    if (data?.length == 0) {
      return [0, 1];
    } 
    return data;
  }, [data]);

  useEffect(() => {
    if (total === 0) {
      setColorsChart(['#6B7280', '#6B7280', '#6B7280']);
    } else {
      setColorsChart(colorsDefault);
    }
  }, [total])
  useEffect(() => {
    if (colors) {
      setColorsChart(colors);
    }
  }, [colors])

  const options = {
    accessibility: {
      enabled: false
    },
    chart: {
      type: 'pie',
      borderWidth: 0,
      width: config.width,
      height: config.height,
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
    },
    credits: {
      enabled: false
    },
    title: {
      text: '',
      disable: true
    },
    subtitle: {
      useHTML: true,
      text: `
            <div style="text-align: center;">
                <div style="font-size: 36px; color:#1F2937; font-family:'Lexend';">
                  ${hasPercent ? `${data[0] ? format1Decimal(data[0] * 100/total) : 0}%` : total}
                </div>
                <div style="font-size: 14px; color:#1F2937; font-family:'Lexend'">${label}</div>
            </div>
        `,
      verticalAlign: 'middle'
    },
    plotOptions: {
      organization: {
        borderRadius: 0,
        linecap: 'square',
      },
      pie: {
        borderWidth: 0,
        borderRadius: 0,
        allowPointSelect: false,
        cursor: 'pointer',
        startAngle: 0,
        linecap: 'square',
      },
      series: {
        dataLabels: {
          enabled: false
        },
        lineWidth: 0,
        states: {
          hover: {
            enabled: true,
            halo: {
              size: 2
            }
          }
        }
      },
    },
    tooltip: {
      outside: true,
      enabled: data?.length > 0,
      useHTML: true,
      borderRadius: 4,
      headerFormat: '',
      formatter: function () {
        if (this.point.value === null) {
            return 'Null';
        }
        return `<span>${SessionBreakdown[dataLabel[this.point.x]?.status]}: </span>
        <b>${this.point.y} (${format1Decimal(this.point.y * 100/total)}%)</b>`;
    }
    },
    colors: colorsChart,
    series: [
      {
        data: chartData,
        size: '100%',
        innerSize: '70%'
      }
    ]
  };

  return (
    <>
      <div className="flex justify-center w-full">
        <div className={`relative  ${hideLabel ? 'w-full' : 'w-1/2  min-w-[250px] flex justify-end'}`}>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
        {!hideLabel && <div className="w-1/2 flex justify-center flex-col">
          {dataLabel?.map((item, index) => <div className="flex items-center space-x-2 my-1">
            <span className={`rounded w-3 h-3 inline-block mx-2`} style={{background: colorsChart[index]}}></span>
            <span className="text-xs text-gray-800">
              {SessionBreakdown[item.status]}
            </span>
          </div>)}
        </div>}
      </div>
    </>
  )
}

export default ChartDonut;