
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import './DocumentPreview.scss';
import { ArrowLeftIcon, DownloadIcon } from "@heroicons/react/outline";
import { DeleteIcon } from "assets/icon";
import { dowloadFile, downloadURI } from "utils/commonFun";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import docIcon from 'assets/img/docIcon.png';
import pdfIcon from 'assets/img/pdfIcon.png';
import pngIcon from 'assets/img/pngIcon.png';
import jpegIcon from 'assets/img/jpegIcon.png';
import { IframePdfViewer } from "_shared/PreviewContent/IframePDFViewer";
import resourcesService from "services/resources.service";
import { hasCheckedStream } from "constant/file.constant";
import { useParams } from "react-router-dom";

interface DocumentPreviewProps {
    closeModal: () => void,
    isOpen: boolean,
    data: any,
    onDelete?: () => void,
    isDownload?: boolean
}

export const DocumentPreview = ({ data, isOpen, closeModal, onDelete = undefined, isDownload = true}: DocumentPreviewProps) => {
    const params: {id: string} = useParams();
    const [downloading, setDownloading] = useState(false);
    let TIMER_DOWNLOAD;

    const download = () => {
        if (downloading) {
            return;
        }
        setDownloading(true);
        if (hasCheckedStream(data?.url)) {
            TIMER_DOWNLOAD = setInterval(async () => {
                if (!TIMER_DOWNLOAD) {
                    return;
                }
                const res = await resourcesService.getDownloadUrl(params?.id, data?.object_id);
                if (res?.url) {
                    downloadURI(res?.url, data?.name);
                    setDownloading(false);
                    clearInterval(TIMER_DOWNLOAD);
                }
            }, 5000);
            
        } else {
            dowloadFile(data?.url, data?.name, () => {
                setDownloading(false);
            });
        }
        
    }

    return (<>
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/80" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full justify-center text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full transform text-left align-middle transition-all">
                                <div className="documentPreview-header">
                                    <div className="flex text-white items-center">
                                        <ArrowLeftIcon className="w-7 h-7 mr-2.5 cursor-pointer" onClick={closeModal} />
                                        {(data?.type?.includes('msword') || data?.type?.includes('document')) && <img className="w-8" src={docIcon} />}
                                        {data?.type?.includes('pdf') && <img className="w-8" src={pdfIcon} />}
                                        {data?.type?.includes('png') && <img className="w-8" src={pngIcon} />}
                                        {(data?.type?.includes('jpeg') || data?.type?.includes('jpg')) && <img className="w-8" src={jpegIcon} />}
                                        <span className="documentPreview-name">{data?.name}</span>
                                    </div>
                                    <div className="documentPreview-actions">
                                        {isDownload && 
                                        <button className="w-6 h-6" onClick={download}>
                                            {downloading ? <CircleSpin /> : <DownloadIcon />}
                                        </button>}
                                       {onDelete && 
                                            <button className="w-6 h-6" onClick={onDelete}>
                                                <DeleteIcon />
                                            </button>
                                        }
                                    </div>
                                </div>
                                <div className="documentPreview-content">
                                    {data?.type?.includes('image') && <img src={data?.url || URL.createObjectURL(data)} alt={data?.name} />}
                                    {data?.type?.includes('document') && <iframe className="document" src={`https://view.officeapps.live.com/op/embed.aspx?src=${data?.url ||  URL.createObjectURL(data)}`}  />}
                                    {data?.type?.includes('msword') && <iframe className="document" src={`https://view.officeapps.live.com/op/embed.aspx?src=${data?.url ||  URL.createObjectURL(data)}`}  />}
                                    {data?.type?.includes('pdf') && <IframePdfViewer url={data?.url ||  URL.createObjectURL(data)} />}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    </>)
}