import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Label, TagList, UserAvatarInfo } from '_shared';
import { Settings } from 'components/Library/Settings/Settings';
import workspaceService from 'services/workspace.service';
import { ChevronDownIcon, ExternalLinkIcon } from '@heroicons/react/outline';

import './Information.scss';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { checkPermission } from 'utils/commonFun';
import { Disclosure } from '@headlessui/react';

export const Information = ({ details, onSave, onSaving, missingInformation }) => {
    const params: { id: string; lessonId: string } = useParams();
    const { t: translator } = useTranslation();
    const [isOpenSettings, setIsOpenSettings] = useState(false);
    const [customFields, setCustomFields] = useState([]);
    const userInformation = JSON.parse(localStorage.getItem('user-data'));
    const [isLoadingTags, setIsLoadingTags] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        clearErrors
    } = useForm({
        mode: 'onBlur',
        defaultValues: useMemo(() => {
            return {
                title: details?.title,
                lesson_goal: details?.lesson_goal,
            };
        }, [details?.title]),
    });

    const handleUpdateTag = async (value) => {
        setCustomFields(value);
        await workspaceService.updateCustomFieldByTypeService(params.id, {
            custom_fields: value.map((item) => ({ custom_field_id: item.id, ...item })),
            id: params.lessonId,
            type: 'lessons',
        });
    };

    const getTags = async () => {
        setIsLoadingTags(true);
        const response: any = await workspaceService.getCustomFieldByTypeService(params.id, {
            order: 'desc',
            sort_by: 'created_at',
            skip_pagination: true,
            type: 'lessons',
            id: params.lessonId,
        });
        if (response) {
            setCustomFields(response?.data || []);
        }
        setIsLoadingTags(false);
    };

    const onSubmit = async (data) => {
        onSave(data);
    };

    const watchTitle: any = watch('title');

    const resizeTextArea = () => {
        const textElm = document.getElementById('lesson_goal');
        textElm.style.height = 'auto';
        textElm.style.height = textElm.scrollHeight + 'px';
    };

    useEffect(() => {
        if (params?.lessonId !== 'new') {
            getTags();
        }
    }, [params?.lessonId]);

    useEffect(() => {
        setValue('title', details?.title);
        setValue('lesson_goal', details?.lesson_goal);
        resizeTextArea();
    }, [details]);

    const handleEmptyTitle = () => {
        if (!watch('title')) {
            setValue('title', details?.title);
            clearErrors('title');
        }
    }

    return (
        <div className="">
            <form
                onSubmit={(e) =>
                    handleSubmit(onSubmit)(e).finally(() => handleEmptyTitle())
                }
            >
                <div className="information-item">
                    <Label className="information-label !text-xs">{translator('Lesson Name')}</Label>
                    <input
                        id="title"
                        placeholder={translator('Enter Lesson Name')}
                        {...register('title', {
                            required: true,
                            maxLength: 255,
                            onBlur: (e) =>
                                handleSubmit(onSubmit)(e).finally(() => handleEmptyTitle()),
                        })}
                        className="information-input"
                        style={{ width: watchTitle?.length + 'ch' }}
                        maxLength={255}
                        disabled={!checkPermission('libraryContentLessonsOrScorm', 'U')}
                    />
                    {((errors.title && errors.title.type) || missingInformation) === 'required' && (
                        <span className="error-title">{translator('Lesson Name is required')}</span>
                    )}
                    {errors.title && errors.title.type === 'maxLength' && (
                        <span className="error-title">{translator('Max length exceeded')}</span>
                    )}
                </div>
                <div className="information-item">
                    <Label className="information-label !text-xs">{translator('Lesson Goal')}</Label>
                    <textarea
                        id="lesson_goal"
                        placeholder={translator('Enter Lesson Goal')}
                        {...register('lesson_goal', {
                            maxLength: 255,
                            onBlur: handleSubmit(onSubmit),
                        })}
                        className="information-textarea"
                        onChange={resizeTextArea}
                        maxLength={255}
                        disabled={!checkPermission('libraryContentLessonsOrScorm', 'U')}
                    />
                    {errors.lesson_goal && errors.lesson_goal.type === 'maxLength' && (
                        <div className="error-title">{translator('Max length exceeded')}</div>
                    )}
                </div>
                <div className="information-item">
                    <Label className="information-label !text-xs mb-0.5">{translator('Owner')}</Label>
                    <UserAvatarInfo userInformation={details?.created_by || userInformation} />
                </div>
            </form>
            <div className="information-disclosure">
                <Disclosure defaultOpen>
                    {({ open }) => (
                        <>
                            <div className="flex items-center justify-between mb-0.5">
                                <Label className="information-label">{translator('ASSOCIATED_LEARNING_PATH_S')}</Label>
                                <Disclosure.Button className="">
                                    {/* <ChevronDownIcon className={`h-3.5 w-3.5 text-gray-500 ${open ? '-rotate-90 transform' : ''}`} /> */}
                                </Disclosure.Button>
                            </div>
                            <Disclosure.Panel className="">
                                <p className="text-gray-500 text-xs">{translator(!!details?.associatedLearningPath?.length ?'ASSOCIATED_LEARNING_PATH_MESSAGE' : 'ASSOCIATED_LEARNING_PATH_NO_DATA')}</p>
                                {!!details?.associatedLearningPath?.length && (
                                    <div className="mt-1 flex flex-col space-y-1 max-h-[150px] overflow-auto">
                                        {details?.associatedLearningPath.map((item, index) => (
                                            <p 
                                                key={index} 
                                                className={`text-xs font-semibold flex items-center space-x-1 ${checkPermission('learningPath', 'U') ? 'text-enterprise cursor-pointer' : 'text-gray-800'}`} 
                                                onClick={() => {
                                                    if (checkPermission('learningPath', 'U')) {
                                                        window.open(`/workspace/${params?.id}/learning-path/${item?.id}`, '_blank');
                                                    }
                                                }}
                                            >
                                                <span className="mb-0.5">●</span>
                                                <span>{item?.title}</span>
                                            </p>
                                        ))}
                                    </div>
                                )}
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            </div>
            <div className="information-info">
                <div className="flex justify-between items-center mb-0.5">
                    <Label className="information-label">{translator('CUSTOM_TAGS')}</Label>
                    <div className='flex-none'>
                        <button 
                            onClick={() => setIsOpenSettings(true)} 
                            disabled={!checkPermission('libraryContentSetting', 'C')}
                            className="text-xs text-enterprise flex items-center space-x-1"
                        >
                            {translator(customFields?.length > 0 ? 'EDIT' : 'ADD_NEW')}
                            <ExternalLinkIcon className="text-enterprise w-4 h-4" />
                        </button>
                    </div>
                </div>
                {isLoadingTags ? (
                    <div className="information-loading">
                        <CircleSpin className="text-primary-500" />
                    </div>
                ) : customFields?.length > 0 ? (
                    <TagList
                        customFields={customFields}
                        setCustomFields={handleUpdateTag}
                        loading={false}
                        hasTitle={false}
                        className="noBackground"
                        isEdit={checkPermission('libraryContentLessonsOrScorm', 'U')}
                    />
                ) : (
                    <>
                        {translator('THERE_ARE_NO_CUSTOM_TAGS')}
                    </>
                )}
            </div>
            <Settings
                isOpen={isOpenSettings}
                onClose={() => {
                    setIsOpenSettings(false);
                    getTags();
                }}
            />
        </div>
    );
};
