import { useMemo } from 'react'
import { HANDLE_ACTION_ENUM, INVOICE_STATUS, INVOICE_TYPE } from '../../constants'
import { useParams } from 'react-router-dom'
import workspaceService from 'services/workspace.service'
import { Toastify } from '_shared'
import { checkPermission } from 'utils/commonFun';

export default function Status({status, invoiceId, translator, isShowAction = true}) {
  const params: {id} = useParams()
  const invoiceStatus = useMemo(() => (translator) => {
    return INVOICE_STATUS(translator)
  }, [translator])

  const handleResendInvoice = (action) => {
    const invoice = {
        invoice_id: invoiceId,
        invoice_type: INVOICE_TYPE.single,
        action
    }
    workspaceService.actionInvoice(params?.id, invoice)
    .then(() => {
        Toastify.success(translator('RESEND_INVOICE_SUCCESS'));
    })
    .catch(() => Toastify.error())
}

  return (
    <>
      {status === invoiceStatus(translator).scheduled?.value && <div className='text-orange-500 bg-orange-50 border border-orange-300 px-2 py-[3px] rounded w-fit'>{invoiceStatus(translator)?.scheduled?.label}</div>}
      {status === invoiceStatus(translator).overdue?.value &&
        <div className='flex gap-2 items-center'>
          <div className='text-red-500 bg-red-50 border border-red-300 px-2 py-[3px] rounded w-fit'>{invoiceStatus(translator)?.overdue?.label}</div>
          {isShowAction && <div onClick={() => handleResendInvoice(HANDLE_ACTION_ENUM.resend_invoice)} className='text-primary-500 underline cursor-pointer'>{translator('RESEND')}</div>}
        </div>
      }
      {status === invoiceStatus(translator)?.sent?.value &&
        <div className='flex gap-2 items-center'>
          <div className='text-blue-500 bg-blue-50 border border-blue-300 px-2 py-[3px] rounded w-fit'>{invoiceStatus(translator)?.sent?.label}</div>
          {isShowAction && <div onClick={() => handleResendInvoice(HANDLE_ACTION_ENUM.resend_invoice)} className='text-primary-500 underline cursor-pointer'>{translator('RESEND')}</div>}
        </div>
      }
      {status === invoiceStatus(translator).paid?.value && <div className='text-green-500 bg-green-50 border border-green-300 px-2 py-[3px] rounded w-fit'>{invoiceStatus(translator)?.paid?.label}</div>}
      {status === invoiceStatus(translator).cancelled?.value && <div className='text-gray-500 bg-gray-50 border border-gray-300 px-2 py-[3px] rounded w-fit'>{invoiceStatus(translator)?.cancelled?.label}</div>}
      {status === invoiceStatus(translator).refunded?.value && <div className='text-purple-500 bg-purple-50 border border-purple-300 px-2 py-[3px] rounded w-fit'>{invoiceStatus(translator)?.refunded?.label}</div>}
    </>
  )
}
