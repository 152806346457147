import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { featureURL, objectMenuType, formatPermission } from 'constant/workspace.const';
import { defaultRole } from 'components/Management/RolesPermissions/RolesPermissionsContants';
import { UserService, WorkspaceService } from 'services';

const ManagePermissionWrapper = ({ children }) => {
    const history = useHistory();
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const {
        result: { membership }, permissions
    } = getWorkspaceDetailState;

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const onCheckPermissions = () => {
        let path = window.location.pathname?.split('/')?.[3];
        setIsLoading(false);
        if (Object.keys(objectMenuType)?.find((i) => objectMenuType[i] === path && path !== 'instructor-dashboard')) {
            path = window.location.pathname?.split('/')?.[4];
        };
        if (path) {
            const feature = Object.keys(featureURL).find((item) => featureURL[item] === path);
            const features = Object.keys(featureURL).filter((item) => featureURL[item] === path);
            if (Object?.keys(permissions)?.length) {
                if (feature === 'setting' && membership?.user_role?.name == defaultRole.superAdmin) {
                    setIsLoading(false);
                    return;
                };
                if (feature && (permissions[feature]?.includes('R') || feature == 'instructor' || feature == 'student')) {
                    setIsLoading(false);
                    return;
                } else if (path === 'dashboard-export' && features?.length > 0) {
                    setIsLoading(false);
                    return;
                } else if (!permissions[feature]?.includes('R')) {
                    history.push('/forbidden');
                }
            } else {
                WorkspaceService.getPermissionsUserWorkspace().then((res) => {
                    UserService.getUser().then((userData) => {
                        const format = formatPermission(res?.data);
                        if (feature === 'setting' && userData?.membership?.user_role?.name == defaultRole.superAdmin) {
                            setIsLoading(false);
                            return;
                        };
                        if (feature && (format[feature]?.includes('R') || feature == 'instructor' || feature == 'student')) {
                            setIsLoading(false);
                        } else if (path === 'dashboard-export' && features?.length > 0) {
                            setIsLoading(false);
                        } else if (!format[feature]?.includes('R')) {
                            history.push('/forbidden');
                        }
                    })
                })
            }
        }
        setIsLoading(true);
    }

    useEffect(() => {
        onCheckPermissions();
    }, [])

    return !isLoading && children;
};

export default ManagePermissionWrapper;
