import { FC, useContext, useState, useEffect } from 'react';
import { BookOpenIcon, CheckIcon, PencilAltIcon, XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { openUrl } from 'utils/commonFun';
import { useParams, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import ButtonComponent from 'components/Button/ButtonComponent';
import { useBoolean } from 'hooks/custom';
import { UserContext } from 'contexts/User/UserContext';
import { fetchVcLink } from 'components/Dashboard/util/common';
import { Toast } from 'utils/sweetAlert';
import { HeartIcon } from '@heroicons/react/solid';
import classService from 'services/class.service';
import ModalCancel from './ModalCancel';
import DialogComponent from 'components/Dialog/DialogComponent';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { SCHEDULE_TYPE_EMUM, SESSION_STATUS_ENUM } from 'constant/sessions.const';
import { TeacherDashboardService } from 'services';
import { Toastify } from '_shared';

type SessionDetailsModalProps = {
    open: boolean;
    onCloseModal: () => void;
    sessionId: string;
    refreshData?: () => void;
    title?: string;
    classId?: string
};

dayjs.extend(utc);
dayjs.extend(CustomParseFormat);
dayjs.extend(timezone);

const SessionDetailsModal: FC<SessionDetailsModalProps> = ({ open, onCloseModal, sessionId, refreshData, title , classId}) => {
    const { t: translator } = useTranslation();
    const history = useHistory();
    const params: any = useParams();
    const { booleanValue: modalCancel, toggleBooleanValue: toggleModalCancel } = useBoolean();
    const [isInputVCLink, setIsInputVCLink] = useState<boolean>(false);
    const [vcLink, setVCLink] = useState<string>('');
    const [sessionDetails, setSessionDetails] = useState<any>({})
    const {
        userState: { result },
    } = useContext(UserContext);

    const getSessionDetail = async () => {
        const { data } = await TeacherDashboardService.getSessionDetailService(params?.id, params.classId || classId, sessionId);
        setSessionDetails(data);
        setVCLink(data?.vc_link || data?.my_class?.vc_link);
    };

    useEffect(() => {
       if (sessionId) {
            getSessionDetail();
       }
    }, [sessionId])

    const invalidSession =
        (!sessionDetails?.learning_path && sessionDetails?.schedule_type === SCHEDULE_TYPE_EMUM.BOOKING_SCHEDULE) ||
         dayjs.utc().tz(result?.time_zone)?.valueOf() > dayjs.utc(sessionDetails?.startTimeInit || sessionDetails?.start_time)?.tz(result?.time_zone)?.valueOf() ||
        [
            SESSION_STATUS_ENUM.RESCHEDULED,
            SESSION_STATUS_ENUM.IN_PROGRESS,
            SESSION_STATUS_ENUM.CANCELLED,
            SESSION_STATUS_ENUM.COMPLETED,
        ]?.includes(sessionDetails?.display_status);

    const checkPreferred = () => {
        const isPreferred = sessionDetails?.students?.find((item) =>
            sessionDetails?.instructors[0]?.instructor?.prefer_users?.includes(item?.id)
        );
        return !!isPreferred;
    };

    const handleJoinClass = async (val, event?) => {
        event.stopPropagation();
        const data: any = await fetchVcLink(params?.id, val?.my_class?.id || params?.classId, val.id);
        if (data?.vc_link || sessionDetails?.vc_link) {
            openUrl(data.vc_link || sessionDetails?.vc_link || sessionDetails?.my_class?.vc_link);
        } else {
            Toast.fire({
                icon: 'error',
                title: data.description,
            });
        }
    };

    const [btnJoinClass, setBtnJoinClass] = useState<any>();
    function formatTimeNumber(n) {
        return n < 10 ? '0' + n : n;
    }

    const getTime = (timeRemaining) => {
        const days = Math.floor(timeRemaining / 1000 / 60 / 60 / 24);
        const hours = Math.floor(timeRemaining / 1000 / 60 / 60) % 24;
        let totalHours = hours;
        if (days > 0) {
            totalHours += days * 24;
        }
        return {
            days,
            hours: formatTimeNumber(hours),
            totalHours: formatTimeNumber(totalHours),
            minutes: formatTimeNumber(Math.floor(timeRemaining / 1000 / 60) % 60),
            seconds: formatTimeNumber(Math.round(timeRemaining / 1000) % 60),
        };
    };

    useEffect(() => {
        if (
            open &&
            sessionDetails?.display_status !== SESSION_STATUS_ENUM.CANCELLED &&
            sessionDetails?.display_status !== SESSION_STATUS_ENUM.COMPLETED &&
            sessionDetails?.display_status !== SESSION_STATUS_ENUM.RESCHEDULED
        ) {
            const startTime = sessionDetails?.startTimeInit || sessionDetails?.start_time;
            const endTime = sessionDetails?.endTimeInit || sessionDetails?.end_time;

            const interval = setInterval(function () {
                const currentTime = dayjs.utc().tz(result?.time_zone)?.valueOf();
                const remainStart = dayjs?.utc(startTime)?.tz(result?.time_zone)?.valueOf() - currentTime;
                const { totalHours, minutes, seconds } = getTime(remainStart);
                let isDisable: boolean = true;
                let label: string = 'Join class on  ' + dayjs?.utc(startTime)?.tz(result?.time_zone)?.format('DD/MM/YYYY');
                if (remainStart > 60000 * 10 && remainStart <= 60000 * 24 * 60) {
                    label = 'Start in ' + `${totalHours}:${minutes}:${seconds}`;
                    isDisable = true;
                }
                if (remainStart <= 60000 * 10) {
                    label = 'Start in ' + `${minutes}:${seconds}`;
                    isDisable = false;
                }
                if (
                    currentTime >= dayjs?.utc(startTime)?.tz(result?.time_zone)?.valueOf() &&
                    currentTime < dayjs?.utc(endTime)?.tz(result?.time_zone)?.valueOf()
                ) {
                    label = 'In progress';
                    isDisable = false;
                }
                return setBtnJoinClass({ label, isDisable });
            }, 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [sessionDetails, vcLink, open]);

    const handleChangeVCLink = (e) => {
        setVCLink(e?.target?.value);
    };
    const onUpdateVCLink = () => {
        classService
            .updateSessionsPatch(params.id, params?.classId || sessionDetails?.my_class?.id, sessionDetails.id, {
               vc_link: vcLink,
            })
            .then((res) => {
                refreshData?.();
                Toastify.success();
                setIsInputVCLink(false);
            })
            .catch((err) => {
                Toastify.error();
            });
    };

    return (
        <DialogComponent
            isOpen={open}
            title={
                sessionDetails?.learning_path?.title ||
                title ||
                sessionDetails?.classTitle ||
                sessionDetails?.my_class?.name ||
                '-'
            }
            styles="max-w-lg min-h-[240px]"
            stylesTitle="text-base"
            onCloseModal={() => {
                onCloseModal();
                setIsInputVCLink(false);
                setBtnJoinClass(false);
                setVCLink('');
            }}
            child={
                <div className="w-full">
                    <div>
                        <div className="font-inter mt-2">
                            <div className=" pt-0 align-middle inline-block min-w-full text-ooolab_dark_1">
                                <div className="flex items-center space-x-2">
                                    <span className="flex items-center justify-center h-10 w-10  bg-gray-200 rounded-lg">
                                        <BookOpenIcon className="w-4 rounded-lg text-ooolab_dark_1" />
                                    </span>
                                    <div className=" flex flex-col justify-center">
                                        <span className="text-sm text-gray-900 font-semibold">
                                            {'Event ' +
                                                translator(
                                                    `SELF_BOOKING.${sessionDetails?.learning_path?.type_ || sessionDetails?.type}`
                                                ) +
                                                ' Lesson'}
                                        </span>
                                        <span className="text-xs text-gray-500">
                                            {dayjs
                                                ?.utc(sessionDetails?.startTimeInit || sessionDetails?.start_time)
                                                ?.tz(result?.time_zone)
                                                .format('DD/MM/YYYY')}{' '}
                                            •{' '}
                                            {dayjs
                                                ?.utc(sessionDetails?.startTimeInit || sessionDetails?.start_time)
                                                ?.tz(result?.time_zone)
                                                .format('HH:mm')}{' '}
                                            -{' '}
                                            {dayjs
                                                ?.utc(sessionDetails?.endTimeInit || sessionDetails?.end_time)
                                                ?.tz(result?.time_zone)
                                                .format('HH:mm')}
                                        </span>
                                    </div>
                                </div>

                                <div className="text-xs  mt-5 grid grid-cols-3 gap-1 text-gray-800">
                                    <span className="font-semibold col-span-1">{translator('LESSON.TITLE')}: </span>
                                    <div className="col-span-2">
                                        {sessionDetails?.lesson?.title || sessionDetails?.my_lesson?.title || '-'}
                                    </div>
                                </div>

                                <div className="text-xs  mt-3 grid grid-cols-3 gap-1 text-gray-800">
                                    <span className="font-semibold col-span-1">{translator('SELF_BOOKING.INSTRUCTOR')}: </span>
                                    <div className="col-span-2">
                                        {sessionDetails?.instructors?.length > 0 ? (
                                            <div
                                                className={`${
                                                    sessionDetails?.instructors?.length > 1 ? 'grid grid-cols-2 gap-2' : ''
                                                } `}
                                            >
                                                {sessionDetails?.instructors?.map((instructor, index) => (
                                                    <div
                                                        onClick={() =>
                                                            window.open(
                                                                `/workspace/${params?.id}/management/users/${instructor?.id}`,
                                                                '_blank'
                                                            )
                                                        }
                                                        className="flex items-center text-primary-500 cursor-pointer"
                                                        key={`instructor-${index}`}
                                                    >
                                                        <div
                                                            className={`rounded-full mr-1 flex items-center justify-center bg-gray-100 relative ${
                                                                instructor?.preferred || checkPreferred()
                                                                    ? 'border border-red-500 '
                                                                    : ''
                                                            }`}
                                                        >
                                                            <img
                                                                src={instructor?.avatar_url}
                                                                alt="avatar"
                                                                className="rounded-full w-5 h-5"
                                                            />
                                                            {(instructor?.preferred || checkPreferred()) && (
                                                                <HeartIcon className="w-4 h-4 absolute -right-1 -bottom-1 text-red-500" />
                                                            )}
                                                        </div>
                                                        {instructor?.display_name}{' '}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            '-'
                                        )}
                                    </div>
                                </div>

                                <div className="text-xs  mt-3 grid grid-cols-3 gap-1 text-gray-800">
                                    <span className="font-semibold mr-2 col-span-1">{translator('SELF_BOOKING.STUDENT')}: </span>
                                    <div className="col-span-2">
                                        {sessionDetails?.students?.length > 0 ? (
                                            <div className={`${sessionDetails?.students?.length > 1 ? 'grid grid-cols-2 gap-2' : ''}`}>
                                                {sessionDetails?.students?.map((student, index) => (
                                                    <div
                                                        onClick={() =>
                                                            window.open(
                                                                `/workspace/${params?.id}/management/users/${student?.id}`,
                                                                '_blank'
                                                            )
                                                        }
                                                        key={`student-${index}`}
                                                        className="flex items-center cursor-pointer"
                                                    >
                                                        <img
                                                            src={student?.avatar_url}
                                                            alt="avatar"
                                                            className="rounded-full w-5 h-5 mr-1"
                                                        />
                                                        <div className="truncate text-ellipsis text-primary-500">
                                                            {student?.display_name}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            '-'
                                        )}
                                    </div>
                                </div>

                                {
                                    <div className="text-xs  mt-3 grid grid-cols-3 gap-1 text-gray-800">
                                        <span className="font-semibold mr-2 col-span-1 flex items-center">VC Link:</span>
                                        <div className="col-span-2 flex items-center">
                                            <div className={`${isInputVCLink ? 'w-full' : 'w-4/5'}`}>
                                                <input
                                                    id="vc_link"
                                                    type="text"
                                                    disabled={!isInputVCLink || invalidSession}
                                                    className="w-full pr-2 py-1  rounded border focus:border-blue-500 disabled:border-none disabled:bg-white border-blue-500"
                                                    name="vc_link"
                                                    onChange={(e) => {
                                                        handleChangeVCLink(e);
                                                    }}
                                                    defaultValue={'-'}
                                                    value={vcLink}
                                                    placeholder="Enter VC link"
                                                />
                                                {isInputVCLink && !invalidSession && (
                                                    <div className="flex items-center justify-end">
                                                        <button
                                                            type="button"
                                                            disabled={!vcLink?.length}
                                                            className="p-1 rounded shadow-ooolab_box_shadow_container bg-white mr-1 disabled:cursor-not-allowed"
                                                            onClick={onUpdateVCLink}
                                                        >
                                                            <CheckIcon
                                                                className={`w-4 h-4  ${
                                                                    vcLink?.length ? 'text-primary-500 cursor-pointer' : 'text-dark-100'
                                                                }`}
                                                            />
                                                        </button>
                                                        <div className="p-1 rounded shadow-ooolab_box_shadow_container bg-white">
                                                            <XIcon
                                                                className="w-4 h-4 text-dark-800 cursor-pointer"
                                                                onClick={() => {
                                                                    setIsInputVCLink(false);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {!invalidSession && (
                                                <div className="w-1/5 flex justify-end">
                                                    <PencilAltIcon
                                                        className="w-4 h-4 text-ooolab_dark_1"
                                                        onClick={() => {
                                                            setIsInputVCLink(true);
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        {!!sessionDetails && (
                            <div className="grid grid-cols-2 gap-3 mt-4">
                                <button
                                    className="text-red-500 border border-red-500 bg-white rounded py-2 text-xs disabled:bg-gray-400 disabled:!border-gray-300 disabled:!text-white disabled:cursor-not-allowed"
                                    type="button"
                                    onClick={toggleModalCancel}
                                    disabled={
                                        !sessionDetails?.students?.length || !sessionDetails?.students ||
                                        invalidSession                                     
                                    }
                                >
                                    {translator('SELF_BOOKING.CANCEL_BOOKING')}
                                </button>
                                <button
                                    className="text-white bg-enterprise rounded py-2 text-xs disabled:!bg-gray-400"
                                    type="button"
                                    disabled={
                                        sessionDetails?.schedule_type === SCHEDULE_TYPE_EMUM.MANUAL_SCHEDULE ||
                                        sessionDetails?.schedule_type === SCHEDULE_TYPE_EMUM.CLASS_SCHEDULE ||
                                        invalidSession ||
                                        !sessionDetails?.students?.length
                                    }
                                    onClick={() => {
                                        history.push(`/workspace/${params?.id}/management/booking-class/add-booking`, {
                                            ...sessionDetails,
                                            start_time: sessionDetails?.startTimeInit || sessionDetails?.start_time,
                                            end_time: sessionDetails?.endTimeInit || sessionDetails?.end_time,
                                        });
                                    }}
                                >
                                    {translator('SELF_BOOKING.RESCHDULE')}
                                </button>
                            </div>
                        )}

                        {!sessionDetails?.vc_link && !sessionDetails?.my_class?.vc_link && !vcLink && !invalidSession && (
                            <div className="mt-4">
                                <ButtonComponent
                                    title={'VC link is not available, Please update.'}
                                    classStyle={`text-xs primary-button !rounded w-full flex justify-center  `}
                                    disable={true}
                                />
                            </div>
                        )}

                        {btnJoinClass && (sessionDetails?.vc_link || sessionDetails?.my_class?.vc_link || vcLink) && (
                            <div className="mt-4">
                                <ButtonComponent
                                    title={btnJoinClass.label}
                                    classStyle={`text-xs primary-button !bg-enterprise disabled:!bg-gray-400 !rounded w-full flex justify-center`}
                                    disable={btnJoinClass?.isDisable}
                                    onClickButton={(e) => handleJoinClass(sessionDetails, e)}
                                />
                            </div>
                        )}
                    </div>
                    <ModalCancel
                        isOpen={modalCancel}
                        onCloseModal={toggleModalCancel}
                        sessionDetails={sessionDetails}
                        refreshData={() => {
                            refreshData();
                            onCloseModal();
                        }}
                    />
                </div>
            }
        />
    );
};

export default SessionDetailsModal;
