import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './../store';
import { UploadState } from 'types/Library.type';

interface LibraryState {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    statusUpload?: 'init' | 'start' | 'finished';
    error?: any;
    fileList?: any[];
    uploadStates?: UploadState[];
}
const initialState: LibraryState = {
    loading: 'idle',
    statusUpload: 'init',
};

export const librarySlice = createSlice({
    name: 'library',
    initialState,
    reducers: {
        libraryUploadFilePicker: (state, action: PayloadAction<any[]>) => {
            state.fileList = action.payload;
            state.statusUpload = 'start';
            state.uploadStates = action.payload.map((item) => ({
                status: 'pending',
                loading: true,
                name: item?.name,
            }));
        },
        setUploadStates: (state, action: PayloadAction<UploadState[]>) => {
            state.uploadStates = action?.payload;
        },
        clearStatus: (state) => {
            state.statusUpload = 'init';
        },
    },
    extraReducers: (builder) => {},
});

export const { libraryUploadFilePicker, clearStatus, setUploadStates } = librarySlice.actions;

export const selectLibrarySlice = (state: RootState) => state.library;

export default librarySlice.reducer;
