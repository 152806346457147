import { FC, useState, useEffect, useMemo } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './AssignedPermissions.scss'
import { useForm } from 'react-hook-form';
import { InputField, TextareaField } from 'components/InputForm/InputForm';
import { ButtonPrimary, Toastify } from '_shared';
import { defaultRole, PERMISSIONS_FEATURE, PERMISSIONS_INSTRUCTOR_DASHBOARD, PERMISSIONS_INSTRUCTOR_FEATURE, roleType } from '../../RolesPermissionsContants';
import FeatureExtend from './FeatureExtend';
import rolesPermissionsService from 'services/rolesPermissions.service';

type PermissionObject = {
    [key: string]: string;
};

interface AssignedPermissionsProps {
    data: any;
    isDisable: boolean;
    roleId?: any;
    submitted: any;
}

const AssignedPermissions: FC<AssignedPermissionsProps> = ({ data, isDisable, roleId, submitted }) => {
    const {
        handleSubmit,
        control,
        formState: { errors, isValid, isDirty },
        watch,
        reset,
    } = useForm({ mode: 'onChange' });
    const params: any = useParams();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const permissionsToRemove = ['read_syllabus', 'read_course_resource', 'write_syllabus', 'write_course_resource'];

    const onSubmit = (values) => {
        setIsLoading(true);
        let features = [];
        let isCustomInstructor = false;
        Object.keys(values)?.map(key => {
            if (values[key] && values[key]?.split('_')[0] !== 'noPermission') {
                if (values[key]?.split('_')[0] === 'write') {
                    features.push(values[key]?.replace("write", "read"));
                }
                features.push(values[key])
            }
            if (values[key] === 'noPermission_course') {
                isCustomInstructor = true;
            }
        });
        if (data?.role_type === roleType.custom && !isCustomInstructor) {
            features = features.filter(feature => !permissionsToRemove.includes(feature));
            if (features?.includes('read_course') && !features?.includes('write_course')) features.push('read_syllabus', 'read_course_resource')
            else if (features?.includes('write_course')) features.push('read_syllabus', 'read_course_resource', 'write_syllabus', 'write_course_resource')
        }

        if (isCustomInstructor) {
            features = features.filter(feature => !permissionsToRemove.includes(feature));
        }

        rolesPermissionsService.patchDetailsRoles(params?.id, params?.roleId || roleId, {
            permissions: features,
        }).then((res) => {
            Toastify.success();
            submitted();
        })
            .catch((err) => {
                Toastify.error();
            }).finally(() => {
                setIsLoading(false);
            });

    }

    useEffect(() => {
        const formattedPermissions: PermissionObject = data?.permissions?.reduce((acc, permission) => {
            const feature = permission?.split('_')?.slice(1)?.join('_');
            acc[feature] = permission;
            return acc;
        }, {});
        reset(formattedPermissions)
    }, [data])

    const isCheckAll = (listFeature) => {
        if (isDisable) {
            const isCheck = listFeature?.find((item) => Object?.values(data?.permissions)?.find((per: any) => per?.includes(item?.value)));
            if (isCheck && Object?.keys(isCheck)) {
                return true
            }
            return false
        }
        return false
    }

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`ROLES_PERMISSIONS.${str}`, opt));

    const isCustomHidePermission = useMemo(() => {
        if ((data?.name === defaultRole.editor || data?.name === defaultRole.viewer || data?.name === defaultRole.teachingAssistant) && data?.role_type === roleType.default) {
            return true;
        }
        return false
    }, [data])
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="">

                <div className='assignedPermissions'>
                    <div className='assignedPermissions-header'>
                        <h1>{translator('ASSIGNED_PERMISSIONS')}</h1>
                        {!isDisable && <ButtonPrimary disabled={!isValid || !isDirty || isLoading} className={`${isLoading ? 'bg-ooola_EFF0FA text-ooolab_8083A3 cursor-not-allowed' : ''}`} type="submit">{t('SAVE')}</ButtonPrimary>}
                    </div>
                    <main className='assignedPermissions-main'>
                        <div className='assignedPermissions-feature'>
                            <div className='assignedPermissions-featureName'>
                                <h2>{translator('FEATURE')}</h2>
                            </div>
                            {isDisable ? <div className='assignedPermissions-permissions'>
                                <h2>{translator('PERMISSIONS')}</h2>
                            </div> : <>
                                <div className='assignedPermissions-readEdit'>
                                    <h2>{translator('READ_EDIT')}</h2>
                                </div>
                                <div className='assignedPermissions-readOnly'>
                                    <h2>{translator('READ_ONLY')}</h2>
                                </div>
                                <div className='assignedPermissions-noPermission'>
                                    <h2>{translator('NO_PERMISSION')}</h2>
                                </div></>}

                        </div>
                        <div className='assignedPermissions-featureList'>
                            {
                                (data?.name === defaultRole.instructor && data?.role_type === roleType.default) ? PERMISSIONS_INSTRUCTOR_FEATURE(translator)?.map((feature) =>
                                    (isCheckAll(feature?.items) && isDisable) || !isDisable ? <div className='listExtend' key={feature?.value}>
                                        <FeatureExtend featureFor={feature?.value} listFeature={feature?.items} permissions={data?.permissions} name={feature?.label || ''} control={control} watch={watch} isDisable={isDisable} feature={feature} />
                                    </div> : ''
                                ) : PERMISSIONS_FEATURE(translator)?.map((feature) =>
                                    (isCheckAll(feature?.items) && isDisable) || !isDisable ? <div className='listExtend' key={feature?.value}>
                                        <FeatureExtend featureFor={feature?.value} listFeature={feature?.items} permissions={data?.permissions} name={feature?.label || ''} control={control} watch={watch} isDisable={isDisable} isCustomHidePermission={isCustomHidePermission} />
                                    </div> : ''
                                )
                            }
                        </div>
                    </main>
                </div>
            </form>
        </>
    );
};

export default AssignedPermissions;
