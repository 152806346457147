import FullCalendar from '@fullcalendar/react';
import { PlusIcon } from '@heroicons/react/outline';
import { ButtonOutline, ButtonPrimary } from '_shared';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from 'contexts/User/UserContext';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { SessionServiceFormat } from 'constant/util.const';
import '_shared/components/Calendar/Calendar.scss';
import MultipleSession from './components/MultipleSession';
import ModalSessionDetail from './components/ModalSessionDetail/ModalSessionDetail';
import AloneSession from './components/AloneSession';
import courseService from 'services/course.service';
import { Popover } from '@headlessui/react';
import ModalNewSession from './components/ModalNewSession/ModalNewSession';
import { SessionFilter } from './components/SessionFilter/SessionFilter';
import { SettingsIcon } from 'assets/icon/SettingIcon';
import { useAppSelector } from 'hooks/hooks';
import { checkPermission } from 'utils/commonFun';
import DialogComponent from 'components/Dialog/DialogComponent';
import { useTranslation } from 'react-i18next';

const headerToolbar = {
    left: 'today prev,next title',
    right: 'timeGridDay,timeGridWeek,dayGridMonth',
};
/**
 * hasFilterLearner = true
 *      Course Detail Sessions -> Calendar
 * hasFilterLearner = false
 *      Course Detail People -> Enrollments -> Enrollments detail -> Calendar
 */
export default function Calendar({ hasFilterLearner = true, learnerId = null, hasGroupLearner = false }) {
    const history = useHistory();
    const { data: detail, setting} = useAppSelector((state) => state.course);
    const {t} = useTranslation();
    const [eventsCalendar, setEventsCalendar] = useState<any[]>();
    const {
        userState: { result },
    } = useContext(UserContext);
    const calendarRef: any = useRef<FullCalendar>(null!);
    // expandedMenu
    const { expandedMenu } = useAppSelector(state => state.configPage)
    const [calendarApi, setCalendarApi] = useState<any>();
    const [isOpenModalSelectOptionCreateSession, setOpenModalSelectOptionCreateSession] = useState(false)

    const [isFirstLoad, setFirstLoad] = useState(true);

    const [paramsCalendar, setParamsCalendar] = useState<any>({
        skip_pagination: true,
    });
    const [filter, setFilter] = useState<any>({});
    const [viewType, setViewType] = useState('');
    const [loading, setLoading] = useState(false);
    const params = useParams<{ id: string; courseId: string }>();
    const workspaceId = params?.id;
    const courseId = params?.courseId;

    const [sessionDetail, setSessionDetail] = useState<any>(undefined);
    const [isOpenModalViewSessionDetail, setOpenModalViewSessionDetail] = useState(false);
    const [isOpenModalNewSession, setOpenModalNewSession] = useState(false);

    useEffect(() => {
        if (calendarApi) {
            calendarApi?.changeView(viewType);
        }
    }, [expandedMenu]);

    useEffect(() => {
        if (calendarRef) {
            setCalendarApi(calendarRef.current.getApi());
        }
    }, [calendarRef]);

    const getSessions = async (params?: any) => {
        if (!(paramsCalendar?.start_after && paramsCalendar?.end_before)) return;
        setLoading(true);

        let paramsRequest: any = {};

        if (!hasFilterLearner) {
            paramsRequest = {
                ...params,
                learner_ids: learnerId,
                learning_group_ids: learnerId
            };
            if (!paramsRequest?.learner_ids || hasGroupLearner) delete paramsRequest?.learner_ids;
            if (!paramsRequest?.learning_group_ids || !hasGroupLearner) delete paramsRequest?.learning_group_ids;
        } else {
            paramsRequest = {
                ...params,
            };
            if (!paramsRequest?.learner_ids) delete paramsRequest?.learner_ids;
            if (!paramsRequest?.learning_group_ids) delete paramsRequest?.learning_group_ids;
        }

        const res = await courseService.getSessions(workspaceId, courseId, paramsRequest, true);
        setLoading(false);
        setFirstLoad(false);
        return res?.data;
    };

    const fetchEvents = async (params) => {
        try {
            const resCalendar = await getSessions(params);
            setEventsCalendar(
                resCalendar?.items?.filter(item => item?.status !== 'rescheduled').map((item) => {
                    return {
                        ...item,
                        start: dayjs(item?.start_time).tz(result?.time_zone).format(SessionServiceFormat),
                        end: dayjs(item?.end_time).tz(result?.time_zone)?.format(SessionServiceFormat),
                        initData: item
                    };
                }) || []
            );
        } catch (error) {

        }

    };

    const renderDayHeaderContent = ({ date, isToday, text, view, ...props }) => {
		if (view.type === 'dayGridMonth') return <span className='text-gray-500'>{dayjs(date).format('ddd')}</span>;
		return (
			<>
				<p className='text-gray-500'>{dayjs(date).format('ddd')}</p>
				<p className={`header-day ${isToday ? 'header-today' : ''}`}>{dayjs(date).format('DD')}</p>
			</>
		);
	};

    useEffect(() => {
        fetchEvents({ ...paramsCalendar, ...filter });
    }, [paramsCalendar, filter]);

    const handleFilter = (filter) => {
        setFilter(filter);
    };

    return (
        <div>
            <div className="flex justify-between py-4">
                {hasFilterLearner ? <SessionFilter onChangeFilter={handleFilter} isFirstLoad={isFirstLoad} /> : <div></div>}
                {(checkPermission('coursePeopleEnrollment', 'U') || checkPermission('courseScheduling', 'U')) && (
                    <ButtonPrimary type="button"
                    onClick={() => { 
                        if (setting.enrollment_type !== 'credit') {
                            setOpenModalNewSession(true)
                        }
                        else {
                            setOpenModalSelectOptionCreateSession(true)
                        }
                    }}
                    disabled={detail?.status == 'inactive' || setting?.schedule_type == 'no_schedule'}
                >
                    {t("SESSION.BOOK_SESSIONS")}
                </ButtonPrimary>
                )}
            </div>

            {
                isOpenModalSelectOptionCreateSession && (
                    <DialogComponent
                        title={t("SESSION.BOOK_SESSIONS")}
                        styles='max-w-lg'
                        isOpen={isOpenModalSelectOptionCreateSession}
                        onCloseModal={() => setOpenModalSelectOptionCreateSession(false)}
                        child={
                            <div className='mt-4'>
                                <div className='text-sm flex flex-col gap-3'>
                                <p>{t("Do you want to book Session(s) based on Instructors' Available Slots?")}</p>
                                <p className='text-gray-500'>{t("Please note that credits of the learners will be used for booking sessions in this course.")}</p>
                                </div>
                                <div className='flex justify-center gap-5 mt-5'>
                                    <ButtonOutline 
                                        type="button" 
                                        onClick={() => {
                                            setOpenModalNewSession(true)
                                            setOpenModalSelectOptionCreateSession(false)
                                        }}
                                    >{t("No, skip availability check")}</ButtonOutline>
                                    <ButtonPrimary type="button" onClick={() => history.push(`/workspace/${params?.id}/management/courses/${params?.courseId}/booking`)}>Yes, check available slots</ButtonPrimary>
                                </div>
                            </div>
                        }
                    />
                )
            }

            <div className="Calendar relative">
                {viewType !== 'dayGridMonth' && (
                    <div className="absolute w-[48px] text-center top-[85px] left-0 text-sm">
                        <button
                            type="button"
                            className="text-primary-500 hover:opacity-80 block text-center m-auto z-1"
                            onClick={() => window.open(`/workspace/${workspaceId}/user-setting`, '_blank')}
                            title={''}
                        >
                            <SettingsIcon />
                        </button>
                        <span className="text-xs">UTC{dayjs(new Date()).tz(result?.time_zone).format('Z').slice(0, 3)}</span>
                    </div>
                )}
                {
                    /*@ts-ignore*/
                    <FullCalendar
                        ref={calendarRef}
                        allDaySlot={false}
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        headerToolbar={headerToolbar}
                        eventClick={(data) => {
                            setSessionDetail([data?.event]);
                            setOpenModalViewSessionDetail(true);
                        }}
                        events={eventsCalendar}
                        eventContent={(eventInfo) => {                            
                            return (
                                <AloneSession
                                    id={eventInfo?.event?.id}
                                    startTime={eventInfo?.event?.extendedProps?.start_time}
                                    endTime={eventInfo?.event?.extendedProps?.end_time}
                                />
                            );
                        }}
                        locale="en"
                        dayMaxEvents={1}
                        forceEventDuration={true}
                        moreLinkContent={(content: any) => {
                            if (content?.num === 0) return;
                            return <MultipleSession numberOfSession={content?.num} />;
                        }}
                        slotLabelFormat={{
                            hour: '2-digit',
                            minute: '2-digit',
                            omitZeroMinute: false,
                            meridiem: false,
                            hour12: false
                        }}
                        timeZoneParam={result?.time_zone}
                        dayHeaderFormat={{ weekday: 'short' }}
                        dayHeaderContent={renderDayHeaderContent}
                        moreLinkClick={(value: any) => {
                            setSessionDetail(value?.allSegs?.map((item) => item?.event));
                            setOpenModalViewSessionDetail(true);
                        }}
                        timeZone={result?.time_zone}
                        datesSet={(arg) => {
                            const { startStr, endStr, view } = arg;
                            setParamsCalendar({
                                start_after: dayjs(startStr).format(),
                                end_before: dayjs(endStr).format(),
                                skip_pagination: true,
                            });
                            setViewType(view?.type);
                        }}
                        nowIndicator
                        slotMinTime="06:00:00"
                        slotMaxTime="30:00:00"
                    />
                }
            </div>

            {isOpenModalViewSessionDetail && (
                <ModalSessionDetail
                    sessions={sessionDetail}
                    isOpen={isOpenModalViewSessionDetail}
                    refreshData={() => fetchEvents(paramsCalendar)}
                    onClose={() => {
                        setOpenModalViewSessionDetail(false);
                        setSessionDetail(undefined);
                    }}
                />
            )}

            {isOpenModalNewSession && (
                <ModalNewSession
                    isOpen={isOpenModalNewSession}
                    refreshData={() => fetchEvents(paramsCalendar)}
                    onClose={() => setOpenModalNewSession(false)}
                />
            )}
        </div>
    );
}
