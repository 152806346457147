import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PanelSetting } from "../PanelSetting/PanelSetting";
import { useForm } from "react-hook-form";
import { InputFile } from "_shared/components/Form/InputFile/InputFile";
import { SelectField } from "components/Select";
import { typeOption } from "components/Academics/LearningPath/LearningPathDetail/learningPath.consts";
import { InputField, TextareaField } from "components/InputForm/InputForm";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { ButtonOutline, ButtonPrimary, Toastify } from "_shared";
import { useParams } from "react-router-dom";
import { courseUpdate } from "store/features/courseSlice";
import { DeleteIcon, VideoUpload } from "assets/icon";
import uploadService from "services/upload.service";
import "./CourseInformation.scss";
import { checkPermission, createOption } from "utils/commonFun";
import { CourseFieldData } from "../CourseFieldData/CourseFieldData";
import { ACCEPT_FILE_NONE_MIME_TYPE, VIDEO_TYPES } from "constant/form.const";
import { GetWorkspaceContext } from "contexts/Workspace/WorkspaceContext";
import { defaultRole, roleType } from "components/Management/RolesPermissions/RolesPermissionsContants";
import { VideoPreview } from "_shared/components/DocumentPreview/VideoPreview";
import DialogComponent from "components/Dialog/DialogComponent";
import ChangeVideoIcon from "assets/icon/ChangeVideoIcon";
import PlayVideoIcon from "assets/icon/PlayVideoIcon";
import Spinner from "components/Spinner";
import workspaceService from "services/workspace.service";

export const CourseInformation = () => {
    const params: { id: string, courseId: string } = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { data } = useAppSelector((state) => state.course);
    const refUploadVideo = useRef(null);
    const [isOpenModalViewVideo, setOpenModalViewVideo] = useState(false);
    const [isOpenModalRemoveVideo, setOpenModalRemoveVideo] = useState(false);
    const [loadingVideo, setLoadingVideo] = useState(false);

    const translatorlearning = (str: string, newKey?: boolean, opt?: any) => {
        return newKey ? t(str, opt) : t(`LEARNING_PATH.LEARNING_PATH_DETAIL.${str}`, opt)
    }

    const [hasSetting, setHasSetting] = useState(false);
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        reset,
        watch
    } = useForm({
        mode: "onSubmit",
        defaultValues: useMemo(() => {
            return {
                title: data?.title,
                short_description: data?.short_description,
                type: null,
                level: [],
                subject: [],
                age: [],
                language_delivery: [],
                duration: [],
                tags: [],
                id: null
            }
        }, [data])
    })

    const { getWorkspaceDetailState } = useContext(
        GetWorkspaceContext
    );
    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;

    const onSubmit = async (value) => {
        dispatch(courseUpdate({
            workspaceId: params?.id,
            id: params?.courseId,
            title: value?.title,
            short_description: value?.short_description
        }));
    }

    const handleAddTags = (name, values) => {
        setValue(name, values);
    }

    const checkImageUpload = (file: any) => {
        const mimeType = VIDEO_TYPES;

        const fileSize = file?.size || 0;
        let fileExtenion = file?.type || '';
        const fileName = file?.name;
        const fileExtension = fileName?.split('.').pop();

        for (const mimeType in ACCEPT_FILE_NONE_MIME_TYPE ) {
            if (ACCEPT_FILE_NONE_MIME_TYPE[mimeType].includes(fileExtension)) {
                fileExtenion = mimeType;
                break;
            }
        }
        const fileModify = new File([file], file.name, {
            type: fileExtenion
        });
        
        if (!mimeType.includes(fileExtenion) || +(fileSize/(1024*1024)) > 100) {
            Toastify.error(t('COURSES.WARNING_VIDEO'))
        } else {
            setLoadingVideo(true)
            uploadService.uploadFile(
                fileModify,
                async (path: string) => {
                    setTimeout(() => {
                        dispatch(courseUpdate({
                            workspaceId: params?.id,
                            id: params?.courseId,
                            video: path
                        }))
                        setLoadingVideo(false)
                    }, 10000)
                },
                () => { }
            );
        }
    };

    useEffect(() => {
        if (data?.title) {
            let typeSelect = [];
            if (data?.type?.length > 0) {
                typeSelect = typeOption(translatorlearning).filter(item => data?.type?.find(value => value == item?.value));
            }
            reset({
                ...data,
                type: typeSelect,
                level: data?.level?.map(item => createOption({ name: item, id: item })),
                subject: data?.subject?.map(item => createOption({ name: item, id: item })),
                age: data?.age?.map(item => createOption({ name: item, id: item })),
                language_delivery: data?.language_delivery?.map(item => createOption({ name: item, id: item })),
                duration: data?.duration?.map(item => createOption({ name: item, id: item })),
                tags: data?.tags?.map(item => createOption({ name: item, id: item }))
            });
        }
    }, [data?.title, t]);

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === "type" && value?.type?.length > 0) {
                dispatch(courseUpdate({
                    workspaceId: params?.id,
                    id: params?.courseId != 'new' ? params?.courseId : value?.id,
                    type: value?.type?.map(item => item.value),
                    title: value?.title,
                    description: value?.short_description
                }));
            }
            if (['level', 'subject', 'age', 'language_delivery', 'duration', 'tags'].includes(name)) {
                const payload = {
                    workspaceId: params?.id,
                    id: params?.courseId,
                }
                payload[name] = value[name]?.map(item => item?.label ?? item);
                dispatch(courseUpdate(payload));
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, params?.courseId]);

    const onClickUpload = () => {
        refUploadVideo.current?.click();
    }

    //example
    const getListProperties = async () => {
        const res = await workspaceService.getListPropertiesService(params?.id, { page: 1, per_page: 100, object_type: 'course', skip_parent: true });
        return {
            items: res?.data?.items,
            total: res?.data?.total,
        };
    }

    const [properties, setProperties] = useState<any>([]);
    const [isViewAllProperties, setIsViewAllProperties] = useState(false);

    useEffect(() => {
        getListProperties().then((res) => {
            setProperties(res?.items)
        })
    }, [])

    useEffect(() => {
        if (isViewAllProperties) {
            getDefaultProperties();
        }
    }, [isViewAllProperties])

    const getDefaultProperties = () => {
        const defaultProperties = properties?.filter((item: any) => item?.is_visible === true)
        return setProperties(defaultProperties)
    }

    return (
        <div className="courseInformation">
            <div className="courseBuilder-title">{t('COURSES.INFORMATION')}</div>
            {!hasSetting && WorkspaceDetailInformation?.membership?.user_role?.name != defaultRole.instructor && WorkspaceDetailInformation?.membership?.user_role?.name != roleType.default ? <PanelSetting onClose={() => setHasSetting(true)} /> : null}
            <form onSubmit={handleSubmit(onSubmit)} className="max-w-[608px]">
                <div className="courseInformation-item">
                    <label className="courseInformation-label">
                        {t('COURSES.INTRODUCE_VIDEO')}
                    </label>
                    <div className="group relative overflow-hidden">
                        {/* @ts-ignore */}
                        <InputFile
                            onChange={checkImageUpload}
                            imgDefault={data?.video}
                            className="h-[240px]"
                            icon={<VideoUpload />}
                            textHelp={translatorlearning('COURSES.MESSAGE_UPLOAD_VIDEO', true)}
                            hasVideo={true}
                            isDisable={!checkPermission('courseInformation', 'U')}
                            accept="video/*"
                            ref={refUploadVideo}
                        />
                        {
                            data?.video && (
                                <div className="bg-[#0000004d] z-50 hidden group-hover:flex absolute inset-0 justify-center flex-col items-center text-white text-sm font-semibold gap-5">
                                    <div className="py-1 px-2 cursor-pointer flex items-center gap-2" onClick={onClickUpload}> <ChangeVideoIcon /> {t("Change Video")}</div>
                                    <div className="py-1 px-2 cursor-pointer flex items-center gap-2" onClick={() => setOpenModalViewVideo(true)}><PlayVideoIcon /> {t("PREVIEW")}</div>
                                    <div className="py-1 px-2 cursor-pointer flex items-center gap-2" 
                                        onClick={()=> setOpenModalRemoveVideo(true) }
                                    >
                                        <DeleteIcon width="20px" height="20px" />
                                        {t("REMOVE")}
                                    </div>
                            </div>
                        )}
                        {
                            loadingVideo && (
                                <div className="bg-[#0000004d] z-50 absolute inset-0 flex justify-center items-center">
                                    <Spinner style="text-white" />
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="courseInformation-item">
                    <InputField
                        label={t('COURSES.COURSE_NAME')}
                        name="title"
                        isRequired
                        maxLength={255}
                        placeholder={t('COURSES.COURSE_NAME')}
                        register={register('title', {
                            required: t('FORM_CONST.REQUIRED_FIELD'),
                            validate: {
                                shouldNotContainSpace: (value) => {
                                    return value.trim()?.length ? true : `${t('FORM_CONST.REQUIRED_FIELD')}`;
                                },
                            }
                        })}
                        errors={errors}
                        classNameInput="py-2"
                        handleBlur={handleSubmit(onSubmit)}
                        disabled={!checkPermission('courseInformation', 'U')}
                    />
                </div>
                <div className="courseInformation-item">
                    <SelectField
                        control={control}
                        isRequired
                        label={t('COURSES.COURSE_TYPE')}
                        name="type"
                        placeholder={t("COURSES.SELECT_COURSE_TYPE")}
                        isSearchable={false}
                        isMulti={true}
                        options={typeOption(translatorlearning)}
                        customStyles={{
                            control: (base) => {
                                return { ...base, minHeight: '0px', height: 'auto' }
                            },
                            valueContainer: (base) => {
                                return { ...base, paddingTop: 0, fontSize: 13, fontWeight: 600 }
                            },
                            indicatorsContainer: (base) => {
                                return { ...base, padding: '0px 8px', height: '30px' }
                            },
                        }}
                        errors={errors}
                        isDisabled={!checkPermission('courseInformation', 'U')}
                    />
                </div>
                <div className="courseInformation-item">
                    <TextareaField
                        label={t('COURSES.DESCRIPTION')}
                        name="description"
                        className="col-span-4"
                        placeholder={t('COURSES.ENTER_DESCRIPTION')}
                        register={register('short_description')}
                        textLength={getValues("short_description")?.length?.toString()}
                        maxLength={250}
                        isCountter
                        handleBlur={handleSubmit(onSubmit)}
                        disabled={!checkPermission('courseInformation', 'U')}
                    />
                </div>
                {/* <div className="courseInformation-item">
                    <CourseFieldData
                        label={t('COURSES.LEVEL')}
                        control={control}
                        name="level"
                        onChangeValue={handleAddTags}
                        values={watch('level') ?? []}
                        isDisabled={!checkPermission('courseInformation', 'U')}
                    />
                </div>
                <div className="courseInformation-item">
                    <CourseFieldData
                        label={t('COURSES.SUBJECT')}
                        control={control}
                        name="subject"
                        onChangeValue={handleAddTags}
                        values={watch('subject') ?? []}
                        isDisabled={!checkPermission('courseInformation', 'U')}
                    />
                </div>
                <div className="courseInformation-item">
                    <CourseFieldData
                        label={t('COURSES.AGE_GROUP')}
                        control={control}
                        name="age"
                        onChangeValue={handleAddTags}
                        values={watch('age') ?? []}
                        isDisabled={!checkPermission('courseInformation', 'U')}

                    />
                </div>
                <div className="courseInformation-item">
                    <CourseFieldData
                        label={t('COURSES.LANGUAGE_DELIVERY')}
                        control={control}
                        name="language_delivery"
                        onChangeValue={handleAddTags}
                        values={watch('language_delivery') ?? []}
                        isDisabled={!checkPermission('courseInformation', 'U')}
                    />
                </div>
                <div className="courseInformation-item">
                    <CourseFieldData
                        label={t('COURSES.DURATION')}
                        control={control}
                        name="duration"
                        onChangeValue={handleAddTags}
                        values={watch('duration') ?? []}
                        isDisabled={!checkPermission('courseInformation', 'U')}

                    />
                </div>
                <div className="courseInformation-item">
                    <CourseFieldData
                        label={t('COURSES.TAGS')}
                        control={control}
                        name="tags"
                        onChangeValue={handleAddTags}
                        values={watch('tags') ?? []}
                        isDisabled={!checkPermission('courseInformation', 'U')}
                    />
                </div> */}
                {/* {
                    properties?.map((item: any) => {
                        return (
                            <Properties 
                                item={item} 
                                formState={{ errors }}
                                control={control}
                                register={register}
                                watch={watch}
                            />
                        )
                    })
                } */}
            </form>
            <DialogComponent
                title={"Remove Video"}
                isOpen={isOpenModalRemoveVideo}
                onCloseModal={() => setOpenModalRemoveVideo(false)}
                child={
                    <div className="mt-5">
                        <div className="text-left text-sm text-gray-800">
                            <span>{t("Are you sure you want to remove this video?")}</span>
                        </div>
                        <div className="flex justify-center gap-5 mt-6">
                            <ButtonOutline type="button" size="M" onClick={() => setOpenModalRemoveVideo(false)}>{t("CANCEL")}</ButtonOutline>
                            <ButtonPrimary className="buttonPrimary-danger" type="button" size="M" onClick={() => {
                                dispatch(courseUpdate({
                                    workspaceId: params?.id,
                                    id: params?.courseId,
                                    video: null
                                }))
                                setOpenModalRemoveVideo(false)
                            }}>
                                {t("REMOVE")}
                            </ButtonPrimary>
                        </div>
                    </div>
                }
            />
            {isOpenModalViewVideo && <VideoPreview data={{file_url: data?.video, name: "video.mp4"}} isOpen={isOpenModalViewVideo} closeModal={() => setOpenModalViewVideo(false)} />}
        </div>
    )
}