import React, { useCallback, useContext, useEffect, useState } from 'react';
import { NoteIcon } from 'assets/icon/NoteIcon';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { customSelectStyle } from '../../BookingForm/utils';
import workspaceService from 'services/workspace.service';
import { debounce } from 'lodash';
import { typeOption } from 'components/Academics/LearningPath/LearningPathDetail/learningPath.consts';
import { UserContext } from 'contexts/User/UserContext';
import dayjs from 'dayjs';
import { HeartIcon } from '@heroicons/react/solid';
import ModalInfoInstructor from '../ModalInfoInstructor';

type SelectCardExtendProps = {
    data: any;
    learningPathId?: any;
    setLessonSelected?: any;
    lessonSelected?: any;
    note?: any;
    setNote?: any;
    learningPathType: string;
    handleSelect?: any;
    studentId?: number
};

const customFilter = (option) => {
    return option.label;
};

const RenderDetails = ({ value, icon, styles = 'w-1/6' }: { value: string; icon?: any; styles?: string }) => (
    <div className={`text-xs font-semibold  ${styles}`}>
        <div className="flex items-center">
            {icon}
            {value}:
        </div>
    </div>
);

const SelectCardExtend: React.FC<SelectCardExtendProps> = ({
    note,
    data,
    learningPathId,
    setLessonSelected,
    lessonSelected,
    setNote,
    learningPathType,
    studentId
}: SelectCardExtendProps) => {
    const { t, i18n } = useTranslation();
    const translator = (str: string) => t(`SELF_BOOKING.${str}`);
    const [openModalInstructor, setOpenModalInstructor] = useState(false)
    const [inputSearchLesson, setInputSearchLesson] = useState<string>(null);
    const [listLessons, setListLessons] = useState([]);

    const {
        userState: { result: userInformation },
    } = useContext(UserContext);

    const debounceInput = useCallback(
        debounce((nextValue: string, asyncFunction: (p: string) => void) => {
            asyncFunction(nextValue);
        }, 1000),
        []
    );

    const [paginationLessons, setPaginationLessons] = useState({
        page: 1,
        per_page: 20,
        total: 0,
    });

    const [loadingLessons, setLoadingLesson] = useState<boolean>(false);

    const params: any = useParams();
    const workspaceId = params?.id;

    const loadMoreLessons = () => {
        if (paginationLessons?.total > paginationLessons.page * paginationLessons?.per_page) {
            getListLessons(paginationLessons?.page + 1);
        }
    };

    useEffect(() => {
        getListLessons();
    }, []);

    const getListLessons = (page?: number, keyword?: string) => {
        setLoadingLesson(true);
        const params = {
            page: page || 1,
            per_page: paginationLessons?.per_page,
            title: keyword || inputSearchLesson,
            learning_path: learningPathId,
            order: 'asc',
            sort_by: 'title',
        };
        if (!params?.title) delete params?.title;
        workspaceService
            .getLessonList(workspaceId, params)
            .then((res) => {
                const formatedListLessons = res?.items?.map((lesson) => ({
                    label: lesson?.title,
                    value: lesson?.id,
                }));
                if (page === 1) {
                    setListLessons(formatedListLessons);
                } else {
                    setListLessons([...listLessons, ...formatedListLessons]);
                }
                setPaginationLessons({
                    page: res?.page,
                    per_page: res?.per_page,
                    total: res?.total,
                });
            })
            .finally(() => setLoadingLesson(false));
    };

    const translatorLP = (str: string, newKey?: boolean, opt?: any) =>
        newKey ? t(str, opt) : t(`LEARNING_PATH.LEARNING_PATH_DETAIL.${str}`, opt);

    const checkPerfer = () => {
        const listPrefered = data?.instructors?.[0]?.instructor?.prefer_users;
        if (listPrefered?.includes(studentId)) return true;
        return false;
    };

    return (
        <div className="border border-dashed border-primary-100 rounded-2xl bg-white min-h-230px p-6 text-dark-300 max-h-96 overflow-auto">
            <div className="flex justify-between items-center">
                <div className="mr-3">
                    <span className="text-xs font-semibold ">{translator('DETAIL')}</span>
                    <div className="text-sm pt-2 font-medium ">
                        {dayjs(data?.start_time)?.tz(userInformation?.time_zone)?.locale(i18n?.language)?.format('ddd, MMM DD, YYYY')}•
                        {dayjs(data?.start_time)?.tz(userInformation?.time_zone)?.format('HH:mm A')} -
                        {dayjs(data?.start_time)?.tz(userInformation?.time_zone)?.add(data?.duration, 'minutes')?.format('HH:mm A')}
                    </div>
                </div>
            </div>
            <div className="mt-6 flex items-center">
                <RenderDetails value={translator('TYPE')} styles="min-w-ooolab_h_40" />
                <div className="capitalize rounded px-2 bg-dark-100 text-white text-xs font-semibold ">
                    {typeOption(translatorLP).find((item) => item?.value === learningPathType)?.label}
                </div>
            </div>
            <div className="mt-6 flex ">
                <RenderDetails value={translator('INSTRUCTOR')} styles="min-w-ooolab_h_40" />
                <div className="flex gap-2 cursor-pointer" onClick={() => setOpenModalInstructor(true)}>
                    <div className={`rounded-full flex items-center justify-center bg-gray-100 relative ${(data?.instructor?.preferred || checkPerfer()) ? "border border-red-500 " : ""}`}>
                        <img src={data?.instructor?.avatar_url} alt="avatar" className="rounded-full w-5 h-5" />
                        {(data?.instructor?.preferred || checkPerfer()) && <HeartIcon className='w-4 h-4 absolute -right-1 -bottom-1 text-red-500' />}
                    </div>
                    <div
                        className=" cursor-pointer text-sm text-primary-500 pl-1 truncate"
                    >
                        {data?.instructor?.display_name}
                    </div>
                </div>
            </div>
            <div className="my-6 flex">
                <RenderDetails value={translator('LESSONS')} styles="min-w-ooolab_h_40" />
                <Select
                    className=" text-dark-300 font-normal  rounded-20 w-full"
                    styles={customSelectStyle}
                    onInputChange={(inputValue) => {
                        debounceInput(inputValue, () => getListLessons(1, inputValue));
                        setInputSearchLesson(inputValue);
                    }}
                    value={lessonSelected}
                    options={listLessons}
                    closeMenuOnSelect={true}
                    menuPlacement="auto"
                    menuPosition="fixed"
                    isLoading={loadingLessons}
                    placeholder={translator('SELECT_LESSON')}
                    maxMenuHeight={200}
                    onChange={(val: any) => setLessonSelected(val)}
                    filterOption={customFilter}
                    onMenuScrollToBottom={loadMoreLessons}
                    onMenuClose={() => {
                        setInputSearchLesson(null);
                    }}
                />
            </div>
            <div className="mt-6 flex ">
                <RenderDetails value={translator('NOTE')} icon={<NoteIcon className="mr-2" />} styles="min-w-ooolab_h_40" />
                <div className="w-full">
                    <textarea
                        style={{ resize: 'none' }}
                        id="note"
                        name="note"
                        className=" w-full border border-light-500 rounded p-4 h-24 outline-none text-xs"
                        placeholder={translator('ENTER_NOTE')}
                        value={note}
                        onChange={(e) => setNote(e.target?.value)}
                        maxLength={300}
                    />
                </div>
            </div>
            {openModalInstructor && 
                <ModalInfoInstructor  
                    isOpen={openModalInstructor} 
                    instructorId={data?.instructor?.id} 
                    onClose={() => setOpenModalInstructor(false)}
                />}
        </div>
    );
};

export default SelectCardExtend;
