import { ChevronRightIcon, ExternalLinkIcon } from '@heroicons/react/outline';
import { DeleteIcon, ReorderAlt } from 'assets/icon';
import { useRef, useState } from 'react';
import './DisclosureButton.scss'
import { checkPermission, convertSecondToHourMins } from 'utils/commonFun';
import { useHistory, useParams } from 'react-router-dom';

interface IDisclosureButton {
    index: number,
    item: any,
    isActiveSection: boolean,
    onDragOver?: Function,
    onDragEnd?: Function,
    onDragStart?: Function,
    onRename?: Function,
    onDelete?: Function,
    sectionName: string,
    open?: boolean,
    numberOfFile?: number,
    setSectionName?: any,
    isDraggingContent?: boolean,
    featureName: string
}

export default function DisclosureButton({
    numberOfFile = 0,
    index,
    item,
    isActiveSection,
    onDragOver,
    onRename,
    onDelete,
    onDragEnd,
    onDragStart,
    open,
    sectionName,
    setSectionName,
    isDraggingContent = false,
    featureName
}: IDisclosureButton) {
    const history = useHistory();
    const params: any = useParams();

    const [hasEdit, setHasEdit] = useState(false);
    const htmlElRef = useRef(null);

    const onFocus = () => {
        htmlElRef.current && htmlElRef.current.focus();
    };

    const onEditName = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setHasEdit(true);
        setTimeout(() => {
            onFocus();
        }, 10);
        setSectionName(sectionName);
    };

    return (
        <div className={`DisclosureButton ${isDraggingContent ? 'isDraggingContent' : ''}`}>
            <div className="flex-item relative">
                <button
                    className="reorder cursor-move"
                    onDragStart={(e) => {
                        e.stopPropagation();
                        if (checkPermission(featureName, 'U')) {
                            onDragStart(e, index);
                        }

                    }}
                    onDragEnd={(e) => {
                        e.stopPropagation();
                        if (checkPermission(featureName, 'U')) {
                            onDragEnd();
                        }
                    }}
                    draggable={checkPermission(featureName, 'U')}
                    onDragOver={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (checkPermission(featureName, 'U')) {
                            onDragOver(index);
                        }
                    }}
                >
                    <ReorderAlt />
                </button>
                {numberOfFile > 0 && (
                    <ChevronRightIcon
                        className={`w-4 min-w-[16px] h-4 mr-1 mt-0.5 ${isActiveSection ? 'text-primary-500' : 'text-gray-800'}  ${open ? 'rotate-90' : ''
                            }`}
                    />
                )}
                <div className={`file-name ${hasEdit ? '!w-full' : ''}`}>
                    {!hasEdit ? (
                        <button className="truncate cursor-text" type='button' onClick={(e) => onEditName(e)} disabled={!checkPermission(featureName, 'U')}>
                            {sectionName}
                        </button>
                    ) : (
                        <input
                            ref={htmlElRef}
                            value={sectionName}
                            className="section-input "
                            onFocus={(e) => e.stopPropagation()}
                            onBlur={(e) => {
                                setHasEdit(false);
                                onRename(e.target.value, item?.library_content?.object_type);
                            }}
                            onKeyDown={(e) => {
                                e.code === 'Space' && e.stopPropagation();
                                e.code === 'Enter' && setHasEdit(false);
                                e.code === 'Enter' && onRename(sectionName, item?.library_content?.object_type);
                            }}
                            onChange={(event) => {
                                setSectionName(event.target.value);
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            disabled={!checkPermission(featureName, 'U')}
                        />
                    )}
                </div>
                <span className="section-badge group-hover:hidden">{numberOfFile || 0}</span>
                {checkPermission('libraryContentAssignment', 'U') ? <button
                    onClick={(e) => {
                        e.stopPropagation();
                        history.push(`/workspace/${params?.id}/assignment/${item?.library_content?.object_id}`);
                    }}
                    className=" absolute right-8 top-2 hidden group-hover:block mr-2 text-enterprise"
                >
                    <ExternalLinkIcon className='w-4 h-4' />
                </button> : ''}
                {checkPermission(featureName, 'U') ? <button
                    onClick={(e) => {
                        e.stopPropagation();
                        onDelete(item?.id);
                    }}
                    className=" absolute right-2 top-2 hidden group-hover:block mr-2"
                >
                    <DeleteIcon width="16px" height="16px" />
                </button> : ''}
            </div>
            {item?.library_content?.time_limit && (
                <div className='pr-2 group-hover:hidden'>
                    <p className='w-fit px-2 rounded-lg bg-orange-50 text-orange-700 text-sm font-normal'>{convertSecondToHourMins(item?.library_content?.time_limit)}</p>
                </div>
            )}
        </div>
    );
}
