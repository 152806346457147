import ClassDashboard from 'components/Reports/ClassDashboard';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';
import React from 'react';

const ClassDashboardPage: React.FC<{ setAuthStorage: React.Dispatch<React.SetStateAction<boolean>> }> = ({ setAuthStorage }) => {
    return (
        <ManagePermissionWrapper>
            <ClassDashboard />
        </ManagePermissionWrapper>
    );
};

export default ClassDashboardPage;
