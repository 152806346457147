
export const enum SINGLE_INVOICE_STATUS_ENUM {
  refunded = "refunded",
  canceled = "canceled",
  paid = "paid",
  sent = "sent",
  overdue= "overdue",
  scheduled= "scheduled",
 }

 export const enum RECURRING_INVOICE_STATUS_ENUM {
  active= 'active',
  canceled= 'canceled',
  paid= 'paid',
  completed = 'completed'
 }

 export const INVOICE_ACTION_BY_STATUS = {
  view_detail: [SINGLE_INVOICE_STATUS_ENUM.scheduled, SINGLE_INVOICE_STATUS_ENUM.overdue, SINGLE_INVOICE_STATUS_ENUM.sent, SINGLE_INVOICE_STATUS_ENUM.paid, SINGLE_INVOICE_STATUS_ENUM.canceled, SINGLE_INVOICE_STATUS_ENUM.refunded],
  edit: [SINGLE_INVOICE_STATUS_ENUM.scheduled],
  sent_invoice: [SINGLE_INVOICE_STATUS_ENUM.scheduled],
  resent_invoice: [SINGLE_INVOICE_STATUS_ENUM.overdue, SINGLE_INVOICE_STATUS_ENUM.sent],
  view_invoice: [SINGLE_INVOICE_STATUS_ENUM.scheduled, SINGLE_INVOICE_STATUS_ENUM.overdue, SINGLE_INVOICE_STATUS_ENUM.sent, SINGLE_INVOICE_STATUS_ENUM.paid, SINGLE_INVOICE_STATUS_ENUM.refunded, SINGLE_INVOICE_STATUS_ENUM.canceled],
  create_receipt: [SINGLE_INVOICE_STATUS_ENUM.sent, SINGLE_INVOICE_STATUS_ENUM.overdue],
  add_note: [SINGLE_INVOICE_STATUS_ENUM.scheduled],
  refund: [SINGLE_INVOICE_STATUS_ENUM.paid],
  view_refund: [SINGLE_INVOICE_STATUS_ENUM.refunded],
  cancel: [SINGLE_INVOICE_STATUS_ENUM.scheduled, SINGLE_INVOICE_STATUS_ENUM.overdue, SINGLE_INVOICE_STATUS_ENUM.sent],
  view_receipt: [SINGLE_INVOICE_STATUS_ENUM.paid, SINGLE_INVOICE_STATUS_ENUM.refunded]
}

export const enum HANDLE_ACTION_ENUM {
  send_invoice = 'send_invoice',
  create_receipt = 'create_receipt',
  refund = 'refund',
  resend_invoice= 'resend_invoice',
  cancel= 'cancel'
}

export const INVOICE_STATUS = (translator) => {
  return {
    scheduled: {
      label: translator('SCHEDULED'),
      value: SINGLE_INVOICE_STATUS_ENUM.scheduled
    },
    overdue: {
      label: translator('OVERDUE'),
      value: SINGLE_INVOICE_STATUS_ENUM.overdue
    },
    active: {
      label: translator('ACTIVE'),
      value: RECURRING_INVOICE_STATUS_ENUM.active
    },
    sent: {
      label: translator('SENT'),
      value: SINGLE_INVOICE_STATUS_ENUM.sent
    },
    paid: {
      label: translator('PAID'),
      value: SINGLE_INVOICE_STATUS_ENUM.paid
    },
    cancelled: {
      label: translator('CANCELLED'),
      value: SINGLE_INVOICE_STATUS_ENUM.canceled
    },
    refunded: {
      label: translator('REFUNDED'),
      value: SINGLE_INVOICE_STATUS_ENUM.refunded
    },
    completed: {
      label: translator('COMPLETED'),
      value: RECURRING_INVOICE_STATUS_ENUM.completed
    }
  }
}

export const PAYMENT_OPTION = (translator) => {
  return {
    cash: translator("CASH"),
    bank_transfer: translator("BANK_TRANSFER"),
    payment_gateway: translator("PAYMENT_GATEWAY")
  }
}

export const PAYMENT_OPTION_LIST = (translator) => {
  return [
    {name: translator("CASH"), label: translator("CASH"), value: 'cash'},
    {name: translator("BANK_TRANSFER"), label: translator("BANK_TRANSFER"), value: 'bank_transfer'},
    {name: translator("PAYMENT_GATEWAY"), label: translator("PAYMENT_GATEWAY"), value: 'payment_gateway'}
  ]
}

export const PAYMENT_GETWAY_OPTIONS = (translator) => {
  return [
    {name: 'stripe', label: translator("STRIPE"), value: null},
    {name: 'paypal', label: translator("PAYPAL"), value: null},
  ]
}

export const INVOICE_STATUS_LIST = (translator) => {
  return [
     {
      name: translator('SCHEDULED'),
      value: SINGLE_INVOICE_STATUS_ENUM.scheduled
    },
     {
      name: translator('OVERDUE'),
      value: SINGLE_INVOICE_STATUS_ENUM.overdue
    },
    {
      name: translator('SENT'),
      value: SINGLE_INVOICE_STATUS_ENUM.sent
    },
     {
      name: translator('PAID'),
      value: SINGLE_INVOICE_STATUS_ENUM.paid
    },
    {
      name: translator('CANCELLED'),
      value: SINGLE_INVOICE_STATUS_ENUM.canceled
    },
    {
      name: translator('REFUNDED'),
      value: SINGLE_INVOICE_STATUS_ENUM.refunded
    }
  ]
}

export const RECURRING_INVOICE_STATUS_LIST = (translator) => {
  return [
    {
      name: translator('ACTIVE'),
      value: RECURRING_INVOICE_STATUS_ENUM.active
    },
    {
      name: translator('CANCELLED'),
      value: RECURRING_INVOICE_STATUS_ENUM.canceled
    },
     {
      name: translator('PAID'),
      value: RECURRING_INVOICE_STATUS_ENUM.paid
    },
    {
      name: translator('COMPLETED'),
      value: RECURRING_INVOICE_STATUS_ENUM.completed
    }
  ]
}

export const SCHEDULE_TYPE_LIST = (translator) => {
  return [
    { label: translator('SCHEDULE'), value: 'schedule' },
    { label: translator('NOW'), value: 'now' },
  ]
}


export const enum SCHEDULE_TYPE_ENUM {
  schedule = 'schedule',
  now = 'now'
}

export const enum INVOICE_TYPE {
  single = 'single',
  recurring= 'recurring'
}

