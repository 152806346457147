import { FC } from 'react';
import ButtonComponent from 'components/Button/ButtonComponent';
import { useTranslation } from 'react-i18next';
import DialogComponent from 'components/Dialog/DialogComponent';
import './Modal.scss'
import { ButtonOutline } from '_shared';
import { LEARNING_PATH_STATUS } from '../TableLearningPath';

type ModalDeleteLearningPathProps = {
    onDeleteLearningPath: () => void;
    isOpen: boolean;
    onCloseModal: any;
    status: string
};

const ModalDeleteLearningPath: FC<ModalDeleteLearningPathProps> = ({
    onDeleteLearningPath,
    isOpen,
    onCloseModal,
    status
}) => {
    const { t: translator } = useTranslation();

    return (
        <DialogComponent
            isOpen={isOpen}
            title={translator('MODALS.LEARNING_PATH.DELETE_TITLE')}
            onCloseModal={onCloseModal}
            maskClosable={false}
            child={
                <div className='ModalAction'>
                    <div className="ModalAction-content">
                        {
                            status === LEARNING_PATH_STATUS.published ? 
                            translator('MODALS.LEARNING_PATH.DELETE_LP_PUBLISHED')
                            :
                            translator('MODALS.LEARNING_PATH.DELETE_MSG')
                        }
                    </div>
                    <div className="ModalAction-groupButton">
                        <ButtonOutline
                            onClick={onCloseModal}
                            type={"button"}
                        >
                          {translator('MODALS.CANCEL')}
                        </ButtonOutline>
                        <ButtonComponent
                            title={translator('MODALS.DELETE')}
                            classStyle="primary-button deleteBtn"
                            onClickButton={onDeleteLearningPath}
                        />
                    </div>
                </div>
            }
        />
    );
};

export default ModalDeleteLearningPath;
