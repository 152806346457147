import { CheckIcon } from "assets/icon";
import React from "react";

import { RegisterOptions} from 'react-hook-form';
import './FormCheckBox.scss';

export type FormInputProps = {
  className?: string;
  name: any;
  rules?: RegisterOptions;
  register?: any;
  value?: string;
  title?: string;
  disabled?: boolean;
  checked?: boolean;
};

export const FormCheckBox = ({
  className,
  name,
  register,
  rules,
  title,
  disabled = false,
  checked = false,
  ...props
}: FormInputProps): JSX.Element => {
  return (
    <label className={`formCheckBox ${className ? className: ''}`} aria-live="polite"> 
      <span>{title}</span>
      <input type="checkbox" name={name} {...props} {...(register && register(name, rules))} disabled={disabled} defaultChecked={checked} />
      <span className="formCheckBox-checkmark">
        <CheckIcon />
      </span>
    </label>
  );
};
