import moment from "moment";
import React from "react";
import "./LearnerInfo.scss";

export const LearnerInfo = ({learner, tasks}) => {
    return (
        <div className="learnerInfo">
            <div className="learnerInfo-header">
                <div className="learnerInfo-learner">
                    <div className="learnerInfo-avatar" style={{backgroundImage: `url(${learner?.learner?.avatar_url})`}}></div>
                    <div className="learnerInfo-name">{learner?.learner?.display_name}</div>
                </div>
                <div className="learnerInfo-grade">
                    Total Grade <span>{learner?.final_score ? Math.round(learner?.final_score) : '-'}</span>
                </div>
            </div>
            <div className="learnerInfo-info">
                <div className="learnerInfo-item">
                    <div className="learnerInfo-label">Completion Date:</div>
                    <div className="learnerInfo-value">
                        {learner?.completion_date ? moment(learner?.completion_date).format('DD/MM/YYYY') : '-'}
                    </div>
                </div>
                <div className="learnerInfo-line"></div>
                <div className="learnerInfo-item">
                    <div className="learnerInfo-label">Due Date:</div>
                    <div className="learnerInfo-value">
                        {learner?.end_time ? moment(learner?.end_time).format('DD/MM/YYYY') : '-'}
                    </div>
                </div>
                <div className="learnerInfo-line"></div>
                <div className="learnerInfo-item">
                    <div className="learnerInfo-label">Total Task(s):</div>
                    <div className="learnerInfo-value">
                        {tasks?.task_results?.length ?? 0}
                    </div>
                </div>
                <div className="learnerInfo-line"></div>
                <div className="learnerInfo-item">
                    <div className="learnerInfo-label">Progress</div>
                    <div className="learnerInfo-progress">
                        <div className="learnerInfo-bar" style={{width: tasks?.progress + '%'}}></div>
                    </div>
                    <div className="learnerInfo-value">
                        {Math.round(tasks?.progress || 0)}%
                    </div>

                </div>
            </div>
        </div>
    )
}