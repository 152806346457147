import Table from 'components/Management/components/table';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { Dialog, Transition } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { XIcon } from '@heroicons/react/outline';
import icon_delete from 'assets/img/icon_delete.png';
import rewardService from 'services/reward.service';
import { toast, ToastContainer } from 'react-toastify';
import { Toast } from 'utils/sweetAlert';
import NoContent, { NoContentWithImage } from 'components/NoContent/NoContent';
import { checkPermission } from 'utils/commonFun';
import dayjs from 'dayjs';
import { UserContext } from 'contexts/User/UserContext';

const RenderGroupsHeader = ({ value }: { value: string }) => (
    <div className="h-full pl-1 flex items-center">
        <p className=" text-sm text-ooolab_dark_1 font-semibold">{value}</p>
    </div>
);

const FormatColumnValue = ({ children }) => (
    <p className="overflow-ellipsis whitespace-nowrap overflow-hidden pl-1 py-1 font-normal text-ooolab_dark_1 text-sm my-3">
        {children}
    </p>
);

interface TableClassProps {
    data?: any[];
    loading: boolean;
    onReload: () => void;
}

const TableRewardDashBoard: React.FC<TableClassProps> = ({ data, loading, onReload }) => {
    const { t: translator } = useTranslation();
    const param: { id: string } = useParams();
    const [modalRefund, setModalRefund] = useState(false);
    const [selectedReward, setSelectedReward] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [dataTable, setDataTable] = useState([]);
    const {userState: {result}} = useContext(UserContext)

    const columns: Column<any>[] = React.useMemo(
        () => [
            {
                Header: () => (
                    <div className="flex items-center justify-between border-ooolab_bar_color">
                        <RenderGroupsHeader value={translator('Reward ID')} />
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                ),
                accessor: 'id',
                Cell: ({ row }) => (
                    <div className="border-ooolab_bar_color flex items-center text-xs justify-between h-full py-3 font-semibold">
                        <Link
                            to={`/workspace/${param.id}/management/reward/${row?.original.reward?.id}`}
                            className="text-primary-500 font-semibold cursor-pointer"
                        >
                            {row?.original.reward?.id}
                        </Link>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                ),

                width: 120,
            },
            {
                Header: () => (
                    <>
                        <div className="h-full grid grid-cols-4 ">
                            <p className="col-span-3 text-sm text-ooolab_dark_1 pl-1 font-semibold text-left">
                                {translator("Update Date")}
                            </p>
                        </div>
                    </>
                ),
                accessor: 'updated_at',
                Cell: (d: any) => {
                    return (
                        <><FormatColumnValue>{d?.value && dayjs.utc(d?.value).tz(result?.time_zone)?.format('DD/MM/YYYY - HH:mm')}</FormatColumnValue></>
                    )
                }
            },
            {
                Header: () => (
                    <>
                        <div className="h-full grid grid-cols-4 pl-1">
                            <p className="col-span-3 text-sm text-ooolab_dark_1 font-semibold text-left">
                                {translator("Reward Name")}
                            </p>
                        </div>
                    </>
                ),
                accessor: 'reward_name',
                Cell: ({ row }: { row: any }) => {
                    return (
                        <>
                            <div className="h-full grid grid-cols-4 ">
                                <div className="col-span-1 flex justify-center items-center">
                                    <img
                                        src={row?.original?.reward?.thumbnail}
                                        className="w-6 h-6 rounded"
                                        alt="thumbnail"
                                    />
                                </div>
                                <div className="flex items-center col-span-3 ">
                                    <p
                                        data-tip={row?.original?.reward?.name}
                                        data-for={
                                            row?.values?.id + 'reward_name'
                                        }
                                        className="overflow-ellipsis whitespace-nowrap overflow-hidden font-normal text-ooolab_dark_1 text-sm  "
                                    >
                                        {row?.original?.reward?.name}
                                    </p>
                                </div>
                            </div>
                            {/*@ts-ignore*/
                                <ReactTooltip
                                    place="bottom"
                                    type="dark"
                                    effect="float"
                                    id={row?.values?.id + 'reward_name'}
                                    getContent={(content) => (
                                        <div className=" h-auto">{content}</div>
                                    )}
                                />}
                        </>
                    );
                },
            },
            {
                Header: () => <RenderGroupsHeader value={translator('Student ID')} />,
                accessor: 'student.id',
                Cell: ({ row }) => {
                    return <FormatColumnValue>{row.original?.student?.id}</FormatColumnValue>;
                },
            },
            {
                Header: () => <RenderGroupsHeader value={translator('Student Name')} />,
                accessor: 'student.display_name',
                Cell: ({ row }) => {
                    return (
                        <div className="flex items-center col-span-3 ">
                            <p
                                data-tip={`${row?.original?.student?.display_name} ${row?.original?.student?.nick_name ? ` / ${row?.original?.student?.nick_name}` : ''}`}
                                data-for={
                                    row?.values?.id + 'student_name'
                                }
                                className="my-3 p-1 overflow-ellipsis whitespace-nowrap overflow-hidden font-normal text-ooolab_dark_1 text-sm  "
                            >
                                {row?.original?.student?.display_name}
                                {row?.original?.student?.nick_name && ` / ${row?.original?.student?.nick_name}`}
                            </p>
                            {/*@ts-ignore*/
                                <ReactTooltip
                                    place="bottom"
                                    type="dark"
                                    effect="float"
                                    id={row?.values?.id + 'student_name'}
                                    getContent={(content) => (
                                        <div className=" h-auto">{content}</div>
                                    )}
                                />}
                        </div>
                    );
                },
            },
            {
                Header: () => <RenderGroupsHeader value={translator('Redeemed by')} />,
                accessor: 'redeemed_user',
                Cell: ({ row }) => {
                    return (
                        <div className="flex items-center col-span-3 ">
                            <p
                                data-tip={`${row.original?.redeemed_user?.display_name} ${row?.original?.redeemed_user?.nick_name ? ` / ${row?.original?.redeemed_user?.nick_name}` : ''}`}
                                data-for={
                                    row?.values?.id + 'redeemed_name'
                                }
                                className="my-3 p-1 overflow-ellipsis whitespace-nowrap overflow-hidden font-normal text-ooolab_dark_1 text-sm  "
                            >
                                {row.original?.redeemed_user?.display_name}
                                {row?.original?.redeemed_user?.nick_name && ` / ${row?.original?.redeemed_user?.nick_name}`}
                            </p>
                            {/*@ts-ignore*/
                                <ReactTooltip
                                    place="bottom"
                                    type="dark"
                                    effect="float"
                                    id={row?.values?.id + 'redeemed_name'}
                                    getContent={(content) => (
                                        <div className=" h-auto">{content}</div>
                                    )}
                                />}
                        </div>
                    );
                },
            },
            {
                Header: () => <RenderGroupsHeader value={translator('Coin Price')} />,
                accessor: 'amount',
                Cell: (d) => {
                    return <FormatColumnValue>
                        {d.value} {translator("Coins")}
                    </FormatColumnValue>;
                },
            },
            {
                Header: () => <RenderGroupsHeader value={translator('Status')} />,
                accessor: 'status',
                Cell: (d: any) => {
                    return (
                        <FormatColumnValue>
                            <span className={`${d.value == 'pending' ?
                                "text-[#FA9847]" :
                                d.value == 'refund' ?
                                    "text-[#FF3329]" :
                                    "text-[#3AA757]"} text-first-uppercase font-semibold text-xs`}>
                                {d.value == 'claimed' ? 'received' :
                                    d.value == 'refund' ? 'cancel' :
                                        d.value == 'claim' ? 'confirm' :
                                            d.value
                                }
                            </span>
                        </FormatColumnValue>
                    );
                },
            },
            {
                Header: () => <RenderGroupsHeader value={translator('Reason')} />,
                accessor: 'refund_reason',
                Cell: ({ row }) => {
                    return (
                        <>
                            <div className="flex items-center my-3 py-1">
                                <p
                                    data-tip={row?.original?.refund_reason}
                                    data-for={
                                        row?.original?.id + 'refund_reason'
                                    }
                                    className="overflow-ellipsis whitespace-nowrap overflow-hidden font-normal text-ooolab_dark_1 text-sm"
                                >
                                    {row?.original?.refund_reason}
                                </p>
                                {/*@ts-ignore*/
                                    <ReactTooltip
                                        place="bottom"
                                        type="dark"
                                        effect="float"
                                        id={row?.original?.id + 'refund_reason'}
                                        getContent={(content) => (
                                            <div className="h-auto max-w-[200px]">{content}</div>
                                        )}
                                    />}
                            </div>
                        </>
                    );
                },
            },
            {
                Header: () => <RenderGroupsHeader value="" />,
                accessor: 'student',
                Cell: ({ row }) => {
                    return (
                        checkPermission('rewardDashboard', 'U') &&
                        <>
                            <div className='flex h-full items-center'>
                                {
                                    row.values.status == 'pending' &&
                                    <>
                                        <button type='button'
                                            disabled={row.original.id === selectedReward?.id}
                                            onClick={() => {
                                                confirm(row.original);
                                            }}
                                            className='border border-primary-500 mr-3 py-1 px-2 text-white bg-primary-500 hover:bg-primary-500/80 text-xs text-center rounded h-[32px] transition'>
                                            {row.original.id === selectedReward?.id && !isSubmit ? <CircleSpin color="text-[#ffffff]" className='mx-auto' /> :
                                                'Confirm'}
                                        </button>
                                        <button type='button'
                                            className='border border-[#FF3329] py-1 px-2 text-[#FF3329] text-xs rounded hover:bg-[#FF3329]/10 h-[32px] text-center transition'
                                            onClick={() => {
                                                setSelectedReward(row.original);
                                                setModalRefund(true);
                                                setValue('id', row.values.id, { shouldValidate: true });
                                            }}>
                                            {translator("Cancel")}
                                        </button>
                                    </>
                                }
                                {
                                    row.values.status == 'claimed' &&
                                    <>
                                        <button type='button'
                                            className='border border-[#FF3329] py-1 px-2 text-[#FF3329] hover:bg-[#FF3329]/10 text-xs rounded  h-[32px] text-center transition'
                                            onClick={() => {
                                                setSelectedReward(row.original);
                                                setModalRefund(true);
                                                setValue('id', row.values.id, { shouldValidate: true });
                                            }}>
                                            {translator("Cancel")}
                                        </button>
                                    </>
                                }
                            </div>
                        </>
                    );
                },
            }
        ],
        [data]
    );

    const onSubmit = handleSubmit(async (value: any) => {
        if (isSubmit) {
            return;
        }
        const payload = {
            ...value
        };
        setIsSubmit(true);
        const response = await rewardService.refundRewardClaimService({ workspaceId: param.id, rewardId: selectedReward.reward.id, rewardClaimId: payload.id }, payload);
        if (!response?.data?.error) {
            Toast.fire({
                icon: 'success',
                title: 'Successfully refunded!',
            });
            setValue('refund_reason', '');
            setModalRefund(false);
            setIsSubmit(false);
            onReload();
        } else {
            setIsSubmit(false);
        }
        setSelectedReward(null);
    });

    const confirm = async (rewardClaim) => {
        if (isSubmit) {
            return;
        }
        try {
            setIsSubmit(true);
            const payload = {
                workspace_id: param.id,
                reward_id: rewardClaim.reward.id,
                reward_claim_id: rewardClaim.id
            };
            setSelectedReward(rewardClaim);
            const response = await rewardService.confirmRewardClaimService({ workspaceId: param.id, rewardId: rewardClaim.reward.id, rewardClaimId: rewardClaim.id }, payload);
            if (!response?.data?.error) {
                toast.success(translator('Successfully confirm!'));
                onReload();
            }
            setSelectedReward(null);
            setIsSubmit(false);
        } catch { }
    }

    useEffect(() => {
        setDataTable(data);
    }, [data])

    return <>
        {loading ?
            <div className="TableRewardDashBoard flex items-center justify-center mt-6">
                <CircleSpin color="text-[#00559C]" />
            </div> :
            dataTable?.length > 0 ? <Table data={dataTable} columns={columns} /> :
                (!dataTable || dataTable?.length <= 0) && <NoContentWithImage content={translator("REWARD.NO_REWARDS")} />}
        <Transition.Root show={modalRefund} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-70 inset-0 overflow-y-hidden"
                open={modalRefund}
                onClose={() => {
                    setModalRefund(false);
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 dialog-background  bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <div className="flex i justify-center min-h-screen p-4 pb-20 text-center items-center">
                    <div className="max-w-[320px] p-6 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle w-2/5 relative">
                        <div>
                            <div className="flex w-full items-center">
                                <button
                                    className="w-9 h-9 ml-auto text-ooolab_dark_300 hover:bg-ooolab_light_blue_0 hover:text-primary-500 rounded-full transition p-2  -mt-2.5"
                                    onClick={() => {
                                        setModalRefund(false);
                                    }}
                                >
                                    <XIcon fill="#2E3A59" className='text-ooolab_dark_300 w-[22px] h-[22px]' />
                                </button>
                            </div>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div>
                                <img src={icon_delete} alt="Refund Coins" className='w-[100px] h-[100px] mx-auto' />
                                <h2 className='text-ooolab_dark_300 text-sm font-medium my-3 text-center font-inter'>{translator("Cancel Coins")}</h2>
                                <input type="text" hidden {...register("id")} />
                                <textarea className="text-ooolab_dark_1 text-xs rounded font-normal w-full border border-ooolab_bar_color leading-8 pl-2 focus:outline-none max-w-[272px] h-[44px]"
                                    maxLength={200}
                                    id="reward-description"
                                    placeholder={translator("Enter reason")} name="reason"
                                    {...register("refund_reason")}></textarea>
                                <div className='flex space-x-4 mt-7'>
                                    <button type='button' className='w-1/2 border border-ooolab_dark_300 text-ooolab_sm text-ooolab_dark_300 px-ooolab_p_3 py-ooolab_p_1_e rounded-lg focus:outline-none'
                                        onClick={() => { setModalRefund(false); }}>{translator("No, cancel")}</button>
                                    <button type='submit' className='w-1/2 bg-red-500 text-white text-ooolab_sm px-ooolab_p_3 py-ooolab_p_1_e rounded-lg focus:outline-none'>
                                        {isSubmit ? <CircleSpin color="text-[#ffffff]" className='mx-auto' /> : translator('Yes, Cancel!')}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
        <ToastContainer />
    </>;
};

export default TableRewardDashBoard;
