import * as React from 'react';

// COMPONENT
import CreatePasswordForm from 'components/Authentication/AuthenticationRegisterForm/CreatePasswordForm';
// TYPES
import { AuthType } from 'types/Auth.type';
import ImagesSlider from 'components/ImagesSlider/ImagesSliderRegis';

export interface CreatePasswordPageProps {
    storageUserInfo: AuthType;
    setAuthStorage: React.Dispatch<React.SetStateAction<boolean>>;
    storageUserInfoSession: AuthType;
}

const CreatePasswordPage: React.FC<CreatePasswordPageProps> = ({
    storageUserInfo,
    setAuthStorage,
    storageUserInfoSession,
}) => {
    return (
        <div className="min-h-screen flex">
            <div className="hidden lg:block width30">
                <ImagesSlider />
            </div>
            <div className="lg:w-w_70 w-full">
                <CreatePasswordForm
                    storageUserInfo={storageUserInfo}
                    setAuthStorage={setAuthStorage}
                    storageUserInfoSession={storageUserInfoSession}
                />
            </div>
        </div>
    );
};

export default CreatePasswordPage;
