import { useContext, useEffect, useMemo } from 'react'
import { InputField } from 'components/InputForm/InputForm';
import { useTranslation } from 'react-i18next';
import { SwitchToggle } from '_shared/components/Form/SwitchToggle/SwitchToggle';
import { AsyncSelectField, SelectField } from 'components/Select';
import { useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';
import InputRadio from '_shared/components/Form/InputRadio/InputRadio';
import "../../CourseSetting.scss";
import DatePickerInput from 'components/Management/components/Form/DatePicker';
import { InputCheckbox } from '_shared/components/Form/InputCheckbox/InputCheckbox';
import { ButtonPrimary } from '_shared';
import moment from 'moment';
import { VALIDATE_FIELD } from 'utils/validate';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { Toast } from 'utils/sweetAlert';
import ReactTooltip from 'react-tooltip';

const DURATION_TYPE = [
    { label: 'Month(s)', value: 'month' },
    { label: 'Week(s)', value: 'week' },
    { label: 'Day(s)', value: 'day' }
];

export const EnrollmentType = ({ hasCourse = true, notAllowEdit = false, formProps }) => {
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const {
        result
    } = getWorkspaceDetailState;
    const params: { id: string; courseId: string } = useParams();
    const { t: translator } = useTranslation();
    const { control, register, unregister, formState: { errors }, watch, reset, getValues, setValue, clearErrors } = formProps;
    const handleLearningGroupLoadOptions = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional.page,
            per_page: 20,
            sort_by: 'name',
            order: 'asc',
            name: inputValue || null,
            status: 'active'
        };
        if (!payload?.name) delete payload.name;

        const { items, total } = await fetchLearningGroupe(payload);
        const newOptions = items.map((el: any) => ({
            id: el.id,
            label: el.name,
            value: el.id,
        }));

        return {
            options: newOptions,
            hasMore: additional.page < total / 20,
            additional: {
                page: additional.page + 1,
            },
        };
    };

    const fetchLearningGroupe = async (bodyParams?: any) => {
        const res = await workspaceService.getLearningGroupService(params.id, bodyParams);
        return {
            items: res?.data?.items,
            total: res?.data?.total,
        };
    };

    const enrollment_type = watch('enrollment_type');
    const allowFlexibleEnroll = watch('allowFlexibleEnroll');
    const catalogAvailability = watch('catalogAvailability');
    const catalogPrice = watch('catalogPrice');

    const hasLearnerEnrolled = useMemo(() => {
        return !getValues('allow_change_enrollment_type') && enrollment_type == 'credit';
    }, [getValues('allow_change_enrollment_type'), enrollment_type])

    const resetPrice = () => {
        setValue('price_value', null);
        setValue('price_currency', null);
        setValue('discount_type', null);
        setValue('discount_value', null);
        setValue('discount_percentage', null);
    }

    useEffect(() => {
        if (getValues('enrollment_type') != 'credit' && getValues('enrollment_type') != 'catalog') {
            setValue('enrollment_type', allowFlexibleEnroll ? 'open_auto_enroll' : 'open_not_auto_enroll');
            if (enrollment_type === 'open_auto_enroll' || getValues().enrollment_type === 'open_auto_enroll') {
                register('duration', {
                    required: "This field is required",
                    min: { value: 1, message: "Enrollment Duration has to be at least 1" },
                });
                setValue('duration', getValues()?.duration ?? '');
            }
        } else {
            clearErrors('duration_type');
            unregister(["duration"]);
        }

    }, [allowFlexibleEnroll, enrollment_type]);

    useEffect(() => {
        if (enrollment_type === 'open_auto_enroll') {
            setValue('allowFlexibleEnroll', true);
        } else {
            setValue('allowFlexibleEnroll', false);
            setValue('learningGroups', null);
            setValue('duration', null);
            setValue('duration_type', null)
        }
        if (enrollment_type != 'catalog') {
            setValue('catalogAvailability', null);
            setValue('availability_from_time', null);
            setValue('availability_to_time', null);
            setValue('price_value', null);
            setValue('catalogPrice', null);
            setValue('price_currency', null);
            setValue('learner_portal', false);
            setValue('public_catalog', false);
            setValue('is_featured_course', false);
            resetPrice();
        }
    }, [enrollment_type]);

    useEffect(() => {
        if (catalogAvailability === 'indefinite') {
            setValue('availability_from_time', null);
            setValue('availability_to_time', null);
        }
    }, [catalogAvailability]);

    useEffect(() => {
        if (catalogPrice === 'free') {
            resetPrice();
        } else if (catalogPrice) {
            setValue('discount_type', getValues()?.discount_type || 'none');
        }
    }, [catalogPrice]);

    useEffect(() => {
        if (watch('discount_type') === 'value') {
            setValue('discount_percentage', null);
        }
        if (watch('discount_type') === 'percentage') {
            setValue('discount_value', null);
        }
        if (watch('discount_type') === 'none') {
            setValue('discount_value', null);
            setValue('discount_percentage', null);
        }
        clearErrors();
    }, [watch('discount_type')])

    return (
        <div className='pb-10'>
            <div className="courseSettings-title">
                {translator('COURSES.SETTING.Enrollment_Type')}
                <p>{translator('COURSES.SETTING.Customize_enrollment')}</p>
            </div>
            <div className={`courseSettings-container  ${notAllowEdit ? 'opacity-container' : ''}  `}>
                <div className="courseSettings-item flex-col !border-0">
                    <div className="flex pl-5 items-start gap-2 mt-2">
                        <div className="items-start"
                            data-for={`Message_Change_Enroll_Type-1`}
                            data-tip={translator('COURSES.SETTING.Message_Change_Enroll_Type')}
                        >
                            <InputRadio control={control}
                                inputName="enrollment_type"
                                value="open_not_auto_enroll"
                                checked={enrollment_type != 'credit' && enrollment_type != 'catalog'}
                                disabled={notAllowEdit || hasLearnerEnrolled}
                            />
                            {/*@ts-ignore*/
                                hasLearnerEnrolled && <ReactTooltip
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    className='!opacity-100'
                                    id={`Message_Change_Enroll_Type-1`}
                                    getContent={(content) => <div className='text-xs font-normal'>{content}</div>}
                                />}
                        </div>
                        <div className="courseSettings-right">
                            <div className="courseSettings-label">{translator('COURSES.SETTING.Open')}</div>
                            <p className="mt-1">{translator('COURSES.SETTING.Any_learner')}</p>
                            <div className="flex flex-col mt-5">
                                <div className="flex gap-2">
                                    <div className="courseSettings-left"
                                        data-for={`Message_Change_Enroll_Type-2`}
                                        data-tip={translator('COURSES.SETTING.Message_Change_Enroll_Type')}
                                    >
                                        <SwitchToggle
                                            name="allowFlexibleEnroll"
                                            control={control}
                                            disabled={notAllowEdit || enrollment_type === 'credit' || hasLearnerEnrolled}
                                            enableColor
                                        />
                                        {/*@ts-ignore*/
                                            hasLearnerEnrolled && <ReactTooltip
                                                place="top"
                                                type="dark"
                                                effect="float"
                                                className='!opacity-100'
                                                id={`Message_Change_Enroll_Type-2`}
                                                getContent={(content) => <div className='text-xs font-normal'>{content}</div>}
                                            />}
                                    </div>
                                    <div className="courseSettings-right">
                                        <div className="courseSettings-label !font-normal">{translator('COURSES.SETTING.Auto_Enroll')}</div>
                                        <p>{translator('COURSES.SETTING.Any_new')}</p>
                                        {hasCourse ? <div className="mt-2">
                                            <div className="courseSettings-label !font-semibold !text-xs mb-1">{translator('COURSES.SETTING.Learning_Group')}</div>
                                            <AsyncSelectField
                                                control={control}
                                                label=""
                                                name="learningGroups"
                                                className="col-span-4"
                                                placeholder={translator('COURSES.SETTING.Search_select_Learning_Groups')}
                                                loadOptions={handleLearningGroupLoadOptions}
                                                customStyles={{
                                                    control: (base) => {
                                                        return { ...base, minHeight: '0px', height: '32px' }
                                                    },
                                                    indicatorsContainer: (base) => {
                                                        return { ...base, padding: '0px 8px', height: '32px' }
                                                    },
                                                }}
                                                isMulti={true}
                                                searchIcon={true}
                                                isDisabled={!allowFlexibleEnroll || notAllowEdit}
                                            />
                                        </div> : null}
                                        <div className="mt-2">
                                            <div className="courseSettings-label !font-semibold !text-xs">{translator('COURSES.SETTING.Enrollment_Duration')}</div>
                                            <div className="flex gap-1 mt-1">
                                                <InputField
                                                    name="duration"
                                                    maxLength={255}
                                                    classNameInput="h-[32px] w-[69px]"
                                                    placeholder={translator('COURSES.NUMBER')}
                                                    register={register('duration')}
                                                    errors={errors}
                                                    disabled={!allowFlexibleEnroll || notAllowEdit}
                                                />
                                                <SelectField
                                                    control={control}
                                                    name="duration_type"
                                                    placeholder={translator('COURSES.SELECT_DURATION')}
                                                    isSearchable={false}
                                                    options={DURATION_TYPE}
                                                    customStyles={{
                                                        control: (base) => {
                                                            return { ...base, minHeight: '0px', height: '32px' }
                                                        },
                                                        indicatorsContainer: (base) => {
                                                            return { ...base, padding: '0px 8px', height: '32px' }
                                                        },
                                                    }}
                                                    errors={errors}
                                                    isDisabled={!allowFlexibleEnroll || notAllowEdit}
                                                    isRequired={allowFlexibleEnroll}
                                                />
                                            </div>
                                            <p className="mt-1">{translator('COURSES.SETTING.Set_total_duration')}</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex pl-5 gap-2 mt-2">
                        <div data-for={`Message_Change_Enroll_Type-3`}
                            data-tip={translator('COURSES.SETTING.Message_Change_Enroll_Type')}>
                            <InputRadio control={control}
                                inputName="enrollment_type"
                                value="credit"
                                checked={enrollment_type == 'credit'}
                                disabled={notAllowEdit || !getValues('allow_change_enrollment_type')}
                            />
                            {/*@ts-ignore*/
                                !getValues('allow_change_enrollment_type') && <ReactTooltip
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    className='!opacity-100'
                                    id={`Message_Change_Enroll_Type-3`}
                                    getContent={(content) => <div className='text-xs font-normal'>{content}</div>}
                                />}
                        </div>
                        <div className="flex-col">
                            <div className="courseSettings-label">{translator('COURSES.SETTING.Credits')}</div>
                            <p>{translator('COURSES.SETTING.Only_learners')}</p>
                        </div>
                    </div>
                    <div className="flex pl-5 gap-2 mt-2">
                        <div data-for={`Message_Change_Enroll_Type-4`}
                            data-tip={translator('COURSES.SETTING.Message_Change_Enroll_Type')}>
                            <InputRadio control={control}
                                inputName="enrollment_type"
                                value="catalog"
                                checked={enrollment_type == 'catalog'}
                                disabled={notAllowEdit || hasLearnerEnrolled}
                            />
                            {/*@ts-ignore*/
                                hasLearnerEnrolled && <ReactTooltip
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    className='!opacity-100'
                                    id={`Message_Change_Enroll_Type-4`}
                                    getContent={(content) => <div className='text-xs font-normal'>{content}</div>}
                                />}
                        </div>
                        <div className="flex-col">
                            <div className="courseSettings-label">{translator('COURSES.SETTING.Course_Catalog')}</div>
                            <p>{translator('COURSES.SETTING.Any_learners_enrolled')}</p>
                            <div className="mt-2">
                                <div className="courseSettings-label !font-semibold !text-sm">{translator('COURSES.SETTING.Availability')}</div>
                                <div className='ml-5'>
                                    <div className="flex gap-2 mt-1">
                                        <InputRadio control={control}
                                            inputName="catalogAvailability"
                                            value="dateRange"
                                            checked={watch('catalogAvailability') == 'dateRange'}
                                            disabled={notAllowEdit || enrollment_type != 'catalog'}
                                        />
                                        <div className='courseSettings-label !text-xs pt-1.5'>
                                            {translator('COURSES.SETTING.Date_range')}
                                            <div className='flex ml-0.5 mt-1 gap-2'>
                                                <div className='flex gap-2 items-center'>
                                                    <label className='text-xs text-gray-500'>{translator('COURSES.SETTING.From')}</label>
                                                    <DatePickerInput
                                                        control={control}
                                                        name="availability_from_time"
                                                        placeholderText={translator('DD/MM/YYYY')}
                                                        minDate={new Date()}
                                                        disabled={watch('catalogAvailability') != 'dateRange' || notAllowEdit || enrollment_type != 'catalog'}
                                                        isRequired={watch('catalogAvailability') == 'dateRange'}
                                                        formatDate="dd/MM/yyyy"
                                                        classnames={`${watch('catalogAvailability') == 'dateRange' && !watch('availability_from_time') ? 'border border-red-500' : null}`}
                                                    />
                                                </div>
                                                <div className='flex gap-2 items-center'>
                                                    <label className='text-xs text-gray-500'>To</label>
                                                    <DatePickerInput
                                                        control={control}
                                                        name="availability_to_time"
                                                        placeholderText={translator('DD/MM/YYYY')}
                                                        minDate={new Date(moment(getValues('availability_from_time') ?
                                                            getValues('availability_from_time') : new Date())
                                                            .add(1, 'day')
                                                            .format('YYYY-MM-DD'))}
                                                        disabled={watch('catalogAvailability') != 'dateRange' || notAllowEdit || enrollment_type != 'catalog'}
                                                        isRequired={watch('catalogAvailability') == 'dateRange'}
                                                        formatDate="dd/MM/yyyy"
                                                        classnames={`${watch('catalogAvailability') == 'dateRange' && !watch('availability_to_time') ? 'border border-red-500' : null}`}
                                                    />
                                                </div>
                                            </div>
                                            <span className='text-xs text-red-500 font-semibold tracking-wider mt-1 leading-4'>{watch('catalogAvailability') == 'dateRange' && (!watch('availability_from_time') || !watch('availability_to_time')) ? translator('FORM_CONST.REQUIRED_FIELD') : null}</span>
                                        </div>

                                    </div>
                                    <div className="flex gap-2 mt-1">
                                        <InputRadio control={control}
                                            inputName="catalogAvailability"
                                            value="indefinite"
                                            checked={watch('catalogAvailability') == 'indefinite'}
                                            disabled={notAllowEdit || enrollment_type != 'catalog'}
                                        />
                                        <div className='courseSettings-label !text-xs pt-1.5'>
                                            {translator('COURSES.SETTING.Indefinite')}
                                        </div>
                                    </div>
                                </div>
                                <div className="courseSettings-label !font-semibold !text-sm mt-5">
                                    {translator('COURSES.SETTING.Price')}
                                </div>
                                <div className='ml-5'>
                                    <div className="flex gap-2 mt-1">
                                        <InputRadio control={control}
                                            inputName="catalogPrice"
                                            value="price"
                                            checked={watch('catalogPrice') == 'price'}
                                            disabled={notAllowEdit || enrollment_type != 'catalog'}
                                        />
                                        <div className='courseSettings-label !font-semibold !text-xs pt-1.5'>
                                            {translator('COURSES.SETTING.Price')}
                                            <div className='flex gap-2 mt-1 items-center'>
                                                <div className='courseSettings-price relative mb-1'>
                                                    <InputField
                                                        name="price_value"
                                                        maxLength={255}
                                                        classNameInput="flex-none h-[32px] !w-[70px]"
                                                        placeholder={translator('Price')}
                                                        errors={errors}
                                                        disabled={notAllowEdit || watch('catalogPrice') != 'price'}
                                                        classError='relative whitespace-nowrap'
                                                        register={register('price_value', {
                                                            required: {
                                                                value: watch('catalogPrice') == 'price',
                                                                message: translator('FORM_CONST.REQUIRED_FIELD'),
                                                            },
                                                            pattern: {
                                                                value: VALIDATE_FIELD.NUMBER_FLOAT,
                                                                message: translator('FORM_CONST.NUMBER_ONLY_AND_NUMBERS_GREATER_THAN_ZERO'),
                                                            },
                                                        })}
                                                    />
                                                </div>
                                                <span className='uppercase'>{result?.default_currency?.code}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-2 mt-2">
                                        <InputRadio control={control}
                                            inputName="catalogPrice"
                                            value="free"
                                            checked={watch('catalogPrice') == 'free'}
                                            disabled={notAllowEdit || enrollment_type != 'catalog'}
                                        />
                                        <div className='courseSettings-label !font-semibold !text-xs pt-1.5'>
                                            {translator('COURSES.SETTING.Free')}
                                        </div>
                                    </div>
                                </div>
                                <div className="courseSettings-label !font-semibold !text-sm mt-5">
                                    {translator('COURSES.SETTING.Discount')}
                                </div>
                                <div className='ml-5'>
                                    <div className="flex gap-2 mt-1">
                                        <InputRadio control={control}
                                            inputName="discount_type"
                                            value="none"
                                            checked={watch('discount_type') == 'none'}
                                            disabled={notAllowEdit || watch('catalogPrice') != 'price'}
                                        />
                                        <div className='courseSettings-label !font-semibold !text-xs pt-1'>
                                            {translator('COURSES.SETTING.None')}
                                        </div>
                                    </div>
                                    <div className="flex gap-2 mt-2">
                                        <InputRadio control={control}
                                            inputName="discount_type"
                                            value="value"
                                            checked={watch('discount_type') == 'value'}
                                            disabled={notAllowEdit || watch('catalogPrice') != 'price'}
                                        />
                                        <div className='courseSettings-label !font-semibold !text-xs pt-1'>
                                            {translator('COURSES.SETTING.Fixed_Amount')}
                                        </div>
                                    </div>
                                    <div className='courseSettings-price relative flex gap-2 items-center mt-2 ml-[28px] mb-3'>
                                        <InputField
                                            name="discount_value"
                                            maxLength={255}
                                            classNameInput="flex-none h-[32px] !w-[70px]"
                                            placeholder={''}
                                            errors={errors}
                                            disabled={notAllowEdit || watch('catalogPrice') != 'price' || watch('discount_type') != 'value'}
                                            classError='relative whitespace-nowrap'
                                            register={register('discount_value', {
                                                required: {
                                                    value: watch('discount_type') == 'value',
                                                    message: translator('FORM_CONST.REQUIRED_FIELD'),
                                                },
                                                pattern: {
                                                    value: VALIDATE_FIELD.NUMBER_FLOAT,
                                                    message: translator('FORM_CONST.NUMBER_ONLY_AND_NUMBERS_GREATER_THAN_ZERO'),
                                                },
                                                max: {
                                                    value: watch('price_value') || 0,
                                                    message: translator('FORM_CONST.MAX_LENGTH_FIELD', {
                                                        number: watch('price_value') || 0
                                                    })
                                                },
                                                min: {
                                                    value: watch('discount_type') == 'value' ? 1 : 0,
                                                    message: translator('FORM_CONST.FIX_AMOUNNT_MIN_LENGTH_FIELD', {
                                                        number: 0
                                                    })
                                                }
                                            })}
                                        />
                                        <span className='uppercase text-xs font-semibold text-gray-800'>{result?.default_currency?.code}</span>
                                    </div>
                                    <div className="flex gap-2 mt-2">
                                        <InputRadio control={control}
                                            inputName="discount_type"
                                            value="percentage"
                                            checked={watch('discount_type') == 'percentage'}
                                            disabled={notAllowEdit || watch('catalogPrice') != 'price'}
                                        />
                                        <div className='courseSettings-label !font-semibold !text-xs pt-1'>
                                            {translator('COURSES.SETTING.Percentage')}
                                        </div>
                                    </div>
                                    <div className='mt-2 ml-[28px]'>
                                        <InputField
                                            name="discount_percentage"
                                            maxLength={255}
                                            classNameInput="flex-none h-[32px] !w-[70px]"
                                            placeholder={''}
                                            errors={errors}
                                            disabled={notAllowEdit || watch('catalogPrice') != 'price' || watch('discount_type') != 'percentage'}
                                            classError='relative whitespace-nowrap'
                                            register={register('discount_percentage', {
                                                required: {
                                                    value: watch('discount_type') == 'percentage',
                                                    message: translator('FORM_CONST.REQUIRED_FIELD'),
                                                },
                                                pattern: {
                                                    value: VALIDATE_FIELD.NUMBER_1_100,
                                                    message: translator('FORM_CONST.REQUIRED_DURATION_RANGE')
                                                }
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className="courseSettings-label !font-semibold !text-sm mt-5">
                                    {translator('COURSES.SETTING.Location')}
                                </div>
                                <div className='ml-5'>
                                    <div className="flex gap-2 mt-1">
                                        <InputCheckbox inputName="learner_portal" control={control} disabled={notAllowEdit || enrollment_type != 'catalog'} />
                                        <div className='courseSettings-label !font-semibold !text-xs pt-1'>
                                            {translator('COURSES.SETTING.Explore_Page')}
                                        </div>
                                    </div>
                                    <div className="flex gap-2 mt-2">
                                        <InputCheckbox inputName="public_catalog" control={control} disabled={notAllowEdit || enrollment_type != 'catalog'} />
                                        <div className='courseSettings-label !font-semibold !text-xs pt-1'>
                                            {translator('COURSES.SETTING.PublicCourse_Catalog')}
                                        </div>
                                    </div>
                                    {watch('public_catalog') && result?.public_catalog_url ? <div className="gap-2 mt-2 items-center">
                                        <label className='text-gray-500 text-sm'>URL</label>
                                        <div className='courseSettings-label !font-semibold !text-xs pt-1 w-full'>
                                            <div className='border border-gray-200 text-gray-400 text-sm rounded py-1.5 px-2.5 gap-2 items-center bg-gray-100 flex justify-around'>
                                                <div className='w-full truncate'>{result?.public_catalog_url}</div>
                                                <ButtonPrimary type='button' size='M' onClick={() => {
                                                    navigator.clipboard.writeText(result?.public_catalog_url);
                                                    Toast.fire({
                                                        icon: 'success',
                                                        title: translator('COURSES.SETTING.Copied')
                                                    });
                                                }} >{translator("COPY")}</ButtonPrimary>
                                            </div>
                                        </div>
                                    </div> : null}
                                </div>
                                <div className="flex gap-2 mt-5">
                                    <SwitchToggle name={'is_featured_course'} control={control} disabled={notAllowEdit || enrollment_type != 'catalog'} enableColor />
                                    <div>
                                        <div className='courseSettings-label !font-semibold !text-sm pt-1'>
                                            {translator('COURSES.SETTING.Featured_Course')}
                                        </div>
                                        <p>
                                            {translator('COURSES.SETTING.Display_front_page')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}