import React from 'react';
import ManagementMasterPage from 'pages/RouteMasterPage';
import SalesforceIntegration from 'components/Management/SalesforceIntegration/SalesforceIntegrationList';
import ManagePermissionAdmin from 'pages/Management/ManagePermissionAdmin';

const SalesforceIntegrationPage = () => {
    return (
        <ManagePermissionAdmin>
            <SalesforceIntegration>
                <ManagementMasterPage />
            </SalesforceIntegration>
        </ManagePermissionAdmin>
    );
};

export default SalesforceIntegrationPage;
