import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryParamsURL } from 'hooks/useQueryParamsURL';
import { tabsDownloadData } from './downloadConstant';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { TabLeanerData } from './TabLeanerData';
import { TabInstructorTAData } from './TabInstructorTAData';
import { TabCoursesData } from './TabCoursesData';
import { checkPermission } from 'utils/commonFun';

export const DownloadData = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const params: { id: string } = useParams();
	const query = useQueryParamsURL();

    const workspaceId = params.id;

	const [currentTab, setCurrentTab] = useState<string>();

    const translator = (str: string, opt?: any) => t(`DOWNLOAD_DATA.${str}`, opt);

    const tabs = tabsDownloadData.filter(el => checkPermission(el?.roleName, 'R'));

    useEffect(() => {
        let tab = tabs[0]?.url || '';
        if (!!query.get('tab')) {
            tab = tabs.find(el => query.get('tab') === el.url)?.url;
        }
        if (tab) {
            setCurrentTab(tab);
            history.replace(`/workspace/${workspaceId}/report/dashboard-export?tab=${tab}`);
        }
    }, []);

    return (
        <div>
            <PageBreadcrumb
                pageNameDefault={t('DASHBOARD.SIDEBAR.ANALYTICS')}
                pages={[{ name: t('DASHBOARD.SIDEBAR.DOWNLOAD_DATA'), href: '', className: '' }]}
                containerClassName="z-10 sticky top-0 w-full"
            />
            <h1 className="text-xl text-gray-800 font-semibold px-5 py-4">{t('DASHBOARD.SIDEBAR.DOWNLOAD_DATA')}</h1>
            <div className="flex items-center px-5 border-b">
                {tabs.map((tab, index) => (
                    <p
                        key={index}
                        className={`px-4 py-3 text-sm font-semibold cursor-pointer ${currentTab === tab.url ? 'text-primary-500 border-b border-primary-500' : 'text-gray-500'}`}
                        onClick={() => {
							setCurrentTab(tab.url);
							history.replace(`/workspace/${workspaceId}/report/dashboard-export?tab=${tab.url}`)}
						}
                    >
                        {translator(tab.name)}
                    </p>
                ))}
            </div>
			<div className='p-5 bg-gray-50 text-gray-800'>
				{currentTab === tabsDownloadData[0].url && <TabLeanerData />}
				{currentTab === tabsDownloadData[1].url && <TabInstructorTAData />}
                {currentTab === tabsDownloadData[2].url && <TabCoursesData />}
			</div>
        </div>
    );
};
