export const EditIcon = ({
    fill = '#111827',
    width = '16',
    height = '18',
}: {
    fill?: string;
    width?: string;
    height?: string;
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.345 12.3334L11.7967 3.8817L10.6183 2.70336L2.16667 11.155V12.3334H3.345ZM4.03583 14H0.5V10.4642L10.0292 0.935029C10.1854 0.778803 10.3974 0.69104 10.6183 0.69104C10.8393 0.69104 11.0512 0.778803 11.2075 0.935029L13.565 3.29253C13.7212 3.4488 13.809 3.66073 13.809 3.8817C13.809 4.10267 13.7212 4.31459 13.565 4.47086L4.03583 14ZM0.5 15.6667H15.5V17.3334H0.5V15.6667Z"
                fill={fill}
            />
        </svg>
    );
};
