import axios from './../restful.service';
import { REPORT } from 'constant/api.const';

const getLearner  = (workspaceId: string, params) => {
    return axios.get(REPORT.getLearnerDashboard(workspaceId), {params});
}

const getLearnerGroup  = (workspaceId: string, studentId) => {
    return axios.get(`/workspaces/${workspaceId}/learning-groups/${studentId}`);
}

const getLearnerOverviews  = (workspaceId: string, params) => {
    return axios.get(REPORT.getLearnerDashboard(workspaceId) + `/${params?.studentId}/overviews`, {params});
}
const getLearnerGroupOverviews  = (workspaceId: string, params) => {
    return axios.get(REPORT.getLearnerDashboard(workspaceId) + `/learning-groups/${params?.studentId}/overviews`, {params});
}

const getLearnerOverviewsCoins  = (workspaceId: string, params) => {
    return axios.get(REPORT.getLearnerDashboard(workspaceId) + `/${params?.studentId}/overviews/coins`, {params});
}

const getLearnerGroupOverviewsCoins  = (workspaceId: string, params) => {
    return axios.get(REPORT.getLearnerDashboard(workspaceId) + `/learning-groups/${params?.studentId}/overviews/coins`, {params});
}

const getLearnerAcademics  = (workspaceId: string, params) => {
    return axios.get(REPORT.getLearnerDashboard(workspaceId) + `/${params?.studentId}/academics/courses`, {params});
}

const getLearnerGroupAcademics  = (workspaceId: string, params) => {
    return axios.get(REPORT.getLearnerDashboard(workspaceId) + `/learning-groups/${params?.studentId}/academics/courses`, {params});
}

const assignmentsStat = (workspaceId: string, params) => {
    return axios.get(REPORT.getLearnerDashboard(workspaceId) + `/${params?.studentId}/assignments/stat`, {params});
}

const assignmentsGroupStat = (workspaceId: string, params) => {
    return axios.get(REPORT.getLearnerDashboard(workspaceId) + `/learning-groups/${params?.studentId}/assignments/stat`, {params});
}

const assignments = (workspaceId: string, params) => {
    return axios.get(REPORT.getLearnerDashboard(workspaceId) + `/${params?.studentId}/assignments`, {params});
}

const assignmentsGroup = (workspaceId: string, params) => {
    return axios.get(REPORT.getLearnerDashboard(workspaceId) + `/learning-groups/${params?.studentId}/assignments`, {params});
}

const sessionsOverviews = (workspaceId: string, params) => {
    return axios.get(REPORT.getLearnerDashboard(workspaceId) + `/${params?.studentId}/sessions/overviews`, {params});
}

const sessionsGroupOverviews = (workspaceId: string, params) => {
    return axios.get(REPORT.getLearnerDashboard(workspaceId) + `/learning-groups/${params?.studentId}/sessions/overviews`, {params});
}

const sessionsBreakdown = (workspaceId: string, params) => {
    return axios.get(REPORT.getLearnerDashboard(workspaceId) + `/${params?.studentId}/sessions/breakdown`, {params});
}

const sessionsGroupBreakdown = (workspaceId: string, params) => {
    return axios.get(REPORT.getLearnerDashboard(workspaceId) + `/learning-groups/${params?.studentId}/sessions/breakdown`, {params});
}

const sessionsType = (workspaceId: string, params) => {
    return axios.get(REPORT.getLearnerDashboard(workspaceId) + `/${params?.studentId}/sessions/type`, {params});
}

const sessionsGroupType = (workspaceId: string, params) => {
    return axios.get(REPORT.getLearnerDashboard(workspaceId) + `/learning-groups/${params?.studentId}/sessions/type`, {params});
}

const getLearnerDetail = (workspaceId: string, studentId) => {
    return axios.get(`/workspaces/${workspaceId}/students/${studentId}`);
}

export default {
    getLearner,
    getLearnerOverviews,
    getLearnerOverviewsCoins,
    getLearnerAcademics,
    assignmentsStat,
    assignments,
    sessionsOverviews,
    sessionsBreakdown,
    sessionsType,
    getLearnerDetail,
    getLearnerGroupOverviews,
    getLearnerGroupOverviewsCoins,
    getLearnerGroup,
    getLearnerGroupAcademics,
    assignmentsGroupStat,
    assignmentsGroup,
    sessionsGroupOverviews,
    sessionsGroupBreakdown,
    sessionsGroupType
}