import React, { useContext } from 'react';
import dayjs from 'dayjs';
import {Column} from 'react-table';
import Table, { RenderTableCell, RenderTableHeader } from 'components/V2/Table/TableCustom';
import { UserContext } from 'contexts/User/UserContext';
import empty_icon from 'assets/img/empty-transaction-icon.png';
import { ImageAvatar } from 'components/Image';
import { STATUS_COLOR } from 'constant/util.const';

export const TableCreditAccountDetail = ({ workspaceId, userId, dataTable, translator, onViewDetail }) => {
    const {
        userState: { result },
    } = useContext(UserContext);

    const initialState = {
        hiddenColumns: [],
    };

    const columns = React.useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('TRANSACTED_DATE')} />,
                Cell: ({ row }: any) => {
                    return (
                        <RenderTableCell
                            value={
                                row.values?.created_at
                                    ? dayjs(row.values?.created_at)
                                          .tz(result?.time_zone || 'UTC')
                                          .format('DD/MM/YYYY HH:mm:ss')
                                    : ''
                            }
                        />
                    );
                },
                accessor: 'created_at',
                width: 100,
            },
            {
                Header: () => <RenderTableHeader value={translator('DESCRIPTION')} />,
                Cell: ({ row }: any) => {
                    return (
                        <RenderTableCell
                            value={row.values?.description + (!!row?.original?.course ? ` ${row?.original?.course?.title} (ID ${row?.original?.course?.id})` : '')}
                            className="!text-enterprise !font-semibold cursor-pointer whitespace-pre-line"
                            onClick={() => !!row.values?.description && onViewDetail?.(row.original)}
                        />
                    );
                },
                accessor: 'description',
                width: 200,
            },
            {
                Header: () => <RenderTableHeader value={translator('TRANSACTED_BY')} />,
                Cell: ({ row, column }: { row: any; column: Column }) => {
                    return (
                        <div className="truncate h-full flex items-center space-x-1 pl-ooolab_p_1_e">
                            {!!row.values?.creator && (
                                <>
                                    <ImageAvatar imageSrc={row.values?.creator?.avatar_url} />
                                    <span className="truncate text-xs font-semibold text-enterprise">
                                        {row.values?.creator?.display_name}
                                    </span>
                                </>
                            )}
                        </div>
                    );
                },
                accessor: 'creator',
                width: 120,
            },
            {
                Header: () => <RenderTableHeader value={translator('AMOUNT')} />,
                Cell: ({ row }: any) => {
                    let amount = row.values?.amount;
                    let color = '';
                    if (row?.original?.txn_type === 'increase') {
                        amount = `+${amount}`;
                        color = STATUS_COLOR.increase;
                    } else if (row?.original?.txn_type === 'decrease') {
                        amount = `-${amount}`;
                        color = STATUS_COLOR.decrease;
                    }
                    return <RenderTableCell value={amount} styles={{ color }} />;
                },
                accessor: 'amount',
                width: 60,
            },
            {
                Header: () => <RenderTableHeader value={translator('BALANCE')} />,
                Cell: ({ row }) => {
                    return <RenderTableCell value={`${row.values?.credit_balance || 0}`} />;
                },
                accessor: 'credit_balance',
                width: 60,
            },
        ],
        [dataTable]
    );
    return (
        <Table
            isBorderFirstColum={false}
            data={dataTable}
            columns={columns}
            initialState={initialState}
            emptyContent={translator('NO_DATA_TRANSACTION')}
            emptyIcon={empty_icon}
        />
    );
};
