import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';
import { UserService, WorkspaceService } from 'services';
import { GetListOfWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { GET_WORKSPACE_DETAIL } from 'actions/workspace.action';
import { formatPermission, onCheckPermissions } from 'constant/workspace.const';
import { CircleSpin } from 'components/UiComponents/CircleSpin';

function RouteChangeHandler() {
    const location = useLocation();
    const {
        dispatch: dispatchList,
    } = React.useContext(GetListOfWorkspaceContext);
    const [isSwitching, setIsSwitching] = useState(false);
    useEffect(() => {
        if(location?.pathname) {
            if (location?.pathname.indexOf('workspace') === -1) {
                return;
            }
            const workspaceId = location?.pathname?.split('/')?.[2];
            let currentWorkSpace = localStorage.getItem('workspaceId');
            if (!workspaceId) {
                return;
            }
            if (!currentWorkSpace) {
                localStorage.setItem('workspaceId', workspaceId);
                return;
            }
            if (JSON.parse(currentWorkSpace) == workspaceId) {
                return;
            }
            setIsSwitching(true);
            localStorage.setItem('workspaceId', JSON.stringify(workspaceId));
            workspaceService.onAuthWorkspace(workspaceId).then(async () => {
                UserService.getUser().then((userData) => {
                    WorkspaceService.getPermissionsUserWorkspace().then((res) => {
                        setIsSwitching(false);
                        dispatchList({
                            type: GET_WORKSPACE_DETAIL.REQ_GET_WORKSPACE_PERMISSIONS,
                            result: formatPermission(res?.data),
                        });
                        onCheckPermissions(userData?.membership?.user_role?.role_type, userData?.membership?.user_role?.name, formatPermission(res?.data), history, workspaceId, true, location?.pathname)
                    })
                })
            });
        }
    }, []);

    return isSwitching ? <div className='fixed top-0 lef-0 bg-white flex items-center justify-center w-full h-full z-[99] text-sm'>
        <CircleSpin color='text-primary-500' /> Switching WorkSpace
    </div> : null;
}

export default RouteChangeHandler;