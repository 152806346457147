import React, { FC, useState } from 'react';
import { Row, Column } from 'react-table';
import Table, { RenderTableHeader, RenderTableCell, LinkCustom } from 'components/V2/Table/TableCustom';
import dayjs from 'dayjs';
import SessionDetailsModal from 'components/V2/SessionDetailModal';
import { TeacherDashboardService } from 'services';
import { useParams } from 'react-router';
import { STATUS } from '../util';
import NO_STUDENT from 'assets/img/empty-state/student.png';

type TableStudentProps = {
    workspaceId: string;
    dataTable: any;
    translator: (key: string) => string;
    fetchStudentList: () => void;
    sessionName: string;
};

const TableStudent: FC<TableStudentProps> = ({ workspaceId, dataTable, translator, fetchStudentList, sessionName }) => {
    const initialState = {
        hiddenColumns: ['id'],
    };
    const [isOpenModalDetail, setOpenModalDetail] = useState<boolean>(false);
    const [sessionId, setSessionId] = useState<string>();
    const params: any = useParams();

    const columns = React.useMemo(
        () => [
            { accessor: 'id' },
            {
                Header: () => <RenderTableHeader value={translator('NAME')} />,
                Cell: ({ row }: any) => {
                    return (
                        <LinkCustom
                            to={`/workspace/${workspaceId}/management/users/${row.original?.id}`}
                            isTooltip
                            value={row.values?.display_name}
                            disable={row.values?.display_name?.length < 35}
                        />
                    );
                },
                accessor: 'display_name',
                width: 150,
            },
            {
                Header: () => <RenderTableHeader value={translator('EMAIL')} />,
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return <RenderTableCell value={row.values?.email} />;
                },
                accessor: 'email',
                width: 150,
            },
            {
                Header: () => <RenderTableHeader value={translator('ENROLLED_DATE')} />,
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return <RenderTableCell value={dayjs(row.values?.invited_date).format('DD/MM/YYYY')} />;
                },
                accessor: 'invited_date',
                width: 100,
            },
            {
                Header: () => <RenderTableHeader value={translator('LAST_SESSION')} />,
                Cell: ({ row }: any) => {
                    return (
                        <RenderTableCell
                            disable={!row?.original?.last_session?.id}
                            onClick={() => {
                                setSessionId(row?.original?.last_session?.id);
                                setOpenModalDetail(true);
                            }}
                            className="!font-semibold !text-primary-500"
                            value={row?.values?.last_session?.id ? 'Session ' + row.values?.last_session?.id : '_'}
                        />
                    );
                },
                accessor: 'last_session',
                width: 100,
            },
            {
                Header: () => <RenderTableHeader value={translator('NEXT_SESSION')} />,
                Cell: ({ row }: any) => {
                    return (
                        <RenderTableCell
                            disable={!row?.original?.next_session?.id}
                            onClick={() => {
                                setSessionId(row?.original?.next_session?.id);
                                setOpenModalDetail(true);
                            }}
                            className="!font-semibold !text-primary-500"
                            value={row.values?.next_session?.id ? 'Session ' + row.values?.next_session?.id : '_'}
                        />
                    );
                },
                accessor: 'next_session',
                width: 100,
            },
            {
                Header: () => <RenderTableHeader value={translator('STATUS')} />,
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return (
                        <div className="h-full flex items-center">
                            <p
                                className={`capitalize text-white text-xs font-semibold px-2 py-1 rounded ${
                                    STATUS[row.values?.class_membership_status]?.background
                                }`}
                            >
                                {STATUS[row.values?.class_membership_status]?.label}
                            </p>
                        </div>
                    );
                },
                accessor: 'class_membership_status',
                width: 40,
            },
        ],
        [dataTable]
    );

    return (
        <>
            <Table
                isBorderFirstColum={false}
                data={dataTable}
                columns={columns}
                initialState={initialState}
                emptyContent={translator('NO_STUDENTS')}
                emptyIcon={NO_STUDENT}
            />
            {isOpenModalDetail && (
                <SessionDetailsModal
                    sessionId={sessionId}
                    title={sessionName}
                    open={isOpenModalDetail}
                    onCloseModal={() => {
                        setSessionId(undefined);
                        setOpenModalDetail(false);
                    }}
                    refreshData={fetchStudentList}
                />
            )}
        </>
    );
};

export default TableStudent;
