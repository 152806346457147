import React, { useEffect } from "react";
import { getWhiteLabelByUrl } from "utils/whiteLabel";

export const ClarityMicrosoft = () => {
  const domain = getWhiteLabelByUrl();

  useEffect(() => {
    let clarityMicrosoftKey = process.env.REACT_APP_CLARITY_MICROSOFT;
    switch (domain) {
      case "nihaoma":
      case 'ooolab':
        clarityMicrosoftKey = process.env.REACT_APP_CLARITY_MICROSOFT_NIHAOMA;
        break;
      default:
        break;
    }

    const clarityScript = document.createElement("script");
    clarityScript.type = "text/javascript";
    clarityScript.async = true;

    clarityScript.innerHTML = `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/" + i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "${clarityMicrosoftKey}");
    `;

    document.head.appendChild(clarityScript);

    return () => {
      document.head.removeChild(clarityScript);
    };
  }, []);

  return <></>;
};
