
import { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import './Settings.scss';
import { CloseIcon } from 'assets/icon/CloseIcon';
import { CustomFields } from './CustomFields/CustomFields';
import { ButtonIcon, Tooltip } from '_shared';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { TooltipCustom } from 'components/Tooltip/Tooltip';
import ReactTooltip from 'react-tooltip';
import { CircleInformationIcon } from 'assets/icon';

export const Settings = ({ isOpen, onClose }) => {
    const { t: translator } = useTranslation();

    useEffect(() => {
        ReactTooltip.rebuild();
    }, []);
    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-[99]" onClose={onClose} id='aaa'>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/30 bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <TooltipCustom
                                classNameContainer="shadow-medium"
                                id="TOOLTIP_INFO_CUSTOM_TAGS"
                                content={translator('TOOLTIP_INFO_CUSTOM_TAGS')}
                                place="top"
                                type="light"
                            />
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-[90%] transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl ">
                                    <Dialog.Title
                                        as="h3"
                                        className="settings-header"
                                    >
                                        <div className='flex items-center gap-2'>
                                            {translator('CUSTOM_TAGS')}
                                            <div  className="w-4 h-4 cursor-pointer"
                                                data-for="TOOLTIP_INFO_CUSTOM_TAGS"
                                                data-tip={translator('TOOLTIP_INFO_CUSTOM_TAGS')}>
                                                <CircleInformationIcon width='18' height='18' fill='#111827' />
                                            </div>

                                        </div>
                                        <div className='settings-close' >
                                            <ButtonIcon onClick={onClose}>
                                                <span className='p-1'>
                                                    <CloseIcon />
                                                </span>
                                            </ButtonIcon>
                                        </div>
                                    </Dialog.Title>
                                    <div className="settings-container">
                                        <div className="settings-main">
                                            <CustomFields />
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}