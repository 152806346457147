import { Tab } from '@headlessui/react';
import AposHistory from './ApoHistory';
import RewardHistory from './RewardHistory';
import { useTranslation } from 'react-i18next';

const listTab = ['Coin History', 'Reward History'];

const StudentDetail = ({ children , userData}) => {
    const { t } = useTranslation();
    return (
      <>
      <div className='text-lg font-semibold'>{t("USER.ACHIEVEMENTS")}</div>
      <Tab.Group>
            <Tab.List className="w-full text-xs pt-5  items-center border-b border-gray-300 -mx-5">
                {listTab.map((i, index) => (
                    <Tab
                        key={index}
                        className={({ selected }) => {
                            const tabClassName = `col-span-1 px-7 py-2 font-semibold text-center  ${
                                selected ? 'text-primary-500 border-b border-primary-500 outline-none' : 'text-gray-500'
                            }`;
                            return tabClassName;
                        }}
                    >
                        {t(i)}
                    </Tab>
                ))}
            </Tab.List>
            <Tab.Panels>
                <Tab.Panel>
                    <AposHistory userData={userData}/>
                </Tab.Panel>
                <Tab.Panel>
                    <RewardHistory userData={userData}/>
                </Tab.Panel>
            </Tab.Panels>
        </Tab.Group>
      </>
    );
};

export default StudentDetail;
