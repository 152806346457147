import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import "./index.scss";
import { ButtonOutline, ButtonPrimary } from '_shared';
import { useForm } from 'react-hook-form';
import { InputField } from 'components/InputForm/InputForm';
import { useContext, useEffect, useState } from 'react';
import paymentService from 'services/payment.service';
import { AsyncSelectField } from 'components/Select';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { VALIDATE_FIELD } from 'utils/validate';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { Switch } from '@headlessui/react';
import { Toast } from 'utils/sweetAlert';
import { CircleTime } from 'assets/icon/CircleTime';
import DialogComponent from 'components/Dialog/DialogComponent';
import { useBoolean } from 'hooks/custom';
import { TaxRateHistory } from './TaxRateHistory';

const PER_PAGE = 20;
const formatOptionsGroup = (data: any) => {
    return (
        <div className="flex items-center text-dark-300">
            <p className="truncate capitalize">{data?.label}</p>
        </div>
    );
};

export const Settings = () => {
    const {
        control,
        handleSubmit,
        register,
        formState: { errors, isValid, isDirty },
        reset,
        getValues,
        watch
    } = useForm({ mode: 'onChange' });
    const { t } = useTranslation();
    const params: { id: string } = useParams();
    const { dispatch, getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: workspaceDetailInformation } = getWorkspaceDetailState;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCheck, setCheck] = useState(false)
    const { booleanValue: modalHistory, toggleBooleanValue: toggleModalHitory } = useBoolean();


    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`E_COMMERCE.${str}`, opt));
    const onSubmit = (values) => {
        setIsLoading(true);
        const payload = {
            tax_rate: values?.tax_rate
        }
        workspaceMiddleware.updateDetailWorkspace(dispatch, params.id, payload);
        setIsLoading(false);
    }
    const copy = () => {
        navigator.clipboard.writeText(workspaceDetailInformation?.public_catalog_url);
        Toast.fire({
            icon: 'success',
            title: t('Link copied!')
        });
    }
    const fetchCurrency = async (bodyParams?: any) => {
        const res = await paymentService.getCurrency(bodyParams);
        return {
            items: res?.data?.items,
            total: res?.data?.total,
        };
    };
    const handleLoadCurrency = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional.page,
            per_page: PER_PAGE,
            q: inputValue || null,
        };
        if (!payload?.name) delete payload?.name;

        const { items, total } = await fetchCurrency(payload);
        const newOptions = items?.map((el: any) => ({
            ...el,
            id: el.id,
            label: `${el.name} (${el?.code.toUpperCase()}) - ${el?.symbol}`,
            value: el.id,
        }));

        return {
            options: newOptions,
            hasMore: additional?.page < total / PER_PAGE,
            additional: {
                page: additional?.page + 1,
            },
        };
    };
    useEffect(() => {
        const formatCurrency = {
            ...workspaceDetailInformation?.default_currency,
            id: workspaceDetailInformation?.default_currency?.id,
            label: `${workspaceDetailInformation?.default_currency?.name} (${workspaceDetailInformation?.default_currency?.code?.toUpperCase()}) - ${workspaceDetailInformation?.default_currency?.symbol}`,
            value: workspaceDetailInformation?.default_currency?.id,
        }
        const defaultValues = {
            ...workspaceDetailInformation,
            currency: formatCurrency
        }
        reset(defaultValues)
    }, [workspaceDetailInformation])
    useEffect(() => {
        setCheck(workspaceDetailInformation?.enable_public_catalog);
    }, [workspaceDetailInformation?.enable_public_catalog])

    return <>
        <form onSubmit={handleSubmit(onSubmit)} >
            <div className='rounded-2xl p-5 bg-white disabled:after:bg-'>
                <div className='flex text-gray-800 font-semibold text-xl justify-between items-center'>
                    <h2>{translator('SETTINGS')}</h2>
                    <ButtonPrimary disabled={!isValid || !isDirty || isLoading} type="submit" className={`${isLoading ? 'bg-ooola_EFF0FA text-ooolab_8083A3 cursor-not-allowed' : ''}`} >{t('SAVE')}</ButtonPrimary>
                </div>
                <div className='settings mt-4 '>
                    <div className='relative'>
                        <InputField
                            label={translator('TAX_RATE')}
                            placeholder={translator('ENTER_TAX_RATE')}
                            name="tax_rate"
                            type="text"
                            errors={errors}
                            register={register('tax_rate', {
                                max: {
                                    value: 100,
                                    message: t('FORM_CONST.NUMBER_ONLY_AND_NUMBERS_GREATER_THAN_ZERO_TO_100'),
                                },
                                min: {
                                    value: 0,
                                    message: t('FORM_CONST.NUMBER_ONLY_AND_NUMBERS_GREATER_THAN_ZERO_TO_100'),
                                },
                                pattern: {
                                    value: VALIDATE_FIELD.NUMBER_0_100_FLOAT_1,
                                    message: t('FORM_CONST.NUMBER_FLOAT_1'),
                                },
                            })}
                            tooltipLabel={translator('TAX_RATE_TOOLTIP')}
                            currency='%'
                            isFloatNumber
                        />
                        <div className='absolute top-0 right-0 flex items-center cursor-pointer' onClick={toggleModalHitory}>
                            <CircleTime />
                            <p className='text-xs text-enterprise ml-1 font-normal'>{translator('SHOW_HISTORY')}</p>
                        </div>
                    </div>
                    <AsyncSelectField
                        control={control}
                        label={translator('CURRENCY')}
                        name="currency"
                        placeholder={translator('ENTER_CURRENCY')}
                        closeMenuOnSelect={true}
                        errors={errors}
                        loadOptions={handleLoadCurrency}
                        formatOptionLabelCustom={formatOptionsGroup}
                        isDisabled
                    />
                    <div className='col-span-1 '>
                        <h2 className='text-gray-800 font-semibold text-xs'>{translator('PUBLIC_CATALOG_URL')}</h2>
                        <div className='col-span-1'>
                            <div className='flex items-center mt-3 gap-2'>
                                <Switch
                                    checked={isCheck}
                                    onChange={(value) => {
                                        const payload = {
                                            enable_public_catalog: value,
                                        }
                                        workspaceMiddleware.updateDetailWorkspace(dispatch, params.id, payload);
                                        setCheck(value);
                                    }}
                                    className={`${isCheck ? 'bg-primary-500' : 'bg-gray-400'}
                    relative inline-flex h-[20px] w-[36px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                                >
                                    <span
                                        aria-hidden="true"
                                        className={`${isCheck ? 'translate-x-4' : 'translate-x-0'}
                        pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                    />
                                </Switch>
                                {isCheck && workspaceDetailInformation?.public_catalog_url &&
                                    < div className='rounded px-3 py-[6px] bg-gray-200 text-sm text-dark-100 truncate'>{workspaceDetailInformation?.public_catalog_url}</div>
                                }
                                {isCheck && workspaceDetailInformation?.public_catalog_url && <ButtonOutline
                                    onClick={copy}
                                    type={"button"}
                                    className="buttonOutline--isPrimary flex-none"
                                >
                                    {t('MODALS.SHARE_LINK.COPY_LINK')}
                                </ButtonOutline>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DialogComponent
                isOpen={modalHistory}
                title={translator('TAX_RATE_HISTORY')}
                onCloseModal={toggleModalHitory}
                maskClosable={true}
                child={
                    <TaxRateHistory arrHistory={workspaceDetailInformation?.tax_rate_history} />
                }
            />
        </form >
    </>
}