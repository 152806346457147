import React, { useEffect, useState } from 'react';
import SelfBookingSelectedBox from './SelfBookingSelectedBox';
import SelfBookingFilter from './SelfBookingFilter';
import { useTranslation } from 'react-i18next';
import { filterInstructors } from './SelfBookingContants/SelfBookingContants';
import SelfBookingConfirm from './SelfBookingConfirm';
import ModalCreate from 'components/Management/components/ModalCreateWrapper';
import SelfBookingSelectCard from '../SelfBookingSelectCard';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import selfBookingService from 'services/selfBooking.service';
import dayjs from 'dayjs';
import DialogComponent from 'components/Dialog/DialogComponent';
import SelfBookingReschesuleConfirm from './SelfBookingReschesuleConfirm';
import SelfBookingCreditWarning from './SelfBookingCreditWarning';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { ENROLLMENT_TYPE } from '../BookingForm/utils';
import { Toastify } from '_shared';

type SelfBookingSelectProps = {
    listSession: any[];
    onFilter: (val: any) => void;
    total: number;
    activeInstrunstorFilter: any;
    activeClassFilter: any;
    setActiveInstrunstorFilter: React.Dispatch<React.SetStateAction<any>>;
    setActiveClassFilter: React.Dispatch<React.SetStateAction<any>>;
    student: any;
    onReset: () => void;
    getListSessions: (params: any, resetFilter?: boolean, clearOne?: string) => void;
    onRemoveFilter: (type: string, currentFilter: any, otherFilter: any) => void;
    learningPath: any;
    isReschedule?: boolean;
    enrollmentData?: any;
    loadingGetAvailableSlot: boolean;
    submitFind: boolean;
    infiniteRef: any;
    hasNextPage;
    page: number;
};

const SelfBookingSelect: React.FC<SelfBookingSelectProps> = ({
    activeClassFilter,
    activeInstrunstorFilter,
    listSession,
    onFilter,
    setActiveClassFilter,
    setActiveInstrunstorFilter,
    total,
    student,
    onReset,
    getListSessions,
    onRemoveFilter,
    learningPath,
    isReschedule,
    enrollmentData,
    submitFind,
    loadingGetAvailableSlot,
    infiniteRef,
    hasNextPage,
    page,
}: SelfBookingSelectProps) => {
    const [selected, setSelected] = useState<any[]>([]);
    const [isOpenModalFilter, setIsOpenModalFilter] = useState<boolean>(false);
    const [isOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false);
    const [isOpenModalWarningReschedule, setToggleModalWarningReschedule] = useState<boolean>(false);
    const [filtered, setFiltered] = useState<boolean>(false);
    const params = useParams<{ id: string }>();
    const [listSessionFormated, setListSessionFormated] = useState<any>({});
    const [loadMore, setLoadMore] = useState<any>({});
    const [loadingConfirm, setLoadingConfirm] = useState<boolean>(false);
    const [isOpenModalCredit, setOpenIsModalCredit] = useState<boolean>(false);
    const [remaningCredit, setRemainingCredit] = useState(enrollmentData?.available_credit);
    const toggleFilter = () => {
        setIsOpenModalFilter(!isOpenModalFilter);
    };
    const scrollToIndex = (idx: number) => {
        const element = document.getElementById(`seflBookingSelected-${idx}`);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'start',
            });
        }
    };

    useEffect(() => {
        setRemainingCredit(enrollmentData?.available_credit);
    }, [enrollmentData, learningPath, student]);

    useEffect(() => {
        setSelected([]);
    }, [learningPath, student]);

    const history = useHistory();

    const location: any = useLocation();

    const toggleConfirm = () => {
        setIsOpenModalConfirm(!isOpenModalConfirm);
    };

    const { t } = useTranslation();
    const translator = (str: string) => t(`SELF_BOOKING.${str}`);

    const handleSelect = (itemSelected: any, isUpdate = false) => {
        const hasChecked = selected?.find((i) => i?.uuid === itemSelected?.uuid);
        if (hasChecked) {
            if (isUpdate) {
                const newArr = selected?.map((i) => {
                    if (i?.uuid === itemSelected?.uuid) return itemSelected;
                    return i;
                });
                setSelected(newArr);
            } else {
                if (!isReschedule) setRemainingCredit(remaningCredit + 1);
                const newArr = selected?.filter((i) => i?.uuid !== itemSelected?.uuid);
                setSelected(newArr);
            }
        } else {
            if (remaningCredit === 0 && !isReschedule) return setOpenIsModalCredit(true);
            if (selected.length === 1 && isReschedule) {
                return setToggleModalWarningReschedule(true);
            }
            if (!isReschedule) setRemainingCredit(remaningCredit - 1);
            setSelected([...selected, itemSelected]);
        }
    };

    const handleCheckHasLessons = () => {
        const missing_lesson = selected?.filter((item) => !item?.lesson_id);
        if (missing_lesson?.length) {
            scrollToIndex(missing_lesson[0].uuid);
            setTimeout(() => {
                Toastify.error(translator('MISSING_LESSON'));
            }, 700);
        } else {
            setIsOpenModalConfirm(true);
        }
    };

    const onBookingSession = async () => {
        const slots = selected?.map((slot) => {
            const newSlot = {
                instructor_event_id: slot?.calendar_event_id,
                learning_path_id: learningPath?.value,
                lesson_id: slot?.lesson_id,
                student_id: student?.value,
                start_time: slot?.start_time,
                note: slot?.note,
                reschedule: {
                    prev_session_id: +location?.state?.id,
                },
                schedule_time_range_id: slot?.schedule_time_range_id,
            };
            if (!isReschedule) delete newSlot?.reschedule;
            return newSlot;
        });

        const missing_lesson = slots?.find((item) => !item?.lesson_id);
        if (missing_lesson) return Toastify.error(translator('MISSING_LESSON'));

        if (isReschedule) {
            await selfBookingService.cancelBooking(params?.id, location?.state?.id, { student_id: student?.value, reschedule: true });
        }

        setLoadingConfirm(true);
        await selfBookingService
            .bookavAvailableSlotService(params?.id, { booking_requests: slots })
            .then(() => {
                toggleConfirm();
                getListSessions({ page: 1 });
                setSelected([]);
                Toastify.success(translator('BOOKING_SUCCESS'));
                history.push(`/workspace/${params?.id}/management/booking-class`);
            })
            .catch(() => {
                Toastify.error(translator('BOOKING_ERROR'));
            });
        setLoadingConfirm(false);
    };


    const checkOverlapDataSelected = (session: any, arrSelected = selected) => {
        const start_time_selected = new Date(session?.start_time).getTime();
        const end_time_selected = new Date(session?.start_time).getTime() + session?.duration * 60000;
        const isOverlap = arrSelected?.find((item) => {
            const start_time = new Date(item?.start_time).getTime();
            const end_time = new Date(item?.start_time).getTime() + item?.duration * 60000;
            if (
                Math.min(start_time_selected, end_time_selected) < Math.max(start_time, end_time) &&
                Math.max(end_time_selected, end_time_selected) > Math.min(start_time, end_time) &&
                session?.start_time + session?.instructor?.id !== item?.start_time + item?.instructor?.id
            ) {
                return true;
            }
            return false;
        });
        return !!isOverlap;
    };

    useEffect(() => {
        if (listSession) {
            const listSessionFilter = Object.keys(listSession)?.map((date) => {
                return listSession[date]?.map((item) => {
                    const uuid = `${item?.start_time} + ${item?.instructor?.id}`;
                    if (checkOverlapDataSelected(item)) return { ...item, isOverlap: true, uuid };
                    return { ...item, uuid };
                });
            });
            let temp: any = {};
            Object.keys(listSession)?.forEach((item, i) => {
                temp[item] = listSessionFilter[i];
            });
            setListSessionFormated(temp);
        }
    }, [selected, page, loadingGetAvailableSlot]);

    return (
        <div className="border-t border-gray-300 mt-8">
            <div className="border border-gray-300 p-4 rounded-lg bg-gray-50 mt-8">
                <div className="flex items-center">
                    <img src={student?.avatar_url} className="w-8 h-8 rounded-full" />
                    <span className="font-semibold text-xl ml-2">{enrollmentData?.learning_group?.name || student?.label}</span>
                    { learningPath?.enrollment_type === ENROLLMENT_TYPE.CREDITS && 
                        <>
                            <span className="px-3">•</span>
                            <span className="text-xl font-semibold text-orange-500">
                                <span className="text-dark-100">{translator('AVAILABLE')}:</span>{' '}
                                {(learningPath && enrollmentData?.available_credit) || 0} Credits{' '}
                            </span>
                        </>
                    }
                </div>
                {submitFind && (
                    <>
                        {Object.keys(listSessionFormated)?.length > 0 && (
                            <div className="self-booking-select mt-4">
                                <h1 className="text-xs font-medium text-dark-300 mb-2">{translator('SELECT_PREFRERED_SESION')}</h1>
                                <div className="border border-gray-300 rounded-lg p-5 py-2 flex items-center bg-white">
                                    <div className="w-2/3 flex items-center">
                                        <p className="font-medium text-sm text-dark-300">
                                            {total || 0} {translator('SESIONS_FOUND')}
                                        </p>
                                    </div>
                                    <div
                                        className={`text-dark-300 text-sm font-medium underline flex justify-end w-1/3 cursor-pointer`}
                                        onClick={toggleFilter}
                                    >
                                        {translator('FILTER')}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="flex items-center w-2/3 mt-4">
                            {activeInstrunstorFilter?.value ? (
                                <div className="w-52">
                                    <SelfBookingSelectedBox
                                        item={translator(activeInstrunstorFilter?.label)}
                                        handleRemoveSelected={() => {
                                            setActiveInstrunstorFilter(filterInstructors[0]);
                                            if (activeClassFilter?.value === null) setFiltered(false);
                                            onRemoveFilter('instructor', filterInstructors[0], activeClassFilter);
                                        }}
                                    />
                                </div>
                            ) : null}
                        </div>
                        <div className="mt-4">
                            {Object.keys(listSessionFormated)?.length > 0 && (
                                <div>
                                    {Object.keys(listSessionFormated)?.map((date, idx) => {
                                        return (
                                            <div key={idx}>
                                                <p className="text-sm text-ooolab_282B5E font-medium">
                                                    {dayjs(date)?.format('ddd, MMM D,  YYYY')}
                                                </p>
                                                {(listSessionFormated?.[date]?.length > 0 &&
                                                    (loadMore[date]
                                                        ? listSessionFormated?.[date]
                                                        : listSessionFormated?.[date]?.slice(0, 3)
                                                    )?.map((session) => (
                                                        <div
                                                            className="my-4"
                                                            key={session?.uuid}
                                                            id={`seflBookingSelected-${session?.uuid}`}
                                                        >
                                                            <SelfBookingSelectCard
                                                                studentId={student?.value}
                                                                data={session}
                                                                onlyView={false}
                                                                handleSelect={handleSelect}
                                                                learningPath={learningPath}
                                                                disable={loadingGetAvailableSlot}
                                                                defaultChecked={
                                                                    selected?.length &&
                                                                    selected?.find((el) => el?.uuid === session?.uuid)
                                                                }
                                                                learningPathType={learningPath?.type_}
                                                                isReschedule={isReschedule}
                                                                selected={selected}
                                                            />
                                                        </div>
                                                    ))) ||
                                                    ''}
                                                {!loadMore?.[date] && listSessionFormated?.[date]?.length > 3 && (
                                                    <div className="w-full flex justify-center my-4">
                                                        <button
                                                            type="button"
                                                            onClick={() => setLoadMore({ ...loadMore, [date]: true })}
                                                            className=" border border-dark-300 text-dark-300 py-2 px-4 text-center text-xs rounded w-fit"
                                                        >
                                                            {translator('LOADMORE')}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                        {!hasNextPage && loadingGetAvailableSlot && (
                            <div className="w-full flex items-center justify-center text-center mt-5">
                                {loadingGetAvailableSlot && <CircleSpin color="text-[#024FEE]" />}
                            </div>
                        )}
                        {hasNextPage && (
                            <div className="w-full flex items-center justify-center text-center mt-5" ref={infiniteRef}>
                                {loadingGetAvailableSlot && <CircleSpin color="text-[#024FEE]" />}
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className="sticky bottom-0 py-3 bg-white">
                <div className="flex items-center justify-between">
                    {selected?.length > 0 && learningPath?.enrollment_type === ENROLLMENT_TYPE.CREDITS ? (
                        <div className="flex gap-3">
                            <div className="text-base font-medium text-dark-100">
                                {selected?.length} {translator('SESSIONS_SELECTED')}
                            </div>
                            <div>{'-'}</div>
                            <div className="text-base font-medium text-dark-100 flex">
                                <span className="text-dark-100"> Remaining Credits</span>
                                <span className="text-dark-100 ml-2">{remaningCredit || 0}</span>
                            </div>
                        </div>
                    ) : (
                        <div></div>
                    )}
                    <div
                        className="text-sm font-medium underline text-primary-500 cursor-pointer"
                        onClick={() => {
                            onReset();
                            setSelected([]);
                        }}
                    >
                        {translator('RESET_ALL')}
                    </div>
                </div>
                {!!selected?.length && (
                    <div className="flex justify-center gap-6 mt-8">
                        <button
                            className="text-dark-300 shadow-sm  text-xs w-32  border border-dark-300 rounded py-2 disabled:bg-gray-400"
                            type="button"
                            disabled={!selected?.length}
                            onClick={() => {
                                setSelected([]);
                            }}
                        >
                            {translator('RESET_SELECTION')}
                        </button>
                        <button
                            className={`text-white text-xs border w-32 border-primary-500 bg-primary-500 rounded py-2 ${
                                !selected?.length && 'opacity-50'
                            }`}
                            type="button"
                            onClick={() => {
                                handleCheckHasLessons();
                            }}
                            disabled={!selected?.length}
                        >
                            {isReschedule ? translator('RESCHEDULE') : translator('BOOK')}
                        </button>
                    </div>
                )}
            </div>
            <ModalCreate
                isOpen={isOpenModalFilter}
                onClose={toggleFilter}
                children={
                    <SelfBookingFilter
                        setFiltered={setFiltered}
                        onCancel={() => setIsOpenModalFilter(false)}
                        activeClassFilter={activeClassFilter}
                        activeInstrunstorFilter={activeInstrunstorFilter}
                        setActiveClassFilter={setActiveClassFilter}
                        setActiveInstrunstorFilter={setActiveInstrunstorFilter}
                        onFilter={onFilter}
                    />
                }
                title={translator('FILTER')}
                styles="rounded-2xl w-[440px]"
            />
            <DialogComponent
                isOpen={isOpenModalConfirm}
                onCloseModal={toggleConfirm}
                child={
                    <SelfBookingConfirm
                        selectedData={selected}
                        onCancel={toggleConfirm}
                        onBookingSession={onBookingSession}
                        student={student}
                        loadingConfirm={loadingConfirm}
                        learningPath={learningPath}
                        enrollmentData={enrollmentData}
                        totalBookingCredit={selected?.length}
                        isReschedule={isReschedule}
                    />
                }
                title={isReschedule ? translator('CONFIRM_RESCHEDULE_BOOKING') : translator('CONFIRM_BOOKING')}
                styles="max-w-[800px] "
                stylesTitle="text-base font-semibold"
            />
            <DialogComponent
                isOpen={isOpenModalWarningReschedule}
                onCloseModal={() => setToggleModalWarningReschedule(false)}
                child={<SelfBookingReschesuleConfirm onClose={() => setToggleModalWarningReschedule(false)} />}
                title={translator('WARNING')}
                stylesTitle="text-base font-semibold"
            />
            <DialogComponent
                isOpen={isOpenModalCredit}
                onCloseModal={() => setOpenIsModalCredit(false)}
                child={
                    <SelfBookingCreditWarning  
                        credit={enrollmentData?.available_credit} 
                        onClose={() => setOpenIsModalCredit(false)} 
                        isLimitSession= {
                            !!enrollmentData?.total_credit 
                            && learningPath.enrollment_type === ENROLLMENT_TYPE.EXPIRARION
                        }
                    />
                }
                title={translator('WARNING')}
                stylesTitle="text-base font-semibold"
            />
        </div>
    );
};

export default SelfBookingSelect;
