import { useState, useEffect, useCallback, useContext } from 'react';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import { debounce } from 'lodash';
import './Filter.scss';
import { useParams } from 'react-router-dom';
import { SESSION_TYPE } from 'constant/course.const';
import { useTranslation } from 'react-i18next';
import instructorsService from 'services/instructors.service';
import { SCHEDULE_TYPE } from 'constant/sessions.const';
import { LearnerAndGroupSearch } from './LearnerAndGroupSearch/LearnerAndGroupSearch';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';

interface IFilter {
    onChangeFilter: (value: any) => void;
    isFirstLoad: boolean;
}

export const Filter = ({
    onChangeFilter,
    isFirstLoad,
}: IFilter) => {
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const {
        result: { membership },
    } = getWorkspaceDetailState;

    const params: { id: string, userId: string } = useParams();
    const [keyword, setKeyword] = useDebouncedState('', 0);
    const [sessionType, setSessionType] = useState<any>();
    const [status, setStatus] = useState<any>();
    const [learningGroup, setlearningGroup] = useState<any>();
    const [courseSelected, setCourseSelected] = useState([]);
    const [inputSearchCourse, setInputSearchCourse] = useDebouncedState('');
    const [listCourse, setListCourse] = useState([]);
    const {t} = useTranslation();

    const [paginationCourse, setPaginationCourse] = useState<any>({
        page: 1,
    });

    const updateFilter = useCallback(
        debounce((listFilter) => onChangeFilter(listFilter), 500),
        []
    );

    const getCourse = async (page) => {
        let payload = {
            q: inputSearchCourse,
            sort_by: 'created_at',
            page: page ? page : paginationCourse.page,
            per_page: 10,
            order: 'desc',
            relation_type: "course",
            instructor_user_id: params?.userId || membership?.user_id
        }
        if (payload?.instructor_user_id === -1) {
            return;
        }
        if (!payload?.q) delete payload.q;
        //@ts-ignore
        const response = await instructorsService.getRelationDataOfInstructor(params?.id, payload)
        if (response) {
            const formatedData = response?.data?.items?.map((item) => {
                return {
                    name: item?.title,
                    id: item?.id,
                    value: item?.id,
                };
            });
            if (page === 1) setListCourse(formatedData);
            else setListCourse([...listCourse, ...formatedData]);
            setPaginationCourse({ ...paginationCourse, total: response?.data?.total, page });
        }
    }

    useEffect(() => {
        if (!isFirstLoad) {
            let listFilter: any = {
                q: keyword,
                schedule_type: sessionType ? sessionType[0]?.value : '',
                status: status ? status[0]?.value : '',
                course_id: courseSelected ? courseSelected[0]?.value : '',
            };
            if (learningGroup) {
                listFilter = {
                    ...listFilter,
                    ...learningGroup
                }
            };
            if (!listFilter?.schedule_type) delete listFilter?.schedule_type;
            if (!listFilter?.course_id) delete listFilter?.course_id;

            if (!listFilter?.status) delete listFilter?.status;
            if (!listFilter?.learner_ids || listFilter?.learner_ids == '') delete listFilter?.learner_ids;
            if (!listFilter?.learning_group_ids || listFilter?.learning_group_ids == '') delete listFilter?.learning_group_ids;
            if (!listFilter?.course_id) delete listFilter?.course_id
            if (!listFilter?.q) delete listFilter?.q;
            updateFilter(listFilter);
        }
    }, [keyword, sessionType, learningGroup, courseSelected, status]);

    useEffect(() => {
        getCourse(1);
    }, [inputSearchCourse, membership?.user_id]);
    return (
        <>
            <div className="sessionFilter flex-wrap">
                <div className='max-w-[234px]'>
                    <SearchBox placeholder={t("USER.TEACHING_HISTORY.SEARCH_SESSION")} className="sessionFilter-searchInput" onSubmit={(value) => setKeyword(value)} />
                </div>

                <SelectBox
                    label={t("USER.TEACHING_HISTORY.COURSE")}
                    isMulti={false}
                    onSearch={setInputSearchCourse}
                    onSelect={setCourseSelected}
                    data={listCourse}
                    selectedItems={courseSelected}
                    total={paginationCourse?.total}
                    showmore={() => getCourse(paginationCourse?.page + 1)}
                />

                <LearnerAndGroupSearch onChangeFilter={setlearningGroup} userId={membership?.user_id}  />
                
                <SelectBox
                    label={t("USER.TEACHING_HISTORY.SESSION_TYPE")}
                    isMulti={false}
                    onSelect={setSessionType}
                    data={SESSION_TYPE}
                    selectedItems={sessionType}
                    total={3}
                />

                 <SelectBox
                    label={t("USER.STATUS")}
                    isMulti={false}
                    onSelect={setStatus}
                    data={SCHEDULE_TYPE(t)}
                    selectedItems={status}
                    total={3}
                />
            </div>
        </>
    );
};
