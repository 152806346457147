import Avatar from 'assets/avatar_option.png';

export const formatOptionsLearner = (data: any) => {
  return (
      <div className="flex items-center text-dark-300">
          <div className="flex items-center w-1/3">
              <img src={data?.avatar_url || Avatar} className="h-6 w-6 rounded-full mr-2" alt="" />
              <p className="truncate">{data?.display_name}</p>
          </div>
          <div className="pl-4 w-2/3">
              <p className="truncate">{data?.email}</p>
          </div>
      </div>
  );
};

export const formatOptionsGroup = (data: any) => {
  return (
      <div className="flex items-center text-dark-300">
          <div className="flex items-center w-1/3">
              <p className="truncate">{data?.name}</p>
          </div>
          <div className="pl-4 w-2/3 flex items-center">
              <img src={data?.leader?.avatar_url || Avatar} className="h-6 w-6 rounded-full mr-2" alt="" />
              <p className="truncate">{data?.leader?.display_name}</p>
          </div>
      </div>
  );
};

export const formatOptionsInstructor = (data: any) => {
    return (
        <div className="flex items-center text-dark-300">
            <div className="flex items-center w-1/3">
                <img src={data?.avatar_url || Avatar} className="h-6 w-6 rounded-full mr-2" alt="" />
                <p className="truncate">{data?.display_name}</p>
            </div>
            <div className="pl-4 w-2/3">
                <p className="truncate">{data?.email}</p>
            </div>
        </div>
    );
  };

  export const formatOptionsInstructorNonEmail = (data: any) => {
    return (
        <div className="flex items-center text-dark-500">
            <div className="flex items-center w-full">
                <img src={data?.avatar_url || Avatar} className="h-6 w-6 min-w-[24px] rounded-full mr-2" alt="" />
                <p className="truncate text-ellipsis">{data?.display_name || data?.name}</p>
            </div>
        </div>
    );
  };

  export const formatOptionsAssignee = (data: any) => {
    return (
        <div className="flex items-center text-dark-300">
            <div className="pl-4 w-1/3">
                <p className="truncate">ID: {data?.value}</p>
            </div>
            <div className="flex items-center w-1/3">
                <img src={data?.learners?.[0]?.avatar_url || data?.learning_group?.avatar_url||  Avatar} className="h-6 w-6 rounded-full mr-2" alt="" />
                <p className="truncate">{ data?.name || data?.learning_group?.name || data?.learners?.[0]?.display_name}</p>
            </div>
            <div className="pl-4 w-1/3">
                <p className="truncate capitalize">{data?.type?.replace('_', ' ')}</p>
            </div>
        </div>
    );
  };