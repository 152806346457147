import { PlusIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import paymentService from 'services/payment.service';
import { ButtonOutline, Toastify } from '_shared';
import { Card } from '_shared/components/Card/Card';
import { AddBankAccount } from './AddBankAccount/AddBankAccount';
import { PaymentType } from './model';
import "./index.scss";
import { PaymentItem } from './PaymentItem/PaymentItem';
import { CircleSpin } from 'components/UiComponents/CircleSpin';

export const PaymentDetails = () => {
    const { t: translator } = useTranslation();
    const params: { id: string } = useParams();
    const [payments, setPayments] = useState<PaymentType[]>();
    const [paymentSelect, setPaymentSelect] = useState<PaymentType>();
    const [hasOpenAddBank, setHasOpenAddBank] = useState(false);
    const [loading, setLoading] = useState(true);
    const [actionLoading, setActionLoading] = useState(false);

    const handleAddPayment = async (value) => {
        try {
            setActionLoading(true);
            await paymentService.createPaymentDetail(params.id, value);
            getListPaymentDetail();
        } catch (error) {
            Toastify.error();
        } finally {
            setHasOpenAddBank(false);
            setActionLoading(false);
        }
    }
    const handleUpdateBank = async (value) => {
        try {
            setActionLoading(true);
            await paymentService.updatePaymentDetail(params.id, value);
            getListPaymentDetail();
            Toastify.success(translator('UPATE_SUCCESS'));
        } catch (error) {
            Toastify.error();
        } finally {
            setHasOpenAddBank(false);
            setActionLoading(false);
            setPaymentSelect(null);
        }
    }
    const getListPaymentDetail = async () => {
        try {
            setLoading(true);
            const reponse = await paymentService.getListPaymentDetail(params.id);
            if (reponse?.data) {
                setPayments(reponse?.data?.items);
            }
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    const handleUpdate = (value: PaymentType) => {
        setHasOpenAddBank(true);
        setPaymentSelect(value);
    }

    useEffect(() => {
        getListPaymentDetail();
    }, [])

    return <>
        <Card>
            <div className='card-header'>
                <h2>{translator('Payment Details')}</h2>
                <ButtonOutline type="button" size='L' 
                    onClick={() => {setHasOpenAddBank(true); setPaymentSelect(null)}} 
                    className="buttonOutline--isPrimary"
                    disabled={payments?.length >= 10}
                >
                    <PlusIcon className='w-5 h-5 mr-2' />
                    {translator('Add Bank Account')}
                </ButtonOutline>
            </div>
            <div className='paymentDetails card-content'>
                {loading ? <div className='flex justify-center'><CircleSpin color='text-primary-500' /></div> : <>
                    {payments?.length == 0 ?
                        <p className='paymentDetails-noContent'>{translator('No Account')}</p> :
                        <div className='paymentDetails-list'>
                            {payments?.map(item => <div key={item?.id} className="w-1/2 p-2">
                                <PaymentItem values={item} onUpdate={handleUpdate} />
                            </div>)}
                        </div>}
                </>}
            </div>
        </Card>
        {hasOpenAddBank ? <AddBankAccount
            isOpen={hasOpenAddBank}
            onClose={() => {setHasOpenAddBank(false); setPaymentSelect(null)}}
            onAddPayment={handleAddPayment}
            onUpdateBank={handleUpdateBank}
            loading={actionLoading}
            data={paymentSelect}
        /> : null}
    </>
}