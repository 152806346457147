import { Tab } from '@headlessui/react';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Information from './Information';

const listTab = ['Information'];
const BannerDetail = ({children}) => {
    const { t: translator } = useTranslation();
    const params: any = useParams();
    const [bannerName, setBannerName] = useState('') 
    return (
        <>
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.COMMUNICATION')}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    { name: translator('DASHBOARD.SIDEBAR.BANNERS'), href: `/workspace/${params?.id}/management/banners`, className: '' },
                    { name: bannerName, href: ``, className: '' }
                ]}
            />
            <Tab.Group>
            <Tab.List className="w-full min-h-ooolab_h_18 text-ooolab_xs p-ooolab_p_5 grid grid-cols-7 items-center border-b border-ooolab_bar_color">
                {listTab.map((i) => (
                    <Tab 
                        key={i}
                        className={({ selected }) => {
                            const tabClassName = `col-span-1 px-ooolab_p_7 py-ooolab_p_2 text-left font-semibold  ${
                                selected
                                    ? 'text-ooolab_dark_1 bg-ooolab_light_100 outline-none'
                                    : 'text-ooolab_dark_2'
                            }`;
                            return tabClassName;
                        }}
                    >
                       { translator('BANNER.INFORMATION')}
                    </Tab>
                ))}
            </Tab.List>
            <Tab.Panels>
                <Tab.Panel>
                    <Information setBannerName={setBannerName}/>
                </Tab.Panel>
            </Tab.Panels>
        </Tab.Group>
        </>
        
    );
};

export default BannerDetail;
