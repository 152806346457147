import React from 'react';
import WorkspaceGoogleDriveView from 'components/Workspace/WorkspaceGoogleDriveView';

export interface WorkspaceGoogleDriveViewProps {
    setAuthStorage: React.Dispatch<React.SetStateAction<boolean>>;
}

const WorkspaceGoogleDriveViewPage: React.FC<WorkspaceGoogleDriveViewProps> = ({
    setAuthStorage,
}) => {
    return (
        <WorkspaceGoogleDriveView setAuthStorage={setAuthStorage} />
    );
};

export default WorkspaceGoogleDriveViewPage;
