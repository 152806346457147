import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router';
import TagPopover from './TagPopOver';
import { TagType } from 'types/GetListOfWorkspace.type';
import { CreateTagBody } from 'types/ApiData.type';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import workspaceService from 'services/workspace.service';
import { toast } from 'react-toastify';
import { WORKSPACE_LESSON } from 'actions/workspace.action';

type TagRenderProps = {
    isEditable?: boolean;
    title: string;
    data?: TagType[];
    onCheck?: (tagId: number) => void;
    onUnCheck?: (tagId: number) => void;
};

const TagRender: React.FC<TagRenderProps> = ({
    isEditable = false,
    title,
    data,
    onCheck,
    onUnCheck,
}) => {
    const params: any = useParams();
    const [inputValue, setInputValue] = useState<string>('');

    const { dispatch: WorkspaceDispatch, getWorkspaceDetailState } = useContext(
        GetWorkspaceContext
    );

    const { id: workspaceId, lessonId } = params;

    const { tagList } = getWorkspaceDetailState;

    const formatList = React.useMemo(() => {
        if (!tagList?.items?.length) return [];
        const tmp = data?.map((i) => i.id) || [];
        const format = tagList.items?.map((j: any) => ({
            ...j,
            check: tmp.includes(j.id),
        }));
        return format;
    }, [tagList, data]);

    const pagination = {
        total: tagList.total,
        page: tagList.page,
        per_page: tagList.per_page,
        sort_by: tagList.sort_by,
        order: tagList.order,
    };

    const handleCreateTag = async (body: CreateTagBody): Promise<boolean> => {
        return workspaceService
            .createlessonTags(workspaceId, body)
            .then((res) => {
                if (res) {
                    getListTagApi({}, true);
                }
                return true;
            })
            .catch(() => {
                toast.error('Duplicate tag!', {
                    position: 'bottom-left',
                });
                return false;
            });
    };

    const handleSearchTag = (keyword: string) => {
        getListTagApi({ name: keyword }, true);
    };

    const getListTagApi = (params?: any, isFetch?: boolean) => {
        params = {
            ...params,
            name: params?.name || undefined,
        };
        workspaceMiddleware
            .getTagsMiddleware(workspaceId, params)
            .then((res: any) => {
                const resData = {
                    ...res.data,
                    items: isFetch
                        ? res.data.items
                        : [...tagList.items, ...res.data.items],
                };
                WorkspaceDispatch({
                    type: WORKSPACE_LESSON.REQ_GET_LESSON_TAG_FINISH,
                    tagList: resData,
                });
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    useEffect(() => {
        getListTagApi();
    }, []);

    return (
        <div className="w-full h-20 min-h-20 max-h-30 border-ooolab_bar_color border rounded-lg flex flex-wrap pb-2 overflow-y-scroll custom-scrollbar">
            <div className="text-sm text-ooolab_dark_1 font-semibold border-b border-r border-ooolab_bar_color h-8 px-2 rounded-lg inline-flex items-center py-auto">
                {title}
            </div>
            {(data?.length &&
                data.map((i) => {
                    let color: {
                        backgroundColor: string;
                        color: string;
                    };
                    try {
                        const test = JSON.parse(i.color);
                        color = {
                            backgroundColor: test.backgroundColor,
                            color: test.textColor,
                        };
                    } catch (error) {
                        color = {
                            backgroundColor: 'blue',
                            color: 'white',
                        };
                    }

                    return (
                        <div
                            key={i.id}
                            className="h-8 inline-flex items-end ml-2"
                        >
                            <p
                                style={{ ...color }}
                                className="px-2 py-1 text-center bg-ooolab_blue_4 text-white text-xs rounded-lg "
                            >
                                {i.name.length < 16
                                    ? i.name
                                    : `${i.name?.slice(0, 17)}...`}
                            </p>
                        </div>
                    );
                })) ||
                null}

            {(isEditable && (
                <div className="h-8 flex items-end">
                    <TagPopover
                        title="Tag As"
                        listTag={formatList}
                        pagination={pagination}
                        lessonId={lessonId}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        onCheck={onCheck}
                        onUnCheck={onUnCheck}
                        onCreate={handleCreateTag}
                        onSearch={handleSearchTag}
                        getMoreTag={getListTagApi}
                    />
                </div>
            )) ||
                null}
        </div>
    );
};

export default TagRender;
