// ACTION
import { LIST_REWARDS, LIST_REWARDS_FUTURED, LIST_REWARDS_HISTORY } from 'actions/rewards.action';
import React from 'react';
// SERVICE
import rewardService from 'services/reward.service';
// TYPE

const getListReward = async (
    workspaceId: string,
    dispatch: React.Dispatch<any>,
    params?: Record<any, any>
) => {
    dispatch({
        type: LIST_REWARDS.REQ_GET_REWARDS,
    });
    try {
        const data = await rewardService.getListRewards(
            {
                workspaceId: workspaceId,
            },
            params
        );
        dispatch({
            type: LIST_REWARDS.REQ_GET_REWARDS_FINISH,
            listRewards: data.data,
        });
    } catch (err) {
        dispatch({
            type: LIST_REWARDS.REQ_GET_REWARDS_FAILED,
            err: err.toJSON().message,
        });
    }
};

const getListRewardFutured = async (
    workspaceId: string,
    dispatch: React.Dispatch<any>,
    params?: Record<any, any>
) => {
    dispatch({
        type: LIST_REWARDS_FUTURED.LIST_REWARDS_FUTURED,
    });
    try {
        const data = await rewardService.getListRewards(
            {
                workspaceId: workspaceId,
            },
            params
        );
        dispatch({
            type: LIST_REWARDS_FUTURED.LIST_REWARDS_FUTURED_FINISH,
            listRewardsFutured: data.data,
        });
    } catch (err) {
        dispatch({
            type: LIST_REWARDS_FUTURED.LIST_REWARDS_FUTURED_FAILED,
            err: err.toJSON().message,
        });
    }
};

const getListRewardHistory = async (
    workspaceId: string,
    id: string,
    dispatch: React.Dispatch<any>,
    params?: Record<any, any>
) => {
    dispatch({
        type: LIST_REWARDS_HISTORY.REQ_GET_REWARDS
    });
    try {
        const data = await rewardService.getListRewardsHistory(
            {
                workspaceId: workspaceId,
                id: id
            },
            params
        );
        dispatch({
            type: LIST_REWARDS_HISTORY.REQ_GET_REWARDS_FINISH,
            listRewardsHistory: data.data,
        });
    } catch (err) {
        dispatch({
            type: LIST_REWARDS_HISTORY.REQ_GET_REWARDS_FAILED,
            err: err.toJSON().message,
        });
    }
};

export default {
    getListReward,
    getListRewardFutured,
    getListRewardHistory
};
