import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Pagination from 'components/V2/Pagination';
import { getIndexPage } from 'utils/commonFun';
import { ButtonPrimary } from '_shared';
import { PlusIcon } from '@heroicons/react/outline';
import { Popover } from '@headlessui/react';
import FilterRolesPermissions from './FilterRolesPermissions/FilterRolesPermissions';
import RolesPermissionsTableTable from './RolesPermissionsTable';
import rolesPermissionsService from 'services/rolesPermissions.service';
import DialogComponent from 'components/Dialog/DialogComponent';
import CreateRole from 'components/Management/RolesPermissions/RolesPermissionsList/CreaterRole';

export default function RolesPermissionsList({ children, breadcrumb = true }) {
    const history = useHistory();
    const { t: translator } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [indexPage, setIndexPage] = useState('1-50');
    const [studentList, setStudentList] = useState([]);
    const [isFirstLoad, setFirstLoad] = useState(true);
    const [location, setLocation] = useState('clms')
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'created_at',
        total: 0,
    });
    const params: { id } = useParams();
    const workspaceId = params?.id;
    const [openModalCreateRole, setOpenModalCreateRole] = useState<boolean>(false);


    const getUserList = (page: number = 1, filter = undefined) => {
        const bodyRequest = {
            ...pagination,
            page: page,
            ...filter,
        };
        setLoading(true);
        rolesPermissionsService
            .getWorkspaceRoles(workspaceId, bodyRequest)
            .then((res: any) => {
                setFirstLoad(false)
                if (res) {
                    const { items, page, total } = res.data;
                    setStudentList(items);
                    setPagination({ ...bodyRequest, page, total });
                    setIndexPage(getIndexPage(page, 20, items.length));
                }
            })
            .finally(() => setLoading(false))
    };

    const handlePagination = (page: number) => {
        setLoading(true);
        getUserList(page);
    };

    useEffect(() => {
        getUserList(1);
    }, []);

    const onChangeFilter = (filter) => {
        getUserList(1, filter)
    }

    return (
        <div className="w-full">
            {breadcrumb && <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT')}
                containerClassName=" z-10 sticky top-0"
                pages={[{ name: translator('DASHBOARD.SIDEBAR.ROLES_PERMISSIONS'), href: '', className: '' }]}
            />}


            <div className='px-5 py-4 flex justify-between border-b border-gray-300'>
                <FilterRolesPermissions onChangeFilter={onChangeFilter} isFirstLoad={isFirstLoad} setLocation={setLocation} location={location} />
                <Popover className="relative">
                    <Popover.Button>
                        <ButtonPrimary className='h-fit !py-1.5 w-20' type="button" onClick={() => { setOpenModalCreateRole(true) }}> <PlusIcon className='w-5 h-5  text-white' />{translator("NEW")}</ButtonPrimary>
                    </Popover.Button>
                </Popover>
            </div>
            <div className={`px-5 pt-4`}>
                <div className="h-[calc(100vh-194px)] custom-scrollbar overflow-y-auto relative">
                    {loading ? (
                        <div className="flex items-center justify-center mt-4">
                            <CircleSpin color="text-primary-500" />
                        </div>
                    ) : (
                        <RolesPermissionsTableTable location={location} loading={loading} workspaceId={workspaceId} dataTable={studentList} translator={translator} refreshData={() => getUserList(1)} />
                    )}
                </div>
                <Pagination
                    indexPage={indexPage}
                    page={pagination?.page}
                    per_page={pagination?.per_page}
                    total={pagination.total}
                    callback={handlePagination}
                />
            </div>

            <DialogComponent
                isOpen={openModalCreateRole}
                title={translator("New Role")}
                onCloseModal={() => setOpenModalCreateRole(false)}
                maskClosable={false}
                styles="max-w-lg"
                child={
                    <CreateRole
                        submitted={(id) => {
                            history?.push(`/workspace/${params?.id}/setting?tab=role&tabPermission=overview`, {
                                roleId: id
                            })
                        }}
                        onCloseModal={() => setOpenModalCreateRole(false)}
                    />
                }
            />
        </div>
    );
}
