import { VIDEO_TYPES } from 'constant/form.const';
import { CONTENT_TYPE, CONTENT_TYPE_LESSON_BULDER, H5P_LIBRARY } from 'constant/h5p.const';
import { chunk } from 'lodash';

export const H5PTypes = (inputSearchH5PType: string = '') => {
    if (!inputSearchH5PType?.trim()) {
        const list =  Object.keys(H5P_LIBRARY)
        .sort((a,b)=>H5P_LIBRARY[a]?.localeCompare(H5P_LIBRARY[b]))
        .map((d, i) => {
            return {
                id: i,
                name: H5P_LIBRARY?.[d],
                value: "H5P." +  d,
            };
        });
        return {
            data: chunk(list, 10),
            total: list?.length
        };
    }
   
    const list = Object.keys(H5P_LIBRARY)
    .sort((a,b)=>H5P_LIBRARY[a]?.localeCompare(H5P_LIBRARY[b]))
    .filter((d) => H5P_LIBRARY[d]?.toLowerCase()?.includes(inputSearchH5PType?.toLowerCase()))
    .map((d, i) => {
        return {
            id: i,
            name: H5P_LIBRARY?.[d],
            value: "H5P." +  d,
        };
    });
    return {
        data: chunk(list, 10),
        total: list?.length
    };
};

export const ContentTypes = (inputSearchContentType: string = '', isLessonBulder = false) => {
    let contentType = isLessonBulder ? CONTENT_TYPE_LESSON_BULDER : CONTENT_TYPE

    if (!inputSearchContentType?.trim()) {
        const list =  Object.keys(contentType)
        .sort((a,b)=>contentType[a]?.localeCompare(contentType[b]))
        .map((d, i) => {
            return {
                id: i,
                name: contentType?.[d],
                value: d,
            };
        });
        return {
            data: chunk(list, 10),
            total: list?.length
        };
    }
    const list = Object.keys(contentType)
    .sort((a,b)=>contentType[a]?.localeCompare(contentType[b]))
    .filter((d, i) => contentType[d]?.toLowerCase()?.includes(inputSearchContentType?.toLowerCase()))
    .map((d, i) => {
        return {
            id: i,
            name: contentType?.[d],
            value: d,
        };
    });
    return {
        data: chunk(list, 10),
        total: list?.length
    };
}

export const checkFileType = (mineType) => {
    if (['image/png', 'image/jpe', 'image/jpeg'].includes(mineType)) return 'image';
    if (VIDEO_TYPES.includes(mineType)) return 'video';
    if (mineType === 'audio/mpeg') return 'audio';
    // if (mineType === 'application/h5p') return 'h5p';
    if (
        [
            '.doc',
            '.docx',
            'application/doc',
            'application/ms-doc',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ].includes(mineType)
    )
        return 'document';
    if (mineType === 'application/pdf') return 'pdf'
    if ([
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ].includes(mineType)) return 'presentation'
    if (mineType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') return 'spreadsheet'
    if (mineType === 'application/vnd.ms-excel') return 'spreadsheet'
    return mineType
};
