import React from "react";
import { H5PTask } from "./H5PTask";
import { NormalTask } from "./NormalTask";
import { SubmissionTask } from "./SubmissionTask";
import "./Tasks.scss";

export const Tasks = ({ tasks, setSelectTask, tabStatus, learnerSelect, refreshData, courseId }) => {
    return (<>
        {tasks?.map(item => {
            let status = tabStatus;
            if (status == 'waiting' && item?.num_of_submission > 0 && item?.is_graded) {
                status = 'graded';
            }
            if (!item?.attempts && !item?.is_finished && !item?.is_graded) {
                status = 'assigned';
            }
            return <div key={item?.id}>
            {
                item?.object_type == 'h5p' ?
                    <H5PTask task={item} addFeedback={setSelectTask} tabStatus={status} /> :
                    item?.object_type == 'submission' ?
                        <SubmissionTask learnerSelect={learnerSelect} task={item} addFeedback={setSelectTask} tabStatus={status} refreshData={refreshData} courseId={courseId}/> :
                        <NormalTask task={item} addFeedback={setSelectTask} tabStatus={status}/>
            }
        </div>
        })}
    </>)
}