import { StylesConfig } from 'react-select';
import { MultiValue } from './componentSelect';
import { useState } from 'react';
import classService from 'services/class.service';
import workspaceService from 'services/workspace.service';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import DialogComponent from 'components/Dialog/DialogComponent';
import { ButtonPrimary } from '_shared';
import EmptyStateImg from 'assets/img/empty-state/empty-stage-option.png';

export const initState: any = {
  page: 1,
  per_page: 20,
};

const customSelectStyle: StylesConfig<any, true> = {
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  dropdownIndicator: (base) => ({ ...base, padding: 4, display: 'flex' }),
  control: (base) => ({
    ...base,
    borderColor: '#e6e6e6',
    minHeight: '2rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  }),
  multiValue: (provided, state) => {
    return state.data.isSelected
      ? { display: 'none' }
      : {
        ...provided,
        background: '#F2F9FF',
        borderRadius: '100px',
        textColor: '#000000',
        fontWeight: 400,
      };
  },
};

export default function ModalSelect(props) {
  const {
    isOpenModal,
    closeMOdal,
    options,
    optionSelected,
    handleSelectOption,
    handleAddOption,
    placeholder,
    label,
    preSelectedOption,
    modalName,
    placeholderOnFocus,
    type,
  } = props;

  const [placeholderInput, setPlaceholaderInput] = useState({ text: placeholder, isFocus: false });
  const {t: translator} = useTranslation();

  const handleLoadOptions = async (inputValue, options, additional) => {
    let paginaition: any = {
      page: additional.page,
      per_page: 20,
    };

    try {
      const { items, page, total } =
        type === 'people'
          ? await workspaceService.getWorkspaceMembers(
            { id: params.id },
            { ...paginaition, q: inputValue, status: 'active&status=invite' }
          )
          : await classService.getCLassList(params.id, { ...initState, q: inputValue, status: 'active' });
      return {
        options: formatList(items, false),
        hasMore: [...options, ...formatList(items, false)].length < total,
        additional: {
          page: additional.page + 1,
        },
      };
    }
    catch (e) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 1
        },
      };
    }
  }

  const params = useParams<{ id: string; groupId: string }>();

  const formatList = (items, isSelected) => {
    if (items.length < 0) return [];
    const classListOptions = items.map((item) => {
      if (isSelected) {
        return {
          ...item,
          label: item?.name || item.display_name,
          value: item.id,
          isSelected,
        };
      }
      return {
        ...item,
        label: item?.name || item.display_name,
        value: item.id,
      };
    });
    return classListOptions;
  };

  return (
    <DialogComponent
      isOpen={isOpenModal}
      onCloseModal={closeMOdal}
      title={modalName}
      styles=" max-w-lg rounded-[16px] p-6 "
      stylesTitle='text-base'
      child={
        <div className="mt-2">
          <label htmlFor="group-class" className="text-ooolab_dark_1 font-semibold leading-ooolab_24px block text-xs">
            {label}
          </label>
          {/* @ts-ignore */}
          <AsyncPaginate
            debounceTimeout={1000}
            placeholder={placeholderInput.text}
            loadOptions={handleLoadOptions}
            additional={{
              page: 1,
            }}
            components={{ Option: options, MultiValue }}
            closeMenuOnSelect={false}
            hideSelectedOptions={true}
            className=" text-ooolab_dark_2 rounded font-normal mt-2"
            styles={customSelectStyle}
            isMulti
            defaultValue={optionSelected}
            onChange={handleSelectOption}
            backspaceRemovesValue={false}
            isClearable={true}
            isSearchable
            onFocus={() => setPlaceholaderInput({ text: placeholderOnFocus, isFocus: true })}
            onBlur={() => setPlaceholaderInput({ text: placeholder, isFocus: false })}
            noOptionsMessage={() => {
              return (
                  <div className='flex flex-col justify-center items-center h-full py-5'>
                      <img className='w-32' src={EmptyStateImg} alt="No options" />
                      <div className="text-sm text-gray-400">{translator('FORM_CONST.NO_DATA')}</div>
                  </div>
              );
          }}
          />
          <div className=" w-full flex justify-center mt-5">
            <ButtonPrimary
              type="button"
              onClick={handleAddOption}
              disabled={preSelectedOption.length <= optionSelected.length}
            >
              {"Add"}
            </ButtonPrimary>
          </div>
        </div>
      }
    />
  );
}
