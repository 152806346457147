import { PlusIcon } from '@heroicons/react/outline';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { DeleteIcon, DownloadIcon, WarningCircleIcon } from 'assets/icon';
import axios from 'axios';
import { IParamUploadContext, UploadContext } from 'contexts/Upload';
import dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';
import docIcon from 'assets/img/docIcon.png';
import pdfIcon from 'assets/img/pdfIcon.png';
import pngIcon from 'assets/img/pngIcon.png';
import jpegIcon from 'assets/img/jpegIcon.png';
import DialogComponent from 'components/Dialog/DialogComponent';
import debounce from 'lodash/debounce';
import { checkPermission, dowloadFile } from "utils/commonFun";
import { USER_STATUS } from 'components/Management/User/constant';
import { DocumentPreview } from '_shared/components/DocumentPreview/DocumentPreview';
import { useTranslation } from 'react-i18next';

export const accept_file_upload = [
    '.pdf',
    'application/pdf',
    'image/png',
    'image/jpg',
    'image/jpeg',
    '.doc',
    '.docx',
    'application/doc',
    'application/ms-doc',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

const LIMIT_CONTRACT = 50

export default function Contracts({ userData }) {
    const uploadContext: { handleUploadFile: (params: IParamUploadContext, handleAfterUploadFile?: any) => void , onReset: any} = useContext(UploadContext);
    const [contracts, setContracts] = useState([]);
    const refUpload = useRef<any>();
    const params: any = useParams();
    const workspaceId = params.id;
    const userId = params.userId;
    const [arrFiles, setArrFiles] = useState([]);
    const [isOpenModalDelete, setOpenModalDelete] = useState(false);
    const [contractSelected, setContractSelected] = useState(undefined);
    const [isOpenModalViewFile, setOpenModalViewFile] = useState(false);
    const {t} = useTranslation();

    const onChangeUpload = (e) => {
        if (e.target?.files?.length + contracts?.length > LIMIT_CONTRACT) {
            setArrFiles([...e.target?.files]?.slice(0, LIMIT_CONTRACT - contracts?.length));
            e.target.value = ''
            return Toastify.error(t("USER.CONTRACT.UPLOAD_CONTRACT_FAILED"))
        }
        setArrFiles([...e.target?.files]);
        e.target.value = ''
    };

    const getContracts = () => {
        workspaceService.getUserContracts(workspaceId, userId, { skip_pagination: true }).then(async (res) => {
            const files = [];
            if (res?.data?.length) {
                for await (const file of res?.data) {
                    const information = await axios({ url: file.file, method: 'HEAD' });
                    const size = +information?.headers?.['content-length'] / (1024 * 1024);
                    files.push({
                        id: file?.id,
                        size: size < 1 ? `${(size * 1024)?.toFixed(0)}KB` : `${size?.toFixed(1)}MB`,
                        lastModified: information?.headers?.['last-modified'],
                        type: information?.headers?.['content-type'],
                        url: file?.file,
                        name: file?.filename,
                    });
                }
            }
            setContracts(files);
        });
    };

    const debounceGetContracts = useCallback(
        debounce(() => getContracts(), 1000),
        []
    );

    useEffect(() => {
        if (arrFiles?.length) {
            uploadContext.handleUploadFile({files: arrFiles, maxFileSize: 100, total: 50, acceptFileUpload: accept_file_upload}, (file, onSuccess, onError) => {
                const formatContract = {
                    file: file?.file,
                    filename: file?.name,
                };
                workspaceService.addUserContracts(workspaceId, userId, [formatContract])
                    .then(() => {
                        debounceGetContracts();
                        onSuccess(formatContract);
                    })
                    .catch(() => onError(formatContract))
            });
        }
    }, [arrFiles]);

    useEffect(() => {
        getContracts();
    }, []);

    const deleteContract = (contractId) => {
        workspaceService
            .deleteUserContract(workspaceId, userId, contractId)
            .then((res) => {
                Toastify.success(t("USER.CONTRACT.DELETE_CONTRACT_SUCCESS"));
                setContracts(contracts?.filter((contract) => contract?.id !== contractId));
                setOpenModalViewFile(false)
            })
            .catch((err) => Toastify.error())
            .finally(() => setOpenModalDelete(false));
    };


    const download = (contract) => {
        dowloadFile(contract?.url, contract?.name);
    }

    return (
        <div className="border-t border-gray-300 mt-5 py-5 max-w-2xl">
            <div className="flex justify-between">
                <div className="text-sm font-semibold">{t("USER.CONTRACT.CONTRACTS")}</div>
                
                {checkPermission('userDetailsInfomation', 'U') && <ButtonOutline
                    className="flex w-fit gap-1 items-center !text-primary-500 border !border-primary-500"
                    type="button"
                    onClick={() => refUpload?.current?.click()}
                    disabled={userData?.membership?.status === USER_STATUS.DEACTIVATE || contracts?.length === LIMIT_CONTRACT}
                >
                    <PlusIcon className="w-5 h-5" />
                    {t("USER.CONTRACT.ADD_CONTRACT")}
                </ButtonOutline>}
            </div>
            {
                contracts?.length === LIMIT_CONTRACT && (
                    <div className='mt-4 mb-3 flex items-start border border-orange-300 bg-orange-50 rounded-lg p-3 gap-1'>
                        <WarningCircleIcon className='text-orange-500 w-6 h-6 min-w-[24px]'/>
                        <div>{t('USER.CONTRACT.WARNING_CONTRACT_50_FILES')}</div>
                    </div>
                )
            }
            <input
                ref={refUpload}
                accept={accept_file_upload?.join(',')}
                className="input mt-4"
                type="file"
                placeholder={''}
                hidden
                onChange={onChangeUpload}
                multiple
                disabled={!checkPermission('userDetailsInfomation', 'U')}
            />
            
            <div className="mt-5 flex flex-col gap-5 ">
                {contracts?.map((contract) => {
                    return (
                        <div className="border border-gray-300 rounded-lg p-3 flex justify-between" key={contract?.id}>
                            <div className="flex items-center gap-2 cursor-pointer" onClick={() => { setContractSelected(contract); setOpenModalViewFile(true), uploadContext.onReset(); }}>
                                <div>
                                    {(contract?.type?.includes('msword') || contract?.type?.includes('document')) && <img className="w-8 min-w-[32px]" src={docIcon} />}
                                    {contract?.type?.includes('pdf') && <img className="w-8 min-w-[32px]" src={pdfIcon} />}
                                    {contract?.type?.includes('png') && <img className="w-8 min-w-[32px]" src={pngIcon} />}
                                    {(contract?.type?.includes('jpg') || contract?.type?.includes('jpeg')) && <img className="w-8" src={jpegIcon} />}
                                </div>
                                <div>
                                    <div className="text-sm pr-3">{contract?.name}</div>
                                    <div className="text-xs flex gap-2 text-gray-500">
                                        <div>{contract?.size}</div>・
                                        <div>{t("USER.CONTRACT.UPLOAD_ON")} {dayjs(contract?.lastModified)?.format('DD/MM/YYYY')} </div>
                                    </div>
                                </div>
                            </div>
                            <div className="border-l border-gray-300 px-3 text-gray-500 flex gap-4 items-center">
                                {checkPermission('userDetailsInfomation', 'DL') && <div onClick={() => download(contract)}>
                                    <DownloadIcon width="24px" height="24px" fill="text-gray-500" />
                                </div>}
                                {checkPermission('userDetailsInfomation', 'D') && <div
                                    onClick={() => {
                                        setContractSelected(contract);
                                        setOpenModalDelete(true);
                                    }}
                                >
                                    <DeleteIcon />
                                </div>}

                            </div>
                        </div>
                    );
                })}
            </div>
            <DialogComponent
                title={t("USER.CONTRACT.DELETE_CONTRACT")}
                onCloseModal={() => setOpenModalDelete(false)}
                isOpen={isOpenModalDelete}
                child={
                    <div>
                        <div className="text-sm mt-5">{t("USER.CONTRACT.DELETE_CONTRACT_MSG")}</div>
                        <div className="mt-5 flex gap-4 justify-center">
                            <ButtonOutline className="cursor-pointer" onClick={() => setOpenModalDelete(false)} type={'button'}>
                                {t("CANCEL")}
                            </ButtonOutline>
                            <ButtonPrimary
                                className="!bg-red-500 !border-red-500 cursor-pointer"
                                type="button"
                                onClick={() => deleteContract(contractSelected?.id)}
                            >
                                {t("DELETE")}
                            </ButtonPrimary>
                        </div>
                    </div>
                }
            />
            <DocumentPreview
                closeModal={() => setOpenModalViewFile(false)}
                isOpen={isOpenModalViewFile}
                data={contractSelected}
                onDelete={() => deleteContract(contractSelected?.id)} />
        </div>
    );
}
