import { useEffect, useState } from 'react';
import DialogComponent from 'components/Dialog/DialogComponent';
import { useTranslation } from 'react-i18next';
import { ButtonOutline, ButtonPrimary } from '_shared';
import './Modal.scss'

const MAX_LENGTH = 255;

export default function ModalRenameLearningPath({ onCloseModal, isOpen, learningPath, onRename }) {
    const [learningPathName, setLearningPathName] = useState('');
    const { t: translator } = useTranslation();

    useEffect(() => {
      setLearningPathName(learningPath?.title)
    }, [learningPath])

    return (
        <DialogComponent
            title={translator('MODALS.RENAME')}
            isOpen={isOpen}
            onCloseModal={onCloseModal}
            child={
                <div className="ModalAction">
                    <input
                        value={learningPathName}
                        onChange={(e) => setLearningPathName(e.target.value)}
                        className="ModalAction-input"
                        placeholder={translator("MODALS.LEARNING_PATH.ENTER_LEARNING_PATH_NAME")}
                    />
                    {
                        learningPathName?.length > 255 && (
                            <div className='error-maxlength'>{translator("FORM_CONST.MAX_LENGTH_TEXT", {maxLength: MAX_LENGTH})}</div>
                        )
                    }
                    <div className="ModalAction-groupButton">
                        <ButtonOutline
                            onClick={onCloseModal}
                            type="button"
                        >
                          {translator('MODALS.CANCEL')}
                        </ButtonOutline>
                        <ButtonPrimary
                            disabled={!learningPathName?.trim() || name === learningPath?.name || learningPathName?.length > MAX_LENGTH}
                            onClick={() => onRename(learningPathName)}
                            type="button"
                        >
                          {translator('MODALS.CONFIRM')}
                        </ButtonPrimary>
                    </div>
                </div>
            }
        />
    );
}
