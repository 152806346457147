import { useEffect, useState } from 'react';
import { Tab } from '@headlessui/react';
import Calendar from './Calendar/Calendar';
import { InstructorAvailability } from './InstructorAvailability/InstructorAvailability';
import './CourseScheduling.scss';
import { SessionList } from './SessionList/SessionList';
import { checkPermission } from 'utils/commonFun';
import { useTranslation } from 'react-i18next';
import { useQueryParamsURL } from 'hooks/useQueryParamsURL';
import { useHistory, useParams } from 'react-router-dom';

const listTab = [
    {
        index: 0,
        key: 'SESSION.CALENDAR',
        title: 'CALENDAR',
        path: 'calendar',
    },
    {
        index: 1,
        key: 'SESSION.SESSION_LIST',
        title: 'SESSION_LIST',
        path: 'session-list',
    },
    {
        index: 2,
        key: 'DASHBOARD.SIDEBAR.INSTRUCTOR_AVAILABILITY',
        title: 'INSTRUCTOR_AVAILABILITY',
        path: 'instructor-availability',
    }
];

const CourseScheduling = () => {
    const {t} = useTranslation();
    const query = useQueryParamsURL();
    const history = useHistory();
    const params: { id: string; courseId: string } = useParams();

    const [tabSelectedIndex, setTabSelectedIndex] = useState<number>();

    useEffect(() => {
        if (query.get('subtab')) {
            setTabSelectedIndex(listTab.find(el => el.path === query.get('subtab')).index);
        } else setTabSelectedIndex(0);
    }, []);

    return (
        <div className='CoursePeople'>
            <Tab.Group 
                selectedIndex={tabSelectedIndex} 
                onChange={(tabIndex) => {
                    setTabSelectedIndex(tabIndex);
                    history.push(`/workspace/${params.id}/management/courses/${params.courseId}?tab=scheduling&subtab=${listTab[tabIndex].path}`);
                }}
            >
                <div className="CoursePeople-title">{t("SESSION.SESSIONS")}</div>
                <Tab.List className="CoursePeople-tablist">
                    {checkPermission('courseScheduling', 'R') && listTab.map((i, idx) => (
                        <Tab
                            className={({ selected }) => {
                                const tabClassName = `tagItem ${selected ? 'active ' : 'disable'
                                    }`;
                                return tabClassName;
                            }}
                            key={idx}
                        >
                            {t(i.key)}
                        </Tab>
                    ))}
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>
                        <Calendar />
                    </Tab.Panel>
                    <Tab.Panel>
                        <SessionList />
                    </Tab.Panel>
                    <Tab.Panel>
                        <InstructorAvailability />
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
};

export default CourseScheduling;
