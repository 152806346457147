
import { useHistory, useLocation, useParams } from 'react-router-dom';

export default function Tab({TAB}) {
    const search = useLocation()?.search;
    const tab = new URLSearchParams(search).get('tab');
    const params: { id: string } = useParams();
    const history = useHistory();
    const workspaceId = params?.id;
 
    return (
        <div className="InvoiceList-Tab">
            <div
                className={`TabItem ${tab === TAB.ALL_INVOICES.value ? 'active' : ''}`}
                onClick={() => history?.push(`/workspace/${workspaceId}/e-commerce/billing?tab=${TAB.ALL_INVOICES.value}`)}
            >
                {TAB.ALL_INVOICES.label}
            </div>
            <div
                className={`TabItem ${tab === TAB.RECURRING_INVOICES.value ? 'active' : ''}`}
                onClick={() => history?.push(`/workspace/${workspaceId}/e-commerce/billing?tab=${TAB.RECURRING_INVOICES.value}`)}
            >
                {TAB.RECURRING_INVOICES.label}
            </div>
        </div>
    );
}
