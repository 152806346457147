import { LibraryTypeEnum } from 'components/Library/LibraryContants';
import { accept_file_upload_library } from 'constant/form.const';
import { IParamUploadContext, UploadContext } from 'contexts/Upload';
import { forwardRef, useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import lessonService from 'services/lesson.service';
import './DragAndDrop.scss';
import { IframePdfViewer } from '_shared/PreviewContent/IframePDFViewer';
import libraryService from 'services/library.service';

 function DragAndDrop({ folderId, children, section, updateContentUpload }, ref) {
    const uploadContext: { handleUploadFile: (params?: IParamUploadContext, handleAfterUploadFile?: any) => void } = useContext(UploadContext);
    const [contentType, setContentType] = useState('');
    const [fileUrl, setFileUrl] = useState('');
    const params: any = useParams();
    const [arrFiles, setArrFiles] = useState([]);

    const onDrop = useCallback((acceptedFiles: Array<File>) => {
        const arrFile = [];
        for (let file of acceptedFiles) {

                arrFile.push(file);
        }
        setArrFiles(arrFile);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    useEffect(() => {
        if (arrFiles?.length) {
            uploadContext.handleUploadFile({files: arrFiles, folderId, from_lesson: true, sectionId: section?.id}, (file, onSuccess, onError) => {
                libraryService.uploadFiles(params?.id, file)
                .then((responseCreateFile: any) => {
                    setFileUrl(process.env.REACT_APP_HOST_CDN + file?.file)
                    setContentType(file?.object_type)
                    const fileInformation = {
                        object_id: responseCreateFile?.data?.object_id,
                        object_type: responseCreateFile?.data?.object_type,
                        order_number: section?.section_contents?.length || 0,
                    }
                    lessonService.addContentToSection(params?.id, params?.lessonId, file?.sectionId, fileInformation).then((res) => {
                        const fileCreated = {id: res?.id, library_content: { ...fileInformation, ...file, file: process.env.REACT_APP_HOST_CDN + file?.file}}
                        updateContentUpload(fileCreated)
                        onSuccess(file)
                    })
                    .catch(() => onError(file))
                })
                
            });
        }
    }, [arrFiles]);

    return (
        <div {...getRootProps()} className="upload-file"  ref={ref}>
            {fileUrl ? (
                <>
                    {LibraryTypeEnum.image == contentType && <img className="object-contain" src={fileUrl} alt="" />}
                    {LibraryTypeEnum.video == contentType && <iframe className="document" src={fileUrl}></iframe>}
                    {(LibraryTypeEnum.document == contentType ||
                        LibraryTypeEnum.presentation == contentType ||
                        LibraryTypeEnum.spreadsheet == contentType) && (
                        <iframe className="document" src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`} />
                    )}
                    {LibraryTypeEnum.audio == contentType && <audio controls src={fileUrl} className="w-full"></audio>}
                    {LibraryTypeEnum.pdf == contentType && (
                        <IframePdfViewer url={fileUrl} />
                    )}
                </>
            ) : (
                children
            )}
            <input
                {...getInputProps()}
                multiple
                data-max-size="2048"
                accept={accept_file_upload_library?.join(',')}
                className="input mt-4"
                type="file"
                placeholder={''}
            />
        </div>
    );
}


export default forwardRef(DragAndDrop)