import React from 'react';
import SpecificClassDashboard from 'components/Reports/ClassDashboard/SpecificClassDashboard';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';

const ClassDashboardDetailsPage: React.FC<{ setAuthStorage: React.Dispatch<React.SetStateAction<boolean>> }> = ({
    setAuthStorage,
}) => {
    return (
        <ManagePermissionWrapper>
            <SpecificClassDashboard />
        </ManagePermissionWrapper>
    );
};

export default ClassDashboardDetailsPage;
