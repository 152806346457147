import { useEffect, useMemo } from 'react';
import AssignmentIcon from 'assets/icon/AssignmentIcon';
import DriveIcon from 'assets/icon/DriveIcon';
import FileUploadIcon from 'assets/icon/FileUploadIcon';
import H5PIcon from 'assets/icon/H5PIcon';
import LessonIcon from 'assets/icon/LessonIcon';
import NewFolderIcon from 'assets/icon/NewFolderIcon';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import UploadFile from './UploadFile';
import useDrivePicker from 'react-google-drive-picker';
import { useAppDispatch } from 'hooks/hooks';
import { libraryUploadFilePicker } from 'store/features/librarySlice';
import { OpenBox } from 'assets/icon';
import { checkPermission } from 'utils/commonFun';

export default function PopoverAddLibrary({ setOpenModalNewFolder, setOpenSCORMModal, setOpenUpload }) {
    const { t: translator } = useTranslation();
    const history = useHistory();
    const params: { id: string; folderId: string } = useParams();
    const [openPicker, authResponse] = useDrivePicker();
    const dispatch = useAppDispatch();

    const handleOpenPicker = () => {
        openPicker({
            clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            developerKey: process.env.REACT_APP_GOOGLE_APIS_KEY,
            viewId: 'DOCS',
            // token: token, // pass oauth token in case you already have one
            showUploadView: true,
            showUploadFolders: true,
            supportDrives: true,
            multiselect: true,
            // customViews: customViewsArray, // custom view
            callbackFunction: (data) => {
                let _this = this;
                if (data.action === 'cancel') {
                    return;
                }
                if (data.docs?.length > 0) {
                    handleUploadFile(data.docs);
                }
            },
        });
    };

    const handleUploadFile = (data) => {
        dispatch(libraryUploadFilePicker(data));
    };

    const createList = useMemo(
        () => [
            {
                name: translator('LIBRARY.SELECT_FROM_GOOGLE'),
                onClick: () => {
                    handleOpenPicker();
                },
                icon: <DriveIcon />,
                isDisable: false,
            },
            {
                name: translator('LIBRARY.NEW_H5P_CONTENT'),
                onClick: () =>
                    history.push(`/workspace/${params.id}/h5p-content/new`, {
                        folderId: params?.folderId || null,
                    }),
                icon: <H5PIcon />,
                isDisable: !checkPermission('libraryContentFile', 'C'),
            },
            {
                name: translator('LIBRARY.NEW_LESSON'),
                onClick: () =>
                    history.push(`/workspace/${params.id}/lesson/new`, {
                        folderId: params?.folderId || null,
                    }),
                icon: <LessonIcon />,
                isDisable: !checkPermission('libraryContentLessonsOrScorm', 'C'),

            },
            {
                name: translator('LIBRARY.Import Package'),
                onClick: () => setOpenSCORMModal(true),
                icon: <span className="text-primary-500"><OpenBox /></span>,
                isDisable: !checkPermission('libraryContentLessonsOrScorm', 'C'),

            },
            {
                name: translator('LIBRARY.NEW_ASSIGNMENT'),
                onClick: () => {
                    history.push(`/workspace/${params.id}/assignment/new`, {
                        folderId: params?.folderId || null,
                    });
                },
                icon: <AssignmentIcon />,
                isDisable: !checkPermission('libraryContentAssignment', 'C'),
            },
            {
                name: translator('LIBRARY.NEW_FOLDER'),
                onClick: () => setOpenModalNewFolder(true),
                icon: <NewFolderIcon />,
                isDisable: !checkPermission('libraryContentFolder', 'C'),
            },
        ],
        []
    );

    useEffect(() => {
        if (authResponse) {
            localStorage.setItem('token_google', JSON.stringify(authResponse?.access_token));
        }
    }, [authResponse]);

    return (
        <div>
            <div className="hover:bg-blue-50 hover:border-blue-500 cursor-pointer">
                {checkPermission('libraryContentFile', 'C') ? <UploadFile folderId={params?.folderId || null}>
                    <div className="flex items-center w-full gap-2 ">
                        <FileUploadIcon />
                        <p className="text-sm font-normal group-hover:text-primary-500">{translator('LIBRARY.UPLOAD_FILE')}</p>
                    </div>
                </UploadFile> : ''}

            </div>
            {createList?.map((item) => {
                return (
                    <div
                        onClick={item?.onClick}
                        key={item?.name}
                        className={`group relative w-full py-3 px-4 hover:bg-blue-50 hover:border-blue-500 cursor-pointer items-center ${item?.isDisable ? 'opacity-60' : 'opacity-100'
                            }`}
                    >
                        <div className="flex items-center w-full gap-2">
                            {item?.icon}
                            <p className="text-sm font-normal group-hover:text-primary-500">{item?.name}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
