import React, { useState } from 'react';
// PACKAGES
import { useForm, useFieldArray } from 'react-hook-form';
import {
    XIcon,
    PlusCircleIcon,
    MinusCircleIcon,
    CheckIcon,
} from '@heroicons/react/outline';
// CONSTANTS
import { FORM_CONST } from 'constant/form.const';
// LOGIC
import {
    onSubmitForm,
    InviteToWorkspaceFormProps,
} from './InviteToWorkspaceFormFn';
import { useTranslation } from 'react-i18next';
import { classNames } from 'utils/commonFun';
import { ErrorMessage } from './ErrorMessage';

const InviteToWorkspaceForm: React.FC<InviteToWorkspaceFormProps> = ({
    isLoading,
    setApiErrorMsg,
    access_token,
    dispatch,
    setApiSuccessMsg,
    workspaceName,
    avatar,
    apiErrorMsg,
    memberListMsg,
    currency,
}) => {
    const { t: translator } = useTranslation();
    const [selectField, setSelectField] = useState(null);
    const [errorMess, setErrorMess] = useState('');
    const {
        register,
        control,
        handleSubmit,
        resetField,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        defaultValues: {
            listEmail: [{ email: '', role: 'admin' }],
        },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'listEmail',
    });

    const validateEmail = (email: string) => {
        return String(email).toLowerCase().match(FORM_CONST.EMAIL_REGEX);
    };

    const toFindDuplicates = (arry: any) => {
        const array = arry.map((item: any) => item.email);
        return array.some(
            (item: any, idx: number) => array.indexOf(item) != idx
        );
    };
    const renderIcon = (member: any, index: number) => {
        if (selectField?.id === member.id) {
            if (validateEmail(member?.email))
                return (
                    <button
                        title="CheckIcon"
                        className={'w-ooolab_w_5 h-ooolab_h_5'}
                        type="button"
                    >
                        <CheckIcon className="text-ooolab_50B957" />
                    </button>
                );
            else if (errors.listEmail?.[index]?.email?.type === 'pattern')
                return (
                    <button
                        title="XIcon"
                        className={'w-ooolab_w_5 h-ooolab_h_5'}
                        onClick={() => resetField(`listEmail.${index}.email`)}
                        type="button"
                    >
                        <XIcon />
                    </button>
                );
        } else
            return (
                <button
                    title="MinusCircleIcon"
                    className="w-5 lg:w-6"
                    onClick={() => remove(index)}
                    type="button"
                >
                    <MinusCircleIcon />
                </button>
            );
    };

    const handleChangInput = (value: string, index: number, member: any) => {
        fields[index].email = value;
        if (!validateEmail(value))
            setError(`listEmail.${index}.email`, {
                type: 'pattern',
                message: translator('AUTHENTICATION.WORKSPACE.EMAIL_VALIDATE'),
            });
        else clearErrors(`listEmail.${index}.email`);
        renderIcon(member, index);
    };

    const _onSubmitForm = (values: { listEmail: [] }) => {
        if (toFindDuplicates(values.listEmail)) {
            setErrorMess(
                translator('AUTHENTICATION.WORKSPACE.DUPLICATE_MEMBER')
            );
            return;
        } else if (values.listEmail.length > 5) {
            setErrorMess(
                translator(
                    'AUTHENTICATION.WORKSPACE.ENSURE_THIS_VALUE_HAS_AT_MOST_5_ITEMS'
                )
            );
            return;
        }
        onSubmitForm(
            values.listEmail,
            setApiErrorMsg,
            access_token,
            dispatch,
            setApiSuccessMsg,
            workspaceName,
            avatar,
            currency,
        );
    };

    return (
        <form
            onSubmit={handleSubmit(_onSubmitForm)}
            className="w-full animate-ooolab_fade_in max-w-xs lg:max-w-md"
            autoComplete="off"
        >
            <div className="text-2xl lg:text-3xl font-extrabold text-gray-700 text-center">
                {translator('AUTHENTICATION.WORKSPACE.ADD_YOUR_MEMBERS')}
            </div>
            <div className="mt-2 text-sm font-normal text-ooolab_282B5E text-center">
                {translator(
                    'AUTHENTICATION.WORKSPACE.INVITE_TEAM_MEMBERS_TO_START_COLLABORATING'
                )}
            </div>
            <div className="mt-6">
                <div className="w-full space-y-5 max-h-48 overflow-y-scroll custom-scrollbar">
                    {fields.map((member, index) => {
                        return (
                            <div key={member.id} className="w-full">
                                <div
                                    className={classNames(
                                        'flex flex-row justify-between items-center w-full ooolab_input_2 p-3 lg:px-4 lg:py-5 bg-transparent',
                                        errors.listEmail?.[index]?.email
                                            ? 'border-red-600'
                                            : 'border-ooolab_border_logout'
                                    )}
                                >
                                    <input
                                        type="text"
                                        className="w-full"
                                        placeholder={translator(
                                            'AUTHENTICATION.WORKSPACE.EMAIL'
                                        )}
                                        {...register(
                                            `listEmail.${index}.email`,
                                            {
                                                required: true,
                                                pattern: FORM_CONST.EMAIL_REGEX,
                                                setValueAs: (value: any) =>
                                                    value.trim(),
                                            }
                                        )}
                                        onChange={(e) =>
                                            handleChangInput(
                                                e.target.value,
                                                index,
                                                member
                                            )
                                        }
                                        onFocus={() => setSelectField(member)}
                                    />
                                    {renderIcon(member, index)}
                                </div>
                                {errors.listEmail?.[index]?.email?.type ===
                                    'required' && (
                                    <ErrorMessage
                                        label={translator(
                                            'FORM_CONST.REQUIRED_FIELD'
                                        )}
                                    />
                                )}
                                {errors.listEmail?.[index]?.email?.type ===
                                    'pattern' && (
                                    <ErrorMessage
                                        label={translator(
                                            'AUTHENTICATION.WORKSPACE.EMAIL_VALIDATE'
                                        )}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
                <div
                    onClick={() => append({ email: '', role: 'member' })}
                    className="flex space-x-2 mt-4 font-medium text-primary-500 text-ooolab_base cursor-pointer"
                >
                    <PlusCircleIcon className="w-5 lg:w-6" />
                    <span>
                        {translator('AUTHENTICATION.WORKSPACE.ADD_MEMBER')}
                    </span>
                </div>
            </div>
            {(errorMess || memberListMsg || apiErrorMsg) && (
                <span className="text-red-500 block">
                    {errorMess || memberListMsg || apiErrorMsg}
                </span>
            )}
            <div className="mt-8 text-center">
                <button
                    type="submit"
                    className={classNames(
                        'w-full mt-8 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white',
                        'bg-enterprise focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    )}
                >
                    {translator('AUTHENTICATION.WORKSPACE.INVITE_TEAM')}
                </button>
            </div>

            <p
                className="text-center mt-7 cursor-pointer"
                onClick={() => {
                    onSubmitForm(
                        [],
                        setApiErrorMsg,
                        access_token,
                        dispatch,
                        setApiSuccessMsg,
                        workspaceName,
                        avatar,
                        currency,
                    );
                }}
            >
                {translator('AUTHENTICATION.WORKSPACE.SKIP')}
            </p>
        </form>
    );
};

export default InviteToWorkspaceForm;
