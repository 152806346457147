

import "./EmptyStage.scss";
import { useTranslation } from "react-i18next";
import NO_CONTENT from 'assets/img/empty-state/content.png';
import { ButtonPrimary } from "_shared";
import { PlusIcon } from "@heroicons/react/outline";
import { UUID, checkPermission } from "utils/commonFun";
import "./EmptyStage.scss";
import { useParams } from "react-router-dom";
import lessonService from "services/lesson.service";
import { TYPE_LESSON_BUILDER } from "components/Library/LessonBuilder/constants";

export const EmptyStageSection = ({ setSections, setSectionSelected, sections = [], typeSelect, setTypeSelect, setOpenModalAddAsignment }) => {
    const params: any = useParams();
    const { t: translator } = useTranslation();
    const newSection = () => {
        const newSection = {
            title: 'New Section ' + (sections?.length + 1),
            order_number: (sections?.length || 0) + 1,
            type: typeSelect
        }
        lessonService.createSection(params?.id, params?.lessonId, newSection)
            .then((res) => {
                const sectionUpdated = { ...newSection, id: res?.id, uuid: UUID() }
                setSections([...sections, sectionUpdated])
                setSectionSelected({ ...newSection, id: res?.id, uuid: UUID() })
            })
    };
    const addAsignmentFromLibrary = () => {
        setOpenModalAddAsignment(true);
        setTypeSelect(TYPE_LESSON_BUILDER.assignments)
    }
    return (
        <div className="emptyStage center-item">
            <img className="nocontent-image" src={NO_CONTENT} />
            <div className="nocontent-wrapper">
                <div className="title">{translator('LIBRARY.UPLOAD_LOOK_LIKE_NOTHING_THERE')}</div>
                {checkPermission('libraryContentLessonsOrScorm', 'U') ? <> {
                    typeSelect === TYPE_LESSON_BUILDER.learningMaterials && (
                        <>
                            <div className="content">{translator('LIBRARY.START_CREATING')} </div>
                            <ButtonPrimary className="btn-create" type="button" onClick={newSection}>
                                <PlusIcon className="icon" /> {translator("LIBRARY.NEW_SECTION")}
                            </ButtonPrimary>
                        </>
                    )
                }
                    {
                        typeSelect === TYPE_LESSON_BUILDER.assignments && (
                            <>
                                <div className="content">{translator('LIBRARY.ADD_ASSIGNMENT_FROM_LIBRARY')} </div>
                                <ButtonPrimary className="btn-create" type="button" onClick={addAsignmentFromLibrary}>
                                    <PlusIcon className="icon" /> {translator("LIBRARY.ADD_FROM_LIBRARY")}
                                </ButtonPrimary>
                            </>
                        )
                    } </> : ''}

            </div>
        </div>
    )
}