
export default function ReceiptIcon({className = ''}) {
  return (
    <svg className={className} width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6999_31497)">
    <path d="M15.8337 18.3334H4.16699C3.50395 18.3334 2.86807 18.07 2.39923 17.6011C1.93038 17.1323 1.66699 16.4964 1.66699 15.8334V2.50002C1.66699 2.27901 1.75479 2.06704 1.91107 1.91076C2.06735 1.75448 2.27931 1.66669 2.50033 1.66669H14.167C14.388 1.66669 14.6 1.75448 14.7562 1.91076C14.9125 2.06704 15.0003 2.27901 15.0003 2.50002V12.5H18.3337V15.8334C18.3337 16.4964 18.0703 17.1323 17.6014 17.6011C17.1326 18.07 16.4967 18.3334 15.8337 18.3334ZM15.0003 14.1667V15.8334C15.0003 16.0544 15.0881 16.2663 15.2444 16.4226C15.4007 16.5789 15.6126 16.6667 15.8337 16.6667C16.0547 16.6667 16.2666 16.5789 16.4229 16.4226C16.5792 16.2663 16.667 16.0544 16.667 15.8334V14.1667H15.0003ZM13.3337 16.6667V3.33335H3.33366V15.8334C3.33366 16.0544 3.42146 16.2663 3.57774 16.4226C3.73402 16.5789 3.94598 16.6667 4.16699 16.6667H13.3337ZM5.00033 5.83335H11.667V7.50002H5.00033V5.83335ZM5.00033 9.16669H11.667V10.8334H5.00033V9.16669ZM5.00033 12.5H9.16699V14.1667H5.00033V12.5Z" fill="currentColor"/>
    </g>
    <defs>
    <clipPath id="clip0_6999_31497">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}
