
import { Enrollments } from "./Components/Overview/Enrollments";
import { MonthlyEnrollments } from "./Components/Overview/MonthlyEnrollments";
import { UsersOverview } from "./Components/Overview/UsersOverview";

export const OverviewWorkspaceTab = () => {
    return<>
        <div className="flex space-x-5">
            <Enrollments className="w-1/2" />
            <MonthlyEnrollments className="w-1/2" />
        </div>
        <div className="w-full mt-5">
            <UsersOverview />
        </div>
    </>
}