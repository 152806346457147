import { FC, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ModalUpdateRecordOfWork from './ModalUpdateRecordOfWork';
import TableRecordOfWork from './TableRecordOfWork';
import DialogComponent from 'components/Dialog/DialogComponent';
import { useParams } from 'react-router-dom';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { UserContext } from 'contexts/User/UserContext';
import TablePagination from 'components/Pagination';
import { getIndexPage } from 'utils/commonFun';
import classService from 'services/class.service';
import { Toastify } from '_shared';

const ClassRecordOfWork: FC = () => {
    const { t } = useTranslation();
    const params: { id: string; classId: string } = useParams();

    const [tzString, setTzString] = useState('America/New_York');
    const [isOpenModal, setOpenModal] = useState(false);
    const [data, setData] = useState([]);
    const [recordSelected, setRecordSelected] = useState<any>({});
    const [indexPage, setIndexPage] = useState('1-50');
    const [pagination, setPagination] = useState<{
        total: number;
        page: number;
        per_page: number;
        sort_by: string;
        order: string;
    }>();

    const { dispatch: workspaceDispatch, getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { userState: { result } } = useContext(UserContext);

    const { id: workspaceId, classId } = params;
    const { class: { recordOfWork } } = getWorkspaceDetailState;

    const translator = (key: string, option?: any) => {
        return t('RECORD_OF_WORK.' + key, option);
    };

    const handleUpdateRecord = (record: any) => {
        setRecordSelected(record);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handlePagination = (page: number) => {
        getRecordsApi(page);
    };

    const getRecordsApi = (page: number = 1) => {
        workspaceMiddleware
            .getListRecordOfWorkMiddleware(
                workspaceDispatch,
                { workspaceId, classId },
                {
                    page,
                    per_page: 10,
                    sort_by: 'end_time'
                }
            );
    }

    const updateRecordsApi = async (params: any) => {
            classService.updateRecordOfWorkService({ workspaceId, classId, sessionId: recordSelected?.id }, params)
            .then((res) => {
                getRecordsApi(pagination.page);
                Toastify.success();
            })
            .catch((error) => {
                Toastify.error(error);
            })
    }
    useEffect(() => {
        getRecordsApi();
    }, []);

    useEffect(() => {
        const { status, data: { items, total, page, per_page, sort_by, order } } = recordOfWork;
        if (items?.length) {
            setData(items);
            setPagination({ total, page, per_page, sort_by, order });
            setIndexPage(getIndexPage(page, per_page, items.length));
        }
    }, [recordOfWork]);

    useEffect(() => {
        if(result?.time_zone) setTzString(result?.time_zone);
    }, [result?.time_zone]);

    return (
        <div>
            <div>
                <div className="h-[calc(100vh-201px)] scrollbar overflow-auto">
                    <TableRecordOfWork
                        dataTable={data}
                        translator={translator}
                        handleUpdateRecord={handleUpdateRecord}
                        tz={tzString}
                    />
                </div>
                {data?.length !== 0 &&
                    <div className="flex items-center justify-between border-t border-ooolab_bar_color">
                        <p className="text-ooolab_dark_2 text-ooolab_xs font-semibold leading-ooolab_24px hidden lg:block">
                            {t(
                                'SHOWING_1_50_OF_TOTAL_PAGE',
                                {
                                    fromTo: indexPage,
                                    totalPage: pagination?.total,
                                }
                            )}
                        </p>
                        <div className="mt-[-1px]">
                            <TablePagination
                                onClickPagination={handlePagination}
                                perPage={pagination?.per_page}
                                total={pagination?.total}
                            />
                        </div>
                    </div>
                }
            </div>
            <DialogComponent
                isOpen={isOpenModal}
                width="lg:max-w-2xl"
                title={translator('UPDATE_RECORD_OF_LESSON')}
                onCloseModal={handleCloseModal}
                styles="px-0 py-5"
                stylesTitle='px-5 text-base font-semibold'
                child={
                    <ModalUpdateRecordOfWork
                        data={recordSelected}
                        translator={translator}
                        handleCloseModal={(values: any) => {
                            // re-fetch data and close modal
                            updateRecordsApi(values);
                            handleCloseModal();
                        }}
                    />
                }
            />
        </div>
    );
};

export default ClassRecordOfWork;
