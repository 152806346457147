import './DetailsFilePopup.scss';

const BlockContent = ({ children, title = undefined, number = undefined, styles= undefined }) => {
    return (
        <div className={`blockContent ${styles}`}>
            {title && (
                <div className="blockContent-header">
                    <div className="title">
                        {title}
                        {number !== undefined && <div className="number">{number}</div>}
                    </div>
                    <div className="detailsFilePopup-line"></div>
                </div>
            )}
            {children}
        </div>
    );
};

export default BlockContent;
