import React, { useState, useEffect } from 'react';
import SearchBox from 'components/SearchBox';
import { useParams } from 'react-router-dom';
import { useDebouncedState } from 'hooks/useDebounce';
import FooterPage from 'components/Footer/FooterPage';
import { TableCreditAccount } from './TableCreditAccount';
import Spinner from 'components/Spinner';
import workspaceService from 'services/workspace.service';
import { getIndexPage } from 'utils/commonFun';
import DialogComponent from 'components/Dialog/DialogComponent';
import { ModalDepositWithdraw } from './ModalDepositWithdraw';

const modalViewType = {
    deposit: 'DEPOSIT_CREDITS',
    withdraw: 'WITHDRAW_CREDITS',
};

export const CreditAccountList = ({ userData, translator, onViewCreditDetail }) => {
    const params: any = useParams();

    const [keyword, setKeyword] = useDebouncedState('');

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [indexPage, setIndexPage] = useState('0-0');
    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'updated_at',
        total: 0,
    });
    const [selectedRow, setSelectedRow] = useState(null);

    const workspaceId = params?.id;
    const userId = params?.userId;

    const fetchData = (page: number | string = 1) => {
        setLoading(true);
        const payload = {
            q: keyword,
            ...pagination,
            page,
        };
		delete payload.total;
		if (!payload.q) delete payload.q;

        workspaceService
            .getCreditAccounts(workspaceId, userId, payload)
            .then((res) => {
                if (res?.data) {
                    const { items, page, per_page, order, total, sort_by } = res?.data;
                    setData(items);
                    setPagination({ page, per_page, order, total, sort_by });
                    setIndexPage(getIndexPage(page, per_page, items?.length));
                }
            })
            .catch()
            .finally(() => setLoading(false));
    };

    useEffect(() => {
		fetchData();
    }, [keyword]);

    return (
        <>
            <div className="text-xl font-semibold">{translator('USER.LEFT_MENU.CREDIT_ACCOUNTS', true)}</div>
            <div className="-mx-5 px-5 py-4 border-b border-gray-300">
                <SearchBox className="max-w-md !w-72 h-fit" onSubmit={(e: any) => setKeyword(e)} />
            </div>
            <div className="pt-5">
                <div className="h-[calc(100vh-410px)] custom-scrollbar overflow-auto">
                    {loading ? (
                        <div className="h-[calc(100vh-410px)] flex justify-center items-center">
                            <Spinner />
                        </div>
                    ) : (
                        <TableCreditAccount
                            workspaceId={workspaceId}
                            userId={userId}
                            dataTable={data}
                            translator={translator}
                            onViewDetail={onViewCreditDetail}
                            onDeposit={(values) => setSelectedRow(values)}
                            onWithdraw={(values) => setSelectedRow(values)}
                        />
                    )}
                </div>
                <FooterPage
                    indexPage={indexPage}
                    total={pagination.total}
                    per_page={pagination.per_page}
                    page={pagination.page}
                    callback={(page) => fetchData(page)}
                />
            </div>
            <DialogComponent
                isOpen={!!selectedRow}
                onCloseModal={() => setSelectedRow(null)}
                title={!!selectedRow ? translator(modalViewType?.[selectedRow?.type]) : null}
                styles="max-w-md"
                child={
                    <ModalDepositWithdraw
                        userData={userData}
                        translator={translator}
                        type={selectedRow?.type}
                        data={selectedRow}
                        onClose={() => setSelectedRow(null)}
                        onConfirm={() => {
                            setSelectedRow(null);
                            fetchData(pagination.page);
                        }}
                    />
                }
            />
        </>
    );
};
