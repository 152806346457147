import { useTranslation } from 'react-i18next';
import {components} from 'react-select'
import makeAnimated from "react-select/animated";
import {getTypePerson} from '../../utils';

export const Option = (props) => {
  if (props.isSelected) return null
  return (
    <div>
      <components.Option {...props}  >
        <div className='flex items-center'>
          {/* <input
            className='mr-2'
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "} */}
          <label>{props.label}</label>
        </div>
      </components.Option>
    </div>
  );
};

export const OptionPeople = (props) => {
  const {t: translate} = useTranslation();
  if (props.isSelected) return null
  const {t} = useTranslation();
  return (
    <div>
      <components.Option {...props}  >
        <div className='flex items-center justify-between'>
          <div className=' flex items-center'>
            <img className=' rounded-full w-5 h-5 mr-2' src={props.data.avatar_url} alt="" />
            <label className=' max-w-[300px] truncate text-ellipsis text-ooolab_dark_1'>{`(ID:${props.data.id}) ${props.label}`}</label>
          </div>
          <div className='text-left min-w-[60px] capitalize text-[10px] text-ooolab_dark_2'>
            {getTypePerson((props?.data?.membership?.type === 'parent' || props?.data?.membership?.type === 'student' 
              ? props?.data?.membership?.type
              : props?.data?.membership?.user_role?.name
              ) || null, translate
            )}
          </div>
        </div>
      </components.Option>
    </div>
  );
};

export const animatedComponents = makeAnimated();

export const MultiValue = props => {
  return (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  )
}