import React, { useEffect, useState } from 'react';
import dayJs from 'dayjs';

import { ArrowDownIcon } from '@heroicons/react/solid';
import { H5PFile, IContentListEntry, IH5PContentList, ParamsH5P } from 'types/H5P.type';

import TablePagination from 'components/Pagination';

import TrashFileTableView from './TrashFileTableView';

import { useTranslation } from 'react-i18next';
import { ColumnWithLooseAccessor } from 'react-table';
import Tooltip from 'components/Tooltip';
import Table from 'components/Table';
import FooterPage from 'components/Footer/FooterPage';
import { getIndexPage } from 'utils/commonFun';

interface TrashTableViewProps {
    contentList: any;
    setContentList: React.Dispatch<React.SetStateAction<IH5PContentList>>;
    selected: number | undefined;
    setSelected: React.Dispatch<React.SetStateAction<number | undefined>>;
    handlePagination(page: number): void;
}

const TrashTableView: React.FC<TrashTableViewProps> = ({ contentList, setContentList, selected, setSelected, handlePagination }) => {
    const { t: translator } = useTranslation();

    const columns: ColumnWithLooseAccessor[] = React.useMemo(
        () => [
            {
                Header: () => <p className="text-left pl-4">{translator('NAME')}</p>,
                accessor: 'title', // accessor is the "key" in the data
                Cell: (d: any) => {
                    return (
                        <div className=" whitespace-nowrap  ">
                            <Tooltip title={d?.value} mlClass="ml-0">
                                <span className="flex items-center text-sm  font-normal group-hover:text-primary-500 pl-3">
                                    {d?.value?.length < 75 ? d?.value : `${d?.value?.slice(0, 76)}...`}
                                </span>
                            </Tooltip>
                        </div>
                    );
                },
            },
            {
                Header: () => (
                    <div className="col-span-3 text-primary-500 flex  items-center  cursor-pointer ">
                        <p className="text-sm ">{translator('LAST_MODIFIED')}</p>
                        <ArrowDownIcon className="w-4 h-4 ml-1 " />
                    </div>
                ),
                accessor: 'updated_at',
                Cell: (d: any) => {
                    return (
                        <div className=" whitespace-nowrap  ">
                            <span className="inline-flex text-sm text-ooolab_gray_3  pl-1">
                                <p className="text-sm ">{dayJs.utc(d?.value).locale('en-gb').fromNow()}</p>
                            </span>
                        </div>
                    );
                },
            },
            {
                Header: () => <p className=""> Owner</p>,
                accessor: 'created_by',
                Cell: (d: any) => {
                    return (
                        <div className="whitespace-nowrap  ">
                            <span className="inline-flex text-ooolab_gray_3 items-center pl-1 text-sm">
                                <span className="text-sm">{d?.value && d?.value.display_name}</span>
                            </span>
                        </div>
                    );
                },
            },
        ],
        [contentList]
    );
    return (
        <div className=" ">
            <div className="overflow-x-auto overflow-y-hidden">
                <div className="align-middle inline-block min-w-full px-2 min-h-height_75vh">
                    {contentList && (
                        <Table
                            columns={columns}
                            data={contentList?.items}
                            rowProps={{
                                className: 'bg-white hover:bg-gray-50 cursor-pointer',
                            }}
                            headerProps={{
                                className: 'bg-gray-50',
                            }}
                            className="min-w-full divide-y divide-gray-200"
                            onClickRow={(e) => setSelected(e?.id)}
                        />
                    )}
                </div>
                <div className='px-2'>
                    {contentList && (
                        <FooterPage
                            indexPage={getIndexPage(contentList?.page, 10, contentList?.items?.length) || '0-0'}
                            total={contentList?.total || 0}
                            per_page={10}
                            page={contentList?.page || 1}
                            callback={handlePagination}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default TrashTableView;
