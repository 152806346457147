export const UploadIcon = ({
    width = '24px',
    height = '24px',
    fill = '#F64B7F',
    className='',
}: {
    width?: string;
    height?: string;
    fill?: string;
    className?:string;
}) => {
    return (
        <svg
            width="18"
            height="15"
            viewBox="0 0 18 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M0.750001 9.87501C0.749539 9.0466 0.960316 8.23176 1.36241 7.50748C1.76451 6.78321 2.34465 6.17342 3.048 5.73576C3.23363 4.28832 3.94035 2.95813 5.03589 1.99412C6.13143 1.03011 7.54071 0.498352 9 0.498352C10.4593 0.498352 11.8686 1.03011 12.9641 1.99412C14.0597 2.95813 14.7664 4.28832 14.952 5.73576C15.8244 6.27852 16.503 7.08306 16.891 8.03449C17.2789 8.98592 17.3563 10.0356 17.1121 11.0336C16.8679 12.0317 16.3146 12.9271 15.5313 13.5919C14.7479 14.2568 13.7745 14.6571 12.75 14.7358L5.25 14.75C2.733 14.5445 0.750001 12.4415 0.750001 9.87501ZM12.636 13.2403C13.3454 13.1857 14.0194 12.9084 14.5617 12.4479C15.104 11.9874 15.487 11.3673 15.6558 10.6762C15.8247 9.98505 15.7708 9.25823 15.5019 8.59954C15.233 7.94086 14.7628 7.384 14.1585 7.00851L13.5532 6.63126L13.4633 5.92476C13.3231 4.84002 12.7927 3.84346 11.9712 3.12132C11.1497 2.39919 10.0934 2.0009 8.99963 2.0009C7.90587 2.0009 6.84954 2.39919 6.02807 3.12132C5.20659 3.84346 4.67619 4.84002 4.536 5.92476L4.446 6.63126L3.84225 7.00851C3.23797 7.38395 2.76779 7.94075 2.49885 8.59937C2.22991 9.25799 2.17597 9.98476 2.34473 10.6759C2.5135 11.367 2.89633 11.9871 3.43856 12.4476C3.98079 12.9082 4.65469 13.1856 5.364 13.2403L5.49375 13.25H12.5062L12.636 13.2403ZM9.75 8.75001V11.75H8.25V8.75001H6L9 5.00001L12 8.75001H9.75Z"
                fill={fill}
            />
        </svg>
    );
};
