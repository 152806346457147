import { useTranslation } from 'react-i18next'
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Tab, { TAB } from './Components/Tab';
import Academic from './Components/Academics/Academic';
import News from './Components/News/News';
import './index.scss'
import { TeacherDashboardService } from 'services';

export default function InstructorSchedule() {
  const {t: translator} = useTranslation();
  const search = useLocation()?.search;
  const tab = new URLSearchParams(search).get('tab')
  const history = useHistory();
  const params: {id: string, notificationId: string} = useParams();

  useEffect(() => {
    if(!tab) history.push(`/workspace/${params?.id}/instructor-dashboard/notifications?tab=ACADEMICS`)
  }, [tab])

  return (
    <div>
      <div className='sticky top-0 bg-white z-10'>
        <PageBreadcrumb
          pageClassName="!font-semibold !text-gray-800"
          pageNameDefault={translator('INSTRUCTOR_PORTAL.NOTIFICATIONS')}
          isShowIconNotification={true}
          pages={[]}
          onClickPageNameDefault={() => history.push(`/workspace/${params?.id}/instructor-dashboard/notifications`)}
        />
        <Tab />
      </div>
      {
        tab === TAB.ACADEMICS && (
          <Academic />
        )
      }
      {
        tab === TAB.NEWS && (
          <News />
        )
      }
    </div>
  )
}
