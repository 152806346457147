import { PlusIcon, SearchIcon } from '@heroicons/react/outline';
import { useBoolean } from 'hooks/custom';
import ModalCreate from '../../components/ModalCreateWrapper';
import CreateGroupFrom from './CreateGroupsForm';
import TableGroups from './TableGroups';
import ModalViewDetail from './ModalViewDetail';
import { useEffect, useState } from 'react';
import { useDebouncedState } from 'hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import GroupService from 'services/group.service';
import groupService from 'services/group.service';
import ButtonComponent from 'components/Button/ButtonComponent';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import DialogComponent from 'components/Dialog/DialogComponent';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import Pagination from 'components/V2/Pagination';
import SearchBox from 'components/V2/SearchBox';

const GroupsManagement = ({ children }) => {
    const params: any = useParams();
    const { t: translator } = useTranslation();
    const { booleanValue: modalCreate, toggleBooleanValue: toggleModalCreate } = useBoolean();
    const { booleanValue: modalViewDetail, toggleBooleanValue: toggleModalViewDetail } = useBoolean();
    const [loading, setLoading] = useState(false);
    const [indexPage, setIndexPage] = useState('1-50');
    const [keyword, setKeyword] = useDebouncedState('');
    const [groupList, setGroupList] = useState([]);
    const [detail, setDetail] = useState({ items: [], type: '' });
    const [selectedId, setSelectedId] = useState('');
    const [order, setOrder] = useState({
        order: 'desc',
        sort_by: 'created_at',
    });

    const [paginationDetail, setPaginationDetail] = useState({
        page: 1,
        per_page: 10,
        order: 'desc',
        sort_by: 'created_at',
        total: 0,
    });

    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'created_at',
        total: 0,
    });

    const workspaceId = params.id;

    const getGroupList = (page: number | string = 1) => {
        setLoading(true);
        GroupService.getGroupService(workspaceId, {
            name: keyword,
            ...pagination,
            page,
            ...order,
        })
            .then((res) => {
                const { items, page, per_page, order, sort_by, total } = res.data;
                setGroupList(items);
                setPagination({ ...pagination, page, per_page, order, sort_by, total });
                setIndexPage(getIndexPage(page, per_page, items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getGroupList(1);
    }, [keyword, order]);

    const handlePagination = (page: number) => {
        getGroupList(page);
    };

    const handleDeleteGroup = (id) => {
        groupService.deleteGroupService(params.id, id).then((res) => {
            getGroupList(1);
        });
    };

    const handleViewDetailClass = (id) => {
        setSelectedId(id);
        groupService.getClassSelectedService(params.id, id, paginationDetail).then((res) => {
            const { items, page, per_page, order, sort_by, total } = res.data;
            setDetail({ items, type: 'class' });
            setPaginationDetail({ ...paginationDetail, page, per_page, order, sort_by, total });
            toggleModalViewDetail();
        });
    };

    const handleViewDetailUser = (id) => {
        setSelectedId(id);
        groupService.getPeopleSelectedService(params.id, id, paginationDetail).then((res) => {
            const { items, page, per_page, order, sort_by, total } = res.data;
            setDetail({ items, type: 'people' });
            setPaginationDetail({ ...paginationDetail, page, per_page, order, sort_by, total });
            toggleModalViewDetail();
        });
    };

    const handleNextPage = () => {
        if (detail.type === 'class') {
            groupService
                .getClassSelectedService(params.id, selectedId, { ...paginationDetail, page: paginationDetail.page + 1 })
                .then((res) => {
                    const { items, page, per_page, order, sort_by, total } = res.data;
                    setDetail({ items: [...detail.items, ...items], type: 'class' });
                    setPaginationDetail({ ...paginationDetail, page, per_page, order, sort_by, total });
                });
        }
        if (detail.type === 'people') {
            groupService
                .getPeopleSelectedService(params.id, selectedId, { ...paginationDetail, page: paginationDetail.page + 1 })
                .then((res) => {
                    const { items, page, per_page, order, sort_by, total } = res.data;
                    setDetail({ items: [...detail.items, ...items], type: 'people' });
                    setPaginationDetail({ ...paginationDetail, page, per_page, order, sort_by, total });
                });
        }
    };

    return (
        <>
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.COMMUNICATION')}
                containerClassName=" z-10 sticky top-0"
                pages={[{ name: translator('GROUP.GROUP_MANAGEMENT'), href: ``, className: '' }]}
            />
            <div className="w-full px-2">
                <DialogComponent
                    isOpen={modalCreate}
                    onCloseModal={toggleModalCreate}
                    title={translator('GROUP.ADD_CUSTOM_GROUP')}
                    styles=" rounded-[16px] max-w-[400px]"
                    stylesTitle="text-base"
                    child={<CreateGroupFrom toggleModalCreate={toggleModalCreate} />}
                />
                <ModalCreate
                    isOpen={modalViewDetail}
                    onClose={toggleModalViewDetail}
                    title={detail.type === 'class' ? 'Class Detail' : translator('People Detail')}
                    styles="rounded-[16px] max-w-[470px]"
                >
                    {' '}
                    <p className=" text-xs mt-6 px-3 font-semibold text-ooolab_dark_1">
                        {detail.type === 'class' ? 'Class' : translator('People')}
                    </p>
                    <ModalViewDetail
                        detail={detail}
                        pagination={paginationDetail}
                        handleNextPage={handleNextPage}
                        onClose={toggleModalViewDetail}
                    />
                </ModalCreate>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 py-4">
                    <SearchBox className="w-full max-w-xs" placeholder={translator("GROUP.SEARCH")} onSubmit={(e: any) => setKeyword(e)} />
                    {checkPermission('customGroup', 'C') && <div className="lg:flex lg:justify-end">
                        <ButtonComponent
                            title={translator('GROUP.ADD_NEW_GROUP')}
                            classStyle="xs:p-1 xs:text-sm primary-button-apollo "
                            onClickButton={toggleModalCreate}
                            icon={<PlusIcon className="w-4 h-4 mr-ooolab_m_1" />}
                        />
                    </div>}
                </div>

                <div className="h-[calc(100vh-182px)] custom-scrollbar overflow-y-auto">
                    {loading ? 
                        <div className="h-[calc(100vh-182px)] flex items-center justify-center">
                            <CircleSpin color="text-primary-500" />
                        </div>
                        :
                        <TableGroups
                            workspaceId={workspaceId}
                            dataTable={groupList}
                            handleViewDetailClass={handleViewDetailClass}
                            handleViewDetailUser={handleViewDetailUser}
                            handleDeleteGroup={handleDeleteGroup}
                            order={order}
                            setOrder={setOrder}
                        />
                    }
                </div>
                <Pagination
                    indexPage={indexPage}
                    page={pagination.page}
                    per_page={pagination.per_page}
                    total={pagination.total}
                    callback={handlePagination}
                />
                
            </div>
        </>
    );
};

export default GroupsManagement;
