import { InputField } from 'components/InputForm/InputForm';
import { SelectField } from 'components/Select';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';
import { PAYMENT_GETWAY_OPTIONS, PAYMENT_OPTION_LIST } from '../../constants';
import DatePickerInput from 'components/Management/components/Form/DatePicker';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import './CreateInvoice.scss';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import FormatOptionPeople from '../components/FormatOptionPeople';
import ClockIcon from 'assets/icon/ClockIcon';
import InputRadio from '_shared/components/Form/InputRadio/InputRadio';
import { ErrorMessage } from '@hookform/error-message';
import { VALIDATE_FIELD } from 'utils/validate';
import paymentService from 'services/payment.service';
import { GuideAddingBank, GuidePaymentGetway } from '../components/GuideAddingBank';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from '_shared/components/Select';

let loadingSubmit = false;
export default function CreateRecurringInvoice({ onClose, onRefreshData }) {
    const params: { id } = useParams();
    const workspaceId = params.id;
    const { t: translator } = useTranslation();
    const {
        handleSubmit,
        formState: { errors, isDirty },
        control,
        setValue,
        watch,
        register,
        clearErrors,
        setError,
    } = useForm({ mode: 'onChange' });
    const [paymentList, setPaymentList] = useState([]);
    const [paymentGetwayOptions, setPaymentGetwayOptions] = useState([]);
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: workspaceDetailInformation } = getWorkspaceDetailState;

    const [learnerLearningGroupList, setlearnerLearningGroupList] = useState([]);
    const [inputSearchLearnerLearningGroup, setInputSearchlearnerLearningGroup] = useDebouncedState('', 0);
    const [paginaginlearnerLearningGroup, setPaginationLearnerLearningGroup] = useState({ total: 0, page: 1, per_page: 10 });
    const [loadingLearnerLearningGroup, setLoadingLearnerLearningGroup] = useState(false);

    const [courseList, setcourseList] = useState([]);
    const [inputSearchCourse, setInputSearchCourse] = useDebouncedState('', 0);
    const [paginaginCourse, setPaginationCourse] = useState({ total: 0, page: 1, per_page: 10 });
    const [loadingCourse, setLoadingCourse] = useState(false);

    const getCourses = (page: number) => {
        setLoadingCourse(true);
        let payload: any = {
            page,
            per_page: paginaginCourse.per_page,
            title: inputSearchCourse || null,
        };
        if (!payload?.title) delete payload?.title;

        workspaceService
            .getCoursesList(workspaceId, payload)
            .then((res) => {
                const newOptions = res?.items?.map((el: any) => {
                    return {
                        id: el?.id,
                        name: el?.title,
                        value: el?.id,
                    };
                });
                if (page === 1) setcourseList(newOptions);
                else setcourseList([...courseList, ...newOptions]);
                setPaginationCourse({ ...paginaginCourse, page: res?.page, total: res?.total });
            })
            .finally(() => setLoadingCourse(false));
    };

    const getLearnerAndLearningGroup = (page: number) => {
        setLoadingLearnerLearningGroup(true);
        let payload: any = {
            page,
            per_page: paginaginlearnerLearningGroup.per_page,
            q: inputSearchLearnerLearningGroup || null,
        };
        if (!payload?.title) delete payload?.title;

        workspaceService
            .getLearnerAndLearningGroup(workspaceId, payload)
            .then((res) => {
                const newOptions = res?.data?.items?.map((el: any) => {
                    return {
                        avatar_url: el?.avatar_url,
                        id: el?.id,
                        name: el?.name,
                        value: el?.id,
                        type: el?.type,
                    };
                });
                if (page === 1) setlearnerLearningGroupList(newOptions);
                else setlearnerLearningGroupList([...learnerLearningGroupList, ...newOptions]);
                setPaginationLearnerLearningGroup({
                    ...paginaginlearnerLearningGroup,
                    page: res?.data?.page,
                    total: res?.data?.total,
                });
            })
            .finally(() => setLoadingLearnerLearningGroup(false));
    };


    const getPaymenListt = () => {
        paymentService.getListPaymentDetail(workspaceId, {skip_pagination: true})
        .then((res) => {
            const payments = res?.data?.items?.map((item) => {
                return {
                    ...item,
                    label: item?.bank_name,
                    value: item?.id,
                }
            })
            setPaymentList(payments)
        })
    }

    const fetchPaymentDetails = () => {
        paymentService.getIntegrationList(params?.id).then((res) => {
            const arrTemp = [];
            res.data?.items?.forEach((el: any) => {
                const payItem = PAYMENT_GETWAY_OPTIONS(translator).find((item) => item?.name === el?.type);
                if (!!payItem && el?.enabled) arrTemp.push({ ...payItem, value: el?.id });
            });
            setPaymentGetwayOptions(arrTemp);
        });
    };


    const createInvoice = (values) => {
        const startAt = `${dayjs(values?.start_at).format('YYYY-MM-DD')} ${dayjs(values?.start_at).format('HH:mm')} `;
        const endAt = `${dayjs(values?.end_at).format('YYYY-MM-DD')} ${dayjs(values?.end_at).format('HH:mm')} `;

        const payload = {
            learner_ids: values?.objects?.filter((object) => object?.type === 'learner')?.map((learner) => learner?.id),
            learning_group_ids: values?.objects
                ?.filter((object) => object?.type === 'learning_group')
                ?.map((learningGroup) => learningGroup?.id),
            course_ids: values?.courses?.map((course) => course?.id),
            amount: values?.amount,
            payment_option: values?.payment_option?.value,
            payment_detail_id: values?.payment_option?.value === 'bank_transfer' ? values?.payment_detail?.value : undefined,
            payment_gateway_id: values?.payment_option?.value === 'payment_gateway' ? values?.payment_detail?.value : undefined,
            schedule_type: values?.schedule_type?.value,
            start_at: dayjs(startAt).format(),
            end_at: dayjs(endAt).format(),
            due_day: +values?.due_day,
            frequency_type: values?.frequency_type,
            name: values?.name,
        };
        if (!payload.learning_group_ids?.length) delete payload.learning_group_ids;
        if (!payload.learner_ids?.length) delete payload.learner_ids;

        if (loadingSubmit) return;
        loadingSubmit = true;

        workspaceService
            .createRecurringInvoice(workspaceId, payload)
            .then((res) => {
                Toastify.success(translator('CREATE_INVOICE_SUCCESSFULLY'));
                onRefreshData();
                onClose();
            })
            .catch(() => {
                Toastify.error(translator('CREATE_INVOICE_FAILED'));
            })
            .finally(() => loadingSubmit = false);
    };

    useEffect(() => {
        setValue('end_at', null);
    }, [watch('start_at')]);

    useEffect(() => {
        setValue('frequency_type', 'monthly');
        setValue('due_day', { label: 10, value: 10 });
    }, []);

    useEffect(() => {
        getPaymenListt()
        fetchPaymentDetails();
    }, [])

    useEffect(() => {
        getCourses(1);
    }, [inputSearchCourse]);

    useEffect(() => {
        getLearnerAndLearningGroup(1);
    }, [inputSearchLearnerLearningGroup]);

    return (
        <form className="CreateInvoice mt-5" onSubmit={handleSubmit(createInvoice)}>
            <InputField
                isRequired
                label={translator('RECURRING_INVOICE_NAME')}
                placeholder={translator('ENTER_RECURRING_INVOICE_NAME')}
                name="name"
                className="col-span-1"
                errors={errors}
                register={register('name', {
                    required: {
                        value: true,
                        message: translator('FORM_CONST.REQUIRED_FIELD'),
                    },
                    maxLength: {
                        value: 100,
                        message: translator('FORM_CONST.MAX_LENGTH_TEXT', { maxLength: 255 }),
                    },
                })}
            />
            <div className='mt-5'>
                <SelectBox
                    control={control}
                    label={translator('LEARNER_AND_LEARNING_GROUP')}
                    name="objects"
                    placeholder={translator('SEARCH_LEARNER_OF_LEARNING_GROUP')}
                    errors={errors}
                    isMulti={true}
                    loading={loadingLearnerLearningGroup}
                    value={watch('objects') || []}
                    onSearch={setInputSearchlearnerLearningGroup}
                    getData={getLearnerAndLearningGroup}
                    data={learnerLearningGroupList}
                    pagination={paginaginlearnerLearningGroup}
                    numberOfItemDisplayed={3}
                    isRequired
                    formatOptionValue={(item) => (
                        <>
                            <img className="item-avatar" src={item?.avatar_url} />
                            <span className="text-ellipsis truncate max-w-[95px]">{item?.name}</span>
                        </>
                    )}
                />
            </div>
            <div className="CreateInvoice-grid">
                <div className="col-span-1">
                    <SelectBox
                        control={control}
                        label={translator('COURSES_LABEL')}
                        name="courses"
                        placeholder={translator('SEARCH_COURSES')}
                        errors={errors}
                        isMulti={true}
                        loading={loadingCourse}
                        value={watch('courses') || []}
                        onSearch={setInputSearchCourse}
                        getData={getCourses}
                        data={courseList}
                        pagination={paginaginCourse}
                        numberOfItemDisplayed={2}
                        formatOptionLabel={(item) => <div className="text-ellipsis truncate">{item?.name}</div>}
                        formatOptionValue={(item) => <div className="text-ellipsis truncate max-w-[55px]">{item?.name}</div>}
                    />
                </div>
                <InputField
                    isRequired
                    label={translator('AMOUNT')}
                    placeholder={translator('ENTER_AMOUNT')}
                    name="amount"
                    className="col-span-1"
                    type="floatNumber"
                    register={register('amount', {
                        required: {
                            value: true,
                            message: translator('FORM_CONST.REQUIRED_FIELD'),
                        },
                        min: {
                            value: 1,
                            message: 'Please enter at least 1',
                        },
                        max: {
                            value: 1000000000,
                            message: 'Please enter no more than 1,000,000,000',
                        },
                    })}
                    errors={errors}
                    currency={workspaceDetailInformation?.default_currency?.code}
                />
                <SelectField
                    control={control}
                    label={translator('PAYMENT_OPTION')}
                    className="col-span-1"
                    placeholder={translator('SELECT_PAYMENT_OPTION')}
                    isRequired
                    name="payment_option"
                    options={PAYMENT_OPTION_LIST(translator)}
                    errors={errors}
                    onChange={(newValue) => {
                        setValue('payment_detail', null);
                        setValue('payment_detail_id', null);
                        setValue('payment_gateway_id', null);
                        if (newValue?.value === 'cash') clearErrors('payment_detail');
                        else setError('payment_detail', { message: translator('FORM_CONST.REQUIRED_FIELD') });
                    }}
                />
                <SelectField
                    control={control}
                    label={translator('PAYMENT_DETAIL')}
                    placeholder={
                        !watch('payment_option') || watch('payment_option')?.value === 'cash'
                            ? '-'
                            : translator('SELECT_PAYMENT_DETAIL')
                    }
                    isDisabled={!watch('payment_option') || watch('payment_option')?.value === 'cash'}
                    className="col-span-1"
                    name="payment_detail"
                    options={
                        watch('payment_option')?.value === 'bank_transfer'
                            ? paymentList?.filter((payment) => payment?.status === 'activated')
                            : watch('payment_option')?.value === 'payment_gateway'
                            ? paymentGetwayOptions
                            : []
                    }
                    isRequired={watch('payment_option') && watch('payment_option')?.value !== 'cash'}
                    noOptionsMessage={() =>
                        watch('payment_option')?.value === 'payment_gateway' 
                            ? <GuidePaymentGetway /> 
                            : watch('payment_option')?.value === 'bank_transfer'
                            ? <GuideAddingBank />
                            : ''
                    }
                    errors={errors}
                />
            </div>

            <div className="mt-5">
                <div className="text-xs  font-semibold">
                    {translator('FREQUENCY')} <span className="text-red-500">*</span>
                </div>
                <div className="text-sm flex gap-5">
                    <div className="flex gap-2 items-center">
                        <InputRadio
                            control={control}
                            inputName="frequency_type"
                            value="monthly"
                            checked={watch('frequency_type') == 'monthly'}
                        />
                        <span>{translator('MONTHLY')}</span>
                    </div>
                    <div className="flex gap-2 items-center">
                        <InputRadio
                            control={control}
                            inputName="frequency_type"
                            value="annually"
                            checked={watch('frequency_type') == 'annually'}
                        />
                        <span>{translator('ANNUALLY')}</span>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-2 gap-5">
                <div className="relative col-span-1 mt-5 due_at">
                    <label htmlFor="start-date" className="text-gray-800 text-xs font-semibold block mb-1">
                        {translator('START_DATE')}
                        <span className="text-red-500"> *</span>
                    </label>
                    <DatePickerInput
                        control={control}
                        name="start_at"
                        placeholderText={translator('SELECT_START_DATE')}
                        classnames={`flex !py-3 max-h-[38px] ${errors['start_at'] ? 'border-red-500' : ''}`}
                        minDate={new Date()}
                        isRequired
                        positionIcon="right"
                        formatDate="dd-MM-yyyy・HH:mm"
                        showTimeSelect={true}
                        filterTimeFunc={(time) => {
                            const now: any = new Date()
                            if (now) {
                                return dayjs(time)?.isAfter(dayjs(now));
                            }
                        }}
                    />
                    {/* @ts-ignore */}
                    <ErrorMessage className="text-red-500 text-xs mt-2 font-semibold" errors={errors} name="start_at" as="div" />
                    {dayjs(watch('start_at'))?.isBefore(dayjs()) && <p className="text-red-500 text-xs mt-2 font-semibold">{translator('VALIDATE_TIME_IN_THE_PAST')}</p>}
                </div>
                <div className="relative col-span-1 pr-2.5 mt-5 due_at">
                    <label htmlFor="start-date" className="text-gray-800 text-xs font-semibold block mb-1">
                        {translator('END_DATE')}
                        <span className="text-red-500"> *</span>
                    </label>
                    <DatePickerInput
                        control={control}
                        name="end_at"
                        placeholderText={translator('SELECT_START_DATE')}
                        classnames={`flex !py-3 max-h-[38px] ${errors['end_at'] ? 'border-red-500' : ''}`}
                        minDate={new Date(`${dayjs(watch('start_at'))}`)}
                        isRequired
                        positionIcon="right"
                        formatDate="dd-MM-yyyy・HH:mm"
                        showTimeSelect={true}
                        filterTimeFunc={(time) => {
                            const startAt: any = watch('start_at');
                            const endAt: any = watch('end_at');
                            if (startAt && endAt) {
                                return dayjs(time)?.isAfter(dayjs(startAt));
                            }
                        }}
                    />
                    {/* @ts-ignore */}
                    <ErrorMessage className="text-red-500 text-xs mt-2 font-semibold" errors={errors} name="end_at" as="div" />
                    {dayjs(watch('start_at'))?.isAfter(dayjs(watch('end_at'))) && <p className="text-red-500 text-xs mt-2 font-semibold">{translator('VALIDATE_END_DATE')}</p>}
                </div>
            </div>

            <div className="mt-5 flex items-center gap-2 relative">
                <InputField
                    type='number'
                    label={translator('DUE_DATE')}
                    isRequired
                    placeholder="1 - 100"
                    className="w-20"
                    name="due_day"
                    register={{...register('due_day', {
                        required: {
                            value: true,
                            message: translator('FORM_CONST.REQUIRED_FIELD'),
                        },
                        pattern: {
                            value: VALIDATE_FIELD.NUMBER_1_100,
                            message: translator('FORM_CONST.REQUIRED_DURATION_RANGE'),
                        },
                    })}}
                    errors={errors}
                />
                <span className="text-sm pt-5 absolute top-2 left-[88px]">{translator('DAY_AFTER_INVOICE_SENT')}</span>
            </div>

            {watch('start_at') && watch('end_at') && watch('due_day') && (
                <div className="py-4 border-t border-gray-300 mt-5">
                    <div className=" text-sm flex items-center gap-1">
                        <ClockIcon />
                        <div>
                            {translator('FIRST_INVOICE_WILL_BE_SENT')}
                            <b className="ml-1">{dayjs(watch('start_at'))?.format('DD/MM/YYYY HH:mm')}</b> 
                            {
                                (
                                    (watch('frequency_type') === 'monthly' && dayjs(watch('start_at')).add(1, 'month').isBefore(dayjs(watch('end_at')))) ||
                                    (watch('frequency_type') === 'annually' && dayjs(watch('start_at')).add(1, 'year').isBefore(dayjs(watch('end_at'))))
                                )
                                && 
                                <>
                                    {' '} {translator('NEXT_INVOICE_RECURRING')}
                                    <b className="ml-1">{dayjs(watch('start_at')).add(1, watch('frequency_type') === 'monthly' ?  'month' : 'year')?.format('DD/MM/YYYY HH:mm')}</b>
                                </>
                            }
                        </div>
                    </div>
                </div>
            )}

            <div className="mt-5 flex gap-5 justify-center">
                <ButtonOutline type="button" onClick={onClose}>
                    {translator('CANCEL')}
                </ButtonOutline>
                <ButtonPrimary disabled={!isDirty || loadingSubmit} type="submit" onClick={() => {}}>
                    {translator('CREATE')}
                </ButtonPrimary>
            </div>
        </form>
    );
}
