import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './../store';
import assignmentService from '../../services/assignment.service';
import courseService from 'services/course.service';
import { ICourse } from 'types/Course.type';
import { ICourseSettings, ISyllabus } from 'types/Courses.type';

interface CourseState {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    data: ICourse;
    error?: any;
    changed: boolean;
    courseSelect?: any;
    hasShowCourseSetting: boolean;
    enrollmentSelected: any;
    setting: ICourseSettings;
    peopleNumber: {
        number_instructor: number;
        number_learner: number;
    };
}
type ValidationErrors = {
    errorMessage: string;
    field_errors: Record<string, string>;
};
const initialState: CourseState = {
    loading: 'idle',
    changed: false,
    courseSelect: null,
    enrollmentSelected: undefined,
    hasShowCourseSetting: false,
    setting: null,
    data: {
        id: null,
        title: null,
        syllabuses: [],
    },
    peopleNumber: {
        number_instructor: 0,
        number_learner: 0,
    },
};

export const courseFetch: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('course/fetch', async (params, { rejectWithValue }) => {
    try {
        const response = await courseService.getCourse(params);
        if (!response) {
            return rejectWithValue('');
        }
        return response;
    } catch (error) {
        return error;
    }
});

export const getCourseSettings: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('course/getCourseSettings', async (params, { rejectWithValue }) => {
    try {
        const response = await courseService.getCourseSettings(params);
        if (!response) {
            return rejectWithValue('');
        }
        return response;
    } catch (error) {
        return error;
    }
});

export const courseCreate: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('course/create', async (params, { rejectWithValue }) => {
    try {
        const response = await courseService.createCourse(params);
        if (!response) {
            return rejectWithValue('');
        }
        return response;
    } catch (error) {
        return error;
    }
});

export const courseUpdate: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('course/update', async (params, { rejectWithValue }) => {
    try {
        const response = await courseService.updateCourse(params);
        if (!response || response?.status === 400) {
            return rejectWithValue('');
        }
        return response;
    } catch (error) {
        return error;
    }
});

export const assignmentCreateContent: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('assignment/createContent', async (params: any, { rejectWithValue }) => {
    try {
        const library_content = params?.library_content;
        delete params?.library_content;
        const response = await assignmentService.createContentAssignment(params);

        if (!response) {
            return rejectWithValue('');
        }
        return response?.data;
    } catch (error) {
        return error;
    }
});

export const assignmentSortContent: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('assignment/sortContent', async (params, { rejectWithValue }) => {
    try {
        const response = await assignmentService.sortContentAssignment(params);
        if (!response) {
            return rejectWithValue('');
        }
        return response;
    } catch (error) {
        return error;
    }
});

export const assignmentRenameContent: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('assignment/renameContent', async (params: any, { rejectWithValue }) => {
    try {
        const response = await assignmentService.renameContentAssignment(params);
        if (!response) {
            return rejectWithValue('');
        }
        return {
            ...response?.data,
            sectionId: params?.sectionId,
        };
    } catch (error) {
        return error;
    }
});

export const updateSetting: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('course/updateSetting', async (params: any, { rejectWithValue }) => {
    try {
        const response = await courseService.updateCourseSetting(params);
        if (!response || response?.data?.error) {
            return rejectWithValue(response?.data?.error);
        }
        return params;
    } catch (error) {
        return error;
    }
});

export const assignmentDeleteContent: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('assignment/deleteContent', async (params: any, { rejectWithValue }) => {
    try {
        const response = await assignmentService.deleteContentAssignment(params);
        if (!response) {
            return rejectWithValue('');
        }
        return { id: params.contentId };
    } catch (error) {
        return error;
    }
});

export const createCourseSyllabus: any = createAsyncThunk<{ rejectValue: ValidationErrors }>(
    'courses/createCourseSyllabus',
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await courseService.createCourseSyllabus(params);
            if (!response) {
                return rejectWithValue('');
            }
            return { id: params.contentId };
        } catch (error) {
            return error;
        }
    }
);

export const updateCourseSyllabusOrder: any = createAsyncThunk<{ rejectValue: ValidationErrors }>(
    'courses/updateCourseSyllabusOrder',
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await courseService.updateCourseSyllabusOrder(params);
            if (!response) {
                return rejectWithValue('');
            }
            return { id: params.contentId };
        } catch (error) {
            return error;
        }
    }
);

export const deleteCourseSyllabus: any = createAsyncThunk<{ rejectValue: ValidationErrors }>(
    'courses/deleteCourseSyllabus',
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await courseService.deleteCourseSyllabus(params);
            if (!response) {
                return rejectWithValue('');
            }
            return { id: params.contentId };
        } catch (error) {
            return error;
        }
    }
);

export const courseSlice = createSlice({
    name: 'course',
    initialState,
    reducers: {
        clearError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        changeShowCourseSetting: (state, action: PayloadAction<boolean>) => {
            state.hasShowCourseSetting = action.payload;
        },
        setEnrollmentSelected: (state, action) => {
            state.enrollmentSelected = action.payload;
        },
        updatePeopleNumber: (state, action: PayloadAction<{ number_instructor: number; number_learner: number }>) => {
            state.peopleNumber = action.payload;
        },
        setCourse: (state, action) => {
            state.data = action.payload;
        },
        setCourseSetting: (state, action) => {
            state.setting = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(courseFetch.fulfilled, (state, { payload }) => {
            state.loading = 'succeeded';
            state.changed = false;
            state.data = payload?.data;
        }),
            builder.addCase(getCourseSettings.fulfilled, (state, { payload }) => {
                state.loading = 'succeeded';
                state.changed = false;
                state.setting = payload?.data;
            }),
            builder.addCase(updateSetting.fulfilled, (state, { payload }) => {
                state.loading = 'succeeded';
                state.changed = false;
                state.setting = payload;
            }),
            builder.addCase(courseUpdate.fulfilled, (state, { payload }) => {
                state.loading = 'succeeded';
                state.changed = true;
                state.data = {
                    ...state.data,
                    ...payload.data,
                };
            }),
            builder.addCase(courseCreate.rejected, (state, action) => {
                if (action.payload) {
                    state.error = action.payload.errorMessage;
                } else {
                    state.error = action.error;
                }
                state.loading = 'failed';
            }),
            builder.addMatcher(isPending, (state, action) => {
                state.loading = 'pending';
            }),
            builder.addMatcher(isRejected, (state, action) => {
                state.loading = 'failed';
            }),
            builder.addMatcher(isFulfilled, (state, action) => {
                state.changed = true;
                state.loading = 'succeeded';
            });
    },
});

export const {
    clearError,
    changeShowCourseSetting,
    setEnrollmentSelected,
    updatePeopleNumber,
    setCourse,
    setCourseSetting,
} = courseSlice.actions;

export const selectCourseSlice = (state: RootState) => state.course;

export default courseSlice.reducer;
