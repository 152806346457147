import { useTranslation } from "react-i18next";


const STATUS_LABEL = {
  active: "Active",
  inactive: "Deactivated",
}

export default function Status({status}) {
    const {t} = useTranslation();
    return (
        <div className="flex gap-1 items-center">
            <div
                className={`capitalize text-xs text-gray-500 bg-gray-50 border rounded px-2 py-0.5 w-fit
            ${status === 'active' ? '!text-green-500 !bg-green-50! border-green-300' : ''}
        `}
            >
                {t(STATUS_LABEL[status]?.toUpperCase())}
            </div>
            
        </div>
    )
}
