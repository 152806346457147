export const SET_CREATE_WORKSPACE = {
    REQ_CREATE_WORKSPACE: 'REQ_CREATE_WORKSPACE',
    REQ_CREATE_WORKSPACE_SUCCESS: 'REQ_CREATE_WORKSPACE_SUCCESS',
    REQ_CREATE_WORKSPACE_FAIL: 'REQ_CREATE_WORKSPACE_FAIL',
};

export const SET_GET_CURRENT_USER_WORKSPACE = {
    REQ_GET_CURRENT_USER_WORKSPACE: 'REQ_GET_CURRENT_USER_WORKSPACE',
    REQ_GET_CURRENT_USER_WORKSPACE_SUCCESS:
        'REQ_GET_CURRENT_USER_WORKSPACE_SUCCESS',
    REQ_GET_CURRENT_USER_WORKSPACE_FAIL: 'REQ_GET_CURRENT_USER_WORKSPACE_FAIL',
};

export const SET_INVITE_MEMBERS = {
    REQ_INVITE_MEMBERS: 'REQ_INVITE_MEMBERS',
    REQ_INVITE_MEMBERS_SUCCESS: 'REQ_INVITE_MEMBERS_SUCCESS',
    REQ_INVITE_MEMBERS_FAIL: 'REQ_INVITE_MEMBERS_FAIL',
};

export const GET_WORKSPACE_DETAIL = {
    REQ_GET_WORKSPACE_DETAIL: 'REQ_GET_WORKSPACE_DETAIL',
    REQ_GET_WORKSPACE_DETAIL_SUCCESS: 'REQ_GET_WORKSPACE_DETAIL_SUCCESS',
    REQ_GET_WORKSPACE_DETAIL_FAIL: 'REQ_GET_WORKSPACE_DETAIL_FAIL',
    RESET_GET_WORKSPACE_FAIL: 'RESET_GET_WORKSPACE_FAIL',
    REQ_GET_WORKSPACE_MEMBERS: 'REQ_GET_WORKSPACE_MEMBERS',
    REQ_GET_WORKSPACE_MEMBERS_SUCCESS: 'REQ_GET_WORKSPACE_MEMBERS_SUCCESS',
    REQ_GET_WORKSPACE_MEMBERS_FAIL: 'REQ_GET_WORKSPACE_MEMBERS_FAIL',
    REQ_SET_WORKSPACE_ID: 'REQ_SET_WORKSPACE_ID',
    REQ_REMOVE_WORKSPACE_ID: 'REQ_REMOVE_WORKSPACE_ID',
    REQ_GET_WORKSPACE_PERMISSIONS: 'REQ_GET_WORKSPACE_PERMISSIONS',
    REQ_SET_WORKSPACE_FEATURE_FLAGS: 'REQ_SET_WORKSPACE_FEATURE_FLAGS',

    REQ_SET_CURRENT_UPLOAD_NAVIGATION: 'REQ_SET_CURRENT_UPLOAD_NAVIGATION',

    REQ_SET_CURRENT_ROUTE_DETAIL: 'REQ_SET_CURRENT_ROUTE_DETAIL',
};

export const WORKSPACE_USER_DETAIL = {
    REQ_SET_USER_ROLE: 'REQ_SET_USER_ROLE',
    REQ_SET_USER_CREATOR: 'REQ_SET_USER_CREATOR',

    REQ_SET_WORKSPACE_DRIVE_ID: 'REQ_SET_WORKSPACE_DRIVE_ID',
};

export const WORKSPACE_LESSON = {
    REQ_GET_LESSON_LIST_LOADING: 'REQ_GET_LESSON_LIST_LOADING',
    REQ_GET_LESSON_LIST_SUCCESS: 'REQ_GET_LESSON_LIST_SUCCESS',
    REQ_GET_LESSON_LIST_FAIL: 'REQ_GET_LESSON_LIST_FAIL',
    REQ_GET_LESSON_LIST_INIT: 'REQ_GET_LESSON_LIST_INIT',

    REQ_GET_LESSON_TAG: 'REQ_GET_LESSON_TAG',
    REQ_GET_LESSON_TAG_FINISH: 'REQ_GET_LESSON_TAG_FINISH',
    REQ_GET_LESSON_TAG_ERROR: 'REQ_GET_LESSON_TAG_ERROR',

    REQ_SET_CURRENT_LESSON_INIT: 'REQ_SET_CURRENT_LESSON_INIT',
    REQ_SET_CURRENT_LESSON: 'REQ_SET_CURRENT_LESSON',
    REQ_SET_CURRENT_LESSON_ERROR: 'REQ_SET_CURRENT_LESSON_ERROR',

    REQ_UPDATE_LESSON_DETAIL: 'REQ_UPDATE_LESSON_DETAIL',
    REQ_UPDATE_LESSON_DETAIL_ERROR: 'REQ_UPDATE_LESSON_DETAIL_ERROR',
    REQ_UPDATE_LESSON_DETAIL_FINISH: 'REQ_UPDATE_LESSON_DETAIL_FINISH',
};

export const WORKSPACE_TAG = {
    REQ_UPDATE_TAG_FINISH: 'REQ_UPDATE_TAG_FINISH',
    REQ_UPDATE_TAG_ADD_FAILED: 'REQ_UPDATE_TAG_ADD_FAILED',
    REQ_UPDATE_TAG_REMOVE_FAILED: 'REQ_UPDATE_TAG_REMOVE_FAILED',
    REQ_UPDATE_TAG_FAILED: 'REQ_UPDATE_TAG_FAILED',
    REQ_TAG_RESET: 'REQ_TAG_RESET',
};

export const WORKSPACE_ADMIN = {
    REQ_GET_ADMIN_LIST: 'REQ_GET_ADMIN_LIST',
    REQ_GET_ADMIN_LIST_FINISH: 'REQ_GET_ADMIN_LIST_FINISH',
    REQ_GET_ADMIN_LIST_ERROR: 'REQ_GET_ADMIN_LIST_ERROR',

    REQ_GET_ADMIN_FILTER: 'REQ_GET_ADMIN_FILTER',

    REQ_ADMIN_APPROVE: 'REQ_ADMIN_APPROVE',
    REQ_ADMIN_APPROVE_ERROR: 'REQ_ADMIN_APPROVE_ERROR',

    REQ_ADMIN_DECLINE: 'REQ_ADMIN_DECLINE',
    REQ_ADMIN_DECLINE_ERROR: 'REQ_ADMIN_DECLINE_ERROR',

    REQ_ADMIN_CHANGE_ORDER: 'REQ_ADMIN_CHANGE_ORDER',
};

export const WORKSPACE_COURSES = {
    REQ_GET_COURSES_LIST: 'REQ_GET_COURSES_LIST',
    REQ_GET_COURSES_LIST_FINISH: 'REQ_GET_COURSES_LIST_FINISH',

    REQ_GET_COURSE_DETAIL: 'REQ_GET_COURSE_DETAIL',
    REQ_GET_COURSES_DETAIL_FINISH: 'REQ_GET_COURSES_DETAIL_FINISH',
    REQ_RESET_COURSES_DETAIL: 'REQ_RESET_COURSES_DETAIL',

    REQ_UPDATE_COURSE_DETAIL: 'REQ_UPDATE_COURSE_DETAIL',
    REQ_UPDATE_COURSE_DETAIL_FINISH: 'REQ_UPDATE_COURSE_DETAIL_FINISH',
    REQ_UPDATE_COURSE_DETAIL_ERROR: 'REQ_UPDATE_COURSE_DETAIL_ERROR',
};

export const WORKSPACE_SETTING = {
    RESET_UPDATE_WORKSPACE: 'RESET_UPDATE_WORKSPACE',
    REQ_UPDATE_WORKSPACE: 'REQ_UPDATE_WORKSPACE',
    REQ_UPDATE_WORKSPACE_FINISH: 'REQ_UPDATE_WORKSPACE_FINISH',
    REQ_UPDATE_WORKSPACE_ERROR: 'REQ_UPDATE_WORKSPACE_ERROR',
};

export const CLASSES_INVITE = {
    REQ_GET_MEMBER: 'REQ_GET_MEMBER',
    REQ_GET_MEMBER_FAILED: 'REQ_GET_MEMBER_FAILED',
    REQ_GET_MEMBER_WORKSPACE: 'REQ_GET_MEMBER_WORKSPACE',
    REQ_GET_MEMBER_WORKSPACE_SUCCESS: 'REQ_GET_MEMBER_WORKSPACE_SUCCESS',
    REQ_GET_MEMBER_WORKSPACE_FAILED: 'REQ_GET_MEMBER_WORKSPACE_FAILED',
    REQ_GET_MEMBER_WORKSPACE_NOT_AVAILABLE:
        'REQ_GET_MEMBER_WORKSPACE_NOT_AVAILABLE',
    REQ_GET_MEMBER_CLASSES_NOT_AVAILABLE:
        'REQ_GET_MEMBER_CLASSES_NOT_AVAILABLE',
    REQ_GET_MEMBER_ClASSES_SUCCESS: 'REQ_GET_MEMBER_ClASSES_SUCCESS',
    REQ_GET_MEMBER_ClASSES_FAILED: 'REQ_GET_MEMBER_ClASSES_FAILED',
    REQ_GET_MEMBER_SUCCESS: 'REQ_GET_MEMBER_SUCCESS',
    REQ_GET_STUDENT: 'REQ_GET_STUDENT',
    REQ_GET_STUDENT_FINISH: 'REQ_GET_STUDENT_FINISH',
    REQ_GET_STUDENT_FAILED: 'REQ_GET_STUDENT_FAILED',
    REQ_GET_TEACHER: 'REQ_GET_TEACHER',
    REQ_GET_TEACHER_FINISH: 'REQ_GET_TEACHER_FINISH',
    REQ_GET_TEACHER_FAILED: 'REQ_GET_TEACHER_FAILED',
    REQ_INVITE_EMAIL: 'REQ_INVITE_EMAIL',
    REQ_INVITE_EMAIL_FINISH: 'REQ_INVITE_EMAIL_FINISH',
    REQ_INVITE_EMAIL_FAILED: 'REQ_INVITE_EMAIL_FAILED',
    REQ_INVITE_EMAIL_FAILED_INVALID: 'REQ_INVITE_EMAIL_FAILED_INVALID',
    REQ_REMOVE_MEMBER: 'REQ_REMOVE_MEMBER',
    REQ_REMOVE_MEMBER_SUCCESS: 'REQ_REMOVE_MEMBER_SUCCESS',
    REQ_REMOVE_MEMBER_FAILED: 'REQ_REMOVE_MEMBER_FAILED',
    REQ_RESET_STATUS_CLASSES: 'REQ_RESET_STATUS_CLASSES',
    REQ_RESET_INVITE_CLASSES: 'REQ_RESET_INVITE_CLASSES',
    REQ_INVITE_TEACHER_AS_ME: 'REQ_INVITE_TEACHER_AS_ME',
};
