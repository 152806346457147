import { RestfulService } from './restful.service';
import { HOST_URL } from 'constant/api.const';

const getInstructors = (payload) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${payload.workspaceId}/courses/${payload.id}`);
};
const createInstructors = (payload) => {
    return RestfulService.postApi(HOST_URL + `/workspaces/${payload.workspaceId}/instructors/${payload.id}/events/create`, payload);
};

const getSummary = (workspaceId, instructorId?: string, params?: any) => {
    let url = `/workspaces/${workspaceId}/instructors/teaching-histories/summary`;
    if (instructorId) {
        url = `/workspaces/${workspaceId}/instructors/${instructorId}/teaching-histories/summary`;
    }
    return RestfulService.getApi(HOST_URL + url, params);
};

const getTeachingHistoryList = (workspaceId, instructorId?: string, payload?: any) => {
    let url = `/workspaces/${workspaceId}/instructors/teaching-histories`;
    if (instructorId) {
        url = `/workspaces/${workspaceId}/instructors/${instructorId}/teaching-histories`;
    }
    return RestfulService.getApi(HOST_URL + url, payload);
};

const getRelationDataOfInstructor = (workspaceId, payload) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${workspaceId}/instructors/relations`, payload);
};

export default {
    getInstructors,
    createInstructors,
    getSummary,
    getTeachingHistoryList,
    getRelationDataOfInstructor
};
