import React, { useState, useEffect } from 'react';
// PACKAGES
import { useForm } from 'react-hook-form';
// TYPES
import { CurrencyWorkspaceType, MemberType } from 'types/CreateWorkspace.type';
// LOGIC
import {
    handleChangeWorkspaceName,
    CreateWorkspaceFormProps,
} from './CreateWorkspaceFormFn';
import { getLocalStorageAuthData } from '../../../utils/handleLocalStorage';
import { useTranslation } from 'react-i18next';
import { classNames } from 'utils/commonFun';
import { ErrorMessage } from './ErrorMessage';
import workspaceService from 'services/workspace.service'
import { Dropdown } from '_shared';

const Logo = require(`assets/themes/${process.env.REACT_APP_WHITELABEL}/logo192.png`);

const svgRender = () => (
    <svg
        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
    >
        <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
        ></circle>
        <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
    </svg>
);

const CreateWorkspaceForm: React.FC<CreateWorkspaceFormProps> = ({
    isLoading,
    status,
    apiErrorMsg,
    memberListMsg,
    setMemberListMsg,
    apiSuccessMsg,
    setApiSuccessMsg,
    onMoveToInviteMember,
    onSelectAvatar,
    workspaceName,
}) => {
    const { t: translator } = useTranslation();
    const [isInvitedMembers, setInvitedMembers] = useState<boolean>();
    const [isCreate, setIsCreate] = useState<boolean>(false);
    const [isNext, setIsNext] = useState<boolean>(false);
    const [listMember, setListMember] = useState<MemberType[]>([]);
    const { display_name } = getLocalStorageAuthData();
    const [avatar, setAvatar] = useState<File>();//currencies
    const [currencyList, setCurrencyList] = useState<CurrencyWorkspaceType[]>([]);

    const {
        watch,
        register,
        formState: { errors },
        reset,
        clearErrors,
        getValues,
        setValue,
        trigger,
        handleSubmit,
    } = useForm();

    useEffect(() => {
        if (workspaceName) {
            setValue('workspaceName', workspaceName);
            setIsNext(true);
        }
        workspaceService.getCurrencyWorkspaceService({ skip_pagination: true })
            .then(res => {
                const list: CurrencyWorkspaceType[] = res?.data?.items?.map(el => ({
                    ...el,
                    name: `${el?.name} (${el?.code?.toUpperCase()}) - ${el?.symbol}`,
                }));
                setValue('currency', list?.find(el => el?.code === 'usd') || null);
                setCurrencyList(list);
            });
    }, []);

    useEffect(() => {
        if (!!watch('workspaceName')) {
            if (watch('workspaceName')?.trim().length) {
                setIsNext(true);
            }
        } else {
            setIsNext(false);
        }
    }, [watch('workspaceName')]);

    useEffect(() => {
        if (avatar) {
            onSelectAvatar(avatar);
        }
    }, [avatar]);

    useEffect(() => {
        clearErrors('workspaceName');
        reset();
        setListMember([]);
    }, [isCreate]);

    useEffect(() => {
        if (!isLoading && status === 'done') {
            setIsCreate(false);
        }
    }, [isLoading, status]);

    useEffect(() => {
        if (isInvitedMembers) {
            setApiSuccessMsg(undefined);
            if (listMember.length > 0) {
                setMemberListMsg(undefined);
            } else {
                setMemberListMsg('email invitation is empty!');
            }
        }
    }, [listMember, isInvitedMembers]);

    const onSubmitForm = (values: { workspaceName: string; currency: CurrencyWorkspaceType }) => {
        if (values?.workspaceName?.length) {
            onMoveToInviteMember(values.workspaceName, values?.currency);
        }
    };

    return (
        <div className="flex flex-col animate-ooolab_fade_in">
            <img className='mx-auto' src={Logo} alt="logo" />
            <label className="text-2xl lg:text-3xl font-extrabold text-gray-700 text-center">
                {translator('AUTHENTICATION.WORKSPACE.HI_DISPLAY_NAME', {
                    display_name: display_name,
                })}
            </label>
            <label className="mt-2 lg:mt-3 text-2xl lg:text-3xl font-extrabold text-gray-700 text-center">
                {translator(
                    'AUTHENTICATION.WORKSPACE.LETS_SETUP_YOUR_WORKSPACE'
                )}
            </label>
            <form
                onSubmit={handleSubmit(onSubmitForm)}
                className="w-full mt-7 flex flex-col justify-center items-center"
                autoComplete="off"
            >
                <div className='w-full'>
                    <label className="mb-2 text-sm font-normal text-ooolab_282B5E text-left block">
                        {translator('AUTHENTICATION.WORKSPACE.FIRST_THINGS_CHOOSE_WORKSPACE')}
                    </label>
                    <div className="flex flex-row items-center w-full">
                        <div className="flex flex-col w-full relative">
                            <span className=' absolute right-2 top-1/2 -translate-y-1/2 text-dark-100 text-sm'>
                                {!watch('workspaceName')?.length ? 50 : `${50 - +(watch('workspaceName')?.length)}`}
                            </span>
                            <input
                                id="workspaceName"
                                name="workspaceName"
                                type="text"
                                maxLength={50}
                                className={classNames(
                                    'appearance-none block w-full p-3 py-[13px] border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm',
                                    errors.workspaceName && 'border-red-600'
                                )}
                                placeholder={translator(
                                    'AUTHENTICATION.WORKSPACE.WORKSPACE_NAME'
                                )}
                                {...register('workspaceName', {
                                    required: true
                                })}
                                defaultValue={workspaceName}
                                onChange={handleChangeWorkspaceName(
                                    setValue,
                                    trigger
                                )}
                            />
                            {errors?.workspaceName?.type === 'required' && (
                                <ErrorMessage
                                    label={translator('FORM_CONST.REQUIRED_FIELD')}
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div className='w-full mt-8'>
                    <label className="mb-2 text-sm font-normal text-ooolab_282B5E text-left block">
                        {translator('AUTHENTICATION.WORKSPACE.SELECT_THE_CURRENCY_FOR_YOUR_WORKSPACE')}
                    </label>
                    <Dropdown
                        items={currencyList}
                        onChange={(value: any) => {
                            setValue('currency', value);
                        }}
                        placeholder={''}
                        className="w-full dropdown-custom-createWorkspace"
                        selectedValue={watch('currency')}
                        isDisplayFullKey
                    />
                </div>

                {(memberListMsg || apiErrorMsg) && (
                    <span className="text-red-500 pl-ooolab_p_7 block">
                        {memberListMsg || apiErrorMsg}
                    </span>
                )}
                {apiSuccessMsg && (
                    <span className="text-green-500 pl-ooolab_p_7 block">
                        {apiSuccessMsg}
                    </span>
                )}
                <button
                    type="submit"
                    disabled={!isNext}
                    className={classNames(
                        'w-full mt-8 flex justify-center py-[13px] px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white',
                        'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                        isNext
                            ? 'bg-ooola_3E7EFF hover:bg-indigo-700'
                            : 'bg-ooola_EFF0FA text-ooolab_8083A3 cursor-not-allowed'
                    )}
                >
                    {isLoading ? svgRender() : null}
                    {translator('AUTHENTICATION.WORKSPACE.CREATE_WORKSPACE')}
                </button>
            </form>
        </div>
    );
};

export default CreateWorkspaceForm;
