import React, { useEffect, useState } from 'react';
import './AssignmentSubmission.scss';
import { DeleteIcon } from 'assets/icon';
import InformationCircleIcon from '@heroicons/react/solid/InformationCircleIcon';
import { CloseIcon } from 'assets/icon/CloseIcon';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputField } from 'components/InputForm/InputForm';
import FileUpload from 'assets/img/fileUpload.png';
import assignmentService from 'services/assignment.service';
import { useParams } from 'react-router-dom';
import useDebounceEffect from 'hooks/useDebounce';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { assignmentCreateContent, changeLibraryTypeAdd, changeSelectContent, updateSection } from 'store/features/assignmentSlice';
import { AssignmentDelete } from '../AssignmentDelete';
import { LibraryTypeEnum } from 'components/Library/LibraryContants';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { checkPermission } from 'utils/commonFun';

const configCkEditor = {
    toolbar: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        'blockQuote',
        '|',
        'undo',
        'redo',
    ],
    link: {
        addTargetToExternalLinks: true,
    },
    placeholder: "Enter Submission Instruction here",
};

export default function AssignmentSubmission({ data, isNewPage = false }) {
    const {
        control,
        formState: { errors },
        watch,
        register,
        reset,
        getValues,
        trigger,
        setValue,
        handleSubmit
    } = useForm({ mode: 'onChange' });
    const [instructionLength, setInstructionLength] = useState(0)
    const { t: translator } = useTranslation();
    const [isShowInformation, setShowInformation] = useState(true);
    const params: any = useParams();
    const { data: assignment, assignmentSelect } = useAppSelector((state) => state.assignment);
    const [currentSubmission, setCurrentSubmission] = useState<any>();
    const dispatch = useAppDispatch();
    const [isOpenmodalDelete, setOpenModalDelete] = useState(false);

    useEffect(() => {
        if (!data) {
            assignmentService
                .createSubmission({ workspaceId: params?.id, title: 'Untitled submission', instruction: '' })
                .then((res) => {
                    const submission = {
                        title: res.data?.title,
                        instruction: res.data?.instruction,
                    }
                    reset(submission);
                    setCurrentSubmission(submission)
                    dispatch(
                        assignmentCreateContent({
                            name: res?.data?.title,
                            workspaceId: params?.id,
                            assignmentId: params?.assignmentId,
                            object_id: res?.data?.id,
                            object_type: LibraryTypeEnum.submission,
                            instruction: res?.data?.instruction,
                            order_number: assignment?.sections?.length > 0 ? assignment?.sections?.length + 1 : 0,
                        })
                    ).then(res => {
                        dispatch(changeSelectContent(res?.payload));
                        dispatch(changeLibraryTypeAdd(null))
                    })
                });
        } else {
            assignmentService?.getSubmisstion(params?.id, data?.library_content?.object_id).then((res) => {
                const submission = {
                    title: res?.data?.title,
                    instruction: res?.data?.instruction,
                };
                reset(submission);
                setCurrentSubmission(submission);
            });
        }
    }, [data]);

    const onUpdateSubmission = () => {
        const values = getValues();
        if (instructionLength > 500) return;
        if (!currentSubmission) return;
        if (currentSubmission?.title === values?.title && currentSubmission.instruction === values?.instruction) return;
        assignmentService
            .updateSubmission(params?.id, assignmentSelect?.library_content?.object_id, {
                title: values.title,
                instruction: values.instruction,
            })
            .then((res) => {
                let submission = {
                    id: assignmentSelect?.id,
                    order_number: assignment?.sections?.length > 0 ? assignment?.sections?.length + 1 : 0,
                    library_content: {
                        ...assignmentSelect?.library_content,
                        name: values.title,
                        instruction: values?.instructions,
                    },
                };
                setCurrentSubmission({
                    title: values?.title,
                    instruction: values?.instruction,
                });
                const newSections = (assignment?.sections || [])?.map((section) => {
                    if (section?.library_content?.object_id === submission?.library_content?.object_id) return submission;
                    return section;
                });
                dispatch(updateSection(newSections));
            });
    };

    useDebounceEffect(handleSubmit(onUpdateSubmission), [watch('title'), watch('instruction'), assignment, currentSubmission], 800);

    const removeTags = (str) => {
        if ((str === null) || (str === ''))
            return;
        else
            str = str?.toString();
        return str?.replace(/(<([^>]+)>)/ig, '');
    }

    useEffect(() => {
        const instruction = removeTags(watch('instruction') || '')?.length;
        setInstructionLength(instruction)
    }, [watch('instruction')])

    return (
        <div className="AssignmentSubmission">
            {!isNewPage && (
                <div className="AssignmentSubmission-title">
                    <div className='text-ellipsis truncate max-w-[calc(100%-60px)]'>{watch('title')}</div>
                    {checkPermission('libraryContentAssignment', 'U') ? <div className="btnDelete" onClick={() => setOpenModalDelete(true)}>
                        <DeleteIcon width="20" height="20" />
                    </div> : ''}

                </div>
            )}
            <div className="AssignmentSubmission-information">
                {isShowInformation && !isNewPage && (
                    <div className="wrapper-content">
                        <InformationCircleIcon className="icon" />
                        <div className="text">{translator('ASSIGNMENT.SUBMISSION_INFO')}</div>
                        <div
                            onClick={() => setShowInformation(false)}
                            className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"
                        >
                            <CloseIcon />
                        </div>
                    </div>
                )}

                {!isNewPage && (
                    <form className="">
                        <InputField
                            label={translator('ASSIGNMENT.SUBMISSION_TITLE')}
                            placeholder={translator('ASSIGNMENT.ENTER_SUBMISSION_TITLE')}
                            name="title"
                            errors={errors}
                            isRequired
                            register={register('title', {
                                required: 'This field is required!',
                                maxLength: {
                                    value: 255,
                                    message: translator('FORM_CONST.MAX_LENGTH_TEXT', { maxLength: 255 }),
                                },
                            })}
                            disabled={!checkPermission('libraryContentAssignment', 'U')}
                        />
                        <div className="mt-4">
                            <label className="text-gray-800 text-xs font-semibold flex items-center gap-0.5 mb-1">
                                <span>{translator('ASSIGNMENT.SUBMISSION_INSTRUCTOR')}</span>
                            </label>
                            {/*@ts-ignore*/}
                            <Controller
                                name="instruction"
                                control={control}
                                {...register('instruction')}
                                render={() => (
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={watch('instruction')}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setValue('instruction', data);
                                        }}
                                        config={configCkEditor}
                                        disabled={!checkPermission('libraryContentAssignment', 'U')}
                                    />
                                )}
                            />
                            <div className='flex justify-between mt-1'>
                                <div>
                                    {instructionLength >= 500 && <div className='text-xs text-red-500'>{translator('FORM_CONST.MAX_LENGTH_TEXT', { maxLength: 500 })}</div>}
                                </div>
                                <div className='text-xs text-right text-gray-500'>{instructionLength || 0}/500</div>
                            </div>
                        </div>
                    </form>
                )}

                <div className="preview">
                    <div className=" w-full">
                        {!isNewPage && <div className="label">{translator('ASSIGNMENT.LEARNER_PREVIEW')}</div>}
                        <div className="wrapper-preview">
                            <div className="flex justify-between">
                                <div className="title">{watch('title')}</div>
                            </div>
                            <div className="mt-4 ">
                                {!!watch('instruction') && (
                                    <p className="note" dangerouslySetInnerHTML={{ __html: watch('instruction') }} />
                                )}
                            </div>
                            <div className="uploadFile mt-4">
                                <img src={FileUpload} />
                                <div>
                                    <span className="text-upload">{translator('ASSIGNMENT.CLICK_TO_UPLOAD')}</span>
                                    <span className="text-drag-drop"> {translator('ASSIGNMENT.DRAG_AND_DROP')}</span>
                                </div>
                                <div className="limitFileType">
                                    {translator('ASSIGNMENT.LIMIT_FILE_TYPE')} <br /> {translator('ASSIGNMENT.MAXIMUM_FILE_SIZE')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AssignmentDelete isOpen={isOpenmodalDelete} onClose={() => setOpenModalDelete(false)} values={assignmentSelect} />
        </div>
    );
}
