import React from 'react';
import ManagementMasterPage from '../../../RouteMasterPage';
import { CertificateDetail } from 'components/Management/CertificateManagement/CertificateDetail';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';

const CertificateDetailPage = () => {
    return (
        <ManagePermissionWrapper>
            <CertificateDetail>
                <ManagementMasterPage />
            </CertificateDetail>
        </ManagePermissionWrapper>
    );
};

export default CertificateDetailPage;
