
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import  './Tab.scss'
import { useEffect } from 'react';
import { TeacherDashboardService } from 'services';

export enum TAB {
    ACADEMICS = 'ACADEMICS',
    NEWS = 'NEWS'
}

export default function Tab() {
    const search = useLocation()?.search;
    const tab = new URLSearchParams(search).get('tab') || 'ACADEMICS'
    const params: { id: string } = useParams();
    const history = useHistory();
    const workspaceId = params?.id;
    const {t: translator} = useTranslation();
    
    useEffect(() => {
        TeacherDashboardService.readAllNotification(params?.id);
    }, [tab])

    return (
        <div className="Tab">
            <div
                className={`TabItem ${tab === TAB.ACADEMICS ? 'active' : ''}`}
                onClick={() => history?.push(`/workspace/${workspaceId}/instructor-dashboard/notifications?tab=${TAB.ACADEMICS}`)}
            >
                {translator(`INSTRUCTOR_PORTAL.${TAB.ACADEMICS}`)}
            </div>
            <div
                className={`TabItem ${tab === TAB.NEWS ? 'active' : ''}`}
                onClick={() => history?.push(`/workspace/${workspaceId}/instructor-dashboard/notifications?tab=${TAB.NEWS}`)}
            >
                {translator(`INSTRUCTOR_PORTAL.${TAB.NEWS}`)}
            </div>
        </div>
    );
}
