import React from 'react';
import RouteMasterPage from 'pages/RouteMasterPage';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';
import { TeachingHistoryDashboard } from 'components/TeachingHistory';

const TeachingHistoryPage = () => {
    return (
        <ManagePermissionWrapper>
            <TeachingHistoryDashboard>
                <RouteMasterPage  />
            </TeachingHistoryDashboard>
        </ManagePermissionWrapper>

    );
};

export default TeachingHistoryPage;
