import { components } from "react-select";
import avatarImg from 'assets/avatar_option.png';

export const MultiValue = (props) => {
  const content = props.children?.substring(0, 12);
  return (
      <components.MultiValue {...props}>
          <div className="flex items-center text-[10px] text-dark-300 font-semibold">
              <img className={`rounded-full w-5 h-5 mr-2`} src={props?.data?.user?.avatar_url || props?.data?.avatar_url || avatarImg} alt="img" />
              {content}...
          </div>
      </components.MultiValue>
  );
};

export const ValueContainer = (props) => {
  const { hasValue, children } = props;
  const [chips, otherChildren] = children;
  if (!hasValue) {
      return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
  }

  const CHIPS_LIMIT = 3;
  const overflowCounter = chips.slice(CHIPS_LIMIT).length;
  const displayChips = chips.slice(0, CHIPS_LIMIT);

  return (
      <components.ValueContainer {...props}>
          {displayChips}

          <div
              className={`text-[10px] text-dark-300 font-semibold ml-1 bg-[#E6E6E6] h-[26px] px-1 rounded flex items-center justify-center ${
                  overflowCounter === 0 ? 'hidden ' : ''
              }`}
          >
              {' '}
              {overflowCounter > 0 && `+ ${overflowCounter}`}
          </div>
          {otherChildren}
      </components.ValueContainer>
  );
};

export const customSelect: any = {
  clearIndicator: (base) => ({
      ...base,
      padding: 0,
      marginRight: -6,
  }),
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  control: (base) => {
      return {
          ...base,
          borderColor: '#EBEBF0',
          padding: 0,
          paddingLeft: 8,
          minHeight: 34,
          color: '#2E3A59',
      };
  },
  valueContainer: (base) => ({
      ...base,
      padding: 0,
      fontSize: 13,
  }),
  dropdownIndicator: (base, { selectProps }) => ({
      ...base,
      padding: 6,
      transition: 'all 0.3s ease-in-out',
      transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  }),
  input: (base) => ({
      ...base,
      padding: 0,
      margin: 0,
      fontSize: 13,
  }),
  option: (base) => ({
      ...base,
      paddingLeft: '15px',
      fontSize: 13,
  }),
};


export const STATUS = {
    enroll: {
        label: "Enrolled",
        background: "bg-green-500"
    },
    invite: {
        label: "Active",
        background: "bg-green-500"
    },
    deactivate: {
        label: "Deactivated",
        background: "bg-orange-500"
    },
    expire: {
        label: "Expired",
        background: "bg-gray-500"
    }
}

export const CLASS_TYPE = {
    MANUAL: "manual",
    AUTO: "auto"
}