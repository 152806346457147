import { AUTH_CONST } from 'constant/auth.const';
import { AuthContext } from 'contexts/Auth/AuthContext';
import * as React from 'react';
// PACKAGE
import { Route, useHistory } from 'react-router-dom';
// UTILS
import { getParamFromUrl } from 'utils/handleString';
import queryString from 'query-string';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
const NormalRoute: React.FC<any> = ({
    path,
    component: Component,
    setAuthStorage,
    documentTitle='',
    ...rest
}) => {
    useDocumentTitle(documentTitle);
    const { authState, dispatch: AuthDispatch } = React.useContext(AuthContext);
    const { err } = authState;
    const history = useHistory();
    const params = queryString.parse(history.location.search);

    return (
        <Route
            path={path}
            exact
            {...rest} // PROPS ITEM inside { ...rest } need inject to Component below
            render={(props) => {
                return (
                    <Component
                        {...props}
                        setAuthStorage={setAuthStorage}
                        emailParam={params.email}
                        tokenParam={params.token}
                        otpParam={params.code}
                    />
                ); // Reminder: { ...rest } initial above, but have to pass PROPS ITEM inside Component below
            }}
        />
    );
};

export default NormalRoute;
