export const MoveIcon = ({ fill = '#374151', width = 18, height = 18 }: { fill?: string; width?: number; height?: number }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1350_19542)">
                <path
                    d="M12.4141 5H21.0001C21.2653 5 21.5197 5.10536 21.7072 5.29289C21.8947 5.48043 22.0001 5.73478 22.0001 6V20C22.0001 20.2652 21.8947 20.5196 21.7072 20.7071C21.5197 20.8946 21.2653 21 21.0001 21H3.0001C2.73488 21 2.48053 20.8946 2.29299 20.7071C2.10546 20.5196 2.0001 20.2652 2.0001 20V4C2.0001 3.73478 2.10546 3.48043 2.29299 3.29289C2.48053 3.10536 2.73488 3 3.0001 3H10.4141L12.4141 5ZM4.0001 5V19H20.0001V7H11.5861L9.5861 5H4.0001ZM12.0001 12V9L16.0001 13L12.0001 17V14H8.0001V12H12.0001Z"
                    fill={fill}
                />
            </g>
            <defs>
                <clipPath id="clip0_1350_19542">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
