import React, { FC, useState } from 'react';
import { checkPermission, classNames } from 'utils/commonFun';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import TabProfile from './TabProfile';
import TabStudent from './TabStudent';
import TabHistoryCredit from './TabHistoryCredit';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';

const tabList = ['PROFILE', 'LEARNER'];

const index: FC = () => {
    const params: any = useParams();
    const { t } = useTranslation();
    const [data, setData] = useState<any>();
    const [tabSelected, setTabSelected] = useState(0);

    const companyId = params?.companyId;

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`COMPANY.COMPANY_DETAIL.${str}`, opt));

    return (
        <>
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT', true)}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    {
                        name: translator('DASHBOARD.SIDEBAR.COMPANIES', true),
                        href: `/workspace/${params.id}/management/companies`,
                        className: '',
                    },
                    { name: data?.name, href: '', className: '' },
                ]}
            />
            <Tab.Group onChange={(tab: number) => setTabSelected(tab)} selectedIndex={tabSelected}>
                <Tab.List className="p-5 border-b border-light-500 text-ooolab_dark_2 flex items-center justify-between">
                    <div className="flex items-center">
                        {checkPermission('companyDetails', 'R') && tabList.map((tab, index) => (
                            <Tab
                                key={index}
                                className={({ selected }) =>
                                    classNames(
                                        'px-16 py-3 outline-none text-xs font-semibold',
                                        selected ? 'text-ooolab_dark_1 bg-ooolab_gray_11' : 'text-ooolab_dark_2 bg-white'
                                    )
                                }
                            >
                                {translator(tab)}
                            </Tab>
                        ))}
                    </div>
                    <div className="text-xs font-semibold flex items-center space-x-2">
                        <p className="text-ooolab_dark_2">{translator('COMPANY_ID')}</p>
                        {!!companyId && <p className="text-ooolab_dark_1 px-2 py-1 bg-ooolab_gray_11">{companyId}</p>}
                    </div>
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>
                        {checkPermission('companyDetailsProfile', 'R') && <TabProfile setData={setData} />}
                    </Tab.Panel>
                    <Tab.Panel>
                        {checkPermission('companyDetailsLearner', 'R') && <TabStudent data={data} />}
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </>
    );
};

export default index;
