import { useContext, useState } from 'react'
import ModalSessionDetail from './ModalSessionDetail'
import dayjs from 'dayjs'
import { ExternalLinkIcon } from '@heroicons/react/outline'
import { ButtonOutline } from '_shared'
import { useTranslation } from 'react-i18next'
import "./ModalSessionDetail.scss";
import { UserContext } from 'contexts/User/UserContext'

export default function Session({session, refresh}) {
  const [isOpenModalSessionDetail, setOpenModalSessionDetail] = useState(false);
  const {t: translator} = useTranslation();
  const {
    userState: { result },
} = useContext(UserContext);
  return (
    <div className='Session'>
      <ModalSessionDetail isOpen={isOpenModalSessionDetail} onClose={() => setOpenModalSessionDetail(false)} sessions={[session]} refresh={refresh} />
      <div className='Session-title group' >
          <div className='cover'>
              ID <span>{session?.id}</span> 
              {" • "}
              <div className="date-time">
                  {dayjs(session?.start_time).tz(result.time_zone)?.format("HH:mm") +
                  " - " +
                  dayjs(session?.end_time)?.tz(result.time_zone)?.format("HH:mm")}
              </div>
              {session?.lesson && " • "}
              {session?.lesson && <div className='lesson'>
                <span className='lesson-label'>{translator('LESSON_')}</span> 
                <span className='lesson-title'>{session?.lesson?.title || '-'}</span>
              </div>}
          </div>
          <ButtonOutline size="-isPrimary" className='!hidden group-hover:!flex !py-1 h-fit !px-2 gap-1 items-center' type="button" onClick={() => setOpenModalSessionDetail(true)} >{translator("VIEW_DETAILS")} <ExternalLinkIcon width={16} height={16} /> </ButtonOutline>
      </div>
    </div>
  )
}
