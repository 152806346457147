import React from 'react';
import ManagementMasterPage from 'pages/RouteMasterPage';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';
import GradeBook from 'components/Workspace/Course/CourseBuilder/components/CourseGradebook/GradebookDetails/StudyHistory/Grade/Grade';

const Grade = () => {
    return (
        <ManagePermissionWrapper>
            <GradeBook>
                <ManagementMasterPage />
            </GradeBook>
        </ManagePermissionWrapper>
    );
};

export default Grade;
