import React, { useContext, useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import SelectCardExtend from './SelectCardExtend';
import Tooltip from 'components/Tooltip';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'contexts/User/UserContext';
import dayjs from 'dayjs';
import { HeartIcon } from '@heroicons/react/solid';
import ModalInfoInstructor from './ModalInfoInstructor';
import { ENROLLMENT_TYPE } from '../BookingForm/utils';

type SelfBookingSelectCardProps = {
    data: any;
    onlyView: boolean;
    handleSelect?: any;
    defaultChecked?: any;
    isConfirm?: boolean;
    learningPath?: any;
    learningPathType?: string;
    isReschedule?: boolean;
    selected?: any;
    studentId?: number;
    enrollGroupName?: string;
    disable?: boolean;
};

const SelfBookingSelectCard: React.FC<SelfBookingSelectCardProps> = ({
    data,
    handleSelect,
    defaultChecked,
    onlyView,
    learningPath,
    learningPathType,
    isReschedule,
    selected,
    studentId,
    disable,
}: SelfBookingSelectCardProps) => {
    const { i18n } = useTranslation();
    const [isExtend, setIsExtend] = useState<boolean>(false);
    const toggleExtend = () => {
        setIsExtend(!isExtend);
    };

    const [openModalInstructor, setOpenModalInstructor] = useState(false);
    const [instructorSelected, setInstructorSelected] = useState<any>();
    const {
        userState: { result: userInformation },
    } = useContext(UserContext);

    const [lessonSelected, setLessonSelected] = useState<any>();
    const [note, setNote] = useState<any>();

    useEffect(() => {
        if (isReschedule && selected?.length === 1 && !defaultChecked) return;
        if (lessonSelected || note) {
            handleSelect?.({ ...data, lesson_id: lessonSelected?.value, lesson_name: lessonSelected?.label, note }, true);
        }
    }, [lessonSelected, note]);

    const checkPerfer = () => {
        const listPrefered = data?.instructors?.[0]?.instructor?.prefer_users;
        if (listPrefered?.includes(studentId)) return true;
        return false;
    };

    return (
        <>
            <div
                className={`self-booking-select-card rounded-2xl flex items-center ${
                    defaultChecked || onlyView
                        ? onlyView || lessonSelected
                            ? 'bg-primary-10  justify-center border border-primary-500'
                            : 'bg-primary-10  justify-center border border-red-500'
                        : 'bg-white border border-gray-300 justify-between'
                } ${
                    data?.isOverlap || disable
                        ? 'opacity-30 pointer-events-none cursor-not-allowed'
                        : 'opacity-100 cursor-pointer pointer-events-auto'
                }`}
                onClick={() => handleSelect({ ...data, lesson_id: lessonSelected?.value, note })}
            >
                <div className={`pl-6 w-1/12 ${onlyView ? 'hidden' : ''}`}>
                    <input
                        type={'checkbox'}
                        checked={!!defaultChecked}
                        onChange={() => handleSelect({ ...data, lesson_id: lessonSelected?.value, note })}
                    />
                </div>
                <div className={`${onlyView ? 'w-9/12' : 'w-8/12'} py-2  text-center   flex items-center `}>
                    <div className="min-w-ooolab_h_40 text-sm pb-2x text-left pr-1">
                        {dayjs
                            ?.utc(data?.start_time)
                            ?.tz(userInformation?.time_zone)
                            .locale(i18n?.language)
                            ?.format('ddd, MMM DD, YYYY') + ' • '}
                        {dayjs.utc(data?.start_time)?.tz(userInformation?.time_zone).format('HH:mm') + ' - '}
                        {isReschedule && onlyView
                            ? dayjs.utc(data?.end_time)?.tz(userInformation?.time_zone).format('HH:mm')
                            : dayjs
                                  .utc(data?.start_time)
                                  ?.tz(userInformation?.time_zone)
                                  .add(data?.duration, 'minutes')
                                  ?.format('HH:mm')}
                    </div>
                    <div className=" flex items-center justify-start py-2 gap-1 ml-8">
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                setInstructorSelected(data.instructor?.id || data?.instructors?.[0]?.id);
                                setOpenModalInstructor(true);
                            }}
                            className={`flex items-center justify-center bg-gray-100 rounded-full ${
                                data.instructor?.preferred || checkPerfer() ? 'border border-red-500' : ''
                            } relative`}
                        >
                            {(data?.instructor?.preferred || checkPerfer()) && (
                                <HeartIcon className="w-4 h-4 absolute -right-1 -bottom-1 text-red-500" />
                            )}
                            <img
                                src={data?.instructor?.avatar_url || data?.instructors?.[0]?.avatar_url}
                                alt="avatar"
                                className="rounded-full w-5 h-5"
                            />
                        </div>
                        <div className='text-xs'>{data?.instructor?.display_name || data?.instructors?.[0]?.display_name}</div>
                    </div>
                    {onlyView ? (
                        <span className="text-[13px] text-center pl-8 truncate whitespace-nowrap max-w-[150px]">
                            {data?.lesson_name}
                        </span>
                    ) : (
                        <span></span>
                    )}
                </div>
                <div className=" text-orange-500 w-2/12 text-right">
                    {learningPath?.enrollment_type === ENROLLMENT_TYPE.CREDITS && '1 Credit'}
                </div>
                {!onlyView && (
                    <div
                        className="pr-6  w-1/12 cursor-pointer flex justify-end"
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleExtend();
                        }}
                    >
                        {isExtend ? <ChevronUpIcon className="w-4 h-4" /> : <ChevronDownIcon className="w-4 h-4" />}
                    </div>
                )}
            </div>
            {!onlyView && (
                <div className={`selfTooltip-booking-select-card-extend mt-2`}>
                    {isExtend ? (
                        <SelectCardExtend
                            data={data}
                            learningPathId={learningPath?.id}
                            lessonSelected={lessonSelected}
                            setLessonSelected={setLessonSelected}
                            setNote={setNote}
                            note={note}
                            learningPathType={learningPathType}
                            handleSelect={handleSelect}
                            studentId={studentId}
                        />
                    ) : null}
                </div>
            )}

            {openModalInstructor && (
                <ModalInfoInstructor
                    isOpen={openModalInstructor}
                    instructorId={instructorSelected}
                    onClose={() => setOpenModalInstructor(false)}
                />
            )}
        </>
    );
};

export default SelfBookingSelectCard;
