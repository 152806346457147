import { AssignmentGrade } from 'components/GradeSubmission/AssignmentGrade/AssignmentGrade';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';
import React from 'react';

const AssignmentGradePage: React.FC<{ setAuthStorage: React.Dispatch<React.SetStateAction<boolean>> }> = ({
    setAuthStorage
}) => (
    <ManagePermissionWrapper>
        <AssignmentGrade />
    </ManagePermissionWrapper>
);

export default AssignmentGradePage;
