import React from "react";
import { ArrowRightSIcon } from "assets/icon";
import './AssigmentBuilderSidebar.scss';

export const AssigmentBuilderSidebarHeader = ({ isOpen, onClick = null, children }) => {
    return (
        <div onClick={onClick}
            className={`assigmentBuilderSidebar-header ${isOpen ? 'assigmentBuilderSidebar-header--active' : ''}`}>
            <span className="assigmentBuilderSidebar-arrow"><ArrowRightSIcon /></span>
            {children}
        </div>
    )
}

export const AssigmentBuilderSidebarContent = ({ children }) => {
    return <div className="assigmentBuilderSidebar-content">{children}</div>;
}