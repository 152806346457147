import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './../store';
import assignmentService from '../../services/assignment.service';
import { VIEW_TYPE } from 'components/Library/LessonBuilder/constants';

interface AssignmentState {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    data?: any;
    error?: any;
    changed: boolean;
    libraryTypeAdd: string;
    viewType: string;
    assignmentSelect?: any;
    isNew?: boolean;
    hasLoadData?: boolean
}
type ValidationErrors = {
    errorMessage: string;
    field_errors: Record<string, string>;
};
const initialState: AssignmentState = {
    loading: 'idle',
    changed: false,
    libraryTypeAdd: null,
    viewType: VIEW_TYPE.WEB,
    assignmentSelect: null,
    isNew: false,
    hasLoadData: false
};

export const assignmentUpdate: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('assignment/update', async (params, { rejectWithValue }) => {
    try {
        const response = await assignmentService.updateAssignment(params);
        if (!response || response?.status === 400) {
            return rejectWithValue('');
        }
        return response;
    } catch (error) {
        return error;
    }
});

export const assignmentCreate: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('assignment/create', async (params, { rejectWithValue }) => {
    try {
        const response = await assignmentService.createAssignment(params);
        if (!response) {
            return rejectWithValue('');
        }
        return response;
    } catch (error) {
        return error;
    }
});

export const assignmentCreateContent: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('assignment/createContent', async (params: any, { rejectWithValue }) => {
    try {
        const library_content = params?.library_content;
        delete params?.library_content;
        const response = await assignmentService.createContentAssignment(params);

        if (!response) {
            return rejectWithValue('');
        }
        return response?.data;
    } catch (error) {
        return error;
    }
});

export const assignmentSortContent: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('assignment/sortContent', async (params, { rejectWithValue }) => {
    try {
        const response = await assignmentService.sortContentAssignment(params);
        if (!response) {
            return rejectWithValue('');
        }
        return response;
    } catch (error) {
        return error;
    }
});

export const assignmentRenameContent: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('assignment/renameContent', async (params: any, { rejectWithValue }) => {
    try {
        const response = await assignmentService.renameContentAssignment(params);
        if (!response) {
            return rejectWithValue('');
        }
        return {
            ...response?.data,
            sectionId: params?.sectionId,
            object_id: params?.contentId,
            contentId: params?.contentId,
            name: response?.data?.name || response?.data?.title
        };
    } catch (error) {
        return error;
    }
});

export const assignmentDeleteContent: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('assignment/deleteContent', async (params: any, { rejectWithValue }) => {
    try {
        const response = await assignmentService.deleteContentAssignment(params);
        if (!response) {
            return rejectWithValue('');
        }
        return { id: params.contentId };
    } catch (error) {
        return error;
    }
});

export const assignmentFetch: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('assignment/fetch', async (params, { rejectWithValue }) => {
    try {
        const response = await assignmentService.getAssignment(params);
        if (!response) {
            return rejectWithValue('');
        }
        return response;
    } catch (error) {
        return error;
    }
});

export const assignmentFetchAssociatedLessons: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('assignment/fetchAssociatedLessons', async (params, { rejectWithValue }) => {
    try {
        const response = await assignmentService.getAssociatedLessonAssignment(params);
        if (!response) {
            return rejectWithValue('');
        }
        return response;
    } catch (error) {
        return error;
    }
});

export const assignmentSlice = createSlice({
    name: 'assignment',
    initialState,
    reducers: {
        changeLibraryTypeAdd: (state, action: PayloadAction<string>) => {
            state.libraryTypeAdd = action.payload;
        },
        changeViewType: (state, action: PayloadAction<string>) => {
            state.viewType = action.payload;
        },
        changeSelectContent: (state, action: PayloadAction<string>) => {
            state.assignmentSelect = action.payload;
            state.hasLoadData = false;
        },
        updateSection: (state, action: PayloadAction<string>) => {
            state.data.sections = action.payload;
        },
        clearError: (state, action: PayloadAction<string>) => {
            state.data.error = action.payload;
        },
        clearStatusNewContent: (state) => {
            state.isNew = false;
        },
        updateHasLoadData: (state, action: PayloadAction<boolean>) => {
            state.hasLoadData = action.payload;
            state.assignmentSelect = null;
        },
        resetAssociatedLessons: (state) => {
            state.data = {
                ...state.data,
                associatedLessons: [],
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            assignmentUpdate.pending ||
                assignmentCreate.pending ||
                assignmentCreateContent.pending ||
                assignmentDeleteContent.pending ||
                assignmentSortContent.pending,
            (state, { payload }) => {
                state.loading = 'pending';
            }
        ),
            builder.addCase(assignmentCreate.fulfilled, (state, { payload }: { payload: any }) => {
                state.loading = 'succeeded';
                state.changed = false;
                state.isNew = true;
                state.data = payload?.data;
            }),
            builder.addCase(assignmentUpdate.fulfilled, (state, { payload }: { payload: any }) => {
                state.loading = 'succeeded';
                state.changed = true;
                if (JSON.parse(payload?.config?.data)?.status) {
                    state.data.status = payload?.data?.status;
                } else {
                    state.data = {
                        ...state?.data,
                        ...payload?.data,
                    };
                }
            }),
            builder.addCase(assignmentDeleteContent.fulfilled, (state, { payload }) => {
                state.loading = 'succeeded';
                state.changed = true;
                const newSection = state?.data?.sections.filter((item) => item.id != payload?.id);
                state.data.sections = newSection;
                if (payload?.id == state.assignmentSelect.id) {
                    state.assignmentSelect = newSection[0] || null;
                }
            }),
            builder.addCase(assignmentRenameContent.fulfilled, (state, { payload }) => {
                state.loading = 'succeeded';
                state.changed = true;
                let sections = state.data?.sections;
                sections.forEach((section, index) => {
                    if (section?.library_content?.object_id == payload.object_id) {
                        sections[index].library_content.name = payload.name;
                    }
                });
                state.data.sections = sections;
                if (payload.sectionId == state.assignmentSelect.id) {
                    state.assignmentSelect.library_content.name = payload.name;
                }
            }),
            builder.addCase(assignmentCreateContent.fulfilled, (state, { payload }) => {
                state.loading = 'succeeded';
                state.changed = true;
                const sections = state?.data?.sections || [];
                sections.push(payload);
                state.data.sections = sections;
                if (!state.assignmentSelect) {
                    state.assignmentSelect = payload;
                }
            }),
            builder.addCase(assignmentFetch.fulfilled, (state, { payload }) => {
                state.loading = 'succeeded';
                state.changed = false;
                state.data = payload?.data;
                if (!state.assignmentSelect && payload?.data?.sections?.length > 0) {
                    state.assignmentSelect = payload?.data?.sections[0];
                }
            }),
            builder.addCase(assignmentFetchAssociatedLessons.fulfilled, (state, { payload }) => {
                state.loading = 'succeeded';
                state.changed = false;
                state.data = {
                    ...state.data,
                    associatedLessons: payload?.data?.items,
                }
            }),
            builder.addCase(assignmentUpdate.rejected || assignmentCreate.rejected, (state, action) => {
                if (action.payload) {
                    state.error = action.payload.errorMessage;
                } else {
                    state.error = action.error;
                }
                state.loading = 'failed';
            });
    },
});

export const {
    changeLibraryTypeAdd,
    changeViewType,
    changeSelectContent,
    updateSection,
    clearError,
    clearStatusNewContent,
    updateHasLoadData,
    resetAssociatedLessons,
} = assignmentSlice.actions;

export const selectAssignmentSlice = (state: RootState) => state.assignment;

export default assignmentSlice.reducer;
