import { Learning } from "./Components/Academics/Learning";
import { Resources } from "./Components/Academics/Resources";

export const AcademicsTab = () => {
    const data = { value: '1,234', percent: '8', day: 1 };
    return <>
        <div className="flex space-x-5">
            <div className="w-full">
                <Learning />
            </div>
        </div>
        <div className="w-4/5 mt-5">
            <Resources />
        </div>
    </>
}