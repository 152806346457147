import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ArrowLeftIcon } from '@heroicons/react/outline';

interface DocumentPreviewProps {
    closeModal: () => void;
    isOpen: boolean;
    children: any;
    title?: string;
}

export const PreviewFullScreen = ({ children, isOpen, closeModal, title }: DocumentPreviewProps) => {
    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-[999]" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full justify-center text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full transform text-left align-middle transition-all">
                                    <div className="flex items-center justify-between space-x-2 p-4">
                                        <p className="flex items-center space-x-2.5">
                                            <ArrowLeftIcon className="w-7 h-7 mr-2 cursor-pointer text-white" onClick={closeModal} />
                                            <span className="text-sm font-semibold text-white inline-block truncate">{title}</span>
                                        </p>
                                    </div>
                                    <>{children}</>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};
