import { useState, useEffect, useContext } from 'react';
import DialogComponent from 'components/Dialog/DialogComponent';
import { useTranslation } from 'react-i18next';
import ModalUpdateApos from './ModalUpdateApos';
import ButtonComponent from 'components/Button/ButtonComponent';
import { PlusIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import Spinner from 'components/Spinner';
import TableApo from './TableApo';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';

const ClassApo = () => {
    const { t } = useTranslation();
    const workspace: any = useParams();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [isOpenModal, setOpenModal] = useState(false);

    const { dispatch: workspaceDispatch, getWorkspaceDetailState } = useContext(
        GetWorkspaceContext
    );

    const { id: workspaceId, classId } = workspace;
    const {
        class: { coin },
    } = getWorkspaceDetailState;

    const translator = (key: string, option?: any) => {
        return t('COIN.' + key, option);
    };

    const getClassStudents = () => {
        workspaceMiddleware.getClassStudentsWithPointsMiddleware(
            workspaceDispatch,
            { workspaceId, classId }
        );
    };

    const updateRewardPoint = (params: any) => {
        workspaceMiddleware
            .updateClassStudentsRewardPointsMiddleware(
                workspaceDispatch,
                {
                    workspaceId,
                    classId,
                },
                params
            )
            .then((res) => {
                getClassStudents();
            })
            .finally(() => {
                handleCloseModal();
            });
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        getClassStudents();
    }, []);

    useEffect(() => {
        if (coin?.data?.items) setData(coin.data.items);
    }, [coin]);

    return (
        <div>
            <div className="flex justify-end py-5">
                <ButtonComponent
                    title={translator('EDIT_COINS')}
                    classStyle="xs:p-1 xs:text-sm primary-button"
                    onClickButton={() => setOpenModal(true)}
                    icon={<PlusIcon className="w-4 h-4 mr-ooolab_m_1" />}
                />
            </div>
            <div className="border-t h-[calc(100vh-239px)] overflow-auto">
                {loading && <Spinner />}
                {!!data?.length && !loading && (
                    <TableApo dataTable={data} translator={translator} />
                )}
                {!!!data?.length && !loading && (
                    <NoContentWithImage content="No students" />
                )}
            </div>
            <DialogComponent
                isOpen={isOpenModal}
                width="lg:max-w-2xl"
                title={translator('EDIT_COINS')}
                onCloseModal={handleCloseModal}
                child={
                    <ModalUpdateApos
                        data={data}
                        translator={translator}
                        closeModal={handleCloseModal}
                        updateRewardPoint={updateRewardPoint}
                    />
                }
            />
        </div>
    );
};

export default ClassApo;
