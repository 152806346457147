import './DetailsFilePopup.scss';

const BlockContent = ({ children, title, number = undefined }) => {
    return (
        <div className="blockContent">
            <div className="blockContent-header">
                <div className="title">
                    {title}
                    {number !== undefined && <div className="number">{number}</div>}
                </div>
                <div className="detailsFilePopup-line"></div>
            </div>
            {children}
        </div>
    );
};

export default BlockContent;
