import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { FC, useEffect, useState } from "react";
import TeachService from 'services/teach.services';
import { useParams } from "react-router-dom";
import './Banner.scss'
import { openUrl } from "utils/commonFun";

type TButtonSliderProps = {
  className?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const NextArrow: FC<TButtonSliderProps> = ({onClick}) => {
  return (
    <button
      className="BtnNext"
      onClick={onClick}
    >
      <ChevronRightIcon className="icon" />
    </button>
  )
}

const PrevArrow: FC<TButtonSliderProps> = ({onClick}) => {
  return (
    <button
      className="BtnPre"
      onClick={onClick}
    >
      <ChevronLeftIcon className="icon" />
    </button>
  )
}

export default function SliderImage() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 5000
  };

  const params: {id: string} = useParams();
  const workspaceId: string  = params?.id;
  const [banners, setBanners] = useState([])

  const getBanners = () => {
    TeachService.getInstructorDashboardBanner(workspaceId, {view: 'web_instructor_dashboard', skip_pagination: true})
    .then((res) => {
      setBanners(res?.data?.items?.slice(0,6))
    })
  }

  useEffect(() => {
    getBanners();
  }, [])

  return (
    <div className={`Banner ${!banners?.length ? 'hidden' : ''}`}>
      <div className="Banner-cover">
        <div>
          <Slider {...settings}>
            {
              banners?.map((item) => {
                return (
                <div key={item?.id}>
                  <img onClick={() => openUrl(item?.deeplink)} className="banner-img" src={item?.thumbnail} alt="slider" />
                </div>
                )
              })
            }
          </Slider>
        </div>
      </div>
    </div>
  )
}
