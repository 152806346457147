import React from 'react';
import { Row, Column } from 'react-table';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import Table, { RenderTableCell, RenderTableHeader } from 'components/V2/Table/TableCustom';
import NO_BANNERS from 'assets/img/empty-state/banner.png'

interface TableBannerProps {
    workspaceId: string;
    dataTable: any;
    translator: (str: string, opt?: any) => string;
}

const TableBanner: React.FC<TableBannerProps> = ({
    workspaceId,
    dataTable,
    translator
}) => {
    const capitalizeFirstLetter = (str: string) => {
        if (!str) return '';
        return str?.charAt(0)?.toUpperCase() + str?.slice(1);
    };

    const initialState = {
        hiddenColumns: ['id', 'thumbnail'],
    };

    const columns = React.useMemo(
        () => [
            { accessor: 'id' },
            { accessor: 'thumbnail' },
            {
                Header: () => (
                    <RenderTableHeader
                        value={translator("BANNER_NAME")}
                        className="pl-[120px]"
                    />
                ),
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return (
                        <div className="flex justify-start items-center space-x-2 mt-[6px] mb-[12px]">
                            <img
                                className="w-28 h-10 rounded-lg object-cover"
                                src={row.values.thumbnail}
                                alt="alt_img"
                            />
                            <Link
                                to={`/workspace/${workspaceId}/management/banners/${row.values.id}`}
                                className="text-ooolab_xs text-primary-500 font-semibold cursor-pointer truncate"
                                data-tip={row.values.name}
                                data-for={row.values.id + 'name'}
                            >
                                {row.values.name}
                            </Link>
                            {/* @ts-ignore */}
                            <ReactTooltip
                                place="left"
                                type="dark"
                                effect="float"
                                id={row.values.id.toString() + 'name'}
                                getContent={(content) => (
                                    <div className="w-56 h-auto">{content}</div>
                                )}
                                disable={row.values.name?.length < 35}
                            />
                        </div>
                    );
                },
                accessor: 'name',
                width: 370,
            },
            {
                Header: () => <RenderTableHeader value={translator("START_DATE")} />,
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return (
                        <RenderTableCell
                            value={dayjs(row.values.start_date).format(
                                'MM/DD/YYYY'
                            )}
                        />
                    );
                },
                accessor: 'start_date',
                width: 80,
            },
            {
                Header: () => <RenderTableHeader value={translator("END_DATE")} />,
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return (
                        <RenderTableCell
                            value={dayjs(row.values.end_date).format(
                                'MM/DD/YYYY'
                            )}
                        />
                    );
                },
                accessor: 'end_date',
                width: 80,
            },
            {
                Header: () => <RenderTableHeader value={translator("STATUS")} />,
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return (
                        <div className="pl-ooolab_p_1_e py-2 flex justify-start items-center space-x-2">
                            <div
                                className={`w-2 h-2 rounded-full ${
                                    row.values?.status?.toLowerCase() ===
                                    'online'
                                        ? 'bg-[#3AA757]'
                                        : 'bg-[#E7443C]'
                                }`}
                            ></div>
                            <p className="text-ooolab_xs text-ooolab_dark_1 leading-10 text-left font-semibold">
                                {capitalizeFirstLetter(translator(row.values.status))}
                            </p>
                        </div>
                    );
                },
                accessor: 'status',
                width: 80,
            },
        ],
        []
    );
    return (
        <Table
            data={dataTable}
            columns={columns}
            initialState={initialState}
            isBorderFirstColum={false}
            emptyContent={translator('NO_BANNERS')}
            emptyIcon={NO_BANNERS}
        />
    );
};

export default TableBanner;
