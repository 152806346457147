
import { useState } from "react";
import { ArrowDownIcon, ArrowUpIcon, PlusIcon } from "@heroicons/react/outline";
import { CaretIcon } from "assets/icon";
import TrashIcon from "assets/icon/TrashIcon";
import { ButtonIcon, ConfirmDeleteModal } from "_shared";
import { SyllabusLearningPathUnit } from "../SyllabusLearningPathUnit/SyllabusLearningPathUnit";
import { Disclosure } from '@headlessui/react'
import "./SyllabusList.scss";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { LearningPathDetail } from "types/LearningPath.type";
import { useParams } from "react-router-dom";
import { EmptySyllabus } from "../EmptySyllabus/EmptySyllabus";
import { checkPermission } from "utils/commonFun";
import { courseTemplateFetch, deleteCourseTemplateSyllabus, updateCourseTemplateSyllabusOrder } from "store/features/courseTemplateSlice";

export const TemplateSyllabusList = ({ hanldeAddLearningPath }: { hanldeAddLearningPath: () => void }) => {
    const params: { id: string; courseId: string } = useParams();
    const dispatch = useAppDispatch()
    const { syllabuses } = useAppSelector(state => state.courseTemplate.data);
    const { t: translator } = useTranslation();
    const [showConfirmRemove, setShowConfirmRemove] = useState(false);
    const [selectLearningPath, setSelectLearningPath] = useState(null);

    const handleMoveUnit = async (values, unitIndex, next: boolean) => {
        const fromIndex = unitIndex;
        let toIndex = unitIndex - 1;
        if (next) {
            toIndex = unitIndex + 1;
        }
        let sections = [...values];
        const element = sections[fromIndex];
        sections.splice(fromIndex, 1);
        sections.splice(toIndex, 0, element);
        const syllabusesData = sections.map((item, index) => {
            return {
                id: item?.id,
                order_number: index + 1
            }
        });
        await dispatch(updateCourseTemplateSyllabusOrder({
            workspaceId: params?.id,
            id: params?.courseId,
            syllabuses: syllabusesData
        }));
        dispatch(
            courseTemplateFetch({
                workspaceId: params.id,
                id: params.courseId,
            })
        );
    }

    const handleDelete = async (value: boolean) => {
        if (value) {
            await dispatch(deleteCourseTemplateSyllabus({
                workspaceId: params.id,
                id: params.courseId,
                syllabus_id: selectLearningPath.id
            }));
            dispatch(
                courseTemplateFetch({
                    workspaceId: params.id,
                    id: params.courseId,
                })
            );
        }
        setSelectLearningPath(null)
        setShowConfirmRemove(false)
    }

    return (<>
        <div className="syllabusList">
            {syllabuses?.length > 0 ?
                <> {syllabuses?.map((item: { id: string; learning_path: LearningPathDetail }, index) =>
                    <div key={item.id}>
                        <Disclosure >
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className="w-full" disabled={!checkPermission('courseAcademic', 'R')}>
                                        <div className="syllabusList-item">
                                            <div className="syllabusList-header">
                                                <div className="syllabusList-info">
                                                    <h3 className="syllabusList-name">{item?.learning_path?.title}</h3>
                                                    <span className="syllabusList-badge">{item?.learning_path?.sections?.length}</span>
                                                    <ButtonIcon type="button" onClick={() => { }} className="ml-auto">
                                                        <span className={`${open ? 'rotate-90' : ''}`}>
                                                            <CaretIcon size={22} />
                                                        </span>
                                                    </ButtonIcon>
                                                </div>
                                                {checkPermission('courseAcademic', 'U') && <div className="syllabusList-action"><ButtonIcon type="button"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        setShowConfirmRemove(true);
                                                        setSelectLearningPath(item);
                                                    }}>
                                                    <TrashIcon />
                                                </ButtonIcon>
                                                    <ButtonIcon
                                                        disabled={index == syllabuses?.length - 1}
                                                        className={`${index == syllabuses?.length - 1 ? 'cursor-not-allowed' : ''}`}
                                                        type="button" onClick={(event) => {
                                                            event.preventDefault();
                                                            handleMoveUnit(syllabuses, index, true);
                                                        }}>
                                                        <span className={`${index == syllabuses?.length - 1 ? 'text-gray-300' : 'text-primary-500'} p-1`}>
                                                            <ArrowDownIcon className="w-4 h-4" />
                                                        </span>
                                                    </ButtonIcon>
                                                    <ButtonIcon
                                                        type="button"
                                                        disabled={index == 0}
                                                        className={`${index == 0 ? 'cursor-not-allowed' : ''}`}
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            handleMoveUnit(syllabuses, index, false)

                                                        }}>
                                                        <span className={`${index == 0 ? 'text-gray-300' : 'text-primary-500'} p-1`}>
                                                            <ArrowUpIcon className="w-4 h-4" />
                                                        </span>
                                                    </ButtonIcon>
                                                </div>
                                                }


                                            </div>
                                        </div>
                                    </Disclosure.Button>
                                    {open ? <Disclosure.Panel>
                                        <div className="syllabusList-content">
                                            {item?.learning_path?.description ? <p><span>{translator("COURSES.DESCIPTION")}:</span> {item?.learning_path?.description ?? 'N/A'}</p> : null}
                                            {item?.learning_path?.objective ? <p><span>{translator("COURSES.OBJECTIVE")}:</span> {item?.learning_path?.objective ?? 'N/A'}</p> : null}
                                            <div>
                                                <SyllabusLearningPathUnit sections={item.learning_path.sections} />
                                            </div>
                                        </div>
                                    </Disclosure.Panel> : null}
                                </>
                            )}
                        </Disclosure>
                    </div>)}
                    {checkPermission('courseAcademic', 'U') && <div className="syllabusList-item">
                        <div className="syllabusList-header">
                            <div className="syllabusList-info cursor-pointer" onClick={hanldeAddLearningPath}>
                                <PlusIcon className="w-5 mr-1 text-primary-500" />
                                <h3 className="syllabusList-name !text-primary-500">{translator("COURSES.ADD_LEARNING_PATH")} </h3>
                            </div>
                        </div>
                    </div>}

                </> :
                <EmptySyllabus onAction={hanldeAddLearningPath} />
            }
        </div>
        {showConfirmRemove ?
            <ConfirmDeleteModal
                isOpen={showConfirmRemove}
                titleModal={translator('REMOVE')}
                btnPrimaryTitle={translator('REMOVE')}
                onClose={handleDelete}>
                <p>{translator('COURSES.REMOVE_LEARNING_PATH')}</p>
            </ConfirmDeleteModal> : null}
    </>)
}