import { useState, useCallback, useEffect } from 'react';

import debounce from 'lodash/debounce';

export const useDebounce = (fnToDebounce, durationInMs = 1000) => {
    if (isNaN(durationInMs))
        throw new TypeError('Time for debounce should be a number.');

    if (fnToDebounce == null)
        throw new TypeError('fnToDebounce cannot be null.');

    if (typeof fnToDebounce !== 'function')
        throw new TypeError('fnToDebounce should be a function.');

    return useCallback(debounce(fnToDebounce, durationInMs), [
        fnToDebounce,
        durationInMs,
    ]);
};


export default function useDebounceEffect(effect, dependencies, delay) {
  const callback = useCallback(effect, dependencies);

  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, [callback, delay]);
}

export const useDebouncedState = (initialState, durationInMs = 1000) => {
    const [internalState, setInternalState] = useState(initialState);
    const debouncedFunction = useDebounce(setInternalState, durationInMs);
    return [internalState, debouncedFunction];
};
