import { CheckIcon } from '@heroicons/react/outline';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import './style.css';

interface RadioProps {
    inputName: string;
    disabled?: boolean;
    value: string;
    control?: any,
    onClick?: (value: any) => void,
    checked?: boolean
}

const InputRadio: FC<RadioProps> = ({ control, disabled, inputName, value, checked, onClick }) => {
    return (
        <Controller
            control={control}
            name={inputName}
            render={({ field: { onChange } }) => (<>
                <input
                    name={inputName}
                    className={`w-0 h-0 opacity-0 absolute `}
                    type="radio"
                    id={`${inputName}-${value}`}
                    value={value}
                    disabled={disabled}
                    onChange={onClick ? onClick : onChange}
                    checked={checked}
                />
                <label
                    className={`inputRadio overflow-hidden rounded-full cursor-pointer flex justify-center items-center ${disabled && checked ? '!bg-gray-400 !border-gray-400' : ''}`}
                    htmlFor={`${inputName}-${value}`}>
                    <span className="hidden">Color</span>
                    <CheckIcon className="w-ooolab_w_5 h-ooolab_h_5 rounded-full text-white border-none" />
                </label></>)}
        />
    )
};

export default InputRadio;
