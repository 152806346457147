import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import React, { Fragment } from 'react';
import { classNames } from 'utils/commonFun';

interface ConfirmModalProps {
    isOpen: boolean;
    maskClosable?: boolean; // Whether to close the modal dialog when the mask (area outside the modal) is clicked
    isShowClose?: boolean;
    onCloseModal?: (e?: any) => void;
    title?: string | React.ReactNode;
    child?: React.ReactNode;
    width?: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
    isOpen,
    maskClosable = false,
    isShowClose = true,
    onCloseModal,
    title = '',
    child = <div>Content modal</div>,
    width,
}) => {
    const closeButtonRef = React.useRef(null);

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-70 overflow-y-auto"
                onClose={maskClosable ? onCloseModal : function () { }}
                initialFocus={closeButtonRef}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 dialog-background bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className={classNames(
                                'inline-block  p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl',
                                'w-full max-w-lg',
                                width
                            )}
                        >
                            <Dialog.Title
                                as="div"
                                className="flex justify-between mb-4"
                            >
                                <h1 className="text-base text-ooolab_dark_1 font-bold leading-6">
                                    {title}
                                </h1>
                                <button
                                    hidden={!isShowClose}
                                    type="button"
                                    onClick={onCloseModal}
                                    ref={closeButtonRef}
                                    className="focus:outline-none"
                                >
                                    <XIcon className="w-6 h-6 hover:text-red-600" />
                                </button>
                            </Dialog.Title>

                            <Fragment>{child}</Fragment>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default ConfirmModal;
