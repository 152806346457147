import { FC, useState, useEffect, useMemo } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { useTranslation } from 'react-i18next';
import { NavbarTop } from '../components/NavbarTop/NavbarTop';
import { ExternalLinkIcon } from '@heroicons/react/solid';
import { ClassDashboardOveriew } from './ClassDashboardOveriew';
import { ClassDashboardPeople } from './ClassDashboardPeople';
import { ClassDashboardAcademics } from './ClassDashboardAcademics';
import { ClassDashboardAssignments } from './ClassDashboardAssignments';
import { ClassDashboardSessions } from './ClassDashboardSessions';
import ClassService from 'services/class.service';
import courseService from 'services/course.service';

type SpecificClassDashboardProps = {};

const SpecificClassDashboard: FC<SpecificClassDashboardProps> = () => {
    const { t } = useTranslation();
    const params: { id: string; classId: string } = useParams();
    const navsTop = useMemo(() => {
        return [
            {
                name: t('REPORTS.Overview'),
                url: `/workspace/${params.id}/report/dashboard-course/${params.classId}`,
                tabName: 'overview'
            },
            {
                name: t('REPORTS.People'),
                url: `/workspace/${params.id}/report/dashboard-course/${params.classId}?tab=people`,
                tabName: 'people'
            },
            {
                name: t('REPORTS.Assignments'),
                url: `/workspace/${params.id}/report/dashboard-course/${params.classId}?tab=assignments`,
                tabName: 'assignments'
            },
            {
                name: t('SESSION.SESSIONS'),
                url: `/workspace/${params.id}/report/dashboard-course/${params.classId}?tab=sessions`,
                tabName: 'sessions'
            }
        ]
    },[params, t]);
    const [tabActive, setTabActive] = useState<string>('overview');
    const location: any = useLocation();
    const [classDetail, setClassDetail] = useState<any>(null);
    const workspaceId = params.id;
    const classId = params.classId;
    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);

    const fetchDetailClass = () => {
        courseService.getCourse({workspaceId: workspaceId, id:classId})
            .then((res) => {
                setClassDetail({
                    ...res?.data,
                    sis_course_group_name: res?.data?.sis_course_group_name || 'Apollo Active',
                    sis_course_name: res?.data?.sis_course_name || 'Apollo Active Primary',
                    sis_level_name: res?.data?.sis_level_name || '1',
                });
            })
    };

    useEffect(() => {
        fetchDetailClass()
    }, []);

    useEffect(() => {
        if (location?.search) {
            setTabActive(location?.search?.split('=')[1])
        } else {
            setTabActive('overview');
        }
    }, [location])

    return (
        <div className="w-full">
            <PageBreadcrumb
                pageNameDefault={translator('REPORTS')}
                pages={[
                    { name: translator("Course Dashboard"), href: `/workspace/${workspaceId}/report/dashboard-course`, className: '' },
                    { name: classDetail?.title, href: '', className: '' },
                ]}
                containerClassName=" z-10 sticky top-0 w-full"
            />
            <div >
                <div className='cursor-pointer'>
                    <h1 className="flex items-center p-5 text-xl text-gray-800 font-semibold">
                        {classDetail?.title}
                        <Link to={`/workspace/${workspaceId}/management/courses/${classId}`}>
                            <ExternalLinkIcon className='w-6 h-6 ml-2 text-primary-500' />
                        </Link>
                    </h1>
                    <NavbarTop data={navsTop} actived={tabActive} />
                </div>
                <div className='p-5 bg-gray-50 min-h-[calc(100vh-126px)]'>
                    {tabActive == 'overview' && <ClassDashboardOveriew />}
                    {tabActive == 'people' && <ClassDashboardPeople />}
                    {tabActive == 'assignments' && <ClassDashboardAssignments />}
                    {tabActive == 'sessions' && <ClassDashboardSessions />}
                </div>
            </div>
        </div>
    );
};

export default SpecificClassDashboard;
