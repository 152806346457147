import { CreatableAsyncSelectField } from 'components/Select';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import courseService from 'services/course.service';
import { createOption } from 'utils/commonFun';

export const CourseFieldData = ({values, control, name, onChangeValue, label, isDisabled = false}: 
    {
        values: any
        control: any, 
        name: string,
        onChangeValue: (field: string, value: any) => void,
        label: string,
        isDisabled?:boolean,
    }) => {
    const {t: translator} = useTranslation();
    const params: { id: string, courseId: string } = useParams();
    const onCreateLevel = (inputValue: string) => {
        const newOption = createOption({name: inputValue});
        const tagSelected = [newOption, ...values];
        onChangeValue(name, tagSelected);
        courseService.addNewValueForCourseFieldData(params?.id, {field: name, value: newOption.name})
    };
    const handleTagLoadOptions = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional.page,
            per_page: 20,
            order: 'asc',
            q: inputValue || null,
            field: name

        };
        if (!payload?.q) delete payload.q;

        const { items, total } = await fetchTags(payload);
        const newOptions = items.map((el: any) => ({
            id: el,
            label: el,
            value: el,
        }));

        return {
            options: newOptions,
            hasMore: additional.page < total / 20,
            additional: {
                page: additional.page + 1,
            },
        };
    };

    const fetchTags = async (bodyParams?: any) => {
        let response = {
            items: [],
            total: 0,
        };

        await courseService.getFieldData(params.id, bodyParams).then((res) => {
            response = {
                items: res?.data?.items,
                total: res?.data?.total,
            };
        });

        return response;
    };

    return (
        <>
            <CreatableAsyncSelectField
                control={control}
                label={label}
                name={name}
                className="col-span-4"
                placeholder={translator('COURSES.SEARCH_OR_CREATE_NEW_TAG')}
                handleCreate={onCreateLevel}
                loadOptions={handleTagLoadOptions}
                isDisabled={isDisabled}
                customStyles={{
                    control: (base) => {
                        return { ...base, minHeight: '0px', height: 'auto' }
                    },
                    indicatorsContainer: (base) => {
                        return { ...base, padding: '0px 8px', height: '30px' }
                    },
                }}
            />
        </>
    )
}