import { useState, useEffect } from 'react';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import './Filter.scss';
import { useTranslation } from 'react-i18next';
import { LearnerAndGroupSearch } from '_shared/components/LearnerAndGroupSearch/LearnerAndGroupSearch';
import { REPORT_STATUS_LIST } from '../../constant';

interface IFilter {
    onChangeFilter: (value: any) => void;
    isFirstLoad: boolean;
}

const Filter = ({
    onChangeFilter,
    isFirstLoad,
}: IFilter) => {
    const {t: translator} = useTranslation();
    const [keyword, setKeyword] = useDebouncedState('', 0);
    const [statusSelected, setStatusSelected] = useState([]);
    const [assignee, setAssignee] = useState<any>();

    useEffect(() => {
        if (!isFirstLoad) {
            let listFilter: any = {
                name: keyword?.trim(),
                status: statusSelected?.[0]?.value,
                learner_ids: assignee?.learner_ids?.split(','),
                learning_group_ids: assignee?.learning_group_ids?.split(',')
            };
            if (statusSelected?.length === 2) delete listFilter.status;
            if (!assignee?.learning_group_ids) delete listFilter.learning_group_ids
            if (!assignee?.learner_ids) delete listFilter.learner_ids
            if (!listFilter?.name) delete listFilter.name
            if (!listFilter?.status) delete listFilter.status
            onChangeFilter(listFilter);
        }
    }, [ keyword, statusSelected, assignee]);


    return (
        <div className="flex gap-3 flex-wrap relative z-10">
            <SearchBox placeholder={translator('SEARCH')} className="max-w-[280px]" onSubmit={(value) => setKeyword(value)} />
            <LearnerAndGroupSearch onChangeFilter={setAssignee} />
            <SelectBox
                label={translator('STATUS')}
                onSelect={setStatusSelected}
                selectedItems={statusSelected}
                data={REPORT_STATUS_LIST(translator)}
                total={2}
                isMulti={false}
            />
        </div>
    );
};

export default Filter;
