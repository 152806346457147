import React from "react";
import './index.scss';
import { PaymentDetails } from "./PaymentDetails/PaymentDetails";
import { PaymentGateways } from "./PaymentGateways";
import { Settings } from "./Settings/Settings";
import { InvoiceReceiptInformation } from "./InvoiceReceiptInformation";

export const ECommerce = () => {
    return <div className="eCommerce">
        <section>
            <Settings />
        </section>
        <section>
            <InvoiceReceiptInformation />
        </section>
        <section>
            <PaymentDetails />
        </section>
        <section>
            <PaymentGateways />
        </section>
    </div>
}