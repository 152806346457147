import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import * as React from 'react';
import { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import editIcon from 'assets/SVG/pencil.svg';
import listIcon from 'assets/SVG/list.svg';
import closeIcon from 'assets/SVG/close.svg';
import shareIcon from 'assets/SVG/share.svg';
import { LessonSection } from 'types/GetListOfWorkspace.type';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { genClassNames } from 'utils/handleString';
import { LibraryIcon } from 'constant/setupBars.const';
import H5PPublicViewer from 'components/H5P/H5PPublicViewer';
import { useTranslation } from 'react-i18next';

const h5pIcon = require(`assets/themes/${process.env.REACT_APP_WHITELABEL}/logo192.png`);


const doAssigmentBg = require(`assets/themes/${process.env.REACT_APP_WHITELABEL}/do-assigment-bg.png`);
interface FrameType {
    file_name: string;
    file_url: string;
    firstFile: number;
    index: string;
    main_library: string;
    sectionIndex: number;
    uid: string;
    idxTotal: number;
}

interface TypePreview {
    index: number;
    value: FrameType | undefined;
}


const Arrow = ({ reverse }: { reverse: boolean }) => (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" style={reverse ? { transform: 'scaleX(-1)' } : undefined}>
        <path
            d="M8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893L15.7071 6.29289C15.8946 6.48043 16 6.73478 16 7C16 7.26522 15.8946 7.51957 15.7071 7.70711L9.70711 13.7071C9.31658 14.0976 8.68342 14.0976 8.29289 13.7071C7.90237 13.3166 7.90237 12.6834 8.29289 12.2929L12.5858 8L1 8C0.447715 8 0 7.55229 0 7C0 6.44772 0.447715 6 1 6L12.5858 6L8.29289 1.70711C7.90237 1.31658 7.90237 0.683417 8.29289 0.292893Z"
            fill="#0071CE"
        />
    </svg>
);

const DoubleArrow = ({ reverse, disabled }: { reverse: boolean; disabled?: boolean }) => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" style={reverse ? { transform: 'scaleX(-1)' } : undefined}>
        <path
            d="M5.24408 0.41107C5.56951 0.0856329 6.09715 0.085633 6.42259 0.41107L11.4226 5.41107C11.748 5.73651 11.748 6.26415 11.4226 6.58958L6.42259 11.5896C6.09715 11.915 5.56951 11.915 5.24408 11.5896C4.91864 11.2641 4.91864 10.7365 5.24408 10.4111L9.65482 6.00033L5.24408 1.58958C4.91864 1.26414 4.91864 0.736507 5.24408 0.41107ZM0.244078 0.41107C0.569515 0.085633 1.09715 0.085633 1.42259 0.41107L6.42259 5.41107C6.57887 5.56735 6.66667 5.77931 6.66667 6.00033C6.66667 6.22134 6.57887 6.4333 6.42259 6.58958L1.42259 11.5896C1.09715 11.915 0.569514 11.915 0.244078 11.5896C-0.0813592 11.2641 -0.0813592 10.7365 0.244078 10.4111L4.65482 6.00033L0.244078 1.58958C-0.0813584 1.26414 -0.0813584 0.736507 0.244078 0.41107Z"
            fill={disabled === true ? '#C7C9D9' : '#2E3A59'}
        />
    </svg>
);

const H5PAssigmentsPreview: React.FC = () => {
    const params: { id: string; lessonId: string } = useParams();
    const [index, setIndex] = useState(0);
    const [page, setPage] = useState(0);
    const [sectionIndex, setSectionIndex] = useState(0);
    const [showSection, setShowSection] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [info, setInfo] = useState<string | undefined>(undefined);
    const [loadingSlide, setLoadingSlide] = useState(false);
    const [loadingPrevSlide, setLoadingPrevSlide] = useState(false);
    const [contentPreview, setContentPreview] = useState<TypePreview[]>([]);
    const [currentData, setCurrentData] = useState<string>();

    const refs = contentPreview?.reduce((acc, val, i, arr) => {
        acc[i] = React.createRef();
        return acc;
    }, {});
    const parent = useRef(undefined);
    const assignmentsList = localStorage.getItem('assignmentsList');
    const data = useMemo(() => {
        let idx: number = 0;
        const assignments = JSON.parse(assignmentsList);
        const data = (assignments || []).map((file: any, index: number) => ({
            uid:file,
            index: `${index + 1}/${(assignments || []).length}`,
            sectionIndex,
            idxTotal: index,
            firstFile: index === 0 ? sectionIndex : -1,
        }));
        return data;
    }, [assignmentsList]);

    useEffect(() => {
        if (data?.length == 0) {
            setInfo('No files to preview');
        } else {
            setTimeout(() => setShowLoading(false), 1500);
        }
    }, [data]);

    const ref = useRef<any>();
    const dataNonFlat = useMemo(() => {
        let idx: number = 0;
        const assignments = JSON.parse(assignmentsList);
        const data =
        assignments?.map((item: LessonSection) => {
            return {
                idxTotal: idx++
            }
        }) || [];
        return data;
    }, [assignmentsList]);

    useEffect(() => {
        setCurrentData(data[0]?.uid);
        if (data?.length) {
            let itemsNull: TypePreview[] = [];
            data.map((d, i) => {
                const dataItem = {
                    index: i,
                    value: undefined,
                };
                itemsNull.push(dataItem);
            });
            for (let i = 0; i < (data.length > 1 ? 2 : 1); i++) {
                const dataFrame = {
                    file_name: data[i].file_name,
                    file_url: data[i].file_url,
                    firstFile: data[i].firstFile,
                    index: data[i].index,
                    main_library: data[i].main_library,
                    sectionIndex: data[i].sectionIndex,
                    uid: data[i].uid,
                    idxTotal: data[i].idxTotal,
                };
                itemsNull[i].value = dataFrame;
            }
            setContentPreview(itemsNull);
        }
    }, [data]);
    const beforeChange = (next: number) => {
        if (sectionIndex !== data[next]?.sectionIndex) {
            setSectionIndex(data[next].sectionIndex);
        }
    };
    const sections = useMemo(
        () =>{
            const assignments = JSON.parse(assignmentsList);
            return [{
                title: assignments.title,
                disabled: (assignments.files || []).length === 0,
            }]
        },
        [assignmentsList, page]
    );

    const nextSlide = () => {
        beforeChange(index + 1);
        setCurrentData(data[index + 1].uid);
        setIndex(index + 1);
    };

    const preSlide = () => {
        beforeChange(index - 1);
        setCurrentData(data[index - 1].uid);
        setIndex(index - 1);
    };

    const handleSelectSection = (idx: number) => {
        const firstFileAtSection = contentPreview[dataNonFlat[idx][0].idxTotal];
        setCurrentData(data[firstFileAtSection.index]?.uid);
        setIndex(firstFileAtSection.index);
    };

    return (
        <div className="relative bg-cover h-full min-h-screen bg-scroll">
            
            <div className="flex items-center justify-center py-2 mt-3">
                {loadingPrevSlide ? (
                    <div className="flex items-center  ml-20 justify-center rounded-full w-10 h-10 shadow-ooolab_sub_item">
                        <svg
                            className="animate-spin w-5 h-5 opacity-100"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path
                                className="opacity-75"
                                fill="gray"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                    </div>
                ) : (
                    <div
                        onClick={preSlide}
                        style={{ top: '50%', left: '7%' }}
                        className={genClassNames({
                            'bg-white cursor-pointer flex items-center justify-center rounded-full w-10 h-10 shadow-ooolab_sub_item fixed': true,
                            invisible: index === 0 || showLoading,
                        })}
                    >
                        <Arrow reverse />
                    </div>
                )}

                <div
                    ref={parent}
                    className="bg-white min-h-[calc(100vh-40px)] w-ooolab_w_h5p_preview ml-20 shadow-ooolab_sub_item rounded-ooolab_h5p p-5 h-auto"
                >
                    <div className="flex mb-2">
                        <div className="flex-1 flex items-center">
                            <img className="w-6 h-6" alt="" src={h5pIcon} />
                            <div className="text-sm text-ooolab_text_username ml-3">
                                {data.length > index && !!data[index] ? data[index].file_name : ''}
                            </div>
                        </div>
                        <div className="text-sm text-ooolab_dark_2">
                            {data.length > index && !!data[index] ? data[index].index : ''}
                        </div>
                        <div className="text-sm text-ooolab_dark_2 flex-1 text-right">
                            {data.length > index && !!data[index]
                                ? ({
                                      'application/h5p': 'H5P',
                                  } as any)[data[index].file_mimetype]
                                : ''}
                        </div>
                    </div>
                    {currentData ? <H5PPublicViewer contentUid={currentData} /> : ''}
                </div>
                {loadingSlide ? (
                    <div className="flex items-center  ml-20 justify-center rounded-full w-10 h-10 shadow-ooolab_sub_item">
                        <svg
                            className="animate-spin w-5 h-5 opacity-100"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path
                                className="opacity-75"
                                fill="gray"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                    </div>
                ) : (
                    <div
                        onClick={nextSlide}
                        style={{ top: '50%', right: '7%' }}
                        className={genClassNames({
                            'bg-white cursor-pointer ml-20 flex items-center justify-center rounded-full w-10 h-10 shadow-ooolab_sub_item fixed': true,
                            invisible: index === data.length - 1 || showLoading,
                        })}
                    >
                        <Arrow reverse={false} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default H5PAssigmentsPreview;
