import { CircleSpin } from "components/UiComponents/CircleSpin";
import { PaymentGatewaysState, PaymentGatewaysType } from "constant/payment.const";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import paymentService from "services/payment.service";
import { Toastify } from "_shared";

export const PaymentGateWaysUpdateState = () => {
    const params: {id: string} = useParams();
    const history = useHistory();

    useEffect(() => {
        if (params?.id) {
            const updateIntegration = async () => {
                try {
                    const stripe = await paymentService.getIntegrationList(params?.id, {type: PaymentGatewaysType.stripe});
                    if (stripe?.data?.items?.length > 0 ) {
                        await paymentService.updateIntegration(params.id, {integration_id: stripe?.data?.items[0]?.id, connect_state: PaymentGatewaysState.connected, enabled: true});
                        Toastify.success();
                    }
                    
                } catch (error) {
                    Toastify.error();
                } finally {
                    history.push(`/workspace/${params.id}/setting?tab=e-commerce`);
                }
                
            }
            updateIntegration();
            
        }
    }, [params?.id])
    return <div className="flex w-full h-screen items-center justify-center">
        <CircleSpin color="text-primary-500" />
    </div>
}