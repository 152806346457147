import { Toastify } from '_shared';
import { SESSION_STATUS_ENUM } from 'constant/sessions.const';
import classService from 'services/class.service';
import { IClassSessionParams } from 'types/Class.type';

export type EditStateType = Record<
    number,
    {
        start_time: string;
        end_time: string;
        index: number;
        lesson?: any;
    }
>;

export const ACTIONS = {
    SET_NEW_RECORD: 'SET_NEW_RECORD',
    SET_NEW_START_RECORD: 'SET_NEW_START_RECORD',
    SET_NEW_END_RECORD: 'SET_NEW_END_RECORD',
    SET_NEW_LESSON_RECORD: 'SET_NEW_LESSON_RECORD',
    RESET: 'RESET',
};

export const initSessionState: IClassSessionParams = {
    page: 1,
    per_page: 20,
    // has_student: true,
    // has_teacher: true
};

export const updateSession = async (
    workspaceId: string,
    classId: string,
    sessionId: string,
    param: {
        start_time: string;
        end_time: string;
        lesson_id?: number;
        type: string;
        assignments?: any[];
        assignment_templates?: any[];
        import_assignment_from_lesson?: boolean;
        instructors?: any[];
        students?: any[];
        vc_link?:string;
    }
): Promise<string> => {
    await classService
        .updateSessions(workspaceId, classId, sessionId, param)
        .then(() => Toastify.success())
        .catch(() => Toastify.error());

    return sessionId;
};

export async function sequentialMap<V, R, T>(arr: V[], fn: (v: V, t: T) => Promise<R>, wpId: T): Promise<R[]> {
    const result: R[] = [];
    for (const value of arr) {
        result.push(await fn(value, wpId));
    }
    return result;
}

export type ActionType = {
    type: string;
    value: {
        id: number;
        start_time?: string;
        end_time?: string;
        lesson?: any;
        index?: number;
    };
};

export const editStateReducer = (state: EditStateType, dispatch: ActionType): EditStateType => {
    const { type, value } = dispatch;
    const { id, start_time, end_time, lesson, index } = value;
    switch (type) {
        case ACTIONS.SET_NEW_START_RECORD:
            return {
                ...state,
                [id]: {
                    ...state[id],
                    start_time,
                    index,
                },
            };
        case ACTIONS.SET_NEW_END_RECORD:
            return {
                ...state,
                [id]: {
                    ...state[id],
                    end_time,
                    index,
                },
            };
        case ACTIONS.SET_NEW_LESSON_RECORD:
            return {
                ...state,
                [id]: {
                    ...state[id],
                    lesson,
                    index,
                },
            };
        case ACTIONS.RESET:
            return {};

        default:
            break;
    }

    return state;
};

export const invalidSession = (sessionDetail) => {
return [
        SESSION_STATUS_ENUM.RESCHEDULED,
        SESSION_STATUS_ENUM.IN_PROGRESS,
        SESSION_STATUS_ENUM.CANCELLED,
        SESSION_STATUS_ENUM.COMPLETED,
    ]?.includes(sessionDetail?.display_status);
} 
