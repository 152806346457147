export enum REPORT_STATUS {
  scheduled = 'scheduled',
  released = 'released'
}

export enum SCHEDULED_TYPE {
  schedule = 'schedule',
  now = 'now'
}

export const REPORT_STATUS_LIST = (translator) => {
  return [
    {name: translator('SCHEDULED'), value: REPORT_STATUS.scheduled},
    {name: translator('RELEASED'), value: REPORT_STATUS.released}
  ]
}