import { FC, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import courseService from 'services/course.service';
import DialogComponent from 'components/Dialog/DialogComponent';
import { UserContext } from 'contexts/User/UserContext';
import { SessionEdit } from 'components/Workspace/Course/CourseBuilder/components/CourseScheduling/SessionList/components/SessionEdit/SessionEdit';
import { SessionDetails } from 'components/Workspace/Course/CourseBuilder/components/CourseScheduling/SessionList/components/SessionDetails/SessionDetails';
interface ActionMenuProp {
    refreshData: Function,
    data: any;
    isOpen: boolean;
    onClose: any;

    editLearner?: boolean;
}

const Detail: FC<ActionMenuProp> = ({
    refreshData,
    data,
    isOpen,
    onClose,
    editLearner = false,
}) => {
    const { t: translator } = useTranslation();
    const [isOpenModalDeactivate, setOpenModalDeactivate] = useState(false);
    const [isOpenModalEdit, setOpenModalEdit] = useState(false);

    const params: { id: string; userId: string } = useParams();
    const workspaceId = params?.id;
    const userId = params?.userId;
    const handleCancelSession = () => {
        const payload = {
            learner_id: userId
        }
        courseService
            .cancelSession(workspaceId, data?.course_id, data?.id, payload)
            .then(() => {
                Toastify.success('Session successfully cancelled');
                setOpenModalDeactivate(false);
                refreshData();
            })
            .catch(() => Toastify.error());
    };

    const {
        userState: { result },
    } = useContext(UserContext);

    return (
        <>
            {isOpenModalDeactivate && (
                <DialogComponent
                    title={translator('SESSION.CANCEL_SESSION')}
                    isOpen={isOpenModalDeactivate}
                    onCloseModal={() => setOpenModalDeactivate(false)}
                    styles="overflow-hidden max-w-lg px-6 !py-4"
                    child={
                        <div className="flex flex-col mt-1">
                            <div className="mt-2 text-sm text-gray-800">
                                {data?.course?.enrollment_type === 'credit'
                                    ? translator('SESSION.CANCEL_SESSIONS_HAS_CREDIT_MESSAGE')
                                    : translator('SESSION.CANCEL_A_SESSION_NONE_CREDIT_MESSAGE')
                                }
                            </div>
                            <div className="flex justify-center gap-4 mt-6 mb-2 w-full">
                                <ButtonOutline type="button" onClick={() => setOpenModalDeactivate(false)}>
                                    {translator('NO')}
                                </ButtonOutline>
                                <ButtonPrimary type="button" className='buttonPrimary-danger' onClick={handleCancelSession}>
                                    {translator('Yes')}
                                </ButtonPrimary>
                            </div>
                        </div>
                    }
                />
            )}
            {isOpen && (
                <DialogComponent
                    title={''}
                    isOpen={isOpen}
                    onCloseModal={onClose}
                    isShowClose={false}
                    styles="overflow-hidden max-w-[600px] !p-0"
                    child={
                        <SessionDetails {...data} 
                            onClose={onClose}
                            setOpenModalEdit={() => setOpenModalEdit(true)}
                            setOpenModalDeactivate={()=> setOpenModalDeactivate(true)}
                        />
                    }
                />
            )}
            {isOpenModalEdit && (
                <DialogComponent
                    title={''}
                    isOpen={isOpenModalEdit}
                    onCloseModal={() => setOpenModalEdit(false)}
                    isShowClose={false}
                    styles="!max-w-[600px] !p-0"
                    child={
                        <SessionEdit 
                            data={data} 
                            refreshData={refreshData} 
                            onDelete={() => setOpenModalDeactivate(true)} 
                            onClose={() => setOpenModalEdit(false)}
                            editLearner={editLearner}
                        />
                    }
                />
            )}
        </>
    );
};

export default Detail;
