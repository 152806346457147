import { LIBRARY_ICONS } from 'components/Library/LibraryContants';
import './File.scss';
import ReactTooltip from "react-tooltip";

export default function File({ item, isActive, onClick, translator, name }) {
    return (
        <div onClick={onClick} className={`file ${isActive ? 'active' : ''}`}>
            <img
                alt="libary-icon"
                className="library-icon"
                src={
                    item?.library_content?.object_type
                        ? LIBRARY_ICONS(translator)?.find((icon) => icon?.value === item?.library_content?.object_type)?.icon
                        : LIBRARY_ICONS(translator)[0].icon
                }
            />
            <>
                <div 
                    className="file-name"
                    data-for={`file-${item?.id}`}
                    data-tip={name}
                >
                    {name}
                </div>
                {/*@ts-ignore*/
                <ReactTooltip
                    place="top"
                    type="dark"
                    effect="float"
                    id={`file-${item?.id}`}
                    getContent={(value) => <div>{value}</div>}
                />}
            </>
        </div>
    );
}
