import { CircleSpin } from "components/UiComponents/CircleSpin";
import React, { ReactNode } from "react";
import './ButtonPrimary.scss';
type ButtonPrimaryProps = {
    type: 'button' | 'submit' | 'reset';
    onClick?: (e?: any) => void;
    disabled?: boolean;
    children: ReactNode;
    className?: string;
    ref?: any;
    form?: any;
    size?: 'M' | 'L',
    loading?: boolean
}
export const ButtonPrimary = ({ type, onClick, disabled = false, className = '', children, ref, form, size='M', loading }: ButtonPrimaryProps) => {
    return <>
        {form ? <button type={type}
            disabled={disabled}
            onClick={onClick}
            className={`buttonPrimary ${className} buttonPrimary-${size}`}
            form={form}
        >
            {children}
        </button> :
            <button type={type}
                disabled={disabled || loading}
                onClick={onClick}
                className={`buttonPrimary ${className} buttonPrimary-${size}`}
                form={form}
            >   {loading && <CircleSpin className="text-primary-500" />}
                {children}
            </button>
        }
    </>
}