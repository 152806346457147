import { GROUP } from 'constant/api.const';
import axios from './restful.service';


const createGroupService = (workspaceId: string, data: any) => {
  if (workspaceId) {
      const url = GROUP.createGroup(workspaceId);
      return axios.post(url, data);
  }
};

const deleteGroupService = (
  workspaceId: string,
  id: string
) => {
  if (workspaceId) {
    const url = GROUP.deleteGroup(workspaceId, id);
    return axios.delete(url);
  }
}

const getGroupService = (
  workspaceId: string,
  bodyData: any
) => {
  if (workspaceId) {
      const url = GROUP.getGroups(workspaceId);
      if (bodyData.name === '') delete bodyData.name
      return axios.get(url, { params: bodyData });
  }
};

const getClassSelectedService = (
  workspaceId: string,
  groupId: string,
  bodyData: any
) => {
  if (workspaceId) {
      const url = GROUP.getClassSelected(workspaceId, groupId);
      return axios.get(url, { params: bodyData });
  }
};

const getPeopleSelectedService = (
  workspaceId: string,
  groupId: string,
  bodyData: any
) => {
  if (workspaceId) {
      const url = GROUP.getPeopleSelected(workspaceId, groupId);
      return axios.get(url, { params: bodyData });
  }
};

const getGroupDetailService = (
  workspaceId: string,
  id: string
) => {
  if (workspaceId) {
      const url = GROUP.getGroupDetail(workspaceId, id);
      return axios.get(url);
  }
};

const addClassService = (workspaceId: string, groupId: string, data: any) => {
  if (workspaceId) {
      const url = GROUP.addClass(workspaceId, groupId);
      return axios.post(url, data);
  }
};

const addPeopleService = (workspaceId: string, groupId: string, data: any) => {
  if (workspaceId) {
      const url = GROUP.addPeople(workspaceId, groupId);
      return axios.post(url, data);
  }
};

const removeClassService = (workspaceId: string, groupId: string, classId: string, data: any) => {
  if (workspaceId) {
      const url = GROUP.removeClass(workspaceId, groupId, classId);
      return axios.delete(url, {data});
  }
};

const removePeopleService = (workspaceId: string, groupId: string, personId: string) => {
  if (workspaceId) {
      const url = GROUP.removePeople(workspaceId, groupId, personId);
      return axios.delete(url, {data:{} });
  }
};

const updateGroupService = (workspaceId: string, groupId: string, data) => {
  if (workspaceId) {
      const url = GROUP.updateGroup(workspaceId, groupId);
      return axios.put(url, data);
  }
};

export default {
  getGroupService,
  createGroupService,
  deleteGroupService,
  getGroupDetailService,
  addClassService,
  addPeopleService,
  removeClassService,
  removePeopleService,
  getClassSelectedService,
  getPeopleSelectedService,
  updateGroupService
}

