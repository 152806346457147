import { ACADEMICS, LEARNING_PATH } from 'constant/api.const';
import { LearningPathDetail } from 'types/LearningPath.type';
import axios from './restful.service';
import { RestfulService } from './restful.service';

const getInstructorLearningPath = (workspaceId: string, learningPathId: string, params?: any) => {
    if (workspaceId) {
        const url = LEARNING_PATH.getInstructorLearningPath(workspaceId, learningPathId);
        return axios.get(url, { params });
    }
};

const getResourcesService = (workspaceId: string, learningPathId: string, params?: any) => {
    if (workspaceId) {
        const url = LEARNING_PATH.getResources(workspaceId, learningPathId);
        return axios.get(url, { params });
    }
};

const renameResourceService = (workspaceId: string, learningPathId: string, resourceId: string, file_name: string) => {
    if (workspaceId && learningPathId && resourceId) {
        const url = LEARNING_PATH.renameResource(workspaceId, learningPathId, resourceId);
        return axios.patch(url, { file_name });
    }
};

const uploadResourcesService = (
    { workspaceId, learningPathId }: { workspaceId: string; learningPathId: string },
    bodyData: {
        h5p_content_id?: number;
        mime_type: string;
        file_extension: string;
        file_name?: string;
        file_path?: string;
    }
) => {
    if (workspaceId && learningPathId) {
        const url = LEARNING_PATH.getAndUploadResourcesUrl(workspaceId, learningPathId);
        return axios.post(url, bodyData);
    }
};

const viewResourceService = (workspaceId: string, learningPathId: string, resourceId: string) => {
    if (workspaceId && learningPathId) {
        const url = LEARNING_PATH.detailResourceUrl(workspaceId, learningPathId, resourceId);
        return axios.get(url);
    }
};

const deleteResourceService = (workspaceId: string, classId: string, resourceId: string) => {
    if (workspaceId && classId && resourceId) {
        const url = LEARNING_PATH.detailResourceUrl(workspaceId, classId, resourceId);
        return axios.delete(url);
    }
};

const getListAssignmentLearningPath = (workspaceId: string, learningPathId: string) => {
    if (workspaceId && learningPathId) {
        const url = LEARNING_PATH.getAssignmentLearningPath(workspaceId, learningPathId);
        return axios.get(url);
    }
};

const deleteAssignmentLearningPath = (workspaceId: string, classId: string, assignmentId: string) => {
    if (workspaceId && classId && assignmentId) {
        const url = LEARNING_PATH.assignmentLearningPath(workspaceId, classId, assignmentId);
        return axios.delete(url);
    }
};

const updateSettingAssignment = (workspaceId: string, classId: string, assignmentId: string, bodyData: any) => {
    if (workspaceId && classId && assignmentId) {
        const url = LEARNING_PATH.assignmentSettingLearningPath(workspaceId, classId, assignmentId);
        return axios.post(url, bodyData);
    }
};

const create = (params: LearningPathDetail) => {
    return RestfulService.postApi(ACADEMICS.getLearningPaths(params?.workspaceId), params);
};

const get = (params: LearningPathDetail) => {
    return RestfulService.getApi(`${ACADEMICS.getLearningPaths(params?.workspaceId)}/${params.id}`);
};

const update = (params: LearningPathDetail) => {
    return RestfulService.patchApi(`${ACADEMICS.getLearningPaths(params?.workspaceId)}/${params.id}`, params);
};

const addUnit =(params: LearningPathDetail) => {
    let payload = {
        title: params?.title ?? '',
        order_number: params?.order_number ?? ''
    }
    return RestfulService.postApi(`${ACADEMICS.getLearningPaths(params?.workspaceId)}/${params.id}/sections`, 
    payload);
};

const addLesson =(params: LearningPathDetail) => {
    const url = `${ACADEMICS.getLearningPaths(params?.workspaceId)}`;
    return RestfulService.postApi(`${url}/${params.id}/sections/${params.sectionId}/contents`, 
        params
    );
};

const updateUnit =(params: LearningPathDetail) => {
    let payload = {
        title: params?.title ?? ''
    }
    return RestfulService.patchApi(`${ACADEMICS.getLearningPaths(params?.workspaceId)}/${params.id}/sections/${params.sectionId}`, 
    payload);
};

const orderSections = (params) => {
    const payload = {
        ids: params?.sections.map(item => item.id)
    };
    return RestfulService.patchApi(`${ACADEMICS.getLearningPaths(params?.workspaceId)}/${params.id}/sections/sort`, 
    payload);
};

const orderLesson = (params) => {
    const payload = {
        section_id: params?.sectionId,
        ids: params?.sections.map(item => item.id)
    };
    return RestfulService.patchApi(`${ACADEMICS.getLearningPaths(params?.workspaceId)}/${params.id}/sections/${params?.sectionId}/contents/sort`, 
    payload);
};

const deleteUnit =(params: LearningPathDetail) => {
    return RestfulService.deleteApi(`${ACADEMICS.getLearningPaths(params?.workspaceId)}/${params.id}/sections/${params.sectionId}`);
};

const deleteLesson = (params: LearningPathDetail) => {
    const url = `${ACADEMICS.getLearningPaths(params?.workspaceId)}`;
    return RestfulService.deleteApi(`${url}/${params.id}/sections/${params.sectionId}/contents/${params?.contentId}`);
};
const updateLearningPath = (workspaceId: string, learningPathId: string, bodyData: any) => {
    const url = LEARNING_PATH.updateLearningPath(workspaceId, learningPathId)
    return axios.patch(url, bodyData);
}

const duplicateLearningPath = (workspaceId: string, learningPathId: string) => {
    const url = LEARNING_PATH.duplicateLearningPath(workspaceId, learningPathId)
    return axios.post(url);
}

const getlearningPathDetail = (workspaceId: string, learningPathId: string) => {
    const url = LEARNING_PATH.getlearningPathDetail(workspaceId, learningPathId)
    return axios.get(url);
}

const getLearningPathCustomField = (workspaceId: string, learningPathId: string, params: any) => {
    const url = LEARNING_PATH.getLearningPathCustomField(workspaceId, learningPathId)
    return axios.get(url, { params });
}

const updateCustomFieldLearningPath = (workspaceId: string, learningPathId: string, bodyData: any) => {
    const url = LEARNING_PATH.getLearningPathCustomField(workspaceId, learningPathId)
    return axios.post(url, bodyData);
}

const getLearningPathAssociatedCourses = (workspaceId: string, learningPathId: string, params?: any) => {
    const url = LEARNING_PATH.getAssociatedCourses(workspaceId, learningPathId)
    return axios.get(url, { params });
}

export default {
    getInstructorLearningPath,
    getResourcesService,
    renameResourceService,
    uploadResourcesService,
    viewResourceService,
    deleteResourceService,
    getListAssignmentLearningPath,
    deleteAssignmentLearningPath,
    updateSettingAssignment,
    create,
    get,
    update,
    addUnit,
    updateUnit,
    deleteUnit,
    addLesson,
    deleteLesson,
    orderSections,
    orderLesson,
    updateLearningPath,
    duplicateLearningPath,
    getlearningPathDetail,
    getLearningPathCustomField,
    updateCustomFieldLearningPath,
    getLearningPathAssociatedCourses,
};
