import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classesService from "services/reports/classes-report.service";
import { CardContainer } from "components/Reports/components/CardContainer";
import { CardInfo } from "components/Reports/components/CardInfo";
import { Title } from "components/Reports/components/Title";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import DatePickerComponent from "components/Reports/components/DatePicker";
import moment from "moment";

export const ClassSessionsOverview = () => {
    const { t } = useTranslation();
    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);
    const params: { id: string, classId: string } = useParams();
    const [loading, setLoading] = useState(true);
    const [overview, setOverview] = useState<any>();

    const getData = async () => {
        if (!startDate || !endDate) {
            return;
        }
        try {
            setLoading(true);
            const payload = {
                start_time: moment(new Date(startDate)).format('YYYY-MM-DD')+ 'T00:00:00.000',
                end_time: moment(new Date(endDate)).format('YYYY-MM-DD')+ 'T23:59:59.000',
                classId: params?.classId,
            }
            const response = await classesService.getClassSessionsById(params.id, payload);
            setOverview(response?.data);
            setLoading(false);
        } catch {
            setLoading(false);
        }
    }
    const [startDate, setStartDate] = useState(new Date(moment().subtract(1, 'months').format('MM-DD-YYYY')));
    const [endDate, setEndDate] = useState(new Date());
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    useEffect(() => {
        getData();
    }, [startDate, endDate]);

    return <>
        <CardContainer>
            <div className="flex items-center">
                <Title>{translator('Overview')}</Title>
                <div className="ml-auto min-w-[132px]">
                    <DatePickerComponent onChange={onChange} startDate={startDate} endDate={endDate} />
                </div>
            </div>
            <div className="mt-4 min-h-[260px]">
                {loading ?
                    <div className="flex justify-center items-center w-full min-h-[123px]">
                        <CircleSpin color="text-primary-500" />
                    </div> :
                    <>
                        <div className="flex items-center space-x-5">
                            <div className="w-1/3">
                                <CardInfo {...{
                                    value: overview?.total_sessions || 0,
                                    percent: overview?.total_sessions_changes_percent || 0,
                                    title: translator('Total Sessions'),
                                }}  />
                            </div>
                            <div className="w-1/3">
                                <CardInfo {...{
                                    value: overview?.total_booked || 0,
                                    percent: overview?.total_booked_changes_percent || 0,
                                    title: translator('Total Booked')
                                }}  />
                            </div>
                            <div className="w-1/3">
                                <CardInfo {...{
                                    value: overview?.total_completed || 0,
                                    percent: overview?.total_completed_changes_percent || 0,
                                    title: translator('Total Completed')
                                }}  />
                            </div>
                        </div>
                        <div className="flex items-center space-x-5 mt-5">
                            <div className="w-1/3">
                                <CardInfo {...{
                                    value: overview?.total_rescheduled || 0,
                                    percent: overview?.total_rescheduled_changes_percent || 0,
                                    title: translator('Total Rescheduled')
                                }}  />
                            </div>
                            <div className="w-1/3">
                                <CardInfo {...{
                                    value: overview?.total_cancellation || 0,
                                    percent: overview?.total_cancellation_changes_percent || 0,
                                    title: translator('Total Cancellations')
                                }}  />
                            </div>
                            <div className="w-1/3">
                                
                            </div>
                        </div>
                    </>
                }
            </div>
        </CardContainer>
    </>
}