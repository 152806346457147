import { FC, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ButtonComponent from 'components/Button/ButtonComponent';
import { useParams } from 'react-router-dom';
import { AsyncPaginate } from 'react-select-async-paginate';
import { components } from 'react-select';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { ClassService } from 'services';
import { customSelect, MultiValue, ValueContainer } from '../util';
import { SelectField } from 'components/Select';
import classService from 'services/class.service';
import { CheckIcon } from '@heroicons/react/outline';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { Toastify } from '_shared';

type ModalEnrollInstructorProps = {
    translator: (key: string, newKey?: boolean) => string;
    fetchData: () => void;
    onCloseModal: () => void
};

const ModalEnrollInstructor: FC<ModalEnrollInstructorProps> = ({ translator, fetchData, onCloseModal}) => {
    const [error, setError] = useState<any> ()
    const param: { id: string; classId: string } = useParams();
    const { dispatch: dispatchWorkspace } = useContext(GetWorkspaceContext);
    const [loading, setLoading] = useState(false)
    const handleLoadOptionsInstructors = async (inputValue, options, additional) => {
        const params: any = {
            page: additional.page,
            per_page: 20,
            type: 'instructor',
            q: inputValue || undefined,
            languages: watch("language")?.value,
            certifications: watch("certificate")?.map((cer) => cer?.value)?.join(',')
        };

        const { data : {items, total} } = await ClassService.getTeacherListService( param.id, params);
        let newOptions = [];

        if (items?.length > 0) {
            newOptions = items?.map((el) => ({
                ...el,
                id: el?.user?.id,
                label: el.user?.display_name,
                value: el.user?.email,
            }));
        }

        return {
            options: newOptions,
            hasMore: additional.page < total / 20,
            additional: {
                page: additional.page + 1,
            },
        };
    };

    const params: { id: string; classId: string } = useParams();

    const onSubmitForm = (val) => {
        setLoading(true)
        if (val?.instructor?.length) {
            let isMeAsTeacher = false;
            const listEmailInvite = val?.instructor?.map((i) => {
                return {
                    email: i.value,
                    type: 'instructor',
                };
            });
            
            workspaceMiddleware.inviteUserClasses(
                dispatchWorkspace,
                params.id,
                params.classId,
                {
                    members: listEmailInvite,
                    typeInvite: 'instructor'
                },
                isMeAsTeacher,
                () => {
                    setLoading(false);
                    onCloseModal();
                    fetchData()
                    Toastify.success();
                },
                (error) => {
                    const instructorAlreadyInvited = error.error.body_params?.map(err => {
                        return listEmailInvite?.[err?.loc?.[1]]
                    })
                    if (instructorAlreadyInvited?.length) {
                        const listEmailStudentAlreadyInvited = instructorAlreadyInvited?.map(student => student?.email)
                        setError(listEmailStudentAlreadyInvited)
                    }
                    Toastify.error(translator("INVITEL_INSTRUCTOR_ERROR")),
                    setLoading(false)
                }
            )
        }
    };

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        watch,
        control
    } = useForm({mode: 'onChange'});

    const [languages, setLanguages] = useState([]);
    const [certifications, setCertifications] = useState([]);

    const getLanguages = async () => {
        const resLanguagle = await classService.getLanguagesOptionService(params?.id, { skip_pagination: true });
        const optionLanguage = resLanguagle?.data?.items?.map((item) => ({ label: item?.name, value: item?.id }));
        setLanguages(optionLanguage);
    };

    const getCertifications = async () => {
        const resCertifications = await classService.getCertificationOptionService(params?.id);
        const optionCertifications = resCertifications?.data?.items?.map((item) => ({ label: item?.name, value: item?.id }));
        setCertifications(optionCertifications);
    };

    useEffect(() => {
        getLanguages();
        getCertifications()
    }, [])

    useEffect(() => {
        setValue('instructor', [])
    }, [watch('language'), watch('certificate')])

    useEffect(() => {
        const newError = error?.filter(email => {
          return !!watch('instructor')?.find(student => student?.value === email)
        })
        setError(newError)
      }, [watch('instructor')])

    return (
        <form onSubmit={handleSubmit(onSubmitForm)}>
            <SelectField
                control={control}
                label={translator('LANGUAGE')}
                name="language"
                placeholder={translator('SELECT_LANGUAGE')}
                options={languages}
                errors={errors}
                isRequired
            /> 

            <SelectField
                control={control}
                label={translator('CERTIFICATES')}
                name="certificate"
                className='mt-3'
                placeholder={translator('SELECT_CERTIFICATES')}
                isMulti
                options={certifications}
            />

           <div className='mt-3'>
                <label className="text-xs text-left font-medium mb-1 mt-3">
                    {translator('INSTRUCTORS')} <span className="text-red-500 ml-1">*</span>
                </label>

                <Controller
                    control={control}
                    name="instructor"
                    {
                        ...register('instructor', {
                            required: {
                                value: true,
                                message: translator(
                                    'FORM_CONST.REQUIRED_FIELD', true
                                ),
                            },
                        })
                    }
                    render={({ field: { onChange, value}}) => {
                        return (
                            <AsyncPaginate
                                loadOptions={handleLoadOptionsInstructors}
                                additional={{
                                    page: 1,
                                }}
                                debounceTimeout={500}
                                styles={customSelect}
                                onChange={onChange}                            
                                isMulti
                                isDisabled={!watch('language')}
                                isClearable
                                placeholder="Select Instructors"
                                value={value}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                menuPlacement="auto"
                                className={`${(watch('instructor')?.length > 5 || error?.length) ? '!border-red-500 !border !rounded focus-visible:!border-red-500' : ''}`}
                                key={JSON.stringify(watch('language')) + JSON.stringify(watch('certificate'))}
                                components={{
                                    Option: ({ isDisabled, isFocused, isSelected, children, ...props }) => {
                                        // FIX LAG
                                        const [isActive, setIsActive] = useState(false);
                                        const onMouseDown = () => setIsActive(true);
                                        const onMouseUp = () => setIsActive(false);
                                        const onMouseLeave = () => setIsActive(false);
                                        let bg = 'transparent';
                                        if (isFocused) bg = '#eee';
                                        if (isActive) bg = '#B2D4FF';
                                        const style = {
                                            alignItems: 'center',
                                            backgroundColor: bg,
                                            color: 'inherit',
                                            display: 'flex ',
                                        };
                
                                        const propsNew = {
                                            ...props.innerProps,
                                            onMouseDown,
                                            onMouseUp,
                                            onMouseLeave,
                                            style,
                                        };
                                        const newProps = Object.assign(props, {
                                            innerProps: propsNew,
                                            isSelected,
                                            isDisabled,
                                            isFocused,
                                        });
                
                                        const { data } = props;
                
                                        return (
                                            <components.Option {...newProps}>
                                                <div className='flex justify-between w-full'>
                                                    <div className='flex'>
                                                        <img
                                                            className={`rounded-full w-5 h-5 mr-2`}
                                                            src={data?.user?.avatar_url}
                                                            alt="img"
                                                        />
                                                        {children}
                                                    </div>
                                                    {isSelected && <CheckIcon className='text-gray-500 w-4 h-4'/>}
                                                </div>
                                            </components.Option>
                                        );
                                    },
                                    ValueContainer,
                                    MultiValue,
                                }}
                            />
                        )
                    }}
                />
                {
                    watch('instructor')?.length > 5 && 
                    <p className='text-xs text-red-500 mt-1'>{translator("LIMIT_REACHED")}</p>
                }
                {error?.length > 0 &&
                    <div className='text-xs text-red-500 mt-1'>
                        <p>{translator("VALIDATE_INSTRUCTOR")}</p>
                        <div className='ml-4'>
                            {
                                error?.map(email => {
                                    return <p className='flex items-center mt-1'>• {email}</p>
                                })
                            }
                        </div>
                    </div>
                }
           </div>

            <div className="flex justify-center gap-4">
                <ButtonComponent onClickButton={onCloseModal} title={translator('CANCEL')} classStyle="xs:p-2 xs:text-sm secondary-button !text-gray-800 !border-gray-300 mt-4" />
                <ButtonComponent 
                    icon={loading && <CircleSpin className='mr-1 w-2.5 h-2.5' />} 
                    disable={!isValid || loading || error?.length || watch('instructor')?.length > 5} 
                    type="submit" title={translator('INVITE')} 
                    classStyle="xs:p-2 xs:text-sm primary-button !bg-enterprise mt-4 disabled:!bg-gray-300" />
            </div>  
        </form>
    );
};

export default ModalEnrollInstructor;
