import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import Table from './Table';
import { useDebouncedState } from 'hooks/useDebounce';
import { getIndexPage } from 'utils/commonFun';
import SearchBox from 'components/V2/SearchBox';
import Spinner from 'components/Spinner';
import workspaceService from 'services/workspace.service';
import Pagination from 'components/V2/Pagination';
import { STATUS_LEARNER_ENROLL_OPTION } from 'components/Workspace/Course/CourseBuilder/components/CoursePeople/constants';
import SelectBox from 'components/V2/SelectBox';

const capitalized = (word = '') => {
    return word.charAt(0).toUpperCase()
  + word.slice(1)
}

const index: React.FC = ({ children }) => {
    const params: any = useParams();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [indexPage, setIndexPage] = useState('0-0');
    const [keyword, setKeyword] = useDebouncedState('');
    const [data, setData] = useState([]);
    const [isAscName, setAscName] = useState(false)
    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 20,
        order: 'asc',
        sort_by: 'name',
        total: 0,
    });

    const workspaceId = params?.id;
    const learningGroupId = params?.groupId;
    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`LEARNING_GROUP.${str}`, opt));
    const search = useLocation()?.search;
    const defaultStatus = new URLSearchParams(search).get('statuses') || '';
    const [statusSelected, setStatusSelected] = useState<any>();
    const fetchData = (page: number | string = 1) => {
        setLoading(true);
        workspaceService.getEnrollmentOfGroupService(workspaceId, learningGroupId, {
            q: keyword,
            ...pagination,
            order: isAscName? 'asc' : 'desc',
            page,
            statuses: statusSelected?.map(item => item?.value)?.join(',')
        })
            .then((res) => {
                if (res?.data) {
                    const { items, page, per_page, order, sort_by, total } = res?.data;
                    setData(items);
                    setPagination({ page, per_page, order, sort_by, total });
                    setIndexPage(getIndexPage(page, per_page, items?.length));
                }
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const handlePagination = (page: number) => {
        fetchData(page);
    };

    const refreshData = () => {
        fetchData(pagination?.page);
    };


    useEffect(() => {
        if(defaultStatus) {
            setStatusSelected(defaultStatus?.split(',')?.map(item => ({name: capitalized(item), value: item})));
        }
    }, [defaultStatus])

    useEffect(() => {
        fetchData();
        return () => {
            setData([]);
        };
    }, [keyword, isAscName, statusSelected]);
    
    return (
        <div className="w-full">
            <div className="flex gap-4 p-4">
                <SearchBox className='max-w-md' onSubmit={(e: any) => setKeyword(e)} />
                <SelectBox
                    label={t("Enrollment Status")}
                    onSelect={setStatusSelected}
                    selectedItems={statusSelected}
                    data={STATUS_LEARNER_ENROLL_OPTION(t)}
                    total={4}
                    isMulti={true}
                />
            </div>
            <div className="px-4">
                <div className="h-[calc(100vh-280px)] custom-scrollbar overflow-auto">
                    {loading ? (
                        <div className="h-[calc(100vh-280px)] flex justify-center items-center">
                            <Spinner />
                        </div>
                    ) : (
                        <Table 
                            setAscName={setAscName} 
                            isAscName={isAscName} 
                            workspaceId={workspaceId} 
                            dataTable={data} 
                            translator={translator} 
                            refreshData={refreshData}
                        />
                    )}
                </div>
                <Pagination
                    indexPage={indexPage}
                    total={pagination.total}
                    per_page={pagination.per_page}
                    page={pagination.page}
                    callback={handlePagination}
                />
            </div>
        </div>
    );
};

export default index;
