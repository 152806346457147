export const WarningIcon = ({
    width = '36px',
    height = '31',
}: {
    width?: string;
    height?: string;
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 36 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.4437 1.00001L35.3203 28.5C35.4666 28.7534 35.5436 29.0408 35.5436 29.3333C35.5436 29.6259 35.4666 29.9133 35.3203 30.1667C35.1741 30.42 34.9637 30.6304 34.7103 30.7767C34.4569 30.923 34.1695 31 33.877 31H2.12366C1.8311 31 1.5437 30.923 1.29034 30.7767C1.03698 30.6304 0.826587 30.42 0.680312 30.1667C0.534036 29.9133 0.457029 29.6259 0.457031 29.3333C0.457033 29.0408 0.534044 28.7534 0.680322 28.5L16.557 1.00001C16.7033 0.746662 16.9137 0.536284 17.167 0.390018C17.4204 0.243751 17.7078 0.166748 18.0003 0.166748C18.2929 0.166748 18.5803 0.243751 18.8336 0.390018C19.087 0.536284 19.2974 0.746662 19.4437 1.00001ZM5.01032 27.6667H30.9903L18.0003 5.16667L5.01032 27.6667ZM16.3337 22.6667H19.667V26H16.3337V22.6667ZM16.3337 11H19.667V19.3333H16.3337V11Z"
                fill="currentColor"
            />
        </svg>
    );
};
