export const countryAndLanguage = {
    languageFamilies: [
        'Northwest Caucasian',
        'Afro-Asiatic',
        'Indo-European',
        'Niger–Congo',
        'Northeast Caucasian',
        'Aymaran',
        'Turkic',
        'Language isolate',
        'Creole',
        'Sino-Tibetan',
        'Austronesian',
        'Algonquian',
        'Constructed',
        'Uralic',
        'South Caucasian',
        'Tupian',
        'Eskimo–Aleut',
        'Japonic',
        'Dravidian',
        'Nilo-Saharan',
        'Austroasiatic',
        'Koreanic',
        'Tai–Kadai',
        'Mongolic',
        'Dené–Yeniseian',
        'Niger-Congo',
        'Quechuan',
    ],
    languages: [
        {
            code: 'en',
            name: 'English'
        },
        {
            code: 'vi',
            name: 'Tiếng Việt',
        },
        {
            code: 'fr',
            name: 'Français',
        }
    ],
    countries: [
        {
            code_2: 'AF',
            code_3: 'AFG',
            numCode: '004',
            name: 'Afghanistan',
            languages: ['prs', 'pus', 'fas'],
        },
        {
            code_2: 'AX',
            code_3: 'ALA',
            numCode: '248',
            name: 'Åland Islands',
            languages: ['swe'],
        },
        {
            code_2: 'AL',
            code_3: 'ALB',
            numCode: '008',
            name: 'Albania',
            languages: ['sqi'],
            langCultureMs: [
                {
                    langCultureName: 'sq-AL',
                    displayName: 'Albanian - Albania',
                    cultureCode: '0x041C',
                },
            ],
        },
        {
            code_2: 'DZ',
            code_3: 'DZA',
            numCode: '012',
            name: 'Algeria',
            languages: ['ara', 'ber'],
            langCultureMs: [
                {
                    langCultureName: 'ar-DZ',
                    displayName: 'Arabic - Algeria',
                    cultureCode: '0x1401',
                },
            ],
        },
        {
            code_2: 'AS',
            code_3: 'ASM',
            numCode: '016',
            name: 'American Samoa',
        },
        {
            code_2: 'AD',
            code_3: 'AND',
            numCode: '020',
            name: 'Andorra',
            languages: ['cat'],
        },
        {
            code_2: 'AO',
            code_3: 'AGO',
            numCode: '024',
            name: 'Angola',
            languages: ['kon', 'kmb', 'kua', 'por', 'umb'],
        },
        {
            code_2: 'AI',
            code_3: 'AIA',
            numCode: '660',
            name: 'Anguilla',
        },
        {
            code_2: 'AQ',
            code_3: 'ATA',
            numCode: '010',
            name: 'Antarctica',
        },
        {
            code_2: 'AG',
            code_3: 'ATG',
            numCode: '028',
            name: 'Antigua and Barbuda',
            languages: ['eng'],
        },
        {
            code_2: 'AR',
            code_3: 'ARG',
            numCode: '032',
            name: 'Argentina',
            languages: ['spa'],
            langCultureMs: [
                {
                    langCultureName: 'es-AR',
                    displayName: 'Spanish - Argentina',
                    cultureCode: '0x2C0A',
                },
            ],
        },
        {
            code_2: 'AM',
            code_3: 'ARM',
            numCode: '051',
            name: 'Armenia',
            languages: ['hye'],
            langCultureMs: [
                {
                    langCultureName: 'hy-AM',
                    displayName: 'Armenian - Armenia',
                    cultureCode: '0x042B',
                },
            ],
        },
        {
            code_2: 'AW',
            code_3: 'ABW',
            numCode: '533',
            name: 'Aruba',
            languages: ['nld', 'pap'],
        },
        {
            code_2: 'AU',
            code_3: 'AUS',
            numCode: '036',
            name: 'Australia',
            languages: ['eng'],
            langCultureMs: [
                {
                    langCultureName: 'en-AU',
                    displayName: 'English - Australia',
                    cultureCode: '0x0C09',
                },
            ],
        },
        {
            code_2: 'AT',
            code_3: 'AUT',
            numCode: '040',
            name: 'Austria',
            languages: ['deu'],
            langCultureMs: [
                {
                    langCultureName: 'de-AT',
                    displayName: 'German - Austria',
                    cultureCode: '0x0C07',
                },
            ],
        },
        {
            code_2: 'AZ',
            code_3: 'AZE',
            numCode: '031',
            name: 'Azerbaijan',
            languages: ['aze'],
            langCultureMs: [
                {
                    langCultureName: 'Cy-az-AZ',
                    displayName: 'Azeri (Cyrillic) - Azerbaijan',
                    cultureCode: '0x082C',
                },
                {
                    langCultureName: 'Lt-az-AZ',
                    displayName: 'Azeri (Latin) - Azerbaijan',
                    cultureCode: '0x042C',
                },
            ],
        },
        {
            code_2: 'BS',
            code_3: 'BHS',
            numCode: '044',
            name: 'Bahamas',
            languages: ['eng'],
        },
        {
            code_2: 'BH',
            code_3: 'BHR',
            numCode: '048',
            name: 'Bahrain',
            languages: ['ara'],
            langCultureMs: [
                {
                    langCultureName: 'ar-BH',
                    displayName: 'Arabic - Bahrain',
                    cultureCode: '0x3C01',
                },
            ],
        },
        {
            code_2: 'BD',
            code_3: 'BGD',
            numCode: '050',
            name: 'Bangladesh',
            languages: ['ben'],
        },
        {
            code_2: 'BB',
            code_3: 'BRB',
            numCode: '052',
            name: 'Barbados',
            languages: ['eng'],
        },
        {
            code_2: 'BY',
            code_3: 'BLR',
            numCode: '112',
            name: 'Belarus',
            languages: ['bel', 'rus'],
            langCultureMs: [
                {
                    langCultureName: 'be-BY',
                    displayName: 'Belarusian - Belarus',
                    cultureCode: '0x0423',
                },
            ],
        },
        {
            code_2: 'BE',
            code_3: 'BEL',
            numCode: '056',
            name: 'Belgium',
            languages: ['nld', 'fra', 'deu'],
            langCultureMs: [
                {
                    langCultureName: 'nl-BE',
                    displayName: 'Dutch - Belgium',
                    cultureCode: '0x0813',
                },
                {
                    langCultureName: 'fr-BE',
                    displayName: 'French - Belgium',
                    cultureCode: '0x080C',
                },
            ],
        },
        {
            code_2: 'BZ',
            code_3: 'BLZ',
            numCode: '084',
            name: 'Belize',
            languages: ['eng'],
            langCultureMs: [
                {
                    langCultureName: 'en-BZ',
                    displayName: 'English - Belize',
                    cultureCode: '0x2809',
                },
            ],
        },
        {
            code_2: 'BJ',
            code_3: 'BEN',
            numCode: '204',
            name: 'Benin',
            languages: ['fra', 'ful', 'yor'],
        },
        {
            code_2: 'BM',
            code_3: 'BMU',
            numCode: '060',
            name: 'Bermuda',
        },
        {
            code_2: 'BT',
            code_3: 'BTN',
            numCode: '064',
            name: 'Bhutan',
            languages: ['dzo'],
        },
        {
            code_2: 'BO',
            code_3: 'BOL',
            numCode: '068',
            name: 'Bolivia, Plurinational State of',
            languages: ['aym', 'grn', 'que', 'spa'],
            langCultureMs: [
                {
                    langCultureName: 'es-BO',
                    displayName: 'Spanish - Bolivia',
                    cultureCode: '0x400A',
                },
            ],
        },
        {
            code_2: 'BQ',
            code_3: 'BES',
            numCode: '535',
            name: 'Bonaire, Sint Eustatius and Saba',
        },
        {
            code_2: 'BA',
            code_3: 'BIH',
            numCode: '070',
            name: 'Bosnia and Herzegovina',
            languages: ['bos', 'hrv', 'srp'],
        },
        {
            code_2: 'BW',
            code_3: 'BWA',
            numCode: '072',
            name: 'Botswana',
            languages: ['eng', 'tsn'],
        },
        {
            code_2: 'BV',
            code_3: 'BVT',
            numCode: '074',
            name: 'Bouvet Island',
        },
        {
            code_2: 'BR',
            code_3: 'BRA',
            numCode: '076',
            name: 'Brazil',
            languages: ['por'],
            langCultureMs: [
                {
                    langCultureName: 'pt-BR',
                    displayName: 'Portuguese - Brazil',
                    cultureCode: '0x0416',
                },
            ],
        },
        {
            code_2: 'IO',
            code_3: 'IOT',
            numCode: '086',
            name: 'British Indian Ocean Territory',
        },
        {
            code_2: 'BN',
            code_3: 'BRN',
            numCode: '096',
            name: 'Brunei Darussalam',
            languages: ['msa'],
            langCultureMs: [
                {
                    langCultureName: 'ms-BN',
                    displayName: 'Malay - Brunei',
                    cultureCode: '0x083E',
                },
            ],
        },
        {
            code_2: 'BG',
            code_3: 'BGR',
            numCode: '100',
            name: 'Bulgaria',
            languages: ['bul'],
            langCultureMs: [
                {
                    langCultureName: 'bg-BG',
                    displayName: 'Bulgarian - Bulgaria',
                    cultureCode: '0x0402',
                },
            ],
        },
        {
            code_2: 'BF',
            code_3: 'BFA',
            numCode: '854',
            name: 'Burkina Faso',
            languages: ['fra', 'ful', 'mos'],
        },
        {
            code_2: 'BI',
            code_3: 'BDI',
            numCode: '108',
            name: 'Burundi',
            languages: ['fra', 'run'],
        },
        {
            code_2: 'KH',
            code_3: 'KHM',
            numCode: '116',
            name: 'Cambodia',
            languages: ['khm'],
        },
        {
            code_2: 'CM',
            code_3: 'CMR',
            numCode: '120',
            name: 'Cameroon',
            languages: ['eng', 'fra'],
        },
        {
            code_2: 'CA',
            code_3: 'CAN',
            numCode: '124',
            name: 'Canada',
            languages: ['eng', 'fra'],
            langCultureMs: [
                {
                    langCultureName: 'en-CA',
                    displayName: 'English - Canada',
                    cultureCode: '0x1009',
                },
                {
                    langCultureName: 'fr-CA',
                    displayName: 'French - Canada',
                    cultureCode: '0x0C0C',
                },
            ],
        },
        {
            code_2: 'CV',
            code_3: 'CPV',
            numCode: '132',
            name: 'Cape Verde',
            languages: ['por'],
        },
        {
            code_2: 'KY',
            code_3: 'CYM',
            numCode: '136',
            name: 'Cayman Islands',
        },
        {
            code_2: 'CF',
            code_3: 'CAF',
            numCode: '140',
            name: 'Central African Republic',
            languages: ['fra', 'sag'],
        },
        {
            code_2: 'TD',
            code_3: 'TCD',
            numCode: '148',
            name: 'Chad',
            languages: ['ara', 'fra'],
        },
        {
            code_2: 'CL',
            code_3: 'CHL',
            numCode: '152',
            name: 'Chile',
            languages: ['spa'],
            langCultureMs: [
                {
                    langCultureName: 'es-CL',
                    displayName: 'Spanish - Chile',
                    cultureCode: '0x340A',
                },
            ],
        },
        {
            code_2: 'CN',
            code_3: 'CHN',
            numCode: '156',
            name: 'China',
            languages: ['zho'],
            langCultureMs: [
                {
                    langCultureName: 'zh-CN',
                    displayName: 'Chinese - China',
                    cultureCode: '0x0804',
                },
                {
                    langCultureName: 'zh-CHS',
                    displayName: 'Chinese (Simplified)',
                    cultureCode: '0x0004',
                },
                {
                    langCultureName: 'zh-CHT',
                    displayName: 'Chinese (Traditional)',
                    cultureCode: '0x7C04',
                },
            ],
        },
        {
            code_2: 'CX',
            code_3: 'CXR',
            numCode: '162',
            name: 'Christmas Island',
        },
        {
            code_2: 'CC',
            code_3: 'CCK',
            numCode: '166',
            name: 'Cocos (Keeling) Islands',
        },
        {
            code_2: 'CO',
            code_3: 'COL',
            numCode: '170',
            name: 'Colombia',
            languages: ['spa'],
            langCultureMs: [
                {
                    langCultureName: 'es-CO',
                    displayName: 'Spanish - Colombia',
                    cultureCode: '0x240A',
                },
            ],
        },
        {
            code_2: 'KM',
            code_3: 'COM',
            numCode: '174',
            name: 'Comoros',
            languages: ['ara', 'fra'],
        },
        {
            code_2: 'CG',
            code_3: 'COG',
            numCode: '178',
            name: 'Congo',
            languages: ['fra', 'kon', 'lin'],
        },
        {
            code_2: 'CD',
            code_3: 'COD',
            numCode: '180',
            name: 'Congo, the Democratic Republic of the',
            languages: ['fra', 'kon', 'lin', 'swa', 'lub'],
        },
        {
            code_2: 'CK',
            code_3: 'COK',
            numCode: '184',
            name: 'Cook Islands',
        },
        {
            code_2: 'CR',
            code_3: 'CRI',
            numCode: '188',
            name: 'Costa Rica',
            languages: ['spa'],
            langCultureMs: [
                {
                    langCultureName: 'es-CR',
                    displayName: 'Spanish - Costa Rica',
                    cultureCode: '0x140A',
                },
            ],
        },
        {
            code_2: 'CI',
            code_3: 'CIV',
            numCode: '384',
            name: "Côte d'Ivoire",
            languages: ['fra'],
        },
        {
            code_2: 'HR',
            code_3: 'HRV',
            numCode: '191',
            name: 'Croatia',
            languages: ['hrv', 'ita'],
            langCultureMs: [
                {
                    langCultureName: 'hr-HR',
                    displayName: 'Croatian - Croatia',
                    cultureCode: '0x041A',
                },
            ],
        },
        {
            code_2: 'CU',
            code_3: 'CUB',
            numCode: '192',
            name: 'Cuba',
            languages: ['spa'],
        },
        {
            code_2: 'CW',
            code_3: 'CUW',
            numCode: '531',
            name: 'Curaçao',
            languages: ['nld', 'eng', 'pap'],
        },
        {
            code_2: 'CY',
            code_3: 'CYP',
            numCode: '196',
            name: 'Cyprus',
            languages: ['ell', 'tur'],
        },
        {
            code_2: 'CZ',
            code_3: 'CZE',
            numCode: '203',
            name: 'Czech Republic',
            languages: ['ces', 'slk'],
            langCultureMs: [
                {
                    langCultureName: 'cs-CZ',
                    displayName: 'Czech - Czech Republic',
                    cultureCode: '0x0405',
                },
            ],
        },
        {
            code_2: 'DK',
            code_3: 'DNK',
            numCode: '208',
            name: 'Denmark',
            languages: ['dan'],
            langCultureMs: [
                {
                    langCultureName: 'da-DK',
                    displayName: 'Danish - Denmark',
                    cultureCode: '0x0406',
                },
            ],
        },
        {
            code_2: 'DJ',
            code_3: 'DJI',
            numCode: '262',
            name: 'Djibouti',
            languages: ['aar', 'ara', 'fra', 'som'],
        },
        {
            code_2: 'DM',
            code_3: 'DMA',
            numCode: '212',
            name: 'Dominica',
            languages: ['eng'],
        },
        {
            code_2: 'DO',
            code_3: 'DOM',
            numCode: '214',
            name: 'Dominican Republic',
            languages: ['spa'],
            langCultureMs: [
                {
                    langCultureName: 'es-DO',
                    displayName: 'Spanish - Dominican Republic',
                    cultureCode: '0x1C0A',
                },
            ],
        },
        {
            code_2: 'EC',
            code_3: 'ECU',
            numCode: '218',
            name: 'Ecuador',
            languages: ['spa'],
            langCultureMs: [
                {
                    langCultureName: 'es-EC',
                    displayName: 'Spanish - Ecuador',
                    cultureCode: '0x300A',
                },
            ],
        },
        {
            code_2: 'EG',
            code_3: 'EGY',
            numCode: '818',
            name: 'Egypt',
            languages: ['ara'],
            langCultureMs: [
                {
                    langCultureName: 'ar-EG',
                    displayName: 'Arabic - Egypt',
                    cultureCode: '0x0C01',
                },
            ],
        },
        {
            code_2: 'SV',
            code_3: 'SLV',
            numCode: '222',
            name: 'El Salvador',
            languages: ['spa'],
            langCultureMs: [
                {
                    langCultureName: 'es-SV',
                    displayName: 'Spanish - El Salvador',
                    cultureCode: '0x440A',
                },
            ],
        },
        {
            code_2: 'GQ',
            code_3: 'GNQ',
            numCode: '226',
            name: 'Equatorial Guinea',
            languages: ['fra', 'por', 'spa'],
        },
        {
            code_2: 'ER',
            code_3: 'ERI',
            numCode: '232',
            name: 'Eritrea',
            languages: ['ara', 'eng', 'tir'],
        },
        {
            code_2: 'EE',
            code_3: 'EST',
            numCode: '233',
            name: 'Estonia',
            languages: ['est'],
            langCultureMs: [
                {
                    langCultureName: 'et-EE',
                    displayName: 'Estonian - Estonia',
                    cultureCode: '0x0425',
                },
            ],
        },
        {
            code_2: 'ET',
            code_3: 'ETH',
            numCode: '231',
            name: 'Ethiopia',
            languages: ['amh'],
        },
        {
            code_2: 'FK',
            code_3: 'FLK',
            numCode: '238',
            name: 'Falkland Islands (Malvinas)',
        },
        {
            code_2: 'FO',
            code_3: 'FRO',
            numCode: '234',
            name: 'Faroe Islands',
            languages: ['dan'],
            langCultureMs: [
                {
                    langCultureName: 'fo-FO',
                    displayName: 'Faroese - Faroe Islands',
                    cultureCode: '0x0438',
                },
            ],
        },
        {
            code_2: 'FJ',
            code_3: 'FJI',
            numCode: '242',
            name: 'Fiji',
            languages: ['eng', 'fij', 'hin', 'urd'],
        },
        {
            code_2: 'FI',
            code_3: 'FIN',
            numCode: '246',
            name: 'Finland',
            languages: ['fin', 'swe'],
            langCultureMs: [
                {
                    langCultureName: 'fi-FI',
                    displayName: 'Finnish - Finland',
                    cultureCode: '0x040B',
                },
                {
                    langCultureName: 'sv-FI',
                    displayName: 'Swedish - Finland',
                    cultureCode: '0x081D',
                },
            ],
        },
        {
            code_2: 'FR',
            code_3: 'FRA',
            numCode: '250',
            name: 'France',
            languages: ['fra'],
            langCultureMs: [
                {
                    langCultureName: 'fr-FR',
                    displayName: 'French - France',
                    cultureCode: '0x040C',
                },
            ],
        },
        {
            code_2: 'GF',
            code_3: 'GUF',
            numCode: '254',
            name: 'French Guiana',
            languages: ['fra'],
        },
        {
            code_2: 'PF',
            code_3: 'PYF',
            numCode: '258',
            name: 'French Polynesia',
            languages: ['fra'],
        },
        {
            code_2: 'TF',
            code_3: 'ATF',
            numCode: '260',
            name: 'French Southern Territories',
        },
        {
            code_2: 'GA',
            code_3: 'GAB',
            numCode: '266',
            name: 'Gabon',
            languages: ['fra'],
        },
        {
            code_2: 'GM',
            code_3: 'GMB',
            numCode: '270',
            name: 'Gambia',
            languages: ['eng'],
        },
        {
            code_2: 'GE',
            code_3: 'GEO',
            numCode: '268',
            name: 'Georgia',
            languages: ['kat'],
            langCultureMs: [
                {
                    langCultureName: 'ka-GE',
                    displayName: 'Georgian - Georgia',
                    cultureCode: '0x0437',
                },
            ],
        },
        {
            code_2: 'DE',
            code_3: 'DEU',
            numCode: '276',
            name: 'Germany',
            languages: ['deu'],
            langCultureMs: [
                {
                    langCultureName: 'de-DE',
                    displayName: 'German - Germany',
                    cultureCode: '0x0407',
                },
            ],
        },
        {
            code_2: 'GH',
            code_3: 'GHA',
            numCode: '288',
            name: 'Ghana',
            languages: ['aka', 'eng', 'ewe', 'gaa'],
        },
        {
            code_2: 'GI',
            code_3: 'GIB',
            numCode: '292',
            name: 'Gibraltar',
        },
        {
            code_2: 'GR',
            code_3: 'GRC',
            numCode: '300',
            name: 'Greece',
            languages: ['ell'],
            langCultureMs: [
                {
                    langCultureName: 'el-GR',
                    displayName: 'Greek - Greece',
                    cultureCode: '0x0408',
                },
            ],
        },
        {
            code_2: 'GL',
            code_3: 'GRL',
            numCode: '304',
            name: 'Greenland',
        },
        {
            code_2: 'GD',
            code_3: 'GRD',
            numCode: '308',
            name: 'Grenada',
            languages: ['eng'],
        },
        {
            code_2: 'GP',
            code_3: 'GLP',
            numCode: '312',
            name: 'Guadeloupe',
            languages: ['fra'],
        },
        {
            code_2: 'GU',
            code_3: 'GUM',
            numCode: '316',
            name: 'Guam',
        },
        {
            code_2: 'GT',
            code_3: 'GTM',
            numCode: '320',
            name: 'Guatemala',
            languages: ['spa'],
            langCultureMs: [
                {
                    langCultureName: 'es-GT',
                    displayName: 'Spanish - Guatemala',
                    cultureCode: '0x100A',
                },
            ],
        },
        {
            code_2: 'GG',
            code_3: 'GGY',
            numCode: '831',
            name: 'Guernsey',
        },
        {
            code_2: 'GN',
            code_3: 'GIN',
            numCode: '324',
            name: 'Guinea',
            languages: ['fra'],
        },
        {
            code_2: 'GW',
            code_3: 'GNB',
            numCode: '624',
            name: 'Guinea-Bissau',
            languages: ['por'],
        },
        {
            code_2: 'GY',
            code_3: 'GUY',
            numCode: '328',
            name: 'Guyana',
            languages: ['eng'],
        },
        {
            code_2: 'HT',
            code_3: 'HTI',
            numCode: '332',
            name: 'Haiti',
            languages: ['fra', 'hat'],
        },
        {
            code_2: 'HM',
            code_3: 'HMD',
            numCode: '334',
            name: 'Heard Island and McDonald Islands',
        },
        {
            code_2: 'VA',
            code_3: 'VAT',
            numCode: '336',
            name: 'Holy See (Vatican City State)',
            languages: ['ita', 'lat'],
        },
        {
            code_2: 'HN',
            code_3: 'HND',
            numCode: '340',
            name: 'Honduras',
            languages: ['spa'],
            langCultureMs: [
                {
                    langCultureName: 'es-HN',
                    displayName: 'Spanish - Honduras',
                    cultureCode: '0x480A',
                },
            ],
        },
        {
            code_2: 'HK',
            code_3: 'HKG',
            numCode: '344',
            name: 'Hong Kong',
            languages: ['zho', 'eng'],
            langCultureMs: [
                {
                    langCultureName: 'zh-HK',
                    displayName: 'Chinese - Hong Kong SAR',
                    cultureCode: '0x0C04',
                },
            ],
        },
        {
            code_2: 'HU',
            code_3: 'HUN',
            numCode: '348',
            name: 'Hungary',
            languages: ['hun'],
            langCultureMs: [
                {
                    langCultureName: 'hu-HU',
                    displayName: 'Hungarian - Hungary',
                    cultureCode: '0x040E',
                },
            ],
        },
        {
            code_2: 'IS',
            code_3: 'ISL',
            numCode: '352',
            name: 'Iceland',
            languages: ['isl'],
            langCultureMs: [
                {
                    langCultureName: 'is-IS',
                    displayName: 'Icelandic - Iceland',
                    cultureCode: '0x040F',
                },
            ],
        },
        {
            code_2: 'IN',
            code_3: 'IND',
            numCode: '356',
            name: 'India',
            languages: ['ben', 'eng', 'guj', 'hin', 'pan', 'tam', 'tel'],
            langCultureMs: [
                {
                    langCultureName: 'gu-IN',
                    displayName: 'Gujarati - India',
                    cultureCode: '0x0447',
                },
                {
                    langCultureName: 'hi-IN',
                    displayName: 'Hindi - India',
                    cultureCode: '0x0439',
                },
                {
                    langCultureName: 'kn-IN',
                    displayName: 'Kannada - India',
                    cultureCode: '0x044B',
                },
                {
                    langCultureName: 'kok-IN',
                    displayName: 'Konkani - India',
                    cultureCode: '0x0457',
                },
                {
                    langCultureName: 'mr-IN',
                    displayName: 'Marathi - India',
                    cultureCode: '0x044E',
                },
                {
                    langCultureName: 'pa-IN',
                    displayName: 'Punjabi - India',
                    cultureCode: '0x0446',
                },
                {
                    langCultureName: 'sa-IN',
                    displayName: 'Sanskrit - India',
                    cultureCode: '0x044F',
                },
                {
                    langCultureName: 'ta-IN',
                    displayName: 'Tamil - India',
                    cultureCode: '0x0449',
                },
                {
                    langCultureName: 'te-IN',
                    displayName: 'Telugu - India',
                    cultureCode: '0x044A',
                },
            ],
        },
        {
            code_2: 'ID',
            code_3: 'IDN',
            numCode: '360',
            name: 'Indonesia',
            languages: ['ind', 'msa'],
            langCultureMs: [
                {
                    langCultureName: 'id-ID',
                    displayName: 'Indonesian - Indonesia',
                    cultureCode: '0x0421',
                },
            ],
        },
        {
            code_2: 'IR',
            code_3: 'IRN',
            numCode: '364',
            name: 'Iran, Islamic Republic of',
            languages: ['fas'],
            langCultureMs: [
                {
                    langCultureName: 'fa-IR',
                    displayName: 'Farsi - Iran',
                    cultureCode: '0x0429',
                },
            ],
        },
        {
            code_2: 'IQ',
            code_3: 'IRQ',
            numCode: '368',
            name: 'Iraq',
            languages: ['ara', 'kur'],
            langCultureMs: [
                {
                    langCultureName: 'ar-IQ',
                    displayName: 'Arabic - Iraq',
                    cultureCode: '0x0801',
                },
            ],
        },
        {
            code_2: 'IE',
            code_3: 'IRL',
            numCode: '372',
            name: 'Ireland',
            languages: ['eng', 'gle'],
            langCultureMs: [
                {
                    langCultureName: 'en-IE',
                    displayName: 'English - Ireland',
                    cultureCode: '0x1809',
                },
            ],
        },
        {
            code_2: 'IM',
            code_3: 'IMN',
            numCode: '833',
            name: 'Isle of Man',
        },
        {
            code_2: 'IL',
            code_3: 'ISR',
            numCode: '376',
            name: 'Israel',
            languages: ['ara', 'heb'],
            langCultureMs: [
                {
                    langCultureName: 'he-IL',
                    displayName: 'Hebrew - Israel',
                    cultureCode: '0x040D',
                },
            ],
        },
        {
            code_2: 'IT',
            code_3: 'ITA',
            numCode: '380',
            name: 'Italy',
            languages: ['fra', 'deu', 'ita'],
            langCultureMs: [
                {
                    langCultureName: 'it-IT',
                    displayName: 'Italian - Italy',
                    cultureCode: '0x0410',
                },
            ],
        },
        {
            code_2: 'JM',
            code_3: 'JAM',
            numCode: '388',
            name: 'Jamaica',
            languages: ['eng'],
            langCultureMs: [
                {
                    langCultureName: 'en-JM',
                    displayName: 'English - Jamaica',
                    cultureCode: '0x2009',
                },
            ],
        },
        {
            code_2: 'JP',
            code_3: 'JPN',
            numCode: '392',
            name: 'Japan',
            languages: ['jpn'],
            langCultureMs: [
                {
                    langCultureName: 'ja-JP',
                    displayName: 'Japanese - Japan',
                    cultureCode: '0x0411',
                },
            ],
        },
        {
            code_2: 'JE',
            code_3: 'JEY',
            numCode: '832',
            name: 'Jersey',
            languages: ['fra'],
        },
        {
            code_2: 'JO',
            code_3: 'JOR',
            numCode: '400',
            name: 'Jordan',
            languages: ['ara'],
            langCultureMs: [
                {
                    langCultureName: 'ar-JO',
                    displayName: 'Arabic - Jordan',
                    cultureCode: '0x2C01',
                },
            ],
        },
        {
            code_2: 'KZ',
            code_3: 'KAZ',
            numCode: '398',
            name: 'Kazakhstan',
            languages: ['kaz', 'rus'],
            langCultureMs: [
                {
                    langCultureName: 'kk-KZ',
                    displayName: 'Kazakh - Kazakhstan',
                    cultureCode: '0x043F',
                },
                {
                    langCultureName: 'ky-KZ',
                    displayName: 'Kyrgyz - Kazakhstan',
                    cultureCode: '0x0440',
                },
            ],
        },
        {
            code_2: 'KE',
            code_3: 'KEN',
            numCode: '404',
            name: 'Kenya',
            languages: ['eng', 'swa'],
            langCultureMs: [
                {
                    langCultureName: 'sw-KE',
                    displayName: 'Swahili - Kenya',
                    cultureCode: '0x0441',
                },
            ],
        },
        {
            code_2: 'KI',
            code_3: 'KIR',
            numCode: '296',
            name: 'Kiribati',
            languages: ['eng'],
        },
        {
            code_2: 'KP',
            code_3: 'PRK',
            numCode: '408',
            name: "Korea, Democratic People's Republic of",
            languages: ['kor'],
        },
        {
            code_2: 'KR',
            code_3: 'KOR',
            numCode: '410',
            name: 'Korea, Republic of',
            languages: ['kor'],
            langCultureMs: [
                {
                    langCultureName: 'ko-KR',
                    displayName: 'Korean - Korea',
                    cultureCode: '0x0412',
                },
            ],
        },
        {
            code_2: 'KW',
            code_3: 'KWT',
            numCode: '414',
            name: 'Kuwait',
            languages: ['ara'],
            langCultureMs: [
                {
                    langCultureName: 'ar-KW',
                    displayName: 'Arabic - Kuwait',
                    cultureCode: '0x3401',
                },
            ],
        },
        {
            code_2: 'KG',
            code_3: 'KGZ',
            numCode: '417',
            name: 'Kyrgyzstan',
            languages: ['kir', 'rus'],
        },
        {
            code_2: 'LA',
            code_3: 'LAO',
            numCode: '418',
            name: "Lao People's Democratic Republic",
            languages: ['lao'],
        },
        {
            code_2: 'LV',
            code_3: 'LVA',
            numCode: '428',
            name: 'Latvia',
            languages: ['lav'],
            langCultureMs: [
                {
                    langCultureName: 'lv-LV',
                    displayName: 'Latvian - Latvia',
                    cultureCode: '0x0426',
                },
            ],
        },
        {
            code_2: 'LB',
            code_3: 'LBN',
            numCode: '422',
            name: 'Lebanon',
            languages: ['ara'],
            langCultureMs: [
                {
                    langCultureName: 'ar-LB',
                    displayName: 'Arabic - Lebanon',
                    cultureCode: '0x3001',
                },
            ],
        },
        {
            code_2: 'LS',
            code_3: 'LSO',
            numCode: '426',
            name: 'Lesotho',
            languages: ['eng', 'sot'],
        },
        {
            code_2: 'LR',
            code_3: 'LBR',
            numCode: '430',
            name: 'Liberia',
            languages: ['eng'],
        },
        {
            code_2: 'LY',
            code_3: 'LBY',
            numCode: '434',
            name: 'Libya',
            languages: ['ara'],
            langCultureMs: [
                {
                    langCultureName: 'ar-LY',
                    displayName: 'Arabic - Libya',
                    cultureCode: '0x1001',
                },
            ],
        },
        {
            code_2: 'LI',
            code_3: 'LIE',
            numCode: '438',
            name: 'Liechtenstein',
            languages: ['deu'],
            langCultureMs: [
                {
                    langCultureName: 'de-LI',
                    displayName: 'German - Liechtenstein',
                    cultureCode: '0x1407',
                },
            ],
        },
        {
            code_2: 'LT',
            code_3: 'LTU',
            numCode: '440',
            name: 'Lithuania',
            languages: ['lit'],
            langCultureMs: [
                {
                    langCultureName: 'lt-LT',
                    displayName: 'Lithuanian - Lithuania',
                    cultureCode: '0x0427',
                },
            ],
        },
        {
            code_2: 'LU',
            code_3: 'LUX',
            numCode: '442',
            name: 'Luxembourg',
            languages: ['fra', 'deu', 'ltz'],
            langCultureMs: [
                {
                    langCultureName: 'fr-LU',
                    displayName: 'French - Luxembourg',
                    cultureCode: '0x140C',
                },
                {
                    langCultureName: 'de-LU',
                    displayName: 'German - Luxembourg',
                    cultureCode: '0x1007',
                },
            ],
        },
        {
            code_2: 'MO',
            code_3: 'MAC',
            numCode: '446',
            name: 'Macao',
            languages: ['zho', 'por'],
            langCultureMs: [
                {
                    langCultureName: 'zh-MO',
                    displayName: 'Chinese - Macau SAR',
                    cultureCode: '0x1404',
                },
            ],
        },
        {
            code_2: 'MK',
            code_3: 'MKD',
            numCode: '807',
            name: 'Macedonia, the former Yugoslav Republic of',
            languages: ['mkd'],
            langCultureMs: [
                {
                    langCultureName: 'mk-MK',
                    displayName: 'Macedonian (FYROM)',
                    cultureCode: '0x042F',
                },
            ],
        },
        {
            code_2: 'MG',
            code_3: 'MDG',
            numCode: '450',
            name: 'Madagascar',
            languages: ['fra', 'mlg'],
        },
        {
            code_2: 'MW',
            code_3: 'MWI',
            numCode: '454',
            name: 'Malawi',
            languages: ['nya', 'eng'],
        },
        {
            code_2: 'MY',
            code_3: 'MYS',
            numCode: '458',
            name: 'Malaysia',
            languages: ['eng', 'msa', 'tam'],
            langCultureMs: [
                {
                    langCultureName: 'ms-MY',
                    displayName: 'Malay - Malaysia',
                    cultureCode: '0x043E',
                },
            ],
        },
        {
            code_2: 'MV',
            code_3: 'MDV',
            numCode: '462',
            name: 'Maldives',
            languages: ['div'],
            langCultureMs: [
                {
                    langCultureName: 'div-MV',
                    displayName: 'Dhivehi - Maldives',
                    cultureCode: '0x0465',
                },
            ],
        },
        {
            code_2: 'ML',
            code_3: 'MLI',
            numCode: '466',
            name: 'Mali',
            languages: ['bam', 'fra', 'ful', 'snk'],
        },
        {
            code_2: 'MT',
            code_3: 'MLT',
            numCode: '470',
            name: 'Malta',
            languages: ['eng', 'mlt'],
        },
        {
            code_2: 'MH',
            code_3: 'MHL',
            numCode: '584',
            name: 'Marshall Islands',
            languages: ['eng', 'mah'],
        },
        {
            code_2: 'MQ',
            code_3: 'MTQ',
            numCode: '474',
            name: 'Martinique',
            languages: ['fra'],
        },
        {
            code_2: 'MR',
            code_3: 'MRT',
            numCode: '478',
            name: 'Mauritania',
            languages: ['ara'],
        },
        {
            code_2: 'MU',
            code_3: 'MUS',
            numCode: '480',
            name: 'Mauritius',
            languages: ['eng', 'fra', 'tam'],
        },
        {
            code_2: 'YT',
            code_3: 'MYT',
            numCode: '175',
            name: 'Mayotte',
            languages: ['fra'],
        },
        {
            code_2: 'MX',
            code_3: 'MEX',
            numCode: '484',
            name: 'Mexico',
            languages: ['spa'],
            langCultureMs: [
                {
                    langCultureName: 'es-MX',
                    displayName: 'Spanish - Mexico',
                    cultureCode: '0x080A',
                },
            ],
        },
        {
            code_2: 'FM',
            code_3: 'FSM',
            numCode: '583',
            name: 'Micronesia, Federated States of',
            languages: ['eng'],
        },
        {
            code_2: 'MD',
            code_3: 'MDA',
            numCode: '498',
            name: 'Moldova, Republic of',
            languages: ['ron'],
        },
        {
            code_2: 'MC',
            code_3: 'MCO',
            numCode: '492',
            name: 'Monaco',
            languages: ['fra'],
            langCultureMs: [
                {
                    langCultureName: 'fr-MC',
                    displayName: 'French - Monaco',
                    cultureCode: '0x180C',
                },
            ],
        },
        {
            code_2: 'MN',
            code_3: 'MNG',
            numCode: '496',
            name: 'Mongolia',
            languages: ['mon'],
            langCultureMs: [
                {
                    langCultureName: 'mn-MN',
                    displayName: 'Mongolian - Mongolia',
                    cultureCode: '0x0450',
                },
            ],
        },
        {
            code_2: 'ME',
            code_3: 'MNE',
            numCode: '499',
            name: 'Montenegro',
        },
        {
            code_2: 'MS',
            code_3: 'MSR',
            numCode: '500',
            name: 'Montserrat',
        },
        {
            code_2: 'MA',
            code_3: 'MAR',
            numCode: '504',
            name: 'Morocco',
            languages: ['ara', 'ber'],
            langCultureMs: [
                {
                    langCultureName: 'ar-MA',
                    displayName: 'Arabic - Morocco',
                    cultureCode: '0x1801',
                },
            ],
        },
        {
            code_2: 'MZ',
            code_3: 'MOZ',
            numCode: '508',
            name: 'Mozambique',
            languages: ['por'],
        },
        {
            code_2: 'MM',
            code_3: 'MMR',
            numCode: '104',
            name: 'Myanmar',
            languages: ['mya'],
        },
        {
            code_2: 'NA',
            code_3: 'NAM',
            numCode: '516',
            name: 'Namibia',
            languages: ['eng'],
        },
        {
            code_2: 'NR',
            code_3: 'NRU',
            numCode: '520',
            name: 'Nauru',
            languages: ['eng'],
        },
        {
            code_2: 'NP',
            code_3: 'NPL',
            numCode: '524',
            name: 'Nepal',
            languages: ['nep'],
        },
        {
            code_2: 'NL',
            code_3: 'NLD',
            numCode: '528',
            name: 'Netherlands',
            languages: ['nld'],
            langCultureMs: [
                {
                    langCultureName: 'nl-NL',
                    displayName: 'Dutch - The Netherlands',
                    cultureCode: '0x0413',
                },
            ],
        },
        {
            code_2: 'NC',
            code_3: 'NCL',
            numCode: '540',
            name: 'New Caledonia',
            languages: ['fra'],
        },
        {
            code_2: 'NZ',
            code_3: 'NZL',
            numCode: '554',
            name: 'New Zealand',
            languages: ['eng', 'mri'],
            langCultureMs: [
                {
                    langCultureName: 'en-NZ',
                    displayName: 'English - New Zealand',
                    cultureCode: '0x1409',
                },
            ],
        },
        {
            code_2: 'NI',
            code_3: 'NIC',
            numCode: '558',
            name: 'Nicaragua',
            languages: ['spa'],
            langCultureMs: [
                {
                    langCultureName: 'es-NI',
                    displayName: 'Spanish - Nicaragua',
                    cultureCode: '0x4C0A',
                },
            ],
        },
        {
            code_2: 'NE',
            code_3: 'NER',
            numCode: '562',
            name: 'Niger',
            languages: ['ara', 'fra', 'ful', 'hau', 'kau'],
        },
        {
            code_2: 'NG',
            code_3: 'NGA',
            numCode: '566',
            name: 'Nigeria',
            languages: ['eng', 'hau', 'ibo', 'yor'],
        },
        {
            code_2: 'NU',
            code_3: 'NIU',
            numCode: '570',
            name: 'Niue',
        },
        {
            code_2: 'NF',
            code_3: 'NFK',
            numCode: '574',
            name: 'Norfolk Island',
        },
        {
            code_2: 'MP',
            code_3: 'MNP',
            numCode: '580',
            name: 'Northern Mariana Islands',
        },
        {
            code_2: 'NO',
            code_3: 'NOR',
            numCode: '578',
            name: 'Norway',
            languages: ['nob', 'nno', 'nor'],
            langCultureMs: [
                {
                    langCultureName: 'nb-NO',
                    displayName: 'Norwegian (Bokmål) - Norway',
                    cultureCode: '0x0414',
                },
                {
                    langCultureName: 'nn-NO',
                    displayName: 'Norwegian (Nynorsk) - Norway',
                    cultureCode: '0x0814',
                },
            ],
        },
        {
            code_2: 'OM',
            code_3: 'OMN',
            numCode: '512',
            name: 'Oman',
            languages: ['ara'],
            langCultureMs: [
                {
                    langCultureName: 'ar-OM',
                    displayName: 'Arabic - Oman',
                    cultureCode: '0x2001',
                },
            ],
        },
        {
            code_2: 'PK',
            code_3: 'PAK',
            numCode: '586',
            name: 'Pakistan',
            languages: ['eng', 'urd'],
            langCultureMs: [
                {
                    langCultureName: 'ur-PK',
                    displayName: 'Urdu - Pakistan',
                    cultureCode: '0x0420',
                },
            ],
        },
        {
            code_2: 'PW',
            code_3: 'PLW',
            numCode: '585',
            name: 'Palau',
            languages: ['eng'],
        },
        {
            code_2: 'PS',
            code_3: 'PSE',
            numCode: '275',
            name: 'Palestine, State of',
        },
        {
            code_2: 'PA',
            code_3: 'PAN',
            numCode: '591',
            name: 'Panama',
            languages: ['spa'],
            langCultureMs: [
                {
                    langCultureName: 'es-PA',
                    displayName: 'Spanish - Panama',
                    cultureCode: '0x180A',
                },
            ],
        },
        {
            code_2: 'PG',
            code_3: 'PNG',
            numCode: '598',
            name: 'Papua New Guinea',
            languages: ['eng', 'hmo', 'tpi'],
        },
        {
            code_2: 'PY',
            code_3: 'PRY',
            numCode: '600',
            name: 'Paraguay',
            languages: ['grn', 'spa'],
            langCultureMs: [
                {
                    langCultureName: 'es-PY',
                    displayName: 'Spanish - Paraguay',
                    cultureCode: '0x3C0A',
                },
            ],
        },
        {
            code_2: 'PE',
            code_3: 'PER',
            numCode: '604',
            name: 'Peru',
            languages: ['aym', 'que', 'spa'],
            langCultureMs: [
                {
                    langCultureName: 'es-PE',
                    displayName: 'Spanish - Peru',
                    cultureCode: '0x280A',
                },
            ],
        },
        {
            code_2: 'PH',
            code_3: 'PHL',
            numCode: '608',
            name: 'Philippines',
            languages: ['eng', 'fil', 'tgl'],
            langCultureMs: [
                {
                    langCultureName: 'en-PH',
                    displayName: 'English - Philippines',
                    cultureCode: '0x3409',
                },
            ],
        },
        {
            code_2: 'PN',
            code_3: 'PCN',
            numCode: '612',
            name: 'Pitcairn',
        },
        {
            code_2: 'PL',
            code_3: 'POL',
            numCode: '616',
            name: 'Poland',
            languages: ['pol'],
            langCultureMs: [
                {
                    langCultureName: 'pl-PL',
                    displayName: 'Polish - Poland',
                    cultureCode: '0x0415',
                },
            ],
        },
        {
            code_2: 'PT',
            code_3: 'PRT',
            numCode: '620',
            name: 'Portugal',
            languages: ['por'],
            langCultureMs: [
                {
                    langCultureName: 'pt-PT',
                    displayName: 'Portuguese - Portugal',
                    cultureCode: '0x0816',
                },
            ],
        },
        {
            code_2: 'PR',
            code_3: 'PRI',
            numCode: '630',
            name: 'Puerto Rico',
            languages: ['spa'],
            langCultureMs: [
                {
                    langCultureName: 'es-PR',
                    displayName: 'Spanish - Puerto Rico',
                    cultureCode: '0x500A',
                },
            ],
        },
        {
            code_2: 'QA',
            code_3: 'QAT',
            numCode: '634',
            name: 'Qatar',
            languages: ['ara'],
            langCultureMs: [
                {
                    langCultureName: 'ar-QA',
                    displayName: 'Arabic - Qatar',
                    cultureCode: '0x4001',
                },
            ],
        },
        {
            code_2: 'RE',
            code_3: 'REU',
            numCode: '638',
            name: 'Réunion',
            languages: ['fra'],
        },
        {
            code_2: 'RO',
            code_3: 'ROU',
            numCode: '642',
            name: 'Romania',
            languages: ['ron'],
            langCultureMs: [
                {
                    langCultureName: 'ro-RO',
                    displayName: 'Romanian - Romania',
                    cultureCode: '0x0418',
                },
            ],
        },
        {
            code_2: 'RU',
            code_3: 'RUS',
            numCode: '643',
            name: 'Russian Federation',
            languages: ['rus'],
            langCultureMs: [
                {
                    langCultureName: 'ru-RU',
                    displayName: 'Russian - Russia',
                    cultureCode: '0x0419',
                },
                {
                    langCultureName: 'tt-RU',
                    displayName: 'Tatar - Russia',
                    cultureCode: '0x0444',
                },
            ],
        },
        {
            code_2: 'RW',
            code_3: 'RWA',
            numCode: '646',
            name: 'Rwanda',
            languages: ['eng', 'fra', 'kin'],
        },
        {
            code_2: 'BL',
            code_3: 'BLM',
            numCode: '652',
            name: 'Saint Barthélemy',
            languages: ['fra'],
        },
        {
            code_2: 'SH',
            code_3: 'SHN',
            numCode: '654',
            name: 'Saint Helena, Ascension and Tristan da Cunha',
        },
        {
            code_2: 'KN',
            code_3: 'KNA',
            numCode: '659',
            name: 'Saint Kitts and Nevis',
            languages: ['eng'],
        },
        {
            code_2: 'LC',
            code_3: 'LCA',
            numCode: '662',
            name: 'Saint Lucia',
            languages: ['eng'],
        },
        {
            code_2: 'MF',
            code_3: 'MAF',
            numCode: '663',
            name: 'Saint Martin (French part)',
        },
        {
            code_2: 'PM',
            code_3: 'SPM',
            numCode: '666',
            name: 'Saint Pierre and Miquelon',
            languages: ['fra'],
        },
        {
            code_2: 'VC',
            code_3: 'VCT',
            numCode: '670',
            name: 'Saint Vincent and the Grenadines',
            languages: ['eng'],
        },
        {
            code_2: 'WS',
            code_3: 'WSM',
            numCode: '882',
            name: 'Samoa',
            languages: ['eng'],
        },
        {
            code_2: 'SM',
            code_3: 'SMR',
            numCode: '674',
            name: 'San Marino',
            languages: ['ita'],
        },
        {
            code_2: 'ST',
            code_3: 'STP',
            numCode: '678',
            name: 'Sao Tome and Principe',
            languages: ['por'],
        },
        {
            code_2: 'SA',
            code_3: 'SAU',
            numCode: '682',
            name: 'Saudi Arabia',
            languages: ['ara'],
            langCultureMs: [
                {
                    langCultureName: 'ar-SA',
                    displayName: 'Arabic - Saudi Arabia',
                    cultureCode: '0x0401',
                },
            ],
        },
        {
            code_2: 'SN',
            code_3: 'SEN',
            numCode: '686',
            name: 'Senegal',
            languages: ['fra', 'ful', 'srr', 'snk', 'wol'],
        },
        {
            code_2: 'RS',
            code_3: 'SRB',
            numCode: '688',
            name: 'Serbia',
            languages: ['srp'],
            langCultureMs: [
                {
                    langCultureName: 'Cy-sr-SP',
                    displayName: 'Serbian (Cyrillic) - Serbia',
                    cultureCode: '0x0C1A',
                },
                {
                    langCultureName: 'Lt-sr-SP',
                    displayName: 'Serbian (Latin) - Serbia',
                    cultureCode: '0x081A',
                },
            ],
        },
        {
            code_2: 'SC',
            code_3: 'SYC',
            numCode: '690',
            name: 'Seychelles',
            languages: ['eng', 'fra'],
        },
        {
            code_2: 'SL',
            code_3: 'SLE',
            numCode: '694',
            name: 'Sierra Leone',
            languages: ['eng'],
        },
        {
            code_2: 'SG',
            code_3: 'SGP',
            numCode: '702',
            name: 'Singapore',
            languages: ['zho', 'eng', 'msa', 'tam'],
            langCultureMs: [
                {
                    langCultureName: 'zh-SG',
                    displayName: 'Chinese - Singapore',
                    cultureCode: '0x1004',
                },
            ],
        },
        {
            code_2: 'SX',
            code_3: 'SXM',
            numCode: '534',
            name: 'Sint Maarten (Dutch part)',
            languages: ['nld', 'eng'],
        },
        {
            code_2: 'SK',
            code_3: 'SVK',
            numCode: '703',
            name: 'Slovakia',
            languages: ['ces', 'slk'],
            langCultureMs: [
                {
                    langCultureName: 'sk-SK',
                    displayName: 'Slovak - Slovakia',
                    cultureCode: '0x041B',
                },
            ],
        },
        {
            code_2: 'SI',
            code_3: 'SVN',
            numCode: '705',
            name: 'Slovenia',
            languages: ['ita', 'slv'],
            langCultureMs: [
                {
                    langCultureName: 'sl-SI',
                    displayName: 'Slovenian - Slovenia',
                    cultureCode: '0x0424',
                },
            ],
        },
        {
            code_2: 'SB',
            code_3: 'SLB',
            numCode: '090',
            name: 'Solomon Islands',
            languages: ['eng'],
        },
        {
            code_2: 'SO',
            code_3: 'SOM',
            numCode: '706',
            name: 'Somalia',
            languages: ['ara', 'som'],
        },
        {
            code_2: 'ZA',
            code_3: 'ZAF',
            numCode: '710',
            name: 'South Africa',
            languages: [
                'afr',
                'eng',
                'nde',
                'nbl',
                'nso',
                'sot',
                'ssw',
                'tso',
                'tsn',
                'ven',
                'xho',
                'zul',
            ],
            langCultureMs: [
                {
                    langCultureName: 'af-ZA',
                    displayName: 'Afrikaans - South Africa',
                    cultureCode: '0x0436',
                },
                {
                    langCultureName: 'en-ZA',
                    displayName: 'English - South Africa',
                    cultureCode: '0x1C09',
                },
            ],
        },
        {
            code_2: 'GS',
            code_3: 'SGS',
            numCode: '239',
            name: 'South Georgia and the South Sandwich Islands',
        },
        {
            code_2: 'SS',
            code_3: 'SSD',
            numCode: '728',
            name: 'South Sudan',
            languages: ['eng'],
        },
        {
            code_2: 'ES',
            code_3: 'ESP',
            numCode: '724',
            name: 'Spain',
            languages: ['spa'],
            langCultureMs: [
                {
                    langCultureName: 'eu-ES',
                    displayName: 'Basque - Basque',
                    cultureCode: '0x042D',
                },
                {
                    langCultureName: 'ca-ES',
                    displayName: 'Catalan - Catalan',
                    cultureCode: '0x0403',
                },
                {
                    langCultureName: 'gl-ES',
                    displayName: 'Galician - Galician',
                    cultureCode: '0x0456',
                },
                {
                    langCultureName: 'es-ES',
                    displayName: 'Spanish - Spain',
                    cultureCode: '0x0C0A',
                },
            ],
        },
        {
            code_2: 'LK',
            code_3: 'LKA',
            numCode: '144',
            name: 'Sri Lanka',
            languages: ['eng', 'sin', 'tam'],
        },
        {
            code_2: 'SD',
            code_3: 'SDN',
            numCode: '729',
            name: 'Sudan',
            languages: ['ara', 'eng'],
        },
        {
            code_2: 'SR',
            code_3: 'SUR',
            numCode: '740',
            name: 'Suriname',
            languages: ['nld'],
        },
        {
            code_2: 'SJ',
            code_3: 'SJM',
            numCode: '744',
            name: 'Svalbard and Jan Mayen',
        },
        {
            code_2: 'SZ',
            code_3: 'SWZ',
            numCode: '748',
            name: 'Swaziland',
            languages: ['eng', 'ssw'],
        },
        {
            code_2: 'SE',
            code_3: 'SWE',
            numCode: '752',
            name: 'Sweden',
            languages: ['swe'],
            langCultureMs: [
                {
                    langCultureName: 'sv-SE',
                    displayName: 'Swedish - Sweden',
                    cultureCode: '0x041D',
                },
            ],
        },
        {
            code_2: 'CH',
            code_3: 'CHE',
            numCode: '756',
            name: 'Switzerland',
            languages: ['fra', 'deu', 'ita', 'roh'],
            langCultureMs: [
                {
                    langCultureName: 'fr-CH',
                    displayName: 'French - Switzerland',
                    cultureCode: '0x100C',
                },
                {
                    langCultureName: 'de-CH',
                    displayName: 'German - Switzerland',
                    cultureCode: '0x0807',
                },
                {
                    langCultureName: 'it-CH',
                    displayName: 'Italian - Switzerland',
                    cultureCode: '0x0810',
                },
            ],
        },
        {
            code_2: 'SY',
            code_3: 'SYR',
            numCode: '760',
            name: 'Syrian Arab Republic',
            languages: ['ara'],
            langCultureMs: [
                {
                    langCultureName: 'ar-SY',
                    displayName: 'Arabic - Syria',
                    cultureCode: '0x2801',
                },
                {
                    langCultureName: 'syr-SY',
                    displayName: 'Syriac - Syria',
                    cultureCode: '0x045A',
                },
            ],
        },
        {
            code_2: 'TW',
            code_3: 'TWN',
            numCode: '158',
            name: 'Taiwan, Province of China',
            languages: ['zho'],
            langCultureMs: [
                {
                    langCultureName: 'zh-TW',
                    displayName: 'Chinese - Taiwan',
                    cultureCode: '0x0404',
                },
            ],
        },
        {
            code_2: 'TJ',
            code_3: 'TJK',
            numCode: '762',
            name: 'Tajikistan',
            languages: ['fas', 'rus', 'tgk'],
        },
        {
            code_2: 'TZ',
            code_3: 'TZA',
            numCode: '834',
            name: 'Tanzania, United Republic of',
            languages: ['eng', 'swa'],
        },
        {
            code_2: 'TH',
            code_3: 'THA',
            numCode: '764',
            name: 'Thailand',
            languages: ['tha'],
            langCultureMs: [
                {
                    langCultureName: 'th-TH',
                    displayName: 'Thai - Thailand',
                    cultureCode: '0x041E',
                },
            ],
        },
        {
            code_2: 'TL',
            code_3: 'TLS',
            numCode: '626',
            name: 'Timor-Leste',
            languages: ['por', 'tet'],
        },
        {
            code_2: 'TG',
            code_3: 'TGO',
            numCode: '768',
            name: 'Togo',
            languages: ['ewe', 'fra'],
        },
        {
            code_2: 'TK',
            code_3: 'TKL',
            numCode: '772',
            name: 'Tokelau',
        },
        {
            code_2: 'TO',
            code_3: 'TON',
            numCode: '776',
            name: 'Tonga',
            languages: ['eng'],
        },
        {
            code_2: 'TT',
            code_3: 'TTO',
            numCode: '780',
            name: 'Trinidad and Tobago',
            languages: ['eng'],
            langCultureMs: [
                {
                    langCultureName: 'en-TT',
                    displayName: 'English - Trinidad and Tobago',
                    cultureCode: '0x2C09',
                },
            ],
        },
        {
            code_2: 'TN',
            code_3: 'TUN',
            numCode: '788',
            name: 'Tunisia',
            languages: ['ara'],
            langCultureMs: [
                {
                    langCultureName: 'ar-TN',
                    displayName: 'Arabic - Tunisia',
                    cultureCode: '0x1C01',
                },
            ],
        },
        {
            code_2: 'TR',
            code_3: 'TUR',
            numCode: '792',
            name: 'Turkey',
            languages: ['tur'],
            langCultureMs: [
                {
                    langCultureName: 'tr-TR',
                    displayName: 'Turkish - Turkey',
                    cultureCode: '0x041F',
                },
            ],
        },
        {
            code_2: 'TM',
            code_3: 'TKM',
            numCode: '795',
            name: 'Turkmenistan',
            languages: ['tuk'],
        },
        {
            code_2: 'TC',
            code_3: 'TCA',
            numCode: '796',
            name: 'Turks and Caicos Islands',
        },
        {
            code_2: 'TV',
            code_3: 'TUV',
            numCode: '798',
            name: 'Tuvalu',
            languages: ['eng'],
        },
        {
            code_2: 'UG',
            code_3: 'UGA',
            numCode: '800',
            name: 'Uganda',
            languages: ['eng', 'swa'],
        },
        {
            code_2: 'UA',
            code_3: 'UKR',
            numCode: '804',
            name: 'Ukraine',
            languages: ['ukr'],
            langCultureMs: [
                {
                    langCultureName: 'uk-UA',
                    displayName: 'Ukrainian - Ukraine',
                    cultureCode: '0x0422',
                },
            ],
        },
        {
            code_2: 'AE',
            code_3: 'ARE',
            numCode: '784',
            name: 'United Arab Emirates',
            languages: ['ara'],
            langCultureMs: [
                {
                    langCultureName: 'ar-AE',
                    displayName: 'Arabic - United Arab Emirates',
                    cultureCode: '0x3801',
                },
            ],
        },
        {
            code_2: 'GB',
            code_3: 'GBR',
            numCode: '826',
            name: 'United Kingdom',
            languages: ['eng', 'cym'],
            langCultureMs: [
                {
                    langCultureName: 'en-GB',
                    displayName: 'English - United Kingdom',
                    cultureCode: '0x0809',
                },
            ],
        },
        {
            code_2: 'US',
            code_3: 'USA',
            numCode: '840',
            name: 'United States',
            languages: ['eng'],
            langCultureMs: [
                {
                    langCultureName: 'en-US',
                    displayName: 'English - United States',
                    cultureCode: '0x0409',
                },
            ],
        },
        {
            code_2: 'UM',
            code_3: 'UMI',
            numCode: '581',
            name: 'United States Minor Outlying Islands',
        },
        {
            code_2: 'UY',
            code_3: 'URY',
            numCode: '858',
            name: 'Uruguay',
            languages: ['spa'],
            langCultureMs: [
                {
                    langCultureName: 'es-UY',
                    displayName: 'Spanish - Uruguay',
                    cultureCode: '0x380A',
                },
            ],
        },
        {
            code_2: 'UZ',
            code_3: 'UZB',
            numCode: '860',
            name: 'Uzbekistan',
            languages: ['uzb'],
            langCultureMs: [
                {
                    langCultureName: 'Cy-uz-UZ',
                    displayName: 'Uzbek (Cyrillic) - Uzbekistan',
                    cultureCode: '0x0843',
                },
                {
                    langCultureName: 'Lt-uz-UZ',
                    displayName: 'Uzbek (Latin) - Uzbekistan',
                    cultureCode: '0x0443',
                },
            ],
        },
        {
            code_2: 'VU',
            code_3: 'VUT',
            numCode: '548',
            name: 'Vanuatu',
            languages: ['bis', 'eng', 'fra'],
        },
        {
            code_2: 'VE',
            code_3: 'VEN',
            numCode: '862',
            name: 'Venezuela, Bolivarian Republic of',
            languages: ['spa'],
            langCultureMs: [
                {
                    langCultureName: 'es-VE',
                    displayName: 'Spanish - Venezuela',
                    cultureCode: '0x200A',
                },
            ],
        },
        {
            code_2: 'VN',
            code_3: 'VNM',
            numCode: '704',
            name: 'Viet Nam',
            languages: ['vie'],
            langCultureMs: [
                {
                    langCultureName: 'vi-VN',
                    displayName: 'Vietnamese - Vietnam',
                    cultureCode: '0x042A',
                },
            ],
        },
        {
            code_2: 'VG',
            code_3: 'VGB',
            numCode: '092',
            name: 'Virgin Islands, British',
        },
        {
            code_2: 'VI',
            code_3: 'VIR',
            numCode: '850',
            name: 'Virgin Islands, U.S.',
        },
        {
            code_2: 'WF',
            code_3: 'WLF',
            numCode: '876',
            name: 'Wallis and Futuna',
            languages: ['fra'],
        },
        {
            code_2: 'EH',
            code_3: 'ESH',
            numCode: '732',
            name: 'Western Sahara',
            languages: ['spa'],
        },
        {
            code_2: 'YE',
            code_3: 'YEM',
            numCode: '887',
            name: 'Yemen',
            languages: ['ara'],
            langCultureMs: [
                {
                    langCultureName: 'ar-YE',
                    displayName: 'Arabic - Yemen',
                    cultureCode: '0x2401',
                },
            ],
        },
        {
            code_2: 'ZM',
            code_3: 'ZMB',
            numCode: '894',
            name: 'Zambia',
            languages: ['eng'],
        },
        {
            code_2: 'ZW',
            code_3: 'ZWE',
            numCode: '716',
            name: 'Zimbabwe',
            languages: ['eng', 'nde', 'sna'],
            langCultureMs: [
                {
                    langCultureName: 'en-ZW',
                    displayName: 'English - Zimbabwe',
                    cultureCode: '0x3009',
                },
            ],
        },
    ],
    locales: [
        ['af', 'ZA'],
        ['am', 'ET'],
        ['ar', 'AE'],
        ['ar', 'BH'],
        ['ar', 'DZ'],
        ['ar', 'EG'],
        ['ar', 'IQ'],
        ['ar', 'JO'],
        ['ar', 'KW'],
        ['ar', 'LB'],
        ['ar', 'LY'],
        ['ar', 'MA'],
        ['arn', 'CL'],
        ['ar', 'OM'],
        ['ar', 'QA'],
        ['ar', 'SA'],
        ['ar', 'SY'],
        ['ar', 'TN'],
        ['ar', 'YE'],
        ['as', 'IN'],
        ['az', 'Cyrl', 'AZ'],
        ['az', 'Latn', 'AZ'],
        ['ba', 'RU'],
        ['be', 'BY'],
        ['bg', 'BG'],
        ['bn', 'BD'],
        ['bn', 'IN'],
        ['bo', 'CN'],
        ['br', 'FR'],
        ['bs', 'Cyrl', 'BA'],
        ['bs', 'Latn', 'BA'],
        ['ca', 'ES'],
        ['co', 'FR'],
        ['cs', 'CZ'],
        ['cy', 'GB'],
        ['da', 'DK'],
        ['de', 'AT'],
        ['de', 'CH'],
        ['de', 'DE'],
        ['de', 'LI'],
        ['de', 'LU'],
        ['dsb', 'DE'],
        ['dv', 'MV'],
        ['el', 'GR'],
        ['en', '029'],
        ['en', 'AU'],
        ['en', 'BZ'],
        ['en', 'CA'],
        ['en', 'GB'],
        ['en', 'IE'],
        ['en', 'IN'],
        ['en', 'JM'],
        ['en', 'MY'],
        ['en', 'NZ'],
        ['en', 'PH'],
        ['en', 'SG'],
        ['en', 'TT'],
        ['en', 'US'],
        ['en', 'ZA'],
        ['en', 'ZW'],
        ['es', 'AR'],
        ['es', 'BO'],
        ['es', 'CL'],
        ['es', 'CO'],
        ['es', 'CR'],
        ['es', 'DO'],
        ['es', 'EC'],
        ['es', 'ES'],
        ['es', 'GT'],
        ['es', 'HN'],
        ['es', 'MX'],
        ['es', 'NI'],
        ['es', 'PA'],
        ['es', 'PE'],
        ['es', 'PR'],
        ['es', 'PY'],
        ['es', 'SV'],
        ['es', 'US'],
        ['es', 'UY'],
        ['es', 'VE'],
        ['et', 'EE'],
        ['eu', 'ES'],
        ['fa', 'IR'],
        ['fi', 'FI'],
        ['fil', 'PH'],
        ['fo', 'FO'],
        ['fr', 'BE'],
        ['fr', 'CA'],
        ['fr', 'CH'],
        ['fr', 'FR'],
        ['fr', 'LU'],
        ['fr', 'MC'],
        ['fy', 'NL'],
        ['ga', 'IE'],
        ['gd', 'GB'],
        ['gl', 'ES'],
        ['gsw', 'FR'],
        ['gu', 'IN'],
        ['ha', 'Latn', 'NG'],
        ['he', 'IL'],
        ['hi', 'IN'],
        ['hr', 'BA'],
        ['hr', 'HR'],
        ['hsb', 'DE'],
        ['hu', 'HU'],
        ['hy', 'AM'],
        ['id', 'ID'],
        ['ig', 'NG'],
        ['ii', 'CN'],
        ['is', 'IS'],
        ['it', 'CH'],
        ['it', 'IT'],
        ['iu', 'Cans', 'CA'],
        ['iu', 'Latn', 'CA'],
        ['ja', 'JP'],
        ['ka', 'GE'],
        ['kk', 'KZ'],
        ['kl', 'GL'],
        ['km', 'KH'],
        ['kn', 'IN'],
        ['kok', 'IN'],
        ['ko', 'KR'],
        ['ky', 'KG'],
        ['lb', 'LU'],
        ['lo', 'LA'],
        ['lt', 'LT'],
        ['lv', 'LV'],
        ['mi', 'NZ'],
        ['mk', 'MK'],
        ['ml', 'IN'],
        ['mn', 'MN'],
        ['mn', 'Mong', 'CN'],
        ['moh', 'CA'],
        ['mr', 'IN'],
        ['ms', 'BN'],
        ['ms', 'MY'],
        ['mt', 'MT'],
        ['nb', 'NO'],
        ['ne', 'NP'],
        ['nl', 'BE'],
        ['nl', 'NL'],
        ['nn', 'NO'],
        ['nso', 'ZA'],
        ['oc', 'FR'],
        ['or', 'IN'],
        ['pa', 'IN'],
        ['pl', 'PL'],
        ['prs', 'AF'],
        ['ps', 'AF'],
        ['pt', 'BR'],
        ['pt', 'PT'],
        ['qut', 'GT'],
        ['quz', 'BO'],
        ['quz', 'EC'],
        ['quz', 'PE'],
        ['rm', 'CH'],
        ['ro', 'RO'],
        ['ru', 'RU'],
        ['rw', 'RW'],
        ['sah', 'RU'],
        ['sa', 'IN'],
        ['se', 'FI'],
        ['se', 'NO'],
        ['se', 'SE'],
        ['si', 'LK'],
        ['sk', 'SK'],
        ['sl', 'SI'],
        ['sma', 'NO'],
        ['sma', 'SE'],
        ['smj', 'NO'],
        ['smj', 'SE'],
        ['smn', 'FI'],
        ['sms', 'FI'],
        ['sq', 'AL'],
        ['sr', 'Cyrl', 'BA'],
        ['sr', 'Cyrl', 'CS'],
        ['sr', 'Cyrl', 'ME'],
        ['sr', 'Cyrl', 'RS'],
        ['sr', 'Latn', 'BA'],
        ['sr', 'Latn', 'CS'],
        ['sr', 'Latn', 'ME'],
        ['sr', 'Latn', 'RS'],
        ['sv', 'FI'],
        ['sv', 'SE'],
        ['sw', 'KE'],
        ['syr', 'SY'],
        ['ta', 'IN'],
        ['te', 'IN'],
        ['tg', 'Cyrl', 'TJ'],
        ['th', 'TH'],
        ['tk', 'TM'],
        ['tn', 'ZA'],
        ['tr', 'TR'],
        ['tt', 'RU'],
        ['tzm', 'Latn', 'DZ'],
        ['ug', 'CN'],
        ['uk', 'UA'],
        ['ur', 'PK'],
        ['uz', 'Cyrl', 'UZ'],
        ['uz', 'Latn', 'UZ'],
        ['vi', 'VN'],
        ['wo', 'SN'],
        ['xh', 'ZA'],
        ['yo', 'NG'],
        ['zh', 'CN'],
        ['zh', 'HK'],
        ['zh', 'MO'],
        ['zh', 'SG'],
        ['zh', 'TW'],
        ['zu', 'ZA'],
    ],
};
