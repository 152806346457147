import AvatarCard from 'components/UiComponents/AvatartCard';

type EvaluateTeacherProps = {
    name?: string;
    attendance_in_percent?: number;
    total_apo?: number;
    instructors?: [];
    isOverall?: boolean;
    overall?: number;
};

const EvaluateTeacher = ({
    name,
    attendance_in_percent,
    total_apo,
    instructors,
    isOverall = false,
    overall,
}: EvaluateTeacherProps) => {
    return (
        <>
            <h3 className="heading-base  !text-sm text-ooolab_dark_1">{name}</h3>
            <div className='hidden'>
                <div className="flex flex-wrap mt-2">
                    {instructors?.map((item: { id: string; display_name: string; avatar_url: string }) => {
                        return (
                            <div className="w-full md:w-1/2" key={item.id}>
                                <AvatarCard name={item.display_name} image={item.avatar_url} />
                            </div>
                        );
                    })}
                </div>
                <div className="h-px w-11/12 bg-ooolab_gray_6 mx-auto mt-4"></div>
                {isOverall ? (
                    <div className="w-1/2">
                        <label className="text-sm font-semibold text-ooolab_dark_1">Overall grade</label>
                        <p className="text-[28px] font-bold text-[#3AA757] mt-1">{Math.round(overall)}%</p>
                    </div>
                ) : (
                    <div className="flex space-x-10 text-left mt-4">
                        <div className="w-1/2">
                            <label className="text-sm font-semibold text-ooolab_dark_1">Total COIN</label>
                            <p className="text-[28px] font-bold text-ooolab_2D61DB mt-1">{total_apo}</p>
                        </div>
                        <div className="w-1/2">
                            <label className="text-sm font-semibold text-ooolab_dark_1">Attendance</label>
                            <p className="text-[28px] font-bold text-[#3AA757] mt-1">{Math.round(attendance_in_percent)}%</p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default EvaluateTeacher;
