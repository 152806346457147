import React, { ReactNode, useEffect, useState } from 'react';
import { classNames } from 'utils/commonFun';

interface ButtonPrimaryProps {
    classStyle?: string;
    title?: string;
    icon?: ReactNode;
    disable?: boolean;
    onClickButton?: (event?: any) => void;
    type?: 'button' | 'submit' | 'reset';
    hidden?: boolean;
    children?: React.ReactNode
}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
    classStyle = `relative inline-flex items-center 
    px-4 py-2 border border-transparent shadow-sm text-sm 
    font-medium rounded-md text-white 
    focus:outline-none`,
    disable,
    onClickButton,
    type = 'button',
    hidden,
    children
}) => {
    const [whiteLabel, setWhiteLabel] = useState<string>();
    useEffect(() => {
        setWhiteLabel(process.env.REACT_APP_WHITELABEL);
    }, [])
    return (
        <button
            title=""
            type={type}
            onClick={onClickButton}
            className={classNames(
                disable && 'cursor-not-allowed',
                `bg-primary-500`,
                classStyle
            )}
            hidden={hidden}
            disabled={disable}
        >
            {children}
        </button>  
    );
};

export default ButtonPrimary;
