import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ErrorMessageForm from 'components/ErrorMessageForm';
import { useTranslation } from 'react-i18next';
import classService from 'services/class.service';
import { TeacherDashboardService } from 'services';
import { Toastify } from '_shared';
import { User } from '_shared/components/User/User';
import { TextareaField } from 'components/InputForm/InputForm';
interface ModalUpdateRecordOfWorkProps {
    workspaceId?: any;
    sessionId?: any;
    classId?: any;
    isSubmit?: boolean;
    setSubmit: any;
    isReset?: boolean;
    setIsReset?: React.Dispatch<React.SetStateAction<boolean>>;
}

const PedCard: FC<ModalUpdateRecordOfWorkProps> = ({
    workspaceId,
    sessionId,
    classId,
    isSubmit,
    setSubmit,
    isReset = false,
    setIsReset,
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        getValues,
        reset,
        setValue,
    } = useForm();
    const { t } = useTranslation();
    const translator = (key: string, option?: any) => {
        return t('RECORD_OF_WORK.' + key, option);
    };
    const [sessionDetail, setSessionDetail] = useState(undefined)

    const updateRecordsApi = async (params: any) => {
        classService
            .updateRecordOfWorkService({ workspaceId, classId, sessionId }, params)
            .then((res) => {
                //Toastify.success();
            })
            .catch((error) => {
                Toastify.error(translator('UPDATE_ERROR'));
            })
            .finally(() => setSubmit(false));
    };

    const onSubmitForm = (values: any) => {
        updateRecordsApi({
            //learning_materials_used: values?.learning_materials_used || null,
            lesson_notes: values?.lesson_notes || null,
            instructor_notes: values?.instructor_notes || null,
        });
    };

    useEffect(() => {
        if (isSubmit) {
            onSubmitForm(getValues());
        }
    }, [isSubmit]);

    const getSessionDetailService = () => {
        TeacherDashboardService.getSessionDetailService(workspaceId, classId, sessionId).then((res) => {
            if (res?.data) {
                reset({
                    ...res.data
                });
                setSessionDetail(res?.data)
            }
        });
    };

    useEffect(() => {
        getSessionDetailService();
    }, []);
    useEffect(() => {
        if (isReset) {
            getSessionDetailService();
            setIsReset(false);
        }
    }, [isReset]);

    useEffect(() => {
        if (getValues('goal_achieved')?.value !== 1) {
            setValue('failed_goals', []);
        }
    }, [watch('goal_achieved')]);

    return (
        <form onSubmit={handleSubmit(onSubmitForm)} className=" flex rounded-lg p-5 !bg-white mx-5 mt-6">
            <div className='w-1/2'>
                <div className="font-semibold text-lg">{translator('OVERVIEW')}</div>
                <div>
                    <label className='text-xs font-semibold'>Course</label>
                    <div onClick={() => window.open(`/workspace/${workspaceId}/management/courses/${sessionDetail?.course?.id}`, '_blank')} className='text-sm font-semibold cursor-pointer text-primary-500'>{sessionDetail?.course?.title}</div>
                </div>
                <div>
                    <label className='text-xs font-semibold'>Lesson</label>
                    <div onClick={() => window.open(`/workspace/${workspaceId}/lesson/${sessionDetail?.lesson?.id}`)} className='text-sm font-semibold cursor-pointer text-primary-500'>{sessionDetail?.lesson?.title || '-'}</div>
                </div>
                <div>
                    <label className='text-xs font-semibold'>Instructor(s)</label>
                    <div className='flex flex-wrap gap-2'>
                        {
                            sessionDetail?.instructors?.map(item => {
                                return (
                                    <User
                                        key={item?.id}
                                        name={item?.display_name}
                                        link={`/workspace/${workspaceId}/management/users/${item?.id}?tab=information`}
                                        avatar={item?.avatar_url}
                                        className='!max-w-none'
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className=" w-1/2">
                <TextareaField
                    label={translator('LESSON_NOTES')}
                    name="lesson_notes"
                    placeholder={translator('ENTER_LESSON_NOTES')}
                    register={register('lesson_notes')}
                    textLength={getValues('lesson_notes')?.length?.toString()}
                    maxLength={1000}
                    isCountter
                />
                <TextareaField
                    label={translator('INSTRUCTOR_NOTES')}
                    name="instructor_notes"
                    className='mt-2'
                    placeholder={translator('ENTER_INSTRUCTOR_NOTES')}
                    register={register('instructor_notes')}
                    textLength={getValues('instructor_notes')?.length?.toString()}
                    maxLength={1000}
                    isCountter
                />
            </div>
        </form>
    );
};

export default PedCard;
