import FullCalendar from "@fullcalendar/react";
import React, { useContext, useEffect, useRef, useState } from "react";

import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import "./BookingInstructor.scss";
import moment from "moment";
import { useTranslation } from "react-i18next";
import DialogComponent from "components/Dialog/DialogComponent";
import { BookingForm } from "../BookingForm/BookingForm";
import DatePickerInput from "components/Management/components/Form/DatePicker";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { useParams } from "react-router-dom";
import { BookingInstructorEmpty } from "./BookingInstructorEmpty";
import { UserContext } from "contexts/User/UserContext";
import courseService from "services/course.service";
import { useAppSelector } from "hooks/hooks";
import { Toastify } from "_shared";
import { CircleSpin } from "components/UiComponents/CircleSpin";

const OPTION_CALENDAR = {
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    weekends: true,
    allDaySlot: false,
    slotDuration: { days: 1 }
}
enum STATUS_EVENT {
    NotAvailable,
    HasSlot,
    Available
}
const RenderEvent = ({ event, borderColor, textColor }: any) => {
    return <div className={`eventContent flex w-full h-full`}>
        <div className={`dayivew-bg flex items-center justify-center text-xs text-center absolute w-full h-[103%] overflow-y-auto top-0 left-0 z-1 p-1.5`}
            style={{ borderColor: borderColor, color: textColor }}
        >
            {event?.extendedProps?.text} {event?.extendedProps?.hasPast}
        </div>
        {event?.extendedProps?.status != STATUS_EVENT.NotAvailable && !event?.extendedProps?.hasSelectedFull && !event?.extendedProps?.hasPast ?
            <div className={`dayivew-bg overlapse flex items-center justify-center text-xs text-center absolute w-full h-[103%] overflow-y-auto top-0 left-0 z-1 p-1.5`}>
                Book slot <PlusCircleIcon className="w-5 h-5 ml-2" />
            </div> : null
        }
    </div>
}

export const BookingInstructor = ({ onAddSlot, enrollmentSelected, numberInstructor = 0 }: { enrollmentSelected: any, onAddSlot: (value) => void; numberInstructor?: number }) => {
    const {
        userState: { result },
    } = useContext(UserContext);
    const { expandedMenu } = useAppSelector(state => state.configPage)
    const { sessions } = useAppSelector(state => state.booking);
    const { control, setValue, watch, getValues } = useForm();
    const { t: translator } = useTranslation();
    const params: { id: string, courseId: string } = useParams();
    const calendarRef = useRef<any>();
    const [optionCalendar, setOptionCalendar] = useState(OPTION_CALENDAR);
    const [hasBookingForm, setHasBookingForm] = useState(false);
    const splitString = (str: string) => str.substring(0, 3);
    const [calendarApi, setCalendarApi] = useState<any>();
    const [dateRange, setDateRange] = useState<{ start: Date, end: Date }>();
    const [events, setEvents] = useState([]);
    const [resource, setResource] = useState([]);
    const [bookSlot, setBookSlot] = useState();
    const [loading, setLoading] = useState(false);

    const renderSlotLabelContent = ({ date, isToday, ...props }) => {
        const weekdays = moment(date).locale('en').format('dddd').toUpperCase();
        const day = moment(date).format('DD');
        return <>
            <p className='text-center'>{splitString(translator(weekdays))}</p>
            <p className={`header-day ${moment().format('YYYY-DD-MM') == moment(date).format('YYYY-DD-MM') ? 'header-today' : ''}`}>{day}</p>
        </>;
    };

    const handleCloseBooking = (value?: any) => {
        if (value) {
            onAddSlot(value);
        }
        setHasBookingForm(false);
    }

    const createEventAndSource = () => {
        const eventsData = [];
        resource?.forEach((item) => {
            let start = dayjs(dateRange?.start).format('YYYY-MM-DD');
            let index = 0;
            let eventItem: any = {
                title: item?.title,
                resourceId: item?.id,
                display: 'background',
            }
            while (index <= 6) {
                //Not available
                eventItem = {
                    ...eventItem,
                    backgroundColor: '#F3F4F6',
                    borderColor: '#D1D5DB',
                    textColor: '#9CA3AF',
                    extendedProps: {
                        status: STATUS_EVENT.NotAvailable,
                        text: 'Not available'
                    }
                }
                //Has Slot can book
                const slotAvaiable = getSlotAvaiable(start, item?.calendar_events);
                if ((item?.date_full?.includes(start) || item?.date_not_full?.includes(start)) && slotAvaiable?.length > 0) {
                    const sessionBooked = sessions?.filter(session => moment(session.date).format('YYYY-MM-DD') == start);
                    let text = `${slotAvaiable.length} ${slotAvaiable.length > 1 ? 'Slots' : 'Slot'}`;
                    let backgroundColor = moment(new Date(start)).isBefore(moment(), 'day') ? '#F3F4F6' : '#fff';
                    let borderColor = '#D1D5DB';
                    let textColor = '#6B7280';
                    let slots = [];
                    if (sessionBooked?.length > 0) {
                        slots = sessionBooked.map(slot => slot?.slots).flat();
                        slots = slots?.filter(slot => slot?.instructor?.id == item?.id);
                        if (slots?.length > 0) {
                            text = `${slots?.length}/${slotAvaiable.length} Selected`;
                            backgroundColor = '#E5F4FF';
                            borderColor = '#0071CE';
                            textColor = '#0071CE';
                        }
                    }
                    eventItem = {
                        ...eventItem,
                        backgroundColor,
                        borderColor,
                        textColor,
                        extendedProps: {
                            status: STATUS_EVENT.HasSlot,
                            slotAvaiable,
                            text,
                            date: new Date(start),
                            hasSelectedFull: slots?.length === slotAvaiable?.length,
                            hasPast: moment(new Date(start)).isBefore(moment(), 'day')
                        },
                        instructor: item
                    }
                }
                eventsData.push({
                    ...eventItem,
                    start: new Date(start),
                    id: `${item?.id}_${index}`,
                });
                index++;
                start = dayjs(dateRange.start).add(index, 'day').format('YYYY-MM-DD');
            }
        });
        setEvents(eventsData);
        setLoading(false);
    }

    const getSlotAvaiable = (date, calendarEvents) => {
        const calendarTimeRanges = calendarEvents?.filter(item => item?.course_id == params?.courseId)?.map(item => item.calendar_time_ranges).flat();
        return calendarTimeRanges?.filter(item => moment(item.start_time)
            .format('YYYY-MM-DD') == date && (item?.status == 'auto_generated' || (item?.status == 'touched' && item?.occupied_slots?.length == 0)));
    }

    const getinstructorAvailability = async () => {
        try {
            if (loading) {
                return;
            }
            setLoading(true);
            const payload = {
                includes_past: true,
                start_time: dateRange?.start || new Date(),
                end_time: dateRange?.end || new Date(),
                calendar_view: 'week',
                users: null
            }
            if (!payload?.users) {
                delete payload?.users;
            }
            const response = await courseService.getinstructorAvailability(params?.id, params?.courseId, payload);
            if (!response?.data?.error) {
                setResource(response?.data?.map(item => ({
                    ...item,
                    id: item.user_id,
                    title: item?.display_name ?? '',
                })));
            }
            setLoading(false);
        } catch {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (calendarRef) {
            setCalendarApi(calendarRef.current.getApi());
        }
    }, [calendarRef]);

    useEffect(() => {
        setValue('start_date', new Date());
    }, []);

    useEffect(() => {
        calendarApi?.changeView('resourceTimelineWeek', new Date(getValues('start_date')));
    }, [watch('start_date')]);

    useEffect(() => {
        if (dateRange?.start) {
            getinstructorAvailability();
        }
    }, [dateRange?.start, enrollmentSelected]);

    useEffect(() => {
        if (resource?.length > 0) {
            setLoading(true);
            createEventAndSource();
        } else {
            setEvents([]);
        }
    }, [resource, sessions]);

    useEffect(() => {
        if (calendarApi) {
            calendarApi?.changeView('resourceTimelineWeek');
        }
    }, [expandedMenu]);

    return (<>
        <div className="bookingInstructor-title">
            Select Instructor's Available Slots
        </div>
        <div className={`bookingInstructor-content bookingResourceTime ${resource?.length === 0 ? 'bookingInstructor-noInstructor' : null}`}>
            <div className="absolute top-4 right-4 max-w-[125px] z-10">
                <DatePickerInput
                    control={control}
                    name="start_date"
                    positionIcon="right"
                    classnames="text-sm !py-1.5"
                    formatDate="dd/MM/yyyy"
                    iconCls="!top-[7px]"
                />
            </div>
            {/*@ts-ignore*/
                <FullCalendar
                    ref={calendarRef}
                    plugins={[interactionPlugin, resourceTimelinePlugin, resourceTimeGridPlugin]}
                    {...optionCalendar}
                    headerToolbar={{
                        left: 'today prev,next title',
                        right: ''
                    }}
                    initialView='resourceTimelineWeek'
                    /*@ts-ignore*/
                    events={events}
                    resources={resource}
                    eventContent={(eventInfo) => (
                        <RenderEvent {...eventInfo} />
                    )}
                    eventClick={(info: any) => {
                        if (info?.event?.extendedProps?.hasSelectedFull || info?.event?.extendedProps?.hasPast) {
                            return;
                        }
                        if (info?.event?.extendedProps?.status != STATUS_EVENT?.NotAvailable) {
                            if (!enrollmentSelected?.learners?.length) {
                                return Toastify.error('Please select learner(s)');
                            }
                            setHasBookingForm(true);
                            setBookSlot(info?.event?.extendedProps);
                        }
                    }}
                    datesSet={((data: any) => {
                        setDateRange({
                            start: data.start,
                            end: data.end,
                        });
                    })}
                    slotLabelContent={renderSlotLabelContent}
                    resourceAreaColumns={[
                        {
                            field: 'title',
                            headerContent: `${resource?.length} Instructor(s) Available`
                        },
                    ]}
                    schedulerLicenseKey={process.env.REACT_APP_FULL_CALENDAR_KEY}
                />}
            {resource?.length === 0 && !loading ? <>
                <div className="bookingInstructor-empty">
                    <BookingInstructorEmpty />
                </div>
            </> : null}
        </div>
        <DialogComponent
            isOpen={hasBookingForm}
            onCloseModal={() => handleCloseBooking()}
            title={translator("SESSION.BOOK_SESSIONS")}
            styles="max-w-[500px] min-h-[276px] my-0 rounded-lg !py-2 !px-6"
            isShowClose={true}
            child={
                <BookingForm onClose={handleCloseBooking} bookSlot={bookSlot} enrollmentSelected={enrollmentSelected} />
            }
        />
        {loading ? <div className="top-0 left-0 fixed flex items-center justify-center w-full h-full bg-white/50 z-9999">
            <CircleSpin color="text-primary-500" />
        </div> : null}
    </>)
} 