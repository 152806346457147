import React, { useContext, useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { H5PContext } from 'contexts/H5P/H5PContext';
import h5pMiddlware from 'middleware/h5p.middlware';
import { useParams } from 'react-router';
import { TFunction } from 'react-i18next';

interface TrashOptionsProps {
    selected: number | undefined;
    setSelected: React.Dispatch<React.SetStateAction<number | undefined>>;
    selectedTabs: number;
    handleRestore(): void;
    translator: TFunction<'translation'>;
}

const TrashOptions: React.FC<TrashOptionsProps> = ({
    setSelected,
    handleRestore,
    translator,
}) => {
    return (
        <div className="flex justify-end h-full mr-3">
            <div
                onClick={handleRestore}
                className="text-sm  rounded-md text-white  bg-gray-400 w-24 h-9  cursor-pointer flex items-center justify-center mr-3 hover:bg-primary-500focus:bg-gray-700"
            >
                {translator('DASHBOARD.TRASH_BIN.RESTORE')}
            </div>
            <div className="mt-2">
                <XIcon
                    className="h-5 w-5 text-ooolab_dark_2 cursor-pointer hover:text-ooolab_pink_1"
                    onClick={() => setSelected(undefined)}
                />
            </div>
        </div>
    );
};

export default TrashOptions;
