import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ActionsButton from 'components/ActionsButton';
import { EyesIcon } from 'assets/icon/EyesIcon';

interface ActionMenuProp {
    data: any;
    position?: string
}

const ActionMenu: FC<ActionMenuProp> = ({ data, position }) => {
    const { t: translator } = useTranslation();
    const params: { id: string; courseId: string } = useParams();
    const workspaceId = params?.id;
    
    return (
        <ActionsButton
            styleOptions={`${position ? position : 'top-0'} right-[35px]`}
            menus={[
                {
                    label: translator('PREVIEW'),
                    icon: <div className='text-ooolab_dark_1'><EyesIcon width='14' height='14' fill='currentColor'/></div>,
                    action: () => window.open(`/workspace/${workspaceId}/assignment/${data?.id}/preview`),
                    class: 'border-b border-gray-300',
                },
            ]}
        />
    );
};

export default ActionMenu;
