import { ReactNode } from 'react';
import { useTable, useRowSelect, useFlexLayout, Column } from 'react-table';
import { classNames } from 'utils/commonFun';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSticky } from 'react-table-sticky';

const Styles = styled.div`
    table {
        width: 100%;
        border-radius: 8px;

        td {
            border: 0.5px solid #ebebf0;
            padding: 20px;
        }

        thead tr {
            th:first-child {
                border-radius: 8px 0 0 0;
            }

            th:last-child {
                border-radius: 0 8px 0 0;
            }

            th {
                border: 0;
                background: #fafafa;
            }
        }
    }

    .sticky {
        overflow: scroll;

        [data-sticky-td] {
            position: sticky;
        }

        tbody tr {
            [data-sticky-td] {
                background: #ffffff;
            }

            &:hover td {
                background: #f9fafb;
            }
        }
    }
`;

export const RenderTableHeader = ({ value, className }: { value: string | ReactNode; className?: string }) => (
    <p className={classNames('font-semibold text-xs text-ooolab_dark_1 p-5 h-full flex items-center justify-center', className)}>
        {value}
    </p>
);

export const RenderTableCell = ({ value, className, color }: { value: string | ReactNode; className?: string; color?: string }) => (
    <span
        className={classNames('text-sm text-dark-300 font-semibold h-full flex items-center justify-center', className)}
        style={{ color: color }}
    >
        {value}
    </span>
);

export const LinkCustom = ({ to, className, value }: { to: any; className?: string; value: string }) => (
    <Link to={to} className={classNames('text-sm text-primary-500 font-semibold h-full flex items-center justify-start', className)}>
        {value}
    </Link>
);

const Table = ({
    columns,
    data = [],
    updateData,
    initialState,
}: {
    columns: Column<any>[];
    data: any;
    updateData?: any;
    initialState?: any;
    noBorder?: boolean;
    isBorderFirstColum?: boolean;
}) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
            updateData,
            initialState,
        },
        useRowSelect,
        useFlexLayout,
        useSticky
    );

    return (
        <Styles>
            <table className="sticky" {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps({
                                        style: { minWidth: column.minWidth, width: column.width },
                                    })}
                                >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Styles>
    );
};

export default Table;
