import { Fragment, useEffect, useMemo, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CloseIcon } from 'assets/icon/CloseIcon';
import { useTranslation } from 'react-i18next';
import { ButtonIcon, ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { SettingSideLeft } from './components/SettingSideLeft/SettingSideLeft';
import { Course } from './components/Course/Course';
import { COURSE_SETTING_TAB, CURRENCY } from 'constant/course.const';
import { Scheduling } from './components/Scheduling/Scheduling';
import { EnrollmentType } from './components/EnrollmentType/EnrollmentType';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import './CourseSetting.scss';
import { useParams } from 'react-router-dom';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { CourseAssignment } from './components/CourseAssignment/CourseAssignment';
import Gradebook from './components/Gradebook/Gradebook';
import Certificate from './components/Certificate/Certificate';
import { useForm } from 'react-hook-form';
import { checkPermission } from 'utils/commonFun';
import { getCourseTemplateSettings, updateTemplateSetting } from 'store/features/courseTemplateSlice';

type CourseTemplateSettingProps = {
    show: boolean;
    onClose: (value?: boolean) => void;
};

const DURATION_TYPE = [
    { label: 'Month(s)', value: 'month' },
    { label: 'Week(s)', value: 'week' },
    { label: 'Day(s)', value: 'day' },
];

export const CourseTemplateSetting = ({ show, onClose }: CourseTemplateSettingProps) => {
    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
        watch,
        reset,
        setValue,
        getValues,
        clearErrors,
        unregister,
    } = useForm({
        defaultValues: {
            assignment_release_format: 'after_lesson',
            assigment_due_date: null,
            assignment_max_retry: 1,
            allow_ready: false,
            has_due_date: false,
            learning_os_grading: true,
            allowFlexible: false,
            duration: null,
            enrollment_type: null,
            duration_type: null,
            learningGroups: [],
            schedule_type: null,
            vc_link: null,
            location: null,
            eLearning: false,
            course_completion_weight: 25,
            assignment_grade_weight: 25,
            lesson_goal_weight: 25,
            attendance_weight: 25,
            allow_instructor_grading: true,
            has_course_completion: true,
            has_attendance: true,
            has_lesson_goal: true,
            has_assignment_grade: true,
            allowFlexibleEnroll: true,
            availability_from_time: null,
            availability_to_time: null,
            catalogAvailability: null,
            catalogPrice: null,
            price_value: null,
            price_currency: null,
            discount_type: null,
            course_status: null,
            absence_request_type: null, // not_auto_remove_learner | auto_remove_learner
            auto_remove_learner_before_minutes: null,
            remove_learner_hours: null,
            remove_learner_mins: null,
        },
        mode: 'onChange',
    });
    const params: { id: string; courseId: string } = useParams();
    const dispatch = useAppDispatch();
    const { data, peopleNumber, loading, setting } = useAppSelector((state) => state.courseTemplate);
    const { t: translator } = useTranslation();
    const [activeTab, setActiveTab] = useState(COURSE_SETTING_TAB.course);
    const [isOpenModalDelete, setOpenModalDelete] = useState(false);
    const [hasActiveLearner, setHasActiveLearner] = useState(false);

    const handleSave = async (values) => {
        let payload = {
            workspaceId: params.id,
            id: params.courseId,
            ...values,
            auto_enroll_learning_groups: getValues('learningGroups')?.map((item) => item.value),
            duration: Number(values?.duration),
            duration_type: values?.duration_type?.value,
            course_completion_weight: values?.course_completion_weight === null ? null : +values?.course_completion_weight,
            assignment_grade_weight: values?.assignment_grade_weight === null ? null : +values?.assignment_grade_weight,
            lesson_goal_weight: values?.lesson_goal_weight === null ? null : +values?.lesson_goal_weight,
            attendance_weight: values?.attendance_weight === null ? null : +values?.attendance_weight,
            allow_instructor_grading: +values?.allow_instructor_grading,
            price_currency: values?.price_currency?.value || null,
            price_value: +values?.price_value || null,
            absence_request_type: values?.absence_request_type|| 'not_auto_remove_learner',
            auto_remove_learner_before_minutes: ((values?.remove_learner_hours || 0) * 60 + Number(values?.remove_learner_mins || 0)) || null,
        };
        if (values?.availability_to_time) {
            payload.availability_to_time = (new Date(values?.availability_to_time)).setHours(23, 59, 59);
        }
        if (!payload?.duration) delete payload.duration;
        if (payload?.discount_type === 'none') {
            delete payload.discount_type
        };
        if (payload?.enrollment_type === 'credit') delete payload.auto_enroll_learning_groups;
        
        const response = await dispatch(updateTemplateSetting(payload));
        if (response?.error) {
            Toastify.error(translator("COURSES.SETTING_UPDATE_ERROR"));
        } else {
            Toastify.success(translator("COURSES.SETTING_UPDATE_SUCCESS"));
        }
    };

    const notAllowEdit = useMemo(() => {
        if (!checkPermission('courseSetting', 'U')) {
            return true;
        }
        if (activeTab == COURSE_SETTING_TAB.enrollmentType) {
            return peopleNumber?.number_learner > 0 && data?.status === 'active' && setting?.enrollment_type === 'credit';
        }
        if (activeTab == COURSE_SETTING_TAB.assignment) {
            return data?.status === 'active';
        }
        return false;
    }, [peopleNumber, data?.status, activeTab]);

    useEffect(() => {
        dispatch(
            getCourseTemplateSettings({
                workspaceId: params.id,
                id: params.courseId,
            })
        );
    }, []);

    useEffect(() => {
        if (setting) {
            reset({
                ...setting,
                has_due_date: setting?.assigment_due_date && setting?.assignment_release_format === 'after_lesson',
                learning_os_grading: true,
                allowFlexible: !!(setting?.schedule_type == 'schedule_allow_learner_booking'),
                allowFlexibleEnroll: !!(setting.enrollment_type == 'open_auto_enroll'),
                course_completion_weight: setting?.course_completion_weight,
                assignment_grade_weight: setting?.assignment_grade_weight,
                lesson_goal_weight: setting?.lesson_goal_weight,
                attendance_weight: setting?.attendance_weight,
                allow_instructor_grading: setting?.allow_instructor_grading,
                duration_type: DURATION_TYPE.find((item) => item.value == setting.duration_type),
                eLearning: setting?.enable_assignment || setting?.enable_learning_material || setting?.enable_supplementary_materials,
                availability_to_time: setting?.availability_to_time ? new Date(setting?.availability_to_time) : null,
                availability_from_time: setting?.availability_from_time ? new Date(setting?.availability_from_time) : null,
                catalogAvailability: setting?.availability_from_time ? 'dateRange' : 'indefinite',
                catalogPrice: setting?.price_value ? 'price' : 'free',
                price_value: setting?.price_value || null,
                price_currency: CURRENCY.find((item) => item.value == setting.price_currency),
                course_status: data?.status,
                remove_learner_hours: Math.trunc((setting?.auto_remove_learner_before_minutes || 0) / 60) || null,
                remove_learner_mins: ((setting?.auto_remove_learner_before_minutes || 0) % 60) || null,
            });
            setValue('has_course_completion', setting?.course_completion_weight !== null);
            setValue('has_attendance', setting?.attendance_weight !== null);
            setValue('has_lesson_goal', setting?.lesson_goal_weight !== null);
            setValue('has_assignment_grade', setting?.assignment_grade_weight !== null);
            if (setting?.price_value && !setting?.discount_type) {
                setValue('discount_type', 'none');
            }
        }
    }, [setting, data?.status]);

    useEffect(() => {
        const values = getValues();
        if (values) {
            reset({
                ...getValues(),
                has_due_date: values?.assigment_due_date && values?.assignment_release_format === 'after_lesson',
                learning_os_grading: true,
                allowFlexible: !!(values?.schedule_type == 'schedule_allow_learner_booking'),
                allowFlexibleEnroll: !!(values.enrollment_type == 'open_auto_enroll'),
                course_completion_weight: values?.course_completion_weight,
                assignment_grade_weight: values?.assignment_grade_weight,
                lesson_goal_weight: values?.lesson_goal_weight,
                attendance_weight: values?.attendance_weight,
                allow_instructor_grading: values?.allow_instructor_grading,
                availability_from_time: values?.availability_from_time ? new Date(values?.availability_from_time) : null,
                availability_to_time: values?.availability_to_time

            });
            setValue('has_course_completion', values?.course_completion_weight !== null);
            setValue('has_attendance', values?.attendance_weight !== null);
            setValue('has_lesson_goal', values?.lesson_goal_weight !== null);
            setValue('has_assignment_grade', values?.assignment_grade_weight !== null);
        }
    }, [activeTab]);

    return (
        <Transition appear show={show} as={Fragment}>
            <Dialog as="div" className="relative z-[99]" onClose={() => { }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/30 bg-opacity-25" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="panelSeting">
                                <Dialog.Title as="h3" className="courseSettings-header">
                                    {translator('Settings')}
                                    <div className="courseSettings-close">
                                        <ButtonIcon onClick={onClose}>
                                            <span className="p-1">
                                                <CloseIcon />
                                            </span>
                                        </ButtonIcon>
                                    </div>
                                </Dialog.Title>
                                <div className="courseSettings-content">
                                    <div className="courseSettings-sideLeft">
                                        <SettingSideLeft hasCourse={false} name={activeTab} handleChangeTabs={setActiveTab} />
                                    </div>

                                    <form className="courseSettings-main ">
                                        <div>
                                            {activeTab == COURSE_SETTING_TAB.course ? (
                                                <Course
                                                    formProps={{
                                                        control,
                                                        register,
                                                        formState: { errors },
                                                        watch,
                                                        reset,
                                                        setValue,
                                                        getValues,
                                                    }}
                                                    notAllowEdit={notAllowEdit}
                                                    dataCourseSetting={setting}
                                                />
                                            ) : null}
                                            {activeTab == COURSE_SETTING_TAB.scheduling ? (
                                                <Scheduling
                                                    formProps={{
                                                        control,
                                                        register,
                                                        formState: { errors },
                                                        watch,
                                                        reset,
                                                        setValue,
                                                        getValues,
                                                        clearErrors,
                                                    }}
                                                    notAllowEdit={notAllowEdit}
                                                />
                                            ) : null}
                                            {activeTab == COURSE_SETTING_TAB.enrollmentType ? (
                                                <EnrollmentType
                                                    formProps={{
                                                        control,
                                                        register,
                                                        formState: { errors },
                                                        watch,
                                                        reset,
                                                        setValue,
                                                        getValues,
                                                        clearErrors,
                                                        unregister,
                                                    }}
                                                    notAllowEdit={notAllowEdit}
                                                    hasCourse={false}
                                                />
                                            ) : null}
                                            {activeTab == COURSE_SETTING_TAB.assignment ? (
                                                <CourseAssignment
                                                    formProps={{
                                                        control,
                                                        register,
                                                        formState: { errors },
                                                        watch,
                                                        reset,
                                                        setValue,
                                                        getValues,
                                                    }}
                                                    notAllowEdit={notAllowEdit}
                                                />
                                            ) : null}
                                            {activeTab == COURSE_SETTING_TAB.gradebook ? (
                                                <Gradebook
                                                    formProps={{
                                                        control,
                                                        register,
                                                        formState: { errors },
                                                        watch,
                                                        reset,
                                                        setValue,
                                                        getValues,
                                                    }}
                                                    notAllowEdit={notAllowEdit}
                                                />
                                            ) : null}
                                            {activeTab == COURSE_SETTING_TAB.certificate ? (
                                                <Certificate
                                                    formProps={{
                                                        control,
                                                        register,
                                                        formState: { errors },
                                                        watch,
                                                        reset,
                                                        setValue,
                                                        getValues,
                                                    }}
                                                    notAllowEdit={(notAllowEdit)}
                                                />
                                            ) : null}
                                        </div>
                                    </form>
                                </div>
                                <div className="courseSettings-footer">
                                    {checkPermission('courseSetting', 'U') && <div className="courseSettings-actions">
                                        <ButtonOutline type="button" onClick={onClose}>
                                            {translator('CANCEL')}
                                        </ButtonOutline>
                                        <ButtonPrimary
                                            type="submit"
                                            onClick={handleSubmit(handleSave)}
                                            form="hook-form"
                                            disabled={loading == 'pending' || notAllowEdit}
                                        >
                                            {loading == 'pending' ? <CircleSpin className="mr-1" /> : null}
                                            {translator('SAVE')}
                                        </ButtonPrimary>
                                    </div>}

                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        {isOpenModalDelete && (
                            <div className="backdropModal">
                                <div className="modalDeleteCourse">
                                    <div className="modalDeleteCourse-header">
                                        <span className="title">{translator('DELETE')}</span>
                                        <div onClick={() => setOpenModalDelete(false)}>
                                            <CloseIcon />
                                        </div>
                                    </div>
                                    <div className="modalDeleteCourse-mainContent">
                                        {hasActiveLearner && <div className="text">{translator('COURSES.DELETE_COURSE_WARNING')}</div>}
                                        <div className="mt-2 text">{translator('COURSES.DELETE_COURSE')}</div>
                                        <div className="groupBtn">
                                            <ButtonOutline type="button" onClick={() => setOpenModalDelete(false)}>
                                                {translator('CANCEL')}
                                            </ButtonOutline>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
