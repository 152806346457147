export const attendanceOption = (translator) => [
  {
      id: 1,
      value: 'present',
      label: translator('EVALUATE.PRESENT'),
  },
  {
      id: 2,
      value: 'late',
      label: translator('EVALUATE.LATE'),
  },
  {
      id: 3,
      value: 'absent',
      label: translator('EVALUATE.ABSENT'),
  },
]