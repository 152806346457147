import { LearningPathBuilder } from 'components/Academics/LearningPath/LearningPathBuilder/LearningPathBuilder';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';

const LearningPathBuilderPage = () => {
    return (
        <ManagePermissionWrapper >
            <LearningPathBuilder />
        </ManagePermissionWrapper >

    );
};

export default LearningPathBuilderPage;
