import TeacherDashboard from 'components/Reports/TeacherDashboard';
import React from 'react';
import ReportPermission from '../ReportPermission';

const TeacherDashboardPage: React.FC<{ setAuthStorage: React.Dispatch<React.SetStateAction<boolean>> }> = ({ setAuthStorage }) => {
    return (
        <ReportPermission>
            <TeacherDashboard />
        </ReportPermission>
    );
};

export default TeacherDashboardPage;