import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import lodash from 'lodash';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { classNames } from 'utils/commonFun';

const ManagementMasterPage = ({ children }: {children?: React.ReactNode}) => {
    const history = useHistory();
    const { t: translator } = useTranslation();

    const params: { id: string } = useParams();
    const { url, path } = useRouteMatch();
    const [breadCrumbState, setBreadCrumbState] = useState<{ name: string; path: string; isLoading: boolean; id?: string }[]>(() => {
        let prevAdmin = false;
        if (history.location?.state) {
            const historyState: any = history.location.state;
            if (historyState?.prevPath) {
                if (historyState?.prevPath.split('/')[3] === 'admin') {
                    prevAdmin = true;
                }
            }
        }
        const workspaceIdIndex = url.split('/').findIndex((i) => i === `${params.id}`);

        const splittedUrl = url.split('/').slice(workspaceIdIndex);
        splittedUrl.shift(); //remove / from url

        const pathSplitted = path.split('/').slice(workspaceIdIndex);
        pathSplitted.shift(); //remove / from path

        const mergePathWithURL = splittedUrl.map((i, index) => {
            if (pathSplitted[index]) {
                const hasRouteParam = pathSplitted[index].includes(':');
                const pathOfBreadCrumb = url
                    .split('/')
                    .slice(0, index + workspaceIdIndex + 2)
                    .join('/');
                const hasCourseIssue = pathOfBreadCrumb.split('/').reverse()[0] === 'course';
                return {
                    id: hasRouteParam ? pathSplitted[index].slice(1) : '', //id in route
                    name: hasCourseIssue ? 'Courses' : lodash.startCase(i.split('-').join(' ')),
                    path: hasCourseIssue ? pathOfBreadCrumb.replace('course', 'courses') : pathOfBreadCrumb,
                };
            }
        });
        if (splittedUrl.length) {
            if (workspaceIdIndex) {
                const tmpBreadCrumbState = [];
                mergePathWithURL.forEach((i) => {
                    if (i) {
                        tmpBreadCrumbState.push({
                            name: i.name,
                            path: i.path,
                            isLoading: !!i.id,
                            id: i.id,
                        });
                    }
                });
                if (prevAdmin) {
                    tmpBreadCrumbState[0] = {
                        name: translator('BREADCRUMB.ADMIN'),
                        path: `/workspace/${params.id}/admin`,
                        isLoading: false,
                        id: '',
                    };
                }

                return tmpBreadCrumbState;
            }
        }
        return [];
    });
    const {
        getWorkspaceDetailState: { currentRouteDetail },
    } = useContext(GetWorkspaceContext);

    useEffect(() => {
        if (currentRouteDetail.length) {
            const tmpBreadCrumbState = [...breadCrumbState];
            const newState = tmpBreadCrumbState.map((i) => {
                if (!i.isLoading) {
                    return i;
                }
                const item = currentRouteDetail.find((j) => j.routeId === i.id);
                return {
                    ...i,
                    isLoading: false,
                    name: item ? item.name : i.name,
                };
            });
            setTimeout(() => setBreadCrumbState(newState), 1000);
        }
    }, [currentRouteDetail]);

    const handleTranslate = (t: string) => {
        let text = t;
        switch (t) {
            case 'H 5 P Content':
                text = translator('BREADCRUMB.H5P');
                break;
            case 'Admin':
                text = translator('BREADCRUMB.ADMIN');
                break;
            case 'Courses':
                text = translator('BREADCRUMB.COURSE');
                break;
            case 'Lesson':
                text = translator('BREADCRUMB.LESSON');
                break;
            case 'Trash':
                text = translator('BREADCRUMB.TRASH');
                break;
            case 'Management':
                text = translator('BREADCRUMB.MANAGEMENT');
                break;
            case 'Class':
                text = translator('BREADCRUMB.CLASS');
                break;
            default:
                break;
        }
        return text;
    };
    return (
        <>
            <div className="flex items-center p-4">
                <div className="flex items-center space-x-2 text-sm font-normal text-ooolab_dark_1">
                    {breadCrumbState.map((i, index) =>
                        i.isLoading ? (
                            <span
                                key={`${i.name}-${index}-breadcrumb-loading`}
                                className="w-ooolab_w_14 h-ooolab_h_3 bg-ooolab_dark_50 opacity-70 animate-pulse"
                            ></span>
                        ) : (
                            <p
                                className={classNames(
                                    'flex items-center space-x-2',
                                    index === breadCrumbState.length - 1 && 'font-semibold'
                                )}
                                key={`${i.name}-${index}-breadcrumb-name`}
                            >
                                {i.name !== 'Management' ? (
                                    <Link className="overflow-ellipsis whitespace-nowrap overflow-hidden" to={i.path}>
                                        {handleTranslate(i.name)}
                                    </Link>
                                ) : (
                                    <span className="overflow-ellipsis whitespace-nowrap overflow-hidden">
                                        {handleTranslate(i.name)}
                                    </span>
                                )}

                                {(index < breadCrumbState.length - 1 && <span>/</span>) || null}
                                {/* {(index < breadCrumbState.length - 1 && <ChevronRightIcon className="w-3 h-3" />) || null} */}
                            </p>
                        )
                    )}
                </div>
            </div>
            <>{children}</>
        </>
    );
};

export default ManagementMasterPage;
