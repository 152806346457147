import LockIcon from 'assets/icon/LockIcon';
import SesstionIcon from 'assets/icon/SesstionIcon';
import './MultipleSession.scss'
import { useTranslation } from 'react-i18next';

export default function MultipleSession({ numberOfSession, isExpireSession }) {
    const {t: translator} = useTranslation();
    return (
        <div  key={`listSession_${numberOfSession}`} 
            className={`MultipleSession`}
        >
            <div className="session">
                <SesstionIcon /> <span className="font-medium">{numberOfSession + 1}</span> {translator('SESSIONS')}
            </div>
            {
                isExpireSession && (
                <div className="icon">
                    <LockIcon />
                </div>
                )
            }
        </div>
    );
}
