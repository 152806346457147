import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PublicLayout } from '_shared';
import { FilePreview } from './FilePreview/FilePreview';
import { base64ToObject } from 'utils/commonFun';

export const LibraryPublicPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const info: any = searchParams.get("info");
    const [library, setLibrary] = useState<any>();

    useEffect(() => {
        if (info) {
            base64ToObject(info)
                .then(obj => setLibrary(obj))
                .catch(error => setLibrary(null));
        }
    }, [info]);

    return (
        <PublicLayout>
            <FilePreview data={library} isPrivate={false} />
        </PublicLayout>
    );
};