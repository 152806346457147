import React from 'react';

import './style.css';
import StepsSetup from './StepsSetup';
import Logo from 'components/Logo/Logo';
import { useTranslation } from 'react-i18next';

const ImagesSlider: React.FC = ({ }) => {
    const { t: translator } = useTranslation();
    return (
        <>
            <div className="img-bg-resgis relative  h-screen flex flex-col justify-center">
                <div className=" items-center  flex flex-col justify-center ">
                    <div className="mx-8 mt-20 tall:mt-28 text-white">
                        <p className="text-xl 2xl:text-2xl font-semibold">
                            {translator('SIGN_UP_FOLLOW.START_TEACHING')}
                        </p>
                        <p className=" text-sm 2xl:text-base font-light mt-2 tall:mt-5">
                            {translator('SIGN_UP_FOLLOW.SET_UP_YOUR')}
                        </p>
                    </div>
                </div>
                <div className="mt-8">
                    <StepsSetup text={translator('SIGN_UP_FOLLOW.CREATE_CONTENT_H5P')} />
                    <StepsSetup text={translator('SIGN_UP_FOLLOW.RAPIDLY_SCHEDULE')} />
                    <StepsSetup text={translator('SIGN_UP_FOLLOW.INVITE_LEARNERS')} />
                    <StepsSetup text={translator('SIGN_UP_FOLLOW.TRACK_LEARNER')} />
                    <StepsSetup
                        text={translator('SIGN_UP_FOLLOW.OPTIMIZE_LEARNING')}
                        lastStep={true}
                    />
                </div>
            </div>
        </>
    );
};

export default ImagesSlider;
