import React from 'react';
import Logo from 'components/Logo/Logo';

interface OOOLapLogoProps {
    divClass?: string;
    child?: any;
}

const OOOLapLogo: React.FC<OOOLapLogoProps> = ({ child }) => {
    return (
        <>
            {!!child ? (
                child
            ) : (
                <Logo className="absolute max-h-[80px] lg:w-20 top-5 lg:top-8 left-6 lg:left-8" alt=""/>
            )}
        </>
    );
};

export default OOOLapLogo;
