import { Tab } from '@headlessui/react';
import Calendar from './Calendar/Calendar';
import './Scheduling.scss';
import { SessionList } from './SessionList/SessionList';
import { useTranslation } from 'react-i18next';

const listTab = ['SESSION.CALENDAR', 'SESSION.SESSION_LIST'];

const Scheduling = ({userData}) => {
    const {t} = useTranslation();
    return (
        <div className='CoursePeople -m-5'>
            <Tab.Group>
                <div className="CoursePeople-title">{t("USER.SCHEDULE")}</div>
                <Tab.List className="CoursePeople-tablist">
                    {listTab.map((i) => (
                        <Tab
                            className={({ selected }) => {
                                const tabClassName = `tagItem ${
                                    selected ? 'active ' : 'disable'
                                }`;
                                return tabClassName;
                            }}
                            key={i}
                        >
                            {t(i)}
                        </Tab>
                    ))}
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>
                        <Calendar />
                    </Tab.Panel>
                    <Tab.Panel>
                        <SessionList userData={userData}/>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
};

export default Scheduling;
