import DialogComponent from 'components/Dialog/DialogComponent';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import courseService from 'services/course.service';
import { formatOptionsInstructorNonEmail } from '../../CoursePeople/utils';
import { AsyncSelectField } from 'components/Select';
import { ButtonOutline, ButtonPrimary, InputFile, LoadingPopup, Toastify, Tooltip } from '_shared';
import uploadService from 'services/upload.service';
import { useAppSelector } from 'hooks/hooks';
import { getFileExtensionFromUrl } from 'utils/commonFun';
import { CircleInformationIcon, VideoUpload } from "assets/icon";
import { InformationCircleIcon } from '@heroicons/react/outline';
import ReactTooltip from 'react-tooltip';


const accept_file_upload = ['.pdf', 'application/pdf', 'image/png', 'image/jpg', 'image/jpeg'];
const mimeTypePdf = ['.pdf', 'application/pdf',];


interface IModalNewCertificate {
    isOpen: boolean;
    onClose: () => void;
    refreshData: () => void;
    certiData?: any;
}

export default function ModalNewCertificate({ isOpen, onClose, refreshData, certiData }: IModalNewCertificate) {
    const { t: translator } = useTranslation();
    const params: any = useParams();
    const workspaceId = params?.id;
    const courseId = params?.courseId;
    const [imgPath, setImgPath] = useState<any>(undefined);
    const { data: detail } = useAppSelector((state) => state.course);
    const [loadingUpload, SetLoadingUpload] = useState<boolean>(false)

    const {
        handleSubmit,
        control,
        formState: { errors, dirtyFields },
        reset,
    } = useForm({ mode: 'onChange' });

    const fetchLearner = async (payload?: any) => {
        try {
            const res = await courseService.getLeanerCertificate(workspaceId, courseId, payload);
            return {
                items: res?.data?.items || [],
                total: res?.data?.total || 0,
            };
        } catch (error) {
            Toastify.error();
        }
    };

    const handleLoadLearner = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional.page,
            per_page: 20,
            order: 'desc',
            q: inputValue || null,
            query_ready_cert: true,
        };
        if (!payload?.q) delete payload?.q
        try {
            const { items, total } = await fetchLearner(payload);
            const newOptions = items?.map((el: any) => {
                return {
                    id: el?.id,
                    display_name: el?.display_name,
                    value: el?.id,
                    avatar_url: el.avatar_url,
                };
            });

            return {
                options: newOptions,
                hasMore: additional?.page < total / 20,
                additional: {
                    page: additional?.page + 1,
                },
            };
        } catch (error) {
            Toastify.error();
        }
    };

    const createCertificateLearner = (data) => {
        const payload = {
            file: imgPath
        };
        courseService
            .onCertificateCourse(workspaceId, courseId, data?.learner?.id || certiData?.learner?.id, payload)
            .then(() => {
                Toastify.success();
                refreshData();
                onClose();
                reset({});
            })
            .catch(() => {
                Toastify.error();
            });
    };

    const onSubmit = (values) => {
        createCertificateLearner(values)
    };

    useEffect(() => {
        return () => {
            reset({});
        };
    }, []);

    useEffect(() => {
        if (certiData) {
            reset({
                name: certiData?.name,
                learner: {
                    value: certiData?.learner?.id,
                    name: certiData?.learner?.display_name,
                    label: certiData?.learner?.display_name,
                    avatar_url: certiData?.learner.avatar_url,
                }
            });
        }
    }, [certiData]);


    const checkImageUpload = (file: any) => {
        SetLoadingUpload(true);
        const mimeType = ['image/jpeg', 'image/png', '.pdf', 'application/pdf',];
        const limitFileSize = 100 * 1024 * 1024;

        const fileSize = file?.size || 0;
        const fileExtenion = file?.type || '';

        if (!mimeType.includes(fileExtenion) || fileSize > limitFileSize) {
            Toastify.error(translator('COURSES.YOUR_IMAGE_SHOULD_BE_UPLOADED_AS_JPG_PNG_JPED_FILES_AND_MUST_BE_LESS_THAN_100MB_IN_SIZE'))
            SetLoadingUpload(false);
        } else {
            uploadService.uploadFile(
                file,
                async (path: string) => {
                    setImgPath(path);
                    SetLoadingUpload(false);
                    Toastify.success();
                },
                () => { Toastify?.error() }
            );
        }
    };

    const onCheckIsPdf = () => {
        const fileExtenion = getFileExtensionFromUrl(imgPath || certiData?.file);
        if (fileExtenion === 'pdf') return true
        return false
    }

    return (
        <>        <DialogComponent
            isOpen={isOpen}
            onCloseModal={onClose}
            title={certiData ? translator('COURSES.EDIT_CERTIFICATE') : translator('COURSES.NEW_CERTIFICATE')}
            styles="max-w-lg"
            child={
                <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label className='text-xs text-gray-800 font-semibold'>{translator('COURSE')}</label>
                        <p className='text-sm text-gray-800'>{detail?.title}</p>
                    </div>
                    <AsyncSelectField
                        control={control}
                        label={translator('COURSES.LEANER')}
                        name="learner"
                        placeholder={translator('COURSES.LEANER')}
                        hideSelectedOptions
                        closeMenuOnSelect={true}
                        errors={errors}
                        loadOptions={handleLoadLearner}
                        formatOptionLabelCustom={formatOptionsInstructorNonEmail}
                        className="mt-5 relative z-20"
                        toolTip='Only learners who have completed the course will be available for selection.'
                        isRequired
                    />
                    <div className='mt-5'>
                        <label htmlFor="learner" className="text-gray-800 font-semibold text-xs">
                            {translator('COURSES.CERTIFICATE_FILE')}
                            <span className="text-red-500"> *</span>
                        </label>
                        <InputFile isPdf={onCheckIsPdf()} accept={accept_file_upload?.toString()} isDisable={false} onChange={checkImageUpload} imgDefault={imgPath ? process.env.REACT_APP_HOST_CDN + imgPath : certiData?.file} className="h-[360px] bg-center" textHelp={translator('COURSES.ACCEPT_FILE_CERTIFICATE')}
                            subTextHelp={
                                <div className='text-gray-500 text-xs leading-6'>
                                    <p>{translator('COURSES.RECOMMENDED_FILE_CERTIFICATE')}</p>
                                    <p>{translator('COURSES.72_PPI')}</p>
                                    <p>{translator('COURSES.200_PPI')}</p>
                                    <p>{translator('COURSES.300_PPI')}</p>
                                </div>}
                        />
                    </div>

                    <div className="mt-5 flex gap-5 justify-center">
                        <ButtonOutline type="button" onClick={onClose}>
                            {translator('CANCEL')}
                        </ButtonOutline>
                        <ButtonPrimary disabled={
                            !(
                                dirtyFields &&
                                (Object.keys(dirtyFields)
                                    .length) || imgPath
                            )} type="submit">
                            {certiData ? translator('UPDATE') : translator('CREATE')}
                        </ButtonPrimary>
                    </div>
                </form>
            }
        />
            {loadingUpload && <LoadingPopup message={translator('COURSES.UPLOADING')} loading={loadingUpload} />}
        </>
    );
}
