import { useMemo, useRef, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { PencilAltIcon, TrashIcon, PlusIcon, EyeIcon, DownloadIcon, XCircleIcon } from '@heroicons/react/outline';
import { Column } from 'react-table';
import ReactTooltip from 'react-tooltip';
import { ClassResourcesItemsType } from 'types/Class.type';
import { downloadWithAxios, getIndexPage } from 'utils/commonFun';
import { ConvertMineTypeResource, ValidateFileUpload } from 'utils/validate';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import Table from 'components/Management/components/table';
import { RenderGroupsHeader } from '../ClassSession/components/TableCustom';
import TablePagination from 'components/Pagination';
import ActionsButton from 'components/ActionsButton';
import DialogComponent from 'components/Dialog/DialogComponent';
import ButtonComponent from 'components/Button/ButtonComponent';
import Spinner from 'components/Spinner';
import Waiting from 'components/Waiting';
import ModalAddH5p from './ModalAddH5p';
import ModalViewResource from './ModalViewResource';

import ClassService from 'services/class.service';
import resourcesService from 'services/resources.service';
import { UploadIcon } from 'assets/icon/UploadIcon';
import { accept_file_input } from 'constant/form.const';
import { UserContext } from 'contexts/User/UserContext';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import axios from 'axios';
import { useDebouncedState } from 'hooks/useDebounce';
import SearchBox from 'components/V2/SearchBox';
import NO_RESOURCES_IMG from 'assets/img/empty-state/resource.png';
import { defaultPagination } from 'constant/util.const';
import { Toastify } from '_shared';

const index = () => {
    const { t } = useTranslation();
    const file = useRef<any>(null);
    const params: any = useParams();
    const [keyword, setKeyword] = useDebouncedState('');

    const [isOpening, setIsOpening] = useState(false);
    const [hasView, setHasView] = useState(false);
    const [hasDownload, setHasDownload] = useState(false);
    const [hasEdit, setHasEdit] = useState(false);
    const [hasDelete, setHasDelete] = useState(false);
    const [hasAddH5p, setHasAddH5p] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [resourceSelected, setResourceSelected] = useState<ClassResourcesItemsType | any>(null);
    const [resourceList, setResourceList] = useState<ClassResourcesItemsType[]>([]);
    const [indexPage, setIndexPage] = useState('1-50');
    const [fileName, setFileName] = useState('');
    const [pagination, setPagination] = useState({
        page: 1,
        per_page: defaultPagination?.per_page,
        order: 'asc',
        total: 1,
        sort_by: 'file_name',
    });

    const { userState } = useContext(UserContext);
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);

    const { result: detailWorkspace } = getWorkspaceDetailState;
    const { result: userInformation } = userState;

    const {
        membership: { role, type },
    } = detailWorkspace;
    const timeZone = useMemo(() => userInformation?.time_zone || 'UTC', [userInformation?.time_zone]);

    const { id: workspaceId, classId } = params;

    const translator = (key: string, option?: any) => {
        return t('RESOURCES.' + key, option);
    };

    useEffect(() => {
        getResourceList();
    }, [keyword]);

    const initialState = {
        hiddenColumns: ['id'],
    };

    const columns: Column<any>[] = useMemo(
        () => [
            { accessor: 'id' },
            {
                Header: () => (
                    <div className="pl-3 text-left">
                        <RenderGroupsHeader value={translator('NAME')} />
                    </div>
                ),
                accessor: 'file_name',
                Cell: (d: any) => {
                    return (
                        <>
                            <div
                                className=" p-3 font-semibold text-xs text-primary-500 cursor-pointer truncate"
                                onClick={() => {
                                    setResourceSelected(d?.row?.original);
                                    viewFile(d?.row?.original.id);
                                }}
                                data-tip={d?.value}
                                data-for={d?.row?.original.id.toString() + 'file_name'}
                            >
                                {d?.value}
                            </div>
                            {
                                //@ts-ignore
                                <ReactTooltip
                                    place="bottom"
                                    type="dark"
                                    effect="float"
                                    id={d?.row?.original.id.toString() + 'file_name'}
                                    getContent={(content) => <div className="w-auto h-auto">{content}</div>}
                                    disable={d?.value?.length < 30}
                                />
                            }
                        </>
                    );
                },
                width: 100,
            },
            {
                Header: () => (
                    <div className="pl-3 text-left">
                        <RenderGroupsHeader value={translator('CREATED_DATE')} />
                    </div>
                ),
                accessor: 'created_at',
                Cell: (d: any) => {
                    return (
                        <div className=" pl-3  py-3 font-normal text-ooolab_dark_1 text-xs">
                            {dayjs(d?.value).tz(timeZone).format('DD/MM/YYYY')}
                        </div>
                    );
                },
                width: 60,
            },
            {
                Header: () => <div className="hidden"></div>,
                Cell: () => <div className=" hidden"></div>,
                accessor: 'colum_hidden_1',
                width: 120,
            },
            {
                Header: () => <div className="hidden"></div>,
                Cell: () => <div className=" hidden"></div>,
                accessor: 'colum_hidden_2',
                width: 120,
            },
            {
                Header: () => (
                    <div className="text-center">
                        <RenderGroupsHeader value={translator('ACTION')} />
                    </div>
                ),
                accessor: 'actions',
                Cell: ({ row, column }: { row: any; column: Column }) => {
                    return (
                        <div className="h-full flex items-center justify-center">
                            <ActionsButton
                                styleOptions="top-0 right-[35px]"
                                menus={[
                                    {
                                        label: 'View',
                                        icon: <EyeIcon className="w-4 h-4" />,
                                        isDisabled: false,
                                        action: () => {
                                            setResourceSelected(row?.original);
                                            viewFile(row?.original.id);
                                        },
                                    },
                                    {
                                        label: 'Download',
                                        icon: <DownloadIcon className="w-4 h-4" />,
                                        isDisabled: row?.original?.file_extension === '.h5p',
                                        action: () => {
                                            if (row?.original?.file_extension === '.h5p') return;
                                            downloadFile(row?.original);
                                        },
                                    },
                                    {
                                        label: 'Edit',
                                        icon: <PencilAltIcon className="w-4 h-4" />,
                                        isDisabled:
                                            row?.original?.file_extension === '.h5p' || (type === 'instructor' && role === 'member'),
                                        action: () => {
                                            if (
                                                row?.original?.file_extension === '.h5p' ||
                                                (type === 'instructor' && role === 'member')
                                            )
                                                return;
                                            setFileName(row?.values?.file_name || '');
                                            setResourceSelected(row?.original);
                                            setHasEdit(true);
                                        },
                                    },
                                    {
                                        label: 'Delete',
                                        icon: <TrashIcon className="w-4 h-4" />,
                                        isDisabled: type === 'instructor' && role === 'member',
                                        action: () => {
                                            if (type === 'instructor' && role === 'member') return;
                                            setResourceSelected(row?.original);
                                            setHasDelete(true);
                                        },
                                    },
                                ]}
                                disable={false}
                            />
                        </div>
                    );
                },
                width: 20,
            },
        ],
        [timeZone, resourceList]
    );

    const getResourceList = async (page: number | string = 1) => {
        setLoading(true);
        ClassService.getResourcesService({ workspaceId, classId: classId }, { page, per_page: 20, order: 'desc', q: keyword })
            .then((res) => {
                const { items, page, per_page, order, sort_by, total } = res.data;
                setResourceList(items);
                setPagination({ page, per_page, order, sort_by, total });
                setIndexPage(getIndexPage(page, per_page, items.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const handleChangePagination = (page: number) => {
        getResourceList(page);
    };

    const handleInputFile = (e: any) => {
        if (e.target?.files?.length <= 0) {
            return;
        }
        const extensionSplit = e?.target?.files[0]?.name.split('.');
        const extension = extensionSplit[extensionSplit.length - 1];
        const sizeMB = e?.target?.files[0]?.size / 1024 / 1024;

        if (ValidateFileUpload(extension) && sizeMB <= 101) {
            setUploading(true);
            resourcesService.uploadFile(
                e.target.files[0],
                async (url) => {
                    const re = /(?:\.([^.]+))?$/;
                    const fileExtension = `.${re.exec(e.target.files[0]?.name)![1]}`;
                    await ClassService.uploadResourcesService(
                        { workspaceId, classId },
                        {
                            file_extension: fileExtension,
                            mime_type: e.target.files[0]?.type ? e.target.files[0]?.type : ConvertMineTypeResource(extension),
                            file_name: e.target.files[0]?.name,
                            file_path: url,
                        }
                    )
                        .then((res) => Toastify.success())
                        .catch((err) => {
                            const arrayMsgsError = err?.response?.data?.error?.body_params?.map((el) => el?.msg) || [];
                            if (arrayMsgsError.includes('LIMIT_SIZE_EXCEEDED')) Toastify.error('File is larger than 100MB');
                            else if (arrayMsgsError.includes('INVALID_FILE_EXTENSION'))
                                Toastify.error(
                                    'This file type is not supported. The following file types are supported: pdf, doc, docx, png, jpg, jpeg, mp3, mp4, ppt, pptx'
                                );
                            else Toastify.error('File upload failed');
                        })
                        .finally(() => setUploading(false));

                    getResourceList(pagination.page);
                    file.current.value = '';
                },
                (err) => {
                    Toastify.error('File upload failed');
                    file.current.value = '';
                    setUploading(false);
                }
            );
        } else if (!ValidateFileUpload(extension)) {
            file.current.value = '';
            Toastify.error(
                'This file type is not supported. The following file types are supported: pdf, doc, docx, png, jpg, jpeg, mp3, mp4, ppt, pptx'
            );
        } else {
            file.current.value = '';
            Toastify.error('File is larger than 100MB');
        }
    };

    const handleURL = async (_response: any, action: 'download' | 'view') => {
        const { file_url, file_name, mime_type } = _response.data;

        const response: any = await axios({
            url: file_url,
            method: 'get',
            responseType: 'blob',
        }).finally(() => {
            setHasDownload(false);
            setIsOpening(false);
        });
        const blob = new Blob([response.data], { type: mime_type });
        if (action === 'download') {
            downloadWithAxios(blob, file_name, response);
        } else {
            const url = window.URL.createObjectURL(blob);
            const newWindow = window.open(url, '_blank');
            newWindow.addEventListener(
                'load',
                function () {
                    newWindow.document.title = file_name;
                },
                false
            );
        }
    };

    const viewFile = (id: number) => {
        if (!id) return;
        ClassService.viewResourceService({ workspaceId, classId, resourceId: id })
            .then((res) => {
                const { file_extension } = res.data;
                if (file_extension !== '.h5p') {
                    const nonNewTab = ['.docx', '.doc', '.ppt', '.pptx', '.pdf'];
                    if (nonNewTab.includes(file_extension)) {
                        setHasDownload(true);
                        handleURL(res, 'download');
                    } else {
                        setIsOpening(true);
                        handleURL(res, 'view');
                    }
                } else setHasView(true);
            })
            .catch(() => Toastify.error('Can not open file'));
    };

    const downloadFile = (record: any) => {
        if (!record.id) {
            setHasDownload(false);
            return;
        }
        if (record?.file_extension === '.h5p') {
            Toastify.error('h5p content is not allowed to download');
            return;
        }
        setHasDownload(true);
        ClassService.viewResourceService({ workspaceId, classId, resourceId: record.id })
            .then((res) => handleURL(res, 'download'))
            .catch()
            .finally(() => setHasDownload(false));
    };

    const renameFile = () => {
        if (!fileName) {
            setHasEdit(false);
            return;
        }
        if (resourceSelected?.file_extension === '.h5p') {
            Toastify.error('h5p content is not allowed to rename');
            return;
        }
        ClassService.renameResourceService({ workspaceId, classId, resourceId: resourceSelected?.id }, { file_name: fileName })
            .then(() => {
                getResourceList(pagination.page);
            })
            .catch()
            .finally(() => setHasEdit(false));
    };

    const deleteFile = () => {
        if (!resourceSelected?.id) {
            setHasDelete(false);
            return;
        }
        ClassService.deleteResourceService({ workspaceId, classId, resourceId: resourceSelected?.id })
            .then(() => {
                getResourceList(pagination.page);
            })
            .catch()
            .finally(() => setHasDelete(false));
    };

    return (
        <div>
            <div className="flex justify-between items-center p-4 px-3">
                <SearchBox className="max-w-md" onSubmit={(e: any) => setKeyword(e)} />
                <div className="flex">
                    <input
                        ref={file}
                        type="file"
                        onChange={(e) => handleInputFile(e)}
                        className="hidden"
                        accept={accept_file_input.join(', ')}
                        placeholder={''}
                    />
                    <ButtonComponent
                        title="Add H5P"
                        classStyle={'primary-button-apollo mr-3'}
                        icon={<PlusIcon className="w-4 h-4 mr-2" />}
                        onClickButton={() => setHasAddH5p(true)}
                        disable={role !== 'admin'}
                    />
                    <ButtonComponent
                        title={!uploading ? 'Upload' : ''}
                        classStyle={'secondary-button'}
                        icon={
                            <>
                                <UploadIcon fill="#024FEE" className="mr-2" />
                                {uploading && <CircleSpin color="text-[#00559C]" className="mx-auto" />}
                            </>
                        }
                        onClickButton={() => !uploading && role === 'admin' && file?.current?.click()}
                        disable={!uploading && role !== 'admin'}
                    />
                </div>
            </div>
            <div className="w-full border-t border-ooolab_bar_color">
                {loading ? (
                    <Spinner />
                ) : !!!resourceList.length && !loading ? (
                    <NoContentWithImage content={translator('NO_RESOURCES')} img={NO_RESOURCES_IMG} />
                ) : (
                    <>
                        <div className="h-[calc(100vh-273px)] custom-scrollbar overflow-y-scroll">
                            <Table 
                                data={resourceList || []} 
                                columns={columns} 
                                initialState={initialState} 
                            />
                        </div>
                        <div className="flex items-center justify-between border-t border-ooolab_bar_color">
                            <p className="text-ooolab_dark_2 text-ooolab_xs font-semibold leading-ooolab_24px hidden lg:block">
                                {translator('SHOWING_1_50_OF_TOTAL_PAGE', {
                                    fromTo: indexPage,
                                    totalPage: pagination.total,
                                })}
                            </p>
                            <div className="mt-[-1px]">
                                <TablePagination
                                    onClickPagination={handleChangePagination}
                                    perPage={pagination.per_page}
                                    total={pagination.total}
                                    forcePage={pagination.page - 1}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
            <DialogComponent
                isOpen={hasAddH5p}
                title="Add H5P"
                maskClosable={false}
                onCloseModal={() => setHasAddH5p(false)}
                styles="max-w-lg"
                child={
                    <ModalAddH5p
                        workspaceId={workspaceId}
                        classId={classId}
                        callBack={() => {
                            setHasAddH5p(false);
                            getResourceList(pagination.page);
                        }}
                    />
                }
            />
            <DialogComponent
                isOpen={hasView}
                title=""
                maskClosable={false}
                onCloseModal={() => setHasView(false)}
                styles="min-h-[90vh] w-[75%]"
                child={<ModalViewResource workspaceId={workspaceId} classId={classId} resourceId={resourceSelected?.id} />}
            />
            <Waiting
                isLoading={isOpening || hasDownload}
                content={isOpening ? 'Waiting for open file ...' : 'Waiting for download ...'}
            />
            <DialogComponent
                isOpen={hasEdit}
                title="Edit name"
                maskClosable={false}
                onCloseModal={() => setHasEdit(false)}
                child={
                    <div className="flex flex-col px-5 text-ooolab_dark_1">
                        <label htmlFor="fileNameId" className="font-semibold text-xs">
                            Name
                        </label>
                        <input
                            id="fileNameId"
                            type="text"
                            placeholder=""
                            className="mt-2 rounded border px-2 py-1"
                            value={fileName}
                            onChange={(e) => setFileName(e.target.value)}
                        />
                        {fileName?.length > 255 && (
                            <p className="text-xs text-red-500 font-semibold tracking-wider mt-1 leading-4">
                                Max length is 255 characters
                            </p>
                        )}
                        <div className="mt-9 text-center">
                            <ButtonComponent
                                title="Save"
                                classStyle={'primary-button-apollo'}
                                onClickButton={() => renameFile()}
                                disable={!fileName.trim()}
                            />
                        </div>
                    </div>
                }
            />
            <DialogComponent
                isOpen={hasDelete}
                isShowClose={false}
                title=""
                maskClosable={false}
                onCloseModal={() => setHasDelete(false)}
                child={
                    <div className="-mt-6 flex flex-col items-center space-y-7">
                        <XCircleIcon className="w-20 h-20 text-ooolab_error" />
                        <p className="font-semibold text-sm text-ooolab_dark_1">
                            {translator('ARE_YOU_SURE_TO_DELETE_FILE_NAME', { file_name: resourceSelected?.file_name })}
                        </p>
                        <div className="flex justify-center items-center space-x-5">
                            <ButtonComponent
                                title="Cancel"
                                classStyle={'secondary-button'}
                                onClickButton={() => setHasDelete(false)}
                            />
                            <ButtonComponent
                                title="Delete"
                                classStyle={'primary-button-apollo !bg-ooolab_error'}
                                onClickButton={() => deleteFile()}
                            />
                        </div>
                    </div>
                }
            />
        </div>
    );
};

export default index;
