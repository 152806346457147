import httpClient from './../restful.service';
import { REPORT } from 'constant/api.const';

const getLearnersInformation = (workspaceId: string, params?: any) => {
    return httpClient.get(REPORT.getLearnersInformationDashboard(workspaceId), { params });
};
const getLearnersSessions = (workspaceId: string, params?: any) => {
    return httpClient.get(REPORT.getLearnersSessionsDashboard(workspaceId), { params });
};
const getLearnersCreditPackages = (workspaceId: string, params?: any) => {
    return httpClient.get(REPORT.getLearnersCreditPackagesDashboard(workspaceId), { params });
};
const getLearnersInvoices = (workspaceId: string, params?: any) => {
    return httpClient.get(REPORT.getLearnersInvoicesDashboard(workspaceId), { params });
};
const getInstructorsInformation = (workspaceId: string, params?: any) => {
    return httpClient.get(REPORT.getInstructorsInformationDashboard(workspaceId), { params });
};
const getInstructorsSession = (workspaceId: string, params?: any) => {
    return httpClient.get(REPORT.getInstructorsSessionDashboard(workspaceId), { params });
};
const getTransactionsCreditAccount = (workspaceId: string, params?: any) => {
    return httpClient.get(REPORT.getTransactionsCreditAccountDashboard(workspaceId), { params });
};
const getTransactionsCreditBalance = (workspaceId: string, params?: any) => {
    return httpClient.get(REPORT.getTransactionsCreditBalanceDashboard(workspaceId), { params });
};
const getGrade = (workspaceId: string, params?: any) => {
    return httpClient.get(REPORT.getGradeDashboard(workspaceId), { params });
};
const getExportCourses = (workspaceId: string, params?: any) => {
    return httpClient.get(REPORT.getCoursesDashboard(workspaceId), { params });
};
const getExportSessions = (workspaceId: string, params?: any) => {
    return httpClient.get(REPORT.getSessionsDashboard(workspaceId), { params });
};

export default {
    getLearnersInformation,
    getLearnersSessions,
    getLearnersCreditPackages,
    getLearnersInvoices,
    getInstructorsInformation,
    getInstructorsSession,
    getTransactionsCreditAccount,
    getTransactionsCreditBalance,
    getGrade,
    getExportCourses,
    getExportSessions,
};
