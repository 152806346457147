import FullCalendar from "@fullcalendar/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import courseService from "services/course.service";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import ReactTooltip from "react-tooltip";
import "./CalendarResourceTime.scss";
import { UserContext } from "contexts/User/UserContext";
import { ButtonIcon, ConfirmDeleteModal, Toastify } from "_shared";
import { TeacherDashboardService } from "services";
import { SettingsIcon } from "assets/icon/SettingIcon";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks/hooks";

const VIEW_TYPE = {
  resourceTimeGridDay: 'day',
  resourceTimelineWeek: 'week'
}
const enum STATUS_ACTION {
  notAllow,
  edit,
  add
}
const RenderEvent = ({ id, view, event, textColor, backgroundColor, timezone }: any) => {
  return (
    <div className={`flex w-full h-full`}>
      {view?.type === 'resourceTimeGridDay' ? <>
        <div className={`dayivew-bg rounded absolute w-full h-full overflow-y-auto top-0 left-0 z-1 borderType-${textColor} p-1.5`}
          style={{ backgroundColor: backgroundColor }}>
          {textColor == STATUS_ACTION.add ? <div className="overflow-y-auto max-h-full">
            {dayjs(event?.start).isAfter(new Date()) ? <ButtonIcon type="button" onClick={() => { }} className="absolute right-1 top-0.5 text-gray-600 z-10">
              <XIcon className="w-4 h-4" />
            </ButtonIcon> : null}
            <div className="text-xs text-green-600 font-semibold">{event?.title} Availability</div>
            <div className="text-xs text-green-600 font-semibold">Slot</div>
            <div className="text-xs text-green-600 mt-0.5">
              {`${dayjs.utc(event?.start).tz(timezone).format('HH:mm')} - ${dayjs.utc(dayjs(event?.end)).tz(timezone).format('HH:mm')}`}
            </div>
          </div> : null}
        </div></> :
        <div className={`flex w-full h-full items-center justify-center`}>
          <div data-for={id} className="event-bg absolute w-full h-[39px] top-0 left-0 z-1" style={{ backgroundColor: backgroundColor }}></div>
          {textColor == STATUS_ACTION.edit ? <CheckIcon className="text-green-500 w-5 h-5 z-[11] relative mt-2" /> : null}
          {//@ts-ignore
            <ReactTooltip
              type="dark"
              effect="float"
              id={id}
              getContent={(content) => <div>You cannot add availability for this time</div>}
            />}
        </div>}
    </div>
  );
};

const OPTION_CALENDAR = {
  editable: true,
  selectable: true,
  selectMirror: true,
  dayMaxEvents: true,
  weekends: true,
  allDaySlot: false,
}
type CalendarResourceTimeProps = {
  instructor: any,
  onAddSlot: (value) => void,
  onOpenDetail: (value) => void
}
export const CalendarResourceTime = ({ instructor, onAddSlot, onOpenDetail }: CalendarResourceTimeProps) => {
  const {
    userState: { result },
  } = useContext(UserContext);
  const { expandedMenu } = useAppSelector(state => state.configPage)
  const {t: translator} = useTranslation();
  const params: { id: string, courseId } = useParams();
  const calendarRef = useRef<any>();
  const [optionCalendar, setOptionCalendar] = useState(OPTION_CALENDAR);
  const [calendarApi, setCalendarApi] = useState<any>();
  const [modeView, setModeView] = useState<string>('resourceTimelineWeek');
  const [dateRange, setDateRange] = useState<{ start: Date, end: Date }>();
  const [resources, setResources] = useState<any>(null);
  const [events, setEvents] = useState<any>(null);
  const [slotSelect, setSlotSelect] = useState<any>(null);
  const splitString = (str: string) => str.substring(0, 3);

  const mappingEvent = (data) => {
    if (data?.length > 0) {
      const resourcesData = [];
      const eventsData = [];
      data.forEach((item, idx) => {
        let eventItem: any = {
          title: item?.display_name,
          resourceId: item?.user_id,
        }
        if (modeView == 'resourceTimelineWeek') {
          let index = 0;
          let start = dayjs(dateRange?.start).format('YYYY-MM-DD');
          while (index <= 6) {
            if (item?.date_full?.includes(start)) {
              eventItem = {
                ...eventItem,
                backgroundColor: '#F3F4F6',
                borderColor: '#F3F4F6',
                textColor: STATUS_ACTION.notAllow
              }
              eventsData.push({
                ...eventItem,
                start: new Date(start),
                id: `${item?.user_id}_${index}`,
                groupId: item?.user_id
              });
            }
            if (item?.date_not_full?.includes(start)) {
              eventItem = {
                ...eventItem,
                backgroundColor: '#F0FDF4',
                borderColor: '#F0FDF4',
                textColor: STATUS_ACTION.edit
              }
              eventsData.push({
                ...eventItem,
                start: new Date(start),
                id: `${item?.user_id}_${index}`,
                groupId: item?.user_id
              });
            }
            index++;
            start = dayjs(dateRange?.start).add(index, 'day').format('YYYY-MM-DD');
          }
        } else {
          let calendar_events = item?.calendar_events?.map(
            event => event?.calendar_time_ranges?.map(slot => ({ ...slot, course_id: event?.course_id }))).flat();
          calendar_events = calendar_events?.filter(item => 
            item?.status === 'auto_generated' || (item?.status === 'touched' && item?.occupied_slots?.length === 0));
          calendar_events?.forEach((slot, index) => {
            const end = dayjs(slot?.start_time).add(slot?.duration, 'minutes').format();
            const color = slot?.course_id == params.courseId ? '#F0FDF4' : '#F3F4F6';
            eventItem = {
              ...slot,
              ...eventItem,
              start: new Date(slot?.start_time),
              end: new Date(end),
              backgroundColor: color,
              borderColor: color,
              id: `${item?.user_id}_${index}`,
              display: 'background',
              textColor: slot?.course_id == params.courseId ? STATUS_ACTION.add : STATUS_ACTION.notAllow,
              groupId: item?.user_id,
              alllow: !!(slot?.course_id == params.courseId),
              course_id: slot?.course_id
            }
            eventsData.push(eventItem);
          });
        }
        resourcesData.push({
          ...item,
          id: item.user_id,
          title: item?.display_name ?? '',
        });
      });
      setEvents(eventsData);
      setResources(resourcesData);
    }
  }

  const handleDelete = (value) => {
    setSlotSelect(value);
  }

  const onHandleDelete = (value) => {
    if (value) {
      TeacherDashboardService.removeEventTeacher(params?.id, slotSelect?.resourceId, slotSelect.calendar_event_id, {
        schedule_time_range_id: slotSelect?.schedule_time_range_id,
        start_time: slotSelect?.start_time,
      }).then((response) => {
        Toastify.success(response?.data?.message);
        getinstructorAvailability();
      }, error => {
        Toastify.error(error?.data?.message);
      })
      setSlotSelect(null);
    }
    setSlotSelect(null);
  }

  useEffect(() => {
    const moreOption = {
      slotDuration: { days: 1 },
      slotLabelFormat: [
        { weekday: 'short', }
      ]
    }
    if (modeView === 'resourceTimelineWeek') {
      setOptionCalendar({ ...OPTION_CALENDAR, ...moreOption });
    } else {
      setOptionCalendar(OPTION_CALENDAR);
    }
    calendarApi?.changeView(modeView, new Date());

  }, [modeView]);

  useEffect(() => {
    if (calendarRef) {
      setCalendarApi(calendarRef.current.getApi());
    }
  }, [calendarRef]);

  useEffect(() => {
    getinstructorAvailability();
  }, [dateRange, instructor]);

  useEffect(() => {
        if (calendarApi) {
          calendarApi?.changeView(modeView);
        }
  }, [expandedMenu]);

  const getinstructorAvailability = async () => {
    const payload = {
      includes_past: true,
      start_time: dateRange?.start || new Date(),
      end_time: dateRange?.end || new Date(),
      calendar_view: VIEW_TYPE[modeView],
      users: instructor?.value ? [instructor?.value] : null,
      sort_by: 'display_name',
      order: 'asc'
    }
    if (!payload?.users) {
      delete payload?.users;
    }
    const response = await courseService.getinstructorAvailability(params?.id, params?.courseId, payload);
    if (response?.data) {
      mappingEvent(response.data);
    }
  }

  const renderSlotLabelContent = ({ date, isToday, ...props }) => {
    const weekdays = moment(date).locale('en').format('dddd').toUpperCase();
    const day = moment(date).format('DD');
    if (modeView == 'resourceTimeGridDay') {
      return moment(date).format('HH:mm');
    }
    return <>
        <p className='text-center'>{splitString(translator(weekdays))}</p>
        <p className={`header-day ${moment().format('YYYY-DD-MM') == moment(date).format('YYYY-DD-MM') ? 'header-today' : ''}`}>{day}</p>
    </>;
};

  return <div className="calendarResourceTime">
    {modeView !== 'resourceTimelineWeek' && (
        <div className="absolute w-[50px] text-center top-[95px] left-[7px] text-xs z-[1]">
            <button
                type="button"
                className="text-primary-500 hover:opacity-80 block text-center m-auto"
                onClick={() => window.open(`/workspace/${params?.id}/user-setting`, '_blank')}
            >
                <SettingsIcon />
            </button>
            <span className="text-[10px]">UTC{dayjs(new Date()).tz(result?.time_zone).format('Z')}</span>
        </div>
    )}
    <div className="calendarResourceTime-typeView">
      <button className={`${modeView === 'resourceTimeGridDay' ? 'active' : ''}`} onClick={() => setModeView('resourceTimeGridDay')}>Day</button>
      <button className={`${modeView === 'resourceTimelineWeek' ? 'active' : ''}`} onClick={() => setModeView('resourceTimelineWeek')}>Week</button>
    </div>
    <FullCalendar
      ref={calendarRef}
      plugins={[interactionPlugin, resourceTimelinePlugin, resourceTimeGridPlugin]}
      {...optionCalendar}
      headerToolbar={{
        left: 'today prev,next title',
        right: ''
      }}
      initialView='resourceTimelineWeek'
      /*@ts-ignore*/
      events={events}
      resources={resources}
      resourceOrder={'display_name'}
      eventContent={(eventInfo) => (
        <RenderEvent {...eventInfo} timezone={result.time_zone} handleDelete={handleDelete} />
      )}
      eventClick={(info: any) => {
        const eventData = resources?.find(item => item.id == info?.event?.groupId);
        if (info?.event?.textColor == STATUS_ACTION.notAllow) {
          return;
        }
        if (info?.event?.textColor == STATUS_ACTION.add) {
          return onAddSlot({
            ...eventData,
            start: info?.event?.start
          });
        }
        return onOpenDetail({
          ...eventData,
          start: info?.event?.start
        });
      }}
      select={(info: any) => {
        const resourceData = resources?.find(item => item.id == info?.resource?.id);
        if (info?.view?.type == 'resourceTimelineWeek') {
          if (dayjs(info?.start).diff(dayjs(), 'minutes') >= 0) {
            return onAddSlot({
              ...resourceData,
              start: info?.start
            });
          }
        } else {
          const eventData = events?.find(item =>
            item.resourceId == info?.resource?.id &&
            dayjs(info.start).diff(item.start, 'minutes') >= 0 &&
            dayjs(info.start).diff(item.end, 'minutes') < 0);
          if (dayjs(info?.start).isBefore(new Date())) {
            return;
          }  
          if (eventData?.alllow) {
            handleDelete(eventData);
          } else if (!eventData && dayjs(info?.start).diff(dayjs(), 'minutes') >= 0) {
            return onAddSlot({
              ...resourceData,
              start: info?.start
            });
          }
        }
      }}
      datesSet={((data: any) => {
        setDateRange({
          start: data.start,
          end: data.end,
        });

      })}
      slotLabelContent={renderSlotLabelContent}
      resourceAreaColumns={[
        {
          field: 'title',
          headerContent: ''
        },
      ]}
      schedulerLicenseKey={process.env.REACT_APP_FULL_CALENDAR_KEY}
    />
    <div className="calendarResourceTime-infor">
      <div className="calendarResourceTime-infor-item">
        <span className="calendarResourceTime-label"></span> Available to Add
      </div>
      <div className="calendarResourceTime-infor-item">
        <span className="calendarResourceTime-label calendarResourceTime-label--Unavailable"></span> Unavailable to Add
      </div>
      <div className="calendarResourceTime-infor-item">
        <span className="calendarResourceTime-label calendarResourceTime-label--Availability"></span> Availability Slot
      </div>
    </div>
    {slotSelect && <ConfirmDeleteModal
      isOpen={!!slotSelect}
      onClose={onHandleDelete}
      titleModal={'Delete'}>
      <div className="text-sm">Are you sure you want to delete this availability?</div>
    </ConfirmDeleteModal>}
  </div>
}