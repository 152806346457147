import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import EmptyStateLearningGroup from 'assets/img/empty-stage-learning-group.png'
import ActionMenu from './ActionMenu';
import Status from './Status';

const TableGroups = ({
    workspaceId,
    dataTable,
    order,
    setOrder,
    refreshData
}) => {
    const { t: translator } = useTranslation();

    const handleChangeOrder = () =>{
        if (order === 'asc') {
            setOrder('desc')
        }
        if (order === 'desc') {
            setOrder('asc')
        }
    }

    const columns = React.useMemo(
        () => [
            {
                Header: () => (
                    <div className="text-left flex items-center ml-1">
                        <RenderTableHeader className='text-primary-500' isOrder order={order} onClick={handleChangeOrder} value={translator("LEARNING_GROUP.GROUP_NAME")} />
                    </div>
                ),
                accessor: 'name',
                Cell: ({ row }) => {
                    return (
                        <Link
                            to={`/workspace/${workspaceId}/management/learning-groups/${row.original?.id}`}
                            className=" text-ooolab_xs text-primary-500 font-semibold cursor-pointer flex items-center px-1.5 h-full "
                        >
                             <p className='text-ellipsis truncate'> {row.values?.name}</p>
                        </Link>
                    );
                },
                width: 200,
            },
            {
                Header: () => (
                    <div className="text-left">
                        <RenderTableHeader value={translator("LEARNING_GROUP.MEMBERS")} />
                    </div>
                ),
                accessor: 'no_of_members',
                Cell: ({ row }) => (
                    <div className="h-full flex items-center cursor-default">
                        <RenderTableCell value={row.values?.no_of_members} />
                    </div>
                ),
                width: 40,
            },
            {
                Header: () => (
                    <div className="text-left">
                        <RenderTableHeader value={translator("LEARNING_GROUP.STATUS")} />
                    </div>
                ),
                accessor: 'status',
                Cell: ({ row }) => (
                    <Status status={translator(row.values?.status)} />
                ),
                width: 60,
            },
            {
                Header: () => <RenderTableHeader value="" /> ,
                accessor: 'action',
                Cell: ({ row }) => (
                    <ActionMenu
                        data={row?.original}
                        refreshData={refreshData}
                        position={row.index > dataTable?.length/2 ? 'bottom-0': ''}
                     />
                ),
                width: 20,
            },
        ],
        [order]
    );
    return <Table data={dataTable} columns={columns} noBorder emptyContent={translator("LEARNING_GROUP.NOGROUP")} emptyIcon={EmptyStateLearningGroup} />;
};

export default TableGroups;
