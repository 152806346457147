import React from 'react';
import ResourcesList from 'components/Management/Resources';
import ManagementMasterPage from 'pages/RouteMasterPage';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';

const ResourcesPage = () => {
    return (
        <ManagePermissionWrapper>
            <ResourcesList>
                <ManagementMasterPage />
            </ResourcesList>
        </ManagePermissionWrapper>
    );
};

export default ResourcesPage;
