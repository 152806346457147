import { FC } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EyeIcon } from '@heroicons/react/outline';
import ActionsButton from 'components/ActionsButton';
import { checkPermission } from 'utils/commonFun';

interface ActionMenuProp {
    refreshData?: Function,
    data?: any;
    hideAction?: string;
    isLearningGroup?: any;
}

const ActionMenu: FC<ActionMenuProp> = ({ data, isLearningGroup }) => {
    const { t: translator } = useTranslation();
    const history = useHistory()
    const params: { id: string, courseId: string } = useParams()
    const search = useLocation()?.search;
    const learnerId = new URLSearchParams(search).get('learnerId');
    const learnerGroupeId = new URLSearchParams(search).get('learnerGroupeId');
    const isGroup = new URLSearchParams(search).get('isGroup');

    return (
        <>
            <ActionsButton
                styleOptions="top-0 right-[35px]"
                menus={[
                    {
                        label: translator("EVALUATE.EVALUATE"),
                        icon: <EyeIcon className='w-4 h-4' />,
                        action: () => {
                            let url = ''
                            if (learnerGroupeId) url = `/workspace/${params?.id}/management/courses/${params?.courseId}/session/${data?.id}/grade?learnerGroupeId=${learnerGroupeId}`
                            if (learnerId) url = `/workspace/${params?.id}/management/courses/${params?.courseId}/session/${data?.id}/grade?learnerId=${learnerId}&isGroup=${isGroup}`
                            return history.push(url, {
                                isLearningGroup: isLearningGroup || null,
                            })
                        },
                        hide: !checkPermission('courseProgressHistoryStudyHistoryGrade', 'R'),
                        isDisabled: data?.display_status == 'late_cancellation'
                    },
                ]}
            />

        </>
    );
};

export default ActionMenu;
