import React from "react";
import { ClassSessionBreakdown } from "./components/ClassSessions/ClassSessionBreakdown";
import { ClassSessionsOverview } from "./components/ClassSessions/ClassSessionsOverview";

export const ClassDashboardSessions = () => {
    return<>
        <div className="flex space-x-5">
            <div className="w-full">
                <ClassSessionsOverview />
            </div>
        </div>
        <div className="flex w-full mt-5 space-x-5">
            <ClassSessionBreakdown />
        </div>
    </>
}