import { FC, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { EditIcon } from 'assets/icon/EditIcon';
import ActionsButton from 'components/ActionsButton';
import DeactivateIcon from 'assets/icon/DeactivateIcon';
import courseService from 'services/course.service';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import DialogComponent from 'components/Dialog/DialogComponent';
import { ENROLLMENT_STATUS } from '../../constants';
import { checkPermission } from 'utils/commonFun';
import ModalEditEnrollment from '../../Modals/ModalEditEnrollment';
import { ActiveIcon } from 'assets/icon/ActiveIcon';
import { SESSION_STATUS } from 'constant/course.const';
import ErrorIcon from 'assets/icon/ErrorIcon';

interface ActionMenuProp {
    refreshData: Function,
    data: any;
    position?: string
}

const ActionMenu: FC<ActionMenuProp> = ({
    refreshData,
    data,
    position
}) => {
    const { t: translator } = useTranslation();
    const history = useHistory();

    const [isOpenModalDeactivate, setOpenModalDeactivate] = useState(false);
    const [isOpenModalEditEnrollment, setOpenModalEditEnrollment] = useState(false);
    const [isOpenModalCannotDeactivatedInstructor, setIsOpenModalCannotDeactivatedInstructor] = useState(false);
    const params: { id: string; courseId: string } = useParams();
    const workspaceId = params?.id;
    const courseId = params?.courseId;

    const preCheckDeactivatedInstructor = () => {
        courseService.preCheckInstructorInCourse(params?.id, courseId, data?.id)
            .then((res) => {
                if (res?.data?.has_on_up_session) {
                    setIsOpenModalCannotDeactivatedInstructor(true);
                } else {
                    setOpenModalDeactivate(true);
                }
            }).catch(() => {
                Toastify.error();
            });
    }

    const onChangeStatusInstructor = () => {
        const status = data?.status === ENROLLMENT_STATUS.DEACTIVATED ? ENROLLMENT_STATUS.ACTIVE : ENROLLMENT_STATUS.DEACTIVATED
        courseService
            .updateDataInstructorInvited(workspaceId, courseId, data?.id, { status })
            .then(() => {
                Toastify.success();
                setOpenModalDeactivate(false);
                refreshData();
            })
            .catch(() => Toastify.error());
    };

    return (
        <>
            <ActionsButton
                styleOptions={`${position ? position : 'top-0'} right-[35px]`}
                menus={[
                    {
                        label: translator("COURSES.EDIT_LEARNER_LEARNING_GROUP"),
                        icon: <EditIcon />,
                        isDisabled: data?.status === ENROLLMENT_STATUS.DEACTIVATED,
                        action: () => setOpenModalEditEnrollment(true),
                        class: 'border-b border-gray-300',
                        hide: !checkPermission('coursePeopleInstructor', 'DA'),
                    },
                    {
                        label: data?.status === ENROLLMENT_STATUS.DEACTIVATED ? translator('ACTIVATE') : translator("DEACTIVATE"),
                        icon: data?.status === ENROLLMENT_STATUS.DEACTIVATED ? <ActiveIcon /> : <DeactivateIcon />,
                        action: () => preCheckDeactivatedInstructor(),
                        class: 'border-b border-gray-300',
                        hide: !checkPermission('coursePeopleInstructor', 'DA'),
                    },
                ]}
            />
            {isOpenModalDeactivate && (
                <DialogComponent
                    title={data?.status === ENROLLMENT_STATUS.DEACTIVATED ? translator('ACTIVATE') : translator('DEACTIVATE')}
                    isOpen={isOpenModalDeactivate}
                    onCloseModal={() => setOpenModalDeactivate(false)}
                    child={
                        <div className="flex flex-col">
                            <div className="mt-2 text-sm text-gray-800">
                                {
                                    data?.status === ENROLLMENT_STATUS.DEACTIVATED  ? 
                                    translator('COURSES.ACTIVATE_INSTRUCTOR_MESSAGE')
                                    :
                                    translator('COURSES.DEACTIVATE_INSTRUCTOR_MESSAGE')
                                }
                            </div>
                            <div className="flex justify-center gap-4 mt-6 w-full">
                                <ButtonOutline type="button" onClick={() => setOpenModalDeactivate(false)}>
                                    {translator('CANCEL')}
                                </ButtonOutline>
                                {
                                    data?.status === ENROLLMENT_STATUS.DEACTIVATED  ? 
                                    <ButtonPrimary type="button"  onClick={onChangeStatusInstructor}>
                                        {translator('ACTIVATE')}
                                    </ButtonPrimary>
                                    :
                                    <ButtonPrimary type="button" className='buttonPrimary-danger' onClick={onChangeStatusInstructor}>
                                        {translator('DEACTIVATE')}
                                    </ButtonPrimary>
                                }
                               
                            </div>
                        </div>
                    }
                />
            )}
            {isOpenModalEditEnrollment && (
                <ModalEditEnrollment 
                    isOpen={isOpenModalEditEnrollment}
                    onClose={() => setOpenModalEditEnrollment(false)}
                    refreshData={refreshData}
                    data={data}
                />
            )}
            {isOpenModalCannotDeactivatedInstructor && (
                <DialogComponent
                    isOpen={isOpenModalCannotDeactivatedInstructor}
                    title={
                        <div className="flex items-center gap-2">
                            <div className="flex-none w-5">
                                <ErrorIcon width={20} height={20} />
                            </div>
                            {translator('COURSES.UNABLE_TO_DEACTIVATE_INSTRUCTOR_COURSE')}
                        </div>
                    }
                    onCloseModal={() => setIsOpenModalCannotDeactivatedInstructor(false)}
                    child={
                        <div className="mt-2 text-sm">
                            <div className="mt-2">{translator('COURSES.UNABLE_TO_DEACTIVATE_INSTRUCTOR_COURSE_MSG')}</div>
                            <div className='mt-3'>
                                <Trans i18nKey="COURSES.UNABLE_TO_DEACTIVATE_INSTRUCTOR_COURSE_CLICK_LINK">
                                    <span
                                        className='text-enterprise underline cursor-pointer'
                                        onClick={() => {
                                            history.push(`/workspace/${workspaceId}/management/courses/${courseId}?tab=scheduling&subtab=session-list`, {
                                                instructor_id: [
                                                    {
                                                        label: data?.instructor?.display_name,
                                                        name: data?.instructor?.display_name,
                                                        value: data?.instructor?.id,
                                                    },
                                                ],
                                                session_status: [SESSION_STATUS(translator)[0], SESSION_STATUS(translator)[1]],
                                            });
                                        }}
                                    ></span>
                                </Trans>
                            </div>
                        </div>
                    }
                />
            )}
        </>
    );
};

export default ActionMenu;
