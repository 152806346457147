import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import { WorkspaceService } from 'services';
import workspaceService from 'services/workspace.service';
import debounce from 'lodash/debounce';
import { PlusIcon } from '@heroicons/react/outline';
import './Filter.scss';
import { DropdownAsync } from '_shared/components/Form/DropdownAsync/DropdownAsync';
import { typeOption } from "components/Academics/LearningPath/LearningPathDetail/learningPath.consts";
import { fieldData } from '../../constants';
import courseService from 'services/course.service';
import SearchInput from '_shared/components/SearchInput/SearchInput';

interface IFilter {
    onChangeFilter: (value: any) => void;
}

const Filter = ({
    onChangeFilter,
}: IFilter) => {
    const params: any = useParams();
    const { t } = useTranslation();
    const [keyword, setKeyword] = useDebouncedState('', 0);
    const [inputSearchOwner, setInputSearchOwner] = useDebouncedState('');
    const [inputSearchCustomField, setInputSearchCustomField] = useDebouncedState('');
    const [listOwners, setListOwner] = useState([]);
    const [ownerSelected, setOwnerSelected] = useState([]);
    const [statusSelected, setStatusSelected] = useState([])
    const [loadingOwner, setloadingOwner] = useState(false);
    const [listCustomField, setListCustomField] = useState([]);
    const [filterByTags, setFilterByTags] = useState<any>();
    const [selectedTags, setSelectedTags] = useState<any>([]);
    const [enrollmentType, setEnrollmentType] = useState([])

    const [selectedCourseType, setSelectedCourseType] = useState<any>([]);
    const [hasAddFilterFirst, setHasAddFilterFirst] = useState<boolean>(false);
    const translatorlearning = (str: string, newKey?: boolean, opt?: any) => {
        return newKey ? t(str, opt) : t(`LEARNING_PATH.LEARNING_PATH_DETAIL.${str}`, opt)
    }
    const translatorCourse = (str: string, newKey?: boolean, opt?: any) => {
        return newKey ? t(str, opt) : t(`COURSES.${str}`, opt)
    }

    const [payloadAddFilter, setPayloadAddFilter] = useState<{ types: string }>(null);
    const [hasReset, setHasReset] = useState(false);

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`LIBRARY.${str}`, opt));

    const [pagination, setPagination] = useState<any>({
        page: 1,
    });
    const [paginationCustomField, setPaginationCustomField] = useState<any>({
        page: 0,
    });

    const getListCustomFields = (page: number = 1) => {
        const body = {
            order: 'asc',
            page: page || paginationCustomField?.page,
            per_page: 10,
            q: inputSearchCustomField,
        };
        if (inputSearchCustomField === '') {
            delete body.q;
        };
        workspaceService.getCustomFieldsService(params.id, body).then((res: any) => {
            const formatedData = res?.data?.items?.map((item) => {
                return {
                    name: item?.name,
                    id: item?.id,
                };
            });
            setPaginationCustomField({ ...pagination, total: res?.data?.total, page });
            if (page === 1) setListCustomField(formatedData);
            else setListCustomField([...listCustomField, ...formatedData]);
        });
    };

    const getListOwner = (page: number = 1) => {
        setloadingOwner(true);
        WorkspaceService.getWorkspaceMembers(
            { id: params.id },
            {
                page: page ? page : pagination.page,
                per_page: 10,
                q: inputSearchOwner,
                order: 'asc',
                sort_by: 'display_name',
            }
        )
            .then((res) => {
                const formatedData = res?.items?.map((item) => {
                    return {
                        name: item?.display_name,
                        id: item?.id,
                        value: item?.id,
                    };
                });
                setPagination({ ...pagination, total: res?.total, page });
                if (page === 1) setListOwner(formatedData);
                else setListOwner([...listOwners, ...formatedData]);
            })
            .finally(() => setloadingOwner(false));
    };

    const onChangeAddFilter = (values) => {
        setHasReset(false);
        setSelectedTags(values);
        const tags = filterByTags?.tags || {};
        if (Object.keys(tags)?.length < values?.length) {
            const newItem = values[values?.length - 1];
            if (newItem) {
                tags[newItem.value] = [];
            }
        } else {
            Object.keys(tags).map(key => {
                if (values.filter(item => item.value == key).length == 0) {
                    delete tags[key];
                }
            });
        }
        setFilterByTags(prev => ({
            ...prev,
            tags
        }));
        if (values?.length > 0 && selectedCourseType?.length == 0) {
            setHasAddFilterFirst(true);
        }
    }

    useEffect(() => {
        getListOwner(1);
    }, [inputSearchOwner]);

    useEffect(() => {
        getListCustomFields(1);
    }, [inputSearchCustomField]);

    const updateFilter = useCallback(
        debounce((listFilter) => onChangeFilter(listFilter), 1500),
        []
    );

    useEffect(() => {
        let listFilter: any = {
            created_by: ownerSelected?.map((owner) => owner?.id)?.join(','),
            title: keyword,
            status: statusSelected?.[0]?.value,
            enrollment_type: enrollmentType?.map((enroll) => enroll?.value)?.join(','),

        };
        if (statusSelected.length === 2) delete listFilter.status
        if (!listFilter.created_by) delete listFilter.created_by
        if (!listFilter.title) delete listFilter.title
        if (!listFilter.enrollment_type) delete listFilter.enrollment_type
        if (!listFilter.types) delete listFilter.types

        if (filterByTags?.tags || selectedCourseType?.length) {
            const listCheckedTags = {};
            if (filterByTags?.tags) {
                Object.keys(filterByTags?.tags)?.forEach((key) => {
                    if (filterByTags?.tags[key]?.length > 0) {
                        listCheckedTags[key] = filterByTags?.tags[key]?.map((item) => item?.name);
                    }
                });
            }
            if (selectedCourseType?.length) {
                listCheckedTags['type'] = selectedCourseType?.map((type) => type?.value);
            }
            listFilter = {
                ...listFilter,
                extra_filters: JSON.stringify(listCheckedTags)
            }
        }
        onChangeFilter(listFilter);
    }, [ownerSelected, keyword, filterByTags, statusSelected, enrollmentType, selectedCourseType]);

    const handleSelectedCourseType = (values) => {
        setSelectedCourseType(values);
        setHasReset(false);
        if (!hasAddFilterFirst) {
            setTimeout(() => {
                setHasReset(true);
            })
            setSelectedTags([]);
            setFilterByTags(null);
            setPayloadAddFilter({
                types: values?.map(item => item.value).join(',')
            })
        } else {
            setPayloadAddFilter(null);
        }
    }

    return (
        <div className="filterLearningPath">
                <SearchInput
                    className="filterLearningPath-searchInput"
                    onChange={(value) => setKeyword(value)}
                    value={keyword}
                />
                <SelectBox
                    label={t("OWNER")}
                    onSearch={setInputSearchOwner}
                    onSelect={setOwnerSelected}
                    data={listOwners}
                    loading={loadingOwner}
                    selectedItems={ownerSelected}
                    total={pagination?.total}
                    showmore={() => getListOwner(pagination?.page + 1)}
                />
                <SelectBox
                    label={t("COURSES.COURSE_TYPE")}
                    onSelect={handleSelectedCourseType}
                    data={typeOption(translatorlearning)}
                    selectedItems={selectedCourseType}
                    total={7}
                />
                <SelectBox
                    label={t("COURSES.ENROLLMENT_TYPE")}
                    onSelect={setEnrollmentType}
                    selectedItems={enrollmentType}
                    data={[
                    { name: t("OPEN"), value: 'open' },
                    { name: t("COURSES.CREDIT"), value: 'credit' },
                    { name: t("COURSES.CATALOG"), value: 'catalog' },
                    ]}
                    total={3}
                    isMulti={false}
                />

                <SelectBox
                    label={t("STATUS")}
                    onSelect={setStatusSelected}
                    selectedItems={statusSelected}
                    data={[
                        { name: t("ACTIVE"), value: 'active' },
                        { name: t("INACTIVE"), value: 'inactive' }
                    ]}
                    total={2}
                />

                {selectedTags?.map((item) => (
                    <div key={item.id}>
                        <DropdownAsync
                            placeholder={item.name}
                            loadOptions={(payload) => courseService.getFieldData(params.id,
                                {
                                    ...payload,
                                    field: item.value,
                                    order: 'asc'
                                }
                            )}
                            onChange={(value) => {
                                setFilterByTags(prev => {
                                    const tags = prev?.tags ?? {};
                                    tags[item.value] = value;
                                    return {
                                        ...prev,
                                        tags
                                    }
                                })
                            }}
                            onRemove={() => {
                                setSelectedTags(prev => {
                                    return prev.filter(tag => tag.id != item.id);
                                });
                                setFilterByTags(prev => {
                                    const tags = prev?.tags ?? {};
                                    delete tags[item.value];
                                    return {
                                        ...prev,
                                        tags
                                    }
                                });
                            }}
                            isRemoved={true}
                            isAll={true}
                            customOption
                        />
                    </div>
                ))}

                <DropdownAsync
                    className="dropdownAsync--actived"
                    placeholder={t("COURSES.ADD_FILTER")}
                    loadOptions={(payload) => workspaceService.getCustomFieldsService(params.id, payload)}
                    onChange={onChangeAddFilter}
                    paramsApi={{
                        ...payloadAddFilter,
                        order: "asc",
                        sort_by: "name"
                    }}
                    isReset={hasReset}
                    selectedValue={selectedTags}
                    items={fieldData(translatorCourse)}
                >
                    <PlusIcon className="dropdownAsync--icon" aria-hidden="true" />
                    <span className='text-primary-500'>{t("COURSES.ADD_FILTER")}</span>

                </DropdownAsync>

                <div className="line"></div>

                <button
                    onClick={() => {
                        setOwnerSelected([]);
                        setFilterByTags(null);
                        setSelectedTags(null);
                        setEnrollmentType(null)
                        setSelectedCourseType([])
                        setOwnerSelected([])
                        setPayloadAddFilter(null);
                        setHasAddFilterFirst(false);
                        setHasReset(prev => !prev);
                        setStatusSelected([])
                        setKeyword('')
                    }}
                    className="btn-reset"
                    disabled={!ownerSelected?.length && !selectedTags?.length && !statusSelected?.length && !keyword && !selectedCourseType?.length && !enrollmentType?.length}
                >
                    {translator('RESET')}
                </button>
            </div>
    );
};

export default Filter;
