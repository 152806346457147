import React from 'react';
import { NavigationProps } from 'components/MasterPage/LeftNavigation/LeftNavigation';
import { SessionsLayout } from 'components/Management/Sessions/layout';

interface SessionsPageProps {
    barType?: NavigationProps;
    setAuthStorage: React.Dispatch<React.SetStateAction<boolean>>;
}

const SessionsPage: React.FC<SessionsPageProps> = () => {
    return (<SessionsLayout />);
};

export default SessionsPage;
