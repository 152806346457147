import React from 'react';
import ManagementMasterPage from 'pages/RouteMasterPage';
import NotificationList from 'components/Management/Notification/NotificationList/NotificationList';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';

const NotificationListPage =() => {
    return (
        <ManagePermissionWrapper>
            <NotificationList>
                <ManagementMasterPage />
            </NotificationList>
        </ManagePermissionWrapper>
    );
};

export default NotificationListPage;
