import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import { useParams } from 'react-router-dom';
import ActionMenu from './ActionMenu';
import { User } from '_shared/components/User/User';
import EnrollmentStatus from '_shared/components/EnrollmentStatus/EnrollmentStatus';

const ViewTable = ({ data, refreshData }) => {
    const { t: translator } = useTranslation();
    const params: any = useParams()
    const workspaceId = params?.id;
    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('COURSES.MEMBER')} />,
                Cell: ({ row }: any) => {
                    return (
                        <User 
                            link={`/workspace/${workspaceId}/management/users/${row?.original?.id}`} 
                            avatar={row.original?.avatar_url} 
                            name={row.original?.display_name} 
                        />
                    );
                },
                accessor: 'member',
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.EMAIL')} />,
                accessor: 'email',
                Cell: ({ row }: any) => <RenderTableCell value={row.values?.email} />,
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.STATUS')} />,
                accessor: 'display_status',
                Cell: ({ row }: any) => <EnrollmentStatus status={row?.values?.display_status} />,
                width: 50,
            },
            {
                Header: () => <></>,
                accessor: 'action',
                width: 20,
                Cell: ({ row }: any) => <ActionMenu  data={row?.original} refreshData={refreshData}/>,
            },
        ],
        [data]
    );

    return <Table data={data} columns={columns} emptyContent={translator('COURSES.NO_MEMBERS')} />;
};

export default ViewTable;
