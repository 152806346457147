import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';
import React from 'react';
import CreateAssignmentPage from '../CreateAssignmentPage';

const NewAssignment: React.FC<{ setAuthStorage: React.Dispatch<React.SetStateAction<boolean>> }> = () => (
    <ManagePermissionWrapper>
        <CreateAssignmentPage />
    </ManagePermissionWrapper>
);

export default NewAssignment;
