import { CheckCircleIcon } from '@heroicons/react/solid';
import { toast } from 'react-toastify';
import './Toastify.scss';
import ErrorIcon from 'assets/icon/ErrorIcon';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { WarningIcon } from 'assets/icon/WarningIcon';

export const Toastify = {
    success: (title: any = 'Your work has been saved', config = undefined) =>
        toast.success(
            <div className="flex gap-2 justify-start items-start text-gray-800">
                <span className="flex-none self-center">
                    <CheckCircleIcon className="w-4 h-4 min-w-[16px] text-green-500 " />
                </span>
                <div>
                    <p className={`${title?.title ? 'font-semibold' : ''}`} >{title?.title || title}</p>
                    {title?.message && <p>{title?.message}</p>}
                </div>
            </div>,
            {
                type: 'default',
                closeButton: true,
                hideProgressBar: true,
                autoClose: 3000,
                closeOnClick: true,
                className: 'toastify success',
                ...config
            }
        ),
    error: (title: any = 'Your work has been failed', config = undefined) =>
        toast.error(
            <div className="flex gap-1 justify-start items-start text-gray-800">
                <div className='min-w-[16px] mt-0.5'><ErrorIcon width={16} height={16} /></div>
                <div>
                    <p className={`${title?.title ? 'font-semibold' : ''}`} >{title?.title || title}</p>
                    {title?.message && <p>{title?.message}</p>}
                </div>
            </div>,
            {
                type: 'default',
                closeButton: true,
                hideProgressBar: true,
                autoClose: 3000,
                closeOnClick: true,
                className: 'toastify error',
                ...config
            }
        ),
    loading: (message: string = 'Loading...', loading = 5000, config = undefined) =>
        toast.info(
            <div className="flex gap-1 justify-start items-center text-gray-800">
                <span className="flex-none">
                    <CircleSpin color="text-primary-500" className=' -ml-1 mr-1' />
                </span>
                <span>{message}</span>
            </div>,
            {
                type: 'default',
                closeButton: false,
                hideProgressBar: true,
                autoClose: loading,
                closeOnClick: true,
                className: 'toastify loading',
            }
        ),
    warning: (title: any = '', config = undefined) =>
        toast.warning(
            <div className="flex gap-1 justify-start items-start text-gray-800">
                <div className='min-w-[16px] mt-0.5 text-orange-500'><WarningIcon width={'16px'} height={'16px'} /></div>
                <div>
                    <p className={`${title?.title ? 'font-semibold' : ''}`} >{title?.title || title}</p>
                    {title?.message && <p>{title?.message}</p>}
                </div>
            </div>,
            {
                type: 'default',
                closeButton: true,
                hideProgressBar: true,
                autoClose: 3000,
                closeOnClick: true,
                className: 'toastify warning',
                ...config
            }
        ),
};
