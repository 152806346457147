import React from 'react';
import LessonBuilder from 'components/Workspace/LessonBuilder';
import RouteMasterPage from 'pages/RouteMasterPage';

export const LessonBuilderOldPage = () => {
    return (
        <LessonBuilder>
            <RouteMasterPage />
        </LessonBuilder>
    );
};
