import React from "react";
import { XIcon } from "@heroicons/react/outline";

type SelfBookingSelectedBoxProps = {
  item: any;
  handleRemoveSelected: (item: any) => void;
};

const SelfBookingSelectedBox: React.FC<SelfBookingSelectedBoxProps> = ({
  item,
  handleRemoveSelected,
}: SelfBookingSelectedBoxProps) => {
  return (
      <div className="flex items-center justify-between bg-primary-50 rounded-[32px]  px-3 py-2 mr-4">
        <div className="text-primary-500 font-semibold text-xs  w-4/5">
          {item}
        </div>
        <XIcon
          className="w-4 h-4 mx-1 text-primary-500 cursor-pointer"
          onClick={() => handleRemoveSelected(item)}
        />
      </div>
  );
};

export default SelfBookingSelectedBox;
