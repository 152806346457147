import React, { useCallback, useEffect, useState } from 'react';
// import { LEARNING_GROUP } from 'components/Workspace/Course/CourseBuilder/components/CoursePeople/constants';
import { SelectField } from 'components/Select';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import SelectBox from 'components/V2/SelectBox';
import { useDebouncedState } from 'hooks/useDebounce';
import { debounce } from 'lodash';
import { WorkspaceService } from 'services';
import './LearnerAndGroupSearch.scss';
import { LOCATION } from 'components/Management/User/constant';
import { checkPermission } from 'utils/commonFun';
import { useTranslation } from 'react-i18next';

export const LearnerOrLearningGroupWorkspace = ({ onChangeFilter }) => {
    const { control, setValue, getValues, watch } = useForm();
    const params: { id: string; courseId: string } = useParams();
    const [listOwners, setListOwner] = useState([]);
    const [inputSearchOwner, setInputSearchOwner] = useDebouncedState('');
    const [ownerSelected, setOwnerSelected] = useState([]);
    const [loadingOwner, setLoadingOwner] = useState(false);
    const [pagination, setPagination] = useState<any>({
        page: 1,
    });

    const {t} = useTranslation();

    const updateFilter = useCallback(
        debounce((listFilter) => onChangeFilter(listFilter)),
        []
    );

    const getList = async (page: number = 1) => {
        setLoadingOwner(true);

        let responseAPIs;
        let payload: any = {
            q: inputSearchOwner,
            page: page ? page : pagination.page,
            per_page: 10,
        };

        if (!payload?.q) delete payload.q;
        if (!watch('learning_group')?.value) {
            return;
        }
        if (watch('learning_group')?.value == 'learning_group') {
            payload = {
                ...payload,
                order: 'asc',
                sort_by: 'name',
            }
            responseAPIs = await WorkspaceService.getLearningGroupService(params.id, payload);
        } else {
            payload = {
                ...payload,
                order: 'desc',
                sort_by: 'created_at',
                location: LOCATION.learner_portal,
            }
            responseAPIs = await WorkspaceService.getWorkspaceMembersService(params.id, payload);
        }
        if (responseAPIs) {
            const formattedData = responseAPIs?.data?.items?.map((item) => {
                return {
                    name: item?.name ?? item?.display_name,
                    id: item?.id,
                    value: item?.id,
                };
            }) || [];

            setPagination({ ...pagination, total: responseAPIs?.data?.total, page });

            if (page === 1) setListOwner(formattedData);
            else setListOwner([...listOwners, ...formattedData]);
        }

        setLoadingOwner(false);
    };

    useEffect(() => {
        if (getValues('learning_group')?.value == 'learners') {
            updateFilter({
                learner_ids: ownerSelected?.map((item) => item.value).join(',') ?? '',
            });
        } else {
            updateFilter({
                learning_group_ids: ownerSelected?.map((item) => item.value).join(',') ?? '',
            });
        }
    }, [ownerSelected]);

    useEffect(() => {
        getList(1);
    }, [inputSearchOwner, watch('learning_group')]);

    useEffect(() => {
        setValue('learning_group', { name: t("COURSES.LEARNERS"), label: t("COURSES.LEARNERS"), value: 'learners' });
    }, []);

    return (
        <div className="learnerAndGroupSearch">
            {checkPermission('learningGroup', 'R') && <div className="-mr-1 z-1">
                <SelectField
                    control={control}
                    name="learning_group"
                    className="w-[122px]"
                    options={[
                        { name: t("COURSES.LEARNERS"), label: t("COURSES.LEARNERS"), value: 'learners' },
                        { name: t("LEARNING_GROUP_KEY"), label: t("LEARNING_GROUP_KEY"), value: 'learning_group' },
                    ]}
                    onChange={() => {
                        setInputSearchOwner('');
                        setOwnerSelected([]);
                    }}
                    customStyles={{
                        control: (base) => {
                            return {
                                ...base,
                                minHeight: '0px',
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                height: '36px',
                                borderColor: '#E5E7EB',
                            };
                        },
                        valueContainer: (base) => {
                            return { ...base, fontSize: '14px' };
                        },
                        indicatorsContainer: (base) => {
                            return { ...base, padding: '0px', height: '36px' };
                        },
                        menuList: (base) => {
                            return { ...base, height: '80px' };
                        },
                    }}
                />
            </div>}

            <SelectBox
                label={`${watch('learning_group')?.value == 'learners' ? t("SEARCH_LEARNERS") : t("SEARCH_LEARNING_GROUPS")}`}
                onSearch={setInputSearchOwner}
                onSelect={setOwnerSelected}
                data={listOwners}
                loading={loadingOwner}
                selectedItems={ownerSelected}
                total={pagination?.total}
                showmore={() => getList(pagination?.page + 1)}
                hasSearchIcon={true}
                position='right'
            />
        </div>
    );
};
