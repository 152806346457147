import SelectBox from '_shared/components/Select';
import { InputField, PhoneInputField, TextareaField } from 'components/InputForm/InputForm';
import DatePickerInput from 'components/Management/components/Form/DatePicker';
import { UUID } from 'utils/commonFun';

export const propertyType = {
    single_selection: {
        key: '',
        title: 'Single selection',
        value: 'single_selection',
        component: SelectBox
    },
    multiple_selection: {
        key: '',
        title: 'Multiple selection',
        value: 'multiple_selection',
        component: SelectBox
    },
    single_line_text: {
        key: '',
        title: 'Single-line text',
        value: 'single_line_text',
        component: InputField
    },
    multi_line_text: {
        key: '',
        title: 'Multi-line text',
        value: 'multi_line_text',
        component: TextareaField
    },
    date_picker: {
        key: '',
        title: 'Date picker',
        value: 'date_picker',
        component: DatePickerInput
    },
    phone_number: {
        key: '',
        title: 'Phone number',
        value: 'phone_number',
        component: PhoneInputField
    },
    email: {
        key: '',
        title: 'Email',
        value: 'email',
        component: InputField
    },
};

export const addUniqueId = (nodes) => {
    return nodes.map((node) => ({
        ...node,
        uuid: UUID(),
        children: node.children ? addUniqueId(node.children) : null,
    }));
};

export const flattenData = (nodes, level = 0) => {
    if (!Array.isArray(nodes)) {
        return [];
    }
    return nodes?.reduce((acc, node) => {
        acc.push({ ...node, level });
        if (node?.children) {
            acc = acc?.concat(flattenData(node?.children, level + 1));
        }
        return acc;
    }, []);
};