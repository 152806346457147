import React, { useEffect, useMemo, useState } from "react";
import { LibraryEnum } from "constant/LibraryEnum.const";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonOutline, ButtonPrimary } from "_shared";
import './CustomFieldsForm.scss';
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { FormCheckBox } from "_shared";
import { CUSTOM_FIELDS_TYPES } from "components/Library/LibraryContants";
import _ from "lodash";

const TYPES = CUSTOM_FIELDS_TYPES;

export const setEmptyOrStr = (v: string) => {
    if (_.isString(v) && _.isEmpty(v)) {
        return null;
    }
    else return v?.trim();
};

export const CustomFieldsForm = ({ values = null, onCancel, onSave, isLoading = false }) => {
    const { t: translator } = useTranslation();
    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
        defaultValues: useMemo(() => {
            return {
                name: values?.name,
                type: values?.type
            }
        }, [values])
    });

    const onFormSubmit = (data) => {
        setLoading(true);
        onSave(data);
    };

    const onErrors = errors => console.error(errors);

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);

    return (<>
        <form onSubmit={handleSubmit(onFormSubmit, onErrors)} className="customFieldsForm">
            <div className="customFieldsForm-header">
                {values ? translator('LIBRARY.EDIT_CUSTOM_TAG') : translator('LIBRARY.ADD_NEW_CUSTOM_TAG')}
            </div>
            <div className="customFieldsForm-item">
                <div className="customFieldsForm-name">
                    <label>{translator('LIBRARY.CUSTOM_TAG_NAME')}</label>
                    <input placeholder={translator('LIBRARY.ENTER_CUSTOM_TAG_NAME')} maxLength={160}
                        name="name" {...register('name', {
                            required: translator('LIBRARY.CUSTOM_TAG_NAME_IS_REQUIRED'),
                            setValueAs: setEmptyOrStr
                        })}
                    />
                    <span className="customFieldsForm-error">{errors?.name && errors.name.message}</span>
                </div>
                <div className="customFieldsForm-line"></div>
                <div className="customFieldsForm-type">
                    <label>{translator('LIBRARY.SELECT_TYPE(S)')}</label>
                    <div className="customFieldsForm-typeList">
                        {TYPES.map(item => (
                            <div key={item}>
                                <FormCheckBox name="type" title={LibraryEnum[item]}
                                    register={register}
                                    rules={{ required: translator('LIBRARY.TYPE_IS_REQUIRED') }}
                                    value={item}
                                    disabled={false}
                                />
                            </div>
                        ))}
                    </div>
                    <span className="customFieldsForm-error mt-2">{errors?.type && errors.type.message}</span>
                </div>
                <div className="customFieldsForm-actions">
                    <ButtonOutline type="button" onClick={onCancel} disabled={loading}>
                        {translator('CANCEL')}
                    </ButtonOutline>
                    <ButtonPrimary type="submit" onClick={() => { }} disabled={loading}>
                        {loading ? <span className="customFieldsForm-loading"><CircleSpin />{translator('SAVING')}</span> :
                            translator('SAVE')}
                    </ButtonPrimary>
                </div>
            </div>
        </form>
    </>)
}