
import React from "react";
import { useAppDispatch } from "hooks/hooks";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ConfirmDeleteModal } from "_shared";
import { learningPathDeleteLesson } from "store/features/learningPathSlice";

export const LearningPathLessonDelete = ({isOpen, onClose, values, sectionId}) => {
    const params: {id: string, learningPathId: string} = useParams();
    const {t: translator} = useTranslation();
    const dispatch = useAppDispatch();
    const handleDelete = (value: boolean) => {
        if (!value) {
            return;
        }
        dispatch(learningPathDeleteLesson({
            workspaceId: params.id,
            id: params.learningPathId,
            contentId: values.id,
            sectionId
        }));
    }
    return (
        <ConfirmDeleteModal isOpen={isOpen} onClose={(value: boolean) => {
            handleDelete(value);
            onClose();
        }} titleModal={translator('Remove Lesson')}>
            <div className="text-sm">{translator('Are you sure you want to remove this lesson?')}</div>
        </ConfirmDeleteModal>
    )
}
