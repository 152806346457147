import { FC, useEffect, useState } from 'react';
import { Popover } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary, LoadingPopup } from '_shared';
import FileUploadIcon from 'assets/icon/FileUploadIcon';
import H5P_LIBRARY from 'assets/img/library.png';
import "./index.scss";
import DriveIcon from 'assets/icon/DriveIcon';
import useDrivePicker from 'react-google-drive-picker';
import resourcesService from 'services/resources.service';
import { useParams } from 'react-router-dom';
import uploadService from 'services/upload.service';
import UploadResourceFile from './ResourceUploadFile';

export const PopoverNewResource = ({ onUploadFile, refreshData, onAddLibrary }) => {
    const params: { id: string } = useParams();
    const { t: translator } = useTranslation();
    const [openPicker, authResponse] = useDrivePicker();
    const [loadingUpload, setLoadingUpload] = useState(false);

    const handleOpenPicker = () => {
        openPicker({
            clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            developerKey: process.env.REACT_APP_GOOGLE_APIS_KEY,
            viewId: 'DOCS',
            // token: token, // pass oauth token in case you already have one
            showUploadView: true,
            showUploadFolders: true,
            supportDrives: true,
            multiselect: true,
            // customViews: customViewsArray, // custom view
            callbackFunction: (data) => {
                let _this = this;
                if (data.action === 'cancel') {
                    return;
                }
                if (data.docs?.length > 0) {
                    handleUploadFile(data.docs);
                }
            },
        });
    };
    const handleUploadFile = (data) => {
        const uploadAPIs = [];
        setLoadingUpload(true);
        data.forEach((item, index) => {
            let payload: any = {
                access_token: JSON.parse(localStorage.getItem('token_google')),
                file_id: item.id ?? '',
                file_name: item.name ?? '',
            };
            uploadAPIs.push(uploadService.uploadFileFromGoogle(params?.id, payload));
        });
        Promise.all(uploadAPIs).then((res) => {
            let importAPIs = [];
            res?.map(item => {
                importAPIs.push(resourcesService.importResources(params?.id, { library_content_ids: [item?.data?.id] }))
            });
            Promise.all(uploadAPIs).finally(() => {
                refreshData();
                setLoadingUpload(false);
            });
        });
    };

    useEffect(() => {
        if (authResponse) {
            localStorage.setItem('token_google', JSON.stringify(authResponse?.access_token));
        }
    }, [authResponse]);

    return (
        <>
            <Popover className="popoverNewResource relative">
                <Popover.Button>
                    <ButtonPrimary onClick={() => { }} type="button">
                        <div className="flex items-center justify-center">
                            <PlusIcon className="w-4 h-4 mr-1" />
                            {translator('ADD')}
                        </div>
                    </ButtonPrimary>
                </Popover.Button>
                <Popover.Panel className={`popoverNewResource-panel`}>
                    <div className="popoverNewResource-item" onClick={handleOpenPicker}>
                        <DriveIcon />
                        <p className="popoverNewResource-text">{translator('LIBRARY.SELECT_FROM_GOOGLE')}</p>
                    </div>
                    <div>
                        <UploadResourceFile folderId={null}>
                            <div className="flex items-center w-full gap-2 ">
                                <FileUploadIcon />
                                <p className="text-sm font-normal group-hover:text-primary-500">{translator('LIBRARY.UPLOAD_FILE')}</p>
                            </div>
                        </UploadResourceFile>
                    </div>
                    <div className="popoverNewResource-item" onClick={onAddLibrary}>
                        <img src={H5P_LIBRARY} className="w-5" />
                        <p className="text-sm font-normal group-hover:text-primary-500">{translator('RESOURCES.ADD_FROM_LIBRARY')}</p>
                    </div>
                </Popover.Panel>
            </Popover>
            {loadingUpload ? <LoadingPopup message={translator('LIBRARY.UPLOADING')} loading={loadingUpload} /> : ''}
        </>
    );
};
