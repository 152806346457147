import { useState, useEffect, useCallback} from 'react';
import { useParams } from 'react-router-dom';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import { WorkspaceService } from 'services';
import { debounce } from 'lodash';
import { CONTENT_TYPE_LESSON_BULDER, H5P_LIBRARY } from 'constant/h5p.const';
import { ContentTypes, H5PTypes } from 'components/Library/components/FIlterSearch/uitils';
import { TYPE_CREATE_CONTENT, TYPE_LESSON_BUILDER } from 'components/Library/LessonBuilder/constants';
import { useTranslation } from 'react-i18next';

interface IFilter {
    onChangeFilter: any;
    isFirstLoad: boolean;
    folderId: number;
    createContentType: string;
    acceptContentType?: any;
    type: string;
    hasLesson?: boolean;
}

const Filter = ({ onChangeFilter, isFirstLoad, folderId, createContentType, acceptContentType, type, hasLesson=false }: IFilter) => {
    const params: any = useParams();
    const { t: translator } = useTranslation();

    const [keyword, setKeyword] = useDebouncedState('', 0);
    const [inputSearchOwner, setInputSearchOwner] = useDebouncedState('');
    const [inputSearchContentType, setInputSearchContentType] = useDebouncedState('');
    const [inputSearchH5PType, setInputSearchH5PType] = useDebouncedState('');

    const [listOwners, setListOwner] = useState([]);
    const [selectedOwners, setSelectedOwners] = useState([]);
    const [selectedContectType, setSelectedContentType] = useState([]);
    const [selectedH5PType, setSelectedH5PType] = useState([]);

    const [loadingOwner, setloadingOwner] = useState(false);
    const [listContentType, setlistContentType] = useState({ data: ContentTypes('', true)?.data?.[0], total: ContentTypes('', true)?.total });
    const [listH5PType, setlistH5PType] = useState({ data: H5PTypes()?.data?.[0], total: H5PTypes('')?.total });

    const [pagination, setPagination] = useState<any>({
        page: 1,
    });
    const [paginationContentType, setPaginationContentType] = useState<any>({
        page: 0,
    });
    const [paginationH5PType, setPaginationH5PType] = useState<any>({
        page: 0,
    });

    const getListOwner = (page: number = 1) => {
        setloadingOwner(true);
        WorkspaceService.getWorkspaceMembers(
            { id: params.id },
            {
                page: page ? page : pagination.page,
                per_page: 10,
                q: inputSearchOwner,
                order: 'asc',
                sort_by: 'display_name',
            }
        )
            .then((res) => {
                const formatedData = res?.items?.map((item) => {
                    return {
                        name: item?.display_name,
                        id: item?.id,
                        value: item?.id,
                    };
                });
                setPagination({ ...pagination, total: res?.total, page });
                if (page === 1) setListOwner(formatedData);
                else setListOwner([...listOwners, ...formatedData]);
            })
            .finally(() => setloadingOwner(false));
    };

    useEffect(() => {
        getListOwner(1);
    }, [inputSearchOwner]);

    useEffect(() => {
        const h5pTypes = H5PTypes(inputSearchH5PType);
        if (paginationH5PType?.page > 0 && paginationH5PType?.page < h5pTypes?.total) {
            return setlistH5PType({ ...listH5PType, data: [...listH5PType?.data, ...h5pTypes?.data?.[paginationH5PType?.page]] });
        }
    }, [paginationH5PType?.page]);

    useEffect(() => {
        setPaginationH5PType({ page: 0 });
        const listH5PType = H5PTypes(inputSearchH5PType);
        setlistH5PType({ total: listH5PType?.total, data: listH5PType?.data?.[0] });
    }, [inputSearchH5PType]);

    useEffect(() => {
        const contentTypes = ContentTypes(inputSearchContentType, true);
        if (paginationContentType?.page > 0 && paginationContentType?.page < contentTypes?.total) {
            setlistContentType({
                ...listContentType,
                data: [...listContentType?.data, ...contentTypes?.data?.[paginationContentType?.page]],
            });
        }
    }, [paginationContentType?.page]);

    useEffect(() => {
        setPaginationContentType({ page: 0 });
        const listContentType = ContentTypes(inputSearchContentType, true);
        setlistContentType({ total: listContentType?.total, data: listContentType?.data?.[0] });
    }, [inputSearchContentType]);

    const updateFilter = useCallback(
            debounce((listFilter) => onChangeFilter(listFilter), 1500), []
    );

    const getOpjectType = () => {
        if (selectedContectType?.length) {
            return [...selectedContectType, {value: 'folder'}]?.map((type) => type?.value)?.join(',');
        }
        if (createContentType === TYPE_CREATE_CONTENT.H5P_LIBRARY) {
            return 'h5p,folder';
        }
        return acceptContentType;
    }

    useEffect(() => {
        if (!isFirstLoad) {
            const listFilter = {
                created_by: selectedOwners?.map((owner) => owner?.id)?.join(','),
                h5p_types: selectedH5PType?.map((type) => type?.value)?.join(','),
                object_types: getOpjectType(),
                q: keyword,
            };
            updateFilter(listFilter);
        }
    }, [selectedOwners, selectedH5PType, selectedContectType, keyword]);

    return (
        <div className="w-full flex gap-2 items-center flex-nowrap">
            <SearchBox className="w-full !text-sm max-h-9" onSubmit={(value) => setKeyword(value)} />
            <SelectBox
                label={translator('OWNER')}
                onSearch={setInputSearchOwner}
                onSelect={setSelectedOwners}
                data={listOwners}
                loading={loadingOwner}
                selectedItems={selectedOwners}
                total={pagination?.total}
                showmore={() => getListOwner(pagination?.page + 1)}
                position={`${hasLesson ? 'right' : 'left'}`}
                styles={{container: "flex-nowrap"}}
            />
            
            {
              createContentType === TYPE_CREATE_CONTENT.LIBRARY && type !== TYPE_LESSON_BUILDER.assignments &&
              <SelectBox
                label={translator('CONTENT_TYPE')}
                onSearch={setInputSearchContentType}
                onSelect={setSelectedContentType}
                data={listContentType?.data}
                selectedItems={selectedContectType}
                total={Object.keys(selectedContectType)?.length > 0 ? Object.keys(CONTENT_TYPE_LESSON_BULDER)?.length : listContentType?.total}
                showmore={() => setPaginationContentType({ page: paginationContentType?.page + 1 })}
                styles={{container: "flex-nowrap"}}
            />
            }

            {   createContentType === TYPE_CREATE_CONTENT.H5P_LIBRARY && type !== TYPE_LESSON_BUILDER.assignments &&
                <SelectBox
                    label={translator('H5P_TYPE')}
                    onSearch={setInputSearchH5PType}
                    onSelect={setSelectedH5PType}
                    selectedItems={selectedH5PType}
                    data={listH5PType?.data}
                    total={selectedH5PType?.length ? Object.keys(H5P_LIBRARY)?.length : listH5PType?.total}
                    showmore={() => setPaginationH5PType({ page: paginationH5PType?.page + 1 })}
                    styles={{container: "flex-nowrap"}}
                />
            }
        </div>
    );
};

export default Filter;
