import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { classNames } from 'utils/commonFun';
import { DotIcon, EyesIcon, EyesOffIcon } from 'assets/icon';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import emptyIconProperty from 'assets/img/empty-state/empty-properties.png';

export const TableShowHideProperty = ({ workspaceId, tab, subtab, data, translator, handleUpdateVisibility }) => {
    const history = useHistory();

    const handleEditProperty = (id, idSelected = null) => {
        history.push(`/workspace/${workspaceId}/setting?tab=${tab}&subtab=${subtab}&id=${id}${idSelected ? `&idSelected=${idSelected}` : ''}`);
    };

    return (
        <>
            <div className="rounded-tl-lg border-b-[0.5px] border-gray-300 bg-gray-50 py-2 px-4 font-semibold">
                {translator('NAME')}
            </div>
            {data?.length > 0 ? (
                <div>
                    {data.map((node, index) => {
                        let paddingLeft = `20px`;
                        if (node?.level === 1) paddingLeft = `48px`;
                        else if (node?.level === 2) paddingLeft = `68px`;

                        return (
                            <React.Fragment key={index}>
                                <div className="flex items-center py-2 px-4">
                                    <div className="flex-1 flex items-center truncate">
                                        <div style={{ paddingLeft }} className="relative h-5">
                                            <p
                                                className={classNames(
                                                    'absolute right-0 w-5 flex justify-center flex-none',
                                                    node?.is_required ? 'top-[7px]' : 'top-1'
                                                )}
                                            >
                                                {!node?.is_required ? (
                                                    <span
                                                        className="cursor-pointer"
                                                        onClick={() => handleUpdateVisibility(node?.id, !node?.is_visible)}
                                                    >
                                                        {node?.is_visible ? (
                                                            <EyesIcon fill="#111827" width="18" height="12" />
                                                        ) : (
                                                            <EyesOffIcon />
                                                        )}
                                                    </span>
                                                ) : (
                                                    <DotIcon className={node?.level > 0 ? 'w-1 h-1' : ''} />
                                                )}
                                            </p>
                                        </div>
                                        <p
                                            className={classNames(
                                                'absolute right-0 w-5 flex justify-center flex-none',
                                                node?.is_required ? 'top-[7px]' : 'top-1'
                                            )}
                                        >
                                            {!node?.is_required ? (
                                                <span
                                                    className="cursor-pointer"
                                                    onClick={() => handleUpdateVisibility(node?.id, !node?.is_visible)}
                                                >
                                                    {node?.is_visible ? (
                                                        <EyesIcon fill="#111827" width="18" height="12" />
                                                    ) : (
                                                        <EyesOffIcon />
                                                    )}
                                                </span>
                                            ) : (
                                                <DotIcon className={node?.level > 0 ? 'w-1 h-1' : ''} />
                                            )}
                                        </p>
                                    </div>
                                    <p
                                        className="pl-2 text-enterprise font-semibold cursor-pointer truncate"
                                        onClick={() => {
                                            if (!node?.parent?.parent_id && !node?.parent_id) {
                                                handleEditProperty(node?.id);
                                            } else handleEditProperty(node?.parent?.parent_id || node?.parent_id, node?.id);
                                        }}
                                    >
                                        {node?.name}
                                    </p>
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            ) : (
                <div className="h-[calc(100%-37px)] flex items-center justify-center">
                    <NoContentWithImage content={translator('NO_PROPERTIES')} img={emptyIconProperty} />
                </div>
            )}
        </>
    );
};
