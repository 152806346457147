import CompanyDetail from 'components/Management/Company/CompanyDetail';
import ManagementMasterPage from 'pages/RouteMasterPage';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';

const CompanyDetailPage = () => {
    return (
        <ManagePermissionWrapper>
            <CompanyDetail>
                <ManagementMasterPage />
            </CompanyDetail>
        </ManagePermissionWrapper>
    );
};

export default CompanyDetailPage;
