import React from 'react';
import { HOST_URL } from 'constant/api.const';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

interface HeaderSignFormProps {
    title: string;
    subTitle: string;
    errorMsg?: any;
    textInside: 'in' | 'up';
}

const HeaderSignForm: React.FC<HeaderSignFormProps> = ({
    title,
    subTitle,
    errorMsg,
    textInside,
}) => {
    const { t: translator } = useTranslation();
    const handleLoginGg = () => {
        window.location.href = `${HOST_URL}/oauth2/google/login`;
    };

    const handleLoginFb = () => {
        window.location.href = `${HOST_URL}/oauth2/facebook/login`;
    };

    return (
        <>
            <div className="mb-3 pb-0 text-center relative">
                <p className="text-2xl font-semibold text-black mb-2">
                    {title}
                </p>
                <p className="text-sm text-blue_0">{subTitle}</p>
                <div className="">
                    {errorMsg && (
                        <div className="flex items-center justify-start w-full rounded-lg bg-red-100 py-2 mt-2">
                            <ExclamationCircleIcon className='w-5 text-red-600 mx-2' />
                            <span className="text-black text-sm text-left">{errorMsg}</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default HeaderSignForm;
