import { Tab } from '@headlessui/react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import RewardInformation from './RewardDetailsComponents/RewardInformation';
import RewardHistory from './RewardDetailsComponents/RewardHistory';
import { DetailsRewardsType } from 'types/Rewards.type';
import rewardService from 'services/reward.service';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { checkPermission } from 'utils/commonFun';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';

const listTab = (translator: Function) => {
    const type = [
        {
            name: 'Information',
            hiddenWhenDeactivate: false,
            href: 'information',
        },
        {
            name: 'Reward History',
            hiddenWhenDeactivate: false,
            href: 'history',
        },
    ];
    return type;
};

const RewardDetails: React.FC = ({ children }) => {
    const { t: translator } = useTranslation();
    const params = useParams<{ id: string; rewardId: string }>();
    const search = useLocation().search;
    const tab = new URLSearchParams(search).get('tab');
    const defaultIndex = useMemo(
        () => (tab ? ['information', 'history'].indexOf(tab) : 0),
        [tab]
    );

    const [dataDetails, setDataDetails] = useState<DetailsRewardsType>();
    const [selectedIndex, setSelectedIndex] = useState(
        defaultIndex < 0 ? 0 : defaultIndex
    );
    const listDetailTab = useMemo(
        () =>
            listTab(translator).filter((i) => i.hiddenWhenDeactivate === false),
        []
    );

    useEffect(() => {
        rewardService
            .getReward(params.id, params.rewardId)
            .then((res) => {
                setDataDetails({
                    id: res?.data?.id,
                    name: res?.data?.name,
                });
            })
            .catch((err) => console.log(err));
    }, []);

    return (
        <>
          <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.ENGAGEMENT')}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    {
                        name: translator('DASHBOARD.SIDEBAR.REWARD'),
                        href: `/workspace/${params.id}/management/reward`,
                        className: '',
                    },
                    { name: dataDetails?.name || 'reward', href: '', className: 'truncate' },
                ]}
            />

            <div className="w-full">
                <Tab.Group
                    defaultIndex={defaultIndex < 0 ? 0 : defaultIndex}
                    selectedIndex={selectedIndex}
                    onChange={setSelectedIndex}
                >
                    <Tab.List className="bg-white opacity-100 z-2 w-full text-sm px-2 grid grid-cols-7 items-center ">
                        <div className="col-span-5 flex scrollbar overflow-auto  m-4">
                            {listDetailTab.map((i, index) => (
                                <Tab
                                    key={index}
                                    className={({ selected }) => {
                                        const tabClassName = `flex justify-start items-center p-2 lg:px-12 lg:py-3 text-left font-semibold outline-none ${selected
                                            ? 'text-ooolab_dark_1  bg-[#FAFAFC]'
                                            : 'text-ooolab_dark_2'
                                            }`;
                                        return tabClassName;
                                    }}
                                >
                                    <span>{i.name}</span>
                                </Tab>
                            ))}
                        </div>
                        <div
                            className={`col-span-2 text-ooolab_dark_2 font-semibold text-right `}
                        >
                            {translator("Reward ID")}
                            <span className="bg-ooolab_light_100 text-ooolab_dark_1 ml-2 py-1 px-4">
                                {dataDetails?.id || ''}
                            </span>
                        </div>
                    </Tab.List>
                    <Tab.Panels className="z-1 mx-6 h-full outline-none border-t">
                        <Tab.Panel>
                            {checkPermission('rewardRewardsInformation', 'R') && <RewardInformation />}
                        </Tab.Panel>
                        <Tab.Panel>
                            {checkPermission('rewardRewardsHistory', 'R') && <RewardHistory />}
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </>
    );
};

export default RewardDetails;
