import React, { FC, useContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { InputField, LabelForm, TextareaField } from 'components/InputForm/InputForm';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';
import { ImageAvatar } from 'components/Image';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { UserContext } from 'contexts/User/UserContext';
import workspaceService from 'services/workspace.service';
import { VALIDATE_FIELD } from 'utils/validate';

type ModalDepositWithdrawProps = {
    userData: any;
    data: any;
    translator: (key: string, isNew?: boolean) => string;
    type?: 'deposit' | 'withdraw';
    page?: 'users' | 'courses';
    onClose: () => void;
    onConfirm: (values?: any) => void;
};

export const ModalDepositWithdraw: FC<ModalDepositWithdrawProps> = ({
    userData,
    data,
    translator,
    type = 'deposit',
    page = 'users',
    onClose,
    onConfirm,
}) => {
    const params: any = useParams();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {
        userState: { result },
    } = useContext(UserContext);

    const workspaceId = params?.id;
    const userId = params?.userId;

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        getValues,
        watch,
        reset,
        setValue,
        setError,
        clearErrors,
    } = useForm();

    const onDepositCreditUser = (bodyParams: any) => {
        setIsLoading(true);

        workspaceService
            .depositCreditAccounts(workspaceId, bodyParams)
            .then((res) => {
                Toastify.success(translator("COURSES.DEPOSIT_CREDIT_SUCCESSFULLY", true));
                onConfirm?.();
            })
            .catch((err) => Toastify.error())
            .finally(() => setIsLoading(false));
    };

    const onWithdrawCreditUser = (bodyParams: any) => {
        setIsLoading(true);

        workspaceService
            .withdrawCreditAccounts(workspaceId, bodyParams)
            .then((res) => {
                Toastify.success(translator("COURSES.WITHDRAW_CREDIT_SUCCESSFULLY", true));
                onConfirm?.();
            })
            .catch((err) => Toastify.error())
            .finally(() => setIsLoading(false));
    };

    const final_credit_balance = useMemo(() => {
        const amount = watch('amount') || 0;
        const credit = data?.credit_balance || 0;
        let final_credit = 0;

        if (type === 'deposit') {
            final_credit = Number(credit) + Number(amount);
        } else final_credit = Number(credit) - Number(amount);

        return final_credit;
    }, [watch('amount')]);

    const conditionCredit = useMemo(() => {
        if (type === 'deposit') {
            return watch('amount') > 0;
        }

        if (watch('amount') > 0 && watch('amount') == data?.credit_balance) return true;
        return watch('amount') > 0 && final_credit_balance > 0;
    }, [final_credit_balance, watch('amount')]);

    const onSubmitForm = (values) => {
        if (page === 'users') {
            const payload = {
                credit_account_id: data?.id,
                amount: values?.amount >= 0 ? Number(values?.amount) : null,
                note: values?.note || null,
            };
            if (type === 'deposit') onDepositCreditUser([payload]);
            else onWithdrawCreditUser(payload);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="text-sm">
                <div className="bg-blue-50 grid grid-cols-2 gap-3 -mx-5 px-5 py-4 border-t border-gray-300">
                    <div>
                        <LabelForm title={translator('ACCOUNT_NAME')} />
                        <p className="truncate">{data?.name}</p>
                    </div>
                    <div>
                        <LabelForm title={translator('ACCOUNT_OWNER')} />
                        <div className="flex items-center space-x-1">
                            <ImageAvatar imageSrc={userData?.avatar_url} />
                            <span className="font-semibold text-enterprise truncate">{userData?.display_name}</span>
                        </div>
                    </div>
                    <InputField
                        name="amount"
                        type="number"
                        register={register('amount', {
                            required: {
                                value: true,
                                message: translator('FORM_CONST.REQUIRED_FIELD', true),
                            },
                            pattern: {
                                value: type === 'deposit' && VALIDATE_FIELD.NUMBER_1_100000,
                                message: translator('Enter number 1-100000', true),
                            },
                            validate: {
                                insufficientBalance: (value) => {
                                    const amount = value || 0;
                                    const credit = data?.credit_balance || 0;
                                    let final_credit = 0;
                            
                                    if (type === 'deposit') {
                                        final_credit = Number(credit) + Number(amount);
                                    } else final_credit = Number(credit) - Number(amount);

                                    if (type === 'withdraw' && final_credit < 0) {
                                        return translator('INSUFFICIENT_BALANCE');
                                    };

                                    return true;
                                }
                            }
                        })}
                        label={translator('NUMBER_OF_CREDIT_S')}
                        placeholder={translator('ENTER_HERE')}
                        isRequired
                        errors={errors}
                        classNameInput="!w-2/3"
                    />
                    <div>
                        <LabelForm title={translator('FINAL_CREDIT_BALANCE')} />
                        <div className="flex items-center space-x-1 truncate mt-[13px]">
                            <span>{data?.credit_balance}</span>
                            <ArrowNarrowRightIcon className="w-4 h-4" />
                            {conditionCredit ? (
                                <span className="font-semibold truncate" style={{ color: type === 'deposit' ? '#16A34A' : '#EF4444' }}>
                                    {final_credit_balance}
                                </span>
                            ) : (
                                <span>-</span>
                            )}
                        </div>
                    </div>
                </div>
                <TextareaField
                    name="note"
                    register={register('note')}
                    label={translator('NOTES')}
                    placeholder={translator('ADD_YOUR_NOTES_HERE')}
                    maxLength={1000}
                    isCountter
                    textLength={watch('note')?.length}
                    className="mt-2 mb-4"
                />
            </div>
            <div className="pt-4 border-t border-gray-300 -mx-5 flex justify-center space-x-4">
                <ButtonOutline onClick={onClose} type={'button'}>
                    {translator('CANCEL', true)}
                </ButtonOutline>
                <ButtonPrimary
                    type="submit"
                    disabled={isLoading || !(isDirty && watch('amount') > 0)}
                    className={`${isLoading ? 'bg-ooola_EFF0FA text-ooolab_8083A3 cursor-not-allowed' : ''}`}
                >
                    {translator('CONFIRM', true)}
                </ButtonPrimary>
            </div>
        </form>
    );
};
