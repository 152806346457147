import { CommentIcon, DeleteIcon, ReorderAlt } from 'assets/icon';
import { LIBRARY_ICONS, LibraryTypeEnum } from 'components/Library/LibraryContants';
import { debounce } from 'lodash';
import { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import libraryService from 'services/library.service';
import './FileWithAction.scss'
import { ConfirmDeleteModal } from '_shared';
import ReactTooltip from "react-tooltip";
import { checkPermission } from 'utils/commonFun';

const MAX_LENGTH = 1024;

export default function ContentItem({
    file,
    contentSelected,
    isSectionActive,
    isStandalone,
    sectionSelected,
    handleSelectContentToPreview,
    setSectionSelected,
    sectionItem,
    onDragOver,
    onDragStart,
    onDragEnd,
    index,
    translator,
    sections,
    setSections,
    setOnSaving,
    handleDeleteFileOfSection,
    isShowNote = true
}) {
    const [value, setValue] = useState(file?.library_content?.title || file?.library_content?.name);
    const [hasEdit, setHasEdit] = useState(false);
    const htmlElRef = useRef(null);

    const [isOpenModalDeleteFile, setOpenModalDeleteFile] = useState(false)
    const [fileId, setFileId] = useState('');
    const deleteFile = (value: boolean) => {
        if (value) {
            handleDeleteFileOfSection(sectionItem, fileId);
        }
        setOpenModalDeleteFile(false);
    };

    const onEditName = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setHasEdit(true);
        setTimeout(() => {
            setFocus();
        }, 10);
        const fileName =
            file?.library_content?.object_type === LibraryTypeEnum.h5p
                ? file?.library_content?.title || file?.library_content?.name
                : file?.library_content?.name?.substr(0, file?.library_content?.name.lastIndexOf('.'));
        setValue(fileName);
    };

    const setFocus = () => {
        htmlElRef.current && htmlElRef.current.focus();
    };

    const params: any = useParams();

    const handleUpdateContentName = useCallback(
        debounce((nextValue) => {
            const fileName =
                file?.library_content?.object_type === LibraryTypeEnum.h5p
                    ? file?.library_content?.name
                    : file?.library_content?.name?.substr(0, file?.library_content?.name.lastIndexOf('.'));
            if (nextValue === fileName) return setValue(file?.library_content?.name);
            handleRename(nextValue);
        }, 800),
        [sectionItem, file, sections]
    );

    const handleRename = async (nextValue) => {
        setOnSaving(true);
        if (nextValue?.length + file?.library_content?.sub_object_type?.length > MAX_LENGTH) return;
        const fileName =
            file?.library_content?.object_type === LibraryTypeEnum.h5p
                ? nextValue
                : nextValue + file?.library_content?.sub_object_type;
        if (file?.library_content?.object_type === LibraryTypeEnum.h5p) {
            await libraryService.renameH5P(params?.id, file?.library_content?.object_id, { title: fileName });
        } else {
            const id = file?.library_content?.object_id;
            await libraryService.updateFile(params?.id, id, {
                name: fileName,
            });
        }
        setValue(fileName);
        const fileUpdated = { ...file, library_content: { ...file?.library_content, name: fileName } };
        const newSectionContents = sectionItem?.section_contents?.map((item) => {
            if (item?.id === fileUpdated?.id) return fileUpdated;
            return item;
        });
        const newSections = sections?.map((section) => {
            if (section?.id === sectionItem?.id) return { ...section, section_contents: newSectionContents };
            return section;
        });
        setSections(newSections);
        handleSelectContentToPreview(fileUpdated);
        setOnSaving(false)
    };

    return (
        <div>
            <div
                className={`FileWithAction group/item ${contentSelected?.id === file?.id && isSectionActive ? 'active standalone-active' : ''} 
                              ${isStandalone ? 'standalone-item ' : ''}`}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (checkPermission('libraryContentLessonsOrScorm', 'R')) {
                        if (sectionItem?.id !== sectionSelected?.id && setSectionSelected) setSectionSelected?.(sectionItem);
                        handleSelectContentToPreview(file);
                    }
                }}
                onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (checkPermission('libraryContentLessonsOrScorm', 'U')) {
                        onDragOver(index);
                    }
                }}
                draggable={checkPermission('libraryContentLessonsOrScorm', 'U')}
                onDragStart={(e) => {
                    e.stopPropagation();
                    if (checkPermission('libraryContentLessonsOrScorm', 'U')) {
                        onDragStart(e, index);
                    }
                }}
                onDragEnd={(e) => {
                    e.stopPropagation();
                    if (checkPermission('libraryContentLessonsOrScorm', 'U')) {
                        onDragEnd();
                    }

                }
                }
            >
                <div className="flex-item ">
                    <button className="reorder hidden group-hover/item:block cursor-move disabled:cursor-not-allowed" disabled={!checkPermission('libraryContentLessonsOrScorm', 'U')}>
                        <ReorderAlt />
                    </button>
                    <img
                        alt="libary-icon"
                        className="library-icon block group-hover/item:hidden"
                        src={
                            file?.library_content?.object_type
                                ? LIBRARY_ICONS(translator)?.find((item) => item?.value === file?.library_content?.object_type)?.icon
                                : LIBRARY_ICONS(translator)[0].icon
                        }
                    />
                    <div className="file-name">
                        {!hasEdit ? (
                            <>
                                <span
                                    className="truncate cursor-text" onClick={(e) => onEditName(e)}
                                    data-for={`file-${file?.id}`}
                                    data-tip={value}
                                >
                                    {value}
                                </span>
                                {/*@ts-ignore*/
                                    <ReactTooltip
                                        place="top"
                                        type="dark"
                                        effect="float"
                                        id={`file-${file?.id}`}
                                        getContent={(value) => <div>{value}</div>}
                                    />}
                            </>

                        ) : (
                            <input
                                ref={htmlElRef}
                                value={value}
                                className="section-input"
                                onFocus={(e) => e.stopPropagation()}
                                disabled={!checkPermission('libraryContentLessonsOrScorm', 'U')}
                                onBlur={(e) => {
                                    setHasEdit(false);
                                    handleUpdateContentName(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                    e.code === 'Space' && e.stopPropagation();
                                    e.code === 'Enter' && setHasEdit(false);
                                    e.code === 'Enter' && handleUpdateContentName(value);
                                }}
                                onChange={(event) => {
                                    setValue(event.target.value);
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                            />
                        )}
                    </div>
                </div>
                {(file?.note && isShowNote) ? (
                    <span className="group-hover/item:hidden px-2 py-2.5">
                        <CommentIcon width={16} height={16} />
                    </span>
                ) : (
                    ''
                )}
                {checkPermission('libraryContentLessonsOrScorm', 'U') ? <button
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpenModalDeleteFile(true);
                        setFileId(file?.id);
                    }}
                    className=" hidden group-hover/item:block mr-2"
                    disabled={!checkPermission('libraryContentLessonsOrScorm', 'U')}
                >
                    <DeleteIcon width="16px" height="16px" />
                </button> : ''}

            </div>
            {value?.length + file?.library_content?.sub_object_type?.length > MAX_LENGTH && (
                <div className="maxLengthFileName">
                    {translator('FORM_CONST.MAX_LENGTH_TEXT', { maxLength: MAX_LENGTH })}
                </div>
            )}
            <ConfirmDeleteModal
                isOpen={isOpenModalDeleteFile}
                onClose={deleteFile}
                titleModal={translator('LIBRARY.REMOVE_CONTENT')}
                btnPrimaryTitleLoading={translator('LIBRARY.REMOVING')}
                btnPrimaryTitle={translator('LIBRARY.REMOVE')}
            >
                <div className="text-sm">{translator('LIBRARY.REMOVE_CONTENT_MESSAGE')}</div>
            </ConfirmDeleteModal>
        </div>

    );
}
