import React, { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";
import "./SideLeft.scss";
import { CaretIcon } from "assets/icon";
import { CircleSpin } from "components/UiComponents/CircleSpin";

const TASKS = [
    {
        value: 'completed',
        label: 'Completed'
    },
    {
        value: 'waiting',
        label: 'Waiting for Grade'
    },
    {
        value: 'assigned',
        label: 'Assigned'
    }
]
export const SideLeft = ({ assignments, learnerSelect, indexDefault, loading, setLearnerSelect, setTabsStatus }) => {
    const [activeDisclosurePanel, setActiveDisclosurePanel] = useState(null);

    const togglePanels = (newPanel) => {
        if (activeDisclosurePanel) {
            if (activeDisclosurePanel.key !== newPanel.key && activeDisclosurePanel.open) {
                activeDisclosurePanel.close();
            }
        }
        setActiveDisclosurePanel({
            ...newPanel,
            open: !newPanel.open
        });
    }

    return (<div className="sideLeft sticky top-[48px]">
        {loading ?
            <div className="flex items-center justify-center mt-8">
                <CircleSpin color="text-primary-500" />
            </div> :
            indexDefault != null && TASKS?.map((item, index) => (
                <Disclosure as="div" key={index} defaultOpen={index == indexDefault}>
                    {
                        (panel) => {
                            const { open, close } = panel
                            return (<div className="sideLeft-item">
                                <Disclosure.Button onClick={() => {
                                    if (!open) {
                                        close();
                                    }
                                    togglePanels({ ...panel, key: index });
                                }}>
                                    <div className={`sideLeft-header ${open ? 'active' : ''}`}>
                                        <span className={`${open ? 'rotate-90' : null}`}><CaretIcon size={24} /></span>
                                        <span className="sideLeft-label">{item.label}</span>
                                        <span className="sideLeft-count">{assignments[item?.value]?.length}</span>
                                    </div>
                                </Disclosure.Button>
                                <Disclosure.Panel className="sideLeft-container">
                                    {assignments[item.value]?.length > 0 ? assignments[item.value]?.map(assignment =>
                                        <div onClick={() => {setLearnerSelect(assignment); setTabsStatus(item.value)}} key={assignment?.id} className={`sideLeftUser ${learnerSelect?.id == assignment?.id ? 'active' : ''}`}>
                                            <div className="sideLeftUser-avatar"
                                                style={{ backgroundImage: `url(${assignment?.learner?.avatar_url})` }}></div>
                                            <div className="sideLeftUser-name">{
                                                assignment?.learner?.display_name
                                            }</div>
                                        </div>) : <div className="px-8 text-gray-500 text-sm">No Task.</div>
                                    }
                                </Disclosure.Panel>
                            </div>)
                        }
                    }
                </Disclosure>
            ))
        }
    </div>)
}