import React from 'react';

const Timer = ({ seconds, translator }) => {
    const [timeLeft, setTimeLeft] = React.useState(seconds);

    const displayTimeLeft = (_seconds: number) => {
        let hours: number | string;
        let minutes: number | string;
        let seconds: number | string;
        if (_seconds < 0) {
            hours = '00';
            minutes = '00';
            seconds = '00';
        }
        else {
            hours = Math.floor(_seconds / 3600);
            minutes = Math.floor((_seconds - hours * 3600) / 60);
            seconds = _seconds - hours * 3600 - minutes * 60;

            if (hours < 10) hours = '0' + hours;
            if (minutes < 10) minutes = '0' + minutes;
            if (seconds < 10) seconds = '0' + seconds;
        }
        return (
            <div className="flex">
                <div>
                    <p className="text-center font-semibold text-ooolab_dark_1 leading-5">
                        {hours}
                    </p>
                    <p className="text-center text-ooolab_10px text-ooolab_dark_2 leading-3">
                        {translator("DASHBOARD.TEACHER.HOURS")}
                    </p>
                </div>
                <div className="mx-2 xs:mx-1">
                    <p className="text-center font-semibold text-ooolab_dark_1 leading-5 border-l border-r border-ooolab_blue_4 px-2 xs:px-1">
                        {minutes}
                    </p>
                    <p className="text-center text-ooolab_10px text-ooolab_dark_2 leading-3 px-2 xs:px-1">
                        {translator("DASHBOARD.TEACHER.MINUTES")}
                    </p>
                </div>
                <div>
                    <p className="text-center font-semibold text-ooolab_dark_1 leading-5">
                        {seconds}
                    </p>
                    <p className="text-center text-ooolab_10px text-ooolab_dark_2 leading-3">
                        {translator("DASHBOARD.TEACHER.SECONDS")}
                    </p>
                </div>
            </div>
        );
    };

    React.useEffect(() => {
        if (!timeLeft) return;

        const intervalTime = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalTime);
    }, [timeLeft]);

    return <>{displayTimeLeft(timeLeft)}</>;
};

export default Timer;
