import { Tab } from '@headlessui/react';
import GroupProfileForm from './GroupProfileForm';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { useContext } from 'react';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const listTab = ['Information'];

const GroupDetail = ({children}) => {
    const workspaceState = useContext(GetWorkspaceContext);
    const customGroupName = workspaceState?.getWorkspaceDetailState?.currentRouteDetail?.[0]?.name
    const params : any= useParams();
    const {t: translator} = useTranslation()
    return (
        <>
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.COMMUNICATION')}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    { name: translator('GROUP.GROUP_MANAGEMENT'), href: `/workspace/${params?.id}/management/custom-groups`, className: '' },
                    { name: customGroupName, href: ``, className: '' },
                ]}
            />
            <Tab.Group>
                <div className='p-1 text-xl font-semibold text-ooolab_dark_1 ml-8'>{customGroupName}</div>
                <Tab.List className="w-full text-ooolab_xs items-center border-b border-ooolab_bar_color mt-6">
                    {listTab.map((i) => (
                        <Tab
                            className={({ selected }) => {
                                const tabClassName = ` py-1 mx-5 text-center font-semibold text-sm flex  ${
                                    selected
                                        ? 'text-primary-500 border-b-2 border-primary-500 '
                                        : 'text-ooolab_dark_2'
                                }`;
                                return tabClassName;
                            }}
                        >
                        <InformationCircleIcon className="mr-2 w-5 h-5 text-primary-500" /> {i}
                        </Tab>
                    ))}
                    <div className="col-span-1"></div>
                
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>
                        <GroupProfileForm />
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </>
        
    );
};

export default GroupDetail;
