import { useContext, useEffect, useMemo, useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { ButtonIcon, ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useParams } from 'react-router-dom';
import { InputField, TextareaField } from 'components/InputForm/InputForm';
import { FORM_CONST } from 'constant/form.const';
import courseService from 'services/course.service';
import DatePickerInput from 'components/Management/components/Form/DatePicker';
import dayjs from 'dayjs';
import TimePickerInput from 'components/Management/components/Form/TimePicker';
import './SessionEdit.scss';
import moment from 'moment';
import { UserContext } from 'contexts/User/UserContext';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import SelectBox from '_shared/components/Select';
import { useDebouncedState } from 'hooks/useDebounce';
import { useAppSelector } from 'hooks/hooks';
import workspaceService from 'services/workspace.service';
import { checkPermission } from 'utils/commonFun';
import InputRadio from '_shared/components/Form/InputRadio/InputRadio';

const PER_PAGE = 20;

export const SessionEdit = ({ data, onClose, refreshData, onDelete, editLearner }) => {
    const {
        userState: { result },
    } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const params: any = useParams();

    const {
        handleSubmit,
        reset,
        control,
        watch,
        formState: { errors, isValid, isDirty },
        register,
        getValues,
        trigger,
        clearErrors,
        setValue
    } = useForm({ mode: 'onChange' });

    const { t: translator } = useTranslation();

    const hasDisabled = useMemo(() => {
        return data?.display_status === 'in_progress';
    }, [data]);

    const [paginationLesson, setPaginationLesson] = useState({ page: 1, total: 0 });
    const [lessons, setLessions] = useState([]);
    const [inputSearchLesson, setInputSearchLesson] = useDebouncedState('', 0);
    const [loadingLesson, setLoadingLesson] = useState(false);

    const [paginationInstructor, setPaginationInstructor] = useState({ page: 1, total: 0 });
    const [instructorsList, setLInstructorsList] = useState([]);
    const [inputSearchInstructor, setInputSearchInstructor] = useDebouncedState('', 0);
    const [loadingInstructor, setLoadingInstructor] = useState(false);

    const [paginationEnrollment, setPaginationEnrollment] = useState({ page: 1, total: 0 });
    const [enrollments, setEnrollments] = useState([]);
    const [inputSearchEnrollment, setInputSearchEnrollment] = useDebouncedState('', 0);
    const [loadingEnrollment, setLoadingEnrollment] = useState(false);
    const { setting } = useAppSelector((state) => state.course);
    const [inputSearchLearnerOfLearningGroup, setInputSearchLearnerOfLearningGroup] = useDebouncedState('', 0);
    const [learnerOfLearningGroup, setLearnerOfLearningGroup] = useState([]);
    const [attendeeRemoved, setAttendeeRemoved] = useState([]);

    const getInstructors = (page: number = 1) => {
        setLoadingInstructor(true);
        const param = {
            page: page,
            per_page: 20,
            q: inputSearchInstructor,
            status: 'active',
        };
        courseService
            .getInstructorEnrolled(params.id, data?.course?.id, param)
            .then((res) => {
                const listFormated = res?.data?.items?.map((item) => {
                    return {
                        ...item,
                        id: item?.instructor?.id,
                        name: item.instructor?.display_name,
                        value: item?.instructor?.id,
                        avatar_url: item?.instructor?.avatar_url,
                    };
                });
                if (page === 1) setLInstructorsList(listFormated);
                if (page > 1) {
                    setLInstructorsList([...instructorsList, ...listFormated]);
                }
                setPaginationInstructor({ total: res?.data?.total, page: res?.data?.page });
            })
            .finally(() => setLoadingInstructor(false));
    };

    const getLessons = (page: number = 1) => {
        setLoadingLesson(true);
        const param = {
            page: page,
            per_page: PER_PAGE,
            q: inputSearchLesson,
        };
        courseService
            .getLessonOfCourse(params.id, data?.course?.id, param)
            .then((res) => {
                const listFormated = res?.data?.items?.map((item) => {
                    return {
                        ...item,
                        id: item.id,
                        name: item?.title,
                        value: item.id,
                    };
                });
                if (page === 1) setLessions(listFormated);
                if (page > 1) {
                    setLessions([...lessons, ...listFormated]);
                }
                setPaginationLesson({ total: res?.data?.total, page: res?.data?.page });
            })
            .finally(() => setLoadingLesson(false));
    };

    const getEnrollmentOfCourse = (page: number = 1) => {
        setLoadingEnrollment(true);
        const param = {
            page: page,
            per_page: 20,
            q: inputSearchEnrollment,
            enrollment_as: data?.learning_groups?.length ? 'learning_group' : 'individual',
            statuses: 'active,enrolled',
        };
        if (!param.q) delete param.q;
        courseService
            .getEnrollments(params.id, data?.course?.id, param)
            .then((res) => {
                const listFormated = res?.data?.items?.map((enrollment) => {
                    if (!enrollment?.learning_group?.id) {
                        const learnerInfo = enrollment?.learners?.[0];
                        const isExist = data?.learners?.find((learnerItem) => learnerItem?.id === enrollment?.learner_id);
                        return {
                            id: learnerInfo?.id,
                            name: learnerInfo?.display_name,
                            avatar_url: learnerInfo?.avatar_url,
                            value: learnerInfo.id,
                            disabled:
                                setting?.enrollment_type === 'credit' &&
                                (!learnerInfo?.credit_balance?.available_credit || learnerInfo?.credit_balance?.available_credit <= 0) && !isExist,
                        };
                    }
                    const learningGroup = enrollment?.learning_group;
                    return {
                        ...learningGroup,
                        id: learningGroup?.id,
                        name: learningGroup?.name,
                        value: learningGroup?.id,
                        avatar_url: learningGroup?.avatar_url,
                        learners: enrollment?.learners?.map((learner) => {
                            const isExist = data?.learners?.find((learnerItem) => learnerItem?.id === learner?.id);
                            return {
                                id: learner?.id,
                                name: learner?.display_name,
                                avatar_url: learner?.avatar_url,
                                value: learner.id,
                                disabled:
                                    setting?.enrollment_type === 'credit' &&
                                    (!learner?.credit_balance?.available_credit || learner?.credit_balance?.available_credit <= 0) && !isExist,
                            };
                        }),
                    };
                });
                if (page === 1) setEnrollments(listFormated);
                if (page > 1) {
                    setEnrollments([...enrollments, ...listFormated]);
                }
                setPaginationEnrollment({ total: res?.data?.total, page: res?.data?.page });
            })
            .finally(() => setLoadingEnrollment(false));
    };

    const fetchSessionDetail = () => {
        workspaceService.getSessionDetail(params.id, data?.id)
            .then(res => {
                setValue('vc_link', res?.data?.vc_link);
            })
    }

    useEffect(() => {
        if (checkPermission('course', 'R')) {
            getEnrollmentOfCourse(1);
        }
    }, [inputSearchEnrollment]);

    useEffect(() => {
        if (checkPermission('libraryContent', 'R')) {
            getLessons(1);
        }
    }, [inputSearchLesson]);

    useEffect(() => {
        getInstructors(1);
    }, [inputSearchInstructor]);

    const onSubmit = (value) => {
        setLoading(true);
        let session: any = {
            id: data.id,
            name: value?.name,
            instructors: value?.instructors?.map((instructor) => instructor?.value),
            start_time: dayjs(value?.date)
                .tz(result.time_zone)
                .hour(dayjs(value?.start_time).hour())
                .minute(dayjs(value?.start_time)?.minute())
                ?.format(),
            end_time: dayjs(value?.date)
                .tz(result.time_zone)
                .hour(dayjs(value?.end_time).hour())
                .minute(dayjs(value?.end_time)?.minute())
                ?.format(),
            location: value?.location,
            vc_link: value?.vc_link,
            extra_link: value?.extra_link,
            // record_link: value?.record_link,
            instructor_note: value?.instructor_note,
            lesson_id: value?.lesson?.[0]?.value,
            remove_attendees_type: value?.remove_attendees_type || undefined
        };

        if ( attendeeRemoved?.length === 0) {
            delete session?.remove_attendees_type;
        }

        if (value?.learners) {
            session.learners = value?.learners?.map((learner) => learner?.value)
        }
        if (value?.learning_group?.length) {
            session.learning_group = value?.learning_group?.[0]?.id
        }
        if (hasDisabled) {
            delete session?.vc_link;
        }

        courseService
            .updateSession(params.id, data?.course?.id || data?.course_id, session)
            .then((res) => {
                if (res.data?.error) {
                    return Toastify.error(res.data?.error?.description);
                }
                Toastify.success();
                onClose();
                reset({});
                refreshData();
                setLoading(false);
            })
            .catch(() => {
                Toastify.error();
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (data?.id) {
            initialValues(data);
            if (!data?.vc_link) {
                fetchSessionDetail();
            }
        }
    }, [data?.id]);

    const initialValues = async (values) => {
        const learnersData = mappinDropDrownValues(values?.learners);
        const instructorsData = mappinDropDrownValues(values?.instructors);
        const lessonData = values?.lesson ? mappinDropDrownValues([values?.lesson]) : null;
        let newData: any = {
            date: new Date(values?.start_time),
            start_time: new Date(dayjs(values?.start_time).utc().tz(result?.time_zone).format("YYYY/MM/DD HH:mm")),
            end_time: new Date(dayjs(values?.end_time).utc().tz(result?.time_zone).format("YYYY/MM/DD HH:mm")),
            vc_link: values?.vc_link,
            instructor_note: values?.instructor_note,
            location: values?.location,
            learners: learnersData,
            instructors: instructorsData,
            lesson: lessonData,
            extra_link: values?.extra_link,
        };
        if (values?.learning_groups?.length > 0) {
            const { data: { items } } = await courseService.getMoreLearnerAddToSession(params.id, data?.course?.id, {
                skip_pagination: true,
                learning_group_id: values?.learning_groups?.[0]?.id,
                exclude_session_ids: values?.id
            })

            newData = {
                ...newData,
                learning_group: [{ ...values?.learning_groups?.[0], learners: [...learnersData, ...mappinDropDrownValues([...items])] }],
            };
        }
        reset(newData);
    };
    const mappinDropDrownValues = (data) => {
        return (
            data?.map((item) => {
                return {
                    ...item,
                    id: item?.id,
                    name: item?.display_name || item?.title || item?.name,
                    value: item?.id,
                };
            }) ?? []
        );
    };

    const handleChangeAttendee = (item, action) => {
        if (data?.display_status !== 'in_progress' && data?.display_status !== 'not_started') {
            return;
        }
        const listAttendee = [...data?.learners, ...data?.instructors];
        const itemExist = listAttendee?.find((attendee) => attendee?.id === item?.value || attendee?.id === item?.id);
        if (itemExist) {
            if (action === 'add') {
                return setAttendeeRemoved((attendeeRemoved) => attendeeRemoved?.filter((attendee) => attendee?.id !== itemExist?.value && attendee?.id !== itemExist?.id));
            }
            return setAttendeeRemoved((attendeeRemoved) => [...attendeeRemoved?.filter((attendee) => attendee?.id !== itemExist?.value && attendee?.id !== itemExist?.id), itemExist]);
        }
    }
    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="sessionEdit-form"
            onClick={(e) => e?.stopPropagation()}
            onKeyDown={(e) => e?.stopPropagation()}
        >
            <div className="sessionEdit">
                <div className="sessionEdit-header">
                    <div className="sessionEdit-title">{translator("SESSION.EDIT_SESSION")}</div>
                    <div className="ml-auto sessionEdit-header-action">
                        <ButtonIcon onClick={onClose}>
                            <XIcon className="w-5 h-5" />
                        </ButtonIcon>
                    </div>
                </div>
                <div className="sessionEdit-content">
                    <div className="sessionEdit-infor">
                        <span>{translator("SESSION.SESSION_ID")}:</span>
                        <span className="text-primary-500">{data?.id}</span>
                    </div>

                    <div className="field">
                        <label className="sessionEdit-form-label">
                            {translator('Course')}
                        </label>
                        <div onClick={() => window?.open(`/workspace/${params?.id}/management/courses/${data?.course?.id}`)} className="text-xs cursor-pointer text-primary-500 font-semibold">
                            {data?.course?.title}
                        </div>
                    </div>

                    <div className="field">
                        <label className="sessionEdit-form-label">
                            {translator('Date')}
                            <span className="text-red-500"> *</span>
                        </label>
                        <div className="sessionEdit-form-input">
                            <DatePickerInput
                                control={control}
                                name="date"
                                placeholderText={translator('COURSES.SELECT_A_DATE')}
                                minDate={new Date()}
                                isRequired
                                classnames="!pl-2.5 !h-8"
                                iconCls="!top-2 !right-2.5 !left-auto"
                                handleOnChange={(value) => {
                                    setTimeout(() => {
                                        clearErrors();
                                        trigger('start_time', { shouldFocus: true });
                                    }, 100);
                                }}
                                formatDate="dd/MM/yyyy"
                                disabled={hasDisabled}
                            />
                        </div>
                    </div>
                    <div className="field">
                        <label className="sessionEdit-form-label">
                            {translator('COURSES.TIME')}
                            <span className="text-red-500"> *</span>
                        </label>
                        <div className=" grid grid-cols-2 gap-4 w-full">
                            <div className="col-span-1 ">
                                <TimePickerInput
                                    control={control}
                                    name="start_time"
                                    placeholderText={translator('COURSES.START_TIME')}
                                    filter={(time) => {
                                        if (watch('end_time')) return dayjs(time)?.isBefore(watch('end_time'));
                                        return new Date();
                                    }}
                                    disabled={!watch('date') || hasDisabled}
                                    placement="bottom"
                                    classnames="mt-1 text-xs !rounded !px-2 !py-2.5 !pl-7"
                                    iconCls=" w-4 h-4 !top-[14px] !left-2 !right-auto  "
                                    styles="!h-full"
                                    rules={{
                                        validate: (value) => {
                                            if (hasDisabled) {
                                                return true;
                                            }
                                            if (
                                                value &&
                                                moment(getValues('date')).isSame(new Date(), 'day') &&
                                                (moment(
                                                    `${moment(getValues('date')).format('YYYY-MM-DD')} ${moment(value).format(
                                                        'HH:mm'
                                                    )}`
                                                ).isBefore(moment()) ||
                                                    moment(getValues('date')).isBefore(new Date(), 'day'))
                                            ) {
                                                return 'Invalid start time. It must be in the future.';
                                            }
                                            return true;
                                        },
                                    }}
                                />
                            </div>
                            <div className="col-span-1 ">
                                <TimePickerInput
                                    control={control}
                                    name="end_time"
                                    placeholderText={translator('COURSES.END_TIME')}
                                    disabled={!watch('date') || hasDisabled}
                                    filter={(time) => {
                                        if (watch('start_time')) return dayjs(time)?.isAfter(watch('start_time'));
                                        return true;
                                    }}
                                    classnames="mt-1 text-xs !rounded !p-2 !py-2.5 !pl-7 "
                                    iconCls=" w-4 h-4 !top-[14px] !left-2 !right-auto"
                                    styles="!h-full"
                                />
                                {/* @ts-ignore */}
                                <ErrorMessage className="text-ooolab_error text-xs mt-1" errors={errors} name="endt_time" as="p" />
                            </div>
                        </div>
                    </div>
                    <div className="pl-[88px]">
                        {/* @ts-ignore */}
                        <ErrorMessage className="text-ooolab_error text-xs mt-1" errors={errors} name="start_time" as="p" />
                    </div>

                    {data?.learning_groups?.length && (
                        <>
                            <div className="field">
                                <label className="sessionEdit-form-label">
                                    {translator('Learning Group(s) ')}
                                    <span className="text-red-500"> *</span>
                                </label>
                                <div className="sessionEdit-form-input">
                                    <SelectBox
                                        control={control}
                                        name="learning_group"
                                        placeholder={translator('Select Learning Group')}
                                        isRequired
                                        placeholderSearchInput="Search a name"
                                        pagination={paginationEnrollment}
                                        onSearch={(value) => setInputSearchEnrollment(value)}
                                        getData={getEnrollmentOfCourse}
                                        data={enrollments}
                                        numberOfItemDisplayed={1}
                                        isMulti={false}
                                        errors={errors}
                                        value={watch('learning_group') || []}
                                        loading={loadingEnrollment}
                                        isDisabled={(dayjs
                                            .utc()
                                            .tz(result.time_zone)
                                            .isAfter(dayjs.utc(data?.start_time).tz(result?.time_zone)) || !checkPermission('course', 'R'))}
                                        actionAfterSelectItem={(item) => {
                                            let learners = item?.learners;
                                            data?.learners?.forEach(learner => handleChangeAttendee(learner, 'remove'));
                                            if (learners?.length) {
                                                learners?.forEach(learner => handleChangeAttendee(learner, 'add'));
                                                clearErrors('learners');
                                            }
                                            if (setting?.enrollment_type === 'credit') {
                                                setValue('learners', item?.learners?.filter(learner => !learner?.disabled));
                                                return;
                                            }
                                            data?.learners?.forEach(learner => handleChangeAttendee(learner, 'remove'));
                                            return setValue('learners', learners)
                                        }}
                                        isShowSearchIcon={true}
                                        actionAfterRemoveAllItem={() => {
                                            data?.learners.forEach(learner => handleChangeAttendee(learner, 'remove'));
                                            setValue('learners', []);
                                        }}
                                        actionAfterRemoveItemSelected={(item)=> handleChangeAttendee(item, 'remove')}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    <div className="field">
                        <label className="sessionEdit-form-label">
                            {translator('Learners')}
                            <span className="text-red-500"> *</span>
                        </label>
                        <div className="sessionEdit-form-input">
                            <SelectBox
                                control={control}
                                name="learners"
                                placeholder={translator('COURSES.SELECT_LEARNERS')}
                                isRequired
                                placeholderSearchInput="Search a name"
                                pagination={paginationEnrollment}
                                onSearch={(value) => {
                                    if (data?.learning_groups?.length > 0) {
                                        const learners = watch('learning_group')?.[0]?.learners?.filter(
                                            (learner) => learner?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) !== -1
                                        );
                                        setInputSearchLearnerOfLearningGroup(value);
                                        setLearnerOfLearningGroup(learners);
                                    } else setInputSearchEnrollment(value);
                                }}
                                getData={(page) => getEnrollmentOfCourse(page)}
                                data={
                                    data?.learning_groups?.length > 0
                                        ? inputSearchLearnerOfLearningGroup
                                            ? learnerOfLearningGroup
                                            : watch('learning_group')?.[0]?.learners
                                        : enrollments
                                }
                                numberOfItemDisplayed={3}
                                isMulti={true}
                                errors={errors}
                                value={watch('learners') || []}
                                isDisabled={(dayjs.utc().tz(result.time_zone).isAfter(dayjs.utc(data?.end_time)) || !checkPermission('course', 'R'))}
                                loading={loadingEnrollment}
                                isShowSearchIcon={true}
                                actionAfterRemoveItemSelected={(item) => handleChangeAttendee(item, 'remove')}
                                actionAfterSelectItem={(item) => handleChangeAttendee(item, 'add')}
                                actionAfterRemoveAllItem={() => {
                                    data?.learners?.forEach(learner => handleChangeAttendee(learner, 'remove'));
                                }}
                            />
                        </div>
                    </div>

                    <div className="field">
                        <label className="sessionEdit-form-label">
                            {translator('COURSES.INSTRUCTORS')}
                            <span className="text-red-500"> *</span>
                        </label>
                        <div className="sessionEdit-form-input">
                            <SelectBox
                                control={control}
                                label={''}
                                name="instructors"
                                placeholder={translator('SESSION.SELECT_LEARNING_INSTRUCTORS')}
                                isRequired
                                placeholderSearchInput="Search a name"
                                pagination={paginationInstructor}
                                onSearch={(value) => setInputSearchInstructor(value)}
                                getData={getInstructors}
                                data={instructorsList}
                                numberOfItemDisplayed={3}
                                isMulti={true}
                                errors={errors}
                                value={watch('instructors') || []}
                                loading={loadingInstructor}
                                isShowSearchIcon={true}
                                isDisabled={dayjs.utc().tz(result.time_zone).isAfter(dayjs.utc(data?.end_time))}
                                actionAfterRemoveItemSelected={(item) => handleChangeAttendee(item, 'remove')}
                                actionAfterSelectItem={(item) => handleChangeAttendee(item, 'add')}
                                actionAfterRemoveAllItem={() => {
                                    data?.instructors?.forEach(instructor => handleChangeAttendee(instructor, 'remove'));
                                }}
                            />
                        </div>
                    </div>

                    {
                        attendeeRemoved?.length > 0 && (
                            <div className="px-4 py-3 rounded-lg bg-gray-50 mt-3">
                                <div className='text-xs font-semibold'>{translator("SESSION.REMOVE_ATTENDEE_FROM")}<span className='text-red-500'>*</span></div>
                               <div className='flex items-center mt-2'>
                                    <InputRadio
                                        control={control}
                                        inputName="remove_attendees_type"
                                        value="this_session"
                                        checked={watch('remove_attendees_type') === 'this_session'}
                                    />
                                    <div className="text-xs text-dark-800 ml-2">{translator("SESSION.THIS_SESSION")}</div>
                                </div>
                                <div className='flex items-center  mt-2'>
                                    <InputRadio
                                        control={control}
                                        inputName="remove_attendees_type"
                                        value="upcoming_sessions_from_this"
                                        checked={watch('remove_attendees_type') === 'upcoming_sessions_from_this'}
                                    />
                                    <div className="text-xs text-dark-800 ml-2">{translator("SESSION.THIS_AND_UPCOMING_SESSION")} {dayjs(watch("date")).format("DD/MM/YYYY")} {dayjs(watch("start_time")).format("HH:mm")}</div>
                                </div>
                                <div className='flex items-center  mt-2'>
                                    <InputRadio
                                        control={control}
                                        inputName="remove_attendees_type"
                                        value="upcoming_sessions_from_today"
                                        checked={watch('remove_attendees_type') === 'upcoming_sessions_from_today'}
                                    />
                                    <div className="text-xs text-dark-800 ml-2">{translator("SESSION.ALL_UPCOMING_SESSION_FROM")} {dayjs().format("DD/MM/YYYY")}</div>
                                </div>
                                {!watch('remove_attendees_type') && <div className='mt-2 text-red-500 text-xs'>{translator("SESSION.PLEASE_CHOOSE_AN_OPTION")}</div>}
                            </div>
                        )
                    }
                    <div className="field">
                        <label className="sessionEdit-form-label">{translator('COURSES.VC_LINK')}</label>
                        <div className="sessionEdit-form-input">
                            <InputField
                                label={''}
                                placeholder={translator('COURSES.ENTER_VC_LINK')}
                                name="vc_link"
                                errors={errors}
                                register={register('vc_link', {
                                    pattern: {
                                        value: FORM_CONST.URL_VALIDATE,
                                        message: translator('COURSES.VALIDATE_LINK'),
                                    },
                                })}
                                disabled={hasDisabled}
                            />
                        </div>
                    </div>
                    <div className="field">
                        <label className="sessionEdit-form-label">{translator('Extra Link')}</label>
                        <div className="sessionEdit-form-input">
                            <InputField
                                label={''}
                                placeholder={translator('Enter Extra Link')}
                                name="extra_link"
                                errors={errors}
                                register={register('extra_link', {
                                    pattern: {
                                        value: FORM_CONST.URL_VALIDATE,
                                        message: translator('COURSES.VALIDATE_LINK'),
                                    },
                                })}
                            />
                        </div>
                    </div>

                    {/* <div className="field">
                    <label className="sessionEdit-form-label">
                        {translator('COURSES.RECORDING_LINK')}
                    </label>
                    <div className="sessionEdit-form-input">
                        <InputField
                            label={''}
                            placeholder={translator('COURSES.ENTER_RECORDING_LINK')}
                            name="record_link"
                            errors={errors}
                            register={register('record_link', {
                                pattern: {
                                    value: FORM_CONST.URL_VALIDATE,
                                    message: translator('COURSES.VALIDATE_LINK'),
                                },
                            })}
                        />
                    </div>
                </div> */}

                    <div className="field">
                        <label className="sessionEdit-form-label">{translator('COURSES.LOCATION')}</label>
                        <div className="sessionEdit-form-input">
                            <InputField
                                label={''}
                                placeholder={translator('COURSES.ENTER_LOCATION')}
                                name="location"
                                errors={errors}
                                register={register('location', {
                                    maxLength: {
                                        value: 255,
                                        message: translator('FORM_CONST.VALIDATE_255_CHARACTERS'),
                                    },
                                })}
                            />
                        </div>
                    </div>
                    {checkPermission('libraryContent', 'R') && <div className="field">
                        <label className="sessionEdit-form-label">{translator('COURSES.LESSON')}</label>
                        <div className="sessionEdit-form-input">
                            <SelectBox
                                control={control}
                                label={''}
                                name="lesson"
                                placeholder={translator('COURSES.SELECT_A_LESSON')}
                                placeholderSearchInput="Search a name"
                                pagination={paginationLesson}
                                onSearch={(value) => setInputSearchLesson(value)}
                                formatOptionLabel={(item) => <div className="w-full text-xs text-ellipsis truncate">{item?.name}</div>}
                                formatOptionValue={(item) => <div className="text-xs max-w-[400px] text-ellipsis truncate">{item?.name}</div>}
                                getData={getLessons}
                                data={lessons}
                                numberOfItemDisplayed={1}
                                isMulti={false}
                                errors={errors}
                                value={watch('lesson') || []}
                                loading={loadingLesson}
                            />
                        </div>
                    </div>}


                    <div className="field">
                        <label className="sessionEdit-form-label">{translator('COURSES.INSTRUCTOR_NOTE')}</label>
                        <div className="sessionEdit-form-input">
                            <TextareaField
                                label={''}
                                maxLength={255}
                                placeholder={translator('COURSES.ENTER_INSTRUCTOR_NOTE')}
                                name="instructor_note"
                                errors={errors}
                                textLength={watch('instructor_note')?.value?.length || data?.instructor_note?.length}
                                isCountter={true}
                                register={register('instructor_note', {
                                    maxLength: {
                                        value: 255,
                                        message: translator('FORM_CONST.VALIDATE_255_CHARACTERS'),
                                    },
                                })}
                            />
                        </div>
                    </div>
                </div>
                <div className="sessionEdit-footer">
                    <div className="">
                        {/* {data?.schedule_type === 'scheduled' && (
                            <button type="button" className="sessionEdit-footer-delete" onClick={onDelete} disabled={hasDisabled}>
                                <TrashIcon className="w-4 h-4 text-red-500" /> Delete Session
                            </button>
                        )} */}
                    </div>
                    <div className="flex gap-2 ml-auto">
                        <ButtonOutline
                            type="Button"
                            onClick={() => {
                                onClose();
                                initialValues(data);
                            }}
                        >
                            {translator('CANCEL')}
                        </ButtonOutline>
                        <ButtonPrimary type="submit" disabled={!isValid || !isDirty || loading || (attendeeRemoved?.length && !watch("remove_attendees_type"))}>
                            {loading && <CircleSpin />}
                            {translator('Save')}
                        </ButtonPrimary>
                    </div>
                </div>
            </div>
        </form>
    );
};
