import { Dialog, Transition } from '@headlessui/react';
import React, { Dispatch, Fragment, SetStateAction, useContext, useMemo } from 'react';
import { checkPermission, classNames } from 'utils/commonFun';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { EyeIcon, XIcon } from '@heroicons/react/outline';
import ActionMenu from 'components/Library/TabLibrary/ActionMenu';
import { LIBRARY_ICONS, LibraryTypeEnum, onDownload } from 'components/Library/LibraryContants';
import { DownloadIcon, EditIcon, FolderOpen, OpenBox, PresentationIcon } from 'assets/icon';
import { useHistory, useParams } from 'react-router-dom';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';

interface DialogProps {
    isOpen: boolean;
    maskClosable?: boolean; // Whether to close the modal dialog when the mask (area outside the modal) is clicked
    isShowClose?: boolean;
    onCloseModal?: (e?: any) => void;
    child?: React.ReactNode;
    width?: string;
    styles?: string;
    loading: boolean;
    data: any;
    previewLibrary: (value: any) => void;
    setOpenModalRename: Dispatch<SetStateAction<boolean>>;
    setSelectedFile: Dispatch<(prevState: undefined) => undefined>;
    setOpenModalDelete: Dispatch<SetStateAction<boolean>>;
    setOpenModalDuplicate: Dispatch<SetStateAction<boolean>>;
    setModalShareLink: Dispatch<SetStateAction<boolean>>;
    setLoadingDownload: Dispatch<SetStateAction<boolean>>;
    onMoveFile: () => void;
    translator: (str: string, newKey?: boolean, opt?: any) => string;
    setModalGetLinkVideo: Dispatch<SetStateAction<boolean>>;
}

const Popup: React.FC<DialogProps> = ({
    isOpen,
    maskClosable = false,
    isShowClose = true,
    onCloseModal,
    width,
    child,
    styles,
    loading,
    data,
    previewLibrary,
    setOpenModalRename,
    setSelectedFile,
    setOpenModalDelete,
    setOpenModalDuplicate,
    setModalShareLink,
    setLoadingDownload,
    onMoveFile,
    translator,
    setModalGetLinkVideo
}) => {
    const closeButtonRef = React.useRef(null);
    const params: any = useParams();
    const history = useHistory();

    const { getWorkspaceDetailState: { featureFlags } } = useContext(GetWorkspaceContext);

    const type = useMemo(() => {
        return data?.object_type;
    }, [data]);
    const subType = useMemo(() => {
        let result = type;
        if (type === 'image') {
            result = data?.sub_object_type?.split('.')[1];
        }
        return result;
    }, [data]);

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed w-[40vw] right-0 bottom-0 z-70 top-[53px]"
                onClose={maskClosable ? onCloseModal : function () { }}
                initialFocus={closeButtonRef}
            >
                <div className="min-h-[80vh] text-center ">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className={classNames(
                                'inline-block py-4 px-5 min-h-screen text-left align-middle transition-all transform bg-white shadow-xl',
                                'w-full',
                                width,
                                styles ? styles : 'overflow-hidden'
                            )}
                        >
                            {loading ? (
                                <div className="flex items-center justify-center mt-4">
                                    <CircleSpin color="text-primary-500" />
                                </div>
                            ) : (
                                <>
                                    <Dialog.Title as="div" className={`pb-[13px] flex justify-between border-b px-5 `}>
                                        <div className="flex items-center mr-2">
                                            <div className="flex-none w-5 mr-2">
                                                {data?.sub_object_type === 'scorm' ? <>
                                                    <span className='text-primary-500'><OpenBox /></span>
                                                </> : <img
                                                    alt="libary-icon"
                                                    className="h-auto w-5 "
                                                    src={
                                                        data?.object_type
                                                            ? LIBRARY_ICONS(translator)?.find((item) => item?.value === subType)?.icon
                                                            : LIBRARY_ICONS(translator)[0].icon
                                                    }
                                                />}
                                            </div>
                                            <h1 className={`text-base font-normal text-gray-900 leading-6 mr-2 truncate w-[20vw]`}>
                                                {data?.name}
                                            </h1>
                                        </div>
                                        <div className="flex items-center gap-2 text-gray-900 justify-end">
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    if (LibraryTypeEnum.h5p == data?.object_type) {
                                                        return history.push(`/workspace/${params?.id}/h5p-content/${data?.object_id}`);
                                                    }
                                                    if (LibraryTypeEnum.lesson == data?.object_type) {
                                                        return history.push(`/workspace/${params?.id}/lesson/${data?.object_id}`);
                                                    }
                                                    if (LibraryTypeEnum.assignment == data?.object_type) {
                                                        return history.push(`/workspace/${params?.id}/assignment/${data?.object_id}`);
                                                    }
                                                }}

                                                className={`mr-2 ${(data?.object_type === LibraryTypeEnum?.h5p ||
                                                    data?.object_type === LibraryTypeEnum?.lesson ||
                                                    data?.object_type === LibraryTypeEnum?.assignment) &&
                                                    data?.sub_object_type !== 'scorm'
                                                    ? ''
                                                    : 'hidden'
                                                    }`}
                                                disabled={(!checkPermission('libraryContentLessonsOrScorm', 'U') || !checkPermission('libraryContentAssignment', 'U') || !checkPermission('libraryContentFile', 'U'))}
                                            >
                                                <EditIcon fill="#1F2937" height="18" width="18" />
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    if (data?.object_type == LibraryTypeEnum.h5p)
                                                        history.push(
                                                            `/workspace/${params?.id}/h5p-content/${data?.object_id}/preview`
                                                        );
                                                    if (data?.object_type == LibraryTypeEnum.lesson)
                                                        history.push(`/workspace/${params?.id}/lesson/${data?.object_id}/preview`);
                                                    if (data?.object_type == LibraryTypeEnum.assignment)
                                                        history.push(`/workspace/${params?.id}/assignment/${data?.object_id}/preview`);
                                                }}
                                                className={`mr-1 ${data?.object_type === LibraryTypeEnum.h5p ||
                                                    data?.object_type === LibraryTypeEnum.lesson ||
                                                    data?.object_type === LibraryTypeEnum.assignment
                                                    ? ''
                                                    : 'hidden'
                                                    }`}
                                                disabled={!checkPermission('libraryContent', 'R')}
                                            >
                                                <PresentationIcon />
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    previewLibrary(data);
                                                }}
                                                className={`mr-1 ${data?.object_type === LibraryTypeEnum?.folder ||
                                                    data?.object_type === LibraryTypeEnum?.h5p ||
                                                    data?.object_type === LibraryTypeEnum?.lesson ||
                                                    data?.object_type === LibraryTypeEnum.assignment
                                                    ? 'hidden'
                                                    : ''
                                                    }`}
                                                disabled={!checkPermission('libraryContent', 'R')}
                                            >
                                                <EyeIcon className="w-5 h-5 text-gray-900" />
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    onCloseModal();
                                                    history.push(`/workspace/${params?.id}/library/${data?.id}`);
                                                }}
                                                className={`mr-1 text-gray-800 ${data?.object_type !== LibraryTypeEnum?.folder ? 'hidden' : ''
                                                    }`}
                                                disabled={!checkPermission('libraryContent', 'R')}
                                            >
                                                <FolderOpen width={18} height={18} />
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => onDownload(data, params, setLoadingDownload)}
                                                className={`mr-1 ${data?.object_type == LibraryTypeEnum.folder || !featureFlags?.systemDownload ? 'hidden' : ''}`}
                                                disabled={(!checkPermission('libraryContentLessonsOrScorm', 'DL') || !checkPermission('libraryContentAssignment', 'DL') || !checkPermission('libraryContentFile', 'DL'))}
                                            >
                                                <DownloadIcon width="20" height="20" />
                                            </button>
                                            <ActionMenu
                                                item={data}
                                                previewLibrary={previewLibrary}
                                                setOpenModalRename={setOpenModalRename}
                                                setSelectedFile={setSelectedFile}
                                                setOpenModalDelete={setOpenModalDelete}
                                                isDetail={true}
                                                setOpenModalDuplicate={setOpenModalDuplicate}
                                                setModalShareLink={setModalShareLink}
                                                setLoadingDownload={setLoadingDownload}
                                                onMoveFile={onMoveFile}
                                                isPopupDetails={isOpen}
                                                setModalGetLinkVideo={setModalGetLinkVideo}
                                            />

                                            <button
                                                hidden={!isShowClose}
                                                type="button"
                                                onClick={onCloseModal}
                                                ref={closeButtonRef}
                                                className="focus:outline-none "
                                            >
                                                <XIcon className="w-5 h-5 text-gray-800" />
                                            </button>
                                        </div>
                                    </Dialog.Title>
                                    <Fragment>
                                        <div className="h-[calc(100vh-124px)] overflow-y-auto">{child}</div>
                                    </Fragment>
                                </>
                            )}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default Popup;
