import React from 'react';
import { useTranslation } from 'react-i18next';
import SelfBookingSelectCard from '../../SelfBookingSelectCard';
import { RefreshIcon } from '@heroicons/react/outline';
import { ENROLLMENT_TYPE } from '../../BookingForm/utils';

type SelfBookingConfirmCardProps = {
    selectedData: any[];
    onCancel: () => void;
    onBookingSession: () => void;
    student: any;
    loadingConfirm: boolean;
    learningPath?: any,
    enrollmentData: any;
    totalBookingCredit?: number
    isReschedule?: boolean
};

const SelfBookingConfirm: React.FC<SelfBookingConfirmCardProps> = ({
    selectedData,
    onCancel,
    onBookingSession,
    student,
    loadingConfirm,
    learningPath,
    enrollmentData,
    totalBookingCredit,
    isReschedule
}: SelfBookingConfirmCardProps) => {
    const { t } = useTranslation();
    const translator = (str: string) => t(`SELF_BOOKING.${str}`);
    return (
        <div className='p-3'>
            <div className="flex items-center">
                <img src={student?.avatar_url} className="w-8 h-8 rounded-full" />
                <span className="font-semibold text-xl ml-2">
                    {enrollmentData?.learning_group?.name || student?.label}
                </span>
                {
                    learningPath?.enrollment_type === ENROLLMENT_TYPE.CREDITS && 
                    <>
                        <span className="px-3">•</span>
                        <span className="text-xl font-semibold text-orange-500">
                            <span className='text-dark-100'>{translator("AVAILABLE")}:</span> 
                            <span className='ml-2'>{enrollmentData?.available_credit || 0} Credits </span>
                        </span>
                    </>
                }
            </div>
            <div className="text-base text-dark-100 font-medium pb-3 mt-4 border-b border-b-dark-50">{learningPath?.label || ''}</div>
            <div className='py-2'>
                {selectedData?.map((item) => (
                    <div className="my-4" key={item.id}>
                        <SelfBookingSelectCard data={item} onlyView={true} isConfirm={true} />
                    </div>
                ))}
            </div>
            {
                learningPath?.enrollment_type === ENROLLMENT_TYPE.CREDITS && 
                <div className='flex justify-between border-t border-t-dark-50 pt-4'>
                    <div className='font-semibold'>{ isReschedule ? translator("TOTAL_RESCHEDULE_BOOKING_CREDIT") : translator("TOTAL_BOOKING_CREDIT")} </div>
                    <div className='text-orange-500'>{isReschedule ?'0 Credit ': `${totalBookingCredit} Credits`} </div>
                </div>
            }
            <div className="grid grid-cols-2 gap-6 mt-8">
                <button className="text-primary-500 border border-primary-500 rounded-[20px] py-3" type="button" onClick={onCancel}>
                    {translator('CANCEL')}
                </button>
                <button
                    className="text-white border border-primary-500 bg-primary-500 rounded-[20px] py-3 flex items-center justify-center"
                    type="button"
                    onClick={onBookingSession}
                    disabled={loadingConfirm}
                >
                    <RefreshIcon className={`w-4 h-4 mr-1 ${loadingConfirm ? ' animate-spin' : ''}`} />
                    {translator('CONFIRM')}
                </button>
            </div>
        </div>
    );
};

export default SelfBookingConfirm;
