import React, { useEffect, useState } from "react";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { ButtonOutline, ButtonPrimary, Toastify } from "_shared";
import "./AssignmentGrade.scss";
import { LearnerInfo } from "../components/LearnerInfo/LearnerInfo";
import { Tasks } from "../components/Tasks/Tasks";
import courseService from "services/course.service";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { SideLeft } from "../components/SideLeft/SideLeft";
import DialogComponent from "components/Dialog/DialogComponent";
import { TextareaField } from "components/InputForm/InputForm";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

export const TASKS_STATUS = {
    completed: 'completed',
    waiting: 'Waiting for Grade',
    assigned: 'Assigned',
    graded: 'Graded'
}
export const AssignmentGrade = () => {
    const { t: translator } = useTranslation();
    const { register, watch, getValues, setValue } = useForm();
    const params: { id: string; courseId: string; assignmentId: string } = useParams();
    const history = useHistory();
    const { location }: any = history;
    const locationPage = useLocation();
    const queryParams = new URLSearchParams(locationPage.search);
    const courseId = queryParams.get('courseId');

    const { state }: { state: any } = location;

    const [assignments, setAssignments] = useState<{ assigned: any[], waiting: any[], completed: any[] }>({
        assigned: [],
        waiting: [],
        completed: []
    });
    const [learnerSelect, setLearnerSelect] = useState<any>();
    const [indexDefault, setIndexDefault] = useState(null);
    const [assigmentTasks, setAssigmentTasks] = useState(null);
    const [loading, setLoading] = useState(false);
    const [feedbackSelect, setFeedbackSelect] = useState<any>();
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [tabStatus, setTabsStatus] = useState('waiting');

    const handleAddFeedback = async () => {
        if (!getValues('comment') || loading) {
            return;
        }
        setLoadingSubmit(true);
        const response = await courseService.addFeedbackScore(params?.id, params?.courseId || state?.courseId || courseId, {
            id: learnerSelect?.id,
            feedback: getValues('comment'),
            taskId: feedbackSelect?.attempt?.id
        });
        if (response?.data?.error) {
            Toastify.error();
        } else {
            Toastify.success('Changed successfully!');
            getTasks();
            setFeedbackSelect(null);
        }
        setLoadingSubmit(false);
    }

    const getTasks = async () => {
        const response = await courseService.getAssignmentsTask(params?.id, params?.courseId || state?.courseId || courseId, learnerSelect?.id);
        if (response?.data) {
            setAssigmentTasks(response?.data);
        }
        setLoading(false);
    }

    const getGrade = async () => {
        setLoading(true);
        let response = await courseService.getAssignmentsGrade(params.id, params.courseId || state?.courseId || courseId, { id: params?.assignmentId });
        if (response?.data) {
            const assigned = response?.data?.find(item => item?.state == 'assigned');
            const inProgress = response?.data?.filter(item => item?.state == 'waiting_for_grade' || item?.state == 'in_progress' || item?.state == 'overdue');
            const completed = response?.data?.find(item => item?.state == 'completed');
            const inProgressAssignment = inProgress?.map(item => item?.assignments)?.flat();

            setAssignments({
                assigned: assigned?.assignments ?? [],
                waiting: inProgressAssignment ?? [],
                completed: completed?.assignments ?? []
            });
            const taskWaiting = inProgressAssignment?.find(item => item.id == params.assignmentId);
            if (inProgressAssignment?.length && taskWaiting) {
                setIndexDefault(1);
                setTabsStatus('waiting');
                return setLearnerSelect(taskWaiting);
            }
            const taskCompleted = completed?.assignments?.find(item => item.id == params.assignmentId);
            if (completed?.assignments?.length && taskCompleted) {
                setIndexDefault(0);
                setTabsStatus('completed');
                return setLearnerSelect(taskCompleted);
            }
            const taskAssigned = assigned?.assignments?.find(item => item.id == params.assignmentId);
            if (assigned?.assignments?.length && taskAssigned) {
                setIndexDefault(2);
                setTabsStatus('assigned');
                return setLearnerSelect(taskAssigned);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        setValue('comment', feedbackSelect?.attempt?.instructor_feedback);
    }, [feedbackSelect]);

    useEffect(() => {
        if (params?.assignmentId) {
            getGrade();
        }
    }, [params?.assignmentId]);

    useEffect(() => {
        if (learnerSelect?.id) {
            getTasks();
        }
    }, [learnerSelect]);

    const handleRefresh = () => {
        getGrade();
    }

    return (<>
        <div className="assignmentGradeHeader assignmentGrade-header">
            <button className="assignmentGradeHeader-back !hidden" onClick={() => history.goBack()}>
                <span><ChevronLeftIcon className="w-5 h-5 " /></span> {translator('BACK')}
            </button>
            {learnerSelect?.learner?.display_name ? <h1 className="assignmentGradeHeader-name w-full flex justify-center items-center">{learnerSelect?.learner?.display_name} / {learnerSelect?.name}</h1> : null}
            {false ? <ButtonPrimary type="button" size="M" onClick={() => { }} disabled={true} className="hidden">{translator('SAVE')}</ButtonPrimary> : <div></div>}
        </div>
        <div className="assignmentGrade-container">
            {loading ? <div className="flex items-center justify-center mt-8 w-full">
                <CircleSpin color="text-primary-500" />
            </div> :
                (assignments?.assigned?.length > 0 ||
                    assignments?.waiting?.length > 0 ||
                    assignments?.completed?.length > 0)
                    ? <>
                        <div className="assignmentGrade-sideLeft relative">
                            <SideLeft
                                assignments={assignments}
                                learnerSelect={learnerSelect}
                                indexDefault={indexDefault}
                                loading={loading}
                                setLearnerSelect={setLearnerSelect}
                                setTabsStatus={setTabsStatus}
                            />
                        </div>
                        <div className="assignmentGrade-content">
                            <div className="assignmentGrade-learner">
                                <LearnerInfo learner={learnerSelect} tasks={assigmentTasks} />
                            </div>
                            <div className="assignmentGrade-tasks">
                                <Tasks tasks={assigmentTasks?.task_results} learnerSelect={learnerSelect} tabStatus={tabStatus}
                                    setSelectTask={setFeedbackSelect}
                                    refreshData={handleRefresh}
                                    courseId={state?.courseId || courseId}
                                />
                            </div>
                        </div>
                    </> : <div className="flex w-full justify-center mt-8 text-gray-500">
                    {translator('NO_TASK')}
                    </div>

            }
        </div>
        {!!feedbackSelect ? <DialogComponent
            title={`Feedback / ${feedbackSelect?.task?.name} / ${feedbackSelect?.attempt?.id}`}
            isOpen={!!feedbackSelect}
            onCloseModal={() => setFeedbackSelect(null)}
            isShowClose={true}
            styles="max-w-[600px] !py-1.5 !px-6"
            child={<div className='mt-2'>
                <TextareaField
                    label={''}
                    name="comment"
                    className="col-span-4"
                    placeholder={`Write your Feedback here`}
                    register={register('comment')}
                    textLength={getValues("comment")?.toString()?.length}
                    maxLength={1000}
                    isCountter
                    rows={10}
                    cols={200}
                />
                <div className='flex gap-2 justify-center my-4'>
                    <ButtonOutline type="button" onClick={() => setFeedbackSelect(null)} size="M">Cancel</ButtonOutline>
                    <ButtonPrimary type="button" onClick={handleAddFeedback} disabled={!watch('comment') || loadingSubmit} size="M">
                        {loadingSubmit ? <CircleSpin color='text-primary-500' /> : null}
                        Save
                    </ButtonPrimary>
                </div>
            </div>}
        /> : null}
    </>)
}