import React from 'react';
// COMPONENTS
import H5PPublicViewerContentComponent from './H5PPublicViewerContentComponent';

export interface H5PPublicViewerProps {
    contentUid: string;
    isPublic?: boolean;
    args?: any;
}

const H5PPublicViewer: React.FC<H5PPublicViewerProps> = ({ contentUid, isPublic = false, args }) => {
    const [entryState, setEntryState] = React.useState({
        loading: false,
    });
    return (
        <div className="mx-auto w-full">
            <H5PPublicViewerContentComponent
                contentUid={contentUid}
                entryState={entryState}
                setEntryState={setEntryState}
                isPublic={isPublic}
                args={args}
            />
        </div>
    );
};

export default H5PPublicViewer;
