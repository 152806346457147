import { DocumentPreview } from '_shared/components/DocumentPreview/DocumentPreview';
import { DeleteIcon, DownloadIcon } from 'assets/icon';
import { EyesIcon } from 'assets/icon/EyesIcon';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dowloadFile } from 'utils/commonFun';
import Progress from './Progress';
import pdfIcon from 'assets/img/pdfIcon.png';
import pngIcon from 'assets/img/pngIcon.png';
import jpegIcon from 'assets/img/jpegIcon.png';
import docIcon from 'assets/img/docIcon.png';
import ReloadIcon from 'assets/icon/ReloadIcon';
import './File.scss';
import { REPORT_STATUS } from 'components/Workspace/Course/CourseBuilder/components/CourseReport/constant';

interface IFile {
    file: {
        type: string;
        url?: string;
        name: string;
        lastModifiedDate: string;
        size: number;
        error?: String
    };
    progressInfo?: {
        progress: number;
        error: string;
    };
    onDelete?: () => void;
    onReload?: () => void;
    status: string
}

export default function File({ file, progressInfo, onDelete, onReload, status }: IFile) {
    const [isOpenModalPreviewFile, setOpenModalPreviewFile] = useState(false);
    const  filesize = file?.size / (1024*1024);
    const { t: translator } = useTranslation();

    const download = () => {
        dowloadFile(file?.url, file?.name);
    };

    return (
        <div className="File">
            <div className="File-Info">
                {(file?.type?.includes('msword') || file?.type?.includes('document')) && (
                    <img className="w-8" src={docIcon} />
                )}
                {file?.type?.includes('pdf') && <img className="w-8" src={pdfIcon} />}
                {file?.type?.includes('png') && <img className="w-8" src={pngIcon} />}
                {(file?.type?.includes('jpeg') || file?.type?.includes('jpg')) && <img className="w-8" src={jpegIcon} />}
                <div>
                    <div className="File-Info--Name">{file?.name}</div>
                    <div className="File-Info--Detail">
                        <div>{filesize < 1 ? `${(filesize * 1024)?.toFixed(0)}KB` : `${filesize?.toFixed(1)}MB`}</div>・
                        {!file?.error && progressInfo?.progress === 100 && !progressInfo?.error && (
                            <div>
                                {translator('UPLOADED_ON')} {dayjs(file?.lastModifiedDate)?.format('DD/MM/YYYY')}
                            </div>
                        )}
                        {file?.error && <div className="text-xs text-red-500">{file?.error}</div>}
                        {(progressInfo.progress > 0 || progressInfo.error) && <Progress error={progressInfo?.error} percentage={progressInfo?.progress} />}
                    </div>
                </div>
            </div>
            <div className="File-Action">
                <div className="File-Action--Icon" onClick={() => setOpenModalPreviewFile(true)}>
                    <EyesIcon width="20" height="20" fill="#6b7280" />
                </div>

                {onReload && progressInfo?.error && (
                    <div className="File-Action--Icon" onClick={onReload}>
                        <ReloadIcon className="w-5 h-5 text-gray-500" />
                    </div>
                )}

                {progressInfo?.progress === 100 &&  (
                    <div className="File-Action--Icon" onClick={download}>
                        <DownloadIcon width="20px" height="20px" fill="text-gray-500" />
                    </div>
                )}

                {onDelete && status !== REPORT_STATUS.released && (
                    <div className="File-Action--Icon" onClick={onDelete}>
                        <DeleteIcon width="20px" height="20px" />
                    </div>
                )}
            </div>
            <DocumentPreview data={file} isDownload={progressInfo?.progress === 100} isOpen={isOpenModalPreviewFile} closeModal={() => setOpenModalPreviewFile(false)} />
        </div>
    );
}
