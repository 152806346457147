import { useState } from 'react';
import { PDF_VIEWER_PATH } from 'constant/util.const';

export const IframePdfViewer = ({ url = '', className = '' }: { url: string; className?: string; }) => {
    const [loading, setLoading] = useState(true);

    if (!url) return <></>;
    return (
        <div id='pdf-viewer-container' className={`w-full h-[80vh] ${className}`}>
            <iframe
                src={PDF_VIEWER_PATH + url}
                width="100%"
                height="100%"
                title="PDF Viewer"
                className="border-none"
                srcDoc={loading ? 'loading...' : null}
                loading="lazy"
                onLoad={() => setLoading(false)}
            />
        </div>
    );
};
