import { HeartIcon } from '@heroicons/react/solid';
import React from 'react';
import { useTranslation } from 'react-i18next';

const RenderDetail = ({ value }: { value: any }) => (
    <div className={`bg-dark-100  rounded py-[2px] px-2  mr-2  text-white`}>
        <span className={'text-xs text-white font-semibold '}>{value}</span>
    </div>
);

type InstructorDetailProps = {
    instructorDetails: any;
};

const InstructorDetail: React.FC<InstructorDetailProps> = ({ instructorDetails }: InstructorDetailProps) => {
    const { t: translator } = useTranslation();
    return (
        <>
            <div>
                <div className="py-4 flex items-center border-t border-b border-[#E4E6E8]">
                    <div className="w-20 h-20  relative">
                        <img
                            className={`rounded-full w-20 h-20 ${
                                instructorDetails?.preferred ? 'border-2 border-red-500 rounded-full' : ''
                            } `}
                            src={instructorDetails?.user?.avatar_url}
                            alt="avatar"
                        />
                        {instructorDetails?.preferred && <HeartIcon className="w-9 h-9 text-red-500 absolute -bottom-2 right-1" />}
                    </div>
                    <p className="text-xl text-primary-80 font-semibold ml-2">{instructorDetails?.user?.display_name}</p>
                </div>
                {instructorDetails?.biography && 
                  <div className="pt-4 text-dark-300">
                    <div className="text-base font-semibold ">{translator('SELF_BOOKING.BIOGRAPHY')}</div>
                    <div className="text-sm mt-2">{instructorDetails?.biography}</div>
                  </div>
                }
                {instructorDetails?.certifications?.length && (
                    <div className="pt-4 text-dark-300">
                        <div className="text-base font-semibold ">{translator('SELF_BOOKING.CERTIFICATIONS')}</div>
                        <div className="text-sm mt-2">{instructorDetails?.certifications?.map((i) => i?.name).join(', ') || ''}</div>
                    </div>
                )}
                {instructorDetails?.languages?.length && (
                    <div className="pt-4 text-dark-300">
                        <div className="text-base font-semibold ">{translator('SELF_BOOKING.LANGUAGE')}</div>
                        <div className="text-sm mt-2 flex items-center flex-wrap flex-row">
                            {instructorDetails?.languages?.map((language, index) => (
                                <div key={index} className="my-1">
                                    <RenderDetail value={language?.name} />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {instructorDetails?.user?.address && (
                    <div className="pt-4 text-dark-300">
                        <div className="text-base font-semibold ">{translator('SELF_BOOKING.ADDRESS')}</div>
                        <div className="text-sm mt-2">{instructorDetails?.user?.address}</div>
                    </div>
                )}
            </div>
        </>
    );
};

export default InstructorDetail;
