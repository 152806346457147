import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { CourseType } from 'types/Courses.type';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'contexts/User/UserContext';
import { useLocation, useParams } from 'react-router-dom';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import dayjs from 'dayjs';
import NO_CONTENT from 'assets/img/empty-state/instructor.png';
import ActionMenu from './ActionMenu';
import DialogComponent from 'components/Dialog/DialogComponent';
import { TextareaField } from 'components/InputForm/InputForm';
import { useForm } from 'react-hook-form';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import courseService from 'services/course.service';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { checkPermission } from 'utils/commonFun';
const LESSON_GOAL = [
    'N/A',
    'No',
    'Yes'
]
interface IViewTable {
    data: CourseType[];
    refreshData: Function;
    isLearningGroup?: any;
}
const TYPE_COMMENT = (translateor) => ({
    lesson_notes: {
        label: translateor('COURSES.LESSON_NOTE'),
        value: 'lesson_notes'
    },
    instructor_comment: {
        label: translateor('MY_REPORTS.TEACHER_COMMENT'),
        value: 'instructor_comment'
    },
    instructor_notes: {
        label: translateor('COURSES.INSTRUCTOR_NOTES'),
        value: 'instructor_notes'
    }

})

const StudyHistoryTable: FC<IViewTable> = ({ data, refreshData, isLearningGroup }) => {
    const { t } = useTranslation();
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;
    const params: { id: string, courseId } = useParams();
    const [typeComment, setTypeComment] = useState<string>(null);
    const [sessionSelect, setSessionSelect] = useState<any>();
    const { register, getValues, watch, setValue } = useForm();
    const [loading, setLoading] = useState(false);
    const {
        userState: { result },
    } = useContext(UserContext);
    const search = useLocation()?.search;
    const learnerId = new URLSearchParams(search).get('learnerId');

    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={t('COURSES.SESSION_ID')} />,
                accessor: 'id',
                Cell: ({ row }: any) => <RenderTableCell value={row.values?.id} className="text-primary-500 font-semibold" />,
                width: "90"
            },
            {
                Header: () => <RenderTableHeader value={t('COURSES.SESSION_DATE_TIME')} />,
                accessor: 'start_time',
                Cell: ({ row }: any) => <RenderTableCell
                    value={`${dayjs.utc(row.values?.start_time).tz(result?.time_zone).format('DD/MM/YYYY')} ${dayjs.utc(row.values?.start_time).tz(result?.time_zone).format('HH:mm')}-${dayjs.utc(row.original?.end_time).tz(result?.time_zone).format('HH:mm')}`} />,
                width: "150"
            },
            ...(learnerId ? [{
                Header: () => <RenderTableHeader value={t('COURSES.ATTENDANCE')} />,
                accessor: 'attendance_type',
                Cell: ({ row }: any) => <RenderTableCell value={row?.values?.attendance_type} className="first-letter:uppercase" />,
                width: "100"
            },
            {
                Header: () => <RenderTableHeader value={t('COURSES.LESSON_GOAL')} />,
                accessor: 'goal_achieved',
                Cell: ({ row }: any) => <RenderTableCell value={LESSON_GOAL[row.values?.goal_achieved ?? 0]} />,
                width: "100"
            }] : []),
            {
                Header: () => <RenderTableHeader value={t('COURSES.LESSON_NOTE')} />,
                accessor: 'lesson_notes',
                Cell: ({ row }) => {
                    return <RenderTableCell className="text-primary-500 font-semibold underline cursor-pointer" value={row?.values?.lesson_notes ? t('View Note') : t('Add Note')}
                        disable={!checkPermission('coursePeopleEnrollment', 'U')}
                        onClick={() => {
                            setTypeComment('lesson_notes');
                            setSessionSelect(row.original);
                        }}
                    />;
                },
                width: "110"
            },

            {
                Header: () => <RenderTableHeader value={t('COURSES.INSTRUCTOR_NOTES')} />,
                accessor: 'instructor_notes',
                Cell: ({ row }: any) => {
                    return <RenderTableCell className="text-primary-500 font-semibold underline cursor-pointer" value={row?.values?.instructor_notes ? t('View Note') : t('Add Note')}
                        disable={!checkPermission('coursePeopleEnrollment', 'U')}
                        onClick={() => {
                            setTypeComment('instructor_notes');
                            setSessionSelect(row.original);
                        }}
                    />;
                },
                width: "110"
            },
            ...(learnerId ? [{
                Header: () => <RenderTableHeader value={t("COURSES.INSTRUCTOR_COMMENT")} />,
                accessor: 'instructor_comment',
                Cell: ({ row }: any) => {
                    return <RenderTableCell className="text-primary-500 font-semibold underline cursor-pointer" value={row?.values?.instructor_comment ? t('View Comment') : t('Add Comment')}
                        disable={!checkPermission('coursePeopleEnrollment', 'U')}
                        onClick={() => {
                            setTypeComment('instructor_comment');
                            setSessionSelect(row.original);
                        }}
                    />;
                },
                width: "140"
            },
            {
                Header: () => <RenderTableHeader value={t("COURSES.CREDIT_TRANSACTION")} />,
                accessor: 'display_used_credit',
                Cell: ({ row }: any) => {
                    return (
                        <div className="py-3 ml-2">
                            <span className={`${row?.values?.display_used_credit > 0 ? 'text-green-500' : row?.values?.display_used_credit < 0 ? 'text-red-500' : 'text-gray-800'}`}>
                                {row?.values?.display_used_credit}
                            </span>
                        </div>
                    );
                },
                width: "130"
            }] : []),
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="h-full flex items-center justify-center">
                            <ActionMenu data={{ ...row?.original, time_zone: result?.time_zone }} refreshData={refreshData} isLearningGroup={isLearningGroup}/>
                        </div>
                    );
                },
                accessor: 'action',
                width: 60,
            },
        ],
        [data, WorkspaceDetailInformation]
    );

    const handleUpdate = async () => {
        setLoading(true);
        let payload: any = {
            id: sessionSelect?.id
        }
        switch (typeComment) {
            case 'lesson_notes':
                payload = {
                    ...payload,
                    lesson_notes: getValues('comment')
                }
                break;
            case 'instructor_notes':
                payload = {
                    ...payload,
                    instructor_notes: getValues('comment')
                }
                break;
            case 'instructor_comment':
                payload = {
                    ...payload,
                    student_attendances: [
                        {
                            student_id: learnerId,
                            instructor_comment: getValues('comment')
                        }
                    ]
                }
                break;
            default:
        }
        let response;
        if (typeComment === 'instructor_comment') {
            response = await courseService.updateSessionAttendances(params?.id, params?.courseId, payload);
        } else {
            response = await courseService.updateSessionPast(params?.id, params?.courseId, payload);
        }
        if (response?.data?.error) {
            Toastify.error();
        } else {
            Toastify.success();
            setTypeComment(null);
            refreshData();
        }
        setLoading(false);
    }

    useEffect(() => {
        if (typeComment) {
            let value = sessionSelect[typeComment];
            if (typeComment === 'instructor_comment') {
                value = sessionSelect?.instructor_comment;
            }
            setValue('comment', value);
        }
    }, [typeComment])

    return (<>
        <Table
            data={data || []}
            columns={columns}
            emptyContent={t('COURSES.NO_STUDY_HISTORY')}
            emptyIcon={NO_CONTENT}
        />
        {!!typeComment ? <DialogComponent
            title={TYPE_COMMENT(t)[typeComment]?.label}
            isOpen={!!typeComment}
            onCloseModal={() => setTypeComment(null)}
            isShowClose={true}
            styles="max-w-[400px] !py-1.5 !px-6"
            child={<div className='mt-2'>
                <TextareaField
                    label={t('COURSES.COMMENT')}
                    name="comment"
                    className="col-span-4"
                    placeholder={`${TYPE_COMMENT(t)[typeComment]?.label}`}
                    register={register('comment')}
                    textLength={getValues("comment")?.length?.toString()}
                    maxLength={250}
                    isCountter
                />
                <div className='flex gap-2 justify-center my-4'>
                    <ButtonOutline type="button" onClick={() => setTypeComment(null)} size="M">{t("CANCEL")}</ButtonOutline>
                    <ButtonPrimary type="button" onClick={handleUpdate} disabled={!watch('comment') || loading} size="M">
                        {loading ? <CircleSpin color='text-primary-500' /> : null}
                        {t("SAVE")}
                    </ButtonPrimary>
                </div>
            </div>}
        /> : null}
    </>
    );
};

export default StudyHistoryTable;
