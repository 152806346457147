import React from 'react';
import RouteMasterPage from 'pages/RouteMasterPage';
import { Booking } from 'components/Booking/Booking';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';

const BookingPageProps = () => {
    return (
        <ManagePermissionWrapper>
            <Booking>
                <RouteMasterPage />
            </Booking>
        </ManagePermissionWrapper>
    );
};

export default BookingPageProps;
