import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import { LESSON_ACTIONS } from '../../utils';

interface TagFieldsProps {
    title: string;
    data: any;
    dispatchLesson: any;
    id: string,
    customFields: any,
    translator: Function
}

const TagFields: React.FC<TagFieldsProps> = ({
    title,
    data,
    dispatchLesson,
    id,
    customFields,
    translator
}) => {
    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState<boolean>(false)

    const handleAddTagField = (e: any) => {
        if (e.key === 'Enter') {
            const valueKeyDown = e.target.value.trim()
            const temp = [...customFields]
            const currentCustomfield = temp.find(item => item.id === id)
            if (currentCustomfield.data === null) currentCustomfield.data = []
            const isDisableEnter = currentCustomfield.data.includes(valueKeyDown)
            if (isDisableEnter || valueKeyDown === '')  return setError(true)
            else setError(false)
            currentCustomfield.data.push(valueKeyDown)
            setValue('');
            dispatchLesson({
                type: LESSON_ACTIONS.SET_CUSTOM_FIELD_LIST,
                value: temp,
                status: 'touched'
            });
        }
    };

    const handleRemoveTagField = (value: string) => {
        const temp = [...customFields]
        const currentCustomfield = temp.find(item => item.id === id)
        currentCustomfield.data = [...data].filter((el) => el !== value)
        dispatchLesson({
            type: LESSON_ACTIONS.SET_CUSTOM_FIELD_LIST,
            value: temp,
            status: 'touched'
        });
    };

    return (
        <>
            <div className="w-full min-h-18 max-h-30 border-ooolab_bar_color border rounded-lg flex flex-wrap pb-2 pr-2 overflow-y-scroll custom-scrollbar max-h-96 mt-4">
                <div className="text-sm text-ooolab_dark_1 font-semibold border-b border-r border-ooolab_bar_color h-8 px-2 rounded-lg inline-flex items-center py-auto">
                    {title}
                </div>
                {data?.map((i: any, index: number) => (
                    <div
                        key={index}
                        className="inline-flex items-end ml-2 mt-2 relative"
                    >
                        <div className="bg-ooolab_light_blue_0 rounded px-2 py-0">
                            <span className="text-sm mr-5">{i}</span>
                            <XIcon
                                className="absolute top-1 right-1 h-4 w-4 inline-block hover:text-red-500 cursor-pointer"
                                onClick={() => handleRemoveTagField(i)}
                            />
                        </div>
                    </div>
                ))}
                <input
                    type="text"
                    value={value}
                    placeholder="Enter more"
                    className="h-6 inline-flex items-end ml-2 mt-2"
                    onKeyDown={handleAddTagField}
                    onChange={(e) => setValue(e.target.value)}
                />
            </div>
            {
              error &&  <p className=' text-ooolab_error text-xs mt-2'>{translator("TAG_FIELD.DUPLICATE_TAG_NAME")}</p>
            }
        </>
    );
};

export default TagFields;
