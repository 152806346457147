
import { useEffect, useState } from "react";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import Pagination from "components/V2/Pagination";
import { useParams } from "react-router-dom";
import courseService from "services/course.service";
import { getIndexPage } from "utils/commonFun";
import SessionTable from "./components/SessionTable/SessionTable";
import "./SessionList.scss";
import { SessionFilter } from "./components/SessionFilter/SessionFilter";
import workspaceService from "services/workspace.service";

export const SessionList = ({userData}) => {
    const params: { id: string, userId: string } = useParams();
    const [isFirstLoad, setFirstLoad] = useState(true);
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'updated_at',
        total: 0,
    });
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [indexPage, setIndexPage] = useState('0-0');
    const getdata = (page: number | string = 1, filter = undefined) => {
        setLoading(true);
        const payload = {
            ...pagination,
            ...filter,
            page,
            query_for: 'for_a_specific_learner'
        };
        delete payload?.total;        
        workspaceService.getSessionListLearner(params?.id, params?.userId, payload)
            .then((res) => {
                setFirstLoad(false);
                const { items = [], total = 0 } = res?.data;
                setData(items);
                setPagination({ ...pagination, ...payload, total });
                setIndexPage(getIndexPage(payload?.page, pagination?.per_page, items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };
    const handlePagination = (page: number) => {
        getdata(page);
    };
    const handleFilter = (value) => {
        getdata(1, value);
    }
    useEffect(() => {
        getdata();
    }, []);

    return (<>
        <div className="sessionList sessionList-main">
            <div className="sessionList-filter">
                <SessionFilter onChangeFilter={handleFilter} isFirstLoad={isFirstLoad} />
            </div>
            <div className="wrapper-content">
                <div className="scrollbar">
                    {loading ? (
                        <div className="loading">
                            <CircleSpin color="text-primary-500" />
                        </div>
                    ) : <SessionTable data={data} refreshData={getdata} learnerId={params?.userId} userData={userData}/>
                    }
                </div>
                <Pagination
                    total={pagination.total}
                    per_page={pagination.per_page}
                    callback={(e) => handlePagination(e)}
                    indexPage={indexPage}
                    page={pagination?.page}
                />
            </div>
        </div>
    </>)
}