import React, { Children, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { CloseIcon } from "assets/icon/CloseIcon";
import { ButtonPrimary } from "../Buttons/ButtonPrimary/ButtonPrimary";
import './ConfirmModal.scss';
import { ButtonOutline } from "../Buttons/ButtonOutline/ButtonOutline";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { ButtonIcon } from "../Buttons/ButtonIcon/ButtonIcon";

export const ConfirmModal = ({ title = '', isOpen, onClose, children, textConfirm = 'Yes', textCancel = 'Cancel', isHideBtnSecondary = false, onCloseX = undefined, isLoading = false }) => {
    const { t: translator } = useTranslation();
    
    return <>
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={() => { }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/30 bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-[480px] transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="confirmDeleteModal-header"
                                >
                                    {translator(title)}
                                    <div className='confirmDeleteModal-close'>
                                        <ButtonIcon disabled={isLoading} onClick={() => onCloseX ? onCloseX() : onClose()}>
                                            <span className="p-1">
                                                <CloseIcon />
                                            </span>
                                        </ButtonIcon>
                                    </div>
                                </Dialog.Title>
                                <div className="confirmDeleteModal-container">
                                    {children}
                                </div>
                                <div className="confirmDeleteModal-actions">
                                    {!isHideBtnSecondary && <ButtonOutline type="button" disabled={isLoading} onClick={() => onClose()}>{translator(textCancel)}</ButtonOutline>}
                                    {textConfirm ? <ButtonPrimary type="button" disabled={isLoading} onClick={() => {
                                        onClose(true);
                                    }} className="">
                                        {isLoading ? <span className="confirmDeleteModal-loading"><CircleSpin /></span> :
                                            translator(textConfirm)}
                                    </ButtonPrimary> : null}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    </>
}