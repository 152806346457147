import React from 'react';
import { useTable, useRowSelect, useFlexLayout, Row, Column } from 'react-table';
import './style.css';
import { classNames } from 'utils/commonFun';

const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
}: {
    value: any;
    row: Row;
    column: Column;
    updateMyData: Function;
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    const onChange = (e: any) => {
        setValue(e.target.value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return <input value={value} onChange={onChange} onBlur={onBlur} placeholder={''} />;
};
const handleCalculatorIndex = (index) => {
    return 69 - index;
};

const Table = ({
    columns,
    data,
    updateData,
    initialState,
    noBorder,
    isBorderFirstColum = true,
}: {
    columns: Column<any>[];
    data: any;
    updateData?: any;
    initialState?: any;
    noBorder?: boolean;
    isBorderFirstColum?: boolean;
}) => {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        state: { selectedRowIds },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: {
                /*@ts-ignore*/
                Cell: EditableCell,
            },
            updateData,
            initialState,
        },
        useRowSelect,
        useFlexLayout
    );

    return (
        <table className=" w-full h-auto" {...getTableProps()}>
            <thead>
                {headerGroups.map((headerGroup, i) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: any, index) => {
                            return (
                                <th
                                    className={`  ${
                                        index == 0 && isBorderFirstColum && !noBorder
                                            ? 'border-t-0 border-r border-ooolab_bar_color py-2'
                                            : 'border-t-0 border-ooolab_bar_color py-2'
                                    } ${column?.sticky ? 'sticky right-0 bg-white z-10 ' : ''} ${
                                        column?.shadow ? 'border-l border-l-gray-300' : ''
                                    }
                                    ${column?.zIndex ? 'z-1' : ''}`}
                                    {...column.getHeaderProps()}
                                    key={`td_head_${headerGroup?.id}_${index}`}
                                >
                                    {column.render('Header')}
                                </th>
                            );
                        })}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);

                    return (
                        <tr className="group hover:bg-gray-50" {...row.getRowProps()} key={`tr_${row?.id}_${i}`}>
                            {row.cells.map((cell: any, cellIndex) => {
                                return (
                                    <td
                                        className={classNames(
                                            cellIndex == 0 && isBorderFirstColum && !noBorder ? 'border-r' : '',
                                            cell?.column?.sticky ? 'sticky right-0 bg-white group-hover:bg-gray-50' : '',
                                            cell?.column?.shadow ? 'border-l border-l-gray-300' : '',
                                            cell?.column?.zIndex ? '!z-[1]' : ''
                                        )}
                                        {...cell.getCellProps()}
                                        style={{
                                            zIndex: (cell?.column?.sticky && handleCalculatorIndex(i)) || 'auto',
                                            ...cell.getCellProps()?.style,
                                        }}
                                        key={`td_${cell?.value}_${cellIndex}`}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default Table;
