import React from 'react';
import { useHistory } from 'react-router-dom';
import h5p from 'assets/SVG/h5p-section.svg';

import { LessonInterface } from 'types/GetListOfWorkspace.type';
import LessonDetailAction from './LessonDetailAction';
import LessonForm from './LessonForm';
import { handleRemoveLesson } from '../../LessonFN';
import { useTranslation } from 'react-i18next';
import { StatusContent } from 'constant/util.const';

interface LessonDetailProps {
    data: LessonInterface | undefined;
    currentWorkspace: string;
    dispatch: React.Dispatch<any>;
    canRemove: boolean;
    setSelectedRemove: React.Dispatch<any>;
    duplicateLesson: Function;
}

const statusShadow: Record<any, string> = {
    draft: 'shadow-ooolab_lesson_status bg-ooolab_dark_50 ',
    pending: 'shadow-ooolab_lesson_status_pending bg-ooolab_warning',
    public: 'shadow-ooolab_alert_success bg-ooolab_alert_success',
};

const LessonDetail: React.FC<LessonDetailProps> = ({
    data,
    currentWorkspace,
    dispatch,
    canRemove,
    setSelectedRemove,
    duplicateLesson
}) => {
    const { t: translator } = useTranslation();
    const handleDelete = () => {
        if (data?.id) {
            setSelectedRemove(data?.id);
            handleRemoveLesson(dispatch, currentWorkspace, `${data.id}`);
        }
    };

    return (
        <div className="h-full w-full p-4 custom-scrollbar overflow-y-scroll">
            <div className="flex justify-between items-center">
                <p>{data?.title}</p>
                {/* <p className="capitalize flex items-center">
                    <span
                        className={`w-2 h-2 rounded-full mr-2  ${statusShadow[data?.status || 'draft']
                            }`}
                    />
                    <span> {StatusContent(translator, data?.status)}</span>
                </p> */}
            </div>
            <div>
                {data?.id && (
                    <LessonDetailAction
                        workspaceId={currentWorkspace}
                        lessonId={`${data.id}`}
                        handleDelete={handleDelete}
                        canRemove={canRemove}
                        duplicateLesson={duplicateLesson}
                    />
                )}
            </div>
            <div className="w-full">
                <LessonForm tags={[]} formData={data} />
            </div>
            <div className="w-full mt-8">
                <p className="flex items-center mb-4">
                    <span className="mr-2">
                        {translator('LESSON.SECTIONS')}
                    </span>
                    <span className="bg-ooolab_light_blue_0 p-2 rounded-full w-6 h-6 inline-flex justify-center items-center">
                        {(data?.sections && data.sections.learning_material?.length) || 0}
                    </span>
                </p>
            </div>
            {data.sections &&
                data.sections?.learning_material?.map((i) => (
                    <div className="w-full mb-4 p-4 rounded-lg border border-ooolab_border_logout">
                        <p className="flex justify-between items-center mb-ooolab_m_3 font-medium">
                            <span className="text-base text-ooolab_dark_1 ">
                                {i.title}
                            </span>
                            <span className="text-sm  text-ooolab_dark_2">
                                {i.files.length} Files
                            </span>
                        </p>
                        {i.files.map((j) => (
                            <p className="flex items-center mb-2">
                                <img src={h5p} alt="" />
                                <span className="ml-2">
                                    {j.file_name}
                                </span>
                            </p>
                        ))}
                    </div>
                ))}
        </div>
    );
};

export default LessonDetail;
