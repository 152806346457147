
export default function LessonIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_760_18590)">
        <path d="M16.6667 18.3332H5.41667C4.64312 18.3332 3.90125 18.0259 3.35427 17.4789C2.80729 16.9319 2.5 16.1901 2.5 15.4165V4.1665C2.5 3.50346 2.76339 2.86758 3.23223 2.39874C3.70107 1.9299 4.33696 1.6665 5 1.6665H16.6667C16.8877 1.6665 17.0996 1.7543 17.2559 1.91058C17.4122 2.06686 17.5 2.27882 17.5 2.49984V17.4998C17.5 17.7209 17.4122 17.9328 17.2559 18.0891C17.0996 18.2454 16.8877 18.3332 16.6667 18.3332ZM15.8333 16.6665V14.1665H5.41667C5.08515 14.1665 4.7672 14.2982 4.53278 14.5326C4.29836 14.767 4.16667 15.085 4.16667 15.4165C4.16667 15.748 4.29836 16.066 4.53278 16.3004C4.7672 16.5348 5.08515 16.6665 5.41667 16.6665H15.8333ZM8.33333 3.33317V9.99984L11.25 8.33317L14.1667 9.99984V3.33317H8.33333Z" fill="#0071CE" />
      </g>
      <defs>
        <clipPath id="clip0_760_18590">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
