import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import './FilterUser.scss';
import { LOCATION, USER_ROLE, USER_STATUS_LIST } from '../../constant';
import { WorkspaceService } from 'services';
import { useParams } from 'react-router-dom';

interface IFilter {
    onChangeFilter: (value: any) => void;
    location: LOCATION.learner_portal | LOCATION.clms;
    setLocation: any
}

const Filter = ({ onChangeFilter, location, setLocation }: IFilter) => {
    const { t } = useTranslation();
    const params: { id } = useParams();
    const [keyword, setKeyword] = useDebouncedState('', 0);
    const [inputSearchRole, setInputSearchRole] = useDebouncedState('');
    const [roleSelected, setRoleSelected] = useState([]);
    const [statusSelected, setStatusSelected] = useState([]);
    const [loadingRole, setLoadingRole] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [rolepagination, setRolePagination] = useState<any>({
        page: 1,
    });

    const getRoleList = (page: number = 1) => {
        setLoadingRole(true);
        const request: any = {
            page: page ? page : rolepagination.page,
            per_page: 10,
            name: inputSearchRole,
        };
        if (!request.name) delete request.name;
        if (location === LOCATION.learner_portal) {
            setLoadingRole(false)
            return setRoleList([
                { name: t("USER.LEARNER"), value: 'student'},
                { name: t("USER.PARENT"), value: 'parent'},
            ])
        }
        WorkspaceService.getRoles(params.id, request)
            .then((res) => {
                const formatedData = res?.data?.items?.map((item) => {
                    return {
                        name: USER_ROLE?.[item?.name] || item?.name,
                        id: item?.id,
                        value:  item?.name,
                    };
                });
                setRolePagination({ ...rolepagination, total: res?.data?.total, page });
                if (page === 1) setRoleList(formatedData);
                else setRoleList([...roleList, ...formatedData]);
            })
            .finally(() => setLoadingRole(false));
    };

    useEffect(() => {
        if (inputSearchRole?.trim()) getRoleList(1);
    }, [inputSearchRole]);

    useEffect(() => {
        if (roleSelected?.length) setRoleSelected([])
        getRoleList(1);
    }, [location]);

    useEffect(() => {
        setRoleSelected([])
    }, [location]);

    useEffect(() => {
        let listFilter: any = {
            user_role_name: roleSelected?.map((role) => role?.value),
            q: keyword,
            status: statusSelected?.map(item => item?.value),
            location,
        };
        if (location === LOCATION.learner_portal) {
            delete listFilter.user_role_name;
            listFilter.type = roleSelected?.map((role) => role?.value)
        }
        if (!listFilter?.status) delete listFilter.status;
        if (!listFilter.type?.length) delete listFilter.type;
        onChangeFilter(listFilter);
    }, [roleSelected, keyword, statusSelected, location]);

    return (
        <>
            <div className="filterBar">
                <SearchBox className="filterBar-searchInput" onSubmit={(value) => setKeyword(value)} />

                <div className="filterBar-roleLocation">
                    <div
                        onClick={() => setLocation(LOCATION.clms)}
                        className={`btn-default ${
                            location === LOCATION.clms ? 'bg-blue-50  text-primary-500' : 'text-gray-500 bg-white'
                        }`}
                    >
                        CLMS
                    </div>
                    <div
                        onClick={() => setLocation(LOCATION.learner_portal)}
                        className={`btn-default ${
                            location === LOCATION.learner_portal ? 'bg-blue-50  text-primary-500' : 'text-gray-500 bg-white'
                        }`}
                    >
                        {t('USER.LEARNER_PORTAL')}
                    </div>
                </div>

                <SelectBox
                    label={location === LOCATION.learner_portal ? t('USER.TYPE'): t('USER.ROLE')}
                    onSearch={setInputSearchRole}
                    onSelect={setRoleSelected}
                    data={roleList}
                    loading={loadingRole}
                    selectedItems={roleSelected}
                    total={location === LOCATION.learner_portal ? 2 : rolepagination?.total}
                    showmore={() => getRoleList(rolepagination?.page + 1)}
                />
                
                <SelectBox
                    label={t('USER.STATUS')}
                    onSelect={setStatusSelected}
                    selectedItems={statusSelected}
                    data={USER_STATUS_LIST(t)}
                    total={USER_STATUS_LIST(t)?.length}
                    isMulti={true}
                />

                <div className="line"></div>

                <button
                    onClick={() => {
                        setRoleSelected([]);
                        onChangeFilter({});
                        setStatusSelected([]);
                    }}
                    className="btn-reset"
                    disabled={!roleSelected?.length && !statusSelected?.length}
                >
                    {t('RESET')}
                </button>
            </div>
        </>
    );
};

export default Filter;
