import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { timeList } from "constant/workspace.const";
import { ComboboxSelect } from "components/ComboboxSelect/ComboboxSelect";
import { CardContainer } from "components/Reports/components/CardContainer";
import { CardInfo } from "components/Reports/components/CardInfo";
import { Title } from "components/Reports/components/Title";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import classesService from "services/reports/classes-report.service";
import { StudentList } from "components/Reports/components/StudentList/StudentList";
import { DownloadStudent } from "components/Reports/components/StudentList/DownloadStudent";
import { PREVIOUS_DAYS_COMPARE } from "constant/util.const";
import { DownloadLearnerGroup } from "components/Reports/components/StudentList/DownloadLearnerGroup";
import DatePickerComponent from "components/Reports/components/DatePicker";
import moment from "moment";

export const ClassPeople = () => {
    const { t } = useTranslation();
    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);
    const params: { id: string, classId: string } = useParams();
    const [loading, setLoading] = useState(true);
    const [people, setPeople] = useState<any>();
    const [students, setStudents] = useState<any>();
    const [loadingStudents, setLoadingStudents] = useState(true);
    const [searchText, setSearchText] = useState<string>(null);
    const [isDownloading, setIsDownloading] = useState(false);
    const [type, setType] = useState('individual');

    const getPeopleData = async () => {
        if (!startDate || !endDate) {
            return;
        }
        try {
            setLoading(true);
            const payload = {
                start_time: moment(new Date(startDate)).format('YYYY-MM-DD')+ 'T00:00:00.000',
                end_time: moment(new Date(endDate)).format('YYYY-MM-DD')+ 'T23:59:59.000',
                classId: params?.classId
            }
            const response = await classesService.getClassPeopleById(params.id, payload);
            setPeople(response?.data);

            setLoading(false);
        } catch {
            setLoading(false);
        }
    }

    const getStudentsData = async (page = 1) => {
        try {
            setLoadingStudents(true);
            let payload: any = {
                classId: params.classId,
                page,
                q: searchText,
                type
            }
            if (!payload.q) {
                delete payload.q;
            }
            const response = await classesService.getClassStudentsById(params.id, payload);
            setStudents(response?.data);
            setLoadingStudents(false);
        } catch {
            setLoadingStudents(false);
        }
    }

    const handlePagination = (page: number) => {
        getStudentsData(page);
    }

    const handleDownloadCSV = async () => {
        try {
            setIsDownloading(true);
            const response = await classesService.getClassStudentsById(params.id, {
                classId: params.classId,
                page: students?.page,
                skip_pagination: true,
                type
            });
            if (type == 'individual') {
                DownloadStudent(response?.data);
            } else {
                DownloadLearnerGroup(response?.data);
            }
            
            setIsDownloading(false);
        } catch {
            setIsDownloading(false);
        }
    }

    const [startDate, setStartDate] = useState(new Date(moment().subtract(1, 'months').format('MM-DD-YYYY')));
    const [endDate, setEndDate] = useState(new Date());
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    useEffect(() => {
        getPeopleData();
    }, [startDate, endDate]);

    useEffect(() => {
        getStudentsData(1);
    }, [searchText, type]);

    return <>
        <CardContainer>
            <div className="flex items-center">
                <Title>{translator('People')}</Title>
                <div className="ml-auto min-w-[132px]">
                    <DatePickerComponent onChange={onChange} startDate={startDate} endDate={endDate} />
                </div>
            </div>
            <div className="mt-4 min-h-[123px]">
                {loading ?
                    <div className="flex justify-center items-center w-full min-h-[123px]">
                        <CircleSpin color="text-primary-500" />
                    </div> :
                    <>
                        <div className="flex space-x-5">
                            <div className="w-1/3">
                                <CardInfo  {...{
                                    value: people?.total_learners || 0,
                                    percent: people?.total_learners_changes_percent || 0,
                                    title: `${translator('Total Learners')}`,
                                }} />
                            </div>
                            <div className="w-1/3">
                                <CardInfo  {...{
                                    value: people?.total_learning_groups || 0,
                                    percent: people?.total_learning_groups_changes_percent || 0,
                                    title: `${translator('Total Learning Groups')}`,
                                }} />
                            </div>
                            <div className="w-1/3">
                                <CardInfo  {...{
                                    value: people?.total_instructors || 0,
                                    percent: people?.total_instructors_changes_percent || 0,
                                    title: `${translator('Total Instructors')}`,
                                }} />
                            </div>
                        </div>
                    </>
                }
            </div>
        </CardContainer>
        <div className='mt-5'>
            <StudentList
                students={students}
                onSearchStudent={setSearchText}
                loadingStudents={loadingStudents}
                handlePaginationStudent={handlePagination}
                handleDownloadCSV= {handleDownloadCSV}
                isDownloading={isDownloading}
                setType={setType}
                type={type}
             />
        </div>
    </>
}