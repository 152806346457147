import React from "react";
import { Disclosure } from '@headlessui/react';
import { ButtonIcon } from "_shared";
import { Caret } from "../LessonHeader/Caret";
import { Book3Outline, BookMarkLine, DraftLine, OpenBox } from "assets/icon";
import { LearningPathLesson } from "types/LearningPath.type";
import { clsx } from "clsx";
import { LessonActions } from "../LessonActions/LessonActions";

import "./LessonContent.scss";
import { useTranslation } from "react-i18next";

export const LessonContent = ({ values, sectionId, isDraggingSection }: { values: LearningPathLesson, sectionId: string, isDraggingSection: boolean }) => {
    const { t: translator } = useTranslation();
    
    return <div className={clsx("lessonContent-wrapper", {
        "lessonContent--drag": isDraggingSection
    })}>
        <span className="lessonContent-dot"></span>
        <div className="lessonContent">
            <Disclosure>
                {({ open }) => <>
                    <Disclosure.Button className="w-full">
                        <div className="lessonContent-title">
                            {values?.library_content?.sub_object_type !== "scorm" ? <ButtonIcon onClick={(event: React.MouseEvent<HTMLElement>) => { }}>
                                <span className={clsx("transition cursor-pointer",
                                    { 'rotate-90': open }
                                )}><Caret size={22} /></span>

                            </ButtonIcon> : <div className="w-[15px]"></div>}
                            <div className="flex items-center">
                                {values?.library_content?.name}
                                {
                                    values?.library_content?.sub_object_type === "scorm" ?
                                        <div className="lessonContent-scorm ml-2">
                                            <span className="text-primary-500 mr-1"><OpenBox /></span>
                                            {translator('LEARNING_PATH.LEARNING_PATH_DETAIL.SCORM_LESSON')}
                                        </div> : null
                                }
                            </div>
                            <div className="ml-auto cursor-pointer">
                                <LessonActions values={values} sectionId={sectionId} />
                            </div>
                        </div>
                    </Disclosure.Button>
                    {values?.library_content?.sub_object_type !== "scorm" ? <Disclosure.Panel>
                        <div className="lessonContent-content">
                            <div className="flex">
                                <div className="lessonContent-label">{translator('LEARNING_PATH.LEARNING_PATH_DETAIL.CONTAINS')}: </div>
                                <div className="lessonContent-value">
                                    {values?.learning_materials > 0 ? <div className="flex space-x-2">
                                        <span className="flex-none w-4"><BookMarkLine /></span>
                                        <span>{translator('LEARNING_PATH.LEARNING_PATH_DETAIL.LEARNING_MATERIALS')}</span>
                                    </div> : null}
                                    {values?.assignments > 0 ? <div className="flex mr-8 space-x-2">
                                        <span className="flex-none w-4"><DraftLine /></span>
                                        <span>{translator('LEARNING_PATH.LEARNING_PATH_DETAIL.ASSESSMENTS')}</span>
                                    </div> : null}
                                    {values?.supplementary_materials > 0 ? <div className="flex mr-8 space-x-2">
                                        <span className="flex-none w-4"><Book3Outline /></span>
                                        <span>{translator('LEARNING_PATH.LEARNING_PATH_DETAIL.SUPPLEMENTARY_MATERIALS')}</span>
                                    </div> : null}
                                </div>
                            </div>
                            <div className="flex mt-2">
                                <div className="lessonContent-label">{translator('LEARNING_PATH.LEARNING_PATH_DETAIL.LESSON_GOAL')}: </div>
                                <div className="lessonContent-value w-full">{values.lesson_goal}</div>
                            </div>
                        </div>
                    </Disclosure.Panel> : null}
                </>}
            </Disclosure>
        </div>
    </div>
}