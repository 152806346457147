import React, { useEffect, useState } from 'react';
import { PlusIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { Dropdown, PanelMessage } from '_shared';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { LearningPathBuilderHeader } from './components';
import { LearningPathLesson } from './components/LearningPathLesson/LearningPathLesson';
import { TYPE_CREATE_CONTENT } from 'components/Library/LessonBuilder/constants';
import {
    learningPathCreate,
    learningPathFetch,
    learningPathAddUnit,
    learningPathAddLesson,
    changeSelectUnit,
    clearData,
    clearStatusNewContent,
    getAssociatedCoursesLearningPath,
} from 'store/features/learningPathSlice';
import { LessonInterface } from 'types/GetListOfWorkspace.type';
import DialogComponent from 'components/Dialog/DialogComponent';
import FromLibrary from 'components/Library/LessonBuilder/components/Content/FromLibrary/FromLibrary';
import LearningPathInformation from './components/LearningPathInformation/LearningPathInformation';

import './LearningPathBuilder.scss';
import { checkPermission } from 'utils/commonFun';

export const LearningPathBuilder = () => {
    const history = useHistory();
    const { t: translator } = useTranslation();
    const params: { id: string; learningPathId: string } = useParams();
    const dispatch = useAppDispatch();
    const { data, unitSelect: unitSelected, error } = useAppSelector((state) => state.learningPath);
    const [isOpenModalLibrary, setIsOpenModalLibrary] = useState(false);
    const [hasContent, setHasContent] = useState(true);
    const [unitSelect, setUnitSelect] = useState<string>();
    const { location } = history;
    const { state }: { state: any } = location;

    const handleAddLesson = (lessons: LessonInterface[]) => {
        if (lessons?.length > 0) {
            let addlessons = [];
            let order_number = 0;
            const currentSections = data.sections.filter((item) => item.id === unitSelect);
            if (currentSections?.length > 0 && currentSections[0]?.section_contents) {
                order_number = currentSections[0]?.section_contents?.length;
            }
            lessons.forEach((item, index) => {
                addlessons.push(
                    dispatch(
                        learningPathAddLesson({
                            workspaceId: params.id,
                            id: params.learningPathId,
                            sectionId: unitSelect,
                            object_id: item.object_id,
                            object_type: 'lesson',
                            library_content_id: item.id,
                            order_number: order_number + index,
                            name: item?.name ?? '',
                            sub_object_type: item?.sub_object_type ?? '',
                        })
                    )
                );
            });
            Promise.all(addlessons).then();
        }
    };

    const handleAddUnit = () => {
        let sections: any = data?.sections || [];
        const payload = {
            workspaceId: params.id,
            id: params.learningPathId,
            title: `${translator('LEARNING_PATH.LEARNING_PATH_DETAIL.UNTITLED_UNIT')} ${sections?.length + 1}`,
            order_number: sections?.length + 1,
        };
        dispatch(learningPathAddUnit(payload));
    };

    useEffect(() => {
        const createLearningPath = async () => {
            const response = await dispatch(
                learningPathCreate({
                    workspaceId: params?.id,
                    title: translator('LEARNING_PATH.LEARNING_PATH_DETAIL.UNTITLED_LEARNING_PATH'),
                })
            );
            if (response) {
                if (response?.payload?.data?.id) {
                    history.push(`/workspace/${params.id}/learning-path/${response?.payload.data?.id}`, {
                        newContent: true,
                    });
                }
            }
        };
        if (params?.learningPathId === 'new') {
            createLearningPath();
        } else {
            if (!state?.newContent) {
                dispatch(clearStatusNewContent());
            }
            dispatch(
                learningPathFetch({
                    workspaceId: params.id,
                    id: params.learningPathId,
                })
            );
            dispatch(
                getAssociatedCoursesLearningPath({
                    workspaceId: params.id,
                    learningPathId: params.learningPathId,
                    skip_pagination: true,
                })
            );
        }
    }, [params]);

    useEffect(() => {
        if (data?.sections?.length > 0) {
            data?.sections?.forEach((item) => {
                if (item?.section_contents?.length > 0) {
                    setHasContent(true);
                    return;
                }
            });
            if (!unitSelected) {
                dispatch(changeSelectUnit(data?.sections[0]));
            }
        } else {
            setHasContent(false);
            dispatch(changeSelectUnit(null));
        }
    }, [data]);

    useEffect(() => {
        return () => {
            dispatch(clearData());
        };
    }, []);

    useEffect(() => {
        const selectUpdated = data?.sections?.filter(item => item?.id == unitSelected?.id);
        if (selectUpdated?.length > 0) {
            dispatch(changeSelectUnit(selectUpdated[0]));
        }
    }, [data?.sections]);

    return (
        <>
            <div className="learningPathBuilder">
                <div className="learningPathBuilder-header">
                    <LearningPathBuilderHeader />
                </div>
                <div className="learningPathBuilder-main">
                    <div>
                        <h2 className="learningPathBuilder-title">{translator('Information')}</h2>
                        <div className="learningPathBuilder-sidebar">
                            <LearningPathInformation />
                        </div>
                    </div>
                    <div className="w-full">
                        <h2 className="learningPathBuilder-title">
                            {translator('Contents')}
                            <div className="flex ml-auto">
                                {data?.sections?.length > 0 ? (
                                    <Dropdown
                                        items={data?.sections}
                                        onChange={(value: any) => {
                                            dispatch(changeSelectUnit(value));
                                            document.getElementById(value.id).scrollIntoView();
                                        }}
                                        displayKey={'title'}
                                        placeholder={translator('LEARNING_PATH.LEARNING_PATH_DETAIL.SELECT_UNIT')}
                                        className="min-w-[200px]"
                                        selectedValue={unitSelected}
                                    />
                                ) : null}
                                <button type="button" onClick={handleAddUnit} className="learningPathBuilder-add disabled:cursor-not-allowed" disabled={!checkPermission('learningPath', 'U')}>
                                    <PlusIcon className="w-4 h-4 mr-2.5" />
                                    {translator('LEARNING_PATH.LEARNING_PATH_DETAIL.ADD_UNIT')}
                                </button>
                            </div>
                        </h2>
                        <div className="learningPathBuilder-content">
                            {!hasContent && (
                                <PanelMessage
                                    message={translator('LEARNING_PATH.LEARNING_PATH_DETAIL.ADD_LEAST_ONE_UNIT')}
                                    onClick={() => setHasContent(true)}
                                />
                            )}
                            <div className="learningPathBuilder-container relative">
                                {data?.sections?.map((item, index) => (
                                    <div className="learningPathBuilder-unit" key={item?.id} id={item?.id}>
                                        <LearningPathLesson
                                            values={item}
                                            unitIndex={index}
                                            addLesson={(id: string) => {
                                                setIsOpenModalLibrary(true);
                                                setUnitSelect(id);
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                            <button
                                type="button"
                                onClick={handleAddUnit}
                                className="learningPathBuilder-add learningPathBuilder-add--large disabled:cursor-not-allowed"
                                disabled={!checkPermission('learningPath', 'U')}
                            >
                                <PlusIcon className="w-4 h-4 mr-2.5" />
                                {translator('LEARNING_PATH.LEARNING_PATH_DETAIL.ADD_UNIT')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpenModalLibrary && (
                <DialogComponent
                    isOpen={isOpenModalLibrary}
                    onCloseModal={() => setIsOpenModalLibrary(false)}
                    hasNonTitle
                    styles="max-w-[70vw] min-h-[480px] my-0 rounded-lg !p-0 pb-4"
                    isShowClose={false}
                    child={
                        <FromLibrary
                            createContentType={TYPE_CREATE_CONTENT.LESSON}
                            type={TYPE_CREATE_CONTENT.LESSON}
                            title={translator('LEARNING_PATH.LEARNING_PATH_DETAIL.ADD_LESSON')}
                            onCloseModal={() => setIsOpenModalLibrary(false)}
                            handleAddContentToSection={handleAddLesson}
                            hasLesson={true}
                        />
                    }
                />
            )}
        </>
    );
};
