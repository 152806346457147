
import React from "react";
import { OverviewSessions } from "./Components/Sessions/OverviewSessions";
import { SessionBreakdown } from "./Components/Sessions/SessionBreakdown";

export const SessionsTab = () => {
    return<>
        <div className="flex space-x-5">
            <div className="w-full">
                <OverviewSessions />
            </div>
        </div>
        <div className="flex w-full mt-5 space-x-5">
            <SessionBreakdown />
        </div>
    </>
}