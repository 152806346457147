export const ClipboardIcon = ({
    fill = '#111827',
    width = '20',
    height = '20',
}: {
    fill?: string;
    width?: string;
    height?: string;
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.66634 2.49996C6.66634 2.03972 7.03944 1.66663 7.49967 1.66663H12.4997C12.9599 1.66663 13.333 2.03972 13.333 2.49996H14.9997C15.9201 2.49996 16.6663 3.24615 16.6663 4.16663V16.6666C16.6663 17.5871 15.9201 18.3333 14.9997 18.3333H4.99967C4.0792 18.3333 3.33301 17.5871 3.33301 16.6666V4.16663C3.33301 3.24615 4.0792 2.49996 4.99967 2.49996H6.66634ZM6.66634 4.16663H4.99967V16.6666H14.9997V4.16663H13.333V4.99996C13.333 5.4602 12.9599 5.83329 12.4997 5.83329H7.49967C7.03944 5.83329 6.66634 5.4602 6.66634 4.99996V4.16663ZM11.6663 3.33329H8.33301V4.16663H11.6663V3.33329ZM13.0889 8.99404C13.4144 9.31947 13.4144 9.84711 13.0889 10.1725L9.7556 13.5059C9.43016 13.8313 8.90252 13.8313 8.57709 13.5059L6.91042 11.8392C6.58498 11.5138 6.58498 10.9861 6.91042 10.6607C7.23586 10.3353 7.76349 10.3353 8.08893 10.6607L9.16634 11.7381L11.9104 8.99404C12.2359 8.6686 12.7635 8.6686 13.0889 8.99404Z" fill="#111827" />
        </svg>

    );
};
