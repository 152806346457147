import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { RadioGroup } from '@headlessui/react';
import { classNames } from 'utils/commonFun';
import ButtonComponent from 'components/Button/ButtonComponent';
import { InputField } from 'components/InputForm/InputForm';
import ErrorMessageForm from 'components/ErrorMessageForm';

type ModalSettingProps = {
    translator: (key: string, newKey?: boolean) => string;
    onClose: () => void;
};

const ModalSetting: FC<ModalSettingProps> = ({ translator, onClose }) => {
    const {
        register,
        control,
        formState: { errors, isDirty },
        handleSubmit,
        watch,
        reset,
        setValue,
    } = useForm({
        defaultValues: {
            username: '',
            password: '',
            url: '',
            clientId: '',
            client_secret: '',
            grant_type: '',
            grant: false,
            schedule_on: '',
            status: '', // '' | 'connected' | 'fail'
        },
    });

    const onSubmitForm = (values) => {
        console.log(values);
    };

    return (
        <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="w-full flex flex-col space-y-5 border-t border-gray-200 pt-5">
                <InputField
                    label={translator('USERNAME')}
                    name="username"
                    maxLength={255}
                    placeholder={translator('USERNAME')}
                    register={register('username', { required: translator('FORM_CONST.REQUIRED_FIELD', true) })}
                    errors={errors}
                    vertical={false}
                />
                <InputField
                    label={translator('PASSWORD')}
                    name="password"
                    maxLength={255}
                    placeholder={translator('PASSWORD')}
                    register={register('password', { required: translator('FORM_CONST.REQUIRED_FIELD', true) })}
                    errors={errors}
                    vertical={false}
                />
                <InputField
                    label={translator('URL')}
                    name="url"
                    maxLength={255}
                    placeholder={translator('URL')}
                    register={register('url', { required: translator('FORM_CONST.REQUIRED_FIELD', true) })}
                    errors={errors}
                    vertical={false}
                />
                <InputField
                    label={translator('CLIENT_ID')}
                    name="clientId"
                    maxLength={255}
                    placeholder={translator('CLIENT_ID')}
                    register={register('clientId', { required: translator('FORM_CONST.REQUIRED_FIELD', true) })}
                    errors={errors}
                    vertical={false}
                />
                <InputField
                    label={translator('CLIENT_SECRET')}
                    name="client_secret"
                    maxLength={255}
                    placeholder={translator('CLIENT_SECRET')}
                    register={register('client_secret', { required: translator('FORM_CONST.REQUIRED_FIELD', true) })}
                    errors={errors}
                    vertical={false}
                />
                <InputField
                    label={translator('GRANT_TYPE')}
                    name="grant_type"
                    maxLength={255}
                    placeholder={translator('GRANT_TYPE')}
                    register={register('grant_type', { required: translator('FORM_CONST.REQUIRED_FIELD', true) })}
                    errors={errors}
                    vertical={false}
                />
            </div>
            <div className="mt-3 flex justify-end">
                {watch('status') === '' && (
                    <p className="w-fit flex items-center space-x-1 text-primary-500 text-sm font-normal">
                        <span className="w-[10px] h-[10px] rounded-full border border-primary-500 text-[8px] flex items-center justify-center">
                            ?
                        </span>
                        <span>{translator('CONNECTION') + translator('NOT_CONNECTED')}</span>
                    </p>
                )}
                {watch('status') === 'connected' && (
                    <p className="w-fit flex items-center space-x-1 bg-green-50 text-green-500 text-sm font-normal px-2 py-1 rounded border border-green-200">
                        <span className="w-[6px] h-[6px] rounded-full bg-green-500" />
                        <span>{translator('CONNECTION') + translator('CONNECTED')}</span>
                    </p>
                )}
                {watch('status') === 'fail' && (
                    <p className="w-fit flex items-center space-x-1 bg-orange-50 text-orange-500 text-sm font-normal px-2 py-1 rounded border border-orange-200">
                        <span className="w-[6px] h-[6px] rounded-full bg-orange-500" />
                        <span>{translator('CONNECTION') + translator('FAILURE')}</span>
                    </p>
                )}
            </div>
            <div className="mt-10">
                <Controller
                    name={'grant'}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <RadioGroup
                            as="div"
                            className="grid grid-cols-5 gap-x-4"
                            value={value}
                            onChange={onChange}
                        >
                            <RadioGroup.Label className="text-xs text-ooolab_dark_1 font-semibold flex items-center">
                                {translator('GRANT_TYPE')}
                            </RadioGroup.Label>
                            <div className="col-span-4 flex items-center">
                                <RadioGroup.Option
                                    value={true}
                                    className={classNames(
                                        'w-1/2 text-center px-4 py-2 rounded-l border border-primary-500 cursor-pointer text-sm font-semibold',
                                        watch('grant') ? 'bg-primary-500' : ''
                                    )}
                                >
                                    {({ checked }) => (
                                        <span className={checked ? 'text-white' : 'text-primary-500'}>{translator('ON')}</span>
                                    )}
                                </RadioGroup.Option>
                                <RadioGroup.Option
                                    value={false}
                                    className={classNames(
                                        'w-1/2 text-center px-4 py-2 rounded-r border border-primary-500 cursor-pointer text-sm font-semibold',
                                        !watch('grant') ? 'bg-primary-500' : ''
                                    )}
                                >
                                    {({ checked }) => (
                                        <span className={checked ? 'text-white' : 'text-primary-500'}>{translator('OFF')}</span>
                                    )}
                                </RadioGroup.Option>
                            </div>
                        </RadioGroup>
                    )}
                />
                <InputField
                    label={translator('SCHEDULE_ON')}
                    name="schedule_on"
                    maxLength={255}
                    className="mt-5"
                    placeholder={translator('SCHEDULE_ON')}
                    register={register('schedule_on', { required: translator('FORM_CONST.REQUIRED_FIELD', true) })}
                    errors={errors}
                    vertical={false}
                />
                <div className="mt-3 text-sm font-semibold text-gray-800 flex justify-end">
                    <p>
                        <span>{translator('EXAMPLE')}</span>
                        <a
                            rel="noopener noreferrer"
                            href="https://crontab.guru/"
                            target="_blank"
                            className="decoration-slice text-[#2576B9] cursor-pointer"
                        >
                            {translator('HERE')}
                        </a>
                    </p>
                </div>
                <div className="mt-6 flex items-center justify-end space-x-4 border-t border-gray-200 pt-5">
                    <ButtonComponent
                        title={translator('CANCEL', true)}
                        classStyle="xs:p-2 xs:text-sm secondary-button !bg-gray-200 !border-gray-200 !text-gray-800 !rounded-lg"
                        onClickButton={() => onClose?.()}
                    />
                    <ButtonComponent
                        type="submit"
                        title={translator('SAVE', true)}
                        classStyle="xs:p-2 xs:text-sm primary-button !rounded-lg"
                    />
                </div>
            </div>
        </form>
    );
};

export default ModalSetting;
