import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import { debounce } from 'lodash';
import './FilterRolesPermissions.scss';

interface IFilterRolesPermissions {
    onChangeFilter: (value: any) => void;
    isFirstLoad: boolean;
    location: string;
    setLocation: any
}

const FilterRolesPermissions = ({ onChangeFilter, isFirstLoad, location, setLocation }: IFilterRolesPermissions) => {
    const { t } = useTranslation();
    const [keyword, setKeyword] = useDebouncedState('', 0);
    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`LIBRARY.${str}`, opt));
    
    const updateFilter = useCallback(
        debounce((listFilter) => onChangeFilter(listFilter), 500),
        []
    );

    useEffect(() => {
        if (!isFirstLoad) {
            let listFilter: any = {
                q: keyword?.trim(),
            };
            updateFilter(listFilter);
        }
    }, [keyword]);

    return (
        <>
            <div className="filterBar">
                <SearchBox className="filterBar-searchInput" onSubmit={(value) => setKeyword(value)} />
                <div className="line hidden"></div>
                <button
                    onClick={() => {
                        setKeyword(null);
                    }}
                    className="btn-reset hidden"
                    disabled={!keyword?.length}
                >
                    {translator('RESET')}
                </button>
            </div>
        </>
    );
};

export default FilterRolesPermissions;
