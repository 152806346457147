import { useParams } from 'react-router-dom';
import TeachingHistoryTable from './Table'
import { getIndexPage } from 'utils/commonFun';
import { useEffect, useState } from 'react';
import Pagination from 'components/V2/Pagination';
import { Filter } from './Filter/Filter';
import instructorsService from 'services/instructors.service';
import Spinner from 'components/Spinner';
import "./index.scss";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import dayjs from 'dayjs';

let startDate = null;
let endDate = null;
let dateRangeTemp = [];
export default function TeachingHistoryList({ dateRange, start = null, end = null, hasById = true }) {
  const {t: translator} = useTranslation();
  const params: { id: string, userId: string } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [indexPage, setIndexPage] = useState('0-0');
  const [isFirstLoad, setFirstLoad] = useState(true);
  const [pagination, setPagination] = useState<any>({
    page: 1,
    per_page: 20,
    order: 'desc',
    sort_by: 'updated_at',
    total: 0,
  });

  const getdata = async (page: number | string = 1, filter = undefined) => {
		if (loading) return;

    try {
      setFirstLoad(false);
      setLoading(true);
      let payload: any = {
          ...pagination,
          ...filter,
          page,
          from_date: dayjs(dateRangeTemp?.[0]).format("YYYY-MM-DDTHH:mm:ss.sssZ"),
          to_date: dayjs(dateRangeTemp?.[1]).format("YYYY-MM-DDT23:59:59.000Z"),
      }
      delete payload?.total;
      let response;
      if (hasById) {
        response = await instructorsService.getTeachingHistoryList(params?.id, params?.userId, payload);

      } else if (startDate && endDate) {
        payload = {
          ...payload,
          from_date: moment(startDate).format("YYYY-MM-DDTHH:mm:ss.sssZ"),
          to_date: moment(endDate).format("YYYY-MM-DDT23:59:59.000Z"),
        }
        response = await instructorsService.getTeachingHistoryList(params?.id, null, payload);
      }
			if (response?.data) {
				const { items, total } = response.data;
				setData(items);
				setPagination({ ...pagination, ...payload, total });
				setIndexPage(getIndexPage(payload?.page, pagination?.per_page, items?.length));
			}

    } catch (error) {

    } finally {
      setLoading(false);
    }
  };

  const handlePagination = (page: number) => {
    getdata(page);
  };

	useEffect(() => {
		startDate = start;
		endDate = end;
		dateRangeTemp = dateRange;
		return () => {
			startDate = null;
			endDate = null;
			dateRangeTemp = [];
		}
  }, [dateRange, start, end]);

  useEffect(() => {
    getdata();
  }, [startDate, endDate, dateRangeTemp])

  const handleFilter = (filter) => {
    getdata(1, filter);
  }

  return (
    <div className='mt-5'>
      {!hasById ? <h2 className='teachingHistoryList-title'>{translator('TEACHING_HISTORY.TEACHING_HISTORY')}</h2> : null}
      <div className='relative z-[6]'><Filter onChangeFilter={handleFilter} isFirstLoad={isFirstLoad} /></div>
      <div className='teachingHistoryList'>
        {loading && (
          <div className='top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute'>
            <Spinner />
          </div>
        )}
        {!loading && <TeachingHistoryTable data={data} refreshData={undefined} userData={undefined} />}
      </div>
      {!loading && <Pagination
        total={pagination.total}
        per_page={pagination.per_page}
        callback={(e) => handlePagination(e)}
        indexPage={indexPage}
        page={pagination?.page}
      />}
    </div>
  )
}
