import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { EyeIcon, TrashIcon } from '@heroicons/react/outline';

import { useTranslation } from 'react-i18next';

import notificationsService from 'services/notifications.service';
import { Toastify } from '_shared';
import { checkPermission } from 'utils/commonFun';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

const iconStyle = 'w-4 h-9 text-ooolab_dark_2';

interface ActionListInterface {
    data: any;
    workspace: string;
    setLoadList: React.Dispatch<React.SetStateAction<boolean>>;
    status: string;
    position?: string;
}

const ActionList: React.FC<ActionListInterface> = ({
    data,
    workspace,
    setLoadList,
    status,
    position
}) => {
    const { t: translator } = useTranslation();
    const history = useHistory();

    async function onDelete() {
        await notificationsService
            .deleteNotification(workspace, data.id)
            .then((res) => {
                setLoadList(true);
                Toastify.success();
            })
            .catch(() => Toastify.error());
    }
    const MenuList = [
        {
            name: translator("VIEW"),
            icons: <EyeIcon className={iconStyle} />,
            function: () =>
                history.push(
                    `/workspace/${workspace}/management/notification/${data.id}`
                ),
            isDisplay: checkPermission('customNotification', 'R'),
        },
        {
            name: translator("DELETE"),
            icons: <TrashIcon className={iconStyle} />,
            function: onDelete,
            isDisplay: (status !== 'published' && checkPermission('customNotification', 'D')),
        },
    ];

    return (
        <>
            <Menu
                as="div"
                className="text-left h-full flex items-center relative"
            >
                {({ open }) => (
                    <>
                        <div>
                            <Menu.Button className="flex justify-center items-center text-ooolab_dark_1 hover:bg-ooolab_bg_sub_tab_hover hover:text-white focus:outline-none w-6 h-6 rounded-full">
                                <DotsVerticalIcon
                                    className="w-4 h-4"
                                    aria-hidden="true"
                                />
                            </Menu.Button>
                        </div>

                        <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className={`z-9999 shadow-ooolab_box_shadow_container origin-top-right  absolute ${position ? position : 'top-[10%]'} right-[100%] mt-2 w-44 rounded-3xl focus:outline-none`}>
                                <div className="">
                                    {MenuList.map((i) => {
                                        return (
                                            <Menu.Item key={i.name}>
                                                {({ }) => (
                                                    <div
                                                        onClick={(e) => {
                                                            if (i.function) {
                                                                i.function();
                                                            }
                                                        }}
                                                        className={`flex  px-2 w-full bg-white hover:bg-ooolab_bg_sub_tab_hover rounded-lg cursor-pointer  ${!i.isDisplay
                                                            ? 'hidden'
                                                            : ' '
                                                            }`}
                                                    >
                                                        {i.icons}
                                                        <a
                                                            className={classNames(
                                                                ' px-4 py-1 text-ooolab_sm self-center text-ooolab_dark_1'
                                                            )}
                                                        >
                                                            {i.name}
                                                        </a>
                                                    </div>
                                                )}
                                            </Menu.Item>
                                        );
                                    })}
                                </div>
                            </Menu.Items>
                        </Transition>
                    </>
                )}
            </Menu>
        </>
    );
};

export default ActionList;
