import { HOST_URL, CLASS, H5P } from 'constant/api.const';
import {
    DetailsGradebookType,
    IAssignmentResultByStudent,
    IClassAssignment,
    IClassAssignmentParam,
    IClassAssignmentPatchParams,
    IClassAssignmentResponse,
    IClassResponse,
    IClassSessionParams,
    ICreateClassParams,
    IGetClassParams,
    IPatchClassParams,
    IUserAssignmentResult,
    ParamsReport,
} from 'types/Class.type';
import { NormalResponseError } from 'types/Common.type';
import { MemberInviteType } from 'types/GetListOfWorkspace.type';
import axios, { RestfulService } from './restful.service';

const createClass = async (workspaceId: string, classParam: ICreateClassParams): Promise<any> => {
    const res = await RestfulService.postApi(HOST_URL + CLASS.create(workspaceId), classParam);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const getCLassList = async (workspaceId: string, params: IGetClassParams): Promise<any> => {
    const res = await RestfulService.getApi(HOST_URL + CLASS.getList(workspaceId), params);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const getClassDetail = async (workspaceId: string, classId: string): Promise<any> => {
    const res = await RestfulService.getApi(HOST_URL + CLASS.getDetail(workspaceId, classId));
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const updatePartialClass = async (workspaceId: string, classId: string, params: IPatchClassParams) => {
    const res = await RestfulService.patchApi(HOST_URL + CLASS.update(workspaceId, classId), params);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const updateClassStatus = async (workspaceId: string, classId: string, status: 'active' | 'deactivate') => {
    const res = await RestfulService.postApi(HOST_URL + CLASS.delete(workspaceId, classId), { status });
    if (!!res.data.error) {
        throw res.data;
    }
    return true;
};

const getClassSession = async (workspaceId: string, classId: string, params?: IClassSessionParams) => {
    const res = await RestfulService.getApi(HOST_URL + CLASS.getListSession(workspaceId, classId), params);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const inviteEmail = async (workspaceId: string, classId: string, body: MemberInviteType) => {
    const res = await RestfulService.postApi(HOST_URL + CLASS.inviteMembers(workspaceId, classId), body);
    if (!!res.data.error) {
        throw res.data;
    }

    return true;
};

const removeMemberClasses = async (workspaceId: string, classId: string, userId: number) => {
    const res = await RestfulService.deleteApi(HOST_URL + CLASS.removeMembers(workspaceId, classId, userId));
    if (!!res.data.error) {
        throw res.data;
    }

    return true;
};
const deleteSessions = async (workspaceId: string, classId: string, sessionId: string) => {
    const res = await RestfulService.deleteApi(HOST_URL + CLASS.deleteSession(workspaceId, classId, sessionId));
    if (!!res.data.error) {
        throw res.data;
    }

    return true;
};

const createSession = async (
    workspaceId: string,
    classId: string,
    params: {
        start_time: string;
        end_time: string;
        lesson_id?: number;
        type: string;
        assignments: any[];
        assignment_templates: any[];
        instructors?: any[];
        students?: any[];
        vc_link?: string;
    }
) => {
    const res = await RestfulService.postApi(HOST_URL + CLASS.createSession(workspaceId, classId), params);
    if (!!res.data.error) {
        throw res.data;
    }
};

const updateSessions = async (
    workspaceId: string,
    classId: string,
    sessionId: string,
    params: {
        start_time: string;
        end_time: string;
        lesson_id?: number;
    }
) => {
    const res = await RestfulService.putApi(HOST_URL + CLASS.updateSessions(workspaceId, classId, sessionId), params);
    if (!!res.data.error) {
        throw res.data;
    }

    return sessionId;
};

const getAssignments = async (
    workspaceId: string,
    classId: string,
    params: {
        page: number;
        per_page?: number;
        end_before?: string;
        end_after?: string;
        order?: string;
    }
): Promise<IClassAssignmentResponse | undefined> => {
    const { page, per_page = 5, end_before, end_after, order } = params;
    const res = await RestfulService.getApi(HOST_URL + CLASS.getAssignments(workspaceId, classId), {
        page,
        per_page,
        end_before,
        end_after,
        order,
    });
    if (!!res.data.error) {
        return undefined;
    }
    return res.data;
};

const getAssignmentById = async (
    workspaceId: string,
    classId: string,
    assignmentId: string
): Promise<IClassAssignment | undefined> => {
    const res = await RestfulService.getApi(HOST_URL + CLASS.getAssignmentById(workspaceId, classId, assignmentId));
    if (!!res.data.error) {
        return undefined;
    }
    return res.data;
};

const getAssignmentResultById = async (
    workspaceId: string,
    classId: string,
    assignmentId: string
): Promise<IUserAssignmentResult[] | undefined> => {
    const res = await RestfulService.getApi(HOST_URL + CLASS.getAssignmentResult(workspaceId, classId, assignmentId));
    if (!!res.data.error) {
        return undefined;
    }
    return res.data.items;
};

const getAssignmentResultByStudentId = async (
    workspaceId: string,
    classId: string,
    assignmentId: string,
    studentId: string
): Promise<IAssignmentResultByStudent | undefined> => {
    const res = await RestfulService.getApi(
        HOST_URL + CLASS.getAsignmentResultByStudentId(workspaceId, classId, assignmentId, studentId)
    );
    if (!!res.data.error) {
        return undefined;
    }
    return res.data;
};

const deleteAssignment = async (workspaceId: string, classId: string, assignmentId: number) => {
    const res = await RestfulService.deleteApi(HOST_URL + CLASS.deleteAssignment(workspaceId, classId, assignmentId));
    if (!!res.data.error) {
        throw res.data;
    }
    return true;
};

const patchAssignment = async (workspaceId: string, classId: string, assignmentId: string, params: IClassAssignmentParam) => {
    const res = await RestfulService.patchApi(HOST_URL + CLASS.updateAssignment(workspaceId, classId, assignmentId), params);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const importAssignmentToClass = async (
    workspaceId: string,
    classId: string,
    courseId: string
): Promise<boolean | NormalResponseError> => {
    const res = await RestfulService.postApi(HOST_URL + CLASS.importAssignment(workspaceId, classId), {
        course_id: courseId,
    });
    if (!!res.data.error) {
        throw res.data;
    }
    if (res.status === 204) {
        return true;
    }
};

const patchAssignmentGroups = async (
    workspaceId: string,
    class_id: string,
    assignment_group_id: string,
    data: IClassAssignmentPatchParams
) => {
    const res = await RestfulService.patchApi(HOST_URL + CLASS.patchGroup(workspaceId, class_id, assignment_group_id), data);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const importCourseToSession = async (
    workspaceId: string,
    classId: string,
    body: {
        course_id: number;
    }
) => {
    const res = await RestfulService.postApi(HOST_URL + CLASS.importCourse(workspaceId, classId), body);
    if (!!res.data.error) {
        throw res.data;
    }
};

const getGradebook = async (workspaceId: string, classId: string) => {
    const res = await RestfulService.getApi(HOST_URL + CLASS.getGradebook(workspaceId, classId));
    if (!!res.data.error) {
        return undefined;
    }
    return res.data;
};

const getGradebookApo = async (workspaceId: string, classId: string) => {
    const res = await RestfulService.getApi(HOST_URL + CLASS.getGradebookApo(workspaceId, classId));
    if (!!res.data.error) {
        return undefined;
    }
    return res.data;
};

const updateGradebook = async (workspaceId: string, classId: string, gradebookId: number, details: DetailsGradebookType) => {
    const res = await RestfulService.putApi(HOST_URL + CLASS.updateGradebook(workspaceId, classId, gradebookId), details);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};
const getReportOfClass = async (workspaceId: string, classId: string, params?: ParamsReport) => {
    const res = await RestfulService.getApi(HOST_URL + CLASS.getReportOfClass(workspaceId, classId), params);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const getReportDetailsOfClass = async (workspaceId: string, classId: string, reportId: number) => {
    const res = await RestfulService.getApi(HOST_URL + CLASS.getReportDetailsOfClass(workspaceId, classId, reportId));
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const getTeacherListInClassService = ({ workspaceId, classId }, bodyData: any) => {
    if (workspaceId && classId) {
        const url = CLASS.getTeachersInClass(workspaceId, classId);
        return axios.get(url, { params: bodyData });
    }
};

const getTeacherListOfStudentService = ({ workspaceId, classId, membershipId }, bodyData: any) => {
    if (workspaceId && classId) {
        const url = CLASS.getTeachersOfStudentInClass(workspaceId, classId, membershipId);
        return axios.get(url, { params: bodyData });
    }
};

const createProgressReportService = ({ workspaceId, classId }, bodyData: any) => {
    if (workspaceId && classId) {
        const url = CLASS.createProgressReport(workspaceId, classId);
        return axios.post(url, bodyData);
    }
};

const createGradebookReportService = ({ workspaceId, classId }, bodyData: any) => {
    if (workspaceId && classId) {
        const url = CLASS.createGradebookReport(workspaceId, classId);
        return axios.post(url, bodyData);
    }
};

const getTeacherListService = (workspaceId: string, bodyData: any) => {
    if (workspaceId) {
        const url = CLASS.getTeachers(workspaceId);
        return axios.get(url, { params: bodyData });
    }
};

const getStudentListService = (workspaceId: string, bodyData: any) => {
    if (workspaceId) {
        const url = CLASS.getStudents(workspaceId);
        return axios.get(url, { params: bodyData });
    }
};

const addStudent = (workspaceId: string, bodyData: any) => {
    if (workspaceId) {
        const url = CLASS.getStudents(workspaceId);
        return axios.post(url, bodyData);
    }
};

const getStudentApoHistoryListService = (workspaceId: string, studentId: string, bodyData: any) => {
    if (workspaceId) {
        const url = CLASS.getStudentApoHistoryList(workspaceId, studentId);
        return axios.get(url, { params: bodyData });
    }
};

const getStudentDetailService = (workspaceId: string, studentId: string) => {
    if (workspaceId) {
        const url = CLASS.getStudentDetailPoint(workspaceId, studentId);
        return axios.get(url);
    }
};

const getStudentPedCardsService = (workspaceId: string, studentId: string, bodyData) => {
    if (workspaceId) {
        const url = CLASS.getStudentPedCards(workspaceId, studentId);
        return axios.get(url, { params: bodyData });
    }
};

const getTeacherDetailService = (workspaceId: string, studentId: string) => {
    if (workspaceId) {
        const url = CLASS.getTeacherDetail(workspaceId, studentId);
        return axios.get(url);
    }
};

const updateTeacherDetailService = (workspaceId: string, teacherId: string, bodyData) => {
    if (workspaceId) {
        const url = CLASS.updateTeacherDetail(workspaceId, teacherId);
        return axios.put(url, bodyData);
    }
};

const updateStudentDetailService = (workspaceId: string, studentId: string, bodyData) => {
    if (workspaceId) {
        const url = CLASS.updateStudentDetail(workspaceId, studentId);
        return axios.patch(url, bodyData);
    }
};

const getLanguagesService = (workspaceId: string) => {
    if (workspaceId) {
        const url = CLASS.getLanguages(workspaceId);
        return axios.get(url);
    }
};

const getLanguagesOptionService = (workspaceId: string, params: any,) => {
    if (workspaceId) {
        const url = CLASS.getLanguagesOption(workspaceId);
        return axios.get(url, { params });
    }
};

const getCertificationOptionService = (workspaceId: string, params?: any) => {
    if (workspaceId) {
        const url = CLASS.getCertificationOption(workspaceId);
        return axios.get(url, { params });
    }
};

const addAposRewardService = (workspaceId: string, studentId: string, bodyData: any) => {
    if (workspaceId) {
        const url = CLASS.addAposReward(workspaceId, studentId);
        return axios.post(url, bodyData);
    }
};

const getClaimHistoryListService = (workspaceId: string, studentId: string, bodyData: any) => {
    if (workspaceId) {
        const url = CLASS.getClaimHistoryList(workspaceId, studentId);
        return axios.get(url, { params: bodyData });
    }
};

const refundRewardService = (workspaceId: string, rewardId: string, rewardClaimId: string, bodyData: any) => {
    if (workspaceId) {
        const url = CLASS.refundReward(workspaceId, rewardId, rewardClaimId);
        return axios.post(url, bodyData);
    }
};

const claimService = (workspaceId: string, rewardId: string, rewardClaimId: string) => {
    if (workspaceId) {
        const url = CLASS.claimReward(workspaceId, rewardId, rewardClaimId);
        return axios.post(url);
    }
};

const UpdateProgressReportService = ({ workspaceId, classId, reportId }, bodyData: any) => {
    if (workspaceId && classId && reportId) {
        const url = CLASS.updateProgressReport(workspaceId, classId, reportId);
        return axios.put(url, bodyData);
    }
};

const UpdateGradebookReportService = ({ workspaceId, classId, reportId }, bodyData: any) => {
    if (workspaceId && classId && reportId) {
        const url = CLASS.updateGradebookReport(workspaceId, classId, reportId);
        return axios.put(url, bodyData);
    }
};

const getListRecordOfWorkService = (
    { workspaceId, classId }: { workspaceId: string; classId: string },
    bodyData?: {
        page: number;
        per_page: number;
        order: 'asc' | 'desc';
        sort_by: string;
    }
) => {
    if (workspaceId && classId) {
        const url = CLASS.getRecordsOfWorkFromClass(workspaceId, classId);
        return axios.get(url, { params: bodyData });
    }
};

const updateRecordOfWorkService = (
    { workspaceId, classId, sessionId }: { workspaceId: string; classId: string; sessionId: string },
    bodyData: {
        sb_ab_pages: string;
        tl_new_reviewed: string;
        productive: string;
        note: string;
    }
) => {
    if (workspaceId && classId && sessionId) {
        const url = CLASS.updateRecordsOfWorkFromClass(workspaceId, classId, sessionId);
        return axios.patch(url, bodyData);
    }
};

const getClassStudentsWithPointsService = ({ workspaceId, classId }: { workspaceId: string; classId: string }) => {
    if (workspaceId && classId) {
        const url = CLASS.getClassStudentsWithPoints(workspaceId, classId);
        return axios.get(url);
    }
};

const updateClassStudentsRewardPointsService = (
    { workspaceId, classId }: { workspaceId: string; classId: string },
    bodyData: {
        students: [
            {
                student_id: number;
                amount: number;
                description: string;
            }
        ];
    }
) => {
    if (workspaceId && classId) {
        const url = CLASS.updateClassStudentsRewardPoints(workspaceId, classId);
        return axios.post(url, bodyData);
    }
};

const getBannersService = (workspaceId: string, bodyData: any) => {
    if (workspaceId) {
        const url = CLASS.getBanners(workspaceId);
        return axios.get(url, { params: bodyData });
    }
};

const getResourcesService = ({ workspaceId, classId }: { workspaceId: string; classId: string }, bodyData: any) => {
    if (workspaceId && classId) {
        const url = CLASS.getAndUploadResourcesUrl(workspaceId, classId);
        return axios.get(url, { params: bodyData });
    }
};

const uploadResourcesService = (
    { workspaceId, classId }: { workspaceId: string; classId: string },
    bodyData: {
        h5p_content_id?: number;
        mime_type: string;
        file_extension: string;
        file_name?: string;
        file_path?: string;
    }
) => {
    if (workspaceId && classId) {
        const url = CLASS.getAndUploadResourcesUrl(workspaceId, classId);
        return axios.post(url, bodyData);
    }
};

const viewResourceService = ({ workspaceId, classId, resourceId }: { workspaceId: string; classId: string; resourceId: number }) => {
    if (workspaceId && classId) {
        const url = CLASS.detailResourceUrl(workspaceId, classId, resourceId);
        return axios.get(url);
    }
};

const deleteResourceService = ({ workspaceId, classId, resourceId }: { workspaceId: string; classId: string; resourceId: number }) => {
    if (workspaceId && classId && resourceId) {
        const url = CLASS.detailResourceUrl(workspaceId, classId, resourceId);
        return axios.delete(url);
    }
};

const renameResourceService = (
    { workspaceId, classId, resourceId }: { workspaceId: string; classId: string; resourceId: number },
    { file_name }: { file_name: string }
) => {
    if (workspaceId && classId && resourceId) {
        const url = CLASS.renameResourceUrl(workspaceId, classId, resourceId);
        return axios.patch(url, { file_name });
    }
};

const downloadResourceService = ({
    workspaceId,
    classId,
    resourceId,
}: {
    workspaceId: string;
    classId: string;
    resourceId: number;
}) => {
    if (workspaceId && classId && resourceId) {
        const url = CLASS.detailResourceUrl(workspaceId, classId, resourceId);
        return axios.get(url);
    }
};

const getH5pFilesService = ({ workspaceId }: { workspaceId: string }, bodyData: any) => {
    if (workspaceId) {
        const url = H5P.root(workspaceId);
        return axios.get(url, { params: bodyData });
    }
};

const completeReportClass = (workspaceId: string, classId: string, reportId: string) => {
    if (workspaceId) {
        const url = CLASS.completeReportClass(workspaceId, classId, reportId);
        return axios.post(url);
    }
};
const deleteReportClass = (workspaceId: string, classId: string, reportId: string) => {
    if (workspaceId) {
        const url = CLASS.reportClass(workspaceId, classId, reportId);
        return axios.delete(url);
    }
};
const publishReportClass = (workspaceId: string, classId: string, reportId: string, bodyData: any) => {
    if (workspaceId) {
        const url = CLASS.publishReportClass(workspaceId, classId, reportId);
        return axios.post(url, bodyData);
    }
};

const getReportVersion = (workspaceId: string, classId: string, reportId: any, bodyData?: any) => {
    if (workspaceId) {
        const url = CLASS.reportVersionClass(workspaceId, classId, reportId);
        return axios.get(url, { params: bodyData });
    }
};

const getCompanyListService = (workspaceId: string, bodyData?: any) => {
    if (workspaceId) {
        const url = CLASS.getCompanyList(workspaceId);
        return axios.get(url, { params: bodyData });
    }
};

const getCompanyService = (workspaceId: string, companyId: string) => {
    if (workspaceId && companyId) {
        const url = CLASS.getCompany(workspaceId, companyId);
        return axios.get(url);
    }
};

const createCompanyService = (workspaceId: string, bodyData: any) => {
    if (workspaceId) {
        const url = CLASS.createCompany(workspaceId);
        return axios.post(url, bodyData);
    }
};
const updateSessionsPatch = async (workspaceId: string, classId: string, sessionId: string, params: any) => {
    const url = CLASS.updateSessions(workspaceId, classId, sessionId);
    return axios.patch(url, params);
};

export default {
    addStudent,
    createClass,
    getCLassList,
    getClassDetail,
    updatePartialClass,
    updateClassStatus,
    getClassSession,
    createSession,
    inviteEmail,
    removeMemberClasses,
    deleteSessions,
    updateSessions,
    patchAssignmentGroups,
    patchAssignment,
    getAssignments,
    importAssignmentToClass,
    getAssignmentById,
    getAssignmentResultById,
    deleteAssignment,
    importCourseToSession,
    getGradebook,
    getAssignmentResultByStudentId,
    getGradebookApo,
    updateGradebook,
    getReportOfClass,
    getReportDetailsOfClass,
    getTeacherListInClassService,
    createProgressReportService,
    createGradebookReportService,
    getTeacherListService,
    getStudentListService,
    UpdateProgressReportService,
    getListRecordOfWorkService,
    updateRecordOfWorkService,
    UpdateGradebookReportService,
    getClassStudentsWithPointsService,
    updateClassStudentsRewardPointsService,
    getStudentApoHistoryListService,
    getStudentDetailService,
    addAposRewardService,
    getClaimHistoryListService,
    refundRewardService,
    claimService,
    getBannersService,
    getResourcesService,
    viewResourceService,
    deleteResourceService,
    renameResourceService,
    downloadResourceService,
    uploadResourcesService,
    getH5pFilesService,
    completeReportClass,
    deleteReportClass,
    publishReportClass,
    getReportVersion,
    getTeacherListOfStudentService,
    getTeacherDetailService,
    updateTeacherDetailService,
    getLanguagesService,
    getStudentPedCardsService,
    getCompanyListService,
    getCompanyService,
    getLanguagesOptionService,
    getCertificationOptionService,
    updateStudentDetailService,
    createCompanyService,
    updateSessionsPatch,
};
