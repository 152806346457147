import { Fragment, FC, useRef, useEffect, useCallback } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import {
    handleConfirmPasswordChange,
    handleNewPasswordChange,
    onSubmit,
} from './ChangePasswordModalFn';
import { PasswordContext } from 'contexts/Password/PasswordContext';
import { FORM_CONST } from 'constant/form.const';
import SavingChangesModal from '../SavingChangesModal/SavingChangesModal';
import { useTranslation } from 'react-i18next';
import DialogComponent from 'components/Dialog/DialogComponent';
import MatchChangePassword from 'components/Authentication/AuthenticationForgotPasswordForm/CreatePassword/MatchChangePassword';
import PasswordEyes from 'components/PasswordWithEyes/PasswordEye';

interface ChangePasswordModalProps {
    onClose: () => void;
    onUpdateSuccess: () => void;
}

const ChangePasswordModal: FC<ChangePasswordModalProps> = ({
    onClose,
    onUpdateSuccess,
}) => {
    const { t: translator } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
        trigger,
    } = useForm();
    const { dispatch, passwordState } = React.useContext(PasswordContext);
    const [apiError, setApiError] = React.useState<string>();
    const { isLoading, err, valErr, result: passwordResult } = passwordState;

    useEffect(() => {
        if (passwordResult?.email && passwordResult?.id) {
            onUpdateSuccess();
        }
        if (err) {
            setApiError(err.error.name ?? err.error.description);
        }
        if (valErr) {
            setApiError(valErr.validation_error.body_params[0].msg);
        }
    }, [passwordResult, err, valErr]);

    const onSubmitForm = (values: any) => {
        onSubmit(values.confirm_password, values.new_password, dispatch);
    };

    return (
        <>
            <DialogComponent
                isOpen={true}
                title={translator('ACCOUNT_SETTING.CHANGE_PASSWORD')}
                onCloseModal={onClose}
                child={
                    <form
                        className="flex flex-col"
                        onSubmit={handleSubmit(onSubmitForm)}
                    >
                        <div>
                            <PasswordEyes
                                formProps={register('new_password', {
                                    minLength: {
                                        value: 10,
                                        message: translator(
                                            'FORM_CONST.MIN_LENGTH'
                                        ),
                                    },
                                    maxLength: {
                                        value: 64,
                                        message: translator(
                                            'FORM_CONST.MAX_LENGTH'
                                        ),
                                    },
                                    required: {
                                        value: true,
                                        message: translator(
                                            'FORM_CONST.REQUIRED_FIELD'
                                        ),
                                    },
                                    pattern: {
                                        value: FORM_CONST.PASSWORD_REGEX,
                                        message: translator(
                                            'FORM_CONST.ONLY_USE_CHARACTERS_UPPERCASES_LOWER_CASE_NUMBERS_AND_SPACIAL_CHARACTERS'
                                        ),
                                    },
                                })}
                                onChange={handleNewPasswordChange(
                                    setValue,
                                    trigger
                                )}
                                placeholder={translator('PASSWORD')}
                                errorsField={!!errors?.new_password}
                                nameInput="new_password"
                            />
                            {/* @ts-ignore */}
                            <ErrorMessage
                                errors={errors}
                                name="new_password"
                                as="span"
                                className="text-red-500 text-xs font-semibold"
                            />
                        </div>
                        {/* @ts-ignore */}
                        <MatchChangePassword
                            textField={watch('new_password') || ''}
                        />

                        <div className="mt-4">
                            <PasswordEyes
                                formProps={register('confirm_password', {
                                    // minLength: {
                                    //     value: 10,
                                    //     message: translator(
                                    //         'FORM_CONST.MIN_LENGTH'
                                    //     ),
                                    // },
                                    // maxLength: {
                                    //     value: 64,
                                    //     message: translator(
                                    //         'FORM_CONST.MAX_LENGTH'
                                    //     ),
                                    // },
                                    // required: {
                                    //     value: true,
                                    //     message: translator(
                                    //         'FORM_CONST.REQUIRED_FIELD'
                                    //     ),
                                    // },
                                    validate: (value) => {
                                        return value === watch('new_password')
                                            ? true
                                            : translator(
                                                  'FORM_CONST.LOOK_LIKE_YOUR_NEW_PASSWORD_FIELDS_DONT_MATCH'
                                              );
                                    },
                                })}
                                onChange={handleConfirmPasswordChange(
                                    setValue,
                                    trigger
                                )}
                                placeholder={translator('CONFIRM_PASSWORD')}
                                errorsField={!!errors?.confirm_password}
                                nameInput="confirm_password"
                            />
                            {/* @ts-ignore */}
                            <ErrorMessage
                                errors={errors}
                                name="confirm_password"
                                as="span"
                                className="text-red-500 text-xs font-semibold"
                            />
                            {apiError && (
                                <span className="text-red-500 text-xs font-semibold">
                                    {apiError}
                                </span>
                            )}
                        </div>
                        <div className="mt-6 flex justify-end space-x-4">
                            <button
                                type="button"
                                className="rounded-lg bg-white border px-3 py-2 lg:py-1 text-ooolab_sm leading-ooolab_24px text-ooolab_dark_1 font-medium border-ooolab_dark_2 "
                                onClick={onClose}
                            >
                                {translator('ACCOUNT_SETTING.CANCEL')}
                            </button>
                            <button
                                type="submit"
                                className="rounded-lg bg-primary-500 px-3 py-2 lg:py-1 text-ooolab_sm leading-ooolab_24px text-white font-medium border-ooolab_dark_2 "
                            >
                                {translator('ACCOUNT_SETTING.SAVE')}
                            </button>
                        </div>
                    </form>
                }
            />
            <SavingChangesModal isLoading={isLoading} />
        </>
    );
};

export default ChangePasswordModal;
