import React from 'react';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import ReactTooltip from 'react-tooltip';
import EmptyIcon from 'assets/img/empty-state/user.png';
import moment from 'moment';
import { ButtonOutline } from '_shared';
import { ImportUserTypeConst } from './importUserType.const';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { convertFiletoBlobAndDownload } from 'utils/commonFun';
import { LoadingIcon } from 'assets/icon';

interface UserTableProps {
    workspaceId: string;
    dataTable: any;
    t: Function;
    loading?: boolean;
    refreshData?: any;
    location?: string
}

export const StatusUpload = ({ value, t }) => {
    if (value?.state === 'in_progress') {
        return <div className='flex item-center text-primary-500 text-sm gap-1'>
            <LoadingIcon /> <span>{t("UPLOAD_STATUS.UPLOADING")}</span>
        </div>
    }
    if (value?.state === 'success' && value?.result?.success == value?.result?.total) {
        return <div className='flex item-center text-green-500 text-sm'>
            <CheckCircleIcon className='w-5 h-5 mr-1' />
            {value?.result?.success || 0}/{value?.result?.total || 0} {t("UPLOAD_STATUS.UPLOADED")}
        </div>
    }
    return <div className='flex item-center text-red-500 text-sm'>
        <CheckCircleIcon className='w-5 h-5 mr-1' />
        {value?.result?.success || 0}/{value?.result?.total || 0} {t("UPLOAD_STATUS.UPLOADED")}
    </div>
}

const UserHistoryTable: React.FC<UserTableProps> = ({ workspaceId, dataTable, t, refreshData, location }) => {
    const columns = React.useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={t("USER.USER_HISTORY.FILE_NAME")} />,
                Cell: ({ row }) => {
                    return (
                        <div className="flex items-center space-x-2 py-2">
                            <div
                                className="text-ooolab_xs truncate px-2"
                                data-tip={row?.values?.configs?.csv_name?.replace('.csv', '')}
                                data-for={row.original?.id.toString() + 'name'}
                            >
                                {row?.values?.configs?.csv_name?.replace('.csv', '')}
                            </div>
                            {/* @ts-ignore */}
                            <ReactTooltip
                                place="bottom"
                                type="dark"
                                effect="float"
                                id={row.original?.id.toString() + 'name'}
                                getContent={(content) => <div className="w-56 h-auto">{content}</div>}
                                disable={row?.values?.configs?.csv_name?.replace('.csv', '')?.length < 18}
                            />
                        </div>
                    );
                },
                accessor: 'name',
                width: 140,
            },
            {
                Header: () => <RenderTableHeader value={t("USER.USER_HISTORY.TYPE")} />,
                Cell: ({ row }) => {
                    return <RenderTableCell value={ImportUserTypeConst[row?.values?.configs?.location]} />;
                },
                accessor: 'configs',
                width: 80,
            },
            {
                Header: () => <RenderTableHeader value={t("USER.USER_HISTORY.IMPORT_DATE_TIME")}/>,
                Cell: ({ row }) => {
                    return <RenderTableCell className="capitalize" value={moment(row?.values?.created_at).format('DD/MM/YYYY HH:mm')} />;
                },
                accessor: 'created_at',
                width: 100,
            },
            {
                Header: () => <RenderTableHeader value={t("USER.STATUS")} />,
                Cell: ({ row }) => {
                    return (
                        <StatusUpload value={row?.original} t={t}/>
                    );
                },
                accessor: 'status',
                width: 80,
            },
            {
                Header: () => <RenderTableHeader value={t("USER.ACTION")} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="CourseAction">
                            {row?.original?.result?.error_file ? <ButtonOutline type="button" size='M'
                                className='!border-primary-500 !text-primary-500'
                                onClick={() => {
                                    convertFiletoBlobAndDownload(row?.original?.result?.error_file, 
                                        row?.original?.configs?.location === 'clms' ? 'CLMS-Failed Upload List.csv': 'Learner Portal-Failed Upload List.csv');
                                }}>
                                {t("USER.USER_HISTORY.DOWNLOAD")}
                            </ButtonOutline> : null}
                        </div>
                    );
                },
                accessor: 'action',
                width: 35,
            },
        ],
        []
    );

    return (
        <>
            <Table data={dataTable} columns={columns} emptyIcon={EmptyIcon} emptyContent={'No History'} />
        </>
    );
};

export default UserHistoryTable;
