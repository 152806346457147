import React, { Dispatch, useContext, useEffect, useState } from 'react';
import ButtonComponent from 'components/Button/ButtonComponent';
import { useForm, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ClassService from 'services/class.service';
import ErrorMessageForm from 'components/ErrorMessageForm';
import { setEmptyOrStr } from 'constant/util.const';
import { get } from 'lodash';
import { UserContext } from 'contexts/User/UserContext';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import DialogComponent from 'components/Dialog/DialogComponent';
import GradeReport from './GradebookComponents/GradeReport';
import PublishSchedule from './GradebookComponents/PublishSchedule';
import classService from 'services/class.service';
import dayjs from 'dayjs';
import { checkAdminTeachersComments } from './GradebookComponents/constantsGradebook';
import { Toastify } from '_shared';

const styleBtn = `py-2 border  rounded-lg  hover:text-white text-base font-semibold`;

const renderCompleted = (instructor: any) => {
    return (
        <>
            {instructor.is_completed ? (
                <>
                    <img src={instructor?.avatar_url} alt="ava" className="w-6 h-6  rounded-full  mx-2" />
                    <div className="text-[#8083A3] text-xs">{instructor?.display_name} </div>
                </>
            ) : (
                ''
            )}
        </>
    );
};

const checkTeachersCompleted = (initValue: any, membership: any) => {
    let check = false;
    if (initValue?.comments && membership?.id) {
        const arrCompleted: any[] = initValue?.comments.filter((i) => i.is_completed && i?.id === membership?.user_id);
        if (arrCompleted?.length > 0) {
            check = true;
        }
    }
    return check;
};

interface CreateGradebookReportProps {
    detailStudent: any;
    setReloadData: React.Dispatch<React.SetStateAction<boolean>>;
    editId?: number;
    setStatusReport?: Dispatch<any>;
    statusReport?: any;
    studentName?: string;
    setPublishDate?: Dispatch<any>;
}

const CreateGradebookReport: React.FC<CreateGradebookReportProps> = ({
    detailStudent,
    setReloadData,
    editId = undefined,
    setStatusReport,
    statusReport = '',
    studentName = '',
    setPublishDate,
}) => {
    const workspace: any = useParams();
    const [loading, setLoading] = React.useState(false);
    const [selectTabs, setSelectTabs] = useState<number>(1);
    const [previewGradebook, setPreviewGradebook] = useState<boolean>(false);
    const [publishSchedule, setPublishSchedule] = useState<boolean>(false);
    const { t: translator } = useTranslation();
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const [listHistory, setListHistory] = useState<any>();
    const [dataVersion, setDataVersion] = useState<any>();
    const [haveHistory, setHaveHistory] = useState<boolean>(false);

    const {
        result: { membership },
    } = getWorkspaceDetailState;
    const {
        userState: { result },
    } = useContext(UserContext);
    const [initValue, setInitValue] = React.useState<{
        name?: string;
        listening_comment?: string;
        reading_comment?: string;
        speaking_comment?: string;
        writing_comment?: string;
        listening_comment_vn?: string;
        reading_comment_vn?: string;
        speaking_comment_vn?: string;
        writing_comment_vn?: string;
        comments?: any[];
        reportDate?: any;
        reportTime?: any;
        scheduleGradebook?: any;
        instructorComment?: string;
    }>({
        name: '',
        listening_comment: '',
        reading_comment: '',
        speaking_comment: '',
        writing_comment: '',
        listening_comment_vn: undefined,
        reading_comment_vn: undefined,
        speaking_comment_vn: undefined,
        writing_comment_vn: undefined,
        comments: [],
        reportDate: new Date(),
        reportTime: new Date(),
        scheduleGradebook: null,
        instructorComment: '',
    });

    const {
        register,
        handleSubmit,
        control,
        formState: { errors, dirtyFields },
        setValue,
        getValues,
        watch,
        clearErrors,
        setError,
        reset,
        trigger,
    } = useForm({
        defaultValues: React.useMemo(() => {
            return {
                ...initValue,
            };
        }, [initValue]),
    });

    const togglePreviewGradebook = () => {
        setPreviewGradebook(!previewGradebook);
    };

    const togglePublishSchedule = () => {
        setPublishSchedule(!publishSchedule);
    };

    useEffect(() => {
        reset(initValue);
    }, [initValue]);

    const handlePreviewReport = (i: any) => {
        if (i) {
            setDataVersion(i);
            togglePreviewGradebook();
        }
    };

    const { fields, replace } = useFieldArray({
        control,
        name: 'comments',
    });

    const { id: workspaceId, classId } = workspace;
    const { id, student } = detailStudent;

    const getTeachersInClassApi = () => {
        ClassService.getTeacherListOfStudentService(
            { workspaceId, classId, membershipId: student?.id },
            {
                skip_pagination: true,
            }
        ).then((res) => {
            setValue('comments', res?.data?.items);
        });
    };

    const createReportApi = (data: any) => {
        setLoading(true);
        ClassService.createGradebookReportService(
            {
                workspaceId,
                classId,
            },
            data
        )
            .then((res) => {
                setReloadData(true);
                Toastify.success();
            })
            .catch((err) => {
                console.log(err.response);
            })
            .finally(() => setLoading(false));
    };

    const updateGradebookReport = (data: any) => {
        setLoading(true);
        ClassService.UpdateGradebookReportService(
            {
                workspaceId,
                classId,
                reportId: editId,
            },
            data
        )
            .then((res) => {
                setReloadData(true);
                Toastify.success('Report successfully saved!');
            })
            .catch((err) => {
                if (err?.response?.data?.error?.code === 400) {
                    if (err?.response?.data?.error?.name === 'NOTHING_CHANGED') {
                        Toastify.error('Gradebook report not changed!');
                    }
                } else {
                    Toastify.error('Error occurred while saving');
                }
            })
            .finally(() => setLoading(false));
    };

    React.useEffect(() => {
        if (!editId) getTeachersInClassApi();
    }, []);

    React.useEffect(() => {
        if (editId) {
            ClassService.getReportDetailsOfClass(workspaceId, classId, editId)
                .then((res) => {
                    if (res) {
                        setInitValue({
                            ...initValue,
                            name: res?.name,
                            listening_comment: res?.data?.listening_comment,
                            reading_comment: res?.data?.reading_comment,
                            speaking_comment: res?.data?.speaking_comment,
                            writing_comment: res?.data?.writing_comment,
                            listening_comment_vn: res?.data?.listening_comment_vn,
                            reading_comment_vn: res?.data?.reading_comment_vn,
                            speaking_comment_vn: res?.data?.speaking_comment_vn,
                            writing_comment_vn: res?.data?.writing_comment_vn,
                            comments: res?.data?.comments,
                            instructorComment: res?.data?.comments[0],
                        });
                        setPublishDate(res?.published_at);
                        setStatusReport(res?.status);
                    }
                })
                .catch((err) => console.log(err));
            classService.getReportVersion(workspaceId, classId, editId, { skip_pagination: true }).then((res) => {
                if (res?.data?.items?.length > 0) {
                    setHaveHistory(true);
                }
            });
        }
    }, [editId]);

    const onSubmitForm = (values: any) => {
        const bodyData = {
            id,
            student_id: student.id,
            ...values,
            comments: [values?.instructorComment] || [],
        };
        delete bodyData?.instructorComment;

        const bodyDataWithUpdateGradebookReport = {
            ...values,
            comments: [values?.instructorComment] || [],
        };
        delete bodyDataWithUpdateGradebookReport?.instructorComment;

        if (editId) updateGradebookReport(bodyDataWithUpdateGradebookReport);
        else createReportApi(bodyData);
    };

    const handleCompleteReport = (reportId) => {
        classService
            .completeReportClass(workspaceId, classId, reportId)
            .then((res) => {
                setReloadData(true);
                Toastify.success();
            })
            .catch(() => Toastify.error());
    };

    const onCompleteReport = async (reportId) => {
        if (dirtyFields && Object.keys(dirtyFields).length) {
            const values = getValues();
            await onSubmitForm(values);
            setTimeout(() => {
                handleCompleteReport(reportId);
            }, 700);
        } else {
            handleCompleteReport(reportId);
        }
    };
    const handlePublishReport = (values: any, reportId: any) => {
        const date = dayjs(values.reportDate).format('YYYY-MM-DD');
        const time = dayjs(values.reportTime).format('HH:mm:ss');
        const dateTime = date + 'T' + time;
        const bodyData = { schedule_at: dateTime };
        classService
            .publishReportClass(workspaceId, classId, reportId, values?.scheduleGradebook.value === 1 ? {} : bodyData)
            .then((res) => {
                setReloadData(true);
                Toastify.success();
            })
            .catch((err) => {
                const error: any = get(err, 'response.data.error');
                if (error && error?.code === 400 && error?.name === 'INVALID_ACTION') {
                    Toastify.error('Invalid Report');
                } else Toastify.error();
            });
    };

    const onPublishReport = async (reportId) => {
        const values = getValues();
        if (values) {
            if (dirtyFields && Object.keys(dirtyFields).length) {
                await onSubmitForm(values);
                setTimeout(() => {
                    handlePublishReport(values, reportId);
                }, 700);
            } else {
                handlePublishReport(values, reportId);
            }
        }
    };

    useEffect(() => {
        if (selectTabs === 2) {
            classService
                .getReportVersion(workspaceId, classId, editId, { skip_pagination: true })
                .then((res) => setListHistory(res?.data));
        }
    }, [selectTabs]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmitForm)}>
                {editId ? (
                    <div className="flex ml-2 items-center mb-6">
                        <div className="bg-dark-300 w-1 h-1 rounded-full mr-2"></div>
                        <p className="text-xs font-semibold text-dark-300 flex items-center">
                            Completed: {initValue?.comments ? initValue?.comments.map((i) => renderCompleted(i)) : null}
                        </p>
                    </div>
                ) : null}

                {/* {selectTabs === 2 ? (
                    <div className="flex ml-2 items-center mb-6">
                        <div className="bg-dark-300 w-1 h-1 rounded-full mr-2"></div>
                        <p className="text-xs font-semibold text-dark-300">Version:</p>
                    </div>
                ) : null} */}
                {statusReport === 'publish' || haveHistory ? (
                    <div className="flex items-center justify-center ">
                        <button
                            className={`${selectTabs === 1 ? 'text-[#024FEE]' : 'text-dark-100 '} mr-5 text-base font-bold`}
                            onClick={() => {
                                setSelectTabs(1);
                            }}
                            type="button"
                            disabled={selectTabs === 1}
                        >
                            Information
                        </button>
                        <button
                            className={`${selectTabs === 2 ? 'text-[#024FEE]' : 'text-dark-100 '} text-base font-bold `}
                            onClick={() => {
                                setSelectTabs(2);
                            }}
                            type="button"
                            disabled={selectTabs === 2}
                        >
                            History
                        </button>
                    </div>
                ) : null}
                {selectTabs === 1 ? (
                    <>
                        <div className="grid grid-cols-1 pb-5">
                            <div>
                                <label htmlFor="reportName" className="block text-gray-900 font-semibold text-sm">
                                    {translator('GRADEBOOK_APOLLO.CREATE_REPORT.REPORT_NAME')}
                                </label>
                                <input
                                    type="text"
                                    id="reportName"
                                    {...register('name', {
                                        required: translator('FORM_CONST.REQUIRED_FIELD'),
                                        maxLength: {
                                            value: 1000,
                                            message: translator('FORM_CONST.MAX_LENGTH'),
                                        },
                                    })}
                                    className="w-full border rounded border-ooolab_gray_6 px-3 py-2 mt-1 text-sm font-normal text-ooolab_dark_1"
                                />
                                <ErrorMessageForm errors={errors} name={'name'} />
                            </div>
                            <div className="hidden lg:block"></div>
                        </div>
                        <div className=" grid grid-cols-1">
                            <div className="">
                                <div className="flex text-gray-900 font-semibold text-sm ">
                                    <label className=" truncate max-w-[320px]  ">
                                        {translator('GRADEBOOK_APOLLO.CREATE_REPORT.TEACHER_COMMENTS')}
                                    </label>
                                </div>
                                <textarea
                                    id={'instructorComment'}
                                    rows={4}
                                    cols={50}
                                    placeholder={translator('GRADEBOOK_APOLLO.CREATE_REPORT.TEACHER_IS_COMMENTS')}
                                    className="w-full border rounded border-ooolab_gray_6 px-3 py-2 mt-1 text-sm font-normal text-gray-900 outline-blue-200"
                                    {...register(`instructorComment`, {
                                        maxLength: {
                                            value: 1000,
                                            message: translator('FORM_CONST.MAX_LENGTH'),
                                        },
                                    })}
                                />
                                <ErrorMessageForm errors={errors} name={`instructorComment`} />
                            </div>
                        </div>
                        <h1 className="hidden text-ooolab_dark_1 font-semibold text-sm text-center my-5">
                            {translator('GRADEBOOK_APOLLO.CREATE_REPORT.SUMMATIVE_ASSESSMENT_COMMENTS')}
                        </h1>
                        <div>
                            <div className="hidden grid grid-cols-1 lg:grid-cols-2 lg:gap-5">
                                <div>
                                    <label htmlFor="Listening" className="block text-ooolab_dark_1 font-semibold text-sm">
                                        {translator('GRADEBOOK_APOLLO.CREATE_REPORT.LISTENING') + ` (EN)`}
                                    </label>
                                    <textarea
                                        id="Listening"
                                        rows={4}
                                        cols={50}
                                        placeholder={translator('GRADEBOOK_APOLLO.CREATE_REPORT.LISTENING')}
                                        className="w-full border rounded border-ooolab_gray_6 px-3 py-2 mt-1 text-sm font-normal text-ooolab_dark_1 outline-blue-200"
                                        {...register('listening_comment', {
                                            required: {
                                                value: false,
                                                message: translator('FORM_CONST.REQUIRED_FIELD'),
                                            },
                                            maxLength: {
                                                value: 1000,
                                                message: translator('FORM_CONST.MAX_LENGTH'),
                                            },
                                        })}
                                    />
                                    <ErrorMessageForm errors={errors} name={'listening_comment'} />
                                </div>
                                <div>
                                    <label htmlFor="Reading" className="block text-ooolab_dark_1 font-semibold text-sm">
                                        {translator('GRADEBOOK_APOLLO.CREATE_REPORT.READING') + ` (EN)`}
                                    </label>
                                    <textarea
                                        id="Reading"
                                        rows={4}
                                        cols={50}
                                        placeholder={translator('GRADEBOOK_APOLLO.CREATE_REPORT.READING')}
                                        className="w-full border rounded border-ooolab_gray_6 px-3 py-2 mt-1 text-sm font-normal text-ooolab_dark_1 outline-blue-200"
                                        {...register('reading_comment', {
                                            required: {
                                                value: false,
                                                message: translator('FORM_CONST.REQUIRED_FIELD'),
                                            },
                                            maxLength: {
                                                value: 1000,
                                                message: translator('FORM_CONST.MAX_LENGTH'),
                                            },
                                        })}
                                    />
                                    <ErrorMessageForm errors={errors} name={'reading_comment'} />
                                </div>
                            </div>
                            <div className="hidden file:grid grid-cols-1 lg:grid-cols-2 lg:gap-5 lg:mt-5">
                                <div>
                                    <label htmlFor="Listening_vn" className="block text-ooolab_dark_1 font-semibold text-sm">
                                        {translator('GRADEBOOK_APOLLO.CREATE_REPORT.LISTENING') + ` (VI)`}
                                    </label>
                                    <textarea
                                        id="Listening_vn"
                                        rows={4}
                                        cols={50}
                                        placeholder={translator('GRADEBOOK_APOLLO.CREATE_REPORT.LISTENING')}
                                        className="w-full border rounded border-ooolab_gray_6 px-3 py-2 mt-1 text-sm font-normal text-ooolab_dark_1 outline-blue-200"
                                        {...register('listening_comment_vn', {
                                            setValueAs: setEmptyOrStr,
                                            maxLength: {
                                                value: 1000,
                                                message: translator('FORM_CONST.MAX_LENGTH'),
                                            },
                                        })}
                                    />
                                    <ErrorMessageForm errors={errors} name={'listening_comment_vn'} />
                                </div>
                                <div>
                                    <label htmlFor="Reading" className="block text-ooolab_dark_1 font-semibold text-sm">
                                        {translator('GRADEBOOK_APOLLO.CREATE_REPORT.READING') + ` (VI)`}
                                    </label>
                                    <textarea
                                        id="Reading"
                                        rows={4}
                                        cols={50}
                                        placeholder={translator('GRADEBOOK_APOLLO.CREATE_REPORT.READING')}
                                        className="w-full border rounded border-ooolab_gray_6 px-3 py-2 mt-1 text-sm font-normal text-ooolab_dark_1 outline-blue-200"
                                        {...register('reading_comment_vn', {
                                            setValueAs: setEmptyOrStr,
                                            maxLength: {
                                                value: 1000,
                                                message: translator('FORM_CONST.MAX_LENGTH'),
                                            },
                                        })}
                                    />
                                    <ErrorMessageForm errors={errors} name={'reading_comment_vn'} />
                                </div>
                            </div>
                            <div className="hidden grid grid-cols-1 lg:grid-cols-2 lg:gap-5 lg:mt-5">
                                <div className="mt-5 lg:mt-0">
                                    <label htmlFor="Speaking" className="block text-ooolab_dark_1 font-semibold text-sm">
                                        {translator('GRADEBOOK_APOLLO.CREATE_REPORT.SPEAKING') + ` (EN)`}
                                    </label>
                                    <textarea
                                        id="Speaking"
                                        rows={4}
                                        cols={50}
                                        placeholder={translator('GRADEBOOK_APOLLO.CREATE_REPORT.SPEAKING')}
                                        className="w-full border rounded border-ooolab_gray_6 px-3 py-2 mt-1 text-sm font-normal text-ooolab_dark_1 outline-blue-200"
                                        {...register('speaking_comment', {
                                            required: {
                                                value: false,
                                                message: translator('FORM_CONST.REQUIRED_FIELD'),
                                            },
                                            maxLength: {
                                                value: 1000,
                                                message: translator('FORM_CONST.MAX_LENGTH'),
                                            },
                                        })}
                                    />
                                    <ErrorMessageForm errors={errors} name={'speaking_comment'} />
                                </div>
                                <div className="mt-5 lg:mt-0">
                                    <label htmlFor=" Writing" className="block text-ooolab_dark_1 font-semibold text-sm">
                                        {translator('GRADEBOOK_APOLLO.CREATE_REPORT.WRITING') + ` (EN)`}
                                    </label>
                                    <textarea
                                        id="Writing"
                                        rows={4}
                                        cols={50}
                                        placeholder={translator('GRADEBOOK_APOLLO.CREATE_REPORT.WRITING')}
                                        className="w-full border rounded border-ooolab_gray_6 px-3 py-2 mt-1 text-sm font-normal text-ooolab_dark_1 outline-blue-200"
                                        {...register('writing_comment', {
                                            required: {
                                                value: false,
                                                message: translator('FORM_CONST.REQUIRED_FIELD'),
                                            },
                                            maxLength: {
                                                value: 1000,
                                                message: translator('FORM_CONST.MAX_LENGTH'),
                                            },
                                        })}
                                    />
                                    <ErrorMessageForm errors={errors} name={'writing_comment'} />
                                </div>
                            </div>
                            <div className="hidden grid grid-cols-1 lg:grid-cols-2 lg:gap-5 lg:mt-5">
                                <div className="mt-5 lg:mt-0">
                                    <label htmlFor="Speaking" className="block text-ooolab_dark_1 font-semibold text-sm">
                                        {translator('GRADEBOOK_APOLLO.CREATE_REPORT.SPEAKING') + ` (VI)`}
                                    </label>
                                    <textarea
                                        id="Speaking"
                                        rows={4}
                                        cols={50}
                                        placeholder={translator('GRADEBOOK_APOLLO.CREATE_REPORT.SPEAKING')}
                                        className="w-full border rounded border-ooolab_gray_6 px-3 py-2 mt-1 text-sm font-normal text-ooolab_dark_1 outline-blue-200"
                                        {...register('speaking_comment_vn', {
                                            setValueAs: setEmptyOrStr,
                                            maxLength: {
                                                value: 1000,
                                                message: translator('FORM_CONST.MAX_LENGTH'),
                                            },
                                        })}
                                    />
                                    <ErrorMessageForm errors={errors} name={'speaking_comment_vn'} />
                                </div>
                                <div className="mt-5 lg:mt-0">
                                    <label htmlFor=" Writing" className="block text-ooolab_dark_1 font-semibold text-sm">
                                        {translator('GRADEBOOK_APOLLO.CREATE_REPORT.WRITING') + ` (VI)`}
                                    </label>
                                    <textarea
                                        id="Writing"
                                        rows={4}
                                        cols={50}
                                        placeholder={translator('GRADEBOOK_APOLLO.CREATE_REPORT.WRITING')}
                                        className="w-full border rounded border-ooolab_gray_6 px-3 py-2 mt-1 text-sm font-normal text-ooolab_dark_1 outline-blue-200"
                                        {...register('writing_comment_vn', {
                                            setValueAs: setEmptyOrStr,
                                            maxLength: {
                                                value: 1000,
                                                message: translator('FORM_CONST.MAX_LENGTH'),
                                            },
                                        })}
                                    />
                                    <ErrorMessageForm errors={errors} name={'writing_comment_vn'} />
                                </div>
                            </div>
                        </div>
                        <h1 hidden={fields.length === 0} className="hidden text-ooolab_dark_1 font-semibold text-sm text-center mt-5">
                            {translator('GRADEBOOK_APOLLO.CREATE_REPORT.TEACHER_COMMENTS')}
                        </h1>
                        <div hidden={fields.length === 0} className="hidden grid grid-cols-1 lg:grid-cols-2 lg:gap-x-5">
                            {fields.map((item, index) => (
                                <div key={item.id} className="mt-5 ">
                                    <div className="flex text-ooolab_dark_1 font-semibold text-sm ">
                                        <label htmlFor={'teachercomment' + item.id} className="  truncate max-w-[320px] ">
                                            {translator('GRADEBOOK_APOLLO.CREATE_REPORT.TEACHER') + ' ' + item.display_name + ` `}
                                        </label>
                                        <p>(EN)</p>
                                    </div>
                                    <textarea
                                        id={'teachercomment' + item.id}
                                        rows={4}
                                        cols={50}
                                        placeholder={translator('GRADEBOOK_APOLLO.CREATE_REPORT.TEACHER_IS_COMMENTS')}
                                        className="w-full border rounded border-ooolab_gray_6 px-3 py-2 mt-1 text-sm font-normal text-ooolab_dark_1 outline-blue-200"
                                        {...register(`comments.${index}.comment`, {
                                            maxLength: {
                                                value: 1000,
                                                message: translator('FORM_CONST.MAX_LENGTH'),
                                            },
                                        })}
                                    />
                                    <ErrorMessageForm errors={errors} name={`comments.${index}.comment`} />
                                </div>
                            ))}
                        </div>
                        <div hidden={fields.length === 0} className="hidden grid grid-cols-1 lg:grid-cols-2 lg:gap-x-5">
                            {fields.map((item, index) => (
                                <div key={item.id} className="mt-5">
                                    <div className="flex text-ooolab_dark_1 font-semibold text-sm ">
                                        <label htmlFor={'teachercomment' + item.id} className="  truncate max-w-[320px] ">
                                            {translator('GRADEBOOK_APOLLO.CREATE_REPORT.TEACHER') + ' ' + item.display_name + ` `}
                                        </label>
                                        <p>(VI)</p>
                                    </div>
                                    <textarea
                                        id={'teachercomment' + item.id}
                                        rows={4}
                                        cols={50}
                                        placeholder={translator('GRADEBOOK_APOLLO.CREATE_REPORT.TEACHER_IS_COMMENTS')}
                                        className="w-full border rounded border-ooolab_gray_6 px-3 py-2 mt-1 text-sm font-normal text-ooolab_dark_1 outline-blue-200"
                                        {...register(`comments.${index}.comment_vn`, {
                                            maxLength: {
                                                value: 1000,
                                                message: translator('FORM_CONST.MAX_LENGTH'),
                                            },
                                        })}
                                    />
                                    <ErrorMessageForm errors={errors} name={`comments.${index}.comment_vn`} />
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <div className=" max-h-[50vh] py-3 overflow-auto">
                        {listHistory?.items?.map((i) => (
                            <div className="grid grid-cols-3 gap-7 my-4 mx-5">
                                <div className="col-span-1">
                                    <div className="text-xs text-ooolab_dark_2 font-semibold">Published by</div>
                                    <div className="text-xs text-ooolab_dark_1 font-normal">{i?.publisher?.display_name}</div>
                                </div>
                                <div className="col-span-1">
                                    <div className="text-xs text-ooolab_dark_2 font-semibold">Publish date</div>
                                    <div className="text-xs text-ooolab_dark_1 font-normal">
                                        {dayjs(i?.published_at).tz(result?.time_zone).format('DD/MM/YYYY HH:mm')}
                                    </div>
                                </div>
                                <div className="col-span-1 flex justify-end">
                                    <button
                                        className={`${styleBtn} border-ooolab_selected_bar_item text-primary-500 hover:bg-[#2576B9] px-4`}
                                        type="button"
                                        onClick={() => handlePreviewReport(i)}
                                    >
                                        Preview
                                    </button>
                                </div>
                            </div>
                        ))}

                        <DialogComponent
                            isOpen={previewGradebook}
                            width={'min-w-ooolab_w_50'}
                            title={'Report Preview'}
                            stylesTitle={' !text-base text-gray-900'}
                            onCloseModal={togglePreviewGradebook}
                            maskClosable={true}
                            child={<GradeReport reportId={editId} studentName={studentName} dataVersion={dataVersion} />}
                            styles={'max-w-min'}
                        />
                    </div>
                )}
                {selectTabs === 1 ? (
                    <div className="mt-9 text-center">
                        {editId ? (
                            <div className="flex items-center justify-center">
                                <ButtonComponent
                                    title={'Save'}
                                    type="submit"
                                    classStyle={` text-xs font-semibold px-3 py-2 rounded mx-1 ${
                                        dirtyFields &&
                                        Object.keys(dirtyFields).length &&
                                        (checkAdminTeachersComments(initValue, membership) || membership?.role === 'admin')
                                            ? ' text-white bg-[#2576B9]'
                                            : ' text-white bg-[#9ca3af]'
                                    }`}
                                    disable={
                                        !(dirtyFields && Object.keys(dirtyFields).length) ||
                                        (!checkAdminTeachersComments(initValue, membership) && membership?.role !== 'admin')
                                    }
                                />

                                {membership?.role !== 'admin' ||
                                (membership?.role === 'admin' &&
                                    membership?.type === 'instructor' &&
                                    checkAdminTeachersComments(initValue, membership)) ? (
                                    <button
                                        className={`text-xs font-semibold px-3 py-2 rounded  text-white mx-1 ${
                                            checkTeachersCompleted(initValue, membership) ||
                                            !checkAdminTeachersComments(initValue, membership)
                                                ? 'bg-[#9ca3af] '
                                                : 'bg-[#2576B9] '
                                        }`}
                                        disabled={
                                            checkTeachersCompleted(initValue, membership) ||
                                            !checkAdminTeachersComments(initValue, membership)
                                        }
                                        type="button"
                                        onClick={() => onCompleteReport(editId)}
                                    >
                                        Complete
                                    </button>
                                ) : null}

                                {membership?.role === 'admin' ? (
                                    <button
                                        className={`text-xs font-semibold px-3 py-2 rounded  text-white  mx-1 ${
                                            statusReport === 'publish' ? 'bg-[#9ca3af] ' : 'bg-[#2576B9] '
                                        } `}
                                        onClick={togglePublishSchedule}
                                        type="button"
                                        disabled={statusReport === 'publish'}
                                    >
                                        Publish
                                    </button>
                                ) : null}

                                <a
                                    href="https://docs.google.com/forms/d/e/1FAIpQLSfFehSr9yZp81EcefQzbM2iLa8y-b_7NqVyAH_vOgTos4d30g/viewform"
                                    target="_blank"
                                    className="hidden text-xs font-semibold px-3 py-2 rounded  text-white bg-[#2576B9] mx-1"
                                >
                                    Tutoring Request
                                </a>
                            </div>
                        ) : (
                            <ButtonComponent
                                title={translator('GRADEBOOK_APOLLO.CREATE_REPORT.CREATE')}
                                type="submit"
                                classStyle="bg-[#2576B9] text-xs font-semibold px-3 py-2 text-white rounded"
                            />
                        )}
                    </div>
                ) : null}
            </form>

            {publishSchedule && (
                <DialogComponent
                    isOpen={publishSchedule}
                    width={'min-w-[20vw]'}
                    title={'Schedule Publish'}
                    onCloseModal={togglePublishSchedule}
                    maskClosable={true}
                    child={
                        <PublishSchedule
                            control={control}
                            register={register}
                            setValue={setValue}
                            trigger={trigger}
                            reportId={editId}
                            nameFields={'scheduleGradebook'}
                            onPublishReport={onPublishReport}
                            getValues={getValues}
                        />
                    }
                    styles={'max-w-min'}
                />
            )}
        </>
    );
};

export default CreateGradebookReport;
