import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { timeList } from "constant/workspace.const";
import classesService from "services/reports/classes-report.service";

import { ComboboxSelect } from "components/ComboboxSelect/ComboboxSelect";
import { CardContainer } from "components/Reports/components/CardContainer";
import { CardInfo } from "components/Reports/components/CardInfo";
import { Title } from "components/Reports/components/Title";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { timeConvert } from "utils/commonFun";
import DatePickerComponent from "components/Reports/components/DatePicker";
import moment from "moment";

export const ClassAssignmentsTimeSpent = () => {
    const { t } = useTranslation();
    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);
    const params: { id: string, classId: string } = useParams();
    const [loading, setLoading] = useState(true);
    const [assignments, setAssignments] = useState<any>();

    const getData = async () => {
        if (!startDate || !endDate) {
            return;
        }
        try {
            setLoading(true);
            const payload = {
                start_time: moment(new Date(startDate)).format('YYYY-MM-DD')+ 'T00:00:00.000',
                end_time: moment(new Date(endDate)).format('YYYY-MM-DD')+ 'T23:59:59.000',
                classId: params?.classId,
            }
            const response = await classesService.getClassAssignmentsById(params.id, payload);
            setAssignments(response?.data);
            setLoading(false);
        } catch {
            setLoading(false);
        }
    }

    const [startDate, setStartDate] = useState(new Date(moment().subtract(1, 'months').format('MM-DD-YYYY')));
    const [endDate, setEndDate] = useState(new Date());
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    useEffect(() => {
        getData();
    }, [startDate, endDate]);

    return <>
        <CardContainer>
            <div className="flex items-center">
                <Title>{translator('Time Spent')}</Title>
                <div className="ml-auto min-w-[132px]">
                    <DatePickerComponent onChange={onChange} startDate={startDate} endDate={endDate} />
                </div>
            </div>
            <div className="mt-4 min-h-[123px]">
                {loading ?
                    <div className="flex justify-center items-center w-full min-h-[123px]">
                        <CircleSpin color="text-primary-500" />
                    </div> :
                    <>
                        <div className="flex space-x-5">
                            <div className="w-1/3">
                                <CardInfo  {...{
                                    value: timeConvert((assignments?.avg_time_spent || 0)/60),
                                    percent: assignments?.avg_time_spent_changes_percent || 0,
                                    title: `${translator('Avg Time Spent')}`,
                                    content: 'Time Spent currently records all time spent specifically doing H5P content'
                                } as any} />
                            </div>
                            <div className="w-1/3">
                                <CardInfo  {...{
                                    value: timeConvert((assignments?.total_time_spent || 0)/60),
                                    percent: assignments?.total_time_spent_changes_percent || 0,
                                    title: `${translator('Total Time Spent')}`,
                                    content: 'Time Spent currently records all time spent specifically doing H5P content'
                                } as any} />
                            </div>
                            <div className="w-1/3"></div>
                        </div>
                    </>
                }
            </div>
        </CardContainer>
    </>
}