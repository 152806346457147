import React from 'react';
import ManagementMasterPage from 'pages/RouteMasterPage';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';
import UserDetail from 'components/Management/User/UserDetail/UserDetail';

const UserDetailPage = () => {
    return (
        <ManagePermissionWrapper>
            <UserDetail>
                <ManagementMasterPage />
            </UserDetail>
        </ManagePermissionWrapper>
    );
};

export default UserDetailPage;
