import * as React from 'react';
import AccountSettingForm from 'components/User/AccountSettingForm';
import UpdateUserProvider from 'contexts/User/UpdateUserProvider';

const UserAccountSettingPage = () => {
    return (
        <UpdateUserProvider>
            <AccountSettingForm />
        </UpdateUserProvider>
    );
};

export default UserAccountSettingPage;
