import "./BookingDetails.scss";

export const BookingLearner = ({data}) => {
    return (<>
        <div className="bookingDetails-learner-avatar bg-cover" 
            style={{backgroundImage: `url(${data?.avatar_url})`}}>
        </div>
        <div className="bookingDetails-learner-info flex items-center">
            <div className="bookingDetails-name">
                {data?.display_name}
            </div>
            <div className="bookingDetails-available">
                <div>{data?.name}</div>
                Available: <span>{data?.credit_balance?.available_credit} {data?.credit_balance?.available_credit > 1 ? 'Credits' : 'Credit'}</span>
            </div>
        </div>
    </>)
}