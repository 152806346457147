import React, { useState, useEffect } from "react";
const PADDING = 180;
export const AspectRatioBox = ({children}: {children: React.ReactNode}) => {
  const [height, setHeight] = useState(window.innerHeight - PADDING);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const calculateDimensions = () => {
      // Update height and calculate width using 16:9 aspect ratio
      const screenHeight = window.innerHeight - PADDING;
      setHeight(screenHeight);
      const newWidth = screenHeight * (16 / 9);
      setWidth(newWidth);
    };

    // Add event listener to handle window resize
    window.addEventListener("resize", calculateDimensions);

    // Initial calculation
    calculateDimensions();

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", calculateDimensions);
    };
  }, []);

  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`
      }}
    >
      {children}
    </div>
  );
};