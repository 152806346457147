export const HOST_URL = process.env.REACT_APP_API_ENDPOINT;

// *** USER *** //
export const USER = {
    CREATE_TEMPORARY_USER: '/users',
    CREATE_NEW_PASSWORD: '/users/me/password',
    UPDATE_PASSWORD: '/users/me/password',
    GET_USER: '/users/me',
    UPDATE_USER: '/users/me',
    UPLOAD_AVATAR: '/resources/upload-url',
    UPLOAD_AVATAR_FORM_DATA: 'https://cdn-crisp-tarpon.s3.amazonaws.com/',
    UPLOAD_AVATR_FINAL: '/users/me/avatar',
};

// *** AUTHENTICATE *** //
export const AUTH = {
    VERIFY_EMAIL: '/auth/email',
    LOGIN: '/auth/login',
    FORGOT_PASSWORD: '/auth/forgot-password',
    RESET_PASSWORD: '/auth/reset-password',
    LOGOUT: '/auth/logout',
    OAUTHGG: '/oauth2/google/auth',
    OAUTHFB: '/oauth2/facebook/auth',
    VERIFY_TOKEN_RESET_PASSWORD: '/auth/token',
};

// *** WORKSPACE *** //
export const WORKSPACE = {
    GET_CURRENT_USER_OF_WORKSPACE: '/workspaces/me',
    CREATE_WORKSPACE: '/workspaces',
    INVITE_WORKSPACE: (workspaceId: number) => `/workspaces/${workspaceId}/members`,
    GET_WORKSPACE_DETAIL: (workspaceId: string) => `/workspaces/${workspaceId}`,
    GET_WORKSPACE_MEMBERS: (workspaceId?: string) => `/workspaces/${workspaceId}/members`,
    CREATE_TEACHER: (workspaceId: string) => `/workspaces/${workspaceId}/instructors`,
    AUTH_WORKSPACES: (workspaceId: string) => `/auth/workspaces/${workspaceId}`,
    PERMISSION_WORKSPACES: '/users/me/permissions',
    CURRENCY: '/metadata/currencies',
};

// *** GOOGLE API *** //
export const GOOGLE_SERVER_SIDE = {
    LOCAL_STORAGE: {
        GOOGLE_REDIRECT_URL_WORKSPACE_ID: 'google_redirect_url_workspace_id',
        GOOGLE_OAUTH_TOKEN: 'google_oauth_token',
    },
    OAUTH2: {
        GET_DRIVE_AUTHENTICATE_URL: (workspaceId: string) => `/workspaces/${workspaceId}/drive-auth`,
        AUTHENTICATE_WITH_DRIVE: (workspaceId: string) => `/workspaces/${workspaceId}/drive-auth`,
        GET_DRIVE_CREDENTIALS_OF_WORKSPACE: (workspaceId: string) => `/workspaces/${workspaceId}/drive-credentials`,
    },
    DRIVER: {
        URL: 'https://www.googleapis.com/drive/v3/files/',
    },
};

export const ERROR_LOGOUT_MSG = {
    COULD_NOT_VERIFY_AUTHORIZED: `The server could not verify that you are authorized to access the URL requested. You either supplied the wrong credentials (e.g. a bad password), or your browser doesn't understand how to supply the credentials required.`,
};

// *** H5P *** //
export const H5P = {
    root: (workpspaceId: string) => `/h5p/workspaces/${workpspaceId}`,
    play: (contentId: string) => `/${contentId}/play`,
    delete: (contentId: string) => `/${contentId}/trash`,
    save: (contentId: string) => `/${contentId}`,
    edit: (contentId: string) => `/${contentId}/edit`,
    download: (contentId: string) => `/download/${contentId}`,
    approveContent: (contentId: string) => `/${contentId}/approve`,
    uidPlay: (contentUid: string) => `/h5p/${contentUid}/play`,
    addTags: (contentId: string) => `/${contentId}/tags`,
    Tags: (contentId: string) => `/${contentId}/tags`,
    downloadContent: (workpspaceId: string, contentId: string) => `/h5p/workspaces/${workpspaceId}/${contentId}/download`,
};

export const CLASS = {
    create: (workspaceId: string) => `/workspaces/${workspaceId}/classes`,
    getList: (workspaceId: string) => `/workspaces/${workspaceId}/classes`,
    getDetail: (workspaceId: string, classId: string) => `/workspaces/${workspaceId}/classes/${classId}`,
    update: (workspaceId: string, classId: string) => `/workspaces/${workspaceId}/classes/${classId}`,
    delete: (workspaceId: string, classId: string) => `/workspaces/${workspaceId}/classes/${classId}/status`,
    deactive: (workspaceId: string, classId: string) => `/workspaces/${workspaceId}/classes/${classId}/status`,
    getListSession: (workspaceId: string, classId: string) => `/workspaces/${workspaceId}/classes/${classId}/sessions`,
    getClassMembersUrl: (workspaceId: string, classId: string, sessionId: string) =>
        `/workspaces/${workspaceId}/classes/${classId}/sessions/${sessionId}/members`,
    inviteMembers: (workspaceId: string, classId: string) => `/workspaces/${workspaceId}/classes/${classId}/members`,
    removeMembers: (workspaceId: string, classId: string, userId: number) =>
        `/workspaces/${workspaceId}/classes/${classId}/members/${userId}`,
    deleteSession: (workspaceId: string, classId: string, sessionId: string) =>
        `/workspaces/${workspaceId}/classes/${classId}/sessions/${sessionId}`,
    updateSessions: (workspaceId: string, classId: string, sessionId: string) =>
        `/workspaces/${workspaceId}/classes/${classId}/sessions/${sessionId}`,
    createSession: (workspaceId: string, classId: string) => `/workspaces/${workspaceId}/classes/${classId}/sessions`,
    getAssignments: (workspaceId: string, classId: string) => `/workspaces/${workspaceId}/classes/${classId}/assignments`,
    deleteAssignment: (workspaceId: string, classId: string, assignmentId: number) =>
        `/workspaces/${workspaceId}/classes/${classId}/assignments/${assignmentId}`,
    updateAssignment: (workspaceId: string, classId: string, assignmentId: string) =>
        `/workspaces/${workspaceId}/classes/${classId}/assignments/${assignmentId}`,
    importAssignment: (workspaceId: string, classId: string) => `/workspaces/${workspaceId}/classes/${classId}/assignments/import`,
    getAssignmentById: (workspaceId: string, classId: string, assignmentId: string) =>
        `/workspaces/${workspaceId}/classes/${classId}/assignments/${assignmentId}`,
    patchGroup: (workspaceId: string, class_id: string, assignment_group_id: string) =>
        `/workspaces/${workspaceId}/classes/${class_id}/assignment-groups/${assignment_group_id}`,
    getAssignmentResult: (workspaceId: string, classId: string, assignmentId: string) =>
        `/workspaces/${workspaceId}/classes/${classId}/assignments/${assignmentId}/results`,
    importCourse: (workspaceId: string, classId: string) => `/workspaces/${workspaceId}/classes/${classId}/sessions/course`,
    getGradebook: (workspaceId: string, classId: string) => `/workspaces/${workspaceId}/classes/${classId}/gradebook`,
    getGradebookApo: (workspaceId: string, classId: string) => `/workspaces/${workspaceId}/classes/${classId}/gradebooks`,
    getAsignmentResultByStudentId: (workspaceId: string, classId: string, assignmentId: string, studentId: string) =>
        `/workspaces/${workspaceId}/classes/${classId}/assignments/${assignmentId}/results/${studentId}`,
    updateGradebook: (workspaceId: string, classId: string, gradebookId: number) =>
        `/workspaces/${workspaceId}/classes/${classId}/gradebooks/${gradebookId}`,
    getReportOfClass: (workspaceId: string, classId: string) => `/workspaces/${workspaceId}/classes/${classId}/reports`,
    getReportDetailsOfClass: (workspaceId: string, classId: string, reportId: number) =>
        `/workspaces/${workspaceId}/classes/${classId}/reports/${reportId}`,
    getTeachersInClass: (workspaceId: string, classId: string) => `/workspaces/${workspaceId}/classes/${classId}/members`,
    getTeachersOfStudentInClass: (workspaceId: string, classId: string, membershipId: string) =>
        `/workspaces/${workspaceId}/classes/${classId}/members/${membershipId}/instructors`,
    createProgressReport: (workspaceId: string, classId: string) => `/workspaces/${workspaceId}/classes/${classId}/reports/progress`,
    createGradebookReport: (workspaceId: string, classId: string) => `/workspaces/${workspaceId}/classes/${classId}/reports/gradebook`,
    getTeachers: (workspaceId: string) => `/workspaces/${workspaceId}/instructors`,
    createTeacher: (workspaceId: string) => `/workspaces/${workspaceId}/instructors`,
    getStudents: (workspaceId: string) => `/workspaces/${workspaceId}/students`,
    updateProgressReport: (workspaceId: string, classId: string, reportId: string) =>
        `/workspaces/${workspaceId}/classes/${classId}/reports/progress/${reportId}`,
    updateGradebookReport: (workspaceId: string, classId: string, reportId: string) =>
        `/workspaces/${workspaceId}/classes/${classId}/reports/gradebook/${reportId}`,
    getRecordsOfWorkFromClass: (workspaceId: string, classId: string) =>
        `/workspaces/${workspaceId}/classes/${classId}/sessions/records`,
    updateRecordsOfWorkFromClass: (workspaceId: string, classId: string, sessionId: string) =>
        `/workspaces/${workspaceId}/courses/${classId}/sessions/${sessionId}/past`,
    getRecordsOfWorkSessionFromClass: (workspaceId: string, classId: string, sessionId: string) =>
        `/workspaces/${workspaceId}/classes/${classId}/sessions/${sessionId}/records`,
    getClassStudentsWithPoints: (workspaceId: string, classId: string) => `/workspaces/${workspaceId}/classes/${classId}/students`,
    updateClassStudentsRewardPoints: (workspaceId: string, classId: string) => `/workspaces/${workspaceId}/classes/${classId}/points`,
    getStudentApoHistoryList: (workspaceId: string, studentId: string) =>
        `/workspaces/${workspaceId}/students/${studentId}/received-points`,
    getStudentDetail: (workspaceId: string, studentId: string) => `/workspaces/${workspaceId}/students/${studentId}`,
    getStudentDetailPoint: (workspaceId: string, studentId: string) => `/workspaces/${workspaceId}/students/${studentId}/points`,
    getStudentPedCards: (workspaceId: string, studentId: string) => `/workspaces/${workspaceId}/students/${studentId}/ped-cards`,
    getTeacherDetail: (workspaceId: string, teacherId: string) => `/workspaces/${workspaceId}/instructors/${teacherId}`,
    updateTeacherDetail: (workspaceId: string, teacherId: string) => `/workspaces/${workspaceId}/instructors/${teacherId}`,
    updateStudentDetail: (workspaceId: string, studentId: string) => `/workspaces/${workspaceId}/students/${studentId}`,
    getLanguages: (workspaceId: string) => `/workspaces/${workspaceId}/instructors/languages`,
    getLanguagesOption: (workspaceId: string) => `/workspaces/${workspaceId}/languages`,
    getCertificationOption: (workspaceId: string) => `/workspaces/${workspaceId}/certifications`,
    addAposReward: (workspaceId: string, studentId: string) => `/workspaces/${workspaceId}/students/${studentId}/points`,
    getClaimHistoryList: (workspaceId: string, studentId: string) =>
        `/workspaces/${workspaceId}/students/${studentId}/claim-histories`,
    refundReward: (workspaceId: string, rewardId: string, rewardClaimId: string) =>
        `/workspaces/${workspaceId}/rewards/${rewardId}/claim-histories/${rewardClaimId}/refund`,
    claimReward: (workspaceId: string, rewardId: string, rewardClaimId: string) =>
        `/workspaces/${workspaceId}/rewards/${rewardId}/claim-histories/${rewardClaimId}/confirm`,
    getBanners: (workspaceId: string) => `/workspaces/${workspaceId}/banners`,
    getAssignmentTemplates: (workspaceId: string) => `/workspaces/${workspaceId}/lessons/assignment-templates`,
    getAndUploadResourcesUrl: (workspaceId: string, classId: string) => `/workspaces/${workspaceId}/classes/${classId}/resources`,
    detailResourceUrl: (workspaceId: string, classId: string, resourceId: number) =>
        `/workspaces/${workspaceId}/classes/${classId}/resources/${resourceId}`,
    renameResourceUrl: (workspaceId: string, classId: string, resourceId: number) =>
        `/workspaces/${workspaceId}/classes/${classId}/resources/${resourceId}/name`,
    completeReportClass: (workspaceId: string, classId: string, reportId: string) =>
        `/workspaces/${workspaceId}/classes/${classId}/reports/${reportId}/complete`,
    reportClass: (workspaceId: string, classId: string, reportId: string) =>
        `/workspaces/${workspaceId}/classes/${classId}/reports/${reportId}`,
    publishReportClass: (workspaceId: string, classId: string, reportId: string) =>
        `/workspaces/${workspaceId}/classes/${classId}/reports/${reportId}/publish`,
    reportVersionClass: (workspaceId: string, classId: string, reportId: string) =>
        `/workspaces/${workspaceId}/classes/${classId}/reports/${reportId}/publish/versions`,
    getCompanyList: (workspaceId: string) => `/workspaces/${workspaceId}/companies`,
    getCompany: (workspaceId: string, companyId: string) => `/workspaces/${workspaceId}/companies/${companyId}`,
    createCompany: (workspaceId: string) => `/workspaces/${workspaceId}/companies`,
    getClassMembers: (workspaceId: string, classId: string) => `/workspaces/${workspaceId}/classes/${classId}/members`,
};

export const BANNER = {
    createBanner: (workspaceId: string) => `/workspaces/${workspaceId}/banners`,
    updateBanner: (workspaceId: string, bannerId: string) => `/workspaces/${workspaceId}/banners/${bannerId}`,
    getBannerDetail: (workspaceId: string, bannerId: string) => `/workspaces/${workspaceId}/banners/${bannerId}`,
};

export const GROUP = {
    getGroups: (workspaceId: string) => `/workspaces/${workspaceId}/groups`,
    getClassSelected: (workspaceId: string, groupId: string) => `/workspaces/${workspaceId}/groups/${groupId}/classes`,
    getPeopleSelected: (workspaceId: string, groupId) => `/workspaces/${workspaceId}/groups/${groupId}/people`,
    createGroup: (workspaceId: string) => `/workspaces/${workspaceId}/groups`,
    deleteGroup: (workspaceId: string, id: string) => `/workspaces/${workspaceId}/groups/${id}`,
    getGroupDetail: (workspaceId: string, id: string) => `/workspaces/${workspaceId}/groups/${id}`,
    addClass: (workspaceId: string, groupId: string) => `/workspaces/${workspaceId}/groups/${groupId}/classes`,
    addPeople: (workspaceId: string, groupId: string) => `/workspaces/${workspaceId}/groups/${groupId}/people`,
    removeClass: (workspaceId: string, groupId: string, classId: string) =>
        `/workspaces/${workspaceId}/groups/${groupId}/classes/${classId}`,
    removePeople: (workspaceId: string, groupId: string, personId: string) =>
        `/workspaces/${workspaceId}/groups/${groupId}/people/${personId}`,
    updateGroup: (workspaceId: string, groupId: string) => `/workspaces/${workspaceId}/groups/${groupId}`,
};

export const REPORT = {
    getGeneral: (workspaceId: string) => `/workspaces/${workspaceId}/reports/dashboards/general`,
    getTeacher: (workspaceId: string) => `/workspaces/${workspaceId}/reports/dashboards/instructor`,
    getGeneralReportClasses: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/classes/general`,
    getHomeLearningReportClasses: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/classes/home-learning`,
    getGeneralReportsUrl: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/classes/general`,
    getHomeLearningReportsUrl: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/classes/home-learning`,

    getOverviewReportClasses: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/classes/overview`,

    //student dashboard
    getOverviewReportStudents: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/students/overviews`,
    getHomeLearningWorkbokStudents: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/students/home-learning/workbooks`,
    getDataActiveReviewStudent: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/students/home-learning/active-review`,
    getDataAttendanceStudents: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/students/attendances`,
    getGeneralReportStudent: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/students/general`,
    getHomeLearningReportStudents: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/students/home-learning`,

    //instructor dashboard
    getGeneralReportTeachers: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/instructors/general`,
    getOverviewReportTeachers: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/instructors/overview`,

    // Specific Class dashboard
    getClassAssignmentReportsUrl: (workspaceId: string, classId: string) =>
        `/workspaces/${workspaceId}/dashboards/classes/${classId}/assignments`,
    getClassGeneralReportsUrl: (workspaceId: string, classId: string) =>
        `/workspaces/${workspaceId}/dashboards/classes/${classId}/general`,
    getClassHomeLearningReportsUrl: (workspaceId: string, classId: string) =>
        `/workspaces/${workspaceId}/dashboards/classes/${classId}/home-learning`,
    getClassOverviewReportsUrl: (workspaceId: string, classId: string) =>
        `/workspaces/${workspaceId}/dashboards/classes/${classId}/overview`,
    getClassStudentAssignmentReportsUrl: (workspaceId: string, classId: string) =>
        `/workspaces/${workspaceId}/dashboards/classes/${classId}/student-assignments`,
    getClassAttendanceReportsUrl: (workspaceId: string, classId: string) =>
        `/workspaces/${workspaceId}/dashboards/classes/${classId}/attendances`,

    // Specific Student dashboard
    getSpecificStudentOverviewsUrl: (workspaceId: string, studentId: string) =>
        `/workspaces/${workspaceId}/dashboards/learners/${studentId}/overviews`,
    getSpecificStudentAssignmentsUrl: (workspaceId: string, studentId: string) =>
        `/workspaces/${workspaceId}/dashboards/students/${studentId}/assignments`,
    getSpecificStudentAttendancesUrl: (workspaceId: string, studentId: string) =>
        `/workspaces/${workspaceId}/dashboards/students/${studentId}/attendances`,
    getSpecificStudentActiveReviewUrl: (workspaceId: string, studentId: string) =>
        `/workspaces/${workspaceId}/dashboards/students/${studentId}/active-review`,
    getSpecificStudentWorkbooksUrl: (workspaceId: string, studentId: string) =>
        `/workspaces/${workspaceId}/dashboards/students/${studentId}/workbooks`,
    getSpecificStudentReportsUrl: (workspaceId: string, studentId: string) =>
        `/workspaces/${workspaceId}/dashboards/students/${studentId}/reports`,
    getSpecificStudentReportDetailUrl: (workspaceId: string, studentId: string, reportId: string) =>
        `/workspaces/${workspaceId}/dashboards/students/${studentId}/reports/${reportId}`,

    //Workspace Dashboard
    getGroupClassPerformance: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/groups/class-performance`,
    getGroupFeedbacks: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/groups/feedbacks`,
    getGroup: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/groups`,
    getWorkspacesOverview: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/workspaces/overview`,
    getWorkspacesAcademics: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/workspaces/academics`,
    getWorkspacesSessions: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/workspaces/sessions`,
    getClassDashboard: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/courses`,
    getStudentDashboard: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/students`,
    getLearnerDashboard: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/learners`,
    getActivitiesDashBoard: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/activities`,

    // Download Page
    getLearnersInformationDashboard: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/export/learners/information`,
    getLearnersSessionsDashboard: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/export/learners/sessions`,
    getLearnersCreditPackagesDashboard: (workspaceId: string) =>
        `/workspaces/${workspaceId}/dashboards/export/learners/credit-packages`,
    getLearnersInvoicesDashboard: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/export/learners/invoices`,
    getInstructorsInformationDashboard: (workspaceId: string) =>
        `/workspaces/${workspaceId}/dashboards/export/instructors/information`,
    getInstructorsSessionDashboard: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/export/instructors/sessions`,
    getTransactionsCreditAccountDashboard: (workspaceId: string) =>
        `/workspaces/${workspaceId}/dashboards/export/learners/credit-accounts/transactions`,
    getTransactionsCreditBalanceDashboard: (workspaceId: string) =>
        `/workspaces/${workspaceId}/dashboards/export/learners/enrollment-credit-balances/transactions`,
    getGradeDashboard: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/export/learners/assignment-grades`,
    getCoursesDashboard: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/export/courses/information`,
    getSessionsDashboard: (workspaceId: string) => `/workspaces/${workspaceId}/dashboards/export/courses/sessions`,
};

export const TEACHER_DASHBOARD = {
    getListTodayUrl: (workspaceId: string) => `/workspaces/${workspaceId}/instructors/sessions`,
    getListUpcomingUrl: (workspaceId: string) => `/workspaces/${workspaceId}/instructors/sessions/date`,
    getListPastUrl: (workspaceId: string) => `/workspaces/${workspaceId}/instructors/sessions/date`,
    getListAttendanceUrl: (workspaceId: string, classId: string, sessionId: string) =>
        `/workspaces/${workspaceId}/courses/${classId}/sessions/${sessionId}/attendances`,
    getSessionDetail: (workspaceId: string, classId: string, sessionId: string) =>
        `/workspaces/${workspaceId}/courses/${classId}/sessions/${sessionId}`,
    updateListAttendanceUrl: (workspaceId: string, classId: string, sessionId: string) =>
        `/workspaces/${workspaceId}/courses/${classId}/sessions/${sessionId}/attendances`,
    getVcLinkUrl: (workspaceId: string, classId: number, sessionId: number) =>
        `/workspaces/${workspaceId}/classes/${classId}/sessions/${sessionId}/vc-link`,
    getVcRecord: (workspaceId: string, classId: number, sessionId: number) =>
        `/workspaces/${workspaceId}/classes/${classId}/sessions/${sessionId}/vc-records`,
    // availability dashboard
    getAvailableSlotsUrl: (workspaceId: string) => `/workspaces/${workspaceId}/learning-paths/available-slots`,
    createAvailableSlotUrl: (workspaceId: string, learningPathId: string) =>
        `/workspaces/${workspaceId}/learning-paths/${learningPathId}/available-slots`,
    updateAvailableSlotUrl: (workspaceId: string, learningPathId: string, availableSlotId: string) =>
        `/workspaces/${workspaceId}/learning-paths/${learningPathId}/available-slots/${availableSlotId}`,
    updateStatusAvailableSlotUrl: (workspaceId: string, learningPathId: string, availableSlotId: string) =>
        `/workspaces/${workspaceId}/learning-paths/${learningPathId}/available-slots/${availableSlotId}/status`,
    getLearningPathSessionsUrl: (workspaceId: string) => `/workspaces/${workspaceId}/learning-paths/sessions`,
    createOneTimeEvent: (workspaceId: string, teacherId: string) =>
        `/workspaces/${workspaceId}/instructors/${teacherId}/events/create`,
    getInstructorTimeEvents: (workspaceId: string, teacherId: string) =>
        `/workspaces/${workspaceId}/instructors/${teacherId}/events/get`,
    getLanguagesTeacher: (workspaceId: string, teacherId: string) => `/workspaces/${workspaceId}/instructors/${teacherId}/languages`,
    removeEventTeacher: (workspaceId: string, teacherId: string, eventId) =>
        `/workspaces/${workspaceId}/instructors/${teacherId}/events/${eventId}/calendar-time-ranges/delete`,
    getIntructorSessions: (workspaceId: string) => `/workspaces/${workspaceId}/instructors/sessions`,
    getInstructorDashboardBanner: (workspaceId: string) => `/workspaces/${workspaceId}/instructors/banners`,
    getNotifications: (workspaceId: string) => `/workspaces/${workspaceId}/notifications`,
    getNotificationDetail: (workspaceId: string, notificationId) => `/workspaces/${workspaceId}/notifications/${notificationId}`,
    clickNotification: (workspaceId: string, notificationId: string) =>
        `/workspaces/${workspaceId}/notifications/${notificationId}/click`,
    readAllNotification: (workspaceId: string) => `/workspaces/${workspaceId}/notifications/read`,
    getStisticNotification: (workspaceId: string) => `/workspaces/${workspaceId}/notifications/stat`,
};

export const RESOURCES = {
    getListResources: (workspaceId: string) => `/workspaces/${workspaceId}/resources`,
    updateResources: (workspaceId: string, id: string) => `/workspaces/${workspaceId}/resources/${id}/name`,
    resourcesById: (workspaceId: string, id: string) => `/workspaces/${workspaceId}/resources/${id}`,
    resourcesUpload: () => '/resources/multiple-upload-urls',
};

export const REWARDS = {
    getListRewards: (workspaceId: string) => `/workspaces/${workspaceId}/rewards`,
    getListRewardsHistory: (workspaceId: string, id: string) => `/workspaces/${workspaceId}/rewards/${id}/claim-histories`,
    confirmRewardClaim: (workspaceId: string, rewardId: string, rewardClaimId: string) =>
        `/workspaces/${workspaceId}/rewards/${rewardId}/claim-histories/${rewardClaimId}/confirm`,
    refundRewardClaim: (workspaceId: string, rewardId: string, rewardClaimId: string) =>
        `/workspaces/${workspaceId}/rewards/${rewardId}/claim-histories/${rewardClaimId}/refund`,
    getReward: (workspaceId: string, rewardId: string) => `/workspaces/${workspaceId}/rewards/${rewardId}`,
    updateRewardLevelByBatch: (workspaceId: string) => `/workspaces/${workspaceId}/reward-levels/batch`,
    getRewardLevels: (workspaceId: string) => `/workspaces/${workspaceId}/reward-levels`,
    statusReward: (workspaceId: string, rewardId: string) => `/workspaces/${workspaceId}/rewards/${rewardId}/status`,
    allRewardsClaimHistories: (workspaceId: string) => `/workspaces/${workspaceId}/rewards/claim-histories`,
};

export const NOTIFICATIONS = {
    getListNotifications: (workspaceId: string) => `/workspaces/${workspaceId}/custom-notifications`,
    updateNotifications: (workspaceId: string, id: string) => `/workspaces/${workspaceId}/custom-notifications/${id}`,
};

export const SELFBOOKING = {
    getSessionAvailable: (workspaceId: string) => `/workspaces/${workspaceId}/classes/sessions/available`,
    getAvailableSlots: (workspaceId: string) => `/workspaces/${workspaceId}/available-slots/get`,

    studentEnroll: (workspaceId: string) => `/workspaces/${workspaceId}/classes/sessions/enroll_member`,
    getCurrentSessions: (workspaceId: string | number) => `/workspaces/${workspaceId}/sessions`,
    SessionNote: (sessionId: number | string) => `/sessions/${sessionId}/notes`,
    getSessionById: (sessionId: number | string) => `/sessions/${sessionId}`,
    updateSessionNote: (sessionId: number | string, noteId: number) => `/sessions/${sessionId}/notes/${noteId}`,
    bookavAvailableSlot: (workspaceId: number | string) => `/workspaces/${workspaceId}/available-slots/book`,
    cancelBooking: (workspaceId: number | string, sessionId: string) =>
        `/workspaces/${workspaceId}/classes/sessions/${sessionId}/unenroll_member`,
};

export const ACADEMICS = {
    getLearningPaths: (workspaceId: string) => `/workspaces/${workspaceId}/learning-paths`,
    getDetailEnrollmentOfStudentWithLearningPath: (workspaceId: string, learningPathId: string, studentId: string) =>
        `/workspaces/${workspaceId}/learning-paths/${learningPathId}/enrollments/${studentId}`,
    getLearningLearners: (workspaceId: string, learningPathId: string) =>
        `/workspaces/${workspaceId}/learning-paths/${learningPathId}/students`,
    getLearningGroups: (workspaceId: string, learningPathId: string) =>
        `/workspaces/${workspaceId}/learning-paths/${learningPathId}/group`,
    getLearningPathDetail: (workspaceId: string, learningPathId: string) =>
        `/workspaces/${workspaceId}/learning-paths/${learningPathId}`,
    deleteLearningPaths: (workspaceId: string, learningPathId: string) =>
        `/workspaces/${workspaceId}/learning-paths/${learningPathId}`,
    updateStatusLearningPath: (workspaceId: string, learningPathId: string) =>
        `/workspaces/${workspaceId}/learning-paths/${learningPathId}/change-status`,
    addLearnerIntoLearningPath: (workspaceId: string, learningPathId: string) =>
        `/workspaces/${workspaceId}/learning-paths/${learningPathId}/student/invite`,
    addGroupIntoLearningPath: (workspaceId: string, learningPathId: string) =>
        `/workspaces/${workspaceId}/learning-paths/${learningPathId}/group/invite`,
    addInstructorIntoLearningPath: (workspaceId: string) => `/workspaces/${workspaceId}/learning-paths/instructors/add`,
    removeInstructorIntoLearningPath: (workspaceId: string) => `/workspaces/${workspaceId}/learning-paths/instructors/remove`,
    getGroupLearningPtahService: (workspaceId: string) => `/workspaces/${workspaceId}/learning-paths/learning-groups`,
    IndividualEnrollment: (workspaceId: string, learningPathId: string, enrollmentId: string) =>
        `/workspaces/${workspaceId}/learning-paths/${learningPathId}/individual-enrollment/${enrollmentId}`,
    GroupEnrollment: (workspaceId: string, learningPathId: string, groupId: string) =>
        `/workspaces/${workspaceId}/learning-paths/${learningPathId}/group-enrollment/${groupId}`,
};

export const LEARNING_GROUP = {
    removeLearnerIntoLearningGroup: (workspaceId, learningGroupId: string, memberId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-groups/${learningGroupId}/member/${memberId}/remove`,
    addLearnerIntoLearningGroup: (workspaceId: string, learningGroupId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-groups/${learningGroupId}/members/invite`,
    getLearningGroup: (workspaceId: string) => `${HOST_URL}/workspaces/${workspaceId}/learning-groups`,
    getMemberOfLearingGroup: (workspaceId: string, learningGroupId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-groups/${learningGroupId}/members`,
    getLearningGroupDetail: (workspaceId: string, learningGroupId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-groups/${learningGroupId}`,
    createLearningGroup: (workspaceId: string) => `${HOST_URL}/workspaces/${workspaceId}/learning-groups`,
    updateLearningGroup: (workspaceId: string, learningGroupId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-groups/${learningGroupId}`,
    getLearners: (workspaceId: string) => `${HOST_URL}/workspaces/${workspaceId}/learning-groups/students`,
    getEnrollmentOfGroup: (workspaceId: string, learningGroupId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-groups/${learningGroupId}/enrollments`,
    getEnrollmentOfStudent: (workspaceId: string, studentId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/students/${studentId}/enrollments`,
    getOpportunities: (workspaceId: string) => `${HOST_URL}/workspaces/${workspaceId}/sale-contracts/available`,
    getStudyHistory: (workspaceId: string, learningGroupId: string, learningPathId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-groups/${learningGroupId}/study-histories/${learningPathId}`,
    refundCreditOfSessionEnrollment: (workspaceId: string, sessionId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/sessions/enrollments/${sessionId}/refund`,
    addCreditForLearningGroup: (workspaceId: string) => `${HOST_URL}/workspaces/${workspaceId}/sale-contracts`,
    getSummaryCreditHistoriesForGroup: (workspaceId: string, groupId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-groups/${groupId}/credit-histories/summary`,
    getCreditHistoriesForGroup: (workspaceId: string, groupId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-groups/${groupId}/credit-histories`,
    updateCreditInformationForGroup: (workspaceId: string, saleContarctId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/sale-contracts/${saleContarctId}`,
    updateStatusCreditInformationForGroup: (workspaceId: string, saleContarctId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/sale-contracts/${saleContarctId}/change-status`,
    getStatusAddMemberToLearningGroup: (workspaceId: string, learningGroupId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-groups/${learningGroupId}/members/updatable`,
    changeStatusMemberOfLearningGroup: (workspaceId: string, learningGroupId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-groups/${learningGroupId}/members/change-status`,
    preCheckBeforeChangeStatusMemberOfLearningGroup: (workspaceId: string, learningGroupId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-groups/${learningGroupId}/members/change-status/pre-check`,
    preCheckBeforeChangeStatusOfLearningGroup: (workspaceId: string, learningGroupId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-groups/${learningGroupId}/change-status/pre-check`,
};

export const COMPANY = {
    getCreditHistoryCompany: (workspaceId: string, companyId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/companies/${companyId}/credit-histories`,
    getSummaryCreditHistory: (workspaceId: string, companyId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/companies/${companyId}/credit-histories/summary`,
    updateCompany: (workspaceId: string, companyId: string) => `${HOST_URL}/workspaces/${workspaceId}/companies/${companyId}`,
};
export const SALESFORCE = {
    getListSalesforce: (workspaceId: string) => `/workspaces/${workspaceId}/integrations/salesforce/logs`,
    salesforceSync: (workspaceId: string) => `/workspaces/${workspaceId}/integrations/salesforce/synch-lead`,
};

export const LEARNING_PATH = {
    getInstructorLearningPath: (workspaceId: string, learningPathId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-paths/${learningPathId}/instructors`,
    getResources: (workspaceId: string, learningPathId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-paths/${learningPathId}/resources`,
    renameResource: (workspaceId: string, learningPathId: string, resourceId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-paths/${learningPathId}/resources/${resourceId}/name`,
    getAndUploadResourcesUrl: (workspaceId: string, learningPathId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-paths/${learningPathId}/resources`,
    detailResourceUrl: (workspaceId: string, learningPathId: string, resourceId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-paths/${learningPathId}/resources/${resourceId}`,
    getAssignmentLearningPath: (workspaceId: string, learningPathId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-paths/${learningPathId}/assignments`,
    assignmentLearningPath: (workspaceId: string, learningPathId: string, assignmentId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-paths/${learningPathId}/assignments/${assignmentId}`,
    assignmentSettingLearningPath: (workspaceId: string, learningPathId: string, assignmentId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-paths/${learningPathId}/assignments/${assignmentId}/settings`,
    updateLearningPath: (workspaceId: string, learningPathId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-paths/${learningPathId}`,
    duplicateLearningPath: (workspaceId: string, learningPathId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-paths/${learningPathId}/clone`,
    getlearningPathDetail: (workspaceId: string, learningPathId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-paths/${learningPathId}`,
    getLearningPathCustomField: (workspaceId: string, learningPathId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-paths/${learningPathId}/custom-fields`,
    getAssociatedCourses: (workspaceId: string, learningPathId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-paths/${learningPathId}/courses`,
};

export const STUDENT = {
    getStudyHistory: (workspaceId: string, studentId: string, learningPathId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/students/${studentId}/study-histories/${learningPathId}`,
    refundSession: (workspaceId: string, sessionenrollmentId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/sessions/enrollments/${sessionenrollmentId}/refund`,
    getDetailEnrollment: (workspaceId: string, studentId: string, learningPathId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/learning-paths/${learningPathId}/enrollments/${studentId}`,
    getCreditHistory: (workspaceId: string, studentId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/students/${studentId}/credit-histories`,
    getSummaryCreditHistories: (workspaceId: string, studentId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/students/${studentId}/credit-histories/summary`,
    addCreditInformation: (workspaceId: string) => `${HOST_URL}/workspaces/${workspaceId}/sale-contracts`,
    getCreditInformation: (workspaceId: string, saleContarctId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/sale-contracts/${saleContarctId}`,
    updateCreditInformation: (workspaceId: string, saleContarctId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/sale-contracts/${saleContarctId}`,
    updateStatusCreditInformation: (workspaceId: string, saleContarctId: string) =>
        `${HOST_URL}/workspaces/${workspaceId}/sale-contracts/${saleContarctId}/change-status`,
    getCreditAccounts: (workspaceId: string, studentId: string) => `/workspaces/${workspaceId}/members/${studentId}/credit-accounts`,
    getCreditAccountsById: (workspaceId: string, creditId: string) => `/workspaces/${workspaceId}/credit-accounts/${creditId}`,
    depositCredit: (workspaceId: string) => `/workspaces/${workspaceId}/credit-accounts/deposit`,
    withdrawCredit: (workspaceId: string) => `/workspaces/${workspaceId}/credit-accounts/withdraw`,
    getCreditTransactions: (workspaceId: string, creditId: string) =>
        `/workspaces/${workspaceId}/credit-accounts/${creditId}/transactions`,
    getCreditTransactionsById: (workspaceId: string, creditId: string, transactionId: string) =>
        `/workspaces/${workspaceId}/credit-accounts/${creditId}/transactions/${transactionId}`,
    updateNoteCreditTransactionsById: (workspaceId: string, creditId: string, transactionId: string) =>
        `/workspaces/${workspaceId}/credit-accounts/${creditId}/transactions/${transactionId}/note`,
};

export const LIBRARY = {
    getLibary: (workspaceId: string) => `${HOST_URL}/workspaces/${workspaceId}/library-contents`,
    createFolder: (workspaceId: string) => `${HOST_URL}/workspaces/${workspaceId}/folders`,
    uploadFiles: (workspaceId: string) => `${HOST_URL}/workspaces/${workspaceId}/files`,
    updateFile: (workspaceId: string, id: string) => `${HOST_URL}/workspaces/${workspaceId}/files/${id}`,
    updateFolder: (workspaceId: string, id: string) => `${HOST_URL}/workspaces/${workspaceId}/folders/${id}`,
    deleteFile: (workspaceId: string, id: string) => `${HOST_URL}/workspaces/${workspaceId}/files/${id}`,
    getDetailsFile: (workspaceId: string, contentId: string) => `${HOST_URL}/workspaces/${workspaceId}/files/${contentId}`,
    getDetailsFolder: (workspaceId: string, contentId: string) => `${HOST_URL}/workspaces/${workspaceId}/folders/${contentId}`,
    renameLesson: (workspaceId: string, id: string) => `${HOST_URL}/workspaces/${workspaceId}/lessons/${id}/rename`,
    renameH5P: (workspaceId: string, id: string) => `${HOST_URL}/h5p/workspaces/${workspaceId}/${id}/rename`,
    moveToTrashLesson: (workspaceId: string, id: string) => `${HOST_URL}/workspaces/${workspaceId}/lessons/${id}/trash`,
    moveToTrashH5P: (workspaceId: string, id: string) => `${HOST_URL}/h5p/workspaces/${workspaceId}/${id}/trash`,
    moveToTrashFile: (workspaceId: string, id: string) => `${HOST_URL}/workspaces/${workspaceId}/files/${id}/trash`,
    moveToTrashFolder: (workspaceId: string, id: string) => `${HOST_URL}/workspaces/${workspaceId}/folders/${id}/trash`,
    duplicateFolder: (workspaceId: string, id: string) => `${HOST_URL}/workspaces/${workspaceId}/folders/${id}/clone`,
    duplicateFile: (workspaceId: string, id: string) => `${HOST_URL}/workspaces/${workspaceId}/files/${id}/clone`,
    duplicateLesson: (workspaceId: string, id: string) => `${HOST_URL}/workspaces/${workspaceId}/lessons/${id}/clone`,
    duplicateH5P: (workspaceId: string, id: string) => `${HOST_URL}/h5p/workspaces/${workspaceId}/${id}/clone`,
    recoverH5P: (workspaceId: string, id: string) => `${HOST_URL}/h5p/workspaces/${workspaceId}/${id}/recover`,
    recoverLesson: (workspaceId: string, id: string) => `${HOST_URL}/workspaces/${workspaceId}/lessons/${id}/recover`,
    recoverFile: (workspaceId: string, id: string) => `${HOST_URL}/workspaces/${workspaceId}/files/${id}/recover`,
    recoverFolder: (workspaceId: string, id: string) => `${HOST_URL}/workspaces/${workspaceId}/folders/${id}/recover`,
    deleteForeverFolder: (workspaceId: string, id: string) => `${HOST_URL}/workspaces/${workspaceId}/folders/${id}`,
    deleteForeverFile: (workspaceId: string, id: string) => `${HOST_URL}/workspaces/${workspaceId}/files/${id}`,
    deleteForeverLesson: (workspaceId: string, id: string) => `${HOST_URL}/workspaces/${workspaceId}/lessons/${id}`,
    deleteForeverH5P: (workspaceId: string, id: string) => `${HOST_URL}/h5p/workspaces/${workspaceId}/${id}`,
    getlistFolder: (workspaceId: string) => `${HOST_URL}/workspaces/${workspaceId}/folders`,
    moveH5p: (workspaceId: string, id: string) => `${HOST_URL}/h5p/workspaces/${workspaceId}/${id}/move`,
    emptyTrash: (workspaceId: string) => `${HOST_URL}/workspaces/${workspaceId}/library-contents/bulk-delete`,
    duplicateAssignment: (workspaceId: string, id: string) => `${HOST_URL}/workspaces/${workspaceId}/exercises/${id}/clone`,
    onAssignment: (workspaceId: string, id: string) => `${HOST_URL}/workspaces/${workspaceId}/exercises/${id}`,
    moveToTrashAssignment: (workspaceId: string, id: string) => `${HOST_URL}/workspaces/${workspaceId}/exercises/${id}/trash`,
    recoverAssignment: (workspaceId: string, id: string) => `${HOST_URL}/workspaces/${workspaceId}/exercises/${id}/recover`,
};

export const ROLE_PERMISSIONS = {
    customRoles: (workspaceId: string) => `${HOST_URL}/workspaces/${workspaceId}/roles`,
    customRolesDetails: (workspaceId: string, id: string) => `${HOST_URL}/workspaces/${workspaceId}/roles/${id}`,
};

export const META_DATA = {
    currency: `${HOST_URL}/metadata/currencies`,
};
