import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { CourseType } from 'types/Courses.type';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import dayjs from 'dayjs';
import './Table.scss'
import { UserContext } from 'contexts/User/UserContext';
import { useParams } from 'react-router-dom';
import NoAssignment from 'assets/img/empty-state/emptyAssignmentGrade.png'
import ActionMenu from './ActionMenu';
import { ListUser } from '_shared/components/User/User';
import { ASSIGNMENT_STATUS } from 'constant/course.const';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid';


interface IViewTable {
    data: CourseType[];
    refreshData: Function;
    assignmentSelected: any[];
    setAssignmentSelected: Function;
    pagination?: any;
}

const ViewTable: FC<IViewTable> = ({
    data,
    refreshData,
    assignmentSelected,
    setAssignmentSelected,
    pagination
}) => {
    const { t: translator } = useTranslation();
    const [isAll, setIsAll] = useState<any>(false);
    const [isFlagAll, setIsFlagAll] = useState<any>(false);

    const {
        userState: { result },
    } = useContext(UserContext);
    const params: { id } = useParams();
    const workspaceId = params?.id;


    const onSelectAssignment = (assignment) => {
        const hasCheck = assignmentSelected?.find(item => item?.id === assignment?.id)
        if (hasCheck) {
            setAssignmentSelected(assignmentSelected.filter(item => item?.id !== assignment.id))
        } else {
            setAssignmentSelected([...assignmentSelected, assignment])
        }
    }

    const handleSort = () => {
        refreshData(1, pagination?.order === 'desc' ? 'asc' : 'desc');
    }

    useEffect(() => {
        if (isFlagAll) {
            if (isAll) {
                setAssignmentSelected(data)
            } else {
                setAssignmentSelected([])
            }
            setIsFlagAll(false);
        }
    }, [isAll])

    useEffect(() => {
        if (assignmentSelected?.length) {
            setIsAll(true)
        } else {
            setIsAll(false)
        }
    }, [assignmentSelected])

    const columns = useMemo(
        () => [
            {
                Header: () => <div className='w-full px-2 flex'><input type='checkbox' checked={isAll} onChange={() => {
                    if (isAll) {
                        setIsAll(false);
                        setAssignmentSelected([]);
                    } else if (!isAll) { setIsFlagAll(true); setIsAll(!isAll); }
                }} /></div>,
                Cell: ({ row }: any) => {
                    const hasCheck = assignmentSelected?.find(item => item?.id === row?.original?.id)
                    return (
                        <div className='p-2 flex w-full'><input type='checkbox' checked={hasCheck} onChange={() => onSelectAssignment(row?.original)} /></div>
                    )
                },
                accessor: 'assignmentSelected',
                width: "30",
                sticky: 'left'
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.ASSIGNMENT')} />,
                accessor: 'name',
                width: 240,
                Cell: ({ row }: any) => {
                    return (
                        <RenderTableCell
                            value={`(ID: ${row?.original?.id})  ${row.values?.name}`}
                        />
                    )
                },
                sticky: 'left'
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.LEARNER')} />,
                accessor: 'learner',
                width: 180,
                Cell: ({ row }: any) => {
                    return (
                        <div className='flex py-2'>
                            <ListUser
                                id="learner"
                                users={[{
                                    name: row?.values?.learner?.display_name,
                                    avatar: row?.values?.learner?.avatar_url,
                                    id: row?.values?.learner?.id,
                                    link: `/workspace/${params?.id}/management/users/${row?.values?.learner?.id}`,
                                }]}
                            />
                        </div>
                    )
                },
                sticky: 'left'
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.LEARNING_GROUP')} />,
                accessor: 'learning_group',
                width: 140,
                Cell: ({ row }: any) => {
                    return (
                        <div className='flex ml-3 w-full pr-2'>
                            {row?.values?.learning_group && <ListUser
                                id="learningGroup"
                                className="w-full"
                                users={[{
                                    name: row?.values?.learning_group?.name,
                                    avatar: row?.values?.learning_group?.avatar_url,
                                    id: row?.values?.learning_group?.id,
                                    link: `/workspace/${params?.id}/management/learning-groups/${row?.values?.learning_group?.id}`,
                                }]}
                            />}
                        </div>
                    )
                }
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.LESSON')} />,
                accessor: 'lesson',
                width: 240,
                Cell: ({ row }: any) => {
                    return (
                        <RenderTableCell className='text-ellipsis truncate' value={row.values?.lesson?.title} />
                    )
                }
            },
            {
                Header: () => (<div className="flex items-center cursor-pointer text-primary-500" onClick={handleSort}>
                    <RenderTableHeader className="!text-primary-500" value={translator('STATUS')} />
                    {pagination?.order === 'desc' ? (
                        <ArrowDownIcon className="w-4 h-4 ml-1 " />
                    ) : (
                        <ArrowUpIcon className="w-4 h-4 ml-1 " />
                    )}
                </div>),
                accessor: 'state',
                Cell: ({ row }: any) => {
                    if (!row?.original?.state) return <RenderTableCell value='' />;
                    return (
                        <div className={`pl-1`}>
                            <div className={`status status-${row?.original?.state} !text-xs`}>
                                {ASSIGNMENT_STATUS(translator)[row?.original?.state]}
                            </div>
                        </div>

                    );
                },
                width: 160
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.GRADE')} />,
                accessor: 'final_score',
                width: "120",
                Cell: ({ row }: any) => <RenderTableCell value={row?.values?.final_score ? Math?.round(row?.values?.final_score) + '/100' : '-'} />
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.COMPLETION')} />,
                accessor: 'completion_percentage',
                width: "120",
                Cell: ({ row }: any) => <RenderTableCell value={row?.values?.completion_percentage ? Math?.round(row?.values?.completion_percentage) + '%' : '-'} />
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.ATTEMPTS')} />,
                accessor: 'attempt',
                width: "120",
                Cell: ({ row }: any) => <RenderTableCell value={row?.values?.attempt ? row?.values?.attempt : '-'} />
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.TIME_SPENT')} />,
                accessor: 'total_time_spent',
                width: "120",
                Cell: ({ row }: any) => <RenderTableCell value={row?.values?.total_time_spent ? Math?.round(row?.values?.total_time_spent / 60) + 'm' : '-'} />
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.DUE_DATE')} />,
                accessor: 'end_time',
                width: "120",
                Cell: ({ row }: any) => <RenderTableCell value={row?.values?.end_time ? dayjs(row?.values?.end_time).format("DD/MM/YYYY") : '-'} />
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.COMPLETED_DATE')} />,
                accessor: 'completion_date',
                width: "120",
                Cell: ({ row }: any) => <RenderTableCell value={row?.values?.completion_date ? dayjs(row?.values?.completion_date).format("DD/MM/YYYY") : '-'} />
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.GRADE_BY')} />,
                accessor: 'graded_by',
                width: "120",
                Cell: ({ row }: any) => <div className='flex ml-3'>
                    {row?.values?.graded_by && <ListUser
                        id="learner"
                        users={[{
                            name: row?.values?.graded_by?.display_name,
                            avatar: row?.values?.graded_by?.avatar_url,
                            id: row?.values?.graded_by?.id,
                            link: `/workspace/${params?.id}/management/users/${row?.values?.graded_by?.id}`,
                        }]}
                    />}

                </div>
            },
            {
                Header: () => <></>,
                accessor: 'action',
                width: 60,
                Cell: ({ row }: any) => <ActionMenu 
                    data={row?.original} 
                    refreshData={refreshData} 
                    hideAction={(assignmentSelected?.length > 1 || isAll) ? ['grade', 'dueDate'] : []} 
                    status={row?.original?.state}
                    position={row?.index > data?.length/2 ? 'bottom-0': ''}
                    isDisable={!!assignmentSelected?.length}
                 />
            },
        ],
        [data, assignmentSelected, isAll, pagination?.order]
    );

    return (
        <Table
            data={data || []}
            columns={columns}
            emptyContent={translator('COURSES.NO_ASSIGNMENTS')}
            emptyIcon={NoAssignment}
        />
    );
};

export default ViewTable;
