import StudentDashboardDetails from 'components/Reports/StudentDashboard/SpecificStudentDashboard';
import React from 'react';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';

const StudentDashboardDetailsPage: React.FC<{ setAuthStorage: React.Dispatch<React.SetStateAction<boolean>> }> = ({
    setAuthStorage,
}) => {
    return (
        <ManagePermissionWrapper>
            <StudentDashboardDetails />
        </ManagePermissionWrapper>
    );
};

export default StudentDashboardDetailsPage;
