import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { CloseIcon } from "assets/icon/CloseIcon";
import { ButtonPrimary } from "../Buttons/ButtonPrimary/ButtonPrimary";
import './ConfirmDeleteModal.scss';
import { ButtonOutline } from "../Buttons/ButtonOutline/ButtonOutline";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { ButtonIcon } from "../Buttons/ButtonIcon/ButtonIcon";

export const ConfirmDeleteModal = ({isOpen, onClose, children, btnPrimaryTitle = undefined, btnSecondaryTitle = undefined, titleModal= undefined, btnPrimaryTitleLoading= undefined}) => {
    const {t: translator} = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        return () => {
            setLoading(false);
        }
    });
    return <>
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={() => {}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/30 bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-[480px] transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="confirmDeleteModal-header"
                                >
                                    {titleModal || translator('Delete')}
                                    <div className='confirmDeleteModal-close'>
                                        <ButtonIcon disabled={loading} onClick={() => onClose()}>
                                            <span className="p-1">
                                                <CloseIcon />
                                            </span>
                                        </ButtonIcon>
                                    </div>
                                </Dialog.Title>
                                <div className="confirmDeleteModal-container">
                                    {children}
                                </div>
                                <div className="confirmDeleteModal-actions">
                                    <ButtonOutline type="button" disabled={loading} onClick={() => onClose()}>{ btnSecondaryTitle || translator('Cancel')}</ButtonOutline>
                                    <ButtonPrimary type="button" disabled={loading} onClick={() => {
                                        onClose(true);
                                        setLoading(true);
                                    }} className="buttonPrimary-danger">
                                        {loading ? <span className="confirmDeleteModal-loading"><CircleSpin />{ btnPrimaryTitleLoading || translator('Deleting')}</span> :
                                        btnPrimaryTitle || translator('Delete')}
                                    </ButtonPrimary>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    </>
}