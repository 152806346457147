import axios from './../restful.service';
import { REPORT } from 'constant/api.const';
import { parseParams } from 'utils/commonFun';

const getActivitiesOverview = (workspaceId: string, params = null) => {
    return axios.get(REPORT.getActivitiesDashBoard(workspaceId) + `/overview`, {params});
}

const getListActivities = (workspaceId: string, params) => {
    return axios.get(REPORT.getActivitiesDashBoard(workspaceId) + `${params ? '?' + parseParams(params) : ''}`);
}

export default {
    getActivitiesOverview,
    getListActivities
}