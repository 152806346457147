import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import resourcesService from 'services/resources.service';
import { useParams } from 'react-router';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import DialogComponent from 'components/Dialog/DialogComponent';

type DeleteResourceModalProps = {
    open: boolean;
    resource?: any;
    onDeleted: (data) => void;
    onCloseModal: () => void;
};

const DeleteResourceModal: FC<DeleteResourceModalProps> = ({
    open,
    resource,
    onDeleted,
    onCloseModal,
}) => {
    const { t } = useTranslation();
    const params: { id: string } = useParams();
    const [loading, setLoading] = useState(false);
    const {
        formState: { errors },
        setValue,
    } = useForm({
        defaultValues: {
            file_name: resource?.file_name,
        },
    });
    useEffect(() => {
        if (resource) {
            setValue('file_name', resource?.file_name);
        }
    }, [resource]);

    const submit = async () => {
        if (loading) {
            return;
        }
        setLoading(true);
        await resourcesService
            .deleteResources(params?.id, { id: resource?.id })
            .then((res) => {
                Toastify.success('File deleted successfully');
                onDeleted(res);
            })
            .catch(() => Toastify.error('File deleted failed'));
        setLoading(false);
    };
    return (<>
        <DialogComponent
            title={t('MODALS.DELETE')}
            isOpen={open}
            onCloseModal={onCloseModal}
            styleDialog="!z-[9999]"
            child={
                <div className="mt-4">
                    <p className="text-sm">{t('MODALS.DELETE_LIBRARY_ITEM')}</p>
                    <div className="flex justify-center gap-4 mt-5">
                        <ButtonOutline
                            type="button"
                            onClick={onCloseModal}
                        >
                            {t('MODALS.CANCEL')}
                        </ButtonOutline>
                        <ButtonPrimary
                            type="button"
                            onClick={() => submit()}
                            disabled={loading}
                            className="buttonPrimary-danger"
                        >
                            {loading ? (
                                <span className="confirmDeleteModal-loading">
                                    <CircleSpin />
                                    {t('MODALS.DELETE')}
                                </span>
                            ) : (
                                t('MODALS.DELETE')
                            )}
                        </ButtonPrimary>
                    </div>
                </div>
            }
            stylesTitle="text-base"
        />
    </>
    );
};

export default DeleteResourceModal;
