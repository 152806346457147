import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./ProgressOverview.scss";
import learnerReportService from "services/reports/learner-report.service";
import { checkPermission } from "utils/commonFun";
import { useTranslation } from "react-i18next";

export const ProgressOverview = () => {
    const params: {id: string, userId: string} = useParams();
    const [overview, setOverview] = useState<any>();
    const {t} = useTranslation();
    
    useEffect(() => {
        const payload = {
            studentId: params?.userId,
        }
        const getOverview = async () => {
            const response = await learnerReportService.getLearnerOverviews(params?.id, payload);
            setOverview(response?.data);
        }
        if (params?.userId && checkPermission('learnerDashboard','R')) {
            getOverview();
        }
    }, [params?.userId]);

    return <div className="courseOverview">
        <h2 className="courseOverview-title"></h2>
        <div className="courseOverview-list">
            <div className="courseOverview-item">
                <div className="courseOverview-label">{t("COURSES.OVERALL_GRADE")}</div>
                <div className="courseOverview-value">{overview?.avg_overall == null ? '-' : `${overview?.avg_overall}%`}</div>
            </div>
            <div className="courseOverview-item">
                <div className="courseOverview-label">{t("COURSES.COURSES_COMPLETETION")}</div>
                <div className="courseOverview-value">{overview?.avg_course_completion == null ? '-' : `${overview?.avg_course_completion}%`}</div>
            </div>
            <div className="courseOverview-item">
                <div className="courseOverview-label">{t("COURSES.ASSIGNMENT_GRADE")}</div>
                <div className="courseOverview-value">{overview?.avg_assignment_score == null ? '-' : `${overview?.avg_assignment_score}%`}</div>
            </div>
            <div className="courseOverview-item">
                <div className="courseOverview-label">{t("COURSES.LESSON_GOAL_COMPLETION")}</div>
                <div className="courseOverview-value">{overview?.avg_lesson_goal == null ? '-' : `${overview?.avg_lesson_goal}%`}</div>
            </div>
        </div>
    </div>
}