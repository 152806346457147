import { SaveIcon } from '@heroicons/react/solid';
import { DotSVG } from 'assets/icon';
import ConfirmCancel from 'components/CommonModals/ConfirmCancel';
import ConfirmModal from 'components/CommonModals/ConfirmModal';
import ConfirmSave from 'components/CommonModals/ConfirmSave';
import NotificationLabel from 'components/Notification/NotificationLabel/NotificationLabel';
import { roundNumber } from 'constant/util.const';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import classService from 'services/class.service';
import { DetailsGradebookType, GradebookApoStudent, StudentGradebook } from 'types/Class.type';
import InputPoints from './InputPoints';

const styleTitleCol = `col-span-1 text-sm font-semibold text-ooolab_dark_2 `;
const slyteBorder = `border-r border-r-gray_1`;
const styleDetail = `text-3xl font-semibold text-ooolab_dark_1 pt-10 bottom-0 absolute`;
const styleSubDetail = `grid grid-cols-7 w-4/5 text-sm text-ooolab_dark_1 items-center gap-1`;
const styleTitleDetail = `bg-ooolab_blue_0 rounded-lg text-sm font-semibold text-primary-500 p-4`;

interface DetailsStudentProps {
    data: GradebookApoStudent;
    setRefreshGradebook: Function;
    refreshGradebook: boolean;
}

const DetailsStudent: React.FC<DetailsStudentProps> = ({ data, setRefreshGradebook, refreshGradebook }) => {
    const [editDetails, setEditDetails] = useState<boolean>(true);
    const [cancelModal, setCancelModal] = useState<boolean>(false);
    const [saveModal, setSaveModal] = useState<boolean>(false);
    const [dataDetails, setDataDetails] = useState<GradebookApoStudent>(data);
    const [studentDetail, setStudentDetail] = useState<StudentGradebook>();
    const params: { id: string; classId: string } = useParams();
    const { t: translator } = useTranslation();

    useEffect(() => {
        if (data) {
            setStudentDetail(data.student);
        }
    }, [data]);

    const {
        register,
        handleSubmit,
        formState: { isDirty, errors },
        setValue,
        getValues,
        trigger,
        reset,
    } = useForm();
    const ToggleEdit = () => {
        setEditDetails(!editDetails);
    };

    const ToggleCancel = () => {
        setCancelModal(!cancelModal);
    };
    const ToggleSave = () => {
        setSaveModal(!saveModal);
    };
    const onSave = () => {
        const values = getValues();
        if (values) {
            const details: DetailsGradebookType = {
                online_progress: parseInt(values?.progress_onl),
                online_participation: parseInt(values?.participation_onl),
                online_behaviour: parseInt(values?.behavior_onl),
                offline_progress: parseInt(values?.progress_off),
                offline_participation: parseInt(values?.participation_off),
                offline_behaviour: parseInt(values?.behavior_off),
                assessment_listening: parseInt(values?.listening_ass),
                assessment_reading: parseInt(values?.rgv_ass),
                assessment_speaking: parseInt(values?.speaking_ass),
                assessment_writing: parseInt(values?.writing_ass),
            };
            ToggleSave();
            classService
                .updateGradebook(params.id, params.classId, data.id, details)
                .then(async (res) => {
                    if (res) {
                        await setDataDetails(res);
                        setEditDetails(true);
                        setRefreshGradebook(!refreshGradebook);
                        toast(<NotificationLabel textContent={'Save successfully!'} type="success" imageContent={<SaveIcon />} />, {
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeButton: false,
                            bodyStyle: {
                                padding: 0,
                            },
                            delay: 500,
                            position: 'bottom-left',
                            className: 'shadow-ooolab_box_shadow_2 min-w-ooolab_w_65 rounded-2xl',
                        });
                    }
                })
                .catch((err) => {
                    if (err?.error?.name == 'NOTHING_CHANGED') {
                        toast(<NotificationLabel textContent={'Nothing Changes!'} type="danger" imageContent={<SaveIcon />} />, {
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeButton: false,
                            bodyStyle: {
                                padding: 0,
                            },
                            delay: 500,
                            position: 'bottom-left',
                            className: 'shadow-ooolab_box_shadow_2 min-w-ooolab_w_65 rounded-2xl',
                        });
                    } else if (err) {
                        toast(<NotificationLabel textContent={'Something went wrong!'} type="danger" imageContent={<SaveIcon />} />, {
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeButton: false,
                            bodyStyle: {
                                padding: 0,
                            },
                            delay: 500,
                            position: 'bottom-left',
                            className: 'shadow-ooolab_box_shadow_2 min-w-ooolab_w_65 rounded-2xl',
                        });
                    }
                });
        }
    };

    const onCancel = () => {
        ToggleEdit();
        reset();
        setCancelModal(false);
    };

    return (
        <>
            <ToastContainer />
            <div className="pb-5">
                <div className="h-64 rounded-btn_10 shadow-card_detail">
                    <div className="px-16 pt-14 pb-8 font-bold text-2xl text-ooolab_dark_1 flex items-center">
                        <div className="mr-2">
                            <img src={studentDetail?.avatar_url} alt="Avatar" className="w-10 h-10 rounded-ooolab_circle" />
                        </div>
                        {studentDetail?.display_name}
                        {studentDetail?.nick_name && ` / ${studentDetail?.nick_name}`}
                    </div>
                    <div className="grid grid-cols-4 lg:gap-10 gap-1 items-center pl-2 lg:pl-16 ">
                        <div className={`${slyteBorder} h-24 relative `}>
                            <p className={styleTitleCol}> {translator('REPORTS.CLASS_GRADE')}</p>
                            <p className={styleDetail}>
                                {dataDetails?.class_grade || dataDetails?.class_grade == 0
                                    ? `${roundNumber(dataDetails?.class_grade, 1)}%`
                                    : `-`}
                            </p>
                        </div>
                        <div className={`${slyteBorder} h-24 relative`}>
                            <p className={styleTitleCol}>{translator('REPORTS.ASSIGNMENT_GRADE')}</p>
                            <p className={styleDetail}>
                                {dataDetails?.assignment_grade_in_percent || dataDetails?.assignment_grade_in_percent == 0
                                    ? `${roundNumber(dataDetails?.assignment_grade_in_percent, 1)}%`
                                    : `-`}
                            </p>
                        </div>
                        <div className={`${slyteBorder} h-24 relative`}>
                            <p className={styleTitleCol}>{translator('REPORTS.ASSIGNMENT_COMPLETION')}</p>
                            <p className={styleDetail}>
                                {dataDetails?.assignment_completion_in_percent || dataDetails?.assignment_completion_in_percent == 0
                                    ? `${roundNumber(dataDetails?.assignment_completion_in_percent, 1)}%`
                                    : `-`}
                            </p>
                        </div>
                        <div className={` h-24 relative`}>
                            <p className={styleTitleCol}>{translator('REPORTS.ATTENDANCE')}</p>
                            <p className={styleDetail}>
                                {dataDetails?.class_attendance || dataDetails?.class_attendance == 0
                                    ? `${roundNumber(dataDetails?.class_attendance, 1)}%`
                                    : `-`}
                            </p>
                        </div>
                    </div>
                </div>
                <ConfirmModal
                    isOpen={cancelModal}
                    width={'min-w-max'}
                    onCloseModal={ToggleCancel}
                    maskClosable={true}
                    child={
                        <ConfirmCancel
                            title="Confirm Cancel"
                            subTitle="Are you sure you want to Cancel?"
                            btnCancel="Close"
                            btnSubmit="Yes, cancel it!"
                            onClose={ToggleCancel}
                            onFetchSubmit={onCancel}
                        />
                    }
                />
                <ConfirmModal
                    isOpen={saveModal}
                    width={'min-w-max'}
                    onCloseModal={ToggleSave}
                    maskClosable={true}
                    child={
                        <ConfirmSave
                            title="Save Changes"
                            subTitle="Are you sure you want to save your changes?"
                            btnCancel="No, cancel"
                            btnSubmit="Yes, save it!"
                            onClose={ToggleSave}
                            onFetchSubmit={onSave}
                        />
                    }
                />
            </div>
        </>
    );
};

export default DetailsStudent;
