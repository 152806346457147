import { InformationCircleIcon, PlusIcon } from '@heroicons/react/outline';
import Assignment from 'components/Management/components/AssignmentGroup/Assignment';
import ImportCourse from 'components/Management/components/ImportCourse';
import ModalEditAssignment from 'components/Workspace/CourseDetail/component/ModalEditAssignment';
import { UserContext } from 'contexts/User/UserContext';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useBoolean } from 'hooks/custom';
import lodash from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import classService from 'services/class.service';
import { IClassAssignment } from 'types/Class.type';
import h5pServices from 'services/h5p.service';
import dayjs from 'dayjs';
import { timezone } from 'constant/timezone.const';
import workspaceMiddleware from 'middleware/workspace.middleware';
import DialogComponent from 'components/Dialog/DialogComponent';

function convertDateWithTz(date: any, timezone: string): string {
    return `${dayjs(date).format('YYYY-MM-DDTHH:mm:ss')}${dayjs(date).tz(timezone).format('Z')}`;
}

const ClassAssignments = () => {
    const { t: translator } = useTranslation();
    const params: { id: string; classId: string } = useParams();
    const { userState } = useContext(UserContext);
    const userInfo = userState.result;
    const { booleanValue: openImportModal, toggleBooleanValue: toggleImportModal } = useBoolean();
    const { dispatch, getWorkspaceDetailState } = useContext(GetWorkspaceContext);

    const { detail } = getWorkspaceDetailState.class;
    useEffect(() => {
        if (!detail) {
            workspaceMiddleware.getClassDetail(dispatch, {
                classId: params.classId,
                workspaceId: params.id,
            });
        }
    }, [params.id]);
    const classTimeZone = detail ? timezone.find((a) => a.utc.indexOf(detail?.time_zone) > -1) : undefined;
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [currentTab, setCurrentTab] = useState('Upcoming');

    const [assignments, setAssignments] = useState<IClassAssignment[]>([]);
    const [originalAssignments, setOriginalAssignments] = useState([]);
    const history = useHistory();
    const navigate = useCallback(() => history.push(`/workspace/${params.id}/assignment/class/${params.classId}/new`), []);

    const handleMoveSections = useCallback(
        (dragIdx: number, hoverIdx: number) => {
            const tmpSelected = [...assignments];
            const draggedLesson = assignments[dragIdx];
            let tmp;
            if (dragIdx < hoverIdx) {
                tmp = [
                    ...tmpSelected.slice(0, dragIdx),
                    ...tmpSelected.slice(dragIdx + 1, hoverIdx + 1),
                    draggedLesson,
                    ...tmpSelected.slice(hoverIdx + 1),
                ];
            } else {
                tmp = [
                    ...tmpSelected.slice(0, hoverIdx),
                    draggedLesson,
                    ...tmpSelected.slice(hoverIdx, dragIdx),
                    ...tmpSelected.slice(dragIdx + 1),
                ];
            }
            setAssignments(tmp);
        },
        [assignments]
    );

    const getAssignments = async (tab = 'Upcoming') => {
        try {
            const utcStr = new Date().toISOString();
            setPage(1); //reset page
            setCurrentTab(tab);
            const req = await classService.getAssignments(params.id, params.classId, {
                page: 1,
                per_page: 10,
                end_after: tab == 'Upcoming' ? utcStr : undefined,
                end_before: tab == 'Past' ? utcStr : undefined,
                order: tab == 'Upcoming' ? 'asc' : 'desc',
            });
            setHasMore(req.page * req.per_page < req.total);
            setAssignments(req.items);
            setOriginalAssignments(req.items);
        } catch (error) {
            console.log('err', error);
        }
    };

    const loadMoreAssignmentGroups = async () => {
        try {
            const utcStr = new Date().toISOString();
            console.log(currentTab);
            const req = await classService.getAssignments(params.id, params.classId, {
                page: page + 1,
                per_page: 10,
                end_after: currentTab == 'Upcoming' ? utcStr : undefined,
                end_before: currentTab == 'Past' ? utcStr : undefined,
                order: currentTab == 'Upcoming' ? 'asc' : 'desc',
            });
            if (req && req.items) {
                //setTimeout(() => {
                    setHasMore(req.page * req.per_page < req.total);
                    setPage(page + 1);
                    setAssignments([...assignments, ...req.items]);
                    setOriginalAssignments([...originalAssignments, ...req.items]);
                //}, 1000);
            }
        } catch (error) {}
    };

    const handleImportAssignment = async (courseId: string): Promise<any> => {
        try {
            const req = await classService.importAssignmentToClass(params.id, params.classId, courseId);
            return req;
        } catch (error) {
            console.log('errr', error);
            throw error;
        }
    };

    useEffect(() => {
        getAssignments();
    }, []);

    const handleDeleteAssignment = async (assignmentId: number, index: number) => {
        const req = await classService.deleteAssignment(params.id, params.classId, assignmentId);
        if (req) {
            if (index === 0) {
                setAssignments(assignments.slice(1));
                setOriginalAssignments(originalAssignments.slice(1));
            } else if (index === assignments.length - 1) {
                setAssignments(assignments.slice(0, -1));
                setOriginalAssignments(originalAssignments.slice(0, -1));
            } else {
                const newAssignmentState = [...lodash.slice(assignments, 0, index), ...lodash.slice(assignments, index + 1)];
                const newOriginalAssignmentState = [
                    ...lodash.slice(originalAssignments, 0, index),
                    ...lodash.slice(originalAssignments, index + 1),
                ];
                setAssignments(newAssignmentState);
                setOriginalAssignments(newOriginalAssignmentState);
            }
        } else {
            toast.error('Cannot delete assignment', {
                position: 'bottom-left',
            });
        }
    };

    const [editId, setEditId] = useState(-1);

    const handleClickEdit = (assignmentId: number) => {
        setEditId(assignmentId);
    };

    return (
        <>
            {editId !== -1 && (
               
                <DialogComponent 
                    title={"Edit Assignment"}
                    isOpen={editId !== -1}
                    onCloseModal={() => setEditId(-1)}
                    stylesTitle={"text-base"}
                    styles="max-w-md"
                    child={
                        <ModalEditAssignment
                            title={assignments.find((assignment: any) => assignment.id === editId)?.name}
                            onClose={() => setEditId(-1)}
                            endDate={assignments.find((assignment: any) => assignment.id === editId)?.end_time}
                            tz={detail?.time_zone || 'UTC'}
                            onConfirm={async (name, end_date) => {
                                await h5pServices.updatePartialAssignment(params.id, params.classId, editId, {
                                    name,
                                    end_time: detail?.time_zone
                                        ? convertDateWithTz(end_date, detail.time_zone)
                                        : dayjs(end_date).format('YYYY-MM-DDTHH:mm:ss'),
                                });
                                getAssignments(currentTab);
                            }}
                            startDate={assignments.find((assignment: any) => assignment.id === editId)?.start_time}
                            sessionId={assignments.find((assignment: any) => assignment.id === editId)?.session_id}
                        />
                    }
                />
            )}
            <ToastContainer className={'h-0'} />
            <div id="assignments-list" className="h-full relative overflow-y-scroll custom-scrollbar max-h-full">
                <ImportCourse
                    open={openImportModal}
                    onCloseModal={toggleImportModal}
                    onSubmit={handleImportAssignment}
                    onSuccessImport={getAssignments}
                />
                <div className="flex justify-between items-center row-span-1 top-0 z-2 mb-4">
                    <div>
                        <nav className="flex space-x-4" aria-label="Tabs">
                            <a
                                href={'#'}
                                className={
                                    currentTab == 'Upcoming'
                                        ? 'text-primary-500 px-3 py-2 font-semibold text-sm rounded-md'
                                        : 'text-gray-400 px-3 py-2 font-semibold text-sm rounded-md'
                                }
                                aria-current={'page'}
                                onClick={() => {
                                    getAssignments('Upcoming');
                                }}
                            >
                                Upcoming
                            </a>
                            <a
                                href={'#'}
                                className={
                                    currentTab == 'Past'
                                        ? 'text-primary-500 px-3 py-2 font-semibold text-sm rounded-md'
                                        : 'text-gray-400 px-3 py-2 font-semibold text-sm rounded-md'
                                }
                                onClick={() => {
                                    getAssignments('Past');
                                }}
                            >
                                Past
                            </a>
                        </nav>
                    </div>
                </div>
                <div className="text-xs font-medium inline-flex items-center text-green-500">
                    <InformationCircleIcon width={18} />
                    {translator('CLASSES.DUE_DATE_IN_TIME_ZONE')} {classTimeZone?.text}
                </div>

                {detail ? (
                    <InfiniteScroll
                        hasMore={hasMore}
                        next={loadMoreAssignmentGroups}
                        dataLength={assignments.length}
                        loader={
                            <div className="width-full text-center">
                                <svg
                                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-black"
                                    style={{ margin: 'auto' }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                            </div>
                        }
                        scrollableTarget="assignments-list"
                        height={'75vh'}
                        className="custom-scrollbar"
                    >
                        {assignments.map((i, index) => (
                            <Assignment
                                key={i.id}
                                onDelete={() => {
                                    handleDeleteAssignment(i.id, index);
                                }}
                                onEdit={() => handleClickEdit(i.id)}
                                id={i.id}
                                {...i}
                                displayTimeZone={detail?.time_zone}
                            />
                        ))}
                    </InfiniteScroll>
                ) : null}
                {/* <div className="row-span-full">
               <NoAssignment />
           </div> */}
            </div>
        </>
    );
};

export default ClassAssignments;
