// @ts-nocheck
import { FC, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import ButtonComponent from 'components/Button/ButtonComponent';
import ErrorMessageForm from 'components/ErrorMessageForm';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import avatarDefault from 'assets/avatar_option.png';
import ReactTooltip from 'react-tooltip';

type ModalUpdateAposProps = {
    data: any;
    translator: (str: string) => string;
    closeModal: () => void;
    updateRewardPoint: (params: any) => void;
};

const ModalUpdateApos: FC<ModalUpdateAposProps> = ({
    data,
    translator,
    closeModal,
    updateRewardPoint,
}) => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        getValues,
        watch,
        clearErrors,
        setError,
    } = useForm({
        defaultValues: {
            students: [],
        },
    });

    const { fields, replace } = useFieldArray({
        control,
        name: 'students',
    });

    useEffect(() => {
        replace(data);
    }, [data]);

    const onSubmitForm = (values: any) => {
        const arr = [];
        values.students.forEach((el: any) => {
            if (el.amount)
                arr.push({
                    student_id: el.id,
                    amount: Number(el.amount),
                    description: el.description,
                });
        });
        if (arr.length) updateRewardPoint({ students: arr });
        else closeModal();
    };

    return (
        <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="grid grid-cols-4 gap-2 mb-2">
                <div className="text-xs font-semibold">
                    {translator('STUDENT_NAME')}
                </div>
                <div className="text-xs font-semibold">
                    {translator('COINS')}
                </div>
                <div className="col-span-2 text-xs font-semibold">
                    {translator('DESCRIPTION')}
                </div>
            </div>
            <div className="max-h-96 overflow-auto">
                {fields.map((student, index) => (
                    <div key={index} className="mb-4">
                        <div className="grid grid-cols-4 gap-2">
                            <div className="flex justify-start items-center space-x-2">
                                <div className="w-11 flex justify-center">
                                    <img
                                        className="rounded-full w-8 h-8"
                                        src={
                                            student?.avatar_url || avatarDefault
                                        }
                                        alt={'avatar' + student.id}
                                    />
                                </div>

                                <div className="w-full truncate">
                                    <h1
                                        className="text-xs font-semibold truncate"
                                        data-tip={student.display_name}
                                        data-for={student.id + 'display_name'}
                                    >
                                        {student.display_name}
                                        {student?.nick_name && ` / ${student?.nick_name}`}
                                    </h1>
                                    <ReactTooltip
                                        place="right"
                                        type="dark"
                                        effect="float"
                                        id={
                                            student.id.toString() +
                                            'display_name'
                                        }
                                        getContent={(content) => (
                                            <div className="w-auto h-auto">
                                                {content}
                                            </div>
                                        )}
                                        disable={
                                            student.display_name?.length < 25
                                        }
                                    />
                                </div>
                            </div>
                            <div className="border rounded px-1">
                                <input
                                    type="number"
                                    placeholder="Coins"
                                    className="text-xs w-full"
                                    max={2147483647}
                                    min={-2147483648}
                                    maxLength={11}
                                    // maxLength={255}
                                    {...register(`students.${index}.amount`, {
                                        // required: translator('REQUIRED_FIELD'),
                                        maxLength: {
                                            value: 255,
                                            message: translator('MAX_LENGTH'),
                                        },
                                        validate: (val) => {
                                            const regex = /^-?\d+$/g;
                                            return !!!val ||
                                                (val != 0 && regex.test(val))
                                                ? true
                                                : 'The input value must be a non-zero integer';
                                        },
                                    })}
                                />
                            </div>
                            <div className="col-span-2 border rounded px-1">
                                <input
                                    type="text"
                                    placeholder={translator('DESCRIPTION')}
                                    className="text-xs w-full"
                                    // maxLength={255}
                                    {...register(
                                        `students.${index}.description`,
                                        {
                                            // required: translator('REQUIRED_FIELD'),
                                            maxLength: {
                                                value: 50,
                                                message:
                                                    'Maximum 50 characters allowed.',
                                            },
                                        }
                                    )}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-4 gap-2">
                            <div></div>
                            <div>
                                <ErrorMessageForm
                                    errors={errors}
                                    name={`students.${index}.amount`}
                                />
                            </div>
                            <div className="col-span-2">
                                <ErrorMessageForm
                                    errors={errors}
                                    name={`students.${index}.description`}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {!!!data?.length && <NoContentWithImage content="No students" />}
            <div className="text-center pt-4">
                <ButtonComponent
                    type="submit"
                    title={translator('SAVE')}
                    classStyle="xs:p-1 xs:text-sm primary-button"
                    disable={!!!data?.length}
                />
            </div>
        </form>
    );
};

export default ModalUpdateApos;
