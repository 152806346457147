import Table, { LinkCustom } from '../../components/Table';
import React from 'react';
import { Row, Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { roundNumberWithHours } from 'constant/util.const';
import { get } from 'lodash';
import { MinusIcon } from '@heroicons/react/outline';

const RenderGroupsHeader = ({ value }: { value: string }) => (
    <p className="font-semibold text-xs text-ooolab_dark_1 px-6 py-5">{value}</p>
);

const RenderCell = ({value}) => (
    <span className="text-sm text-center text-dark-300 font-semibold truncate text-ellipsis py-2 w-full">{value}</span>
);

interface TableProps {
    workspaceId?: string;
    data?: any;
}

const TableGroups = ({ workspaceId, data }: TableProps) => {
    const { t } = useTranslation();

    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);

    const columns = React.useMemo(
        () => [
            {
                Header: () => (
                    <div className="text-left flex items-center">
                        <RenderGroupsHeader value={translator('TEACHER_OVERVIEW')} />
                    </div>
                ),
                accessor: 'display_name',
                Cell: ({ row }: { row: Row; column: Column }) => {
                    return (
                        <div className=" text-xs text-primary-500 font-semibold cursor-pointer h-full w-full flex items-center">
                            <LinkCustom
                                className=' !block truncate w-full h-fit m-auto '
                                to={{
                                    pathname: `/workspace/${workspaceId}/report/dashboard-instructor/${get(row, 'original.id')}`,
                                    state: {
                                        instructor_name: row?.values?.display_name,
                                    },
                                }}
                                value={row?.values?.display_name}
                            />
                        </div>
                    );
                },
                width: 140,
            },
            {
                Header: () => (
                    <div className="text-center">
                        <RenderGroupsHeader value={translator('PUNCTUALITY')} />
                    </div>
                ),
                accessor: 'punctuality',
                Cell: ({ row }: { row: Row; column: Column }) => {
                    return (
                        <div className="">
                            <RenderCell value={row.values?.punctuality || 'N/A'} />
                        </div>
                    );
                },
                width: 140,
            },
            {
                Header: () => (
                    <div className="text-center">
                        <RenderGroupsHeader value={translator('TOTAL_TALK_TIME')} />
                    </div>
                ),
                accessor: 'total_talk_time',
                Cell: ({ row }: { row: Row; column: Column }) => (
                    <div className="h-full flex items-center cursor-default">
                        <RenderCell value={row.values?.total_talk_time || 'N/A'} />
                    </div>
                ),
                width: 140,
            },
            {
                Header: () => (
                    <div className="text-center">
                        <RenderGroupsHeader value={translator('PERCENT_TALK_TIME')} />
                    </div>
                ),
                accessor: 'percent_talk_time',
                Cell: ({ row }: { row: Row; column: Column }) => (
                    <div className="h-full flex items-center cursor-default">
                        <RenderCell value={row.values?.percent_talk_time || 'N/A'} />
                    </div>
                ),
            },
            {
                Header: () => (
                    <div className="text-center">
                        <RenderGroupsHeader value={translator('TOTAL_CLASSES_TALK')} />
                    </div>
                ),
                accessor: 'count_session',
                Cell: ({ row }: { row: Row; column: Column }) => (
                    <div className="h-full flex items-center cursor-default">
                        <RenderCell value={row.values.count_session && row.values.count_session !== '0' ? row.values.count_session :  <MinusIcon className="w-6 h-6 text-dark-100" />} />
                    </div>
                ),
            },
            {
                Header: () => (
                    <div className="text-center">
                        <RenderGroupsHeader value={translator('TOTAL_HOURS_TALK')} />
                    </div>
                ),
                accessor: 'sum_duration',
                Cell: ({ row }) => (
                    <div className="h-full flex items-center cursor-default">
                        <RenderCell value={row.values?.sum_duration && row.values?.sum_duration !== '0' ? roundNumberWithHours(row.values?.sum_duration) : <MinusIcon className="w-6 h-6 text-dark-100" />} />
                    </div>
                ),
            },
        ],
        [data]
    );
    return <Table data={data} columns={columns} />;
};

export default TableGroups;
