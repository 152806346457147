import React from 'react';
import { CourseBuilder } from 'components/Workspace/Course/CourseBuilder/CourseBuilder';
import RouteMasterPage from 'pages/RouteMasterPage';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';

const CourseBuilderPage = () => {
    return (
        <ManagePermissionWrapper>
            <CourseBuilder>
                <RouteMasterPage />
            </CourseBuilder>
        </ManagePermissionWrapper>
    );
};

export default CourseBuilderPage;
