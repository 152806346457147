import { StylesConfig } from 'react-select';

export const customSelectStyle: StylesConfig<any, true> = {
    clearIndicator: (base) => ({ ...base, display: 'none' }),
    indicatorSeparator: (base) => ({ ...base, display: 'none' }),
    dropdownIndicator: (base, { selectProps }) => ({
        ...base,
        padding: 6,
        transition: 'all 0.3s ease-in-out',
        transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    }),
    control: (styles, { selectProps }) => ({
        ...styles,
        borderColor: '#EBEBF0',
        minHeight: '32px',
        maxHeight: '36px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        borderRadius: 4,
        paddingLeft: 12,
        fontSize: 14,
        backgroundColor: selectProps?.isDisabled ? "#EFEFEF4D" : "#ffffff",
    }),
    menuList: (styles) => ({
        ...styles,
        fontSize: 14
    }),

    multiValue: (provided) => ({
        ...provided,
        background: '#F2F9FF',
        borderRadius: '18px',
        textColor: '#2E3A59',
        fontWeight: 400,
    }),
};

export const typeOfProgramOptions = (translator: (key: string) => string) => [
    { label: translator('STUDENT'), value: 'individual' },
    { label: translator('LEARNING_GROUP'), value: 'learning_group' },
];

export const ENROLLMENT_TYPE = {
    CREDITS: "credits",
    EXPIRARION: "expirations"
}

export const PROGRAM_TYPE = {
    INDIVIDUAL: "individual",
    LEARNING_GROUP: "learning_group"
}