import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CourseSideLeft } from "./../CourseBuilder/components/CourseSideLeft/CourseSideLeft";
import { setCourse } from "store/features/courseSlice";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { CourseGradebook } from "./../CourseBuilder/components/CourseGradebook/CourseGradebook";
import { checkPermission } from "utils/commonFun";
import { TemplateInformation } from "../CourseBuilder/components/CourseInformation/TemplateInformation";
import { changeShowCourseTemplateSetting, courseTemplateCreate, courseTemplateFetch, updateTemplateSetting } from "store/features/courseTemplateSlice";
import { CourseTemplateHeader } from "../CourseBuilder/components/CourseHeader/CourseTemplateHeader";
import Detail from "./../CourseBuilder/components/CoursePeople/Enrollment/Detail/EnrollmentDetail";
import PageBreadcrumb from "components/Breadcrumb/PageBreadcrumb";
import "./../CourseBuilder/CourseBuilder.scss";
import { CourseTemplateSyllabus } from "../CourseBuilder/components/CourseSyllabus/CourseTemplateSyllabus";
import { CourseTemplateSetting } from "../CourseSetting/CourseTemplateSetting";

type CourseBuilderProp = {
    children: React.ReactNode
}
const COURSE_TAB = {
    information: 'information',
    syllabus: 'syllabus',
    gradebook: 'gradebook',
    academics: 'academics'
};

export const TemplateBuilder = ({ children }: CourseBuilderProp) => {
    const { data: detail, hasShowCourseSetting, error } = useAppSelector((state) => state.courseTemplate);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { t: translator } = useTranslation();
    const params: { id: string, courseId: string } = useParams();
    const search = useLocation()?.search;
    const enrollmentIdParam = new URLSearchParams(search).get('enrollmentId')

    const tab = new URLSearchParams(search).get('tab');

    const hasCourseSetting = useMemo(() => {
        return hasShowCourseSetting;
    }, [hasShowCourseSetting]);

    useEffect(() => {
        const createCourse = async () => {
            const response = await dispatch(
                courseTemplateCreate({
                    workspaceId: params?.id,
                    template_name: 'Untitled Course Template',
                    title: 'Untitled Course',
                    status: 'draft'
                })
            );
            if (response) {
                await dispatch(
                    updateTemplateSetting({
                        workspaceId: params.id,
                        id: response?.payload.data?.id,
                        course_completion_weight: 25,
                        assignment_grade_weight: 25,
                        lesson_goal_weight: 25,
                        attendance_weight: 25,
                        allow_instructor_grading: false
                    })
                );
                if (response?.payload?.data?.id) {
                    history.push(`/workspace/${params.id}/management/courses/${response?.payload.data?.id}/template`);
                }
            }
        };
        if (params?.courseId === 'new') {
            createCourse();
        } else {
            dispatch(
                courseTemplateFetch({
                    workspaceId: params.id,
                    id: params.courseId,
                })
            );
        }
    }, [params?.courseId]);
    //@ts-ignore
    useEffect(() => {
        return () => dispatch(setCourse({}))
    }, [])

    return (
        <div className="relative">
            <PageBreadcrumb
                pageNameDefault={translator('COURSES.LEARNING_MANAGEMENT')}
                pages={[
                    { name: translator('COURSES.COURSES'), href: `/workspace/${params.id}/management/courses`, className: '' },
                    { name: detail ? detail?.template_name : translator('COURSES.NEW_COURSE'), href: ``, className: '' },
                ]}
                containerClassName="z-10 sticky top-0"
            />
            <div className="sticky top-[53px] z-[10] bg-white w-full">
                <CourseTemplateHeader />
            </div>
            <main className="courseBuilder-main">
                <div className="relative">
                    <div className="courseBuilder-side">
                        {checkPermission('course', 'R') && <CourseSideLeft hasCourse={false} />}
                    </div>
                </div>

                <div className="courseBuilder-content">
                    {
                        enrollmentIdParam ? <Detail />
                            :
                            <div className="courseBuilder-container !bg-transparent">
                                {tab == COURSE_TAB.information || !tab ? <TemplateInformation /> : null}
                                {tab == COURSE_TAB.academics ? <CourseTemplateSyllabus /> : null}
                                {tab === COURSE_TAB.gradebook ? <CourseGradebook /> : null}
                            </div>
                    }
                </div>
            </main>
            {hasCourseSetting ? <CourseTemplateSetting show={hasCourseSetting} onClose={() => {
                dispatch(changeShowCourseTemplateSetting(false));
            }} /> : null}
        </div>
    )
}