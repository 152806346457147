import React from "react";
import { StudentSessionBreakdown } from "./components/StudentSessions/StudentSessionBreakdown";
import { StudentSessionOverview } from "./components/StudentSessions/StudentSessionOverview";

export const StudentDashboardSessions = () => {
    return<>
        <StudentSessionOverview />
        <div className="mt-4">
            <StudentSessionBreakdown />
        </div>
    </>
}