import React from "react";
import { CaretIcon } from "assets/icon";
import { ButtonIcon } from "_shared";
import "./SyllabusLearningPathUnit.scss";
import { UnitContent } from "../UnitContent/UnitContent";
import { Disclosure } from "@headlessui/react";
import { LearningPathUnit } from "types/LearningPath.type";
import { checkPermission } from "utils/commonFun";

export const SyllabusLearningPathUnit = ({ sections }: { sections: LearningPathUnit[] }) => {
    return (
        <div className="syllabusLearningPathUnit">
            {sections?.map((item: LearningPathUnit) =>
                <Disclosure key={item.id}>
                    {({ open }) => (
                        <>
                            <Disclosure.Button disabled={!checkPermission('courseAcademic', 'R')}>
                                <div className="syllabusLearningPathUnit-header">
                                    <ButtonIcon type="button" onClick={() => { }} className="h-[22px] mt-0.5 ml-1">
                                        <span className={`${open ? 'rotate-90' : ''}`}>
                                            <CaretIcon size={22} />
                                        </span>
                                    </ButtonIcon>
                                    <div className="syllabusLearningPathUnit-info">
                                        <span className="syllabusLearningPathUnit-badge">{item?.section_contents?.length ?? 0}</span>
                                        <h3 className="syllabusLearningPathUnit-name">
                                            {item?.title}
                                        </h3>
                                    </div>
                                </div>
                            </Disclosure.Button>
                            {open ? <Disclosure.Panel>
                                <div className="syllabusLearningPathUnit-content">
                                    <UnitContent contents={item?.section_contents} />
                                </div>
                            </Disclosure.Panel> : null}
                        </>
                    )}
                </Disclosure>
            )}
        </div>
    )
}