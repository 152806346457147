import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PlusSmIcon } from '@heroicons/react/outline';
import Table from './Table/Table';
import { useTranslation } from 'react-i18next';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import './Library.scss';
import courseService from 'services/course.service';
import Pagination from 'components/V2/Pagination';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import DialogComponent from 'components/Dialog/DialogComponent';
import FromLibrary from 'components/Library/LessonBuilder/components/Content/FromLibrary/FromLibrary';
import ModalAssignAssignment from '../ModalAssignAssignment';
import { useAppSelector } from 'hooks/hooks';

const Library: React.FC = ({ children }) => {
    const { t: translator } = useTranslation();
    const params: { id: string; courseId: string } = useParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [indexPage, setIndexPage] = useState('0-0');
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'added_at',
        total: 0,
    });
    const { data: {status}, data: {settings}} = useAppSelector((state) => state.course);   
    const [keyword, setKeyword] = useDebouncedState('', 0);
    const [isOpenModalAddFromLibrary, setOpenModalAddFromLibrary] = useState(false)
    const workspaceId = params.id;
    const courseId = params.courseId;
    const [isOpenModalAssignAssignment, setOpenModalAssignAssignment] = useState(false)

    const [assignmentSelected, setAssignmentSelected] = useState([]);

    const getdata = (page: number | string = 1, keyword = undefined) => {
        setLoading(true);
        const payload = {
            ...pagination,
            page,
            tab: 'library',
            q: keyword,
        };
        if (!payload?.q) delete payload?.q;
        delete payload?.total;
        courseService
            .getCourseExercises(workspaceId, courseId, payload)
            .then((res: any) => {
                setData(res?.data?.items);
                setPagination({ ...pagination, ...payload, total: res?.data?.total });
                setIndexPage(getIndexPage(payload?.page, pagination?.per_page, res?.data?.items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const handlePagination = (page: number) => {
        setAssignmentSelected([])
        getdata(page);
    };

    const addAssignment = (assignments) => {
        courseService.addAssignmentToCourse(workspaceId, courseId, {libraries: assignments?.map((item) => item?.id)})
        .then(res => {
            Toastify.success({
                title: translator("COURSES.ADD_ASSIGNMENT_SUCCESS"),
                message: assignments?.length > 1 ? 
                    translator("COURSES.ADD_ASSIGNMENTS_SUCCESS_MESSAGE", {assignmnetName: assignments?.map(item => item?.name)?.join(', ')})
                    :
                    translator("COURSES.ADD_ASSIGNMENT_SUCCESS_MESSAGE", {assignmnetName: assignments?.[0]?.name})
            }, 5000)
            getdata(1, keyword);
        })
        .catch(() => {
            Toastify.error(translator("COURSES.ADD_ASSIGNMENT_FAILURE"))
        })
        .finally(() => setOpenModalAddFromLibrary(false))
    }

    useEffect(() => {
        getdata(1, keyword);
    }, [keyword, pagination?.order]);

    return (
        <div>
            <div className="LibraryTab">
                <div className="LibraryTab-filter flex justify-between">
                    <SearchBox placeholder={translator("COURSES.SEARCH_BY_ASSIGNMENT_NAME")} onSubmit={(value) => setKeyword(value)} className='max-w-xs' />
                    {checkPermission('course', 'R') && <div className="flex gap-2">
                        <ButtonOutline 
                            className="buttonOutline--isPrimary" 
                            type="button" 
                            onClick={() => setOpenModalAddFromLibrary(true)}
                        >
                            <span className="flex">
                                <PlusSmIcon className="PlusIcon" aria-hidden="true" />
                                <span> {translator('COURSES.ADD_ASSIGNMENT')}</span>
                            </span>
                        </ButtonOutline>
                        <ButtonPrimary 
                            disabled={!assignmentSelected?.length || status === 'inactive'} 
                            type="button" 
                            onClick={() => setOpenModalAssignAssignment(true)}
                        >
                            {translator('COURSES.ASSIGN')}
                        </ButtonPrimary>
                    </div>}
                </div>

                <div className="LibraryTab-main">
                    <div className="wrapper-content">
                        <div className="scrollbar">
                            {loading && (
                                <div className="loading">
                                    <CircleSpin color="text-primary-500" />
                                </div>
                            )}
                            {!loading && 
                                <Table 
                                    data={data} 
                                    refreshData={getdata} 
                                    assignmentSelected={assignmentSelected}
                                    setAssignmentSelected={setAssignmentSelected}
                                    setOrder={(order) => setPagination({...pagination, order})}
                                    order={pagination?.order}
                                    isAllowAssign={status !== 'inactive'}
                                    onAssignAssignment={() => setOpenModalAssignAssignment(true)}
                                />}
                        </div>
                        <Pagination
                            total={pagination.total}
                            per_page={pagination.per_page}
                            callback={(e) => handlePagination(e)}
                            indexPage={indexPage}
                            page={pagination?.page}
                        />
                    </div>
                </div>
            </div>
            {isOpenModalAddFromLibrary && (
                <DialogComponent
                    isOpen={isOpenModalAddFromLibrary}
                    hasNonTitle
                    styles="max-w-[70vw] min-h-[480px] my-0 rounded-lg !p-0 pb-4"
                    isShowClose={false}
                    child={
                        <FromLibrary
                            createContentType={''}
                            isShowContentType
                            title={translator('LIBRARY.ADD_FROM_LIBRARY')}
                            onCloseModal={() => setOpenModalAddFromLibrary(false)}
                            handleAddContentToSection={(values) => {
                                if (values?.length > 0)
                                    addAssignment(values);
                            }}
                            queryParams={{ exclude_assignment_of_course_id: courseId }}
                            type={'assignment'}
                        />
                    }
                />
            )}
            {isOpenModalAssignAssignment && <DialogComponent 
                isOpen={isOpenModalAssignAssignment}   
                onCloseModal={() => setOpenModalAssignAssignment(false)} 
                title={translator('COURSES.ASSIGN_ASSIGNMENT')}   
                styles='max-w-lg'    
                child={
                    <ModalAssignAssignment onCloseModal={() => setOpenModalAssignAssignment(false)} assignmentSelected={assignmentSelected} />
                } 
            />}
        </div>
    );
};

export default Library;
