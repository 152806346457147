export const ActiveIcon = ({ width = '20', height = '20' }: { width?: string; height?: string }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7891_17584)">
                <path d="M11.6668 11.8768V13.6185C10.9126 13.3518 10.1054 13.27 9.313 13.38C8.52061 13.4899 7.76616 13.7884 7.11302 14.2503C6.45988 14.7123 5.92712 15.3242 5.55946 16.0347C5.19181 16.7452 5.00001 17.5335 5.00016 18.3335L3.3335 18.3327C3.33324 17.3151 3.56593 16.3109 4.01375 15.3972C4.46156 14.4834 5.11261 13.6843 5.91701 13.061C6.72141 12.4378 7.65781 12.0069 8.65446 11.8015C9.6511 11.5961 10.6815 11.6216 11.6668 11.876V11.8768ZM10.0002 10.8335C7.23766 10.8335 5.00016 8.596 5.00016 5.8335C5.00016 3.071 7.23766 0.833496 10.0002 0.833496C12.7627 0.833496 15.0002 3.071 15.0002 5.8335C15.0002 8.596 12.7627 10.8335 10.0002 10.8335ZM10.0002 9.16683C11.8418 9.16683 13.3335 7.67516 13.3335 5.8335C13.3335 3.99183 11.8418 2.50016 10.0002 2.50016C8.1585 2.50016 6.66683 3.99183 6.66683 5.8335C6.66683 7.67516 8.1585 9.16683 10.0002 9.16683ZM14.8277 16.5952L17.7735 13.6493L18.9527 14.8277L14.8277 18.9527L11.881 16.006L13.0602 14.8277L14.8268 16.5952H14.8277Z" fill="#111827" />
            </g>
            <defs>
                <clipPath id="clip0_7891_17584">
                    <rect width={width} height={height} fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
};
