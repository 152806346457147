import WorkspaceSetting from 'components/WorkspaceSetting/WorkspaceSetting';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';
import React from 'react';

interface WorkspaceSettingProps {
    setAuthStorage: React.Dispatch<React.SetStateAction<boolean>>;
}

const WorkspaceSettingPage: React.FC<WorkspaceSettingProps> = ({
    setAuthStorage,
}) => {
    return (
        <ManagePermissionWrapper>
            <WorkspaceSetting />
        </ManagePermissionWrapper>
    );
};

export default WorkspaceSettingPage;
