import { Tab } from '@headlessui/react';

import './index.scss'
import { useHistory, useParams } from 'react-router-dom';
import Syllabus from './Syllanus/Syllabus';
import Library from './Library/Library';
import { CircleInformationIcon } from 'assets/icon';
import ReactTooltip from "react-tooltip";
import { useTranslation } from 'react-i18next';

const ListTab = (translator) => [
    { label: translator('From Syllabus'), value: 'syllbus', tooltip: translator('The assignments listed here come from the course syllabus.')  },
    { label: translator('From Library'), value: 'library', tooltip: translator('If you want to add additional assignments from Library to learners, please visit this listing.') },
]

const Assignment = () => {
    const history = useHistory();
    const params: { id: string; courseId: string } = useParams();
    const {t} = useTranslation();
    return (
        <div className='AsignmentTab'>
            <Tab.Group>
                <div className="AsignmentTab-title">{t("COURSES.ASSIGNMENTS")}</div>
                <Tab.List className={`AsignmentTab-tablist`}>
                    {ListTab(t).map((i, index) => (
                        <Tab
                            className={({ selected }) => {
                                const tabClassName = `tagItem ${selected ? 'active ' : 'disable'
                                    }`;
                                return tabClassName;
                            }}
                            onClick={() => history?.push(`/workspace/${params.id}/management/courses/${params.courseId}?tab=assignment-management&subtab=assignments&&view=${i?.value}`)}
                        >
                            <div className='flex gap-1 items-center text-xs'>
                                {i?.label} 
                                <div 
                                    data-for={`tab-${i?.value}`} 
                                    data-tip={i?.tooltip}
                                >
                                    <CircleInformationIcon 
                                        width='14' 
                                        height='14' 
                                        fill="currentColor" 
                                    />
                                </div>
                            </div>
                            {/*@ts-ignore*/
                                <ReactTooltip
                                    place={index === 1 ? "right": "top"}
                                    type="light"              
                                    id={`tab-${i?.value}`}
                                    getContent={(content) => <div className="max-w-[280px] text-left text-sm font-normal p-1">{content}</div>}
                                    className='shadow-lg opacity-100'
                                />
                            }
                        </Tab>
                    ))}
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>
                        <Syllabus />
                    </Tab.Panel>
                    <Tab.Panel>
                        <Library />
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
};

export default Assignment;