import React, { useState } from 'react';
import { H5PEditorUI } from 'packages/h5p-react';
import { useHistory, useParams } from 'react-router-dom';
import { SaveIcon, XIcon } from '@heroicons/react/outline';
import { H5PContext } from 'contexts/H5P/H5PContext';
import { IContentListEntry } from 'types/H5P.type';
import { H5PModal } from 'components/H5P/H5PComponents';
import { CANCEL_H5P } from 'constant/modal.const';
import { errorNoti } from 'components/H5P/H5PFN';
import { getLocalStorageAuthData } from 'utils/handleLocalStorage';
import { useTranslation } from 'react-i18next';
import { ButtonIcon, ButtonOutline, ButtonPrimary } from '_shared';
import { CircleInformationIcon } from 'assets/icon';
import workspaceService from 'services/workspace.service';
import h5pServices from 'services/h5p.service';
import H5pInformation from 'components/H5P/H5pInformation/H5pInformation';
import Spinner from 'components/Spinner';
import './CreateH5p.scss';
import DialogComponent from 'components/Dialog/DialogComponent';
import { LibraryTypeEnum } from 'components/Library/LibraryContants';
import { Toastify } from '_shared/components/Toastify/Toastify';

const CreateH5p = ({ handleAddContentToSection, onCloseModal, payload }) => {
    const history = useHistory();
    const { location } = history;
    const { state }: { state: any } = location;
    const [isShowInformation, setShowInformation] = useState(false);
    const userInfo = getLocalStorageAuthData();
    const h5pEditor: React.RefObject<H5PEditorUI> = React.useRef(null);
    const { h5pEditPromise, h5pSavePromise } = h5pServices;
    const [entryState, setEntryState] = useState({
        editing: 'new',
        playing: false,
        saving: false,
        saved: false,
        loading: true,
        saveErrorMessage: '',
        saveError: false,
    });
    const [loading, setLoading] = useState(true);
    const params: { id: string } = useParams();
    const [isModal, setIsModal] = React.useState<boolean>(false);
    const [modalProps, setModalProps] = React.useState<{
        component: {
            type: string;
            title: string;
            subTitle: string;
            btnCancel: string;
            btnSubmit: string;
            color: string;
            img: string;
        };
        onFetch: () => Promise<void>;
    }>();
    let newCounter = 0;
    const { t: translator } = useTranslation();
    const h5PCtx = React.useContext(H5PContext);
    const [modalCreate, setModalCreate] = useState(false);
    const {
        H5PState: { h5PApproveContentResult },
    } = h5PCtx;

    const [content, setContent] = useState<IContentListEntry>({
        contentId: 'new',
        argsId: {
            workspaceId: params.id,
            contentId: 'new',
            parent_id: state?.folderId && parseInt(state.folderId),
            ...payload
        },
        mainLibrary: undefined,
        title: 'New H5P',
        originalNewKey: `new-${newCounter++}`,
        status: '',
        tags: undefined,
    });

    const [customFields, setCustomFields] = React.useState<any>([]);

    React.useEffect(() => {
        workspaceService
            .getCustomFieldsService(params.id, { type: 'h5p_content', skip_pagination: true, sort_by: 'updated_at' })
            .then((res: any) => {
                const data = res.data.items.map((item) => {
                    return {
                        data: [],
                        name: item.name,
                        id: item.id,
                    };
                });
                setCustomFields(data);
            });
    }, []);

    React.useEffect(() => {
        if (h5PApproveContentResult) {
            setContent({
                ...content,
                status: 'public',
            });
        }
    }, [h5PApproveContentResult]);

    const handleSaved = async () => {
        setEntryState({
            ...entryState,
            saving: false,
            saved: true,
        });

        setTimeout(() => {
            setEntryState({
                ...entryState,
                saved: false,
            });
        }, 3000);
    };

    async function onSave() {
        setEntryState({ ...entryState, saving: true });
        const custom_fields = customFields?.map((customField) => {
            return {
                custom_field_id: customField.id,
                data: customField.data,
            };
        });
        try {
            const returnData: any = await h5pEditor?.current?.save();
            if (returnData) {
                if (returnData?.error) {
                    if (returnData?.error?.name === 'NOTHING_CHANGED') {
                        errorNoti(translator('DASHBOARD.H5P_CONTENTS.H5P_CONTENT_NOT_CHANGED'), <SaveIcon />);
                    }
                }
                if (returnData?.contentId) {
                    setContent({
                        contentId: returnData?.contentId,
                        argsId: {
                            workspaceId: params.id,
                            contentId: returnData?.contentId,
                        },
                        mainLibrary: returnData?.metadata?.mainLibrary,
                        title: returnData?.metadata?.title,
                        originalNewKey: content.originalNewKey,
                        status: 'draft',
                        tags: content?.tags,
                        created_by: {
                            id: userInfo.id,
                        },
                    });
                    await workspaceService.upsertCustomfiledH5p(params.id, returnData?.contentId, { custom_fields });
                    handleAddContentToSection({
                        ...returnData,
                        object_type: LibraryTypeEnum.h5p,
                        object_id: returnData?.contentId,
                        uid: returnData?.uid,
                    });
                    onCloseModal();
                    Toastify.success(translator("DASHBOARD.H5P_CONTENTS.CREATE_H5P_SUCCESS"));
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onEditorLoaded = () => {
        setEntryState({ ...entryState, loading: false });
    };

    const onSaveError = async (event: any) => {
        setEntryState({
            ...entryState,
            saving: false,
            saved: false,
            saveError: true,
            saveErrorMessage: event?.detail?.message,
        });

        setTimeout(() => {
            setEntryState({
                ...entryState,
                saveError: false,
            });
        }, 5000);
    };

    async function onCloseH5P() {
        setIsModal(true);
        const modalCancelProps = {
            component: CANCEL_H5P(translator),
            onFetch: () => onCloseModal(),
        };
        await setModalProps(modalCancelProps);
    }


    React.useEffect(() => {
        setLoading(true);
        clearInterval(intervalRemoveIconZoom);
        var intervalRemoveIconZoom = setInterval(() => {
            const iframe = document?.getElementsByClassName('h5p-editor-iframe')[0];
            if (iframe) {
                //@ts-ignore
                const body = iframe?.contentWindow?.document?.getElementsByTagName('body')?.[0];
                if (body) {
                    body.style.maxWidth = '100%';
                    //@ts-ignore
                    const panel = iframe?.contentWindow?.document?.getElementsByClassName('h5p-section-content-types')?.[0];
                    if (panel) {
                        setLoading(false);
                        clearInterval(intervalRemoveIconZoom);
                    }
                }
            }
        }, 1000);
    }, []);

    return (
        <div className="create-h5p relative">
            {loading && (
                <div className="loading">
                    <Spinner />
                </div>
            )}
            <div className={'header-modal'}>
                <div className={`header-modal-container`}>
                    <div className="header-modal-title">{translator('DASHBOARD.H5P_CONTENTS.NEW_H5P')}</div>
                    <div className="header-modal-action">
                        <div className="header-modal-action-information">
                            <ButtonIcon onClick={() => setShowInformation(true)}>
                                <CircleInformationIcon fill="#1F2937" />
                            </ButtonIcon>
                        </div>
                        <ButtonIcon onClick={onCloseH5P}>
                            <XIcon  className="header-modal-action-close" />
                        </ButtonIcon>
                    </div>
                </div>
            </div>

            <div className="main-content">
                {
                    //@ts-ignore
                    <H5PEditorUI
                        ref={h5pEditor}
                        argsId={content.argsId}
                        loadContentCallback={h5pEditPromise}
                        saveContentCallback={h5pSavePromise}
                        onSaved={handleSaved}
                        onLoaded={onEditorLoaded}
                        onSaveError={onSaveError}
                    />
                }
            </div>
            {!loading && (
                <div className="footer absolute bottom-0">
                    <ButtonOutline type="button" onClick={onCloseH5P}>
                        {translator('DASHBOARD.H5P_CONTENTS.CANCEL')}
                    </ButtonOutline>
                    <ButtonPrimary type="button" onClick={onSave}>
                        {translator('DASHBOARD.H5P_CONTENTS.ADD')}
                    </ButtonPrimary>
                </div>
            )}
            {isModal && <H5PModal isModal={true} setIsModal={setIsModal} modalProps={modalProps} />}
            <DialogComponent
                isOpen={modalCreate}
                onCloseModal={() => history.push(`/workspace/${params.id}/library`)}
                isShowClose={false}
                child={
                    <div className="mb-1">
                        <p>{translator('MODALS.SUCCESS.H5P_CREATE')}</p>
                        <p className="text-ooolab_dark_2 text-ooolab_xs mt-ooolab_m_2">
                            {translator('MODALS.SUCCESS.H5P_REDIRECT_TO_LIST')}
                        </p>
                    </div>
                }
            />

            {isShowInformation && (
                <H5pInformation
                    type="new"
                    onClose={() => setShowInformation(false)}
                    userInfo={userInfo}
                    customFields={customFields}
                    setCustomFields={setCustomFields}
                    className="!top-12"
                    classNameContainer="!max-h-[calc(100%-80px)]"
                />
            )}
        </div>
    );
};

export default CreateH5p;
