import React, { useEffect, useState } from "react";
import PageBreadcrumb from "components/Breadcrumb/PageBreadcrumb";
import { useDebouncedState } from "hooks/useDebounce";
import { useTranslation } from "react-i18next";
import { ButtonPrimary } from "_shared";
import SearchInput from '_shared/components/SearchInput/SearchInput';
import "./index.scss";
import { PlusSmIcon } from "@heroicons/react/solid";
import TableList from "./components/TableList";
import { AddRecipient } from "./components/AddRecipient";
import { useParams } from "react-router-dom";
import absenceRequestService from "services/absenceRequest.service";
import Pagination from 'components/V2/Pagination';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import { AlertIcon } from "assets/icon";

export const RecipientAbsenceRequestPage = () => {
    const { t: translator } = useTranslation();
    const [keyword, setKeyword] = useDebouncedState('', 0);
    const [hasOpen, setHasOpen] = useState(false);
    const params: { id: string } = useParams();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'status',
        total: 0,
    });
    const [indexPage, setIndexPage] = useState('0-0');
    const [hasAbsenceEmpty, setHasAbsenceEmpty] = useState(false);

    const getCountAbsenceRequestRecipients = async () => {
        try {
            const res: any = await absenceRequestService.countAbsenceRequestRecipients(params?.id);
            setHasAbsenceEmpty(res?.data?.need_add_recipient);
        } catch (error) {
            
        }
    }

    const getRecipients = async (page: number = 1) => {
        getCountAbsenceRequestRecipients();
        try {
            const param = {
                page: page,
                per_page: 20,
                q: keyword,
                workspace_id: params?.id
            };
            if (!param.q) delete param.q;
            const res: any = await absenceRequestService.getAbsenceRequestRecipients(params?.id, param);
            setData(res?.data?.items || []);
            setPagination({ ...pagination, ...param, total: res?.data?.total });
            setIndexPage(getIndexPage(param?.page, pagination?.per_page, res?.data?.items?.length));
        } catch (error) {

        }
    }

    const handlePagination = (page: number) => {
        getRecipients(page);
    };

    useEffect(() => {
        if (hasAbsenceEmpty) {
            document.body.classList.add("bodyTop");
            document.body.classList.remove("Noabsence");
        } else {
            document.body.classList.remove("bodyTop");
            document.body.classList.add("Noabsence");
        }
        return () => {
            document.body.classList.remove("bodyTop");
        }
    }, [hasAbsenceEmpty]);

    useEffect(() => {
        getRecipients(1);
    }, [keyword]);

    return (<div className="recipientAbsenceRequestPage">
        {<div className="recipientAbsenceRequestPage-notification">
            <div className="flex items-center gap-2">
                <AlertIcon />
                <p>{translator("The Recipient List for Absence Request is currently empty. Please add recipient(s) to receive notifications.")}</p>
            </div>
            {checkPermission('course', 'C') && <button     
                type="button" 
                className="flex-none text-sm border border-white rounded font-semibold px-3 py-1.5 !w-[90px]"
                onClick={() => setHasOpen(true)}
            >
                {translator('Add Now')}
            </button>}
        </div>}
        <PageBreadcrumb
            pageNameDefault={translator(
                'DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT'
            )}
            pages={[
                { name: translator('COURSES.Recipient(s) of Absence Request Notifications'), href: ``, className: '' },
            ]}
            containerClassName=" z-10"
        />
        <div className="recipientAbsenceRequestPage-header">
            <h1>{translator('COURSES.Recipient(s) of Absence Request Notifications')}</h1>
            <p>{translator('COURSES.This list shows recipient(s) of absence request notifications for all courses')}</p>
        </div>
        <div className="recipientAbsenceRequestPage-container">
            <div className="recipientAbsenceRequestPage-content">
                <div className="recipientAbsenceRequestPage-search">
                    <SearchInput
                        onChange={(value) => setKeyword(value)}
                        value={keyword}
                        placeholder={translator('Search Recipient Email or Name')}
                        className="max-w-[320px]"
                    />
                    {checkPermission('course', 'C') && <ButtonPrimary type="button" onClick={() => setHasOpen(true)} className=' outline-none'>
                        <span className='flex'>
                            <PlusSmIcon
                                className="w-5 h-5"
                            />
                            <span> {translator('Add')}</span>
                        </span>
                    </ButtonPrimary>}
                </div>
                <div className="h-[calc(100vh-322px)] mt-4 overflow-x-auto">
                    <TableList data={data} refreshData={(hasRemove) => {
                        let pageIndex = pagination?.page;
                        if (hasRemove && data?.length == 1) {
                            pageIndex -= 1;
                        }
                        getRecipients(pageIndex);
                    }} />
                </div>
                <Pagination
                    total={pagination.total}
                    per_page={pagination.per_page}
                    callback={(e) => handlePagination(e)}
                    indexPage={indexPage}
                    page={pagination?.page}
                />
            </div>
        </div>
        <AddRecipient hasOpen={hasOpen} onClose={() => setHasOpen(false)} onRefresh={() => getRecipients(1)} />
    </div>)
}