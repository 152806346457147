import { PlusCircleIcon, TrashIcon } from '@heroicons/react/outline';
import { TimePickerField } from 'components/DatePicker';
import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { getDateForDayOfWeek } from 'utils/handleFormatTime';

interface Props {
    control: any;
    index: any;
    watch: any;
    errors: any;
    fieldsParent: any;
    appendParent: any;
    startDate: any;
    endDate: any;
    weekDay: number;
    fieldName?: string;
    today?: boolean;
    clearErrors?: any;
    setValue?: any;
    timeDurations?: number;
    previous?: any;
    trigger?: any;
    isRequired?:boolean;
    hasFilter?:boolean;
    disableInput?: boolean;
    isClearable?: boolean;
}

export default function TimeSlotComponent(props: Props) {
    const {
        control,
        index,
        watch,
        fieldsParent,
        errors,
        appendParent,
        startDate,
        endDate,
        weekDay,
        today,
        clearErrors,
        setValue,
        timeDurations = 15,
        previous,
        trigger,
        isRequired,
        hasFilter = false,
        isClearable = false
    } = props;
    const { fields, append, replace, remove } = useFieldArray({
        control,
        name: `time_range.${index}.timeSlot`,
    });
    const item = useWatch({ control, name: `time_range.${index}` });
    const children = item?.timeSlot ?? [];

    const autoGenerate = (idx) => {
        const obj = {
            weekdayChild: index,
            slot: idx + 1,
            slotStartTime: '',
            slotEndTime: '',
            dateOfWeek: getDateForDayOfWeek(startDate, endDate, weekDay),
        };
        append(obj);
    };
    const removeTimeSlot = (idx) => {
        if (fields?.length) {
            remove(idx);
        }
    };

    useEffect(() => {
        appendParent({
            timeSlot: fields,
        });
    }, [fields]);

    useEffect(() => {
        const obj = {
            weekdayChild: index,
            slot: 0,
            slotStartTime: '',
            slotEndTime: '',
            dateOfWeek: getDateForDayOfWeek(startDate, endDate, weekDay),
        };
        replace([obj]);
    }, []);

    const handleFilterDate = (time: any, date, hasFilter) => {
        const selectedDate = new Date(time);
        if (dayjs(startDate).isAfter(selectedDate) || !hasFilter) {
            return true;
        }
        return (new Date().getTime()) < selectedDate.getTime();
    };

    return (
        <div>
            {fields?.length > 0 &&
                fields?.map((i: any, idx) => {
                    return (
                        <div className="flex  my-2" key={idx}>
                            <div className="w-3/4 flex  space-x-2">
                                <TimePickerField
                                    control={control}
                                    placeholder="Start time"
                                    name={`time_range.${index}.timeSlot.${idx}.slotStartTime`}
                                    startTime={watch(`${i}.slotStartTime`)}
                                    endTime={watch(`${i}.slotEndTime`)}
                                    errors={errors}
                                    isRequired={isRequired}
                                    timeDurations={timeDurations}
                                    filterTimeFunc={(time) => {
                                        const previousField: any = fields[idx-1]
                                        if (previousField?.slotEndTime) {
                                            return dayjs(time)?.isAfter(dayjs(previousField.slotEndTime).add(15, 'minutes'));
                                        }
                                        return watch(`time_range.${index}.timeSlot.${idx}.slotEndTime`)
                                            ? dayjs(time)?.isBefore(watch(`time_range.${index}.timeSlot.${idx}.slotEndTime`))
                                            : moment(i.dateOfWeek).isSame(moment(), 'day')
                                                ? handleFilterDate(time, i.dateOfWeek, hasFilter)
                                                : true;
                                    }}
                                    onChangeValue={(e) => {
                                        setValue(`time_range.${index}.timeSlot.${idx}.slotStartTime`, e, { shouldValidate: true });
                                        if (trigger) {
                                            trigger(`time_range.${index}.timeSlot.${idx}.slotStartTime`);
                                        }
                                    }}
                                    {...{ selected: watch(`time_range.${index}.timeSlot.${idx}.slotStartTime`) }}
                                    isClearable={isClearable}
                                    formatDate='HH:mm'
                                />
                                <TimePickerField
                                    control={control}
                                    placeholder="End time"
                                    name={`time_range.${index}.timeSlot.${idx}.slotEndTime`}
                                    startTime={watch(`${i}.slotStartTime`)}
                                    endTime={watch(`${i}.slotEndTime`)}
                                    errors={errors}
                                    isRequired={isRequired}
                                    timeDurations={timeDurations}
                                    onChangeValue={(e) => {
                                        setValue(`time_range.${index}.timeSlot.${idx}.slotEndTime`, e, { shouldValidate: true });
                                        if (trigger) {
                                            trigger(`time_range.${index}.timeSlot.${idx}.slotEndTime`);
                                        }
                                    }}
                                    filterTimeFunc={(time) => {
                                        const startTime = watch(`time_range.${index}.timeSlot.${idx}.slotStartTime`);
                                        return watch(`time_range.${index}.timeSlot.${idx}.slotStartTime`)
                                            ? dayjs(time)?.isAfter(dayjs(startTime).add(15, 'minutes'))
                                            : true;
                                    }}
                                    isClearable={isClearable}
                                    {...{ selected: watch(`time_range.${index}.timeSlot.${idx}.slotEndTime`) }}
                                    formatDate='HH:mm'
                                />
                            </div>
                            <div className="1/4 flex items-center space-x-4 ml-4 h-[38px]">
                                <button className="" type="button" disabled={fields?.length <= 1} onClick={() => removeTimeSlot(idx)}>
                                    <TrashIcon className={`w-5 h-5 ${fields?.length <= 1 ? 'text-gray-300' : 'text-gray-500'}`} />
                                </button>
                                <button className="" type="button" onClick={() => autoGenerate(idx)} disabled={fields?.length > 4}>
                                    <PlusCircleIcon className="w-5 h-5 text-gray-500" />
                                </button>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
}
