import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './../store';
import moment from 'moment';

interface BookingState {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    error?: any;
    changed: boolean;
    sessions: any;
}

const initialState: BookingState = {
    loading: 'idle',
    changed: false,
    sessions: []
};

export const bookingSlice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        clearError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        clearSlots: (state) => {
            state.sessions = [];
            state.changed = false;
        },
        addSlotToSession: (state, action: PayloadAction<any>) => {
            const value = action.payload;
            let newSession = [];
            if (state.sessions?.length <= 0) {
                 newSession = [{
                    date: value?.date,
                    slots: [value.slot]
                }];
            } else {
            if (state?.sessions.find(item => moment(item.date).format('YYYY-MM-DD') == moment(value.date).format('YYYY-MM-DD'))) {
                const newSessionsAdd = state?.sessions?.map(item => {
                    if (moment(item.date).format('YYYY-MM-DD') == moment(value.date).format('YYYY-MM-DD')) {
                        return {
                            ...item,
                            slots: [...[value.slot], ...item.slots]
                        }
                    }
                    return item;
                });
                newSession = newSessionsAdd;
                } else {
                    newSession = [
                        ...[{
                            date: value?.date,
                            slots: [value.slot]
                        }
                    ], 
                    ...state.sessions];
                }
            }
            state.sessions = newSession;
            state.changed = true;
        },
        updateSlotToSession: (state, action: PayloadAction<any>) => {
            const newSession = state.sessions.map(item => ({
                ...item,
                slots: item?.slots?.map(slot => {
                    if (slot?.session_time?.schedule_time_range_id === action?.payload?.slot?.session_time?.schedule_time_range_id) {
                        return {
                            ...slot,
                            ...action.payload?.slot
                        }
                    }
                    return slot;
                })
            }))
            state.sessions = newSession;
            state.changed = true;
        },
        removeSlotFromSession: (state, action: PayloadAction<string>) => {
            const newSessions = state.sessions.map(item => ({
                ...item,
                slots: item?.slots?.filter(slot => slot?.session_time?.schedule_time_range_id != action.payload)
            }))
            state.sessions = newSessions;
            state.changed = true;
        }
    },
});

export const { clearError, addSlotToSession, removeSlotFromSession, clearSlots, updateSlotToSession } = bookingSlice.actions;

export const selectCourseSlice = (state: RootState) => state.booking;

export default bookingSlice.reducer;
