
import React from "react";
import { useAppDispatch } from "hooks/hooks";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { assignmentDeleteContent } from "store/features/assignmentSlice";
import { ConfirmDeleteModal } from "_shared";

export const AssignmentDelete = ({isOpen, onClose, values}) => {
    const params: {id: string, assignmentId: string} = useParams();
    const {t: translator} = useTranslation();
    const dispatch = useAppDispatch();
    const handleDelete = (value: boolean) => {
        if (!value) {
            return;
        }
        dispatch(assignmentDeleteContent({
            workspaceId: params?.id,
            assignmentId: params?.assignmentId,
            contentId: values?.id
        }));
    }
    return (
        <ConfirmDeleteModal isOpen={isOpen} onClose={(value: boolean) => {
            handleDelete(value);
            onClose();
        }} titleModal={translator('Delete Content')}>
            <div className="text-sm">{translator('Are you sure you want to delete this content?')}</div>
        </ConfirmDeleteModal>
    )
}
