import React, { useState } from 'react';
import { ImageFill } from 'assets/icon';
import { InputField, LabelForm, TextareaField } from 'components/InputForm/InputForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import uploadService from 'services/upload.service';
import { ButtonOutline, ButtonPrimary, InputFile, Toastify } from '_shared';
import './index.scss';
import certificateService from 'services/certificate.service';
import ErrorMessageForm from 'components/ErrorMessageForm';

export const acceptFileCertificate = ['image/jpeg', 'image/jpg', 'image/png'];

export const NewCertificateTemplate = ({ workspaceId, onClose, onRefreshList }) => {
    const { t: translator } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        watch,
        setValue,
        setError,
        clearErrors,
    } = useForm({
        defaultValues: {
            name: '',
            image: '',
            description: '',
        },
    });

    const [loading, setLoading] = useState(false);
    const [imgSrc, setImgSrc] = useState(null);

    const createCertificateTemplate = (payload) => {
        certificateService
            .createTemplateCertificateService(workspaceId, payload)
            .then(() => {
                Toastify.success();
                onRefreshList();
            })
            .catch(() => Toastify.error())
            .finally(() => setLoading(false));
    };

    const onSubmit = async (values) => {
        if (!values?.image) {
            return setError('image', {
                type: 'required',
                message: translator('FORM_CONST.REQUIRED_FIELD'),
            });
        }

        setLoading(true);

        try {
            await uploadService.uploadFile(
                values?.image,
                (path: string) => {
                    createCertificateTemplate({
                        ...values,
                        image: path,
                        description: values?.description || null,
                    });
                },
                () => {}
            );
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const checkImageUpload = (file: any) => {
        setImgSrc(null);
        setValue('image', null);
        clearErrors('image');
        const mimeType = acceptFileCertificate;
        const limitFileSize = 100 * 1024 * 1024;

        const fileSize = file?.size || 0;
        const fileExtension = file?.type || '';

        if (!mimeType.includes(fileExtension) || fileSize > limitFileSize) {
            Toastify.error(translator('CERTIFICATE_MANAGEMENT.WARNING_UPLOAD'));
        } else {
            setValue('image', file);
            setImgSrc(URL.createObjectURL(file));
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} className="newCertificateTemplate">
                <InputField
                    label={translator(translator('CERTIFICATE_MANAGEMENT.Template_Name'))}
                    name="name"
                    isRequired
                    maxLength={255}
                    placeholder={translator('CERTIFICATE_MANAGEMENT.Enter_Template_Name')}
                    register={register('name', {
                        required: translator('FORM_CONST.REQUIRED_FIELD'),
                        validate: {
                            shouldNotContainSpace: (value) => {
                                return value.trim()?.length ? true : `${translator('FORM_CONST.REQUIRED_FIELD')}`;
                            },
                        },
                    })}
                    errors={errors}
                    classNameInput="py-2"
                />
                <div>
                    <LabelForm title={translator('CERTIFICATE_MANAGEMENT.BACKGROUND')} isRequired />
                    <InputFile
                        onChange={checkImageUpload}
                        imgDefault={imgSrc}
                        accept={acceptFileCertificate.toString()}
                        className={`h-[424px] overflow-hidden ${errors?.['image'] ? '!border-red-500' : ''}`}
                        textHelp={translator('CERTIFICATE_MANAGEMENT.MESSAGE_WARNING')}
                        icon={<ImageFill />}
                    />
                    <ErrorMessageForm name="image" errors={errors} />
                </div>
                <TextareaField
                    label={translator('DESCRIPTION')}
                    name="description"
                    className="col-span-4"
                    placeholder={translator('CERTIFICATE_MANAGEMENT.Enter_Description')}
                    register={register('description')}
                    textLength={getValues('description')?.length?.toString()}
                    maxLength={500}
                    isCountter
                />
                <div className="newCertificateTemplate-action">
                    <ButtonOutline type="button" className="" onClick={onClose}>
                        {translator('CANCEL')}
                    </ButtonOutline>
                    <ButtonPrimary type="submit" className="" disabled={loading}>
                        {translator('CREATE')}
                    </ButtonPrimary>
                </div>
            </form>
        </div>
    );
};
