import React, { useEffect, useState } from 'react';
import Progress from '../UploadFile/Progress';

export const ProgressFiles = ({pctComplete=0}) => {
    let INTERVAL_PROCESS;
    const [processPercent, setProcessPercent] = useState(2);
    useEffect(() => {
        const cacularProcess = () => {
            INTERVAL_PROCESS = setInterval(() => {
                if (!INTERVAL_PROCESS) {
                    return;
                }
                let step = 2;
                if (pctComplete >= 90) {
                    step = 90;
                }
                if (processPercent < 100 && pctComplete > 10) {
                    setProcessPercent(prev => prev + step);
                } else {
                    clearInterval(INTERVAL_PROCESS);
                }
            }, 1000);
        }
        cacularProcess();
        return () => {
            clearInterval(INTERVAL_PROCESS);
        };
    }, [pctComplete])

    return <>
        <Progress percentage={processPercent} error="" size={'[50px]'} />
    </>
}