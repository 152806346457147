import WorkspaceConnectGoogle from 'components/Workspace/WorkspaceConnectGoogle';
import React from 'react';

const WorkspaceConnectDrivePage = () => {
    return (
        <WorkspaceConnectGoogle />
    );
};

export default WorkspaceConnectDrivePage;
