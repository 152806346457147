import { accept_file_upload_library } from 'constant/form.const';
import { IParamUploadContext, UploadContext } from 'contexts/Upload';
import { useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import libraryService from 'services/library.service';

export default function UploadFile({folderId,children, fromLesson = false}) {
  const fileInputRef = useRef(null);
  const uploadContext: { handleUploadFile: (params?: IParamUploadContext, handleAfterUploadFile?: any) => void } = useContext(UploadContext);
  const params: {id: string} = useParams();
  return (
    <div
      onClick={() => fileInputRef.current.click()}
      className="group relative w-full py-3 px-4 rounded-lg cursor-pointer items-center"
    >
      {children}
      <input
        multiple
        accept={accept_file_upload_library?.join(",")}
        ref={fileInputRef}
        onChange={(e) =>uploadContext?.handleUploadFile({files: e.target?.files as any, folderId, from_lesson: fromLesson}, (file, onSuccess, onError) => {
          libraryService.uploadFiles(params?.id, file)
          .then(() => {
            onSuccess(file);
          })
          .catch(() => onError(file))
        })}
        className=" hidden"
        type="file"
        placeholder={''}
      />
    </div>
  )
}
