import React, { useContext, useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { CheckIcon, XIcon } from '@heroicons/react/outline';
import { STATUS_COLOR } from 'constant/util.const';
import { UserContext } from 'contexts/User/UserContext';
import { ImageAvatar } from 'components/Image';
import { EditIcon } from 'assets/icon';
import { InputField } from 'components/InputForm/InputForm';
import workspaceService from 'services/workspace.service';
import { Toastify } from '_shared';
import { checkPermission } from 'utils/commonFun';

export const ModalTransactionDetail = ({ translator, workspaceId, creditDetail, transactionDetail, userData }) => {
    const [isEditNotes, setIsEditNotes] = useState(false);
    const [data, setData] = useState(null);
    const [enrollCreditDetail, setEnrollCreditDetail] = useState(null);

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        reset,
        watch,
        setFocus,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            note: null,
        },
    });

    const {
        userState: { result },
    } = useContext(UserContext);

    const fetchTransactionDetail = () => {
        workspaceService.getCreditTransactionsById(workspaceId, creditDetail?.id, transactionDetail?.id).then((res) => {
            const newData = { detail: creditDetail, transaction: res?.data };
            setData({ detail: creditDetail, transaction: res?.data });
            reset({ note: newData?.transaction?.note || null });
        });
    };

    const fetchEnrollCreditDetail = () => {
        workspaceService
            .getEnrollCreditBalanceDetail(workspaceId, data?.transaction?.course_enrollment?.id, userData?.membership?.id)
            .then((res) => {
                setEnrollCreditDetail(res?.data || null);
            });
    };

    const handleUpdateNote = () => {
        const payload = {
            note: getValues()?.note || null,
        };

        workspaceService
            .updateNoteCreditTransactionsById(workspaceId, creditDetail?.id, transactionDetail?.id, payload)
            .then((res) => Toastify.success())
            .catch((res) => Toastify.error())
            .finally(() => setIsEditNotes(false));
    };

    const creditObject = useMemo(() => {
        let amount = data?.transaction?.amount;
        let color = '';
        if (data?.transaction?.txn_type === 'increase') {
            amount = `+${amount}`;
            color = STATUS_COLOR.increase;
        } else if (data?.transaction?.txn_type === 'decrease') {
            amount = `-${amount}`;
            color = STATUS_COLOR.decrease;
        }

        return { amount, color };
    }, [data]);

    useEffect(() => {
        if (creditDetail?.id && transactionDetail?.id) fetchTransactionDetail();
    }, [creditDetail, transactionDetail]);

    useEffect(() => {
        if (data?.transaction?.course_enrollment?.id && userData?.membership?.id) fetchEnrollCreditDetail();
    }, [data, userData]);

    useEffect(() => {
        if (isEditNotes) setFocus('note', { shouldSelect: true });
    }, [isEditNotes]);

    return (
        <div className="-mx-5 px-6 py-4 border-t border-gray-300 grid grid-cols-6 gap-4 text-sm">
            <>
                <p className="col-span-2 font-semibold">{translator('ACCOUNT_NAME')}</p>
                <p className="col-span-4 break-words">{data?.detail?.name}</p>
            </>
            <>
                <p className="col-span-2 font-semibold">{translator('DESCRIPTION')}</p>
                <p className="col-span-4 break-words">
                    <span>{data?.transaction?.description}</span>
                    {!!data?.transaction?.course ? (
                        <>
                            <span className="font-semibold">{` ${data?.transaction?.course?.title} (ID ${data?.transaction?.course?.id})`}</span>
                            <br />
                            {!!enrollCreditDetail?.credit_balance && (
                                <span
                                    className="text-enterprise cursor-pointer"
                                    onClick={() => {
                                        const url = `/workspace/${workspaceId}/management/courses/${data?.transaction?.course?.id}?tab=credit-balance`;
                                        if (data?.transaction?.course_enrollment?.type === 'individual') {
                                            window.open(url + `&creditId=${enrollCreditDetail?.credit_balance?.id}`, '_blank');
                                        } else
                                            window.open(
                                                url +
                                                    `&enrollment_id=${data?.transaction?.course_enrollment?.id}&membership_id=${userData?.membership?.id}`,
                                                '_blank'
                                            );
                                    }}
                                >
                                    {translator('VIEW_CREDIT_BALANCE')}
                                </span>
                            )}
                        </>
                    ) : null}
                </p>
            </>
            <>
                <p className="col-span-2 font-semibold">{translator('CREDIT_S')}</p>
                <p className="col-span-4" style={{ color: creditObject?.color }}>
                    {creditObject?.amount}
                </p>
            </>
            <>
                <p className="col-span-2 font-semibold">{translator('NOTES')}</p>
                <div className="col-span-4">
                    {!isEditNotes ? (
                        <div className="flex items-center">
                            <p className="break-all">{getValues('note')}</p>
                            {!isEditNotes && checkPermission('userDetailsCreditAccounts', 'U') && (
                                <p
                                    className="ml-2 flex-none cursor-pointer text-enterprise flex items-center space-x-1"
                                    onClick={() => setIsEditNotes(true)}
                                >
                                    <EditIcon fill="#0071CE" />
                                    <span>{translator(!watch('note') ? 'ADD_NOTE' : 'EDIT_NOTE')}</span>
                                </p>
                            )}
                        </div>
                    ) : (
                        <div className="flex space-x-2 w-full">
                            <InputField
                                placeholder={translator('ENTER_NOTE_HERE')}
                                name="note"
                                errors={errors}
                                register={register('note')}
                                classNameInput="max-h-[22px] text-xs"
                                className="w-[85%]"
                                maxLength={1000}
                            />
                            <span
                                className={`mt-0.5 ${!!errors['note'] ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                onClick={handleUpdateNote}
                            >
                                <CheckIcon className="w-4 h-4 text-enterprise" />
                            </span>
                            <span
                                className="mt-0.5 cursor-pointer"
                                onClick={() => {
                                    setValue('note', data?.transaction?.note);
                                    setIsEditNotes(false);
                                }}
                            >
                                <XIcon className="w-4 h-4 text-gray-500" />
                            </span>
                        </div>
                    )}
                </div>
            </>
            <>
                <p className="col-span-2 font-semibold">{translator('UPDATED_BY')}</p>
                <div className="col-span-4 truncate flex items-center space-x-2">
                    {!!data?.transaction?.last_editor && (
                        <>
                            <ImageAvatar imageSrc={data?.transaction?.last_editor?.avatar_url} />
                            <span className="truncate text-xs font-semibold text-enterprise">
                                {data?.transaction?.last_editor?.display_name}
                            </span>
                        </>
                    )}
                </div>
            </>
            <>
                <p className="col-span-2 font-semibold">{translator('TRANSACTED_BY')}</p>
                <div className="col-span-4 truncate flex items-center space-x-2">
                    {!!data?.transaction?.creator && (
                        <>
                            <ImageAvatar imageSrc={data?.transaction?.creator?.avatar_url} />
                            <span className="truncate text-xs font-semibold text-enterprise">
                                {data?.transaction?.creator?.display_name}
                            </span>
                        </>
                    )}
                </div>
            </>
            <>
                <p className="col-span-2 font-semibold">{translator('TRANSACTED_DATE')}</p>
                <p className="col-span-4">
                    {data?.transaction?.created_at
                        ? dayjs(data?.transaction?.created_at)
                              .tz(result?.time_zone || 'UTC')
                              .format('DD/MM/YYYY HH:mm:ss')
                        : ''}
                </p>
            </>
        </div>
    );
};
