import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Table from './Table/Table';
import { useTranslation } from 'react-i18next';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { ButtonPrimary } from '_shared';
import './Syllabus.scss';
import courseService from 'services/course.service';
import Pagination from 'components/V2/Pagination';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import { useAppSelector } from 'hooks/hooks';
import DialogComponent from 'components/Dialog/DialogComponent';
import ModalAssignAssignment from '../ModalAssignAssignment';

const Library: React.FC = ({ children }) => {
    const { t } = useTranslation();
    const params: { id: string; courseId: string } = useParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [indexPage, setIndexPage] = useState('0-0');
    const {data: {status}, data: {settings}} = useAppSelector((state) => state.course);   
    const [isOpenModalAssignAssignment, setOpenModalAssignAssignment] = useState(false)
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        total: 0,
    });

    const [keyword, setKeyword] = useDebouncedState('', 0);

    const workspaceId = params.id;
    const courseId = params.courseId;
    const [inputSearchLesson, setInputSearchLesson] = useDebouncedState('');
    const [assignmentSelected, setAssignmentSelected] = useState([]);

    const [lessonList, setLessonList] = useState([]);
    const [rolepagination, setRolePagination] = useState<any>({
        page: 1,
    });
    const [loadingFilterLesson, setLoadingFilterLesson] = useState(false);
    const [lessonSelected, setLessonSelected] = useState([])

    const getLessonList = (page: number = 1, inputSearchLesson = undefined) => {
        setLoadingFilterLesson(true);
        const request: any = {
            page: page ? page : rolepagination.page,
            per_page: 10,
            q: inputSearchLesson,
        };
        
        courseService.getLessonOfCourse(params.id,  courseId, request)
            .then((res) => {
                const formatedData = res?.data?.items?.map((item) => {
                    return {
                        name: item?.title,
                        id: item?.id,
                        value:  item?.id,
                    };
                });
                setRolePagination({ ...rolepagination, total: res?.data?.total, page });
                if (page === 1) setLessonList(formatedData);
                else setLessonList([...lessonList, ...formatedData]);
            })
            .finally(() => setLoadingFilterLesson(false));
    };

    useEffect(() => {
        getLessonList(1, inputSearchLesson);
    }, [inputSearchLesson]);

    const getdata = (page: number | string = 1, keyword = undefined, lessonSelected = []) => {
        setLoading(true);
        const payload = {
            ...pagination,
            page,
            tab: 'syllabus',
            q: keyword,
            lesson_ids: lessonSelected?.map(lesson => lesson?.id)?.join(',')
        };
        if (!payload?.q) delete payload?.q;
        delete payload?.total;
        courseService
            .getCourseExercises(workspaceId, courseId, payload)
            .then((res: any) => {
                setData(res?.data?.items);
                setPagination({ ...pagination, ...payload, total: res?.data?.total });
                setIndexPage(getIndexPage(payload?.page, pagination?.per_page, res?.data?.items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const handlePagination = (page: number) => {
        setAssignmentSelected([])
        getdata(page, keyword, lessonSelected);
    };

    useEffect(() => {
        getdata(1, keyword, lessonSelected);
    }, [keyword, lessonSelected]);

    return (
        <div>
            <div className="SyllabusTab">
                <div className="SyllabusTab-filter">
                    <div className='flex gap-2 w-full'>
                      <SearchBox placeholder={t("COURSES.SEARCH_ASSIGNMENT")} onSubmit={(value) => setKeyword(value)} className='w-full max-w-[220px]' />
                      <SelectBox
                        label={t('COURSES.LESSON')}
                        onSearch={setInputSearchLesson}
                        selectedItems={lessonSelected}
                        data={lessonList}
                        total={lessonList?.length} 
                        onSelect={setLessonSelected}   
                        loading={loadingFilterLesson}   
                        styles={{container: "flex-nowrap"}}                
                      />
                    </div>
                    {checkPermission('course', 'R') && settings?.assignment_release_type === 'manually' && <ButtonPrimary 
                        disabled={(!assignmentSelected?.length) || status === 'inactive'} 
                        type="button" 
                        onClick={() => setOpenModalAssignAssignment(true)}
                    >
                        {t('COURSES.ASSIGN')}
                    </ButtonPrimary>}
                </div>

                <div className="SyllabusTab-main">
                    <div className="wrapper-content">
                        <div className="scrollbar">
                            {loading && (
                                <div className="loading">
                                    <CircleSpin color="text-primary-500" />
                                </div>
                            )}
                            {!loading && 
                              <Table 
                                    data={data} 
                                    assignmentSelected={assignmentSelected}
                                    setAssignmentSelected={setAssignmentSelected}
                                    isAllowAssign={settings?.assignment_release_type === 'manually' && status !== 'inactive'}
                                />
                              }
                        </div>
                        <Pagination
                            total={pagination.total}
                            per_page={pagination.per_page}
                            callback={(e) => handlePagination(e)}
                            indexPage={indexPage}
                            page={pagination?.page}
                        />
                    </div>
                </div>
            </div>
            <DialogComponent 
                isOpen={isOpenModalAssignAssignment}   
                onCloseModal={() => setOpenModalAssignAssignment(false)} 
                title={t("COURSES.ASSIGN_ASSIGNMENT")}   
                styles='max-w-lg'    
                child={
                    <ModalAssignAssignment onCloseModal={() => setOpenModalAssignAssignment(false)} assignmentSelected={assignmentSelected} />
                } 
            />
        </div>
    );
};

export default Library;
