import DialogComponent from 'components/Dialog/DialogComponent'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import classService from 'services/class.service'
import InstructorDetail from '../InstructorInfo'

export default function ModalInfoInstructor({onClose, isOpen, instructorId}) {
  const [data, setData] = useState({})
  const params: any = useParams()
  const {t: translator} = useTranslation()
  useEffect(() => {
    classService.getTeacherDetailService(params?.id, instructorId)
    .then(res => setData(res.data))
    return () => setData({})
  },[instructorId])

  return (
    <DialogComponent
        title={translator("SELF_BOOKING.INSTRUCTOR_INFORMATION")}
        isOpen={isOpen}
        onCloseModal={onClose}
        child={<InstructorDetail instructorDetails={data} />}
        maskClosable={true}
        stylesTitle="text-center justify-center"
      />
  )
}
