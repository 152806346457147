import React from 'react';
import BannerDetail from 'components/Management/Banner/BannerDetail';
import ManagementMasterPage from 'pages/RouteMasterPage';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';

const BannerDetailPage = () => {
    return (
        <ManagePermissionWrapper>
          <BannerDetail >
            <ManagementMasterPage />
          </BannerDetail>
        </ManagePermissionWrapper>
    );
};

export default BannerDetailPage;