import React from "react";
import "./ButtonIcon.scss";
import { clsx } from "clsx";

export const ButtonIcon = ({ children, disabled = false, onClick, type="button", className = "" }) => {
    return <>
        <button className={`buttonIcon ${className ? className : ''} `}
            disabled={disabled}
            type="button"
            onClick={onClick}>{children}</button>
    </>
}
