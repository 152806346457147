
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { InputFile, TagList, Toastify } from "_shared";
import { Settings } from "components/Library/Settings/Settings";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { learningPathUpdate, updateCanSubmmit } from "store/features/learningPathSlice";
import workspaceService from 'services/workspace.service';
import UploadService from 'services/upload.service';
import "./LearningPathInformation.scss";
import { checkPermission } from "utils/commonFun";
import { ChevronDownIcon, ExternalLinkIcon } from "@heroicons/react/outline";
import { Disclosure } from '@headlessui/react';

const LearningPathInformation = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { data, error } = useAppSelector((state) => state.learningPath);
    const params: { id: string, learningPathId: string } = useParams();
    const [customFields, setCustomFields] = useState([]);
    const [isOpenSettings, setIsOpenSettings] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset
    } = useForm({
        mode: "onBlur",
        defaultValues: useMemo(() => {
            return {
                title: data?.title,
                objective: data?.objective ?? null,
                description: data?.description ?? null
            }
        }, [data])
    });

    const translator = (str: string, newKey?: boolean, opt?: any) =>
        newKey ? t(str, opt) : t(`LEARNING_PATH.LEARNING_PATH_DETAIL.${str}`, opt);

    const [isLoadingTags, setIsLoadingTags] = useState(false); 
    
    const handleUpdateTag = async (value) => {
        setCustomFields(value);

        await workspaceService.updateCustomFieldByTypeService(params.id, {
            custom_fields: value.map((item) => ({ custom_field_id: item.id, ...item })),
            id: params.learningPathId,
            type: 'learning-paths',
        });
    };

    useEffect(() => {
        if (data?.title) {
            reset(data);
        }
    }, [data]);
    useEffect(() => {
        if (errors?.title) {
            dispatch(updateCanSubmmit(false));
        } else {
            dispatch(updateCanSubmmit(true));
        }
    }, [errors.title]);

    const getTags = async () => {
        try {
            setIsLoadingTags(true);
            const response: any = await workspaceService.getCustomFieldByTypeService(params.id, {
                order: 'desc',
                sort_by: 'created_at',
                skip_pagination: true,
                type: 'learning-paths',
                id: params?.learningPathId,
            });
            if (response) {
                setCustomFields(response?.data || []);
            }
            setIsLoadingTags(false);
        } catch {
            setIsLoadingTags(false);
        }
    }

    const checkImageUpload = (file: any) => {
        const mimeType = ['image/jpeg', 'image/png'];
        const limitFileSize = 5 * 1024 * 1024;

        const fileSize = file?.size || 0;
        const fileExtenion = file?.type || '';

        if (!mimeType.includes(fileExtenion) || fileSize > limitFileSize) {
            Toastify.error(translator('YOUR_IMAGE_SHOULD_BE_UPLOADED_AS_JPG_PNG_JPED_FILES_AND_MUST_BE_LESS_THAN_5MB_IN_SIZE'))
        } else {
            UploadService.uploadFile(
                file,
                async (path: string) => {
                    dispatch(learningPathUpdate({
                        workspaceId: params?.id,
                        id: params?.learningPathId,
                        banner: path,
                        title: data?.title,
                    }))
                },
                () => { }
            );
        }
    };

    const onSubmit = (value) => {
        dispatch(learningPathUpdate({
            workspaceId: params?.id,
            id: params?.learningPathId,
            title: value?.title,
            objective: value?.objective,
            description: value?.description
        }))
    }

    useEffect(() => {
        if (params?.learningPathId !== "new") {
            getTags();
        }
    }, [params?.learningPathId]);
    return <>
        <form onSubmit={handleSubmit(onSubmit)}>
            {/* <div className="learningPathInformation-item">
                <label className="learningPathInformation-label">
                    {translator('LEARNING_PATH_IMAGE')}
                </label>
                <InputFile className="h-[216px]" onChange={checkImageUpload} imgDefault={data?.banner} isDisable={!checkPermission('learningPath', 'U')} />
            </div> */}
            <div className="learningPathInformation-item">
                <label className="learningPathInformation-label">
                    {translator('LEARNING_PATH_NAME')}<span className="text-red-500 text-xs ml-0.5">*</span>
                </label>
                <input type="text"
                    placeholder={translator("LEARNING_PATH_NAME")}
                    className="learningPathInformation-input"
                    disabled={!checkPermission('learningPath', 'U')}
                    {...register("title", {
                        required: true,
                        maxLength: 255,
                        onBlur: handleSubmit(onSubmit)
                    })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmit(onSubmit);
                        }
                    }}
                    maxLength={255}
                />
                {errors.title && errors.title.type === "required" && (
                    <span className="error">{translator('LEARNING_PATH_NAME_IS_REQUIRED')}</span>
                )}
                {errors.title && errors.title.type === "maxLength" && (
                    <span className="error">{translator('MAX_LENGTH_EXCEEDED')}</span>
                )}
            </div>
            <div className="learningPathInformation-item">
                <div className="relative">
                    <label className="learningPathInformation-label">
                        {translator('OBJECTIVE')}
                    </label>
                    <textarea placeholder={translator("ENTER_LEARNING_PATH_OBJECTIVE")} className="learningPathInformation-textarea"
                        {...register("objective", {
                            maxLength: 255,
                            onBlur: handleSubmit(onSubmit)
                        })}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSubmit(onSubmit);
                            }
                        }}
                        disabled={!checkPermission('learningPath', 'U')}
                        maxLength={255}
                    />
                    <span className="learningPathInformation-sub">({watch("objective")?.length ?? 0}/255)</span>
                </div>
                {errors.objective && errors.objective.type === "required" && (
                    <span className="error">{translator('OBJECTIVE_IS_REQUIRED')}</span>
                )}
                {errors.objective && errors.objective.type === "maxLength" && (
                    <span className="error">{translator('MAX_LENGTH_EXCEEDED')}</span>
                )}
            </div>
            <div className="learningPathInformation-item">
                <div className="relative">
                    <label className="learningPathInformation-label">
                        {translator('DESCRIPTION')}
                    </label>
                    <textarea
                        placeholder={t('ENTER') + translator("DESCRIPTION")}
                        className="learningPathInformation-textarea"
                        {...register("description", {
                            maxLength: 255,
                            onBlur: handleSubmit(onSubmit)
                        })}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSubmit(onSubmit);
                            }
                        }}
                        disabled={!checkPermission('learningPath', 'U')}
                        maxLength={255}
                    />
                    <span className="learningPathInformation-sub">({watch("description")?.length ?? 0}/255)</span>
                </div>
                {errors.description && errors.description.type === "required" && (
                    <span className="error">{translator('DESCRIPTION_IS_REQUIRED')}</span>
                )}
                {errors.description && errors.description.type === "maxLength" && (
                    <span className="error">{translator('MAX_LENGTH_EXCEEDED')}</span>
                )}
            </div>
            <div className="learningPathInformation-disclosure">
                <Disclosure defaultOpen>
                    {({ open }) => (
                        <>
                            <div className="flex items-center justify-between">
                                <label className="learningPathInformation-label !text-sm">
                                    {t('ASSOCIATED_COURSES')}
                                </label>
                                <Disclosure.Button className="">
                                    <ChevronDownIcon className={`h-4 w-4 text-gray-500 ${open ? '-rotate-90 transform' : ''}`} />
                                </Disclosure.Button>
                            </div>
                            <Disclosure.Panel className="">
                                <p className="text-gray-500 text-xs">
                                    {t(
                                        !!data?.associatedCourses?.length ? 'ASSOCIATED_COURSES_MESSAGE' : 'ASSOCIATED_COURSES_MESSAGE_NODATA',
                                        { course_length: data?.associatedCourses?.length })
                                    }
                                </p>
                                {!!data?.associatedCourses?.length && (
                                    <div className="mt-1 flex flex-col space-y-1">
                                        {data?.associatedCourses.map((item, index) => (
                                            <p 
                                                key={index} 
                                                className={`text-xs font-semibold flex items-center space-x-1 ${checkPermission('course', 'R') ? 'text-enterprise cursor-pointer' : 'text-gray-800'}`} 
                                                onClick={() => {
                                                    if (checkPermission('course', 'R')) {
                                                        history.push(`/workspace/${params?.id}/management/courses/${item?.id}?tab=academics&subtab=syllabus`);
                                                    }
                                                }}
                                            >
                                                <span className="mb-0.5">●</span>
                                                <span>{item?.title}</span>
                                            </p>
                                        ))}
                                    </div>
                                )}
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            </div>
            <div className="learningPathInformation-tags">
                <div className="flex justify-between items-center">
                    <label className="learningPathInformation-label !text-sm">
                        {t('CUSTOM_TAGS')}
                    </label>
                    <div className="flex-none">
                        <button 
                            onClick={() => setIsOpenSettings(true)} 
                            disabled={!checkPermission('learningPath', 'U')}
                            className="text-xs text-enterprise flex items-center space-x-1"
                        >
                            {t(customFields?.length > 0 ? 'EDIT' : 'ADD_NEW')}
                            <ExternalLinkIcon className="text-enterprise w-4 h-4" />
                        </button>
                    </div>
                </div>
                <div className={`learningPathInformation-info ${customFields?.length > 0 ? 'learningPathInformation-info--hasData' : ''}`}>
                    {isLoadingTags ? <div className="learningPathInformation-loading">
                        <CircleSpin className="text-primary-500" /></div> :
                        customFields?.length > 0 ?
                            <TagList customFields={customFields}
                                setCustomFields={handleUpdateTag}
                                loading={false} hasTitle={false}
                                className="noBackground"
                                isEdit={checkPermission('learningPath', 'U')}
                            /> :
                            <>
                                {t('THERE_ARE_NO_CUSTOM_FIELDS')}
                            </>
                    }
                </div>
                <Settings isOpen={isOpenSettings} onClose={() => {
                    setIsOpenSettings(false);
                    getTags();
                }} />
            </div>
        </form>
    </>
}
export default LearningPathInformation;