import { CalendarIcon } from '@heroicons/react/outline';
import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import './styles.scss';


interface Props {
    startDate: any;
    endDate: any;
    onChange: (value: any) => void;
}

export default function DatePickerWeekComponent(props: Props) {
    const { endDate, startDate, onChange } = props;
    const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
        <button
            className="border border-ooolab_gray_5 text-xs px-5 py-2 rounded-lg flex items-center w-full font-semibold"
            onClick={onClick}
            type='button'
            ref={ref}
        >
            <CalendarIcon width={16} height={16} /> <span className="ml-2">{value}</span>
        </button>
    ));

    return (
        <div className='w-full custom-daypicker'>
            <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                showPopperArrow={false}
                minDate={new Date()}
                maxDate={new Date(startDate?.getTime() + 365 * 24 * 60 * 60 * 1000)}
                customInput={<CustomInput />}
                dateFormat="dd/MM/yyyy"
            />
        </div>
    );
}
