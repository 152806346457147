import React, { Fragment, ReactNode, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { CheckboxType } from "types/Lesson.type";
import { CheckboxFill } from "assets/icon";
import SearchBox from "components/SearchBox";
import useComponentVisible from "utils/UseComponentVisible";
import './Dropdown.scss';
let deplayChange;
let deplaySearch;

interface DropdownProps {
    items?: CheckboxType[] | any,
    children?: ReactNode,
    className?: string,
    hasArrow?: boolean,
    placeholder?: string,
    displayKey?: string,
    isDisplayFullKey?: boolean;
    isSearchable?: boolean,
    onChange: (value: CheckboxType[]) => void,
    onRemove?: () => void,
    selectedValue?: any;
}
export const Dropdown = ({
    items,
    className,
    hasArrow = true,
    children,
    placeholder,
    displayKey = 'name',
    isDisplayFullKey = false,
    onChange,
    selectedValue,
}: DropdownProps) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState(null);

    const handleChange = (value) => {
        setSelected(value);
        setIsComponentVisible(false);
        onChange(value);
    }

    useEffect(() => {
        if (items) {
            setOptions(items);
        }
    }, [items]);

    useEffect(() => {
        setSelected(selectedValue);
    }, [selectedValue])

    return (
        <div className={`dropdown ${className ? className : ''} ${selected?.id ? 'dropdown--selected' : ''}
        ${isComponentVisible ? 'dropdown--actived' : 'border-gray-200'}`} ref={ref}>
            <Listbox value={selected} onChange={handleChange}>
                <div className='relative'>
                    <Listbox.Button
                        className={`dropdown-button min-h-[36px]`}
                        onClick={() => { setIsComponentVisible(!isComponentVisible) }}>
                        {children ? children : <>
                            <span className="truncate w-full">
                                {selected?.[displayKey] ? selected?.[displayKey] : placeholder}
                            </span>
                        </>}
                        {(hasArrow) || (selected?.length == 0) ? <span className="dropdown-arrow text-[8px]">
                            <ChevronDownIcon className={`w-4 h-4 transition ${isComponentVisible ? '-rotate-180' : ''}`} />
                        </span> : ''}
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        show={isComponentVisible}
                    >
                        <div className='dropdown-panel w-[calc(100%)]'>
                            <Listbox.Options className="max-h-[205px] overflow-auto  focus:outline-none mt-1">
                                {options?.length > 0 ? options?.map((item: any, index) => (
                                    item[displayKey] && <>
                                        <Listbox.Option
                                            key={'dropdown'+ item?.id}
                                            className={({ active }) => `relative cursor-default py-0 ${active ? '' : 'text-gray-900'}`}
                                            value={item}
                                        >
                                            {() => (
                                                <>
                                                    <div className={`dropdown-item ${selected?.id == item?.id ? 'dropdown-item--selected' : ''}`} key={'dropdown_'+ item?.id + index}
                                                        onClick={() => handleChange(item)}
                                                    >
                                                        <div className={`dropdown-checkbox hidden ${selected?.id == item?.id ? 'dropdownAsync-checkbox--checked' : ''}`}>
                                                            <label>
                                                                <CheckboxFill />
                                                            </label>
                                                        </div>
                                                        <span className="max-w-[332px]">
                                                            {item[displayKey]?.length > 25 && !isDisplayFullKey ? `${item[displayKey]?.slice(0,25)}...` : item[displayKey]}
                                                        </span>
                                                    </div>
                                                </>
                                            )}
                                        </Listbox.Option>
                                    </>
                                )): null}
                            </Listbox.Options>
                        </div>
                    </Transition>
                </div>
            </Listbox>
        </div>
    )
}