import { FC, useContext, useMemo } from 'react';
import { CourseType } from 'types/Courses.type';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import ActionMenu from './ActionMenu';
import dayjs from 'dayjs';
import './Table.scss'
import { ENROLLMENT_STATUS } from '../../constants';
import { UserContext } from 'contexts/User/UserContext';
import { ListUser } from '_shared/components/User/User';
import { useHistory, useParams } from 'react-router-dom';
import EnrollmentStatus from '_shared/components/EnrollmentStatus/EnrollmentStatus';

interface IViewTable {
    data: CourseType[];
    refreshData: Function
}

const ViewTable: FC<IViewTable> = ({
    data,
    refreshData
}) => {
    const { t: translator } = useTranslation();

    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;
    const params: any = useParams();
    const {
        userState: { result },
    } = useContext(UserContext);
    const history = useHistory();

    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('COURSES.Enrollment')} />,
                Cell: ({row}: any) => {
                    console.log(row);
                    return (
                        <RenderTableCell 
                            onClick={() => history?.push(`/workspace/${params.id}/management/courses/${params.courseId}?tab=people&subtab=enrollment&enrollmentId=${row?.values?.id}&view=information`)} 
                            className='text-primary-500 font-semibold cursor-pointer' value={`(ID: ${row.values?.id}) ${row?.original?.type === 'learning_group' ?   row?.original?.learning_group?.name : row?.original?.learners[0]?.display_name}`} 
                        />
                    )
                },
                accessor: 'id',
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.Numberoflearner')} />,
                accessor: 'learners',
                Cell: ({row}: any) => {
                    return (
                        <div className='flex ml-3 text-xs'>
                            {row?.original?.learners?.length}
                        </div>
                    )
                }
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.ENROLLMENT_AS')} />,
                accessor: 'type',
                Cell: ({row}: any) => <RenderTableCell className='capitalize' value={row.values?.type?.replace('_', ' ')} />
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.START_DATE')} />,
                accessor: 'start_date',
                Cell: ({row}: any) => <RenderTableCell value={dayjs.utc(row.values?.start_date).tz(result?.time_zone).format("DD/MM/YYYY")} />
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.END_DATE')} />,
                accessor: 'end_date',
                Cell: ({row}: any) => <RenderTableCell value={dayjs.utc(row.values?.end_date).tz(result?.time_zone).format("DD/MM/YYYY")} />
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.STATUS')} />,
                accessor: 'display_status',
                Cell: ({row}: any) => {
                    if (!row?.values?.display_status) return <RenderTableCell value=''/>
                    return (
                        <EnrollmentStatus status={row?.values?.display_status} />
                    );
                },
            },
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="h-full flex items-center justify-center">
                            <ActionMenu
                                data={row?.original}
                                refreshData={refreshData}
                                onViewDetail={() => history?.push(`/workspace/${params.id}/management/courses/${params.courseId}?tab=people&subtab=enrollment&enrollmentId=${row?.values?.id}&view=information`)}
                                position={row?.index > data?.length/2 ? 'bottom-0' : ''}
                            />
                        </div>
                    );
                },
                accessor: 'action',
                width: 40,
            },
        ],
        [data, WorkspaceDetailInformation]
    );

    return (
        <Table
            data={data || []}
            columns={columns}
            emptyContent={translator('COURSES.NO_ENROLLMENT')}
        />
    );
};

export default ViewTable;
