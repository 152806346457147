import { Transition } from '@headlessui/react';
import React from 'react';
import './NotePopup.scss';
import { CommentIcon } from 'assets/icon';
import { useTranslation } from 'react-i18next';
import { ChevronDoubleRightIcon, XIcon } from '@heroicons/react/outline';

interface DialogProps {
    isOpen: boolean;
    onCloseModal?: (e?: any) => void;
    child?: React.ReactNode;
    noteData: any;
    isZoom: boolean;
}

const NotePopup: React.FC<DialogProps> = ({ isOpen, onCloseModal, noteData, isZoom = false }) => {
    const { t } = useTranslation();
    return (
        <Transition
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            show={isOpen}
            className={`notePopup ${isZoom ? 'fullScreenMode' : ''}`}
        >
            <div className="container">
                <div className="content">
                    <div className="contentHeader">
                        <div className="contentHeader-label">
                            <CommentIcon width={20} height={20} />
                            <span className="contentHeader-text">{t('NOTES')}</span>
                        </div>
                        <button
                            type="button"
                            onClick={() => {
                                onCloseModal();
                            }}
                        >
                            {isZoom ? (
                                <XIcon width={20} height={20} />
                            ) : (
                                <ChevronDoubleRightIcon width={24} height={24} className="text-gray-500" />
                            )}
                        </button>
                    </div>
                    <div className={`content-notes ${isZoom? 'fullscreen': 'default'}`}>
                        {noteData ? (
                            <div className="content-body" dangerouslySetInnerHTML={{ __html: noteData }}></div>
                        ) : (
                            <div className='content-notes--empty'>{t('EMPTY_NOTES')} </div>
                        )}
                    </div>
                </div>
            </div>
        </Transition>
    );
};

export default NotePopup;
