import './DisclosurePanel.scss'

export default function DisclosurePanel({
  children
}) {
  return (
    <div className="listChildren">
        {children}
    </div>
  )
}


