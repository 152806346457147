import React, { forwardRef } from "react";
import { useAppDispatch, useAppSelector } from "hooks/hooks";

import Dropzone from "components/Library/components/Upload/Dropzone";
import { assignmentCreateContent, assignmentFetch } from "store/features/assignmentSlice";
import { useParams } from "react-router-dom";

const AssignmentUploadFile = ({ }, ref) => {
    const params: {id: string, assignmentId: string} = useParams();
    const dispatch = useAppDispatch();
    const {data} = useAppSelector(state => state.assignment);
    const addFileToAssignment = async (values: any) => {
        if (!values?.file?.startsWith('http')) {
            values.file = process.env.REACT_APP_HOST_CDN + values?.file;
        }
        await dispatch(assignmentCreateContent({
            workspaceId: params?.id,
            assignmentId: params?.assignmentId,
            object_id: values.object_id,
            object_type: values.object_type,
            order_number: data?.sections?.length + 1 || 0,
            library_content: values,
        }));
    }
    return <Dropzone updateContentUpload={addFileToAssignment} ref={ref} />
}

export default forwardRef(AssignmentUploadFile);