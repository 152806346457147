export const TagIcon = ({
    width = '20',
    height = '20',
}: {
    width?: string;
    height?: string;
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.66669 2.50002C1.66669 2.03978 2.03978 1.66669 2.50002 1.66669H9.16669C9.3877 1.66669 9.59966 1.75448 9.75594 1.91076L18.0893 10.2441C18.4147 10.5695 18.4147 11.0972 18.0893 11.4226L11.4226 18.0893C11.0972 18.4147 10.5695 18.4147 10.2441 18.0893L1.91076 9.75594C1.75448 9.59966 1.66669 9.3877 1.66669 9.16669V2.50002ZM3.33335 3.33335V8.82151L10.8334 16.3215L16.3215 10.8334L8.82151 3.33335H3.33335Z" fill="#0071CE" />
            <path d="M7.50002 6.25002C7.50002 6.94038 6.94038 7.50002 6.25002 7.50002C5.55966 7.50002 5.00002 6.94038 5.00002 6.25002C5.00002 5.55966 5.55966 5.00002 6.25002 5.00002C6.94038 5.00002 7.50002 5.55966 7.50002 6.25002Z" fill="#0071CE" />
        </svg>

    );
};
