import { FC } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EyeIcon } from '@heroicons/react/outline';
import ActionsButton from 'components/ActionsButton';
import { USER_STATUS } from 'components/Management/User/constant';

interface ActionMenuProp {
    refreshData?: Function,
    data?: any;
    hideAction?: string,
    userData: any
}

const ActionMenu: FC<ActionMenuProp> = ({data, userData}) => {
    const { t: translator } = useTranslation();
    const history = useHistory()
    const params: {id: string, userId: string} = useParams()
    const search = useLocation()?.search;
    const courseId = new URLSearchParams(search).get('courseId');

    return (
        <>
            <ActionsButton
                styleOptions="top-0 right-[35px]"
                menus={[
                    {
                        label: translator("EVALUATE.EVALUATE"),
                        icon: <EyeIcon className='w-4 h-4' />,
                        action: () => {
                            let url = `/workspace/${params?.id}/management/users/${params?.userId}/session/${data?.id}/grade?&courseId=${courseId}`
                            return history.push(url)
                        },
                        hide: false,
                        isDisabled: data?.display_status == 'late_cancellation' || userData?.membership?.status === USER_STATUS.DEACTIVATE
                    },
                ]}
            />

        </>
    );
};

export default ActionMenu;
