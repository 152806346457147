/* This example requires Tailwind CSS v2.0+ */
// PACKAGE
import { Dialog, RadioGroup, Transition } from '@headlessui/react';
import H5Psquare from 'assets/SVG/H5Psquare.svg';
import { H5P_LIBRARY } from 'constant/h5p.const';
// CONTEXTS
import { H5PContext } from 'contexts/H5P/H5PContext';
import dayJs from 'dayjs';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import h5PServices from 'services/h5p.service';
//TYPE
import { IH5PContentList } from 'types/H5P.type';
import { SectionState } from 'types/Lesson.type';
import { getLocalStorageAuthData } from 'utils/handleLocalStorage';
import './style.css';
import SearchBox from 'components/V2/SearchBox';

export interface ListH5PModalProps {
    isOpen: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    contentList: IH5PContentList;
    workspaceID: string;
    // setSectionState: React.Dispatch<React.SetStateAction<SectionState>>;
    setSectionState: (sec: SectionState) => void;
    sectionState: SectionState;
}

const ListH5PModal: React.FC<ListH5PModalProps> = ({
    isOpen,
    setOpen,
    contentList,
    workspaceID,
    setSectionState,
    sectionState,
}) => {
    const { t: translator } = useTranslation();

    const cancelButtonRef = useRef(null);
    const { time_zone } = getLocalStorageAuthData();

    const [selectedItem, setSelectedItem] = useState<string[]>([]);

    const [selected, setSelected] = useState<string>();

    const [isEmbed, setIsEmbed] = useState<string>();

    const params: { id: string } = useParams();

    const [original, setOriginal] = useState<IH5PContentList>(contentList);

    const [searchText, setSearchText] = useState<string>('');

    useEffect(() => {
        const flatSelected = sectionState.files.map((i) => i.uid);
        const result = contentList?.items.map((i) => ({
            ...i,
            check: flatSelected.includes(i.uid),
        }));
        setOriginal({
            items: result,
            order: contentList.order,
            page: contentList.page,
            per_page: contentList.per_page,
            sort_by: contentList.sort_by,
            total: contentList.total,
        });
    }, [contentList]);

    const [searchResults, setSearchResults] = useState<IH5PContentList>(
        original
    );

    const h5PCtx = React.useContext(H5PContext);
    const {
        dispatch,
        H5PState: { h5PApproveContentResult, err },
    } = h5PCtx;

    const {
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
    } = useForm();

    function onClose() {
        setOpen(false);
    }

    function onSubmit() {
        return async () => {
            if (selected) {
                const itemH5P = original?.items.find(
                    (i) => i.contentId == selected
                );
                setSelectedItem([...selectedItem, selected]);
                if (itemH5P?.uid) {
                    const url =
                        window.location.hostname === 'localhost'
                            ? 'http://'
                            : 'https://';
                    setIsEmbed(
                        `${url}${window.location.host}/contents/${itemH5P.uid}/embed`
                    );
                    // setIsEmbed(`${HOST_URL}/contents/${itemH5P.uid}/embed`);
                }
                // else {
                //     const argsId: IH5PPlayerArgs = {
                //         contentId: selected,
                //         workspaceId: workspaceID,
                //     };
                //     await h5pMiddleware.h5pApproveContent(dispatch, argsId);
                // }
            }
        };
    }

    useEffect(() => {
        if (isEmbed) {
            const flatSelected = sectionState.files.map((i) => i.uid);
            const itemH5P = original?.items.find(
                (i) => i.contentId == selected
            );
            setSectionState({
                title: sectionState.title,
                description: sectionState.description,
                files: [
                    {
                        uid: itemH5P?.uid || '',
                        file_name: itemH5P?.title || ' ',
                        file_url: isEmbed,
                        file_mimetype: 'application/h5p',
                        main_library: itemH5P?.mainLibrary,
                        id: itemH5P.contentId,
                    },
                    ...(sectionState.files || []),
                ],
                type: sectionState.type,
            });
            setOriginal({
                ...original,
                // items: original?.items?.filter(
                //     (item: any) => item.contentId !== selected
                // ),
                items: original?.items?.map((i) => ({
                    ...i,
                    check:
                        selected === i.contentId || flatSelected.includes(i.uid)
                            ? true
                            : false,
                })),
            });
            setSearchResults({
                ...searchResults,
                items: searchResults?.items?.filter(
                    (item: any) => item.contentId !== selected
                ),
            });
        }
    }, [isEmbed]);

    function handleSelected(e: any) {
        setSelected(e);
    }

    const scrollToTop = () => {
        const e = document.getElementsByClassName(
            'infinite-scroll-component'
        )[0];
        if (e) {
            e.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    async function handleSearch(t) {
        setSearchText(t);
        let arrayUID = [];
        sectionState.files.map((d) => arrayUID.push(d.uid));
        const flatSelected = sectionState.files.map((i) => i.uid);
        await h5PServices
            .h5pContentList(params.id, { title: t, page: 1 })
            .then((result) => {
                // const itemH5P = result?.items?.map(
                //     (i) => !arrayUID.includes(i?.uid)
                // );

                const itemH5P = result?.items?.map((i) => ({
                    ...i,
                    check:
                        flatSelected.includes(i.uid) ||
                        selectedItem.includes(i.contentId)
                            ? true
                            : false,
                }));

                setOriginal({
                    items: itemH5P,
                    order: result.order,
                    page: result.page,
                    per_page: result.per_page,
                    sort_by: result.sort_by,
                    total: result.total,
                });
                scrollToTop();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    function getMoreH5P() {
        let arrayUID = [];
        sectionState.files.map((d) => arrayUID.push(d.uid));
        const flatSelected = sectionState.files.map((i) => i.uid);

        h5PServices
            .h5pContentList(params.id, {
                title: searchText,
                page: original.page + 1,
            })
            .then((result) => {
                // const itemH5P = result?.items?.filter(
                //     (i) => !arrayUID.includes(i?.uid)
                // );
                const itemH5P = result?.items?.map((i) => ({
                    ...i,
                    check:
                        flatSelected.includes(i.uid) ||
                        selectedItem.includes(i.contentId)
                            ? true
                            : false,
                }));

                setOriginal({
                    items: original?.items.concat(itemH5P),
                    order: result.order,
                    page: result.page,
                    per_page: result.per_page,
                    sort_by: result.sort_by,
                    total: result.total,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    // function handleSubmitSearch() {
    //     const value = getValues('h5p');
    //     if (value) {
    //         const itemH5P = original?.items.filter((i) =>
    //             i.title.includes(value)
    //         );
    //         if (itemH5P) {
    // setSearchResults({
    //     items: itemH5P,
    //     order: original.order,
    //     page: original.page,
    //     per_page: original.per_page,
    //     sort_by: original.sort_by,
    //     total: original.total,
    // });
    //         }
    //     } else {
    //         setSearchResults(original);
    //     }
    // }

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-70 inset-0 overflow-y-hidden"
                initialFocus={cancelButtonRef}
                open={isOpen}
                onClose={onClose}
            >
                <div className="flex i justify-center min-h-screen p-4 pb-20 text-center items-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 dialog-background  bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <div
                            style={{
                                zIndex: 999,
                            }}
                            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                        >
                            <form
                                className="z-999"
                                onSubmit={handleSubmit(onSubmit())}
                            >
                                <div className="">
                                    <div className="flex items-center ">
                                        {/* <p className="w-10/12 text-ooolab_base text-ooolab_dark_1">
                                            {translator(
                                                'ADD_H5P_CONTENT.SELECT_H5P_FILE'
                                            )}
                                        </p> */}
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg leading-6 font-medium text-gray-900"
                                        >
                                            {translator(
                                                'ADD_H5P_CONTENT.SELECT_H5P_FILE'
                                            )}
                                        </Dialog.Title>
                                        {/* <button className="flex items-center bg-ooolab_bg_bar rounded-header_menu py-ooolab_p_1 px-ooolab_p_3 focus:outline-none">
                                            <img
                                                src={filterIcon}
                                                alt=""
                                                className="w-4 h-4"
                                            />
                                            <p className="text-ooolab_sm text-primary-500 ml-ooolab_m_2">
                                                {' '}
                                                Filter
                                            </p>
                                        </button> */}
                                    </div>
                                    <div className="relative w-full mt-2">
                                        {/* <input
                                            type="text"
                                            placeholder={translator(
                                                'ADD_H5P_CONTENT.SEARCH'
                                            )}
                                            className="focus:outline-none pl-2 pr-2 px-2 w-full h-full border border-ooolab_border_logout rounded-sub_tab"
                                            onChange={(e) => onChangeInput(e)}
                                            onKeyDown={(e: any) => {
                                                if (e.key === 'Enter') {
                                                    handleSearch(
                                                        e.target.value
                                                    );
                                                }
                                            }}
                                        ></input>
                                        <SearchIcon
                                            className="absolute top-ooolab_8px right-ooolab_8px w-ooolab_w_5 h-ooolab_h_5 text-ooolab_dark_2 hover:text-ooolab_blue_4 cursor-pointer"
                                            onClick={() =>
                                                handleSearch(inputValue)
                                            }
                                        /> */}
                                        <SearchBox onSubmit={handleSearch} />
                                    </div>
                                </div>
                                <div className="overflow-x-auto">
                                    <div className="py-2 align-middle inline-block min-w-full  ">
                                        <div className=" overflow-hidden border-t border-b">
                                            <div className="grid gap-2 grid-cols-10 my-2 px-2">
                                                <div className="col-span-4 flex items-center">
                                                    <p className="text-sm font-semibold text-ooolab_dark_1">
                                                        {translator(
                                                            'ADD_H5P_CONTENT.NAME'
                                                        )}
                                                    </p>
                                                </div>

                                                <div className="col-span-3 justify-center  flex items-center">
                                                    <p className="text-sm font-semibold text-ooolab_dark_1">
                                                        {translator(
                                                            'ADD_H5P_CONTENT.LAST_MODIFIED'
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="col-span-3 text-center ">
                                                    <p className="text-sm font-semibold text-ooolab_dark_1">
                                                        {translator(
                                                            'ADD_H5P_CONTENT.CONTENT_TYPE'
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <InfiniteScroll
                                                dataLength={
                                                    original.items?.length
                                                }
                                                next={() => getMoreH5P()}
                                                hasMore={
                                                    original?.items.length !==
                                                    original.total
                                                }
                                                loader={
                                                    false && (
                                                        <h4>
                                                            {translator(
                                                                'LOADING'
                                                            )}
                                                        </h4>
                                                    )
                                                }
                                                height={'280px'}
                                                scrollableTarget="scrollableDiv "
                                                className="custom-style-scrollbar scrollData"
                                            >
                                                <>
                                                    <div
                                                        className="overflow-y-auto custom-scrollbar "
                                                        id="h5p-data"
                                                    >
                                                        <RadioGroup
                                                            value={selected}
                                                            onChange={(e) =>
                                                                handleSelected(
                                                                    e
                                                                )
                                                            }
                                                        >
                                                            {original?.items.map(
                                                                (d) => (
                                                                    <RadioGroup.Option
                                                                        key={
                                                                            d.contentId
                                                                        }
                                                                        disabled={
                                                                            d.check
                                                                        }
                                                                        value={
                                                                            d.contentId
                                                                        }
                                                                        className={({
                                                                            active,
                                                                            checked,
                                                                            disabled,
                                                                        }) =>
                                                                            `${
                                                                                active &&
                                                                                !disabled &&
                                                                                'bg-ooolab_bg_bar text-primary-500'
                                                                            }
                                            ${
                                                checked &&
                                                !disabled &&
                                                'bg-ooolab_bg_bar text-primary-500'
                                            }

                                      ${
                                          !disabled
                                              ? 'hover:bg-ooolab_bg_bar cursor-pointer '
                                              : 'opacity-20 cursor-not-allowed'
                                      } w-full grid grid-cols-10 gap-2 py-2 px-5`
                                                                        }
                                                                    >
                                                                        {({
                                                                            active,
                                                                            checked,
                                                                        }) => (
                                                                            <>
                                                                                <div className={`whitespace-nowrap col-span-4`}>
                                                                                    <span className="flex items-center text-ooolab_1sx font-normal">
                                                                                        <img
                                                                                            src={
                                                                                                H5Psquare
                                                                                            }
                                                                                            alt=""
                                                                                            className="mr-ooolab_m_2 w-ooolab_w_6 h-ooolab_h_6"
                                                                                        />
                                                                                        <p
                                                                                            className={
                                                                                                'text-ooolab_1xs overflow-hidden overflow-ellipsis whitespace-pre  text-ooolab_dark_1'
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                d.title
                                                                                            }
                                                                                        </p>
                                                                                    </span>
                                                                                </div>

                                                                                <div className=" whitespace-nowrap col-span-3 text-center">
                                                                                    <span className=" inline-flex text-ooolab_1xs leading-5 ">
                                                                                        <p
                                                                                            className={`text-ooolab_1xs 
                                                                                                 text-ooolab_gray_3
                                                                                            `}
                                                                                        >
                                                                                            {d.updated_at &&
                                                                                                time_zone &&
                                                                                                dayJs
                                                                                                    .utc(
                                                                                                        d.updated_at
                                                                                                    )
                                                                                                    .tz(
                                                                                                        time_zone
                                                                                                    )
                                                                                                    .locale(
                                                                                                        'en-gb'
                                                                                                    )
                                                                                                    .fromNow()}
                                                                                        </p>
                                                                                    </span>
                                                                                </div>
                                                                                <div className=" whitespace-nowrap col-span-3 text-center">
                                                                                    <span className=" inline-flex text-ooolab_1xs leading-5  ">
                                                                                        <p
                                                                                            className={`text-ooolab_1xs
                                                                                                text-ooolab_gray_3
                                                                                            `}
                                                                                        >
                                                                                            {d?.mainLibrary &&
                                                                                                H5P_LIBRARY[
                                                                                                    d?.mainLibrary?.split(
                                                                                                        '.'
                                                                                                    )[1]
                                                                                                ]}
                                                                                        </p>
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </RadioGroup.Option>
                                                                )
                                                            )}
                                                        </RadioGroup>
                                                    </div>
                                                </>
                                            </InfiniteScroll>
                                        </div>
                                    </div>
                                </div>
                                {/* <button className="bg-white float-right text-xs mr-ooolab_m_5 p-1 mb-1 text-primary-500 px-ooolab_p_3 rounded-header_menu focus:outline-none border border-ooolab_blue_1  hover:bg-primary-500 hover:text-white focus:bg-primary-500 focus:text-white">
                                    {translator('ADD_H5P_CONTENT.SELECT')}
                                </button> */}
                                <div className="py-2 sm:flex sm:flex-row-reverse">
                                    <button className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-sm border-ooolab_blue_1 font-medium text-primary-500 hover:bg-primary-500 hover:text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm">
                                        {translator('ADD_H5P_CONTENT.SELECT')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default ListH5PModal;
