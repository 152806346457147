import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import activityReportService from 'services/reports/activity-report.service';
import { ActivitiesOverviewModel } from 'types/ActivityDashboard.type';
import { CardContainer } from '../components/CardContainer';
import { CardInfo } from '../components/CardInfo';
import DatePickerComponent from '../components/DatePicker';
import { Title } from '../components/Title';
import { LastActivity } from './LastActivity';

export const UserActivityDashboard = () => {
    const params: { id: string } = useParams();
    const { t } = useTranslation();
    const translator = (str: string, opt?: any) => t(`USER_ACTIVITY_DASHBOARD.${str}`, opt);

    const [startDate, setStartDate] = useState(new Date(moment().subtract(1, 'months').format('MM-DD-YYYY')));
    const [endDate, setEndDate] = useState(new Date());
    const [activitiesOverview, setActivitiesOverview] = useState<ActivitiesOverviewModel>();

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    useEffect(() => {
        const getActivitiesOverview = async () => {
            try {
                const res = await activityReportService.getActivitiesOverview(params?.id, {
                    start_time: moment(new Date(startDate)).format('YYYY-MM-DD')+ 'T00:00:00.000Z',
                    end_time: moment(new Date(endDate)).format('YYYY-MM-DD')+ 'T23:59:59.000Z',
                });
                if (res?.data) {
                    setActivitiesOverview(res.data);
                }
            } catch (error) {

            }
        }
        if (endDate) {
            getActivitiesOverview();
        }
    }, [endDate]);

    return (
        <div className="w-full">
            <PageBreadcrumb
                pageNameDefault={t('DASHBOARD.SIDEBAR.ANALYTICS')}
                pages={[{ name: translator('User_Activity_Dashboard'), href: '', className: '' }]}
                containerClassName=" z-10 sticky top-0 w-full"
            />
            <div >
                <div className="flex items-center justify-between p-5 border-b boder-gray-50">
                    <h1 className="text-xl text-gray-800 font-semibold">{translator('User_Activity_Dashboard')}</h1>
                    <div className='relative min-w-[242px]'>
                        <DatePickerComponent onChange={onChange} startDate={startDate} endDate={endDate} maxDate={new Date()} />
                    </div>
                </div>
                <div className='p-5'>
                    <CardContainer>
                        <div className="flex flex-col">
                            <Title>{t('REPORTS.Overview')}</Title>
                            <div className="ml-auto min-w-[132px] w-full mt-4">
                                <div className="flex space-x-5">
                                    <div className="w-1/3">
                                        <CardInfo  {...{
                                            value: activitiesOverview?.total_member,
                                            day: null,
                                            percent: 0,
                                            title: `${translator('Total Users')}`,
                                            hasPercent: false,
                                            description: '',
                                            content: translator("A unique user who has been registered in the system within the timeframe specified in the filter.")
                                        }} className="h-full" />
                                    </div>
                                    <div className="w-1/3">
                                        <CardInfo  {...{
                                            value: activitiesOverview?.total_active_member,
                                            day: null,
                                            percent: 0,
                                            title: `${translator('Total Active Users')}`,
                                            hasPercent: false,
                                            description: '',
                                            content: translator("A unique user who has logged an activity in either CLMS or Learner Portal within the timeframe specified in the filter.")
                                        }} className="h-full" />
                                    </div>
                                    <div className="w-1/3">
                                        <CardInfo  {...{
                                            value: activitiesOverview?.total_deactived_member,
                                            day: null,
                                            percent: 0,
                                            title: `${translator('Total Deactivated Users')}`,
                                            hasPercent: false,
                                            description: '',
                                            content: translator("A unique user who has NOT logged any activity in either CLMS or Learner Portal within the timeframe specified in the filter.")
                                        }} className="h-full"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardContainer>
                </div>
                <div className='p-5'>
                    <CardContainer>
                        <LastActivity startDate={startDate} endDate={endDate} />
                    </CardContainer>
                </div>
            </div>
        </div>
    )
}