import * as React from 'react';
import { useParams } from 'react-router-dom';
import { IContentListEntry } from 'types/H5P.type';
import { NextIcon, PreviousIcon } from 'assets/icon';
import { useTranslation } from 'react-i18next';
import { closeFullscreen, openFullScreen } from 'utils/commonFun';
import { ListIcon, ZoomInIcon, ZoomOutIcon } from 'assets/icon';
import Spinner from 'components/Spinner';
import './ContentPreview.scss'
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import H5PPlayerContentPublicComponent from 'components/H5P/H5PPlayer/H5PPlayerContentComponent/H5PPlayerContentPublicComponent';
import ReactTooltip from "react-tooltip";
import { AspectRatioBox } from 'components/AspectRatioBox';

const ContentPublicPreview = ({data}) => {
    const { t } = useTranslation()
    const params: { id: string; contentId: string } = useParams();
    const [isZoom, setZoom] = React.useState(false);
    const [showLoading, setShowLoading] = React.useState(true);
    const [page, setPage] = React.useState({ index: 1, total: 1 })

    const argsId = {
        workspaceId: params.id,
        contentId: data?.uid,
    };
    const [entryState, setEntryState] = React.useState({
        editing: false,
        playing: false,
        loading: false,
        saved: false,
        saving: false,
        saveError: false,
        saveErrorMessage: '',
    });
    const [h5pContentListEntryState, setH5PContentListEntryState] = React.useState<IContentListEntry>();
    const [h5pData, setH5PData] = React.useState<IContentListEntry>();

    const handleSetEntryState = (value) => {
        setEntryState(value);
        setShowLoading(false);
    }

    React.useEffect(() => {
        if (h5pContentListEntryState) {
            setH5PData(h5pContentListEntryState);
        }
    }, [h5pContentListEntryState]);

    React.useEffect(() => {
        setH5PContentListEntryState({
            argsId: argsId,
            contentId: argsId?.contentId,
            mainLibrary: '',
            title: data?.title,
            status: data?.status,
            tags: data?.tags,
            uid: data?.uid,
            updated_at: data?.updated_at,
        });
    }, [data]);

    React.useEffect(() => {
        setShowLoading(false);
        clearInterval(intervalRemoveIconZoom)
        var intervalRemoveIconZoom = setInterval(() => {
            const iframe = document?.getElementsByClassName('h5p-iframe')[0];
            if (iframe) {
                //@ts-ignore
                const zoom = iframe?.contentWindow?.document?.getElementsByClassName('h5p-footer-toggle-full-screen')?.[0];
                if (zoom) {
                    zoom.style.visibility = 'hidden';
                }
                setShowLoading(false);
                clearInterval(intervalRemoveIconZoom)
            }
        }, 1000)
    }, [isZoom]);

    return (
        <div id="presentation-mode" className='presentation-mode'>
            <div className={`header ${isZoom ? 'group' : ''}`}>
                <div className={`${isZoom ? 'hidden' : 'flex'} header-container group-hover:flex`}>
                    <div className="header-title mx-auto">{h5pData?.title}</div>
                </div>
            </div>
            <div className={`main-content min-h-[450px] h-[calc(100vh)] ${!isZoom ? 'pt-14 pb-16' : 'py-0'}`}> 
                <div
                    key={`iframe-${isZoom}`}
                    className={`h5p-container ${isZoom ? 'zoom' : ''}`}
                >
                    {showLoading && <div className='h5p-container-loading'> <Spinner /> </div>}
                    <AspectRatioBox>
                        <H5PPlayerContentPublicComponent argsId={argsId} entryState={entryState} setEntryState={handleSetEntryState} />
                    </AspectRatioBox>
                </div>
            </div>
            <div
                className={`navigation ${isZoom ? 'group z-100' : ''}`}
            >
                <div className={`${isZoom ? 'hidden' : 'flex'} navigation-container group-hover:flex`}>
                    <div className="navigation-dropdown">
                        <ListIcon />
                        <div className="navigation-dropdown-button">
                            <span 
                                className="file-name"
                                data-for="file-name"
                                data-tip={h5pData?.title}
                            >
                                {h5pData?.title}
                            </span>{' '}
                            {/*@ts-ignore*/
                            <ReactTooltip
                                place="top"
                                type="dark"
                                effect="float"
                                id={`file-name`}
                                getContent={(value) => <div>{value}</div>}
                            />}
                        </div>
                    </div>
                    <div className="navigation-button">
                        <div className="opacity-50">
                            <PreviousIcon />
                        </div>
                        <div className="line"></div>
                        <div className="index-page">{page.index}</div>
                        <div className='total-slide'>/ {page.total}</div>
                        <div className="line"></div>
                        <div className="opacity-50">
                            <NextIcon />
                        </div>
                    </div>
                    <div
                        id="zoom-presentation"
                        onClick={() => {
                            isZoom ? closeFullscreen() : openFullScreen();
                            setZoom(!isZoom);
                        }}
                        className={`button-zoom`}
                    >
                        {isZoom ? <ZoomOutIcon /> : <ZoomInIcon />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContentPublicPreview;
