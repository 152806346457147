import axios, { RestfulService } from './restful.service';
// CONSTANTS
import { HOST_URL, REPORT } from 'constant/api.const';
import { RequestBodyClassReportType, RequestBodyClassReportWithPaginationType } from 'types/Report.type';
// TYPES

const getGeneral = async (workspaceId: string) => {
    const res = await RestfulService.getApi(HOST_URL + REPORT.getGeneral(workspaceId));
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const getTeacher = async (workspaceId: string) => {
    const res = await RestfulService.getApi(HOST_URL + REPORT.getTeacher(workspaceId));
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const getGeneralReportsService = (
    workspaceId: string,
    bodyData?: {
        classes?: number;
        start_time?: string;
        end_time?: string;
        session_type?: '' | 'online' | 'offline';
    }
) => {
    if (workspaceId) {
        const url = REPORT.getGeneralReportsUrl(workspaceId);
        return axios.get(url, { params: bodyData });
    }
};

const getHomeLearningReportsService = (
    workspaceId: string,
    bodyData?: {
        classes?: number;
        start_time?: string;
        end_time?: string;
        session_type?: '' | 'online' | 'offline';
    }
) => {
    if (workspaceId) {
        const url = REPORT.getHomeLearningReportsUrl(workspaceId);
        return axios.get(url, { params: bodyData });
    }
};

const getClassGeneralReportsService = (workspaceId: string, classId: string, bodyData?: RequestBodyClassReportType) => {
    if (workspaceId) {
        const url = REPORT.getClassGeneralReportsUrl(workspaceId, classId);
        return axios.get(url, { params: bodyData });
    }
};

const getClassHomeLearningReportsService = (workspaceId: string, classId: string, bodyData?: RequestBodyClassReportType) => {
    if (workspaceId) {
        const url = REPORT.getClassHomeLearningReportsUrl(workspaceId, classId);
        return axios.get(url, { params: bodyData });
    }
};

const getClassAssignmentReportsService = (workspaceId: string, classId: string, bodyData?: RequestBodyClassReportType) => {
    if (workspaceId && classId) {
        const url = REPORT.getClassAssignmentReportsUrl(workspaceId, classId);
        return axios.get(url, { params: bodyData });
    }
};

const getClassOverviewReportsService = (workspaceId: string, classId: string, bodyData?: RequestBodyClassReportType) => {
    if (workspaceId && classId) {
        const url = REPORT.getClassOverviewReportsUrl(workspaceId, classId);
        return axios.get(url, { params: bodyData });
    }
};

const getClassStudentAssignmentReportsService = (workspaceId: string, classId: string, bodyData?: RequestBodyClassReportType) => {
    if (workspaceId && classId) {
        const url = REPORT.getClassStudentAssignmentReportsUrl(workspaceId, classId);
        return axios.get(url, { params: bodyData });
    }
};

const getClassAttendanceReportsService = (workspaceId: string, classId: string, bodyData?: RequestBodyClassReportType) => {
    if (workspaceId && classId) {
        const url = REPORT.getClassAttendanceReportsUrl(workspaceId, classId);
        return axios.get(url, { params: bodyData });
    }
};

const getGeneralReportClasses = (workspaceId: string, params?: Record<any, any>, arrClasses?: any[]) => {
    if (workspaceId) {
        const paramsUrl =
            (params &&
                `${Object.keys(params)
                    .map((i) => {
                        if (i && params[i]) return `${i}=${params[i]}`;
                        return null;
                    })
                    .join('&')}`) ||
            null;

        const classesParams = (arrClasses && `${arrClasses.map((i) => `classes=${i}`).join('&')}`) || null;

        const url = `${REPORT.getGeneralReportClasses(workspaceId)}${paramsUrl ? `?${paramsUrl}` : ''}${classesParams ? `&${classesParams}` : ''
            }`;
        return axios.get(url);
    }
};

const getHomeLearningReportClasses = (workspaceId: string, params?: Record<any, any>, arrClasses?: any[]) => {
    if (workspaceId) {
        const paramsUrl =
            (params &&
                `${Object.keys(params)
                    .map((i) => {
                        if (i && params[i]) return `${i}=${params[i]}`;
                        return null;
                    })
                    .join('&')}`) ||
            null;

        const classesParams = (arrClasses && `${arrClasses.map((i) => `classes=${i}`).join('&')}`) || null;

        const url = `${REPORT.getHomeLearningReportClasses(workspaceId)}${paramsUrl ? `?${paramsUrl}` : ''}${classesParams ? `&${classesParams}` : ''
            }`;
        return axios.get(url);
    }
};

const getOverviewReportClasses = (workspaceId: string, params?: Record<any, any>, arrClasses?: any[]) => {
    if (workspaceId) {
        const paramsUrl =
            (params &&
                `${Object.keys(params)
                    .map((i) => {
                        if (i && params[i]) return `${i}=${params[i]}`;
                        return null;
                    })
                    .join('&')}`) ||
            null;

        const classesParams = (arrClasses && `${arrClasses.map((i) => `classes=${i}`).join('&')}`) || null;

        const url = `${REPORT.getOverviewReportClasses(workspaceId)}${paramsUrl ? `?${paramsUrl}` : ''}${classesParams ? `&${classesParams}` : ''
            }`;
        return axios.get(url);
    }
};

const getSpecificStudentOverviewsService = (workspaceId: string, studentId: string, bodyData?: RequestBodyClassReportType) => {
    if (workspaceId && studentId) {
        const url = REPORT.getSpecificStudentOverviewsUrl(workspaceId, studentId);
        return axios.get(url, { params: bodyData });
    }
};

const getSpecificStudentAssignmentsService = (workspaceId: string, studentId: string, bodyData?: RequestBodyClassReportWithPaginationType) => {
    if (workspaceId && studentId) {
        const url = REPORT.getSpecificStudentAssignmentsUrl(workspaceId, studentId);
        return axios.get(url, { params: bodyData });
    }
};

const getSpecificStudentAttendancesService = (workspaceId: string, studentId: string, bodyData?: RequestBodyClassReportType) => {
    if (workspaceId && studentId) {
        const url = REPORT.getSpecificStudentAttendancesUrl(workspaceId, studentId);
        return axios.get(url, { params: bodyData });
    }
};

const getSpecificStudentActiveReviewService = (workspaceId: string, studentId: string, bodyData?: RequestBodyClassReportType) => {
    if (workspaceId && studentId) {
        const url = REPORT.getSpecificStudentActiveReviewUrl(workspaceId, studentId);
        return axios.get(url, { params: bodyData });
    }
};

const getSpecificStudentWorkbooksService = (workspaceId: string, studentId: string, bodyData?: RequestBodyClassReportType) => {
    if (workspaceId && studentId) {
        const url = REPORT.getSpecificStudentWorkbooksUrl(workspaceId, studentId);
        return axios.get(url, { params: bodyData });
    }
};

const getSpecificStudentReportsService = (workspaceId: string, studentId: string, bodyData?: RequestBodyClassReportWithPaginationType) => {
    if (workspaceId && studentId) {
        const url = REPORT.getSpecificStudentReportsUrl(workspaceId, studentId);
        return axios.get(url, { params: bodyData });
    }
};

const getSpecificStudentReportDetailService = (workspaceId: string, studentId: string, reportId: string, bodyData?: RequestBodyClassReportType) => {
    if (workspaceId && studentId && reportId) {
        const url = REPORT.getSpecificStudentReportDetailUrl(workspaceId, studentId, reportId);
        return axios.get(url, { params: bodyData });
    }
};
const getGeneralReportStudent = (workspaceId: string, params?: Record<any, any>, arrStudents?: any[]) => {
    if (workspaceId) {
        const paramsUrl =
            (params &&
                `${Object.keys(params)
                    .map((i) => {
                        if (i && params[i]) return `${i}=${params[i]}`;
                        return null;
                    })
                    .join('&')}`) ||
            null;

        const studentParams = (arrStudents && `${arrStudents.map((i) => `students=${i}`).join('&')}`) || null;

        const url = `${REPORT.getGeneralReportStudent(workspaceId)}${paramsUrl ? `?${paramsUrl}` : ''}${studentParams ? `&${studentParams}` : ''
            }`;
        return axios.get(url);
    }
};

const getOverviewReportStudents = (workspaceId: string, params?: Record<any, any>, arrStudents?: any[]) => {
    if (workspaceId) {
        const paramsUrl =
            (params &&
                `${Object.keys(params)
                    .map((i) => {
                        if (i && params[i]) return `${i}=${params[i]}`;
                        return null;
                    })
                    .join('&')}`) ||
            null;

        const studentsParams = (arrStudents && `${arrStudents.map((i) => `students=${i}`).join('&')}`) || null;

        const url = `${REPORT.getOverviewReportStudents(workspaceId)}${paramsUrl ? `?${paramsUrl}` : ''}${studentsParams ? `&${studentsParams}` : ''
            }`;
        return axios.get(url);
    }
};

const getHomeLearningReportStudent = (workspaceId: string, params?: Record<any, any>, arrClasses?: any[]) => {
    if (workspaceId) {
        const paramsUrl =
            (params &&
                `${Object.keys(params)
                    .map((i) => {
                        if (i && params[i]) return `${i}=${params[i]}`;
                        return null;
                    })
                    .join('&')}`) ||
            null;

        const studentParams = (arrClasses && `${arrClasses.map((i) => `students=${i}`).join('&')}`) || null;

        const url = `${REPORT.getHomeLearningReportStudents(workspaceId)}${paramsUrl ? `?${paramsUrl}` : ''}${studentParams ? `&${studentParams}` : ''
            }`;
        return axios.get(url);
    }
};

const getHomeLearningWorkbookStudents = (workspaceId: string, params?: Record<any, any>, arrStudents?: any[]) => {
    if (workspaceId) {
        const paramsUrl =
            (params &&
                `${Object.keys(params)
                    .map((i) => {
                        if (i && params[i]) return `${i}=${params[i]}`;
                        return null;
                    })
                    .join('&')}`) ||
            null;

        const studentsParams = (arrStudents && `${arrStudents.map((i) => `students=${i}`).join('&')}`) || null;

        const url = `${REPORT.getHomeLearningWorkbokStudents(workspaceId)}${paramsUrl ? `?${paramsUrl}` : ''}${studentsParams ? `&${studentsParams}` : ''
            }`;
        return axios.get(url);
    }
};

const getDataActiveReviewStudent = (workspaceId: string, params?: Record<any, any>, arrStudents?: any[]) => {
    if (workspaceId) {
        const paramsUrl =
            (params &&
                `${Object.keys(params)
                    .map((i) => {
                        if (i && params[i]) return `${i}=${params[i]}`;
                        return null;
                    })
                    .join('&')}`) ||
            null;

        const studentsParams = (arrStudents && `${arrStudents.map((i) => `students=${i}`).join('&')}`) || null;

        const url = `${REPORT.getDataActiveReviewStudent(workspaceId)}${paramsUrl ? `?${paramsUrl}` : ''}${studentsParams ? `&${studentsParams}` : ''
            }`;
        return axios.get(url);
    }
};

const getDataAttendanceStudents = (workspaceId: string, params?: Record<any, any>, arrStudents?: any[]) => {
    if (workspaceId) {
        const paramsUrl =
            (params &&
                `${Object.keys(params)
                    .map((i) => {
                        if (i && params[i]) return `${i}=${params[i]}`;
                        return null;
                    })
                    .join('&')}`) ||
            null;

        const studentsParams = (arrStudents && `${arrStudents.map((i) => `students=${i}`).join('&')}`) || null;

        const url = `${REPORT.getDataAttendanceStudents(workspaceId)}${paramsUrl ? `?${paramsUrl}` : ''}${studentsParams ? `&${studentsParams}` : ''
            }`;
        return axios.get(url);
    }
};

const getGeneralReportTeachers = (workspaceId: string, params?: Record<any, any>, arrTeachers?: any[]) => {
    if (workspaceId) {
        const paramsUrl =
            (params &&
                `${Object.keys(params)
                    .map((i) => {
                        if (i && params[i]) return `${i}=${params[i]}`;
                        return null;
                    })
                    .join('&')}`) ||
            null;

        const teachersParams = (arrTeachers && `${arrTeachers.map((i) => `instructors=${i}`).join('&')}`) || null;

        const url = `${REPORT.getGeneralReportTeachers(workspaceId)}${paramsUrl ? `?${paramsUrl}` : ''}${teachersParams ? `&${teachersParams}` : ''
            }`;
        return axios.get(url);
    }
}

const getOverviewReportTeachers = (workspaceId: string, params?: Record<any, any>, arrTeachers?: any[]) => {
    if (workspaceId) {
        const paramsUrl =
            (params &&
                `${Object.keys(params)
                    .map((i) => {
                        if (i && params[i]) return `${i}=${params[i]}`;
                        return null;
                    })
                    .join('&')}`) ||
            null;

        const teachersParams = (arrTeachers && `${arrTeachers.map((i) => `instructors=${i}`).join('&')}`) || null;

        const url = `${REPORT.getOverviewReportTeachers(workspaceId)}${paramsUrl ? `?${paramsUrl}` : ''}${teachersParams ? `&${teachersParams}` : ''
            }`;
        return axios.get(url);
    }
};

const getGroupClassPerformance = (workspaceId: string, params?: Record<any, any>, arrGroups?: any[]) => {
    if (workspaceId) {
        const paramsUrl =
            (params &&
                `${Object.keys(params)
                    .map((i) => {
                        if (i && params[i]) return `${i}=${params[i]}`;
                        return null;
                    })
                    .join('&')}`) ||
            null;

        const groupsParams = (arrGroups && `${arrGroups.map((i) => `${i}`).join(',')}`) || null;

        const url = `${REPORT.getGroupClassPerformance(workspaceId)}${paramsUrl ? `?${paramsUrl}` : ''}${groupsParams ? `&groups:[${groupsParams}]` : ''
            }`;
        return axios.get(url);
    }
}

const getGroupFeedbacks = (workspaceId: string, params?: Record<any, any>, arrGroups?: any[]) => {
    if (workspaceId) {
        const paramsUrl =
            (params &&
                `${Object.keys(params)
                    .map((i) => {
                        if (i && params[i]) return `${i}=${params[i]}`;
                        return null;
                    })
                    .join('&')}`) ||
            null;

        const groupsParams = (arrGroups && `${arrGroups.map((i) => `${i}`).join(',')}`) || null;

        const url = `${REPORT.getGroupFeedbacks(workspaceId)}${paramsUrl ? `?${paramsUrl}` : ''}${groupsParams ? `&groups:[${groupsParams}]` : ''
            }`;
        return axios.get(url);
    }
}

const getGroup = (workspaceId: string, params?: Record<any, any>, arrGroups?: any[]) => {
    if (workspaceId) {
        const paramsUrl =
            (params &&
                `${Object.keys(params)
                    .map((i) => {
                        if (i && params[i]) return `${i}=${params[i]}`;
                        return null;
                    })
                    .join('&')}`) ||
            null;

        const groupsParams = (arrGroups && `${arrGroups.map((i) => `${i}`).join(',')}`) || null;

        const url = `${REPORT.getGroup(workspaceId)}${paramsUrl ? `?${paramsUrl}` : ''}${groupsParams ? `&groups:[${groupsParams}]` : ''
            }`;
        return axios.get(url);
    }
}



export default {
    getGeneral,
    getTeacher,
    getGeneralReportsService,
    getHomeLearningReportsService,
    getClassGeneralReportsService,
    getClassHomeLearningReportsService,
    getClassAssignmentReportsService,
    getClassOverviewReportsService,
    getClassStudentAssignmentReportsService,
    getClassAttendanceReportsService,
    getGeneralReportClasses,
    getHomeLearningReportClasses,
    getOverviewReportClasses,
    getSpecificStudentOverviewsService,
    getSpecificStudentAssignmentsService,
    getSpecificStudentAttendancesService,
    getSpecificStudentActiveReviewService,
    getSpecificStudentWorkbooksService,
    getSpecificStudentReportsService,
    getSpecificStudentReportDetailService,
    getGeneralReportStudent,
    getHomeLearningReportStudent,
    getOverviewReportStudents,
    getHomeLearningWorkbookStudents,
    getDataActiveReviewStudent,
    getDataAttendanceStudents,
    getGeneralReportTeachers,
    getOverviewReportTeachers,
    getGroupFeedbacks,
    getGroup,
    getGroupClassPerformance,
};
