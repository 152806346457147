export const FORM_CONST = {
    FORGOT_PASSWORD:
        'Enter your registered email below to recieve password reset instructions',
    PASSWORD_RESET_SUCCESS: 'Your password was reset successfully!',
    IS_REQUIRED: 'This field is required',
    MAX_LENGTH: 'You exceeded the max length',
    MIN_LENGTH: 'Password must have at least 6 characters',
    PASSWORD_NOT_MATCH: "Password doesn't match",
    EMAIL_VALIDATE: 'Email is invalid',
    PASSWORD_VALIDATE:
        'Password must at have least 10 characters, at least one capital letter, at least one number AND at least one special character',
    NUMBER_VALIDATE: 'Number only',
    // PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d)[A-Za-z\d~!@#$%^&*_\-+=`|\\(){}[\]:;\"\'<>,.?/]{10,64}$/,
    PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*_\-+=`|\\(){}[\]:;\"\'<>,.?/])(?=.*\d)[A-Za-z\d~!@#$%^&*_\-+=`|\\(){}[\]:;\"\'<>,.?/]{10,64}$/,
    EMAIL_REGEX: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NUMBER_REGEX: /^[0-9]*$/,
    NUMBER_CASE: /[0-9]/g,
    LOWER_CASE: /[a-z]/g,
    UPPER_CASE: /[A-Z]/g,
    SPECIAL_CASE: /[!@#\$%\^\&*\)\(+=._-]/g,
    PHONE_NUMBER: /^\+?[1-9][0-9]{7,14}$/,
    PHONE_NUMBER_NEW: /^\+([1-9]{1}[0-9]{0,2})\s?\d{4,14}$/,
    URL_VALIDATE: /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g
};

export const FORM_FIlTER: Record<any, any> = {
    typeFilter: {
        H5P_CONTENT: 'H5P',
        LESSON: 'Lesson',
        COURSE: 'Course',
    },
    durationType: {
        MONTH_1: '1 month',
        MONTHS_7: '7 months',
        YEAR_1: '1 year',
    },
    statusType: {
        PENDING: 'Pending',
        PUBLIC: 'Published',
        DRAFT: 'Draft',
        ARCHIVE: 'Archive',
        TRASH: 'Trash',
    },
};

export const TYPE_FILTER = (t: Function): Record<any, string> => {
    const typeFilter = {
        H5P_CONTENT: t('DASHBOARD.ADMIN_APPROVAL.TYPE_H5P'),
        LESSON: t('DASHBOARD.ADMIN_APPROVAL.TYPE_LESSON'),
        COURSE: t('DASHBOARD.ADMIN_APPROVAL.TYPE_COURSE'),
    };
    return typeFilter;
};

export const accept_file_input = [
    '.ppt',
    '.pptx',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/pdf',
    'image/png',
    'image/jpe',
    'image/jpeg',
    'video/mp4',
    'audio/mpeg',
    '.doc',
    '.docx',
    'application/doc',
    'application/ms-doc',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];



export const VIDEO_TYPES = [
    "video/mp4",       // MP4
    "video/quicktime", // MOV
    "video/x-matroska",// MKV
    "video/x-msvideo", // AVI
    "video/webm",      // WEBM
    "video/x-m4v",     // M4V
    "video/MP2T",      // TS
    "video/hevc",      // HEVC
    "video/avchd",     // AVCHD
    "video/quicktime", // ProRes
    "video/mp2t",
    "video/x-flv",
    "video/3gpp",
    "video/mpeg",
    "application/mxf",
    ".mts",
    ".hevc",
    ".3gp",
    ".flv",
    ".mkv",
    ".mpg",
    ".mxf",
    ".ts"
]

export const accept_file_upload_library = [
    ".pdf",
    ".ppt",
    ".pptx",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/pdf",
    "image/png",
    "image/jpe",
    "image/jpeg",
    "video/mp4",
    "audio/mpeg",
    ".doc",
    ".docx",
    "application/doc",
    "application/ms-doc",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    ...VIDEO_TYPES
];


export const ACCEPT_FILE_EXTENSIONS = [
    'mts',
    'm2ts',
    'hevc',
    'avi'
]

export const ACCEPT_FILE_NONE_MIME_TYPE = {
    'video/mp2t': ['mts', 'm2ts'],
    'video/hevc': ['hevc'],
    'video/x-msvideo': ['avi'],
    'video/x-flv': ['flv'],
    'application/mxf': ['mxf'],
    'video/3gpp': ['3gp'],
    'video/x-matroska': ['mkv']
}

export const acceptContentType = 'audio,document,folder,image,pdf,presentation,spreadsheet,video,h5p';

export const sessionTypeTabs = ['ALL', 'ONLINE', 'OFFLINE'];

export const overviewTabs = ['OVERVIEW', 'WORKBOOK', 'ACTIVE_APP', 'ATTENDANCE', 'GRADEBOOK'];
