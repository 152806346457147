import { ChevronRightIcon } from '@heroicons/react/outline';
import { FC, Fragment } from 'react';
import { classNames } from 'utils/commonFun';
import './LocationDetails.scss';

type arrayLocation = {
    name: string;
    className?: string;
};

type LocationDetailsProps = {
    containerClassName?: string;
    NameDefault?: string;
    arr: arrayLocation[];
};

const LocationDetails: FC<LocationDetailsProps> = ({ containerClassName, NameDefault, arr }) => {
    return (
        <div className={classNames('LocationDetails', containerClassName)}><p>
            {NameDefault && <span className="LocationDetails-nameDefault">{NameDefault}</span>}
            {arr?.length > 3 ? (
                <>
                    <span><ChevronRightIcon width={16} height={16} /></span>
                    <span className={'LocationDetails-nameDefault'} key={0}>
                        {arr[0]?.name}
                    </span>
                    <span><ChevronRightIcon width={16} height={16} /></span>
                    ...
                    {arr?.slice(arr?.length - 2)?.map((item, index) => {
                        return (
                            <>
                                <span><ChevronRightIcon width={16} height={16} /></span>
                                <span
                                    className={classNames(
                                        index === 1? 'LocationDetails-currentPath' : 'LocationDetails-nameDefault',
                                        item?.className
                                    )}
                                >
                                    {item?.name}
                                </span>
                            </>
                        );
                    })}
                </>
            ) : (
                arr?.map((item, index) => (
                    <Fragment key={index}>
                        <span><ChevronRightIcon width={16} height={16} /></span>
                        <span
                            className={classNames(
                                index === arr?.length - 1 ? 'LocationDetails-currentPath' : 'LocationDetails-nameDefault',
                                item?.className
                            )}
                        >
                            {item?.name}
                        </span>
                    </Fragment>
                ))
            )}
            </p>
        </div>
    );
};

export default LocationDetails;
