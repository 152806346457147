import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppReview from 'components/UiComponents/AppReivew';
import AssessmentResult from 'components/UiComponents/AssessmentResult';
import Comments from 'components/UiComponents/Comments';

import classService from 'services/class.service';
import { ReportDetails } from 'types/Class.type';
import EvaluateTeacher from './EvaluateTeacher';
import { useTranslation } from 'react-i18next';

interface PreviewReportProps {
    reportId: number;
    studentName: string;
    dataVersion?: any;
    classOfReport?: string;
}

const PreviewReport: React.FC<PreviewReportProps> = ({ reportId, studentName, dataVersion = null, classOfReport = null }) => {
    const params: { id: string; classId: string } = useParams();
    const { t: translator } = useTranslation();

    const [dataView, setDataView] = useState<any>();
    const [hasComments, setHasComments] = useState(false);
    useEffect(() => {
        if (dataVersion) {
            setDataView(dataVersion.data.data);
        } else {
            classService
                .getReportDetailsOfClass(params.id, params?.classId || classOfReport, reportId)
                .then((res) => {
                    if (res) {
                        setDataView(res?.data);
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [reportId]);

    useEffect(() => {
        dataView?.comments?.forEach((item) => {
            if (item?.comment || item?.comment_vn) {
                setHasComments(true);
            }
        });
    }, [dataView]);

    return (
        <div className="w-full">
            <div className="flex flex-col  mt-6 font-inter">
                <div className="w-full ">
                    <div className="card px-5 py-6">
                        <EvaluateTeacher
                            name={dataView?.my_class?.name}
                            total_apo={dataView?.total_apo}
                            attendance_in_percent={dataView?.attendance_in_percent}
                            instructors={dataView?.comments}
                        />
                    </div>
                    <div className="card p-5 text-white mt-6 bg-[#1AB8FE] hidden">
                        <AppReview {...dataView?.app_review} />
                    </div>
                    {dataView?.comments?.length > 0 && hasComments && (
                        <Comments comments={dataView?.comments} isEnglish={true} className="card p-4 mt-6 hidden" />
                    )}
                </div>
                <div className="w-full mt-6 sm:mt-0">
                    <div className="card py-4 px-6">
                        <div className="flex text-ooolab_dark_1 text-sm space-x-2 font-semibold items-center">
                            <span>Assessment</span>
                        </div>
                        <div className="mt-4">
                            <AssessmentResult
                                color="text-[#2E3A59]"
                                label="Progress"
                                value={dataView?.progress && studentName + translator(`RATING.PROGRESS.${dataView?.progress}`)}
                            />
                        </div>
                        <div className="mt-4">
                            <AssessmentResult
                                color="text-[#2E3A59]"
                                label="Behavior"
                                value={dataView?.behavior && studentName + translator(`RATING.BEHAVIOUR.${dataView?.behavior}`)}
                            />
                        </div>
                        <div className="mt-4">
                            <AssessmentResult
                                color="text-[#2E3A59]"
                                label="Participation"
                                value={dataView?.participation && translator(`RATING.PARTICIPATION.${dataView?.participation}`)}
                            />
                        </div>
                        <div
                            // hidden={!dataView?.progress || !dataView?.behavior || !dataView?.participation}
                            className={`${
                                dataView?.progress || dataView?.behavior || dataView?.participation ? '' : 'hidden'
                            } h-px w-11/12 bg-[#EBEBF0] mx-auto mt-4`}
                        ></div>
                        <div className="mt-4 hidden">
                            <AssessmentResult
                                color="text-[#E7443C]"
                                label="Vocabulary"
                                value={dataView?.vocabulary && studentName + translator(`RATING.VOCABULARY.${dataView?.vocabulary}`)}
                            />
                        </div>
                        <div className="mt-4 hidden">
                            <AssessmentResult
                                color="text-[#D8880F]"
                                label="Grammar"
                                value={dataView?.grammar && translator(`RATING.GRAMMAR.${dataView?.grammar}`)}
                            />
                        </div>
                        <div className="mt-4 hidden">
                            <AssessmentResult
                                color="text-[#812BEE]"
                                label="Speaking"
                                value={dataView?.speaking && translator(`RATING.SPEAKING.${dataView?.speaking}`)}
                            />
                        </div>
                        <div className="mt-4 hidden">
                            <AssessmentResult
                                color="text-[#FED030]"
                                label="Listening"
                                value={dataView?.listening && studentName + translator(`RATING.LISTENING.${dataView?.listening}`)}
                            />
                        </div>
                        <div className="mt-4 hidden">
                            <AssessmentResult
                                color="text-[#23D4C8]"
                                label="Reading"
                                value={dataView?.reading && translator(`RATING.READING.${dataView?.reading}`)}
                            />
                        </div>
                        <div className="mt-4 hidden">
                            <AssessmentResult
                                color="text-[#0C97AA]"
                                label="Pronunciation"
                                value={
                                    dataView?.pronunciation &&
                                    studentName + translator(`RATING.PRONUNCIATION.${dataView?.pronunciation}`)
                                }
                            />
                        </div>
                        <div className="mt-4 hidden">
                            <AssessmentResult
                                color="text-[#FF60E6]"
                                label="Writing"
                                value={dataView?.writing && studentName + translator(`RATING.WRITING.${dataView?.writing}`)}
                            />
                        </div>
                        <div className="mt-4 hidden">
                            <AssessmentResult
                                color="text-[#46A34D]"
                                label="Future Skills"
                                value={dataView?.future_skills && translator(`RATING.FUTURESKILLS.${dataView?.future_skills}`)}
                            />
                        </div>
                    </div>
                </div>
                {dataView?.comments && (
                    <div className="card mt-5 py-6 px-5 flex items-center">
                        <div className="heading-base text-sm text-ooolab_dark_1 mr-1">Instructor Comment:</div>
                        <div className='text-sm'>{dataView?.comments[0]} </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PreviewReport;
