import { useEffect, useState } from 'react';
import { SelectField } from 'components/Select';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import courseService from 'services/course.service';
import './SelectLearner.scss';
import { ButtonOutline } from '_shared';
import { LEARNER_TYPE, LEARNER_TYPE_ENUM } from 'constant/course.const';
import { useAppSelector } from 'hooks/hooks';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from '_shared/components/Select';

export const SelectLearner = ({ onApply, isUpdateListEnrollment, setUpdateListEnrollment, enrollmentSelected }) => {
    const { t: translator } = useTranslation();
    const {
        control,
        watch,
        formState: { errors },
        setValue,
        handleSubmit,
    } = useForm();
    const params: { id: string; courseId: string } = useParams();

    const [paginationEnrollment, setPaginationEnrollment] = useState({ page: 1, total: 0 });
    const [enrollments, setEnrollments] = useState([]);
    const [inputSearchEnrollment, setInputSearchEnrollment] = useDebouncedState('', 0);
    const [loadingEnrollment, setLoadingEnrollment] = useState(false);
    const [inputSearchLearnerOfLearningGroup, setInputSearchLearnerOfLearningGroup] = useDebouncedState('', 0);
    const [learnerOfLearningGroup, setLearnerOfLearningGroup] = useState([]);

    const { setting } = useAppSelector((state) => state.course);

    const getEnrollmentOfCourse = (page: number = 1) => {
        setLoadingEnrollment(true);
        const param = {
            page: page,
            per_page: 20,
            q: inputSearchEnrollment,
            enrollment_as: watch('type')?.value === LEARNER_TYPE_ENUM.learning_group ? 'learning_group' : 'individual',
            statuses: 'active,enrolled',
        };
        if (!param.q) delete param.q;
        courseService
            .getEnrollments(params.id, params.courseId, param)
            .then((res) => {
                const listFormated = res?.data?.items?.map((item) => {
                    if (watch('type')?.value === LEARNER_TYPE_ENUM.individual) {
                        const learner = item?.learners?.[0];
                        return {
                            credit_balance: learner?.credit_balance,
                            id: learner?.id,
                            name: learner?.display_name,
                            avatar_url: learner?.avatar_url,
                            value: learner.id,
                            disabled:
                                setting?.enrollment_type === 'credit' &&
                                (!learner?.credit_balance?.available_credit || learner?.credit_balance?.available_credit === 0),
                        };
                    }
                    const learningGroup = item?.learning_group;
                    const listLearnerOfGroup = item?.learners?.map((learner) => {
                        return {
                            credit_balance: learner?.credit_balance,
                            id: learner?.id,
                            name: learner?.display_name,
                            avatar_url: learner?.avatar_url,
                            value: learner.id,
                            disabled:
                                setting?.enrollment_type === 'credit' &&
                                (!learner?.credit_balance?.available_credit || learner?.credit_balance?.available_credit === 0),
                        };
                    })
                    return {
                        ...learningGroup,
                        id: learningGroup?.id,
                        name: learningGroup?.name,
                        value: learningGroup?.id,
                        avatar_url: learningGroup?.avatar_url,
                        learners: listLearnerOfGroup,
                    };
                });
                if (isUpdateListEnrollment) setValue('learners', enrollmentSelected?.learners)
                if (page === 1) setEnrollments(listFormated);
                if (page > 1) {
                    setEnrollments([...enrollments, ...listFormated]);
                }
                setPaginationEnrollment({ total: res?.data?.total, page: res?.data?.page});
            })
            .finally(() => setLoadingEnrollment(false));
    };

    const onSubmit = (values: any) => {
        onApply({
            type: values?.type,
            learners: values?.learners,
            learning_group: values?.learning_group?.[0],
        });
    };

    useEffect(() => {
        setValue('type', LEARNER_TYPE[0]);
    }, []);

    useEffect(() => {
        setValue('learners', null);
        setValue('learning_group', null);
    }, [watch('type')]);

    useEffect(() => {
        getEnrollmentOfCourse(1);
    }, [inputSearchEnrollment, watch('type')]);

    useEffect(() => {
        if (isUpdateListEnrollment) {
            getEnrollmentOfCourse(1);
            setUpdateListEnrollment(false);
        }
    }, [isUpdateListEnrollment]);

    

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="selectLearner">
                <div className="selectLearner-label">Select Learner(s)</div>
                <div className="gap-2 grid grid-cols-6 mt-3">
                    <SelectField
                        control={control}
                        placeholder={translator('Select type Learner')}
                        isRequired
                        name="type"
                        options={LEARNER_TYPE}
                        className="font-normal col-span-1"
                        size="M"
                    />
                    {watch('type')?.value === LEARNER_TYPE_ENUM.learning_group && (
                        <div className={`col-span-2`}>
                            <SelectBox
                                control={control}
                                name="learning_group"
                                placeholder={translator('Select Learning Group')}
                                isRequired
                                placeholderSearchInput="Search a name"
                                pagination={paginationEnrollment}
                                onSearch={(value) => setInputSearchEnrollment(value)}
                                getData={getEnrollmentOfCourse}
                                data={enrollments}
                                numberOfItemDisplayed={1}
                                isMulti={false}
                                errors={errors}
                                value={watch('learning_group') || []}
                                loading={loadingEnrollment}
                                className={'!h-[32.5px]'}
                                actionAfterSelectItem={(item) => {
                                    let learners = item?.learners
                                    if(setting?.enrollment_type === 'credit') {
                                        setValue('learners', item?.learners?.filter(learner => learner?.credit_balance?.available_credit > 0));
                                        return;
                                    }
                                    return setValue('learners', learners)
                                }}
                                isShowSearchIcon={true}
                            />
                        </div>
                    )}
                    <div className={`col-span-2`}>
                        <SelectBox
                            control={control}
                            name="learners"
                            placeholder={translator('COURSES.SELECT_LEARNERS')}
                            isRequired
                            placeholderSearchInput="Search a name"
                            pagination={paginationEnrollment}
                            onSearch={(value) => {
                                if (watch('type')?.value === LEARNER_TYPE_ENUM.learning_group) {
                                    const learners =
                                        watch('learning_group')?.[0]?.learners?.filter(
                                            (learner) => learner?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) !== -1
                                        ) || [];
                                    setInputSearchLearnerOfLearningGroup(value);
                                    setLearnerOfLearningGroup(learners);
                                } else setInputSearchEnrollment(value);
                            }}
                            getData={(page) => getEnrollmentOfCourse(page)}
                            data={
                                watch('type')?.value === LEARNER_TYPE_ENUM.learning_group
                                    ? inputSearchLearnerOfLearningGroup
                                        ? learnerOfLearningGroup
                                        : watch('learning_group')?.[0]?.learners
                                    : enrollments
                            }
                            numberOfItemDisplayed={1}
                            isMulti={watch('type')?.value === LEARNER_TYPE_ENUM.learning_group}
                            errors={errors}
                            value={watch('learners') || []}
                            loading={loadingEnrollment}
                            className={'!h-[32.5px]'}
                            isDisabled={watch('type')?.value === LEARNER_TYPE_ENUM.learning_group && !watch('learning_group')?.[0]}
                            isShowSearchIcon={true}
                        />
                    </div>
                    {watch('type')?.value === LEARNER_TYPE_ENUM.individual && <div className="col-span-2"></div>}
                    <div className="col-span-1 flex justify-end w-full">
                        <ButtonOutline
                            type="submit"
                            className="buttonOutline--isPrimary"
                            size="M"
                            onClick={() => {}}
                            disabled={!watch('learners')}
                        >
                            Apply
                        </ButtonOutline>
                    </div>
                </div>
            </div>
        </form>
    );
};
