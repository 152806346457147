import { LessonSection, TagType } from 'types/GetListOfWorkspace.type';
import { CreateLessonParam, SectionState } from 'types/Lesson.type';

type SyncInfoType = {
    sync_started_at: string;
    sync_status: string;
    sync_success_at: string;
};

export type LessonBuilderType = {
    status: 'init' | 'touched';
    currentSection: SectionState;
    sectionIndex: number;
    canEdit: boolean;
    canPublish: boolean;
    params: CreateLessonParam & {
        sections?: LessonSection[];
    };
    tags: TagType[];
    sections: SectionState[];
    vocabulary_topic: string;
    vocabulary_list: string[];
    grammar: string[];
    skills: string[];
    student_book_page: string;
    active_app_review: string;
    workbook_page_review: string;
    unit_progress: string;
    unit_number: string;
    lesson_number: string;
    lesson_goal: string;
    custom_fields: any;
    syncing_info?: SyncInfoType;
};

export const initialLesson = (type: string): LessonBuilderType => {
    return {
        params:
            type === 'new'
                ? {
                      title: 'Untitled',
                  }
                : {},
        sectionIndex: -1,
        currentSection: {
            description: '',
            files: [],
            title: '',
            type: undefined,
        },
        status: 'init',
        canEdit: false,
        canPublish: false,
        tags: [],
        sections: [],
        vocabulary_topic: '',
        vocabulary_list: [],
        grammar: [],
        skills: [],
        student_book_page: '',
        active_app_review: '',
        workbook_page_review: '',
        unit_progress: '',
        unit_number: '',
        lesson_number: '',
        lesson_goal: '',
        custom_fields: [],
    };
};

export interface LessonFormInterface {
    data: {};
    status: 'init' | 'touched';
    currentSection: SectionState;
    sectionIndex: number;
    canEdit: boolean;
    canPublish: boolean;
}

export interface ActionType {
    type: string;
    value: any;
    status?: 'init' | 'touched';
}

export const LESSON_ACTIONS = {
    RESET: 'RESET',
    SET_TITLE: 'SET_TITLE',
    SET_LESSON: 'SET_LESSON',
    SET_TAGS: 'SET_TAGS',
    ADD_TAGS: 'ADD_TAGS',
    REMOVE_TAGS: 'REMOVE_TAGS',
    SET_SECTION: 'SET_SECTION',
    EDIT_SECTIONS: 'EDIT_SECTIONS',
    MOVE_SECTIONS: 'MOVE_SECTIONS',
    DELETE_SECTIONS: 'DELETE_SECTIONS',
    ADD_SECTION: 'ADD_SECTION',
    CAN_EDIT: 'CAN_EDIT',
    CAN_PUBLISH: 'CAN_PUBLISH',
    CAN_APPROVE: 'CAN_APPROVE',
    SET_LESSONS_FROM_LESSON: 'SET_LESSONS_FROM_LESSON',
    SET_STATUS: 'SET_STATUS',
    SET_VOCABULARY_TOPIC: 'SET_VOCABULARY_TOPIC',
    SET_VOCABULARY_LIST: 'SET_VOCABULARY_LIST',
    SET_GRAMMAR: 'SET_GRAMMAR',
    SET_SKILLS: 'SET_SKILLS',
    SET_STUDENT_BOOK_PAGE: 'SET_STUDENT_BOOK_PAGE',
    SET_ACTIVE_APP_REVIEW: 'SET_ACTIVE_APP_REVIEW',
    SET_WORKBOOK_REVIEW_PAGE: 'SET_WORKBOOK_REVIEW_PAGE',
    SET_UNIT_PROGRESS: 'SET_UNIT_PROGRESS',
    SET_TOUCHED: 'SET_TOUCHED',
    SET_UNIT_NUMBER: 'SET_UNIT_NUMBER',
    SET_LESSON_NUMBER: 'SET_LESSON_NUMBER',
    SET_CUSTOM_FIELD_LIST: 'SET_CUSTOM_FIELD_LIST',
    SET_LESSON_GOAL: 'SET_LESSON_GOAL'
};

export const lessonReducer = (state: LessonBuilderType, action: ActionType): LessonBuilderType => {
    const { type, value, status } = action;
    switch (type) {
        case LESSON_ACTIONS.SET_TITLE: {
            return {
                ...state,
                status: 'touched',
                params: {
                    ...state.params,
                    title: value,
                },
            };
        }
        case LESSON_ACTIONS.SET_TAGS:
            return {
                ...state,
                tags: value,
            };
        case LESSON_ACTIONS.ADD_TAGS: {
            return {
                ...state,
                tags: value,
                status: 'touched',
            };
        }
        case LESSON_ACTIONS.SET_TOUCHED: {
            return {
                ...state,
                status: 'touched',
            };
        }
        case LESSON_ACTIONS.REMOVE_TAGS: {
            return {
                ...state,
                tags: value,
                status: 'touched',
            };
        }
        case LESSON_ACTIONS.SET_LESSON:
            return {
                ...state,
                params: value,
                canEdit: state.canEdit,
                canPublish: state.canPublish,
            };

        case LESSON_ACTIONS.SET_SECTION: {
            return {
                ...state,
                ...value,
            };
        }
        case LESSON_ACTIONS.ADD_SECTION:
            return {
                ...state,
                params: {
                    ...state.params,
                    sections: value,
                },
                sections: value,
                status: 'touched',
            };
        case LESSON_ACTIONS.EDIT_SECTIONS: {
            const copyState = { ...state };
            const currentSectionIndex = state.sectionIndex;
            const { sections } = copyState;
            if (sections[currentSectionIndex]) {
                sections[currentSectionIndex] = value;
            }
            copyState.params = { ...copyState.params, sections };

            return {
                ...copyState,
                status: 'touched',
            };
        }
        case LESSON_ACTIONS.MOVE_SECTIONS: {
            return {
                ...state,
                params: {
                    ...state.params,
                    sections: value,
                },
                sections: value,
                sectionIndex: -1,
                status: 'touched',
            };
        }
        case LESSON_ACTIONS.DELETE_SECTIONS: {
            const copyState = { ...state };
            const {
                params: { sections = [] },
                sections: sectionsRender,
            } = copyState;
            if (sectionsRender[value]) {
                if (value === 0) {
                    sectionsRender.shift();
                } else {
                    sectionsRender.splice(value, 1);
                }
                copyState.sectionIndex = -1;
                copyState.currentSection = {
                    description: '',
                    title: '',
                    files: [],
                    type: '',
                };
            }
            return {
                ...copyState,
                params: {
                    ...copyState.params,
                    sections: sectionsRender,
                },
                status: 'touched',
            };
        }
        case LESSON_ACTIONS.CAN_EDIT: {
            return {
                ...state,
                canEdit: value,
            };
        }
        case LESSON_ACTIONS.CAN_PUBLISH: {
            return {
                ...state,
                canPublish: value,
            };
        }
        case LESSON_ACTIONS.SET_LESSONS_FROM_LESSON: {
            if (value.length > 0) {
                return {
                    ...state,

                    sections: value,

                    currentSection: {
                        description: value[0].description,
                        files: value[0].files?.map((j) => ({
                            ...j,
                        })),
                        title: value[0].title,
                        type: value[0].type,
                    },
                    sectionIndex: 0,
                };
            }

            return {
                ...state,

                sections: value,
            };
        }
        case LESSON_ACTIONS.SET_STATUS: {
            return {
                ...state,
                status: 'init',
            };
        }
        case LESSON_ACTIONS.RESET: {
            return {
                ...state,
                params: {},
                status: 'init',
            };
        }
        case LESSON_ACTIONS.SET_VOCABULARY_TOPIC: {
            return {
                ...state,
                params: {
                    ...state.params,
                    vocabulary_topic: value,
                },
                status: 'touched',
            };
        }
        case LESSON_ACTIONS.SET_VOCABULARY_LIST: {
            return {
                ...state,
                params: {
                    ...state.params,
                    vocabulary_list: value,
                },
                status: 'touched',
            };
        }
        case LESSON_ACTIONS.SET_GRAMMAR: {
            return {
                ...state,
                params: {
                    ...state.params,
                    grammar: value,
                },
                status: 'touched',
            };
        }
        case LESSON_ACTIONS.SET_SKILLS: {
            return {
                ...state,
                params: {
                    ...state.params,
                    skills: value,
                },
                status: 'touched',
            };
        }
        // SET_CUSTOM_FIELD
        case LESSON_ACTIONS.SET_CUSTOM_FIELD_LIST: {
            return {
                ...state,
                params: {
                    ...state.params,
                },
                custom_fields: value,
                status: status,
            };
        }

        case LESSON_ACTIONS.SET_STUDENT_BOOK_PAGE: {
            return {
                ...state,
                params: {
                    ...state.params,
                    student_book_page: value,
                },
                status: 'touched',
            };
        }
        case LESSON_ACTIONS.SET_ACTIVE_APP_REVIEW: {
            return {
                ...state,
                params: {
                    ...state.params,
                    active_app_review: value,
                },
                status: 'touched',
            };
        }
        case LESSON_ACTIONS.SET_WORKBOOK_REVIEW_PAGE: {
            return {
                ...state,
                params: {
                    ...state.params,
                    workbook_page_review: value,
                },
                status: 'touched',
            };
        }
        case LESSON_ACTIONS.SET_UNIT_PROGRESS: {
            return {
                ...state,
                params: {
                    ...state.params,
                    unit_progress: value,
                },
                status: 'touched',
            };
        }
        case LESSON_ACTIONS.SET_UNIT_NUMBER: {
            return {
                ...state,
                params: {
                    ...state.params,
                    unit_number: value,
                },
                status: 'touched',
            };
        }
        case LESSON_ACTIONS.SET_LESSON_NUMBER: {
            return {
                ...state,
                params: {
                    ...state.params,
                    lesson_number: value,
                },
                status: 'touched',
            };
        }
        case LESSON_ACTIONS.SET_LESSON_GOAL: {
            return {
                ...state,
                params: {
                    ...state.params,
                    lesson_goal: value,
                },
                status: 'touched',
            };
        }
        default:
            return state;
    }
};
