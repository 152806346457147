import {  useMemo, useState } from 'react'
import { USER_STATUS, USER_STATUS_LABEL } from '../constant';
import ModalResendIntiveMember from '../ModalAction/ModalResendIntiveMember'
import { checkPermission } from 'utils/commonFun';
import './Status.scss';
import { useTranslation } from 'react-i18next';

interface StatusProps {
    user: Readonly<{id: string, email: string}>;
    status: Readonly<string>;
    refreshData?: () => void;
}

export default function Status({ user, status, refreshData = undefined }: Readonly<StatusProps>) {
    const [isOpenModalResendInvite, setIsOpenModalResendInvite] = useState(false);
    const {t} = useTranslation();
    const   style = useMemo(() => {
        switch (status) {
            case USER_STATUS.ACTIVE:
                return 'active';
            case USER_STATUS.DEACTIVATE:
                return 'deactivate';
            case USER_STATUS.INVITE:
                return 'invite';
            case USER_STATUS.REGISTERED:
                return 'registered';
            default:
                return '';
        }
    }
    , [status]);
    
    return (
        <div className="flex gap-1 items-center">
            <div
                className={`default ${(style)}`}
            >
                {t(`USER.STATUSES.${USER_STATUS_LABEL[status]}`)}
            </div>
            {(checkPermission('user', 'U') && status === USER_STATUS.INVITE) && (
                <button
                    type="button"
                    onClick={() => {
                        setIsOpenModalResendInvite(true);
                    }}
                    className="text-primary-500 text-xs cursor-pointer"
                >
                   {t(`USER.STATUSES.RESEND`)}
                </button>
            )}

            {isOpenModalResendInvite && (
                <ModalResendIntiveMember
                    isOpen={isOpenModalResendInvite}
                    onClose={() => {
                        setIsOpenModalResendInvite(false)
                    }}
                    refreshData={() => refreshData?.()}
                    data={user}
                />
            )}
        </div>
    )
}
