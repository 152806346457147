import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { XIcon } from '@heroicons/react/outline';
import { EditIcon } from 'assets/icon';
import { useHistory, useParams } from 'react-router-dom';
import learningPathService from 'services/learningPath.service';
import ActionMenu from '../ActionMenu';
import { checkPermission } from 'utils/commonFun';

interface DialogProps {
    isOpen: boolean;
    maskClosable?: boolean;
    isShowClose?: boolean;
    onCloseModal?: (e?: any) => void;
    child?: any;
    loading?: boolean;
    data: any;
    onDelete: Function;
    onRename: Function;
    onDuplicateLearningPath: Function;
    onViewDetail: () => void;
    onRevertToDraft: Function;
}

const ModalViewDetailLearningPath: React.FC<DialogProps> = ({
    isOpen,
    maskClosable = false,
    isShowClose = true,
    onCloseModal,
    child: ChildComponent,
    loading = false,
    data,
    onDelete,
    onRename,
    onDuplicateLearningPath,
    onViewDetail,
    onRevertToDraft
}) => {
    const closeButtonRef = React.useRef(null);
    const params: any = useParams();
    const history = useHistory();
    const [learningPath, setLearningPath] = useState<any>()

    useEffect(() => {
        learningPathService.getlearningPathDetail(params?.id, data?.id)
            .then((res) => {
                setLearningPath(res.data)
            })
    }, [data])

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed w-[40vw] right-0 bottom-0 z-70 top-[53px]"
                onClose={maskClosable ? onCloseModal : function () { }}
                initialFocus={closeButtonRef}
            >
                <div className="min-h-[80vh] text-center ">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className='inline-block py-4 min-h-screen text-left align-middle transition-all transform bg-white shadow-xl w-full px-0 top-14'
                        >
                            {loading ? (
                                <div className="flex items-center justify-center mt-4">
                                    <CircleSpin color="text-primary-500" />
                                </div>
                            ) : (
                                <>
                                    <Dialog.Title as="div" className={`pb-[13px] flex justify-between border-b px-5 `}>
                                        <div className="flex items-center mr-2">
                                            <h1 className={`text-base w-full font-normal text-gray-900 leading-6 flex items-center `}>
                                                {learningPath?.title}
                                            </h1>
                                        </div>
                                        <div className="flex items-center gap-2 text-gray-900">
                                            <button
                                                type="button"
                                                onClick={() => history.push(`/workspace/${params?.id}/learning-path/${learningPath?.id}`)}
                                                className="mr-2 disabled:cursor-not-allowed"
                                                disabled={!checkPermission('learningPath', 'U')}
                                            >
                                                <EditIcon fill="#1F2937" height="18" width="18" />
                                            </button>

                                            <ActionMenu
                                                data={learningPath}
                                                onRename={onRename}
                                                onDelete={onDelete}
                                                onDuplicate={() => onDuplicateLearningPath(learningPath?.id)}
                                                onRevertToDraft={onRevertToDraft}
                                            />

                                            <button
                                                hidden={!isShowClose}
                                                type="button"
                                                onClick={onCloseModal}
                                                ref={closeButtonRef}
                                                className="focus:outline-none"
                                            >
                                                <XIcon className="w-5 h-5 text-gray-800" />
                                            </button>
                                        </div>
                                    </Dialog.Title>
                                    <Fragment>
                                        <div className="ModalViewDetailLearningPath">
                                            <ChildComponent item={learningPath} />
                                        </div>
                                    </Fragment>
                                </>
                            )}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default ModalViewDetailLearningPath;
