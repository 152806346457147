import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './../store';
import assignmentService from '../../services/assignment.service';
import courseService from 'services/course.service';
import { ICourseTemplate } from 'types/Course.type';
import { ICourseSettings, ISyllabus } from 'types/Courses.type';
import courseTemplateService from 'services/courseTemplate.service';

interface CourseTemplateState {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    data: ICourseTemplate;
    error?: any;
    changed: boolean;
    courseSelect?: any;
    hasShowCourseSetting: boolean
    enrollmentSelected: any,
    setting: ICourseSettings,
    peopleNumber: {
        number_instructor: number, 
        number_learner: number
    }
}
type ValidationErrors = {
    errorMessage: string;
    field_errors: Record<string, string>;
};
const initialState: CourseTemplateState = {
    loading: 'idle',
    changed: false,
    courseSelect: null,
    enrollmentSelected: undefined,
    hasShowCourseSetting: false,
    setting: null,
    data: {
        id: null,
        title: null,
        syllabuses: []
    },
    peopleNumber: {
        number_instructor: 0,
        number_learner: 0
    }
};

export const courseTemplateFetch: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('courseTemplate/fetch', async (params, { rejectWithValue }) => {
    try {
        const response = await courseTemplateService.getCourse(params);
        if (!response) {
            return rejectWithValue('');
        }
        return response;
    } catch (error) {
        return error;
    }
});

export const getCourseTemplateSettings: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('courseTemplate/getCourseSettings', async (params, { rejectWithValue }) => {
    try {
        const response = await courseTemplateService.getCourseSettings(params);
        if (!response) {
            return rejectWithValue('');
        }
        return response;
    } catch (error) {
        return error;
    }
});

export const courseTemplateCreate: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('courseTemplate/create', async (params, { rejectWithValue }) => {
    try {
        const response = await courseTemplateService.createCourse(params);
        if (!response) {
            return rejectWithValue('');
        }
        return response;
    } catch (error) {
        return error;
    }
});

export const courseTemplateUpdate: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('courseTemplate/update', async (params, { rejectWithValue }) => {
    try {
        const response = await courseTemplateService.updateCourse(params);
        if (!response || response?.status === 400) {
            return rejectWithValue('');
        }
        return response;
    } catch (error) {
        return error;
    }
});

export const updateTemplateSetting: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('courseTemplate/updateSetting', async (params: any, { rejectWithValue }) => {
    try {
        const response = await courseTemplateService.updateCourseSetting(params);
        if (!response || response?.data?.error) {
            return rejectWithValue(response?.data?.error);
        }
        return params;
    } catch (error) {
        return error;
    }
});

export const createCourseTemplateSyllabus: any = createAsyncThunk<{rejectValue: ValidationErrors;}>
('courseTemplate/createCourseSyllabus', async (params: any, { rejectWithValue }) => {
    try {
        const response = await courseTemplateService.createCourseSyllabus(params);
        if (!response) {
            return rejectWithValue('');
        }
        return { id: params.contentId };
    } catch (error) {
        return error;
    }
});

export const updateCourseTemplateSyllabusOrder: any = createAsyncThunk<{rejectValue: ValidationErrors;}>
('courseTemplate/updateCourseSyllabusOrder', async (params: any, { rejectWithValue }) => {
    try {
        const response = await courseTemplateService.updateCourseSyllabusOrder(params);
        if (!response) {
            return rejectWithValue('');
        }
        return { id: params.contentId };
    } catch (error) {
        return error;
    }
});

export const deleteCourseTemplateSyllabus: any = createAsyncThunk<{rejectValue: ValidationErrors;}>
('courseTemplate/deleteCourseSyllabus', async (params: any, { rejectWithValue }) => {
    try {
        const response = await courseTemplateService.deleteCourseSyllabus(params);
        if (!response) {
            return rejectWithValue('');
        }
        return { id: params.contentId };
    } catch (error) {
        return error;
    }
});

export const courseTemplateSlice = createSlice({
    name: 'courseTemplate',
    initialState,
    reducers: {
        clearError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        changeShowCourseTemplateSetting:  (state, action: PayloadAction<boolean>) => {
            state.hasShowCourseSetting = action.payload;
        },
        setEnrollmentSelected:  (state, action: PayloadAction<boolean>) => {
            state.enrollmentSelected = action.payload;
        },
        updatePeopleNumber: (state, action: PayloadAction<{number_instructor: number, number_learner: number}>) => {
            state.peopleNumber = action.payload;
        },
        setCourse: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(courseTemplateFetch.fulfilled, (state, { payload }) => {
            state.loading = 'succeeded';
            state.changed = false;
            state.data = payload?.data;
        }),
        builder.addCase(getCourseTemplateSettings.fulfilled, (state, { payload }) => {
            state.loading = 'succeeded';
            state.changed = false;
            state.setting = payload?.data;
        }),
        builder.addCase(updateTemplateSetting.fulfilled, (state, { payload }) => {
            state.loading = 'succeeded';
            state.changed = false;
            state.setting = payload;
        }),
        builder.addCase(courseTemplateUpdate.fulfilled, (state, { payload }) => {
            state.loading = 'succeeded';
            state.changed = true;
            state.data = {
                ...state.data,
                ...payload.data
            };
        }),
        builder.addCase(courseTemplateCreate.rejected, (state, action) => {
            if (action.payload) {
                state.error = action.payload.errorMessage;
            } else {
                state.error = action.error;
            }
            state.loading = 'failed';
        }),
        builder.addMatcher(isPending, (state, action) => {
            state.loading = 'pending';
        }),
        builder.addMatcher(isRejected, (state, action) => {
            state.loading = 'failed';
        }),
        builder.addMatcher(isFulfilled, (state, action) => {
            state.changed = true;
            state.loading = 'succeeded';
        });
    },
});

export const { clearError, changeShowCourseTemplateSetting, setEnrollmentSelected, updatePeopleNumber, setCourse } = courseTemplateSlice.actions;

export const selectCourseTemplateSlice = (state: RootState) => state.courseTemplate;

export default courseTemplateSlice.reducer;
