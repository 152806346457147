import { Disclosure } from "@headlessui/react";
import { Book3Outline, BookMarkLine, CaretIcon, DraftLine, OpenBox } from "assets/icon";
import { LearningPathLesson } from "types/LearningPath.type";
import { ButtonIcon } from "_shared";
import { UnitActions } from "./UnitActions/UnitActions";
import "./UnitContent.scss";
import { useTranslation } from "react-i18next";

export const UnitContent = ({ contents }: { contents: any }) => {
    const {t} = useTranslation();
    return (
        <div className="unitContent">
            {contents?.map((item: LearningPathLesson) => (
                <Disclosure key={item?.id}>
                    {({ open }) => (
                        <div className="unitContent-item">
                            <Disclosure.Button className="w-full">
                                <div className="unitContent-header">
                                    {item.library_content.sub_object_type != 'scorm' ? <ButtonIcon type="button" onClick={() => { }}>
                                        <span className={`${open ? 'rotate-90' : ''}`}>
                                            <CaretIcon size={22} />
                                        </span>
                                    </ButtonIcon>: null}
                                    <div className="unitContent-info">
                                        <h3 className="unitContent-name">{item?.library_content?.name}</h3>
                                        {item.library_content.sub_object_type == 'scorm' ? <span className="unitContent-scorm">
                                            <span className="text-primary-500"><OpenBox /></span>
                                            {t("COURSES.SCORM_LESSON")}
                                        </span> : null}
                                    </div>
                                    <div className="unitContent-action">
                                        <UnitActions values={item} />
                                    </div>
                                </div>
                            </Disclosure.Button>
                            {open && item.library_content.sub_object_type !== 'scorm' ? <Disclosure.Panel>
                                <div className="unitContent-content">
                                    <div className="unitContent-content-item">
                                        <div className="unitContent-label">{t("COURSES.CONTAINS")}:</div>
                                        <div className="unitContent-value">
                                            {item?.learning_materials > 0 && <div className="unitContent-value-item">
                                                <span className="text-gray-400 mr-0.5">
                                                    <BookMarkLine />
                                                </span>
                                                {t('COURSES.LEARNING_MATERIAL')}
                                            </div>}
                                            {item?.assignments > 0 && <div className="unitContent-value-item">
                                                <span className="text-gray-400 mr-0.5">
                                                    <DraftLine />
                                                </span>
                                                {t('COURSES.ASSIGNMENTS')}
                                            </div>}
                                            {item?.supplementary_materials > 0 && <div className="unitContent-value-item">
                                                <span className="text-gray-400 mr-0.5">
                                                    <Book3Outline size={16} />
                                                </span>
                                                {t('COURSES.SUPPLEMENTARY_MATERIALS')}
                                            </div>}
                                        </div>
                                    </div>
                                    {item?.lesson_goal ? <div className="unitContent-content-item mt-2">
                                        <div className="unitContent-label">{t("COURSES.LESSON_GOAL")}:</div>
                                        <div className="unitContent-value">
                                           {item?.lesson_goal ?? 'N/A'} 
                                        </div>
                                    </div> : null}
                                </div>
                            </Disclosure.Panel> : null}
                        </div>
                    )}
                </Disclosure>
            ))}
        </div>
    )
}