import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { PaymentGatewaysState, PaymentGatewaysType } from 'constant/payment.const';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonOutline } from '_shared';
import "./index.scss";

export const PaymentMethod = ({ label, brand, type, data, onAdd, onActive, onDeActive, loading = false, onDashboard }) => {
    const { t: translator } = useTranslation();
    const restrictedStatus = useMemo(() => {
        if (!data?.data?.account || !data?.data?.account?.requirements?.disabled_reason ||
            data?.data?.account?.requirements?.disabled_reason?.length == 0) {
            return false;
        }
        return data?.data?.account?.requirements?.disabled_reason?.length > 0;
    }, [data]);

    return <>
        {data?.connect_state !== PaymentGatewaysState.connected ?
            <div className="paymentGateways-method" onClick={() => onAdd(type)}>
                <label className='paymentGateways-connect'>
                    {label}
                </label>
                <img src={brand} alt={type} />
            </div> :
            <div className='paymentGateways-connected'>
                <div className='paymentGateways-brand'>
                    <div className={`paymentGateways-info`}>
                        <img src={brand} alt={type} />
                        <span className={`paymentGateways-status ${data?.enabled && !restrictedStatus ? 'paymentGateways-status--enabled' : 'paymentGateways-status--restricted'}`}>
                            {!data?.enabled ? translator('Inactive') : restrictedStatus ? 'Action Required' : translator('Active')}</span>
                    </div>
                    {type === 'stripe' &&
                        <div className='flex items-center gap-2'>
                            {!restrictedStatus && <>{!data?.enabled ? <ButtonOutline type="button" size='M' onClick={() => onActive(type)} className="buttonOutline--isPrimary">
                                {translator('ACTIVATE')}
                            </ButtonOutline> : <ButtonOutline type="button" size='M' onClick={() => onDeActive(type)} className=" !text-red-500">
                                {translator('DEACTIVATE')}
                            </ButtonOutline>}
                            </>}
                            <ButtonOutline type="button" size='M' onClick={() => restrictedStatus ? onAdd(type, true) : onDashboard()} disabled={loading} className="buttonOutline--isPrimary">
                                {loading ? <div className='w-40 flex justify-center'><CircleSpin color='text-primary-500' /></div> :
                                    translator('DASHBOARD.WORKSPACE_SETTING.PAYMENT_DETAILS.OPEN_STRIPE_DASHBOARD')}
                            </ButtonOutline>
                        </div>}

                    {type === 'paypal' && <> {restrictedStatus ?
                        <>
                            <ButtonOutline type="button" size='M' onClick={() => onAdd(type, true)} disabled={loading} className="buttonOutline--isPrimary">
                                {loading ? <div className='w-40 flex justify-center'><CircleSpin color='text-primary-500' /></div> :
                                    translator('Update Your Information')}
                            </ButtonOutline>
                        </> :
                        <>
                            {!data?.enabled ? <ButtonOutline type="button" size='M' onClick={() => onActive(type)} className="buttonOutline--isPrimary">
                                {translator('ACTIVATE')}
                            </ButtonOutline> : <ButtonOutline type="button" size='M' onClick={() => onDeActive(type)} className=" !text-red-500">
                                {translator('DEACTIVATE')}
                            </ButtonOutline>}
                        </>
                    }
                    </>
                    }
                </div>
                {restrictedStatus ?
                    <p className='text-red-500 text-xs font-normal mt-2'>{type === 'stripe' ? translator('DASHBOARD.WORKSPACE_SETTING.PAYMENT_DETAILS.MESSAGE_RESTRICTED_STRIPE') : translator('DASHBOARD.WORKSPACE_SETTING.PAYMENT_DETAILS.MESSAGE_RESTRICTED')}</p> :
                    <label className='label'>{translator('E_COMMERCE.Connected_Account')}</label>
                }
                <div className='paymentGateways-value'>
                    {type == PaymentGatewaysType.stripe ?
                        data?.data?.account?.business_profile?.support_email :
                        data?.data?.account?.email
                    }
                </div>
            </div>
        }
    </>
}