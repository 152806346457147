//@ts-nocheck
import * as React from 'react';
// PACKAGE
import {
    matchPath,
    Route,
} from 'react-router';
import PrivateRouteProvider from 'contexts/PrivateRoute/PrivateRouteProvider';
import { BarType, NewBarType } from 'constant/setupBars.const';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

// Requires authentication
const FullLayoutRouter: React.FC<
    { barType?: BarType } & { [key: string]: any } & { newBar?: NewBarType }
> = ({
    path,
    isAuthStorage,
    setAuthStorage,
    storageUserInfo,
    barType,
    component: Component,
    newBar,
    documentTitle='',
    ...rest
}) => {
    useDocumentTitle(documentTitle);
        const {
            dispatch: WorkspaceDispatch,
            getWorkspaceDetailState: { result: WorkspaceDetailInformation },
        } = React.useContext(GetWorkspaceContext);

        const currentPath = matchPath(window.location.pathname, {
            path: '/workspace/:id',
            strict: false,
        });

        React.useEffect(() => {
            if (WorkspaceDetailInformation.id === -1 && currentPath) {
                const params: any = currentPath.params;
                workspaceMiddleware.getWorkspace(WorkspaceDispatch, {
                    id: params.id,
                });
            }
            // return () => workspaceMiddleware.resetUserState(WorkspaceDispatch);
        }, []);
        return (

            <Route
                path={path}
                {...rest}
                exact
                render={(props) => {
                    return isAuthStorage ? (
                        <PrivateRouteProvider barType={barType} newBarType={newBar}>
                            <Component
                                {...props}
                                isAuthStorage={isAuthStorage}
                                setAuthStorage={setAuthStorage}
                                storageUserInfo={storageUserInfo}
                            />
                        </PrivateRouteProvider>
                    ) : (
                        null
                    );
                }}
            />

        );
    };

export default FullLayoutRouter;
