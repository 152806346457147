import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import AvatarCard from 'components/UiComponents/AvatartCard';
import { BookSingleIcon } from 'assets/icon/BookSingleIcon';
import Comments from 'components/UiComponents/Comments';
import classService from 'services/class.service';
import { useParams } from 'react-router-dom';
interface GradeReportProps {
    reportId: number;
    classOfReport?: string;
}
const LessonReport: React.FC<GradeReportProps> = ({ reportId, classOfReport = null }) => {
    const { t: translator } = useTranslation();
    const [dataView, setDataView] = useState<any>();
    const [comments, setComments] = useState(null);
    const params: { id: string; classId: string } = useParams();

    useEffect(() => {
        classService
            .getReportDetailsOfClass(params.id, params?.classId || classOfReport, reportId)
            .then((res) => {
                if (res) {
                    setDataView(res?.data);
                }
            })
            .catch((err) => console.log(err));
    }, [reportId]);

    useEffect(() => {
        let comment = {
            id: '',
            display_name: '',
            comment: '',
            comment_vn: '',
        };
        if (dataView?.session_attendance?.instructor_comment || dataView?.session_attendance?.instructor_comment_vn) {
            comment = {
                ...comment,
                id: dataView?.session_attendance?.commenter?.id || 'commenter',
                display_name: dataView?.session_attendance?.commenter?.display_name || '',
                comment: dataView?.session_attendance?.instructor_comment,
            };
            setComments([comment]);
        } else {
            setComments([]);
        }
    }, [dataView]);

    return (
        <div className="flex justify-center">
            <div className="card p-6 font-inter mt-6 w-96">
                <div className="flex items-center">
                    <span className="text-xs text-ooolab_8083A3">
                        {translator('MY_REPORTS.' + dataView?.my_session?.type?.toUpperCase())}
                    </span>
                    <div className="ml-auto bg-ooolab_blue_0 text-ooolab_blue_light py-1 px-2 rounded-lg text-xs font-semibold">
                        {moment(new Date(dataView?.my_session?.start_time)).format('HH:mm')} -{' '}
                        {moment(new Date(dataView?.my_session?.end_time)).format('HH:mm')}
                        {` `}
                        {moment(new Date(dataView?.my_session?.end_time)).format('DD/MM/YYYY')}
                    </div>
                </div>
                <h2 className="heading-base mt-2 text-ooolab_dark_1">{dataView?.my_class?.name}</h2>
                <div className="mt-2">
                    <p className="text-ooolab_8083A3 text-xs">{dataView?.lesson?.title}</p>
                    <div className="flex flex-wrap">
                        {dataView?.instructors?.map((item) => {
                            return (
                                <div className="w-full" key={item.id}>
                                    <AvatarCard name={item.display_name} image={item.avatar_url} />
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="text-[#CC3E69] text-sm flex space-x-2 items-center mt-4">
                    <BookSingleIcon fill="#CC3E69" />
                    <span>{translator("EVALUATE.SESSION_DETAILS")}</span>
                </div>

                <div className="flex mt-3 text-sm text-ooolab_dark_1 items-center justify-between">
                    <span className=' font-semibold'>{translator("EVALUATE.SESSION_GRADE")}</span>
                    <span>{dataView?.session_attendance?.grade || 0}%</span>
                </div>

                <div className="flex mt-3 text-sm text-ooolab_dark_1 items-center justify-between">
                    <span className=' font-semibold'>{translator('EVALUATE.ATTENDANCE')}</span>
                    <span className=' capitalize'>{dataView?.session_attendance?.attendance_type || 0}</span>
                </div>

                <div className="flex mt-3 text-sm text-ooolab_dark_1 items-center justify-between">
                    <span className=' font-semibold'>{translator('EVALUATE.EARNED')}</span>
                    <span>{dataView?.session_attendance?.total_apos || 0}</span>
                </div>
                {comments?.length > 0 && (
                    <div className="mt-3">
                        <Comments comments={comments} isEnglish={true} />
                    </div>
                )}
            </div>{' '}
        </div>
    );
};

export default LessonReport;
