import { ButtonOutline, ButtonPrimary, Toastify } from '_shared'
import DialogComponent from 'components/Dialog/DialogComponent';
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';

export default function ModalResendIntiveMember({isOpen, onClose, refreshData, data}) {
  const {t} = useTranslation();
  const params: {id} = useParams();
  const workspaceId = params?.id;

  const onResendInvite = (member) => {
    workspaceService.resetInviteMembers(workspaceId, member?.membership?.user_id)
    .then(() => {
        Toastify.success(t("USER.EMAIL_HAS_BEEN_SUCCESSFULLY_SENT"));
        refreshData();
    })
    .catch(() => Toastify.error(t("USER.RESEND_INVITATION_FAILED")))
    .finally(() => onClose())
}
  return (
    <DialogComponent
        title={
            <div className="flex gap-1 items-center text-orange-500">
                <div className="text-base text-gray-800">{t('RESEND')}</div>
            </div>
        }
        isOpen={isOpen}
        onCloseModal={onClose}
        child={
            <div className="flex flex-col">
                <div className="mt-2 text-sm text-gray-800">
                    {t('CONFIRM_RESEND_INVITATION')}
                </div>
                <div className="flex justify-center gap-4 mt-6 w-full">
                    <ButtonOutline type="button" onClick={onClose}>
                        {t('CANCEL')}
                    </ButtonOutline>
                    <ButtonPrimary type="button" onClick={() => onResendInvite(data)}>
                        {t('RESEND')}
                    </ButtonPrimary>
                </div>
            </div>
        }
    />
  )
}
