// @ts-ignore
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import {
    Controller,
    FieldValues,
    UseFormClearErrors,
    UseFormGetValues,
    UseFormHandleSubmit,
    UseFormRegister,
    UseFormSetError,
    UseFormSetValue,
    UseFormTrigger,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePickerInput from 'components/Management/components/Form/DatePicker';
import { useParams } from 'react-router-dom';
import { SelectOptionType } from 'types/GetListOfWorkspace.type';
import TimePickerInput from 'components/Management/components/Form/TimePicker';
import { ReceiveOptions, SendToOptions, configCkEditor, scheduleType } from './ConstantNotications';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './styles.css';
import UploadAdapter from './uploadImage';
import { checkPermission } from 'utils/commonFun';
import { InputFile, Toastify } from '_shared';
import uploadService from 'services/upload.service';
import { InputField, TextareaField } from 'components/InputForm/InputForm';
import InputRadio from '_shared/components/Form/InputRadio/InputRadio';
import { FORM_CONST } from 'constant/form.const';
import { AsyncSelectField, SelectField } from 'components/Select';
import workspaceService from 'services/workspace.service';
import courseService from 'services/course.service';
import groupService from 'services/group.service';
import workspaceMiddleware from 'middleware/workspace.middleware';
import notificationsService from 'services/notifications.service';
import { UserContext } from 'contexts/User/UserContext';
import dayjs from 'dayjs';
import SelectBox from '_shared/components/Select';
import { useDebouncedState } from 'hooks/useDebounce';
import No_CUSTOMGROUP from 'assets/img/empty-stage-learning-group.png'
interface NotificationFormProps {
    handleSubmit: UseFormHandleSubmit<FieldValues>;
    register: UseFormRegister<FieldValues>;
    errors: {
        [x: string]: any;
    };
    control: any;
    submitForm: (val: any) => void;
    setValue: UseFormSetValue<FieldValues>;
    setLabelListPeople: Dispatch<SetStateAction<SelectOptionType[]>>;
    setLabelListClasses: Dispatch<SetStateAction<SelectOptionType[]>>;
    setLabelListGroups: Dispatch<SetStateAction<SelectOptionType[]>>;
    labelListPeople: SelectOptionType[];
    labelListClasses: SelectOptionType[];
    labelListGroups: SelectOptionType[];
    dataNoti: any;
    trigger: UseFormTrigger<any>;
    getValues: UseFormGetValues<any>;
    watch: any;
    setImgPath: any;
    imgPath?: string;
    setError?: UseFormSetError<any>;
    clearErrors: UseFormClearErrors<any>;
}

const NotificationForm: React.FC<NotificationFormProps> = ({
    handleSubmit,
    register,
    errors,
    control,
    submitForm,
    setValue,
    dataNoti,
    trigger,
    getValues,
    watch,
    setImgPath,
    imgPath,
    setError,
    clearErrors,
}) => {
    const params: { id: string; notificationId: string } = useParams();
    const {userState: {result}} = useContext(UserContext)
    const { t: translator } = useTranslation();

    const filterPassedTime = (time) => {
        const values = getValues();
        const timeSelected = dayjs(`${dayjs(values.notiDate).format('YYYY-MM-DD')} ${dayjs(time).format('HH:mm')}`)
        const currentTime = dayjs().tz(result?.time_zone)
        return timeSelected.isAfter(currentTime)
    };

    const checkImageUpload = (file: any) => {
        const mimeType = ['image/jpeg', 'image/png'];
        const limitFileSize = 300 * 1024;

        const fileSize = file?.size || 0;
        const fileExtenion = file?.type || '';

        if (!mimeType.includes(fileExtenion) || fileSize > limitFileSize) {
            Toastify.error(
                translator(
                    'NOTIFICATION_CUSTOM.YOUR_IMAGE_SHOULD_BE_UPLOADED_AS_JPG_PNG_JPED_FILES_AND_MUST_BE_LESS_THAN_300KB_IN_SIZE'
                )
            );
        } else {
            uploadService.uploadFile(
                file,
                async (path: string) => {
                    setImgPath(process.env.REACT_APP_HOST_CDN + path);
                },
                () => {}
            );
        }
    };

    const handleChooseRadio = (value) => {
        if (value === 'link' && !watch('description')) {
            clearErrors('clearBefore');
            setValue('typeDesc', value);
        } else if (value === 'msg' && !watch('linkDetails')) {
            clearErrors('clearBefore');
            clearErrors('description');
            setValue('typeDesc', value);
        } else {
            setError('clearBefore', {
                type: 'custom',
                message: translator('NOTIFICATION_CUSTOM.MSG_WARNING_CHOOSE'),
            });
        }
    };


    const [specificIndividuals, setSpecificIndividuals] = useState([]);
    const [inputSearchSpecificIndividuals, setInputSearchSpecificIndividuals] = useDebouncedState('', 0);
    const [paginaginSpecificIndividuals, setPaginationSpecificIndividuals] = useState({ total: 0, page: 1, per_page: 10 });
    const [loadingSpecificIndividuals, setLoadingSpecificIndividuals] = useState(false);

    const getSpecificIndividualList = async (page: number) => {
        const courseId = watch('course')?.value;
        setLoadingSpecificIndividuals(true);
        let payload: any = {
            page,
            per_page: paginaginSpecificIndividuals.per_page,
            q: inputSearchSpecificIndividuals || null,
            status: ['invite', 'active']
        };
        if (!payload?.q) delete payload?.q;

        let response = undefined;

        if (courseId) {
            if (watch('send_to')?.value === 'specific_teaching_assistants') {
                response = await courseService.getTeachingAssistants(params?.id, courseId, payload);
            }
            if (watch('send_to')?.value === 'specific_instructors') {
                response = await courseService.getInstructorEnrolled(params?.id, courseId, payload);
            }
            if (watch('send_to')?.value === 'specific_learners') {
                response = await courseService.getLearners(params?.id, courseId, payload);
            }
            if (watch('send_to')?.value === 'specific_parents') {
                response = await courseService.getParents(params?.id, courseId, payload);
            }
        }
        else {
            if (watch('send_to')?.value === 'specific_instructors') {
                response = await workspaceMiddleware.getWorkspaceMembersMiddleware(params.id, {
                    ...payload,
                    user_role_name: 'Instructor',
                    location: 'clms',
                });
            }
            if (watch('send_to')?.value === 'specific_learners') {
                response = await workspaceMiddleware.getWorkspaceMembersMiddleware(params.id, {
                    ...payload,
                    type: 'student',
                    location: 'learner_portal',
                });
            }
        }
        
        if (response) {
            const newOptions = response?.data?.items?.map((el: any) => {
                return {
                    id: el?.id,
                    name: el?.display_name || el?.instructor?.display_name,
                    value: el?.user_teaching_assistant?.id || el?.instructor?.id || el?.id,
                    avatar_url: el?.avatar_url || el?.instructor?.avatar_url
                };
            });
            if (page === 1) setSpecificIndividuals(newOptions);
            else setSpecificIndividuals([...specificIndividuals, ...newOptions]);
            setPaginationSpecificIndividuals({ ...paginaginSpecificIndividuals, page: response?.data?.page, total: response?.data?.total });
            setLoadingSpecificIndividuals(false)
        }
    };

    useEffect(() => {
        getSpecificIndividualList(1);
    }, [inputSearchSpecificIndividuals, watch('send_to')?.value, watch('receivers')?.value, watch('course')?.id])

    const [customGroups, setCustomGroups] = useState([]);
    const [inputSearchCustomGroups, setInputSearchCustomGroups] = useDebouncedState('', 0);
    const [paginaginCustomGroups, setPaginationCustomGroups] = useState({ total: 0, page: 1, per_page: 10 });
    const [loadingCustomGroups, setLoadingCustomGroups] = useState(false);

    const getCustomGroupList = (page: number) => {
        setLoadingCustomGroups(true)
        let payload: any = {
            page: page,
            per_page: paginaginCustomGroups?.per_page,
            name: inputSearchCustomGroups || null,
        };
        if (!payload?.name) delete payload?.name;

        groupService.getGroupService(params?.id, payload)
         .then(res => {
            const newOptions = res?.data?.items?.map((el: any) => {
                return {
                    id: el?.id,
                    name: el?.name,
                    value: el?.id,
                };
            });
            if (page === 1) setCustomGroups(newOptions);
            else setCustomGroups([...customGroups, ...newOptions]);
            setPaginationCustomGroups({ ...paginaginCustomGroups, page: res?.data?.page, total: res?.data?.total });
         })
         .finally(() => setLoadingCustomGroups(false))
    };

    useEffect(() => {
        getCustomGroupList(1);
    }, [inputSearchCustomGroups])
    

    const getCourseList = async (bodyParams?: any) => {
        const res = await workspaceService.getCoursesList(params?.id, bodyParams);
        return {
            items: res?.items,
            total: res?.total,
        };
    };

    const handleLoadCourseList = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional?.page,
            per_page: 10,
            title: inputValue || null,
            status: 'active'
        };
        if (!payload?.title) delete payload?.title;

        const { items, total } = await getCourseList(payload);

        const newOptions = items?.map((el: any) => {
            return {
                id: el?.id,
                label: el?.title,
                value: el?.id,
            };
        });
        return {
            options: newOptions,
            hasMore: additional?.page < total / 20,
            additional: {
                page: additional?.page + 1,
            },
        };
    };

    useEffect(() => {
        if (params?.notificationId == 'new') {
            setValue('receiver_type', { label: 'People', value: 'people' });
            setValue('send_to', { value: 'all', label: 'All' });
        }
    }, [params]);

    useEffect(() => {
        if (params?.notificationId) {
            setValue('list_user_id', null);
            setValue('list_group_id', null);
        }
    }, [watch('send_to'), watch('receiver_type')]);

    useEffect(() => {
        setValue('list_user_id', null);
    }, [watch('course')]);


    useEffect(() => {
        setValue('course', null);
    }, [watch('receivers')]);

    useEffect(() => {
         if ((dataNoti?.receivers?.send_to && !dataNoti?.receivers?.send_to?.includes('all')) || dataNoti?.receivers?.receiver_type === 'custom_group') {
            notificationsService
                .getReceivers(params?.id, params?.notificationId, { skip_pagination: true })
                .then((res) => {
                    if (watch('receiver_type')?.value === 'custom_group') {
                        setValue(
                            'list_group_id',
                            res?.data?.items?.map((item) => ({ name: item?.name, value: item?.id, avatar_url: item?.avatar_url }))
                        );
                        return;
                    }
                    setValue(
                        'list_user_id',
                        res?.data?.items?.map((item) => ({ name: item?.display_name, value: item?.id, avatar_url: item?.avatar_url})) || []
                    );
                })
        }
    }, [dataNoti]);

    const renderLabelDropdownIndividual = () => {
        if (watch('send_to')?.value === 'specific_instructors') return translator("Select Instructor(s)");
        if (watch('send_to')?.value === 'specific_learners') return translator('Select Learner(s)');
        return translator('Select People');
    };

    return (
        <form onSubmit={handleSubmit(submitForm)}>
            <div className="notification p-5 grid grid-cols-12  gap-x-8 gap-y-5 ">
                <InputField
                    label={translator('NOTIFICATION_CUSTOM.TITLE')}
                    placeholder={translator('NOTIFICATION_CUSTOM.TITLE')}
                    maxLength={255}
                    register={register('title', {
                        required: {
                            value: true,
                            message: translator('FORM_CONST.REQUIRED_FIELD'),
                        },
                    })}
                    className="!col-span-8"
                    name="title"
                    errors={errors}
                    disabled={dataNoti?.status === 'published' || !checkPermission('customNotificationDetails', 'U')}
                    isRequired
                />
                <div className="col-span-4"></div>
                <div className="col-span-3 flex">
                    <div className="flex-none w-1/2">
                        <p className="text-xs font-semibold mb-1">{translator('NOTIFICATION_CUSTOM.IMAGE')}</p>
                        <InputFile
                            onChange={checkImageUpload}
                            imgDefault={imgPath || dataNoti?.image}
                            className="h-[100px]"
                            textHelp=""
                            stylesCenter="mt-3"
                        />
                    </div>
                    <div className="col-span-2 flex items-center text-xs text-gray-500 ml-2">
                        {translator('NOTIFICATION_CUSTOM.MAX_FILE_SIZE')}
                    </div>
                </div>
                <div className="col-span-5">
                    <TextareaField
                        label={translator('NOTIFICATION_CUSTOM.SUBTILE')}
                        name="subtitle"
                        className="col-span-4"
                        placeholder={translator('NOTIFICATION_CUSTOM.ENTER_SUBTITLE')}
                        register={register('subtitle', {
                            required: {
                                value: true,
                                message: translator('FORM_CONST.REQUIRED_FIELD'),
                            },
                        })}
                        textLength={getValues('subtitle')?.length}
                        maxLength={100}
                        isCountter
                        isRequired
                        errors={errors}
                    />
                </div>
                <div className="col-span-8 text-xs flex items-center gap-6">
                    <div className="msg flex items-center gap-2">
                        <InputRadio
                            control={control}
                            inputName="typeDesc"
                            value={`msg`}
                            checked={watch('typeDesc') === 'msg'}
                            disabled={false}
                            onClick={() => handleChooseRadio('msg')}
                        />
                        <p>{translator('NOTIFICATION_CUSTOM.MESSAGE')}</p>
                    </div>
                    <div className="link msg flex items-center gap-2">
                        <InputRadio
                            control={control}
                            inputName="typeDesc"
                            value={`link`}
                            checked={watch('typeDesc') === 'link'}
                            onClick={() => handleChooseRadio('link')}
                            disabled={false}
                        />
                        <p>{translator('NOTIFICATION_CUSTOM.LINK')}</p>
                    </div>
                </div>
                {watch('typeDesc') === 'msg' ? (
                    <div className="col-span-8 text-xs">
                        <label htmlFor="description" className="text-ooolab_dark_1 font-semibold leading-8 block">
                            {translator('NOTIFICATION_CUSTOM.MESSAGE')}
                            <span className="text-red-500"> *</span>
                        </label>

                        <Controller
                            name="description"
                            control={control}
                            {...register('description', {
                                required: {
                                    value: true,
                                    message: translator('FORM_CONST.REQUIRED_FIELD'),
                                },
                            })}
                            render={({ field, formState }) => (
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={getValues('description')}
                                    onReady={(eventData) => {
                                        eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
                                            return new UploadAdapter(loader);
                                        };
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setValue('description', data, {
                                            shouldTouch: true,
                                        });
                                        trigger('description');
                                    }}
                                    disabled={dataNoti?.status === 'published' || !checkPermission('customNotificationDetails', 'U')}
                                    config={configCkEditor}
                                />
                            )}
                        />
                        {errors?.clearBefore ? (
                            <ErrorMessage
                                className="text-red-500 text-xs font-semibold tracking-wider mt-1 leading-4"
                                errors={errors}
                                name="clearBefore"
                                as="p"
                            />
                        ) : (
                            <ErrorMessage
                                className="text-red-500 text-xs font-semibold tracking-wider mt-1 leading-4"
                                errors={errors}
                                name="description"
                                as="p"
                            />
                        )}
                    </div>
                ) : (
                    <div className="col-span-8">
                        <InputField
                            label={translator('NOTIFICATION_CUSTOM.LINK')}
                            placeholder={translator('NOTIFICATION_CUSTOM.LINK')}
                            name="link"
                            errors={errors}
                            register={register('link', {
                                required: translator('FORM_CONST.REQUIRED_FIELD'),
                                pattern: {
                                    value: FORM_CONST.URL_VALIDATE,
                                    message: translator('Please enter a valid URL!'),
                                },
                                maxLength: {
                                    value: 255,
                                    message: translator('FORM_CONST.VALIDATE_255_CHARACTERS'),
                                },
                            })}
                            isRequired
                        />
                    </div>
                )}
                <div className="col-span-4"></div>

                <div className="col-span-8 text-xs flex justify-between">
                    <label htmlFor="noti-sent" className="text-gray-800 font-semibold text-sm leading-8 block min-w-fit">
                        {translator('NOTIFICATION_CUSTOM.RECEIVERS')}
                    </label>
                    <SelectField
                        control={control}
                        className="col-span-4"
                        placeholder={translator('NOTIFICATION_CUSTOM.RECEIVERS')}
                        isRequired
                        name="receiver_type"
                        options={ReceiveOptions(translator)}
                        errors={errors}
                    />
                </div>

                <div className="col-span-4"></div>

                {watch('receiver_type')?.value === 'course' && (
                    <>
                        <AsyncSelectField
                            control={control}
                            label={translator('COURSES_LABEL')}
                            name="course"
                            placeholder={translator('SEARCH_COURSES')}
                            className="col-span-8"
                            closeMenuOnSelect={true}
                            errors={errors}
                            loadOptions={handleLoadCourseList}
                            isDisabled={dataNoti?.status === 'published' || !checkPermission('customNotificationDetails', 'U')}
                            isRequired
                        />
                        <div className="col-span-4"></div>
                    </>
                )}

                {watch('receiver_type')?.value !== 'custom_group' && (
                    <div className="col-span-3 text-xs mt-1.5">
                        <label htmlFor="noti-sent" className="text-ooolab_dark_1 font-semibold block min-w-fit">
                            {translator('NOTIFICATION_CUSTOM.SEND_TO')} <span className="text-red-500">*</span>
                        </label>
                        <SelectField
                            control={control}
                            className="col-span-4 mt-1.5"
                            placeholder={translator('NOTIFICATION_CUSTOM.SEND_TO')}
                            isRequired
                            name="send_to"
                            options={SendToOptions(translator)}
                            errors={errors}
                            key={`sendto-${watch('receiver_type')?.value}`}
                        />
                    </div>
                )}

                {watch('receiver_type')?.value === 'custom_group' && (
                    <div className="col-span-8">
                            <SelectBox
                                control={control}
                                label={translator('NOTIFICATION_CUSTOM.SELECT_CUSTOM_GROUP')}
                                name="list_group_id"
                                placeholder={translator('NOTIFICATION_CUSTOM.SELECT_CUSTOM_GROUP')}
                                errors={errors}
                                isMulti={true}
                                loading={loadingCustomGroups}
                                value={watch('list_group_id') || []}
                                onSearch={setInputSearchCustomGroups}
                                getData={getCustomGroupList}
                                data={customGroups}
                                pagination={paginaginCustomGroups}
                                numberOfItemDisplayed={6}
                                isRequired
                                formatOptionValue={(item) => (
                                    <span className="text-ellipsis truncate max-w-[95px] py-0.5">{item?.name}</span>
                                )}
                                formatOptionLabel={(item) => (
                                    <span className="text-ellipsis truncate ">{item?.name}</span>
                                )}
                                isDisabled={dataNoti?.status === 'published' || !checkPermission('customNotificationDetails', 'U')}
                                emptyState={{
                                    message: translator("GROUP.NOGROUP"),
                                    img: No_CUSTOMGROUP
                                }}
                            />
                    </div>
                )}

                {(watch('receiver_type')?.value === 'people'  || watch('receiver_type')?.value === 'course') && (
                    <div  className="col-span-5 gap-2 mt-1.5">
                        <SelectBox
                            control={control}
                            key={`id-${watch('send_to')?.value}`}
                            label={renderLabelDropdownIndividual()}
                            name="list_user_id"
                            placeholder={renderLabelDropdownIndividual()}
                            errors={errors}
                            isMulti={true}
                            loading={loadingSpecificIndividuals}
                            value={watch('list_user_id') || []}
                            onSearch={setInputSearchSpecificIndividuals}
                            getData={getSpecificIndividualList}
                            data={specificIndividuals}
                            pagination={paginaginSpecificIndividuals}
                            numberOfItemDisplayed={3}
                            isRequired={
                                        watch('send_to')?.value === 'specific_learners' || watch('send_to')?.value === 'specific_instructors'
                                    }
                            isDisabled={
                                dataNoti?.status === 'published' ||
                                !checkPermission('customNotificationDetails', 'U') ||
                                !watch('send_to')?.value ||
                                watch('send_to')?.value?.includes('all')
                            }
                        />
                    </div>
                )}

                <div className="col-span-4"></div>

                <div className="col-span-12 border-b border-ooolab_bar_color"></div>
                <div className="col-span-12 text-sm font-semibold text-gray-800"> {translator('NOTIFICATION_CUSTOM.SCHEDULE')}</div>
                <div className="col-span-4 text-xs">
                    <SelectField
                        control={control}
                        className="w-full mt-2"
                        placeholder={translator("SELECT")}
                        label={translator('NOTIFICATION_CUSTOM.SCHEDULE')}
                        isRequired
                        name="schedule_type"
                        options={scheduleType(translator)}
                        errors={errors}
                        isDisabled={dataNoti?.status === 'published' || !checkPermission('customNotificationDetails', 'U')}
                    />
                </div>
                {watch('schedule_type')?.value === 'schedule' && (
                    <>
                        <div className="col-span-2 text-xs">
                            <label htmlFor="notiDate" className="text-ooolab_dark_1 font-semibold leading-8 block">
                                {translator('NOTIFICATION_CUSTOM.DATE')} <span className="text-red-500">*</span>
                            </label>
                            <DatePickerInput
                                control={control}
                                name="notiDate"
                                placeholderText={translator('NOTIFICATION_CUSTOM.DATE')}
                                formatDate="MM/dd/yyyy"
                                minDate={new Date()}
                                isRequired
                                disabled={
                                    (watch('schedule_type')?.value === 'now' && params.notificationId === 'new') ||
                                    dataNoti?.status === 'published' ||
                                    !checkPermission('customNotificationDetails', 'U')
                                }
                            />
                        </div>
                        <div className="col-span-2 text-xs">
                            <label htmlFor="notiTime" className="text-ooolab_dark_1 font-semibold leading-8 block">
                                {translator('NOTIFICATION_CUSTOM.TIME')} <span className="text-red-500">*</span>
                            </label>
                            <TimePickerInput
                                control={control}
                                name="notiTime"
                                placeholderText={translator('NOTIFICATION_CUSTOM.TIME')}
                                filter={filterPassedTime}
                                disabled={
                                    (watch('schedule_type')?.value === 'now' && params.notificationId === 'new') ||
                                    dataNoti?.status === 'published' ||
                                    !checkPermission('customNotificationDetails', 'U')
                                }
                            />
                        </div>
                    </>
                )}
            </div>
        </form>
    );
};

export default NotificationForm;
