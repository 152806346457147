import React from "react";
import { Book3FillIcon, BookMarkFillIcon, DraftFillIcon } from "assets/icon";
import { TYPE_LESSON_BUILDER } from "../../LessonBuilder/constants";

import "./EmptyStage.scss";
import { checkPermission } from "utils/commonFun";

const EMPTY_STAGE = [
    {
        label: 'Add Learning Materials',
        icon: <BookMarkFillIcon width={56} height={56} />,
        colorIcon: 'text-primary-500 bg-primary-50',
        key: TYPE_LESSON_BUILDER.learningMaterials
    },
    {
        label: 'Add Assignments',
        icon: <DraftFillIcon />,
        colorIcon: 'text-pink-500 bg-pink-50',
        key: TYPE_LESSON_BUILDER.assignments
    },
    {
        label: 'Add Supplementary Materials',
        icon: <Book3FillIcon />,
        colorIcon: 'text-cyan-500 bg-cyan-50',
        key: TYPE_LESSON_BUILDER.supplementaryMaterials
    }
];

export const EmptyStage = ({ onTypeSelect }) => {
    return (
        <div className="emptyStage">
            <div className="emptyStage-list">
                {EMPTY_STAGE.map((item, index) => (
                    <button className="emptyStage-item disabled:cursor-not-allowed"
                        key={'emptyStage_' + index}
                        onClick={() => onTypeSelect(item.key)}
                        disabled= {!checkPermission('libraryContent', 'U')}
                    >
                        <span className={`emptyStage-icon ${item.colorIcon || ''}`}>{item.icon}</span>
                        <span className="emptyStage-title">{item.label}</span>
                    </button>
                ))}
            </div>
        </div>
    )
}