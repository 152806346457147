
export default function DriveIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.895186 12.7085C0.891876 13.0023 0.966156 13.2967 1.11803 13.56L3.15005 17.0829C3.44768 17.5989 3.99808 17.9168 4.59376 17.9168H15.4062C16.0019 17.9168 16.5523 17.5989 16.8499 17.0829L18.8819 13.56C19.0338 13.2967 19.1081 13.0023 19.1048 12.7085H0.895186Z" fill="#4285F4"/>
    <path d="M16.2465 17.6892L7.13165 1.90172C7.38555 1.75032 7.67946 1.6665 7.98532 1.6665H12.0147C12.6102 1.6665 13.1604 1.98417 13.4581 2.49984L18.8816 11.8936C19.1792 12.4091 19.1794 13.0441 18.882 13.5597L16.8499 17.0826C16.7025 17.3383 16.4929 17.5453 16.2465 17.6892Z" fill="#FBBC04"/>
    <path d="M12.8684 1.90173C12.6145 1.75032 12.3205 1.6665 12.0147 1.6665H7.98526C7.38982 1.6665 6.83961 1.98417 6.54189 2.49984L1.11837 11.8936C0.820772 12.4091 0.820642 13.0441 1.11803 13.5597L3.15005 17.0826C3.29753 17.3383 3.50709 17.5453 3.75346 17.6892L12.8684 1.90173Z" fill="#34A853"/>
    <path d="M19.1048 12.7085C19.1081 13.0023 19.0338 13.2967 18.8819 13.56L16.8499 17.0829C16.7024 17.3386 16.4929 17.5457 16.2465 17.6895L13.3707 12.7085H19.1048Z" fill="#EA4335"/>
    <path d="M12.8684 1.90173C12.6145 1.75032 12.3206 1.6665 12.0147 1.6665H7.98531C7.67946 1.6665 7.38555 1.75032 7.13165 1.90172L10 6.8699L12.8684 1.90173Z" fill="#188038"/>
    <path d="M0.895186 12.7085C0.891876 13.0023 0.966156 13.2967 1.11803 13.56L3.15005 17.0829C3.29753 17.3386 3.50709 17.5457 3.75346 17.6895L6.62925 12.7085H0.895186Z" fill="#1967D2"/>
    </svg>
  )
}

