import { FC } from 'react';
import { Popover } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/outline';
import PopoverAddLibrary from 'components/V2/UploadFile/PopoverAddLibrary';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from '_shared';

export const PopoverNewContent: FC<{
    setPopup: any;
    titleBtn?: string;
    stylesPopup?: string;
    stylesBtn?: string;
    setUpload?: any;
    setOpenSCORM?: any;
}> = ({ setPopup, titleBtn = 'New', stylesPopup = '', stylesBtn='w-[100px] h-[36px]', setUpload, setOpenSCORM }) => {
    const { t } = useTranslation();

    return (
        <>
            <Popover className="relative">
                <Popover.Button>
                    <ButtonPrimary onClick={() => {}} type="button" className={`${stylesBtn}`}>
                        <div className="flex items-center justify-center">
                            <PlusIcon className="w-4 h-4 mr-1" />
                            {titleBtn}
                        </div>
                    </ButtonPrimary>
                </Popover.Button>
                <Popover.Panel className={`absolute z-10 bg-white w-60 shadow-md right-0 ${stylesPopup}`}>
                    <PopoverAddLibrary setOpenModalNewFolder={setPopup} setOpenUpload={setUpload} setOpenSCORMModal={setOpenSCORM} />
                </Popover.Panel>
            </Popover>
        </>
    );
};
