import React, { useState } from 'react';
import { CloudCheckIcon } from 'assets/icon';
import { useAppSelector, useAppDispatch } from 'hooks/hooks';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { useParams } from 'react-router-dom';
import { WarningIcon } from 'assets/icon/WarningIcon';
import { Trans, useTranslation } from 'react-i18next';
import { checkPermission } from 'utils/commonFun';
import DialogComponent from 'components/Dialog/DialogComponent';
import courseService from 'services/course.service';
import './CourseHeader.scss';
import courseTemplateService from 'services/courseTemplate.service';
import { courseTemplateFetch } from 'store/features/courseTemplateSlice';

export const CourseTemplateHeader = () => {
    const { data, changed, error, loading } = useAppSelector((state) => state.courseTemplate);
    const dispatch = useAppDispatch();

    const params: any = useParams();
    const [hasActiveLearner, setHasActiveLearner] = useState(false);
    const [isOpenModalInactive, setOpenModalInactive] = useState(false);
    const [isOpenModalActive, setOpenModalActive] = useState(false);
    const { t } = useTranslation();
    const checkHasLearner = () => {
        courseService
            .checkHasActiveLearner({ workspaceId: params?.id, id: data?.id })
            .then((res) => {
                setHasActiveLearner(res?.data?.has_active_learner);
            })
            .catch((error) => console.error(error));
    };

    const onUpdateCourse = (value) => {
        courseTemplateService
            .updateCourse({ workspaceId: params?.id, id: data?.id, ...value })
            .then((res) => {
                Toastify.success();
                setOpenModalActive(false);
                setOpenModalInactive(false);
                dispatch(
                    courseTemplateFetch({
                        workspaceId: params.id,
                        id: params.courseId,
                    })
                );
            })
            .catch(() => Toastify.error());
    };

    return (
        <div className="courseHeader">
            <h1 className="courseHeader-title">{data?.template_name}</h1>
            {data?.status ? <span className={`courseHeader-status capitalize ${data?.status === 'published' ? 'courseHeader-status--active' : ''}`}>
                {t(data?.status?.toUpperCase())}
            </span> : null}
            <div className="courseHeader-save flex gap-4">
                {loading === 'pending' ? (
                    <span>
                        <CircleSpin color="text-primary-500" />
                    </span>
                ) : (
                    <span className={`${changed ? 'text-green-500' : null}`}>
                        <CloudCheckIcon />
                    </span>
                )}
                {t('COURSE_TEMPLATE.All_changes_saved')}
                {(data && checkPermission('courseInformation','U')) && (
                    <ButtonPrimary
                        type="button"
                        onClick={() => {
                            if (data?.status === 'draft') return setOpenModalActive(true);
                            setOpenModalInactive(true);
                        }}
                    >
                        {data?.status === 'published' ? t('COURSES.REVERT_TO_DRAFT') : t('COURSES.PUBLISH')}
                    </ButtonPrimary>
                )}
            </div>
            {isOpenModalInactive && (
                <DialogComponent
                    title={t('COURSES.REVERT_TO_DRAFT')}
                    isOpen={isOpenModalInactive}
                    onCloseModal={() => setOpenModalInactive(false)}
                    child={
                        <div className="flex flex-col">
                            {hasActiveLearner && (
                                <div className="text-sm text-gray-800">
                                    {/* @ts-ignore */}
                                    <Trans
                                        i18nKey={'COURSE_TEMPLATE.INACTIVE_COURSE_WARNING'}
                                        components={{
                                            1: <strong className="text-gray-800 font-semibold" />,
                                        }}
                                    />
                                </div>
                            )}
                            <div className="mt-2 text-sm text-gray-800">{t('COURSE_TEMPLATE.INACTIVE_COURSE')}</div>
                            <div className="flex justify-center gap-4 mt-6 w-full">
                                <ButtonOutline type="button" onClick={() => setOpenModalInactive(false)}>
                                    {t('CANCEL')}
                                </ButtonOutline>
                                <ButtonPrimary
                                    type="button"
                                    className="buttonPrimary-danger"
                                    onClick={() => onUpdateCourse({ status: 'draft' })}
                                >
                                    {t('CONFIRM')}
                                </ButtonPrimary>
                            </div>
                        </div>
                    }
                />
            )}

            {isOpenModalActive && (
                <DialogComponent
                    title={
                        <div className="flex gap-1 items-center text-orange-500">
                            <WarningIcon width="24" height="24" />{' '}
                            <div className="text-base text-gray-800">{t('COURSE_TEMPLATE.CONFIRM_ACTIVATE')}</div>
                        </div>
                    }
                    isOpen={isOpenModalActive}
                    onCloseModal={() => setOpenModalActive(false)}
                    child={
                        <div className="flex flex-col">
                            <div className="mt-2 text-sm text-gray-800">
                                {/* @ts-ignore */}
                                <Trans
                                    i18nKey={'COURSE_TEMPLATE.ACTIVATE_COURSE'}
                                    components={{
                                        1: <strong className="text-gray-800 font-semibold" />,
                                    }}
                                />
                            </div>
                            <div className="flex justify-center gap-4 mt-6 w-full">
                                <ButtonOutline type="button" onClick={() => setOpenModalActive(false)}>
                                    {t('CANCEL')}
                                </ButtonOutline>
                                <ButtonPrimary type="button" onClick={() => onUpdateCourse({ status: 'published' })}>
                                    {t('CONFIRM')}
                                </ButtonPrimary>
                            </div>
                        </div>
                    }
                />
            )}
        </div>
    );
};
