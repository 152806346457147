import { FC } from 'react';
import { CourseType } from 'types/Courses.type';
import { getTimeFromNow } from 'utils/handleFormatTime';

import CourseBG from 'assets/course-bg.jpg';
import '../../style.css';
import { ArrowDownIcon, ArrowUpIcon, DownloadIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import workspaceService from 'services/workspace.service';

interface IViewGridProps {
    data: CourseType[];
    handleChangeOrder: () => void;
    order: 'asc' | 'desc' | undefined;
    handleClickCourse: (id: string) => void;
    activeCourse: number;
    workspaceId: string;
}

const ViewGrid: FC<IViewGridProps> = ({
    data,
    handleChangeOrder,
    order,
    handleClickCourse,
    activeCourse,
    workspaceId
}) => {
    const { t: translator } = useTranslation();
    return (
        <>
            <div className="flex items-center text-ooolab_sm border-t border-b bg-gray-50">
                <div className='px-6 py-3 text-left text-sm font-semibold text-ooolab_dark_1'>
                    <p>{translator('COURSES.NAME')}</p>
                </div>
                <div className='px-6 py-3 text-left text-sm font-semibold text-ooolab_dark_1'>
                    <p
                        onClick={() => handleChangeOrder()}
                        className="inline-flex items-center pl-16 pr-8 cursor-pointer text-primary-500"
                    >
                        <span>{translator('COURSES.LAST_MODIFIED')}</span>
                        {(order === 'asc' && (
                            <ArrowUpIcon className="w-4 h-4 ml-ooolab_m_1" />
                        )) || (
                                <ArrowDownIcon className="w-4 h-4 ml-ooolab_m_1" />
                            )}
                    </p>
                </div>
                <div className='px-6 py-3 text-left text-sm font-semibold text-ooolab_dark_1'>
                    <p>{translator('COURSES.AUTHOR')}</p>
                </div>
            </div>
            <div className="h-full w-full overflow-y-scroll custom-scrollbar cursor-pointer filter mt-4 mb-4">
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                    {data.map((i: any) => (
                        <div
                            style={
                                (i?.thumbnail && {
                                    backgroundImage: `url("${i?.thumbnail}")`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                }) ||
                                {}
                            }
                            onClick={() => handleClickCourse(i?.id)}
                            key={`course-grid-${i.id}`}
                            className={`${activeCourse === i.id ? 'drop-shadow-lg' : ''
                                }col-span-1 bg-ooolab_dark_50 max-h-ooolab_h_45 h-40 relative rounded-xl overflow-hidden filter drop-shadow group hover:drop-shadow-lg`}
                        >
                            {/* <DownloadIcon onClick={() => workspaceService.downloadCousrse(workspaceId, i.id)} className='top-2 right-2 absolute h-5 w-5 text-ooolab_dark_2 cursor-pointer hover:text-ooolab_dark_1' /> */}
                            {/* <img
                                src={i.thumbnail || CourseBG}
                                className="w-full h-full z-0 bg-ooolab_dark_50"
                                alt=""
                            /> */}
                            <div className="bg-img-course bg-no-repeat bg-cover absolute w-full bottom-0 left-0 z-20">
                                <div className="w-full h-full px-3 py-2 bg-opacity-10 group-hover:bg-opacity-5 group-hover:bg-blue-400">
                                    <p className="mb-2 text-xs text-ooolab_dark_2">
                                        {getTimeFromNow(i.updated_at)}
                                    </p>
                                    <div className="flex justify-between items-center">
                                        <p className="w-1/2 overflow-hidden overflow-ellipsis whitespace-nowrap text-ooolab_dark_1 group-hover:text-primary-500 font-medium text-sm">
                                            {i.title}
                                        </p>
                                        <img
                                            src={i.created_by.avatar_url}
                                            className="w-6 h-6 rounded-full"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default ViewGrid;
