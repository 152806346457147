import React from "react";

export const ListViewIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.16602 3.33341C3.70578 3.33341 3.33268 3.70651 3.33268 4.16675V15.8334C3.33268 16.2937 3.70578 16.6667 4.16602 16.6667H15.8327C16.2929 16.6667 16.666 16.2937 16.666 15.8334V4.16675C16.666 3.70651 16.2929 3.33341 15.8327 3.33341H4.16602ZM1.66602 4.16675C1.66602 2.78604 2.7853 1.66675 4.16602 1.66675H15.8327C17.2134 1.66675 18.3327 2.78604 18.3327 4.16675V15.8334C18.3327 17.2141 17.2134 18.3334 15.8327 18.3334H4.16602C2.7853 18.3334 1.66602 17.2141 1.66602 15.8334V4.16675Z" fill="currentColor" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66602 7.50008C1.66602 7.03984 2.03911 6.66675 2.49935 6.66675H17.4993C17.9596 6.66675 18.3327 7.03984 18.3327 7.50008C18.3327 7.96032 17.9596 8.33341 17.4993 8.33341H2.49935C2.03911 8.33341 1.66602 7.96032 1.66602 7.50008Z" fill="currentColor" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66602 12.5001C1.66602 12.0398 2.03911 11.6667 2.49935 11.6667H17.4993C17.9596 11.6667 18.3327 12.0398 18.3327 12.5001C18.3327 12.9603 17.9596 13.3334 17.4993 13.3334H2.49935C2.03911 13.3334 1.66602 12.9603 1.66602 12.5001Z" fill="currentColor" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.49935 6.66675C7.95959 6.66675 8.33268 7.03984 8.33268 7.50008V17.5001C8.33268 17.9603 7.95959 18.3334 7.49935 18.3334C7.03911 18.3334 6.66602 17.9603 6.66602 17.5001V7.50008C6.66602 7.03984 7.03911 6.66675 7.49935 6.66675Z" fill="currentColor" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4993 6.66675C12.9596 6.66675 13.3327 7.03984 13.3327 7.50008V17.5001C13.3327 17.9603 12.9596 18.3334 12.4993 18.3334C12.0391 18.3334 11.666 17.9603 11.666 17.5001V7.50008C11.666 7.03984 12.0391 6.66675 12.4993 6.66675Z" fill="currentColor" />
        </svg>
    )
}