import { Fragment, useState, useEffect, useRef, FC } from 'react';
import { useParams } from 'react-router';
import { Transition, Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

import workspaceService from 'services/workspace.service';
import {
    LessonInterface
} from 'types/GetListOfWorkspace.type';
import { useTranslation } from 'react-i18next';
import SessionForm from './SessionForm';
import AssessmentForm from './AssessmentForm';
import EventForm from './EventForm';

type SessionDetailsModalProps = {
    open: boolean;
    onCloseModal: () => void;
    onSubmit: (e) => void;
    selectedLessonParams?: { lesson_uid?: string }[];
};

type RadioLessonInterfae = LessonInterface & {
    check?: boolean;
};


const CreateCalendarModal: FC<SessionDetailsModalProps> = ({
    open,
    onCloseModal,
    onSubmit,
    selectedLessonParams,
}) => {
    const params: { id: string } = useParams();
    const cancelButtonRef = useRef(null);
    const lessonRef = useRef<any>();
    const [loading, setLoading] = useState(false);
    const { t: translator } = useTranslation();

    useEffect(() => {
        if (open) {
            setLoading(true);
            workspaceService
                .getLessonList(params.id)
                .then((res) => {
                    if (res) {
                        const flatSelected = selectedLessonParams.map(
                            (i) => i.lesson_uid
                        );
                        const result: RadioLessonInterfae[] =
                            (res.items?.length &&
                                res.items.map((i) => ({
                                    ...i,
                                    check: flatSelected.includes(i.uid),
                                }))) ||
                            [];

                    }
                })
                .finally(() => setTimeout(() => setLoading(false), 1000));
        }
    }, [open]);

    
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-70 inset-0 overflow-y-hidden"
                initialFocus={cancelButtonRef}
                open={open}
                onClose={onCloseModal}
            >
                <div className="flex i justify-center min-h-screen p-4 pb-20 text-center items-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 dialog-background  bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <div className="max-w-[428px] inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle w-2/5 relative">
                            <div>
                                <div className="pl-3 pr-1 pt-3">
                                    <div className='flex w-full items-center'>
                                        <div className='text-xs font-semibold text-ooolab_dark_1'>
                                            Create New
                                        </div>
                                        <div className='w-8 h-8 ml-auto text-ooolab_text_bar_inactive hover:bg-ooolab_light_blue_0 hover:text-primary-500 rounded-full transition p-2'  onClick={onCloseModal}>
                                            <XIcon fill="none"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="overflow-x-auto mt-6">
                                    <div className='flex px-3'>
                                        <div className='w-1/3 text-center text-xs text-ooolab_dark_1 font-semibold bg-ooolab_light_100 py-1'>
                                            Session
                                        </div>
                                        <div className='w-1/3 text-center text-xs text-ooolab_dark_1 font-semibold  py-1'>
                                            Assessment
                                        </div>
                                        <div className='w-1/3 text-center text-xs text-ooolab_dark_1 font-semibold py-1'>
                                            Event
                                        </div>
                                    </div>
                                    <div className="px-10 mt-6 pb-4 align-middle inline-block min-w-full text-ooolab_dark_1">
                                       <EventForm data=''/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default CreateCalendarModal;