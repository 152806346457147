import React from "react";
import { ClassAssignmentsList } from "./components/ClassAssignments/ClassAssignmentsList";
import { ClassAssignmentsOverview } from "./components/ClassAssignments/ClassAssignmentsOverview";
import { ClassAssignmentsTimeSpent } from "./components/ClassAssignments/ClassAssignmentsTimeSpent";

export const ClassDashboardAssignments = () => {
    return<>
        <ClassAssignmentsOverview />
        <div className="mt-4">
            <ClassAssignmentsTimeSpent />
        </div>
        <div className="mt-4">
            <ClassAssignmentsList />
        </div>
    </>
}