import WorkspaceDetailProvider from 'contexts/Workspace/WorkspaceDetailProvider';
import * as React from 'react';
// PACKAGE
import { QueryClient, QueryClientProvider } from 'react-query';
// COMPONENT
import AppContainer from './AppContainer';
// CONTEXT
import AuthProvider from './contexts/Auth/AuthProvider';
import PasswordProvider from './contexts/Password/PasswordProvider';
import UserProvider from './contexts/User/UserProvider';
// CONST
// UTILS
import { isUserLoggedIn } from 'utils/handleAuthorized';
import RegisterProvider from 'contexts/Auth/RegisterProvider';
import ReportProvider from 'contexts/Report/ReportProvider';
import ResourcesProvider from 'contexts/Resources/ResourcesProvider';
import ErrorBoundary from 'components/ErrorBoundary';
import RewardsProvider from 'contexts/Rewards/RewardsProvider';
import { ToastContainer } from 'react-toastify';
import FaviconChanger from 'components/FaviconChanger';
import { ClarityMicrosoft } from 'components/DynamicHead/ClarityMicrosoft';
declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        webkitdirectory?: string;
        mozdirectory?: string;
        directory?: string;
    }
}

const queryClient = new QueryClient();

const App: React.FC = () => {
    const [isAuthStorage, setAuthStorage] = React.useState<boolean>(false);

    React.useMemo(() => {
        setAuthStorage(isUserLoggedIn());
    }, []);

    React.useEffect(() => {
        if(process.env.REACT_APP_S3_PATH === "production" || process.env.REACT_APP_S3_PATH === "staging") {
            console.log = function () {};
            console.info = function () {};
            console.warn = function () {};
            console.error = function () {};
        }
    }, []);

    return (
        <>
            <ErrorBoundary>
                <QueryClientProvider client={queryClient}>
                    <AuthProvider>
                        <RegisterProvider>
                            <PasswordProvider>
                                <UserProvider>
                                    <WorkspaceDetailProvider>
                                        <ReportProvider>
                                            <ResourcesProvider>
                                                <RewardsProvider>
                                                    <AppContainer
                                                        isAuthStorage={
                                                            isAuthStorage
                                                        }
                                                        setAuthStorage={
                                                            setAuthStorage
                                                        }
                                                    />
                                                    <ToastContainer />
                                                    <FaviconChanger />
                                                    <ClarityMicrosoft />
                                                </RewardsProvider>
                                            </ResourcesProvider>
                                        </ReportProvider>
                                    </WorkspaceDetailProvider>
                                </UserProvider>
                            </PasswordProvider>
                        </RegisterProvider>
                    </AuthProvider>
                </QueryClientProvider>
            </ErrorBoundary>
        </>
    );
};

export default App;
