import React, { useState } from 'react'
import { TeachingOverview } from './TeachingOverview/TeachingOverview'
import TeachingHistoryList from './TeachingHistoryList/TeachingHistoryList'
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DatePickerComponent from "components/Reports/components/DatePicker";
import './TeachingHistory.scss';

export default function TeachingHistory({start=null, end=null, hasById=true}) {
  const {t: translator} = useTranslation();
  const [dateRange, setDateRange] = useState([new Date(moment().subtract(1, 'months').format('MM-DD-YYYY')), new Date()]);

  return (
    <div className='TeachingHistory'>
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-gray-800 text-lg font-semibold">{translator('OVERVIEW')}</h2>
       {hasById ? <div className='relative min-w-[242px]'>
          <DatePickerComponent onChange={setDateRange} startDate={dateRange[0]} endDate={dateRange[1]} maxDate={new Date()} />
        </div> : null}
      </div>
      <TeachingOverview start={start} end={end} hasById={hasById} dateRange={dateRange} />
      <TeachingHistoryList start={start} end={end} hasById={hasById} dateRange={dateRange} />
    </div>
  )
}
