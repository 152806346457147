export default function NewFolderIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_760_18608)">
        <path d="M10.345 4.16667H17.5C17.721 4.16667 17.933 4.25446 18.0893 4.41074C18.2456 4.56702 18.3334 4.77899 18.3334 5V16.6667C18.3334 16.8877 18.2456 17.0996 18.0893 17.2559C17.933 17.4122 17.721 17.5 17.5 17.5H2.50002C2.27901 17.5 2.06704 17.4122 1.91076 17.2559C1.75448 17.0996 1.66669 16.8877 1.66669 16.6667V3.33333C1.66669 3.11232 1.75448 2.90036 1.91076 2.74408C2.06704 2.5878 2.27901 2.5 2.50002 2.5H8.67835L10.345 4.16667ZM9.16669 10H6.66669V11.6667H9.16669V14.1667H10.8334V11.6667H13.3334V10H10.8334V7.5H9.16669V10Z" fill="#3B82F6" />
      </g>
      <defs>
        <clipPath id="clip0_760_18608">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
