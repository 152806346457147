import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import SelectBox from '_shared/components/Select';
import { FolderIcon, InformationCircleIcon } from 'assets/icon';
import DialogComponent from 'components/Dialog/DialogComponent';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';

export default function ModalMoveFile({ isOpen, onClose, data, refreshData, isLastFile }) {
    const params: { id } = useParams();
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const {t: translator} = useTranslation();
    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
        watch,
    } = useForm({ mode: 'onChange' });

    const { result: workspaceDetailInformation } = getWorkspaceDetailState;

    const [listFolders, setListFolders] = useState([]);
    const [pagination, setPagination] = useState<any>({ page: 1, per_page: 10, total: 0 });
    const [loading, setLoading] = useState(false);

    const getListFolders = (page: number) => {
        setLoading(true);
        const payload = {
            page: page || pagination?.page,
            custom_field_id: workspaceDetailInformation?.custom_field_ws_resource_folder?.id,
        };
        workspaceService
            .getCustomFieldsByNameMapDataService(params?.id, payload)
            .then((res) => {
                if (page === 1) setListFolders(res?.items);
                else setListFolders([...listFolders, ...res?.items]);
                setPagination({ ...pagination, page: res?.page, total: res?.total });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (workspaceDetailInformation?.custom_field_ws_resource_folder) {
            getListFolders(1);
        }
    }, [workspaceDetailInformation]);

    const onSubmit = (value) => {
        const payload = {
            custom_fields: [
                {
                    ...workspaceDetailInformation?.custom_field_ws_resource_folder,
                    custom_field_id: workspaceDetailInformation?.custom_field_ws_resource_folder?.id,
                    data: [value?.folders?.[0]?.name],
                },
            ],
            id: data?.object_id,
            type: 'files',
        };
        if (data?.object_type === 'h5p') {
          delete payload.type;
          delete payload.id;
          workspaceService.upsertCustomfiledH5p(params.id, data?.object_id, payload)
          .then((res) => {
            Toastify.success();
            onClose();
            refreshData();
          })
          .catch(() => Toastify.error())
        }
        else {
          workspaceService.updateCustomFieldByTypeService(params.id, payload)
          .then((res) => {
            Toastify.success();
            onClose();
            refreshData();
          })
          .catch(() => Toastify.error())
        }
    };

    return (
        <DialogComponent
            title={translator("RESOURCES.MOVE_TO_EXISTING_FOLDER")}
            isOpen={isOpen}
            onCloseModal={onClose}
            styles='max-w-lg'
            child={
                <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                    <SelectBox
                        placeholder={translator('SELECT_FOLDER')}
                        data={listFolders}
                        value={watch('folders') || []}
                        loading={loading}
                        getData={getListFolders}
                        pagination={pagination}
                        control={control}
                        name="folders"
                        errors={errors}
                        isRequired={true}
                        isMulti={false}
                        numberOfItemDisplayed={1}
                        formatOptionLabel={(item) => (
                            <div className='flex gap-3 items-center w-full'>
                                <div className='text-amber-400'>
                                    <FolderIcon width={24} height={24}/>
                                </div> 
                                <div className='text-ellipsis text-sm truncate w-[calc(100%-30px)]'>{item?.name} </div>
                            </div>
                        )}
                        formatOptionValue={(item) => (
                            <div className='text-ellipsis text-sm truncate max-w-[400px]'>{item?.name} </div>                            
                        )}
                    />
                    {isLastFile && (
                        <div className='text-xs mt-2 text-primary-500 rounded-md border border-blue-300 bg-blue-50 p-2 flex gap-2'> 
                            <div className='w-5'><InformationCircleIcon /> </div>
                            <div>
                                {translator('RESOURCES.MESSAGE_LAST_FILE')}
                            </div>
                        </div>
                    )}
                    <div className="flex justify-center gap-4 mt-4">
                      <ButtonOutline type="Button" onClick={onClose}>
                          {translator('CANCEL')}
                      </ButtonOutline>
                      <ButtonPrimary type="submit" onClick={handleSubmit(onSubmit)} disabled={!isValid}>
                          {translator('Move')}
                      </ButtonPrimary>
                  </div>
                </form>
            }
        />
    );
}
