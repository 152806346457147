
import React from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon, EyeIcon } from "@heroicons/react/outline";
import "./Tasks.scss";
import { LIBRARY_ICONS } from "components/Library/LibraryContants";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { TASKS_STATUS } from "components/GradeSubmission/AssignmentGrade/AssignmentGrade";

export const H5PTask = ({task, addFeedback, tabStatus}) => {
    const { t: translator } = useTranslation();
    return <div className="tasks">
        <Disclosure>
            {({ open }) => (
                <>
                    <Disclosure.Button className="tasks-header">
                        <div className="tasks-header-name">
                            {tabStatus !== 'assigned' ? <ChevronUpIcon
                                className={`${open ? 'rotate-180 transform' : ''} h-5 w-5`}
                            /> : null}
                            <span className="tasks-icon">
                                <img
                                    alt="libary-icon"
                                    className="assigmentBuilderPreview-icon"
                                    src={task?.object_type ?
                                        LIBRARY_ICONS(translator)?.find((item) => item?.value === task?.object_type)?.icon :
                                        LIBRARY_ICONS(translator)[0].icon
                                    }
                                />
                            </span>
                            <h3 className="tasks-name">{task.name}</h3>
                            <span className={`tasks-status ${tabStatus}`}>
                                {TASKS_STATUS[tabStatus]}
                            </span>
                            <span className="tasks-grade">Graded: <span>{task?.grade ? `${task?.grade}%` : '-'}</span></span>
                        </div>
                        {tabStatus !== 'assigned' ? <div className="tasks-info">
                            <div className="tasks-info-item">
                                <div className="tasks-info-label">Highest Grade:</div>
                                <div className="tasks-info-value">{task?.score ?? '-'}/{task?.max_score ?? '-'}</div>
                            </div>
                            <div className="tasks-info-line"></div>
                            <div className="tasks-info-item">
                                <div className="tasks-info-label">Completion Date:</div>
                                <div className="tasks-info-value">{task?.attempts?.length > 0 ? dayjs(task?.attempts[0]?.finished).format('DD/MM/YYYY') : '-'}</div>
                            </div>
                            <div className="tasks-info-line"></div>
                            <div className="tasks-info-item">
                                <div className="tasks-info-label">Time Spent:</div>
                                <div className="tasks-info-value">{(task?.attempts?.reduce((total, item) => total + (item?.time_spent || 0), 0) ?? 0)}s</div>
                            </div>
                        </div> : null}
                    </Disclosure.Button>
                    {tabStatus !== 'assigned' ? <Disclosure.Panel className="tasks-container">
                        {task?.attempts?.length > 0 ? <table>
                            <thead>
                                <tr>
                                    <th>Attempts</th>
                                    <th>Grade</th>
                                    <th>Time Spent</th>
                                    <th>Completion Date & Time</th>
                                    <th className="!text-right !pr-2">Feedback</th>
                                </tr>
                            </thead>
                            <tbody>
                                {task?.attempts?.map((attempt, index) => <tr>
                                    <td width={70}>{index + 1}</td>
                                    <td width={80}>{attempt?.score ?? '-'}/{attempt?.max_score ?? '-'}</td>
                                    <td width={88}>{attempt?.time_spent}s</td>
                                    <td>{attempt?.finished ? dayjs(attempt?.finished).format('DD/MM/YYYY HH:mm') : '-'}</td>
                                    <td width="191">
                                        <div className="tasks-action justify-end">
                                            <button className="tasks-link"
                                                onClick={() => {
                                                    addFeedback({
                                                        task,
                                                        attempt
                                                    });
                                                }}
                                            >{attempt?.instructor_feedback ? 'View' : 'Add'} Feedback</button>
                                            <button className="tasks-btn !hidden"><EyeIcon className="w-4" /> <span>View</span></button>
                                        </div>
                                    </td>
                                </tr>)}
                            </tbody>
                        </table> : <p className="text-gray-500 py-4 text-sm">No Attempts</p>}
                    </Disclosure.Panel> : null}
                </>
            )}
        </Disclosure>
    </div>
}