import dayJs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Toast } from './sweetAlert';
import { FORM_CONST } from 'constant/form.const';
import userService from 'services/user.service';

dayJs.extend(utc);
dayJs.extend(CustomParseFormat);
dayJs.extend(timezone);

export const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ');

export const log = console.log.bind(document);

export const UUID = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
};

export const randomNumber = (toNum: number) => Math.floor(Math.random() * toNum); // random 0 => toNum

// input format '2021-04-26T00:00:00Z'
export const getPeriodTime = (start: string, end: string, tz: string = 'America/New_York') => {
    return dayJs(start).tz(tz).format('HH:mm') + ' ~ ' + dayJs(end).tz(tz).format('HH:mm'); // exp: 12:30 ~ 14:30
};

// input format '2021-04-26T00:00:00Z'
export const getFullTime = (date_time: string, tz: string = 'America/New_York') => {
    return date_time ? dayJs(date_time).tz(tz).format('HH:mm:ss') : '00:00:00'; // exp: 12:30:00
};

// input format '2021-04-26T00:00:00Z'
export const getFullDate = (date_time: any = undefined) => {
    return dayJs(date_time).format('YYYY-MM-DD'); // exp: 2022-10-09
};

// input format '2021-04-26T00:00:00Z'
export const getFullDateDisplay = (date_time: any = undefined, tz: string = 'America/New_York') => {
    return dayJs(date_time).tz(tz).format('DD/MM/YYYY'); // exp: 13/01/2022
};

export const getDateISO8601 = (date: any) => {
    return date ? dayJs(date).toISOString() : dayJs().toISOString(); // exp: 2022-04-27T04:07:11.305Z
};

export const capitalize = (s: string) => {
    if (typeof s !== 'string' || !s) return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

// fields[] is property useFieldArray in react-hook-form
export const handleError = (err: any, setError?: Function, fields?: any) => {
    const typeName = err?.response?.data?.error?.name;
    const errMes: string = err?.response?.data?.error?.description;
    if (typeName === 'NOTHING_CHANGED') return;
    const errs: any = err?.response?.data?.error?.body_params?.map((i: any) => ({
        idx: i.loc[1],
        name: i.loc[2],
        message: i.msg,
    }));
    const arrayErrors: any = fields.map((i: any, index: number) => {
        const obj = errs?.find((el: any) => index === el.idx);
        if (obj)
            return {
                [obj.name]: {
                    type: 'required',
                    message: capitalize(obj.message),
                },
            };
        return { [index]: null };
    });
    if (errMes) {
        Toast.fire({
            icon: 'error',
            title: errMes,
        });
    } else if (arrayErrors) {
        setError('student_attendances', arrayErrors);
    } else {
        Toast.fire({
            icon: 'error',
            title: 'An error has occurred',
        });
    }
};

export const handleSuccess = (res?: any) => {
    Toast.fire({
        icon: 'success',
        title: res ? res : 'Your work has been saved',
    });
};
export const handleFail = (res?: any, icon?: any) => {
    Toast.fire({
        icon: icon ? icon : 'error',
        title: res ? res : 'Your work has been failed',
    });
};

export const IsValidUrl = (urlString) => {
    const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' +
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
            '((\\d{1,3}\\.){3}\\d{1,3}))' +
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
            '(\\?[;&a-z\\d%_.~+=-]*)?' +
            '(\\#[-a-z\\d_]*)?$',
        'i'
    );
    return !!urlPattern.test(urlString) && (urlString.indexOf('http://') !== -1 || urlString.indexOf('https://') !== -1);
};

export const isEmail = (email: string) => FORM_CONST.EMAIL_REGEX.test(email);

export const getIndexPage = (page: number, per_page: number, length: number) => {
    if (page && per_page && length) {
        const from = page * per_page - per_page + 1;
        const to = length + per_page * (page - 1);
        return from + '-' + to;
    } else return '0-0';
};

export const downloadURI = (uri: string, fileName: string) => {
    const link = document.createElement('a');
    link.setAttribute('href', uri);
    link.setAttribute('download', fileName);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const downloadWithAxios = (fileBlob: Blob, file_name: string, response?: any) => {
    const url = window.URL.createObjectURL(fileBlob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    const contentDisposition = response?.headers['content-disposition'];
    let fileName = file_name;
    if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
    }
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};

const preventEvent = (e) => {
    //document.onkeydown = function(e) {
    // "I" key
    if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
        disabledEvent(e);
    }
    // "J" key
    if (e.ctrlKey && e.shiftKey && e.keyCode == 74) {
        disabledEvent(e);
    }
    // "S" key + macOS
    if (e.keyCode == 83 && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
        disabledEvent(e);
    }
    // "U" key
    if (e.ctrlKey && e.keyCode == 85) {
        disabledEvent(e);
    }
    // "F12" key
    if (e.keyCode == 123) {
        disabledEvent(e);
    }
    // "C" key
    if (e.ctrlKey && e.keyCode == 67) {
        disabledEvent(e);
    }
    function disabledEvent(e) {
        if (e.stopPropagation) {
            e.stopPropagation();
        } else if (window.event) {
            window.event.cancelBubble = true;
        }
        e.preventDefault();
        return false;
    }
};
const preventRightClick = (e) => {
    e.preventDefault();
};

export const disabledCopyPast = () => {
    /**
     * Disable right-click of mouse, F12 key, and save key combinations on page
     */
    if (process.env.NODE_ENV !== 'development') {
        document.addEventListener(
            'contextmenu',
            function (e) {
                preventRightClick(e);
            },
            false
        );
        document.addEventListener(
            'keydown',
            function (e) {
                preventEvent(e);
            },
            false
        );
    }
};

export const destroyDisabledCopyPast = () => {
    if (process.env.NODE_ENV !== 'development') {
        document.removeEventListener(
            'contextmenu',
            function (e) {
                preventRightClick(e);
            },
            false
        );
        document.removeEventListener(
            'keydown',
            function (e) {
                preventEvent(e);
            },
            false
        );
    }
};

export const activityWatcher = (callback) => {
    //The number of seconds that have passed
    //since the user was active.
    var secondsSinceLastActivity = Date.now();
    // var timestamp = +new Date();

    //Ten minutes. 60 x 10 = 600 seconds.
    var maxInactivity = 60 * 30;

    //Setup the setInterval method to run
    //every second. 1000 milliseconds = 1 second.
    const interTimer = setInterval(function () {
        // secondsSinceLastActivity++;
        // timestamp = +new Date();
        //if the user has been inactive or idle for longer
        //then the seconds specified in maxInactivity
        //console.log(Date.now() - secondsSinceLastActivity);
        if (Date.now() - secondsSinceLastActivity > maxInactivity * 1000) {
            //Redirect them to your previous page.
            secondsSinceLastActivity = 0;
            // timestamp = null;
            clearInterval(interTimer);
            activityEvents.forEach(function (eventName) {
                document.removeEventListener(eventName, activity, true);
            });
            callback();
        }
    }, 1000);

    //The function that will be called whenever a user is active
    function activity() {
        //reset the secondsSinceLastActivity variable
        //back to 0
        secondsSinceLastActivity = Date.now();
        // timestamp = null;
        //clearInterval(interTimer);
    }

    //An array of DOM events that should be interpreted as
    //user activity.
    var activityEvents = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];

    //add these events to the document.
    //register the activity function as the listener parameter.
    activityEvents.forEach(function (eventName) {
        document.addEventListener(eventName, activity, true);
    });
};

export const convertTime = (input, timezone) => {
    var d = new Date(input); /* midnight in China on April 13th */

    const result = d.toLocaleString('en-US', { timeZone: timezone });
    return result;
};

export const copyClipboard = ({ id, text }: { id?: string; text?: string }) => {
    if (!id && !text) return;
    const copyText: any = (!!id && document.getElementById(id).innerHTML) || text;
    navigator.clipboard.writeText(copyText || '');
};

export const getLocalTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const timeConvert = (time) => {
    const num = time;
    const hours = num / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    if (rhours > 0) {
        return rhours + 'h ' + rminutes + 'm';
    }
    return rminutes + 'm';
};

// input: hh:mm -> second (number | null)
export const convertHourMinsToSecond = (time: string) => {
    if (!time || !time.includes(':')) return null;
    const timeSplit = time.split(':');
    const hour = Number(timeSplit[0]);
    const mins = Number(timeSplit[1]);
    let second = 0;
    if (hour > 0) second += hour * 3600;
    if (mins > 0) second += mins * 60;

    return second || null;
}

// input: second (number | null) -> hh:mm | empty string
export const convertSecondToHourMins = (second: number | null) => {
    if (!second) return '';

    const minutes = Math.floor(second / 60);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    return `${hours}:${remainingMinutes.toString().padStart(2, '0')}`;
}

export const getDateWithoutTimezone = (date) => {
    const dateConvert = date?.split('T');
    return dateConvert?.length > 0 ? new Date(dateConvert[0]) : new Date();
};

export const openUrl = (url: string) => {
    if (url.startsWith('http')) {
        return window.open(url, '_blank');
    }
    return window.open(`https://${url}`, '_blank');
};

export const flatArrayWithConditionName = (array, condition) => {
    const newArray: any[] = array.reduce((result, subArray) => {
        const arrayWithCondition = subArray.filter((item) => item?.name?.toLowerCase().includes(condition?.toLowerCase()));
        return result.concat(arrayWithCondition);
    }, []);
    return newArray;
};

export const formatThousand = (value: any): string => {
    if (typeof value == 'number') {
        return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return value;
};

export const dowloadFile = (url: string, filename, callback?: any) => {
    if (!filename) filename = url.split('\\').pop().split('/').pop();
    fetch(url, {
        headers: new Headers({
            Origin: location.origin,
        }),
        mode: 'cors',
    })
        .then((response) => response.blob())
        .then((blob) => {
            let blobUrl = window.URL.createObjectURL(blob);
            forceDownload(blobUrl, filename, callback);
        })
        .catch((e) => {
            if (callback) {
                callback();
            }
        });
};
export const onGetBlobFile = (url: string) => {
    return fetch(url, {
        headers: new Headers({
            Origin: location.origin,
        }),
        mode: 'cors',
    })
        .then((response) => response.blob())
        .then((bolb) => bolb);
};

function forceDownload(blob, filename, callback?: any) {
    var a = document.createElement('a');
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
    if (callback) {
        callback();
    }
}

export const format1Decimal = (data: number) => {
    let value = data.toFixed(1).split('.');
    if (Number(value[1]) > 0) {
        return data.toFixed(1);
    }
    return value[0];
};

export const openFullScreen = () => {
    const elem = document.getElementById('presentation-mode');
    if (elem) {
        elem.requestFullscreen();
        //@ts-ignore
    } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        //@ts-ignore
        elem.webkitRequestFullscreen();
        //@ts-ignore
    } else if (elem.msRequestFullscreen) {
        /* IE11 */
        //@ts-ignore
        elem.msRequestFullscreen();
    }
};

/* Close fullscreen */
export const closeFullscreen = () => {
    // const elem = document.getElementById("presentation-mode")
    if (document.exitFullscreen) {
        document.exitFullscreen();
        //@ts-ignore
    } else if (document.webkitExitFullscreen) {
        /* Safari */
        //@ts-ignore
        document.webkitExitFullscreen();
        //@ts-ignore
    } else if (document.msExitFullscreen) {
        /* IE11 */
        //@ts-ignore
        document.msExitFullscreen();
    }
};

type Params = {
    [key: string]: string | number | boolean | string[];
};

export const parseParams = (params: Params) => {
    let options = '';

    for (const [key, value] of Object.entries(params)) {
        if (Array.isArray(value)) {
            for (const element of value) {
                // options += `${key}=${element}&`;
                options += `${encodeURIComponent(key)}=${encodeURIComponent(element)}&`;
            }
        } else {
            // options += `${key}=${value}&`;
            if (key === 'q') {
                options += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
            } else {
                options += `${encodeURIComponent(key)}=${value}&`;
            }
        }
    }

    return options.slice(0, -1);
};

export const exitFullScreen = (callback?: () => void) => {
    if (document.addEventListener) {
        document.addEventListener('fullscreenchange', exitHandler, false);
        document.addEventListener('mozfullscreenchange', exitHandler, false);
        document.addEventListener('MSFullscreenChange', exitHandler, false);
        document.addEventListener('webkitfullscreenchange', exitHandler, false);
    }
    function exitHandler() {
        //@ts-ignore
        if (document.webkitIsFullScreen === false) {
            callback();
        }
        //@ts-ignore
        else if (document.mozFullScreen === false) {
            callback();
        }
        //@ts-ignore
        else if (document.msFullscreenElement === false) {
            callback();
        }
    }
};

export const trapSpacesForRequiredFields = (value) => !!value.trim();

export const createOption = (option) => ({
    ...option,
    label: option.name,
    value: option.id,
});

export const getAvatarUserByName = (name: string) => {
    if (!name) {
        return;
    }
    const nameArray = name?.split(' ');
    if (nameArray?.length > 1) {
        return `${nameArray[0].substring(0, 1)}${nameArray[1].substring(0, 1)}`;
    }
    return nameArray[0].substring(0, 1);
};

export const getValueOrSign = (value) => {
    if (value === null || typeof value === undefined) {
        return '-';
    }
    if (Number(value) % 1 === 0) {
        return `${value}%`;
    }
    return `${value.toFixed(1)}%`;
};

export class UploadAdapter {
    loader: any;
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        const hostCDN = process.env.REACT_APP_HOST_CDN;
        return this.loader.file.then(
            (file) =>
                new Promise(async (resolve, reject) => {
                    let myReader: FileReader = new FileReader();
                    await userService.uploadImage(
                        file,
                        (resUpload) => {
                            myReader.onloadend = () => {
                                resolve({ default: hostCDN + resUpload });
                            };

                            myReader.readAsDataURL(file);
                        },
                        (err) => {
                            reject(err);
                        }
                    );
                })
        );
    }
}

export const convertFiletoBlobAndDownload = async (file, name) => {
    const blob = await fetch(file).then((r) => r.blob());
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = name; // add custom extension here
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    // Remove "a" tag from the body
    a.remove();
};
export function utf8_to_b64(str: string): string {
    return encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode(parseInt(p1, 16));
    });
}

export const checkPermission = (to, permission, role?: any) => {
    let isAllowTo = false;
    let userRoleLocalStorage: any = localStorage.getItem('userRole');
    userRoleLocalStorage = JSON.parse(userRoleLocalStorage);
    const roleUser = userRoleLocalStorage?.permissions;

    if (roleUser) {
        isAllowTo = userRoleLocalStorage?.permissions[to]?.includes(permission);
    }
    return isAllowTo;
};

export async function objectToBase64(obj) {
    try {
        const jsonString = JSON.stringify(obj);
        const encoder = new TextEncoder();
        const utf8Bytes = encoder.encode(jsonString);
        const base64String = btoa(String.fromCharCode.apply(null, utf8Bytes));

        return encodeURIComponent(base64String);
    } catch (error) {
        console.error('Error while encoding object to Base64:', error);
        return null;
    }
}

function base64ArrayBuffer(arrayBuffer) {
    return new Promise((resolve, reject) => {
        const reader: any = new FileReader();
        reader.onloadend = function () {
            resolve(reader.result.split(',')[1]);
        };
        reader.onerror = reject;
        reader.readAsDataURL(new Blob([arrayBuffer]));
    });
}

export async function base64ToObject(base64String) {
    try {
        const binaryString = atob(base64String);
        const utf8Bytes = Uint8Array.from(binaryString, (c) => c.charCodeAt(0));
        const decoder = new TextDecoder();
        const jsonString = decoder.decode(utf8Bytes);
        return JSON.parse(decodeURIComponent(jsonString));
    } catch (error) {
        console.error('Error while decoding Base64 to object:', error);
        return null;
    }
}

function base64ToString(base64String) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = function () {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsText(base64ToBlob(base64String));
    });
}

function base64ToBlob(base64String) {
    const byteString = atob(base64String);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([uint8Array]);
}

export const getFileExtensionFromUrl = (url: string): string | null => {
    if (!url) return;
    const filename = url.substring(url.lastIndexOf('/') + 1);
    const parts = filename.split('.');
    if (parts.length > 1) {
        return parts[parts.length - 1];
    } else {
        return null;
    }
};

export const timeSince = (date: Date) => {
    const today = +new Date();
    const seconds = Math.floor((today - +date) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
        return `${Math.floor(interval)} ${Math.floor(interval) > 1 ? ' years' : ' year'}`;
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return `${Math.floor(interval)} ${Math.floor(interval) > 1 ? ' months' : ' month'}`;
    }
    interval = seconds / 604800;
    if (interval > 1) {
        return `${Math.floor(interval)} ${Math.floor(interval) > 1 ? ' weeks' : ' week'}`;
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return `${Math.floor(interval)} ${Math.floor(interval) > 1 ? ' days' : ' day'}`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return `${Math.floor(interval)} ${Math.floor(interval) > 1 ? ' hours' : ' hour'}`;
    }
    interval = seconds / 60;
    if (interval > 1) {
        return `${Math.floor(interval)} ${Math.floor(interval) > 1 ? ' minutes' : ' minute'}`;
    }
    return `${Math.floor(interval)} ${Math.floor(interval) > 1 ? ' seconds' : ' second'}`;
};

export const isEmptyNullUndefine = (value?: number | string | null | undefined) => {
    if (value === '' || value === null || value === undefined) return true;
    return false;
};
