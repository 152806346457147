import axios from './restful.service';
import { SELFBOOKING } from 'constant/api.const';

const getSessionAvailable = (workspaceId: string, params?: Record<any, any>) => {
    if (workspaceId) {
        const url = SELFBOOKING.getSessionAvailable(workspaceId);
        return axios.get(url, { params });
    }
};

const getAvailableSlotsServive = (workspaceId: string, payload?: Record<any, any>) => {
    if (workspaceId) {
        const url = SELFBOOKING.getAvailableSlots(workspaceId);
        return axios.post(url, payload);
    }
};

const bookingSession = (workspaceId: string, payload?: Record<any, any>) => {
    const url = SELFBOOKING.studentEnroll(workspaceId);
    return axios.post(url, payload);
};

const getCurrentSession = (workspaceId: string | number, params?: Record<any, any>) => {
    const url = SELFBOOKING.getCurrentSessions(workspaceId);
    return axios.get(url, { params });
};

const getSessionNotes = (sessionId: string | number, params?: Record<any, any>) => {
    const url = SELFBOOKING.SessionNote(sessionId);
    return axios.get(url, { params });
};
const getSessionById = (sessionId: string | number) => {
    const url = SELFBOOKING.getSessionById(sessionId);
    return axios.get(url);
};

const addSessionNote = (sessionId: string | number, payload?: Record<any, any>) => {
    const url = SELFBOOKING.SessionNote(sessionId);
    return axios.post(url, payload);
};
const updateSessionNotes = (sessionId: string | number, noteId: number, payload?: Record<any, any>) => {
    const url = SELFBOOKING.updateSessionNote(sessionId, noteId);
    return axios.patch(url, payload);
};

const bookavAvailableSlotService = (workspaceId: string | number, body?: Record<any, any>) => {
    const url = SELFBOOKING.bookavAvailableSlot(workspaceId);
    return axios.post(url, body);
}

const cancelBooking = (workspaceId: string, sessionId, body) => {
    const url = SELFBOOKING.cancelBooking(workspaceId, sessionId);
    return axios.post(url, body);
}

export default {
    getSessionAvailable,
    bookingSession,
    getCurrentSession,
    getSessionNotes,
    getSessionById,
    updateSessionNotes,
    addSessionNote,
    getAvailableSlotsServive,
    bookavAvailableSlotService,
    cancelBooking
};
