export const EyesIcon = ({
    width = '14px',
    height = '10px',
    fill = '#6B7280',
}: {
    width?: string;
    height?: string;
    fill?: string;
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.00001 5.00065C9.00001 6.10522 8.10458 7.00065 7.00001 7.00065C5.89544 7.00065 5.00001 6.10522 5.00001 5.00065C5.00001 3.89608 5.89544 3.00065 7.00001 3.00065C8.10458 3.00065 9.00001 3.89608 9.00001 5.00065Z"
                fill={fill}
            />
            <path
                d="M13.5963 4.70251C12.1575 1.82488 9.60209 0.333984 7.00001 0.333984C4.39793 0.333984 1.84254 1.82488 0.403725 4.70251C0.309883 4.89019 0.309883 5.11111 0.403725 5.29879C1.84254 8.17642 4.39793 9.66732 7.00001 9.66732C9.60209 9.66732 12.1575 8.17642 13.5963 5.29879C13.6901 5.11111 13.6901 4.89019 13.5963 4.70251ZM7.00001 8.33398C5.02082 8.33398 2.99871 7.25346 1.75312 5.00065C2.99871 2.74784 5.02082 1.66732 7.00001 1.66732C8.9792 1.66732 11.0013 2.74784 12.2469 5.00065C11.0013 7.25346 8.9792 8.33398 7.00001 8.33398Z"
                fill={fill}
            />
        </svg>
    );
};
export const EyesOffIcon = ({ className = '' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="16"
        fill="none"
        viewBox="0 0 18 16"
        className={className}
    >
        <path
            fill="#111827"
            d="M2.922.744a.833.833 0 0 0-1.178 1.179l2.02 2.02C2.572 4.839 1.533 6.07.754 7.627a.83.83 0 0 0 0 .746c1.799 3.597 4.993 5.46 8.246 5.46a8.6 8.6 0 0 0 3.775-.879l2.302 2.302a.833.833 0 1 0 1.179-1.179zm8.59 10.946A6.9 6.9 0 0 1 9 12.167c-2.474 0-5.002-1.351-6.559-4.167.682-1.232 1.549-2.184 2.516-2.865l1.786 1.787a2.5 2.5 0 0 0 3.334 3.334zM14.46 9.58A9 9 0 0 0 15.558 8C14.001 5.184 11.474 3.833 9 3.833q-.14 0-.28.006L7.233 2.352A8.5 8.5 0 0 1 9 2.167c3.252 0 6.447 1.863 8.245 5.46a.83.83 0 0 1 0 .746 11 11 0 0 1-1.604 2.387z"
        ></path>
    </svg>
);