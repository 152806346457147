import { ErrorMessage } from '@hookform/error-message';
import { Controller, useForm } from 'react-hook-form';
import ModalUploadImage from './ModalSelectImage';
import { useBoolean } from 'hooks/custom';
import { useTranslation } from 'react-i18next';
import DatePickerInput from 'components/Management/components/Form/DatePicker';
import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import UploadImage from 'assets/icon/UploadImage';
import banner1Service from 'services/banner.service';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import userService from 'services/user.service';
import { customSelectStyle, optionBannerActivation, optionApply, errorMsg } from './constants'
import { get } from 'lodash';
import { InputField } from 'components/InputForm/InputForm';
import { UserContext } from 'contexts/User/UserContext';
import { Toastify } from '_shared';

export default function CreateBanner({ handleCloseModal, updateBannerList, setUpdateBannerList }) {
    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        control,
        reset,
        setError,
        watch,
    } = useForm({ mode: 'onChange' });
    const formWatchEndate = watch('endDate')
    const formWatchStartdate = watch('startDate')
    const params = useParams<{ id: string; classId: string }>();
    const [loading, setLoading] = useState(false)
    const { t: translator } = useTranslation();

    const {
        booleanValue: isUpload,
        toggleBooleanValue: toggleIsUpload,
    } = useBoolean();

    const [tmpAvatar, setTmpAvatar] = useState<{ link: string, file: any }>();

    const {
        userState: { result },
    } = useContext(UserContext);

    useEffect(() => {
        reset(
            {
                created_by: result.display_name
            },
            {
                keepDirty: false,
            }
        );
    }, [])

    const [requireBannerImg, setRequireBannerImg] = useState('')

    const submitForm = async (val) => {
        setLoading(true)
        let thumbnail = ''
        await userService.uploadImage(
            tmpAvatar.file,
            (e: string) => {
                thumbnail = e
            },
            () => { }
        );
        const banner = {
            name: val.bannerName,
            start_date: dayjs(val.startDate).format('YYYY-MM-DD'),
            end_date: dayjs(val.endDate).format('YYYY-MM-DD'),
            deeplink: val.deeplink,
            activation: val.bannerActivation.value,
            views: val.applyfor?.map(item => item.value),
            thumbnail
        }

        banner1Service.createBannerService(params.id, banner)
            .then(res => {
                setUpdateBannerList(!updateBannerList)
                handleCloseModal()
                Toastify.success(translator("Created banner successfully!"))
            })
            .catch(err => {
                const error = get(err, 'response.data.error.body_params[0].msg')
                if (error === 'EXISTING_NAME') {
                    setError('bannerName', { type: 'custom', message: errorMsg[error] });
                }
                Toastify.error(translator("Created banner failed!"))
            })
            .finally(() => setLoading(false))
    }

    const onChangeFile = (file, canvas) => {
        setTmpAvatar({ file, link: canvas.toDataURL() })
        setRequireBannerImg('')
    }

    

    return (
        <form
            onSubmit={(e) => handleSubmit(submitForm)(e)
                // you will have to catch those error and handle them
                .catch((err) => { console.log(err.response) })
                .finally(() => {
                    if (!tmpAvatar) setRequireBannerImg(translator('FORM_CONST.REQUIRED_FIELD'))
                })
            }
        >
            <ModalUploadImage
                isOpen={isUpload}
                title={translator('BANNER.UPLOAD_IMAGE')}
                onClose={() => {
                    toggleIsUpload();
                }}
                onSubmitImage={(file, canvas) => onChangeFile(file, canvas)}
            />
            <label
                className="text-ooolab_dark_1 font-semibold text-xs leading-ooolab_24px flex mb-1"
            >
                {translator('BANNER.BANNER_IMAGE')} <span className='text-red-500 ml-1'> *</span>
            </label>
            <div className=" flex sm:justify-between justify-center w-full h-32">

                <div className="group relative overflow-hidden w-full rounded-2xl bg-gray-200 z-1 group flex justify-center items-center hover:opacity-70">
                    {
                        tmpAvatar && (
                            <img
                                className="w-auto h-auto z-10 absolute"
                                src={tmpAvatar?.link}
                                alt="group-avatar"
                            />
                        )

                    }
                    <button
                        type="button"
                        onClick={toggleIsUpload}
                        className="z-1 w-full h-[6.25rem] absolute flex justify-center items-center group-hover:z-20 "
                    >
                        <UploadImage />
                    </button>
                </div>
            </div>
            <div className='mt-1 text-xs text-gray-500'>
                {translator('BANNER.RECOMMENDED_BANNER')}
                <br />
                {translator('BANNER.RECOMMENDED_BANNER_CONTENT')}
            </div>
            {requireBannerImg && <div className="text-red-500 text-xs font-semibold">{requireBannerImg}</div>}
            <div className="py-5 grid grid-cols-8 sm:grid-cols-7 gap-x-ooolab_w_8 gap-y-ooolab_w_5">
                <InputField
                    label={translator('BANNER.BANNER_NAME')}
                    name="bannerName"
                    maxLength={255}
                    className="col-span-8"
                    isRequired
                    placeholder={translator('BANNER.PLACEHOLDER_BANNER_NAME')}
                    register={register('bannerName', {
                        required: {
                            value: true,
                            message: translator(
                                'FORM_CONST.REQUIRED_FIELD'
                            ),
                        },
                        maxLength: {
                            value: 100,
                            message: translator('BANNER.VALIDATE_BANNER_NAME')
                        },
                    })}
                    errors={errors}
                />

                <div className=" col-span-4 sm:col-span-4 text-ooolab_xs">
                    <label
                        htmlFor="startDate"
                        className="text-ooolab_dark_1 font-semibold leading-ooolab_24px flex mb-1"
                    >
                        {translator('BANNER.START_DATE')} <span className='text-red-500 ml-1'> *</span>
                    </label>
                    <div className="w-full h-8 relative">
                        <div className='flex justify-between items-center'>
                            <DatePickerInput
                                labelCls='text-ooolab_text_username'
                                control={control}
                                name="startDate"
                                isRequired
                                paddingCls="py-2 px-4 "
                                placeholderText={translator('BANNER.START_DATE')}
                                minDate={new Date()}
                                maxDate={formWatchEndate}
                                {...register('startDate', {
                                    required: {
                                        value: true,
                                        message: translator(
                                            'FORM_CONST.REQUIRED_FIELD'
                                        ),
                                    },
                                })}
                            />
                        </div>
                        <ErrorMessage
                            className="text-red-500 text-xs font-semibold mt-1"
                            errors={errors}
                            name="startDate"
                            as="p"
                        />
                    </div>
                </div>
                <div className=" col-span-4 sm:col-span-4  text-ooolab_xs">
                    <label
                        htmlFor="endDate"
                        className="text-ooolab_dark_1 font-semibold leading-ooolab_24px flex mb-1"
                    >
                        {translator('BANNER.END_DATE')} <span className='text-red-500 ml-1'> *</span>
                    </label>
                    <div className="w-full h-8 relative">
                        <div className='flex justify-between items-center'>
                            <DatePickerInput
                                labelCls='text-ooolab_text_username'
                                isOriginal={true}
                                control={control}
                                name="endDate"
                                isRequired
                                minDate={formWatchStartdate || new Date()}
                                paddingCls="py-2 px-4 "
                                placeholderText={translator('BANNER.END_DATE')}
                                {...register('endDate', {
                                    required: {
                                        value: true,
                                        message: translator(
                                            'FORM_CONST.REQUIRED_FIELD'
                                        ),
                                    },
                                })}
                            />
                        </div>
                        <ErrorMessage
                            className="text-red-500 text-xs font-semibold mt-1"
                            errors={errors}
                            name="endDate"
                            as="p"
                        />
                    </div>
                </div>
                <div className="col-span-8 sm:col-span-8 lg:col-span-8 text-ooolab_xs">
                    <label
                        htmlFor="apllyfor"
                        className="text-ooolab_dark_1 font-semibold leading-ooolab_24px flex mb-1"
                    >
                        {translator('BANNER.APPLY_FOR')} <span className='text-red-500 ml-1'> *</span>
                    </label>
                    <Controller
                        control={control}
                        name="applyfor"
                        {...register('applyfor',
                            {
                                required: {
                                    value: true,
                                    message: translator(
                                        'FORM_CONST.REQUIRED_FIELD'
                                    ),
                                },
                            }
                        )}
                        render={({ field, formState }) => {
                            return (
                                <Select
                                    {...field}
                                    {...formState}
                                    placeholder={translator('BANNER.APPLY_FOR')}
                                    className=" text-ooolab_dark_2 rounded font-normal"
                                    styles={customSelectStyle}
                                    id="applyfor"
                                    options={optionApply}
                                    isMulti
                                    menuPlacement='auto'
                                />
                            );
                        }}
                    />
                    <ErrorMessage
                        className="text-red-500 text-xs font-semibold mt-1"
                        errors={errors}
                        name="applyfor"
                        as="p"
                    />
                </div>
                <InputField
                    label={translator('BANNER.DEEPLINK')}
                    name="deeplink"
                    maxLength={255}
                    className="col-span-8"
                    isRequired
                    placeholder={translator('BANNER.PLACEHODER_DEEPLINK')}
                    register={register('deeplink', {
                        required: {
                            value: true,
                            message: translator(
                                'FORM_CONST.REQUIRED_FIELD'
                            ),
                        },
                        validate: (val) => {
                            const regex = /https?:\/\//g;
                            return regex.test(val) ? true : translator('BANNER.VALIDATE_DEEPLINK');
                        },
                    })}
                    errors={errors}
                />
                <div className="col-span-8 sm:col-span-8 lg:col-span-8 text-ooolab_xs">
                    <label
                        htmlFor="apllyfor"
                        className="text-ooolab_dark_1 font-semibold leading-ooolab_24px flex mb-1"
                    >
                        {translator('BANNER.BANNER_ACTIVATION')} <span className='text-red-500 ml-1'> *</span>
                    </label>
                    <Controller
                        control={control}
                        name="bannerActivation"
                        {...register('bannerActivation',
                            {
                                required: {
                                    value: true,
                                    message: translator(
                                        'FORM_CONST.REQUIRED_FIELD'
                                    ),
                                },
                            }
                        )}
                        render={({ field, formState }) => {
                            return (
                                <Select
                                    {...field}
                                    {...formState}
                                    placeholder={translator('BANNER.PLACEHOLDER_BANNER_ACTIVATION')}
                                    className=" text-ooolab_dark_2 rounded font-normal"
                                    styles={customSelectStyle}
                                    id="bannerActivation"
                                    options={optionBannerActivation(translator)}
                                    menuPlacement='auto'
                                />
                            );
                        }}
                    />
                    <ErrorMessage
                        className="text-red-500 text-sm font-semibold mt-1"
                        errors={errors}
                        name="bannerActivation"
                        as="p"
                    />
                </div>
                <InputField
                    label={translator('BANNER.CREATED_BY')}
                    maxLength={255}
                    className="col-span-8"
                    disabled
                    name="created_by"
                    register={register("created_by")}
                />
            </div>
            <div className='flex w-full'>
                <button type='submit' disabled={loading || !isValid || !tmpAvatar} className={`m-auto bg-enterprise disabled:bg-gray-400 text-white p-2 rounded text-sm font-semibold leading-ooolab_24px`}>
                    {translator('CLASSES.CREATE')}
                </button>
            </div>
        </form>
    )
}
