import React from 'react';
import { NavigationProps } from 'components/MasterPage/LeftNavigation/LeftNavigation';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';
import NotificationDetails from 'components/Management/Notification/NotificationDetails/NotificationDetails';

interface NotificationDetailsPageProps {
    barType?: NavigationProps;
    setAuthStorage: React.Dispatch<React.SetStateAction<boolean>>;
}

const NotificationDetailsPage: React.FC<NotificationDetailsPageProps> = () => {
    return (
        <ManagePermissionWrapper>
            <NotificationDetails />
        </ManagePermissionWrapper>
    );
};

export default NotificationDetailsPage;
