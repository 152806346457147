import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipboardCheckIcon } from '@heroicons/react/outline';
import ActionsButton from 'components/ActionsButton';
import { EditLine } from 'assets/icon';
import DialogComponent from 'components/Dialog/DialogComponent';
import { ChangeDueDate } from './ChangeDueDate/ChangeDueDate';
import courseService from 'services/course.service';
import { useParams } from 'react-router-dom';
import { Toastify } from '_shared';
import { checkPermission } from 'utils/commonFun';

interface ActionMenuProp {
    refreshData?: Function,
    data?: any;
    hideAction?: string,
    status?: string,
}

const ActionMenu: FC<ActionMenuProp> = ({ data, refreshData, status }) => {
    const { t: translator } = useTranslation();
    const [isOpenModalEdit, setOpenModalEdit] = useState(false);
    const params: { id: string, courseId: string } = useParams();
    const [loading, setLoading] = useState(false);

    const handleUpdateDueDate = async (value) => {
        if (value) {
            try {
                setLoading(true);
                const response = await courseService.updateAssignment(params?.id, params.courseId, {
                    id: data.id,
                    end_time: value
                });
                if (response?.data?.error) {
                    setLoading(false);
                    return Toastify.error();
                }
                setOpenModalEdit(false);
                setLoading(false);
                refreshData();
                Toastify.success();
            } catch {
                Toastify.error();
            }
        } else {
            setOpenModalEdit(false);
        }
    }
    return (
        <>
            <ActionsButton
                styleOptions="top-0 right-[35px]"
                menus={[
                    ...([
                        {
                            label: translator("COURSES.GRADE"),
                            icon: <ClipboardCheckIcon className='w-5 h-5' />,
                            action: () => window.open(`/workspace/${params.id}/management/courses/${params.courseId}/assignment/${data.id}/grade`, '_blank'),
                            hide: !checkPermission('courseProgressHistoryAssignmentGradeAssignment', 'U')
                        }]),
                    {
                        label: translator("COURSES.EDIT_DUE_DATE"),
                        icon: <EditLine size={20} />,
                        action: () => setOpenModalEdit(true),
                        hide: !checkPermission('courseProgressHistoryAssignmentEditDueDate', 'U')

                    }
                ]}
            />
            <DialogComponent
                title={translator("COURSES.CHANGE_DUE_DATE")}
                isOpen={isOpenModalEdit}
                onCloseModal={() => setOpenModalEdit(false)}
                isShowClose={true}
                styles="max-w-[400px] !py-1.5 !px-6"
                child={<ChangeDueDate data={data} onClose={handleUpdateDueDate} loading={loading} />}
            />
        </>
    );
};

export default ActionMenu;
