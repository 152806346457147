import React from "react";
import { useTranslation } from "react-i18next";

type SelfBookingReschesuleConfirmProps = {
  onClose: () => void;
};

const SelfBookingReschesuleConfirm: React.FC<SelfBookingReschesuleConfirmProps> = ({
  onClose,
}: SelfBookingReschesuleConfirmProps) => {
  const { t } = useTranslation();
  const translator = (str: string) => t(`SELF_BOOKING.${str}`);

  return (
    <>
      <div className="text-sm text-dark-100 font-normal my-6">
        {translator("WARNING_RESCHEDULE")}
      </div>
      
      <div className="w-full flex justify-center  mt-8">
        <button
          className="text-white text-center text-base bg-primary-500 font-medium rounded-full py-3 w-full"
          type="button"
          onClick={onClose}
        >
          {translator("OKAY")}
        </button>
      </div>
    </>
  );
};

export default SelfBookingReschesuleConfirm;
