export interface ReportUrl {
    url: string;
    resizerJsUrl: string;
}

export interface ReportState {
    general?: ReportUrl;
    instructor?: ReportUrl;
}

export interface ReportAction extends ReportUrl {
    type: string;
}

export const initReportState: ReportState = {};

export interface ReportGeneralClassType {
    avg_attendance: string;
    total_apo: string;
    avg_apo: string;
}

export interface ReportHomeLearningClassType {
    avg_workbook_completion_rate?: string;
    avg_assignment_score?: string;
    avg_assignment_completion_rate?: string;
    avg_assignment_total_time_spent?: string;
}
interface ResponseType {
    items: any;
    total: number;
    page: number;
    per_page: number;
    sort_by: string;
    order: 'desc' | 'asc';
}

export interface ReportUrl {
    url: string;
    resizerJsUrl: string;
}

export interface ReportState {
    general?: ReportUrl;
    instructor?: ReportUrl;
}

export interface ReportAction extends ReportUrl {
    type: string;
}

export type RequestBodyClassReportType = {
    start_time?: string;
    end_time?: string;
    session_type?: '' | 'online' | 'offline';
};

export type RequestBodyClassReportWithPaginationType = RequestBodyClassReportType & {
    page?: number;
    per_page?: number;
    order?: 'asc' | 'desc';
};

export type GeneralReportType = {
    avg_attendance: string;
    total_apo: string;
    avg_apo: string;
};

export type ActiveReviewItemType = {
    id: number;
    name: string;
    start_time: string;
    end_time: string;
    avg_assignment_score: string;
    avg_assignment_completion_rate: string;
    avg_assignment_total_time_spent: string;
    sum_assignment_total_time_spent: string;
};

export type ActiveReviewType = ResponseType & {
    items: ActiveReviewItemType[];
};

export type HomeLearningType = {
    avg_workbook_completion_rate: string;
    avg_assignment_score: string;
    avg_assignment_completion_rate: string;
    avg_assignment_total_time_spent: string;
};

export type OverViewItemType = {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    display_name: string;
    avatar_url: string;
    avg_attendance: string;
    total_apo: string;
    avg_workbook_completion: string;
    avg_assignment_score: string;
    avg_assignment_completion_rate: string;
    avg_assignment_total_time_spent: string;
    grade: string;
    assignment_score_in_percent: string;
    online_score_in_percent: string;
    offline_score_in_percent: string;
    assessment_score_in_percent: string;
    overall: string;
    assessment_listening: string;
    assessment_reading: string;
    assessment_speaking: string;
    assessment_writing: string;
};

export type ClassOverviewItemType = {
    id: number;
    name: string;
    avg_apo: string;
    avg_attendance: string;
    avg_workbook_completion_rate: string;
    avg_assignment_score: string;
    avg_assignment_completion_rate: string;
    avg_assignment_total_time_spent: string;
    avg_grade: string;
    avg_assignment_score_in_percent: string;
    avg_online_score_in_percent: string;
    avg_offline_score_in_percent: string;
    avg_assessment_score_in_percent: string;
    avg_overall: string;
    avg_assessment_listening: string;
    avg_assessment_reading: string;
    avg_assessment_speaking: string;
    avg_assessment_writing: string;
};

export type OverViewItemDefineType = {
    id: number;
    name: string;
    general: {
        avg_class_grade: string;
        summative_assessment?: string;
        attendance: string;
        total_apos: string;
    };
    online_class: {
        total_talk_time: string;
        avg_talk_time: string;
        avg_percent_talk_time: string;
        engagement_index: string;
    };
    formative_assessment: {
        avg_grade: string;
        avg_time: string;
        correct_responses: string;
    };
    home_learning: {
        workbook_completion: string;
        avg_active_review_score: string;
        active_review_completion: string;
        avg_active_review_time: string;
    };
    gradebook: {
        overall: string;
        listening: string;
        reading: string;
        speaking: string;
        writing: string;
    };
};

export type OverViewType = ResponseType & {
    items: OverViewItemType[] | ClassOverviewItemType[];
};

export type AssignmentItem = {
    id: number;
    name: string;
    start_time: string;
    end_time: string;
    final_score: number;
    completion_percentage: number;
    completion_date: string;
    total_time_spent: number;
    assignment_name?: string;
};

export type ActiveAppItemType = {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    display_name: string;
    avatar_url: string;
    avg_assignment_score: string | number;
    avg_assignment_completion_rate: string | number;
    avg_assignment_total_time_spent: string | number;
    assignments: AssignmentItem[];
};

export type ActiveAppType = ResponseType & {
    items: ActiveAppItemType[];
};

export type SessionType = {
    session_id: number;
    start_time: string;
    end_time: string;
    lesson_id: number;
    lesson_title: string;
    attendance_type: 'present' | 'late' | 'absent';
    workbook_review: 'complete' | 'incomplete';
};

export type AttendanceItemType = {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    display_name: string;
    avatar_url: string;
    avg_attendance: string | number;
    avg_punctuality: string | number;
    avg_workbook_completion_rate: string | number;
    sessions?: SessionType[];
    attendances?: SessionType[];
    workbooks?: SessionType[];
};

export type AttendanceType = ResponseType & {
    items: AttendanceItemType[];
};

export type VerticalProgressBarType = {
    label: string;
    percent: number | string; // is integer, min 0, max 100
    progress: number | string; // is integer, min 0, max 100
    mins: number | string;
};

export type HomeLearningChartType = {
    active_review_grade: string;
    active_review: number | string; // is integer, min 0, max 100
    workbook: number | string; // is integer, min 0, max 100
};

export type SpecificStudentOverviewType = {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    display_name: string;
    avatar_url: string;
    avg_attendance: string;
    total_apo: string;
    avg_workbook_completion: string;
    avg_assignment_score: string;
    avg_assignment_completion_rate: string;
    avg_assignment_total_time_spent: string;
    grade: string;
    avg_punctuality: string;
    max_score: string;
    completed: number;
    assignment_score_in_percent: string;
    online_score_in_percent: string;
    offline_score_in_percent: string;
    assessment_score_in_percent: string;
    overall: string;
    assessment_listening: string;
    assessment_reading: string;
    assessment_speaking: string;
    assessment_writing: string;
};

export type SpecificStudentAssignmentItemType = {
    id: number;
    name: string;
    end_time: string;
    final_score: number;
    completion_percentage: number;
    total_time_spent: number;
};

export type SpecificStudentAssignmentsType = ResponseType & {
    items: SpecificStudentAssignmentItemType[];
};

export type SpecificStudentAttendanceItemType = {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    display_name: string;
    avatar_url: string;
    avg_attendance: number;
    avg_punctuality: number;
    nick_name?: string;
    attendances: {
        session_id: number;
        start_time: string;
        end_time: string;
        lesson_title: string;
        attendance_type: 'present' | 'late' | 'absent';
    }[];
};

export type SpecificStudentAttendanceType = ResponseType & {
    items: SpecificStudentAttendanceItemType[];
};

export type SpecificStudentActiveReviewAssignmentItemType = {
    id: number;
    name: string;
    end_time: string;
    final_score: number;
    completion_percentage: number;
    total_time_spent: number;
    session_id: number;
    session_start_time: string;
    session_end_time: string;
};

export type SpecificStudentActiveReviewItemType = {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    display_name: string;
    avatar_url: string;
    avg_assignment_completion: number;
    avg_assignment_score: number;
    avg_assignment_completion_time: number;
    assignments: SpecificStudentActiveReviewAssignmentItemType[];
    nick_name?: string;
};

export type SpecificStudentActiveReviewType = ResponseType & {
    items: SpecificStudentActiveReviewItemType[];
};

export type SpecificStudentWorkbookItemType = {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    display_name: string;
    avatar_url: string;
    avg_workbook_completion: number;
    nick_name?: string;
    workbooks: {
        session_id: number;
        start_time: string;
        end_time: string;
        lesson_title: string;
        workbook_review: 'complete' | 'incomplete';
        enable_workbook_review: boolean;
    }[];
};

export type SpecificStudentWorkbookType = ResponseType & {
    items: SpecificStudentWorkbookItemType[];
};

export type SpecificStudentReportDetailType = {
    id: number;
    name: string;
    type: string;
    status: string;
    created_at: string;
    creator: {
        id: number;
        email: string;
        first_name: string;
        last_name: string;
        display_name: string;
        avatar_url: string;
    };
    student: {
        id: number;
        email: string;
        first_name: string;
        last_name: string;
        display_name: string;
        avatar_url: string;
    };
    published_at: string;
    data: {
        vocabulary: string;
        grammar: string;
        speaking: string;
        listening: string;
        reading: string;
        pronunciation: string;
        writing: string;
        future_skills: string;
        progress: string;
        behavior: string;
        participation: string;
        comments: {
            id: number;
            email: string;
            first_name: string;
            last_name: string;
            display_name: string;
            avatar_url: string;
            comment: string;
            comment_vn: string;
            is_completed: boolean;
        }[];
        my_class: {
            id: number;
            name: string;
            avatar: string;
            address: string;
            description: string;
        };
        total_apo: number;
        app_review: {
            assignment_completion: number;
            assignment_grade: number;
            assignment_completion_in_percent: number;
            assignment_grade_in_percent: number;
            assignment_score: number;
            assignment_score_in_percent: number;
        };
        attendance_in_percent: number;
    };
};

export type SpecificStudentReportItemType = {
    id: number;
    name: string;
    type: string;
    status: string;
    created_at: string;
    creator: {
        id: number;
        email: string;
        first_name: string;
        last_name: string;
        display_name: string;
        avatar_url: string;
    };
    student: {
        id: number;
        email: string;
        first_name: string;
        last_name: string;
        display_name: string;
        avatar_url: string;
    };
    published_at: string;
    data: any;
};

export type SpecificStudentReportType = ResponseType & {
    items: SpecificStudentReportItemType[];
};
