import { useEffect, useState } from 'react';
import DialogComponent from 'components/Dialog/DialogComponent';
import libraryService from 'services/library.service';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LibraryTypeEnum } from 'components/Library/LibraryContants';
import { Toastify } from '_shared';
import { ButtonOutline, ButtonPrimary } from '_shared';

export default function ModalRename({ onClose, isOpen, refreshData, item }) {
    const [name, setName] = useState('');
    const params: any = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        const itemName =
            item?.object_type === LibraryTypeEnum.folder ||
            item?.object_type === LibraryTypeEnum.h5p ||
            item?.object_type === LibraryTypeEnum.lesson ||
            item?.object_type === LibraryTypeEnum.assignment
                ? item?.name
                : item?.name?.substr(0, item?.name.lastIndexOf('.'));
        setName(itemName);
    }, [item]);

    const onAfterFetch = () => {
        setName('');
        onClose(false);
        Toastify.success();
        refreshData();
    };

    const handleRename = () => {
        if (
            item?.object_type === LibraryTypeEnum.lesson ||
            item?.object_type === LibraryTypeEnum.h5p ||
            item?.object_type === LibraryTypeEnum.assignment
        ) {
            let action = 'renameH5P';
            switch (item?.object_type) {
                case LibraryTypeEnum.lesson:
                    action = 'renameLesson';
                    break;
                case LibraryTypeEnum.assignment:
                    action = 'renameAssignment';
                    break;
                default:
                    break;
            }
            const payLoad = {
                title: name,
            };
            libraryService[action](params?.id, item?.object_id, payLoad)
                .then(() => {
                    onAfterFetch();
                })
                .catch(() => Toastify.error());
        } else {
            const id = item?.object_type === LibraryTypeEnum.folder ? item?.id : item?.object_id;
            const action = item?.object_type === LibraryTypeEnum.folder ? 'updateFolder' : 'updateFile';
            const itemName = item?.object_type === LibraryTypeEnum.folder ? name : name + item?.sub_object_type;
            libraryService[action](params?.id, id, {
                name: itemName,
            })
                .then(() => {
                    onAfterFetch();
                })
                .catch(() => Toastify.error());
        }
    };

    return (
        <DialogComponent
            title={t('MODALS.RENAME')}
            isOpen={isOpen}
            onCloseModal={() => {
                onClose(false);
            }}
            child={
                <div className="mt-4">
                    <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full py-2 px-3 border  text-sm font-normal border-gray-300 rounded outline-none"
                        placeholder={t(item?.object_type == LibraryTypeEnum.folder ? 'RESOURCES.ENTER_FOLDER_NAME' : 'RESOURCES.ENTER_LESSON_NAME')}
                        maxLength={255}
                    />
                    <div className="flex justify-center gap-4 mt-5">
                        <ButtonOutline
                            type="button"
                            onClick={() => onClose(false)}
                        >
                            {t('MODALS.CANCEL')}
                        </ButtonOutline>
                        <ButtonPrimary
                            disabled={!name?.trim() || name === item?.name}
                            onClick={handleRename}
                            type='button'
                        >
                            {t('MODALS.CONFIRM')}
                        </ButtonPrimary>
                    </div>
                </div>
            }
            stylesTitle="text-base"
        />
    );
}
