import libraryService from 'services/library.service';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LibraryTypeEnum } from 'components/Library/LibraryContants';
import { useEffect, useState } from 'react';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { Toastify } from '_shared';

export default function DuplicateAction({ onClose, refreshData, item, isMulti, listItems = [] }) {
    const params: any = useParams();
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);

    const onAfterFetch = () => {
        onClose(false);
        Toastify.success();
        refreshData(1);
    };

    useEffect(() => {
        isMulti ? onDuplicateMultiple() : handleDuplicateItem();
    }, []);

    const handleDuplicateItem = () => {
        setLoading(true);
        if (
            item?.object_type === LibraryTypeEnum.lesson ||
            item?.object_type === LibraryTypeEnum.h5p ||
            item?.object_type === LibraryTypeEnum.assignment
        ) {
            let action = 'duplicateH5P';
            switch (item?.object_type) {
                case LibraryTypeEnum.lesson:
                    action = 'duplicateLesson';
                    break;
                case LibraryTypeEnum.assignment:
                    action = 'duplicateAssignment';
                    break;
                default:
                    break;
            }
            libraryService[action](params?.id, item?.object_id)
                .then(() => {
                    onAfterFetch();
                })
                .catch(() => {
                    Toastify.error();
                    onClose(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            const id = item?.object_type === LibraryTypeEnum.folder ? item?.id : item?.object_id;
            const action = item?.object_type === LibraryTypeEnum.folder ? 'duplicateFolder' : 'duplicateFile';

            libraryService?.[action](params?.id, id)
                .then(() => {
                    onAfterFetch();
                })
                .catch(() => {
                    Toastify.error();
                    onClose(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const onDuplicateMultiple = () => {
        if (listItems?.length) {
            setLoading(true);
            const listNeedToDuplicate = listItems?.map((item) => {
                const id = item?.object_type === LibraryTypeEnum.folder ? item?.id : item?.object_id;
                let action = 'duplicateFile';
                switch (item?.object_type) {
                    case LibraryTypeEnum.folder:
                        action = 'duplicateFolder';
                        break;
                    case LibraryTypeEnum.lesson:
                        action = 'duplicateLesson';
                        break;
                    case LibraryTypeEnum.h5p:
                        action = 'duplicateH5P';
                        break;
                    case LibraryTypeEnum.assignment:
                        action = 'duplicateAssignment';
                        break;
                    default:
                        action = 'duplicateFile';
                        break;
                }
                return libraryService?.[action](params?.id, id);
            });

            if (listNeedToDuplicate?.length) {
                Promise?.all(listNeedToDuplicate?.map((fn) => fn))
                    .then(() => {
                        onAfterFetch();
                    })
                    .catch(() => {
                        Toastify.error();
                        onClose(false);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    return (
        <>
            <div className="fixed bottom-4 right-4 z-9999 bg-white  rounded-lg overflow-hidden shadow-lg">
                <div className="w-[240px] max-h-96 p-5 overflow-auto scrollbar-hidden">
                    <div className=" sticky top-0 z-30">
                        <div className="flex gap-1 font-normal text-base text-gray-900 items-center">
                            <CircleSpin color={'text-primary-500'} />

                            {loading &&
                                t('LIBRARY.COPY_FILE', {
                                    number: isMulti ? listItems?.length : 1,
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
