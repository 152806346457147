import { useEffect } from 'react'
import { InputField } from 'components/InputForm/InputForm';
import InputRadio from '_shared/components/Form/InputRadio/InputRadio';
import "../../CourseSetting.scss";
import { InputCheckbox } from '_shared/components/Form/InputCheckbox/InputCheckbox';
import { VALIDATE_FIELD } from 'utils/validate';
import { useTranslation } from 'react-i18next';
import { TooltipCustom } from 'components/Tooltip/Tooltip';

export const CourseAssignment = ({notAllowEdit = false, formProps}) => {
    const {t: translator} = useTranslation();
    const { control, register, formState: { errors }, watch, reset, setValue, getValues} = formProps;

    useEffect(() => {
        if (watch('assignment_max_retry') >= 1) {
            setValue('allow_ready', true)
        }
    }, [watch('assignment_max_retry')])


    useEffect(() => {
        if (!watch('allow_ready')) {
            setValue('assignment_max_retry', null)
        }
        if (watch('allow_ready') && !watch('assignment_max_retry')) setValue('assignment_max_retry', 1)
    }, [watch('allow_ready')])

    return (
        <>
            <div className="courseSettings-title">
                {translator('COURSES.SETTING.ASSIGNMENT')}    
                <p>{translator('COURSES.SETTING.ASSIGNMENT_CONTENT')}</p>
            </div>
            <div className={`courseSettings-container !pb-2  ${notAllowEdit ? 'opacity-container' : ''}  `}>
                <div className="flex items-start gap-2 mt-2">
                    <div 
                        className="items-start"
                        data-for="COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS"
                        data-tip={translator('COURSES.SETTING.COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS')}
                    >
                        <InputRadio control={control} 
                            inputName="assignment_release_type" 
                            value="manually" 
                            checked={watch('assignment_release_type') === 'manually'}
                            disabled={notAllowEdit}
                        />
                    </div>
                    <div className="courseSettings-right">
                        <div className="courseSettings-label">{translator("COURSES.SETTING.MANUAL_ASSIGNMENT")}</div>
                        <p className="mt-1">{translator("COURSES.SETTING.MANUAL_ASSIGNMENT_CONTENT")}</p>
                    </div>
                </div>
                <div className="flex items-start gap-2 mt-4">
                    <div 
                        className="items-start"
                        data-for="COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS"
                        data-tip={translator('COURSES.SETTING.COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS')}
                    >
                        <InputRadio control={control} 
                            inputName="assignment_release_type" 
                            value="automatic" 
                            checked={watch('assignment_release_type') === 'automatic'}
                            disabled={notAllowEdit}
                        />
                    </div>
                    <div className="courseSettings-right">
                        <div className="courseSettings-label">{translator("COURSES.SETTING.AUTOMATIC_ASSIGNMENT")}</div>
                        <p className="mt-1">{translator("COURSES.SETTING.AUTOMATIC_ASSIGNMENT_CONTENT")}</p>
                    </div>
                </div>
            </div>
            {notAllowEdit && getValues('course_status') === 'active' && (
                <TooltipCustom
                    classNameContainer="shadow-medium"
                    id="COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS"
                    content={translator('COURSES.SETTING.COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS')}
                    place="top"
                    type="dark"
                />
            )}
            
            {
                watch('assignment_release_type') === 'automatic' && (
                    <div className={`courseSettings-container !px-12 !pt-0  ${notAllowEdit ? 'opacity-container' : ''}  `}>
                        <div className="courseSettings-item flex-col">
                        <div className='text-base font-semibold'>{translator("COURSES.RELEASE_FORMAT")}</div>

                            <div className="flex pl-5 items-start gap-2 mt-2">
                                <div className="items-start">
                                    <InputRadio control={control} 
                                        inputName="assignment_release_format" 
                                        value="after_lesson" 
                                        checked={watch('assignment_release_format') === 'after_lesson'}
                                        disabled={notAllowEdit}
                                    />
                                </div>
                                <div className="courseSettings-right">
                                    <div className="courseSettings-label">{translator("COURSES.AFTER_LESSON")}</div>
                                    <p className="mt-1">{translator("COURSES.AFTER_LESSON_CONTENT")}</p>
                                    <div className="mt-2 ml-2">
                                        <InputCheckbox
                                            control={control}
                                            inputName="has_due_date"
                                            label={translator("COURSES.DUE_DATE")}
                                            disabled={watch('assignment_release_format') !== "after_lesson" || notAllowEdit}
                                        />
                                        <p className="mt-1 ml-7">{translator("COURSES.DUE_DATE_CONTENT")}</p>
                                        <div className='flex items-center gap-2 ml-7 mt-2'>
                                            <InputField
                                            name="assigment_due_date"
                                            maxLength={255}
                                            classNameInput="h-8 !w-20"
                                            labelHorizontal='day(s)'
                                            register={register('assigment_due_date', {
                                                pattern: {
                                                    value: VALIDATE_FIELD.NUMBER,
                                                    message: translator('FORM_CONST.NUMBER_ONLY_AND_NUMBERS_GREATER_THAN_ZERO'),
                                                },
                                            })}
                                            errors={errors}
                                            disabled={!watch('has_due_date') || watch('assignment_release_format') !== "after_lesson" || notAllowEdit}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex pl-5 gap-2 mt-2">
                                <div>
                                    <InputRadio control={control} 
                                        inputName="assignment_release_format" 
                                        value="all_available" 
                                        checked={watch('assignment_release_format') === 'all_available'}
                                        disabled={notAllowEdit}
                                    />
                                    <div>
                                    </div>
                                </div>
                                <div className="flex-col">
                                    <div className="courseSettings-label">{translator("COURSES.ALL_AVAILABLE")}</div>
                                    <p>{translator("COURSES.ALL_AVAILABLE_CONTENT")}</p>
                                </div>
                                
                            </div>
                            

                        </div>
                        <div className="courseSettings-item flex-col !border-b-0">

                            <div className='text-base font-semibold mt-6 '>{translator("COURSES.RETRY_SOLUTION")}</div>
                                <div className="flex flex-col pl-5 gap-2">
                                <div>
                                    <div className="mt-2">
                                        <div className=''>
                                        <InputCheckbox
                                            control={control}
                                            inputName="allow_ready"
                                            label={translator("COURSES.ALLOW_READY")}
                                            labelClassName='!text-base font-semibold'
                                            disabled={notAllowEdit}
                                        />
                                        </div>
                                        <p className="mt-1 ml-7">{translator("COURSES.RETRY_CONTENT")}</p>
                                        <div className='flex items-center gap-2 ml-7 mt-2'>
                                            <InputField
                                            name="assignment_max_retry"
                                            maxLength={255}
                                            classNameInput="h-8 !w-20"
                                            labelHorizontal="time(s)"
                                            register={register('assignment_max_retry', {
                                                pattern: {
                                                    value: VALIDATE_FIELD.NUMBER,
                                                    message: translator('FORM_CONST.NUMBER_ONLY_AND_NUMBERS_GREATER_THAN_ZERO'),
                                                },
                                            })}
                                            errors={errors}
                                            disabled={!watch('allow_ready') || notAllowEdit}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="mt-2">
                                        <div className=''>
                                        <InputCheckbox
                                            control={control}
                                            inputName="assignment_hide_solution"
                                            label={translator("COURSES.HIDE_SOLUTION")}
                                            labelClassName='!text-base font-semibold'
                                            disabled={notAllowEdit}
                                        />
                                        </div>
                                        <p className="mt-1 ml-7">{translator("COURSES.HIDE_SOLUTION_CONTENT")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )

            }
            
        </>
    )
}