import React from 'react';
import RouteMasterPage from 'pages/RouteMasterPage';
import { AssignmentCenter } from 'components/AssignmentCenter/AssignmentCenter';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';

const AssignmentCenterPage = () => {
    return (
        <ManagePermissionWrapper>
            <AssignmentCenter>
                <RouteMasterPage />
            </AssignmentCenter>
        </ManagePermissionWrapper>

    );
};

export default AssignmentCenterPage;
