import * as React from 'react';
import { genClassNames } from 'utils/handleString';

interface TooltipProps {
    titleArr?: any[];
    children: React.ReactNode;
    mtClass?: string;
    mlClass?: string;
    type?: 'error' | 'warning';
    styles?: string;
}

const TooltipArr: React.FC<TooltipProps> = ({
    styles = undefined,
    children,
    mtClass,
    mlClass,
    type,
    titleArr = [],
}: TooltipProps) => {
    return (
        <div className="has-tooltip">
            <span
                className={genClassNames({
                    'tooltip shadow-lg text-ooolab_sxs rounded-[20px] px-3 py-1': true,
                    'ml-ooolab_default_tooltip_left': mlClass === undefined,
                    'mt-ooolab_default_tooltip_top': mtClass === undefined,
                    [mtClass || '']: true,
                    [mlClass || '']: true,
                    'opacity-0': !titleArr,
                    'bg-ooolab_bg_tooltip text-white': !type,
                    'bg-ooolab_bg_tooltip_error text-white': type === 'error',
                    'whitespace-nowrap': styles === undefined,
                })}
            >
                {titleArr?.length > 0
                    ? titleArr.map((i, idx) => (
                          <div className={`${idx <= 1 ? 'hidden ' : ''} flex items-center pr-1`}>
                              <div className="w-1 h-1 rounded-full bg-white mx-2"></div> {i}
                          </div>
                      ))
                    : null}
            </span>
            {children}
        </div>
    );
};

export default TooltipArr;
