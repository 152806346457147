import React from 'react';
import ManagementMasterPage from '../../../RouteMasterPage';
import GroupDetail from 'components/Management/Group/GroupDetail';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';

const GroupDetailPage = () => {
    return (
        <ManagePermissionWrapper>
            <GroupDetail>
                <ManagementMasterPage />
            </GroupDetail>
        </ManagePermissionWrapper>
    );
};

export default GroupDetailPage;
