import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import rolesPermissionsService from 'services/rolesPermissions.service';
import { AsyncSelectField } from 'components/Select';

const PER_PAGE = 20;

const formatRole = (data: any) => {
    return (
        <div className="flex items-center text-dark-300">
            <p className="truncate capitalize">{data?.name?.replace('_', ' ')}</p>
        </div>
    );
};

const DeleteRole = ({ submitted, onCloseModal, roleId, roleData }) => {
    const { t } = useTranslation();
    const params: any = useParams();
    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
        watch,
    } = useForm({ mode: "onChange" });

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`ROLES_PERMISSIONS.${str}`, opt));
    const fetchRole = async (bodyParams?: any) => {
        try {
            const res = await rolesPermissionsService.getWorkspaceRoles(params.id, bodyParams);
            return {
                items: res?.data?.items || [],
                total: res?.data?.total || 0,
            };
        } catch (error) { }
    };

    const handleLoadRolesOptions = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional.page,
            per_page: PER_PAGE,
            q: inputValue || null,
            except_role_id: params?.roleId || roleId,
        };
        try {
            const { items, total } = await fetchRole(payload);
            const newOptions = items?.map((el: any) => ({
                ...el,
                id: el.id,
                label: el?.title,
                value: el.id,
            }));
            return {
                options: newOptions,
                hasMore: additional?.page < total / 20,
                additional: {
                    page: additional?.page + 1,
                },
            };
        } catch (error) { }
    };

    const submitForm = (val) => {
        setIsLoading(true);
        const bodyData = {
            replace_role_id: watch('role')?.value
        }
        if (!roleData?.has_attached_members) delete bodyData?.replace_role_id
        rolesPermissionsService.deleteCustomRole(params?.id, params?.roleId || roleId,
            bodyData
        )
            .then((res) => {
                Toastify.success();
                submitted();
            })
            .catch((err) => {
                Toastify.error();
            }).finally(() => setIsLoading(false));
    };

    return (
        <form onSubmit={handleSubmit(submitForm)} className="">
            {!roleData?.has_attached_members ? <div className='text-sm text-gray-800 my-2' dangerouslySetInnerHTML={{
                __html: translator("TITLE_DELETE_ROLE_NO_MEMBERS", false, {
                    roleName: roleData?.name
                })
            }} /> : <>
                <p className='text-sm text-gray-800 mt-4'>{translator("TITLE_DELETE_ROLE")}</p>
                <p className='text-sm text-gray-800 mt-5 mb-3'>{translator("SUB_TITLE_DELETE_ROLE")}</p>
            </>
            }

            {
                roleData?.has_attached_members && <div className="selectRole">
                    <AsyncSelectField
                        control={control}
                        label={translator('ROLE')}
                        name="role"
                        placeholder={translator('SELECT_ROLE')}
                        closeMenuOnSelect={true}
                        loadOptions={handleLoadRolesOptions}
                        formatOptionLabelCustom={formatRole}
                    />
                </div>
            }
            <div className="flex justify-center gap-4 items-center pt-4">
                <ButtonOutline
                    onClick={onCloseModal}
                    type={'button'}
                >
                    {translator('CANCEL', true)}
                </ButtonOutline>
                <ButtonPrimary
                    type="submit"
                    disabled={isLoading || (!roleData?.has_attached_members ? false : (!isValid || !watch('role')))}
                    className={`${isLoading ? 'bg-gray_0 text-white cursor-not-allowed' : ''} buttonPrimary-danger`}
                >
                    {translator('DELETE', true)}

                </ButtonPrimary>
            </div>
        </form >
    );
};

export default DeleteRole;
