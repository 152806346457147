import Table, { RenderTableHeader, RenderTableCell, LinkCustom} from 'components/V2/Table/TableCustom';
import React from 'react';
import {  Row, Column } from 'react-table';
import LessonOptions from '../MenuContext'
import { useTranslation } from 'react-i18next';
import EmptyStateLearningGroup from 'assets/img/empty-stage-learning-group.png'

const TableGroups = ({
    workspaceId,
    dataTable,
    handleViewDetailClass,
    handleViewDetailUser,
    handleDeleteGroup,
    order,
    setOrder,
}) => {
    const { t: translator } = useTranslation();

    const handleChangeOrder = () =>{
        if (order.order === 'asc') {
            setOrder({
                order: 'desc',
                sort_by: 'name',
            })
        }
        if (order.order === 'desc') {
            setOrder({
                order: 'asc',
                sort_by: 'name',
            })
        }
        
    }

    const columns = React.useMemo(
        () => [
            {
                Header: () => (
                    <div className="text-left flex items-center">
                        <RenderTableHeader isOrder onClick={handleChangeOrder} value={translator("GROUP.NAME")} />
                    </div>
                ),
                accessor: 'name',
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return (
                        <LinkCustom
                            to={`/workspace/${workspaceId}/management/custom-groups/${row.values.id}`}
                            className=" text-ooolab_xs text-primary-500 font-semibold cursor-pointer  "
                            value={row.values.name}
                            isTooltip
                        />
                    );
                },
                width: 140,
            },
            {
                Header: () => (
                    <RenderTableHeader value={translator("GROUP.DESC")} />
                ),
                accessor: 'description',
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return (
                        <RenderTableCell value={row.values.description || '_'} />
                    );
                },
                width: 140,
            },
            {
                Header: () => (
                    <RenderTableHeader value={translator("GROUP.PEOPLE")} />
                ),
                accessor: 'users',
                Cell: (d: any) => (
                    <RenderTableCell className='!text-primary-500' value={translator("GROUP.DETAIL")} onClick={() => handleViewDetailUser(d.row.original.id)}/>
                ),
            },
            {
                Header: () => (
                    <RenderTableHeader value={translator("GROUP.ACTIONS")} />
                ),
                accessor: 'id',
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return (
                        <div className=" flex items-center h-full">
                            <LessonOptions
                                id={row.values.id}
                                canRemove={true}
                                redirectUrl={`/workspace/${workspaceId}/management/custom-groups/${row.values.id}`}
                                handleRemove={handleDeleteGroup}
                                position={row?.index > dataTable?.length/2 ? 'bottom-0 top-auto':''}
                            />
                        </div>
                        
                    );
                },
                width: 40,
            },
        ],
        [order]
    );
    return <Table data={dataTable} columns={columns} emptyIcon={EmptyStateLearningGroup} emptyContent={translator("GROUP.NOGROUP")}/>;
};

export default TableGroups;
