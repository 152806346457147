import { FC, useState, useEffect, useMemo } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './RolesPermssionOverview.scss'
import OverView from '../RolesPermissionDetailsComponents/OverView';
import AssignedPermissions from '../RolesPermissionDetailsComponents/AssignedPermissions';
interface RolesPermssionOverviewProps {
    data: any;
    submitted: any;
    isDisable: boolean;
    roleId?: any;
}

const RolesPermssionOverview: FC<RolesPermssionOverviewProps> = ({ data, submitted, isDisable, roleId }) => {
    const params: any = useParams();
    const { t } = useTranslation();
    const history = useHistory();

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`ROLES_PERMISSIONS.${str}`, opt));
    return (
        <>
            <div className='py-5'><OverView data={data} submitted={submitted} isDisable={isDisable} roleId={roleId} /></div>
            <div className='py-5'><AssignedPermissions data={data} submitted={submitted} isDisable={isDisable} roleId={roleId} /></div>

        </>
    );
};

export default RolesPermssionOverview;
