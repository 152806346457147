
import { AuthContext } from 'contexts/Auth/AuthContext';
import React from 'react';

const Logo = ({ className, alt }) => {
  const authCtx = React.useContext(AuthContext);
  const [whiteLabel, setWhiteLabel] = React.useState<any>();

  React.useEffect(() => {
    if (authCtx?.config) {
      setWhiteLabel(authCtx?.config?.find(item => item?.page_name == "login"));
    }
  }, [authCtx]);

  const logo = require(`assets/themes/${process.env.REACT_APP_WHITELABEL}/logo.svg`);
  return (
    <img
      className={className}
      src={whiteLabel?.data?.web?.logo || logo.default}
      alt={alt}
    />
  )
}

export default Logo;