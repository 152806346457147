import { FC } from 'react';
import { DownloadIcon } from '@heroicons/react/outline';
import { LoadingSVG } from 'assets/icon';

type DownloadCSVProps = {
    title?: string;
    disabled?: boolean;
    isDownload: boolean;
    onClick: () => void;
};

const index: FC<DownloadCSVProps> = ({ title = 'Download', disabled = false, isDownload = false, onClick }) => {
    return (
        <button
            type="button"
            disabled={isDownload || disabled}
            onClick={onClick}
            className={`text-gray-800 text-xs whitespace-nowrap font-semibold border border-gray-300 rounded py-1.5 px-3 flex justify-center items-center space-x-1.5 disabled:cursor-not-allowed disabled:bg-gray-300/50
            ${disabled ? 'opacity-50' : ''}`}
        >
            {isDownload ? <LoadingSVG className="text-gray-800" /> : <DownloadIcon className="w-5 h-5" />}
            <span>{title}</span>
        </button>
    );
};

export default index;
