import { useContext, useEffect, useMemo, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import 'react-datepicker/dist/react-datepicker.css';

import ModalUploadImage from 'components/Management/components/UploadImage';

import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';

import { useBoolean } from 'hooks/custom';
import DatePickerInput from 'components/Management/components/Form/DatePicker';
import InputNumber from 'components/Management/Reward/RewardComponents/InputNumber';
import rewardService from 'services/reward.service';
import { DetailsRewardsType } from 'types/Rewards.type';
import { UserContext } from 'contexts/User/UserContext';
import ButtonComponent from 'components/Button/ButtonComponent';
import userService from 'services/user.service';
import Modal from 'components/Modal';
import { ExclamationIcon } from '@heroicons/react/outline';
import { Toastify } from '_shared';
import { checkPermission } from 'utils/commonFun';

interface RewardInformationProps { }

const RewardInformation: React.FC<RewardInformationProps> = ({ }) => {
    const { t: translator } = useTranslation();
    const params = useParams<{ id: string; rewardId: string }>();
    const { dispatch, getWorkspaceDetailState } = useContext(
        GetWorkspaceContext
    );

    const [levelWs, setLevelWs] = useState<any>();
    const [tmpAvatar, setTmpAvatar] = useState<any>();
    const [rewardStatus, setRewardStatus] = useState<string>('');
    const [loading, setLoading] = useState(false)
    const [initValue, setInitValue] = useState<DetailsRewardsType>({
        id: null,
        reward_level: null,
        name: '',
        thumbnail: '',
        is_featured: false,
        description: '',
        start_date: '',
        end_date: '',
        total_quantity: null,
        available_quantity: null,
        price: null,
        status: '',
        created_at: '',
        updated_at: '',
        reward_level_id: null,
    });
    const {
        register,
        formState: { errors, dirtyFields, isDirty, isValid },
        getValues,
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
    } = useForm({
        defaultValues: useMemo(() => {
            return {
                ...initValue,
            };
        }, [initValue]),
    });

    const dateStartWatch = watch('start_date')
    const dateEndWatch = watch('end_date')

    useEffect(() => {
        const getData = async () => {
            await rewardService.getApoLevelService(params.id).then((res: any) => {
                if (res) {
                    setLevelWs(res?.data?.items);
                }
            });
            rewardService
                .getReward(params.id, params.rewardId)
                .then((res) => {
                    setInitValue({
                        id: res?.data?.id,
                        reward_level: res?.data?.reward_level,
                        reward_level_id: res?.data?.reward_level?.id,
                        name: res?.data?.name,
                        thumbnail: res?.data?.thumbnail,
                        is_featured: res?.data?.is_featured,
                        description: res?.data?.description,
                        start_date: new Date(res?.data?.start_date),
                        end_date: new Date(res?.data?.end_date),
                        total_quantity: res?.data?.total_quantity,
                        available_quantity: res?.data?.available_quantity,
                        price: res?.data?.price,
                        status: res?.data?.status,
                        created_at: res?.data?.created_at,
                        updated_at: res?.data?.updated_at,
                    });
                    setRewardStatus(res?.data?.status);
                    setTmpAvatar(res?.data?.thumbnail);
                })
                .catch((err) => console.log(err));
        }
        getData();
    }, []);

    useEffect(() => {
        reset(initValue);
    }, [initValue]);

    const {
        membership: { role },
    } = getWorkspaceDetailState.result;

    const {
        userState: { result: userInformation },
    } = useContext(UserContext);

    const {
        booleanValue: isUpload,
        toggleBooleanValue: toggleIsUpload,
    } = useBoolean();

    const {
        booleanValue: confirmDeactivateReward,
        toggleBooleanValue: toggleDeactivateReward,
    } = useBoolean();

    const onSubmit = () => {
        setLoading(true)
        const values = getValues();
        const formData = {
            reward_level_id: values?.reward_level_id
                ? values?.reward_level_id
                : initValue?.reward_level?.id,
            name: values?.name,
            description: values?.description,
            thumbnail: tmpAvatar ? tmpAvatar : '',
            start_date: dayjs(values?.start_date).format('YYYY-MM-DD'),
            end_date: dayjs(values?.end_date).format('YYYY-MM-DD'),
            total_quantity: values?.total_quantity,
            price: values?.price,
            is_featured: values?.is_featured,
        };
        rewardService
            .updateReward(params.id, params.rewardId, formData)
            .then((res) => {
                setInitValue({
                    id: res?.data?.id,
                    reward_level: res?.data?.reward_level,
                    name: res?.data?.name,
                    thumbnail: res?.data?.thumbnail,
                    is_featured: res?.data?.is_featured,
                    description: res?.data?.description,
                    start_date: new Date(res?.data?.start_date),
                    end_date: new Date(res?.data?.end_date),
                    total_quantity: res?.data?.total_quantity,
                    available_quantity: res?.data?.available_quantity,
                    price: res?.data?.price,
                    status: res?.data?.status,
                    created_at: res?.data?.created_at,
                    updated_at: res?.data?.updated_at,
                });
                Toastify.success();
            })
            .catch(() => Toastify.error())
            .finally(() => setLoading(false))
    };

    const handleUpdateImage = async (image: File) => {
        await userService.uploadImage(
            image,
            (resUpload) => {
                rewardService
                    .updatePartialReward(params.id, params.rewardId, {
                        thumbnail: resUpload,
                    })
                    .then((res) => {
                        setTmpAvatar(res?.data?.thumbnail);
                        Toastify.success();
                    })
                    .catch(() => {
                        Toastify.error();
                    });
            },
            (err) => {
                Toastify.error(translator('UP_LOAD_IMAGE_FAILED'));
            }
        );
    };

    const handleUpdateRewardStatus = (e: 'active' | 'deactivate') => {
        rewardService
            .updateStatusReward(params.id, params.rewardId, { status: e })
            .then((res) => {
                setRewardStatus(res?.data?.status);
                Toastify.success();
            })
            .catch(() => Toastify.error());
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={`px-5 relative `}>
            <ModalUploadImage
                isOpen={isUpload}
                onClose={toggleIsUpload}
                onSubmitImage={handleUpdateImage}
            />
            <Modal
                isOpen={confirmDeactivateReward}
                onClose={toggleDeactivateReward}
                title={translator('Confirm deactivate')}
                contentText={translator('Are you sure to deactivate this reward?')}
                imgSrc={
                    <ExclamationIcon className="text-red-500 w-ooolab_w_10 h-ooolab_h_10" />
                }
                subBtn={
                    <button
                        className="px-ooolab_p_2 py-ooolab_p_1_e bg-primary-500 text-white border-none rounded"
                        onClick={toggleDeactivateReward}
                        type="button"
                    >
                        {translator("Cancel")}
                    </button>
                }
                mainBtn={
                    <button
                        className="px-ooolab_p_2 py-ooolab_p_1_e bg-red-500 text-white border-none rounded"
                        type="button"
                        onClick={() => {
                            handleUpdateRewardStatus('deactivate');
                            setTimeout(() => toggleDeactivateReward(), 200);
                        }}
                    >
                        {translator("Deactive")}
                    </button>
                }
            />
            <div className="border-b border-ooolab_bar_color py-5 flex items-center justify-between">
                <div className="relative overflow-hidden w-28 h-28 rounded-3xl border z-1 group hover:border-black">
                    <img
                        className="w-full h-full z-1 absolute left-0"
                        src={tmpAvatar}
                        alt="group-avatar"
                    />

                    <button
                        type="button"
                        onClick={toggleIsUpload}
                        className="z-30 w-28 h-28 bg-ooolab_dark_50 bg-opacity-60 rounded-3xl border-ooolab_bar_color left-[0px] hidden absolute group-hover:block"
                        disabled={!checkPermission('rewardRewardsInformation', 'U')}
                    >
                        <span className="text-ooolab_10px bg-ooolab_light_100 p-1">
                            {translator("Change Avatar")}
                        </span>
                    </button>
                </div>
                {checkPermission('rewardRewardsInformation', 'U') && <div className={`flex items-center text-xs font-semibold`}>
                    <ButtonComponent
                        title={'Save'}
                        type="submit"
                        classStyle={`px-3 py-1 rounded items-center px-4 py-2 text-sm font-medium rounded-md text-white bg-primary-500 text-white disabled:text-gray_0 disabled:bg-gray_0 disabled:cursor-not-allowed
                        }`}
                        disable={
                            !isDirty || !isValid || loading || !checkPermission('rewardRewardsInformation', 'U')
                        }
                    />

                    {rewardStatus === 'deactivate' ? (
                        <button
                            type="button"
                            onClick={() => handleUpdateRewardStatus('active')}
                            className="mx-2 secondary-button"
                            disabled={!checkPermission('rewardRewardsInformation', 'DA')}
                        >
                            {translator("Active")}
                        </button>
                    ) : (
                        <button
                            onClick={toggleDeactivateReward}
                            type="button"
                            className="mx-2 secondary-button"
                            disabled={!checkPermission('rewardRewardsInformation', 'AC')}
                        >
                            {translator("Deactivate")}
                        </button>
                    )}
                </div>}


            </div>
            <div className=" mt-5 grid grid-cols-6 gap-x-8 gap-y-5">
                <div className="col-span-2 text-xs">
                    <label
                        htmlFor="reward-name"
                        className="text-ooolab_dark_1 font-semibold leading-8 block"
                    >
                        {translator("Reward Name")}
                    </label>
                    <input
                        className="border w-full border-ooolab_bar_color text-ooolab_dark_1 p-2 rounded font-normal"
                        type="text"
                        id="reward-name"
                        maxLength={100}
                        placeholder={translator('Reward Name')}
                        disabled={!checkPermission('rewardRewardsInformation', 'U')}
                        {...register('name', {
                            required: {
                                value: true,
                                message: translator(
                                    'FORM_CONST.REQUIRED_FIELD'
                                ),
                            },
                            validate: {
                                shouldNotContainSpace: (value) =>
                                    !!value.trim()
                                        ? true
                                        : `${translator(
                                            'FORM_CONST.REQUIRED_FIELD'
                                        )}`,
                            },
                        })}
                    />
                    <ErrorMessage
                        className="text-red-500 text-ooolab_10px"
                        errors={errors}
                        name="name"
                        as="p"
                    />
                </div>
                <div className="col-span-1 text-ooolab_xs"></div>
                <div className="col-span-3"></div>
                <div className="col-span-4 text-xs">
                    <label
                        htmlFor="class-desc"
                        className="text-ooolab_dark_1 font-semibold leading-8 block"
                    >
                        {translator("Description")}
                    </label>
                    <textarea
                        className="text-ooolab_dark_1 rounded font-normal w-full border border-ooolab_bar_color leading-8 pl-2 py-1 focus:outline-none"
                        rows={3}
                        id="reward-description"
                        placeholder={translator('Description')}
                        {...register('description', {
                            maxLength: {
                                value: 200,
                                message: translator('FORM_CONST.MAX_LENGTH_200'),
                            },
                        })}
                        disabled={!checkPermission('rewardRewardsInformation', 'U')}
                    />
                    <ErrorMessage
                        className="text-red-500 text-ooolab_10px"
                        errors={errors}
                        name="description"
                        as="p"
                    />
                </div>
                <div className="col-span-2 "></div>
                <div className="col-span-1 ">
                    <label
                        className="text-ooolab_dark_1 text-xs font-semibold leading-8 block"
                        htmlFor="start_date"
                    >
                        {translator("Start Date")}
                    </label>
                    <div className="w-full h-8 relative">
                        <DatePickerInput
                            control={control}
                            name="start_date"
                            placeholderText={'Start Date'}
                            formatDate="MM/dd/yyyy"
                            maxDate={dateEndWatch}
                            isRequired
                            disabled={!checkPermission('rewardRewardsInformation', 'U')}

                        />
                    </div>
                    <ErrorMessage
                        className="text-red-500 text-ooolab_10px"
                        errors={errors}
                        name="start_date"
                        as="p"
                    />
                </div>

                <div className="col-span-1 text-xs">
                    <label
                        className="text-ooolab_dark_1 text-xs font-semibold leading-8 block"
                        htmlFor="end_date"
                    >
                        {translator("End Date")}
                    </label>
                    <div className="w-full h-8 relative">
                        <DatePickerInput
                            control={control}
                            name="end_date"
                            placeholderText={'End Date'}
                            formatDate="MM/dd/yyyy"
                            minDate={dateStartWatch}
                            isRequired
                            disabled={!checkPermission('rewardRewardsInformation', 'U')}
                        />
                    </div>
                    <ErrorMessage
                        className="text-red-500 text-ooolab_10px"
                        errors={errors}
                        name="end_date"
                        as="p"
                    />
                </div>

                <div className="col-span-1 text-xs">
                    <label
                        className="text-ooolab_dark_1 text-xs font-semibold leading-8 block"
                        htmlFor="total_quantity"
                    >
                        {translator("Quantity")}
                    </label>
                    <InputNumber
                        nameField="total_quantity"
                        disableInput={!checkPermission('rewardRewardsInformation', 'U')}
                        getValues={getValues}
                        setValue={setValue}
                        register={register}
                        valueField={null}
                        isRequired={true}
                        maxNumber={1000}
                        placeHolder={translator('Quantity')}
                    />
                    <ErrorMessage
                        className="text-red-500 text-ooolab_10px"
                        errors={errors}
                        name="total_quantity"
                        as="p"
                    />
                </div>

                <div className="col-span-1 text-xs">
                    <label
                        className="text-ooolab_dark_1 text-xs font-semibold leading-8 block"
                        htmlFor="available_quantity"
                    >
                        {translator("Availability")}
                    </label>
                    <div className="border-[#EBEBF0] border rounded  p-2 bg-[#FAFAFC]">
                        {initValue?.available_quantity}
                    </div>
                </div>

                <div className="col-span-1 text-xs">
                    <label
                        className=" text-xs font-semibold leading-8 block"
                        htmlFor="price"
                    >
                        {translator("Coin Price")}
                    </label>
                    <InputNumber
                        nameField="price"
                        disableInput={!checkPermission('rewardRewardsInformation', 'U')}
                        getValues={getValues}
                        setValue={setValue}
                        register={register}
                        valueField={null}
                        maxNumber={1000}
                        isRequired={true}
                        placeHolder={'Quantity'}
                    />
                    <ErrorMessage
                        className="text-red-500 text-ooolab_10px"
                        errors={errors}
                        name="price"
                        as="p"
                    />
                </div>
                <div className="col-span-1 "></div>
                <div className="col-span-1 text-xs">
                    <label
                        className="text-ooolab_dark_1 text-xs font-semibold leading-8 block"
                        htmlFor="reward_level"
                    >
                        {("Level")}
                    </label>
                    <select
                        className={`text-ooolab_dark_1 text-xs font-normal leading-8 outline-none w-full border border-ooolab_bar_color rounded py-2`}
                        id="reward_level_id"
                        {...register('reward_level_id', {
                            required: {
                                value: true,
                                message: translator(
                                    'FORM_CONST.REQUIRED_FIELD'
                                ),
                            },
                        })}
                        disabled={!checkPermission('rewardRewardsInformation', 'U')}
                    >
                        <option className="text-sm" value="">
                            {translator("level")}
                        </option>
                        {levelWs &&
                            levelWs?.map((i, idx) => (
                                <option
                                    className="text-sm"
                                    value={i?.id}
                                    key={i?.id}
                                >
                                    {i?.name}
                                </option>
                            ))}
                    </select>
                    <ErrorMessage
                        className="text-red-500 text-ooolab_10px"
                        errors={errors}
                        name="reward_level_id"
                        as="p"
                    />
                </div>
                <div className="col-span-1 text-xs">
                    <label
                        className="text-ooolab_dark_1 text-xs font-semibold leading-8 block"
                        htmlFor="created_at"
                    >
                        {translator("Created Date")}
                    </label>
                    <div className="border-[#EBEBF0] border rounded  p-2 bg-[#FAFAFC]">
                        {dayjs(initValue?.created_at)
                            .tz(userInformation?.time_zone)
                            .format('MM/DD/YYYY')}
                    </div>
                </div>
                <div className="col-span-1 text-xs">
                    <label
                        className="text-ooolab_dark_1 text-xs font-semibold leading-8 block"
                        htmlFor="status"
                    >
                        {translator("Status")}
                    </label>
                    <div className="border-[#EBEBF0] border rounded  p-2 bg-[#FAFAFC]">
                        {initValue?.status}
                    </div>
                </div>
                <div className="col-span-1 text-xs">
                    <label
                        className="text-ooolab_dark_1 text-xs font-semibold leading-8 block"
                        htmlFor="is_featured"
                    >
                        {translator("Featured rewards")}
                    </label>
                    <input
                        className="border-[2px]  border-[#404475] text-ooolab_dark_1 p-3 rounded-md h-8 w-5 "
                        type="checkbox"
                        id="reward-feature"
                        placeholder={translator('Reward Name')}
                        {...register('is_featured')}
                        disabled={!checkPermission('rewardRewardsInformation', 'U')}
                    />
                </div>
            </div>
        </form>
    );
};

export default RewardInformation;
