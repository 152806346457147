import React, { useEffect, useState } from "react";
import SearchBox from 'components/V2/SearchBox';
import "./StudyHistory.scss";
import StudyHistoryTable from "./StudyHistoryTable";
import Pagination from "components/V2/Pagination";
import courseService from "services/course.service";
import { useLocation, useParams } from "react-router-dom";
import { checkPermission, getIndexPage } from "utils/commonFun";
import { CircleSpin } from "components/UiComponents/CircleSpin";

export const StudyHistory = ({ userData }) => {
    const search = useLocation()?.search;
    const params: { id: string, userId } = useParams();
    const courseId = new URLSearchParams(search).get('courseId');

    const learnerGroupeId = new URLSearchParams(search).get('learnerGroupeId');
    const [loading, setLoading] = useState(false);
    const [indexPage, setIndexPage] = useState('0-0');
    const [searchText, setSearchText] = useState(null);
    const [data, setData] = useState();
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'end_time',
        total: 0,
        past: true
    });

    const handlePagination = (page: number) => {
        getStudyHistory(page);
    };

    const getStudyHistory = async (page = 1) => {
        setLoading(true);
        const payload = {
            ...pagination,
            learner_id: params?.userId,
            learning_group_id: learnerGroupeId,
            page,
            q: searchText
        }
        if (!payload?.q) delete payload?.q;
        if (!payload?.learner_id) delete payload?.learner_id;
        if (!payload?.learning_group_id) delete payload?.learning_group_id;
        delete payload?.total;

        let response;
        if (payload?.learner_id) {
            response = await courseService.studyHistoryIndividual(params?.id, courseId, payload);
        } else {
            response = await courseService.studyHistory(params?.id, courseId, payload);
        }
        const { items, total } = response.data;
        setData(items);
        setPagination({ ...pagination, ...payload, total });
        setIndexPage(getIndexPage(payload?.page, pagination?.per_page, items?.length));
        setLoading(false);
    }

    useEffect(() => {
        if (checkPermission('course', 'R')) {
            getStudyHistory();
        }
    }, [searchText])

    return <>
        <div className="studyHistory">
            <div className="studyHistory-header sticky left-0">
                <SearchBox className="filterLearningPath-searchInput" onSubmit={setSearchText} />
            </div>
            <div className="studyHistory-content overflow-auto h-[500px]">
                {loading ?
                    <div className="flex items-center justify-center h-[500px]">
                        <CircleSpin color="text-primary-500" />
                    </div> :
                    <StudyHistoryTable userData={userData} data={data} refreshData={getStudyHistory} />}
            </div>
            <div className="studyHistory-pagination sticky left-0">
                <Pagination
                    total={pagination.total}
                    per_page={pagination.per_page}
                    callback={(e) => handlePagination(e)}
                    indexPage={indexPage}
                    page={pagination?.page}
                />
            </div>
        </div>
    </>
}