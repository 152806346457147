import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "_shared/components/Card/Card";
import "./index.scss";
import paypal from "assets/SVG/paypal.svg";
import stripe from "assets/SVG/stripe.svg";
import { ButtonOutline, ButtonPrimary, ConfirmModal, Toastify } from "_shared";
import { useParams } from "react-router-dom";
import paymentService from "services/payment.service";
import { PaymentGatewaysType } from "constant/payment.const";
import { PaymentMethod } from "./PaymentMethod";
import { ConfirmAddPayment } from "./ConfirmAddPayment";
import { SelectField } from "components/Select";
import { COUNTRIES } from "constant/country";
import { useForm } from "react-hook-form";
import DialogComponent from "components/Dialog/DialogComponent";

export const PaymentGateways = () => {
    const {
        control,
        watch
    } = useForm({});

    const { t: translator } = useTranslation();
    const params: { id: string } = useParams();
    const [hasOpenConfirm, setHasOpenConfirm] = useState(false);
    const [hasOpenConfirmStripe, setHasOpenConfirmStripe] = useState(false);
    const [hasConfirmActive, setHasConfirmActive] = useState(false);
    const [hasDeActive, setHasDeActive] = useState(false);
    const [paypalConnected, setPaypalConnected] = useState(null);
    const [stripeConnected, setStripeConnected] = useState(null);
    const [typeAdd, setTypeAdd] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openCountry, setOpenCountry] = useState(false);

    const getIntegrationList = async () => {
        try {
            const res = await paymentService.getIntegrationList(params?.id);
            if (res?.data) {
                setPaypalConnected(res?.data?.items?.find(item => item.type === PaymentGatewaysType.paypal));
                setStripeConnected(res?.data?.items?.find(item => item.type === PaymentGatewaysType.stripe));
            }

        } catch (error) {

        }
    }

    const handleConfirm = async (value) => {
        if (!value) {
            setHasOpenConfirm(false);
            setHasOpenConfirmStripe(false);
            return;
        }
        if (typeAdd === PaymentGatewaysType.stripe) {
            setHasOpenConfirmStripe(false);
            return setOpenCountry(true);
        }
        setLoading(true);
        try {
            await paymentService.onboardIntegration(params.id, { type: typeAdd });
            getIntegrationList();
        } catch (error) {
        } finally {
            setLoading(false);
            setHasOpenConfirm(false);
            setHasOpenConfirmStripe(false);
        }
    }

    const handleConfirmActive = async (value) => {
        if (!value) {
            setHasConfirmActive(false);
            setHasDeActive(false);
            return;
        }
        try {
            let enabled = false;
            if (hasConfirmActive) {
                enabled = true;
            }
            const integration_id = typeAdd == PaymentGatewaysType.stripe ? stripeConnected.id : paypalConnected.id;
            await paymentService.updateIntegration(params.id, { integration_id, enabled });
            Toastify.success();
        } catch (error) {
            Toastify.error();
        } finally {
            setHasConfirmActive(false);
            setHasDeActive(false);
            setTypeAdd(null);
            getIntegrationList();
        }
    }

    const reconnected = async (value) => {
        try {
            setLoading(true);
            const res = await paymentService.onboardIntegration(params.id, { type: PaymentGatewaysType.stripe });
            window.location.href = res?.data?.data?.account_link?.url;
            setLoading(false);

        } finally {
            setLoading(false);
        }
    }

    const onDashboard = async () => {
        try {
            setLoading(true);
            const res = await paymentService.getDashboardStripe(params.id, { type: PaymentGatewaysType.stripe });
            window.open(res?.data?.url, '_blank')
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (params?.id) {
            getIntegrationList();
        }

    }, [params?.id])

    return (<>
        <Card>
            <div className='card-header'>
                <h2>{translator('E_COMMERCE.Payment_Gateways')}</h2>
            </div>
            <div className='paymentGateways card-content'>
                <div className="paymentGateways-item">
                    <PaymentMethod label={translator('E_COMMERCE.Connect')} brand={paypal} type={PaymentGatewaysType.paypal} data={paypalConnected}
                        onAdd={async (value, isReconnected) => {
                            setHasOpenConfirm(true);
                            setTypeAdd(value);
                        }}
                        onActive={(value) => {
                            setHasConfirmActive(true);
                            setTypeAdd(value);
                        }}
                        onDeActive={(value) => {
                            setHasDeActive(true);
                            setTypeAdd(value);
                        }}
                        onDashboard={() => { }}
                    />

                </div>
                <div className="paymentGateways-item ">
                    <PaymentMethod label={translator('E_COMMERCE.Connect')} brand={stripe} type={PaymentGatewaysType.stripe} data={stripeConnected}
                        loading={loading}
                        onAdd={(value, isReconnected) => {
                            if (isReconnected) {
                                reconnected(value)
                            } else {
                                setHasOpenConfirmStripe(true);
                                setTypeAdd(value);
                            }
                        }}
                        onActive={(value) => {
                            setHasConfirmActive(true);
                            setTypeAdd(value);
                        }}
                        onDeActive={(value) => {
                            setHasDeActive(true);
                            setTypeAdd(value);
                        }}
                        onDashboard={onDashboard}
                    />
                </div>
            </div>
        </Card>
        <DialogComponent
            isOpen={hasOpenConfirmStripe}
            title={translator('E_COMMERCE.STRIPE_FEES_TERMS')}
            onCloseModal={() => {
                setHasOpenConfirmStripe(false);
            }}
            classNameHeader="border-b px-6 py-4"
            styles="max-w-xl !rounded-md !p-0"
            child={<ConfirmAddPayment isLoading={loading} onConfirm={handleConfirm} onClose={() => setHasOpenConfirmStripe(false)} />}
        />
        {hasOpenConfirm ? <ConfirmModal
            title={`${translator('E_COMMERCE.Confirm_Add_Payment')}`}
            textConfirm={translator('CONFIRM')}
            isOpen={hasOpenConfirm}
            onClose={handleConfirm}
            isLoading={loading}
        >
            <div dangerouslySetInnerHTML={{ __html: translator('E_COMMERCE.Payment_information') }}></div>
        </ConfirmModal> : null}
        {hasConfirmActive || hasDeActive ? <ConfirmModal
            title={`${hasDeActive ? translator('DEACTIVATE') : translator('ACTIVATE')}`}
            textConfirm={hasDeActive ? translator('DEACTIVATE') : translator('ACTIVATE')}
            isOpen={hasConfirmActive || hasDeActive}
            onClose={handleConfirmActive}
        >
            <div dangerouslySetInnerHTML={{ __html: hasDeActive ? translator('E_COMMERCE.Confirm_DeActive') : translator('E_COMMERCE.Confirm_Active') }}></div>
        </ConfirmModal> : null}
        {openCountry ?
            <DialogComponent
                isOpen={openCountry}
                onCloseModal={() => setOpenCountry(false)}
                title={translator('E_COMMERCE.Connect_Stripe')}
                styles="max-w-[480px] my-0 rounded-lg px-4 pb-4"
                isShowClose={true}
                child={
                    <>
                        <div className="flex flex-col gap-4">
                            <p className="text-sm mt-1">{translator('E_COMMERCE.SELECT_COUNTRY')}<sup className="text-red-500">*</sup></p>
                            <SelectField
                                control={control}
                                isRequired
                                label={''}
                                name="country"
                                placeholder={translator('Select Country')}
                                isSearchable={false}
                                options={COUNTRIES}
                                customStyles={{
                                    control: (base) => {
                                        return { ...base, minHeight: '0px', height: 'auto' }
                                    },
                                    valueContainer: (base) => {
                                        return { ...base, paddingTop: 0, fontSize: 13, fontWeight: 600 }
                                    },
                                    indicatorsContainer: (base) => {
                                        return { ...base, padding: '0px 8px', height: '30px' }
                                    },
                                }}
                            />
                            <div className="flex justify-center gap-4">

                                <ButtonOutline disabled={false} type={'button'} onClick={() => setOpenCountry(false)}>
                                    {translator('CANCEL')}
                                </ButtonOutline>

                                <ButtonPrimary disabled={!watch('country') || loading} type={'button'} onClick={async () => {
                                    try {
                                        setLoading(true);
                                        const res = await paymentService.onboardIntegration(params.id, { type: typeAdd, country: watch('country')?.value });
                                        window.location.href = res?.data?.data?.account_link?.url;
                                    } catch (error) {
                                        Toastify.error(error?.error?.message || 'Connected to Stripe failed!');
                                    } finally {
                                        setLoading(false);
                                    }
                                }}>
                                    {translator('Next')}
                                </ButtonPrimary>
                            </div>
                        </div>
                    </>
                }
            /> : null
        }
    </>)
}