export const DownloadIcon = ({
    width = '18',
    height = '18',
}: {
    fill?: string;
    width?: string;
    height?: string;
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.9999 2C12.5522 2 12.9999 2.44772 12.9999 3V13.5858L15.2928 11.2929C15.6833 10.9024 16.3165 10.9024 16.707 11.2929C17.0975 11.6834 17.0975 12.3166 16.707 12.7071L12.707 16.7071C12.3165 17.0976 11.6833 17.0976 11.2928 16.7071L7.2928 12.7071C6.90227 12.3166 6.90227 11.6834 7.2928 11.2929C7.68332 10.9024 8.31648 10.9024 8.70701 11.2929L10.9999 13.5858V3C10.9999 2.44772 11.4476 2 11.9999 2ZM4.9999 17C5.55219 17 5.9999 17.4477 5.9999 18V20H17.9999V18C17.9999 17.4477 18.4476 17 18.9999 17C19.5522 17 19.9999 17.4477 19.9999 18V20C19.9999 21.1046 19.1045 22 17.9999 22H5.9999C4.89533 22 3.9999 21.1046 3.9999 20V18C3.9999 17.4477 4.44762 17 4.9999 17Z"
                fill='currentColor'
            />
        </svg>
    );
};
