import { CloudUploadIcon } from 'assets/icon';
import { useCallback, useRef, useState } from 'react';
import File from './File';
import { useTranslation } from 'react-i18next';
import './UploadFile.scss';
import { useDropzone } from 'react-dropzone';
import { REPORT_STATUS } from 'components/Workspace/Course/CourseBuilder/components/CourseReport/constant';

export default function UploadFile({ file, setFile, progressInfo, accept_file_upload, onReload, status = undefined, setProgressInfo }) {
    const refUpload: any = useRef();
    const { t: translator } = useTranslation();
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
    };

    const onDrop = useCallback((acceptedFiles: Array<File>) => {
        onChangeUpload(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const onChangeUpload = (arrFile) => {
        if (status === REPORT_STATUS.released) return;
        const fileUpload = arrFile?.[0];
        const filesize = fileUpload?.size / (1024 * 1024);
        if (!accept_file_upload?.includes(fileUpload.type)) {
            fileUpload.error = translator('INVALID_FILE_TYPE');
        }
        if (filesize > 100) {
            fileUpload.error = translator('MAX_FILE_SIZE_100MB');
        }
        setFile(fileUpload);
        setProgressInfo({
            progress: 0,
            error: '',
        })
    };

    return (
        <div {...getRootProps()} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag}>
            <div className="UploadFile">
                <label className="UploadFile-label">
                    {translator('REPORT_FILE')} <span className="required-text">*</span>
                </label>
                {!file && (
                    <div className="UploadFile-upload" onClick={() => refUpload?.current?.click()}>
                        <div className="UploadFile-upload--border">
                            <CloudUploadIcon className="icon-upload" />
                            <div className="text-upload">
                                <span  className="text-link">
                                    {translator('CLICK_TO_UPLOAD')}
                                </span>{' '}
                                {translator('DRAG_AND_DROP')}
                            </div>
                            <div className="text-information">
                                <p className="text-information--detail">{translator('LIMIT_FILE_TYPE')}</p>
                                <p className="text-information--detail">{translator('MAXIMUM_FILE_SIZE')}</p>
                            </div>
                        </div>
                    </div>
                )}
                {file && <File status={status} file={file} progressInfo={progressInfo} onDelete={() => setFile(undefined)} onReload={onReload} />}
                <input
                    {...getInputProps()}
                    ref={refUpload}
                    accept={accept_file_upload?.join(',')}
                    className="input mt-4"
                    type="file"
                    placeholder={''}
                    hidden
                    onChange={(e) => onChangeUpload(e.target?.files)}
                />
            </div>
        </div>
    );
}
