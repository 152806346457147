import React, { useRef } from 'react';
import dayjs from 'dayjs';
import ReactDatePicker from 'react-datepicker';
import { SessionDateFormat } from 'constant/util.const';

interface DatePickerProps {
    value: any;
    valueStartTime?: any;
    isOpeningCalendar: any;
    onChangeDate: (e: Date) => void;
    name: string;
    disabled?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = ({
    name,
    value: initValue,
    valueStartTime,
    isOpeningCalendar,
    onChangeDate,
    disabled
}) => {
    const dateRef = useRef(undefined);

    const handleSelect = (e: Date, event: any) => {
        dateRef.current.setMonth(0);
        dateRef.current.setDate(e.getDate());
        dateRef.current.setFullYear(e.getFullYear());
        dateRef.current.setMonth(e.getMonth());
        onChangeDate(dateRef.current);
    };

    const handleChange = (e: Date) => {
        const newDate = new Date(e.getTime());
        if (dateRef.current) {
            newDate.setMonth(0);
            newDate.setDate(dateRef.current.getDate());
            newDate.setFullYear(dateRef.current.getFullYear());
            newDate.setMonth(dateRef.current.getMonth());
        }
        dateRef.current = newDate;
        onChangeDate(newDate);
    };

    const handleFilterTime = (time: any) => {
        const currentDate = dayjs(valueStartTime);
        const selectedDate = new Date(time);

        if (dateRef.current) {
            selectedDate.setMonth(0);
            selectedDate.setDate(dateRef.current.getDate());
            selectedDate.setFullYear(dateRef.current.getFullYear());
            selectedDate.setMonth(dateRef.current.getMonth());
        }
        return dayjs(selectedDate).isAfter(currentDate);
    };

    const handleFilterDate = (date: any) => {
        const currentDate = dayjs(valueStartTime);
        const selectedDate = new Date(date);

        return (
            dayjs(selectedDate).isSame(currentDate, 'day') ||
            dayjs(selectedDate).isAfter(currentDate)
        );
    };

    return (
        <ReactDatePicker
            preventOpenOnFocus
            onSelect={handleSelect}
            onChange={handleChange}
            filterTime={
                name === 'endTime'
                    ? (time) => handleFilterTime(time)
                    : undefined
            }
            filterDate={
                name === 'endTime'
                    ? (time) => handleFilterDate(time)
                    : undefined
            }
            onCalendarOpen={() => {
                isOpeningCalendar.current = true;
            }}
            onCalendarClose={() => {
                isOpeningCalendar.current = false;
                dateRef.current = undefined;
            }}
            value={initValue}
            dateFormat={SessionDateFormat}
            className="cursor-pointer pl-[10px] py-[7px] w-full text-ooolab_1xs text-ooolab_dark_1 bg-transparent rounded font-normal"
            showTimeSelect
            shouldCloseOnSelect
            // portalId="custom-picker"
            popperClassName="top-level"
            disabled={disabled}
        />
    );
};

export default DatePicker;
