export const ShareIcon = ({ className = '' }: { className?: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 20 20" className={className}>
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M15 2.5a1.667 1.667 0 1 0 0 3.333A1.667 1.667 0 0 0 15 2.5m-3.333 1.667a3.333 3.333 0 1 1 6.666 0 3.333 3.333 0 0 1-6.666 0M5 8.333a1.667 1.667 0 1 0 0 3.334 1.667 1.667 0 0 0 0-3.334M1.667 10a3.333 3.333 0 1 1 6.666 0 3.333 3.333 0 0 1-6.666 0M15 14.167a1.667 1.667 0 1 0 0 3.333 1.667 1.667 0 0 0 0-3.333m-3.333 1.666a3.333 3.333 0 1 1 6.666 0 3.333 3.333 0 0 1-6.666 0"
                clipRule="evenodd"
            ></path>
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M6.438 10.839a.833.833 0 0 1 1.14-.3l5.692 3.316a.833.833 0 0 1-.84 1.44L6.74 11.978a.833.833 0 0 1-.3-1.14M13.562 5.005a.833.833 0 0 1-.3 1.14L7.578 9.46a.833.833 0 1 1-.84-1.44l5.684-3.316a.833.833 0 0 1 1.14.3"
                clipRule="evenodd"
            ></path>
        </svg>
    );
};
