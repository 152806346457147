import React from 'react';
import ManagementMasterPage from 'pages/RouteMasterPage';
import NewBookingClass from 'components/Management/BookingClass/Newbooking';
import ManagePermissionAdmin from 'pages/Management/ManagePermissionAdmin';

const NewBookingPage = () => {
    return (
        <ManagePermissionAdmin>
            <NewBookingClass>
                <ManagementMasterPage  />
            </NewBookingClass>
        </ManagePermissionAdmin>
    );
};

export default NewBookingPage;
