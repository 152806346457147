import React, { useEffect, useState } from 'react';
import { CloudCheckIcon } from 'assets/icon';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ButtonBack, ButtonPrimary, ConfirmModal, Toastify } from '_shared';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { LESSON_STATUS, LESSON_STATUS_CODE } from 'components/Library/LessonBuilder/constants';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { learningPathUpdate } from 'store/features/learningPathSlice';
import './LearningPathBuilderHeader.scss';
import { WorkspaceService } from 'services';
import { checkPermission } from 'utils/commonFun';

export const LearningPathBuilderHeader = () => {
    const dispatch = useAppDispatch();
    const { loading, data: details, error, changed, canSubmit, viewType, isNew } = useAppSelector((state) => state.learningPath);

    const { t: translator } = useTranslation();
    const params: any = useParams();
    const history = useHistory();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [hasContent, setHasContent] = useState(false);
    const [openWarningCreateEmpty, setOpenModalWarningCreateEmpty] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);

    const onClose = async (value) => {
        setIsLoading(true);
        if (value) {
            let status = LESSON_STATUS_CODE.draft;
            if (details?.status == LESSON_STATUS_CODE.draft) {
                status = LESSON_STATUS_CODE?.published;
            }

            const response = await dispatch(
                learningPathUpdate({
                    workspaceId: params?.id,
                    id: params?.learningPathId,
                    title: details?.title,
                    status,
                })
            );
            if (!response?.error) {
                Toastify.success(
                    translator('LEARNING_PATH.LEARNING_PATH_DETAIL.MESSAGE_LEARNING_PATH_UPDATE_SUCCESS', { status: details?.status == LESSON_STATUS_CODE.draft ? 'published' : 'unpublished' })
                );
            } else {
                Toastify.error(translator('LEARNING_PATH.LEARNING_PATH_DETAIL.MESSAGE_LEARNING_PATH_UPDATE_FAIL'));
            }
        }
        setIsLoading(false);
        setOpenConfirm(false);
    };

    useEffect(() => {
        setHasContent(false);
        if (details?.sections?.length > 0) {
            details?.sections?.forEach((item) => {
                if (item?.section_contents?.length > 0) {
                    setHasContent(true);
                    return;
                }
            });
        }
    }, [details]);

    return (
        <>
            <div className="learningPathBuilderHeader">
                <div className="flex-none w-[349px]">
                    <ButtonBack
                        onClick={() => {
                            if (!hasContent && isNew) {
                                setOpenModalWarningCreateEmpty(true);
                            } else {
                                history.push(`/workspace/${params?.id}/learning-path`);
                            }
                        }}
                    />
                </div>
                <div className="learningPathBuilderStatus">
                    <span
                        className={`learningPathBuilderStatus-label ${details?.status == LESSON_STATUS_CODE.published ? 'learningPathBuilderStatus-label--active' : ''
                            }`}
                    >
                        {translator(LESSON_STATUS[details?.status])}
                    </span>
                    <span className="learningPathBuilderStatus-text">{details?.title}</span>
                </div>
                <div className="learningPathBuilderHeader-action">
                    <span className="text-gray-500">
                        {loading == 'pending' ? (
                            <CircleSpin className="!text-primary-500" />
                        ) : (
                            <span className={`${changed ? 'text-green-500' : ''}`}>
                                <CloudCheckIcon />
                            </span>
                        )}
                    </span>
                    <div className="learningPathBuilderHeader-line"></div>
                    <ButtonPrimary
                        type="button"
                        className="whitespace-nowrap"
                        onClick={() => setOpenConfirm(true)}
                        disabled={loading == 'pending' || !hasContent || !canSubmit || !checkPermission('learningPath', 'U')}
                    >
                        {translator(details?.status == LESSON_STATUS_CODE.draft ? 'PUBLISH' : 'REVERT_TO_DRAFT')}
                    </ButtonPrimary>
                </div>
            </div>
            <ConfirmModal
                title={translator('LEARNING_PATH.LEARNING_PATH_DETAIL.' + (details?.status == LESSON_STATUS_CODE.draft ? 'PUBLISH_LEARNING_PATH' : 'CONFIRM_UNPUBLISH'))}
                isOpen={openConfirm}
                onClose={onClose}
                textConfirm={translator(details?.status == LESSON_STATUS_CODE.draft ? 'PUBLISH' : 'UNPUBLISH')}
                textCancel={translator(details?.status == LESSON_STATUS_CODE.draft ? 'CANCEL' : 'DISCARD_CHANGES')}
                isLoading={isLoading}
            >
                <p className="text-sm">
                    {translator('LEARNING_PATH.LEARNING_PATH_DETAIL.' + (details?.status == LESSON_STATUS_CODE.draft ? 'MESSAGE_LEARNING_PATH_PUBLISHED' : 'MESSAGE_LEARNING_PATH_REVERT_TO_DRAFT'))}
                </p>
            </ConfirmModal>
            <ConfirmModal
                title={translator('LEARNING_PATH.INFORMATION')}
                isOpen={openWarningCreateEmpty}
                onCloseX={() => {
                    setOpenModalWarningCreateEmpty(false);
                }}
                onClose={async (value) => {
                    setOpenModalWarningCreateEmpty(false);
                    if (!value) {
                        await WorkspaceService.deleteLearningPathsService(params?.id, details?.id)
                            .then((res) => { })
                            .catch(() => { });
                        history.push(`/workspace/${params?.id}/learning-path`);
                    } else history.push(`/workspace/${params?.id}/learning-path`);
                }}
                textConfirm={translator('LEARNING_PATH.SAVE')}
                textCancel={translator('LEARNING_PATH.DISCARD')}
            >
                <p className="text-sm">{translator('LEARNING_PATH.MSG_WARNING_LEAVE')}</p>
            </ConfirmModal>
        </>
    );
};
