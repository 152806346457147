import React from 'react';
import TrashBinMyDrive from 'components/Workspace/TrashBin/TrashBinMyDrive';
import RouteMasterPage from 'pages/RouteMasterPage';

const TrashBinMyDrivePage = () => {
    return (
        <TrashBinMyDrive>
            <RouteMasterPage />
        </TrashBinMyDrive>
    );
};

export default TrashBinMyDrivePage;
