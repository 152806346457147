import { ButtonOutline, ButtonPrimary, Toastify } from '_shared'
import DialogComponent from 'components/Dialog/DialogComponent'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';
import { USER_STATUS } from '../constant';

export default function ModalActivateMember({isOpen, onClose, data, refreshData}) {
  const {t: translator} = useTranslation();
  const params: {id} = useParams();
  const workspaceId = params?.id;

  const onUpdateMemberStatus = (value) => {
    workspaceService.updateWorkspacePartialMember(workspaceId, data?.membership?.user_id, {status: value})
        .then((res) => {
            refreshData();
            Toastify.success();
        })
        .catch(() => Toastify.error())
        .finally(() => onClose())
  };

  return (
    <DialogComponent
        title={
            <div className="flex gap-1 items-center text-orange-500">
                <div className="text-base text-gray-800">{translator('ACTIVATE')}</div>
            </div>
        }
        isOpen={isOpen}
        onCloseModal={onClose}
        child={
            <div className="flex flex-col">
                <div className="mt-2 text-sm text-gray-800">
                  {translator('CONFIRM_ACTIVATE_MEMBER')}
                </div>
                <div className="flex justify-center gap-4 mt-6 w-full">
                    <ButtonOutline type="button" onClick={onClose}>
                        {translator('CANCEL')}
                    </ButtonOutline>
                    <ButtonPrimary type="button" onClick={() => onUpdateMemberStatus(USER_STATUS.ACTIVE)}>
                        {translator('ACTIVATE')}
                    </ButtonPrimary>
                </div>
            </div>
        }
    />
  )
}
