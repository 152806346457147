import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Label, TagList, UserAvatarInfo } from '_shared';
import { Settings } from 'components/Library/Settings/Settings';
import workspaceService from 'services/workspace.service';

import './AssignmentInformation.scss';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { useAppSelector, useAppDispatch } from 'hooks/hooks';
import { assignmentUpdate } from 'store/features/assignmentSlice';
import { checkPermission, convertHourMinsToSecond, convertSecondToHourMins } from 'utils/commonFun';
import { InputTimeField } from 'components/InputForm/InputForm';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ExternalLinkIcon } from '@heroicons/react/outline';

export const AssignmentInformation = () => {
    const { loading, data: details, error } = useAppSelector((state) => state.assignment);
    const dispatch = useAppDispatch();
    const params: { id: string; assignmentId: string } = useParams();
    const { t: translator } = useTranslation();
    const [isOpenSettings, setIsOpenSettings] = useState(false);
    const [customFields, setCustomFields] = useState([]);
    const userInformation = JSON.parse(localStorage.getItem('user-data'));
    const [isLoadingTags, setIsLoadingTags] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        clearErrors,
    } = useForm({
        mode: 'onBlur',
        defaultValues: useMemo(() => {
            return {
                title: details?.title,
                time_limit: details?.time_limit,
            };
        }, [details?.title]),
    });

    const handleUpdateTag = async (value) => {
        setCustomFields(value);
        await workspaceService.updateCustomFieldByTypeService(params.id, {
            custom_fields: value.map((item) => ({ custom_field_id: item.id, ...item })),
            id: params.assignmentId,
            type: 'exercises',
        });
    };

    const getTags = async () => {
        setIsLoadingTags(true);
        const response: any = await workspaceService.getCustomFieldByTypeService(params.id, {
            order: 'desc',
            sort_by: 'created_at',
            skip_pagination: true,
            type: 'exercises',
            id: params?.assignmentId,
        });
        if (response) {
            setCustomFields(response?.data || []);
        }
        setIsLoadingTags(false);
    };

    const onSubmit = async (data) => {
        await dispatch(
            assignmentUpdate({
                workspaceId: params?.id,
                assignmentId: params?.assignmentId,
                ...data,
                time_limit: convertHourMinsToSecond(data?.time_limit),
            })
        );
    };

    const watchTitle: any = watch('title');

    useEffect(() => {
        if (params?.assignmentId !== 'new') {
            getTags();
        }
    }, [params?.assignmentId]);

    useEffect(() => {
        setValue('title', details?.title || '');
        setValue('time_limit', convertSecondToHourMins(details?.time_limit))
    }, [details]);

    const handleEmptyTitle = () => {
        if (!watch('title')) {
            setValue('title', details?.title);
            clearErrors('title');
        }
    }

    return (
        <div className="">
            <form
                onSubmit={(e) => handleSubmit(onSubmit)(e).finally(() => handleEmptyTitle())}
            >
                <div className="informationAssignment-item">
                    <Label className="informationAssignment-label !text-xs">{translator('Assignment Name')}</Label>
                    <div>
                        <input
                            id="title"
                            placeholder={translator('Enter Assignment Name')}
                            {...register('title', {
                                required: true,
                                maxLength: 255,
                                onBlur: (e) => handleSubmit(onSubmit)(e).finally(() => handleEmptyTitle()),
                            })}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSubmit(onSubmit)(e).finally(() => handleEmptyTitle());
                                }
                            }}
                            className="informationAssignment-input"
                            style={{ width: watchTitle?.length + 'ch' }}
                            maxLength={255}
                            disabled={!checkPermission('libraryContentAssignment', 'U')}
                        />
                    </div>
                    {errors.title && errors.title.type === 'required' && (
                        <span className="errorTitle">{translator('Assignment Name is required')}</span>
                    )}
                    {errors.title && errors.title.type === 'maxLength' && (
                        <span className="errorTitle">{translator('Max length exceeded')}</span>
                    )}
                </div>
                <div className="informationAssignment-item">
                    <Label className="informationAssignment-label !text-xs mb-0.5">{translator('Owner')}</Label>
                    <UserAvatarInfo userInformation={details?.created_by || userInformation} />
                </div>
                <div className="informationAssignment-item pt-3 border-t border-gray-300 flex items-start justify-between">
                    <div className='flex-1'>
                        <Label className="informationAssignment-label">{translator('TIME_LIMIT')}</Label>
                        <p className='text-[10px] text-gray-500'>{translator('TIME_LIMIT_MESSAGE')}</p>
                    </div>
                    <InputTimeField
                        placeholder={'hh:mm'}
                        name="time_limit"
                        disabled={false}
                        errors={errors}
                        className='max-w-[116px] flex flex-col items-end'
                        classNameInput='max-w-[96px]'
                        classError='!mt-0'
                        positionIcon='right'
                        register={register}
                        messageError={translator('FORMAT_IS_INCORRECT')}
                        onBlur={handleSubmit(onSubmit)}
                    />
                </div>
            </form>
            <div className="informationAssignment-disclosure">
                <Disclosure defaultOpen>
                    {({ open }) => (
                        <>
                            <div className="flex items-center justify-between mb-0.5">
                                <Label className="informationAssignment-label">{translator('ASSOCIATED_LESSON_S')}</Label>
                                <Disclosure.Button className="">
                                    {/* <ChevronDownIcon className={`h-3.5 w-3.5 text-gray-500 ${open ? '-rotate-90 transform' : ''}`} /> */}
                                </Disclosure.Button>
                            </div>
                            <Disclosure.Panel className="">
                                <p className="text-gray-500 text-xs">{translator(!!details?.associatedLessons?.length ? 'ASSOCIATED_LESSON_MESSAGE' : 'ASSOCIATED_LESSON_NO_DATA')}</p>
                                {!!details?.associatedLessons?.length && (
                                    <div className="mt-1 flex flex-col space-y-1 max-h-[150px] overflow-auto">
                                        {details?.associatedLessons.map((item, index) => (
                                            <p 
                                                key={index} 
                                                className={`text-xs font-semibold flex items-center space-x-1 ${checkPermission('libraryContentLessonsOrScorm', 'U') ? 'text-enterprise cursor-pointer' : 'text-gray-800'}`} 
                                                onClick={() => {
                                                    if (checkPermission('libraryContentLessonsOrScorm', 'U')) {
                                                        window.open(`/workspace/${params?.id}/lesson/${item?.id}`, '_blank');
                                                    }
                                                }}
                                            >
                                                <span className="mb-0.5">●</span>
                                                <span>{item?.title}</span>
                                            </p>
                                        ))}
                                    </div>
                                )}
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            </div>
            <div className='informationAssignment-tags'>
                <div className="flex justify-between items-center mb-0.5">
                    <Label className="informationAssignment-label">{translator('CUSTOM_TAGS')}</Label>
                    <div className='flex-none'>
                        <button 
                            onClick={() => setIsOpenSettings(true)} 
                            disabled={!checkPermission('libraryContentSetting', 'C')}
                            className="text-xs text-enterprise flex items-center space-x-1"
                        >
                            {translator(customFields?.length > 0 ? 'EDIT' : 'ADD_NEW')}
                            <ExternalLinkIcon className="text-enterprise w-4 h-4" />
                        </button>
                    </div>
                </div>
                <div className={`informationAssignment-info ${customFields?.length > 0 ? 'informationAssignment-info--hasData' : ''}`}>
                    {isLoadingTags ? (
                        <div className="informationAssignment-loading">
                            <CircleSpin className="text-primary-500" />
                        </div>
                    ) : customFields?.length > 0 ? (
                        <TagList
                            customFields={customFields}
                            setCustomFields={handleUpdateTag}
                            loading={false}
                            hasTitle={false}
                            className="noBackground"
                            isEdit={checkPermission('libraryContentAssignment', 'U')}
                        />
                    ) : (
                        <>
                            {translator('THERE_ARE_NO_CUSTOM_TAGS')}
                        </>
                    )}
                </div>
                <Settings
                    isOpen={isOpenSettings}
                    onClose={() => {
                        setIsOpenSettings(false);
                        getTags();
                    }}
                />
            </div>
        </div>
    );
};
