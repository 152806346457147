import { Tab } from '@headlessui/react';
import { useContext, useEffect, useState } from 'react';
import Info from './Info';
import Member from './members';
import Enrollment from './enrollment'
import { useTranslation } from 'react-i18next';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { checkPermission } from 'utils/commonFun';

const listTab = ['INFORMATION', 'ENROLLMENT_', 'MEMBERS'];

const GroupDetail = ({ children }) => {
    const [data, setData] = useState<any>();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const params = useParams<{ id: string; groupId: string, tab?: string }>();
    const history = useHistory();
    const { t: translator } = useTranslation();
    const workspaceState = useContext(GetWorkspaceContext);
    const learningGroupName = workspaceState?.getWorkspaceDetailState?.currentRouteDetail?.[0]?.name || '';
    const search = useLocation()?.search;
    const tab = new URLSearchParams(search).get('tab');

    useEffect(() => {
        if (tab === 'enrollment') setSelectedIndex(1);
    }, [])

    return (
        <>
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT')}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    { name: translator('DASHBOARD.SIDEBAR.LEARNING_GROUP'), href: `/workspace/${params?.id}/management/learning-groups`, className: '' },
                    { name: learningGroupName, href: ``, className: '' },
                ]}
            />
            <Tab.Group defaultIndex={selectedIndex} selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                <Tab.List className="w-full min-h-ooolab_h_18 text-ooolab_xs p-ooolab_p_5 grid grid-cols-5 items-center border-b border-ooolab_bar_color">

                    {checkPermission('learningGroupDetails', 'R') && listTab.map((i: string, index) => (
                        <Tab
                            key={index}
                            className={({ selected }) => {
                                const tabClassName = `col-span-1 px-ooolab_p_7 py-ooolab_p_2 text-center font-semibold capitalize ${selected ? 'text-ooolab_dark_1 bg-ooolab_light_100' : 'text-ooolab_dark_2'
                                    }`;
                                return tabClassName;
                            }}
                            onClick={() => history.push(`/workspace/${params?.id}/management/learning-groups/${params?.groupId}?tab=${i}`)}
                        >
                            {translator(i)}
                        </Tab>
                    ))}

                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>
                        {checkPermission('learningGroupDetailsInformation', 'R') && <Info setData={setData} />}
                    </Tab.Panel>
                    <Tab.Panel>
                        {checkPermission('learningGroupDetailsEnrollment', 'R') && <Enrollment />}
                    </Tab.Panel>
                    <Tab.Panel>
                        {checkPermission('learningGroupDetailsMembers', 'R') && <Member data={data} />}
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </>

    );
};

export default GroupDetail;
