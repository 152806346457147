import React from 'react'

export default function PlayVideoIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_11126_18102)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99967 2.49967C5.85754 2.49967 2.49967 5.85754 2.49967 9.99967C2.49967 14.1418 5.85754 17.4997 9.99967 17.4997C14.1418 17.4997 17.4997 14.1418 17.4997 9.99967C17.4997 5.85754 14.1418 2.49967 9.99967 2.49967ZM0.833008 9.99967C0.833008 4.93706 4.93706 0.833008 9.99967 0.833008C15.0623 0.833008 19.1663 4.93706 19.1663 9.99967C19.1663 15.0623 15.0623 19.1663 9.99967 19.1663C4.93706 19.1663 0.833008 15.0623 0.833008 9.99967Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.94012 5.93161C8.21108 5.7866 8.53987 5.80249 8.79558 5.97297L13.7956 9.3063C14.0274 9.46086 14.1667 9.72105 14.1667 9.99968C14.1667 10.2783 14.0274 10.5385 13.7956 10.6931L8.79558 14.0264C8.53987 14.1969 8.21108 14.2128 7.94012 14.0677C7.66915 13.9227 7.5 13.6403 7.5 13.333V6.66634C7.5 6.35901 7.66915 6.07663 7.94012 5.93161ZM9.16667 8.22344V11.7759L11.831 9.99968L9.16667 8.22344Z" fill="currentColor"/>
    </g>
    <defs>
    <clipPath id="clip0_11126_18102">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
    </svg>

  )
}
