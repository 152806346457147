import { TrashIcon, XIcon } from '@heroicons/react/outline';
import { CopyFileIcon } from 'assets/icon/CopyFileIcon';
import { DownloadIcon } from 'assets/icon/DownloadIcon';
import { MoveIcon } from 'assets/icon/MoveIcon';
import { Dispatch, SetStateAction, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { LibraryTypeEnum, downloadMultipleFiles, onDownload } from '../../LibraryContants';
import { checkPermission } from 'utils/commonFun';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';

interface SelectedFilesProps {
    list: any[];
    onClose: () => void;
    onDelete: () => void;
    onDuplicate: () => void;
    setLoadingDownload: Dispatch<SetStateAction<boolean>>;
    onMoveFile: () => void;
}

const SelectedFiles = ({ list, onClose, onDelete, onDuplicate, setLoadingDownload, onMoveFile }: SelectedFilesProps) => {
    const params: any = useParams();
    const { t } = useTranslation();

    const { getWorkspaceDetailState: { featureFlags } } = useContext(GetWorkspaceContext);

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`LIBRARY.${str}`, opt));

    const onDisableDownload = useMemo(() => {
        const folderType = list?.find((item) => item?.object_type === LibraryTypeEnum.folder);
        return folderType ? true : false;
    }, [list]);

    const isScorm = useMemo(() => {
        const hasScorm = list?.find((item) => item?.sub_object_type === "scorm");
        return hasScorm ? true : false;
    }, [list]);

    return (
        <>
            <div className="w-full flex flex-wrap gap-5 items-center">
                <button type="button" onClick={onClose}>
                    <XIcon className="w-5 h-5 text-gray-500" />
                </button>
                <div className="text-sm font-normal text-gray-800">{`${list?.length} ${translator('FILE(S)_SELECTED')}`}</div>
                {!isScorm && featureFlags?.systemDownload ? <button
                    type="button"
                    onClick={() => {
                        if (list?.length === 1) {
                            onDownload(list[0], params, setLoadingDownload);
                        } else {
                            downloadMultipleFiles(list, params, setLoadingDownload);
                        }
                    }}
                    disabled={onDisableDownload}
                    className={`disabled:cursor-not-allowed ${(onDisableDownload || !checkPermission('libraryContent', 'DL')) ? 'hidden' : ''}`}
                >
                    <DownloadIcon />
                </button> : null}
                <button type="button" onClick={onMoveFile} className={`disabled:cursor-not-allowed ${!checkPermission('libraryContent', 'U') ? 'hidden' : ''}`}>
                    <MoveIcon />
                </button>

                {!isScorm ? <button type="button" onClick={onDuplicate} className={`disabled:cursor-not-allowed ${!checkPermission('libraryContent', 'DC') ? 'hidden' : ''}`}>
                    <CopyFileIcon />
                </button> : null}

                <button type="button" onClick={onDelete} className={`disabled:cursor-not-allowed ${!checkPermission('libraryContent', 'D') ? 'hidden' : ''}`}>
                    <TrashIcon className="w-[18px] h-[18px] text-gray-700" />
                </button>
            </div>
        </>
    );
};

export default SelectedFiles;
