import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TableReward from '../RewardComponents/TableReward';
import NoContent, { NoContentWithImage } from 'components/NoContent/NoContent';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { useParams } from 'react-router-dom';
import { RewardsContext } from 'contexts/Rewards/RewardsContext';
import rewardMiddleware from 'middleware/reward.middleware';
import { RewardsType } from 'types/Rewards.type';
import TablePagination from 'components/Pagination';

const RewardFeatures: React.FC = ({ children }) => {
    const { t: translator } = useTranslation();

    const params: { id: string } = useParams();

    const [dataList, setDataList] = useState<RewardsType>();
    const { dispatch: dispatchRewards, rewardsState } = useContext(
        RewardsContext
    );
    const { listRewardsFutured, isLoading } = rewardsState;

    useEffect(() => {
        rewardMiddleware.getListRewardFutured(params?.id, dispatchRewards, {
            is_featured: true,
        });
    }, []);

    useEffect(() => {
        if (listRewardsFutured?.items) {
            setDataList(listRewardsFutured);
        }
    }, [listRewardsFutured]);

    const handleChangePagination = (e: number) => {
        rewardMiddleware.getListRewardFutured(params?.id, dispatchRewards, {
            page: e,
            is_featured: true,
        });
    };

    return (
        <div>
            <div className="z-1 h-full outline-none">
                {isLoading ? (
                    <div className="flex items-center justify-center mt-6">
                        <CircleSpin color="text-primary-500" />
                    </div>
                ) : dataList?.items?.length ? (
                    <>
                        <div className="h-[calc(100vh-175px)] max-w-full overflow-x-auto custom-scrollbar overflow-y-scroll">
                            <TableReward data={dataList?.items} />
                        </div>
                        <div className="flex justify-between opacity-100 mx-3 border-t">
                            <div className="">
                                <p className="text-ooolab_dark_2 text-xs font-semibold leading-ooolab_24px pt-2">
                                    Showing{' '}
                                    {dataList.page * dataList.per_page -
                                        dataList.per_page +
                                        1}
                                    -
                                    {dataList?.items?.length +
                                        dataList.per_page *
                                            (dataList.page - 1)}{' '}
                                    of {dataList.total}
                                </p>
                            </div>
                            <div className="">
                                <TablePagination
                                    onClickPagination={handleChangePagination}
                                    perPage={dataList?.per_page || 1}
                                    total={dataList?.total || 1}
                                    forcePage={(dataList?.page || 1)- 1}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <NoContentWithImage content={translator("REWARD.NO_REWARDS")} />
                )}
            </div>
        </div>
    );
};

export default RewardFeatures;
