import React from 'react'

export default function PdfIcon({className = ''}) {
  return (
    <svg className={className} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 0C6.125 0 5 1.125 5 2.5V37.5C5 38.875 6.125 40 7.5 40H32.5C33.875 40 35 38.875 35 37.5V10L25 0H7.5Z" fill="#EF4444"/>
      <path d="M9.43848 27.8571V19.8571H12.8328C13.3051 19.8571 13.7318 19.9714 14.1128 20.2C14.5013 20.4209 14.8061 20.7257 15.027 21.1143C15.2556 21.5028 15.3699 21.9409 15.3699 22.4286C15.3699 22.9238 15.2556 23.3695 15.027 23.7657C14.8061 24.1619 14.5013 24.4781 14.1128 24.7143C13.7318 24.9428 13.3051 25.0571 12.8328 25.0571H11.1528V27.8571H9.43848ZM11.1299 23.5143H12.6385C12.8213 23.5143 12.9851 23.4686 13.1299 23.3771C13.2747 23.2781 13.389 23.1486 13.4728 22.9886C13.5642 22.8286 13.6099 22.6457 13.6099 22.44C13.6099 22.2343 13.5642 22.0552 13.4728 21.9028C13.389 21.7428 13.2747 21.6209 13.1299 21.5371C12.9851 21.4457 12.8213 21.4 12.6385 21.4H11.1299V23.5143Z" fill="white"/>
      <path d="M16.905 27.8571V19.8571H20.185C20.7717 19.8571 21.3012 19.9562 21.7736 20.1543C22.2536 20.3448 22.665 20.619 23.0079 20.9771C23.3583 21.3276 23.625 21.7467 23.8079 22.2343C23.9907 22.7219 24.0821 23.2628 24.0821 23.8571C24.0821 24.4438 23.9907 24.9848 23.8079 25.48C23.625 25.9676 23.3621 26.3905 23.0193 26.7486C22.6764 27.099 22.265 27.3733 21.785 27.5714C21.305 27.7619 20.7717 27.8571 20.185 27.8571H16.905ZM18.6193 26.6114L18.4364 26.3143H20.1279C20.4707 26.3143 20.7793 26.2571 21.0536 26.1428C21.3279 26.0209 21.5602 25.8571 21.7507 25.6514C21.9488 25.4381 22.0974 25.179 22.1964 24.8743C22.2955 24.5695 22.345 24.2305 22.345 23.8571C22.345 23.4838 22.2955 23.1486 22.1964 22.8514C22.0974 22.5467 21.9488 22.2876 21.7507 22.0743C21.5602 21.8609 21.3279 21.6971 21.0536 21.5828C20.7793 21.4609 20.4707 21.4 20.1279 21.4H18.4021L18.6193 21.1257V26.6114Z" fill="white"/>
      <path d="M25.6327 27.8571V19.8571H27.347V27.8571H25.6327ZM26.4212 24.68V23.2057H30.5584V24.68H26.4212ZM26.4212 21.4V19.8571H31.0384V21.4H26.4212Z" fill="white"/>
      <path d="M27.5 10H35L25 0V7.5C25 8.875 26.125 10 27.5 10Z" fill="#FEE2E2"/>
      <path d="M35 17.5L27.5 10H35V17.5Z" fill="#EF4444"/>
    </svg>
  )
}
