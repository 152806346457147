import CheckboxComponent from 'components/Checkbox/CheckboxComponent';
import TimeSlotComponent from 'components/Dashboard/util/TimeSlot';
import DatePickerInput from 'components/Management/components/Form/DatePicker';
import { SelectField } from 'components/Select';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from 'utils/commonFun';
import './AddSlot.scss';
import { SwitchToggle } from '_shared/components/Form/SwitchToggle/SwitchToggle';
import { CircleInformationIcon } from 'assets/icon';
import ReactTooltip from 'react-tooltip';
import { useParams } from 'react-router-dom';
import courseService from 'services/course.service';
import { getDateForDayOfWeek } from 'utils/handleFormatTime';
const REPEAT_TYPE = [
    {
        value: 'weekly',
        label: 'Weekly',
    },
    {
        value: 'bi_weekly',
        label: 'Bi-weekly',
    },
];

export default function AddSlot({ control, register, errors, watch, setValue, fields, update, timeRange, getValues, replace }) {
    const { t } = useTranslation();
    const params: any = useParams();
    const timeRangeConst: any = [
        {
            weekday: 1,
            label: t('WEEK.MONDAY'),
            value: false,
            start_time: '',
            end_time: '',
            disable: true,
            fieldName: 'mon',
            timeSlot: [
                {
                    weekdayChild: 1,
                    slot: 0,
                    slotStartTime: '',
                    slotEndTime: '',
                },
            ],
        },
        {
            weekday: 2,
            label: t('WEEK.TUESDAY'),
            value: false,
            start_time: '',
            end_time: '',
            disable: true,
            fieldName: 'tue',
            timeSlot: [
                {
                    weekdayChild: 2,
                    slot: 0,
                    slotStartTime: '',
                    slotEndTime: '',
                },
            ],
        },
        {
            weekday: 3,
            label: t('WEEK.WEDNESDAY'),
            value: false,
            start_time: '',
            end_time: '',
            disable: true,
            fieldName: 'wed',
            timeSlot: [
                {
                    weekdayChild: 3,
                    slot: 0,
                    slotStartTime: '',
                    slotEndTime: '',
                },
            ],
        },
        {
            weekday: 4,
            label: t('WEEK.THURSDAY'),
            value: false,
            start_time: '',
            end_time: '',
            disable: true,
            fieldName: 'thu',
            timeSlot: [
                {
                    weekdayChild: 4,
                    slot: 0,
                    slotStartTime: '',
                    slotEndTime: '',
                },
            ],
        },
        {
            weekday: 5,
            label: t('WEEK.FRIDAY'),
            value: false,
            start_time: '',
            end_time: '',
            disable: true,
            fieldName: 'fri',
            timeSlot: [
                {
                    weekdayChild: 5,
                    slot: 0,
                    slotStartTime: '',
                    slotEndTime: '',
                },
            ],
        },
        {
            weekday: 6,
            label: t('WEEK.SATURDAY'),
            value: false,
            start_time: '',
            end_time: '',
            disable: true,
            fieldName: 'sat',
            timeSlot: [
                {
                    weekdayChild: 6,
                    slot: 0,
                    slotStartTime: '',
                    slotEndTime: '',
                },
            ],
        },
        {
            weekday: 0,
            label: t('WEEK.SUNDAY'),
            value: false,
            start_time: '',
            end_time: '',
            disable: true,
            fieldName: 'sun',
            timeSlot: [
                {
                    weekdayChild: 0,
                    slot: 0,
                    slotStartTime: '',
                    slotEndTime: '',
                },
            ],
        },
    ];

    const [totalLesson, setTotalLesson] = useState(0);

    const formatWeekList = (start, end) => {
        if (start) {
            let count = 0;
            const diffStartToEnd = dayjs(end).diff(dayjs(start), 'day');
            const dayAvailable = [];
            let timeRange = [];
            const timeRangeValue = getValues('time_range')?.length > 0 ? getValues('time_range') : timeRangeConst;

            for (let i = 0; i <= diffStartToEnd; i++) {
                const nextDate = dayjs(start).add(i, 'day');
                dayAvailable.push(dayjs(nextDate)?.isoWeekday() - 1);
            }
            while (count <= 6) {
                let timeslot = timeRangeValue[count];
                if (dayAvailable.includes(count)) {
                    timeslot.disable = false;
                } else {
                    timeslot = timeRangeConst[count];
                    timeslot.disable = true;
                    timeslot.value = false;
                }
                timeRange.push(timeslot);
                count++;
            }
            replace(timeRange);
        }
    };

    useEffect(() => {
        if (watch('start_date') && watch('end_date')) {
            formatWeekList(watch('start_date'), watch('end_date'));
            if (dayjs(watch('end_date')).diff(dayjs(watch('start_date')), 'day') > 7) {
                setValue('repeat_type', {
                    value: 'weekly',
                    label: 'Weekly',
                });
            }
            courseService.getLessonOfCourse(params?.id, params?.courseId, {})
                .then((res) => {
                    setTotalLesson(res?.data?.total)
                })
        }
    }, [watch('start_date'), watch('end_date')]);

    const getInfoFixSchedule = () => {
        let totalSection = 0;
        let repeatDateInterval = 7;
        let totalRepeat = 0;
        if (watch('repeat_type')?.value === 'weekly') {
            repeatDateInterval= 7;
        }
        if (watch('repeat_type')?.value === 'bi_weekly') {
            repeatDateInterval= 14;
        }
        if (dayjs(watch('end_date')).diff(dayjs(watch('start_date')), 'day') >= 7 && repeatDateInterval) {
            totalRepeat = Number(Math.floor(dayjs(watch('end_date')).diff(dayjs(watch('start_date')), 'day') / repeatDateInterval))
        }
        timeRange?.forEach((element) => {
            if (element?.value) {
                const validTimeSlot = element?.timeSlot?.filter((slot) => !!slot?.slotStartTime)?.length || 0;
                const endDate = dayjs(watch('end_date')).set('hour', 23).set('minute', 59);
                totalSection = totalSection + validTimeSlot * (totalRepeat + 1);
                element?.timeSlot?.forEach((slot) => {
                    const dateOfWeek = getDateForDayOfWeek(watch('start_date'), endDate, slot.weekdayChild + 1);                    
                    const hour = dayjs(slot?.slotStartTime).get('hour');
                    const minute = dayjs(slot?.slotStartTime).get('minute')
                    const startTime = dayjs(dateOfWeek).set('hour', hour).set('minute', minute)
                    if (startTime.isBefore(dayjs()) || startTime.add(totalRepeat * repeatDateInterval, 'day').isAfter(endDate)) {
                        totalSection = totalSection - 1;
                    };
                });
            }
        });
        return {
            totalSection,
            totalLesson: totalSection >= totalLesson ? totalLesson : totalSection
        }
    };

    return (
        <div className="">
            <div className="flex mt-4 gap-3">
                <div className="w-1/2">
                    <label htmlFor="start-date" className="text-gray-800 text-xs font-semibold block mb-1">
                        {t('SESSION.START_DATE')}
                        <span className="text-red-500"> *</span>
                    </label>
                    <DatePickerInput
                        control={control}
                        name="start_date"
                        positionIcon="right"
                        placeholderText={t('SESSION.SELECT_START_DATE')}
                        classnames="text-xs"
                        minDate={new Date()}
                        maxDate={watch('end_date')}
                        isRequired
                        {...register('start_date', {
                            required: t("FORM_CONST.REQUIRED_FIELD"),
                        })}
                    />
                </div>
                <div className="w-1/2">
                    <label htmlFor="start-date" className="text-gray-800 text-xs font-semibold block mb-1">
                        {t('SESSION.END_DATE')}
                        <span className="text-red-500"> *</span>
                    </label>
                    <DatePickerInput
                        control={control}
                        name="end_date"
                        positionIcon="right"
                        placeholderText={t('SESSION.SELECT_END_DATE')}
                        classnames="text-xs"
                        minDate={watch('start_date') || new Date()}
                        isRequired
                        {...register('end_date', {
                            required: t("FORM_CONST.REQUIRED_FIELD"),
                        })}
                    />
                </div>
            </div>
            <div className="addSlot !p-0">
                {watch('start_date') && watch('end_date') ? (
                    <div className="addSlot-section">
                        {dayjs(watch('end_date')).diff(dayjs(watch('start_date')), 'day') > 7 ? (
                            <>
                                <p className="text-sm text-gray-700">{t("SESSION.YOUR_SCHEDULED_PERIOD_IS_LONGER_THAN_1_WEEK")}</p>
                                <div className="text-sm text-gray-700 mb-6 mt-1 inline-block w-full">
                                    {t("SESSION.THE_SLOT_BELLOW_WILL_AUTOMATICALLY_REPEAT")}
                                    <div className="inline-block mx-1">
                                        <SelectField
                                            control={control}
                                            label={''}
                                            name="repeat_type"
                                            placeholder={t('SESSION.SELECT_REPEAT_TYPE')}
                                            isSearchable={false}
                                            isDisabled={false}
                                            isMulti={false}
                                            options={REPEAT_TYPE}
                                            errors={errors}
                                        />
                                    </div>
                                   {t('SESSION.UNTIL_END_DATE')} 
                                </div>
                            </>
                        ) : null}
                        <div className="addSlot-days">
                            <label htmlFor="day" className="text-gray-800 text-xs font-semibold block mb-1">
                                {t('SESSION.DAYS')}
                                <span className="text-red-500"> *</span>
                            </label>
                            <div className="flex w-full gap-5">
                                <div className="text-xs font-normal">
                                    <div className="grid grid-cols-7 gap-4">
                                        {timeRange?.map((el, index) => (
                                            <CheckboxComponent
                                                key={index}
                                                id={el.label}
                                                label={el?.label?.slice(0, 3)}
                                                propsCheckbox={{ ...register(`time_range.${index}.value`) }}
                                                type="checkbox"
                                                disabled={el?.disable}
                                                checked={el?.value}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="addSlot-time">
                            {!!timeRange?.filter((el) => el.value)?.length && (
                                <div className="text-xs font-normal">
                                    <label htmlFor="day" className="text-gray-800 text-xs font-semibold block mb-1">
                                        {t('SESSION.TIME_SLOTS')}
                                        <span className="text-red-500"> *</span>
                                    </label>
                                    <div className="-mt-2">
                                        {timeRange?.map((el, index) => (
                                            <div
                                                key={`${index}-time_range-${watch('start_date')}`}
                                                className={classNames('mt-2 grid grid-cols-4 gap-2', !el.value ? 'hidden' : '')}
                                            >
                                                <div className="col-span-1 text-gray-500 text-sm py-4">{el?.label?.slice(0, 3)}</div>
                                                <div className="col-span-3 space-x-2">
                                                    <TimeSlotComponent
                                                        control={control}
                                                        index={index}
                                                        watch={watch}
                                                        errors={errors}
                                                        fieldsParent={fields}
                                                        appendParent={update}
                                                        startDate={watch('start_date')}
                                                        endDate={watch('end_date')}
                                                        weekDay={el?.weekday}
                                                        setValue={setValue}
                                                        isRequired={el?.value}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>

            <div className="flex flex-col gap-1 mt-4">
                <div className="flex gap-1 items-center text-xs font-semibold text-gray-800">
                    {t("SESSION.USE_LESSION_FORM_SYLLABUS")}{' '}
                    <div
                        data-tip={t("SESSION.USE_LESSION_FORM_SYLLABUS_TOOLTIP")}
                        data-for={'use-lesson'}
                    >
                        <CircleInformationIcon fill="#1f2937" width="14" height="14" />
                    </div>
                    {
                        /*@ts-ignore*/
                        <ReactTooltip
                            place="right"
                            type="dark"
                            effect="float"
                            id={'use-lesson'}
                            getContent={(content) => <div>{content}</div>}
                        />
                    }
                </div>
                <SwitchToggle name={'use_lesson'} control={control} />
                {watch('end_date') && watch('start_date') && watch('use_lesson') && (
                    <div className="mt-1.5 border border-dashed border-gray-300 bg-gray-50 py-3 px-4 flex flex-col gap-2 rounded">
                        <div className="text-sm">
                            {t("SESSION.TOTAL_SESSION_WILL_BE_CREATE")}: <span className="font-semibold">{getInfoFixSchedule()?.totalSection}</span>
                        </div>
                        <div className="text-sm">
                            {t("SESSION.TOTAL_SESSION_FROM_SYLLABUS")}: <span className="font-semibold">{getInfoFixSchedule()?.totalLesson}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
