import React from 'react';

export default function CreditIcon({className}: {className?: string}) {
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.63389 3.71045C6.90978 3.23202 7.52129 3.06783 7.99972 3.34372L16.9997 8.53372C17.4782 8.80962 17.6423 9.42113 17.3664 9.89956C17.0906 10.378 16.479 10.5422 16.0006 10.2663L7.00061 5.07629C6.52218 4.80039 6.35799 4.18888 6.63389 3.71045Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5021 1.40276C10.9577 1.14026 11.4742 1.00208 12 1.00208C12.5258 1.00208 13.0424 1.14027 13.4979 1.4028C13.4986 1.4032 13.4993 1.4036 13.5 1.404L20.5 5.40399C20.9556 5.66704 21.334 6.04527 21.5973 6.50074C21.8606 6.95621 21.9995 7.47291 22 7.999V16.0011C21.9995 16.5271 21.8606 17.0438 21.5973 17.4993C21.334 17.9548 20.9556 18.333 20.5 18.5961L20.4961 18.5983L13.5 22.5961C13.4994 22.5964 13.4988 22.5967 13.4982 22.5971C13.0426 22.8597 12.5259 22.998 12 22.998C11.4741 22.998 10.9575 22.8597 10.5019 22.5971C10.5012 22.5968 10.5006 22.5964 10.5 22.5961L3.50386 18.5983L3.5 18.5961C3.04439 18.333 2.66597 17.9548 2.40269 17.4993C2.13941 17.0438 2.00054 16.5271 2 16.0011V7.999C2.00054 7.47291 2.13941 6.95621 2.40269 6.50074C2.66597 6.04527 3.04439 5.66704 3.5 5.404L3.50386 5.40177L10.5021 1.40276ZM12 3.00208C11.8245 3.00208 11.652 3.04828 11.5 3.13605L11.4961 3.13828L4.5 7.13605C4.49947 7.13635 4.49895 7.13666 4.49842 7.13696C4.34726 7.2246 4.22169 7.35033 4.13423 7.50163C4.04654 7.65333 4.00025 7.8254 4 8.00062V15.9994C4.00025 16.1746 4.04654 16.3467 4.13423 16.4984C4.22169 16.6497 4.34726 16.7754 4.49842 16.8631C4.49895 16.8634 4.49947 16.8637 4.5 16.864L11.5 20.864C11.652 20.9518 11.8245 20.998 12 20.998C12.1755 20.998 12.348 20.9518 12.5 20.864L12.5039 20.8618L19.5 16.864C19.5005 16.8637 19.5011 16.8634 19.5016 16.8631C19.6527 16.7754 19.7783 16.6497 19.8658 16.4984C19.9535 16.3466 19.9998 16.1744 20 15.999V8.00105C19.9998 7.82569 19.9535 7.65345 19.8658 7.50163C19.7783 7.35034 19.6527 7.22461 19.5016 7.13697C19.5011 7.13666 19.5005 7.13636 19.5 7.13605L12.5 3.13606C12.348 3.04829 12.1755 3.00208 12 3.00208Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.40409 6.45928C2.68063 5.98122 3.29236 5.81786 3.77042 6.0944L11.9997 10.8547L20.229 6.0944C20.707 5.81786 21.3188 5.98122 21.5953 6.45928C21.8718 6.93734 21.7085 7.54907 21.2304 7.82561L12.5004 12.8756C12.1907 13.0548 11.8087 13.0548 11.499 12.8756L2.76897 7.82561C2.29091 7.54907 2.12755 6.93734 2.40409 6.45928Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 11C12.5523 11 13 11.4477 13 12V22.08C13 22.6323 12.5523 23.08 12 23.08C11.4477 23.08 11 22.6323 11 22.08V12C11 11.4477 11.4477 11 12 11Z"
                fill="currentColor"
            />
        </svg>
    );
}

export function CreditAccountIcon({className}: {className?: string}) {
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.25 6.375C20.25 8.65317 16.5563 10.5 12 10.5C7.44365 10.5 3.75 8.65317 3.75 6.375M20.25 6.375C20.25 4.09683 16.5563 2.25 12 2.25C7.44365 2.25 3.75 4.09683 3.75 6.375M20.25 6.375V17.625C20.25 19.9032 16.5563 21.75 12 21.75C7.44365 21.75 3.75 19.9032 3.75 17.625V6.375M20.25 6.375V10.125M3.75 6.375V10.125M20.25 10.125V13.875C20.25 16.1532 16.5563 18 12 18C7.44365 18 3.75 16.1532 3.75 13.875V10.125M20.25 10.125C20.25 12.4032 16.5563 14.25 12 14.25C7.44365 14.25 3.75 12.4032 3.75 10.125"
                stroke="#6B7280"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}