import { useState, useEffect, useCallback } from 'react';
import { useDebouncedState } from 'hooks/useDebounce';
import debounce from 'lodash/debounce';
import './SessionFilter.scss';
import { useLocation, useParams } from 'react-router-dom';
import { LOCATION, USER_ROLE } from 'components/Management/User/constant';
import { WorkspaceService } from 'services';
import SelectBox from 'components/V2/SelectBox';
import { LearnerOrLearningGroupWorkspace } from '_shared/components/LearnerAndGroupSearch/LearnerOrLearningGroupWorkspace';
import { SESSION_STATUS } from 'constant/course.const';
import { useTranslation } from 'react-i18next';
import { checkPermission } from 'utils/commonFun';
import SearchBox from 'components/V2/SearchBox';
import DatePickerComponent from 'components/Reports/components/DatePicker';
import dayjs from 'dayjs';

interface SessionFilterType {
    onChangeFilter: (value: any) => void;
    className?: string;
}

export const SessionFilter = ({ onChangeFilter, className = '' }: SessionFilterType) => {
    const params: { id: string } = useParams();
    const { t } = useTranslation();
    const location: any = useLocation();

    const [inputSearchCourse, setInputSearchCourse] = useDebouncedState('');
    const [inputSearchInstructor, setInputSearchInstructor] = useDebouncedState('');
    const [keyword, setKeyword] = useDebouncedState('', 0);

    const [listCourse, setListCourse] = useState<any>([]);
    const [courseSelected, setCourseSelected] = useState<any>([]);
    const [listInstructor, setListInstructor] = useState<any>([]);
    const [instructorSelected, setInstructorSelected] = useState<any>([]);
    const [learnerOrLearningGroupSelected, setLearnerOrLearningGroupSelected] = useState<any>();
    const [paginationCourse, setPaginationCourse] = useState<any>({ page: 1 });
    const [paginationInstructor, setPaginationInstructor] = useState<any>({ page: 1 });
    const [sessionStatus, setSessionStatus] = useState<any>([]);
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);

    const [startDate, endDate] = dateRange;

    const getCourses = async (page) => {
        let payload: any = {
            title: inputSearchCourse,
            page: page ? page : paginationCourse.page,
            per_page: 20,
            order: 'desc',
            sort_by: 'updated_at',
        };

        if (!payload?.title) delete payload.title;

        const response = await WorkspaceService.getCoursesList(params.id, payload);

        if (response) {
            const formattedData = response?.items?.map((item) => {
                return {
                    id: item?.id,
                    name: item?.title,
                    value: item?.id,
                };
            });
            if (page === 1) setListCourse(formattedData);
            else setListCourse([...listCourse, ...formattedData]);
            setPaginationCourse({ ...paginationCourse, total: response?.total, page });
        }

        return [];
    };

    const getInstructors = async (page) => {
        let payload: any = {
            q: inputSearchInstructor,
            page: page ? page : paginationInstructor.page,
            per_page: 20,
            order: 'desc',
            sort_by: 'updated_at',
            location: LOCATION.clms,
            user_role_name: USER_ROLE.instructor,
        };

        if (!payload?.q) delete payload.q;

        const response = await WorkspaceService.getWorkspaceMembersService(params.id, payload);

        if (response) {
            const formattedData = response?.data?.items?.map((item) => {
                return {
                    id: item?.id,
                    name: item?.display_name,
                    value: item?.id,
                };
            });
            if (page === 1) setListInstructor(formattedData);
            else setListInstructor([...listInstructor, ...formattedData]);
            setPaginationInstructor({ ...paginationInstructor, total: response?.data?.total, page });
        }

        return [];
    };

    const updateFilter = useCallback(
        debounce((listFilter) => onChangeFilter(listFilter), 1500),
        []
    );

    useEffect(() => {
        if (location?.state) {
            let filterValues: any = {};

            if (location?.state?.instructor_id) {
                setInstructorSelected(location?.state?.instructor_id);
                filterValues.instructor_ids = location?.state?.instructor_id[0]?.value;
            }
            if (location?.state?.session_status) {
                setSessionStatus(location?.state?.session_status);
                filterValues.session_status = location?.state?.session_status?.map((i) => i?.value);
            }

            setDateRange([]);
            Object.keys(filterValues).length && updateFilter(filterValues);
        };
    }, []);

    useEffect(() => {
        let listFilter: any = {
            q: keyword,
            from_date: startDate ? dayjs(startDate).startOf('D').format('YYYY-MM-DDTHH:mm:ss') : undefined,
            to_date: endDate ? dayjs(endDate).endOf('D').format('YYYY-MM-DDTHH:mm:ss') : undefined,
            course_ids: courseSelected?.length ? courseSelected?.map((el) => el?.value)?.join(',') : null,
            instructor_ids: instructorSelected?.length ? instructorSelected?.map((el) => el?.value)?.join(',') : null,
            session_status: !!sessionStatus?.length ? sessionStatus?.map((i) => i?.value) : null,
        };
        if (learnerOrLearningGroupSelected) {
            listFilter = {
                ...listFilter,
                ...learnerOrLearningGroupSelected,
            };
        }
        if (!listFilter?.q) delete listFilter?.q;
        if (!listFilter?.to_date) {
            delete listFilter?.from_date;
            delete listFilter?.to_date;
        }
        if (!listFilter?.course_ids) delete listFilter?.course_ids;
        if (!listFilter?.instructor_ids) delete listFilter?.instructor_ids;
        if (!listFilter?.session_status) delete listFilter?.session_status;
        if (!listFilter?.learner_ids || listFilter?.learner_ids == '') delete listFilter?.learner_ids;
        if (!listFilter?.learning_group_ids || listFilter?.learning_group_ids == '') delete listFilter?.learning_group_ids;

        updateFilter(listFilter);
    }, [keyword, dateRange, learnerOrLearningGroupSelected, courseSelected, instructorSelected, sessionStatus]);

    useEffect(() => {
        if (checkPermission('course', 'R')) {
            getCourses(1);
        }
    }, [inputSearchCourse]);

    useEffect(() => {
        getInstructors(1);
    }, [inputSearchInstructor]);

    return (
        <>
            <div className="sessions-title">
                <h1 className="sessions-name">{t('SESSION.SESSION_LIST')}</h1>
                <div className="sessions-search">
                    <div className="max-w-[334px] w-full">
                        <SearchBox
                            placeholder={t('SESSION.SEARCH_SESSION')}
                            className="sessionFilter-searchInput"
                            onSubmit={setKeyword}
                        />
                    </div>
                    <div className="relative min-w-[248px]">
                        <DatePickerComponent
                            onChange={setDateRange}
                            startDate={startDate}
                            endDate={endDate}
                            className="!rounded !px-2 min-h-[36px]"
                            placeHolder="dd/mm/yyyy - dd/mm/yyyy"
                            isClearable
                        />
                    </div>
                </div>
            </div>
            <div className={`sessionFilter ${className}`}>
                <LearnerOrLearningGroupWorkspace onChangeFilter={setLearnerOrLearningGroupSelected} />
                {checkPermission('course', 'R') && (
                    <SelectBox
                        label={t('COURSE')}
                        isMulti
                        onSearch={setInputSearchCourse}
                        onSelect={setCourseSelected}
                        data={listCourse}
                        selectedItems={courseSelected}
                        total={paginationCourse?.total}
                        showmore={() => getCourses(paginationCourse?.page + 1)}
                    />
                )}

                <SelectBox
                    label={t('INSTRUCTOR')}
                    isMulti
                    onSearch={setInputSearchInstructor}
                    onSelect={setInstructorSelected}
                    data={listInstructor}
                    selectedItems={instructorSelected}
                    total={paginationInstructor?.total}
                    showmore={() => getInstructors(paginationInstructor?.page + 1)}
                />
                <SelectBox
                    label={t('STATUS')}
                    isMulti
                    onSelect={setSessionStatus}
                    data={SESSION_STATUS(t)}
                    selectedItems={sessionStatus}
                    total={Object.keys(SESSION_STATUS(t))?.length}
                />
            </div>
        </>
    );
};
