import { FC, useState, useEffect, useMemo } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './OverView.scss'
import { useForm } from 'react-hook-form';
import { InputField, TextareaField } from 'components/InputForm/InputForm';
import { ButtonPrimary, Toastify } from '_shared';
import rolesPermissionsService from 'services/rolesPermissions.service';


interface OverViewProps {
    data: any;
    submitted: any;
    isDisable: boolean;
    roleId?: any;
}

const locationRole = (translator) => [
    { value: 'clms', label: translator('CLMS') },
    { value: 'instructor_portal', label: translator('INSTRUCTOR_PORTAL') }
]

const OverView: FC<OverViewProps> = ({ data, submitted, isDisable, roleId }) => {
    const {
        handleSubmit,
        register,
        formState: { errors, isValid, isDirty },
        reset,
        getValues
    } = useForm({ mode: 'onChange' });
    const params: any = useParams();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`ROLES_PERMISSIONS.${str}`, opt));


    const onSubmit = (values) => {
        setIsLoading(true);
        rolesPermissionsService.updateDetailsRoles(params?.id, params?.roleId || roleId, {
            name: values?.roleName,
            description: values?.description
        }).then((res) => {
            Toastify.success();
            submitted();
        })
            .catch((err) => {
                Toastify.error();
            }).finally(() => {
                setIsLoading(false);
            });

    }

    useEffect(() => {
        const defaultValues = {
            ...data,
            roleName: data?.name || '',
            description: data?.description || '',
            location: locationRole(translator).find(location => location?.value === data?.location)?.label || data?.location || '',
        }
        reset(defaultValues)
    }, [data])

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="">

                <div className='roleOverview'>
                    <div className='roleOverview-header'>
                        <h1>{translator('OVERVIEW')}</h1>
                        {!isDisable && <ButtonPrimary disabled={!isValid || !isDirty || isLoading} type="submit" className={`${isLoading ? 'bg-ooola_EFF0FA text-ooolab_8083A3 cursor-not-allowed' : ''}`} >{t('SAVE')}</ButtonPrimary>}
                    </div>
                    <InputField
                        name="roleName"
                        maxLength={255}
                        label={translator('ROLE')}
                        errors={errors}
                        className="col-span-1 !w-full"
                        isRequired
                        placeholder={translator('ENTER_ROLE_NAME')}
                        register={register('roleName', {
                            required: t('FORM_CONST.REQUIRED_FIELD'),
                            maxLength: {
                                value: 255,
                                message: t('FORM_CONST.VALIDATE_255_CHARACTERS'),
                            },
                        })}
                        disabled={isDisable}
                    />
                    <InputField
                        name="location"
                        label={translator('LOCATION')}
                        className="col-span-1 !w-full capitalize"
                        placeholder={''}
                        register={register('location'
                        )}
                        disabled
                    />
                    <TextareaField
                        label={translator('DESCRIPTION')}
                        name="description"
                        className="col-span-2"
                        placeholder={translator('ENTER_DESCRIPTION')}
                        register={register('description')}
                        textLength={getValues("description")?.length}
                        maxLength={255}
                        isCountter
                        disabled={isDisable}
                    />
                </div>
            </form>
        </>
    );
};

export default OverView;
