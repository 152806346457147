import { FC, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EditIcon } from 'assets/icon/EditIcon';
import ActionsButton from 'components/ActionsButton';
import { InformationCircleIcon, TrashIcon } from '@heroicons/react/outline';
import { UserContext } from 'contexts/User/UserContext';
import { defaultRole, roleType } from '../RolesPermissionsContants';
import DialogComponent from 'components/Dialog/DialogComponent';
import DeleteRole from '../RolesPermissionDetails/DeleteRole';

interface ActionMenuProp {
    data: any;
    refreshData: Function;
}

const ActionMenu: FC<ActionMenuProp> = ({ data, refreshData }) => {
    const params: { id } = useParams();
    const { t: translator } = useTranslation();
    const history = useHistory();
    const {
        userState: { result },
    } = useContext(UserContext);
    const [openModalDeleteRole, setOpenModalDeleteRole] = useState<boolean>(false);
    

    return (
        <>
            <ActionsButton
                styleOptions="top-0 right-[35px]"
                menus={[
                    {
                        label: translator('ROLES_PERMISSIONS.DETAILS'),
                        icon: <InformationCircleIcon className='w-5 h-5' />,
                        action: async () => {
                            history?.push(`/workspace/${params?.id}/setting?tab=role&tabPermission=overview`, {
                                roleId: data?.id
                            })
                        },
                        class: 'border-b border-gray-300',
                    },
                    {
                        label: translator('ROLES_PERMISSIONS.DELETE'),
                        icon: <TrashIcon width="18" height="16" className="text-gray-900" />,
                        action: async () => {
                            setOpenModalDeleteRole(true)
                        },
                        class: 'border-b border-gray-300',
                        hide: (data?.role_type === roleType.default)
                    },
                ]}
            />
            <DialogComponent
                isOpen={openModalDeleteRole}
                title={translator("ROLES_PERMISSIONS.DELETE_ROLE")}
                onCloseModal={() => setOpenModalDeleteRole(false)}
                maskClosable={false}
                styles="max-w-lg"
                child={
                    <DeleteRole submitted={() => {
                        refreshData();
                        setOpenModalDeleteRole(false);
                    }}
                        onCloseModal={() => setOpenModalDeleteRole(false)} roleId={data?.id} roleData={data}/>

                }
            />
        </>
    );
};

export default ActionMenu;
