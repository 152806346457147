import React from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import { debounce } from 'lodash';

interface SearchInputProps {
    onSubmit: (value: string) => void;
    defaultValue?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({ onSubmit }) => {
    const onChange = (e) => {
        onSubmit(e.target.value);
    };
    const debouncedOnChange = debounce(onChange, 1000);
    return (
        <div className="flex items-center justify-start">
            <div className="lg:w-[360px]">
                <label htmlFor="search" className="sr-only">
                    Search
                </label>
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center cursor-pointer">
                        <SearchIcon className=" h-4 w-4 text-dark-100" aria-hidden="true" />
                    </div>
                    <input
                        id="search"
                        name="search"
                        autoComplete="off"
                        className="block w-full pl-8 pr-3 py-1.5 border border-ooolab_gray_6 rounded leading-5 bg-white placeholder-gray-500  text-xs focus:outline-none"
                        placeholder={'Search'}
                        type="search"
                        onChange={debouncedOnChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default SearchInput;
