import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/outline';
import TableBanner from './TableBanner';
import ButtonComponent from 'components/Button/ButtonComponent';
import { useDebouncedState } from 'hooks/useDebounce';
import ClassService from 'services/class.service';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import DialogComponent from 'components/Dialog/DialogComponent';
import CreateBanner from '../CreateBanner';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import Pagination from 'components/V2/Pagination';
import SearchBox from 'components/V2/SearchBox';
import { defaultPagination } from 'constant/util.const';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';

const BannerList: FC = ({ children }) => {
    const params: any = useParams();
    const { t } = useTranslation();
    const [isOpenModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [indexPage, setIndexPage] = useState('1-50');
    const [keyword, setKeyword] = useDebouncedState('');
    const [bannerList, setBannerList] = useState([]);
    const [pagination, setPagination] = useState(defaultPagination);
    const workspaceId = params.id;
    const [updateBannerList, setUpdateBannerList] = useState(false);

    const getBannerList = (page: number | string = 1) => {
        setLoading(true);
        ClassService.getBannersService(workspaceId, {
            name: keyword,
            ...pagination,
            page,
        })
            .then((res) => {
                const {
                    items,
                    page,
                    per_page,
                    order,
                    sort_by,
                    total,
                } = res.data;
                setBannerList(items);
                setPagination({ page, per_page, order, sort_by, total });
                setIndexPage(getIndexPage(page, per_page, items.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const translator = (str: string, opt?: any) => opt ? t(str) : t(`BANNER.${str}`);

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handlePagination = (page: number) => {
        getBannerList(page);
    };

    useEffect(() => {
        getBannerList();
    }, [keyword, updateBannerList]);

    return (
        <div className="w-full h-screen">
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.COMMUNICATION', true)}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    { name: translator('DASHBOARD.SIDEBAR.BANNERS',true), href: ``, className: '' },
                ]}
            />
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 p-4 px-2">
                <SearchBox className='w-full max-w-xs' onSubmit={(e: any) => setKeyword(e)} />
                {checkPermission('banner', 'C') && <div className="lg:flex lg:justify-end">
                    <ButtonComponent
                        title={translator('CREATE_BANNER')}
                        classStyle="xs:p-1 xs:text-sm primary-button !bg-enterprise"
                        onClickButton={() => setOpenModal(true)}
                        icon={<PlusIcon className="w-4 h-4 mr-1" />}
                    />
                </div>}

            </div>
            <div className={`px-2`}>
                <div className="h-[calc(100vh-179px)] custom-scrollbar overflow-y-auto">
                    {loading ? 
                        <div className='h-[calc(100vh-189px)] flex items-center justify-center mt-4'><CircleSpin color='text-primary-500' /></div>
                        :
                        <TableBanner
                            workspaceId={workspaceId}
                            dataTable={bannerList}
                            translator={translator}
                        />
                    }   
                    
                </div>
                <Pagination
                    indexPage={indexPage}
                    total={pagination.total}
                    per_page={pagination.per_page}
                    page={pagination.page}
                    callback={handlePagination}
                />
            </div>
            
            <DialogComponent
                isOpen={isOpenModal}
                styles="max-w-md"
                title={translator('CREATE_BANNER')}
                stylesTitle="text-base"
                onCloseModal={handleCloseModal}
                child={
                    <CreateBanner
                        handleCloseModal={handleCloseModal}
                        updateBannerList={updateBannerList}
                        setUpdateBannerList={setUpdateBannerList}
                    />
                }
            />
        </div>
    );
};

export default BannerList;
