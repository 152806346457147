export const PresentationIcon = ({
    fill = '#111827',
    width = '18',
    height = '17',
}: {
    fill?: string;
    width?: string;
    height?: string;
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.666748 1.33333C0.666748 0.873096 1.03984 0.5 1.50008 0.5H16.5001C16.9603 0.5 17.3334 0.873096 17.3334 1.33333C17.3334 1.79357 16.9603 2.16667 16.5001 2.16667V11.3333C16.5001 12.2538 15.7539 13 14.8334 13H11.0119L12.9227 14.9107C13.2481 15.2362 13.2481 15.7638 12.9227 16.0893C12.5972 16.4147 12.0696 16.4147 11.7442 16.0893L9.00008 13.3452L6.256 16.0893C5.93057 16.4147 5.40293 16.4147 5.07749 16.0893C4.75206 15.7638 4.75206 15.2362 5.07749 14.9107L6.98824 13H3.16675C2.24627 13 1.50008 12.2538 1.50008 11.3333V2.16667C1.03984 2.16667 0.666748 1.79357 0.666748 1.33333ZM3.16675 2.16667V11.3333H14.8334V2.16667H3.16675Z"
                fill="currentColor"
            />
            <path
                d="M11.9176 6.59938L7.85175 3.88938C7.8016 3.85592 7.74331 3.83669 7.68309 3.83373C7.62287 3.83078 7.56298 3.84421 7.50979 3.8726C7.4566 3.90099 7.41211 3.94328 7.38105 3.99495C7.34999 4.04663 7.33353 4.10576 7.33342 4.16605V9.58772C7.33363 9.64788 7.35011 9.70686 7.38113 9.7584C7.41215 9.80995 7.45654 9.85214 7.50959 9.88051C7.56264 9.90887 7.62238 9.92235 7.68248 9.91951C7.74257 9.91667 7.80077 9.89762 7.85092 9.86438L11.9176 7.15438C11.9633 7.12396 12.0008 7.0827 12.0267 7.03429C12.0527 6.98588 12.0662 6.93181 12.0662 6.87688C12.0662 6.82196 12.0527 6.76789 12.0267 6.71948C12.0008 6.67107 11.9633 6.62981 11.9176 6.59938Z"
                fill="currentColor"
            />
        </svg>
    );
};
