import React from 'react';
import ManagementMasterPage from 'pages/RouteMasterPage';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';
import GroupsManagement from 'components/Management/Group/GroupList';

const GroupPage =() => {
    return (
        <ManagePermissionWrapper>
            <GroupsManagement>
                <ManagementMasterPage  />
            </GroupsManagement>
        </ManagePermissionWrapper>
    );
};

export default GroupPage;