import { ReactNode, useState, useEffect } from 'react';
import { useTable, useRowSelect, useFlexLayout, Row, Column } from 'react-table';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import './styleTableCustom.css';
import { classNames, UUID } from 'utils/commonFun';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import { ImageAvatar } from 'components/Image';
import ChevronUpDown from 'assets/icon/ChevronUpDown';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/outline';
import { useSticky } from 'react-table-sticky';

export const RenderTableHeader = ({ value, className, onClick, isOrder, order }: { value: any; className?: string, onClick?: any, isOrder?: boolean, order?: string }) => (
    <p className={classNames('pl-ooolab_p_1_e text-xs text-ooolab_dark_1 font-semibold flex items-center justify-start h-full text-left', className)}
        onClick={() => {
            if (isOrder) {
                onClick?.();
            }
        }}
    >
        {value}
        {isOrder && !order && <span><ChevronUpDown /></span>}
        {isOrder && order &&
            <span className='ml-2'>
                {order == 'desc' ? <ArrowDownIcon className='w-4 h-4' /> : <ArrowUpIcon className='w-4 h-4' />}
            </span>
        }
    </p>
);

export const RenderTableCell = ({
    value,
    className,
    isTooltip,
    disable,
    onClick,
    imageSrc,
    styles,
}: {
    value: any;
    className?: string;
    isTooltip?: boolean;
    disable?: boolean;
    onClick?: () => void;
    imageSrc?: string;
    styles?: any;
}) => {
    const id = UUID();
    let element: ReactNode = <>{value || "-"}</>;
    if (isTooltip && !!imageSrc) {
        element = <ImageAvatar imageSrc={imageSrc} />;
    } else if (!isTooltip && !!imageSrc) {
        element = (
            <p className="flex items-center space-x-2">
                <ImageAvatar imageSrc={imageSrc} />
                <span>{value}</span>
            </p>
        );
    }
    return (
        <>
            <div
                className={classNames(
                    'pl-ooolab_p_1_e text-xs text-ooolab_dark_1 leading-ooolab_24px font-normal py-2 truncate ',
                    className,
                    disable && 'pointer-events-none',
                    !!onClick && 'cursor-pointer'
                )}
                onClick={() => onClick?.()}
                data-tip={value}
                data-for={id}
                style={styles}
            >
                {element}
            </div>
            {isTooltip && (
                //@ts-ignore
                <ReactTooltip
                    place="bottom"
                    type="dark"
                    effect="float"
                    id={id}
                    disable={disable}
                    getContent={(content) => <div className="whitespace-normal h-auto capitalize max-w-xs">{content}</div>}
                />
            )}
        </>
    );
};

export const LinkCustom = ({
    to,
    className,
    value,
    isTooltip,
    disable,
    target = '_self'
}: {
    to: any;
    className?: string;
    value: any;
    isTooltip?: boolean;
    disable?: boolean;
    target?: string;
}) => {
    const id = UUID();
    return (
        <>
            <Link
                to={to}
                className={classNames(
                    'pl-ooolab_p_1_e text-xs text-primary-500 leading-ooolab_24px font-semibold py-2 truncate block items-center justify-start',
                    className
                )}
                data-tip={value}
                data-for={id}
                target={target}
            >
                {value}
            </Link>
            {isTooltip && (
                //@ts-ignore
                <ReactTooltip
                    place="bottom"
                    type="dark"
                    effect="float"
                    id={id}
                    disable={disable}
                    getContent={(content) => <div className="w-56 h-auto whitespace-normal">{content}</div>}
                />
            )}
        </>
    );
};

const EditableCell: any = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
}: {
    value: any;
    row: Row;
    column: Column;
    updateMyData: Function;
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);

    const onChange = (e: any) => {
        setValue(e.target.value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return <input value={value} onChange={onChange} onBlur={onBlur} placeholder={''} />;
};


const Table = ({
    columns,
    data,
    initialState,
    isBorderFirstColum = false,
    emptyContent = 'No data',
    onClickRow,
    onDoubleClickRow,
    rowSelected,
    emptyIcon,
    classEmpty
}: {
    columns: Column<any>[];
    data: any;
    initialState?: any;
    noBorder?: boolean;
    isBorderFirstColum?: boolean;
    emptyContent?: string;
    onClickRow?: (e?: any) => void;
    onDoubleClickRow?: (e?: any) => void;
    rowSelected?: any;
    isTableInfinity?: boolean;
    emptyIcon?: any,
    classEmpty?: any
}) => {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            defaultColumn: {
                Cell: EditableCell,
            },
            initialState,
        },
        useRowSelect,
        useFlexLayout,
        useSticky
    );

    const renderTbody = () => {
        if (!data?.length) {
            return (
                <div className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${classEmpty ? classEmpty : ''}`}>
                    <NoContentWithImage content={emptyContent} img={emptyIcon} />
                </div>
            );
        }
        const element = rows.map((row) => {
            const id_tbody_tr = UUID();
            const tmp: any = { ...row.original };
            prepareRow(row);
            return (
                <tr
                    key={id_tbody_tr}
                    className={classNames('hover:bg-gray-50 items-center relative', rowSelected && rowSelected?.id === tmp.id ? 'bg-gray-50' : '',)}
                    {...row.getRowProps()}
                    onClick={() => onClickRow?.(row.original)}
                    onDoubleClick={() => onDoubleClickRow?.(tmp.id)}
                >
                    {row.cells.map((cell: any, cellIndex) => {
                        const id_tbody_tr_td = UUID();
                        return (
                            <td
                                key={id_tbody_tr_td}
                                className={classNames(cellIndex == 0 && isBorderFirstColum ? 'border-r' : '',
                                    cell?.column?.sticky === 'left' || cell?.column?.sticky === 'right'
                                        ? 'table-cell-sticky'
                                        : ''
                                )}
                                {...cell.getCellProps()}
                            >
                                {cell.render('Cell')}
                            </td>
                        );
                    })}
                </tr>
            );
        });
        return element;
    };

    return (
        <div className="table-container h-full relative">
            <table className="w-full h-full " {...getTableProps()}>
                <thead className='sticky top-0  bg-white z-[4]'>
                    {headerGroups.map((headerGroup) => {
                        const id_thead_tr = UUID();
                        return (
                            <tr className='bg-gray-50 border-t border-t-gray-300' key={id_thead_tr} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column: any, index) => {
                                    const id_thead_tr_th = UUID();
                                    return (
                                        <th
                                            key={id_thead_tr_th}
                                            className={classNames(
                                                index == 0 && isBorderFirstColum
                                                    ? 'border-t-0 border-r border-ooolab_bar_color py-2'
                                                    : 'border-t-0 border-ooolab_bar_color py-2',
                                                column?.sticky === 'left' && 'sticky-left',
                                                column?.sticky === 'right' && 'sticky-right',
                                            )}
                                            {...column.getHeaderProps()}
                                        >
                                            {column.render('Header')}
                                        </th>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </thead>
                <tbody {...getTableBodyProps()}>{renderTbody()}</tbody>
            </table>
        </div>
    );
};

export default Table;
