import { Dispatch, createContext } from 'react';
// STATES
// TYPES
import { initResourcesState, ResourcesState } from 'types/Resources.type';

export const ResourcesContext = createContext<{
    resourcesState: ResourcesState;
    dispatch: Dispatch<any>;
}>({
    resourcesState: initResourcesState,
    dispatch: () => null,
});
