import React from 'react';
import ManagementMasterPage from 'pages/RouteMasterPage';
import BookingClass from 'components/Management/BookingClass';
import ManagePermissionAdmin from 'pages/Management/ManagePermissionAdmin';

const BookingClassListPage = () => {
    return (
        <ManagePermissionAdmin>
            <BookingClass>
                <ManagementMasterPage />
            </BookingClass>
        </ManagePermissionAdmin>
    );
};

export default BookingClassListPage;
