import {
    ExclamationCircleIcon,
    PencilAltIcon,
    TagIcon
} from '@heroicons/react/outline';
import { ErrorMessage } from '@hookform/error-message';
import closeIcon from 'assets/SVG/close.svg';
import ActionsCircleButton from 'components/ActionsCircleButton';
import BorderWithTitle from 'components/BorderWithTitle';
import { errorNoti, successNoti } from 'components/H5P/H5PFN';
import DatePickerInput from 'components/Management/components/Form/DatePicker';
import { formatTimezone } from 'components/User/AccountSettingForm/AccountSettingFormFn';
import ModalRename from 'components/Workspace/CourseDetail/component/ModalRename';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import dayjs from 'dayjs';
import Lodash from 'lodash';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { H5PEditorUI } from 'packages/h5p-react';
import React, {
    RefObject,
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ClassService } from 'services';
import h5pServices from 'services/h5p.service';
import { IClassAssignment } from 'types/Class.type';
import { genClassNames } from 'utils/handleString';

function convertDateWithTz(date: any, timezone: string): string {
    return `${dayjs(date).format('YYYY-MM-DDT23:59:59')}${dayjs(date)
        .tz(timezone)
        .format('Z')}`;
}

const Header: React.FC<{
    setDispatchTime: any;
    title: string;
    setTitle: any;
    savedInfo: {
        isSaving: boolean;
        newContentId?: string;
        assignment?: IClassAssignment;
    };
}> = ({
    setDispatchTime,
    title,
    setTitle,
    savedInfo: { isSaving, newContentId },
}) => {
        const [isEditMode, setIsEditMode] = useState(false);
        const params: { id: string; classId: string } = useParams();
        const history = useHistory();
        const { t: translator } = useTranslation();
        return (
            <div className="absolute -inset-x-0 flex h-ooolab_h_15 px-3 shadow-ooolab_menu bg-white">
                <div className="flex-1" />
                <div className="flex items-center">
                    <div className="color-ooolab_dark_2 font-semibold h-ooolab_h_8 text-ooolab_sm leading-ooolab_32px">
                        {title}
                    </div>
                    <ActionsCircleButton
                        onclick={() => setIsEditMode(true)}
                        icon={
                            <PencilAltIcon className="w-ooolab_w_4 h-ooolab_h_4" />
                        }
                    />
                    {isEditMode && (
                        <ModalRename
                            title={title}
                            isOpen={isEditMode}
                            onClose={() => setIsEditMode(false)}
                            onConfirm={(e) => setTitle(e)}
                        />
                    )}
                </div>
                <div className="flex-1 flex justify-end items-center">
                    {newContentId && (
                        <Link
                            target="_blank"
                            to={`/workspace/${params.id}/h5p-content/${newContentId}/preview`}
                            className="color-ooolab_dark_2 font-semibold text-ooolab_sm hover:text-ooolab_blue_4 mr-4"
                        >
                            {translator('ASSIGNMENT.CREATE.PREVIEW')}
                        </Link>
                    )}
                    <button
                        onClick={() => {
                            if (!isSaving) {
                                setDispatchTime(new Date().getTime());
                            }
                        }}
                        className={genClassNames({
                            'h-fit text-white text-ooolab_sm px-ooolab_p_3 py-ooolab_p_1_e rounded-lg focus:outline-none': true,
                            'bg-ooolab_gray_0': isSaving,
                            'bg-primary-500': !isSaving,
                        })}
                        type="button"
                    >
                        {translator('ASSIGNMENT.CREATE.SAVE')}
                    </button>
                    <button
                        onClick={() => {
                            history.push(
                                `/workspace/${params.id}/management/class/${params.classId}?tab=assignment`
                            );
                        }}
                        className="ml-3 shadow-ooolab_close_button w-ooolab_w_7_n h-ooolab_h_7 flex items-center justify-center ml-4 cursor-pointer border-ooolab_transparent border-ooolab_border_bar_button hover:bg-ooolab_bg_sub_tab_hover hover:border-ooolab_transparent p-ooolab_p_1_e rounded-full"
                        onKeyDown={() => { }}
                        type="button"
                    >
                        <img
                            src={closeIcon}
                            className="w-ooolab_w_2_e h-ooolab_w_2_e"
                            alt=''
                        />
                    </button>
                </div>
            </div>
        );
    };

const Bar: React.FC<{
    h5pEditor: RefObject<H5PEditorUI>;
    dispatchTime: number;
    onSave: (
        newContentId: string,
        saving: boolean,
        shouldRefetch?: boolean
    ) => void;
    savedInfo: {
        title: string;
        newContentId?: string;
        assignment?: IClassAssignment;
    };
}> = ({
    h5pEditor,
    dispatchTime,
    savedInfo: { title, assignment },
    onSave,
}) => {
        const {
            control,
            formState: { errors },
            trigger,
            getValues,
            setValue,
        } = useForm();
        const { t: translator } = useTranslation();
        const params: {
            id: string;
            classId: string;
            assignmentId?: string;
        } = useParams();
        const [attempt, setAttempt] = useState('');
        const [attemptError, setAttemptError] = useState({});
        const { getWorkspaceDetailState, dispatch } = useContext(
            GetWorkspaceContext
        );
        const detail = getWorkspaceDetailState.class?.detail;
        useEffect(() => {
            if (!detail) {
                workspaceMiddleware.getClassDetail(dispatch, {
                    classId: params.classId,
                    workspaceId: params.id,
                });
            }
        }, []);
        useEffect(() => {
            if (assignment) {
                setValue(
                    'end_date',
                    new Date(
                        dayjs(assignment.end_time, 'YYYY-MM-DDTHH:mm:SSZ')
                            .tz(detail?.time_zone || 'UTC')
                            .format('YYYY-MM-DD')
                    )
                );
                setAttempt(assignment.max_attempt_number.toString());
            }
        }, [assignment?.id]);
        useEffect(() => {
            if (dispatchTime !== 0) {
                trigger('end_date').then(async (success) => {
                    if (success) {
                        setAttemptError({});
                        if (attempt.length === 0 || +attempt <= 0) {
                            setAttemptError({
                                attempt: {
                                    type: 'required',
                                    message: translator(
                                        'FORM_CONST.REQUIRED_FIELD'
                                    ),
                                },
                            });
                        } else {
                            const data = getValues();
                            try {
                                const returnData:
                                    | { uid: string; contentId: string }
                                    | undefined = await h5pEditor?.current?.save();
                                if (returnData) {
                                    successNoti(
                                        translator('ASSIGNMENT.EDIT.H5P_SUCCESS'),
                                        <TagIcon />,
                                        0
                                    );
                                    onSave(returnData.contentId, true);
                                    if (assignment) {
                                        const {
                                            max_attempt_number,
                                            end_time,
                                            name,
                                            h5p_contents
                                        } = assignment;
                                        const shouldUpdateAssignment = Lodash.isEqual(
                                            {
                                                h5p_contents: [returnData.uid],
                                                name: title,
                                                end_time: detail?.time_zone
                                                    ? dayjs(data.end_date)
                                                        .tz(detail?.time_zone)
                                                        .format(
                                                            'YYYY-MM-DDT23:59:59Z'
                                                        )
                                                    : dayjs(data.end_date).format(
                                                        'YYYY-MM-DDT23:59:59'
                                                    ),
                                                max_attempt_number: +attempt,
                                            },
                                            {
                                                max_attempt_number,
                                                end_time: detail?.time_zone
                                                    ? dayjs(end_time)
                                                        .tz(detail?.time_zone)
                                                        .format(
                                                            'YYYY-MM-DDT23:59:59Z'
                                                        )
                                                    : dayjs(data?.time_zone).format(
                                                        'YYYY-MM-DDT23:59:59'
                                                    ),
                                                name,
                                                h5p_contents,
                                            }
                                        );

                                        if (!shouldUpdateAssignment) {
                                            await h5pServices.updateAssignment(
                                                params.id,
                                                params.classId,
                                                assignment.id,
                                                {
                                                    h5p_contents: [returnData.uid],
                                                    name: title,
                                                    end_time: detail?.time_zone
                                                        ? convertDateWithTz(
                                                            data.end_date,
                                                            detail?.time_zone
                                                        )
                                                        : dayjs(
                                                            data.end_date
                                                        ).format(
                                                            'YYYY-MM-DDT23:59:59'
                                                        ),
                                                    max_attempt_number: +attempt,
                                                }
                                            );
                                            successNoti(
                                                translator(
                                                    'ASSIGNMENT.EDIT.SUCCESS'
                                                ),
                                                <TagIcon />,
                                                0
                                            );
                                        }

                                        onSave(
                                            returnData.contentId,
                                            false,
                                            !shouldUpdateAssignment
                                        );
                                    } else {
                                        await h5pServices.createAssignment(
                                            params.id,
                                            params.classId,
                                            {
                                                h5p_contents: [returnData.uid],
                                                name: title,
                                                end_time: detail?.time_zone
                                                    ? convertDateWithTz(
                                                        data.end_date,
                                                        detail?.time_zone
                                                    )
                                                    : dayjs(data.end_date).format(
                                                        'YYYY-MM-DDT23:59:59'
                                                    ),
                                                max_attempt_number: +attempt,
                                            }
                                        );
                                        successNoti(
                                            translator('ASSIGNMENT.CREATE.SUCCESS'),
                                            <TagIcon />,
                                            0
                                        );
                                    }
                                } else if (assignment) {
                                    errorNoti(
                                        translator('ASSIGNMENT.EDIT.FAIL'),
                                        <ExclamationCircleIcon />,
                                        0
                                    );
                                } else {
                                    errorNoti(
                                        translator('ASSIGNMENT.CREATE.FAIL'),
                                        <ExclamationCircleIcon />,
                                        0
                                    );
                                }
                            } catch (e) {
                                //
                            }
                        }
                    }
                });
            }
        }, [dispatchTime]);
        return (
            <form className="bg-white w-ooolab_w_menu_1 pt-ooolab_p_20 px-5">
                <div className="text-ooolab_dark_300 font-semibold text-ooolab_sm">
                    {translator('ASSIGNMENT.CREATE.CREATE_ASSIGNMENT')}
                </div>
                <div className="text-green-500 text-ooolab_10px mt-2">
                    {translator('CLASSES.TIME_SLOT_IN_TIME_ZONE')}
                </div>
                <div className="text-green-500 text-ooolab_10px">
                    {detail?.time_zone
                        ? formatTimezone(detail.time_zone).label
                        : ''}
                </div>
                <div className="h-ooolab_h_1px bg-ooolab_border_logout my-5" />
                {detail && (
                    <BorderWithTitle
                        title={translator('ASSIGNMENT.CREATE.ASSIGNED_TO')}
                        cls="mb-5"
                    >
                        <div className="text-ooolab_dark_1 text-ooolab_sm opacity-90">
                            {detail.name}
                        </div>
                    </BorderWithTitle>
                )}
                <DatePickerInput
                    control={control}
                    name="end_date"
                    placeholderText={translator('ASSIGNMENT.CREATE.DUE_DATE')}
                    isRequired
                    paddingCls="py-3"
                    iconCls="top-ooolab_inset_12px"
                />
                <ErrorMessage
                    className="text-red-500 text-ooolab_10px mt-ooolab_m_2"
                    errors={errors}
                    name="end_date"
                    as="div"
                />
                <div className="border border-ooolab_gray_6 rounded-header_menu px-3 py-2 mt-7">
                    <input
                        value={attempt}
                        type="number"
                        onChange={(e: any) => {
                            const isInteger = Lodash.isInteger(
                                parseInt(e.target.value)
                            );
                            if (isInteger) {
                                setAttempt(e.target.value);
                            }
                        }}
                        onBlur={() => {
                            const parsedAttemp = parseInt(attempt);
                            if (parsedAttemp > 10) {
                                setAttempt('10');
                            } else if (parsedAttemp < 1) {
                                setAttempt('1');
                            }
                        }}
                        placeholder={translator('ASSIGNMENT.CREATE.NUMBER_ATTEMPT')}
                        className="text-ooolab_1xs w-full"
                        min={'1'}
                        minLength={1}
                    />
                </div>
                <ErrorMessage
                    className="text-red-500 text-ooolab_10px"
                    errors={attemptError}
                    name="attempt"
                    as="div"
                />
            </form>
        );
    };

const H5P: React.FC<{
    h5pEditor: RefObject<H5PEditorUI>;
    contentId?: string;
}> = ({ h5pEditor, contentId }) => {
    const { h5pEditPromise, h5pSavePromise } = h5pServices;
    const [key, setKey] = useState(new Date().getTime());
    const params: { id: string } = useParams();
    const handleSaved = () => { };
    const onEditorLoaded = () => { };
    const onSaveError = (event: any) => { };
    const onCustomError = (msg: string) => {
        errorNoti(msg, <ExclamationCircleIcon />, 0);
        setKey(new Date().getTime());
    };
    return (
        <div className="w-10/12 m-auto">
            {
                /*@ts-ignore*/
                <H5PEditorUI
                    key={key}
                    ref={h5pEditor}
                    argsId={{
                        workspaceId: params.id,
                        contentId: contentId || 'new',
                        whiteListTypes: ['H5P.QuestionSet'],
                    }}
                    loadContentCallback={h5pEditPromise}
                    saveContentCallback={h5pSavePromise}
                    onSaved={handleSaved}
                    onLoaded={onEditorLoaded}
                    onSaveError={onSaveError}
                    onCustomError={onCustomError}
                />
            }
        </div>
    );
};

const CreateAssignmentPage: React.FC = () => {
    const h5pEditor: RefObject<H5PEditorUI> = React.useRef(null);
    const [dispatchTime, setDispatchTime] = useState(0);
    const [savedInfo, setSavedInfo] = useState({
        newContentId: undefined,
        assignment: undefined,
        title: 'Untitled Assignment',
        isSaving: false,
    });
    const params: {
        id: string;
        classId: string;
        assignmentId?: string;
    } = useParams();
    const getAssignmentById = useCallback(async () => {
        try {
            if (params.assignmentId) {
                const res = await ClassService.getAssignmentById(
                    params.id,
                    params.classId,
                    params.assignmentId
                );
                let old = {
                    ...savedInfo,
                    assignment: res,
                    title: res.name,
                };
                setSavedInfo(old);
                const h5pRes = await h5pServices.h5pContentList(params.id, {
                    uid: res.h5p_contents[0],
                });
                old = {
                    ...old,
                    newContentId: h5pRes.items[0].contentId,
                };
                setSavedInfo(old);
            }
        } catch (e) {
            //
        }
    }, [savedInfo]);
    useEffect(() => {
        if (params.assignmentId) {
            getAssignmentById();
        }
    }, []);
    return (
        <div className="bg-ooolab_gray_11 h-screen">
            <Header
                title={savedInfo.title}
                setTitle={(e: string) =>
                    setSavedInfo({
                        ...savedInfo,
                        title: e,
                    })
                }
                setDispatchTime={setDispatchTime}
                savedInfo={savedInfo}
            />
            <div className="flex h-full">
                <Bar
                    h5pEditor={h5pEditor}
                    dispatchTime={dispatchTime}
                    onSave={(
                        newContentId: string,
                        saving: boolean,
                        shouldRefetch?: boolean
                    ) => {
                        setSavedInfo({
                            ...savedInfo,
                            newContentId,
                            isSaving: saving,
                        });
                        if (shouldRefetch) {
                            getAssignmentById();
                        }
                    }}
                    savedInfo={savedInfo}
                />
                <div className="flex-1 pt-ooolab_p_20 overflow-y-scroll">
                    {params.assignmentId ?
                        savedInfo.newContentId &&
                        <H5P
                            h5pEditor={h5pEditor}
                            contentId={savedInfo.newContentId}
                        /> : <H5P h5pEditor={h5pEditor} />
                    }
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default CreateAssignmentPage;
