import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import { WorkspaceService } from 'services';
import workspaceService from 'services/workspace.service';
import { debounce } from 'lodash';
import { PlusIcon } from '@heroicons/react/outline';
import './Filter.scss';
import { DropdownAsync } from '_shared/components/Form/DropdownAsync/DropdownAsync';
import SearchInput from '_shared/components/SearchInput/SearchInput';

interface IFilter {
    onChangeFilter: (value: any) => void;
    isFirstLoad: boolean;
}

const Filter = ({
    onChangeFilter,
    isFirstLoad,
}: IFilter) => {
    const params: any = useParams();
    const { t } = useTranslation();
    const [keyword, setKeyword] = useDebouncedState('', 0);
    const [inputSearchOwner, setInputSearchOwner] = useDebouncedState('');
    const [inputSearchCustomField, setInputSearchCustomField] = useDebouncedState('');
    const [listOwners, setListOwner] = useState([]);
    const [ownerSelected, setOwnerSelected] = useState([]);
    const [statusSelected, setStatusSelected] = useState([])
    const [loadingOwner, setloadingOwner] = useState(false);
    const [listCustomField, setListCustomField] = useState([]);
    const [filterByTags, setFilterByTags] = useState<any>();
    const [selectedTags, setSelectedTags] = useState<any>([]);
    const [payloadAddFilter, setPayloadAddFilter] = useState<{ types: string }>(null);
    const [hasReset, setHasReset] = useState(false);

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`LIBRARY.${str}`, opt));

    const [pagination, setPagination] = useState<any>({
        page: 1,
    });
    const [paginationCustomField, setPaginationCustomField] = useState<any>({
        page: 0,
    });

    const getListCustomFields = (page: number = 1) => {
        const body = {
            order: 'asc',
            page: page || paginationCustomField?.page,
            per_page: 10,
            q: inputSearchCustomField,
            type: 'learning_path'
        };
        if (inputSearchCustomField === '') {
            delete body.q;
        };
        workspaceService.getCustomFieldsService(params.id, body).then((res: any) => {
            const formatedData = res?.data?.items?.map((item) => {
                return {
                    name: item?.name,
                    id: item?.id,
                };
            });
            setPaginationCustomField({ ...pagination, total: res?.data?.total, page });
            if (page === 1) setListCustomField(formatedData);
            else setListCustomField([...listCustomField, ...formatedData]);
        });
    };

    const getListOwner = (page: number = 1) => {
        setloadingOwner(true);
        WorkspaceService.getWorkspaceMembers(
            { id: params.id },
            {
                page: page ? page : pagination.page,
                per_page: 10,
                q: inputSearchOwner,
                order: 'asc',
                sort_by: 'display_name',
            }
        )
            .then((res) => {
                const formatedData = res?.items?.map((item) => {
                    return {
                        name: item?.display_name,
                        id: item?.id,
                        value: item?.id,
                    };
                });
                setPagination({ ...pagination, total: res?.total, page });
                if (page === 1) setListOwner(formatedData);
                else setListOwner([...listOwners, ...formatedData]);
            })
            .finally(() => setloadingOwner(false));
    };

    const onChangeAddFilter = (values) => {
        setHasReset(false);
        setSelectedTags(values);
        const tags = filterByTags?.tags || {};
        if (Object.keys(tags)?.length < values?.length) {
            const newItem = values[values?.length - 1];
            if (newItem) {
                tags[newItem.id] = [];
            }
        } else {
            Object.keys(tags).map(key => {
                if (values.filter(item => item.id == key).length == 0) {
                    delete tags[key];
                }
            });
        }
        setFilterByTags(prev => ({
            ...prev,
            tags
        }));
    }

    useEffect(() => {
        getListOwner(1);
    }, [inputSearchOwner]);

    useEffect(() => {
        getListCustomFields(1);
    }, [inputSearchCustomField]);

    // const updateFilter = useCallback(
    //     debounce((listFilter) => onChangeFilter(listFilter), 1500),
    //     []
    // );

    useEffect(() => {
        if (!isFirstLoad) {
            const listCheckedTags = [];
            let listFilter: any = {
                created_by: ownerSelected?.map((owner) => owner?.id)?.join(','),
                q: keyword,
                status: statusSelected?.[0]?.value
            };
            if (statusSelected.length === 2) delete listFilter.status
            if (filterByTags?.tags) {
                Object.keys(filterByTags.tags).forEach((key) => {
                    let item: any = {
                        custom_field_id: key
                    };
                    if (filterByTags?.tags[key]?.length > 0) {
                        item = {
                            ...item,
                            data: filterByTags?.tags[key].map(tag => tag.name)
                        }
                    }
                    listCheckedTags.push(item);
                });
                listFilter = {
                    ...listFilter,
                    custom_fields: JSON.stringify(listCheckedTags)
                }
            }
            onChangeFilter(listFilter);
        }
    }, [ownerSelected, keyword, filterByTags, statusSelected]);

    return (
        <>
            <div className="filterLearningPath">
                {/* <SearchBox className="filterLearningPath-searchInput" onSubmit={(value) => setKeyword(value)} /> */}
                <SearchInput
                    className="filterLearningPath-searchInput"
                    onChange={(value) => setKeyword(value)}
                    value={keyword}
                />
                <SelectBox
                    label={translator("OWNER")}
                    onSearch={setInputSearchOwner}
                    onSelect={setOwnerSelected}
                    data={listOwners}
                    loading={loadingOwner}
                    selectedItems={ownerSelected}
                    total={pagination?.total}
                    showmore={() => getListOwner(pagination?.page + 1)}
                />

                <SelectBox
                    label={translator("STATUS", true)}
                    onSelect={setStatusSelected}
                    selectedItems={statusSelected}
                    data={[
                        { name: translator("COURSES.STATUS_DRAFT", true), value: 'draft' },
                        { name: translator("PUBLISHED", true), value: 'published' }
                    ]}
                    total={2}
                />
                
                {selectedTags?.map((item) => (
                    <div key={item.id}>
                        <DropdownAsync
                            placeholder={item.name}
                            loadOptions={(payload) => workspaceService.getCustomFieldsByNameMapDataService(params.id,
                                {
                                    ...payload,
                                    custom_field_id: item.id,
                                    order: 'asc'
                                }
                            )}
                            onChange={(value) => {
                                setFilterByTags(prev => {
                                    const tags = prev?.tags ?? {};
                                    tags[item.id] = value;
                                    return {
                                        ...prev,
                                        tags
                                    }
                                })
                            }}
                            onRemove={() => {
                                setSelectedTags(prev => {
                                    return prev.filter(tag => tag.id != item.id);
                                });
                                setFilterByTags(prev => {
                                    const tags = prev?.tags ?? {};
                                    delete tags[item.id];
                                    return {
                                        ...prev,
                                        tags
                                    }
                                });
                            }}
                            isRemoved={true}
                            isAll={true}
                        />
                    </div>
                ))}
                
                <DropdownAsync
                    className="dropdownAsync--actived"
                    placeholder={translator('ADD_FILTER')}
                    loadOptions={(payload) => workspaceService.getCustomFieldsService(params.id, {...payload, type: 'learning_path'})}
                    onChange={onChangeAddFilter}
                    paramsApi={{
                        ...payloadAddFilter,
                        order: "asc",
                        sort_by: "name"
                    }}
                    isReset={hasReset}
                    hasArrow={false}
                    selectedValue={selectedTags}
                >
                    <PlusIcon className="dropdownAsync--icon" aria-hidden="true" />
                    <span className='text-primary-500'>{translator('ADD_FILTER')}</span>

                </DropdownAsync>
                
                <div className="line"></div>

                <button
                    onClick={() => {
                        setOwnerSelected([]);
                        onChangeFilter({});
                        setFilterByTags(null);
                        setSelectedTags(null);
                        setOwnerSelected([])
                        setPayloadAddFilter(null);
                        setHasReset(prev => !prev);
                        setStatusSelected([])
                        setKeyword('')
                    }}
                    className="btn-reset"
                    disabled={ !ownerSelected?.length && !selectedTags?.length && !statusSelected?.length && !keyword}
                >
                    {translator('RESET')}
                </button>
            </div>
        </>
    );
};

export default Filter;
