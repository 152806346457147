import { Tab } from '@headlessui/react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import ClassroomProfileForm from './ClassProfileForm';
import workspaceMiddleware from 'middleware/workspace.middleware';
import ClassSession from './ClassSession';
import { UserContext } from 'contexts/User/UserContext';
import userMiddleware from 'middleware/user.middleware';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import {
    BookOpenIcon,
    CalendarIcon,
    ClipboardCheckIcon,
    InformationCircleIcon,
    UsersIcon,
    BriefcaseIcon,
    PuzzleIcon,
    StarIcon,
} from '@heroicons/react/outline';
import { LaptopIcon } from 'assets/icon';
import ClassAssignments from './ClassAssignments';
import ClassCalendar from './ClassCalendar';
import ClassGradeBookApollo from './ClassGradeBookApollo';
import ClassRecordOfWork from './ClassRecordOfWork';
import ClassResources from './ClassResources';
import ClassApo from './ClassApo';
import ClassStudents from './ClassStudents';
import ClassInstructors from './ClassInstructors';
import TabListMenu from './TabListMenu';
import GraduationCap from 'assets/icon/GraduationCap';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';

const listTab = (translator: Function) => {
    const type = [
        {
            name: translator('CLASSES.INFO'),
            hiddenWhenDeactivate: false,
            icon: <InformationCircleIcon className="w-4 h-4" />,
            href: 'information',
        },
        {
            name: translator('CLASSES.CALENDAR'),
            hiddenWhenDeactivate: true,
            icon: <CalendarIcon className="w-4 h-4" />,
            href: 'calendar',
        },
        {
            name: translator('CLASSES.SESSIONS'),
            hiddenWhenDeactivate: true,
            icon: <LaptopIcon className="w-4 h-4 stroke-current fill-current" />,
            href: 'sessions',
        },
        {
            name: translator('CLASSES.INSTRUCTORS'),
            hiddenWhenDeactivate: true,
            icon: <UsersIcon className="w-4 h-4 stroke-current" />,
            href: 'people',
        },
        {
            name: translator('CLASSES.STUDENTS'),
            hiddenWhenDeactivate: true,
            icon: <GraduationCap className="w-4 h-4 stroke-current" />,
            href: 'people',
        },
        {
            name: translator('CLASSES.ASSIGNMENT'),
            hiddenWhenDeactivate: true,
            icon: <BookOpenIcon className="w-4 h-4" />,
            href: 'assignment',
        },
        {
            name: translator('CLASSES.GRADEBOOK'),
            hiddenWhenDeactivate: true,
            icon: <ClipboardCheckIcon className="w-4 h-4" />,
            href: 'gradebook',
        },
        {
            name: translator('CLASSES.RECORD_OF_WORK'),
            hiddenWhenDeactivate: true,
            icon: <BriefcaseIcon className="w-4 h-4" />,
            href: 'record-of-work',
        },
        {
            name: translator('CLASSES.CLASS_RESOURCES'),
            hiddenWhenDeactivate: true,
            icon: <PuzzleIcon className="w-4 h-4" />,
            href: 'resources',
        },
        {
            name: translator('CLASSES.COINS'),
            hiddenWhenDeactivate: true,
            icon: <StarIcon className="w-4 h-4" />,
            href: 'coins',
        },
    ];
    return type;
};

const ClassDetail: React.FC = ({ children }) => {
    const { t: translator } = useTranslation();
    const { state }: any = useLocation();
    const params = useParams<{ id: string; classId: string }>();
    const search = useLocation().search;
    const tab = new URLSearchParams(search).get('tab');
    const [numOfCurrentTab, setNumOfCurrentTab] = useState<number>(0);

    const defaultIndex = useMemo(
        () =>
            tab
                ? [
                      'information',
                      'calendar',
                      'sessions',
                      'instructors',
                      'students',
                      'assignment',
                      'gradebook',
                      'record-of-work',
                      'resources',
                      'coin'
                  ].indexOf(tab)
                : 0,
        [tab]
    );
    const [selectedIndex, setSelectedIndex] = useState(defaultIndex < 0 ? 0 : defaultIndex);

    const { dispatch: workspaceDispatch, getWorkspaceDetailState } = useContext(GetWorkspaceContext);

    const { detail } = getWorkspaceDetailState.class;

    const listClassDetailTab = React.useMemo(
        () =>
            listTab(translator).filter(
                (i) => detail?.status !== 'deactivate' || (detail?.status === 'deactivate' && i.hiddenWhenDeactivate === false)
            ),
        [detail, translator]
    );

    const { dispatch } = useContext(UserContext);

    useEffect(() => {
        if (state?.tabSelect) setSelectedIndex(state?.tabSelect);
    }, []);

    useEffect(() => {
        userMiddleware.getUser(dispatch);
        return () => {
            workspaceMiddleware.setCurrentRouteDetail(workspaceDispatch, []);
        };
    }, []);

    useEffect(() => {
        workspaceMiddleware.getClassDetail(dispatch, {
            classId: params.classId,
            workspaceId: params.id,
        });
    }, [params.id]);
    useEffect(() => {
        if (detail) {
            workspaceMiddleware.setCurrentRouteDetail(dispatch, [
                {
                    name: detail.name,
                    routeId: 'classId',
                },
            ]);
            workspaceMiddleware.resetClassStatus(dispatch);
        }
    }, [detail]);

    const checkNumOfCurrentTab = () => {
        const parentContainerWidth = document.getElementById('tab_list')?.offsetWidth;
        let count = 0;
        let width = 0;
        const elements: any = document.getElementsByClassName('sub-tab');
        const length = elements.length;
        for (let i = 0; i < length; i++) {
            width = width + elements[i]?.offsetWidth;
            count++;
            if (width > parentContainerWidth) {
                count--;
                break;
            }
        }
        setNumOfCurrentTab(count);
    };

    useEffect(() => {
        if (listClassDetailTab) {
            checkNumOfCurrentTab();
        }
    }, [listClassDetailTab]);

    return (
        <>
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT')}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    { name: translator('DASHBOARD.SIDEBAR.CLASSES'), href: `/workspace/${params.id}/management/class`, className: '' },
                    { name: detail?.name, href: '', className: '' },
                ]}
            />
            <div className="p-4 text-xl font-semibold text-ooolab_dark_1 overflow-ellipsis whitespace-nowrap overflow-hidden">
                {detail?.name}
            </div>
            <div className="w-full">
                <Tab.Group
                    defaultIndex={defaultIndex < 0 ? 0 : defaultIndex}
                    selectedIndex={selectedIndex}
                    onChange={setSelectedIndex}
                >
                    <Tab.List className="bg-white opacity-100 z-2 w-full text-sm px-2 border-b border-ooolab_bar_color flex justify-between items-center">
                        <div id="tab_list"  className=" flex w-full max-w-[calc(100vw-16rem)] overflow-x-auto hide-scrollbar">
                            {listClassDetailTab.map((i, index) => (
                                <Tab
                                    key={index}
                                    className={({ selected }) => {
                                        const tabClassName = `sub-tab flex justify-start items-center space-x-1 p-2 lg:px-4 lg:py-3 text-left font-semibold outline-none whitespace-nowrap
                                         ${selected ? 'text-primary-500 border-b-2 border-enterprise' : 'text-ooolab_dark_2'}`;
                                        return tabClassName;
                                    }}
                                >
                                    <span>{i.icon}</span>
                                    <span>{i.name}</span>
                                </Tab>
                            ))}
                        </div>
                        <div
                            className={`text-ooolab_dark_2 py-2 border-l border-l-gray-200 font-semibold whitespace-nowrap pl-2 flex item-center justify-center ${
                                detail?.status === 'deactivate' ? 'hidden' : ''
                            }`}
                        >
                            {numOfCurrentTab < listClassDetailTab?.length && (
                                <TabListMenu
                                    setSelectedIndex={setSelectedIndex}
                                    position={numOfCurrentTab}
                                    menuList={listClassDetailTab?.slice(numOfCurrentTab)?.map(el => ({
                                        ...el,
                                        name: translator(el?.name)
                                    }))}
                                />
                            )}
                            <span className="ml-2">{translator('CLASSES.CLASS_ID')}</span>
                            <span className="bg-ooolab_light_100 text-ooolab_dark_1 ml-2 px-4">{detail?.id || ''}</span>
                        </div>
                    </Tab.List>
                    <Tab.Panels className="z-1 h-full outline-none px-5">
                        <Tab.Panel>
                            <ClassroomProfileForm />
                        </Tab.Panel>
                        <Tab.Panel>
                            <ClassCalendar />
                        </Tab.Panel>
                        <Tab.Panel className="h-full">
                            <ClassSession sessionName={detail?.name} classType={detail?.class_type}/>
                        </Tab.Panel>
                        <Tab.Panel>
                            <ClassInstructors sessionName={detail?.name} classType={detail?.class_type}/>
                        </Tab.Panel>
                        <Tab.Panel>
                            <ClassStudents sessionName={detail?.name} classType={detail?.class_type}/>
                        </Tab.Panel>
                        <Tab.Panel className="h-full">
                            <ClassAssignments />
                        </Tab.Panel>
                        <Tab.Panel className="h-full outline-none">
                            <ClassGradeBookApollo />
                        </Tab.Panel>
                        <Tab.Panel className="h-full outline-none">
                            <ClassRecordOfWork />
                        </Tab.Panel>
                        <Tab.Panel className="h-full outline-none">
                            <ClassResources />
                        </Tab.Panel>
                        <Tab.Panel className="h-full outline-none">
                            <ClassApo />
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </>
    );
};

export default ClassDetail;
