import { FC, useState, useEffect, useContext, useMemo } from 'react';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tab } from '@headlessui/react';
import { classNames } from 'utils/commonFun';
import rolesPermissionsService from 'services/rolesPermissions.service';
import { ButtonBack, ButtonPrimary } from '_shared';
import RolesPermssionOverview from './RolesPermssionOverview/RolesPermssionOverview';
import { defaultRole, roleType } from '../RolesPermissionsContants';
import RolesPermissionMember from './RolesPermissionMember/RolesPermissionMember';
import DialogComponent from 'components/Dialog/DialogComponent';
import DeleteRole from './DeleteRole';
import { UserContext } from 'contexts/User/UserContext';

export const PERMISSION_TAB = [
    {
        name: 'OVERVIEW',
        value: 'overview',
        index: 0,
    },
    {
        name: 'MEMBERS',
        value: 'members',
        index: 1,
    },
];
interface RolesPermissionDetailsProps {
    breadcrumb?: boolean,
}

const RolesPermissionDetails: FC<RolesPermissionDetailsProps> = ({ breadcrumb = true }) => {
    const params: any = useParams();
    const { t } = useTranslation();
    const history = useHistory();
    const search = useLocation()?.search;
    const tab = new URLSearchParams(search).get('tabPermission');
    const [tabSelect, setTabSelect] = useState<any>(null);
    const [roleData, setRoleData] = useState<any>(undefined);
    const getRoleDtails = () => {
        rolesPermissionsService.getDetailsRoles(params?.id, params?.roleId || state?.roleId).then((res) => {
            setRoleData(res?.data);
        });
    };
    const { location } = history;
    const { state }: { state: any } = location;

    const [openModalDeleteRole, setOpenModalDeleteRole] = useState<boolean>(false);

    useEffect(() => {
        getRoleDtails();
    }, [state?.roleId, params?.roleId]);
    const {
        userState: { result },
    } = useContext(UserContext);

    const isDisable = useMemo(() => {
        if (roleData?.role_type === roleType.default) {
            if (roleData?.name === defaultRole.instructor) return false
            return true
        }
        return false
    }, [roleData])

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`ROLES_PERMISSIONS.${str}`, opt));
    return (
        <>
            {breadcrumb && <PageBreadcrumb
                pageNameDefault={t('DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT')}
                containerClassName=" z-10 sticky top-0"

                pages={[{
                    name: t('DASHBOARD.SIDEBAR.ROLES_PERMISSIONS'),
                    href: `/workspace/${params?.id}/management/roles-permissions`,
                    className: '',
                },
                { name: roleData?.name, href: '', className: '' },]}
            />}

            <div className="relative text-xs font-semibold">
                {roleData?.role_type !== roleType.default && <div className="absolute right-5 top-[22px]">
                    <ButtonPrimary type="button" onClick={() => {
                        setOpenModalDeleteRole(true)
                    }} className="buttonPrimary-danger !bg-white !text-red-500">
                        {translator('DELETE_ROLE')}
                    </ButtonPrimary>
                </div>}
                <div className='flex items-center gap-4 px-5 pt-6 '>
                    <div className="flex-none w-6">
                        <ButtonBack
                            onClick={() => {
                                history.push(`/workspace/${params?.id}/setting?tab=role`)
                            }}
                            hideLabel={true}
                        />
                    </div>
                    <h1 className='text-xl font-semibold text-gray-800 truncate flex-none'>
                        {roleData?.name}
                    </h1>
                </div>


                <Tab.Group
                    onChange={(tab: number) => {
                        history.push(`/workspace/${params?.id}/setting?tab=role&tabPermission=${PERMISSION_TAB[tab].value}`, {
                            roleId: state?.roleId
                        });
                    }}
                    defaultIndex={tabSelect}
                    selectedIndex={tabSelect}
                >
                    <Tab.List className="border-b flex items-end space-x-3 pt-5 px-5">
                        {PERMISSION_TAB?.map((tab, index) => (
                            <Tab
                                key={index}
                                className={({ selected }) =>
                                    classNames(
                                        'mr-3 px-3 py-3 outline-none font-semibold',
                                        selected ? 'text-blue-500  border-b border-blue-500' : ' text-gray-500'
                                    )
                                }
                            >
                                {translator(tab.name)}
                            </Tab>
                        ))}
                    </Tab.List>
                    <Tab.Panels className="bg-gray-50 min-h-[calc(90vh-60px)] ">
                        <Tab.Panel className={'outline-none'}>
                            <RolesPermssionOverview data={roleData} submitted={getRoleDtails} isDisable={isDisable} roleId={state?.roleId} />
                        </Tab.Panel>
                        <Tab.Panel className={'outline-none py-5'}>
                            <RolesPermissionMember data={roleData} />
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div >
            <DialogComponent
                isOpen={openModalDeleteRole}
                title={translator("DELETE_ROLE")}
                onCloseModal={() => setOpenModalDeleteRole(false)}
                maskClosable={false}
                styles="max-w-lg"
                child={
                    <DeleteRole submitted={() => {
                        history.push(`/workspace/${params?.id}/management/roles-permissions`)
                        setOpenModalDeleteRole(false);
                    }}
                        onCloseModal={() => setOpenModalDeleteRole(false)} roleId={state?.roleId} roleData={roleData} />

                }
            />
        </>
    );
};

export default RolesPermissionDetails;
