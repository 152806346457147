import React from 'react';
import ManagementMasterPage from 'pages/RouteMasterPage';
import ManagePermissionWrapper from '../ManagePermissionWrapper';
import RewardDetails from 'components/Management/RewardDetails';

const RewardDetailsPage = () => {
    return (
        <ManagePermissionWrapper>
            <RewardDetails>
                <ManagementMasterPage />
            </RewardDetails>
        </ManagePermissionWrapper>
    );
};

export default RewardDetailsPage;
