import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PlusSmIcon } from '@heroicons/react/outline';
import Table from './Table/Table';
import { useTranslation } from 'react-i18next';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { ButtonPrimary } from '_shared';
import Filter from './Filter/Filter';
import './Learner.scss'
import DialogComponent from 'components/Dialog/DialogComponent';
import ModalNewEnrollment from '../Modals/ModalNewEnrollment';
import courseService from 'services/course.service';
import Pagination from 'components/V2/Pagination';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import { useAppSelector } from 'hooks/hooks';
import { debounce } from 'lodash';

const Learner: React.FC = ({ children }) => {
    const { t: translator } = useTranslation();
    const params: { id: string, courseId: string } = useParams();
    const [isOpenModalNewEnrollment, setOpenModalNewEnrollment] = useState(false)
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [indexPage, setIndexPage] = useState('0-0');
    const [filter, setFilter] = useState(undefined)
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'updated_at',
        total: 0,
    });

    const workspaceId = params.id;
    const courseId = params.courseId;
    const {setting} = useAppSelector((state) => state.course);   

    const getdata = (page: number | string = 1, filter = undefined) => {
        setLoading(true);
        const payload = {
            ...pagination,
            ...filter,
            page,
            workspaceId
        };
        delete payload?.total;
        courseService.getAllCourseLearner(workspaceId, courseId, payload)
            .then((res: any) => {
                setData(res?.data?.items);
                setPagination({ ...pagination, ...payload, total: res?.data?.total, page: res?.data?.page });
                setIndexPage(getIndexPage(payload?.page, pagination?.per_page, res?.data?.items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const handlePagination = (page: number) => {
        getdata(page);
    };

    const updateFilter = useCallback(
        debounce((page, listFilter) => getdata(page, listFilter), 1000),
        []
    );

    useEffect(() => {
        updateFilter(1, filter);
    }, [filter]);

    const onChangeFilter = (filter) => {
        setFilter(filter)
    };

    return (
        <div>
            <div className="CourseListEnrollment">
                <div className="CourseListEnrollment-filter">
                    <Filter onChangeFilter={onChangeFilter} />
                    {checkPermission('coursePeopleEnrollment', 'U') && <div className="">
                        <ButtonPrimary type="button" onClick={() => setOpenModalNewEnrollment(true)}>
                            <span className="flex">
                                <PlusSmIcon className="PlusIcon" aria-hidden="true" />
                                <span> {translator('COURSES.ENROLL')}</span>
                            </span>
                        </ButtonPrimary>
                    </div>}

                </div>

                <div className="CourseListEnrollment-main">
                    <div className="wrapper-content">
                        <div className="scrollbar">
                            {loading && (
                                <div className="loading">
                                    <CircleSpin color="text-primary-500" />
                                </div>
                            )}
                            {!loading && <Table data={data} refreshData={() => getdata()} />}
                        </div>
                        <Pagination
                            total={pagination.total}
                            per_page={pagination.per_page}
                            callback={(e) => handlePagination(e)}
                            indexPage={indexPage}
                            page={pagination?.page}
                        />
                    </div>
                </div>
                {isOpenModalNewEnrollment && <DialogComponent
                    isOpen={isOpenModalNewEnrollment}
                    onCloseModal={() => setOpenModalNewEnrollment(false)}
                    title={translator("COURSES.NEW_LEARNER")}
                    stylesTitle='text-base'
                    styles='max-w-2xl'
                    child={
                        <ModalNewEnrollment isUseCredit={setting?.enrollment_type === 'credit'} 
                            onCloseModal={(isRefesh?: boolean) => {
                                if (isRefesh) getdata()
                                setOpenModalNewEnrollment(false)
                            }}
                        />
                    }
                />}
            </div>
        </div>

    );
};

export default Learner;
