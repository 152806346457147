import { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EditIcon } from 'assets/icon/EditIcon';
import ActionsButton from 'components/ActionsButton';
import { checkPermission } from 'utils/commonFun';
import { PlusIcon, TrashIcon } from '@heroicons/react/outline';

interface ActionTemplateProps {
    data: any;
    createdCourse: any;
    onDeleteTemplate: any;
}

const ActionTemplate: FC<ActionTemplateProps> = ({
    data,
    createdCourse,
    onDeleteTemplate
}) => {
    const params: any = useParams();
    const { t: translator } = useTranslation();
    const history = useHistory();

    return (
        <>
            <ActionsButton
                styleOptions="top-0 right-[35px]"
                menus={[
                    {
                        label: translator("CREATE"),
                        icon: <PlusIcon width="18" height="20" className="text-gray-900" />,
                        isDisabled: !checkPermission('course', 'U') || data?.status === "draft",
                        action: () => createdCourse(data),
                        contentTip: translator("COURSES.CREATE_COURSE_TIP")
                    },
                    {
                        label: translator("EDIT"),
                        icon: <EditIcon />,
                        isDisabled: !checkPermission('course', 'U'),
                        action: () => { history.push(`/workspace/${params.id}/management/courses/${data?.id}/template`) }
                    },
                    {
                        label: translator("DELETE"),
                        icon: <TrashIcon width="18" height="16" className="text-gray-900" />,
                        isDisabled: !checkPermission('course', 'U'),
                        action:() => onDeleteTemplate(data),
                    },
                ]}
            />
        </>
    );
};

export default ActionTemplate;
