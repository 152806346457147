import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsHorizontalIcon } from '@heroicons/react/outline';
import { classNames } from 'utils/commonFun';

interface ITabListMenu {
    menuList: any;
    setSelectedIndex: any;
    position: number;
}

const TabListMenu: React.FC<ITabListMenu> = ({ menuList, setSelectedIndex, position }) => {
    return (
        <Menu as="div" className="relative inline-block text-left z-9999">
            {({ open }) => (
                <>
                    <div>
                        <Menu.Button className="flex justify-center items-center text-ooolab_dark_1 hover:bg-ooolab_bg_sub_tab_hover hover:text-white focus:outline-none w-6 h-6 rounded-full">
                            <DotsHorizontalIcon className="w-5 h-5" aria-hidden="true" />
                        </Menu.Button>
                    </div>

                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="z-9999 shadow-ooolab_box_shadow_container origin-top-right absolute right-ooolab_inset_100 -top-full mt-2 w-fit rounded-header_menu divide-y divide-gray-100 focus:outline-none">
                            <div className="py-1 flex-col flex">
                                {menuList?.map((i, index) => {
                                    return (
                                        <Menu.Item key={index}>
                                            {({}) => (
                                                <div
                                                    className={`flex items-center px-2 w-56 bg-white hover:bg-ooolab_bg_sub_tab_hover cursor-pointer `}
                                                    onClick={() => setSelectedIndex(index + position)}
                                                >
                                                    <>
                                                        <span>{i.icon}</span>
                                                        <span className={classNames('block px-4 py-2 text-sm')}>{i.name}</span>
                                                    </>
                                                </div>
                                            )}
                                        </Menu.Item>
                                    );
                                })}
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
};

export default TabListMenu;
