import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryParamsURL } from 'hooks/useQueryParamsURL';
import { CoursePropertyTab } from './CoursePropertyTab';
import { LearnerPropertyTab } from './LearnerPropertyTab';
import './custom-property.scss';

const subTabProperty = [
    {
        index: 0,
        key: 'COURSE',
        title: 'COURSE',
        url: 'course',
    },
    {
        index: 1,
        key: 'LEARNER',
        title: 'LEARNER',
        url: 'learner',
    },
];

export const CustomProperty = ({ tab }) => {
    const { t } = useTranslation();
    const query = useQueryParamsURL();
    const history = useHistory();
    const params: { id: string; courseId: string } = useParams();

    const [currentTab, setCurrentTab] = useState<string>();

    const workspaceId = params.id;

    useEffect(() => {
        let subtab = subTabProperty[0]?.url;
        if (!!query.get('subtab')) {
            subtab = subTabProperty.find((el) => query.get('subtab') === el.url)?.url;
        }
		setCurrentTab(subtab);
        const newQueryParams = new URLSearchParams(query);
        newQueryParams.set('tab', tab);
        newQueryParams.set('subtab', subtab);
        history.replace(`/workspace/${workspaceId}/setting?${newQueryParams.toString()}`);
    }, []);

    return (
        <div className='custom-property'>
            <div className="flex items-center space-x-1 tab-property-container">
                {subTabProperty.map((st, idx) => (
                    <p
                        key={idx}
                        className={`px-4 py-2.5 text-sm font-semibold cursor-pointer rounded-t-lg bg-white shadow-card_common tab-property ${
                            currentTab === st.url ? 'text-primary-500 tab-property-active' : 'text-gray-500 !bg-gray-50'
                        }`}
                        onClick={() => {
                            setCurrentTab(st.url);
                            history.replace(`/workspace/${workspaceId}/setting?tab=${tab}&subtab=${st.url}`);
                        }}
                    >
                        {t(st.title)}
                    </p>
                ))}
            </div>
            <div className="bg-white text-gray-800 text-sm font-normal rounded-r-lg rounded-b-lg shadow-card_common">
                {currentTab === subTabProperty[0].url && <CoursePropertyTab tab={tab} subtab='course' />}
                {currentTab === subTabProperty[1].url && <LearnerPropertyTab tab={tab} subtab={query.get('subtab')} />}
            </div>
        </div>
    );
};
