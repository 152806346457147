
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import  './InstructorSchedule.scss'

export enum TAB {
    SESSION_LIST = 'SESSION_LIST',
    CALENDAR = 'CALENDAR'
}

export default function Tab() {
    const search = useLocation()?.search;
    const tab = new URLSearchParams(search).get('tab') || 'SESSION_LIST'
    const params: { id: string } = useParams();
    const history = useHistory();
    const workspaceId = params?.id;
    const {t: translator} = useTranslation();
 
    return (
        <div className="Tab">
            <div
                className={`TabItem ${tab === TAB.SESSION_LIST ? 'active' : ''}`}
                onClick={() => history?.push(`/workspace/${workspaceId}/instructor-dashboard/instructor-schedule?tab=${TAB.SESSION_LIST}`)}
            >
                {translator(`DASHBOARD.TEACHER.${TAB.SESSION_LIST}`)}
            </div>
            <div
                className={`TabItem ${tab === TAB.CALENDAR ? 'active' : ''}`}
                onClick={() => history?.push(`/workspace/${workspaceId}/instructor-dashboard/instructor-schedule?tab=${TAB.CALENDAR}`)}
            >
                {translator(`DASHBOARD.TEACHER.${TAB.CALENDAR}`)}
            </div>
        </div>
    );
}
