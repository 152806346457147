export const ValidateFileUpload = (fileExtension) => {
    let allowedExtension = ['doc', 'docx', 'png', 'jpg', 'jpeg', 'mp3', 'mp4', 'ppt', 'pptx', 'pdf', 'jfif', 'pjpeg', 'pjp', 'xlsx'];
    let isValidFile = false;
    for (let index in allowedExtension) {
        if (fileExtension === allowedExtension[index]) {
            console.log('fileExtension: ', fileExtension);
            isValidFile = true;
            break;
        }
    }
    return isValidFile;
};
export const ConvertMineTypeResource = (extension) => {
    let mineType = '';
    switch (extension) {
        case 'doc':
            mineType = 'application/msword';
            break;
        case 'docx':
            mineType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            break;
        case 'png':
            mineType = 'image/png';
            break;
        case 'jpg':
            mineType = 'image/jpeg';
            break;
        case 'jfif':
            mineType = 'image/jpeg';
            break;
        case 'pjpeg':
            mineType = 'image/jpeg';
            break;
        case 'pjp':
            mineType = 'image/jpeg';
            break;
        case 'jpeg':
            mineType = 'image/jpeg';
            break;
        case 'mp4':
            mineType = 'video/mp4';
            break;
        case 'pdf':
            mineType = 'application/pdf';
            break;
        case 'pptx':
            mineType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
            break;
        case 'ppt':
            mineType = 'application/vnd.ms-powerpoint';
            break;
        case 'xlsx':
            mineType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            break;
        default:
            break;
    }
    return mineType;
};

export const VALIDATE_FIELD = {
    NUMBER_FLOAT: /^-?\d+\.?\d*$/,
    NUMBER_: /^[0-9][0-9]*$/,
    MUNUTES: /^[0-5]?[0-9]$/,
    NUMBER: /^0*?[1-9]\d*$/,
    NUMBER_0_9: /^0*?[0-9]\d*$/,
    NUMBER_1_1000: /^(?:(?:(?:1000|[1-9][0-9][0-9]|[1-9][0-9]|[1-9]) ){0})(?:1000|[1-9][0-9][0-9]|[1-9][0-9]|[1-9])$/,
    NUMBER_1_100: /^[1-9]$|^[1-9][0-9]$|^(100)$/,
    AT_LEAST_2_CHARACTER: /\d*(?:[a-zA-Z]){2,}\d*/,
    FILE: /(?:\.([^.]+))?$/,
    NUMBER_0_100: /^[0-9]$|^[1-9][0-9]$|^(100)$/,
    NUMBER_1_100000: /^(100000|[1-9][0-9]{0,4})$/,
    NUMBER_0_100000: /^(100000|[1-9][0-9]{0,4})$/,

    NUMBER_0_100_FLOAT_1: /^\d*\.?\d{0,1}$/,
};
