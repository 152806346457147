import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { CourseType } from 'types/Courses.type';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import './ViewTable.scss'
import NO_CONTENT from 'assets/img/empty-state/noAssignment.png'
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { ASSIGNMENT_STATUS } from 'constant/course.const';
import ActionMenu from './ActionMenu';
import { UserContext } from 'contexts/User/UserContext';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/outline';
import { timeConvert } from 'utils/commonFun';

interface IViewTable {
    data: CourseType[];
    refreshData: Function;
    order: string;
    onChangeOrder: (value: string) => void;
    selected: any;
    setSelected: Function;
}

const ViewTable: FC<IViewTable> = ({
    data,
    refreshData,
    order,
    onChangeOrder,
    selected,
    setSelected,
}) => {
    const { t } = useTranslation();

    const [isAll, setIsAll] = useState<boolean>(false);
    const [isFlagAll, setIsFlagAll] = useState<boolean>(false);

    const translator = (str: string, newKey?: boolean, opt?: any) =>
        newKey ? t(str, opt) : t(`ASSIGNMENT_CENTER.${str}`, opt);
    const {
        userState: { result },
    } = useContext(UserContext);

    const onSelect = (assignment) => {
        const hasCheck = !!selected?.find(item => item?.id === assignment?.id);
        if (hasCheck) {
            setSelected(selected.filter(item => item?.id !== assignment.id));
        } else {
            setSelected([...selected, assignment]);
        }
    }

    useEffect(() => {
        if (isFlagAll) {
            if (isAll) {
                setSelected(data);
            } else {
                setSelected([]);
            }
            setIsFlagAll(!isAll);
        }
    }, [isAll]);

    useEffect(() => {
        if (selected?.length) {
            setIsAll(true);
        } else {
            setIsAll(false);
        }
    }, [selected]);

    const columns = useMemo(
        () => [
            {
                Header: () => (
                    <div className='w-full px-2 flex'>
                        <input 
                            type='checkbox' 
                            checked={isAll} 
                            onChange={() => {
                                if (isAll) {
                                    setSelected([]);
                                } else { 
                                    setIsFlagAll(true); 
                                }
                                setIsAll(!isAll);
                            }}
                            placeholder={''}
                        />
                    </div>
                ),
                Cell: ({ row }: any) => {
                    const hasCheck = !!selected?.find(item => item?.id === row?.original?.id);
                    return (
                        <div className='p-2 flex w-full'>
                            <input 
                                type='checkbox' 
                                checked={hasCheck} 
                                onChange={() => onSelect(row?.original)}
                                placeholder={''}
                            />
                        </div>
                    )
                },
                accessor: 'assignment_selected',
                width: "30",
                sticky: 'left'
            },
            {
                Header: () => <RenderTableHeader value={translator('Assignment')} />,
                Cell: ({ row }) => {
                    return <div className='text-sm text-gray-800 truncate py-0.5'>
                        <span className='text-gray-500'>(ID: {row.original?.id})</span> {row.original?.name}
                    </div>
                },
                accessor: 'title', // accessor is the "key" in the data
                width: 320,
                sticky: 'left'
            },
            {
                Header: () => <RenderTableHeader value={translator('LEARNER')} />,
                accessor: 'learner',
                Cell: ({ row }: { row: any }) => {
                    return (
                        <div className="flex items-center px-2">
                            <div className="flex-none w-6">
                                <img alt="avatar-icon" className="h-6 w-6 rounded-full" src={row?.original?.learner?.avatar_url} />
                            </div>
                            <div className='text-xs font-normal ml-2 w-full text-gray-800 truncate text-ellipsis'>{row?.original?.learner?.display_name}</div>
                        </div>
                    )
                },
                minWidth: 180,
                sticky: 'left'
            },
            {
                Header: () => <RenderTableHeader value={translator('Learning_Group')} />,
                accessor: 'learning_group',
                Cell: ({ row }: { row: any }) => {
                    if (!row?.original?.learning_group) {
                        return <div className='text-xs pl-2'>-</div>;
                    }
                    return (
                        <div className="flex items-center px-2">
                            <div className="flex-none w-6">
                                <img alt="avatar-icon" className="h-6 w-6 rounded-full" src={row?.original?.learning_group?.avatar_url} />
                            </div>
                            <div className='text-xs text-gray-800 font-normal ml-2 w-full truncate text-ellipsis'>{row?.original?.learning_group?.name}</div>
                        </div>
                    )
                },
                width: 160,
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSE')} />,
                accessor: 'course',
                Cell: ({ row }: any) => {
                    return <RenderTableCell className='!text-sm' value={row?.original?.course?.title} />;
                },
                width: 180
            },
            {
                Header: () => <>

                    <div
                        className="flex items-center cursor-pointer text-primary-500 pl-2"
                        onClick={() => onChangeOrder(order == 'desc' ? 'asc' : 'desc')}
                    >
                        <p className="text-xs font-semibold">{translator('STATUS')}</p>
                        {order == 'desc' ? (
                            <ArrowDownIcon className="w-4 h-4 ml-1" />
                        ) : (
                            <ArrowUpIcon className="w-4 h-4 ml-1" />
                        )}
                    </div>
                </>,
                accessor: 'state',
                Cell: ({ row }: any) => {
                    if (!row?.original?.state) return <RenderTableCell value='' />;
                    return (
                        <div className={`pl-ooolab_p_1_e flex items-center`}>
                            <div className={`status whitespace-nowrap status-${row?.original?.state}`}>
                                {ASSIGNMENT_STATUS(t)[row?.original?.state]}
                            </div>
                        </div>

                    );
                },
                width: 160
            },
            {
                Header: () => <RenderTableHeader value={translator('Grade')} />,
                accessor: 'final_score',
                Cell: ({ row }: any) => {
                    return (
                        <RenderTableCell className='!text-sm' value={row?.original?.final_score ? `${Math.round(row?.original?.final_score)}/100` : ''} />
                    );
                },
                width: 140
            },
            {
                Header: () => <RenderTableHeader value={translator('Completion')} />,
                accessor: 'completion_percentage',
                Cell: ({ row }: any) => {
                    return (
                        <RenderTableCell className='!text-sm' value={row?.original?.completion_percentage ? `${row?.original?.completion_percentage}%` : ''} />
                    );
                },
                width: 140
            },
            {
                Header: () => <RenderTableHeader value={translator('Attempts')} />,
                accessor: 'attempt',
                Cell: ({ row }: any) => {
                    return (
                        <RenderTableCell className='!text-sm' value={row?.original?.attempt ? `${row?.original?.attempt}` : ''} />
                    );
                },
                width: 140
            },
            {
                Header: () => <RenderTableHeader value={translator('Time_Spent')} />,
                accessor: 'total_time_spent',
                Cell: ({ row }: any) => {
                    return (
                        <RenderTableCell className='!text-sm' value={row?.original?.total_time_spent ? 
                            `${timeConvert((row?.original?.total_time_spent || 0) / 60)}` : ''} />
                    );
                },
                width: 140
            },
            {
                Header: () => <RenderTableHeader value={translator('DUE_DATE')} />,
                Cell: ({ row, column }: { row: any; column: any }) => {
                    return (
                        <RenderTableCell value={row?.original?.end_time && dayjs(row?.original?.end_time).format("DD/MM/YYYY")} />
                    )

                },
                accessor: 'end_date',
                width: 120
            },
            {
                Header: () => <RenderTableHeader value={translator('COMPLETED_DATE')} />,
                Cell: ({ row, column }: { row: any; column: any }) => {
                    return (
                        <RenderTableCell value={row?.original?.completion_date && dayjs(row?.original?.completion_date).format("DD/MM/YYYY")} />
                    )

                },
                accessor: 'completed_date',
                width: 120
            },
            {
                Header: () => <RenderTableHeader value={translator('Graded_by')} />,
                Cell: ({ row, column }: { row: any; column: any }) => {
                    if (!row?.original?.graded_by) {
                        return <div className='text-xs pl-2'>-</div>;
                    }
                    return (
                        <div className="flex items-center px-2">
                            <div className="flex-none w-6">
                                <img alt="avatar-icon" className="h-6 w-6 rounded-full" src={row?.original?.graded_by?.avatar_url} />
                            </div>
                            <div className='text-xs font-normal ml-2 w-full text-gray-800 truncate text-ellipsis'>{row?.original?.graded_by?.display_name}</div>
                        </div>
                    )

                },
                accessor: 'Graded_by',
                width: 180
            },
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="h-full flex items-center justify-center">
                            <ActionMenu 
                                isDisable={!!selected?.length} 
                                data={{ 
                                    id: row?.original?.id, 
                                    courseId: row?.original?.course?.id, 
                                    end_time: row?.original?.end_time, 
                                    time_zone: result?.time_zone 
                                }} 
                                status={row.original?.state} 
                                refreshData={refreshData} 
                            />
                        </div>
                    );

                },
                accessor: 'action',
                width: 40,
            },

        ],
        [data, selected, isAll]
    );

    return (
        <Table
            data={data}
            columns={columns}
            emptyContent={translator('NO_ASSIGNMENT')}
            emptyIcon={NO_CONTENT}
        />
    );
};

export default ViewTable;
