import { ListSection } from './ListSection';

export const LearningMaterials = ({
    sections,
    setSections,
    sectionSelected,
    setSectionSelected,
    handleSelectContentToPreview,
    contentSelected,
    handleDeleteFileOfSection,
    handleDeleteSection,
    typeSelect,
    setOnSaving
}) => {
    return (
        <ListSection
            sections={sections}
            contentSelected={contentSelected}
            setSections={setSections}
            sectionSelected={sectionSelected}
            setSectionSelected={setSectionSelected}
            handleSelectContentToPreview={handleSelectContentToPreview}
            handleDeleteFileOfSection={handleDeleteFileOfSection}
            handleDeleteSection={handleDeleteSection}
            typeSelect={typeSelect}
            setOnSaving={setOnSaving}
        />
    );
};
