import { useMemo, useState } from 'react';
import { PlusIcon } from '@heroicons/react/outline';
import DialogComponent from 'components/Dialog/DialogComponent';
import FromLibrary from 'components/Library/LessonBuilder/components/Content/FromLibrary/FromLibrary';
import { TYPE_CREATE_CONTENT } from 'components/Library/LessonBuilder/constants';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from '_shared';
import "./CourseSyllabus.scss";
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { useParams } from 'react-router-dom';
import { checkPermission } from 'utils/commonFun';
import { courseTemplateFetch, createCourseTemplateSyllabus } from 'store/features/courseTemplateSlice';
import { TemplateSyllabusList } from './components/SyllabusList/TemplateSyllabusList';

export const CourseTemplateSyllabus = () => {
    const dispatch = useAppDispatch();
    const { syllabuses } = useAppSelector(state => state.courseTemplate.data);
    const params: { id: string, courseId: string } = useParams();
    const { t } = useTranslation();

    const [isOpenModalLibrary, setIsOpenModalLibrary] = useState(false);

    const handleAddLearningPath = async (learningPath) => {
        let createAPIS = learningPath?.map((item, idx) => ({
            learning_path_id: item?.id,
            order_number: idx + 1
        }));
        await dispatch(createCourseTemplateSyllabus({
            id: params.courseId,
            workspaceId: params.id,
            syllabuses: createAPIS
        }))
        dispatch(
            courseTemplateFetch({
                workspaceId: params.id,
                id: params.courseId,
            })
        );
    }

    const listAdded = useMemo(() => {
        if (syllabuses) {
            return syllabuses?.map((item: any) => item?.learning_path_id);
        }
        return [];
    }, [syllabuses]);

    return (
        <div className="courseSyllabus bg-white rounded">
            <div className="courseSyllabus-title">{t('COURSES.ACADEMICS.ACADEMICS')}</div>
            <div className="courseSyllabus-tab hidden">
                <div className="courseSyllabus-tab-item courseSyllabus-tab--itemActive hidden">
                    {t('COURSES.SYLLABUS')}
                </div>
                <div className="courseSyllabus-tab-item hidden">
                    {t('COURSES.RESOURCES')}
                </div>
            </div>
            <div className="courseSyllabus-content">
                <div className="courseSyllabus-header">
                    <h2 className="courseSyllabus-name">{t('COURSES.SYLLABUS')}</h2>
                    {checkPermission('courseAcademic', 'U') && <ButtonPrimary type="button" onClick={() => setIsOpenModalLibrary(true)} className="ml-auto">
                        <span className="flex items-center text-sm"><PlusIcon className="w-4 h-4 mr-1.5" /> {t("COURSES.ADD_LEARNING_PATH")}</span>
                    </ButtonPrimary>}

                </div>
                <div className="flex py-5 min-h-[416px]">
                    {<TemplateSyllabusList hanldeAddLearningPath={() => setIsOpenModalLibrary(true)} />}
                </div>
            </div>
            <div></div>
            {isOpenModalLibrary && (
                <DialogComponent
                    isOpen={isOpenModalLibrary}
                    onCloseModal={() => setIsOpenModalLibrary(false)}
                    hasNonTitle
                    styles="max-w-[70vw] min-h-[480px] my-0 rounded-lg !p-0 pb-4"
                    isShowClose={false}
                    child={
                        <FromLibrary
                            createContentType={TYPE_CREATE_CONTENT.LEARNING_PATH}
                            type={TYPE_CREATE_CONTENT.LEARNING_PATH}
                            title={t("COURSES.ADD_LEARNING_PATH")}
                            onCloseModal={() => setIsOpenModalLibrary(false)}
                            handleAddContentToSection={handleAddLearningPath}
                            hasLesson={true}
                            listAdded={listAdded}
                        />
                    }
                />
            )}
        </div>
    )
}