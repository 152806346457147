import React from 'react'

export default function SesstionIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3368_6531)">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.84476 0.736398C8.03245 0.642555 8.25336 0.642555 8.44105 0.736398L15.1077 4.06973C15.3336 4.18266 15.4762 4.4135 15.4762 4.66602C15.4762 4.91853 15.3336 5.14937 15.1077 5.2623L8.44105 8.59563C8.25336 8.68948 8.03245 8.68948 7.84476 8.59563L1.17809 5.2623C0.952239 5.14937 0.80957 4.91853 0.80957 4.66602C0.80957 4.4135 0.952239 4.18266 1.17809 4.06973L7.84476 0.736398ZM2.96695 4.66602L8.1429 7.25399L13.3189 4.66602L8.1429 2.07804L2.96695 4.66602Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.880098 11.0347C1.04476 10.7054 1.44521 10.5719 1.77453 10.7365L8.14305 13.9208L14.5116 10.7365C14.8409 10.5719 15.2413 10.7054 15.406 11.0347C15.5707 11.364 15.4372 11.7645 15.1079 11.9291L8.44119 15.2624C8.25351 15.3563 8.03259 15.3563 7.84491 15.2624L1.17824 11.9291C0.848922 11.7645 0.715439 11.364 0.880098 11.0347Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.880098 7.70265C1.04476 7.37334 1.44521 7.23985 1.77453 7.40451L8.14305 10.5888L14.5116 7.40451C14.8409 7.23985 15.2413 7.37334 15.406 7.70265C15.5707 8.03197 15.4372 8.43242 15.1079 8.59708L8.44119 11.9304C8.25351 12.0243 8.03259 12.0243 7.84491 11.9304L1.17824 8.59708C0.848922 8.43242 0.715439 8.03197 0.880098 7.70265Z" fill="currentColor"/>
    </g>
    <defs>
    <clipPath id="clip0_3368_6531">
    <rect width="16" height="16" fill="white" transform="translate(0.142578)"/>
    </clipPath>
    </defs>
    </svg>
  )
}
