import React from "react";

export const ReorderAlt = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.08335 5.83333C8.00383 5.83333 8.75002 5.08714 8.75002 4.16667C8.75002 3.24619 8.00383 2.5 7.08335 2.5C6.16288 2.5 5.41669 3.24619 5.41669 4.16667C5.41669 5.08714 6.16288 5.83333 7.08335 5.83333Z" fill="currentColor" />
            <path d="M7.08335 11.6667C8.00383 11.6667 8.75002 10.9205 8.75002 10C8.75002 9.07953 8.00383 8.33333 7.08335 8.33333C6.16288 8.33333 5.41669 9.07953 5.41669 10C5.41669 10.9205 6.16288 11.6667 7.08335 11.6667Z" fill="currentColor" />
            <path d="M8.75002 15.8333C8.75002 16.7538 8.00383 17.5 7.08335 17.5C6.16288 17.5 5.41669 16.7538 5.41669 15.8333C5.41669 14.9129 6.16288 14.1667 7.08335 14.1667C8.00383 14.1667 8.75002 14.9129 8.75002 15.8333Z" fill="currentColor" />
            <path d="M12.9167 5.83333C13.8372 5.83333 14.5834 5.08714 14.5834 4.16667C14.5834 3.24619 13.8372 2.5 12.9167 2.5C11.9962 2.5 11.25 3.24619 11.25 4.16667C11.25 5.08714 11.9962 5.83333 12.9167 5.83333Z" fill="currentColor" />
            <path d="M14.5834 10C14.5834 10.9205 13.8372 11.6667 12.9167 11.6667C11.9962 11.6667 11.25 10.9205 11.25 10C11.25 9.07953 11.9962 8.33333 12.9167 8.33333C13.8372 8.33333 14.5834 9.07953 14.5834 10Z" fill="currentColor" />
            <path d="M12.9167 17.5C13.8372 17.5 14.5834 16.7538 14.5834 15.8333C14.5834 14.9129 13.8372 14.1667 12.9167 14.1667C11.9962 14.1667 11.25 14.9129 11.25 15.8333C11.25 16.7538 11.9962 17.5 12.9167 17.5Z" fill="currentColor" />
        </svg>
    )
}