import React from 'react'

export default function RefundIcon({className = ''}) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6818_31436)">
      <path d="M4.72548 3.54749C7.99881 0.864987 12.8363 1.05165 15.8921 4.10749C19.1463 7.36165 19.1463 12.6383 15.8921 15.8925C12.638 19.1467 7.36131 19.1467 4.10715 15.8925C3.1085 14.8952 2.37971 13.6605 1.9892 12.3043C1.59868 10.948 1.55927 9.5148 1.87465 8.13915L1.93881 7.87832L3.55048 8.30332C3.15093 9.82187 3.30022 11.4327 3.97204 12.852C4.64386 14.2712 5.79509 15.4078 7.22284 16.0614C8.65059 16.715 10.2632 16.8436 11.7765 16.4246C13.2898 16.0057 14.6067 15.066 15.495 13.7712C16.3833 12.4764 16.7861 10.9096 16.6323 9.34687C16.4786 7.78418 15.7782 6.32592 14.6545 5.22907C13.5309 4.13223 12.0562 3.46721 10.4902 3.35121C8.92427 3.23521 7.36763 3.67568 6.09465 4.59499L5.91048 4.73332L6.75798 5.58082L2.92798 6.46415L3.81131 2.63415L4.72465 3.54749H4.72548ZM10.833 4.99999V6.66665H12.9163V8.33332H8.33298C8.22886 8.33313 8.12844 8.37193 8.05149 8.44208C7.97455 8.51223 7.92666 8.60864 7.91725 8.71234C7.90784 8.81603 7.9376 8.9195 8.00066 9.00235C8.06372 9.0852 8.15552 9.14144 8.25798 9.15999L8.33298 9.16665H11.6663C12.2188 9.16665 12.7488 9.38615 13.1395 9.77685C13.5302 10.1675 13.7496 10.6975 13.7496 11.25C13.7496 11.8025 13.5302 12.3324 13.1395 12.7231C12.7488 13.1138 12.2188 13.3333 11.6663 13.3333H10.833V15H9.16631V13.3333H7.08298V11.6667H11.6663C11.7704 11.6668 11.8709 11.628 11.9478 11.5579C12.0247 11.4877 12.0726 11.3913 12.082 11.2876C12.0914 11.1839 12.0617 11.0805 11.9986 10.9976C11.9356 10.9148 11.8438 10.8585 11.7413 10.84L11.6663 10.8333H8.33298C7.78044 10.8333 7.25054 10.6138 6.85984 10.2231C6.46914 9.83243 6.24965 9.30252 6.24965 8.74999C6.24965 8.19745 6.46914 7.66755 6.85984 7.27685C7.25054 6.88615 7.78044 6.66665 8.33298 6.66665H9.16631V4.99999H10.833Z" fill="#111827"/>
      </g>
      <defs>
      <clipPath id="clip0_6818_31436">
      <rect width="20" height="20" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}
