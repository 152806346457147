//@ts-nocheck
import * as React from 'react';
// PACKAGE
import {
    matchPath,
    Redirect,
    Route,
    useHistory,
    useLocation,
    useParams,
} from 'react-router';
import PrivateRouteProvider from 'contexts/PrivateRoute/PrivateRouteProvider';
import { BarType, NewBarType } from 'constant/setupBars.const';
import { handleRole } from 'components/H5P/H5PFN';
import { AuthContext } from 'contexts/Auth/AuthContext';
import Cookies from 'js-cookie';
import { AUTH_CONST } from 'constant/auth.const';
import { handleLogout } from 'utils/handleLogout';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import workspaceMiddleware from 'middleware/workspace.middleware';
import MasterPage from 'pages/MasterPage';
import { objectMenuType, featureURL } from 'constant/workspace.const';
import { useDocumentTitle } from 'hooks/useDocumentTitle';


// Requires authentication
const PrivateRoute: React.FC<
    { barType?: BarType } & { [key: string]: any } & { newBar?: NewBarType }
> = ({
    path,
    isAuthStorage,
    setAuthStorage,
    storageUserInfo,
    barType,
    component: Component,
    newBar,
    documentTitle = '',
    ...rest
}) => {
    useDocumentTitle(documentTitle);
        const {
            dispatch: WorkspaceDispatch,
            getWorkspaceDetailState: { result: WorkspaceDetailInformation, permissions },
        } = React.useContext(GetWorkspaceContext);

        const currentPath = matchPath(window.location.pathname, {
            path: '/workspace/:id',
            strict: false,
        });

        const getWorkspace = () => {
            const params: any = currentPath?.params;
            workspaceMiddleware.getWorkspace(WorkspaceDispatch, {
                id: params?.id,
            });

        }

        React.useEffect(() => {
            if (isAuthStorage) {
                if (WorkspaceDetailInformation.id === -1 && currentPath) {
                    getWorkspace();
                } 
            }
            // return () => workspaceMiddleware.resetUserState(WorkspaceDispatch);
        }, []);
       
        return (
            <MasterPage setAuthStorage={setAuthStorage} newBarType={newBar} isAuthStorage={isAuthStorage}>
                <Route
                    path={path}
                    {...rest}
                    exact
                    render={(props) => {
                        return isAuthStorage ? (
                            <PrivateRouteProvider
                                barType={barType}
                                newBarType={newBar}
                            >
                                <Component
                                    {...props}
                                    isAuthStorage={isAuthStorage}
                                    setAuthStorage={setAuthStorage}
                                    storageUserInfo={storageUserInfo}
                                />
                            </PrivateRouteProvider>

                        ) : (
                            <Redirect
                                to={{
                                    pathname: '/login',
                                    state: { from: props.location },
                                }}
                            />
                        );
                    }}
                />
            </MasterPage>
        );
    };

export default PrivateRoute;
