export  const VIEW_TYPE = {
  WEB: 'web',
  MOBILE: 'mobile'
}

export enum TYPE_LESSON_BUILDER {
  information = 'information',
  learningMaterials = 'learning_material',
  assignments = 'assignment',
  supplementaryMaterials = 'supplementary_material'
}

export enum LESSON_STATUS {
  draft = 'Draft',
  published = 'Published'
}

export enum LESSON_STATUS_CODE {
  draft = 'draft',
  published = 'published'
}

export enum TYPE_CREATE_CONTENT {
  UPLOAD = 'upload',
  NEW_H5P = 'new_h5p',
  NEW_SUBMISSION= 'new_submission',
  LIBRARY = 'library',
  H5P_LIBRARY = 'h5p_library',
  LESSON = 'lesson',
  LEARNING_PATH = 'learning_path'
}