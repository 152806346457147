import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import ReportService from 'services/report.service';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { BlockInfo, BlockName, BlockValue } from '../components/BlockInfo';
import { roundNumber, roundNumberWithHours } from 'constant/util.const';

const SpecificTeacherDashboard = () => {
    const { t } = useTranslation();
    const params: { id: string; teacherId: string } = useParams();
    const location: any = useLocation();

    const workspaceId = params.id;
    const teacherId = params.teacherId;

    const [generalReport, setGeneralReport] = useState<any>({});

    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);

    const fetchGeneral = (params?: any) => {
        ReportService.getGeneralReportTeachers(workspaceId, {
            instructors: teacherId,
            ...params,
        })
            .then((res) => setGeneralReport(res.data))
            .finally();
    };

    const convertSecondToHour = (seconds: number) => {
        return seconds ? roundNumber(seconds / 3600, 2) : 0;
    };

    useEffect(() => {
        fetchGeneral();

        return () => {
            setGeneralReport(null);
        };
    }, []);

    return (
        <div className="StudentDashboardDetails w-full">
            <PageBreadcrumb
                pageNameDefault={translator('REPORTS')}
                pages={[
                    {
                        name: translator('TEACHER_DASHBOARD'),
                        href: `/workspace/${workspaceId}/report/dashboard-instructor`,
                        className: '',
                    },
                    { name: location?.state?.instructor_name, href: '', className: '' },
                ]}
                containerClassName=" z-10 sticky top-0 w-full"
            />
            <div className="py-4 px-8 ">
                <h1 className="text-xl font-semibold">{location?.state?.instructor_name}</h1>
                <div className="mt-7 grid grid-cols-5 gap-7">
                    <BlockInfo>
                        <div>
                            <BlockValue>
                                {generalReport?.punctuality && generalReport?.punctuality !== '0'
                                    ? generalReport?.punctuality + '%'
                                    : 'N/A'}
                            </BlockValue>
                            <BlockName>{translator('PUNCTUALITY')}</BlockName>
                        </div>
                    </BlockInfo>
                    <BlockInfo containerClassName="col-span-2">
                        <div className="flex justify-between w-full">
                            <div className="w-1/2">
                                <BlockValue>
                                    {generalReport?.total_talk_time && generalReport?.total_talk_time !== '0'
                                        ? generalReport?.total_talk_time + ' mins'
                                        : 'N/A'}
                                </BlockValue>
                                <BlockName>{translator('TOTAL_TALK_TIME')}</BlockName>
                            </div>
                            <div className="absolute top-[calc(50%-16px)] left-1/2 h-8 w-[1px] bg-[#D9D9D9]"></div>
                            <div className="w-1/2">
                                <BlockValue>
                                    {generalReport?.percent_talk_time && generalReport?.percent_talk_time !== '0'
                                        ? generalReport?.percent_talk_time + '%'
                                        : 'N/A'}
                                </BlockValue>
                                <BlockName>{translator('PERCENT_TALK_TIME')}</BlockName>
                            </div>
                        </div>
                    </BlockInfo>
                    <BlockInfo>
                        <div>
                            <BlockValue>
                                {generalReport?.count_session && generalReport?.count_session !== '0'
                                    ? generalReport?.count_session
                                    : 'N/A'}
                            </BlockValue>
                            <BlockName>{translator('TOTAL_CLASSES_TALK')}</BlockName>
                        </div>
                    </BlockInfo>
                    <BlockInfo>
                        <div>
                            <BlockValue>{roundNumberWithHours(generalReport?.sum_duration) || 'N/A'}</BlockValue>
                            <BlockName>{translator('TOTAL_HOURS_TALK')}</BlockName>
                        </div>
                    </BlockInfo>
                </div>
            </div>
        </div>
    );
};

export default SpecificTeacherDashboard;
