import dayjs from "dayjs";
import { colorByType, colorExpiredSession } from "../constants";
import { UserContext } from "contexts/User/UserContext";
import { useContext, useEffect, useState } from "react";
import LockIcon from "assets/icon/LockIcon";

interface Props {
  startTime: string;
  endTime: string;
  id: string;
}

export default function AloneSession({startTime, endTime, id}: Props) {
  const {
    userState: { result },
} = useContext(UserContext);

const [isExpriredSession, setExpriredSession] = useState(false);

useEffect(() => {
  setExpriredSession(dayjs().tz(result.time_zone).isAfter(dayjs(endTime).tz(result?.time_zone)))
},[endTime])

  return (
      <div className={` w-full my-1 h-full rounded p-1 relative overflow-hidden ${ isExpriredSession ?  colorExpiredSession : colorByType.scheduled }`}>
          <div className="text-xs font-semibold">ID: {id}</div>
          <div className="text-[10px] font-normal">{dayjs.utc(startTime)?.tz(result.time_zone)?.format('HH:mm')} - {dayjs.utc(endTime)?.tz(result.time_zone)?.format('HH:mm')}</div>
          {isExpriredSession && (
              <div className="absolute right-1 bottom-1 text-gray-500">
                <LockIcon />
              </div>
            )
          }
      </div>
  );
};