import axios from './../restful.service';
import { REPORT } from 'constant/api.const';

const getClassOverviewStat  = (workspaceId: string, params) => {
    return axios.get(REPORT.getClassDashboard(workspaceId) + '/overviews', {params});
}

const getClassStat  = (workspaceId: string, params) => {
    return axios.get(REPORT.getClassDashboard(workspaceId), {params});
}

const getClassOverviewByIdStat  = (workspaceId: string, params) => {
    return axios.get(REPORT.getClassDashboard(workspaceId) + `/${params.classId}/overviews`, {params});
}

const getClassCoinsById = (workspaceId: string, params) => {
    return axios.get(REPORT.getClassDashboard(workspaceId) + `/${params.classId}/overview/coins`, {params});
}

const getClassPeopleById = (workspaceId: string, params) => {
    return axios.get(REPORT.getClassDashboard(workspaceId) + `/${params.classId}/people/stat`, {params});
}

const getClassStudentsById = (workspaceId: string, params) => {
    return axios.get(REPORT.getClassDashboard(workspaceId) + `/${params.classId}/people`, {params});
}

const getClassAcademicsById = (workspaceId: string, params) => {
    return axios.get(REPORT.getClassDashboard(workspaceId) + `/${params.classId}/academics`, {params});
}

const getClassAssignmentsById = (workspaceId: string, params) => {
    return axios.get(REPORT.getClassDashboard(workspaceId) + `/${params.classId}/assignments/stat`, {params});
}

const getClassAssignmentsListById = (workspaceId: string, params) => {
    return axios.get(REPORT.getClassDashboard(workspaceId) + `/${params.classId}/assignments`, {params});
}

const getClassSessionsById = (workspaceId: string, params) => {
    const courseId = params.classId;
    delete params?.classId;
    return axios.get(REPORT.getClassDashboard(workspaceId) + `/${courseId}/sessions/overview`, {params});
}

const getClassSessionsSchedulingById = (workspaceId: string, params) => {
    const courseId = params.classId;
    delete params?.classId;
    return axios.get(REPORT.getClassDashboard(workspaceId) + `/${courseId}/sessions/breakdown`, {params});
}

const getClassSessionsTypeById = (workspaceId: string, params) => {
    const courseId = params.classId;
    delete params?.classId;
    return axios.get(REPORT.getClassDashboard(workspaceId) + `/${courseId}/sessions/type`, {params});
}

export default {
    getClassOverviewStat,
    getClassStat,
    getClassOverviewByIdStat,
    getClassCoinsById,
    getClassPeopleById,
    getClassStudentsById,
    getClassAcademicsById,
    getClassAssignmentsById,
    getClassAssignmentsListById,
    getClassSessionsById,
    getClassSessionsSchedulingById,
    getClassSessionsTypeById
}