const BorderCardInfo = ({ label, subLabel, classes = '' }) => {
    return (
        <div
            className={`flex items-center  border border-[#C7C9D9] rounded-lg px-5 py-2 text-xs font-semibold text-dark-300 ${classes}`}
        >
            {label}: {subLabel}
        </div>
    );
};

export default BorderCardInfo;
