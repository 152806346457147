import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';
import React from 'react';
import GradeSubmissionPage from '../GradeSubmissionPage';

const Grade: React.FC<{ setAuthStorage: React.Dispatch<React.SetStateAction<boolean>> }> = ({
    setAuthStorage
}) => (
    <ManagePermissionWrapper>
        <GradeSubmissionPage />
    </ManagePermissionWrapper>
);

export default Grade;
