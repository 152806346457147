import { BANNER  } from 'constant/api.const';
import axios from './restful.service';


const createBannerService = (workspaceId: string, data: any) => {
  if (workspaceId) {
      const url = BANNER.createBanner(workspaceId);
      return axios.post(url, data);
  }
};

const updateBannerService = (workspaceId: string, bannerId: string, data: any) => {
  if (workspaceId) {
      const url = BANNER.updateBanner(workspaceId, bannerId);
      return axios.put(url, data);
  }
};

const updateBannerStatus = (workspaceId: string, bannerId: string, data: any) => {
  if (workspaceId) {
      const url = BANNER.updateBanner(workspaceId, bannerId);
      return axios.patch(url, data);
  }
};

const getBannerDetail = (workspaceId: string, bannerId: string) => {
  if (workspaceId) {
      const url = BANNER.getBannerDetail(workspaceId, bannerId);
      return axios.get(url);
  }
};

export default {
  createBannerService,
  getBannerDetail,
  updateBannerService,
  updateBannerStatus
}

