import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import {
    EyeIcon,
    TrashIcon,
} from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { checkPermission } from 'utils/commonFun';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

const iconStyle = 'w-4 h-9 text-ooolab_dark_2';

interface LessonOptionsInterface {
    id?: string;
    workspace?: string;
    dispatch?: React.Dispatch<any>;
    canRemove?: boolean;
    duplicateLesson?: Function;
    handleRemove?: Function;
    redirectUrl?: any,
    position?: string
}

const LessonOptions: React.FC<LessonOptionsInterface> = ({
    id,
    handleRemove,
    redirectUrl,
    position
}) => {
    const { t: translator } = useTranslation();
    const history = useHistory();
    const MenuList = [
        {
            name: `${translator('GROUP.VIEW')}`,
            icons: <EyeIcon className={iconStyle} />,
            function: () =>
                history.push(redirectUrl),
            isDisplay: true,
            hide: !checkPermission('customGroupDetails', 'R'),
        },
        {
            name: `${translator('LESSON.DELETE')}`,
            icons: <TrashIcon className={iconStyle} />,
            function: () => handleRemove(id),
            hide: !checkPermission('customGroup', 'D'),
        },
    ];

    if (!redirectUrl) delete MenuList[0]

    return (
        <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
                <>
                    <div>
                        <Menu.Button className={`flex justify-center items-center text-ooolab_dark_1 hover:bg-ooolab_bg_sub_tab_hover hover:text-white focus:outline-none w-6 h-6 rounded-full`}>
                            <DotsVerticalIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                            />
                        </Menu.Button>
                    </div>

                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            className={`z-9999 shadow-ooolab_box_shadow_container ${position ? position : 'origin-top-right'}  absolute right-ooolab_inset_100 -top-full mt-2 w-40 rounded-header_menu divide-y divide-gray-100 focus:outline-none`}
                        >
                            <div className="py-1">

                                {MenuList.map((i) => {
                                    return (
                                        <Menu.Item key={i.name}>
                                            {({ }) => (
                                                <div
                                                    onClick={() => {
                                                        if (i.function) {
                                                            i.function();
                                                        }
                                                    }}
                                                    className={`flex  px-2 w-full bg-white hover:bg-ooolab_bg_sub_tab_hover cursor-pointer ${i?.hide ? 'hidden' : ''}  `}
                                                >
                                                    {i.icons}
                                                    <a
                                                        className={classNames(
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        {i.name}
                                                    </a>
                                                </div>
                                            )}
                                        </Menu.Item>
                                    );
                                })}
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
};

export default LessonOptions;
