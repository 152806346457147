import { propertyType } from 'components/WorkspaceSetting/Property/components/propertyCommon';
import { FORM_CONST } from 'constant/form.const';
import { useTranslation } from 'react-i18next';
import propertyService from 'services/property.service';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import workspaceService from 'services/workspace.service';
import isArray from 'lodash/isArray';

interface Props {
    formState: any;
    watch: any;
    control: any;
    register: any;
    data: any;
    setValue: any;
    isViewAllProperties: boolean;
}

const PER_PAGE = 10;

export default function Properties({ formState, watch, control, register, data, setValue, isViewAllProperties }: Props) {
    const params: { id: string } = useParams();
    const [properties, setProperties] = useState<any>([]);

    const formatPropertiesObj = (properties) => {
        const propertiesArr = [];
        //for property push properties to properties array and children to children array
        const pushProperties = (properties, parentName = '') => {
            properties.forEach((child: any) => {
                const name = `${child?.name?.split(' ').join('_').toLowerCase()}`;
                propertiesArr.push({
                    name,
                    property_id: child?.id,
                    property_type: child?.property_type,
                    is_visible: child?.is_visible,
                    parent_name: parentName,
                    label: child?.name,
                });
                if (child?.children) {
                    pushProperties(child?.children, name);
                }
            });
        };
        pushProperties(properties, '');
        return propertiesArr;
    };

    useEffect(() => {
        if (data) {
            getListProperties();
        }
    }, [data]);

    const getListProperties = () => {
        workspaceService
            .getListPropertiesService(params?.id, { page: 1, per_page: 100, object_type: 'learner', skip_parent: true })
            .then((res) => {
                const properties = formatPropertiesObj(res?.data?.items);
                setProperties(properties);
                const propertiesData = {};
                properties?.forEach((item: any) => {
                    const property = data?.properties_value?.find((property: any) => property?.property_id === item?.property_id);
                    const value = property?.value;
                    propertiesData[item?.name] = {
                        value: value,
                        property_type: item?.property_type,
                        property_id: item?.property_id,
                    };
                    if (
                        item?.property_type === propertyType.multiple_selection?.value ||
                        item?.property_type === propertyType.single_selection?.value
                    ) {
                        const valueArr = value?.split(',');
                        propertiesData[item?.name] = {
                            value: valueArr?.map((item) => ({ label: item, value: item })),
                            property_type: item?.property_type,
                            property_id: item?.property_id,
                        };
                    }
                    if (item?.property_type === propertyType.date_picker?.value) {
                        propertiesData[item?.name] = {
                            value: value && new Date(value),
                            property_type: item?.property_type,
                            property_id: item?.property_id,
                        };
                    }
                    setValue('properties', propertiesData);
                });
            });
    };

    useEffect(() => {
        getListProperties();
    }, [isViewAllProperties]);

    return (
        <div>
            {properties?.map((item: any) => {
                return (
                    <PropertyComponent
                        property={item}
                        register={register}
                        control={control}
                        formState={formState}
                        watch={watch}
                        isViewAllProperties={isViewAllProperties}
                        setValue={setValue}
                    />
                );
            })}
        </div>
    );
}

const PropertyComponent = ({ property, register, control, formState, watch, isViewAllProperties, setValue }): JSX.Element | null => {
    const { t } = useTranslation();
    const [isFirstCallApi, setFirstCallApi] = useState(true);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<any>([]);
    const params: { id: string } = useParams();
    const [pagination, setPagination] = useState<any>({
        page: 1,
        total: 0,
    });
    const getPropertyOptions = (page: number = 1, item) => {
        setLoading(true);
        const param = {
            page: page,
            per_page: PER_PAGE,
        };

        if (options?.length > 0) return;
        if (
            propertyType?.single_selection?.value === item?.property_type ||
            propertyType?.multiple_selection?.value === item?.property_type
        ) {
            propertyService
                .getOptions(params?.id, item?.property_id, param)
                .then((res) => {
                    const optionFormated = res?.data?.items?.map((option: any) => ({
                        name: option?.label || option?.value,
                        value: option?.id,
                        property_type: propertyType,
                    }));
                    setFirstCallApi(false);
                    if (page === 1) setOptions(optionFormated);
                    if (page > 1) {
                        setOptions([...options, ...optionFormated]);
                    }
                    setPagination({ total: res?.data?.total, page: res?.data?.page });
                })
                .finally(() => setLoading(false));
        }
    };

    const name = `properties[${property?.name}].value`;
    const Component = propertyType?.[property?.property_type]?.component;
    let validate = undefined;

    if (property?.property_type === propertyType.email.value) {
        validate = {
            pattern: {
                value: FORM_CONST.EMAIL_REGEX,
                message: t('FORM_CONST.EMAIL_VALIDATE'),
            },
            maxLength: {
                value: 255,
                message: t('FORM_CONST.VALIDATE_255_CHARACTERS'),
            },
        };
    }

    useEffect(() => {
        if (property.parent_name && !watch(`properties[${property?.parent_name}].value`)) {
            setValue(`properties[${property?.name}].value`, null);
        }
    }, [watch(`properties[${property?.parent_name}].value`), property?.parent_name]);


    //------condition to show property--------
    //if property is not visible and isViewAllProperties is false then return null
    //if property has parent and parent value is empty then return null
    //if property has parent and parent value is empty array then return null
    const parentValue = watch(`properties[${property?.parent_name}].value`);
    
    if (!isViewAllProperties) {
        if (!property?.is_visible) return null;
        
    }
    if (isViewAllProperties) {
        if (property?.parent_name) {
            if (isArray(parentValue) && !parentValue.length) {
                return null;
            }
            if (!parentValue) {
                return null;
            }
        }
    }

    return (
        <div key={property?.name} className="pb-5">
            <div>
                {property?.property_type === propertyType.date_picker.value && (
                    <label htmlFor="dob" className="text-gray-800 text-xs font-semibold">
                        {property?.label}
                    </label>
                )}
                <div>
                    <Component
                        //common props
                        name={name}
                        label={property?.label}
                        control={control}
                        register={register(name, { ...validate })}
                        errors={formState?.errors}
                        isRequired={property?.is_required}
                        classNameInput="!h-8"
                        //for DatePickerInput
                        labelCls="text-ooolab_text_username"
                        isOriginal={true}
                        paddingCls="py-2 px-4"
                        iconCls="top-[10px]"
                        positionIcon="right"
                        placeholderText={t('USER.DOB')}
                        //for Select
                        onClick={() => isFirstCallApi && getPropertyOptions(1, property)}
                        className={
                            propertyType?.single_selection?.value === property?.property_type ||
                            propertyType?.multiple_selection?.value === property?.property_type
                                ? '!h-8'
                                : ''
                        }
                        pagination={pagination}
                        loading={loading}
                        getData={getPropertyOptions}
                        value={watch(name)}
                        data={options}
                        numberOfItemDisplayed={5}
                        isMulti={propertyType?.multiple_selection?.value === property?.property_type}
                        placeholder={
                            property?.property_type === propertyType.multi_line_text?.value ||
                            property?.property_type === propertyType.single_line_text?.value ||
                            propertyType.phone_number
                                ? 'Enter ' + property?.name
                                : 'Select ' + property?.name
                        }
                        formatOptionLabel={(data) => <div className="text-xs">{data?.name}</div>}
                        formatoptionValue={(data) => <div className="text-xs">{data?.value}</div>}
                    />
                </div>
            </div>
        </div>
    );
};
