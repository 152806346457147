import { FC, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { components } from 'react-select';
import ClassService from 'services/class.service';
import ButtonComponent from 'components/Button/ButtonComponent';
import { Toast } from 'utils/sweetAlert';
import { useTranslation } from 'react-i18next';

type ModalAddH5pProps = {
    workspaceId: string;
    classId: string;
    callBack: () => void;
};

const customSelect = {
    menuList: (base) => ({
        ...base,
        height: 200,
    }),
};

const ModalAddH5p: FC<ModalAddH5pProps> = ({ workspaceId, classId, callBack }) => {
    const [selectedH5P, setSelectedH5P] = useState<any>(null);
    const [submitting, setSubmitting] = useState(false);
    const {t: translator} = useTranslation();

    const handleLoadOptions = async (inputValue, options, additional) => {
        let params: any = {
            page: additional.page,
            per_page: 20,
        };

        if (inputValue) {
            params.title = inputValue;
        }

        const res = await ClassService.getH5pFilesService({ workspaceId }, params);
        const { items, total } = res.data;

        const newOptions = items.map((el) => ({
            ...el,
            label: el.title,
            value: el.id,
        }));

        return {
            options: newOptions,
            hasMore: additional.page < total / 20,
            additional: {
                page: additional.page + 1,
            },
        };
    };

    const submitCreateH5p = () => {
        try {
            setSubmitting(true);
            ClassService.uploadResourcesService(
                { workspaceId, classId },
                {
                    h5p_content_id: selectedH5P.id,
                    mime_type: 'application/h5p',
                    file_extension: '.h5p',
                }
            ).then(() => {
                setSubmitting(false);
                callBack();
            }, error => {
                setSubmitting(false);
                Toast.fire({
                    icon: 'error',
                    title: translator('CLASSES.SOMETHING_WRONG'),
                });
            })
        } catch(error) {
            setSubmitting(false);
            Toast.fire({
                icon: 'error',
                title: translator('CLASSES.SOMETHING_WRONG'),
            });
        }
        
    };

    return (
        <div className="flex flex-col justify-between px-5 text-ooolab_dark_1">
            <div>
                <label htmlFor="addH5pId" className="font-semibold text-xs">
                    H5P
                </label>
                <AsyncPaginate
                    loadOptions={handleLoadOptions}
                    additional={{
                        page: 1,
                    }}
                    debounceTimeout={500}
                    styles={customSelect}
                    onChange={(newValue: any, actionMeta: any) => {
                        setSelectedH5P(newValue);
                    }}
                    isClearable
                    placeholder=""
                    value={selectedH5P}
                    menuPlacement="auto"
                    components={{
                        Option: ({ children, ...props }) => {
                            // FIX LAG
                            const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
                            const newProps = Object.assign(props, { innerProps: rest });
                            return <components.Option {...newProps}>{children}</components.Option>;
                        },
                    }}
                />
            </div>

            <div className="mt-9 text-center">
                <ButtonComponent
                    title="Add"
                    classStyle={'primary-button-apollo'}
                    onClickButton={submitCreateH5p}
                    disable={!selectedH5P || submitting}
                    
                />
            </div>
        </div>
    );
};

export default ModalAddH5p;
