import { ErrorMessage } from '@hookform/error-message';
import { CLASS_DATE_FORMAT } from 'constant/ui.const';
import { ListWeekDay, WeekDay } from 'constant/util.const';
import dayjs from 'dayjs';
import { toInteger } from 'lodash';
import { useCallback, useEffect } from 'react';
import {
    Control,
    DeepMap,
    FieldError,
    FieldValues,
    useFieldArray,
    UseFormWatch,
    Controller,
    UseFormRegister,
} from 'react-hook-form';
import { getDayOfWeekInRange } from 'utils/handleFormatTime';
import CustomCheckbox from '../Form/CustomCheckbox';
import DatePickerInput from '../Form/DatePicker';
import TimePickerInput from '../Form/TimePicker';
import { useTranslation } from 'react-i18next';
import Select, { StylesConfig } from 'react-select';
import { classTypes } from 'constant/classTypes';
import TooltipInfo from 'components/UiComponents/TooltipInfo';
import { CircleInformationIcon } from 'assets/icon/CircleInfomationIcon';

interface FormCreateSessionProps {
    watch: UseFormWatch<FieldValues>;
    control: Control<FieldValues>;
    register: UseFormRegister<FieldValues>;
    errors: DeepMap<FieldValues, FieldError>;
    setValue: Function;
    clearErrors: Function;
}

export const customSelectClassType: StylesConfig<any, true> = {
    clearIndicator: (base) => ({ ...base, display: 'none' }),
    indicatorSeparator: (base) => ({ ...base, display: 'none' }),
    control: (base) => {
        return {
            ...base,
            borderColor: '#EBEBF0',
            padding: 0,
            paddingLeft: 8,
            height: 32,
            minHeight: 32,
            color: '#2E3A59',
        };
    },
    valueContainer: (base) => ({
        ...base,
        padding: 0,
        fontSize: 12,
    }),
    indicatorsContainer: (base, { selectProps }) => ({
        ...base,
        transition: 'all 0.3s ease-in-out',
        transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: 6,
    }),
    input: (base) => ({
        ...base,
        padding: 0,
        margin: 0,
        fontSize: 12,
    }),
    option: (base) => ({
        ...base,
        paddingLeft: '15px',
        fontSize: 12,
    }),
};

const regex = /^[0-9]*$/;
const regex2 = /^[0-9]{0,2}$/;

const FormCreateSession: React.FC<FormCreateSessionProps> = ({ watch, control, register, errors, setValue, clearErrors }) => {
    const { t: translator } = useTranslation();

    const { fields, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'time_range', // unique name for your Field Array
        // keyName: "id", default to "id", you can change the key name
        shouldUnregister: false,
    });

    const startDateWatch = watch('class_start_date');
    const endDateWatch = watch('class_end_date');
    const timeslotWatch = watch('time_range');

    const days = watch('days');
    const hours = watch('hours');
    const minutes = watch('minutes');

    const resetTimeslots = useCallback(() => {
        const listAllowWeekday = getDayOfWeekInRange(startDateWatch, endDateWatch);

        const listRemoveValue = timeslotWatch?.filter((i) => !listAllowWeekday.includes(i['weekday'])).map((j) => j['weekday']);

        const listRemoveIndex = [];

        fields.forEach((j, index) => {
            if (listRemoveValue.includes(j['weekday'])) {
                listRemoveIndex.push(index);
            }
        });

        remove(listRemoveIndex);
        const listInputsDocument = document.getElementsByClassName('custom-input');
        if (listInputsDocument?.length) {
            for (let i = 0; i < listInputsDocument.length; i++) {
                const inputFields = listInputsDocument[i] as HTMLInputElement;

                if (inputFields && !listAllowWeekday.includes(toInteger(inputFields.value))) inputFields.checked = false;
            }
        }
    }, [timeslotWatch]);

    const handleChangeTimeSlot = useCallback(
        (checked: boolean, fieldsValue: number) => {
            if (checked) {
                append({ weekday: fieldsValue });
            } else {
                const targetIndex = fields.findIndex((i) => i['weekday'] === fieldsValue);
                if (targetIndex > -1) {
                    remove(targetIndex);
                }
            }
        },
        [fields, remove, append]
    );

    const sortedTimeslots = Array.from(fields)
        .map((i, index) => ({ ...i, originalIndex: index }))
        .sort((a, b) => a['weekday'] - b['weekday']);
    const listActiveDay = getDayOfWeekInRange(startDateWatch, endDateWatch);

    useEffect(() => {
        resetTimeslots();
    }, [startDateWatch, endDateWatch]);

    return (
        <>
            <div className="col-span-1">
                <label className="text-ooolab_dark_1 text-ooolab_xs font-bold leading-ooolab_24px block" htmlFor="class_start_date">
                    {translator('CLASSES.START_DATE')}
                </label>
                <div className="w-full h-ooolab_h_8 relative">
                    <DatePickerInput
                        control={control}
                        name="class_start_date"
                        placeholderText={CLASS_DATE_FORMAT}
                        maxDate={endDateWatch}
                        minDate={new Date()}
                        isRequired
                    />
                </div>
                <ErrorMessage className="text-red-500 text-ooolab_10px" errors={errors} name="class_start_date" as="p" />
            </div>
            <div className="col-span-1">
                <label className="text-ooolab_dark_1 text-ooolab_xs font-bold leading-ooolab_24px block" htmlFor="class_end_date">
                    {translator('CLASSES.END_DATE')}
                </label>
                <div className="w-full h-ooolab_h_8 relative">
                    <DatePickerInput
                        control={control}
                        name="class_end_date"
                        placeholderText={CLASS_DATE_FORMAT}
                        minDate={startDateWatch}
                        isRequired
                    />
                </div>
                <ErrorMessage className="text-red-500 text-ooolab_10px" errors={errors} name="class_end_date" as="p" />
            </div>
            <div className="col-span-1 text-ooolab_xs">
                <label htmlFor="max_people" className="text-ooolab_dark_1 font-semibold leading-ooolab_24px block">
                    {translator('CLASSES.MAX_PEOPLE')}
                </label>
                <input
                    className="border lg:w-full md:w-3/4 w-1/2 border-ooolab_bar_color text-ooolab_dark_1 p-ooolab_p_2 rounded font-normal"
                    type="number"
                    id="max_people"
                    min={1}
                    placeholder={translator('CLASSES.MAX_PEOPLE')}
                    {...register('max_people')}
                />
            </div>
            <div className="col-span-2">
                <p className="text-ooolab_dark_1 text-ooolab_xs font-bold leading-ooolab_24px">{translator('CLASSES.CLASS_DAYS')}</p>
                <div className="grid grid-cols-7 gap-x-2">
                    {ListWeekDay(translator).map((i, index) => {
                        return (
                            <CustomCheckbox
                                key={`class-slot-${i.name}`}
                                className="flex col-span-1 items-center text-ooolab_10px font-semibold leading-ooolab_24px"
                                value={i.value}
                                title={i.name}
                                onChange={handleChangeTimeSlot}
                                disabled={!listActiveDay.includes(i.value)}
                            />
                        );
                    })}
                </div>
            </div>
            <div className="col-span-2 h-3/5 ">
                <p className="text-ooolab_dark_1 text-ooolab_xs font-bold leading-ooolab_24px">{translator('CLASSES.TIME_SLOT')}</p>
                {sortedTimeslots?.length ? (
                    sortedTimeslots.map((field) => {
                        const fieldValue = timeslotWatch.find((i: { weekday: any }) => i.weekday === field['weekday']);
                        const setSessionType = (value) => {
                            setValue(`time_range.${field.originalIndex}.session_type`, value.value);
                            clearErrors(`time_range.${field.originalIndex}.session_type`);
                        };
                        const filterPassedTime = (time) => {
                            return fieldValue['start_time'] ? dayjs(time).isAfter(fieldValue['start_time']) : true;
                        };
                        const filterBeforeTime = (time) =>
                            fieldValue['end_time']
                                ? dayjs(time).set('milliseconds', 0).isBefore(dayjs(fieldValue['end_time']).set('milliseconds', 0))
                                : true;
                        return (
                            <div className="grid grid-cols-8 mb-ooolab_m_2 items-center gap-x-3" key={`${field.id}`}>
                                {/* row 1 */}
                                <label
                                    className="col-span-2 inline-flex align-middle capitalize text-ooolab_dark_2 font-semibold"
                                    htmlFor={field.id}
                                >
                                    {WeekDay(translator)[field['weekday']]}
                                </label>
                                <div className="col-span-2">
                                    <TimePickerInput
                                        control={control}
                                        name={`time_range.${field.originalIndex}.start_time`}
                                        placeholderText={translator('CLASSES.START_TIME')}
                                        filter={filterBeforeTime}
                                    />
                                </div>
                                <div className="col-span-2">
                                    <TimePickerInput
                                        control={control}
                                        name={`time_range.${field.originalIndex}.end_time`}
                                        placeholderText={translator('CLASSES.END_TIME')}
                                        filter={filterPassedTime}
                                    />
                                </div>
                                <div className="col-span-2">
                                    <Controller
                                        control={control}
                                        name={`time_range.${field.originalIndex}.session_type`}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: translator('FORM_CONST.REQUIRED_FIELD'),
                                            },
                                        }}
                                        render={() => {
                                            return (
                                                <Select
                                                    styles={customSelectClassType}
                                                    className="w-full"
                                                    options={classTypes}
                                                    onChange={setSessionType}
                                                    placeholder={translator('CLASSES.CLASS_TYPE')}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                                {/* row 2 */}
                                <div className="col-span-2"></div>
                                <div className="col-span-2">
                                    <ErrorMessage
                                        className="text-red-500 text-ooolab_10px mt-1"
                                        errors={errors}
                                        name={`time_range.${field.originalIndex}.start_time`}
                                        as="p"
                                    />
                                </div>
                                <div className="col-span-2">
                                    <ErrorMessage
                                        className="text-red-500 text-ooolab_10px mt-1"
                                        errors={errors}
                                        name={`time_range.${field.originalIndex}.end_time`}
                                        as="p"
                                    />
                                </div>
                                <div className="col-span-2">
                                    <ErrorMessage
                                        className="text-red-500 text-ooolab_10px mt-1"
                                        errors={errors}
                                        name={`time_range.${field.originalIndex}.session_type`}
                                        as="p"
                                    />
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <p className="text-ooolab_dark_2 text-ooolab_sm leading-ooolab_24px">{translator('CLASSES.NO_CLASS')}</p>
                )}
            </div>
            <div className="col-span-2">
                <p className="flex items-center space-x-2 relative">
                    <label className="text-ooolab_dark_1 text-ooolab_xs font-bold leading-ooolab_24px" htmlFor="days">
                        {translator('CLASSES.ASSIGNMENT_DURATION')}
                    </label>
                        <TooltipInfo
                            info={<div className="text-xs text-dark-300">{translator('CLASSES.ASSIGNMENT_DURATION_INSTRUCTION')}</div>}
                            styles="!top-0 !right-8 "
                        >
                            <CircleInformationIcon height="10.5" width="10.5" fill="#1F2937" />
                        </TooltipInfo>
                </p>
                <div className="grid grid-cols-3 gap-2 text-ooolab_xs font-normal">
                    <div>
                        <div className="flex items-center space-x-1 xl:space-x-2">
                            <input
                                className="border border-ooolab_bar_color text-ooolab_dark_1 p-ooolab_p_2 rounded w-14 xl:w-20"
                                type="text"
                                id="days"
                                // placeholder={translator('CLASSES.DAYS')}
                                {...register('days', {
                                    // required: {
                                    //     value: true,
                                    //     message: translator(
                                    //         'FORM_CONST.REQUIRED_FIELD'
                                    //     ),
                                    // },
                                    pattern: {
                                        value: /^[0-9]*$/,
                                        message: 'Enter the value as numeric',
                                    },
                                    onChange: (e) => {
                                        clearErrors('minutes');
                                    },
                                })}
                            />
                            <span>{translator('CLASSES.DAYS')}</span>
                        </div>
                        <ErrorMessage className="text-red-500 text-ooolab_10px" errors={errors} name="days" as="p" />
                    </div>
                    <div>
                        <div className="flex items-center space-x-1 xl:space-x-2">
                            <input
                                className="border border-ooolab_bar_color text-ooolab_dark_1 p-ooolab_p_2 rounded w-14 xl:w-20"
                                type="text"
                                id="hours"
                                maxLength={2}
                                defaultValue={1}
                                // placeholder={translator('CLASSES.HOURS')}
                                {...register('hours', {
                                    // required: {
                                    //     value: true,
                                    //     message: translator(
                                    //         'FORM_CONST.REQUIRED_FIELD'
                                    //     ),
                                    // },
                                    validate: (value) => {
                                        if (!regex2.test(value) || (regex2.test(value) && +value > 23)) {
                                            return 'Enter the value as numeric and start from 0 to 23';
                                        }
                                        return true;
                                    },
                                    onChange: (e) => {
                                        clearErrors('minutes');
                                    },
                                })}
                            />
                            <span>{translator('CLASSES.HOURS')}</span>
                        </div>
                        <ErrorMessage className="text-red-500 text-ooolab_10px" errors={errors} name="hours" as="p" />
                    </div>
                    <div>
                        <div className="flex items-center space-x-1 xl:space-x-2">
                            <input
                                className="border border-ooolab_bar_color text-ooolab_dark_1 p-ooolab_p_2 rounded w-14 xl:w-20"
                                type="text"
                                id="minutes"
                                maxLength={2}
                                // placeholder={translator('CLASSES.MINUTES')}
                                {...register('minutes', {
                                    // required: {
                                    //     value: ((days && days != 0) || (hours && hours != 0)) && !minutes ? false : true,
                                    //     message: translator('FORM_CONST.REQUIRED_FIELD'),
                                    // },
                                    validate: (value) => {
                                        if (!regex2.test(value) || (regex2.test(value) && +value > 59)) {
                                            return 'Enter the value as numeric and start from 0 to 59';
                                        }
                                        if ((!days || !hours) && value && +value < 30) {
                                            return 'Minimum time is 30 minutes';
                                        }
                                        return true;
                                    },
                                })}
                            />
                            <span>{translator('CLASSES.MINUTES')}</span>
                        </div>
                        <ErrorMessage className="text-red-500 text-ooolab_10px" errors={errors} name="minutes" as="p" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default FormCreateSession;
