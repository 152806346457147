import { Toastify } from '_shared';
import ButtonComponent from 'components/Button/ButtonComponent';
import DialogComponent from 'components/Dialog/DialogComponent'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router';
import selfBookingService from 'services/selfBooking.service';

interface IModalCancel  {
  isOpen: boolean,
  onCloseModal: () => void,
  refreshData?: () => void,
  sessionDetails: any
}

export default function ModalCancel({
  isOpen,
  onCloseModal,
  refreshData,
  sessionDetails
}: IModalCancel) {
  const [reason, setReason] = useState<string>('');
  const {t: translator} = useTranslation();
  const [loadingCancel, setLoadingCancel] = useState<boolean>(false)
  const params: any = useParams()

  const onCancelBooking = async () => {
    setLoadingCancel(true);
    let student_id;
    if (sessionDetails?.learning_path?.program_type === 'learning_group') {
        student_id = sessionDetails?.students?.find((student) => student?.is_leader)?.id;
    } else {
        student_id = sessionDetails?.students?.[0]?.id;
    }
    await selfBookingService
        .cancelBooking(params?.id, sessionDetails?.id, { student_id, reason: reason?.trim() })
        .then(() => {
            Toastify.success(translator('SELF_BOOKING.CANCEL_SUCCESS'));
            refreshData?.();
        })
        .catch(() => {
            Toastify.error(translator('SELF_BOOKING.CANCEL_FAILED'));
        })
        .finally(() => {
            onCloseModal();
        });
    setLoadingCancel(false);
    setReason('')
};

  return (
    <DialogComponent
        isOpen={isOpen}
        title={translator('SELF_BOOKING.CONFIRMATION')}
        onCloseModal={() => {
            onCloseModal()
            setReason('')
        }}
        maskClosable={false}
        styles="max-w-md"
        stylesTitle="text-base font-semibold"
        child={
            <div>
                <p className="text-sm font-bold mb-4">{translator('SELF_BOOKING.CONFIRM_CANCEL_SESSION')} <span className='text-primary-500'>Session {sessionDetails?.id}</span> ?</p>
                <label className='text-xs text-gray-800 font-semibold'>{translator('SELF_BOOKING.REASON')} </label>
                <textarea 
                    className='h-24 w-full mt-1 border border-gray-300 rounded text-xs p-2' 
                    value={reason} 
                    onChange={(e) => setReason(e.target.value)} 
                    placeholder={translator('SELF_BOOKING.ENTER_REASON')} 
                />
                {reason?.length > 250 && <p className='text-red-500 text-xs'>{translator('FORM_CONST.MAX_LENGTH_REASON_250')}</p>}
                
                <div className="flex items-center justify-center space-x-6 mt-4">
                    <ButtonComponent
                        title={translator('SELF_BOOKING.NO')}
                        classStyle="font-medium text-sm text-gray-800 border-gray-300 border rounded w-fit p-2 px-4 flex justify-center"
                        onClickButton={() => {
                            onCloseModal();
                            setReason('');
                        }}
                    />
                    <ButtonComponent
                        type="submit"
                        title={translator('SELF_BOOKING.CANCEL_SESSION')}
                        classStyle=" !font-medium !text-sm primary-button !bg-ooolab_error !rounded w-fit !p-2 flex justify-center disabled:!bg-gray-300"
                        loading={loadingCancel}
                        loadingColor="text-white"
                        onClickButton={onCancelBooking}
                        disable={!reason?.trim() || reason?.length > 250}
                    />
                </div>
            </div>
        }
    />
  )
}
