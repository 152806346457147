import React from "react";
import { InformationCircleIcon } from "assets/icon";
import "./PanelMessage.scss";
import { ButtonIcon } from "../Buttons/ButtonIcon/ButtonIcon";
import { CloseIcon } from "assets/icon/CloseIcon";

export const PanelMessage = ({message, onClick}: {message: string, onClick: () => void}) => {
    return<>
        <div className="panelMessage">
            <span className="panelMessage-icon">
                <InformationCircleIcon />
            </span>
            {message}
            <div className="ml-auto">
                <ButtonIcon  onClick={onClick}>
                    <span className="p-1">
                        <CloseIcon />
                    </span>
                </ButtonIcon>
            </div>
        </div>
    </>
}