import { FC, useContext, useMemo } from 'react';
import { CourseType } from 'types/Courses.type';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import ActionMenu from './ActionMenu';
import dayjs from 'dayjs';
import './Table.scss';
import NO_CONTENT from 'assets/img/empty-state/instructor.png';
import ReactTooltip from 'react-tooltip';
import { useParams } from 'react-router-dom';
import { UserContext } from 'contexts/User/UserContext';
import EnrollmentStatus from '_shared/components/EnrollmentStatus/EnrollmentStatus';
import { ListUser } from '_shared/components/User/User';

interface IViewTable {
    data: CourseType[];
    refreshData: Function;
}

const ViewTable: FC<IViewTable> = ({ data, refreshData }) => {
    const { t: translator } = useTranslation();

    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;
    const params: {id : string} = useParams();
    const {
        userState: { result },
    } = useContext(UserContext);
    const initialState = {
        hiddenColumns: ['id'],
    };

    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('COURSES.INSTRUCTOR_NAME')} />,
                Cell: ({ row }) => {
                    return <RenderTableCell onClick={() => window.open(`/workspace/${params?.id}/management/users/${row?.original?.instructor?.id}?tab=information`, '_blank')} className="text-primary-500 font-semibold" value={row.values?.instructor?.display_name} />;
                },
                accessor: 'instructor',
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.LEARNER_LEARNING_GROUP')} />,
                accessor: 'learning_groups',
                Cell: ({ row }: any) => {
                    const learningGroups = row?.original?.learning_groups?.map((item) => ({
                        ...item, 
                        link: `/workspace/${params?.id}/management/learning-groups/${item?.id}`
                    })) || []
                    const learners = row?.original?.learners?.map((item) => ({
                        ...item, 
                        link: `/workspace/${params?.id}/management/users/${item.id}?tab=information`
                    })) || []
                    const peopleEnrolled = [...learners, ...learningGroups];
                    return (
                        <div className="flex gap-1.5 text-sm text-gray-800">
                            <ListUser users={peopleEnrolled} id={'learner/learninggroup-of-instructor-enrollment'} /> 
                        </div>
                    );
                },
                width: "200"
            },

            {
                Header: () => <RenderTableHeader value={translator('COURSES.INVITED_DATE')} />,
                accessor: 'invited_at',
                Cell: ({ row }: any) => <RenderTableCell value={dayjs.utc(row.values?.invited_at).tz(result?.time_zone).format('DD/MM/YYYY')} />,
                width: "100"
            },

            {
                Header: () => <RenderTableHeader value={translator('COURSES.STATUS')} />,
                accessor: 'status',
                Cell: ({ row }: any) => {
                    if (!row?.original?.status) return <RenderTableCell value="" />;
                    return (
                        <EnrollmentStatus status={row?.original?.status} />
                    );
                },
                width: "100"
            },
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="h-full flex items-center justify-center">
                            <ActionMenu 
                                data={row?.original} 
                                refreshData={refreshData} 
                                position={row?.index > data?.length/2 ? 'bottom-0' : ''}
                            />
                        </div>
                    );
                },
                accessor: 'action',
                width: 60,
            },
        ],
        [data, WorkspaceDetailInformation]
    );

    return (
        <Table
            data={data || []}
            columns={columns}
            initialState={initialState}
            emptyContent={translator('COURSES.NO_INSTRUCTOR')}
            emptyIcon={NO_CONTENT}
        />
    );
};

export default ViewTable;
