export const ListIcon = ({width = 18, height= 24}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1609_1007)">
                <path
                    d="M6 6H15.75V7.5H6V6ZM3.75 5.25V7.5H4.5V8.25H2.25V7.5H3V6H2.25V5.25H3.75ZM2.25 13.5V11.625H3.75V11.25H2.25V10.5H4.5V12.375H3V12.75H4.5V13.5H2.25ZM3.75 17.625H2.25V16.875H3.75V16.5H2.25V15.75H4.5V18.75H2.25V18H3.75V17.625ZM6 11.25H15.75V12.75H6V11.25ZM6 16.5H15.75V18H6V16.5Z"
                    fill='currentColor'
                />
            </g>
            <defs>
                <clipPath id="clip0_1609_1007">
                    <rect width="18" height="18" fill="white" transform="translate(0 3)" />
                </clipPath>
            </defs>
        </svg>
    );
}
