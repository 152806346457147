import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';
import ButtonComponent from 'components/Button/ButtonComponent';
import { checkPermission } from 'utils/commonFun';
import { useBoolean } from 'hooks/custom';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { useTranslation } from 'react-i18next';
import workspaceService from 'services/workspace.service';
import Spinner from 'components/Spinner';
import dayjs from 'dayjs';
import { UserContext } from 'contexts/User/UserContext';
import { Toastify } from '_shared';

const GroupInfo = ({ setData }) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid, isDirty },
        reset,
    } = useForm({ mode: "onChange" });

    const { t: translator } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [currentData, setCurrentData] = useState<any>();
    const {
        booleanValue: modalConfirm,
        toggleBooleanValue: toggleModalConfirm,
    } = useBoolean();

    const user = useContext(UserContext)
    const timezone = user?.userState?.result?.time_zone

    const params: any = useParams();
    const { dispatch: workspaceDispatch } = useContext(GetWorkspaceContext);

    const getInfo = () => {
        setLoading(true);
        workspaceService.getLearningGroupDetail(params.id, params.groupId)
            .then(res => {
                const data = res?.data;
                setCurrentData(data);
                setData(data);
                reset(
                    {
                        name: data?.name,
                        description: data?.description,
                        created_at: data?.created_at && dayjs.utc(data?.created_at)?.tz(timezone)?.format("DD/MM/YYYY")
                    },
                    {
                        keepDirty: false,
                    }
                );
                workspaceMiddleware.setCurrentRouteDetail(workspaceDispatch, [
                    {
                        name: data?.name,
                        routeId: 'groupId',
                    },
                ])
            })
            .finally(() => setLoading(false))
        return (
            workspaceMiddleware.setCurrentRouteDetail(workspaceDispatch, [
                {
                    name: '',
                    routeId: '',
                },
            ])
        )
    }

    useEffect(() => {
        getInfo();
    }, []);

    const onUpdateInfo = (val) => {
        setLoading(true);
        modalConfirm && toggleModalConfirm()
        const data = {
            name: val.name?.trim(),
            status: currentData?.status
        }
        workspaceService.updateLearningGroupService(params?.id, params?.groupId, data)
            .then(res => {
                Toastify.success(translator("LEARNING_GROUP.UPDATE_GROUP_SUCCESS"))
                getInfo();
            })
            .catch(err => {
                Toastify.error(translator("LEARNING_GROUP.UPDATE_GROUP_ERROR"))
            })
            .finally(() => {
                setLoading(false);
            });
    }


    const submitForm = (val) => {
        onUpdateInfo(val);
    }

    if (loading) {
        return (
            <div className='py-10 flex justify-center'>
                <Spinner />
            </div>
        );
    }

    return (
        <div>
            {checkPermission('learningGroupDetailsInformation', 'U') && <div className='flex justify-end px-8 py-4 ooolab_bar_color'>
                <ButtonComponent disable={!isValid || !isDirty} type='submit' onClickButton={handleSubmit(submitForm)} title={translator("SAVE")} classStyle='primary-button disabled:!bg-primary-500 disabled:!opacity-50'></ButtonComponent>
            </div>}

            <form className="px-ooolab_p_5 grid grid-cols-2 gap-6 mt-4 max-w-5xl" onSubmit={handleSubmit(submitForm)}>
                <div className=" text-ooolab_xs col-span-1">
                    <label
                        htmlFor="group-name"
                        className="text-dark-300 font-semibold pb-0.5"
                    >
                        {translator("LEARNING_GROUP.GROUP_NAME")} <span className="text-red-500"> *</span>
                    </label>
                    <input
                        className="border w-full border-ooolab_bar_color text-ooolab_dark_1 h-[38px] rounded font-normal px-3"
                        type="text"
                        id="group-name"
                        placeholder={translator("LEARNING_GROUP.GROUP_NAME_DESC")}
                        {...register('name', {
                            required: {
                                value: true,
                                message: `${translator(
                                    'FORM_CONST.REQUIRED_FIELD'
                                )}`,
                            },
                            validate: {
                                shouldNotContainSpace: (value) =>
                                    !!value.trim()
                                        ? true
                                        : `${translator(
                                            'FORM_CONST.REQUIRED_FIELD'
                                        )}`,
                            },
                            maxLength: {
                                value: 100,
                                message: translator('GROUP.GROUP_NAME_MAX_LENGTH'),
                            },
                        })}
                        disabled={!checkPermission('learningGroupDetailsInformation', 'U')}
                    />
                    <ErrorMessage
                        className="text-red-500 text-ooolab_10px"
                        errors={errors}
                        name="name"
                        as="p"
                    />
                </div>
                <div className=" text-ooolab_xs col-span-1">
                    <label
                        htmlFor="created_at"
                        className="text-ooolab_dark_1 font-semibold block pb-0.5"
                    >
                        {translator("LEARNING_GROUP.CREATED_DATE")}
                    </label>
                    <input
                        disabled
                        className="border w-full disabled:bg-gray-100 border-ooolab_bar_color text-ooolab_dark_1 h-[38px] rounded font-normal px-3"
                        type="text"
                        {...register('created_at')}
                    />
                </div>
            </form>
        </div>

    );
};

export default GroupInfo;