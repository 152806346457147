import i18n from "i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import FsBackend from "i18next-fs-backend";
import {getLocalStorageAuthData} from "../utils/handleLocalStorage";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
const LANGUAGE_DEFAULT = process.env.REACT_APP_LANGUAGE_DEFAULT;
const REACT_APP_LANGUAGE_URL = process.env.REACT_APP_LANGUAGE_URL;

i18n
  .use(HttpBackend)
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)  
  .init({
    compatibilityJSON: 'v3',
    fallbackLng: LANGUAGE_DEFAULT,
    backend: {
      backends: [
        HttpBackend,
        FsBackend
      ],
      backendOptions: [{
        loadPath: `${REACT_APP_LANGUAGE_URL}/${process.env.REACT_APP_WHITELABEL}/{{lng}}.json` 
      }]
    },
    react: {
      useSuspense: false
    },
    interpolation: {
      escapeValue: false, // prevent i18n from encoding special characters
    }
  });
i18n.changeLanguage(getLocalStorageAuthData()?.language || LANGUAGE_DEFAULT);

i18n?.on('loaded', function(loaded) {
  setTimeout(() => {
    document.getElementById('loader')?.remove();
    document.getElementById('root').style.opacity = '1';
  }, 1400);
})

export default i18n;