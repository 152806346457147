import React from 'react';
import ManagementMasterPage from '../../../RouteMasterPage';
import ListClassroom from 'components/Management/Classrooms/ClassroomList';

const ClassroomManagementPage = () => {
    return (

        <ManagementMasterPage>
            <ListClassroom />
        </ManagementMasterPage>

    );
};

export default ClassroomManagementPage;
