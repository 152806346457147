import { CircleSpin } from 'components/UiComponents/CircleSpin';
import './LoadingPopup.scss';

export function LoadingPopup({ loading, message = '' }) {
    return (
        <>
            <div className="loadingPopup">
                <div className="loadingPopup-card">
                    <div className="loadingPopup-sticky">
                        <div className="loadingPopup-item">
                            <CircleSpin color={'text-primary-500'}/>
                            {loading && message}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
