import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import { LearnerOrLearningGroupWorkspace } from '_shared/components/LearnerAndGroupSearch/LearnerOrLearningGroupWorkspace';
import { WorkspaceService } from 'services';
import { LOCATION, USER_ROLE } from 'components/Management/User/constant';
import { useTranslation } from 'react-i18next';
import { checkPermission } from 'utils/commonFun';

type FilterCalendarType = {
    onChangeFilter: (value: any) => void;
    isFirstLoad: boolean;
    className?: string;
};

export const FilterCalendar = ({ onChangeFilter, isFirstLoad, className = '' }: FilterCalendarType) => {
    const params: { id: string } = useParams();
    const [listCourse, setListCourse] = useState<any>([]);
    const [inputSearchCourse, setInputSearchCourse] = useDebouncedState('');
    const [courseSelected, setCourseSelected] = useState<any>([]);
    const [listInstructor, setListInstructor] = useState<any>([]);
    const [inputSearchInstructor, setInputSearchInstructor] = useDebouncedState('');
    const [instructorSelected, setInstructorSelected] = useState<any>([]);
    const [learnerOrLearningGroupSelected, setLearnerOrLearningGroupSelected] = useState<any>();
    const [paginationCourse, setPaginationCourse] = useState<any>({ page: 1 });
    const [paginationInstructor, setPaginationInstructor] = useState<any>({ page: 1 });
    const {t} = useTranslation();
    const getCourses = async (page) => {
        let payload: any = {
            title: inputSearchCourse,
            page: page ? page : paginationCourse.page,
            per_page: 20,
            order: 'desc',
            sort_by: 'updated_at',
        };

        if (!payload?.title) delete payload.title;

        const response = await WorkspaceService.getCoursesList(params.id, payload);

        if (response) {
            const formattedData = response?.items?.map((item) => {
                return {
                    id: item?.id,
                    name: item?.title,
                    value: item?.id,
                };
            });
            if (page === 1) setListCourse(formattedData);
            else setListCourse([...listCourse, ...formattedData]);
            setPaginationCourse({ ...paginationCourse, total: response?.total, page });
        }

        return [];
    };

    const getInstructors = async (page) => {
        let payload: any = {
            q: inputSearchInstructor,
            page: page ? page : paginationInstructor.page,
            per_page: 20,
            order: 'desc',
            sort_by: 'updated_at',
            location: LOCATION.clms,
            user_role_name: USER_ROLE.instructor,
        };

        if (!payload?.q) delete payload.q;

        const response = await WorkspaceService.getWorkspaceMembersService(params.id, payload);

        if (response) {
            const formattedData = response?.data?.items?.map((item) => {
                return {
                    id: item?.id,
                    name: item?.display_name,
                    value: item?.id,
                };
            });
            if (page === 1) setListInstructor(formattedData);
            else setListInstructor([...listInstructor, ...formattedData]);
            setPaginationInstructor({ ...paginationInstructor, total: response?.data?.total, page });
        }

        return [];
    };

    const updateFilter = useCallback(
        debounce((listFilter) => onChangeFilter(listFilter), 1500),
        []
    );

    useEffect(() => {
        if (!isFirstLoad) {
            let listFilter: any = {
                course_ids: courseSelected?.length ? courseSelected?.map((el) => el?.value)?.join(',') : null,
                instructor_ids: instructorSelected?.length ? instructorSelected?.map((el) => el?.value)?.join(',') : null,
            };
            if (learnerOrLearningGroupSelected) {
                listFilter = {
                    ...listFilter,
                    ...learnerOrLearningGroupSelected,
                };
            }
            if (!listFilter?.course_ids) delete listFilter?.course_ids;
            if (!listFilter?.instructor_ids) delete listFilter?.instructor_ids;
            if (!listFilter?.learner_ids || listFilter?.learner_ids == '') delete listFilter?.learner_ids;
            if (!listFilter?.learning_group_ids || listFilter?.learning_group_ids == '') delete listFilter?.learning_group_ids;

            updateFilter(listFilter);
        }
    }, [courseSelected, instructorSelected, learnerOrLearningGroupSelected]);

    useEffect(() => {
        if(checkPermission('course', 'R'))   getCourses(1);
    }, [inputSearchCourse]);

    useEffect(() => {
        getInstructors(1);
    }, [inputSearchInstructor]);

    return (
        <>
            <div className={`flex gap-2 ${className}`}>
                {checkPermission('course', 'R') &&
                    <SelectBox
                        label={t("COURSE")}
                        isMulti
                        onSearch={setInputSearchCourse}
                        onSelect={setCourseSelected}
                        data={listCourse}
                        selectedItems={courseSelected}
                        total={paginationCourse?.total}
                        showmore={() => getCourses(paginationCourse?.page + 1)}
                    />
                }

                <SelectBox
                    label={t("INSTRUCTOR")}
                    isMulti
                    onSearch={setInputSearchInstructor}
                    onSelect={setInstructorSelected}
                    data={listInstructor}
                    selectedItems={instructorSelected}
                    total={paginationInstructor?.total}
                    showmore={() => getInstructors(paginationInstructor?.page + 1)}
                />
                <LearnerOrLearningGroupWorkspace onChangeFilter={setLearnerOrLearningGroupSelected} />
            </div>
        </>
    );
};
