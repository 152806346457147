import { useState, useEffect } from 'react';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import './Filter.scss';
import { STATUS_LEARNER_ENROLL_OPTION } from '../../constants';
import courseService from 'services/course.service';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IFilter {
    onChangeFilter: (value: any) => void;
}

const Filter = ({
    onChangeFilter,
}: IFilter) => {
    const [keyword, setKeyword] = useDebouncedState('', 0);
    const [statusSelected, setStatusSelected] = useState([]);
    const [enrollmentSelected, setEnrollmentSelected] = useState([]);
    const [enrollmentList, setEnrollmentList] = useState([]);
    const [loadingFilterEnrollment, setLoadingFilterEnrollment] = useState(false);
    const [inputSearchEnrollment, setInputSearchEnrollment] = useState('');
    const [pagination, setPagination] = useState<any>({page: 1, per_page: 20})
    const params: {id, courseId} = useParams();
    const {t} = useTranslation();

    const getEnrollments = (page: number = 1) => {
        setLoadingFilterEnrollment(true);
        const request: any = {
            page: page ? page : pagination.page,
            per_page: 10,
            q: inputSearchEnrollment,
        };
        courseService.getEnrollments(params.id,  params?.courseId, request)
            .then((res) => {
                const formatedData = res?.data?.items?.map((item) => {
                    return {
                        name: item?.name || item?.learning_group?.name ||item?.learners[0]?.display_name,
                        id: item?.id,
                        value:  item?.id,
                    };
                });
                setPagination({ ...pagination, total: res?.data?.total, page });
                if (page === 1) setEnrollmentList(formatedData);
                else setEnrollmentList([...enrollmentList, ...formatedData]);
            })
            .finally(() => setLoadingFilterEnrollment(false));
    };

    useEffect(() => {
        getEnrollments(1);
    }, [inputSearchEnrollment]);

    useEffect(() => {
        let listFilter: any = {
            q: keyword,
            statuses: statusSelected?.map(status => status?.value)?.join(','),
            enrollment_ids: enrollmentSelected?.map(enrollment => enrollment?.value)?.join(','),
        };
        if (!listFilter.q) delete listFilter.q
        if (!statusSelected?.length || statusSelected.length === 4) delete listFilter.statuses;
        if (!listFilter?.enrollment_ids.length) delete listFilter.enrollment_ids;
        onChangeFilter(listFilter);
    }, [ keyword, statusSelected, enrollmentSelected]);

    

    return (
        <div className="FilterEnrollment">
            <SearchBox placeholder={t("Search by Learner Name")} className="FilterEnrollment-searchInput" onSubmit={(value) => setKeyword(value)} />
            <SelectBox
                label={t("COURSES.ENROLLMENT")}
                onSearch={setInputSearchEnrollment}
                selectedItems={enrollmentSelected}
                data={enrollmentList}
                total={enrollmentList?.length} 
                onSelect={setEnrollmentSelected}   
                loading={loadingFilterEnrollment}   
                styles={{container: "flex-nowrap"}}                
            />
            <SelectBox
                label={t("STATUS")}
                onSelect={setStatusSelected}
                selectedItems={statusSelected}
                data={STATUS_LEARNER_ENROLL_OPTION(t)}
                total={4}
            />
        </div>
    );
};

export default Filter;
