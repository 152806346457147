import React, { useRef } from 'react';
import { useContext, useEffect, useState } from 'react';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useParams, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ResourcesType } from 'types/Resources.type';
import { Column } from 'react-table';
import { TrashIcon } from '@heroicons/react/outline';
import resourcesService from 'services/resources.service';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import DeleteResourceModal from './ResourcesComponents/DeleteResourceModal';
import Table, { RenderTableCell, RenderTableHeader } from 'components/V2/Table/TableCustom';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import { ValidateFileUpload } from 'utils/validate';
import { UserContext } from 'contexts/User/UserContext';
import dayjs from 'dayjs';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import Pagination from 'components/V2/Pagination';
import { PopoverNewResource } from './ResourcesComponents/PopoverNewResource';
import { accept_file_upload_library } from 'constant/form.const';
import { LibraryTypeEnum, LIBRARY_ICONS, onDownload } from 'components/Library/LibraryContants';
import ActionMenuResourceTable from './ResourcesComponents/ActionMenuResourceTable';
import { EyesIcon } from 'assets/icon/EyesIcon';
import { DownloadIcon, EditIcon, EditLine, FolderIcon, MoveIcon } from 'assets/icon';
import DialogComponent from 'components/Dialog/DialogComponent';
import FromLibrary from 'components/Library/LessonBuilder/components/Content/FromLibrary/FromLibrary';
import { TYPE_CREATE_CONTENT } from 'components/Library/LessonBuilder/constants';
import { checkFileType } from 'components/Academics/LearningPath/LearningPathList/Components/Filter/uitils';
import SearchBox from 'components/V2/SearchBox';
import { LoadingPopup, Toastify } from '_shared';
import ModalRename from 'components/Library/TabLibrary/Modals/ModalRename';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid';
import { DocumentPreview } from 'components/Library/components/DocumentPreview/DocumentPreview';
import { UploadContext } from 'contexts/Upload';
import workspaceService from 'services/workspace.service';
import { useDebouncedState } from 'hooks/useDebounce';
import ModalMoveFile from './ResourcesComponents/ModalMoveFile';
import ModalMoveFileToNewFolder from './ResourcesComponents/ModalMoveFiletoNewFolder';
import ModalRenameFolder from './ResourcesComponents/ModalRenameFolder';
import ActionsButton from 'components/ActionsButton';

const ResourcesList: React.FC = ({ children }) => {
    const history = useHistory();
    const { t: translator } = useTranslation();
    const translatorLabel = (str: string, newKey?: boolean, opt?: any) => (newKey ? translator(str, opt) : translator(`LIBRARY.${str}`, opt));
    const file = useRef<any>();
    const params: { id: string } = useParams();
    const [dataList, setDataList] = useState<ResourcesType>(null);
    const [hasEdit, setHasEdit] = useState(false);
    const [hasDelete, setHasDelete] = useState(false);
    const [resourceSelected, setResourceSelected] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(true);
    const { userState } = useContext(UserContext);
    const [isOpenModalLibrary, setIsOpenModalLibrary] = useState(false);
    const [keyword, setKeyword] = useState(null);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [selectDocument, setSelectDocument] = useState(null);
    const uploadContext: any = useContext(UploadContext);
    const [isOpenModalMoveFile, setOpenModalMoveFile] = useState(false);
    const [isOpenModalMoveFileToNewFolder, setOpenModalMoveFileToNewFolder] = useState(false);
    const [isOpenModalRenameFolder, setOpenModalRenameFolder] = useState(false)
    const [folderSelected, setFolderSelected] = useState(undefined)
    const [folderSelectedToRename, setFolderSelectedToRename] = useState(undefined)

    const {getWorkspaceDetailState } = useContext(
        GetWorkspaceContext
    );

    const { result: workspaceDetailInformation } = getWorkspaceDetailState;
    const [indexPage, setIndexPage] = useState('1-50');
    const { result: userInformation } = userState;

    const getResourceList = async ({
        page,
        per_page = 20,
        q,
        order = 'desc'
    }: {
        page: number;
        per_page?: number;
        q?: string;
        order?: string;
    }) => {
        setLoading(true);
        const payload = {
            page,
            per_page,
            q,
            sort_by: 'updated_at',
            order,
            custom_fields: JSON.stringify([{custom_field_id: workspaceDetailInformation.custom_field_ws_resource_folder?.id, data:[folderSelected?.name]}]) 
        }
        if (!payload.q) delete payload.q;
        if (!folderSelected) delete payload.custom_fields;
        try {
            const response = await resourcesService.getListResources(params.id, payload);
            if (response) {
                setDataList(response);
                setIndexPage(getIndexPage(response?.page, response?.per_page, response?.items?.length));
            }
            setLoading(false);
        } catch {
            setLoading(false);
        }
    };

    const handleSort = () => {
        const order = dataList.order === 'desc' ? 'asc' : 'desc';
        getResourceList({ page: dataList.page, order, q: keyword });
    }

    const previewLibrary = (value) => {
        switch (value?.object_type) {
            case LibraryTypeEnum.image:
            case LibraryTypeEnum.video:
            case LibraryTypeEnum.document:
            case LibraryTypeEnum.audio:
            case LibraryTypeEnum.pdf:
            case LibraryTypeEnum.presentation:
            case LibraryTypeEnum.spreadsheet:
                setSelectDocument(value);
                break;
            case LibraryTypeEnum.h5p:
                history.push(`/workspace/${params?.id}/h5p-content/${value.object_id}/preview?from=resourse`);
                break;
            case LibraryTypeEnum.lesson:
                history.push(`/workspace/${params?.id}/lesson/${value.object_id}/preview?from=resourse`);
                break;
            case LibraryTypeEnum.assignment:
                history.push(`/workspace/${params?.id}/assignment/${value.object_id}/preview?from=resourse`);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        getResourceList({ page: 1, q: keyword });
    }, [keyword, folderSelected])

    const columns: Column<any>[] = React.useMemo(
        () => [
            {
                Header: () => (
                    <RenderTableHeader value={translator("RESOURCES.NAME")} />
                ),
                accessor: 'library_content',
                Cell: (d: any) => {
                    let type = d?.value?.object_type;
                    const subType = d?.value?.sub_object_type
                    return (
                        <div className="flex items-center px-2">
                            <div className="flex-none w-6">
                                {<img
                                    alt="libary-icon"
                                    className="h-6 w-6"
                                    src={
                                        type !== 'image'
                                          ? LIBRARY_ICONS(translator)?.find((item) => item?.value === type)?.icon
                                          :  LIBRARY_ICONS(translator)?.find((item) => subType?.includes(item?.value))?.icon
                                      }
                                />}
                            </div>
                            <div className="w-full">
                                <RenderTableCell
                                    className="p-3 pl-1 font-normal text-sm !text-gray-800 cursor-pointer truncate"
                                    value={d?.value?.name?.replace(d?.value?.sub_object_type, '')}
                                />
                            </div>
                        </div>
                    );
                },
                width: 180,
            },
            {
                Header: () => (
                    <RenderTableHeader value={translator("RESOURCES.CREATED_DATE")} />
                ),
                accessor: 'created_at',
                Cell: (d: any) => {
                    return (
                        <div className=" pl-3  py-3 font-normal text-ooolab_dark_1 text-xs">
                            {dayjs(d?.value)
                                .tz(userInformation?.time_zone || 'UTC')
                                .format('DD MMM YYYY')}
                        </div>
                    );
                },
                width: 100,
            },
            {
                Header: () => (
                    <RenderTableHeader value={translator("RESOURCES.CONTENT_TYPE")} />
                ),
                accessor: 'library_content.object_type',
                Cell: (d: any) => {
                    return (
                        <div className=" pl-3  py-3 font-normal text-ooolab_dark_1 text-xs loo">
                            {
                                `${LIBRARY_ICONS(translatorLabel)?.find((item) => item?.value === d?.value)?.label}`
                            }
                        </div>
                    );
                },
                width: 100,
            },
            {
                Header: () => (
                    <div className="flex items-center cursor-pointer text-primary-500" onClick={handleSort}>
                        <RenderTableHeader className="!text-primary-500" value={translator('RESOURCES.LAST_MODIFIED')} />
                        {dataList.order === 'desc' ? (
                            <ArrowDownIcon className="w-4 h-4 ml-1 " />
                        ) : (
                            <ArrowUpIcon className="w-4 h-4 ml-1 " />
                        )}
                    </div>
                ),
                accessor: 'library_content.updated_at',
                Cell: (d: any) => {
                    return (
                        <div className=" pl-3  py-3 font-normal text-ooolab_dark_1 text-xs">
                            {
                                dayjs.utc(d?.value).tz(userInformation?.time_zone).fromNow()
                            }
                        </div>
                    );
                },
                width: 80,
            },
            {
                Header: () => (<></>),
                accessor: 'action',
                Cell: ({ row, column }: { row: any; column: Column }) => {
                    return (
                        <div className="h-full flex items-center">
                            <ActionMenuResourceTable
                                menus={[
                                    {
                                        label: translator('PREVIEW'),
                                        icon: <EyesIcon width="18" height="16" fill="#111827" />,
                                        isDisabled: (!checkPermission('resource', 'R')),
                                        action: () => {
                                            previewLibrary({
                                                ...row?.original?.library_content,
                                                id: row?.original?.id
                                            });
                                        },
                                        hide: !checkPermission('resource','R')
                                    },

                                    {
                                        label: translator('DOWNLOAD'),
                                        icon: <DownloadIcon width="18" height="16" fill="#111827" />,
                                        isDisabled: (!checkPermission('resource', 'DL')),
                                        action: () => {
                                            onDownload(row?.original?.library_content, params, setLoadingDownload);
                                        },
                                        hide: !checkPermission('resource','DL')
                                    },
                                    {
                                        label: translator('RESOURCES.MOVE_TO_FOLDER'),
                                        icon: <MoveIcon />,
                                        isDisabled: (!checkPermission('resource', 'U')),
                                        action: (e) => {
                                            setResourceSelected(row?.original?.library_content);
                                            setOpenModalMoveFile(true);
                                        },
                                        hide: !checkPermission('resource','U')
                                    },
                                    {
                                        label: translator('RESOURCES.MOVE_TO_NEW_FOLDER'),
                                        icon: <MoveIcon />,
                                        isDisabled: (!checkPermission('resource', 'U')),
                                        action: (e) => {
                                            setResourceSelected(row?.original?.library_content);
                                            setOpenModalMoveFileToNewFolder(true);
                                        },
                                        hide: !checkPermission('resource','U')
                                    },
                                    {
                                        label: translator('RENAME'),
                                        icon: <EditLine />,
                                        isDisabled: (!checkPermission('resource', 'U')),
                                        action: (e) => {
                                            setResourceSelected(row?.original?.library_content);
                                            setHasEdit(true);
                                        },
                                        hide: !checkPermission('resource','U')
                                    },
                                    {
                                        label: translator('DELETE'),
                                        icon: <TrashIcon width="18" height="16" className="text-gray-900" />,
                                        isDisabled: (!checkPermission('resource', 'D')),
                                        action: async (e) => {
                                            setResourceSelected(row?.original);
                                            setHasDelete(true);
                                        },
                                        hide: !checkPermission('resource','U')
                                    },

                                ]}
                                position={row?.index > dataList?.items?.length/2 ? 'bottom-10': ''}
                            />
                        </div>
                    );
                },
                width: 20,
            }
        ],
        [userInformation?.time_zone, dataList]
    );

    const handleChangePagination = (page: number) => {
        getResourceList({ page, order: dataList.order, q: keyword });
    };

    const handleInputFile = (e: any) => {
        if (e.target?.files?.length <= 0) {
            return;
        }
        const extensionSplit = e?.target?.files[0]?.name.split('.');
        const extension = extensionSplit[extensionSplit.length - 1];
        const sizeMB = e?.target?.files[0]?.size / 1024 / 1024;

        if (ValidateFileUpload(extension) && sizeMB <= 101) {
            setUploading(true);
            resourcesService.uploadFile(
                e.target.files[0],
                async (url) => {
                    await resourcesService
                        .createResources(params.id, {
                            object_type: e.target.files[0]?.object_type || checkFileType(e.target.files[0].type || e.target.files[0]?.mime_type),
                            name: e.target.files[0]?.name,
                            file: url,
                            is_published: true,
                            size: sizeMB,
                            status: 'published',
                            from_lesson: false,
                            from_assignment: false
                        })
                        .then((res) => Toastify.success())
                        .catch((err) => {
                            Toastify.error(translator('RESOURCES.Msg_exist_file'));
                        });
                    setUploading(false);
                    getResourceList({ page: 1 });
                    file.current.value = '';
                },
                (err) => {
                    Toastify.error();
                    file.current.value = '';
                    setUploading(false);
                },
                null,
                true
            );
        } else if (!ValidateFileUpload(extension)) {
            file.current.value = '';
            Toastify.error(
                'This file type is not supported. The following file types are supported: pdf, doc, docx, png, jpg, jpeg, mp4, ppt, pptx'
            );
        } else {
            file.current.value = '';
            Toastify.error('File is larger than 100MB');
        }
    };

    const deleted = () => {
        if (dataList?.items?.length === 1 && folderSelected) {
            setFolderSelected(undefined);
            getFolderResourse(1);
        }
        else {
            getResourceList({page: dataList?.page})
        }
        setHasDelete(false);
       
    };

    const handleAddContentToSection = async (data) => {
        try {
            await resourcesService.importResources(params?.id, { library_content_ids: data?.map(item => item.id) });
            getResourceList({ page: 1 });
            Toastify.success();
        } catch (error) {
            let errorMessage;
            if (error?.error?.body_params?.[0]?.msg?.includes("ALREADY_IMPORTED")) {
                errorMessage = translator('RESOURCES.Msg_exist_file');
            }
            Toastify.error(errorMessage);
        }
    }

    useEffect(() => {
        if (Object.keys(uploadContext?.fileUploadSuccess)?.length > 0) getResourceList({page: 1});
    }, [uploadContext?.fileUploadSuccess]);

    const [paginationFolder, setPaginationFolder] = useState<any>({page: 1, per_page: 10});
    const [inputSearchFolder, setInputSearchFolder] = useDebouncedState('', 0);
    const [listFolders, setListFolders] = useState([])
    const [loadingFOolder, setLoadingFolder] = useState(false)

    const getFolderResourse = (page: number) => {
        setLoadingFolder(true)
        const payload = {
            page: page || paginationFolder?.page,
            q: inputSearchFolder,
            per_page: 12,
            custom_field_id: workspaceDetailInformation?.custom_field_ws_resource_folder?.id,
            skip_pagination: true
        }
        workspaceService.getCustomFieldsByNameMapDataService(params?.id, payload)
        .then((res) => {
            if (page === 1) setListFolders(res?.items)
            else  setListFolders([...listFolders, ...res?.items])
            setPaginationFolder({ ...paginationFolder, page: res?.page, total: res?.total})
        })
        .finally(() => setLoadingFolder(false))
    }

   
    useEffect(() => {
        if (workspaceDetailInformation?.custom_field_ws_resource_folder) {
            getFolderResourse(1)
        }
    }, [workspaceDetailInformation])

    return (
        <div>
            <input
                ref={file}
                type="file"
                onChange={(e) => handleInputFile(e)}
                className="hidden"
                accept={accept_file_upload_library?.join(',')}
            />
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.CONTENT_MANAGEMENT')}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    { name: translator('DASHBOARD.SIDEBAR.LEARNER_PORTAL_RESOURCE'), href: '', className: !folderSelected ? 'font-semibold' : '', onClick:() => setFolderSelected(undefined)},
                    { name: folderSelected?.name, href: '', className: '' }
                ]}
            />
            <div className='flex justify-between p-4 border-b border-gray-200'>
                <div className='w-full'>
                    <SearchBox className="w-full max-w-[320px] !text-sm max-h-9" onSubmit={(value) => setKeyword(value)} />
                </div>
                {checkPermission('resource', 'C') && <PopoverNewResource
                    onUploadFile={() => file?.current?.click()}
                    refreshData={() => getResourceList({ page: 1, order: dataList.order})}
                    onAddLibrary={() => setIsOpenModalLibrary(true)}
                />}
            </div>
            
            {listFolders?.length > 0 && !folderSelected && (
                <div className='mt-4 px-4'>
                    <div className='font-semibold text-base text-gray-800'>{translator('FOLDERS')}</div>
                    <div className='grid 2xl:grid-cols-5 grid-cols-4  gap-4 flex-wrap mt-2'>
                        {
                                listFolders?.map((item) => {
                                    return (
                                        <div className={`
                                            p-3 col-span-1 cursor-pointer 
                                            ${folderSelected?.id === item?.id ? 'bg-blue-50 text-primary-500' : 'bg-gray-100 text-gray-800'}  
                                            rounded-xl flex items-center justify-between pl-3
                                            hover:bg-blue-50 hover:text-primary-500
                                            `
                                            } key={item?.id}>
                                            <div className='flex items-center gap-1 w-full max-w-[calc(100%-30px)]'
                                                onClick={() => {
                                                    if (folderSelected?.id === item?.id) {
                                                        return setFolderSelected(undefined)
                                                    }
                                                    setFolderSelected(item)
                                                }}
                                            >
                                                <div className='text-amber-400'><FolderIcon width={32} height={32} /></div>
                                                <div className='text-sm font-semibold text-ellipsis truncate'>{item?.name}</div>
                                            </div>
                                            <ActionsButton
                                                className={'!-mr-2'}
                                                classNameIcon={'w-5 h-5'}
                                                styleOptions={`!-bottom-10 !-right-2`}
                                                menus={[
                                                    {
                                                        label: translator('RENAME'),
                                                        icon: <EditIcon width="10" height="16" fill="#111827" />,
                                                        isDisabled: (!checkPermission('resource', 'U')),
                                                        action: () => {
                                                            setOpenModalRenameFolder(true);
                                                            setFolderSelectedToRename(item)
                                                        },
                                                        hide: false
                                                    }
                                                ]}
                                            />
                                        </div>
                                    )
                                })
                            }
                    </div>
                </div>
            )
           }

            <div className="w-full px-4 py-4 bg-2   ">
                {
                    !folderSelected && <div className='font-semibold text-base mb-2 text-gray-800'>Files</div>
                }
                <>
                    <div className={`h-[calc(100vh-210px)] relative custom-scrollbar overflow-y-auto`}>
                        {loading ?
                            <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                                <CircleSpin color='text-primary-500' />
                            </div>
                            :
                            <Table
                                data={dataList?.items}
                                columns={columns}
                                emptyContent={translator("RESOURCES.NO_RESOURCES")}
                            />
                        }
                    </div>
                    {dataList?.items?.length > 0 && <Pagination
                        per_page={dataList?.per_page}
                        page={dataList?.page}
                        indexPage={indexPage}
                        callback={handleChangePagination}
                        total={dataList?.total}
                    />}
                </>
            </div>
            {hasEdit && (
                <ModalRename
                    isOpen={hasEdit}
                    onClose={() => {
                        setHasEdit(false);
                    }}
                    refreshData={() => {
                        getResourceList({ page: dataList.page, q: keyword });
                    }}
                    item={resourceSelected}
                />
            )}
            <DeleteResourceModal
                open={hasDelete}
                onCloseModal={() => setHasDelete(false)}
                resource={resourceSelected}
                onDeleted={(value) => deleted()}
            />
            {isOpenModalLibrary && (
                <DialogComponent
                    isOpen={isOpenModalLibrary}
                    onCloseModal={() => setIsOpenModalLibrary(false)}
                    hasNonTitle
                    styles="max-w-[70vw] min-h-[480px] my-0 rounded-lg !p-0 pb-4"
                    isShowClose={false}
                    child={
                        <FromLibrary
                            createContentType={TYPE_CREATE_CONTENT.LIBRARY}
                            type={'resoures'}
                            title={translator('LIBRARY.ADD_FROM_LIBRARY')}
                            onCloseModal={() => setIsOpenModalLibrary(false)}
                            handleAddContentToSection={handleAddContentToSection}
                        />
                    }
                />
            )}
            {isOpenModalMoveFile && (
                <ModalMoveFile
                    isOpen={isOpenModalMoveFile}
                    onClose={() => setOpenModalMoveFile(false)}
                    data={resourceSelected}
                    isLastFile={dataList?.items?.length === 1 && folderSelected}
                    refreshData={() => {
                        if (dataList?.items?.length === 1 && folderSelected) {
                            setFolderSelected(undefined)
                            getFolderResourse(1)
                        }
                        else getResourceList({ page: 1 })
                    }}
                />
            )}
            {isOpenModalMoveFileToNewFolder && (
                <ModalMoveFileToNewFolder
                    isOpen={isOpenModalMoveFileToNewFolder}
                    onClose={() => setOpenModalMoveFileToNewFolder(false)}
                    data={resourceSelected}
                    isLastFile={dataList?.items?.length === 1 && folderSelected}
                    refreshData={() => {
                        if (dataList?.items?.length === 1 && folderSelected) {
                            setFolderSelected(undefined)
                        }
                        getResourceList({ page: 1 })
                        getFolderResourse(1)
                    }}
                />
            )}
            {isOpenModalRenameFolder && (
                <ModalRenameFolder
                    isOpen={isOpenModalRenameFolder}
                    onClose={() => setOpenModalRenameFolder(false)}
                    data={folderSelectedToRename}
                    refreshData={() => {
                        getFolderResourse(1);
                        setFolderSelectedToRename(undefined)
                    }}
                />
            )
            }
            <DocumentPreview
                isOpen={!!selectDocument}
                data={selectDocument}
                closeModal={() => setSelectDocument(null)}
                onDelete={() => {
                    setResourceSelected(selectDocument);
                    setHasDelete(true);
                }}
            />
            {loadingDownload ? <LoadingPopup message={translator('LIBRARY.DOWNLOADING')} loading={loadingDownload} /> : ''}
            {uploading ? <LoadingPopup message={translator('LIBRARY.UPLOADING')} loading={uploading} /> : ''}
        </div>
    );
};

export default ResourcesList;
