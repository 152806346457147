import React, { useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { InputField } from 'components/InputForm/InputForm';
import ButtonComponent from 'components/Button/ButtonComponent';
import ClassService from 'services/class.service';
import { FORM_CONST } from 'constant/form.const';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import WorkspaceMiddleware from 'middleware/workspace.middleware';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { SelectField } from 'components/Select';
import workspaceService from 'services/workspace.service';
import { Toastify } from '_shared';
import { checkPermission } from 'utils/commonFun';

const statusOptions = [
    {
        label: 'Active',
        value: 'active',
    },
    {
        label: 'Inactive',
        value: 'inactive',
    },
];

const TabProfile = ({ setData }) => {
    const params: any = useParams();
    const { t } = useTranslation();

    const { dispatch: workspaceDispatch } = useContext(GetWorkspaceContext);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isDirty },
        reset,
    } = useForm({
        defaultValues: {
            name: '',
            contact_email: '',
            address: '',
            phone: '',
            thumbnail: '',
            status: {
                label: 'Active',
                value: 'active',
            },
        },
    });

    const workspaceId = params.id;
    const companyId = params?.companyId;

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`COMPANY.COMPANY_DETAIL.${str}`, opt));

    const fetchCompany = () => {
        ClassService.getCompanyService(workspaceId, companyId)
            .then((res) => {
                reset({ ...res?.data, status: (res.data?.status && statusOptions?.find((el) => el?.value === res?.data?.status)) || {} });
                setData(res?.data)
                WorkspaceMiddleware.setCurrentRouteDetail(workspaceDispatch, [
                    {
                        name: res?.data?.name || '',
                        routeId: 'companyId',
                    },
                ]);
            })
            .catch()
            .finally();
    };

    useEffect(() => {
        fetchCompany();
        return () => {
            WorkspaceMiddleware.setCurrentRouteDetail(workspaceDispatch, []);
        };
    }, [companyId]);

    const apiEditCompany = (bodyParams: any) => {
        workspaceService.updateCompanyService(workspaceId, companyId, bodyParams)
            .then((res) => {
                Toastify.success();
                fetchCompany()
            })
            .catch((err) => {
                Toastify.error();
            })
            .finally();
    };

    const onSubmitForm = async (values: any) => {
        const payload = {
            ...values, status: values?.status?.value
        }
        delete payload?.updated_at;
        delete payload?.created_at;
        apiEditCompany(payload);
    };

    return (
        <form onSubmit={handleSubmit(onSubmitForm)} className="px-5">
            <div className="flex justify-between pr-2 py-5 ">
                <div />
                {checkPermission('companyDetailsProfile', 'U') && <ButtonComponent disable={!isDirty} type="submit" title={translator('SAVE', true)} classStyle="xs:p-2 xs:text-sm primary-button !bg-enterprise h-fit" />}

            </div>
            <div className=" grid grid-cols-2 gap-x-10 gap-y-5 max-w-4xl">
                <InputField
                    label={translator('COMPANY.COMPANY_NAME', true)}
                    name="name"
                    maxLength={255}
                    className="col-span-2"
                    isRequired
                    placeholder={translator('COMPANY.COMPANY_NAME', true)}
                    register={register('name', { required: translator('FORM_CONST.REQUIRED_FIELD', true) })}
                    errors={errors}
                    disabled={!checkPermission('companyDetailsProfile', 'U')}
                />
                <InputField
                    label={translator('COMPANY.EMAIL', true)}
                    name="contact_email"
                    maxLength={255}
                    className="col-span-1"
                    placeholder={translator('COMPANY.EMAIL', true)}
                    register={register('contact_email', {
                        pattern: {
                            value: FORM_CONST.EMAIL_REGEX,
                            message: FORM_CONST.EMAIL_VALIDATE,
                        },
                    })}
                    errors={errors}
                    disabled={!checkPermission('companyDetailsProfile', 'U')}
                />
                <InputField
                    label={translator('COMPANY.PHONE', true)}
                    name="phone"
                    maxLength={255}
                    className="col-span-1"
                    placeholder={translator('COMPANY.PHONE', true)}
                    register={register('phone', {
                        pattern: {
                            value: FORM_CONST.PHONE_NUMBER,
                            message: translator('FORM_CONST.VALIDATE_PHONE', true),
                        },
                    })}
                    errors={errors}
                    disabled={!checkPermission('companyDetailsProfile', 'U')}
                />
                <InputField
                    label={translator('COMPANY.ADDRESS', true)}
                    name="address"
                    maxLength={255}
                    className="col-span-2"
                    placeholder={translator('COMPANY.ADDRESS', true)}
                    register={register('address')}
                    errors={errors}
                    disabled={!checkPermission('companyDetailsProfile', 'U')}
                />
                <SelectField
                    control={control}
                    label={translator('COMPANY.STATUS', true)}
                    name="status"
                    className="col-span-1"
                    placeholder={translator('COMPANY.STATUS', true)}
                    isRequired
                    errors={errors}
                    options={statusOptions}
                    getOptionLabel={(option) => (
                        <span className={`px-3 py-1 rounded text-ooolab_dark_1 ${option?.value === 'active' ? 'bg-ooolab_alert_success_50' : 'bg-ooolab_warning'}`}>{option?.label}</span>
                    )}
                    isDisabled={!checkPermission('companyDetailsProfile', 'U')}
                />
            </div>
        </form>
    );
};

export default TabProfile;
