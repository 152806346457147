import { RestfulService } from './restful.service';
import { HOST_URL } from 'constant/api.const';

const createCourseTemplates = (payload) => {
    return RestfulService.postApi(HOST_URL + `/workspaces/${payload.workspaceId}/course-templates`, payload);
};
const createCourseASTemplates = (payload) => {
    return RestfulService.postApi(HOST_URL + `/workspaces/${payload.workspaceId}/courses/${payload.id}/create-template`, payload);
};
const getCourse = (payload) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${payload.workspaceId}/course-templates/${payload.id}`);
};
const getCourseSettings = (payload) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${payload.workspaceId}/course-templates/${payload.id}/settings`);
};
const createCourse = (payload) => {
    return RestfulService.postApi(HOST_URL + `/workspaces/${payload.workspaceId}/course-templates`, payload);
};
const updateCourse = (payload) => {
    return RestfulService.patchApi(HOST_URL + `/workspaces/${payload.workspaceId}/course-templates/${payload.id}`, payload);
};

const updateCourseSetting = async (payload) => {
    return RestfulService.putApi(HOST_URL + `/workspaces/${payload.workspaceId}/course-templates/${payload.id}/settings`, payload);
};

const createCourseSyllabus = async (payload) => {
    return RestfulService.postApi(HOST_URL + `/workspaces/${payload.workspaceId}/course-templates/${payload.id}/syllabuses`, payload);
};

const updateCourseSyllabusOrder = async (payload) => {
    return RestfulService.patchApi(HOST_URL + `/workspaces/${payload.workspaceId}/course-templates/${payload.id}/syllabuses/sort`, {syllabuses: payload?.syllabuses});
};

const deleteCourseSyllabus = async (payload) => {
    return RestfulService.deleteApi(HOST_URL + `/workspaces/${payload.workspaceId}/course-templates/${payload.id}/syllabuses/${payload.syllabus_id}`);
};

export default {
    createCourse,
    getCourse,
    getCourseSettings,
    updateCourse,
    updateCourseSetting,
    createCourseSyllabus,
    updateCourseSyllabusOrder,
    deleteCourseSyllabus,
    createCourseTemplates,
    createCourseASTemplates
};
