import { useMemo, useState } from 'react';
import { PlusIcon } from '@heroicons/react/outline';
import DialogComponent from 'components/Dialog/DialogComponent';
import FromLibrary from 'components/Library/LessonBuilder/components/Content/FromLibrary/FromLibrary';
import { TYPE_CREATE_CONTENT } from 'components/Library/LessonBuilder/constants';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from '_shared';
import "./CourseSyllabus.scss";
import { SyllabusList } from './components/SyllabusList/SyllabusList';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { courseFetch, createCourseSyllabus } from 'store/features/courseSlice';
import { useParams } from 'react-router-dom';
import { checkPermission } from 'utils/commonFun';

export const CourseSyllabus = () => {
    const dispatch = useAppDispatch();
    const { syllabuses } = useAppSelector(state => state.course.data);
    const params: { id: string, courseId: string } = useParams();
    const { t: translator } = useTranslation();

    const [isOpenModalLibrary, setIsOpenModalLibrary] = useState(false);

    const handleAddLearningPath = (learningPath) => {
        const lastLearningPath = [...syllabuses]?.pop();
        let createAPIS = learningPath?.map((item, idx) => (dispatch(createCourseSyllabus({
            workspaceId: params.id,
            id: params.courseId,
            learning_path_id: item?.id,
            order_number:  lastLearningPath ? lastLearningPath?.order_number + idx + 1 : idx + 1
        }))));
        Promise.all(createAPIS).finally(() => {
            dispatch(
                courseFetch({
                    workspaceId: params.id,
                    id: params.courseId,
                })
            );
        });
    }

    const listAdded = useMemo(() => {
        if (syllabuses) {
            return syllabuses?.map((item: any) => item?.learning_path_id);
        }
        return [];
    }, [syllabuses]);

    return (
        <div className="courseSyllabus">
            <div className="courseSyllabus-content">
                <div className="courseSyllabus-header">
                    <h2 className="courseSyllabus-name">{translator('COURSES.SYLLABUS')}</h2>
                    {checkPermission('course', 'U') && <ButtonPrimary type="button" onClick={() => setIsOpenModalLibrary(true)} className="ml-auto">
                        <span className="flex items-center text-sm"><PlusIcon className="w-4 h-4 mr-1.5" />{translator("COURSES.ADD_LEARNING_PATH")}</span>
                    </ButtonPrimary>}

                </div>
                <div className="flex py-5 min-h-[416px]">
                    {<SyllabusList hanldeAddLearningPath={() => setIsOpenModalLibrary(true)} />}
                </div>
            </div>
            <div></div>
            {isOpenModalLibrary && (
                <DialogComponent
                    isOpen={isOpenModalLibrary}
                    onCloseModal={() => setIsOpenModalLibrary(false)}
                    hasNonTitle
                    styles="max-w-[70vw] min-h-[480px] my-0 rounded-lg !p-0 pb-4"
                    isShowClose={false}
                    child={
                        <FromLibrary
                            createContentType={TYPE_CREATE_CONTENT.LEARNING_PATH}
                            type={TYPE_CREATE_CONTENT.LEARNING_PATH}
                            title={translator("COURSES.ADD_LEARNING_PATH")}
                            onCloseModal={() => setIsOpenModalLibrary(false)}
                            handleAddContentToSection={handleAddLearningPath}
                            hasLesson={true}
                            listAdded={listAdded}
                        />
                    }
                />
            )}
        </div>
    )
}