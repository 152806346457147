/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useMemo, useRef, useState } from 'react';
// PACKAGE
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

import cancel from 'assets/SVG/cancel_2.svg';
import confirm from 'assets/SVG/confirm.svg';
import deleteModal from 'assets/SVG/delete.svg';
import approve from 'assets/SVG/approve_img.svg';
import decline from 'assets/SVG/decline_img.svg';
import { ButtonIcon, ButtonOutline, ButtonPrimary } from '_shared';

export interface H5PModalProps {
    isModal: boolean;
    setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
    modalProps:
        | {
              component: {
                  type: string;
                  title: string;
                  subTitle: string;
                  btnCancel: string;
                  btnSubmit: string;
                  color: string;
                  img: string;
              };
              onFetch: () => Promise<void>;
          }
        | undefined;
}

const H5PModal: React.FC<H5PModalProps> = ({ isModal, setIsModal, modalProps }) => {
    const cancelButtonRef = useRef(null);
    const [loading, setLoading] = useState<boolean>(true);

    function onClose() {
        setIsModal(false);
    }

    function image() {
        switch (modalProps?.component.type) {
            case 'cancelNewH5P':
                return cancel;
            case 'publish':
                return confirm;
            case 'deleteH5P':
                return deleteModal;
            case 'editH5P':
                return cancel;
            case 'saveH5P':
                return confirm;
            case 'approveAdmin':
                return approve;
            case 'declineAdmin':
                return decline;

            default:
                break;
        }
    }

    function onFetchSubmit() {
        modalProps?.onFetch();
        setIsModal(false);
    }

    React.useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, [isModal]);

    return (
        <Transition.Root show={isModal} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-70 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={isModal}
                onClose={setIsModal}
            >
                <div className="flex i justify-center min-h-screen pt-4 px-4 pb-20 text-center items-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 dialog-background   bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <div className="inline-block align-bottom bg-white backgroud-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle w-[480px] relative p-4">
                            <div
                                ref={cancelButtonRef}
                                className="absolute w-ooolab_w_8 right-1 top-2 opacity-50 focus:outline-none"
                            >
                                <ButtonIcon onClick={onClose}>
                                    <XIcon className="text-gray-800 w-6 h-6" />
                                </ButtonIcon>
                            </div>
                            {modalProps && (
                                <>
                                    {/* <div className="w-full flex justify-center">
                                            <img
                                                className="w-ooolab_w_25 h-ooolab_h_25"
                                                src={image()}
                                                alt=""
                                            />
                                        </div> */}
                                    <div className="flex flex-col">
                                        <p className="text-gray-800 text-base font-semibold">{modalProps.component.title}</p>
                                        <p className="text-gray-800 text-sm font-normal mt-4">{modalProps.component.subTitle}</p>
                                    </div>
                                    <div className="flex w-full justify-center gap-4 mt-4 ">
                                        <ButtonOutline
                                            type="button"
                                            className="py-1 px-4 rounded border-ooolab_dark_1 border text-ooolab_sm text-ooolab_dark_1 bg-ooolab_gray_11 focus:outline-none"
                                            onClick={onClose}
                                        >
                                            {modalProps.component.btnCancel}
                                        </ButtonOutline>
                                        <ButtonPrimary
                                            type="button"
                                            className={`${
                                                modalProps.component.type === 'deleteH5P' ? 'buttonPrimary-danger' : ''
                                            } py-1 px-4 rounded  text-sm text-white focus:outline-none bg-primary-500`}
                                            onClick={onFetchSubmit}
                                        >
                                            {modalProps.component.btnSubmit}
                                        </ButtonPrimary>
                                    </div>
                                </>
                            )}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default H5PModal;
