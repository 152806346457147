import { ACADEMICS, LIBRARY } from 'constant/api.const';
import axios from './restful.service';

const getListLibrary = (workspaceId: string, params?: Record<any, any>, hasLearningPath?: boolean) => {
    if (workspaceId) {
        let url = LIBRARY.getLibary(workspaceId);
        if (hasLearningPath) {
            url = ACADEMICS.getLearningPaths(workspaceId);
            params = {
                page: params?.page,
                per_page: params?.per_page,
                order: params?.order,
                sort_by: params?.sort_by,
                created_by: params?.created_by,
                q: params?.q,
                status: params?.status
            }
        }
        return axios.get(url, { params });
    }
};

const createFolder = (workspaceId: string, data: any) => {
    if (workspaceId) {
        const url = LIBRARY.createFolder(workspaceId);
        return axios.post(url, data);
    }
};

const updateFile = (workspaceId: string, id: string, data: any) => {
    if (workspaceId) {
        const url = LIBRARY.updateFile(workspaceId, id);
        return axios.patch(url, data);
    }
};

const updateFolder = (workspaceId: string, id: string, data: any) => {
    if (workspaceId) {
        const url = LIBRARY.updateFolder(workspaceId, id);
        return axios.patch(url, data);
    }
};

const uploadFiles = (workspaceId: string, data: any) => {
    if (workspaceId) {
        const url = LIBRARY.uploadFiles(workspaceId);
        return axios.post(url, data);
    }
};

const getDetailsFile = (workspaceId: string, contentId: string, params?: Record<any, any>) => {
    if (workspaceId && contentId) {
        const url = LIBRARY.getDetailsFile(workspaceId, contentId);
        return axios.get(url, { params });
    }
};
const getDetailsFolder = (workspaceId: string, contentId: string, params?: Record<any, any>) => {
    if (workspaceId && contentId) {
        const url = LIBRARY.getDetailsFolder(workspaceId, contentId);
        return axios.get(url, { params });
    }
};
const deleteFile = (workspaceId: string, id: any) => {
    if (workspaceId) {
        const url = LIBRARY.deleteFile(workspaceId, id);
        return axios.delete(url);
    }
};
const renameLesson = (workspaceId: string, id: any, params?: Record<any, any>) => {
    if (workspaceId) {
        const url = LIBRARY.renameLesson(workspaceId, id);
        return axios.patch(url, params);
    }
};

const renameH5P = (workspaceId: string, id: any, params?: Record<any, any>) => {
    if (workspaceId) {
        const url = LIBRARY.renameH5P(workspaceId, id);
        return axios.patch(url, params);
    }
};

const moveToTrashLesson = async (workspaceId: string, id: string) => {
    if (workspaceId && id) {
        const url = LIBRARY.moveToTrashLesson(workspaceId, id);
        return axios.post(url);
    }
};

const moveToTrashH5P = async (workspaceId: string, id: string) => {
    if (workspaceId && id) {
        const url = LIBRARY.moveToTrashH5P(workspaceId, id);
        return axios.post(url);
    }
};

const moveToTrashFile = (workspaceId: string, id: any) => {
    if (workspaceId && id) {
        const url = LIBRARY.moveToTrashFile(workspaceId, id);
        return axios.post(url);
    }
};

const moveToTrashFolder = (workspaceId: string, id: any) => {
    if (workspaceId && id) {
        const url = LIBRARY.moveToTrashFolder(workspaceId, id);
        return axios.post(url);
    }
};
const duplicateFolder = (workspaceId: string, id: any) => {
    if (workspaceId && id) {
        const url = LIBRARY.duplicateFolder(workspaceId, id);
        return axios.post(url);
    }
};
const duplicateFile = (workspaceId: string, id: any) => {
    if (workspaceId && id) {
        const url = LIBRARY.duplicateFile(workspaceId, id);
        return axios.post(url);
    }
};
const duplicateLesson = (workspaceId: string, id: any) => {
    if (workspaceId && id) {
        const url = LIBRARY.duplicateLesson(workspaceId, id);
        return axios.post(url);
    }
};
const duplicateH5P = (workspaceId: string, id: any) => {
    if (workspaceId && id) {
        const url = LIBRARY.duplicateH5P(workspaceId, id);
        return axios.post(url);
    }
};
const recoverH5P = (workspaceId: string, id: any) => {
    if (workspaceId && id) {
        const url = LIBRARY.recoverH5P(workspaceId, id);
        return axios.post(url);
    }
};
const recoverLesson = (workspaceId: string, id: any) => {
    if (workspaceId && id) {
        const url = LIBRARY.recoverLesson(workspaceId, id);
        return axios.post(url);
    }
};
const recoverFile = (workspaceId: string, id: any) => {
    if (workspaceId && id) {
        const url = LIBRARY.recoverFile(workspaceId, id);
        return axios.post(url);
    }
};
const recoverFolder = (workspaceId: string, id: any) => {
    if (workspaceId && id) {
        const url = LIBRARY.recoverFolder(workspaceId, id);
        return axios.post(url);
    }
};
const deleteForeverFolder = (workspaceId: string, id: any) => {
    if (workspaceId && id) {
        const url = LIBRARY.deleteForeverFolder(workspaceId, id);
        return axios.delete(url);
    }
};
const deleteForeverFile = (workspaceId: string, id: any) => {
    if (workspaceId && id) {
        const url = LIBRARY.deleteForeverFile(workspaceId, id);
        return axios.delete(url);
    }
};
const deleteForeverH5P = (workspaceId: string, id: any) => {
    if (workspaceId && id) {
        const url = LIBRARY.deleteForeverH5P(workspaceId, id);
        return axios.delete(url);
    }
};
const deleteForeverLesson = (workspaceId: string, id: any) => {
    if (workspaceId && id) {
        const url = LIBRARY.deleteForeverLesson(workspaceId, id);
        return axios.delete(url);
    }
};

const moveH5p = (workspaceId: string, id: any, params: any) => {
    if (workspaceId && id) {
        const url = LIBRARY.moveH5p(workspaceId, id);
        return axios.post(url, params);
    }
};

const getlistFolder = (workspaceId: string, params: any) => {
    if (workspaceId) {
        const url = LIBRARY.getlistFolder(workspaceId);
        return axios.get(url, { params });
    }
};

const emptyTrash = (workspaceId: string) => {
    if (workspaceId) {
        const url = LIBRARY.emptyTrash(workspaceId);
        return axios.post(url);
    }
};
const duplicateAssignment = (workspaceId: string, id: any) => {
    if (workspaceId && id) {
        const url = LIBRARY.duplicateAssignment(workspaceId, id);
        return axios.post(url);
    }
};
const renameAssignment = (workspaceId: string, id: any, params?: Record<any, any>) => {
    if (workspaceId) {
        const url = LIBRARY.onAssignment(workspaceId, id);
        return axios.patch(url, params);
    }
};
const moveToTrashAssignment = async (workspaceId: string, id: string) => {
    if (workspaceId && id) {
        const url = LIBRARY.moveToTrashAssignment(workspaceId, id);
        return axios.post(url);
    }
};
const recoverAssignment = (workspaceId: string, id: any) => {
    if (workspaceId && id) {
        const url = LIBRARY.recoverAssignment(workspaceId, id);
        return axios.post(url);
    }
};

const moveToAssignment = (workspaceId: string, id: any, params: any) => {
    if (workspaceId && id) {
        const url = LIBRARY.onAssignment(workspaceId, id);
        return axios.patch(url, params);
    }
};
const getDetailsAssignment = (workspaceId: string, contentId: string, params?: Record<any, any>) => {
    if (workspaceId && contentId) {
        const url = LIBRARY.onAssignment(workspaceId, contentId);
        return axios.get(url, { params });
    }
};
const deleteForeverAssignment = (workspaceId: string, id: any) => {
    if (workspaceId && id) {
        const url = LIBRARY.onAssignment(workspaceId, id);
        return axios.delete(url);
    }
};

const libraryIsUsed = (workspaceId: string, id: any, type?: string) => {
    let url = `/h5p/workspaces/${workspaceId}/${id}/is-used`;
    if (type != 'h5p') {
        url = `/workspaces/${workspaceId}/${type}/${id}/is-used`;
    }
    if (workspaceId && id) {
        return axios.get(url);
    }
};

const setShareLink = (workspaceId, id, params) => {
    if (workspaceId && id) {
        const url = `/workspaces/${workspaceId}/library-contents/${id}/share`
        return axios.post(url, params);
    }
}

export default {
    getListLibrary,
    createFolder,
    uploadFiles,
    updateFile,
    updateFolder,
    deleteFile,
    getDetailsFile,
    getDetailsFolder,
    renameH5P,
    renameLesson,
    moveToTrashLesson,
    moveToTrashH5P,
    moveToTrashFile,
    moveToTrashFolder,
    duplicateFolder,
    duplicateFile,
    duplicateLesson,
    duplicateH5P,
    recoverH5P,
    recoverFile,
    recoverFolder,
    recoverLesson,
    deleteForeverFile,
    deleteForeverFolder,
    deleteForeverH5P,
    deleteForeverLesson,
    getlistFolder,
    moveH5p,
    emptyTrash,
    duplicateAssignment,
    renameAssignment,
    moveToTrashAssignment,
    recoverAssignment,
    moveToAssignment,
    getDetailsAssignment,
    deleteForeverAssignment,
    libraryIsUsed,
    setShareLink
};
