import { ButtonBack } from '_shared';
import EnrollmentInfomation from './EnrollmentInfomation';
import { setEnrollmentSelected } from "store/features/courseSlice";
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import Members from './Members/Member';
import './Detail.scss'
import { ENROLLMENT_TYPE } from '../../constants';
import { useEffect, useState } from 'react';
import { SessionList } from '../../../CourseScheduling/SessionList/SessionList';
import { GradebookDetails } from '../../../CourseGradebook/GradebookDetails/GradebookDetails';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Calendar from '../../../CourseScheduling/Calendar/Calendar';
import courseService from 'services/course.service';
import Credits from './Credits/Credits';

const LIST_TAB = (type, setting) => {
    return [
        {
            label: "Enrollment Information",
            value: "information",
            isHidden: false
        },
        {
            label: "Calendar",
            value: "calendar",
            isHidden: false
        },
        {
            label: "Session List",
            value: "session_list",
            isHidden: false
        },
        {
            label: "Progress & History",
            value: "gradebook",
            isHidden: false
        },
        {
            label: "Members",
            value: "members",
            isHidden: type === ENROLLMENT_TYPE.INDIVIDUAL
        },
        // {
        //     label: "Credits",
        //     value: "credits",
        //     isHidden: type === ENROLLMENT_TYPE.LEARNING_GROUP || setting?.enrollment_type !== 'credit'
        // }
    ]
}

export default function EnrollmentDetail() {
    const params: {id: string; courseId: string} = useParams();
    const history = useHistory();
    const search = useLocation()?.search;
    const enrollmentId = new URLSearchParams(search).get('enrollmentId');
    const view = new URLSearchParams(search).get('view');
    const dispatch = useAppDispatch();
    const { enrollmentSelected, setting } = useAppSelector((state) => state.course);

    const [tab, setTab] = useState('information');
    useEffect(() => {
        if (enrollmentId && tab === 'information') {
            courseService.getEnrollmentDetail(params?.id, params?.courseId, enrollmentId)
            .then(res => {
                dispatch(setEnrollmentSelected({ ...res?.data }));
            })
        }
    }, [tab, enrollmentId])

    useEffect(() => {
        setTab(view)
    }, [view])

    const getUrl = (tab) => {
        if (tab == 'gradebook') {
            if (enrollmentSelected?.type === ENROLLMENT_TYPE.LEARNING_GROUP) {
                return `&learnerGroupeId=${enrollmentSelected?.learning_group?.id}&view=gradebook&subtab=overview`
            }
            return `&learnerId=${enrollmentSelected?.learner?.id}&view=gradebook&subtab=assignment`
        }
        return `view=${tab}`;
    }

    return (
        <div className="EnrollmentDetail">
            <div className="BackBtn px-4">
                <ButtonBack hideLabel={true} onClick={() =>  history.push(`/workspace/${params.id}/management/courses/${params.courseId}?tab=people`)} />
                <div className="text-ellipsis truncate">{enrollmentSelected?.type === ENROLLMENT_TYPE.LEARNING_GROUP ? enrollmentSelected?.learning_group?.name : enrollmentSelected?.learner?.display_name}</div>
            </div>

            <div className="EnrollmentDetail-Tablist px-4">
                {LIST_TAB(enrollmentSelected?.type, setting)?.filter(tabItem => !tabItem?.isHidden).map((tabItem) => (
                    <div
                        className={`tab-default cursor-pointer ${tab === tabItem?.value ? 'active' : ''}`}
                        key={tabItem?.value}
                        onClick={() => {
                            setTab(tabItem?.value)
                            history.push(`/workspace/${params.id}/management/courses/${params.courseId}?tab=people&enrollmentId=${enrollmentId}&${getUrl(tabItem?.value)}`);
                        }}
                    >
                        {tabItem?.label}
                    </div>
                ))}
               
            </div>
            <div className='px-4'>
                    {
                        tab === 'information' && <EnrollmentInfomation />

                    }
                    {
                        tab === 'calendar' && <Calendar hasFilterLearner={false} learnerId={enrollmentSelected?.learning_group?.id || enrollmentSelected?.learner?.id} hasGroupLearner={!!enrollmentSelected?.learning_group?.id} />

                    }
                    {
                        tab === 'session_list' &&  <SessionList hasFilterLearner={false} learner_group_ids={enrollmentSelected?.learning_group?.id } learnerId={enrollmentSelected?.learner?.id} />

                    }
                    {
                        tab === 'gradebook' && <GradebookDetails enrollmentId={enrollmentId} hasUserName={false} />

                    }
                    {
                        tab === 'members' && <Members />

                    }
                    {/* {
                        tab === 'credits' && <Credits enrollmentSelected={enrollmentSelected} />

                    } */}
                </div>
        </div>
    );
}
