import { FC } from 'react';
import ReactDatePicker from 'react-datepicker';
import { CalendarIcon } from '@heroicons/react/outline';
import { classNames } from 'utils/commonFun';
import './react-datepicker.scss';
import { genClassNames } from 'utils/handleString';

type ReactDatePickerProps = {
    // props default
    inputProps?: any;
    isClearable?: boolean;
    showTimeSelect?: boolean;
    dateFormat?: string;
    timeFormat?: string;
    placeholder?: string;
    containerClassName?: string;
    onChange?: (date?: any) => void;
    maxDate?: Date;
    mindate?: Date,
    positionIcon?: string
};

type DatePickerProps = ReactDatePickerProps & {
    selected: Date;
};

type DateRangePickerProps = ReactDatePickerProps & {
    startDate: Date;
    endDate: Date;
    selectsRange: boolean;
};

/**
 * NOTE: FC<DatePickerProps> & FC<DateRangePickerProps>
 * use to check required props
 */
const DatePicker: FC<DatePickerProps> & FC<DateRangePickerProps> = ({
    inputProps,
    selected,
    startDate,
    endDate,
    selectsRange,
    isClearable,
    showTimeSelect,
    dateFormat = 'yyyy/MM/dd',
    timeFormat = 'HH:mm',
    placeholder,
    containerClassName,
    onChange,
    maxDate,
    mindate,
    positionIcon = 'left'
}) => {
    return (
        <div
            className={classNames(
                'react-datepicker__custom relative bg-white w-full border border-gray-300 rounded py-1.5 focus-within:border-blue-400',
                containerClassName
            )}
        >
            <ReactDatePicker
                selected={selected}
                startDate={startDate}
                endDate={endDate}
                selectsRange={selectsRange}
                isClearable={isClearable}
                showTimeSelect={showTimeSelect}
                dateFormat={dateFormat}
                timeFormat={timeFormat}
                placeholderText={placeholder}
                className="w-full pl-8 text-sm font-semibold text-gray-900"
                onChange={onChange}
                maxDate={maxDate}
                minDate={mindate}
                {...inputProps}
            />
            <CalendarIcon
                    className={genClassNames({
                        'w-4 h-4 text-gray-500 absolute left-2 top-2': true,
                        ['!right-2 !left-auto']: positionIcon === 'right'
                    })}
                />
        </div>
    );
};

export default DatePicker;
