import { FC, ReactNode } from 'react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import teamwork from 'assets/img/teamwork.png';
import { classNames } from 'utils/commonFun';
import Empty from 'assets/img/empty.png';

const NoContent: FC<{ content?: string; img?: any; icon?: ReactNode }> = ({ content = 'No Content', img = teamwork, icon }) => {
    return (
        <div className="flex flex-col justify-center items-center py-6">
            <div className="backdrop-grayscale bg-white/30">
                <img className="inline-block max-w-[200px] w-full" src={img} alt="teamwork" />
            </div>
            <p className="flex items-center space-x-2 mt-5 font-bold text-sm leading-5 text-ooolab_dark_2">
                {icon || <InformationCircleIcon className="w-5 h-5" />}
                {content}
            </p>
        </div>
    );
};

export default NoContent;

export const NoContentWithImage: FC<{ content?: string; img?: any; className?: string; styleImg?: string }> = ({
    content = 'No data',
    className,
    img = Empty,
    styleImg = '',
}) => {
    return (
        <div className={classNames('flex flex-col justify-center items-center py-6 h-full', className)}>
            <div className="">
                <img className={`inline-block max-w-[200px] w-full ${styleImg}`} src={img} alt="no data" />
            </div>
            <p className="mt-3 font-medium text-sm leading-6 text-ooolab_dark_2">{content}</p>
        </div>
    );
};
