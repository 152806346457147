import Table from 'components/Management/components/table';
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import icon_delete from 'assets/img/icon_delete.png';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import rewardService from 'services/reward.service';
import { useForm } from 'react-hook-form';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import dayjs from 'dayjs';
import { toast, ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

const RenderGroupsHeader = ({ value }: { value: string }) => (
    <div className="h-full pl-1 flex items-center">
        <p className=" text-sm text-ooolab_dark_1 font-semibold">{value}</p>
    </div>
);


const FormatColumnValue = ({ children, title }: {title?: string, children: any}) => (
    <p title={title} className="overflow-ellipsis whitespace-nowrap overflow-hidden pl-1 pr-2 py-ooolab_p_1 font-normal text-ooolab_dark_1 text-sm leading-8">
        {children}
    </p>
);

const RenderTableCell = ({
    value,
    dataFor,
}: {
    value: string;
    dataFor?: string;
}) => (
    <p
        className="pl-ooolab_p_1_e text-ooolab_xs text-ooolab_dark_1 leading-ooolab_24px font-normal text-left py-2 truncate"
        data-tip={value}
        data-for={dataFor}
    >
        {value}
    </p>
);

interface TableClassProps {
    data?: any[];
    onReload?: () =>  void

}

const TableRewardHistory: React.FC<TableClassProps> = ({ data, onReload }: {data: any, onReload: () => void}) => {
    const { t: translator } = useTranslation();
    const param: { id: string, rewardId: string } = useParams();
    const [modalRefund, setModalRefund] = useState(false);
    const [selectedReward, setSelectedReward] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [dataTable, setDataTable] = useState([]);

    const columns: Column<any>[] = React.useMemo(
        () => [
            {
                Header: () => (
                    <div className="flex items-center justify-between">
                        <RenderGroupsHeader value={translator('Student ID')} />
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                ),
                accessor: 'student.id',
                Cell: (d: any) => (
                    <div className="flex items-center text-xs justify-between h-full py-3 font-semibold">
                        <span className='pl-1'> {d.value}</span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                ),
                width: 120,
            },
            {
                Header: () => <RenderGroupsHeader value={translator('Student Name')} />,
                accessor: 'student.display_name',
            Cell: ({row}: {row: any}) => {
                    return <div className="flex items-center">
                    <p
                        data-tip={`${row?.original?.student?.display_name} ${row?.original?.student?.nick_name ? ` / ${row?.original?.student?.nick_name}` : ''}`}
                        data-for={
                            row?.values?.id + 'student_name'
                        }
                        className="my-3 p-1 overflow-ellipsis whitespace-nowrap overflow-hidden font-semibold text-xs text-ooolab_dark_1"
                    >
                        {row?.original?.student?.display_name}
                        {row?.original?.student?.nick_name && ` / ${row?.original?.student?.nick_name}`}
                    </p>
                    {/*@ts-ignore*/
                        <ReactTooltip
                        place="bottom"
                        type="dark"
                        effect="float"
                        id={row?.values?.id + 'student_name'}
                        getContent={(content) => (
                            <div className=" h-auto">{content}</div>
                        )}
                    />}
                </div>
                },
            },
            {
                Header: () => <RenderGroupsHeader value={translator('Redeemed by')} />,
                accessor: 'redeemed_user.display_name',
                Cell:  ({row}: {row: any}) => {
                    return <div className="flex items-center">
                    <p
                        data-tip={`${row?.original?.redeemed_user?.display_name} ${row?.original?.redeemed_user?.nick_name ? ` / ${row?.original?.redeemed_user?.nick_name}` : ''}`}
                        data-for={
                            row?.values?.id + 'redeemed_user'
                        }
                        className="my-3 p-1 overflow-ellipsis whitespace-nowrap overflow-hidden font-semibold text-xs text-ooolab_dark_1"
                    >
                        {row?.original?.redeemed_user?.display_name}
                        {row?.original?.redeemed_user?.nick_name && ` / ${row?.original?.redeemed_user?.nick_name}`}
                    </p>
                    {/*@ts-ignore*/
                        <ReactTooltip
                        place="bottom"
                        type="dark"
                        effect="float"
                        id={row?.values?.id + 'redeemed_user'}
                        getContent={(content) => (
                            <div className=" h-auto">{content}</div>
                        )}
                    />}
                </div>
                },
            },
            {
                Header: () => <RenderGroupsHeader value={translator('Coin Price')} />,
                accessor: 'amount',
                Cell:  (d: any) => {
                    return (
                        <FormatColumnValue>
                            <span className={`font-semibold text-xs`}>
                                {d.value + ' '} 
                                <span className='text-[10px]'> {translator("Coins")}</span>
                            </span>
                            </FormatColumnValue>
                    );
                },
                width: 100,
            },
            {
                Header: () => <RenderGroupsHeader value={translator('Update Date')} />,
                accessor: 'updated_at',
                Cell:  (d: any) => {
                    return (
                        <FormatColumnValue>
                             <span className='text-xs'>{dayjs(d?.value).format('MM/DD/YYYY')}</span>
                        </FormatColumnValue>
                    );
                },
            },
            {
                Header: () => <RenderGroupsHeader value={translator('Status')} />,
                accessor: 'status',
                Cell:  (d: any) => {
                    return (
                        <FormatColumnValue>
                            <span className={`${d.value == 'pending' ? "text-[#FA9847]": d.value == 'refund' ? "text-[#FF3329]" : "text-[#3AA757]"} text-first-uppercase font-semibold text-xs`}>{d.value}</span>
                        </FormatColumnValue>
                    );
                },
            },
            {
                Header: () => <RenderGroupsHeader value={translator('Reason')} />,
                accessor: 'refund_reason',
                Cell:  ({ row }) => {
                    return (
                        <>
                            <RenderTableCell
                                value={row.values?.refund_reason}
                                dataFor={row.values.id + 'refund_reason'}
                            />
                            {/*@ts-ignore*/
                            <ReactTooltip
                                place="bottom"
                                type="dark"
                                effect="float"
                                id={row.values.id + 'refund_reason'}
                                getContent={(content) => (
                                    <div className="w-72 h-auto">{content}</div>
                                )}
                                disable={row.values?.refund_reason?.length < 50}
                            />}
                        </>
                    );
                },
            },
            {
                Header: () => <RenderGroupsHeader value={''} />,
                accessor: 'id',
                Cell:  ({ row }: { row: Row }) => {
                    return (
                        <div className='flex h-full items-center'>
                            {
                                row.values.status == 'pending' &&
                                <>
                                    <button type='button' 
                                        onClick={()=> {
                                            claimReward(row.values.id);
                                        }}
                                        className='border border-primary-500 mr-3 py-1 px-2 text-white bg-primary-500 text-xs text-center rounded h-[32px]'>
                                        {selectedReward == row.values.id ? <CircleSpin color="text-[#ffffff]" className="w-[12px] h-[12px]" /> : 'Claim'}
                                    </button>
                                    <button type='button' 
                                        className='border border-[#FF3329] py-1 px-2 text-[#FF3329] text-xs rounded  h-[32px] text-center' 
                                        onClick={() => {
                                            setModalRefund(true);
                                            setValue('id', row.values.id, { shouldValidate: true });
                                        }}>
                                        {translator("Refund")}
                                    </button>
                                </>
                            }
                            {
                                row.values.status == 'claimed' &&
                                <>
                                    <button type='button' 
                                        className='border border-[#FF3329] py-1 px-2 text-[#FF3329] text-xs rounded  max-h-[32px]' 
                                        onClick={() => {
                                            setModalRefund(true);
                                            setValue('id', row.values.id, { shouldValidate: true });
                                        }}>
                                        {translator("Refund")}
                                    </button>
                                </>
                            }
                        </div>
                    );
                },
            }
        ],
        []
    );

    const onSubmit = handleSubmit(async (payload: any) => {
        setIsSubmit(true);
        const response = await rewardService.refundRewardClaimService({workspaceId: param.id, rewardId: param.rewardId, rewardClaimId: payload?.id}, payload);
        if(!response?.data?.error) {
            toast.success(translator('Successfully refunded!'));
            setValue('refund_reason', '');
            setModalRefund(false);
            setIsSubmit(false);
            onReload();
        } else {
            setIsSubmit(false);
        }        
    });    

    const claimReward = async (id: string) => {
        try {
            const payload = {
                workspace_id: param.id,
                reward_id: param.rewardId,
                reward_claim_id: id
            };
            setSelectedReward(id);
            const response = await rewardService.confirmRewardClaimService({workspaceId: param.id, rewardId: param.rewardId, rewardClaimId: id}, payload);
            if(!response?.data?.error) {
                toast.success(translator('Successfully claimed!'));
                const dataUpdate = data.map(item => {
                    if(item.id === id) {
                        return {
                            ...item,
                            status: 'claimed'
                        }
                    }
                    return item;
                });
                setDataTable(dataUpdate);
            }            
            setSelectedReward(null);
        } catch {}
    }

    useEffect(() => {
        setDataTable(data);
    }, [data])

    useEffect(() => {
        if(!modalRefund) {
            setValue('refund_reason', '');
        }
    }, [modalRefund]);

    return (<>
        <Table data={dataTable} columns={columns} noBorder={true}/>
        <Transition.Root show={modalRefund} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-70 inset-0 overflow-y-hidden"
                open={modalRefund}
                onClose={() => {
                    setModalRefund(false);
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 dialog-background  bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <div className="flex i justify-center min-h-screen p-4 pb-20 text-center items-center">
                    <div className="max-w-[320px] p-6 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle w-2/5 relative">
                        <div>
                            <div className="flex w-full items-center">
                                <button
                                    className="w-9 h-9 ml-auto text-ooolab_dark_300 hover:bg-ooolab_light_blue_0 hover:text-primary-500 rounded-full transition p-2  -mt-2.5"
                                    onClick={() => {
                                        setModalRefund(false);
                                    }}
                                >
                                    <XIcon fill="#2E3A59" className='text-ooolab_dark_300 w-[22px] h-[22px]' />
                                </button>
                            </div>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div>
                                <img src={icon_delete} alt="Refund Coins" className='w-[100px] h-[100px] mx-auto' />
                                <h2 className='text-ooolab_dark_300 text-sm font-medium my-3 text-center font-inter'>{translator("Refund Coins")}</h2>
                                <input type="text" hidden {...register("id")} />
                                <textarea className="text-ooolab_dark_1 text-xs rounded font-normal w-full border border-ooolab_bar_color leading-8 pl-2 focus:outline-none max-w-[272px] h-[44px]"
                                    maxLength={200}
                                    id="reward-description" 
                                    placeholder={translator("Enter reason")} name="reason"
                                    {...register("refund_reason")}></textarea>
                                <div className='flex space-x-4 mt-7'>
                                    <button type='button' className='w-1/2 border border-ooolab_dark_300 text-ooolab_sm text-ooolab_dark_300 px-ooolab_p_3 py-ooolab_p_1_e rounded-lg focus:outline-none'
                                    onClick={() => {setModalRefund(false);}}>{translator("No, cancel")}</button>
                                    <button type='submit' className='w-1/2 bg-red-500 text-white text-ooolab_sm px-ooolab_p_3 py-ooolab_p_1_e rounded-lg focus:outline-none'>
                                        {isSubmit ? <CircleSpin color="text-[#ffffff]" className='mx-auto' /> : translator('Yes, refund!')}
                                    </button>
                                </div>    
                            </div>
                        </form>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
        <ToastContainer />
    </>);
};

export default TableRewardHistory;
