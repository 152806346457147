import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipboardCheckIcon } from '@heroicons/react/outline';
import ActionsButton from 'components/ActionsButton';
import { EditLine } from 'assets/icon';
import DialogComponent from 'components/Dialog/DialogComponent';
import { ChangeDueDate } from './ChangeDueDate/ChangeDueDate';
import courseService from 'services/course.service';
import { useHistory, useParams } from 'react-router-dom';
import { Toastify } from '_shared';
import { USER_STATUS } from 'components/Management/User/constant';

interface ActionMenuProp {
    refreshData?: Function,
    data?: any;
    hideAction?: string,
    status?:string,
    userData: any
}

const ActionMenu: FC<ActionMenuProp> = ({ data, refreshData, status, userData }) => {
    const { t: translator } = useTranslation();
    const [isOpenModalEdit, setOpenModalEdit] = useState(false);
    const params: { id: string, userId: string } = useParams();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const handleUpdateDueDate = async (value) => {
        if (value) {
            try {
                setLoading(true);
                const response = await courseService.updateAssignment(params?.id, data?.courseId, {
                    id: data.id,
                    end_time: value
                });
                if (response?.data?.error) {
                    setLoading(false);
                    return Toastify.error();
                }
                setOpenModalEdit(false);
                setLoading(false);
                refreshData();
                Toastify.success('Update Grade Successful!');
            } catch {
                Toastify.error();
            }
        } else {
            setOpenModalEdit(false);
        }
    }
    return (
        <>
            <ActionsButton
                styleOptions="top-0 right-[35px]"
                menus={[
                    ...(status !== 'overdue' ? [
                    {
                        label: translator("COURSES.GRADE"),
                        icon: <ClipboardCheckIcon className='w-5 h-5' />,
                        action: () => window.open(`/workspace/${params.id}/management/users/${params.userId}/course/${data?.courseId}/assignment/${data.id}/grade`, '_blank'),
                        hide: false,
                        isDisabled: userData?.membership?.status === USER_STATUS.DEACTIVATE
                    }]: []),
                    {
                        label: translator("COURSES.EDIT_DUE_DATE"),
                        icon: <EditLine size={20} />,
                        action: () => setOpenModalEdit(true),
                        hide: false,
                        isDisabled: userData?.membership?.status === USER_STATUS.DEACTIVATE

                    }
                ]}
            />
            <DialogComponent
                title={translator("COURSES.CHANGE_DUE_DATE")}
                isOpen={isOpenModalEdit}
                onCloseModal={() => setOpenModalEdit(false)}
                isShowClose={true}
                styles="max-w-[400px] !py-1.5 !px-6"
                child={<ChangeDueDate data={data} onClose={handleUpdateDueDate} loading={loading} />}
            />
        </>
    );
};

export default ActionMenu;
