import { Control, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { CalendarIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { genClassNames } from 'utils/handleString';
import './date-picker.scss';

type DatePickerInputProps = {
    control?: Control<any>;
    name: string;
    placeholderText?: string;
    minDate?: Date;
    maxDate?: Date;
    minTime?: Date;
    maxTime?: Date;
    isRequired?: boolean;
    disabled?: boolean;
    paddingCls?: string;
    iconCls?: string;
    labelCls?: string;
    portal?: { id: string; className: string };
    onCalendarOpen?: () => void;
    onCalendarClose?: (value?: any) => void;
    isOriginal?: boolean;
    formatDate?: string;
    showTimeSelect?: boolean;
    classnames?: string;
    styles?: string;
    positionIcon?: string;
    handleOnChange?: (value: any) => void,
    filterTimeFunc?: any
    timeIntervals?: number
};

const DatePickerInput: React.FC<DatePickerInputProps> = ({
    control,
    name,
    placeholderText = null,
    minDate = null,
    maxDate = null,
    minTime = null,
    maxTime = null,
    isRequired,
    disabled = false,
    paddingCls,
    iconCls,
    portal,
    onCalendarOpen,
    onCalendarClose,
    isOriginal,
    labelCls,
    formatDate = 'yyyy-MM-dd',
    showTimeSelect,
    classnames,
    styles = '',
    positionIcon= 'left',
    handleOnChange,
    filterTimeFunc,
    timeIntervals = 30
}) => {
    const { t: translator } = useTranslation();

    const input = (
        // @ts-ignore
        <Controller
            rules={{
                required: {
                    value: isRequired,
                    message: translator('FORM_CONST.REQUIRED_FIELD'),
                },
            }}
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, isTouched, isDirty, error } }) => (
                <DatePicker
                    showTimeSelect={showTimeSelect}
                    onCalendarOpen={onCalendarOpen}
                    onCalendarClose={onCalendarClose}
                    portalId={portal?.id}
                    popperClassName={portal?.className}
                    disabled={disabled}
                    ref={() => ref}
                    minDate={minDate}
                    maxDate={maxDate}
                    minTime={minTime}
                    maxTime={maxTime}
                    placeholderText={placeholderText}
                    selected={value}
                    filterTime={filterTimeFunc}
                    onChange={(event) => {
                        onChange(event)
                        if (handleOnChange) {
                            handleOnChange(event);
                        }
                    }}
                    dateFormat={formatDate}
                    timeFormat='HH:mm'
                    timeIntervals={timeIntervals}
                    className={genClassNames({
                        'border pl-ooolab_p_7 h-full w-full border-ooolab_bar_color text-ooolab_dark_1 rounded font-normal':
                            isOriginal !== false,
                        [paddingCls || 'py-ooolab_p_2']: isOriginal !== false,
                        [labelCls || '']: true,
                        [classnames || '']: true,
                        ['!pl-2']: positionIcon === 'right'
                    })}
                    onKeyDown={(e) => {
                        e.preventDefault();
                    }}
                />
            )}
        />
    );

    if (isOriginal !== false) {
        return (
            <div className={`w-full h-ooolab_h_8 relative group text-ooolab_xs ${styles}`}>
                {input}
                <CalendarIcon
                    className={genClassNames({
                        'w-4 h-4 text-ooolab_dark_2 absolute left-ooolab_8px top-ooolab_8px': true,
                        [iconCls || '']: true,
                        ['!right-2 !left-auto']: positionIcon === 'right'
                    })}
                />
            </div>
        );
    }
    return input;
};

export default DatePickerInput;
