import { FC, useState, useEffect, useMemo, useContext } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RolesPermissionsList from 'components/Management/RolesPermissions/RolesPermissionsList/RolesPermissionsList';
import RolesPermissionDetails from 'components/Management/RolesPermissions/RolesPermissionDetails/RolesPermissionDetails';

interface RolePermissionsSettingProps { }

const RolePermissionsSetting: FC<RolePermissionsSettingProps> = ({ }) => {
    const params: any = useParams();
    const { t } = useTranslation();
    const history = useHistory();
    const search = useLocation()?.search;
    const tab = new URLSearchParams(search).get('tabPermission');
    const { location } = history;
    const { state }: { state: any } = location;


    return (
        <>
            <div className='bg-white rounded-lg'>
                {state?.roleId ? <RolesPermissionDetails breadcrumb={false} /> : <RolesPermissionsList children={<> </>} breadcrumb={false} />}

            </div>
        </>
    );
};

export default RolePermissionsSetting;
