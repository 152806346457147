import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EditIcon } from 'assets/icon/EditIcon';
import ActionsButton from 'components/ActionsButton';
import { EyeIcon, TrashIcon } from '@heroicons/react/outline';
import { DownloadIcon } from 'assets/icon';
import { checkPermission, dowloadFile, getFileExtensionFromUrl } from 'utils/commonFun';
import { DocumentPreview } from '_shared/components/DocumentPreview/DocumentPreview';
import ModalNewCertificate from './ModalNewCertificate';
import DialogComponent from 'components/Dialog/DialogComponent';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import courseService from 'services/course.service';

interface ActionMenuProp {
    refreshData: any;
    data: any;
    position?: string
}

const ActionMenu: FC<ActionMenuProp> = ({ refreshData, data, position }) => {
    const { t: translator } = useTranslation();
    const params: { id: string; courseId: string } = useParams();
    const [isOpenModalEditCertificate, setOpenModalEditCertificate] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [isOpenViewFile, setOpenViewFile] = useState(false)
    const [isOpenModalDelete, setOpenModalDelete] = useState(false);

    const download = () => {
        if (downloading) {
            return;
        }
        setDownloading(true);
        dowloadFile(data?.file, data?.name, () => {
            setDownloading(false);
        });
    }
    const onDelete = () => {
        courseService.deleteCertificateCourse(params?.id, params?.courseId, data?.learner?.id)
            .then(() => {
                Toastify.success()
                refreshData();
                setOpenModalDelete(false);
            })
            .catch(() => {
                Toastify.error()
            })
    }

    return (
        <>
            <ActionsButton
                styleOptions={`${position ? position : 'top-0'} right-[35px]`}
                menus={[
                    {
                        label: translator('VIEW'),
                        icon: <EyeIcon className='w-4 h-4' />,
                        action: () => setOpenViewFile(true),
                        hide: !checkPermission('course', 'R'),
                    },
                    {
                        label: translator('EDIT'),
                        icon: <EditIcon width='16' height='16' />,
                        action: () => setOpenModalEditCertificate(true),
                        class: '',
                        hide: !checkPermission('course', 'U'),
                    },
                    {
                        label: translator('DOWNLOAD'),
                        icon: <DownloadIcon />,
                        action: download,
                        class: '',
                        hide: !checkPermission('course', 'DL'),
                    },
                    {
                        label: translator('DELETE'),
                        icon: <TrashIcon width="18" height="16" />,
                        action: () => setOpenModalDelete(true),
                        hide: !checkPermission('course', 'D'),
                    },
                ]}
            />
            {isOpenViewFile &&
                <DocumentPreview
                    closeModal={() => setOpenViewFile(false)}
                    isOpen={isOpenViewFile}
                    data={{
                        type: getFileExtensionFromUrl(data?.file) === 'pdf' ? 'pdf' : 'image',
                        name: data?.name,
                        url: data?.file
                    }}
                />
            }
            {isOpenModalEditCertificate &&
                <ModalNewCertificate
                    isOpen={isOpenModalEditCertificate}
                    onClose={() => setOpenModalEditCertificate(false)}
                    refreshData={refreshData}
                    certiData={data}
                />
            }
            {isOpenModalDelete &&
                <DialogComponent
                    isOpen={isOpenModalDelete}
                    onCloseModal={() => setOpenModalDelete(false)}
                    title={translator('COURSES.DELETE_CERTIFICATE')}
                    child={
                        <div className='mt-4'>
                            <div>{translator('COURSES.DELETE_CERTIFICATE_CONTENT')}</div>
                            <div className='mt-5 flex justify-center gap-5'>
                                <ButtonOutline type="button" onClick={() => setOpenModalDelete(false)}>{translator('CANCEL')}</ButtonOutline>
                                <ButtonPrimary type="button" className='!border-red-500 !bg-red-500' onClick={onDelete}>{translator('DELETE')}</ButtonPrimary>
                            </div>
                        </div>
                    }
                />

            }
        </>
    );
};

export default ActionMenu;
