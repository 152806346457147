import { NoContentWithImage } from "components/NoContent/NoContent";
import { useTranslation } from "react-i18next";
import { TagItem } from "./TagItem";
import './TagList.scss';
import { CircleSpin } from "components/UiComponents/CircleSpin";

export const TagList = ({
    customFields,
    setCustomFields,
    isEdit = true,
    loading = false,
    hasTitle = true,
    className = '' }) => {
    const { t: translator } = useTranslation();
    return <>
        <div className={`taglist ${className ? className : ''}`}>
            {hasTitle ? <div className="taglistHeader">
                <div className="taglistHeader-label">{translator('LIBRARY.CUSTOM_TAGS')}</div>
                <div className="taglistHeader-line"></div>
            </div> : null}
            <div className="taglist-list custom-scrollbar">
                {
                    loading ?
                        <CircleSpin className="taglist-loading" /> :
                        <>
                            {customFields?.length > 0 ? (
                                customFields?.map((customField) => {
                                    return (
                                        <TagItem
                                            key={customField.id}
                                            title={customField.name}
                                            id={customField.id}
                                            data={customField?.data || []}
                                            setCustomFields={setCustomFields}
                                            customFields={customFields}
                                            isEdit={isEdit}
                                        />
                                    );
                                })
                            ) : (
                                <NoContentWithImage content={translator('NO_CUSTOM_TAGS')} />
                            )}
                        </>
                }
            </div>
        </div>
    </>
}