import { CourseOverview } from "./CourseOverview/CourseOverview";
import "./CourseGradebook.scss";
import { GradebookList } from "./GradebookList/GradebookList";
import { useLocation, useParams } from "react-router-dom";
import { GradebookDetails } from "./GradebookDetails/GradebookDetails";
import { useAppSelector } from "hooks/hooks";

export const CourseGradebook = () => {
    const search = useLocation()?.search;
    const learnerId = new URLSearchParams(search).get('learnerId');
    const learnerGroupeId = new URLSearchParams(search).get('learnerGroupeId');
    const enrollmentId = new URLSearchParams(search).get('enrollmentId');
    const params: {id: string, courseId: string} = useParams();

    const { setting } = useAppSelector((state) => state.course);
    
    return (<div className="courseGradebook">
        {learnerId || learnerGroupeId ? <GradebookDetails enrollmentId={enrollmentId} /> :
            <>
                <CourseOverview setting={setting}/>
                <GradebookList setting={setting}/>
            </>}
    </div>)
}