import React, { useState, useEffect } from 'react';
import { PlusIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TableCompany from './TableCompany';
import ButtonComponent from 'components/Button/ButtonComponent';
import { useDebouncedState } from 'hooks/useDebounce';
import ClassService from 'services/class.service';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import SearchBox from 'components/V2/SearchBox';
import Spinner from 'components/Spinner';
import DialogComponent from 'components/Dialog/DialogComponent';
import { useBoolean } from 'hooks/custom';
import ModalCreateCompany from './ModalCreateCompany';
import Pagination from 'components/V2/Pagination';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';

const index: React.FC = ({ children }) => {
    const params: any = useParams();
    const { t } = useTranslation();

    const { booleanValue: modalCreate, toggleBooleanValue: toggleModalCreate } = useBoolean();
    const [loading, setLoading] = useState(true);
    const [indexPage, setIndexPage] = useState('0-0');
    const [keyword, setKeyword] = useDebouncedState('');
    const [companyList, setCompanyList] = useState([]);
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'asc',
        sort_by: 'name',
        total: 0,
    });

    const workspaceId = params.id;

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`COMPANY.${str}`, opt));

    const fetchCompanyList = (page: number | string = 1) => {
        setLoading(true);
        ClassService.getCompanyListService(workspaceId, {
            name: keyword,
            ...pagination,
            page,
        })
            .then((res) => {
                const { items, page, order, sort_by, total } = res.data;
                setCompanyList(items);
                setPagination({ ...pagination, page, order, sort_by, total });
                setIndexPage(getIndexPage(page, pagination?.per_page, items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const handlePagination = (page: number) => {
        fetchCompanyList(page);
    };

    useEffect(() => {
        fetchCompanyList();
        return () => {
            setCompanyList([]);
        };
    }, [keyword]);

    return (
        <div className="w-full h-screen">
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT', true)}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    { name: translator('DASHBOARD.SIDEBAR.COMPANIES', true), href: ``, className: '' },
                ]}
            />
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 p-2 py-4 ">
                <SearchBox onSubmit={(e: any) => setKeyword(e)} />
                {checkPermission('company', 'C') &&
                    <div className="lg:flex lg:justify-end">
                        <ButtonComponent
                            title={translator('ADD_COMPANY')}
                            classStyle="xs:p-2 xs:text-sm primary-button !bg-enterprise"
                            onClickButton={toggleModalCreate}
                            icon={<PlusIcon className="w-4 h-4 mr-1" />}
                        />
                    </div>}

            </div>

            <div className="px-2">
                <div className="h-[calc(100vh-178px)] custom-scrollbar overflow-auto">
                    {loading ? (
                        <div className='h-[calc(100vh-178px)] flex justify-center items-center'><Spinner /></div>
                    ) : (
                        <TableCompany workspaceId={workspaceId} dataTable={companyList} translator={translator} />
                    )}
                </div>
                <Pagination
                    indexPage={indexPage}
                    total={pagination.total}
                    per_page={pagination.per_page}
                    page={pagination.page}
                    callback={handlePagination}
                />
            </div>
            <DialogComponent
                isOpen={modalCreate}
                title={translator('ADD_COMPANY')}
                onCloseModal={toggleModalCreate}
                maskClosable={false}
                stylesTitle="text-base"
                child={
                    <ModalCreateCompany
                        translator={translator}
                        fetchData={() => {
                            fetchCompanyList(pagination.page);
                            toggleModalCreate();
                        }}
                    />
                }
            />
        </div>
    );
};

export default index;
