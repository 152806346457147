import React from "react";
import "./UserAvatarInfo.scss";

export const UserAvatarInfo = ({ userInformation }) => {
    return (
        <div className="userAvatarInfo">
            <div className="userAvatarInfo-img"
                style={{ backgroundImage: `url(${userInformation?.avatar_url})` }}></div>
            <div className="userAvatarInfo-name">
                {userInformation?.display_name}
            </div>
        </div>
    )
}