import * as React from 'react';
import { genClassNames } from 'utils/handleString';

interface TooltipInfoProps {
    info: any;
    children: React.ReactNode;
    styles?: string;
    stylePosition?: string;
}

const TooltipInfo: React.FC<TooltipInfoProps> = ({ info, children, styles, stylePosition }: TooltipInfoProps) => (
    <div className="has-tooltip flex items-center">
        <span
            className={genClassNames({
                'whitespace-nowrap tooltip  px-3 py-1 bg-white shadow-xl rounded-xl  top-8 ': true,
                [stylePosition || 'right-0']: true,
                [styles || '']: true,
            })}
        >
            {info}
        </span>
        {children}
    </div>
);

export default TooltipInfo;
