import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { defaultPagination } from 'constant/util.const';
import libraryService from 'services/library.service';
import { XIcon } from '@heroicons/react/solid';
import { LibraryTypeEnum } from 'components/Library/LibraryContants';
import { ButtonIcon, ButtonPrimary } from '_shared';
import Filter from './Filter';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import Table from './Table';
import './FromLibrary.scss'
import { TYPE_CREATE_CONTENT, TYPE_LESSON_BUILDER } from 'components/Library/LessonBuilder/constants';
import { acceptContentType } from 'constant/form.const';

const FromLibrary = ({ onCloseModal, title, 
    createContentType, 
    handleAddContentToSection, 
    type=undefined, 
    hasLesson=false, 
    listAdded = [],
    acceptsContentType = acceptContentType,
    queryParams = {},
    isShowContentType = false,
}) => {
    const params: any = useParams();
    const { t } = useTranslation();
    const [pagination, setPagination] = useState<any>({ ...defaultPagination, per_page: 10 });
    const [dataTable, setDateTable] = useState<any>([]);
    const [loadingTable, setLoadingTable] = useState<boolean>(false);
    const [fileSelected, setFileSelected] = useState<any>([]);
    const [path, setPath] = useState<any>([]);
    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`LIBRARY.${str}`, opt));
    const [isFirstLoad, setFirstLoad] = useState(true);
    const [folderSelected, setFolderSelected] = useState<any>();
    const [filter, setFilter] = useState<any>()

    const objectType = () => {
        if (type === TYPE_LESSON_BUILDER.assignments) return 'assignment,folder'
        if (createContentType === TYPE_CREATE_CONTENT.H5P_LIBRARY) return 'h5p,folder'
        if (createContentType === TYPE_CREATE_CONTENT.LESSON) return 'lesson,folder'
        return acceptsContentType
    }

    const getList = (page: number = 1, order?: string) => {
        setLoadingTable(true);
        
        const payload = {
            ...pagination,
            object_types:  objectType(),
            ...filter,
            page: page || pagination.page,
            order: order || pagination?.order,
            parent_id: folderSelected?.id || null,
            ...queryParams,
        };
        if ((type === TYPE_LESSON_BUILDER.assignments || type === TYPE_CREATE_CONTENT.LESSON) || type === TYPE_CREATE_CONTENT.LEARNING_PATH) {
            payload.status = 'published';
        }
        
        delete payload?.total;
        delete payload?.filter;
        !payload.parent_id && !pagination.parent_id && delete payload?.parent_id;
        libraryService
            .getListLibrary(params.id, payload, type === TYPE_CREATE_CONTENT.LEARNING_PATH)
            .then((res) => {
                setFirstLoad(false);
                if (page === 1) setDateTable(res?.data?.items);
                else setDateTable([...dataTable, ...res?.data?.items]);
                setPagination({
                    ...payload,
                    page: page || res?.data?.page,
                    per_page: res.data?.per_page,
                    order: order || res?.data?.order,
                    total: res?.data?.total,
                    sort_by: res?.data?.sort_by,
                });
            })
            .finally(() => setLoadingTable(false));
    };

    useEffect(() => {
        getList(1);
    }, [filter, folderSelected]);

    const handleSort = () => {
        getList(1, pagination?.order === 'desc' ? 'asc' : 'desc');
    };

    let timer;

    const handleClick = (item: any, event: any) => {
        event.stopPropagation();
        clearTimeout(timer);
        setFirstLoad(undefined)
        if (event.detail === 1 && item?.object_type !== LibraryTypeEnum.folder) {
            timer = setTimeout(() => {
                const isShiftClick = event?.shiftKey;
                let files = [...fileSelected]
                if (isShiftClick ) {
                    if (files?.find(file => item?.id === file?.id)) {
                        files = fileSelected?.filter(file => item?.id !== file?.id)
                    }
                    else files = [...fileSelected, item];
                } 
                if (!isShiftClick) {
                    files = [item];
                }
                setFileSelected(files);
            }, 250);
        }
    };

    const onDoubleClickRow = (item) => {
        if (item?.object_type === LibraryTypeEnum.folder) {
            setFileSelected([]);
            setFolderSelected(item)
            libraryService.getDetailsFolder(params?.id, item?.id).then((res) => {
                setTimeout(() => {
                    setPath([...path, { ...res.data, name: item.name }]);
                }, 300);
            });
            setDateTable([])
        }
    };

    const onChangeFilter = (filter) => {
        return setFilter(filter)
    };

    const onAddContent = () => {
        handleAddContentToSection(fileSelected);
        onCloseModal();
    };

    return (
        <div className='from-library'>
            <div className="header">
                <div className="header-title">{title}</div>
                <ButtonIcon onClick={onCloseModal} >
                    <XIcon className="header-icon" />
                </ButtonIcon>
            </div>

            {type !== TYPE_CREATE_CONTENT.LEARNING_PATH ? <div className="breadcrumb">
                <PageBreadcrumb
                    onClickPageNameDefault={() => {
                        setPath([]);
                        setFolderSelected(undefined)
                    }}
                    pageNameDefault={translator('DASHBOARD.SIDEBAR.INTERNAL_LIBRARY', true)}
                    pages={path?.map((item, index) => {
                        return {
                            name: item?.name,
                            onClick: () => {
                                if (item?.object_type !== 'folder') return;
                                setFolderSelected(item)
                                setPath(path?.slice(0, index + 1));
                            },
                            className: '',
                        };
                    })}
                    containerClassName="breadcrumb-container"
                />
            </div> : null}

            <div className="filter">
                <Filter 
                    type={type} 
                    acceptContentType={objectType()} 
                    createContentType={createContentType} 
                    isFirstLoad={isFirstLoad} 
                    folderId={path?.slice(-1)?.[0]?.id} 
                    onChangeFilter={onChangeFilter} 
                    hasLesson={hasLesson}
                />
            </div>
            <Table 
                translator={translator}
                dataTable={dataTable}
                pagination={pagination}
                handleSort={handleSort}
                getList={getList}
                loadingTable={loadingTable}
                fileSelected={fileSelected}
                onDoubleClickRow={onDoubleClickRow}
                handleClick={handleClick}
                createContentType={createContentType} 
                typeCreateLesson={type}
                hasLesson={hasLesson}
                hasLearningPath={type === TYPE_CREATE_CONTENT.LEARNING_PATH}
                listAdded={listAdded}
                isShowContentType={isShowContentType}
            />

            <div className="footer">
                <div className="fileSelected">{fileSelected?.length || 0} {type === TYPE_CREATE_CONTENT.LEARNING_PATH ? translator('LEARNING_PATH_S_SELECTED') :translator('FILE_SELECTED')}</div>
                <ButtonPrimary disabled={fileSelected?.length === 0} type={'button'} onClick={onAddContent}>
                    {translator('ADD')}
                </ButtonPrimary>
            </div>
        </div>
    );
};

export default FromLibrary;
