import { FC, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ActionsButton from 'components/ActionsButton';
import { TrashIcon } from '@heroicons/react/outline';
import { checkPermission } from 'utils/commonFun';
import ModalCancelnvoice from '../components/ModalCancelnvoice';
import { EditIcon } from 'assets/icon';
import SendIcon from 'assets/icon/SendIcon';
import CreateReceiptIcon from 'assets/icon/CreateReceiptIcon';
import RefundIcon from 'assets/icon/RefundIcon';
import ViewFileIcon from 'assets/icon/ViewFileIcon';
import DetailIcon from 'assets/icon/DetailIcon';
import AddNoteIcon from 'assets/icon/AddNoteIcon';
import workspaceService from 'services/workspace.service';
import { Toastify } from '_shared';
import DialogComponent from 'components/Dialog/DialogComponent';
import CreateSingleInvoice from '../CreateInvoice/CreateSingleInvoice';
import ModalAddNote from '../components/ModalAddNote';
import { DocumentPreview } from '_shared/components/DocumentPreview/DocumentPreview';
import { HANDLE_ACTION_ENUM, INVOICE_ACTION_BY_STATUS, INVOICE_TYPE } from '../../constants';
import ReceiptIcon from 'assets/icon/ReceiptIcon';

interface ActionMenuProp {
    data: any;
    refreshData: Function;
    position?: string
}

const ActionMenu: FC<ActionMenuProp> = ({ data, refreshData, position }) => {
    const params: { id } = useParams();
    const { t: translator } = useTranslation();
    const history = useHistory();
    const [isOpenModalCancelInvoice, setOpenModalCancelInvoice] = useState(false);
    const [isOpenModalUpdateInvoice, setOpenModalIpdateInvoice] = useState(false);
    const [isOpenModalAddNote, setOpenModalAddNote] = useState(false)
    const [fileData, setFileData] = useState(undefined);
    const [isOpenModalViewFile, setIsOpenModalViewFile] = useState(false)
    const handleActionInvoice = (action) => {
        const invoice = {
            invoice_id: data?.id,
            invoice_type: INVOICE_TYPE.single,
            action
        }
        workspaceService.actionInvoice(params?.id, invoice)
        .then(() => {
            let message = ''
            if (action === HANDLE_ACTION_ENUM.cancel) message = 'CANCEL_INVOICE_SUCCESS';
            if (action === HANDLE_ACTION_ENUM.send_invoice) message = 'SEND_INVOICE_SUCCESS';
            if (action === HANDLE_ACTION_ENUM.resend_invoice) message = 'RESEND_INVOICE_SUCCESS';
            if (action === HANDLE_ACTION_ENUM.create_receipt) message = 'CREATE_RECEIPT_INVOICE_SUCCESS';
            if (action === HANDLE_ACTION_ENUM.refund) message = 'REFUND_INVOICE_SUCCESS';
            Toastify.success(translator(message));
        })
        .catch(() => Toastify.error())
    }

    return (
        <>
            <ActionsButton
                styleOptions={`${position ? position : 'top-0'} right-[35px]`}
                menus={[
                    {
                        label: translator('DETAIL'),
                        icon: <DetailIcon />,
                        action: () => history?.push(`/workspace/${params?.id}/e-commerce/billing/${data?.id}?type=single`),
                        isDisabled: (!checkPermission('invoice', 'R')),
                    },
                    {
                        label: translator('EDIT'),
                        icon: <EditIcon />,
                        action: () => setOpenModalIpdateInvoice(true),
                        hide: !INVOICE_ACTION_BY_STATUS.edit.includes(data?.display_status),
                        isDisabled: (!checkPermission('invoice', 'U')),
                    },
                    {
                        label: translator('RESEND_INVOICE'),
                        icon: <SendIcon />,
                        action: () => handleActionInvoice(HANDLE_ACTION_ENUM.resend_invoice),
                        hide: !INVOICE_ACTION_BY_STATUS.resent_invoice.includes(data?.display_status),
                        isDisabled: (!checkPermission('invoice', 'U')),
                    },
                    {
                        label: translator('SEND_INVOICE'),
                        icon: <SendIcon />,
                        action: () => handleActionInvoice(HANDLE_ACTION_ENUM.send_invoice),
                        hide: !INVOICE_ACTION_BY_STATUS.sent_invoice.includes(data?.display_status),
                        isDisabled: (!checkPermission('invoice', 'U')),
                    },
                    {
                        label: translator('CREATE_RECEIPT'),
                        icon: <CreateReceiptIcon />,
                        action: () => handleActionInvoice(HANDLE_ACTION_ENUM.create_receipt),
                        hide: !INVOICE_ACTION_BY_STATUS.create_receipt.includes(data?.display_status),
                        isDisabled: (!checkPermission('invoice', 'U')),
                    },
                    {
                        label: translator('REFUND'),
                        icon: <RefundIcon />,
                        action: () => handleActionInvoice(HANDLE_ACTION_ENUM.refund),
                        hide: !INVOICE_ACTION_BY_STATUS.refund.includes(data?.display_status),
                        isDisabled: (!checkPermission('invoice', 'U')),
                    },
                    {
                        label: translator('VIEW_INVOICE'),
                        icon: <ViewFileIcon />,
                        action: () => {
                            const file = data?.generated_files?.filter((file) => file?.file_type === 'manual_invoice')?.[0]
                            setFileData({
                                url: file?.file_url,
                                name: file?.file_name,
                                type: 'application/pdf'
                            })
                            setIsOpenModalViewFile(true);
                        },
                        class: "border-t border-gray-300",
                        isDisabled: (!data?.generated_files || data?.generated_files?.filter((file) => file?.file_type === 'manual_invoice')?.length === 0 || !checkPermission('invoice', 'R')),
                        hide: !INVOICE_ACTION_BY_STATUS.view_invoice.includes(data?.display_status)
                    },
                    {
                        label: translator('VIEW_RECEIPT'),
                        icon: <ReceiptIcon />,
                        action: () => {
                            const file = data?.generated_files?.filter((file) => file?.file_type === 'manual_receipt' || file?.file_type === 'purchase_receipt')?.[0]
                            setFileData({
                                url: file?.file_url,
                                name: file?.file_name,
                                type: 'application/pdf'
                            })
                            setIsOpenModalViewFile(true);
                        },
                        isDisabled: ( !data?.generated_files || data?.generated_files?.filter((file) => file?.file_type === 'manual_receipt' || file?.file_type === 'purchase_receipt')?.length === 0 || !checkPermission('invoice', 'R')),
                        hide: !INVOICE_ACTION_BY_STATUS.view_receipt.includes(data?.display_status)
                    },
                    // {
                    //     label: translator('View Refund'),
                    //     icon: <ViewRefundIcon />,
                    //     action: () => {
                    //         setFileData({
                    //             url: data?.refund_file,
                    //             name: 'Refund Receipt.pdf',
                    //             type: 'application/pdf'
                    //         })
                    //         setIsOpenModalViewFile(true);
                    //     },
                    //     isDisabled: (!data?.invoice_file || !checkPermission('invoice', 'R')),
                    //     hide: !INVOICE_ACTION_BY_STATUS.view_refund.includes(data?.display_status)
                    // },
                    {
                        label: translator('ADD_DESCRIPTION'),
                        icon: <AddNoteIcon />,
                        action: () => setOpenModalAddNote(true),
                        isDisabled: (!checkPermission('invoice', 'U')),
                        hide: !INVOICE_ACTION_BY_STATUS.add_note.includes(data?.display_status) || data?.note
                    },
                    {
                        label: translator('CANCEL'),
                        icon: <TrashIcon width="18" height="16" className="text-gray-900" />,
                        action: () => setOpenModalCancelInvoice(true),
                        class: "border-t border-gray-300",
                        hide: !INVOICE_ACTION_BY_STATUS.cancel.includes(data?.display_status),
                        isDisabled: !checkPermission('invoice', 'D')
                    },
                ]}
            />
            <ModalCancelnvoice
                isOpen={isOpenModalCancelInvoice}
                onClose={() => setOpenModalCancelInvoice(false)}
                refreshData={refreshData} 
                invoice={{invoice_id: data?.id, invoice_type: INVOICE_TYPE.single, action: HANDLE_ACTION_ENUM.cancel}}           
            />

            <DialogComponent 
                isOpen={isOpenModalUpdateInvoice}
                onCloseModal={() => setOpenModalIpdateInvoice(false)}
                title={translator("EDIT_SINGLE_INVOICE")}
                child={
                    <CreateSingleInvoice
                        invoiceId={data?.id} 
                        onClose={() => setOpenModalIpdateInvoice(false)} 
                        onRefreshData={refreshData}                     
                    />
                }
            />

            <DialogComponent
                title={translator('DESCRIPTION')}
                onCloseModal={() => setOpenModalAddNote(false)}
                isOpen={isOpenModalAddNote}
                child={
                    <ModalAddNote refreshData={refreshData} invoiceId={data?.id} note={data?.note} onClose={() => setOpenModalAddNote(false)} />
                }
            />

            <DocumentPreview data={fileData} isOpen={isOpenModalViewFile} closeModal={() => setIsOpenModalViewFile(false)} />
        </>
    );
};

export default ActionMenu;
