import { FC } from 'react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { classNames } from 'utils/commonFun';

type PagesProps = {
    name: string;
    href: string;
    className?: string;
    onClick?: any;
};

type SubBreadcrumbProps = {
    containerClassName?: string;
    pageClassName?: string;
    pageNameDefault?: string;
    pages: PagesProps[];
    onClickPageNameDefault?: any;
};

export const SubBreadcrumb: FC<SubBreadcrumbProps> = ({
    containerClassName,
    pageClassName,
    pageNameDefault,
    pages,
    onClickPageNameDefault
}) => {

    return (
        <div
            className={classNames('bg-white flex justify-between items-center', containerClassName)}
        >
            <div className={classNames('w-full flex items-center text-base font-normal text-gray-800')}>
                {pageNameDefault && (
                    <h1
                        onClick={() => onClickPageNameDefault?.()}
                        className={classNames('text-gray-500 hover:text-gray-700 min-w-fit mr-1 cursor-pointer', pageClassName)}
                    >
                        {pageNameDefault}
                    </h1>
                )}
                {pages?.length > 3 ? (
                    <>
                        <ChevronRightIcon className='w-3.5 h-3.5 text-gray-400 flex-none' />{' '}
                        {!pages[0]?.href ? (
                            <div onClick={() => pages[0]?.onClick?.()}>{pages[0]?.name}</div>
                        ) : (
                            <Link to={pages[0]?.href} className={''} key={0}>
                                {pages[0]?.name}
                            </Link>
                        )}
                        <ChevronRightIcon className='w-3.5 h-3.5 text-gray-400 flex-none' />
                        ...
                        {pages?.slice(pages?.length - 2)?.map((item, index) => {
                            return (
                                <div className='flex items-center' key={index}>
                                    <ChevronRightIcon className='w-3.5 h-3.5 text-gray-400 flex-none' />{' '}
                                    {!item?.href ? (
                                        <div onClick={() => item?.onClick?.()} className={`ml-1`}>{item?.name}</div>
                                    ) : (
                                        <Link to={item?.href} className={''} key={index}>
                                            {item?.name}
                                        </Link>
                                    )}
                                </div>
                            );
                        })}
                    </>
                ) : (
                    pages?.map((page, index) => {
                        return (
                            <div className='flex items-center truncate' key={index}>
                                <ChevronRightIcon className='w-3.5 h-3.5 text-gray-400 flex-none' />
                                {page?.href ? (
                                    <Link
                                        to={page?.href}
                                        className={classNames(
                                            index === pages?.length - 1 ? 'font-semibold min-w-fit' : 'min-w-fit ml-1.5 text-gray-500',
                                            page?.className,
                                        )}
                                    >
                                        {page?.name}
                                    </Link>
                                ) : (
                                    <div
                                        className={classNames(
                                            index === pages?.length - 1
                                                ? 'font-semibold text-ellipsis truncate ml-1.5'
                                                : 'text-ellipsis truncate cursor-pointer',
                                            page?.className,
                                        )}
                                        onClick={() => page?.onClick?.()}
                                    >
                                        {page?.name}
                                    </div>
                                )}
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
};
