import {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    PlusSmIcon
} from '@heroicons/react/outline';

//Component
import ViewGrid from './components/ViewGrid/';
import ViewTable from './components/ViewTable';

import { useTranslation } from 'react-i18next';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { ButtonPrimary } from '_shared';
import Filter from './components/FIlter/Filter';
import { WorkspaceService } from 'services';
import NO_CONTENT from 'assets/img/empty-state/course.png'
import './CourseList.scss'
import { checkPermission, getIndexPage } from 'utils/commonFun';
import { Popover } from '@headlessui/react';
import { LayoutIcon } from 'assets/icon/LayoutIcon';
import { ClipboardIcon } from 'assets/icon/ClipboardIcon';
import DialogComponent from 'components/Dialog/DialogComponent';
import ModalTemplate from './components/ModalTemplate/ModalTemplate';
import debounce from 'lodash/debounce';
import Pagination from 'components/V2/Pagination';

const CoursesList: React.FC = ({ children }) => {
    const { t: translator } = useTranslation();
    const params: { id: string } = useParams();
    const history = useHistory();

    const [isGrid, setIsGrid] = useState(false);
    const [loading, setLoading] = useState(true);
    const [courses, setCourses] = useState([]);
    const [indexPage, setIndexPage] = useState<string>('1-50');
    const [isModalTemplate, setIsModalTemplate] = useState<boolean>(false);
    const [filter, setFilter] = useState(undefined)
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'updated_at',
        total: 0
    });

    const workspaceId = params.id;

    const getCourses = (page: number | string = 1, order = 'desc', filter = undefined) => {
        setLoading(true);
        const payload = {
            ...pagination,
            ...filter,
            order,
            page,
        }
        delete payload?.total;
        WorkspaceService.getCoursesList(workspaceId, payload)
            .then((res) => {
                const { items, page, total } = res;
                setCourses(items);
                setPagination({ ...pagination, total, page });
                setIndexPage(getIndexPage(page, pagination?.per_page, items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const handlePagination = (page: number) => {
        getCourses(page, pagination?.order, filter);
    };

    const getCoursesDebounce = useCallback(
        debounce((page, order?: string, filter?: any) => getCourses(page, order, filter), 500),
        []
      );
    

    useEffect(() => {
        getCoursesDebounce(1, pagination?.order, filter);
    }, [filter]);

    const onChangeFilter = (filter) => {
        setFilter(filter);
    }

    const newCourse = async () => {
        return history.push(`/workspace/${params.id}/management/courses/new`);
    };

    return (
        <div className="CoursesList">
            <PageBreadcrumb
                pageNameDefault={translator(
                    'DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT'
                )}
                pages={[
                    { name: translator('COURSES.COURSES'), href: ``, className: '' },
                ]}
                containerClassName=" z-10"
            />
            <div className="CourseList-filter">
                {checkPermission('course', 'R') ? <Filter onChangeFilter={onChangeFilter} /> : ''}
                {checkPermission('course', 'C') && <Popover className="relative">
                    <Popover.Button>
                        <ButtonPrimary type="button" onClick={() => { }} className=' outline-none'>
                            <span className='flex'>
                                <PlusSmIcon
                                    className="PlusIcon"
                                    aria-hidden="true"
                                />
                                <span> {translator('COURSES.NEW')}</span>
                            </span>
                        </ButtonPrimary>
                    </Popover.Button>
                    <Popover.Panel className="absolute z-10 w-52 right-0 bg-white rounded-lg shadow-sm mt-3">
                        <div className='px-4 text-sm w-full cursor-pointer'>
                            <div onClick={newCourse} className="flex gap-1 py-2 items-center"><ClipboardIcon /> {translator('COURSES.BLANK_COURSE')}</div>
                            <div onClick={() => { setIsModalTemplate(true) }} className="flex gap-1 py-2 items-center"><LayoutIcon />{translator('COURSES.SELECT_A_TEMPLATE')}</div>
                        </div>
                    </Popover.Panel>
                </Popover>}

            </div>

            <div className="CourseList-main">
                <div className="wrapper-content">
                    <div className="scrollbar">
                        {loading &&
                            <div className='loading'>
                                <CircleSpin color='text-primary-500' />
                            </div>
                        }
                        {courses?.length > 0 && !loading && isGrid &&
                            <ViewGrid
                                data={courses}
                                handleChangeOrder={() => { }}
                                handleClickCourse={(id) => history.push(`/workspace/${params?.id}/management/courses/${id}`)}
                                order={pagination.order}
                                activeCourse={0}
                                workspaceId={params.id}
                            />
                        }
                        {!loading && !isGrid &&
                            <ViewTable
                                data={courses}
                                refreshData={getCourses}
                            />
                        }
                        {courses?.length <= 0 && !loading && isGrid &&
                            <NoContentWithImage img={NO_CONTENT} content={translator('COURSES.NO_COURSE')} />
                        }
                    </div>
                    <div className="CourseList-pagination">
                        <Pagination
                            total={pagination.total}
                            per_page={pagination.per_page}
                            indexPage={indexPage}
                            page={pagination?.page} 
                            callback={handlePagination}
                        />
                    </div>
                </div>
            </div>
            <DialogComponent
                isOpen={isModalTemplate}
                title={translator('COURSES.SELECT_FROM_TEMPLATE')}
                onCloseModal={() => setIsModalTemplate(false)}
                stylesTitle=" pl-6 py-2"
                xIconStyles="mr-4"
                maskClosable
                styles="max-w-5xl !p-0"
                child={
                    <><ModalTemplate isCreated={getCourses} /></>
                }
            />
        </div>
    );
};

export default CoursesList;
