import { withAsyncPaginate } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';
import Creatable from 'react-select/creatable';
import type { CreatableProps } from 'react-select/creatable';
import type { UseAsyncPaginateParams, ComponentProps } from 'react-select-async-paginate';
import type { ReactElement } from 'react';

type AsyncPaginateCreatableProps<
    OptionType,
    Group extends GroupBase<OptionType>,
    Additional,
    IsMulti extends boolean
> = CreatableProps<OptionType, IsMulti, Group> &
    UseAsyncPaginateParams<OptionType, Group, Additional> &
    ComponentProps<OptionType, Group, IsMulti>;

type AsyncPaginateCreatableType = <OptionType, Group extends GroupBase<OptionType>, Additional, IsMulti extends boolean = false>(
    props: AsyncPaginateCreatableProps<OptionType, Group, Additional, IsMulti>
) => ReactElement;

const AsyncPaginateCreatable = withAsyncPaginate(Creatable) as AsyncPaginateCreatableType;

export default AsyncPaginateCreatable;
