import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import InputRadio from '_shared/components/Form/InputRadio/InputRadio';
import { Link, useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { DataResponse } from 'types/ApiData.type';
import { CheckIcon, ExclamationCircleIcon, ExternalLinkIcon } from '@heroicons/react/outline';

export default function Certificate({ notAllowEdit = false, formProps }) {
  const { control, watch, setValue, getValues, register } = formProps;
  const params: any = useParams();
  const { t } = useTranslation();
  const [certificates, setCertificates] = useState<DataResponse>(null);
  const [items, setItems] = useState([]);
  const [hasLoading, sethHasLoading] = useState(true);
  const [templateSelected, setTemplateSelected] = useState<any>();
  const certTemplateId = watch('cert_template_id');
  const certType = watch('cert_type');

  const getCertificates = async (page) => {
    sethHasLoading(true);
    const res = await workspaceService.getCertificatesByWorkspace(params?.id, { page });
    setCertificates(res?.data);
    setItems(prev => [...prev, ...res?.data?.items]);
    sethHasLoading(false);
  }

  useEffect(() => {
    getCertificates(1);
  }, []);
  useEffect(() => {
    if (certTemplateId && params?.id) {
      const getCertificateDetail = async () => {
        const detailResponse = await workspaceService.getCertificateDetail(params?.id, certTemplateId);
        if (detailResponse?.data) {
          setTemplateSelected(detailResponse?.data);
        }
      }
      getCertificateDetail();
    }
  }, [certTemplateId]);

  useEffect(() => {
    if (certType === 'manually') {
      setValue('cert_template_id', null);
    } else {
      if (items?.length > 0 && !templateSelected) {
        setValue('cert_template_id', items[0]?.id);
      }
      if (templateSelected) {
        setValue('cert_template_id', templateSelected.id);
      }
    }
  }, [certType, templateSelected])

  return (
    <div className='py-5'>
      <div className='font-semibold text-xl px-5'>
        {t("COURSES.CERTIFICATE")}
      </div>
      <div className='mt-1 text-sm px-5 pb-6 border-b border-gray-300 text-gray-500'>{t("COURSES.CERTIFICATE_CONTENT")}</div>

      <div className={`${notAllowEdit ? 'opacity-container' : ''} `}>
        <div className="flex pl-5 items-start gap-2 mt-4">
          <div className="items-start">
            <InputRadio control={control}
              inputName="cert_type"
              value="manually"
              checked={watch('cert_type') === 'manually'}
              disabled={notAllowEdit}
            />
          </div>
          <div className="courseSettings-right">
            <div className="courseSettings-label">{t("COURSES.UPLOAD_CERTIFICATE")}</div>
            <p className="mt-1 text-gray-500 text-sm font-normal">{t("COURSES.UPLOAD_CERTIFICATE_CONTENT")}</p>
          </div>
        </div>
        <div className="flex pl-5 items-start gap-2 mt-4">
          <div className="items-start">
            <InputRadio control={control}
              inputName="cert_type"
              value="template"
              checked={watch('cert_type') === 'template'}
              disabled={notAllowEdit}
            />
          </div>
          <div className="courseSettings-right w-full pr-5">
            <div className="courseSettings-label">{t("COURSES.CERTIFICATE_TEMPLATE")}</div>
            <p className="mt-1 text-gray-500 text-sm font-normal">{t("COURSES.CHOOSE_CERTIFICATE_TEMPLATE")} <Link to={`/workspace/${params.id}/management/certificate`} className="text-primary-500 inline-flex">Certificate Management <ExternalLinkIcon className='w-5' /></Link>  <br />
              {t("COURSES.CERTIFICATE_TEMPLATE_CONTENT")}
            </p>
            <input name="cert_template_id" type="hidden"
              {...register('cert_template_id')}
            />
            {watch('cert_type') === 'template' ? <div className='flex gap-5 mt-2'>
              <div className='w-1/2'>
                <h3 className='text-gray-800 text-sm font-semibold  mb-2'>{t("COURSES.TEMPLATES")}</h3>
                <div className='w-full h-[302px] border border-gray-200 rounded'>
                  {/* @ts-ignore */}
                  <InfiniteScroll
                    dataLength={items?.length}
                    next={() =>
                      items?.length < certificates?.total &&
                      getCertificates(certificates?.page + 1)
                    }
                    hasMore={items?.length !== certificates?.total}
                    loader={
                      hasLoading && (
                        <div className="h-[300px] flex items-center justify-center">
                          <CircleSpin color="text-primary-500" />
                        </div>
                      )
                    }
                    height={'h-60'}
                    className="overflow-y-auto h-[300px] custom-scrollbar"
                  >
                    {items?.length > 0 ? items?.map(item => <div key={item?.id}
                      className={`flex justify-between px-4 py-2 text-gray-800 text-sm cursor-pointer hover:bg-primary-50 transition 
                      ${templateSelected?.id == item?.id ? 'bg-primary-50' : ''}`}
                      onClick={() => {
                        if (notAllowEdit) {
                          return;
                        }
                        setTemplateSelected(item);
                        setValue('cert_template_id', item?.id);
                      }}
                    >
                      {item?.name}
                      {templateSelected?.id == item?.id ? <span><CheckIcon className='w-5 text-primary-500' /></span> : null}
                    </div>) :
                      !hasLoading && <p className='flex items-center justify-center h-[300px] text-gray-500 text-sm'><ExclamationCircleIcon className='w-5 mr-2' /> {t("COURSES.NO_CERTIFICATE_TEMPLATE_AVAILABLE")}</p>}
                  </InfiniteScroll>
                </div>
              </div>
              <div className='w-1/2'>
                <h3 className='text-gray-800 text-sm font-semibold mb-2'>{t("PREVIEW")}</h3>
                <div className={`w-full h-[302px] flex items-center justify-center border border-gray-200 rounded bg-contain bg-center bg-no-repeat ${!templateSelected ? 'bg-gray-100' : ''}`}
                  style={{ backgroundImage: `url(${templateSelected?.image})` }}>
                  {!templateSelected ? <p className='text-gray-500 text-sm'>{t("COURSES.NO_CERTIFICATE_TO_PREVIEW")}</p> : null}
                </div>
              </div>
            </div> : null}
          </div>
        </div>
      </div>

    </div>
  )
}
