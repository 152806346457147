import { CheckIcon } from '@heroicons/react/outline';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import './style.css';

interface CheckBoxProps {
    inputName: string;
    disabled?: boolean;
    label?: string;
    control,
    labelClassName?: string
}

export const InputCheckbox: FC<CheckBoxProps> = ({ control, label, disabled, inputName, labelClassName }) => {
    return (
        <>
            <div className='flex items-center'>
                <Controller
                    control={control}
                    name={inputName}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <input
                                className="w-0 h-0 opacity-0"
                                type="checkbox"
                                id={`${inputName}-${value}`}
                                checked={value}
                                disabled={disabled}
                                onChange={onChange}
                            />
                            <label
                                className={`InputCheckbox ${disabled ? 'disabled': ''} overflow-hidden cursor-pointer rounded flex justify-center items-center`}
                                htmlFor={`${inputName}-${value}`}
                            >
                                <span className="hidden">Color</span>
                                <CheckIcon className="w-ooolab_w_5 h-ooolab_h_5  text-white border-none" />
                            </label>
                        </>
                    )}
                />
                {label ? <div className={`text-sm ml-2 ${disabled ? 'text-gray-400' : 'text-gray-800' } ${labelClassName ? labelClassName : ''}`}>{label}</div> : null}
            </div>
        </>
    );
};