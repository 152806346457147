import { Transition, Popover } from '@headlessui/react';
import React, { useContext, useEffect, useState } from 'react';
import {
    CreateWorkspaceContext,
    GetListOfWorkspaceContext,
    GetWorkspaceContext,
} from 'contexts/Workspace/WorkspaceContext';
import {
    GetListOfWorkspaceType,
    WorkspaceItem,
} from 'types/GetListOfWorkspace.type';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import workspaceMiddleware from 'middleware/workspace.middleware';

import '../style.css';
import { PlusIcon } from '@heroicons/react/outline';
import { Link, useHistory } from 'react-router-dom';

import DefaultWorkspaceAva from 'assets/SVG/workspace_avatar.svg';
import { SettingsIcon } from 'assets/icon/SettingIcon';
import { OptionsVertical } from 'assets/icon/OptionsVertical';
import { useAppSelector } from 'hooks/hooks';
import "./WorkspaceMenu.scss";
import { ListSettingIcon } from 'assets/icon';
import workspaceService from 'services/workspace.service';
import { defaultRole } from 'components/Management/RolesPermissions/RolesPermissionsContants';
import { UserService, WorkspaceService } from 'services';
import { formatPermission, onCheckPermissions } from 'constant/workspace.const';
import { GET_WORKSPACE_DETAIL } from 'actions/workspace.action';

const WorkspaceMenu = () => {
    const { expandedMenu } = useAppSelector(state => state.configPage);
    const { t: translator } = useTranslation();
    const history = useHistory();
    const [
        currentWorkspace,
        setCurrentWorkspace,
    ] = useState<GetListOfWorkspaceType>({
        items: [],
        order: '',
        page: 1,
        per_page: 10,
        sort_by: '',
        total: -1,
    });
    const { getWorkspaceDetailState } = useContext(
        GetWorkspaceContext
    );
    const {
        result: { membership }, permissions
    } = getWorkspaceDetailState;

    const {
        getListOfWorkspaceState,
        dispatch: dispatchList,
    } = React.useContext(GetListOfWorkspaceContext);

    const { result: getListOfWorkspaceStateResult } = getListOfWorkspaceState;

    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;
    useEffect(() => {
        if (getListOfWorkspaceStateResult?.items?.length > 0) {
            setCurrentWorkspace({
                items: currentWorkspace.items.concat(
                    getListOfWorkspaceStateResult.items
                ),
                order: getListOfWorkspaceStateResult.order,
                page: getListOfWorkspaceStateResult.page,
                per_page: getListOfWorkspaceStateResult.per_page,
                sort_by: getListOfWorkspaceStateResult.sort_by,
                total: getListOfWorkspaceStateResult.total,
            });
        }
    }, [getListOfWorkspaceStateResult]);

    function handleSelectedWorkspace(e: any) {
        workspaceService.onAuthWorkspace(e?.id).then(async () => {
            UserService.getUser().then((userData) => {
                WorkspaceService.getPermissionsUserWorkspace().then((res) => {
                    dispatchList({
                        type: GET_WORKSPACE_DETAIL.REQ_GET_WORKSPACE_PERMISSIONS,
                        result: formatPermission(res?.data),
                    });
                    onCheckPermissions(userData?.membership?.user_role?.role_type, userData?.membership?.user_role?.name, formatPermission(res?.data), history, e?.id, true)
                })
            })
        });
    }

    const getMoreListWorkspace = () => {
        workspaceMiddleware.getListOfWorkspaceMore(dispatchList, {
            page: currentWorkspace.page + 1,
        });
    };
    return (
        <Popover className="z-70 relative h-full w-full text-center" as="div">
            {({ open }) => (
                <>
                    <Popover.Button className="w-full focus:outline-none">
                        <div className={`workspaceMenu ${expandedMenu ? 'workspaceMenu-expaned' : 'workspaceMenu-collapsed'}`}>
                            <img
                                src={WorkspaceDetailInformation?.avatar_url || DefaultWorkspaceAva}
                                alt="workspace"
                                className={`block rounded-full w-8 h-8 ${WorkspaceDetailInformation?.avatar_url ? 'object-cover' : ''}`}
                            />
                            {!expandedMenu ? <button className="workspaceMenu-listSetting">
                                <ListSettingIcon />
                            </button> : null}
                            <div className={`text-xs font-semibold text-dark-300 truncate pl-1.5 ${expandedMenu ? '' : 'hidden'}`}>
                                {WorkspaceDetailInformation?.name}
                            </div>
                            {expandedMenu ? <div className='ml-auto text-dark-100'><OptionsVertical /></div> : null}
                        </div>
                    </Popover.Button>
                    <Transition
                        show={open}
                        as="div"
                        className={`absolute bg-white rounded-header_menu left-0 bottom-[56px] z-[999] w-full md:w-60 shadow-ooolab_menu flex flex-col overflow-y-auto focus:outline-none`}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"

                    >
                        <Popover.Panel>
                            <div className='flex items-center py-2.5 px-4 bg-blue-50'>
                                <img
                                    src={
                                        WorkspaceDetailInformation?.avatar_url ||
                                        DefaultWorkspaceAva
                                    }
                                    alt="workspace"
                                    className={`block rounded-full w-6 h-6 ${WorkspaceDetailInformation?.avatar_url ? 'object-cover' : ''}`}
                                />
                                <div className="text-xs font-semibold text-dark-300 truncate pl-1.5">
                                    {WorkspaceDetailInformation?.name}
                                </div>
                                {
                                    WorkspaceDetailInformation?.membership?.user_role?.name == defaultRole.superAdmin &&
                                    <Link to={`/workspace/${WorkspaceDetailInformation?.membership?.workspace_id}/setting?tab=general`}
                                        className={`ml-auto text-dark-300`}
                                    ><SettingsIcon /></Link>
                                }
                            </div>
                            {/*@ts-ignore*/}
                            <InfiniteScroll
                                dataLength={currentWorkspace?.items?.length}
                                next={() => getMoreListWorkspace()}
                                hasMore={
                                    currentWorkspace?.items?.length !==
                                    currentWorkspace?.total
                                }
                                loader={false && <h4>{translator('LOADING')}</h4>}
                                height={`${currentWorkspace?.items?.length > 2 ? '168px' : '90px'}`}
                                scrollableTarget="scrollableDiv "
                                className="custom-scrollbar scrollData pb-2"
                            >
                                <Popover.Group>
                                    {currentWorkspace?.items?.map(
                                        (item: WorkspaceItem, index: number) => (
                                            <div key={item.id + item?.name} className={`${item.id == WorkspaceDetailInformation?.id && 'hidden'}`}>
                                                <div
                                                    onClick={() =>
                                                        handleSelectedWorkspace(item)
                                                    }
                                                    className="flex items-center text-dark-300 px-4 py-2.5 text-xs cursor-pointer hover:bg-ooolab_bg_sub_tab_hover whitespace-nowrap text-left outline-none"
                                                >
                                                    <img
                                                        src={item.avatar_url || DefaultWorkspaceAva}
                                                        alt={item.name}
                                                        className={`block rounded-full w-6 h-6 ${item.avatar_url ? 'object-cover' : ''}`}
                                                    />
                                                    <div className='pl-2 truncate'>{item.name}</div>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </Popover.Group>
                            </InfiniteScroll>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
};

export default WorkspaceMenu;