import { FC, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ActionsButton from 'components/ActionsButton';
import { TrashIcon } from '@heroicons/react/outline';
import ModalCancelnvoice from '../components/ModalCancelnvoice';
import { checkPermission } from 'utils/commonFun';
import DetailIcon from 'assets/icon/DetailIcon';
import { HANDLE_ACTION_ENUM, INVOICE_TYPE } from '../../constants';

interface ActionMenuProp {
    data: any;
    refreshData: Function;
    position?: string
}

const ActionMenu: FC<ActionMenuProp> = ({ data, refreshData, position }) => {
    const { t: translator } = useTranslation();
    const params : {id} = useParams();
    const [isOpenModalCancel, setOpenModalCancel] = useState(false);
    const history = useHistory();

    return (
        <>
            <ActionsButton
                styleOptions={`${position ? position : 'top-0'} right-[35px]`}
                menus={[
                    {
                        label: translator('DETAIL'),
                        icon: <DetailIcon />,
                        action: () => history?.push(`/workspace/${params?.id}/e-commerce/billing/${data?.id}?type=recurring`),
                        isDisabled: (!checkPermission('invoice', 'R')),
                    },
                    {
                        label: translator('CANCEL'),
                        icon: <TrashIcon width="18" height="16" className="text-gray-900" />,
                        action: () => setOpenModalCancel(true),
                        isDisabled: !checkPermission('invoice', 'U'),
                        hide: data?.status === 'canceled'
                    },
                ]}
            />
            <ModalCancelnvoice
                isOpen={isOpenModalCancel}
                onClose={() => setOpenModalCancel(false)}
                invoice={{ invoice_type: INVOICE_TYPE.recurring, invoice_id: data?.id, action: HANDLE_ACTION_ENUM.cancel }}
                refreshData={refreshData}
            />

        </>
    );
};

export default ActionMenu;
