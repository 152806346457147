import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "./CourseCertificate.scss";
import CourseCertificateFilter from "./CourseCertificateFilter";
import { checkPermission, dowloadFile, getFileExtensionFromUrl, getIndexPage, onGetBlobFile } from "utils/commonFun";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import CourseCertificateTable from "./Components/CourseCertificateTable";
import Pagination from 'components/V2/Pagination';
import courseService from "services/course.service";
import { ButtonPrimary, LoadingPopup } from "_shared";
import { PlusIcon, XIcon } from "@heroicons/react/outline";
import ModalNewCertificate from "./Components/ModalNewCertificate";
import { DownloadIcon } from "assets/icon";
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import dayjs from "dayjs";
import debounce from "lodash/debounce";

const defaulPagination = {
    page: 1,
    per_page: 20,
    order: 'desc',
    total: 0,
};

export const CourseCertificate = () => {
    const params: { id: string, courseId: string } = useParams();
    const { t: translator } = useTranslation();
    const [isOpenModalNew, setOpenModalNew] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [indexPage, setIndexPage] = useState('0-0');
    const [pagination, setPagination] = useState<any>(defaulPagination);
    const [filter, setFilter] = useState(undefined);
    const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
    const [isMulti, setIsMulti] = useState<boolean>(false);
    const [loadingDownload, setIsLoadingDownload] = useState<boolean>(false)

    const workspaceId = params.id;
    const courseId = params.courseId;

    const getData = (page: number = 1, filter = {}, order = 'desc') => {
        setLoading(true);
        const payload = {
            ...pagination,
            ...filter,
            page,
            order
        };
        delete payload?.total;
        courseService
            .getListCertificate(workspaceId, courseId, payload)
            .then((res) => {
                const { items, total, page } = res.data;
                setData(items);
                setPagination({ ...pagination, total, page });
                setIndexPage(getIndexPage(payload?.page, pagination?.per_page, items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };
    const handlePagination = (page: number) => {
        getData(page);
    };

    const getDataDebounce = useCallback(
        debounce((page, filter) => getData(page, filter), 800),
        []
    );

    useEffect(() => {
        getDataDebounce(1, filter);
    }, [filter])

    const onCloseSelectFiles = () => {
        setSelectedFiles([]);
    };

    const onDownload = () => {
        dowloadFile(selectedFiles[0].file, selectedFiles[0].name, () => {
        });
    }

    function processFiles(files: { name: string; content: any }[]): { name: string; content: any }[] {
        const nameCounts = new Map<string, number>();
        const processedFiles: { name: string; content: any }[] = [];
        files.forEach((file) => {
            const { name, content } = file;
            const baseName = name?.split('.')[0];
            if (!nameCounts.has(baseName)) {
                nameCounts.set(baseName, 0);
                processedFiles.push(file);
            } else {
                const count = nameCounts.get(baseName)! + 1;
                nameCounts.set(baseName, count);
                const newName = `${baseName}(${count}).${name?.split('.')[1]}`;
                processedFiles.push({ name: newName, content });
            }
        });

        return processedFiles;
    }
    const downloadMultipleFiles = async () => {
        const zip = new JSZip();
        setIsLoadingDownload(true);
        try {
            const responses = await Promise.all(
                selectedFiles?.map(async (item) => {
                    const url = item?.file;
                    return {
                        name: item?.name + '.' + getFileExtensionFromUrl(item?.file),
                        content: await onGetBlobFile(url),
                    };
                })
            );
            const processedFiles = processFiles(responses);
            processedFiles?.forEach((file, index) => {
                const safeFileName = file.name.replace(/[<>:"\/\\|?*]/g, '_');
                const fileContent = file?.content;
                zip.file(safeFileName, fileContent, { binary: true });
            });

            const content = await zip.generateAsync({ type: 'blob' });
            const blob = new Blob([content]);
            const blobUrl = URL.createObjectURL(blob);
            saveAs(blob, 'certificate-download' + dayjs().format('YYYYMMDDHHmmss') + '.zip');
            URL.revokeObjectURL(blobUrl);
        } catch (error) {
        }
        setIsLoadingDownload(false);
    };

    return (
        <div className="courseCertificate">
            <div className="courseCertificate-title">{translator('COURSES.CERTIFICATES')}</div>
            <div className={` mt-5 flex justify-between `}>
                <div className={`${selectedFiles?.length > 0 ? 'flex' : 'hidden'}`}>
                    <div className="w-full flex flex-wrap gap-5 items-center">
                        <button type="button" onClick={onCloseSelectFiles}>
                            <XIcon className="w-5 h-5 text-gray-500" />
                        </button>
                        <div className="text-sm font-normal text-gray-800">{`${selectedFiles?.length} ${translator('COURSES.FILE(S)_SELECTED')}`}</div>
                        <button
                            type="button"
                            onClick={() => {
                                if (selectedFiles?.length === 1) {
                                    onDownload();
                                } else {
                                    downloadMultipleFiles();
                                }
                            }}
                        >
                            <DownloadIcon />
                        </button>
                    </div>
                </div>
                <div className={`w-full ${selectedFiles?.length == 0 ? '' : 'hidden'} `}>
                    <CourseCertificateFilter
                        onChangeFilter={(filter) => setFilter(filter)}
                        isFirstLoad={false}
                        listSelected={selectedFiles}
                        onClose={onCloseSelectFiles}
                        setIsMulti={setIsMulti}
                    />
                </div>
                {checkPermission('courseCertificate', 'U') &&
                    <ButtonPrimary type="button" onClick={() => setOpenModalNew(true)} className="flex gap-2 min-w-[80px] h-fit">
                        <PlusIcon className="text-white w-5 h-5" /> {translator('COURSES.NEW')}
                    </ButtonPrimary>
                }
            </div>
            <div className="courseCertificate-main mt-5">
                <div className="wrapper-content">
                    <div className="scrollbar">
                        {loading && (
                            <div className="loading">
                                <CircleSpin color="text-primary-500" />
                            </div>
                        )}
                        <CourseCertificateTable data={data} refreshData={getData} setSelectedFiles={setSelectedFiles} selectedFiles={selectedFiles} />
                    </div>
                    <div className="">
                        <Pagination
                            total={pagination.total}
                            per_page={pagination.per_page}
                            callback={handlePagination}
                            indexPage={indexPage}
                            page={pagination?.page}
                        />
                    </div>

                </div>
            </div>
            {isOpenModalNew && <ModalNewCertificate isOpen={isOpenModalNew} onClose={() => setOpenModalNew(false)} refreshData={getData} />}
            {loadingDownload && <LoadingPopup message={translator('COURSES.DOWNLOADING')} loading={loadingDownload} />}
        </div>
    )
}