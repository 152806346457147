import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import './styles.css';
import Chart from 'chart.js/auto';

export interface ColumnProps {
    labels: any,
    data: any
}

export const ChartColumnUnit = ({ data, label }: { data: ColumnProps, label?: string }) => {
    const options = {
        chart: {
            type: 'column',
            height: 185
        },
        credits: {
            enabled: false
        },
        title: {
            text: '',
            disable: true
        },
        subtitle: {
            text: '',
            disable: true
        },
        accessibility: {
            announceNewData: {
                enabled: false
            }
        },
        xAxis: {
            categories: data.labels
        },
        legend: {
            enabled: false
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                gridLineWidth: 0,
                dataLabels: {
                    enabled: false,
                    format: '{point.y:.1f}%'
                },
                color: '#0071CE',
                borderRadius: 4
            },
            column: {
                gridLineWidth: 0,
                pointWidth: 20,
                borderRadius: 4
            }
        },
        tooltip: {
            headerFormat: '',
            formatter: function () {
                if (this.point.value === null) {
                    return 'Null';
                }
                return `<span>${label}: </span><b>${this.point.y}</b>`;
            }
        },
        series: [
            {
                data: data.data
            }
        ]

    }
    
    return <>
        <div className="flex justify-center w-full">
            <div className={`relative  w-full`}>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
        </div>
    </>
}