import * as React from 'react';
import { useCallback } from 'react';
import { EyeOffSVG, EyeSVG } from 'assets/icon';
import { LockClosedIcon } from '@heroicons/react/outline';
import { classNames } from 'utils/commonFun';

interface PasswordEyesProps {
    formProps: any;
    onChange?: React.ChangeEventHandler<any>;
    placeholder?: string;
    errorsField?: any;
    nameInput?: any;
}

const PasswordEyes: React.FC<PasswordEyesProps> = ({
    formProps,
    onChange,
    placeholder,
    errorsField,
    nameInput,
}) => {
    const [show, updateShow] = React.useState(false);
    const changeEye = useCallback(() => updateShow(!show), [show]);
    return (
        <div
            className={classNames(
                'w-full ooolab_input_2 flex items-center lg:px-3 p-3 py-2',
                errorsField && 'border-red-600'
            )}
        >
            <input
                type={show ? 'text' : 'password'}
                placeholder={placeholder || 'Password'}
                className="w-full focus:ring-0 focus:outline-none bg-transparent"
                name={nameInput || 'password'}
                onChange={onChange}
                {...formProps}
            />
            <button
                type="button"
                onClick={changeEye}
                className="cursor-pointer"
            >
                {show ? <EyeOffSVG /> : <EyeSVG />}
            </button>
        </div>
    );
};

export default PasswordEyes;
