import { Fragment, FC, useRef, useEffect, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { SessionDateFormat } from 'constant/util.const';
import dayjs from 'dayjs';
import { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { ErrorMessage } from '@hookform/error-message';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { CalendarIcon } from '@heroicons/react/outline';
import TimePickerInput from 'components/Management/components/Form/TimePicker';
import DatePickerInput from 'components/Management/components/Form/DatePicker';

const Picker: FC<{ getValues: any, control: any, isOpeningCalendar: any, value: any, onChangeDate: (e: Date) => void, name: string }> = ({ getValues, control, isOpeningCalendar, onChangeDate, name }) => {
    const { t: translator } = useTranslation();
    const date = useRef(undefined);
    return (<Controller
        rules={{
            required: {
                value: true,
                message: translator('FORM_CONST.REQUIRED_FIELD'),
            },
        }}
        control={control}
        name={name}
        render={({
            field: { onChange, onBlur, value, ref },
            fieldState: { invalid, isTouched, isDirty, error },
        }) => {
            return <div className='flex px-1.5'>
                <CalendarIcon className='ooolab_dark_2 w-[15px]' />    
                <DatePicker
                    preventOpenOnFocus
                    onSelect={(e: Date, event: any) => {
                        date.current.setMonth(0);
                        date.current.setDate(e.getDate());
                        date.current.setFullYear(e.getFullYear());
                        date.current.setMonth(e.getMonth());
                        onChangeDate(date.current);
                    }}
                    onChange={(e: Date) => {
                        const newDate = new Date(e.getTime());
                        if (date.current) {
                            newDate.setMonth(0)
                            newDate.setDate(date.current.getDate())
                            newDate.setFullYear(date.current.getFullYear())
                            newDate.setMonth(date.current.getMonth())
                        }
                        date.current = newDate;
                        onChangeDate(newDate);
                    }}
                    filterTime={name === "endTime" ? (time) => {
                        const currentDate = dayjs(getValues().startTime);
                        const selectedDate = new Date(time);

                        if (date.current) {
                            selectedDate.setMonth(0)
                            selectedDate.setDate(date.current.getDate())
                            selectedDate.setFullYear(date.current.getFullYear())
                            selectedDate.setMonth(date.current.getMonth())
                        }
                        return dayjs(selectedDate).isAfter(currentDate);
                    } : undefined}
                    filterDate={name === "endTime" ? (time) => {
                        const currentDate = dayjs(getValues().startTime);
                        const selectedDate = new Date(time);

                        return (
                            dayjs(selectedDate).isSame(currentDate, 'day') ||
                            dayjs(selectedDate).isAfter(currentDate)
                        );
                    } : undefined}
                    onCalendarOpen={() => {
                        isOpeningCalendar.current = true;
                    }}
                    onCalendarClose={() => {
                        isOpeningCalendar.current = false;
                        date.current = undefined;
                    }}
                    value={value}
                    dateFormat={SessionDateFormat}
                    className='cursor-pointer pl-ooolab_p_1_e h-full border-ooolab_bar_color text-ooolab_1xs text-ooolab_dark_1 bg-transparent py-ooolab_p_2 rounded font-normal'
                    showTimeSelect
                    shouldCloseOnSelect
                    portalId='custom-picker'
                    popperClassName='top-level'
                />
            </div>
        }}
    />);
}

const EventForm = ({
    data
}) => {
    const params: { id: string } = useParams();
    const cancelButtonRef = useRef(null);
    const lessonRef = useRef<any>();
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        reset,
        clearErrors,
        control,
        setValue
    } = useForm({
        defaultValues: {
            name: '',
            description: null,
            startTime: undefined,
            endTime: undefined,
            link: undefined,
            lesson: undefined,
        },
    });
    const { t: translator } = useTranslation();
    const [, setUpdateTime] = useState(0);
    const isOpeningCalendar = useRef(false);
    const [keyword, setKeyword] = useState('');
    const [lessons, setLessons] = useState([]);

    useEffect(() => {
        if (data) {
            setValue(
                'startTime',
                data.start_time
            );
            setValue(
                'endTime',
                data.end_time
            );
            if (data.lesson) {
                setValue(
                    'lesson',
                    data.lesson.id
                );
            }
            setUpdateTime(new Date().getTime());
        }
    }, []);

    const onStartTimeChange = (e: Date) => {
        setValue(
            'startTime',
            dayjs(e).format(SessionDateFormat)
        );
        setUpdateTime(new Date().getTime());
    };

    const onEndTimeChange = (e: Date) => {
        setValue(
            'endTime',
            dayjs(dayjs(e).isAfter(dayjs(getValues().startTime)) ? e : dayjs(getValues().startTime).add(30, 'minute').toDate()).format(SessionDateFormat)
        );
        setUpdateTime(new Date().getTime());
    };

    const debounceInput = useCallback(
        debounce(
            (
                nextValue: string,
                asyncFunction: (inputValue: string) => void
            ) => {
                asyncFunction(nextValue);
            },
            1000
        ),
        []
    );
    const submit = () => {
        const val = getValues();
        // onConfirm(val.startTime, val.endTime, val.lesson, data ? data.id : undefined);
        // onClose();
    };
    const update = useCallback((inputValue: string) => setKeyword(inputValue), []);
    
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <div className="col-span-2 text-ooolab_xs">
                    <label
                        htmlFor="class-name"
                        className="text-ooolab_dark_1 font-semibold leading-ooolab_24px block"
                    >
                        {translator('CLASSES.CLASS_NAME')}
                        <span className="text-red-500">*</span>
                    </label>
                    <input
                        className="border lg:w-full md:w-3/4 w-1/2 h-8 border-ooolab_bar_color text-ooolab_dark_1 p-ooolab_p_2 rounded font-normal"
                        type="text"
                        id="class-name"
                        maxLength={255}
                        placeholder={translator('CLASSES.CLASS_NAME')}
                        {...register('name', {
                            required: {
                                value: true,
                                message: translator(
                                    'FORM_CONST.REQUIRED_FIELD'
                                ),
                            },
                            validate: {
                                shouldNotContainSpace: (value) =>
                                    !!value.trim()
                                        ? true
                                        : `${translator(
                                            'FORM_CONST.REQUIRED_FIELD'
                                        )}`,
                            },
                        })}
                    />
                    <ErrorMessage
                        className="text-red-500 text-ooolab_10px"
                        errors={errors}
                        name="name"
                        as="p"
                    />
                </div>
                <div className='flex justify-between items-center mt-4 space-x-10'>
                    <div className='w-1/2'>
                        <div className='text-xs text-ooolab_dark_1 font-semibold'>Date</div>
                        <div className='mt-1'>
                            <div className='flex justify-between items-center border border-ooolab_bar_color pl-2 py-ooolab_p_2 h-8 overflow-hidden rounded h-8'>
                                <CalendarIcon className='flex-none w-5 h-5 text-ooolab_text_username mr-1' />
                                <DatePickerInput
                                    labelCls='text-ooolab_text_username  text-xs'
                                    isOriginal={false}
                                    onCalendarOpen={() => {
                                        isOpeningCalendar.current = true;
                                    }}
                                    onCalendarClose={() => {
                                        isOpeningCalendar.current = false;
                                    }}
                                    portal={{ id: 'custom-picker', className: 'top-level' }}
                                    control={control}
                                    name="end_date"
                                    isRequired
                                    paddingCls="py-3"
                                />
                            </div>    
                        </div>
                        <ErrorMessage
                            className="text-red-500 text-ooolab_10px"
                            errors={errors}
                            name="startTime"
                            as="div"
                        />
                    </div>
                    <div className='w-1/2 flex space-x-2'>
                        <div className='w-1/2'>
                            <div className='text-xs text-ooolab_dark_1 font-semibold mb-1'>Start Time</div>
                            <div className='mt-1 text-xs'>
                                <TimePickerInput
                                    control={control}
                                    name="time"
                                />
                            </div>
                        </div>
                        <div className='w-1/2'>
                            <div className='text-xs text-ooolab_dark_1 font-semibold mb-1'>End Time</div>
                            <div className='mt-1 text-xs'>
                                <TimePickerInput
                                    control={control}
                                    name="time"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-4 text-ooolab_xs mt-4">
                    <label
                        htmlFor="description"
                        className="text-ooolab_dark_1 font-semibold leading-ooolab_24px block"
                    >
                        {translator('CLASSES.DESCRIPTION')}
                    </label>
                    <textarea
                        className="border focus:outline-none lg:w-full md:w-3/4 w-1/2 border-ooolab_bar_color text-ooolab_dark_1 p-ooolab_p_2 rounded font-normal"
                        rows={5}
                        maxLength={255}
                        id={translator('CLASSES.DESCRIPTION_CLASS')}
                        {...register('description')}
                    />
                    <ErrorMessage
                        className="text-red-500 text-ooolab_10px"
                        errors={errors}
                        name="description"
                        as="p"
                    />
                </div>
                <div className='flex justify-between items-center mt-4 space-x-10'>
                    <div className='w-full'>
                        <div className='text-xs text-ooolab_dark_1 font-semibold'>Link</div>
                        <input
                            className="border lg:w-full md:w-3/4 w-1/2 h-8 border-ooolab_bar_color text-ooolab_dark_1 p-ooolab_p_2 rounded text-xs"
                            type="text"
                            id="class-name"
                            maxLength={255}
                            placeholder={translator('CLASSES.CLASS_NAME')}
                            {...register('link', {
                                required: {
                                    value: true,
                                    message: translator(
                                        'FORM_CONST.REQUIRED_FIELD'
                                    ),
                                },
                                validate: {
                                    shouldNotContainSpace: (value) =>
                                        !!value.trim()
                                            ? true
                                            : `${translator(
                                                'FORM_CONST.REQUIRED_FIELD'
                                            )}`,
                                },
                            })}
                        />
                    </div>                    
                </div>
                <div className='flex justify-center items-center mt-8'>
                    <button
                        type="submit"
                        className='text-xs bg-ooolab_dark_300 text-white rounded-ooolab_radius_4px flex justify-center items-center shadow-ooolab_sched_button text-ooolab_sm px-2 py-2'
                    >
                        Create
                    </button>
                </div>
            </form>
        </>
    );
};

export default EventForm;
