import * as React from 'react';
// COMPONENT
import ForgotPasswordForm from 'components/Authentication/AuthenticationForgotPasswordForm';
import CreatePassword from 'components/Authentication/AuthenticationForgotPasswordForm/CreatePassword';
import ImagesLogin from 'components/ImagesSlider/ImagesLogin';
import { AuthContext } from 'contexts/Auth/AuthContext';

export interface ForgotPasswordPageProps {
    tokenParam?: string;
    emailParam?: string;
}

const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = ({
    tokenParam,
    emailParam,
}) => {
    const authCtx = React.useContext(AuthContext);
    const [whiteLabel, setWhiteLabel] = React.useState();

    React.useEffect(() => {
        if (authCtx?.config) {
            setWhiteLabel(authCtx?.config?.find(item => item?.page_name == "login"));
        }
    }, [authCtx]);

    return (
        <div className="min-h-screen">
            {tokenParam ? (
                <div className="relative">
                    <CreatePassword
                        tokenParam={tokenParam}
                        emailParam={emailParam}
                    />
                </div>
            ) : (
                <div className="flex">
                    <div className="w-full max-w-sm 2xl:max-w-md">
                        <ImagesLogin whiteLabel={whiteLabel} />
                    </div>
                    <div className=" w-full">
                        <ForgotPasswordForm whiteLabel={whiteLabel}/>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ForgotPasswordPage;
