import { FC, useContext, useEffect, useState } from 'react';
import ButtonComponent from 'components/Button/ButtonComponent';
import { useFieldArray, useForm } from 'react-hook-form';
import CheckboxComponent from 'components/Checkbox/CheckboxComponent';
import { classNames, getLocalTimeZone } from 'utils/commonFun';
import DashboardService from 'services/teach.services';
import dayjs from 'dayjs';
import { UserContext } from 'contexts/User/UserContext';
import { Switch } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { Toastify } from '_shared';
import DatePickerWeekComponent from '../util/DatePickerWeek';
import TimeSlotComponent from '../util/TimeSlot';

dayjs.extend(weekOfYear);

type ModalCheckinAvailableTimeProps = {
    workspaceId: string;
    translator: (key: string, newKey?: boolean) => string;
    data?: any;
    fetchData: () => void;
    isAdmin?: boolean;
    TeacherId?: string;
};

const dateFormat = 'YYYY-MM-DD';

const ModalCheckinAvailableTime: FC<ModalCheckinAvailableTimeProps> = ({
    workspaceId,
    translator,
    data,
    fetchData,
    isAdmin = false,
    TeacherId,
}) => {
    
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isDirty },
        setValue,
        getValues,
        watch,
        clearErrors,
        setError,
        reset,
    } = useForm({
        defaultValues: {
            start_date: undefined,
            end_date: undefined,
            time_range: [],
        },
    });
    const {
        userState: { result },
    } = useContext(UserContext);

    const { fields, replace, update } = useFieldArray({
        control,
        name: 'time_range',
    });
    const timeZoneUser = result?.time_zone || getLocalTimeZone();
    const dateByTZ = dayjs(new Date()).tz(timeZoneUser);
    const toLocal = dateByTZ.format('YYYY-MM-DDTHH:mm:ss');

    const [isRecurring, setIsRecurring] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState([new Date(toLocal), new Date().setDate(new Date().getDate() + 6)]);
    const [startDate, endDate] = dateRange;
    const formatWeekList = (start) => {
        if (start) {
            let count = 0;
            let countDays = dayjs(start)?.isoWeekday() - 1;
            let timeRange = [timeRangeConst[dayjs(start)?.isoWeekday() - 1]];
            while (count < 6) {
                if (countDays === 6) {
                    timeRange.push(timeRangeConst[0]);
                    countDays = 0;
                } else {
                    timeRange.push(timeRangeConst[countDays + 1]);
                    countDays++;
                }
                count++;
            }
            replace(timeRange);
        }
    };

    const onChange = (timeRange) => {
        setDateRange([timeRange[0], new Date(timeRange[0]).setDate(new Date(timeRange[0]).getDate() + 6)]);
    };

    const timeRangeConst: any = [
        {
            weekday: 1,
            label: translator('WEEK.MONDAY', true),
            value: false,
            start_time: '',
            end_time: '',
            disable: true,
            fieldName: 'mon',
            timeSlot: [
                {
                    weekdayChild: 1,
                    slot: 0,
                    slotStartTime: '',
                    slotEndTime: '',
                },
            ],
        },
        {
            weekday: 2,
            label: translator('WEEK.TUESDAY', true),
            value: false,
            start_time: '',
            end_time: '',
            disable: true,
            fieldName: 'tue',
            timeSlot: [
                {
                    weekdayChild: 2,
                    slot: 0,
                    slotStartTime: '',
                    slotEndTime: '',
                },
            ],
        },
        {
            weekday: 3,
            label: translator('WEEK.WEDNESDAY', true),
            value: false,
            start_time: '',
            end_time: '',
            disable: true,
            fieldName: 'wed',
            timeSlot: [
                {
                    weekdayChild: 3,
                    slot: 0,
                    slotStartTime: '',
                    slotEndTime: '',
                },
            ],
        },
        {
            weekday: 4,
            label: translator('WEEK.THURSDAY', true),
            value: false,
            start_time: '',
            end_time: '',
            disable: true,
            fieldName: 'thu',
            timeSlot: [
                {
                    weekdayChild: 4,
                    slot: 0,
                    slotStartTime: '',
                    slotEndTime: '',
                },
            ],
        },
        {
            weekday: 5,
            label: translator('WEEK.FRIDAY', true),
            value: false,
            start_time: '',
            end_time: '',
            disable: true,
            fieldName: 'fri',
            timeSlot: [
                {
                    weekdayChild: 5,
                    slot: 0,
                    slotStartTime: '',
                    slotEndTime: '',
                },
            ],
        },
        {
            weekday: 6,
            label: translator('WEEK.SATURDAY', true),
            value: false,
            start_time: '',
            end_time: '',
            disable: true,
            fieldName: 'sat',
            timeSlot: [
                {
                    weekdayChild: 6,
                    slot: 0,
                    slotStartTime: '',
                    slotEndTime: '',
                },
            ],
        },
        {
            weekday: 0,
            label: translator('WEEK.SUNDAY', true),
            value: false,
            start_time: '',
            end_time: '',
            disable: true,
            fieldName: 'sun',
            timeSlot: [
                {
                    weekdayChild: 0,
                    slot: 0,
                    slotStartTime: '',
                    slotEndTime: '',
                },
            ],
        },
    ];

    useEffect(() => {
        formatWeekList(startDate);
    }, []);

    const createAvailableSlotAPI = (bodyParams?: any) => {
        DashboardService.createOneTimeEvent(workspaceId, isAdmin ? TeacherId : result?.id, bodyParams)
            .then((res) => {
                fetchData?.();
                Toastify.success('Your availability time have been successfully submitted.');
            })
            .catch((err) => {
                if (err?.response?.data) {
                    Toastify.error(err?.response?.data?.message);
                } else {
                    Toastify.error();
                }
            })
            .finally();
    };


    const handleMath3MonthRecurring = (startDate) => {
        const date = dayjs(startDate);
        const next3Months = date.add(3, 'month');
        const weeksInNext3Months = next3Months.week() - date.week() + (next3Months.year() - date.year()) * date.week();
        const endDate = date.clone().add(7 * weeksInNext3Months, 'days');
        return endDate;
    };

    const onSubmitForm = (values) => {
        const timeZone = result?.time_zone || getLocalTimeZone();
        const arr2DTimeRange = values.time_range.filter((el) => el.value).map((el) => el?.timeSlot);
        const arrTimeRange = arr2DTimeRange?.flat() || [];

        const params = {
            type_: 'available',
            schedule_time_ranges: arrTimeRange?.map((slot) => ({
                start_time: `${dayjs(slot?.dateOfWeek).format(dateFormat)} ${dayjs(slot?.slotStartTime).format('HH:mm:ss')}${dayjs(
                    slot?.slotStartTime
                )
                    .tz(timeZone)
                    .format('Z')}`,
                duration: Math?.round(dayjs(slot?.slotEndTime)?.diff(dayjs(slot?.slotStartTime), 'second') / 60) || 30,
                schedule_type: isRecurring ? 'weekly' : 'once',
                recurring_start_at: isRecurring
                    ? `${dayjs(slot?.dateOfWeek).format(dateFormat)} ${dayjs(slot?.slotStartTime).format('HH:mm:ss')}${dayjs(
                          slot?.slotStartTime
                      )
                          .tz(timeZone)
                          .format('Z')}`
                    : null,
                recurring_end_at: isRecurring
                    ? `${handleMath3MonthRecurring(slot?.dateOfWeek).format(dateFormat)} ${dayjs(slot?.slotEndTime).format(
                          'HH:mm:ss'
                      )}${dayjs(slot?.slotEndTime).tz(timeZone).format('Z')}`
                    : null,
            })),
        };
        createAvailableSlotAPI(params);
    };
    return (
        <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="border-t border-gray-200 py-6">
                <div className='text-sm text-orange-500'>
                    {translator('SUB_LABEL_ADD_AVAILABILITY')}
                </div>
                <div className="mt-5">
                    <label className="font-semibold text-xs text-gray-800">{translator('WEEK')}</label>
                    <DatePickerWeekComponent onChange={onChange} startDate={startDate} endDate={endDate} />
                </div>
                {(startDate && (
                    <div className="mt-5 text-xs font-normal">
                        <p className="font-semibold mb-1">{translator('DAYS')}</p>
                        <div className="grid grid-cols-7 gap-2">
                            {watch('time_range').map((el, index) => (
                                <CheckboxComponent
                                    key={index}
                                    id={el.label}
                                    label={el?.label?.slice(0, 3)}
                                    propsCheckbox={{ ...register(`time_range.${index}.value`) }}
                                    type='checkbox'
                                />
                            ))}
                        </div>
                    </div>
                )) ||
                    ''}

                {!!watch('time_range').filter((el) => el.value)?.length && (
                    <div className="mt-5 text-xs font-normal">
                        <p className="font-semibold">{translator('TIME_SLOTS')}</p>
                        <div>
                            {watch('time_range').map((el, index) => (
                                <div
                                    key={`${index}-time_range-${startDate}`}
                                    className={classNames('mt-2 grid grid-cols-4 gap-2', !el.value ? 'hidden' : '')}
                                >
                                    <div className="col-span-1 text-gray-500 font-semibold">{el.label}</div>
                                    <div className="col-span-3 space-x-2">
                                        <TimeSlotComponent
                                            control={control}
                                            index={index}
                                            watch={watch}
                                            errors={errors}
                                            fieldsParent={fields}
                                            appendParent={update}
                                            startDate={startDate}
                                            endDate={endDate}
                                            weekDay={el.weekday}
                                            today={index === 0}
                                            clearErrors={clearErrors}
                                            setValue={setValue}
                                            isRequired={el?.value}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            {(isDirty && (
                <div>
                    <div className="text-gray-800 text-xs font-semibold">{translator('WEEKLY_AVAILABILITY')}</div>
                    <Switch
                        checked={isRecurring}
                        onChange={setIsRecurring}
                        style={{
                            boxShadow: 'inset 0px 0.5px 4px rgba(96, 97, 112, 0.2)',
                        }}
                        className={`${
                            !isRecurring ? 'bg-ooolab_bg_bar' : 'bg-primary-500'
                        } relative m-2 py-2 flex items-center h-6 w-12 shadow-ooolab_box_shadow_container rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                        <span
                            style={{
                                boxShadow: '0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16)',
                            }}
                            className={`${
                                !isRecurring ? 'left-ooolab_inset_10' : 'right-ooolab_inset_10'
                            } absolute inline-block w-5 h-5 transform bg-white rounded-full`}
                        />
                    </Switch>
                    {(isRecurring && (
                        <div className="text-xs text-primary-500 flex my-3">
                            <InformationCircleIcon className="h-5 w-6 mr-1" />
                            {isAdmin ? translator('RECURRING_WARNING_ADMIN') : translator('RECURRING_WARNING')}
                        </div>
                    )) ||
                        ''}
                </div>
            )) ||
                ''}

            <div className="px-2 pt-4 -mb-2 text-center border-t border-gray-200">
                <ButtonComponent
                    type="submit"
                    title={translator('ADD')}
                    classStyle="xs:p-2 xs:text-sm primary-button"
                    disable={!watch('time_range').filter((el) => el.value)?.length}
                />
            </div>
        </form>
    );
};

export default ModalCheckinAvailableTime;
