import { FC, useContext, useMemo } from 'react';
import { CourseType } from 'types/Courses.type';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'contexts/User/UserContext';
import { useHistory, useParams } from 'react-router-dom';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import NO_CONTENT from 'assets/img/empty-state/instructor.png';
import ReactTooltip from 'react-tooltip';

interface IViewTable {
    data: CourseType[];
    refreshData: Function;
    learnerGroupeId: any
}

const GradebookListOverviewTable: FC<IViewTable> = ({ data, refreshData,learnerGroupeId }) => {
    const { t: translator } = useTranslation();
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;
    const params: { id: string, courseId: string } = useParams();
    const {
        userState: { result },
    } = useContext(UserContext);
    const history = useHistory();

    const getValue = (value) => {
        if (value == null) {
            return '-';
        }
        return value + '%';
    }

    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('LEARNER')} />,
                accessor: 'Learner/Learning Group',
                Cell: ({ row }: any) => {
                    const learner = row?.original?.user || row?.original?.learner;
                    const id = row?.original?.learning_group ? `learnerGroupeId=${learner?.id}` : `learnerId=${learner?.id}`
                    return (
                        <div className="flex gap-1 text-sm text-gray-800 ml-2 cursor-pointer"
                            onClick={() => history.push(`/workspace/${params?.id}/management/courses/${params?.courseId}?tab=gradebook&${id}&subtab=assignment&isGroup=true`, {
                                isLearningGroup: learnerGroupeId ? learnerGroupeId : null
                            })}>
                            <div className='flex gap-2 items-center'
                                data-tip={learner?.name || learner?.display_name}
                                data-for={row?.original?.id + '_show-more-people'}>
                                <div
                                    className={`flex-none w-7 h-7 rounded-full overflow-hidden bg-cover`}
                                    style={{ backgroundImage: `url('${row?.original?.learning_group ? `https://ui-avatars.com/api?name=${learner?.name}&size=128&format=png` : learner?.avatar_url}')` }}
                                ></div>
                                <div className='grid'>
                                    <div className='truncate text-primary-500 font-semibold text-sm'>{learner?.name || learner?.display_name}</div>
                                </div>
                                {
                                    //@ts-ignore
                                    <ReactTooltip
                                        place="top"
                                        type="dark"
                                        effect="float"
                                        id={row?.original?.id + '_show-more-people'}
                                        getContent={(content) => <div>{content}</div>}
                                    />
                                }
                            </div>
                        </div>
                    );
                },
                width: "180"
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.OVERALL_GRADE')} className="!justify-center" />,
                accessor: 'overall',
                Cell: ({ row }: any) => <RenderTableCell className='text-center bg-gray-100' value={getValue(row?.values?.overall)} />,
                width: "100"
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.ATTENDANCE')} className="!justify-center" />,
                accessor: 'attendance',
                Cell: ({ row }: any) => <RenderTableCell className='text-center' value={getValue(row?.values?.attendance)} />,
                width: "100"
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.COURSE_COMPLETION')} className="!justify-center" />,
                accessor: 'course_completion',
                Cell: ({ row }: any) => <RenderTableCell className='text-center' value={getValue(row?.values?.course_completion)} />,
                width: "140"
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.ASSIGNMENT_GRADE')} className="!justify-center" />,
                accessor: 'assignment_score',
                Cell: ({ row }: any) => <RenderTableCell className='text-center' value={getValue(row?.values?.assignment_score)} />,
                width: "120"
            },

            {
                Header: () => <RenderTableHeader value={translator('COURSES.LESSON_GOAL_COMPLETION')} className="!justify-center" />,
                accessor: 'lesson_goal',
                Cell: ({ row }: any) => <RenderTableCell className='text-center' value={getValue(row?.values?.lesson_goal)} />,
                width: "160"
            }
        ],
        [data, WorkspaceDetailInformation]
    );

    return (
        <Table
            data={data || []}
            columns={columns}
            emptyContent={translator('No Gradebook')}
            emptyIcon={NO_CONTENT}
        />
    );
};

export default GradebookListOverviewTable;
