import React, { useContext, useEffect, useState } from 'react'
import Notification from '../Notification/Notification'
import { UserContext } from 'contexts/User/UserContext'
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { useParams } from 'react-router-dom';
import { Toastify } from '_shared';
import { TeacherDashboardService } from 'services';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import NO_NOTIFICATION from 'assets/img/empty-state/notiEmpty.png'
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'contexts/Auth/AuthContext';

export default function News() {
  const authCtx = React.useContext(AuthContext);
  const [whiteLabel, setWhiteLabel] = useState<any>();

  useEffect(() => {
    if (authCtx?.config) {
      console.log(authCtx?.config);
      setWhiteLabel(authCtx?.config?.find(item => item?.page_name == "login"));
    }
  }, [authCtx]);

  const { userState: { result } } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [pagination, setPagination] = useState({ page: 1, total: 0, per_page: 20 });
  const params: { id: string } = useParams();
  const [listNotifications, setListNotifications] = useState([])
  const { t: translator } = useTranslation();

  const getListNotifications = (page = 1) => {
    setLoading(true);
    TeacherDashboardService.getNotifications(params?.id, {
      page,
      per_page: pagination?.per_page,
      category: 'news'
    })
      .then((res) => {
        setListNotifications([...listNotifications, ...res?.data?.items]);
        setPagination({ ...pagination, page: pagination.page + 1, total: res?.data?.total })
        handleCheckNext(res?.data?.page, res?.data?.total / pagination?.per_page);
        setLoading(false)
      })
      .catch(err => Toastify.error())
  }

  const handleCheckNext = (page: number, totalPage) => {
    if (page < totalPage) {
      setHasNextPage(true);
    } else {
      setHasNextPage(false);
    }
  };

  const handleLoadMore = async () => {
    getListNotifications(pagination?.page + 1)
  }

  useEffect(() => {
    getListNotifications()
  }, [])


  const [infiniteRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: handleLoadMore,
  });

  return (
    <div className='m-5 flex flex-col gap-4'>
      {
        listNotifications?.map((item) => {
          const notificationItem = {
            type: item?.event,
            subTitle: item?.subtitle || item?.data?.custom_notification?.subtitle,
            title: item?.title || item?.data?.custom_notification?.title,
            tz: result.time_zone,
            startDate: item?.created_at,
            createdAt: item?.created_at,
            id: item?.id,
            courseName: item?.data?.session?.course?.title,
            assignmentName: item?.data?.assignment?.name,
            link: item?.data?.custom_notification?.link,
            vcLink: item?.data?.session?.vc_link,
            isClicked: item?.is_clicked,
            session: item?.data?.session,
            image: item?.data?.custom_notification?.image || whiteLabel?.data?.web?.logo
          }
          return (
            <div key={item?.id}>
              <Notification {...notificationItem} />
            </div>
          )
        })
      }
      {
        listNotifications?.length === 0 && !loading && (
          <div className='h-[calc(100vh-100px)] w-full flex items-center justify-center'>
            <NoContentWithImage img={NO_NOTIFICATION} content={translator('INSTRUCTOR_PORTAL.NO_NOTIFICATIONS')} />
          </div>
        )
      }
      {hasNextPage &&
        <div className="w-full flex items-center justify-center text-center mt-5" ref={infiniteRef}>
          {loading && <CircleSpin color="text-primary-500" />}
        </div>
      }
    </div>
  )
}
