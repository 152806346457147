import * as React from 'react';
import { H5PBuilder } from 'components/H5P/H5PComponents';
import H5PProvider from 'contexts/H5P/H5PProvider';
import RouteMasterPage from 'pages/RouteMasterPage';

const H5PBuilderPage = () => {
    return (
        <H5PProvider>
            <H5PBuilder>
                <RouteMasterPage />
            </H5PBuilder>
        </H5PProvider>
    );
};

export default H5PBuilderPage;
