import userService from 'services/user.service';

class UploadAdapter {
    loader: any;
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        const hostCDN = process.env.REACT_APP_HOST_CDN;
        return this.loader.file.then(
            (file) =>
                new Promise(async (resolve, reject) => {
                    let myReader: FileReader = new FileReader();
                    await userService.uploadImage(
                        file,
                        (resUpload) => {
                            myReader.onloadend = () => {
                                resolve({ default: hostCDN + resUpload });
                            };

                            myReader.readAsDataURL(file);
                        },
                        (err) => {
                            reject(err);
                        }
                    );
                })
        );
    }
}

export default UploadAdapter;
