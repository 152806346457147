import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import CreateFolder from 'components/GoogleDriver/CreateFolder';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { getLocalStorageAuthData } from 'utils/handleLocalStorage';
import { FormDataType, GoogleTypeFolder, TreeFolder } from 'types/GoogleType';
import { GoogleAPIAndServicesContext } from 'contexts/Google/GoogleAPIAndServicesContext';
import { LeftMenuContext } from 'contexts/LeftMenu/LeftMenuContext';
import Notification from 'components/Notification';
import { AuthContext } from 'contexts/Auth/AuthContext';
import googleMiddleware from 'middleware/google.middleware';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { UPLOAD_FILE, UPLOAD_FOLDER } from 'constant/menu.const';
import leftMenuMiddleware from 'middleware/leftMenu.middleware';
import { FileUpload } from 'types/LeftMenu.type';
import { useTranslation } from 'react-i18next';

export interface SubMenuNavigationProps {
    bar: any;
    setSelectedMenu: React.Dispatch<React.SetStateAction<number>>;
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const SubMenuNavigation: React.FC<SubMenuNavigationProps> = ({
    bar
}) => {
    const { t: translator } = useTranslation();
    const history = useHistory();
    const param: { id: string; type?: string; folderId: string } = useParams();
    const checkGoogleAuth = localStorage.getItem('google_auth');
    const file = useRef<any>();
    const folder = useRef<any>();
    const [formDataGoogle, setFormDataGoogle] = useState<FormDataType[]>([]);
    const { dispatch: GoogleDispatch } = React.useContext(
        GoogleAPIAndServicesContext
    );
    const { dispatch: leftMenuDispatch } = React.useContext(
        LeftMenuContext
    );
    const [isNotify, setIsNotify] = useState<boolean>(false);
    const {
        dispatch: workspaceDispatch,
        getWorkspaceDetailState,
    } = React.useContext(GetWorkspaceContext);
    const {
        members,
        result: WorkspaceDetailInformation,
    } = getWorkspaceDetailState;
    const authCtx = useContext(AuthContext);
    const [isCreateFolder, setIsCreateFolder] = useState<boolean>(false);

    useEffect(() => {
        async function sync() {
            if (formDataGoogle.length) {
                await googleMiddleware.uploadFileGoogleDrive(
                    GoogleDispatch,
                    formDataGoogle
                );
            }
        }

        sync();
    }, [formDataGoogle]);

    const l = useLocation();
    if (bar) {
        for (let i = 0; i < bar.tab.length; i++) {
            bar.tab[i].current = false;
            if (bar.tab[i].href.includes(l.pathname)) {
                bar.tab[i].current = true;
            }
        }
    }

    const userDataLocalStorage = getLocalStorageAuthData();
    useEffect(() => {
        if (members.items && members.items.length) {
            const { items: listMembers } = members;
            const currentUser = listMembers.filter(
                (i) =>
                    i.email === userDataLocalStorage.email &&
                    i.name === userDataLocalStorage.name
            );
            const userRole = currentUser[0]?.membership.role.toLocaleLowerCase();
            const isCreator = currentUser[0]?.membership.is_creator;
            workspaceMiddleware.setUserRole(workspaceDispatch, userRole);
            workspaceMiddleware.setUserWorkspaceCreator(
                workspaceDispatch,
                isCreator
            );
        }
    }, [members]);
    let pathFile = '';
    function handleFileInput(file: React.ChangeEvent<HTMLInputElement>) {
        if (file) {
            setFormDataGoogle([]);
            let id = 'root';

            if (file.target.files) {
                // setUploadAction(UPLOAD_FILE);
                googleMiddleware.actionUpload(GoogleDispatch, UPLOAD_FILE);
                const pathName = location.pathname;
                if (
                    pathName.includes('workspace-drive') &&
                    WorkspaceDetailInformation.drive_default_path
                ) {
                    id = WorkspaceDetailInformation.drive_default_path;
                } else if (param.folderId) {
                    id = param.folderId;
                }
                Array.from(file.target.files).forEach((d) => {
                    const filemetaData: GoogleTypeFolder = {
                        name: d.name,
                        mimeType: d.type,
                        parents: [id],
                    };
                    const file: FormDataType = {
                        metadata: filemetaData,
                        file: d,
                    };
                    setFormDataGoogle((prevState) => [...prevState, file]);
                });
            }
        }
    }
    function handleCreateFolder() {
        if (checkGoogleAuth) {
            setIsCreateFolder(true);
        } else if (!checkGoogleAuth) {
            setIsNotify(true);
        }
    }

    async function uploadFile(
        folders: TreeFolder,
        res: any,
        acceptedFiles: FileList,
        parentName?: string
    ) {
        setFormDataGoogle([]);
        if (folders.children.length) {
            await uploadFolder(folders, res.id, acceptedFiles, parentName);
        } else {
            const path = pathFile + '/' + folders.name;
            Array.from(acceptedFiles).forEach((d: any) => {
                if (d.webkitRelativePath.includes(path)) {
                    const filemetaData: GoogleTypeFolder = {
                        name: d.name,
                        mimeType: d.type,
                        parents: [res?.id],
                    };
                    const file: FormDataType = {
                        metadata: filemetaData,
                        file: d,
                    };
                    setFormDataGoogle((prevState) => [...prevState, file]);
                }
            });
        }
    }

    async function uploadFolder(
        folders: TreeFolder,
        id: string,
        acceptedFiles: FileList,
        parentName?: string
    ) {
        if (folders.name) {
            const newFolderGoogle = {
                mimeType: 'application/vnd.google-apps.folder',
                name: folders.name,
                parents: [id],
            };
            const res = await googleMiddleware.uploadNewFolderGoogleDrive(
                GoogleDispatch,
                newFolderGoogle
            );
            if (pathFile) {
                pathFile = parentName + '/' + folders.name;
            } else {
                pathFile = folders.name;
            }

            folders.children.map(async (d: any) => {
                await uploadFile(d, res, acceptedFiles, folders.name);
            });
        }
    }

    async function handleFolder(folder: any) {
        if (folder.target.files?.length) {
            setFormDataGoogle([]);
            let id = 'root';
            const pathName = location.pathname;
            if (
                pathName.includes('workspace-drive') &&
                WorkspaceDetailInformation.drive_default_path
            ) {
                id = WorkspaceDetailInformation.drive_default_path;
            } else if (param.folderId) {
                id = param.folderId;
            }
            // setUploadAction(UPLOAD_FOLDER);
            googleMiddleware.actionUpload(GoogleDispatch, UPLOAD_FOLDER);
            const theFiles: FileUpload[] = folder.target.files;
            const relativePath = theFiles[0].webkitRelativePath;
            const folderName = relativePath.split('/');

            const acceptedFiles = folder?.target?.files;

            const paths: any[] = [];
            const glob = {
                name: undefined,
                children: [],
                files: acceptedFiles,
            };
            const symbol = '/';
            const lookup = { [symbol]: glob };

            if (acceptedFiles) {
                Array.from(acceptedFiles).forEach((d: any) => {
                    paths.push(d.webkitRelativePath);
                });

                paths.forEach(function (path) {
                    path.split('/')
                        .slice(0)
                        .reduce((dir: any, sub: any) => {
                            if (!dir[sub]) {
                                const subObj = { name: sub, children: [] };
                                dir[symbol].children.push(subObj);
                                return (dir[sub] = { [symbol]: subObj });
                            }
                            return dir[sub];
                        }, lookup);
                });

                leftMenuMiddleware.setFolderName(
                    leftMenuDispatch,
                    folderName[0]
                );
                if (glob?.children.length) {
                    await glob.children.map((d: any) => {
                        uploadFolder(d, id, acceptedFiles);
                    });
                }
            }
        }
    }

    function handleCheckGoogleConnect(item: any) {
        if (checkGoogleAuth) {
            item.click();
        } else if (!checkGoogleAuth) {
            setIsNotify(true);
        }
    }
    function handleFunction(item: string) {
        switch (item) {
            case 'createFolder':
                handleCreateFolder();
                break;
            case 'uploadFolder':
                handleCheckGoogleConnect(folder.current);
                break;
            case 'uploadFile':
                handleCheckGoogleConnect(file.current);
                break;

            default:
                break;
        }
    }

    return (
        <>
            <div className='SubMenuNavigation bg-blue-50 rounded-b-lg'>
                <input
                    ref={file}
                    type="file"
                    onChange={(e) => handleFileInput(e)}
                    className="hidden"
                    multiple
                />
                <input
                    ref={folder}
                    type="file"
                    onChange={(e) => handleFolder(e)}
                    className="hidden"
                    webkitdirectory=""
                    mozdirectory=""
                    directory=""
                    multiple
                />
                {bar && (
                    <>
                        <nav
                            className="flex-1 px-ooolab_p_1_half space-y-1"
                            aria-label="Sidebar"
                        >
                            {bar.tab.map((item) =>
                                !item.subtabs ? (
                                    !item?.hide &&
                                    <Link
                                        key={item.name}
                                        to={item.href}
                                        className={classNames(
                                            item.current
                                                ? 'text-primary-500'
                                                : 'text-dark-300',
                                            'group w-full flex items-center p-3 text-xs rounded-md font-semibold hover:text-dark-100'
                                        )}
                                        onClick={() => {
                                            for (let i = 0; i < bar.tab.length; i++) {
                                                bar.tab[i].current = false;
                                            }
                                            (item.current = true)
                                        }}
                                    >
                                        <span>{item.name}</span>
                                    </Link>
                                ) : (
                                    <Disclosure
                                        as="div"
                                        key={item.name}
                                        className="space-y-1"
                                    >
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button
                                                    className={classNames(
                                                        item.current
                                                            ? 'bg-ooolab_gray_11 text-ooolab_dark_1'
                                                            : 'bg-white text-ooolab_dark_2 hover:bg-ooolab_gray_11 hover:text-ooolab_dark_1',
                                                        'group w-full flex items-center pr-ooolab_p_2 py-ooolab_p_2 text-left text-sm font-semibold focus:outline-none  focus:bg-ooolab_gray_11 focus:text-ooolab_dark_1'
                                                    )}
                                                >
                                                    <ChevronRightIcon
                                                        className={classNames(
                                                            open &&
                                                            'rotate-90  ',

                                                            'text-center ml-ooolab_m_3 mr-ooolab_m_3 flex-shrink-0 h-ooolab_h_3_i w-ooolab_w_3_i transform transition-colors ease-in-out duration-150'
                                                        )}
                                                    />
                                                    {item.name}
                                                </Disclosure.Button>
                                                <Disclosure.Panel className="space-y-1 ">
                                                    {item.subtabs.map(
                                                        (subItem) => (
                                                            !subItem?.hide &&
                                                            <div
                                                                key={
                                                                    subItem.name
                                                                }
                                                                onClick={() =>
                                                                    subItem.href
                                                                        ? history.push(
                                                                            subItem.href
                                                                        )
                                                                        : handleFunction(
                                                                            subItem.onFetch
                                                                        )
                                                                }
                                                                className="group w-full flex items-center pl-ooolab_p_10 pr-ooolab_p_2 cursor-pointer py-ooolab_p_2 text-ooolab_10px font-medium text-ooolab_dark_2  hover:text-ooolab_dark_1 hover:bg-ooolab_gray_11"
                                                            >
                                                                {subItem.name}
                                                            </div>
                                                        )
                                                    )}
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                )
                            )}
                        </nav>
                    </>
                )}
                {isCreateFolder && (
                    <CreateFolder
                        isCreateFolder={isCreateFolder}
                        setIsCreateFolder={setIsCreateFolder}
                    />
                )}
                {isNotify && (
                    <Notification
                        isNotify={isNotify}
                        setIsNotify={setIsNotify}
                    />
                )}
            </div>
        </>
    );
};

export default SubMenuNavigation;
