import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from 'contexts/User/UserContext';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { SessionServiceFormat } from 'constant/util.const';
import '_shared/components/Calendar/Calendar.scss';
import ModalSessionDetail from 'components/Workspace/Course/CourseBuilder/components/CourseScheduling/Calendar/components/ModalSessionDetail/ModalSessionDetail';
import { SettingsIcon } from 'assets/icon/SettingIcon';
import { useAppSelector } from 'hooks/hooks';
import workspaceService from 'services/workspace.service';
import MultipleSession from 'components/Workspace/Course/CourseBuilder/components/CourseScheduling/Calendar/components/MultipleSession';
import AloneSession from 'components/Workspace/Course/CourseBuilder/components/CourseScheduling/Calendar/components/AloneSession';

const headerToolbar = {
    left: 'today prev,next title',
    right: 'timeGridDay,timeGridWeek,dayGridMonth',
};

export default function Calendar() {
    const [eventsCalendar, setEventsCalendar] = useState<any[]>();
    const {
        userState: { result },
    } = useContext(UserContext);
    const calendarRef: any = useRef<FullCalendar>(null!);
    // expandedMenu
    const { expandedMenu } = useAppSelector(state => state.configPage)
    const [calendarApi, setCalendarApi] = useState<any>();

    const [paramsCalendar, setParamsCalendar] = useState<any>({
        skip_pagination: true,
    });
    const [viewType, setViewType] = useState('');
    const [loading, setLoading] = useState(false);
    const params = useParams<{ id: string; userId: string }>();
    const workspaceId = params?.id;
    const userId = params?.userId;

    const [sessionDetail, setSessionDetail] = useState<any>(undefined);
    const [isOpenModalViewSessionDetail, setOpenModalViewSessionDetail] = useState(false);

    useEffect(() => {
        if (calendarApi) {
            calendarApi?.changeView(viewType);
        }
    }, [expandedMenu]);

    useEffect(() => {
        if (calendarRef) {
            setCalendarApi(calendarRef.current.getApi());
        }
    }, [calendarRef]);

    const getSessions = async (params?: any) => {
        setLoading(true);
        const paramsRequest = {
            ...params,
            studentId: userId,
        };
        const res = await workspaceService.getSessionOfLearner(workspaceId, userId, paramsRequest, true);
        setLoading(false);
        return res?.data;
    };

    const fetchEvents = async (params) => {
        const resCalendar = await getSessions(params);
        setEventsCalendar(
            resCalendar?.items?.map((item) => {
                return {
                    ...item,
                    start: dayjs(item?.start_time).tz(result?.time_zone).format(SessionServiceFormat),
                    end: dayjs(item?.end_time).tz(result?.time_zone)?.format(SessionServiceFormat),
                    initData: item
                };
            }) || []
        );
    };

    const renderDayHeaderContent = ({ date, isToday, view }) => {
		if (view.type === 'dayGridMonth') return <span className='text-gray-500'>{dayjs(date).format('ddd')}</span>;
		return (
			<>
				<p className='text-gray-500'>{dayjs(date).format('ddd')}</p>
				<p className={`header-day ${isToday ? 'header-today' : ''}`}>{dayjs(date).format('DD')}</p>
			</>
		);
	};

    useEffect(() => {
        fetchEvents({ ...paramsCalendar });
    }, [paramsCalendar]);

    return (
        <div>
            <div className="Calendar relative">
                {viewType !== 'dayGridMonth' && (
                    <div className="absolute w-[48px] text-center top-[85px] left-0 text-sm">
                        <button
                            type="button"
                            className="text-primary-500 hover:opacity-80 block text-center m-auto z-1"
                            onClick={() => window.open(`/workspace/${workspaceId}/user-setting`, '_blank')}
                            title={''}
                        >
                            <SettingsIcon />
                        </button>
                        <span className="text-xs">UTC{dayjs(new Date()).tz(result?.time_zone).format('Z').slice(0, 3)}</span>
                    </div>
                )}
                {
                    /*@ts-ignore*/
                    <FullCalendar
                        ref={calendarRef}
                        allDaySlot={false}
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        headerToolbar={headerToolbar}
                        eventClick={(data) => {
                            setSessionDetail([data?.event]);
                            setOpenModalViewSessionDetail(true);
                        }}
                        events={eventsCalendar}
                        eventContent={(eventInfo) => {
                            return (
                                <AloneSession
                                    id={eventInfo?.event?.id}
                                    startTime={eventInfo?.event?.extendedProps?.start_time}
                                    endTime={eventInfo?.event?.extendedProps?.end_time}
                                />
                            );
                        }}
                        locale="en"
                        dayMaxEvents={1}
                        forceEventDuration={true}
                        moreLinkContent={(content: any) => {
                            if (content?.num === 0) return;
                            return <MultipleSession numberOfSession={content?.num} />;
                        }}
                        slotLabelFormat={{
                            hour: '2-digit',
                            minute: '2-digit',
                            omitZeroMinute: false,
                            meridiem: false,
                            hour12: false
                        }}
                        timeZoneParam={result.time_zone}
                        dayHeaderFormat={{ weekday: 'short' }}
                        dayHeaderContent={renderDayHeaderContent}
                        moreLinkClick={(value: any) => {
                            setSessionDetail(value?.allSegs?.map((item) => item?.event));
                            setOpenModalViewSessionDetail(true);
                        }}
                        timeZone={result?.time_zone}
                        datesSet={(arg) => {
                            const { startStr, endStr, view } = arg;
                            setParamsCalendar({
                                start_after: dayjs(startStr).format(),
                                end_before: dayjs(endStr).format(),
                                skip_pagination: true,
                            });
                            setViewType(view?.type);
                        }}
                        nowIndicator
                        slotMinTime="06:00:00"
                        slotMaxTime="30:00:00"
                    />
                }
            </div>

            {isOpenModalViewSessionDetail && (
                <ModalSessionDetail
                    sessions={sessionDetail}
                    isOpen={isOpenModalViewSessionDetail}
                    refreshData={() => fetchEvents(paramsCalendar)}
                    onClose={() => {
                        setOpenModalViewSessionDetail(false);
                        setSessionDetail(undefined);
                    }}
                />
            )}
        </div>
    );
}
