import { FC, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { EditIcon } from 'assets/icon/EditIcon';
import ActionsButton from 'components/ActionsButton';
import { TrashIcon } from '@heroicons/react/outline';
import { RefreshSVG, StatusIcon } from 'assets/icon';
import { USER_STATUS } from '../constant';
import ModalResendIntiveMember from '../ModalAction/ModalResendIntiveMember';
import ModalActivateMember from '../ModalAction/ModalActivateMember';
import ModalDeactivateMember from '../ModalAction/ModalDeactivateMember';
import ModalDeleteMember from '../ModalAction/ModalDeleteMember';
import { checkPermission } from 'utils/commonFun';
import workspaceService from 'services/workspace.service';
import { useBoolean } from 'hooks/custom';
import DialogComponent from 'components/Dialog/DialogComponent';
import { defaultRole, roleType } from 'components/Management/RolesPermissions/RolesPermissionsContants';
import userService from 'services/user.service';
import { Toastify } from '_shared';
import ErrorIcon from 'assets/icon/ErrorIcon';
import { SESSION_STATUS } from 'constant/course.const';

interface ActionMenuProp {
    data: any;
    refreshData: Function;
    position?: string;
}

const ActionMenu: FC<ActionMenuProp> = ({ data, refreshData, position }) => {
    const params: { id } = useParams();
    const { t } = useTranslation();
    const history = useHistory();

    const [isOpenModalDelete, setOpenModalDelete] = useState(false);
    const [isOpenModalInactive, setOpenModalInactive] = useState(false);
    const [isOpenModalActive, setOpenModalActive] = useState(false);
    const [isOpenModalResendInvite, setOpenModalResendInvite] = useState(false);
    const { booleanValue: modalNotAlowChangeStatus, toggleBooleanValue: toggleModalNotAlowChangeStatus } = useBoolean();
    const [isOpenModalCannotDeactivatedSuperAdmin, setIsOpenModalCannotDeactivatedSuperAdmin] = useState(false);
    const [isOpenModalCannotDeactivatedInstructor, setIsOpenModalCannotDeactivatedInstructor] = useState(false);

    const handleChangeStatusUser = () => {
        const status = data?.membership?.status;
        if (status === USER_STATUS?.ACTIVE ||
            data?.membership?.status === USER_STATUS.INVITE ||
            data?.membership?.status === USER_STATUS.REGISTERED) {
            setOpenModalInactive(true);
        }
        if (status === USER_STATUS?.DEACTIVATE) {
            setOpenModalActive(true);
        }
    };

    const preCheckAllChangeStatus = async () => {
        let status = data?.membership?.status;
        if (status === USER_STATUS?.ACTIVE ||
            data?.membership?.status === USER_STATUS.INVITE ||
            data?.membership?.status === USER_STATUS.REGISTERED) {
            status = USER_STATUS.DEACTIVATE
        }
        if (status === USER_STATUS?.DEACTIVATE) {
            status = USER_STATUS.ACTIVE
        }
        const { data: { allow_change_status = false } } = await workspaceService.preCheckBeforeChangeStatusOfUser(params?.id, data?.id, {
            status
        })
        if (allow_change_status) {
            handleChangeStatusUser()
        }
        else {
            toggleModalNotAlowChangeStatus();
        }
    }

    const preCheckSuperAdminRole = () => {
        userService.preCheckUserWorkspace(params?.id, data?.id).then((res) => {
            if (res?.data?.allow_change_status) {
                handleChangeStatusUser()
            }
            else {
                setIsOpenModalCannotDeactivatedSuperAdmin(true);
            }
        }).catch(() => {
            Toastify.error()
        })
    }

    const preCheckDeactivatedInstructor = () => {
        userService.preCheckUserInstructor(params?.id, data?.id)
            .then((res) => {
                if (res?.data?.has_on_up_session) {
                    setIsOpenModalCannotDeactivatedInstructor(true);
                } else {
                    handleChangeStatusUser();
                }
            }).catch(() => {
                Toastify.error();
            });
    }

    const handleDeactivateAction = () => {
        if (data?.membership?.user_role?.name === defaultRole.superAdmin && data?.membership?.user_role?.role_type === roleType.default) {
            preCheckSuperAdminRole();
        } else if (data?.membership?.type === 'student' && data?.membership?.status !== USER_STATUS.DEACTIVATE) {
            preCheckAllChangeStatus();
        } else if (data?.membership?.type === 'instructor' && data?.membership?.status !== USER_STATUS.DEACTIVATE) {
            preCheckDeactivatedInstructor();
        } else {
            handleChangeStatusUser();
        }
    };

    const onViewLearnerTab = () => {
        window.open(`/workspace/${params?.id}/management/users/${data?.id}?tab=enrollment&statuses=active,enrolled`, '_blank')
    }

    return (
        <>
            <ActionsButton
                styleOptions={`${position || 'top-0'} right-[35px]`}
                menus={[
                    {
                        label: t('EDIT'),
                        icon: <EditIcon />,
                        action: async () => history.push(`/workspace/${params?.id}/management/users/${data?.membership?.user_id}?tab=information`),
                        class: 'border-b border-gray-300',
                        hide: !checkPermission('user', 'R')
                    },
                    {
                        label: t('ACTIVATE'),
                        icon: <StatusIcon />,
                        isDisabled: false,
                        action: () => {
                            setOpenModalActive(true);
                        },
                        class: 'border-b border-gray-300',
                        hide: !checkPermission('user', 'DA') || data?.membership?.status === USER_STATUS.ACTIVE ||
                            data?.membership?.status === USER_STATUS.INVITE || data?.membership?.status === USER_STATUS.REGISTERED
                    },
                    {
                        label: t('RESEND'),
                        icon: <RefreshSVG />,
                        isDisabled: false,
                        action: () => setOpenModalResendInvite(true),
                        class: 'border-b border-gray-300',
                        hide: data?.membership?.status !== USER_STATUS.INVITE || !checkPermission('user', 'C')
                    },
                    {
                        label: t('DEACTIVATE'),
                        icon: <StatusIcon />,
                        isDisabled: false,
                        action: () =>  handleDeactivateAction(),
                        class: 'border-b border-gray-300',
                        hide: !checkPermission('user', 'DA') || data?.membership?.status === USER_STATUS.DEACTIVATE
                    },
                    {
                        label: t('DELETE'),
                        icon: <TrashIcon width="18" height="16" className="text-gray-900" />,
                        action: () => {
                            setOpenModalDelete(true);
                        },
                        hide: !checkPermission('user', 'D') || true
                    },
                ]}
            />
            {isOpenModalDelete && (
                <ModalDeleteMember
                    isOpen={isOpenModalDelete}
                    onClose={() => setOpenModalDelete(false)}
                    refreshData={refreshData}
                    data={data}
                />
            )}

            {isOpenModalInactive && (
                <ModalDeactivateMember
                    isOpen={isOpenModalInactive}
                    onClose={() => setOpenModalInactive(false)}
                    refreshData={refreshData}
                    data={data}
                />
            )}

            {isOpenModalActive && (
                <ModalActivateMember
                    isOpen={isOpenModalActive}
                    onClose={() => setOpenModalActive(false)}
                    refreshData={refreshData}
                    data={data}
                />
            )}

            {isOpenModalResendInvite && (
                <ModalResendIntiveMember
                    isOpen={isOpenModalResendInvite}
                    onClose={() => setOpenModalResendInvite(false)}
                    refreshData={refreshData}
                    data={data}
                />
            )}

            {modalNotAlowChangeStatus && (
                <DialogComponent
                    isOpen={modalNotAlowChangeStatus}
                    title={t('DEACTIVATE')}
                    onCloseModal={toggleModalNotAlowChangeStatus}
                    maskClosable={false}
                    child={
                        <div className='mt-2'>
                            <div className="text-sm">
                                {t("USER.DEACTIVATE_MESSAGE")}
                            </div>
                            <div className="text-sm mt-2">
                                {t("USER.CLICK")} <span onClick={onViewLearnerTab} className='cursor-pointer opacity-100 hover:opacity-75 text-primary-500 '>{t("USER.HERE")}</span> {t("USER.TO_VIEW_ENROLLMENT_LIST")}
                            </div>
                        </div>
                    }
                />
            )}

            {isOpenModalCannotDeactivatedSuperAdmin && (
                <DialogComponent
                    isOpen={isOpenModalCannotDeactivatedSuperAdmin}
                    title={<div className='flex items-center gap-2 '>
                        <div className='flex-none w-5'>
                            <ErrorIcon width={20} height={20} />
                        </div>
                        {t('USER.UNABLE_TO_DEACTIVATE_SUPER_ADMIN')}
                    </div>}
                    onCloseModal={() => {
                        setIsOpenModalCannotDeactivatedSuperAdmin(false);
                    }}
                    width=" !rounded-lg"
                    maskClosable={false}
                    stylesTitle=" text-base"
                    child={
                        <div className='mt-2'>
                            <div className="text-sm mt-2">
                                {t('USER.MSG_UNABLE_TO_DEACTIVATE')}
                            </div>
                            <div className="text-sm mt-4 mb-2">
                                {t('USER.SUB_MSG_UNABLE_TO_DEACTIVATE')}
                            </div>
                        </div>
                    }
                />
            )}
            {isOpenModalCannotDeactivatedInstructor && (
                <DialogComponent
                    isOpen={isOpenModalCannotDeactivatedInstructor}
                    title={
                        <div className="flex items-center gap-2">
                            <div className="flex-none w-5">
                                <ErrorIcon width={20} height={20} />
                            </div>
                            {t('USER.UNABLE_TO_DEACTIVATE_INSTRUCTOR')}
                        </div>
                    }
                    onCloseModal={() => setIsOpenModalCannotDeactivatedInstructor(false)}
                    child={
                        <div className="mt-2 text-sm">
                            <div className="mt-2">{t('USER.UNABLE_TO_DEACTIVATE_INSTRUCTOR_MSG')}</div>
                            <div className='mt-3'>
                                <Trans i18nKey="USER.UNABLE_TO_DEACTIVATE_INSTRUCTOR_CLICK_LINK">
                                    <span
                                        className='text-enterprise underline cursor-pointer'
                                        onClick={() => {
                                            history.push(`/workspace/${params.id}/management/sessions?tab=sessions-list`, {
                                                instructor_id: [
                                                    {
                                                        label: data?.display_name,
                                                        name: data?.display_name,
                                                        value: data?.id,
                                                    },
                                                ],
                                                session_status: [SESSION_STATUS(t)[0], SESSION_STATUS(t)[1]],
                                            });
                                        }}
                                    ></span>
                                </Trans>
                            </div>
                        </div>
                    }
                />
            )}
        </>
    );
};

export default ActionMenu;
