import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DownloadIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { ButtonPrimary } from '_shared';
import {
    tableCreditPackage,
    tableInvoice,
    tableLearnerInformation,
    tableSessionPerformance,
    handleDownloadCSVTable,
    TableNameType,
    tableTransactionsCreditAccount,
    tableTransactionsCreditBalance,
    tableGrade,
} from './downloadConstant';
import { TooltipCustom } from 'components/Tooltip/Tooltip';
import DatePickerComponent from 'components/Reports/components/DatePicker';
import { UserContext } from 'contexts/User/UserContext';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import downloadPageService from 'services/reports/download-page.service';
import { checkPermission } from 'utils/commonFun';

export const TabLeanerData = () => {
    const { t } = useTranslation();
    const params: { id: string } = useParams();

    const maxDate = new Date(dayjs().subtract(1, 'day').format('YYYY-MM-DD'));
    const MAX_FILTER_DATE = 30; // startDate + 30 = 31 days

    const {
        userState: { result },
    } = useContext(UserContext);
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: workspaceDetailInformation } = getWorkspaceDetailState;

    const [loadingDownloadInformation, setLoadingDownloadInformation] = useState<boolean>(false);
    const [loadingDownloadSession, setLoadingDownloadSession] = useState<boolean>(false);
    const [loadingDownloadCredit, setLoadingDownloadCredit] = useState<boolean>(false);
    const [loadingDownloadInvoice, setLoadingDownloadInvoice] = useState<boolean>(false);
    const [loadingDownloadTransactionsCreditAccount, setLoadingDownloadTransactionsCreditAccount] = useState<boolean>(false);
    const [loadingDownloadTransactionsCreditBalance, setLoadingDownloadTransactionsCreditBalance] = useState<boolean>(false);
    const [loadingDownloadGrade, setLoadingDownloadGrade] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState([
        new Date(dayjs(maxDate).subtract(MAX_FILTER_DATE, 'day').format('YYYY-MM-DD')),
        maxDate,
    ]);

    const workspaceId = params.id;
    const [startDate, endDate] = dateRange;

    const translator = (str: string, opt?: any) => t(`DOWNLOAD_DATA.${str}`, opt);

    const onDownloadCsv = async (tableName: TableNameType) => {
        try {
            let data = [];
            let fileName = `${dayjs(startDate).format('DD-MM-YYYY')} to ${dayjs(endDate).format('DD-MM-YYYY')} (${result.time_zone != 'UTC' ? 'UTC' + dayjs().tz(result.time_zone).format('Z').replace(':00', '') : result.time_zone
                })`;
            let extendData = {};
            let setLoading: any = function () { };
            const start_time = dayjs(startDate).tz(result.time_zone).startOf('day').format('YYYY-MM-DDTHH:mm:ss.sssZ');
            const end_time = dayjs(endDate).tz(result.time_zone).endOf('day').format('YYYY-MM-DDTHH:mm:ss.sssZ');

            switch (tableName) {
                case 'learner-information': {
                    setLoadingDownloadInformation(true);
                    const response = await downloadPageService.getLearnersInformation(workspaceId, {
                        start_time,
                        end_time,
                        skip_pagination: true,
                    });
                    data = response?.data?.items || [];
                    fileName = 'Learners_Created Date ' + fileName;
                    extendData = {
                        currency: workspaceDetailInformation.default_currency?.symbol || '',
                        tz: result?.time_zone || 'UTC',
                    };
                    setLoading = setLoadingDownloadInformation;
                    break;
                }
                case 'session-performance': {
                    setLoadingDownloadSession(true);
                    const response = await downloadPageService.getLearnersSessions(workspaceId, {
                        start_time,
                        end_time,
                        skip_pagination: true,
                    });
                    data = response?.data?.items || [];
                    fileName = 'Sessions_Date ' + fileName;
                    extendData = { tz: result?.time_zone || 'UTC' };
                    setLoading = setLoadingDownloadSession;
                    break;
                }
                case 'credit-package': {
                    setLoadingDownloadCredit(true);
                    const response = await downloadPageService.getLearnersCreditPackages(workspaceId, {
                        start_time,
                        end_time,
                        skip_pagination: true,
                    });
                    data = response?.data?.items || [];
                    fileName = 'Credits_Created Date ' + fileName;
                    extendData = { tz: result?.time_zone || 'UTC' };
                    setLoading = setLoadingDownloadCredit;
                    break;
                }
                case 'invoice': {
                    setLoadingDownloadInvoice(true);
                    const response = await downloadPageService.getLearnersInvoices(workspaceId, {
                        start_time,
                        end_time,
                        skip_pagination: true,
                    });
                    data = response?.data?.items || [];
                    fileName = 'Invoices_Created Date ' + fileName;
                    extendData = { translator: t, tz: result?.time_zone || 'UTC' };
                    setLoading = setLoadingDownloadInvoice;
                    break;
                }
                case 'transactions-credit-account': {
                    setLoadingDownloadTransactionsCreditAccount(true);
                    const response = await downloadPageService.getTransactionsCreditAccount(workspaceId, {
                        start_time,
                        end_time,
                        skip_pagination: true,
                    });
                    data = response?.data?.items || [];
                    fileName = 'Credit Account Transactions_Created Date ' + fileName;
                    extendData = { tz: result?.time_zone || 'UTC' };
                    setLoading = setLoadingDownloadTransactionsCreditAccount;
                    break;
                }
                case 'transactions-credit-balance': {
                    setLoadingDownloadTransactionsCreditBalance(true);
                    const response = await downloadPageService.getTransactionsCreditBalance(workspaceId, {
                        start_time,
                        end_time,
                        skip_pagination: true,
                    });
                    data = response?.data?.items || [];
                    fileName = 'Credit Balance Transactions_Created Date ' + fileName;
                    extendData = { tz: result?.time_zone || 'UTC' };
                    setLoading = setLoadingDownloadTransactionsCreditBalance;
                    break;
                }
                case 'grade': {
                    setLoadingDownloadGrade(true);
                    const response = await downloadPageService.getGrade(workspaceId, {
                        start_time,
                        end_time,
                        skip_pagination: true,
                    });
                    data = response?.data?.items || [];
                    fileName = 'Grades_Create Date ' + fileName;
                    extendData = { translator: t, tz: result?.time_zone || 'UTC' };
                    setLoading = setLoadingDownloadGrade;
                    break;
                }
                default:
                    break;
            }

            handleDownloadCSVTable({
                data,
                table: tableName,
                fileName,
                extendData,
                setLoading,
            });
        } catch (error) {
            setLoadingDownloadInformation(false);
            setLoadingDownloadSession(false);
            setLoadingDownloadCredit(false);
            setLoadingDownloadInvoice(false);
            setLoadingDownloadTransactionsCreditAccount(false);
            setLoadingDownloadTransactionsCreditBalance(false);
            setLoadingDownloadGrade(false);
        }
    };

    return (
        <div className="p-5 bg-white rounded-2xl shadow-card_common">
            <div className="flex justify-between items-center">
                <h1 className="text-xl font-semibold">{translator('LEARNERS_DATA')}</h1>
                <div className="relative min-w-[230px]">
                    <DatePickerComponent
                        onChange={setDateRange}
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={maxDate}
                        filterDate={(date) => {
                            if (date > maxDate) return false;
                            if (startDate) {
                                const maxEndDate = new Date(dayjs(startDate).add(MAX_FILTER_DATE, 'day').format('YYYY-MM-DD'));
                                if (date > maxEndDate) return false;
                            }
                            return true;
                        }}
                        className="!rounded !px-2.5"
                    />
                </div>
            </div>
            <>
                <div className="my-4 border-b" />
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold flex items-center space-x-2">
                        <span>{translator('Learner Information')}</span>
                        <InformationCircleIcon
                            className="w-5 h-5 cursor-pointer"
                            data-for="LEANER_INFORMATION_TOOLTIP"
                            data-tip={translator('LEANER_INFORMATION_TOOLTIP')}
                        />
                        <TooltipCustom
                            classNameContainer="shadow-medium"
                            id="LEANER_INFORMATION_TOOLTIP"
                            content={translator('LEANER_INFORMATION_TOOLTIP')}
                            place="bottom"
                            type="light"
                        />
                    </h2>
                    {checkPermission('exportLearnerDashboard', 'DL') && (
                        <ButtonPrimary
                            type="button"
                            size="M"
                            disabled={loadingDownloadInformation}
                            onClick={() => onDownloadCsv('learner-information')}
                        >
                            {loadingDownloadInformation ? (
                                <CircleSpin className="!m-0" />
                            ) : (
                                <DownloadIcon className="w-5 h-5 text-white" />
                            )}
                            <span>{translator('Download')}</span>
                        </ButtonPrimary>
                    )}
                </div>
                <div className="bg-gray-50 rounded-lg p-4 mt-3 text-sm font-semibold">
                    <p className="text-gray-500">{translator('Sample Data')}</p>
                    <div className="mt-2 overflow-auto">
                        <div className="flex items-center">
                            {tableLearnerInformation.map((item, index) => (
                                <div key={index}>
                                    <p className="bg-blue-50 px-2.5 py-2" style={{ width: `${item.width}px` }}>
                                        {item.columnName}
                                    </p>
                                    <p className="bg-white font-normal px-2.5 py-2" style={{ width: `${item.width}px` }}>
                                        {item.value}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>
            <>
                <div className="my-4 border-b" />
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold flex items-center space-x-2">
                        <span>{translator('Session Performance')}</span>
                        <InformationCircleIcon
                            className="w-5 h-5 cursor-pointer"
                            data-for="SESSION_PERFORMANCE_TOOLTIP"
                            data-tip={translator('SESSION_PERFORMANCE_TOOLTIP')}
                        />
                        <TooltipCustom
                            classNameContainer="shadow-medium"
                            id="SESSION_PERFORMANCE_TOOLTIP"
                            content={translator('SESSION_PERFORMANCE_TOOLTIP')}
                            place="bottom"
                            type="light"
                        />
                    </h2>
                    {checkPermission('exportLearnerDashboard', 'DL') && (
                        <ButtonPrimary
                            type="button"
                            size="M"
                            disabled={loadingDownloadSession}
                            onClick={() => onDownloadCsv('session-performance')}
                        >
                            {loadingDownloadSession ? (
                                <CircleSpin className="!m-0" />
                            ) : (
                                <DownloadIcon className="w-5 h-5 text-white" />
                            )}
                            <span>{translator('Download')}</span>
                        </ButtonPrimary>
                    )}
                </div>
                <div className="bg-gray-50 rounded-lg p-4 mt-3 text-sm font-semibold">
                    <p className="text-gray-500">{translator('Sample Data')}</p>
                    <div className="mt-2 overflow-auto">
                        <div className="flex items-center">
                            {tableSessionPerformance.map((item, index) => (
                                <div key={index}>
                                    <p className="bg-blue-50 px-2.5 py-2" style={{ width: `${item.width}px` }}>
                                        {item.columnName}
                                    </p>
                                    <p className="bg-white font-normal px-2.5 py-2" style={{ width: `${item.width}px` }}>
                                        {item.value}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>
            {/* <>
                <div className="my-4 border-b" />
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold flex items-center space-x-2">
                        <span>{translator('Credit Package')}</span>
                        <InformationCircleIcon
                            className="w-5 h-5 cursor-pointer"
                            data-for="CREDIT_PACKAGE_TOOLTIP"
                            data-tip={translator('CREDIT_PACKAGE_TOOLTIP')}
                        />
                        <TooltipCustom
                            classNameContainer="shadow-medium"
                            id="CREDIT_PACKAGE_TOOLTIP"
                            content={translator('CREDIT_PACKAGE_TOOLTIP')}
                            place="bottom"
                            type="light"
                        />
                    </h2>
                    {checkPermission('exportLearnerDashboard', 'DL') && (
                        <ButtonPrimary
                            type="button"
                            size="M"
                            disabled={loadingDownloadCredit}
                            onClick={() => onDownloadCsv('credit-package')}
                        >
                            {loadingDownloadCredit ? <CircleSpin className="!m-0" /> : <DownloadIcon className="w-5 h-5 text-white" />}
                            <span>{translator('Download')}</span>
                        </ButtonPrimary>
                    )}
                </div>
                <div className="bg-gray-50 rounded-lg p-4 mt-3 text-sm font-semibold">
                    <p className="text-gray-500">{translator('Sample Data')}</p>
                    <div className="mt-2 overflow-auto">
                        <div className="flex items-center">
                            {tableCreditPackage.map((item, index) => (
                                <div key={index}>
                                    <p className="bg-blue-50 px-2.5 py-2" style={{ width: `${item.width}px` }}>
                                        {item.columnName}
                                    </p>
                                    <p className="bg-white font-normal px-2.5 py-2" style={{ width: `${item.width}px` }}>
                                        {item.value}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </> */}
            <>
                <div className="my-4 border-b" />
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold flex items-center space-x-2">
                        <span>{translator('INVOICE')}</span>
                        <InformationCircleIcon
                            className="w-5 h-5 cursor-pointer"
                            data-for="INVOICE_TOOLTIP"
                            data-tip={translator('INVOICE_TOOLTIP')}
                        />
                        <TooltipCustom
                            classNameContainer="shadow-medium"
                            id="INVOICE_TOOLTIP"
                            content={translator('INVOICE_TOOLTIP')}
                            place="bottom"
                            type="light"
                        />
                    </h2>
                    {checkPermission('exportLearnerDashboard', 'DL') && (
                        <ButtonPrimary
                            type="button"
                            size="M"
                            disabled={loadingDownloadInvoice}
                            onClick={() => onDownloadCsv('invoice')}
                        >
                            {loadingDownloadInvoice ? (
                                <CircleSpin className="!m-0" />
                            ) : (
                                <DownloadIcon className="w-5 h-5 text-white" />
                            )}
                            <span>{translator('Download')}</span>
                        </ButtonPrimary>
                    )}
                </div>
                <div className="bg-gray-50 rounded-lg p-4 mt-3 text-sm font-semibold">
                    <p className="text-gray-500">{translator('Sample Data')}</p>
                    <div className="mt-2 overflow-auto">
                        <div className="flex items-center">
                            {tableInvoice.map((item, index) => (
                                <div key={index}>
                                    <p className="bg-blue-50 px-2.5 py-2" style={{ width: `${item.width}px` }}>
                                        {item.columnName}
                                    </p>
                                    <p className="bg-white font-normal px-2.5 py-2" style={{ width: `${item.width}px` }}>
                                        {item.value}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>
            <>
                <div className="my-4 border-b" />
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold flex items-center space-x-2">
                        <span>{translator('TRANSACTIONS_OF_CREDIT_ACCOUNT')}</span>
                        <InformationCircleIcon
                            className="w-5 h-5 cursor-pointer"
                            data-for="TRANSACTIONS_CREDIT_ACCOUNT_TOOLTIP"
                            data-tip={translator('TRANSACTIONS_CREDIT_ACCOUNT_TOOLTIP')}
                        />
                        <TooltipCustom
                            classNameContainer="shadow-medium"
                            id="TRANSACTIONS_CREDIT_ACCOUNT_TOOLTIP"
                            content={translator('TRANSACTIONS_CREDIT_ACCOUNT_TOOLTIP')}
                            place="bottom"
                            type="light"
                        />
                    </h2>
                    {checkPermission('exportLearnerDashboard', 'DL') && (
                        <ButtonPrimary
                            type="button"
                            size="M"
                            disabled={loadingDownloadTransactionsCreditAccount}
                            onClick={() => onDownloadCsv('transactions-credit-account')}
                        >
                            {loadingDownloadTransactionsCreditAccount ? (
                                <CircleSpin className="!m-0" />
                            ) : (
                                <DownloadIcon className="w-5 h-5 text-white" />
                            )}
                            <span>{translator('Download')}</span>
                        </ButtonPrimary>
                    )}
                </div>
                <div className="bg-gray-50 rounded-lg p-4 mt-3 text-sm font-semibold">
                    <p className="text-gray-500">{translator('Sample Data')}</p>
                    <div className="mt-2 overflow-auto">
                        <div className="flex items-center">
                            {tableTransactionsCreditAccount.map((item, index) => (
                                <div key={index}>
                                    <p className="bg-blue-50 px-2.5 py-2" style={{ width: `${item.width}px` }}>
                                        {item.columnName}
                                    </p>
                                    <p className="bg-white font-normal px-2.5 py-2" style={{ width: `${item.width}px` }}>
                                        {item.value}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>
            <>
                <div className="my-4 border-b" />
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold flex items-center space-x-2">
                        <span>{translator('TRANSACTIONS_OF_CREDIT_BALANCE')}</span>
                        <InformationCircleIcon
                            className="w-5 h-5 cursor-pointer"
                            data-for="TRANSACTIONS_CREDIT_BALANCE_IN_COURSE_TOOLTIP"
                            data-tip={translator('TRANSACTIONS_CREDIT_BALANCE_IN_COURSE_TOOLTIP')}
                        />
                        <TooltipCustom
                            classNameContainer="shadow-medium"
                            id="TRANSACTIONS_CREDIT_BALANCE_IN_COURSE_TOOLTIP"
                            content={translator('TRANSACTIONS_CREDIT_BALANCE_IN_COURSE_TOOLTIP')}
                            place="bottom"
                            type="light"
                        />
                    </h2>
                    {checkPermission('exportLearnerDashboard', 'DL') && (
                        <ButtonPrimary
                            type="button"
                            size="M"
                            disabled={loadingDownloadTransactionsCreditBalance}
                            onClick={() => onDownloadCsv('transactions-credit-balance')}
                        >
                            {loadingDownloadTransactionsCreditBalance ? (
                                <CircleSpin className="!m-0" />
                            ) : (
                                <DownloadIcon className="w-5 h-5 text-white" />
                            )}
                            <span>{translator('Download')}</span>
                        </ButtonPrimary>
                    )}
                </div>
                <div className="bg-gray-50 rounded-lg p-4 mt-3 text-sm font-semibold">
                    <p className="text-gray-500">{translator('Sample Data')}</p>
                    <div className="mt-2 overflow-auto">
                        <div className="flex items-center">
                            {tableTransactionsCreditBalance.map((item, index) => (
                                <div key={index}>
                                    <p className="bg-blue-50 px-2.5 py-2" style={{ width: `${item.width}px` }}>
                                        {item.columnName}
                                    </p>
                                    <p className="bg-white font-normal px-2.5 py-2" style={{ width: `${item.width}px` }}>
                                        {item.value}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>
            <>
                <div className="my-4 border-b" />
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold flex items-center space-x-2">
                        <span>{translator('GRADE')}</span>
                        <InformationCircleIcon
                            className="w-5 h-5 cursor-pointer"
                            data-for="GRADE_TOOLTIP"
                            data-tip={translator('GRADE_TOOLTIP')}
                        />
                        <TooltipCustom
                            classNameContainer="shadow-medium"
                            id="GRADE_TOOLTIP"
                            content={translator('GRADE_TOOLTIP')}
                            place="bottom"
                            type="light"
                        />
                    </h2>
                    {checkPermission('exportLearnerDashboard', 'DL') && (
                        <ButtonPrimary type="button" size="M" disabled={loadingDownloadGrade} onClick={() => onDownloadCsv('grade')}>
                            {loadingDownloadGrade ? <CircleSpin className="!m-0" /> : <DownloadIcon className="w-5 h-5 text-white" />}
                            <span>{translator('Download')}</span>
                        </ButtonPrimary>
                    )}
                </div>
                <div className="bg-gray-50 rounded-lg p-4 mt-3 text-sm font-semibold">
                    <p className="text-gray-500">{translator('Sample Data')}</p>
                    <div className="mt-2 overflow-auto">
                        <div className="flex items-center">
                            {tableGrade.map((item, index) => (
                                <div key={index}>
                                    <p className="bg-blue-50 px-2.5 py-2" style={{ width: `${item.width}px` }}>
                                        {item.columnName}
                                    </p>
                                    <p className="bg-white font-normal px-2.5 py-2" style={{ width: `${item.width}px` }}>
                                        {item.value}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>
        </div>
    );
};
