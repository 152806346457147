import React from 'react';
import { classNames } from 'utils/commonFun';

interface HeaderPageProps {
    label: string;
    classes?: string;
    subLabel?: any;
}

const HeaderPage: React.FC<HeaderPageProps> = ({ label, classes, subLabel = undefined }) => {
    return (
        <h1 className={classNames('p-4 text-sm font-semibold text-gray-700 hover:text-gray-500 flex items-center', classes)}>
            {label}
            {subLabel}
        </h1>
    );
};

export default HeaderPage;
