import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Filter from './Components/Filter/Filter';
import { ButtonPrimary } from '_shared';
import { PlusIcon } from '@heroicons/react/outline';
import ModalAddReport from './Components/ModalAddReport';
import { useParams } from 'react-router-dom';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import courseService from 'services/course.service';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import Table from './Components/Table/Table';
import Pagination from 'components/V2/Pagination';
import debounce from 'lodash/debounce';
import './CourseReport.scss'

const defaulPagination = {
    page: 1,
    per_page: 20,
    order: 'desc',
    sort_by: 'released_at',
    total: 0,
};
export default function CourseReport() {
    const params: { id: string; courseId: string } = useParams();
    const { t: translator } = useTranslation();
    const [isOpenModalAddReport, setOpenModalAddReport] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [indexPage, setIndexPage] = useState('0-0');
    const [pagination, setPagination] = useState<any>(defaulPagination);
    const [filter, setFilter] = useState(undefined);
    const [order, setOrder] = useState('desc')

    const workspaceId = params.id;
    const courseId = params.courseId;

    const getData = (page: number = 1, filter = {}, order = 'desc') => {
        setLoading(true);
        const payload = {
            ...pagination,
            ...filter,
            page,
            order
        };
        delete payload?.total;
        courseService
            .getCustomReport(workspaceId, courseId, payload)
            .then((res) => {
                const { items, total, page } = res.data;
                setData(items);
                setPagination({ ...pagination, total, page });
                setIndexPage(getIndexPage(payload?.page, pagination?.per_page, items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const handlePagination = (page: number) => {
        getData(page);
    };

    const getDataDebounce = useCallback(
        debounce((page, filter, order) => getData(page, filter, order), 800),
        []
    );

    useEffect(() => {
        getDataDebounce(1, filter, order);
    }, [filter, order]);

    return (
        <div className="CourseReport">
            <div className="CourseReport-title">{translator('COURSES.REPORTS')}</div>
            <div className=" mt-5 flex justify-between">
                <div className='w-full'>
                    <Filter onChangeFilter={(filter) => setFilter(filter)} isFirstLoad={false} />
                </div>
                {(checkPermission('courseReport', 'U') || checkPermission('course', 'U')) &&
                    <ButtonPrimary type="button" onClick={() => setOpenModalAddReport(true)} className="flex gap-2 min-w-[80px] h-fit">
                        {' '}
                        <PlusIcon className="text-white w-5 h-5" /> {translator('ADD')}
                    </ButtonPrimary>
                }
            </div>
            <div className="CourseReport-main mt-5">
                <div className="wrapper-content">
                    <div className="scrollbar">
                        {loading && (
                            <div className="loading">
                                <CircleSpin color="text-primary-500" />
                            </div>
                        )}
                        <Table data={data} refreshData={getData} order={order} setOrder={setOrder} />
                    </div>
                    <Pagination
                        total={pagination.total}
                        per_page={pagination.per_page}
                        callback={handlePagination}
                        indexPage={indexPage}
                        page={pagination?.page}
                    />
                </div>
            </div>
            {isOpenModalAddReport && <ModalAddReport isOpen={isOpenModalAddReport} onClose={() => setOpenModalAddReport(false)} refreshData={() => getData(1, filter)} />}
        </div>
    );
}
