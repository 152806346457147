import NoImageJPG from 'assets/img/no-image.jpg';
import NoAvatarPNG from 'assets/img/avt-default.png';
import { classNames } from 'utils/commonFun';

export const ImageDefault = ({ imageSrc, className }: { imageSrc: string; className?: string }) => {
    return (
        <img
            src={imageSrc}
            alt="avatar"
            onError={(e: any) => (e.target.src = NoImageJPG)}
            className={classNames('w-6 h-6 rounded-full bg-center bg-contain object-cover', className)}
        />
    );
};

export const ImageAvatar = ({ imageSrc, className }: { imageSrc: string; className?: string }) => {
    return (
        <img
            src={imageSrc}
            alt="avatar"
            onError={(e: any) => (e.target.src = NoAvatarPNG)}
            className={classNames('w-6 h-6 rounded-full bg-center bg-contain object-cover', className)}
        />
    );
};
