import React from 'react';
import ManagementMasterPage from 'pages/RouteMasterPage';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';
import InvoiceList from 'components/Ecommerce/Billing/InvoiceList/InvoiceList';

const InvoiceListPage = () => {
    return (
        <ManagePermissionWrapper>
            <InvoiceList>
                <ManagementMasterPage />
            </InvoiceList>
        </ManagePermissionWrapper>
    );
};

export default InvoiceListPage;
