import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import paymentService from "services/payment.service";
import { ConfirmModal, Toastify } from "_shared";
import { SwitchToggle } from "_shared/components/Form/SwitchToggle/SwitchToggle";
import { PaymentType } from "../model";
import "./index.scss";
import { STATUS_PAYMENT } from "constant/course.const";

export const PaymentItem = ({values, onUpdate}:{values: PaymentType, onUpdate: (value: PaymentType) => void}) => {
    const {t} = useTranslation();
    const params: {id: string} = useParams();
    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`DASHBOARD.WORKSPACE_SETTING.PAYMENT_DETAILS.${str}`, opt));
    const [hasOpenConfirm, setHasOpenConfirm] = useState(false);
    const {
        control,
        setValue,
        watch
    } = useForm({ mode: 'onChange' });
    const status = watch('status');

    const handleConfirm = async (value: boolean) => {
        if (value) {
            try {
                const response = await paymentService.updatePaymentDetail(params.id, {id: values.id, status: status ? STATUS_PAYMENT[0] : STATUS_PAYMENT[1] })
                values.status = response?.data?.status;
                Toastify.success(t('UPATE_SUCCESS'));
            } catch (error) {
                
            }
        } else {
            setValue('status', !status)
        }
        setHasOpenConfirm(false);
    }

    useEffect(() => {
        setValue('status', values?.status == STATUS_PAYMENT[0] ? true : false);
    }, [values?.status]);

    return <article className={`paymentItem ${values?.status == STATUS_PAYMENT[1] ? 'bg-gray-10': ''}`}>
        <header className="paymentItem-header">
            <h2>{values?.company_name}</h2>
            <div className="paymentItem-action">
                <label className={`${values?.status == STATUS_PAYMENT[0] ? 'text-green-600': 'text-red-600'}`}>{translator(values?.status)}</label>
                <SwitchToggle
                    name="status"
                    control={control}
                    onClick={() => setHasOpenConfirm(true)}
                />
                <span className="paymentItem-line h-5"></span>
                <button className="text-primary-500" onClick={() => onUpdate(values)}>{translator("EDIT", true)}</button>
            </div>
        </header>
        <div className="paymentItem-info">
            <div className="w-1/2">
                <label>{translator('Bank_Name')}</label>
                <div className="paymentItem-value">{values?.bank_name}</div>
            </div>
            <span className="paymentItem-line h-6 mr-5"></span>
            <div className="w-1/2">
                <label>{translator('Account_Number')}</label>
                <div className="paymentItem-value">{values?.account_number}</div>
            </div>
        </div>
        {hasOpenConfirm ? <ConfirmModal 
            title={`${translator('Label_'+ values.status)}`} 
            textConfirm={translator('Button_' + values.status)}
            isOpen={hasOpenConfirm}
            onClose={handleConfirm}
        >
            {translator('MESSAGE_' + values.status)}
        </ConfirmModal> : null}
    </article>
}