import { forwardRef, useEffect, useState } from 'react';
import { TYPE_CREATE_CONTENT } from '../../constants';
import FileUploadIcon from 'assets/icon/FileUploadIcon';
import { useTranslation } from 'react-i18next';
import NEW_H5P from 'assets/img/h5p.png';
import LIBRARY from 'assets/img/library.png';
import H5P_LIBRARY from 'assets/img/h5pLibrary.png';
import { AddIcon } from 'assets/icon';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { Popover } from '@headlessui/react';
import lessonService from 'services/lesson.service';
import { useParams } from 'react-router-dom';
import { UUID, checkPermission } from 'utils/commonFun';
import './SupplementaryMaterial.scss';
import { usePopper } from 'react-popper';
import FileWithAction from '../DisclosureSection/DisclosurePanel/FileWithAction/FileWithAction';

function SupplementaryMaterials({
    sections = [],
    setSections,
    setSectionSelected,
    handleSelectContentToPreview,
    contentSelected,
    handleDeleteFileOfSection,
    typeSelect,
    setOnSaving,
    setCreateContentType,
    setOpenModalCreateH5p,
    setOpenModalLibrary,
}, ref) {
    const { t: translator } = useTranslation();
    const params: any = useParams();
    const [draggedContent, setDraggedContent] = useState<any>();
    const onDragContentStart = (e, index) => {
        setOnSaving(true);
        setDraggedContent(sections[0]?.section_contents?.[index]);
    };

    const onDragOverContent = (index) => {
        const draggedOverContent = sections[0]?.section_contents?.[index];
        if (draggedContent?.id === draggedOverContent?.id) {
            return;
        }
        let section_contents = sections[0]?.section_contents?.filter((item) => item?.id !== draggedContent?.id);
        section_contents?.splice(index, 0, draggedContent);
        const newSection = { ...sections[0], section_contents };
        const newSections = sections.map((section) => {
            if (section?.id === newSection?.id) return newSection;
            return section;
        });
        setSections(newSections);
    };

    const onDragContentEnd = () => {
        setOnSaving(true);
        lessonService
            .sortContent(params?.id, params?.lessonId, sections[0]?.id, {
                ids: sections[0]?.section_contents?.map((content) => content?.id),
            })
            .then((res) => {
                setDraggedContent(undefined);
                setOnSaving(false);
            });
    };

    const newSection = () => {
        setOnSaving(true);
        const newSection = {
            title: 'New Section ' + ((sections?.length || 0) + 1),
            order_number: (sections?.length || 0) + 1,
            type: typeSelect,
        };
        lessonService.createSection(params?.id, params?.lessonId, newSection).then((res) => {
            setSections([...sections, { ...newSection, id: res?.id, uuid: UUID() }]);
            setSectionSelected({ ...newSection, id: res?.id, uuid: UUID() });
            setOnSaving(false);
        });
    };

    useEffect(() => {
        if (sections?.[0]) setSectionSelected(sections[0]);
        else {
            if (checkPermission('libraryContent', 'U')) newSection();
        }
    }, []);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom',
        modifiers: [
            {
                name: 'flip',
                options: {
                    allowedAutoPlacements: ['top', 'bottom'], // by default, all the placements are allowed
                },
            },
        ],
    });
    return (
        <div className='supplementaryMaterial'>
            <div className="listContent custom-scrollbar">
                {sections[0]?.section_contents?.map((file, index) => {
                    return (
                        <FileWithAction
                            key={file.id}
                            file={file}
                            index={index}
                            contentSelected={contentSelected}
                            isSectionActive={true}
                            isStandalone={true}
                            sectionSelected={sections[0]}
                            handleSelectContentToPreview={handleSelectContentToPreview}
                            setSectionSelected={setSectionSelected}
                            sectionItem={sections[0]}
                            onDragOver={onDragOverContent}
                            onDragStart={onDragContentStart}
                            onDragEnd={onDragContentEnd}
                            translator={translator}
                            sections={sections}
                            setSections={setSections}
                            setOnSaving={setOnSaving}
                            handleDeleteFileOfSection={handleDeleteFileOfSection}
                            isShowNote={false}
                        />
                    );
                })}
            </div>

            <Popover className={`popover  ${!checkPermission('libraryContentLessonsOrScorm', 'U') ? 'hidden' : ''}`}>
                {({ open }) => (
                    <>
                        <Popover.Button
                            ref={setReferenceElement}
                            className={`${open ? '' : 'text-opacity-90'
                                } popover-btn disabled:cursor-not-allowed`}
                            disabled={!checkPermission('libraryContentLessonsOrScorm', 'U')}
                        >
                            <AddIcon />
                            <span>{translator('New Content')}</span>
                            <ChevronDownIcon className="icon" />
                        </Popover.Button>

                        <Popover.Panel
                            ref={setPopperElement}
                            style={styles.popper}
                            {...attributes.popper}
                            className="popover-panel"
                        >
                            <div className="listMenu">
                                <div
                                    onClick={() => {
                                        setCreateContentType(TYPE_CREATE_CONTENT.UPLOAD);
                                        ref?.current?.click()
                                    }}
                                    className="menuItem"
                                >
                                    <div className="menuItem-content">
                                        <FileUploadIcon />
                                        <div>{translator('LIBRARY.UPLOAD_FILE')}</div>
                                    </div>
                                </div>
                                <div
                                    onClick={() => {
                                        setCreateContentType(TYPE_CREATE_CONTENT.NEW_H5P);
                                        setOpenModalCreateH5p(true);
                                    }}
                                    className="menuItem"
                                >
                                    <div className="menuItem-content">
                                        <img src={NEW_H5P} />
                                        <div>{translator('LIBRARY.NEW_H5P')}</div>
                                    </div>
                                </div>

                                <div
                                    onClick={() => {
                                        setOpenModalLibrary(true);
                                        setCreateContentType(TYPE_CREATE_CONTENT.LIBRARY);
                                    }}
                                    className="menuItem"
                                >
                                    <div className="menuItem-content">
                                        <img src={LIBRARY} />
                                        <div>{translator('LIBRARY.LIBRARY')}</div>
                                    </div>
                                </div>
                                <div
                                    onClick={() => {
                                        setOpenModalLibrary(true);
                                        setCreateContentType(TYPE_CREATE_CONTENT.H5P_LIBRARY);
                                    }}
                                    className="menuItem"
                                >
                                    <div className="menuItem-content">
                                        <img src={H5P_LIBRARY} />
                                        <div>{translator('LIBRARY.H5P_LIBRARY')}</div>
                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    </>
                )}
            </Popover>
        </div>
    );
}

export default forwardRef(SupplementaryMaterials)
