import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ResourcesType, ResourcesTypeColor, timeList } from "constant/workspace.const";
import reportService from "services/reports/workspace-report.service";

import { ComboboxSelect } from "components/ComboboxSelect/ComboboxSelect";
import { CardContainer } from "components/Reports/components/CardContainer";
import { CardInfo, CardInfoProps } from "components/Reports/components/CardInfo";
import { ChartColumn, ColumnProps } from "components/Reports/components/ChartColumn/ChartColumn";
import { Title } from "components/Reports/components/Title";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { format1Decimal } from "utils/commonFun";
import { EmptyStateChart } from "_shared";
import DatePickerComponent from "components/Reports/components/DatePicker";
import moment from "moment";

export const Resources = () => {
    const { t } = useTranslation();
    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);
    const params: { id: string } = useParams();
    const [loading, setLoading] = useState(true);
    const [totalResourcesData, setTotalResourcesData] = useState<CardInfoProps>();
    const [resourcesData, setResourcesData] = useState<ColumnProps[]>();
    const [noData, setNoData] = useState(false);

    const getData = async () => {
        if (!startDate || !endDate) {
            return;
        }
        try {
            setNoData(false);
            setLoading(true);
            const payload = {
                start_time: moment(new Date(startDate)).format('YYYY-MM-DD')+ 'T00:00:00.000',
                end_time: moment(new Date(endDate)).format('YYYY-MM-DD')+ 'T23:59:59.000',
            }
            const response = await reportService.getAcademicsResources(params.id, payload);
            const {total_resources, total_resources_changes_percent, resources} = response?.data;
            setTotalResourcesData({
                value: total_resources,
                percent: total_resources_changes_percent,
            });
            const resourceItems= [];
            for (const key in ResourcesType) {
                const value = response?.data[key] || 0;
                const percent = value ? format1Decimal((value/total_resources)*100) : 0;
                resourceItems.push({
                    name: ResourcesType[key],
                    color: ResourcesTypeColor[key],
                    height: percent || 0,
                    toolTipLabel: `${response?.data[key] || 0}(${percent}%)`
                })
            }
            setResourcesData(resourceItems);
            setLoading(false);
            setNoData(total_resources==0);
        } catch {
            setLoading(false);
            setNoData(true);
        }
    }

    const [startDate, setStartDate] = useState(new Date(moment().subtract(1, 'months').format('MM-DD-YYYY')));
    const [endDate, setEndDate] = useState(new Date());
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    useEffect(() => {
        getData();
    }, [startDate, endDate]);

    return <>
        <CardContainer>
            <div className="flex items-center">
                <Title>{translator('Library')}</Title>
                <div className="ml-auto min-w-[132px]">
                    <DatePickerComponent onChange={onChange} startDate={startDate} endDate={endDate} />
                </div>
            </div>
            <div className="flex items-center mt-4 min-h-[169px]">
                {loading ?
                    <div className="flex justify-center w-full">
                        <CircleSpin color="text-primary-500" />
                    </div> :
                    <>
                        <div className="w-2/3">
                            {!noData ?
                                <ChartColumn data={resourcesData} /> :
                                <EmptyStateChart />
                            }
                        </div>
                        <div className="h-8 w-[1px] bg-gray-300 mx-6"></div>
                        <div className="w-1/3">
                            <CardInfo {...totalResourcesData} title={translator('Total Content')} />
                        </div>
                    </>
                }
            </div>
        </CardContainer>
    </>
}