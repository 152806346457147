import "./ModalSessionDetail.scss";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { User } from "_shared/components/User/User";
import { CalendarIcon } from "@heroicons/react/outline";
import ClockIcon from "assets/icon/ClockIcon";
import { useHistory, useParams } from "react-router-dom";
import { ButtonOutline, ButtonPrimary } from "_shared";
import { SESSION_STATUS_ENUM } from "constant/sessions.const";
import { useContext, useEffect, useState } from "react";
import courseService from "services/course.service";
import DefaultThumb from 'assets/img/defaultThumb.png'
import { UserContext } from "contexts/User/UserContext";
import { openUrl } from "utils/commonFun";

export const SessionTitle = ({ session }) => {
  return (
    <div className="SessionTitle">
      {session?.course?.title || "-"}
    </div>
  );
};

export const SessionDetail = ({ session }) => {
  const { t: translator } = useTranslation();
  const [sessionDetail, setSessionDetail] = useState(undefined)
  const params: any = useParams();
  const history = useHistory()
  const {
    userState: { result },
  } = useContext(UserContext);

  useEffect(() => {
    courseService.getSessionDetail(params?.id, session?.course?.id, session?.id).then(res => setSessionDetail(res?.data));
  }, [session?.id])

  const checkJoinSession = (el: any) => {
    let flg: boolean = false;
    // within 15 minus, lesser end_time
    if ((dayjs().valueOf() >= (dayjs?.utc(el?.start_time).tz(result?.time_zone).valueOf() - 900000)) && (dayjs().valueOf() < dayjs?.utc(el?.end_time)?.tz(result?.time_zone).valueOf()))
      flg = true;
    return flg;
  };

  return (
    <div className="SessionDetail">
      <div className="wrapper">
        <div className="wrapper-grid">
          <div className="thumbnail">
            <img src={sessionDetail?.course?.thumbnail || DefaultThumb} />
          </div>
          <ButtonOutline
            type="button"
            size="-isPrimary"
            className="btn"
            onClick={() => {
              const url = new URL(`/workspace/${params?.id}/lesson/${sessionDetail?.lesson?.id}/preview`, window?.location?.origin);
              url.searchParams.set('isCloseBack', 'true');
              window.open(url.toString(), '_blank');
            }}
          >
            {translator('DASHBOARD.TEACHER.VIEW_LESSON')}
          </ButtonOutline>
          {sessionDetail?.display_status === SESSION_STATUS_ENUM.COMPLETED && (
            <ButtonPrimary
              type="button"
              onClick={() =>
                window.open(
                  `/workspace/${params?.id}/instructor-dashboard/gradebook/${sessionDetail?.course?.id}/${sessionDetail?.id}`,
                  '_blank'
                )
              }
            >
              {translator('EVALUATE.EVALUATE')}
            </ButtonPrimary>
          )}
          {sessionDetail?.vc_link && checkJoinSession(sessionDetail) && (
            <ButtonPrimary type="button" onClick={() => openUrl(sessionDetail?.vc_link)}>
              {translator('DASHBOARD.TEACHER.JOIN')}
            </ButtonPrimary>
          )}
        </div>
        <SessionInfo sessionDetail={sessionDetail} translator={translator} />
      </div>
    </div>
  );
};

const SessionInfo = ({ sessionDetail, translator }) => {
  const params: any = useParams();
  const {
    userState: { result },
  } = useContext(UserContext);
  return (
    <div className="SessionInfo">
      <div>
        <label>
          {translator("DASHBOARD.TEACHER.SESSION_ID")}
        </label>
        <div className="text">{sessionDetail?.id || '-'}</div>
      </div>

      <div>
        <label>
          {translator("DASHBOARD.TEACHER.DATE_AND_TIME")}
        </label>
        <div className="cover-date-time">
          <CalendarIcon width="16" height="16" />
          <div className="date-time">
            {dayjs(sessionDetail?.start_time)?.tz(result.time_zone).format("dddd, DD/MM/YYYY")}
            {" • "}
            <ClockIcon className={"w-4 h-4"} />
            {dayjs(sessionDetail?.start_time)?.tz(result.time_zone).format("HH:mm") +
              " - " +
              dayjs(sessionDetail?.end_time)?.tz(result.time_zone).format("HH:mm")}
          </div>
        </div>
      </div>

      <div>
        <label>
          {translator("DASHBOARD.TEACHER.LESSON")}
        </label>
        <div className="value">
          {sessionDetail?.lesson?.title || "-"}
        </div>
      </div>

      <div>
        <label>
          {translator("DASHBOARD.TEACHER.COURSE")}
        </label>
        <div className="value">
          {sessionDetail?.course?.title || "-"}
        </div>
      </div>

      <div>
        <label>
          {translator("DASHBOARD.TEACHER.INSTRUCTORS")}{" "}
        </label>
        <div className="cover-value">
          {sessionDetail?.instructors?.map((instructor) => {
            return (
              <div key={instructor?.id}>
                <User
                  link={`/workspace/${params?.id}/management/users/${instructor?.id}?tab=information`}
                  avatar={instructor?.avatar_url}
                  name={instructor?.display_name}
                  className="!text-sm"
                />
              </div>
            );
          })}
        </div>
      </div>

      <div>
        <label>
          {translator("DASHBOARD.TEACHER.TEACHING_ASSISTANTS")}
        </label>
        <div className="cover-value">
          {sessionDetail?.instructor_assistants?.map((item) => {
            return (
              <div key={item?.id}>
                <User
                  link={`/workspace/${params?.id}/management/users/${item?.id}?tab=information`}
                  avatar={item?.avatar_url}
                  name={item?.display_name}
                  className="!text-sm"
                />
              </div>
            );
          })}
          {!!!sessionDetail?.instructor_assistants && '-'}
        </div>
      </div>

      <div>
        <label>
          {translator("DASHBOARD.TEACHER.LEARNERS")}{" "}
        </label>
        <div className="cover-value">
          {sessionDetail?.learners?.map((instructor) => {
            return (
              <div key={instructor?.id}>
                <User
                  link={`/workspace/${params?.id}/management/users/${instructor?.id}?tab=information`}
                  avatar={instructor?.avatar_url}
                  name={instructor?.display_name}
                  className="!text-sm"
                />
              </div>
            );
          })}
        </div>
      </div>

      <div>
        <label>
          {translator("DASHBOARD.TEACHER.INSTRUCTOR_NOTES")}
        </label>
        <div className="text">{sessionDetail?.instructor_note || "-"}</div>
      </div>

      <div>
        <label>
          {translator("DASHBOARD.TEACHER.VC_LINK")}
        </label>
        <div
          className="link"
          onClick={() =>
            sessionDetail?.vc_link && openUrl(sessionDetail?.vc_link)
          }
        >
          {sessionDetail?.vc_link || "-"}
        </div>
      </div>

      <div>
        <label>
          {translator("DASHBOARD.TEACHER.EXTRA_LINK")}
        </label>
        <div
          className="link"
          onClick={() =>
            sessionDetail?.extra_link && openUrl(sessionDetail?.extra_link)
          }
        >
          {sessionDetail?.extra_link || "-"}
        </div>
      </div>

      <div>
        <label>
          {translator("DASHBOARD.TEACHER.RECORDING_LINK")}
        </label>
        <div
          className="link"
          onClick={() =>
            sessionDetail?.record_link && openUrl(sessionDetail?.record_link)
          }
        >
          {sessionDetail?.record_link || "-"}
        </div>
      </div>

      <div>
        <label>
          {translator("DASHBOARD.TEACHER.LOCATION")}
        </label>
        <div className="value">
          {sessionDetail?.location || "-"}
        </div>
      </div>
    </div>
  );
};
