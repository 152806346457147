import { FC, useContext, useMemo, useState } from 'react';
import { CourseType } from 'types/Courses.type';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import NO_CONTENT from 'assets/img/empty-state/trash.png'
import { useHistory, useParams } from 'react-router-dom';
import { XIcon } from '@heroicons/react/outline';
import DialogComponent from 'components/Dialog/DialogComponent';
import { ButtonOutline, ButtonPrimary } from '_shared';
import absenceRequestService from 'services/absenceRequest.service';
import { checkPermission } from 'utils/commonFun';

interface IViewTable {
    data: CourseType[];
    refreshData?: Function
}

const TableList: FC<IViewTable> = ({
    data,
    refreshData
}) => {
    const params: { id: string } = useParams();
    const { t: translator } = useTranslation();
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;
    const history = useHistory();
    const [selectedRecipient, setSelectedRecipient] = useState<any>();
    const [loading, setLoading] = useState(false);

    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('Name')} />,
                width: 240,
                Cell: ({ row }) => {
                    return <div className='flex items-center gap-2 hover:underline cursor-pointer'
                        onClick={() => history.push(`/workspace/${params?.id}/management/users/${row?.values?.user_profile?.id}?tab=information`, '_blank')}
                    >
                        <div className='w-6 h-6 rounded-full bg-cover bg-center' style={{ backgroundImage: `url(${row?.values?.user_profile?.avatar_url})` }}></div>
                        <div className='text-primary-500 font-semibold text-sm'>{row?.values?.user_profile?.display_name}</div>
                    </div>
                },
                accessor: 'user_profile',
            },

            {
                Header: () => <RenderTableHeader value={translator('Email')} />,
                accessor: 'email',
                width: 240,
                Cell: ({ row }: any) => {
                    return <RenderTableCell value={row?.values?.user_profile?.email} />
                }
            },
            {
                Header: () => <RenderTableHeader value={translator('Role')} />,
                accessor: 'user_role',
                Cell: ({ row }: any) => {
                    return <RenderTableCell value={row?.values?.user_role?.name} />;
                },
            },
            {
                Header: () => <RenderTableHeader value="" />,
                accessor: 'id',
                width: 20,
                Cell: ({ row }: any) => {
                    return checkPermission('course', 'C') && <button type='button' className='text-red-500' onClick={() => setSelectedRecipient(row?.values?.id)}>
                        <XIcon className='w-5 h-5' />
                    </button>
                }
            },
        ],
        [data, WorkspaceDetailInformation]
    );

    const handleRemove = async () => {
        try {
            setLoading(true);
            await absenceRequestService.removeAbsenceRequestRecipients(params?.id, {
                ids: [selectedRecipient]
            });
            refreshData?.(true);
            setSelectedRecipient(null);
        } catch (error) {
            
        } finally {
            setLoading(false);
        }
     }

    return (<>
        <Table
            data={data}
            columns={columns}
            emptyContent={translator('No Recipient')}
            emptyIcon={NO_CONTENT}
        />
        <DialogComponent
            title={translator('Remove a Recipient')}
            isOpen={!!selectedRecipient}
            onCloseModal={() => setSelectedRecipient(null)}
            styles="max-w-lg"
            child={<>
                <p className='text-sm text-gray-800'>
                    {translator('Are you sure you want to remove this recipient from the list?')}
                </p>
                <div className="flex justify-center gap-4 mt-5">
                    <ButtonOutline type="button" onClick={() => setSelectedRecipient(null)}>
                        {translator('Cancel')}
                    </ButtonOutline>
                    <ButtonPrimary type="button" onClick={handleRemove}
                    className="buttonPrimary-danger"
                    loading={loading}
                    >
                        {translator('Remove')}
                    </ButtonPrimary>
                </div>
            </>}>
        </DialogComponent>
    </>
    );
};

export default TableList;
