import React, { useCallback, useEffect, useState } from "react";
import { LEARNING_GROUP } from "components/Workspace/Course/CourseBuilder/components/CoursePeople/constants";
import { SelectField } from "components/Select";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import SelectBox from "components/V2/SelectBox";
import { useDebouncedState } from "hooks/useDebounce";
import { debounce } from "lodash";
import "./SearchLearnerAndLearningGroup.scss";
import workspaceService from "services/workspace.service";
import { useTranslation } from "react-i18next";

export const SearchLearnerAndLearningGroup = ({onChangeFilter, isReset}) => {
    const { control, setValue, getValues, watch } = useForm()
    const params: { id: string, courseId: string } = useParams();
    const [listOwners, setListOwner] = useState([]);
    const [inputSearchOwner, setInputSearchOwner] = useDebouncedState('');
    const [ownerSelected, setOwnerSelected] = useState([]);
    const [loadingOwner, setloadingOwner] = useState(false);
    const [pagination, setPagination] = useState<any>({
        page: 1,
    });

    const {t: translator} = useTranslation();

    const updateFilter = useCallback(
        debounce((listFilter) => onChangeFilter(listFilter)),
        []
    );

    const getListLearners = async (page: number = 1) => {
        setloadingOwner(true);
        let payload = {
            page: page ? page : pagination.page,
            per_page: 10,
            q: inputSearchOwner,
            order: 'asc',
            payer_type: 'learner'
        }
        if (!payload?.q) delete payload.q;
        let callAPIs;
        if (!watch('learning_group')?.value) {
            return;
        }
        if (watch('learning_group')?.value == LEARNING_GROUP[1]?.value) {
            payload.payer_type = 'learning_group';
        } else {
            payload.payer_type = 'learner';
        }
        callAPIs = await workspaceService.getPayers(params.id, payload);
        const formatedData = callAPIs?.data?.items?.map((item) => {
            return {
                name: item?.name ?? item?.display_name,
                id: item?.membership_id || item?.id,
                value: item?.membership_id || item?.id,
            };
        });
        setPagination({ ...pagination, total: callAPIs?.data?.total, page });
        if (page === 1) setListOwner(formatedData);
        else setListOwner([...listOwners, ...formatedData]);
        setloadingOwner(false)
    };

    useEffect(() => {
        if (getValues('learning_group')?.value == LEARNING_GROUP[0]?.value) {
            updateFilter({
              learner_membership_ids: ownerSelected?.map(item => item.value).join(",") ?? ''
            });
        } else {
            updateFilter({
                learning_group_ids: ownerSelected?.map(item => item.value).join(",") ?? ''
            });
        }
    }, [ownerSelected]);

    useEffect(() => {
        getListLearners(1);
    }, [inputSearchOwner, watch('learning_group')]);

    useEffect(() => {
        setValue('learning_group', { name: translator("COURSES.LEARNING_GROUPS"), label: translator("COURSES.LEARNING_GROUPS"), value: 'learning_group' });
    }, []);

    useEffect(() => {
        !isReset && setOwnerSelected([])
    }, [isReset])

    return <div className="learnerAndGroupSearch">
        <div className="-mr-1 z-1">
            <SelectField
                control={control}
                name="learning_group"
                className="w-[130px]"
                options={[
                    { name: translator("COURSES.LEARNERS"), label: translator("COURSES.LEARNERS"), value: 'learners' },
                    { name: translator("COURSES.LEARNING_GROUPS"), label: translator("COURSES.LEARNING_GROUPS"), value: 'learning_group' },
                ]}
                customStyles={{
                    control: (base) => {
                        return {
                            ...base,
                            minHeight: '0px',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            height: '36px',
                            borderColor: '#E5E7EB'
                        }
                    },
                    valueContainer: (base) => {
                        return { ...base, fontSize: '14px' }
                    },
                    indicatorsContainer: (base) => {
                        return { ...base, padding: '0px', height: '36px' }
                    },
                    menuList: (base) => {
                        return { ...base, height: '80px' }
                    },
                }}
            />
        </div>
        <SelectBox
            label={`${watch('learning_group')?.value == LEARNING_GROUP[0]?.value ? translator("SEARCH_LEARNERS") :  translator("SEARCH_LEARNING_GROUPS")}`}
            onSearch={setInputSearchOwner}
            onSelect={setOwnerSelected}
            data={listOwners}
            loading={loadingOwner}
            selectedItems={ownerSelected}
            total={pagination?.total}
            showmore={() => getListLearners(pagination?.page + 1)}
            hasSearchIcon={true}    
        />
    </div>
}