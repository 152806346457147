import { FC } from 'react';
import { DuplicateIcon } from '@heroicons/react/outline';
import { classNames, copyClipboard } from 'utils/commonFun';
import { Toastify } from '../Toastify/Toastify';

type CopyClipboardProps = {
    text?: string;
    id?: string;
    color?: string;
    message?: string;
    className?: string;
    type?: 'border' | 'none';
};

export const CopyClipboard: FC<CopyClipboardProps> = ({
    text = '',
    id = '',
    color = 'text-gray-500',
    message = '',
    className = '',
    type = 'none',
}) => {
    const style = type === 'border' ? 'z-2 p-1 bg-white border border-gray-200 rounded' : '';

    return (
        <button
            type="button"
            title={''}
            className={classNames(style, className)}
            onClick={() => {
                copyClipboard({ id, text });
                !!message && Toastify.success(message);
            }}
        >
            <DuplicateIcon className={`w-4 h-4 ${color}`} />
        </button>
    );
};
