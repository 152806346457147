const ActionsCircleButton = ({
    icon,
    onclick,
}: {
    icon: React.ReactNode;
    onclick?: () => void;
}) => {
    return (
        <button
            onClick={() => {
                if (onclick) onclick();
            }}
            className="w-8 h-8 mr-1 p-2 hover:bg-ooolab_light_blue_0 hover:text-primary-500 active:bg-primary-500 active:text-white active:outline-none rounded-full group"
        >
            {icon}
        </button>
    );
};

export default ActionsCircleButton;
