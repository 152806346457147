import { useState } from 'react';
import { ConfirmDeleteModal } from '_shared';
import './ListContent.scss';
import ContentItem from './ContentItem';

export default function ListContent({
    sectionItem,
    contentSelected,
    handleSelectContentToPreview,
    translator,
    handleDeleteFileOfSection,
    onDragContentStart,
    onDragContentEnd,
    onDragOverContent,
    sections,
    setSections,
    setOnSaving,
    setAssignmentSelected,
    assignmentSelected,
    isDragging
}) {
    const [isOpenModalConfirm, setOpenModalConfirm] = useState(false);
    const [fileId, setFileId] = useState('');
    const deleteFile = (value: boolean) => {
        if (value) {
            handleDeleteFileOfSection(sectionItem, fileId);
        }
        setOpenModalConfirm(false);
    };

    return (
        <div>
            {sectionItem?.section_contents?.length > 0 && (
                <div className="ListContent">
                    {sectionItem?.section_contents?.map((file, index) => {
                        return (
                            <ContentItem
                                key={file?.id}
                                file={file}
                                contentSelected={contentSelected}
                                handleSelectContentToPreview={handleSelectContentToPreview}
                                sectionItem={sectionItem}
                                onDragOverContent={onDragOverContent}
                                index={index}
                                onDragContentStart={onDragContentStart}
                                onDragContentEnd={onDragContentEnd}
                                translator={translator}
                                sections={sections}
                                setSections={setSections}
                                setOnSaving={setOnSaving}
                                setAssignmentSelected={setAssignmentSelected}
                                assignmentSelected={assignmentSelected}
                                onDelete={(id) => {
                                    setOpenModalConfirm(true);
                                    setFileId(id)
                                }}
                                isDragging={isDragging}
                            />
                        );
                    })}
                </div>
            )}
            <ConfirmDeleteModal
                isOpen={isOpenModalConfirm}
                onClose={deleteFile}
                titleModal={translator('LIBRARY.DELETE_ASSIGNMENT')}
                btnPrimaryTitleLoading={translator('LIBRARY.REMOVING')}
                btnPrimaryTitle={translator('LIBRARY.REMOVE')}
            >
                <div className="text-sm">{translator('LIBRARY.DELETE_ASSIGNMENT_MESSAGE')}</div>
            </ConfirmDeleteModal>
        </div>
    );
}


