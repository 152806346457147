import React, { useEffect, useMemo, useState } from "react";
import { timeList } from "constant/workspace.const";
import { useTranslation } from "react-i18next";
import ButtonDownload from 'components/Button/ButtonDownload';
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { NoContentWithImage } from "components/NoContent/NoContent";
import { checkPermission, getIndexPage, getValueOrSign } from "utils/commonFun";
import TablePagination from "components/V2/Pagination/Pagination";
import { ColumnWithLooseAccessor } from "react-table";
import { useLocation, useParams } from "react-router-dom";
import { downloadCSV } from "components/Reports/components/common";
import { CardContainer } from "components/Reports/components/CardContainer";
import { Title } from "components/Reports/components/Title";
import SearchInput from "components/Reports/components/SearchInput";
import { LinkCustom } from "components/V2/Table/TableCustom";
import learnerReportService from "services/reports/learner-report.service";
import Table from "components/V2/Table";

export const StudentAcademicsEnrollment = () => {
    const { t } = useTranslation();
    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);
    const params: { id: string, studentId: string } = useParams();
    const [enrolled, setEnrolled] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(timeList[1]);
    const [searchText, setSearchText] = useState<string>();
    const search = useLocation()?.search;
    const type = new URLSearchParams(search).get('type');

    const columns: ColumnWithLooseAccessor[] = useMemo(
        () => [
            {

                Header: () => (
                    <p className="text-left pl-2">
                        {translator('Course Name')}
                    </p>
                ),
                Cell: ({ row }: { row: any }) => {
                    return (
                        <div className="grid w-full">
                            <LinkCustom
                                to={`/workspace/${params.id}/report/dashboard-course/${row.values?.course?.id}`}
                                className="text-sm text-primary-500 font-semibold cursor-pointer truncate"
                                isTooltip
                                value={row.values?.course?.title}
                                disable={row.values.course?.title?.length < 35}

                            /></div>
                    );
                },
                accessor: 'course',
                width: 180,
            },
            {
                Header: () => <p className="">{translator('Overall Grade')}</p>,
                accessor: 'overall',
                Cell: (d: any) => {
                    return (
                        <p className="text-gray-800">
                            {getValueOrSign(d.value)}
                        </p>
                    );
                },
                width: 100,
            },
            {
                Header: () => <p className="">{translator('Attendance')}</p>,
                accessor: 'attendance',
                Cell: (d: any) => {
                    return (
                        <p className="text-gray-800">
                            {getValueOrSign(d.value)}
                        </p>
                    );
                },
                width: 100,
            },
            {
                Header: () => <p className="">{translator('Assignment Grade')}</p>,
                accessor: 'assignment_score',
                Cell: (d: any) => {
                    return (
                        <p className="text-gray-800">
                            {getValueOrSign(d.value)}
                        </p>
                    );
                },
                width: 100,
            },
            {
                Header: () => <p className="">{translator('Course Completion')}</p>,
                accessor: 'course_completion',
                Cell: (d: any) => {
                    return (
                        <p className="text-gray-800">
                            {getValueOrSign(d.value)}
                        </p>
                    );
                },
                width: 100
            },
            {
                Header: () => <p className="">{translator('Goals Achieved')}</p>,
                accessor: 'lesson_goal',
                Cell: (d: any) => {
                    return (
                        <p className="text-gray-800">
                            {getValueOrSign(d.value)}
                        </p>
                    );
                },
                width: 100,
            },
        ],
        []
    );
    const handleDownloadCSV = async () => {
        const payload = {
            studentId: params?.studentId,
            page: enrolled?.page,
            skip_pagination: true
        }
        let response;
        if (type === 'learning-group') {
            response = await learnerReportService.getLearnerGroupAcademics(params.id, payload);
        } else {
            response = await learnerReportService.getLearnerAcademics(params.id, payload);
        }
        let headers: string[], rows: string[][];
        let file_name = `Enrollment`;
        headers = [
            'No.',
            'Course Name',
            'Overall Grade',
            'Attendance',
            'Assignment Grade',
            'Course Completion',
            'Goals Achieved'
        ];
        rows = response?.data?.items?.map((el: any, index) => [
            index + 1,
            el?.course?.title || 'N/A',
            el?.overall != null ? el?.overall + '%' : '-',
            el?.attendance != null ? el?.attendance + '%' : '-',
            el?.assignment_score != null ? el?.assignment_score + '%' : '-',
            el?.course_completion != null ? el?.course_completion + '%' : '-',
            el?.lesson_goal != null ? el?.lesson_goal + '%' : '-',

        ]);
        downloadCSV([headers, ...rows], file_name);
    };

    const handlePagination = (page: number) => {
        getData(page);
    }

    const getData = async (page: number) => {
        try {
            setLoading(true);
            let payload: any = {
                studentId: params?.studentId,
                page,
                q: searchText
            }
            if (!payload.q) {
                delete payload.q;
            }
            if (payload?.previous_days_compare == '') {
                delete payload?.previous_days_compare;
            }
            let response;
            if (type === 'learning-group') {
                response = await learnerReportService.getLearnerGroupAcademics(params.id, payload);
            } else {
                response = await learnerReportService.getLearnerAcademics(params.id, payload);
            }

            setEnrolled(response?.data);

            setLoading(false);
        } catch {
            setLoading(false);
        }
    }
    useEffect(() => {
        getData(1);
    }, [selectedDate?.id, searchText]);

    return <>
        <CardContainer>
            <div className='flex mb-4 items-center'>
                <Title>{translator('Enrollment')}</Title>
                {checkPermission('learnerDashboard', 'DL') && <div className='ml-auto flex space-x-4'>
                    <ButtonDownload title="Download CSV" isDownload={false} disabled={enrolled?.total == 0 || enrolled == null} onClick={handleDownloadCSV} />
                </div>}

            </div>
            <div className=''>
                <SearchInput onSubmit={setSearchText} />
            </div>
            <div className='mt-4 overflow-auto'>
                {
                    loading ?
                        <div className="flex justify-center items-center w-full min-h-[566px]">
                            <CircleSpin color="text-primary-500" />
                        </div> :
                        enrolled?.items?.length > 0 ?
                            <Table
                                columns={columns}
                                data={enrolled?.items}
                                rowProps={{
                                    className:
                                        'bg-white hover:bg-gray-50 cursor-pointer',
                                }}
                                rowClass={(row) => row?.id === ''
                                    ? 'bg-white hover:bg-gray-50 cursor-pointer bg-gray-50'
                                    : 'bg-white hover:bg-gray-50 cursor-pointer'
                                }
                                headerProps={{
                                    className: 'bg-gray-50 text-gray-900 text-sm py-5',
                                }}
                                className="min-w-full divide-y divide-gray-200"
                                onClickRow={() => { }}
                                onDoubleClickRow={() => { }}
                                selectedRemove={() => { }}
                            /> :
                            <div className="flex justify-center items-center w-full max-h-[220px] py-8">
                                <NoContentWithImage />
                            </div>
                }
            </div>
            {enrolled?.total > 0 && <div className="flex items-center justify-between border-t border-ooolab_bar_color mt-4">
                <p className="text-ooolab_dark_2 text-ooolab_xs font-semibold leading-ooolab_24px hidden lg:block">
                    {t('SHOWING_1_50_OF_TOTAL_PAGE', {
                        fromTo: getIndexPage(enrolled?.page, enrolled?.per_page, enrolled?.items?.length),
                        totalPage: enrolled?.total,
                    })}
                </p>
                <div className="mt-4">
                    <TablePagination
                        onClickPagination={handlePagination}
                        perPage={enrolled?.per_page}
                        total={enrolled?.total}
                        forcePage={enrolled?.page - 1}
                    />
                </div>
            </div>}
        </CardContainer>
    </>
}