import { ExternalLinkIcon, InformationCircleIcon, XIcon } from "@heroicons/react/solid";
import "./PanelSetting.scss";
import { ButtonIcon } from "_shared";
import { useAppDispatch } from "hooks/hooks";
import { changeShowCourseSetting } from "store/features/courseSlice";
import { useTranslation } from "react-i18next";

export const PanelSetting = ({onClose}: {onClose:() => void}) => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    return (
        <div className="panelSetting">
            <InformationCircleIcon className="flex-none w-6 h-6 text-primary-500" />
            <div className="panelSetting-content">
                <h2 className="panelSetting-title">{t('COURSES.HAVE_YOU_CONFIGURED_YOUR_COURSE_YET')}</h2>
                <p className="panelSetting-description">{t('COURSES.GO_TO_SETTINGS')}</p>
            </div>
            <div className="panelSetting-action">
                <button className="panelSetting-button" type="button" onClick={() => dispatch(changeShowCourseSetting(true))}>
                    {t('COURSES.OPEN_SETTINGS')}
                    <ExternalLinkIcon className="w-5 h-5" />
                </button>
                <ButtonIcon onClick={onClose}>
                    <XIcon className="w-5 h-5" />
                </ButtonIcon>
            </div>
        </div>
    )
}