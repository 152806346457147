import React, { useState, useEffect } from 'react';
import { PlusIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ButtonComponent from 'components/Button/ButtonComponent';
import { useDebouncedState } from 'hooks/useDebounce';
import { getIndexPage } from 'utils/commonFun';
import SearchBox from 'components/V2/SearchBox';
import Spinner from 'components/Spinner';
import DialogComponent from 'components/Dialog/DialogComponent';
import { useBoolean } from 'hooks/custom';
import ModalEnrollStudent from './ModalEnrollStudent';
import Pagination from 'components/V2/Pagination';
import TableStudent from './TableStudent';
import { defaultPagination } from 'constant/util.const';
import workspaceService from 'services/workspace.service';
import { CLASS_TYPE } from '../util';

const index = ({sessionName, classType}) => {
    const params: any = useParams();
    const { t } = useTranslation();

    const { booleanValue: modalEnrollStudent, toggleBooleanValue: toggleModalEnrollStudent } = useBoolean();
    const [loading, setLoading] = useState(true);
    const [indexPage, setIndexPage] = useState('0-0');
    const [keyword, setKeyword] = useDebouncedState('');
    const [studentList, setStudentList] = useState([]);
    const [pagination, setPagination] = useState(defaultPagination);
    
    const workspaceId = params.id;
    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`CLASSES.${str}`, opt));

    const fetchStudentList = (page: number | string = 1) => {
        setLoading(true);
            workspaceService
            .getClassesMembers(params.id, params.classId, {
                type: 'student',
                ...pagination, page: page || pagination?.page,
                sort_by: 'display_name',
                order: 'asc',
                q: keyword
            })
            .then((res) => {
              const { items, page, per_page, order, sort_by, total } = res?.data;
              setStudentList(items);
              setPagination({ page, per_page, order, sort_by, total });
              setIndexPage(getIndexPage(page, per_page, items?.length));
          })
        .catch()
        .finally(() => setLoading(false));
    };

    const handlePagination = (page: number) => {
      fetchStudentList(page);
    };

    useEffect(() => {
      fetchStudentList();
      return () => {
          setStudentList([]);
      };
    }, [keyword]);

    return (
        <div className="w-full">
            <div className="flex justify-between p-4 px-2">
                <SearchBox className='w-full max-w-md' placeholder={translator('NAME_EMAIL')} onSubmit={(e: any) => setKeyword(e)} />
               {
                classType === CLASS_TYPE.MANUAL && 
                    <ButtonComponent
                        title={translator('ENROLL_STUDENT')}
                        classStyle="xs:p-2 xs:text-sm primary-button !bg-enterprise"
                        onClickButton={toggleModalEnrollStudent}
                        icon={<PlusIcon className="w-4 h-4 mr-ooolab_m_1" />}
                    />
                }
            </div>

            <div className="px-2">
                <div className="h-[calc(100vh-280px)] custom-scrollbar overflow-auto">
                    {loading ? (
                        <Spinner />
                    ) : (
                        <TableStudent sessionName={sessionName} workspaceId={workspaceId} dataTable={studentList} translator={translator} fetchStudentList={fetchStudentList}/>
                    )}
                </div>
                <Pagination
                    indexPage={indexPage}
                    total={pagination.total}
                    per_page={pagination.per_page}
                    page={pagination.page}
                    callback={handlePagination}
                />
            </div>
            <DialogComponent
                isOpen={modalEnrollStudent}
                title={translator('ENROLL_STUDENT')}
                onCloseModal={toggleModalEnrollStudent}
                maskClosable={false}
                stylesTitle='text-base'
                styles={'max-w-[550px]'}
                child={
                    <ModalEnrollStudent
                        translator={translator}
                        fetchData={() => {
                            fetchStudentList(pagination.page);
                            toggleModalEnrollStudent();
                        }}
                        onCloseModal={toggleModalEnrollStudent}
                    />
                }
            />
        </div>
    );
};

export default index;
