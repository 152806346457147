import { components, StylesConfig } from "react-select";

const customSelectStyle: StylesConfig<any, true> = {
  clearIndicator: (base) => ({ ...base, display: 'none' }),
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  dropdownIndicator: (base) => ({ ...base, padding: 4, color: "#2E3A59" }),
  control: (styles) => ({
      ...styles,
      borderColor: '#C7C9D9',
      minHeight: '2.1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      borderRadius: '8px'
  }),
  multiValue: (styles, { data }) => {
    return {
    ...styles,
    background: data.bgColor,
    borderRadius: '4px',
    color: data.textColor,
    fontWeight: 600,
    fontSize: '12px',
  }
},
  option: (styles, { data }) => {
    return {
    ...styles,
    background: '#ffffff',
    color: data.isSelected && '#2E3A59',
    ":active": {background: '#ffffff'},
    }
  },
  menu: (styles: any) => ({
    ...styles, width: 300,
    boxShadow: '0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);',
    border: '1px solid #EBEBF0'
  }),
}

const OptionComponent = (props) => {
  return (
    <div>
      <components.Option {...props}  >
        <div className='flex items-center'>
          <input
            className='mr-2'
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            key={props.value}
          />{" "}
          <label className="text-xs text-dark-300 font-normal">{props.label}</label>
        </div>
      </components.Option>
    </div>
  );
};

export { OptionComponent, customSelectStyle}