import { StylesConfig } from 'react-select';

export const SendToOptions = (translator) => [
    { value: "all", label: translator("All")},
    { value: "all_learners", label: translator("All Learners")},
    { value: "all_parents", label: translator("All Parents")},
    { value: "all_instructors", label: translator("All Instructors")},
    { value: "all_teaching_assistants", label: translator("All Teaching Assistants")},
    { value: "specific_learners", label: translator("Specific Learners")},
    { value: "specific_instructors", label: translator("Specific Instructors")}
];

export const ReceiveOptions= (translator) => [
    { value: 'people', label: translator('GROUP.PEOPLE') },
    { value: 'course', label: translator('COURSE') },
    { value: 'custom_group', label: translator('Custom Group') },
];

export const scheduleType = (translator) =>  {
    return [
        { value: 'now', label: translator('NOW') },
        { value: 'schedule', label: translator('SCHEDULE') },
    ]
};

export const customSelectMulti: StylesConfig<any, true> = {
    clearIndicator: (base) => ({ ...base, display: 'none' }),
    indicatorSeparator: (base) => ({ ...base, display: 'none' }),
    dropdownIndicator: (base) => ({ ...base, display: 'none' }),
    control: (base) => ({
        ...base,
        borderColor: '#e6e6e6',
        minHeight: '2rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    }),
    multiValue: (provided) => ({
        ...provided,
        background: '#F2F9FF',
        borderRadius: '18px',
        textColor: '#2E3A59',
        fontWeight: 400,
    }),
};

export const customSelectType: StylesConfig<any, true> = {
    clearIndicator: (base) => ({ ...base, display: 'none' }),
    indicatorSeparator: (base) => ({ ...base, display: 'none' }),
    control: (base) => {
        return {
            ...base,
            borderColor: '#EBEBF0',
            padding: 0,
            paddingLeft: 8,
            height: 32,
            minHeight: 32,
            color: '#2E3A59',
        };
    },
    valueContainer: (base) => ({
        ...base,
        padding: 0,
        fontSize: 12,
    }),
    indicatorsContainer: (base, { selectProps }) => ({
        ...base,
        transition: 'all 0.3s ease-in-out',
        transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: 6,
    }),
    input: (base) => ({
        ...base,
        padding: 0,
        margin: 0,
        fontSize: 12,
    }),
    option: (base) => ({
        ...base,
        paddingLeft: '15px',
        fontSize: 12,
    }),
};

export const handleCheckArray = (arrClasses: any[], arrGroups: any[]) => {
    let value = 'people';

    if (arrClasses?.length) {
        value = 'classes';
    } else if (arrGroups?.length) {
        value = 'groups';
    }
    return value;
};

export const configCkEditor = {
    toolbar: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        'blockQuote',
        'imageUpload',
        '|',
        'undo',
        'redo',
    ],

    link: {
        addTargetToExternalLinks: true,
    },
};


