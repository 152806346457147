import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreditAccountList } from './CreditAccountList';
import { CreditAccountDetail } from './CreditAccountDetail';
import { useQueryParamsURL } from 'hooks/useQueryParamsURL';

export const CreditAccounts = ({ userData }) => {
    const query = useQueryParamsURL();
    const { t } = useTranslation();

    const [creditDetail, setCreditDetail] = useState(null);

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`USER.CREDIT_ACCOUNT.${str}`, opt));

    useEffect(() => {
        if (!creditDetail && query.get('creditId')) setCreditDetail({ id: query.get('creditId') });
    }, []);
    
    return (
        <div className="w-full text-gray-800">
            {!creditDetail ? (
                <CreditAccountList userData={userData} translator={translator} onViewCreditDetail={setCreditDetail} />
            ) : (
                <CreditAccountDetail userData={userData} translator={translator} onViewCreditDetail={() => setCreditDetail(null)} creditId={creditDetail?.id} />
            )}
        </div>
    );
};
