import React, { useRef, useState } from 'react';
import { Popover } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { ButtonOutline, ConfirmModal, LoadingPopup } from '_shared';
import { HistoryIcon, ImportIcon, ListViewIcon } from 'assets/icon';
import { ChevronRightIcon } from '@heroicons/react/outline';
import userService from 'services/user.service';
import { useHistory, useParams } from 'react-router';
import { downloadCSV } from 'components/Reports/components/common';
import uploadService from 'services/upload.service';
import "./ImportUser.scss";

export const ImportUser = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const params: { id } = useParams();
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [loadingUploading, setLoadingUploading] = useState(false);
    const fileInputRef = useRef(null);
    const fileInputRef1 = useRef(null);
    const [resultUpload, setResultUpload] = useState<any>();

    const handleDownload = async (type) => {
        setLoadingDownload(true);
        const response = await userService.getImportWorkspaceMemberTemplates(params.id, { location: type });
        downloadCSV([[response?.data], []], type === 'clms' ? 'CLMS-Upload Users': 'Learner Portal-Upload Users');
        setLoadingDownload(false);
    }
    const handleUploadFile = (file, location) => {
        setLoadingUploading(true);
        uploadService.uploadFile(
            file,
            async (result) => { 
                try {
                    const response = await userService.importWorkspaceMembers(params.id, {
                        csv_file: result,
                        csv_name: file.name,
                        location
                    });
                    setResultUpload({
                        title: t("USER.PROCESSING_YOUR_CSV_IMPORT"),
                        message: t("USER.PROCESSING_YOUR_CSV_IMPORT_MESSAGE")
                    });
                    setLoadingUploading(false);
                } catch (error) {
                    handleSetFail(error?.response?.data?.error);
                    setLoadingUploading(false);
                }
            },
            () => {
                handleSetFail();
                setLoadingUploading(false);
            }
        )
    }
    const handleSetFail = (error?: any) => {
        const title = t(`USER.HEADER_${error?.body_params[0]?.msg || 'UPLOAD_FAILED'}`);
        const message =  t(`USER.${error?.body_params[0]?.msg || 'UPLOAD_FAILED'}`);
        setResultUpload({
            title,
            message
        })
    }
    return (<>
        <Popover className="importUser relative">
            <Popover.Button className="outline-none">
                <ButtonOutline className='importUser-button' type="button" onClick={() => { }}>
                    <div className='importUser-content'><span className='importUser-icon'><ImportIcon /></span> {t("USER.IMPORT")}</div>
                </ButtonOutline>
            </Popover.Button>
            <Popover.Panel className="absolute z-10 w-56 right-0 bg-white rounded-lg shadow-sm mt-3">
                <div className='text-sm w-full cursor-pointer'>
                    <div onClick={() => { }} className="flex gap-1 py-2 items-center w-full  px-4 hover:bg-gray-50 transitions">
                        <div className='w-full'>
                            <Popover className="importUser relative">
                                <Popover.Button className="flex outline-none items-center gap-1 w-full">
                                    <span className='importUser-icon'><ImportIcon /></span> {t('USER.IMPORT_CSV')}
                                    <ChevronRightIcon className='w-5 h-5 ml-auto' />
                                </Popover.Button>
                                <Popover.Panel className="absolute z-10 w-40 left-[108%] -top-2 bg-white rounded-lg shadow-sm">
                                    <div className='text-sm w-full cursor-pointer'>
                                        <div
                                            onClick={() => fileInputRef.current.click()}
                                            className="flex gap-1 py-2 px-4  items-center hover:bg-gray-50 transitions"
                                        >
                                            CLMS
                                            <input
                                                accept=".csv"
                                                ref={fileInputRef}
                                                onChange={(e) => handleUploadFile(e.target?.files[0], 'clms')}
                                                className=" hidden"
                                                type="file"
                                                placeholder={''}
                                            />
                                        </div>
                                        <div
                                            onClick={() => fileInputRef1.current.click()}
                                            className="flex gap-1 py-2 px-4  items-center hover:bg-gray-50 transitions"
                                        >
                                            {t('USER.LEARNER_PORTAL')}
                                            <input
                                                accept=".csv"
                                                ref={fileInputRef1}
                                                onChange={(e) => handleUploadFile(e.target?.files[0], 'learner_portal')}
                                                className=" hidden"
                                                type="file"
                                                placeholder={''}
                                            />
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Popover>
                        </div>
                    </div>
                    <div onClick={() => { }} className="flex py-2 px-4 hover:bg-gray-50 transitions">
                        <div className='w-full'>
                            <Popover className="importUser relative">
                                <Popover.Button className="flex outline-none items-center gap-1 w-full">
                                    <span className='importUser-icon'><ListViewIcon /></span>
                                    {t("USER.DOWNLOAD_TEMPLATE")}
                                    <ChevronRightIcon className='w-5 h-5 ml-auto' />
                                </Popover.Button>
                                <Popover.Panel className="absolute z-10 w-40 left-[108%] -top-2 bg-white rounded-lg shadow-sm">
                                    <div className='text-sm w-full cursor-pointer'>
                                        <div onClick={() => handleDownload('clms')} className="flex gap-1 py-2 px-4  items-center hover:bg-gray-50 transitions">
                                            CLMS
                                        </div>
                                        <div onClick={() => handleDownload('learner_portal')} className="flex gap-1 py-2 px-4  items-center hover:bg-gray-50 transitions">
                                            {t('USER.LEARNER_PORTAL')}
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Popover>
                        </div>
                    </div>
                    <div onClick={() => history.push(`/workspace/${params.id}/management/users-history`)} 
                        className="flex gap-1 py-2 items-center  px-4 hover:bg-gray-50 transitions">
                        <span className='importUser-icon'><HistoryIcon /></span>
                        {t('USER.HISTORY')}
                    </div>
                </div>
            </Popover.Panel>
        </Popover>
        {loadingDownload ? <LoadingPopup message={t('LIBRARY.DOWNLOADING')} loading={loadingDownload} /> : ''}
        {loadingUploading ? <LoadingPopup message={t('LIBRARY.UPLOADING')} loading={loadingUploading} /> : ''}
        {!!resultUpload ? <ConfirmModal
                title={resultUpload?.title}
                isOpen={!!resultUpload}
                onClose={(value) => setResultUpload(null)}
                textConfirm={``}
                textCancel={t('CLOSE')}
            >
                <p className="text-sm" dangerouslySetInnerHTML={{__html: resultUpload?.message}}></p>
            </ConfirmModal> : null } 
    </>)
}