import { CalendarIcon, PencilAltIcon } from '@heroicons/react/outline';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import DatePicker from 'components/DatePicker/DatePicker';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import courseService from 'services/course.service';
import { UserContext } from 'contexts/User/UserContext';
import { ENROLLMENT_TYPE, ENROLLMENT_TYPE_OPTION } from '../../constants';
import Spinner from 'components/Spinner';
import DialogComponent from 'components/Dialog/DialogComponent';
import './Detail.scss';
import { useTranslation } from 'react-i18next';
import { setEnrollmentSelected } from 'store/features/courseSlice';
import { checkPermission } from 'utils/commonFun';
import { ListUser } from '_shared/components/User/User';
import ModalUpdateInstructor from './ModalUpdateInstructor';

export default function EnrollmentInfomation() {
    const { enrollmentSelected, setting } = useAppSelector((state) => state.course);
    const dispatch = useAppDispatch();
    const params: { id: string; courseId: string } = useParams();
    const workspaceId = params?.id;
    const courseId = params?.courseId;
    const [data, setData] = useState<any>([]);
    const [dateInfo, setDateInfo] = useState({
        startDate: new Date(),
        endDate: new Date(),
        defaultEndDate: new Date(),
    });
    const { t: translator } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [isOpenModalUpdateInstructor, setOpenModalUpdateInstrcutor] = useState(false)
    const [isOpenModalUpdateEndDate, setOpenModalUpdateEndDate] = useState(false);
    const {
        userState: { result },
    } = useContext(UserContext);

    useEffect(() => {
        const dataEnrollmentDetail = [
            {
                label: translator('COURSES.ENROLLMENT_AS'),
                value:
                    enrollmentSelected?.type === ENROLLMENT_TYPE.INDIVIDUAL
                        ? ENROLLMENT_TYPE_OPTION(translator)[0]?.name
                        : ENROLLMENT_TYPE_OPTION(translator)[1]?.name,
            },
            {
                label: translator('Learner Email'),
                value: enrollmentSelected?.learner?.email,
                hide: enrollmentSelected?.type === ENROLLMENT_TYPE.LEARNING_GROUP || (!enrollmentSelected?.learner?.email && enrollmentSelected?.parent?.email)
            },
            {
                label: translator('Parent Email'),
                value: enrollmentSelected?.learner?.email,
                hide: enrollmentSelected?.type === ENROLLMENT_TYPE.LEARNING_GROUP || !enrollmentSelected?.parent?.email
            },
            {
                label: translator('COURSES.ENROLLED_DATE'),
                value: dayjs.utc(enrollmentSelected?.enrolled_at).tz(result?.time_zone)?.format('DD/MM/YYYY'),
            },
            {
                label: translator('COURSES.START_DATE'),
                value: dayjs.utc(enrollmentSelected?.start_date).tz(result?.time_zone)?.format('DD/MM/YYYY'),
            },
            
        ];
        if (enrollmentSelected?.type === ENROLLMENT_TYPE.INDIVIDUAL) {
            dataEnrollmentDetail?.unshift({
                label: translator('COURSES.LEARNER_NAME'),
                value: enrollmentSelected?.learner?.display_name,
            });
        }
        if (enrollmentSelected?.type === ENROLLMENT_TYPE.LEARNING_GROUP) {
            dataEnrollmentDetail?.unshift({
                label: translator('COURSES.LEARNING_GROUP_NAME'),
                value: enrollmentSelected?.learning_group?.name,
            });
        }
        setData(dataEnrollmentDetail);
        setDateInfo({
            endDate: new Date(dayjs.utc(enrollmentSelected?.end_date).tz(result?.time_zone)?.format()),
            startDate: new Date(dayjs.utc(enrollmentSelected?.start_date).tz(result?.time_zone)?.format()),
            defaultEndDate: new Date(dayjs.utc(enrollmentSelected?.end_date).tz(result?.time_zone)?.format()),
        });
    }, [enrollmentSelected, translator, setting?.enrollment_type]);

    const onUpdateEndDate = () => {
        courseService
            .updateEnrollmentDetail(workspaceId, courseId, enrollmentSelected?.id, { end_date: dateInfo?.endDate })
            .then((res) => {
                Toastify.success();
                setDateInfo({ ...dateInfo, defaultEndDate: dateInfo?.endDate });
                setOpenModalUpdateEndDate(false);
                dispatch(setEnrollmentSelected({ ...enrollmentSelected, ...res?.data }));
            })
            .catch(() => Toastify.error());
    };

    if (loading) {
        return (
            <div className="w-full h-full flex justify-center items-center">
                <Spinner />
            </div>
        );
    }

    return (
        <div className="EnrollmentInformation">
            {data
                ?.filter((item) => !item?.hide)
                ?.map((item, index) => {
                    return (
                        <div key={index} className="EnrollmentInformation-field">
                            <div className="label">{item?.label}</div>
                            <div className="value text-ellipsis truncate">{item?.value || '-'}</div>
                        </div>
                    );
                })}
            <div className="EnrollmentInformation-field">
                <div className="label">{translator('COURSES.END_DATE')}</div>
                <div className="value">
                    <input className="w-20" value={dayjs(dateInfo?.defaultEndDate)?.format('DD/MM/YYYY')} />
                    {checkPermission('coursePeopleEnrollment', 'U') && <div onClick={() => setOpenModalUpdateEndDate(true)} className="changeEndDate">
                        {translator('COURSES.CHANGE_DATE')}
                        <CalendarIcon className="icon" />
                    </div>}
                </div>
            </div>

            <div className="EnrollmentInformation-field">
                <div className="label">{translator('COURSES.INSTRUCTORS')}</div>
                <div className="value">
                <div className='flex gap-1'>
                        <ListUser fontsize='!text-sm' id="instructor-tooltip" users={enrollmentSelected?.instructors?.map((instructor) => ({...instructor, link:`/workspace/${params?.id}/management/users/${instructor?.id}/tab=information`}))}/>
                        <div className='flex gap-1 w-20 items-center'>
                            <span className='text-sm text-primary-500'>{translator("EDIT")}</span>
                            <PencilAltIcon className='text-primary-500 cursor-pointer w-5' onClick={() => setOpenModalUpdateInstrcutor(true)}/>   
                        </div>
                    </div>
                </div>
            </div>

            <div className="EnrollmentInformation-field !hidden">
                <div className="label">{translator('COURSES.TEACHING_ASSISTANTS')}</div>
                <div className="value">
                    <ListUser fontsize='!text-sm' id="teaching-assistant-tooltip" users={enrollmentSelected?.teaching_assistants?.map((item) => ({...item, link:`/workspace/${params?.id}/management/users/${item?.id}/tab=information`}))}/>
                </div>
            </div>


            <DialogComponent
                title={translator('COURSES.CHANGE_END_DATE')}
                isOpen={isOpenModalUpdateEndDate}
                onCloseModal={() => setOpenModalUpdateEndDate(false)}
                styles="max-w-md "
                child={
                    <div className="ModalChangeEndDate">
                        <DatePicker
                            onChange={(value) => setDateInfo({ ...dateInfo, endDate: value })}
                            mindate={dateInfo?.startDate}
                            selected={dateInfo?.endDate}
                            dateFormat={'dd/MM/yyyy'}
                            inputProps={{ className: 'ModalChangeEndDate-input' }}
                            positionIcon="right"
                            placeholder={translator('COURSES.ENTER_DATE')}
                        />
                        <div className="GroupBtn">
                            <ButtonOutline type="button" onClick={() => setOpenModalUpdateEndDate(false)}>
                                {translator('CANCEL')}
                            </ButtonOutline>
                            <ButtonPrimary disabled={!dateInfo.endDate} type="button" onClick={onUpdateEndDate}>
                                {translator('UPDATE')}
                            </ButtonPrimary>
                        </div>
                    </div>
                }
            />
            {isOpenModalUpdateInstructor && (
                <DialogComponent
                    title={translator('COURSES.EDIT_INSTRUCTOR')}
                    isOpen={isOpenModalUpdateInstructor}
                    onCloseModal={() => setOpenModalUpdateInstrcutor(false)}
                    styles="!max-w-lg"
                    child={
                        <ModalUpdateInstructor onCloseModal={() => setOpenModalUpdateInstrcutor(false)} data={enrollmentSelected} />
                    }
                />
            )}
        </div>
    );
}
