import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import { debounce } from 'lodash';
import './Filter.scss';
import { INVOICE_STATUS_LIST, PAYMENT_OPTION_LIST, RECURRING_INVOICE_STATUS_LIST } from '../../constants';
import { SearchLearnerAndLearningGroup } from './SearchLearnerAndLearningGroup';
import SearchInput from '_shared/components/SearchInput/SearchInput';

interface IFilter {
    onChangeFilter: (value: any) => void;
    tab: string;
}

const Filter = ({ onChangeFilter, tab }: IFilter) => {
    const { t: translator } = useTranslation();
    const [keyword, setKeyword] = useDebouncedState('', 0);
    const [paymentOption, setPaymentOption] = useState([]);
    const [status, setStatus] = useState([]);
    const [isReset, setReset] = useState(false);
    const [payerSelected, setPayerSelected] = useState(undefined);
    const [frequency, setFrequency] = useState([]);

    const updateFilter = useCallback(
        debounce((listFilter) => onChangeFilter(listFilter)),
        []
    );

    useEffect(() => {
        setReset(false);
        let listFilter: any = {
            status: status[0]?.value,
            payment_options: paymentOption?.map((item) => item?.value)?.join(','),
            learner_membership_ids: payerSelected?.learner_membership_ids,
            learning_group_ids: payerSelected?.learning_group_ids,
            q: keyword?.trim(),
            frequency_type: frequency[0]?.value,
        };
        if (!listFilter?.frequency_type?.length) delete listFilter.frequency_type;
        if (!listFilter?.status?.length) delete listFilter.status;
        if (!listFilter?.learning_group_ids) delete listFilter.learning_group_ids;
        if (!listFilter?.learner_membership_ids) delete listFilter.learner_membership_ids;
        if (!listFilter?.payment_options?.length) delete listFilter.payment_options;
        if (!listFilter?.q) delete listFilter.q;
        updateFilter(listFilter);
    }, [status, paymentOption, keyword, payerSelected, frequency]);

    const onReset = () => {
        setStatus([]);
        setPaymentOption([]);
        onChangeFilter({});
        setPayerSelected([]);
        setFrequency([]);
        setReset(true);
        setKeyword('')
    }

    useEffect(() => {
        onReset();
    }, [tab])

    return (
        <div className="filterBar">
            <SearchInput
                placeholder={tab === 'all-invoices' ? translator('SEARCH_INVOICE_ID') : translator('SEARCH_RECURRING_INVOICE_NAME_OR_LEARNER_NAME')}
                className={`sessionFilter-searchInput ${tab === 'all-invoices' ? 'width-280' : 'width-420'}` }
                onChange={(value) => setKeyword(value)}
                value={keyword}
            />

            {tab === 'all-invoices' && <SearchLearnerAndLearningGroup isReset={isReset} onChangeFilter={setPayerSelected} />
}
            <SelectBox
                label={translator('PAYMENT_OPTION')}
                onSelect={setPaymentOption}
                data={PAYMENT_OPTION_LIST(translator)}
                loading={false}
                selectedItems={paymentOption}
                total={3}
                isMulti={true}
            />

            {tab === 'recurring-invoices' && (
                <SelectBox
                    label={translator('FREQUENCY')}
                    onSelect={setFrequency}
                    selectedItems={frequency}
                    data={[
                        { name: translator('MONTHLY'), value: 'monthly' },
                        { name: translator('ANNUALLY'), value: 'annually' },
                    ]}
                    total={2}
                    isMulti={false}
                />
            )}

            <SelectBox
                label={translator('STATUS')}
                onSelect={setStatus}
                selectedItems={status}
                data={tab === 'all-invoices' ? INVOICE_STATUS_LIST(translator) : RECURRING_INVOICE_STATUS_LIST(translator)}
                total={tab === 'all-invoices' ? INVOICE_STATUS_LIST(translator)?.length : RECURRING_INVOICE_STATUS_LIST(translator)?.length}
                isMulti={false}
            />

            
            <div className="line"></div>

            <button
                onClick={onReset}
                className="btn-reset"
            >
                {translator('RESET')}
            </button>
        </div>
    );
};

export default Filter;
