import React, { Fragment, FC, useRef, useEffect, useState } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { ArrowLeftIcon, XIcon } from '@heroicons/react/outline';
import { toInteger } from 'lodash';
import { useTranslation } from 'react-i18next';
import { CircleSpin } from 'components/UiComponents/CircleSpin';

interface ModalProps {
    isOpen: boolean;
    onSubmitImage: (file: File, canvas: HTMLCanvasElement) => void;
    onClose: Function;
    title: string,
}

const DragAndDropImageComponent: React.FC<{
    onSelectImage: (image: File) => void;
}> = ({ onSelectImage }) => {
    const { t: translator } = useTranslation();
    const [errorUpload, setErrorUpload] = React.useState<string[]>([])

    return (
        <Dropzone
            onDrop={(acceptedFiles, fileRejections) => {  
                setErrorUpload([])
                if (fileRejections && fileRejections.length > 0) {
                    fileRejections[0].errors.forEach(err => {
                        if (err.code === "file-too-large") setErrorUpload((errorUpload) => errorUpload.concat("The banner image size must be less than 5 MB"))
                        else setErrorUpload((errorUpload) => errorUpload.concat(err.message))
                    })                
                    onSelectImage(undefined);
                }
                if (acceptedFiles && acceptedFiles.length > 0) {
                    onSelectImage(acceptedFiles[0]);
                }
            }}
            accept=".jpeg, .png, .jpg" 
            //less than 5 MB
            maxSize={5*1048576}
        >
            {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                    {...getRootProps()}
                    className={
                        'cursor-pointer justify-center flex flex-col w-full h-full text-ooolab_xl text-ooolab_dark_2 font-medium leading-ooolab_28px items-center'
                    }
                >
                    <input {...getInputProps()} />
                    <label>
                        {
                            errorUpload.length > 0 && 
                                errorUpload.map((err, index) => {
                                return (
                                    <p key={index} className='text-ooolab_error italic text-ooolab_base text-center'>
                                        {err}
                                    </p>
                                )
                                })
                        }
                        {isDragActive ? (
                            translator('UPLOAD_IMG.DRAG_THE_FILES')
                        ) : (
                            <div className="text-center cursor-pointer">
                                <p>
                                    {translator('UPLOAD_IMG.DRAG_DROP_IMAGE')}
                                </p>
                                <em className="text-ooolab_xs">
                                    (Only *.jpg *.jpeg and *.png images will be accepted)
                                </em>
                            </div>
                        )}
                    </label>
                </div>
            )}
        </Dropzone>
    );
};

const ModalSelectImage: FC<ModalProps> = ({
    isOpen,
    onSubmitImage,
    onClose,
    title,
}) => {
    const { t: translator } = useTranslation();

    const cancelButtonRef = useRef(null);
    const [imagePath, setImagePath] = useState<File | undefined>();
    const [scale, setScale] = useState(1);
    const [sizeImage, setSizeImage] = useState({w: 320, h: 128})
    let editor: AvatarEditor;
    const [loading, setLoading] = useState(false)
    const setEditorRef = (avatarEditor: AvatarEditor) =>
        (editor = avatarEditor);

    const onSaveImage = () => {
        if (editor && imagePath) {
            const canvas = editor.getImageScaledToCanvas();
            onSubmitImage(imagePath, canvas);
            setLoading(false)
            onClose();
        }
    };

    const handleClose = () => {
        setImagePath(undefined);
        setScale(1);
        onClose();
    };

    const onloadFile = (files) => {
        var _URL = window.URL || window.webkitURL;
        var img;
        img = new Image();
        var objectUrl = _URL.createObjectURL(files);
        img.onload = function () {
            _URL.revokeObjectURL(objectUrl);
            setSizeImage({w: this.width, h: this.height})
        };
        img.src = objectUrl;
        setImagePath(files)
    }

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-70 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={isOpen}
                onClose={handleClose}
            >
                <div className="flex justify-center h-screen text-center items-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-700"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 dialog-background  bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <div
                            className={`bg-white rounded-ooolab_card text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle relative
                             ${!imagePath && 'w-2/5 h-3/5'}
                        `}
                        >
                            {imagePath ? (
                                <div className="w-full h-full relative flex flex-col items-center justify-center px-ooolab_p_16 py-ooolab_p_16">
                                    <div className="absolute w-full items-center top-0 left-0 px-ooolab_p_5 py-ooolab_p_5 flex justify-between">
                                        {/* {translator(
                                            'UPLOAD_IMG.UPDATE_COURSE_BACKGROUND'
                                        )} */}
                                        {
                                            title
                                        }
                                        <XIcon
                                            onClick={handleClose}
                                            className="w-4 h-4"
                                        />
                                    </div>
                                    <div className="absolute w-full items-center bottom-0 left-0 px-ooolab_p_5 py-ooolab_p_5 flex justify-between">
                                        <ArrowLeftIcon
                                            onClick={() =>
                                                setImagePath(undefined)
                                            }
                                            className="w-4 h-4 cursor-pointer"
                                        />
                                        <button
                                            onClick={() => {
                                                setLoading(true)
                                                onSaveImage();
                                            }}
                                            disabled={loading}
                                            className="px-ooolab_p_3 py-ooolab_p_1 rounded-lg text-white bg-primary-500 focus:outline-none border flex items-center gap-1 "
                                        >   
                                            { loading && <CircleSpin className='mr-1 h-3 w-3'/>}
                                            {translator('UPLOAD_IMG.SAVE')}
                                        </button>
                                    </div>

                                    <AvatarEditor
                                        ref={setEditorRef}
                                        style={{
                                            width: 'calc(520*(100vw/1440))',
                                            height: 'calc(220*(100vw/1440))',
                                            objectFit: 'contain'
                                        }}
                                        width={sizeImage.w}
                                        height={sizeImage.h}
                                        image={imagePath}
                                        border={20}
                                        color={[255, 225, 225, 0.6]} // RGBA
                                        scale={scale}
                                        rotate={0}
                                    />
                                    <div className="flex justify-center">
                                        <label htmlFor="course-background">
                                            {translator('UPLOAD_IMG.SCALE')}
                                        </label>
                                        <input
                                            onChange={(e) =>
                                                setScale(
                                                    toInteger(e.target.value)
                                                )
                                            }
                                            step={0.1}
                                            min={1}
                                            max={3}
                                            className="w-ooolab_w_142px"
                                            type="range"
                                            name=""
                                            id="course-background"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <DragAndDropImageComponent
                                    onSelectImage={(e) => onloadFile(e)}
                                />
                            )}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default ModalSelectImage;
