import React, { useState } from 'react';
import { CloudCheckIcon } from 'assets/icon';
import { useAppSelector, useAppDispatch } from 'hooks/hooks';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import './CourseHeader.scss';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { useParams } from 'react-router-dom';
import courseService from 'services/course.service';
import { WarningIcon } from 'assets/icon/WarningIcon';
import DialogComponent from 'components/Dialog/DialogComponent';
import { Trans, useTranslation } from 'react-i18next';
import { courseFetch } from 'store/features/courseSlice';
import { checkPermission } from 'utils/commonFun';
import { DialogCourseSaveTemplate } from 'components/Workspace/CourseTemplate/DialogCourseSaveTemplate';

export const CourseHeader = () => {
    const { data, changed, error, loading } = useAppSelector((state) => state.course);
    const dispatch = useAppDispatch();

    const params: any = useParams();
    const [isOpenModalInactive, setOpenModalInactive] = useState(false);
    const [isOpenModalActive, setOpenModalActive] = useState(false);
    const [hasCreateTemplate, setHasCreateTemplate] = useState(false);
    const { t: translator } = useTranslation();
    const [isOpenActiveLearner, setIsOpenActiveLearner] = useState(false);

    const checkHasLearner = async () => {
        const res = await courseService.precheck(params?.id, data?.id);
        if (res?.data?.has_active_learner) {
            setIsOpenActiveLearner(true);
            return;
        } else {
            setOpenModalInactive(true);
        }
    };

    const onUpdateCourse = async (value) => {
        try {
            courseService
                .updateCourse({ workspaceId: params?.id, id: data?.id, ...value })
                .then((res) => {
                    Toastify.success();
                    setOpenModalActive(false);
                    setOpenModalInactive(false);
                    dispatch(
                        courseFetch({
                            workspaceId: params.id,
                            id: params.courseId,
                        })
                    );
                })
                .catch(() => Toastify.error());
        } catch (error) {

        }
    };

    return (
        <div className="courseHeader">
            <h1 className="courseHeader-title">{data?.title}</h1>
            <span className={`courseHeader-status capitalize ${data?.status === 'active' ? 'courseHeader-status--active' : ''}`}>
                {translator(data?.status?.toUpperCase())}
            </span>
            <div className="courseHeader-save flex gap-4">
                {loading === 'pending' ? (
                    <span>
                        <CircleSpin color="text-primary-500" />
                    </span>
                ) : (
                    <span className={`${changed ? 'text-green-500' : null}`}>
                        <CloudCheckIcon />
                    </span>
                )}
                {translator('COURSE_TEMPLATE.All_changes_saved')}
                {checkPermission('courseInformation', 'C') && <div className='courseHeader-saveTemplate' onClick={() => setHasCreateTemplate(true)}>
                    {translator('COURSE_TEMPLATE.Save_as_Template')}
                </div>}

                {(data && checkPermission('courseInformation', 'U')) && (
                    <ButtonPrimary
                        type="button"
                        onClick={() => {
                            if (data?.status === 'inactive') {
                                return setOpenModalActive(true);
                            };
                            checkHasLearner();
                        }}
                        className={`${data?.status === 'active' ? 'buttonPrimary-secondary' : ''}`}
                        size="M"
                    >
                        {data?.status === 'active' ? translator('DEACTIVATE') : translator('ACTIVATE')}
                    </ButtonPrimary>
                )}
            </div>
            {isOpenModalInactive && (
                <DialogComponent
                    title={translator('DEACTIVATE')}
                    isOpen={isOpenModalInactive}
                    onCloseModal={() => setOpenModalInactive(false)}
                    child={
                        <div className="flex flex-col">
                            {/* {hasActiveLearner && (
                                <div className="text-sm text-gray-800">
                                    <Trans
                                        i18nKey={'COURSES.INACTIVE_COURSE_WARNING'}
                                        components={{
                                            1: <strong className="text-gray-800 font-semibold" />,
                                        }}
                                    />
                                </div>
                            )} */}
                            <div className="mt-2 text-sm text-gray-800">{translator('COURSES.INACTIVE_COURSE')}</div>
                            <div className="flex justify-center gap-4 mt-6 w-full">
                                <ButtonOutline type="button" onClick={() => setOpenModalInactive(false)}>
                                    {translator('CANCEL')}
                                </ButtonOutline>
                                <ButtonPrimary
                                    type="button"
                                    className="buttonPrimary-danger"
                                    onClick={() => onUpdateCourse({ status: 'inactive' })}
                                >
                                    {translator('DEACTIVATE')}
                                </ButtonPrimary>
                            </div>
                        </div>
                    }
                />
            )}

            {isOpenModalActive && (
                <DialogComponent
                    title={
                        <div className="flex gap-1 items-center text-orange-500">
                            <WarningIcon width="24" height="24" />{' '}
                            <div className="text-base text-gray-800">{translator('COURSES.CONFIRM_ACTIVATE')}</div>
                        </div>
                    }
                    isOpen={isOpenModalActive}
                    onCloseModal={() => setOpenModalActive(false)}
                    child={
                        <div className="flex flex-col">
                            <div className="mt-2 text-sm text-gray-800">
                                {/* @ts-ignore */}
                                <Trans
                                    i18nKey={'COURSES.ACTIVATE_COURSE'}
                                    components={{
                                        1: <strong className="text-gray-800 font-semibold" />,
                                    }}
                                />
                            </div>
                            <div className="flex justify-center gap-4 mt-6 w-full">
                                <ButtonOutline type="button" onClick={() => setOpenModalActive(false)}>
                                    {translator('CANCEL')}
                                </ButtonOutline>
                                <ButtonPrimary type="button" onClick={() => onUpdateCourse({ status: 'active' })}>
                                    {translator('CONFIRM')}
                                </ButtonPrimary>
                            </div>
                        </div>
                    }
                />
            )}
            {hasCreateTemplate ? <DialogCourseSaveTemplate course={data} onClose={() => setHasCreateTemplate(false)} /> : null}
            {isOpenActiveLearner &&
                <DialogComponent
                    title={translator('DEACTIVATE')}
                    isOpen={isOpenActiveLearner}
                    onCloseModal={() => setIsOpenActiveLearner(false)}
                    child={<>
                        <div className='text-sm'>
                            <p>{translator('COURSES.WARNING_DEACTIVE')}</p>
                            <p className='mt-2'>Click <a href={`/workspace/${params?.id}/management/courses/${data?.id}?tab=people&subtab=enrollment&status=active,enrolled`} target="_blank" className='text-primary-600 hover:underline'>here</a> to view the list of active enrollments.</p>
                        </div>
                    </>}
                />
            }
        </div>
    );
};
