export const MENU_ITEMS = [
    'PREVIEW',
    'SHARE',
    'ADD_TO_WORKSPACE_DRIVE',
    // 'Download',
    // 'Rename',
    // 'Make a copy',
    // 'Move to',
    'DELETE',
    // 'Go to file location',
    // 'Mask star',
    // 'Tag as',
];

export const UPLOAD_FILE = 'file';

export const UPLOAD_FOLDER = 'folder';

export const DROP = 'drop';

export const CREATE_FOLDER = 'create';
