import React from 'react';
import dayjs from 'dayjs';
import { get } from 'lodash';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import { useTranslation } from 'react-i18next';

interface TableGroupsProps {
    dataTable: any;
    tz: string;
}

const TableGroups: React.FC<TableGroupsProps> = ({ dataTable, tz }) => {
    const {t} = useTranslation();
    const getDataByType = (type: string, data: any) => {
        if (type === 'online' || type === 'offline') {
            return {
                type: 'Class',
                desc: get(data, 'lesson.title'),
            };
        }
        if (type === 'assignment') {
            return {
                type: 'App Active Review',
                desc: get(data, 'assignment.name', ''),
            };
        }
        if (type === 'refund') {
            return {
                type: 'Refund Reward',
                desc: get(data, 'reward.name', ''),
            };
        }
        return {
            type: 'Other',
            desc: data?.description || '',
        };
    };

    const columns = React.useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={t("COIN.ACTIVITY")} />,
                Cell: ({ row }) => {
                    return (
                        <RenderTableCell value={getDataByType(get(row, 'original.point_origin_type'), row.values.data).type} />
                    );
                },
                accessor: 'data',
                width: 160,
            },
            {
                Header: () => <RenderTableHeader value={t("COIN.DESCRIPTION")} />,
                Cell: ({ row }) => {
                    return <RenderTableCell value={getDataByType(row.values.point_origin_type, get(row, 'original.data')).desc} />;
                },
                accessor: 'point_origin_type',
                width: 120,
            },
            {
                Header: () => <RenderTableHeader value={t("COIN.TRANSACTION")} />,
                Cell: ({ row }) => {
                    return (
                        <div className='text-xs'>
                            {get(row, 'original.transaction_type') === 'add' ? (
                                <span className="text-green-500">
                                    + {row.values?.amount} {t("COIN.COINS")}
                                </span>
                            ) : (
                                <span className=" text-red-500">
                                    - {row.values?.amount} {t("COIN.COINS")}
                                </span>
                            )}
                        </div>
                    );
                },
                accessor: 'amount',
                width: 60,
            },
            {
                Header: () => <RenderTableHeader value={t("COIN.UPDATED_DATE")} />,
                Cell: ({ row }) => {
                    return <RenderTableCell value={dayjs.utc(row.values?.created_at).tz(tz).format('DD/MM/YYYY HH:mm')} />;
                },
                accessor: 'created_at',
                width: 60,
            },
        ],
        []
    );

    return (
      <Table data={dataTable} columns={columns} emptyContent={t("COIN.NO_COIN_HISTORY")} />
    );
};

export default TableGroups;
