
import { forwardRef, useCallback, useContext, useEffect, useState } from 'react';
import { accept_file_upload_library } from 'constant/form.const';
import { IParamUploadContext, UploadContext } from 'contexts/Upload';
import { useDropzone } from 'react-dropzone';
import './DragAndDrop.scss';
import libraryService from 'services/library.service';
import { useParams } from 'react-router-dom';

const DragAndDrop = ({ folderId = null, children, updateContentUpload }, ref) => {
    const uploadContext: { handleUploadFile: (params?: IParamUploadContext, handleAfterUploadFile?: any) => void } = useContext(UploadContext);
    const [arrFiles, setArrFiles] = useState([]);
    const [dragActive, setDragActive] = useState(false);
    const params: {id: string} = useParams();
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
          setDragActive(true);
        } else if (e.type === "dragleave") {
          setDragActive(false);
        }
      };

    const onDrop = useCallback((acceptedFiles: Array<File>) => {
        const arrFile = [];
        for (let file of acceptedFiles) {
            arrFile.push(file);
        }
        setArrFiles(arrFile);
        setDragActive(false);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    useEffect(() => {
        if (arrFiles?.length) {
            uploadContext.handleUploadFile({files: arrFiles, folderId, from_assignment: true}, (file, onSuccess, onError) => {
                libraryService.uploadFiles(params?.id, file)
                .then((res) => {
                    updateContentUpload({...res?.data, file: file?.file})
                    onSuccess(file)
                })
                .catch(() => onError(file))
            });
        }
    }, [arrFiles]);

    return (
        <div {...getRootProps()} 
            onDragEnter={handleDrag} 
            onDragLeave={handleDrag} 
            onDragOver={handleDrag}   
            className={`drag-upload-file ${dragActive ? 'drag-active': ''}`} 
            ref={ref} >
            <div>
                {children}
                <input
                    {...getInputProps()}
                    multiple
                    data-max-size="2048"
                    accept={accept_file_upload_library?.join(',')}
                    className="input mt-4"
                    type="file"
                    placeholder={''}
                />
            </div>
        </div>
    );
}
export default forwardRef(DragAndDrop)