import { ErrorMessage } from '@hookform/error-message';
import { Controller, useForm } from 'react-hook-form';
import ModalUploadImage from '../ModalSelectImage';
import { useBoolean } from 'hooks/custom';
import { useTranslation } from 'react-i18next';
import DatePickerInput from 'components/Management/components/Form/DatePicker';
import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import ButtonComponent from 'components/Button/ButtonComponent';
import banner1Service from 'services/banner.service';
import { useParams } from 'react-router-dom';
import userService from 'services/user.service';
import dayjs from 'dayjs';
import { get, isEqual } from 'lodash';
import Modal from 'components/Modal';
import { ExclamationIcon } from '@heroicons/react/outline';
import { customSelectStyle, optionApply, BannerActivationItem, labelOptions, errorMsg } from '../constants';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import UploadImage from 'assets/icon/UploadImage';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { Toastify } from '_shared';
import { checkPermission } from 'utils/commonFun';

export default function Information({ setBannerName }) {
    const {
        register,
        formState: { errors, isValid },
        getValues,
        handleSubmit,
        control,
        reset,
        watch,
        setError,
    } = useForm({ mode: 'onChange' });
    const formWatch = watch();
    const { dispatch: workspaceDispatch } = useContext(GetWorkspaceContext);
    const params = useParams<{ id: string; bannerId: string }>();
    const [data, setData] = useState<any>({});
    const { t: translator } = useTranslation();
    const { booleanValue: isUpload, toggleBooleanValue: toggleIsUpload } = useBoolean();
    const [errorModal, setErrorModal] = useState('');
    const [loading, setLoading] = useState(false);
    const [disableSave, setDisableSave] = useState(true);

    const { booleanValue: confirmDeactivateBanner, toggleBooleanValue: toggleDeactivateBanner } = useBoolean();
    const { booleanValue: confirmUpdateBanner, toggleBooleanValue: toggleUpdateBanner } = useBoolean();

    const { booleanValue: updateErrorModal, toggleBooleanValue: toggleUpdateError } = useBoolean();
    const [tmpAvatar, setTmpAvatar] = useState<{ link: string; file: any }>();

    useEffect(() => {
        const currentData = getValues();
        const listUpdateKeys = Object.keys(control._fields);
        const fieldChanges = [];
        if (tmpAvatar?.link !== get(data, 'thumbnail')) {
            fieldChanges.push('BannerImage');
        }
        listUpdateKeys?.forEach((i) => {
            if (!isEqual(currentData[i], data[i])) {
                return fieldChanges.push(i);
            }
        });
        if (
            fieldChanges?.length > 0 ||
            (currentData['bannerName']?.trim() !== '' && currentData['bannerName']?.trim() !== data['bannerName']?.trim())
        )
            setDisableSave(false);
        else setDisableSave(true);
    }, [formWatch, data, tmpAvatar]);

    const submitForm = async (val) => {
        setLoading(true);
        let thumbnail = tmpAvatar?.link;
        if (tmpAvatar?.file) {
            await userService.uploadImage(
                tmpAvatar.file,
                (e: string) => {
                    thumbnail = e;
                },
                () => { }
            );
        }
        const banner = {
            name: val.bannerName,
            start_date: dayjs(val.startDate).format('YYYY-MM-DD'),
            end_date: dayjs(val.endDate).format('YYYY-MM-DD'),
            deeplink: val.deeplink,
            activation: val.bannerActivation.value,
            views: val.applyfor.map((item) => item.value),
            thumbnail,
        };
        if (!thumbnail) delete banner.thumbnail;

        banner1Service
            .updateBannerService(params.id, params.bannerId, banner)
            .then((res) => {
                setDisableSave(true);
                setData({
                    ...val,
                    bannerActivation: BannerActivationItem(val.bannerActivation.value),
                    thumbnail: res?.data?.thumbnail,
                });
                setTmpAvatar({ ...tmpAvatar, link: res?.data?.thumbnail });
                Toastify.success();
            })
            .catch((err) => {
                const error = get(err, 'response.data.error.body_params[0].msg') || get(err, 'response.data.error.name');
                if (error === 'EXISTING_NAME') {
                    setError('bannerName', { type: 'custom', message: errorMsg[get(err, 'response.data.error.body_params[0].msg')] });
                } else {
                    setErrorModal(errorMsg[error]);
                    toggleUpdateError();
                }
            })
            .finally(() => {
                toggleUpdateBanner();
                setLoading(false);
            });
    };

    useEffect(() => {
        banner1Service
            .getBannerDetail(params.id, params.bannerId)
            .then((res: any) => {
                const { data } = res;
                const formatData = {
                    bannerName: data?.name,
                    startDate: data?.start_date && new Date(data.start_date),
                    endDate: data?.end_date && new Date(data.end_date),
                    deeplink: data?.deeplink,
                    applyfor: data?.views?.map((item) => ({ label: labelOptions[item], value: item })),
                    bannerActivation: data?.activation && BannerActivationItem(data.activation),
                };
                setBannerName(data?.name)
                workspaceMiddleware.setCurrentRouteDetail(workspaceDispatch, [
                    {
                        name: formatData.bannerName,
                        routeId: 'bannerId',
                    },
                ]);
                setData({ ...formatData, thumbnail: data?.thumbnail });
                reset(formatData, {
                    keepDirty: false,
                });
                setTmpAvatar({ link: data.thumbnail, file: undefined });
            })
            .catch((err) => err);
        return () => {
            workspaceMiddleware.setCurrentRouteDetail(workspaceDispatch, []);
        };
    }, []);

    const handleUpdateBannerStatus = (status: 'active' | 'inactive') => {
        banner1Service.updateBannerStatus(params.id, params.bannerId, { activation: status }).then((res) => {
            const temp = {
                ...data,
                bannerActivation: BannerActivationItem(status),
            };
            reset(temp, {
                keepDirty: false,
            });
            setData(temp);
        })
    };

    const onChangeFile = (file, canvas) => {
        setTmpAvatar({ file, link: canvas.toDataURL() });
    };

    return (
        <div className="p-5 ">
            <form onSubmit={handleSubmit(toggleUpdateBanner)}>
                <div className="flex w-full justify-between items-start  px-5 pb-1">
                    <div className=" flex sm:justify-between justify-center w-full max-w-4xl min-w-ooolab_w_80  h-[200px]">
                        <div
                            className={`group relative overflow-hidden w-full rounded-2xl bg-opacity-60 ${tmpAvatar?.link ? 'bg-white border border-gray-100' : 'bg-gray-300'
                                } z-1 group flex justify-center items-center hover:opacity-70 `}
                        >
                            {tmpAvatar?.link && (
                                <img className="w-auto h-auto z-10 absolute" src={tmpAvatar?.link} alt="group-avatar" />
                            )}
                            <button
                                type="button"
                                onClick={toggleIsUpload}
                                className={`z-1 w-full h-[6.25rem] absolute justify-center items-center ${tmpAvatar?.link ? 'hidden' : ''
                                    } group-hover:z-20 group-hover:flex `}
                                disabled={!checkPermission('bannerDetails', 'U')}
                            >
                                <UploadImage />
                            </button>
                        </div>
                    </div>

                    <div className="flex ml-8">
                        {checkPermission('bannerDetails', 'U') && <ButtonComponent
                            title={translator('SAVE')}
                            type="submit"
                            classStyle="xs:p-1 xs:text-sm primary-button w-fit mr-5"
                            disable={disableSave}
                        />}
                        {checkPermission('bannerDetails', 'DA') && <button
                            onClick={toggleDeactivateBanner}
                            type="button"
                            className={`mx-2 secondary-button border-none shadow-ooolab_box_shadow_container text-ooolab_dark_2 xs:text-sm font-semibold`}
                        >
                            {get(data, 'bannerActivation.value') === 'inactive'
                                ? translator('CLASSES.ACTIVATE')
                                : translator('CLASSES.DEACTIVATE')}
                        </button>}

                    </div>
                </div>

                <div className="mt-1 text-xs text-gray-500 border-b border-ooolab_bar_color px-5 pb-5">
                    {translator('BANNER.RECOMMENDED_BANNER')}
                    <br />
                    {translator('BANNER.RECOMMENDED_BANNER_CONTENT')}
                </div>

                <ModalUploadImage
                    isOpen={isUpload}
                    title={translator('BANNER.UPLOAD_IMAGE')}
                    onClose={() => {
                        toggleIsUpload();
                    }}
                    onSubmitImage={(f, c) => onChangeFile(f, c)}
                />

                <div className="p-5 grid grid-cols-8 sm:grid-cols-7 gap-x-ooolab_w_8 gap-y-ooolab_w_5 max-w-4xl">
                    <div className="col-span-8 sm:col-span-8 text-ooolab_xs">
                        <label
                            htmlFor="student-firstname"
                            className="text-ooolab_dark_1 font-semibold leading-ooolab_24px flex mb-1"
                        >
                            {translator('BANNER.BANNER_NAME')} <span className="text-red-500 ml-1"> *</span>
                        </label>
                        <input
                            className="border w-full h-8 border-ooolab_bar_color text-ooolab_dark_1 p-2 rounded font-normal"
                            type="text"
                            id="bannerName"
                            placeholder={translator('BANNER.PLACEHOLDER_BANNER_NAME')}
                            name="bannerName"
                            {...register('bannerName', {
                                required: {
                                    value: true,
                                    message: translator('FORM_CONST.REQUIRED_FIELD'),
                                },
                                maxLength: {
                                    value: 100,
                                    message: translator('BANNER.VALIDATE_BANNER_NAME'),
                                },
                                validate: {
                                    shouldNotContainSpace: (value) => {
                                        return value.trim()?.length ? true : `${translator('FORM_CONST.REQUIRED_FIELD')}`;
                                    },
                                },
                            })}
                            disabled={!checkPermission('bannerDetails', 'U')}
                        />
                        <ErrorMessage className="text-red-500 text-ooolab_10px" errors={errors} name="bannerName" as="p" />
                    </div>
                    <div className=" col-span-4 sm:col-span-2 text-ooolab_xs">
                        <label htmlFor="startDate" className="text-ooolab_dark_1 font-semibold leading-ooolab_24px flex mb-1">
                            {translator('BANNER.START_DATE')} <span className="text-red-500 ml-1"> *</span>
                        </label>
                        <div className="w-full h-8 relative">
                            <div className="flex justify-between items-center">
                                <DatePickerInput
                                    labelCls="text-ooolab_text_username"
                                    isOriginal={true}
                                    control={control}
                                    name="startDate"
                                    isRequired
                                    paddingCls="py-2 px-4 "
                                    placeholderText={translator('BANNER.START_DATE')}
                                    minDate={new Date()}
                                    maxDate={new Date(getValues().endDate)}
                                    {...register('startDate', {
                                        required: {
                                            value: true,
                                            message: translator('FORM_CONST.REQUIRED_FIELD'),
                                        },
                                    })}
                                    disabled={!checkPermission('bannerDetails', 'U')}
                                />
                            </div>
                            <ErrorMessage className="text-red-500 text-ooolab_10px" errors={errors} name="startDate" as="p" />
                        </div>
                    </div>
                    <div className=" col-span-4 sm:col-span-2  text-ooolab_xs">
                        <label htmlFor="endDate" className="text-ooolab_dark_1 font-semibold leading-ooolab_24px flex mb-1">
                            {translator('BANNER.END_DATE')} <span className="text-red-500 ml-1"> *</span>
                        </label>
                        <div className="w-full h-8 relative">
                            <div className="flex justify-between items-center">
                                <DatePickerInput
                                    labelCls="text-ooolab_text_username"
                                    isOriginal={true}
                                    control={control}
                                    name="endDate"
                                    isRequired
                                    minDate={new Date(getValues().startDate)}
                                    paddingCls="py-2 px-4 "
                                    placeholderText={translator('BANNER.END_DATE')}
                                    {...register('endDate', {
                                        required: {
                                            value: true,
                                            message: translator('FORM_CONST.REQUIRED_FIELD'),
                                        },
                                    })}
                                    disabled={!checkPermission('bannerDetails', 'U')}
                                />
                            </div>
                            <ErrorMessage className="text-red-500 text-ooolab_10px" errors={errors} name="endDate" as="p" />
                        </div>
                    </div>
                    <div className="col-span-8 sm:col-span-4 text-ooolab_xs">
                        <label htmlFor="apllyfor" className="text-ooolab_dark_1 font-semibold leading-ooolab_24px flex mb-1">
                            {translator('BANNER.APPLY_FOR')} <span className="text-red-500 ml-1"> *</span>
                        </label>
                        <Controller
                            control={control}
                            name="applyfor"
                            {...register('applyfor', {
                                required: {
                                    value: true,
                                    message: translator('FORM_CONST.REQUIRED_FIELD'),
                                },
                            })}
                            render={({ field, formState }) => {
                                return (
                                    <Select
                                        {...field}
                                        {...formState}
                                        placeholder={translator('BANNER.APPLY_FOR')}
                                        className=" text-ooolab_dark_2 rounded font-normal"
                                        styles={customSelectStyle}
                                        id="applyfor"
                                        options={optionApply}
                                        isMulti
                                        menuPlacement="auto"
                                        isDisabled={!checkPermission('bannerDetails', 'U')}
                                    />
                                );
                            }}
                        />
                        <ErrorMessage className="text-red-500 text-ooolab_10px" errors={errors} name="applyfor" as="p" />
                    </div>
                    <div className="col-span-4 sm:col-span-2 text-ooolab_xs">
                        <label htmlFor="deeplink" className="text-ooolab_dark_1 font-semibold leading-ooolab_24px flex mb-1">
                            {translator('BANNER.DEEPLINK')} <span className="text-red-500 ml-1"> *</span>
                        </label>
                        <input
                            className="border w-full h-8 border-ooolab_bar_color text-ooolab_dark_1 p-2 rounded font-normal"
                            type="text"
                            id="deeplink"
                            placeholder={translator('BANNER.PLACEHODER_DEEPLINK')}
                            name="deeplink"
                            maxLength={2048}
                            {...register('deeplink', {
                                required: 'This field is required!',
                                validate: (val) => {
                                    const regex = /https?:\/\//g;
                                    return regex.test(val) ? true : translator('BANNER.VALIDATE_DEEPLINK');
                                },
                            })}
                            disabled={!checkPermission('bannerDetails', 'U')}
                        />
                        <ErrorMessage className="text-red-500 text-ooolab_10px" errors={errors} name="deeplink" as="p" />
                    </div>
                    <div className="col-span-10 sm:col-span-4  text-ooolab_xs">
                        <label
                            htmlFor="bannerActivation"
                            className="text-ooolab_dark_1 font-semibold leading-ooolab_24px block mb-1"
                        >
                            {translator('BANNER.BANNER_ACTIVATION')}
                        </label>
                        <div className="border w-full h-8 border-ooolab_bar_color text-ooolab_dark_1 p-2 rounded font-normal flex items-center">
                            {BannerActivationItem(get(data, 'bannerActivation.value'))?.label}
                        </div>
                    </div>
                </div>
            </form>

            <Modal
                isOpen={confirmDeactivateBanner}
                onClose={toggleDeactivateBanner}
                title={`${translator('BANNER.CONFIRM')} ${get(data, 'bannerActivation.value') === 'inactive'
                    ? translator('CLASSES.ACTIVATE')
                    : translator('CLASSES.DEACTIVATE')
                    }`}
                contentText={`${translator('BANNER.CONFIRM_ASK')} ${get(data, 'bannerActivation.value') === 'inactive'
                    ? translator('CLASSES.ACTIVATE')
                    : translator('CLASSES.DEACTIVATE')
                    }  ${translator('BANNER.THIS_BANNER')}?`}
                imgSrc={<ExclamationIcon className="text-red-500 w-ooolab_w_10 h-ooolab_h_10" />}
                subBtn={
                    <button
                        className="px-ooolab_p_2 py-ooolab_p_1_e text-ooolab-btn border rounded w-28 hover:opacity-70"
                        onClick={toggleDeactivateBanner}
                        type="button"
                    >
                        {translator('CLASSES.CANCEL')}
                    </button>
                }
                mainBtn={
                    <button
                        className="px-ooolab_p_3 py-ooolab_p_1_e  bg-primary-500 text-white border-none rounded w-28 hover:opacity-70"
                        type="button"
                        onClick={() => {
                            handleUpdateBannerStatus(get(data, 'bannerActivation.value') === 'inactive' ? 'active' : 'inactive');
                            setTimeout(() => toggleDeactivateBanner(), 200);
                        }}
                    >
                        {get(data, 'bannerActivation.value') === 'inactive'
                            ? translator('CLASSES.ACTIVATE')
                            : translator('CLASSES.DEACTIVATE')}
                    </button>
                }
            />
            <Modal
                isOpen={confirmUpdateBanner}
                onClose={toggleUpdateBanner}
                title={translator('BANNER.CONFIRM_SAVE')}
                contentText={translator('BANNER.CONFIRM_SAVE_MESSAGE')}
                subBtn={
                    <button
                        className="px-ooolab_p_2 disabled:opacity-50 py-ooolab_p_1_e secondary-button flex justify-center rounded w-28"
                        onClick={toggleUpdateBanner}
                        type="button"
                        disabled={loading}
                    >
                        {translator('CLASSES.CANCEL')}
                    </button>
                }
                mainBtn={
                    <button
                        className="px-ooolab_p_3 disabled:bg-gray-400 py-ooolab_p_1_e bg-primary-500 text-white border-none rounded w-28 flex justify-center items-center"
                        type="submit"
                        disabled={loading}
                        onClick={() => submitForm(getValues())}
                    >
                        {loading && <CircleSpin className="w-3 h-3 mr-1" />}
                        {translator('CLASSES.SAVE')}
                    </button>
                }
            />
            <Modal
                isOpen={updateErrorModal}
                onClose={toggleUpdateError}
                title={translator('BANNER.ERROR')}
                imgSrc={<ExclamationIcon className="text-red-500 w-ooolab_w_10 h-ooolab_h_10" />}
                contentText={errorModal || translator('BANNER.WRONG')}
                subBtn={
                    <button
                        className="px-ooolab_p_2 py-ooolab_p_1_e secondary-button flex justify-center rounded w-28"
                        onClick={toggleUpdateError}
                        type="button"
                    >
                        {translator('CLASSES.CANCEL')}
                    </button>
                }
            />
        </div>
    );
}
