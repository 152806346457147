export const ImportUserTypeConst = {
    learner_portal: 'Learner Portal',
    clms: 'CLMS'
}
export enum  ImportUserStatus  {
    pending, 
    in_progress, 
    success, 
    failed
}

export const ImportUserStatusDisplay = {
    pending: 'Pending',
    in_progress: 'In Progress',
    success: 'Success', 
    failed: 'Failed'
}