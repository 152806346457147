export const checkAdminTeachersComments = (initValue: any, membership: any) => {
    let check = false;
    if (initValue?.comments && membership?.id) {
        const checked: any = initValue?.comments.find((i) => i?.id === membership?.user_id);
        if (checked) {
            check = true;
        }
    }
    return check;
};
