import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { InputField } from 'components/InputForm/InputForm';
import ButtonComponent from 'components/Button/ButtonComponent';
import ClassService from 'services/class.service';
import { FORM_CONST } from 'constant/form.const';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { Toastify } from '_shared';

type ModalCreateCompanyProps = {
    translator: (key: string, newKey?: boolean) => string;
    fetchData: () => void;
};

const ModalCreateCompany: FC<ModalCreateCompanyProps> = ({ translator, fetchData }) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: {
            name: '',
            contact_email: '',
            address: '',
            phone: '',
            thumbnail: '',
        },
        mode: 'onChange'
    });

    const params: any = useParams()

    const apiCreateCompany = (bodyParams: any) => {
        ClassService.createCompanyService(params?.id, bodyParams)
            .then((res) => {
                Toastify.success();
                fetchData?.();
            })
            .catch((err) => {
                const error= get(err, 'response.data.error.description')?.replace(["/"], "")
                Toastify.error(error)
            })
            .finally();
    };

    const onSubmitForm = async (values: any) => {
        apiCreateCompany({...values, status: "active", name: values?.name?.trim()});
    };

    return (
        <form onSubmit={handleSubmit(onSubmitForm)} className="grid grid-cols-2 gap-x-10 gap-y-5">
            <InputField
                label={translator('COMPANY_NAME')}
                name="name"
                maxLength={255}
                className="col-span-2"
                placeholder={translator('COMPANY_NAME')}
                register={register('name', {
                     required: translator('FORM_CONST.REQUIRED_FIELD', true) ,
                     validate: {
                        shouldNotContainSpace: (value) =>
                            !!value.trim()
                                ? true
                                : `${translator(
                                      'FORM_CONST.REQUIRED_FIELD', true
                                  )}`,
                    },
                })}
                errors={errors}
                isRequired
            />
            <InputField
                label={translator('CONTACT_EMAIL')}
                name="contact_email"
                maxLength={255}
                className="col-span-1"
                placeholder={translator('EMAIL')}
                register={register('contact_email', {
                    pattern: {
                        value: FORM_CONST.EMAIL_REGEX,
                        message: FORM_CONST.EMAIL_VALIDATE,
                    },
                })}
                errors={errors}
            />
            <InputField
                label={translator('PHONE')}
                name="phone"
                maxLength={255}
                className="col-span-1"
                placeholder={translator('PHONE')}
                register={register('phone', {
                    pattern: {
                        value: FORM_CONST.PHONE_NUMBER,
                        message: translator("FORM_CONST.VALIDATE_PHONE", true),
                    },
                })}
                errors={errors}
            />
            <InputField
                label={translator('COMPANY_ADDRESS')}
                name="address"
                maxLength={255}
                className="col-span-2"
                placeholder={translator('ADDRESS')}
                register={register('address')}
                errors={errors}
            />
            <div className="col-span-2 text-center mt-3">
                <ButtonComponent disable={!isValid} type="submit" title={translator('ADD_NEW_COMPANY')} classStyle="xs:p-2 xs:text-sm primary-button disabled:!bg-gray-400 !bg-enterprise" />
            </div>
        </form>
    );
};

export default ModalCreateCompany;
