export const PERMISSIONS_INSTRUCTOR_DASHBOARD = (translator: (key: string) => string) => [
    {
        value: 'instructor_dashboard',
        label: translator('INSTURCTOR_DASHBOARD'),
    },
];
export const PERMISSIONS_CONTENT_MANAGEMENT = (translator: (key: string) => string) => [
    {
        value: 'resource',
        label: translator('LEARNER_PORTAL_RESOURCE'),
    },
    {
        value: 'library_content',
        label: translator('INTERNAL_LIBRARY'),
    },
    {
        value: 'library_trash_content',
        label: translator('INTERNAL_LIBRARY_TRASH'),
    },
    {
        value: 'learning_path',
        label: translator('LEARNING_PATH'),
    },
];

export const PERMISSIONS_INSTRUCTOR_CONTENT_MANAGEMENT = (translator: (key: string) => string) => [
    {
        value: 'resource',
        label: translator('LEARNER_PORTAL_RESOURCE'),
        hideArr: ['noPermission'],

    },
    {
        value: 'library_content',
        label: translator('INTERNAL_LIBRARY'),
        hideArr: ['noPermission'],
    },
    {
        value: 'library_trash_content',
        label: translator('INTERNAL_LIBRARY_TRASH'),
        hideArr: ['noPermission'],
    },
    {
        value: 'learning_path',
        label: translator('LEARNING_PATH'),
        hideArr: ['noPermission'],
    },
];
export const PERMISSIONS_LEARNING_MANAGEMENT = (translator: (key: string) => string) => [
    {
        value: 'course',
        label: translator('COURSES'),
    },
    {
        value: 'session',
        label: translator('SESSIONS'),
    },
    {
        value: 'user',
        label: translator('USERS'),
    },
    {
        value: 'learning_group',
        label: translator('LEARNING_GROUPS'),
    },
    {
        value: 'cert',
        label: translator('CERTIFICATE_TEMPLATE'),
    },
];
export const PERMISSIONS_COMMUNICATIONS = (translator: (key: string) => string) => [
    {
        value: 'banner',
        label: translator('BANNERS'),
    },
    {
        value: 'custom_notification',
        label: translator('NOTIFICATIONS'),
    },
    {
        value: 'custom_group',
        label: translator('CUSTOM_GROUPS'),
    },
];
export const PERMISSIONS_ENGAGEMENT = (translator: (key: string) => string) => [
    {
        value: 'reward',
        label: translator('REWARDS'),
    },
];
export const PERMISSIONS_ANALYTICS = (translator: (key: string) => string) => [
    {
        value: 'workspace_dashboard',
        label: translator('WORKSPACE_DASHBOARD'),
        hideArr: ['write']
    },
    {
        value: 'course_dashboard',
        label: translator('COURSE_DASHBOARD'),
        hideArr: ['write']
    },
    {
        value: 'learner_dashboard',
        label: translator('LEARNER_DASHBOARD'),
        hideArr: ['write']
    },
    {
        value: 'activity_dashboard',
        label: translator('ACTIVITY_DASHBOARD'),
        hideArr: ['write']
    },
    {
        value: 'export_learner_dashboard',
        label: translator('DOWNLOAD_LEANER_DATA'),
        hideArr: ['write']
    },
    {
        value: 'export_instructor_dashboard',
        label: translator('DOWNLOAD_INSTRUCTOR_TA_DATA'),
        hideArr: ['write']
    },
    {
        value: 'export_course_dashboard',
        label: translator('DOWNLOAD_COURSE_DATA'),
        hideArr: ['write']
    },
];
export const E_COMMERCE = (translator: (key: string) => string) => [
    {
        value: 'invoice',
        label: translator('BILLING'),
    },
];

export const PERMISSIONS_FEATURE = (translator: (key: string) => string) => [
    {
        value: 'instructorDashboard',
        label: translator('DASHBOARD'),
        items: PERMISSIONS_INSTRUCTOR_DASHBOARD(translator)
    },
    {
        value: 'contentManagement',
        label: translator('CONTENT_MANAGEMENT'),
        items: PERMISSIONS_CONTENT_MANAGEMENT(translator)
    },
    {
        value: 'learningManagement',
        label: translator('LEARNING_MANAGEMENT'),
        items: PERMISSIONS_LEARNING_MANAGEMENT(translator)
    },
    {
        value: 'comunication',
        label: translator('COMMUNICATION'),
        items: PERMISSIONS_COMMUNICATIONS(translator)
    },
    {
        value: 'engagement',
        label: translator('ENGAGEMENT'),
        items: PERMISSIONS_ENGAGEMENT(translator)
    },
    {
        value: 'analytics',
        label: translator('ANALYTICS'),
        items: PERMISSIONS_ANALYTICS(translator)
    },
    {
        value: 'ecommerce',
        label: translator('E_COMMERCE'),
        items: E_COMMERCE(translator)
    }
]
export const PERMISSIONS_INSTRUCTOR_DASHBOARD_CUSTOM = (translator: (key: string) => string) => [
    {
        value: 'instructor_dashboard',
        label: translator('INSTURCTOR_DASHBOARD'),
        hideArr: ['read', 'noPermission'],
        isDisable: true,

    },
];
export const PERMISSIONS_LEARNING_MANAGEMENT_INSTRUCTOR = (translator: (key: string) => string) => [
    {
        value: 'course',
        label: translator('COURSES'),
        hideArr: ['write'],

    },
    {
        value: 'syllabus',
        label: translator('SYLLABUS'),
        hideArr: ['write'],
    },
    {
        value: 'course_resource',
        label: translator('COURSE_RESOURCES'),
    },
];
export const PERMISSIONS_INSTRUCTOR_FEATURE = (translator: (key: string) => string) => [
    {
        value: 'instructorDashboard',
        label: translator('DASHBOARD'),
        items: PERMISSIONS_INSTRUCTOR_DASHBOARD_CUSTOM(translator),

    },
    {
        value: 'contentManagement',
        label: translator('CONTENT_MANAGEMENT'),
        items: PERMISSIONS_INSTRUCTOR_CONTENT_MANAGEMENT(translator)
    },
    {
        value: 'learningManagement',
        label: translator('LEARNING_MANAGEMENT'),
        items: PERMISSIONS_LEARNING_MANAGEMENT_INSTRUCTOR(translator)
    },
]


export const enum roleType {
    default = "default",
    custom = "custom"
}

export const enum permissionType {
    write = "write",
    read = "read",
}
export const enum defaultRole {
    superAdmin = "Super Admin",
    Admin = "Admin",
    admin = "admin",
    viewer = "Viewer",
    editor = "Editor",
    instructor = "Instructor",
    teachingAssistant = "Teaching Assistant"
}

