import React from "react";
import styles from "./index.module.scss";
import img404 from 'assets/img/404.png';
import { Link } from "react-router-dom";
import Logo from "components/Logo/Logo";
import { useTranslation } from "react-i18next";

const PageNotFound = () => {
  const {t: translator} = useTranslation()
  return (<div className={styles.page404}>
        <Logo className={`${styles['page404-logo ']} w-32 h-32 m-4`} alt="logo" />  
        <div className={styles['page404-container']}>
          <div className={styles['page404-content'] + ' flex flex-col items-center justify-center'}>
            <img
              className={`${styles['page404-img']} w-full max-w-[893px] mx-auto`}
              src={img404}
              alt="oolab"
            />
            <h1 className=" text-[32px] font-semibold text-dark-300 text-center mt-8 mb-6">
               {translator("404_TITLE")}
            </h1>
            <Link to="/" className="bg-enterprise px-14 py-3 text-lg text-white rounded-[18px] mx-auto mt-4">{translator("GO_TO_HOME")}</Link>
          </div>
        </div>
      </div>
  )
}

export default PageNotFound;