import React, { useEffect, useState } from 'react';
import LoginForm from 'components/Authentication/AuthenticationLoginForm';
import ImagesLogin from 'components/ImagesSlider/ImagesLogin';
import { AuthContext } from 'contexts/Auth/AuthContext';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
export interface LoginPageProps {
    isAuthStorage: boolean;
    setAuthStorage: React.Dispatch<React.SetStateAction<boolean>>;
    defaultPassword?: boolean;
}

const LoginPage: React.FC<LoginPageProps> = ({ isAuthStorage, setAuthStorage, defaultPassword }) => {
    const authCtx = React.useContext(AuthContext);
    const [whiteLabel, setWhiteLabel] = useState();
    useDocumentTitle('');
    useEffect(() => {
        if (authCtx?.config) {
            console.log(authCtx?.config);
            setWhiteLabel(authCtx?.config?.find(item => item?.page_name == "login"));
        }
    }, [authCtx]);

    return (
        <div className="min-h-screen flex">
            <div className="w-full max-w-sm 2xl:max-w-md">
                <ImagesLogin whiteLabel={whiteLabel} />
            </div>
            <div className=" w-full flex justify-center items-center">
                <div className="flex-1 flex flex-col justify-start pb-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <LoginForm setAuthStorage={setAuthStorage} defaultPassword={defaultPassword} whiteLabel={whiteLabel} />
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
