import { PlusIcon } from '@heroicons/react/outline';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useBoolean } from 'hooks/custom';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import { Tab } from '@headlessui/react';
import { useLocation } from 'react-router-dom';
import TableReward from './RewardComponents/TableReward';
import CreateRewardForm from './RewardComponents/CreateRewardForm';
import RewardFeatures from './RewardFeatures';
import ApoLevelComponent from './ApoLevel/ApoLevelComponents';
import { RewardsContext } from 'contexts/Rewards/RewardsContext';
import rewardMiddleware from 'middleware/reward.middleware';
import { RewardsType } from 'types/Rewards.type';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import DialogComponent from 'components/Dialog/DialogComponent';
import RewardDashBoard from './RewardDashBoard';
import TablePagination from 'components/Pagination';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import SearchBox from 'components/V2/SearchBox';
import { checkPermission } from 'utils/commonFun';

const listTab = (translator: Function) => {
    const type = [
        {
            name: translator('REWARD.DASHBORD'),
            hiddenWhenDeactivate: false,
            href: 'dashboard',
        },
        {
            name: translator('REWARD.REWARDS'),
            hiddenWhenDeactivate: false,
            href: 'reward',
        },
        {
            name: translator('REWARD.FEATURED_REWARDS'),
            hiddenWhenDeactivate: false,
            href: 'feature',
        },
        {
            name: translator('REWARD.COIN_LEVEL'),
            hiddenWhenDeactivate: false,
            href: 'apolevel',
        },
    ];
    return type;
};

const RewardList: React.FC = () => {
    const { t: translator } = useTranslation();
    const search = useLocation().search;
    const [dataList, setDataList] = useState<RewardsType>();
    const tab = new URLSearchParams(search).get('tab');
    const defaultIndex = useMemo(
        () => (tab ? ['dashboard', 'reward', 'feature', 'apolevel'].indexOf(tab) : 0),
        [tab]
    );
    const [selectedIndex, setSelectedIndex] = useState(
        defaultIndex < 0 ? 0 : defaultIndex
    );
    const [savedApoLevel, setSavedApoLevel] = useState(null);
    const [canSaveApoLevel, setCanSaveApoLevel] = useState(false);
    const listDetailTab = useMemo(
        () =>
            listTab(translator).filter((i) => i.hiddenWhenDeactivate === false),
        [translator]
    );

    const params: { id: string } = useParams();
    const {
        booleanValue: modalCreate,
        toggleBooleanValue: toggleModalCreate,
    } = useBoolean();
    const [shouldWarningBeforeClose, setShouldWarningBeforeClose] = useState(
        false
    );
    const { dispatch: dispatchRewards, rewardsState } = useContext(
        RewardsContext
    );
    const { listRewards, isLoading } = rewardsState;

    const { dispatch, getWorkspaceDetailState } = useContext(
        GetWorkspaceContext
    );

    const { result } = getWorkspaceDetailState;
    const {
        membership: { role },
    } = result;

    useEffect(() => {
        rewardMiddleware.getListReward(params?.id, dispatchRewards, {
            per_page: 20,
        });
    }, []);

    useEffect(() => {
        if (listRewards?.items) {
            setDataList(listRewards);
        }
    }, [listRewards]);

    const handleChangePagination = (e: number) => {
        rewardMiddleware.getListReward(params?.id, dispatchRewards, {
            page: e,
            per_page: 20,
        });
    };

    const handleSearchReward = (e: string) => {
        if (selectedIndex === 1) {
            rewardMiddleware.getListReward(params?.id, dispatchRewards, {
                q: e,
                page: 1,
                per_page: 20,
            });
        } else {
            rewardMiddleware.getListRewardFutured(params?.id, dispatchRewards, {
                q: e,
                page: 1,
                is_featured: true,
                per_page: 20,
            });
        }
    };

    const handleCreateSuccess = () => {
        setShouldWarningBeforeClose(false);
    };

    const saveApoLevel = () => {
        setSavedApoLevel(true);
    };

    return (
        <div>
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.ENGAGEMENT')}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    { name:translator('DASHBOARD.SIDEBAR.REWARD'), href: '', className: '' },
                ]}
            />

            <div className="w-full">
                <Tab.Group
                    defaultIndex={defaultIndex < 0 ? 0 : defaultIndex}
                    selectedIndex={selectedIndex}
                    onChange={setSelectedIndex}
                >
                    <Tab.List className={`bg-white opacity-100 z-2 w-full text-sm px-2  items-center my-4 grid grid-cols-12 gap-2`}>
                        <div className="col-span-8 flex scrollbar overflow-auto  ">
                            {listDetailTab.map((i, index) => (
                                <Tab
                                    key={index}
                                    className={({ selected }) => {
                                        const tabClassName = `flex justify-start items-center p-2 xl:px-12 xl:py-3 text-left font-semibold outline-none ${selected
                                            ? 'text-ooolab_dark_1  bg-[#FAFAFC]'
                                            : 'text-ooolab_dark_2'
                                            }`;
                                        return tabClassName;
                                    }}
                                >
                                    <span>{i.name}</span>
                                </Tab>
                            ))}
                        </div>
                        {(selectedIndex == 1 || selectedIndex == 2) && (
                            <>
                                <div className="col-span-2">
                                    <div className="w-full">
                                        <SearchBox
                                            onSubmit={handleSearchReward}
                                        />
                                    </div>
                                </div>
                                {(checkPermission('rewardRewards', 'C') || checkPermission('rewardFeaturedRewards', 'C')) && <div className="col-span-2 flex justify-end">
                                    <ButtonPrimary
                                        onClickButton={toggleModalCreate}
                                    >
                                        <PlusIcon className="w-5 h-5 " />
                                        <span className="font-semibold">
                                            {translator("REWARD.ADD_REWARD")}
                                        </span>
                                    </ButtonPrimary>
                                </div>}

                            </>
                        )}
                        {(selectedIndex === 3 && checkPermission('rewardCoinLevels', 'U')) && (
                            <div className="col-span-4 flex items-center justify-end">
                                <ButtonPrimary
                                    onClickButton={saveApoLevel}
                                    disable={!canSaveApoLevel}
                                    title="Save"
                                >
                                    {savedApoLevel ? (
                                        <CircleSpin color="text-white" />
                                    ) : (
                                        <>
                                            <span className="font-semibold">
                                                {translator("Save")}
                                            </span>
                                        </>
                                    )}
                                </ButtonPrimary>
                            </div>
                        )}
                    </Tab.List>
                    <Tab.Panels className="z-1  h-full outline-none border-t">
                        <Tab.Panel className="mx-2">
                            {checkPermission('rewardDashboard', 'R') && <RewardDashBoard />}
                        </Tab.Panel>
                        <Tab.Panel className="mx-2">
                            {checkPermission('rewardRewards', 'R') && <>
                                {isLoading ? (
                                    <div className="flex items-center justify-center mt-6">
                                        <CircleSpin color="text-primary-500" />
                                    </div>
                                ) : dataList?.items?.length > 0 ? (
                                    <>
                                        <div className="h-[calc(100vh-175px)] max-w-full overflow-x-auto custom-scrollbar overflow-y-scroll">
                                            <TableReward
                                                data={dataList?.items}
                                            />
                                        </div>
                                        <div className="flex justify-between opacity-100 border-t">
                                            <div className="">
                                                <p className="text-ooolab_dark_2 text-xs font-semibold leading-ooolab_24px pt-2">
                                                    Showing{' '}
                                                    {dataList.page *
                                                        dataList.per_page -
                                                        dataList.per_page +
                                                        1}
                                                    -
                                                    {dataList?.items?.length +
                                                        dataList.per_page *
                                                        (dataList.page -
                                                            1)}{' '}
                                                    of {dataList.total}
                                                </p>
                                            </div>
                                            <div className="">
                                                <TablePagination
                                                    onClickPagination={handleChangePagination}
                                                    perPage={dataList?.per_page || 1}
                                                    total={dataList?.total || 1}
                                                    forcePage={(dataList?.page || 1) - 1}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <NoContentWithImage content={translator("REWARD.NO_REWARDS")} />
                                )}
                            </>}

                        </Tab.Panel>
                        <Tab.Panel className="mx-2">
                            {checkPermission('rewardFeaturedRewards', 'R') && <RewardFeatures />}

                        </Tab.Panel>
                        <Tab.Panel className="">
                            {checkPermission('rewardCoinLevels', 'R') && <ApoLevelComponent
                                save={savedApoLevel}
                                onSaved={() => {
                                    setSavedApoLevel(false);
                                }}
                                onCanSave={(event) => {
                                    setCanSaveApoLevel(event);
                                }}
                            />}

                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>

            <DialogComponent
                isOpen={modalCreate}
                width={''}
                title={translator('Add Reward')}
                onCloseModal={toggleModalCreate}
                child={
                    <CreateRewardForm
                        onSuccessCreate={() => {
                            handleCreateSuccess();
                        }}
                        closeModal={toggleModalCreate}
                        callbackWhenDirty={setShouldWarningBeforeClose}
                        selectedIndex={selectedIndex}
                    />
                }
            />
        </div>
    );
};

export default RewardList;
