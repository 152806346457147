import React from 'react';
import { Row, Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Table, { RenderTableHeader, RenderTableCell, LinkCustom } from 'components/V2/Table/TableCustom';
import { checkPermission } from 'utils/commonFun';
import dayjs from 'dayjs';
import { ArrowDownIcon, ArrowUpIcon, TrashIcon } from '@heroicons/react/outline';
import { EditLine } from 'assets/icon';
import ActionsButton from 'components/ActionsButton';
import { ImageDefault } from 'components/Image';

const TableCertificates = ({ workspaceId, dataTable, order, onOder, onEdit, onDelete }) => {
    const { t: translator } = useTranslation();
    const history = useHistory()

    const columns = React.useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('CERTIFICATE_MANAGEMENT.NAME')} />,
                accessor: 'name',
                Cell: ({ row, column }: { row: any; column: Column }) => {
                    return (
                        <div className="flex items-center">
                            <ImageDefault imageSrc={row.original?.image} className='!rounded !w-5 !h-5 flex-none' />
                            {/* <LinkCustom
                                to={`/workspace/${workspaceId}/management/certificate/${row.original?.id}`}
                                value={row.values?.name}
                                className="text-truncate"
                            /> */}
                            <RenderTableCell value={row.values?.name} className='!text-enterprise font-semibold' />
                        </div>
                    );
                },
                width: 120,
            },
            {
                Header: () => <RenderTableHeader value={translator('CERTIFICATE_MANAGEMENT.DESCRIPTION')} />,
                accessor: 'description',
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return <RenderTableCell value={row.values?.description} />;
                },
                width: 250,
            },
            {
                Header: () => (
                    <div className="flex items-center cursor-pointer text-primary-500" onClick={onOder}>
                        <RenderTableHeader className="!text-primary-500" value={translator('RESOURCES.Last_Modified')} />
                        {order === 'desc' ? <ArrowDownIcon className="w-4 h-4 ml-1 " /> : <ArrowUpIcon className="w-4 h-4 ml-1 " />}
                    </div>
                ),
                accessor: 'updated_at',
                Cell: ({ row }) => (
                    <div className="h-full flex items-center cursor-default">
                        <RenderTableCell value={dayjs(row.values?.updated_at).format('DD/MM/YYYY')} />
                    </div>
                ),
                width: 50,
            },
            {
                Header: () => <></>,
                accessor: 'action',
                Cell: ({ row, column }: { row: any; column: Column }) => {
                    return (
                        <div className="h-full flex items-center">
                            <ActionsButton
                                menus={[
                                    {
                                        label: translator('EDIT'),
                                        icon: <EditLine />,
                                        isDisabled: !checkPermission('cert', 'U'),
                                        action: () => history.push(`/workspace/${workspaceId}/management/certificate/${row.original?.id}`),
                                        hide: false,
                                    },
                                    {
                                        label: translator('DELETE'),
                                        icon: <TrashIcon width="18" height="16" className="text-gray-900" />,
                                        isDisabled: !checkPermission('cert', 'D'),
                                        hide: false,
                                        action: () => onDelete(row.original),
                                    },
                                ]}
                                position={row?.index > dataTable?.length/2 ? 'bottom-10': ''}
                            />
                        </div>
                    );
                },
                width: 20,
            },
        ],
        [dataTable]
    );

    return <Table data={dataTable} columns={columns} noBorder emptyContent={translator('CERTIFICATE_MANAGEMENT.NO_TEMPLATE')} />;
};

export default TableCertificates;
