import React from "react";
import { StudentAssignmentsList } from "./components/StudentAssignments/StudentAssignmentsList";
import { StudentAssignmentsOverview } from "./components/StudentAssignments/StudentAssignmentsOverview";
import { StudentAssignmentsTimeSpent } from "./components/StudentAssignments/StudentAssignmentsTimeSpent";

export const StudentDashboardAssignments = () => {
    return<>
        <StudentAssignmentsOverview />
        <div className="mt-4">
            <StudentAssignmentsTimeSpent />
        </div>
        <div className="mt-4">
            <StudentAssignmentsList />
        </div>
    </>
}