import * as React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import h5pMiddleware from 'middleware/h5p.middlware';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import H5PPlayerContentComponent from 'components/H5P/H5PPlayer/H5PPlayerContentComponent';
import { H5PContext } from 'contexts/H5P/H5PContext';
import { IContentListEntry } from 'types/H5P.type';
import { ChevronDownIcon, ChevronLeftIcon } from '@heroicons/react/outline';
import { NextIcon, PreviousIcon } from 'assets/icon';
import { ListIcon, ZoomInIcon, ZoomOutIcon } from 'assets/icon/';
import ModalShareLink from 'components/V2/Modals/ModalShareLink';
import Spinner from 'components/Spinner';
import PencilIcon from 'assets/icon/PencilIcon';
import CopyLinkIcon from 'assets/icon/CopyLinkIcon';
import { useTranslation } from 'react-i18next';
import './ContentPreview.scss';
import ReactTooltip from "react-tooltip";
import { getParamFromUrl } from 'utils/handleString';
import { activityWatcher, checkPermission, destroyDisabledCopyPast, disabledCopyPast } from 'utils/commonFun';
import { AspectRatioBox } from 'components/AspectRatioBox';

const ContentPreview: React.FC = () => {
    const params: { id: string; contentId: string, courseId: string } = useParams();
    const history = useHistory();
    const [isZoom, setZoom] = React.useState(false);
    const [openModalShareLink, setOpenModalShareLink] = React.useState(false);
    const [showLoading, setShowLoading] = React.useState(true);
    const [page, setPage] = React.useState({ index: 1, total: 1 });
    const from = getParamFromUrl(useLocation()?.search, 'from');
    const courseId = getParamFromUrl(useLocation()?.search, 'courseId');

    const argsId = React.useMemo(() => {
        return {
            workspaceId: params.id,
            contentId: params.contentId,
        };
    }, [params]);

    const [entryState, setEntryState] = React.useState({
        editing: false,
        playing: false,
        loading: false,
        saved: false,
        saving: false,
        saveError: false,
        saveErrorMessage: '',
    });
    const h5PCtx = React.useContext(H5PContext);
    React.useEffect(() => {
        if (params?.contentId) {
            h5pMiddleware.getCurentH5P(dispatch, argsId);
        }
    }, [params?.contentId]);
    const [h5pContentListEntryState, setH5PContentListEntryState] = React.useState<IContentListEntry>();
    const [h5pData, setH5PData] = React.useState<IContentListEntry>();
    React.useEffect(() => {
        if (h5pContentListEntryState) {
            setH5PData(h5pContentListEntryState);
        }
    }, [h5pContentListEntryState]);

    const {
        dispatch,
        H5PState: { currentH5P },
    } = h5PCtx;

    React.useEffect(() => {
        if (currentH5P) {
            setH5PContentListEntryState({
                argsId: argsId,
                contentId: argsId.contentId,
                mainLibrary: currentH5P?.metadata?.mainLibrary,
                title: currentH5P?.metadata?.title,
                status: currentH5P?.status,
                tags: currentH5P?.tags,
                uid: currentH5P?.uid,
                updated_at: currentH5P?.updated_at,
                path: currentH5P?.path || null,
            });
        }
    }, [currentH5P]);

    React.useEffect(() => {
        setShowLoading(true);
        clearInterval(intervalRemoveIconZoom);
        var intervalRemoveIconZoom = setInterval(() => {
            const iframe = document?.getElementsByClassName('h5p-iframe')[0];
            if (iframe) {
                //@ts-ignore
                const zoom = iframe?.contentWindow?.document?.getElementsByClassName('h5p-footer-toggle-full-screen')?.[0];
                if (zoom) {
                    zoom.style.visibility = 'hidden';
                }
                setShowLoading(false);
                clearInterval(intervalRemoveIconZoom);
            }
        }, 1000);
    }, [isZoom]);

    React.useEffect(() => {
        activityWatcher(function () {
            history.goBack();
        });
        disabledCopyPast();

        return () => {
            destroyDisabledCopyPast();
        };
    }, []);

    const openFullScreen = () => {
        const elem = document?.getElementById('presentation-mode');
        if (elem) {
            elem.requestFullscreen();
            //@ts-ignore
        } else if (elem.webkitRequestFullscreen) {
            /* Safari */
            //@ts-ignore
            elem.webkitRequestFullscreen();
            //@ts-ignore
        } else if (elem.msRequestFullscreen) {
            /* IE11 */
            //@ts-ignore
            elem.msRequestFullscreen();
        }
    };

    /* Close fullscreen */
    const closeFullscreen = () => {
        // const elem = document.getElementById("presentation-mode")
        if (document?.exitFullscreen) {
            document.exitFullscreen();
            //@ts-ignore
        } else if (document.webkitExitFullscreen) {
            /* Safari */
            //@ts-ignore
            document.webkitExitFullscreen();
            //@ts-ignore
        } else if (document.msExitFullscreen) {
            /* IE11 */
            //@ts-ignore
            document.msExitFullscreen();
        }
    };

    const { t } = useTranslation();

    const onGoback = () => {
        const folderId = h5pData?.path?.slice(-1)?.[0]?.id;
        if (folderId) {
            history.push(`/workspace/${params?.id}/library/${folderId}`)
        } else {
            history.push(`/workspace/${params?.id}/library?tab=library`)
        }
    }

    return (
        <div id="presentation-mode" className="presentation-mode">
            <div className={`header ${isZoom ? 'group' : ''}`}>
                <div className={`${isZoom ? 'hidden' : 'flex'} header-container group-hover:flex`}>
                    <div className="header-icon" onClick={() => {
                        if (from == 'resourse') {
                            return history.push(`/workspace/${params?.id}/management/resources`);
                        }
                        if (from == 'course-resourse') {
                            return history.push(`/workspace/${params?.id}/management/courses/${courseId}?tab=academics&subtab=resources`);
                        }
                        onGoback()
                    }}>
                        <div className="background-icon">
                            <ChevronLeftIcon className="icon" />
                        </div>
                        {(from == 'resourse' || from == 'course-resourse') ? t('RESOURCES.RESOURCES') : t('DASHBOARD.SIDEBAR.LIBRARY')}
                    </div>
                    <div className="header-title">{h5pData?.title}</div>
                    <div className="header-group-icon">
                        {checkPermission('libraryContentFile', "U") && <div
                            className="sub-icon"
                            onClick={() => history.push(`/workspace/${params.id}/h5p-content/${params.contentId}`)}
                        >
                            <PencilIcon />
                        </div>}
                        {checkPermission("libraryContentFile", "SR") && <div
                            onClick={() => {
                                setOpenModalShareLink(true);
                                closeFullscreen();
                                setZoom(false);
                            }}
                            className="sub-icon"
                        >
                            <CopyLinkIcon />
                        </div>}

                    </div>
                </div>
            </div>
            <div className={`main-content min-h-[450px] h-[calc(100vh)] ${!isZoom ? 'pt-14 pb-16' : 'py-0'}`}>
                <div key={`iframe-${isZoom}`} className={`h5p-container ${isZoom ? 'zoom' : ''}  `}>
                    {showLoading && (
                        <div className="h5p-container-loading">
                            {' '}
                            <Spinner />{' '}
                        </div>
                    )}
                    {currentH5P ? (
                        <AspectRatioBox>
                            <H5PPlayerContentComponent argsId={argsId} entryState={entryState} setEntryState={setEntryState} />
                        </AspectRatioBox>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            <div className={`navigation ${isZoom ? 'group z-100' : ''}`}>
                <div className={`${isZoom ? 'hidden' : 'flex'} navigation-container group-hover:flex`}>
                    <div className="navigation-dropdown">
                        <ListIcon />
                        <div className="navigation-dropdown-button">
                            <span className="file-name"
                                data-for="file-name"
                                data-tip={h5pData?.title}
                            >
                                {h5pData?.title}
                            </span>
                            <ChevronDownIcon className="icon" />
                            {/*@ts-ignore*/
                                <ReactTooltip
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    id={`file-name`}
                                    getContent={(value) => <div>{value}</div>}
                                />}
                        </div>
                    </div>
                    <div className="navigation-button">
                        <div className="opacity-50">
                            <PreviousIcon />
                        </div>
                        <div className="line"></div>
                        <div className="index-page">{page.index}</div>
                        <div className="total-slide">/ {page.total}</div>
                        <div className="line"></div>
                        <div className="opacity-50">
                            <NextIcon />
                        </div>
                    </div>
                    <div
                        id="zoom-presentation"
                        onClick={() => {
                            isZoom ? closeFullscreen() : openFullScreen();
                            setZoom(!isZoom);
                        }}
                        className={`button-zoom`}
                    >
                        {isZoom ? <ZoomOutIcon /> : <ZoomInIcon />}
                    </div>
                </div>
            </div>
            {openModalShareLink ? (
                <ModalShareLink
                    isOpen={openModalShareLink}
                    onClose={() => setOpenModalShareLink(false)}
                    value={{ ...currentH5P, object_type: 'h5p' }}
                />
            ) : (
                ''
            )}
        </div>
    );
};

export default ContentPreview;
