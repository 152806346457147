import { FC, useContext, useMemo, useState } from 'react';
import { CourseType } from 'types/Courses.type';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import ActionMenu from './ActionMenu';
import dayjs from 'dayjs';
import './Table.scss'
import { UserContext } from 'contexts/User/UserContext';
import { User } from '_shared/components/User/User';
import { useHistory, useParams } from 'react-router-dom';
import EnrollmentStatus from '_shared/components/EnrollmentStatus/EnrollmentStatus';
import NO_LEARNER from 'assets/img/empty-state/student.png'
import { useAppSelector } from 'hooks/hooks';
import DialogComponent from 'components/Dialog/DialogComponent';
import { ModalDepositRefund } from 'components/Workspace/Course/CourseBuilder/components/CourseCreditBalance/ModalDepositRefund';

interface IViewTable {
    data: CourseType[];
    refreshData?: () => void;
}

const ViewTable: FC<IViewTable> = ({
    data,
    refreshData,
}) => {
    const { t: translator } = useTranslation();
    const {setting} = useAppSelector((state) => state.course);
    
    const [rowData, setRowData] = useState<any>(null);   

    const params: any = useParams();
    const {
        userState: { result },
    } = useContext(UserContext);
    const history = useHistory();

    const columns = useMemo(
        () => [
            
            {
                Header: () => <RenderTableHeader value={translator('COURSES.LEARNER')} />,
                accessor: 'user',
                Cell: ({row}: any) => {
                    return (
                        <div 
                            className='flex items-center gap-1 font-semibold text-primary-500 cursor-pointer' 
                            onClick={() => window.open(`/workspace/${params?.id}/management/users/${row?.values?.user?.id}?tab=information`)}
                        >
                            <img className='w-5 h-5 rounded-full' src={row?.values?.user?.avatar_url} />
                            <div className='text-xs text-ellipsis truncate'>{row?.values?.user?.display_name}</div>
                        </div>
                    )
                },
                width: 100
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.ENROLLMENT')} />,
                Cell: ({row}: any) => {
                    return (
                        <RenderTableCell 
                            onClick={() => history?.push(`/workspace/${params.id}/management/courses/${params.courseId}?tab=people&subtab=enrollment&enrollmentId=${row.values?.course_enrollment?.id}&view=information`)} 
                            className='text-primary-500 font-semibold cursor-pointer' value={`(${row.values?.course_enrollment?.id}) ${row.original?.learning_group?.name || row.original?.user?.display_name}`} 
                        />
                    )
                },
                accessor: 'course_enrollment',
                width: 100
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.START_DATE')} />,
                accessor: 'start_date',
                Cell: ({row}: any) => <RenderTableCell value={dayjs.utc(row.original?.course_enrollment?.start_date).tz(result?.time_zone).format("DD/MM/YYYY")} />,
                width: 70
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.END_DATE')} />,
                accessor: 'end_date',
                Cell: ({row}: any) => <RenderTableCell value={dayjs.utc(row.original?.course_enrollment?.end_date).tz(result?.time_zone).format("DD/MM/YYYY")} />,
                width: 70
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.STATUS')} />,
                accessor: 'display_status',
                Cell: ({row}: any) => {
                    if (!row?.values?.display_status) return <RenderTableCell value='-'/>
                    return (
                        <EnrollmentStatus status={row?.values?.display_status} />
                    );
                },
                width: 100
            },
            setting?.enrollment_type === 'credit' ? 
                {
                    Header: () => <RenderTableHeader value={translator('COURSES.CREDITS')} />,
                    accessor: 'credit_balance',
                    Cell: ({row}: any) => <RenderTableCell value={<div><span className='font-semibold'>{(row?.original?.credit_balance?.total_credit - row?.original?.credit_balance?.available_credit )|| 0}</span>/{row?.original?.credit_balance?.total_credit || 0}</div>} />,
                    width: 70
                }
                : 
                {
                    Header: () => null,
                    accessor: 'credit_balance',
                    Cell: () => null,
                    width: 0
                },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.ADDED_BY')} />,
                accessor: 'creator',
                Cell: ({row}: any) => {
                    if (!row?.values?.creator) {
                        return <RenderTableCell value="-" />
                    }
                    return (
                        <User 
                            name={row?.values?.creator?.display_name}
                            avatar={row?.values?.creator?.avatar_url} 
                            link={`/workspace/${params?.id}/management/users/${row?.values?.creator?.id}?tab=information`}
                        /> 
                    )
                },
                width: 80
            },
            setting?.enrollment_type === 'credit' ?
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="h-full flex items-center justify-center">
                            <ActionMenu
                                data={row?.original}
                                position={row?.index > data?.length/2 ? 'bottom-0' : ''}
                                onDeposit={() => setRowData(row?.original)}
                            />
                        </div>
                    );
                },
                accessor: 'action',
                width: 40,
                
            }
            :
            {
                Header: () => null,
                Cell: () => null,
                accessor: 'action',
                width: 0,
            }
            ,
        ],
        [data, setting]
    );

    return (
        <>
            <Table
                data={data || []}
                columns={columns}
                emptyIcon={NO_LEARNER}
                emptyContent={translator("COURSES.NO_LEARNERS")}
            />
            <DialogComponent
                isOpen={!!rowData}
                onCloseModal={() => setRowData(false)}
                title={translator('COURSES.CREDIT_BALANCE.DEPOSIT_CREDITS')}
                styles="max-w-md"
                child={
                    <ModalDepositRefund
                        translator={(key, isNew) => isNew ? translator(key) : translator(`COURSES.CREDIT_BALANCE.${key}`)}
                        type='deposit'
                        data={rowData}
                        onClose={() => setRowData(null)}
                        onConfirm={() => {
                            setRowData(null);
                            refreshData?.();
                        }}
                    />
                }
            />
        </>
    );
};

export default ViewTable;
