import ButtonComponent from 'components/Button/ButtonComponent';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogComponent from 'components/Dialog/DialogComponent';
import { AsyncSelectField } from 'components/Select';
import { useBoolean } from 'hooks/custom';
import { useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';
import { useForm } from 'react-hook-form';
import { Toast } from 'utils/sweetAlert';
import { PlusIcon } from '@heroicons/react/outline';
import classService from 'services/class.service';
import SearchBox from 'components/SearchBox';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import Spinner from 'components/Spinner';
import TableStudent from './TableStudent';
import { useDebouncedState } from 'hooks/useDebounce';
import Pagination from 'components/V2/Pagination';

const PER_PAGE = 20;
export default function StudentTab({ data }) {
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm();

    const [keyword, setKeyword] = useDebouncedState('');
    const { t: translator } = useTranslation();
    const [students, setLeaners] = useState([]);
    const { booleanValue: modalAdd, toggleBooleanValue: toggleModalAdd } = useBoolean();
    const params: any = useParams();
    const [loading, setLoading] = useState(false);
    const workspaceId = params?.id;
    const companyId = params?.companyId;
    const [indexPage, setIndexPage] = useState('0-0');

    const [pagination, setPagination] = useState({
        page: 1,
        total: 0,
        order: 'desc',
        per_page: PER_PAGE,
        sort_by: 'created_at',
    });

    useEffect(() => {
        getLearnerOfCompanyList();
    }, [keyword]);

    const getLearnerOfCompanyList = (page: number = 1) => {
        setLoading(true);
        const payload = {
            ...pagination,
            page: page || pagination?.page,
            sort_by: 'created_at',
            order: 'desc',
            company: companyId,
            q: keyword,
        };
        classService
            .getStudentListService(workspaceId, payload)
            .then((res) => {
                const { items, page, per_page, order, sort_by, total } = res?.data;
                setLeaners(items);
                setPagination({ page, per_page, order, sort_by, total });
                setIndexPage(getIndexPage(page, per_page, items?.length));
            })
            .finally(() => setLoading(false));
    };

    const fetchLearners = async (bodyParams?: any) => {
        const res = await classService.getStudentListService(workspaceId, bodyParams);
        return {
            items: res?.data?.items,
            total: res?.data?.total,
        };
    };

    const handleLearnerLoadOptions = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional?.page,
            per_page: PER_PAGE,
            sort_by: 'email',
            order: 'desc',
            q: inputValue || null,
        };
        if (!payload?.name) delete payload?.name;

        const { items, total } = await fetchLearners(payload);
        const newOptions = items.map((el: any) => ({
            id: el?.id,
            label: el?.parent?.email || el?.email,
            value: el?.id,
            avatar: el?.avatar_url,
        }));

        return {
            options: newOptions,
            hasMore: additional?.page < total / PER_PAGE,
            additional: {
                page: additional?.page + 1,
            },
        };
    };

    const onSubmit = (val) => {
        const learnerList = val?.student?.map((student) => student?.value);
        workspaceService
            .updateCompanyService(workspaceId, companyId, { ...data, invite_students: learnerList })
            .then((res) => {
                getLearnerOfCompanyList();
                Toast.fire({
                    icon: 'success',
                    title: translator('COMPANY.COMPANY_DETAIL.ADD_LEARNER_SUCCESS'),
                });
            })
            .catch((err) => {
                Toast.fire({
                    icon: 'error',
                    title: translator('COMPANY.COMPANY_DETAIL.SELECT_STUDENT_ERROR'),
                });
            })
            .finally(() => {
                toggleModalAdd();
                setValue('student', []);
            });
    };

    const handlePagination = (page) => {
        getLearnerOfCompanyList(page);
    };

    const renderList = () => {
        return (
            <div className="pb-3 pt-6 border-b border-light-500 gap-4 text-sm font-semibold">
                <div className="flex justify-between p-4 px-0 border-t border-ooolab_bar_color">
                    <SearchBox
                        placeholder={translator('COMPANY.COMPANY_DETAIL.SEARCH_NAME_EMAIL')}
                        onSubmit={(e: any) => setKeyword(e)}
                    />
                    {checkPermission('companyDetailsLearner', 'U') && <div className="lg:flex lg:justify-end">
                        <ButtonComponent
                            title={translator('COMPANY.COMPANY_DETAIL.ADD_STUDENT')}
                            disable={status === 'inactive'}
                            classStyle="xs:p-2 xs:text-sm primary-button !bg-enterprise"
                            onClickButton={toggleModalAdd}
                            icon={<PlusIcon className="w-4 h-4" />}
                        />
                    </div>}

                </div>
                <div className="h-[calc(100vh-300px)] custom-scrollbar overflow-auto">
                    {loading ? 
                        <div className='h-[calc(100vh-300px)] flex justify-center items-center'><Spinner /></div>
                        : 
                        <TableStudent workspaceId={workspaceId} dataTable={students} translator={translator} />
                    }
                </div>
                <Pagination
                    indexPage={indexPage}
                    total={pagination?.total}
                    per_page={pagination?.per_page}
                    page={pagination?.page}
                    callback={handlePagination}
                />
            </div>
        );
    };

    return (
        <div className="px-6 text-ooolab_dark_1 text-xs font-normal">
            {renderList()}
            <DialogComponent
                isOpen={modalAdd}
                title={''}
                onCloseModal={() => {
                    toggleModalAdd();
                }}
                maskClosable={false}
                styles="max-w-md"
                child={
                    <form>
                        <AsyncSelectField
                            control={control}
                            label={translator('COMPANY.EMAIL')}
                            name="student"
                            className="col-span-4"
                            placeholder={translator('COMPANY.EMAIL')}
                            isRequired
                            hideSelectedOptions
                            isMulti
                            hasAvatar
                            closeMenuOnSelect={false}
                            errors={errors}
                            loadOptions={handleLearnerLoadOptions}
                        />

                        <div className="flex items-center justify-center space-x-4 mt-8">
                            <ButtonComponent
                                title={translator('COMPANY.COMPANY_DETAIL.CANCEL')}
                                classStyle="xs:p-2 xs:text-sm secondary-button !text-dark-300 !border-dark-300"
                                onClickButton={toggleModalAdd}
                            />
                            <ButtonComponent
                                title={translator('COMPANY.COMPANY_DETAIL.ADD')}
                                classStyle="xs:p-2 xs:text-sm primary-button !bg-enterprise"
                                onClickButton={handleSubmit(onSubmit)}
                            />
                        </div>
                    </form>
                }
            />
        </div>
    );
}
