import { FC, useContext, useMemo } from 'react';
import { CourseType } from 'types/Courses.type';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import ActionMenu from './ActionMenu';
import ReactTooltip from 'react-tooltip';
import './ViewTable.scss'
import NO_CONTENT from 'assets/img/empty-state/course.png'
import { COURSE_TYPE } from '../../constants';
import { useHistory, useParams } from 'react-router-dom';
import { COURSE_STATUS } from 'constant/course.const';
import { checkPermission } from 'utils/commonFun';
import EnrollmentStatus from '_shared/components/EnrollmentStatus/EnrollmentStatus';

interface IViewTable {
    data: CourseType[];
    refreshData: Function
}

const ViewTable: FC<IViewTable> = ({
    data, refreshData
}) => {
    const { t: translator } = useTranslation();
    const history = useHistory();
    const params: any = useParams()
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;

    const initialState = {
        hiddenColumns: ['id'],
    };

    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('COURSES.NAME')} />,
                Cell: ({ row }) => {
                    return <RenderTableCell onClick={() => history.push(`/workspace/${params?.id}/management/courses/${row?.original?.id}`)} className="cursor-pointer text-primary-500 font-semibold" value={row.values?.title} />;
                },
                accessor: 'title', // accessor is the "key" in the data
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.COURSE_TYPE')} />,
                accessor: 'type',
                Cell: ({ row }: any) => {
                    const length = row?.values?.type?.length;
                    if (!length) return <RenderTableCell value={''} />
                    return (
                        <div className='CourseType'>
                            {row?.values?.type?.slice(0, 3)?.map((item) => (
                                <div key={item} className='CourseType-item'>
                                    {COURSE_TYPE?.[item]}
                                </div>
                            ))}
                            {length > 3 && <div className='CourseType-viewmore'>
                                <div
                                    data-tip={row?.values?.type?.slice(3)?.map(item => COURSE_TYPE?.[item])?.join(', ')}
                                    data-for={`${row?.original?.id}-tooltip`}
                                >
                                    +{length - 3}
                                </div>
                                {/*@ts-ignore*/
                                    <ReactTooltip
                                        place="bottom"
                                        type="dark"
                                        effect="float"
                                        id={`${row?.original?.id}-tooltip`}
                                        getContent={(content) => content}
                                    />}
                            </div>}
                        </div>
                    )
                },
                width: 200
            },

            {
                Header: () => <RenderTableHeader value={translator('COURSES.LEVEL')} />,
                accessor: 'level',
                Cell: ({ row }: any) => {
                    const length = row?.values?.level?.length
                    if (!length) return <RenderTableCell value={''} />
                    return (
                        <div className='CourseType'>
                            {row?.values?.level?.slice(0, 3)?.map((item) => (
                                <div key={item} className='CourseType-item'>
                                    {item}
                                </div>
                            ))}
                            {length > 3 && <div className='CourseType-viewmore'>
                                <div
                                    data-tip={row?.values?.level?.slice(3)?.map(item => item)?.join(', ')}
                                    data-for={`${row?.original?.id}-tooltip`}
                                >
                                    +{length - 3}
                                </div>
                                {/*@ts-ignore*/
                                    <ReactTooltip
                                        place="bottom"
                                        type="dark"
                                        effect="float"
                                        id={`${row?.original?.id}-tooltip`}
                                        getContent={(content) => content}
                                    />}
                            </div>}
                        </div>
                    )
                }
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.STATUS')} />,
                accessor: 'status',
                Cell: ({ row }: any) => {
                    if (!row?.values?.status) return <RenderTableCell value='' />;
                    return (
                        <EnrollmentStatus status={row?.values?.status} />
                    );
                },
            },
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="CourseAction">
                            {checkPermission('course', 'U') && <ActionMenu
                                data={row?.original}
                                refreshData={refreshData}
                                position={row?.index > data?.length / 2 ? 'bottom-0' : ''}
                            />}

                        </div>
                    );
                },
                accessor: 'action',
                width: 40,
            },
        ],
        [data, WorkspaceDetailInformation]
    );

    return (
        <Table
            data={data}
            columns={columns}
            initialState={initialState}
            emptyContent={translator('COURSES.NO_COURSE')}
            emptyIcon={NO_CONTENT}
        />
    );
};

export default ViewTable;
