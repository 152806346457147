import { useTranslation } from "react-i18next";

import chart from 'assets/img/empty-state/chart.png';
import "./EmptyStateChart.scss";

export const EmptyStateChart = () => {
    const {t: translator} = useTranslation();
    return <>
        <div className="emptyStateChart">
            <img src={chart} className="emptyStateChart-img" />
            <span className="emptyStateChart-text">{translator('FORM_CONST.NO_DATA')}</span>
        </div>
    </>
}