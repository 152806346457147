import { ArrowRightIcon, CheckIcon, XIcon } from '@heroicons/react/outline';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { EditIcon } from 'assets/icon';
import DialogComponent from 'components/Dialog/DialogComponent';
import { InputField } from 'components/InputForm/InputForm';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useContext, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';
import { VALIDATE_FIELD } from 'utils/validate';

export default function ModalDepositCredit({ isOpen, onClose, data, refreshData }) {
    const {
        watch,
        control,
        handleSubmit,
        formState: { errors, isValid },
        register,
        setValue,
        reset
    } = useForm({ mode: 'onChange' });
    
    const workspaceState = useContext(GetWorkspaceContext);
    const learningGroupName = workspaceState?.getWorkspaceDetailState?.currentRouteDetail?.[0]?.name || '';

    const { t } = useTranslation();
    const params: {id} = useParams();

    const onSubmit = (value) => {
        const param = value?.credits?.map((item) => {
          const credit  = {
            credit_account_id:  item?.credit_account_id,
            amount: +value?.amount,
            note: item?.note
          }
          if (!credit?.note) delete credit.note
          return credit;
        })
        workspaceService.depositCredit(params?.id, param)
        .then(() => {
          Toastify.success();
          refreshData();
        })
        .catch(() => {
          Toastify.error();
        })
        .finally(() => {
          reset({
            credits: [],
            amount: null
          })
          onClose()
        })
    };

    const { fields, replace } = useFieldArray({
        control,
        name: 'credits',
    });

    const credits = () => {
        const credits = data?.map((item) => {
            return {
                ...item,
                note: item?.note,
                isEditing: false,
            };
        });
        replace(credits);
    };

    useEffect(() => {
        credits();
    }, [data, watch('amount')]);

    return (
        <DialogComponent
            title={t("USER.CREDIT_ACCOUNT.DEPOSIT_CREDITS")}
            isOpen={isOpen}
            onCloseModal={onClose}
            styles="!max-w-2xl"
            child={
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="bg-blue-50 px-6 py-4 flex gap-5 -mx-5">
                        <InputField
                            name="amount"
                            type="number"
                            isRequired
                            errors={errors}
                            placeholder={t("USER.CREDIT_ACCOUNT.ENTER_HERE")}
                            label={t("USER.CREDIT_ACCOUNT.NUMBER_OF_CREDIT_S")}
                            className="text-sm w-[160px]"
                            register={register('amount', {
                                required: t('FORM_CONST.REQUIRED_FIELD'),
                                pattern: {
                                    value: VALIDATE_FIELD.NUMBER_1_100000,
                                    message: t('USER.CREDIT_ACCOUNT.Enter_number_1_100000'),
                                },
                            })}
                        />
                        <div>
                            <div className="text-sm font-semibold">{t("USER.CREDIT_ACCOUNT.ACCOUNT_NAME")}</div>
                            <div className="text-sm flex items-center h-9">{learningGroupName}</div>
                        </div>
                    </div>

                    <div className="mt-5">
                        <div className="grid grid-cols-8 text-sm font-semibold border-b border-gray-300 pb-2">
                            <div className="col-span-2 px-1">{t("USER.CREDIT_ACCOUNT.ACCOUNT_OWNERS")}</div>
                            <div className="col-span-2 px-1 truncate text-ellipsis">{t("USER.CREDIT_ACCOUNT.FINAL_CREDIT_BALANCE")}</div>
                            <div className="col-span-4 px-1">{t("USER.CREDIT_ACCOUNT.NOTES")}</div>
                        </div>
                        {fields?.map((item: any, index: number) => {
                            return (
                                <div className="grid grid-cols-8 text-sm py-2">
                                    <div className="col-span-2 text-ellipsis truncate px-1 flex items-center">{item?.display_name}</div>
                                    <div className="col-span-2 px-1 flex items-center gap-2">
                                        <span className="">{item?.credit_balance}</span>
                                        <ArrowRightIcon className="w-4 h-4" />
                                        <span className={`${watch('amount') ? 'text-green-500 font-semibold': 'text-gray-800' } `}>
                                            {watch('amount') ? +(watch('amount') || 0) + +item?.credit_balance : '-'}
                                        </span>
                                    </div>
                                    <div className="col-span-4 px-1">
                                        <div className="flex space-x-2 w-full">
                                            {!watch(`credits.${index}.note`) && !watch(`credits.${index}.isEditing`) && (
                                                <div
                                                    className="text-primary-500 flex items-center gap-1 cursor-pointer"
                                                    onClick={() => setValue(`credits.${index}.isEditing`, true)}
                                                >
                                                    <EditIcon fill="#0071ce" /> {t("USER.CREDIT_ACCOUNT.ADD_NOTE")}
                                                </div>
                                            )}
                                            {watch(`credits.${index}.isEditing`) && (
                                                <div className="" onClick={() => setValue(`credits.${index}.isEditing`, true)}>
                                                    <InputField
                                                        placeholder={t("COURSES.ENTER_NOTE_HERE")}
                                                        name={`credits.${index}.note`}
                                                        errors={errors}
                                                        classNameInput="max-h-6 text-xs"
                                                        register={register(`credits.${index}.note`, {
                                                          maxLength: {
                                                            value: 1000,
                                                            message: t('FORM_CONST.MAX_LENGTH_TEXT', {maxLength: 1000})
                                                          }
                                                        })}
                                                        inputProps={{autoFocus: true}}
                                                    />
                                                </div>
                                            )}

                                            {watch(`credits.${index}.note`) && !watch(`credits.${index}.isEditing`) && (
                                                <div
                                                    onClick={() => setValue(`credits.${index}.isEditing`, true)}
                                                    className="cursor-pointer text-ellipsis line-clamp-2 text-sm"
                                                >
                                                    {' '}
                                                    {watch(`credits.${index}.note`)}{' '}
                                                </div>
                                            )}
                                            {watch(`credits.${index}.isEditing`) && (
                                                <span
                                                    className="mt-0.5 cursor-pointer"
                                                    onClick={() => {
                                                        setValue(`credits.${index}.note`, item?.note);
                                                        setValue(`credits.${index}.isEditing`, false);
                                                    }}
                                                >
                                                    <XIcon className="w-4 h-4 text-red-500" />
                                                </span>
                                            )}
                                            {watch(`credits.${index}.isEditing`) && watch(`credits.${index}.note`)?.length <= 1000 && (
                                                <span
                                                    className={`mt-0.5 ${
                                                        !!errors[`credits.${index}.note`] ? 'cursor-not-allowed' : 'cursor-pointer'
                                                    }`}
                                                    onClick={() => {
                                                        setValue(`credits.${index}.note`, watch(`credits.${index}.note`));
                                                        setValue(`credits.${index}.isEditing`, false);
                                                    }}
                                                >
                                                    <CheckIcon className="w-4 h-4 text-green-500" />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className='mt-5 border-gray-300 border-t pt-5 flex justify-center gap-5 -mx-5'>
                      <ButtonOutline type={"button"} onClick={onClose}>{t("CANCEL")}</ButtonOutline>
                      <ButtonPrimary disabled={!isValid} type="submit" >{t("CONFIRM")}</ButtonPrimary>
                    </div>
                </form>
            }
        />
    );
}
