import { HOST_URL, USER } from 'constant/api.const';
import { CLOUD_STORAGE, isVideo, loadVideo } from 'constant/file.constant';
import httpClient from 'services/restful.service';
import { NormalResponseError, UploadFormDataResultType } from 'types/Common.type';
import { UploadResourceArgsType } from 'types/User.type';
import { VALIDATE_FIELD } from 'utils/validate';
import { Upload } from 'tus-js-client';

const uploadImageToAWS = async (body: FormData, url: string, onUploadProgress, cancelToken?: any) => {
    if (body) {
        const res = await httpClient.post(url, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress,
            cancelToken,
        });
        return res.status;
    }
};

const uploadResource = async (body: UploadResourceArgsType) => {
    if (body) {
        const { mime_type, file_extension, file_duration_seconds } = body;
        const filePublic = body.public;
        let payload: any = {
            mime_type,
            file_extension,
            public: filePublic,
            cloud_storage: body?.cloud_storage,
            file_size: body?.file_size,
            file_name: body?.file_name
        }
        if (file_duration_seconds) {
            payload = {
                ...payload,
                file_duration_seconds: file_duration_seconds
            }
        }
        const res = await httpClient.post(HOST_URL + USER.UPLOAD_AVATAR, payload);
        return res.data;
    }
};

/**
 * Upload image to CDN
 * @param image
 * @param access_token token of current user
 * @param onError
 * @param onSuccess callback to getting image path after uploading to CDN
 * */
const uploadFile = async (
    image: File,
    onSuccess?: (path: string) => void,
    onError?: (error: NormalResponseError | undefined) => void,
    onUploadProgress = (event) => null,
    cancelToken?: any
) => {
    const fileExtension = `.${VALIDATE_FIELD.FILE.exec(image.name)![1]}`;
    const fileType = fileExtension === '.h5p' ? 'application/h5p' : image.type;
    // post resource metadata then get information for uploading CDN
    const cloud_storage = isVideo(image) ? CLOUD_STORAGE.cfs : CLOUD_STORAGE.s3;
    let payload: any = {
        cloud_storage,
        public: true,
        mime_type: fileType,
        file_extension: fileExtension,
        file_size: image.size,
        file_name: image?.name?.replace(fileExtension, '')
    }

    if (cloud_storage === CLOUD_STORAGE.cfs) {
        const video: any = await loadVideo(image);
        if (video?.duration > 0) {
            payload = {
                ...payload,
                file_duration_seconds: Math.ceil(video?.duration)
            }
        }
    }
    const resource = await uploadResource(payload).catch(onError);

    if (cloud_storage == CLOUD_STORAGE.s3) {
        uploadToAWS(resource, image, onUploadProgress, cancelToken, onSuccess, onError);
    } else {
        uploadToCloud(resource, image, onUploadProgress, cancelToken, onSuccess, onError);
    }
};

const uploadToAWS = async (resource, image, onUploadProgress, cancelToken, onSuccess, onError) => {
    const uploadAvatarStateResult = resource as UploadFormDataResultType;

    //prepare form for uploading to CDN
    const form: FormData = new FormData();
    form.append('Content-Type', uploadAvatarStateResult.fields['Content-Type']);
    form.append('acl', uploadAvatarStateResult.fields.acl);
    form.append('key', uploadAvatarStateResult.fields.key);
    form.append('policy', uploadAvatarStateResult.fields.policy);
    form.append('tagging', uploadAvatarStateResult.fields.tagging);
    form.append('x-amz-algorithm', uploadAvatarStateResult.fields['x-amz-algorithm']);
    form.append('x-amz-credential', uploadAvatarStateResult.fields['x-amz-credential']);
    form.append('x-amz-date', uploadAvatarStateResult.fields['x-amz-date']);
    form.append('x-amz-security-token', uploadAvatarStateResult.fields['x-amz-security-token']);
    form.append('x-amz-signature', uploadAvatarStateResult.fields['x-amz-signature']);

    let file: Blob | undefined;

    form.append('file', file ?? image);
    try {
        await uploadImageToAWS(form, uploadAvatarStateResult.url, onUploadProgress, cancelToken);
        onSuccess(uploadAvatarStateResult.fields.key);
    } catch (err) {
        onError(err);
    }

}

const uploadToCloud = async (resource, image, onUploadProgress, cancelToken, onSuccess, onError) => {
    const uploadAvatarStateResult = resource as UploadFormDataResultType;
    const file = image;
    // Get upload URL from Server
    const uploadURL = resource?.url;
    const options = {
        uploadUrl: uploadURL,
        retryDelays: [0, 1000, 3000, 5000],
        chunkSize: 150 * 1024 * 1024,
        metadata: {
            filename: file.name,
            filetype: file.type
        },
        onError: function (error) {
            onError(error);
        },
        onProgress: function (bytesUploaded, bytesTotal) {
            onUploadProgress({
                loaded: bytesUploaded,
                total: bytesTotal
            });
        },
        onSuccess: function () {
            onSuccess(uploadAvatarStateResult.fields.key);
        }
    };
    const upload = new Upload(file, options);
    upload.start();
}

const getFile = (workspaceId: string, id: string) => {
    if (workspaceId && id) return httpClient.get(`${HOST_URL}/workspaces/${workspaceId}/files/${id}`);
};
const uploadFileFromGoogle = (workspaceId: string, params: any) => {
    if (workspaceId) return httpClient.post(`${HOST_URL}/workspaces/${workspaceId}/google-drive/files`, params);
};

const getResourceStreamStatus = (params: { key: string }) => {
    return httpClient.get(`${HOST_URL}/resources/stream/status`, { params });
}

export default {
    uploadResource,
    uploadFile,
    getFile,
    uploadFileFromGoogle,
    getResourceStreamStatus
};
