
export interface ResourcesType   {
    items: ResourcesItemsType[];
    page: number;
    per_page: number;
    total: number;
    sort_by: string;
    order: string;
};

export interface ResourcesItemsType  {
    id: number;
    workspace_id:number;
    mime_type:string;
    file_extension:string;
    file_name:string;
    file_display_name:string;
    file_url:string;
};

export interface ResourcesState {
   listResources?:ResourcesType;
};

export interface ResourcesAction extends ResourcesState {
    type: string;
}

export const initResourcesState: ResourcesState = {

};


