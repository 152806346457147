import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Prompt } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { InputField, LabelForm, TextareaField } from 'components/InputForm/InputForm';
import './certificate-detail.scss';
import DialogComponent from 'components/Dialog/DialogComponent';
import { ButtonOutline, ButtonPrimary, InputFile, Toastify } from '_shared';
import { checkPermission } from 'utils/commonFun';
import certificateService from 'services/certificate.service';
import { acceptFileCertificate } from 'components/Management/CertificateManagement/NewCertificateTemplate';
import { ImageFill } from 'assets/icon';
import ErrorMessageForm from 'components/ErrorMessageForm';
import uploadService from 'services/upload.service';
import { ModalConfirmCourseUseCertificateTemplate } from '../components-certificate/ModalConfirmCourseUseCertificateTemplate';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

export const CertificateDetail = ({ children }) => {
    const params: any = useParams();
    const { t: translator } = useTranslation();
    const history = useHistory();

    const [certificateDetail, setCertificateDetail] = useState<any>(null);
    const [isOpenModalDelete, setOpenModalDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [imgSrc, setImgSrc] = useState(null);
    const [certificateUsedCourse, setCertificateUsedCourse] = useState<any[]>([]);

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        getValues,
        watch,
        reset,
        setValue,
        setError,
        clearErrors,
    } = useForm();

    const workspaceId = params.id;
    const certificateId = params.certificateId;

    const fetchCertificateById = () => {
        certificateService
            .getCertificateByIdService(workspaceId, certificateId)
            .then((res) => {
                const objTemp = {
                    ...res?.data,
                    description: res?.data?.description || '',
                    learner_name: 'Kenneth Thompson',
                    course_name: 'English Speaking for Young Professional',
                    certificate_date: '11/12/2023',
                    certificate_id: 'ID20956Y23',
                };
                setCertificateDetail(objTemp);
                reset(objTemp);
            })
            .catch(() => Toastify.error());
    };

    const updateCertificateTemplate = (payload) => {
        if (imgSrc) {
            setValue('image', URL?.createObjectURL(imgSrc));
            setImgSrc(null);
        }

        certificateService
            .updateCertificateByIdService(workspaceId, certificateId, payload)
            .then(() => {
                fetchCertificateById();
                Toastify.success();
            })
            .catch(() => Toastify.error())
            .finally(() => setLoading(false));
    };

    const onDeleteCertificate = () => {
        setLoadingDelete(true);
        certificateService
            .deleteCertificateByIdService(workspaceId, certificateId)
            .then((res) => {
                Toastify.success();
                setOpenModalDelete(false);
                setIsDeleted(true);
                history.push(`/workspace/${workspaceId}/management/certificate`);
            })
            .catch((err) => {
                if (!!err?.response?.data?.courses?.length) {
                    setCertificateUsedCourse(err?.response?.data?.courses);
                } else Toastify.error();
                setOpenModalDelete(false);
            })
            .finally(() => setLoadingDelete(false));
    };

    const checkImageUpload = (file: any) => {
        setImgSrc(null);
        clearErrors('image');
        const mimeType = acceptFileCertificate;
        const limitFileSize = 100 * 1024 * 1024;

        const fileSize = file?.size || 0;
        const fileExtension = file?.type || '';

        if (!mimeType.includes(fileExtension) || fileSize > limitFileSize) {
            Toastify.error(translator('CERTIFICATE_MANAGEMENT.WARNING_UPLOAD'));
        } else {
            setImgSrc(file);
        }
    };

    useEffect(() => {
        if (!!certificateId) {
            fetchCertificateById();
        }
    }, [certificateId]);

    const onSubmitForm = async (values) => {
        if (!values?.image) {
            return setError('image', {
                type: 'required',
                message: translator('FORM_CONST.REQUIRED_FIELD'),
            });
        }

        setLoading(true);

        try {
            if (imgSrc) {
                await uploadService.uploadFile(
                    imgSrc,
                    (path: string) => {
                        updateCertificateTemplate({
                            name: values?.name,
                            image: path,
                            description: values?.description || null,
                        });
                    },
                    () => {}
                );
            } else {
                updateCertificateTemplate({
                    name: values?.name,
                    image: values?.image ? values?.image?.replace(process.env.REACT_APP_HOST_CDN, '') : null,
                    description: values?.description || null,
                });
            }
        } catch (error) {
        } finally {
            setLoading(true);
        }
    };

    return (
        <form className="h-[100vh] certificate-detail" onSubmit={handleSubmit(onSubmitForm)}>
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT')}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    {
                        name: translator('DASHBOARD.SIDEBAR.CERTIFICATE_TEMPLATE'),
                        href: `/workspace/${workspaceId}/management/certificate`,
                        className: '',
                    },
                    { name: certificateDetail?.name || 'Untitled certificate', href: '', className: 'truncate' },
                ]}
            />
            <div className="flex items-center justify-between space-x-2 px-5 py-[22px] border-b border-gray-300">
                <h1 className="text-xl font-semibold">{certificateDetail?.name || 'Untitled certificate'}</h1>
                {checkPermission('cert', 'DL') && (
                    <ButtonOutline
                        type="button"
                        className="text-sm font-semibold !text-red-500 px-3 py-1 border !border-red-500 disabled:!border-gray-300 disabled:!text-gray-400 disabled:bg-gray-100"
                        onClick={() => setOpenModalDelete(true)}
                        disabled={loadingDelete}
                    >
                        {translator('DELETE')}
                    </ButtonOutline>
                )}
            </div>
            <div className="p-5 grid grid-cols-3 gap-5 bg-gray-50 h-[calc(100%-134px)]">
                <div className="bg-white p-5 rounded-lg overflow-auto">
                    <div className="flex justify-between items-center">
                        <h1 className="text-lg font-semibold">{translator('CERTIFICATE_MANAGEMENT.INFORMATION')}</h1>
                        {checkPermission('cert', 'U') && (
                            <ButtonPrimary
                                type="submit"
                                className="text-sm font-semibold !rounded bg-primary-500 text-white flex gap-1 justify-center items-center px-3 py-1"
                                disabled={loading || (!imgSrc && !isDirty)}
                            >
                                {translator('SAVE')}
                            </ButtonPrimary>
                        )}
                    </div>
                    <div className="pt-5 flex flex-col space-y-5">
                        <div>
                            <LabelForm title={translator('CERTIFICATE_MANAGEMENT.BACKGROUND')} isRequired />
                            <InputFile
                                onChange={checkImageUpload}
                                imgDefault={imgSrc ? URL?.createObjectURL(imgSrc) : getValues('image')}
                                accept={acceptFileCertificate.toString()}
                                className={`h-[275px] overflow-hidden bg-contain bg-no-repeat bg-center ${
                                    errors?.['image'] ? '!border-red-500' : ''
                                }`}
                                textHelp={translator('CERTIFICATE_MANAGEMENT.MESSAGE_WARNING')}
                                icon={<ImageFill />}
                            />
                            <ErrorMessageForm name="image" errors={errors} />
                        </div>
                        <InputField
                            name="name"
                            register={register('name', {
                                required: {
                                    value: true,
                                    message: translator('FORM_CONST.REQUIRED_FIELD'),
                                },
                            })}
                            label={translator('CERTIFICATE_MANAGEMENT.TEMPLATE_NAME')}
                            isRequired
                            maxLength={255}
                            errors={errors}
                        />
                        <TextareaField
                            name="description"
                            register={register('description')}
                            label={translator('CERTIFICATE_MANAGEMENT.DESCRIPTION')}
                            maxLength={500}
                            isCountter
                            textLength={watch('description')?.length}
                        />
                    </div>
                </div>
                <div className="col-span-2 bg-white p-5 rounded-lg">
                    <h1 className="text-lg font-semibold">{translator('CERTIFICATE_MANAGEMENT.PREVIEW')}</h1>
                    <div
                        className="certificate-container"
                        style={{ backgroundImage: `url(${imgSrc ? URL?.createObjectURL(imgSrc) : getValues('image')})` }}
                    >
                        <h1 className="certificate-text learner-name truncate">{certificateDetail?.learner_name}</h1>
                        <h3 className="certificate-text course-name truncate">{certificateDetail?.course_name}</h3>
                        <p className="certificate-text certificate-date">{certificateDetail?.certificate_date}</p>
                        <p className="certificate-text certificate-id">{certificateDetail?.certificate_id}</p>
                    </div>
                </div>
            </div>
            <DialogComponent
                title={translator('DELETE')}
                isOpen={isOpenModalDelete}
                onCloseModal={() => setOpenModalDelete(false)}
                child={
                    <div className="flex flex-col">
                        <div className="mt-2 text-sm text-gray-800">
                            {translator('CERTIFICATE_MANAGEMENT.CONFIRM_DELETE_CERTIFICATE')}
                        </div>
                        <div className="flex justify-center gap-4 mt-6 w-full">
                            <ButtonOutline type="button" onClick={() => setOpenModalDelete(false)}>
                                {translator('CANCEL')}
                            </ButtonOutline>
                            <ButtonPrimary type="button" className="buttonPrimary-danger" onClick={onDeleteCertificate}>
                                {translator('DELETE')}
                            </ButtonPrimary>
                        </div>
                    </div>
                }
            />
            <Prompt
                when={(isDirty || !!imgSrc) && !isDeleted}
                message={() =>
                    JSON.stringify({
                        message: translator('CERTIFICATE_MANAGEMENT.CONFIRM_LEAVE_CERTIFICATE'),
                        isImg: false,
                        title: translator('CERTIFICATE_MANAGEMENT.LEAVE_WITHOUT_SAVING'),
                        textCancel: translator('CERTIFICATE_MANAGEMENT.SAVE_CHANGES'),
                        textConfirm: translator('CERTIFICATE_MANAGEMENT.DISCARD_CHANGES'),
                    })
                }
            />
            <DialogComponent
                isOpen={!!certificateUsedCourse?.length}
                title={
                    <>
                        <ExclamationCircleIcon className='w-5 h-5 text-orange-500 stroke-[2.5px]' />
                        <span className='ml-3'>{translator('CERTIFICATE_MANAGEMENT.TEMPLATE_IN_USE')}</span>
                    </>
                }
                onCloseModal={() => setCertificateUsedCourse([])}
                maskClosable={false}
                stylesTitle="text-base"
                styles="max-w-[500px]"
                child={
                    <ModalConfirmCourseUseCertificateTemplate
                        data={certificateUsedCourse}
                        onClose={() => setCertificateUsedCourse([])}
                    />
                }
            />
        </form>
    );
};
