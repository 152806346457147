import Enrollment from './Enrollment/Enrollment';
import Instructor from './Instructor/Instructor';
import './CoursePeople.scss'
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { checkPermission } from 'utils/commonFun';
import TeachingAssistant from './TeachingAssistant/TeachingAssistant';
import Learner from './Learner/Learner';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ListTab = (translator) =>  ([
    { label: translator("COURSES.LEARNERS"), value: 'learner' },
    { label: translator("COURSES.INSTRUCTORS"), value: 'instructor' },
    // { label: translator("TEACHING_ASSISTANT"), value: 'teaching-assistant'},
    { label: translator("ENROLLMENT.ENROLLMENTS"), value: 'enrollment' },
])

const CoursePeople = () => {
    const history = useHistory();
    const params: { id: string; courseId: string } = useParams();
    const search = useLocation()?.search;
    const subtab = new URLSearchParams(search).get('subtab');
    const {t} = useTranslation();

    useEffect(() => {
        if (!subtab) {
            history?.push(`/workspace/${params.id}/management/courses/${params.courseId}?tab=people&subtab=learner`)
        }
    }, [subtab])

    return (
        <div className='CoursePeople'>
                <div className="CoursePeople-title">{t("People")}</div>
                <div className={`CoursePeople-tablist  `}>
                    {checkPermission('coursePeople', 'R') && ListTab(t).map((i) => (
                        <div
                            className={`tagItem ${subtab === i?.value ? 'active ' : 'disable'}`}
                            onClick={() => history?.push(`/workspace/${params.id}/management/courses/${params.courseId}?tab=people&subtab=${i?.value}`)}
                        >
                            {i?.label}
                        </div>
                    ))}
                </div>
                {
                  checkPermission('coursePeopleEnrollment', 'R') && (
                    <>
                        {subtab === 'learner' && <Learner />}
                        {subtab === 'enrollment' && <Enrollment />}
                        {subtab === 'instructor' && <Instructor />}
                        {/* {subtab === 'teaching-assistant' && <TeachingAssistant />} */}
                    </>
                  )
                }
        </div>
    );
};

export default CoursePeople;
