import React, { useContext, useEffect, useState } from "react";
import TimeSlotComponent from "components/Dashboard/util/TimeSlot";
import DatePickerInput from "components/Management/components/Form/DatePicker";
import { AsyncSelectField, SelectField } from "components/Select";
import dayjs from "dayjs";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { classNames, getLocalTimeZone } from "utils/commonFun";
import { ButtonOutline, ButtonPrimary, Toastify } from "_shared";
import { useParams } from "react-router-dom";
import "./AddSlot.scss";
import instructorsService from "services/instructors.service";
import { UserContext } from "contexts/User/UserContext";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import courseService from "services/course.service";
import moment from "moment";
import { clsx } from "clsx";
import { CheckIcon } from "@heroicons/react/outline";

type AddSlotProp = {
    data?: any,
    onClose: (value?: boolean) => void
}
const REPEAT_TYPE = [
    {
        value: 'weekly',
        label: 'Weekly'
    },
    {
        value: 'bi-weekly',
        label: 'Bi-weekly'
    },

];
const dateFormat = 'YYYY-MM-DD';

export const AddSlot = ({ data, onClose }: AddSlotProp) => {
    const {
        userState: { result },
    } = useContext(UserContext);
    const params: { id: string, courseId } = useParams();
    const {
        handleSubmit,
        control,
        register,
        watch,
        formState: { errors, isValid, isDirty },
        clearErrors,
        setValue,
        getValues,
        reset,
        trigger,
    } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            instructor: null,
            start_date: undefined,
            end_date: undefined,
            time_range: [],
            repeat_type: REPEAT_TYPE[0]
        },
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const start_date = watch('start_date');
    const end_date = watch('end_date');
    const { t: translator } = useTranslation();
    const timeRangeConst: any = [
        {
            weekday: 1,
            label: translator('WEEK.MONDAY'),
            value: false,
            start_time: '',
            end_time: '',
            disable: true,
            fieldName: 'mon',
            timeSlot: [
                {
                    weekdayChild: 1,
                    slot: 0,
                    slotStartTime: '',
                    slotEndTime: '',
                },
            ],
        },
        {
            weekday: 2,
            label: translator('WEEK.TUESDAY'),
            value: false,
            start_time: '',
            end_time: '',
            disable: true,
            fieldName: 'tue',
            timeSlot: [
                {
                    weekdayChild: 2,
                    slot: 0,
                    slotStartTime: '',
                    slotEndTime: '',
                },
            ],
        },
        {
            weekday: 3,
            label: translator('WEEK.WEDNESDAY'),
            value: false,
            start_time: '',
            end_time: '',
            disable: true,
            fieldName: 'wed',
            timeSlot: [
                {
                    weekdayChild: 3,
                    slot: 0,
                    slotStartTime: '',
                    slotEndTime: '',
                },
            ],
        },
        {
            weekday: 4,
            label: translator('WEEK.THURSDAY'),
            value: false,
            start_time: '',
            end_time: '',
            disable: true,
            fieldName: 'thu',
            timeSlot: [
                {
                    weekdayChild: 4,
                    slot: 0,
                    slotStartTime: '',
                    slotEndTime: '',
                },
            ],
        },
        {
            weekday: 5,
            label: translator('WEEK.FRIDAY'),
            value: false,
            start_time: '',
            end_time: '',
            disable: true,
            fieldName: 'fri',
            timeSlot: [
                {
                    weekdayChild: 5,
                    slot: 0,
                    slotStartTime: '',
                    slotEndTime: '',
                },
            ],
        },
        {
            weekday: 6,
            label: translator('WEEK.SATURDAY'),
            value: false,
            start_time: '',
            end_time: '',
            disable: true,
            fieldName: 'sat',
            timeSlot: [
                {
                    weekdayChild: 6,
                    slot: 0,
                    slotStartTime: '',
                    slotEndTime: '',
                },
            ],
        },
        {
            weekday: 0,
            label: translator('WEEK.SUNDAY'),
            value: false,
            start_time: '',
            end_time: '',
            disable: true,
            fieldName: 'sun',
            timeSlot: [
                {
                    weekdayChild: 0,
                    slot: 0,
                    slotStartTime: '',
                    slotEndTime: '',
                },
            ],
        },
    ];
    const { fields, replace, update } = useFieldArray({
        control,
        name: 'time_range',
    });

    const formatWeekList = (start, end) => {
        if (start) {
            let count = 0;
            const diffStartToEnd = dayjs(end).diff(dayjs(start), 'day');
            const dayAvailable = [];
            let timeRange = [];
            const timeRangeValue = getValues('time_range')?.length > 0 ? getValues('time_range') : timeRangeConst;

            for (let i = 0; i <= diffStartToEnd; i++) {
                const nextDate = dayjs(start).add(i, 'day');
                dayAvailable.push(dayjs(nextDate)?.isoWeekday() - 1);
            }
            while (count <= 6) {
                let timeslot = timeRangeValue[count];
                if (dayAvailable.includes(count)) {
                    timeslot.disable = false;
                } else {
                    timeslot = timeRangeConst[count];
                    timeslot.disable = true;
                    timeslot.value = false;
                }
                timeRange.push(timeslot);
                count++;
            }
            replace(timeRange);
        }
    };

    const handleLoadInstructor = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional.page,
            per_page: 20,
            order: 'asc',
            q: inputValue || null,
            sort_by: 'invited_at',
            status: 'active'
        };
        if (!payload?.q) delete payload.q;

        const { items, total } = await fetchInstructor(payload);
        const newOptions = items.map((el: any) => ({
            id: el?.id,
            label: el?.instructor?.display_name,
            value: el?.instructor?.id,
        }));

        return {
            options: newOptions,
            hasMore: additional.page < total / 20,
            additional: {
                page: additional.page + 1,
            },
        };
    };

    const fetchInstructor = async (bodyParams?: any) => {
        let response = {
            items: [],
            total: 0,
        };

        await courseService.getInstructorEnrolled(params.id, params.courseId, bodyParams).then((res) => {
            response = {
                items: res?.data?.items,
                total: res?.data?.total,
            };
        });

        return response;
    };

    useEffect(() => {
        if (data) {
            reset({
                instructor: {
                    id: data?.id,
                    value: data?.id,
                    label: data?.title
                },
                start_date: data?.start ? new Date(dayjs(data?.start).format('MM-DD-YYYY')) : null
            })
        }
    }, [data]);

    const onSubmit = async (values) => {
        console.log('rrr')
        try {
            setIsSubmitting(true);
            const timeZone = result?.time_zone || getLocalTimeZone();
            let timeRangeSetData = values.time_range.filter((el) => el.value).map((el) => el?.timeSlot);
            timeRangeSetData = timeRangeSetData?.flat() || [];
            const isRecurring = dayjs(end_date).diff(dayjs(start_date), 'day') > 6;
            const payload = {
                workspaceId: params?.id,
                course_id: params?.courseId,
                id: values.instructor.value,
                type_: 'available',
                schedule_time_ranges: timeRangeSetData?.map((slot) => ({
                    start_time: `${dayjs(slot?.dateOfWeek).format(dateFormat)} ${dayjs(slot?.slotStartTime).format('HH:mm:ss')}${dayjs(
                        slot?.slotStartTime
                    )
                        .tz(timeZone)
                        .format('Z')}`,
                    duration: Math?.round(dayjs(slot?.slotEndTime)?.diff(dayjs(slot?.slotStartTime), 'second') / 60) || 30,
                    schedule_type: isRecurring ? values.repeat_type.value : 'once',
                    recurring_start_at: isRecurring
                        ? `${dayjs(slot?.dateOfWeek).format(dateFormat)} ${dayjs(slot?.slotStartTime).format('HH:mm:ss')}${dayjs(
                            slot?.slotStartTime
                        )
                            .tz(timeZone)
                            .format('Z')}`
                        : null,
                    recurring_end_at: isRecurring ?
                        `${dayjs(end_date).format(dateFormat)} ${dayjs(slot?.slotEndTime).format('HH:mm:ss')}${dayjs(
                            slot?.slotEndTime
                        ).tz(timeZone).format('Z')}` : null,
                })),
            };
            const reponse: any = await instructorsService.createInstructors(payload);
            setIsSubmitting(false);
            if (reponse?.data?.code) {
                return Toastify.error(reponse?.data?.message);
            }
            Toastify.success('Available slots successfully created.');
            onClose(true);

        } catch (e) {
            setIsSubmitting(false);
        }
    }

    const handleMath3MonthRecurring = (startDate) => {
        const date = dayjs(startDate);
        const next3Months = date.add(3, 'month');
        const weeksInNext3Months = next3Months.week() - date.week() + (next3Months.year() - date.year()) * date.week();
        const endDate = date.clone().add(7 * weeksInNext3Months, 'days');
        return endDate;
    };

    useEffect(() => {
        if (start_date && end_date) {
            formatWeekList(start_date, end_date);
        }
    }, [start_date, end_date]);

    useEffect(() => {
        setValue('end_date', null);
    }, [start_date]);

    useEffect(() => {
        console.log(errors, isValid, isDirty);
    }, [errors, isValid, isDirty])

    return (<>
        <div className="addSlot">
            <form>
                <AsyncSelectField
                    control={control}
                    label={'Instructor'}
                    isRequired
                    name="instructor"
                    placeholder={translator('Select Enrollment')}
                    isSearchable={false}
                    loadOptions={handleLoadInstructor}
                    customStyles={{
                        control: (base) => {
                            return { ...base, minHeight: '0px', height: '32px', boxShadow: 'none', borderColor: '#E5E7EB' }
                        },
                        valueContainer: (base) => {
                            return { ...base, paddingTop: 0, fontSize: 14, fontWeight: 400 }
                        },
                        indicatorsContainer: (base) => {
                            return { ...base, padding: '0px 8px', height: '32px' }
                        },
                    }}
                />
                <div className="flex mt-7 gap-3">
                    <div className="w-1/2">
                        <label htmlFor="start-date" className="text-gray-800 text-xs font-semibold block mb-1">
                            {translator('Start Date')}<span className="text-red-500"> *</span>
                        </label>
                        <DatePickerInput
                            control={control}
                            name="start_date"
                            positionIcon="right"
                            placeholderText={translator('Enter Start Date')}
                            classnames="text-sm"
                            isRequired
                            {...register('start_date', {
                                required: 'This field is required'
                            })}
                            minDate={new Date()}
                            paddingCls="!py-2"
                            formatDate="dd/MM/yyyy"
                        />
                    </div>
                    <div className="w-1/2">
                        <label htmlFor="start-date" className="text-gray-800 text-xs font-semibold block mb-1">
                            {translator('End Date')}<span className="text-red-500"> *</span>
                        </label>
                        <DatePickerInput
                            control={control}
                            name="end_date"
                            positionIcon="right"
                            placeholderText={translator('Enter End Date')}
                            classnames="text-sm"
                            isRequired
                            {...register('end_date', {
                                required: 'This field is required'
                            })}
                            minDate={start_date}
                            paddingCls="!py-2"
                            formatDate="dd/MM/yyyy"
                        />
                    </div>
                </div>
                {start_date && end_date ? <div className="addSlot-section">
                    {dayjs(end_date).diff(dayjs(start_date), 'day') > 6 ? <>
                        <p className="text-sm text-gray-700">Your scheduled period is longer than 1 week.</p>
                        <div className="text-sm text-gray-700 mb-6 mt-1 inline-block w-full">The slots below will automatically repeat
                            <div className="inline-block mx-1">
                                <SelectField
                                    control={control}
                                    label={''}
                                    name="repeat_type"
                                    placeholder={translator('Select repeat type')}
                                    isSearchable={false}
                                    isDisabled={false}
                                    isMulti={false}
                                    options={REPEAT_TYPE}
                                    className="min-w-[125px]"
                                    customStyles={{
                                        control: (base) => {
                                            return { ...base, minHeight: '0px', height: 'auto' }
                                        },
                                        valueContainer: (base) => {
                                            return { ...base, paddingTop: 0, fontSize: 13, fontWeight: 600 }
                                        },
                                        indicatorsContainer: (base) => {
                                            return { ...base, padding: '0px 8px', height: '30px' }
                                        },
                                        menuList: (base) => {
                                            return { ...base, height: '80px' }
                                        },
                                    }}
                                    errors={errors}
                                />
                            </div>
                            until the end date.
                        </div>
                    </> : null}
                    <div className="addSlot-days">
                        <label htmlFor="day" className="text-gray-800 text-xs font-semibold block mb-1">
                            {translator('Days')}<span className="text-red-500"> *</span>
                        </label>
                        <div className="flex w-full gap-5">
                            <div className="text-xs font-normal">
                                <div className="grid grid-cols-7 gap-4">
                                    {watch('time_range')?.map((el, index) => (
                                        <button
                                            key={index}
                                            id={el?.label}
                                            type='button'
                                            disabled={el?.disable}
                                            onClick={() => setValue(`time_range.${index}.value`, !el?.value)}
                                            className={`flex items-center gap-1 ${el?.disable ? 'text-[#dddddd]' : ''}`}
                                        >
                                            <span className={`${clsx('w-5 h-5 rounded border border-gray-500 flex items-center justify-center',
                                                el?.disable ? 'bg-[#dddddd] border-[#dddddd]': '',
                                                el?.value ? 'bg-primary-500 border-primary-500 text-white': ''
                                            )}`}>
                                                {el?.value && <CheckIcon className="w-4" />}
                                            </span>

                                            {el?.label?.slice(0, 3)}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="addSlot-time">
                        {!!watch('time_range')?.filter((el) => el.value)?.length && (
                            <div className="text-xs font-normal">
                                <label htmlFor="day" className="text-gray-800 text-xs font-semibold block mb-1">
                                    {translator('Time Slots')}<span className="text-red-500"> *</span>
                                </label>
                                <div className="-mt-2">
                                    {watch('time_range')?.map((el, index) => (
                                        <div
                                            key={`${index}-time_range-${start_date}`}
                                            className={classNames('mt-2 grid grid-cols-4 gap-2', !el.value ? 'hidden' : '')}
                                        >
                                            <div className="col-span-1 text-gray-500 text-sm py-4">{el?.label?.slice(0, 3)}</div>
                                            <div className="col-span-3 space-x-2">
                                                <TimeSlotComponent
                                                    control={control}
                                                    index={index}
                                                    watch={watch}
                                                    errors={errors}
                                                    fieldsParent={fields}
                                                    appendParent={update}
                                                    startDate={start_date}
                                                    endDate={end_date}
                                                    weekDay={el.weekday}
                                                    today={moment(start_date).isSame(moment(), 'day')}
                                                    clearErrors={clearErrors}
                                                    setValue={setValue}
                                                    previous={index > 0 ? el[index - 1]?.timeSlot : null}
                                                    isRequired={el.value}
                                                    trigger={trigger}
                                                    hasFilter={dayjs(end_date).diff(dayjs(start_date), 'day') < 7}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div> : null}
                <div className="addSlot-action">
                    <ButtonOutline type="button" onClick={onClose}>
                        {translator('Cancel')}
                    </ButtonOutline>
                    <ButtonPrimary type="button"
                        onClick={() => onSubmit(getValues())}
                        disabled={!isValid || !isDirty || isSubmitting || !watch('time_range')?.filter((el) => el.value)?.length}>
                        {isSubmitting ? <span>
                            <CircleSpin className="text-primary-500" color="text-primary-500" />
                        </span> : null}
                        {translator('Add')}
                    </ButtonPrimary>
                </div>
            </form>
        </div>
    </>)
}