import { useContext, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import Pagination from 'components/V2/Pagination';
import { SessionFilter } from './SessionFilter/SessionFilter';
import SessionTable from './SessionTable/SessionTable';
import { WorkspaceService } from 'services';
import { getIndexPage } from 'utils/commonFun';
import './index.scss';
import { UserContext } from 'contexts/User/UserContext';
import { TooltipCustom } from 'components/Tooltip/Tooltip';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { XCircleIcon, XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import DialogComponent from 'components/Dialog/DialogComponent';

export const ManagementSession = () => {
    const params: { id: string; courseId: string } = useParams();
    const { t } = useTranslation();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [indexPage, setIndexPage] = useState('0-0');
    const [sessionsSelected, setSessionsSelected] = useState([]);
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'updated_at',
        total: 0,
    });
    const [isOpenModalCancel, setOpenModalCancel] = useState(false);
    const [loadingCancelSession, setLoadingCancelSession] = useState(false);
    const [filter, setFilter] = useState<any>(null);

    const {
        userState: { result },
    } = useContext(UserContext);

    const workspaceId = params?.id;

    const translator = (str: string, opt?: any) => t(`SESSION.${str}`, opt);

    // for check Cancel sessions
    const checkSessionUpcoming = useMemo(() => {
        const currentTime = dayjs();

        return sessionsSelected.every((ss) => {
            const startTime = dayjs(ss?.start_time).tz(result.time_zone); // for check Upcoming session
            if (ss?.display_status !== 'cancelled' && ss?.display_status !== 'completed') return startTime.isAfter(currentTime);
            return false;
        });
    }, [sessionsSelected]);

    const fetchSessions = (page = 1, _filter = undefined) => {
        if (loading) return;

        setLoading(true);

        let payload = {
            ...pagination,
            ...(_filter || filter),
            page: page ? page : pagination.page,
        };

        delete payload?.total;

        WorkspaceService.getSessionsWorkspace(workspaceId, payload)
            .then((res) => {
                const { items, total } = res.data;
                setData(items);
                setPagination({ ...pagination, ...payload, total });
                setIndexPage(getIndexPage(payload?.page, pagination?.per_page, items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const onCancelSession = () => {
        setLoadingCancelSession(true);
        WorkspaceService.cancelMultiSessionsWorkspace(params?.id, { session_ids: sessionsSelected?.map((item) => item?.id) })
            .then(() => {
                Toastify.success(sessionsSelected?.length > 1 ? 'Sessions successfully canceled' : 'Session successfully canceled');
                fetchSessions();
            })
            .catch(() => Toastify.error())
            .finally(() => {
                setLoadingCancelSession(false);
                setOpenModalCancel(false);
                setSessionsSelected([]);
            });
    };

    return (
        <>
            <div className="sessions-header">
                <SessionFilter
                    onChangeFilter={(values) => {
                        setFilter(values);
                        fetchSessions(1, values);
                    }}
                />
                <div className={`flex items-center space-x-2 ${!!sessionsSelected?.length ? '' : '!hidden'}`}>
                    <span onClick={() => setSessionsSelected([])}>
                        <XIcon className="w-6 h-6 hover:text-red-500 cursor-pointer" />
                    </span>
                    <span>{sessionsSelected?.length + ` ${translator('sessions selected')}`}</span>
                    <>
                        <ButtonOutline
                            type="button"
                            disabled={!checkSessionUpcoming}
                            onClick={() => setOpenModalCancel(true)}
                            className={`${!checkSessionUpcoming ? 'buttonOutline--isDisable' : '!text-red-500'}`}
                            data-for="CANCEL_SESSIONS_TOOLTIPS"
                            data-tip={translator('CANCEL_SESSIONS_TOOLTIPS')}
                        >
                            <XCircleIcon className={`w-6 h-6 mr-1 ${!checkSessionUpcoming ? 'text-gray-400' : 'text-red-500'}`} />
                            {translator('CANCEL_SESSIONS')}
                        </ButtonOutline>
                        {!checkSessionUpcoming && (
                            <TooltipCustom
                                classNameContainer="shadow-medium"
                                id="CANCEL_SESSIONS_TOOLTIPS"
                                content={translator('CANCEL_SESSIONS_TOOLTIPS')}
                                place="bottom"
                                type="light"
                            />
                        )}
                    </>
                </div>
            </div>
            <div className="sessions-contentpage">
                <div className="sessions-contentpage--main">
                    {loading ? (
                        <div className="loading">
                            <CircleSpin color="text-primary-500" />
                        </div>
                    ) : (
                        <SessionTable
                            data={data}
                            refreshData={() => fetchSessions()}
                            selected={sessionsSelected}
                            setSelected={setSessionsSelected}
                        />
                    )}
                </div>
                <Pagination
                    total={pagination.total}
                    per_page={pagination.per_page}
                    callback={(page) => fetchSessions(page)}
                    indexPage={indexPage}
                    page={pagination?.page}
                />
            </div>
            <DialogComponent
                title={translator(sessionsSelected?.length > 1 ? 'CANCEL_SESSIONS' : 'CANCEL_SESSION')}
                isOpen={isOpenModalCancel}
                onCloseModal={() => setOpenModalCancel(false)}
                isShowClose={true}
                styles="max-w-[530px]"
                child={
                    <>
                        <div className="flex flex-col mt-1">
                            <div className="mt-2 text-sm text-gray-800">
                                {t(
                                    sessionsSelected?.length > 1
                                        ? 'SESSIONS_PAGE.CANCEL_SESSIONS_PAGE_NONE_CREDIT_MESSAGE'
                                        : 'SESSIONS_PAGE.CANCEL_A_SESSION_PAGE_NONE_CREDIT_MESSAGE'
                                )}
                            </div>
                            <div className="flex justify-center gap-4 mt-6 mb-2 w-full">
                                <ButtonOutline type="button" onClick={() => setOpenModalCancel(false)}>
                                    {t('NO')}
                                </ButtonOutline>
                                <ButtonPrimary
                                    type="button"
                                    disabled={loadingCancelSession}
                                    className="buttonPrimary-danger"
                                    onClick={onCancelSession}
                                >
                                    {t('Yes')}
                                </ButtonPrimary>
                            </div>
                        </div>
                    </>
                }
            />
        </>
    );
};
