import { DocumentIcon, XIcon } from '@heroicons/react/outline';
import UndoIcon from 'assets/icon/UndoIcon';
import UploadIconLibrary from 'assets/icon/UploadIconLibrary';
import ButtonComponent from 'components/Button/ButtonComponent';
import DialogComponent from 'components/Dialog/DialogComponent';
import { checkFileType } from 'components/Library/components/FIlterSearch/uitils';
import Progress from 'components/V2/UploadFile/Progress';
import { ACCEPT_FILE_EXTENSIONS, ACCEPT_FILE_NONE_MIME_TYPE, accept_file_upload_library } from 'constant/form.const';
import { createContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import libraryService from 'services/library.service';
import uploadService from 'services/upload.service';
import { UUID } from 'utils/commonFun';
import axios from 'axios';
import { VALIDATE_FIELD } from 'utils/validate';

export const UploadContext: any = createContext({});

export interface IParamUploadContext {
    files: any[],
    folderId?: string,
    acceptFileUpload?: string[],
    isRetry?: boolean,
    maxFileSize?: number //mb
    sectionId?: any,
    total?: number,
    from_assignment?: boolean
    from_lesson?: boolean
}

export default function UploadProvider({ children }) {
    const refModalProgress = useRef()
    const [isOpenProgress, setOpenProgress] = useState<boolean>(false);
    const [fileUploadSuccess, setFileUploadSuccess] = useState({});
    const [fileUploadError, setFileUploadError] = useState({});
    const [fileCreateError, setFileCreateError] = useState({});
    const [progressInfo, setProgressInfo] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const { t: translator } = useTranslation();
    const [openModalCancel, setOpenModalCancel] = useState(false);
    const history: any = useHistory();
    const workspaceId = history?.location?.pathname?.split('/')?.[2];
    let cancelTokenSourceRef = useRef(axios.CancelToken.source());
    const [paramsUpload, setParamsUpload] = useState<any>()

    const onchangeFiles = (params: IParamUploadContext, handleAfterUploadToCdn) => {
        setLoading(true);
        const modifiedFiles = [];
        for (let file of params?.files) {
            const fileName = file?.name;
            const fileExtension = fileName?.split('.').pop();
            let mimeTypeFound = '';

            for (const mimeType in ACCEPT_FILE_NONE_MIME_TYPE) {
                if (ACCEPT_FILE_NONE_MIME_TYPE[mimeType].includes(fileExtension)) {
                    mimeTypeFound = mimeType;
                    break;
                }
            }
            const modifiedFile: any = new File([file], file.name, {
                type: mimeTypeFound || file.type,
                lastModified: file.lastModified,
            });
            modifiedFile.uuid = UUID();

            if (!accept_file_upload_library.includes(modifiedFile.type) && !ACCEPT_FILE_EXTENSIONS.includes(fileExtension)) {
                modifiedFile.error = translator('FORM_CONST.FILE_TYPE_IS_NOT_ALLOWED');
            }
            if (+(file?.size / 1024) >= 1024 * (params?.maxFileSize || 1024)) {
                modifiedFile.error = translator('FORM_CONST.FILE_SIZE_LESS_THAN', { size: params?.maxFileSize ? `${params?.maxFileSize}MB` : '1GB' });
            }
            if (!params?.isRetry) {
                modifiedFile.uuid = UUID();
            }
            modifiedFiles.push(modifiedFile);
        }

        const listNeedtoUpload = modifiedFiles.map((file, index) => {
            progressInfo[file.uuid] = {
                ...file,
                id: file?.uuid,
                name: file?.name,
            };
            if (!file) return () => { };
            if (file?.error) {
                if (!params?.isRetry) {
                    setProgressInfo((pre) => ({ ...pre, ...progressInfo }));
                    setFileCreateError((pre) => ({ ...pre, ...fileCreateError }));
                }
                return () => { };
            }
            return () =>
                uploadService.uploadFile(
                    file,
                    (result) => {
                        const fileFormat = {
                            ...file,
                            name: file.name,
                            file: result,
                            object_type: file?.object_type || checkFileType(file.type || file?.mime_type),
                            size: file?.size,
                            parent_id: params?.folderId ? parseInt(params?.folderId) : null,
                            ...params,
                            sub_object_type: '.' + file.name.split('.').pop()

                        };
                        !fileFormat?.parent_id && delete fileFormat?.parent_id;

                        const onSuccess = (fileFormat) => {
                            fileUploadSuccess[file.uuid] = { ...fileFormat, status: 'success' };
                            setFileUploadSuccess((pre) => ({ ...pre, ...fileUploadSuccess }));
                            progressInfo[file.uuid].error = false;
                            progressInfo[file.uuid].progress = 100;
                            setProgressInfo((pre) => ({ ...pre, ...progressInfo }));

                            if (params?.isRetry) {
                                delete fileUploadError?.[file.uuid];
                                setFileUploadError(() => fileUploadError);
                                progressInfo[file.uuid].error = false;
                                progressInfo[file.uuid].progress = 100;
                                setProgressInfo((pre) => ({ ...pre, ...progressInfo }));
                            }
                        }

                        const onError = (fileFormat) => {
                            fileCreateError[file.uuid] = fileFormat;
                            setFileCreateError((pre) => ({ ...pre, ...fileCreateError }));
                            progressInfo[file.uuid].error = 'Upload failed';
                            progressInfo[file.uuid].progress = 0;
                            setProgressInfo((pre) => ({ ...pre, ...progressInfo }));
                        }

                        handleAfterUploadToCdn?.(fileFormat, (file) => onSuccess(file), (file) => onError(file));

                    },
                    (err) => {
                        fileUploadError[file.uuid] = file;
                        setFileUploadError((pre) => ({ ...pre, ...fileUploadError }));
                        progressInfo[file.uuid].error = 'Upload failed';
                        progressInfo[file.uuid].progress = 0;
                        setProgressInfo((pre) => ({ ...pre, ...progressInfo }));
                    },
                    (event) => {
                        progressInfo[file.uuid].progress = Math.round((100 * event.loaded) / event.total);
                        progressInfo[file.uuid].error = false;
                        setProgressInfo((pre) => ({ ...pre, ...progressInfo }));
                    },
                    cancelTokenSourceRef.current.token
                );
        });
        if (listNeedtoUpload?.length) {
            Promise.all(listNeedtoUpload?.map((fn) => fn())).finally(() => {
                setLoading(false);
                setOpenProgress(true);
            });
        }
    };

    const handleRetry = () => {
        cancelTokenSourceRef.current = axios.CancelToken.source();
        if (Object.values(fileUploadError).length > 0) {
            const files = Object.values(fileUploadError);
            onchangeFiles({ ...paramsUpload, files, isRetry: true }, paramsUpload?.handleAfterUploadToCdn);
        }
        if (Object.values(fileCreateError).length > 0) {
            setLoading(true);
            Promise.all(
                Object.keys(fileCreateError)?.map((item: any) => {
                    return libraryService
                        .uploadFiles(workspaceId, fileCreateError[item])
                        .then((res) => {
                            progressInfo[item].progress = 100;
                            delete fileCreateError?.[item];
                            setFileCreateError(() => fileCreateError);
                            setProgressInfo(() => ({ ...progressInfo }));
                            fileUploadSuccess[item.uuid] = res?.data;
                            setFileUploadSuccess((pre) => ({ ...pre, ...fileUploadSuccess }));
                            paramsUpload?.callback?.({ ...res?.data, file: res?.data, order_number: Object.keys(fileUploadSuccess)?.length + 1 }, paramsUpload?.params?.sectionId)
                        })
                        .catch(() => {
                            progressInfo[item].progress = 0;
                            setFileCreateError(() => ({ ...fileCreateError[item.uuid] }));
                            setProgressInfo(() => ({ ...progressInfo }));
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                })
            );
        }
    };

    const handleUploadFile = (params: IParamUploadContext, handleAfterUploadToCdn = undefined) => {
        cancelTokenSourceRef.current = axios.CancelToken.source();
        setOpenProgress(true);
        onchangeFiles(params, handleAfterUploadToCdn);
        setParamsUpload({ ...params, handleAfterUploadToCdn })
    };

    const renderStatus = () => {
        const numOfItemLoading = Object.keys(progressInfo)?.filter(
            (key) => progressInfo?.[key]?.progress !== 0 && progressInfo?.[key]?.progress !== 100
        )?.length;
        if (loading && numOfItemLoading >= 1) {
            if (numOfItemLoading === 1) {
                return translator('LIBRARY.UPLOADING_ITEM', {
                    number: 1,
                });
            }
            if (numOfItemLoading > 1) {
                return translator('LIBRARY.UPLOADING_ITEMS', {
                    number: numOfItemLoading,
                });
            }
        } else
            return translator(Object.keys(fileUploadSuccess)?.length > 1 ? 'LIBRARY.UPLOADS_COMPLETE' : 'LIBRARY.UPLOAD_COMPLETE', {
                number: Object.keys(fileUploadSuccess)?.length || 0,
            });
    };

    const onReset = () => {
        setOpenProgress(false);
        setProgressInfo({});
        setFileUploadSuccess({});
        setFileUploadError({});
        setFileCreateError({});
    }

    return (
        //@ts-ignore
        <UploadContext.Provider
            value={{
                handleUploadFile,
                fileUploadSuccess,
                loadingUpload: loading,
                onReset
            }}
        >
            {children}
            {!!progressInfo && isOpenProgress && (
                <div ref={refModalProgress} className="fixed bottom-4 right-4 z-9999 bg-white  rounded-lg overflow-hidden shadow-lg">
                    <div className="w-[400px] max-h-96 overflow-auto scrollbar-hidden">
                        <div className=" sticky top-0 z-30">
                            <div className="flex justify-between p-4 bg-gray-50 ">
                                <div className="flex gap-2 font-semibold ">
                                    <UploadIconLibrary />
                                    {renderStatus()}
                                </div>
                                <XIcon
                                    onClick={() => {
                                        if (!!Object.keys(progressInfo)?.find((file: any) => (progressInfo?.[file]?.progress > 0 && progressInfo?.[file]?.progress < 100))) return setOpenModalCancel(true);
                                        onReset();
                                    }}
                                    className="w-6 h-6 cursor-pointer"
                                />
                            </div>
                            {Object.keys(progressInfo)?.filter((file: any) => progressInfo?.[file]?.error)?.length > 0 && (
                                <div className="px-4 bg-gray-100 pb-1 flex justify-between">
                                    <div className="text-sm text-gray-800 ">
                                        {translator(
                                            Object.keys(progressInfo)?.filter((file: any) => progressInfo?.[file]?.error)?.length > 1
                                                ? 'LIBRARY.UPLOADS_FAILED'
                                                : 'LIBRARY.UPLOAD_FAILED',
                                            {
                                                number: Object.keys(progressInfo)?.filter((file: any) => progressInfo?.[file]?.error)
                                                    ?.length,
                                            }
                                        )}
                                    </div>
                                    {Object.keys(fileCreateError)?.length + Object.keys(fileUploadError)?.length > 0 && !loading && (
                                        <div
                                            onClick={handleRetry}
                                            className="text-sm text-blue-500 flex ga-1 items-center cursor-pointer"
                                        >
                                            {' '}
                                            <UndoIcon /> {translator('LIBRARY.RETRY')}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div>
                            {Object.keys(progressInfo)?.map((item) => {
                                const extentionFile = VALIDATE_FIELD.FILE.exec(progressInfo[item]?.name)![0];
                                const fileNameWithouteXtension = progressInfo[item]?.name?.replace(extentionFile, '');
                                const fileName =
                                    fileNameWithouteXtension?.length > 30
                                        ? fileNameWithouteXtension?.slice(0, 23) + '...' + extentionFile
                                        : progressInfo[item]?.name;
                                return (
                                    <div key={progressInfo[item]?.id} className="flex w-full justify-between items-center gap-2 p-4 ">
                                        <div
                                            className={`flex gap-1${progressInfo[item]?.progress > 0 && progressInfo[item]?.progress < 100
                                                ? ' text-gray-400'
                                                : ' text-gray-800'
                                                }`}
                                        >
                                            <DocumentIcon strokeWidth={1} className="min-w-[24px] h-6" />
                                            {fileName}
                                        </div>
                                        <div className="w-8">
                                            <Progress percentage={progressInfo[item]?.progress} error={progressInfo[item]?.error} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}

            <DialogComponent
                isOpen={openModalCancel}
                title={translator('LIBRARY.CANCEL_UPLOAD')}
                stylesTitle="text-base"
                onCloseModal={() => setOpenModalCancel(false)}
                child={
                    <div>
                        <div>{translator('LIBRARY.ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THIS_UPLOAD')}</div>
                        <div className="mt-6 flex justify-center gap-4">
                            <ButtonComponent
                                title={translator('LIBRARY.CONTINUE_UPLOAD')}
                                onClickButton={() => setOpenModalCancel(false)}
                                classStyle="secondary-button"
                            />
                            <ButtonComponent
                                title={translator('LIBRARY.CANCEL_UPLOAD')}
                                onClickButton={() => {
                                    cancelTokenSourceRef.current.cancel();
                                    setOpenModalCancel(false);
                                }}
                                classStyle="primary-button"
                            />
                        </div>
                    </div>
                }
            />
        </UploadContext.Provider>
    );
}
