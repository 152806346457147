
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { PlusIcon, XCircleIcon, XIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import { ButtonIcon, ButtonOutline, ConfirmDeleteModal, Toastify } from '_shared';
import "./SlotDetails.scss";
import { UserContext } from 'contexts/User/UserContext';
import { useParams } from 'react-router-dom';
import { TeacherDashboardService } from 'services';
import { checkPermission } from 'utils/commonFun';

export const SlotDetails = ({ data, onClose, onAddSlot }: { data: any; onClose, onAddSlot }) => {
    const params: { id: string; courseId: string } = useParams();
    const {
        userState: { result },
    } = useContext(UserContext);
    const [slots, setSlots] = useState([]);
    const [slotSelect, setSlotSelect] = useState<any>();

    const handleDelete = (value) => {
        if (value) {
            TeacherDashboardService.removeEventTeacher(params?.id, data?.id, slotSelect.calendar_event_id, {
                schedule_time_range_id: slotSelect?.schedule_time_range_id,
                start_time: slotSelect?.start_time,
            }).then((response) => {
                Toastify.success(response?.data?.message);
                const slotsData = slots.filter(item => item.schedule_time_range_id != slotSelect.schedule_time_range_id);
                setSlots(slotsData);
            }, error => {
                Toastify.error(error?.data?.message);
            })
        }
        setSlotSelect(null);
    }

    const hasPastDay = useMemo(() => {
        return dayjs(data?.start).isBefore(new Date(), 'day');
    }, [data?.start]);

    useEffect(() => {
        if (data?.calendar_events) {
            let events = data?.calendar_events.filter(eventItem =>
                eventItem?.course_id == params?.courseId
            )?.map(item => item.calendar_time_ranges).flat();
            events = events?.filter(item => dayjs(item.start_time).isSame(dayjs(data?.start), 'day'));
            setSlots(events.reverse());
        }
    }, [data]);

    return (<>
        <div className='slotDetails-header'>
            {data?.title} - {dayjs(data?.start).format('DD/MM/YYYY')}
            <ButtonIcon onClick={onClose} className="slotDetails-close">
                <span>
                    <XIcon className="w-5 h-5" />
                </span>
            </ButtonIcon>
        </div>
        <div className='slotDetails-content'>
            <div className='slotDetails-title'>Available Slots:</div>
            <div className='slotDetails-list'>
                {slots?.map(item => (
                    <div className={`slotDetails-slot ${item?.occupied_slots?.length ? 'slotDetails-slotBooked' : ''} `}>
                        {`${dayjs.utc(item?.start_time).tz(result?.time_zone).format('HH:mm')} - ${dayjs.utc(dayjs(item?.start_time).add(item?.duration, 'minutes')).tz(result?.time_zone).format('HH:mm')}`}
                        {(!hasPastDay && !item?.occupied_slots?.length) ? <ButtonIcon type="button" onClick={() => setSlotSelect(item)} className="ml-2 -mr-1">
                            <XIcon className='w-4 h-4 text-gray-500' />
                        </ButtonIcon> : null}
                    </div>
                ))}
            </div>
        </div>
        {(checkPermission('courseScheduling', 'C') && !hasPastDay) ? <div className='slotDetails-add'>
            <ButtonOutline type="button" onClick={() => onAddSlot(data)}>
                <PlusIcon className='w-5 h-5 mr-1' /> Add Slots
            </ButtonOutline>
        </div> : null}
        {slotSelect && <ConfirmDeleteModal
            isOpen={!!slotSelect}
            onClose={handleDelete}
            titleModal={'Delete'}>
            <div className="text-sm">Are you sure you want to delete this availability?</div>
        </ConfirmDeleteModal>}
    </>)

}