import React from "preact/compat";
import { forwardRef, useState } from "react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon } from '@heroicons/react/outline';
import moment from "moment";
import './styles.css';

export const MonthPicker = ({onChange}: {onChange:(value: Date)=> void}) => {
    const [startDate, setStartDate] = useState(new Date());
    const CustomInput = forwardRef(({ value, onClick}:{value?: string, onClick?: () => {}}, ref: any) => (
      <button className="flex items-center border border-gray-300 text-sm rounded px-2.5 py-1.5 text-gray-800 space-x-2" onClick={onClick} ref={ref}>
        {moment(value).format('MM/YYYY')} <span className="ml-2 inline-block"><CalendarIcon width={16} height={16}/></span>
      </button>
    ));
    return (
        <div className="monthPicker z-10">
            <DatePicker
                showMonthYearPicker
                selected={startDate}
                onChange={(date) => {
                    setStartDate(date);
                    onChange(date);
                }}
                customInput={<CustomInput />}
            />
        </div>
    );
  };