export const ShowHideIcon = ({ className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    className={className}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.155 12q.066.106.148.232c.284.432.705 1.007 1.25 1.578C8.659 14.969 10.165 16 12 16s3.34-1.031 4.447-2.19A11.3 11.3 0 0 0 17.845 12a11.2 11.2 0 0 0-1.398-1.81C15.34 9.032 13.835 8 12 8s-3.34 1.031-4.447 2.19A11.3 11.3 0 0 0 6.155 12M19 12l.89-.454v-.003l-.003-.005-.009-.016a7 7 0 0 0-.128-.234 13.326 13.326 0 0 0-1.856-2.479C16.614 7.47 14.62 6 12 6S7.386 7.469 6.106 8.81a13.3 13.3 0 0 0-1.856 2.478 8 8 0 0 0-.129.234l-.008.016-.002.005-.001.002s-.001.001.89.455l-.891-.454a1 1 0 0 0 0 .908L5 12l-.891.454v.001l.002.002.002.005.008.016a5 5 0 0 0 .13.234 13.322 13.322 0 0 0 1.856 2.479C7.385 16.53 9.379 18 11.999 18s4.614-1.469 5.894-2.81a13.3 13.3 0 0 0 1.856-2.478 8 8 0 0 0 .128-.234l.009-.016.002-.005.001-.002s.001-.001-.89-.455m0 0 .891.454a1 1 0 0 0 0-.908z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.586 10.586a2 2 0 1 1 2.828 2.828 2 2 0 0 1-2.828-2.828M2.879 2.879A3 3 0 0 1 5 2h14a3 3 0 0 1 3 3v3a1 1 0 1 1-2 0V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v3a1 1 0 0 1-2 0V5a3 3 0 0 1 .879-2.121M3 15a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-3a1 1 0 1 1 2 0v3a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-3a1 1 0 0 1 1-1"
      clipRule="evenodd"
    ></path>
  </svg>
);