import React, { FC, useContext } from 'react';
import { Row, Column } from 'react-table';
import Table, { RenderTableHeader, RenderTableCell } from 'components/Table/TableCustom';
import ActionsButton from 'components/ActionsButton';
import { PencilAltIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import { RefreshSVG } from 'assets/icon';
import { UserContext } from 'contexts/User/UserContext';

type TableGroupsProps = {
    workspaceId: string;
    dataTable: any;
    translator: (key: string) => string;
    rowSelected: React.Dispatch<React.SetStateAction<any>>;
    viewLog: () => void;
    loadProcess: (date?: any) => void;
};

const stageConst = {
    processing: '#3B82F6',
    completed: '#22C55E',
    failed: '#EF4444',
};

const getStage = (value: string, translator: (key: string) => string) => {
    let newValue = '';
    switch (value) {
        case 'processing':
            newValue = translator('PROCESSING');
            break;
        case 'completed':
            newValue = translator('COMPLETE');
            break;
        case 'failed':
            newValue = translator('FAILURE');
            break;
        default:
            break;
    }

    return (
        <p
            className="pl-ooolab_p_1_e text-xs text-ooolab_dark_1 leading-ooolab_24px font-normal py-2 truncate flex items-center justify-start"
            style={{ color: stageConst[value] }}
        >
            {newValue}
        </p>
    );
};

const TableSalesforce: FC<TableGroupsProps> = ({ workspaceId, dataTable, translator, rowSelected, viewLog, loadProcess }) => {
    const initialState = {
        hiddenColumns: ['id'],
    };

    const {
        userState: { result: userInformation },
    } = useContext(UserContext);

    const columns = React.useMemo(
        () => [
            { accessor: 'id' },
            {
                Header: () => <RenderTableHeader value={translator('CONTACT_ID')} />,
                Cell: ({ row, column }: { row: any; column: Column }) => {
                    return (
                        <>
                            <a href={`${row?.original?.instance_url}/contact/${row?.original?.contact_id}/view`} target="_blank">
                                <RenderTableCell value={row.values?.contact_id} className="underline !text-primary-500 !font-medium" />
                            </a>
                        </>
                    );
                },
                accessor: 'contact_id',
                width: 80,
            },

            {
                Header: () => <RenderTableHeader value={translator('OPPORTUNITY_ID')} />,
                Cell: ({ row, column }: { row: any; column: Column }) => {
                    return (
                        <>
                            <a
                                href={`${row?.original?.instance_url}/opportunity/${row?.original?.opportunity_id}/view`}
                                target="_blank"
                            >
                                <RenderTableCell value={row.values?.opportunity_id} className="underline !text-primary-500 !font-medium" />
                            </a>
                        </>
                    );
                },
                accessor: 'opportunity_id',
                width: 80,
            },
            {
                Header: () => <RenderTableHeader value={translator('NAME')} />,
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return <RenderTableCell value={row.values?.name} />;
                },
                accessor: 'name',
                width: 80,
            },
            // {
            //     Header: () => <RenderTableHeader value={translator('LEARNER_GROUP')} />,
            //     Cell: ({ row, column }: { row: any; column: Column }) => {
            //         return (
            //             <>
            //                 <a
            //                     href={`${row?.original?.instance_url}/opportunity/${row?.original?.learner_group}/view`}
            //                     target="_blank"
            //                 >
            //                     <RenderTableCell value={row.values?.learner_group} className="underline !text-primary-500 !font-medium" />
            //                 </a>
            //             </>
            //         );
            //     },
            //     accessor: 'learner_group',
            //     width: 80,
            // },
            {
                Header: () => <RenderTableHeader value={translator('COUNTRY')} />,
                Cell: ({ row, column }: { row: any; column: Column }) => {
                    return <RenderTableCell value={row.values?.country} />;
                },
                accessor: 'country',
                width: 80,
            },
            {
                Header: () => <RenderTableHeader value={translator('ACCOUNT_ID')} />,
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return <RenderTableCell value={row.values?.account_id} />;
                },
                accessor: 'account_id',
                width: 80,
            },
            {
                Header: () => <RenderTableHeader value={translator('DELIVERY_CENTER')} />,
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return <RenderTableCell value={row.values?.delivery_center} />;
                },
                accessor: 'delivery_center',
                width: 80,
            },
            {
                Header: () => <RenderTableHeader value={translator('PROCESSED')} />,
                Cell: ({ row, column }: { row: any; column: Column }) => {
                    return (
                        <RenderTableCell
                            value={
                                row.original?.updated_at
                                    ? dayjs(row.original?.updated_at).tz(userInformation?.time_zone).format('DD/MM/YYYY HH:mm:ss')
                                    : ''
                            }
                        />
                    );
                },
                accessor: 'processed',
                width: 80,
            },
            {
                Header: () => <RenderTableHeader value={translator('STAGE')} />,
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return getStage(row.values?.stage, translator);
                },
                accessor: 'stage',
                width: 80,
            },
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row, column }: { row: any; column: Column }) => {
                    return (
                        <div className={`h-full flex items-center`}>
                            <ActionsButton
                                styleOptions="top-0 right-[35px]"
                                onClick={() => rowSelected(row?.original)}
                                menus={[
                                    {
                                        label: translator('LOG'),
                                        icon: <RefreshSVG className="w-4 h-4" />,
                                        action: () => viewLog?.(),
                                    },
                                    {
                                        label: translator('PROCESS_AGAIN'),
                                        icon: <PencilAltIcon className="w-4 h-4" />,
                                        action: () => loadProcess?.(row?.original?.opportunity_id),
                                    },
                                ]}
                            />
                        </div>
                    );
                },
                accessor: 'action',
                width: 40,
            },
        ],
        []
    );

    return (
        <Table
            isBorderFirstColum={false}
            data={dataTable}
            columns={columns}
            initialState={initialState}
            emptyContent={translator('NO_SALESFORCE_INTEGRATION')}
        />
    );
};

export default TableSalesforce;
