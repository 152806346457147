import { CalendarIcon } from '@heroicons/react/outline';
import { SessionDetail, SessionTitle } from './SessionDetail';
import './ModalSessionDetail.scss';
import dayjs from 'dayjs';
import Session from './Session';
import DialogComponent from 'components/Dialog/DialogComponent';
import { useTranslation } from 'react-i18next';

export default function ModalSessionDetail({ isOpen, onClose, sessions, refresh}) {
    const {t: translator} = useTranslation();
    return (
        <DialogComponent
            isOpen={isOpen}
            onCloseModal={onClose}
            child={
                <div className='ModalSessionDetail'>
                    {sessions?.length > 1 && (
                        <div className="session-name">
                            {translator("TOTAL")} <span>{sessions?.length}</span> {translator("SESSIONS_FOR")} <CalendarIcon className="icon" />
                            <span>{dayjs(sessions?.[0]?.start_time)?.format('dddd, DD/MM/YYYY')}</span>
                        </div>
                    )}
                    {sessions?.length > 1 ? (
                        <div className=''>
                            {
                                sessions?.map((session, index) => {
                                    return (
                                        <Session key={index} session={session} refresh={refresh}/>
                                )
                            })}
                        </div>
                    ) : (
                        <div className='session-border'><SessionDetail session={sessions?.[0]} /></div>
                    )}
                </div>
            }
            styles="max-w-[900px] !p-5 !rounded-2xl"
            title={sessions?.length > 1 ? <div className="pb-2 text-xl">{translator("SESSION_LIST")}</div> : <SessionTitle session={sessions?.[0]}/>}
        />
    );
}
