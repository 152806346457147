import DialogComponent from 'components/Dialog/DialogComponent';
import libraryService from 'services/library.service';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LibraryTypeEnum } from 'components/Library/LibraryContants';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'contexts/User/UserContext';
import DialogWarning from 'components/V2/DialogWarning/DialogWarning';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { get } from 'lodash';

export default function ModalDelete({ onClose, isOpen, refreshData, item, isDeleteMulti, listItems = [] }) {
    const params: any = useParams();
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);
    const [isUsed, setIsUsed] = useState<boolean>(false);
    const [anotherUser, setAnotherUser] = useState<{
        isOpen: boolean;
        isFilesAnother: boolean;
    }>({
        isOpen: false,
        isFilesAnother: false,
    });

    const {
        userState: { result },
    } = useContext(UserContext);

    const onAfterFetch = () => {
        onClose(false);
        Toastify.success();
        refreshData();
    };

    const onOpenAnotherUser = (isFileinFolder = false) => {
        setAnotherUser({
            isOpen: true,
            isFilesAnother: isFileinFolder,
        });
    };

    const handleCheckUser = () => {
        if (!isDeleteMulti && item?.created_by?.id == result?.id) {
            handleDeleteItem();
        } else if (isDeleteMulti) {
            const checked = listItems?.find((item) => item?.created_by?.id !== result?.id);
            if (!checked) {
                onDeleteMultiple();
            } else onOpenAnotherUser();
        } else {
            onOpenAnotherUser();
        }
    };

    const handleDeleteItem = () => {
        setLoading(true);
        if (
            item?.object_type === LibraryTypeEnum.lesson ||
            item?.object_type === LibraryTypeEnum.h5p ||
            item?.object_type === LibraryTypeEnum.assignment
        ) {
            let action = 'moveToTrashH5P';
            switch (item?.object_type) {
                case LibraryTypeEnum.lesson:
                    action = 'moveToTrashLesson';
                    break;
                case LibraryTypeEnum.assignment:
                    action = 'moveToTrashAssignment';
                    break;
                default:
                    break;
            }
            libraryService[action](params?.id, item?.object_id)
                .then(() => {
                    onAfterFetch();
                })
                .catch(() => Toastify.error())
                .finally(() => {
                    onClose(false);
                    setLoading(false);
                });
        } else {
            const id = item?.object_type === LibraryTypeEnum.folder ? item?.id : item?.object_id;
            const action = item?.object_type === LibraryTypeEnum.folder ? 'moveToTrashFolder' : 'moveToTrashFile';
            libraryService?.[action](params?.id, id)
                .then(() => {
                    onAfterFetch();
                })
                .catch((err) => {
                    if (get(err, 'response.data.error.name') === 'cannot_delete_folder') {
                        onOpenAnotherUser(true);
                    } else Toastify.error();
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const onDeleteMultiple = () => {
        if (listItems?.length) {
            setLoading(true);
            const listNeedtoDelete = listItems?.map((item) => {
                const id = item?.object_type === LibraryTypeEnum.folder ? item?.id : item?.object_id;
                let action = 'moveToTrashFile';
                switch (item?.object_type) {
                    case LibraryTypeEnum.folder:
                        action = 'moveToTrashFolder';
                        break;
                    case LibraryTypeEnum.lesson:
                        action = 'moveToTrashLesson';
                        break;
                    case LibraryTypeEnum.h5p:
                        action = 'moveToTrashH5P';
                        break;
                    case LibraryTypeEnum.assignment:
                        action = 'moveToTrashAssignment';
                        break;
                    default:
                        action = 'moveToTrashFile';
                        break;
                }
                return libraryService?.[action](params?.id, id);
            });

            if (listNeedtoDelete?.length) {
                Promise?.all(listNeedtoDelete?.map((fn) => fn))
                    .then(() => {
                        onAfterFetch();
                    })
                    .catch((err) => {
                        if (get(err, 'response.data.error.name') === 'cannot_delete_folder') {
                            onOpenAnotherUser(true);
                        } else Toastify.error();
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    const onCheckType = (objectType) => {
        if (objectType === LibraryTypeEnum.folder) {
            return t('LIBRARY.FOLDER_LOWER');
        } else return t('LIBRARY.FILE');
    };

    const geType = (type: string) => {
        switch (type) {
            case LibraryTypeEnum.folder:
                return 'folders';
            case LibraryTypeEnum.lesson:
                return 'lessons';
            case LibraryTypeEnum.h5p:
                return 'h5p';
            case LibraryTypeEnum.assignment:
                return 'exercises';
            default:
                return 'files';
        }
    };

    useEffect(() => {
        const checkResourceIsUsed = async () => {
            const response = await libraryService.libraryIsUsed(params.id, item?.object_id, geType(item.object_type));
            setIsUsed(response?.data?.is_used);
        };
        if (item?.object_id) {
            checkResourceIsUsed();
        }
        if (listItems?.length > 0) {
            setIsUsed(false);
            const checkResourcesIsUsed = async () => {
                let listAPIschecked = [];
                listItems.forEach((lib) => {
                    listAPIschecked.push(libraryService.libraryIsUsed(params.id, lib?.object_id, geType(lib.object_type)));
                });
                Promise.all(listAPIschecked).then(([...res]) => {
                    res.forEach((response) => {
                        if (response.data?.is_used) {
                            setIsUsed(true);
                        }
                    });
                });
            };
            checkResourcesIsUsed();
        }
    }, [item?.id, listItems]);

    return (
        <>
            {anotherUser.isOpen ? (
                <DialogWarning
                    title={
                        anotherUser.isFilesAnother && anotherUser.isOpen
                            ? t('LIBRARY.CANNOT_DELETE_FOLDER')
                            : t('LIBRARY.DELETE_ANOTHER_USER', {
                                type:
                                    isDeleteMulti && listItems?.length > 1
                                        ? t('LIBRARY.FILES')
                                        : onCheckType(
                                            isDeleteMulti && listItems?.length === 1
                                                ? listItems[0]?.object_type
                                                : item?.object_type
                                        ),
                            })
                    }
                    isOpen={anotherUser.isOpen}
                    onCloseModal={() => {
                        setAnotherUser({
                            isOpen: false,
                            isFilesAnother: false,
                        });
                        onClose();
                    }}
                    maskClosable={true}
                    child={
                        <div className="mt-4 flex justify-center">
                            <ButtonOutline
                                type=""
                                onClick={() => {
                                    setAnotherUser({
                                        isOpen: false,
                                        isFilesAnother: false,
                                    })
                                    onClose();
                                }
                                }
                            >
                                {t('MODALS.CLOSE')}
                            </ButtonOutline>
                        </div>
                    }
                    stylesTitle="text-base"
                />
            ) : (
                <DialogComponent
                    title={t('MODALS.DELETE')}
                    isOpen={isOpen}
                    onCloseModal={() => {
                        onClose(false);
                    }}
                    styleDialog="!z-[9999]"
                    child={
                        <div className="mt-4">
                            {isUsed ? (
                                <p className="text-sm">
                                    {item?.object_type == LibraryTypeEnum.assignment
                                        ? t('MODALS.DELETE_ASSIGNMENT')
                                        : item?.object_type == LibraryTypeEnum.lesson
                                            ? t('MODALS.DELETE_LESSON')
                                            : t('MODALS.DELETE_OTHER')}
                                </p>
                            ) : (
                                <p className="text-sm">{t('MODALS.DELETE_LIBRARY_ITEM')}</p>
                            )}
                            <div className="flex justify-center gap-4 mt-5">
                                <ButtonOutline
                                    type="button"
                                    onClick={() => {
                                        onClose(false);
                                    }}
                                >
                                    {t('MODALS.CANCEL')}
                                </ButtonOutline>
                                <ButtonPrimary
                                    type="button"
                                    onClick={() => {
                                        handleCheckUser();
                                    }}
                                    disabled={loading}
                                    className="buttonPrimary-danger"
                                >
                                    {loading ? (
                                        <span className="confirmDeleteModal-loading">
                                            <CircleSpin />
                                            {t('MODALS.DELETE')}
                                        </span>
                                    ) : (
                                        t('MODALS.DELETE')
                                    )}
                                </ButtonPrimary>
                            </div>
                        </div>
                    }
                    stylesTitle="text-base"
                />
            )}
        </>
    );
}
