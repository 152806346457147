import React from 'react';

type ProgressPointBarProps = {
    label: string;
    percentage: number;
};

const ProgressPointBar: React.FC<ProgressPointBarProps> = ({
    percentage,
    label,
}: ProgressPointBarProps) => {
    return (
        <>
            <div className="w-full">
                {
                    <label className="flex text-xs">
                        {label}
                        <span className="ml-auto">
                            {Math.round(percentage)}%
                        </span>
                    </label>
                }
                <div className="w-full mt-1">
                    <div className={`relative h-2 rounded-lg`}>
                        <div className="left-0 absolute h-2 w-[12px] rounded-lg bg-[#52B9FB] z-10"></div>
                        {
                            <div
                                className="absolute h-2 w-[12px] rounded-lg bg-[#52B9FB] z-10"
                                style={{
                                    left: `calc(${Math.round(percentage)}% - ${
                                        percentage > 12 ? 12 : 0
                                    }px)`,
                                }}
                            ></div>
                        }
                        <div
                            className="absolute h-2 rounded-lg bg-[#3E3C6D] z-9"
                            style={{ width: `${Math.round(percentage)}%` }}
                        ></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProgressPointBar;
