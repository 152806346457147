import Cookies from 'js-cookie';
import { getUserLocalData } from './handleLocalStorage';

export const getLocalCookie = () => {
    if(process.env.REACT_APP_S3_PATH != 'production'){
        const cookieName = 'cms.fai.' + process.env.REACT_APP_S3_PATH
        return Cookies.withAttributes({
            secure: true,
        }).get(cookieName);
    } else {
        return Cookies.withAttributes({
            secure: true,
        }).get('cms.fai');
    }
};

export const isUserLoggedIn = () => {
    const isLoggedIn = getLocalCookie();
    const localUser = getUserLocalData();
    return !!isLoggedIn && localUser
};
