import { UserContext } from 'contexts/User/UserContext';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { INVOICE_TYPE, PAYMENT_OPTION } from '../constants';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { useLocation, useParams } from 'react-router-dom';
import { ListUser } from '_shared/components/User/User';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { PriceFormat } from '_shared/components/Price/PriceFormat';

export default function Information({ invoice }) {
    const {
        userState: { result },
    } = useContext(UserContext);
    const { t: translator } = useTranslation();
    const params: { id } = useParams();
    const search = useLocation()?.search;
    const invoiceType = new URLSearchParams(search).get('type');
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: workspaceDetailInformation } = getWorkspaceDetailState;
    return (
        <div className="InvoiceInformation">
            <div className="InvoiceInformation-title">{translator('INFORMATION')}</div>
            {invoice?.name && (
                <>
                    <div className="InvoiceInformation--invoice-field-label">{translator('RECURRING_INVOICE_NAME')}</div>
                    <div className="InvoiceInformation--invoice-field-value">{invoice?.name}</div>
                </>
            )}

            {invoice?.amount &&  invoiceType === INVOICE_TYPE.single && (
                <>
                    <div className="InvoiceInformation--invoice-field-label">{translator('AMOUNT')}</div>
                    <div className="InvoiceInformation--invoice-field-value uppercase">
                        <span className="font-semibold "><PriceFormat value={invoice?.amount} currency={workspaceDetailInformation.default_currency} /></span>
                    </div>
                </>
            )}

            {invoiceType === INVOICE_TYPE.single && (
                <div className="InvoiceInformation--transaction-table">
                    <div className="transaction-item border-style">
                        <div className="transaction-item--label">{translator('TRANSACTION_ID')}</div>
                        <div className="transaction-item--value">{invoice?.id}</div>
                    </div>
                    <div className="transaction-item border-style padding-left">
                        <div className="transaction-item--label">{translator('TRANSACTION_DATE')}</div>
                        <div className="transaction-item--value">
                            {dayjs.utc(invoice?.schedule_at)?.tz(result?.time_zone)?.format('DD/MM/YYYY')}
                        </div>
                    </div>
                    <div className="transaction-item border-style padding-left">
                        <div className="transaction-item--label">{translator('PAYMENT_OPTION')}</div>
                        <div className="transaction-item--value">{PAYMENT_OPTION(translator)[invoice?.payment_option]}</div>
                    </div>
                    <div className="transaction-item border-style padding-left">
                        <div className="transaction-item--label">{translator('DUE_DATE')}</div>
                        <div className="transaction-item--value">
                            {invoice?.due_at ? dayjs.utc(invoice?.due_at)?.tz(result?.time_zone)?.format('DD/MM/YYYY・HH:mm') : '-'}
                        </div>
                    </div>
                    <div className="transaction-item padding-left">
                        <div className="transaction-item--label">{translator('PAYMENT_DATE')}</div>
                        <div className="transaction-item--value">{invoice?.paid_at ? dayjs.utc(invoice?.paid_at)?.tz(result?.time_zone)?.format('DD/MM/YYYY・HH:mm'): '-'}</div>
                    </div>
                </div>
            )}

            {invoiceType === INVOICE_TYPE.recurring && (
                <div className="InvoiceInformation--transaction-table !grid-cols-4">
                    <div className="transaction-item border-style">
                        <div className="transaction-item--label">{translator('START_DATE')}</div>
                        <div className="transaction-item--value">
                            {dayjs.utc(invoice?.start_at)?.tz(result?.time_zone)?.format('DD/MM/YYYY')}
                        </div>
                    </div>
                    <div className="transaction-item border-style padding-left">
                        <div className="transaction-item--label">{translator('END_DATE')}</div>
                        <div className="transaction-item--value">
                            {dayjs.utc(invoice?.end_at)?.tz(result?.time_zone)?.format('DD/MM/YYYY')}
                        </div>
                    </div>
                    <div className="transaction-item border-style padding-left">
                        <div className="transaction-item--label">{translator('FREQUENCY')}</div>
                        <div className="transaction-item--value capitalize">{invoice?.frequency_type}</div>
                    </div>
                    <div className="transaction-item padding-left">
                        <div className="transaction-item--label">{translator('PAYMENT_OPTION')}</div>
                        <div className="transaction-item--value">{PAYMENT_OPTION(translator)[invoice?.payment_option]}</div>
                    </div>
                </div>
            )}

            <div className="InvoiceInformation-information">
                <div className="InvoiceInformation-information--label ">{translator('COURSE')}</div>
                <div className="InvoiceInformation-information--value">
                    {invoice?.courses?.map((course) => course?.title)?.join(', ')}
                </div>
            </div>
            <div className="InvoiceInformation-information">
                <div className="InvoiceInformation-information--label">{translator('LEARNER')}</div>
                <ListUser
                    users={[...(invoice?.learners || []), ...(invoice?.learning_groups || [])]?.map((item) => {
                        let link = ''
                        if (!item?.display_name) link = `/workspace/${params?.id}/management/learning-groups/${item?.id}`
                        else link = `/workspace/${params?.id}/management/users/${item?.id}?tab=information` 
                        return { 
                            ...item, 
                            link
                        };
                    })}
                    id={'user'}
                    fontsize="text-sm"
                    avatarStyle="w-6 h-6"
                    
                />
            </div>
            {invoice?.note && (
                <div className="InvoiceInformation-information">
                    <div className="InvoiceInformation-information--label">{translator('DESCRIPTION')}</div>
                    <div className="InvoiceInformation-information--value">{invoice?.note}</div>
                </div>
            )}
        </div>
    );
}
