import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import {
    ArrowDownIcon,
    ArrowUpIcon,
    ExclamationCircleIcon,
    PlusSmIcon,
} from '@heroicons/react/outline';
import { ColumnWithLooseAccessor } from 'react-table';

import { LessonOptions, LessonDetail } from './components';

import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { LESSON_ACTIONS } from './actions';
import { LessonInterface } from 'types/GetListOfWorkspace.type';
import { CheckboxType } from 'types/Lesson.type';

import Table from '../../Table';
import TablePagination from 'components/Pagination';
import LessonGridItem from './components/LessonGridItem';

import { getLessonFilterLocal } from 'utils/handleLocalStorage';
import {
    generateData,
    handleGetLessonsList,
    handleClickRow,
    generateDefaultParam,
    lessonParamReducer,
} from './LessonFN';
import SwitchView from 'components/SwitchView';
import Tooltip from 'components/Tooltip';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { useTranslation } from 'react-i18next';
import workspaceService from 'services/workspace.service';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import { RenderTableCell } from 'components/Table/TableCustom';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { debounce } from 'lodash';
import SearchBox from 'components/V2/SearchBox';

export interface ParamInterface {
    param: {
        page: number;
        per_page: number;
        sort_by: 'updated_at' | 'created_at' | 'title.keyword';
        order: 'desc' | 'asc';
        tag_id: string;
        title: string;
        created_by: string;
        // status: 'pending' | 'draft' | 'public' | 'archive' | 'trash';
    };
    hasNextPage: boolean;
    selectedTag: CheckboxType[];
    selectedAuthor: CheckboxType[];
}

const orderObj: Record<'desc' | 'asc', string> = {
    asc: 'desc',
    desc: 'asc',
};

const Lesson: React.FC = ({ children }) => {
    const { t: translator } = useTranslation();
    const history = useHistory();
    const params: { id: string } = useParams();
    const { dispatch: WorkspaceDispatch, getWorkspaceDetailState } = useContext(
        GetWorkspaceContext
    );
    const [isGrid, setIsGrid] = useState(false);
    const {
        lessonList,
        lessonListStatus,
        currentLesson,
        result: WorkspaceDetailInformation,
    } = getWorkspaceDetailState;
    
    const data = React.useMemo(() => generateData(lessonList), [lessonList]);
    const [lessonParam, dispatch] = useReducer(
        lessonParamReducer,
        generateDefaultParam()
    );
    const [selectedRemove, setSelectedRemove] = useState<any>();
    const [rowSelected, setRowSelected] = useState<any>({});
    const columns: ColumnWithLooseAccessor[] = React.useMemo(
        () => [
            {
                Header: () => (
                    <p className="text-left pl-2">
                        {translator('LESSON.LESSON_NAME')}
                    </p>
                ),
                Cell: ({row}) => {
                    return (
                        <RenderTableCell className='text-ellipsis truncate max-w-xs' value={row.values?.title} />
                    )
                },
                accessor: 'title', // accessor is the "key" in the data
            },
            {
                Header: () => (
                    <p
                        onClick={() => {
                            dispatch({
                                type: LESSON_ACTIONS.SET_ORDER,
                                value: orderObj[lessonParam.param.order],
                            });
                        }}
                        className="cursor-pointer flex text-primary-500"
                    >
                        {translator('LESSON.LAST_MODIFIED')}
                        {(lessonList.order === 'asc' && (
                            <ArrowUpIcon className="w-4 h-4 ml-1" />
                        )) || <ArrowDownIcon className="w-4 h-4 ml-1" />}
                    </p>
                ),
                accessor: 'updated_at',
                Cell: (d: any) => {
                    return (
                        <p className="text-ooolab_text_bar_inactive">
                            {d.value}
                        </p>
                    );
                },
            },
            {
                Header: () => <p className="">{translator('LESSON.AUTHOR')}</p>,
                accessor: 'created_by',
                Cell: (d: any) => {
                    return (
                        <div className="flex justify-between ">
                            <Tooltip
                                title={d.value.display_name}
                                mlClass="ml-0"
                            >
                                <img
                                    className="w-6 h-6 rounded-full bg-center bg-contain"
                                    src={d.value.avatar_url}
                                    alt=""
                                />
                            </Tooltip>
                        </div>
                    );
                },
            },
            {
                accessor: 'id',
                Cell: (d: any) => {
                    return (
                        <LessonOptions
                            id={d.row.original.id}
                            workspace={params.id}
                            dispatch={WorkspaceDispatch}
                            // canRemove={
                            //     (d.row.original.status !== 'public' &&
                            //         d.row.original.created_by.id ===
                            //             WorkspaceDetailInformation
                            //                 .membership.user_id) ||
                            //     WorkspaceDetailInformation.membership
                            //         .role === 'admin'
                            // }
                            canRemove={true}
                            duplicateLesson={duplicateLesson}
                        />
                    );
                },
            },
        ],
        [lessonList, WorkspaceDetailInformation]
    );

    const handleSearch = (content: string) => {
        dispatch({ type: LESSON_ACTIONS.SET_TITLE, value: content });
    };

    const handleClickRowTable = (e: LessonInterface) => {
        setRowSelected(e);
        handleClickRow(WorkspaceDispatch, e, params.id);
    };

    const handleDoubleClickRowTable = (lessonId: any) => {
        history.push(`/workspace/${params.id}/lesson/${lessonId}`);
    };

    const getLessonByPage = (selected: number) => {
        dispatch({
            type: LESSON_ACTIONS.SET_PAGE,
            value: selected,
        });
    };

    useEffect(() => {
        const dataLocal = getLessonFilterLocal();
        dispatch({
            type: LESSON_ACTIONS.SET_SELECTED_AUTHOR,
            value: dataLocal.authors,
        });
        dispatch({
            type: LESSON_ACTIONS.SET_SELECTED_TAG,
            value: dataLocal.tags,
        });

        handleGetLessonsList(WorkspaceDispatch, params.id, {
            ...lessonParam.param,
        });
    }, [lessonParam.param]);

    const duplicateLesson = async (lessonId) => {
        let timerInterval;
        Swal.fire({
            title: 'Lesson is cloning',
            html: 'Please wait...',
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
            },
            willClose: () => {
                clearInterval(timerInterval);
            },
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer');
            }
        });

        let lesson = await workspaceService.getLessonDetail(
            params.id,
            lessonId
        );
        lesson.title = 'copy of ' + lesson.title;
        lesson.collaborators = undefined;
        lesson.uid = undefined;
        lesson.tags = [];
        let res = await workspaceService.cloneLesson(params.id, lesson.id);
        if (res.status == 200) {
            Swal.fire({
                icon: 'success',
                title: 'Your lesson has been cloned',
                text: 'Do you want to see it?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: 'rgb(55 65 81)',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    history.push(
                        `/workspace/${params.id}/lesson/${res.data.id}`
                    );
                } else if (result.isDenied) {
                    Swal.close();
                }
            });
        }
    };

    const debouncedOnChange = debounce(handleSearch, 1000);

    return (
        <div className="Lesson">
            <ToastContainer />
            <PageBreadcrumb
                pageNameDefault={translator(
                    'DASHBOARD.SIDEBAR.CONTENT_MANAGEMENT'
                )}
                pages={[
                    { name: translator('DASHBOARD.SIDEBAR.LIBRARY'), href: `/workspace/${params.id}/library?tab=library`, className: '' },
                ]}
                containerClassName=" z-10 sticky top-0"
            />

            <div className="flex items-center justify-between m-4">
                <div className="max-w w-full max-w-xs">
                    {/* <LessonMenuBar
                        onSubmit={handleSearch}
                        children={children}
                    /> */}
                    <SearchBox onSubmit={debouncedOnChange} />
                </div>
                <div className="">
                    <ButtonPrimary onClickButton={() => {
                        workspaceMiddleware.setCurrentLesson(
                            WorkspaceDispatch,
                            undefined
                        );
                        history.push(`/workspace/${params.id}/lesson/new`);
                    }}>
                        <PlusSmIcon
                            className="-ml-1 mr-2 h-5 w-5"
                            aria-hidden="true"
                        />
                        <span> {translator('LESSON.NEW_LESSON')}</span>
                    </ButtonPrimary>                    
                </div>
            </div>
            <div className="grid auto-rows-max grid-cols-6 gap-x-2  border-t mx-2">
                {/* Search bar */}

                {/* List Lessons */}
                <div className="col-span-6 md:col-span-4  py-2 relative flex flex-col items-stretch">
                    <div className="min-h-height_80vh">
                        {lessonListStatus == 'loading' && <div className='flex items-center justify-center mt-4'>
                            <CircleSpin color='text-primary-500' />
                        </div>}
                        {isGrid && data?.length > 0 && lessonListStatus != 'loading' && (
                            <>
                                <div className="flex items-center text-ooolab_sm py-ooolab_p_4 border-b border-ooolab_border_logout">
                                    <p>{translator('LESSON.NAME')}</p>
                                    <p
                                        onClick={() => {
                                            dispatch({
                                                type: LESSON_ACTIONS.SET_ORDER,
                                                value:
                                                    orderObj[
                                                        lessonParam.param.order
                                                    ],
                                            });
                                        }}
                                        className="inline-flex items-center px-ooolab_p_5 cursor-pointer text-primary-500"
                                    >
                                        <span>
                                            {translator('LESSON.LAST_MODIFIED')}
                                        </span>
                                        {(lessonParam.param.order === 'asc' && (
                                            <ArrowUpIcon className="w-4 h-4 ml-ooolab_m_1" />
                                        )) || (
                                            <ArrowDownIcon className="w-4 h-4 ml-ooolab_m_1" />
                                        )}
                                    </p>
                                    <p>{translator('LESSON.AUTHOR')}</p>
                                </div>
                                <div className=" grid grid-cols-3 auto-rows-max gap-5 pt-ooolab_p_5 overflow-y-scroll custom-scrollbar">
                                    {data?.map((i) => (
                                        <div
                                            key={`grid-${i.id}`}
                                            className="col-span-1"
                                        >
                                            <LessonGridItem
                                                key={`grid-item-${i.id}`}
                                                data={i}
                                                onClick={handleClickRowTable}
                                                currentActiveId={
                                                    currentLesson?.id || -1
                                                }
                                                workspaceId={params.id}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </>
                        )
                        }
                        {!isGrid && data?.length > 0 && lessonListStatus != 'loading' && (
                            <div className=" align-middle inline-block min-w-full">
                                <div className="">
                                    <Table
                                        columns={columns}
                                        data={data}
                                        rowProps={{
                                            className:
                                                'bg-white hover:bg-gray-50 cursor-pointer',
                                        }}
                                        rowClass={(row) =>
                                            row?.id === rowSelected?.id
                                                ? 'bg-white hover:bg-gray-50 cursor-pointer bg-gray-50'
                                                : 'bg-white hover:bg-gray-50 cursor-pointer'
                                        }
                                        headerProps={{
                                            className: 'bg-gray-50',
                                        }}
                                        className="min-w-full divide-y divide-gray-200"
                                        onClickRow={handleClickRowTable}
                                        onDoubleClickRow={
                                            handleDoubleClickRowTable
                                        }
                                        selectedRemove={selectedRemove}
                                    />
                                </div>
                            </div>
                        )}
                        {
                            lessonListStatus !== 'loading' && data?.length <= 0 && 
                            <NoContentWithImage content={translator('LESSON.NO_LESSON')} />
                        }
                    </div>
                    <div className="flex justify-between items-end  border-t">
                        <div className="w-1/4 h-full flex items-center ml-3">
                            <SwitchView
                                isGrid={isGrid}
                                onChange={setIsGrid}
                                textLeft={translator('LIST')}
                                textRight={translator('GRID')}
                            />
                        </div>
                        <div>
                            <TablePagination
                                total={lessonList.total}
                                perPage={lessonList.per_page}
                                onClickPagination={getLessonByPage}
                            />
                        </div>
                    </div>
                </div>

                {/* Lesson detail */}
                <div className="hidden md:flex w-full border-l justify-center items-center flex-col md:col-span-2">
                    {(!currentLesson && (
                        <>
                            <ExclamationCircleIcon className="w-12 h-12 text-ooolab_dark_2" />
                            <p className="text-xl text-ooolab_dark_2">
                                {translator('LESSON.SELECT_LESSON')}
                            </p>
                        </>
                    )) || (
                        <LessonDetail
                            setSelectedRemove={setSelectedRemove}
                            currentWorkspace={params.id}
                            data={currentLesson}
                            dispatch={WorkspaceDispatch}
                            canRemove={true}
                            duplicateLesson={duplicateLesson}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Lesson;