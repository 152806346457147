import { Disclosure } from '@headlessui/react';

export default function ContentItem({ button, panel, index }) {
    return (
        <Disclosure defaultOpen={index === 0}>
            {({ open }) => (
                <>
                    <Disclosure.Button className="w-full">{button(open)}</Disclosure.Button>
                    <Disclosure.Panel>{({ close }) => panel(close, open)}</Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}
