import * as React from 'react';
// PACKAGE
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
// CONST
import { FORM_CONST } from 'constant/form.const';
import { AUTH_CONST } from 'constant/auth.const';
// CONTEXT
import { RegisterContext } from 'contexts/Auth/RegisterContext';
// ACTION
import { SET_REGISTER } from 'actions/auth.action';
// UTILS
import { sessionClear, setStorageAuthApiData } from 'utils/handleLocalStorage';
import { handleLogoutRegister } from 'utils/handleLogout';
import { classNames } from 'utils/commonFun';
import { loadSVG } from 'assets/icon';
import {
    CreatePasswordFormProps,
    handleNewPassword,
    onSubmit,
    navigateToUpdateInformation,
    handleConfirmPassword,
} from 'components/Authentication/AuthenticationRegisterForm/CreatePasswordForm/CreatePasswordFormFn';
import HeaderChangePassword from 'components/Authentication/AuthenticationForgotPasswordForm/CreatePassword/HeaderChangePassword';
import FooterChangePassword from 'components/Authentication/AuthenticationForgotPasswordForm/CreatePassword/FooterChangePassword';
import MatchChangePassword from 'components/Authentication/AuthenticationForgotPasswordForm/CreatePassword/MatchChangePassword';
import PasswordEyes from 'components/PasswordWithEyes/PasswordEye';

const CreatePasswordForm: React.FC<CreatePasswordFormProps> = ({
    storageUserInfo,
    setAuthStorage,
    storageUserInfoSession,
}) => {
    const { t: translator } = useTranslation();
    const history = useHistory();
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        watch,
        formState: { errors },
        trigger,
    } = useForm();

    const [apiError, setApiError] = React.useState<string>();
    const { dispatch, registerState } = React.useContext(RegisterContext);
    const { createNewPasswordResult, err, isLoading } = registerState;
    const { temporary_access_token } = storageUserInfoSession;
    const [isValidPassword, setIsValidPassword] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (createNewPasswordResult) {
            setAuthStorage(true);
            sessionClear();
            navigateToUpdateInformation(history, setAuthStorage)();
            // @ts-ignore
            dispatch({ type: SET_REGISTER.REQ_RESET_RESULT });

            setStorageAuthApiData(createNewPasswordResult);
            setApiError(undefined);
        }
        if (err?.error) {
            setApiError(err.error.description);
            if (
                err.error.name === AUTH_CONST.TOKEN_EXPIRED ||
                err.error.code === 401
            ) {
                handleLogoutRegister(setAuthStorage);
                history.push('/register');
            }
        }
    }, [createNewPasswordResult, err]);

    React.useEffect(() => {
        setIsValidPassword(true);
        if (watch('confirm_password') && watch('confirm_password').length > 0) {
            setIsValidPassword(false);
        }
    }, [watch('confirm_password'), watch('password')]);

    const onSubmitForm = () => {
        onSubmit(getValues, temporary_access_token, dispatch);
    };

    return (
        <>
            <div className="flex items-center justify-center h-screen">
                <form
                    className="max-w-xs lg:max-w-lg"
                    onSubmit={handleSubmit(onSubmitForm)}
                >
                    <HeaderChangePassword
                        title={translator(
                            'FORGOT_YOUR_PASSWORD.PLEASE_ENTER_YOUR_NEW_PASSWORD'
                        )}
                        inputClass="text-ooolab_22px lg:text-3xl font-bold text-gray-700 text-center"
                    />

                    <div className="mt-6">
                        <PasswordEyes
                            formProps={register('password', {
                                minLength: {
                                    value: 10,
                                    message: translator(
                                        'FORM_CONST.MIN_LENGTH'
                                    ),
                                },
                                maxLength: {
                                    value: 64,
                                    message: translator(
                                        'FORM_CONST.MAX_LENGTH'
                                    ),
                                },
                                required: {
                                    value: true,
                                    message: translator(
                                        'FORM_CONST.REQUIRED_FIELD'
                                    ),
                                },
                                pattern: {
                                    value: FORM_CONST.PASSWORD_REGEX,
                                    message: translator(
                                        'FORM_CONST.ONLY_USE_CHARACTERS_UPPERCASES_LOWER_CASE_NUMBERS_AND_SPACIAL_CHARACTERS'
                                    ),
                                },
                            })}
                            nameInput="password"
                            onChange={handleNewPassword(setValue, trigger)}
                            placeholder={translator('PASSWORD')}
                            errorsField={!!errors?.password}
                        />
                        {/*@ts-ignore* */}
                        <ErrorMessage
                            errors={errors}
                            name="password"
                            as="span"
                            className="text-red-500 text-xs font-semibold"
                        />
                    </div>
                    {/*@ts-ignore* */}
                    <MatchChangePassword textField={watch('password') || ''} />

                    <div className="mt-4">
                        <PasswordEyes
                            formProps={register('confirm_password', {
                                // minLength: {
                                //     value: 10,
                                //     message: translator(
                                //         'FORM_CONST.MIN_LENGTH'
                                //     ),
                                // },
                                // maxLength: {
                                //     value: 64,
                                //     message: translator(
                                //         'FORM_CONST.MAX_LENGTH'
                                //     ),
                                // },
                                // required: {
                                //     value: true,
                                //     message: translator(
                                //         'FORM_CONST.REQUIRED_FIELD'
                                //     ),
                                // },
                                validate: (value) => {
                                    return value === watch('password')
                                        ? true
                                        : translator(
                                              'FORM_CONST.LOOK_LIKE_YOUR_NEW_PASSWORD_FIELDS_DONT_MATCH'
                                          );
                                },
                            })}
                            nameInput="confirm_password"
                            onChange={handleConfirmPassword(setValue, trigger)}
                            placeholder={translator('CONFIRM_PASSWORD')}
                            errorsField={!!errors?.confirm_password}
                        />
                        {/*@ts-ignore* */}
                        <ErrorMessage
                            errors={errors}
                            name="confirm_password"
                            as="span"
                            className="text-red-500 text-xs font-semibold"
                        />
                        {apiError && (
                            <span className="text-red-500 text-xs font-semibold">
                                {apiError}
                            </span>
                        )}
                    </div>

                    <FooterChangePassword
                        inputClass="w-full mt-8 text-center"
                        child={
                            <button
                                type="submit"
                                disabled={isValidPassword}
                                className={classNames(
                                    'w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ',
                                    'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                                    isValidPassword
                                        ? 'bg-ooola_EFF0FA text-ooolab_8083A3 cursor-not-allowed'
                                        : 'bg-ooola_3E7EFF hover:bg-indigo-700'
                                )}
                            >
                                {isLoading ? loadSVG() : null}
                                {translator(
                                    'FORGOT_YOUR_PASSWORD.CREATE_ACCOUNT'
                                )}
                            </button>
                        }
                    />
                </form>
            </div>
        </>
    );
};

export default CreatePasswordForm;
