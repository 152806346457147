import axios from './restful.service';
import { REWARDS } from 'constant/api.const';

import { RestfulService } from './restful.service';
// CONSTANTS
import { HOST_URL } from 'constant/api.const';

const getListRewards = (
    { workspaceId }: { workspaceId: string },
    bodyData?: {
        is_featured?: boolean;
        q?: string;
        page?: number;
        per_page?: number;
        order?: 'asc' | 'desc';
        sort_by?: string;
    }
) => {
    if (workspaceId) {
        const url = REWARDS.getListRewards(workspaceId);
        return axios.get(url, { params: bodyData });
    }
};

const getListRewardsHistory = (
    { workspaceId, id }: { workspaceId: string; id: string },
    bodyData?: {
        page?: number;
        per_page?: number;
        order?: 'asc' | 'desc';
        sort_by?: string;
    }
) => {
    if (workspaceId) {
        const url = REWARDS.getListRewardsHistory(workspaceId, id);
        return axios.get(url, { params: bodyData });
    }
};

const createRewardService = ({ workspaceId }, bodyData: any) => {
    if (workspaceId) {
        const url = REWARDS.getListRewards(workspaceId);
        return axios.post(url, bodyData);
    }
};

const getApoLevelService = (workspaceId) => {
    const url = REWARDS.getRewardLevels(workspaceId);
    return RestfulService.getApi(url);
};

const updateApoLevelService = (workspaceId, bodyData: any) => {
    const url = REWARDS.updateRewardLevelByBatch(workspaceId);
    return RestfulService.postApi(url, bodyData);
};

const confirmRewardClaimService = (
    { workspaceId, rewardId, rewardClaimId },
    bodyData: any
) => {
    const url = REWARDS.confirmRewardClaim(
        workspaceId,
        rewardId,
        rewardClaimId
    );
    return RestfulService.postApi(url, bodyData);
};

const refundRewardClaimService = (
    { workspaceId, rewardId, rewardClaimId },
    bodyData: any
) => {
    const url = REWARDS.refundRewardClaim(workspaceId, rewardId, rewardClaimId);
    return RestfulService.postApi(url, bodyData);
};

const getReward = (workspaceId: string, rewardId: string) => {
    if (workspaceId && rewardId) {
        const url = REWARDS.getReward(workspaceId, rewardId);
        return axios.get(url);
    }
};

const updatePartialReward = (
    workspaceId: string,
    rewardId: string,
    bodyData: any
) => {
    if (workspaceId) {
        const url = REWARDS.getReward(workspaceId, rewardId);
        return axios.patch(url, bodyData);
    }
};

const updateReward = (workspaceId: string, rewardId: string, bodyData: any) => {
    if (workspaceId) {
        const url = REWARDS.getReward(workspaceId, rewardId);
        return axios.put(url, bodyData);
    }
};

const updateStatusReward = (
    workspaceId: string,
    rewardId: string,
    { status }
) => {
    if (workspaceId) {
        const url = REWARDS.statusReward(workspaceId, rewardId);
        return axios.patch(url, { status: status });
    }
};

const getAllRewardsClaimHistories = (workspaceId: string, params) => {
    if (workspaceId) {
        const url = REWARDS.allRewardsClaimHistories(workspaceId);
        return axios.get(url, {params});
    }
    
};

export default {
    updateApoLevelService,
    getApoLevelService,
    getListRewards,
    createRewardService,
    getListRewardsHistory,
    confirmRewardClaimService,
    refundRewardClaimService,
    getReward,
    updatePartialReward,
    updateReward,
    updateStatusReward,
    getAllRewardsClaimHistories
};
