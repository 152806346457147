import { Dialog, Transition } from '@headlessui/react';
import { WarningSVG } from 'assets/icon';
import { WarningIcon } from 'assets/icon/WarningIcon';
import React, { Fragment } from 'react';
import { classNames } from 'utils/commonFun';

interface DialogProps {
    isOpen: boolean;
    maskClosable?: boolean;
    onCloseModal?: (e?: any) => void;
    title?: string | React.ReactNode;
    child?: React.ReactNode;
    width?: string;
    styles?: string;
    stylesTitle?: string;
}

const DialogWarning: React.FC<DialogProps> = ({
    isOpen,
    maskClosable = false,
    onCloseModal,
    title = 'Modal title',
    child = <div>Content modal</div>,
    width,
    styles,
    stylesTitle = '',
}) => {
    const closeButtonRef = React.useRef(null);

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-70 overflow-y-auto"
                onClose={maskClosable ? onCloseModal : function () {}}
                initialFocus={closeButtonRef}
            >
                <div className="min-h-screen px-4 text-center ">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 dialog-background bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="inline-block h-screen align-middle" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className={classNames(
                                'inline-block py-6 px-5 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-xl',
                                'w-full',
                                width,
                                styles ? styles : 'overflow-hidden max-w-md'
                            )}
                        >
                            <Dialog.Title as="div" className={` mb-4`}>
                                <div className='flex justify-center mb-3 text-amber-500'>
                                    <WarningIcon />
                                </div>
                                <h1
                                    className={`text-base w-full font-normal text-gray-900 leading-6 flex items-center justify-center text-center ${
                                        stylesTitle ? stylesTitle : ''
                                    } `}
                                >
                                    {title}
                                </h1>
                            </Dialog.Title>

                            {/* Body modal */}
                            <Fragment>{child}</Fragment>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default DialogWarning;
