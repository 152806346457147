import React from 'react';
import ManagementMasterPage from '../../../RouteMasterPage';
import ClassroomDetail from 'components/Management/Classrooms/ClassroomDetail';

const ClassroomDetailPage = () => {
    return (

        <ManagementMasterPage>
            <ClassroomDetail />
        </ManagementMasterPage>

    );
};

export default ClassroomDetailPage;
