import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import { STATUS_LEARNER_ENROLL_OPTION } from 'constant/util.const';
import { checkPermission } from 'utils/commonFun';
interface IFilter {
    onChangeFilter: (value: any) => void;
}
const capitalized = (word = '') => {
    return word.charAt(0).toUpperCase()
        + word.slice(1)
}

const EnrollmentFilter = ({ onChangeFilter }: IFilter) => {
    const [keyword, setKeyword] = useDebouncedState('');
    const [learningGroupList, setLearningGroupList] = useState([]);
    const [inputSearchLearningGroup, setInputSearchLearningGroup] = useState('');
    const [loadingFilterLearningGroup, setLoadingFilterLearningGroup] = useState(false);
    const [paginationGroup, setPaginationGroup] = useState<any>({ page: 1, per_page: 10 });
    const search = useLocation()?.search;
    const defaultStatus = new URLSearchParams(search).get('statuses');
    const groupName = new URLSearchParams(search).get('groupName');
    const groupId = new URLSearchParams(search).get('groupId');
    const {t} = useTranslation();

    const [statusSelected, setStatusSelected] = useState<any>(defaultStatus ? defaultStatus?.split(',')?.map(item => ({ name: capitalized(item), value: item })) : []);
    const [learningGroupSelected, setLearningGroupSelected] = useState(groupName ? [{ name: groupName, value: +groupId }] : []);

    const params: { id, userId } = useParams();
    const workspaceId = params?.id;
    const userId = params?.userId

    const getLearningGroup = (page: number = 1) => {
        setLoadingFilterLearningGroup(true);
        const request: any = {
            page: page ? page : paginationGroup.page,
            per_page: 10,
            member_id: userId,
            q: inputSearchLearningGroup,

        };
        if (!request?.q) delete request?.q
        workspaceService.getLearningGroups(workspaceId, request)
            .then((res) => {
                const formatedData = res?.data?.items?.map((item) => {
                    return {
                        name: item?.name || item?.learning_group?.name,
                        id: item?.id,
                        value: item?.id,
                    };
                });
                setPaginationGroup({ ...paginationGroup, total: res?.data?.total, page });
                if (page === 1) setLearningGroupList(formatedData);
                else setLearningGroupList([...learningGroupList, ...formatedData]);
            })
            .finally(() => setLoadingFilterLearningGroup(false));
    };

    useEffect(() => {
        if (checkPermission('learningGroup', 'R')) { getLearningGroup(1); }
    }, [inputSearchLearningGroup]);

    useEffect(() => {
        let listFilter: any = {
            title: keyword,
            statuses: statusSelected?.map(status => status?.value)?.join(','),
            learning_group_ids: learningGroupSelected?.map(enrollment => enrollment?.value)?.join(','),
        };
        if (!statusSelected?.length || statusSelected.length === 4) delete listFilter.statuses;
        if (!listFilter?.title) delete listFilter?.title
        if (!listFilter?.learning_group_ids?.length) delete listFilter?.learning_group_ids;

        onChangeFilter(listFilter);
    }, [keyword, statusSelected, learningGroupSelected]);


    return (
        <div className=' w-full flex flex-wrap gap-2 items-center mt-3'>
            <SearchBox
                onSubmit={(e: any) => setKeyword(e)}
                className="w-full max-w-xs text-sm max-h-9"
                placeholder={t("SEARCH")}
            />
            <SelectBox
                label={t("USER.ENROLLMENT.LEARNER_STATUS")}
                onSelect={setStatusSelected}
                selectedItems={statusSelected}
                data={STATUS_LEARNER_ENROLL_OPTION(t)}
                total={4}
            />
            {checkPermission('learningGroup', 'R') && <SelectBox
                label={t("LEARNING_GROUP.LEARNING_GROUP")}
                onSearch={setInputSearchLearningGroup}
                selectedItems={learningGroupSelected}
                data={learningGroupList}
                total={learningGroupList?.length}
                onSelect={setLearningGroupSelected}
                loading={loadingFilterLearningGroup}
                styles={{ container: "flex-nowrap" }}
            />}

        </div>
    );
}
export default EnrollmentFilter;