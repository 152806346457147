import React from 'react'

export default function ChangeVideoIcon() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_11126_3958)">
      <path d="M13.833 3.33301C14.293 3.33301 14.6663 3.70634 14.6663 4.16634V7.66634L19.0105 4.62467C19.1988 4.49301 19.4588 4.53884 19.5913 4.72801C19.6397 4.79801 19.6663 4.88134 19.6663 4.96634V15.033C19.6663 15.263 19.4797 15.4497 19.2497 15.4497C19.1638 15.4497 19.0805 15.423 19.0105 15.3747L14.6663 12.333V15.833C14.6663 16.293 14.293 16.6663 13.833 16.6663H2.16634C1.70634 16.6663 1.33301 16.293 1.33301 15.833V4.16634C1.33301 3.70634 1.70634 3.33301 2.16634 3.33301H13.833ZM12.9997 4.99967H2.99967V14.9997H12.9997V4.99967ZM7.99967 6.66634L11.333 9.99967H8.83301V13.333H7.16634V9.99967H4.66634L7.99967 6.66634ZM17.9997 7.36717L14.6663 9.70051V10.2988L17.9997 12.6322V7.36634V7.36717Z" fill="currentColor"/>
      </g>
      <defs>
      <clipPath id="clip0_11126_3958">
      <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
      </clipPath>
      </defs>
    </svg>
  )
}
