import React from 'react';
// COMPONENTS
import H5PPublicViewerEntryComponent from './H5PPublicViewerEntryComponent';
// SERVICE
import h5pServices from 'services/h5p.service';

export interface H5PPlayerContentListComponentProps {
    contentUid: string,
    entryState: {
        loading: boolean;
    };
    setEntryState: React.Dispatch<React.SetStateAction<{
        loading: boolean;
    }>>
    isPublic?: boolean;
    args?: any;
}

const H5PPublicViewerContentComponent: React.FC<H5PPlayerContentListComponentProps> = ({
    contentUid,
    entryState,
    setEntryState,
    isPublic = false,
    args,
}) => {
    return (
        <H5PPublicViewerEntryComponent
            contentUid={contentUid}
            h5pUidPlayerPromise={isPublic ? h5pServices.h5pUidPlayerPromise : h5pServices.h5pPlayerPromise}
            entryState={entryState}
            setEntryState={setEntryState}
            isPublic={isPublic}
            args={args}
        />
    );
};

export default H5PPublicViewerContentComponent;
