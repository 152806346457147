import { FC } from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { classNames } from 'utils/commonFun';

type SearchboxProps = {
    className?: string;
    onSubmit?: (value: string) => void;
    placeholder?: string;
    value?: string
};
let delayTimeSearch;

const index: FC<SearchboxProps> = ({ className, onSubmit, placeholder, value }) => {
    const { t: translator } = useTranslation();
    const handleSubmit = (event) => {
        clearTimeout(delayTimeSearch);
        delayTimeSearch = setTimeout(() => {
            onSubmit(event.target.value)
        }, 300);
    }
    return (
        <div
            className={classNames(
                'w-full border border-ooolab_gray_6 rounded p-2 flex items-center space-x-2 text-xs focus-within:border-[#2684FF]',
                className
            )}
        >
            <SearchIcon className="h-5 w-5 text-dark-100" />
            <input
                type="search"
                placeholder={placeholder || translator('SEARCH')}
                className="w-full leading-5 bg-white placeholder-gray-500"
                onChange={handleSubmit}
                defaultValue={value}
            />
        </div>
    );
};

export default index;
