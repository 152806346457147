import { createAsyncThunk, createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './../store';
import learningPathService from '../../services/learningPath.service';
import { VIEW_TYPE } from 'components/Library/LessonBuilder/constants';
import { LearningPathDetail, LearningPathUnit } from 'types/LearningPath.type';
interface LearningPathState {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    data?: LearningPathDetail;
    error?: any;
    changed: boolean;
    libraryTypeAdd: string;
    viewType: string;
    unitSelect?: LearningPathUnit;
    canSubmit?: boolean;
    isNew: boolean;
}
type ValidationErrors = {
    errorMessage: string;
    field_errors: Record<string, string>;
};
const initialState: LearningPathState = {
    loading: 'idle',
    changed: false,
    libraryTypeAdd: null,
    viewType: VIEW_TYPE.WEB,
    unitSelect: null,
    canSubmit: true,
    isNew: false,
};

export const learningPathCreate: any = createAsyncThunk<{ rejectValue: ValidationErrors }>(
    'learningPath/create',
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await learningPathService.create(params);
            if (!response) {
                return rejectWithValue('');
            }
            return response;
        } catch (error) {
            return error;
        }
    }
);

export const learningPathFetch: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('learningPath/fetch', async (params: any, { rejectWithValue }) => {
    try {
        const response = await learningPathService.get(params);
        if (response?.data?.error) {
            return rejectWithValue('');
        }
        return response;
    } catch (error) {
        return error;
    }
});

export const learningPathUpdate: any = createAsyncThunk<{
    rejectValue: ValidationErrors;
}>('learningPath/update', async (params: any, { rejectWithValue }) => {
    try {
        const response = await learningPathService.update(params);
        if (response?.data?.error) {
            return rejectWithValue('');
        }
        return {
            ...response.data,
            ...params,
        };
    } catch (error) {
        return error;
    }
});

export const learningPathAddUnit: any = createAsyncThunk<{ rejectValue: ValidationErrors }>(
    'learningPath/addUnit',
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await learningPathService.addUnit(params);
            if (!response) {
                return rejectWithValue('');
            }
            return {
                id: response?.data?.id,
                title: params?.title ?? '',
                order_number: params?.order_number ?? 0,
            };
        } catch (error) {
            return error;
        }
    }
);

export const learningPathAddLesson: any = createAsyncThunk<{ rejectValue: ValidationErrors }>(
    'learningPath/addLesson',
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await learningPathService.addLesson(params);
            if (!response) {
                return rejectWithValue('');
            }
            return {
                id: response?.data?.id,
                sectionId: params.sectionId,
                ...response?.data,
                library_content: {
                    ...params,
                },
            };
        } catch (error) {
            return error;
        }
    }
);

export const learningPathUpdateUnit: any = createAsyncThunk<{ rejectValue: ValidationErrors }>(
    'learningPath/updateUnit',
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await learningPathService.updateUnit(params);
            if (!response) {
                return rejectWithValue('');
            }
            return {
                id: params?.sectionId,
                title: params?.title ?? '',
            };
        } catch (error) {
            return error;
        }
    }
);
export const learningPathOrderUnit: any = createAsyncThunk<{ rejectValue: ValidationErrors }>(
    'learningPath/orderSections',
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await learningPathService.orderSections(params);
            if (!response) {
                return rejectWithValue('');
            }
            return params?.sections;
        } catch (error) {
            return error;
        }
    }
);

export const learningPathOrderLesson: any = createAsyncThunk<{ rejectValue: ValidationErrors }>(
    'learningPath/orderLesson',
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await learningPathService.orderLesson(params);
            if (response?.data?.error) {
                //return rejectWithValue('');
            }
            return params;
        } catch (error) {
            return error;
        }
    }
);

export const learningPathDeleteUnit: any = createAsyncThunk<{ rejectValue: ValidationErrors }>(
    'learningPath/deleteUnit',
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await learningPathService.deleteUnit(params);
            if (!response) {
                return rejectWithValue('');
            }
            return {
                id: params?.sectionId,
            };
        } catch (error) {
            return error;
        }
    }
);

export const learningPathDeleteLesson: any = createAsyncThunk<{ rejectValue: ValidationErrors }>(
    'learningPath/deleteLesson',
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await learningPathService.deleteLesson(params);
            if (!response) {
                return rejectWithValue('');
            }
            return {
                sectionId: params?.sectionId,
                id: params?.contentId,
            };
        } catch (error) {
            return error;
        }
    }
);

export const getAssociatedCoursesLearningPath: any = createAsyncThunk<{ rejectValue: ValidationErrors }>(
    'learningPath/getAssociatedCourses',
    async (params: any, { rejectWithValue }) => {
        const  { workspaceId, learningPathId, ...restParams } = params;
        try {
            const response = await learningPathService.getLearningPathAssociatedCourses(workspaceId, learningPathId, restParams);
            if (!response) {
                return rejectWithValue('');
            }
            return response;
        } catch (error) {
            return error;
        }
    }
);

export const learningPathSlice = createSlice({
    name: 'learningPath',
    initialState,
    reducers: {
        changeLibraryTypeAdd: (state, action: PayloadAction<string>) => {
            state.libraryTypeAdd = action.payload;
        },
        changeViewType: (state, action: PayloadAction<string>) => {
            state.viewType = action.payload;
        },
        changeSelectUnit: (state, action: PayloadAction<LearningPathUnit>) => {
            state.unitSelect = action.payload;
        },
        updateUnit: (state, action: PayloadAction<any>) => {
            state.data.sections = action.payload;
        },
        clearError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        updateCanSubmmit: (state, action: PayloadAction<boolean>) => {
            state.canSubmit = action.payload;
        },
        clearData: (state, action: PayloadAction<string>) => {
            state.data = null;
        },
        clearStatusNewContent: (state) => {
            state.isNew = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(learningPathFetch.fulfilled, (state, { payload }) => {
            state.loading = 'succeeded';
            state.changed = false;
            state.data = {
                ...state.data,
                ...payload?.data
            };
        }),
            builder.addCase(learningPathCreate.fulfilled, (state, { payload }) => {
                state.loading = 'succeeded';
                state.changed = false;
                state.isNew = true;
                state.data = {
                    ...state.data,
                    ...payload?.data
                };
            }),
            builder.addCase(learningPathAddUnit.fulfilled, (state, { payload }) => {
                state.loading = 'succeeded';
                state.changed = true;
                const sections = state.data?.sections ?? [];
                sections.push(payload);
                state.data.sections = sections;
            }),
            builder.addCase(learningPathUpdateUnit.fulfilled, (state, { payload }) => {
                state.loading = 'succeeded';
                state.changed = true;
                let sections = state.data?.sections ?? [];
                sections = sections?.map((item) => {
                    if (item.id === payload.id) {
                        return { ...item, ...payload };
                    }
                    return item;
                });
                state.data.sections = sections;
            }),
            builder.addCase(learningPathOrderUnit.fulfilled, (state, { payload }) => {
                state.loading = 'succeeded';
                state.changed = true;
                state.data.sections = payload;
            }),
            builder.addCase(learningPathOrderLesson.fulfilled, (state, { payload }) => {
                state.loading = 'succeeded';
                state.changed = true;
                let sections = state.data.sections;
                sections.forEach((item, index) => {
                    if (item.id == payload.sectionId) {
                        sections[index].section_contents = payload.sections;
                    }
                });
                state.data.sections = sections;
            }),
            builder.addCase(learningPathUpdate.fulfilled, (state, { payload }) => {
                state.loading = 'succeeded';
                state.changed = true;
                if (payload?.banner && !payload?.banner?.startsWith('http')) {
                    payload.banner = `${process.env.REACT_APP_HOST_CDN}${payload.banner}`;
                }
                state.data = {
                    ...state.data,
                    ...payload,
                };
            }),
            builder.addCase(learningPathDeleteUnit.fulfilled, (state, { payload }) => {
                state.loading = 'succeeded';
                state.changed = true;
                const sections = state.data?.sections?.filter((item) => item?.id !== payload.id);
                state.data.sections = sections;
            }),
            builder.addCase(learningPathDeleteLesson.fulfilled, (state, { payload }) => {
                state.loading = 'succeeded';
                state.changed = true;
                const sections = state.data?.sections;
                sections.forEach((item, index) => {
                    if (item.id === payload.sectionId) {
                        let section_contents = item?.section_contents ?? [];
                        section_contents = section_contents?.filter((item) => item.id != payload.id);
                        sections[index].section_contents = section_contents;
                    }
                });
                state.data.sections = sections;
            }),
            builder.addCase(learningPathAddLesson.fulfilled, (state, { payload }) => {
                state.loading = 'succeeded';
                state.changed = true;
                const sections = state.data?.sections;
                sections.forEach((item, index) => {
                    if (item.id === payload.sectionId) {
                        let section_contents = item?.section_contents ?? [];
                        section_contents.push(payload);
                        sections[index].section_contents = section_contents;
                    }
                });
                state.data.sections = sections;
            }),
            builder.addCase(getAssociatedCoursesLearningPath.fulfilled, (state, { payload }) => {
                state.loading = 'succeeded';
                state.changed = false;
                state.data = {
                    ...state.data,
                    associatedCourses: payload?.data?.items,
                }
            })
            builder.addMatcher(isPending, (state, action) => {
                state.loading = 'pending';
            }),
            builder.addMatcher(isRejected, (state, action) => {
                state.loading = 'failed';
            });
    },
});

export const {
    changeLibraryTypeAdd,
    changeViewType,
    changeSelectUnit,
    updateUnit,
    clearError,
    updateCanSubmmit,
    clearData,
    clearStatusNewContent
} = learningPathSlice.actions;

export const selectlearningPathSlice = (state: RootState) => state.assignment;

export default learningPathSlice.reducer;
