import React, { useEffect, useState } from "react";
import { PlusIcon } from "@heroicons/react/outline";
import { useForm, useFieldArray } from "react-hook-form";
import './style.css';
import { RewardService } from "services";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'rc-color-picker/assets/index.css';
import ColorPicker from 'rc-color-picker';
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { checkPermission } from "utils/commonFun";

type ApoLevelComponentProps = {
    save: boolean,
    onSaved: () => void,
    onCanSave: (data: boolean) => void
};

const ApoLevelComponent = ({ save, onSaved, onCanSave }: ApoLevelComponentProps) => {
    const params: { id: string } = useParams();
    const { t: translator } = useTranslation();
    const [apoLevelsData, setApoLevelsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formChanged, setFormChanged] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const defaultValues = { name: "", point_required: null, color: "#FA9847" };

    const {
        register,
        control,
        formState: { errors, isDirty, isValid, isValidating },
        getValues,
        trigger
    } = useForm({
        mode: 'onChange'
    });

    const { fields, append, remove, update } = useFieldArray({
        name: "apoLevels",
        control
    });

    const addNewField = () => {
        append(defaultValues);
    }

    const selectColorChange = (value, index) => {
        update(index, {
            ...getValues()?.apoLevels[index],
            color: value?.color
        });
        compareDataWithForm();
    };

    useEffect(() => {
        const getApoLevel = async () => {
            const response = await RewardService.getApoLevelService(params?.id);
            if (response?.data?.items?.length > 0) {
                setApoLevelsData(response?.data?.items);
                response?.data?.items.map(item => {
                    append({
                        ...item
                    }, { shouldFocus: false });
                })
            }
            setLoading(false);
        }
        getApoLevel();

        return () => {
            setApoLevelsData([]);
        };
    }, [])

    useEffect(() => {
        if (save) {
            const handleSave = async () => {
                await trigger();
                if (!errors?.apoLevels) {
                    const payload = {
                        list_add: getNewItems(),
                        list_update: getUpdateItems(),
                        list_remove: getRemoveItem()
                    };
                    try {
                        const response = await RewardService.updateApoLevelService(params.id, payload);
                        if(response?.data?.error) {
                            toast.error(`${response?.data?.error?.name == 'NOTHING_CHANGED' ? translator('Nothing Changed!') : translator('Coin Level update failed, please try again!')}`);
                        } else {
                            toast.success(translator(`Coin Level update successfully!`));
                            updateData(payload?.list_add?.length > 0);
                        }
                        onSaved();
                    } catch {
                        toast.error(translator(`Coin Level update failed, please try again!`));
                        onSaved();
                    }
                }
            }
            handleSave();
        }
    }, [save]);

    useEffect(() => {
        onCanSave(isDirty && isValid && formChanged);
    }, [isDirty, isValid, formChanged]);

    useEffect(() => {
        compareDataWithForm();
    }, [isValidating, isDeleted]);

    const getNewItems = () => {
        let list_add = [];
        getValues().apoLevels?.forEach(item => {
            const itemNews = apoLevelsData.filter(level => item?.id === level.id);
            if (itemNews?.length === 0) {
                list_add.push({
                    ...item
                });
            }
        });
        return list_add;
    }

    const getUpdateItems = () => {
        let list_update = [];
        getValues().apoLevels?.forEach(item => {
            const itemUpdated = apoLevelsData.filter(level =>
                item?.id === level.id &&
                (item.name != level?.name || item.point_required != level?.point_required || item?.color != level?.color));

            if (itemUpdated?.length > 0) {
                list_update.push({
                    ...item
                });
            }
        });
        return list_update;
    }

    const getRemoveItem = () => {
        let list_remove = [];
        apoLevelsData.forEach(item => {
            const itemRemoved = getValues().apoLevels?.filter(level => item?.id === level.id);
            if (itemRemoved?.length === 0) {
                list_remove.push(item.id);
            }
        });
        return list_remove;
    }

    const updateData = async (isRender?: boolean) => {
        const responseData = await RewardService.getApoLevelService(params?.id);
        if (responseData?.data?.items?.length > 0) {
            setApoLevelsData(responseData?.data?.items);
            if (isRender) {
                remove();
                responseData?.data?.items.map(item => {
                    append({
                        ...item
                    });
                });
            }
            setFormChanged(false);
            setIsDeleted(false);
        }
    }

    const compareDataWithForm = () => {
        let isChanged = false;
        setFormChanged(false);
        const dataForm = getValues()?.apoLevels;
        if (apoLevelsData?.length != dataForm?.length || isDeleted) {
            isChanged = true;
        }
        getValues().apoLevels?.forEach(item => {
            const itemUpdated = apoLevelsData.filter(level =>
                item?.id === level.id &&
                (item.name != level?.name || item.point_required != level?.point_required || item?.color != level?.color));

            if (itemUpdated?.length > 0) {
                isChanged = true;
            }
        });
        setFormChanged(isChanged);
    }

    return (
        <>
            <div className="ApoLevelComponent" >
                {loading ? <div className="flex items-center justify-center mt-6">
                        <CircleSpin color="text-[#00559C]" />
                    </div> :
                    <>
                        <form className="ApoLevelComponent-content pt-8 pb-2 px-6">
                            {
                                fields.map((field: any, index) => {
                                    return (
                                        <div className="flex py-3 px-5 mb-4" key={field.id}>
                                            <div className="flex items-center mr-5">
                                                <label className="text-sm text-[#2F3738] mr-5">{translator('REWARD.LEVEL_NAME')}:</label>
                                                <div className="relative">
                                                    <input type="text" className="text-xs text-ooolab_dark_300 border border-ooolab_gray_6 bg-white rounded px-2 py-1 w-[140px] h-[32px]"
                                                        maxLength={30}
                                                        disabled={!checkPermission('rewardCoinLevels', 'U')}
                                                        {...register(`apoLevels.${index}.name` as const, {
                                                            required: true
                                                        })} />
                                                    {errors?.apoLevels && errors?.apoLevels[index]?.name?.type == 'required' && <div className="absolute text-[#E7443C] text-[10px]">{'Invalid Name'}</div>}
                                                </div>

                                            </div>
                                            <div className="flex items-center mr-5">
                                                <label className="text-sm text-[#2F3738] mr-5">{translator('REWARD.COIN_NEEDED')}:</label>
                                                <div className="relative">
                                                    <input type="number" className="text-xs text-ooolab_dark_300 border border-ooolab_gray_6 bg-white rounded px-2 py-1 w-[48px] h-[32px]"
                                                        min={1}
                                                        disabled={!checkPermission('rewardCoinLevels', 'U')}
                                                        {...register(`apoLevels.${index}.point_required` as const, {
                                                            valueAsNumber: true,
                                                            required: true,
                                                            validate: {
                                                                minNumber: value => value > -1,
                                                                biggerPrevApo: value => index === 0 ? true : value > getValues(`apoLevels.${index - 1}.point_required`)
                                                            }
                                                        })} />
                                                    {errors?.apoLevels && (errors?.apoLevels[index]?.point_required?.type == 'required' ||
                                                        errors?.apoLevels[index]?.point_required?.type == 'biggerPrevApo' ||
                                                        errors?.apoLevels[index]?.point_required?.type == 'minNumber') &&
                                                        <div className="absolute text-[#E7443C] text-[10px] whitespace-nowrap">{'Invalid Coins'}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="flex items-center mr-5 relative">
                                                <label className="text-sm text-[#2F3738] mr-5">{translator('REWARD.COLOR')}:</label>
                                                <input type="text" hidden className="text-xs text-ooolab_dark_300 border border-ooolab_gray_6 bg-white rounded px-2 py-1 w-[48px] h-[32px]"
                                                    disabled={!checkPermission('rewardCoinLevels', 'U')}
                                                    {...register(`apoLevels.${index}.color` as const, {
                                                        required: true
                                                    })} />
                                                <div className="relative">
                                                    <ColorPicker
                                                        animation="slide-up"
                                                        color={field?.color}
                                                        onClose={(color) => selectColorChange(color, index)}
                                                    />
                                                </div>
                                                {errors?.apoLevels && (errors?.apoLevels[index]?.color?.type == 'required') && <div className="absolute text-[#E7443C] text-[10px] -bottom-[15px]">{'Invalid Color'}</div>}
                                            </div>
                                            {checkPermission('rewardCoinLevels', 'D') && <div className="flex items-center ml-6">
                                                <button type="button" className="text-xs text-white rounded bg-[#FF3329] w-[50px] h-[32px]" onClick={() => { remove(index); setIsDeleted(true) }}>{translator('REWARD.DELETE')}</button>
                                            </div>}

                                        </div>
                                    )
                                })
                            }
                        </form>
                        {checkPermission('rewardCoinLevels', 'C') && <div className={`bg-white px-11 py-4  ApoLevelComponent-add`}>
                            <button type="button" className={`text-sm primary-button ${!isValid ? 'disable' : ''}`} onClick={addNewField} disabled={!isValid}>
                                <PlusIcon className="w-[16px] h-[16px] mr-1" />
                                <span className="text-sm">{translator('REWARD.ADD_LEVEL')} </span>
                            </button>
                        </div>}

                    </>
                }
            </div>
            <ToastContainer />
        </>
    )
}

export default ApoLevelComponent;