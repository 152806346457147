import PageBreadcrumb from "components/Breadcrumb/PageBreadcrumb";
import Reat, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BookingHeaderTitle } from "./components/BookingHeaderTitle/BookingHeaderTitle";
import "./Booking.scss";
import { BookingEmpty } from "./components/BookingEmpty/BookingEmpty";
import { SelectLearner } from "./components/SelectLearner/SelectLearner";
import { BookingDetails } from "./components/BookingDetails/BookingDetails";
import { BookingInstructor } from "./components/BookingInstructor/BookingInstructor";
import courseService from "services/course.service";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { useAppDispatch } from "hooks/hooks";
import { addSlotToSession, clearSlots } from "store/features/bookingSlice";
import { getCourseSettings } from "store/features/courseSlice";

export const Booking = ({ children }: { children: React.ReactNode }) => {
    const dispatch = useAppDispatch();
    const { t: translator } = useTranslation();
    const params: { id: string, courseId: string } = useParams();
    const [enrollmentSelected, setEnrollmentSelected] = useState(null);
    const [countPeople, setCountPeople] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [isUpdateListEnrollment, setUpdateListEnrollment] = useState(false)

    const handleAddSlot = (value) => {
        dispatch(addSlotToSession(value));
    }

    useEffect(() => {
        setLoading(true);
        if (!params?.id) {
            setLoading(false);
            return;
        }
        const getCountPeople = async () => {
            await courseService.getCountPeople(params.id, params.courseId).then((response) => {
                setCountPeople(response?.data);
            });
            setLoading(false);
        }
        getCountPeople();
        dispatch(
            getCourseSettings({
                workspaceId: params.id,
                id: params.courseId,
            })
        )
    }, [params]);

    const updateCredit = (amount) => {
        const learners = enrollmentSelected?.learners?.map(learner => {
            return {
                ...learner, credit_balance: { available_credit: learner?.credit_balance?.available_credit - amount }
            }
        })
        setEnrollmentSelected({...enrollmentSelected, learners})
        setUpdateListEnrollment(!isUpdateListEnrollment)
    }

    return (
        <>
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT')}
                pages={[
                    {
                        name: translator('COURSES.COURSES'),
                        href: `/workspace/${params.id}/management/courses/${params.courseId}`,
                        className: '',
                    },
                    { name: translator("SESSION.BOOK_SESSIONS"), href: ``, className: '' },
                ]}
                containerClassName=" z-10"
            />
            <div className="">
                <BookingHeaderTitle />
            </div>
            <div className="booking-container">
                {loading ? (
                    <div className="booking-loading">
                        <CircleSpin color="text-primary-500" />
                    </div>
                ) : (
                    <div className="booking-content">
                        <div className="booking-resource">
                            <SelectLearner
                                onApply={(selected) => {
                                    setEnrollmentSelected(selected);
                                    dispatch(clearSlots());
                                }}
                                isUpdateListEnrollment={isUpdateListEnrollment}
                                setUpdateListEnrollment={setUpdateListEnrollment}
                                enrollmentSelected={enrollmentSelected}
                            />
                            <div className="booking-instructor">
                                <BookingInstructor numberInstructor={countPeople?.number_instructor || 0} onAddSlot={handleAddSlot} enrollmentSelected={enrollmentSelected} />
                            </div>
                        </div>
                        <div className="booking-details">
                            <BookingDetails enrollmentSelected={enrollmentSelected} updateCredit={updateCredit} />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
