import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AddIcon } from 'assets/icon';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { TYPE_CREATE_CONTENT } from 'components/Library/LessonBuilder/constants';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { assignmentRenameContent, assignmentSortContent, changeLibraryTypeAdd, changeSelectContent, updateHasLoadData, updateSection } from 'store/features/assignmentSlice';
import { AssignmentItem } from './AssignmentItem';
import { AssignmentDelete } from '../AssignmentDelete';
import AssignmentUploadFile from '../AssignmentUploadFile/AssignmentUploadFile';
import NEW_H5P from 'assets/img/h5p.png';
import NEW_SUBMISSION from 'assets/img/submission.png';
import LIBRARY from 'assets/img/library.png';
import H5P_LIBRARY from 'assets/img/h5pLibrary.png';
import FileUploadIcon from 'assets/icon/FileUploadIcon';

import './AssignmentList.scss';
import { checkPermission } from 'utils/commonFun';

export const AssignmentList = () => {
    const { t: translator } = useTranslation();
    const params: { id: string, assignmentId: string } = useParams();
    const { data, assignmentSelect } = useAppSelector(state => state.assignment);
    const dispatch = useAppDispatch();

    const refUpload = useRef<any>();
    const [draggedContent, setDraggedContent] = useState<any>();
    const [sections, setSections] = useState<any>([]);
    const [itemDelete, setItemDelete] = useState();

    const onDragContentStart = (e, index) => {
        setDraggedContent(sections[index]);
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/html', e.target.parentNode);
        e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
    };

    const onDragOverContent = (index) => {
        const draggedOverContent = sections?.[index];
        if (draggedContent?.id === draggedOverContent?.id) {
            return;
        }
        let section_contents = sections?.filter((item) => item?.id !== draggedContent?.id);
        section_contents?.splice(index, 0, draggedContent);
        setSections(section_contents);
    };

    const onDragContentEnd = async () => {
        await dispatch(assignmentSortContent({
            workspaceId: params.id,
            assignmentId: params?.assignmentId,
            ids: sections.map(item => item?.id)
        }));
        dispatch(updateSection(sections));
    };

    const handleUpdateName = (value) => {
        dispatch(assignmentRenameContent({
            workspaceId: params.id,
            assignmentId: params?.assignmentId,
            contentId: value.id,
            sectionId: value.sectionId,
            title: value.title,
            type: value.type
        }))
    }

    const handleAddNewLibrary = (type: string) => {
        dispatch(changeLibraryTypeAdd(type));
    }

    const handleSelectContent = async (value) => {
        dispatch(changeLibraryTypeAdd(undefined));
        if (assignmentSelect?.id === value?.id) {
            return;
        }
        await dispatch(updateHasLoadData(true));
        dispatch(changeSelectContent(value));
    }

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom',
        modifiers: [
            {
                name: 'flip',
                options: {
                    allowedAutoPlacements: ['top', 'bottom'],
                },
            },
        ],
    });

    useEffect(() => {
        setSections(data?.sections || []);
    }, [data?.sections]);

    return (
        <div className='min-h-[calc(100vh-178px)]'>
            <div className="assignmentList max-h-[calc(100vh-215px)] overflow-auto custom-scrollbar">
                {
                    sections?.map((item, index) =>
                        <div key={item.id}>
                            <AssignmentItem
                                values={item}
                                onDragContentStart={onDragContentStart}
                                onDragOverContent={onDragOverContent}
                                onDragContentEnd={onDragContentEnd}
                                idx={index}
                                onDelete={() => setItemDelete(item)}
                                onUpdateName={handleUpdateName}
                                onSelectContent={handleSelectContent}
                                hasCurrentSelect={assignmentSelect?.id === item.id}
                            />
                        </div>)
                }
            </div>

            <Popover className={`relative ${checkPermission('libraryContentAssignment', 'U') ? '' : 'hidden'}`}>
                {({ open }) => (
                    <>
                        <Popover.Button
                            ref={setReferenceElement}
                            className={`${open ? '' : 'text-opacity-90'} assignmentList-createBtn`}
                        >
                            <AddIcon />
                            <span>{translator('New Content')}</span>
                            <ChevronDownIcon className="text-primary-500 w-5 h-5 absolute right-4 top-2.5" />
                        </Popover.Button>

                        <Popover.Panel
                            ref={setPopperElement}
                            style={styles.popper}
                            {...attributes.popper}
                            className="absolute left-0 z-10 mt-1 w-full rounded-lg shadow-card bg-white"
                        >
                            <div className="flex flex-col rounded-lg">
                                <div
                                    onClick={() => {
                                        handleAddNewLibrary(TYPE_CREATE_CONTENT.UPLOAD);
                                        refUpload.current.click();
                                    }}
                                    className="w-full px-4 py-2 cursor-pointer hover:bg-blue-50"
                                >
                                    <div className="flex items-center w-full gap-2">
                                        <FileUploadIcon />
                                        <p className="text-sm ">{translator('LIBRARY.UPLOAD_FILE')}</p>
                                    </div>
                                </div>
                                <div
                                    onClick={() => handleAddNewLibrary(TYPE_CREATE_CONTENT.NEW_SUBMISSION)}
                                    className="w-full px-4 py-2 cursor-pointer hover:bg-blue-50"
                                >
                                    <div className="flex items-center w-full gap-2">
                                        <img className="w-6 h-6" src={NEW_SUBMISSION} />
                                        <div className="text-sm ">New Submission</div>
                                    </div>
                                </div>

                                <div
                                    onClick={() => handleAddNewLibrary(TYPE_CREATE_CONTENT.NEW_H5P)}
                                    className="w-full px-4 py-2 cursor-pointer hover:bg-blue-50"
                                >
                                    <div className="flex items-center w-full gap-2">
                                        <img className="w-6 h-6" src={NEW_H5P} />
                                        <div className="text-sm ">{translator('LIBRARY.NEW_H5P')}</div>
                                    </div>
                                </div>
                                <div
                                    onClick={() => handleAddNewLibrary(TYPE_CREATE_CONTENT.LIBRARY)}
                                    className="w-full cursor-pointer hover:bg-blue-50 border-t border-gray-300"
                                >
                                    <div className="flex items-center w-full gap-2 px-4 py-2">
                                        <img className="w-6 h-6" src={LIBRARY} />
                                        <div className="text-sm ">{translator('Add from Library')}</div>
                                    </div>
                                </div>
                                <div
                                    onClick={() => handleAddNewLibrary(TYPE_CREATE_CONTENT.H5P_LIBRARY)}
                                    className="w-full cursor-pointer hover:bg-blue-50"
                                >
                                    <div className="flex items-center w-full gap-2 px-4 py-2">
                                        <img className="w-6 h-6" src={H5P_LIBRARY} />
                                        <div className="text-sm ">{translator('Add from H5P Library')}</div>
                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    </>
                )}
            </Popover>
            <div className='hidden'>
                {/* @ts-ignore */}
                <AssignmentUploadFile ref={refUpload} />
            </div>
            <AssignmentDelete values={itemDelete} isOpen={!!itemDelete} onClose={() => setItemDelete(null)} />
        </div>
    );
}