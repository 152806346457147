import React from 'react';
import ManagementMasterPage from '../../../RouteMasterPage';
import GroupDetail from 'components/Management/LearningGroup/GroupDetail';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';

const LearningGroupDetailPage = () => {
    return (
        <ManagePermissionWrapper>
            <GroupDetail>
                <ManagementMasterPage  />
            </GroupDetail>
        </ManagePermissionWrapper>
    );
};

export default LearningGroupDetailPage;
