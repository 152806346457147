import React from 'react';

export default function RewardIcon({className}) {
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4224_134187)">
                <path
                    d="M3.49189 8.06499L4.77789 19H19.2219L20.5079 8.06499L16.4979 10.738L11.9999 4.44099L7.50189 10.738L3.49189 8.06499ZM2.80089 5.19999L6.99989 7.99999L11.1859 2.13999C11.2784 2.01036 11.4005 1.9047 11.5421 1.83179C11.6837 1.75889 11.8406 1.72086 11.9999 1.72086C12.1591 1.72086 12.3161 1.75889 12.4577 1.83179C12.5993 1.9047 12.7214 2.01036 12.8139 2.13999L16.9999 7.99999L21.1999 5.19999C21.3588 5.09424 21.5447 5.0362 21.7356 5.03273C21.9265 5.02926 22.1144 5.0805 22.2771 5.18041C22.4398 5.28032 22.5705 5.42471 22.6537 5.59653C22.737 5.76834 22.7693 5.96039 22.7469 6.14999L21.1039 20.117C21.0752 20.3602 20.9583 20.5845 20.7753 20.7473C20.5922 20.91 20.3558 21 20.1109 21H3.88889C3.64395 21 3.40755 20.91 3.22451 20.7473C3.04148 20.5845 2.92454 20.3602 2.89589 20.117L1.25289 6.14899C1.23069 5.95947 1.26317 5.76756 1.34651 5.59591C1.42984 5.42425 1.56055 5.28003 1.7232 5.18025C1.88584 5.08048 2.07364 5.02932 2.26442 5.03281C2.45521 5.03631 2.641 5.09432 2.79989 5.19999H2.80089ZM11.9999 15C11.4695 15 10.9607 14.7893 10.5857 14.4142C10.2106 14.0391 9.99989 13.5304 9.99989 13C9.99989 12.4696 10.2106 11.9608 10.5857 11.5858C10.9607 11.2107 11.4695 11 11.9999 11C12.5303 11 13.039 11.2107 13.4141 11.5858C13.7892 11.9608 13.9999 12.4696 13.9999 13C13.9999 13.5304 13.7892 14.0391 13.4141 14.4142C13.039 14.7893 12.5303 15 11.9999 15Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_4224_134187">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
