import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';
import { checkPermission } from 'utils/commonFun';

export default function ModalAddNote({invoiceId, note, onClose, refreshData = null }) {
    const params: {id} = useParams();
    const [noteField, setNoteField] = useState('');
    const {t: translator} = useTranslation()

    useEffect(() => {
        if (note) setNoteField(note);
    }, [note]);

    const handleAddNotes = () => {
      workspaceService.addNoteToInvoice(params?.id, invoiceId, {note: noteField})
      .then(() => {
        Toastify.success(translator('ADD_DESCRIPTION_SUCCESS'));
        refreshData?.();
        onClose();
      })
      .catch(() => Toastify.error());
    }

    return (
        <div className='w-full mt-4'>
            <div className="text-sm font-semibold">{translator("DESCRIPTION")}</div>
            <div className="relative">
                <textarea 
                  className="mt-1 h-20 border border-gray-300 !rounded-sm w-full p-2 text-sm focus:outline-none" 
                  placeholder={translator("ENTER_DESCRIPTION")}
                  value={noteField}
                  onChange={(e) => setNoteField(e.target.value)}
                  maxLength={100}
                  disabled={!checkPermission('invoice','U')}
                />
                <div className="absolute bottom-2 right-2 text-xs text-gray-500">({noteField?.length}/100)</div>
            </div>
            <div className='w-full mt-5 flex justify-center gap-5'>
              <ButtonOutline type="button" onClick={onClose} >{translator('CANCEL')}</ButtonOutline>
              {
                !note &&<ButtonPrimary type="button" onClick={handleAddNotes} disabled={!checkPermission('invoice','U')} >{translator('ADD')}</ButtonPrimary>
              }
            </div>
        </div>
    );
}
