import { FC, useContext, useMemo, useState } from 'react';
import { CourseType } from 'types/Courses.type';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import ActionMenu from './ActionMenu';
import dayjs from 'dayjs';
import './Table.scss';
import NO_CONTENT from 'assets/img/empty-state/report.png';
import { useParams } from 'react-router-dom';
import { UserContext } from 'contexts/User/UserContext';
import { ListUser } from '_shared/components/User/User';

interface IViewTable {
    data: CourseType[];
    refreshData: Function;
    setOrder: (value) => void,
    order: string
}

const ViewTable: FC<IViewTable> = ({ data, refreshData , order, setOrder}) => {
    const { t: translator } = useTranslation();

    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;
    const params: { id: string } = useParams();
    const {
        userState: { result },
    } = useContext(UserContext);
    const initialState = {
        hiddenColumns: ['id'],
    };

    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('COURSES.REPORT_NAME')} />,
                Cell: ({ row }) => {
                    return (
                        <RenderTableCell
                            className="text-primary-500 font-semibold"
                            value={row.values?.name}
                        />
                    );
                },
                accessor: 'name',
                width: '180',
            },
            {
                Header: () => <RenderTableHeader value={translator('LEARNER_AND_LEARNING_GROUP')} />,
                accessor: 'learners',
                Cell: ({ row }: any) => {
                    return (
                        <div>
                            <ListUser
                                users={[...(row?.original?.learners || []), ...(row?.original?.learning_groups || [])]?.map((item) => {
                                    return {
                                        ...item,
                                        link: item?.name ? 
                                        `/workspace/${params?.id}/management/learning-groups/${item?.id}`
                                        :
                                        `/workspace/${params?.id}/management/users/${item?.id}?tab=information`
                                    }
                                })}
                                id={'learners'}
                            />
                        </div>
                    );
                },
                width: '180',
            },
            {
                Header: () => <RenderTableHeader className='text-primary-500' order={order} isOrder={true} onClick={() => setOrder(order === 'desc' ? 'asc' : 'desc')} value={translator('COURSES.RELEASE_DATE')} />,
                accessor: 'released_at',
                Cell: ({ row }: any) => (
                    <RenderTableCell value={row?.original?.status == 'released' ? dayjs.utc(row.original?.released_at).tz(result?.time_zone).format('DD/MM/YYYY') :dayjs.utc(row.original?.schedule_at).tz(result?.time_zone).format('DD/MM/YYYY')  } />
                ),
                width: '100',
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.STATUS')} />,
                accessor: 'status',
                Cell: ({ row }: any) => {
                    if (!row?.original?.status) return <RenderTableCell value="" />;
                    return (
                        <div className="py-3 ml-2">
                            <span
                                className={`InstructorStatus
                                                ${row?.values?.status == 'scheduled' ? 'scheduled' : ''}
                                                ${row?.values?.status == 'released' ? 'released' : ''}
                                            `}
                            >
                                {translator(row?.original?.status?.toUpperCase())}
                            </span>
                        </div>
                    );
                },
                width: '100',
            },
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="h-full flex items-center justify-center">
                            <ActionMenu data={row?.original} refreshData={refreshData} position={row?.index > data?.length/2 ? 'bottom-0' : ''} />
                        </div>
                    );
                },
                accessor: 'action',
                width: 60,
            },
        ],
        [data, WorkspaceDetailInformation]
    );

    return (
        <Table
            data={data}
            columns={columns}
            initialState={initialState}
            emptyContent={translator('COURSES.NO_REPORTS')}
            emptyIcon={NO_CONTENT}
        />
    );
};

export default ViewTable;
