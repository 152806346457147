import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './FeatureExtend.scss'
import { ChevronDownIcon } from 'assets/icon/ChevronDownIcon';
import InputRadio from '_shared/components/Form/InputRadio/InputRadio';
interface FeatureExtendProps {
    listFeature?: any[];
    name?: string;
    control?: any;
    watch?: any;
    permissions?: any[];
    isDisable: boolean;
    featureFor: string;
    feature?: any;
    isCustomHidePermission?: boolean
}

const FeatureExtend: FC<FeatureExtendProps> = ({ listFeature, name, control, watch, permissions, isDisable, featureFor, feature, isCustomHidePermission }) => {
    const params: any = useParams();
    const { t } = useTranslation();
    const [openExtend, setOpenExtend] = useState<boolean>(true);
    const toggleExtend = () => {
        setOpenExtend(!openExtend);
    };
    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`ROLES_PERMISSIONS.${str}`, opt));
    console.log(featureFor, name);

    const renderPermissions = (item) => {
        let textRender = '';
        if (permissions) {
            if (item?.value === 'session' && isCustomHidePermission) {
                return;
            } else {
                const isCheck = Object?.values(permissions)?.filter((per) => per?.includes(item?.value))
                if (isCheck?.length > 1) {
                    textRender = item?.value === "library_trash_content" ? translator('READ_EDIT_EMPTY_TRASH') : translator('READ_EDIT')
                } else if (isCheck?.length === 1) {
                    textRender = translator('READ_ONLY')
                }
            }

        }
        return textRender
    }

    const isCheckDisableForCourse = useMemo(() => {
        if ('noPermission_course' === watch('course')) return true;
        return false;
    }, [watch('course')])

    const customFieldDisable = ['course_resource', 'syllabus']
    return (
        <>
            {(isCustomHidePermission && featureFor === 'learningManagement') ? '' :
                <div className='featureExtends'>
                    <div className='featureExtends-header'>
                        <div
                            className="cursor-pointer"
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleExtend();
                            }}
                        >
                            <ChevronDownIcon className={`w-3 h-3 ${openExtend ? 'rotate-180 transform' : ''} `} />

                        </div>
                        <h2>{name}</h2>
                    </div>

                    {openExtend ? <div className='featureExtends-list'>
                        {listFeature?.map((item) => <div className='featureExtends-item' key={item?.value}>
                            {(renderPermissions(item) && isDisable) || !isDisable ? <div className='name'> {item?.label} </div> : ''}
                            {isDisable ? <div className='text-sm text-gray-800 font-normal col-span-2'>{renderPermissions(item)} </div> : <>
                                <div className='readEdit'>
                                    {!(item?.hideArr?.includes('write')) && <InputRadio control={control}
                                        inputName={item?.value}
                                        value={`write_${item?.value}`}
                                        checked={watch(item?.value) === `write_${item?.value}`}
                                        disabled={item?.isDisable || (customFieldDisable?.includes(item?.value) && isCheckDisableForCourse)}
                                    />}

                                </div>
                                <div className='readOnly'>
                                    {!(item?.hideArr?.includes('read')) && <InputRadio control={control}
                                        inputName={item?.value}
                                        value={`read_${item?.value}`}
                                        checked={watch(item?.value) === `read_${item?.value}`}
                                        disabled={item?.isDisable || (customFieldDisable?.includes(item?.value) && isCheckDisableForCourse)}
                                    />}
                                </div>
                                <div className='noPermission'>
                                    {!(item?.hideArr?.includes('noPermission')) &&
                                        <InputRadio control={control}
                                            inputName={item?.value}
                                            value={`noPermission_${item?.value}`}
                                            checked={watch(item?.value) == `noPermission_${item?.value}` || watch(item?.value) == undefined}
                                            disabled={item?.isDisable || (customFieldDisable?.includes(item?.value) && isCheckDisableForCourse)}
                                        />}
                                </div>
                            </>}
                        </div>)}
                    </div> : ''}
                </div>
            }
        </>
    );
};

export default FeatureExtend;
