
import React, { useRef, useState } from "react";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/outline";
import { DeleteIcon } from "assets/icon";
import { ButtonIcon, Toastify } from "_shared";
import { Caret } from "./Caret";
import { clsx } from "clsx";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { LearningPathUnitDelete } from "../LearningPathUnitDelete";
import { LearningPathUnit } from "types/LearningPath.type";
import { changeSelectUnit, learningPathOrderUnit, learningPathUpdateUnit } from "store/features/learningPathSlice";
import { useParams } from "react-router-dom";
import "./LessonHeader.scss";
import { useTranslation } from "react-i18next";
import { checkPermission } from "utils/commonFun";

type LessonHeaderProps = {
    values: LearningPathUnit,
    actived: boolean,
    unitIndex: number
}
export const LessonHeader = ({ values, actived, unitIndex }: LessonHeaderProps) => {
    const { t: translator } = useTranslation();
    const params: { id: string, learningPathId: string } = useParams();
    const { data, unitSelect } = useAppSelector(state => state.learningPath);
    const dispatch = useAppDispatch();
    const [hasOpenDelete, setHasOpenDelete] = useState<boolean>(false);
    const refInput = useRef<any>();
    const [hasEdit, setHasEdit] = useState(false);
    const [value, setValue] = useState<any>(values.title);

    const onEditName = (e) => {
        e.stopPropagation();
        setHasEdit(true);
        setTimeout(() => {
            refInput.current.focus();
        }, 100);
    };

    const handleUpdate = (value) => {
        dispatch(learningPathUpdateUnit({
            workspaceId: params.id,
            id: params.learningPathId,
            sectionId: values.id,
            title: value
        }))
    }

    const handleDelete = () => {
        setHasOpenDelete(true);
    }

    const handleMoveUnit = (values, next: boolean) => {
        const fromIndex = unitIndex;
        let toIndex = unitIndex - 1;
        if (next) {
            toIndex = unitIndex + 1;
        }
        const sections = [...values];
        const element = sections[fromIndex];
        sections.splice(fromIndex, 1);
        sections.splice(toIndex, 0, element);

        dispatch(learningPathOrderUnit({
            workspaceId: params?.id,
            id: params?.learningPathId,
            sections
        }))
    }

    return (<>
        <div className="lessonHeader">
            <div className="lessonHeader-dot"><div></div></div>
            <ButtonIcon className="mx-1 flex-none w-10"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                    if (unitSelect?.id == values.id) {
                        dispatch(changeSelectUnit(null));
                    } else {
                        dispatch(changeSelectUnit(values));
                    }
                }}>
                <span className={clsx("transition",
                    { 'rotate-90': actived }
                )}>
                    <Caret />
                </span>
            </ButtonIcon>
            {hasEdit ? <input
                ref={refInput}
                value={value}
                className="lessonHeader-input disabled:bg-gray-50"
                onFocus={(e) => e.stopPropagation()}
                disabled={!checkPermission('learningPath', 'U')}
                onBlur={(e) => {
                    if (e.target.value?.length > 255) {
                        return Toastify.error(translator('FORM_CONST.MAX_LENGTH_TEXT', { maxLength: 255 }));
                    }
                    handleUpdate(e.target.value);
                    setHasEdit(false);
                }}
                onKeyDown={(e: any) => {
                    e.code === 'Space' && e.stopPropagation();
                    if (e.code === 'Enter') {
                        refInput.current.blur();
                        e.stopPropagation()
                    }
                }}
                onChange={(event) => {
                    setValue(event.target.value);
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}

            /> : <div className="grid"><span className="truncate hover:bg-gray-300" onClick={onEditName} >{values.title}</span></div>}
            {!hasEdit ? <span className="lessonHeader-badge">{values?.section_contents?.length ?? 0}</span> : null}
            <div className="lessonHeader-action">
                <ButtonIcon disabled={!checkPermission('learningPath', 'U')} className="disabled:cursor-not-allowed" onClick={(event: React.MouseEvent<HTMLElement>) => {
                    event.preventDefault();
                    handleDelete();
                }}>
                    <span className="text-red-500 p-1">
                        <DeleteIcon height="16" width="16" />
                    </span>
                </ButtonIcon>
                <ButtonIcon
                    className={clsx({
                        'cursor-not-allowed': unitIndex == 0,
                        'disabled:cursor-not-allowed': true
                    })}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.stopPropagation();
                        handleMoveUnit(data?.sections, false);
                    }}
                    disabled={unitIndex == 0 || !checkPermission('learningPath', 'U')}
                >
                    <span className={clsx("text-primary-500 p-1",
                        { '!text-gray-400': unitIndex == 0 })}>
                        <ArrowUpIcon className="w-4 h-4" />
                    </span>
                </ButtonIcon>
                <ButtonIcon
                    className={clsx({
                        'cursor-not-allowed': unitIndex == data.sections?.length - 1,
                        'disabled:cursor-not-allowed': true
                    })}
                    disabled={!checkPermission('learningPath', 'U')}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.stopPropagation();
                        handleMoveUnit(data?.sections, true);
                    }}>
                    <span className={clsx("text-primary-500 p-1",
                        { '!text-gray-400': unitIndex == data.sections?.length - 1 })}>
                        <ArrowDownIcon className="w-4 h-4" />
                    </span>
                </ButtonIcon>
            </div>
        </div>
        {hasOpenDelete && <LearningPathUnitDelete
            isOpen={hasOpenDelete}
            onClose={() => setHasOpenDelete(false)}
            values={values}
        />}
    </>)
}