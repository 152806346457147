import React from 'react';
import RouteMasterPage from 'pages/RouteMasterPage';
import { TemplateBuilder } from 'components/Workspace/Course/Template/TemplateBuilder';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';

const TemplateBuilderPage = () => {
    return (
        <ManagePermissionWrapper>
            <TemplateBuilder>
                <RouteMasterPage />
            </TemplateBuilder>
        </ManagePermissionWrapper>
    );
};

export default TemplateBuilderPage;
