export const CLASS_LIST = {
    REQ_GET_CLASSLIST: 'REQ_GET_CLASSLIST',
    REQ_GET_CLASSLIST_SUCCESS: 'REQ_GET_CLASSLIST_SUCCESS',
    REQ_GET_CLASSLIST_FAIL: 'REQ_GET_CLASSLIST_FAIL',

    REQ_GET_CLASS_DETAIL: 'REQ_GET_CLASS_DETAIL',
    REQ_GET_CLASS_DETAIL_SUCCESS: 'REQ_GET_CLASS_DETAIL_SUCCESS',
    REQ_GET_CLASS_DETAIL_FAIL: 'REQ_GET_CLASS_DETAIL_FAIL',

    REQ_UPDATE_CLASS: 'REQ_UPDATE_CLASS',
    REQ_UPDATE_CLASS_SUCCESS: 'REQ_UPDATE_CLASS_SUCCESS',
    REQ_UPDATE_CLASS_FAIL: 'REQ_UPDATE_CLASS_FAIL',
    REQ_RESET_CLASS_DETAIL_STATUS: 'REQ_RESET_CLASS_DETAIL_STATUS',

    REQ_GET_CLASS_SESSION: 'REQ_GET_CLASS_SESSION',
    REQ_GET_CLASS_SESSION_SUCCESS: 'REQ_GET_CLASS_SESSION_SUCCESS',
    REQ_GET_CLASS_SESSION_FAIL: 'REQ_GET_CLASS_SESSION_FAIL',
};

export const CLASS_ASSIGNMENTS = {
    GET_CLASS_ASSIGNMENTS: 'GET_CLASS_ASSIGNMENTS',
    GET_CLASS_ASSIGNMENTS_SUCCESS: 'GET_CLASS_ASSIGNMENTS_SUCCESS',
    GET_CLASS_ASSIGNMENTS_FAIL: 'GET_CLASS_ASSIGNMENTS_FAIL',
};

export const CLASS_GRADEBOOK = {
    GET_CLASS_GRADEBOOK: 'GET_CLASS_GRADEBOOK',
    GET_CLASS_GRADEBOOK_SUCCESS: 'GET_CLASS_GRADEBOOK_SUCCESS',
    GET_CLASS_GRADEBOOK_FAIL: 'GET_CLASS_GRADEBOOK_FAIL',
    GET_CLASS_GRADEBOOK_APO: 'GET_CLASS_GRADEBOOK_APO',
    GET_CLASS_GRADEBOOK_SUCCESS_APO: 'GET_CLASS_GRADEBOOK_SUCCESS_APO',
    GET_CLASS_GRADEBOOK_FAIL_APO: 'GET_CLASS_GRADEBOOK_FAIL_APO',
};

export const CLASS_REPORT = {
    GET_CLASS_REPORT: 'GET_CLASS_REPORT',
    GET_CLASS_REPORT_SUCCESS: 'GET_CLASS_REPORT_SUCCESS',
    GET_CLASS_REPORT_FAIL: 'GET_CLASS_REPORT_FAIL',
};

export const CLASS_RECORD_OF_WORK = {
    GET_CLASS_RECORD_OF_WORK: 'GET_CLASS_RECORD_OF_WORK',
    GET_CLASS_RECORD_OF_WORK_SUCCESS: 'GET_CLASS_RECORD_OF_WORK_SUCCESS',
    GET_CLASS_RECORD_OF_WORK_FAIL: 'GET_CLASS_RECORD_OF_WORK_FAIL',
    UPDATE_CLASS_RECORD_OF_WORK: 'UPDATE_CLASS_RECORD_OF_WORK',
    UPDATE_CLASS_RECORD_OF_WORK_SUCCESS: 'UPDATE_CLASS_RECORD_OF_WORK_SUCCESS',
    UPDATE_CLASS_RECORD_OF_WORK_FAIL: 'UPDATE_CLASS_RECORD_OF_WORK_FAIL',
    RESET_CLASS_RECORD_OF_WORK: 'RESET_CLASS_RECORD_OF_WORK',
};

export const CLASS_APO = {
    GET_CLASS_STUDENTS: 'GET_CLASS_STUDENTS',
    GET_CLASS_STUDENTS_SUCCESS: 'GET_CLASS_STUDENTS_SUCCESS',
    GET_CLASS_STUDENTS_FAIL: 'GET_CLASS_STUDENTS_FAIL',
    UPDATE_CLASS_STUDENTS_REWARD_POINTS: 'UPDATE_CLASS_STUDENTS_REWARD_POINTS',
    UPDATE_CLASS_STUDENTS_REWARD_POINTS_SUCCESS: 'UPDATE_CLASS_STUDENTS_REWARD_POINTS_SUCCESS',
    UPDATE_CLASS_STUDENTS_REWARD_POINTS_FAIL: 'UPDATE_CLASS_STUDENTS_REWARD_POINTS_FAIL',
    RESET_GET_CLASS_STUDENTS: 'RESET_GET_CLASS_STUDENTS',
};