import React, { Component, ReactNode } from 'react';
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

interface Props {
  children?: any;
}

declare global {
  interface Window {
      FS: any;
  }
}

export default class ErrorBoundary extends Component <Props> {
  constructor(props) {
    super(props);
    // Sentry.init({
    //   dsn: process.env.REACT_APP_SENTRY_DSN,
    //   environment: process.env.NODE_ENV
    // });
    Sentry.init({
      dsn: "https://6ed30002cb114caca3d7cd413aaf9965@o1365645.ingest.sentry.io/6661699",
      integrations: [new BrowserTracing()],
      tracesSampleRate: process.env.REACT_APP_S3_PATH == 'production' ? 0.05 : 0.01
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
    // if (typeof window !== 'undefined') {
    //   let retries = 0;
    //   const intervalID = setInterval(() => {
    //     if (window.FS && window.FS.getCurrentSessionURL) {
    //       Sentry.addBreadcrumb({
    //         category: 'FullStory',
    //         message: window.FS.getCurrentSessionURL(),
    //       })
    //       window.clearInterval(intervalID);
    //       return;
    //     }
    //     retries += 1;
    //     if (retries === 5) {
    //       window.clearInterval(intervalID);
    //     }
    //   }, 1000);
    // }
  }

  render() {
    // next code block goes here
  return this.props.children;
  }
}

