import React from 'react';
import ManagementMasterPage from 'pages/RouteMasterPage';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';
import LearningGroup from 'components/Management/LearningGroup/GroupList';

const GroupPage = () => {
    return (
        <ManagePermissionWrapper>
            <LearningGroup>
                <ManagementMasterPage />
            </LearningGroup>
        </ManagePermissionWrapper>
    );
};

export default GroupPage;