import DialogComponent from 'components/Dialog/DialogComponent';
import { useContext, useState } from 'react';
import GradeReport from './GradebookComponents/GradeReport';
import PreviewReport from './GradebookComponents/PreviewReport';
import dayJs from 'dayjs';
import { ReportType } from 'types/Class.type';
import LessonReport from './GradebookComponents/LessonReport';
import { useTranslation } from 'react-i18next';
import CreateProgressReport from './CreateProgressReport';
import CreateGradebookReport from './CreateGradebookReport';
import classService from 'services/class.service';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'hooks/custom';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import Tooltip from 'components/Tooltip';
import { checkTeacherCompleted } from './GradebookComponents/ReportsContanst';
import TooltipArr from 'components/Tooltip/ToolTipArr';
import dayjs from 'dayjs';
import { UserContext } from 'contexts/User/UserContext';
import { Toastify } from '_shared';

const renderAvatar = (instructor: any, idx: number) => {
    return (
        <>
            {instructor.is_completed && idx <= 1 ? (
                <Tooltip title={instructor.display_name} mlClass="ml-0" key={idx}>
                    <img src={instructor?.avatar_url} alt="ava" className="w-6 h-6  rounded-full  mr-2" />
                </Tooltip>
            ) : (
                ''
            )}
        </>
    );
};

interface ReportItemProps {
    data: ReportType;
    studentName: string;
    setReloadData: React.Dispatch<React.SetStateAction<boolean>>;
    dataDetailStudent: any;
}
const styleBtn = `py-2 border  rounded-lg  hover:text-white text-base font-semibold`;
const ReportItem: React.FC<ReportItemProps> = ({ data, studentName, setReloadData, dataDetailStudent }) => {
    const { t: translator } = useTranslation();
    const params: { id: string; classId: string } = useParams();
    const [previewProgress, setPreviewProgress] = useState<boolean>(false);
    const [previewGradebook, setPreviewGradebook] = useState<boolean>(false);
    const [previewLesson, setPreviewLesson] = useState<boolean>(false);
    const [editProgress, setEditProgress] = useState<boolean>(false);
    const [editGradebook, setEditGradebook] = useState<boolean>(false);
    const [statusReport, setStatusReport] = useState<any>('');
    const [publishDate, setPublishDate] = useState<any>(null);
    const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
    const { booleanValue: modalDeleteReport, toggleBooleanValue: toggleModalDeleteReport } = useBoolean();
    const {
        userState: { result },
    } = useContext(UserContext);

    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const {
        result: { membership },
    } = getWorkspaceDetailState;

    const togglePreviewProgress = () => {
        setPreviewProgress(!previewProgress);
    };

    const togglePreviewGradebook = () => {
        setPreviewGradebook(!previewGradebook);
    };
    const togglePreviewLesson = () => {
        setPreviewLesson(!previewLesson);
    };
    const toggleEditProgress = () => {
        setEditProgress(!editProgress);
    };
    const toggleEditGradebook = () => {
        setEditGradebook(!editGradebook);
    };

    const typeReport = () => {
        let text;
        switch (data?.type) {
            case 'progress':
                text = translator('MY_REPORTS.PROGRESS_REPORT');
                break;
            case 'gradebook':
                text = translator('MY_REPORTS.GRADEBOOK_REPORT');
                break;
            case 'lesson':
                text = translator('MY_REPORTS.LESSON_REPORT');
                break;
            default:
                break;
        }
        return text;
    };

    const handleReviewReport = () => {
        switch (data?.type) {
            case 'progress':
                togglePreviewProgress();
                break;
            case 'gradebook':
                togglePreviewGradebook();
                break;
            case 'lesson':
                togglePreviewLesson();
                break;
            default:
                break;
        }
    };

    const handleEdit = (type: string) => {
        if (type === 'progress') toggleEditProgress();
        else toggleEditGradebook();
    };

    const onDeleteReport = async () => {
        if (data.id) {
            setLoadingDelete(true);
            await classService
                .deleteReportClass(params.id, params.classId, data.id.toString())
                .then((res) => {
                    Toastify.success();
                    toggleModalDeleteReport();
                    setReloadData(true);
                })
                .catch(() => {
                    Toastify.error();
                    toggleModalDeleteReport();
                });
        }
        setLoadingDelete(false);
    };

    return (
        <div className="py-3 px-4 my-2 grid grid-cols-12 gap-2 items-center  border border-ooolab_gray_6 rounded-lg justify-center">
            <div className="col-span-2  border-r border-r-ooolab_gray_6 text-sm font-semibold text-item_bar_hover   ">{data.name}</div>
            <div className="col-span-2 ">
                <div className="text-ooolab_dark_2 text-xs font-semibold mb-1">Report Type</div>
                <div className="text-[#2E3A59] text-sm font-normal">{typeReport()}</div>
            </div>
            <div className="col-span-1 ">
                <div className="text-ooolab_dark_2 text-xs font-semibold mb-1">Created date</div>
                <div className="text-[#2E3A59] text-sm font-normal">
                    {dayJs(data.created_at).tz(result?.time_zone).format('DD/MM/YYYY')}
                </div>
            </div>
            <div className="col-span-2 ">
                <div className="text-ooolab_dark_2 text-xs font-semibold mb-1">Created by</div>
                <div className="flex justify-start items-center ">
                    <img src={data?.creator?.avatar_url} alt="ava" className="w-6 h-6  rounded-full  mr-2" />
                    <p className="text-[#2E3A59] text-sm truncate">{data?.creator?.display_name}</p>
                </div>
            </div>
            <div className="col-span-1 h-full mt-1">
                <div className="text-ooolab_dark_2 text-xs font-semibold mb-1">Completed</div>

                <div className="flex justify-start items-center ">
                    {data?.data?.comments ? checkTeacherCompleted(data?.data?.comments).map((i, idx) => renderAvatar(i, idx)) : null}

                    {data?.data?.comments && checkTeacherCompleted(data?.data?.comments).length > 2 ? (
                        <TooltipArr
                            titleArr={checkTeacherCompleted(data?.data?.comments)?.map((i) => i.display_name)}
                            mlClass="ml-0"
                            key={`tooltip-${checkTeacherCompleted(data?.data?.comments).length}`}
                            styles="test"
                        >
                            <div className="border border-ooolab_dark_1 rounded-full w-6 h-6 flex justify-center items-center text-xs text-ooolab_dark_1">
                                +{checkTeacherCompleted(data?.data?.comments).length - 2}
                            </div>
                        </TooltipArr>
                    ) : null}
                </div>
            </div>
            <div className="col-span-1 ">
                <div className="text-ooolab_dark_2 text-xs font-semibold mb-1">Status</div>
                <div className="flex justify-start items-center ">
                    <p className={` text-sm ${data?.status === 'draft' ? 'text-[#8F90A6]' : 'text-[#FA9847]'} capitalize`}>
                        {data?.status || 'Publish'}
                    </p>
                </div>
            </div>

            <div className="col-span-3 flex justify-end">
                <button
                    className={`${styleBtn} border-ooolab_selected_bar_item text-primary-500 hover:bg-primary-500 px-4  mr-3`}
                    onClick={handleReviewReport}
                >
                    Preview
                </button>
                {(data?.type === 'progress' || data?.type === 'gradebook') && (
                    <>
                        <button
                            className={`${styleBtn} border-ooolab_selected_bar_item text-primary-500 hover:bg-primary-500 px-4 mr-3`}
                            onClick={() => handleEdit(data?.type)}
                        >
                            Edit
                        </button>
                    </>
                )}
                {membership?.role === 'admin' ? (
                    <button
                        className={`${styleBtn} border-[#E7443C] text-[#E7443C] hover:bg-[#E7443C] px-4`}
                        onClick={toggleModalDeleteReport}
                    >
                        Delete
                    </button>
                ) : null}
            </div>
            <DialogComponent
                isOpen={previewProgress}
                width={'min-w-ooolab_w_50'}
                title={'Report Preview'}
                onCloseModal={togglePreviewProgress}
                maskClosable={true}
                stylesTitle={' !text-base text-gray-900'}
                child={<PreviewReport reportId={data.id} studentName={studentName} />}
            />
            <DialogComponent
                isOpen={previewGradebook}
                width={'min-w-ooolab_w_50'}
                title={'Report Preview'}
                stylesTitle={' !text-base text-gray-900'}
                onCloseModal={togglePreviewGradebook}
                maskClosable={true}
                child={<GradeReport reportId={data.id} studentName={studentName} />}
                styles={'max-w-min'}
            />
            <DialogComponent
                isOpen={previewLesson}
                width={'min-w-[40vw]'}
                title={'Report Preview'}
                onCloseModal={togglePreviewLesson}
                maskClosable={true}
                child={<LessonReport reportId={data.id} />}
                styles={'max-w-min'}
            />
            <DialogComponent
                isOpen={editProgress}
                width={'max-w-xs lg:min-w-max'}
                title={'Edit Progress Report'}
                subTitle={
                    <div className="flex ml-2 items-center">
                        <div className=" bg-ooolab_dark_50 w-1 h-1 rounded-full mr-2"></div>
                        <div
                            className={`${
                                statusReport === 'draft' ? ' text-dark-100' : 'text-[#FA9847]'
                            } capitalize text-xs font-semibold `}
                        >
                            {statusReport}
                        </div>
                        <div className=" text-ooolab_dark_2 ml-1 text-xs font-semibold ">
                                {statusReport === 'publish' && publishDate
                                    ? `on ${dayjs(publishDate).tz(result?.time_zone).format('DD/MM/YYYY ')}`
                                    : null}
                            </div>
                    </div>
                }
                onCloseModal={toggleEditProgress}
                maskClosable={true}
                stylesTitle={' !text-base text-gray-900'}
                child={
                    <CreateProgressReport
                        setReloadData={setReloadData}
                        editId={data.id}
                        detailStudent={dataDetailStudent}
                        setStatusReport={setStatusReport}
                        statusReport={statusReport}
                        studentName={studentName}
                        setPublishDate={setPublishDate}
                    />
                }
            />
            <DialogComponent
                isOpen={editGradebook}
                width={'max-w-xs lg:min-w-max'}
                title={'Edit Gradebook Report'}
                stylesTitle={' !text-base text-gray-900'}
                subTitle={
                    <div className="flex ml-2 items-center">
                        <div className="bg-ooolab_dark_50 w-1 h-1 rounded-full mr-2"></div>
                        <div
                            className={`${
                                statusReport === 'draft' ? ' text-dark-100' : 'text-[#FA9847]'
                            } capitalize text-xs font-semibold flex items-center`}
                        >
                            {statusReport}
                        </div>
                        <div className=" text-ooolab_dark_2 ml-1 text-xs font-semibold ">
                                {statusReport === 'publish' && publishDate
                                    ? `on ${dayjs(publishDate).tz(result?.time_zone).format('DD/MM/YYYY ')}`
                                    : null}
                            </div>
                    </div>
                }
                onCloseModal={toggleEditGradebook}
                maskClosable={true}
                child={
                    <CreateGradebookReport
                        setReloadData={setReloadData}
                        editId={data.id}
                        detailStudent={dataDetailStudent}
                        setStatusReport={setStatusReport}
                        statusReport={statusReport}
                        studentName={studentName}
                        setPublishDate={setPublishDate}
                    />
                }
            />

            <DialogComponent
                isOpen={modalDeleteReport}
                onCloseModal={toggleModalDeleteReport}
                maskClosable={true}
                title={'Delete Report'}
                width={'min-w-[20vw]'}
                styles={'max-w-min'}
                child={
                    <>
                        <div className="font-inter ">
                            <div className="px-8 pt-2.5 pb-4 align-middle inline-block min-w-full text-ooolab_dark_1 w-full">
                                <p className="text-sm whitespace-pre-wrap break-words font-semibold ">
                                    Are you sure to delete "{data?.name}" ?
                                </p>
                                <div className="flex justify-center items-center mt-8">
                                    <button
                                        type="button"
                                        onClick={onDeleteReport}
                                        className="text-xs min-w-[90px] bg-ooolab_dark_300 text-white rounded-ooolab_radius_4px font-semibold flex justify-center items-center shadow-ooolab_sched_button text-ooolab_sm px-3 py-2"
                                    >
                                        {loadingDelete ? <CircleSpin color="text-[#ffffff]" className="mx-auto" /> : 'Delete Report'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                }
            />
        </div>
    );
};

export default ReportItem;
