export const TextIcon = ({ fill = '#374151', width = 20, height = 20 }: { fill?: string; width?: number; height?: number }) => {
    return (
        <svg width={width} height={fill} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.1665 5.00004C4.1665 4.07957 4.9127 3.33337 5.83317 3.33337H14.1665C15.087 3.33337 15.8332 4.07957 15.8332 5.00004V5.83337C15.8332 6.29361 15.4601 6.66671 14.9998 6.66671C14.5396 6.66671 14.1665 6.29361 14.1665 5.83337V5.00004H10.8332V15H11.6665C12.1267 15 12.4998 15.3731 12.4998 15.8334C12.4998 16.2936 12.1267 16.6667 11.6665 16.6667H8.33317C7.87293 16.6667 7.49984 16.2936 7.49984 15.8334C7.49984 15.3731 7.87293 15 8.33317 15H9.1665V5.00004H5.83317V5.83337C5.83317 6.29361 5.46007 6.66671 4.99984 6.66671C4.5396 6.66671 4.1665 6.29361 4.1665 5.83337V5.00004Z"
                fill={fill}
            />
        </svg>
    );
};
