import * as React from 'react';
import H5PProvider from 'contexts/H5P/H5PProvider';
import ContentPreview from 'components/ContentPreview';

const ContentPlayerPage = () => {
    return (

        <H5PProvider>
            <ContentPreview />
        </H5PProvider>

    );
};

export default ContentPlayerPage;
