import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/styles/themes.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'translations/i18n';

import { store } from './store/store';
import { Provider } from 'react-redux';

ReactDOM.render(
    //<React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    //</React.StrictMode>
    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

window.addEventListener('load', () => {
    if ('serviceWorker' in navigator) {
        // console.log('Service worker supported');
        navigator.serviceWorker
            .getRegistrations()
            .then(function (registrations) {
                for (let registration of registrations) {
                    registration.unregister();
                }
                // Register service worker
                // console.log('Service worker register ...');
                navigator.serviceWorker
                    .register('/sw.5.js')
                    .then((registration) => {
                        // console.log('Service worker registered');
                    })
                    .catch((error) => {
                        // console.log('Register failed: ' + error)";
                    });
            });
    } else {
        // console.log('Service worker not supported');
    }
});
