import React from "react";
import { useTranslation } from "react-i18next";

type SelfBookingCreditWarningProps = {
  onClose: () => void;
  credit?: number;
  isLimitSession: boolean
};

const SelfBookingCreditWarning: React.FC<SelfBookingCreditWarningProps> = ({
  onClose,
  credit,
  isLimitSession
}: SelfBookingCreditWarningProps) => {
  const { t } = useTranslation();
  const translator = (str: string) => t(`SELF_BOOKING.${str}`);

  return (
    <>
      <div className="text-base text-dark-300 font-normal my-6">
        { 
          isLimitSession ? translator("LIMIT_SESSION") : 
          credit === 0 ? translator("NO_HAS_CREDIT") : translator("WARNING_CREDIT")
        }
      </div>
      
      <div className="w-full flex justify-center mt-8">
      <button
          className="text-white text-center text-base bg-primary-500 font-medium rounded-full py-3 w-full"
          type="button"
          onClick={onClose}
        >
          {translator("OKAY")}
        </button>
      </div>
    </>
  );
};

export default SelfBookingCreditWarning;
