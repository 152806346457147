import { FC, useRef, useEffect, useCallback, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Link, useParams } from 'react-router-dom';
import { debounce, isEqual } from 'lodash';
import Select, { components } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';

import { SessionDateFormat, SessionServiceFormat } from 'constant/util.const';
import workspaceService from 'services/workspace.service';
import { classTypes } from 'constant/classTypes';
import classService from 'services/class.service';
import DatePicker from './DatePicker';
import { updateSession } from 'components/Management/Class/ClassDetail/ClassSession/util';
import ButtonComponent from 'components/Button/ButtonComponent';
import ErrorMessageForm from 'components/ErrorMessageForm';
import { XIcon } from '@heroicons/react/outline';
import { UUID } from 'utils/commonFun';
import avatarImg from 'assets/avatar_option.png';
import './style.css';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { Toastify } from '_shared';

const ValueContainer = (props) => {
    const { hasValue, children } = props;
    const [chips, otherChildren] = children;
    if (!hasValue) {
        return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
    }

    const CHIPS_LIMIT = 3;
    const overflowCounter = chips.slice(CHIPS_LIMIT).length;
    // const displayChips = chips.slice(overflowCounter, overflowCounter + CHIPS_LIMIT);

    const displayChips = chips.slice(0, CHIPS_LIMIT);

    return (
        <components.ValueContainer {...props}>
            {displayChips}

            <div
                className={`text-[10px] text-dark-300 font-semibold ml-1 bg-[#E6E6E6] h-[26px] px-1 rounded flex items-center justify-center ${
                    overflowCounter === 0 ? 'hidden ' : ''
                }`}
            >
                {' '}
                {overflowCounter > 0 && `+ ${overflowCounter}`}
            </div>
            {otherChildren}
        </components.ValueContainer>
    );
};

export const MultiValue = (props) => {
    const content = props.children.substring(0, 12);
    return (
        <components.MultiValue {...props}>
            <div className="flex items-center text-[10px] text-dark-300 font-semibold">
                <img className={`rounded-full w-5 h-5 mr-2`} src={props?.data?.avatar_url || avatarImg} alt="img" />
                {content}...
            </div>
        </components.MultiValue>
    );
};

interface ModalSessionProps {
    onClose: () => void;
    onCloseModal?: () => void;
    handleFetchData: () => void;
    data?: any;
    timeZone: string;
}

const customSelect: any = {
    clearIndicator: (base) => ({
        ...base,
        padding: 0,
        marginRight: -6,
    }),
    indicatorSeparator: (base) => ({ ...base, display: 'none' }),
    control: (base) => {
        return {
            ...base,
            borderColor: '#EBEBF0',
            padding: 0,
            paddingLeft: 8,
            minHeight: 34,
            color: '#2E3A59',
        };
    },
    valueContainer: (base) => ({
        ...base,
        padding: 0,
        fontSize: 13,
    }),
    dropdownIndicator: (base, { selectProps }) => ({
        ...base,
        padding: 6,
        transition: 'all 0.3s ease-in-out',
        transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    }),
    input: (base) => ({
        ...base,
        padding: 0,
        margin: 0,
        fontSize: 13,
    }),
    option: (base) => ({
        ...base,
        paddingLeft: '15px',
        fontSize: 13,
    }),
};

const customSelectLesson: any = {
    ...customSelect,
    menuList: (base) => ({
        ...base,
        height: 200,
    }),
    groupHeading: (base) => ({
        ...base,
        color: '#2E3A59',
        fontSize: 13,
        fontWeight: 'bold',
        textTransform: 'none',
    }),
};

const customSelectAPR: any = {
    ...customSelect,
    menuList: (base) => ({
        ...base,
        height: 190,
    }),
    valueContainer: (base) => ({
        ...base,
        fontSize: 13,
        flexWrap: 'nowrap',
        width: 1,
        padding: 0,
    }),
    multiValue: (base) => ({
        ...base,
        minWidth: 'unset',
    }),
};

const ModalSession: FC<ModalSessionProps> = ({ onClose, onCloseModal, handleFetchData, data, timeZone }) => {
    const isOpeningCalendar = useRef(false);
    const selectAssignmentRef = useRef(null);
    const { t: translator } = useTranslation();
    const param: { id: string; classId: string } = useParams();
    const featureFlags = JSON.parse(process.env.REACT_APP_FEATURE_FLAGS);
    const { dispatch, getWorkspaceDetailState } = useContext(GetWorkspaceContext);

    const { detail } = getWorkspaceDetailState.class;


    const [lessons, setLessons] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [, setUpdateTime] = useState(0);
    const [initValue, setInitValue] = useState<{
        startTime: string | undefined | null;
        endTime: string | undefined | null;
        lesson: number | undefined | null;
        type: string | undefined | null;
        assignments: any;
        teacherSession?: any;
        studentSession?: any;
        vc_Link?: string;
    }>();
    const [isChange, setIsChange] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [assignments, setAssignments] = useState([]); // field use to submit data
    const [assignment_templates, setAssignment_templates] = useState([]); // field use to submit data
    const [assignmentsView, setAssignmentsView] = useState([]); // array temp handle to view assignment on UI
    const [assignmentsValue, setAssignmentsValue] = useState([]); // array temp handle to add/delete assignment on UI
    const [selectedTeachers, setSelectedTeachers] = useState<any[]>([]);
    const [selectedStudents, setSelectedStudents] = useState<any[]>([]);
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        reset,
        clearErrors,
        control,
        setValue,
        setError,
        watch,
        trigger,
    } = useForm({
        defaultValues: {
            startTime: undefined,
            endTime: undefined,
            lesson: undefined,
            type: undefined,
            assignments: undefined,
            teacherSession: [],
            studentSession: [],
            vc_link: detail?.vc_link || '',
        },
    });

    useEffect(() => {
        if (data) {
            setValue('startTime', data?.start_time);
            setValue('vc_link', data?.vc_link);
            setValue('endTime', data?.end_time);
            if (data?.lesson) {
                setValue('lesson', data.lesson.id);
            }
            setValue('type', data.type);
            setUpdateTime(new Date().getTime());
            setAssignmentsView(
                data?.assignments?.map((el) => ({
                    ...el,
                    label: el?.name,
                }))
            );
            setAssignments(data?.assignments?.map((el) => el.id));
            setSelectedTeachers(
                data?.instructors?.map((el) => ({
                    ...el,
                    label: el?.display_name,
                    value: el?.membership.id,
                }))
            );
            setSelectedStudents(
                data?.students?.map((el) => ({
                    ...el,
                    label: el?.display_name,
                    value: el?.membership.id,
                }))
            );
        }
        setInitValue({
            ...getValues(),
            assignments: data?.assignments?.map((el) => el?.id) || undefined,
            teacherSession: data?.instructors?.map((el) => el?.membership?.id) || [],
            studentSession: data?.students?.map((el) => el?.membership?.id) || [],
        });
    }, []);

    useEffect(() => {
        loadLessons();
    }, [keyword]);

    const handleChangeStartTime = (e: Date) => {
        setValue('startTime', dayjs(e).format(SessionDateFormat));
        setUpdateTime(new Date().getTime());
        clearErrors('startTime');
        checkSaveBtn();
    };

    const handleChangeEndTime = (e: Date) => {
        setValue(
            'endTime',
            dayjs(dayjs(e).isAfter(dayjs(getValues().startTime)) ? e : dayjs(getValues().startTime).add(30, 'minute').toDate()).format(
                SessionDateFormat
            )
        );
        setUpdateTime(new Date().getTime());
        clearErrors('endTime');
        checkSaveBtn();
    };

    const handleSubmitData = async ({ id, startTime, endTime, lesson, type, vc_link }) => {
        /* 
            NOTE:
            assignments use to delete assignment in session
            assignment_templates use to create/update assignment in session
        */
        if (id) {
            await updateSession(param.id, param.classId, id.toString(), {
                start_time: dayjs(startTime).format(SessionServiceFormat),
                end_time: dayjs(endTime).format(SessionServiceFormat),
                lesson_id: lesson,
                type,
                assignments,
                assignment_templates:
                    assignment_templates?.map((el) => ({
                        name: el.label,
                        h5p_contents: el.value,
                    })) || undefined,
                instructors: selectedTeachers?.map((i) => i?.value) || [],
                students: selectedStudents?.map((i) => i?.value) || [],
                vc_link
            });
        } else {
            await classService
                .createSession(param.id, param.classId, {
                    start_time: dayjs(startTime).format(SessionServiceFormat),
                    end_time: dayjs(endTime).format(SessionServiceFormat),
                    lesson_id: lesson,
                    type,
                    assignments: [],
                    assignment_templates: assignment_templates?.map((el) => ({
                        name: el.label,
                        h5p_contents: el.value,
                    })),
                    instructors: selectedTeachers?.map((i) => i?.value),
                    students: selectedStudents?.map((i) => i?.value),
                    vc_link,
                })
                .then((res) => Toastify.success())
                .catch(() => Toastify.error());
        }
        handleFetchData();
        onClose();
    };

    const loadLessons = useCallback(async () => {
        if (keyword) {
            const data = await workspaceService.getLessonGroupByCourse(param.id, keyword);
            setLessons(
                data?.items?.map((item: any) => ({
                    label: item.title,
                    options: item.lessons.map((lesson: any) => ({
                        label: lesson.title,
                        value: lesson.id,
                    })),
                })) || []
            );
        } else {
            setLessons([]);
        }
    }, [keyword]);

    const update = useCallback((inputValue: string) => setKeyword(inputValue), []);

    const debounceInput = useCallback(
        debounce((nextValue: string, asyncFunction: (inputValue: string) => void) => {
            asyncFunction(nextValue);
        }, 1000),
        []
    );

    const setDefaultValueSessionType = () => {
        if (!data?.type) return null;
        return data.type === 'online' ? classTypes[0] : classTypes[1];
    };

    const checkSaveBtn = () => {
        setIsChange(
            !isEqual(initValue, {
                ...getValues(),
                assignments: assignmentsView?.map((el) => el.id) || undefined,
                teacherSession: selectedTeachers?.map((el) => el?.value) || [],
                studentSession: selectedStudents?.map((el) => el?.value) || [],
            })
        );
    };

    const handleLoadOptions = async (inputValue, options, additional) => {
        let params: any = {
            page: additional.page,
            per_page: 20,
        };

        if (inputValue) {
            if (!isNaN(inputValue)) params.lesson_id = inputValue;
            else params.name = inputValue;
        } else if (watch('lesson')) params.lesson_id = watch('lesson');

        const { items, total } = await workspaceService.getAssignmentTemplatesService(param.id, params);

        const newOptions = [];

        items.forEach((el: any) => {
            let lessonName = el.title;
            el.sections.forEach((elm: any) => {
                newOptions.push({
                    id: UUID(),
                    lessonName,
                    label: elm.name,
                    value: elm.h5p_contents,
                });
            });
        });

        return {
            options: newOptions,
            hasMore: additional.page < total / 20,
            additional: {
                page: additional.page + 1,
            },
        };
    };

    const onSubmitForm = (values: any) => {
        handleSubmitData({
            startTime: values.startTime,
            endTime: values.endTime,
            lesson: values.lesson,
            id: data ? data.id : undefined,
            type: values.type,
            vc_link: values?.vc_link,
        });
    };

    const handleLoadOptionsTeachers = async (inputValue, options, additional) => {
        let params: any = {
            page: additional.page,
            per_page: 20,
            type: 'instructor',
            q: inputValue || undefined,
        };

        const { items, total } = await workspaceService.getWorkspaceMembers({ id: param.id }, params);
        let newOptions = [];
        
        if (items?.length > 0) {
            newOptions = items?.map((el) => ({
                ...el,
                label: el.display_name,
                value: el.membership.id,
            }));
            if(data?.instructors) {
                newOptions = newOptions.filter(item => data?.instructors?.filter(instructor => instructor?.id == item?.id)?.length == 0);
            }
        }
        if (additional.page == 1) {
            const defaultvalue = data?.instructors?.map((el) => ({
                ...el,
                label: el.display_name,
                value: el.membership.id,
            })) || [];
            newOptions = defaultvalue.concat(newOptions);
        }

        return {
            options: newOptions,
            hasMore: additional.page < total / 20,
            additional: {
                page: additional.page + 1,
            },
        };
    };

    const handleLoadOptionsStudents = async (inputValue, options, additional) => {
        let params: any = {
            page: additional.page,
            per_page: 20,
            type: 'student',
            q: inputValue || undefined,
        };

        const { items, total } = await workspaceService.getWorkspaceMembers({ id: param.id }, params);
        let newOptions = [];

        if (items?.length > 0) {
            newOptions = items?.map((el) => ({
                ...el,
                label: el.display_name,
                value: el.membership.id,
            }));
            if(data?.students) {
               newOptions = newOptions.filter(item => data?.students?.filter(student => student?.id == item?.id)?.length == 0);
            }
        }
        if (additional.page == 1) {
            const defaultvalue = data?.students?.map((el) => ({
                ...el,
                label: el.display_name,
                value: el.membership.id,
            })) || [];
            newOptions = defaultvalue.concat(newOptions);
        }

        return {
            options: newOptions,
            hasMore: additional.page < total / 20,
            additional: {
                page: additional.page + 1,
            },
        };
    };

    const handleClick = () => {};

    useEffect(() => {
        checkSaveBtn();
    }, [selectedTeachers, selectedStudents, getValues()]);

    const handleCheckMemberType = (newValue: any, actionMeta: any) => {
        if (actionMeta.action === 'select-option') {
            if (actionMeta?.option?.membership?.type === 'instructor') {
                setSelectedTeachers(newValue);
            } else {
                Toastify.error(`Member isn't a instructor`);
            }
        } else if (actionMeta.action === 'remove-value' || actionMeta.action === 'clear') {
            setSelectedTeachers(newValue);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmitForm)} className="ModalSession h-[89%] flex flex-col justify-between">
            <div className="px-4">
                <div className="grid grid-cols-2 gap-x-5">
                    <div className="">
                        <div className="text-ooolab_1xs font-semibold">{translator("CLASSES.START_DATETIME")}</div>
                        <div className="border border-ooolab_gray_6 rounded-admin_type mt-1 relative custom-date-picker-session">
                            <Controller
                                rules={{
                                    required: {
                                        value: true,
                                        message: translator('FORM_CONST.REQUIRED_FIELD'),
                                    },
                                    validate: (value) => {
                                        const now = dayjs().tz(timeZone).format('YYYY-MM-DDTHH:mm:ssZ');
                                        const timezoneHours = now.slice(-6);
                                        const date = dayjs(value).format('YYYY-MM-DDTHH:mm:ss') + timezoneHours;
                                        return now < date || 'Start Datetime invalid';
                                    },
                                }}
                                control={control}
                                name="startTime"
                                render={() => (
                                    <DatePicker
                                        isOpeningCalendar={isOpeningCalendar}
                                        onChangeDate={handleChangeStartTime}
                                        value={getValues().startTime}
                                        name="startTime"
                                        disabled={featureFlags?.SIS}
                                    />
                                )}
                            />
                        </div>
                        <ErrorMessageForm errors={errors} name="startTime" />
                    </div>
                    <div className="relative">
                        <div className="text-ooolab_1xs font-semibold">{translator("CLASSES.END_DATETIME")}</div>
                        <div className="border border-ooolab_gray_6 rounded-admin_type mt-1 relative custom-date-picker-session">
                            <Controller
                                rules={{
                                    required: {
                                        value: true,
                                        message: translator('FORM_CONST.REQUIRED_FIELD'),
                                    },
                                }}
                                control={control}
                                name="endTime"
                                render={() => (
                                    <DatePicker
                                        valueStartTime={getValues().startTime}
                                        isOpeningCalendar={isOpeningCalendar}
                                        onChangeDate={handleChangeEndTime}
                                        value={getValues().endTime}
                                        name="endTime"
                                        disabled={featureFlags?.SIS}
                                    />
                                )}
                            />
                        </div>
                        <ErrorMessageForm errors={errors} name="endTime" />
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-x-5 mt-4">
                    <div>
                        <div className="text-ooolab_1xs font-semibold">Lesson</div>
                        <div className="relative mt-1">
                            <CreatableSelect
                                className="text-ooolab_1xs"
                                styles={customSelectLesson}
                                onChange={(newValue: any, actionMeta: any) => {
                                    if (newValue?.value) {
                                        setValue('lesson', +newValue.value);
                                    } else {
                                        isOpeningCalendar.current = true;
                                        setValue('lesson', undefined);
                                        setUpdateTime(new Date().getTime());
                                    }
                                    checkSaveBtn();
                                }}
                                onMenuClose={() => {
                                    isOpeningCalendar.current = false;
                                }}
                                onInputChange={(inputValue: string, actionMeta: any) => {
                                    if (actionMeta.action === 'input-change' && inputValue !== keyword) {
                                        debounceInput(inputValue, update);
                                    }
                                }}
                                noOptionsMessage={(e) => {
                                    if (e.inputValue) {
                                        return 'No options';
                                    }
                                    return 'Type something to search lesson';
                                }}
                                isLoading={false}
                                isSearchable
                                isClearable
                                isValidNewOption={(inputValue: string, value: any, options: any, accessors: any) => false}
                                menuPosition="absolute"
                                placeholder="Select"
                                value={
                                    lessons?.length === 0 && getValues()?.lesson
                                        ? {
                                              value: data?.lesson?.id,
                                              label: data?.lesson?.title,
                                          }
                                        : undefined
                                }
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                                options={lessons}
                                isDisabled={featureFlags?.SIS}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="text-ooolab_1xs font-semibold mb-1">Session Type</div>
                        <Controller
                            control={control}
                            name="type"
                            rules={{
                                required: {
                                    value: true,
                                    message: translator('FORM_CONST.REQUIRED_FIELD'),
                                },
                            }}
                            render={() => {
                                return (
                                    <Select
                                        styles={customSelect}
                                        className="w-full"
                                        options={classTypes}
                                        onChange={(value: any) => {
                                            setValue('type', value.value);
                                            clearErrors('type');
                                            checkSaveBtn();
                                        }}
                                        isSearchable={false}
                                        placeholder={translator('CLASSES.CLASS_TYPE')}
                                        defaultValue={setDefaultValueSessionType()}
                                        menuPlacement="bottom"
                                        isDisabled={featureFlags?.SIS}
                                    />
                                );
                            }}
                        />
                        <ErrorMessageForm errors={errors} name="type" />
                    </div>
                </div>
                <div className="mt-4">
                    <div className="text-ooolab_1xs font-semibold">{translator('CLASSES.INSTRUCTORS')}</div>
                    <div className="flex justify-between items-center space-x-2">
                        <div className="w-full mt-1">
                            {featureFlags?.SIS && (selectedTeachers?.length <= 0 || !selectedTeachers) && (
                                <div className="text-xs pl-2 text-dark-100">{translator('CLASSES.NO_INSTRUCTORS')}</div>
                            )}
                            {featureFlags?.SIS ? (
                                selectedTeachers?.map((instructor: any) => {
                                    return (
                                        <Link
                                            to={`/workspace/${param?.id}/management/users/${instructor?.id}`}
                                            className="w-full flex space-x-2 items-center mt-2 hover:text-primary-500 hover:underline"
                                            key={instructor?.id}
                                        >
                                            <span
                                                className="w-7 h-7 bg-cover rounded-full flex-none"
                                                style={{ backgroundImage: `url(" ${instructor?.avatar_url}")` }}
                                            ></span>
                                            <span className="text-xs">{instructor?.display_name}</span>
                                        </Link>
                                    );
                                })
                            ) : (
                                <Controller
                                    control={control}
                                    name="teacherSession"
                                    render={() => {
                                        return (
                                            <AsyncPaginate
                                                loadOptions={handleLoadOptionsTeachers}
                                                additional={{
                                                    page: 1,
                                                }}
                                                debounceTimeout={500}
                                                styles={customSelect}
                                                onChange={(newValue: any, actionMeta: any) => {
                                                    handleCheckMemberType(newValue, actionMeta);
                                                }}
                                                isMulti
                                                isClearable
                                                placeholder=""
                                                value={selectedTeachers}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                menuPlacement="auto"
                                                components={{
                                                    Option: ({ isDisabled, isFocused, isSelected, children, ...props }) => {
                                                        // FIX LAG
                                                        const [isActive, setIsActive] = useState(false);

                                                        const onMouseDown = () => setIsActive(true);
                                                        const onMouseUp = () => setIsActive(false);
                                                        const onMouseLeave = () => setIsActive(false);
                                                        let bg = 'transparent';
                                                        if (isFocused) bg = '#eee';
                                                        if (isActive) bg = '#B2D4FF';
                                                        const style = {
                                                            alignItems: 'center',
                                                            backgroundColor: bg,
                                                            color: 'inherit',
                                                            display: 'flex ',
                                                        };

                                                        const propsNew = {
                                                            ...props.innerProps,
                                                            onMouseDown,
                                                            onMouseUp,
                                                            onMouseLeave,
                                                            style,
                                                        };
                                                        const newProps = Object.assign(props, {
                                                            innerProps: propsNew,
                                                            isSelected,
                                                            isDisabled,
                                                            isFocused,
                                                        });

                                                        const { data } = props;

                                                        return (
                                                            <components.Option {...newProps}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={isSelected}
                                                                    defaultChecked={null}
                                                                    className="mr-2"
                                                                    onChange={() => handleClick()}
                                                                />
                                                                <img
                                                                    className={`rounded-full w-5 h-5 mr-2`}
                                                                    src={data?.avatar_url || avatarImg}
                                                                    alt="img"
                                                                />
                                                                {`(ID:${data?.membership?.id})`}
                                                                {` `}
                                                                {children}
                                                            </components.Option>
                                                        );
                                                    },
                                                    ValueContainer,
                                                    MultiValue,
                                                }}
                                                isDisabled={featureFlags?.SIS}
                                            />
                                        );
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <div className="text-ooolab_1xs font-semibold">{translator('CLASSES.STUDENTS')}</div>
                    <div className="flex justify-between items-center space-x-2">
                        <div className="w-full mt-1">
                            {featureFlags?.SIS && (selectedStudents?.length <= 0 || !selectedStudents) && (
                                <div className="text-xs text-dark-100">{translator('CLASSES.NO_STUDENTS')}</div>
                            )}
                            {featureFlags?.SIS ? (
                                selectedStudents?.map((student: any) => {
                                    return (
                                        <Link
                                            to={`/workspace/${param?.id}/management/users/${student?.id}`}
                                            className="w-full flex space-x-2 items-center hover:text-primary-500 hover:underline mt-2"
                                            key={student?.id}
                                        >
                                            <span
                                                className="w-7 h-7 bg-cover rounded-full flex-none"
                                                style={{ backgroundImage: `url(" ${student?.avatar_url}")` }}
                                            ></span>
                                            <span className="text-xs">
                                                {student?.display_name}
                                                {student?.nick_name && ` / ${student?.nick_name}`}
                                            </span>
                                        </Link>
                                    );
                                })
                            ) : (
                                <Controller
                                    control={control}
                                    name="studentSession"
                                    render={() => {
                                        return (
                                            <AsyncPaginate
                                                loadOptions={handleLoadOptionsStudents}
                                                additional={{
                                                    page: 1,
                                                }}
                                                debounceTimeout={500}
                                                styles={customSelect}
                                                onChange={(newValue: any, actionMeta: any) => {
                                                    setSelectedStudents(newValue);
                                                }}
                                                isMulti
                                                isClearable
                                                placeholder=""
                                                value={selectedStudents}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                menuPlacement="auto"
                                                components={{
                                                    Option: ({ isDisabled, isFocused, isSelected, children, ...props }) => {
                                                        // FIX LAG
                                                        const [isActive, setIsActive] = useState(false);

                                                        const onMouseDown = () => setIsActive(true);
                                                        const onMouseUp = () => setIsActive(false);
                                                        const onMouseLeave = () => setIsActive(false);
                                                        let bg = 'transparent';
                                                        if (isFocused) bg = '#eee';
                                                        if (isActive) bg = '#B2D4FF';
                                                        const style = {
                                                            alignItems: 'center',
                                                            backgroundColor: bg,
                                                            color: 'inherit',
                                                            display: 'flex ',
                                                        };

                                                        const propsNew = {
                                                            ...props.innerProps,
                                                            onMouseDown,
                                                            onMouseUp,
                                                            onMouseLeave,
                                                            style,
                                                        };
                                                        const newProps = Object.assign(props, {
                                                            innerProps: propsNew,
                                                            isSelected,
                                                            isDisabled,
                                                            isFocused,
                                                        });

                                                        const { data } = props;

                                                        return (
                                                            <components.Option {...newProps}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={isSelected}
                                                                    defaultChecked={null}
                                                                    className="mr-2"
                                                                    onChange={() => handleClick()}
                                                                />
                                                                <img
                                                                    className={`rounded-full w-5 h-5 mr-2`}
                                                                    src={data?.avatar_url || avatarImg}
                                                                    alt="img"
                                                                />
                                                                {`(ID:${data?.membership?.id})`}
                                                                {children}
                                                                {data?.nick_name && ` / ${data?.nick_name}`}
                                                            </components.Option>
                                                        );
                                                    },
                                                    ValueContainer,
                                                    MultiValue,
                                                }}
                                                isDisabled={featureFlags?.SIS}
                                            />
                                        );
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <div className="text-ooolab_1xs font-semibold">VC Link</div>
                    <input
                        id="vc_link"
                        name="vc_link"
                        type={'text'}
                        {...register('vc_link')}
                        placeholder="Enter VC Link"
                        className="w-full outline-none border border-ooolab_blue_4 focus:border-[#2684FF] rounded p-2.5 hover:border-[#B3B3B3] text-xs text-ooolab_dark_1"
                        onChange={(e) => {
                            setValue('vc_link', e?.target?.value);
                            checkSaveBtn();
                        }}
                    />
                </div>
                <div className="mt-4">
                    <div className="text-ooolab_1xs font-semibold">Add Assignment</div>
                    <div className="flex justify-between items-center space-x-2">
                        <div className="w-full">
                            <Controller
                                control={control}
                                name="assignments"
                                // rules={{
                                //     required: {
                                //         value: true,
                                //         message: translator('FORM_CONST.REQUIRED_FIELD'),
                                //     },
                                // }}
                                render={() => {
                                    return (
                                        <AsyncPaginate
                                            key={watch('lesson')}
                                            selectRef={selectAssignmentRef}
                                            loadOptions={handleLoadOptions}
                                            additional={{
                                                page: 1,
                                            }}
                                            debounceTimeout={500}
                                            styles={customSelectAPR}
                                            isMulti
                                            className="w-full"
                                            closeMenuOnSelect={false}
                                            onChange={(newValue: any, actionMeta: any) => {
                                                setAssignmentsValue(newValue);
                                                newValue.length ? setIsAdd(true) : setIsAdd(false);
                                            }}
                                            placeholder="Select Assignment"
                                            value={assignmentsValue}
                                            menuPlacement="bottom"
                                            components={{
                                                Option: ({ children, ...props }) => {
                                                    // FIX LAG
                                                    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
                                                    const newProps = Object.assign(props, { innerProps: rest });
                                                    return (
                                                        <components.Option {...newProps}>
                                                            {children + ' - ' + newProps.data.lessonName}
                                                        </components.Option>
                                                    );
                                                },
                                            }}
                                        />
                                    );
                                }}
                            />
                            {/* <ErrorMessageForm errors={errors} name="assignments" /> */}
                        </div>
                        <ButtonComponent
                            title="Add"
                            disable={!isAdd}
                            classStyle="primary-button-apollo-custom"
                            onClickButton={() => {
                                setAssignmentsView([...assignmentsView, ...assignmentsValue]);
                                setAssignment_templates([...assignment_templates, ...assignmentsValue]);
                                setAssignmentsValue([]);
                                setIsAdd(false);
                                setIsChange(
                                    !isEqual(initValue, {
                                        ...getValues(),
                                        assignments: [...assignmentsView, ...assignmentsValue]?.map((el) => el.id) || undefined,
                                    })
                                );
                            }}
                        />
                    </div>
                </div>
                <div className="mt-4">
                    <div className="text-ooolab_1xs font-semibold">{translator("CLASSES.ALL_ASSIGNMENTS")}</div>
                    <div className="overflow-y-auto h-24 border rounded flex flex-wrap py-1 px-2">
                        {assignmentsView?.map((el) => (
                            <div
                                key={el.id}
                                className="h-6 inline-flex items-center space-x-1 rounded bg-ooolab_gray_11 px-1 mr-2 mb-2"
                            >
                                <span className="text-[10px] font-semibold whitespace-nowrap">{el.label}</span>
                                <XIcon
                                    className="w-3 h-3 cursor-pointer hover:text-red-500"
                                    onClick={() => {
                                        setAssignmentsView(assignmentsView.filter((elm) => elm.id !== el.id));
                                        setAssignment_templates(assignment_templates.filter((elm) => elm.id !== el.id));
                                        setAssignments(assignments.filter((elm) => elm !== el.id));
                                        setIsChange(
                                            !isEqual(initValue, {
                                                ...getValues(),
                                                assignments:
                                                    assignmentsView.filter((elm) => elm.id !== el.id)?.map((el) => el.id) || undefined,
                                            })
                                        );
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex justify-center items-center space-x-4 pt-4">
                <ButtonComponent title="Cancel" classStyle="secondary-button" onClickButton={() => onClose()} />
                <ButtonComponent
                    type="submit"
                    title={translator("CLASSES.SAVE")}
                    classStyle="primary-button !bg-primary-500 disabled:text-white disabled:!bg-gray-400 "
                    disable={!isChange}
                />
            </div>
        </form>
    );
};

export default ModalSession;
