import { ButtonOutline, ButtonPrimary, Toastify } from '_shared'
import SelectBox from '_shared/components/Select';
import DialogComponent from 'components/Dialog/DialogComponent'
import { useDebouncedState } from 'hooks/useDebounce';
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import courseService from 'services/course.service'

export default function ModalChangeStatusMember({isOpen, onClose, enrollmentSelected, refreshData}) {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
} = useForm({ mode: 'onChange'});
  const params: {courseId, id} = useParams();
  const {t: translator} = useTranslation();
  const search = useLocation()?.search;
  const enrollmentId = new URLSearchParams(search).get('enrollmentId');
  const [inputSearchMember, setInputSearchMember] = useDebouncedState('');
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState<any>({
    page: 1,
    per_page: 10,
  })
  const [listMember, setListMember] = useState([])

  const getMemberCanAddToEnrollment = (page) => {
    setLoading(true)
    let payload = {
      page: page ? page : pagination.page,
      per_page: pagination.per_page,
      q: inputSearchMember,
    };
    if (!payload?.q) delete payload?.q
    courseService.getMemberCanAddToEnrollment(params?.id, params?.courseId, enrollmentId, payload)
    .then((res) => {
      const members = res?.data?.items?.map((item) => {
        return {
          ...item,
          name: item?.display_name,
          value: item?.id
        }
      })
      if (page === 1) setListMember(members);
      else setListMember([...listMember, ...members]);
      setPagination({ ...pagination, total: res?.data?.total, page });
    })
    .catch(() => Toastify.error())
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    getMemberCanAddToEnrollment(1)
  }, [inputSearchMember])

  const onSubmit = (values) => {
    const data = {
      learner_ids: values?.members?.map((item) => item?.id)
    }
    courseService.addMemberToEnrollment(params?.id, params?.courseId, enrollmentId, data)
    .then(res => {
      Toastify.success({
        title: translator("COURSES.ADD_MEMBER_SUCCESS"),
        message: translator("COURSES.ADD_MEMBER_SUCCESS_CONTENT", {amount: values?.members?.length, learningGroupName: enrollmentSelected?.learning_group?.name})
      }, {autoClose: 10000});
      refreshData();
      onClose();
    })
    .catch(() => Toastify.error());
  }

  return (
    <DialogComponent
      title={translator('COURSES.ADD_MORE_MEMBER')}
      onCloseModal={onClose}
      isOpen={isOpen}
      styles='max-w-lg'
      child={
        <form className='mt-4' onSubmit={handleSubmit(onSubmit)}>
            <div className='h-[275px]'>
              <SelectBox
                  placeholder={translator('COURSES.SEARCH_A_LEARNER')}
                  data={listMember}
                  onSearch={setInputSearchMember}
                  value={watch('members')}
                  loading={loading}
                  getData={getMemberCanAddToEnrollment}
                  pagination={pagination}
                  control={control}
                  name="members"
                  errors={errors}
                  isRequired={true}
                  numberOfItemDisplayed={3}
                  isMulti={true}
                  isOpen={true}
                  heightPanel='180px'
              />
            </div>
            <div className='text-xs text-right py-2 mr-4 text-gray-500'>{watch('members')?.length || 0} learner(s) selected</div>
            <div className="flex justify-center gap-4 mt-4">
                <ButtonOutline type="Button" onClick={onClose}>
                    {translator('CANCEL')}
                </ButtonOutline>
                <ButtonPrimary type="submit" onClick={handleSubmit(onSubmit)} disabled={!isValid}>
                    {translator('ADD')}
                </ButtonPrimary>
            </div>
        </form>
      }
    />
  )
}
