import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom';
import './RecurringInvoiceList.scss'
import Tab from './Tab';
import { useMemo, useState } from 'react';
import AllInvoices from './AllInvoices/AllInvoices';
import RecurringInvoices from './RecurringInvoices/RecurringInvoiceList';
import Filter from './Filter/Filter';
import { Popover } from '@headlessui/react';
import { ButtonPrimary } from '_shared';
import { PlusIcon } from '@heroicons/react/outline';
import SingleInvoiceIcon from 'assets/icon/SingleInvoiceIcon';
import RecurringInvoiceIcon from 'assets/icon/RecurringInvoiceIcon';
import DialogComponent from 'components/Dialog/DialogComponent';
import CreateSingleInvoice from './CreateInvoice/CreateSingleInvoice';
import CreateRecurringInvoice from './CreateInvoice/CreateRecurringInvoice';
import { checkPermission } from 'utils/commonFun';

export default function InvoiceList({ children }) {
  const { t: translator } = useTranslation();
  const [isOpenModalCreateSignleInvoice, setOpenModalCreateSingleInvoice] = useState<boolean>(false);
  const [isOpenModalCreateRecurringInvoice, setOpenModalCreateRecurringInvoice] = useState<boolean>(false);
  const [filter, setFilter] = useState({});
  const params: { id } = useParams();
  const workspaceId = params?.id;
  const history = useHistory();
  const search = useLocation()?.search;
  const tab = new URLSearchParams(search).get('tab');
  const [isRefreshList, setRefreshList] = useState(false)

  const TAB = useMemo(
    () => ({
      ALL_INVOICES: {
        label: translator('ALL_INVOICES'),
        value: 'all-invoices',
      },
      RECURRING_INVOICES: {
        label: translator('RECURRING_INVOICES'),
        value: 'recurring-invoices',
      },
    }),
    [translator]
  );

  const handleFilter = (filter) => {
    setFilter(filter);
  }

  return (
    <div className='InvoiceList'>
      <PageBreadcrumb
        pageNameDefault={translator('DASHBOARD.SIDEBAR.ECOMMERCE')}
        containerClassName="InvoiceList-Breadcrum"
        pages={[{ name: translator('DASHBOARD.SIDEBAR.BILLING'), href: '', className: '' }]}
      />
      <Tab TAB={TAB} />
      <div className='flex justify-between mt-5 px-5'>
        <Filter onChangeFilter={handleFilter} tab={tab} />
        {checkPermission('invoice', 'C') && <Popover className={'relative'}>
          <Popover.Button>
            <ButtonPrimary className='w-40 !py-2' onClick={() => { }} type="button"><PlusIcon className='w-5 h-5' /> {translator("NEW_INVOICE")}</ButtonPrimary>
          </Popover.Button>
          <Popover.Panel className="absolute z-10 bg-white shadow-sm rounded py-4 w-48 right-0">
            <div onClick={() => setOpenModalCreateSingleInvoice(true)} className='text-sm py-2 px-4 flex gap-1 items-center cursor-pointer hover:bg-gray-50'><SingleInvoiceIcon /> {translator("SIGNLE_INVOICE")}</div>
            <div onClick={() => setOpenModalCreateRecurringInvoice(true)} className='text-sm py-2 px-4 flex gap-1 items-center cursor-pointer hover:bg-gray-50'><RecurringInvoiceIcon /> {translator("RECURRING_INVOICE")}</div>
          </Popover.Panel>
        </Popover>}

      </div>
      <div className=''>
        {
          tab === TAB.ALL_INVOICES.value && <AllInvoices filter={filter} isRefresh={isRefreshList} setRefresh={setRefreshList} />
        }
        {
          tab === TAB.RECURRING_INVOICES.value && <RecurringInvoices filter={filter} isRefresh={isRefreshList} setRefresh={setRefreshList} />
        }
      </div>
      <DialogComponent
        title={translator("NEW_SINGLE_INVOICE")}
        isOpen={isOpenModalCreateSignleInvoice}
        onCloseModal={() => setOpenModalCreateSingleInvoice(false)}
        styles='!max-w-xl'
        child={
          <CreateSingleInvoice
            onClose={() => setOpenModalCreateSingleInvoice(false)}
            onRefreshData={() => {
              setRefreshList(true);
              history?.push(`/workspace/${workspaceId}/e-commerce/billing?tab=${TAB.ALL_INVOICES.value}`);
            }}
          />
        }
      />
      <DialogComponent
        title={translator("NEW_RECURRING_INVOICE")}
        isOpen={isOpenModalCreateRecurringInvoice}
        onCloseModal={() => setOpenModalCreateRecurringInvoice(false)}
        styles='!max-w-xl'
        child={
          <CreateRecurringInvoice
            onClose={() => setOpenModalCreateRecurringInvoice(false)}
            onRefreshData={() => {
              setRefreshList(true);
              history?.push(`/workspace/${workspaceId}/e-commerce/billing?tab=${TAB.RECURRING_INVOICES.value}`);
            }}
          />
        }
      />
    </div>
  )
}
