import React from 'react';

import TooltipInfo from 'components/UiComponents/TooltipInfo';

interface AvatarListProps {
    list: any[];
}

const AvatarList: React.FC<AvatarListProps> = ({ list }) => {
    const numOfList = list?.length;

    return (
        <div className="flex gap-0 relative py-2 w-full items-center h-full">
            {list.slice(0, 3).map((item, index) => {
                return (
                    <div key={index} style={{ left: index * 20 }} className={` absolute top-2`}>
                        <img className={`rounded-full w-7 h-7 border-2 border-white`} src={item?.avatar_url} alt="avatar" />
                    </div>
                );
            })}
            <div className="absolute top-2" style={{ left: 3 * 20 }}>
                <TooltipInfo
                    info={
                        <div
                            className=" bg-white p-1"
                            style={{ width: list.slice(3)?.length <= 5 ? 36 * list.slice(3)?.length : 160 }}
                        >
                            <div className=" flex gap-0 max-w-md flex-wrap justify-center">
                                {list.slice(3)?.map((item, index) => {
                                    return (
                                        <div className="w-7 h-7" key={index}>
                                            <img
                                                className={`rounded-full w-7 h-7 border-2 border-white`}
                                                src={item?.avatar_url}
                                                alt="avatar"
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    }
                >
                    {(numOfList > 3 && (
                        <div className=" bg-ooolab_dark_50 rounded-full w-7 h-7  border-2 border-white text-[10px] font-semibold  text-gray-800 cursor-pointer flex items-center justify-center">
                            + {numOfList - 3}
                        </div>
                    )) ||
                        ''}
                </TooltipInfo>
            </div>
        </div>
    );
};

export default AvatarList;
