
export const ChevronDownIcon = ({
    width = '10',
    height = '6',
    className = ''
}: {
    fill?: string;
    height?: string;
    width?: string;
    className?: string;
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M5 5.33337L0 0.333374H10L5 5.33337Z" fill="currentColor" />
        </svg>
    );
};
