import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { timeList } from "constant/workspace.const";
import reportService from "services/reports/workspace-report.service";

import { CardContainer } from "components/Reports/components/CardContainer";
import { CardInfo, CardInfoProps } from "components/Reports/components/CardInfo";
import { Title } from "components/Reports/components/Title";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import DatePickerComponent from "components/Reports/components/DatePicker";
import moment from "moment";

export const OverviewSessions = () => {
    const { t } = useTranslation();
    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);
    const params: { id: string } = useParams();
    const [selectedDate, setSelectDate] = useState(timeList[1]);
    const [loading, setLoading] = useState(true);
    const [learnningData, setLearnningData] = useState<any>();

    const getData = async () => {
        if (!startDate || !endDate) {
            return;
        }
        try {
            setLoading(true);
            const payload = {
                start_time: moment(new Date(startDate)).format('YYYY-MM-DD')+ 'T00:00:00.000',
                end_time: moment(new Date(endDate)).format('YYYY-MM-DD')+ 'T23:59:59.000',
            }
            const response = await reportService.getSessionsOverview(params.id, payload);
            setLearnningData(response?.data);

            setLoading(false);
        } catch {
            setLoading(false);
        }
    }

    const [startDate, setStartDate] = useState(new Date(moment().subtract(1, 'months').format('MM-DD-YYYY')));
    const [endDate, setEndDate] = useState(new Date());
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    useEffect(() => {
        getData();
    }, [selectedDate?.id, startDate, endDate]);

    return <>
        <CardContainer>
            <div className="flex items-center">
                <Title>Overview</Title>
                <div className="ml-auto min-w-[132px] relative">
                    <DatePickerComponent onChange={onChange} startDate={startDate} endDate={endDate} />
                </div>
            </div>
            <div className="mt-4 min-h-[260px]">
                {loading ?
                    <div className="flex justify-center items-center w-full min-h-[123px]">
                        <CircleSpin color="text-primary-500" />
                    </div> :
                    <>
                        <div className="flex items-center space-x-5">
                            <div className="w-1/3">
                                <CardInfo {...{
                                    value: learnningData?.total_sessions || 0,
                                    day: selectedDate?.id,
                                    title: translator("Total Sessions"),
                                } as CardInfoProps}  />
                            </div>
                            <div className="w-1/3">
                                <CardInfo {...{
                                    value: learnningData?.total_booked || 0,
                                    day: selectedDate?.id,
                                    title: translator("Total Booked"),
                                } as CardInfoProps}  />
                            </div>
                            <div className="w-1/3">
                                <CardInfo {...{
                                    value: learnningData?.total_completed || 0,
                                    day: selectedDate?.id,
                                    title: translator("Total Completed"),
                                } as CardInfoProps }  />
                            </div>
                        </div>
                        <div className="flex items-center space-x-5 mt-5">
                            <div className="w-1/3">
                                <CardInfo {...{
                                    value: learnningData?.total_rescheduled || 0,
                                    day: selectedDate?.id,
                                    title: translator("Total Rescheduled"),
                                } as CardInfoProps}  />
                            </div>
                            <div className="w-1/3">
                                <CardInfo {...{
                                    value: learnningData?.total_cancellation || 0,
                                    day: selectedDate?.id,
                                    title: translator("Total Cancellation"),
                                } as CardInfoProps}  />
                            </div>
                            <div className="w-1/3">
                            </div>
                        </div>
                    </>
                }
            </div>
        </CardContainer>
    </>
}