import { FC } from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { classNames } from 'utils/commonFun';

type SearchboxProps = {
    className?: string;
    onSubmit?: (value: string) => void;
    placeholder?: string;
    value?: string;
};

const index: FC<SearchboxProps> = ({ className, onSubmit, placeholder, value }) => {
    const { t: translator } = useTranslation();
    return (
        <div
            className={classNames(
                'w-full lg:w-2/5 border border-gray-300 rounded p-2 flex items-center space-x-2 text-xs focus-within:border-enterprise',
                className
            )}
        >
            <SearchIcon className="h-5 w-5 text-dark-100" />
            <input
                type="search"
                placeholder={placeholder || translator('SEARCH')}
                className="w-full leading-5 bg-white placeholder-gray-500"
                value={value}
                onChange={(e: any) => onSubmit(e.currentTarget.value)}
                onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                        onSubmit(e.target.value);
                    }
                }}
            />
        </div>
    );
};

export default index;
