import React, { useState, useEffect, useCallback } from 'react';
import { PlusIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import TableLearningPath from './Components/TableLearningPath';
import WorkspaceService from 'services/workspace.service';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import Spinner from 'components/Spinner';
import Pagination from 'components/V2/Pagination';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import Filter from './Components/Filter/Filter';
import { ButtonPrimary, Toastify } from '_shared';
import learningPathService from 'services/learningPath.service';
import { ModalDeleteLearningPath, ModalRenameLearningPath, ModalRevertToDraft, ModalViewDetailLearningPath, } from './Components/Modals'
import './LearningPathList.scss'
import LearningPathDetail from './Components/LearningPathDetail/LearningPathDetail';
import { debounce } from 'lodash';

const LearningPathList: React.FC = ({ children }) => {
    const params: any = useParams();
    const { t } = useTranslation();
    const history = useHistory();

    const [isOpenmodalDeleteLearningPath, setOpenmodalDeleteLearningPath] = useState(false);
    const [isOpenModalRenameLearningPath, setOpenModalRenameLearningPath] = useState(false);
    const [isOpenmodalViewDetailLearningPath, setOpenmodalViewDetailLearningPath] = useState(false)
    const [isOpenmodalRevertToDraft, setOpenmodalRevertToDraft] = useState(false)
    const [filter, setFilter] = useState(undefined)
    const [isFirstLoad, setFirstLoad] = useState(true);
    const [selected, setSelected] = useState<any>({})
    const [loading, setLoading] = useState(true);
    const [indexPage, setIndexPage] = useState('0-0');
    const [learningPathList, setLearningPathList] = useState([]);
    const [pagination, setPagination] = React.useState({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'updated_at',
        total: 0
    });

    const workspaceId = params.id;

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`LEARNING_PATH.${str}`, opt));

    const getLearningPathList = (page: number | string = 1, order = 'desc',  filter = undefined) => {
        console.log('order: ', order)
        setLoading(true);
        const payload = {
            ...pagination,
            ...filter,
            order,
            page,
        }
        delete payload?.total;
        WorkspaceService.getLearningPathsService(workspaceId, payload)
            .then((res) => {
                setFirstLoad(false);
                const { items, page, per_page, total } = res.data;
                setLearningPathList(items);
                setPagination({ ...pagination, ...payload, total });
                setIndexPage(getIndexPage(page, per_page, items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const getInvoiceListDebounce = useCallback(
        debounce((page, order, filter) => getLearningPathList(page, order, filter), 500),
        []
      );

    const handlePagination = (page: number) => {
        getLearningPathList(page, pagination?.order, filter);
    };

    useEffect(() => {
        getInvoiceListDebounce(1, pagination?.order, filter);
    }, [filter]);

    const onChangeFilter = (filter) => {
        setFilter(filter)
    }

    const onChangeOrder = (order) => {
        getLearningPathList(1, order)
    }

    const onDeleteLearningPath = () => {
        setLoading(true);
        WorkspaceService.deleteLearningPathsService(workspaceId, selected.id)
            .then((res) => {
                getLearningPathList(1, filter)
                Toastify.success(translator("DELETE_LEARNING_PATH_SUCCESS"));
                setOpenmodalDeleteLearningPath(false)
                setOpenmodalViewDetailLearningPath(false)
                setSelected(undefined)
            })
            .catch(() => {
                Toastify.error(translator("DELETE_LEARNING_PATH_ERROR"));
            })
            .finally(() => setLoading(false));
    }

    const onRenameLearningPath = (learningPathName) => {
        learningPathService.updateLearningPath(params?.id, selected?.id, { title: learningPathName })
            .then(() => {
                Toastify.success(translator("RENAME_LEARNING_PATH_SUCCESS"));
                getLearningPathList(1, filter);
                setOpenModalRenameLearningPath(false);
                setOpenmodalViewDetailLearningPath(false);
                setSelected(undefined);
            })
            .catch(() => Toastify.error(translator("RENAME_LEARNING_PATH_ERROR")));
    };

    const onDuplicateLearningPath = (learningPathId) => {
        learningPathService.duplicateLearningPath(params?.id, learningPathId)
            .then(() => {
                Toastify.success(translator("DUPLICATE_LEARNING_PATH_SUCCESS"));
                getLearningPathList(1, filter);
            })
            .catch(() => Toastify.error(translator("DUPLICATE_LEARNING_PATH_ERROR")));
    }

    const onRevertToDraft = () => {
        learningPathService.updateLearningPath(params?.id, selected?.id, { status: 'draft' })
            .then(() => {
                Toastify.success(translator("CHANGE_STATUS_SUCCESS"));
                getLearningPathList(1, filter);
                setOpenmodalRevertToDraft(false);
            })
            .catch(() => Toastify.error(translator("CHANGE_STATUS_ERROR")));
    }

    return (
        <div className="LearningPathList">
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.CONTENT_MANAGEMENT', true)}
                pages={[
                    { name: translator('DASHBOARD.SIDEBAR.LEARNING_PATH', true), href: ``, className: '' },
                ]}
                containerClassName="LearningPathList-breadcrumb"
            />
            <div className="LearningPathList-FilterBar">
                <Filter isFirstLoad={isFirstLoad} onChangeFilter={onChangeFilter} />
                {checkPermission('learningPath', 'C') ? <ButtonPrimary
                    type="button"
                    onClick={() => history.push(`/workspace/${workspaceId}/learning-path/new`)}
                    className='h-fit'
                >
                    <PlusIcon className="icon-add" />{translator("NEW")}
                </ButtonPrimary> : ''}

            </div>

            <div className="LearningPathList-TableContent">
                <div className="Scrollbar custom-scrollbar">
                    {loading ? (
                        <div className='Scrollbar-loading'><Spinner /></div>
                    ) : (
                        <TableLearningPath
                            workspaceId={workspaceId}
                            dataTable={learningPathList}
                            translator={translator}
                            setSelected={setSelected}
                            onChangeOrder={onChangeOrder}
                            pagination={pagination}
                            onDelete={() => setOpenmodalDeleteLearningPath(true)}
                            onRename={() => setOpenModalRenameLearningPath(true)}
                            onDuplicateLearningPath={onDuplicateLearningPath}
                            onViewDetail={() => setOpenmodalViewDetailLearningPath(true)}
                            onRevertToDraft={() => setOpenmodalRevertToDraft(true)}
                        />
                    )}
                </div>
                <Pagination
                    indexPage={indexPage}
                    total={pagination.total}
                    per_page={pagination.per_page}
                    page={pagination.page}
                    callback={handlePagination}
                />
            </div>

            {isOpenmodalDeleteLearningPath && (
                <ModalDeleteLearningPath
                    onDeleteLearningPath={onDeleteLearningPath}
                    isOpen={isOpenmodalDeleteLearningPath}
                    onCloseModal={() => setOpenmodalDeleteLearningPath(false)}
                    status={selected?.status}
                />
            )
            }
            {isOpenmodalRevertToDraft && (
                <ModalRevertToDraft
                    onRevertToDraft={onRevertToDraft}
                    isOpen={isOpenmodalRevertToDraft}
                    onCloseModal={() => setOpenmodalRevertToDraft(false)}
                />
            )
            }
            {isOpenModalRenameLearningPath && (
                <ModalRenameLearningPath
                    isOpen={isOpenModalRenameLearningPath}
                    onCloseModal={() => setOpenModalRenameLearningPath(false)}
                    onRename={onRenameLearningPath}
                    learningPath={selected}
                />
            )}
            {isOpenmodalViewDetailLearningPath && (
                <ModalViewDetailLearningPath
                    isOpen={isOpenmodalViewDetailLearningPath}
                    onCloseModal={() => setOpenmodalViewDetailLearningPath(false)}
                    data={selected}
                    onDelete={() => setOpenmodalDeleteLearningPath(true)}
                    onRename={() => setOpenModalRenameLearningPath(true)}
                    onDuplicateLearningPath={onDuplicateLearningPath}
                    onViewDetail={() => setOpenmodalViewDetailLearningPath(true)}
                    onRevertToDraft={() => setOpenmodalRevertToDraft(true)}
                    child={LearningPathDetail}
                />
            )}
        </div>
    );
};

export default LearningPathList;
