import * as React from 'react';
import { useLocation } from 'react-router-dom';
// PACKAGES
import { Link } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react';
// ASSETS
import Camera from 'assets/SVG/camera.svg';
// CONTEXTS
import { AuthContext } from 'contexts/Auth/AuthContext';
import { useState } from 'react';
import UpdateAvatarModal from 'components/User/AccountSettingForm/UpdateAvatarModal';
import { useTranslation } from 'react-i18next';
import { handleLogout } from 'utils/handleLogout';
import { UserContext } from 'contexts/User/UserContext';
import userMiddleware from 'middleware/user.middleware';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useAppSelector } from 'hooks/hooks';
import { CogIcon } from '@heroicons/react/outline';

export interface UserDetailMenuProps {
    setOpenUserDetail?: React.Dispatch<React.SetStateAction<boolean>>;
    setAuthStorage?: React.Dispatch<React.SetStateAction<boolean>>;
    role?: string;
    isAuthStorage?: boolean;
}

export function handleUserDetailForm(
    setOpenUserDetail: React.Dispatch<React.SetStateAction<boolean>>
) {
    return () => {
        setOpenUserDetail((prevState) => {
            return !prevState;
        });
    };
}

const UserDetailMenu: React.FC<UserDetailMenuProps> = ({
    setAuthStorage,
    role,
    isAuthStorage,
}) => {
    const { expandedMenu } = useAppSelector(state => state.configPage);
    const { t: translator } = useTranslation();
    const authCtx = React.useContext(AuthContext);
    const { dispatch: UserDispatch, userState } = React.useContext(UserContext);
    const { dispatch: WorkspaceDispatch } = React.useContext(
        GetWorkspaceContext
    );
    const { result: userInformation } = userState;
    const authDispatch = authCtx.dispatch;

    const location = useLocation();
    const workspaceId = location.pathname.split('/')[2];
    const [isShowUpdateAvatarModal, showUpdateAvatarModal] = useState(false);

    React.useEffect(() => {
        if (!userInformation.id && isAuthStorage) {
            userMiddleware.getUser(UserDispatch);
        }
    }, [userInformation]);

    const handleLogoutUser = () => {
        handleLogout(authDispatch, setAuthStorage, async () => {
            userMiddleware.resetUserState(UserDispatch);
            await workspaceMiddleware.resetUserState(WorkspaceDispatch);
            localStorage.clear();
        });
    };

    return (
        <Popover className="z-70 relative h-full w-full text-center" as="div">
            {({ open }) => (
                <>
                    <Popover.Button className="focus:outline-none w-full">
                        <div className="w-full flex items-center justify-center space-x-3 px-2 py-[9px]">
                            <img
                                className="flex-none w-10 h-10 border border-apollo-btn rounded-full max-w-none"
                                src={userInformation?.avatar_url}
                                alt=""
                            />
                            {expandedMenu ? (
                                <div className="flex-1 truncate">
                                    <div className="flex items-center space-x-3">
                                        <h3 className="text-gray-900 text-sm font-semibold truncate">{userInformation?.first_name}</h3>
                                    </div>
                                    <p className="text-gray-800 text-[12px] truncate text-left">{userInformation.email}</p>
                                </div>
                            ) : null}
                        </div>
                    </Popover.Button>

                    <>
                        <Popover.Panel className="absolute min-w-[200px] top-[55px] left-0 z-20 bg-white rounded-header_menu w-full shadow-ooolab_box_userinfo overflow-hidden mt-1">
                            <div className="py-4">
                                <div className="relative w-12 h-12 m-auto drop-shadow shadow-ooolab_box_shadow_2 rounded-full">
                                    <img
                                        src={userInformation?.avatar_url || ''}
                                        alt="_avatar"
                                        className="rounded-full object-cover w-12 h-12"
                                    />
                                    <div
                                        onClick={() => showUpdateAvatarModal(true)}
                                        className="absolute bottom-ooolab_n_inset_camera right-ooolab_n_inset_camera bg-white rounded-full cursor-pointer group w-5 h-5 flex items-center justify-center"
                                        style={{
                                            boxShadow: '0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16)',
                                        }}
                                    >
                                        <img src={Camera} alt="_camera" className="w-3 h-2.5" />
                                    </div>
                                </div>
                                <div className="text-gray-800 text-base text-center mt-1 font-semibold truncate px-2">
                                    {userInformation?.display_name || ''}
                                </div>
                                {/* <div className="text-ooolab_text_bar_inactive text-xs text-center break-words">
                                    {userInformation?.email || ''}
                                </div> */}
                                <Link to={`/workspace/${workspaceId}/user-setting`}>
                                    <div className="py-1.5 px-3 mt-1 rounded-sub_tab text-enterprise max-h-8 max-w-[170px] border border-enterprise flex items-center justify-center space-x-1.5 m-auto">
                                        <CogIcon className="w-5 h-5 text-enterprise" />
                                        <span className="text-sm font-semibold text-center">
                                            {translator('ACCOUNT_SETTING.ACCOUNT_SETTINGS')}
                                        </span>
                                    </div>
                                </Link>
                            </div>

                            <div className="border-t border-gray-300 px-4 py-2.5">
                                {/* <div className="text-xs text-ooolab_dark_2 font-medium flex items-end">
                                    {translator('TERMS_OF_SERVICE')}
                                </div> */}

                                <div
                                    onClick={handleLogoutUser}
                                    className="flex items-center space-x-2 cursor-pointer"
                                >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M4.16675 3.33332C3.94573 3.33332 3.73377 3.42112 3.57749 3.5774C3.42121 3.73368 3.33341 3.94564 3.33341 4.16666V15.8333C3.33341 16.0543 3.42121 16.2663 3.57749 16.4226C3.73377 16.5789 3.94573 16.6667 4.16675 16.6667H7.50008C7.96032 16.6667 8.33341 17.0398 8.33341 17.5C8.33341 17.9602 7.96032 18.3333 7.50008 18.3333H4.16675C3.50371 18.3333 2.86782 18.0699 2.39898 17.6011C1.93014 17.1322 1.66675 16.4964 1.66675 15.8333V4.16666C1.66675 3.50362 1.93014 2.86773 2.39898 2.39889C2.86782 1.93005 3.50371 1.66666 4.16675 1.66666H7.50008C7.96032 1.66666 8.33341 2.03975 8.33341 2.49999C8.33341 2.96023 7.96032 3.33332 7.50008 3.33332H4.16675Z"
                                            fill="#111827"
                                        />
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M12.7441 5.24408C13.0695 4.91864 13.5972 4.91864 13.9226 5.24408L18.0893 9.41074C18.4147 9.73618 18.4147 10.2638 18.0893 10.5893L13.9226 14.7559C13.5972 15.0814 13.0695 15.0814 12.7441 14.7559C12.4186 14.4305 12.4186 13.9028 12.7441 13.5774L16.3215 10L12.7441 6.42259C12.4186 6.09715 12.4186 5.56951 12.7441 5.24408Z"
                                            fill="#111827"
                                        />
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M6.66675 9.99999C6.66675 9.53975 7.03984 9.16666 7.50008 9.16666H17.5001C17.9603 9.16666 18.3334 9.53975 18.3334 9.99999C18.3334 10.4602 17.9603 10.8333 17.5001 10.8333H7.50008C7.03984 10.8333 6.66675 10.4602 6.66675 9.99999Z"
                                            fill="#111827"
                                        />
                                    </svg>
                                    <span className="text-sm font-normal text-gray-800">
                                        Log Out
                                    </span>
                                </div>
                            </div>
                        </Popover.Panel>
                    </>
                    {isShowUpdateAvatarModal && (
                        <UpdateAvatarModal
                            avatar_url={userInformation?.avatar_url}
                            onUpdatedAvatar={() => {
                                showUpdateAvatarModal(false);
                                userMiddleware.getUser(UserDispatch);
                            }}
                            onCancel={() => showUpdateAvatarModal(false)}
                        />
                    )}
                </>
            )}
        </Popover>
    );
};

export default UserDetailMenu;
