import InstructorAvailability from 'components/Dashboard/InstructorAvailability';
import ManagementMasterPage from 'pages/RouteMasterPage';

const InstructorAvailabilityPage = () => {
    return (
        <InstructorAvailability>
            <ManagementMasterPage />
        </InstructorAvailability>
    );
};

export default InstructorAvailabilityPage;
