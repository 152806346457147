import React, {
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    generateAppNewBars,
    NewBarType,
} from 'constant/setupBars.const';
import {
    GetListOfWorkspaceContext,
    GetWorkspaceContext,
} from 'contexts/Workspace/WorkspaceContext';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Transition } from '@headlessui/react';
import SubMenuNavigation from './LeftNavComponent/SubMenuNavigation';
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon, } from '@heroicons/react/outline';
import WorkspaceMenu from './WorkspaceMenu/WorkspaceMenu';
import { AUTH_CONST } from 'constant/auth.const';
import UserDetailMenu from 'components/MainNav/UserDetailMenu';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';

import './style.css';
import "./NewLeftMenu.scss";
import { changeExpandedMenu } from 'store/features/configPageSlice';

export interface NewLeftMenuProps {
    tabType: NewBarType;
    setHiddenMenu: React.Dispatch<React.SetStateAction<boolean>>;
    hiddenMenu: boolean;
    setAuthStorage: React.Dispatch<React.SetStateAction<boolean>>;
    isMobile: boolean;
    isParent?: number;
    isAuthStorage?: boolean;
}

const NewLeftMenu: React.FC<NewLeftMenuProps> = ({
    tabType,
    hiddenMenu,
    setAuthStorage,
    isMobile,
    isParent,
    isAuthStorage,
}) => {
    const { expandedMenu } = useAppSelector(state => state.configPage);
    const useDispatch = useAppDispatch();

    const { t: translator } = useTranslation();
    const history = useHistory();
    const { getWorkspaceDetailState } = useContext(
        GetWorkspaceContext
    );
    const [selectedMenu, setSelectedMenu] = useState<number>(0);
    const [selectedMenus, setSelectedMenus] = useState<number[]>([]);

    const getListOfWorkspaceCtx = useContext(GetListOfWorkspaceContext);
    const getListOfWorkspaceDispatch = getListOfWorkspaceCtx.dispatch;

    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;
    const {
        membership: { role, type, user_role },
        id: paramId,
    } = WorkspaceDetailInformation;

    useEffect(() => {
        if (isAuthStorage) {
            workspaceMiddleware.getListOfWorkspace(getListOfWorkspaceDispatch);
        }
        if (isParent != -1) {
            setSelectedMenu(isParent);
        }
    }, []);
    useEffect(() => {
        const { err } = getWorkspaceDetailState;
        if (
            err?.error?.name === AUTH_CONST.FORBIDDEN ||
            err?.error?.name === AUTH_CONST.NOT_FOUND
        ) {
            history.push('/');
        }
    }, [getWorkspaceDetailState.err]);

    const appBars = useMemo(() => {
        return generateAppNewBars({
            workspaceId: paramId.toString(),
            isAdmin: role === 'admin' ? true : false,
            translator,
            type,
            role: user_role,
        });
    }, [role, paramId, translator, type]);

    const currentParentTab = useMemo(
        () => appBars.find((item: any) => item.type === tabType),
        [tabType, appBars]
    );

    useEffect(() => {
        const currentTab = appBars.find((item: any) => item.type === tabType);
        if (currentTab) {
            setSelectedMenu(currentTab.id);
            setSelectedMenus([currentTab?.id]);
        }
    }, [tabType, appBars]);

    const handleSelectMenu = (tabID) => {
        if (selectedMenus?.filter(id => tabID == id)?.length > 0) {
            setSelectedMenus(selectedMenus?.filter(id => tabID != id));
        } else {
            setSelectedMenus([...selectedMenus, ...[tabID]]);
        }
    }
    return (
        <>
            <div
                className={`newLeftMenu ${isMobile ? 'newLeftMenu--mobile' : 'newLeftMenu--desktop'} ${expandedMenu ? '' : 'newLeftMenu--collapse'}`}
            >
                <div className="flex flex-col flex-grow bg-white">
                    <div className="relative flex items-center flex-shrink-0 border-b border-b-borderColor-ooolab_gray_6">
                        <UserDetailMenu
                            setAuthStorage={setAuthStorage}
                            role={role}
                            isAuthStorage={isAuthStorage}
                        />
                        <button onClick={() => useDispatch(changeExpandedMenu(!expandedMenu))}
                            className='flex items-center justify-center absolute -right-3 -bottom-3 bg-white hover:bg-gray-500 text-gray-500 hover:text-white z-10 shadow-medium rounded-full border border-gray-200 h-6 w-6 p-0.5 transition'>
                            {!expandedMenu ? <ChevronRightIcon className='w-4 h-4' /> : <ChevronLeftIcon className='w-4 h-4' />}
                        </button>
                    </div>
                    <div className='newLeftMenu-menulist flex flex-col h-full max-h-[calc(100vh-118px)] overflow-y-auto'>
                        <div className="flex-grow flex flex-col">
                            <div>
                                <nav className="flex-1 pb-4 space-y-1 px-2 mt-2">
                                    {appBars.map((bar: any, index: number) => (
                                        <div key={index}>
                                            <div className={`group newLeftMenu-item
                                                ${hiddenMenu && 'justify-center'} ${(selectedMenus?.filter(id => id == bar?.id).length > 0 && expandedMenu) || (currentParentTab?.id == bar?.id) ? 'bg-primary-500 text-white hover:text-white ' : 'text-dark-100 hover:text-primary-500'}
                                                ${bar?.tab?.length > 0 ? 'rounded-t-lg' : 'rounded-lg'}
                                                ${bar?.hide ? ' !hidden' : ''}
                                            `}
                                                onClick={() => {
                                                    setSelectedMenu(bar.id);
                                                    handleSelectMenu(bar.id);
                                                    useDispatch(changeExpandedMenu(true))
                                                    if (bar?.href) {
                                                        history.push(bar.href);
                                                    }
                                                }}
                                            >
                                                <div className='w-10 flex items-center justify-center'>{bar.icon}</div>
                                                {expandedMenu ? <div className={`${hiddenMenu && 'hidden'}`}>
                                                    {bar.title}
                                                </div> : null}
                                                {selectedMenus?.filter(id => id == bar?.id).length == 0 && !bar?.href && expandedMenu && <ChevronDownIcon className="flex-shrink-0 h-3 w-3 transform transition-colors ease-in-out duration-150 ml-auto" />}
                                                {selectedMenus?.filter(id => id == bar?.id).length > 0 && !bar?.href && expandedMenu && <ChevronUpIcon className="flex-shrink-0 h-3 w-3 transform transition-colors ease-in-out duration-150 ml-auto" />}
                                            </div>

                                            {bar?.tab?.length > 0 && expandedMenu && selectedMenus?.filter(id => id == bar?.id).length > 0 && !bar?.hide && <Transition
                                                show={true}
                                                as="div"
                                                className=""
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <SubMenuNavigation
                                                    key={`bar-${bar.id}`}
                                                    bar={bar}
                                                    setSelectedMenu={
                                                        setSelectedMenu
                                                    }
                                                />
                                            </Transition>}
                                        </div>
                                    ))}
                                </nav>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={`w-full p-2 ${!expandedMenu ? 'border-t border-gray-200' : ''}`}>
                    <div className="relative">
                        <WorkspaceMenu />
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewLeftMenu;
