import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';

import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Pagination from 'components/Management/components/Pagination';
import TableRewardHistory from './TableRewardHistory';
import rewardMiddleware from 'middleware/reward.middleware';
import { useParams } from 'react-router-dom';
import { RewardsContext } from 'contexts/Rewards/RewardsContext';
import NoContent, { NoContentWithImage } from 'components/NoContent/NoContent';
import { CircleSpin } from 'components/UiComponents/CircleSpin';

const RewardHistory: React.FC = ({ children }) => {
    const params: { id: string, rewardId: string } = useParams();
    const [dataList, setDataList] = useState<any>();
    const [reloadData, setReloadData] = useState(false);
    const { t: translator } = useTranslation();

    const { dispatch, getWorkspaceDetailState } = useContext(
        GetWorkspaceContext
    );

    const { dispatch: dispatchRewardsHistory, rewardsState } = useContext(
        RewardsContext
    );
    const { listRewardsHistory, loading } = rewardsState;

    const { result } = getWorkspaceDetailState;
    const {
        membership: { role },
    } = result;

    const handleChangePagination = (e) => {
        rewardMiddleware.getListRewardHistory(params?.id,  params?.rewardId, dispatchRewardsHistory, {
            page: e,
        });
    };

    useEffect(() => {
        rewardMiddleware.getListRewardHistory(params?.id, params?.rewardId, dispatchRewardsHistory);
        return () => {
            setDataList({});
        }
    }, []);

    useEffect(() => {
        if(listRewardsHistory?.items) {
            setDataList(listRewardsHistory);
        }
    }, [listRewardsHistory]);

    useEffect(() => {
        if(reloadData) {
            setDataList({});
            rewardMiddleware.getListRewardHistory(params?.id,  params?.rewardId, dispatchRewardsHistory, {
                page: dataList.page,
            });
            setReloadData(false);
        }
    }, [reloadData]);

    return (
        <div className='RewardHistory'>
            <div className="z-1 h-full outline-none mt-2">
            {loading ? (
                <div className="flex items-center justify-center mt-6">
                    <CircleSpin color="text-[#00559C]" />
                </div>
            ) : dataList?.items?.length ? (
                <div>
                    <div className="min-h-height_78vh">
                        <TableRewardHistory data={dataList.items} onReload={() => {setReloadData(true)}} />
                    </div>
                    <div className="flex justify-between opacity-100 mx-3 border-t pt-3 ">
                        <div className="">
                            <p className="text-ooolab_dark_2 text-xs font-semibold leading-ooolab_24px">
                                Showing{' '}
                                {dataList.page * dataList.per_page -
                                    dataList.per_page +
                                    1}
                                -
                                {dataList.items.length +
                                    dataList.per_page *
                                        (dataList.page - 1)}{' '}
                                of {dataList.total}
                            </p>
                        </div>
                        <div className="">
                            <Pagination
                                onClickPagination={handleChangePagination}
                                perPage={dataList.per_page}
                                total={dataList.total}
                            />
                        </div>
                    </div>
                </div>
                ) : (
                <NoContentWithImage content={translator("No Reward History")} />
            )}
            </div>
        </div>
    );
};

export default RewardHistory;
