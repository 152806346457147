import { PlusIcon } from '@heroicons/react/outline';
import { useBoolean } from 'hooks/custom';
import CreateGroupFrom from './CreateGroupsForm';
import TableGroups from './TableGroups';
import { useEffect, useState } from 'react';
import { useDebouncedState } from 'hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import ButtonComponent from 'components/Button/ButtonComponent';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import DialogComponent from 'components/Dialog/DialogComponent';
import workspaceService from 'services/workspace.service';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import Pagination from 'components/V2/Pagination';
import SearchBox from 'components/V2/SearchBox';

const PER_PAGE = 20;
const GroupsManagement = ({ children }) => {
    const params: any = useParams();
    const { t: translator } = useTranslation();

    const {
        booleanValue: modalCreate,
        toggleBooleanValue: toggleModalCreate,
    } = useBoolean();

    const [loading, setLoading] = useState(false);
    const [indexPage, setIndexPage] = useState('1-50');
    const [keyword, setKeyword] = useDebouncedState('');
    const [groupList, setGroupList] = useState([]);
    const [order, setOrder] = useState('asc')

    const [pagination, setPagination] = useState({
        page: 1,
        per_page: PER_PAGE,
        order: 'asc',
        sort_by: 'name',
        total: 0,
    });

    const workspaceId = params.id;

    const getGroupList = (page: number | string = 1) => {
        setLoading(true);
        const params = {
            q: keyword,
            ...pagination,
            page,
            order,
        }
        if (!keyword) delete params.q;
        workspaceService.getLearningGroupService(workspaceId, params)
            .then((res) => {
                const {
                    items,
                    page,
                    per_page,
                    order,
                    total,
                } = res.data;
                setGroupList(items);
                setPagination({ ...pagination, page, per_page, order, total });
                setIndexPage(getIndexPage(page, per_page, items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getGroupList();
    }, [keyword, order]);

    const handlePagination = (page: number) => {
        getGroupList(page);
    };


    return (
        <>
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT')}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    { name: translator('DASHBOARD.SIDEBAR.LEARNING_GROUP'), href: ``, className: '' },
                ]}
            />
            <div className="w-full px-4">
                <DialogComponent
                    isOpen={modalCreate}
                    title={translator("LEARNING_GROUP.ADD_LEARNING_GROUP")}
                    onCloseModal={toggleModalCreate}
                    maskClosable={false}
                    styles="max-w-md"
                    stylesTitle='text-base'
                    child={
                        <CreateGroupFrom
                            toggleModalCreate={toggleModalCreate}
                            getGroupList={getGroupList}
                        />
                    }
                />

                <div className="flex justify-between py-4">
                    <SearchBox className="w-full max-w-xs" placeholder={translator("GROUP.SEARCH")} onSubmit={(e: any) => setKeyword(e)} />
                    {checkPermission('learningGroup', 'C') && <div className="lg:flex lg:justify-end">
                        <ButtonComponent
                            title={translator("LEARNING_GROUP.ADD_GROUP")}
                            classStyle="text-xs w-fit font-semibold !rounded bg-primary-500 text-white flex gap-1 justify-center items-center px-2"
                            onClickButton={toggleModalCreate}
                            icon={<PlusIcon className="w-4 h-4" />}
                        />
                    </div>}
                </div>

                <div>
                    <div className="h-[calc(100vh-179px)] custom-scrollbar overflow-y-auto">
                        {
                            loading ?
                                <div className='h-[calc(100vh-179px)] flex items-center justify-center mt-4'><CircleSpin color='text-primary-500' /></div>
                                :
                                <TableGroups
                                    workspaceId={workspaceId}
                                    dataTable={groupList}
                                    order={order}
                                    setOrder={setOrder}
                                    refreshData={getGroupList}
                                />
                        }

                    </div>
                    <div className='px-2'>
                        <Pagination
                            callback={handlePagination}
                            total={pagination?.total}
                            page={pagination?.page}
                            per_page={pagination?.per_page}
                            indexPage={indexPage}
                        />
                    </div>
                </div>
               
            </div>
        </>

    );
};

export default GroupsManagement;



