import TeacherDashboardDetails from 'components/Reports/TeacherDashboard/SpecificTeacherDashboard';
import React from 'react';
import ReportPermission from '../ReportPermission';

const StudentDashboardDetailsPage: React.FC<{ setAuthStorage: React.Dispatch<React.SetStateAction<boolean>> }> = ({
    setAuthStorage,
}) => {
    return (
        <ReportPermission>
            <TeacherDashboardDetails />
        </ReportPermission>
    );
};

export default StudentDashboardDetailsPage;
