import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import DragAndDrop from './DragAndDrop';
import NO_CONTENT from 'assets/img/empty-state/content.png';
import './DragAndDrop.scss';

const Dropzone = ({updateContentUpload}, ref) => {
    const { t: translator } = useTranslation();
    return (
        <DragAndDrop updateContentUpload={updateContentUpload} ref={ref}>
            <div className="dropzone">
                <img className="img-nocontent" src={NO_CONTENT} />
                <div className="main-content">
                    <div className="title">
                        {translator('LIBRARY.UPLOAD_LOOK_LIKE_NOTHING_THERE')}
                    </div>
                    <div className="content">{translator('LIBRARY.START_CONTENT')}</div>
                    <div className="content">{translator('LIBRARY.OR')}</div>
                    <div className="content">{translator('LIBRARY.DROP_FILE')}</div>
                </div>
            </div>
        </DragAndDrop>
    )
}

export default forwardRef(Dropzone)