import { ACADEMICS, LIBRARY, META_DATA } from 'constant/api.const';
import axios from './restful.service';

const getListPaymentDetail = (workspaceId: string, params?: Record<any, any>) => {
    if (workspaceId) {
        return axios.get(`/workspaces/${workspaceId}/payment-details`, { params });
    }
};
const createPaymentDetail = (workspaceId: string, params?: Record<any, any>) => {
    if (workspaceId) {
        return axios.post(`/workspaces/${workspaceId}/payment-details`, params);
    }
};
const getPaymentDetail = (workspaceId: string, payment_detail_id: string) => {
    if (workspaceId) {
        return axios.post(`/workspaces/${workspaceId}/payment-details/${payment_detail_id}`);
    }
};
const updatePaymentDetail = (workspaceId: string, params?: Record<any, any>) => {
    if (workspaceId) {
        return axios.patch(`/workspaces/${workspaceId}/payment-details/${params.id}`, params);
    }
};
const getCurrency = (params?: Record<any, any>) => {
    return axios.get(META_DATA.currency, { params });
};
const getIntegrationList = (workspace_id?: string, params?: any) => {
    return axios.get(`/workspaces/${workspace_id}/integrations`, { params });
};
const onboardIntegration = (workspace_id?: string, params?: { type?: string; country?: string }) => {
    return axios.post(`/workspaces/${workspace_id}/integrations/onboard`, params);
};
const updateIntegration = (workspace_id?: string, params?: { integration_id?: string; connect_state?: string; enabled?: boolean }) => {
    return axios.patch(`/workspaces/${workspace_id}/integrations/${params.integration_id}`, params);
};
const getIntegrationLog = (workspace_id?: string) => {
    return axios.get(`/workspaces/${workspace_id}/integrations/logs`);
};

const getDashboardStripe = (workspace_id?: string, params?: any) => {
    return axios.post(`/workspaces/${workspace_id}/integrations/login`, params);
};

export default {
    getListPaymentDetail,
    createPaymentDetail,
    getPaymentDetail,
    updatePaymentDetail,
    getCurrency,
    getIntegrationList,
    onboardIntegration,
    updateIntegration,
    getIntegrationLog,
    getDashboardStripe,
};
