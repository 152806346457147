import React from "react";
import { ArrowRightSIcon } from "assets/icon";
import './LessonBuilderSidebar.scss';

export const LessonBuilderSideHeader = ({ isOpen, onClick = null, children , isShowError, translator}) => {
    return (
        <>
            <div onClick={onClick}
                className={`lessonBuilderSidebar-header ${isOpen ? 'lessonBuilderSidebar-header--active' : ''} ${isShowError ? 'showError' : ''}`}>
                <span className="lessonBuilderSidebar-arrow"><ArrowRightSIcon /></span>
                {children}
            </div>
            {isShowError && <div className="error">{translator("FORM_CONST.THIS_IS_REQUIRED_FIELDS_FOR_PUBLISHING_A_LESSON")}</div>}
        </>
    )
}

export const LessonBuilderSideContent = ({ children }) => {
    return <div className="lessonBuilderSidebar-content">{children}</div>;
}