import { ROLE_PERMISSIONS } from 'constant/api.const';
import { RestfulService } from './restful.service';
import axios from './restful.service';

const getWorkspaceRoles = (workspace_id, params: any) => {
    return RestfulService.getApi(`/workspaces/${workspace_id}/roles`, params);
};
const createCustomRoles = (workspaceId: string, bodyData?: any) => {
    const url = ROLE_PERMISSIONS.customRoles(workspaceId);
    return axios.post(url, bodyData);
};
const getDetailsRoles = (workspaceId: string, roleId: string) => {
    const url = ROLE_PERMISSIONS.customRolesDetails(workspaceId, roleId);
    return axios.get(url);
};
const patchDetailsRoles = (workspaceId: string, roleId: string, bodyData?: any) => {
    const url = ROLE_PERMISSIONS.customRolesDetails(workspaceId, roleId);
    return axios.patch(url, bodyData);
};
const updateDetailsRoles = (workspaceId: string, roleId: string, bodyData?: any) => {
    const url = ROLE_PERMISSIONS.customRolesDetails(workspaceId, roleId);
    return axios.put(url, bodyData);
};
const deleteCustomRole = (workspaceId: string, roleId: string, bodyData?: any) => {
    const url = ROLE_PERMISSIONS.customRolesDetails(workspaceId, roleId);
    return axios.delete(url, { data: bodyData });
};
export default {
    getWorkspaceRoles,
    createCustomRoles,
    getDetailsRoles,
    patchDetailsRoles,
    updateDetailsRoles,
    deleteCustomRole,
};
