import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
// COMPONENTS
import WorkspaceHomeContent from 'components/Workspace/WorkspaceHomeContent';
import RouteMasterPage from 'pages/RouteMasterPage';
export interface WorkspaceHomePageProps {
    setAuthStorage: React.Dispatch<React.SetStateAction<boolean>>;
}

const WorkspaceHomePage: React.FC<WorkspaceHomePageProps> = ({
    setAuthStorage,
}) => {
    const history = useHistory();
    const params: { id: string } = useParams();

    React.useEffect(() => {
        history.push(`/workspace/${params.id}/instructor-dashboard/instructor-schedule`);
    }, []);

    return (
        <RouteMasterPage>
            <WorkspaceHomeContent setAuthStorage={setAuthStorage} />
        </RouteMasterPage>
    );
};

export default WorkspaceHomePage;
