import React, { FC, useContext } from 'react';
import { Row, Column } from 'react-table';
import Table, { RenderTableHeader, RenderTableCell, LinkCustom } from 'components/V2/Table/TableCustom';
import dayjs from 'dayjs';
import { UserContext } from 'contexts/User/UserContext';
import EmptyIcon from 'assets/SVG/empty-stage-icon-student.svg'

type TableStudentProps = {
    workspaceId: string;
    dataTable: any;
    translator: (key: string) => string;
};

const TableStudent: FC<TableStudentProps> = ({ workspaceId, dataTable, translator }) => {
    const initialState = {
        hiddenColumns: ['id'],
    };

    const {
        userState: { result },
    } = useContext(UserContext);

    const columns = React.useMemo(
        () => [
            { accessor: 'id' },
            {
                Header: () => <RenderTableHeader value={translator('COMPANY.COMPANY_DETAIL.STUDENT_NAME')} />,
                Cell: ({ row, column }: { row: any; column: Column }) => {
                    return (
                        <LinkCustom
                            to={`/workspace/${workspaceId}/management/users/${row?.original?.id}`}
                            isTooltip
                            value={row.values?.display_name}
                            disable={row.values?.display_name?.length < 35}
                        />
                    );
                },
                accessor: 'display_name',
                width: 150,
            },
            {
                Header: () => <RenderTableHeader value={translator('COMPANY.COMPANY_DETAIL.EMAIL')} />,
                Cell: ({ row }) => {
                    return <RenderTableCell value={row.values?.parent?.email} />;
                },
                accessor: 'parent',
                width: 100,
            },
            {
                Header: () => <RenderTableHeader value={translator('COMPANY.COMPANY_DETAIL.CREATED_DATE')} />,
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return <RenderTableCell value={row.values?.created_at && dayjs(row.values?.created_at)?.tz(result?.time_zone)?.format("DD/MM/YYYY")} />;
                },
                accessor: 'created_at',
                width: 150,
            },
            
        ],
        [dataTable]
    );

    return (
        <Table
            isBorderFirstColum={false}
            data={dataTable}
            columns={columns}
            initialState={initialState}
            emptyContent={translator('COMPANY.COMPANY_DETAIL.NO_STUDENT')}
            emptyIcon={EmptyIcon}
        />
    );
};

export default TableStudent;
