import * as React from 'react';
// PACKAGE
import { useHistory } from 'react-router-dom';
// COMPONENTS
import CreateWorkspaceForm from './CreateWorkspaceForm';
// CONTEXT
import {
    CreateWorkspaceContext,
    GetListOfWorkspaceContext,
} from 'contexts/Workspace/WorkspaceContext';
// MIDDLEWARE
import workspaceMiddleware from 'middleware/workspace.middleware';
// LOGIC
import { CreateWorkspaceProps } from './CreateWorkspaceFn';
// CONTEXT
import { AuthContext } from 'contexts/Auth/AuthContext';
// UTILS
import { getLocalStorageAuthData } from 'utils/handleLocalStorage';
import { handleLogout } from 'utils/handleLogout';
import InviteToWorkspaceForm from './InviteToWorkspaceForm';
import { useState } from 'react';
import { AUTH_CONST } from 'constant/auth.const';
import workspaceService from 'services/workspace.service';
import { CurrencyWorkspaceType } from 'types/CreateWorkspace.type';
import { onSubmitForm } from './InviteToWorkspaceFormFn';

const CreateWorkspace: React.FC<CreateWorkspaceProps> = ({
    setAuthStorage,
    changeStep,
    step,
}) => {
    const history = useHistory();
    const [apiSuccessMsg, setApiSuccessMsg] = React.useState<string>();
    const [memberListMsg, setMemberListMsg] = React.useState<string>();
    const [apiErrorMsg, setApiErrorMsg] = React.useState<string>();
    const [avatar, setAvatar] = useState<File>();

    const { createWorkspaceState, dispatch } = React.useContext(
        CreateWorkspaceContext
    );
    const { err, isLoading, status } = createWorkspaceState;
    const { access_token } = getLocalStorageAuthData();

    const {
        getListOfWorkspaceState,
        dispatch: dispatchList,
    } = React.useContext(GetListOfWorkspaceContext);

    const { err: listWorkspaceErr } = getListOfWorkspaceState;

    const authCtx = React.useContext(AuthContext);
    const authDispatch = authCtx.dispatch;
    const [workspaceName, setWorkspaceName] = useState<string>('');
    const [currency, setCurrency] = useState<CurrencyWorkspaceType>(null);

    React.useEffect(() => {
        if (err?.error?.description) {
            setApiErrorMsg(err.error.description);
        } else if (err?.body_params[0]?.msg) {
            setApiErrorMsg(err?.body_params[0]?.msg);
        }
    }, [err]);

    React.useEffect(() => {
        if (listWorkspaceErr?.error?.name === AUTH_CONST.TOKEN_EXPIRED) {
            handleLogout(authDispatch, setAuthStorage);
        }
    }, [listWorkspaceErr]);

    React.useEffect(() => {
        if (access_token) {
            workspaceMiddleware.getListOfWorkspace(dispatchList);
        }
    }, []);

    React.useEffect(() => {
        if (status === 'done' && !isLoading) {
            workspaceService.getListOfWorkspace().then((res) => {
                const { items } = res;
                items.length > 1
                    ? history.push('/')
                    : items.length === 1 &&
                      history.push(`/workspace/${items[0].id}/instructor-dashboard/instructor-schedule`);
            });
        }
    }, [status, isLoading]);

    return (
        <>
            {step === 0 ? (
                <CreateWorkspaceForm
                    isLoading={isLoading}
                    status={status}
                    apiErrorMsg={apiErrorMsg}
                    memberListMsg={memberListMsg}
                    setMemberListMsg={setMemberListMsg}
                    apiSuccessMsg={apiSuccessMsg}
                    setApiSuccessMsg={setApiSuccessMsg}
                    workspaceName={workspaceName}
                    onMoveToInviteMember={(workspacename, currencyWp) => {
                        setWorkspaceName(workspacename);
                        setCurrency(currencyWp);
                        onSubmitForm(
                            [],
                            setApiErrorMsg,
                            access_token,
                            dispatch,
                            setApiSuccessMsg,
                            workspacename,
                            avatar,
                            currencyWp,
                        );
                    }}
                    onSelectAvatar={setAvatar}
                />
            ) : (
                <InviteToWorkspaceForm
                    isLoading={isLoading}
                    apiErrorMsg={apiErrorMsg}
                    setApiErrorMsg={setApiErrorMsg}
                    memberListMsg={memberListMsg}
                    setApiSuccessMsg={setApiSuccessMsg}
                    access_token={access_token}
                    dispatch={dispatch}
                    workspaceName={workspaceName}
                    avatar={avatar}
                    currency={currency}
                />
            )}
        </>
    );
};

export default CreateWorkspace;
