import React from 'react';
import TrashBin from 'components/Workspace/TrashBin';
import H5PProvider from 'contexts/H5P/H5PProvider';
import RouteMasterPage from 'pages/RouteMasterPage';

const WorkspaceTrashBin = () => {
    return (
        <H5PProvider>
            <TrashBin>
                <RouteMasterPage  />
            </TrashBin>
        </H5PProvider>
    );
};

export default WorkspaceTrashBin;
