import dayjs from 'dayjs';
import DashboardService from 'services/teach.services';
import WorkspaceService from 'services/workspace.service';

export const fetchVcLink = async (workspaceId, classId, sessionId) => {
    try {
        const res = await DashboardService.getVcLinkService({
            workspaceId: workspaceId,
            sessionId,
            classId,
        });
        return res.data || null;
    } catch (error) {
        return error.response.data.error;
    }
};

export const fetchDetailSyllabus = async (workspaceId, lessonId) => {
    if (lessonId)
        try {
            const res = await WorkspaceService.getLessonDetail(workspaceId, lessonId);
            return res || null;
        } catch (error) {
            return error.error;
        }
};

export const checkJoinClass = (el: any) => {
    let flg: boolean = true;
    // within 15 minus, lesser end_time
    if (dayjs().valueOf() >= dayjs(el.start_time).valueOf() - 900000 && dayjs().valueOf() < dayjs(el.end_time).valueOf()) flg = false;
    return flg;
};
