import * as React from 'react';
import './ProgressUploadFile.scss';
import UploadIconLibrary from 'assets/icon/UploadIconLibrary';
import { useTranslation } from 'react-i18next';
import { DocumentIcon, XIcon } from '@heroicons/react/outline';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { Tooltip } from '_shared';
import ErrorIcon from 'assets/icon/ErrorIcon';
import { CheckCircleIcon } from '@heroicons/react/solid';

interface ProgressUploadFileProps {
    fileList: any[];
    onClose: () => void;
}

export const ProgressUploadFile: React.FC<ProgressUploadFileProps> = ({ fileList, onClose }: ProgressUploadFileProps) => {
    const { t: translator } = useTranslation();

    const fileSuccess = fileList?.filter((item) => item?.status === 'success');

    return (
        <div className="ProgressUploadFile">
            <div className="ProgressUploadFile-card">
                <div className="ProgressUploadFile-sticky">
                    <div className="ProgressUploadFile-header">
                        <div className="title">
                            <UploadIconLibrary />
                            {translator(fileSuccess?.length > 1 ? 'LIBRARY.UPLOADS_COMPLETE' : 'LIBRARY.UPLOAD_COMPLETE', {
                                number: Object.keys(fileSuccess)?.length || 0,
                            })}
                        </div>
                        <XIcon onClick={onClose} className="w-6 h-6 cursor-pointer" />
                    </div>
                    {fileList?.map((item, idx) => (
                        <div className="ProgressUploadFile-item" key={idx}>
                            <div className="itemUpload">
                                <DocumentIcon strokeWidth={1} className="min-w-[24px] h-6" />
                                {item?.name}
                            </div>
                            <div className="status-upload">
                                {item?.loading ? (
                                    <CircleSpin color={'text-primary-500'} className='ml-0 w-6 h-6'/>
                                ) : item?.status === 'success' ? (
                                    <CheckCircleIcon className="text-green-500 w-8 h-8" />
                                ) : (
                                    <div className="relative">
                                        <Tooltip styles="" info={item?.error}>
                                            <ErrorIcon />
                                        </Tooltip>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
