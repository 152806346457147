import { PlusIcon } from '@heroicons/react/outline';
import ButtonComponent from 'components/Button/ButtonComponent';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import DialogComponent from 'components/Dialog/DialogComponent';
import { useBoolean } from 'hooks/custom';
import { useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';
import { useForm } from 'react-hook-form';
import { Toast } from 'utils/sweetAlert';
import TableMember from './Table';
import { useDebouncedState } from 'hooks/useDebounce';
import SearchBox from 'components/V2/SearchBox';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import Spinner from 'components/Spinner';
import Pagination from 'components/V2/Pagination';
import SelectBox from '_shared/components/Select';
import { Toastify } from '_shared';
import { ButtonOutline, ButtonPrimary } from '_shared';
import AddUserIcon from 'assets/icon/AddUserIcon';
import ModalDepositCredit from './ModalDepositCredit/ModalDepositCredit';

const PER_PAGE = 20;

export default function Member({ data }) {
    const {
        handleSubmit,
        control,
        formState: { errors, isDirty },
        setValue,
        watch
    } = useForm({ mode: "onChange" });

    const { t: translator } = useTranslation();
    const [members, setMembers] = useState([]);
    const { booleanValue: modalAdd, toggleBooleanValue: toggleModalAdd } = useBoolean();
    const params: any = useParams();
    const [loading, setLoading] = useState(false);
    const workspaceId = params?.id;
    const learningGroupId = params?.groupId;
    const [keyword, setKeyword] = useDebouncedState('');
    const [indexPage, setIndexPage] = useState('0-0');
    const [order, setOrder] = useState(false)
    const [memberSelected, setMemberSelected] = useState([])
    const [isOpenModalDepositCredit, setOpenModalDepositCredit] = useState(false)

    const [pagination, setPagination] = useState({
        page: 1,
        total: 0,
        per_page: PER_PAGE,
        order: "asc",
        sort_by: "email",
        q: ''
    });

    const fetchMemberList = (page: number | string = 1) => {
        setLoading(true);
        const payload = {
            ...pagination,
            page: page || pagination?.page,
            sort_by: "display_name",
            order: order ? "desc" : "asc",
            q: keyword
        };
        if (!payload?.q) delete payload?.q
        workspaceService.getMemberOfLearingGroupService(workspaceId, learningGroupId, payload)
            .then((res) => {
                const { items, page, per_page, order, sort_by, total } = res.data;
                setMembers(items);
                setPagination({ page, per_page, order, sort_by, total, q: keyword });
                setIndexPage(getIndexPage(page, per_page, items.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const handlePagination = (page: number) => {
        fetchMemberList(page);
    };

    useEffect(() => {
        fetchMemberList();
        return () => {
            setMembers([]);
        };
    }, [keyword, order]);

    const [learners, setLearners] = useState([]);
    const [inputSearchLearner, setInputSearchLearner] = useDebouncedState('', 0);
    const [paginaginLearner, setPaginationLearner] = useState({ total: 0, page: 1, per_page: 10 });
    const [loadingLearner, setLoadingLearner] = useState(false);

    const getLearners = (page: number) => {
        setLoadingLearner(true);
        let payload: any = {
            page,
            per_page: paginaginLearner.per_page,
            q: inputSearchLearner || null,
        };
        if (!payload?.q) delete payload?.q;

        workspaceService.getLearnersService(workspaceId, payload)
            .then((res) => {
                const newOptions = res?.data?.items?.map((el: any) => {
                    return {
                        id: el?.id,
                        name: el?.display_name,
                        email: el?.email,
                        value: el?.id,
                        avatar_url: el?.avatar_url,
                    };
                });
                if (page === 1) setLearners(newOptions);
                else setLearners([...learners, ...newOptions]);
                setPaginationLearner({ ...paginaginLearner, page: res?.data?.page, total: res?.data?.total });
            })
            .finally(() => setLoadingLearner(false));
    };

    const onSubmit = (val) => {
        const learnerList = val?.student?.map(student => student.value)
        workspaceService.addLearnerIntoLearningGroupService(workspaceId, learningGroupId, { members: learnerList })
            .then((res => {
                fetchMemberList()
                Toastify.success();
            }))
            .catch(err => {
                Toastify.error();
            })
            .finally(() => {
                toggleModalAdd()
                setValue('student', [])
            })
    }

    useEffect(() => {
        getLearners(1);
    }, [inputSearchLearner])

    return (
        <div className="text-ooolab_dark_1 text-xs font-normal">
            <div className='flex justify-between mx-3 mt-5 gap-4'>
                <SearchBox className='max-w-sm' onSubmit={(e: any) => setKeyword(e)} />

                {checkPermission('learningGroupDetailsMembers', 'C') && (
                    <div className='flex gap-4'>
                        <ButtonOutline
                            disabled={memberSelected?.length === 0}
                            onClick={() => setOpenModalDepositCredit(true)}
                            type='button'
                            className='flex gap-1 items-center !border-primary-500 !text-primary-500 min-w-fit'
                        >
                            <PlusIcon className='w-4 h-4 text-primary-500'/> {translator("USER.CREDIT_ACCOUNT.DEPOSIT_CREDITS")}
                        </ButtonOutline>
                        <ButtonPrimary
                            disabled={data?.status === 'inactive'}
                            onClick={toggleModalAdd}
                            type='button'
                            className='flex gap-1 items-center min-w-fit'
                        >
                            <AddUserIcon/> {data?.status === 'active' ? translator("LEARNING_GROUP.ADD_MEMBER") : translator("LEARNING_GROUP.ADD_MEMBER_NOT_AVAILABLE")}
                        </ButtonPrimary>
                    </div>
                )}
            </div>
            <div className="px-4 mt-4">
                <div className="h-[calc(100vh-250px)] custom-scrollbar overflow-auto">
                    {loading ? (
                        <div className="h-[calc(100vh-250px)] flex justify-center items-center">
                            <Spinner />
                        </div>
                    ) : (
                        <TableMember
                            dataTable={members}
                            workspaceId={workspaceId}
                            translator={translator}
                            isDisableAction={data?.status === 'inactive'}
                            memberSelected={memberSelected}
                            setMemberSelected={setMemberSelected}
                            refreshData={() => fetchMemberList()}
                        />
                    )}
                </div>
                <Pagination
                    indexPage={indexPage}
                    total={pagination.total}
                    per_page={pagination.per_page}
                    page={pagination.page}
                    callback={handlePagination}
                />
            </div>
            <DialogComponent
                isOpen={modalAdd}
                title={"Add Member"}
                onCloseModal={() => {
                    toggleModalAdd();
                }}
                maskClosable={false}
                styles="!max-w-lg"
                child={
                    <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>
                        <SelectBox
                            control={control}
                            label={translator('LEARNING_GROUP.EMAIL')}
                            name="student"
                            placeholder={translator('LEARNING_GROUP.EMAIL')}
                            errors={errors}
                            isMulti={true}
                            loading={loadingLearner}
                            value={watch('student') || []}
                            onSearch={setInputSearchLearner}
                            getData={getLearners}
                            data={learners}
                            pagination={paginaginLearner}
                            numberOfItemDisplayed={3}
                            isRequired
                            placeholderSearchInput="Search email"
                            formatOptionValue={(item) => (
                                <span className="text-ellipsis truncate max-w-[95px] text-sm">{item?.email}</span>
                            )}
                            formatOptionLabel={(item) => (
                                <span className="text-ellipsis truncate text-sm">{item?.email}</span>
                            )}
                        />

                        <div className="flex items-center justify-center space-x-4 mt-8">
                            <ButtonOutline
                                type={"button"}
                                onClick={toggleModalAdd}
                            >
                                {translator('LEARNING_GROUP.CANCEL')}
                            </ButtonOutline>
                            <ButtonPrimary
                                type={"submit"}
                                disabled={!isDirty}
                            >
                                {translator('LEARNING_GROUP.ADD')}
                            </ButtonPrimary>
                        </div>
                    </form>
                }
            />

            {isOpenModalDepositCredit &&
                <ModalDepositCredit 
                    isOpen={isOpenModalDepositCredit}
                    onClose={() => {
                        setOpenModalDepositCredit(false)
                        setMemberSelected([])
                    }}
                    data={memberSelected}
                    refreshData={fetchMemberList}
                />
            }
        </div>
    );
}
