import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import courseService from 'services/course.service';
import { useEffect, useState } from 'react';
import SelectBox from '_shared/components/Select';
import { useDebouncedState } from 'hooks/useDebounce';
import { useAppDispatch } from 'hooks/hooks';
import { setEnrollmentSelected } from 'store/features/courseSlice';

export default function ModalUpdateInstructor({ onCloseModal , data}) {
    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
        reset,
        watch
    } = useForm({mode: 'onChange'});
    const { t: translator } = useTranslation();

    const dispatch = useAppDispatch();

    const params: any = useParams();
    const workspaceId = params?.id;
    const courseId = params?.courseId;

    const [paginationInstructor, setPaginationInstructor] = useState<any>({
        page: 1,
        per_page: 20,
    })
    const [loadingInstructor, setLoadingInstructor] = useState(false);
    const [inputSearchInstructor, setInputSearchInstructor] = useDebouncedState('');
    const [listInstructors, setListInstructors] = useState([])

    const getInstructors = async (page) => {
        setLoadingInstructor(true);
        let payload: any = {
            page: page || paginationInstructor.page,
            per_page: 20,
            sort_by: 'email',
            order: 'desc',
            q: inputSearchInstructor || null,
        };
        if (!payload?.q) delete payload.q;
        const response = await  courseService.getInstructorEnrolled(workspaceId, courseId, payload)
        if (response) {
            setLoadingInstructor(false);
            const listOptions = response?.data?.items?.map((item) => {
                    return {
                        ...item.instructor,
                        name: item?.instructor?.display_name,
                        value: item?.id,
                    };
            });
            if (page === 1) setListInstructors(listOptions);
            else setListInstructors([...listInstructors, ...listOptions]);
            setPaginationInstructor({ ...paginationInstructor, total: response?.data?.total, page });
        }
    };

    useEffect(() => {
        getInstructors(1);
    }, [inputSearchInstructor])
     
    useEffect(() => {
        if (data) {
            reset({
                instructors: data?.instructors?.map(item => {
                    return {
                        ...item,
                        name: item?.display_name,
                        value: item?.instructor_course_id ||  item?.value,
                    }
                })
            })
        }
    }, [data])

    const refreshData = () => {
        courseService.getEnrollmentDetail(params?.id, params?.courseId, data?.id)
            .then(res => {
                dispatch(setEnrollmentSelected({ ...res?.data }));
            })
    }

    const onSubmit = (value) => {
        const payload = {
            instructor_course_ids: value?.instructors?.map(item => item?.value),
        }
        courseService.updateInstructorOfEnrollment(workspaceId, params?.courseId, data?.id, payload)
        .then((res) => {
            if (res.data?.error) {
                return Toastify.error((res.data?.error?.body_params?.[0]?.msg));
            }
            Toastify.success(res.data?.message);
            refreshData();
            onCloseModal();
        })
        .catch((error) => Toastify.error())
    }

    return (
        <form className="flex flex-col gap-4 mt-4">
            <div>
                <SelectBox
                    label={translator('INSTRUCTORS')}
                    name="instructors"
                    placeholder={translator('SELECT_INSTRUCTORS')}
                    data={listInstructors}
                    onSearch={(value) => setInputSearchInstructor(value)}
                    value={watch('instructors')}
                    loading={loadingInstructor}
                    getData={getInstructors}
                    pagination={paginationInstructor}
                    control={control}
                    errors={errors}
                    numberOfItemDisplayed={3}
                    isMulti={true}
                    isRequired
                />
             </div>

            <div className="flex justify-center gap-4 mt-4">
                <ButtonOutline
                    type="Button"
                    onClick={onCloseModal}
                >
                    {translator('CANCEL')}
                </ButtonOutline>
                <ButtonPrimary type="button" onClick={handleSubmit(onSubmit)} disabled={!isValid}>
                    {translator('UPDATE')}
                </ButtonPrimary>
            </div>
        </form>
    );
}
