import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import { classNames } from 'utils/commonFun';
import { genClassNames } from 'utils/handleString';

interface TooltipProps {
    title: string;
    children: React.ReactNode;
    mtClass?: string;
    mlClass?: string;
    type?: 'error' | 'warning';
}

const Tooltip: React.FC<TooltipProps> = ({ title, children, mtClass, mlClass, type }: TooltipProps) => (
    <div className="has-tooltip">
        <span
            className={genClassNames({
                'whitespace-nowrap tooltip shadow-lg text-ooolab_sxs rounded-sub_tab px-3 py-1': true,
                'ml-ooolab_default_tooltip_left': mlClass === undefined,
                'mt-ooolab_default_tooltip_top': mtClass === undefined,
                [mtClass || '']: true,
                [mlClass || '']: true,
                'opacity-0': !title,
                'bg-ooolab_bg_tooltip text-white': !type,
                'bg-ooolab_bg_tooltip_error text-white': type === 'error',
            })}
        >
            {title?.length < 90 ? title : `${title?.slice(0, 91)}... `}
        </span>
        {children}
    </div>
);

export default Tooltip;

type TooltipCustomProps = {
    id: string;
    isDisable?: boolean;
    place?: 'top' | 'right' | 'bottom' | 'left';
    className?: string;
    content: any;
    type?: 'dark' | 'light' | 'error' | 'warning' | 'success' | 'info';
    classNameContainer?: string;
    isDebug?: boolean;
};

// use with "data-for" & "data-tip" in element need show tooltip
export const TooltipCustom: React.FC<TooltipCustomProps> = ({ id, isDisable = false, place = 'bottom', className, content, type='dark', classNameContainer = '', isDebug = false }) => {
    return (
        //@ts-ignore
        <ReactTooltip
            place={place}
            type={type}
            effect="float"
            id={id}
            disable={isDisable}
            className={`tooltips_custom-container !opacity-100 ${classNameContainer}`}
            delayHide={isDebug && 999999}
            // getContent={(content) => <div className={classNames('w-auto h-auto', className)}>{content}</div>}
        >
            <div className={classNames('w-auto h-auto', className)}>{content}</div>
        </ReactTooltip>
    );
};

