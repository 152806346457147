import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { timeList } from "constant/workspace.const";
import classesService from "services/reports/classes-report.service";
import { CardContainer } from "components/Reports/components/CardContainer";
import { Title } from "components/Reports/components/Title";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import ChartDonut from "components/Reports/components/ChartDonut/ChartDonut";
import { ComboboxSelect } from "components/ComboboxSelect/ComboboxSelect";
import { EmptyStateChart } from "_shared";
import DatePickerComponent from "components/Reports/components/DatePicker";
import moment from "moment";

export const ClassSessionBreakdown = () => {
    const { t } = useTranslation();
    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);
    const params: { id: string, classId: string } = useParams();
    const [loading, setLoading] = useState(true);
    const [breakdownData, setBreakdownData] = useState<any>();
    const [schedulingData, setSchedulingData] = useState<any>();
    const [selectSchedulingDate, setSelectSchedulingDate] = useState(timeList[1]);
    const [noDataBreakdown, setNoDataBreakdown] = useState(false);
    const [noDataScheduling, setNoDataScheduling] = useState(false);

    const getData = async () => {
        if (!startDate || !endDate) {
            return;
        }
        try {
            setLoading(true);
            setNoDataBreakdown(false);
            const payload = {
                start_time: moment(new Date(startDate)).format('YYYY-MM-DD')+ 'T00:00:00.000',
                end_time: moment(new Date(endDate)).format('YYYY-MM-DD')+ 'T23:59:59.000',
                classId: params?.classId,
            }
            const response = await classesService.getClassSessionsSchedulingById(params.id, payload);
            setBreakdownData(response?.data);
            setLoading(false);
        } catch {
            setLoading(false);
            setNoDataBreakdown(true);
        }
    }

    const getSchedulingData = async () => {
        if (!startDateType || !endDateType) {
            return;
        }
        try {
            setNoDataScheduling(false);
            const payload = {
                start_time: moment(new Date(startDateType)).format('YYYY-MM-DD')+ 'T00:00:00.000',
                end_time: moment(new Date(endDateType)).format('YYYY-MM-DD')+ 'T23:59:59.000',
                classId: params?.classId,
            }
            setSchedulingData([]);
            const response = await classesService.getClassSessionsTypeById(params.id, payload);
            setSchedulingData(response?.data || []);
        } catch {
            setNoDataScheduling(true);
        }
    }

    const chartData = useMemo(() => {
        const data = [
            breakdownData?.enrolled,
            breakdownData?.rescheduled,
            breakdownData?.cancelled
        ];
        const total = data.reduce((total, value) => total + value, 0);
        setNoDataBreakdown(total == 0);
        if (total > 0) {
            return data;
        }
        return [];
    }, [breakdownData]);

    const chartDataScheduling = useMemo(() => {
        const data = [
            schedulingData?.scheduled,
            schedulingData?.admin_booking,
            schedulingData?.learner_booking
        ];
        const total = data?.reduce((total, value) => total + value, 0);
        setNoDataScheduling(total == 0);
        if (total > 0) {
            return data;
        }
        return [];
    }, [schedulingData]);
    const [startDate, setStartDate] = useState(new Date(moment().subtract(1, 'months').format('MM-DD-YYYY')));
    const [endDate, setEndDate] = useState(new Date());
    const [startDateType, setStartDateType] = useState(new Date(moment().subtract(1, 'months').format('MM-DD-YYYY')));
    const [endDateType, setEndDateType] = useState(new Date());
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
    const onChangeType = (dates) => {
        const [start, end] = dates;
        setStartDateType(start);
        setEndDateType(end);
    }

    useEffect(() => {
        getData();
    }, [startDate, endDate]);

    useEffect(() => {
        getSchedulingData();
    }, [startDateType, endDateType])

    return <>
        <div className="w-1/2 min-h-[332px]">
            <CardContainer className="h-full">
                <>
                    <div className="flex flex-col lg:flex-row lg:items-center text-left">
                        <Title>{translator('Session Breakdown')}</Title>
                        <div className="flex-none lg:ml-auto min-w-[132px] whitespace-nowrap">
                            <DatePickerComponent onChange={onChange} startDate={startDate} endDate={endDate} />
                        </div>
                    </div>
                    {loading ?
                        <div className="flex justify-center items-center w-full min-h-[252px]">
                            <CircleSpin color="text-primary-500" />
                        </div> :
                        <div className="flex items-center">
                            {
                                !noDataBreakdown ?
                                    <ChartDonut data={chartData} label={t("SESSION.SESSIONS")}
                                        dataLabel={[
                                            { status: 'completed' },
                                            { status: 'rescheduled' },
                                            { status: 'cancelled' }
                                        ]} /> :
                                    <div className="min-h-[252px] w-full flex items-center justify-center">
                                        <EmptyStateChart />
                                    </div>
                            }
                        </div>
                    }
                </>
            </CardContainer>
        </div>
        <div className="w-1/2">
            <CardContainer className="h-full">
                <div className="flex flex-col lg:flex-row lg:items-center text-left">
                    <Title>{translator('Session Type')}</Title>
                    <div className="flex-none lg:ml-auto min-w-[132px] whitespace-nowrap">
                        <DatePickerComponent onChange={onChangeType} startDate={startDateType} endDate={endDateType} />
                    </div>
                </div>
                <div className="flex items-center">
                    {!noDataScheduling ?
                        <ChartDonut data={chartDataScheduling} label={t("SESSION.SESSIONS")}
                        dataLabel={[
                            { status: 'scheduled' },
                            { status: 'admin_booking' },
                            { status: 'learner_booking' }
                        ]} 
                        colors={['#22C55E', '#3B82F6', '#F59E0B']} /> :
                        <div className="min-h-[252px] w-full flex items-center justify-center">
                            <EmptyStateChart />
                        </div>
                    }
                </div>
            </CardContainer>
        </div>
    </>
}