import { CloseIcon } from 'assets/icon/CloseIcon';
import { DownloadIcon } from 'assets/icon/DownloadIcon';
import { EditIcon } from 'assets/icon/EditIcon';
import H5PIcon from 'assets/icon/H5PIcon';
import { PresentationIcon } from 'assets/icon/PresentationIcon';
import { H5P_LIBRARY } from 'constant/h5p.const';
import dayjs from 'dayjs';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TrashIcon } from '@heroicons/react/outline';
import { useHistory, useParams } from 'react-router-dom';
import DuplicateAction from 'components/Library/TabLibrary/Modals/DuplicateAction';
import ModalShareLink from 'components/V2/Modals/ModalShareLink';
import ActionsButton from 'components/ActionsButton';
import { MoveIcon, CopyFileIcon, ShareIcon, TextIcon } from 'assets/icon';
import { TagList } from '_shared/';
import './H5pInformation.scss';
import ModalMoveTo from 'components/Library/TabLibrary/Modals/ModalMoveTo/ModalMoveTo';
import LocationDetails from 'components/Library/components/LocationDetails/LocationDetails';
import ModalRename from 'components/Library/TabLibrary/Modals/ModalRename';
import { LibraryTypeEnum } from 'components/Library/LibraryContants';
import { checkPermission } from 'utils/commonFun';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';

interface IInformation {
    type: string;
    isEdit?: boolean;
    onDownload?: Function;
    setCustomFields: any;
    userInfo: any;
    onClose: any;
    h5pData?: any;
    onEdit?: any;
    customFields: any;
    onDelete?: any;
    refreshData?: any;
    className?: string;
    classNameContainer?: string;
}

export default function H5pInformation({
    type,
    isEdit,
    onDownload,
    customFields,
    userInfo,
    setCustomFields,
    onClose,
    h5pData,
    onEdit,
    onDelete,
    refreshData,
    className,
    classNameContainer
}: IInformation) {
    const { t } = useTranslation();
    const params: any = useParams();
    const history = useHistory();
    const [isOpenModalMoveFile, setOpenModalMoveFile] = useState(false);
    const [isOpenModalDuplicate, setOpenModalDuplicate] = useState(false);
    const [openModalShareLink, setOpenModalShareLink] = useState(false);
    const [isOpenModalRename, setOpenModalRename] = useState(false);

    const { getWorkspaceDetailState: { featureFlags } } = useContext(GetWorkspaceContext);

    const arrayLocation = useMemo(() => {
        let arrFormat = [];
        const currentFolder = {
            name: h5pData?.title,
            classNames: '',
        };
        if (h5pData?.path?.length) {
            h5pData?.path?.map((item) => {
                const format = {
                    name: item?.name,
                    classNames: '',
                };
                arrFormat.push(format);
            });
        } else if (h5pData?.id) {
            arrFormat.push(currentFolder);
        }
        return arrFormat;
    }, [h5pData?.path]);

    return (
        <div className={`h5p-information ${className}`}>
            <div className="header">
                <div className="header-title">
                    <H5PIcon />
                    <div className="title">{h5pData?.title || t('DASHBOARD.H5P_CONTENTS.NEW_H5P')} </div>
                </div>
                <div className="action">
                    {type === 'edit' && (
                        <>
                            {checkPermission('libraryContentFile', 'U') ? <div onClick={() => onEdit?.()}>
                                <EditIcon />
                            </div> : ''}
                            {checkPermission('libraryContentFile', 'R') ? <div
                                onClick={() =>
                                    window.open(`/workspace/${params?.id}/h5p-content/${h5pData?.contentId}/preview`, '_blank')
                                }
                            >
                                <PresentationIcon />
                            </div> : ''}
                            {checkPermission('libraryContentFile', 'DL') && featureFlags?.systemShareLink ? <div onClick={() => onDownload?.()}>
                                <DownloadIcon />
                            </div> : ''}
                            {checkPermission('libraryContentFile', 'D') ? <div onClick={() => onDelete?.()} className="">
                                <TrashIcon className="action-icon" />
                            </div> : ''}

                            <ActionsButton
                                classNameIcon="p-0 w-6 h-6"
                                className="hover:bg-none p-0 w-4 border-r border-gray-300 rounded-none"
                                menus={[
                                    {
                                        label: t('RENAME'),
                                        icon: <TextIcon />,
                                        action: () => setOpenModalRename(true),
                                        hide: !checkPermission('libraryContentFile', 'U'),
                                        isDisabled: !checkPermission('libraryContentFile', 'U'),
                                    },
                                    {
                                        label: t('MOVE_TO'),
                                        icon: <MoveIcon />,
                                        action: () => setOpenModalMoveFile(true),
                                        hide: !checkPermission('libraryContentFile', 'U'),
                                        isDisabled: !checkPermission('libraryContentFile', 'U'),
                                    },
                                    {
                                        label: t('DUPLICATE'),
                                        icon: <CopyFileIcon />,
                                        action: () => setOpenModalDuplicate(true),
                                        hide: !checkPermission('libraryContentFile', 'DC'),
                                        isDisabled: !checkPermission('libraryContentFile', 'DC'),
                                    },
                                    {
                                        label: t('SHARE'),
                                        icon: <ShareIcon />,
                                        action: () => setOpenModalShareLink(true),
                                        hide: !checkPermission('libraryContentFile', 'R') || !featureFlags?.systemShareLink,
                                        isDisabled: !checkPermission('libraryContentFile', 'R'),
                                    },
                                ]}
                            />
                        </>
                    )}
                    <div onClick={onClose} className="">
                        <CloseIcon />
                    </div>
                </div>
            </div>
            <div className={`h5p-information-container ${classNameContainer}`}>
                <div className="detail">
                    <div className="header">
                        <div className="label">{t('LIBRARY.DETAILS')}</div>
                        <div className="line"></div>
                    </div>
                    {type == 'edit' && (
                        <>
                            <div className="item">
                                <div className="item-label">{t('LIBRARY.H5P_TYPE')}:</div>
                                <div className="item-value">
                                    <div className="value">{H5P_LIBRARY[h5pData?.mainLibrary?.replace('H5P.', '')]}</div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-label">{t('LIBRARY.LOCATION')}:</div>
                                <div className="item-value">
                                    <div className="value">
                                        <LocationDetails
                                            NameDefault={t('DASHBOARD.SIDEBAR.LIBRARY')}
                                            arr={arrayLocation || []}
                                            containerClassName="z-10 sticky top-0"
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="item">
                        <div className="item-label">{t('LIBRARY.OWNER')}:</div>
                        <div className="item-value">
                            <img className="avatar" src={h5pData?.created_by?.avatar_url || userInfo?.avatar_url} />
                            <div className="value">{h5pData?.created_by?.display_name || userInfo?.display_name}</div>
                        </div>
                    </div>
                    {type == 'edit' && (
                        <>
                            <div className="item">
                                <div className="item-label">{t('LIBRARY.LAST_MODIFIED')}:</div>
                                <div className="item-value">
                                    <div className="value">
                                        {h5pData.updated_at ? dayjs(h5pData.updated_at).format('MMM DD, YYYY') : '_'}
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="item-label">{t('LIBRARY.CREATED')}:</div>
                                <div className="item-value">
                                    <div className="value">
                                        {h5pData.created_at ? dayjs(h5pData.created_at).format('MMM DD, YYYY') : '_'}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="h5p-information-tags">
                    <TagList setCustomFields={setCustomFields} customFields={customFields} isEdit={true} />
                </div>
            </div>

            {isOpenModalDuplicate && (
                <DuplicateAction
                    onClose={() => setOpenModalDuplicate(false)}
                    refreshData={() => {
                        history.push(`/workspace/${params?.id}/library`);
                    }}
                    item={{ object_id: h5pData?.contentId, object_type: LibraryTypeEnum.h5p }}
                    isMulti={false}
                />
            )}
            {openModalShareLink ? (
                <ModalShareLink
                    isOpen={openModalShareLink}
                    onClose={() => setOpenModalShareLink(false)}
                    link={''}
                    value={{ ...h5pData, workspaceId: params?.id, object_type: LibraryTypeEnum.h5p, name: h5pData?.title }}
                />
            ) : (
                ''
            )}

            {isOpenModalMoveFile && (
                <ModalMoveTo
                    isOpen={isOpenModalMoveFile}
                    onClose={() => setOpenModalMoveFile(false)}
                    arrItem={[{ object_id: h5pData?.contentId, object_type: LibraryTypeEnum.h5p }]}
                    refreshData={refreshData}
                />
            )}

            <ModalRename
                isOpen={isOpenModalRename}
                onClose={() => setOpenModalRename(false)}
                refreshData={refreshData}
                item={{ object_id: h5pData?.contentId, object_type: LibraryTypeEnum.h5p, name: h5pData?.title }}
            />
        </div>
    );
}
