import React from "react";
import EmptyIcon from 'assets/img/empty-state/instructor.png';
import { useTranslation } from "react-i18next";
export const BookingInstructorEmpty = () => {
    const { t } = useTranslation();

    return <>
        <div className="flex min-h-[calc(100vh-460px)] rounded-2xl bg-white text-center items-center justify-center">
        <div>
            <img src={EmptyIcon} className="w-[211px] mx-auto" />
            <p className="text-gray-700 text-base font-normal mt-3">{t('COURSES.NO_BOOKING_SESSIONS')}</p>
        </div>
    </div>
    </>
}