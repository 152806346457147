// @ts-nocheck
import { FC, useMemo } from 'react';
import Table from 'components/Management/components/table';
import { classNames } from 'utils/commonFun';
import ReactTooltip from 'react-tooltip';

interface TableApoProps {
    dataTable: any;
    translator: (key: string) => string;
}

const TableApo: FC<TableApoProps> = ({ dataTable, translator }) => {
    const RenderTableHeader = ({
        label,
        classes,
    }: {
        label: string;
        classes?: string;
    }) => (
        <p
            className={classNames(
                'pl-2 text-sm text-ooolab_dark_1 leading-6 font-semibold text-left',
                classes
            )}
        >
            {translator(label)}
        </p>
    );

    const RenderTableCell = ({
        value,
        classes = 'text-ooolab_dark_1 font-normal',
    }: {
        value: string;
        classes?: string;
    }) =>
        !!value ? (
            <p
                className={classNames(
                    'pl-2 text-sm leading-6 text-left py-2 truncate',
                    classes
                )}
            >
                {value}
            </p>
        ) : null;

    const initialState = {
        hiddenColumns: ['id', 'avatar_url'],
    };

    const columns = useMemo(
        () => [
            { accessor: 'id' },
            { accessor: 'avatar_url' },
            {
                Header: () => (
                    <RenderTableHeader label="STUDENT_NAME" classes="pl-11" />
                ),
                Cell: ({ row }) => (
                    <div className="pl-2 flex items-center space-x-1">
                        <div className="w-7 flex justify-center">
                            <img
                                className="rounded-full w-6 h-6"
                                src={row.values.avatar_url}
                                alt={'avatar' + row.values.id}
                            />
                        </div>
                        <div className="w-full truncate">
                            <p
                                className="pl-2 text-sm leading-6 text-left py-2 text-ooolab_dark_1 font-normal truncate"
                                data-tip={row.values.display_name}
                                data-for={row.values.id + 'display_name'}
                            >
                                {row.values.display_name}
                                {row?.original?.nick_name && ` / ${row?.original?.nick_name}`}
                            </p>
                            <ReactTooltip
                                place="bottom"
                                type="dark"
                                effect="float"
                                id={row.values.id.toString() + 'display_name'}
                                getContent={(content) => (
                                    <div className="w-auto h-auto">
                                        {content}
                                    </div>
                                )}
                                disable={row.values.display_name?.length < 30}
                            />
                        </div>
                    </div>
                ),
                accessor: 'display_name',
                width: 150,
            },
            {
                Header: () => <RenderTableHeader label="AVAILABLE_COINS" />,
                Cell: ({ row }) => (
                    <RenderTableCell
                        value={row.values.available_points}
                        classes="text-primary-500 font-semibold"
                    />
                ),
                accessor: 'available_points',
                width: 80,
            },
            {
                Header: () => <RenderTableHeader label="COINS_RECEIVED" />,
                Cell: ({ row }) => (
                    <RenderTableCell value={row.values.total_points} />
                ),
                accessor: 'total_points',
                width: 80,
            },
            {
                Header: () => <RenderTableHeader label="LEVEL" />,
                Cell: ({ row }) => (
                    <RenderTableCell value={row.values.current_level?.name} />
                ),
                accessor: 'current_level',
                width: 80,
            },
            {
                Header: () => null,
                Cell: () => null,
                accessor: 'hidden_colum1',
                width: 100,
            },
            {
                Header: () => null,
                Cell: () => null,
                accessor: 'hidden_colum2',
                width: 100,
            },
            {
                Header: () => null,
                Cell: () => null,
                accessor: 'hidden_colum3',
                width: 100,
            },
        ],
        []
    );

    return (
        <Table
            data={dataTable}
            columns={columns}
            initialState={initialState}
            isBorderFirstColum={false}
        />
    );
};

export default TableApo;
