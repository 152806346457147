import * as React from 'react';
import './Tooltip.scss';

interface TooltipProps {
    info: any;
    children: React.ReactNode;
    styles?: string;
}

export const Tooltip: React.FC<TooltipProps> = ({ info, children, styles }: TooltipProps) => (
    <div className="tooltipContainer">
        <span className={`tooltipContainer-info ${styles}`}>{info}</span>
        {children}
    </div>
);

