import LearningPathList from 'components/Academics/LearningPath/LearningPathList/LearningPathList';
import ManagementMasterPage from 'pages/RouteMasterPage';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';

const LearningPathListPage = () => {
    return (
        <ManagePermissionWrapper>
            <LearningPathList>
                <ManagementMasterPage />
            </LearningPathList>
        </ManagePermissionWrapper>
    );
};

export default LearningPathListPage;
