import React from 'react'

export default function SendIcon({className = ''}) {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6818_15199)">
      <path d="M18.3333 16.6725C18.3318 16.8914 18.2443 17.1008 18.0896 17.2557C17.9349 17.4105 17.7255 17.4983 17.5067 17.5H2.49333C2.27401 17.4998 2.06375 17.4125 1.90874 17.2573C1.75374 17.1022 1.66667 16.8918 1.66667 16.6725V15.8333H16.6667V6.08333L10 12.0833L1.66667 4.58333V3.33333C1.66667 3.11232 1.75446 2.90036 1.91074 2.74408C2.06702 2.5878 2.27899 2.5 2.5 2.5H17.5C17.721 2.5 17.933 2.5878 18.0893 2.74408C18.2455 2.90036 18.3333 3.11232 18.3333 3.33333V16.6725ZM3.695 4.16667L10 9.84167L16.305 4.16667H3.695ZM0 12.5H6.66667V14.1667H0V12.5ZM0 8.33333H4.16667V10H0V8.33333Z" fill="currentColor"/>
      </g>
      <defs>
      <clipPath id="clip0_6818_15199">
      <rect width="20" height="20" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}
