import { Popover } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, SearchIcon, XIcon } from '@heroicons/react/outline';
import './SelectBox.scss';
import SearchBox from 'components/V2/SearchBox';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ErrorMessageForm from 'components/ErrorMessageForm';
import { CircleInformationIcon } from 'assets/icon';
import ReactTooltip from 'react-tooltip';
import EmptyStateImg from 'assets/img/empty-state/empty-stage-option.png';

interface ISelectBox {
    onSearch?: (value: string) => void;
    data?: any[];
    loading?: boolean;
    getData?: (page) => void;
    type?: string;
    isDisabled?: boolean;
    isMulti?: boolean;
    pagination?: {
        total: number,
        page: number
    };
    control: any;
    name: string;
    errors?: any;
    isRequired?: boolean;
    value: any[]
    label?: string;
    placeholder?: string
    tooltipLabel?: string
    numberOfItemDisplayed: number
    isOpen?: boolean,
    isShowNumOfSelected?: boolean
    formatOptionLabel?: any
    formatOptionValue?: any
    heightPanel? : string
    emptyState?: any
    placeholderSearchInput?: string
    actionAfterRemoveItemSelected?: Function
    actionAfterSelectItem?: Function
    actionAfterRemoveAllItem?: Function,
    isShowSearchIcon?: boolean
    listItemBlocked?: any[]
    className?: string,
    onClick?: () => void
}


export default function SelectBox({
    onSearch,
    data,
    loading = false,
    type,
    isDisabled = false,
    isMulti = true,
    pagination,
    getData,
    errors,
    isRequired,
    control,
    name,
    value = [],
    placeholder,
    label,
    tooltipLabel,
    numberOfItemDisplayed = 3,
    isOpen = false,
    isShowNumOfSelected = false,
    formatOptionLabel = false,
    formatOptionValue = false,
    heightPanel,
    emptyState,
    placeholderSearchInput,
    actionAfterRemoveItemSelected,
    actionAfterSelectItem,
    actionAfterRemoveAllItem,
    isShowSearchIcon = false,
    listItemBlocked = [],
    className='',
    onClick
}: ISelectBox) {
    const { t: translator } = useTranslation();
    const handleCheck = (selected) => {
        const index = value?.findIndex((item) => item?.value === selected?.value);
        let newSelectedItems = [];
        if (index !== -1) {
            newSelectedItems = value?.filter((item) => item?.value !== selected?.value);
            actionAfterRemoveItemSelected?.(selected, index)
        } else {
            if (!isMulti) {
                actionAfterSelectItem?.(selected)
                return [selected];
            }
            newSelectedItems = [...value, selected]
            actionAfterSelectItem?.(selected)
        };
        return newSelectedItems;
    };
    const handleClearSelection = () => {
        actionAfterRemoveAllItem?.();
        return [];
    };

    const defaultOptionLabel = (item) => {
        return (
            <div className="font-normal text-sm text-gray-800 flex gap-1 items-center text-ellipsis truncate mr-4 w-full">
                {item?.avatar_url && <img className="w-5 h-5 rounded-full" src={item?.avatar_url} />}
                <span className={`text-ellipsis truncate ${item?.email ? 'max-w-[200px]' : ''}`}>{item?.name}</span>
                {item?.email && (
                    <>
                    (
                        <span className="text-gray-500 text-ellipsis truncate max-w-[200px]">
                            {item?.email}
                        </span>
                    )
                    </>
                )}
            </div>
        )
    }

    return (
        <>
            {label && 
                <span className='flex gap-1 items-center text-xs font-semibold mb-1'>
                    {label} {isRequired && <span className='text-red-500'>*</span>}
                    {tooltipLabel && <span data-for={`tooltip-${name}`} data-tip={tooltipLabel} ><CircleInformationIcon height="10.5" width="10.5" fill="#1F2937" /></span>}
                </span>
            }
            {/* @ts-ignore*/
                <ReactTooltip
                    place="right"
                    type="light"              
                    id={`tooltip-${name}`}
                    getContent={(content) => <div className="max-w-[280px] text-left text-xs font-normal p-1">{content}</div>}
                    className='shadow-lg opacity-100'
                />
            }
            {/* @ts-ignore */}
            <Controller
                rules={{
                    required: {
                        value: isRequired,
                        message: translator('FORM_CONST.REQUIRED_FIELD'),
                    },
                }}
                control={control}
                name={name}
                render={({ field }) => (
                    <Popover className="relative w-full">
                        {({ open }) => (
                            <>
                                <Popover.Button 
                                    onClick={() => {
                                        onSearch?.('');
                                        console.log("click")
                                        onClick?.();
                                    }} 
                                    disabled={isDisabled} 
                                    className={`selectBox--button bg-white`}
                                >
                                    <div className={`ItemSelected ${value?.length ? 'hasItem' : ''} ${className? className: ''}`}>
                                        <div className='flex gap-2 items-center h-full'>
                                            {isShowSearchIcon &&<SearchIcon className='w-4 h-4 text-gray-500' />}
                                            <div className={`flex gap-2 items-center h-full ${value?.length ? 'hasItem' : ''}`}>
                                                {value?.length > 0 ? (
                                                    <div className={`ListItem`}>
                                                        {value?.slice(0, numberOfItemDisplayed)?.map((item) => {
                                                            const isBlockedItem = listItemBlocked?.find(el => item?.id === el.id)
                                                            if (!isMulti) {
                                                                return (
                                                                    <div className='w-full flex gap-1 items-center'>
                                                                        {
                                                                            formatOptionValue ? formatOptionValue(item)
                                                                            : 
                                                                            <>
                                                                                {item?.avatar_url && <img className="item-avatar" src={item?.avatar_url} />}
                                                                                <span className="text-ellipsis truncate text-sm">{item?.name}</span>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                            return (
                                                                <div className="item" key={item?.id}>
                                                                    {
                                                                        formatOptionValue ? formatOptionValue(item)
                                                                        : 
                                                                        <>
                                                                            {item?.avatar_url && <img className="item-avatar" src={item?.avatar_url} />}
                                                                            <span className="text-ellipsis truncate max-w-[80px] text-sm">{item?.name}</span>
                                                                        </>
                                                                    }
                                                                    {!isBlockedItem && (
                                                                        <XIcon
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                field.onChange(handleCheck(item));
                                                                            }}
                                                                            className="item-icon"
                                                                        />
                                                                    )}
                                                                </div>
                                                            );
                                                        })}
                                                        {value?.length > numberOfItemDisplayed && type !== 'show-all' && (
                                                            <div className="item !h-[34px] w-fit flex items-center justify-center">
                                                            +{value?.length - numberOfItemDisplayed}...
                                                        </div>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <span className="text-gray-400 text-xs">{placeholder}</span>
                                                )}
                                            </div>
                                        </div>
                                        {value?.length && type !== 'show-all' ? (
                                            <XIcon
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (listItemBlocked?.length) field.onChange(listItemBlocked);
                                                    else field.onChange(handleClearSelection());
                                                }}
                                                className="w-5 h-5 text-gray-500"
                                            />
                                        ) : (
                                            <ChevronDownIcon className="w-5 h-5 text-gray-500" />
                                        )}
                                    </div>
                                </Popover.Button>
                                {(isOpen || open) && <Popover.Panel static className={`absolute rounded z-10 bg-gray-100 border borrer-gray-300 w-full shadow-md top-11 left-0 overflow-hidden`}>
                                    <div>
                                        {onSearch && (
                                            <div className="p-2 bg-gray-100">
                                                <SearchBox
                                                    placeholder={placeholderSearchInput || translator('Search a name')}
                                                    className="min-w-full text-xs max-w-md bg-white border !border-gray-300"
                                                    onSubmit={(value) => onSearch?.(value)}
                                                />
                                            </div>
                                        )}
                                        {/* @ts-ignore */}
                                        <InfiniteScroll
                                            dataLength={data?.length || 0}
                                            next={() => data?.length < pagination?.total && getData(pagination?.page + 1)}
                                            hasMore={data?.length !== pagination?.total}
                                            loader={
                                                loading && (
                                                    <div className="tableLibrary-loading">
                                                        <CircleSpin color="text-primary-500" />
                                                    </div>
                                                )
                                            }
                                            height={heightPanel || 'max-h-[220px]'}
                                            className={`overflow-auto flex flex-col ${heightPanel ? `max-h-[${heightPanel}] h-[${heightPanel}]` : 'max-h-[220px]'}`}
                                        >
                                            {loading && !data?.length && (
                                                <div className='py-4 h-full w-full flex justify-center items-center'>
                                                    <CircleSpin color="text-primary-500" />
                                                </div>
                                            )}
                                            {data?.map((item, index) => {
                                                return (
                                                    <div
                                                        onClick={() => {
                                                            const isBlockedItem = listItemBlocked?.find(el => item?.id === el.id)
                                                            if (isBlockedItem) return;
                                                            field.onChange(handleCheck(item))
                                                        }}
                                                        key={index}
                                                        className={`selectBox-checkbox flex justify-between text-xs relative
                                                            ${
                                                                !!value?.find((selectedItem) => selectedItem?.value === item?.value)
                                                                    ? 'selectBox-checkbox--checked'
                                                                    : ''
                                                            }
                                                            ${item?.disabled ? 'selectBox-checkbox--disabled' : ''}`}
                                                    >
                                                        {
                                                            formatOptionLabel ? formatOptionLabel(item) : defaultOptionLabel(item)
                                                        }
                                                        {!!value?.find((selectedItem) => selectedItem?.value === item?.value) && (
                                                            <CheckIcon className="absolute right-2 min-w-[20px] w-5 h-5 text-primary-500" />
                                                        )}
                                                    </div>
                                                );
                                            })}
                                            {!loading && !data?.length && (
                                                <div className='flex flex-col justify-center items-center h-full py-5'>
                                                    <img className='w-32' src={emptyState?.img || EmptyStateImg} />
                                                    <div className="text-sm text-gray-400">{emptyState ? emptyState?.message : 'No Data'}</div>
                                                </div>
                                            )}
                                        </InfiniteScroll>
                                    {value?.length > 0 && isShowNumOfSelected && <div className='text-xs text-right py-2 mr-4 text-gray-500'>{value?.length} learner(s) selected</div>}
                                    </div>
                                </Popover.Panel>}
                            </>
                        )}
                        
                    </Popover>
                )}
            />
            {!!errors && <ErrorMessageForm errors={errors} name={name} />}
        </>
    );
}
