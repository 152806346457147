import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';

const Progress = ({ percentage = 0, error, size='4'}) => {
    if (percentage === 100) {
        return <div></div>
    }
    if (error) {
        return (
            <div className="text-xs text-red-500">
                {error}
            </div>
        );
    }
    return (
        <div className={`w-${size} h-${size}`}>
            <CircularProgressbarWithChildren
                value={percentage}
                strokeWidth={8}
                styles={buildStyles({
                    strokeLinecap: 'butt',
                    pathColor: '#2576B9',
                    trailColor: '#C7C9D9',
                })}
            ></CircularProgressbarWithChildren>
        </div>
    );
};

export default Progress;