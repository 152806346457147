import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import ButtonComponent from 'components/Button/ButtonComponent';
import ErrorMessageForm from 'components/ErrorMessageForm';
import {optionFailedGoal} from './constant'
import { TooltipCustom } from 'components/Tooltip/Tooltip';
import {  InformationCircleIcon  } from '@heroicons/react/outline';
import ReactTooltip from 'react-tooltip';
interface ModalUpdateRecordOfWorkProps {
    data: any;
    translator: (key: string) => string;
    handleCloseModal: (values: any) => void;
}

const ModalUpdateRecordOfWork: FC<ModalUpdateRecordOfWorkProps> = ({
    data,
    translator,
    handleCloseModal,
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        setValue,
    } = useForm();

    useEffect(() => {
        const defaultFailedGoal = optionFailedGoal?.filter(option => data?.failed_goals?.includes(option?.value))
        if (data) {
            setValue('learning_materials_used', data?.learning_materials_used);
            setValue('ped_notes', data?.ped_notes);
            setValue('instructor_notes', data?.instructor_notes);
            setValue('failed_goals', defaultFailedGoal)
        }
        ReactTooltip.rebuild()
    }, [data]);

    const onSubmitForm = (values: any) => {
        handleCloseModal({
            learning_materials_used: values?.learning_materials_used || null,
            ped_notes: values?.ped_notes || null,
            instructor_notes: values?.instructor_notes || null,
            failed_goals: values?.failed_goals?.map(option => option?.value)
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="border-t border-b py-6 border-t-gray-300 border-b-gray-300 px-5">
                <div>
                    <div className='flex items-center'>
                        <label
                            htmlFor="learning_materials_used"
                            className="block text-ooolab_dark_1 font-semibold text-sm text-center mr-1"
                        >
                            {translator('LEARNING_MATERIALS_USED')} 
                        </label>
                        <InformationCircleIcon  
                            width='14' 
                            height='14' 
                            data-for="LEARNING_MATERIALS_USED_TOOLTIP"
                            data-tip={translator('LEARNING_MATERIALS_USED_TOOLTIP')}
                        />
                        <TooltipCustom
                            className='z-9999'
                            id="LEARNING_MATERIALS_USED_TOOLTIP"
                            content={translator('LEARNING_MATERIALS_USED_TOOLTIP')}
                            place="bottom"
                            type="dark"
                        />
                    </div>
                    
                    <textarea
                        id="learning_materials_used"
                        rows={4}
                        cols={50}
                        placeholder={translator('LEARNING_MATERIALS_USED')}
                        className="w-full border rounded border-ooolab_gray_6 px-3 py-2 mt-1 text-sm font-normal text-ooolab_dark_1 outline-blue-200"
                        {...register('learning_materials_used', {
                            maxLength: {
                                value: 200,
                                message: translator('MAX_LENGTH'),
                            },
                        })}
                    />
                    <ErrorMessageForm errors={errors} name="learning_materials_used" />
                </div>
               
                <div className='mt-5'>
                    <div className='flex items-center'>
                        <label
                            htmlFor="ped_comments"
                            className="block text-ooolab_dark_1 font-semibold text-sm text-center mr-1"
                        >
                            {translator('INSTRUCTOR_FEEDBACK')} 
                        </label>
                        
                        <InformationCircleIcon  
                            width='14' 
                            height='14' 
                            data-for="INSTRUCTOR_FEEDBACK_TOOLTIP"
                            data-tip={translator('INSTRUCTOR_FEEDBACK_TOOLTIP')}
                        />
                        <TooltipCustom
                            className='z-9999'
                            id="INSTRUCTOR_FEEDBACK_TOOLTIP"
                            content={translator('INSTRUCTOR_FEEDBACK_TOOLTIP')}
                            place="bottom"
                            type="dark"
                        />
                    </div>
                    <textarea
                        id="ped_notes"
                        rows={4}
                        cols={50}
                        placeholder={translator('INSTRUCTOR_FEEDBACK')}
                        className="w-full border rounded border-ooolab_gray_6 px-3 py-2 mt-1 text-sm font-normal text-ooolab_dark_1 outline-blue-200"
                        {...register('ped_notes', {
                            maxLength: {
                                value: 200,
                                message: translator('MAX_LENGTH'),
                            },
                        })}
                    />
                    <ErrorMessageForm errors={errors} name="ped_notes" />
                </div>

                <div className='mt-5'>
                    <div className='flex items-center'>
                        <label
                            htmlFor="instructor_notes"
                            className="block text-ooolab_dark_1 font-semibold text-sm text-center mr-1"
                        >
                            {translator('NOTES_FOR_CO_TEACHER')} 
                        </label>
                        <InformationCircleIcon  
                            width='14' 
                            height='14' 
                            data-for="NOTES_FOR_CO_TEACHER_TOOLTIP"
                            data-tip={translator('NOTES_FOR_CO_TEACHER_TOOLTIP')}
                        />
                        <TooltipCustom
                            className='z-9999'
                            id="NOTES_FOR_CO_TEACHER_TOOLTIP"
                            content={translator('NOTES_FOR_CO_TEACHER_TOOLTIP')}
                            place="bottom"
                            type="dark"
                        />
                        
                    </div>
                    <textarea
                        id="instructor_notes"
                        rows={4}
                        cols={50}
                        placeholder={translator('NOTES_FOR_CO_TEACHER')}
                        className="w-full border rounded border-ooolab_gray_6 px-3 py-2 mt-1 text-sm font-normal text-ooolab_dark_1 outline-blue-200"
                        {...register('instructor_notes', {
                            maxLength: {
                                value: 200,
                                message: translator('MAX_LENGTH'),
                            },
                        })}
                    />
                    <ErrorMessageForm errors={errors} name="instructor_notes" />
                </div>
            </div>
            <div className="mt-9 text-center">
                <ButtonComponent
                    title={translator('UPDATE')}
                    type="submit"
                    disable={!isDirty}
                    classStyle="bg-primary-500 text-base px-3 py-2 text-white !rounded-lg"
                />
            </div>
        </form>
    );
};

export default ModalUpdateRecordOfWork;
