
import React, { useState } from "react";
import { Disclosure } from "@headlessui/react";
import "./Tasks.scss";
import { LIBRARY_ICONS } from "components/Library/LibraryContants";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { TASKS_STATUS } from "components/GradeSubmission/AssignmentGrade/AssignmentGrade";
import { EyeIcon } from "@heroicons/react/outline";
import { hasCheckedStream } from "constant/file.constant";
import { VideoPreview } from "_shared/components/DocumentPreview/VideoPreview";

export const NormalTask = ({ task, addFeedback, tabStatus }) => {
    const { t: translator } = useTranslation();
    const [hasOpenFile, setHasOpenFile] = useState(false);
    const [urlPlay, setUrlPlay] = useState('');
    return <div className="tasks">
        <Disclosure>
            {({ open }) => (
                <>
                    <Disclosure.Button className="tasks-header">
                        <div className="tasks-header-name">
                            <span className="tasks-icon">
                                <img
                                    alt="libary-icon"
                                    className="assigmentBuilderPreview-icon"
                                    src={task?.object_type ?
                                        LIBRARY_ICONS(translator)?.find((item) => item?.value === task?.object_type)?.icon :
                                        LIBRARY_ICONS(translator)[0].icon
                                    }
                                />
                            </span>
                            <h3 className="tasks-name !grid"><span className="truncate">{task?.name}</span></h3>
                            <span className={`tasks-status ${tabStatus}`}>
                                {TASKS_STATUS[tabStatus]}
                            </span>
                            <span className="tasks-grade">Graded: <span>{task?.grade ? `${task?.grade}%` : '-'}</span></span>
                        </div>
                        {tabStatus !== 'assigned' ? <div className="tasks-info w-full">
                            <div className="tasks-info-item">
                                <div className="tasks-info-label">Completion Date:</div>
                                <div className="tasks-info-value">{task?.attempts?.length > 0 ? moment(task?.attempts[0]?.finished).format('DD/MM/YYYY') : '-'}</div>
                            </div>
                            {task?.attempts?.length > 0 ? <div className="flex gap-2 ml-auto">
                                <button className="tasks-link"
                                    onClick={() => {
                                        addFeedback({
                                            task,
                                            attempt: task?.attempts[0]
                                        });
                                    }}
                                >{task?.attempts[0]?.instructor_feedback ? 'View' : 'Add'} Feedback</button>
                                {task?.content_url ?
                                    <button className="tasks-btn" onClick={() => {
                                        if (!hasCheckedStream(task?.content_url)) {
                                            window.open(task?.content_url, '_blank');
                                        } else {
                                            setHasOpenFile(true);
                                            setUrlPlay({ ...task, name: task?.name, file_url: task?.content_url });
                                            return;
                                        }
                                        }
                                    }>
                                        <EyeIcon className="w-4" /> <span>View</span>
                                    </button> : null}
                            </div> : null}
                        </div> : null}
                    </Disclosure.Button>
                </>
            )}
        </Disclosure>
        <VideoPreview data={urlPlay} isOpen={hasOpenFile} closeModal={() => setHasOpenFile(false)} />
    </div>
}