import React, { useState } from 'react';
// COMPONENT
import CreateWorkspace from 'components/User/CreateWorkspaceForm';
import { ArrowLeftIcon } from '@heroicons/react/outline';
// PROVIDER
import CreateWorkspaceProvider from 'contexts/Workspace/CreateWorkspaceProvider';
import GetListOfWorkspaceProvider from 'contexts/Workspace/GetListOfWorkspaceProvider';
// CONTEXT
import { CreateWorkspacePageProps } from './CreateWorkspacePageFn';
import ImagesSlider from 'components/ImagesSlider/ImagesSliderRegis';
import { useHistory, useLocation } from 'react-router-dom';

const CreateWorkspacePage: React.FC<CreateWorkspacePageProps> = ({
    setAuthStorage,
    storageUserInfo,
}) => {
    const history = useHistory();
    const location: any = useLocation();
    // step: 0 || 1, step 0: CreateWorkspaceForm, step 1: InviteToWorkspaceForm
    const [step, setStep] = useState(0);

    React.useEffect(() => localStorage.removeItem('google_auth'), []);

    return (
        <CreateWorkspaceProvider>
            <GetListOfWorkspaceProvider>
                <div className="min-h-screen flex">
                    <div className="hidden w-1/4 2xl:w-1/5 h-screen lg:flex items-center">
                        <ImagesSlider />
                    </div>
                    <div className="lg:w-3/4 w-full relative">
                        <button
                            title="ArrowLeftIcon"
                            type="button"
                            className="absolute top-0 left-0 ml-4 mt-4 cursor-pointer"
                            onClick={() => history.goBack()}
                            hidden={!!location?.state?.preLocation}
                        >
                            <ArrowLeftIcon className="w-5 lg:w-6" />
                        </button>

                        <div className="flex justify-center items-center h-screen">
                            <CreateWorkspace
                                setAuthStorage={setAuthStorage}
                                storageUserInfo={storageUserInfo}
                                changeStep={(step) => setStep(step)}
                                step={step}
                            />
                        </div>
                    </div>
                </div>
            </GetListOfWorkspaceProvider>
        </CreateWorkspaceProvider>
    );
};

export default CreateWorkspacePage;
