import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import PageBreadcrumb from "components/Breadcrumb/PageBreadcrumb";
import TeachingHistory from "components/Management/User/UserDetail/Tab/TeachingHistory/TeachingHistory";
import "./index.scss";
import DatePickerComponent from "components/Reports/components/DatePicker";
import moment from "moment";

export const enum assignmentFromEnum {
    from_session = 'from_session',
    from_e_learning = 'from_e_learning',
    from_assigned = 'from_assigned'
};

type TeachingHistoryProps = {
    children: React.ReactNode
}

export const TeachingHistoryDashboard = ({ children }: TeachingHistoryProps) => {
    const history = useHistory();
    const { t: translator } = useTranslation();
    const params: { id: string } = useParams();
    const [startDate, setStartDate] = useState(new Date(moment().subtract(1, 'months').format('MM-DD-YYYY')));
    const [endDate, setEndDate] = useState(new Date());

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    return (
        <div className="teachingHistoryDashboard">
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.DASHBOARD')}
                pages={[
                    { name: translator('TEACHING_HISTORY.TEACHING_HISTORY'), href: ``, className: '' },
                ]}
                containerClassName="z-10 sticky top-0"
            />
            <div className="teachingHistoryDashboard-header">
                <h1 className="teachingHistoryDashboard-title">{translator('TEACHING_HISTORY.TEACHING_HISTORY')}</h1>
                <div className='teachingHistoryDashboard-date'>
                    <DatePickerComponent onChange={onChange} startDate={startDate} endDate={endDate} maxDate={new Date()} />
                </div>
            </div>
            <div className="teachingHistoryDashboard-main">
                <TeachingHistory start={startDate} end={endDate} hasById={false} />
            </div>
        </div>
    )
}