import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import ModalCreate from 'components/Management/components/ModalCreateWrapper';
import { useBoolean } from 'hooks/custom';
import RefundModal from './RefundModal'
import classService from 'services/class.service';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import dayjs from 'dayjs';
import Table, { RenderTableCell, RenderTableHeader } from 'components/V2/Table/TableCustom';
import { checkPermission } from 'utils/commonFun';
import { USER_STATUS } from 'components/Management/User/constant';
import { ButtonOutline, ButtonPrimary } from '_shared';
import CancelModal from './CancelModal';

interface TableGroupsProps {
  dataTable: any;
  t: Function;
  tz: string;
  refreshData: any,
  userData: any
}

const TableGroups: React.FC<TableGroupsProps> = ({
  dataTable,
  t,
  tz,
  refreshData,
  userData
}) => {

  const {
    booleanValue: modalCreate,
    toggleBooleanValue: toggleModalCreate,
  } = useBoolean();

  const [isOpenModalCancel, setOpenModalCancel] = useState(false)

  const { getWorkspaceDetailState } = useContext(
    GetWorkspaceContext
  );

  const { result } = getWorkspaceDetailState;
  const {
    membership: { role },
  } = result;

  const [rewardSelected, setRewardSelected] = useState({})
  const params = useParams<{ id: string; studentId: string }>();

  const handleClaimReward = (rewardId, rewardClaimId) => {
    classService.claimService(params.id, rewardClaimId, rewardId)
      .then(res => {
        refreshData()
      })
      .catch(err => err)
  }

  const handleToggleModal = (rewardId, rewardClaimId) => {
    setRewardSelected({ rewardId, rewardClaimId })
    toggleModalCreate()
  }


  const columns =
    [
      {
        Header: () => (
          <RenderTableHeader
            value={t("COIN.REWARD_NAME")}
          />
        ),
        Cell: ({ row }) => {
          return (
            <RenderTableCell value={get(row, 'values.reward.name')} />

          );
        },
        accessor: 'reward',
        width: 120,
      },
      {
        Header: () => <RenderTableHeader value={t("COIN.DESCRIPTION")} />,
        Cell: ({ row }) => {
          return (
            <RenderTableCell value={get(row, 'original.reward.description')} />
          )
        },
        accessor: 'description',
        width: 100,
      },
      {
        Header: () => <RenderTableHeader value={t("COIN.COIN_PRICE")} />,
        Cell: ({ row }) => {
          return (
            <div>
              <RenderTableCell className='!text-green-500' value={row.values.amount ? `+ ${row.values.amount} Coins` : '-'} />
            </div>
          )
        },
        accessor: 'amount',
        width: 60,
      },
      {
        Header: () => <RenderTableHeader value={t("COIN.UPDATE_DATE")} />,
        Cell: ({ row }) => {
          return (
            <RenderTableCell value={dayjs.utc(row.values.updated_at).tz(tz).format('DD/MM/YYYY')} />
          )
        },
        accessor: 'updated_at',
        width: 100,
      },
      {
        Header: () => <RenderTableHeader value={t("COIN.REASON")} />,
        Cell: ({ row }) => {
          return (
            <RenderTableCell value={row.values.refund_reason} />
          )
        },
        accessor: 'refund_reason',
        width: 80,
      },
      {
        Header: () => <RenderTableHeader value={t("COIN.STATUS")} />,
        Cell: ({ row }) => {
          return (
            <div className='flex gap-2 flex-nowrap text-xs'>

              {
                row.values.status === 'pending' && <span className='text-orange-500 bg-orange-50 border border-orange-300 p-1.5 rounded capitalize '>Pending</span>
              }

              {
                row.values.status === 'claimed' && <span className='text-green-500  bg-green-50-50 border border-green-300 p-1.5 rounded capitalize '>Received</span>
              }

              {
                row.values.status === 'refund' && <span className='text-error-500  bg-red-50 border border-red-300 p-1.5 rounded capitalize '>Cancelled</span>
              }
            </div>
          )
        },
        accessor: 'status',
        width: 60,
      },
      {
        Header: () => <RenderTableHeader value={t("COIN.ACTION")} />,
        Cell: ({ row }) => {
          return (
            <div className='flex gap-2 flex-nowrap '>
              {
                 row.original.status === 'pending' && checkPermission('userDetailsAchievementsRewardHistory', 'U') &&
                <>
                  <ButtonPrimary
                    type="button"
                    className='!text-xs'
                    onClick={() => handleClaimReward(get(row, 'original.id'), get(row, 'original.reward.id'))}

                    disabled={userData?.membership?.status === USER_STATUS.DEACTIVATE}
                  >
                    {t("CONFIRM")}
                  </ButtonPrimary>
                  <ButtonOutline
                    className=' !text-error-500 border !border-error-500 !text-xs !font-normal'
                    onClick={() => {
                      setRewardSelected(row?.original)
                      setOpenModalCancel(true)
                    }}
                    disabled={userData?.membership?.status === USER_STATUS.DEACTIVATE}
                    type="button"
                  >
                    {t('CANCEL')}
                  </ButtonOutline>
                </>

              }
              {
               (row.values.status == 'claimed') && checkPermission('userDetailsAchievementsRewardHistory', 'U') &&
                <ButtonOutline
                  className=' !text-error-500 border !border-error-500 !text-xs !font-normal'
                  onClick={() => handleToggleModal(get(row, 'original.id'), get(row, 'original.reward.id'))}
                  disabled={userData?.membership?.status === USER_STATUS.DEACTIVATE}
                  type="button"
                >
                  {t('REFUND')}
                </ButtonOutline>
              }
            </div>
          )
        },
        accessor: 'action',
        width: 140,
      },
    ]

  return (
    <div className="w-full h-full">
      <Table data={dataTable} columns={columns} />
      <div className='max-w-md'>
        <ModalCreate
          isOpen={modalCreate}
          onClose={toggleModalCreate}
          title=""
        >
          <RefundModal refreshData={refreshData} toggleModalCreate={toggleModalCreate} rewardSelected={rewardSelected} />
        </ModalCreate>
        <CancelModal refreshData={refreshData} rewardSelected={rewardSelected} isOpen={isOpenModalCancel} onClose={() => setOpenModalCancel(false)} />
      </div>
    </div>
  );

}

export default TableGroups;
