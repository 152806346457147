import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import TableGradebookApollo from './TableGradeBookApollo';
import { ChevronDownIcon, ChevronUpIcon, ClipboardListIcon } from '@heroicons/react/outline';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useLocation, useParams } from 'react-router-dom';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { GradebookApoStudent } from 'types/Class.type';
import { Tab } from '@headlessui/react';
import StudentDetails from './StudentDetails';
import StudentReports from './StudentReports';
import { roundNumber } from 'constant/util.const';
import { NoContentWithImage } from 'components/NoContent/NoContent';

const styleTd = 'flex items-center text-sm  font-semibold text-ooolab_dark_1 py-2 justify-center';

const listTab = (translator: Function) => {
    const type = [
        {
            name: 'Grades',
            hiddenWhenDeactivate: false,
            href: 'grades',
        },
        {
            name: 'Reports',
            hiddenWhenDeactivate: true,
            href: 'reports',
        },
    ];
    return type;
};

const ClassGradeBookApollo: React.FC<{}> = ({}) => {
    const { t: translator } = useTranslation();
    const { dispatch: workspaceDispatch, getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { state }: any = useLocation();
    const defaultIndex = useMemo(() => (state?.isTabGradebookReport ? ['grades', 'reports'].indexOf('reports') : 0), [
        state?.isTabGradebookReport,
    ]);

    const [refreshGradebook, setRefreshGradebook] = useState(false);
    const gradebookApo = getWorkspaceDetailState.class.gradebookApo;

    const params: { id: string; classId: string } = useParams();
    const [orderByName, setOrderByName] = useState<boolean>(false);
    const [toggleDetails, setToggleDetails] = useState<boolean>(false);
    const [dataView, setDataView] = useState<GradebookApoStudent[]>([]);
    const [studentDetails, setStudentDetails] = useState<GradebookApoStudent>();

    useEffect(() => {
        workspaceMiddleware.getGradebookApo(workspaceDispatch, params.id, params.classId);
    }, [refreshGradebook]);

    useEffect(() => {
        setDataView(gradebookApo?.items);
        if (state?.isTabGradebookReport && state?.studentId && gradebookApo?.items?.length) {
            const objTemp = gradebookApo?.items.find((el) => el?.student?.id === state?.studentId);
            if (objTemp) {
                setStudentDetails(objTemp);
                setToggleDetails(true);
            }
        }
    }, [gradebookApo]);

    const toggleSort = () => {
        setOrderByName(!orderByName);
    };
    const toggleDetailsView = () => {
        setToggleDetails(!toggleDetails);
    };

    const handleSortUp = () => {
        toggleSort();
        let dataSort = dataView;
        dataSort.sort((a, b) => a?.student?.display_name.localeCompare(b?.student?.display_name));
        setDataView(dataSort);
    };

    const handleSortDown = () => {
        let dataSort = dataView;
        dataSort.sort((a, b) => b?.student?.display_name.localeCompare(a?.student?.display_name));
        toggleSort();
        setDataView(dataSort);
    };

    const columns: Column<any>[] = React.useMemo(
        () => [
            {
                Header: () => (
                    <p
                        style={{ width: '208px' }}
                        className="flex justify-center items-center text-sm font-bold text-ooolab_dark_1 py-2 "
                    >
                        <span className="font-normal">Sort by &nbsp; </span>
                        {translator('NAME')}
                        {orderByName ? (
                            <span>
                                <ChevronUpIcon className="w-5 h-5 ml-1 cursor-pointer" onClick={() => handleSortUp()} />
                            </span>
                        ) : (
                            <span>
                                <ChevronDownIcon className="w-5 h-5 ml-1 cursor-pointer" onClick={() => handleSortDown()} />
                            </span>
                        )}
                    </p>
                ),
                accessor: 'student',
                Cell: (d: any) => {
                    return (
                        <div className="flex items-center text-ooolab_dark_1">
                            <div className="mr-2">
                                <img src={d?.value?.avatar_url} alt="Avatar" className="w-7 h-7 rounded-ooolab_circle" />
                            </div>
                            <span className=" text-xs  font-semibold text-ooolab_dark_1 ">
                                {d?.value?.display_name}
                                {d?.value?.nick_name && ` / ${d?.value?.nick_name}`}
                            </span>
                        </div>
                    );
                },
            },
            {
                Header: () => (
                    <div className="items-center  w-20 md:w-28  lg:w-30 2xl:w-52 py-2">
                        <div className="w-full flex justify-center"></div>
                        <p className=" font-bold text-ooolab_dark_1  text-sm ">{translator('REPORTS.CLASS_GRADE')}</p>
                    </div>
                ),
                accessor: `class_grade`,

                Cell: (d: any) => {
                    return <span className={`${styleTd}`}>{d?.value || d?.value == 0 ? `${roundNumber(d?.value,1)}%` : `-`}</span>;
                },
            },
            {
                Header: () => (
                    <div className="items-center w-20 md:w-28  lg:w-30 2xl:w-52 py-2">
                        <p className="  text-sm  font-semibold text-ooolab_dark_1  ">{translator('REPORTS.ASSIGNMENT_GRADE')}</p>
                    </div>
                ),
                accessor: `assignment_grade_in_percent`,

                Cell: (d: any) => {
                    return <span className={`${styleTd}`}>{d?.value || d?.value == 0 ? `${roundNumber(d?.value,1)}%` : `-`}</span>;
                },
            },
            {
                Header: () => (
                    <div className="items-center  w-20 md:w-28  lg:w-30 2xl:w-52 py-2">
                        <p className="  text-sm  font-semibold text-ooolab_dark_1  ">{translator('REPORTS.ASSIGNMENT_COMPLETION')}</p>
                    </div>
                ),
                accessor: `assignment_completion_in_percent`,

                Cell: (d: any) => {
                    return <span className={`${styleTd}`}>{d?.value || d?.value == 0 ? `${roundNumber(d?.value,1)}%` : `-`}</span>;
                },
            },
            {
                Header: () => (
                    <div className="items-center  w-20 md:w-28  lg:w-30 2xl:w-52 py-2">
                        <p className="  text-sm  font-semibold text-ooolab_dark_1  ">{translator('REPORTS.ATTENDANCE')}</p>
                    </div>
                ),
                accessor: `class_attendance`,

                Cell: (d: any) => {
                    return <span className={`${styleTd}`}>{d?.value || d?.value == 0 ? `${roundNumber(d?.value,1)}%` : `-`}</span>;
                },
            },
        ],
        [dataView, orderByName, refreshGradebook]
    );
    const handleOnclickRow = (e: any) => {
        setStudentDetails(e);
        toggleDetailsView();
    };

    return (
        <>
            {toggleDetails ? (
                <div className="ClassGradeBookApolloIndex relative w-full">
                    <Tab.Group defaultIndex={defaultIndex < 0 ? 0 : defaultIndex}>
                        <Tab.List className="absolute top-0 left-0 bg-white opacity-100 z-2  w-full text-sm px-2  items-center ">
                            <div className=" flex justify-center items-center relative ">
                                <div
                                    className="absolute top-1/4 left-0 flex group hover:text-text_blue_1 cursor-pointer items-center "
                                    onClick={toggleDetailsView}
                                >
                                    <ArrowLeftIcon className="w-5 h-5 mr-3" />
                                    <p className="font-semibold text-base text-ooolab_dark_1 group-hover:text-text_blue_1 ">Back</p>
                                </div>
                                {listTab(translator).map((i, index) => (
                                    <Tab
                                        key={index}
                                        className={({ selected }) => {
                                            const tabClassName = `flex justify-start items-center px-5 py-3 text-left font-bold text-base  outline-none ${
                                                selected ? 'text-primary-500 ' : 'text-ooolab_dark_2'
                                            }`;
                                            return tabClassName;
                                        }}
                                    >
                                        <span>{i.name}</span>
                                    </Tab>
                                ))}
                            </div>
                        </Tab.List>
                        <Tab.Panels className="custom-scrollbar z-1 px-1 2xl:px-10 pt-20 h-full overflow-y-scroll outline-none">
                            <Tab.Panel>
                                <StudentDetails
                                    data={studentDetails}
                                    refreshGradebook={refreshGradebook}
                                    setRefreshGradebook={setRefreshGradebook}
                                />
                            </Tab.Panel>
                            <Tab.Panel>
                                <StudentReports data={studentDetails} />
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            ) : (
                <div
                    style={{
                        height: '70vh',
                        overflowX: 'auto',
                        maxHeight: '70vh',
                        overflowY: 'auto',
                    }}
                    className="m-3 gradebook flex"
                    id="scroll-btn"
                >
                    {
                        dataView?.length ?
                        <TableGradebookApollo
                        columns={columns}
                        data={dataView}
                        rowProps={{
                            className: 'bg-white hover:bg-gray-50 cursor-pointer',
                        }}
                        headerProps={{
                            className: 'outline-0',
                        }}
                        className="border-none "
                        onClickRow={(e) => handleOnclickRow(e)}
                    />
                    : <NoContentWithImage className='m-auto' content='No Gradebooks'/>
                    }
                    
                </div>
            )}
        </>
    );
};

export default ClassGradeBookApollo;
