import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import './DocumentPreview.scss';
import { ArrowLeftIcon } from "@heroicons/react/outline";
import docIcon from 'assets/img/docIcon.png';
import pdfIcon from 'assets/img/pdfIcon.png';
import pngIcon from 'assets/img/pngIcon.png';
import jpegIcon from 'assets/img/jpegIcon.png';
import VideoPlayer from "../VideoPlayer/VideoPlayer";

interface VideoPreviewProps {
    closeModal: () => void,
    isOpen: boolean,
    data: any,
    onDelete?: () => void
}

export const VideoPreview = ({ data, isOpen, closeModal }: VideoPreviewProps) => {
    return (<>
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[9999]" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/80" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full justify-center text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full transform text-left align-middle transition-all">
                                <div className="documentPreview-header">
                                    <div className="flex text-white items-center">
                                        <ArrowLeftIcon className="w-7 h-7 mr-2.5 cursor-pointer" onClick={closeModal} />
                                        {(data?.type?.includes('msword') || data?.type?.includes('document')) && <img className="w-8" src={docIcon} />}
                                        {data?.type?.includes('pdf') && <img className="w-8" src={pdfIcon} />}
                                        {data?.type?.includes('png') && <img className="w-8" src={pngIcon} />}
                                        {(data?.type?.includes('jpeg') || data?.type?.includes('jpg')) && <img className="w-8" src={jpegIcon} />}
                                        <span className="documentPreview-name">{data?.name}</span>
                                    </div>
                                    <div></div>
                                </div>
                                <div className="documentPreview-content">
                                    <VideoPlayer data={data} onReady={() => {}} />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    </>)
}