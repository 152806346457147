import { CircleSpin } from 'components/UiComponents/CircleSpin';
import React, { ReactNode } from 'react';
import { classNames } from 'utils/commonFun';

interface ButtonProps {
    classStyle?: string;
    title?: string;
    icon?: ReactNode;
    disable?: boolean;
    onClickButton?: (event?: any) => void;
    type?: 'button' | 'submit' | 'reset';
    hidden?: boolean;
    iconPrefix?: boolean;
    loading?: boolean;
    loadingColor?: string;
}

const ButtonComponent: React.FC<ButtonProps> = ({
    classStyle = 'px-3 py-2 flex items-center',
    title,
    icon,
    disable,
    onClickButton,
    type = 'button',
    hidden,
    iconPrefix = true,
    loading = false,
    loadingColor = 'text-[#024FEE]',
}) => {
    return (
        <button
            type={type}
            onClick={onClickButton}
            className={classNames('rounded-lg disabled:cursor-not-allowed disabled:bg-gray-400', classStyle)}
            hidden={hidden}
            disabled={disable}
        >
            {loading ? <CircleSpin color={loadingColor} /> : ''}
            {iconPrefix && icon}
            {title}
            {!iconPrefix && icon}
        </button>
    );
};

export default ButtonComponent;
