import SessionList from './SessionList'
import Tab, { TAB } from './Tab'
import { useTranslation } from 'react-i18next'
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Banner from './Banner/Banner';
import Calendar from './Calendar/Calendar';
import { useEffect } from 'react';

export default function InstructorSchedule() {
  const {t: translator} = useTranslation();
  const search = useLocation()?.search;
  const tab = new URLSearchParams(search).get('tab')
  const history = useHistory();
  const params: {id: string} = useParams();

  useEffect(() => {
    if(!tab) history.push(`/workspace/${params?.id}/instructor-dashboard/instructor-schedule?tab=SESSION_LIST`)
  }, [tab])

  return (
    <div>
      <PageBreadcrumb
        pageNameDefault={translator('DASHBOARD.TEACHER.DASHBOARD')}
        pages={[{ name: translator('DASHBOARD.TEACHER.INSTRUCTOR_SCHEDULE'), href: '', className: '' }]}
        isShowIconNotification={true}
      />
      <div>
        <Banner />
      </div>

      <Tab />
      <div className=''>
        {
          tab === TAB.SESSION_LIST && <SessionList />
        }
        {
          tab === TAB.CALENDAR && <Calendar />
        }
      </div>
    </div>
  )
}
