import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryParamsURL } from 'hooks/useQueryParamsURL';
import './CourseSyllabus.scss';
import { checkPermission, classNames } from 'utils/commonFun';
import { CourseSyllabus } from './CourseSyllabus';
import { CourseResources } from './CourseResources';

const ACADEMICS_TAB = {
    syllabus: 'syllabus',
    resources: 'resources',
};

export const CourseAcademics = () => {
    const { t: translator } = useTranslation();
    const params: { id: string; courseId: string } = useParams();
    const history = useHistory();
    const query = useQueryParamsURL();

    useEffect(() => {
        if (!!query.get('tab') && !query.get('subtab')) {
            history?.replace(`/workspace/${params.id}/management/courses/${params.courseId}?tab=academics&subtab=syllabus`);
        }
    }, []);

    return (
        <div className="courseSyllabus">
            <div className="courseSyllabus-title">{translator('COURSES.ACADEMICS.ACADEMICS')}</div>
            <div className="courseSyllabus-tab">
                {Object.keys(ACADEMICS_TAB).map((tab, index) => (
                    <div
                        key={index}
                        className={classNames(
							'courseSyllabus-tab-item',
                            query.get('subtab') === tab ? 'active' : '',
                            index === 0 && !checkPermission('syllabus','R')? ' !hidden' :'',
                            index === 1 && !checkPermission('courseResource','R')? ' !hidden' :'',
                        )}
						onClick={() => history?.replace(`/workspace/${params.id}/management/courses/${params.courseId}?tab=academics&subtab=${tab}`)}
                    >
                        {translator(`COURSES.ACADEMICS.${tab.toUpperCase()}`)}
                    </div>
                ))}
            </div>
			{query.get('subtab') === ACADEMICS_TAB.syllabus && <CourseSyllabus />}
			{query.get('subtab') === ACADEMICS_TAB.resources && <CourseResources />}
        </div>
    );
};
