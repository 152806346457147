import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import DatePickerInput from 'components/Management/components/Form/DatePicker';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import './index.scss';
import { InputField } from 'components/InputForm/InputForm';
import courseService from 'services/course.service';
import SelectBox from '_shared/components/Select';
import { useDebouncedState } from 'hooks/useDebounce';
import { ENROLLMENT_TYPE } from '../../../CoursePeople/constants';
import { VALIDATE_FIELD } from 'utils/validate';
import { SwitchToggle } from '_shared/components/Form/SwitchToggle/SwitchToggle';
import { CircleInformationIcon } from 'assets/icon';
import ReactTooltip from 'react-tooltip';
import dayjs from 'dayjs';
import ErrorMessageForm from 'components/ErrorMessageForm';
import { lowerCase } from 'lodash';

export default function ModalAssignAssignment({ onCloseModal, assignmentSelected }) {
    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
        setValue,
        watch,
        register,
        reset
    } = useForm({ mode: 'onChange', defaultValues: {hide_solution: false, max_retry: 0, learners: [], learning_groups: [], due_date: null}});
    const { t: translator } = useTranslation();
    const [type, setType] = useState('individual');
    const params: any = useParams();
    const workspaceId = params.id;
    const courseId = params?.courseId;
    const [loading, setLoading] = useState(false);
    const [listEnrollments, setListEnrollments] = useState([]);
    const [inputSearchEnrollments, setInputSearchEnrollments] = useDebouncedState('');
    const [loadingEnrollment, setLoadingEnrollment] = useState(false);
    const [listLearnerOfGroup, setListLearnerOfGroup] = useState([]);

    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 10,
    });

    const getEnrollments = async (page) => {
        setLoadingEnrollment(true);
        let payload = {
            page: page ? page : pagination.page,
            per_page: pagination.per_page,
            q: inputSearchEnrollments,
            enrollment_as: type,
            statuses: 'active,enrolled',
        };
        if (!payload?.q) delete payload.q;
        const response = await courseService.getEnrollments(workspaceId, courseId, payload);
        if (response) {
            setLoadingEnrollment(false);
            const listOptions = [];
            response?.data?.items?.forEach((enrollment) => {
                if (type === ENROLLMENT_TYPE.INDIVIDUAL) {
                    enrollment?.learners?.forEach((item) => {
                        listOptions.push({
                            ...item,
                            name: item?.display_name,
                            id: item?.id,
                            value: item?.id,
                            enrollId: enrollment?.id
                        });
                    });
                } else {
                    listOptions.push({
                        ...enrollment?.learning_group,
                        name: enrollment?.learning_group?.name,
                        id: enrollment?.learning_group?.id,
                        enrollId: enrollment?.id,
                        value: enrollment?.learning_group?.id,
                        learners: enrollment?.learners?.map((learner) => {
                            return {
                                ...learner,
                                name: learner?.display_name,
                                id: learner?.id,
                                value: learner?.id,
                            };
                        }),
                    });
                }
            });
            if (page === 1) setListEnrollments(listOptions);
            else setListEnrollments([...listEnrollments, ...listOptions]);
            setPagination({ ...pagination, total: response?.data?.total, page });
        }
    };

    useEffect(() => {
        ReactTooltip.rebuild()
    }, [type]);

    useEffect(() => {
        getEnrollments(1)
    }, [type, inputSearchEnrollments])

    const onSearch = (keyword) => {
        setInputSearchEnrollments(keyword)
    }

    const onChangeTab = (tab) => {
        reset({max_retry: 0, learners: [], learning_groups: [], hide_solution: false, due_date: null})
        setListLearnerOfGroup([]);
        setListEnrollments([]);
        onSearch('')
        setType(tab);
    }

    const onFilterLearnerOfGroup = (name = '') => {
        if (!name?.length) return listLearnerOfGroup;
        const listLearner =  listLearnerOfGroup?.filter(item => {
            const result = lowerCase(item.name)?.search(lowerCase(name)) !== -1;
            return result;
        });
        return listLearner;
    }

    useEffect(() => {
        setValue('learners', []);
        if (watch('learning_groups')?.length > 0) {
            let listLearners = watch('learning_groups')?.[0]?.learners || []
            setListLearnerOfGroup(listLearners);
        } else {
            setListLearnerOfGroup([]);
        }
    }, [watch('learning_groups')]);

    const onSubmit = (value) => {
      let enrollments = []
      if (type === ENROLLMENT_TYPE.INDIVIDUAL) {
          enrollments = watch('learners')?.map(learner => {
            return {
              type: ENROLLMENT_TYPE.INDIVIDUAL,
              id: learner?.enrollId,
              learner_ids: [learner?.id]
            }
          })
      }
      if (type === ENROLLMENT_TYPE.LEARNING_GROUP) {
          enrollments = watch('learning_groups')?.map(group => {
            return {
              type: ENROLLMENT_TYPE.LEARNING_GROUP,
              id: group?.enrollId,
              learner_ids: watch('learners')?.map(learner => learner?.id)
            }
        })
    }

      const data = {
        hide_solution: value?.hide_solution,
        max_retry: +value?.max_retry,
        assignments: assignmentSelected?.map(assignment => ({id: assignment?.library_content?.object_id, lesson_id: assignment?.lesson?.id})),
        enrollments,
        due_date: dayjs(value?.due_date)?.format("YYYY-MM-DDT23:59:59.000Z")
      }

      setLoading(true);
      courseService.assignAssignment(workspaceId, courseId, data)
      .then(res => {
        Toastify.success();
        onCloseModal();
      })
      .catch(() => Toastify.error())
      .finally(() => setLoading(false));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="ModalAssignAssignment">
            <div className="ModalAssignAssignment-type">
                <div onClick={() => onChangeTab('individual')} className={`btn-type ${type === 'individual' ? 'active' : ''} `}>
                    {translator('COURSES.LEARNERS')}
                </div>
                <div onClick={() => onChangeTab('learning_group')} className={`btn-type ${type === 'learning_group' ? 'active' : ''}`}>
                    {translator('COURSES.LEARNING_GROUPS')}
                </div>
            </div>
            <div className="ModalAssignAssignment-assignmnetSelected">
                <div className="ModalAssignAssignment-assignmnetSelected--title">{translator('COURSES.YOU_ARE_SELECTING')}</div>
                <ul>
                    {assignmentSelected?.map((assignment) => {
                        return (
                            <li className="assignmentSelectedItem" key={assignment?.id}>
                                <div className='assignmentSelectedItem-name'>{assignment?.title}</div>
                            </li>
                        );
                    })}
                </ul>
            </div>
            {type === ENROLLMENT_TYPE.LEARNING_GROUP && (
                <div className="field">
                    <SelectBox
                        label={translator('COURSES.LEARNING_GROUPS')}
                        placeholder={translator('COURSES.SELECT_LEARNING_GROUPS')}
                        data={listEnrollments}
                        onSearch={onSearch}
                        value={watch('learning_groups')}
                        loading={loadingEnrollment}
                        getData={getEnrollments}
                        pagination={pagination}
                        control={control}
                        name="learning_groups"
                        errors={errors}
                        isRequired={true}
                        numberOfItemDisplayed={3}
                        isMulti={false}
                    />
                </div>
            )}
            <div className="field">
                <SelectBox
                    key={`enrollment-${type}`}
                    label={translator('COURSES.LEARNERS')}
                    placeholder={translator('COURSES.SELECT_LEARNERS')}
                    data={type === ENROLLMENT_TYPE.LEARNING_GROUP ? onFilterLearnerOfGroup(inputSearchEnrollments) : listEnrollments}
                    onSearch={onSearch}
                    value={watch('learners')}
                    loading={type !== ENROLLMENT_TYPE.LEARNING_GROUP && loadingEnrollment}
                    getData={type !== ENROLLMENT_TYPE.LEARNING_GROUP ? getEnrollments : () => {}}
                    pagination={pagination}
                    control={control}
                    name="learners"
                    errors={errors}
                    isRequired={true}
                    numberOfItemDisplayed={3}
                    isMulti={true}
                    isDisabled={type === ENROLLMENT_TYPE.LEARNING_GROUP && !listLearnerOfGroup?.length}
                />
            </div>

            <div className="field group-datepicker">
                <div className="w-full">
                    <div className='flex gap-1 text-xs font-semibold items-center'>
                        {translator('COURSES.DUE_DATE')}
                        <span className="text-red-500"> *</span>
                    </div>
                    
                    <DatePickerInput
                        control={control}
                        name="due_date"
                        placeholderText={translator('COURSES.SELECT_DUE_DATE')}
                        minDate={new Date()}
                        iconCls="!top-3"
                        styles="!h-10"
                        classnames='!h-10'
                        isRequired
                        positionIcon="right"
                    />
                    {!!errors && <ErrorMessageForm errors={errors} classes='!mt-1' name={'due_date'} />}
                </div>
            </div>

            <div className="field">
                <InputField
                    label={translator("COURSES.ALLOW_RETRY")}
                    classNameInput="!w-16"
                    name="max_retry"
                    isRequired
                    tooltipLabel={translator("COURSES.ALLOW_RETRY_TOOLTIP")}
                    register={{
                        ...register('max_retry', {
                            pattern: {
                                value: VALIDATE_FIELD.NUMBER_,
                                message: translator('FORM_CONST.NUMBER_ONLY'),
                            },
                            required: translator('FORM_CONST.REQUIRED_FIELD')
                        }),
                    }}
                    labelHorizontal={translator("COURSES.TIMES")}
                    errors={errors}
                />
            </div>

            <div className="field flex gap-2 items-center">
                <SwitchToggle name={'hide_solution'} control={control} />
                <div className="text-sm font-semibold flex gap-1 items-center">
                    {translator('COURSES.SHOW_SOLUTION')} 
                    <span data-for={`hide_solution`} data-tip={translator("COURSES.SHOW_SOLUTION_TOOLTIP")} ><CircleInformationIcon height="10.5" width="10.5" fill="#1F2937" /></span>
                </div>
                 {/* @ts-ignore*/
                    <ReactTooltip
                        place="right"
                        type="light"              
                        id={`hide_solution`}
                        getContent={(content) => <div className="max-w-[280px] text-left text-xs font-normal p-1">{content}</div>}
                        className='shadow-lg opacity-100'
                    />
                }
            </div>

            <div className="field group-button">
                <ButtonOutline type="Button" onClick={onCloseModal}>
                    {translator('CANCEL')}
                </ButtonOutline>
                <ButtonPrimary type="submit" onClick={handleSubmit(onSubmit)} disabled={!isValid || loading}>
                    {translator('COURSES.ASSIGN')}
                </ButtonPrimary>
            </div>
        </form>
    );
}
