import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ActionsButton from 'components/ActionsButton';
import { CircleInformationIcon } from 'assets/icon';
import { ENROLLMENT_STATUS } from '../../constants';
import { checkPermission } from 'utils/commonFun';
import { PlusIcon } from '@heroicons/react/outline';
import { useAppSelector } from 'hooks/hooks';

interface ActionMenuProp {
    data: any;
    position?: string;
    onDeposit?: (values) => void;
}

const ActionMenu: FC<ActionMenuProp> = ({ data, position, onDeposit }) => {
    const { t: translator } = useTranslation();

    const params: { id: string; courseId: string } = useParams();
    const workspaceId = params?.id;
    const courseId = params?.courseId
    const {setting} = useAppSelector((state) => state.course);   

    return (
        <>
            <ActionsButton
                styleOptions={`${position ? position : 'top-0'} right-[35px]`}
                menus={[
                    {
                        label: translator('COURSES.VIEW_CREDIT_BALANCE'),
                        icon: <div className='text-ooolab_dark_1'><CircleInformationIcon width='16px' height='16px' fill="currentColor" /></div>,
                        action: () => window?.open(`/workspace/${workspaceId}/management/courses/${courseId}?tab=credit-balance&creditId=${data?.credit_balance?.id}`, '_blank'),
                        class: 'border-b border-gray-300',
                        hide: !checkPermission('coursePeopleEnrollment', 'R') || setting?.enrollment_type !== 'credit',
                        isDisabled: !data?.credit_balance?.id
                    },
                    {
                        label: translator('COURSES.DEPOSIT'),
                        icon: <PlusIcon className='w-4 h-4' />,
                        action: onDeposit,
                        class: 'border-b border-gray-300',
                        hide: !checkPermission('coursePeopleEnrollment', 'DA') || data?.status === ENROLLMENT_STATUS.DEACTIVATED || setting?.enrollment_type !== 'credit',
                    },
                ]}
            />
        </>
    );
};

export default ActionMenu;
