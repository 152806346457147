import Table from './Table'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';
import DialogComponent from 'components/Dialog/DialogComponent';
import { useCallback, useEffect, useState } from 'react';
import CreateSingleInvoice from '../CreateInvoice/CreateSingleInvoice';
import workspaceService from 'services/workspace.service';
import { getIndexPage } from 'utils/commonFun';
import Pagination from 'components/V2/Pagination';
import Spinner from 'components/Spinner';
import CreateRecurringInvoice from '../CreateInvoice/CreateRecurringInvoice';
import { debounce } from 'lodash';

const defaultPagination = {
  per_page: 20,
  total: undefined,
  page: 1,
  sort_by: "id",
  order: 'desc',
  q: ''
}
export default function RecurringInvoiceList({filter, isRefresh, setRefresh}) {
  const {t: translator} = useTranslation();
  const params: {id: string} = useParams();
  const workspaceId = params?.id;
  const [isOpenModalCreateSignleInvoice, setOpenModalCreateSingleInvoice] = useState<boolean>(false);
  const [isOpenModalCreateRecurringInvoice, setOpenModalCreateRecurringInvoice] = useState<boolean>(false);

  const [loading, setLoading] = useState(false)
  const [indexPage, setIndexPage] = useState<string>('1-50');
  const [invoiceList, setInvoiceList] = useState([]);
  const [isFirstLoad, setFirstLoad] = useState(true)
  const [pagination, setPagination] = useState(defaultPagination)

  const getRecurringInvoiceList = (page: number = 1, filter= undefined) => {
    setFirstLoad(false)
    setLoading(true);
    setRefresh(false)
    let param = {
      ...defaultPagination,
      page
    }
    if (filter) {
      param = {
        ...pagination,
        ...filter,
        page
      }
    }
    if (!param?.q) delete param?.q

    workspaceService.getRecurringInvoiceList(workspaceId, param)
    .then((res) => {
      const {items, page, total} = res?.data
      setPagination({...pagination, ...param, total});
      setInvoiceList(items)
      setIndexPage(getIndexPage(page, pagination?.per_page, items?.length));
      setLoading(false)
    })
  }

  const getRecurringInvoiceListDebounce = useCallback(
    debounce((page, filter) => getRecurringInvoiceList(page, filter), 500),
    []
  );

  useEffect(() => {
    getRecurringInvoiceListDebounce(1, filter);
  }, [filter])

  useEffect(() => {
    isRefresh && getRecurringInvoiceListDebounce(1, filter);
  }, [isRefresh])

  const handlePagination = (page: number) => {
    setLoading(true);
    getRecurringInvoiceListDebounce(page, filter);
};

  return (
    <div className='px-5'>      
      <div className="max-h-[calc(100vh-280px)] h-[calc(100vh-280px)] overflow-auto mt-5 relative">
        {
          loading && 
          <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
            <Spinner />
          </div>
        }
        {
          !loading && 
            <Table 
              workspaceId={workspaceId} 
              dataTable={invoiceList} 
              translator={translator} 
              refreshData={() => getRecurringInvoiceList(1, filter)}
            />
        }
      </div>
      <Pagination
          indexPage={indexPage}
          page={pagination?.page}
          per_page={pagination?.per_page}
          total={pagination.total}
          callback={handlePagination}
      />
      <DialogComponent 
        title={translator("NEW_SINGLE_INVOICE")}
        isOpen={isOpenModalCreateSignleInvoice} 
        onCloseModal={() => setOpenModalCreateSingleInvoice(false)}
        styles='!max-w-xl'
        child={
          <CreateSingleInvoice 
            onClose={() => setOpenModalCreateSingleInvoice(false)}
            onRefreshData={() => getRecurringInvoiceList(1)}
          />
        }
      />
      <DialogComponent 
        title={translator("NEW_RECURRING_INVOICE")}
        isOpen={isOpenModalCreateRecurringInvoice} 
        onCloseModal={() => setOpenModalCreateRecurringInvoice(false)}
        styles='!max-w-xl'
        child={
          <CreateRecurringInvoice 
            onClose={() => setOpenModalCreateRecurringInvoice(false)}
            onRefreshData={() => getRecurringInvoiceList(1)}
          />
        }
      />
    </div>
  )
}
