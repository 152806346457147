import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/vi';
import { useBoolean } from 'hooks/custom';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { IClassAssignment } from 'types/Class.type';
import AssignmentExpand from './AssignmentExpand';
import AssignmentOption from './AssignmentOption';

interface IAssignmentProps extends IClassAssignment {
    onHoverEnd?: (a: number, b: number) => void;
    assignment_group_id?: number;
    content_uid?: string;
    name: string;
    rank?: number;
    end_time: string;
    displayTimeZone?: string;
    index?: number;
    onDelete: () => void;
    onEdit: () => void;
    innerRef?: (e: HTMLElement) => any;
    provided?: DraggableProvided;
    id: any;
    total_graded?: number;
    total_assigned?: number;
}

const convertTime = (input, timezone) => {
    var d = new Date(input); /* midnight in China on April 13th */

    const result = d.toLocaleString('en-US', { timeZone: timezone });
    return result;
};

const Assignment: React.FC<IAssignmentProps> = ({
    name,
    end_time,
    onDelete,
    onEdit,
    displayTimeZone,
    id,
    total_assigned,
    total_graded,
}) => {
    const { t: translator } = useTranslation();
    const { booleanValue: open, toggleBooleanValue: toggleOpen } = useBoolean();
    const i18nData = localStorage.getItem('i18nextLng');

    return (
        <div
            // {...provided.draggableProps}
            // {...provided.dragHandleProps}
            // data-hanlder-id={handlerId}
            // ref={innerRef}
            style={{
                height: 'max-content',
            }}
            className={`border block border-ooolab_bar_color rounded-xl relative mb-5`}
        >
            <div className="inline-flex w-full ">
                <div onClick={toggleOpen} className="w-7/12 lg:w-5/6 cursor-pointer px-10 py-5 group">
                    <p className="font-semibold flex items-center">{name}</p>
                </div>
                <div className="flex items-center justify-end w-5/12 lg:w-2/6 px-10 py-5">
                    <p className="mr-4">
                        {translator('CLASSES.DUE')}{' '}
                        {i18nData === 'vi'
                            ? dayjs(convertTime(end_time, displayTimeZone)).locale('vi').format('MMMM DD - HH:mm')
                            : dayjs(convertTime(end_time, displayTimeZone)).locale('en').format('MMM DD - HH:mm')}
                    </p>
                    <AssignmentOption onDelete={onDelete} onEdit={onEdit} />
                </div>
            </div>
            {open && <AssignmentExpand assignmentId={id} total_assigned={total_assigned} total_graded={total_graded} />}
        </div>
    );
};

export default Assignment;
