import React from "react";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { useHistory, useParams } from "react-router-dom";
import "./BookingHeaderTitle.scss";
import { useTranslation } from "react-i18next";

export const BookingHeaderTitle = () => {
    const history = useHistory();
    const params: {id: string, courseId: string} = useParams();
    const {t} = useTranslation();

    return <div className="bookingHeaderTitle">
        <button className="bookingHeaderTitle-back" onClick={() => history.push(`/workspace/${params.id}/management/courses/${params.courseId}?tab=scheduling`)}>
            <ChevronLeftIcon className="w-4 h-4" />
        </button>
        {t("SESSION.BOOK_SESSIONS")}
    </div>
}