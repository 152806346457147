export const EmailIcon = () => (
    <svg
        className="w-full h-full"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8 2.66634C6.52724 2.66634 5.33333 3.86025 5.33333 5.33301C5.33333 6.80577 6.52724 7.99967 8 7.99967C9.47276 7.99967 10.6667 6.80577 10.6667 5.33301C10.6667 3.86025 9.47276 2.66634 8 2.66634ZM4 5.33301C4 3.12387 5.79086 1.33301 8 1.33301C10.2091 1.33301 12 3.12387 12 5.33301C12 7.54215 10.2091 9.33301 8 9.33301C5.79086 9.33301 4 7.54215 4 5.33301ZM5.33333 11.9997C4.22876 11.9997 3.33333 12.8951 3.33333 13.9997C3.33333 14.3679 3.03486 14.6663 2.66667 14.6663C2.29848 14.6663 2 14.3679 2 13.9997C2 12.1587 3.49239 10.6663 5.33333 10.6663H10.6667C12.5076 10.6663 14 12.1587 14 13.9997C14 14.3679 13.7015 14.6663 13.3333 14.6663C12.9651 14.6663 12.6667 14.3679 12.6667 13.9997C12.6667 12.8951 11.7712 11.9997 10.6667 11.9997H5.33333Z"
            fill="#C7C9D9"
        />
    </svg>
);

export const ForgotPasswordEmailIcon: React.ReactNode = () => (
    <svg
        className="w-ooolab_w_6 h-ooolab_w_6 ooolab_btn_second_animate_child_1"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M2 6C2 4.89543 2.89543 4 4 4L20 4C21.1046 4 22 4.89543 22 6L22 18C22 19.1046 21.1046 20 20 20L4 20C2.89543 20 2 19.1046 2 18L2 6ZM5.51859 6L12 11.6712L18.4814 6L5.51859 6ZM20 7.32877L12.6585 13.7526C12.2815 14.0825 11.7185 14.0825 11.3415 13.7526L4 7.32877L4 18L20 18L20 7.32877Z" />
    </svg>
);

export const ArrowIcon: React.ReactNode = () => (
    <svg
        className="w-ooolab_w_6 h-ooolab_w_6 ooolab_btn_second_animate_child_1"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M12.2929 5.29289C12.6834 4.90237 13.3166 4.90237 13.7071 5.29289L19.7071 11.2929C19.8946 11.4804 20 11.7348 20 12C20 12.2652 19.8946 12.5196 19.7071 12.7071L13.7071 18.7071C13.3166 19.0976 12.6834 19.0976 12.2929 18.7071C11.9024 18.3166 11.9024 17.6834 12.2929 17.2929L16.5858 13L5 13C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11L16.5858 11L12.2929 6.70711C11.9024 6.31658 11.9024 5.68342 12.2929 5.29289Z" />
    </svg>
);

export const CircleCheckIcon = () => (
    <svg
        className="w-8 h-8"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16 5.33366C10.109 5.33366 5.33332 10.1093 5.33332 16.0003C5.33332 21.8914 10.109 26.667 16 26.667C21.891 26.667 26.6667 21.8914 26.6667 16.0003C26.6667 10.1093 21.891 5.33366 16 5.33366ZM2.66666 16.0003C2.66666 8.63653 8.63619 2.66699 16 2.66699C23.3638 2.66699 29.3333 8.63653 29.3333 16.0003C29.3333 23.3641 23.3638 29.3337 16 29.3337C8.63619 29.3337 2.66666 23.3641 2.66666 16.0003ZM22.2191 11.6704C22.7695 12.1597 22.8191 13.0024 22.3299 13.5528L15.2188 21.5528C14.9657 21.8375 14.6031 22.0003 14.2222 22.0003C13.8414 22.0003 13.4787 21.8375 13.2257 21.5528L9.67011 17.5528C9.18089 17.0024 9.23046 16.1597 9.78084 15.6704C10.3312 15.1812 11.174 15.2308 11.6632 15.7812L14.2222 18.6601L20.3368 11.7812C20.826 11.2308 21.6688 11.1812 22.2191 11.6704Z"
            fill="#3AA757"
        />
    </svg>
);
