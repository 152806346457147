const options = {
	Vocabulary: {
		skills: 'Vocabulary',
		key: 'VOCABULARY',
		rates: [
			{
				id: 1,
				show: true,
				label: 'Very Good',
				value: 'VERY_GOOD',
				values: [
					{
						value: 'VOCABULARY_VERY_GOOD_1',
						label: ' is able to use a wide range of vocabulary at this point in the course.',
						VOCABULARY_VERY_GOOD_1: ' is able to use a wide range of vocabulary at this point in the course.',
					},
					{
						value: 'VOCABULARY_VERY_GOOD_2',
						label: ' has an excellent understanding of the vocabulary learnt throughout the course so far.',
						VOCABULARY_VERY_GOOD_2: ' has an excellent understanding of the vocabulary learnt throughout the course so far.',
					},
					{
						value: 'VOCABULARY_VERY_GOOD_3',
						label: ' is able to use less common vocabulary appropriately and effectively.',
						VOCABULARY_VERY_GOOD_3: ' is able to use less common vocabulary appropriately and effectively.',
					},
					{
						value: 'VOCABULARY_VERY_GOOD_4',
						label: ' is able to remember and learn new vocabulary extremely well.',
						VOCABULARY_VERY_GOOD_4: ' is able to remember and learn new vocabulary extremely well.',
					},
					{
						value: 'VOCABULARY_VERY_GOOD_5',
						label: ' is very confident when using and experimenting with new vocabulary.',
						VOCABULARY_VERY_GOOD_5: ' is very confident when using and experimenting with new vocabulary.',
					},
					{
						value: 'VOCABULARY_VERY_GOOD_6',
						label: ' has learnt and acquired a knowledge of vocabulary beyond what is expected at this point in the course.',
						VOCABULARY_VERY_GOOD_6: ' has learnt and acquired a knowledge of vocabulary beyond what is expected at this point in the course.',
					},
				],
			},
			{
				id: 2,
				show: true,
				label: 'Good',
				value: 'GOOD',
				values: [
					{
						value: 'VOCABULARY_GOOD_1',
						label: ' has a good range of vocabulary at this point in the course.',
						VOCABULARY_GOOD_1: ' has a good range of vocabulary at this point in the course.',
					},
					{
						value: 'VOCABULARY_GOOD_2',
						label: ' has a good understanding of the vocabulary learnt throughout the course so far.',
						VOCABULARY_GOOD_2: ' has a good understanding of the vocabulary learnt throughout the course so far.',
					},
					{
						value: 'VOCABULARY_GOOD_3',
						label: ' is able to use vocabulary that has been learnt throughout the course appropriately.',
						VOCABULARY_GOOD_3: ' is able to use vocabulary that has been learnt throughout the course appropriately.',
					},
					{
						value: 'VOCABULARY_GOOD_4',
						label: ' is able to remember and learn new vocabulary well.',
						VOCABULARY_GOOD_4: ' is able to remember and learn new vocabulary well.',
					},
					{
						value: 'VOCABULARY_GOOD_5',
						label: ' could be more confident when using new vocabulary.',
						VOCABULARY_GOOD_5: ' could be more confident when using new vocabulary.',
					},
					{
						value: 'VOCABULARY_GOOD_6',
						label: ' has a good knowledge of vocabulary learnt so far but struggles with less common or unfamiliar words.',
						VOCABULARY_GOOD_6: ' has a good knowledge of vocabulary learnt so far but struggles with less common or unfamiliar words.',
					},
					{
						value: 'VOCABULARY_GOOD_7',
						label: ' has a good range of vocabulary but sometimes makes errors in spelling.',
						VOCABULARY_GOOD_7: ' has a good range of vocabulary but sometimes makes errors in spelling.',
					},
				],
			},
			{
				id: 3,
				show: true,
				label: 'Ok',
				value: 'OK',
				values: [
					{
						value: 'VOCABULARY_OK_1',
						label: ' has an OK range of vocabulary at this stage of the course but should spend extra time practicing this outside of class.',
						VOCABULARY_OK_1:
							' has an OK range of vocabulary at this stage of the course but should spend extra time practicing this outside of class.',
					},
					{
						value: 'VOCABULARY_OK_2',
						label: ' often understands new vocabulary but can struggle to understand more complex words.',
						VOCABULARY_OK_2: ' often understands new vocabulary but can struggle to understand more complex words.',
					},
					{
						value: 'VOCABULARY_OK_3',
						label: ' can sometimes mix up and confuse words.',
						VOCABULARY_OK_3: ' can sometimes mix up and confuse words.',
					},
					{
						value: 'VOCABULARY_OK_4',
						label: ' is able to remember and learn simple vocabulary well but often struggles to recall less common words.',
						VOCABULARY_OK_4: ' is able to remember and learn simple vocabulary well but often struggles to recall less common words.',
					},
					{
						value: 'VOCABULARY_OK_5',
						label: ' needs a lot of practice and repetition before attempting to use new vocabulary when speaking or writing.',
						VOCABULARY_OK_5: ' needs a lot of practice and repetition before attempting to use new vocabulary when speaking or writing.',
					},
					{
						value: 'VOCABULARY_OK_6',
						label: ' has a good understanding of the vocabulary learnt but often makes errors in spelling.',
						VOCABULARY_OK_6: ' has a good understanding of the vocabulary learnt but often makes errors in spelling.',
					},
					{
						value: 'VOCABULARY_OK_7',
						label: ' is often only confident to use new vocabulary with prompting and support.',
						VOCABULARY_OK_7: ' is often only confident to use new vocabulary with prompting and support.',
					},
				],
			},
			{
				id: 4,
				show: true,
				label: 'Poor',
				value: 'POOR',
				values: [
					{
						value: 'VOCABULARY_POOR_1',
						label: " doesn't have an adequate range of vocabulary at this stage of the course.",
						VOCABULARY_POOR_1: " doesn't have an adequate range of vocabulary at this stage of the course.",
					},
					{
						value: 'VOCABULARY_POOR_2',
						label: ' often struggles to understand new vocabulary and can take a long time to acquire new words.',
						VOCABULARY_POOR_2: ' often struggles to understand new vocabulary and can take a long time to acquire new words.',
					},
					{
						value: 'VOCABULARY_POOR_3',
						label: ' can often mix up and confuse words.',
						VOCABULARY_POOR_3: ' can often mix up and confuse words.',
					},
					{
						value: 'VOCABULARY_POOR_4',
						label: ' struggles to remember and recall new vocabulary.',
						VOCABULARY_POOR_4: ' struggles to remember and recall new vocabulary.',
					},
					{
						value: 'VOCABULARY_POOR_5',
						label: ' often struggles to use new vocabulary when speaking or writing, even after a lot of repetition and practice.',
						VOCABULARY_POOR_5: ' often struggles to use new vocabulary when speaking or writing, even after a lot of repetition and practice.',
					},
					{
						value: 'VOCABULARY_POOR_6',
						label: ' often struggles to spell words.',
						VOCABULARY_POOR_6: ' often struggles to spell words.',
					},
					{
						value: 'VOCABULARY_POOR_7',
						label: ' usually needs prompting and support when using new words learnt throughout the course when speaking or writing.',
						VOCABULARY_POOR_7:
							' usually needs prompting and support when using new words learnt throughout the course when speaking or writing.',
					},
				],
			},
			{
				id: 5,
				show: true,
				label: 'Very Poor',
				value: 'VERY_POOR',
				values: [
					{
						value: 'VOCABULARY_VERY_POOR_1',
						label: ' has a very narrow range of vocabulary at this stage of the course.',
						VOCABULARY_VERY_POOR_1: ' has a very narrow range of vocabulary at this stage of the course.',
					},
					{
						value: 'VOCABULARY_VERY_POOR_2',
						label: ' has struggled to learn and acquire most of the vocabulary throughout the course.',
						VOCABULARY_VERY_POOR_2: ' has struggled to learn and acquire most of the vocabulary throughout the course.',
					},
					{
						value: 'VOCABULARY_VERY_POOR_3',
						label: ' usually mixes up and confuses words.',
						VOCABULARY_VERY_POOR_3: ' usually mixes up and confuses words.',
					},
					{
						value: 'VOCABULARY_VERY_POOR_4',
						label: ' cannot remember and recall even simple vocabulary.',
						VOCABULARY_VERY_POOR_4: ' cannot remember and recall even simple vocabulary.',
					},
					{
						value: 'VOCABULARY_VERY_POOR_5',
						label: ' often struggles to use familiar and common vocabulary items when speaking or writing.',
						VOCABULARY_VERY_POOR_5: ' often struggles to use familiar and common vocabulary items when speaking or writing.',
					},
					{
						value: 'VOCABULARY_VERY_POOR_6',
						label: ' cannot spell words accurately.',
						VOCABULARY_VERY_POOR_6: ' cannot spell words accurately.',
					},
					{
						value: 'VOCABULARY_VERY_POOR_7',
						label: ' is not confident to use new words without prompting and support.',
						VOCABULARY_VERY_POOR_7: ' is not confident to use new words without prompting and support.',
					},
				],
			},
		],
	},
	Grammar: {
		skills: 'Grammar',
		key: 'GRAMMAR',
		rates: [
			{
				id: 1,
				show: true,
				label: 'Very Good',
				value: 'VERY_GOOD',
				values: [
					{
						value: 'GRAMMAR_VERY_GOOD_1',
						label: 'They have a very wide range of grammar knowledge and are able to use this accurately and appropriately.',
						GRAMMAR_VERY_GOOD_1: 'They have a very wide range of grammar knowledge and are able to use this accurately and appropriately.',
					},
					{
						value: 'GRAMMAR_VERY_GOOD_2',
						label: 'They are able to understand new structures and concepts easily.',
						GRAMMAR_VERY_GOOD_2: 'They are able to understand new structures and concepts easily.',
					},
					{
						value: 'GRAMMAR_VERY_GOOD_3',
						label: 'They are very confident to use newly acquired grammar structures when speaking or writing.',
						GRAMMAR_VERY_GOOD_3: 'They are very confident to use newly acquired grammar structures when speaking or writing.',
					},
					{
						value: 'GRAMMAR_VERY_GOOD_4',
						label: 'They have very little hesitation when using the grammar structures learnt throughout the course.',
						GRAMMAR_VERY_GOOD_4: 'They have very little hesitation when using the grammar structures learnt throughout the course.',
					},
					{
						value: 'GRAMMAR_VERY_GOOD_5',
						label: 'They are able to use a wide range of grammatical structures accurately and fluently.',
						GRAMMAR_VERY_GOOD_5: 'They are able to use a wide range of grammatical structures accurately and fluently.',
					},
					{
						value: 'GRAMMAR_VERY_GOOD_6',
						label: 'They can use even complex grammatical structures with ease when speaking or writing.',
						GRAMMAR_VERY_GOOD_6: 'They can use even complex grammatical structures with ease when speaking or writing.',
					},
					{
						value: 'GRAMMAR_VERY_GOOD_7',
						label: 'They can use new structures accurately with it not impacting spoken fluency.',
						GRAMMAR_VERY_GOOD_7: 'They can use new structures accurately with it not impacting spoken fluency.',
					},
					{
						value: 'GRAMMAR_VERY_GOOD_8',
						label: 'They use the grammatical structures appropriately.',
						GRAMMAR_VERY_GOOD_8: 'They use the grammatical structures appropriately.',
					},
				],
			},
			{
				id: 2,
				show: true,
				label: 'Good',
				value: 'GOOD',
				values: [
					{
						value: 'GRAMMAR_GOOD_1',
						label: 'They have a very good range of grammar knowledge for this level and are able to use this mostly accurately and appropriately.',
						GRAMMAR_GOOD_1:
							'They have a very good range of grammar knowledge for this level and are able to use this mostly accurately and appropriately.',
					},
					{
						value: 'GRAMMAR_GOOD_2',
						label: 'They are often able to understand new structures and concepts without too much support from the teacher.',
						GRAMMAR_GOOD_2: 'They are often able to understand new structures and concepts without too much support from the teacher.',
					},
					{
						value: 'GRAMMAR_GOOD_3',
						label: 'They are confident to use newly acquired grammar structures with some support from the teacher.',
						GRAMMAR_GOOD_3: 'They are confident to use newly acquired grammar structures with some support from the teacher.',
					},
					{
						value: 'GRAMMAR_GOOD_4',
						label: 'They sometimes hesitate slightly when using new grammar structures.',
						GRAMMAR_GOOD_4: 'They sometimes hesitate slightly when using new grammar structures.',
					},
					{
						value: 'GRAMMAR_GOOD_5',
						label: 'They are able to use a wide range of grammatical structures with only minor errors in accuracy.',
						GRAMMAR_GOOD_5: 'They are able to use a wide range of grammatical structures with only minor errors in accuracy.',
					},
					{
						value: 'GRAMMAR_GOOD_6',
						label: 'They are mostly able to use the correct word order but sometimes make errors.',
						GRAMMAR_GOOD_6: 'They are mostly able to use the correct word order but sometimes make errors.',
					},
				],
			},
			{
				id: 3,
				show: true,
				label: 'Ok',
				value: 'OK',
				values: [
					{
						value: 'GRAMMAR_OK_1',
						label: 'They have an OK range of grammatical knowledge for this level but often struggle to use it accurately.',
						GRAMMAR_OK_1: 'They have an OK range of grammatical knowledge for this level but often struggle to use it accurately.',
					},
					{
						value: 'GRAMMAR_OK_2',
						label: 'They often need a lot of support from the teacher to be able to understand and use new grammatical structures.',
						GRAMMAR_OK_2: 'They often need a lot of support from the teacher to be able to understand and use new grammatical structures.',
					},
					{
						value: 'GRAMMAR_OK_3',
						label:
							'They sometimes struggle with confidence to experiment with new grammatical structures but can use them with some support from the teacher.',
						GRAMMAR_OK_3:
							'They sometimes struggle with confidence to experiment with new grammatical structures but can use them with some support from the teacher.',
					},
					{
						value: 'GRAMMAR_OK_4',
						label: 'They have a good knowledge of grammar but can sometimes make accuracy errors when using different structures.',
					},
					{
						value: 'GRAMMAR_OK_5',
						label: 'They often use grammatical structures accurately but can sometimes use them in the wrong situation.',
						GRAMMAR_OK_5: 'They often use grammatical structures accurately but can sometimes use them in the wrong situation.',
					},
					{
						value: 'GRAMMAR_OK_6',
						label: 'They understand new grammatical concepts and structures but often cannot recall or use them when speaking and writing.',
						GRAMMAR_OK_6: 'They understand new grammatical concepts and structures but often cannot recall or use them when speaking and writing.',
					},
					{
						value: 'GRAMMAR_OK_7',
						label: 'They often use grammatical structures accurately but can sometimes make errors in word order.',
						GRAMMAR_OK_7: 'They often use grammatical structures accurately but can sometimes make errors in word order.',
					},
				],
			},
			{
				id: 4,
				show: true,
				label: 'Poor',
				value: 'POOR',
				values: [
					{
						value: 'GRAMMAR_POOR_1',
						label: 'They do not have an adequate range of grammar at this point in the course.',
						GRAMMAR_POOR_1: 'They do not have an adequate range of grammar at this point in the course.',
					},
					{
						value: 'GRAMMAR_POOR_2',
						label: 'They often cannot understand and use new grammatical structures without a lot of support from the teacher.',
						GRAMMAR_POOR_2: 'They often cannot understand and use new grammatical structures without a lot of support from the teacher.',
					},
					{
						value: 'GRAMMAR_POOR_3',
						label: 'They have very little confidence to use and experiment with newly learnt grammatical structures.',
						GRAMMAR_POOR_3: 'They have very little confidence to use and experiment with newly learnt grammatical structures.',
					},
					{
						value: 'GRAMMAR_POOR_4',
						label: 'They often make errors with grammatical accuracy which can impede understanding.',
						GRAMMAR_POOR_4: 'They often make errors with grammatical accuracy which can impede understanding.',
					},
					{
						value: 'GRAMMAR_POOR_5',
						label: 'They often use grammatical structures inaccurately and inappropriately.',
						GRAMMAR_POOR_5: 'They often use grammatical structures inaccurately and inappropriately.',
					},
					{
						value: 'GRAMMAR_POOR_6',
						label: 'They often cannot recall or use them when speaking and writing.',
						GRAMMAR_POOR_6: 'They often cannot recall or use them when speaking and writing.',
					},
					{
						value: 'GRAMMAR_POOR_7',
						label: 'They often make errors in word order which can impede understanding.',
						GRAMMAR_POOR_7: 'They often make errors in word order which can impede understanding.',
					},
				],
			},
			{
				id: 5,
				show: true,
				label: 'Very Poor',
				value: 'VERY_POOR',
				values: [
					{
						value: 'GRAMMAR_VERY_POOR_1',
						label: 'They have a very narrow range of grammar at this point in the course.',
						GRAMMAR_VERY_POOR_1: 'They have a very narrow range of grammar at this point in the course.',
					},
					{
						value: 'GRAMMAR_VERY_POOR_2',
						label: 'They often cannot understand and use new grammatical structures.',
						GRAMMAR_VERY_POOR_2: 'They often cannot understand and use new grammatical structures.',
					},
					{
						value: 'GRAMMAR_VERY_POOR_3',
						label: 'They have no confidence to use and experiment with newly learnt grammatical structures.',
						GRAMMAR_VERY_POOR_3: 'They have no confidence to use and experiment with newly learnt grammatical structures.',
					},
					{
						value: 'GRAMMAR_VERY_POOR_4',
						label: 'They cannot use grammatical structures without making significant errors.',
						GRAMMAR_VERY_POOR_4: 'They cannot use grammatical structures without making significant errors.',
					},
					{
						value: 'GRAMMAR_VERY_POOR_5',
						label: 'They usually are unable to use grammatical structures appropriately.',
						GRAMMAR_VERY_POOR_5: 'They usually are unable to use grammatical structures appropriately.',
					},
					{
						value: 'GRAMMAR_VERY_POOR_6',
						label: 'They cannot recall or use grammatical structures when speaking and writing.',
						GRAMMAR_VERY_POOR_6: 'They cannot recall or use grammatical structures when speaking and writing.',
					},
					{
						value: 'GRAMMAR_VERY_POOR_7',
						label: 'They make significant errors in word order which can impede understanding.',
						GRAMMAR_VERY_POOR_7: 'They make significant errors in word order which can impede understanding.',
					},
				],
			},
		],
	},
	Pronunciation: {
		skills: 'Pronunciation',
		key: 'PRONUNCIATION',
		rates: [
			{
				id: 1,
				show: true,
				label: 'Very Good',
				value: 'VERY_GOOD',
				values: [
					{
						value: 'PRONUNCIATION_VERY_GOOD_1',
						label: ' is able to pronounce the sounds of newly learnt words accurately and naturally.',
						PRONUNCIATION_VERY_GOOD_1: ' is able to pronounce the sounds of newly learnt words accurately and naturally.',
					},
					{
						value: 'PRONUNCIATION_VERY_GOOD_2',
						label: ' can use intonation and stress when speaking naturally and effectively.',
						PRONUNCIATION_VERY_GOOD_2: ' can use intonation and stress when speaking naturally and effectively.',
					},
					{
						value: 'PRONUNCIATION_VERY_GOOD_3',
						label: ' speaks with a natural rhythm and cadence.',
						PRONUNCIATION_VERY_GOOD_3: ' speaks with a natural rhythm and cadence.',
					},
					{
						value: 'PRONUNCIATION_VERY_GOOD_4',
						label: ' is able to speak very clearly and be understood easily.',
						PRONUNCIATION_VERY_GOOD_4: ' is able to speak very clearly and be understood easily.',
					},
					{
						value: 'PRONUNCIATION_VERY_GOOD_5',
						label: ' is able to produce sounds well that are often difficult for other Vietnamese learners.',
						PRONUNCIATION_VERY_GOOD_5: ' is able to produce sounds well that are often difficult for other Vietnamese learners.',
					},
					{
						value: 'PRONUNCIATION_VERY_GOOD_6',
						label: ' is able to use features of connected speech clearly and consistently.',
						PRONUNCIATION_VERY_GOOD_6: ' is able to use features of connected speech clearly and consistently.',
					},
					{
						value: 'PRONUNCIATION_VERY_GOOD_7',
						label: ' is able to listen to and understand connected speech well.',
						PRONUNCIATION_VERY_GOOD_7: ' is able to listen to and understand connected speech well.',
					},
					{
						value: 'PRONUNCIATION_VERY_GOOD_8',
						label: ' is able to use word stress accurately and effectively.',
						PRONUNCIATION_VERY_GOOD_8: ' is able to use word stress accurately and effectively.',
					},
				],
			},
			{
				id: 2,
				show: true,
				label: 'Good',
				value: 'GOOD',
				values: [
					{
						value: 'PRONUNCIATION_GOOD_1',
						label: ' is generally able to pronounce the sounds of newly learnt words accurately.',
						PRONUNCIATION_GOOD_1: ' is generally able to pronounce the sounds of newly learnt words accurately.',
					},
					{
						value: 'PRONUNCIATION_GOOD_2',
						label: ' can use sentence stress when speaking naturally and effectively but may need practice with natural intonation.',
						PRONUNCIATION_GOOD_2:
							' can use sentence stress when speaking naturally and effectively but may need practice with natural intonation.',
					},
					{
						value: 'PRONUNCIATION_GOOD_3',
						label: ' is sometimes able to speak with a natural rhythm and stress.',
						PRONUNCIATION_GOOD_3: ' is sometimes able to speak with a natural rhythm and stress.',
					},
					{
						value: 'PRONUNCIATION_GOOD_4',
						label:
							' is generally able to speak clearly and be understood but should focus on some sounds that are often complex for Vietnamese learners.',
						PRONUNCIATION_GOOD_4:
							' is generally able to speak clearly and be understood but should focus on some sounds that are often complex for Vietnamese learners.',
					},
					{
						value: 'PRONUNCIATION_GOOD_5',
						label:
							' is able to produce individual and blended sounds well in isolation but can struggle when using them in words or connected speech.',
						PRONUNCIATION_GOOD_5:
							' is able to produce individual and blended sounds well in isolation but can struggle when using them in words or connected speech.',
					},
					{
						value: 'PRONUNCIATION_GOOD_6',
						label: ' is able to use features of connected speech when repeating after the teacher but cannot produce these independently.',
						PRONUNCIATION_GOOD_6:
							' is able to use features of connected speech when repeating after the teacher but cannot produce these independently.',
					},
					{
						value: 'PRONUNCIATION_GOOD_7',
						label: ' is able to listen to and understand connected speech well but needs practice producing this.',
						PRONUNCIATION_GOOD_7: ' is able to listen to and understand connected speech well but needs practice producing this.',
					},
					{
						value: 'PRONUNCIATION_GOOD_8',
						label: ' is often able to use word stress accurately but can sometimes place the stress on the wrong syllable.',
						PRONUNCIATION_GOOD_8: ' is often able to use word stress accurately but can sometimes place the stress on the wrong syllable.',
					},
				],
			},
			{
				id: 3,
				show: true,
				label: 'Ok',
				value: 'OK',
				values: [
					{
						value: 'PRONUNCIATION_OK_1',
						label: ' is often able to pronounce the sounds of newly learnt words but can struggle with some more complex words or sounds.',
						PRONUNCIATION_OK_1:
							' is often able to pronounce the sounds of newly learnt words but can struggle with some more complex words or sounds.',
					},
					{
						value: 'PRONUNCIATION_OK_2',
						label: ' can sometimes use sentence stress when speaking but speech can often slip and become more monotonal.',
						PRONUNCIATION_OK_2: ' can sometimes use sentence stress when speaking but speech can often slip and become more monotonal.',
					},
					{
						value: 'PRONUNCIATION_OK_3',
						label:
							' can produce sounds and words with accurate pronunciation but is not usually able to speak with a natural rhythm and stress.',
						PRONUNCIATION_OK_3:
							' can produce sounds and words with accurate pronunciation but is not usually able to speak with a natural rhythm and stress.',
					},
					{
						value: 'PRONUNCIATION_OK_4',
						label: ' can usually speak with accurate pronunciation but sometimes errors in producing sounds can impede understanding.',
						PRONUNCIATION_OK_4:
							' can usually speak with accurate pronunciation but sometimes errors in producing sounds can impede understanding.',
					},
					{
						value: 'PRONUNCIATION_OK_5',
						label:
							' is able to produce individual and blended sounds well in isolation but can struggle when using them in words or connected speech.',
						PRONUNCIATION_OK_5:
							' is able to produce individual and blended sounds well in isolation but can struggle when using them in words or connected speech.',
					},
					{
						value: 'PRONUNCIATION_OK_6',
						label: ' is able to produce individual and blended sounds well in isolation but cannot use features of connected speech.',
						PRONUNCIATION_OK_6:
							' is able to produce individual and blended sounds well in isolation but cannot use features of connected speech.',
					},
					{
						value: 'PRONUNCIATION_OK_7',
						label:
							' can often be understood and use simple pronunciation features but struggles to listen to and understand features of connected speech.',
						PRONUNCIATION_OK_7:
							' can often be understood and use simple pronunciation features but struggles to listen to and understand features of connected speech.',
					},
					{
						value: 'PRONUNCIATION_OK_8',
						label: ' is often able to use word stress accurately but can make errors that impede understanding.',
						PRONUNCIATION_OK_8: ' is often able to use word stress accurately but can make errors that impede understanding.',
					},
				],
			},
			{
				id: 4,
				show: true,
				label: 'Poor',
				value: 'POOR',
				values: [
					{
						value: 'PRONUNCIATION_POOR_1',
						label: ' is often unable to pronounce the sounds of newly learnt words accurately.',
						PRONUNCIATION_POOR_1: ' is often unable to pronounce the sounds of newly learnt words accurately.',
					},
					{
						value: 'PRONUNCIATION_POOR_2',
						label: ' cannot use sentence stress when speaking fluently.',
						PRONUNCIATION_POOR_2: ' cannot use sentence stress when speaking fluently.',
					},
					{
						value: 'PRONUNCIATION_POOR_3',
						label: ' can pronounce individual sounds and blends in isolation but struggles to produce them accurately in words or sentences.',
						PRONUNCIATION_POOR_3:
							' can pronounce individual sounds and blends in isolation but struggles to produce them accurately in words or sentences.',
					},
					{
						value: 'PRONUNCIATION_POOR_4',
						label: ' is generally unable to speak clearly and errors with individual sounds can impede understanding.',
						PRONUNCIATION_POOR_4: ' is generally unable to speak clearly and errors with individual sounds can impede understanding.',
					},
					{
						value: 'PRONUNCIATION_POOR_5',
						label: ' often struggles with individual sounds or consonant clusters.',
						PRONUNCIATION_POOR_5: ' often struggles with individual sounds or consonant clusters.',
					},
					{
						value: 'PRONUNCIATION_POOR_6',
						label: ' cannot produce accurate pronunciation without a lot of support from the teacher and repetition.',
						PRONUNCIATION_POOR_6: ' cannot produce accurate pronunciation without a lot of support from the teacher and repetition.',
					},
					{
						value: 'PRONUNCIATION_POOR_7',
						label: ' is unable to understand features of connected speech',
						PRONUNCIATION_POOR_7: ' is unable to understand features of connected speech',
					},
					{
						value: 'PRONUNCIATION_POOR_8',
						label: ' is unable to use word stress accurately without support from the teacher.',
						PRONUNCIATION_POOR_8: ' is unable to use word stress accurately without support from the teacher.',
					},
				],
			},
			{
				id: 5,
				show: true,
				label: 'Very Poor',
				value: 'VERY_POOR',
				values: [
					{
						value: 'PRONUNCIATION_VERY_POOR_1',
						label: ' is unable to pronounce the sounds of newly learnt words and this impedes understanding.',
						PRONUNCIATION_VERY_POOR_1: ' is unable to pronounce the sounds of newly learnt words and this impedes understanding.',
					},
					{
						value: 'PRONUNCIATION_VERY_POOR_2',
						label: ' cannot use sentence stress or even repeat correct stress patterns with support from the teacher.',
						PRONUNCIATION_VERY_POOR_2: ' cannot use sentence stress or even repeat correct stress patterns with support from the teacher.',
					},
					{
						value: 'PRONUNCIATION_VERY_POOR_3',
						label: ' can struggle to produce individual sounds even in isolation.',
						PRONUNCIATION_VERY_POOR_3: ' can struggle to produce individual sounds even in isolation.',
					},
					{
						value: 'PRONUNCIATION_VERY_POOR_4',
						label: ' is unable to speak clearly and errors with individual sounds impede understanding.',
						PRONUNCIATION_VERY_POOR_4: ' is unable to speak clearly and errors with individual sounds impede understanding.',
					},
					{
						value: 'PRONUNCIATION_VERY_POOR_5',
						label: ' struggles with individual sounds or consonant clusters.',
						PRONUNCIATION_VERY_POOR_5: ' struggles with individual sounds or consonant clusters.',
					},
					{
						value: 'PRONUNCIATION_VERY_POOR_6',
						label: ' cannot produce accurate pronunciation even with a lot of support from the teacher and repetition.',
						PRONUNCIATION_VERY_POOR_6: ' cannot produce accurate pronunciation even with a lot of support from the teacher and repetition.',
					},
					{
						value: 'PRONUNCIATION_VERY_POOR_7',
						label: ' is unable to understand features of connected speech',
						PRONUNCIATION_VERY_POOR_7: ' is unable to understand features of connected speech',
					},
					{
						value: 'PRONUNCIATION_VERY_POOR_8',
						label: ' is unable to use word stress accurately even with a lot of support from the teacher.',
						PRONUNCIATION_VERY_POOR_8: ' is unable to use word stress accurately even with a lot of support from the teacher.',
					},
				],
			},
		],
	},
	Behavior: {
		skills: 'Behavior',
		key: 'BEHAVIOUR',
		rates: [
			{
				id: 1,
				show: true,
				label: 'Very Good',
				value: 'VERY_GOOD',
				values: [
					{
						value: 'BEHAVIOUR_VERY_GOOD_1',
						label: ' has exemplary behaviour and is a good example for their peers.',
						BEHAVIOUR_VERY_GOOD_1: ' has exemplary behaviour and is a good example for their peers.',
					},
					{
						value: 'BEHAVIOUR_VERY_GOOD_2',
						label: ' can be relied upon to behave impeccably in every lesson.',
						BEHAVIOUR_VERY_GOOD_2: ' can be relied upon to behave impeccably in every lesson.',
					},
					{
						value: 'BEHAVIOUR_VERY_GOOD_3',
						label: ' is always willing to support their peers and help them complete tasks.',
						BEHAVIOUR_VERY_GOOD_3: ' is always willing to support their peers and help them complete tasks.',
					},
					{
						value: 'BEHAVIOUR_VERY_GOOD_4',
						label: ' has a very good understanding of what is expected of an Apollo student - and exceeds those expectations.',
						BEHAVIOUR_VERY_GOOD_4: ' has a very good understanding of what is expected of an Apollo student - and exceeds those expectations.',
					},
					{
						value: 'BEHAVIOUR_VERY_GOOD_5',
						label: ' is very respectful to their teachers and their peers.',
						BEHAVIOUR_VERY_GOOD_5: ' is very respectful to their teachers and their peers.',
					},
					{
						value: 'BEHAVIOUR_VERY_GOOD_6',
						label: " always follows the rules, and even reminds their classmates of what's expected.",
						BEHAVIOUR_VERY_GOOD_6: " always follows the rules, and even reminds their classmates of what's expected.",
					},
					{
						value: 'BEHAVIOUR_VERY_GOOD_7',
						label: ' sets a good example by using English as much as possible in class consistently.',
						BEHAVIOUR_VERY_GOOD_7: ' sets a good example by using English as much as possible in class consistently.',
					},
					{
						value: 'BEHAVIOUR_VERY_GOOD_8',
						label: ' is a pleasure to have in class due to their impeccable behaviour.',
						BEHAVIOUR_VERY_GOOD_8: ' is a pleasure to have in class due to their impeccable behaviour.',
					},
					{
						value: 'BEHAVIOUR_VERY_GOOD_9',
						label: ' is a model student with regards to behaviour.',
						BEHAVIOUR_VERY_GOOD_9: ' is a model student with regards to behaviour.',
					},
				],
			},
			{
				id: 2,
				show: true,
				label: 'Good',
				value: 'GOOD',
				values: [
					{
						value: 'BEHAVIOUR_GOOD_1',
						label: ' consistently demonstrates good behaviour.',
						BEHAVIOUR_GOOD_1: ' consistently demonstrates good behaviour.',
					},
					{
						value: 'BEHAVIOUR_GOOD_2',
						label: ' sets a good example by following the class rules consistently.',
						BEHAVIOUR_GOOD_2: ' sets a good example by following the class rules consistently.',
					},
					{
						value: 'BEHAVIOUR_GOOD_3',
						label: ' always meets behavioural expectations in class.',
						BEHAVIOUR_GOOD_3: ' always meets behavioural expectations in class.',
					},
					{
						value: 'BEHAVIOUR_GOOD_4',
						label: ' tries their best to avoid using Vietnamese in the classroom - keep it up!',
						BEHAVIOUR_GOOD_4: ' tries their best to avoid using Vietnamese in the classroom - keep it up!',
					},
					{
						value: 'BEHAVIOUR_GOOD_5',
						label: ' is a very respectful student.',
						BEHAVIOUR_GOOD_5: ' is a very respectful student.',
					},
					{
						value: 'BEHAVIOUR_GOOD_6',
						label: ' shares with others and enjoys being helpful in class.',
						BEHAVIOUR_GOOD_6: ' shares with others and enjoys being helpful in class.',
					},
					{
						value: 'BEHAVIOUR_GOOD_7',
						label: ' is a positive influence on their peers through good behaviour.',
						BEHAVIOUR_GOOD_7: ' is a positive influence on their peers through good behaviour.',
					},
				],
			},
			{
				id: 3,
				show: true,
				label: 'Ok',
				value: 'OK',
				values: [
					{
						value: 'BEHAVIOUR_OK_1',
						label: ' is able to follow the rules most of the time and has no behavioral issues.',
						BEHAVIOUR_OK_1: ' is able to follow the rules most of the time and has no behavioral issues.',
					},
					{
						value: 'BEHAVIOUR_OK_2',
						label: ' generally avoids using Vietnamese in class, but has to be reminded from time to time.',
						BEHAVIOUR_OK_2: ' generally avoids using Vietnamese in class, but has to be reminded from time to time.',
					},
					{
						value: 'BEHAVIOUR_OK_3',
						label: ' is respectful, but sometimes pushes the limit a little.',
						BEHAVIOUR_OK_3: ' is respectful, but sometimes pushes the limit a little.',
					},
					{
						value: 'BEHAVIOUR_OK_4',
						label: ' meets the behavioral expectations for an Apollo class.	',
						BEHAVIOUR_OK_4: ' meets the behavioral expectations for an Apollo class.',
					},
					{
						value: 'BEHAVIOUR_OK_5',
						label: ' can have disruptful moments in class, but is generally a good student.',
						BEHAVIOUR_OK_5: ' can have disruptful moments in class, but is generally a good student.',
					},
					{
						value: 'BEHAVIOUR_OK_6',
						label: ' follows the rules most of the time, but needs reminding occasionally.',
						BEHAVIOUR_OK_6: ' follows the rules most of the time, but needs reminding occasionally.',
					},
					{
						value: 'BEHAVIOUR_OK_7',
						label: ' is mostly respectful of their peers, but sometimes needs the rules to be reinforced.',
						BEHAVIOUR_OK_7: ' is mostly respectful of their peers, but sometimes needs the rules to be reinforced.',
					},
				],
			},
			{
				id: 4,
				show: true,
				label: 'Poor',
				value: 'POOR',
				values: [
					{
						value: 'BEHAVIOUR_POOR_1',
						label: ' regularly displays poor behaviour which can disrupt the class.',
						BEHAVIOUR_POOR_1: ' regularly displays poor behaviour which can disrupt the class.',
					},
					{
						value: 'BEHAVIOUR_POOR_2',
						label: ' can be a disruptive influence which negatively affects the class for their peers.',
						BEHAVIOUR_POOR_2: ' can be a disruptive influence which negatively affects the class for their peers.',
					},
					{
						value: 'BEHAVIOUR_POOR_3',
						label: ' often disobeys class rules.',
						BEHAVIOUR_POOR_3: ' often disobeys class rules.',
					},
					{
						value: 'BEHAVIOUR_POOR_4',
						label: ' speaks Vietnamese a lot in class, and needs to be constantly reminded of expectations.',
						BEHAVIOUR_POOR_4: ' speaks Vietnamese a lot in class, and needs to be constantly reminded of expectations.',
					},
					{
						value: 'BEHAVIOUR_POOR_5',
						label: ' is failing to meet behavioral expectations.',
						BEHAVIOUR_POOR_5: ' is failing to meet behavioral expectations.',
					},
					{
						value: 'BEHAVIOUR_POOR_6',
						label: ' can show a lack of respect towards thier peers and not be a team player.',
						BEHAVIOUR_POOR_6: ' can show a lack of respect towards thier peers and not be a team player.',
					},
					{
						value: 'BEHAVIOUR_POOR_7',
						label: ' has trouble with sharing and needs to work on this.',
						BEHAVIOUR_POOR_7: ' has trouble with sharing and needs to work on this.',
					},
					{
						value: 'BEHAVIOUR_POOR_8',
						label: ' is not able to behave in an acceptable manner consistently.',
						BEHAVIOUR_POOR_8: ' is not able to behave in an acceptable manner consistently.',
					},
				],
			},
			{
				id: 5,
				show: true,
				label: 'Very Poor',
				value: 'VERY_POOR',
				values: [
					{
						value: 'BEHAVIOUR_VERY_POOR_1',
						label: ' consistently behaves poorly and has a negative impact on the class.',
						BEHAVIOUR_VERY_POOR_1: ' consistently behaves poorly and has a negative impact on the class.',
					},
					{
						value: 'BEHAVIOUR_VERY_POOR_2',
						label: ' disrupts lessons regularly with their misbehaviour. This needs to be addressed.',
						BEHAVIOUR_VERY_POOR_2: ' disrupts lessons regularly with their misbehaviour. This needs to be addressed.',
					},
					{
						value: 'BEHAVIOUR_VERY_POOR_3',
						label: ' does not follow the rules in class.',
						BEHAVIOUR_VERY_POOR_3: ' does not follow the rules in class.',
					},
					{
						value: 'BEHAVIOUR_VERY_POOR_4',
						label: ' does not attempt to follow the class rules.',
						BEHAVIOUR_VERY_POOR_4: ' does not attempt to follow the class rules.',
					},
					{
						value: 'BEHAVIOUR_VERY_POOR_5',
						label: ' always uses Vietnamese in class despite regularly being reminded to not do so.',
						BEHAVIOUR_VERY_POOR_5: ' always uses Vietnamese in class despite regularly being reminded to not do so.',
					},
					{
						value: 'BEHAVIOUR_VERY_POOR_6',
						label: ' does not work well with others and derails group work.',
						BEHAVIOUR_VERY_POOR_6: ' does not work well with others and derails group work.',
					},
					{
						value: 'BEHAVIOUR_VERY_POOR_7',
						label: ' shows a lack of respect for their peers which negatively affects the class atmosphere.',
						BEHAVIOUR_VERY_POOR_7: ' shows a lack of respect for their peers which negatively affects the class atmosphere.',
					},
					{
						value: 'BEHAVIOUR_VERY_POOR_8',
						label: ' is disrespectful towards the teacher.',
						BEHAVIOUR_VERY_POOR_8: ' is disrespectful towards the teacher.',
					},
				],
			},
		],
	},
	Speaking: {
		skills: 'Speaking',
		key: 'SPEAKING',
		rates: [
			{
				id: 1,
				show: true,
				label: 'Very Good',
				value: 'VERY_GOOD',
				values: [
					{
						value: 'SPEAKING_VERY_GOOD_1',
						label: 'They are able to speak fluently and coherently even about unfamiliar topics.',
						SPEAKING_VERY_GOOD_1: 'They are able to speak fluently and coherently even about unfamiliar topics.',
					},
					{
						value: 'SPEAKING_VERY_GOOD_2',
						label: 'They are able to speak accurately and confidently and experiment with new language from throughout the course.',
						SPEAKING_VERY_GOOD_2: 'They are able to speak accurately and confidently and experiment with new language from throughout the course.',
					},
					{
						value: 'SPEAKING_VERY_GOOD_3',
						label: 'They are able to speak coherently and convey meaning effectively.',
						SPEAKING_VERY_GOOD_3: 'They are able to speak coherently and convey meaning effectively.',
					},
					{
						value: 'SPEAKING_VERY_GOOD_4',
						label: 'They are able to speak at length for significant periods of time.',
						SPEAKING_VERY_GOOD_4: 'They are able to speak at length for significant periods of time.',
					},
					{
						value: 'SPEAKING_VERY_GOOD_5',
						label: 'They are able to communicate and interact well with their classmates during speaking activities.',
						SPEAKING_VERY_GOOD_5: 'They are able to communicate and interact well with their classmates during speaking activities.',
					},
					{
						value: 'SPEAKING_VERY_GOOD_6',
						label: 'They are able to speak accurately and fluently and paraphrase well to convey meaning effectively.',
						SPEAKING_VERY_GOOD_6: 'They are able to speak accurately and fluently and paraphrase well to convey meaning effectively.',
					},
					{
						value: 'SPEAKING_VERY_GOOD_7',
						label: 'They rarely hesitate and are able to speak for extended periods without making significant errors.',
						SPEAKING_VERY_GOOD_7: 'They rarely hesitate and are able to speak for extended periods without making significant errors.',
					},
					{
						value: 'SPEAKING_VERY_GOOD_8',
						label: 'They use pauses effectively and appropriately when speaking.',
						SPEAKING_VERY_GOOD_8: 'They use pauses effectively and appropriately when speaking.',
					},
				],
			},
			{
				id: 2,
				show: true,
				label: 'Good',
				value: 'GOOD',
				values: [
					{
						value: 'SPEAKING_GOOD_1',
						label: 'They are able to speak fluently and coherently but may struggle with some unfamiliar topics.',
						SPEAKING_GOOD_1: 'They are able to speak fluently and coherently but may struggle with some unfamiliar topics.',
					},
					{
						value: 'SPEAKING_GOOD_2',
						label: 'They are able to speak accurately and confidently but could experiment more with new language from throughout the course.',
						SPEAKING_GOOD_2:
							'They are able to speak accurately and confidently but could experiment more with new language from throughout the course.',
					},
					{
						value: 'SPEAKING_GOOD_3',
						label: 'They are generally able to speak coherently and convey meaning effectively.',
						SPEAKING_GOOD_3: 'They are generally able to speak coherently and convey meaning effectively.',
					},
					{
						value: 'SPEAKING_GOOD_4',
						label: 'They are able to speak at length for significant periods of time but may lose coherence at times.',
						SPEAKING_GOOD_4: 'They are able to speak at length for significant periods of time but may lose coherence at times.',
					},
					{
						value: 'SPEAKING_GOOD_5',
						label: 'They are generally able to communicate and interact well with their classmates during speaking activities.',
						SPEAKING_GOOD_5: 'They are generally able to communicate and interact well with their classmates during speaking activities.',
					},
					{
						value: 'SPEAKING_GOOD_6',
						label:
							'They are generally able to paraphrase well to convey meaning effectively but can often hesitate if they cannot remember the correct word or phrase.',
						SPEAKING_GOOD_6:
							'They are generally able to paraphrase well to convey meaning effectively but can often hesitate if they cannot remember the correct word or phrase.',
					},
					{
						value: 'SPEAKING_GOOD_7',
						label: 'They rarely hesitate and are able to speak for extended periods but may sometimes make errors when speaking for a long time.',
						SPEAKING_GOOD_7:
							'They rarely hesitate and are able to speak for extended periods but may sometimes make errors when speaking for a long time.',
					},
					{
						value: 'SPEAKING_GOOD_8',
						label: 'They generally use pauses effectively but may sometimes use them at the wrong time.',
						SPEAKING_GOOD_8: 'They generally use pauses effectively but may sometimes use them at the wrong time.',
					},
				],
			},
			{
				id: 3,
				show: true,
				label: 'Ok',
				value: 'OK',
				values: [
					{
						value: 'SPEAKING_OK_1',
						label: 'They are usually able to speak fluently and coherently about familiar topics but struggle with less common topics.',
						SPEAKING_OK_1: 'They are usually able to speak fluently and coherently about familiar topics but struggle with less common topics.',
					},
					{
						value: 'SPEAKING_OK_2',
						label: 'They are able to speak confidently but often make errors with new language from throughout the course.',
						SPEAKING_OK_2: 'They are able to speak confidently but often make errors with new language from throughout the course.',
					},
					{
						value: 'SPEAKING_OK_3',
						label: 'They are generally able to convey meaning effectively but can struggle with coherence.',
						SPEAKING_OK_3: 'They are generally able to convey meaning effectively but can struggle with coherence.',
					},
					{
						value: 'SPEAKING_OK_4',
						label: 'They are able to speak well for short periods but can struggle when required to speak for a longer time.',
						SPEAKING_OK_4: 'They are able to speak well for short periods but can struggle when required to speak for a longer time.',
					},
					{
						value: 'SPEAKING_OK_5',
						label:
							'They are generally able to convey their ideas to classmates during speaking activities but can struggle to make conversation natural and interactive.',
						SPEAKING_OK_5:
							'They are generally able to convey their ideas to classmates during speaking activities but can struggle to make conversation natural and interactive.',
					},
					{
						value: 'SPEAKING_OK_6',
						label: 'They can usually convey meaning well when speaking but struggle to paraphrase if they do not know the correct word or phrase.',
						SPEAKING_OK_6:
							'They can usually convey meaning well when speaking but struggle to paraphrase if they do not know the correct word or phrase.',
					},
					{
						value: 'SPEAKING_OK_7',
						label: 'They can hesitate often when speaking but are generally able to speak fluently.',
						SPEAKING_OK_7: 'They can hesitate often when speaking but are generally able to speak fluently.',
					},
					{
						value: 'SPEAKING_OK_8',
						label: 'They can generally speak for extended periods but may only use short utterances when speaking.',
						SPEAKING_OK_8: 'They can generally speak for extended periods but may only use short utterances when speaking.',
					},
				],
			},
			{
				id: 4,
				show: true,
				label: 'Poor',
				value: 'POOR',
				values: [
					{
						value: 'SPEAKING_POOR_1',
						label: 'They are only able to speak about very familiar topics and struggle with fluency when talking about less common topics.',
						SPEAKING_POOR_1:
							'They are only able to speak about very familiar topics and struggle with fluency when talking about less common topics.',
					},
					{
						value: 'SPEAKING_POOR_2',
						label: 'They often struggle with confidence when speaking.',
						SPEAKING_POOR_2: 'They often struggle with confidence when speaking.',
					},
					{
						value: 'SPEAKING_POOR_3',
						label: 'They generally struggle with coherence when speaking and this can impede understanding.',
						SPEAKING_POOR_3: 'They generally struggle with coherence when speaking and this can impede understanding.',
					},
					{
						value: 'SPEAKING_POOR_4',
						label: 'They struggle with interaction during speaking activities with their classmates.',
						SPEAKING_POOR_4: 'They struggle with interaction during speaking activities with their classmates.',
					},
					{
						value: 'SPEAKING_POOR_5',
						label: 'They can often only use short utterances or words to convey meaning to their classmates.',
						SPEAKING_POOR_5: 'They can often only use short utterances or words to convey meaning to their classmates.',
					},
					{
						value: 'SPEAKING_POOR_6',
						label: 'They cannot paraphrase if they do not know the correct word or phrase.',
						SPEAKING_POOR_6: 'They cannot paraphrase if they do not know the correct word or phrase.',
					},
					{
						value: 'SPEAKING_POOR_7',
						label: 'They can hesitate often when speaking and this can impede understanding.',
						SPEAKING_POOR_7: 'They can hesitate often when speaking and this can impede understanding.',
					},
					{
						value: 'SPEAKING_POOR_8',
						label: 'They cannot generally speak for extended periods and may only use short utterances when speaking.',
						SPEAKING_POOR_8: 'They cannot generally speak for extended periods and may only use short utterances when speaking.',
					},
				],
			},
			{
				id: 5,
				show: true,
				label: 'Very Poor',
				value: 'VERY_POOR',
				values: [
					{
						value: 'SPEAKING_VERY_POOR_1',
						label: 'They are only unable to speak fluently even about very familiar topics.',
						SPEAKING_VERY_POOR_1: 'They are only unable to speak fluently even about very familiar topics.',
					},
					{
						value: 'SPEAKING_VERY_POOR_2',
						label: 'They often struggle with confidence when speaking.',
						SPEAKING_VERY_POOR_2: 'They often struggle with confidence when speaking.',
					},
					{
						value: 'SPEAKING_VERY_POOR_3',
						label: 'They struggle with coherence when speaking and this can impede understanding.',
						SPEAKING_VERY_POOR_3: 'They struggle with coherence when speaking and this can impede understanding.',
					},
					{
						value: 'SPEAKING_VERY_POOR_4',
						label: 'They struggle with interaction during speaking activities with their classmates.',
						SPEAKING_VERY_POOR_4: 'They struggle with interaction during speaking activities with their classmates.',
					},
					{
						value: 'SPEAKING_VERY_POOR_5',
						label: 'They can often only use single words to convey meaning to their classmates.',
						SPEAKING_VERY_POOR_5: 'They can often only use single words to convey meaning to their classmates.',
					},
					{
						value: 'SPEAKING_VERY_POOR_6',
						label: 'They are often unable to participate in interactive speaking activities with their classmates.',
						SPEAKING_VERY_POOR_6: 'They are often unable to participate in interactive speaking activities with their classmates.',
					},
					{
						value: 'SPEAKING_VERY_POOR_7',
						label: 'They hesitate a lot when speaking and this can impede understanding.',
						SPEAKING_VERY_POOR_7: 'They hesitate a lot when speaking and this can impede understanding.',
					},
					{
						value: 'SPEAKING_VERY_POOR_8',
						label: 'They cannot speak for extended periods and only use short utterances when speaking.',
						SPEAKING_VERY_POOR_8: 'They cannot speak for extended periods and only use short utterances when speaking.',
					},
				],
			},
		],
	},
	Listening: {
		skills: 'Listening',
		key: 'LISTENING',
		rates: [
			{
				id: 1,
				show: true,
				label: 'Very Good',
				value: 'VERY_GOOD',
				values: [
					{
						value: 'LISTENING_VERY_GOOD_1',
						label: ' is able to understand the overall meaning of extended speech consistently.',
						LISTENING_VERY_GOOD_1: ' is able to understand the overall meaning of extended speech consistently.',
					},
					{
						value: 'LISTENING_VERY_GOOD_2',
						label: ' can follow speech about an unfamiliar topic and understand the overall meaning.',
						LISTENING_VERY_GOOD_2: ' can follow speech about an unfamiliar topic and understand the overall meaning.',
					},
					{
						value: 'LISTENING_VERY_GOOD_3',
						label: ' can understand English being spoken at a natural pace.',
						LISTENING_VERY_GOOD_3: ' can understand English being spoken at a natural pace.',
					},
					{
						value: 'LISTENING_VERY_GOOD_4',
						label: " is able to understand regardless of the speaker's accent.",
						LISTENING_VERY_GOOD_4: " is able to understand regardless of the speaker's accent.",
					},
					{
						value: 'LISTENING_VERY_GOOD_5',
						label: ' can understand details after listening to information only once.',
						LISTENING_VERY_GOOD_5: ' can understand details after listening to information only once.',
					},
					{
						value: 'LISTENING_VERY_GOOD_6',
						label: " has no trouble following the teacher's instructions.",
						LISTENING_VERY_GOOD_6: " has no trouble following the teacher's instructions.",
					},
					{
						value: 'LISTENING_VERY_GOOD_7',
						label: "'s listening skills are very strong and are exceeding expectations for the level.",
						LISTENING_VERY_GOOD_7: "'s listening skills are very strong and are exceeding expectations for the level.",
					},
					{
						value: 'LISTENING_VERY_GOOD_8',
						label: ' can count listening skills as one of their strengths.',
						LISTENING_VERY_GOOD_8: ' can count listening skills as one of their strengths.',
					},
				],
			},
			{
				id: 2,
				show: true,
				label: 'Good',
				value: 'GOOD',
				values: [
					{
						value: 'LISTENING_GOOD_1',
						label: ' is generally able to understand the overall meaning of extended speech.',
						LISTENING_GOOD_1: ' is generally able to understand the overall meaning of extended speech.',
					},
					{
						value: 'LISTENING_GOOD_2',
						label: ' can mostly understand authentic listening material.',
						LISTENING_GOOD_2: ' can mostly understand authentic listening material.',
					},
					{
						value: 'LISTENING_GOOD_3',
						label: " understands teacher's instructions and some more complex speech.",
						LISTENING_GOOD_3: " understands teacher's instructions and some more complex speech.",
					},
					{
						value: 'LISTENING_GOOD_4',
						label: "'s listening skills are progressing well.",
						LISTENING_GOOD_4: "'s listening skills are progressing well.",
					},
					{
						value: 'LISTENING_GOOD_5',
						label: ' has no problem understanding graded language.',
						LISTENING_GOOD_5: ' has no problem understanding graded language.',
					},
					{
						value: 'LISTENING_GOOD_6',
						label: ' can often understand speech at a natural pace.',
						LISTENING_GOOD_6: ' can often understand speech at a natural pace.',
					},
					{
						value: 'LISTENING_GOOD_7',
						label: ' can generally understand the gist of spoken communication.',
						LISTENING_GOOD_7: ' can generally understand the gist of spoken communication.',
					},
					{
						value: 'LISTENING_GOOD_8',
						label: ' has good listening skills and will continue to improve with this application.',
						LISTENING_GOOD_8: ' has good listening skills and will continue to improve with this application.',
					},
				],
			},
			{
				id: 3,
				show: true,
				label: 'Ok',
				value: 'OK',
				values: [
					{
						value: 'LISTENING_OK_1',
						label: ' is able to understand the songs from the course.',
						LISTENING_OK_1: ' is able to understand the songs from the course.',
					},
					{
						value: 'LISTENING_OK_2',
						label: "'s listening skills are progressing - keep practising outside of class.",
						LISTENING_OK_2: "'s listening skills are progressing - keep practising outside of class.",
					},
					{
						value: 'LISTENING_OK_3',
						label: ' sometimes does not follow meaning due to focusing on unfamiliar words.',
						LISTENING_OK_3: ' sometimes does not follow meaning due to focusing on unfamiliar words.',
					},
					{
						value: 'LISTENING_OK_4',
						label: ' can generally follow graded language and sometimes understand ungraded speech.',
						LISTENING_OK_4: ' can generally follow graded language and sometimes understand ungraded speech.',
					},
					{
						value: 'LISTENING_OK_5',
						label: ' is generally able to understand after listening for a second time.',
						LISTENING_OK_5: ' is generally able to understand after listening for a second time.',
					},
					{
						value: 'LISTENING_OK_6',
						label: ' often understands the general meaning, though can sometimes struggle with details.',
						LISTENING_OK_6: ' often understands the general meaning, though can sometimes struggle with details.',
					},
					{
						value: 'LISTENING_OK_7',
						label: ' should practice listening more often outside the classroom.',
						LISTENING_OK_7: ' should practice listening more often outside the classroom.',
					},
					{
						value: 'LISTENING_OK_8',
						label: ' can make listening a strength with regular practice.',
						LISTENING_OK_8: ' can make listening a strength with regular practice.',
					},
				],
			},
			{
				id: 4,
				show: true,
				label: 'Poor',
				value: 'POOR',
				values: [
					{
						value: 'LISTENING_POOR_1',
						label: ' needs to keep working on listening skills.',
						LISTENING_POOR_1: ' needs to keep working on listening skills.',
					},
					{
						value: 'LISTENING_POOR_2',
						label: ' requires more focus on listening practice to keep progressing.',
						LISTENING_POOR_2: ' requires more focus on listening practice to keep progressing.',
					},
					{
						value: 'LISTENING_POOR_3',
						label: " sometimes has difficulty understanding the teacher's instructions.",
						LISTENING_POOR_3: " sometimes has difficulty understanding the teacher's instructions.",
					},
					{
						value: 'LISTENING_POOR_4',
						label: ' has difficulty understanding speech at natural pace.',
						LISTENING_POOR_4: ' has difficulty understanding speech at natural pace.',
					},
					{
						value: 'LISTENING_POOR_5',
						label: ' cannot follow extended speech, but can follow short segments of speech.',
						LISTENING_POOR_5: ' cannot follow extended speech, but can follow short segments of speech.',
					},
					{
						value: 'LISTENING_POOR_6',
						label: ' should listen to more authentic listening materials outside of class.',
						LISTENING_POOR_6: ' should listen to more authentic listening materials outside of class.',
					},
					{
						value: 'LISTENING_POOR_7',
						label: ' can work on listening by using the songs from the Apollo Active App.',
						LISTENING_POOR_7: ' can work on listening by using the songs from the Apollo Active App.',
					},
					{
						value: 'LISTENING_POOR_8',
						label: " struggles to follow any speech that's not about a familiar topic.",
						LISTENING_POOR_8: " struggles to follow any speech that's not about a familiar topic.",
					},
				],
			},
			{
				id: 5,
				show: true,
				label: 'Very Poor',
				value: 'VERY_POOR',
				values: [
					{
						value: 'LISTENING_VERY_POOR_1',
						label: ' needs to practice listening much more, this is an area of weakness.',
						LISTENING_VERY_POOR_1: ' needs to practice listening much more, this is an area of weakness.',
					},
					{
						value: 'LISTENING_VERY_POOR_2',
						label: ' is having difficulty with listening tasks at this level.',
						LISTENING_VERY_POOR_2: ' is having difficulty with listening tasks at this level.',
					},
					{
						value: 'LISTENING_VERY_POOR_3',
						label: ' struggles to follow speech and requires multiple repetitions to understand.',
						LISTENING_VERY_POOR_3: ' struggles to follow speech and requires multiple repetitions to understand.',
					},
					{
						value: 'LISTENING_VERY_POOR_4',
						label: " has trouble with understanding the teacher's instructions.",
						LISTENING_VERY_POOR_4: " has trouble with understanding the teacher's instructions.",
					},
					{
						value: 'LISTENING_VERY_POOR_5',
						label: ' requires a lot of support to complete listening activities successfully.',
						LISTENING_VERY_POOR_5: ' requires a lot of support to complete listening activities successfully.',
					},
					{
						value: 'LISTENING_VERY_POOR_6',
						label: ' will have trouble progressing without improving listening skills.',
						LISTENING_VERY_POOR_6: ' will have trouble progressing without improving listening skills.',
					},
					{
						value: 'LISTENING_VERY_POOR_7',
						label: ' often misunderstands despite multiple attempts at listening.',
						LISTENING_VERY_POOR_7: ' often misunderstands despite multiple attempts at listening.',
					},
					{
						value: 'LISTENING_VERY_POOR_8',
						label: ' must focus more when practising listening if they are to improve.',
						LISTENING_VERY_POOR_8: ' must focus more when practising listening if they are to improve.',
					},
				],
			},
		],
	},
	Reading: {
		skills: 'Reading',
		key: 'READING',
		rates: [
			{
				id: 1,
				show: true,
				label: 'Very Good',
				value: 'VERY_GOOD',
				values: [
					{
						value: 'READING_VERY_GOOD_1',
						label: 'They have demonstrated excellent reading skills for the level, keep it up!',
						READING_VERY_GOOD_1: 'They have demonstrated excellent reading skills for the level, keep it up!',
					},
					{
						value: 'READING_VERY_GOOD_2',
						label: 'They are able to read for detail with accuracy in both long and short texts.',
						READING_VERY_GOOD_2: 'They are able to read for detail with accuracy in both long and short texts.',
					},
					{
						value: 'READING_VERY_GOOD_3',
						label: 'They understand ideas from the first read.',
						READING_VERY_GOOD_3: 'They understand ideas from the first read.',
					},
					{
						value: 'READING_VERY_GOOD_4',
						label: 'They are able to deduce meaning through context when encountering unfamiliar vocabulary.',
						READING_VERY_GOOD_4: 'They are able to deduce meaning through context when encountering unfamiliar vocabulary.',
					},
					{
						value: 'READING_VERY_GOOD_5',
						label: 'They have demonstrated an enjoyment in reading which should be encouraged.',
						READING_VERY_GOOD_5: 'They have demonstrated an enjoyment in reading which should be encouraged.',
					},
					{
						value: 'READING_VERY_GOOD_6',
						label: 'They are progressing really well in terms of reading, and are exceeding expectations.',
						READING_VERY_GOOD_6: 'They are progressing really well in terms of reading, and are exceeding expectations.',
					},
					{
						value: 'READING_VERY_GOOD_7',
						label: 'They are able to read at a good speed without it impeding their understanding.',
						READING_VERY_GOOD_7: 'They are able to read at a good speed without it impeding their understanding.',
					},
					{
						value: 'READING_VERY_GOOD_8',
						label: 'They are able to quickly locate information they need in longer texts.',
						READING_VERY_GOOD_8: 'They are able to quickly locate information they need in longer texts.',
					},
				],
			},
			{
				id: 2,
				show: true,
				label: 'Good',
				value: 'GOOD',
				values: [
					{
						value: 'READING_GOOD_1',
						label: 'They are doing well with reading and the teacher is happy with their progress.',
						READING_GOOD_1: 'They are doing well with reading and the teacher is happy with their progress.',
					},
					{
						value: 'READING_GOOD_2',
						label: 'They can generally read for detail successfully, even with longer texts.',
						READING_GOOD_2: 'They can generally read for detail successfully, even with longer texts.',
					},
					{
						value: 'READING_GOOD_3',
						label: 'They can consider themselves as a good reader of English which helps their general improvement.',
						READING_GOOD_3: 'They can consider themselves as a good reader of English which helps their general improvement.',
					},
					{
						value: 'READING_GOOD_4',
						label: 'They read at a good speed for this level whilst understanding most of the texts after the first read.',
						READING_GOOD_4: 'They read at a good speed for this level whilst understanding most of the texts after the first read.',
					},
					{
						value: 'READING_GOOD_5',
						label: 'They are confident enough to tackle quite complex texts.',
						READING_GOOD_5: 'They are confident enough to tackle quite complex texts.',
					},
					{
						value: 'READING_GOOD_6',
						label: 'They seem to enjoy reading and should be encouraged to keep doing so.',
						READING_GOOD_6: 'They seem to enjoy reading and should be encouraged to keep doing so.',
					},
					{
						value: 'READING_GOOD_7',
						label: 'They can mostly understand the overall meaning of various text types.',
						READING_GOOD_7: 'They can mostly understand the overall meaning of various text types.',
					},
					{
						value: 'READING_GOOD_8',
						label: 'They are good scanners of a text, which will really help develop exam skills.',
						READING_GOOD_8: 'They are good scanners of a text, which will really help develop exam skills.',
					},
				],
			},
			{
				id: 3,
				show: true,
				label: 'Ok',
				value: 'OK',
				values: [
					{
						value: 'READING_OK_1',
						label: 'They are meeting expectations with regards to reading for this level.',
						READING_OK_1: 'They are meeting expectations with regards to reading for this level.',
					},
					{
						value: 'READING_OK_2',
						label: 'They are steadily improving and should be encouraged to read in English more outside of class.',
						READING_OK_2: 'They are steadily improving and should be encouraged to read in English more outside of class.',
					},
					{
						value: 'READING_OK_3',
						label: 'They generally do well with reading on familiar topics.',
						READING_OK_3: 'They generally do well with reading on familiar topics.',
					},
					{
						value: 'READING_OK_4',
						label: "They don't have a problem with short texts, but should practice with longer texts to further develop their reading skills.",
						READING_OK_4:
							"They don't have a problem with short texts, but should practice with longer texts to further develop their reading skills.",
					},
					{
						value: 'READING_OK_5',
						label: 'They sometimes allow unfamiliar vocabulary to impede their overall understanding of a text.',
						READING_OK_5: 'They sometimes allow unfamiliar vocabulary to impede their overall understanding of a text.',
					},
					{
						value: 'READING_OK_6',
						label: 'They usually successfully complete reading tasks, but often need multiple reads to do so.',
						READING_OK_6: 'They usually successfully complete reading tasks, but often need multiple reads to do so.',
					},
					{
						value: 'READING_OK_7',
						label: 'They have trouble identifying important information quickly.',
						READING_OK_7: 'They have trouble identifying important information quickly.',
					},
					{
						value: 'READING_OK_8',
						label: 'They should practice reading more outside of class to increase their ability to read and understand more quickly.',
						READING_OK_8: 'They should practice reading more outside of class to increase their ability to read and understand more quickly.',
					},
				],
			},
			{
				id: 4,
				show: true,
				label: 'Poor',
				value: 'POOR',
				values: [
					{
						value: 'READING_POOR_1',
						label: 'They are not strong readers and need to focus on this to avoid falling behind.',
						READING_POOR_1: 'They are not strong readers and need to focus on this to avoid falling behind.',
					},
					{
						value: 'READING_POOR_2',
						label: 'They are not meeting expectations for reading and this could become a problem if not worked on.',
						READING_POOR_2: 'They are not meeting expectations for reading and this could become a problem if not worked on.',
					},
					{
						value: 'READING_POOR_3',
						label: 'They rarely understand the overall meaning, and need to read a text multiple times if they are to do so.',
						READING_POOR_3: 'They rarely understand the overall meaning, and need to read a text multiple times if they are to do so.',
					},
					{
						value: 'READING_POOR_4',
						label: 'They can only tackle short texts on familiar topics.',
						READING_POOR_4: 'They can only tackle short texts on familiar topics.',
					},
					{
						value: 'READING_POOR_5',
						label: 'They are a slow reader and this is having a negative impact on their progress.',
						READING_POOR_5: 'They are a slow reader and this is having a negative impact on their progress.',
					},
					{
						value: 'READING_POOR_6',
						label: "They don't seem to enjoy reading, and as a result are not getting adequate practice.",
						READING_POOR_6: "They don't seem to enjoy reading, and as a result are not getting adequate practice.",
					},
					{
						value: 'READING_POOR_7',
						label: 'They are often unable to locate important information to complete reading tasks with accuracy.',
						READING_POOR_7: 'They are often unable to locate important information to complete reading tasks with accuracy.',
					},
					{
						value: 'READING_POOR_8',
						label: 'They struggle to understand the meaning of texts aimed at learners of this level.',
						READING_POOR_8: 'They struggle to understand the meaning of texts aimed at learners of this level.',
					},
				],
			},
			{
				id: 5,
				show: true,
				label: 'Very Poor',
				value: 'VERY_POOR',
				values: [
					{
						value: 'READING_VERY_POOR_1',
						label: 'They are falling behind with regards to reading and require a lot more practice.',
						READING_VERY_POOR_1: 'They are falling behind with regards to reading and require a lot more practice.',
					},
					{
						value: 'READING_VERY_POOR_2',
						label: 'They are not meeting expectations for reading and need to work on this at home.',
						READING_VERY_POOR_2: 'They are not meeting expectations for reading and need to work on this at home.',
					},
					{
						value: 'READING_VERY_POOR_3',
						label: 'They should read outside of class if they are to catch up with the expectation at this level.',
						READING_VERY_POOR_3: 'They should read outside of class if they are to catch up with the expectation at this level.',
					},
					{
						value: 'READING_VERY_POOR_4',
						label: 'They really struggle with reading skills for this level and need to focus on this.',
						READING_VERY_POOR_4: 'They really struggle with reading skills for this level and need to focus on this.',
					},
					{
						value: 'READING_VERY_POOR_5',
						label: "They often can't understand texts at this level, even after multiple reads.",
						READING_VERY_POOR_5: "They often can't understand texts at this level, even after multiple reads.",
					},
					{
						value: 'READING_VERY_POOR_6',
						label: 'They are derailed by unfamiliar words which impedes understanding.',
						READING_VERY_POOR_6: 'They are derailed by unfamiliar words which impedes understanding.',
					},
					{
						value: 'READING_VERY_POOR_7',
						label: 'They have difficulty understanding the overall meaning of written text.',
						READING_VERY_POOR_7: 'They have difficulty understanding the overall meaning of written text.',
					},
					{
						value: 'READING_VERY_POOR_8',
						label: 'They have difficulty with reading, even if it is on a familiar topic.',
						READING_VERY_POOR_8: 'They have difficulty with reading, even if it is on a familiar topic.',
					},
				],
			},
		],
	},
	Writing: {
		skills: 'Writing',
		key: 'WRITING',
		rates: [
			{
				id: 1,
				show: true,
				label: 'Very Good',
				value: 'VERY_GOOD',
				values: [
					{
						value: 'WRITING_VERY_GOOD_1',
						label: ' is a strong writer and is exceeding expectations for the level.',
						WRITING_VERY_GOOD_1: ' is a strong writer and is exceeding expectations for the level.',
					},
					{
						value: 'WRITING_VERY_GOOD_2',
						label: ' is able to write with a high level of accuracy, including spelling and punctuation.',
						WRITING_VERY_GOOD_2: ' is able to write with a high level of accuracy, including spelling and punctuation.',
					},
					{
						value: 'WRITING_VERY_GOOD_3',
						label: "'s writing skills are excellent and this is a clear strength of theirs.",
						WRITING_VERY_GOOD_3: "'s writing skills are excellent and this is a clear strength of theirs.",
					},
					{
						value: 'WRITING_VERY_GOOD_4',
						label: "'s spelling is very good and they rarely make mistakes.",
						WRITING_VERY_GOOD_4: "'s spelling is very good and they rarely make mistakes.",
					},
					{
						value: 'WRITING_VERY_GOOD_5',
						label: ' produces coherent written communication to a higher level than expected.',
						WRITING_VERY_GOOD_5: ' produces coherent written communication to a higher level than expected.',
					},
					{
						value: 'WRITING_VERY_GOOD_6',
						label: ' is able to use grammatical structures studied accurately in writing.',
						WRITING_VERY_GOOD_6: ' is able to use grammatical structures studied accurately in writing.',
					},
					{
						value: 'WRITING_VERY_GOOD_7',
						label: ' edits their own written work and is able to correct themselves.',
						WRITING_VERY_GOOD_7: ' edits their own written work and is able to correct themselves.',
					},
					{
						value: 'WRITING_VERY_GOOD_8',
						label: ' enjoys writing and this is shown through the quality of their work.',
						WRITING_VERY_GOOD_8: ' enjoys writing and this is shown through the quality of their work.',
					},
				],
			},
			{
				id: 2,
				show: true,
				label: 'Good',
				value: 'GOOD',
				values: [
					{
						value: 'WRITING_GOOD_1',
						label: ' is progressing very well with writing.',
						WRITING_GOOD_1: ' is progressing very well with writing.',
					},
					{
						value: 'WRITING_GOOD_2',
						label: ' writes with a good level of accuracy consistently.',
						WRITING_GOOD_2: ' writes with a good level of accuracy consistently.',
					},
					{
						value: 'WRITING_GOOD_3',
						label: ' is a strong speller and only has issues with more complex vocabulary.',
						WRITING_GOOD_3: ' is a strong speller and only has issues with more complex vocabulary.',
					},
					{
						value: 'WRITING_GOOD_4',
						label: ' uses punctuation correctly most of the time.',
						WRITING_GOOD_4: ' uses punctuation correctly most of the time.',
					},
					{
						value: 'WRITING_GOOD_5',
						label: ' seems to enjoy writing and this should be encouraged.',
						WRITING_GOOD_5: ' seems to enjoy writing and this should be encouraged.',
					},
					{
						value: 'WRITING_GOOD_6',
						label: "'s writing skills are strong for this level.",
						WRITING_GOOD_6: "'s writing skills are strong for this level.",
					},
					{
						value: 'WRITING_GOOD_7',
						label: ' has a good level of written fluency which aids their progress in this regard.',
						WRITING_GOOD_7: ' has a good level of written fluency which aids their progress in this regard.',
					},
					{
						value: 'WRITING_GOOD_8',
						label: ' generally uses known grammatical structures with accuracy in their written work.',
						WRITING_GOOD_8: ' generally uses known grammatical structures with accuracy in their written work.',
					},
				],
			},
			{
				id: 3,
				show: true,
				label: 'Ok',
				value: 'OK',
				values: [
					{
						value: 'WRITING_OK_1',
						label: ' is progressing well with writing and meets the expectations for this level.',
						WRITING_OK_1: ' is progressing well with writing and meets the expectations for this level.',
					},
					{
						value: 'WRITING_OK_2',
						label: ' is generally accurate with use of familiar vocabulary and grammatical structures in writing.',
						WRITING_OK_2: ' is generally accurate with use of familiar vocabulary and grammatical structures in writing.',
					},
					{
						value: 'WRITING_OK_3',
						label: "'s written fluency is continuing to develop at a good pace.",
						WRITING_OK_3: "'s written fluency is continuing to develop at a good pace.",
					},
					{
						value: 'WRITING_OK_4',
						label: ' uses basic punctuation accurately.',
						WRITING_OK_4: ' uses basic punctuation accurately.',
					},
					{
						value: 'WRITING_OK_5',
						label: ' would produce better written work if they edited it themselves first before submitting it.',
						WRITING_OK_5: ' would produce better written work if they edited it themselves first before submitting it.',
					},
					{
						value: 'WRITING_OK_6',
						label: ' sometimes lacks organisation in their written work but the message is always clearly communicated.',
						WRITING_OK_6: ' sometimes lacks organisation in their written work but the message is always clearly communicated.',
					},
					{
						value: 'WRITING_OK_7',
						label: " is doing fine with regards to writing, but doesn't stray away from simple language.",
						WRITING_OK_7: " is doing fine with regards to writing, but doesn't stray away from simple language.",
					},
					{
						value: 'WRITING_OK_8',
						label: ' can write quite well, but is hesitant to use newly acquired language in written work.',
						WRITING_OK_8: ' can write quite well, but is hesitant to use newly acquired language in written work.',
					},
				],
			},
			{
				id: 4,
				show: true,
				label: 'Poor',
				value: 'POOR',
				values: [
					{
						value: 'WRITING_POOR_1',
						label: ' needs to focus on writing as this is an area of weakness.',
						WRITING_POOR_1: ' needs to focus on writing as this is an area of weakness.',
					},
					{
						value: 'WRITING_POOR_2',
						label: "'s written work is not up to the expected standard for this level.",
						WRITING_POOR_2: "'s written work is not up to the expected standard for this level.",
					},
					{
						value: 'WRITING_POOR_3',
						label: ' often makes mistakes with simple spelling.',
						WRITING_POOR_3: ' often makes mistakes with simple spelling.',
					},
					{
						value: 'WRITING_POOR_4',
						label: ' lacks grammatical accuracy in written work.',
						WRITING_POOR_4: ' lacks grammatical accuracy in written work.',
					},
					{
						value: 'WRITING_POOR_5',
						label: ' struggles to convey their message through written communication.',
						WRITING_POOR_5: ' struggles to convey their message through written communication.',
					},
					{
						value: 'WRITING_POOR_6',
						label: ' is unable to use punctuation accurately.',
						WRITING_POOR_6: ' is unable to use punctuation accurately.',
					},
					{
						value: 'WRITING_POOR_7',
						label: ' regularly produces error-strewn written work.',
						WRITING_POOR_7: ' regularly produces error-strewn written work.',
					},
					{
						value: 'WRITING_POOR_8',
						label: ' is not progressing as expected with writing and needs further practice outside the classroom.',
						WRITING_POOR_8: ' is not progressing as expected with writing and needs further practice outside the classroom.',
					},
				],
			},
			{
				id: 5,
				show: true,
				label: 'Very Poor',
				value: 'VERY_POOR',
				values: [
					{
						value: 'WRITING_VERY_POOR_1',
						label: ' is having a lot of difficulty with writing and needs to practice much more outside of class.',
						WRITING_VERY_POOR_1: ' is having a lot of difficulty with writing and needs to practice much more outside of class.',
					},
					{
						value: 'WRITING_VERY_POOR_2',
						label: ' is failing to meet expectations with regards to writing for this level.',
						WRITING_VERY_POOR_2: ' is failing to meet expectations with regards to writing for this level.',
					},
					{
						value: 'WRITING_VERY_POOR_3',
						label: ' cannot produce written communication to the required standard for this level.',
						WRITING_VERY_POOR_3: ' cannot produce written communication to the required standard for this level.',
					},
					{
						value: 'WRITING_VERY_POOR_4',
						label: "'s written work is consistently error-strewn.",
						WRITING_VERY_POOR_4: "'s written work is consistently error-strewn.",
					},
					{
						value: 'WRITING_VERY_POOR_5',
						label: ' regularly makes lots of mistakes with accuracy of spelling and grammar.',
						WRITING_VERY_POOR_5: ' regularly makes lots of mistakes with accuracy of spelling and grammar.',
					},
					{
						value: 'WRITING_VERY_POOR_6',
						label: ' is a very slow writer and this negatively impacts their ability to complete tasks and progress.',
						WRITING_VERY_POOR_6: ' is a very slow writer and this negatively impacts their ability to complete tasks and progress.',
					},
					{
						value: 'WRITING_VERY_POOR_7',
						label: "'s written work is often unintelligible.",
						WRITING_VERY_POOR_7: "'s written work is often unintelligible.",
					},
					{
						value: 'WRITING_VERY_POOR_8',
						label: ' still struggles with the basics of writing and needs to put a lot of effort in this to improve.',
						WRITING_VERY_POOR_8: ' still struggles with the basics of writing and needs to put a lot of effort in this to improve.',
					},
				],
			},
		],
	},
	Future_skills: {
		skills: 'Future_skills',
		key: 'FUTURESKILLS',
		rates: [
			{
				id: 1,
				show: true,
				label: 'Very Good',
				value: 'VERY_GOOD',
				values: [
					{
						value: 'FUTURESKILLS_VERY_GOOD_1',
						label: 'They are showing strong progress across various future skills and are a positive example for their peers.',
						FUTURESKILLS_VERY_GOOD_1: 'They are showing strong progress across various future skills and are a positive example for their peers.',
					},
					{
						value: 'FUTURESKILLS_VERY_GOOD_2',
						label: 'They have demonstrated excellent problem solving and critical thinking skills in class.',
						FUTURESKILLS_VERY_GOOD_2: 'They have demonstrated excellent problem solving and critical thinking skills in class.',
					},
					{
						value: 'FUTURESKILLS_VERY_GOOD_3',
						label: 'They can interpret data accurately and convey its meaning.',
						FUTURESKILLS_VERY_GOOD_3: 'They can interpret data accurately and convey its meaning.',
					},
					{
						value: 'FUTURESKILLS_VERY_GOOD_4',
						label: 'They are self-aware and have a good level of emotional intelligence.',
						FUTURESKILLS_VERY_GOOD_4: 'They are self-aware and have a good level of emotional intelligence.',
					},
					{
						value: 'FUTURESKILLS_VERY_GOOD_5',
						label: 'They are showing excellent improvement in their presentation skills.',
						FUTURESKILLS_VERY_GOOD_5: 'They are showing excellent improvement in their presentation skills.',
					},
					{
						value: 'FUTURESKILLS_VERY_GOOD_6',
						label: 'They are a leader in the classroom and set an example for their peers.',
						FUTURESKILLS_VERY_GOOD_6: 'They are a leader in the classroom and set an example for their peers.',
					},
					{
						value: 'FUTURESKILLS_VERY_GOOD_7',
						label: "They are able to express opinions and counter other people's ideas constructively.",
						FUTURESKILLS_VERY_GOOD_7: "They are able to express opinions and counter other people's ideas constructively.",
					},
					{
						value: 'FUTURESKILLS_VERY_GOOD_8',
						label: 'They have developed excellent learning habits and this is reflected in their performance in class.',
						FUTURESKILLS_VERY_GOOD_8: 'They have developed excellent learning habits and this is reflected in their performance in class.',
					},
					{
						value: 'FUTURESKILLS_VERY_GOOD_9',
						label: 'They are creative thinkers which enhances their work in class.',
						FUTURESKILLS_VERY_GOOD_9: 'They are creative thinkers which enhances their work in class.',
					},
				],
			},
			{
				id: 2,
				show: true,
				label: 'Good',
				value: 'GOOD',
				values: [
					{
						value: 'FUTURESKILLS_GOOD_1',
						label: 'They are progressing with various future skills and are a positive member of the class.',
						FUTURESKILLS_GOOD_1: 'They are progressing with various future skills and are a positive member of the class.',
					},
					{
						value: 'FUTURESKILLS_GOOD_2',
						label: 'They can think critically and have shown good problem solving skills.',
						FUTURESKILLS_GOOD_2: 'They can think critically and have shown good problem solving skills.',
					},
					{
						value: 'FUTURESKILLS_GOOD_3',
						label: 'They are able to correctly interpret data and communicate that in English.',
						FUTURESKILLS_GOOD_3: 'They are able to correctly interpret data and communicate that in English.',
					},
					{
						value: 'FUTURESKILLS_GOOD_4',
						label: 'They understand their own emotions and have demonstrated emotional regulation in class.',
						FUTURESKILLS_GOOD_4: 'They understand their own emotions and have demonstrated emotional regulation in class.',
					},
					{
						value: 'FUTURESKILLS_GOOD_5',
						label: 'They are open-minded and curious about other cultures and seek to expand their mind.',
						FUTURESKILLS_GOOD_5: 'They are open-minded and curious about other cultures and seek to expand their mind.',
					},
					{
						value: 'FUTURESKILLS_GOOD_6',
						label: 'They have developed good learning habits and are a good example for their peers.',
						FUTURESKILLS_GOOD_6: 'They have developed good learning habits and are a good example for their peers.',
					},
					{
						value: 'FUTURESKILLS_GOOD_7',
						label: 'They are able to utilise their creativity in class, especially in project work.',
						FUTURESKILLS_GOOD_7: 'They are able to utilise their creativity in class, especially in project work.',
					},
					{
						value: 'FUTURESKILLS_GOOD_8',
						label: 'They are making good progress with their presentation skills.',
						FUTURESKILLS_GOOD_8: 'They are making good progress with their presentation skills.',
					},
				],
			},
			{
				id: 3,
				show: true,
				label: 'Ok',
				value: 'OK',
				values: [
					{
						value: 'FUTURESKILLS_OK_1',
						label: 'They are making progress across various future skills and are on track.',
						FUTURESKILLS_OK_1: 'They are making progress across various future skills and are on track.',
					},
					{
						value: 'FUTURESKILLS_OK_2',
						label: 'They can usually solve problems, but sometimes require peer support.',
						FUTURESKILLS_OK_2: 'They can usually solve problems, but sometimes require peer support.',
					},
					{
						value: 'FUTURESKILLS_OK_3',
						label: 'They are improving their presentation skills, with continued practice they will keep getting better.',
						FUTURESKILLS_OK_3: 'They are improving their presentation skills, with continued practice they will keep getting better.',
					},
					{
						value: 'FUTURESKILLS_OK_4',
						label: 'They are benefitting from some good learning habits and they can further improve this to enable better progress.',
						FUTURESKILLS_OK_4: 'They are benefitting from some good learning habits and they can further improve this to enable better progress.',
					},
					{
						value: 'FUTURESKILLS_OK_5',
						label: 'They contribute to the class positively but rarely take a leadership role in group work.',
						FUTURESKILLS_OK_5: 'They contribute to the class positively but rarely take a leadership role in group work.',
					},
					{
						value: 'FUTURESKILLS_OK_6',
						label: 'They are developing a more open-minded approach to learning about and understanding other cultures.',
						FUTURESKILLS_OK_6: 'They are developing a more open-minded approach to learning about and understanding other cultures.',
					},
					{
						value: 'FUTURESKILLS_OK_7',
						label: 'They can think critically, but often need support and prompting to inspire their ideas.',
						FUTURESKILLS_OK_7: 'They can think critically, but often need support and prompting to inspire their ideas.',
					},
					{
						value: 'FUTURESKILLS_OK_8',
						label: 'They understand opposing views, but struggle to present opposing ideas of their own.',
						FUTURESKILLS_OK_8: 'They understand opposing views, but struggle to present opposing ideas of their own.',
					},
				],
			},
			{
				id: 4,
				show: true,
				label: 'Poor',
				value: 'POOR',
				values: [
					{
						value: 'FUTURESKILLS_POOR_1',
						label: 'They have difficulty with critical thinking and need to work on this to aid their general progression.',
						FUTURESKILLS_POOR_1: 'They have difficulty with critical thinking and need to work on this to aid their general progression.',
					},
					{
						value: 'FUTURESKILLS_POOR_2',
						label: 'They follow their peers and rarely volunteer new ideas themselves.',
						FUTURESKILLS_POOR_2: 'They follow their peers and rarely volunteer new ideas themselves.',
					},
					{
						value: 'FUTURESKILLS_POOR_3',
						label: 'They struggle with accurately interpreting data.',
						FUTURESKILLS_POOR_3: 'They struggle with accurately interpreting data.',
					},
					{
						value: 'FUTURESKILLS_POOR_4',
						label: 'They have not shown much creativity in class, creative outputs should be encouraged outside of the classroom.',
						FUTURESKILLS_POOR_4: 'They have not shown much creativity in class, creative outputs should be encouraged outside of the classroom.',
					},
					{
						value: 'FUTURESKILLS_POOR_5',
						label: 'They seek a lot of teacher-support when asked to reflect on their learning.',
						FUTURESKILLS_POOR_5: 'They seek a lot of teacher-support when asked to reflect on their learning.',
					},
					{
						value: 'FUTURESKILLS_POOR_6',
						label: 'They have not developed good learning habits. This will greatly improve their performance if focused on.',
						FUTURESKILLS_POOR_6: 'They have not developed good learning habits. This will greatly improve their performance if focused on.',
					},
					{
						value: 'FUTURESKILLS_POOR_7',
						label: 'They need to develop their ability to be self-aware as this can have a negative impact on group work.',
						FUTURESKILLS_POOR_7: 'They need to develop their ability to be self-aware as this can have a negative impact on group work.',
					},
					{
						value: 'FUTURESKILLS_POOR_8',
						label: 'They have not improved their presentation skills to the level expected.',
						FUTURESKILLS_POOR_8: 'They have not improved their presentation skills to the level expected.',
					},
				],
			},
			{
				id: 5,
				show: true,
				label: 'Very Poor',
				value: 'VERY_POOR',
				values: [
					{
						value: 'FUTURESKILLS_VERY_POOR_1',
						label: "They struggle to think critically and often just follow their classmates' ideas rather than have their own.",
						FUTURESKILLS_VERY_POOR_1: "They struggle to think critically and often just follow their classmates' ideas rather than have their own.",
					},
					{
						value: 'FUTURESKILLS_VERY_POOR_2',
						label: 'They have not demonstrated problem solving skills to the level expected of them.',
						FUTURESKILLS_VERY_POOR_2: 'They have not demonstrated problem solving skills to the level expected of them.',
					},
					{
						value: 'FUTURESKILLS_VERY_POOR_3',
						label: 'They have difficulty being creative and this inhibits their output.',
						FUTURESKILLS_VERY_POOR_3: 'They have difficulty being creative and this inhibits their output.',
					},
					{
						value: 'FUTURESKILLS_VERY_POOR_4',
						label: 'They have not developed good learning habits which would greatly improve their chances of progression.',
						FUTURESKILLS_VERY_POOR_4: 'They have not developed good learning habits which would greatly improve their chances of progression.',
					},
					{
						value: 'FUTURESKILLS_VERY_POOR_5',
						label: 'They cannot accurately interpret data.',
						FUTURESKILLS_VERY_POOR_5: 'They cannot accurately interpret data.',
					},
					{
						value: 'FUTURESKILLS_VERY_POOR_6',
						label: 'They are having issues with their communication skills and this harms their ability to communicate their ideas effectively.',
						FUTURESKILLS_VERY_POOR_6:
							'They are having issues with their communication skills and this harms their ability to communicate their ideas effectively.',
					},
					{
						value: 'FUTURESKILLS_VERY_POOR_7',
						label: 'They have a lack of emotional awareness.',
						FUTURESKILLS_VERY_POOR_7: 'They have a lack of emotional awareness.',
					},
					{
						value: 'FUTURESKILLS_VERY_POOR_8',
						label: 'They are unable to reflect on their learning.',
						FUTURESKILLS_VERY_POOR_8: 'They are unable to reflect on their learning.',
					},
				],
			},
		],
	},
	Progress: {
		skills: 'Progress',
		key: 'PROGRESS',
		rates: [
			{
				id: 1,
				show: true,
				label: 'Very Good',
				value: 'VERY_GOOD',
				values: [
					{
						value: 'PROGRESS_VERY_GOOD_1',
						label: ' is far exceeding expectations for the level and has made excellent progress.',
						PROGRESS_VERY_GOOD_1: ' is far exceeding expectations for the level and has made excellent progress.',
					},
					{
						value: 'PROGRESS_VERY_GOOD_2',
						label: ' has developed a lot of confidence throughout the course so far and is continuing to improve.',
						PROGRESS_VERY_GOOD_2: ' has developed a lot of confidence throughout the course so far and is continuing to improve.',
					},
					{
						value: 'PROGRESS_VERY_GOOD_3',
						label: ' is developing automaticity and is able to use English effectively without conscious effort.',
						PROGRESS_VERY_GOOD_3: ' is developing automaticity and is able to use English effectively without conscious effort.',
					},
					{
						value: 'PROGRESS_VERY_GOOD_4',
						label: ' has made significant progress and is now able to use English extremely fluently compared to the level.',
						PROGRESS_VERY_GOOD_4: ' has made significant progress and is now able to use English extremely fluently compared to the level.',
					},
					{
						value: 'PROGRESS_VERY_GOOD_5',
						label: ' has acquired a lot of new language throughout the course and is making great progress.',
						PROGRESS_VERY_GOOD_5: ' has acquired a lot of new language throughout the course and is making great progress.',
					},
					{
						value: 'PROGRESS_VERY_GOOD_6',
						label: ' has improved accuracy significantly throughout the course.',
						PROGRESS_VERY_GOOD_6: ' has improved accuracy significantly throughout the course.',
					},
					{
						value: 'PROGRESS_VERY_GOOD_7',
						label: ' has made fantastic progress from the start of the course up until now.',
						PROGRESS_VERY_GOOD_7: ' has made fantastic progress from the start of the course up until now.',
					},
					{
						value: 'PROGRESS_VERY_GOOD_8',
						label: ' rarely needs support from the teacher and can use English independently.',
						PROGRESS_VERY_GOOD_8: ' rarely needs support from the teacher and can use English independently.',
					},
				],
			},
			{
				id: 2,
				show: true,
				label: 'Good',
				value: 'GOOD',
				values: [
					{
						value: 'PROGRESS_GOOD_1',
						label: ' is exceeding expectations for the level and has made good progress.',
						PROGRESS_GOOD_1: ' is exceeding expectations for the level and has made good progress.',
					},
					{
						value: 'PROGRESS_GOOD_2',
						label: ' has grown in confidence throughout the course.',
						PROGRESS_GOOD_2: ' has grown in confidence throughout the course.',
					},
					{
						value: 'PROGRESS_GOOD_3',
						label: ' is developing automaticity and is able to use the content from this course well with little conscious effort.',
						PROGRESS_GOOD_3: ' is developing automaticity and is able to use the content from this course well with little conscious effort.',
					},
					{
						value: 'PROGRESS_GOOD_4',
						label: ' has made progress in fluency above the level expected at this stage in the course. ',
						PROGRESS_GOOD_4: ' has made progress in fluency above the level expected at this stage in the course. ',
					},
					{
						value: 'PROGRESS_GOOD_5',
						label: ' has acquired most of the new language throughout the course effectively and is making great progress.',
						PROGRESS_GOOD_5: ' has acquired most of the new language throughout the course effectively and is making great progress.',
					},
					{
						value: 'PROGRESS_GOOD_6',
						label: ' has improved accuracy throughout the course and continues to make progress in this area.',
						PROGRESS_GOOD_6: ' has improved accuracy throughout the course and continues to make progress in this area.',
					},
					{
						value: 'PROGRESS_GOOD_7',
						label: ' has made good progress from the start of the course up until now.',
						PROGRESS_GOOD_7: ' has made good progress from the start of the course up until now.',
					},
					{
						value: 'PROGRESS_GOOD_8',
						label: ' sometimes needs support from the teacher but can use English independently most of the time.',
						PROGRESS_GOOD_8: ' sometimes needs support from the teacher but can use English independently most of the time.',
					},
				],
			},
			{
				id: 3,
				show: true,
				label: 'Ok',
				value: 'OK',
				values: [
					{
						value: 'PROGRESS_OK_1',
						label: ' is on track at this stage of the course but still has areas of improvement.',
						PROGRESS_OK_1: ' is on track at this stage of the course but still has areas of improvement.',
					},
					{
						value: 'PROGRESS_OK_2',
						label: ' is growing in confidence but may still be a little hesitant at times.',
						PROGRESS_OK_2: ' is growing in confidence but may still be a little hesitant at times.',
					},
					{
						value: 'PROGRESS_OK_3',
						label: ' still has to try hard and put a lot of effort in when using English.',
						PROGRESS_OK_3: ' still has to try hard and put a lot of effort in when using English.',
					},
					{
						value: 'PROGRESS_OK_4',
						label: ' has the level of fluency expected at this stage in the course but still has some areas to improve.',
						PROGRESS_OK_4: ' has the level of fluency expected at this stage in the course but still has some areas to improve.',
					},
					{
						value: 'PROGRESS_OK_5',
						label: ' has acquired a majority of new language throughout the course but still has some way to go to exceed expectations.',
						PROGRESS_OK_5:
							' has acquired a majority of new language throughout the course but still has some way to go to exceed expectations.',
					},
					{
						value: 'PROGRESS_OK_6',
						label: ' has made some progress with accuracy throughout the course but can still often make mistakes which impede understanding.',
						PROGRESS_OK_6:
							' has made some progress with accuracy throughout the course but can still often make mistakes which impede understanding.',
					},
					{
						value: 'PROGRESS_OK_7',
						label: ' has made OK progress from the start of the course up until now but still has a lot of areas to continue to develop.',
						PROGRESS_OK_7:
							' has made OK progress from the start of the course up until now but still has a lot of areas to continue to develop.',
					},
					{
						value: 'PROGRESS_OK_8',
						label: ' often needs support from the teacher but can sometimes use English independently.',
						PROGRESS_OK_8: ' often needs support from the teacher but can sometimes use English independently.',
					},
				],
			},
			{
				id: 4,
				show: true,
				label: 'Poor',
				value: 'POOR',
				values: [
					{
						value: 'PROGRESS_POOR_1',
						label: ' is not on track at this stage of the course and should continue to put effort in to improve.',
						PROGRESS_POOR_1: ' is not on track at this stage of the course and should continue to put effort in to improve.',
					},
					{
						value: 'PROGRESS_POOR_2',
						label:
							' is not overly confident at this stage in the course and may need extra practice outside of class and on Assignment.',
						PROGRESS_POOR_2:
							' is not overly confident at this stage in the course and may need extra practice outside of class and on Assignment.',
					},
					{
						value: 'PROGRESS_POOR_3',
						label: ' is not able to use English without significant effort.',
						PROGRESS_POOR_3: ' is not able to use English without significant effort.',
					},
					{
						value: 'PROGRESS_POOR_4',
						label: ' is behind the level of fluency expected at this stage of the course.',
						PROGRESS_POOR_4: ' is behind the level of fluency expected at this stage of the course.',
					},
					{
						value: 'PROGRESS_POOR_5',
						label: ' has not acquired enough new language to succeed at this stage of the course.',
						PROGRESS_POOR_5: ' has not acquired enough new language to succeed at this stage of the course.',
					},
					{
						value: 'PROGRESS_POOR_6',
						label: ' can often make accuracy errors and this is affecting the overall progress at this stage of the course.',
						PROGRESS_POOR_6: ' can often make accuracy errors and this is affecting the overall progress at this stage of the course.',
					},
					{
						value: 'PROGRESS_POOR_7',
						label: ' has not made the level of improvement that was expected at this stage of the course.',
						PROGRESS_POOR_7: ' has not made the level of improvement that was expected at this stage of the course.',
					},
					{
						value: 'PROGRESS_POOR_8',
						label: ' can sometimes use English independently but needs support from the teacher quite often.',
						PROGRESS_POOR_8: ' can sometimes use English independently but needs support from the teacher quite often.',
					},
				],
			},
			{
				id: 5,
				show: true,
				label: 'Very Poor',
				value: 'VERY_POOR',
				values: [
					{
						value: 'PROGRESS_VERY_POOR_1',
						label: ' is far behind the expectations for this stage of the course and should put more effort into Assignment.',
						PROGRESS_VERY_POOR_1:
							' is far behind the expectations for this stage of the course and should put more effort into Assignment.',
					},
					{
						value: 'PROGRESS_VERY_POOR_2',
						label: ' has no confidence when using English or inside an English environment and should focus more on Assignment.',
						PROGRESS_VERY_POOR_2:
							' has no confidence when using English or inside an English environment and should focus more on Assignment.',
					},
					{
						value: 'PROGRESS_VERY_POOR_3',
						label: ' is barely able to produce any English without strained effort and focus.',
						PROGRESS_VERY_POOR_3: ' is barely able to produce any English without strained effort and focus.',
					},
					{
						value: 'PROGRESS_VERY_POOR_4',
						label: ' does not have the level of fluency expected at this stage of the course and needs to practice more.',
						PROGRESS_VERY_POOR_4: ' does not have the level of fluency expected at this stage of the course and needs to practice more.',
					},
					{
						value: 'PROGRESS_VERY_POOR_5',
						label: ' has not acquired enough new language to succeed at this stage of the course and is far behind what is expected.',
						PROGRESS_VERY_POOR_5:
							' has not acquired enough new language to succeed at this stage of the course and is far behind what is expected.',
					},
					{
						value: 'PROGRESS_VERY_POOR_6',
						label: ' can often be difficult to understand because of major accuracy issues.',
						PROGRESS_VERY_POOR_6: ' can often be difficult to understand because of major accuracy issues.',
					},
					{
						value: 'PROGRESS_VERY_POOR_7',
						label: ' has made little to no progress throughout the duration of this course.',
						PROGRESS_VERY_POOR_7: ' has made little to no progress throughout the duration of this course.',
					},
					{
						value: 'PROGRESS_VERY_POOR_8',
						label: ' cannot use English independently and constantly needs the support of the teacher',
						PROGRESS_VERY_POOR_8: ' cannot use English independently and constantly needs the support of the teacher',
					},
				],
			},
		],
	},
	Participation: {
		skills: 'Participation',
		key: 'PARTICIPATION',
		rates: [
			{
				id: 1,
				show: true,
				label: 'Very Good',
				value: 'VERY_GOOD',
				values: [
					{
						value: 'PARTICIPATION_VERY_GOOD_1',
						label: 'They are very active participants in class which is great to see!',
						PARTICIPATION_VERY_GOOD_1: 'They are very active participants in class which is great to see!',
					},
					{
						value: 'PARTICIPATION_VERY_GOOD_2',
						label: 'They always engage with the lesson content or activities and set a good example for their peers.',
						PARTICIPATION_VERY_GOOD_2: 'They always engage with the lesson content or activities and set a good example for their peers.',
					},
					{
						value: 'PARTICIPATION_VERY_GOOD_3',
						label: 'They take a lead role in class which helps their peers complete tasks successfully.',
						PARTICIPATION_VERY_GOOD_3: 'They take a lead role in class which helps their peers complete tasks successfully.',
					},
					{
						value: 'PARTICIPATION_VERY_GOOD_4',
						label: 'They are happy to interact with the teacher and their peers in any activity.',
						PARTICIPATION_VERY_GOOD_4: 'They are happy to interact with the teacher and their peers in any activity.',
					},
					{
						value: 'PARTICIPATION_VERY_GOOD_5',
						label: 'They enjoy studying at Apollo, as shown by their active participation in every lesson.',
						PARTICIPATION_VERY_GOOD_5: 'They enjoy studying at Apollo, as shown by their active participation in every lesson.',
					},
					{
						value: 'PARTICIPATION_VERY_GOOD_6',
						label: 'They are confident and willing to try any task/game/activity/exercise in class.',
						PARTICIPATION_VERY_GOOD_6: 'They are confident and willing to try any task/game/activity/exercise in class.',
					},
					{
						value: 'PARTICIPATION_VERY_GOOD_7',
						label: 'They will try and use new language without being inhibited by the fear of making a mistake.',
						PARTICIPATION_VERY_GOOD_7: 'They will try and use new language without being inhibited by the fear of making a mistake.',
					},
					{
						value: 'PARTICIPATION_VERY_GOOD_8',
						label: 'They consistently volunteer to take part and answer questions.',
						PARTICIPATION_VERY_GOOD_8: 'They consistently volunteer to take part and answer questions.',
					},
					{
						value: 'PARTICIPATION_VERY_GOOD_9',
						label: 'They never shy away from being actively involved in the lesson.',
						PARTICIPATION_VERY_GOOD_9: 'They never shy away from being actively involved in the lesson.',
					},
				],
			},
			{
				id: 2,
				show: true,
				label: 'Good',
				value: 'GOOD',
				values: [
					{
						value: 'PARTICIPATION_GOOD_1',
						label: 'They regularly participate in all activity types.',
						PARTICIPATION_GOOD_1: 'They regularly participate in all activity types.',
					},
					{
						value: 'PARTICIPATION_GOOD_2',
						label: 'They rarely shy away from volunteering, and enjoy participating in activities.',
						PARTICIPATION_GOOD_2: 'They rarely shy away from volunteering, and enjoy participating in activities.',
					},
					{
						value: 'PARTICIPATION_GOOD_3',
						label: 'They participate actively and set a positive example for their peers.',
						PARTICIPATION_GOOD_3: 'They participate actively and set a positive example for their peers.',
					},
					{
						value: 'PARTICIPATION_GOOD_4',
						label: 'They focus on the task set in class and do their best to complete any exercise.',
						PARTICIPATION_GOOD_4: 'They focus on the task set in class and do their best to complete any exercise.',
					},
					{
						value: 'PARTICIPATION_GOOD_5',
						label: 'They work well with others, and will stay focused during group activities.',
						PARTICIPATION_GOOD_5: 'They work well with others, and will stay focused during group activities.',
					},
					{
						value: 'PARTICIPATION_GOOD_6',
						label: 'They are willing to make mistakes, which is very important for progression.',
						PARTICIPATION_GOOD_6: 'They are willing to make mistakes, which is very important for progression.',
					},
					{
						value: 'PARTICIPATION_GOOD_7',
						label: 'They enjoy studying at Apollo, which their good participation shows.',
						PARTICIPATION_GOOD_7: 'They enjoy studying at Apollo, which their good participation shows.',
					},
					{
						value: 'PARTICIPATION_GOOD_8',
						label: 'They are a confident member of the group who wants to take part in the lesson.',
						PARTICIPATION_GOOD_8: 'They are a confident member of the group who wants to take part in the lesson.',
					},
				],
			},
			{
				id: 3,
				show: true,
				label: 'Ok',
				value: 'OK',
				values: [
					{
						value: 'PARTICIPATION_OK_1',
						label: 'They participate in class, but sometimes need prompting by the teacher to do so.',
						PARTICIPATION_OK_1: 'They participate in class, but sometimes need prompting by the teacher to do so.',
					},
					{
						value: 'PARTICIPATION_OK_2',
						label: 'They are reasonably active participants in class, but there is room for improvement.',
						PARTICIPATION_OK_2: 'They are reasonably active participants in class, but there is room for improvement.',
					},
					{
						value: 'PARTICIPATION_OK_3',
						label: 'They sometimes actively volunteer to participate - do this more often, please!',
						PARTICIPATION_OK_3: 'They sometimes actively volunteer to participate - do this more often, please!',
					},
					{
						value: 'PARTICIPATION_OK_4',
						label: 'They can be very engaged in some lessons, but not consistently.',
						PARTICIPATION_OK_4: 'They can be very engaged in some lessons, but not consistently.',
					},
					{
						value: 'PARTICIPATION_OK_5',
						label: 'They are inconsistent with their level of participation in class.',
						PARTICIPATION_OK_5: 'They are inconsistent with their level of participation in class.',
					},
					{
						value: 'PARTICIPATION_OK_6',
						label: 'They sometimes enjoy being active in class, but this is not maintained consistently.',
						PARTICIPATION_OK_6: 'They sometimes enjoy being active in class, but this is not maintained consistently.',
					},
					{
						value: 'PARTICIPATION_OK_7',
						label: 'They can allow a lack of confidence to reduce their participation at times.',
						PARTICIPATION_OK_7: 'They can allow a lack of confidence to reduce their participation at times.',
					},
					{
						value: 'PARTICIPATION_OK_8',
						label: 'They will interact with their peers when asked to, but need prompting to do so.',
						PARTICIPATION_OK_8: 'They will interact with their peers when asked to, but need prompting to do so.',
					},
				],
			},
			{
				id: 4,
				show: true,
				label: 'Poor',
				value: 'POOR',
				values: [
					{
						value: 'PARTICIPATION_POOR_1',
						label: 'They do not meet the expectations for participation.',
						PARTICIPATION_POOR_1: 'They do not meet the expectations for participation.',
					},
					{
						value: 'PARTICIPATION_POOR_2',
						label: 'They rarely volunteer to participate and will often wait to get nominated before getting involved.',
						PARTICIPATION_POOR_2: 'They rarely volunteer to participate and will often wait to get nominated before getting involved.',
					},
					{
						value: 'PARTICIPATION_POOR_3',
						label: 'They are often disengaged with the lesson content and do not actively participate.',
						PARTICIPATION_POOR_3: 'They are often disengaged with the lesson content and do not actively participate.',
					},
					{
						value: 'PARTICIPATION_POOR_4',
						label: 'They prefer to be passive in the classroom and rarely actively participate.',
						PARTICIPATION_POOR_4: 'They prefer to be passive in the classroom and rarely actively participate.',
					},
					{
						value: 'PARTICIPATION_POOR_5',
						label: 'They need a lot of encouragement before they are willing to interact with their peers in group activities.',
						PARTICIPATION_POOR_5: 'They need a lot of encouragement before they are willing to interact with their peers in group activities.',
					},
					{
						value: 'PARTICIPATION_POOR_6',
						label: 'They avoid making mistakes by reducing their participation, which negatively impacts their practice opportunities.',
						PARTICIPATION_POOR_6:
							'They avoid making mistakes by reducing their participation, which negatively impacts their practice opportunities.',
					},
					{
						value: 'PARTICIPATION_POOR_7',
						label: 'They never take a lead role and would rather follow their more participative peers.',
						PARTICIPATION_POOR_7: 'They never take a lead role and would rather follow their more participative peers.',
					},
				],
			},
			{
				id: 5,
				show: true,
				label: 'Very Poor',
				value: 'VERY_POOR',
				values: [
					{
						value: 'PARTICIPATION_VERY_POOR_1',
						label: 'They will never volunteer and avoid participating as much as possible.',
						PARTICIPATION_VERY_POOR_1: 'They will never volunteer and avoid participating as much as possible.',
					},
					{
						value: 'PARTICIPATION_VERY_POOR_2',
						label: 'They show a very low level of engagement in class.',
						PARTICIPATION_VERY_POOR_2: 'They show a very low level of engagement in class.',
					},
					{
						value: 'PARTICIPATION_VERY_POOR_3',
						label: 'They are unwilling to participate and this impacts their practice opportunities.',
						PARTICIPATION_VERY_POOR_3: 'They are unwilling to participate and this impacts their practice opportunities.',
					},
					{
						value: 'PARTICIPATION_VERY_POOR_4',
						label: 'They are very passive in class and resist participating even when nominated by the teacher.',
						PARTICIPATION_VERY_POOR_4: 'They are very passive in class and resist participating even when nominated by the teacher.',
					},
					{
						value: 'PARTICIPATION_VERY_POOR_5',
						label: 'They lack confidence to the extent that it seriously inhibits their participation.',
						PARTICIPATION_VERY_POOR_5: 'They lack confidence to the extent that it seriously inhibits their participation.',
					},
					{
						value: 'PARTICIPATION_VERY_POOR_6',
						label: 'They will avoid interacting with their classmates whenever possible.',
						PARTICIPATION_VERY_POOR_6: 'They will avoid interacting with their classmates whenever possible.',
					},
					{
						value: 'PARTICIPATION_VERY_POOR_7',
						label: 'They are so afraid of making a mistake that it is difficult to get them to participate at all.',
						PARTICIPATION_VERY_POOR_7: 'They are so afraid of making a mistake that it is difficult to get them to participate at all.',
					},
					{
						value: 'PARTICIPATION_VERY_POOR_8',
						label: 'They are far below expectations regarding participation.',
						PARTICIPATION_VERY_POOR_8: 'They are far below expectations regarding participation.',
					},
				],
			},
		],
	},
};

export default options;