
import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import './DocumentPreview.scss';
import { ArrowLeftIcon, DownloadIcon } from "@heroicons/react/outline";
import { LibraryTypeEnum, LIBRARY_ICONS } from "components/Library/LibraryContants";
import { useTranslation } from "react-i18next";
import { DeleteIcon } from "assets/icon";
import { checkPermission, dowloadFile, downloadURI } from "utils/commonFun";
import { useParams } from "react-router-dom";
import fileServices from 'services/upload.service';
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { IframePdfViewer } from "_shared/PreviewContent/IframePDFViewer";
import { GetWorkspaceContext } from "contexts/Workspace/WorkspaceContext";
import { hasCheckedStream, isVideo } from "constant/file.constant";
import VideoPlayer from "_shared/components/VideoPlayer/VideoPlayer";
import resourcesService from "services/resources.service";

interface DocumentPreviewProps {
    closeModal: () => void,
    isOpen: boolean,
    data: any,
    onDelete: () => void
}

export const DocumentPreview = ({ data, isOpen, closeModal, onDelete }: DocumentPreviewProps) => {
    let TIMER_DOWNLOAD;
    const params: { id: string } = useParams();
    const { t: translator } = useTranslation();
    const [details, setDetails] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [downloading, setDownloading] = useState(false);
    const [noData, setNoData] = useState(false);
    const [isPlayCloudFlare, setIsPlayCloudFlare] = useState(false);

    const { getWorkspaceDetailState: { featureFlags } } = useContext(GetWorkspaceContext);

    const download = () => {
        if (downloading) {
            return;
        }
        setDownloading(true);
        if (hasCheckedStream(details?.file)) {
            TIMER_DOWNLOAD = setInterval(async () => {
                if (!TIMER_DOWNLOAD) {
                    return;
                }
                const res = await resourcesService.getDownloadUrl(params?.id, data?.object_id);
                if (res?.url) {
                    downloadURI(res?.url, data?.name);
                    setDownloading(false);
                    clearInterval(TIMER_DOWNLOAD);
                }
            }, 1000);
            
        } else {
            dowloadFile(details?.file, data?.name, () => {
                setDownloading(false);
            });
        }
        
    }

    useEffect(() => {
        const getFile = async () => {
            try {
                setNoData(false);
                setLoading(true);
                const response = await fileServices.getFile(params?.id, data?.object_id);
                setDetails(response?.data);
                setIsPlayCloudFlare(hasCheckedStream(response?.data?.file));
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setNoData(true);
            }
        }
        if (data?.object_id != null) {
            getFile();
        }
        return () => {
            setDetails(null);
        }
    }, [data?.object_id]);

    return (<>
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/80" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full justify-center text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full transform text-left align-middle transition-all">
                                <div className="documentPreview-header">
                                    <div className="flex text-white items-center">
                                        <ArrowLeftIcon className="w-7 h-7 mr-2.5 cursor-pointer" onClick={closeModal} />
                                        {LibraryTypeEnum.image == data?.object_type ?
                                            <img src={LIBRARY_ICONS(translator)?.find((item) => item?.value === data?.sub_object_type?.split('.')[1])?.icon} className="w-auto h-5" /> :
                                            <img src={LIBRARY_ICONS(translator)?.find((item) => item?.value === data?.object_type)?.icon} className="w-auto h-5" />
                                        }
                                        <span className="documentPreview-name">{data?.name}</span>
                                    </div>
                                    <div className="documentPreview-actions">
                                        {checkPermission('libraryContent', 'DL') && featureFlags?.systemDownload && <button className="w-6 h-6" onClick={download}>
                                            {downloading ? <CircleSpin /> : <DownloadIcon />}
                                        </button>}
                                        {checkPermission('libraryContent', 'D') && <button className="w-6 h-6" onClick={onDelete}>
                                            <DeleteIcon />
                                        </button>}

                                    </div>
                                </div>
                                <div className="documentPreview-content">
                                    {loading ? <>
                                        <CircleSpin />
                                    </> :
                                        !noData && <>
                                            {LibraryTypeEnum.image == data?.object_type && <img src={details?.file} alt={data?.name} />}
                                            {LibraryTypeEnum.video == data?.object_type && <>
                                                {!isPlayCloudFlare ? <video controls>
                                                    <source src={details?.file} type='video/mp4'></source>
                                                </video> : <VideoPlayer data={details} />}
                                            </>}
                                            {(LibraryTypeEnum.document == data?.object_type ||
                                                LibraryTypeEnum.presentation == data?.object_type ||
                                                LibraryTypeEnum.spreadsheet == data?.object_type) && <>
                                                    <iframe className="document" src={`https://view.officeapps.live.com/op/embed.aspx?src=${details?.file}`} />
                                                </>}
                                            {
                                                LibraryTypeEnum.audio == data?.object_type && <>
                                                    <audio controls src={details?.file} className="w-full"></audio>
                                                </>
                                            }
                                            {
                                                LibraryTypeEnum.pdf == data?.object_type && <>
                                                    <IframePdfViewer url={details?.file} />
                                                </>
                                            }
                                        </>
                                    }
                                    {!loading && noData && <>
                                        <div className="documentPreview-nocontent">
                                            {translator("LIBRARY.COULDN'T_PREVIEW_FILE")}
                                        </div>
                                    </>}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    </>)
}