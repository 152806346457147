import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import courseService from 'services/course.service';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import DialogComponent from 'components/Dialog/DialogComponent';
import SelectBox from '_shared/components/Select';
import { useDebouncedState } from 'hooks/useDebounce';
import { useForm } from 'react-hook-form';
import { ENROLLMENT_TYPE } from '../constants';
import NO_DATA from 'assets/img/empty-state/student.png'

interface ActionMenuProp {
    refreshData: Function;
    data: any;
    onClose: Function;
    isOpen: boolean;
}

const formatOptionLabel = (item) => {
    return (
        <div className="font-normal text-xs text-gray-800 flex gap-2 items-center justify-between w-full">
            <div className="flex gap-2 items-center max-w-[calc(100%-120px)]">
                <span className="min-w-[60px]">
                    <span className="text-gray-500">ID:</span> {item?.id}
                </span>
                <img className="w-5 h-5 rounded-full" src={item?.avatar_url} />
                <span className="text-ellipsis truncate ">{item?.name || item?.display_name}</span>
            </div>
            <span className="text-ellipsis truncate capitalize w-max pr-5">{item?.type?.split('_')?.join(' ')}</span>
        </div>
    );
};

const ModalEditEnrollment: FC<ActionMenuProp> = ({ refreshData, onClose, data, isOpen }) => {

    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
        setValue,
        watch,
    } = useForm({ mode: 'onChange' });

    const { t: translator } = useTranslation();
    const [listEnrollments, setListEnrollments] = useState([]);
    const [inputSearchEnrollments, setInputSearchEnrollments] = useDebouncedState('');
    const [loadingEnrollment, setLoadingEnrollment] = useState(false);

    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 10,
    });

    const params: { id: string; courseId: string } = useParams();
    const workspaceId = params?.id;
    const courseId = params?.courseId;

    useEffect(() => {
        getEnrollments(1);
    }, [inputSearchEnrollments]);

    useEffect(() => {
        const peopleEnrolled = [...(data?.learning_groups || []), ...(data?.learners || [])];
        if (peopleEnrolled?.length) {
            const formatOption = peopleEnrolled?.map((item) => {
                return {
                    ...item,
                    name: item?.display_name || item?.name,
                    id: item?.id,
                    value: item?.id,
                    type: item?.display_name ? ENROLLMENT_TYPE.INDIVIDUAL : ENROLLMENT_TYPE.LEARNING_GROUP,
                };
            });
            setValue('enrollments', formatOption);
        }
    }, [data]);

    const onSearch = (keyword) => {
        setInputSearchEnrollments(keyword);
    };

    const getEnrollments = async (page) => {
        setLoadingEnrollment(true);
        let payload = {
            page: page ? page : pagination.page,
            per_page: pagination.per_page,
            q: inputSearchEnrollments,
            statuses: 'active,enrolled',
        };
        if (!payload?.q) delete payload.q;
        const response = await courseService.getEnrollments(workspaceId, courseId, payload);
        if (response) {
            setLoadingEnrollment(false);
            const listOptions = response?.data?.items?.map((enrollment) => {
                if (enrollment.type === ENROLLMENT_TYPE.INDIVIDUAL) {
                    const learner = enrollment?.learners[0];
                    return {
                        ...learner,
                        name: learner?.display_name,
                        id: learner?.id,
                        value: learner?.id,
                        type: ENROLLMENT_TYPE.INDIVIDUAL,
                    };
                } else {
                    const group = enrollment?.learning_group;
                    return {
                        ...group,
                        name: group?.name,
                        id: group?.id,
                        value: group?.id,
                        type: ENROLLMENT_TYPE.LEARNING_GROUP,
                    };
                }
            });
            if (page === 1) setListEnrollments(listOptions);
            else setListEnrollments([...listEnrollments, ...listOptions]);
            setPagination({ ...pagination, total: response?.data?.total, page });
        }
    };

    const onSubmit = (value) => {
        let assigned_to_learning_groups = [];
        let assigned_to_learners = [];
        value?.enrollments?.forEach((item) => {
            if (item?.type === ENROLLMENT_TYPE.INDIVIDUAL) assigned_to_learners.push(item?.id);
            if (item?.type === ENROLLMENT_TYPE.LEARNING_GROUP) assigned_to_learning_groups.push(item?.id);
        });
        const payload = {
            assigned_to_learning_groups,
            assigned_to_learners,
        };
        courseService
            .updateDataInstructorInvited(workspaceId, courseId, data?.id, payload)
            .then(() => {
                onClose();
                refreshData();
                Toastify.success();
            })
            .catch(() => Toastify.error());
    };

    const onCloseModal = () => {
        setValue('enrollments', []);
        onClose(false);
    };

    return (
        <DialogComponent
            title={translator('COURSES.EDIT_LEARNER_LEARNING_GROUP')}
            isOpen={isOpen}
            onCloseModal={onCloseModal}
            styles="max-w-lg"
            child={
                <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
                    <SelectBox
                        placeholder={translator('COURSES.SEARCH_LEARNER_LEARNING_GROUP')}
                        data={listEnrollments}
                        onSearch={onSearch}
                        value={watch('enrollments')}
                        loading={loadingEnrollment}
                        getData={getEnrollments}
                        pagination={pagination}
                        control={control}
                        name="enrollments"
                        numberOfItemDisplayed={3}
                        emptyState={{
                            img: NO_DATA,
                            message: translator('COURSES.NO_RESULT_FOUND')
                        }}
                        isMulti={true}
                        formatOptionLabel={formatOptionLabel}
                    />
                    <div className="flex justify-center gap-4 mt-6 w-full">
                        <ButtonOutline type="button" onClick={onCloseModal}>
                            {translator('CANCEL')}
                        </ButtonOutline>
                        <ButtonPrimary disabled={!isValid} type="submit">
                            {translator('ADD')}
                        </ButtonPrimary>
                    </div>
                </form>
            }
        />
    );
};

export default ModalEditEnrollment;
