import React, { Dispatch, SetStateAction, useContext } from 'react';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import { Row, Column } from 'react-table';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import ActionList from '../NotificationComponents/ActionList';
import { UserContext } from 'contexts/User/UserContext';
import { ChevronDownIcon } from '@heroicons/react/outline';
import NO_CONTENT from 'assets/img/empty-state/notiEmpty.png'
import { useTranslation } from 'react-i18next';
interface TableNotificationProps {
    workspaceId: string;
    dataTable: any;
    handleViewDetailNoti: (data: any) => void;
    setLoadList: Dispatch<SetStateAction<boolean>>;
    handleSortTitle: () => void;
    handleSortStatus: () => void;
}

const TableNotification: React.FC<TableNotificationProps> = ({
    workspaceId,
    dataTable,
    handleViewDetailNoti,
    setLoadList,
    handleSortTitle,
    handleSortStatus,
}) => {
    const { t: translator } = useTranslation();

    const {
        userState: { result },
    } = useContext(UserContext);
    const initialState = {
        hiddenColumns: ['id'],
    };

    const columns = React.useMemo(
        () => [
            {
                Header: () => (
                    <div className="text-left flex items-center">
                        <RenderTableHeader value={translator('NOTIFICATION.TITLE')} className="pl-[8px]" />
                        <ChevronDownIcon
                            onClick={handleSortTitle}
                            className={`w-4 h-4 ml-2 text-primary-500 cursor-pointer ${dataTable.order === 'asc' && 'rotate-180'}`}
                        />
                    </div>
                ),
                Cell: ({ row, column }: { row: any; column: Column }) => {
                    return (
                        <div className="flex justify-start items-center space-x-2">
                            {/*@ts-ignore*/}
                            <Link
                                to={`/workspace/${workspaceId}/management/notification/${row?.original?.id}`}
                                className="pl-1 text-ooolab_xs  leading-10 text-left py-2 text-primary-500 font-semibold cursor-pointer truncate"
                                data-tip={row.values?.title}
                                data-for={row.values?.id + 'title'}
                            >
                                {row.values.title}
                            </Link>
                            {/*@ts-ignore*/
                                <ReactTooltip
                                    place="left"
                                    type="dark"
                                    effect="float"
                                    id={row.values?.id + 'title'}
                                    getContent={(content) => <div className="w-auto h-auto">{content}</div>}
                                    disable={row.values?.title?.length < 35}
                                />}
                        </div>
                    );
                },
                accessor: 'title',
                width: 320,
            },
            {
                Header: () => <RenderTableHeader value={translator('NOTIFICATION.SUBTITLE')} />,
                Cell: ({ row, column }: { row: any; column: Column }) => {
                    return (
                        <RenderTableCell
                            value={
                                row?.original?.subtitle

                            }
                        />
                    );
                },
                accessor: 'subtitle',
                width:120,
            },
            {
                Header: () => <RenderTableHeader value={translator('NOTIFICATION.SCHEDULE')} />,
                Cell: ({ row, column }: { row: any; column: Column }) => {
                    return (
                        <RenderTableCell
                            value={
                                row?.original?.schedule
                                    ? dayjs
                                        .utc(row?.original?.schedule)
                                        .tz(result?.time_zone || 'UTC')
                                        .format('DD/MM/YYYY HH:mm')
                                    : dayjs
                                        .utc(row?.original?.created_at)
                                        .tz(result?.time_zone || 'UTC')
                                        .format('DD/MM/YYYY HH:mm')
                            }
                        />
                    );
                },
                accessor: 'schedule',
            },
            {
                Header: () => (
                    <div className="text-left flex items-center">
                        <RenderTableHeader value={translator('NOTIFICATION.STATUS')} />
                        <ChevronDownIcon
                            onClick={handleSortStatus}
                            className={`w-4 h-4 ml-2 text-primary-500 cursor-pointer ${dataTable.order === 'asc' && 'rotate-180'}`}
                        />
                    </div>
                ),
                Cell: ({ row, column }: { row: any; column: Column }) => {
                    return (
                        <div className="h-full flex items-center w-3/4">
                            <div
                                className={` overflow-ellipsis whitespace-nowrap overflow-hidden p-1 font-normal text-sm text-center  rounded w-full border ${row.original?.schedule && dayjs.utc(row.original?.schedule).tz(result?.time_zone) > dayjs(new Date()).tz(result?.time_zone) ? 'bg-[#FFF7ED] border-[#F97316] text-[#F97316]' : 'text-green-500 border-green-300 bg-green-50 '
                                    }`}
                            >
                                {row.original?.schedule && dayjs.utc(row.original?.schedule).tz(result?.time_zone) > dayjs(new Date()).tz(result?.time_zone) ? translator("SCHEDULED") : translator('Published')}
                            </div>
                        </div>
                    );
                },
                accessor: 'status',
            },
            {
                Header: () => <RenderTableHeader value="" />,
                Cell: ({ row }: { row: any }) => {
                    return <ActionList workspace={workspaceId} data={row?.original} setLoadList={setLoadList} status={row.original?.status} position={row?.index > dataTable?.items?.length / 2 ? '-bottom-2' : ''} />;
                },
                accessor: 'action',
                width: 40,
            },
        ],
        [result?.time_zone, dataTable?.items]
    );
    return (
        <Table
            data={dataTable?.items}
            columns={columns}
            initialState={initialState}
            emptyContent={translator('NOTIFICATION.NO_CONTENT')}
            emptyIcon={NO_CONTENT}
        />
    );
};

export default TableNotification;
