import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import DialogComponent from 'components/Dialog/DialogComponent'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';
import courseService from 'services/course.service';

export default function ModalDeleteReport({ isOpen, onClose, reportId, course_id = null, refreshData }) {
  const { t: translator } = useTranslation();
  const params: { id: string, courseId: string } = useParams();
  const workspaseId = params?.id;
  const courseId = params?.courseId || course_id;
  const deleteReport = () => {
    courseService.deleteCustomReport(workspaseId, courseId, reportId)
      .then(() => {
        Toastify.success(translator("COURSES.DELETE_REPORT_SUCCESS"))
        refreshData();
        onClose();
      })
      .catch(() => {
        Toastify.error(translator("COURSES.DELETE_REPORT_ERROR"))
      })
  }
  return (
    <DialogComponent
      isOpen={isOpen}
      onCloseModal={onClose}
      title={translator('COURSES.DELETE_REPORT')}
      child={
        <div className='mt-4'>
          <div>{translator('COURSES.DELETE_REPORT_CONTENT')}</div>
          <div className='mt-5 flex justify-center gap-5'>
            <ButtonOutline type="button" onClick={onClose}>{translator('CANCEL')}</ButtonOutline>
            <ButtonPrimary type="button" className='!border-red-500 !bg-red-500' onClick={deleteReport}>{translator('DELETE')}</ButtonPrimary>
          </div>
        </div>
      }
    />
  )
}
