import * as React from 'react';
// COMPONENTS
import AssignmentPreview from 'components/AssignmentView/AssignmentPreview';
export interface UserAccountSettingPageProps {
    setAuthStorage: React.Dispatch<React.SetStateAction<boolean>>;
}

const H5PPreviewPage: React.FC<UserAccountSettingPageProps> = ({
    setAuthStorage,
}) => {
    return <AssignmentPreview />;
};

export default H5PPreviewPage;
