import { StylesConfig } from 'react-select';

export const statusOption = (translator: (key: string) => string) => [
    { label: translator('ACTIVE'), value: 'active', bg: '#3AA757' },
    { label: translator('INACTIVE'), value: 'inactive', bg: '#8F90A6' },
];

export const publishOption = (translator: (key: string) => string) => [
    { label: translator('PEOPLE'), value: 'people' },
    { label: translator('GROUP'), value: 'group' },
];

export const typeOption = (translator: (key: string) => string) => [
    { label: translator('ONLINE'), value: 'online', name: translator('ONLINE') },
    { label: translator('OFFLINE'), value: 'offline', name: translator('OFFLINE') },
    { label: translator('BLENDED'), value: 'blended', name: translator('BLENDED') },
    { label: translator('INSTRUCTOR_LED'), value: 'instructor_led', name: translator('INSTRUCTOR_LED') },
    { label: translator('SELF_STUDY'), value: 'self_study', name: translator('SELF_STUDY') },
    { label: translator('E_LEARNING'), value: 'e_learning', name: translator('E_LEARNING') },
    { label: translator('HYBRID'), value: 'hybrid', name: translator('HYBRID') },
];

export const learningTypeOption = (translator: (key: string) => string) => [
    { label: translator('IN_ORDER'), value: 'in_order' },
    { label: translator('NOT_IN_ORDER'), value: 'not_in_order' },
];

export const learningLevelOption = (translator: (key: string) => string) => [
    { label: translator('NOVICE'), value: 'novice' },
    { label: translator('ADVANCED'), value: 'advanced' },
    { label: translator('INTERMEDIATE'), value: 'intermediate' },
];

export const durationTypeOption = (translator: (key: string) => string) => [
    { label: translator('HOUR'), value: 'hour' },
    { label: translator('DAY'), value: 'day' },
    { label: translator('MONTH'), value: 'month' },
];

export const contentTypeOption = (translator: (key: string) => string) => [
    { label: translator('COURSES'), value: 'course' },
    { label: translator('LESSON'), value: 'lesson' },
];

export const typeOfProgramOptions = (translator: (key: string) => string) => [
    { label: translator('INDIVIDUAL'), value: 'individual' },
    { label: translator('LEARNING_GROUP'), value: 'learning_group' },
];

export const arrayProgramOptions = (translator: (key: string) => string) => [
    { label: translator('LEVEL') + 1, value: '1' },
    { label: translator('LEVEL') + 2, value: '2' },
    { label: translator('LEVEL') + 3, value: '3' },
    { label: translator('LEVEL') + 4, value: '4' },
    { label: translator('LEVEL') + 5, value: '5' },
    { label: translator('LEVEL') + 6, value: '6' },
    { label: translator('LEVEL') + 7, value: '7' },
    { label: translator('LEVEL') + 8, value: '8' },
    { label: translator('LEVEL') + 9, value: '9' },
    { label: translator('LEVEL') + 10, value: '10' },
];

export const lessonDurationOptions = (translator: (key: string) => string) => [
    { label: translator('30_MINUTES'), value: 30 },
    { label: translator('45_MINUTES'), value: 45 },
    { label: translator('60_MINUTES'), value: 60 },
    { label: translator('75_MINUTES'), value: 75 },
    { label: translator('90_MINUTES'), value: 90 },
    { label: translator('105_MINUTES'), value: 105 },
    { label: translator('120_MINUTES'), value: 120 },
    { label: translator('135_MINUTES'), value: 135 },
    { label: translator('150_MINUTES'), value: 150 },
    { label: translator('165_MINUTES'), value: 165 },
    { label: translator('180_MINUTES'), value: 180 },
];

export const PAGINATION = {
    per_page: 20,
};

export const customSelectStyle: StylesConfig<any, true> = {
    clearIndicator: (base) => ({ ...base, display: 'none' }),
    indicatorSeparator: (base) => ({ ...base, display: 'none' }),
    dropdownIndicator: (base) => ({ ...base, padding: 4 }),
    control: (styles) => ({
        ...styles,
    }),
    singleValue: (styles) => {
        return {
            ...styles,
            padding: 0,
            margin: 0,
            overflow: 'auto',
        };
    },
};

export const isValidEmail = (list: any[]) => {
    return list.filter((el) => !/\S+@\S+\.\S+/.test(el.value)).length;
};

export const typeAssignment = (translator: (key: string) => string) => [
    { label: translator('AFTER'), value: 'after' },
    { label: translator('BEFORE'), value: 'before' },
];

export const typeAssignmentOption = (translator: (key: string) => string) => [
    { label: translator('COMPLETED_LESSON'), value: 'lesson' },
    // { label: translator('COMPLETED_ASSIGNMENT'), value: 'assignment' },
    { label: translator('ENROLLMENT_DATE'), value: 'enrollment_date' },
    { label: translator('ENROLLMENT_START_DATE_LOWER'), value: 'enrollment_start_date' },
];

export const typeDurationAssignment = (translator: (key: string) => string, lower?: boolean) => [
    { label: translator(lower ? 'DAY(S)_LOWER' : 'DAY(S)'), value: 'day' },
    { label: translator(lower ? 'WEEK(S)_LOWER' : 'WEEK(S)'), value: 'week' },
    { label: translator(lower ? 'MONTH(S)_LOWER' : 'MONTH(S)'), value: 'month' },
];

export const typeDueDateAssignment = (translator: (key: string) => string) => [
    { label: translator('END_BY_ENROLLMENT_END_DATE'), value: 'end_by_enrollment_end_date' },
    { label: translator('AFTER_A_DURATION'), value: 'after_duration' },
];

export const typeAvailabilityAssignment = (translator: (key: string) => string) => [
    { label: translator('AFTER_ASSIGNMENT_OPEN'), value: 'after_assignment_open' },
    { label: translator('LEARNING_PATH__ENROLLMENT'), value: 'enroll_learning_path' },
    { label: translator('ENROLLMENT_START_DATE'), value: 'enrollment_start_date' },
];

export const typeAssignmentLabelEnable = (translator: (key: string, newKey?: boolean, opt?: any) => string, lessonName: string) => [
    {
        label: `${translator('AFTER_COMPLETED_LESSON', false, {
            lessonName: lessonName,
        })}`,
        value: 'lesson',
    },
    { label: translator('AFTER_ENROLLING'), value: 'enrollment_date' },
    { label: translator('AFTER_THE_EROLLMENT_START_DATE'), value: 'enrollment_start_date' },
];

export const typeAssignmentLabelDueDate = (
    translator: (key: string, newKey?: boolean, opt?: any) => string,
    number: number,
    option: string
) => [
    {
        label: `${translator('AFTER_END_START', false, {
            number: number,
            date: option,
        })}`,
        value: 'after_duration',
    },
    { label: translator('END_BY_ENROLLMENT_END_DATE'), value: 'end_by_enrollment_end_date' },
];

export const typeAvailabilityAssignmentLabel = (translator: (key: string) => string) => [
    { label: translator('AFTER_ASSIGNMENT_OPEN'), value: 'after_assignment_open' },
    { label: translator('AFTER_ENROLLING'), value: 'enroll_learning_path' },
    { label: translator('AFTER_THE_EROLLMENT_START_DATE'), value: 'enrollment_start_date' },
];
