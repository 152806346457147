import React, { useState } from "react";
import { SESSION_TYPE, SESSION_TYPE_EMUN } from "constant/course.const";
import { ButtonIcon, Toastify } from "_shared";
import { ChevronDownIcon, ChevronUpIcon, DotsVerticalIcon, DuplicateIcon, ExternalLinkIcon, TrashIcon, XIcon } from "@heroicons/react/outline";
import { useParams } from "react-router-dom";
import ActionsButton from 'components/ActionsButton';
import "./SessionDetails.scss";
import dayjs from "dayjs";
import { EditIcon } from "assets/icon";
import { useTranslation } from "react-i18next";
import { checkPermission, openUrl } from "utils/commonFun";

export const SessionDetails = (props) => {
    const params: { id: string } = useParams();
    const { t: translator } = useTranslation();
    const [showLearner, setShowLearner] = useState(false);

    const { id, start_time, time_zone, end_time, schedule_type, learners, instructors,
        lesson, vc_link, location, instructor_note, onClose, setOpenModalEdit, setOpenModalDeactivate,
        learning_groups, extra_link, status
    } = props;

    const handleCopy = () => {
        navigator.clipboard.writeText(vc_link);
        Toastify.success('VC Link copied!');
        openUrl(vc_link);
    }

    return (<div className="sessionDetails">
        <div className="sessionDetails-header">
            <div className="sessionDetails-header-infor">
                <span>ID</span><span className="text-primary-500">{id}</span> <span>-</span><span className="text-primary-500">{`${dayjs(start_time).tz(time_zone).format('HH:mm')} - ${dayjs(end_time).tz(time_zone).format('HH:mm')}`}</span>
                <span
                    className={`sessionList-status sessionList-status--${schedule_type}`}
                >
                    {SESSION_TYPE_EMUN[schedule_type]}
                </span>
            </div>
            <div className="ml-auto sessionDetails-header-action">
                <ActionsButton
                    styleOptions="top-0 right-[35px]"
                    className="flex justify-center items-center !rounded !w-6 !h-6 !mr-0 !p-1 mt-0.5"
                    menus={[
                        {
                            label: translator("Edit"),
                            icon: <EditIcon />,
                            action: () => setOpenModalEdit(),
                            isDisabled: dayjs().isAfter(dayjs(start_time).tz(time_zone)) || status == 'cancelled',
                            hide: !checkPermission('courseScheduling', 'U')
                        },
                        {
                            label: schedule_type === SESSION_TYPE[0].value ? translator("Delete") : translator("Cancel"),
                            icon: <TrashIcon className='w-4 h-4' />,
                            action: () => setOpenModalDeactivate(),
                            isDisabled: dayjs().isAfter(dayjs(start_time).tz(time_zone)) || status == 'cancelled',
                            hide: !checkPermission('courseScheduling', 'U')
                        },
                    ]}
                />
                <span className="text-gray-200 -mt-0.5">|</span>
                <ButtonIcon onClick={onClose}>
                    <XIcon className="w-5 h-5" />
                </ButtonIcon>
            </div>
        </div>
        <div className="sessionDetails-content">
            {learning_groups?.length > 0 ? <div className="sessionDetails-item">
                <div className="sessionDetails-label">
                    Learning Group(s)
                </div>
                <div className="sessionDetails-value text-primary-500">
                    {learning_groups.map(item => item.name).join(',')}
                </div>
            </div> : null}
            <div className="sessionDetails-item">
                <div className="sessionDetails-label mt-0.5">
                    Learner(s)
                </div>
                <div className="sessionDetails-value flex-col w-full">
                    <div className="flex justify-between">
                        <div>{learners?.length} Learners</div>
                        <button className="flex items-center text-primary-500 text-xs" onClick={() => setShowLearner(!showLearner)}>
                            {!showLearner ? <>Show All {learners?.length} Learners <ChevronDownIcon className="w-4 h-4 ml-1" /></> :
                                <>Hide Learners <ChevronUpIcon className="w-4 h-4 ml-1" /></>}
                        </button>
                    </div>
                    {showLearner ? <div className="flex flex-wrap gap-2 mb-4">
                        {learners?.map(item => <div key={item.id} className="sessionDetails-user">
                            <img src={item?.avatar_url} className="sessionDetails-user-avatar" />
                            <span className="sessionDetails-user-name">{item?.display_name}</span>
                        </div>)}
                    </div> : null}
                </div>

            </div>
            <div className="sessionDetails-item">
                <div className="sessionDetails-label mt-1">
                    Instructor(s)
                </div>
                <div className="sessionDetails-value">
                    {instructors?.map(item => <div key={item.id} className="sessionDetails-user">
                        <img src={item?.avatar_url} className="sessionDetails-user-avatar" />
                        <span className="sessionDetails-user-name">{item?.display_name}</span>
                    </div>)}
                </div>
            </div>
            <div className="sessionDetails-item">
                <div className="sessionDetails-label">
                    Lesson
                </div>
                <div className="sessionDetails-value text-primary-500">
                    {lesson?.title ?? '-'} {lesson?.title ? <ExternalLinkIcon
                        onClick={() => window.open(`/workspace/${params?.id}/lesson/${lesson?.id}/preview`, '_blank')}
                        className="w-4 h-4 cursor-pointer" /> : null
                    }
                </div>
            </div>
            <div className="sessionDetails-item">
                <div className="sessionDetails-label">
                    VC Link
                </div>
                <div className="sessionDetails-value text-primary-500">
                    {vc_link ? vc_link : '-'} {vc_link ? <DuplicateIcon className="w-4 h-4 cursor-pointer" onClick={handleCopy} /> : null}
                </div>
            </div>
            <div className="sessionDetails-item">
                <div className="sessionDetails-label">
                    Extra Link
                </div>
                <div className="sessionDetails-value text-primary-500">
                    {extra_link ? extra_link : '-'}
                </div>
            </div>
            <div className="sessionDetails-item">
                <div className="sessionDetails-label">
                    Location
                </div>
                <div className="sessionDetails-value text-gray-800 break-all">
                    {location ? location : '-'}
                </div>
            </div>
            <div className="sessionDetails-item">
                <div className="sessionDetails-label">
                    Instructor Notes
                </div>
                <div className="sessionDetails-value text-gray-800 font-normal">
                    {instructor_note ? instructor_note : '-'}
                </div>
            </div>
        </div>
    </div>)
}