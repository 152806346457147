import React from 'react'

export default function AddNoteIcon({className = ''}) {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.95801 4.375C3.79225 4.375 3.63328 4.44085 3.51607 4.55806C3.39886 4.67527 3.33301 4.83424 3.33301 5C3.33301 5.16576 3.39886 5.32473 3.51607 5.44194C3.63328 5.55915 3.79225 5.625 3.95801 5.625H13.5413C13.7071 5.625 13.8661 5.55915 13.9833 5.44194C14.1005 5.32473 14.1663 5.16576 14.1663 5C14.1663 4.83424 14.1005 4.67527 13.9833 4.55806C13.8661 4.44085 13.7071 4.375 13.5413 4.375H3.95801ZM3.95801 7.70833C3.79225 7.70833 3.63328 7.77418 3.51607 7.89139C3.39886 8.0086 3.33301 8.16757 3.33301 8.33333C3.33301 8.49909 3.39886 8.65806 3.51607 8.77527C3.63328 8.89248 3.79225 8.95833 3.95801 8.95833H10.208C10.3738 8.95833 10.5327 8.89248 10.6499 8.77527C10.7672 8.65806 10.833 8.49909 10.833 8.33333C10.833 8.16757 10.7672 8.0086 10.6499 7.89139C10.5327 7.77418 10.3738 7.70833 10.208 7.70833H3.95801ZM3.33301 11.6667C3.33301 11.5009 3.39886 11.3419 3.51607 11.2247C3.63328 11.1075 3.79225 11.0417 3.95801 11.0417H6.87467C7.04044 11.0417 7.19941 11.1075 7.31662 11.2247C7.43383 11.3419 7.49967 11.5009 7.49967 11.6667C7.49967 11.8324 7.43383 11.9914 7.31662 12.1086C7.19941 12.2258 7.04044 12.2917 6.87467 12.2917H3.95801C3.79225 12.2917 3.63328 12.2258 3.51607 12.1086C3.39886 11.9914 3.33301 11.8324 3.33301 11.6667ZM3.95801 14.375C3.79225 14.375 3.63328 14.4408 3.51607 14.5581C3.39886 14.6753 3.33301 14.8342 3.33301 15C3.33301 15.1658 3.39886 15.3247 3.51607 15.4419C3.63328 15.5592 3.79225 15.625 3.95801 15.625H5.20801C5.37377 15.625 5.53274 15.5592 5.64995 15.4419C5.76716 15.3247 5.83301 15.1658 5.83301 15C5.83301 14.8342 5.76716 14.6753 5.64995 14.5581C5.53274 14.4408 5.37377 14.375 5.20801 14.375H3.95801Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6603 6.92328C17.3415 6.60473 16.9093 6.42578 16.4587 6.42578C16.008 6.42578 15.5758 6.60473 15.257 6.92328L9.50699 12.6733C9.12579 13.0546 8.82884 13.5117 8.63533 14.015L7.84783 16.0633C7.81191 16.1572 7.80395 16.2596 7.82493 16.3579C7.8459 16.4563 7.89491 16.5465 7.96603 16.6176C8.03714 16.6887 8.12733 16.7377 8.22569 16.7587C8.32406 16.7797 8.42639 16.7717 8.52033 16.7358L10.5687 15.9483C11.0719 15.7548 11.529 15.4578 11.9103 15.0766L17.6603 9.32662C17.9789 9.00784 18.1578 8.57562 18.1578 8.12495C18.1578 7.67429 17.9789 7.24206 17.6603 6.92328ZM15.9937 7.65995C16.0536 7.5941 16.1263 7.54109 16.2073 7.50414C16.2883 7.46719 16.376 7.44706 16.465 7.44497C16.5541 7.44288 16.6426 7.45888 16.7253 7.49199C16.8079 7.5251 16.883 7.57464 16.946 7.63761C17.009 7.70058 17.0585 7.77567 17.0916 7.85834C17.1247 7.94101 17.1407 8.02953 17.1386 8.11856C17.1366 8.20759 17.1164 8.29527 17.0795 8.37629C17.0425 8.45732 16.9895 8.53 16.9237 8.58995L16.4587 9.05495L15.5287 8.12495L15.9937 7.65995ZM14.792 8.86162L11.1537 12.5L12.0837 13.43L15.722 9.79162L14.792 8.86162ZM10.2437 13.41L10.417 13.2366L11.347 14.1666L11.1737 14.34C10.8953 14.6181 10.5618 14.8347 10.1945 14.9758L9.56033 15.22L9.36366 15.0241L9.60783 14.3891C9.7495 14.0216 9.96616 13.6883 10.2437 13.41Z" fill="#0D1216"/>
    </svg>
  )
}
