import { FC, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ActionsButton from 'components/ActionsButton';
import { checkPermission } from 'utils/commonFun';
import DeactivateIcon from 'assets/icon/DeactivateIcon';
import { ActiveIcon } from 'assets/icon/ActiveIcon';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import workspaceService from 'services/workspace.service';
import DialogComponent from 'components/Dialog/DialogComponent';
import { useBoolean } from 'hooks/custom';

interface ActionMenuProp {
    data: any;
    refreshData: Function;
    position?: string;
}

const ActionMenu: FC<ActionMenuProp> = ({ data, refreshData, position }) => {
    const { t: translator } = useTranslation();
    const [isOpenModalActive, setOpenModalChangeStatus] = useState(false);
    const { booleanValue: modalNotAlowChangeStatus, toggleBooleanValue: toggleModalNotAlowChangeStatus } = useBoolean();
    const history = useHistory();
    const params: { id: string} = useParams();
    const workspaceId = params?.id;

    const updateStatus = () => {
      const bodyParams = {
          ...data,
          status: data?.status === 'active' ? 'inactive' : 'active'
      }
      workspaceService.updateLearningGroupService(params?.id, data?.id, bodyParams)
          .then((res) => {
            Toastify.success()
            refreshData()
          })
          .catch(() => {
              Toastify.error()
          })
          .finally(() => setOpenModalChangeStatus(false))
    }

    const preCheckAllChangeStatus = async () => {
        const {data: {allow_change_status = false}} = await workspaceService.preCheckBeforeChangeStatusOfLearningGroup(workspaceId, data?.id, {
          status: data?.status === 'active' ? 'inactive' : 'active'
        })
        if (allow_change_status) {
            setOpenModalChangeStatus(true)
        }
        else {
          toggleModalNotAlowChangeStatus();
        }
      }

      const onViewLearnerTab = () => {
        window.open(`/workspace/${workspaceId}/management/learning-groups/${data?.id}?tab=enrollment&statuses=enrolled,active`, '_blank');
      }

    return (
        <>
            <ActionsButton
                styleOptions={`${position ? position: 'top-0'} right-[35px]`}
                menus={[
                    {
                        label: data?.status === 'active' ? translator('DEACTIVATE') : translator('ACTIVATE'),
                        icon: data?.status === 'active' ? <DeactivateIcon /> : <ActiveIcon />,
                        action: preCheckAllChangeStatus,
                        class: data?.status === 'active' ? 'text-red-500' : '',
                        hide: !checkPermission('learningGroup', 'U')
                    },
                ]}
            />

            <DialogComponent
                isOpen={isOpenModalActive}
                title={ data?.status === 'active' ? translator('DEACTIVATE') : translator('ACTIVATE')}
                onCloseModal={() => setOpenModalChangeStatus(false)}
                maskClosable={false}
                stylesTitle="text-base"
                styles="max-w-md"
                child={
                    <div className='mt-4'>
                        <div className="text-gray-800 text-sm text-left ">
                          {data?.status === 'active' ? translator('LEARNING_GROUP.CONFIRM_DEACTIVATE_LEARNING_GROUP') : translator('LEARNING_GROUP.CONFIRM_ACTIVATE_LEARNING_GROUP')} 
                        </div>
                        <div className="flex items-center justify-center space-x-4 mt-8">
                            <ButtonOutline
                                onClick={() => setOpenModalChangeStatus(false)}
                                type="button"
                            >
                              {translator('LEARNING_GROUP.CANCEL')}
                            </ButtonOutline>

                            <ButtonPrimary
                                onClick={updateStatus}
                                type="button"
                                className={data?.status === 'active' ? 'buttonPrimary-danger' : ''}
                            >
                               {data?.status === 'active' ? translator('DEACTIVATE') : translator('ACTIVATE')}
                            </ButtonPrimary>
                        </div>
                    </div>
                }
              />

            <DialogComponent
              isOpen={modalNotAlowChangeStatus}
              title={translator('DEACTIVATE')}
              onCloseModal={toggleModalNotAlowChangeStatus}
              maskClosable={false}
              child={
                  <div className='mt-2'>
                      <div className="text-sm">
                        {translator('LEARNING_GROUP.CANNOT_DEACTIVATE_LEARNING_GROUP')}
                      </div>
                      <div className="text-sm mt-2">
                        {translator("LEARNING_GROUP.CLICK")} <span onClick={onViewLearnerTab} className='cursor-pointer opacity-100 hover:opacity-75 text-primary-500'>{translator("LEARNING_GROUP.HERE")}</span> {translator("LEARNING_GROUP.TO_VIEW_ENROLLMENT_LIST")}
                      </div>
                  </div>
              }
            />
            
        </>
    );
};

export default ActionMenu;
