import React from 'react';
import WorkspaceDashboard from 'components/Reports/WorkspaceDashboard';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';


const WorkspaceDashboardPage: React.FC<{ setAuthStorage: React.Dispatch<React.SetStateAction<boolean>> }> = ({ setAuthStorage }) => {
    return (
        <ManagePermissionWrapper>
            <WorkspaceDashboard />
        </ManagePermissionWrapper>
    );
};

export default WorkspaceDashboardPage;
