import { ExclamationCircleIcon, ExternalLinkIcon } from "@heroicons/react/outline";
import { USER_ROLE } from "components/Management/User/constant";
import { GetWorkspaceContext } from "contexts/Workspace/WorkspaceContext";
import React from "react";
import { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import "./index.scss";

export const GuideAddingBank = () => {
    const params: { id: string } = useParams();
    const { t: translator } = useTranslation();
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const {
        result: { membership }
    } = getWorkspaceDetailState;

    return <div className="guideAddingBank">
        <ExclamationCircleIcon className="w-5 mx-auto" />
        <h2>{translator('GUIDE_ADDING_BANK.No_Bank_Accounts')}</h2>
        {membership?.user_role?.name == USER_ROLE.super_admin ?
            <p>Please go to <a href={`/workspace/${params.id}/setting?tab=e-commerce`} className='inline-flex'>{translator("WORKSPACE_SETTINGS")} <ExternalLinkIcon className='w-4 ml-1' /></a><br />to add a Bank Account.</p> :
            <p>{translator('GUIDE_ADDING_BANK.Message_Create_Bank')}</p>}
    </div>
}

export const GuidePaymentGetway = () => {
    const params: { id: string } = useParams();
    const { t: translator } = useTranslation();
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const {
        result: { membership }
    } = getWorkspaceDetailState;

    return <div className="guideAddingBank">
        <ExclamationCircleIcon className="w-5 mx-auto" />
        <h2>{translator('GUIDE_ADDING_BANK.No_Getway_Accounts')}</h2>
        {membership?.user_role?.name == USER_ROLE.super_admin 
            ? <p>Please go to <a href={`/workspace/${params.id}/setting?tab=e-commerce`} className='inline-flex'>{translator("WORKSPACE_SETTINGS")} <ExternalLinkIcon className='w-4 ml-1' /></a><br />to connect account.</p> 
            : <p>{translator('GUIDE_ADDING_BANK.Message_Create_Getway')}</p>}
    </div>
}