import { FC } from 'react';
import { classNames } from 'utils/commonFun';
import Empty from 'assets/img/empty.png';
import './NoContentWithAction.scss';

export const NoContentWithAction: FC<{
    iconContent?: any;
    content?: string;
    subContent?: string;
    img?: any;
    className?: string;
    styleImg?: string;
    child?: any;
    styleNoContent?: string;
}> = ({ content = 'No data', subContent = '', className, img = Empty, styleImg = '', iconContent, child, styleNoContent = '' }) => {
    return (
        <div className={classNames('noContent', className)}>
            <div className="">
                <img className={`noContent-img ${styleImg}`} src={img} alt="ooolab" />
            </div>
            <div className="noContent-title">
                {(iconContent && <img alt="icon" src={iconContent} className="hidden" />) || ''}
                <p className={`${styleNoContent}`}>{content}</p>
            </div>
            <div className="noContent-subTitle">
                <p>{subContent}</p>
            </div>
            {(child && <div className="noContent-child">{child}</div>) || ''}
        </div>
    );
};
