import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ActionsButton from 'components/ActionsButton';
import DeactivateIcon from 'assets/icon/DeactivateIcon';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import DialogComponent from 'components/Dialog/DialogComponent';
import { CircleInformationIcon } from 'assets/icon';
import courseService from 'services/course.service';
import { checkPermission } from 'utils/commonFun';
import { ActiveIcon } from 'assets/icon/ActiveIcon';
import { useAppSelector } from 'hooks/hooks';
import { toast } from 'react-toastify';
import { ENROLLMENT_STATUS } from 'components/Workspace/Course/CourseBuilder/components/CoursePeople/constants';

interface ActionMenuProp {
    data: any;
    refreshData: Function;
    position?: string;
}

const ActionMenu: FC<ActionMenuProp> = ({ data, refreshData, position }) => {
    const { t: translator } = useTranslation();
    const [isOpenModalDeactivate, setOpenModalDeactivate] = useState(false);
    const [isOpenModalActivate, setOpenModalActivate] = useState(false);

    const params: { id: string } = useParams();
    const workspaceId = params?.id;

    const onDeactivateEnrollment = () => {
        const loadingToastId = Toastify.loading(
            translator('COURSES.DEACTIVATING_ENROLLMENT'),
        );
        courseService
            .updateEnrollmentDetail(workspaceId, data?.course?.id, data?.id, { status: ENROLLMENT_STATUS.DEACTIVATED })
            .then(() => {
                toast.dismiss(loadingToastId)
                Toastify.success(translator('YOUR_WORK_HAS_BEEN_SAVED'));
                setOpenModalDeactivate(false);
                refreshData();
            })

            .catch(() => {
                toast.dismiss(loadingToastId);
                Toastify.error()
            });
    };
    const onActiveEnrollment = () => {
        courseService
            .updateEnrollmentDetail(workspaceId, data?.course?.id, data?.id, { status: ENROLLMENT_STATUS.ENROLLED })
            .then(() => {
                Toastify.success();
                setOpenModalActivate(false);
                refreshData();
            })
            .catch(() => Toastify.error());
    };

    return (
        <>
            {data?.display_status !== ENROLLMENT_STATUS.EXPIRED ? <ActionsButton
                styleOptions={`${position ? position : 'top-0'} right-[35px]`}
                menus={[
                    {
                        label: translator('DEACTIVATE'),
                        icon: <DeactivateIcon />,
                        isDisabled: data?.status === ENROLLMENT_STATUS.DEACTIVATED,
                        action: () => setOpenModalDeactivate(true),
                        class: 'border-b border-gray-300 text-red-500',
                        hide: !checkPermission('learningGroup', 'U') || data?.status === ENROLLMENT_STATUS.DEACTIVATED || data?.display_status === ENROLLMENT_STATUS.EXPIRED,
                    },
                    {
                        label: translator('REACTIVATE'),
                        icon: <ActiveIcon />,
                        action: () => setOpenModalActivate(true),
                        class: 'border-b border-gray-300',
                        isDisabled: data?.status === ENROLLMENT_STATUS.ENROLLED,
                        hide: !checkPermission('learningGroup', 'U') ||
                            data?.display_status === ENROLLMENT_STATUS.ACTIVE ||
                            data?.display_status === ENROLLMENT_STATUS.EXPIRED ||
                            data?.status === ENROLLMENT_STATUS.ENROLLED,
                    },
                ]}
            /> : null}

            {isOpenModalDeactivate && (
                <DialogComponent
                    title={translator('DEACTIVATE')}
                    isOpen={isOpenModalDeactivate}
                    onCloseModal={() => setOpenModalDeactivate(false)}
                    child={
                        <div className="flex flex-col">
                            <div className="mt-2 text-sm text-gray-800">{data?.course?.enrollment_type === 'credit' ? translator('COURSES.DEACTIVATE_ENROLLMENT_CREDIT_MSG') : translator('COURSES.DEACTIVATE_ENROLLMENT_MESSAGE')}</div>
                            <div className="flex justify-center gap-4 mt-6 w-full">
                                <ButtonOutline type="button" onClick={() => setOpenModalDeactivate(false)}>
                                    {translator('CANCEL')}
                                </ButtonOutline>
                                <ButtonPrimary type="button" className='buttonPrimary-danger' onClick={onDeactivateEnrollment}>
                                    {data?.course?.enrollment_type === 'credit' ? translator('YES') : translator('DEACTIVATE')}
                                </ButtonPrimary>
                            </div>
                        </div>
                    }
                />
            )}
            {isOpenModalActivate && (
                <DialogComponent
                    title={translator('COURSES.ACTIVE_CONFIRMATION')}
                    isOpen={isOpenModalActivate}
                    onCloseModal={() => setOpenModalActivate(false)}
                    child={
                        <div className="flex flex-col">
                            <div className="mt-2 text-sm text-gray-800">{translator('COURSES.ACTIVATE_ENROLLMENT_MESSAGE')}</div>
                            <div className="flex justify-center gap-4 mt-6 w-full">
                                <ButtonOutline type="button" onClick={() => setOpenModalActivate(false)}>
                                    {translator('CANCEL')}
                                </ButtonOutline>
                                <ButtonPrimary type="button" onClick={onActiveEnrollment}>
                                    {translator('ACTIVATE')}
                                </ButtonPrimary>
                            </div>
                        </div>
                    }
                />
            )}
        </>
    );
};

export default ActionMenu;
