import React, { useContext, useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { useHistory } from 'react-router-dom';
import { TFunction } from 'react-i18next';
import dayjs from 'dayjs';

import Tooltip from 'components/Tooltip';
import calendarSVG from 'assets/SVG/calendar.svg';
import avatarImg from 'assets/avatar_option.png';
import DefaultThumb from 'assets/img/defaultThumb.png'
import { ClipboardListIcon, VideoCameraIcon, ClipboardCheckIcon, CheckCircleIcon } from '@heroicons/react/outline';

import { getPeriodTime, getFullDateDisplay, classNames, openUrl, checkPermission } from 'utils/commonFun';
import ButtonComponent from 'components/Button/ButtonComponent';
import { MapSVG, PlaySVG } from 'assets/icon';
import { TeacherDashboardService } from 'services';
import { UserContext } from 'contexts/User/UserContext';
import { COURSE_TYPE } from 'components/Workspace/Course/CoursesList/constants';

interface DetailClassProps {
    val: any;
    workspaceId: string;
    teachObj?: any;
    translator?: TFunction<'translation'>;
    checkJoinClass?: any;
    handleJoinClass?: any;
    timeZone: string;
    tabSelect?: number;
    handleViewSyllabus: () => void;
}

const DetailClass: React.FC<DetailClassProps> = ({
    val,
    workspaceId,
    teachObj,
    translator,
    checkJoinClass,
    handleJoinClass,
    timeZone,
    tabSelect,
    handleViewSyllabus,
}) => {
    const {
        userState: { result },
    } = useContext(UserContext);

    const [statusClass, setStatusClass] = React.useState(0); // 0: Offline Class, 1: Online Class, 2: Assessment
    const [listMember, setListMember] = React.useState([]);
    const [count, setCount] = React.useState(2);
    const history = useHistory();

    const [data, setData] = useState<any>();

    const handleViewLesson = () => {
        const url = new URL(`/workspace/${workspaceId}/lesson/${val?.lesson?.id}/preview`, window?.location?.origin);
        url.searchParams.set('isCloseBack', 'true');
        window.open(url.toString(), '_blank');
    };

    const checkDisableGrade = () => {
        return dayjs().tz(timeZone).valueOf() < dayjs(val.end_time).tz(timeZone).valueOf();
    };

    const handleGrade = () => {
        history.push(`/workspace/${workspaceId}/instructor-dashboard/gradebook/${val.course?.id}/${val.id || val?.session_id}`, {
            nameclass: val.my_class?.name,
            tabSelect: tabSelect,
        });
    };

    const handleClassRecord = () => {
        history.push(`/workspace/${workspaceId}/management/class/${val.my_class?.id}`, {
            tabSelect: 6,
        });
    };

    // React.useEffect(() => {
    //     TeachService.getClassesMembersService(workspaceId, val.my_class?.id, val.id || val?.session_id, {
    //         type: 'student',
    //         skip_pagination: true,
    //     })
    //         .then((res) => {
    //             const { items } = res.data;
    //             const box: any = document.querySelector('#boxDisclosure');
    //             const width = box?.clientWidth || 0;
    //             setCount(Math.floor(width / 36));
    //             setListMember(items);
    //         })
    //         .catch((err) => console.log(err))
    //         .finally();
    // }, []);

    React.useEffect(() => {
        TeacherDashboardService.getSessionDetailService(workspaceId, val?.course?.id, val?.id || val?.session_id).then((res) => {
            setData(res?.data);
        });
    }, []);

    React.useEffect(() => {
        const status = {
            offline: 0,
            online: 1,
            assessment: 3,
        };
        setStatusClass(status[val?.type]);
    }, [val.type]);

    return (
        <div>
            <div className="flex items-center">
                <img className="rounded-lg mr-3 w-14 h-14 object-cover" src={val.course?.thumbnail || DefaultThumb} alt="img" />
                <div>
                    <p className="text-xl text-ooolab_dark_1 font-bold leading-7">{val?.course?.title}</p>
                    <div className="flex items-center space-x-2">
                        <p className="text-xs text-ooolab_dark_2 leading-6 capitalize">{val.course?.type?.map(label => COURSE_TYPE?.[label])?.join(', ')}</p>
                        {val.is_graded && (
                            <div className="flex items-center space-x-1">
                                <CheckCircleIcon className="w-4 h-4 text-ooolab_green_2" />
                                <span className="text-xs text-ooolab_green_2">{translator('EVALUEATED')}</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <hr className="my-5 divide-y-2 border-ooolab_blue_4" />

            <div>
                {data?.notes?.length > 0 && (
                    <>
                        {data?.notes?.[0]?.membership_type === 'student' && data?.notes?.[0]?.membership_role !== 'admin' && (
                            <div className="mt-4">
                                <div className="text-xs font-semibold text-ooolab_dark_2 leading-6">
                                    {translator('EVALUATE.LEARNER_NOTE')}
                                </div>
                                {data?.notes?.map((item) => {
                                    return <p className="text-sm text-ooolab_dark_1 font-normal leading-5">{item?.note}</p>;
                                })}
                            </div>
                        )}
                        {data?.notes?.[0]?.membership_role === 'admin' && (
                            <div className="mt-4">
                                <div className="text-xs font-semibold text-ooolab_dark_2 leading-6">
                                    {translator('EVALUATE.ADMIN_NOTE')}
                                </div>
                                {data?.notes?.map((item) => {
                                    return <p className="text-sm text-ooolab_dark_1 font-normal leading-5">{item?.note}</p>;
                                })}
                            </div>
                        )}
                    </>
                )}
            </div>

            <div className="grid grid-cols-2 gap-4 mt-3">
                <div>
                    <p className="text-xs font-semibold text-ooolab_dark_2 mb-2 leading-6">
                        {translator('DASHBOARD.TEACHER.TEACHER')}
                    </p>
                    <div className="flex items-center space-x-2">
                        {teachObj?.length ? (
                            teachObj?.map((item, index) => (
                                <p className="flex items-center">
                                    <img className="rounded-full mx-2 w-7 h-7" src={item?.avatar_url || avatarImg} alt="img" />
                                    <span className="text-sm text-ooolab_dark_1 font-normal leading-5">{item?.display_name}</span>
                                </p>
                            ))
                        ) : (
                            <p className="flex items-center">
                                <img className="rounded-full mx-2 w-7 h-7" src={teachObj?.avatar_url || avatarImg} alt="img" />
                                <span className="text-sm text-ooolab_dark_1 font-normal leading-5">{teachObj?.display_name}</span>
                            </p>
                        )}
                    </div>
                </div>
                <div>
                    <p className="text-xs font-semibold text-ooolab_dark_2 mb-2 leading-6">
                        {translator('DASHBOARD.TEACHER.SESSION_DATE_TIME')}
                    </p>
                    <div className="rounded-lg py-1 px-2 bg-ooolab_light_blue_0 text-ooolab_dark_1 font-semibold flex items-center">
                        <img className="mr-2 inline-block" src={calendarSVG} alt="calendarSVG" />
                        {getFullDateDisplay(val.start_time, timeZone)}
                        {' • '}
                        {getPeriodTime(val.start_time, val.end_time, timeZone)}
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-6">
                <div>
                    <p className="text-xs font-semibold text-ooolab_dark_2 mb-2 leading-6">
                        {translator('DASHBOARD.TEACHER.LIST_OF_STUDENTS')}
                    </p>
                    <Disclosure>
                        <Disclosure.Button className="block w-full">
                            <div id="boxDisclosure" className="flex -space-x-1 w-full">
                                {data?.learners?.map((item: any, index: number) => {
                                    return data?.learners.length > count ? (
                                        index < count - 1 ? (
                                            <Tooltip
                                                title={`${item.display_name} ${item?.nick_name ? ` / ${item?.nick_name}` : ''}`}
                                                mlClass="ml-0"
                                                key={index}
                                            >
                                                <img
                                                    className="rounded-full w-9 h-9 min-w-[36px] border border-ooolab_light_100"
                                                    src={item?.avatar_url || avatarImg}
                                                    alt="img"
                                                />
                                            </Tooltip>
                                        ) : index === count ? (
                                            <div
                                                key={index}
                                                className="rounded-full w-9 h-9 bg-ooolab_dark_50 flex items-center justify-center text-ooolab_dark_1 border border-ooolab_light_100"
                                            >
                                                {'+' + (data?.learners?.length - (count - 1))}
                                            </div>
                                        ) : null
                                    ) : (
                                        <Tooltip
                                            title={`${item.display_name} ${item?.nick_name ? ` / ${item?.nick_name}` : ''}`}
                                            mlClass="ml-0"
                                            key={index}
                                        >
                                            <img
                                                className="rounded-full w-9 h-9 min-w-[36px] border border-ooolab_light_100"
                                                src={item?.avatar_url || avatarImg}
                                                alt="img"
                                            />
                                        </Tooltip>
                                    );
                                })}
                            </div>
                        </Disclosure.Button>
                        <Disclosure.Panel>
                            <hr className="mt-3 divide-y-2 border-ooolab_blue_4" />
                            <div className="max-h-40 overflow-auto">
                                {data?.learners?.map((item: any, index: number) => (
                                    <div key={index} className="flex items-center mt-3">
                                        <img className="rounded-full w-6 h-6 mr-2" src={item?.avatar_url || avatarImg} alt="img" />
                                        <p className="text-sm text-ooolab_dark_1 font-semibold leading-5">
                                            {item?.display_name}
                                            {item?.nick_name && ` / ${item?.nick_name}`}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </Disclosure.Panel>
                    </Disclosure>
                </div>
                <div>
                    <p className="text-xs font-semibold text-ooolab_dark_2 mb-2 leading-6">
                        {translator('Location')}
                    </p>
                    <p className="text-sm text-ooolab_dark_1 font-normal leading-5">{data?.location || '-'}</p>
                </div>
            </div>
            <div className='flex flex-1 mt-6'>
                <div className='w-1/2'>
                    <p className="text-xs font-semibold text-ooolab_dark_2 mb-2 leading-6">
                        {translator('Extra Link')}
                    </p>
                    <p className="text-sm text-ooolab_dark_1 font-normal leading-5 break-words">{data?.extra_link || '-'}</p>
                </div>
                <div className='w-1/2'>
                    <p className="text-xs font-semibold text-ooolab_dark_2 mb-2 leading-6">
                        {translator('Instructor Notes')}
                    </p>
                    <p className="text-sm text-ooolab_dark_1 font-normal leading-5">{data?.instructor_note || '-'}</p>
                </div>
            </div>
            <div className={`flex flex-1 mt-6 ${dayjs.utc().tz(result.time_zone).isAfter(dayjs.utc(data?.end_time).tz(result?.time_zone)) ? '' : 'hidden'}`}>
                <div className='w-1/2'>
                    <p className="text-xs font-semibold text-ooolab_dark_2 mb-2 leading-6">
                        {translator('Recording Link')}
                    </p>
                    <p className="text-sm text-ooolab_dark_1 font-normal leading-5 break-words">{data?.record_link || '-'}</p>
                </div>
                <div className='w-1/2'></div>
            </div>
            <div className="mt-6" hidden={val?.session_type !== 2}>
                <p className="text-xs font-semibold text-ooolab_dark_2 mb-2 leading-6">
                    {translator('DASHBOARD.TEACHER.DESCRIPTION')}
                </p>
                <p className="text-sm text-ooolab_dark_1 font-normal leading-5">{val.my_class?.description}</p>
            </div>
            <div className="grid grid-cols-4 gap-3 mt-10">
                {checkPermission('libraryContent', 'R') && <div className="col-span-2">
                    <Tooltip title={!val.lesson?.id ? 'No lessons' : null} mlClass="ml-0">
                        <ButtonComponent
                            title={translator('DASHBOARD.TEACHER.VIEW_LESSON')}
                            classStyle={classNames(
                                'w-full h-fit justify-center mr-4 primary-button-apollo',
                                !val?.lesson?.id && 'disable',
                            )}
                            onClickButton={handleViewLesson}
                            icon={<ClipboardListIcon className="mr-2 w-4 h-4" />}
                            disable={!val?.lesson?.id}
                        />
                    </Tooltip>
                </div>}

                <div hidden={val?.session_type === 2} className="col-span-2">
                    <ButtonComponent
                        title={translator('EVALUATE.EVALUATE')}
                        classStyle={classNames('w-full justify-center mr-4 primary-button-apollo', checkDisableGrade() && 'disable')}
                        onClickButton={handleGrade}
                        icon={<ClipboardCheckIcon className="mr-2 w-4 h-4" />}
                        hidden={val.session_type === 2}
                        disable={checkDisableGrade()}
                    />
                </div>
                <div className="col-span-2 hidden">
                    <Tooltip title={!val.lesson_id ? 'No lessons' : null} mlClass="ml-0">
                        <ButtonComponent
                            title={translator('DASHBOARD.TEACHER.SYLLABUS')}
                            classStyle={classNames('w-full justify-center mr-4 primary-button-apollo', !val.lesson_id && 'disable')}
                            onClickButton={handleViewSyllabus}
                            icon={<MapSVG className="mr-2" />}
                            disable={!val.lesson_id}
                        />
                    </Tooltip>
                </div>
                {(data?.vc_link && (
                    <div hidden={data?.session_type === 0} className={'col-span-2'}>
                        <ButtonComponent
                            title={translator('Join')}
                            classStyle={classNames('w-full justify-center primary-button-apollo', checkJoinClass(val) && 'disable')}
                            onClickButton={() => {
                                openUrl(data?.vc_link);
                            }}
                            icon={<VideoCameraIcon className="mr-2 w-4 h-4" />}
                            disable={checkJoinClass(val) || !data?.vc_link}
                            hidden={data?.session_type === 0}
                        />
                    </div>
                )) ||
                    ''}
            </div>
        </div>
    );
};

export default DetailClass;
