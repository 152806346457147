import TeachDashboard from 'components/Dashboard/TeachDashboard/index';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';
import React from 'react';
interface TeachProps {
    setAuthStorage: React.Dispatch<React.SetStateAction<boolean>>;
}

const TeachDashboardPage: React.FC<TeachProps> = ({ setAuthStorage }) => {
    return (
        <ManagePermissionWrapper>
            <TeachDashboard />
        </ManagePermissionWrapper>
    )
};

export default TeachDashboardPage;
