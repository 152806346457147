import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';
import NotificationDetail from 'components/Dashboard/InstructorPortal/Components/NotificationDetail/NotificationDetail'

const NotificationDetailPage = () => {
    return <ManagePermissionWrapper>
        <NotificationDetail />
    </ManagePermissionWrapper>;
};

export default NotificationDetailPage;
