
import React, { useEffect, useState } from "react";
import { AsyncSelectField } from "components/Select";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ButtonPrimary } from "_shared";
import { PlusIcon } from "@heroicons/react/outline";
import "./InstructorAvailability.scss";
import { AddSlot } from "./components/AddSlot/AddSlot";
import DialogComponent from "components/Dialog/DialogComponent";
import { useParams } from "react-router-dom";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import emptyStageIcon from 'assets/img/empty-stage-icon.png';
import { CalendarResourceTime } from "./components/CalendarResourceTime/CalendarResourceTime";
import courseService from "services/course.service";
import { SlotDetails } from "./components/SlotDetails/SlotDetails";
import { checkPermission } from "utils/commonFun";

export const InstructorAvailability = () => {
    const params: { id: string, courseId } = useParams();
    const { t: translator } = useTranslation();
    const allOption = {
        id: 'all',
        label: 'All Instructors',
        value: '',
    };
    const { control, setValue, watch, getValues } = useForm({
        defaultValues: {
            instructor: {}
        }
    });
    const [hasAdSlot, setHasAdSlot] = useState(false);
    const [hasSlotDetail, setSlotDetail] = useState(false);
    const [hasInstuctor, setHasInstructor] = useState(false);
    const [loading, setLoading] = useState(true);
    const [instuctorSelect, setInstuctorSelect] = useState<any>();

    const handleLoadInstructor = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional.page,
            per_page: 20,
            order: 'asc',
            q: inputValue || null,
            sort_by: 'invited_at',
            status: 'active'
        };
        if (!payload?.q) delete payload.q;

        const { items, total } = await fetchInstructor(payload);

        let newOptions = items.map((el: any) => ({
            id: el?.id,
            label: el?.instructor?.display_name,
            value: el?.instructor?.id,
        }));
        if (payload.page == 1 && newOptions?.length > 0) {
            newOptions = [allOption].concat(newOptions);
        };
        return {
            options: newOptions,
            hasMore: additional.page < total / 20,
            additional: {
                page: additional.page + 1,
            },
        };
    };

    const fetchInstructor = async (bodyParams?: any) => {
        let response = {
            items: [],
            total: 0,
        };
        await courseService.getInstructorEnrolled(params.id, params.courseId, bodyParams).then((res) => {
            response = {
                items: res?.data?.items,
                total: res?.data?.total,
            };
        });
        return response;
    };

    const handleAddSlot = (value) => {
        if (value) {
            setInstuctorSelect(value);
            setHasAdSlot(true);
        }
    }

    const handleClose = (value) => {
        if (value) {
            setValue('instructor', {
                ...getValues('instructor')
            })
        }
        setInstuctorSelect(null);
        setHasAdSlot(false);
        setSlotDetail(false);
    }

    const getInstructor = async () => {
        setLoading(true);
        const response = await courseService.getInstructorEnrolled(params.id, params.courseId, { page: 1, sort_by: 'invited_at' });
        if (response) {
            setValue('instructor', allOption);
            setHasInstructor(response?.data?.total > 0);
        }
        setLoading(false);
    }

    const handleOpenDetail = (value) => {
        setSlotDetail(true);
        setInstuctorSelect(value);
    }

    useEffect(() => {
        getInstructor();
    }, []);

    return (<>
        <div className="instructorAvailability">
            {
                loading ?
                    <>
                        <div className="flex items-center justify-center py-8">
                            <CircleSpin color="text-primary-500" />
                        </div>
                    </> :
                    hasInstuctor ? <>
                        <div className="instructorAvailability-header">
                            <AsyncSelectField
                                control={control}
                                label={''}
                                name="instructor"
                                placeholder={translator('Select Enrollment')}
                                isSearchable={false}
                                loadOptions={handleLoadInstructor}
                                customStyles={{
                                    control: (base) => {
                                        return { ...base, minHeight: '0px', height: 'auto', boxShadow: 'none', borderColor: '#E5E7EB' }
                                    },
                                    container: (base) => {
                                        return { ...base, minWidth: '220px' }
                                    },
                                    valueContainer: (base) => {
                                        return { ...base, paddingTop: 0, fontSize: 14, fontWeight: 400 }
                                    },
                                    indicatorsContainer: (base) => {
                                        return { ...base, padding: '0px 8px', height: '30px' }
                                    },
                                }}
                            />
                            {checkPermission('courseScheduling', 'C') && <div className="instructorAvailability-action">
                                <ButtonPrimary type="button" onClick={() => setHasAdSlot(true)} className="flex">
                                    <PlusIcon className="w-5 h-5 mr-1" />
                                    <span>{translator('Add Slots')}</span>
                                </ButtonPrimary>
                            </div>}

                        </div>
                        <div className="instructorAvailability-content">
                            <CalendarResourceTime instructor={watch('instructor')} onAddSlot={handleAddSlot} onOpenDetail={handleOpenDetail} />
                        </div>
                    </> :
                        <div className="flex flex-col min-h-[509px] justify-center items-center text-gray-500 text-base">
                            <img src={emptyStageIcon} className="max-h-[150px]" />
                            No Instructor
                        </div>
            }
        </div>
        {hasAdSlot && (
            <DialogComponent
                isOpen={hasAdSlot}
                onCloseModal={handleClose}
                title={instuctorSelect?.edit ? 'Edit Slot' : 'Add Slot'}
                styles="max-w-[600px] min-h-[276px] my-0 rounded-lg py-2 px-6"
                isShowClose={true}
                child={
                    <AddSlot onClose={handleClose} data={instuctorSelect} />
                }
            />
        )}
        {hasSlotDetail && (
            <DialogComponent
                isOpen={hasSlotDetail}
                onCloseModal={handleClose}
                title={''}
                styles="max-w-[600px] min-h-[276px] my-0 rounded-lg !p-0"
                isShowClose={false}
                child={
                    <SlotDetails data={instuctorSelect} onClose={handleClose} onAddSlot={(event) => {
                        setInstuctorSelect(event);
                        setHasAdSlot(true);
                    }} />
                }
            />
        )}
    </>)
}