import { CloseIcon } from 'assets/icon/CloseIcon';

import React from 'react';
type GradebookTestScores = {};

const GradebookTestScores: React.FC<GradebookTestScores> = ({}: GradebookTestScores) => {
    return (
        <>
            <div className="p-2  max-w-[343px] bg-white ">
                <div className="flex  items-center mt-3 w-full justify-between">
                    <div className="w-4 h-2 bg-[#2576B9] rounded-[5px]"></div>
                    <div className="text-sm mx-3 text-ooolab_dark_1 font-normal">
                        Apollo dark blue
                    </div>
                    <div className="text-sm text-ooolab_dark_1 ml-auto font-normal">
                        80% - 100%
                    </div>
                </div>
                <div className="flex  items-center mt-3 w-full  justify-between">
                    <div className="w-4 h-2 bg-[#3AA757] rounded-[5px]"></div>
                    <div className="text-sm mx-3  text-ooolab_dark_1 font-normal">
                        Green
                    </div>
                    <div className="text-sm text-ooolab_dark_1  ml-auto font-normal">
                        65% - 79%
                    </div>
                </div>
                <div className="flex  items-center mt-3 w-full  justify-between">
                    <div className="w-4 h-2 bg-[#FFD95C] rounded-[5px]"></div>
                    <div className="text-sm mx-3  text-ooolab_dark_1 font-normal">
                        Apollo yellow
                    </div>
                    <div className="text-sm text-ooolab_dark_1  ml-auto font-normal">
                        50% - 64%
                    </div>
                </div>
                <div className="flex  items-center mt-3 w-full  justify-between">
                    <div className="w-4 h-2 bg-[#F64B7F] rounded-[5px]"></div>
                    <div className="text-sm mx-3 text-ooolab_dark_1 font-normal">
                        Pink
                    </div>
                    <div className="text-sm text-ooolab_dark_1  ml-auto font-normal">
                        0% - 49%
                    </div>
                </div>
            </div>
        </>
    );
};
export default GradebookTestScores;
