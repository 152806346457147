import { FC, ReactNode, useState, useEffect } from 'react';
import ClassService from 'services/class.service';
import H5PPlayerContentComponent from 'components/H5P/H5PPlayer/H5PPlayerContentComponent';
import Spinner from 'components/Spinner';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import axiosInstance, { RestfulService } from 'services/restful.service';
import { useTranslation } from 'react-i18next';
import { IframePdfViewer } from '_shared/PreviewContent/IframePDFViewer';

type ModalViewResourceProps = {
    workspaceId: string;
    classId: string;
    resourceId: number;
};

const ModalViewResource: FC<ModalViewResourceProps> = ({ workspaceId, classId, resourceId }) => {
    const { t: translator } = useTranslation();
    const [entryState, setEntryState] = useState({
        editing: false,
        playing: false,
        loading: false,
        saved: false,
        saving: false,
        saveError: false,
        saveErrorMessage: '',
    });
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        if (resourceId) {
            setLoading(true);
            ClassService.viewResourceService({ workspaceId, classId, resourceId })
                .then((res) => {
                    setData(res.data);
                })
                .catch()
                .finally(() => setLoading(false));
        }
    }, [resourceId]);

    if (data) {
        let template: ReactNode;
        switch (data?.file_extension) {
            case '.png':
            case '.jpg':
            case '.jpeg':
                template = (
                    <div className="h-[calc(90vh-60px)] overflow-auto">
                        <img src={data?.file_url} alt="img/*" className="m-auto" />
                    </div>
                );
                break;
            case '.ppt':
            case '.pptx':
            case '.doc':
            case '.docx':
                template = (
                    <div className="overflow-auto">
                        <iframe
                            id="iframeView"
                            title="View file with iframe"
                            src={`https://docs.google.com/viewer?url=${data?.file_url}&embedded=true`}
                            frameBorder="1"
                            scrolling="auto"
                            height="800"
                            width="100%"
                        ></iframe>
                        {/*
                        view .doc .docx with ms office plugin
                        <iframe
                            id="iframeView"
                            title="View file with iframe"
                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${data?.file_url}`}
                            frameBorder="0"
                            scrolling="auto"
                            height="800"
                            width="100%"
                        ></iframe> */}
                    </div>
                );
                break;
            case '.pdf':
                template = (<IframePdfViewer url={data?.file_url} />);
                break;
            case '.mp3':
                template = (
                    <div className="w-full">
                        <audio controls src={data?.file_url}></audio>
                    </div>
                );
                break;
            case '.mp4':
                template = (
                    <div className="w-full">
                        <video controls width="100%" className="m-auto">
                            <source src={data?.file_url} type="video/mp4" />
                        </video>
                    </div>
                );
                break;
            case '.h5p':
                template = data?.h5p_content_id ? (
                    <>
                        <H5PPlayerContentComponent
                            argsId={{
                                workspaceId,
                                contentId: data.h5p_content_id,
                            }}
                            entryState={entryState}
                            setEntryState={setEntryState}
                        />
                    </>
                ) : (
                    'No content'
                );
                break;
            default:
                template = 'Can not view content';
        }
        return <>{template}</>;
    }

    return loading ? <Spinner /> : <div>No content</div>;
};

export default ModalViewResource;
