import React, { useState } from "react";
import { Transition } from '@headlessui/react';
import { CommentIcon } from "assets/icon";
import { useTranslation } from "react-i18next";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import "./NoteSection.scss";
import { CloseIcon } from "assets/icon/CloseIcon";
import { checkPermission } from "utils/commonFun";
const configCkEditor = {
    toolbar: [
        'bold',
        'italic',
        'bulletedList',
        'numberedList',
        'blockQuote'
    ],
    link: {
        addTargetToExternalLinks: true,
    },
    placeholder: 'Enter your note here'
};
let changeTimeout;

export const NoteSection = ({ isShowing, onClose, data, onChange }) => {
    const { t: translator } = useTranslation();
    const [note, setNote] = useState(data);

    return (
        <>
            <Transition
                show={isShowing}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
                className="flex-none w-[266px] relative"
            >
                <div className="noteSection">
                    <div className="noteSection-header">
                        <CommentIcon width={24} height={24} />
                        <span className="noteSection-text">
                            {translator('Notes')}
                        </span>
                        <button className="noteSection-close" onClick={() => {
                            onClose({ note, refresh: true });
                        }}>
                            <CloseIcon />
                        </button>
                    </div>
                    <div className="noteSection-editor">
                        <CKEditor
                            editor={ClassicEditor}
                            data={data}
                            onChange={(event, editor) => {
                                if (changeTimeout) {
                                    clearTimeout(changeTimeout);
                                }
                                changeTimeout = setTimeout(() => {
                                    setNote(editor.getData());
                                    onChange({ note: editor.getData(), refresh: false });
                                }, 600);
                            }}
                            config={configCkEditor}
                            disabled={!checkPermission('libraryContentLessonsOrScorm', 'U')}
                        />
                    </div>
                </div>
            </Transition>
        </>
    )
}