import { useForm } from 'react-hook-form';
import classService from 'services/class.service';
import { useParams } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';
import { useState } from 'react';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';

const CreateStudentFrom = ({t, onClose, refresh}) => {
    const {
        register,
        handleSubmit,
        formState: { errors, dirtyFields },
    } = useForm({mode: "onChange"});

    const params = useParams<{ id: string; userId: string }>();
    const [loading, setLoading] = useState(false)

    const submitForm = (val) => {
        setLoading(true)
        const data =  {
            students :[
                {
                    ...val,
                    amount: +val.amount,
                    student_id: +params.userId
                }
            ]}
        classService.addAposRewardService(params.id, params.userId, data)
        .then(res => {
            refresh();
            onClose();
            Toastify.success(t("COIN.ADD_COINS_SUCCESS"));
        })
        .catch(() => Toastify.error())
        .finally(() => {
            setLoading(false);
        })
    };
    return (
        <form
            onSubmit={handleSubmit(submitForm)}
            className="grid grid-cols-2 px-ooolab_p_2 gap-x-8 gap-y-5 mt-2"
        >
            
            <div className="col-span-2 text-ooolab_xs">
                <label
                    htmlFor="coins"
                    className="text-ooolab_dark_1 font-semibold leading-ooolab_24px block"
                >
                    {t("COIN.COINS")} <span className='text-red-500'> *</span>
                </label>

                <input
                    type="number"
                    placeholder={t('COIN.ENTER_NUMBER_OF_COIN')}
                    className="border lg:w-full md:w-3/4 w-1/2 border-ooolab_bar_color text-ooolab_dark_2 p-ooolab_p_2 rounded font-normal"
                    max={2147483647}
                    min={-2147483648}
                    maxLength={11}
                    {...register(`amount`, {
                        required: t('FORM_CONST.REQUIRED'),
                        maxLength: {
                            value: 255,
                            message: t('MAX_LENGTH'),
                        },
                        validate: (val) => {
                            const regex = /^-?\d+$/g;
                            return !!!val ||
                                (val != 0 && regex.test(val))
                                ? true
                                : t('FORM_CONST.THE_INPUT_MUST_BE_A_NON_ZERO_INTEGER');
                        },
                    })}
                />
                {/* @ts-ignore */}
                <ErrorMessage
                    className="text-red-500 text-xs"
                    errors={errors}
                    name="amount"
                    as="p" />
            </div>
            
            <div className="col-span-2 text-xs">
                <label
                    htmlFor="description"
                    className="text-ooolab_dark_1 font-semibold leading-ooolab_24px flex gap-1"
                >
                    {t('COIN.DESCRIPTION')} <span className='text-red-500'> *</span>
                </label>
                <textarea
                    className="border w-full border-gary-300 text-gray-800 p-2 rounded font-normal"
                    id="description"
                    placeholder="Description"
                    {...register('description', {
                        required: {
                            value: true,
                            message: t('FORM_CONST.REQUIRED'),
                        },
                        maxLength: {
                            value: 50,
                            message: t('FORM_CONST.MAX_LENGTH_TEXT', {maxLength: 50}),
                        },
                    })}
                />
                {/* @ts-ignore */}
                <ErrorMessage
                    className="text-red-500 text-xs"
                    errors={errors}
                    name="description"
                    as="p" />
            </div>
            <div className=" col-span-2 flex justify-center gap-4">
                <ButtonOutline type="button" onClick={onClose}>{t("CANCEL")}</ButtonOutline>
                <ButtonPrimary type="submit" disabled={!dirtyFields.description || !dirtyFields.amount || loading}>{t("ADD")}</ButtonPrimary>
            </div>
        </form>
    );
};

export default CreateStudentFrom;
