import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@hookform/error-message';
import { CheckCircleIcon } from '@heroicons/react/outline';

import { MESSAGE } from 'constant/message.const';
import { FORM_CONST } from 'constant/form.const';
import { PasswordContext } from 'contexts/Password/PasswordContext';
import { AuthContext } from 'contexts/Auth/AuthContext';
import {
    handleChangeConfirmPassword,
    handleChangePassword,
    handleResetPasswordState,
    onResetSubmit,
    checkVerifyTokenResetPassword,
} from './ForgotPasswordFormFn';
import { loadSVG } from 'assets/icon';
import { classNames } from 'utils/commonFun';
import HeaderChangePassword from './CreatePassword/HeaderChangePassword';
import FooterChangePassword from './CreatePassword/FooterChangePassword';
import MatchChangePassword from './CreatePassword/MatchChangePassword';
import OOOLapLogoProps from './CreatePassword/OOOLapLogo';
import PasswordEyes from 'components/PasswordWithEyes/PasswordEye';
import { ToastConfirm } from 'utils/sweetAlert';
import XIconSVG from 'assets/SVG/XIconSVG.svg';

interface CreatePasswordProps {
    tokenParam: string;
    emailParam: string;
}

const CreatePassword: React.FC<CreatePasswordProps> = ({
    tokenParam,
    emailParam,
}) => {
    const { t: translator } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const [
        apiResetPasswordSuccess,
        setApiResetPasswordSuccess,
    ] = React.useState<string>();
    const [apiError, setApiError] = React.useState<string>();
    const [isValidPassword, setIsValidPassword] = React.useState<boolean>(true);
    const { passwordState, dispatch } = React.useContext(PasswordContext);
    const { dispatch: authDispatch } = React.useContext(AuthContext);

    const {
        result,
        isLoading,
        resetPasswordResult,
        err,
        valErr,
    } = passwordState;
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        trigger,
        watch,
    } = useForm();

    React.useEffect(() => {
        if (resetPasswordResult === 204) {
            setApiResetPasswordSuccess(MESSAGE.RESET_PASSWORD_SUCCESS);
        }
        if (err) {
            setApiError(err.error.description);
        }
        if (valErr) {
            setApiError(valErr.validation_error.body_params[0].msg);
        }
    }, [resetPasswordResult || err || valErr]);

    React.useEffect(() => {
        setIsValidPassword(true);
        if (watch('confirmPassword') && watch('confirmPassword').length > 0) {
            setIsValidPassword(false);
        }
    }, [watch('confirmPassword'), watch('password')]);

    React.useEffect(() => {
        if (location.search) {
            checkVerifyTokenResetPassword(emailParam, tokenParam)
                .then((res) => {
                    if (!res.data.is_valid)
                        ToastConfirm.fire({
                            iconHtml: `<img src="${XIconSVG}" />`,
                            position: 'center',
                            title: translator(
                                'FORGOT_YOUR_PASSWORD.YOUR_VERIFICATION_EMAIL_HAS_EXPIRED'
                            ),
                            customClass: {
                                title: 'swale2-title-custom',
                                popup: 'z-9999 swale2-popup-custom',
                                icon: 'swale2-icon-custom',
                                confirmButton: 'swale2-confirmButton-custom',
                                container: 'swale2-container-custom',
                            },
                        }).then((result) => {
                            if (result.isConfirmed) {
                                history.push('/password/forgot');
                            }
                        });
                })
                .catch((err) => {
                    console.log(err?.response);
                });
        }
    }, [location.search]);

    const onSumitForm = () => {
        onResetSubmit(getValues, tokenParam, emailParam, dispatch);
    };

    return (
        <div className="flex items-center justify-center h-screen">
            {resetPasswordResult ? (
                <div className="max-w-xs lg:max-w-md">
                    <p className="text-center">
                        <CheckCircleIcon className="w-12 lg:w-14 text-ooolab_50B957 inline" />
                    </p>
                    <p className="text-ooolab_282B5E font-semibold mt-7 text-xl lg:text-3xl text-center">
                        {translator('FORM_CONST.PASSWORD_RESET_SUCCESS')}
                    </p>
                    <button
                        type="button"
                        onClick={handleResetPasswordState(
                            dispatch,
                            authDispatch,
                            history
                        )}
                        className="w-full mt-8 bg-ooola_3E7EFF px-1 py-2 rounded-md text-white"
                    >
                        {translator('BACK_TO_SIGN_IN')}
                    </button>
                </div>
            ) : (
                <form
                    className="max-w-xs lg:max-w-lg"
                    onSubmit={handleSubmit(onSumitForm)}
                >
                    <OOOLapLogoProps />
                    <HeaderChangePassword
                        title={translator(
                            'FORGOT_YOUR_PASSWORD.PLEASE_ENTER_YOUR_NEW_PASSWORD'
                        )}
                        inputClass="text-ooolab_22px lg:text-3xl font-bold text-gray-700 text-center"
                    />

                    <div className="mt-6">
                        <PasswordEyes
                            formProps={register('password', {
                                minLength: {
                                    value: 10,
                                    message: translator(
                                        'FORM_CONST.MIN_LENGTH'
                                    ),
                                },
                                maxLength: {
                                    value: 64,
                                    message: translator(
                                        'FORM_CONST.MAX_LENGTH'
                                    ),
                                },
                                required: {
                                    value: true,
                                    message: translator(
                                        'FORM_CONST.REQUIRED_FIELD'
                                    ),
                                },
                                pattern: {
                                    value: FORM_CONST.PASSWORD_REGEX,
                                    message: translator(
                                        'FORM_CONST.ONLY_USE_CHARACTERS_UPPERCASES_LOWER_CASE_NUMBERS_AND_SPACIAL_CHARACTERS'
                                    ),
                                },
                            })}
                            onChange={handleChangePassword(setValue, trigger)}
                            placeholder={translator('PASSWORD')}
                            errorsField={!!errors?.password}
                            nameInput="password"
                        />
                        {/*@ts-ignore* */}
                        <ErrorMessage
                            errors={errors}
                            name="password"
                            as="span"
                            className="text-red-500 text-xs font-semibold"
                        />
                    </div>
                    {/*@ts-ignore* */}
                    <MatchChangePassword textField={watch('password') || ''} />

                    <div className="mt-4">
                        <PasswordEyes
                            formProps={register('confirmPassword', {
                                // minLength: {
                                //     value: 10,
                                //     message: translator(
                                //         'FORM_CONST.MIN_LENGTH'
                                //     ),
                                // },
                                // maxLength: {
                                //     value: 64,
                                //     message: translator(
                                //         'FORM_CONST.MAX_LENGTH'
                                //     ),
                                // },
                                // required: {
                                //     value: true,
                                //     message: translator(
                                //         'FORM_CONST.REQUIRED_FIELD'
                                //     ),
                                // },
                                validate: (value) => {
                                    return value === watch('password')
                                        ? true
                                        : translator(
                                              'FORM_CONST.LOOK_LIKE_YOUR_NEW_PASSWORD_FIELDS_DONT_MATCH'
                                          );
                                },
                            })}
                            onChange={handleChangeConfirmPassword(
                                setValue,
                                trigger
                            )}
                            placeholder={translator('CONFIRM_PASSWORD')}
                            errorsField={!!errors?.confirmPassword}
                            nameInput="confirmPassword"
                        />
                        {/*@ts-ignore* */}
                        <ErrorMessage
                            errors={errors}
                            name="confirmPassword"
                            as="span"
                            className="text-red-500 text-xs font-semibold"
                        />
                        {apiError && (
                            <span className="text-red-500 text-xs font-semibold">
                                {apiError}
                            </span>
                        )}
                        {apiResetPasswordSuccess && (
                            <span className="text-green-500 text-xs font-semibold">
                                {apiResetPasswordSuccess}
                            </span>
                        )}
                    </div>

                    <FooterChangePassword
                        inputClass="w-full mt-8 text-center"
                        child={
                            <button
                                type="submit"
                                disabled={isValidPassword}
                                className={classNames(
                                    'w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ',
                                    'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
                                    isValidPassword
                                        ? 'bg-ooola_EFF0FA text-ooolab_8083A3 cursor-not-allowed'
                                        : 'bg-primary-500 hover:bg-primary-500/80'
                                )}
                            >
                                {isLoading ? loadSVG() : null}
                                {translator(
                                    'FORGOT_YOUR_PASSWORD.SET_NEW_PASSWORD'
                                )}
                            </button>
                        }
                    />
                </form>
            )}
        </div>
    );
};

export default CreatePassword;
