import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Pagination from 'components/V2/Pagination';
import { getIndexPage } from 'utils/commonFun';
import DialogComponent from 'components/Dialog/DialogComponent';
import CreateLearnerPortal from '../CreateUser/LearnerPortal';
import CreateUser from '../CreateUser/User';
import UserHistoryTable from './UserHistoryTable';
import userService from 'services/user.service';
import FilterUserHistory from './FilterUserHistory/FilterUserHistory';

export default function UserHistoryList({ children }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [indexPage, setIndexPage] = useState('1-50');
    const [studentList, setStudentList] = useState([]);
    const [isFirstLoad, setFirstLoad] = useState(true);
    const [location, setLocation] = useState('clms')
    const [openModalCreateLearnerPortal, setOpenModalCreateLearnerPortal] = useState(false)
    const [openModalCreateUser, setOpenModalCreateUser] = useState(false);
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'created_at',
        total: 0,
    });
    const params: { id } = useParams();
    const workspaceId = params?.id;

    const getUserList = (page: number = 1, filter = undefined) => {
        const bodyRequest = {
            ...pagination,
            page: page,
            ...filter,
            state: filter?.state
        };
        if (!bodyRequest.state) delete bodyRequest?.state;
        setLoading(true);
        userService.getImportWorkspaceMemberHistory(workspaceId, bodyRequest)
            .then((res: any) => {
                setFirstLoad(false)
                if (res) {
                    const { items, page, total } = res.data;
                    setStudentList(items);
                    setPagination({ ...bodyRequest, page, total });
                    setIndexPage(getIndexPage(page, 20, items.length));
                }
            })
            .finally(() => setLoading(false))
    };

    const handlePagination = (page: number) => {
        setLoading(true);
        getUserList(page);
    };

    useEffect(() => {
        getUserList(1);
    }, []);

    const onChangeFilter = (filter) => {
        getUserList(1, filter)
    }

    return (
        <div className="w-full h-screen">
            <PageBreadcrumb
                pageNameDefault={t('DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT')}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    { name: t('DASHBOARD.SIDEBAR.USERS'), href: `/workspace/${params.id}/management/users`, className: '' },
                    { name: t('USER.HISTORY'), href: '', className: '' }
                ]}
            />
            <div className='p-5 bg-white border-b border-gray-300'>
                <h1 className='page-title'>{t('USER.HISTORY')}</h1>
            </div>
            <div className='bg-gray-50 px-5 pt-4 '>
                <div className='bg-white'>
                    <div className='flex justify-between px-5 py-4 border-b border-gray-300'>
                        <FilterUserHistory onChangeFilter={onChangeFilter} isFirstLoad={isFirstLoad} setLocation={setLocation} location={location}/>
                    </div>
                    <div className={`px-5 pt-4`}>
                        <div className="h-[calc(100vh-285px)] custom-scrollbar overflow-y-auto relative">
                            {loading ? (
                                <div className="flex items-center justify-center mt-4">
                                    <CircleSpin color="text-primary-500" />
                                </div>
                            ) : (
                                <UserHistoryTable location={location} loading={loading} workspaceId={workspaceId} dataTable={studentList} t={t} refreshData={() => getUserList(1)} />
                            )}
                        </div>
                        <Pagination
                            indexPage={indexPage}
                            page={pagination?.page}
                            per_page={pagination?.per_page}
                            total={pagination.total}
                            callback={handlePagination}
                        />
                    </div>
                </div>
            </div>            
            <DialogComponent
                isOpen={openModalCreateLearnerPortal}
                title={t("USER.NEW_LEARNER_PORTAL")}
                onCloseModal={() => setOpenModalCreateLearnerPortal(false)}
                maskClosable={false}
                styles="max-w-lg"
                child={
                    <CreateLearnerPortal
                        submitted={() => {
                            getUserList();
                            setOpenModalCreateLearnerPortal(false);
                        }}
                        onCloseModal={() => setOpenModalCreateLearnerPortal(false)}
                    />
                }
            />

            <DialogComponent
                isOpen={openModalCreateUser}
                title={t("USER.NEW_CLMS_USER")}
                onCloseModal={() => setOpenModalCreateUser(false)}
                maskClosable={false}
                styles="max-w-lg"
                child={
                    <CreateUser
                        submitted={() => {
                            getUserList();
                            setOpenModalCreateUser(false);
                        }}
                        onCloseModal={() => setOpenModalCreateUser(false)}
                    />
                }
            />
        </div>
    );
}
