import { Dialog, RadioGroup, Transition } from '@headlessui/react';
import { SearchIcon } from '@heroicons/react/outline';
import SearchBox from 'components/V2/SearchBox';
import { debounce } from 'lodash';
import { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router';
import workspaceService from 'services/workspace.service';
import { NormalResponseError } from 'types/Common.type';
import { LessonInterface } from 'types/GetListOfWorkspace.type';
import { getTimeFromNow } from 'utils/handleFormatTime';

type ImportCourseProps = {
    open: boolean;
    onCloseModal: () => void;
    onSubmit: (e: any) => Promise<boolean>;
    onSuccessImport: () => void;
};

type RadioLessonInterfae = LessonInterface & {
    check?: boolean;
};

const SkeletonItem = () => {
    return (
        <>
            <div className="grid animate-pulse gap-2 grid-cols-10 mb-3 px-5 mt-3">
                <div className="col-span-6 font-medium text-ooolab_gray_10  flex items-center bg-ooolab_dark_50 rounded w-2/3 h-5"></div>
                <div className="col-span-4 flex justify-center font-medium text-ooolab_gray_10  h-5">
                    <div className="bg-ooolab_dark_50 rounded w-2/5 h-full"></div>
                </div>
            </div>
            <div className="grid animate-pulse gap-2 grid-cols-10 mb-3 px-5 mt-3">
                <div className="col-span-6 font-medium text-ooolab_gray_10  flex items-center bg-ooolab_dark_50 rounded w-2/3 h-5"></div>
                <div className="col-span-4 flex justify-center font-medium text-ooolab_gray_10  h-5">
                    <div className="bg-ooolab_dark_50 rounded w-2/5 h-full"></div>
                </div>
            </div>
            <div className="grid animate-pulse gap-2 grid-cols-10 mb-3 px-5 mt-3">
                <div className="col-span-6 font-medium text-ooolab_gray_10  flex items-center bg-ooolab_dark_50 rounded w-2/3 h-5"></div>
                <div className="col-span-4 flex justify-center font-medium text-ooolab_gray_10  h-5">
                    <div className="bg-ooolab_dark_50 rounded w-2/5 h-full"></div>
                </div>
            </div>
            <div className="grid animate-pulse gap-2 grid-cols-10 mb-3 px-5 mt-3">
                <div className="col-span-6 font-medium text-ooolab_gray_10  flex items-center bg-ooolab_dark_50 rounded w-2/3 h-5"></div>
                <div className="col-span-4 flex justify-center font-medium text-ooolab_gray_10  h-5">
                    <div className="bg-ooolab_dark_50 rounded w-2/5 h-full"></div>
                </div>
            </div>
        </>
    );
};

const ImportCourse: FC<ImportCourseProps> = ({
    open,
    onCloseModal,
    onSubmit,
    onSuccessImport,
}) => {
    const [importError, setImportError] = useState<string[]>([]);
    const params: { id: string } = useParams();
    const cancelButtonRef = useRef(null);
    const courseRef = useRef<any>();
    const [listCourses, setListCourses] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const { t: translator } = useTranslation();
    const [page, setPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [maxData, setMaxData] = useState(0);
    const [selected, setSelected] = useState<string>();

    let isLoading = false;
    const handleSearchLesson = (e: string, _page: number) => {
        if (!isLoading) {
            isLoading = true;
            setPage(_page);
            workspaceService
                .getCoursesList(params.id, {
                    status: 'draft',
                    title: e,
                    page: _page,
                })
                .then((res) => {
                    isLoading = false;
                    setMaxData(res.total);
                    if (res.page == 1) {
                        setListCourses(res.items);
                    } else {
                        setListCourses(listCourses.concat(res.items));
                    }
                    console.log(listCourses);
                })
                .catch(() => setListCourses([]))
                .finally(() => setTimeout(() => setLoading(false), 300));
        }
    };

    const debounceInput = useCallback(
        debounce((nextValue: string) => {
            handleSearchLesson(nextValue, 1);
        }, 700),
        []
    );

    useEffect(() => {
        if (open) {
            handleSearchLesson('', 1);
        }
    }, [open]);

    const onChange = (e) => {
        setSelected(e);
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-70 inset-0 overflow-y-hidden"
                initialFocus={cancelButtonRef}
                open={open}
                onClose={onCloseModal}
            >
                <div className="flex i justify-center min-h-screen p-4 pb-20 text-center items-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 dialog-background  bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded text-left overflow-hidden shadow-xl transform transition-all sm:align-middle relative sm:align-middle sm:max-w-lg sm:w-full">
                            <form>
                                <div className="p-ooolab_p_5">
                                    <div className="flex items-center ">
                                        <p className="w-10/12 text-base text-ooolab_dark_1">
                                            {translator(
                                                'COURSES.SELECT_COURSE'
                                            )}
                                        </p>
                                    </div>
                                    <div className="relative mt-4">
                                        {/* <input
                                            type="text"
                                            placeholder={translator('SEARCH')}
                                            className="focus:outline-none pl-3 pr-9 w-full h-full border border-ooolab_border_logout"
                                            onChange={(e) =>
                                                debounceInput(e.target.value)
                                            }
                                        ></input>
                                        <SearchIcon
                                            className="absolute top-2 right-2 w-5 h-5 text-ooolab_dark_2 hover:text-ooolab_blue_4 cursor-pointer"
                                            // onClick={handleSearchLesson}
                                        /> */}
                                        <SearchBox onSubmit={debounceInput} />
                                    </div>
                                </div>
                                <div className="overflow-x-auto">
                                    <div className=" align-middle inline-block min-w-full">
                                        <div className="overflow-hidden">
                                            <div className="grid gap-2 grid-cols-10 mb-3 px-5">
                                                <div className="col-span-6 text-sm font-semibold text-ooolab_dark_1 flex items-center">
                                                    <p className="">
                                                        {translator(
                                                            'COURSES.NAME'
                                                        )}
                                                    </p>
                                                </div>

                                                <div className="col-span-4 justify-center text-sm font-semibold text-ooolab_dark_1 flex items-center">
                                                    <p className="">
                                                        {translator(
                                                            'COURSES.LAST_MODIFIED'
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-b rounded-sub_tab bg-ooolab_vertical_line mb-3">
                                            {' '}
                                        </div>
                                        <InfiniteScroll
                                            hasMore={
                                                listCourses.length < maxData
                                            }
                                            next={() =>
                                                handleSearchLesson(
                                                    searchValue,
                                                    page + 1
                                                )
                                            }
                                            height={'30vh'}
                                            loader={undefined}
                                            dataLength={listCourses.length}
                                            className="custom-scrollbar"
                                        >
                                            {(loading && <SkeletonItem />) || (
                                                <RadioGroup
                                                    value={selected}
                                                    onChange={(e) =>
                                                        onChange(e)
                                                    }
                                                >
                                                    {(listCourses &&
                                                        listCourses?.length &&
                                                        listCourses?.map(
                                                            (d) => (
                                                                <RadioGroup.Option
                                                                    disabled={
                                                                        d.check
                                                                    }
                                                                    key={d.id}
                                                                    value={d.id}
                                                                    className={({
                                                                        active,
                                                                        checked,
                                                                        disabled,
                                                                    }) =>
                                                                        `${
                                                                            active &&
                                                                            !disabled &&
                                                                            'bg-ooolab_bg_bar text-primary-500'
                                                                        }
                                                    ${
                                                        checked &&
                                                        !disabled &&
                                                        'bg-ooolab_bg_bar text-primary-500'
                                                    }

                                              ${
                                                  !disabled
                                                      ? 'hover:bg-ooolab_bg_bar cursor-pointer '
                                                      : 'opacity-20 cursor-not-allowed'
                                              } w-full grid grid-cols-10 gap-2 py-2 px-5`
                                                                    }
                                                                >
                                                                    {({
                                                                        active,
                                                                        checked,
                                                                    }) => (
                                                                        <>
                                                                            <div className="whitespace-nowrap flex items-center col-span-6">
                                                                                <span className="p-2 bg-primary-500 rounded-md mr-3">
                                                                                    <svg
                                                                                        width="12"
                                                                                        height="14"
                                                                                        viewBox="0 0 12 14"
                                                                                        fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        className="w-ooolab_w_31 h-ooolab_h_3_e"
                                                                                    >
                                                                                        <path
                                                                                            d="M0.666748 1.66683C0.666748 0.930451 1.2637 0.333496 2.00008 0.333496H7.33341C7.51023 0.333496 7.67979 0.403734 7.80482 0.528758L11.1382 3.86209C11.2632 3.98712 11.3334 4.15669 11.3334 4.3335V12.3335C11.3334 13.0699 10.7365 13.6668 10.0001 13.6668H2.00008C1.2637 13.6668 0.666748 13.0699 0.666748 12.3335V1.66683ZM9.72394 4.3335L7.33341 1.94297V4.3335H9.72394ZM6.00008 1.66683L2.00008 1.66683V12.3335H10.0001V5.66683H6.66675C6.29856 5.66683 6.00008 5.36835 6.00008 5.00016V1.66683ZM3.33341 7.66683C3.33341 7.29864 3.63189 7.00016 4.00008 7.00016H8.00008C8.36827 7.00016 8.66675 7.29864 8.66675 7.66683C8.66675 8.03502 8.36827 8.3335 8.00008 8.3335H4.00008C3.63189 8.3335 3.33341 8.03502 3.33341 7.66683ZM3.33341 10.3335C3.33341 9.96531 3.63189 9.66683 4.00008 9.66683H8.00008C8.36827 9.66683 8.66675 9.96531 8.66675 10.3335C8.66675 10.7017 8.36827 11.0002 8.00008 11.0002H4.00008C3.63189 11.0002 3.33341 10.7017 3.33341 10.3335Z"
                                                                                            fill="white"
                                                                                        />
                                                                                    </svg>
                                                                                </span>
                                                                                <p
                                                                                    className={`text-xs overflow-hidden overflow-ellipsis whitespace-pre`}
                                                                                >
                                                                                    {
                                                                                        d.title
                                                                                    }
                                                                                </p>
                                                                            </div>

                                                                            <div className=" whitespace-nowrap col-span-4 text-center">
                                                                                <span className=" inline-flex text-xs leading-5 ">
                                                                                    <p
                                                                                        className={`text-xs`}
                                                                                    >
                                                                                        {d.updated_at &&
                                                                                            getTimeFromNow(
                                                                                                d.updated_at
                                                                                            )}
                                                                                    </p>
                                                                                </span>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </RadioGroup.Option>
                                                            )
                                                        )) || (
                                                        <p className="px-5">
                                                            {translator(
                                                                'ADD_H5P_CONTENT.NO_RESULT'
                                                            )}
                                                        </p>
                                                    )}
                                                </RadioGroup>
                                            )}
                                        </InfiniteScroll>
                                    </div>
                                </div>
                                <div className="flex justify-between px-6 py-2">
                                    <p className="text-xs text-red-500">
                                        {importError.map((i) => (
                                            <span>{i}</span>
                                        ))}
                                    </p>
                                    <button
                                        onClick={async (e) => {
                                            e.preventDefault();
                                            setImportError([]);
                                            if (selected) {
                                                try {
                                                    await onSubmit(selected);
                                                    onCloseModal();
                                                    setTimeout(() => {
                                                        onSuccessImport();
                                                    }, 200);
                                                } catch (error) {
                                                    const err = error as NormalResponseError;
                                                    setImportError(
                                                        err.error.body_params.map(
                                                            (i) =>
                                                                translator(
                                                                    `CLASSES.${i.msg}`
                                                                )
                                                        )
                                                    );
                                                }
                                            }
                                        }}
                                        className="primary-button"
                                    >
                                        {translator('SELECT')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default ImportCourse;
