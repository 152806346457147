
import * as React from 'react';
import { useParams } from 'react-router-dom';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { H5PContext } from 'contexts/H5P/H5PContext';

import axiosInstance, { RestfulService } from 'services/restful.service';
import { AssignmentViewPageProps } from 'pages/AssignmentViewPage/AssignmentViewPage';
import { CheckIcon } from '@heroicons/react/outline';
import { getLocalCookie } from 'utils/handleAuthorized';
import axios from 'axios';
import H5PAssignmentContentListEntryComponent from 'components/H5P/H5PAssignment/H5PAssignmentContentEntryComponent'

const AssignmentView: React.FC<AssignmentViewPageProps> = ({ emailParam, otpParam }) => {
    const params: { id: string; contentId: string } = useParams();
    const argsId = {
        workspaceId: params.id,
        contentId: params.contentId,
    };
    const [entryState, setEntryState] = React.useState({
        editing: false,
        playing: false,
        loading: false,
        saved: false,
        saving: false,
        saveError: false,
        saveErrorMessage: '',
    });
    const h5PCtx = React.useContext(H5PContext);
    const [
        h5pContentListEntryState,
        setH5PContentListEntryState,
    ] = React.useState<any[]>([]);
    const [currentFrame, setCurrentFrame] = React.useState(0);
    const [isRestartAttemp, setIsRestart] = React.useState(false);
    const {
        dispatch,
    } = h5PCtx;

    axiosInstance.defaults.headers["X-OTP-Email"] = emailParam;
    axiosInstance.defaults.headers["X-OTP-Token"] = otpParam;

    React.useEffect(() => {
        RestfulService.getApi(
            `/assignments/${params.contentId}`
        ).then(res => {
            console.log("tasks", JSON.stringify(res.data.tasks));
            if (res.data.completion_percentage == 100) {
                setIsRestart(true);
                for (let i = 0; i < res.data.tasks.length; i++) {
                    res.data.tasks[i].is_finished = false;

                }
            }
            setH5PContentListEntryState(res.data.tasks);
        });

    }, []);
    const scrollToImage = i => {
        // First let's set the index of the image we want to see next
        setCurrentFrame(i);
    };
    const setFinishContent = (argsId, input) => {
        const { object, result } = input;
        console.log(JSON.stringify(object));
        console.log(JSON.stringify(result));

        let temp = h5pContentListEntryState.slice();
        let index = temp.findIndex(e => e.uid == argsId.contentId);
        let previousStatus = temp[index].is_finished;
        if (index > -1) {
            temp[index].is_finished = true;
        }
        console.log((temp));
        setH5PContentListEntryState(temp);
        if (!previousStatus && result.fake_event) {
            const cookieToken = getLocalCookie();
            axiosInstance.defaults.headers["X-CSRF-TOKEN"] = cookieToken;
            var bodyFormData = new FormData();
            bodyFormData.append('contentId', object.id);
            bodyFormData.append('opened', Date.now().toString());
            bodyFormData.append('finished', Date.now().toString());

            axios({
                method: "post",
                url: `/v1/assignments/${params.contentId}/contents/${object.id}/finished`,
                data: bodyFormData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-CSRF-TOKEN": cookieToken
                },
            })
                .then(function (response) {
                    //handle success
                    console.log(response);
                })
                .catch(function (response) {
                    //handle error
                    console.log(response);
                });


        }
        let shouldBack = true;
        for (let i = 0; i < temp.length; i++) {
            if (!temp[i].is_finished) {
                shouldBack = false;
            }
        }
        console.log("shouldBack", temp);
        if (shouldBack) {
            console.log("flutter_inappwebview");
            (window as any)?.flutter_inappwebview?.callHandler('h5pSubmit', { tasks: temp }).then(function (result) {

            })
        }
    }
    return (
        <>
            <div className='px-4 pt-4 custom-scrollbar' style={{ whiteSpace: 'nowrap', overflowX: 'scroll' }}>
                {h5pContentListEntryState ? h5pContentListEntryState.map((content, i) => {
                    if (currentFrame == i)
                        return <button
                            type="button"
                            onClick={() => {
                                scrollToImage(i)
                            }}
                            style={{ maxWidth: '200px' }}
                            className="pl-4 pr-4 inline-flex mx-1 items-center px-2.5 py-1.5 border border-ooolab_blue_1 text-sm font-medium rounded-full shadow-sm text-white bg-primary-500 hover:bg-primary-500 hover:text-white "
                        >
                            <p className='truncate'>{content.title}</p> {content.is_finished ? <CheckIcon width={16} style={{ marginLeft: '8px' }}></CheckIcon> : null}
                        </button>
                    else
                        return content.is_finished ? <button
                            type="button"
                            onClick={() => {
                                scrollToImage(i)
                            }}
                            style={{ maxWidth: '200px' }}
                            className="pl-4 pr-4 inline-flex mx-1 items-center px-2.5 py-1.5 border border-green-600 text-sm font-medium rounded-full shadow-sm text-green-600 bg-white hover:bg-primary-500 hover:text-white "
                        >
                            <p className='truncate'>{content.title} </p> <CheckIcon width={16} style={{ marginLeft: '8px' }}></CheckIcon>
                        </button> :
                            <button
                                type="button"
                                onClick={() => {
                                    scrollToImage(i)
                                }}
                                style={{ maxWidth: '200px' }}
                                className="pl-4 pr-4 inline-flex mx-1 items-center px-2.5 py-1.5 border border-ooolab_blue_1 text-sm font-medium rounded-full shadow-sm text-primary-500 bg-white hover:bg-primary-500 hover:text-white "
                            >
                                <p className='truncate'>{content.title}</p>
                            </button>

                }) : null}
            </div>
            <div className="w-screen flex justify-center">


                <div className="p-4 flex justify-center w-screen items-center">
                    <div className="relative w-full">
                        <div style={{
                            display: "flex",
                            overflowX: "hidden",
                            scrollSnapType: "x mandatory"
                        }}>
                            {/* {sliderControl(true)} */}
                            {/* {h5pContentListEntryState.map((contentId, i) => ( */}
                            {h5pContentListEntryState.length > 0 ? <div className="w-full flex-shrink-0" key={h5pContentListEntryState[currentFrame]}>

                                <H5PAssignmentContentListEntryComponent
                                    argsId={{ workspaceId: undefined, contentId: h5pContentListEntryState[currentFrame].uid }}
                                    h5pPlayerPromise={
                                        async () => {
                                            const res = await RestfulService.getApi(
                                                `/assignments/${params.contentId}/contents/${h5pContentListEntryState[currentFrame].uid}/play`
                                            );
                                            if (!res) {
                                                console.log(`${res.status} ${res.statusText}`);
                                                // throw new Error(`${res.status} ${res.statusText}`);
                                            }
                                            return res.data;
                                        }
                                    }
                                    entryState={entryState}
                                    setEntryState={setEntryState}
                                    setFinishContent={setFinishContent}
                                />
                            </div> : null}
                            {/* ))} */}
                            {/* {sliderControl(false)} */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AssignmentView;
