export const ListSettingIcon = () => {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2916_82971)">
                <path d="M0.833008 7.49996H3.74967V8.33329H0.833008V7.49996ZM0.833008 4.58329H4.58301V5.41663H0.833008V4.58329ZM0.833008 1.66663H9.16634V2.49996H0.833008V1.66663ZM8.61384 5.42704L9.09551 5.26413L9.51217 5.98579L9.13051 6.32121C9.17855 6.54898 9.17855 6.78427 9.13051 7.01204L9.51217 7.34746L9.09551 8.06913L8.61384 7.90621C8.44301 8.06038 8.23968 8.17913 8.01592 8.25204L7.91634 8.74996H7.08301L6.98301 8.25163C6.76187 8.17936 6.55833 8.06155 6.38551 7.90579L5.90384 8.06913L5.48717 7.34746L5.86884 7.01204C5.8208 6.78427 5.8208 6.54898 5.86884 6.32121L5.48717 5.98579L5.90384 5.26413L6.38551 5.42704C6.55634 5.27288 6.75967 5.15413 6.98342 5.08121L7.08301 4.58329H7.91634L8.01634 5.08163C8.23967 5.15413 8.44301 5.27329 8.61384 5.42746V5.42704ZM7.49967 7.08329C7.61018 7.08329 7.71616 7.03939 7.7943 6.96125C7.87244 6.88311 7.91634 6.77713 7.91634 6.66663C7.91634 6.55612 7.87244 6.45014 7.7943 6.372C7.71616 6.29386 7.61018 6.24996 7.49967 6.24996C7.38917 6.24996 7.28319 6.29386 7.20505 6.372C7.12691 6.45014 7.08301 6.55612 7.08301 6.66663C7.08301 6.77713 7.12691 6.88311 7.20505 6.96125C7.28319 7.03939 7.38917 7.08329 7.49967 7.08329Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_2916_82971">
                    <rect width="10" height="10" rx="5" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}