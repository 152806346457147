import { FC, useCallback, useContext, useEffect, useState } from 'react';
import {
    FieldValues,
    UseFormGetValues,
    UseFormRegister,
} from 'react-hook-form';
import { SearchIcon } from '@heroicons/react/outline';
import { CheckboxType } from 'types/Lesson.type';
import { useTranslation } from 'react-i18next';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useParams } from 'react-router';
import workspaceMiddleware from 'middleware/workspace.middleware';
import InfiniteScroll from 'react-infinite-scroll-component';

interface Props {
    register: UseFormRegister<FieldValues>;
    getValues: UseFormGetValues<FieldValues>;
}

const iconStyle = 'w-4 h-4 text-ooolab_dark_2 mb-2';

const FilterTags: FC<Props> = ({ getValues, register }) => {
    const { t: translator } = useTranslation();
    const [dataTagsView, setDataTagsView] = useState<CheckboxType[]>([]);
    const [inputText, setInputText] = useState<string>();
    const [selectedTags, setSelectedTags] = useState<(string | number)[]>([]);

    const scrollToTop = (num: number) => {
        const e = document.getElementsByClassName('infinite-scroll-component')[
            num
        ];
        if (e) {
            e.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const params: { id: string } = useParams();

    const { dispatch: WorkspaceDispatch, getWorkspaceDetailState } = useContext(
        GetWorkspaceContext
    );
    const { tagList } = getWorkspaceDetailState;

    const resetListTags = () => {
        setDataTagsView([]);
        workspaceMiddleware.ResetTags(WorkspaceDispatch);
    };

    useEffect(() => {
        workspaceMiddleware.getLessonTags(WorkspaceDispatch, params.id);
        return () => {
            if (resetListTags) {
                resetListTags();
            }
        };
    }, []);

    const handleSearchTag = (e: string) => {
        setSelectedTags(dataTagsView.map((i) => i.id));
        workspaceMiddleware.getLessonTags(WorkspaceDispatch, params.id, e, {
            page: 1,
        });
        scrollToTop(0);
    };

    const onChange = (e: string) => {
        setInputText(e);
    };

    useEffect(() => {
        if (tagList?.items.length) {
            if (tagList.page === 1) {
                setDataTagsView([]);
            }
            tagList.items.map((i) => {
                const type = {
                    check:
                        (selectedTags?.length &&
                            selectedTags?.includes(`${i.id}`)) ||
                        false,
                    id: i.id,
                    name: i.name,
                };

                setDataTagsView((pre) => [...pre, type]);
            });
        } else setDataTagsView([]);
    }, [tagList]);

    const getMore = () => {
        workspaceMiddleware.getLessonTags(
            WorkspaceDispatch,
            params.id,
            inputText,
            {
                page: tagList?.page + 1 || 1,
            }
        );
    };

    return (
        <div>
            <p className="font-medium w-full text-ooolab_sm text-black py-1">
                Tags
            </p>

            <div className="relative mb-4 mt-1 pr-1">
                <input
                    className=" bg-gray-50 rounded-sub_tab focus:outline-none pl-10 py-1 text-ooolab_xs"
                    type="text"
                    placeholder={'Search Tags'}
                    onChange={(e) => {
                        onChange(e.target.value);
                        if (e.target.value == '') {
                            handleSearchTag(e.target.value);
                        }
                    }}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleSearchTag(e.target.value);
                        }
                    }}
                />
                <SearchIcon
                    style={{ top: '25%' }}
                    className={`${iconStyle}  absolute left-0 top-in  ml-2 cursor-pointer`}
                    onClick={() => handleSearchTag(inputText)}
                />
            </div>
            <div className="max-h-32 overflow-y-scroll custom-scrollbar">
                <InfiniteScroll
                    dataLength={dataTagsView?.length}
                    next={() => getMore()}
                    hasMore={dataTagsView?.length !== tagList?.total}
                    loader={false && <h4>'Loading'</h4>}
                    height={'128px'}
                    scrollableTarget="scrollableDiv "
                    className="custom-style-scrollbar scrollData"
                >
                    {dataTagsView &&
                        dataTagsView.map((i) => (
                            <div key={i.id} className="flex items-center mb-2">
                                <input
                                    className="mr-3 w-4 h-4"
                                    type="checkbox"
                                    defaultChecked={i.check}
                                    {...register(`${'tags'}.${i.id}`)}
                                    id={i.name}
                                />
                                <label
                                    className="text-ooolab_xs text-black"
                                    htmlFor={i.name}
                                >
                                    {i?.name?.length < 16
                                        ? i?.name
                                        : `${i?.name?.slice(0, 17)}...`}
                                </label>
                            </div>
                        ))}
                </InfiniteScroll>
            </div>
        </div>
    );
};

export default FilterTags;
