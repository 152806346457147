import React from 'react';
import {
    ChevronUpIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    DotsCircleHorizontalIcon,
    ArrowLeftIcon,
    XIcon,
} from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import './Dropdown.scss';
import options from 'constant/dropdownProgressReport';
import { classNames } from 'utils/commonFun';
import { UseFormTrigger } from 'react-hook-form';

interface DropdownProps {
    inputProps?: any;
    label: string;
    placeholder?: string;
    skill: string;
    studentName?: string;
    setValue: Function;
    clearErrors: Function;
    position?: string;
    defaultValue: string;
}

const Dropdown: React.FC<DropdownProps> = ({
    label,
    inputProps,
    placeholder = 'DROPDOWN_FEEDBACK',
    skill,
    studentName,
    setValue,
    clearErrors,
    position = 'bottom',
    defaultValue,
}) => {
    const { t: translator } = useTranslation();
    const [data, setData] = React.useState([]);
    const [isDropdown, setIsDropdown] = React.useState(false);
    const [option, setOption] = React.useState(null);
    const [selectOption, setSelectOption] = React.useState('');

    React.useEffect(() => {
        setData(options[skill].rates);
    }, []);

    React.useEffect(() => {
        if (defaultValue) setSelectOption(defaultValue);
    }, [defaultValue]);

    const translateValue = () => {
        let value = '';
        if (selectOption) {
            let temp = selectOption.split('_');
            value = translator(`RATING.${temp[0]}.${selectOption}`);
        }
        return value && studentName + value;
    };

    const handleOpenDropdown = () => {
        setIsDropdown(!isDropdown);
    };

    const handleClearValue = () => {
        setSelectOption('');
        setValue(skill.toLocaleLowerCase(), null), { shouldTouch: true };
        setOption(null);
        setData(options[skill].rates);
    };

    return (
        <div className="dropdown-list">
            <label htmlFor={label} className="dropdown-label">
                {translator(`GRADEBOOK_APOLLO.CREATE_REPORT.${label}`)}
            </label>
            <div className="dropdown-input">
                <input
                    type="text"
                    {...inputProps}
                    readOnly
                    id={label}
                    value={translateValue()}
                    onClick={handleOpenDropdown}
                    placeholder={translator(`GRADEBOOK_APOLLO.CREATE_REPORT.${placeholder}`)}
                />
                {!!selectOption ? (
                    <XIcon className="w-5 h-5 icon-xicon" onClick={handleClearValue} />
                ) : isDropdown ? (
                    <ChevronUpIcon className="w-5 h-5 icon-chevron-up" onClick={handleOpenDropdown} />
                ) : (
                    <ChevronDownIcon className="w-5 h-5 icon-chevron-down" onClick={handleOpenDropdown} />
                )}
            </div>
            {isDropdown && (
                <div className={classNames('dropdown-option-wrapper', position)}>
                    <div className="dropdown-option">
                        {data.map((item) => (
                            <div key={item.id}>
                                {!!!option && item.show && (
                                    <div
                                        className="option"
                                        onClick={() => {
                                            setOption(item.id);
                                            let arr = [...data].map((i) => ({
                                                ...i,
                                                show: i.id === item.id,
                                            }));
                                            setData(arr);
                                        }}
                                    >
                                        <p>{item.label}</p>
                                        <ChevronRightIcon className="w-5 h-5 icon-chevron-right" />
                                    </div>
                                )}
                                {!!option && item.show && (
                                    <div className="value-wrapper h-48 custom-scrollbar overflow-scroll">
                                        <div
                                            className="value-header"
                                            onClick={() => {
                                                setOption(null);
                                                let arr = [...data].map((i) => ({
                                                    ...i,
                                                    show: true,
                                                }));
                                                setData(arr);
                                            }}
                                        >
                                            <ArrowLeftIcon className="w-5 h-5 icon-arrow-left" />
                                            <p>Back</p>
                                        </div>
                                        {item.values.map((el) => (
                                            <div
                                                className="value"
                                                key={el.value}
                                                onClick={() => {
                                                    setSelectOption(el.value);
                                                    setValue(skill.toLocaleLowerCase(), el.value), { shouldTouch: true };
                                                    clearErrors(skill.toLocaleLowerCase());
                                                    setIsDropdown(false);
                                                }}
                                            >
                                                <DotsCircleHorizontalIcon
                                                    className={`w-5 h-5 icon-circle ${selectOption === el.value && 'select'}`}
                                                />
                                                <p className={`${selectOption === el.value && 'select'}`}>
                                                    {studentName + el.label}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dropdown;
