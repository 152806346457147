import React, { useState } from 'react';
import { CloudCheckIcon, DesktopIcon, MobileIcon } from 'assets/icon';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ButtonBack, ButtonOutline, ButtonPrimary, ConfirmModal, Toastify } from '_shared';
import { PresentationIcon } from 'assets/icon/PresentationIcon';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { LIBRARY_TAB } from 'components/Library/LibraryContants';

import './AssigmentBuilderHeader.scss';
import { LESSON_STATUS, LESSON_STATUS_CODE, VIEW_TYPE } from 'components/Library/LessonBuilder/constants';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { assignmentUpdate, changeViewType } from 'store/features/assignmentSlice';
import libraryService from 'services/library.service';
import { checkPermission } from 'utils/commonFun';

export const AssigmentBuilderHeader = ({ folderId, setMissingInformation, isMissingInformation }) => {
    const dispatch = useAppDispatch();
    const { loading, data: details, changed, viewType, isNew } = useAppSelector((state) => state.assignment);
    const [openWarning, setOpenWarning] = useState(false);
    const { t: translator } = useTranslation();
    const params: any = useParams();
    const history = useHistory();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openWarningCreateEmptyAssignment, setOpenModalWarningCreateEmptyAssignment] = useState(false);

    const onCloseModalWarning = () => {
        setOpenWarning(false);
        setMissingInformation();
    };

    const onClose = async (value) => {
        if (value) {
            let status = LESSON_STATUS_CODE.draft;
            if (details?.status == LESSON_STATUS_CODE.draft) {
                status = LESSON_STATUS_CODE?.published;
            }

            const response = await dispatch(
                assignmentUpdate({
                    workspaceId: params?.id,
                    assignmentId: params?.assignmentId,
                    status,
                })
            );
            if (response?.payload?.data) {
                Toastify.success(
                    translator('LIBRARY.MESSAGE_ASSIGNMENT_UPDATE_SUCCESS', { status: details?.status == LESSON_STATUS_CODE.draft ? 'published' : 'unpublished' })
                );
            } else {
                Toastify.error(translator('LIBRARY.MESSAGE_ASSIGNMENT_UPDATE_FAIL'));
            }
        }
        setOpenConfirm(false);
    };
    const onPresentation = () => {
        history.push(`/workspace/${params?.id}/assignment/${params?.assignmentId}/preview`);
    };

    const onPublicAssignment = () => {
        if (isMissingInformation && details?.status == LESSON_STATUS_CODE.draft) {
            setOpenWarning(true);
        } else setOpenConfirm(true);
    };


    const onBack = () => {
        let url = '';
        if (isMissingInformation && details?.status == LESSON_STATUS_CODE.draft && isNew) return setOpenModalWarningCreateEmptyAssignment(true);
        if (folderId) url = `/workspace/${params?.id}/library/${folderId}?tab=${LIBRARY_TAB[0].value}`;
        else url = `/workspace/${params?.id}/library?tab=${LIBRARY_TAB[0].value}`;
        history.push(url);
    };

    const onCloseModalWarningCreateEmptyAssignment = (value) => {
        let url = '';
        setOpenModalWarningCreateEmptyAssignment(false);
        if (folderId) url = `/workspace/${params?.id}/library/${folderId}?tab=${LIBRARY_TAB[0].value}`;
        else url = `/workspace/${params?.id}/library?tab=${LIBRARY_TAB[0].value}`;
        if (!value) {
            libraryService.moveToTrashAssignment(params?.id, params?.assignmentId).then((res) => {
                libraryService.deleteForeverAssignment(params?.id, params?.assignmentId);
            });
        }
        history.push(url);
    };

    return (
        <>
            <div className="assigmentBuilderHeader">
                <div className="flex-none w-[349px]">
                    <ButtonBack onClick={onBack} />
                </div>
                <div className="assigmentBuilderStatus">
                    <span
                        className={`lessonBuilderStatus-label ${details?.status == LESSON_STATUS_CODE.published ? 'assigmentBuilderStatus-label--active' : ''
                            }`}
                    >
                        {translator(LESSON_STATUS[details?.status])}
                    </span>
                    <span className="assigmentBuilderStatus-text">{details?.title}</span>
                </div>
                <div className="assigmentBuilderHeader-action">
                    <span className="text-gray-500">
                        {loading == 'pending' ? (
                            <CircleSpin className="text-primary-500" />
                        ) : (
                            <span className={`${changed ? 'text-green-500' : ''}`}>
                                <CloudCheckIcon />
                            </span>
                        )}
                    </span>
                    <div className="assigmentBuilderHeader-line"></div>
                    <button
                        className={`assigmentBuilderHeader-btn ${viewType === VIEW_TYPE.WEB ? 'assigmentBuilderHeader-btn--active' : ''
                            } mr-1`}
                        onClick={() => dispatch(changeViewType(VIEW_TYPE.WEB))}
                    >
                        <DesktopIcon />
                    </button>
                    <button
                        className={`assigmentBuilderHeader-btn ${viewType === VIEW_TYPE.MOBILE ? 'assigmentBuilderHeader-btn--active' : ''
                            }`}
                        onClick={() => dispatch(changeViewType(VIEW_TYPE.MOBILE))}
                    >
                        <MobileIcon />
                    </button>
                    <div className="assigmentBuilderHeader-line"></div>
                    <ButtonOutline type="button" onClick={onPresentation} className="buttonOutline--isPrimary" disabled={!checkPermission('libraryContentAssignment', 'R')}>
                        <PresentationIcon />
                        <span>{translator('PREVIEW')}</span>
                    </ButtonOutline>
                    <ButtonPrimary
                        type="button"
                        className="whitespace-nowrap"
                        onClick={onPublicAssignment}
                        disabled={loading == 'pending' || !checkPermission('libraryContentAssignment', 'PL') || !checkPermission('libraryContentAssignment', 'RD')}
                    >
                        {details?.status == LESSON_STATUS_CODE.draft ? translator('PUBLISH') : translator('REVERT_TO_DRAFT')}
                    </ButtonPrimary>
                </div>
            </div>
            <ConfirmModal
                title={`${details?.status == LESSON_STATUS_CODE.draft ? 'LIBRARY.PUBLISH_ASSIGNMENT' : 'LIBRARY.CONFIRM_UNPUBLISH'}`}
                isOpen={openConfirm}
                onClose={onClose}
                textConfirm={`${details?.status == LESSON_STATUS_CODE.draft ? 'PUBLISH' : 'UNPUBLISH'}`}
                textCancel={`${details?.status == LESSON_STATUS_CODE.draft ? 'CANCEL' : 'DISCARD_CHANGES'}`}
            >
                <p className="text-sm">
                    {details?.status == LESSON_STATUS_CODE.draft
                        ? translator('LIBRARY.MESSAGE_PUBLISH_ASSIGNMENT')
                        : translator(
                            'LIBRARY.MESSAGE_REVERT_ASSIGNMENT'
                        )}
                </p>
            </ConfirmModal>
            <ConfirmModal
                title={`Information`}
                isOpen={openWarning}
                onClose={onCloseModalWarning}
                textConfirm={`Ok`}
                isHideBtnSecondary={true}
            >
                <p className="text-sm">{translator('LIBRARY.WARNING_PUBLISH_ASSIGNMENT')}</p>
            </ConfirmModal>
            <ConfirmModal
                title={`Information`}
                isOpen={openWarningCreateEmptyAssignment}
                onCloseX={() => {
                    setOpenModalWarningCreateEmptyAssignment(false);
                }}
                onClose={onCloseModalWarningCreateEmptyAssignment}
                textConfirm="Save"
                textCancel={translator('DISCARD')}
            >
                <p className="text-sm">{translator('LIBRARY.WARNING_CREATE_EMPTY_ASSIGNMENT')}</p>
            </ConfirmModal>
        </>
    );
};
