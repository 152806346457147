import confirm_save from 'assets/img/confirm_save.png';

interface ConfirmSaveProps {
    title: string;
    subTitle: string;
    btnCancel: string;
    btnSubmit: string;
    onClose?: (e?: any) => void;
    onFetchSubmit?: (e?: any) => void;
}
const ConfirmSave: React.FC<ConfirmSaveProps> = ({
    title,
    btnCancel,
    btnSubmit,
    onClose,
    onFetchSubmit,
    subTitle,
}) => {
    return (
        <>
            <div className="flex justify-center">
                <img className="w-24 h-24" src={confirm_save} alt="" />
            </div>
            <div className="flex flex-col items-center">
                <p className="text-ooolab_dark_1 text-ooolab_sm font-medium my-3">
                    {title}
                </p>
                <p className="text-ooolab_dark_1 text-ooolab_sm font-normal">
                    {subTitle}
                </p>
            </div>
            <div className="flex w-full justify-evenly my-4 ">
                <button
                    className="py-1 px-4 rounded-xl border-ooolab_dark_1 border text-ooolab_sm text-ooolab_dark_1 bg-ooolab_gray_11 focus:outline-none"
                    onClick={onClose}
                >
                    {btnCancel}
                </button>
                <button
                    className={`py-1 px-4 rounded-xl  text-ooolab_sm text-white focus:outline-none bg-blue-600 `}
                    onClick={onFetchSubmit}
                >
                    {btnSubmit}
                </button>
            </div>
        </>
    );
};

export default ConfirmSave;
