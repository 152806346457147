import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import { ButtonIcon } from '_shared';
import { DotsVerticalIcon, EyeIcon, PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import { LearningPathLessonDelete } from '../LearningPathLessonDelete';
import DialogComponent from "components/Dialog/DialogComponent";
import H5PPreview from 'components/H5PPreview';
import "./LessonActions.scss";
import { checkPermission } from 'utils/commonFun';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const LessonActions = ({ values, sectionId }) => {
    const { t } = useTranslation();
    const params: { id: string, learningPathId: string } = useParams();

    const [hasOpenDelete, setHasOpenDelete] = useState(false);
    const [hasPreviewLesson, setHasPreviewLesson] = useState(false);

    return (
        <>
            <div className="lessonActions">
                <Popover>
                    <Popover.Button className="focus:outline-0" onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.stopPropagation();
                    }}>
                        <ButtonIcon onClick={(event: React.MouseEvent<HTMLElement>) => {
                            //event.stopPropagation();
                        }}>
                            <DotsVerticalIcon className="w-5 h-5" />
                        </ButtonIcon>
                    </Popover.Button>
                    <Popover.Panel className="lessonActions-panel">
                        <button className="lessonActions-item disabled:cursor-not-allowed" disabled={!checkPermission('libraryContentLessonsOrScorm', 'U')} onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.preventDefault();
                            window.open(`/workspace/${params?.id}/lesson/${values?.library_content?.object_id}`, '_blank');
                        }}>
                            <PencilAltIcon className="w-5 mr-2" />
                            {t('EDIT')}
                        </button>
                        <button className="lessonActions-item lessonActions-item--border disabled:cursor-not-allowed" disabled={!checkPermission('learningPath', 'R')} onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.preventDefault();
                            setHasPreviewLesson(true);
                        }}>
                            <EyeIcon className="w-5 mr-2" />
                            {t('PREVIEW')}
                        </button>
                        <button className="lessonActions-item disabled:cursor-not-allowed" disabled={!checkPermission('learningPath', 'U')} onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.preventDefault();
                            setHasOpenDelete(true);
                        }}>
                            <TrashIcon className="w-5 mr-2" />
                            {t('REMOVE')}
                        </button>
                    </Popover.Panel>
                </Popover>
            </div>
            {hasOpenDelete ?
                <LearningPathLessonDelete
                    isOpen={hasOpenDelete}
                    onClose={() => setHasOpenDelete(false)}
                    values={values}
                    sectionId={sectionId}
                /> : null
            }
            {hasPreviewLesson && <DialogComponent
                isOpen={true}
                onCloseModal={() => setHasPreviewLesson(false)}
                hasNonTitle
                styles="max-w-[1200px] w-full min-h-[480px] my-0 rounded-lg !p-0 pb-4"
                isShowClose={false}
                child={
                    <H5PPreview values={{
                        ...values.library_content
                    }}
                        onClose={() => setHasPreviewLesson(false)}
                    />
                }
            />}
        </>
    )
}