import { CheckCircleIcon } from '@heroicons/react/solid';
import { Tooltip } from '_shared/components';
import ErrorIcon from 'assets/icon/ErrorIcon';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';


const Progress = ({ percentage = 0, error }) => {
    if (percentage === 100) {
        return <CheckCircleIcon className="text-green-500 w-8 h-8" />;
    }
    if (error) {
        return (
            <div className="relative w-8 h-8">
                <Tooltip styles="!whitespace-normal w-[380px] -!top-[150%]" info={error}>
                    <ErrorIcon />
                </Tooltip>
            </div>
        );
    }
    return (
        <div className="w-7 h-7">
            <CircularProgressbarWithChildren
                value={percentage}
                strokeWidth={8}
                styles={buildStyles({
                    strokeLinecap: 'butt',
                    pathColor: '#2576B9',
                    trailColor: '#C7C9D9',
                })}
            ></CircularProgressbarWithChildren>
        </div>
    );
};

export default Progress;
