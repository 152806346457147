import React, { useEffect, useState } from "react";
import { Disclosure, Transition } from '@headlessui/react';
import { PlusIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { LessonHeader } from "./components/LessonHeader/LessonHeader";
import { LessonContent } from "./components/LessonContent/LessonContent";
import { LearningPathUnit } from "types/LearningPath.type";
import { useAppDispatch, useAppSelector } from "hooks/hooks";

import "./LearningPathLesson.scss";
import { learningPathOrderLesson } from "store/features/learningPathSlice";
import { useParams } from "react-router-dom";
import { checkPermission } from "utils/commonFun";

type LearningPathLessProps = {
  values: LearningPathUnit,
  unitIndex: number,
  addLesson: (value: string) => void
}

export const LearningPathLesson = ({ values, unitIndex, addLesson }: LearningPathLessProps) => {
  const params: { id: string; learningPathId: string } = useParams();
  const { unitSelect } = useAppSelector(state => state.learningPath);
  const dispatch = useAppDispatch();
  const { t: translator } = useTranslation();
  const [draggedItem, setDraggedItem] = useState<any>();
  const [isDraggingSection, setIsDraggingSection] = useState(false);
  const [sections, setSections] = useState([]);

  const onDragStart = (e, index) => {
    setIsDraggingSection(true);
    setDraggedItem(sections[index]);
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };

  const onDragOver = (index) => {
    const draggedOverItem = sections?.[index];
    if (!draggedOverItem?.id || draggedItem?.id === draggedOverItem?.id) {
      return;
    }
    let items = sections.filter((item) => item?.id !== draggedItem?.id);
    items?.splice(index, 0, draggedItem);
    setSections(items);
  };

  const onDragEnd = () => {
    setIsDraggingSection(false);
    const index = sections?.findIndex((item) => item?.id === draggedItem?.id);
    const newSections = sections?.filter((item) => item?.id !== draggedItem?.id);
    newSections?.splice(index, 0, draggedItem);

    dispatch(learningPathOrderLesson({
      workspaceId: params?.id,
      id: params.learningPathId,
      sectionId: values?.id,
      sections: newSections
    }));
  };

  useEffect(() => {
    setSections(values?.section_contents ?? []);
  }, [values])

  return <>
    <Disclosure defaultOpen={values?.id == unitSelect?.id}>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full justify-between rounded-lg  p-5 text-left hover:bg-gray-50">
            <LessonHeader
              values={values}
              actived={values?.id == unitSelect?.id}
              unitIndex={unitIndex}
            />
          </Disclosure.Button>
          <Transition
            show={values?.id == unitSelect?.id}>
            <Disclosure.Panel className="pl-[60px] pr-6" static>
              <div className="learningPathLesson-title">{translator('LEARNING_PATH.LEARNING_PATH_DETAIL.LESSONS')}</div>
              <div>
                {sections?.map((item, index) => (
                  <div key={item.id}
                  >
                    <div
                      onDragStart={(e) => {
                        e.stopPropagation();
                        onDragStart(e, index);
                      }}
                      onDragEnd={(e) => {
                        e.stopPropagation();
                        onDragEnd();
                      }}
                      draggable
                      onDragOver={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onDragOver(index);
                      }}
                    >
                      <LessonContent values={item} sectionId={values.id} isDraggingSection={isDraggingSection} />
                    </div>
                  </div>
                ))}
              </div>
              <button className="learningPathLesson-add disabled:cursor-not-allowed" onClick={() => addLesson(values.id)} disabled={!checkPermission('learningPath', 'U')}>
                <span className="learningPathLesson-dot"></span>
                <PlusIcon className="w-5 h-5 mr-2" />
                {translator('LEARNING_PATH.LEARNING_PATH_DETAIL.ADD_LESSON')}
              </button>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  </>
}