import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TablePagination from './Pagination';

type FooterPageProps = {
    indexPage: string;
    page: number;
    per_page: number;
    total: number;
    callback: (page: number) => void;
    className?: string;
};

const Pagination: FC<FooterPageProps> = ({ indexPage, page, per_page, total, callback, className = '' }) => {
    const { t: translator } = useTranslation();

    return (
        <div className={`flex items-center justify-between border-t border-gray-300 w-full ${className}`}>
            <p className="text-ooolab_dark_2 text-ooolab_xs font-semibold leading-ooolab_24px hidden lg:block">
                {translator('SHOWING_1_50_OF_TOTAL_PAGE', {
                    fromTo: indexPage,
                    totalPage: total,
                })}
            </p>
            <div className="">
                <TablePagination
                    onClickPagination={(page) => callback?.(page)}
                    perPage={per_page}
                    total={total}
                    forcePage={page - 1}
                />
            </div>
        </div>
    );
};

export default Pagination;
