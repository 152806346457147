import React from "react";
type AssessmentResultProps = {
  color: string;
  label: string;
  value: string;
}

const AssessmentResult: React.FC<AssessmentResultProps> = ({color, label, value}: AssessmentResultProps) => {
  console.log(value)
  if (!!!value) return null;
  return (
    <>
      <div className="">
        <label className={`${color} text-xs font-publicSans`}>{label}</label>
        <div className="bg-ooola_EFF0FA text-xs text-[#2F3047] font-semibold mt-2 px-2 py-[5px] rounded-lg">
          {value}
        </div>
      </div>
    </>
  )
}

export default AssessmentResult;