import * as React from 'react';
import { genClassNames } from 'utils/handleString';

interface BorderWithTitleProps {
    title: string;
    children: React.ReactNode;
    cls?: string;
}

const BorderWithTitle: React.FC<BorderWithTitleProps> = ({
    title,
    children,
    cls
}) => (
    <div className={genClassNames({
        'relative': true,
        [cls || ""]: true
    })}>
        <div className='border border-ooolab_gray_6 rounded-header_menu p-3'>{children}</div>
        <div className='whitespace-nowrap px-1 bg-white w-min absolute top-ooolab_border_title left-3 right-0 text-ooolab_dark_1 text-ooolab_10px h-ooolab_h_4 font-semibold'>{title}</div>
    </div>
);

export default BorderWithTitle;
