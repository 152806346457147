import './DetailsFilePopup.scss';
import { LIBRARY_ICONS } from 'components/Library/LibraryContants';
import { useTranslation } from 'react-i18next';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import NO_CONTENT_IMG from 'assets/img/empty-state/library.png';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import BlockContent from './BlockContent';

const SectionList = ({ sectionName = undefined, contents, translator }) => {
    return (
        <div className="sectionList">
            {sectionName && (
                <div className="flex justify-between">
                    <div className="sectionList-title ">{sectionName}</div>
                    <div className="sectionList-file">{contents?.length || 0} Files</div>
                </div>
            )}
            <div>
                {contents?.map((content) => {
                    return (
                        <div key={content?.id} className="sectionList-item">
                            <img
                                alt="libary-icon"
                                className="icon"
                                src={
                                    content?.library_content?.object_type
                                        ? LIBRARY_ICONS(translator)?.find(
                                              (item) => item?.value === content?.library_content?.object_type
                                          )?.icon
                                        : LIBRARY_ICONS(translator)[0].icon
                                }
                            />
                            <div className="content-name">{content?.library_content?.name}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default function Materials({ lesson, isLoading }) {
    const { t: translator } = useTranslation();
    if (isLoading) {
        return <CircleSpin className="taglist-loading" />;
    }
    return (
        <div>
            <BlockContent title={'Overview'}>
                <div>
                    <div className="lessonGoal">
                        <p className="label">{translator('LIBRARY.LESSON_GOAL')}</p>
                        <p className="value">{lesson?.lesson_goal || '_'}</p>
                    </div>
                </div>
            </BlockContent>

            <BlockContent title={translator('LIBRARY.LEARNING_MATERIALS')}>
                {!lesson?.sections?.learning_material?.length && <NoContentWithImage img={NO_CONTENT_IMG} />}
                {lesson?.sections?.learning_material?.map((section) => {
                    return (
                        <SectionList
                            key={section?.id}
                            sectionName={section?.title}
                            contents={section?.section_contents}
                            translator={translator}
                        />
                    );
                })}
            </BlockContent>

            <BlockContent
                title={translator('LIBRARY.ASSIGNMENTS')}
                number={lesson?.sections?.assignment?.[0]?.section_contents?.length || 0}
            >
                {!lesson?.sections?.assignment?.[0]?.section_contents?.length && <NoContentWithImage img={NO_CONTENT_IMG} />}
                {lesson?.sections?.assignment?.[0]?.section_contents?.length > 0 && (
                    <SectionList contents={lesson?.sections?.assignment?.[0]?.section_contents} translator={translator} />
                )}
            </BlockContent>

            <BlockContent
                title={translator('LIBRARY.SUPPLEMENTARY')}
                number={lesson?.sections?.supplementary_material?.[0]?.section_contents?.length || 0}
            >
                {!lesson?.sections?.supplementary_material?.[0]?.section_contents?.length && (
                    <NoContentWithImage img={NO_CONTENT_IMG} />
                )}
                {lesson?.sections?.supplementary_material?.[0]?.section_contents?.length > 0 && (
                    <SectionList contents={lesson?.sections?.supplementary_material?.[0]?.section_contents} translator={translator} />
                )}
            </BlockContent>
        </div>
    );
}
