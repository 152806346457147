import React, { useEffect, useMemo, useState } from "react";
import SelectBox from "components/V2/SelectBox";
import courseService from "services/course.service";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const GradebookListFilter = ({onHandleChangeFilter}: {onHandleChangeFilter: (value) => void}) => {
    const params: { id: string, courseId: string } = useParams();
    const [learners, setLearners] = useState([]);
    const [paginationLearners, setPaginationLearners] = useState<any>();
    const [learnersGroup, setLearnersGroup] = useState([]);
    const [paginationLearnersGroup, setPaginationLearnersGroup] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [learnerSelected, setLearnerSelected] = useState<any>();
    const [learnerGroupSelected, setLearnerGroupSelected] = useState<any>();
    const {t} = useTranslation();

    const handleLoadLearnerOptions = async (page: number, searchText?: string) => {
        let payload: any = {
            page: page ?? 1,
            per_page: 10,
            order: 'desc',
            q: searchText || paginationLearners?.q || null,
            enrollment_as: 'individual'
        };
        try {
            const { items, total } = await fetchPeopleOfCourse(payload);
            const newOptions = items?.map((el: any) => {
                return {
                    ...el,
                    id: el?.learners[0]?.id,
                    label: el?.learners[0]?.display_name,
                    name: el?.learners[0]?.display_name,
                    value: el?.learners[0]?.id,
                    avatar_url: el?.learners[0]?.avatar_url
                }
            });
            if (page === 1) setLearners(newOptions);
            else setLearners([...learners, ...newOptions]);
            setPaginationLearners({ ...paginationLearners, total, page });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleLoadLearnerGroupOptions = async (page: number, searchText?: string) => {
        let payload: any = {
            page: page ?? 1,
            per_page: 10,
            order: 'desc',
            q: searchText || paginationLearnersGroup?.q || null,
            enrollment_as: 'learning_group',
        };
        try {
            const { items, total } = await fetchPeopleOfCourse(payload);
            const newOptions = items?.map((el: any) => {
                return {
                    ...el,
                    id: el?.learning_group?.id,
                    label: el?.learning_group?.name,
                    name: el?.learning_group?.name,
                    value: el?.learning_group?.id,
                    avatar_url: el?.learning_group?.avatar_url
                }
            });
            if (page === 1) setLearnersGroup(newOptions);
            else setLearnersGroup([...learners, ...newOptions]);
            setPaginationLearnersGroup({ ...paginationLearners, total, page });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchPeopleOfCourse = async (bodyParams?: any) => {
        try {
            setLoading(true);
            const res = await courseService.getEnrollments(params?.id, params.courseId, bodyParams);
            setLoading(false);
            return {
                items: res?.data?.items || [],
                total: res?.data?.total || 0,
            };
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
        }
    };
    const handleChangeFilter = useMemo(() => {
        return {
            learners: learnerSelected?.length > 0 ? JSON.stringify(learnerSelected?.map(item => item.id)) : null,
            learning_groups: learnerGroupSelected?.length > 0 ? JSON.stringify(learnerGroupSelected?.map(item => item.id)) : null,
        }
    }, [learnerSelected, learnerGroupSelected]);

    useEffect(() => {
        onHandleChangeFilter(handleChangeFilter)
    }, [handleChangeFilter]);

    useEffect(() => {
        handleLoadLearnerOptions(1);
    }, []);

    return (<>
        <SelectBox
            label={t("LEARNER")}
            onSearch={(searchText) => handleLoadLearnerOptions(1, searchText)}
            onSelect={setLearnerSelected}
            data={learners}
            loading={loading}
            selectedItems={learnerSelected}
            total={paginationLearners?.total ?? 0}
            showmore={() => handleLoadLearnerOptions(paginationLearners?.page + 1)}
            siz="M"
            styles={{
                container: 'min-w-[154px]'
            }}
        />
        <SelectBox
            siz="M"
            label={t("LEARNING_GROUP.LEARNING_GROUP")}
            onSearch={(searchText) => handleLoadLearnerGroupOptions(1, searchText)}
            onSelect={setLearnerGroupSelected}
            data={learnersGroup}
            loading={loading}
            selectedItems={learnerGroupSelected}
            total={paginationLearnersGroup?.total ?? 0}
            showmore={() => handleLoadLearnerGroupOptions(paginationLearnersGroup?.page + 1)}
            styles={{
                container: 'min-w-[154px]'
            }}
        />
    </>
    )
}