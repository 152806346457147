import React from "react";
import EmptyStateLearningGroup from 'assets/img/empty-state/learning-path.png'
import { ButtonPrimary } from "_shared";
import { PlusIcon } from "@heroicons/react/outline";
import { checkPermission } from "utils/commonFun";
import { useTranslation } from "react-i18next";

export const EmptySyllabus = ({ onAction }: { onAction?: () => void }) => {
    const {t} = useTranslation();
    return (
        <div className="text-center">
            <img src={EmptyStateLearningGroup} className="mx-auto" />
            <p className="text-center text-xl text-gray-800 font-semibold mt-4">{t("COURSES.LOOK_LIKE_THERE_NOTHING_HERE")}</p>
            <p className="text-center text-base text-gray-700 font-normal mt-3">{t("COURSES.GET_STARTED_ON_CREATING")}</p>
            {checkPermission('courseAcademic', 'U') && <ButtonPrimary type="button" onClick={onAction} className="mx-auto mt-3">
                <span className="flex items-center text-sm"><PlusIcon className="w-4 h-4 mr-1.5" />{t("COURSES.ADD_LEARNING_PATH")}</span>
            </ButtonPrimary>}

        </div>
    )
}