import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
const AssignmentExpand = ({ assignmentId, total_graded, total_assigned }) => {
    const params: { id: string; classId: string } = useParams();
    const { t: translator } = useTranslation();
    return (
        <div className="px-10 py-5 flex justify-between items-center border-t">
            <div className="flex items-center">

                <div className="flex flex-col pr-24 pl-8 border-r">
                    <p className="mb-3 text-ooolab_dark_2 text-base">
                        {translator('ASSIGNMENT.ASSIGNED')}
                    </p>
                    <p className="text-3xl">{total_assigned}</p>
                </div>
                <div className="flex flex-col pr-24 pl-8">
                    <p className="mb-3 text-ooolab_dark_2 text-base">
                        {translator('ASSIGNMENT.GRADED')}
                    </p>
                    <p className="text-3xl">{total_graded}</p>
                </div>
            </div>
            <div>
                <a className="primary-button ml-2" href={"/workspace/" + params.id + "/assignment/" + assignmentId + "/class/" + params.classId + "/submission"}>
                    {translator('ASSIGNMENT.VIEW_GRADES')}
                </a>
            </div>
        </div>
    );
};

export default AssignmentExpand;
