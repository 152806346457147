import React, { useEffect, useState } from "react";
import SearchBox from 'components/V2/SearchBox';
import Pagination from "components/V2/Pagination";
import GradebookAssignmentTable from "./GradebookAssignmentTable";
import "./GradebookAssignment.scss";
import courseService from "services/course.service";
import { useLocation, useParams } from "react-router-dom";
import { checkPermission, getIndexPage } from "utils/commonFun";
import { CircleSpin } from "components/UiComponents/CircleSpin";

export const GradebookAssignment = ({userData}) => {
    const params: {id: string, userId: string} = useParams();
    const search = useLocation()?.search;
    const courseId = new URLSearchParams(search).get('courseId');
    const [loading, setLoading] = useState(false);
    const [indexPage, setIndexPage] = useState('0-0');
    const [order, setOrder] = useState('desc');
    const [searchText, setSearchText] = useState(null);

    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order,
        sort_by: 'end_time',
        total: 0,
        name: ''
    });
    const [data, setData] = useState();

    const handlePagination = (page: number) => {
        getCourseAssignments(page);
    };

    const getCourseAssignments = async (page=1) => {
        setLoading(true);
        const payload = {
            ...pagination,
            learner_ids: params?.userId,
            page,
            name: searchText,
            order
        }
        if (!payload?.learner_ids) delete payload?.learner_ids;
        if (!payload?.learning_group_ids) delete payload?.learning_group_ids;
        if (!payload?.name) delete payload?.name;
        delete payload?.total;
        const response = await courseService.getCourseAssignments(params?.id, courseId, payload);
        const { items, total } = response.data;
        setData(items);
        setPagination({ ...pagination, ...payload, total });
        setIndexPage(getIndexPage(payload?.page, pagination?.per_page, items?.length));
        setLoading(false);
    }

    useEffect(() => {
        if(checkPermission('course','R')){
            getCourseAssignments();
        }
    }, [order, searchText]);

    return <>
        <div className="LearnerGradebookAssignment">
            <div className="LearnerGradebookAssignment-header">
                <SearchBox className="filterLearningPath-searchInput" onSubmit={(value) => setSearchText(value?.trim())} />
            </div>
            <div className="LearnerGradebookAssignment-content overflow-auto">
                {loading ? <div className="flex items-center justify-center h-full min-h-[300px]"><CircleSpin color="text-primary-500" /></div> :
                <GradebookAssignmentTable 
                    data={data} 
                    refreshData={() => getCourseAssignments(1)} 
                    order={order}
                    setOrder={setOrder}
                    userData={userData}
                />}
            </div>
            <div className="LearnerGradebookAssignment-pagination">
                <Pagination
                    total={pagination.total}
                    per_page={pagination.per_page}
                    callback={(e) => handlePagination(e)}
                    indexPage={indexPage}
                    page={pagination?.page}
                />
            </div>
        </div>
    </>
}