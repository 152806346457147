export enum LibraryEnum  {
    h5p_content = 'H5P',
    lesson = 'Lesson',
    file = 'File',
    assignment= 'Assignment',
    learning_path = 'Learning Path',
    course = 'Course'

}


export  enum contentTypeEnum {
    image = 'image',
    video = 'video',
    document = 'document',
    audio = 'audio',
    lesson = 'lesson',
    h5p = 'h5p',
    folder = 'folder',
    pdf = 'pdf',
    presentation = 'presentation',
    spreadsheet = 'spreadsheet',
    file = 'file',
    assignment = 'assignment',
    submission = 'submission'
}