export const LIST_REWARDS = {
    REQ_GET_REWARDS: 'REQ_GET_REWARDS ',
    REQ_GET_REWARDS_FINISH: 'REQ_GET_REWARDS _FINISH',
    REQ_GET_REWARDS_FAILED: 'REQ_GET_REWARDS _FAILED',
};
export const LIST_REWARDS_FUTURED = {
    LIST_REWARDS_FUTURED: 'LIST_REWARDS_FUTURED ',
    LIST_REWARDS_FUTURED_FINISH: 'LIST_REWARDS_FUTURED _FINISH',
    LIST_REWARDS_FUTURED_FAILED: 'LIST_REWARDS_FUTURED _FAILED',
};
export const LIST_REWARDS_HISTORY = {
    REQ_GET_REWARDS: 'REQ_GET_REWARDS_HISTORY',
    REQ_GET_REWARDS_FINISH: 'REQ_GET_REWARDS _FINISH_HISTORY',
    REQ_GET_REWARDS_FAILED: 'REQ_GET_REWARDS _FAILED_HISTORY',
};