import * as React from 'react';
import Admin from 'components/Workspace/Admin';
import WorkspaceAdminProvider from 'contexts/Workspace/WorkspaceAdminProvider';

const AdminPage = () => {
    return <WorkspaceAdminProvider>
        <Admin></Admin>
    </WorkspaceAdminProvider>;
};

export default AdminPage;
