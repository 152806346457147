import React from 'react'

export default function EcommerceIcon({className = ''}) {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6520_22827)">
    <path d="M3.33336 5.34494L0.630859 2.64328L1.81003 1.46411L4.51169 4.16661H17.2134C17.3433 4.1666 17.4713 4.19696 17.5874 4.25525C17.7035 4.31354 17.8043 4.39817 17.8819 4.50236C17.9594 4.60655 18.0116 4.72743 18.0341 4.85535C18.0566 4.98327 18.049 5.11469 18.0117 5.23911L16.0117 11.9058C15.9602 12.0775 15.8548 12.2281 15.711 12.3351C15.5671 12.4422 15.3926 12.5 15.2134 12.4999H5.00003V14.1666H14.1667V15.8333H4.16669C3.94568 15.8333 3.73372 15.7455 3.57744 15.5892C3.42116 15.4329 3.33336 15.221 3.33336 14.9999V5.34494ZM5.00003 5.83328V10.8333H14.5934L16.0934 5.83328H5.00003ZM4.58336 19.1666C4.25184 19.1666 3.9339 19.0349 3.69948 18.8005C3.46506 18.5661 3.33336 18.2481 3.33336 17.9166C3.33336 17.5851 3.46506 17.2671 3.69948 17.0327C3.9339 16.7983 4.25184 16.6666 4.58336 16.6666C4.91488 16.6666 5.23282 16.7983 5.46724 17.0327C5.70166 17.2671 5.83336 17.5851 5.83336 17.9166C5.83336 18.2481 5.70166 18.5661 5.46724 18.8005C5.23282 19.0349 4.91488 19.1666 4.58336 19.1666ZM14.5834 19.1666C14.2518 19.1666 13.9339 19.0349 13.6995 18.8005C13.4651 18.5661 13.3334 18.2481 13.3334 17.9166C13.3334 17.5851 13.4651 17.2671 13.6995 17.0327C13.9339 16.7983 14.2518 16.6666 14.5834 16.6666C14.9149 16.6666 15.2328 16.7983 15.4672 17.0327C15.7017 17.2671 15.8334 17.5851 15.8334 17.9166C15.8334 18.2481 15.7017 18.5661 15.4672 18.8005C15.2328 19.0349 14.9149 19.1666 14.5834 19.1666Z" fill="currentColor"/>
    </g>
    <defs>
    <clipPath id="clip0_6520_22827">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}
