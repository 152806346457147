import React from 'react';
import ManagementMasterPage from 'pages/RouteMasterPage';
import ManagePermissionAdmin from 'pages/Management/ManagePermissionAdmin';
import UserHistoryList from 'components/Management/User/ImportUser/UserHistoryList';

const UserImportHistoryPage = () => {
    return (
        <ManagePermissionAdmin>
            <UserHistoryList>
                <ManagementMasterPage />
            </UserHistoryList>
        </ManagePermissionAdmin>
    );
};

export default UserImportHistoryPage;
