import React, { FC, useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from 'contexts/User/UserContext';
import { useTranslation } from 'react-i18next';
import './RolesPermissionMember.scss'
import workspaceService from 'services/workspace.service';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import EmptyIcon from 'assets/img/empty-state/emptyMember.png';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import Pagination from 'components/V2/Pagination';
import { getIndexPage } from 'utils/commonFun';
import dayjs from 'dayjs';
import { USER_STATUS, USER_STATUS_LABEL } from 'components/Management/User/constant';


interface RolesPermissionMembeProps {
    data: any;
}

const RolesPermissionMember: FC<RolesPermissionMembeProps> = ({ data }) => {
    const params: any = useParams();
    const { t } = useTranslation();
    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`ROLES_PERMISSIONS.${str}`, opt));
    const [indexPage, setIndexPage] = useState('0-0');
    const [pagination, setPagination] = useState({
        page: 1,
        total: 0,
        per_page: 20,
        sort_by: 'display_name',
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [dataList, setDataList] = useState<any[]>([]);
    const {
        userState: { result },
    } = useContext(UserContext);



    const getList = (page?: number) => {
        setLoading(true);
        const paramsMember = {
            page: page,
            per_page: 20,
            order: 'desc',
            user_role_name: data?.name,
        };
        workspaceService
            .getWorkspaceMembersService(params.id, paramsMember)
            .then((res: any) => {
                if (res) {
                    const { items, page, per_page, sort_by, total } = res?.data;
                    setDataList(items);
                    setPagination({ page, per_page, sort_by, total });
                    setIndexPage(getIndexPage(page, per_page, items?.length));

                }
            })
            .finally(() => setLoading(false))
    };

    useEffect(() => {
        getList(1);
    }, [])

    const columns = React.useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('NAME')} />,
                Cell: ({ row }) => {
                    return <RenderTableCell value={row?.values?.display_name || '-'} />
                },
                accessor: 'display_name',
                width: 200,
            },
            {
                Header: () => <RenderTableHeader value={translator('EMAIL')} />,
                Cell: ({ row }) => {
                    return <RenderTableCell value={row?.values?.email || '-'} />;
                },
                accessor: 'email',
                width: 240,
            },
            {
                Header: () => <RenderTableHeader value={translator('ADDED_DATE')} />,
                Cell: ({ row }) => {
                    const status = row?.original?.membership?.created_at;
                    return (
                        <RenderTableCell
                            value={
                                row?.original?.membership?.created_at
                                    ? dayjs(row?.original?.membership?.created_at).tz(result?.time_zone).format('DD/MM/YYYY')
                                    : ''
                            }
                        />
                    );
                },
                accessor: 'add_date',
                width: 80,
            },
            {
                Header: () => <RenderTableHeader value={translator('STATUS')} />,
                Cell: ({ row }) => {
                    const status = row?.original?.membership?.status;
                    return (
                        <div
                            className={`capitalize font-normal text-xs text-gray-500 border rounded px-2 py-1 w-fit
                        ${status === USER_STATUS.ACTIVE ? 'text-green-500 bg-green-50 border-green-300' : ''}
                        ${status === USER_STATUS.DEACTIVATE ? 'text-red-500 bg-red-50 border-red-300' : ''}
                        ${status === USER_STATUS.INVITE ? 'text-orange-500 bg-orange-50 border-orange-300' : ''}
                    `}
                        >
                            {t(`USER.STATUSES.${USER_STATUS_LABEL[status]}`)}
                        </div>
                    );
                },
                accessor: 'status',
                width: 80,
            },

        ],
        [dataList]
    );
    const handlePagination = (page: number) => {
        getList(page);
    };
    return (
        <div className='membersTab'>
            <h1 className='text-xl font-semibold'>{translator("MEMBERS_TOTAL", false, {
                number: pagination?.total,
            })}</h1>
            <div className={`pt-4`}>
                <div className="custom-scrollbar h-[calc(100vh-290px)] overflow-y-auto relative">
                    {loading ? (
                        <div className=" h-[calc(100vh-290px)] flex items-center justify-center mt-4">
                            <CircleSpin color="text-primary-500" />
                        </div>
                    ) : (
                        <Table data={dataList} columns={columns} emptyIcon={EmptyIcon} emptyContent={translator('NO_MEMBERS')} />
                    )}
                </div>
                <div className='noPadding'>
                    <Pagination
                        indexPage={indexPage}
                        page={pagination?.page}
                        per_page={pagination?.per_page}
                        total={pagination.total}
                        callback={handlePagination}
                    />
                </div>
            </div>

        </div>
    );
};

export default RolesPermissionMember;

