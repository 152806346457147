import React from 'react';
import ManagementMasterPage from 'pages/RouteMasterPage';
import RewardList from 'components/Management/Reward';
import ManagePermissionWrapper from '../ManagePermissionWrapper';

const RewardPage = () => {
    return (
        <ManagePermissionWrapper>
            <RewardList>
                <ManagementMasterPage  />
            </RewardList>
        </ManagePermissionWrapper>
    );
};

export default RewardPage;
