import { NoContentWithImage } from "components/NoContent/NoContent"
import InfiniteScroll from "react-infinite-scroll-component"
import {getTypePerson} from '../utils'
import { ButtonOutline } from "_shared"
import { useTranslation } from "react-i18next"

export default function ModalViewDetail({detail: {items}, pagination, handleNextPage, onClose}) {
  const {t: translator} = useTranslation();
  if (items.length  === 0) return (
    <NoContentWithImage content={translator("GROUP.NO_PEOPLE")} />
  )
  return (
    <div className='rounded-sm p-6 pb-0'>
      {/* @ts-ignore */}
      <InfiniteScroll
        dataLength={items.length}
        next={handleNextPage}
        hasMore={items.length != pagination.total}
        loader={items.length < pagination.total && <p className='w-full text-center'> Loading...</p>}
        height={200}
        className="custom-scrollbar pr-1"
      >
        {items.map((item:any, index) => {
          return (
            <div key={index} className="my-3 flex justify-between text-xs font-normal">
                <div className='flex'>
                    {item?.avatar_url && <img className='rounded-full w-5 h-5 mr-2 ' src={item.avatar_url} alt="" />}
                    <div className='w-[220px] max-w-[220px] truncate text-ellipsis '> 
                      {item.membership && <span className="min-w-[60px]"> {(`(ID:${item.id}) `)} </span>}
                       {(item.name || item.display_name ) + 
                       (item?.nick_name ? ` / ${item?.nick_name}` : '') +
                       ((item.status === 'deactivate' || item?.membership?.status === 'deactivate') ? ` (${translator("DEACTIVATED")})` : '')}
                    </div>
                </div>
                <div className=' capitalize'>
                  {getTypePerson((item?.membership?.type === 'parent' || item?.membership?.type === 'student' 
                    ? item?.membership?.type
                    : item?.membership?.user_role?.name
                  ) || null, translator)}
                </div>
            </div>
          )
        })}
      </InfiniteScroll>
      <div className="w-full flex justify-center">
        <ButtonOutline
            type='button'
            onClick={onClose}
            >
              {translator('CLOSE')}
        </ButtonOutline>
      </div>
    </div>
  )
}


