import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { Row, Column } from 'react-table';
import Table, { RenderTableCell, RenderTableHeader } from 'components/V2/Table/TableCustom';
import { UserContext } from 'contexts/User/UserContext';
import empty_icon from 'assets/img/empty-account-icon.png';
import ActionsButton from 'components/ActionsButton';
import { EyeIcon, MinusSmIcon, PlusSmIcon } from '@heroicons/react/outline';
import { checkPermission } from 'utils/commonFun';
import { accountCreditType } from 'constant/workspace.const';

export const TableCreditAccount = ({ workspaceId, userId, dataTable, translator, onViewDetail, onDeposit, onWithdraw }) => {
    const history = useHistory();

    const {
        userState: { result },
    } = useContext(UserContext);

    const initialState = {
        hiddenColumns: [],
    };

    const columns = React.useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('ACCOUNT_NUMBER')} />,
                Cell: ({ row }: any) => {
                    return <RenderTableCell value={row.values?.id} />;
                },
                accessor: 'id',
                width: 100,
            },
            {
                Header: () => <RenderTableHeader value={translator('ACCOUNT_NAME')} />,
                Cell: ({ row }: any) => {
                    return <RenderTableCell value={row.values?.name} />;
                },
                accessor: 'name',
                width: 170,
            },
            {
                Header: () => <RenderTableHeader value={translator('ACCOUNT_TYPE')} />,
                Cell: ({ row, column }: { row: any; column: Column }) => {
                    return (
                        <RenderTableCell
                            value={
                                accountCreditType?.[row.values?.account_type]?.key
                                    ? translator(accountCreditType?.[row.values?.account_type]?.key)
                                    : ''
                            }
                        />
                    );
                },
                accessor: 'account_type',
                width: 100,
            },
            {
                Header: () => <RenderTableHeader value={translator('CREDIT_BALANCE')} />,
                Cell: ({ row }: any) => {
                    return <RenderTableCell value={`${row.values?.credit_balance || 0}`} />;
                },
                accessor: 'credit_balance',
                width: 100,
            },
            {
                Header: () => <RenderTableHeader value={translator('LAST_UPDATED')} />,
                Cell: ({ row }) => {
                    return (
                        <RenderTableCell
                            value={
                                row.values?.updated_at
                                    ? dayjs(row.values?.updated_at)
                                          .tz(result?.time_zone || 'UTC')
                                          .format('DD/MM/YYYY')
                                    : ''
                            }
                        />
                    );
                },
                accessor: 'updated_at',
                width: 100,
            },
            {
                Header: () => null,
                Cell: ({ row }) => {
                    return (
                        <div className="h-full flex items-center">
                            <ActionsButton
                                menus={[
                                    {
                                        label: translator('VIEW_DETAILS'),
                                        icon: <EyeIcon className="w-5 h-5 text-gray-800" />,
                                        isDisabled: !checkPermission('userDetailsCreditAccounts', 'R'),
                                        action: () => {
                                            const creditId = row.values?.id;
                                            onViewDetail(row.original);
                                            history.push(
                                                `/workspace/${workspaceId}/management/users/${userId}?tab=credit-accounts&creditId=${creditId}`
                                            );
                                        },
                                        hide: false,
                                    },
                                    {
                                        label: translator('DEPOSIT'),
                                        icon: <PlusSmIcon className="w-5 h-5 text-gray-800" />,
                                        isDisabled: !checkPermission('userDetailsCreditAccounts', 'U'),
                                        hide: false,
                                        action: () => onDeposit({ ...row.original, type: 'deposit' }),
                                    },
                                    {
                                        label: translator('WITHDRAW'),
                                        icon: <MinusSmIcon className="w-5 h-5 text-gray-800" />,
                                        isDisabled: !checkPermission('userDetailsCreditAccounts', 'U') || row.values?.credit_balance <= 0,
                                        hide: false,
                                        action: () => onWithdraw({ ...row.original, type: 'withdraw' }),
                                    },
                                ]}
                                position={row?.index > dataTable?.length / 2 ? 'bottom-10' : ''}
                            />
                        </div>
                    );
                },
                accessor: 'action',
                width: 30,
            },
        ],
        [dataTable]
    );
    return (
        <>
            <Table
                isBorderFirstColum={false}
                data={dataTable}
                columns={columns}
                initialState={initialState}
                emptyContent={translator('NO_DATA')}
                emptyIcon={empty_icon}
            />
        </>
    );
};
