import { roundNumber } from 'constant/util.const';
import React from 'react';

type ProgressBarProps = {
    hideLabel?: boolean;
    hasPoint?: boolean;
    percentage: number;
    height?: string;
    processBg?: string;
    lineBg?: string;
};

const ProgressBar: React.FC<ProgressBarProps> = ({
    percentage,
    hideLabel = false,
    hasPoint = false,
    height = 'h-2',
    processBg = 'bg-[#024FEE]',
    lineBg,
}: ProgressBarProps) => {
    if (!lineBg) {
        lineBg = hideLabel ? 'bg-[#CCE8FF]' : 'bg-[#F2F2F5]';
    }
    return (
        <>
            <div className="flex items-center space-x-2">
                {!hideLabel && <label className="flex-none text-xs text-neutral-1 font-medium">Progress</label>}
                <div className="flex-1 w-full">
                    <div className={`relative rounded-lg ${height} ${lineBg}`}>
                        {hasPoint && (
                            <div
                                className={`absolute h-2 w-[12px] rounded-lg bg-white z-10 ${height}`}
                                style={{ left: `calc(${percentage}% - 11px)` }}
                            ></div>
                        )}
                        <div
                            className={`absolute rounded-lg  z-9  ${processBg} ${height}`}
                            style={{ width: `${Math.round(percentage)}%` }}
                        ></div>
                    </div>
                </div>
                <span className="text-xs font-medium">{roundNumber(percentage, 1)}%</span>
            </div>
        </>
    );
};

export default ProgressBar;
