import { LIST_REWARDS, LIST_REWARDS_FUTURED, LIST_REWARDS_HISTORY } from 'actions/rewards.action';
import { RewardsAction, RewardsState } from 'types/Rewards.type';

export function getRewardsReducer(state: RewardsState, action: RewardsAction) {
    switch (action.type) {
        case LIST_REWARDS.REQ_GET_REWARDS:
            return {
                ...state,
                isLoading: true,
            };
        case LIST_REWARDS.REQ_GET_REWARDS_FINISH:
            return {
                ...state,
                listRewards: action.listRewards,
                isLoading: false,
            };
        case LIST_REWARDS.REQ_GET_REWARDS_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case LIST_REWARDS_FUTURED.LIST_REWARDS_FUTURED:
            return {
                ...state,
                isLoading: true,
            };
        case LIST_REWARDS_FUTURED.LIST_REWARDS_FUTURED_FINISH:
            return {
                ...state,
                listRewardsFutured: action.listRewardsFutured,
                isLoading: false,
            };
        case LIST_REWARDS_FUTURED.LIST_REWARDS_FUTURED_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case LIST_REWARDS_HISTORY.REQ_GET_REWARDS:
            return {
                ...state,
                isLoading: true,
            };
        case LIST_REWARDS_HISTORY.REQ_GET_REWARDS_FINISH:
            return {
                ...state,
                listRewardsHistory: action.listRewardsHistory,
                isLoading: false,
            };
        case LIST_REWARDS_HISTORY.REQ_GET_REWARDS_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}
