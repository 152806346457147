export const formatPeople = (data: any) => {
  return (
      <div className="flex items-center text-dark-300">
          <div className="flex-none w-[48px] items-center">
              <p className="truncate">ID: {data?.id}</p>
          </div>
          <div className="pl-2 w-full flex items-center">
              <p className="truncate">{data?.label}</p>
          </div>
      </div>
  );
};

export const formatPeopleAvatar = (data: any) => {
    return (
        <div className="flex items-center text-dark-300">
            <div className="flex-none w-5">
                <img src={data?.avatar_url || data?.instructor?.avatar_url} className="w-5 h-5 rounded-full border border-white overflow-hidden" />
            </div>
            <div className="pl-2 w-full">
                <p className="truncate">{data?.label}</p>
            </div>
        </div>
    );
  };

export const formatOptionsInstructor = (data: any) => {
    return (
        <div className="flex items-center text-dark-300">
            {data?.type === 'learning_group' ? (
                <div className="min-w-[70px]"> ID: {data?.id}</div>
            ) : (
                <img className="w-5 h-5 rounded-full border border-white" src={data?.avatar} />
            )}
            <div className="truncate text-ellipsis">{data?.label}</div>
        </div>
    );
};

export const formatLesson= (data: any) => {
    return (
        <div className="flex gap-1 items-center text-dark-300">
            <div className="truncate text-ellipsis">{data?.label}</div>
        </div>
    );
};

export const formatAvatarLearnerNameEmail = (data: any) => {
    return (
        <div className="flex items-center">
            <div className="flex-none w-5">
                <img src={data?.avatar_url} alt="" className="w-5 h-5 rounded-full border border-white overflow-hidden" />
            </div>
            <div className="pl-1 w-full">
                <p className="truncate">{data?.label + (data?.email ? ` (${data?.email})` : '')}</p>
            </div>
        </div>
    );
};