import { useEffect, useState } from 'react';

import { CircleInformationIcon } from 'assets/icon/CircleInfomationIcon';
import { MessageIcon } from 'assets/icon/MessageIcon';
import AppReview from 'components/UiComponents/AppReivew';
import Comments from 'components/UiComponents/Comments';
import ProgressBar from 'components/UiComponents/ProgressBar';
import ProgressPointBar from 'components/UiComponents/ProgressPointBar';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classService from 'services/class.service';
import EvaluateTeacher from './EvaluateTeacher';
import TooltipInfo from 'components/UiComponents/TooltipInfo';
import GradebookTestScores from './GradebookTestScores';

interface GradeReportProps {
    reportId: number;
    studentName: string;
    dataVersion?: any;
    classOfReport?: string;
}
const GradeReport: React.FC<GradeReportProps> = ({ reportId, studentName, dataVersion = null, classOfReport = null }) => {
    const params: { id: string; classId: string } = useParams();
    const { t: translator } = useTranslation();
    const [hasComments, setHasComments] = useState(false);

    const [dataView, setDataView] = useState<any>();

    useEffect(() => {
        if (dataVersion) {
            setDataView(dataVersion.data.data);
        } else {
            classService
                .getReportDetailsOfClass(params.id, params?.classId || classOfReport, reportId)
                .then((res) => {
                    if (res) {
                        setDataView(res?.data);
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [reportId]);

    useEffect(() => {
        dataView?.comments?.forEach((item) => {
            if (item?.comment || item?.comment_vn) {
                setHasComments(true);
            }
        });
    }, [dataView]);

    const getColorAssessment = (value) => {
        if (value >= 80) {
            return '#2576B9';
        }
        if (value < 80 && value >= 65) {
            return '#3AA757';
        }
        if (value < 65 && value >= 50) {
            return '#FFD95C';
        }
        return '#F64B7F';
    };

    return (
        <div className="">
            <div className="w-full">
                <div className="flex flex-col  mt-6 font-inter">
                    <div className="w-full ">
                        <div className="card px-5 py-6">
                            <EvaluateTeacher
                                name={dataView?.my_class?.name}
                                isOverall={true}
                                instructors={dataView?.comments}
                                overall={dataView?.gradebook?.overall || 0}
                            />
                        </div>
                        {dataView?.comments && (
                            <div className="card mt-5 py-6 px-5 flex items-center">
                                <div className='heading-base text-sm text-ooolab_dark_1 mr-1'>Instructor Comment:</div>
                                {dataView?.comments[0]}
                            </div>
                        )}

                        <div className="flex mt-6 space-x-4">
                            <div className="w-1/2 bg-[#2F2D51] rounded-[20px] px-5 py-4 text-white hidden">
                                <div className="mb-4">
                                    <label className="text-xs font-semibold">Online Class Grade</label>
                                    <p className="text-2xl font-bold mt-1">
                                        {Math.round(dataView?.gradebook?.online_score_in_percent) || 0}%
                                    </p>
                                </div>
                                <div className="mt-2.5">
                                    <ProgressPointBar
                                        percentage={dataView?.gradebook?.online_apo_in_percent || 0}
                                        label="Total Coins"
                                    />
                                </div>
                                <div className="mt-2.5">
                                    <ProgressPointBar
                                        percentage={dataView?.gradebook?.online_progress_in_percent || 0}
                                        label="Progress"
                                    />
                                </div>
                                <div className="mt-2.5">
                                    <ProgressPointBar
                                        percentage={dataView?.gradebook?.online_behaviour_in_percent || 0}
                                        label="Behavior"
                                    />
                                </div>
                                <div className="mt-2.5">
                                    <ProgressPointBar
                                        percentage={dataView?.gradebook?.online_participation_in_percent || 0}
                                        label="Participation"
                                    />
                                </div>
                            </div>
                            <div className="w-1/2 bg-[#5E62AC] rounded-[20px] px-5 py-4 text-white hidden">
                                <div className="mb-4">
                                    <label className="text-xs font-semibold">Offline Class Grade</label>
                                    <p className="text-2xl font-bold mt-1">
                                        {Math.round(dataView?.gradebook?.offline_score_in_percent) || 0}%
                                    </p>
                                </div>
                                <div className="mt-2.5">
                                    <ProgressPointBar
                                        percentage={dataView?.gradebook?.offline_apo_in_percent || 0}
                                        label="Total Coins"
                                    />
                                </div>
                                <div className="mt-2.5">
                                    <ProgressPointBar
                                        percentage={dataView?.gradebook?.offline_progress_in_percent || 0}
                                        label="Progress"
                                    />
                                </div>
                                <div className="mt-2.5">
                                    <ProgressPointBar
                                        percentage={dataView?.gradebook?.offline_behaviour_in_percent || 0}
                                        label="Behavior"
                                    />
                                </div>
                                <div className="mt-2.5">
                                    <ProgressPointBar
                                        percentage={dataView?.gradebook?.offline_participation_in_percent || 0}
                                        label="Participation"
                                    />
                                </div>
                            </div>
                        </div>
                        {dataView?.gradebook && (
                            <div className="card p-5 text-white mt-6 bg-[#1AB8FE] hidden">
                                <AppReview {...dataView?.gradebook} />
                            </div>
                        )}
                    </div>
                    <div className="w-full sm:w-1/2 mt-6 sm:mt-0 hidden">
                        <div className="card w-full py-4 px-5 text-ooolab_dark_1">
                            <div className="flex text-ooolab_dark_1 text-sm space-x-2 font-semibold items-center relative">
                                <span>Summative Assessment</span>
                                <TooltipInfo info={<GradebookTestScores />}>
                                    <button>
                                        <CircleInformationIcon height="13" width="13" />
                                    </button>
                                </TooltipInfo>
                            </div>
                            <div className="mt-4">
                                <div className="mt-2 flex w-full items-center">
                                    <label className="flex-none w-[77px] text-xs">Reading</label>
                                    <div className="w-full">
                                        <ProgressBar
                                            percentage={dataView?.gradebook?.assessment_reading_in_percent || 0}
                                            hideLabel={true}
                                            height="h-[4px]"
                                            processBg={`bg-[${getColorAssessment(
                                                dataView?.gradebook?.assessment_reading_in_percent || 0
                                            )}]`}
                                            lineBg="bg-[#C7C9D9]"
                                        />
                                    </div>
                                </div>
                                <div className="text-[#009FB2] text-sm  space-x-2 items-center mt-3 relative">
                                    <div className="absolute mt-1">
                                        <MessageIcon fill="#2E3A59" />
                                    </div>
                                    <span className="text-xs text-ooolab_dark_1 pl-4">
                                        Teacher comment (EN): {dataView?.reading_comment}
                                    </span>
                                </div>
                                {dataView?.reading_comment_vn && (
                                    <div className="text-[#009FB2] text-sm  space-x-2 items-center mt-3 relative">
                                        <div className="absolute mt-1">
                                            <MessageIcon fill="#2E3A59" />
                                        </div>

                                        <span className="text-xs text-ooolab_dark_1 pl-4">
                                            Teacher comment (VI): {dataView?.reading_comment_vn}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div className="mt-4">
                                <div className="mt-2 flex w-full items-center">
                                    <label className="flex-none w-[77px] text-xs">Speaking</label>
                                    <div className="w-full">
                                        <ProgressBar
                                            percentage={dataView?.gradebook?.assessment_speaking_in_percent || 0}
                                            hideLabel={true}
                                            height="h-[4px]"
                                            processBg={`bg-[${getColorAssessment(
                                                dataView?.gradebook?.assessment_speaking_in_percent || 0
                                            )}]`}
                                            lineBg="bg-[#C7C9D9]"
                                        />
                                    </div>
                                </div>
                                <div className="text-[#009FB2] text-sm space-x-2 items-center mt-3 relative">
                                    <div className="absolute mt-1">
                                        <MessageIcon fill="#2E3A59" />
                                    </div>
                                    <span className="text-xs text-ooolab_dark_1 pl-4">
                                        Teacher comment (EN): {dataView?.speaking_comment}
                                    </span>
                                </div>
                                {dataView?.speaking_comment_vn && (
                                    <div className="text-[#009FB2] text-sm  space-x-2 items-center mt-3 relative">
                                        <div className="absolute mt-1">
                                            <MessageIcon fill="#2E3A59" />
                                        </div>

                                        <span className="text-xs text-ooolab_dark_1 pl-4">
                                            Teacher comment (VI): {dataView?.speaking_comment_vn}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div className="mt-4 hidden">
                                <div className="mt-2 flex w-full items-center">
                                    <label className="flex-none w-[77px] text-xs">Writing</label>
                                    <div className="w-full">
                                        <ProgressBar
                                            percentage={dataView?.gradebook?.assessment_writing_in_percent || 0}
                                            hideLabel={true}
                                            height="h-[4px]"
                                            processBg={`bg-[${getColorAssessment(
                                                dataView?.gradebook?.assessment_writing_in_percent || 0
                                            )}]`}
                                            lineBg="bg-[#C7C9D9]"
                                        />
                                    </div>
                                </div>
                                <div className="text-[#009FB2] text-sm space-x-2 items-center mt-3 relative">
                                    <div className="absolute mt-1">
                                        <MessageIcon fill="#2E3A59" />
                                    </div>
                                    <span className="text-xs text-ooolab_dark_1 pl-4">
                                        Teacher comment (EN): {dataView?.writing_comment}
                                    </span>
                                </div>
                                {dataView?.writing_comment_vn && (
                                    <div className="text-[#009FB2] text-sm  space-x-2 items-center mt-3 relative">
                                        <div className="absolute mt-1">
                                            <MessageIcon fill="#2E3A59" />
                                        </div>

                                        <span className="text-xs text-ooolab_dark_1 pl-4">
                                            Teacher comment (VI): {dataView?.writing_comment_vn}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div className="mt-4 hidden">
                                <div className="mt-2 flex w-full items-center">
                                    <label className="flex-none w-[77px] text-xs">Listening</label>
                                    <div className="w-full">
                                        <ProgressBar
                                            percentage={dataView?.gradebook?.assessment_listening_in_percent || 0}
                                            hideLabel={true}
                                            height="h-[4px]"
                                            processBg={`bg-[${getColorAssessment(
                                                dataView?.gradebook?.assessment_listening_in_percent || 0
                                            )}]`}
                                            lineBg="bg-[#C7C9D9]"
                                        />
                                    </div>
                                </div>
                                <div className="text-[#009FB2] text-sm  space-x-2 items-center mt-3 relative ">
                                    <div className="absolute mt-1">
                                        <MessageIcon fill="#2E3A59" />
                                    </div>
                                    <span className="text-xs text-ooolab_dark_1 pl-4 ">
                                        Teacher comment (EN): {dataView?.listening_comment}
                                    </span>
                                </div>
                                {dataView?.listening_comment_vn && (
                                    <div className="text-[#009FB2] text-sm  space-x-2 items-center mt-3 relative">
                                        <div className="absolute mt-1">
                                            <MessageIcon fill="#2E3A59" />
                                        </div>

                                        <span className="text-xs text-ooolab_dark_1 pl-4">
                                            Teacher comment (VI): {dataView?.listening_comment_vn}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        {dataView?.comments && hasComments && (
                            <Comments comments={dataView?.comments} isEnglish={true} className="card p-4 mt-6 hidden" />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GradeReport;
