import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { customSelectStyle, ENROLLMENT_TYPE, PROGRAM_TYPE } from './utils';
import { SearchIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import { getDayOfWeekInRange } from 'utils/handleFormatTime';
import DatePickerInput from 'components/Management/components/Form/DatePicker';
import TimePickerInput from 'components/Management/components/Form/TimePicker';
import { defaultPagination, ListWeekDay } from 'constant/util.const';
import SelfBookingSelect from '../SelfBookingSelect';
import workspaceService from 'services/workspace.service';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import classService from 'services/class.service';
import selfBookingService from 'services/selfBooking.service';
import { filterClass, filterInstructors } from '../SelfBookingSelect/SelfBookingContants/SelfBookingContants';
import { debounce, isArray, mergeWith } from 'lodash';
import { timezone } from 'constant/timezone.const';
import DialogComponent from 'components/Dialog/DialogComponent';
import ButtonComponent from 'components/Button/ButtonComponent';
import { useBoolean } from 'hooks/custom';
import { UserContext } from 'contexts/User/UserContext';
import SelfBookingSelectCard from '../SelfBookingSelectCard';
import useInfiniteScroll from 'react-infinite-scroll-hook';

const customFilter = (option) => {
    return option.label;
};

const defaultParamsGetAvailabelSlot = {
    page: 1,
    per_page: 100,
};

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

const Control = ({ children, ...props }: any) => {
    const style = { cursor: 'pointer' };
    return (
        <components.Control {...props}>
            <span style={style}>
                <SearchIcon className="w-5 h-5 pl-1 text-dark-300" />
            </span>
            {children}
        </components.Control>
    );
};

export default function NewBookingClass() {
    const { t: translator } = useTranslation();

     const typeOfProgramOptions = useMemo(() => [
        { label: 'Student', value: 'individual' },
        { label: 'Learning Group', value: 'learning_group' },
    ], [])

    const {
        handleSubmit,
        formState: { errors },
        control,
        getValues,
        setValue,
        watch,
        reset,
        register,
    } = useForm({mode: "onChange"});

    const history = useHistory();
    const paramsUrl: { id: string } = useParams();
    const startDateWatch = watch('start_date');
    const endDateWatch = watch('end_date');
    const [loadingLearningPath, setLoadingLearningPath] = React.useState<boolean>(false);
    const [loadingTeachers, setLoadingTeachers] = React.useState<boolean>(false);
    const [loadingLearner, setLoadingLearner] = React.useState<boolean>(false);
    const [loadingLearningGroup, setLoadingLearningGroup] = React.useState<boolean>(false);

    const [currentBooking, setCurrentBooking] = useState({});
    const [hasNextPage, setHasNextPage] = useState<boolean>(false);

    const [activeClassFilter, setActiveClassFilter] = useState(filterClass[0]);
    const [activeInstrunstorFilter, setActiveInstrunstorFilter] = useState(filterInstructors[0]);

    const { booleanValue: modalConfirm, toggleBooleanValue: toggleModalConfirm } = useBoolean();
    const { booleanValue: modalCancel, toggleBooleanValue: toggleModalCancel } = useBoolean();
    const [loadingGetAvailableSlot, setLoadingGetAvailableSlot] = useState(false);

    const [enrollmentData, setEnrollmentData] = useState<any>();

    const [listLearningPaths, setListLearningPaths] = useState([]);
    const [listTeachers, setListTeachers] = useState([]);
    const [listLearningGroup, setListLearningGroup] = useState([]);
    const [listLearner, setListLearner] = useState([]);

    const [paginationTeachers, setPaginationTeachers] = useState({...defaultPagination, sort_by: "display_name", order: "asc"});
    const [paginationLearner, setPaginationLearner] = useState({...defaultPagination, sort_by: "display_name", order: "asc"});
    const [paginationLearningGroup, setPaginationLearningGroup] = useState({...defaultPagination, sort_by: "name", order: "asc"});
    const [paginationLearningPath, setPaginationLearningPath] = useState({...defaultPagination, sort_by: "title", order: "asc"});

    const {
        userState: { result },
    } = useContext(UserContext);
    const location: any = useLocation();

    const [paramsAvailableSlots, setParamsAvailableSlots] = useState<any>(defaultParamsGetAvailabelSlot);
    const [listSession, setListSession] = useState<any>({});

    const [inputSearchLearningPath, setInputSearchLearningPath] = useState<string>(null);
    const [inputSearchTeachers, setInputSearchTeachers] = useState<string>(null);
    const [inputSearchLearningGroup, setInputSearchLearningGroup] = useState<string>(null); 
    const [inputSearchLearner, setInputSearchLearner] = useState<string>(null);
    const [submitFind, setSubmitFind] = useState<boolean>(false);
    const [timezoneLearner, setTimezoneLearner] = useState<string>(null);
    const [isReschedule, setReschedule] = useState(true);
    const debounceInput = useCallback(
        debounce((nextValue: string, asyncFunction: (p: string) => void) => {
            if (nextValue?.length) {
                asyncFunction(nextValue);
            }
        }, 1000),
        []
    );

    useEffect(() => {
        if (location?.state) {
            const endDate = addDays(new Date(), 3);
            setCurrentBooking(location?.state);
            const learning_group = undefined;
            const { learning_path, students } = location?.state;

            const commonFields = {
                    instructors: null,
                    learning_path: {
                        ...learning_path,
                        label: learning_path?.title,
                        value: learning_path?.id,
                    },
                    student: learning_path?.program_type === PROGRAM_TYPE.INDIVIDUAL && {
                        label: students?.[0]?.display_name,
                        value: students?.[0]?.id,
                        avatar_url: students?.[0]?.avatar_url,
                    },
                    learning_group,
                    program_type: 
                        learning_path?.program_type === PROGRAM_TYPE.INDIVIDUAL ? 
                            typeOfProgramOptions?.[0]
                            : 
                            typeOfProgramOptions?.[1]
            }

            if ( learning_path?.program_type === PROGRAM_TYPE.LEARNING_GROUP) {
                const leader = students?.find((student) => student?.is_leader)
                commonFields.learning_group = {
                    label: leader?.learning_group?.name,
                    value: leader?.learning_group?.id,
                    leader: {
                        ...leader, value: leader?.id
                    }
                }
            }
            
            if (learning_path?.enrollment_type === ENROLLMENT_TYPE.EXPIRARION) {
                reset({
                    ...commonFields,
                    start_time: dayjs(learning_path?.enrollment_start_date).valueOf(),
                    end_time: new Date().setHours(23, 30, 0),
                    start_date: dayjs(learning_path?.enrollment_start_date).valueOf(),
                    end_date:  dayjs(learning_path?.enrollment_end_date).valueOf(),
                    days: [],
                });
            }

            if (learning_path?.enrollment_type === ENROLLMENT_TYPE.CREDITS) {
                reset({
                    ...commonFields,
                    start_time: new Date().setHours(0, 0, 0),
                    end_time: new Date().setHours(23, 30, 0),
                    start_date: new Date(),
                    end_date: endDate,
                    days: [],
                });
            }
        }
        else {
            setValue('program_type', typeOfProgramOptions?.[0])
            setReschedule(false)
        }
    }, [location?.state]);

    function customizer(objValue, srcValue) {
        if (isArray(objValue)) {
            return objValue.concat(srcValue);
        }
    }

    const getListSessions = (params: any, resetFilter?: boolean) => {
        setLoadingGetAvailableSlot(true);
        const paramsGetListSession = {
            ...paramsAvailableSlots,
            ...params,
        };

        if (resetFilter) {
            delete paramsGetListSession?.preferred;
        }
        selfBookingService
            .getAvailableSlotsServive(paramsUrl?.id, paramsGetListSession)
            .then((res) => {
                setParamsAvailableSlots({ ...paramsGetListSession, total: res.data?.total });
                handleCheckNext(paramsGetListSession?.page, res?.data?.total / paramsGetListSession?.per_page);
                const currentListSession =
                    paramsGetListSession?.page === 1 ? res.data?.items : mergeWith(listSession, res.data?.items, customizer);
                setListSession(currentListSession);
            })
            .finally(() => setLoadingGetAvailableSlot(false));
    };

    const handleCheckNext = (length: number, total) => {
        if (length < total + 1) {
            setHasNextPage(true);
        } else {
            setHasNextPage(false);
        }
    };

    const loadMoreListSession = async () => {
        getListSessions({ page: paramsAvailableSlots.page + 1 });
    };

    const [infiniteRef] = useInfiniteScroll({
        loading: loadingGetAvailableSlot,
        hasNextPage,
        onLoadMore: loadMoreListSession,
    });

    const getListLearner = (page?: number, e?: string) => {
        setLoadingLearner(true);
        const params = {
            ...paginationLearner,
            page: page || paginationLearner.page,
            per_page: paginationLearner.per_page,
            q: e || inputSearchLearner,
        };
        classService
            .getStudentListService(paramsUrl?.id, params)
            .then((res) => {
                const formatedListLearner = res?.data?.items.map((student) => ({
                    ...student,
                    label: student?.display_name,
                    value: student?.id,
                    timezone: student?.time_zone || 'UTC',
                }));
                if (page === 1) {
                    setListLearner(formatedListLearner);
                } else {
                    setListLearner([...listLearner, ...formatedListLearner]);
                }
                setPaginationLearner({
                    ...paginationLearner,
                    page: res?.data?.page,
                    per_page: res?.data?.per_page,
                    total: res?.data?.total,
                });
            })
            .finally(() => setLoadingLearner(false));
    };

    const getListLearningGroup = (page?: number, e?: string) => {
        setLoadingLearningGroup(true);
        const params = {
            ...paginationLearningGroup,
            page: page || paginationLearningGroup.page,
            per_page: paginationLearningGroup.per_page,
            q: e || inputSearchLearningGroup,
        };
        workspaceService.getLearningGroupService(paramsUrl?.id, params)
            .then((res) => {
                const formatedListLearningGroup = res?.data?.items.map((group) => ({
                    ...group,
                    label: group?.name,
                    value: group?.id,
                    leader: {...group?.leader, value: group?.leader?.id},
                    timezone: group?.time_zone || 'UTC',
                }));
                if (page === 1) {
                    setListLearningGroup(formatedListLearningGroup);
                } else {
                    setListLearningGroup([...listLearningGroup, ...formatedListLearningGroup]);
                }
                setPaginationLearningGroup({
                    ...paginationLearningGroup,
                    page: res?.data?.page,
                    per_page: res?.data?.per_page,
                    total: res?.data?.total,
                });
            })
            .finally(() => setLoadingLearningGroup(false));
    };

    const getListTeachers = (page?: number, e?: string) => {
        setLoadingTeachers(true);
        const learning_path = watch('learning_path')?.value;
        const params = {
            ...paginationTeachers,
            page: page || paginationTeachers.page,
            per_page: paginationTeachers.per_page,
            q: e || inputSearchTeachers,
            learning_path,
        };
        classService
            .getTeacherListService(paramsUrl?.id, params)
            .then((res) => {
                const formatedListTeachers = res?.data?.items?.map((instructor) => ({
                    label: instructor?.user?.display_name,
                    value: instructor?.user?.id,
                }));
                if (page === 1) {
                    setListTeachers(formatedListTeachers);
                } else {
                    setListTeachers([...listTeachers, ...formatedListTeachers]);
                }
                setPaginationTeachers({
                    ...paginationTeachers,
                    page: res?.data?.page,
                    per_page: res?.data?.per_page,
                    total: res?.data?.total,
                });
            })
            .finally(() => setLoadingTeachers(false));
    };

    const getDetailEnrollmentOfStudentWithLearningPath = () => {
        const learningPathId = getValues('learning_path')?.value;
        const studentId = getValues('student')?.value || getValues('learning_group')?.leader?.id;
        const learningGroupId = getValues('learning_group')?.value

        if (studentId && learningPathId) {
            workspaceService
                .getDetailEnrollmentOfStudentWithLearningPathService(paramsUrl?.id, learningPathId, studentId, {learning_group_id: learningGroupId })
                .then((res) => {
                    setEnrollmentData(res?.data);
                });
        }
    };

    const getListLearningPath = (page?: number, e?: string) => {
        setLoadingLearningPath(true);
        const params = {
            ...paginationLearningPath,
            page: page || paginationLearningPath?.page,
            per_page: paginationLearningPath?.per_page,
            q: e || inputSearchLearningPath,
            status: 'active',
            student_id: watch('student')?.value || watch('learning_group')?.leader?.id,
            is_booking: true,
            program_type: watch('program_type')?.value,
            group_id: watch('learning_group')?.value
        };
        if (watch('program_type') === 'individual') delete params.group_id
        if (!params?.q) delete params?.q;
        delete params?.total;
        workspaceService
            .getLearningPathsService(paramsUrl?.id, params)
            .then((res) => {
                const formatedListLearningPaths = res?.data?.items?.map((lp) => ({
                    ...lp,
                    label: lp?.title,
                    value: lp?.id,
                }));
                if (page === 1) {
                    setListLearningPaths(formatedListLearningPaths);
                } else {
                    setListLearningPaths([...listLearningPaths, ...formatedListLearningPaths]);
                }
                setPaginationLearningPath({
                    ...paginationLearningPath,
                    page: res?.data?.page,
                    per_page: res?.data?.per_page,
                    total: res?.data?.total,
                });
            })
            .finally(() => setLoadingLearningPath(false));
    };

    const loadMoreLearningPath = () => {
        if (paginationLearningPath?.total > paginationLearningPath?.page * paginationLearningPath?.per_page) {
            getListLearningPath(paginationLearningPath?.page + 1);
        }
    };
    const loadMoreTeachers = () => {
        if (paginationTeachers.total > paginationTeachers.page * paginationTeachers.per_page) {
            getListTeachers(paginationTeachers.page + 1);
        }
    };
    const loadMoreLearner = () => {
        if (paginationLearner.total > paginationLearner.page * paginationLearner.per_page) {
            getListLearner(paginationLearner.page + 1);
        }
    };

    const loadMoreLearningGroup = () => {
        if (paginationLearningGroup.total > paginationLearningGroup.page * paginationLearningGroup.per_page) {
            getListLearningGroup(paginationLearningGroup.page + 1);
        }
    };

    useEffect(() => {
        if (watch("program_type")?.value === 'individual' && !isReschedule) getListLearner(1);
        else getListLearningGroup(1);
        if (!isReschedule) {
            setValue('student', null);
            setValue('learning_group', null);
            setValue('learning_path', null);
            setValue('instructors', []);
            setEnrollmentData({});
            setListLearningGroup([]);
            setListLearner([]);
            setParamsAvailableSlots(defaultParamsGetAvailabelSlot);
            getDetailEnrollmentOfStudentWithLearningPath();
            setActiveInstrunstorFilter(filterInstructors[0]);
            return setSubmitFind(false);
        }
       
    }, [watch("program_type")?.value]);

    useEffect(() => {
        if ((watch('student') || watch('learning_group')) && !location?.state?.id && !isReschedule) {
            setValue('learning_path', null);
            getListLearningPath(1);
            setEnrollmentData({});
            setValue('instructors', []);
            setParamsAvailableSlots(defaultParamsGetAvailabelSlot);
            setActiveInstrunstorFilter(filterInstructors[0]);
            getDetailEnrollmentOfStudentWithLearningPath();
        }
    }, [watch("learning_group")?.value || watch("student")?.value]);

    useEffect(() => {
        const learningPath = watch('learning_path')
        if (learningPath) {
            getListTeachers(1);
            setValue('instructors', []);
            setListSession({});
            setEnrollmentData({});
            setHasNextPage(false);
            setParamsAvailableSlots(defaultParamsGetAvailabelSlot);
            getDetailEnrollmentOfStudentWithLearningPath();
            setActiveInstrunstorFilter(filterInstructors[0]);
           
        }
        if (learningPath?.enrollment_type === ENROLLMENT_TYPE.EXPIRARION) {
            setValue("start_time", undefined);
            setValue("end_time", undefined);
            setValue("start_date", dayjs(learningPath?.enrollment_start_date).isBefore(dayjs()) ? dayjs()?.valueOf() : dayjs(learningPath?.enrollment_start_date)?.valueOf());
            setValue("end_date", dayjs(learningPath?.enrollment_end_date).valueOf());
        }
        else {
            setValue("start_time", undefined);
            setValue("end_time", undefined);
            setValue("start_date", undefined);
            setValue("end_date", undefined);
        }
    }, [watch('learning_path')?.value]);

    useEffect(() => {
        setValue('days', []);
    }, [watch('end_date'), watch('start_date')]);

    const submitForm = (val) => {
        setListSession({});
        setSubmitFind(true);
        const start_time = dayjs(val?.start_time)?.format('HH:mm');
        const end_time = dayjs(val?.end_time)?.format('HH:mm');
        const start_date = dayjs(val?.start_date)?.format('YYYY-MM-DD');
        const end_date = dayjs(val?.end_date)?.format('YYYY-MM-DD');

        const params = {
            start_time: dayjs(start_date + 'T' + start_time)?.format('YYYY-MM-DDTHH:mm'),
            end_time: dayjs(end_date + 'T' + end_time)?.format('YYYY-MM-DDTHH:mm'),
            instructors: (val?.instructors?.length > 0 && val?.instructors?.map((instructor) => instructor?.value)) || [],
            learning_paths: [val?.learning_path?.value],
            group_by: {
                field: 'start_date',
            },
            weekdays: val?.days?.map((item) => ({
                weekday: item,
                time_zone: result?.time_zone,
            })),
            includes_past: false,
            student_id: val?.student?.value || val?.learning_group?.leader?.id,
            page: 1,
        };
        getListSessions(params);
    };

    const handleChangeTimeSlot = (val) => {
        let currentSelectedDay = getValues('days') || [];
        if (currentSelectedDay?.includes(val)) {
            currentSelectedDay = currentSelectedDay?.filter((day) => day !== val);
        } else currentSelectedDay = [...currentSelectedDay, val];
        setValue('days', currentSelectedDay);
    };

    const listActiveDay = getDayOfWeekInRange(startDateWatch, endDateWatch);

    const onFilter = (val) => {
        setListSession({});
        const parmas = {
            preferred: val?.prefer,
            page: 1,
        };
        getListSessions(parmas);
    };

    const onRemoveFilter = () => {
        getListSessions({ page: 1 }, true);
    };

    const onReset = () => {
        reset({
            program_type: typeOfProgramOptions?.[0]
        });
        setSubmitFind(false);
        setListSession({});
        setParamsAvailableSlots({});
        setActiveClassFilter(filterClass[0]);
        setActiveInstrunstorFilter(filterInstructors[0]);
        setPaginationTeachers({...defaultPagination, sort_by: "display_name", order: "asc"});
        setPaginationLearner({...defaultPagination, sort_by: "display_name", order: "asc"});
        setPaginationLearningPath({...defaultPagination, sort_by: "title", order: "asc"});
        setPaginationLearningGroup({...defaultPagination, sort_by: "name", order: "asc"});
        setHasNextPage(false);
        setParamsAvailableSlots(defaultParamsGetAvailabelSlot);
    };

    const handleFindTimezone = (tz: string) => {
        const textTimeZone = timezone.find((i) => i.utc.includes(tz));
        if (textTimeZone) {
            return textTimeZone.text;
        }
        return undefined;
    };

    return (
        <div className=" py-8 px-10 max-w-6xl 2xl:max-w-7xl ">
            <h2 className=" text-2xl font-semibold text-dark-300">
                {isReschedule ? translator('SELF_BOOKING.RESCHEDULE_SESSION') : translator('SELF_BOOKING.ADD_BOOKING')}
            </h2>
            <p className=" text-dark-100 text-sm font-normal mt-2">{translator('SELF_BOOKING.FILL_OUT_YOUR_BOOKING_INFORMATION')}</p>

            {isReschedule && (
                <div className="pointer-events-none">
                    <p className=" text-xs text-dark-300 mt-6 mb-2">{translator('SELF_BOOKING.CURRENT_BOOKED_CLASS')}</p>
                    <SelfBookingSelectCard
                        studentId={getValues('student')?.value}
                        data={currentBooking}
                        defaultChecked
                        onlyView
                        isReschedule={isReschedule}
                    />
                </div>
            )}

            <form onSubmit={handleSubmit(submitForm)} className="grid grid-cols-4 gap-x-10 gap-y-6 mt-1">
                <div className="col-span-2 mt-4 flex gap-1 ">
                    <div className='w-2/5'>
                        <label htmlFor="learning_group" className="text-dark-300 text-xs font-semibold">
                           {translator('SELF_BOOKING.BOOING_FOR')}
                        </label>
                         <div className='mt-2'>
                            <Controller
                                control={control}
                                name="program_type"
                                {...register('program_type', {
                                    required: {
                                        value: true,
                                        message: translator('FORM_CONST.REQUIRED_FIELD'),
                                    },
                                })}
                                render={({ field, fieldState }) => {
                                    return (
                                        <Select
                                            {...field}
                                            className=" text-dark-300 font-normal rounded"
                                            styles={customSelectStyle}
                                            hideSelectedOptions={false}
                                            // components={{ Control }}
                                            menuPlacement="auto"
                                            maxMenuHeight={200}
                                            isDisabled={isReschedule}
                                            closeMenuOnSelect={true}
                                            filterOption={customFilter}
                                            name="program_type"
                                            placeholder={translator('SELECT')}
                                            options={typeOfProgramOptions}
                                            isSearchable={false}
                                        />
                                    );
                                }}
                            />
                         </div>
                    </div>

                    {
                        watch('program_type')?.value === 'learning_group' && 
                        <div className='w-3/5'>
                            <div>
                                <label htmlFor="learning_group" className="text-dark-300 text-xs font-semibold">
                                    <span className="text-green-500 ml-2">{handleFindTimezone(timezoneLearner)}</span>
                                </label>
                                <div className="mt-2">
                                    <Controller
                                        control={control}
                                        name="learning_group"
                                        {...register('learning_group', {
                                            required: {
                                                value: true,
                                                message: translator('FORM_CONST.REQUIRED_FIELD'),
                                            },
                                        })}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    className=" text-dark-300 font-normal rounded"
                                                    styles={customSelectStyle}
                                                    hideSelectedOptions={false}
                                                    onInputChange={(inputValue) => {
                                                        debounceInput(inputValue, () => getListLearningGroup(1, inputValue));
                                                        setInputSearchLearningGroup(inputValue);
                                                    }}
                                                    value={field.value || null}
                                                    options={listLearningGroup}
                                                    components={{ Control }}
                                                    menuPlacement="auto"
                                                    isLoading={loadingLearningGroup}
                                                    placeholder={translator('SELF_BOOKING.SEARCH_LEARNING_GROUP')}
                                                    maxMenuHeight={200}
                                                    onChange={(val: any) => {
                                                        field.onChange(val);
                                                        setTimezoneLearner(val?.leader?.time_zone);
                                                    }}
                                                    isDisabled={isReschedule}
                                                    closeMenuOnSelect={true}
                                                    filterOption={customFilter}
                                                    onMenuScrollToBottom={loadMoreLearningGroup}
                                                    onMenuClose={() => {
                                                        setInputSearchLearningGroup(null);
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <ErrorMessage className=" text-ooolab_error text-xs mt-1" errors={errors} name="learning_group" as="p" />
                        </div>
                    }

                    {
                        watch('program_type')?.value === 'individual' && 
                        <div className='w-3/5'>
                            <div>
                                <label htmlFor="student" className="text-dark-300 text-xs font-semibold">
                                    {/* {translator('SELF_BOOKING.STUDENT')} */}
                                    <span className="text-green-500 ml-2">{handleFindTimezone(timezoneLearner)}</span>
                                </label>
                                <div className="mt-2">
                                    <Controller
                                        control={control}
                                        name="student"
                                        {...register('student', {
                                            required: {
                                                value: true,
                                                message: translator('FORM_CONST.REQUIRED_FIELD'),
                                            },
                                        })}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    className=" text-dark-300 font-normal rounded"
                                                    styles={customSelectStyle}
                                                    hideSelectedOptions={false}
                                                    onInputChange={(inputValue) => {
                                                        debounceInput(inputValue, () => getListLearner(1, inputValue));
                                                        setInputSearchLearner(inputValue);
                                                    }}
                                                    value={field.value || null}
                                                    options={listLearner}
                                                    components={{ Control }}
                                                    menuPlacement="auto"
                                                    isLoading={loadingLearner}
                                                    placeholder={translator('SELF_BOOKING.SEARCH_STUDENT')}
                                                    maxMenuHeight={200}
                                                    onChange={(val: any) => {
                                                        field.onChange(val);
                                                        setTimezoneLearner(val?.timezone);
                                                    }}
                                                    isDisabled={isReschedule}
                                                    closeMenuOnSelect={true}
                                                    filterOption={customFilter}
                                                    onMenuScrollToBottom={loadMoreLearner}
                                                    onMenuClose={() => {
                                                        setInputSearchLearner(null);
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <ErrorMessage className=" text-ooolab_error text-xs mt-1" errors={errors} name="student" as="p" />
                        </div>
                    }
                </div>
                <div className="col-span-2 mt-4">
                    <label className="text-dark-300 text-xs font-semibold">{translator('SELF_BOOKING.LEARNING_PATH')}</label>
                    <div className=" mt-2">
                        <Controller
                            control={control}
                            name="learning_path"
                            {...register('learning_path', {
                                required: {
                                    value: true,
                                    message: translator('FORM_CONST.REQUIRED_FIELD'),
                                },
                            })}
                            render={({ field }) => {
                                return (
                                    <Select
                                        className=" text-dark-300 font-normal  rounded"
                                        styles={customSelectStyle}
                                        onInputChange={(inputValue) => {
                                            debounceInput(inputValue, () => getListLearningPath(1, inputValue));
                                            setInputSearchLearningPath(inputValue);
                                        }}
                                        value={field.value || null}
                                        options={listLearningPaths}
                                        menuPlacement="auto"
                                        placeholder={translator('SELF_BOOKING.SEARCH_LEARNING_PATH')}
                                        maxMenuHeight={200}
                                        components={{ Control }}
                                        filterOption={customFilter}
                                        isLoading={loadingLearningPath}
                                        isDisabled={isReschedule || (!watch('student') && !watch('learning_group'))}
                                        closeMenuOnSelect={true}
                                        onChange={(val) => field.onChange(val)}
                                        onMenuScrollToBottom={loadMoreLearningPath}
                                        onMenuClose={() => {
                                            setInputSearchLearningPath(null);
                                        }}
                                    />
                                );
                            }}
                        />
                    </div>
                    <ErrorMessage className=" text-ooolab_error text-xs mt-1" errors={errors} name="learning_path" as="p" />
                </div>
                <div className="col-span-4">
                    <label htmlFor="instructors" className="text-dark-300 text-xs font-semibold">
                        {translator('SELF_BOOKING.INSTRUCTOR')}
                    </label>
                    <div className=" mt-2">
                        <Controller
                            control={control}
                            name="instructors"
                            render={({ field }) => {
                                return (
                                    <Select
                                        className=" text-dark-300 font-normal  rounded-20"
                                        styles={customSelectStyle}
                                        onInputChange={(inputValue) => {
                                            debounceInput(inputValue, () => getListTeachers(1, inputValue));
                                            setInputSearchTeachers(inputValue);
                                        }}
                                        value={field.value || []}
                                        options={listTeachers}
                                        components={{ Control }}
                                        closeMenuOnSelect={false}
                                        isDisabled={!watch('learning_path')}
                                        menuPlacement="auto"
                                        isLoading={loadingTeachers}
                                        placeholder={translator('SELF_BOOKING.SEARCH_INSTRUCTOR')}
                                        maxMenuHeight={200}
                                        onChange={(val) => field.onChange(val)}
                                        filterOption={customFilter}
                                        onMenuScrollToBottom={loadMoreTeachers}
                                        onMenuClose={() => {
                                            setInputSearchTeachers(null);
                                        }}
                                        isMulti
                                    />
                                );
                            }}
                        />
                    </div>
                    <ErrorMessage className=" text-ooolab_error text-xs mt-1" errors={errors} name="instructors" as="p" />
                </div>

                <div className="col-span-1">
                    <div className="">
                        <label className="text-dark-300 text-xs font-semibold" htmlFor="start_date">
                            {translator('SELF_BOOKING.START_DATE')}
                        </label>
                        <div className="w-full">
                            <DatePickerInput
                                control={control}
                                name="start_date"
                                placeholderText={translator('SELF_BOOKING.START_DATE')}
                                maxDate={
                                    watch('learning_path')?.enrollment_type === ENROLLMENT_TYPE.EXPIRARION ?
                                   (dayjs(watch('learning_path')?.enrollment_end_date).isBefore(dayjs(endDateWatch)) ?
                                    new Date(watch('learning_path')?.enrollment_end_date)
                                    :
                                    endDateWatch)
                                    :
                                    endDateWatch
                                }
                                minDate={
                                    watch('learning_path')?.enrollment_type === ENROLLMENT_TYPE.EXPIRARION ?
                                    (dayjs(watch('learning_path')?.enrollment_start_date).isBefore(dayjs()) ? 
                                    new Date() 
                                    : 
                                    new Date(watch('learning_path')?.enrollment_start_date))
                                    : 
                                    new Date()
                                }
                                disabled={!watch('learning_path')}
                                isRequired
                                classnames="mt-1 text-sm !rounded !p-2"
                                iconCls=" w-4 h-4 !top-[14px] !left-auto !right-2"
                                styles="!h-full"
                            />
                        </div>
                    </div>
                    <ErrorMessage className="text-ooolab_error text-xs  mt-1" errors={errors} name="start_date" as="p" />
                </div>

                <div className="col-span-1">
                    <div className="">
                        <label className="text-dark-300 text-xs font-semibold" htmlFor="end_date">
                            {translator('SELF_BOOKING.END_DATE')}
                        </label>
                        <div className="w-full h-full">
                            <DatePickerInput
                                control={control}
                                name="end_date"
                                placeholderText={translator('SELF_BOOKING.END_DATE')}
                                minDate={ startDateWatch || new Date()}
                                maxDate={
                                    watch('learning_path')?.enrollment_type === ENROLLMENT_TYPE.EXPIRARION &&
                                    new Date(watch('learning_path')?.enrollment_end_date)
                                }
                                disabled={!watch('learning_path') }
                                isRequired
                                classnames="mt-1 text-sm !rounded !p-2"
                                iconCls=" w-4 h-4 !top-[14px] !left-auto !right-2"
                                styles="!h-full"
                            />
                        </div>
                    </div>
                    <ErrorMessage className="text-ooolab_error text-xs mt-1" errors={errors} name="end_date" as="p" />
                </div>

                <div className="col-span-1 ">
                    <div className="">
                        <label className="text-dark-300 text-xs font-semibold">{translator('SELF_BOOKING.START_TIME')}</label>
                        <TimePickerInput
                            control={control}
                            name="start_time"
                            placeholderText={translator('SELF_BOOKING.START_TIME')}
                            filter={(time) => {
                                if (watch('end_time')) return dayjs(time)?.isBefore(watch('end_time'))
                                return true;
                            }}
                            disabled={!watch('learning_path')}
                            placement="bottom"
                            classnames="mt-1 text-sm !rounded !p-2"
                            iconCls=" w-4 h-4 !top-[14px] !left-auto !right-2"
                            styles="!h-full"
                        />
                    </div>
                    <ErrorMessage className="text-ooolab_error text-xs mt-1" errors={errors} name="start_time" as="p" />
                </div>

                <div className="col-span-1 ">
                    <div className="">
                        <label className="text-dark-300 text-xs font-semibold left-auto">{translator('SELF_BOOKING.END_TIME')}</label>
                        <TimePickerInput
                            control={control}
                            name="end_time"
                            placeholderText={translator('SELF_BOOKING.END_TIME')}
                            filter={(time) => {
                                if (watch('start_time')) return dayjs(time)?.isAfter(watch('start_time'));
                                return true
                            }}
                            disabled={!watch('learning_path')}
                            classnames="mt-1 text-sm !rounded !p-2"
                            iconCls=" w-4 h-4 !top-[14px] !left-auto !right-2"
                            styles="!h-full"
                        />
                    </div>
                    <ErrorMessage className="text-ooolab_error text-xs mt-1" errors={errors} name="end_time" as="p" />
                </div>

                <div className="col-span-4 ">
                    <label className="text-dark-300 text-xs font-semibold">{translator('SELF_BOOKING.SELECT_DAY')}</label>
                    <div className=" flex gap-3 justify-between mt-2">
                        {ListWeekDay(translator).map((i, index) => {
                            return (
                                <button
                                    type="button"
                                    onClick={() => handleChangeTimeSlot(i.value)}
                                    disabled={!listActiveDay.includes(i.value) || !watch('learning_path')}
                                    key={`class-slot-${i.name}`}
                                    className={`border border-dark-50 rounded w-24 py-1 text-sm  
                                        ${
                                            (watch('days') || []).includes(i.value)
                                                ? 'border-blue-300 !text-primary-500 bg-blue-50'
                                                : 'bg-none text-dark-300'
                                        }
                                            disabled:opacity-40 disabled:!text-dark-300 disabled:!bg-white
                                        `}
                                >
                                    {i.name}
                                </button>
                            );
                        })}
                    </div>
                </div>

                <div className="col-span-4 flex justify-center gap-4 mt-2">
                    <button
                        type="button"
                        onClick={() => {
                            onReset();
                        }}
                        disabled={isReschedule}
                        className=" disabled:cursor-not-allowed border border-dark-300 text-dark-300 py-2 text-center text-xs rounded w-32 font-semibold"
                    >
                        {translator('SELF_BOOKING.RESET_FILTER')}
                    </button>
                    <button 
                        type="submit" 
                        className=" disabled:!bg-gray-400 font-semibold bg-primary-500 text-white py-2 text-center text-xs rounded w-32">
                        {translator('SELF_BOOKING.FIND')}
                    </button>
                </div>
            </form>
            {watch('learning_path')?.value && (watch('student')?.value || watch('learning_group')?.value) && (
                <div>
                    <SelfBookingSelect
                        listSession={listSession}
                        onFilter={onFilter}
                        total={paramsAvailableSlots?.total || 0}
                        activeInstrunstorFilter={activeInstrunstorFilter}
                        activeClassFilter={activeClassFilter}
                        setActiveInstrunstorFilter={setActiveInstrunstorFilter}
                        setActiveClassFilter={setActiveClassFilter}
                        student={getValues('student') || getValues('learning_group')?.leader}
                        onReset={toggleModalConfirm}
                        getListSessions={() => getListSessions({ page: 1 })}
                        onRemoveFilter={onRemoveFilter}
                        learningPath={getValues('learning_path')}
                        isReschedule={isReschedule}
                        enrollmentData={enrollmentData}
                        loadingGetAvailableSlot={loadingGetAvailableSlot}
                        submitFind={submitFind}
                        hasNextPage={hasNextPage}
                        infiniteRef={infiniteRef}
                        page={paramsAvailableSlots?.page}
                    />
                </div>
            )}

            <DialogComponent
                isOpen={modalConfirm}
                title={translator('SELF_BOOKING.CONFIRMATION')}
                onCloseModal={toggleModalConfirm}
                maskClosable={false}
                styles="max-w-md"
                child={
                    <div className="">
                        <p className="text-sm">{translator('SELF_BOOKING.CONFIRM_RESET')}</p>
                        <div className="flex items-center justify-center space-x-4 mt-6">
                            <ButtonComponent
                                title={translator('SELF_BOOKING.NO')}
                                classStyle="xs:p-2 xs:text-sm secondary-button !rounded-ooolab_h5p w-full !py-3 flex justify-center"
                                onClickButton={toggleModalConfirm}
                            />
                            <ButtonComponent
                                type="submit"
                                title={translator('SELF_BOOKING.RESET_ALL')}
                                classStyle="xs:p-2 xs:text-sm primary-button primary-button !bg-ooolab_error !rounded-ooolab_h5p w-full !py-3 flex justify-center"
                                onClickButton={() => {
                                    onReset();
                                    toggleModalConfirm();
                                }}
                            />
                        </div>
                    </div>
                }
            />

            <DialogComponent
                isOpen={modalCancel}
                title={translator('SELF_BOOKING.CONFIRMATION')}
                onCloseModal={toggleModalCancel}
                maskClosable={false}
                styles="max-w-md"
                child={
                    <div>
                        <p className="text-sm">{translator('SELF_BOOKING.CONFIRM_CANCEL')}</p>
                        <div className="flex items-center justify-center space-x-6 mt-6">
                            <ButtonComponent
                                title={translator('SELF_BOOKING.NO')}
                                classStyle="xs:p-2 xs:text-sm secondary-button !rounded-ooolab_h5p w-full !py-3 flex justify-center"
                                onClickButton={toggleModalCancel}
                            />
                            <ButtonComponent
                                type="submit"
                                title={translator('SELF_BOOKING.CANCEL_BOOKING')}
                                classStyle="xs:p-2 xs:text-sm primary-button primary-button !bg-ooolab_error !rounded-ooolab_h5p w-full !py-3 flex justify-center"
                                onClickButton={() => history.push(`/workspace/${paramsUrl.id}/management/booking-class`)}
                            />
                        </div>
                    </div>
                }
            />
        </div>
    );
}
