import React from "react";

export const ImportIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4900_48371)">
                <path d="M3.33268 15.8333H16.666V10H18.3327V16.6667C18.3327 16.8877 18.2449 17.0996 18.0886 17.2559C17.9323 17.4122 17.7204 17.5 17.4993 17.5H2.49935C2.27834 17.5 2.06637 17.4122 1.91009 17.2559C1.75381 17.0996 1.66602 16.8877 1.66602 16.6667V10H3.33268V15.8333ZM10.8327 7.5V13.3333H9.16602V7.5H4.99935L9.99935 2.5L14.9993 7.5H10.8327Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_4900_48371">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}