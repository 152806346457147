import React from "react";
import { ArrorBottom } from "assets/icon";
import './styles.css';

export interface ColumnProps  {
    name: string, 
    color?: string, 
    height: number,
    subTitle?: string,
    toolTipLabel?: string
}

const Column = ({ name, subTitle, toolTipLabel, color = "primary-500", height=0}: ColumnProps) => {
    return <>
        <div className="">
            <div className={`chart-column cursor-pointer h-[125px] flex flex-col relative w-5 mx-auto items-center`}>
                <div className="absolute column-tooltip rounded shadow-medium  z-10"
                    style={{bottom: (height > 100 ? 100: +height) + 5 + '%' }}>
                    <div className="py-1 px-2 flex space-x-2 items-center whitespace-nowrap">
                        <span className={`w-2 h-2 rounded-full inline-block bg-${color}`}></span>
                        <span className="text-xs text-gray-800">
                            {subTitle || name}: <span className="font-semibold ml-[2px]">{toolTipLabel || height}</span>
                        </span>
                    </div>   
                    <div className="-mb-1 mx-auto w-3">
                        <ArrorBottom />
                    </div> 
                </div>
                <div className="bg-lime-500 bg-amber-700 bg-orange-500 bg-red-50 bg-primary-500" style={{display: 'none'}}></div>
                <div className={`bg-${color} rounded-t rounded-b-[2px] absolute bottom-0 w-full`} style={{height: height > 100 ? '100%' : height + '%' }}></div>
            </div>
            <div className="text-gray-500 text-[10px] mt-2">
                {name}
            </div>
        </div>
    </>
}
export const ChartColumn = ({data, hasPercent = true}: {data: ColumnProps[], hasPercent?:boolean}) => {
    return <>
        <div className="flex w-full">
            <div className="flex-none text-xs text-gray-500 text-center w-[24px] h-[150px] flex flex-col justify-between items-center">
                <p>100{hasPercent ? '%': ''}</p>
                <p>75</p>
                <p>50</p>
                <p>25</p>
                <p>0</p>
            </div>
            <div className="flex justify-around pt-[12px] w-full">
                {data?.map((item: ColumnProps) => (
                    <div key={item.name}>
                        <Column {...item} />
                    </div>
                ))}
            </div>
        </div>
    </>
}