import * as React from 'react';
import H5P from 'components/H5P';
import H5PProvider from 'contexts/H5P/H5PProvider';
import RouteMasterPage from 'pages/RouteMasterPage'

const H5PPage = () => {
    return (
        <H5PProvider>
            <H5P>
                <RouteMasterPage />
            </H5P>
        </H5PProvider>
    );
};

export default H5PPage;
