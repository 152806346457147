import React, { useEffect, useState } from 'react';
import './PublicLayout.scss';

export interface MasterLayoutProps {
    type: string
}

export const PublicLayout = ({ children }) => {
    return (
        <>
            <main className='publicLayout'>
                {children}
            </main>
        </>
    );
};