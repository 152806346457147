import React, { useReducer } from 'react';
import reducers from 'reducers';
import { initRewardsState } from 'types/Rewards.type';
import { RewardsContext } from './RewardsContext';

const RewardsProvider: React.FC = ({ children }) => {
    const [rewardsState, dispatch] = useReducer(
        reducers.getRewardsReducer,
        initRewardsState
    );
    return (
        <RewardsContext.Provider value={{ rewardsState, dispatch }}>
            {children}
        </RewardsContext.Provider>
    );
};

export default RewardsProvider;
