import React from 'react';
import ManagementMasterPage from 'pages/RouteMasterPage';
import BannerList from 'components/Management/Banner/BannerList';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';

const BannerListPage = () => {
    return (
        <ManagePermissionWrapper>
            <BannerList>
                <ManagementMasterPage />
            </BannerList>
        </ManagePermissionWrapper>
    );
};

export default BannerListPage;
