export const COURSE_STATUS = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
};

export const courseStatus = {
    active: 'ACTIVE',
    inactive: 'INACTIVE',
    trash: 'TRASH',
};

export const courseEnrollmentType = {
    open_auto_enroll: 'OPEN',
    open_not_auto_enroll: 'OPEN',
    credit: 'CREDITS',
    catalog: 'COURSE_CATALOG',
};

export const COURSE_TYPE = {
    online: 'Online',
    offline: 'Offline',
    blended: 'Blended',
    expired: 'Expired',
    instructor_led: 'Instructor Led',
    self_study: 'Self Study',
    e_learning: 'E-Learning',
    hybrid: 'Hybrid',
};

export const fieldData = (translator: (key: string) => string) => [
    { label: translator('LEVEL'), value: 'level', name: translator('LEVEL'), id: 1, check: false },
    { label: translator('SUBJECT'), value: 'subject', name: translator('SUBJECT'), id: 2, check: false },
    { label: translator('AGE_GROUP'), value: 'age', name: translator('AGE_GROUP'), id: 3, check: false },
    {
        label: translator('LANGUAGE_DELIVERY'),
        value: 'language_delivery',
        name: translator('LANGUAGE_DELIVERY'),
        id: 4,
        check: false,
    },
    { label: translator('DURATION'), value: 'duration', name: translator('DURATION'), id: 5, check: false },
    { label: translator('TAGS'), value: 'tags', name: translator('TAGS'), id: 6, check: false },
];

export const enum fieldDataEnum {
    subject = 'subject',
    level = 'level',
    age = 'age',
    language_deliverye = 'language_delivery',
    duration = 'duration',
    tags = 'tags',
}
