import { roundNumber } from 'constant/util.const';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from 'utils/commonFun';

const RenderTitle = ({ value, className }: { value: string; className?: string }) => (
    <p className={classNames('text-xs font-normal text-dark-100', className)}>{value}</p>
);
const RenderSubTitle = ({ value, className }: { value: any; className?: string }) => (
    <p className={classNames('text-base font-bold text-dark-300 break-words', className)}>{value}</p>
);

interface SyllabusComponentProps {
    details: any;
    type: string;
}

const SyllabusComponent: React.FC<SyllabusComponentProps> = ({ details, type }) => {
    const { t: translator } = useTranslation();

    return (
        <div>
            <div>
                <RenderTitle
                    value={
                        type === 'online'
                            ? translator('DASHBOARD.TEACHER.ONLINE_CLASS')
                            : translator('DASHBOARD.TEACHER.OFFLINE_CLASS')
                    }
                />
                <RenderSubTitle value={details?.title} />
            </div>
            <div className="border-t border-[#EEF1FA] my-6"></div>
            <div className="flex">
                <div className='w-1/2'>
                    <RenderTitle value={translator('LESSON.UNIT')} />
                    <RenderSubTitle value={details?.unit_number || '-'} />
                </div>
                <div className='w-1/2'>
                    <RenderTitle value={translator('LESSON.lesson goal')} />
                    <RenderSubTitle value={details?.lesson_goal || '-'} />
                </div>
            </div>
        </div>
    );
};

export default SyllabusComponent;
