import { FC, useContext, useMemo } from 'react';
import { CourseType } from 'types/Courses.type';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import dayjs from 'dayjs';
import './Table.scss';
import { UserContext } from 'contexts/User/UserContext';
import { useParams } from 'react-router-dom';
import NoAssignment from 'assets/img/empty-state/noAssignment.png';
import ActionMenu from './ActionMenu';

interface IViewTable {
    data: CourseType[];
    assignmentSelected: any[];
    setAssignmentSelected: Function;
    isAllowAssign: boolean;
}

const ViewTable: FC<IViewTable> = ({
    data,
    assignmentSelected,
    setAssignmentSelected,
    isAllowAssign,
}) => {
    const { t: translator } = useTranslation();
    const {
        userState: { result },
    } = useContext(UserContext);
    const params: { id } = useParams();
    const workspaceId = params?.id;

    const onSelectAssignment = (assignment) => {
        const hasCheck = assignmentSelected?.find((item) => item?.id === assignment?.id);
        if (hasCheck) {
            setAssignmentSelected(assignmentSelected.filter((item) => item?.id !== assignment.id));
        } else {
            setAssignmentSelected([...assignmentSelected, assignment]);
        }
    };

    const onSelectAllAssignment = () => {
        if (!!assignmentSelected?.length) {
            setAssignmentSelected([]);
        } else setAssignmentSelected(data);
    };

    const columns = useMemo(
        () => [
            isAllowAssign
                ? {
                      Header: () => (
                          <div className="w-full px-2 flex">
                              <input
                                  type="checkbox"
                                  checked={!!assignmentSelected?.length}
                                  onChange={() => onSelectAllAssignment()}
                              />
                          </div>
                      ),
                      Cell: ({ row }: any) => {
                          const hasCheck = assignmentSelected?.find((item) => item?.id === row?.original?.id);
                          return (
                              <div className="px-2 flex w-full">
                                  <input type="checkbox" checked={hasCheck} onChange={() => onSelectAssignment(row?.original)} />
                              </div>
                          );
                      },
                      accessor: 'assignmentSelected',
                      width: '20',
                  }
                : { accessor: 'assignmentSelected', width: '0' },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.ID')} />,
                Cell: ({ row }: any) => {
                    return <RenderTableCell value={row.values?.id} />;
                },
                accessor: 'id',
                width: '30',
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.ASSIGNMENT')} />,
                accessor: 'title',
                width: '120',
                Cell: ({ row }: any) => {
                    return (
                        <RenderTableCell
                            onClick={() => window.open(`/workspace/${workspaceId}/assignment/${row.original?.id}/preview`)}
                            className="text-primary-500 cursor-pointer font-semibold text-ellipsis truncate"
                            value={row.values?.title}
                        />
                    );
                },
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.LESSON')} />,
                accessor: 'lesson',
                width: '120',
                Cell: ({ row }: any) => {
                    return <RenderTableCell className="text-ellipsis truncate" value={row.values?.lesson?.title} />;
                },
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.DATE_ADDED')} />
                ,
                accessor: 'added_at',
                Cell: ({ row }: any) => (
                    <RenderTableCell value={dayjs.utc(row.values?.added_at).tz(result?.time_zone).format('DD/MM/YYYY')} />
                ),
                width: '80',
            },
            {
                Header: () => <></>,
                accessor: 'action',
                width: '20',
                Cell: ({ row }: any) => (
                    <ActionMenu
                        data={row?.original}
                        position={row?.index > data?.length/2 ? 'bottom-0' : ''}
                    />
                ),
            },
        ],
        [data, assignmentSelected, isAllowAssign]
    );

    return <Table data={data || []} columns={columns} emptyContent={translator('COURSES.NO_ASSIGNMENTS')} emptyIcon={NoAssignment} />;
};

export default ViewTable;
