import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PublicLayout } from '_shared';
import LessonPublicPreview from 'components/LessonPublicPreview/LessonPublicPreview';
import lessonService from '../../../services/lesson.service';
import { base64ToObject } from 'utils/commonFun';

export const LessonPublicPage = () => {
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const info: any = searchParams.get("info");

    const [library, setLibrary] = useState<any>();

    useEffect(() => {
        if (info) {
            base64ToObject(info)
                .then(async obj => {
                    const response = await lessonService.playLesson({ uid: obj?.uid });
                    setLibrary(response?.data);
                })
                .catch(error => setLibrary(null));
        }
    }, [info]);

    return (
        <PublicLayout>
            <LessonPublicPreview dataLesson={library} />
        </PublicLayout>
    );
};