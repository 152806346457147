import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';

import { classTypes } from 'constant/classTypes';

import ButtonComponent from 'components/Button/ButtonComponent';
import ErrorMessageForm from 'components/ErrorMessageForm';
import DatePicker from 'components/DatePicker/DatePicker';
import TimePicker from 'components/DatePicker/TimePicker';
import { XIcon } from '@heroicons/react/outline';

type EditSessionModalProps = {
    onClose: () => void;
};

const customSelect: any = {
    clearIndicator: (base) => ({
        ...base,
        padding: 0,
        marginRight: -6,
    }),
    indicatorSeparator: (base) => ({ ...base, display: 'none' }),
    control: (base) => {
        return {
            ...base,
            borderColor: '#EBEBF0',
            boxShadow: 'unset',
            padding: 0,
            paddingLeft: 8,
            minHeight: 34,
            color: '#2E3A59',
            '&:hover': {
                borderColor: '#EBEBF0',
            },
            '&:focus-within': {
                borderColor: '#60a5fa',
            },
        };
    },
    valueContainer: (base) => ({
        ...base,
        padding: 0,
        fontSize: 13,
    }),
    dropdownIndicator: (base, { selectProps }) => ({
        ...base,
        padding: 6,
        transition: 'all 0.3s ease-in-out',
        transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    }),
    input: (base) => ({
        ...base,
        padding: 0,
        margin: 0,
        fontSize: 13,
    }),
    option: (base) => ({
        ...base,
        paddingLeft: '15px',
        fontSize: 13,
    }),
};

const customSelectLesson: any = {
    ...customSelect,
    menuList: (base) => ({
        ...base,
        height: 200,
    }),
    groupHeading: (base) => ({
        ...base,
        color: '#2E3A59',
        fontSize: 13,
        fontWeight: 'bold',
        textTransform: 'none',
    }),
};

const customSelectAssignment: any = {
    ...customSelect,
    menuList: (base) => ({
        ...base,
        height: 190,
    }),
    valueContainer: (base) => ({
        ...base,
        fontSize: 13,
        flexWrap: 'nowrap',
        width: 1,
        padding: 0,
    }),
    multiValue: (base) => ({
        ...base,
        minWidth: 'unset',
    }),
};

const EditSessionModal: FC<EditSessionModalProps> = ({ onClose }) => {
    const { t: translator } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        reset,
        clearErrors,
        control,
        setValue,
        setError,
        watch,
        trigger,
    } = useForm({
        defaultValues: {
            startTime: undefined,
            endTime: undefined,
            lesson: undefined,
            type: undefined,
            assignments: undefined,
            instructors: undefined,
            students: undefined,
        },
    });

    const handleLoadOptions = (inputValue, options, additional) => {
        return {
            options: [],
            hasMore: true,
            additional: {
                page: additional.page + 1,
            },
        };
    };

    return (
        <div className="px-3 text-ooolab_dark_1">
            <div className="mb-5">
                <h1 className="mb-1 font-semibold text-xs">Session Name</h1>
                <input type="text" className="px-2 py-1 rounded border w-full focus-within:border-blue-400" placeholder={''} />
            </div>
            <div className="mb-5 grid grid-cols-2 gap-4">
                <div>
                    <h1 className="mb-1 font-semibold text-xs">Select Lessons</h1>
                    <Controller
                        control={control}
                        name="lesson"
                        rules={{
                            required: {
                                value: true,
                                message: translator('FORM_CONST.REQUIRED_FIELD'),
                            },
                        }}
                        render={() => {
                            return (
                                <AsyncPaginate
                                    loadOptions={handleLoadOptions}
                                    additional={{
                                        page: 1,
                                    }}
                                    debounceTimeout={500}
                                    styles={customSelect}
                                    isMulti
                                    className="w-full"
                                    closeMenuOnSelect={false}
                                    onChange={(newValue: any, actionMeta: any) => {}}
                                    placeholder=""
                                    value={[]}
                                />
                            );
                        }}
                    />
                    <ErrorMessageForm errors={errors} name="lesson" />
                </div>
                <div>
                    <h1 className="mb-1 font-semibold text-xs">Learning type</h1>
                    <Controller
                        control={control}
                        name="type"
                        rules={{
                            required: {
                                value: true,
                                message: translator('FORM_CONST.REQUIRED_FIELD'),
                            },
                        }}
                        render={() => {
                            return (
                                <Select
                                    styles={customSelect}
                                    className="w-full"
                                    options={classTypes}
                                    onChange={(value: any) => {}}
                                    isSearchable={false}
                                    placeholder={translator('CLASSES.CLASS_TYPE')}
                                />
                            );
                        }}
                    />
                    <ErrorMessageForm errors={errors} name="type" />
                </div>
            </div>
            <div className="mb-5 grid grid-cols-2 gap-4">
                <div>
                    <h1 className="mb-1 font-semibold text-xs">Date</h1>
                    <DatePicker selected={new Date()} />
                </div>
                <div className="flex space-x-4">
                    <div className="w-full">
                        <h1 className="mb-1 font-semibold text-xs">Start Time</h1>
                        <TimePicker />
                    </div>
                    <div className="w-full">
                        <h1 className="mb-1 font-semibold text-xs">End Time</h1>
                        <TimePicker />
                    </div>
                </div>
            </div>
            <div className="mb-5 grid grid-cols-2 gap-4">
                <div>
                    <h1 className="mb-1 font-semibold text-xs">Select Instructors</h1>
                    <Controller
                        control={control}
                        name="instructors"
                        rules={{
                            required: {
                                value: true,
                                message: translator('FORM_CONST.REQUIRED_FIELD'),
                            },
                        }}
                        render={() => {
                            return (
                                <AsyncPaginate
                                    loadOptions={handleLoadOptions}
                                    additional={{
                                        page: 1,
                                    }}
                                    debounceTimeout={500}
                                    styles={customSelect}
                                    isMulti
                                    className="w-full"
                                    closeMenuOnSelect={false}
                                    onChange={(newValue: any, actionMeta: any) => {}}
                                    placeholder=""
                                    value={[]}
                                />
                            );
                        }}
                    />
                    <ErrorMessageForm errors={errors} name="instructors" />
                </div>
                <div>
                    <h1 className="mb-1 font-semibold text-xs">Select Learners</h1>
                    <Controller
                        control={control}
                        name="students"
                        rules={{
                            required: {
                                value: true,
                                message: translator('FORM_CONST.REQUIRED_FIELD'),
                            },
                        }}
                        render={() => {
                            return (
                                <AsyncPaginate
                                    loadOptions={handleLoadOptions}
                                    additional={{
                                        page: 1,
                                    }}
                                    debounceTimeout={500}
                                    styles={customSelect}
                                    isMulti
                                    className="w-full"
                                    closeMenuOnSelect={false}
                                    onChange={(newValue: any, actionMeta: any) => {}}
                                    placeholder=""
                                    value={[]}
                                />
                            );
                        }}
                    />
                    <ErrorMessageForm errors={errors} name="students" />
                </div>
            </div>
            <div className="mb-5">
                <h1 className="mb-1 font-semibold text-xs">Add Assignment </h1>
                <div className="flex justify-between items-center space-x-2">
                    <div className="w-full">
                        <Controller
                            control={control}
                            name="assignments"
                            rules={{
                                required: {
                                    value: true,
                                    message: translator('FORM_CONST.REQUIRED_FIELD'),
                                },
                            }}
                            render={() => {
                                return (
                                    <AsyncPaginate
                                        key={watch('lesson')}
                                        loadOptions={handleLoadOptions}
                                        additional={{
                                            page: 1,
                                        }}
                                        debounceTimeout={500}
                                        styles={customSelect}
                                        isMulti
                                        className="w-full"
                                        closeMenuOnSelect={false}
                                        onChange={(newValue: any, actionMeta: any) => {}}
                                        placeholder="Select Assignment"
                                        value={[]}
                                        components={{
                                            Option: ({ children, ...props }) => {
                                                // FIX LAG
                                                const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
                                                const newProps = Object.assign(props, { innerProps: rest });
                                                return (
                                                    <components.Option {...newProps}>
                                                        {children + ' - ' + newProps.data.lessonName}
                                                    </components.Option>
                                                );
                                            },
                                        }}
                                    />
                                );
                            }}
                        />
                        <ErrorMessageForm errors={errors} name="assignments" />
                    </div>
                    <ButtonComponent title="Add" classStyle="primary-button !py-[6px]" onClickButton={() => {}} />
                </div>
            </div>
            <div className="mb-5">
                <h1 className="mb-1 font-semibold text-xs">All Assignment </h1>
                <div className="overflow-y-auto h-24 border rounded flex flex-wrap py-1 px-2">
                    {[].map((el) => (
                        <div key={el.id} className="h-6 inline-flex items-center space-x-1 rounded bg-ooolab_gray_11 px-1 mr-2 mb-2">
                            <span className="text-[10px] font-semibold whitespace-nowrap">{el.label}</span>
                            <XIcon className="w-3 h-3 cursor-pointer hover:text-red-500" onClick={() => {}} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="mt-6 flex justify-center space-x-4">
                <ButtonComponent title="Close" classStyle="secondary-button" onClickButton={() => onClose?.()} />
                <ButtonComponent
                    title="Save"
                    classStyle="primary-button"
                    onClickButton={() => {
                        onClose?.();
                    }}
                />
            </div>
        </div>
    );
};

export default EditSessionModal;
