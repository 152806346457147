import React, { useEffect, useState } from "react";
import SearchBox from 'components/V2/SearchBox';
import { PlusIcon } from '@heroicons/react/outline';
import { useTranslation } from "react-i18next";
import { EditIcon } from "assets/icon/EditIcon";
import { DeleteIcon } from "assets/icon";
import workspaceService from 'services/workspace.service';

import './CustomFields.scss';
import { useParams } from "react-router-dom";
import { CustomFieldsType } from "types/CustomFields.type";
import { LibraryEnum } from "constant/LibraryEnum.const";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { NoContentWithImage } from "components/NoContent/NoContent";
import { ButtonPrimary, ConfirmDeleteModal } from "_shared";
import { CustomFieldsForm } from "./CustomFieldsForm/CustomFieldsForm";
import { Toast } from "utils/sweetAlert";
import { get } from "lodash";
import { checkPermission } from "utils/commonFun";

const CusomFieldItem = ({ value, onDelete, onEdit }) => {
    return <>
        <div className="customFields-item">
            <div className="customFields-name">
                {value?.name}
            </div>
            <div className="customFields-line"></div>
            <div className="customFields-type">
                {
                    value?.type?.map((key, index) => <span key={`${value.id}_${key}`}>
                        {LibraryEnum[key]}
                    </span>)
                }
            </div>
            {checkPermission('libraryContentSetting', 'U') && <div className="customFields-action">
                <button onClick={() => onEdit(value.id)} disabled={!checkPermission('libraryContentSetting', 'U')}>
                    <EditIcon />
                </button>
                <button onClick={() => onDelete(value)} disabled={!checkPermission('libraryContentSetting', 'D')}>
                    <DeleteIcon />
                </button>
            </div>}

        </div>
    </>
}

export const CustomFields = () => {
    const { t: translator } = useTranslation();
    const params: { id: string } = useParams();
    const [keyword, setKeyword] = useState<string>();
    const [customFields, setCustomFields] = useState<CustomFieldsType[]>();
    const [loading, setLoading] = useState(true);
    const [customFieldSelected, setCustomFieldSelected] = useState<CustomFieldsType>(null);
    const [isAddNew, setIsAddNew] = useState(false);
    const [idSelected, setIdSelected] = useState<string>(null);
    const [isSaving, setIsSaving] = useState(false);

    const getCustomFields = async (page: number) => {
        try {
            setLoading(true);
            const body = {
                order: 'desc',
                page: page,
                per_page: 20,
                sort_by: 'created_at',
                q: keyword,
                skip_pagination: true
            }
            if (keyword === '') {
                delete body.q;
            }

            const response: any = await workspaceService.getCustomFieldsService(params.id, body);
            if (response) {
                setCustomFields(response?.data?.items);
            }
            setLoading(false);
        } catch {
            setLoading(false);
        }
    }

    const handleDelete = async (value: boolean) => {
        if (value) {
            try {
                const response = await workspaceService.deleteCustomFieldService(params.id, customFieldSelected.id);
                if (response) {
                    setCustomFields((prev) => {
                        return prev?.filter(item => item.id !== customFieldSelected?.id);
                    });
                }
            } catch {

            }
        }
        setCustomFieldSelected(null);
    }

    const handleSave = async (values) => {
        const data = {
            name: values?.name?.trim(),
            type: values?.type ?? []
        }
        setIsSaving(true);
        if (isAddNew) {
            try {
                const response: CustomFieldsType = await workspaceService.createCustomFieldService(params.id, data);
                if (response) {
                    setCustomFields(prev => {
                        const newData = prev || [];
                        newData.unshift(response);
                        return newData;
                    });
                }
                Toast.fire({
                    icon: 'success',
                    title: translator('LIBRARY.CREATED_SUCCESS', { name: response?.name })
                });
                setIsAddNew(false);
            } catch (error) {
                const code = get(error, 'body_params[0].msg')
                if (code === "DUPLICATE_CUSTOM_FIELD") {
                    handleError('TAG_FIELD.DUPLICATE_TAG_FIELD');
                }
            }
            setIsSaving(false);
            return;
        }
        try {
            setIsSaving(true);
            const response: CustomFieldsType = await workspaceService.updateCustomFieldsService(params.id, idSelected, data);
            if (response) {
                setCustomFields(prev => {
                    prev?.forEach((item, index) => {
                        if (item.id === response.id) {
                            prev[index] = response;
                        }
                    })
                    return prev;
                });
            }
            Toast.fire({
                icon: 'success',
                title: translator('LIBRARY.UPDATED_SUCCESS', { name: response?.name })
            });
            setIdSelected(null);
        } catch (error) {
            const code = get(error, 'response.data.error.body_params[0].msg')
            if (code === "DUPLICATE_CUSTOM_FIELD") {
                handleError('TAG_FIELD.DUPLICATE_TAG_FIELD');
            }
        }
        setIsSaving(false);

    }

    const handleError = (msg: string) => {
        Toast.fire({
            icon: 'error',
            title: translator(msg)
        });
    }

    useEffect(() => {
        if (params?.id) {
            getCustomFields(1);
        }
    }, [params, keyword]);

    return (<>
        <div className="settings-main-actions">
            {checkPermission('libraryContentSetting', 'R') ? <div className="settings-main-search">
                <SearchBox onSubmit={setKeyword} />
            </div> : ''}
            {checkPermission('libraryContentSetting', 'C') ? <ButtonPrimary
                onClick={() => setIsAddNew(true)}
                className="btn-new"
                type="button"
            >
                <span className="btn-new-content flex">
                    <PlusIcon className="icon flex-none w-4 mr-1" /> {translator('NEW')}
                </span>
            </ButtonPrimary> : ''}


        </div>
        {isAddNew ? <div className="customFields-addnew">
            <CustomFieldsForm onCancel={() => setIsAddNew(false)} onSave={handleSave} isLoading={isSaving} /></div> : null
        }
        <div className={`customFields-content ${isAddNew ? 'customFields-content--isAdd' : ''}`}>
            {loading ?
                <div className="flex items-center justify-center mt-8">
                    <CircleSpin className="text-primary-500" />
                </div> :
                customFields?.length > 0 ?
                    <div className="customFields-list">
                        {customFields?.map((item: CustomFieldsType) => <div key={item.id}>
                            {item?.id !== idSelected ?
                                <CusomFieldItem value={item} onDelete={setCustomFieldSelected} onEdit={() => {
                                    setIdSelected(item?.id);
                                }} /> :
                                <CustomFieldsForm values={item} onSave={handleSave} onCancel={() => setIdSelected(null)} isLoading={isSaving} />}
                        </div>)}
                    </div> :
                    <div>
                        <NoContentWithImage content={translator("LIBRARY.NO_CUSTOM_TAGS")} />
                    </div>
            }
        </div>
        <ConfirmDeleteModal onClose={handleDelete} isOpen={!!customFieldSelected}>
            <p>{translator('TAG_FIELD.DELTE_CUSTOM_TAG')} <br />
                {translator('TAG_FIELD.SUB_DELETE_CUSTOM_TAG')}</p>
        </ConfirmDeleteModal>
    </>)
}