import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Transition } from '@headlessui/react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import viLocale from '@fullcalendar/core/locales/vi';
import { SearchIcon } from '@heroicons/react/outline';
import SessionCard from './components/SessionCard';
import CreateCalendarModal from './components/CreateCalendarModal';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import workspaceMiddleware from 'middleware/workspace.middleware';
import './style.scss';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import { LoadingSVG } from 'assets/icon';
import { UserContext } from 'contexts/User/UserContext';
import 'moment/locale/vi';
import dayjs from 'dayjs';
import { SessionDateFormat, SessionServiceFormat } from 'constant/util.const';
import DialogComponent from 'components/Dialog/DialogComponent';
import EditSessionModal from './components/EditSessionModal';
import SessionDetailModal from 'components/V2/SessionDetailModal';
import { SESSION_STATUS_ENUM } from 'constant/sessions.const';

const ClassCalendar: React.FC = () => {
    const { t: translator } = useTranslation();
    const param: { id: string; classId: string } = useParams();
    const { dispatch, getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { sessions, detail } = getWorkspaceDetailState.class;
    const {
        userState: { result: userInformation },
    } = React.useContext(UserContext);
    const { language } = userInformation;

    const [upComingSessions, setUpComingSessions] = useState(null);
    const [eventDates, setEventDates] = useState([]);

    const [isOpenEditSession, setIsOpenEditSession] = useState(false);
    const [inputValue, setInputValue] = useState<string>();
    const [openModal, setOpenModal] = useState(false);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [sessionSelected, setSessionSelected] = useState<any>(false);
    const [isLoading, setIsLoading] = useState(true);
    
    const onChange = (e) => {};
    const setInputField = (value) => {};
    const onSearch = (value) => {};
    const headerToolbar = {
        left: 'today prev,next title',
        right: 'timeGridDay,timeGridWeek,dayGridMonth',
    };
    const eventClick = (data) => {
        setSessionSelected({...data.event?._def?.extendedProps, id: data?.event?._def?.publicId, title: data?.event?._def?.title});
    };
    const handleAddLesson = () => {};
    const renderEventContent = (eventInfo) => {
        if (eventInfo?.view?.type === 'timeGridDay' || eventInfo?.view?.type === 'timeGridWeek') {
            return (
                <>
                    <div className="fc-event-main-frame fc-sticky">
                        <div className="fc-event-title-container">
                            <div className="fc-event-title">{eventInfo.event.title}</div>
                        </div>
                        <div className="fc-event-time">
                            {eventInfo.timeText}
                            <br />
                            {eventInfo.event.extendedProps.type == 'online' && translator('CLASSES.ONLINE_SESSION')}
                            {eventInfo.event.extendedProps.type == 'offline' && translator('CLASSES.OFFLINE_SESSION')}
                        </div>
                    </div>
                </>
            );
        }
    };

    const getSessions = async () => {
        await workspaceMiddleware.getClassSessions(dispatch, {
            classId: param.classId,
            workspaceId: param.id,
            params: {
                skip_pagination: true,
                sort_by: 'start_time',
                order: 'asc',
            },
        });
    };
    

    useEffect(() => {
        getSessions();
        setIsLoading(false);
    }, []);

    useEffect(() => {
        let upcoming = sessions.items.filter((item) => item?.display_status === SESSION_STATUS_ENUM.NOT_STARTED);
        if (upcoming?.length > 0) {
            upcoming = upcoming.map((item) => {
                const parseStartDate = dayjs.utc(item.start_time).tz(userInformation.time_zone);
                const parseEndDate = dayjs.utc(item.end_time).tz(userInformation.time_zone);
                return {
                    ...item,
                    classTitle: detail?.name,
                    dateFormat: parseStartDate.format('MMMM D YYYY'),
                    start_time: parseStartDate.format(SessionDateFormat),
                    end_time: parseEndDate.format(SessionDateFormat),
                    startTimeInit: item?.start_time,
                    endTimeInit: item?.end_time,
                };
            });
        }
        const grouped = _.mapValues(_.groupBy(upcoming, 'dateFormat'), (clist) => clist.map((item) => _.omit(item, 'dateFormat')));
        setUpComingSessions(grouped);
        const mappingEventData = sessions?.items?.map((item) => {
            const parseStartDate = dayjs.utc(item.start_time).tz(userInformation.time_zone);
            const parseEndDate = dayjs.utc(item.end_time).tz(userInformation.time_zone);
            return {
                ...item,
                title: detail?.name,
                startTimeInit: item?.start_time,
                endTimeInit: item?.end_time,
                start: parseStartDate.format(SessionServiceFormat),
                end: parseStartDate.format(SessionServiceFormat),
                start_time: parseStartDate.format(SessionDateFormat),
                end_time: parseEndDate.format(SessionDateFormat),
            };
        });
        setEventDates(mappingEventData);
    }, [sessions]);

    useEffect(() => {
        moment.locale(language == 'vi' ? 'vi' : 'en');
        return () => {
            moment.locale('en');
        };
    }, []);

    return (
        <div className="calendar-wrapper py-4">
            <div className="flex space-x-10">
                <div className="w-2/6">
                    <div className="border-b border-ooolab_gray_6 w-full pb-4">
                        <h2 className="text-semibold text-base text-ooolab_dark_1 mb-6">{translator('DASHBOARD.TEACHER.UPCOMING')}</h2>
                    </div>
                    {isLoading ? (
                        <div className="flex mt-4 items-center justify-center">
                            <LoadingSVG className="text-gray" />
                        </div>
                    ) : (
                        <div className="upcoming-section space-y-6 py-2 custom-scrollbar">
                            {!_.isEmpty(upComingSessions) &&
                                Object.keys(upComingSessions).map((key, index) => {
                                    return (
                                        <div key={index}>
                                            <p className="text-sm text-ooolab_282B5E mb-3">
                                                {language == 'vi'
                                                    ? moment(upComingSessions[key][0].start_time).format('dddd, D MMMM YYYY')
                                                    : moment(upComingSessions[key][0].start_time).format('dddd, MMMM D YYYY')}
                                            </p>
                                            {upComingSessions[key] &&
                                                upComingSessions[key].map((item) => {
                                                    return (
                                                        <SessionCard 
                                                            key={item.id} 
                                                            data={item} 
                                                            handleModal={() => {
                                                                setSessionSelected(item)
                                                                setOpenModal(true)
                                                            }} 
                                                        />
                                                    );
                                                })}
                                        </div>
                                    );
                                })}
                            {_.isEmpty(upComingSessions) && <NoContentWithImage content={translator('CLASSES.NO_EVENTS')} />}
                        </div>
                    )}
                </div>
                <div className="w-4/6">
                    <div className="flex pb-4 border-b">
                        <div className="max-w w-full max-w-xs">
                            <div className="relative w-full h-[32px] overflow-hidden">
                                <Transition
                                    show={true}
                                    enter="transition ease-out duration-100"
                                    enterFrom="w-6 opacity-0"
                                    enterTo="w-full opacity-100"
                                    leave="transition ease-out duration-100"
                                    leaveFrom="w-full opacity-80"
                                    leaveTo="w-6 opacity-0"
                                >
                                    <input
                                        className={`border border-ooolab_border_logout  block w-full pl-8 py-1 leading-5 bg-white placeholder-gray-500 text-xs  overflow-hidden  focus:outline-none h-[32px]`}
                                        type="text"
                                        placeholder={translator('SEARCH')}
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                            if (e.target.value == '') {
                                                setInputField({
                                                    search: undefined,
                                                });
                                                onSearch(e.target.value);
                                            }
                                        }}
                                        onKeyDown={(e: any) => {
                                            if (e.key === 'Enter') {
                                                onSearch(e.target.value);
                                            }
                                        }}
                                    />
                                </Transition>
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center cursor-pointer">
                                    <SearchIcon
                                        onClick={() => onSearch(inputValue)}
                                        className={`w-4 h-4
                                `}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="pt-5">
                        {/* <FullCalendar
                            allDaySlot={false}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            headerToolbar={headerToolbar}
                            events={eventDates}
                            dayMaxEvents={3}
                            eventClick={(data: any) => {
                                setSessionSelected({...data.event?._def?.extendedProps, id: data?.event?._def?.publicId, title: data?.event?._def?.title});
                                setOpenModal(true)
                            }}
                            eventContent={renderEventContent}
                            timeZone={detail?.time_zone}
                            locale={language == 'vi' ? viLocale : 'en'}
                        /> */}
                    </div>
                </div>
            </div>
            <CreateCalendarModal open={openCreateModal} onCloseModal={() => setOpenCreateModal(false)} onSubmit={handleAddLesson} />
            <SessionDetailModal
                sessionId={sessionSelected?.id}
                onCloseModal={() => setOpenModal(false)}
                open={openModal}
                refreshData={getSessions}
            />
            <DialogComponent
                title="Edit Session"
                styles="max-w-lg"
                isOpen={isOpenEditSession}
                onCloseModal={() => setIsOpenEditSession(false)}
                child={<EditSessionModal onClose={() => setIsOpenEditSession(false)} />}
            />
        </div>
    );
};

export default ClassCalendar;
