export const STATUS_LEARNER_ENROLL_OPTION = (t) => [
    { name: t('Enrolled'), value: 'enrolled' },
    { name: t('ACTIVE'), value: 'active' },
    { name: t('Expired'), value: 'expired' },
    { name: t('DEACTIVATED'), value: 'deactivated' },
];

export const STATUS_INSTRUCTOR_OPTION = (t) => [
    { name: t('ACTIVE'), value: 'active' },
    { name: t('DEACTIVATED'), value: 'deactivated' },
];

export const ENROLLMENT_TYPE_OPTION = (t) => [
    { name: t('SESSION.INDIVIDUAL'), value: 'individual' },
    { name: t('LEARNING_GROUP.LEARNING_GROUP'), value: 'learning_group' },
];

export const ENROLLMENT_STATUS = {
    ENROLLED: 'enrolled',
    ACTIVE: 'active',
    DEACTIVATED: 'deactivated',
    EXPIRED: 'expired',
};

export const ENROLLMENT_TYPE = {
    INDIVIDUAL: 'individual',
    LEARNING_GROUP: 'learning_group',
};

export const COURSE_STATUS = {
    INACTIVE: 'inactive',
    ACTIVE: 'active',
};

export const LEARNING_GROUP = [
    { name: 'Learners', label: 'Learners', value: 'learners' },
    { name: 'Learning Groups', label: 'Learning Groups', value: 'learning_group' },
];
