import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionsButton from 'components/ActionsButton';
import DeactivateIcon from 'assets/icon/DeactivateIcon';
import { ActiveIcon } from 'assets/icon/ActiveIcon';
import ModalChangeStatusMember from './ModalChangeStatusMember';
import { useAppSelector } from 'hooks/hooks';
import { checkPermission } from 'utils/commonFun';
import { ENROLLMENT_STATUS } from '../../../constants';
import { EyeIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';

interface ActionMenuProp {
    data: any;
    refreshData: Function
}

const ActionMenu: FC<ActionMenuProp> = ({
    data,
    refreshData
}) => {
    const { t } = useTranslation();
    const [isOpenModalChangeStatus, setOpenModalChangeStatus] = useState(false);
    const { enrollmentSelected, setting } = useAppSelector((state) => state.course);
    const params: {id, courseId} = useParams();
    return (
        <>
            <ActionsButton
                styleOptions="top-0 right-[35px]"
                menus={[
                    {
                        label: t("COURSES.VIEW_CREDIT_BALANCE"),
                        icon: <EyeIcon className='w-5 h-5' />,
                        action: () => window.open(`/workspace/${params?.id}/management/courses/${params?.courseId}?tab=credit-balance&enrollment_id=${enrollmentSelected?.id}&membership_id=${data?.membership_id}`, '_blank'),
                        class: `border-b border-gray-300`,
                        hide: setting?.enrollment_type !== 'credit'
                    },
                    {
                        label: t("COURSES.ACTIVATE"),
                        icon: <ActiveIcon />,
                        isDisabled: enrollmentSelected?.display_status === ENROLLMENT_STATUS.DEACTIVATED,
                        hide: data?.status !== 'deactivated' || !checkPermission('coursePeopleEnrollment', 'U'),
                        action: () =>setOpenModalChangeStatus(true),
                        class: `border-b border-gray-300`,
                    },
                    {
                        label: t("COURSES.DEACTIVATE"),
                        icon: <DeactivateIcon />,
                        isDisabled: enrollmentSelected?.display_status === ENROLLMENT_STATUS.DEACTIVATED,
                        action: () => setOpenModalChangeStatus(true),
                        hide: data?.status === 'deactivated' || !checkPermission('coursePeopleEnrollment', 'U'),
                        class: `border-b border-gray-300 text-red-500`,
                        contentTip: ''
                    },
                ]}
            />
            <ModalChangeStatusMember
                isOpen={isOpenModalChangeStatus}
                onClose={() => setOpenModalChangeStatus(false)}
                data={data}
                refreshData={refreshData}
             />
        </>
    );
};

export default ActionMenu;
