import React, { useEffect, useReducer, useState } from 'react';
import { AuthContext } from './AuthContext';
import reducers from 'reducers';
import { initAuthState } from 'state/Auth/auth.state';
import { getWhiteLabelList } from 'services/user.service';

const AuthProvider: React.FC = ({ children }) => {
    const [authState, dispatch] = useReducer(
        reducers.authReducer,
        initAuthState
    );
    const [config, setConfig] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getWhiteLabels = async () => {
          try {
            setLoading(true);
            const res = await getWhiteLabelList({ domain: window?.location?.hostname });
            if (res?.data?.items?.length) {
              setConfig(res?.data?.items);
            }
          } catch (error) {
          } finally {
            setLoading(false);
          }
        }
        getWhiteLabels();
      }, []);
    
    return (
        /*@ts-ignore*/
        <AuthContext.Provider value={{ authState, dispatch, config }}>
            {loading ? <div id="loader" className="loader"></div> : children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
