export const mockGroupsData = [
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
    {
        id: 'S0123456789',
        name: 'D7 PMH',
        parent_name: 'Parent Trung Chính',
        status: 'In Progress',
        center: 'Đặng and Sons',
        created: '28-10-2021',
        email: 'english@1on1english.vn',
        location: 'HCM',
        classroom_name: 'The Fishbowl',
        group_name: 'D7 PMH',
        size: '10-20',
        classname: 'Starter Beginner Class',
        instructor_name: 'Nguyễn Văn A',
        student_name: 'Nguyễn Văn B',
    },
];

export const mockTeacherData = [
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },

    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
    {
        id: 'S0123456789',
        instructor_name: '1on1 Nguyen',
        email_address: 'teacher@1on1english.vn',
        status: 'Active',
        email: 'english@1on1english.vn',
        group_name: 'D7 PMH',
    },
];
