import { useBoolean } from 'hooks/custom';
import Table from 'components/Management/components/table';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import workspaceMiddleware from 'middleware/workspace.middleware';
import React, { Fragment, useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Column } from 'react-table';
import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { UserContext } from 'contexts/User/UserContext';
import { ACTIONS, ActionType, editStateReducer, EditStateType, initSessionState, invalidSession, updateSession } from './util';
import { IClassSessionParams, ICLassSession } from 'types/Class.type';

import classService from 'services/class.service';
import { EditableEndCell, EditableStartCell, RenderGroupsHeader, IndeterminateCheckbox } from './components/TableCustom';
import { SessionDateFormat, SessionServiceFormat } from 'constant/util.const';
import lodash, { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { formatTimezone } from 'components/User/AccountSettingForm/AccountSettingFormFn';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import ModalSession from 'components/Workspace/CourseDetail/component/ModalSession';
import workspaceService from 'services/workspace.service';
import ModalImportCourse from '../../../components/ModalImportCourse/index';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { errorNoti } from 'components/H5P/H5PFN';
import { ToastContainer } from 'react-toastify';
import SessionOptions from './components/SessionOptions';
import DialogComponent from 'components/Dialog/DialogComponent';
import { classNames, getIndexPage } from 'utils/commonFun';
import ReactTooltip from 'react-tooltip';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import Pagination from 'components/V2/Pagination';
import SessionDetailsModal from 'components/V2/SessionDetailModal';
import ModalCancel from 'components/V2/SessionDetailModal/ModalCancel';
import { SCHEDULE_TYPE, SESSION_STATUS } from 'constant/sessions.const';
import { CLASS_TYPE } from '../util';
import { NoContentWithImage } from 'components/NoContent/NoContent';

dayjs.extend(utc);
dayjs.extend(CustomParseFormat);
dayjs.extend(timezone);

const FormatColumnValue = ({ children }) => (
    <p className="overflow-ellipsis whitespace-nowrap overflow-hidden pl-2 py-ooolab_p_3 font-normal text-ooolab_dark_1 text-ooolab_1xs leading-ooolab_24px">
        {children}
    </p>
);

const SingleValue = ({ children, ...props }: any) => {
    const param: { id: string } = useParams();
    return (
        <components.SingleValue {...props}>
            <Link className="hover:underline text-primary-500" to={`/workspace/${param.id}/lesson/${props.data.value}`}>
                {children}
            </Link>
        </components.SingleValue>
    );
};

const ClassSession = ({ sessionName, classType }) => {
    const { t: translator } = useTranslation();
    const [isOpenModalDetailSession, setOpenModalDetailSession] = useState(false);
    const [sessionSelected, setSessionSelected] = useState<any>({});
    const param: { id: string; classId: string } = useParams();
    const { dispatch, getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const {
        userState: { result },
    } = useContext(UserContext);
    const { sessions, loading } = getWorkspaceDetailState.class;
    const {
        membership: { role, type },
    } = getWorkspaceDetailState.result;
    const { page, per_page, total, items } = sessions;

    const [loadingSave, setLoadingSave] = useState(false);
    const toggleLoadingSave = (b?: boolean) => {
        setLoadingSave(b !== undefined ? b : !loadingSave);
    };
    const [indexPage, setIndexPage] = useState('1-50');
    const [sessionsState, setSessionsState] = useState<IClassSessionParams>(initSessionState);
    const [sessionData, setSessionData] = useState<ICLassSession[]>([]);
    const [editSessionState, dispatchEditSessionState] = useReducer<React.Reducer<EditStateType, ActionType>>(editStateReducer, {});
    const [canSave, setCanSave] = useState(false);
    const [openNewSession, setOpenNewSession] = useState(undefined);
    const [openImport, setOpenImport] = useState(false);
    const [instructorsStudents, setInstructorsStudents] = useState<{instructors: any,students: any}>(null);
    const featureFlags = JSON.parse(process.env.REACT_APP_FEATURE_FLAGS);

    useEffect(() => {
        setCanSave(
            !!Object.keys(editSessionState)?.length &&
                !lodash.isEqual(
                    sessionData.map((i) => ({
                        start_time: i.start_time,
                        end_time: i.end_time,
                        lesson: i.lesson?.id,
                        id: i.id,
                    })),
                    sessions.items.map((i) => {
                        const parseStartDate = dayjs.utc(i.start_time).tz(result?.time_zone);
                        const parseEndDate = dayjs.utc(i.end_time).tz(result?.time_zone);

                        return {
                            id: i.id,
                            start_time: parseStartDate.format(SessionDateFormat),
                            end_time: parseEndDate.format(SessionDateFormat),
                            lesson: i.lesson?.id,
                        };
                    })
                )
        );
    }, [editSessionState, sessionData]);

    const handleEditSessionStartDateRecord = (d: { id: number; value: string; index: number }) => {
        dispatchEditSessionState({
            type: ACTIONS.SET_NEW_START_RECORD,
            value: {
                id: d.id,
                start_time: d.value,
                index: d.index,
            },
        });
    };

    const handleEditSessionEndDateRecord = (d: { id: number; value: string; index: number }) => {
        dispatchEditSessionState({
            type: ACTIONS.SET_NEW_END_RECORD,
            value: {
                id: d.id,
                end_time: d.value,
                index: d.index,
            },
        });
    };

    const handleEditSessionLessonRecord = (d: { id: number; value: any; index: number }) => {
        dispatchEditSessionState({
            type: ACTIONS.SET_NEW_LESSON_RECORD,
            value: {
                id: d.id,
                lesson: d.value,
                index: d.index,
            },
        });
    };

    const handleResetSessionData = () => {
        workspaceMiddleware.getClassSessions(dispatch, {
            classId: param.classId,
            workspaceId: param.id,
            params: sessionsState,
        });
        dispatchEditSessionState({
            type: ACTIONS.RESET,
            value: { id: -1 },
        });
    };

    const { booleanValue: isRemoveSession, toggleBooleanValue: toggleIsRemoveSession } = useBoolean();
    const sessionRef = useRef<any>(null);

    useEffect(() => {
        workspaceMiddleware.getClassSessions(dispatch, {
            classId: param.classId,
            workspaceId: param.id,
            params: sessionsState,
        });
    }, [sessionsState]);

    useEffect(() => {
        if (!toggleIsRemoveSession) {
            sessionRef.current = null;
        }
    }, [toggleIsRemoveSession]);

    useEffect(() => {
        const listKeys = Object.keys(editSessionState);

        if (listKeys.length) {
            const copiedSessionData = [...sessionData];
            listKeys.forEach((element) => {
                const editElement = editSessionState[element];
                if (editElement && copiedSessionData[editElement.index]) {
                    copiedSessionData[editElement.index] = {
                        ...copiedSessionData[editElement.index],
                        ...editElement,
                    };
                }
            });
            setSessionData(copiedSessionData);
            collapseTags();
        }
    }, [editSessionState]);

    useEffect(() => {
        const formatData = sessions?.items?.map((i) => {
            const parseStartDate = dayjs.utc(i.start_time).tz(result?.time_zone);
            const parseEndDate = dayjs.utc(i.end_time).tz(result?.time_zone);
            return {
                id: i.id,
                start_time: parseStartDate.format(SessionDateFormat),
                end_time: parseEndDate.format(SessionDateFormat),
                lesson: i.lesson,
                type: i.type,
                startTimeInit: i.start_time,
                endTimeInit: i.end_time,
                assignments: i.assignments,
                instructors: i.instructors,
                students: i.students,
                vc_link: i.vc_link,
                learning_path: i?.learning_path,
                schedule_type: i?.schedule_type,
                status: i?.status,
                display_status: i?.display_status,
                my_class: i?.my_class
            };
        });
        setIndexPage(getIndexPage(page, per_page, items?.length));
        setSessionData(formatData);
        collapseTags();
        dispatchEditSessionState({ type: ACTIONS.RESET, value: { id: -1 } });
    }, [sessions]);

    useEffect(() => {
        const getInstructorsStudent = async () => {
            try {
                const [instructors, student] = await Promise.all([
                    workspaceService.getClassesMembers(param.id, param.classId, {
                        type: 'instructor',
                        skip_pagination: true
                    }),
                    workspaceService.getClassesMembers(param.id, param.classId, {
                        type: 'student',
                        skip_pagination: true
                    })
                ]);
                if (instructors?.data.items?.length > 0) {
                    setInstructorsStudents(prev => ({
                        ...prev,
                        instructors: instructors?.data.items
                    }));
                }
                if (student?.data.items?.length > 0) {
                    setInstructorsStudents(prev => ({
                        ...prev,
                        students: student?.data.items
                    }));
                }
            } catch {}
        }
        getInstructorsStudent();
        return setSessionData([]);
    }, []);

    const handleChangePagination = (e: number) => {
        setSessionData([]);
        setSessionsState((prev) => ({
            ...prev,
            page: e,
        }));
    };

    const getSessionList = () => {
        workspaceMiddleware.getClassSessions(dispatch, {
            classId: param.classId,
            workspaceId: param.id,
            params: sessionsState,
        });
    };

    const handleUpdateSession = async () => {
        toggleLoadingSave();
        const listIds = Object.keys(editSessionState);
        const result = [];
        for (const i of listIds) {
            const start_time = editSessionState[i].start_time
                ? dayjs(editSessionState[i].start_time).format(SessionServiceFormat)
                : dayjs(sessionData[editSessionState[i].index].start_time).format(SessionServiceFormat);
            const end_time = editSessionState[i].end_time
                ? dayjs(editSessionState[i].end_time).format(SessionServiceFormat)
                : dayjs(sessionData[editSessionState[i].index].end_time).format(SessionServiceFormat);
            const lesson = editSessionState[i].lesson?.id || sessionData[editSessionState[i].index].lesson?.id;
            result.push(
                await updateSession(param.id, param.classId, i, {
                    end_time,
                    start_time,
                    lesson_id: lesson,
                    type: editSessionState[i].type || sessionData[editSessionState[i].index]?.type,
                    assignments: lesson ? [] : undefined,
                    import_assignment_from_lesson: lesson ? true : false,
                })
            );
        }
        handleResetSessionData();
        setTimeout(() => {
            toggleLoadingSave(false);
            dispatchEditSessionState({
                type: ACTIONS.RESET,
                value: { id: -1 },
            });
        }, 500);
    };

    // start_time < now => true (not edit)
    const checkEditRecord = (values: any) => dayjs(values.startTimeInit).utc().format() < dayjs().utc().format();

    const timeDisplay = (date: string) => dayjs(date).utc().tz(result?.time_zone).format(SessionDateFormat);

    const initialState = {
        hiddenColumns: ['type', 'startTimeInit', 'endTimeInit'],
    };

    const counterElementWidth = 35;
    const imgWidth = 27;
    const collapseTags = () => {
        setTimeout(() => {
            document.querySelectorAll('.apr').forEach((apr) => {
                const counterTag = apr.querySelector('.counter-tagg');

                const aprStyle = getComputedStyle(apr);
                const padding = parseFloat(aprStyle.paddingLeft) + parseFloat(aprStyle.paddingRight);
                const innerWidth = apr.clientWidth - padding;
                let usedWidth = 0;
                let tagsLeft = 0;

                apr.querySelectorAll('.tagg').forEach((tag) => {
                    usedWidth += tag.getBoundingClientRect().width;
                    if (apr.querySelectorAll('.tagg').length === 1) {
                        tag.classList.add('max-w-max', 'truncate');
                    } else if (usedWidth + counterElementWidth >= innerWidth) {
                        tag.classList.add('hidden');
                        tagsLeft++;
                    }
                });

                if (tagsLeft > 0) {
                    counterTag.classList.remove('hidden');
                    counterTag.innerHTML = `${tagsLeft}+`;
                }
            });
            document.querySelectorAll('.boxTeachers').forEach((parent) => {
                const counterTag = parent.querySelector('.counter-instructor');

                const parentStyle = getComputedStyle(parent);
                const padding = parseFloat(parentStyle.paddingLeft) + parseFloat(parentStyle.paddingRight);
                const innerWidth = parent.clientWidth - padding;
                let usedWidth = 0;
                let tagsLeft = 0;

                parent.querySelectorAll('.img-instructor').forEach((tag) => {
                    usedWidth += tag.getBoundingClientRect().width;
                    if (usedWidth + imgWidth >= innerWidth) {
                        tag.classList.add('hidden');
                        tagsLeft++;
                    }
                });

                if (tagsLeft > 0) {
                    counterTag.classList.remove('hidden');
                    counterTag.classList.add('flex');
                    counterTag.innerHTML = `+${tagsLeft}`;
                }
            });
            document.querySelectorAll('.boxStudents').forEach((parent) => {
                const counterTag = parent.querySelector('.counter-student');

                const parentStyle = getComputedStyle(parent);
                const padding = parseFloat(parentStyle.paddingLeft) + parseFloat(parentStyle.paddingRight);
                const innerWidth = parent.clientWidth - padding;
                let usedWidth = 0;
                let tagsLeft = 0;

                parent.querySelectorAll('.img-student').forEach((tag) => {
                    usedWidth += tag.getBoundingClientRect().width;
                    if (usedWidth + imgWidth >= innerWidth) {
                        tag.classList.add('hidden');
                        tagsLeft++;
                    }
                });

                if (tagsLeft > 0) {
                    counterTag.classList.remove('hidden');
                    counterTag.classList.add('flex');
                    counterTag.innerHTML = `+${tagsLeft}`;
                }
            });
        }, 200);
    };

    const columns: Column<any>[] = React.useMemo(
        () => [
            {
                Header: ({ getToggleAllRowsSelectedProps }: { getToggleAllRowsSelectedProps: any }) => (
                    <div className="flex items-center justify-between border-ooolab_bar_color">
                        <RenderGroupsHeader value={translator('CLASSES.SESSION_ID')} />
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                ),
                accessor: 'id',
                Cell: ({ row }) => (
                    <div className="flex items-center justify-between h-full py-ooolab_p_3 font-normal">
                        <p
                            className="text-primary-500 text-ooolab_xs font-semibold cursor-pointer"
                            onClick={() => {
                                setSessionSelected(row.original);
                                setOpenModalDetailSession(true);
                            }}
                        >
                            {row.values.id}
                        </p>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                ),
                width: 90,
            },
            {
                Header: () => (
                    <div className="pl-2 h-full flex items-center">
                        <RenderGroupsHeader value={translator('CLASSES.START_DATETIME')} />
                    </div>
                ),
                accessor: 'start_time',
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    if ((role === 'admin' || type === 'instructor') && !checkEditRecord(row.values) && !invalidSession(row?.original)) {
                        return (
                            <div className="py-ooolab_p_3">
                                <EditableStartCell
                                    key={`start-${row.values.id}`}
                                    value={row.values.start_time}
                                    row={row}
                                    column={column}
                                    updateMyData={handleEditSessionStartDateRecord}
                                />
                            </div>
                        );
                    }
                    return <FormatColumnValue key={row.values.id}>{timeDisplay(row.values.startTimeInit)}</FormatColumnValue>;
                },
                width: 152,
            },
            {
                Header: () => (
                    <div className="pl-2 h-full flex items-center">
                        <RenderGroupsHeader value={translator('CLASSES.END_DATETIME')} />
                    </div>
                ),
                accessor: 'end_time',
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return <FormatColumnValue key={row.values.id}>{timeDisplay(row.values.endTimeInit)}</FormatColumnValue>;
                },
                width: 152,
            },
            {
                Header: () => (
                    <div className="pl-2 h-full flex items-center">
                        <RenderGroupsHeader value={translator('CLASSES.TEACHER')} />
                    </div>
                ),
                accessor: 'instructors',
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return (
                        <div className="boxTeachers w-full h-full flex -space-x-1 items-center pl-2">
                            {row.values?.instructors?.map((item, index) => (
                                <Fragment key={item?.id}>
                                    <img
                                        key={index}
                                        alt=""
                                        className="img-instructor w-7 h-7 min-w-[28px] border rounded-full border-ooolab_light_100"
                                        src={item.avatar_url}
                                        data-tip={item.display_name}
                                        data-for={row.values.id}
                                    />
                                    {
                                        //@ts-ignore
                                        <ReactTooltip
                                            place="top"
                                            type="dark"
                                            effect="float"
                                            id={row.values.id.toString()}
                                            getContent={(content) => <div>{content}</div>}
                                        />
                                    }
                                </Fragment>
                            ))}
                            <span className="counter-instructor hidden items-center justify-center w-7 h-7 font-medium text-sm text-ooolab_dark_2 border rounded-full bg-ooolab_bg_logout_hover border-ooolab_light_100 truncate"></span>
                        </div>
                    );
                },
                width: 150,
            },
            {
                Header: () => (
                    <div className="pl-2 h-full flex items-center">
                        <RenderGroupsHeader value={translator('CLASSES.STUDENT')} />
                    </div>
                ),
                accessor: 'students',
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return (
                        <div className="boxStudents w-full h-full flex -space-x-1 items-center pl-2">
                            {row.values?.students?.map((item: any, index) => {
                                let tooltipContent = item.display_name;
                                if (item?.nick_name) {
                                    tooltipContent += '  ' + item.nick_name;
                                }
                                return (
                                    <Fragment key={item?.id}>
                                        <img
                                            key={index}
                                            alt=""
                                            className="img-student w-7 h-7 min-w-[28px] border rounded-full border-ooolab_light_100"
                                            src={item.avatar_url}
                                            data-tip={tooltipContent}
                                            data-for={row.values.id}
                                        />
                                        {
                                            //@ts-ignore
                                            <ReactTooltip
                                                place="top"
                                                type="dark"
                                                effect="float"
                                                id={row.values.id.toString()}
                                                getContent={(content) => <div>{content}</div>}
                                            />
                                        }
                                    </Fragment>
                                );
                            })}
                            <span className="counter-student hidden items-center justify-center w-7 h-7 font-medium text-sm text-ooolab_dark_2 border rounded-full bg-ooolab_bg_logout_hover border-ooolab_light_100 truncate"></span>
                        </div>
                    );
                },
                width: 150,
            },
            {
                Header: () => (
                    <div className="pl-2 h-full flex items-center">
                        <RenderGroupsHeader value={translator('LESSON.TITLE')} />
                    </div>
                ),
                accessor: 'lesson',
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    const [lessons, setLessons] = useState([]);
                    const [showClear, setShowClear] = useState(false);
                    const [keyword, setKeyword] = useState('');
                    const update = useCallback((inputValue: string) => setKeyword(inputValue), []);
                    const loadLessons = useCallback(async () => {
                        if (keyword) {
                            const data = await workspaceService.getLessonGroupByCourse(param.id, keyword);
                            setLessons(
                                data?.items?.map((item: any) => ({
                                    label: item.title,
                                    options: item.lessons.map((lesson: any) => ({
                                        label: lesson.title,
                                        value: lesson.id,
                                    })),
                                })) || []
                            );
                        } else {
                            setLessons([]);
                        }
                    }, [keyword]);
                    useEffect(() => {
                        loadLessons();
                    }, [keyword]);
                    const debounceInput = useCallback(
                        debounce((nextValue: string, asyncFunction: (inputValue: string) => void) => {
                            asyncFunction(nextValue);
                        }, 1000),
                        []
                    );
                    if ((role === 'admin' || type === 'instructor') && !checkEditRecord(row.values) && false) {
                        return (
                            <CreatableSelect
                                key={`select_${row.values.id}`}
                                className="text-ooolab_1xs py-ooolab_p_3"
                                placeholder=""
                                styles={{
                                    control: (styles) => ({
                                        ...styles,
                                        borderColor: 'transparent',
                                    }),
                                    menu: (base) => ({
                                        ...base,
                                        width: 300,
                                    }),
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                    }),
                                    option: (base) => ({
                                        ...base,
                                        paddingLeft: '30px',
                                    }),
                                    groupHeading: (base) => ({
                                        ...base,
                                        color: '#2E3A59',
                                        fontSize: 13,
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                    }),
                                    singleValue: (base) => ({
                                        ...base,
                                        zIndex: 60,
                                    }),
                                }}
                                onChange={(newValue: any, actionMeta: any) => {
                                    if (newValue?.value) {
                                        handleEditSessionLessonRecord({
                                            id: +row.values.id,
                                            value: { id: +newValue.value },
                                            index: row.index,
                                        });
                                    } else {
                                        handleEditSessionLessonRecord({
                                            id: +row.values.id,
                                            value: undefined,
                                            index: row.index,
                                        });
                                    }
                                }}
                                onInputChange={(inputValue: string, actionMeta: any) => {
                                    if (actionMeta.action === 'input-change' && inputValue !== keyword) {
                                        debounceInput(inputValue, update);
                                    }
                                }}
                                isLoading={false}
                                isSearchable
                                isClearable={showClear}
                                onMenuOpen={() => setShowClear(true)}
                                onMenuClose={() => setShowClear(false)}
                                noOptionsMessage={(e) => {
                                    if (e.inputValue) {
                                        return 'No options';
                                    }
                                    return (
                                        <div className=" flex h-40 justify-center items-center">Type something to search lesson</div>
                                    );
                                }}
                                isValidNewOption={(inputValue: string, value: any, options: any, accessors: any) => false}
                                menuPosition="fixed"
                                value={
                                    lessons.length === 0 && row.values.lesson
                                        ? {
                                              value: row.values.lesson.id,
                                              label: row.values.lesson.title,
                                          }
                                        : undefined
                                }
                                components={{
                                    IndicatorSeparator: () => null,
                                    DropdownIndicator: () => null,
                                    SingleValue: row?.values?.lesson ? SingleValue : () => null,
                                }}
                                options={lessons}
                                menuPlacement="auto"
                            />
                        );
                    }
                    return <FormatColumnValue key={row?.values?.id}>{row?.values?.lesson?.title}</FormatColumnValue>;
                },
                width: 150,
                zIndex: true,
            },
            { accessor: 'type' },
            { accessor: 'startTimeInit' },
            { accessor: 'endTimeInit' },
            {
                Header: () => (
                    <div className="pl-2 h-full flex items-center">
                        <RenderGroupsHeader value={translator('CLASSES.ACTIVE_APP_REVIEW')} />
                    </div>
                ),
                accessor: 'assignments',
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return (
                        <div className="apr w-full h-full flex pl-2 space-x-2 items-center">
                            {row.values.assignments?.map((el: any) => (
                                <div
                                    key={el?.id}
                                    className={classNames(
                                        'tagg text-primary-500 text-ooolab_1xs font-normal whitespace-nowrap max-w-[100px] truncate',
                                        el.name?.length > 5 ? 'min-w-[80px]' : el.name?.length > 15 ? 'min-w-[100px]' : 'min-w-[25px]'
                                    )}
                                >
                                    {el.name}
                                </div>
                            ))}
                            <div className="counter-tagg hidden rounded bg-ooolab_dark_50 text-xs font-semibold px-1"></div>
                        </div>
                    );
                },
                width: 200,
            },

            {
                Header: () => (
                    <div className="pl-2 h-full flex items-center">
                        <RenderGroupsHeader value={translator('CLASSES.SESSION_TYPE')} />
                    </div>
                ),
                accessor: 'schedule_type',
                Cell: ({ row, column }: { row: any; column: Column }) => {
                    return (
                        <div className="h-full flex items-center capitalize text-dark-300 text-xs">
                            {SCHEDULE_TYPE(translator)?.find((type) => type.value === row?.original?.schedule_type)?.label}
                        </div>
                    );
                },
                width: 152,
            },
            {
                Header: () => (
                    <div className="pl-2 h-full flex items-center">
                        <RenderGroupsHeader value={translator('STATUS')} />
                    </div>
                ),
                accessor: 'display_status',
                Cell: ({ row, column }: { row: any; column: Column }) => {
                    return (
                        <div className="h-full flex items-center ml-2">
                            <p
                                className={`capitalize text-white text-xs px-2 py-1 rounded ${
                                    SESSION_STATUS(translator)?.find((type) => type.value === row?.original?.display_status)
                                        ?.background
                                }
                                }`}
                            >
                                {SESSION_STATUS(translator)?.find((type) => type.value === row?.original?.display_status)?.label}
                            </p>
                        </div>
                    );
                },
                width: 152,
                sticky: true,
                shadow: true,
            },
            {
                Header: () => null,
                accessor: 'actions',
                Cell: ({ row, column }: { row: any; column: Column }) => {
                    return (
                        <div className="flex justify-end items-center h-full">
                            <SessionOptions
                                disable={checkEditRecord(row?.original) || !row.original?.display_status}
                                onEdit={() => setOpenNewSession(row?.original)}
                                onDelete={() => {
                                    toggleIsRemoveSession();
                                    sessionRef.current = row.values.id;
                                    setSessionSelected(row?.original);
                                }}
                                sessionDetail={row?.original}
                            />
                        </div>
                    );
                },
                width: 30,
                sticky: true,
            },
        ],
        [sessions, result]
    );

    return (
        <div className="w-full h-full relative grid grid-rows-layout_management_table">
            <DialogComponent
                isOpen={openNewSession !== undefined}
                title={openNewSession?.id ? `Session ID: ${openNewSession.id}` : 'New Session'}
                styles="w-[700px] min-h-[624px] min-w-[35vw]"
                onCloseModal={() => setOpenNewSession(undefined)}
                stylesTitle="text-base"
                child={
                    <ModalSession
                        timeZone={result?.time_zone}
                        onClose={() => setOpenNewSession(undefined)}
                        handleFetchData={() => handleResetSessionData()}
                        data={openNewSession}
                    />
                }
            />
            {openImport && (
                <ModalImportCourse
                    isOpen
                    onClose={() => setOpenImport(false)}
                    onConfirm={async (courseId: number) => {
                        try {
                            await classService.importCourseToSession(param.id, param.classId, {
                                course_id: courseId,
                            });
                        } catch (e) {
                            errorNoti('No lesson to import', <ExclamationCircleIcon />, 0);
                        }
                        handleResetSessionData();
                    }}
                />
            )}

            <ModalCancel
                isOpen={isRemoveSession}
                onCloseModal={() => {
                    toggleIsRemoveSession();
                    setOpenModalDetailSession(false);
                }}
                sessionDetails={sessionSelected}
                refreshData={getSessionList}
            />

            <div className="ClassSession row-span-1 col-span-1 flex justify-between items-center">
                <div className="text-green-500 text-ooolab_xs">
                    {`${translator('CLASSES.TIME_SLOT_IN_TIME_ZONE')} ${formatTimezone(result.time_zone)?.label}`}
                </div>
                {!featureFlags.SIS && (
                    <div className="flex items-center">
                        {!canSave ? (
                            <>
                                <button onClick={() => setOpenImport(true)} className="primary-button !bg-enterprise">
                                    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" className="mr-2">
                                        <path
                                            d="M6 0.5C6.41421 0.5 6.75 0.835786 6.75 1.25V9.18934L8.46967 7.46967C8.76256 7.17678 9.23744 7.17678 9.53033 7.46967C9.82322 7.76256 9.82322 8.23744 9.53033 8.53033L6.53033 11.5303C6.23744 11.8232 5.76256 11.8232 5.46967 11.5303L2.46967 8.53033C2.17678 8.23744 2.17678 7.76256 2.46967 7.46967C2.76256 7.17678 3.23744 7.17678 3.53033 7.46967L5.25 9.18934V1.25C5.25 0.835786 5.58579 0.5 6 0.5ZM0.75 11.75C1.16421 11.75 1.5 12.0858 1.5 12.5V14H10.5V12.5C10.5 12.0858 10.8358 11.75 11.25 11.75C11.6642 11.75 12 12.0858 12 12.5V14C12 14.8284 11.3284 15.5 10.5 15.5H1.5C0.671573 15.5 0 14.8284 0 14V12.5C0 12.0858 0.335786 11.75 0.75 11.75Z"
                                            fill="white"
                                        />
                                    </svg>
                                    <span className="text-sm font-semibold">Import Course</span>
                                </button>
                                { classType === CLASS_TYPE.MANUAL &&
                                    <button onClick={() => setOpenNewSession(prev => ({
                                        ...prev,
                                        ...instructorsStudents
                                    }))} className="primary-button !bg-enterprise ml-3">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" className="mr-2">
                                            <path
                                                d="M5.25 5.25V0.75H6.75V5.25H11.25V6.75H6.75V11.25H5.25V6.75H0.75V5.25H5.25Z"
                                                fill="white"
                                            />
                                        </svg>
                                        <span className="text-sm font-semibold">New Session</span>
                                    </button>
                                }
                            </>
                        ) : (
                            <>
                                <button
                                    type="button"
                                    onClick={handleUpdateSession}
                                    className="flex items-center mr-2 disabled:cursor-not-allowed disabled:opacity-40 primary-button"
                                >
                                    {loadingSave ? (
                                        <svg
                                            className="animate-spin w-ooolab_w_5 h-ooolab_h_5 mr-ooolab_m_1 opacity-100"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="white"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            ></path>
                                        </svg>
                                    ) : (
                                        <span> {translator('CLASSES.SAVE')}</span>
                                    )}
                                </button>
                                <button
                                    onClick={handleResetSessionData}
                                    className="rounded disabled:cursor-not-allowed disabled:opacity-40 mr-2 secondary-button"
                                >
                                    {translator('CLASSES.CANCEL')}
                                </button>
                            </>
                        )}
                    </div>
                )}
            </div>
            <div
                className="h-[calc(100vh-305px)] max-h-[calc(100vh-305px)] max-w-full overflow-auto row-span-9 col-span-1 border-t border-ooolab_bar_color "
            >
                {loading ?
                    <div className="flex flex-col w-full items-center text-sm justify-center mt-4">
                        <CircleSpin color="text-[#00559C]" />
                        Loading...
                    </div> : 
                sessionData?.length > 0 ?    
                    <Table
                        data={sessionData}
                        columns={columns}
                        updateData={editSessionState}
                        initialState={initialState}
                        isBorderFirstColum={false}
                    /> :
                    <div className='flex flex-col justify-center items-center py-6 m-auto h-[calc(100vh-305px)]'>
                        <NoContentWithImage content={translator('LESSON.NO_LESSON')} />
                    </div>
                }
            </div>
            {sessionData?.length > 0 && <Pagination callback={handleChangePagination} per_page={per_page} total={total} page={page} indexPage={indexPage} />}
            <ToastContainer />
            <SessionDetailsModal
                open={isOpenModalDetailSession}
                onCloseModal={() => setOpenModalDetailSession(false)}
                refreshData={handleResetSessionData}
                sessionId={sessionSelected?.id}
                title={sessionName}
            />
        </div>
    );
};

export default ClassSession;
