import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import './ModalTemplate.scss'
import NO_CONTENT from 'assets/img/empty-state/noTemplate.png'
import { useHistory, useParams } from 'react-router-dom';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import courseService from 'services/course.service';
import { ArrowDownIcon, ArrowUpIcon, PlusIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import { UserContext } from 'contexts/User/UserContext';
import ActionTemplate from './ActionTemplate';
import { ButtonOutline, ButtonPrimary } from '_shared';
import DialogComponent from 'components/Dialog/DialogComponent';
import { handleFail, handleSuccess } from 'utils/commonFun';

export enum TEMPLATE_STATUS {
    draft = 'draft',
    published = "published",
}

interface ModalTemplatePropps {
    isCreated?:any;
 }

const ModalTemplate: FC<ModalTemplatePropps> = ({
    isCreated
}) => {
    const { t: translator } = useTranslation();
    const history = useHistory();
    const params: any = useParams()
    const [keyword, setKeyword] = useDebouncedState('', 0);
    const [loadingGetTemplate, setLoadingGetTemplate] = useState<boolean>(false);
    const [hasNextPage, setHasNextPage] = useState<boolean>(false);
    const [templateList, setTemplateList] = useState<any[]>([]);
    const [isFirstLoad, setFirstLoad] = useState<boolean>(true);
    const [isCreateCourseTemplate, setIsCreateCourseTemplate] = useState<boolean>(false);
    const [isDeleteTemplate, setIsDeleteTemplate] = useState<boolean>(false);
    const [templateSelected, setTemplateSelected] = useState<any>(null);

    const {
        userState: { result },
    } = useContext(UserContext);

    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 10,
        order: 'desc',
        sort_by: 'created_at',
        total: 0,
    });

    const handleCheckNext = (length: number, total) => {
        if (length < total) {
            setHasNextPage(true);
        } else {
            setHasNextPage(false);
        }
    };

    const getTemplateList = (page: number | string = 1, order = 'desc') => {
        const bodyRequest = {
            page: page || pagination?.page,
            order: order || pagination?.order,
            q: keyword?.trim()
        };
        if (!bodyRequest?.q) delete bodyRequest?.q
        setLoadingGetTemplate(true);
        courseService
            .getTemplates(params?.id, bodyRequest)
            .then((res: any) => {
                setFirstLoad(false);
                if (res) {
                    const { items, page, total, order } = res.data;
                    setPagination({ ...pagination, page, total, order });
                    if (page === 1) {
                        setTemplateList(items);
                        handleCheckNext(items?.length, total);
                    } else {
                        setTemplateList([...templateList, ...items]);
                        handleCheckNext(items?.length + templateList?.length, total);
                    }
                }
            })
            .finally(() => setLoadingGetTemplate(false))
    };

    const [infiniteRef] = useInfiniteScroll({
        loading: loadingGetTemplate,
        hasNextPage: hasNextPage,
        onLoadMore: () => { getTemplateList(pagination?.page + 1) },
    });

    const onChangeOrder = () => {
        if (pagination?.order === 'desc') return getTemplateList(1, 'asc');
        return getTemplateList(1, 'desc');
    }

    const initialState = {
        hiddenColumns: ['id'],
    };

    const columns = useMemo(
        () => [
            { accessor: 'id' },
            {
                Header: () => <RenderTableHeader value={translator('NAME')} className='ml-6 font-semibold !text-sm' />,
                Cell: ({ row }) => {
                    return (
                        <RenderTableCell
                            className=" ml-6 p-3 font-normal !text-sm !text-gray-800 cursor-pointer truncate"
                            value={row?.values?.template_name}
                        />

                    );
                },
                accessor: 'template_name',
                width: 140,
            },
            {
                Header: () => <RenderTableHeader value={translator('DESCRIPTION')} className='!text-sm' />,
                Cell: ({ row }) => {
                    return (
                        <RenderTableCell
                            className=" p-3 font-normal !text-sm !text-gray-800 cursor-pointer truncate"
                            value={row?.values?.template_description}
                        />

                    );
                },
                accessor: 'template_description',
                width: 140,
            },
            {
                Header: () => <RenderTableHeader value={translator('STATUS')} className='!text-sm' />,
                accessor: 'status',
                Cell: ({ row }: { row: any }) => {
                    return (
                        <div className="py-3 ml-2 ">
                            <span
                                className={`!text-sm font-normal border rounded px-3 py-[1px] capitalize inline-block
                        ${row?.original?.status == TEMPLATE_STATUS.published
                                        ? 'text-green-500 border-green-300 bg-green-50'
                                        : 'text-gray-500 border-gray-300 bg-gray-50'
                                    }`}
                            >
                                {TEMPLATE_STATUS[row?.original?.status]}
                            </span>
                        </div>
                    );
                },
                width: 60,
            },
            {
                Header: () => (
                    <div
                        className="flex items-center cursor-pointer text-enterprise"
                        onClick={() => onChangeOrder()}
                    >
                        <RenderTableHeader value={translator('LAST_MODIFIED')} className='!text-sm !text-enterprise' />
                        {pagination?.order === 'desc' ? (
                            <ArrowDownIcon className="w-4 h-4 ml-1" />
                        ) : (
                            <ArrowUpIcon className="w-4 h-4 ml-1" />
                        )}
                    </div>
                ),
                accessor: 'updated_at',
                Cell: (d: any) => {
                    return (
                        <RenderTableCell
                            className=" p-3 font-normal !text-sm !text-gray-800 cursor-pointer truncate"
                            onClick={() => { }}
                            value={dayjs.utc(d?.value).tz(result?.time_zone).fromNow()}
                        />
                    );
                },
                width: 60,
            },
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="h-full flex items-center justify-center">
                            <ActionTemplate data={row?.original} createdCourse={(data) => {
                                setTemplateSelected({
                                    id: data?.id,
                                    name: data?.template_name
                                });
                                setIsCreateCourseTemplate(true);
                            }} onDeleteTemplate={(data) => {
                                setTemplateSelected({
                                    id: data?.id,
                                    name: data?.template_name
                                });
                                setIsDeleteTemplate(true)
                            }
                            } />
                        </div>
                    );
                },
                accessor: 'action',
                width: 40,
            },
        ],
        [templateList]
    );

    useEffect(() => {
        getTemplateList(1);
    }, [])

    useEffect(() => {
        if (!isFirstLoad) {
            getTemplateList(1)
        }
    }, [keyword]);

    const handleCreate = () => {
        if (templateSelected?.id) {
            courseService.createCourseFromTemplate(params?.id, templateSelected?.id).then((res) => {
                handleSuccess();
                isCreated();
            }).catch((err) => {
                handleFail();
            })
            setIsCreateCourseTemplate(false);

        }
    }

    const handleDelete = () => {
        if (templateSelected?.id) {
            courseService.deleteCourseTemplate(params?.id, templateSelected?.id).then((res) => {
                handleSuccess();
                getTemplateList(1);
            }).catch((err) => {
                handleFail();
            })
            setIsDeleteTemplate(false);
        }
    }

    return (
        <>
            <div className="filterTemplate">
                <SearchBox className="filterTemplate-searchInput" onSubmit={(value) => setKeyword(value)} placeholder={translator('COURSES.SEARCH_BY_TEMPLATE_NAME')} />
            </div>
            <div className='tableBar'>
                <div className='h-[360px] overflow-auto'>
                    <Table initialState={initialState} data={templateList || []} columns={columns} emptyIcon={NO_CONTENT} emptyContent={translator('COURSES.NO_TEMPLATE')} />
                    {hasNextPage && (
                        <div className="w-full flex items-center justify-center text-center py-2" ref={infiniteRef}>
                            {loadingGetTemplate && <CircleSpin color="text-[#024FEE]" />}
                        </div>
                    )}
                </div>
            </div>
            <div className='createTemplate'>
                <ButtonOutline type="button" onClick={() => { history.push(`/workspace/${params.id}/management/courses/new/template`) }} className="buttonOutline--isPrimary"  >
                    <PlusIcon className='w-4 h-4 text-enterprise' />
                    <span>{translator('COURSES.CREATE_NEW_TEMPLATE')}</span>
                </ButtonOutline>
            </div>
            {templateSelected && <DialogComponent
                isOpen={isCreateCourseTemplate}
                title={translator('COURSES.CONFIRM_COURSE_CREATION_FROM_TEMPLATE')}
                onCloseModal={() => setIsCreateCourseTemplate(false)}
                maskClosable={false}
                styles="max-w-md"
                child={
                    <div className="text-sm text-gray-800 flex flex-col gap-8 mt-4">
                        <div>{translator('COURSES.ARE_YOU_SURE_CREATE_COURSE_TEMPLATE', {
                            templateName: templateSelected?.name || '',
                        })}</div>
                        <div className="flex justify-center gap-4">
                            <ButtonOutline type="button" size="M" onClick={() => setIsCreateCourseTemplate(false)}>{translator('CANCEL')}</ButtonOutline>
                            <ButtonPrimary type="button" size="M" onClick={handleCreate}>
                                {translator('COURSES.CREATE_COURSE')}
                            </ButtonPrimary>
                        </div>
                    </div>
                }
            />}
            {templateSelected && <DialogComponent
                isOpen={isDeleteTemplate}
                title={translator('COURSE_TEMPLATE.DELETE')}
                onCloseModal={() => setIsDeleteTemplate(false)}
                maskClosable={false}
                styles="max-w-md"
                child={
                    <div className="text-sm text-gray-800 flex flex-col gap-8 mt-4">
                        <div>{translator('COURSE_TEMPLATE.MSG_DELETE')}</div>
                        <div className="flex justify-center gap-4">
                            <ButtonOutline type="button" size="M" onClick={() => setIsDeleteTemplate(false)}>{translator('CANCEL')}</ButtonOutline>
                            <ButtonPrimary type="button" size="M" onClick={handleDelete} className='buttonPrimary-danger'>
                                {translator('COURSE_TEMPLATE.DELETE')}
                            </ButtonPrimary>
                        </div>
                    </div>
                }
            />}

        </>
    );
};

export default ModalTemplate;
