import { useForm } from 'react-hook-form';
import groupService from 'services/group.service';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { InputField, TextareaField } from 'components/InputForm/InputForm';

const CreateGroupFrom = ({
    toggleModalCreate
}) => {
    const { t: translator } = useTranslation();
    const history = useHistory();
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isValid },
    } = useForm({mode: "onChange"});

    const params = useParams<{ id: string; studentId: string }>();

    const [loading, setLoading] = useState(false)

    const submitForm = (val) => {
        setLoading(true)
        const data = {
            name: val.name.trim(),
            description: val.description.trim()
        }
        if (data.description === '') data.description = null
        groupService.createGroupService(params.id, data)
        .then(res => {
            setLoading(false)
            toggleModalCreate()
            history.push(`/workspace/${params.id}/management/custom-groups/${res.data.id}`)
        })
        .catch(err => err)
        .finally(() => setLoading(false))
    };

    return (
        <form
            onSubmit={handleSubmit(submitForm)}
            className="flex flex-col gap-y-5 mt-4"
        >
            <InputField
                type="text"
                label={translator("GROUP.NAME")}
                maxLength={100}
                isCountter
                name="name"
                isRequired
                placeholder={translator('GROUP.GROUP_NAME_DESC')}
                register={register('name', {
                    required: {
                        value: true,
                        message: `${translator(
                            'FORM_CONST.REQUIRED_FIELD'
                        )}`,
                    },
                    validate: {
                        shouldNotContainSpace: (value) =>
                            !!value.trim()
                                ? true
                                : `${translator(
                                        'FORM_CONST.REQUIRED_FIELD'
                                    )}`,
                    },
                    maxLength: {
                        value: 100,
                        message: translator('GROUP.GROUP_NAME_MAX_LENGTH'),
                    },
                })}
                errors={errors}
            />
           
            <TextareaField 
                label={translator("GROUP.DESC")}
                maxLength={1000}
                isCountter
                name="description"          
                placeholder={translator("GROUP.DESC_PLACEHOLDER")}
                register={register('description')}
            />
            
            <div className="flex justify-center items-center">
                <button disabled={!isValid || !isDirty || loading} className={`bg-primary-500 disabled:bg-ooolab_dark_50 text-white px-ooolab_p_2 rounded text-ooolab_xs font-semibold leading-ooolab_24px flex`}>
                  {translator("GROUP.ADD")}
                </button>
            </div>
        </form>
    );
};

export default CreateGroupFrom;
