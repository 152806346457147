
import React, { useMemo, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon, EyeIcon } from "@heroicons/react/outline";
import "./Tasks.scss";
import { LIBRARY_ICONS } from "components/Library/LibraryContants";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import courseService from "services/course.service";
import { useParams } from "react-router-dom";
import { Toastify, Tooltip } from "_shared";
import { TASKS_STATUS } from "components/GradeSubmission/AssignmentGrade/AssignmentGrade";
import { ACCEPT_FILE_EXTENSIONS } from "constant/util.const";
import { hasCheckedStream } from "constant/file.constant";
import { VideoPreview } from "_shared/components/DocumentPreview/VideoPreview";

export const SubmissionTask = ({ task, addFeedback, tabStatus, learnerSelect, refreshData, courseId }:
    {
        addFeedback: (value?: any) => void, task: any, tabStatus: string,
        learnerSelect?: any,
        refreshData: () => void,
        courseId?: any
    }
) => {
    const { t: translator } = useTranslation();
    const params: { id: string, courseId: string, assignmentId: string } = useParams();
    const [loading, setLoading] = useState(false);
    const [hasOpenFile, setHasOpenFile] = useState(false);
    const [urlPlay, setUrlPlay] = useState('');

    const handleSave = async (event, id) => {
        if (!event?.target?.value || loading) {
            return;
        }
        setLoading(true);
        const response = await courseService.addFeedbackScore(params?.id, params?.courseId || courseId, {
            id: learnerSelect?.id,
            score: event?.target?.value,
            taskId: id
        });
        if (response?.data?.error) {
            Toastify.error();
        } else {
            Toastify.success('Your changed successfully!');
            refreshData()
        }
        setLoading(false);
    }

    return <div className="tasks">
        <Disclosure>
            {({ open }) => (
                <>
                    <Disclosure.Button className="tasks-header">
                        <div className="tasks-header-name">
                            {tabStatus !== 'assigned' ? <ChevronUpIcon
                                className={`${open ? 'rotate-180 transform' : ''} h-5 w-5`}
                            /> : null}
                            <span className="tasks-icon">
                                <img
                                    alt="libary-icon"
                                    className="assigmentBuilderPreview-icon"
                                    src={task?.object_type ?
                                        LIBRARY_ICONS(translator)?.find((item) => item?.value === task?.object_type)?.icon :
                                        LIBRARY_ICONS(translator)[0].icon
                                    }
                                />
                            </span>
                            <h3 className="tasks-name">{task.name}</h3>
                            <span className={`tasks-status ${tabStatus}`}>
                                {TASKS_STATUS[tabStatus]}
                            </span>
                            <span className="tasks-grade">Graded: <span>{task?.grade ? `${task?.grade}%` : '-'}</span></span>
                        </div>
                        {tabStatus !== 'assigned' ? <div className="tasks-info">
                            <div className="tasks-info-item">
                                <div className="tasks-info-label">Highest Grade:</div>
                                <div className="tasks-info-value">{task?.score ?? '-'}/{task?.max_score ?? '-'}</div>
                            </div>
                            <div className="tasks-info-line"></div>
                            <div className="tasks-info-item">
                                <div className="tasks-info-label">Completion Date:</div>
                                <div className="tasks-info-value">{task?.attempts?.length > 0 ? dayjs(task?.attempts[0]?.finished).format('DD/MM/YYYY') : '-'}</div>
                            </div>
                        </div> : null}
                    </Disclosure.Button>
                    {tabStatus !== 'assigned' ? <Disclosure.Panel className="tasks-container">
                        {task?.attempts?.length > 0 ? <table>
                            <thead>
                                <tr>
                                    <th>Attempts</th>
                                    <th>Grade</th>
                                    <th>Completion Date & Time</th>
                                    <th>Submission name</th>
                                    <th className="!text-right !pr-2">Feedback</th>
                                </tr>
                            </thead>
                            <tbody>
                                {task?.attempts?.map((attempt, index) => <tr>
                                    <td width={70}>{index + 1}</td>
                                    <td width={80}>
                                        {attempt?.is_graded ? <>{attempt?.score ?? '-'}/{attempt?.max_score ?? '-'}</> :
                                            <div className="flex items-center">
                                                <input type="number" defaultValue={attempt?.score} onBlur={(event) => handleSave(event, attempt?.id)} onKeyPress={(event) => {
                                                    if (event?.code == 'Enter') {
                                                        handleSave(event, attempt?.id);
                                                    }
                                                }} max={100} className="h-6 w-10 py-[1px] px-2 rounded border border-gray300 mr-[2px]" />/100</div>
                                        }

                                    </td>
                                    <td width={188}>{attempt?.finished ? dayjs(attempt?.finished).format('DD/MM/YYYY HH:mm') : '-'}</td>
                                    <td>{attempt?.files?.map((item, index) =>
                                        <>{!hasCheckedStream(item?.file_url) ? <a className="text-primary-500 underline" href={item?.file_url} target="_blank">{item?.file_name}</a> :
                                            <button type="button" onClick={() => {
                                                setHasOpenFile(true);
                                                setUrlPlay({ ...item, name: item?.file_name });
                                                return;
                                            }} className="text-primary-500 underline">{item?.file_name}</button>
                                        }
                                            {index < attempt?.files?.length - 1 ? ', ' : ''}
                                        </>)}
                                    </td>

                                    <td width="191">
                                        <div className="tasks-action justify-end">
                                            <button className="tasks-link disabled:cursor-not-allowed disabled:text-gray-400"
                                                disabled={attempt?.files?.some((file) =>
                                                    file?.file_mime_type?.split('/')[0] === 'video' &&
                                                    ACCEPT_FILE_EXTENSIONS.indexOf(file?.file_mime_type) === -1 &&
                                                    !file?.converted_file_url
                                                )}
                                                onClick={() => {
                                                    addFeedback({
                                                        task,
                                                        attempt
                                                    });
                                                }}>{attempt?.instructor_feedback ? 'View' : 'Add'} Feedback
                                            </button>
                                            {attempt?.content_url ? <button className="tasks-btn" onClick={() => {
                                                if (!hasCheckedStream(attempt?.content_url)) {
                                                    window.open(attempt?.content_url, '_blank');
                                                } else {
                                                    setHasOpenFile(true);
                                                    setUrlPlay({ ...attempt, name: attempt?.name, file_url: attempt?.content_url });
                                                    return;
                                                }

                                            }}><EyeIcon className="w-4" /> <span>View</span></button> : null}
                                        </div>
                                    </td>
                                </tr>)}
                            </tbody>
                        </table> : <p className="text-gray-500 py-4 text-sm">No Attempts</p>}
                    </Disclosure.Panel> : null}
                </>
            )}
        </Disclosure>
        <VideoPreview data={urlPlay} isOpen={hasOpenFile} closeModal={() => setHasOpenFile(false)} />
    </div>
}