import { downloadCSV } from "../common";

export const DownloadStudent = (students) => {
    let headers: string[], rows: string[][];
    let file_name = `Learner List`;
    headers = [
        'No.',
        'Learner Name',
        'Overall Grade',
        'Attendance',
        'Assignment Grade',
        'Course Completion',
        'Goals Achieved',
        'Total Coins Earned'
    ];
    rows = students?.items?.map((el: any, index) => [
        index + 1,
        el?.learner?.display_name || 'N/A',
        el?.overall != null ? el?.overall : '-',
        el?.attendance != null ? el?.attendance : '-',
        el?.assignment_score != null ? el?.assignment_score : '-',
        el?.course_completion != null ? el?.course_completion : '-',
        el?.lesson_goal != null ? el?.lesson_goal : '-',
        el?.coin_earned != null ? el?.coin_earned : '-',

    ]);
    downloadCSV([headers, ...rows], file_name);
}
