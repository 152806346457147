import { FC, useState, useEffect, useContext, useMemo } from 'react';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LIBRARY_TAB } from './LibraryContants';
import { Tab } from '@headlessui/react';
import { classNames } from 'utils/commonFun';
import TabLibrary from './TabLibrary/TabLibrary';
import TabTrash from './TabTrash/TabTrash';
import { SettingButon } from './Settings/components/SettingsButon/SettingsButon';
import { Settings } from './Settings/Settings';
import libraryService from 'services/library.service';
import usePermission from 'hooks/usePermission';
import { checkPermission } from 'utils/commonFun';
import DialogComponent from 'components/Dialog/DialogComponent';
import { CustomFields } from './Settings/CustomFields/CustomFields';
import { TooltipCustom } from 'components/Tooltip/Tooltip';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { Tooltip } from '_shared';



const LibraryComponent: FC = () => {
    const params: any = useParams();
    const { t } = useTranslation();
    const history = useHistory();
    const search = useLocation()?.search;
    const tab = new URLSearchParams(search).get('tab');
    const [tabSelect, setTabSelect] = useState();
    const [isOpenSettings, setIsOpenSettings] = useState(false);
    const [folderLocation, setFolderLocation] = useState<any>();

    useEffect(() => {
        const typeLibrary: any = (tab && LIBRARY_TAB?.find((item) => item?.value === tab)) || LIBRARY_TAB[0];
        setTabSelect(typeLibrary?.index);
    }, [tab]);
    useEffect(() => {
        if (params?.folderId) {
            libraryService.getDetailsFolder(params?.id, params?.folderId).then((res) => {
                if (res?.data) setFolderLocation(res?.data);
            });
        }
        return () => {
            setFolderLocation({});
        };
    }, [params?.folderId]);

    const arrayBreadcrumbs = useMemo(() => {
        let arrFormat = [];
        const currentFolder = {
            name: folderLocation?.name,
            classNames: '',
            href: `/workspace/${params?.id}/library/${folderLocation?.id}`,
        };
        if (folderLocation?.path?.length) {
            folderLocation?.path?.map((item) => {
                const format = {
                    name: item?.name,
                    classNames: '',
                    href: `/workspace/${params?.id}/library/${item.id}`,
                };
                arrFormat.push(format);
            });
            arrFormat.push(currentFolder);
        } else if (folderLocation?.id) {
            arrFormat.push(currentFolder);
        }
        return arrFormat;
    }, [folderLocation]);

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`LIBRARY.${str}`, opt));
    return (
        <>
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.CONTENT_MANAGEMENT', true)}
                pages={[
                    {
                        name: translator('DASHBOARD.SIDEBAR.INTERNAL_LIBRARY', true),
                        href: `/workspace/${params?.id}/library?tab=library`,
                        className: '',
                    },
                    ...arrayBreadcrumbs,
                ]}
                containerClassName="z-10 sticky top-0"
            />
            <div className="relative text-xs font-semibold">
                {(tabSelect === LIBRARY_TAB[0].index && checkPermission('libraryContentSetting', 'R')) && (
                    <div className="absolute right-5 top-[14px]">
                        {checkPermission('libraryContentSetting', 'R') ? <SettingButon onClick={setIsOpenSettings} /> : ''}
                    </div>
                )}

                <Tab.Group
                    onChange={(tab: number) => {
                        history.push(`/workspace/${params?.id}/library?tab=${LIBRARY_TAB[tab].value}`);
                    }}
                    defaultIndex={tabSelect}
                    selectedIndex={tabSelect}
                >
                    <Tab.List className="border-b flex items-end space-x-3 pt-5 px-5">
                        {LIBRARY_TAB?.map((tab, index) => (
                            <Tab
                                key={index}
                                className={({ selected }) =>
                                    classNames(
                                        'mr-3 px-3 py-3 outline-none font-semibold',
                                        selected ? 'text-blue-500  border-b border-blue-500' : ' text-gray-500',
                                        index === 1 && !checkPermission('libraryTrashContent', 'R') && ' !hidden'
                                    )
                                }
                            >
                                {translator(tab.name)}
                            </Tab>
                        ))}
                    </Tab.List>
                    <Tab.Panels>
                        <Tab.Panel className={'outline-none'}>
                            {checkPermission('libraryContent', 'R') ? <TabLibrary /> : ''}
                        </Tab.Panel>
                        <Tab.Panel className={'outline-none'}>
                            {checkPermission('libraryTrashContent', 'R') ? <TabTrash /> : ''}
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
            {isOpenSettings ? <Settings isOpen={isOpenSettings} onClose={() => setIsOpenSettings(false)} /> : ''}
        </>
    );
};

export default LibraryComponent;
