import React from 'react';
import logo from 'assets/SVG/ooolab_logo.svg';
import { CheckIcon } from '@heroicons/react/solid';

import './style.css';

interface StepsSetupProps {
    text: string;
    lastStep?: boolean;
}

const StepsSetup: React.FC<StepsSetupProps> = ({ text, lastStep }) => {
    return (
        <>
            <div className="flex mx-8  items-center">
                <div
                    style={{
                        border: '4.1867px solid rgba(255, 255, 255, 0.2)',
                    }}
                    className="rounded-full w-8 h-8 bg-white"
                >
                    <CheckIcon className="w-6 h-6 text-text_blue_1" />
                </div>
                <div className="font-semibold text-xs xl:text-sm 2xl:text-base text-white ml-4">
                    {text}
                </div>
            </div>
            {!lastStep && (
                <div className="next-step mx-11 my-1 tall:my-4">
                    <svg
                        width="2"
                        height="24"
                        viewBox="0 0 2 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            width="2"
                            height="24"
                            rx="1"
                            fill="white"
                            fill-opacity="0.2"
                        />
                    </svg>
                </div>
            )}
        </>
    );
};

export default StepsSetup;
