import ReactTooltip from "react-tooltip";

export const User = ({ link, avatar, name, fontsize = 'text-xs', className = "" }) => {
  return (
      <div className={`flex items-center gap-1  ${link ? 'cursor-pointer' : ''}`} onClick={() => link && window.open(link, '_blank')}>
          <img src={avatar} className="w-5 h-5 rounded-full" />
          <div className={`text-xs font-semibold text-primary-500 text-ellipsis truncate max-w-[150px] ${fontsize} ${className}`}>{name}</div>
      </div>
  );
};

export const ListUser = ({ users = [], id, avatarStyle = '', fontsize = 'text-xs', onViewUser = undefined, className=""}) => {
  return (
      <div className={`avatar-member cursor-pointer ${className}`}>
          {users?.length >= 3 && (
              <div className="flex">
                  {users?.slice(0, 3)?.map((user, index) => {
                      return (
                          <div
                              key={user?.id}
                              className={`flex items-center gap-1 ${index !== 0 ? '-ml-1.5' : ''}`}
                              onClick={() => user?.link && window.open(user?.link, '_blank')}
                              data-for={`user-${user?.id}`}
                              data-tip={user?.name || user?.display_name}
                          >
                              <img src={user?.avatar || user?.avatar_url} className={`rounded-full w-5 h-5 border border-white ${avatarStyle}`} />
                              {/*@ts-ignore*/
                              <ReactTooltip
                                  place="top"
                                  type="dark"
                                  effect="float"
                                  className="max-w-xs"
                                  id={`user-${user?.id}`}
                                  getContent={(content) => <div>{content}</div>}
                              />}
                          </div>
                      );
                  })}
                  {users?.length > 3 && (
                      <>
                          <div
                              data-for={id}
                              data-tip={users
                                  ?.slice(3)
                                  ?.map((user) => user?.name || user?.display_name)
                                  ?.join(', ')}
                                className={`gap-1 -ml-2 rounded-full w-5 h-5 border border-white flex items-center justify-center bg-gray-300 text-[10px] ${avatarStyle}`}
                                onClick={() => onViewUser?.()}
                          >
                              +{users?.length - 3}
                          </div>
                          {/*@ts-ignore*/
                          <ReactTooltip
                              place="right"
                              type="dark"
                              effect="float"
                              id={id}
                              className="max-w-xs"
                              getContent={(content) => <div>{content}</div>}
                          />
                          }
                      </>
                  )}
              </div>
          )}
          {
            users?.length === 2 && (
              <div className="flex">
                {users?.map((user, index) => {
                      return (
                          <div
                              key={user?.id}
                              className={`flex items-center gap-1 ${index !== 0 ? '-ml-1.5' : ''}`}
                              onClick={() => window.open(user?.link, '_blank')}
                              data-for={`user-${user?.id}`}
                              data-tip={user?.name || user?.display_name}
                          >
                              <img src={user?.avatar || user?.avatar_url} className="rounded-full w-5 h-5 border border-white" />
                              {/*@ts-ignore*/
                              <ReactTooltip
                                  place="top"
                                  type="dark"
                                  effect="float"
                                  id={`user-${user?.id}`}
                                  getContent={(content) => <div>{content}</div>}
                              />}
                          </div>
                      );
                  })}
              </div>
            )
          }
           {users?.length === 1 && (
            <User
                name={users?.[0]?.name || users?.[0]?.display_name}
                link={users?.[0]?.link}
                avatar={users?.[0]?.avatar || users?.[0]?.avatar_url}
                fontsize={fontsize}
                className={className}
            />
           )}
      </div>
  );
};