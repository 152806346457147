import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import reportService from "services/reports/workspace-report.service";

import { CardContainer } from "components/Reports/components/CardContainer";
import { CardInfo, CardInfoProps } from "components/Reports/components/CardInfo";
import { ChartColumn, ColumnProps } from "components/Reports/components/ChartColumn/ChartColumn";
import { Title } from "components/Reports/components/Title";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { format1Decimal } from "utils/commonFun";
import { EmptyStateChart } from "_shared";
import DatePickerComponent from "components/Reports/components/DatePicker";
import moment from "moment";

export const Enrollments = ({className}: {className?: string}) => {
    const { t } = useTranslation();
    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);
    const params: { id: string } = useParams();
    const [loading, setLoading] = useState(true);
    const [totalEnrollmentsData, setTotalEnrollmentsData] = useState<CardInfoProps>();
    const [enrollmentsData, setEnrollmentsData] = useState<ColumnProps[]>();
    const [noData, setNoData] = useState<boolean>(false);

    const getData = async () => {
        if (!startDate || !endDate) {
            return;
        }
        setNoData(false);
        try {
            setLoading(true);
            const payload = {
                start_time: moment(new Date(startDate)).format('YYYY-MM-DD')+ 'T00:00:00.000',
                end_time: moment(new Date(endDate)).format('YYYY-MM-DD')+ 'T23:59:59.000',
            }
            const response = await reportService.getOverviewEnrollment(params.id, payload);
            const { total_enrollments, total_enrollments_changes_percent } = response.data;
            setTotalEnrollmentsData({
                value: total_enrollments,
                //day: selectedDate?.id,
                percent: total_enrollments_changes_percent,
                title: "Total Enrollments",
                //description: selectedDate?.description
            });
            setEnrollmentsData([
                {
                    name: 'Enrolled',
                    color: 'green-500',
                    height: (response.data?.enrolled / total_enrollments) * 100,
                    toolTipLabel: `${response.data?.enrolled} (${format1Decimal((response.data?.enrolled / total_enrollments) * 100)}%)`
                },
                {
                    name: 'Active',
                    color: 'primary-500',
                    height: (response.data?.active / total_enrollments) * 100,
                    toolTipLabel: `${response.data?.active} (${format1Decimal((response.data?.active / total_enrollments) * 100)}%)`
                },
                {
                    name: 'Expired',
                    color: 'gray-300',
                    height: (response.data?.expired / total_enrollments) * 100,
                    toolTipLabel: `${response.data?.expired} (${format1Decimal((response.data?.expired / total_enrollments) * 100)}%)`
                }
            ])
            setNoData(total_enrollments == 0);
            setLoading(false);
        } catch {
            setLoading(false);
            setNoData(true);
        }
    }
    const [startDate, setStartDate] = useState(new Date(moment().subtract(1, 'months').format('MM-DD-YYYY')));
    const [endDate, setEndDate] = useState(new Date());
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    useEffect(() => {
        getData();
    }, [startDate, endDate]);

    return <>
        <CardContainer className={className}>
            <div className="flex items-center gap-2">
                <Title>{translator('Enrollments')}</Title>
                <div className="ml-auto min-w-[132px] relative">
                    <DatePickerComponent onChange={onChange} startDate={startDate} endDate={endDate} />
                </div>
            </div>
            <div className="flex items-center mt-4 min-h-[169px] overflow-x-auto pt-4 pl-2">
                {loading ?
                    <div className="flex justify-center w-full">
                        <CircleSpin color="text-primary-500" />
                    </div> :
                    <>
                        <div className="w-2/3 min-w-[180px]">
                            {!noData ?
                                <ChartColumn data={enrollmentsData} /> :
                                <EmptyStateChart />
                            }
                        </div>
                        <div className="h-8 flex flex-none w-[1px] bg-gray-300 mx-6"></div>
                        <div className="w-1/3  pr-2 min-w-[120px]">
                            <CardInfo {...totalEnrollmentsData} />
                        </div>
                    </>
                }
            </div>
        </CardContainer>
    </>
}