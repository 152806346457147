import RefundModalImg from 'assets/img/refund_modal.png'
import ButtonComponent from 'components/Button/ButtonComponent'
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import classService from 'services/class.service'

export default function RefundModal({ toggleModalCreate, rewardSelected, refreshData }) {
  const params = useParams<{ id: string }>();
  const [reason, setReason] = useState("")

  const handleRefund = () => {
    const bodyData = {
      refund_reason: reason
    }
    classService.refundRewardService(params.id, rewardSelected.rewardClaimId, rewardSelected.rewardId, bodyData)
      .then(res => {
        refreshData();
        toggleModalCreate()
        setReason('')
      })
      .catch(err => err)
  }

  return (
    <div className='flex flex-col justify-center items-center'>
      <img src={RefundModalImg} alt="refund image" className='w-[100px] h-[100px]' />
      <p className=' font-medium text-xl mt-3'>Refund Coins</p>
      <textarea maxLength={200} value={reason} onChange={(e) => setReason(e.target.value)} className='h-12 w-full max-w-[272px] mt-3 border border-[#EBEBF0] p-2 rounded text-black outline-none' placeholder='Enter reason' />
      <div className='mt-6'>
        <ButtonComponent
          title="No, cancel!"
          classStyle="xs:p-1 xs:text-sm mr-5  border border-[#8F90A6] w-[128px] py-2"
          onClickButton={() => toggleModalCreate(false)}
        />

        <ButtonComponent
          title="Yes, refund!"
          onClickButton={handleRefund}
          classStyle="xs:p-1 xs:text-sm bg-[#FF3329]  text-white w-[128px] py-2"
        />
      </div>
    </div>
  )
}
