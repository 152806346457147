import httpClient, { RestfulService } from './restful.service';

const getWorkspaceStudentCustomReports = (workspace_id: string, params: any) => {
    if (workspace_id) {
        const url = `/workspaces/${workspace_id}/students/${params.userId}/custom-reports`;
        return RestfulService.getApi(url, params);
    }
};

export default {
    getWorkspaceStudentCustomReports,
};
