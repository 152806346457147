export const CLOUD_STORAGE = {
    cfs: 'cfs',
    s3: 's3'
}

export const videoMimeTypes = [
    'video/x-flv',
    'video/x-matroska',
    "video/mp4",       // MP4
    "video/quicktime", // MOV
    "video/x-matroska",// MKV
    "video/x-msvideo", // AVI
    "video/webm",      // WEBM
    "video/x-m4v",     // M4V
    "video/MP2T",      // TS
    "video/hevc",      // HEVC
    "video/avchd",     // AVCHD
    "video/quicktime", // ProRes
    "video/mp2t",
    "video/mpeg"
];

export const videoExtensions = [
    'mp4',
    'ogg',
    'webm',
    'mov',
    'wmv',
    'flv',
    'mkv',
    'm3u8',
    'hevc',
    'mts',
    'hevc',
    'm2ts',
    "3gp",
    "flv",
    "mkv",
    "mpg",
    "mxf",
    "ts",
    "avi",
    "mpeg"
];

export const isVideo = (file: File) => {
    if (!file?.name) {
        const fileExtension = (file as any)?.split('.').pop().toLowerCase();
        return videoExtensions.includes(fileExtension);
    }
    const fileExtension = file?.name?.split('.').pop().toLowerCase();
    return videoMimeTypes.includes(file.type) || videoExtensions.includes(fileExtension);
}

export const loadVideo = file => new Promise((resolve, reject) => {
    try {
        let video = document.createElement('video')
        video.preload = 'metadata'

        video.onloadedmetadata = function () {
            resolve(this)
        }

        video.onerror = function () {
            resolve({duration: 0})
        }

        video.src = window.URL.createObjectURL(file)
    } catch (e) {
        resolve({duration: 0})
    }
})

export const hasCheckedStream = (url) => {
    const fileExtension = (url as any)?.split('.').pop().toLowerCase();
    return !!['m3u8'].includes(fileExtension);
}

export const getFileKey = (urlString) => {
    const url = new URL(urlString);
    const key = url?.pathname?.split('/');
    return key[1];
}