import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { InputField, TextareaField } from 'components/InputForm/InputForm';
import { useTranslation } from 'react-i18next';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import "./CreateRole.scss";
import rolesPermissionsService from 'services/rolesPermissions.service';
import { Toast } from 'utils/sweetAlert';



const PER_PAGE = 20;

const CreateRole = ({ submitted, onCloseModal }) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        watch
    } = useForm({ mode: "onChange" });
    const { t } = useTranslation();
    const params: any = useParams();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`ROLES_PERMISSIONS.${str}`, opt));

    const submitForm = async (val) => {
        setIsLoading(true);
        await rolesPermissionsService.createCustomRoles(params?.id, {
            name: val?.name,
            description: val?.description
        }).then((res) => {
            Toastify.success(
                translator(
                    'CREAT_MESSAGE'
                )
            );
            submitted(res?.data?.id);
        })
            .catch((err) => {
                Toastify.error();
            });
        setIsLoading(false);
    };


    return (
        <form onSubmit={handleSubmit(submitForm)} className="grid grid-cols-2 px-ooolab_p_2  gap-y-2">
            <InputField
                label={translator("Role", true)}
                name="name"
                className="col-span-4"
                placeholder={translator('ENTER_ROLE_NAME')}
                isRequired
                register={register('name', {
                    required: {
                        value: true,
                        message: translator('FORM_CONST.REQUIRED_FIELD', true),
                    },
                    maxLength: {
                        value: 255,
                        message: translator('FORM_CONST.VALIDATE_255_CHARACTERS', true),
                    },
                })}
                errors={errors}
            />
            <div className="descripttionRole">
                <TextareaField
                    label={translator('DESCRIPTION')}
                    name="description"
                    className="col-span-4"
                    placeholder={translator('ENTER_DESCRIPTION')}
                    register={register('description')}
                    textLength={watch("description")?.length}
                    maxLength={255}
                    isCountter
                />
            </div>

            <div className="col-span-4 flex justify-center gap-4 items-center pt-3">
                <ButtonOutline
                    onClick={onCloseModal}
                    type={'button'}
                >
                    {translator('CANCEL', true)}
                </ButtonOutline>
                <ButtonPrimary
                    type="submit"
                    disabled={isLoading || !isValid}
                    className={`${isLoading ? 'bg-ooola_EFF0FA text-ooolab_8083A3 cursor-not-allowed' : ''}`}
                >
                    {translator('CREATE', true)}

                </ButtonPrimary>
            </div>
        </form>
    );
};

export default CreateRole;
