import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import reportService from "services/reports/workspace-report.service";

import { CardContainer } from "components/Reports/components/CardContainer";
import { Title } from "components/Reports/components/Title";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { MonthPicker } from "components/Reports/components/MonthPicker/MonthPicker";
import moment from "moment";
import { ChartColumnUnit } from "components/Reports/components/ChartColumnUnit/ChartColumnUnit";
import { EmptyStateChart } from "_shared";

export const MonthlyEnrollments = ({className = ''}) => {
    const { t } = useTranslation();
    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);
    const params: { id: string } = useParams();
    const [selectedDate, setSelectDate] = useState<Date>(new Date());
    const [loading, setLoading] = useState(true);
    const [enrollmentsData, setEnrollmentsData] = useState<any>(null);

    const getData = async () => {
        try {
            setLoading(true);
            setEnrollmentsData(null);
            const response = await reportService.getOverviewEnrollmentMonthly(params.id, { time: moment(selectedDate).format('MM-YYYY') });
            const {items} = response?.data;
            if (items?.length > 0) {
                const labels =[];
                const data = []
                items?.forEach(item => {
                    labels.push(`Week${item.week_of_month}`);
                    data.push(item.enrollments);
                })
                setEnrollmentsData({labels, data});
            }
            setLoading(false);
        } catch {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (selectedDate) {
            getData();
        }
    }, [selectedDate]);

    return <>
        <CardContainer className={`pb-2 ${className}`}>
            <div className="flex items-center">
                <Title>{translator('Monthly Enrollments')}</Title>
                <div className="ml-auto">
                    <div className="relative z-[999]">
                        <MonthPicker onChange={setSelectDate} />
                    </div>
                </div>
            </div>
            <div className="flex items-center mt-4 min-h-[169px]">
                {loading ?
                    <div className="flex justify-center w-full">
                        <CircleSpin className="text-primary-500" />
                    </div> :
                    enrollmentsData ? <ChartColumnUnit data={enrollmentsData}  label={translator('Enrollments')} /> : 
                    <div className="flex justify-center items-center w-full min-h-[185px]">
                        <EmptyStateChart />
                    </div>
                }
            </div>
        </CardContainer>
    </>
}