import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import workspaceService from 'services/workspace.service';
import { InputField } from 'components/InputForm/InputForm';
import SelectBox from '_shared/components/Select';
import { useDebouncedState } from 'hooks/useDebounce';
import { ButtonPrimary } from '_shared';

const PER_PAGE = 20;

const CreateGroupFrom = ({ getGroupList, toggleModalCreate }) => {
    const { t: translator } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        control,
        watch,
    } = useForm({ mode: 'onChange' });

    const [inputSearchLearder, setInputSearchMember] = useDebouncedState('', 0);
    const [listMember, setListMember] = useState([]);
    const [loadingMember, setLoadingMember] = useState(false);
    const [paginationMember, setPaginationMember] = useState<any>();


    const params = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false);

    const submitForm = (val) => {
        setLoading(true);
        const data = {
            name: val?.name?.trim(),
            member_ids: val?.members?.map(item => item?.value),
        };
        workspaceService
            .createLearningGroupService(params.id, data)
            .then((res) => {
                setLoading(false);
                getGroupList();
                toggleModalCreate();
            })
            .catch((err) => err)
            .finally(() => setLoading(false));
    };


    const getListMembers = (page = 1) => {
        setLoadingMember(true);
        const bodyParams = {
            page: page,
            per_page: PER_PAGE,
            q: inputSearchLearder,
        };
        workspaceService
            .getLearnersService(params.id, bodyParams)
            .then((res) => {
                const formatedList = res?.data?.items?.map((item) => ({
                    name: item?.display_name,
                    avatar_url: item?.avatar_url,
                    value: item?.id,
                }));
                if (page === 1) {
                    setListMember(formatedList);
                } else {
                    setListMember([...listMember, ...formatedList]);
                }
                setPaginationMember({
                    page: res?.data?.page,
                    per_page: res?.data?.per_page,
                    total: res?.data?.total,
                });
            })
            .finally(() => setLoadingMember(false));
    };

    useEffect(() => {
        getListMembers();
    }, [inputSearchLearder]);

    return (
        <form onSubmit={handleSubmit(submitForm)} className=" flex flex-col gap-4 mt-6">
            <InputField
                type="text"
                label={translator('LEARNING_GROUP.GROUP_NAME')}
                placeholder={translator('LEARNING_GROUP.GROUP_NAME_DESC')}
                isRequired
                isCountter
                maxLength={100}
                name="name"
                register={register('name', {
                    required: {
                        value: true,
                        message: `${translator('FORM_CONST.REQUIRED_FIELD')}`,
                    },
                    validate: {
                        shouldNotContainSpace: (value) => (!!value.trim() ? true : `${translator('FORM_CONST.REQUIRED_FIELD')}`),
                    },
                    maxLength: {
                        value: 100,
                        message: translator('GROUP.GROUP_NAME_MAX_LENGTH'),
                    },
                })}
                errors={errors}
            />

            <div>
                <SelectBox 
                    data={listMember} 
                    pagination={paginationMember} 
                    placeholder={translator('LEARNING_GROUP.SELECT_MEMBERS')}
                    label={translator('LEARNING_GROUP.SELECT_MEMBERS')}
                    control={control} 
                    name={'members'} 
                    value={watch('members')} 
                    getData={getListMembers}
                    numberOfItemDisplayed={3} 
                    onSearch={(value) => setInputSearchMember(value)}
                    loading={loadingMember}
                    isRequired  
                    errors={errors}
                />
            </div>
          
            <div className="col-span-2 flex justify-center items-center mt-4">
                <ButtonPrimary
                    disabled={!isValid || loading}
                    type='submit'
                >
                    {translator('ADD')}
                </ButtonPrimary>
            </div>

        </form>
    );
};

export default CreateGroupFrom;
