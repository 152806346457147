import React, { useState } from 'react';
import { DotIcon, CollapseIcon } from 'assets/icon';
import { classNames } from 'utils/commonFun';

export const TreeProperty = ({ ...props }) => {
    const { data, handleNodeSelected, nodeSelected } = props;

    return (
        <div>
            {data.map((node, index) => {
                let paddingLeft = `20px`;
                if (node?.level === 1) paddingLeft = `48px`;
                else if (node?.level === 2) paddingLeft = `68px`;

                return (
                    <React.Fragment key={index}>
                        <div className={classNames("flex items-center py-2 px-4", nodeSelected?.id == node?.id ? 'bg-blue-50' : '')}>
                            <div className="flex-1 flex items-center truncate">
                                <div style={{ paddingLeft }} className="relative h-5">
                                    <p
                                        className={classNames(
                                            'absolute right-0 w-5 flex justify-center flex-none',
                                            node?.children?.length > 0 ? 'top-0' : 'top-[7px]'
                                        )}
                                    >
                                        <span className="">
                                            {node?.children?.length > 0 ? (
                                                <CollapseIcon />
                                            ) : (
                                                <DotIcon className={node?.level > 0 ? 'w-1 h-1 mt-0.5' : ''} />
                                            )}
                                        </span>
                                    </p>
                                </div>
                                <p
                                    className="pl-2 text-enterprise font-semibold cursor-pointer truncate"
                                    onClick={() => handleNodeSelected(node)}
                                >
                                    {node?.name}
                                </p>
                            </div>
                        </div>
                    </React.Fragment>
                );
            })}
        </div>
    );
};
