import React, { useReducer } from 'react';
import reducers from 'reducers';
import { initResourcesState } from 'types/Resources.type';
import { ResourcesContext } from './ResourcesContext';

const ResourcesProvider: React.FC = ({ children }) => {
    const [resourcesState, dispatch] = useReducer(
        reducers.getResourcesReducer,
        initResourcesState
    );
    return (
        <ResourcesContext.Provider value={{ resourcesState, dispatch }}>
            {children}
        </ResourcesContext.Provider>
    );
};

export default ResourcesProvider;
