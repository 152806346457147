import React from 'react';
import { classNames } from 'utils/commonFun';

interface HeaderChangePasswordProps {
    title: string;
    inputClass: string;
}

const HeaderChangePassword: React.FC<HeaderChangePasswordProps> = ({
    title,
    inputClass,
}) => {
    return <div className={classNames(inputClass)}>{title}</div>;
};

export default HeaderChangePassword;
