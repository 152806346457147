import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import rewardService from 'services/reward.service';
import icon_delete from 'assets/img/icon_delete.png';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import DialogComponent from 'components/Dialog/DialogComponent';
import './style.scss'
import { useTranslation } from 'react-i18next';

export default function CancelModal({ isOpen, onClose, rewardSelected, refreshData }) {
    const { handleSubmit, setValue, register } = useForm({ mode: 'onChange' });

    const [isSubmit, setIsSubmit] = useState(false);
    const params: { id: string } = useParams();
    const {t} = useTranslation();
    const onSubmit = handleSubmit(async (value: any) => {
        if (isSubmit) {
            return;
        }

        setIsSubmit(true);
        const response = await rewardService.refundRewardClaimService(
            { workspaceId: params.id, rewardId: rewardSelected.reward.id, rewardClaimId: rewardSelected.id },
            { refund_reason: value?.refund_reason }
        );
        if (!response?.data?.error) {
            Toastify.success();
            setValue('refund_reason', '');
            onClose();
            setIsSubmit(false);
            refreshData();
        } else {
            setIsSubmit(false);
        }
    });
    return (
        <DialogComponent
            title=""
            isOpen={isOpen}
            onCloseModal={onClose}
            styles="max-w-[300px]"
            child={
                <div className="ModalCancel">
                    <form onSubmit={onSubmit} className='w-full'>
                        <img src={icon_delete} alt="Refund Coins" className="icon" />
                        <h2 className="title">{t('COIN.CANCEL_COINS')}</h2>
                        <textarea
                            className="input"
                            maxLength={200}
                            id="reward-description"
                            placeholder={t('COIN.REASON_CANCEL')}
                            name="reason"
                            {...register('refund_reason')}
                        ></textarea>
                        <div className="group-btn">
                            <ButtonOutline type="button" onClick={onClose}>
                                {t("NO")}
                            </ButtonOutline>
                            <ButtonPrimary type="submit" className="btn-cancel">
                                {isSubmit ? <CircleSpin color="text-white" className="mx-auto" /> : t("COIN.YES_CANCEL")}
                            </ButtonPrimary>
                        </div>
                    </form>
                </div>
            }
        />
    );
}
