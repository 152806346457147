import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import lodash, { isEqual, toInteger } from 'lodash';
import Select, { StylesConfig } from 'react-select';
import dayjs from 'dayjs';
import Timezone from 'dayjs/plugin/timezone';
import { ExclamationCircleIcon, ExclamationIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline';
import 'react-datepicker/dist/react-datepicker.css';

import { getDayOfWeekInRange } from 'utils/handleFormatTime';
import { ListWeekDay, WeekDay } from 'constant/util.const';
import DatePickerInput from 'components/Management/components/Form/DatePicker';
import CustomCheckbox from 'components/Management/components/Form/CustomCheckbox';
import TimePickerInput from 'components/Management/components/Form/TimePicker';
import ModalUploadImage from 'components/Management/components/UploadImage';
import Modal from 'components/Modal';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';

import userService from 'services/user.service';
import { useBoolean } from 'hooks/custom';
import classService from 'services/class.service';
import { UserContext } from 'contexts/User/UserContext';
import ModalCreateClass from './ModalCreateClass';
import { isValidURL } from 'utils/handleString';
import { timezone } from 'constant/timezone.const';
import { CLASS_DATE_FORMAT } from 'constant/ui.const';
import { formatTimezone } from 'components/User/AccountSettingForm/AccountSettingFormFn';

import ArrowDown from 'assets/SVG/arrow_down.svg';
import { classTypes } from 'constant/classTypes';
import { customSelectClassType } from 'components/Management/components/FormCreateSession';
import { CircleInformationIcon } from 'assets/icon/CircleInfomationIcon';
import TooltipInfo from 'components/UiComponents/TooltipInfo';

dayjs.extend(Timezone);

const customSelectStyle: StylesConfig<any, true> = {
    clearIndicator: (base) => ({ ...base, display: 'none' }),
    indicatorSeparator: (base) => ({ ...base, display: 'none' }),
    control: (base, { selectProps }) => ({
        ...base,
        border: 'none',
        boxShadow: 'none',
        cursor: 'pointer',
        ':focus': {
            border: 'none',
            boxShadow: 'none',
        },
        ':active': {
            border: 'none',
            boxShadow: 'none',
        },
        ':hover': {
            border: 'none',
            boxShadow: 'none',
        },
    }),
    indicatorsContainer: (base, { selectProps }) => ({
        ...base,
        // display: 'none',
    }),
    singleValue: (base) => ({
        ...base,
        color: 'rgba(16, 185, 129)',
    }),
};

const regex2 = /^[0-9]{0,2}$/;

const DAY = 86400;
const HOUR = 3600;
const MIN = 60;

const GroupProfile = () => {
    const params = useParams<{ id: string; classId: string }>();
    const { dispatch, getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const {
        dispatch: userDispatch,
        userState: { result },
    } = useContext(UserContext);
    const { detail, getDetailStatus, getDetailError } = getWorkspaceDetailState.class;
    const {
        membership: { role },
    } = getWorkspaceDetailState.result;

    const [canUpdate, setCanUpdate] = useState(false);
    const [showWarning, setShowWarning] = useState(false);

    const { booleanValue: isUpload, toggleBooleanValue: toggleIsUpload } = useBoolean();

    const { booleanValue: confirmUpdate, toggleBooleanValue: toggleConfirmUpdate } = useBoolean();

    const { booleanValue: confirmDeactivateClass, toggleBooleanValue: toggleDeactivateClass } = useBoolean();
    const [originalData, setOriginalData] = useState<any>({});

    const [tmpAvatar, setTmpAvatar] = useState('');

    const { t: translator } = useTranslation();

    const {
        register,
        formState: { errors, dirtyFields, isValid },
        getValues,
        handleSubmit,
        control,
        watch,
        reset,
        setError,
        setValue,
        clearErrors,
    } = useForm({
        defaultValues: {
            name: '',
            max_people: null,
            description: '',
            vc_link: '',
            address: '',
            assignment_duration: null,
            start_date: null,
            end_date: null,
            time_zone: null,
            time_range: [],
            days: null,
            hours: null,
            minutes: null,
        },
        mode: 'onChange',
    });

    const formWatch = watch();
    const { fields, append, remove, update } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'time_range', // unique name for your Field Array
        // keyName: "id", default to "id", you can change the key name
        shouldUnregister: true,
    });

    //watch field state of form
    const startDateWatch = watch('start_date');
    const endDateWatch = watch('end_date');
    const timeslotWatch = watch('time_range');
    const timeZoneWatch = watch('time_zone');
    const days = watch('days');
    const hours = watch('hours');
    const minutes = watch('minutes');

    const confirmSubmit = async () => {
        const validationState = customValidate();
        if (validationState) {
            toggleConfirmUpdate();
        }
    };

    const customValidate = () => {
        const formVal = getValues();
        if (!!formVal.description && formVal.description.length < 10) {
            setError('description', {
                type: 'required',
                message: translator('CLASSES.DECRIPTION_REQUIRED'),
            });
            return false;
        } else {
            clearErrors('description');
        }

        return true;
    };

    const onSubmit = () => {
        const data = getValues();
        const tmpData: any = {
            ...data,
            time_range: data.time_range.length
                ? data.time_range.map((item) => ({
                      ...item,
                      start_time: dayjs(item.start_time).set('second', 0).format('HH:mm:ss'),
                      end_time: dayjs(item.end_time).set('second', 0).format('HH:mm:ss'),
                      session_type: item.session_type.value,
                  }))
                : null,
            start_date: dayjs(data.start_date).format('YYYY-MM-DD'),
            end_date: dayjs(data.end_date).format('YYYY-MM-DD'),
            time_zone: data?.time_zone?.value,
        };
        delete tmpData.days;
        delete tmpData.hours;
        delete tmpData.minutes;
        if (!tmpData['description']) tmpData['description'] = null;
        const listUpdateKeys = Object.keys(dirtyFields);
        let submitData: any = {};
        listUpdateKeys.forEach((i) => {
            if (!lodash.isEqual(tmpData[i], detail[i])) submitData[i] = tmpData[i] || null;
        });
        if (dirtyFields?.days || dirtyFields?.hours || dirtyFields?.minutes) {
            submitData = {
                ...submitData,
                assignment_duration: calculatorAssignmentDuration(),
            };
        }
        workspaceMiddleware.updateClass(dispatch, {
            classId: params.classId,
            workspaceId: params.id,
            updateParams: submitData,
        });
    };

    useEffect(() => {
        // check enable or disable btn save
        let formWatchTemp: any = {
            ...watch(),
            start_date: formWatch.start_date ? dayjs(formWatch.start_date).format('YYYY-MM-DD') : formWatch.start_date,
            end_date: formWatch.end_date ? dayjs(formWatch.end_date).format('YYYY-MM-DD') : formWatch.end_date,
            time_range: formWatch?.time_range?.length
                ? formWatch?.time_range?.map((item) => {
                      return {
                          ...item,
                          start_time: dayjs(item.start_time).set('seconds', 0).format('HH:mm:ss'),
                          end_time: dayjs(item.end_time).set('seconds', 0).format('HH:mm:ss'),
                          session_type: item?.session_type?.value ? item?.session_type?.value : item?.session_type,
                      };
                  })
                : null,
            time_zone: formWatch?.time_zone?.value,
            assignment_duration: calculatorAssignmentDuration(),
        };
        delete formWatchTemp.days;
        delete formWatchTemp.hours;
        delete formWatchTemp.minutes;
        if (formWatchTemp?.time_range?.length) {
            formWatchTemp = {
                ...formWatchTemp,
                time_range: lodash.sortBy(formWatchTemp.time_range, ['weekday']),
            };
        }

        let initDataTemp: any = { ...detail };
        if (initDataTemp) {
            delete initDataTemp.id;
            delete initDataTemp.status;
            delete initDataTemp.avatar;
        }
        if (initDataTemp?.time_range?.length) {
            initDataTemp = {
                ...initDataTemp,
                time_range: lodash.sortBy(initDataTemp.time_range, ['weekday']),
            };
        }
        setCanUpdate(!lodash.isEqual(formWatchTemp, initDataTemp));

        // check show or hidden warning text timezone
        if (formWatchTemp && initDataTemp) {
            formWatchTemp = {
                start_date: formWatchTemp.start_date,
                end_date: formWatchTemp.end_date,
                time_zone: formWatchTemp?.time_zone,
                time_range: formWatchTemp?.time_range?.length
                    ? formWatchTemp.time_range.map((el) => ({
                          end_time: el.end_time,
                          start_time: el.start_time,
                          weekday: el.weekday,
                      }))
                    : formWatchTemp.time_range,
            };
            initDataTemp = {
                start_date: initDataTemp.start_date,
                end_date: initDataTemp.end_date,
                time_zone: initDataTemp?.time_zone,
                time_range: initDataTemp?.time_range?.length
                    ? initDataTemp.time_range.map((el) => ({
                          end_time: el.end_time,
                          start_time: el.start_time,
                          weekday: el.weekday,
                      }))
                    : initDataTemp.time_range,
            };
            setShowWarning(!lodash.isEqual(formWatchTemp, initDataTemp));
        }
    }, [formWatch, detail]);

    const resetTimeslots = useCallback(() => {
        const listAllowWeekday = getDayOfWeekInRange(startDateWatch, endDateWatch);
        const listRemoveValue = timeslotWatch?.filter((i) => !listAllowWeekday.includes(i['weekday'])).map((j) => j['weekday']);

        const listRemoveIndex = [];

        fields.forEach((j, index) => {
            if (listRemoveValue.includes(j['weekday'])) {
                listRemoveIndex.push(index);
            }
        });

        remove(listRemoveIndex);

        const listInputsDocument = document.getElementsByClassName('custom-input');
        if (listInputsDocument?.length) {
            for (let i = 0; i < listInputsDocument.length; i++) {
                const inputFields = listInputsDocument[i] as HTMLInputElement;
                if (inputFields && !listAllowWeekday.includes(toInteger(inputFields.value))) inputFields.checked = false;
            }
        }
    }, [timeslotWatch]);

    const handleChangeTimeSlot = (checked: boolean, fieldsValue: number) => {
        if (checked) {
            append({
                weekday: fieldsValue,
                start_time: null,
                end_time: null,
            });
        } else {
            const targetIndex = fields.findIndex((i) => i['weekday'] === fieldsValue);
            if (targetIndex > -1) {
                remove(targetIndex);
            }
        }
    };

    const sortedTimeslots = Array.from(fields)
        .map((i, index) => ({ ...i, originalIndex: index }))
        .sort((a, b) => a['weekday'] - b['weekday']);
    const listActiveDay = getDayOfWeekInRange(startDateWatch, endDateWatch);

    const handleUpdateImage = async (image: File, canvas?: HTMLCanvasElement | undefined) => {
        userService.uploadImage(
            image,
            (e) => {
                classService
                    .updatePartialClass(params.id, params.classId, {
                        avatar: e,
                    })
                    .then(() => {
                        setTmpAvatar(canvas.toDataURL());
                    })
                    .catch(() => setTmpAvatar(''));
            },
            () => {},
            canvas
        );
    };

    const handleUpdateClassStatus = (param: 'active' | 'deactivate') => {
        classService.updateClassStatus(params.id, params.classId, param).then(() => {
            workspaceMiddleware.getClassDetail(dispatch, {
                classId: params.classId,
                workspaceId: params.id,
            });
        });
    };

    const customTimeZone: { value: string; label: string }[] = [];
    timezone.map((item) => {
        customTimeZone.push({
            label: item.text,
            value: item.utc[0],
        });
    });
    const calculatorAssignmentDuration = () => {
        return (days || 0) * DAY + (hours || 0) * HOUR + (minutes || 0) * MIN;
    };
    const featureFlags = JSON.parse(process.env.REACT_APP_FEATURE_FLAGS);

    useEffect(() => {
        if (
            fields.length === 0 ||
            !startDateWatch ||
            !endDateWatch ||
            dayjs(startDateWatch).format('YYYY-MM-DD') !== detail?.start_date ||
            dayjs(endDateWatch).format('YYYY-MM-DD') !== detail?.end_date
        ) {
            resetTimeslots();
        }
    }, [startDateWatch, endDateWatch]);

    useEffect(() => {
        workspaceMiddleware.getClassDetail(dispatch, {
            classId: params.classId,
            workspaceId: params.id,
        });
    }, [params.id]);

    // set init data from server
    useEffect(() => {
        if ((detail && getDetailStatus === 'done') || (detail && getDetailStatus === 'done_update')) {
            const daysOut = Math.floor(detail?.assignment_duration / DAY);
            const hoursOut = Math.floor((detail?.assignment_duration - daysOut * DAY) / HOUR);
            const minutesOut = Math.floor((detail?.assignment_duration - daysOut * DAY - hoursOut * HOUR) / MIN);
            const dataReset = {
                name: detail.name || '',
                max_people: detail?.max_people?.toString() || '',
                description: detail.description || '',
                vc_link: detail?.vc_link || '',
                address: detail.address,
                days: daysOut?.toString() || '',
                hours: hoursOut?.toString() || '',
                minutes: minutesOut?.toString() || '',
                // instructor: detail.instructor,
                // level: detail.level,
                // student_size: detail.student_size,
                assignment_duration: detail?.assignment_duration,
                start_date: new Date(detail?.start_date),
                end_date: new Date(detail?.end_date),
                time_zone: formatTimezone(detail?.time_zone),
                time_range: detail?.time_range
                    ? detail.time_range.map((i: any) => {
                          const [iHourStart, iMinuteStart] = i.start_time.split(':');
                          const [iHourEnd, iMinuteEnd] = i.end_time.split(':');
                          const convertStartTime = dayjs().set('hour', toInteger(iHourStart)).set('minute', toInteger(iMinuteStart));
                          const convertEndTime = dayjs().set('hour', toInteger(iHourEnd)).set('minute', toInteger(iMinuteEnd));

                          return {
                              weekday: i.weekday,
                              start_time: new Date().setHours(convertStartTime.get('hour'), convertStartTime.get('minute')),

                              end_time: new Date().setHours(convertEndTime.get('hour'), convertEndTime.get('minute')),
                              session_type: i.session_type,
                          };
                      })
                    : [],
            };
            setOriginalData(dataReset);
            reset(dataReset);

            const listWeekdayChecked = detail?.time_range ? detail?.time_range.map((i) => i.weekday) : [];
            const listInputsDocument = document.getElementsByClassName('custom-input');
            if (listInputsDocument?.length) {
                for (let i = 0; i < listInputsDocument.length; i++) {
                    const inputFields = listInputsDocument[i] as HTMLInputElement;
                    if (inputFields && listWeekdayChecked.includes(toInteger(inputFields.value))) {
                        inputFields.checked = true;
                    }
                }
            }
            // breadcrumb
            workspaceMiddleware.setCurrentRouteDetail(dispatch, [
                {
                    name: detail.name,
                    routeId: 'classId',
                },
            ]);

            if (getDetailStatus !== 'done_update') workspaceMiddleware.resetClassStatus(dispatch);
        }
    }, [detail, getDetailStatus]);

    return (
        <form onSubmit={handleSubmit(confirmSubmit)} className={`px-ooolab_p_5 relative ${detail ? '' : 'hidden'}`}>
            <ModalCreateClass
                isOpen={confirmUpdate}
                onClose={() => {
                    toggleConfirmUpdate();
                    setTimeout(() => workspaceMiddleware.resetClassStatus(dispatch), 200);
                }}
                submitData={{}}
                onSave={onSubmit}
                createStatus={getDetailStatus}
                createError={getDetailError}
            />
            <Modal
                isOpen={confirmDeactivateClass}
                onClose={toggleDeactivateClass}
                title={translator('CLASSES.CONFIRM_DEACTIVE')}
                contentText={translator('CLASSES.SUB_TITLE_MODAL')}
                imgSrc={<ExclamationIcon className="text-red-500 w-ooolab_w_10 h-ooolab_h_10" />}
                subBtn={
                    <button
                        className="px-ooolab_p_2 py-ooolab_p_1_e bg-primary-500 text-white border-none rounded"
                        onClick={toggleDeactivateClass}
                        type="button"
                    >
                        {translator('CLASSES.CANCEL')}
                    </button>
                }
                mainBtn={
                    <button
                        className="px-ooolab_p_2 py-ooolab_p_1_e bg-red-500 text-white border-none rounded"
                        type="button"
                        onClick={() => {
                            handleUpdateClassStatus('deactivate');
                            setTimeout(() => toggleDeactivateClass(), 200);
                        }}
                    >
                        {translator('CLASSES.DEACTIVE')}
                    </button>
                }
            />
            <ModalUploadImage isOpen={isUpload} onClose={toggleIsUpload} onSubmitImage={handleUpdateImage} />
            <div className="border-b border-ooolab_bar_color px-ooolab_p_5 py-ooolab_p_5 flex items-center justify-between">
                <div className="relative overflow-hidden w-ooolab_w_25 h-ooolab_h_25 rounded-full border z-1 group hover:border-black">
                    <img className="w-full h-full z-1 absolute left-0" src={tmpAvatar || detail?.avatar} alt="group-avatar" />
                    {detail?.status === 'active' ? ( // && role === 'admin'
                        <button
                            type="button"
                            onClick={toggleIsUpload}
                            className="z-30 w-ooolab_w_25 h-ooolab_h_25 bg-ooolab_dark_50 bg-opacity-60 rounded-full border-ooolab_bar_color -left-[105%] absolute group-hover:left-0"
                        >
                            <span className="text-ooolab_10px bg-ooolab_light_100 p-ooolab_p_1">
                                {translator('CLASSES.CHANGE_AVATAR')}
                            </span>
                        </button>
                    ) : null}
                </div>

                {/* role !== 'admin' && 'hidden' */}
                <div className={`flex items-center text-ooolab_xs font-semibold`}>
                    {detail?.status !== 'active' ? (
                        <button
                            type="button"
                            onClick={() => handleUpdateClassStatus('active')}
                            className="px-ooolab_p_2 py-ooolab_p_1_e border-none shadow-ooolab_box_shadow_container rounded"
                        >
                            {translator('CLASSES.ACTIVATE')}
                        </button>
                    ) : (
                        !featureFlags.SIS && (
                            <>
                                <button
                                    disabled={
                                        !(dirtyFields && Object.keys(dirtyFields)?.length) ||
                                        !isValid ||
                                        !!isEqual(originalData, getValues())
                                    }
                                    type="submit"
                                    className="disabled:cursor-not-allowed disabled:bg-gray-400 primary-button"
                                >
                                    {translator('CLASSES.SAVE')}
                                </button>
                                <button onClick={toggleDeactivateClass} type="button" className="mx-2 secondary-button">
                                    {translator('CLASSES.DEACTIVATE')}
                                </button>
                            </>
                        )
                    )}
                </div>
            </div>
            <div className="p-ooolab_p_5 grid grid-cols-6 gap-x-ooolab_w_8 gap-y-ooolab_w_5">
                {/* row 1 */}
                <div className="col-span-2 text-ooolab_xs">
                    <label htmlFor="class-name" className="text-ooolab_dark_1 font-semibold leading-ooolab_24px block">
                        {translator('CLASSES.CLASS_NAME')}
                        <span className="text-red-500">*</span>
                    </label>
                    <input
                        className="border lg:w-full md:w-3/4 w-1/2 border-ooolab_bar_color text-ooolab_dark_1 p-ooolab_p_2 rounded font-normal"
                        type="text"
                        id="class-name"
                        maxLength={255}
                        disabled={detail?.status !== 'active' || featureFlags.SIS}
                        placeholder={translator('CLASSES.CLASS_NAME')}
                        {...register('name', {
                            required: {
                                value: true,
                                message: translator('FORM_CONST.REQUIRED_FIELD'),
                            },
                            validate: {
                                shouldNotContainSpace: (value) =>
                                    !!value.trim() ? true : `${translator('FORM_CONST.REQUIRED_FIELD')}`,
                            },
                        })}
                    />
                    <ErrorMessage className="text-red-500 text-ooolab_10px" errors={errors} name="name" as="p" />
                </div>
                <div className="col-span-2 text-ooolab_xs">
                    <label htmlFor="class-name" className="text-ooolab_dark_1 font-semibold leading-ooolab_24px block">
                        {translator('CLASSES.MAX_PEOPLE')}
                    </label>
                    <input
                        className="border lg:w-full md:w-3/4 w-1/2 border-ooolab_bar_color text-ooolab_dark_1 p-ooolab_p_2 rounded font-normal"
                        type="number"
                        id="max_people"
                        min={1}
                        disabled={detail?.status !== 'active' || featureFlags.SIS}
                        placeholder={translator('CLASSES.MAX_PEOPLE')}
                        {...register('max_people')}
                    />
                </div>
                <div className="col-span-1 text-ooolab_xs"></div>
                {/* row 2 */}
                <div className="col-span-2 text-ooolab_xs">
                    <label htmlFor="vc_link" className="text-ooolab_dark_1 font-semibold leading-ooolab_24px block">
                        {translator('CLASSES.CLASSROOM_LINK')}
                    </label>
                    <input
                        className="border lg:w-full md:w-3/4 w-1/2 border-ooolab_bar_color text-ooolab_dark_1 p-ooolab_p_2 rounded font-normal"
                        type="text"
                        id="vc_link"
                        disabled={detail?.status !== 'active' || featureFlags.SIS}
                        placeholder={translator('CLASSES.VC_URL')}
                        {...register('vc_link')}
                    />
                    <div className="text-xs text-orange-500 pt-1">{translator('CLASSES.VC_LINK_INSTRUCTION_DETAIL')}</div>
                </div>
                <div className="col-span-2 text-ooolab_xs">
                    <label htmlFor="address" className="text-ooolab_dark_1 font-semibold leading-ooolab_24px block">
                        {translator('CLASSES.ADDRESS')}
                    </label>
                    <input
                        className="border lg:w-full md:w-3/4 w-1/2 border-ooolab_bar_color text-ooolab_dark_1 p-ooolab_p_2 rounded font-normal"
                        type="text"
                        id="address"
                        maxLength={255}
                        placeholder={translator('CLASSES.ADDRESS')}
                        {...register('address')}
                        disabled={featureFlags.SIS}
                    />
                    <ErrorMessage className="text-red-500 text-ooolab_10px" errors={errors} name="address" as="p" />
                </div>
                {/* row 4 */}
                <div className="col-span-4 text-ooolab_xs">
                    <label htmlFor="description" className="text-ooolab_dark_1 font-semibold leading-ooolab_24px block">
                        {translator('CLASSES.DESCRIPTION')}
                    </label>
                    <textarea
                        className="border focus:outline-none lg:w-full md:w-3/4 w-1/2 border-ooolab_bar_color text-ooolab_dark_1 p-ooolab_p_2 rounded font-normal"
                        rows={5}
                        maxLength={255}
                        disabled={detail?.status !== 'active' || featureFlags.SIS}
                        id={translator('CLASSES.DESCRIPTION_CLASS')}
                        placeholder={detail?.status === 'active' ? 'Description' : null}
                        {...register('description', {})}
                    />
                    <ErrorMessage className="text-red-500 text-ooolab_10px" errors={errors} name="description" as="p" />
                </div>
                <div className="col-span-2 text-ooolab_xs"></div>
                {/* row 5 */}
                {detail?.class_type !== 'auto' && (
                    <>
                        <div className="col-span-2 text-ooolab_xs ">
                            <p className="flex items-center space-x-2 ">
                                <label className="text-ooolab_dark_1 font-semibold leading-ooolab_24px block" htmlFor="days">
                                    {translator('CLASSES.ASSIGNMENT_DURATION')}
                                </label>
                                <div className="relative">
                                    <TooltipInfo
                                        info={
                                            <div className="text-xs text-dark-300">
                                                {translator('CLASSES.ASSIGNMENT_DURATION_INSTRUCTION')}
                                            </div>
                                        }
                                        styles="!top-0 !right-4 "
                                    >
                                        <CircleInformationIcon height="10.5" width="10.5" fill="#1F2937" />
                                    </TooltipInfo>
                                </div>
                            </p>
                            <div className="grid grid-cols-3 gap-2 font-normal">
                                <div>
                                    <div className="flex items-center space-x-1 2xl:space-x-2">
                                        <input
                                            className="border border-ooolab_bar_color text-ooolab_dark_1 p-ooolab_p_2 rounded w-14"
                                            type="text"
                                            id="days"
                                            {...register('days', {
                                                pattern: {
                                                    value: /^[0-9]*$/,
                                                    message: 'Enter the value as numeric',
                                                },
                                                onChange: (e) => {
                                                    clearErrors('minutes');
                                                },
                                            })}
                                            disabled={featureFlags.SIS}
                                        />
                                        <span>{translator('CLASSES.DAYS')}</span>
                                    </div>
                                    <ErrorMessage className="text-red-500 text-ooolab_10px" errors={errors} name="days" as="p" />
                                </div>
                                <div>
                                    <div className="flex items-center space-x-1 2xl:space-x-2">
                                        <input
                                            className="border border-ooolab_bar_color text-ooolab_dark_1 p-ooolab_p_2 rounded w-14 "
                                            type="text"
                                            id="hours"
                                            maxLength={2}
                                            {...register('hours', {
                                                validate: (value) => {
                                                    if (!regex2.test(value) || (regex2.test(value) && +value > 23)) {
                                                        return 'Enter the value as numeric and start from 0 to 23';
                                                    }
                                                    return true;
                                                },
                                                onChange: (e) => {
                                                    // const { value } = e.target;
                                                    // setHours(value);
                                                    clearErrors('minutes');
                                                },
                                            })}
                                            disabled={featureFlags.SIS}
                                        />
                                        <span>{translator('CLASSES.HOURS')}</span>
                                    </div>
                                    <ErrorMessage className="text-red-500 text-ooolab_10px" errors={errors} name="hours" as="p" />
                                </div>
                                <div>
                                    <div className="flex items-center space-x-1 2xl:space-x-2">
                                        <input
                                            className="border border-ooolab_bar_color text-ooolab_dark_1 p-ooolab_p_2 rounded w-14"
                                            type="text"
                                            id="minutes"
                                            maxLength={2}
                                            {...register('minutes', {
                                                required: {
                                                    value: days == 0 && hours == 0,
                                                    message: translator('FORM_CONST.REQUIRED_FIELD'),
                                                },
                                                validate: (value) => {
                                                    if (!regex2.test(value) || (regex2.test(value) && +value > 59)) {
                                                        return 'Enter the value as numeric and start from 0 to 59';
                                                    }
                                                    if (value && +value < 30 && days == 0 && hours == 0) {
                                                        return 'Minimum time is 30 minutes';
                                                    }
                                                    return true;
                                                },
                                            })}
                                            disabled={featureFlags.SIS}
                                        />
                                        <span>{translator('CLASSES.MINUTES')}</span>
                                    </div>
                                    <ErrorMessage className="text-red-500 text-ooolab_10px" errors={errors} name="minutes" as="p" />
                                </div>
                            </div>
                        </div>
                        <div className="col-span-2 text-ooolab_xs"></div>
                        {/* row 6 empty */}
                        <div className="col-span-6 text-ooolab_xs border-t border-ooolab_gray_6 mt-ooolab_m_5"></div>
                        {/* row 7 */}
                        {detail?.status === 'active' && (
                            <>
                                {/* row 7-1 */}
                                <div className="col-span-2 text-ooolab_dark_1">
                                    <p className="text-ooolab_xs font-semibold mb-ooolab_m_5">{translator('CLASSES.CLASS_DAYS')}</p>
                                    <div className="grid grid-cols-7">
                                        {ListWeekDay(translator).map((i, index) => {
                                            return (
                                                <CustomCheckbox
                                                    key={`class-slot-${i.name}`}
                                                    className="flex col-span-1 items-center text-ooolab_10px font-semibold leading-ooolab_24px"
                                                    value={i.value}
                                                    title={i.name}
                                                    onChange={handleChangeTimeSlot}
                                                    disabled={!listActiveDay.includes(i.value) || featureFlags.SIS}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="col-span-3 flex items-end">
                                    {showWarning && (
                                        <p className="text-red-500 text-ooolab_xs inline-flex items-center">
                                            <ExclamationCircleIcon className="w-ooolab_w_5 h-ooolab_h_5 " />
                                            <span>{translator('CLASSES.CHANGE_WILL_REMOVE')}</span>
                                        </p>
                                    )}
                                </div>
                                <div className="col-span-1"></div>
                                {/* row 7-2 */}
                                <div className="col-span-4 text-ooolab_xs">
                                    <div className="font-bold mb-ooolab_m_2 mt-ooolab_m_1 flex items-center">
                                        <p>{translator('CLASSES.TIME_SLOT')}</p>
                                        <div className="text-ooolab_xs font-medium inline-flex items-center">
                                            <ExclamationCircleIcon className="w-ooolab_w_5 h-ooolab_h_5 mx-ooolab_m_1 text-green-500 " />
                                            <span className="text-green-500">{translator('CLASSES.TIME_SLOT_IN_TIME_ZONE')}</span>
                                            <Controller
                                                control={control}
                                                name="time_zone"
                                                render={({ field, formState }) => {
                                                    return (
                                                        <Select
                                                            {...field}
                                                            {...formState}
                                                            className="text-ooolab_xs min-w-ooolab_h_40 cursor-pointer"
                                                            styles={customSelectStyle}
                                                            options={customTimeZone}
                                                            menuPlacement="top"
                                                            isDisabled={featureFlags.SIS}
                                                        />
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-2"></div>
                                {/* row 7-3 */}
                                <div className="col-span-3 mb-ooolab_m_2 text-ooolab_xs">
                                    {sortedTimeslots.map((field: any) => {
                                        const fieldValue = timeslotWatch.find((i) => i?.weekday === field['weekday']);
                                        const setDefaultValueSessionType = () => {
                                            if (!field?.session_type) return null;
                                            return field?.session_type === 'online' ? classTypes[0] : classTypes[1];
                                        };
                                        const filterPassedTime = (time) => {
                                            return fieldValue['start_time'] ? dayjs(time).isAfter(fieldValue['start_time']) : true;
                                        };
                                        const filterMinTime = (time) => {
                                            return fieldValue['end_time']
                                                ? dayjs(time)
                                                      .set('milliseconds', 0)
                                                      .isBefore(dayjs(fieldValue['end_time']).set('milliseconds', 0))
                                                : true;
                                        };
                                        return (
                                            <div className="grid grid-cols-7 mb-ooolab_m_2 items-center gap-x-3" key={`${field.id}`}>
                                                <label
                                                    className="col-span-1 inline-flex align-middle capitalize text-ooolab_dark_2 font-semibold"
                                                    htmlFor={field.id}
                                                >
                                                    {WeekDay(translator)[field['weekday']]}
                                                </label>
                                                <input
                                                    className="col-span-2 hidden"
                                                    defaultValue={field['weekday']}
                                                    {...register(`time_range.${field.originalIndex}.weekday`)}
                                                    disabled={featureFlags.SIS}
                                                />
                                                <div className="col-span-2">
                                                    <TimePickerInput
                                                        control={control}
                                                        name={`time_range.${field.originalIndex}.start_time`}
                                                        placeholderText={translator('CLASSES.START_DATE')}
                                                        defaultValue={field['start_time'] && new Date(field['start_time'])}
                                                        filter={filterMinTime}
                                                        disabled={featureFlags.SIS}
                                                    />
                                                </div>

                                                <div className="col-span-2">
                                                    <TimePickerInput
                                                        control={control}
                                                        name={`time_range.${field.originalIndex}.end_time`}
                                                        placeholderText={translator('CLASSES.END_DATE')}
                                                        filter={filterPassedTime}
                                                        defaultValue={field['end_time'] && new Date(field['end_time'])}
                                                        disabled={featureFlags.SIS}
                                                    />
                                                </div>
                                                <div className="col-span-2">
                                                    <Controller
                                                        control={control}
                                                        name={`time_range.${field.originalIndex}.session_type`}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: translator('FORM_CONST.REQUIRED_FIELD'),
                                                            },
                                                        }}
                                                        render={({ field: { onChange } }) => {
                                                            return (
                                                                <Select
                                                                    styles={customSelectClassType}
                                                                    className="w-full"
                                                                    options={classTypes}
                                                                    onChange={onChange}
                                                                    defaultValue={setDefaultValueSessionType()}
                                                                    placeholder={translator('CLASSES.CLASS_TYPE')}
                                                                    menuPlacement="top"
                                                                    isDisabled={featureFlags.SIS}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-1"></div>
                                                <div className="col-span-2">
                                                    <ErrorMessage
                                                        className="text-red-500 text-ooolab_10px min-h-button"
                                                        errors={errors}
                                                        name={`time_range.${field.originalIndex}.start_time`}
                                                        as="p"
                                                    />
                                                </div>
                                                <div className="col-span-2">
                                                    <ErrorMessage
                                                        className="text-red-500 text-ooolab_10px min-h-button"
                                                        errors={errors}
                                                        name={`time_range.${field.originalIndex}.end_time`}
                                                        as="p"
                                                    />
                                                </div>
                                                <div className="col-span-2">
                                                    <ErrorMessage
                                                        className="text-red-500 text-ooolab_10px min-h-button"
                                                        errors={errors}
                                                        name={`time_range.${field.originalIndex}.session_type`}
                                                        as="p"
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="col-span-3"></div>
                                {/* end row */}
                            </>
                        )}
                    </>
                )}
            </div>
        </form>
    );
};

export default GroupProfile;
