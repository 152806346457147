import axios from './restful.service';

const create = (workspaceId: string, params?: Record<any, any>) => {
    return axios.post(`/workspaces/${workspaceId}/properties`, params);
};
const get = (workspaceId: string, params?: Record<any, any>) => {
    return axios.get(`/workspaces/${workspaceId}/properties`, { params });
};
const getOptions = (workspaceId: string, propertyId: string, params?: Record<any, any>) => {
    return axios.get(`/workspaces/${workspaceId}/properties/${propertyId}/options`, { params });
};
const detail = (workspaceId: string, propertyId, params?: Record<any, any>) => {
    return axios.get(`/workspaces/${workspaceId}/properties/${propertyId}`, { params });
};
const update = (workspaceId: string, params: Record<any, any>) => {
    if (workspaceId) {
        return axios.patch(`/workspaces/${workspaceId}/properties/${params.id}`, { params });
    }
};
const deleted = (workspaceId: string, id?: string) => {
    return axios.patch(`/workspaces/${workspaceId}/properties/${id}`);
};
const visibility = (workspaceId: string, params?: any) => {
    return axios.post(`/workspaces/${workspaceId}/properties/visibility`, params);
};

export default {
    create,
    get,
    detail,
    update,
    deleted,
    visibility,
    getOptions
};
