import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AddNewLibrary } from "components/Library/components";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { TYPE_CREATE_CONTENT } from "components/Library/LessonBuilder/constants";
import { assignmentCreateContent, changeLibraryTypeAdd } from "store/features/assignmentSlice";
import { TrashIcon } from "@heroicons/react/outline";
import { LIBRARY_ICONS, LibraryTypeEnum } from 'components/Library/LibraryContants';
import { AssignmentDelete } from "../AssignmentDelete";
import { useParams } from "react-router-dom";

import DialogComponent from "components/Dialog/DialogComponent";
import CreateH5p from "components/Library/LessonBuilder/components/Content/CreateH5p/CreateH5p";
import FromLibrary from "components/Library/LessonBuilder/components/Content/FromLibrary/FromLibrary";
import AssignmentUploadFile from "../AssignmentUploadFile/AssignmentUploadFile";
import PreviewContentCommon from "_shared/PreviewContent/PreviewContentCommon";
import "./AssignmentBuilderContent.scss";
import { ButtonIcon } from "_shared";
import AssignmentSubmission from "../AssignmentSubmission/AssignmentSubmission";
import { checkPermission } from "utils/commonFun";

export const AssigmentBuilderContent = () => {
    const params: { id: string, assignmentId: string } = useParams();
    const { t: translator } = useTranslation();
    const { libraryTypeAdd, assignmentSelect, data, viewType, hasLoadData } = useAppSelector((state) => state.assignment);
    const dispatch = useAppDispatch();
    const refUpload = useRef<any>();
    const [confirmDelete, setConfirmDelete] = useState(false);

    const handleAddContentToSection = async (values) => {
        let addContentList = [];
        let list = [];
        if (values?.object_type == 'h5p') {
            list.push({
                ...values,
                name: values?.title
            });
        } else {
            list = values;
        }
        list?.forEach(item => {
            addContentList.push(dispatch(assignmentCreateContent({
                library_content: item,
                workspaceId: params?.id,
                assignmentId: params?.assignmentId,
                object_id: item?.object_id,
                object_type: item.object_type,
                order_number: data?.sections?.length > 0 ? data?.sections?.length + 1 : 0
            })));
        });
        await Promise.all([
            addContentList
        ]).then(() => { });
    }
    const handleAddNewLibrary = async (type: string) => {
        await dispatch(changeLibraryTypeAdd(type));
        if (type == TYPE_CREATE_CONTENT.UPLOAD) {
            refUpload.current.click();
        }
    }

    useEffect(() => {
        return () => {
            dispatch(changeLibraryTypeAdd(null));
        }
    }, [])

    return (<>
        <div className="assigmentBuilderContent">

            <div className="assigmentBuilderContent-main">
                {libraryTypeAdd != TYPE_CREATE_CONTENT.NEW_SUBMISSION &&
                    <div className={assignmentSelect || hasLoadData ? 'hidden' : 'h-full overflow-auto'}>
                        {/*@ts-ignore*/}
                        < AssignmentUploadFile ref={refUpload} />
                    </div>
                }
                {!!assignmentSelect && libraryTypeAdd != TYPE_CREATE_CONTENT.NEW_SUBMISSION &&
                    <div className={`${LibraryTypeEnum.submission != assignmentSelect?.library_content?.object_type ? 'assigmentBuilderPreview' : ''} assigmentBuilderPreview--${viewType}`}>
                        <div className="assigmentBuilderPreview-header">
                            <img
                                alt="libary-icon"
                                className="assigmentBuilderPreview-icon"
                                src={assignmentSelect?.library_content?.object_type ?
                                    LIBRARY_ICONS(translator)?.find((item) => item?.value === assignmentSelect?.library_content?.object_type)?.icon :
                                    LIBRARY_ICONS(translator)[0].icon
                                }
                            />
                            <div className="assigmentBuilderPreview-title truncate">
                                {assignmentSelect?.library_content?.name}
                            </div>
                            <div className="ml-auto">
                                <ButtonIcon onClick={() => setConfirmDelete(true)} disabled={!checkPermission('libraryContentAssignment', 'U')}>
                                    <TrashIcon className="delete" />
                                </ButtonIcon>
                            </div>
                        </div>
                        <div className={`assigmentBuilderPreview-preview`}><PreviewContentCommon contentSelected={assignmentSelect} viewType={viewType} colorProcess="text-gray-800" /></div>
                    </div>
                }
                {
                    libraryTypeAdd == TYPE_CREATE_CONTENT.NEW_SUBMISSION &&
                    <AssignmentSubmission data={undefined} />
                }
            </div>
            {checkPermission('libraryContentAssignment', 'U') ? <div className="assigmentBuilderContent-addNewLibrary">
                <AddNewLibrary onAddLibrary={handleAddNewLibrary} />
            </div> : ''}

        </div>
        {libraryTypeAdd == TYPE_CREATE_CONTENT.NEW_H5P ? (
            <DialogComponent
                isOpen={libraryTypeAdd == TYPE_CREATE_CONTENT.NEW_H5P}
                onCloseModal={() => libraryTypeAdd != TYPE_CREATE_CONTENT.NEW_H5P}
                hasNonTitle
                styles="max-w-[90vw] min-h-[90vh] h-auto my-0 rounded-lg"
                isShowClose={false}
                child={
                    <CreateH5p
                        handleAddContentToSection={handleAddContentToSection}
                        onCloseModal={() => {
                            dispatch(changeLibraryTypeAdd(null));
                        }}
                        payload={{ from_assignment: true }}
                    />
                }
            />
        ) : null}
        {(libraryTypeAdd == TYPE_CREATE_CONTENT.LIBRARY ||
            libraryTypeAdd == TYPE_CREATE_CONTENT.H5P_LIBRARY) ? (
            <DialogComponent
                isOpen={libraryTypeAdd == TYPE_CREATE_CONTENT.LIBRARY || libraryTypeAdd == TYPE_CREATE_CONTENT.H5P_LIBRARY}
                onCloseModal={() => dispatch(changeLibraryTypeAdd(null))}
                hasNonTitle
                styles="max-w-[70vw] min-h-[480px] my-0 rounded-lg !p-0 pb-4"
                isShowClose={false}
                child={
                    <FromLibrary
                        createContentType={libraryTypeAdd}
                        title={
                            libraryTypeAdd === TYPE_CREATE_CONTENT.H5P_LIBRARY
                                ? translator('LIBRARY.ADD_H5PS')
                                : translator('LIBRARY.ADD_FROM_LIBRARY')
                        }
                        onCloseModal={() => dispatch(changeLibraryTypeAdd(null))}
                        handleAddContentToSection={handleAddContentToSection}
                    />
                }
            />
        ) : null}
        <AssignmentDelete values={assignmentSelect} isOpen={confirmDelete} onClose={() => setConfirmDelete(false)} />
    </>)
}