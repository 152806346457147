import React, { Fragment, useEffect } from 'react';
// PACKAGE
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { debounce } from 'lodash';
// COMPONENTS
import UserDetailForm from 'components/User/UserProfile/UserDetailForm';
import EditUserProfileModal from 'components/User/UserProfile/EditUserProfileModal';
import {
    WorkspaceDetailInformation,
    WorkspaceListMembers,
} from 'components/Workspace/WorkspaceInformation';
import WorkspaceOverlay from 'components/Workspace/WorkspaceOverlay';
// CONTEXT
import GoogleAPIAndServicesProvider from 'contexts/Google/GoogleAPIAndServicesProvider';
import LeftMenuProvider from 'contexts/LeftMenu/LeftMenuProvider';
// UTILS
import { getLocalStorageAuthData } from 'utils/handleLocalStorage';
import GetListOfWorkspaceProvider from 'contexts/Workspace/GetListOfWorkspaceProvider';
import InviteMemberProvider from 'contexts/Workspace/InviteMemberProvider';
import UploadAvatarProvider from '../../contexts/User/UploadAvatarProvider';
import NewLeftMenu from 'components/MasterPage/LeftNavigation/NewLeftMenu';
import { useLocation } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import {
    MenuIcon,
    XIcon,
} from '@heroicons/react/outline';
import { NewBarType } from 'constant/setupBars.const';
import { useAppSelector } from 'hooks/hooks';
export interface MasterPageProps {
    isAuthStorage?: boolean;
    newBarType?: NewBarType;
    setAuthStorage?: React.Dispatch<React.SetStateAction<boolean>>;
}

const logo_fullblack = require(`assets/themes/${process.env.REACT_APP_WHITELABEL}/logo192.png`);

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const MasterPage: React.FC<MasterPageProps> = ({
    children,
    setAuthStorage,
    newBarType,
    isAuthStorage,
}) => {
    const {expandedMenu} = useAppSelector(state => state.configPage);
    const { access_token } = getLocalStorageAuthData();
    const [openUserDetail, setOpenUserDetail] = React.useState<boolean>(false);
    const [openEditUserModal, setOpenEditUserModal] = React.useState<boolean>(
        false
    );
    const [idParents] = React.useState<number>(-1);
    const [overlayVisible, setOverlayVisible] = React.useState<boolean>(false);
    const [overlayType, setOverlayType] = React.useState<string>('');
    const [hiddenMenu, setHiddenMenu] = React.useState<boolean>(false);
    const [sidebarOpen, setSidebarOpen] = React.useState<boolean>(false);
    const [useLayout, setUseLayout] = React.useState<boolean>(true);
    const location = useLocation();

    useEffect(() => {
        if (location?.pathname?.includes('/h5p-content/')) setUseLayout(false)
        else setUseLayout(true)
    }, [location])

    const handleToggleOverlay = (type?: string) => {
        if (type) {
            setOverlayType(type);
            setOverlayVisible(true);
        } else {
            setOverlayVisible(false);
            debounce(() => setOverlayType(''), 500);
        }
    };
    const returnComponent = (key: string) => {
        switch (key) {
            case 'detail':
                return (
                    <WorkspaceDetailInformation
                        token={access_token}
                        onClose={handleToggleOverlay}
                        setAuthStorage={setAuthStorage}
                    />
                );
            case 'member':
                return (
                    <WorkspaceListMembers
                        token={access_token}
                        onClose={handleToggleOverlay}
                    />
                );
            default:
                break;
        }
    };

    return (
        <UploadAvatarProvider>
            <LeftMenuProvider>
                <GoogleAPIAndServicesProvider>
                    <DndProvider backend={HTML5Backend}>
                        <GetListOfWorkspaceProvider>
                            <InviteMemberProvider>
                                {useLayout ? (
                                    <NewLeftMenu
                                        tabType={newBarType}
                                        setHiddenMenu={setHiddenMenu}
                                        hiddenMenu={hiddenMenu}
                                        setAuthStorage={setAuthStorage}
                                        isMobile={false}
                                        isParent={idParents}
                                        isAuthStorage={isAuthStorage}
                                    />
                                ) : null}

                                <div>
                                    {useLayout ? (
                                        <Transition.Root
                                            show={sidebarOpen}
                                            as={Fragment}
                                        >
                                            <Dialog
                                                as="div"
                                                className="fixed inset-0 flex z-40 md:hidden"
                                                onClose={setSidebarOpen}
                                            >
                                                <Transition.Child
                                                    as={Fragment}
                                                    enter="transition-opacity ease-linear duration-300"
                                                    enterFrom="opacity-0"
                                                    enterTo="opacity-100"
                                                    leave="transition-opacity ease-linear duration-300"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Dialog.Overlay className="fixed inset-0 dialog-background  bg-opacity-75" />
                                                </Transition.Child>
                                                <Transition.Child
                                                    as={Fragment}
                                                    enter="transition ease-in-out duration-300 transform"
                                                    enterFrom="-translate-x-full"
                                                    enterTo="translate-x-0"
                                                    leave="transition ease-in-out duration-300 transform"
                                                    leaveFrom="translate-x-0"
                                                    leaveTo="-translate-x-full"
                                                >
                                                    <div className="relative flex-1 flex flex-col max-w-xs w-full pb-2 bg-white">
                                                        <Transition.Child
                                                            as={Fragment}
                                                            enter="ease-in-out duration-300"
                                                            enterFrom="opacity-0"
                                                            enterTo="opacity-100"
                                                            leave="ease-in-out duration-300"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                        >
                                                            <div className="absolute top-0 right-0 -mr-12 pt-2">
                                                                <button
                                                                    type="button"
                                                                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                                                    onClick={() =>
                                                                        setSidebarOpen(
                                                                            false
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="sr-only">
                                                                        Close
                                                                        sidebar
                                                                    </span>
                                                                    <XIcon
                                                                        className="h-6 w-6 text-white"
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>
                                                            </div>
                                                        </Transition.Child>
                                                        <NewLeftMenu
                                                            tabType={newBarType}
                                                            setHiddenMenu={
                                                                setHiddenMenu
                                                            }
                                                            hiddenMenu={
                                                                hiddenMenu
                                                            }
                                                            setAuthStorage={
                                                                setAuthStorage
                                                            }
                                                            isMobile={true}
                                                            isParent={idParents}
                                                            isAuthStorage={isAuthStorage}
                                                        />
                                                    </div>
                                                </Transition.Child>
                                                <div
                                                    className="flex-shrink-0 w-14"
                                                    aria-hidden="true"
                                                >
                                                    {/* Dummy element to force sidebar to shrink to fit close icon */}
                                                </div>
                                            </Dialog>
                                        </Transition.Root>
                                    ) : null}

                                    {/* Static sidebar for desktop */}

                                    <div
                                        className={classNames(
                                            useLayout && expandedMenu ? 'md:pl-64' : 'pl-[68px]',
                                            'flex flex-col flex-1'
                                        )}
                                    >
                                        {useLayout ? (
                                            <div className="flex-1 min-w-0 flex flex-col overflow-hidden">
                                                {/* Mobile top navigation */}
                                                <div className="md:hidden">
                                                    <div className="bg-white py-2 px-4 flex items-center justify-between sm:px-6 lg:px-8">
                                                        <div>
                                                            <img
                                                                className="h-8 w-auto"
                                                                src={
                                                                    logo_fullblack
                                                                }
                                                                alt="Workflow"
                                                            />
                                                        </div>
                                                        <div>
                                                            <button
                                                                type="button"
                                                                className="-mr-3 h-12 w-12 inline-flex items-center justify-center bg-white rounded-md text-gray-900 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                                                onClick={() =>
                                                                    setSidebarOpen(
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                <span className="sr-only">
                                                                    Open sidebar
                                                                </span>
                                                                <MenuIcon
                                                                    className="h-6 w-6"
                                                                    aria-hidden="true"
                                                                />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                        <main className="flex-1">
                                            {children}
                                        </main>
                                    </div>
                                </div>

                                <UserDetailForm
                                    openUserDetail={openUserDetail}
                                    setOpenUserDetail={setOpenUserDetail}
                                    setOpenEditUserModal={setOpenEditUserModal}
                                />
                                <EditUserProfileModal
                                    titleText="Edit profile"
                                    openEditUserModal={openEditUserModal}
                                    setOpenEditUserModal={setOpenEditUserModal}
                                    setAuthStorage={setAuthStorage}
                                />
                                <WorkspaceOverlay
                                    onClickBackdrop={handleToggleOverlay}
                                    component={returnComponent(overlayType)}
                                    show={overlayVisible}
                                />
                            </InviteMemberProvider>
                        </GetListOfWorkspaceProvider>
                    </DndProvider>
                </GoogleAPIAndServicesProvider>
            </LeftMenuProvider>
        </UploadAvatarProvider>
    );
};

export default MasterPage;
