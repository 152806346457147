import React from "react";
import { AddIcon } from "assets/icon";
import { useTranslation } from "react-i18next";
import "./Section.scss";

export const NewSection = ({onClick}) => {
    const {t: translator} = useTranslation();
    return <>
        <button onClick={onClick} type="button" className="newSection">
            <AddIcon />
            <span>{translator('New Section')}</span>
        </button>    
    </>
}