import { FC, useContext, useMemo } from 'react';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'contexts/User/UserContext';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import dayjs from 'dayjs';
import NO_CONTENT from 'assets/img/empty-state/instructor.png';
import ActionMenu from './ActionMenu';
import { ASSIGNMENT_STATUS } from 'constant/course.const';

interface IViewTable {
    data: any;
    refreshData: Function;
    order: string;
    setOrder: (value: string) => void;
}   


const GradebookAssignmentTable: FC<IViewTable> = ({ order, data, refreshData, setOrder }) => {
    const { t } = useTranslation();
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;
    const handleChangeOrder = () =>{
        if (order === 'asc') {
            setOrder('desc')
        }
        if (order === 'desc') {
            setOrder('asc')
        }
    }
    const {
        userState: { result },
    } = useContext(UserContext);

    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={t('NAME')} />,
                accessor: 'name',
                Cell: ({row}: any) => {
                    return <RenderTableCell className='text-primary-500 font-semibold cursor-pointer' value={row.values?.name} />
                },
                width: "220"
            },
            {
                Header: () => <RenderTableHeader value={t('COURSES.DUE_DATE')} isOrder order={order} onClick={handleChangeOrder} className="text-primary-500 cursor-pointer" />,
                accessor: 'end_time',
                Cell: ({ row }: any) => <RenderTableCell 
                    value={`${dayjs.utc(row.values?.end_time).tz(result?.time_zone).format('DD/MM/YYYY')}`} />,
                width: "100"
            },
            {
                Header: () => <RenderTableHeader value={t('COURSES.COMPLETE_DATE')} />,
                accessor: 'completion_date',
                Cell: ({ row }: any) => <RenderTableCell 
                value={`${row.values?.completion_date ? dayjs.utc(row.values?.completion_date).tz(result?.time_zone).format('DD/MM/YYYY') : '-'}`} />,
                width: "100"
            },
            {
                Header: () => <RenderTableHeader value={t('STATUS')} />,
                accessor: 'state',
                Cell: ({ row }: any) => {
                    return (
                        <div className={`pl-ooolab_p_1_e`}>
                            <div className={`status status-${row.values?.state}`}>
                                {ASSIGNMENT_STATUS(t)[row.values?.state]}
                            </div>
                        </div>
                    );
                },
                width: "140"
            },
            {
                Header: () => <RenderTableHeader value={t('COURSES.GRADE')} />,
                accessor: 'final_score',
                Cell: ({ row }: any) => <RenderTableCell value={Math.round(row?.values?.final_score || 0)} className="pl-ooolab_p_1_e" />,
                width: "60"
            },
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="h-full flex items-center justify-center">
                            <ActionMenu data={{...row?.original, time_zone: result?.time_zone}} status={row.values?.state} refreshData={refreshData} />
                        </div>
                    );
                },
                accessor: 'action',
                width: 60,
            },
        ],
        [data, WorkspaceDetailInformation]
    );

    return (
        <Table
            data={data || []}
            columns={columns}
            emptyContent={t('COURSES.NO_ASSIGNMENTS')}
            emptyIcon={NO_CONTENT}
        />
    );
};

export default GradebookAssignmentTable;
