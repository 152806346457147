import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CourseHeader } from "./components/CourseHeader/CourseHeader";
import { CourseSideLeft } from "./components/CourseSideLeft/CourseSideLeft";
import { CourseInformation } from "./components/CourseInformation/CourseInformation";
import { changeShowCourseSetting, courseCreate, courseFetch, getCourseSettings, setCourse, setCourseSetting, updateSetting } from "store/features/courseSlice";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import PageBreadcrumb from "components/Breadcrumb/PageBreadcrumb";
import "./CourseBuilder.scss";
import { CourseSetting } from "../CourseSetting/CourseSetting";
import { CourseAcademics } from "./components/CourseSyllabus/CourseAcademics";
import CoursePeople from "./components/CoursePeople/CoursePeople";
import Detail from "./components/CoursePeople/Enrollment/Detail/EnrollmentDetail";
import CourseScheduling from "./components/CourseScheduling/CourseScheduling";
import { CourseGradebook } from "./components/CourseGradebook/CourseGradebook";
import { checkPermission, UUID } from "utils/commonFun";
import CourseReport from "./components/CourseReport/CourseReport";
import { CourseCertificate } from "./components/CourseCertificate/CourseCertificate";
import Assignment from "./components/CourseAssignment/Assignments";
import Grades from "./components/CourseAssignment/Grades";
import CourseCreditBalance from "./components/CourseCreditBalance";

type CourseBuilderProp = {
    children: React.ReactNode
}
const COURSE_TAB = {
    information: 'information',
    academics: 'academics',
    people: 'people',
    scheduling: 'scheduling',
    gradebook: 'gradebook',
    reports: 'reports',
    certificate: 'certificate',
    assignment_management: 'assignment-management',
    credit_balance: 'credit-balance',
};

export const CourseBuilder = ({ children }: CourseBuilderProp) => {
    const { data: { title }, hasShowCourseSetting } = useAppSelector((state) => state.course);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { t: translator } = useTranslation();
    const params: { id: string, courseId: string } = useParams();
    const search = useLocation()?.search;
    const enrollmentIdParam = new URLSearchParams(search).get('enrollmentId')
    const subtab = new URLSearchParams(search).get('subtab');
    const tab = new URLSearchParams(search).get('tab');
    const [numberKey, setNumberKey] = useState<any>(UUID())

    const hasCourseSetting = useMemo(() => {
        return hasShowCourseSetting;
    }, [hasShowCourseSetting]);

    useEffect(() => {
        const createCourse = async () => {
            const response = await dispatch(
                courseCreate({
                    workspaceId: params?.id,
                    title: 'Untitled Course',
                })
            );
            if (response) {
                await dispatch(
                    updateSetting({
                        workspaceId: params.id,
                        id: response?.payload.data?.id,
                        course_completion_weight: 25,
                        assignment_grade_weight: 25,
                        lesson_goal_weight: 25,
                        attendance_weight: 25,
                        allow_instructor_grading: false
                    })
                );
                if (response?.payload?.data?.id) {
                    history.push(`/workspace/${params.id}/management/courses/${response?.payload.data?.id}`);
                }
            }
        };
        if (params?.courseId === 'new') {
            createCourse();
        } else {
            dispatch(
                courseFetch({
                    workspaceId: params.id,
                    id: params.courseId,
                })
            );
            dispatch(
                getCourseSettings({
                    workspaceId: params.id,
                    id: params.courseId,
                })
            )
        }
    }, [params?.courseId]);
    //@ts-ignore
    useEffect(() => {
        return () => {
            dispatch(setCourse({}))
            dispatch(setCourseSetting({}))
        }
    }, [])

    return (
        <div className="relative">
            <PageBreadcrumb
                pageNameDefault={translator("COURSES.LEARNING_MANAGEMENT")}
                pages={[
                    { name: translator('COURSES.COURSES'), href: `/workspace/${params.id}/management/courses`, className: '' },
                    { name: title ? title : translator('COURSES.NEW_COURSE'), href: ``, className: '' },
                ]}
                containerClassName="z-10 sticky top-0"
            />
            <div className="sticky top-[53px] z-[10] bg-white w-full">
                <CourseHeader />
            </div>
            <main className="courseBuilder-main">
                <div className="relative">
                    <div className="courseBuilder-side">
                        {checkPermission('course', 'R') && <CourseSideLeft />}
                    </div>
                </div>

                <div id="courseBuilder-content" className="courseBuilder-content">
                    {
                        enrollmentIdParam ? <Detail />
                            :
                            <div className="courseBuilder-container" key={numberKey}>
                                {tab == COURSE_TAB.information || !tab ? <CourseInformation /> : null}
                                {tab == COURSE_TAB.academics ? <CourseAcademics /> : null}
                                {tab == COURSE_TAB.people ? <CoursePeople /> : null}
                                {tab === COURSE_TAB.scheduling ? <CourseScheduling /> : null}
                                {tab === COURSE_TAB.gradebook ? <CourseGradebook /> : null}
                                {tab === COURSE_TAB.assignment_management && (
                                    subtab === 'assignments' ? <Assignment /> : <Grades />
                                )}
                                {tab === COURSE_TAB.reports ? <CourseReport /> : null}
                                {tab === COURSE_TAB.certificate ? <CourseCertificate /> : null}
                                {tab === COURSE_TAB.credit_balance ? <CourseCreditBalance /> : null}
                            </div>
                    }
                </div>
            </main>
            {hasCourseSetting ? <CourseSetting show={hasCourseSetting} onClose={() => {
                dispatch(changeShowCourseSetting(false));
            }}
                setNumberKey={setNumberKey}
              /> : null}
        </div>
    )
}