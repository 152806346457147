import React from 'react';
import Lesson from 'components/Workspace/Lesson';
import RouteMasterPage from 'pages/RouteMasterPage';

const LessonPage = () => {
    return (
        <Lesson>
            <RouteMasterPage  />
        </Lesson>
    );
};

export default LessonPage;
