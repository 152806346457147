import { useState } from 'react';
import Select from 'react-select';
import { OptionComponent, customSelectStyle } from './constant';
import './style.css';

export default function index({ options, selectedOptions, onChange, optionAll, title, loadMore, loading = true }) {
    const [menuOpen, setMenuOpen] = useState(undefined);
    const MultiValueContainer = (e) => {
        const result = selectedOptions?.[0]?.value === 'all' ? ' Select All' : `${selectedOptions.length} Selected`;
        return (
            <div className="text-xs text-dark-300 font-semibold px-5">
                {title}: {result}
            </div>
        );
    };

    const handleSelect = (selected, actionMeta) => {
        if (actionMeta?.action === 'select-option' && actionMeta?.option?.value === 'all') {
            setMenuOpen(false);
        }

        const hasSelectAll = selected.find((item) => item.value === 'all');
        const hasSelectAllState = selectedOptions.find((item) => item.value === 'all');

        if (hasSelectAll && selected.length !== options.length) onChange([optionAll, ...options]);
        else if (selected.length === options.length && hasSelectAll && hasSelectAllState) {
            const arr = selected.filter((item) => item.value !== 'all');
            onChange(arr);
        } else if (selected.length === options.length && hasSelectAll && !hasSelectAllState) onChange([optionAll, ...options]);
        else if (!hasSelectAll && selectedOptions.length < options.length && selected.length !== options.length) onChange(selected);
        else if (!hasSelectAll && selectedOptions.length < options.length && selected.length === options.length)
            onChange([optionAll, ...selected]);
        else if (!hasSelectAll) onChange([]);
        else onChange(selected);
    };

    const handleCheckSelectAll = () => {
        const hasSelectAll = selectedOptions.find((item) => item.value === 'all');
        if (hasSelectAll) loadMore(true);
        else loadMore(false);
    };
    const onMenuOpen = () => {
        if (menuOpen !== undefined) setMenuOpen(undefined);
    };
    return (
        <Select
            components={{ Option: OptionComponent, MultiValueContainer: (e) => null, Input: MultiValueContainer }}
            className="instructor-select text-dark-300 rounded font-normal "
            styles={customSelectStyle}
            hideSelectedOptions={false}
            options={[optionAll, ...options]}
            isMulti
            menuIsOpen={menuOpen}
            onMenuOpen={onMenuOpen}
            closeMenuOnSelect={false}
            menuPlacement="auto"
            defaultValue={selectedOptions}
            onChange={(select, actionMeta) => handleSelect(select, actionMeta)}
            value={selectedOptions}
            placeholder={false}
            maxMenuHeight={200}
            onMenuScrollToBottom={handleCheckSelectAll}
            isLoading={loading}
        />
    );
}
