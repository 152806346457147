import { DocumentPreview } from '_shared/components/DocumentPreview/DocumentPreview';
import { DownloadIcon } from 'assets/icon';
import { EyesIcon } from 'assets/icon/EyesIcon';
import PdfIcon from 'assets/icon/PdfIcon';
import axios from 'axios';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { dowloadFile } from 'utils/commonFun';

export default function File({file}) {
  const [fileData, setFileData] = useState(undefined);
  const [isOpenModalPreviewFile, setOpenModalPreviewFile] = useState(false);
  const {t: translator} = useTranslation();
  const getFileData = async () => {
    const information = await axios({ url: file?.url, method: 'HEAD' });
    const size = +information?.headers?.['content-length'] / (1024 * 1024);
    setFileData({
        size: size < 1 ? `${(size * 1024)?.toFixed(0)}KB` : `${size?.toFixed(1)}MB`,
        lastModified: information?.headers?.['last-modified'],
        type: file?.type || information?.headers?.['content-type'],
        url: file?.url,
        name: file?.name,
    });
  }

  useEffect(() => {
    getFileData();
  }, [file])

  const download = () => {
    dowloadFile(fileData?.url, fileData?.name);
  }

  return (
    <div className="border border-gray-300 rounded-lg p-3 flex justify-between shadow-sm" >
        <div className="flex items-center gap-2 cursor-pointer">
            <PdfIcon />
            <div>
                <div className="text-sm pr-3 text-primary-500">{fileData?.name}</div>
                <div className="text-xs flex gap-2 text-gray-500">
                    <div>{fileData?.size}</div>・
                    <div>{translator('UPLOADED_ON')} {dayjs(fileData?.lastModified)?.format('DD/MM/YYYY')}</div>
                </div>
            </div>
        </div>
        <div className="border-l border-gray-300 px-3 text-gray-500 flex gap-4 items-center">
            <div className='cursor-pointer' onClick={() => setOpenModalPreviewFile(true)}>
              <EyesIcon width="18" height="16" fill="#111827" />
            </div>
             <div className='cursor-pointer' onClick={download}>
                <DownloadIcon width="24px" height="24px" fill="text-gray-500" />
            </div>
        </div>
        <DocumentPreview data={fileData} isOpen={isOpenModalPreviewFile} closeModal={() => setOpenModalPreviewFile(false)} />
    </div>
  )
}
