import { useTranslation } from "react-i18next";
import "./index.scss";
import { StripeIcon } from "assets/icon/StripeIcon";
import { ButtonOutline, ButtonPrimary } from "_shared";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { useEffect, useState } from "react";


interface IProps {
    isLoading?: boolean;
    onConfirm: (value: any) => Promise<void>;
    onClose: () => void
}

export const ConfirmAddPayment = ({ isLoading, onConfirm, onClose }: IProps) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        setLoading(loading);
        return () => {
            setLoading(false);
        }
    }, [isLoading]);

    const translator = (str: string, newKey?: boolean, opt?: any) => {
        return newKey ? t(str, opt) : t(`E_COMMERCE.${str}`, opt)
    }

    return (
        <>
            <div className="stripeTerm">
                <div className="overview">
                    <div className="overview-header">
                        <h2 className="text-base font-semibold">{translator("OVERVIEW")}</h2>
                        <StripeIcon />
                    </div>
                    <p className="textDetail mt-3">{translator("OVERVIEW_STRIPE")}</p>
                </div>
                <section className="stripeTerm-item">
                    <h1 className="textHeader mb-4">{translator("STRIPE_TRANSACTION_FEES")}</h1>
                    <div >
                        <h3 className="textSubHeader">1. {translator('STANDARD_TRANSACTION_FEES')}</h3>
                        <ul>
                            <li>
                                <p className="textDetail">{translator("STANDARD_TRANSACTION_1")}</p>
                            </li>
                            <li>
                                <p className="textDetail">{translator("STANDARD_TRANSACTION_2")}</p>
                            </li>
                            <li>
                                <p className="textDetail">{translator("STANDARD_TRANSACTION_3")}</p>
                            </li>
                        </ul>
                    </div>

                    <div className="mt-1" >
                        <h3 className="textSubHeader">2. {translator('STRIPE_ADDDITIONAL_FEES')}</h3>
                        <ul>
                            <li>
                                <p className="textDetail">{translator("STRIPE_ADDDITIONAL_1")}</p>
                            </li>
                            <li>
                                <p className="textDetail">{translator("STRIPE_ADDDITIONAL_2")}</p>
                            </li>
                            <li>
                                <p className="textDetail">{translator("STRIPE_ADDDITIONAL_3")}</p>
                            </li>
                        </ul>
                    </div>
                </section>
                <h1 className="textHeader my-1 px-6">{translator("FEE_STRUCTURE")}</h1>
                <section className="stripeTerm-item !py-0 mb-4">
                    <div >
                        <h3 className="textSubHeader">1. {translator('LEARNINGOS_TRANSACTION_FEES')}</h3>
                        <ul>
                            <li>
                                <p className="textDetail">{translator("LEARNINGOS_TRANSACTION_1")}</p>
                            </li>
                        </ul>
                    </div>

                    <div className="mt-1" >
                        <h3 className="textSubHeader">2. {translator('STRIPE_FEES')}</h3>
                        <ul>
                            <li>
                                <p className="textDetail">{translator("STRIPE_FEES_1")}</p>
                            </li>
                        </ul>
                    </div>
                </section>
                <h1 className="textHeader my-1 px-6">{translator("STRIPE_PAYMENT_PROCESSING")}</h1>
                <section className="stripeTerm-item !py-0 mb-4">
                    <div >
                        <h3 className="textSubHeader">1. {translator('PROCESSING_TIME')}</h3>
                        <ul>
                            <li>
                                <p className="textDetail">{translator("PROCESSING_TIME_1")}</p>
                            </li>
                        </ul>
                    </div>

                    <div className="mt-1" >
                        <h3 className="textSubHeader">2. {translator('PAYOUTS')}</h3>
                        <ul>
                            <li>
                                <p className="textDetail">{translator("PAYOUTS_1")}</p>
                            </li>
                        </ul>
                    </div>
                </section>
                <h1 className="textHeader my-1 px-6">{translator("REPORTING_AND_DISPUTES")}</h1>
                <section className="stripeTerm-item !py-0 mb-4">
                    <div >
                        <h3 className="textSubHeader">1. {translator('TRANSACTION_REPORTING')}</h3>
                        <ul>
                            <li>
                                <p className="textDetail">{translator("TRANSACTION_REPORTING_1")}</p>
                            </li>
                        </ul>
                    </div>

                    <div className="mt-1" >
                        <h3 className="textSubHeader">2. {translator('DISPUTE_RESOLUTION')}</h3>
                        <ul>
                            <li>
                                <p className="textDetail">{translator("DISPUTE_RESOLUTION_1")}</p>
                            </li>
                        </ul>
                    </div>
                </section>
                <h1 className="textHeader my-1 px-6">{translator("COMPLIANCE_AND_SECURITY")}</h1>
                <section className="stripeTerm-item !py-0 mb-4">
                    <div >
                        <h3 className="textSubHeader">1. {translator('COMPLIANCE')}</h3>
                        <ul>
                            <li>
                                <p className="textDetail">{translator("COMPLIANCE_1")}</p>
                            </li>
                        </ul>
                    </div>

                    <div className="mt-1" >
                        <h3 className="textSubHeader">2. {translator('DATA_PRIVACY')}</h3>
                        <ul>
                            <li>
                                <p className="textDetail">{translator("DATA_PRIVACY_1")}</p>
                            </li>
                        </ul>
                    </div>
                </section>
                <h1 className="text-base my-1 px-6 font-semibold">{translator("CHANGE_TO_THIS_POLICY")}</h1>
                <p className="textDetail my-1 px-6">{translator('CHANGE_TO_THIS_POLICY_1')}</p>
                <h1 className="text-base my-1 px-6 font-semibold">{translator("CONTRACT_US")}</h1>
                <p className="textDetail my-1 px-6 ">{translator('CONTRACT_US_1')}</p>
                <div className="my-1 px-6 py-2">
                    <p className="text-sm font-semibold">{translator('EFFECTIVE_DATE_TITLE')}<span className="text-sm font-normal">{translator('EFFECTIVE_DATE')}</span></p>
                </div>
            </div>
            <div className="confirmActions">
                <ButtonOutline type="button" disabled={loading} onClick={() => onClose()}>{t("CANCEL")}</ButtonOutline>
                <ButtonPrimary type="button" disabled={loading} onClick={() => {
                    onConfirm(true);
                    setLoading(true);
                }} className="">
                    {loading ? <span className="confirmDeleteModal-loading"><CircleSpin /></span> :
                        translator("ACCEPT_CONTINUE")}
                </ButtonPrimary>
            </div>
        </>
    )
}