import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { timeList } from "constant/workspace.const";
import { CardContainer } from "components/Reports/components/CardContainer";
import { Title } from "components/Reports/components/Title";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import ChartDonut from "components/Reports/components/ChartDonut/ChartDonut";
import { ComboboxSelect } from "components/ComboboxSelect/ComboboxSelect";
import { CardInfo } from "components/Reports/components/CardInfo";
import studentService from "services/reports/student-report.service";
import { EmptyStateChart } from "_shared";
import learnerReportService from "services/reports/learner-report.service";
import moment from "moment";
import DatePickerComponent from "components/Reports/components/DatePicker";

interface CoinsType {
    total_earned: string;
    total_earned_changes_percent: string;
    total_spent: string;
    total_spent_changes_percent: string;
}

export const StudentOverviewCoin = () => {
    const { t } = useTranslation();
    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);
    const params: { id: string, studentId: string } = useParams();
    const [loading, setLoading] = useState(true);
    const [coinsData, setCoinsData] = useState<CoinsType>();
    const [dataLabel, setDataLabel] = useState([{ status: 'spent' }, { status: 'earned' },]);
    const [chartData, setChartData] = useState([]);
    const search = useLocation()?.search;
    const type = new URLSearchParams(search).get('type'); 

    const getData = async () => {
        if (!startDate || !endDate) {
            return;
        }
        try {
            setLoading(true);
            const payload = {
                start_time: moment(new Date(startDate)).format('YYYY-MM-DD')+ 'T00:00:00.000',
                end_time: moment(new Date(endDate)).format('YYYY-MM-DD')+ 'T23:59:59.000',
                studentId: params?.studentId,
            }
            let response;
            if (type === 'learning-group') {
                response = await learnerReportService.getLearnerGroupOverviewsCoins(params.id, payload);
            } else {
                response = await learnerReportService.getLearnerOverviewsCoins(params.id, payload);
            }
            setCoinsData(response?.data);
            setLoading(false);
        } catch {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (+coinsData?.total_spent === 0 && +coinsData?.total_earned == 0) {
            setChartData([]);
        } else {
            setChartData([
                +coinsData?.total_spent,
                +coinsData?.total_earned
            ])
        }
    }, [coinsData]);

    const [startDate, setStartDate] = useState(new Date(moment().subtract(1, 'months').format('MM-DD-YYYY')));
    const [endDate, setEndDate] = useState(new Date());
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    useEffect(() => {
        if (params?.id) {
            getData();
        }
    }, [startDate, endDate]);

    return <>
        <div className="w-full min-h-[332px]">
            <CardContainer>
                {loading ?
                    <div className="flex justify-center items-center w-full min-h-[285px]">
                        <CircleSpin color="text-primary-500" />
                    </div> :
                    <>
                        <div className="flex items-center">
                            <Title>{translator('Coins')}</Title>
                            <div className="ml-auto min-w-[132px]">
                                <DatePickerComponent onChange={onChange} startDate={startDate} endDate={endDate} />
                            </div>
                        </div>
                        <div className="flex mt-4 items-center">
                            <div className="flex-none w-[220px]">
                                {
                                    +coinsData?.total_spent === 0 && +coinsData?.total_earned == 0 ?
                                        <EmptyStateChart /> :
                                        <ChartDonut label={translator(dataLabel[0].status)}
                                            data={chartData}
                                            config={{ width: 220, height: 220 }}
                                            dataLabel={dataLabel}
                                            hideLabel={true}
                                            colors={+coinsData?.total_spent > 0 ? ['#D1D5DB', '#0071CE'] : ['#0071CE', '#D1D5DB']}
                                            hasPercent={true}   
                                        />
                                }
                            </div>
                            <div className="h-8 w-[1px] bg-gray-300 mx-2.5"></div>
                            <div className="w-1/3 ml-5" >
                                <CardInfo  {...{
                                    value: coinsData?.total_earned,
                                    percent: coinsData?.total_earned_changes_percent,
                                    title: `${translator('Total Earned')}`
                                } as any } />
                            </div>
                            <div className="w-1/3 ml-5">
                                <CardInfo  {...{
                                    value: coinsData?.total_spent,
                                    percent: coinsData?.total_spent_changes_percent,
                                    title: `${translator('Total Spent')}`
                                } as any} />
                            </div>
                        </div>
                    </>
                }
            </CardContainer>
        </div>
    </>
}