import * as React from 'react';
// PACKAGE
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
// CONTEXT
import { PasswordContext } from 'contexts/Password/PasswordContext';
// CONSTANT
import { FORM_CONST } from 'constant/form.const';
// LOGIC
import { handleChangeEmail, onForgotSubmit } from './ForgotPasswordFormFn';
// ASSETS
import { useTranslation } from 'react-i18next';
import { loadSVG } from 'assets/icon';
import { ArrowLeftIcon, CheckCircleIcon } from '@heroicons/react/outline';
import { classNames } from 'utils/commonFun';
import { MESSAGE } from 'constant/message.const';
const Logo = require(`assets/themes/${process.env.REACT_APP_WHITELABEL}/logo.svg`);

const ForgotPasswordForm: React.FC<any> = ({whiteLabel}) => {
    const { t: translator } = useTranslation();
    const history = useHistory();
    const [
        apiForgotPasswordSuccess,
        setApiForgotPasswordSuccess,
    ] = React.useState<string>();
    const [apiError, setApiError] = React.useState<string>();
    const { passwordState, dispatch } = React.useContext(PasswordContext);
    const { isLoading, forgotPasswordResult, err, valErr } = passwordState;
    const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
    const [timeLeft, setTimeLeft] = React.useState<number>(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        trigger,
        watch,
    } = useForm();

    React.useEffect(() => {
        if (forgotPasswordResult === 204) {
            setApiForgotPasswordSuccess(
                MESSAGE.EMAIL_WAS_SENT_PLEASE_CHECK_YOUR_EMAIL
            );
        }
        if (err) {
            setApiError(err.error.description);
        }
        if (valErr) {
            setApiError(valErr.validation_error.body_params[0].msg);
        }
    }, [forgotPasswordResult || err || valErr]);

    React.useEffect(() => {
        setIsSubmit(false);
        if (validateEmail(watch('email'))) setIsSubmit(true);
    }, [watch('email')]);

    React.useEffect(() => {
        setIsSubmit(false);
        if (!timeLeft) {
            setIsSubmit(true);
            return;
        }
        const intervalTime = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);
        return () => clearInterval(intervalTime);
    }, [timeLeft]);

    const validateEmail = (email: string) => {
        return String(email).toLowerCase().match(FORM_CONST.EMAIL_REGEX);
    };

    const displayTimeLeft = (_seconds: number) => {
        let hours: number | string;
        let minutes: number | string;
        let seconds: number | string;

        hours = Math.floor(_seconds / 3600);
        minutes = Math.floor((_seconds - hours * 3600) / 60);
        seconds = _seconds - hours * 3600 - minutes * 60;

        if (hours < 10) hours = '0' + hours;
        if (minutes < 10) minutes = '0' + minutes;
        if (seconds < 10) seconds = '0' + seconds;

        return `${minutes}:${seconds}`;
    };

    const onSubmitForm = () => {
        onForgotSubmit(getValues, dispatch);
        setTimeLeft(300);
    };

    return (
        <div className="relative">
            <div className="absolute top-0 right-0 w-full flex justify-between items-center lg:px-10 px-5 lg:pt-8 pt-5">
                <button
                    title="ArrowLeftIcon"
                    type="button"
                    className="cursor-pointer"
                    onClick={() => history.push('/login')}
                >
                    <ArrowLeftIcon className="lg:w-6 w-5" />
                </button>
                {/* <div className="flex justify-end items-center">
                    <p className="text-sm text-blue_0">
                        {translator('DO_NO_HAVE_AN_ACCOUNT')}
                    </p>
                    <button
                        type="button"
                        className="lg:ml-6 ml-3 border rounded-btn_10 lg:px-10 px-4 py-3 text-xs text-gray_0 font-medium"
                        onClick={() => history.push('/register')}
                    >
                        {translator('GET_STARTED')}
                    </button>
                </div> */}
            </div>
            <div className="flex flex-col items-center justify-center min-h-screen py-20">
                <img className='mx-auto mb-10 max-h-[148px]' src={whiteLabel?.data?.web?.logo || Logo.default} alt="logo" />
                <form
                    className="sm:mx-auto sm:w-full sm:max-w-md p-4"
                    onSubmit={handleSubmit(onSubmitForm)}
                >
                    <p className="text-3xl font-extrabold text-gray-700 text-center">
                        {translator(
                            'FORGOT_YOUR_PASSWORD.FORGOT_YOUR_PASSWORD'
                        )}
                    </p>
                    <div className="mt-2">
                        <p className="text-sm font-medium text-gray-700 text-center">
                            {translator(
                                'FORGOT_YOUR_PASSWORD.WE_WILL_HELP_YOU_RESET_IT_AND_GET_BACK_ON_TRACK'
                            )}
                        </p>
                        {!!apiForgotPasswordSuccess && (
                            <div className="rounded-lg mt-5 p-2 bg-ooolab_E5FFE7 flex items-center">
                                <CheckCircleIcon className="w-6 text-ooolab_50B957" />
                                <p className="text-sm ml-4">
                                    {translator(
                                        'FORGOT_YOUR_PASSWORD.IF_YOU_HAVE_AN_ACCOUNT_WE_WILL_EMAIL_YOU_A_RESET_LINK'
                                    )}
                                </p>
                            </div>
                        )}
                        <div className="mt-6">
                            <input
                                type="email"
                                autoComplete="email"
                                required
                                placeholder="Email"
                                className={classNames(
                                    'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm',
                                    errors.email && 'border-red-600'
                                )}
                                {...register('email', {
                                    required: true,
                                    pattern: FORM_CONST.EMAIL_REGEX,
                                    setValueAs: (value) => value.trim(),
                                })}
                                name="email"
                                onChange={handleChangeEmail(setValue, trigger)}
                            />
                        </div>
                        {/* {errors.email && errors.email.type === 'required' && (
                            <span className="text-red-500 ooolab_paragraph_1">
                                {translator('FORM_CONST.REQUIRED_FIELD')}
                            </span>
                        )}
                        {errors.email && errors.email.type === 'pattern' && (
                            <span className="text-red-500 ooolab_paragraph_1">
                                {translator('FORM_CONST.EMAIL_VALIDATE')}
                            </span>
                        )}
                        {apiError && (
                            <span className="text-red-500 ooolab_paragraph_1">
                                {apiError}
                            </span>
                        )} */}
                    </div>
                    <button
                        type="submit"
                        disabled={!isSubmit}
                        className={classNames(
                            'w-full mt-8 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ',
                            'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
                            !isSubmit
                                ? 'bg-ooola_EFF0FA text-ooolab_8083A3 cursor-not-allowed'
                                : 'bg-primary-500 hover:bg-primary-500/80'
                        )}
                    >
                        {!!apiForgotPasswordSuccess ? (
                            <>
                                {translator(
                                    'FORGOT_YOUR_PASSWORD.RESEND_IN_0500',
                                    { time: displayTimeLeft(timeLeft) }
                                )}
                            </>
                        ) : (
                            <>
                                {isLoading ? loadSVG() : null}
                                {translator(
                                    'FORGOT_YOUR_PASSWORD.RESET_PASSWORD'
                                )}
                            </>
                        )}
                    </button>
                    {!!apiForgotPasswordSuccess && (
                        <div className="text-xs font-medium text-ooolab_8083A3 mt-4 text-center">
                            {translator(
                                'FORGOT_YOUR_PASSWORD.IF_YOU_HAVE_AN_ACCOUNT_WE_WILL_EMAIL_YOU_A_RESET_LINK'
                            )}
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default ForgotPasswordForm;
