import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import { ErrorMessage } from '@hookform/error-message';
import Timezone from 'dayjs/plugin/timezone';
import { UserContext } from 'contexts/User/UserContext';
import { useBoolean } from 'hooks/custom';
import ModalUploadImage from 'components/Management/components/UploadImage';
import { useTranslation } from 'react-i18next';
import DatePickerInput from 'components/Management/components/Form/DatePicker';
import InputNumber from './InputNumber';
import rewardService from 'services/reward.service';
import userService from 'services/user.service';
import { RewardsContext } from 'contexts/Rewards/RewardsContext';
import rewardMiddleware from 'middleware/reward.middleware';
import { Toastify } from '_shared';

dayjs.extend(Timezone);

interface CreateRewardFormProps {
    onSuccessCreate: () => void;
    closeModal: () => void;
    callbackWhenDirty?: (v: boolean) => void;
    selectedIndex?: number;
}

const CreateRewardForm: React.FC<CreateRewardFormProps> = ({
    onSuccessCreate,
    closeModal,
    callbackWhenDirty,
    selectedIndex = 0,
}) => {
    const { userState } = useContext(UserContext);
    const { result } = userState;
    const { t: translator } = useTranslation();
    const { dispatch: dispatchRewards, rewardsState } = useContext(
        RewardsContext
    );

    const {
        booleanValue: isUploadImage,
        toggleBooleanValue: toggleUploadImage,
    } = useBoolean();
    const [dataLevel, setDataLevel] = useState<any>();
    const [tmpAvatar, setTmpAvatar] = useState<{
        file: File;
        canvas: HTMLCanvasElement;
    }>();

    const [createStatus, setCreateStatus] = useState<
        'success' | 'error' | 'init'
    >('init');
    const params: { id: string } = useParams();
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        control,
        trigger,
        getValues,
        setValue,
        clearErrors,
        watch,
    } = useForm();
    const dateStartWatch = watch('reward_start_date')
    const dateEndWatch = watch('reward_end_date')

    useEffect(() => {
        if (isDirty || !!tmpAvatar) {
            callbackWhenDirty(true);
        }
    }, [isDirty, tmpAvatar]);
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [errImg, setErrImg] = useState(false);

    const toggleLoadingCreate = () => {
        setLoadingCreate(!loadingCreate);
    };

    useEffect(() => {
        rewardService.getApoLevelService(params.id).then((res: any) => {
            if (res) {
                setValue('reward_level', res?.data?.items[0]?.id);
                setDataLevel(res?.data?.items);
            }
        });
    }, []);

    const {
        userState: { result: UserInformation },
    } = useContext(UserContext);

    const submitForm = async () => {
        const values = getValues();
        if(loadingCreate) {
            return;
        }
        if (tmpAvatar) {
            setLoadingCreate(true);
            await userService.uploadImage(
                tmpAvatar.file,
                (resUpload) => {
                    const formData = {
                        reward_level_id: values?.reward_level,
                        name: values?.reward_name,
                        description: values?.description,
                        thumbnail: resUpload || null,
                        start_date: dayjs(values?.reward_start_date).format(
                            'YYYY-MM-DD'
                        ),
                        end_date: dayjs(values?.reward_end_date).format(
                            'YYYY-MM-DD'
                        ),
                        total_quantity: parseInt(values?.reward_quantity),
                        price: parseInt(values?.reward_apo),
                        is_featured: values?.reward_feature,
                    };
                    rewardService
                        .createRewardService(
                            { workspaceId: params?.id },
                            formData
                        )
                        .then((res) => {
                            setErrImg(false);
                            onSuccessCreate();
                            closeModal();
                            Toastify.success();
                            if (selectedIndex === 1) {
                                rewardMiddleware.getListReward(
                                    params?.id,
                                    dispatchRewards,
                                    {
                                        per_page: 20,
                                    }
                                );
                            } else {
                                rewardMiddleware.getListRewardFutured(
                                    params?.id,
                                    dispatchRewards,
                                    {
                                        is_featured: true,
                                        per_page: 20,
                                    }
                                );
                            }
                            setLoadingCreate(false);
                        })
                        .catch(() => {
                            closeModal();
                            Toastify.error();
                            setLoadingCreate(false);
                        });
                },
                (err) => {
                    setErrImg(false);
                    setLoadingCreate(false);
                    Toastify.error();
                },
                tmpAvatar.canvas
            );
        } else {
            setErrImg(true);
        }
    };

    return (
        <form className="relative CreateRewardForm" onSubmit={handleSubmit(submitForm)}>
            <ModalUploadImage
                isOpen={isUploadImage}
                onClose={() => {
                    toggleUploadImage();
                }}
                onSubmitImage={(f, c) => {
                    setTmpAvatar({
                        file: f,
                        canvas: c,
                    });
                }}
            />

            <div className="relative h-full">
                <div className="grid grid-cols-2 px-2 gap-x-5 gap-y-5 ">
                    <div className="col-span-2 flex items-center justify-center py-6 w-full">
                        <div className="relative rounded-3xl overflow-hidden w-32 h-32 group hover:shadow transition-shadow duration-200">
                            {tmpAvatar && (
                                <img
                                    src={tmpAvatar.canvas.toDataURL()}
                                    className="w-full h-full object-cover"
                                    alt="img"
                                />
                            )}
                            <svg
                                width="120"
                                height="120"
                                viewBox="0 0 120 120"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={toggleUploadImage}
                                className="cursor-pointer w-full h-full"
                            >
                                <rect
                                    width="120"
                                    height="120"
                                    rx="24"
                                    fill="#FAFAFC"
                                />
                                <path
                                    d="M46.6667 46.6667C46.6667 44.8258 48.1591 43.3334 50 43.3334H70C71.8409 43.3334 73.3333 44.8258 73.3333 46.6667V75C73.3333 75.6244 72.9844 76.1963 72.4293 76.482C71.8742 76.7677 71.206 76.7191 70.6979 76.3563L60 68.7149L49.3021 76.3563C48.794 76.7191 48.1258 76.7677 47.5707 76.482C47.0156 76.1963 46.6667 75.6244 46.6667 75V46.6667ZM70 46.6667L50 46.6667V71.7614L59.0313 65.3105C59.6108 64.8966 60.3892 64.8966 60.9687 65.3105L70 71.7614V46.6667Z"
                                    fill="#8F90A6"
                                />
                            </svg>
                            <svg
                                width="120"
                                height="120"
                                viewBox="0 0 120 120"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={toggleUploadImage}
                                className={`cursor-pointer top-0 left-[0px] opacity-0 ${
                                    tmpAvatar?.file
                                        ? 'group-hover:absolute group-hover:opacity-70'
                                        : ''
                                }   z-20 w-full h-full transition-all duration-300`}
                            >
                                <rect
                                    width="120"
                                    height="120"
                                    rx="24"
                                    fill="#FAFAFC"
                                />
                                <path
                                    d="M46.6667 46.6667C46.6667 44.8258 48.1591 43.3334 50 43.3334H70C71.8409 43.3334 73.3333 44.8258 73.3333 46.6667V75C73.3333 75.6244 72.9844 76.1963 72.4293 76.482C71.8742 76.7677 71.206 76.7191 70.6979 76.3563L60 68.7149L49.3021 76.3563C48.794 76.7191 48.1258 76.7677 47.5707 76.482C47.0156 76.1963 46.6667 75.6244 46.6667 75V46.6667ZM70 46.6667L50 46.6667V71.7614L59.0313 65.3105C59.6108 64.8966 60.3892 64.8966 60.9687 65.3105L70 71.7614V46.6667Z"
                                    fill="#8F90A6"
                                />
                            </svg>
                        </div>
                    </div>
                    {errImg && (
                        <div className="col-span-2 text-red-500 text-ooolab_10px text-center">
                            {translator('FORM_CONST.REQUIRED_FIELD')}
                        </div>
                    )}
                    <div className="col-span-2 text-xs">
                        <label
                            htmlFor="reward-name"
                            className="text-ooolab_dark_1 font-semibold leading-8 block"
                        >
                            Reward Name
                            <span className="text-red-500">*</span>
                        </label>
                        <input
                            className="border w-full border-ooolab_bar_color text-ooolab_dark_1 p-2 rounded font-normal"
                            type="text"
                            id="reward-name"
                            placeholder={'Reward Name'}
                            maxLength={100}
                            {...register('reward_name', {
                                required: {
                                    value: true,
                                    message: translator(
                                        'FORM_CONST.REQUIRED_FIELD'
                                    ),
                                },
                                validate: {
                                    shouldNotContainSpace: (value) =>
                                        !!value.trim()
                                            ? true
                                            : `${translator(
                                                  'FORM_CONST.REQUIRED_FIELD'
                                              )}`,
                                },
                            })}
                        />
                        <ErrorMessage
                            className="text-red-500 text-ooolab_10px"
                            errors={errors}
                            name="reward_name"
                            as="p"
                        />
                    </div>

                    <div className="col-span-2 text-xs">
                        <label
                            htmlFor="class-desc"
                            className="text-ooolab_dark_1 font-semibold leading-8 block"
                        >
                            {translator("Description")}
                        </label>
                        <textarea
                            className="text-ooolab_dark_1 rounded font-normal w-full border border-ooolab_bar_color leading-8 pl-2 py-1 focus:outline-none"
                            rows={5}
                            id="reward-description"
                            placeholder={translator('Description')}
                            {...register('description', {
                                maxLength: {
                                    value: 200,
                                    message: translator('FORM_CONST.MAX_LENGTH_200'),
                                },
                            })}
                        />
                        <ErrorMessage
                            className="text-red-500 text-ooolab_10px"
                            errors={errors}
                            name="description"
                            as="p"
                        />
                    </div>
                    <div className="col-span-1 text-xs">
                        <label
                            className="text-ooolab_dark_1 text-xs font-semibold leading-8 block"
                            htmlFor="reward_start_date"
                        >
                            {translator("Start Date")}
                            <span className="text-red-500">*</span>
                        </label>
                        <div className="w-full h-8 relative">
                            <DatePickerInput
                                control={control}
                                name="reward_start_date"
                                placeholderText={translator('Start Date')}
                                minDate={new Date()}
                                formatDate="MM/dd/yyyy"
                                maxDate={dateEndWatch}
                                isRequired
                            />
                        </div>
                        <ErrorMessage
                            className="text-red-500 text-ooolab_10px mt-1"
                            errors={errors}
                            name="reward_start_date"
                            as="p"
                        />
                    </div>
                    <div className="col-span-1 text-xs">
                        <label
                            className="text-ooolab_dark_1 text-xs font-semibold leading-8 block"
                            htmlFor="reward_end_date"
                        >
                            {translator("End Date")}
                            <span className="text-red-500">*</span>
                        </label>
                        <div className="w-full h-8 relative">
                            <DatePickerInput
                                control={control}
                                name="reward_end_date"
                                placeholderText={translator('End Date')}
                                minDate={dateStartWatch}

                                formatDate="MM/dd/yyyy"
                                isRequired
                            />
                        </div>
                        <ErrorMessage
                            className="text-red-500 text-ooolab_10px mt-1"
                            errors={errors}
                            name="reward_end_date"
                            as="p"
                        />
                    </div>
                    <div className="col-span-1 text-xs">
                        <label
                            className="text-ooolab_dark_1 text-xs font-semibold leading-8 block"
                            htmlFor="reward_quantity"
                        >
                            {translator("Quantity")}
                            <span className="text-red-500">*</span>
                        </label>

                        <InputNumber
                            nameField="reward_quantity"
                            disableInput={false}
                            getValues={getValues}
                            setValue={setValue}
                            register={register}
                            valueField={null}
                            maxNumber={10000}
                            placeHolder={translator('Quantity')}
                            isRequired={true}
                        />
                        <ErrorMessage
                            className="text-red-500 text-ooolab_10px"
                            errors={errors}
                            name="reward_quantity"
                            as="p"
                        />
                    </div>
                    <div className="col-span-1 text-xs">
                        <label
                            className="text-ooolab_dark_1 text-xs font-semibold leading-8 block"
                            htmlFor="reward_apo"
                        >
                            {translator("Coin Price")}
                            <span className="text-red-500">*</span>
                        </label>
                        <InputNumber
                            nameField="reward_apo"
                            disableInput={false}
                            getValues={getValues}
                            setValue={setValue}
                            register={register}
                            valueField={null}
                            maxNumber={10000}
                            placeHolder={translator('Coin Price')}
                            isRequired={true}
                        />
                        <ErrorMessage
                            className="text-red-500 text-ooolab_10px"
                            errors={errors}
                            name="reward_apo"
                            as="p"
                        />
                    </div>
                    <div className="col-span-1 text-xs">
                        <label
                            className="text-ooolab_dark_1 text-xs font-semibold leading-8 block"
                            htmlFor="reward_level"
                        >
                            {translator("Level")}
                            <span className="text-red-500">*</span>
                        </label>
                        <select
                            className={`text-ooolab_dark_1 text-xs font-semibold leading-8 outline-none w-full border border-ooolab_bar_color rounded py-2`}
                            id="reward_level"
                            {...register('reward_level', {
                                required: {
                                    value: true,
                                    message: translator(
                                        'FORM_CONST.REQUIRED_FIELD'
                                    ),
                                },
                            })}
                        >
                            <option
                                className="text-sm text-gray_0"
                                hidden={true}
                                value=""
                            >
                                {translator("Level")}
                            </option>
                            {dataLevel &&
                                dataLevel?.map((i, idx) => (
                                    <option
                                        selected={idx === 0 && true}
                                        className="text-sm"
                                        value={i?.id}
                                        key={i?.id}
                                    >
                                        {i?.name}
                                    </option>
                                ))}
                        </select>
                        <ErrorMessage
                            className="text-red-500 text-ooolab_10px"
                            errors={errors}
                            name="reward_level"
                            as="p"
                        />
                    </div>
                    <div className="col-span-1 text-xs">
                        <label
                            className="text-ooolab_dark_1 text-xs font-semibold leading-8 block"
                            htmlFor="reward_feature"
                        >
                            {translator('REWARD.FEATURED_REWARD')}
                        </label>
                        <input
                            className="border  border-ooolab_bar_color text-ooolab_dark_1 p-3 rounded h-8 w-5 "
                            type="checkbox"
                            id="reward-feature"
                            placeholder={translator('REWARD.FEATURED_REWARD')}
                            {...register('reward_feature')}
                        />
                    </div>
                    <div className="col-span-2 w-full flex justify-center text-center pt-1">
                        <button
                            type="submit"
                            disabled={loadingCreate}
                            className="flex justify-center px-4 py-3 bg-ooolab_dark_300 text-white rounded text-xs "
                        >
                            {loadingCreate ? (
                                <svg
                                    className="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                            ) : null}
                             {translator("Add Reward")}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default CreateRewardForm;
