import React from 'react'

export const ZoomInIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.2929 14.2929C14.6834 13.9024 15.3166 13.9024 15.7071 14.2929L20 18.5858V16.2C20 15.6477 20.4477 15.2 21 15.2C21.5523 15.2 22 15.6477 22 16.2V21C22 21.1356 21.973 21.2649 21.9241 21.3828C21.8757 21.4999 21.804 21.6096 21.7092 21.705C21.7078 21.7064 21.7064 21.7078 21.705 21.7092C21.6096 21.804 21.4999 21.8757 21.3828 21.9241C21.2649 21.973 21.1356 22 21 22H16.2C15.6477 22 15.2 21.5523 15.2 21C15.2 20.4477 15.6477 20 16.2 20H18.5858L14.2929 15.7071C13.9024 15.3166 13.9024 14.6834 14.2929 14.2929Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.70711 14.2929C10.0976 14.6834 10.0976 15.3166 9.70711 15.7071L5.41421 20H7.8C8.35228 20 8.8 20.4477 8.8 21C8.8 21.5523 8.35228 22 7.8 22H3C2.86441 22 2.73512 21.973 2.61722 21.9241C2.50014 21.8757 2.3904 21.804 2.29502 21.7092C2.2936 21.7078 2.29219 21.7064 2.29078 21.705C2.19595 21.6096 2.12432 21.4999 2.07588 21.3828C2.02699 21.2649 2 21.1356 2 21C2 20.9999 2 20.9999 2 20.9998V16.2C2 15.6477 2.44772 15.2 3 15.2C3.55228 15.2 4 15.6477 4 16.2V18.5858L8.29289 14.2929C8.68342 13.9024 9.31658 13.9024 9.70711 14.2929Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.2 3C15.2 2.44772 15.6477 2 16.2 2H20.9998C20.9999 2 20.9999 2 21 2C21.1356 2 21.2649 2.02699 21.3828 2.07588C21.4999 2.12432 21.6096 2.19595 21.705 2.29078C21.7064 2.29219 21.7078 2.2936 21.7092 2.29502C21.804 2.3904 21.8757 2.50014 21.9241 2.61722C21.973 2.73512 22 2.86441 22 3V7.8C22 8.35228 21.5523 8.8 21 8.8C20.4477 8.8 20 8.35228 20 7.8V5.41421L15.7071 9.70711C15.3166 10.0976 14.6834 10.0976 14.2929 9.70711C13.9024 9.31658 13.9024 8.68342 14.2929 8.29289L18.5858 4H16.2C15.6477 4 15.2 3.55228 15.2 3Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.41421 4L9.70711 8.29289C10.0976 8.68342 10.0976 9.31658 9.70711 9.70711C9.31658 10.0976 8.68342 10.0976 8.29289 9.70711L4 5.41421V7.8C4 8.35228 3.55228 8.8 3 8.8C2.44772 8.8 2 8.35228 2 7.8V3.00017C2 3.00012 2 3.00006 2 3C2 2.74408 2.09763 2.48816 2.29289 2.29289C2.38877 2.19702 2.49927 2.12468 2.61722 2.07588C2.73512 2.02699 2.86441 2 3 2C3.00006 2 3.00012 2 3.00017 2H7.8C8.35228 2 8.8 2.44772 8.8 3C8.8 3.55228 8.35228 4 7.8 4H5.41421Z" fill="currentColor"/>
    </svg>
  )
}
