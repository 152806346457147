import { FC, useContext, useMemo, useState } from 'react';
import { CourseType } from 'types/Courses.type';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import dayjs from 'dayjs';
import './Table.scss';
import NO_CONTENT from 'assets/img/empty-state/instructor.png';
import ReactTooltip from 'react-tooltip';
import { useParams } from 'react-router-dom';
import { UserContext } from 'contexts/User/UserContext';
import ActionMenu from 'components/Workspace/Course/CourseBuilder/components/CourseScheduling/SessionList/components/SessionTable/ActionMenu';
import { ATTENDANCE_TYPE, SESSION_STATUS } from 'constant/course.const';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import DialogComponent from 'components/Dialog/DialogComponent';
import { SessionDetail, SessionTitle } from 'components/Workspace/Course/CourseBuilder/components/CourseScheduling/Calendar/components/ModalSessionDetail/SessionDetail';

interface IViewTable {
    data: CourseType[];
    refreshData: Function;
    learnerId?: string;
    userData: any
}

const SessionTable: FC<IViewTable> = ({ data, refreshData, learnerId = null, userData }) => {
    const { t } = useTranslation();
    const [isOpenModalDetails, setOpenModalDetails] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();

    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;
    const params: { id: string } = useParams();
    const {
        userState: { result },
    } = useContext(UserContext);

    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={t('SESSION.SESSION_ID')} />,
                Cell: ({ row }) => {
                    return <RenderTableCell onClick={() => { setItemSelected(row?.original); setOpenModalDetails(true) }} className="text-primary-500 font-semibold" value={row.values?.id} />;
                },
                accessor: 'id',
                width: "80"
            },
            {
                Header: () => <RenderTableHeader value={t('SESSION.COURSE_NAME')} />,
                accessor: 'course',
                Cell: ({ row }) => {
                    return <RenderTableCell className="text-primary-500 font-semibold" value={row.values?.course?.title} />;
                },
                width: "100"
            },
            {
                Header: () => <RenderTableHeader value={t('DATE')} />,
                accessor: 'start_time',
                Cell: ({ row }: any) => <RenderTableCell value={dayjs.utc(row.values?.start_time).tz(result?.time_zone).format('DD/MM/YYYY')} />,
                width: "100"
            },
            {
                Header: () => <RenderTableHeader value={t('TIME')} />,
                accessor: 'end_time',
                Cell: ({ row }: any) => <RenderTableCell
                    value={`${dayjs.utc(row.values?.start_time).tz(result?.time_zone).format('HH:mm')} - ${dayjs.utc(row.values?.end_time).tz(result?.time_zone).format('HH:mm')}`} />,
                width: "100"
            },
            {
                Header: () => <RenderTableHeader value={t('STATUS')} />,
                accessor: 'display_status',
                Cell: ({ row }: any) => {
                    if (!row?.original?.display_status) return <RenderTableCell value="" />;
                    return (
                        <div className="py-3 ml-2">
                            <span className={`sessionList-status sessionList-status--${row?.original?.display_status}`}>
                                {SESSION_STATUS(t)?.find(el => el?.value === row?.original?.display_status)?.name}
                            </span>
                        </div>
                    );
                },
                width: '110',
            },
            {
                Header: () => <RenderTableHeader value={t('INSTRUCTOR')} />,
                accessor: 'instructors',
                Cell: ({ row }: any) => {
                    const peopleEnrolled = [...(row?.original?.instructors || []),];
                    return (
                        <div className="flex gap-1 text-sm text-gray-800 ml-2">
                            {peopleEnrolled?.slice(0, 2)?.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <div
                                            data-tip={item?.name || item?.display_name}
                                            data-for={row?.original?.id + '_show-more-people_' + index}
                                            className={`w-7 h-7 rounded-full overflow-hidden bg-cover ${peopleEnrolled?.length > 2 && index > 0 ? '-ml-3.5 border-2 border-white' : 'border-2 border-white'}`}
                                            style={{ backgroundImage: `url('${item?.avatar_url}')` }}
                                        ></div>
                                        {
                                            //@ts-ignore
                                            <ReactTooltip
                                                place="top"
                                                type="dark"
                                                effect="float"
                                                id={row?.original?.id + '_show-more-people_' + index}
                                                getContent={(content) => <div>{content}</div>}
                                            />
                                        }
                                    </div>
                                );
                            })}
                            {peopleEnrolled?.length > 2 && (
                                <div
                                    className="w-7 h-7 border-2 bg-gray-300 border-white text-gray-900 text-[10px] -ml-3.5 p-0.5 flex items-center justify-center rounded-full hover:text-primary-500 hover:border-primary-500"
                                    data-tip={peopleEnrolled?.slice(2)?.map((item) => item?.display_name)}
                                    data-for={row?.original?.id + '_show-more-people'}
                                >
                                    +{peopleEnrolled?.length - 2}
                                </div>
                            )}
                            {
                                //@ts-ignore
                                <ReactTooltip
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    id={row?.original?.id + '_show-more-people'}
                                    getContent={(content) => <div>{content}</div>}
                                />
                            }
                        </div>
                    );
                },
                width: "120"
            },
            ...(!learnerId ? [{
                Header: () => <RenderTableHeader value={t('LEARNER')} />,
                accessor: 'learners',
                Cell: ({ row }: any) => {
                    const peopleEnrolled = [...(row?.original?.learners || []),];
                    return (
                        <div className="flex gap-1 text-sm text-gray-800 ml-2">
                            {peopleEnrolled?.slice(0, 2)?.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <div
                                            data-tip={item?.name || item?.display_name}
                                            data-for={row?.original?.id + '_show-more-people_' + index}
                                            className={`w-7 h-7 rounded-full overflow-hidden bg-cover ${peopleEnrolled?.length > 2 && index > 0 ? '-ml-3.5 border-2 border-white' : 'border-2 border-white'}`}
                                            style={{ backgroundImage: `url('${item?.avatar_url}')` }}
                                        ></div>
                                        {
                                            //@ts-ignore
                                            <ReactTooltip
                                                place="top"
                                                type="dark"
                                                effect="float"
                                                id={row?.original?.id + '_show-more-people_' + index}
                                                getContent={(content) => <div>{content}</div>}
                                            />
                                        }
                                    </div>
                                );
                            })}
                            {peopleEnrolled?.length > 2 && (
                                <div
                                    className="w-7 h-7 -ml-3.5 border-2 bg-gray-300 border-white text-gray-900 text-[10px] flex items-center justify-center rounded-full hover:text-primary-500 hover:border-primary-500"
                                    data-tip={peopleEnrolled?.slice(2)?.map((item) => item?.display_name)}
                                    data-for={row?.original?.id + '_show-more-people'}
                                >
                                    +{peopleEnrolled?.length - 2}
                                </div>
                            )}
                            {
                                //@ts-ignore
                                <ReactTooltip
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    id={row?.original?.id + '_show-more-people'}
                                    getContent={(content) => <div>{content}</div>}
                                />
                            }
                        </div>
                    );
                },
                width: "120"
            }] : []),
            {
                Header: () => <RenderTableHeader value={t('LEARNING_GROUP_KEY')} />,
                accessor: 'learning_groups',
                Cell: ({ row }: any) => {
                    if (!row?.original?.learning_groups) return <RenderTableCell value="" />;

                    let learningGroup = row?.original?.learning_groups;
                    if (learningGroup?.length > 0) learningGroup = learningGroup?.[0];

                    return (
                        <div className="ml-2 flex items-center text-xs text-ooolab_dark_1 space-x-2">
                            <div
                                className={`w-6 h-6 rounded-full overflow-hidden bg-cover flex-none`}
                                style={{ backgroundImage: `url('${learningGroup?.avatar_url}')` }}
                            ></div>
                            <span className='truncate text-enterprise cursor-pointer' onClick={() => window.open(`/workspace/${params?.id}/management/learning-groups/${learningGroup?.id}`, '_blank')}>
                                {learningGroup?.name}
                            </span>
                        </div>
                    );
                },
                width: '160',
            },
            {
                Header: () => <RenderTableHeader value={t('SESSION.ATTENDANCE')} />,
                accessor: 'display_session_attendance',
                Cell: ({ row }: any) => {
                    if (!row?.original?.display_session_attendance) return <RenderTableCell value="" />;
                    if (row?.original?.display_session_attendance === 'not_available') return <RenderTableCell value="-" />
                    return (
                        <div className="py-3 ml-2">
                            <span className={`sessionList-status sessionList-status--attendance_${row?.original?.display_session_attendance}`}>
                                {ATTENDANCE_TYPE(t)?.find((el) => el?.value === row?.original?.display_session_attendance)?.name}
                            </span>
                        </div>
                    );
                },
                width: '130',
            },
            {
                Header: () => <RenderTableHeader value={t('SESSION.SESSION_RECORDING')} />,
                accessor: 'recording_link',
                Cell: ({ row }) => {
                    return (
                        <div className='flex items-center gap-1.5'>
                            {row.values?.recording_link ?
                                (
                                    <>
                                        <RenderTableCell className="text-primary-500 font-semibold text-ellipsis truncate" value={row.values?.recording_link} />
                                        <ExternalLinkIcon
                                            onClick={() => window.open(row.values?.recording_link, '_blank')}
                                            className="w-4 h-4 cursor-pointer min-w-[16px] text-primary-500"
                                        />
                                    </>
                                )
                                : '-'
                            }
                        </div>
                    )
                },
                width: "120"
            },
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="h-full flex items-center justify-center">
                            <ActionMenu
                                data={{ ...row?.original, time_zone: result?.time_zone }} refreshData={refreshData} editLearner={!!learnerId} userData={userData}
                            />
                        </div>
                    );
                },
                accessor: 'action',
                width: 60,
            },
        ],
        [data, WorkspaceDetailInformation]
    );

    return (
        <>
            <Table
                data={data || []}
                columns={columns}
                emptyContent={t('SESSION.NO_SESSIONS')}
                emptyIcon={NO_CONTENT}
            />
            {isOpenModalDetails && (
                <DialogComponent
                    title={<SessionTitle refreshData={refreshData} session={itemSelected} />}
                    isOpen={isOpenModalDetails}
                    onCloseModal={() => setOpenModalDetails(false)}
                    styles="max-w-2xl !p-3"
                    stylesTitle="!h-5"
                    child={
                        <div className="-mx-2 border-t border-gray-300">
                            <SessionDetail session={itemSelected} isOpen={true} close={() => setOpenModalDetails(false)} />
                        </div>
                    }
                />
            )}
        </>
    );
};

export default SessionTable;
