import Disclosure from '_shared/components/Disclosure/Disclosure';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import assignmentService from 'services/assignment.service';
import libraryService from 'services/library.service';
import DisclosureButton from '../DisclosureSection/DisclosureButton';
import DisclosurePanel from '../DisclosureSection/DisclosurePanel/DisclosurePanel';
import File from '../DisclosureSection/DisclosurePanel/File/File';
import Section from '../DisclosureSection/Section/Section';
import { contentTypeEnum } from 'constant/LibraryEnum.const';

const MAX_LENGTH = 1024;

export default function ContentItem({
    file,
    handleSelectContentToPreview,
    sectionItem,
    onDragOverContent,
    index,
    onDragContentStart,
    onDragContentEnd,
    translator,
    onDelete,
    sections,
    setSections,
    setOnSaving,
    setAssignmentSelected,
    assignmentSelected,
    contentSelected,
    isDragging
}) {
    const [sectionName, setSectionName] = useState(file?.library_content?.name);
    const [files, setFiles] = useState([]);
    const params: any = useParams();

    const handleRename = async (nextValue, object_type) => {
        setOnSaving(true);
        if (nextValue?.length > MAX_LENGTH || !nextValue?.length) {
            setSectionName(file?.library_content?.name)
            return;
        };
        const id = file?.library_content?.object_id;
        if (object_type === contentTypeEnum.assignment) {
            await libraryService.renameAssignment(params?.id, id, {
                title: nextValue,
            });
        } else {
            await libraryService.updateFile(params?.id, id, {
                name: nextValue,
            });
        }

        const fileUpdated = { ...file, library_content: { ...file?.library_content, name: nextValue } };
        const newSectionContents = sectionItem?.section_contents?.map((item) => {
            if (item?.id === fileUpdated?.id) return fileUpdated;
            return item;
        });
        const newSections = sections?.map((section) => {
            if (section?.id === sectionItem?.id) return { ...section, section_contents: newSectionContents };
            return section;
        });
        setSections(newSections);
        setAssignmentSelected(fileUpdated);
        handleSelectContentToPreview(files?.[0]);
        setOnSaving(false);
    };

    const getAssigment = async () => {
        const res: any = await assignmentService.getAssignment({
            workspaceId: params?.id,
            assignmentId: file?.library_content?.object_id,
        });
        setFiles(res?.data?.sections);
    };
    useEffect(() => {
        getAssigment();
    }, []);

    useEffect(() => {
        if (assignmentSelected?.id === file?.id) {
            handleSelectContentToPreview(files?.[0]);
        }
    }, [assignmentSelected]);

    return (
        <div className='group'>
            <Section
                isActive={assignmentSelected?.id === file?.id}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    assignmentSelected?.id !== file?.id && setAssignmentSelected(file);
                }}
            >
                <Disclosure
                    index={index}
                    button={(open) => (
                        <DisclosureButton
                            index={index}
                            item={file}
                            isActiveSection={assignmentSelected?.id === file?.id}
                            onDragOver={onDragOverContent}
                            onDragEnd={onDragContentEnd}
                            onDragStart={onDragContentStart}
                            onRename={handleRename}
                            onDelete={onDelete}
                            open={open}
                            numberOfFile={files?.length}
                            sectionName={sectionName}
                            setSectionName={setSectionName}
                            featureName='libraryContentLessonsOrScorm'
                        />
                    )}
                    panel={
                        ({ }) => {
                            return (
                                <DisclosurePanel>
                                    <div className={`${isDragging ? 'opacity-50 pointer-events-none' : 'opacity-100 pointer-events-auto'}`}>
                                        {files?.map((item) => {
                                            return (
                                                <File
                                                    key={item?.id}
                                                    item={item}
                                                    isActive={assignmentSelected?.id === file?.id && contentSelected?.id === item?.id}
                                                    onClick={() => handleSelectContentToPreview(item)}
                                                    name={item?.library_content?.name}
                                                    translator={translator}
                                                />
                                            );
                                        })}
                                    </div>
                                </DisclosurePanel>
                            )
                        }
                    }
                />
            </Section>
            {sectionName?.length > MAX_LENGTH && (
                <div className="maxLengthText">{translator('FORM_CONST.MAX_LENGTH_TEXT', { maxLength: MAX_LENGTH })}</div>
            )}
        </div>
    );
}
