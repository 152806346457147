import { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Table from './Table';
import { UserContext } from 'contexts/User/UserContext';
import { PlusSmIcon } from '@heroicons/react/outline';
import CreateStudentForm from './RewardAposModal';
import { useBoolean } from 'hooks/custom';
import classService from 'services/class.service';
import { get } from 'lodash';
import { ButtonPrimary } from '_shared';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import DialogComponent from 'components/Dialog/DialogComponent';
import Pagination from 'components/V2/Pagination';
import { USER_STATUS } from 'components/Management/User/constant';

const paginationDefault = {
    page: 1,
    per_page: 20,
    total: 0,
};

const AposHistory = ({ userData }) => {
    const {
        userState: { result },
    } = useContext(UserContext);

    const { booleanValue: modalCreate, toggleBooleanValue: toggleModalCreate } = useBoolean();

    const params = useParams<{ id: string; userId: string }>();
    const [indexPage, setIndexPage] = useState('1-50');
    const { t } = useTranslation();
    const [pagination, setPagination] = useState(paginationDefault);
    const [historyList, setHistoryList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [learnerInfo, setLearnerInfo] = useState(undefined)

    const getStudentApoHistoryListService = (page: number = 1) => {
        const bodyRequest = {
            ...pagination,
            page: page,
        };
        setLoading(true);
        classService
            .getStudentApoHistoryListService(params.id, params.userId, bodyRequest)
            .then((res) => {
                const { items, page, total } = res.data;
                setHistoryList(items);
                setPagination({ ...bodyRequest, page, total });
                setIndexPage(getIndexPage(page, pagination?.per_page, items?.length));
            })
            .catch((err) => err)
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getStudentApoHistoryListService();
        getLearnerInfo();
    }, []);

    const getLearnerInfo = () => {
        classService.getStudentDetailService(params.id, params.userId)
            .then((res) => {
                setLearnerInfo(res.data)
            })
            .catch(err => err)
    }

    const handlePagination = (page: number) => {
        setLoading(true);
        getStudentApoHistoryListService(page);
    };

    return (
        <div className="w-full h-full">
            <DialogComponent
                isOpen={modalCreate}
                onCloseModal={toggleModalCreate}
                title={t("COIN.ADD_COINS")}
                child={
                    <CreateStudentForm
                        onClose={toggleModalCreate}
                        refresh={() => {
                            getStudentApoHistoryListService();
                            getLearnerInfo();
                        }}
                        t={t}
                    />
                }
            />
            <div className="flex justify-between items-center border-b border-gray-300 py-4">
                <div className="flex gap-4">
                    <div>
                        <div className=" text-xs text-gray-500">{t('COIN.AVAILABLE_COINS')} </div>
                        <div className=" text-xl font-semibold">{get(learnerInfo, 'available_points', '-')}</div>
                    </div>
                    <div className='h-6 w-[1px] bg-gray-300 mt-2'></div>
                    <div>
                        <div className=" text-xs text-gray-500">{t('COIN.COINS_RECEIVED')} </div>
                        <div className=" text-xl font-semibold">{get(learnerInfo, 'total_points', '-')}</div>
                    </div>
                    <div className='h-6 w-[1px] bg-gray-300 mt-2'></div>
                    <div>
                        <div className=" text-xs text-gray-500">{t('COIN.LEVEL')}</div>
                        <div className=" text-xl font-semibold ">{get(learnerInfo, 'current_level.name') || 'First level'}</div>
                    </div>
                </div>
                {checkPermission('userDetailsAchievementsCoinHistory', 'C') && <ButtonPrimary type="button" onClick={toggleModalCreate} className=" flex item gap-1" disabled={userData?.membership?.status === USER_STATUS.DEACTIVATE}>
                    <PlusSmIcon className="w-5 h-5" />
                    {t('COIN.AVAILABLE_COINS')}
                </ButtonPrimary>}

            </div>

            <div className="h-[calc(100vh-440px)]  overflow-auto mt-5">
                <Table dataTable={historyList} tz={result.time_zone || 'America/New_York'} />
            </div>
            <Pagination
                indexPage={indexPage}
                page={pagination?.page}
                per_page={pagination?.per_page}
                total={pagination.total}
                callback={handlePagination}
            />
        </div>
    );
};

export default AposHistory;
