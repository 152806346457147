import { CalendarIcon } from '@heroicons/react/outline';
import { forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import './react-datepicker.scss';

interface Props {
  startDate: any;
  endDate: Date;
  maxDate?: Date;
  onChange: (value: any) => void;
  placeHolder?: string;
  filterDate?: any;
  className?: string;
  isClearable?: boolean;
}

export default function DatePickerComponent(props: Props) {
  const {endDate, startDate, maxDate, onChange, placeHolder, filterDate, className = '', isClearable = false} = props
  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <button className={`relative min-h-[36px] border border-gray-300 text-sm pl-2 pr-6 py-[7px] rounded flex items-center w-full text-gray-800 font-normal ${className}`} onClick={onClick} ref={ref}>
      <span className='ml-1 mr-2 truncate'>
        {value ? value : <span className='text-gray-500'>{placeHolder}</span>}
      </span>
      <span className='absolute right-2.5'><CalendarIcon width={16} height={16}/></span>
    </button>
  ));

  return (
    <div className='react-datepicker-custom-container relative min-w-[210px]'>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        showPopperArrow={false}
        customInput={<CustomInput />}
        dateFormat="dd/MM/yyyy"
        maxDate={maxDate}
        filterDate={filterDate}
        isClearable={isClearable}
      />
    </div>
  );
}
