import { useCallback, useEffect, useState } from 'react';

export const useBoolean = () => {
    const [booleanValue, setBooleanValue] = useState(false);

    const toggleBooleanValue = useCallback(
        () => setBooleanValue(!booleanValue),
        [booleanValue]
    );

    return { booleanValue, toggleBooleanValue };
};

export const useClickOutside = (ref, callback) => {
    const handleClick = e => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };
    useEffect(() => {
      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
      };
    });
  };
  
