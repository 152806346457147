import * as React from 'react';
// PACKAGE
import { Redirect, Route, useHistory } from 'react-router-dom';
import { SET_AUTH } from 'actions/auth.action';
// CONTEXT
import { AuthContext } from 'contexts/Auth/AuthContext';
// UTILS
import {
    changeFalseForLocalStorageCurrentAccount,
    getPreviousPath,
    isLocalStorageAuth,
    removePreviousPath,
    setLocalStorageAuthEmailData,
} from 'utils/handleLocalStorage';
import { getParamFromUrl } from 'utils/handleString';
import queryString from 'query-string';
import { handleCheckEnv } from 'constant/util.const';
import workspaceService from 'services/workspace.service';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { UserService, WorkspaceService } from 'services';
import { formatPermission, onCheckPermissions } from 'constant/workspace.const';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

// unauthenticated routes (redirect to home when authenticated)
const CommonRoute: React.FC<any> = ({
    path,
    isAuthStorage,
    setAuthStorage,
    // setHeader,
    component: Component,
    documentTitle='',
    ...rest
}) => {
    useDocumentTitle(documentTitle);
    const history = useHistory();
    const params = history.location.search;
    const paramsSSO = queryString.parse(history.location.search);
    const { authState, dispatch } = React.useContext(AuthContext);
    const { err } = authState;
    // CHECK HAVE WORKSPACE
    let hasWorkspace: string;
    if (params.indexOf('has_workspace') > -1) {
        hasWorkspace = getParamFromUrl(params, 'has_workspace');
    }

    // FROM EMAIL ROUTES
    let defaultPassword: boolean | undefined;
    let emailParam: string;
    if (params.indexOf('default_password') > -1) {
        changeFalseForLocalStorageCurrentAccount();
        setAuthStorage(isLocalStorageAuth());
        // add invite account information to LocalStorage
        const defaultPasswordParam = getParamFromUrl(params, 'default_password');

        if (defaultPasswordParam) {
            // add invite account information to LocalStorage
            emailParam = getParamFromUrl(params, 'email');
            defaultPassword = JSON.parse(defaultPasswordParam);
            setLocalStorageAuthEmailData(emailParam);
        }
    }
    // NO HEADER & SET DEFAULT PASSWORD
    React.useEffect(() => {
        if (defaultPassword) {
            dispatch({
                type: SET_AUTH.LOGIN_WITH_DEFAULT_PASSWORD,
                /*@ts-ignore*/
                result: defaultPassword,
            });
        }
    }, []);
    const { getWorkspaceDetailState, dispatch: workspaceDispath } = React.useContext(GetWorkspaceContext);

    React.useEffect(() => {
        if (isAuthStorage) {
            removePreviousPath();
        }
    }, [isAuthStorage]);

    const onAuthWorkspace = (props) => {
        history.push('/', {
            from: props.location,
        });
        //TODO:getPreviousPath
        // const workspaceId = props?.location.state?.from?.pathname?.split('/')[2];
        // if (workspaceId && props?.location.state?.from?.pathname) {
        //     workspaceService.onAuthWorkspace(workspaceId).then(async () => {
        //         UserService.getUser().then((userData) => {
        //             WorkspaceService.getPermissionsUserWorkspace().then((res) => {
        //                 onCheckPermissions(userData?.membership?.user_role?.role_type, userData?.membership?.user_role?.name, formatPermission(res?.data), history, workspaceId)
        //             })
        //         })
        //         history.push(props?.location.state?.from?.pathname, {
        //             from: props.location,
        //         });
        //     });
        // } else {
        //     history.push(getPreviousPath() || '/', {
        //         from: props.location,
        //     });
        //     history.push('/', {
        //         from: props.location,
        //     });
        // }
    };

    return (
        <Route
            path={path}
            exact
            {...rest} // PROPS ITEM inside { ...rest } need inject to Component below
            render={(props: any) => {
                return isAuthStorage && !paramsSSO?.token && !paramsSSO?.usercode ? (
                    hasWorkspace === 'false' ? (
                        history.push('/workspace/create?isHeader=false')
                    ) : (
                        onAuthWorkspace(props)
                    )
                ) : isAuthStorage && paramsSSO?.token && paramsSSO?.usercode && paramsSSO?.student ? (
                    <Redirect
                        to={{
                            pathname: `/workspace/${handleCheckEnv()}/management/users/${paramsSSO?.student}`,
                            state: { from: props.location },
                        }}
                    />
                ) : (
                    <Component {...props} isAuthStorage={isAuthStorage} setAuthStorage={setAuthStorage} />
                );
            }}
        />
    );
};

export default CommonRoute;
