import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonBack, ButtonOutline, ButtonPrimary, Toastify } from "_shared";
import { TreeProperty } from "../TreeProperty/TreeProperty";
import { InputField, TextareaField } from "components/InputForm/InputForm";
import { SelectField } from "components/Select";
import "./index.scss";
import { useForm, useFieldArray } from "react-hook-form";
import { ObjectType, PropertyType } from "constant/property.const";
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from "hooks/useDebounce";
import { PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { LevelFieldData } from "../LevelFieldData";
import { NoContentWithImage } from "components/NoContent/NoContent";
import propertyService from 'services/property.service';
import { useHistory, useParams } from "react-router-dom";
import { flattenData } from "components/WorkspaceSetting/Property/components/propertyCommon";
import { useQueryParamsURL } from "hooks/useQueryParamsURL";
import { CustomPropertiesType } from "types/CustomProperties";
import DialogComponent from "components/Dialog/DialogComponent";
import SelectBox from "_shared/components/Select";

export const EditProperty = ({ ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [keyword, setKeyword] = useDebouncedState('');
    const query = useQueryParamsURL();
    const params: any = useParams();

    const { propertyList, propertyId } = props;
    const [propertyDetail, setPropertyDetail] = useState(null);
    const [nodePropertySelected, setNodePropertySelected] = useState(null);
    const propertySelectId = query.get('idSelected') ?? props?.propertyId;
    const subtab = query.get('subtab');
    const [toggleModalSave, setToggleModalSave] = useState(false);
    const [toggleModalDelete, setToggleModalDelete] = useState(false);
    const [selectedIndexOption, setSelectIndexOption] = useState<number>();
    const [isLoading, setIsLoading] = useState(false);

    const {
        control,
        register,
        handleSubmit,
        formState: { errors, isValid, isDirty },
        getValues,
        reset,
        watch
    } = useForm<any>({
        mode: "onChange"
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'property_options'
    });

    const workspaceId = params?.id;

    const fetchPropertyDetail = () => {
        const payload = {};
        propertyService
            .detail(workspaceId, propertyId, payload)
            .then((res) => {
                setPropertyDetail(res.data);
                const flatList = flattenData([res?.data]);
                setNodePropertySelected(flatList?.find(item => item?.id == propertySelectId));
            })
            .catch()
            .finally();
    }

    const propertyCurrent: CustomPropertiesType = useMemo(() => {
        let propertyData;
        const traverseNodes = (nodes) => {
            for (const node of nodes) {
                if (node.id == propertySelectId) {
                    propertyData = node;
                    break;
                }
                if (node?.children && node?.children?.length > 0) {
                    traverseNodes(node.children);
                }
            }
        }
        traverseNodes(propertyList);
        return propertyData || nodePropertySelected;
    }, [propertySelectId, propertyList, nodePropertySelected]);

    const parentList = useMemo(() => {
        return propertyList.map((item) => {
            if (item?.children?.length > 0 && item?.id != propertyId) {
                return [item, ...item?.children];
            }
            return item;
        })
            .flat(1)
            .filter(item => item?.id != propertyId)
            .map(item => ({ ...item, label: item?.name, value: item?.id }))
    }, [propertyList, propertyId]);

    const hasDisabled = useMemo(() => {
        return propertyCurrent?.created_by?.type === 'system';
    }, [propertyCurrent]);

    const isSelectComponent = (value) => {
        return value === 'single_selection' || value === 'multiple_selection';
    }

    const hasOptions = useMemo(() => {
        return isSelectComponent(watch('property_type')?.value);
    }, [watch('property_type')])

    const checkForDuplicates = (value: string, index: number) => {
        return !getValues('property_options').some((field: any, idx) => idx !== index && field.name === value)
            ? true
            : t('An option already exist', { name: `"${value}"` });
    };

    const onSubmit = async (value) => {
        const payload = {
            ...value,
            id: propertySelectId
        }
        try {
            setIsLoading(true);
            await propertyService.update(params?.id, payload);
            Toastify.success();
        } catch (error) {
            Toastify.error();
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        let propertyType: any;
        let parent;
        if (propertyCurrent?.property_type) {
            propertyType = PropertyType?.find(item => item.value === propertyCurrent?.property_type);
        }
        if (propertyCurrent?.parent_id) {
            parent = parentList?.find(item => item.value === propertyCurrent?.parent_id);
        }
        reset({
            ...propertyCurrent,
            property_type: propertyType,
            parent_id: parent,
            object_type: ObjectType?.find(item => item.value === propertyCurrent?.object_type),
            property_options: []
        });
    }, [propertyCurrent, parentList]);

    useEffect(() => {
        fetchPropertyDetail();
    }, [propertyId, propertySelectId])

    return <> <div className="editProperty">
        <div className="p-5 border-b border-gray-300">
            <SearchBox className="w-full max-w-xs" placeholder={t('SEARCH')} onSubmit={(e: any) => setKeyword(e)} />
        </div>
        <div className="p-5 w-full">
            <div className="flex border border-gray-300 rounded-lg">
                <div className="editProperty-list">
                    <div className="editProperty-name rounded-tl-lg">
                        {t('Name')}
                    </div>
                    <TreeProperty
                        data={flattenData([propertyDetail ?? []])}
                        nodeSelected={nodePropertySelected}
                        handleNodeSelected={(value) => {
                            setNodePropertySelected(value);
                            history.push(`/workspace/${params?.id}/setting?tab=property&subtab=${subtab}&id=${propertyId}&idSelected=${value?.id}`);
                        }}
                    />
                </div>
                <div className="editProperty-content overflow-auto">
                    <div className="editProperty-header">
                        <div className="flex gap-2 items-center">
                            <ButtonBack
                                onClick={() => history.push(`/workspace/${params?.id}/setting?tab=property&subtab=${subtab}`)}
                                hideLabel={true} />
                            {propertyCurrent?.name}
                        </div>
                        <ButtonPrimary
                            size="M"
                            type="button"
                            onClick={() => setToggleModalSave(true)}
                            disabled={!isDirty || !isValid}
                        >{t('Save')}</ButtonPrimary>
                    </div>
                    <div>
                        <form className="flex flex-col gap-5 mt-2 p-5">
                            <div className="editProperty-item">
                                <InputField
                                    label={t('Property Name')}
                                    name="name"
                                    isRequired
                                    maxLength={255}
                                    placeholder={t('Enter Field Name')}
                                    register={register('name', {
                                        required: t('FORM_CONST.REQUIRED_FIELD'),
                                        maxLength: {
                                            value: 255,
                                            message: t('FORM_CONST.MAX_LENGTH_250')
                                        },
                                    })}
                                    errors={errors}
                                    classNameInput="py-2.5"
                                    disabled={hasDisabled}
                                />
                                <SelectField
                                    control={control}
                                    label={t('Object')}
                                    name="object_type"
                                    placeholder={t("Select an option")}
                                    isSearchable={false}
                                    options={ObjectType}
                                    errors={errors}
                                    isDisabled={true}
                                />
                            </div>
                            <div className="editProperty-item">
                                <SelectField
                                    control={control}
                                    isRequired
                                    label={t('Property Type')}
                                    name="property_type"
                                    placeholder={t("Select an option")}
                                    isSearchable={false}
                                    options={PropertyType}
                                    errors={errors}
                                    isDisabled={hasDisabled}
                                />
                                <TextareaField
                                    label={t('Description')}
                                    name="description"
                                    className="col-span-4"
                                    placeholder={t('Enter Description')}
                                    register={register('description')}
                                    textLength={getValues("description")?.length?.toString()}
                                    maxLength={1000}
                                    isCountter
                                />
                            </div>
                            {hasOptions &&
                                <div className="editPropertyOptions overflow-auto">
                                    <div className="editPropertyOptions-header">
                                        <h2>{t('Property Option(s)')}</h2>
                                        <SearchBox className="w-full max-w-xs hidden" placeholder={t('SEARCH')} onSubmit={(e: any) => setKeyword(e)} />
                                    </div>
                                    <div className="editPropertyOptions-content overflow-hidden">
                                        <div className="w-full overflow-auto min-h-[220px]">
                                            <table className="w-full border-collapse border-none">
                                                <thead className="editPropertyOptions-thead rounded-t-full overflow-hidden">
                                                    <tr>
                                                        <th className="editPropertyOptions-th truncate">
                                                            {t('Label')}
                                                        </th>
                                                        <th className="editPropertyOptions-th min-w-[104px]">
                                                            {t('In Use')}
                                                        </th>
                                                        {
                                                            propertyCurrent?.children?.length > 0 ? propertyCurrent?.children?.map(item => isSelectComponent(item?.property_type) && <th key={item?.id} className="editPropertyOptions-th text-primary-500 whitespace-nowrap">
                                                                <div className="cursor-pointer"
                                                                    onClick={() => history.push(`/workspace/${params?.id}/setting?tab=property&subtab=${subtab}&id=${query.get('id')}&idSelected=${item?.id}`)}
                                                                >
                                                                    {item?.name}
                                                                </div>
                                                            </th>) : <th className="w-1/3"></th>
                                                        }
                                                        <th className="editPropertyOptions-th max-w-[40px]"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fields?.length > 0 && fields?.map((field: any, index) =>
                                                        <tr key={'option_' + index} className="editPropertyOptions-item">
                                                            <td className="editPropertyOptions-col">
                                                                <input {...register(`property_options.${index}.name`, {
                                                                    required: t("This field is required"),
                                                                    validate: (value) => checkForDuplicates(value, index)
                                                                })}
                                                                    className={`editPropertyOptions-input min-w-[140px] w-9/12 ${errors.property_options?.[index]?.['name'] ? '!border-red-500' : ''}`}
                                                                    type="text" />
                                                                {errors.property_options?.[index]?.['name'] && (
                                                                    <p className="normal-case text-xs text-red-500 font-semibold mt-1 errors">{errors.property_options[index]?.['name']?.message}</p>
                                                                )}
                                                            </td>
                                                            <td className="editPropertyOptions-col w-[104px] h-[50px] items-center">
                                                                <span className="mt-1.5 inline-block">
                                                                    0
                                                                </span>
                                                            </td>
                                                            {
                                                                field?.children.length > 0 ? field?.children?.map((child, childIndex) => isSelectComponent(child?.property_type) && <td key={child?.name} className="editPropertyOptions-col min-w-[200px]">
                                                                    <SelectBox
                                                                        label=""
                                                                        control={control}
                                                                        name={`property_options.${index}.children.${childIndex}.property_options`}
                                                                        data={[{ name: 'option 1', value: 'option 1' }]}
                                                                        placeholder={t('Select ' + propertyCurrent?.children[childIndex]?.name + "'s")}
                                                                        getData={() => { }}
                                                                        numberOfItemDisplayed={1}
                                                                        isMulti={true}
                                                                        errors={errors}
                                                                        isShowSearchIcon={false}
                                                                        value={watch(`property_options.${index}.children.${childIndex}.property_options`) || []}
                                                                        className="!h-[34px]"
                                                                    />
                                                                </td>) : <td></td>
                                                            }
                                                            <td className="editPropertyOptions-col w-10 sticky right-0 bg-white">
                                                                <button type="button" className="text-red-500 flex h-8 items-center"
                                                                    onClick={() => {
                                                                        setToggleModalDelete(true);
                                                                        setSelectIndexOption(index);
                                                                    }}
                                                                >
                                                                    <TrashIcon className="w-4 h-4" />
                                                                </button>
                                                            </td>

                                                        </tr>)
                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                fields?.length <= 0 && <NoContentWithImage content={t('No Property Option(s)')} />
                                            }
                                        </div>
                                        <div className="editPropertyOptions-footer">
                                            <button type="button" className="flex text-primary-500 gap-2"
                                                onClick={() => {
                                                    append({ name: '', children: propertyCurrent?.children });
                                                }}
                                            >
                                                <PlusIcon className="w-5 h-5" /> {t('Add an option')}
                                            </button>
                                        </div>
                                    </div>
                                </div>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <DialogComponent
            isOpen={toggleModalDelete}
            onCloseModal={setToggleModalDelete}
            title={t('Remove Option')}
            child={
                <>
                    <p>{t('Are you sure you want to remove this option')}</p>
                    <div className="flex gap-4 justify-center mt-4">
                        <ButtonOutline
                            type="button"
                            size="M"
                            onClick={() => setToggleModalDelete(false)}
                        >{t('Cancel')}</ButtonOutline>
                        <ButtonPrimary
                            type="button"
                            size="M"
                            className="!bg-red-500 !border-red-500 !hover:bg-red-600"
                            onClick={() => {
                                remove(selectedIndexOption);
                                setToggleModalDelete(false);
                            }}
                        >{t('Remove')}</ButtonPrimary>
                    </div>
                </>
            }
        />
        {toggleModalSave && <DialogComponent
            isOpen={toggleModalSave}
            onCloseModal={setToggleModalSave}
            title={t('Confirmation')}
            child={
                <>
                    <p>{t('Are you sure you want to save this information')}</p>
                    <div className="flex gap-4 justify-center mt-4">
                        <ButtonOutline
                            type="button"
                            size="M"
                            onClick={() => setToggleModalSave(false)}
                        >{t('Cancel')}</ButtonOutline>
                        <ButtonPrimary
                            type="button"
                            size="M"
                            loading={isLoading}
                            onClick={() => {
                                handleSubmit(onSubmit)();
                                setToggleModalSave(false);
                            }}
                        >{t('Confirm')}</ButtonPrimary>
                    </div>
                </>
            }
        />}
    </>
}