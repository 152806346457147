export const GetLinkIcon = ({ className = '' }: { className?: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 20 20" className={className}>
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M5.833 6.667a3.333 3.333 0 0 0 0 6.666H7.5A.833.833 0 0 1 7.5 15H5.833a5 5 0 0 1 0-10H7.5a.833.833 0 0 1 0 1.667zm5.834-.834c0-.46.373-.833.833-.833h1.667a5 5 0 1 1 0 10H12.5a.833.833 0 0 1 0-1.667h1.667a3.333 3.333 0 0 0 0-6.666H12.5a.833.833 0 0 1-.833-.834"
                clipRule="evenodd"
            ></path>
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M5.833 10c0-.46.373-.833.834-.833h6.666a.833.833 0 0 1 0 1.666H6.667A.833.833 0 0 1 5.833 10"
                clipRule="evenodd"
            ></path>
        </svg>
    );
};
