import { ProgressOverview } from "./CourseOverview/ProgressOverview";
import "./CourseGradebook.scss";
import { GradebookList } from "./GradebookList/GradebookList";
import { useLocation } from "react-router-dom";
import { GradebookDetails } from "./GradebookDetails/GradebookDetails";

export const CourseGradebook = ({userData}) => {
    const search = useLocation()?.search;
    const enrollmentId = new URLSearchParams(search).get('enrollmentId');
    const subtab = new URLSearchParams(search).get('subtab');
    return (<div className="LearnerGradebook">
        {subtab ? <GradebookDetails enrollmentId={enrollmentId} userData={userData} /> :
            <>
                <ProgressOverview />
                <GradebookList />
            </>}
    </div>)
}