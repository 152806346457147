import { FC, ReactNode } from 'react';
import { Popover } from '@headlessui/react';
import { PlusSmIcon } from '@heroicons/react/outline';
import { classNames } from 'utils/commonFun';

type ItemListType = {
    label: string;
    icon?: ReactNode;
    action?: () => void;
    hidden?: boolean;
    disabled?: boolean;
    className?: string;
};

type PopoverComponentProps = {
    title: string;
    icon?: ReactNode;
    list: ItemListType[];
    className?: string;
    classNamePanel?: string;
};

export const PopoverComponent: FC<PopoverComponentProps> = ({ title, icon, list, className = '', classNamePanel = '' }) => {
    return (
        <Popover className="relative">
            <Popover.Button
                className={classNames('flex items-center space-x-1 text-white text-base px-4 py-2 bg-primary-500 rounded', className)}
            >
                {!!icon ? icon : <PlusSmIcon className="w-5 h-5" />}
                <span>{title}</span>
            </Popover.Button>
            <Popover.Panel
                className={classNames('absolute top-10 right-0 z-10 bg-white py-1 rounded shadow-lg w-[200px]', classNamePanel)}
            >
                {list.map((item, index) => (
                    <button
                        key={index}
                        onClick={() => !item?.disabled && item?.action?.()}
                        disabled={item?.disabled}
                        className={classNames(
                            'w-full flex items-center space-x-2 p-2 hover:bg-blue-50 text-sm font-normal',
                            item?.hidden ? 'hidden' : '',
                            item?.disabled ? 'cursor-not-allowed text-gray-400' : '',
                            item?.className
                        )}
                    >
                        {item?.icon}
                        <span>{item?.label}</span>
                    </button>
                ))}
            </Popover.Panel>
        </Popover>
    );
};
