import React, { useMemo } from 'react';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import { ListUser } from '_shared/components/User/User';
import dayjs from 'dayjs';
import { INVOICE_STATUS } from '../../constants';
import Action from './Action';
import { useHistory, useParams } from 'react-router-dom';
import { checkPermission } from 'utils/commonFun';
interface AllInvoiceTableProps {
    workspaceId: string;
    dataTable: any;
    translator: Function;
    loading?: boolean;
    refreshData?: any;
    location?: string;
}

const AllInvoiceTable: React.FC<AllInvoiceTableProps> = ({ workspaceId, dataTable, translator, refreshData, location }) => {
    const invoiceStatus = useMemo(
        () => (translator) => {
            return INVOICE_STATUS(translator);
        },
        [translator]
    );
    const history = useHistory();
    const params: { id } = useParams()
    const columns = React.useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('RECURRING_INVOICE_NAME')} />,
                Cell: ({ row }) => {
                    return <RenderTableCell onClick={() => history.push(`/workspace/${params?.id}/e-commerce/billing/${row?.original?.id}?type=recurring`)} className="text-primary-500 font-semibold" value={row.values.name} />;
                },
                accessor: 'name',
                width: 140,
            },
            {
                Header: () => <RenderTableHeader value={translator('LEARNER_AND_LEARNING_GROUP')} />,
                Cell: ({ row }) => {
                    return (
                        <div className="px-2">
                            <ListUser
                                id={'leaners'}
                                users={[...(row?.values?.learners || []), ...(row?.original?.learning_groups || [])]?.map((item) => {
                                  let link  = `/workspace/${workspaceId}/management/users/${item?.id}?tab=information`;
                                  if  (item?.name) link  = `/workspace/${workspaceId}/management/learning-groups/${item?.id}`;
                                  return {
                                      name: item?.display_name || item?.name,
                                      avatar: item?.avatar_url,
                                      link
                                  }
                                })}
                            />
                        </div>
                    );
                },
                accessor: 'learners',
                width: 140,
            },
            {
                Header: () => <RenderTableHeader value={translator('START_DATE')} />,
                Cell: ({ row }) => {
                    return <RenderTableCell value={dayjs(row?.values?.start_at)?.format('DD/MM/YYYY・HH:mm')} />;
                },
                accessor: 'start_at',
                width: 120,
            },
            {
                Header: () => <RenderTableHeader value={translator('END_DATE')} />,
                Cell: ({ row }) => {
                    return <RenderTableCell value={dayjs(row?.values?.end_at)?.format('DD/MM/YYYY・HH:mm')} />;
                },
                accessor: 'end_at',
                width: 120,
            },
            {
                Header: () => <RenderTableHeader value={translator('FREQUENCY')} />,
                Cell: ({ row }) => {
                    return <RenderTableCell className="capitalize" value={translator(row?.values?.frequency_type.toUpperCase())} />;
                },
                accessor: 'frequency_type',
                width: 120,
            },
            {
                Header: () => <RenderTableHeader value={translator('STATUS')} />,
                Cell: ({ row }) => {
                    return (
                        <div className="text-xs">
                            {row?.values?.status === invoiceStatus(translator).paid?.value && (
                                <div className="text-green-500 bg-green-50 border border-green-300 px-2 py-[3px] rounded w-fit">
                                    {invoiceStatus(translator)?.paid?.label}
                                </div>
                            )}
                            {row?.values?.status === invoiceStatus(translator).active?.value && (
                                <div className="text-blue-500 bg-blue-50 border border-blue-300 px-2 py-[3px] rounded w-fit">
                                    {invoiceStatus(translator)?.active?.label}
                                </div>
                            )}
                            {row?.values?.status === invoiceStatus(translator).cancelled?.value && (
                                <div className="text-gray-500 bg-gray-50 border border-gray-300 px-2 py-[3px] rounded w-fit">
                                    {invoiceStatus(translator)?.cancelled?.label}
                                </div>
                            )}
                            {row?.values?.status === invoiceStatus(translator).completed?.value && (
                                <div className="text-green-500 bg-purple-50 border border-green-300 px-2 py-[3px] rounded w-fit">
                                    {invoiceStatus(translator)?.completed?.label}
                                </div>
                            )}
                        </div>
                    );
                },
                accessor: 'status',
                width: 100,
            },
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }) => {
                    return <Action data={row?.original} refreshData={refreshData} position={row?.index > dataTable?.length/2 ? 'bottom-0':''} />;
                },
                accessor: 'action',
                width: 60,
            },
        ],
        []
    );

    return (
        <>
            <Table data={dataTable} columns={columns} emptyContent={translator('NO_TRANSACTIONS')} />
        </>
    );
};

export default AllInvoiceTable;
