import { Dispatch, SetStateAction, useEffect } from 'react';
import {
    FieldValues,
    UseFormGetValues,
    UseFormRegister,
    UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import './style.css';

interface InputNumberProps {
    nameField: string;
    disableInput: boolean;
    setValue: UseFormSetValue<FieldValues>;
    getValues: UseFormGetValues<FieldValues>;
    register: UseFormRegister<FieldValues>;
    valueField?: number;
    maxNumber?: number;
    placeHolder?: string;
    isRequired?: boolean;
}
const InputNumber: React.FC<InputNumberProps> = ({
    disableInput,
    register,
    nameField,
    getValues,
    valueField,
    setValue,
    maxNumber = 0,
    placeHolder = '',
    isRequired = false,
}) => {
    const { t: translator } = useTranslation();
    useEffect(() => {
        if (valueField || !disableInput) {
            setValue(nameField, valueField);
        }
    }, [valueField, disableInput]);

    const handleOut = (e: any) => {
        if (e) {
            if (e <= 0) {
                setValue(nameField, 0);
            }
            if (e > maxNumber) {
                setValue(nameField, maxNumber);
            }
        }
    };
    return (
        <div className="w-full">
            <input
                id={nameField}
                disabled={disableInput}
                placeholder={placeHolder}
                type="text"
                pattern="[0-9]*"
                onInput={(e: any) => {
                    const valueChange = e.target.validity.valid
                        ? e.target.value
                        : getValues(nameField);
                    setValue(nameField, valueChange);
                }}
                className={` bg-white w-full  ${
                    disableInput
                        ? 'pl-2'
                        : 'rounded border border-ooolab_gray_6 p-2'
                }`}
                {...register(nameField, {
                    required: {
                        value: isRequired,
                        message: translator('FORM_CONST.REQUIRED_FIELD'),
                    },
                })}
                defaultValue={valueField}
                onBlur={(e) => handleOut(e?.target?.value)}
            />
        </div>
    );
};

export default InputNumber;
