import React from 'react';
import ManagementMasterPage from 'pages/RouteMasterPage';
import ManagePermissionAdmin from 'pages/Management/ManagePermissionAdmin';
import RolesPermissionsList from 'components/Management/RolesPermissions/RolesPermissionsList/RolesPermissionsList';

const RolesPermissionsListPage = () => {
    return (
        <ManagePermissionAdmin>
            <RolesPermissionsList>
                <ManagementMasterPage />
            </RolesPermissionsList>
        </ManagePermissionAdmin>
    );
};

export default RolesPermissionsListPage;
