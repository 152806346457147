import { ImageUpload } from "assets/icon";
import { hasCheckedStream } from "constant/file.constant";
import React, { forwardRef, useState } from "react";
import VideoPlayer from "_shared/components/VideoPlayer/VideoPlayer";
import "./InputFile.scss";
import { IframePdfViewer } from "_shared/PreviewContent/IframePDFViewer";


type InputFileProps = {
    className?: string;
    imgDefault?: string;
    onChange: (value) => void;
    textHelp?: string;
    icon?: React.ReactNode;
    hasVideo?: boolean;
    isDisable?: boolean;
    stylesCenter?: string;
    accept?: string;
    subTextHelp?: any
    isPdf?: boolean;
};

export const InputFile = forwardRef<HTMLInputElement, InputFileProps>((props, ref) => {
    const {
        className,
        onChange,
        imgDefault,
        icon,
        textHelp = '.PNG, .JPEG accepted  |   Max file size 5MB',
        hasVideo,
        isDisable = false,
        stylesCenter = '',
        accept,
        subTextHelp,
        isPdf = false,
    } = props;
    const [key, setKey] = useState(0);

    const onFileChange = (e) => {
        const file = e?.target?.files[0];
        setKey(prevKey => prevKey + 1);
        if (!!file) {
            onChange(file);
        }
    };

    return <div className={`inputFile bg-cover ${className}`} style={{ backgroundImage: `url(${imgDefault && !hasVideo || !isPdf ? imgDefault : ''})` }}>
        <input ref={ref} key={key} accept={accept} type="file" onChange={onFileChange} disabled={isDisable} />
        {<div className={`inputFile-content ${imgDefault ? 'opacity-0' : ''}`}>
            <div className={`${stylesCenter}`}>
                {
                    icon ? icon : <ImageUpload />
                }
                {textHelp && <div className="inputFile-text" dangerouslySetInnerHTML={{ __html: textHelp }}></div>}
                {subTextHelp && subTextHelp}

            </div>
        </div>}
        {(hasVideo && imgDefault) ?
            hasCheckedStream(imgDefault) ?
                <VideoPlayer data={{ file: imgDefault }} color="text-gray-800" /> :
                <video controls className="h-full">
                    <source src={imgDefault} />
                </video> :
            null}
        {(isPdf && imgDefault) ? <IframePdfViewer url={imgDefault} /> : null}
    </div>
});

