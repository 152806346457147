import React, { FC, useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderPage from 'components/HeaderPage';
import { useHistory, useParams } from 'react-router-dom';
import ButtonComponent from 'components/Button/ButtonComponent';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import './stylesCalendar.scss';
import DialogComponent from 'components/Dialog/DialogComponent';
import { useBoolean } from 'hooks/custom';
import ModalCheckinAvailableTime from './ModalCheckinAvailableTime';
import DashboardService from 'services/teach.services';
import { UserContext } from 'contexts/User/UserContext';
import dayjs from 'dayjs';
import { TrashIcon } from '@heroicons/react/outline';
import { getLocalTimeZone, timeConvert } from 'utils/commonFun';
import { ClockIcon } from 'assets/icon';
import { SettingsIcon } from 'assets/icon/SettingIcon';
import moment from 'moment';
import { PlusIcon } from '@heroicons/react/solid';
import { Toastify } from '_shared';

const dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';
const dateFormatString = 'YYYY-MM-DDTHH:mm:ss';

const index: FC = ({ children }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const params: any = useParams();
    const calendarRef = useRef(null);

    const { booleanValue: modalDetailClass, toggleBooleanValue: toggleModalDetailClass } = useBoolean();
    const { booleanValue: modalCheckinAvailable, toggleBooleanValue: toggleModalCheckinAvailable } = useBoolean();
    const { booleanValue: modalConfirmRemove, toggleBooleanValue: toggleModalConfirmRemove } = useBoolean();
    const [tzOffset, setTzOffset] = React.useState(dayjs().tz('UTC').format(dateFormat).slice(-6));
    const [calendarChange, setCalendarChange] = useState<{
        view: string;
        start: string;
        end: string;
    }>(null);

    const [selectedDetail, setSelectedDetail] = useState<any>({});
    const [availableEvents, setAvailableEvents] = useState<any>([]);

    const {
        userState: { result },
    } = useContext(UserContext);

    const workspaceId = params?.id;

    const translator = (str: string, newKey?: boolean, opt?: any) =>
        newKey ? t(str, opt) : t(`DASHBOARD.AVAILABILITY_DASHBOARD.${str}`, opt);

    const fetchEvents = (_tzOffset?: string) => {
        if (!calendarChange?.start || !calendarChange.end) return;
        DashboardService.getTeacherEvents(workspaceId, result?.id, {
            includes_past: true,
            start_time: dayjs(calendarChange.start).format('YYYY-MM-DDT00:00:00') + (_tzOffset || tzOffset),
            end_time: dayjs(calendarChange.end).format('YYYY-MM-DDT00:00:00') + (_tzOffset || tzOffset),
        })
            .then((res) => {
                const arr2DData = res?.data?.map((d) => d?.calendar_time_ranges);
                const arrData = arr2DData?.flat() || [];
                setAvailableEvents(
                    arrData?.map((el) => ({
                        ...el,
                        title: 'Event',
                        start: el?.start_time,
                        end:
                            dayjs(el?.start_time).add(el.duration, 'minutes').tz(result?.time_zone).format(dateFormatString) +
                            (_tzOffset || tzOffset),
                        calendar_event_id: el.calendar_event_id,
                        duration: el.duration,
                        extendedProps: {
                            ...el,
                            endDate: dayjs(el?.start_time).add(el.duration, 'minutes').format(dateFormatString),
                            objParent: res?.data?.find((item) => item?.id === el?.calendar_event_id) || '',
                        },
                    }))
                );
            })
            .catch()
            .finally();
    };

    useEffect(() => {
        if (result?.id) {
            const _tzOffset = dayjs().tz(result?.time_zone).format(dateFormat).slice(-6);
            setTzOffset(_tzOffset);
            fetchEvents(_tzOffset);
        }
    }, [result?.id]);

    useEffect(() => {
        fetchEvents();
    }, [calendarChange]);

    const renderEventContent = (eventInfo) => {
        if (eventInfo?.view?.type === 'timeGridWeek') {
            const value = eventInfo?.event._def;
            const duration = moment.duration(moment(value?.extendedProps?.start_time).diff(new Date())).asHours();
            return (
                <>
                    <div
                        className={`fc-event-main-frame fc-sticky p-2 ${
                            duration <= 12 ? 'bg-[#F3F4F6] rounded-lg fc-event-past fc-event-lock ' : ''
                        }`}
                    >
                        <div className="fc-event-title-container">
                            <div className="fc-event-title text-[10px] font-semibold truncate">
                                {value?.extendedProps?.objParent?.display_name}
                            </div>
                        </div>
                        <div className="text-dark-300">
                            {dayjs(value?.extendedProps?.start_time).tz(result?.time_zone).format('MMM DD')}
                        </div>
                        <div className="fc-event-time flex items-center justify-start space-x-2 pb-2">
                            {dayjs(value?.extendedProps?.start_time).tz(result?.time_zone).format('HH:mm')}
                            <span className="mx-1"> - </span>
                            {dayjs(value?.extendedProps?.endDate).tz(result?.time_zone).format('HH:mm')}
                            <span className={`fc-event-lock absolute right-2! ${duration <= 12 ? 'block!' : ''}`}>
                                <ClockIcon />
                            </span>
                        </div>
                    </div>
                </>
            );
        }
    };

    const onRemoveEvent = (details) => {
        if (details) {
            DashboardService.removeEventTeacher(workspaceId, result?.id, details.calendar_event_id, {
                schedule_time_range_id: details?.schedule_time_range_id,
                start_time: details?.start_time,
            })
                .then((res) => {
                    if (res?.data) {
                        Toastify.success();
                        setTimeout(() => {
                            fetchEvents();
                        }, 500);
                    }
                })
                .catch((err) => Toastify.error())
                .finally(() => toggleModalConfirmRemove());
        }
    };

    return (
        <div className="text-dark-300 text-xs font-normal">
            <HeaderPage label={translator('DASHBOARD.SIDEBAR.INSTRUCTOR_AVAILABILITY', true)} />
            <div className="relative">
                <div className="flex items-center justify-end px-5 absolute top-5 right-0">
                    <ButtonComponent
                        title={translator('ADD_AVAILABILITY')}
                        classStyle="xs:p-2 xs:text-sm primary-button"
                        onClickButton={() => {
                            toggleModalCheckinAvailable();
                        }}
                        icon={<PlusIcon className="w-4 h-4 mr-ooolab_m_1" />}
                    />
                </div>
                <div className="absolute text-center top-[80px] left-[25px] z-[99] text-xs">
                    <button
                        type="button"
                        className="text-primary-500 hover:opacity-80 block text-center m-auto"
                        onClick={() => {
                            history.push(`/workspace/${workspaceId}/user-setting`);
                        }}
                    >
                        <SettingsIcon />
                    </button>
                    <span className="text-[10px]">UTC{dayjs(new Date()).tz(result?.time_zone).format('Z')}</span>
                </div>
                <div className="px-5 pb-5 grid grid-cols-2 gap-6 ">
                    <div className="col-span-2">
                        <div className="mt-6 calendar-wrapper-available-instructor ">
                            <FullCalendar
                                ref={calendarRef}
                                initialView="timeGridWeek"
                                allDaySlot={false}
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                headerToolbar={{
                                    left: 'today prev,next title',
                                    right: '',
                                }}
                                events={availableEvents}
                                eventContent={renderEventContent}
                                eventMaxStack={2}
                                eventClick={(e) => {
                                    const durations = moment
                                        .duration(moment(e?.event?._def?.extendedProps?.start_time).diff(new Date()))
                                        .asHours();
                                    if (durations > 12) {
                                        setSelectedDetail(e?.event?._def?.extendedProps);
                                        toggleModalDetailClass();
                                    }
                                }}
                                slotLabelFormat={{
                                    hour: '2-digit',
                                    minute: '2-digit',
                                }}
                                dayMaxEventRows={3}
                                slotEventOverlap={false}
                                slotDuration={'00:15:00'}
                                slotLabelInterval={15}
                                datesSet={(arg) => {
                                    const { startStr, endStr, view } = arg;
                                    setCalendarChange({
                                        start: startStr,
                                        end: endStr,
                                        view: view.type,
                                    });
                                }}
                                timeZone={result?.time_zone || getLocalTimeZone()}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <DialogComponent
                isOpen={modalCheckinAvailable}
                title={translator('ADD_AVAILABLE')}
                onCloseModal={toggleModalCheckinAvailable}
                stylesTitle={'text-base text-gray-900 font-semibold'}
                styles="max-w-xl"
                maskClosable={false}
                child={
                    <ModalCheckinAvailableTime
                        workspaceId={workspaceId}
                        translator={translator}
                        data={undefined}
                        fetchData={() => {
                            toggleModalCheckinAvailable();
                            setTimeout(() => {
                                fetchEvents();
                            }, 500);
                        }}
                    />
                }
            />
            <DialogComponent
                isOpen={modalDetailClass}
                onCloseModal={toggleModalDetailClass}
                maskClosable={true}
                hasNonTitle={true}
                child={
                    <div className="text-[#6B7280] text-sm px-4 mb-2 -mt-2 ">
                        <div className="flex items-center">
                            <div className="text-[#6B7280] text-sm flex-none w-[80px] !font-normal">{translator('DATE')}: </div>
                            <div className="w-full font-normal text-sm text-[#1F2937]">
                                {dayjs(selectedDetail?.start_time).tz(result?.time_zone).format('dddd, MMMM D hh:mma')} -{' '}
                                {dayjs(selectedDetail?.endDate).tz(result?.time_zone).format('hh:mma')}{' '}
                            </div>
                        </div>
                        <div className="flex items-center  mt-0.5">
                            <div className="text-[#6B7280] flex-none w-[80px] !font-normal">{translator('DURATION')}: </div>
                            <div className="w-3/4 text-sm text-[#1F2937]">
                                <span className="">{timeConvert(selectedDetail?.duration)}</span>
                            </div>
                        </div>
                        {
                            <div className="flex items-center mt-0.5">
                                <div className="text-[#6B7280] flex-none w-[80px] !font-normal">{translator('RECURRING')}:</div>
                                <div className="w-3/4 text-sm text-[#1F2937]">
                                    {selectedDetail?.recurring_start_at ? (
                                        <span className="">
                                            {translator('WEEKLY_ON_UNTIL', false, {
                                                StartDate:
                                                    dayjs(selectedDetail?.recurring_start_at).tz(result?.time_zone).format('dddd') ||
                                                    '',
                                                EndDate: '',
                                            })}
                                            {dayjs(selectedDetail?.recurring_end_at).tz(result?.time_zone).format('YYYY/MM/DD')}
                                        </span>
                                    ) : (
                                        'No'
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                }
                width={'min-w-[35vw] 2xl:min-w-[338px] !p-0'}
                subTitle={
                    <div className="flex justify-between items-center w-full border-b px-4 py-2">
                        <div className="text-[#111827 text-base font-semibold]">{selectedDetail?.objParent?.display_name}</div>
                        <button
                            type="button"
                            disabled={
                                selectedDetail?.recurring_start_at ||
                                moment(selectedDetail?.start_time).diff(new Date(), 'hours') <= 12
                            }
                            className={`${
                                !selectedDetail?.recurring_start_at &&
                                moment(selectedDetail?.start_time).diff(new Date(), 'hours') > 12
                                    ? 'text-[#EF4444]'
                                    : 'hidden'
                            }`}
                            onClick={() => {
                                toggleModalConfirmRemove();
                                toggleModalDetailClass();
                            }}
                        >
                            <TrashIcon className="w-5 h-5" />
                        </button>
                    </div>
                }
                stylesTitle="w-full"
                isShowClose={false}
            />
            <DialogComponent
                isOpen={modalConfirmRemove}
                onCloseModal={toggleModalConfirmRemove}
                title={translator('CONFIRMATION')}
                maskClosable={true}
                child={
                    <div className="text-center">
                        <div className="text-dark-300 text-sm">{translator('REMOVE_AVAILABLE')}</div>
                        <div className="mt-8">
                            <button type="button" className="text-dark-100 text-sm" onClick={() => toggleModalConfirmRemove()}>
                                {translator('CANCEL')}
                            </button>
                            <button
                                type="button"
                                className="ml-5 text-white rounded px-2 py-1 bg-[#E7443C] text-sm"
                                onClick={() => onRemoveEvent(selectedDetail)}
                            >
                                {translator('REMOVE')}
                            </button>
                        </div>
                    </div>
                }
                width={'min-w-[35vw] 2xl:min-w-[30vw]'}
            />
        </div>
    );
};

export default index;
