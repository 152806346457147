import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
    IContentService,
    IH5PContentList,
    ParamsH5P,
} from 'types/H5P.type';
import { ContentService } from 'services/h5p/ContentService';
import { PlusSmIcon } from '@heroicons/react/solid';
import { updateList } from './H5PFN';
import ContentListComponentHook from './ContentListComponentHook';
import { debounce } from 'lodash';
import { H5PContext } from 'contexts/H5P/H5PContext';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import h5pMiddlware from 'middleware/h5p.middlware';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import SearchBox from 'components/V2/SearchBox';

// UTILS

const H5P: React.FC = ({ children }) => {
    const { t: translator } = useTranslation();
    const history = useHistory();
    const pathname = history.location.pathname;
    const pathArray = pathname.split('/');
    const workspacePos = pathArray.indexOf('workspace');
    const workspaceId = pathArray[workspacePos + 1];
    /*@ts-ignore*/
    const contentService: IContentService = new ContentService(
        `/v1/h5p/workspaces/${workspaceId}`
    );

    const h5PCtx = React.useContext(H5PContext);

    const {
        dispatch: h5pDispatch,
        H5PState: { h5PContentListResult, params },
    } = h5PCtx;

    const [inputValue, setInputValue] = useState<string>();

    const [contentList, setContentList] = useState<IH5PContentList>({
        items: [],
        order: undefined,
        page: undefined,
        per_page: undefined,
        sort_by: undefined,
        total: undefined,
    });

    React.useEffect(() => {
        if (h5PContentListResult) {
            setContentList(h5PContentListResult);
        }
    }, [h5PContentListResult]);

    const debounceInput = React.useCallback(
        debounce((nextValue: string, asyncFunction: (p: string) => void) => {
            setInputValue(nextValue);
            asyncFunction(nextValue);
        }, 1000),
        []
    );

    function handleSearchH5P(t: string) {
        const searchH5p: ParamsH5P = {
            ...params,
            title: t,
            page: 1,
        };
        h5pMiddlware.h5pParamsContent(h5pDispatch, searchH5p);
        updateList(h5pDispatch, workspaceId, searchH5p);
    }
    
    const debouncedOnChange = debounce(handleSearchH5P, 1000);

    return (
        <div className="">
            <ToastContainer />
            <PageBreadcrumb
                pageNameDefault={translator(
                    'DASHBOARD.SIDEBAR.CONTENT_MANAGEMENT'
                )}
                pages={[
                    { name: translator('DASHBOARD.H5P_CONTENTS.CONTENT_LIBRARY'), href: `/workspace/${workspaceId}/h5p-content`, className: '' },
                ]}
                containerClassName=" z-10"
            />
            <div className="flex items-center justify-between m-4 ">
                {/* <div className=''>{children}</div>
                    <Link to={`/workspace/${workspaceId}/h5p-content`}>
                        <p className="text-ooolab_xl font-semibold text-ooolab_dark_1">
                            {translator('DASHBOARD.H5P_CONTENTS.H5P_CONTENTS')}
                        </p>
                    </Link> */}
                <div className="max-w w-full max-w-xs">
                    <SearchBox onSubmit={debouncedOnChange} />
                </div>

                {/* <div className="relative">
                        <input
                            className={` border-2 border-ooolab_border_logout rounded-sub_tab pl-ooolab_p_3 overflow-hidden ease-linear transition-transform duration-500 w-full h-ooolab_h_10 focus:outline-none pr-ooolab_p_9`}
                            type="text"
                            placeholder={translator('SEARCH')}
                            onChange={(e) => onChangeInput(e)}
                            onKeyDown={(e: any) => {
                                if (e.key === 'Enter') {
                                    handleSearchH5P(e.target.value);
                                }
                            }}
                        />
                        <SearchIcon
                            className="w-ooolab_w_5 h-ooolab_h_5 text-ooolab_dark_2 absolute cursor-pointer top-ooolab_inset_22 right-ooolab_inset_5 "
                            onClick={() => handleSearchH5P(inputValue)}
                        />
                    </div> */}
                <div className="">
                    <ButtonPrimary onClickButton={() =>
                            history.push(
                                `/workspace/${workspaceId}/h5p-content/new`
                            )
                        }>
                        <PlusSmIcon
                            className="-ml-1 mr-1 h-5 w-5"
                            aria-hidden="true"
                        />
                        <p className="text-sm">
                            {translator('DASHBOARD.H5P_CONTENTS.NEW_H5P')}
                        </p>
                    </ButtonPrimary>
                </div>
            </div>
            <div className="border-t mx-2">
                <ContentListComponentHook
                    contentService={contentService}
                    workspaceId={workspaceId}
                    contentList={contentList}
                    setContentList={setContentList}
                ></ContentListComponentHook>
            </div>
        </div>
    );
};

export default H5P;
