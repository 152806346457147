import { FC, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ActionsButton from 'components/ActionsButton';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import DialogComponent from 'components/Dialog/DialogComponent';
import { CircleInformationIcon, EditLine } from 'assets/icon';
import { checkPermission } from 'utils/commonFun';
import { ClipboardCheckIcon } from '@heroicons/react/outline';
import courseService from 'services/course.service';
import { EditDueDate } from './EditDueDate';

interface ActionMenuProp {
    data: any;
    refreshData: Function;
    hideAction?: string[],
    status?: string,
    position?: string
    isDisable?: boolean;
}

const ActionMenu: FC<ActionMenuProp> = ({ data, refreshData, status, hideAction = [], position, isDisable }) => {
    const { t } = useTranslation();
    const params: { id: string; courseId: string } = useParams();
    const [loading, setLoading] = useState(false);
    const [isOpenModalEdit, setOpenModalEdit] = useState(false);

    const translatorCourse = (str: string, newKey?: boolean, opt?: any) => {
        return newKey ? t(str, opt) : t(`COURSES.${str}`, opt)
    }

    const handleUpdateDueDate = async (value) => {
        if (value) {
            try {
                setLoading(true);
                const response = await courseService.updateAssignment(params?.id, params?.courseId, {
                    id: data.id,
                    end_time: value
                });
                if (response?.data?.error) {
                    setLoading(false);
                    return Toastify.error();
                }
                setOpenModalEdit(false);
                setLoading(false);
                refreshData();
                Toastify.success(t("COURSES.UPDATE_DUE_DATE_SUCCESS")); 
            } catch {
                Toastify.error();
            }
        } else {
            setOpenModalEdit(false);
        }
    }

    return (
        <>
            <ActionsButton
                styleOptions={`${position ? position : 'top-0'} right-[35px]`}
                disable={isDisable}
                menus={[
                    ...( [
                        {
                            label: translatorCourse("GRADE"),
                            icon: <ClipboardCheckIcon className='w-5 h-5' />,
                            action: () => window.open(`/workspace/${params.id}/management/courses/${params.courseId}/assignment/${data.id}/grade`, '_blank'),
                            isDisabled: !checkPermission('course', 'R'),
                            hide: hideAction?.includes('grade')
                        }]),
                    {
                        label: translatorCourse("EDIT_DUE_DATE"),
                        icon: <EditLine size={20} />,
                        action: () => setOpenModalEdit(true),
                        isDisabled: !checkPermission('course', 'R'),
                        hide: hideAction?.includes('dueDate')
                    }
                ]}
            />
            <DialogComponent
                title={translatorCourse('EDIT_DUE_DATE')}
                isOpen={isOpenModalEdit}
                onCloseModal={() => setOpenModalEdit(false)}
                isShowClose={true}
                styles="max-w-[400px] !py-1.5 !px-6"
                child={<EditDueDate data={data} onClose={handleUpdateDueDate} loading={loading} />}
            />
        </>
    );
};

export default ActionMenu;
