import { ButtonOutline, ButtonPrimary, Toastify } from '_shared'
import { WarningIcon } from 'assets/icon/WarningIcon'
import DialogComponent from 'components/Dialog/DialogComponent'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';
import { USER_STATUS } from '../constant';

export default function ModalDeactivateMember({isOpen, onClose, data, refreshData}) {
  const {t: translator} = useTranslation();
  const params: {id} = useParams();
  const workspaceId = params?.id;

  const onUpdateMemberStatus = (value) => {
    workspaceService.updateWorkspacePartialMember(workspaceId, data?.membership?.user_id, {status: value})
        .then((res) => {
            refreshData();
            Toastify.success();
        })
        .catch(() => Toastify.error())
        .finally(() => onClose())
  };

  return (
    <DialogComponent
        title={translator('DEACTIVATE')}
        isOpen={isOpen}
        onCloseModal={onClose}
        child={
            <div className="flex flex-col">
                <div className="mt-2 text-sm text-gray-800">{translator('CONFIRM_DEACTIVATE_MEMBER')}</div>
                <div className="flex justify-center gap-4 mt-6 w-full">
                    <ButtonOutline type="button" onClick={onClose}>
                        {translator('CANCEL')}
                    </ButtonOutline>
                    <ButtonPrimary
                        type="button"
                        className="buttonPrimary-danger"
                        onClick={() => onUpdateMemberStatus(USER_STATUS.DEACTIVATE)}
                    >
                        {translator('DEACTIVATE')}
                    </ButtonPrimary>
                </div>
            </div>
        }
    />
  )
}
