import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { timeList } from "constant/workspace.const";
import { CardContainer } from "components/Reports/components/CardContainer";
import { Title } from "components/Reports/components/Title";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import ChartDonut from "components/Reports/components/ChartDonut/ChartDonut";
import { ComboboxSelect } from "components/ComboboxSelect/ComboboxSelect";
import { EmptyStateChart } from "_shared";
import learnerReportService from "services/reports/learner-report.service";
import moment from "moment";
import DatePickerComponent from "components/Reports/components/DatePicker";

export const StudentSessionBreakdown = () => {
    const { t } = useTranslation();
    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);
    const params: { id: string, studentId: string } = useParams();
    const [selectedDate, setSelectDate] = useState(timeList[1]);
    const [loading, setLoading] = useState(true);
    const [breakdownData, setBreakdownData] = useState<any>();
    const [schedulingData, setSchedulingData] = useState<any>();
    const [selectSchedulingDate, setSelectSchedulingDate] = useState(timeList[1]);
    const [noDataBreakdown, setNoDataBreakdown] = useState(false);
    const [noDataScheduling, setNoDataScheduling] = useState(false);
    const search = useLocation()?.search;
    const type = new URLSearchParams(search).get('type'); 


    const getData = async () => {
        if (!startDate || !endDate) {
            return;
        }
        try {
            setLoading(true);
            setNoDataBreakdown(false);
            const payload = {
                start_time: moment(new Date(startDate)).format('YYYY-MM-DD')+ 'T00:00:00.000',
                end_time: moment(new Date(endDate)).format('YYYY-MM-DD')+ 'T23:59:59.000',
                studentId: params?.studentId,
            }
            let  response;
            if (type === 'learning-group') {
                response = await learnerReportService.sessionsGroupBreakdown(params.id, payload);
            } else {
                response = await learnerReportService.sessionsBreakdown(params.id, payload);
            }
            
            setBreakdownData(response?.data);
            setLoading(false);
        } catch {
            setLoading(false);
            setNoDataBreakdown(true);
        }
    }

    const getSchedulingData = async () => {
        if (!startDateType || !endDateType) {
            return;
        }
        try {
            setNoDataScheduling(false);
            setSchedulingData(null);
            const payload = {
                start_time: moment(new Date(startDateType)).format('YYYY-MM-DD')+ 'T00:00:00.000',
                end_time: moment(new Date(endDateType)).format('YYYY-MM-DD')+ 'T23:59:59.000',
                studentId: params?.studentId,
            }
            let response;
            if (type === 'learning-group') {
                response = await learnerReportService.sessionsGroupType(params.id, payload);
            } else {
                response = await learnerReportService.sessionsType(params.id, payload);
            }
            setSchedulingData(response?.data);
        } catch {
            setNoDataScheduling(true);
        }
    }

    const chartData = useMemo(() => {
        if (breakdownData) {
            const data = [
                breakdownData?.enrolled,
                breakdownData?.rescheduled,
                breakdownData?.cancelled
            ]
            const total: number = data?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setNoDataBreakdown(total == 0);
            if (total > 0) {
                return data;
            }
            return []
        }
    }, [breakdownData]);

    const chartDataScheduling = useMemo(() => {
        const data = [
            schedulingData?.scheduled,
            schedulingData?.admin_booking,
            schedulingData?.learner_booking
        ];
        const total = data?.reduce((total, value) => total + value, 0);
        setNoDataScheduling(total == 0);
        if (total > 0) {
            return data;
        }
        return [];
    }, [schedulingData]);

    const [startDate, setStartDate] = useState(new Date(moment().subtract(1, 'months').format('MM-DD-YYYY')));
    const [endDate, setEndDate] = useState(new Date());
    const [startDateType, setStartDateType] = useState(new Date(moment().subtract(1, 'months').format('MM-DD-YYYY')));
    const [endDateType, setEndDateType] = useState(new Date());
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
    const onChangeType = (dates) => {
        const [start, end] = dates;
        setStartDateType(start);
        setEndDateType(end);
    }

    useEffect(() => {
        getData();
    }, [startDate, endDate]);

    useEffect(() => {
        getSchedulingData();
    }, [startDateType, endDateType])

    return <>
        <div className="flex space-x-4">
            <div className="w-1/2 min-h-[332px]">
                <CardContainer>
                    <>
                        <div className="flex flex-col lg:flex-row lg:items-center text-left">
                            <Title>{translator('Session Breakdown')}</Title>
                            <div className="flex-none lg:ml-auto min-w-[132px] whitespace-nowrap">
                                <DatePickerComponent onChange={onChange} startDate={startDate} endDate={endDate} />
                            </div>
                        </div>
                        {loading ?
                            <div className="flex justify-center items-center w-full min-h-[252px]">
                                <CircleSpin color="text-primary-500" />
                            </div> :
                            <div className="flex items-center">
                                {
                                    !noDataBreakdown ?
                                        <ChartDonut data={chartData} label={t("SESSION.SESSIONS")}
                                            dataLabel={[
                                                { status: 'completed' },
                                                { status: 'rescheduled' },
                                                { status: 'cancelled' }
                                            ]} /> :
                                        <div className="min-h-[252px] w-full flex items-center justify-center">
                                            <EmptyStateChart />
                                        </div>
                                }
                            </div>
                        }
                    </>

                </CardContainer>
            </div>
            <div className="w-1/2">
                <CardContainer>
                    <div className="flex flex-col lg:flex-row lg:items-center text-left">
                        <Title>{translator('Session Type')}</Title>
                        <div className="flex-none lg:ml-auto min-w-[132px] whitespace-nowrap">
                            <DatePickerComponent onChange={onChangeType} startDate={startDateType} endDate={endDateType} />
                        </div>
                    </div>
                    <div className="flex items-center">
                        {!noDataScheduling ?
                            <ChartDonut data={chartDataScheduling} label={t("SESSION.SESSIONS")}
                                dataLabel={[
                                    { status: 'scheduled' },
                                    { status: 'admin_booking' },
                                    { status: 'learner_booking' }
                                ]}
                                colors={['#22C55E', '#3B82F6', '#F59E0B']}
                            /> :
                            <div className="min-h-[252px] w-full flex items-center justify-center">
                                <EmptyStateChart />
                            </div>
                        }

                    </div>
                </CardContainer>
            </div>
        </div>
    </>
}