import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonDownload from 'components/Button/ButtonDownload';
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { NoContentWithImage } from "components/NoContent/NoContent";
import { checkPermission, getIndexPage } from "utils/commonFun";
import TablePagination from "components/V2/Pagination/Pagination";
import { ColumnWithLooseAccessor } from "react-table";
import { useParams } from "react-router-dom";
import { Title } from "../components/Title";
import SearchInput from "../components/SearchInput";
import SelectBox from "components/V2/SelectBox";
import { useDebouncedState } from "hooks/useDebounce";
import rolesPermissionsService from "services/rolesPermissions.service";
import trendChart from "assets/img/empty-state/trendChart.png";
import activityReportService from "services/reports/activity-report.service";
import moment from "moment";
import { PlatFormConst } from "types/ActivityDashboard.type";
import { DownloadActivity } from "./DownloadActivity";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/outline";
import Table from "components/V2/Table/TableCustom";
import { RenderTableCell, RenderTableHeader } from "components/Table/TableCustom";

export const LastActivity = ({startDate, endDate }) => {
    const { t } = useTranslation();
    const translator = (str: string, opt?: any) => t(`USER_ACTIVITY_DASHBOARD.${str}`, opt);
    const params: { id: string, classId: string } = useParams();
    const [data, setData] = useState<any>();
    const [keywordSearch, setKeyWordSearch] = useState(null);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [inputSearchOwner, setInputSearchOwner] = useDebouncedState('');
    const [ownerSelected, setOwnerSelected] = useState([]);
    const [listOwners, setListOwner] = useState([]);
    const [platformSelected, setPlatformSelected] = useState([]);
    const [loadingOwner, setloadingOwner] = useState(false);
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'created_at',
        total: 0,
    });
    const columns: any[] = useMemo(
        () => [
            {

                Header: () => (
                    <RenderTableHeader value={translator('User ID')} />
                ),
                Cell: (d) => {
                    return (<RenderTableCell value={d?.value} />)
                },
                accessor: 'id',
            },
            {
                Header: () => <RenderTableHeader value={translator('User Name')} />,
                accessor: 'display_name',
                Cell: (d: any) => {
                    return (
                        <div className="text-primary-500 flex gap-1 text-xs">
                            <div className="w-5 h-5 rounded-full bg-cover" 
                                style={{backgroundImage: `url(${d?.row?.original?.avatar_url})`}}>

                            </div>
                            {d?.value}
                        </div>
                    );
                },
            },
            {
                Header: () => <RenderTableHeader value={translator('Platform')} />,
                accessor: 'membership.locations',
                Cell: (d: any) => {
                    return (
                        <RenderTableCell value={PlatFormConst[d.value?.[0]]} />
                    );
                },
            },
            {
                Header: () => <RenderTableHeader value={translator('User Role')} />,
                accessor: 'membership.user_role',
                Cell: (d: any) => {
                    return (
                        <RenderTableCell value={d?.value?.name ? d?.value?.name : d?.row?.original?.membership?.type == 'student' ? translator('Learner') : translator('Parent')} />
                    );
                },
            },
            {
                Header: () => (
                    <div
                        className="flex items-center cursor-pointer text-primary-500 text-xs"
                        onClick={() => onChangeOrder(data?.order == 'desc' ? 'asc' : 'desc')}
                    >
                        <p className="">{translator('Last Activity')}</p>
                        {data?.order == 'desc' ? (
                            <ArrowDownIcon className="w-4 h-4 ml-1" />
                        ) : (
                            <ArrowUpIcon className="w-4 h-4 ml-1" />
                        )}
                    </div>
                ),
                accessor: 'last_activity_at',
                Cell: (d: any) => {
                    return (
                        <RenderTableCell value={moment(d?.value).format('DD/MM/YYYY')} />
                    );
                },
                width: 128
            },
        ],
        [data]
    );

    const onSearchActivities = (value: string) => {
        setKeyWordSearch(value);
    }

    const handleDownloadCSV = async () => { 
        try {
            setIsDownloading(true);
            const payload = {
                page: 1,
                skip_pagination: true,
                q: keywordSearch || '',
                user_role_ids: ownerSelected?.map(item => item?.value) || [],
                locations: platformSelected?.map(item => item?.value) || [],
                start_time: moment(new Date(startDate)).format('YYYY-MM-DD')+ 'T00:00:00.000Z',
                end_time: moment(new Date(endDate)).format('YYYY-MM-DD')+ 'T23:59:59.000Z',
            }
            const res = await activityReportService.getListActivities(params.id, payload);
            DownloadActivity(res?.data?.items);
        } catch (error) {
            
        } finally {
            setIsDownloading(false);
        }
    }

    const handlePagination = (page) => {
        getListActivities(page);
     }

    const getListActivities = async (page: number = 1, order = 'desc') => {
        try {
            setIsLoading(true);
            const payload = {
                page,
                per_page: 20,
                q: keywordSearch || '',
                user_role_ids: ownerSelected?.map(item => item?.value) || [],
                locations: platformSelected?.map(item => item?.value) || [],
                start_time: moment(new Date(startDate)).format('YYYY-MM-DD')+ 'T00:00:00.000Z',
                end_time: moment(new Date(endDate)).format('YYYY-MM-DD')+ 'T23:59:59.000Z',
                order,
                sort_by: 'last_activity_at'
            }
            const res = await activityReportService.getListActivities(params.id, payload);
            if (res?.data) {
                setData(res.data);
            }
        } catch (error) {
            
        } finally {
            setIsLoading(false);
        }
    }

    const getListOwner = (page) => {
        rolesPermissionsService
            .getWorkspaceRoles(params?.id, {
                ...pagination,
                page: page ? page : pagination.page,
                q: inputSearchOwner
            }).then((res) => {
                const formatedData = res?.data?.items?.map((item) => {
                    return {
                        name: item?.name,
                        id: item?.id,
                        value: item?.id,
                    };
                });
                setPagination({ ...pagination, total: res?.data?.total, page });
                if (page === 1) setListOwner(formatedData);
                else setListOwner([...listOwners, ...formatedData]);
            })
            .finally(() => setloadingOwner(false));
    }

    const onChangeOrder = (order) => {
        getListActivities(1, order);
    }

    useEffect(() => {
        getListOwner(1);
    }, [inputSearchOwner]);

    useEffect(() => {
        if (endDate) {
            getListActivities(1);
        }
    }, [keywordSearch, platformSelected, ownerSelected, endDate]);

    return <>
        <div className='flex mb-4 items-center'>
            <Title>{translator('Last Activity')}</Title>
        </div>
        <div className='flex gap-2 items-center flex-wrap'>
            <SearchInput onSubmit={onSearchActivities} defaultValue={keywordSearch}  />
            <div className="flex items-center p-1 gap-2">
                <SelectBox
                    label={'Platform'}
                    onSelect={setPlatformSelected}
                    selectedItems={platformSelected}
                    data={[
                        { name: translator("CLMS"), value: 'clms' },
                        { name: translator("Learner Portal"), value: "learner_portal" }
                    ]}
                    total={2}
                />
                <SelectBox
                    label={translator("User Role")}
                    onSearch={setInputSearchOwner}
                    onSelect={setOwnerSelected}
                    data={listOwners}
                    loading={loadingOwner}
                    selectedItems={ownerSelected}
                    total={pagination?.total}
                    showmore={() => getListOwner(pagination?.page + 1)}
                />
                <button className={`border-l border-gray-200 text-sm px-2 ${ ownerSelected?.length > 0 || platformSelected?.length > 0 ? 'text-primary-500' : 'text-gray-500' }`} 
                    type="button"
                    onClick={() => {
                        setOwnerSelected([]);
                        setPlatformSelected([]);
                    }}
                >
                    {translator('Reset')}
                </button>
            </div>
            <div className="xl:ml-auto">
                {checkPermission('activityDashboard', 'DL') && <div className='ml-auto flex space-x-4'>
                    <ButtonDownload title="Download .CSV" isDownload={isDownloading} disabled={data?.total == 0 || data == null} onClick={handleDownloadCSV} />
                </div>}
            </div>
        </div>
        <div className={`mt-4 w-full overflow-x-auto`}>
            {
                isLoading ?
                    <div className="flex justify-center items-center w-full min-h-[360px]">
                        <CircleSpin color="!text-primary-500" />
                    </div> :
                    data?.items?.length > 0 ?
                        <Table
                            columns={columns}
                            data={data?.items}
                        /> :
                        <div className="flex justify-center items-center w-full min-h-[360px] py-8">
                            <NoContentWithImage img={trendChart} content={translator('No Activity')} />
                        </div>
            }
        </div>
        {data?.total > 0 && !isLoading && <div className="flex items-center justify-between border-t border-ooolab_bar_color mt-4">
            <p className="text-ooolab_dark_2 text-ooolab_xs font-semibold leading-ooolab_24px hidden lg:block">
                {t('SHOWING_1_50_OF_TOTAL_PAGE', {
                    fromTo: getIndexPage(data?.page, data?.per_page, data?.items?.length),
                    totalPage: data?.total,
                })}
            </p>
            <div className="mt-4">
                <TablePagination
                    onClickPagination={handlePagination}
                    perPage={data?.per_page}
                    total={data?.total}
                    forcePage={data?.page - 1}
                />
            </div>
        </div>}
    </>
}