import h5pService from "services/h5p.service";
import libraryService from "services/library.service";
import workspaceService from "services/workspace.service";
import { LibraryTypeEnum } from "../LibraryContants";
import assignmentService from "services/assignment.service";

export const getDetail = (workspaceId, item, callback, setLoadingPreviewContent = undefined) => {
    setLoadingPreviewContent && setLoadingPreviewContent?.(true)
    switch (item?.library_content?.object_type) {
        case 'lesson':
            workspaceService
                ?.getLessonDetail(workspaceId, item?.library_content?.object_id)
                .then((res) => {
                    const format = {
                        name: res?.title,
                        created_at: res?.created_at,
                        updated_at: res?.updated_at,
                        object_type: LibraryTypeEnum.lesson,
                        created_by: res?.created_by,
                        uid: res?.uid,
                        id: item?.id,
                        object_id: item?.library_content?.object_id,
                        note: item?.note || ''
                    };
                    callback(format);
                })
                .finally(() => setLoadingPreviewContent && setLoadingPreviewContent?.(false))
            break;
        case 'h5p':
            h5pService
                .h5pEditPromise({
                    workspaceId,
                    contentId: item?.library_content?.object_id,
                })
                .then((res: any) => {
                    const format = {
                        id: item?.id,
                        library_content: {
                            id: res.id,
                            name: res?.metadata?.title,
                            created_at: res?.created_at,
                            updated_at: res?.updated_at,
                            object_type: LibraryTypeEnum.h5p,
                            created_by: res?.integration?.user,
                            uid: res?.uid,
                            object_id: item?.library_content?.object_id,
                            file_type: res?.metadata?.mainLibrary
                        },
                        note: item?.note || ''
                    };
                    callback(format);
                })
                .finally(() => setLoadingPreviewContent && setLoadingPreviewContent?.(false))
            break;
        case 'course':
            break;
        case 'folder':
            libraryService
                .getDetailsFolder(workspaceId, item?.library_content?.id)
                .then((res) => {
                    const format = {
                        ...res.data,
                        object_type: item?.library_content?.object_type,
                        object_id: item?.library_content?.object_id,
                        note: item?.note || ''
                    };
                    callback(format);
                })
                .finally(() => setLoadingPreviewContent && setLoadingPreviewContent?.(false))
            break;
        case 'submission':
            assignmentService
                .getSubmisstion(workspaceId, item?.library_content?.object_id)
                .then((res) => {
                    const format = {
                        id: item?.id,
                        library_content: {
                            ...res.data,
                            object_type: item?.library_content?.object_type,
                            object_id: item?.library_content?.object_id,
                        },
                        note: item?.note
                    };
                    callback(format);
                })
                .finally(() => setLoadingPreviewContent && setLoadingPreviewContent?.(false))
            break;

        default:
            libraryService
                .getDetailsFile(workspaceId, item?.library_content?.object_id)
                .then((res) => {
                    const format = {
                        id: item?.id,
                        library_content: {
                            ...res.data,
                            object_type: item?.library_content?.object_type,
                            object_id: item?.library_content?.object_id,
                        },
                        note: item?.note
                    };
                    callback(format);
                })
                .finally(() => setLoadingPreviewContent && setLoadingPreviewContent?.(false))
            break;
    }
};