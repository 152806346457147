import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}
import { SCHEDULE_TYPE_EMUM } from 'constant/sessions.const';
import { invalidSession } from '../util';

const iconStyle = 'w-4 h-9 text-ooolab_dark_2';

const SessionOptions: React.FC<{
    disable: boolean;
    onEdit: () => void;
    onDelete: () => void;
    sessionDetail;
}> = ({ disable, onEdit, onDelete, sessionDetail }) => {
    const { t: translator } = useTranslation();
    const isInvalidSession = invalidSession(sessionDetail)
    const MenuList = [
        {
            name: `${translator('LESSON.EDIT')}`,
            icons: <PencilIcon className={iconStyle} />,
            function: () => onEdit(),
            isDisplay: true,
            isDisable: disable || sessionDetail?.schedule_type === SCHEDULE_TYPE_EMUM.BOOKING_SCHEDULE || isInvalidSession,
        },
        {
            name: `${translator('LESSON.CANCEL')}`,
            icons: <TrashIcon className={iconStyle} />,
            function: () => onDelete(),
            isDisplay: true,
            isDisable: disable || !sessionDetail?.students?.length || isInvalidSession || (!sessionDetail?.learning_path && sessionDetail?.schedule_type === SCHEDULE_TYPE_EMUM.BOOKING_SCHEDULE),
        },
    ];

    return (
        <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
                <>
                    <div>
                        <Menu.Button
                            className={`flex justify-center items-center text-ooolab_dark_1 hover:bg-ooolab_bg_sub_tab_hover hover:text-white focus:outline-none w-6 h-6 rounded-full`}
                        >
                            <DotsVerticalIcon className="w-4 h-4" aria-hidden="true" />
                        </Menu.Button>
                    </div>

                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                        
                    >
                        <Menu.Items className="z-69 shadow-ooolab_box_shadow_container bg-white -top-[90%] origin-top-right absolute right-[90%] mt-2 w-40 rounded-header_menu divide-y divide-gray-100 focus:outline-none">
                            <div className="py-1">
                                {MenuList.map((i) => {
                                    return (
                                        <Menu.Item key={i.name}>
                                            {({}) => (
                                                <div
                                                    onClick={() => {
                                                        if (i.function && !i?.isDisable) {
                                                            i.function();
                                                        }
                                                    }}
                                                    className={`flex  px-2 w-full bg-white hover:bg-ooolab_bg_sub_tab_hover cursor-pointer ${
                                                        i.isDisplay ? '' : 'hidden'
                                                    }
                                                        ${i?.isDisable ? 'cursor-not-allowed' : ''}
                                                        `}
                                                >
                                                    {i.icons}
                                                    <a className={classNames('block px-4 py-2 text-sm')}>{i.name}</a>
                                                </div>
                                            )}
                                        </Menu.Item>
                                    );
                                })}
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
};

export default SessionOptions;
