import { ButtonOutline, ButtonPrimary, Toastify } from '_shared'
import DialogComponent from 'components/Dialog/DialogComponent'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service'

export default function ModalCancelnvoice({isOpen, invoice, onClose, refreshData}) {
  const {t: translator} = useTranslation();
  const params: {id: string} = useParams();

  const handleCancelInvoice = (invoice) => {
    workspaceService.actionInvoice(params?.id, invoice)
    .then(() => {
      Toastify.success(translator('CANCEL_INVOICE_SUCCESS', {id: invoice?.invoice_id}));
      refreshData();
      onClose();
    })
    .catch(() => Toastify.error())
  }

  return (
    <DialogComponent 
        title="Cancel Invoice"
        isOpen={isOpen}
        onCloseModal={onClose}
        child={
            <div className='mt-3'>
                <p className='text-sm'>{translator('CANCEL_INVOICE')}</p>
                <div className='mt-5 flex justify-center gap-5'>
                    <ButtonOutline type={'button'} onClick={onClose}>{translator('CANCEL')}</ButtonOutline>
                    <ButtonPrimary onClick={() => handleCancelInvoice(invoice)} className='!bg-red-500 !border-red-500' type='button'>{translator('CANCEL')}</ButtonPrimary>
                </div>
            </div>
        }
    />
  )
}
