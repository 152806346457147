import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InputCheckbox } from "_shared/components/Form/InputCheckbox/InputCheckbox";
import { SwitchToggle } from "_shared/components/Form/SwitchToggle/SwitchToggle";
import "../../CourseSetting.scss";
import { TooltipCustom } from 'components/Tooltip/Tooltip';

export const Course = ({ notAllowEdit = false, formProps, dataCourseSetting = null }) => {
    const { t } = useTranslation();

    const { control, watch, setValue, getValues } = formProps;

    useEffect(() => {
        if (watch('eLearning')) {
            setValue('enable_learning_material', true);
            setValue('enable_assignment', true);
            setValue('enable_supplementary_materials', true);
        }
    }, [watch('eLearning')])

    const isDisable_eLearning = useMemo(() => {
        if (getValues('course_status') === 'active') {
            return dataCourseSetting?.enable_assignment || dataCourseSetting?.enable_learning_material || dataCourseSetting?.enable_supplementary_materials || false;
        }
        return false;
    }, [dataCourseSetting, getValues('course_status')]);

    const isDisable_Flexible = useMemo(() => {
        if (getValues('course_status') === 'active') {
            return dataCourseSetting?.allow_flexible_learning || false;
        }
        return false;
    }, [dataCourseSetting, getValues('course_status')]);

    return (
        <>
            <div className="courseSettings-title">
                {t("COURSE")}
                <p>{t("COURSES.CUSTOMIZE_COURSE_FOR_LEARNER")}</p>
            </div>
            <div className={`courseSettings-container ${notAllowEdit ? 'opacity-container' : ''}  `}>
                <div className={`courseSettings-item ${isDisable_eLearning ? 'opacity-50' : ''}`}>
                    <div 
                        className="courseSettings-left"
                        data-for="COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS_TYPE1"
                        data-tip={t('COURSES.SETTING.COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS')}
                    >
                        <SwitchToggle
                            name="eLearning"
                            control={control}
                            disabled={notAllowEdit || isDisable_eLearning}
                            enableColor
                        />
                    </div>
                    <div className="courseSettings-right">
                        <div className="courseSettings-label">{t("COURSES.ENABLE_ELEARNING_MODE")}</div>
                        <p className="mt-2">{t("COURSES.ENABLE_ELEARNING_MODE_CONTENT")}</p>
                        <div className="mt-2">
                            <InputCheckbox
                                control={control}
                                inputName="enable_learning_material"
                                label={t("COURSES.LEARNING_MATERIAL")}
                                disabled={notAllowEdit || !watch('eLearning')}
                            />
                        </div>
                        <div className="mt-2">
                            <InputCheckbox
                                control={control}
                                inputName="enable_assignment"
                                label={t("COURSES.ASSIGNMENT")}
                                disabled={notAllowEdit || !watch('eLearning')}
                            />
                        </div>
                        <div className="mt-2">
                            <InputCheckbox
                                control={control}
                                inputName="enable_supplementary_materials"
                                label={t("COURSES.SUPPLEMENTARY_MATERIALS")}
                                disabled={notAllowEdit || !watch('eLearning')}
                            />
                        </div>
                    </div>
                </div>
                <div className="courseSettings-item flex-col">
                    <div className="flex gap-2">
                        <div 
                            className="courseSettings-left"
                            data-for="COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS_TYPE2"
                            data-tip={t('COURSES.SETTING.COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS')}
                        >
                            <SwitchToggle
                                name="allow_flexible_preview"
                                control={control}
                                disabled={notAllowEdit}
                                enableColor
                            />
                        </div>
                        <div className="courseSettings-right">
                            <div className="courseSettings-label">{t("COURSES.ALLOW_LESSON_PREVIEW")}</div>
                        </div>
                    </div>
                    <p>{t("COURSES.ALLOW_LESSON_PREVIEW_CONTENT")}</p>
                </div>
                <div className={`courseSettings-item flex-col ${isDisable_Flexible ? 'opacity-50' : ''}`}>
                    <div className="flex gap-2">
                        <div 
                            className="courseSettings-left"
                            data-for="COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS_TYPE3"
                            data-tip={t('COURSES.SETTING.COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS')}
                        >
                            <SwitchToggle
                                name="allow_flexible_learning"
                                control={control}
                                disabled={notAllowEdit || isDisable_Flexible}
                                enableColor
                            />
                        </div>
                        <div className="courseSettings-right">
                            <div className="courseSettings-label">{t("COURSES.ALLOW_FLEXIBLE_LEARNING")}</div>
                        </div>
                    </div>
                    <p>{t("COURSES.ALLOW_FLEXIBLE_LEARNING_CONTENT")}</p>
                </div>
            </div>
            {isDisable_eLearning && (
                <TooltipCustom
                    classNameContainer="shadow-medium"
                    id="COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS_TYPE1"
                    content={t('COURSES.SETTING.COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS')}
                    place="top"
                    type="dark"
                />
            )}
            {notAllowEdit && (
                <TooltipCustom
                    classNameContainer="shadow-medium"
                    id="COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS_TYPE2"
                    content={t('COURSES.SETTING.COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS')}
                    place="top"
                    type="dark"
                />
            )}
            {isDisable_Flexible && (
                <TooltipCustom
                    classNameContainer="shadow-medium"
                    id="COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS_TYPE3"
                    content={t('COURSES.SETTING.COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS')}
                    place="top"
                    type="dark"
                />
            )}
        </>
    )
}