export const SESSION_TYPE = {
    online: 'Online Class',
    offline: 'Offline Class',
    assessment: 'Assessment',
    event: 'Event',
};
export const SCHEDULE_TYPE = (translator: (key: string) => string) => [
    { label: translator('COMPLETED'), name: translator('COMPLETED'), value: 'completed' },
    { label: translator('CANCELLED'), name: translator('CANCELLED'), value: 'cancelled' },
    { label: translator('RESCHEDULED'), name: translator('RESCHEDULED'), value: 'rescheduled' },
];

export const SESSION_STATUS = (translator: (key: string) => string) => [
    { label: translator('COMPLETED'), value: 'completed', background: 'bg-green-500' },
    { label: translator('UPCOMING'), value: 'not_started', background: 'bg-purple-500' },
    { label: translator('ON_GOING'), value: 'in_progress', background: 'bg-blue-500' },
    { label: translator('RESCHEDULE'), value: 'rescheduled', background: 'bg-orange-500' },
    { label: translator('DEACTIVATED'), value: 'deactivated', background: 'bg-red-500' },
    { label: translator('CANCELLED'), value: 'cancelled', background: 'bg-gray-500' },
];


export const SESSION_STATUS_ENUM = {
    COMPLETED: 'completed',
    NOT_STARTED: 'not_started',
    IN_PROGRESS: 'in_progress',
    RESCHEDULED: 'rescheduled',
    DEACTIVATED: 'deactivated',
    CANCELLED: 'cancelled'
}

export const SCHEDULE_TYPE_EMUM = {
    CLASS_SCHEDULE: 'class_schedule',
    MANUAL_SCHEDULE: 'manual_schedule',
    BOOKING_SCHEDULE: 'booking_schedule'
}
