import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import workspaceMiddleware from 'middleware/workspace.middleware';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useParams, useHistory, useLocation  } from 'react-router-dom';
import { LessonSection } from 'types/GetListOfWorkspace.type';
import { ChevronDownIcon, ChevronLeftIcon } from '@heroicons/react/outline';
import { CommentIcon, NextIcon, PreviousIcon } from 'assets/icon';
import { Popover } from '@headlessui/react';
import { ListIcon, ZoomOutIcon, ZoomInIcon } from 'assets/icon';
import ModalShareLink from 'components/V2/Modals/ModalShareLink';
import Spinner from 'components/Spinner';
import PencilIcon from 'assets/icon/PencilIcon';
import CopyLinkIcon from 'assets/icon/CopyLinkIcon';
import { useTranslation } from 'react-i18next';
import './FilesPreview.scss';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import PreviewContentCommon from '_shared/PreviewContent/PreviewContentCommon';
import { VIEW_TYPE } from 'components/Library/LessonBuilder/constants';
import NotePopup from './NotePopup';
import assignmentService from 'services/assignment.service';
import { Book3FillIcon } from 'assets/icon';
import lessonIcon from 'assets/img/lessonIcon.png';
import assignmentIcon from 'assets/img/assignmentIcon.png';
import { activityWatcher, checkPermission, destroyDisabledCopyPast, disabledCopyPast, exitFullScreen } from 'utils/commonFun';
import { CloseIcon } from 'assets/icon/CloseIcon';
import { clsx } from "clsx";
import ReactTooltip from "react-tooltip";
import { ButtonIcon } from '_shared';
interface Params {
    id: string;
    lessonId: string
}
const doAssigmentBg = require(`assets/themes/${process.env.REACT_APP_WHITELABEL}/do-assigment-bg.png`);
let intervalRemoveIconZoom;

const FilePreview = ({ values, onClose }: { values?: { object_id: string }, onClose?: () => void }) => {
    const paramsURL: Params = useParams();
    const [params, setParams] = useState<Params>();
    const { dispatch, getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const [showLoading, setShowLoading] = useState(true);
    const [isZoom, setZoom] = useState(false);
    const [openModalShareLink, setOpenModalShareLink] = useState(false);
    const [isOpenNote, setIsOpenNote] = useState<boolean>(false);
    const [currentFile, setCurrentFile] = useState<any>();
    const history = useHistory();
    const [data, setData] = useState<any>({});
    const [normalizeData, setNormalizeData] = useState<any[]>([]);
    const location = useLocation();
    const paramsLocation = new URLSearchParams(location?.search);
    const isCloseBack = paramsLocation?.get('isCloseBack') === 'true';

    const getIndexFile = (id) => normalizeData?.findIndex((item) => item?.id === id);

    const getMaterial = (key) => {
        const data = getWorkspaceDetailState.currentLesson?.sections?.[key]?.map((section: LessonSection) => {
            return {
                sectionName: section?.title,
                sectionContents: section?.section_contents,
            };
        });
        return data;
    };

    const getData = async () => {
        const learningMaterial = getMaterial('learning_material');
        const supplementaryMaterial = getMaterial('supplementary_material');
        let assignment = [];
        if (getWorkspaceDetailState.currentLesson?.sections?.assignment?.[0]?.section_contents?.length) {
            await Promise.all(
                getWorkspaceDetailState.currentLesson?.sections?.assignment?.[0]?.section_contents?.map(async (section) => {
                    const sectionContent = await assignmentService.getAssignment({
                        workspaceId: params?.id,
                        assignmentId: section?.library_content?.object_id,
                    });
                    return {
                        sectionName: section?.library_content?.name,
                        sectionContents: sectionContent?.data?.sections,
                    };
                })
            ).then((res) => {
                assignment = res;
            });
        }
        setData({ learningMaterial, assignment, supplementaryMaterial });

        const listContent = [];
        learningMaterial?.forEach((section) => section?.sectionContents?.forEach((file) => listContent.push(file)));
        assignment?.map((section) => section?.sectionContents?.forEach((file) => listContent.push(file)));
        supplementaryMaterial?.[0]?.sectionContents?.forEach((file) => listContent.push(file));
        setNormalizeData(listContent);
        setCurrentFile(listContent[0]);
        setShowLoading(false);
    };

    useEffect(() => {
        if (values || paramsURL) {
            setParams({
                id: paramsURL.id,
                lessonId: values?.object_id ?? paramsURL?.lessonId
            })
        }
    }, [paramsURL, values]);

    useEffect(() => {
        if (getWorkspaceDetailState.currentLesson?.sections && params?.id) {
            getData();
        }
    }, [getWorkspaceDetailState.currentLesson?.sections]);

    useEffect(() => {
        if (params?.lessonId) {
            try {
                workspaceMiddleware.getLessonDetail(dispatch, params.id, params.lessonId);
            } catch { }
        }
    }, [params?.lessonId]);

    const beforeChange = (next: number) => {
        setIsOpenNote(false);
        if (currentFile?.id !== normalizeData[next]?.id) {
            setCurrentFile(normalizeData[next]);
        }
    };

    const nextSlide = () => {
        setCurrentFile(null);
        setTimeout(() => {
            const index = getIndexFile(currentFile?.id);
            beforeChange(index + 1);
            setCurrentFile(normalizeData[index + 1]);
        }, 100)
    };

    const preSlide = () => {
        const index = getIndexFile(currentFile?.id);
        getIndexFile(currentFile?.id);
        beforeChange(index - 1);
        setCurrentFile(null);
        setTimeout(() => {
            setCurrentFile(normalizeData[index - 1]);
        }, 100)
    };

    useEffect(() => {
        if (currentFile?.library_content?.object_type != 'h5p') {
            return;
        };
        setShowLoading(true);
        clearInterval(intervalRemoveIconZoom);
        intervalRemoveIconZoom = setInterval(() => {
            const iframe = document?.getElementsByClassName('h5p-iframe')[0];
            if (iframe) {
                //@ts-ignore
                const zoom = iframe?.contentWindow?.document?.getElementsByClassName('h5p-footer-toggle-full-screen')?.[0];
                if (zoom) {
                    zoom.style.visibility = 'hidden';
                }
                setShowLoading(false);
                clearInterval(intervalRemoveIconZoom);
            }
            const index = getIndexFile(currentFile?.id);
            if (!normalizeData?.length || normalizeData[index]?.library_content?.object_type !== 'h5p') {
                setShowLoading(false);
                clearInterval(intervalRemoveIconZoom);
            }
        }, 1000);
    }, [isZoom, currentFile]);

    useEffect(() => {
        const keydown = (event) => {
            if (event.code === 'ArrowLeft' && currentFile?.id !== normalizeData?.[0]?.id) preSlide();
            if (event.code === 'ArrowRight' && currentFile?.id !== normalizeData?.slice(-1)?.[0]?.id) nextSlide();
        };
        window.addEventListener('keydown', keydown);
        return () => {
            window?.removeEventListener('keydown', keydown);
        };
    }, [currentFile]);

    useEffect(() => {
        activityWatcher(function () {
            history.goBack();
        });
        disabledCopyPast();

        return () => {
            destroyDisabledCopyPast();
        };
    }, []);

    exitFullScreen(() => setZoom(false));

    const openFullScreen = () => {
        const elem = document.getElementById('presentation-mode-lesson');
        if (elem) {
            elem.requestFullscreen();
            //@ts-ignore
        } else if (elem.webkitRequestFullscreen) {
            /* Safari */
            //@ts-ignore
            elem.webkitRequestFullscreen();
            //@ts-ignore
        } else if (elem.msRequestFullscreen) {
            /* IE11 */
            //@ts-ignore
            elem.msRequestFullscreen();
        }
    };

    /* Close fullscreen */
    const closeFullscreen = () => {
        if (document?.exitFullscreen) {
            document?.exitFullscreen();
            //@ts-ignore
        } else if (document?.webkitExitFullscreen) {
            /* Safari */
            //@ts-ignore
            document?.webkitExitFullscreen();
            //@ts-ignore
        } else if (document?.msExitFullscreen) {
            /* IE11 */
            //@ts-ignore
            document?.msExitFullscreen();
        }
    };

    const { t } = useTranslation();

    const renderSection = (key, icon) => {
        const contents = data?.[key]?.filter(section => section?.sectionContents?.length > 0);
        return contents?.map((section, index) => {
            return (
                <div key={index}>
                    <div className="lesson">
                        <div className="flex items-center">
                            {icon}
                            {key === 'supplementaryMaterial' ? 'Supplementary Material' : section?.sectionName}
                        </div>
                    </div>
                    {section?.sectionContents?.map((file: any) => {
                        return (
                            <div
                                key={`${file?.uid}`}
                                onClick={() => {
                                    setCurrentFile(null);
                                    setTimeout(() => {
                                        setCurrentFile(file);
                                    });
                                }}
                                className={`lesson-item ${getIndexFile(file?.id) !== 0 ? 'm-0' : ''} ${currentFile?.id === file?.id ? 'active' : ''
                                    } `}
                            >
                                <span
                                    className="name"
                                    data-for="file-name"
                                    data-tip={currentFile?.library_content?.name}
                                >
                                    {file?.library_content?.name}
                                </span>
                                {/*@ts-ignore*/
                                    <ReactTooltip
                                        place="top"
                                        type="dark"
                                        effect="float"
                                        id="file-name"
                                        getContent={(value) => <div>{value}</div>}
                                    />}
                                {file?.note && (
                                    <span className="text-gray-500">
                                        <CommentIcon width={20} height={20} />
                                    </span>
                                )}
                            </div>
                        );
                    })}
                </div>
            );
        });
    };
    if (!getWorkspaceDetailState?.currentLesson) {
        return (
            <div className="flex h-screen items-center justify-center">
                <Spinner />
            </div>
        )
    }

    const onGoback = () => {
        const folderId = getWorkspaceDetailState?.currentLesson?.path?.slice(-1)?.[0]?.id;
        if (folderId) {
            history.push(`/workspace/${params?.id}/library/${folderId}`)
        } else {
            history.push(`/workspace/${params?.id}/library?tab=library`)
        }
    }

    return (
        <div
            id="presentation-mode-lesson"
            className={clsx("presentation-mode-lesson",
                { "presentation-mode-lesson--modal": values }
            )}
            style={{ backgroundImage: `url(" ${doAssigmentBg}")` }}
        >
            <div className={`h-12 ${isZoom ? 'group' : ''}`}>
                <div className={`${isZoom ? 'hidden' : 'flex'} header-container group-hover:flex`}>
                    {!values && !isCloseBack ? <div className="header-icon" onClick={onGoback}>
                        <div className="background-icon">
                            <ChevronLeftIcon className="icon" />
                        </div>
                        {t('DASHBOARD.SIDEBAR.LIBRARY')}
                    </div> : <div></div>}
                    <div className={clsx("header-title")}>
                        {getWorkspaceDetailState.currentLesson?.title}
                    </div>
                    {
                        !values && getWorkspaceDetailState.currentLesson?.type != "scorm" ? <div className="header-group-icon">
                            {checkPermission('libraryContent', 'U') && <div className="sub-icon" onClick={() => history.push(`/workspace/${params.id}/lesson/${params.lessonId}`)}>
                                <PencilIcon />
                            </div>}
                            {checkPermission('libraryContent', 'SR') && <div
                                className="sub-icon"
                                onClick={() => {
                                    setOpenModalShareLink(true);
                                    closeFullscreen();
                                    setZoom(false);
                                }}
                            >
                                <CopyLinkIcon />
                            </div>}

                        </div> :
                            values ? <div>
                                <ButtonIcon onClick={onClose}>
                                    <span className='p-2'>
                                        <CloseIcon />
                                    </span>
                                </ButtonIcon>
                            </div> : <div></div>
                    }
                </div>
            </div>
            <div className={`main-content ${getWorkspaceDetailState.currentLesson?.type == "scorm" ? 'scorm' : null} ${isZoom ? 'zoom' : ''}`}>
                {getWorkspaceDetailState.currentLesson?.type == "scorm" ?
                    <>
                        {
                            getWorkspaceDetailState.currentLesson?.cloud_link ?
                                <iframe src={getWorkspaceDetailState.currentLesson?.cloud_link} className="scorm-view" /> :
                                <div className="flex items-center justify-center min-h-[calc(100vh-52px)]">
                                    <NoContentWithImage />
                                </div>
                        }
                    </> :
                    <div key={`iframe-${isZoom}`} className={`file-container ${isZoom ? 'zoom' : ''} `}>
                        {showLoading && (
                            <div className="file-container-loading">
                                <Spinner />
                            </div>
                        )}
                        {!showLoading && !normalizeData?.length && (
                            <div className="flex items-center">
                                <NoContentWithImage />
                            </div>
                        )}
                        {currentFile?.id ? (
                            <PreviewContentCommon
                                isNewPage={true}
                                key={`previewContent-${params?.lessonId}`}
                                contentSelected={currentFile}
                                viewType={VIEW_TYPE.WEB}
                                colorProcess="text-gray-800"
                            />
                        ) : null}
                    </div>}
            </div>

            {getWorkspaceDetailState.currentLesson?.type == "scorm" ? null :
                <div className={`navigation ${isZoom ? 'group z-100' : ''}`}>
                    <div className={`${isZoom ? 'hidden' : 'flex'} navigation-container group-hover:flex`}>
                        <Popover className="relative">
                            <Popover.Button data-for="file-selected-name"
                                data-tip={currentFile?.library_content?.name} className="navigation-dropdown">
                                <ListIcon />
                                <div className="navigation-dropdown-button">
                                    <span
                                        className="file-name"

                                    >
                                        {currentFile?.library_content?.name}
                                    </span>{' '}
                                    {/*@ts-ignore*/
                                        <ReactTooltip
                                            place="top"
                                            type="dark"
                                            effect="float"
                                            id="file-selected-name"
                                            getContent={(value) => <div>{value}</div>}
                                        />}
                                    <ChevronDownIcon className="icon" />
                                </div>
                            </Popover.Button>
                            <Popover.Panel className="navigation-panel">
                                {renderSection('learningMaterial', <img className="w-5 h-5 mr-1" src={lessonIcon} />)}
                                {renderSection('assignment', <img className="w-5 h-5 mr-1" src={assignmentIcon} />)}
                                {renderSection(
                                    'supplementaryMaterial',
                                    <span className="text-cyan-500 mr-1">
                                        <Book3FillIcon width={20} height={20} />
                                    </span>
                                )}
                            </Popover.Panel>
                        </Popover>
                        <div className="navigation-button">
                            <div className={`${getIndexFile(currentFile?.id) > 0 ? 'active' : ''}`} onClick={preSlide}>
                                <PreviousIcon />
                            </div>
                            <div className="line"></div>
                            <div id="counter-slide" className="index-page">
                                {normalizeData?.length > 0 ? getIndexFile(currentFile?.id) + 1 : 0}
                            </div>
                            /
                            <div id="total-slide" className="total-slide">
                                {normalizeData?.length}
                            </div>
                            <div className="line"></div>
                            <div
                                className={` ${getIndexFile(currentFile?.id) < normalizeData?.length - 1 ? 'active' : ''}`}
                                onClick={nextSlide}
                            >
                                <NextIcon />
                            </div>
                        </div>
                        <div
                            id="zoom-presentation"
                            onClick={() => {
                                isZoom ? closeFullscreen() : openFullScreen();
                                setZoom(!isZoom);
                            }}
                            className="button-zoom"
                        >
                            {isZoom ? <ZoomOutIcon /> : <ZoomInIcon />}
                        </div>
                        <div
                            id="note-presentation"
                            onClick={() => {
                                setIsOpenNote(true);
                            }}
                            className="button-zoom"
                        >
                            <CommentIcon width={20} height={20} />
                        </div>
                    </div>
                </div>
            }
            {openModalShareLink && (
                <div className="w-full h-full">
                    <ModalShareLink
                        isOpen={openModalShareLink}
                        onClose={() => setOpenModalShareLink(false)}
                        value={{
                            ...getWorkspaceDetailState.currentLesson,
                            object_type: 'lesson',
                            workspaceId: params?.id,
                            object_id: params.lessonId,
                        }}
                    />
                </div>
            )}
            {isOpenNote ? (
                <NotePopup
                    isOpen={isOpenNote}
                    onCloseModal={() => {
                        setIsOpenNote(false);
                    }}
                    noteData={currentFile?.note || ''}
                    isZoom={isZoom}
                />
            ) : (
                ''
            )}
        </div>
    );
};

export default FilePreview;
