import { useTranslation } from "react-i18next";
import './SettingsButon.scss';
import { TagIcon } from "assets/icon/TagIcon";

export const SettingButon = ({onClick}) => {
    const {t: translate} = useTranslation();
    return <>
        <button className="settingsButon" onClick={() => onClick(true)}>
            <TagIcon />
            {translate('CUSTOM_TAGS')}
        </button>
    </>
}