import React from 'react';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import { Link, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import EmptyIcon from 'assets/img/empty-state/user.png';
import ActionMenu from './Action';

interface RolesPermissionsTableProps {
    workspaceId: string;
    dataTable: any;
    translator: Function;
    loading?: boolean;
    refreshData?: any;
    location?: string
}

const RolesPermissionsTableTable: React.FC<RolesPermissionsTableProps> = ({ workspaceId, dataTable, translator, refreshData, location }) => {
    const history = useHistory()
    const columns = React.useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('ROLES_PERMISSIONS.ROLE_NAME')} />,
                Cell: ({ row }) => {
                    return (
                        <div className="flex items-center p-2">
                            <p
                                className="text-ooolab_xs text-primary-500 font-semibold cursor-pointer truncate"
                                data-tip={row.original.name}
                                data-for={row.original?.id.toString() + 'display_name'}
                                onClick={() => history.push(`/workspace/${workspaceId}/setting?tab=role&tabPermission=overview`, {
                                    roleId: row?.original?.id
                                })}
                            >
                                {row.values.name}
                            </p>
                            {/* @ts-ignore */}
                            <ReactTooltip
                                place="bottom"
                                type="dark"
                                effect="float"
                                id={row.original?.id.toString() + 'display_name'}
                                getContent={(content) => <div className="w-56 h-auto">{content}</div>}
                                disable={row.original?.name?.length < 18}
                            />
                        </div>
                    );
                },
                accessor: 'name',
                width: 140,
            },
            {
                Header: () => <RenderTableHeader value={translator('ROLES_PERMISSIONS.DESCRIPTION')} />,
                Cell: ({ row }) => {
                    return <RenderTableCell value={row?.values?.description || '-'} />;
                },
                accessor: 'description',
                width: 160,
            },
            {
                Header: () => <RenderTableHeader value={translator('ROLES_PERMISSIONS.TYPE')} />,
                Cell: ({ row }) => {
                    return <RenderTableCell className="capitalize" value={translator(row?.values?.role_type)} />;
                },
                accessor: 'role_type',
                width: 60,
            },
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="CourseAction">
                            <ActionMenu data={row?.original} refreshData={refreshData} />
                        </div>
                    );
                },
                accessor: 'action',
                width: 20,
            },
        ],
        []
    );

    return (
        <>
            <Table data={dataTable} columns={columns} emptyIcon={EmptyIcon} emptyContent={'No Users'} />
        </>
    );
};

export default RolesPermissionsTableTable;
