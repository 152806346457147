import { useState, useEffect, useCallback } from 'react';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import debounce from 'lodash/debounce';
import './SessionFilter.scss';
import { useParams } from 'react-router-dom';
import { ATTENDANCE_TYPE, SESSION_STATUS } from 'constant/course.const';
import { ClassService, WorkspaceService } from 'services';
import { useTranslation } from 'react-i18next';
import { checkPermission } from 'utils/commonFun';

interface IFilter {
    onChangeFilter: (value: any) => void;
    isFirstLoad: boolean
}

export const SessionFilter = ({
    onChangeFilter,
    isFirstLoad,
}: IFilter) => {
    const params: { id: string, courseId: string } = useParams();
    const [keyword, setKeyword] = useDebouncedState('', 0);
    const [listInstructor, setListInstructor] = useState([]);
    const [listCourse, setListCourse] = useState([]);
    const {t} = useTranslation();
    const [inputSearchInstructor, setInputSearchInstructor] = useDebouncedState('');
    const [inputSearchCourse, setInputSearchCourse] = useDebouncedState('');

    const [instuctorSelected, setInstuctorSelected] = useState([]);
    const [courseSelected, setCourseSelected] = useState([]);
    const [attendanceType, setAttendanceType] = useState<any>([]);
    const [sessionStatus, setSessionStatus] = useState<any>();

    const [paginationInstructor, setPaginationInstructor] = useState<any>({
        page: 1,
    });
    const [paginationCourse, setPaginationCourse] = useState<any>({
        page: 1,
    });

    const updateFilter = useCallback(
        debounce((listFilter) => onChangeFilter(listFilter), 1500),
        []
    );

    const getInstructor = async (page) => {
        let payload = {
            page: page ? page : paginationInstructor.page,
            per_page: 10,
            q: inputSearchInstructor,
            order: 'asc',
        }
        if (!payload?.q) delete payload.q;
        const response = await ClassService.getTeacherListService(params.id, payload);
        if (response) {
            const formatedData = response?.data?.items?.map((item) => {
                return {
                    name: item?.user?.display_name,
                    id: item?.user?.id,
                    value: item?.user?.id,
                };
            });
            if (page === 1) setListInstructor(formatedData);
            else setListInstructor([...listInstructor, ...formatedData]);
            setPaginationInstructor({ ...paginationInstructor, total: response?.data?.total, page });
        }
    }

    const getCourse = async (page) => {
        let payload = {
            title: inputSearchCourse,
            sort_by: 'created_at',
            page: page ? page : paginationCourse.page,
            per_page: 10,
            order: 'desc'
        }
        if (!payload?.title) delete payload.title;
        //@ts-ignore
        const response = await WorkspaceService.getCoursesList(params?.id, payload)
        if (response) {
            const formatedData = response?.items?.map((item) => {
                return {
                    name: item?.title,
                    id: item?.id,
                    value: item?.id,
                };
            });
            if (page === 1) setListCourse(formatedData);
            else setListCourse([...listCourse, ...formatedData]);
            setPaginationCourse({ ...paginationCourse, total: response?.total, page });
        }
    }

    useEffect(() => {
        if (!isFirstLoad) {
            let listFilter: any = {
                q: keyword,
                session_status: !!sessionStatus?.length ? sessionStatus?.map(i => i?.value) : null,
                instructor_id: instuctorSelected ? instuctorSelected[0]?.value : '',
                course_id: courseSelected ? courseSelected[0]?.value : '',
                session_attendance: !!attendanceType?.length ? attendanceType?.map((i) => i?.value) : null,
            };

            if (!listFilter?.session_status) delete listFilter?.session_status;
            if (!listFilter?.session_attendance) delete listFilter?.session_attendance;
            if (!listFilter?.instructor_id) delete listFilter?.instructor_id;
            if (!listFilter?.course_id) delete listFilter?.course_id

            updateFilter(listFilter);
        }
    }, [keyword, sessionStatus, instuctorSelected, courseSelected, attendanceType]);

    useEffect(() => {
        getInstructor(1);
    }, [inputSearchInstructor]);

    useEffect(() => {
        if(checkPermission('course', 'R') ) {
            getCourse(1);
        }
    }, [inputSearchCourse]);

    return (
        <>
            <div className="sessionFilter -mx-5">
                <div className='max-w-[234px]'>
                    <SearchBox placeholder={t("SESSION.SEARCH_SESSION_ID")} className="sessionFilter-searchInput" onSubmit={(value) => setKeyword(value)} />
                </div>

                {checkPermission('course', 'R') && <SelectBox
                    label={t('COURSE')}
                    isMulti={false}
                    onSearch={setInputSearchCourse}
                    onSelect={setCourseSelected}
                    data={listCourse}
                    selectedItems={courseSelected}
                    total={paginationCourse?.total}
                    showmore={() => getCourse(paginationCourse?.page + 1)}
                />}


                <SelectBox
                    label={t('INSTRUCTOR')}
                    isMulti={false}
                    onSearch={setInputSearchInstructor}
                    onSelect={setInstuctorSelected}
                    data={listInstructor}
                    selectedItems={instuctorSelected}
                    total={paginationInstructor?.total}
                    showmore={() => getInstructor(paginationInstructor?.page + 1)}
                />

                <SelectBox
                    label={t('STATUS')}
                    isMulti
                    onSelect={setSessionStatus}
                    data={SESSION_STATUS(t)}
                    selectedItems={sessionStatus}
                    total={Object.keys(SESSION_STATUS(t))?.length}
                />

                <SelectBox
                    label={t('SESSION.ATTENDANCE')}
                    isMulti
                    onSelect={setAttendanceType}
                    data={ATTENDANCE_TYPE(t)}
                    selectedItems={attendanceType}
                    total={Object.keys(ATTENDANCE_TYPE(t))?.length}
                />
            </div>
        </>
    );
};
