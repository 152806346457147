import axios from './restful.service';
import { CLASS, TEACHER_DASHBOARD } from 'constant/api.const';

const getClassesMembersService = async (workspaceId: string, classId: string, sessionId: string, params?: any) => {
    if (workspaceId) {
        const url = CLASS.getClassMembersUrl(workspaceId, classId, sessionId);
        return axios.get(url, { params });
    }
};

const getListTodayService = (workspaceId: string, params?: any) => {
    if (workspaceId) {
        const url = TEACHER_DASHBOARD.getListTodayUrl(workspaceId);
        return axios.get(url, { params });
    }
};

const getSessionDetailService = (workspaceId: string, classId: string, sessionId: string) => {
    if (workspaceId) {
        const url = TEACHER_DASHBOARD.getSessionDetail(workspaceId, classId, sessionId);
        return axios.get(url);
    }
};

const getListUpcomingService = (workspaceId: string, params?: any) => {
    if (workspaceId) {
        const url = TEACHER_DASHBOARD.getListUpcomingUrl(workspaceId);
        return axios.get(url, { params });
    }
};

const getListPastService = (workspaceId: string, params?: any) => {
    if (workspaceId) {
        const url = TEACHER_DASHBOARD.getListPastUrl(workspaceId);
        return axios.get(url, { params });
    }
};

const getListAttendanceService = ({ workspaceId, sessionId, classId }) => {
    if (workspaceId && sessionId && classId) {
        const url = TEACHER_DASHBOARD.getListAttendanceUrl(workspaceId, classId, sessionId);
        return axios.get(url);
    }
};

const updateListAttendanceService = ({ workspaceId, sessionId, classId }, data: any) => {
    if (workspaceId && sessionId && classId) {
        const url = TEACHER_DASHBOARD.updateListAttendanceUrl(workspaceId, classId, sessionId);
        return axios.post(url, data);
    }
};

const getVcLinkService = ({ workspaceId, sessionId, classId }) => {
    if (workspaceId && sessionId && classId) {
        const url = TEACHER_DASHBOARD.getVcLinkUrl(workspaceId, classId, sessionId);
        return axios.get(url);
    }
};

const getVcRecordService = ({ workspaceId, sessionId, classId }) => {
    if (workspaceId && sessionId && classId) {
        const url = TEACHER_DASHBOARD.getVcRecord(workspaceId, classId, sessionId);
        return axios.get(url);
    }
};

const getAvailableSlotsService = (workspaceId: string, params?: any) => {
    if (workspaceId) {
        const url = TEACHER_DASHBOARD.getAvailableSlotsUrl(workspaceId);
        return axios.get(url, { params });
    }
};

const createAvailableSlotService = (workspaceId: string, learningPathId?: string, params?: any) => {
    if (workspaceId && learningPathId) {
        const url = TEACHER_DASHBOARD.createAvailableSlotUrl(workspaceId, learningPathId);
        return axios.post(url, params);
    }
};

const updateAvailableSlotService = (workspaceId: string, learningPathId?: string, availableSlotId?: string, params?: any) => {
    if (workspaceId && learningPathId && availableSlotId) {
        const url = TEACHER_DASHBOARD.updateAvailableSlotUrl(workspaceId, learningPathId, availableSlotId);
        return axios.put(url, params);
    }
};

const updateStatusAvailableSlotService = (workspaceId: string, learningPathId?: string, availableSlotId?: string, params?: any) => {
    if (workspaceId && learningPathId && availableSlotId) {
        const url = TEACHER_DASHBOARD.updateStatusAvailableSlotUrl(workspaceId, learningPathId, availableSlotId);
        return axios.patch(url, params);
    }
};

const getLearningPathSessionsService = (workspaceId: string, params?: any) => {
    if (workspaceId) {
        const url = TEACHER_DASHBOARD.getLearningPathSessionsUrl(workspaceId);
        return axios.get(url, { params });
    }
};

const getTeacherDetail = async (workspaceId: string, teacherId: any, params?: any) => {
    if (workspaceId) {
        const url = CLASS.getTeacherDetail(workspaceId, teacherId);
        return axios.get(url, { params });
    }
};

const createOneTimeEvent = async (workspaceId: string, teacherId: any, params: any) => {
    if (workspaceId) {
        const url = TEACHER_DASHBOARD.createOneTimeEvent(workspaceId, teacherId);
        return axios.post(url, params);
    }
};

const getTeacherEvents = async (workspaceId: string, teacherId: any, params: any) => {
    if (workspaceId && teacherId) {
        const url = TEACHER_DASHBOARD.getInstructorTimeEvents(workspaceId, teacherId);
        return axios.post(url, params);
    }
};

const getLanguagesTeacher = async (workspaceId: string, teacherId: any) => {
    if (workspaceId) {
        const url = TEACHER_DASHBOARD.getLanguagesTeacher(workspaceId, teacherId);
        return axios.get(url);
    }
};

const removeEventTeacher = async (workspaceId: string, teacherId: any, eventId: string, body: any) => {
    if (workspaceId) {
        const url = TEACHER_DASHBOARD.removeEventTeacher(workspaceId, teacherId, eventId);
        return axios.post(url, body);
    }
};

const getIntructorSessions = (workspaceId: string, params?: any) => {
    if (workspaceId) {
        const url = TEACHER_DASHBOARD.getIntructorSessions(workspaceId);
        return axios.get(url, { params });
    }
};

const getInstructorDashboardBanner = (workspaceId: string, params?: any) => {
    const url = TEACHER_DASHBOARD.getInstructorDashboardBanner(workspaceId);
    return axios.get(url, { params });
}

const getNotifications = (workspaceId: string, params?: any) => {
    const url = TEACHER_DASHBOARD.getNotifications(workspaceId);
    return axios.get(url, { params });
}

const getNotificationDetail = (workspaceId: string, notificationId?: any) => {
    const url = TEACHER_DASHBOARD.getNotificationDetail(workspaceId, notificationId);
    return axios.get(url);
}

const clickNotification = async (workspaceId: string, notificationId?: any) => {
    if (workspaceId) {
        const url = TEACHER_DASHBOARD.clickNotification(workspaceId, notificationId);
        return axios.post(url);
    }
};

const readAllNotification = async (workspaceId: string) => {
    if (workspaceId) {
        const url = TEACHER_DASHBOARD.readAllNotification(workspaceId);
        return axios.post(url);
    }
};

const getStisticNotification = async (workspaceId: string) => {
    if (workspaceId) {
        const url = TEACHER_DASHBOARD.getStisticNotification(workspaceId);
        return axios.get(url);
    }
};

export default {
    getClassesMembersService,
    getListTodayService,
    getListUpcomingService,
    getListPastService,
    getListAttendanceService,
    updateListAttendanceService,
    getVcLinkService,
    getAvailableSlotsService,
    createAvailableSlotService,
    updateAvailableSlotService,
    updateStatusAvailableSlotService,
    getLearningPathSessionsService,
    getSessionDetailService,
    getTeacherDetail,
    createOneTimeEvent,
    getTeacherEvents,
    getLanguagesTeacher,
    removeEventTeacher,
    getVcRecordService,
    getIntructorSessions,
    getInstructorDashboardBanner,
    getNotifications,
    getNotificationDetail,
    clickNotification,
    getStisticNotification,
    readAllNotification
};
