export const SET_AUTH = {
    REQ_AUTH: 'REQ_AUTH',

    LOGIN_WITH_DEFAULT_PASSWORD: 'LOGIN_WITH_DEFAULT_PASSWORD',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',

    REMOVE_DEFAULT_PASSWORD_MODALS: 'REMOVE_DEFAULT_PASSWORD_MODALS',

    LOGOUT: 'LOGOUT',
    RESET_LOGIN_STATE: 'RESET_LOGIN_STATE',
};

export const SET_AUTH_LOCAL_STORAGE = {
    REQ_GET_AUTH_LOCAL_STORAGE: 'REQ_GET_AUTH_LOCAL_STORAGE',
    REQ_REMOVE_AUTH_LOCAL_STORAGE: 'REQ_REMOVE_AUTH_LOCAL_STORAGE',
};

export const SET_REGISTER = {
    // Create temporary user
    REQ_REGISTER: 'REQ_REGISTER',
    REQ_REGISTER_TEMPORARY_USER_SUCCESS: 'REQ_REGISTER_TEMPORARY_USER_SUCCESS',
    REQ_REGISTER_TEMPORARY_USER_FAIL: 'REQ_REGISTER_TEMPORARY_USER_FAIL',

    REQ_REGISTER_GET_USER_SUCCESS: 'REQ_REGISTER_GET_USER_SUCCESS',
    REQ_REGISTER_GET_USER_FAIL: 'REQ_REGISTER_GET_USER_FAIL',

    // Verify email
    REQ_REGISTER_VERIFY_EMAIL_SUCCESS: 'REQ_REGISTER_VERIFY_EMAIL_SUCCESS',
    REQ_REGISTER_VERIFY_EMAIL_FAIL: 'REQ_REGISTER_VERIFY_EMAIL_FAIL',

    // Update password
    REQ_REGISTER_NEW_PASSWORD_SUCCESS: 'REQ_REGISTER_NEW_PASSWORD_SUCCESS',
    REQ_REGISTER_NEW_PASSWORD_FAIL: 'REQ_REGISTER_NEW_PASSWORD_FAIL',

    // RESET TO ORIGIN
    REQ_RESET_RESULT: 'REQ_RESET_RESULT',
    REQ_CLEAR_ERROR: 'REQ_CLEAR_ERROR',
};
