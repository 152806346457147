import { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

type WaitingProps = {
    isLoading: boolean;
    content?: string;
};

const index: FC<WaitingProps> = ({ isLoading, content = 'Waiting for download ...' }) => {
    return (
        <Transition.Root show={isLoading} as={Fragment}>
            <Dialog as="div" static onClose={() => {}} className="fixed z-1 inset-0 overflow-y-auto" open={isLoading}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:items-start sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 dialog-background  bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <div className="mt-10 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all relative px-5 py-2 text-ooolab_blue_3">
                            {content}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default index;
