import * as React from 'react';
// PACKAGE
import { ArrowSmRightIcon, CheckIcon, XIcon } from '@heroicons/react/outline';
import { Link, useHistory } from 'react-router-dom';
// CONTEXT
import { CreateWorkspaceContext, GetListOfWorkspaceContext, GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
// MIDDLEWARE
import workspaceMiddleware from 'middleware/workspace.middleware';
// LOGIC
import { MyWorkspaceProps } from './MyWorkspaceFormFn';
// CONTEXT
import { AuthContext } from 'contexts/Auth/AuthContext';
// UTILS
import { getLocalStorageAuthData } from 'utils/handleLocalStorage';
import { handleLogout } from 'utils/handleLogout';
import { WorkspaceItem } from 'types/GetListOfWorkspace.type';
import { useTranslation } from 'react-i18next';
import { AUTH_CONST } from 'constant/auth.const';
import InfiniteScroll from 'react-infinite-scroll-component';
import './style.css';
import { PowerSVG } from 'assets/icon';
import userMiddleware from 'middleware/user.middleware';
import { UserContext } from 'contexts/User/UserContext';
import workspaceService from 'services/workspace.service';
import { UserService, WorkspaceService } from 'services';
import { defaultRole, roleType } from 'components/Management/RolesPermissions/RolesPermissionsContants';
import { formatPermission, objectMenuType, onCheckPermissions } from 'constant/workspace.const';
import { GET_WORKSPACE_DETAIL } from 'actions/workspace.action';

const SkeletonItem = () => {
    let items = [];
    for (let index of [1, 2, 3]) {
        items.push(
            <div
                key={index}
                className="w-ooolab_w_125 group flex items-center p-ooolab_p_2 justify-between border border-ooolab_border_logout rounded-sub_tab transition-all"
            >
                <div className="flex items-center flex-row">
                    <div
                        className={
                            'w-ooolab_w_11 h-ooolab_h_11 bg-ooolab_gray_2 flex items-center justify-center rounded-ooolab_circle'
                        }
                    />
                    <div className=" ml-ooolab_m_3 text-ooolab_base text-ooolab_dark_1 w-ooolab_w_44 h-ooolab_h_4 bg-ooolab_gray_2 rounded-ooolab_radius_4px" />
                </div>

                <div className={'flex flex-row justify-ends space-x-6 items-center'}>
                    {/*<label*/}
                    {/*    className={'text-ooolab_dark_2 leading-ooolab_22px font-medium text-ooolab_base'}>member{'s'}</label>*/}
                    <div className="rounded-ooolab_circle shadow-ooolab_sub_item w-ooolab_w_11 h-ooolab_h_11 flex items-center justify-center"></div>
                </div>
            </div>
        );
    }
    return <div className="animate-pulse flex flex-col space-y-1 overflow-y-scroll">{items.map((value) => value)}</div>;
};

const MyWorkspaceForm: React.FC<MyWorkspaceProps> = ({ setAuthStorage }) => {
    const { t: translator } = useTranslation();
    const userInfo = getLocalStorageAuthData();
    const history = useHistory();
    const [apiSuccessMsg] = React.useState<string>();
    const [memberListMsg, setMemberListMsg] = React.useState<string>();
    const [apiErrorMsg, setApiErrorMsg] = React.useState<string>();
    const [dataView, setDataView] = React.useState<WorkspaceItem[]>([]);


    const { createWorkspaceState, dispatch } = React.useContext(CreateWorkspaceContext);
    const {
        result,
        err,
        isLoading,
        status,
        // validateErr,
    } = createWorkspaceState;
    const { access_token } = userInfo;

    const { getListOfWorkspaceState, dispatch: dispatchList } = React.useContext(GetListOfWorkspaceContext);

    const { dispatch: GetWorkspaceDispatch } = React.useContext(GetWorkspaceContext);
    const { dispatch: UserDispatch, userState } = React.useContext(UserContext);

    const {
        result: { items: listData, page, total },
        err: listWorkspaceErr,
        isLoading: isLoadingWorkspaceItems,
    } = getListOfWorkspaceState;
    const {
        dispatch: WorkspaceDispatch,
        getWorkspaceDetailState: { result: WorkspaceDetailInformation },
    } = React.useContext(GetWorkspaceContext);
    const inviteMemberList = (dataView?.length && dataView.filter((item) => item.membership.status === 'invite')) || [];

    const memberActive = (listData?.length && listData.filter((item) => item.membership.status === 'active')) || [];

    const authCtx = React.useContext(AuthContext);
    const authDispatch = authCtx.dispatch;

    React.useEffect(() => {
        if (err) {
            setApiErrorMsg(err.error.description);
        }
        // if (validateErr) {
        //     setApiErrorMsg(validateErr.validation_error.body_params[0].msg);
        // }
    }, [err]);

    React.useEffect(() => {
        if (listWorkspaceErr?.error?.name === AUTH_CONST.TOKEN_EXPIRED) {
            handleLogout(authDispatch, setAuthStorage);
        }
    }, [listWorkspaceErr]);

    React.useEffect(() => {
        workspaceMiddleware.getListOfWorkspace(dispatchList);
        workspaceMiddleware.resetWorkspaceDetailError(GetWorkspaceDispatch);
    }, []);

    React.useEffect(() => {
        if (status === 'done' && !isLoading && access_token) {
            workspaceMiddleware.getListOfWorkspace(dispatchList);
        }
    }, [status, isLoading]);

    React.useEffect(() => {
        if (listData?.length > 0) {
            const data = dataView.concat(listData);
            setDataView(data);
        }
    }, [listData]);

    const onSwitchWorkspace = (item, active = true) => {
        localStorage.setItem('workspaceId', item?.id);
        workspaceService.onAuthWorkspace(item?.id).then(() => {
            if (active) {
                UserService.getUser().then((userData) => {
                    WorkspaceService.getPermissionsUserWorkspace().then((res) => {
                        WorkspaceDispatch({
                            type: GET_WORKSPACE_DETAIL.REQ_GET_WORKSPACE_PERMISSIONS,
                            result: formatPermission(res?.data),
                        });
                        onCheckPermissions(userData?.membership?.user_role?.role_type, userData?.membership?.user_role?.name, formatPermission(res?.data), history, item?.id, true)
                    })
                })
            } else history.push(`/workspace/${item.id}`);
        });
    };
    const onActiveWorkspaceClick = (item) => {
        window.location.href = `/workspace/${item.id}/instructor-dashboard/instructor-schedule`;
    };
    const onPendingWorkspaceClick = (item) => {
        window.location.href = `/workspace/${item.id}`;
    };
    const getMoreListWorkspace = () => {
        workspaceMiddleware.getListOfWorkspaceMore(dispatchList, {
            page: page + 1,
        });
    };

    const handleLogoutUser = () => {
        handleLogout(authDispatch, setAuthStorage, () => {
            userMiddleware.resetUserState(UserDispatch);
            workspaceMiddleware.resetUserState(WorkspaceDispatch);
        });
    };

    return (
        <>
            <div className="contents sm:justify-center items-center flex-col mt-12 ooolab_ipad_portrait:h-ooolab_body_1 h-full">
                <div className="flex flex-col items-center justify-between w-full my-8">
                    <label className={'text-lg text-ooolab_dark_1 font-semibold'}>
                        {translator('AUTHENTICATION.WORKSPACE.MY_WORKSPACES')}
                    </label>
                    <div className={'flex flex-col items-start w-full my-8 max-w-md'}>
                        <div className={'flex flex-col w-full pb-3 justify-start items-center py-8'}>
                            {isLoadingWorkspaceItems ? (
                                <SkeletonItem />
                            ) : (
                                <div className={'flex flex-col w-full animate-ooolab_fade_in'}>
                                    {/*@ts-ignore*/}
                                    <InfiniteScroll
                                        dataLength={dataView?.length}
                                        next={getMoreListWorkspace}
                                        hasMore={dataView?.length !== total}
                                        loader={dataView?.length !== total && <h4 className="text-center">{translator('LOADING')}</h4>}
                                        height={'45vh'}
                                        scrollableTarget="scrollableDiv "
                                        className="custom-scrollbar scrollData px-8"
                                    >
                                        {dataView?.length > 0 && (
                                            <ActivatedWorkspaceList items={dataView} onItemClick={onSwitchWorkspace} />
                                        )}

                                        {inviteMemberList?.length > 0 && (
                                            <PendingInvitedWorkspaceList
                                                inviteMemberList={inviteMemberList}
                                                onItemClick={onSwitchWorkspace}
                                            />
                                        )}
                                    </InfiniteScroll>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="text-center">
                        <button
                            type="button"
                            onClick={handleLogoutUser}
                            className="font-medium text-primary-500 text-base flex items-center justify-center"
                        >
                            <PowerSVG />
                            <span className="ml-2">Logout</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

const PendingInvitedWorkspaceList: React.FC<{
    inviteMemberList: WorkspaceItem[];
    onItemClick: any;
}> = ({ inviteMemberList, onItemClick }) => {
    return (
        <div className="mt-ooolab_m_1 w-full space-y-1">
            {inviteMemberList.map((item) => (
                <div
                    key={item.id}
                    className="w-full group flex flex-row items-center justify-between border border-ooolab_border_logout rounded-sub_tab border-dashed hover:shadow-lg transition-all"
                >
                    <div className="flex items-center truncate flex-row p-1">
                        <img
                            alt={'avatar'}
                            src={item.avatar_url ?? `https://ui-avatars.com/api?name=${item.name}&size=44`}
                            className={'w-10 h-10 bg-ooolab_gray_2 flex items-center justify-center rounded-ooolab_circle'}
                        />
                        <label className=" ml-ooolab_m_3 text-ooolab_base text-ooolab_dark_1 group-hover:text-ooolab_dark_1 truncate">
                            {item.name}
                        </label>
                    </div>
                    <div className={'flex flex-row justify-ends items-center'}>
                        <label className={'text-ooolab_dark_2 leading-ooolab_22px font-medium text-ooolab_base'}>invited</label>
                        <a
                            // to={`/workspace/${item.id}`}
                            onClick={() => onItemClick(item, true)}
                            className="rounded-ooolab_circle cursor-pointer shadow-ooolab_sub_item w-11 h-11 flex items-center justify-center m-1"
                        >
                            <CheckIcon className="group-hover:text-ooolab_green_2 w-ooolab_w_6 lg:w-ooolab_w_5 text-ooolab_green_2" />
                        </a>
                        {/*todo comment for temporary as this feature is not available*/}
                        {/*<button*/}
                        {/*    className="rounded-ooolab_circle shadow-ooolab_sub_item w-ooolab_w_11 h-ooolab_h_11 flex items-center justify-center">*/}
                        {/*    <XIcon*/}
                        {/*        className="group-hover:text-ooolab_error w-ooolab_w_6 lg:w-ooolab_w_5 text-ooolab_error"*/}
                        {/*        onClick={() => {*/}
                        {/*        }}/>*/}
                        {/*</button>*/}
                    </div>
                </div>
            ))}
        </div>
    );
};

const ActivatedWorkspaceList: React.FC<{
    items: WorkspaceItem[];
    onItemClick: any;
}> = ({ items, onItemClick }) => {
    return (
        <div className="w-full flex flex-col space-y-1 items-center">
            {items
                .filter((item) => item.membership.status === 'active')
                .map((item, index) => (
                    <div
                        key={index}
                        className="group flex items-center justify-between w-full border border-ooolab_border_logout rounded-sub_tab hover:shadow-lg transition-all"
                    >
                        <div className="flex items-center truncate flex-row p-1">
                            <img
                                alt={'avatar'}
                                src={item.avatar_url ?? `https://ui-avatars.com/api?name=${item.name}&size=44`}
                                className={'w-10 h-10 bg-ooolab_gray_2 flex items-center justify-center rounded-ooolab_circle'}
                            />
                            <label className=" ml-3 text-base text-ooolab_dark_1 truncate group-hover:text-ooolab_dark_1">
                                {item.name}
                            </label>
                        </div>

                        <div className={'flex flex-row justify-ends space-x-6 items-center'}>
                            {/*<label*/}
                            {/*    className={'text-ooolab_dark_2 leading-ooolab_22px font-medium text-ooolab_base'}>member{'s'}</label>*/}
                            <a
                                // to={{
                                //     pathname: `/workspace/${item.id}/h5p-content`,
                                //     state: item,
                                // }}
                                onClick={() => onItemClick(item, true)}
                                className="rounded-ooolab_circle cursor-pointer shadow-ooolab_sub_item w-11 h-11 flex items-center justify-center m-1"
                            >
                                <ArrowSmRightIcon className="group-hover:text-primary-500 w-8 lg:w-ooolab_w_5 text-primary-500" />
                            </a>
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default MyWorkspaceForm;
