export const PropertyType = [
    {
        label: 'Single selection',
        value: 'single_selection'
    },
    {
        label: 'Multiple selection',
        value: 'multiple_selection'
    },
    {
        label: 'Single-line Text',
        value: 'single_line_text'
    },
    {
        label: 'Multi-line text',
        value: 'multi_line_text'
    },
    {
        label: 'Date picker',
        value: 'date_picker',
    },
    {
        label: 'Phone number',
        value: 'phone_number',
    },
    {
        label: 'Email',
        value: 'email',
    }
]

export const ObjectType = [
    {
        label: 'Course',
        value: 'course'
    },
    {
        label: 'Learner',
        value: 'learner'
    }
]