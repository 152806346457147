import CompanyList from 'components/Management/Company/CompanyList';
import ManagementMasterPage from 'pages/RouteMasterPage';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';

const CompanyListPage = () => {
    return (
        <ManagePermissionWrapper>
            <CompanyList>
                <ManagementMasterPage />
            </CompanyList>
        </ManagePermissionWrapper>
    );
};

export default CompanyListPage;
