import * as React from 'react';
import HeaderPage from 'components/HeaderPage';
import SearchInput from '../components/SearchInput';
import { useDebouncedState } from 'hooks/useDebounce';
import Table from './TableTeacher';
import { BlockInfo, BlockName, BlockValue } from '../components/BlockInfo';
import { useTranslation } from 'react-i18next';
import Select from '../components/Select';
import DatePickerComponent from '../components/DatePicker';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { SelectOptionType } from 'types/GetListOfWorkspace.type';
import dayjs from 'dayjs';
import reportService from 'services/report.service';
import NoContent from '../components/NoContent/NoContent';
import { roundNumberWithHours } from 'constant/util.const';
import Spinner from 'components/Spinner';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import BorderCardInfo from 'components/UiComponents/BorderCardInfo';
import workspaceService from 'services/workspace.service';
import { NoContentWithImage } from 'components/NoContent/NoContent';

const dateFormat = 'YYYY-MM-DD';
const startTimeFormat = 'T00:00:00';
const endTimeFormat = 'T23:59:59';

const TeacherDashboard = () => {
    const [keyword, setKeyword] = useDebouncedState('');
    const [selected, setSelected] = React.useState([{ label: 'All Teacher', value: 'all' }]);
    const { t } = useTranslation();
    const [loadingGetListTeacher, setLoadingGetListTeachers] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<SelectOptionType[]>([]);
    const [generalReport, setGeneralReport] = React.useState<any>({
        sum_duration: 0,
        count_session: 0,
    });
    const [loadingGetGeneral, setLoadingGetGeneral] = React.useState<boolean>(false);
    const [dataTableOverview, setDataTableOverview] = React.useState([]);
    const [initData, setInitData] = React.useState([]);
    const [loadingDataOverview, setLoadingDataOverview] = React.useState(false);
    const params: any = useParams();
    /*@ts-ignore*/
    const [startDate, setStartDate] = React.useState(moment().startOf('month')?._d);
    const [endDate, setEndDate] = React.useState(new Date());

    const [totalList, setTotalList] = React.useState<any>({
        page: 1,
        per_page: 20,
        sort_by: 'start_time',
        order: 'asc',
        total: 0,
    });

    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (end) {
            getGeneralReport(end);
            getDataOverview(end);
        }
    };

    const getGeneralReport = async (end?: any, arrSelected: any = []) => {
        setLoadingGetGeneral(true);
        const hasSelectAll = (arrSelected || selected).find((item) => item.value === 'all');
        const arrTeachers = (arrSelected || selected).map((i) => i.value);
        const body = {
            start_time: dayjs(startDate).format(dateFormat) + startTimeFormat,
            end_time: end ? dayjs(end).format(dateFormat) + endTimeFormat : dayjs(endDate).format(dateFormat) + endTimeFormat,
        };

        await reportService
            .getGeneralReportTeachers(params.id, body, hasSelectAll ? null : arrTeachers)
            .then((res) => {
                setGeneralReport(res?.data);
            })
            .finally(() => setLoadingGetGeneral(false));
    };

    const getDataOverview = async (end?: any, arrSelected: any = []) => {
        setLoadingDataOverview(true);
        const hasSelectAll = (arrSelected || selected).find((item) => item.value === 'all');
        const arrTeachers = (arrSelected || selected).map((i) => i.value);
        const body = {
            start_time: dayjs(startDate).format(dateFormat) + startTimeFormat,
            end_time: end ? dayjs(end).format(dateFormat) + endTimeFormat : dayjs(endDate).format(dateFormat) + endTimeFormat,
        };
        await reportService
            .getOverviewReportTeachers(params.id, body, hasSelectAll ? null : arrTeachers)
            .then((res) => {
                setDataTableOverview(res?.data?.items);
                setInitData(res?.data?.items);
            })
            .finally(() => setLoadingDataOverview(false));
    };

    const getList = async (page?: number, check = true) => {
        const paramsTeacher = {
            page: page,
            per_page: 20,
            order: 'desc',
            status: 'active',
            type: 'instructor',
            role: ['admin', 'corporate_admin']
        };
        setLoadingGetListTeachers(true);
        await workspaceService
            .getWorkspaceMembersService(params.id, paramsTeacher)
            .then((res: any) => {
                if (res) {
                    let newOptions = [];
                    if (res?.data?.items.length) {
                        newOptions = res.data?.items?.map((el) => ({
                            label: el?.display_name,
                            value: el?.id,
                        }));
                        setOptions([...options, ...newOptions]);
                        if (check) {
                            setSelected([...selected, ...newOptions]);
                        }
                    }
                    setTotalList({
                        page: res.data?.page,
                        per_page: res.data?.per_page,
                        sort_by: res.data?.sort_by,
                        order: res.data?.order,
                        total: res.data?.total,
                    });
                }
            })
            .finally(() => setLoadingGetListTeachers(false));
    };

    const handleSelectTecher = (arrSelected) => {
        setSelected(arrSelected);
        if (arrSelected.length === 0) {
            setGeneralReport({})
            setDataTableOverview([])
        } else {
            getGeneralReport(undefined, arrSelected);
            getDataOverview(undefined, arrSelected);
        }
    };

    React.useEffect(() => {
        if (params?.id) {
            getList(1, true);
            getGeneralReport();
            getDataOverview();
        }
        return () => {
            setKeyword('');
            setDataTableOverview([]);
            setGeneralReport({});
            setInitData([]);
        };
    }, []);

    React.useEffect(() => {
        if (initData?.length) {
            if (!keyword) setDataTableOverview(initData);
            else setDataTableOverview(initData.filter((i) => i?.display_name?.toLowerCase()?.includes(keyword)));
        }
    }, [keyword]);

    const loadMore = (hasAll: boolean) => {
        if (totalList.total > totalList.page * totalList.per_page) {
            getList(totalList.page + 1, hasAll);
        }
    };

    return (
        <div className="w-full">
            <PageBreadcrumb
                pageNameDefault={translator('REPORTS')}
                pages={[
                    {
                        name: translator('TEACHER_DASHBOARD'),
                        href: '',
                        className: '',
                    },
                ]}
                containerClassName="fixed z-10"
            />
            <div className="py-4 px-8 pt-[69px]">
                <div className="flex justify-between items-center">
                    <h1 className="text-xl font-semibold">{translator('TEACHER_DASHBOARD')}</h1>
                    <BorderCardInfo label={translator('TOTAL_TEACHER')} subLabel={totalList.total} classes="max-w-max" />
                </div>
                <div className="mt-7 flex items-center space-x-4">
                    <Select
                        options={options}
                        selectedOptions={selected}
                        onChange={handleSelectTecher}
                        optionAll={{ value: 'all', label: 'All Teachers' }}
                        title="Teacher"
                        loadMore={loadMore}
                        loading={loadingGetListTeacher}
                    />
                    <DatePickerComponent onChange={onChange} startDate={startDate} endDate={endDate} />
                </div>

                {totalList.total === 0 ? (
                    <div className=" absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                        <NoContentWithImage content={translator('EMPTY_FIELD')} />
                    </div>
                ) : (
                    <>
                        <div className="mt-7 grid grid-cols-5 gap-7">
                            <BlockInfo>
                                <div>
                                    <BlockValue>{'N/A'}</BlockValue>
                                    <BlockName>{translator('PUNCTUALITY')}</BlockName>
                                </div>
                            </BlockInfo>
                            <BlockInfo containerClassName="col-span-2">
                                <div className="flex justify-between w-full">
                                    <div className="w-1/2">
                                        <BlockValue>{'N/A'}</BlockValue>
                                        <BlockName>{translator('TOTAL_TALK_TIME')}</BlockName>
                                    </div>
                                    <div className="absolute top-[calc(50%-16px)] left-1/2 h-8 w-[1px] bg-[#D9D9D9]"></div>
                                    <div className="w-1/2">
                                        <BlockValue>{'N/A'}</BlockValue>
                                        <BlockName>{translator('PERCENT_TALK_TIME')}</BlockName>
                                    </div>
                                </div>
                            </BlockInfo>
                            <BlockInfo>
                                {loadingGetGeneral && (
                                    <div className=" absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                        <Spinner />
                                    </div>
                                )}
                                <div>
                                    <BlockValue>{generalReport?.count_session || 'N/A'}</BlockValue>
                                    <BlockName>{translator('TOTAL_CLASSES_TALK')}</BlockName>
                                </div>
                            </BlockInfo>
                            <BlockInfo>
                                {loadingGetGeneral && (
                                    <div className=" absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                        <Spinner />
                                    </div>
                                )}
                                <div>
                                    <BlockValue>
                                        {generalReport?.sum_duration ? roundNumberWithHours(generalReport?.sum_duration) : 'N/A'}
                                    </BlockValue>
                                    <BlockName>{translator('TOTAL_HOURS_TALK')}</BlockName>
                                </div>
                            </BlockInfo>
                        </div>
                        <div className="mt-7">
                            <SearchInput onSubmit={(e: any) => setKeyword(e)} />
                            <div className="mt-7 relative overflow-auto">
                                {loadingDataOverview && (
                                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                        <Spinner />
                                    </div>
                                )}
                                <Table data={dataTableOverview} workspaceId={params.id} />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default TeacherDashboard;
