import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PlusSmIcon } from '@heroicons/react/outline';
import Table from './Table/Table';
import { useTranslation } from 'react-i18next';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { ButtonPrimary } from '_shared';
import Filter from './Filter/Filter';
import './Instructor.scss'
import DialogComponent from 'components/Dialog/DialogComponent';
import ModalInviteInstructor from '../Modals/ModalInviteInstructor';
import courseService from 'services/course.service';
import Pagination from 'components/V2/Pagination';
import { checkPermission, getIndexPage } from 'utils/commonFun';

const Instructor: React.FC = ({ children }) => {
    const { t: translator } = useTranslation();
    const params: { id: string, courseId: string } = useParams();
    const [isFirstLoad, setFirstLoad] = useState(true);
    const [isOpenModalInviteInstructor, setOpenModalInviteInstructor] = useState(false)
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [indexPage, setIndexPage] = useState('0-0');
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'invited_at',
        total: 0,
    });

    const workspaceId = params.id;
    const courseId = params.courseId;


    const getdata = (page: number | string = 1, filter = undefined) => {
        setLoading(true);
        const payload = {
            ...pagination,
            ...filter,
            page,
        };
        delete payload?.total;
        courseService.getInstructorEnrolled(workspaceId, courseId, payload)
            .then((res) => {
                setFirstLoad(false);
                const { items, total } = res.data;
                setData(items);
                setPagination({ ...pagination, ...payload, total });
                setIndexPage(getIndexPage(payload?.page, pagination?.per_page, items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const handlePagination = (page: number) => {
        getdata(page);
    };

    useEffect(() => {
        getdata();
    }, []);

    const onChangeFilter = (filter) => {
        getdata(1, filter);
    };

    return (
        <div className="CourseListInstructor">
            <div className="CourseListInstructor-filter">
                <Filter onChangeFilter={onChangeFilter} isFirstLoad={isFirstLoad} />
                {checkPermission('coursePeopleInstructor', 'U') && <div className="">
                    <ButtonPrimary type="button" onClick={() => setOpenModalInviteInstructor(true)}>
                        <span className="flex">
                            <PlusSmIcon className="PlusIcon" aria-hidden="true" />
                            <span> {translator('ADD')}</span>
                        </span>
                    </ButtonPrimary>
                </div>}

            </div>

            <div className="CourseListInstructor-main">
                <div className="wrapper-content">
                    <div className="scrollbar">
                        {loading && (
                            <div className="loading">
                                <CircleSpin color="text-primary-500" />
                            </div>
                        )}
                        <Table data={data} refreshData={getdata} />
                    </div>
                    <Pagination
                        total={pagination.total}
                        per_page={pagination.per_page}
                        callback={(e) => handlePagination(e)}
                        indexPage={indexPage}
                        page={pagination?.page}
                    />
                </div>
            </div>
            <DialogComponent
                isOpen={isOpenModalInviteInstructor}
                onCloseModal={() => setOpenModalInviteInstructor(false)}
                title={translator("COURSES.ADD_INSTRUCTOR")}
                stylesTitle='text-base'
                styles='max-w-lg'
                child={
                    <ModalInviteInstructor onCloseModal={(isRefesh?: boolean) => {
                        if (isRefesh) getdata()
                        setOpenModalInviteInstructor(false)
                    }} />
                }
            />
        </div>
    );
};

export default Instructor;
