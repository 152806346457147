import { useTranslation } from 'react-i18next';
import "./index.scss";
import { UserContext } from 'contexts/User/UserContext';
import { useContext } from 'react';
import dayjs from 'dayjs';
export const TaxRateHistory = ({ arrHistory = [] }) => {

    const { t } = useTranslation();
    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`E_COMMERCE.${str}`, opt));
    const {
        userState: { result },
    } = useContext(UserContext);
    return <div className='taxRate'>
        <div className='taxRate-header'>
            <div>
                {translator('TAX_RATE')}
            </div>
            <div>
                {translator('DATE')}
            </div>
        </div>
        {arrHistory?.map((item) => <div className='taxRate-item'>
            <div>
                {item?.tax_rate}
            </div>
            <div>
                {
                    item?.updated_at
                        ? dayjs(item?.updated_at).tz(result?.time_zone).format('DD/MM/YYYY HH:mm')
                        : ''
                }
            </div>
        </div>)}


    </div>
}