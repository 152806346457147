import React from 'react'

export default function DetailIcon({className = ''}) {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6818_15183)">
      <path d="M10.0003 18.3333C5.39783 18.3333 1.66699 14.6025 1.66699 9.99996C1.66699 5.39746 5.39783 1.66663 10.0003 1.66663C14.6028 1.66663 18.3337 5.39746 18.3337 9.99996C18.3337 14.6025 14.6028 18.3333 10.0003 18.3333ZM10.0003 16.6666C11.7684 16.6666 13.4641 15.9642 14.7144 14.714C15.9646 13.4638 16.667 11.7681 16.667 9.99996C16.667 8.23185 15.9646 6.53616 14.7144 5.28591C13.4641 4.03567 11.7684 3.33329 10.0003 3.33329C8.23222 3.33329 6.53652 4.03567 5.28628 5.28591C4.03604 6.53616 3.33366 8.23185 3.33366 9.99996C3.33366 11.7681 4.03604 13.4638 5.28628 14.714C6.53652 15.9642 8.23222 16.6666 10.0003 16.6666ZM9.16699 5.83329H10.8337V7.49996H9.16699V5.83329ZM9.16699 9.16663H10.8337V14.1666H9.16699V9.16663Z" fill="currentColor"/>
      </g>
      <defs>
      <clipPath id="clip0_6818_15183">
      <rect width="20" height="20" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}
