import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import BookingForm from './components/BookingForm';

export default function Newbooking({ children }) {
    const { t: translator } = useTranslation();
    const params: { id } = useParams();
    const location: any = useLocation();

    return (
        <div className="">
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT')}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    { name: translator('DASHBOARD.SIDEBAR.BOOKING'), href: `/workspace/${params.id}/management/booking-class`, className: '' },
                    { name: location?.state?.id ?translator('SELF_BOOKING.RESCHEDULE') : translator('SELF_BOOKING.ADD_BOOKING'), href: '', className: '' },
                ]}
            />
            <BookingForm />
        </div>
    );
}
