import React from 'react';
import { classNames } from 'utils/commonFun';

interface FooterChangePasswordProps {
    child: any;
    inputClass?: string;
}

const FooterChangePassword: React.FC<FooterChangePasswordProps> = ({
    child,
    inputClass,
}) => {
    return <div className={classNames(inputClass)}>{child}</div>;
};

export default FooterChangePassword;
