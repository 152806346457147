import React from 'react'

export default function GraduationCap({className=''}) {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.62748 3.42099C9.86209 3.30368 10.1382 3.30368 10.3728 3.42099L18.69 7.57959C18.7959 7.62979 18.8896 7.70168 18.9652 7.78951C19.0909 7.93542 19.1668 8.12534 19.1668 8.33301V13.333C19.1668 13.7932 18.7937 14.1663 18.3335 14.1663C17.8733 14.1663 17.5002 13.7932 17.5002 13.333V9.68137L10.3728 13.245C10.1382 13.3623 9.86209 13.3623 9.62748 13.245L1.29415 9.07836C1.01183 8.9372 0.833496 8.64865 0.833496 8.33301C0.833496 8.01736 1.01183 7.72881 1.29415 7.58765L9.62748 3.42099ZM16.4701 8.33301L10.0002 5.09804L3.53022 8.33301L10.0002 11.568L16.4701 8.33301Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.99984 9.16699C5.46007 9.16699 5.83317 9.54009 5.83317 10.0003V13.8044C6.89069 14.7176 8.41661 15.2087 9.99984 15.2087C11.5831 15.2087 13.109 14.7176 14.1665 13.8044V10.0003C14.1665 9.54009 14.5396 9.16699 14.9998 9.16699C15.4601 9.16699 15.8332 9.54009 15.8332 10.0003V14.167C15.8332 14.388 15.7454 14.6 15.5891 14.7562C14.1474 16.1979 12.0402 16.8753 9.99984 16.8753C7.95944 16.8753 5.85223 16.1979 4.41058 14.7562C4.2543 14.6 4.1665 14.388 4.1665 14.167V10.0003C4.1665 9.54009 4.5396 9.16699 4.99984 9.16699Z" fill="currentColor"/>
    </svg>
  )
}
