export const CircleTime = ({ }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1597_41939)">
                <path d="M7.99967 1.33301C11.6817 1.33301 14.6663 4.31767 14.6663 7.99967C14.6663 11.6817 11.6817 14.6663 7.99967 14.6663C4.31767 14.6663 1.33301 11.6817 1.33301 7.99967H2.66634C2.66634 10.945 5.05434 13.333 7.99967 13.333C10.945 13.333 13.333 10.945 13.333 7.99967C13.333 5.05434 10.945 2.66634 7.99967 2.66634C6.35701 2.66634 4.88767 3.40901 3.90967 4.57634L5.33301 5.99967H1.33301V1.99967L2.96434 3.63034C4.18634 2.22367 5.98901 1.33301 7.99967 1.33301ZM8.66634 4.66634V7.72301L10.8283 9.88501L9.88501 10.8283L7.33301 8.27501V4.66634H8.66634Z" fill="#0071CE" />
            </g>
            <defs>
                <clipPath id="clip0_1597_41939">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}