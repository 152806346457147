import Disclosure from '_shared/components/Disclosure/Disclosure';
import DialogComponent from 'components/Dialog/DialogComponent';
import { CalendarIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { Member, SessionDetail, SessionTitle } from './SessionDetail';
import './ModalSessionDetail.scss';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { UserContext } from 'contexts/User/UserContext';
import { useTranslation } from 'react-i18next';

export default function ModalSessionDetail({ isOpen, onClose, sessions, refreshData }) {
    const {
        userState: { result },
    } = useContext(UserContext);
    const {t} = useTranslation();
    return (
        <DialogComponent
            isOpen={isOpen}
            onCloseModal={() => {
                onClose?.();
                refreshData?.();
            }}
            child={
                <div>
                    {sessions?.length > 1 && (
                        <div className="font-normal py-4 flex gap-1 items-center border-t border-gray-300 -mx-3 px-3">
                            {t("SESSION.TOTAL")} <span className='font-semibold'>{sessions?.length}</span> {t("SESSION.SESSION_FOR")} <CalendarIcon className="w-4 h-4 text-gray-500" />
                            <span className="font-semibold">{dayjs(sessions?.[0]?.startStr).tz(result.time_zone)?.format('DD/MM/YYYY')}</span>
                        </div>
                    )}
                    {sessions?.length > 1 ? (
                        <div className="ModalSessionDetail">
                            {sessions?.map((item, index) => {
                                return (
                                    <div key={index} className="ModalSessionDetail-session">
                                        <Disclosure
                                            button={(open) => {
                                                return (
                                                    <div className="DisclosureButton">
                                                        <div className="title">
                                                            <SessionTitle refreshData={() => { onClose() ;refreshData()}} session={item?.extendedProps?.initData} isMultiSession={true} />
                                                        </div>
                                                        {!open && (
                                                            <div className="member">
                                                                <Member session={item?.extendedProps?.initData} />
                                                                <div className="showmore">
                                                                    {t("SHOW_MORE")} <ChevronDownIcon className="icon" />{' '}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            }}
                                            panel={(close, isOpen) => <SessionDetail session={item?.extendedProps?.initData} close={close} isOpen={isOpen} isMultiSession={true}/>}
                                            index={index}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className='-mx-2 border-t border-gray-300'><SessionDetail session={sessions?.[0]?.extendedProps?.initData} isOpen={true} /></div>
                    )}
                </div>
            }
            styles="max-w-2xl !p-3"
            stylesTitle='!h-5'
            title={sessions?.length > 1 ? t('SESSION.SESSION_LIST') : <SessionTitle refreshData={() => { onClose() ;refreshData()}} session={sessions?.[0]?.extendedProps?.initData} />}
        />
    );
}
