import { Fragment, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Transition, Popover } from '@headlessui/react';

import SearchAndCheckBox from './SearchAndCheckBox';
import { CheckboxType } from 'types/Lesson.type';
import { H5P_LIBRARY } from 'constant/h5p.const';
import { H5PContext } from 'contexts/H5P/H5PContext';
import { updateList } from '../H5PFN';
import { useTranslation } from 'react-i18next';
import { FilterIcon } from '@heroicons/react/outline';
import h5pMiddlware from 'middleware/h5p.middlware';
import { ParamsH5P } from 'types/H5P.type';
import H5PFilterTags from './H5PFilterTags';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import workspaceMiddleware from 'middleware/workspace.middleware';

const H5PFilter: React.FC = () => {
    const { t: translator } = useTranslation();
    const paramsUrl: { id: string } = useParams();
    const [listContentType, setlistContentType] = useState<CheckboxType[]>([]);
    const h5PCtx = useContext(H5PContext);

    const {
        dispatch: h5pDispatch,
        H5PState: { params },
    } = h5PCtx;

    useEffect(() => {
        Object.keys(H5P_LIBRARY).forEach((d, i) => {
            const type = {
                check: false,
                id: i,
                name: d,
                value: H5P_LIBRARY[d],
            };
            setlistContentType((pre) => [...pre, type]);
        });
    }, []);

    const { getValues, register } = useForm();

    const submit = () => {
        const values = getValues();
        const listCheckedContentType: string[] = [];
        const listCheckedTags: string[] = [];
        Object.keys(values.contentType).forEach((i) => {
            if (values.contentType[i]) {
                listCheckedContentType.push(`H5P.${i}`);
            }
        });
        if (values?.tags) {
            Object.keys(values?.tags).forEach((i) => {
                if (values?.tags[i]) {
                    listCheckedTags.push(i);
                }
            });
        }

        const searchH5p: ParamsH5P = {
            ...params,
            page: 1,
            content_type: listCheckedContentType.join(','),
            tag_id: listCheckedTags.join(','),
        };
        if (listCheckedContentType) {
            listCheckedContentType.map((i) => searchH5p);
        }
        h5pMiddlware.h5pParamsContent(h5pDispatch, searchH5p);
        updateList(h5pDispatch, paramsUrl.id, searchH5p);
    };
    return (
        <Popover as="div" className="relative inline-block">
            {({ open }) => (
                <>
                    {/* <Popover.Button
                        as="button"
                        className={`flex border justify-center items-center focus:outline-none  rounded-md border-none`}
                    >
                        <div className="flex items-center bg-ooolab_bg_bar px-ooolab_p_3 py-ooolab_p_2 rounded-lg text-primary-500 text-ooolab_base focus:outline-none">
                            <span className="bg-img-item-filter bg-no-repeat bg-contain w-ooolab_w_6 h-ooolab_h_6 mr-ooolab_m_2 "></span>
                            <span>
                                {translator('DASHBOARD.H5P_CONTENTS.FILTER')}
                            </span>
                        </div>
                    </Popover.Button> */}
                    <div className="relative col-start-1 row-start-1 py-4">
                        <div className="max-w-7xl mx-auto flex justify-end space-x-6 divide-x divide-gray-200 text-sm px-4 sm:px-6 lg:px-8 focus:outline-none">
                            <div>
                                <Popover.Button
                                    as="button"
                                    className={`group text-gray-700 font-medium flex items-center focus:outline-none`}
                                >
                                    <FilterIcon
                                        className="flex-none w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                    />
                                    {translator(
                                        'DASHBOARD.H5P_CONTENTS.FILTER'
                                    )}
                                    {/* <div className="flex items-center bg-ooolab_bg_bar px-ooolab_p_3 py-ooolab_p_2 rounded-lg text-primary-500 focus:outline-none">
                                        <span className="bg-img-item-filter bg-no-repeat bg-contain w-ooolab_w_6 h-ooolab_h_6 mr-ooolab_m_2 "></span>
                                        <span>{translator('COURSES.FILTER')}</span>
                                    </div> */}
                                </Popover.Button>
                            </div>
                            {/* <div className="pl-6">
                                <button type="button" className="text-gray-500">
                                    Clear all
                                </button>
                            </div> */}
                        </div>
                    </div>
                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Popover.Panel
                            unmount
                            className="z-9999 min-w-ooolab_w_56 p-ooolab_p_5 bg-white shadow-ooolab_box_shadow_container origin-top-right absolute right-0 mt-2 rounded-header_menu focus:outline-none"
                        >
                            {({ close }) => (
                                <>
                                    <span className="text-ooolab_dark_1 text-ooolab_base font-medium">
                                        {translator(
                                            'DASHBOARD.H5P_CONTENTS.FILTER'
                                        )}
                                        /{' '}
                                        <span className="text-ooolab_blue_3">
                                            {' '}
                                            {translator(
                                                'DASHBOARD.H5P_CONTENTS.H5P_CONTENTS'
                                            )}
                                        </span>
                                    </span>
                                    <div>
                                        <div className="flex mt-ooolab_m_4">
                                            <div className="min-w-ooolab_w_56 mr-ooolab_m_1">
                                                <SearchAndCheckBox
                                                    listCheckBox={
                                                        listContentType
                                                    }
                                                    register={register}
                                                    getValues={getValues}
                                                />
                                            </div>
                                            <div className="min-w-ooolab_w_56 mr-ooolab_m_1">
                                                <H5PFilterTags
                                                    register={register}
                                                    getValues={getValues}
                                                />
                                            </div>
                                        </div>
                                        <div className="pt-ooolab_p_3 text-right">
                                            <button
                                                onClick={() => {
                                                    submit();
                                                    setTimeout(
                                                        () => close(),
                                                        500
                                                    );
                                                }}
                                                className="bg-primary-500 text-white px-ooolab_p_3 py-ooolab_p_1 rounded-lg focus:outline-none"
                                            >
                                                {translator('APPLY')}
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
};

export default H5PFilter;
