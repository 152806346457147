import { Popover } from '@headlessui/react';
import { ChevronDownIcon, SearchIcon, XIcon } from '@heroicons/react/outline';
import { CheckboxFill } from 'assets/icon';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { useTranslation } from 'react-i18next';
import SearchBox from '../SearchBox';
import "./SelectBox.scss";

interface ISelectBox {
    onSearch?: (value: string) => void;
    data: any[];
    onSelect: (arr) => void;
    label: string;
    loading?: boolean;
    total: number;
    showmore?: () => void;
    type?: string;
    selectedItems: any[];
    isDisabled?: boolean;
    position?: string;
    isMulti?: boolean;
    hasSearchIcon?: boolean;
    styles?: any;
    siz?: string;
}

export default function SelectBox({
    onSearch,
    data,
    onSelect,
    label,
    loading = false,
    total = 0,
    showmore,
    type,
    selectedItems = [],
    isDisabled = false,
    position="left",
    isMulti =  true,
    hasSearchIcon = false,
    styles,
    siz='L'
}: ISelectBox) {
    const handleCheck = (selected) => {
        if (!isMulti) {
            return onSelect([selected]);
        }
        const alreadyCheckedItem = selectedItems?.find((item) => item?.value === selected?.value);
        let newSelectedItems = [];
        if (alreadyCheckedItem) {
            newSelectedItems = selectedItems?.filter((item) => item?.value !== selected?.value);
        } else newSelectedItems = [...selectedItems, selected];
        onSelect(newSelectedItems);
    };

    const handleClearSelection = () => {
        onSelect([]);
    };

    const { t } = useTranslation();

    return (
        <Popover className="relative">
            <Popover.Button onClick={() => onSearch?.('')} disabled={isDisabled} className="disabled:opacity-50 cursor-pointer outline-none focus:outline-none disabled:cursor-not-allowed">
                <div
                    className={`border text-gray-800 ${selectedItems?.length && !hasSearchIcon
                        ? type === 'show-all'
                            ? 'border text-primary-500 border-blue-400 bg-white'
                            : 'border border-primary-500 bg-blue-50'
                        : 'border-gray-200 bg-white'
                        }  rounded w-fit max-w-md flex gap-2 items-center px-3`}
                >
                    <div className={`flex items-center ${styles?.container ? styles?.container : 'flex-wrap'}`}>
                        <div className={`flex items-center ${siz == 'M' ? 'py-[5px]' : 'py-[7px]'} rounded outline-none border-none text-ellipsis truncate bg-transparent text-sm`}>
                            {hasSearchIcon ? <SearchIcon className='w-4 h-4 mr-1' /> : null }
                            {selectedItems?.length && type !== 'show-all' ? (
                                <div className="flex items-center gap-1">
                                    {!hasSearchIcon && <span className="font-semibold">{label}: </span>} <span className='text-ellipsis truncate max-w-[80px]'>{selectedItems?.[0]?.name}</span>
                                </div>
                            ) : (
                                <span className='text-gray-500 text-ellipsis truncate'>{label}</span>
                            )}
                        </div>
                        {selectedItems?.length > 1 && type !== 'show-all' && (
                            <div className="h-6 ml-2 w-fit px-2 border border-gray-300 rounded bg-gray-50 flex items-center justify-center">
                                +{selectedItems?.length - 1}
                            </div>
                        )}
                    </div>
                    {selectedItems?.length && type !== 'show-all' ? (
                        <XIcon
                            onClick={(e) => {
                                e.preventDefault();
                                handleClearSelection();
                            }}
                            className="w-4 h-4 text-gray-500"
                        />
                    ) : (
                        !hasSearchIcon && <ChevronDownIcon className="w-3 h-3 text-gray-500" />
                    )}
                </div>
            </Popover.Button>
            <Popover.Panel className={`absolute z-10 bg-white w-64 shadow-md rounded-lg ${position == "left" ? 'left-0 ' : 'right-0'}`} >
                <div className="w-full pt-3 border-b border-gray-300">
                   { 
                    onSearch && 
                        <div className='px-4 pb-2'>
                            <SearchBox className="min-w-full !text-sm max-w-md" onSubmit={(value) => onSearch?.(value)} />
                        </div>
                    }

                    <div className="max-h-[193px] overflow-auto">
                        {data?.map((item, index) => {
                            return (
                                <label
                                    onClick={() => handleCheck(item)}
                                    key={index}
                                    className={`selectBox-checkbox 
                                        ${!!selectedItems?.find((selectedItem) => selectedItem?.value === item?.value) ? 'selectBox-checkbox--checked' : ''}
                                        ${item.disabled ? 'selectBox-checkbox--disabled' : ''}`}
                                >
                                    <span className='selectBox-check'><CheckboxFill /></span>
                                    <div className="font-normal text-sm text-gray-800 text-ellipsis truncate">{item?.name}</div>
                                </label>
                            );
                        })}
                        {loading ? (
                            <div className="flex justify-center">
                                <CircleSpin className="w-6 h-6 text-primary-500" />{' '}
                            </div>
                        ) : (
                            showmore && data?.length >= 10 && data?.length < total && (
                                <div onClick={showmore} className="text-sm px-4 pb-2 text-primary-500 cursor-pointer font-normal">
                                    {t('LIBRARY.SHOW_MORE')}
                                </div>
                            )
                        )}
                    </div>
                </div>
                <div className="flex justify-between px-4 py-2 text-sm font-normal">
                    <p
                        onClick={handleClearSelection}
                        className={`${selectedItems?.length > 0 ? ' pointer-events-auto cursor-pointer text-primary-500' : 'pointer-events-none text-gray-400'
                            }`}
                    >
                        {' '}
                        {t('Clear Selection')}
                    </p>
                    <p className="text-gray-500">
                        {`${selectedItems?.length || 0}/${total} ${t('Selected')}`}
                    </p>
                </div>
            </Popover.Panel>
        </Popover>
    );
}
