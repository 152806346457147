import { FC } from 'react';

import { LessonInterface } from 'types/GetListOfWorkspace.type';

import Document from 'assets/SVG/document.svg';
import { DownloadIcon } from '@heroicons/react/outline';
import workspaceService from 'services/workspace.service';

interface LessonItem {
    data: LessonInterface;
    onClick: (e: LessonInterface) => void;
    currentActiveId: number;
    workspaceId: string;
}

const LessonGridItem: FC<LessonItem> = ({ data, onClick, currentActiveId, workspaceId }) => {
    return (
        <div
            onClick={() => onClick(data)}
            className={`border hover:border-ooolab_blue_1 cursor-pointer rounded-ooolab_h5p px-ooolab_p_4 py-ooolab_p_3 relative
                ${
                    currentActiveId === data.id
                        ? 'border-ooolab_blue_1 '
                        : 'border-ooolab_border_logout'
                }
                `}
        >         
            <div className="flex justify-between">
                <div>
                    <img
                        src={Document}
                        className="w-ooolab_w_6 h-ooolab_h_6 mr-ooolab_m_2"
                        alt=""
                    />
                    {/* <DownloadIcon onClick={() => workspaceService.downloadLesson(workspaceId, data.id)}  className='h-5 w-5 text-ooolab_dark_2 cursor-pointer hover:text-ooolab_dark_1 mt-2' /> */}
                </div>
                
                <p className="overflow-hidden overflow-ellipsis whitespace-pre">{data.title}</p>
            </div>
            <div className="flex justify-between mt-ooolab_m_8">
                <p className="text-ooolab_dark_2">{data.updated_at}</p>
                <img
                    className="w-ooolab_w_6 h-ooolab_h_6 rounded-full"
                    src={data?.created_by?.avatar_url}
                    alt=""
                />
            </div>
        </div>
    );
};

export default LessonGridItem;
