import { ResourcesAction ,ResourcesState} from 'types/Resources.type';
import { LIST_RESOURCES } from 'actions/resources.action';


export function getResourcesReducer(
    state: ResourcesState,
    action: ResourcesAction
) {
    switch (action.type) {
        case LIST_RESOURCES.REQ_GET_RESOURCES_FINISH:
            return {
                ...state,
                listResources:action.listResources
            };
       
        default:
            return state;
    }
}
