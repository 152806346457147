import { useState, useEffect, useCallback } from 'react';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import debounce from 'lodash/debounce';
import './Filter.scss';
import { STATUS_INSTRUCTOR_OPTION } from '../../constants';
import { useTranslation } from 'react-i18next';

interface IFilter {
    onChangeFilter: (value: any) => void;
    isFirstLoad: boolean;
}

const Filter = ({
    onChangeFilter,
    isFirstLoad,
}: IFilter) => {
    const [keyword, setKeyword] = useDebouncedState('', 0);
    const [statusSelected, setStatusSelected] = useState([]);
    const {t} = useTranslation();
    const updateFilter = useCallback(
        debounce((listFilter) => onChangeFilter(listFilter), 1500),
        []
    );

    useEffect(() => {
        if (!isFirstLoad) {
            let listFilter: any = {
                q: keyword,
                status: statusSelected?.[0]?.value,
            };
            if (statusSelected.length === 2) delete listFilter.status
            updateFilter(listFilter);
        }
    }, [ keyword, statusSelected]);

    return (
        <>
            <div className="FilterInstructor">
                <SearchBox placeholder={t("COURSES.SEARCH_INSTRUCTOR_INPUT")} className="FilterInstructor-searchInput" onSubmit={(value) => setKeyword(value)} />

                <SelectBox
                    label={t("STATUS")}
                    onSelect={setStatusSelected}
                    selectedItems={statusSelected}
                    data={STATUS_INSTRUCTOR_OPTION(t)}
                    total={2}
                    isMulti={false}
                />
            </div>
        </>
    );
};

export default Filter;
