
export const PERFORMANCE_TYPE = {
  lesson: 'Lesson',
  progress: 'Progress',
  gradebook: 'Gradebook'
}

export const COLORS = {
  1: '#00BFD6',
  2: '#2E94E8',
  3: '#21D953',
  4: '#F8BA2D',
  5: '#FF3329',
  6: '#F64B7F'
}