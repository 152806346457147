import React, { FC } from 'react';
import { Row, Column } from 'react-table';
import Table, { RenderTableHeader, RenderTableCell, LinkCustom } from 'components/V2/Table/TableCustom';

type TableCompanyProps = {
    workspaceId: string;
    dataTable: any;
    translator: (key: string) => string;
};

const TableCompany: FC<TableCompanyProps> = ({ workspaceId, dataTable, translator }) => {

    const initialState = {
        hiddenColumns: ['id'],
    };

    const columns = React.useMemo(
        () => [
            { accessor: 'id' },
            {
                Header: () => <RenderTableHeader value={translator('COMPANY_NAME')} />,
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return (
                        <LinkCustom
                            to={`/workspace/${workspaceId}/management/companies/${row.values.id}`}
                            isTooltip
                            value={row.values?.name}
                            disable={row.values.name?.length < 35}
                        />
                    );
                },
                accessor: 'name',
                width: 150,
            },
            {
                Header: () => <RenderTableHeader value={translator('EMAIL')} />,
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return <RenderTableCell value={row.values?.contact_email} />;
                },
                accessor: 'contact_email',
                width: 100,
            },
            {
                Header: () => <RenderTableHeader value={translator('ADDRESS')} />,
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return <RenderTableCell value={row.values?.address} />;
                },
                accessor: 'address',
                width: 150,
            },
            {
                Header: () => <RenderTableHeader value={translator('PHONE')} />,
                Cell: ({ row, column }: { row: any; column: Column }) => {
                    return <RenderTableCell value={row.values?.phone} />;
                },
                accessor: 'phone',
                width: 80,
            },
            {
                Header: () => <RenderTableHeader value={translator('STATUS')} />,
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return (
                        <div className="h-full flex items-center">
                            <p className="capitalize text-white text-xs font-medium px-2 py-1 rounded bg-enterprise">
                                {row.values?.status}
                            </p>
                        </div>
                    );
                },
                accessor: 'status',
                width: 80,
            },
            // {
            //     Header: () => <RenderTableHeader value={translator('ACTION')} />,
            //     Cell: ({ row, column }: { row: Row; column: Column }) => {
            //         return (
            //             <div className="h-full flex items-center">
            //                 <ActionsButton
            //                     styleOptions="top-0 right-[35px]"
            //                     menus={[
            //                         {
            //                             label: translator('EDIT'),
            //                             icon: <PencilAltIcon className="w-4 h-4" />,
            //                             isDisabled: false,
            //                             action: () => {},
            //                         },
            //                         {
            //                             label: translator('DELETE'),
            //                             icon: <TrashIcon className="w-4 h-4" />,
            //                             isDisabled: false,
            //                             action: () => {},
            //                         },
            //                     ]}
            //                 />
            //             </div>
            //         );
            //     },
            //     accessor: 'action',
            //     width: 60,
            // },
        ],
        [dataTable]
    );

    return (
        <Table
            isBorderFirstColum={false}
            data={dataTable}
            columns={columns}
            initialState={initialState}
            emptyContent={translator('NO_COMPANIES')}
        />
    );
};

export default TableCompany;
