
import React from "react";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ConfirmDeleteModal } from "_shared";
import { learningPathDeleteUnit } from "store/features/learningPathSlice";

export const LearningPathUnitDelete = ({isOpen, onClose, values}) => {
    const params: {id: string, learningPathId: string} = useParams();
    const {t: translator} = useTranslation();
    const dispatch = useAppDispatch();

    const handleDelete = (value: boolean) => {
        if (!value) {
            return;
        }
        dispatch(learningPathDeleteUnit({
            workspaceId: params.id,
            id: params?.learningPathId ?? '',
            sectionId: values.id
        }));
    }
    return (
        <ConfirmDeleteModal isOpen={isOpen} onClose={(value: boolean) => {
            handleDelete(value);
            onClose();
        }} titleModal={translator('LEARNING_PATH.LEARNING_PATH_DETAIL.DELETE_UNIT')}>
            <div className="text-sm">{translator('LEARNING_PATH.LEARNING_PATH_DETAIL.MESSAGE_DELETE_UNIT')}</div>
        </ConfirmDeleteModal>
    )
}
