import { FC, useContext, useMemo, useState } from 'react';
import { CourseType } from 'types/Courses.type';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'contexts/User/UserContext';
import { useHistory, useParams } from 'react-router-dom';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import NO_CONTENT from 'assets/img/empty-state/instructor.png';
import dayjs from 'dayjs';
import { ListUser } from '_shared/components/User/User';
import Detail from './ActionMenu';

interface IViewTable {
    data: CourseType[];
    refreshData: Function;
    userData: any
}

const TeachingHistoryTable: FC<IViewTable> = ({ data, refreshData, userData }) => {
    const params: {id: string, userId: string} = useParams();
    const history = useHistory();
    const workspaceId = params?.id;
    const { t } = useTranslation();
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;
    const {
        userState: { result },
    } = useContext(UserContext);

    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={t('USER.TEACHING_HISTORY.SESSION_ID')} />,
                accessor: 'id',
                Cell: ({ row }: any) => <RenderTableCell onClick={() => {setSessionSelected(row?.original); setOpenModalSessionDetail(true)}} value={row.values?.id} className="text-primary-500 font-semibold" />,
                width: "80"
            },
            {
                Header: () => <RenderTableHeader value={t('USER.TEACHING_HISTORY.COURSE_NAME')} />,
                accessor: 'course',
                Cell: ({ row }: any) => <RenderTableCell
                    value={row?.values?.course?.title} />,
                width: "120"
            },
            
            
            {
                Header: () => <RenderTableHeader value={t('USER.TEACHING_HISTORY.DATE')} />,
                accessor: 'start_time',
                Cell: ({ row }) => {
                    return <RenderTableCell value={dayjs.utc(row?.values?.start_time).tz(result?.time_zone)?.format('DD/MM/YYYY')}/>;
                },
                width: "120"
            },

            {
              Header: () => <RenderTableHeader value={t('USER.TEACHING_HISTORY.TIME')} />,
              accessor: 'time',
              Cell: ({ row }) => {
                  return <RenderTableCell value={`${dayjs.utc(row?.original?.start_time).tz(result?.time_zone)?.format("HH:mm")} - ${dayjs.utc(row?.original?.end_time)?.tz(result?.time_zone)?.format("HH:mm")}`}/>;
              },
              width: "120"
            },

            {
              Header: () => <RenderTableHeader value={t('USER.TEACHING_HISTORY.LEARNER')} />,
              accessor: 'learners',
              Cell: ({ row }) => {
                return (
                    <div className="px-2">
                      <ListUser
                          id={'learners'}
                          users={row?.values?.learners?.map((item) => {
                            let link  = `/workspace/${workspaceId}/management/users/${item?.id}?tab=information`;
                            return {
                                name: item?.display_name,
                                avatar: item?.avatar_url,
                                link
                            }
                          })}
                      />
                  </div>
                  );
              },
              width: "120"
            },
            {
                Header: () => <RenderTableHeader value={t('USER.TEACHING_HISTORY.LESSON')} />,
                accessor: 'lesson',
                Cell: ({ row }: any) => {
                    return <RenderTableCell onClick={() => history.push(`/workspace/${workspaceId}/lesson/${row?.values?.lesson?.id}`)} className='text-primary-500' isTooltip value={row?.values?.lesson?.title}/>;
                },
                width: "140"
            },
            {
              Header: () => <RenderTableHeader value={t('USER.STATUS')} />,
              accessor: 'display_status',
              Cell: ({ row }: any) => {
                  return (
                    <div className='text-xs'>
                        {row?.values?.display_status === 'completed' && <div className='text-green-500 bg-green-50 border border-green-300 px-2 py-1 rounded w-fit'>{t('USER.TEACHING_HISTORY.COMPLETED')}</div>}
                        {row?.values?.display_status === 'cancelled' && <div className='text-red-500 bg-red-50 border border-red-300 px-2 py-1 rounded w-fit'>{t('USER.TEACHING_HISTORY.CANCELLED')}</div>}
                    </div>
                  )
              },
              width: "120"
            },
        ],
        [data, WorkspaceDetailInformation]
    );

    const [isOpenModalSessionDetail, setOpenModalSessionDetail] = useState(false);
    const [sessionSelected, setSessionSelected] = useState(false)

    return (
        <>
            <Table
                data={data || []}
                columns={columns}
                emptyContent={t("USER.TEACHING_HISTORY.NO_TEACHING_HISTORIES")}
                emptyIcon={NO_CONTENT}
                classEmpty="min-h-[calc(100vh-635px)]"
            />
            <Detail
                isOpen={isOpenModalSessionDetail}
                onClose={() => setOpenModalSessionDetail(false)} 
                refreshData={refreshData} 
                data={sessionSelected}        
            />
        </>
    );
};

export default TeachingHistoryTable;
