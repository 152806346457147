import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { PlusIcon } from '@heroicons/react/outline';
import { useHistory, useParams } from 'react-router-dom';
import selfBookingService from 'services/selfBooking.service';
import dayjs from 'dayjs';
import { UserContext } from 'contexts/User/UserContext';
import { SessionDateFormat, SessionServiceFormat } from 'constant/util.const';
import './styles.scss';
import UpComingCard from './components/UpComingCard/UpComingCard';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { getLocalTimeZone } from 'utils/commonFun';
import emptyStage from 'assets/img/emptyStage.png';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import ButtonComponent from 'components/Button/ButtonComponent';
import SessionDetailsModal from 'components/V2/SessionDetailModal';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';

const dateFormat = 'YYYY-MM-DD';

const headerToolbar = {
    left: 'today prev,next title',
    right: 'timeGridDay,timeGridWeek,dayGridMonth',
};

export default function BookingClass({ children }) {
    const calendarRef: any = useRef();
    const params = useParams<{ id: string }>();
    const { t: translator } = useTranslation();

    const {
        userState: { result: userInformation },
    } = useContext(UserContext);
    const history = useHistory();

    const tzUser = userInformation?.time_zone || 'UTC';

    const [openModal, setOpenModal] = useState(false);
    const [sessionSelected, setSessionSelected] = useState<any>(false);
    const [loading, setLoading] = useState(false);
    const [sessionsGroupBy, setSessionsGroupBy] = useState([]);
    const [eventsCalendar, setEventsCalendar] = useState<any[]>();
    const [paramsUpcoming, setParamsUpcoming] = useState<any>({
        page: 1,
        per_page: 10,
        start_date: dayjs().tz(tzUser).format(dateFormat),
        end_date: dayjs().endOf('year').tz(tzUser).format(dateFormat),
        group_by: 'start_date',
        start_time: dayjs().format('HH:mm:ss'),
    });
    const [paramsCalendar, setParamsCalendar] = useState<any>();
    const [hasNextPage, setHasNextPage] = useState<boolean>(true);

    const workspaceId = params?.id;

    const getSessions = async (params?: any) => {
        const paramsRequest = {
            sort_by: 'start_time',
            order: 'asc',
            has_student: true,
            has_teacher: true,
            ...params,
        };
        const res = await selfBookingService.getCurrentSession(workspaceId, paramsRequest);
        return res?.data;
    };

    const handleCheckNext = (length: number, total) => {
        if (length < total) {
            setHasNextPage(true);
        } else {
            setHasNextPage(false);
        }
    };

    const fetchEvents = async (params?: any, type = 'calendar', isCancelBooking?: boolean) => {
        const paramsRefresh = {
            page: 1,
            per_page: 10,
            start_date: dayjs().tz(tzUser).format(dateFormat),
            end_date: dayjs().endOf('year').tz(tzUser).format(dateFormat),
            group_by: 'start_date',
            start_time: dayjs().format('HH:mm:ss'),
        };
        // fetch data for Calendar
        if (type === 'calendar') {
            const resCalendar = await getSessions(params);
            setEventsCalendar(
                resCalendar?.items?.map((item) => {
                    const parseStartDate = dayjs(item?.start_time);
                    const parseEndDate = dayjs(item?.end_time);
                    return {
                        ...item,
                        title: item?.my_class?.name,
                        start: parseStartDate.format(SessionServiceFormat),
                        end: parseStartDate.format(SessionServiceFormat),
                        start_time: parseStartDate.format(SessionDateFormat),
                        end_time: parseEndDate.format(SessionDateFormat),
                    };
                }) || []
            );
        }

        // fetch data for Upcoming events
        else {
            setLoading(true);
            const resUpcomingEvents = await getSessions(isCancelBooking ? paramsRefresh : params);
            if (resUpcomingEvents?.page === 1) {
                setSessionsGroupBy(resUpcomingEvents?.items);
                handleCheckNext(resUpcomingEvents?.items?.length, resUpcomingEvents?.total);
            } else {
                setSessionsGroupBy([...sessionsGroupBy, ...resUpcomingEvents?.items]);
                handleCheckNext(resUpcomingEvents?.items?.length + sessionsGroupBy?.length, resUpcomingEvents?.total);
            }
            setParamsUpcoming({
                ...paramsUpcoming,
                page: resUpcomingEvents.page,
                per_page: resUpcomingEvents.per_page,
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEvents(paramsUpcoming, 'upcoming');
    }, []);

    useEffect(() => {
        !!paramsCalendar && fetchEvents(paramsCalendar, 'calendar');
    }, [paramsCalendar]);

    const renderEventContent = (eventInfo) => {
        if (eventInfo?.view?.type === 'timeGridDay' || eventInfo?.view?.type === 'timeGridWeek') {
            return (
                <>
                    <div className="fc-event-main-frame fc-sticky ">
                        <div className="fc-event-title-container">
                            <div className="fc-event-title">{eventInfo?.event?.title}</div>
                        </div>
                        <div className="fc-event-time">
                            {eventInfo?.timeText}
                            <br />
                            {eventInfo?.event.extendedProps?.learning_path?.type_ && translator(`SELF_BOOKING.${eventInfo?.event.extendedProps?.learning_path?.type_}`)}
                        </div>
                    </div>
                </>
            );
        }
    };

    const eventClick = (data) => {
        setSessionSelected({ ...data?.event?._def?.extendedProps, id: data?.event?._def?.publicId });
        setOpenModal(true);
    };

    const loadMoreUpcoming = () => {
        const params = {
            ...paramsUpcoming,
            page: paramsUpcoming.page + 1,
        };
        fetchEvents(params, 'upcoming');
    };
    const [infiniteRef] = useInfiniteScroll({
        loading,
        hasNextPage: hasNextPage,
        onLoadMore: loadMoreUpcoming,
    });

    return (
        <div>
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT')}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    { name: translator('DASHBOARD.SIDEBAR.BOOKING'), href: '', className: '' },
                ]}
            />
            <div className=" p-5 flex space-x-10 items-center  ">
                <div className="w-2/6 ">
                    <h2 className="font-semibold text-base text-ooolab_dark_1 ">{translator('SELF_BOOKING.UPCOMING_EVENTS')}</h2>
                </div>
                <div className="flex justify-end w-4/6 ">
                    <ButtonComponent
                        title={translator('SELF_BOOKING.ADD_BOOKING')}
                        classStyle="primary-button"
                        onClickButton={() => history.push(`/workspace/${workspaceId}/management/booking-class/add-booking`)}
                        icon={<PlusIcon className="w-4 h-4" />}
                    />
                </div>
            </div>
            <div className="flex space-x-10 calendar-wrapper px-5">
                <div className="w-2/6 border-t border-t-ooolab_gray_6 pt-6">
                    {sessionsGroupBy?.length > 0 ? (
                        <div className="upcoming-section space-y-6 py-2 ">
                            {sessionsGroupBy?.map((item, idx) => {
                                return (
                                    <div key={idx}>
                                        <div className="flex items-center justify-between  pb-3 px-3">
                                            <p className="text-sm text-ooolab_282B5E font-medium">
                                                {dayjs(item?.date).format('dddd, MMMM D,  YYYY')}
                                            </p>
                                            <div className="text-xs text-dark-300">{`( ${item?.sessions?.length || 0} ${translator(
                                                'SELF_BOOKING.SESSIONS'
                                            )} )`}</div>
                                        </div>
                                        <div className="px-3">
                                            {item?.sessions?.map((session) => {
                                                return (
                                                    <UpComingCard
                                                        key={session.id}
                                                        data={session}
                                                        handleModal={(data) => {
                                                            setSessionSelected(data);
                                                            setOpenModal(true);
                                                        }}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                            {hasNextPage && (
                                <div className="w-full flex items-center justify-center text-center py-2" ref={infiniteRef}>
                                    {loading && <CircleSpin color="text-[#024FEE]" />}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="h-[60vh] flex items-center justify-center">
                            <NoContentWithImage content="No Events" img={emptyStage} styleImg="max-w-[240px]" />
                        </div>
                    )}
                </div>

                <div className="w-4/6 border-t  border-t-ooolab_gray_6 pt-6 ">
                    <div className="custom-calendar booking-calendar">
                        <FullCalendar
                            ref={calendarRef}
                            allDaySlot={false}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            headerToolbar={headerToolbar}
                            eventClick={(event) => {
                                eventClick(event);
                            }}
                            events={eventsCalendar}
                            eventContent={renderEventContent}
                            locale="en"
                            dayMaxEvents={3}
                            forceEventDuration={false}
                            eventMaxStack={3}
                            moreLinkText={translator('OTHERS')}
                            slotEventOverlap={false}
                            timeZone={userInformation?.time_zone || getLocalTimeZone()}
                            datesSet={(arg) => {
                                const { startStr, endStr } = arg;
                                setParamsCalendar({
                                    start_date: dayjs(startStr).format('YYYY-MM-DD'),
                                    end_date: dayjs(endStr).format('YYYY-MM-DD'),
                                    skip_pagination: true,
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
            {openModal && (
                <SessionDetailsModal
                    open={openModal}
                    onCloseModal={() => setOpenModal(false)}
                    sessionId={sessionSelected?.id}
                    classId={sessionSelected?.my_class?.id}
                    refreshData={() => {
                        fetchEvents(paramsCalendar, 'calendar');
                        fetchEvents(paramsUpcoming, 'upcoming', true);
                    }}
                />
            )}
        </div>
    );
}
