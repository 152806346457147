import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ButtonPrimary, ButtonOutline, Toastify } from '_shared';
import { InputField, PhoneInputField, TextareaField } from 'components/InputForm/InputForm';
import { WorkspaceService } from 'services';
import uploadService from 'services/upload.service';
import DialogComponent from 'components/Dialog/DialogComponent';
import { useBoolean } from 'hooks/custom';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { GET_WORKSPACE_DETAIL } from 'actions/workspace.action';
import { PreviewFullScreen } from 'components/V2/Modals/PreviewFullScreen';
import { PreviewInvoice } from './PreviewInvoice';
import { PreviewReceipt } from './PreviewReceipt';
import DefaultImage from 'assets/img/defaultThumb.png';
import { FORM_CONST } from 'constant/form.const';

export const InvoiceReceiptInformation = () => {
    const { t } = useTranslation();
    const params: { id: string } = useParams();
    const uploadRef = useRef<HTMLInputElement>(null);

    const { booleanValue: confirmCreate, toggleBooleanValue: toggleConfirmCreate } = useBoolean();
    const { booleanValue: previewInvoiceReceipt, toggleBooleanValue: togglePreviewInvoiceReceipt } = useBoolean();
    const { booleanValue: uploadAvatarModal, toggleBooleanValue: toggleUploadAvatarModal } = useBoolean();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { dispatch, getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: workspaceDetailInformation } = getWorkspaceDetailState;

    const {
        control,
        handleSubmit,
        register,
        formState: { errors, isValid, isDirty },
        reset,
        getValues,
        watch,
        setValue,
        clearErrors,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            company_name: '',
            company_logo: '',
            company_address: '',
            company_tax_code: '',
            image: null,
            support_phone: '',
            support_email: '',
        },
    });

    useEffect(() => {
        if (workspaceDetailInformation) {
            reset({
                company_name: workspaceDetailInformation?.company_name || '',
                company_logo: workspaceDetailInformation?.company_logo_url || '',
                company_address: workspaceDetailInformation?.company_address?.replaceAll('<br />', '\n') || '',
                company_tax_code: workspaceDetailInformation?.company_tax_code || '',
                image: watch('image') || null,
                support_phone: workspaceDetailInformation?.support_phone || '',
                support_email: workspaceDetailInformation?.support_email || '',
            });
        }
    }, [workspaceDetailInformation]);

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`E_COMMERCE.${str}`, opt));

    const onUpdateWorkspace = (payload) => {
        WorkspaceService.updateWorkspaceInformation(params?.id, payload)
            .then((res) => {
                Toastify.success();
                dispatch({
                    type: GET_WORKSPACE_DETAIL.REQ_GET_WORKSPACE_DETAIL_SUCCESS,
                    result: res,
                });
            })
            .catch((err) => {
                const fieldsError: any[] = err?.error?.body_params?.map(el => {
                    return {
                        field: el?.loc?.toString(),
                        msg: el?.msg,
                        type: el?.type,
                    }
                }) || [];

                if (err?.error?.name === "VALIDATION_ERROR" && !!fieldsError?.length) {
                    if (fieldsError?.find(el => el?.field === 'support_phone')) {
                        Toastify.error(t('FORM_CONST.PHONE_NUMBER'));
                    } else Toastify.error();
                } else Toastify.error();
            })
            .finally(() => setIsLoading(false));
    };

    const checkImageUpload = (file: any) => {
        clearErrors('image');
        const mimeType = ['image/jpeg', 'image/jpg', 'image/png'];
        const limitFileSize = 1 * 1024 * 1024;

        const fileSize = file?.size || 0;
        const fileExtension = file?.type || '';

        if (!mimeType.includes(fileExtension) || fileSize > limitFileSize) {
            Toastify.error(
                t('WARNING_UPLOAD', {
                    fileSize: '1MB',
                    fileType: 'JPG, PNG',
                })
            );
        } else {
            setValue('image', file, { shouldDirty: true });
        }
    };

    const onConfirm = (values) => {
        toggleConfirmCreate();
    };

    const onSubmitForm = () => {
        toggleConfirmCreate();
        setIsLoading(true);
        try {
            let payload: any = {
                ...getValues(),
                support_phone: '+84902344344',
                support_email: getValues()?.support_email || null,
                company_address: getValues()?.company_address?.replaceAll('\n', '<br />')
            };
            if (payload?.image) {
                uploadService.uploadFile(
                    payload?.image,
                    (path: string) => {
                        payload = {
                            ...payload,
                            company_logo: path,
                        };
                        delete payload?.image;
                        onUpdateWorkspace(payload);
                    },
                    () => { }
                );
            } else {
                delete payload?.image;
                onUpdateWorkspace({
                    ...payload,
                    company_logo: payload?.company_logo?.includes(process.env.REACT_APP_HOST_CDN)
                        ? payload?.company_logo?.replace(process.env.REACT_APP_HOST_CDN, '')
                        : '',
                });
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(onConfirm)} className="rounded-2xl p-5 bg-white grid grid-cols-2 gap-x-4 gap-y-5 font-normal">
                <div className="col-span-2 flex justify-between items-center -mb-1">
                    <h2 className="text-gray-800 font-semibold text-xl">{translator('INVOICE_RECEIPT_INFORMATION')}</h2>
                    <div className="flex items-center space-x-4">
                        <ButtonOutline
                            onClick={togglePreviewInvoiceReceipt}
                            type="button"
                            className="!text-enterprise !border-enterprise"
                        >
                            {t('PREVIEW')}
                        </ButtonOutline>
                        <ButtonPrimary
                            disabled={!isDirty || isLoading}
                            type="submit"
                            className={`${isLoading ? 'bg-ooola_EFF0FA text-ooolab_8083A3 cursor-not-allowed' : ''}`}
                        >
                            {t('SAVE')}
                        </ButtonPrimary>
                    </div>
                </div>
                <InputField
                    label={translator('COMPANY_NAME')}
                    name="company_name"
                    className=""
                    placeholder={t('ENTER') + translator('COMPANY_NAME')}
                    errors={errors}
                    register={register('company_name', {
                        // required: t('FORM_CONST.REQUIRED_FIELD'),
                    })}
                    maxLength={255}
                />
                <InputField
                    label={translator('COMPANY_TAX_ID')}
                    name="company_tax_code"
                    className=""
                    placeholder={t('ENTER') + translator('COMPANY_TAX_ID')}
                    errors={errors}
                    register={register('company_tax_code', {
                        // required: t('FORM_CONST.REQUIRED_FIELD'),
                    })}
                    maxLength={255}
                />
                <div className="">
                    <p className="text-xs text-gray-800 mb-1 font-semibold">{translator('COMPANY_LOGO')}</p>
                    <div className="flex items-center">
                        <img
                            src={(watch('image') && URL.createObjectURL(watch('image'))) || watch('company_logo') || DefaultImage}
                            alt={'logo'}
                            className={`block object-contain h-20 w-20 flex-none ${!watch('image') && !watch('company_logo') ? 'rounded-full object-cover p-2.5 bg-gray-100' : ''
                                }`}
                        />
                        <div className="flex flex-col ml-4 items-start">
                            <input
                                ref={uploadRef}
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                className="hidden"
                                placeholder={''}
                                onChange={(e) => checkImageUpload?.(e?.target?.files?.[0])}
                            />
                            <button
                                className="max-h-8 rounded bg-primary-500 px-3 py-1 text-sm text-white font-semibold focus:outline-none"
                                onClick={() => uploadRef?.current?.click()}
                                type="button"
                            >
                                {t('DASHBOARD.WORKSPACE_SETTING.CHANGE_PHOTO')}
                            </button>
                            <label className={'text-gray-400 text-xs mt-2'}>{translator('PNG_JPG_ACCEPTED_MAX_FILE_SIZE_1MB')}</label>
                        </div>
                    </div>
                </div>
                <TextareaField
                    label={translator('COMPANY_ADDRESS')}
                    name="company_address"
                    className=""
                    placeholder={t('ENTER') + translator('COMPANY_ADDRESS')}
                    register={register('company_address', {
                        // required: t('FORM_CONST.REQUIRED_FIELD'),
                    })}
                    textLength={watch('company_address')?.length}
                    maxLength={500}
                    isCountter
                    errors={errors}
                />
                <PhoneInputField
                    name="support_phone"
                    control={control}
                    label={translator('SUPPORT_PHONE')}
                    placeholder={t('ENTER') + translator('SUPPORT_PHONE')}
                    errors={errors}
                    rules={{
                        pattern: {
                            value: FORM_CONST.PHONE_NUMBER,
                            message: t('FORM_CONST.PHONE_NUMBER'),
                        }
                    }}
                />
                <InputField
                    label={translator('SUPPORT_EMAIL')}
                    name="support_email"
                    className=""
                    placeholder={t('ENTER') + translator('SUPPORT_EMAIL')}
                    errors={errors}
                    register={register('support_email', {
                        pattern: {
                            value: FORM_CONST.EMAIL_REGEX,
                            message: t('FORM_CONST.EMAIL_VALIDATE'),
                        },
                    })}
                    maxLength={255}
                />

                <DialogComponent
                    isOpen={confirmCreate}
                    onCloseModal={toggleConfirmCreate}
                    title={t('CONFIRMATION')}
                    child={
                        <div className="mt-4">
                            <div>{translator('CONFIRM_INFO')}</div>
                            <div className="mt-5 flex justify-center gap-5">
                                <ButtonOutline type="button" onClick={toggleConfirmCreate}>
                                    {t('CANCEL')}
                                </ButtonOutline>
                                <ButtonPrimary type="button" className="!border-white !bg-enterprise" onClick={onSubmitForm}>
                                    {t('CONFIRM')}
                                </ButtonPrimary>
                            </div>
                        </div>
                    }
                />
            </form>
            <PreviewFullScreen
                isOpen={previewInvoiceReceipt}
                closeModal={togglePreviewInvoiceReceipt}
                title={translator('INVOICE_RECEIPT_PREVIEW')}
                children={
                    <div className="flex flex-col space-y-6 pb-[60px]">
                        <PreviewInvoice {...getValues()} />
                        <PreviewReceipt {...getValues()} />
                    </div>
                }
            />
        </>
    );
};
