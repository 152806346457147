import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { InputFile } from "_shared/components/Form/InputFile/InputFile";
import { SelectField } from "components/Select";
import { typeOption } from "components/Academics/LearningPath/LearningPathDetail/learningPath.consts";
import { InputField, TextareaField } from "components/InputForm/InputForm";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { Toastify } from "_shared";
import { useParams } from "react-router-dom";
import { VideoUpload } from "assets/icon";
import { checkPermission, createOption } from "utils/commonFun";
import { CourseFieldData } from "../CourseFieldData/CourseFieldData";
import { courseTemplateUpdate } from "store/features/courseTemplateSlice";
import uploadService from "services/upload.service";
import "./CourseInformation.scss";

export const TemplateInformation = () => {
    const params: { id: string, courseId: string } = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { data, changed, error } = useAppSelector((state) => state.courseTemplate);

    const translatorlearning = (str: string, newKey?: boolean, opt?: any) => {
        return newKey ? t(str, opt) : t(`LEARNING_PATH.LEARNING_PATH_DETAIL.${str}`, opt)
    }

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        reset,
        watch
    } = useForm({
        mode: "onSubmit",
        defaultValues: useMemo(() => {
            return {
                template_name: data?.template_name,
                template_description: data?.template_description,
                title: data?.title,
                short_description: data?.short_description,
                type: null,
                level: [],
                subject: [],
                age: [],
                language_delivery: [],
                duration: [],
                tags: [],
                id: null
            }
        }, [data])
    })

    const onSubmit = async (value) => {
        dispatch(courseTemplateUpdate({
            workspaceId: params?.id,
            id: params?.courseId,
            title: value?.title,
            short_description: value?.short_description,
            template_name: value?.template_name,
            template_description: value?.template_description
        }));
    }

    const handleAddTags = (name, values) => {
        setValue(name, values);
    }

    const checkImageUpload = (file: any) => {
        const mimeType = ['video/mp4'];
        const limitFileSize = 100 * 1024 * 1024;

        const fileSize = file?.size || 0;
        const fileExtenion = file?.type || '';

        if (!mimeType.includes(fileExtenion) || fileSize > limitFileSize) {
            Toastify.error(t('LEARNING_PATH.LEARNING_PATH_DETAIL.WARNING_VIDEO'))
        } else {
            uploadService.uploadFile(
                file,
                async (path: string) => {
                    dispatch(courseTemplateUpdate({
                        workspaceId: params?.id,
                        id: params?.courseId,
                        video: path,
                        title: data?.title,
                    }))
                },
                () => { }
            );
        }
    };

    useEffect(() => {
        if (data?.title) {
            let typeSelect = [];
            if (data?.type?.length > 0) {
                typeSelect = typeOption(translatorlearning).filter(item => data?.type?.find(value => value == item?.value));
            }
            reset({
                ...data,
                type: typeSelect,
                level: data?.level?.map(item => createOption({ name: item, id: item })),
                subject: data?.subject?.map(item => createOption({ name: item, id: item })),
                age: data?.age?.map(item => createOption({ name: item, id: item })),
                language_delivery: data?.language_delivery?.map(item => createOption({ name: item, id: item })),
                duration: data?.duration?.map(item => createOption({ name: item, id: item })),
                tags: data?.tags?.map(item => createOption({ name: item, id: item }))
            });
        }
    }, [data?.template_name, t]);

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === "type" && value?.type?.length > 0) {
                dispatch(courseTemplateUpdate({
                    workspaceId: params?.id,
                    id: params?.courseId != 'new' ? params?.courseId : value?.id,
                    type: value?.type?.map(item => item.value),
                    title: value?.template_name,
                    description: value?.short_description
                }));
            }
            if (['level', 'subject', 'age', 'language_delivery', 'duration', 'tags'].includes(name)) {
                const payload = {
                    workspaceId: params?.id,
                    id: params?.courseId,
                }
                payload[name] = value[name]?.map(item => item?.label ?? item);
                dispatch(courseTemplateUpdate(payload));
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, params?.courseId]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="courseInformation courseInformation-templateCard mb-5">
                <div className="courseBuilder-title">{t('Template Information')}</div>
                <div className="max-w-[608px]">
                    <div className="courseInformation-item">
                        <InputField
                            label={t('COURSE_TEMPLATE.Template_Name')}
                            name="template_name"
                            isRequired
                            maxLength={255}
                            placeholder={t('COURSE_TEMPLATE.Enter_Template_Name')}
                            register={register('template_name', {
                                required: t('FORM_CONST.REQUIRED_FIELD'),
                                validate: {
                                    shouldNotContainSpace: (value) => {
                                        return value.trim()?.length ? true : `${t('FORM_CONST.REQUIRED_FIELD')}`;
                                    },
                                }
                            })}
                            errors={errors}
                            classNameInput="py-2"
                            handleBlur={handleSubmit(onSubmit)}
                            disabled={!checkPermission('courseInformation', 'U')}
                        />
                    </div>
                    <div className="courseInformation-item !mb-0">
                        <TextareaField
                            label={t('COURSE_TEMPLATE.Template_Description')}
                            name="description"
                            className="col-span-4"
                            placeholder={t('COURSE_TEMPLATE.Enter_Template_Description')}
                            register={register('template_description')}
                            textLength={getValues("template_description")?.length?.toString()}
                            maxLength={250}
                            isCountter
                            handleBlur={handleSubmit(onSubmit)}
                            disabled={!checkPermission('courseInformation', 'U')}
                        />
                    </div>
                </div>
            </div>
            <div className="courseInformation courseInformation-templateCard">
                <div className="courseBuilder-title">{t('COURSES.INFORMATION')}</div>
                <div className="max-w-[608px]">
                    <div className="courseInformation-item">
                        <label className="courseInformation-label">
                            {t('COURSES.INTRODUCE_VIDEO')}
                        </label>
                        <InputFile
                            onChange={checkImageUpload}
                            imgDefault={data?.video}
                            className="h-[240px]"
                            icon={<VideoUpload />}
                            textHelp=".MP4 accepted | Max file size 100MB"
                            hasVideo={true}
                            isDisable={!checkPermission('courseInformation', 'U')}
                        />
                    </div>
                    <div className="courseInformation-item hidden">
                        <InputField
                            label={t('COURSES.COURSE_NAME')}
                            name="title"
                            maxLength={255}
                            placeholder={t('COURSES.COURSE_NAME')}
                            register={register('title', {
                                validate: {
                                    shouldNotContainSpace: (value) => {
                                        return value.trim()?.length ? true : `${t('FORM_CONST.REQUIRED_FIELD')}`;
                                    },
                                }
                            })}
                            errors={errors}
                            classNameInput="py-2"
                            handleBlur={handleSubmit(onSubmit)}
                            disabled={!checkPermission('courseInformation', 'U')}
                        />
                    </div>
                    <div className="courseInformation-item">
                        <SelectField
                            control={control}
                            label={t('COURSES.COURSE_TYPE')}
                            name="type"
                            placeholder={t("COURSES.SELECT_COURSE_TYPE")}
                            isSearchable={false}
                            isMulti={true}
                            options={typeOption(translatorlearning)}
                            customStyles={{
                                control: (base) => {
                                    return { ...base, minHeight: '0px', height: 'auto' }
                                },
                                valueContainer: (base) => {
                                    return { ...base, paddingTop: 0, fontSize: 13, fontWeight: 600 }
                                },
                                indicatorsContainer: (base) => {
                                    return { ...base, padding: '0px 8px', height: '30px' }
                                },
                            }}
                            errors={errors}
                            isDisabled={!checkPermission('courseInformation', 'U')}
                        />
                    </div>
                    <div className="courseInformation-item">
                        <TextareaField
                            label={t('COURSES.DESCRIPTION')}
                            name="description"
                            className="col-span-4"
                            placeholder={t('COURSES.ENTER_DESCRIPTION')}
                            register={register('short_description')}
                            textLength={getValues("short_description")?.length?.toString()}
                            maxLength={250}
                            isCountter
                            handleBlur={handleSubmit(onSubmit)}
                            disabled={!checkPermission('courseInformation', 'U')}
                        />
                    </div>
                    <div className="courseInformation-item">
                        <CourseFieldData
                            label={t('COURSES.LEVEL')}
                            control={control}
                            name="level"
                            onChangeValue={handleAddTags}
                            values={watch('level') ?? []}
                            isDisabled={!checkPermission('courseInformation', 'U')}
                        />
                    </div>
                    <div className="courseInformation-item">
                        <CourseFieldData
                            label={t('COURSES.SUBJECT')}
                            control={control}
                            name="subject"
                            onChangeValue={handleAddTags}
                            values={watch('subject') ?? []}
                            isDisabled={!checkPermission('courseInformation', 'U')}
                        />
                    </div>
                    <div className="courseInformation-item">
                        <CourseFieldData
                            label={t('COURSES.AGE_GROUP')}
                            control={control}
                            name="age"
                            onChangeValue={handleAddTags}
                            values={watch('age') ?? []}
                            isDisabled={!checkPermission('courseInformation', 'U')}

                        />
                    </div>
                    <div className="courseInformation-item">
                        <CourseFieldData
                            label={t('COURSES.LANGUAGE_DELIVERY')}
                            control={control}
                            name="language_delivery"
                            onChangeValue={handleAddTags}
                            values={watch('language_delivery') ?? []}
                            isDisabled={!checkPermission('courseInformation', 'U')}
                        />
                    </div>
                    <div className="courseInformation-item">
                        <CourseFieldData
                             label={t('COURSES.DURATION')}
                            control={control}
                            name="duration"
                            onChangeValue={handleAddTags}
                            values={watch('duration') ?? []}
                            isDisabled={!checkPermission('courseInformation', 'U')}

                        />
                    </div>
                    <div className="courseInformation-item">
                        <CourseFieldData
                            label={t('COURSES.TAGS')}
                            control={control}
                            name="tags"
                            onChangeValue={handleAddTags}
                            values={watch('tags') ?? []}
                            isDisabled={!checkPermission('courseInformation', 'U')}
                        />
                    </div>
                </div>
            </div>
        </form>
    )
}