import SesstionIcon from 'assets/icon/SesstionIcon';
import { useTranslation } from 'react-i18next';

export default function MultipleSession({ numberOfSession }) {
    const {t: translator} = useTranslation();
    return (
        <div  key={`listSession_${numberOfSession}`} 
            className={`multi-event flex p-1 border border-cyan-300 bg-cyan-50  overflow-hidden items-start gap-1 text-xs font-light w-auto rounded h-[96%]`}
        >
            <div className="multi-event-text items-center gap-1 flex text-cyan-500">
                <SesstionIcon /> <span className="font-semibold">{numberOfSession + 1}</span> {translator('SESSIONS')}
            </div>
        </div>
    );
}
