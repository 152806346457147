import React from 'react';
import ManagementMasterPage from 'pages/RouteMasterPage';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';
import { CertificateList } from 'components/Management/CertificateManagement/CertificateList';

const CertificatesPage = () => {
    return (
        <ManagePermissionWrapper>
            <CertificateList>
                <ManagementMasterPage  />
            </CertificateList>
        </ManagePermissionWrapper>
    );
};

export default CertificatesPage;
