import { FC, useContext, useMemo } from 'react';
import { CourseType } from 'types/Courses.type';
import { useTranslation } from 'react-i18next';
import ActionMenu from './ActionMenu';
import NO_CONTENT from 'assets/img/empty-state/certificateEmpty.png';
import { useParams } from 'react-router-dom';
import { UserContext } from 'contexts/User/UserContext';
import { User } from '_shared/components/User/User';
import Table, { RenderTableCell, RenderTableHeader } from 'components/V2/Table/TableCustomMultipleSelect';

interface IViewTable {
    data: CourseType[];
    refreshData: Function;
    setSelectedFiles?: React.Dispatch<React.SetStateAction<any[]>>
    selectedFiles?: any;
}

const CourseCertificateTable: FC<IViewTable> = ({ data, refreshData, selectedFiles, setSelectedFiles }) => {
    const { t: translator } = useTranslation();

    const params: { id: string } = useParams();
    const {
        userState: { result },
    } = useContext(UserContext);
    const initialState = {
        hiddenColumns: ['id'],
    };

    const handleClick = (item: any, event: any) => {
        const isShiftClick = event?.shiftKey;
        if (isShiftClick) {
            const handleCheckItem = selectedFiles?.find((el) => el?.id === item?.id);
            if (handleCheckItem && selectedFiles?.length > 0) {
                const newArr = selectedFiles?.filter((el) => el?.id !== item?.id) || [];
                setSelectedFiles(newArr);
            } else setSelectedFiles([...selectedFiles, item]);
        } else {
            setSelectedFiles([item]);
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('COURSES.CERTIFICATE_NAME')} />,
                Cell: ({ row }: { row: any }) => {
                    return (
                        <div onClick={(e) => handleClick(row?.original, e)}>
                            <RenderTableCell
                                className="text-gray-800 font-normal"
                                value={row.original?.name}
                            />
                        </div>
                    );
                },
                accessor: 'name',
                width: '240',
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.LEANER')} />,
                accessor: 'learner',
                Cell: ({ row }: any) => {
                    return (
                        <div className='py-2'>
                            <User
                                link={`/workspace/${params?.id}/management/users/${row?.values?.learner?.id}?tab=information`}
                                avatar={row?.values?.learner?.avatar_url}
                                name={row?.values?.learner?.display_name}
                            />
                        </div>
                    );
                },
                width: '140',
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.OVERALL_GRADE')} />,
                Cell: ({ row }) => {
                    return (
                        <RenderTableCell
                            className="text-gray-800 font-normal"
                            value={row.values?.overall ? row.values?.overall + '%' : '-'}
                        />
                    );
                },
                accessor: 'overall',
                width: '100',
            },

            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="h-full flex items-center justify-center">
                            <ActionMenu 
                                data={row?.original} 
                                refreshData={refreshData}
                                position={row?.index > data?.length/2 ? 'bottom-0' : ''}    
                            />
                        </div>
                    );
                },
                accessor: 'action',
                width: 60,
            },
        ],
        [data, selectedFiles?.length]
    );

    return (
        <Table
            isDisableEvent
            data={data || []}
            columns={columns}
            initialState={initialState}
            emptyContent={translator('COURSES.NO_CERTIFICATE')}
            emptyIcon={NO_CONTENT}
            listSelected={selectedFiles}
            isMultiSelected
        />
    );
};

export default CourseCertificateTable;
