export const MessageIcon = ({
    width = '24px',
    height = '24px',
    fill = '#F64B7F',
}: {
    width?: string;
    height?: string;
    fill?: string;
}) => {
    return (
        <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.666687 1.99967C0.666687 1.0792 1.41288 0.333008 2.33335 0.333008H15.6667C16.5872 0.333008 17.3334 1.0792 17.3334 1.99967V11.1663C17.3334 12.0868 16.5872 12.833 15.6667 12.833H11.8452L9.58928 15.0889C9.26384 15.4144 8.7362 15.4144 8.41077 15.0889L6.15484 12.833H2.33335C1.41288 12.833 0.666687 12.0868 0.666687 11.1663V1.99967ZM15.6667 1.99967H2.33335V11.1663H6.50002C6.72103 11.1663 6.933 11.2541 7.08928 11.4104L9.00002 13.3212L10.9108 11.4104C11.067 11.2541 11.279 11.1663 11.5 11.1663H15.6667V1.99967ZM4.00002 4.91634C4.00002 4.4561 4.37312 4.08301 4.83335 4.08301H13.1667C13.6269 4.08301 14 4.4561 14 4.91634C14 5.37658 13.6269 5.74967 13.1667 5.74967H4.83335C4.37312 5.74967 4.00002 5.37658 4.00002 4.91634ZM4.00002 8.24967C4.00002 7.78944 4.37312 7.41634 4.83335 7.41634H9.83335C10.2936 7.41634 10.6667 7.78944 10.6667 8.24967C10.6667 8.70991 10.2936 9.08301 9.83335 9.08301H4.83335C4.37312 9.08301 4.00002 8.70991 4.00002 8.24967Z"
                fill={fill}
            />
        </svg>
    );
};
