import { InputField, TextareaField } from "components/InputForm/InputForm";
import { AsyncSelectField, SelectField } from "components/Select";
import { PropertyType } from "constant/property.const";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom";
import propertyService from "services/property.service";
import { ButtonOutline, ButtonPrimary, Toastify } from "_shared";

export const CreateNewProperty = ({ ...props }) => {
    const params: { id: string } = useParams();
    const { t } = useTranslation();
    const { onClose, type = 'course' } = props;
    const history = useHistory();
    const {
        control,
        register,
        handleSubmit,
        formState: { errors, isValid },
        getValues,
        watch,
        setError
    } = useForm({
        mode: "onChange"
    })
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (value) => {
        try {
            setIsLoading(true);
            let payload = {
                ...value,
                object_type: type,
                property_type: value?.property_type?.value,
                parent_id: value?.parent_id?.value ?? null
            }
            const res = await propertyService.create(params?.id, payload);
            history.push(`/workspace/${params?.id}/setting?tab=property&subtab=${type}&id=${payload?.parent_id ?? res?.data?.id}${payload?.parent_id ? `&idSelected=${res?.data?.id}` : ''}`);
            onClose?.();
        } catch (error) {
            if (error?.response?.data?.error?.body_params.length > 0) {
                const errorDuplicate = error?.response?.data?.error?.body_params.find(item => item.msg?.indexOf("DUPLICATE_PROPERTY_NAME") != -1 || item.msg?.indexOf("Please select a different name"));
                if (errorDuplicate) {
                    return setError("name", {
                        type: "manual",
                        message: t('DUPLICATE_PROPERTY_NAME', {
                            name: watch('name')
                        })
                    });
                }
            }
            Toastify.error(error?.message ?? '');
        } finally {
            setIsLoading(false);
        }
    }

    const handleLoadParent = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional.page,
            per_page: 20,
            q: inputValue || null,
            object_type: type
        };
        if (!payload?.name) delete payload.name;
        const response: any = await propertyService.get(params.id, payload);
        if (!response.data) return;
        const { items, total } = response.data;
        const newOptions = items
            .map((item) => {
                if (item?.children?.length > 0) {
                    return [item, ...item?.children];
                }
                return item;
            })
            .flat(1)
            .map((el: any) => ({
                id: el.id,
                label: el.name,
                value: el.id,
            }));

        return {
            options: newOptions,
            hasMore: additional.page < total / 20,
            additional: {
                page: additional.page + 1,
            },
        };
    };

    return (
        <form className="flex flex-col gap-5 mt-2" onSubmit={handleSubmit(onSubmit)} noValidate>
            <InputField
                label={t('Property Name')}
                name="name"
                isRequired
                maxLength={255}
                placeholder={t('Enter Field Name')}
                classNameInput="py-2.5"
                register={register('name', {
                    required: t('FORM_CONST.REQUIRED_FIELD'),
                    validate: {
                        shouldNotContainSpace: (value) =>
                            !!value.trim()
                                ? true
                                : t('FORM_CONST.REQUIRED_FIELD'),
                    },
                    maxLength: {
                        value: 255,
                        message: t('FORM_CONST.MAX_LENGTH_250')
                    },
                })}
                errors={errors}
            />
            <SelectField
                control={control}
                isRequired
                label={t('Property Type')}
                name="property_type"
                placeholder={t("Select an option")}
                isSearchable={false}
                options={PropertyType}
                errors={errors}
            />
            <AsyncSelectField
                control={control}
                label={t('Parent Property')}
                name="parent_id"
                placeholder={t("Select an option")}
                hideSelectedOptions
                closeMenuOnSelect
                errors={errors}
                loadOptions={handleLoadParent}
                searchIcon={true}
                labelSplit={true}
            />
            <TextareaField
                label={t('Description')}
                name="description"
                className="col-span-4"
                placeholder={t('Enter Description')}
                register={register('description')}
                textLength={getValues("description")?.length?.toString()}
                maxLength={1000}
                isCountter
            />
            <div className="flex justify-center gap-4">
                <ButtonOutline type='button'
                    onClick={() => onClose?.()}>
                    {t('Cancel')}
                </ButtonOutline>
                <ButtonPrimary type='submit'
                    onClick={() => { }}
                    disabled={!isValid}
                    loading={isLoading}
                >
                    {t('Create')}
                </ButtonPrimary>
            </div>

        </form>
    )
}
