import { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import courseService from 'services/course.service';
import { useDebouncedState } from 'hooks/useDebounce';
import { ButtonOutline, ButtonPrimary, LoadingPopup, Toastify } from '_shared';
import SearchBox from 'components/V2/SearchBox';
import Pagination from 'components/V2/Pagination';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { TableResources } from './components/ResourcesTab/TableResources';
import { useParams } from 'react-router-dom';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import { PopoverComponent } from 'components/V2/Popover/PopoverComponent';
import FileUploadIcon from 'assets/icon/FileUploadIcon';
import LIBRARY from 'assets/img/library.png';
import DialogComponent from 'components/Dialog/DialogComponent';
import { useBoolean } from 'hooks/custom';
import FromLibrary from 'components/Library/LessonBuilder/components/Content/FromLibrary/FromLibrary';
import libraryService from 'services/library.service';
import { onDownload } from 'components/Library/LibraryContants';
import { IParamUploadContext, UploadContext } from 'contexts/Upload';
import { acceptContentType, accept_file_upload_library } from 'constant/form.const';
import { DocumentPreview } from 'components/Library/components/DocumentPreview/DocumentPreview';

const acceptContentTypeUpload = accept_file_upload_library.filter(
    (acceptFile) =>
        acceptFile !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && acceptFile !== 'application/vnd.ms-excel'
);

export const CourseResources = () => {
    const params: { id: string; courseId: string } = useParams();
    const { t: translator } = useTranslation();
    const [keyword, setKeyword] = useDebouncedState('', 0);
    const fileInputRef = useRef(null);

    const { booleanValue: modalAddFromLibrary, toggleBooleanValue: toggleModalAddFromLibrary } = useBoolean();
    const { booleanValue: modalConfirmDelete, toggleBooleanValue: toggleModalConfirmDelete } = useBoolean();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [indexPage, setIndexPage] = useState('0-0');
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'updated_at',
        total: 0,
    });
    const [selectedResource, setSelectedResource] = useState<any>(null);
    const [fileDetail, setFileDetail] = useState<any>(null);
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false);

    const uploadContext: { handleUploadFile: (params?: IParamUploadContext, handleAfterUploadFile?: any) => void } = useContext(UploadContext);

    const workspaceId = params.id;
    const courseId = params.courseId;

    const fetchResources = (_page = 1) => {
        setLoading(true);
        const payload = {
            ...pagination,
            page: _page || pagination.page,
            q: keyword,
        };
        if (!payload?.q) delete payload?.q
        delete payload?.total;

        courseService
            .getCourseResources({ workspaceId, courseId }, payload)
            .then((res) => {
                const { items, total, page } = res.data;
                setData(items);
                setPagination({ ...pagination, page, total });
                setIndexPage(getIndexPage(payload?.page, pagination?.per_page, items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const uploadResource = (bodyParams, onSuccess, onError) => {
        return courseService
            .uploadCourseResource({ workspaceId, courseId }, bodyParams)
            .then(() => {
                fetchResources();
                Toastify.success();
                onSuccess?.(bodyParams);
            })
            .catch((err) => {
                onError?.(bodyParams);
                if (err?.response?.data?.error?.body_params?.[0]?.msg === 'INVALID_TYPE') {
                    Toastify.error(translator('FORM_CONST.FILE_TYPE_IS_NOT_ALLOWED'));
                }else Toastify.error(); 
            });
    };

    const importResource = (bodyParams) => {
        courseService
            .importCourseResource({ workspaceId, courseId }, bodyParams)
            .then((res) => {
                fetchResources();
                Toastify.success();
            })
            .catch((err) => {
                if (err?.response?.data?.error?.body_params?.[0]?.msg === 'INVALID_TYPE') {
                    Toastify.error(translator('FORM_CONST.FILE_TYPE_IS_NOT_ALLOWED'));
                } else Toastify.error(translator('COURSES.ACADEMICS.MESSAGE_UPLOAD_RESOURCE_FAIL'));
            });
    };

    const deleteResource = (resourceId) => {
        toggleModalConfirmDelete();
        setFileDetail(null);
        setSelectedResource(null);
        courseService
            .deleteCourseResourceId({ workspaceId, courseId, resourceId })
            .then(() => {
                fetchResources();
                Toastify.success();
            })
            .catch(() => Toastify.error());
    };

    const getFileDetail = (objectId) => {
        libraryService.getDetailsFile(workspaceId, objectId).then((res) => {
            const formatData = {
                ...res.data,
                contentType: res.data?.object_type,
                url: res.data?.file,
            };
            setFileDetail(formatData);
        });
    };

    useEffect(() => {
        fetchResources();
    }, [keyword]);
    return (
        <div className="">
            <div className="flex items-center justify-between p-5">
                <SearchBox className="max-w-sm" onSubmit={(value) => setKeyword(value)} />
                {checkPermission('courseResource', 'U') && (
                    <div>
                        <PopoverComponent
                            title={translator('ADD')}
                            list={[
                                {
                                    label: translator('LIBRARY.UPLOAD_FILE'),
                                    icon: <FileUploadIcon />,
                                    action: () => fileInputRef.current.click(),
                                },
                                {
                                    label: translator('LIBRARY.ADD_FROM_LIBRARY'),
                                    icon: <img src={LIBRARY} alt="" className="w-6" />,
                                    action: toggleModalAddFromLibrary,
                                    hidden: !checkPermission('libraryContent','R')
                                },
                            ]}
                        />
                        <div className="hidden" onClick={() => fileInputRef.current.click()}>
                            <input
                                multiple
                                accept={acceptContentTypeUpload?.join(',')}
                                ref={fileInputRef}
                                onChange={(e) =>
                                    uploadContext.handleUploadFile({files: e.target?.files as any},
                                        (value, onSuccess, onError) => uploadResource({ ...value, from_lesson: false }, onSuccess, onError)
                                    )
                                }
                                className="hidden"
                                type="file"
                                placeholder={''}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="px-5">
                <div className="h-[calc(100vh-390px)] overflow-auto">
                    {loading ? (
                        <div className="h-[calc(100vh-390px)] flex justify-center items-center">
                            <CircleSpin color="text-primary-500" />
                        </div>
                    ) : (
                        <TableResources
                            workspaceId={workspaceId}
                            data={data}
                            onDeleteResource={(rowValue) => {
                                toggleModalConfirmDelete();
                                setSelectedResource(rowValue);
                            }}
                            onDownloadResource={(rowValue) => {
                                onDownload(rowValue, params, setLoadingDownload);
                            }}
                            onPreviewResource={(rowValue) => {
                                getFileDetail(rowValue?.object_id);
                                setSelectedResource(rowValue);
                            }}
                        />
                    )}
                </div>
                <Pagination
                    total={pagination.total}
                    per_page={pagination.per_page}
                    callback={(page) => fetchResources(page)}
                    indexPage={indexPage}
                    page={pagination?.page}
                />
            </div>
            {modalAddFromLibrary && (
                <DialogComponent
                    isOpen={modalAddFromLibrary}
                    hasNonTitle
                    styles="max-w-[70vw] min-h-[480px] my-0 rounded-lg !p-0 pb-4"
                    isShowClose={false}
                    child={
                        <FromLibrary
                            createContentType={''}
                            isShowContentType
                            title={translator('LIBRARY.ADD_FROM_LIBRARY')}
                            onCloseModal={toggleModalAddFromLibrary}
                            handleAddContentToSection={(values) => {
                                if (values?.length > 0)
                                    importResource({
                                        libraries: values?.map((item) => item?.id),
                                    });
                            }}
                            queryParams={{ exclude_course_id: courseId }}
                        />
                    }
                />
            )}
            {!!fileDetail && (
                <DocumentPreview
                    isOpen={!!fileDetail}
                    data={fileDetail}
                    closeModal={() => {
                        setFileDetail(null);
                    }}
                    onDelete={toggleModalConfirmDelete}
                />
            )}
            {modalConfirmDelete && (
                <DialogComponent
                    title={translator('MODALS.DELETE')}
                    isOpen={modalConfirmDelete}
                    onCloseModal={toggleModalConfirmDelete}
                    styleDialog="!z-9999"
                    child={
                        <div className="mt-4">
                            <p className="text-sm">{translator('MODALS.DELETE_COURSE_RESOURCE_ITEM')}</p>
                            <div className="flex justify-center gap-4 mt-5">
                                <ButtonOutline type="button" onClick={toggleModalConfirmDelete}>
                                    {translator('MODALS.CANCEL')}
                                </ButtonOutline>
                                <ButtonPrimary
                                    type="button"
                                    onClick={() => deleteResource(selectedResource?.id)}
                                    disabled={loading}
                                    className="buttonPrimary-danger"
                                >
                                    {translator('MODALS.DELETE')}
                                </ButtonPrimary>
                            </div>
                        </div>
                    }
                    stylesTitle="text-base"
                />
            )}
            {loadingDownload && <LoadingPopup message={translator('LIBRARY.DOWNLOADING')} loading={loadingDownload} />}
        </div>
    );
};
