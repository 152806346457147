import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/outline';
import TableNotification from './TableNotification';
import ButtonComponent from 'components/Button/ButtonComponent';
import Spinner from 'components/Spinner';
import notificationsService from 'services/notifications.service';
import ModalCreate from 'components/Management/components/ModalCreateWrapper';
import { useBoolean } from 'hooks/custom';
import { handleCheckArray } from '../NotificationComponents/ConstantNotications';
import avatarImg from 'assets/avatar_option.png';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import Pagination from 'components/V2/Pagination';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import SearchBox from 'components/V2/SearchBox';

const NotificationList: FC = () => {
    const params: any = useParams();
    const { t: translator } = useTranslation();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [loadList, setLoadList] = useState(false);
    const [noticationList, setNoticationList] = useState({
        items: [],
        order: 'desc',
        page: 1,
        per_page: 20,
        sort_by: 'created_at',
        total: 0,
    });

    const { booleanValue: modalViewDetail, toggleBooleanValue: toggleModalViewDetail } = useBoolean();
    const [indexPage, setIndexPage] = useState('1-50');

    const [viewDetails, setViewDetails] = useState<any>();

    const workspaceId = params.id;

    const getNoticationList = async (page: number | string = 1, order: string, sort_by: string) => {
        setLoading(true);
        try {
            await notificationsService
                .getListNotifications(workspaceId, {
                    page: page,
                    order: order,
                    sort_by: sort_by,
                    per_page: noticationList?.per_page
                })
                .then((res) => {
                    setNoticationList(res?.data);
                    setIndexPage(getIndexPage(res?.data?.page, res?.data?.per_page, res?.data?.items?.length));

                });
            setLoading(false);

        } catch {
            setLoading(false);
        }
    };

    const handlePagination = (page: number) => {
        getNoticationList(page, noticationList.order, noticationList.sort_by);
    };

    useEffect(() => {
        getNoticationList(1, noticationList.order, noticationList.sort_by);
    }, [workspaceId]);

    const handleViewDetailNoti = (data: any) => {
        notificationsService.getDetailsNotification(workspaceId, data.id).then((res) => {
            if (res?.data) {
                setViewDetails(res.data);
                toggleModalViewDetail();
            }
        });
    };

    const checkType = () => {
        return handleCheckArray(viewDetails.list_class_id, viewDetails.list_group_id);
    };

    const listDetail = (type: string) => {
        let list = [];
        switch (type) {
            case 'classes':
                list = viewDetails.list_class;
                break;
            case 'groups':
                list = viewDetails.list_group;
                break;

            default:
                list = viewDetails.list_user;
                break;
        }
        return list;
    };

    const handleSearch = async (e: string) => {
        setLoading(true);
        await notificationsService
            .getListNotifications(workspaceId, {
                page: 1,
                q: e,
                order: noticationList.order,
                sort_by: noticationList.sort_by,
            })
            .then((res) => {
                setNoticationList(res?.data);
            });
        setLoading(false);
    };

    const handleSortTitle = () => {
        getNoticationList(noticationList.page, noticationList.order === 'asc' ? 'desc' : 'asc', 'title');
    };

    const handleSortStatus = () => {
        getNoticationList(noticationList.page, noticationList.order === 'asc' ? 'desc' : 'asc', 'status');
    };

    useEffect(() => {
        if (loadList) {
            getNoticationList(noticationList.page, noticationList.order, noticationList.sort_by);
            setLoadList(false);
        }
    }, [loadList]);

    return (
        <div className="w-full h-screen">
            {viewDetails && (
                <ModalCreate
                    isOpen={modalViewDetail}
                    onClose={toggleModalViewDetail}
                    title={'Sent to Detail'}
                    styles="rounded-2xl max-w-[400px]"
                >
                    <p className=" text-sm  mt-6 px-3 font-semibold text-primary-500 capitalize text-center">{checkType()}</p>

                    <div className="w-full">
                        {listDetail(checkType())?.length &&
                            listDetail(checkType()).map((i) => (
                                <>
                                    <div className="flex justify-between items-center ">
                                        <div className="w-3/4 text-xs font-normal text-ooolab_dark_1 my-2 mx-12 truncate flex items-center">
                                            <img
                                                className={`rounded-full w-5 h-5 mr-1 ${checkType() === 'people' ? ' ' : ' hidden'}`}
                                                src={i?.avatar_url || avatarImg}
                                                alt="img"
                                            />
                                            {checkType() === 'people' ? i.display_name : i.name}
                                            {i?.membership?.type == 'student' && i?.nick_name ? ` / ${i?.nick_name}` : ''}
                                        </div>
                                        {checkType() === 'people' ? (
                                            <div className="w-1/4 text-[#8F90A6] text-[10px] font-normal mr-8 capitalize">
                                                {i?.membership?.type || ''}
                                            </div>
                                        ) : null}
                                    </div>
                                </>
                            ))}
                    </div>

                    <div className="w-full flex justify-center">
                        <ButtonComponent
                            title="Close"
                            classStyle={
                                'w-fit justify-center rounded text-primary-500 mt-6 p-2 text-xs border border-ooolab_dark_1 text-ooolab_dark_1 mr-4 outline-none'
                            }
                            onClickButton={toggleModalViewDetail}
                        ></ButtonComponent>
                    </div>
                </ModalCreate>
            )}
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.COMMUNICATION')}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    { name: translator('DASHBOARD.SIDEBAR.NOTIFICATIONS'), href: '', className: '' },
                ]}
            />
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 py-4 px-2">
                <div className=" w-full max-w-xs">
                    <SearchBox placeholder={translator('NOTIFICATION_CUSTOM.SEARCH_BY_NOTIFICATION_NAME')} onSubmit={handleSearch} />
                </div>
                {checkPermission('customNotification', 'C') && <div className="lg:flex lg:justify-end">
                    <ButtonComponent
                        title={translator('NOTIFICATION_CUSTOM.NEW')}
                        classStyle="xs:p-1 xs:text-sm primary-button-apollo"
                        onClickButton={() => history.push(`/workspace/${workspaceId}/management/notification/new`)}
                        icon={<PlusIcon className="w-4 h-4 mr-ooolab_m_1" />}
                    />
                </div>}

            </div>
            <div className={`px-4 gap-x-2  border-t border-gray-200 pt-5 `}>
                <div className="h-[calc(100vh-200px)] custom-scrollbar overflow-y-auto">
                    {
                        loading ?
                            <div className="h-[calc(100vh-200px)] flex justify-center items-center">
                                <Spinner />
                            </div>
                            :
                            <TableNotification
                                workspaceId={workspaceId}
                                dataTable={noticationList}
                                handleViewDetailNoti={handleViewDetailNoti}
                                setLoadList={setLoadList}
                                handleSortTitle={handleSortTitle}
                                handleSortStatus={handleSortStatus}
                            />
                    }
                </div>
                <Pagination
                    page={noticationList.page}
                    per_page={noticationList.per_page}
                    total={noticationList.total}
                    callback={handlePagination}
                    indexPage={indexPage}
                />
            </div>
        </div>
    );
};

export default NotificationList;
