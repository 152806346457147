import { Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { ColumnWithLooseAccessor, useTable, useRowSelect } from 'react-table';
import { useSticky } from 'react-table-sticky';
import './table.css';
import styled from 'styled-components';

const Styles = styled.div`
    td,
    th {
        border: 1px solid #ebebf0;
        padding: 10px 20px;
    }
`;

interface CustomPropsGetter {
    className?: string;
    style?: React.CSSProperties;
}

interface TableProps {
    rowProps?: CustomPropsGetter;
    headerProps?: CustomPropsGetter;
    columns: any[];
    data: any[];
    onClickRow: (e: any) => void;
    rowClass?: (row: any) => string;
    onDoubleClickRow?: (e: any) => void;
    className?: string;
    onClickHeader?: Function;
}

const TableGradeBookApollo: React.FC<TableProps> = ({
    rowProps,
    columns,
    data,
    headerProps,
    onClickRow,
    rowClass,
    className,
}) => {
    const { headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
        },
        useRowSelect
    );

    return (
        <>
            <Styles>
                <table className={className || 'w-full h-1'}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps({
                                    ...headerProps,
                                })}
                            >
                                {headerGroup.headers.map((column, idx) => {
                                    return (
                                        <th
                                            key={idx}
                                            id={`col-header-${idx}`}
                                            {...column.getHeaderProps()}
                                            scope="col"
                                            className={`text-ooolab_dark_1 align-middle  ${
                                                column.id === 'student' && `align-bottom`
                                            } `}
                                        >
                                            {column.render('Header')}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {rows.map((row, idx) => {
                            prepareRow(row);
                            return (
                                <tr
                                    onClick={() => {
                                        onClickRow(row.original);
                                    }}
                                    {...row.getRowProps({
                                        ...rowProps,
                                        ...(rowClass
                                            ? {
                                                  className: rowClass(row.original),
                                              }
                                            : {}),
                                    })}
                                >
                                    {row.cells.map((cell, idxx) => {
                                        return (
                                            <td
                                                key={idxx}
                                                {...cell.getCellProps}
                                                className={` ${cell.column.id === 'overall' && `bg-ooolab_gray_11 `} `}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Styles>
        </>
    );
};

export default TableGradeBookApollo;
