import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getIndexPage } from 'utils/commonFun';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import Table from './Table/Table';
import Pagination from 'components/V2/Pagination';
import debounce from 'lodash/debounce';
import userReportService from 'services/user-report.service';
import Filter from './Filter/Filter';

const defaulPagination = {
    page: 1,
    per_page: 20,
    order: 'desc',
    sort_by: 'released_at',
    total: 0,
};
export const Reports = () => {
    const params: { id: string; userId: string } = useParams();
    const { t: translator } = useTranslation();
    const [isOpenModalAddReport, setOpenModalAddReport] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [indexPage, setIndexPage] = useState('0-0');
    const [pagination, setPagination] = useState<any>(defaulPagination);
    const [filter, setFilter] = useState(undefined);
    const [order, setOrder] = useState('desc')

    const workspaceId = params.id;
    const userId = params.userId;

    const getData = (page: number = 1, filter = {}, order = 'desc') => {
        setLoading(true);
        const payload = {
            ...pagination,
            ...filter,
            userId,
            page,
            order
        };
        delete payload?.total;
        userReportService
            .getWorkspaceStudentCustomReports(workspaceId, payload)
            .then((res) => {
                const { items, total, page } = res.data;
                setData(items);
                setPagination({ ...pagination, total, page });
                setIndexPage(getIndexPage(payload?.page, pagination?.per_page, items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const handlePagination = (page: number) => {
        getData(page);
    };

    const getDataDebounce = useCallback(
        debounce((page, filter, order) => getData(page, filter, order), 800),
        []
    );

    useEffect(() => {
        getDataDebounce(1, filter, order);
    }, [filter, order]);

    return (
        <div className="CourseReport">
            <div className="CourseReport-title">{translator('COURSES.REPORTS')}</div>
            <div className=" mt-5 flex justify-between">
                <Filter onChangeFilter={(filter) => setFilter(filter)} isFirstLoad={false} />
            </div>
            <div className="CourseReport-main mt-5">
                <div className="wrapper-content">
                    <div className="scrollbar">
                        {loading && (
                            <div className="loading">
                                <CircleSpin color="text-primary-500" />
                            </div>
                        )}
                        <Table data={data} refreshData={getData} order={order} setOrder={setOrder} />
                    </div>
                    <Pagination
                        total={pagination.total}
                        per_page={pagination.per_page}
                        callback={handlePagination}
                        indexPage={indexPage}
                        page={pagination?.page}
                    />
                </div>
            </div>
        </div>
    );
}
