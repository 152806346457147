import { useForm } from 'react-hook-form';
import { FORM_CONST } from 'constant/form.const';
import { useState, useEffect } from 'react';
import classService from 'services/class.service';
import { useParams } from 'react-router-dom';
import { InputField } from 'components/InputForm/InputForm';
import { CreatableAsyncSelectField } from 'components/Select';
import { WorkspaceService } from 'services';
import { Disclosure } from '@headlessui/react';
import { ArrowRightSIcon } from 'assets/icon/ArrowRightSIcon';
import { isEmail } from 'utils/commonFun';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';

const PER_PAGE = 20;

const CreateLearnerPortal = ({ submitted, onCloseModal }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setError,
        setValue,
        watch,
        clearErrors,
    } = useForm({ mode: "onChange" });
    const { t } = useTranslation();
    const params: any = useParams();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [parentInfo, setParentInfo] = useState<any>(null);
    const [isSubmitForm, setIsSubmitForm] = useState<boolean>(false);
    const [showMessageError, setShowMessageError] = useState<boolean>(false);

    useEffect(() => {
        if (isSubmitForm) {
            if (!!Object.keys(errors).length || (!watch('email') && !watch('parent_email'))) setShowMessageError(true);
            else setShowMessageError(false);
        }
    }, [watch(), isSubmitForm]);

    const submitForm = async (val) => {
        setIsLoading(true);
        await classService
            .addStudent(params?.id, {
                parent_email: val?.parent_email?.value || null,
                first_name: val?.first_name || null,
                last_name: val?.last_name || null,
                email: val?.email || null,
                company_id: val?.company?.value || null,
                parent_first_name: val?.parent_first_name || null,
                parent_last_name: val?.parent_last_name || null,
            })
            .then((res) => {
                Toastify.success(t('USER.CREATE_LEARNER_SUCCESS'))
                submitted();
            })
            .catch((err) => {
                if (err?.response?.data?.error?.code === 400) {
                    err?.response?.data?.error?.body_params?.foreach((el) => {
                        const objTemp = {
                            msg: el?.msg,
                            field: el?.loc?.toString(),
                        };
                        setError(objTemp.field, { message: objTemp.msg });
                    });
                } else {
                    Toastify.error(t('MODALS.INFORM_UPDATE_WORKSPACE_RESULT.ERROR'))
                }
            });

        setIsLoading(false);
    };

    const fetchParentMails = async (bodyParams?: any) => {
        const res = await WorkspaceService.getWorkspaceMembersService(params.id, bodyParams);
        return {
            items: res?.data?.items,
            total: res?.data?.total,
        };
    };

    const handleLoadParentMails = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional?.page,
            per_page: PER_PAGE,
            q: inputValue || null,
            type: 'parent',
        };
        if (!payload?.q) delete payload?.q;

        const { items, total } = await fetchParentMails(payload);

        const newOptions = items?.map((el: any) => ({
            id: el?.id,
            label: el?.display_name + ` (${el?.email})`,
            name: el?.display_name,
            value: el?.email,
            firstName: el?.first_name,
            lastName: el?.last_name
        }));

        return {
            options: newOptions,
            hasMore: additional?.page < total / PER_PAGE,
            additional: {
                page: additional?.page + 1,
            },
        };
    };


    return (
        <form onSubmit={handleSubmit(submitForm)} className="grid grid-cols-4 px-ooolab_p_2 gap-x-8 gap-y-5 mt-3">
            <InputField
                label={t('USER.FIRST_NAME')}
                name="first_name"
                className="col-span-2"
                isRequired
                placeholder={t('USER.ENTER_FIRST_NAME')}
                register={register('first_name', {
                    required: t('FORM_CONST.REQUIRED_FIELD'),
                    validate: {
                        shouldNotContainSpace: (value) =>
                            !!value.trim()
                                ? true
                                : t('FORM_CONST.REQUIRED_FIELD'),
                    },
                    maxLength: {
                        value: 255,
                        message: t('FORM_CONST.VALIDATE_255_CHARACTERS'),
                    },
                })}
                errors={errors}
            />
            <InputField
                label={t('USER.LAST_NAME')}
                name="last_name"
                className="col-span-2"
                isRequired
                placeholder={t('USER.ENTER_LAST_NAME')}
                register={register('last_name', {
                    required: t('FORM_CONST.REQUIRED_FIELD'),
                    validate: {
                        shouldNotContainSpace: (value) =>
                            !!value.trim()
                                ? true
                                : t('FORM_CONST.REQUIRED_FIELD'),
                    },
                    maxLength: {
                        value: 255,
                        message: t('FORM_CONST.VALIDATE_255_CHARACTERS'),
                    },
                })}
                errors={errors}
            />
            <InputField
                label={t('USER.EMAIL')}
                name="email"
                className="col-span-4"
                placeholder={t('USER.ENTER_EMAIL')}
                register={register('email', {
                    required: {
                        value: false,
                        message: t('FORM_CONST.REQUIRED_FIELD'),
                    },
                    validate: {
                        shouldNotContainSpace: (value) => {
                            if (watch('parent_email')?.value?.length > 0 || !!value.trim()) {
                                return true;
                            }
                            return t('FORM_CONST.REQUIRED_FIELD')
                        }
                    },
                    pattern: {
                        value: FORM_CONST.EMAIL_REGEX,
                        message: t('FORM_CONST.EMAIL_VALIDATE'),
                    },
                    maxLength: {
                        value: 255,
                        message: t('FORM_CONST.VALIDATE_255_CHARACTERS'),
                    },
                })}
                errors={errors}
            />
            <p className="col-span-4 text-xs grid-cols-none -mt-3">
                {t('USER.IF_THE_STUDENT_HAS_NO_EMAIL_PLEASE_ADD_PARENT_INFORMATION_BELLOW')}
            </p>
            <Disclosure as="div" className="col-span-4">
                {({ open }) => {
                    return (
                        <>
                            <Disclosure.Button className="text-primary-500 flex items-center space-x-1">
                                <span className={`p-1 ${open ? 'transition -rotate-90' : 'transition rotate-90'}`}>
                                    <ArrowRightSIcon />
                                </span>
                                <span className="text-sm">{t('USER.ADD_PARENT_INFORMATION')}</span>
                            </Disclosure.Button>
                            <Disclosure.Panel className="mt-1 rounded-lg border border-dashed border-gray-300 bg-gray-50 px-4 py-3">
                                <CreatableAsyncSelectField
                                    control={control}
                                    label={t('USER.EMAIL')}
                                    name="parent_email"
                                    placeholder={t('USER.ENTER_PARENT_EMAIL')}
                                    closeMenuOnSelect={true}
                                    errors={errors}
                                    loadOptions={handleLoadParentMails}
                                    onChange={(newOptions) => {
                                        setParentInfo(newOptions);
                                        clearErrors();
                                        setValue('parent_first_name', newOptions?.firstName)
                                        setValue('parent_last_name', newOptions?.lastName)
                                        const mailTemp = newOptions?.value;
                                        if (!!mailTemp) {
                                            if (!isEmail(mailTemp)) {
                                                setError('parent_email', { message: t('FORM_CONST.EMAIL_VALIDATE') });
                                            }
                                        }
                                    }}
                                    isClearable
                                    valueAsLabel
                                    isMulti={false}
                                    isSearchIcon={false}
                                    limitSearch={1000}
                                    textOptionNew={t("USER.ADD_NEW_PARENT_EMAIL")}
                                />
                                <InputField
                                    label={t('USER.FRIST_NAME')}
                                    name="parent_first_name"
                                    className="mt-5"
                                    placeholder={t('USER.ENTER_PARENT_FIRST_NAME')}
                                    isRequired={!parentInfo?.name ? true : false}
                                    register={register('parent_first_name', {
                                        required: (watch('parent_email') && !parentInfo?.name) ? t('FORM_CONST.REQUIRED_FIELD') : false,
                                        maxLength: {
                                            value: 255,
                                            message: t('FORM_CONST.VALIDATE_255_CHARACTERS'),
                                        },
                                    })}
                                    errors={errors}
                                    disabled={!!parentInfo?.name || !watch('parent_email')}
                                />
                                <InputField
                                    label={t('USER.LAST_NAME')}
                                    name="parent_last_name"
                                    className="mt-5"
                                    placeholder={t('USER.ENTER_PARENT_LAST_NAME')}
                                    isRequired={!parentInfo?.name ? true : false}
                                    register={register('parent_last_name', {
                                        required: (watch('parent_email') && !parentInfo?.name)  ? t('FORM_CONST.REQUIRED_FIELD') : false,
                                        maxLength: {
                                            value: 255,
                                            message: t('FORM_CONST.VALIDATE_255_CHARACTERS'),
                                        },
                                    })}
                                    errors={errors}
                                    disabled={!!parentInfo?.name || !watch('parent_email')}
                                />

                            </Disclosure.Panel>
                        </>
                    );
                }}
            </Disclosure>
            {showMessageError && (
                <div className="col-span-4 text-sm text-red-500 pl-5 relative">
                    <ExclamationCircleIcon className="absolute top-0.5 left-0 w-4 h-4" />
                    <p className="font-normal">{t('USER.ERROR_MESSAGE_VALIDATE_FIELD_1')}</p>
                    <p className="font-normal">{t('USER.ERROR_MESSAGE_VALIDATE_FIELD_2')}</p>
                </div>
            )}

            <div className="col-span-4 flex justify-center gap-4 items-center pt-3">
                <ButtonOutline
                    onClick={onCloseModal}
                    type={'button'}
                >
                    {t('CANCEL')}
                </ButtonOutline>
                <ButtonPrimary
                    type="submit"
                    disabled={!!Object.keys(errors).length || isLoading || showMessageError}
                    onClick={() => setIsSubmitForm(true)}
                >
                    {t('ADD')}
                </ButtonPrimary>
            </div>
        </form>
    );
};

export default CreateLearnerPortal;
