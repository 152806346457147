import React, { useEffect } from 'react';
import DialogComponent from 'components/Dialog/DialogComponent';
import { InputField } from 'components/InputForm/InputForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonOutline, ButtonPrimary } from '_shared';
import "./index.scss";
import { FORM_CONST } from 'constant/form.const';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { PaymentType } from '../model';

interface AddBankAccount {
    isOpen: boolean,
    onClose: () => void,
    onAddPayment: (value) => void,
    onUpdateBank: (value) => void,
    loading: boolean,
    data?: PaymentType
}
export const AddBankAccount = ({data, isOpen, onClose, onAddPayment, onUpdateBank, loading}: AddBankAccount) => {
    const { t } = useTranslation();
    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`DASHBOARD.WORKSPACE_SETTING.PAYMENT_DETAILS.${str}`, opt));

    const {
        handleSubmit,
        formState: { errors, isDirty, isValid },
        reset,
        register,
    } = useForm({ mode: 'onChange' });

    const handleAddBank = (value) => {
        if (value?.email == '') value.email = null;
        if (!data) {
            onAddPayment(value);
        } else {
            onUpdateBank(value);
        }
    };

    useEffect(() => {
        if (data) {
            reset(data);
        } else {
            reset();
        }
    }, [data, isOpen]);

    return <>
        <DialogComponent
            title={data ?   translator("Update_Payment_Details") : translator("New_Payment_Details")}
            isOpen={isOpen}
            onCloseModal={onClose}
            styles='!max-w-xl'
            child={
                <>
                    <form className='addBankAccount' onSubmit={handleSubmit(handleAddBank)}>
                        <InputField
                            isRequired
                            label={translator('Company_Name')}
                            placeholder={translator('Enter_Company_Name')}
                            name="company_name"
                            maxLength={100}
                            errors={errors}
                            register={register('company_name', {
                                required: {
                                    value: true,
                                    message: t('FORM_CONST.REQUIRED_FIELD'),
                                },
                                setValueAs: (value: any) => value?.trim()
                            })}
                        />
                        <InputField
                            isRequired
                            label={translator('Account_Number')}
                            placeholder={translator('Enter_Account_Number')}
                            name="account_number"
                            errors={errors}
                            maxLength={24}
                            register={register('account_number', {
                                required: {
                                    value: true,
                                    message: t('FORM_CONST.REQUIRED_FIELD'),
                                },
                                pattern: {
                                    value: FORM_CONST.NUMBER_REGEX,
                                    message: t('FORM_CONST.NUMBER_ONLY')
                                },
                                setValueAs: (value: any) => value?.trim()
                            })}
                        />
                        <InputField
                            label={translator('SWIFT_Code')}
                            placeholder={translator('Enter_SWIFT_Code')}
                            name="swift_code"
                            errors={errors}
                            register={register('swift_code', {
                                setValueAs: (value: any) => value?.trim()
                            })}
                        />
                        <InputField
                            isRequired
                            label={translator('Bank_Name')}
                            placeholder={translator('Enter_Bank_Name')}
                            name="bank_name"
                            errors={errors}
                            maxLength={100}
                            register={register('bank_name', {
                                required: {
                                    value: true,
                                    message: t('FORM_CONST.REQUIRED_FIELD'),
                                },
                                setValueAs: (value: any) => value?.trim()
                            })}
                        />
                        <InputField
                            label={translator('Contact_Name')}
                            placeholder={translator('Enter_Contact_Name')}
                            name="contact_name"
                            errors={errors}
                            maxLength={100}
                            register={register('contact_name', {
                                setValueAs: (value: any) => value?.trim()
                            })}
                        />
                        <InputField
                            label={translator('Email')}
                            placeholder={translator('Enter_Email')}
                            name="email"
                            errors={errors}
                            register={register('email', {
                                pattern: {
                                    value: FORM_CONST.EMAIL_REGEX,
                                    message: t('FORM_CONST.EMAIL_VALIDATE'),
                                },
                                setValueAs: (value: any) => value?.trim()
                            })}
                        />
                        <InputField
                            isRequired
                            label={translator('Registered_Address')}
                            placeholder={translator('Enter_Registered_Address')}
                            name="register_address"
                            errors={errors}
                            maxLength={100}
                            register={register('register_address', {
                                required: {
                                    value: true,
                                    message: t('FORM_CONST.REQUIRED_FIELD'),
                                },
                                setValueAs: (value: any) => value?.trim()
                            })}
                        />
                        <div className="flex gap-5 justify-center">
                            <ButtonOutline type="button" onClick={onClose}>
                                {translator('CANCEL')}
                            </ButtonOutline>
                            <ButtonPrimary disabled={!isDirty || !isValid} type="submit" onClick={() => {}}>
                                {loading ? <CircleSpin className='text-primary-500' /> : 
                                translator(data ? 'UPDATE':'CREATE')}
                            </ButtonPrimary> 
                        </div>
                    </form>
                </>
            }
        />
    </>
}