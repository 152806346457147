import './DetailsFilePopup.scss';
import { LIBRARY_ICONS } from 'components/Library/LibraryContants';
import { useTranslation } from 'react-i18next';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import NO_CONTENT_IMG from 'assets/img/empty-state/library.png';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import BlockContent from './BlockContent';

const SectionList = ({ sectionName = undefined, contents, translator, number = undefined }) => {
    return (
        <div className="sectionList">
            {sectionName && (
                <div className="flex justify-between">
                    <div className="sectionList-title ">{sectionName}</div>
                    {number && <div className="sectionList-file">{number || 0} Files</div>}
                </div>
            )}
            <div>
                {contents?.map((content) => {
                    return (
                        <div key={content?.id} className="sectionList-item">
                            <img
                                alt="libary-icon"
                                className="icon"
                                src={
                                    content?.library_content?.object_type
                                        ? LIBRARY_ICONS(translator)?.find(
                                              (item) => item?.value === content?.library_content?.object_type
                                          )?.icon
                                        : LIBRARY_ICONS(translator)[0].icon
                                }
                            />
                            <div className="content-name">{content?.library_content?.name}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default function Materials({ units, isLoading }) {
    const { t: translator } = useTranslation();
    if (isLoading) {
        return <CircleSpin className="taglist-loading" />;
    }
    return (
        <div>
            <div className='bg-gray-50 m-4 rounded-lg'>
                {!units?.length && <NoContentWithImage img={NO_CONTENT_IMG} />}

                {
                    units?.map(unit => {
                        return (
                            <BlockContent number={unit?.section_contents?.length} styles="!m-0 p-4" title={unit?.title}>
                                {unit?.section_contents?.map((lesson) => {
                                    return (
                                        <SectionList
                                            key={lesson?.id}
                                            sectionName={lesson?.library_content?.name}
                                            contents={[]}
                                            translator={translator}
                                        />
                                    );
                                })}
                            </BlockContent>
                        )
                    })
                }
            </div>
        </div>
    );
}
