import { InputCheckbox } from '_shared/components/Form/InputCheckbox/InputCheckbox';
import { SwitchToggle } from '_shared/components/Form/SwitchToggle/SwitchToggle';
import { InputField } from 'components/InputForm/InputForm';
import { TooltipCustom } from 'components/Tooltip/Tooltip';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VALIDATE_FIELD } from 'utils/validate';

export default function Gradebook({notAllowEdit = false, formProps}) {
    const { control, watch, register, formState: {errors}, setValue, getValues} = formProps;
    const { t: translator } = useTranslation();
    const [isShowError, setShowError] = useState(false)

    const isActiveCourse = useMemo(() => getValues('course_status') === 'active', [getValues('course_status')]);
    
    useEffect(() => {
       if ( +watch('assignment_grade_weight') + +watch('lesson_goal_weight') + +watch('attendance_weight') + +watch('course_completion_weight') !== 100) {
        setShowError(true)
       }
       else setShowError(false)
    }, [watch()])

    useEffect(() => {
        if (!watch('has_course_completion')) setValue('course_completion_weight', null);
    }, [watch('has_course_completion')]);

    useEffect(() => {
        if (!watch('has_attendance')) setValue('attendance_weight', null);
    }, [watch('has_attendance')]);

    useEffect(() => {
        if (!watch('has_lesson_goal')) setValue('lesson_goal_weight', null);
    }, [watch('has_lesson_goal')]);
    
    useEffect(() => {
        if (!watch('has_assignment_grade')) setValue('assignment_grade_weight', null);
    }, [watch('has_assignment_grade')]);

    useEffect(() => {
        if (!watch('enable_pass_fail')) setValue('threshold_pass', 0);
    }, [watch('enable_pass_fail')]);

    return (
        <div className="py-10">
            <div className="courseSettings-title">
                {translator('COURSES.PROGGESS_HISTORY')}
                <p className="text-sm font-normal mt-1">{translator('COURSES.PROGGESS_HISTORY_CONTENT')} </p>
            </div>
            <div  className={`mt-5 px-5  ${notAllowEdit ? 'opacity-container' : ''} `} >
                <div className={isActiveCourse ? 'opacity-50' : ''}>
                    <div className="text-base font-semibold">{translator("COURSES.OVERALL_GRADE")}</div>
                    <div className="mt-1 text-sm font-normal text-gray-500">
                        {translator("COURSES.OVERALL_GRADE_CONTENT")}
                    </div>

                    <div className={`w-full ${isShowError ? 'bg-red-50 pb-3 mt-4' : ''}`}>
                        <div className={`flex justify-around items-center w-full`}>
                            <div className="mt-4 flex flex-col items-left">
                                <div className="flex gap-1.5">
                                    <div 
                                        data-for="COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS"
                                        data-tip={translator('COURSES.SETTING.COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS')}
                                    >
                                        <InputCheckbox inputName="has_attendance" control={control} disabled={notAllowEdit || isActiveCourse}/>
                                    </div>
                                    <label>{translator("COURSES.ATTENDANCE")}</label>
                                </div>
                                <div className="flex gap-1 items-center mt-1">
                                    <InputField
                                        type="number"
                                        disabled={!watch('has_attendance') || notAllowEdit || isActiveCourse}
                                        register={register('attendance_weight', {
                                            pattern: {
                                                value: VALIDATE_FIELD.NUMBER_0_9,
                                                message: translator('FORM_CONST.NUMBER_ONLY'),
                                            },
                                        })}
                                        className="w-11 h-9 !ml-[26px]"
                                        name="attendance_weight"
                                        errors={errors}
                                    />{' '}
                                    %
                                </div>
                            </div>
                            <div className='h-8 w-[1px] mt-2 bg-gray-200'></div>
                            <div className="mt-4 flex flex-col items-left">
                                <div className="flex gap-1.5">
                                    <div
                                        data-for="COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS"
                                        data-tip={translator('COURSES.SETTING.COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS')}
                                    >
                                        <InputCheckbox inputName="has_course_completion" control={control} disabled={notAllowEdit || isActiveCourse}/>
                                    </div>
                                    <label>{translator("COURSES.COURSES_COMPLETETION")}</label>
                                </div>
                                <div className="flex gap-1 items-center mt-1">
                                    <InputField
                                        type="number"
                                        disabled={!watch('has_course_completion') || notAllowEdit  || isActiveCourse}
                                        register={register('course_completion_weight', {
                                            pattern: {
                                                value: VALIDATE_FIELD.NUMBER_0_9,
                                                message: translator('FORM_CONST.NUMBER_ONLY'),
                                            },
                                        })}
                                        className="w-11 h-9 !ml-[26px]"
                                        name="course_completion_weight"
                                        errors={errors}
                                    />{' '}
                                    %
                                </div>
                            </div>
                            <div className='h-8 w-[1px] mt-2 bg-gray-200'></div>
                            <div className="mt-4 flex flex-col items-left">
                                <div className="flex gap-1.5">
                                    <div
                                        data-for="COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS"
                                        data-tip={translator('COURSES.SETTING.COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS')}
                                    >
                                        <InputCheckbox inputName="has_assignment_grade" control={control} disabled={notAllowEdit || isActiveCourse}/>
                                    </div>
                                    <label>{translator("COURSES.ASSIGNMENT_GRADE")}</label>
                                </div>
                                <div className="flex gap-1 items-center mt-1">
                                    <InputField
                                        type="number"
                                        disabled={!watch('has_assignment_grade') || notAllowEdit || isActiveCourse}
                                        register={register('assignment_grade_weight', {
                                            pattern: {
                                                value: VALIDATE_FIELD.NUMBER_0_9,
                                                message: translator('FORM_CONST.NUMBER_ONLY'),
                                            },
                                        })}
                                        className="w-11 h-9 !ml-[26px]"
                                        name="assignment_grade_weight"
                                        errors={errors}
                                    />{' '}
                                    %
                                </div>
                            </div>
                            <div className='h-8 w-[1px] mt-2 bg-gray-200'></div> 
                            <div className="mt-4 flex flex-col items-left">
                                <div className="flex gap-1.5">
                                    <div
                                        data-for="COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS"
                                        data-tip={translator('COURSES.SETTING.COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS')}
                                    >
                                        <InputCheckbox inputName="has_lesson_goal" control={control} disabled={notAllowEdit || isActiveCourse}/>
                                    </div>
                                    <label>{translator("COURSES.LESSON_GOAL")}</label>
                                </div>
                                <div className="flex gap-1 items-center mt-1">
                                    <InputField
                                        type="number"
                                        disabled={!watch('has_lesson_goal') || notAllowEdit || isActiveCourse}
                                        register={register('lesson_goal_weight', {
                                            pattern: {
                                                value: VALIDATE_FIELD.NUMBER_0_9,
                                                message: translator('FORM_CONST.NUMBER_ONLY'),
                                            },
                                        })}
                                        className="w-11 h-9 !ml-[26px]"
                                        name="lesson_goal_weight"
                                        errors={errors}
                                    />{' '}
                                    %
                                </div>
                            </div>
                        </div>
                        {
                            isShowError && <div className='text-sm text-red-500 mt-3 px-6'>{ translator('FORM_CONST.TOTAL_WEIGHTS')}</div>
                        }
                    </div>
                </div>

                <div className={isActiveCourse ? 'opacity-50' : ''}>
                    <div className="mt-6 border-t border-gray-300 pt-4 flex items-center gap-2">
                        <div
                            data-for="COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS"
                            data-tip={translator('COURSES.SETTING.COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS')}
                        >
                            <SwitchToggle name={'enable_pass_fail'} control={control} disabled={notAllowEdit || isActiveCourse} enableColor/>
                        </div>
                        <div className="text-base font-semibold">{translator("COURSES.SETTING.ENABLE_PASS_FAIL")}</div>
                    </div>
                    <div className="mt-1.5 text-sm font-normal text-gray-500 ml-12" >{translator("COURSES.SETTING.ENABLE_PASS_FAIL_CONTENT")}</div>
                    <div className="flex gap-1 items-center mt-1 ml-12">
                        <p className='text-gray-500 text-sm'>{translator('COURSES.SETTING.ABOVE')}</p>
                        <InputField
                            type="number"
                            disabled={!watch('enable_pass_fail') || notAllowEdit || isActiveCourse}
                            register={register('threshold_pass', {
                                pattern: {
                                    value: VALIDATE_FIELD.NUMBER_0_100,
                                    message: translator('FORM_CONST.NUMBER_ONLY_AND_NUMBERS_GREATER_THAN_ZERO_TO_100'),
                                },
                            })}
                            className="w-11 h-9 flex "
                            name="threshold_pass"
                            classError="whitespace-normal min-w-max"
                            errors={errors}
                        />
                        %
                    </div>
                </div>

                <div>
                    <div className="mt-6 border-t border-gray-300 pt-4 flex items-center gap-2">
                        <SwitchToggle name={'allow_instructor_grading'} control={control} disabled={notAllowEdit} enableColor/>
                        <div className="text-base font-semibold">{translator('COURSES.SETTING.ALLOW_INSTRUCTOR_EVALUATING_SESSION')}</div>
                    </div>
                    <div className="mt-1.5 text-sm font-normal text-gray-500 ml-12" >{translator('COURSES.SETTING.ALLOW_INSTRUCTOR_GRADING_CONTENT')}</div>
                </div>
            </div>
            {isActiveCourse && (
                <TooltipCustom
                    classNameContainer="shadow-medium"
                    id="COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS"
                    content={translator('COURSES.SETTING.COURSE_SETTING_COURSE_ACTIVE_TOOLTIPS')}
                    place="top"
                    type="dark"
                />
            )}
        </div>
    );
}
