import { FC, useContext, useEffect, useState } from 'react';
import ButtonComponent from 'components/Button/ButtonComponent';
import { useParams } from 'react-router-dom';
import { AsyncPaginate } from 'react-select-async-paginate';
import { components } from 'react-select';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import classService from 'services/class.service';
import { CheckIcon } from '@heroicons/react/outline';
import { customSelect, MultiValue, ValueContainer } from '../util';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { Toastify } from '_shared';

type ModalEnrollStudentProps = {
    translator: (key: string, newKey?: boolean) => string;
    fetchData: () => void;
    onCloseModal: () => void
};

const ModalEnrollStudent: FC<ModalEnrollStudentProps> = ({ translator, fetchData, onCloseModal}) => {
    const [selectedStudents, setSelectedStudents] = useState<any[]>([]);

    const param: { id: string; classId: string } = useParams();
    const [error, setError] = useState<any> ()
    const handleLoadOptionsStudents = async (inputValue, options, additional) => {
        const params: any = {
            page: additional.page,
            per_page: 20,
            type: 'student',
            q: inputValue || undefined,
        };

        const  { data : { items, total } } = await  classService.getStudentListService(param?.id, params)
        let newOptions = [];

        if (items?.length > 0) {
            newOptions = items?.map((el) => ({
                ...el,
                label: el.display_name,
                value: el.email,
            }));
        }

        return {
            options: newOptions,
            hasMore: additional.page < total / 20,
            additional: {
                page: additional.page + 1,
            },
        };
    };

    const params: { id: string; classId: string } = useParams();
    const [loading, setLoading] = useState(false)
    const {
        dispatch: dispatchWorkspace,
    } = useContext(GetWorkspaceContext);

    const onSubmitForm = () => {
        setLoading(true)
        if (selectedStudents?.length) {
            let isMeAsTeacher = false;
            const listEmailInvite = selectedStudents.map((i) => {
                return {
                    email: i.value,
                    type: 'student',
                };
            });
            
            workspaceMiddleware.inviteUserClasses(
                dispatchWorkspace,
                params.id,
                params.classId,
                {
                    members: listEmailInvite,
                    typeInvite: 'student'
                },
                isMeAsTeacher,
                () => {
                    setLoading(false);
                    onCloseModal();
                    fetchData()
                    Toastify.success();
                },
                (error) => {
                    const studentAlreadyInvited = error.error.body_params?.map(err => {
                        return selectedStudents?.[err?.loc?.[1]]
                    })
                    if (studentAlreadyInvited?.length) {
                        const listEmailStudentAlreadyInvited = studentAlreadyInvited?.map(student => student?.parent?.email)
                        setError(listEmailStudentAlreadyInvited)
                    }
                    Toastify.error(translator("ENROLL_STUDENT_ERROR")),
                    setLoading(false)
                }
            )
        }
    };

    useEffect(() => {
      const newError = error?.filter(email => {
        return !!selectedStudents?.find(student => student?.value === email)
      })
      setError(newError)
    }, [selectedStudents])

    return (
        <div>
            <label className="text-xs text-left font-medium mb-1">
                {translator('STUDENTS')} <span className="text-red-500 ml-1">*</span>
            </label>
            <AsyncPaginate
                loadOptions={handleLoadOptionsStudents}
                additional={{
                    page: 1,
                }}
                debounceTimeout={500}
                styles={customSelect}
                onChange={(newValue: any) => {
                    setSelectedStudents(newValue);
                }}
                isMulti
                isClearable
                placeholder="Select Students"
                value={selectedStudents}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                menuPlacement="auto"
                className={`${(selectedStudents?.length > 5 || error?.length) ? '!border-red-500 !border !rounded focus-visible:!border-red-500' : ''}`}
                components={{
                    Option: ({ isDisabled, isFocused, isSelected, children, ...props }) => {
                        // FIX LAG
                        const [isActive, setIsActive] = useState(false);
                        const onMouseDown = () => setIsActive(true);
                        const onMouseUp = () => setIsActive(false);
                        const onMouseLeave = () => setIsActive(false);
                        let bg = 'transparent';
                        if (isFocused) bg = '#eee';
                        if (isActive) bg = '#B2D4FF';
                        const style = {
                            alignItems: 'center',
                            backgroundColor: bg,
                            color: 'inherit',
                            display: 'flex ',
                        };

                        const propsNew = {
                            ...props.innerProps,
                            onMouseDown,
                            onMouseUp,
                            onMouseLeave,
                            style,
                        };
                        const newProps = Object.assign(props, {
                            innerProps: propsNew,
                            isSelected,
                            isDisabled,
                            isFocused,
                        });

                        const { data } = props;

                        return (
                            <components.Option {...newProps}>
                                <div className='flex justify-between w-full'>
                                    <div className='flex'>
                                        <img
                                            className={`rounded-full w-5 h-5 mr-2`}
                                            src={data?.avatar_url}
                                            alt="img"
                                        />
                                        {children}
                                    </div>
                                    {isSelected && <CheckIcon className='text-gray-500 w-4 h-4'/>}
                                </div>
                            </components.Option>
                        );
                    },
                    ValueContainer,
                    MultiValue,
                }}
            />
            {
               selectedStudents?.length  > 5 && 
                <p className='text-xs text-red-500 mt-1'>{translator("LIMIT_REACHED")}</p>
            }
            {error?.length > 0 &&
                <div className='text-xs text-red-500 mt-1'>
                    <p>{translator("VALIDATE_STUDENT")}</p>
                    <div className='ml-4'>
                        {
                            error?.map(email => {
                                return <p className='flex items-center mt-1'>• {email}</p>
                            })
                        }
                    </div>
                </div>
            }
            <div className="flex justify-center gap-4">
                <ButtonComponent onClickButton={onCloseModal} title={translator('CANCEL')} classStyle="xs:p-2 xs:text-sm secondary-button !text-gray-800 !border-gray-300 mt-4" />
                <ButtonComponent 
                    onClickButton={onSubmitForm} 
                    icon={loading && <CircleSpin 
                    className='mr-1 w-2.5 h-2.5' />} 
                    disable={
                        selectedStudents?.length === 0 || 
                        selectedStudents?.length > 5 || 
                        loading || 
                        error?.length
                    } 
                    title={translator('ENROLL')} 
                    classStyle="xs:p-2 xs:text-sm primary-button !bg-enterprise mt-4 disabled:!bg-gray-300" 
                />
            </div>  
        </div>
    );
};

export default ModalEnrollStudent;
