import React from 'react'

export default function GenerateAssignmentIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_7728_18600)">
      <path d="M16.6667 1.66663C17.1267 1.66663 17.5 2.03996 17.5 2.49996V7.49996C17.5 7.9602 17.1269 8.33329 16.6667 8.33329C16.2064 8.33329 15.8333 7.9602 15.8333 7.49996V3.33329H4.16667V16.6666H8.33333C8.59563 16.6666 8.84262 16.7901 9 17C9.41202 17.5493 9.02004 18.3333 8.33333 18.3333H3.33333C2.87333 18.3333 2.5 17.96 2.5 17.5V2.49996C2.5 2.03996 2.87333 1.66663 3.33333 1.66663H16.6667ZM10 9.99996C10.4602 9.99996 10.8333 10.3731 10.8333 10.8333C10.8333 11.2935 10.4602 11.6666 10 11.6666H7.5C7.03976 11.6666 6.66667 11.2935 6.66667 10.8333C6.66667 10.3731 7.03976 9.99996 7.5 9.99996H10ZM12.5 6.66663C12.9602 6.66663 13.3333 7.03972 13.3333 7.49996C13.3333 7.9602 12.9602 8.33329 12.5 8.33329H7.5C7.03976 8.33329 6.66667 7.9602 6.66667 7.49996C6.66667 7.03972 7.03976 6.66663 7.5 6.66663H12.5Z" fill="#0071CE"/>
      <path d="M18.333 10V12.5H18.0907M11.6921 13.3333C11.8972 11.689 13.2998 10.4167 14.9997 10.4167C16.3986 10.4167 17.5962 11.2784 18.0907 12.5M18.0907 12.5H15.833M11.6663 18.3333V15.8333H11.9086M11.9086 15.8333C12.4031 17.0549 13.6008 17.9167 14.9997 17.9167C16.6995 17.9167 18.1022 16.6443 18.3072 15M11.9086 15.8333H14.1663" stroke="#0071CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_7728_18600">
      <rect width="20" height="20" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}
