import DragAndDrop from './DragAndDrop'
import NO_CONTENT from 'assets/img/empty-state/content.png';
import './DragAndDrop.scss';
import { forwardRef } from 'react';

 function Dropzone ({translator, section, updateContentUpload}, ref){
  return (
    <DragAndDrop ref={ref} updateContentUpload={updateContentUpload} folderId={undefined} section={section} >
        <div className="dropzone">
            <img className="img-nocontent" src={NO_CONTENT} />
            <div className="main-content">
                <div className="title">
                    {translator('LIBRARY.UPLOAD_LOOK_LIKE_NOTHING_THERE')}
                </div>
                <div className="content">{translator('LIBRARY.START_CONTENT')}</div>
                <div className="content">{translator('LIBRARY.OR')}</div>
                <div className="content">{translator('LIBRARY.DROP_FILE')}</div>
            </div>
        </div>
    </DragAndDrop>
  )
}

export default forwardRef(Dropzone)

