import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TablePagination from 'components/Pagination';
import ViewTable from './Table ';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import './Member.scss';
import courseService from 'services/course.service';
import SearchBox from 'components/V2/SearchBox';
import { useAppSelector } from 'hooks/hooks';
import { useDebouncedState } from 'hooks/useDebounce';
import { ButtonPrimary } from '_shared';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from '@heroicons/react/outline';
import ReactTooltip from "react-tooltip";
import ModalAddMember from './ModalAddMember';
import { checkPermission } from 'utils/commonFun';
import ModalAddMemberWithCredit from './ModalAddMemberWithCredit';
import DialogComponent from 'components/Dialog/DialogComponent';

const Members: React.FC = () => {
    const params: { id: string; courseId: string } = useParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [keyword, setKeyword] = useDebouncedState('');
    const { enrollmentSelected, setting } = useAppSelector((state) => state.course);
    const {t} = useTranslation();
    const [isOpenModalAddMember, setOpenModalAddMember] = useState(false);
    const [isOpenModalAddMemberWithCredit, setOpenModalAddMemberWithCredit] = useState(false);

    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'asc',
        sort_by: 'display_name',
        total: 0,
    });

    const workspaceId = params.id;
    const courseId = params.courseId;

    const getdata = (page: number | string = 1, order = 'asc') => {
        setLoading(true);
        const payload = {
            ...pagination,
            q: keyword,
            order,
            page,
        };
        delete payload?.total;
        if (!payload.q) delete payload.q;
        courseService
            .getMemberOfEnrollment(workspaceId, courseId, enrollmentSelected?.id, payload)
            .then((res: any) => {
                const { items, total } = res?.data;
                setData(items);
                setPagination({ ...pagination, ...payload, total });
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const handlePagination = (page: number) => {
        getdata(page);
    };

    useEffect(() => {
        if (enrollmentSelected?.id) getdata();
    }, [keyword, enrollmentSelected?.id]);

    return (
      <div className="Members">
          <div className="my-4 flex justify-between">
                <SearchBox onSubmit={(e: any) => setKeyword(e)} className="max-w-sm" />
                    <div
                        data-for='disable-addbtn'
                        data-tip={t("COURSES.DISABLE_ADD_MEMBER_TOOLTIP")}
                    >
                        {checkPermission('coursePeopleEnrollment', 'U') && (
                        <>
                            <ButtonPrimary 
                                type="button" 
                                onClick={() => {
                                    if (setting?.enrollment_type !== 'credit') {
                                        setOpenModalAddMember(true)
                                    }
                                    else setOpenModalAddMemberWithCredit(true)
                                }}
                                disabled={
                                    enrollmentSelected?.display_status !== 'active' && enrollmentSelected?.display_status !== 'enrolled'
                                }
                                className='flex gap-1'
                            >
                                <PlusIcon className='w-5 h-5 text-white' /> {t("ADD")}
                            </ButtonPrimary>
                            {
                                enrollmentSelected?.display_status !== 'active' && enrollmentSelected?.display_status !== 'enrolled' &&
                                /*@ts-ignore*/
                                <ReactTooltip
                                    place="right"
                                    type="light"              
                                    id={`disable-addbtn`}
                                    getContent={(content) => <div className="max-w-[280px] text-left text-sm font-normal p-1">{content}</div>}
                                    className='shadow-lg'
                                />
                            }
                        </>
                        )}
                    </div>
          </div>
          <div className="Members-main">
              <div className="wrapper-content">
                  <div className="scrollbar">
                      {loading && (
                          <div className="loading">
                              <CircleSpin color="text-primary-500" />
                          </div>
                      )}
                      {!loading && (
                          <ViewTable
                              data={data}
                              refreshData={getdata}
                          />
                      )}
                  </div>
                  <div className="pagination">
                      <TablePagination
                          total={pagination.total}
                          perPage={pagination.per_page}
                          onClickPagination={(e) => handlePagination(e)}
                      />
                  </div>
              </div>
          </div>
            {isOpenModalAddMember &&
                <ModalAddMember 
                    isOpen={isOpenModalAddMember}
                    onClose={() => setOpenModalAddMember(false)}
                    refreshData={() => getdata(1)}
                    enrollmentSelected={enrollmentSelected}
                />
            }

            {isOpenModalAddMemberWithCredit &&
                <DialogComponent
                    title={t('COURSES.ADD_MORE_MEMBER')}
                    styles='max-w-xl'
                    isOpen={isOpenModalAddMemberWithCredit}
                    onCloseModal={() => setOpenModalAddMemberWithCredit(false)}
                    child={
                        <ModalAddMemberWithCredit 
                            refreshData={() => getdata(1)}
                            onCloseModal={() => setOpenModalAddMemberWithCredit(false)}
                            enrollmentSelected={enrollmentSelected}
                        />
                    }
                />
            }
                
      </div>
    );
};

export default Members;
