import { forwardRef, useState } from 'react';
import FileUploadIcon from 'assets/icon/FileUploadIcon';
import { useTranslation } from 'react-i18next';
import NEW_H5P from 'assets/img/h5p.png';
import LIBRARY from 'assets/img/library.png';
import H5P_LIBRARY from 'assets/img/h5pLibrary.png';
import DialogComponent from 'components/Dialog/DialogComponent';
import CreateH5p from './CreateH5p/CreateH5p';
import FromLibrary from './FromLibrary/FromLibrary';
import { ExternalLinkIcon, TrashIcon } from '@heroicons/react/outline';
import { TYPE_CREATE_CONTENT, TYPE_LESSON_BUILDER, VIEW_TYPE } from '../../constants';
import './Content.scss';
import Dropzone from './Upload/Dropzone';
import PreviewContent from './PreviewContent/PreviewContent';
import Spinner from 'components/Spinner';
import { ConfirmDeleteModal } from '_shared';
import { checkPermission } from 'utils/commonFun';
import { useHistory, useParams } from 'react-router-dom';

const Content = (
    {
        contentSelected,
        viewType,
        handleAddContentToSection,
        setCreateContentType,
        createContentType,
        sectionSelected,
        handleDeleteSection,
        handleDeleteFileOfSection,
        setOpenModalCreateH5p,
        setOpenModalLibrary,
        isOpenModalCreateH5p,
        isOpenModalLibrary,
        type,
        loadingPreviewContent,
        updateContentUpload,
        updateNote,
        assignmentSelected,
    },
    ref
) => {
    const { t: translator } = useTranslation();
    const history = useHistory();
    const params: any = useParams();

    const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);

    const renderUI = () => {
        if (loadingPreviewContent) {
            return (
                <div className="w-full h-full justify-center items-center flex">
                    <Spinner />
                </div>
            );
        }

        if ((createContentType === TYPE_CREATE_CONTENT.UPLOAD || !contentSelected) && type !== TYPE_LESSON_BUILDER.assignments) {
            /*@ts-ignore*/
            return checkPermission('libraryContentLessonsOrScorm', 'U') ? <Dropzone ref={ref} updateContentUpload={updateContentUpload} section={sectionSelected} translator={translator} /> : '';
        } else {
            return (
                checkPermission('libraryContentLessonsOrScorm', 'R') ?
                    <PreviewContent
                        sectionSelected={sectionSelected}
                        handleDeleteFileOfSection={handleDeleteFileOfSection}
                        contentSelected={contentSelected}
                        viewType={viewType}
                        translator={translator}
                        updateNote={updateNote}
                        isShowNote={type === TYPE_LESSON_BUILDER.learningMaterials}
                        isShowDelete={type !== TYPE_LESSON_BUILDER.assignments && checkPermission('libraryContentLessonsOrScorm', 'U')}
                        colorProcess="text-gray-800"
                    /> : ''
            );
        }
    };
    return (
        <>
            <div
                className={`lessonBuilder-content ${type == TYPE_LESSON_BUILDER.supplementaryMaterials || !sectionSelected ? 'lessonBuilder-content--noDelete' : ''
                    }`}
            >
                {sectionSelected && type != TYPE_LESSON_BUILDER.supplementaryMaterials && (
                    <div className="current-section-bar">
                        <div 
                            className={`current-section-bar-title ${type === TYPE_LESSON_BUILDER.assignments && checkPermission('libraryContentAssignment', "U") ? 'flex items-center w-fit text-enterprise cursor-pointer' : ''}`}
                            onClick={() => {
                                type === TYPE_LESSON_BUILDER.assignments && checkPermission('libraryContentAssignment', "U") && history.push(`/workspace/${params?.id}/assignment/${assignmentSelected?.library_content?.object_id}`);
                            }}
                        >
                            {type === TYPE_LESSON_BUILDER.assignments
                                ? assignmentSelected?.library_content?.name
                                : sectionSelected?.title}
                            {type === TYPE_LESSON_BUILDER.assignments && checkPermission('libraryContentAssignment', "U") && (
                                <span className='ml-2'>
                                    <ExternalLinkIcon className='w-6 h-6' />
                                </span>
                            )}
                        </div>
                        {checkPermission('libraryContentLessonsOrScorm', "D") ? <div className="lessonBuilder-content-delete" onClick={() => setIsOpenModalConfirm(true)}>
                            <TrashIcon className="icon" />
                        </div> : ''}

                    </div>
                )}
                <div
                    className={`${viewType === VIEW_TYPE.MOBILE ? 'mobile-view' : 'w-full'}  
                    lessonBuilder-render-content 
                    ${type === TYPE_LESSON_BUILDER.assignments ? 'lessonBuilder-render-assignment' : ''} `}
                >
                    {renderUI()}
                </div>
                {(type != TYPE_LESSON_BUILDER.assignments && checkPermission('libraryContentLessonsOrScorm', "U")) && (
                    <div className="action-bar">
                        <div
                            onClick={() => {
                                setCreateContentType(TYPE_CREATE_CONTENT.UPLOAD);
                                ref?.current?.click();
                            }}
                            className=" btn-action"
                        >
                            <div>
                                <div className="flex flex-col justify-center items-center w-full gap-2">
                                    <FileUploadIcon />
                                    <p className="text-sm font-semibold group-hover:text-primary-500">
                                        {translator('LIBRARY.UPLOAD_FILE')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            onClick={() => {
                                setCreateContentType(TYPE_CREATE_CONTENT.NEW_H5P);
                                setOpenModalCreateH5p(true);
                            }}
                            className="btn-action"
                        >
                            <div className="btn-content">
                                <img src={NEW_H5P} />
                                <div>{translator('LIBRARY.NEW_H5P')}</div>
                            </div>
                        </div>
                        <div className="line"></div>
                        <div
                            onClick={() => {
                                setOpenModalLibrary(true);
                                setCreateContentType(TYPE_CREATE_CONTENT.LIBRARY);
                            }}
                            className="btn-action"
                        >
                            <div className="btn-content">
                                <img src={LIBRARY} />
                                {translator('LIBRARY.LIBRARY')}
                            </div>
                        </div>
                        <div
                            onClick={() => {
                                setOpenModalLibrary(true);
                                setCreateContentType(TYPE_CREATE_CONTENT.H5P_LIBRARY);
                            }}
                            className="btn-action"
                        >
                            <div className="btn-content">
                                <img src={H5P_LIBRARY} />
                                {translator('LIBRARY.H5P_LIBRARY')}
                            </div>
                        </div>
                    </div>
                )}
                {isOpenModalCreateH5p && (
                    <DialogComponent
                        isOpen={isOpenModalCreateH5p}
                        onCloseModal={() => setOpenModalCreateH5p(false)}
                        hasNonTitle
                        styles="max-w-[90vw] min-h-[90vh] h-auto my-0 rounded-lg"
                        isShowClose={false}
                        child={
                            <CreateH5p
                                handleAddContentToSection={handleAddContentToSection}
                                onCloseModal={() => setOpenModalCreateH5p(false)}
                                payload={{ from_lesson: true }}
                            />
                        }
                    />
                )}
                {isOpenModalLibrary && (
                    <DialogComponent
                        isOpen={isOpenModalLibrary}
                        onCloseModal={() => setOpenModalLibrary(false)}
                        hasNonTitle
                        styles="max-w-[70vw] min-h-[480px] my-0 rounded-lg !p-0 pb-4"
                        isShowClose={false}
                        child={
                            <FromLibrary
                                createContentType={createContentType}
                                type={type}
                                title={
                                    createContentType === TYPE_CREATE_CONTENT.H5P_LIBRARY
                                        ? translator('LIBRARY.ADD_H5PS')
                                        : translator('LIBRARY.ADD_FROM_LIBRARY')
                                }
                                onCloseModal={() => setOpenModalLibrary(false)}
                                handleAddContentToSection={handleAddContentToSection}
                            />
                        }
                    />
                )}
            </div>
            <ConfirmDeleteModal
                isOpen={isOpenModalConfirm}
                onClose={(value: boolean) => {
                    if (value) {
                        if (type === TYPE_LESSON_BUILDER.assignments) {
                            handleDeleteFileOfSection(sectionSelected, assignmentSelected?.id);
                            setIsOpenModalConfirm(false);
                            return;
                        }
                        handleDeleteSection(sectionSelected?.id);
                    }
                    setIsOpenModalConfirm(false);
                }}
                titleModal={type === TYPE_LESSON_BUILDER.assignments ? translator('LIBRARY.DELETE_ASSIGNMENT') : translator('LIBRARY.DELETE_SECTION')}
            >
                <div className="text-sm">
                    {
                        type === TYPE_LESSON_BUILDER.assignments ?
                            translator('LIBRARY.DELETE_ASSIGNMENT_MESSAGE')
                            :
                            translator('LIBRARY.DELETE_SECTION_MESSAGE')
                    }
                </div>
            </ConfirmDeleteModal>
        </>
    );
};

export default forwardRef(Content);
