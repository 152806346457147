import { FC } from 'react';
import ReactSelect, { OptionsOrGroups, SelectComponentsConfig, StylesConfig, components } from 'react-select';
import { Control, Controller } from 'react-hook-form';
import { classNames, UUID } from 'utils/commonFun';
import ErrorMessageForm from 'components/ErrorMessageForm';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import AsyncPaginateCreatable from './CreatableAsyncPaginate';
import { InformationCircleIcon, SearchIcon, XIcon } from '@heroicons/react/outline';
import { Tooltip } from '_shared';
import EmptyStateImg from 'assets/img/empty-state/empty-stage-option.png';

const styles: StylesConfig = {
    control: (base: any, { selectProps }) => {
        return {
            ...base,
            borderColor: selectProps.className?.includes('react-select__custom_error') ? '#EF4444' : '#D1D5DB',
        };
    },
    option: (base: any, { isSelected }) => {
        return {
            ...base,
            color: '#2E3A59',
            fontSize: 12,
            fontWeight: 400,
            background: isSelected ? '#7dd3fc' : '#FFFFFF',
            '&:hover': {
                background: '#bae6fd',
            },
        };
    },
    valueContainer: (base) => ({
        ...base,
        fontSize: 12,
        fontWeight: 400,
        color: '#2E3A59',
    }),
    indicatorSeparator: (base) => ({ ...base, display: 'none' }),
    dropdownIndicator: (base, { selectProps }) => ({
        ...base,
        paddingLeft: 0,
        paddingRight: 0,
        transition: 'all 0.3s ease-in-out',
        transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    }),
    clearIndicator: (base) => ({
        ...base,
        paddingLeft: 0,
        paddingRight: 0,
    }),
    indicatorsContainer: (base) => ({
        paddingRight: 4,
        display: 'flex',
    }),
    placeholder: (base) => ({
        ...base,
        fontWeight: 400,
        color: '#8F90A6',
        whiteSpace: 'nowrap'
    }),
    singleValue: (base) => ({
        ...base,
        fontWeight: 400,
        color: '#2E3A59',
    }),
    menuList: (base) => ({
        ...base,
        zIndex: 99999
    }),
    multiValue: (base) => ({
        ...base,
        background: '#E5F4FF',
        border: 1,
        borderColor: '#5CA5E2',
        borderStyle: 'solid'
    }),
    multiValueLabel: (base) => ({
        ...base,
        color: '#0071CE',
    }),
};

const formatOptionLabel = ({ value, label, title }) => {
    return (
        <div>
            <div>{label || title}</div>
        </div>
    );
};

type SelectFieldProps = {
    name?: string;
    placeholder?: string;
    customStyles?: StylesConfig | any;
    className?: string;
    options: OptionsOrGroups<any, any>;
    isMulti?: boolean;
    isClearable?: boolean;
    isSearchable?: boolean;
    componentsSelect?: SelectComponentsConfig<any, any, any>;
    label?: string;
    isRequired?: boolean;
    errors?: any;
    isDisabled?: boolean;
    control?: Control<any>;
    rules?: any;
    getOptionLabel?: any;
    getOptionValue?: any;
    hideSelectedOptions?: boolean;
    closeMenuOnSelect?: boolean;
    onMenuClose?: any;
    size?: string;
    noOptionsMessage?: any;
    onChange?: (newOptions: any) => void;
};

export const SelectField: FC<SelectFieldProps> = ({
    closeMenuOnSelect = true,
    hideSelectedOptions = false,
    name = 'select',
    placeholder = '',
    customStyles,
    className,
    options,
    isMulti = false,
    isClearable = false,
    isSearchable = true,
    componentsSelect,
    label,
    isRequired = false,
    errors,
    isDisabled,
    control,
    rules,
    getOptionLabel,
    getOptionValue,
    onMenuClose,
    size = 'L',
    noOptionsMessage,
    onChange,
}) => {
    const { t: translator } = useTranslation();
    const id = UUID();
    let styles = { ...stylesCreatableAsyncSelectField, ...customStyles };
    if (size == 'M') {
        styles = { ...styles, ...stylesCreatableAsyncSelectFieldM }
    }
    return (
        <div className={classNames('flex flex-col space-y-1', className)}>
            {!!label && (
                <label htmlFor={id} className="font-semibold text-xs text-gray-800">
                    {label}
                    {isRequired && <span className="text-red-500"> *</span>}
                </label>
            )}
            <Controller
                rules={{
                    required: {
                        value: isRequired,
                        message: translator('FORM_CONST.REQUIRED_FIELD'),
                    },
                    ...rules,
                }}
                control={control}
                name={name}
                render={({ field }) => (
                    <ReactSelect
                        id={id}
                        ref={field.ref}
                        className={classNames(!!errors && errors[name] && 'react-select__custom_error')}
                        styles={styles}
                        isDisabled={isDisabled}
                        isMulti={isMulti}
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        placeholder={placeholder}
                        options={options}
                        components={componentsSelect}
                        getOptionLabel={getOptionLabel}
                        getOptionValue={getOptionValue}
                        hideSelectedOptions={hideSelectedOptions}
                        closeMenuOnSelect={closeMenuOnSelect}
                        onMenuClose={onMenuClose}
                        noOptionsMessage={noOptionsMessage || (() => 
                                <div className='flex flex-col justify-center items-center h-full py-5'>
                                    <img className='w-32' src={EmptyStateImg} alt="No options" />
                                    <div className="text-sm text-gray-400">{translator('FORM_CONST.NO_DATA')}</div>
                                </div>
                            
                        )}
                        
                        {...field}
                        onChange={(newValue: any, actionMeta: any) => {
                            field.onChange(newValue, actionMeta);
                            onChange?.(newValue);
                        }}
                    />
                )}
            />
            {!!errors && <ErrorMessageForm errors={errors} name={name} />}
        </div>
    );
};

type AsyncSelectFieldProps = {
    key?: any;
    name?: string;
    placeholder?: string;
    customStyles?: StylesConfig | any;
    className?: string;
    isMulti?: boolean;
    isClearable?: boolean;
    isSearchable?: boolean;
    componentsSelect?: SelectComponentsConfig<any, any, any>;
    label?: string;
    isRequired?: boolean;
    errors?: any;
    isDisabled?: boolean;
    control: Control<any>;
    rules?: any;
    loadOptions: LoadOptions<any, any, any>;
    hideSelectedOptions?: boolean;
    closeMenuOnSelect?: boolean;
    getOptionLabel?: any;
    getOptionValue?: any;
    onMenuClose?: any;
    formatOptionLabelCustom?: any;
    searchIcon?: boolean;
    labelSplit?: boolean;
    hasAvatar?: boolean;
    size?: string;
    stylesAsyncSelect?: string;
    toolTip?: string;
    dataSelected?: any;
    noOptionsMessage?: any
};

export const AsyncSelectField: FC<AsyncSelectFieldProps> = ({
    key='async-select-field',
    name = 'async-select',
    placeholder = '',
    customStyles,
    className,
    isMulti = false,
    isClearable = false,
    isSearchable = true,
    componentsSelect,
    label,
    isRequired = false,
    errors,
    isDisabled,
    control,
    rules,
    loadOptions,
    hideSelectedOptions = false,
    closeMenuOnSelect = true,
    getOptionLabel,
    getOptionValue,
    onMenuClose,
    formatOptionLabelCustom,
    searchIcon = false,
    labelSplit = false,
    hasAvatar = false,
    size = 'L',
    stylesAsyncSelect = '',
    toolTip = '',
    dataSelected = null,
    noOptionsMessage
}) => {
    const { t: translator } = useTranslation();
    const id = UUID();
    let stylesSize = { ...styles, ...customStyles };
    if (size == 'M') {
        stylesSize = { ...stylesSize, ...stylesCreatableAsyncSelectFieldM }
    }
    return (
        <div className={classNames('flex flex-col space-y-1', className)}>
            {label ? (
                <label htmlFor={id} className="flex font-semibold text-xs text-gray-800">
                    <span>
                        {label}
                        {isRequired && <span className="text-red-500"> *</span>}
                    </span>
                    {toolTip?.length > 0 ? <div className='relative'>
                        <Tooltip styles='!top-[-12px] !left-[25px] max-w-[320px] w-[320px] !whitespace-break-spaces tooltipContainer-infoleft' info={toolTip}>
                            <span
                                className='w-5 h-5'
                            ><InformationCircleIcon className='inline-block w-5 h-5 cursor-pointer -mt-1 ml-1' /></span>
                        </Tooltip>
                    </div> : null}
                </label>
            ) : null}
            <Controller
                rules={{
                    required: {
                        value: isRequired,
                        message: translator('FORM_CONST.REQUIRED_FIELD'),
                    },
                    ...rules,
                }}
                control={control}
                name={name}
                render={({ field }) => (
                    <AsyncPaginate
                        key={key}
                        loadOptions={loadOptions}
                        additional={{
                            page: 1,
                        }}
                        debounceTimeout={500}
                        className={classNames(!!errors && errors[name] && 'react-select__custom_error', stylesAsyncSelect)}
                        styles={stylesSize}
                        isDisabled={isDisabled}
                        isSearchable={isSearchable}
                        isMulti={isMulti}
                        isClearable={isClearable}
                        hideSelectedOptions={hideSelectedOptions}
                        closeMenuOnSelect={closeMenuOnSelect}
                        placeholder={placeholder}
                        menuPlacement="auto"
                        value={field.value}
                        getOptionLabel={getOptionLabel}
                        getOptionValue={getOptionValue}
                        formatOptionLabel={formatOptionLabelCustom ? formatOptionLabelCustom : formatOptionLabel}
                        onChange={(newValue: any, actionMeta: any) => field.onChange(newValue, actionMeta)}
                        noOptionsMessage={noOptionsMessage || (() => {
                            return (
                                <div className='flex flex-col justify-center items-center h-full py-5'>
                                    <img className='w-32' src={EmptyStateImg} alt="No options" />
                                    <div className="text-sm text-gray-400">{translator('FORM_CONST.NO_DATA')}</div>
                                </div>
                            );
                        })}
                        
                        components={{
                            Option: ({ children, ...props }) => {
                                // FIX LAG
                                const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
                                const newProps: any = Object.assign(props, { innerProps: rest });

                                return (
                                    <components.Option {...newProps}>
                                        {hasAvatar ? (
                                            <div className=" flex items-center">
                                                <img className=" rounded-full w-5 h-5 mr-2" src={newProps?.data?.avatar} alt="avatar" />
                                                <label className="max-w-xs truncate text-ellipsis text-dark-800">{props?.label}</label>
                                            </div>
                                        ) : (
                                            children
                                        )}
                                    </components.Option>
                                );
                            },
                            Control: ({ children, ...props }) => {
                                const style = { cursor: 'pointer' };
                                return (
                                    <components.Control {...props}>
                                        {(searchIcon && (
                                            <span style={style}>
                                                <SearchIcon className="w-4 h-4 ml-2 text-gray-400" />
                                            </span>
                                        )) ||
                                            ''}

                                        {children}
                                    </components.Control>
                                );
                            },
                            MultiValueRemove: ({ children, ...props }) => {
                                if (!dataSelected || dataSelected?.length == 0 || !dataSelected?.find(item => item.id == props?.data?.id)) {
                                    return <components.MultiValueRemove {...props}>
                                        <XIcon className='w-4 h-4' />
                                    </components.MultiValueRemove>
                                }
                                return null;
                            },
                            MultiValue: ({ children, ...props }) => {
                                const newProp: any = props;
                                const content = newProp?.data?.label?.substring(0, 12);

                                return (
                                    <components.MultiValue {...props}>
                                        {labelSplit ? (
                                            <div className="flex items-center text-[10px] text-dark-300 font-semibold">
                                                {content}...
                                            </div>
                                        ) : (
                                            children
                                        )}
                                    </components.MultiValue>
                                );
                            },
                            ...componentsSelect,
                        }}
                        onMenuClose={onMenuClose}
                        onKeyDown={(event) => {
                            if ((event.key === 'Delete' || event.key === 'Backspace') && !isSearchable) {
                              event.preventDefault();
                            }
                          }
                        }
                    />
                )}
            />
            {!!errors && <ErrorMessageForm errors={errors} name={name} />}
        </div>
    );
};

type CreatableAsyncSelectFieldProps = {
    name?: string;
    placeholder?: string;
    customStyles?: StylesConfig | any;
    className?: string;
    isClearable?: boolean;
    isSearchable?: boolean;
    componentsSelect?: SelectComponentsConfig<any, any, any>;
    label?: string;
    isRequired?: boolean;
    isMulti?: boolean;
    isSearchIcon?: boolean;
    limitSearch?: number | undefined;
    errors?: any;
    isDisabled?: boolean;
    control: Control<any>;
    rules?: any;
    loadOptions: LoadOptions<any, any, any>;
    hideSelectedOptions?: boolean;
    closeMenuOnSelect?: boolean;
    handleCreate?: (value) => void;
    onChange?: (newOptions: any) => void;
    valueAsLabel?: boolean;
    textOptionNew?: string;
    noOptionsMessage?: any
};

const stylesCreatableAsyncSelectField = {
    control: (styles: any, { selectProps }) => ({
        ...styles,
        boxShadow: 'none !important',
        borderColor: selectProps.className?.includes('react-select__custom_error') ? '#EF4444' : '#d1d5db',
    }),
    menu: (styles: any) => ({
        ...styles,
        // height: 250,
        zIndex: 2,
    }),
    menuList: (styles: any) => ({
        ...styles,
        height: 'auto',
    }),
    option: (styles: any) => {
        return {
            ...styles,
            color: '#2E3A59',
            fontSize: 12,
            fontWeight: 400,
            background: '#ffffff',
            '&:hover': {
                background: '#DEEBFF',
            },
        };
    },
    multiValue: (styles: any) => {
        return {
            ...styles,
            background: '#EEF1FA',
        };
    },
    input: (styles: any) => ({
        ...styles,
        width: 90,
    }),
    indicatorsContainer: (styles) => ({
        ...styles,
        // display: 'none',
    }),
    indicatorSeparator: (base) => ({ ...base, display: 'none' }),
    dropdownIndicator: (base, { selectProps }) => ({
        ...base,
        paddingLeft: 0,
        paddingRight: 4,
        transition: 'all 0.3s ease-in-out',
        transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    }),
    valueContainer: (base) => ({
        ...base,
        fontSize: 12,
        fontWeight: 400,
        color: '#2E3A59'
    }),
    placeholder: (base) => ({
        ...base,
        fontWeight: 400,
        color: '#8F90A6',
    }),
};

const stylesCreatableAsyncSelectFieldM = {
    control: (base) => {
        return { ...base, minHeight: '0px', height: 'auto', boxShadow: 'none' }
    },
    valueContainer: (base) => {
        return { ...base, paddingTop: 0, paddingBottom: 0, fontSize: 14, fontWeight: 400 }
    },
    indicatorsContainer: (base) => {
        return { ...base, padding: '0px 8px', height: '30px' }
    },
    indicatorSeparator: (base) => {
        return { ...base, display: 'none' }
    },
}

export const CreatableAsyncSelectField: FC<CreatableAsyncSelectFieldProps> = ({
    name = 'create-async-select',
    placeholder = '',
    customStyles,
    className,
    isClearable = false,
    isSearchable = true,
    componentsSelect,
    label,
    isRequired = false,
    isMulti = true,
    isSearchIcon = true,
    limitSearch = 50,
    errors,
    isDisabled,
    control,
    rules,
    loadOptions,
    hideSelectedOptions = true,
    closeMenuOnSelect = true,
    handleCreate,
    onChange,
    valueAsLabel = false,
    textOptionNew = 'Create',
    noOptionsMessage
}) => {
    const { t: translator } = useTranslation();
    const id = UUID();

    return (
        <div className={classNames('flex flex-col space-y-1', className)}>
            {!!label && (
                <label htmlFor={id} className="font-semibold text-xs text-ooolab_dark_1">
                    {label}
                    {isRequired && <span className="text-red-500"> *</span>}
                </label>
            )}
            <Controller
                rules={{
                    required: {
                        value: isRequired,
                        message: translator('FORM_CONST.REQUIRED_FIELD'),
                    },
                    ...rules,
                }}
                control={control}
                name={name}
                render={({ field }) => (
                    <>
                        <AsyncPaginateCreatable
                            loadOptions={loadOptions}
                            additional={{
                                page: 1,
                            }}
                            className={classNames(!!errors && errors[name] && 'react-select__custom_error')}
                            debounceTimeout={500}
                            styles={{ ...stylesCreatableAsyncSelectField, ...customStyles }}
                            isDisabled={isDisabled}
                            isMulti={isMulti}
                            onCreateOption={handleCreate}
                            isClearable={isClearable}
                            hideSelectedOptions={hideSelectedOptions}
                            closeMenuOnSelect={closeMenuOnSelect}
                            placeholder={placeholder}
                            menuPlacement="auto"
                            value={field.value}
                            onChange={(newValue: any, actionMeta: any) => {
                                if (valueAsLabel) {
                                    const newOption = !newValue
                                        ? newValue
                                        : {
                                            ...newValue,
                                            label: newValue.value,
                                        };
                                    field.onChange(newOption, actionMeta);
                                } else field.onChange(newValue, actionMeta);
                                if (onChange) {
                                    onChange(newValue);
                                }
                            }}
                            noOptionsMessage={noOptionsMessage || (() => {
                                return (
                                    <div className='flex flex-col justify-center items-center h-full py-5'>
                                        <img className='w-32' src={EmptyStateImg} alt="No options" />
                                        <div className="text-sm text-gray-400">{translator('FORM_CONST.NO_DATA')}</div>
                                    </div>
                                );
                            })}
                            components={{
                                Control: (props: any) => {
                                    return (
                                        <components.Control {...props}>
                                            {isSearchIcon && <SearchIcon className="h-5 w-5 text-dark-100 ml-2" />}
                                            {props.children}
                                        </components.Control>
                                    );
                                },
                                Option: ({ children, ...props }) => {
                                    // FIX LAG
                                    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
                                    const newProps = Object.assign(props, { innerProps: rest });
                                    return (
                                        <components.Option {...newProps}>
                                            {/* @ts-ignore */}
                                            {textOptionNew !== 'Create' ? children?.replace('Create', textOptionNew) : children}
                                        </components.Option>
                                    );
                                },
                                Input: (props) => {
                                    return (
                                        <components.Input {...props} maxLength={limitSearch}>
                                            {props.children}
                                        </components.Input>
                                    );
                                },
                            }}
                        />
                    </>
                )}
            />
            {!!errors && <ErrorMessageForm errors={errors} name={name} />}
        </div>
    );
};
