import httpClient, { RestfulService } from './restful.service';
import { HOST_URL } from 'constant/api.const';
import { LibraryTypeEnum } from 'components/Library/LibraryContants';
import qs from 'qs';

const getAssignment = async (payload) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${payload.workspaceId}/exercises/${payload.assignmentId}`);
};

const getCustomField = async (payload) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${payload.workspaceId}/exercises/${payload.assignmentId}/custom-fields`);
};

const createCustomField = async (payload) => {
    return RestfulService.postApi(HOST_URL + `/workspaces/${payload.workspaceId}/exercises/${payload.assignmentId}/custom-fields`, payload);
};

const updateAssignment = async (payload) => {
    return RestfulService.patchApi(HOST_URL + `/workspaces/${payload.workspaceId}/exercises/${payload.assignmentId}`, payload);
};

const createAssignment = async (payload) => {
    return RestfulService.postApi(HOST_URL + `/workspaces/${payload.workspaceId}/exercises`, payload);
};

const createContentAssignment = async (payload) => {
    return RestfulService.postApi(HOST_URL + `/workspaces/${payload.workspaceId}/exercises/${payload.assignmentId}/contents`, payload);
};

const sortContentAssignment = async (payload) => {
    return RestfulService.patchApi(HOST_URL + `/workspaces/${payload.workspaceId}/exercises/${payload.assignmentId}/contents/sort`, {
        ids: payload.ids
    });
};

const renameContentAssignment = (payload) => {
    let url = `/workspaces/${payload.workspaceId}/files/${payload?.contentId}`;
    let params: {name?: string, title?: string} = {
        name: payload.title
    };
    if (payload.type == LibraryTypeEnum.h5p) {
        url = `/h5p/workspaces/${payload.workspaceId}/${payload?.contentId}/rename`;
        params = {
            title: payload.title
        }
    }
    if (payload.type == LibraryTypeEnum.submission) {
        url = `/workspaces/${payload.workspaceId}/submissions/${payload?.contentId}`;
        params = {
            title: payload.title
        }
    }
    return RestfulService.patchApi(HOST_URL + url, params);
};

const deleteContentAssignment = async (payload) => {
    return RestfulService.deleteApi(HOST_URL + `/workspaces/${payload.workspaceId}/exercises/${payload.assignmentId}/contents/${payload?.contentId}`);
};

const createSubmission = async (payload) => {
    return RestfulService.postApi(HOST_URL + `/workspaces/${payload.workspaceId}/submissions`, payload);
};

const updateSubmission = async (workspaceId, submissionId, payload) => {
    return RestfulService.patchApi(HOST_URL + `/workspaces/${workspaceId}/submissions/${submissionId}`, payload);
};

const getSubmisstion = async (workspaceId, submissionId) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${workspaceId}/submissions/${submissionId}`);
};
const getAssignmentList = async (workspaceId: string, params?: any) => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/instructors/assignments${params ? `?${qs.stringify(params)}` : ''}`;

    const res = await RestfulService.getApi(url);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const updateDueDateAssignmentService = (workspaceId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/instructors/assignments/bulk`;
    return httpClient.post(url, params);
}

const getAssociatedLessonAssignment = async (payload?: any) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${payload.workspaceId}/exercises/${payload.assignmentId}/lessons`, { skip_pagination: true });
};

export default {
    getAssignment,
    updateAssignment,
    createAssignment,
    getCustomField,
    createCustomField,
    createContentAssignment,
    sortContentAssignment,
    deleteContentAssignment,
    renameContentAssignment,
    createSubmission,
    updateSubmission,
    getSubmisstion,
    getAssignmentList,
    updateDueDateAssignmentService,
    getAssociatedLessonAssignment,
};
