import { FC, useContext, useMemo, useState } from 'react';
import { CourseType } from 'types/Courses.type';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import ActionMenu from './ActionMenu';
import dayjs from 'dayjs';
import './Table.scss';
import NO_CONTENT from 'assets/img/empty-state/report.png';
import { useParams } from 'react-router-dom';
import { UserContext } from 'contexts/User/UserContext';
import { ListUser } from '_shared/components/User/User';
import { DocumentPreview } from '_shared/components/DocumentPreview/DocumentPreview';
import { checkPermission } from 'utils/commonFun';

interface IViewTable {
    data: CourseType[];
    refreshData: Function;
    setOrder: (value) => void,
    order: string
}

const ViewTable: FC<IViewTable> = ({ data, refreshData, order, setOrder }) => {
    const { t: translator } = useTranslation();

    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;
    const params: { id: string } = useParams();
    const {
        userState: { result },
    } = useContext(UserContext);
    const initialState = {
        hiddenColumns: ['id'],
    };
    const [isOpenViewFile, setOpenViewFile] = useState(false);
    const [reportSelect, setReportSelect] = useState<any>();

    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('COURSES.REPORT_NAME')} />,
                Cell: ({ row }) => {
                    return (
                        <RenderTableCell
                            className="text-primary-500 font-semibold"
                            value={row.values?.name}
                            onClick={() => {
                                setReportSelect(row?.original);
                                setOpenViewFile(true);
                            }}
                        />
                    );
                },
                accessor: 'name',
                width: '180',
            },
            {
                Header: () => <RenderTableHeader value={translator('USER.COURSE_NAME')} />,
                accessor: 'course',
                Cell: ({ row }: any) => {
                    return (
                        checkPermission('course', 'R') ? <RenderTableCell
                            className="text-primary-500 font-semibold cursor-pointer"
                            value={row.values?.course?.title}
                            onClick={() => window.open(`/workspace/${params?.id}/management/courses/${row.values?.course?.id}`, '_blank')}
                        /> :
                            <RenderTableCell
                                value={row.values?.course?.title}
                            />
                    );
                },
                width: '180',
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.CREATED_BY')} />,
                accessor: 'created_by',
                Cell: ({ row }: any) => {
                    return (
                        <div>
                            <ListUser
                                users={[row?.original?.created_by || []]?.map((item) => {
                                    return {
                                        ...item,
                                        link: `/workspace/${params?.id}/management/users/${item?.id}?tab=information`
                                    }
                                })}
                                id={'created_by'}
                            />
                        </div>
                    );
                },
                width: '180',
            },
            {
                Header: () => <RenderTableHeader className='text-primary-500' order={order} isOrder={true} onClick={() => setOrder(order === 'desc' ? 'asc' : 'desc')} value={translator('COURSES.RELEASE_DATE')} />,
                accessor: 'released_at',
                Cell: ({ row }: any) => (
                    <RenderTableCell value={row?.original?.status == 'released' ? dayjs.utc(row.original?.released_at).tz(result?.time_zone).format('DD/MM/YYYY') : dayjs.utc(row.original?.schedule_at).tz(result?.time_zone).format('DD/MM/YYYY')} />
                ),
                width: '120',
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.STATUS')} />,
                accessor: 'status',
                Cell: ({ row }: any) => {
                    if (!row?.original?.status) return <RenderTableCell value="" />;
                    return (
                        <div className="py-3 ml-2">
                            <span
                                className={`InstructorStatus
                                                ${row?.values?.status == 'scheduled' ? 'scheduled' : ''}
                                                ${row?.values?.status == 'released' ? 'released' : ''}
                                            `}
                            >
                                {translator(row?.original?.status?.toUpperCase())}
                            </span>
                        </div>
                    );
                },
                width: '100',
            },
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="h-full flex items-center justify-center">
                            <ActionMenu data={row?.original} refreshData={refreshData} position={row?.index > data?.length / 2 ? 'bottom-0' : ''} />
                        </div>
                    );
                },
                accessor: 'action',
                width: 60,
            },
        ],
        [data, WorkspaceDetailInformation]
    );

    return (
        <>
            <Table
                data={data}
                columns={columns}
                initialState={initialState}
                emptyContent={translator('COURSES.NO_REPORTS')}
                emptyIcon={NO_CONTENT}
            />
            {isOpenViewFile &&
                <DocumentPreview
                    closeModal={() => setOpenViewFile(false)}
                    isOpen={isOpenViewFile}
                    data={{
                        type: reportSelect?.file_mime_type,
                        name: reportSelect?.file_url.substring(reportSelect?.file_url?.lastIndexOf('/') + 1),
                        url: reportSelect?.file_url
                    }}
                />

            }
        </>
    );
};

export default ViewTable;
