import { useEffect, useMemo } from 'react';
import { InputField } from 'components/InputForm/InputForm';
import { useTranslation } from 'react-i18next';
import { SwitchToggle } from '_shared/components/Form/SwitchToggle/SwitchToggle';
import '../../CourseSetting.scss';
import { FORM_CONST } from 'constant/form.const';
import { VALIDATE_FIELD } from 'utils/validate';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import { useHistory, useParams } from 'react-router-dom';

export const Scheduling = ({ notAllowEdit = false, formProps }) => {
    const { t } = useTranslation();
    const params: { id: string } = useParams();
    const history = useHistory();

    const {
        control,
        register,
        formState: { errors },
        watch,
        reset,
        setValue,
        getValues,
        clearErrors,
    } = formProps;

    const schedule_type = watch('schedule_type');
    const allowFlexible = watch('allowFlexible');
    const absence_request_type = watch('absence_request_type');

    useEffect(() => {
        if (getValues('schedule_type') != 'no_schedule') {
            setValue('schedule_type', allowFlexible ? 'schedule_allow_learner_booking' : 'schedule_disallow_learner_booking');
        } else {
            setValue('allowFlexible', false);
        }
    }, [allowFlexible, schedule_type]);

    useEffect(() => {
        if (errors?.remove_learner_hours?.type === 'timeRequestValid' && (watch('remove_learner_hours') || watch('remove_learner_mins'))) {
            clearErrors('remove_learner_hours');
            clearErrors('remove_learner_mins');
        }
    }, [watch('remove_learner_hours'), watch('remove_learner_mins')]);

    return (
        <div className='py-10'>
            <div className="courseSettings-title">
                {t('COURSES.SESSION')}
                <p>{t('COURSES.SCHEDULING_DES')}</p>
            </div>
            <div className={`courseSettings-container  ${notAllowEdit ? 'opacity-container' : ''}  `}>
                <div className="courseSettings-item flex-col !gap-0">
                    <h3 className="courseSetting-subTitle font-semibold">{t('COURSES.SESSION_BOOKING')}</h3>
                    <div className="flex items-start gap-2 mt-2">
                        <div className="items-start">
                            <SwitchToggle
                                name="schedule_type"
                                control={control}
                                disabled={notAllowEdit}
                                enableColor
                                checked={
                                    schedule_type === 'schedule_allow_learner_booking' ||
                                    schedule_type === 'schedule_disallow_learner_booking'
                                }
                                onChange={(val) => {
                                    setValue('schedule_type', !val ? 'no_schedule' : 'schedule_disallow_learner_booking');
                                    setValue('allowFlexible', false);
                                }}
                            />
                        </div>
                        <div className="courseSettings-right">
                            <div className="courseSettings-label !text-sm">{t('COURSES.ADMIN_SCHEDULING')}</div>
                            <p className="mt-1">{t('COURSES.ADMIN_SCHEDULING_DES')}</p>
                            {getValues('enrollment_type') === 'credit' && (
                                <div className="flex flex-col mt-2">
                                    <div className="flex gap-2">
                                        <div className="courseSettings-left">
                                            <SwitchToggle
                                                name="allowFlexible"
                                                control={control}
                                                disabled={notAllowEdit || schedule_type == 'no_schedule'}
                                                enableColor
                                            />
                                        </div>
                                        <div className="courseSettings-right">
                                            <div className="courseSettings-label !text-sm !font-normal">
                                                {t('COURSES.ALLOW_LEARNER_BOOKING')}
                                            </div>
                                        </div>
                                    </div>
                                    <p>{t('COURSES.ALLOW_LEARNER_BOOKING_DES')}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="courseSettings-item flex-col !gap-0">
                    <h3 className="courseSetting-subTitle font-semibold">{t('COURSES.ABSENCE_REQUEST')}</h3>
                    <div className="flex items-start gap-2 mt-2">
                        <div className="items-start">
                            <SwitchToggle
                                name="absence_request_type"
                                control={control}
                                disabled={notAllowEdit}
                                enableColor
                                checked={absence_request_type === 'auto_remove_learner'}
                                onChange={(val) => {
                                    setValue('absence_request_type', !val ? 'not_auto_remove_learner' : 'auto_remove_learner');
                                    if (!val) {
                                        setValue('remove_learner_hours', null);
                                        setValue('remove_learner_mins', null);
                                    }
                                }}
                            />
                        </div>
                        <div className="courseSettings-right">
                            <div className="courseSettings-label !text-sm">{t('COURSES.AUTO_REMOVE_LEARNER')}</div>
                            <p className="mt-1">{t('COURSES.AUTO_REMOVE_LEARNER_DES')}</p>
                            <div className="flex items-center gap-1 mt-1">
                                <p className="!text-gray-800">{t('COURSES.AUTO_REMOVE_LEARNER_TIME_IN')}</p>
                                <div className="px-4 py-2 rounded-lg bg-gray-50 flex items-center gap-1">
                                    <div
                                        className={`flex items-center gap-1 truncate relative min-w-[104px] ${Object.keys(errors).length ? 'min-h-[52px] !items-start' : ''
                                            }`}
                                    >
                                        <InputField
                                            placeholder={''}
                                            name="remove_learner_hours"
                                            type="number"
                                            classNameInput="!w-14 max-h-8"
                                            className="truncate"
                                            classError="min-h-[16px]"
                                            disabled={absence_request_type === 'not_auto_remove_learner'}
                                            errors={errors}
                                            register={register('remove_learner_hours', {
                                                pattern: {
                                                    value: VALIDATE_FIELD.NUMBER_0_9,
                                                    message: 'Please enter integer number',
                                                },
                                                max: {
                                                    value: 1000,
                                                    message: 'Enter only numbers less than 1000',
                                                },
                                                validate: {
                                                    timeRequestValid: (value) => {
                                                        const hours = value || 0;
                                                        const mins = watch('remove_learner_mins') || 0;

                                                        const timeRequest = hours * 60 + Number(mins);

                                                        if (timeRequest > 0 || absence_request_type === 'not_auto_remove_learner') return true;
                                                        return 'Time must be at least 1 minute';
                                                    }
                                                }
                                            })}
                                        />
                                        <span className="absolute top-[8px] left-[62px] text-xs text-gray-500">{t('HOUR_S')}</span>
                                    </div>
                                    <div
                                        className={`flex items-center gap-1 truncate relative min-w-[98px] ${Object.keys(errors).length ? 'min-h-[52px] !items-start' : ''
                                            }`}
                                    >
                                        <InputField
                                            placeholder={''}
                                            name="remove_learner_mins"
                                            type="number"
                                            classNameInput="!w-14 max-h-8"
                                            className="truncate"
                                            classError="min-h-[16px]"
                                            disabled={absence_request_type === 'not_auto_remove_learner'}
                                            errors={errors}
                                            register={register('remove_learner_mins', {
                                                pattern: {
                                                    value: VALIDATE_FIELD.NUMBER_0_9,
                                                    message: 'Please enter integer number',
                                                },
                                                max: {
                                                    value: 59,
                                                    message: 'Enter only numbers less than 60',
                                                },
                                                validate: {
                                                    timeRequestValid: (value) => {
                                                        const hours = watch('remove_learner_hours') || 0;
                                                        const mins = value || 0;

                                                        const timeRequest = hours * 60 + Number(mins);

                                                        if (timeRequest > 0 || absence_request_type === 'not_auto_remove_learner') return true;
                                                        return errors?.remove_learner_hours?.type === 'timeRequestValid' ? '' : 'Time must be at least 1 minute';
                                                    }
                                                }
                                            })}
                                        />
                                        <span className="absolute top-[8px] left-[62px] text-xs text-gray-500">{t('MIN_S')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex my-2 items-center text-sm text-primary-500 font-semibold'>
                        {t('COURSES.ABSENCE_REQUEST_NOTI')}
                        <button type='button' className='ml-1' onClick={() => window.open(`/workspace/${params.id}/management/recipient-absence-request`, '_blank')}>
                            <ExternalLinkIcon className='w-5 h-5' />
                        </button>
                    </div>
                </div>

                <div className="courseSettings-item">
                    <div className="w-full">
                        <div className="courseSettings-label">{t('COURSES.VC_LINK')}</div>
                        <div className="w-full">
                            <InputField
                                name="vc_link"
                                maxLength={255}
                                classNameInput="h-[32px] w-full mt-2"
                                placeholder={t('COURSES.ENTER_VC_LINK')}
                                register={register('vc_link', {
                                    pattern: {
                                        value: FORM_CONST.URL_VALIDATE,
                                        message: t('COURSES.VALIDATE_URL'),
                                    },
                                })}
                                errors={errors}
                                disabled={notAllowEdit}
                            />
                            <p className="mt-2">{t('COURSES.VC_LINK_DES')}</p>
                        </div>
                    </div>
                </div>

                <div className="">
                    <div className="courseSettings-label">{t('COURSES.LOCATION')}</div>
                    <div className="w-full">
                        <InputField
                            name="location"
                            maxLength={255}
                            classNameInput="h-[32px] w-full mt-2"
                            placeholder={t('COURSES.ENTER_LOCATION')}
                            register={register('location')}
                            errors={errors}
                            disabled={notAllowEdit}
                        />
                        <p className="mt-2">{t('COURSES.LOCATION_DES')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
