export const LayoutIcon = ({
    fill = '#111827',
    width = '20',
    height = '20',
}: {
    fill?: string;
    width?: string;
    height?: string;
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66699 2.49996C1.66699 2.03972 2.04009 1.66663 2.50033 1.66663H17.5003C17.9606 1.66663 18.3337 2.03972 18.3337 2.49996V8.33329C18.3337 8.79353 17.9606 9.16663 17.5003 9.16663H2.50033C2.04009 9.16663 1.66699 8.79353 1.66699 8.33329V2.49996ZM3.33366 3.33329V7.49996H16.667V3.33329H3.33366Z" fill="#111827" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 11.6667C12.5 11.2065 12.8731 10.8334 13.3333 10.8334H17.5C17.9602 10.8334 18.3333 11.2065 18.3333 11.6667V17.5C18.3333 17.9603 17.9602 18.3334 17.5 18.3334H13.3333C12.8731 18.3334 12.5 17.9603 12.5 17.5V11.6667ZM14.1667 12.5V16.6667H16.6667V12.5H14.1667Z" fill="#111827" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66699 11.6667C1.66699 11.2065 2.04009 10.8334 2.50033 10.8334H10.0003C10.4606 10.8334 10.8337 11.2065 10.8337 11.6667V17.5C10.8337 17.9603 10.4606 18.3334 10.0003 18.3334H2.50033C2.04009 18.3334 1.66699 17.9603 1.66699 17.5V11.6667ZM3.33366 12.5V16.6667H9.16699V12.5H3.33366Z" fill="#111827" />
        </svg>
    );
};
