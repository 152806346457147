import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LessonSection } from 'types/GetListOfWorkspace.type';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { CommentIcon, NextIcon, PreviousIcon } from 'assets/icon';
import { Popover } from '@headlessui/react';
import { ListIcon, ZoomOutIcon, ZoomInIcon } from 'assets/icon';
import Spinner from 'components/Spinner';
import './LessonPublicPreview.scss';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import PreviewContentCommon from '_shared/PreviewContent/PreviewContentCommon';
import { VIEW_TYPE } from 'components/Library/LessonBuilder/constants';
import NotePopup from 'components/H5PPreview/NotePopup';
import { exitFullScreen } from 'utils/commonFun';

import { useTranslation } from 'react-i18next';
import { Book3FillIcon } from 'assets/icon';
import lessonIcon from 'assets/img/lessonIcon.png';
import assignmentIcon from 'assets/img/assignmentIcon.png';
import lessonService from 'services/lesson.service';
import ReactTooltip from "react-tooltip";

const LessonPublicPreview = ({ dataLesson }) => {
    const params: { id: string; lessonId: string } = useParams();
    const [showLoading, setShowLoading] = useState(false);
    const [isZoom, setZoom] = useState(false);
    const [isOpenNote, setIsOpenNote] = useState<boolean>(false);
    const [currentFile, setCurrentFile] = useState<any>()
    const [data, setData] = useState<any>({});
    const [normalizeData, setNormalizeData] = useState<any[]>([]);

    const getIndexFile = (id) => normalizeData?.findIndex((item) => item?.id === id);

    const getMaterial = (key) => {
        const data = dataLesson?.sections?.[key]?.map((section: LessonSection) => {
            return {
                sectionName: section?.title,
                sectionContents: section?.section_contents,
            };
        });
        return data;
    };

    const getData = async () => {
        const learningMaterial = getMaterial('learning_material');
        const supplementaryMaterial = getMaterial('supplementary_material');
        let assignment = [];
        if (dataLesson?.sections?.assignment?.[0]?.section_contents?.length) {
            await Promise.all(
                dataLesson?.sections?.assignment?.[0]?.section_contents?.map(async (section) => {
                    const sectionContent = await lessonService.playAssignment(section?.library_content?.uid)
                    return {
                        sectionName: section?.library_content?.name,
                        sectionContents: sectionContent?.data?.sections,
                    };
                })
            ).then((res) => {
                assignment = res;
            });
        }
        
        setData({ learningMaterial, assignment, supplementaryMaterial});
        const listContent = []
        learningMaterial?.forEach((section) => section?.sectionContents?.forEach((file) => listContent.push(file)));
        assignment?.map(section => section?.sectionContents?.forEach(file => listContent.push(file)));
        supplementaryMaterial?.[0]?.sectionContents?.forEach((file) => listContent.push(file));
        setNormalizeData(listContent);
        setCurrentFile(listContent[0])
    };

    useEffect(() => {
        if (dataLesson?.sections) getData();
    }, [dataLesson?.sections, params?.lessonId]);


    const beforeChange = (next: number) => {
        setIsOpenNote(false);
        if (currentFile?.id !== normalizeData[next]?.id) {
            setCurrentFile(normalizeData[next]);
        }
    };

    const nextSlide = () => {
        const index = getIndexFile(currentFile?.id)
        beforeChange(index + 1);
        setCurrentFile(null);
        setTimeout(() => {
            setCurrentFile(normalizeData[index + 1]);
        });
    };

    const preSlide = () => {
        const index = getIndexFile(currentFile?.id)
        getIndexFile(currentFile?.id)
        beforeChange(index - 1);
        setCurrentFile(null);
        setTimeout(() => {
            setCurrentFile(normalizeData[index - 1]);
        });
    };

    useEffect(() => {
        if (currentFile?.library_content?.object_type != 'h5p') return;
        setShowLoading(true);
        clearInterval(intervalRemoveIconZoom);
        var intervalRemoveIconZoom = setInterval(() => {
            const iframe = document?.getElementsByClassName('h5p-iframe')[0];
            if (iframe) {
                //@ts-ignore
                const zoom = iframe?.contentWindow?.document?.getElementsByClassName('h5p-footer-toggle-full-screen')?.[0];
                if (zoom) {
                    zoom.style.visibility = 'hidden';
                }
                setShowLoading(false);
                clearInterval(intervalRemoveIconZoom);
            }
            if (!normalizeData?.length) setShowLoading(false);
        }, 1000);
    }, [isZoom, currentFile]);

    useEffect(() => {
        const keydown = (event) => {
            if (event.code === 'ArrowLeft' && currentFile?.id !== normalizeData?.[0]?.id) preSlide();
            if (event.code === 'ArrowRight' && currentFile?.id !== normalizeData?.slice(-1)?.[0]?.id) nextSlide();
        };
        window.addEventListener('keydown', keydown);
        exitFullScreen(() => setZoom(false));
        
        return () => {
            window?.removeEventListener('keydown', keydown);
        };
    }, [currentFile]);

    const openFullScreen = () => {
        const elem = document.getElementById('presentation-mode-lesson');
        if (elem) {
            elem.requestFullscreen();
            //@ts-ignore
        } else if (elem.webkitRequestFullscreen) {
            /* Safari */
            //@ts-ignore
            elem.webkitRequestFullscreen();
            //@ts-ignore
        } else if (elem.msRequestFullscreen) {
            /* IE11 */
            //@ts-ignore
            elem.msRequestFullscreen();
        }
    };

    /* Close fullscreen */
    const closeFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
            //@ts-ignore
        } else if (document.webkitExitFullscreen) {
            /* Safari */
            //@ts-ignore
            document.webkitExitFullscreen();
            //@ts-ignore
        } else if (document.msExitFullscreen) {
            /* IE11 */
            //@ts-ignore
            document.msExitFullscreen();
        }
    };

    const { t } = useTranslation();

    const renderSection = (key, icon) => {
        const contents = data?.[key]?.filter(section => section?.sectionContents?.length > 0)
        return contents?.map((section, index) => {
            return(
                <div key={index}>
                    <div className="lesson">
                        <div className='flex items-center'>
                            {icon} 
                            {key === 'supplementaryMaterial' ? 'Supplementary Material' : section?.sectionName}
                        </div>
                    </div>
                    {section?.sectionContents?.map((file: any) => {
                        return (
                            <div
                                key={`${file?.uid}`}
                                onClick={() => setCurrentFile(file)}
                                className={`lesson-item ${getIndexFile(file?.id) !== 0 ? 'm-0' : ''} ${
                                    currentFile?.id === file?.id ? 'active' : ''
                                } `}
                            >
                                <span 
                                    className='name'
                                    data-for="file-name"
                                    data-tip={file?.library_content?.name}
                                >
                                    {file?.library_content?.name}
                                </span>
                                {/*@ts-ignore*/
                                <ReactTooltip
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    id="file-name"
                                    getContent={(value) => <div>{value}</div>}
                                />}
                                {file?.note && <span className='text-gray-500'><CommentIcon width={20} height={20} /></span>}
                            </div>
                        );
                        })}
            </div>
            )
        })
    }

    return (
        <div
            id="presentation-mode-lesson"
            className="LessonPublicPreview"
        >
            <div className={`header ${isZoom ? 'group' : ''}`}>
                <div className={`${isZoom ? 'hidden' : 'flex'} header-container group-hover:flex items-center w-full`}>
                    <div className="header-title">{dataLesson?.title}</div>
                </div>
            </div>
            <div className={`main-content ${isZoom ? 'zoom' : ''}`}>
                <div key={`iframe-${isZoom}`} className={`file-container ${isZoom ? 'zoom' : ''} `}>
                    {showLoading && (
                        <div className="file-container-loading">
                            <Spinner />
                        </div>
                    )}
                    {!showLoading && !normalizeData?.length && (
                        <div className="flex items-center">
                            <NoContentWithImage />
                        </div>
                    )}
                    {currentFile?.id ? <PreviewContentCommon isNewPage={true} key={`previewContent-${params?.lessonId}`} contentSelected={currentFile} viewType={VIEW_TYPE.WEB} isPublic={true} /> : null}
                </div>
            </div>

            <div className={`navigation ${isZoom ? 'group z-100' : ''}`}>
                <div className={`${isZoom ? 'hidden' : 'flex'} navigation-container group-hover:flex`}>
                    <Popover className="relative">
                        <Popover.Button className="navigation-dropdown">
                            <ListIcon />
                            <div className="navigation-dropdown-button">
                                <span 
                                    className="file-name"
                                    data-for="file-selected-name"
                                    data-tip={currentFile?.library_content?.name}
                                >
                                    {currentFile?.library_content?.name}
                                </span>{' '}
                                {/*@ts-ignore*/
                                <ReactTooltip
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    id="file-selected-name"
                                    getContent={(value) => <div>{value}</div>}
                                />}
                                <ChevronDownIcon className="icon" />
                            </div>
                        </Popover.Button>
                        <Popover.Panel className="navigation-panel">
                            {
                                renderSection('learningMaterial', <img className='w-5 h-5 mr-1' src={lessonIcon} />)
                            }
                            {
                                renderSection('assignment', <img className='w-5 h-5 mr-1' src={assignmentIcon} />)
                            }
                            {
                                renderSection('supplementaryMaterial', <span className='text-cyan-500 mr-1'><Book3FillIcon width={20} height={20} /></span>)
                            }
                        </Popover.Panel>
                    </Popover>
                    <div className="navigation-button">
                        <div className={`${getIndexFile(currentFile?.id) > 0 ? 'active' : ''}`} onClick={preSlide}>
                            <PreviousIcon />
                        </div>
                        <div className="line"></div>
                        <div id="counter-slide" className="index-page">
                            {normalizeData?.length > 0 ? getIndexFile(currentFile?.id) + 1 : 0}
                        </div>
                        /
                        <div id="total-slide" className="total-slide">
                            {normalizeData?.length}
                        </div>
                        <div className="line"></div>
                        <div className={` ${getIndexFile(currentFile?.id) < normalizeData?.length - 1 ? 'active' : ''}`} onClick={nextSlide}>
                            <NextIcon />
                        </div>
                    </div>
                    <div
                        id="zoom-presentation"
                        onClick={() => {
                            isZoom ? closeFullscreen() : openFullScreen();
                            setZoom(!isZoom);
                        }}
                        className="button-zoom"
                    >
                        {isZoom ? <ZoomOutIcon /> : <ZoomInIcon />}
                    </div>
                    <div
                        id="note-presentation"
                        onClick={() => {
                            setIsOpenNote(true);
                        }}
                        className="button-zoom"
                    >
                        <CommentIcon width={20} height={20} />
                    </div>
                </div>
            </div>
            {isOpenNote ? (
                <NotePopup
                    isOpen={isOpenNote}
                    onCloseModal={() => {
                        setIsOpenNote(false);
                    }}
                    noteData={currentFile?.note || ''}
                    isZoom={isZoom}
                />
            ) : (
                ''
            )}
        </div>
    );
};

export default LessonPublicPreview;
