import { useState, useEffect, useCallback } from 'react';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import { debounce } from 'lodash';
import './Filter.scss';
import { ENROLLMENT_TYPE_OPTION, STATUS_LEARNER_ENROLL_OPTION } from '../../constants';
import { useLocation } from 'react-use';
import { useTranslation } from 'react-i18next';

interface IFilter {
    onChangeFilter: (value: any) => void;
    isFirstLoad: boolean;
}

const Filter = ({
    onChangeFilter,
    isFirstLoad,
}: IFilter) => {
    const search = useLocation()?.search;
    const status = new URLSearchParams(search).get('status');
    const [keyword, setKeyword] = useDebouncedState('', 0);
    const [statusSelected, setStatusSelected] = useState([]);
    const [enrollmentAs, setEnrollmentAs] = useState([])
    const {t} = useTranslation();
    const updateFilter = useCallback(
        debounce((listFilter) => onChangeFilter(listFilter), 1500),
        []
    );

    useEffect(() => {
        if (!isFirstLoad) {
            let listFilter: any = {
                q: keyword,
                statuses: statusSelected?.map(status => status?.value)?.join(','),
                enrollment_as: enrollmentAs?.[0]?.value
            };
            if (!statusSelected?.length || statusSelected.length === 4) delete listFilter.status;
            if (enrollmentAs.length === 2) delete listFilter.enrollment_as;
            updateFilter(listFilter);
        }
    }, [ keyword, statusSelected, enrollmentAs]);

    useEffect(() => {
        if (status) {
            setStatusSelected([STATUS_LEARNER_ENROLL_OPTION(t)[0], STATUS_LEARNER_ENROLL_OPTION(t)[1]]);
        }
    }, [status]);

    return (
        <div className="FilterEnrollment">
            <SearchBox placeholder={t("COURSES.SEARCH_ENROLLMENT")} className="FilterEnrollment-searchInput" onSubmit={(value) => setKeyword(value)} />
            <SelectBox
                label={t('COURSES.ENROLLMENT_AS')}
                onSelect={setEnrollmentAs}
                selectedItems={enrollmentAs}
                data={ENROLLMENT_TYPE_OPTION(t)}
                total={2}
            />
            <SelectBox
                label={t("STATUS")}
                onSelect={setStatusSelected}
                selectedItems={statusSelected}
                data={STATUS_LEARNER_ENROLL_OPTION(t)}
                total={4}
                isMulti={true}
            />
        </div>
    );
};

export default Filter;
