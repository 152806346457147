import { useContext, useEffect } from 'react';
import { AuthContext } from 'contexts/Auth/AuthContext';

export const useDocumentTitle = (title) => {
    const authCtx = useContext(AuthContext);

    useEffect(() => {
        const whitelabel: any = authCtx?.config?.find((item: { page_name: string }) => item?.page_name == 'login');
        let titlePage = whitelabel?.data?.web?.brand_title ?? 'LearningOS by OOOLAB';
        document.title = `${!!title ? title + ' - ' : ''}${titlePage}`;
    }, [authCtx, title]);

    return null;
};
