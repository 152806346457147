import { ButtonOutline, ButtonPrimary, Toastify } from '_shared'
import DialogComponent from 'components/Dialog/DialogComponent'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';

export default function ModalDeleteMember({isOpen, onClose, data, refreshData}) {
  const {t: translator} = useTranslation();
  const params: {id} = useParams();
  const workspaceId = params?.id;

  const onDeleteMember = () => {
    workspaceService
        .deleteMember(workspaceId, data?.id)
        .then(() => {
            Toastify.success();
            refreshData();
        })
        .catch(() => Toastify.error())
        .finally(() => onClose())
  };
  return (
    <DialogComponent
        title={translator('DELETE')}
        isOpen={isOpen}
        onCloseModal={onClose}
        child={
            <div className="flex flex-col">
                <div className="mt-2 text-sm text-gray-800">{translator("CONFIRM_DELETE_USER")}</div>
                <div className="flex justify-center gap-4 mt-6 w-full">
                    <ButtonOutline type="button" onClick={onClose}>
                        {translator('CANCEL')}
                    </ButtonOutline>
                    <ButtonPrimary type="button" className="buttonPrimary-danger" onClick={onDeleteMember}>
                        {translator('DELETE')}
                    </ButtonPrimary>
                </div>
            </div>
        }
    />
  )
}
