const AvatarCard = ({ name, image }) => {
    return (
        <div className="flex items-center space-x-2 mt-2">
            <span
                className="w-[20px] h-[20px] bg-[#8083A3] flex-none  rounded-full bg-cover"
                style={{ backgroundImage: `url(" ${image}")` }}
            ></span>
            <span className="text-ooolab_8083A3 text-xs">{name}</span>
        </div>
    );
};

export default AvatarCard;
