import { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircleInformationIcon } from 'assets/icon/CircleInfomationIcon';
import { EditIcon } from 'assets/icon/EditIcon';
import { TextIcon } from 'assets/icon/TextIcon';
import { CopyFileIcon } from 'assets/icon/CopyFileIcon';
import ActionsButton from 'components/ActionsButton';
import { TrashIcon } from '@heroicons/react/outline';
import RevertIcon from 'assets/icon/RevertIcon';
import { LEARNING_PATH_STATUS } from './TableLearningPath';
import { checkPermission } from 'utils/commonFun';

interface ActionMenuProp {
    onDuplicate: Function;
    onRename: Function;
    onDelete: Function;
    onViewDetail?: Function;
    onRevertToDraft?: any;
    data: any;
    position?: string;
}

const ActionMenu: FC<ActionMenuProp> = ({
    onDuplicate,
    onRename,
    onDelete,
    onViewDetail,
    onRevertToDraft = false,
    data,
    position
}) => {
    const params: any = useParams();
    const { t: translator } = useTranslation();
    const history = useHistory();

    return (
        <>
            <ActionsButton
                styleOptions={`${position ? position: 'top-0'} right-[35px]`}
                menus={[
                    {
                        label: translator("LEARNING_PATH.EDIT"),
                        icon: <EditIcon />,
                        isDisabled: (!onViewDetail || !checkPermission('learningPath', 'U')),
                        hide: !onViewDetail,
                        action: async () => history.push(`/workspace/${params?.id}/learning-path/${data?.id}`),
                    },
                    {
                        label: translator("LEARNING_PATH.DETAILS"),
                        icon: <CircleInformationIcon width="18" height="16" fill="#111827" />,
                        action: onViewDetail,
                        class: 'border-b border-gray-300',
                        isDisabled: (!onViewDetail || !checkPermission('learningPath', 'R')),
                        hide: !onViewDetail
                    },
                    {
                        label: translator("LEARNING_PATH.REVERT_TO_DRAFT"),
                        icon: <RevertIcon />,
                        isDisabled: data?.status === LEARNING_PATH_STATUS.draft || !checkPermission('learningPath', 'RD'),
                        action: onRevertToDraft,
                    },
                    {
                        label: translator("LEARNING_PATH.RENAME"),
                        icon: <TextIcon />,
                        isDisabled: !checkPermission('learningPath', 'U'),
                        action: onRename,
                    },
                    {
                        label: translator("LEARNING_PATH.DUPLICATE"),
                        icon: <CopyFileIcon />,
                        action: onDuplicate,
                        isDisabled: !checkPermission('learningPath', 'C'),
                        class: 'border-b border-gray-300',

                    },
                    {
                        label: translator("LEARNING_PATH.DELETE"),
                        icon: <TrashIcon width="18" height="16" className="text-gray-900" />,
                        isDisabled: !checkPermission('learningPath', 'D'),
                        action: onDelete,
                    },
                ]}
            />
        </>
    );
};

export default ActionMenu;
