import httpClient from './restful.service';

const createTemplateCertificateService = (workspaceId: string, bodyParams: any) => {
  const url = `/workspaces/${workspaceId}/certificates`;
  return httpClient.post(url, bodyParams);
};

const getCertificatesService = (workspaceId: string, params?: any) => {
  const url = `/workspaces/${workspaceId}/certificates`;
  return httpClient.get(url, { params });
};

const getCertificateByIdService = (workspaceId: string, certificateId: string, params?: any) => {
  const url = `/workspaces/${workspaceId}/certificates/${certificateId}`;
  return httpClient.get(url, { params });
};

const updateCertificateByIdService = (workspaceId: string, certificateId: string, params?: any) => {
  const url = `/workspaces/${workspaceId}/certificates/${certificateId}`;
  return httpClient.put(url, params);
};

const deleteCertificateByIdService = (workspaceId: string, certificateId: string) => {
  const url = `/workspaces/${workspaceId}/certificates/${certificateId}`;
  return httpClient.delete(url);
};

export default {
  createTemplateCertificateService,
  getCertificatesService,
  getCertificateByIdService,
  updateCertificateByIdService,
  deleteCertificateByIdService,
}