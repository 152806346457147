import { Fragment, FC, useRef, useState, useCallback, useEffect } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { XIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import workspaceService from 'services/workspace.service';
import { debounce } from 'lodash';
import { genClassNames } from 'utils/handleString';
import InfiniteScroll from 'react-infinite-scroll-component';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (courseId: number) => void;
}

const ModalImportCourse: FC<ModalProps> = ({ isOpen, onClose, onConfirm }) => {
    const { t: translator } = useTranslation();

    const cancelButtonRef = useRef(null);

    const params: { id: string; classId: string } = useParams();

    const [course, setCourses] = useState(undefined);
    const [isEnd, setEnd] = useState(false);
    const [selected, setSelected] = useState(-1);
    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState('');
    const update = useCallback((inputValue: string) => setKeyword(inputValue), []);
    const loadCourses = useCallback(
        async (reset: boolean) => {
            const newPage = reset ? 1 : page + 1;
            const data = await workspaceService.getCoursesList(
                params.id,
                keyword ? { title: keyword, page: newPage, per_page: 20 } : { page: newPage, per_page: 20 }
            );
            setPage(newPage);
            setSelected(-1);
            setEnd((data?.items || []).length < 20);
            setCourses(reset ? data?.items || [] : [...course, ...(data?.items || [])]);
        },
        [keyword, page, course]
    );
    useEffect(() => {
        loadCourses(true);
    }, [keyword]);
    const debounceInput = useCallback(
        debounce((nextValue: string, asyncFunction: (inputValue: string) => void) => {
            asyncFunction(nextValue);
        }, 1000),
        []
    );
    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                unmount
                className="fixed z-70 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={isOpen}
                onClose={onClose}
            >
                <div className="relative flex justify-center h-screen text-center items-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-700"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 dialog-background  bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0"
                        enterTo="transform opacity-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100"
                        leaveTo="transform opacity-0"
                    >
                        <div className="bg-white text-left overflow-hidden shadow-xl transform transition-all sm:align-middle max-w-ooolab_w_100 w-ooolab_w_100 relative pt-ooolab_p_4 pb-ooolab_p_2">
                            <div className="px-ooolab_p_5">
                                <div className="flex items-center justify-between mb-ooolab_m_2">
                                    <p className="text-ooolab_xs text-ooolab_dark_1 font-semibold">Import Course</p>
                                    <XIcon
                                        onClick={onClose}
                                        className="h-ooolab_h_5 w-ooolab_w_5 text-ooolab_dark_2 cursor-pointer"
                                    />
                                </div>
                                <input
                                    onChange={(e: any) => {
                                        debounceInput(e.target.value, update);
                                    }}
                                    autoComplete="off"
                                    placeholder="Search for a course"
                                    className="w-full text-ooolab_xs mt-3"
                                />
                                <div className="mt-2 bg-ooolab_gray_6 h-ooolab_h_1px" />
                            </div>
                            <div className="text-ooolab_text_bar_inactive text-ooolab_xs mt-4 px-ooolab_p_5 mb-1">
                                SEARCH RESULTS
                            </div>
                            {course !== undefined ? (
                                <>
                                    <div className="h-ooolab_h_70 overflow-auto">
                                        <InfiniteScroll
                                            dataLength={course.length}
                                            next={() => loadCourses(false)}
                                            hasMore={!isEnd}
                                            loader={<h4>{translator('LOADING')}</h4>}
                                            height="calc(280*(100vw/1440))"
                                            scrollableTarget="scrollableDiv"
                                            className="custom-style-scrollbar scrollData"
                                        >
                                            {course.map((c: any, index: number) => (
                                                <div
                                                    onClick={() => setSelected(course[index].id)}
                                                    className={genClassNames({
                                                        'cursor-pointer text-ooolab_text_username text-ooolab_sm px-ooolab_p_5 py-2': true,
                                                        'bg-ooolab_bg_bar': selected === course[index].id,
                                                    })}
                                                >
                                                    {c.title}
                                                </div>
                                            ))}
                                        </InfiniteScroll>
                                    </div>
                                </>
                            ) : (
                                <div className="h-ooolab_h_70" />
                            )}
                            <div className="bg-ooolab_gray_6 h-ooolab_h_1px" />
                            <div className="flex justify-between pt-2 px-2">
                                <div className="flex items-center space-x-1 text-ooolab_xs text-red-500">
                                    <ExclamationCircleIcon className="w-4 h-4 " />
                                    <span>Changes will re-import lessons and assignments in all upcoming class sessions.</span>
                                </div>
                                <button
                                    onClick={() => {
                                        onConfirm(selected);
                                        onClose();
                                    }}
                                    disabled={selected === -1}
                                    type="submit"
                                    className={genClassNames({
                                        'h-ooolab_h_7 w-ooolab_w_11 rounded-ooolab_radius_4px flex justify-center items-center shadow-ooolab_sched_button text-ooolab_xs font-semibold': true,
                                        'bg-ooolab_dark_300 text-white': selected !== -1,
                                        'bg-gray-300 text-black': selected === -1,
                                    })}
                                >
                                    Import
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default ModalImportCourse;
