import { FC, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { EditIcon } from 'assets/icon/EditIcon';
import { TextIcon } from 'assets/icon/TextIcon';
import ActionsButton from 'components/ActionsButton';
import { TrashIcon } from '@heroicons/react/outline';
import DialogComponent from 'components/Dialog/DialogComponent';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import courseService from 'services/course.service';
import { COURSE_STATUS } from '../../constants';
import { StatusIcon } from 'assets/icon';
import { WarningIcon } from 'assets/icon/WarningIcon';
import { checkPermission } from 'utils/commonFun';

interface ActionMenuProp {
    data: any;
    refreshData: Function;
    position?: string
}

const ActionMenu: FC<ActionMenuProp> = ({ data, refreshData, position }) => {
    const params: { id: string; courseId: string } = useParams();
    const workspaceId = params?.id;
    const { t: translator } = useTranslation();
    const history = useHistory();

    const [isOpenModalRename, setOpenModalRename] = useState(false);
    const [isOpenModalDelete, setOpenModalDelete] = useState(false);
    const [isOpenModalInactive, setOpenModalInactive] = useState(false);
    const [isOpenModalActive, setOpenModalActive] = useState(false);
    const [isOpenActiveLearner, setOpenActiveLearner] = useState(false);
    const [hasActiveLearner, setHasActiveLearner] = useState(false)
    const [courseName, setCourseName] = useState('');

    const onUpdateCourse = (value) => {
        courseService
            .updateCourse({ workspaceId, id: data?.id, ...value })
            .then((res) => {
                refreshData();
                setOpenModalRename(false);
                Toastify.success();
            })
            .catch(() => Toastify.error());
    };

    const checkHasLearner = () => {
        courseService
            .checkHasActiveLearner({ workspaceId, id: data?.id })
            .then((res) => {
                if (res?.data?.has_active_learner) {
                    return setOpenActiveLearner(true);
                } else {
                    setOpenModalInactive(true);
                }
            })
            .catch((error) => console.error(error));
    }

    const onDeleteCourse = () => {
        courseService
            .deleteCourse({ workspaceId, id: data?.id })
            .then(() => {
                Toastify.success();
                setOpenModalDelete(false);
                refreshData();
            })
            .catch(() => Toastify.error());
    };

    return (
        <>
            <ActionsButton
                styleOptions={`${position ? position : 'top-0'} right-[35px]`}
                menus={[
                    {
                        label: translator('EDIT'),
                        icon: <EditIcon />,
                        action: async () => history.push(`/workspace/${params?.id}/management/courses/${data?.id}`),
                        class: 'border-b border-gray-300',
                        hide: !checkPermission('course', 'R')
                    },
                    {
                        label: data?.status === COURSE_STATUS.ACTIVE ? translator('DEACTIVATE') : translator('ACTIVATE'),
                        icon: <StatusIcon />,
                        isDisabled: !checkPermission('course', 'DA'),
                        action: () => {
                            if (data?.status === COURSE_STATUS.INACTIVE) {
                                return setOpenModalActive(true);
                            };
                            checkHasLearner();
                        },
                        hide: !checkPermission('course', 'DA')
                    },
                    {
                        label: translator('RENAME'),
                        icon: <TextIcon />,
                        isDisabled: !checkPermission('course', 'U'),
                        action: () => setOpenModalRename(true),
                        class: 'border-b border-gray-300',
                        hide: !checkPermission('course', 'U')
                    },
                    {
                        label: translator('DELETE'),
                        icon: <TrashIcon width="18" height="16" className="text-gray-900" />,
                        action: () => {
                            setOpenModalDelete(true);
                            checkHasLearner();
                        },
                        isDisabled: !checkPermission('course', 'D'),
                        hide: !checkPermission('course', 'D') || true,
                    },
                ]}
            />
            {isOpenModalRename && (
                <DialogComponent
                    title={translator('RENAME')}
                    isOpen={isOpenModalRename}
                    onCloseModal={() => setOpenModalRename(false)}
                    child={
                        <div className="flex flex-col">
                            <label className="text-xs">
                                Name <span className="text-red-500">*</span>
                            </label>
                            <input
                                onChange={(e) => setCourseName(e.target.value)}
                                className="px-3 py-1 rounded border text-sm border-gray-300 mt-2"
                                placeholder="Course name"
                            />
                            {courseName?.length > 255 && (
                                <p className="text-error-500 mt-1 text-xs">{translator('FORM_CONST.VALIDATE_255_CHARACTERS')}</p>
                            )}
                            <div className="flex justify-center gap-4 mt-6 w-full">
                                <ButtonOutline type="button" onClick={() => setOpenModalRename(false)}>
                                    {translator('CANCEL')}
                                </ButtonOutline>
                                <ButtonPrimary
                                    type="button"
                                    disabled={!courseName}
                                    onClick={() => onUpdateCourse({ title: courseName })}
                                >
                                    {translator('SAVE')}
                                </ButtonPrimary>
                            </div>
                        </div>
                    }
                />
            )}
            {isOpenModalDelete && (
                <DialogComponent
                    title={translator('DELETE')}
                    isOpen={isOpenModalDelete}
                    onCloseModal={() => setOpenModalDelete(false)}
                    child={
                        <div className="flex flex-col">
                            {hasActiveLearner && <div className="text-sm text-gray-800">{translator('COURSES.DELETE_COURSE_WARNING')}</div>}
                            <div className="mt-2 text-sm text-gray-800">{translator('COURSES.DELETE_COURSE')}</div>
                            <div className="flex justify-center gap-4 mt-6 w-full">
                                <ButtonOutline type="button" onClick={() => setOpenModalDelete(false)}>
                                    {translator('CANCEL')}
                                </ButtonOutline>
                                <ButtonPrimary type="button" className="buttonPrimary-danger" onClick={onDeleteCourse}>
                                    {translator('DELETE')}
                                </ButtonPrimary>
                            </div>
                        </div>
                    }
                />
            )}

            {isOpenModalInactive && (
                <DialogComponent
                    title={translator('DEACTIVATE')}
                    isOpen={isOpenModalInactive}
                    onCloseModal={() => setOpenModalInactive(false)}
                    child={
                        <div className="flex flex-col">
                            <div className="mt-2 text-sm text-gray-800">{translator('COURSES.INACTIVE_COURSE')}</div>
                            <div className="flex justify-center gap-4 mt-6 w-full">
                                <ButtonOutline type="button" onClick={() => setOpenModalInactive(false)}>
                                    {translator('CANCEL')}
                                </ButtonOutline>
                                <ButtonPrimary
                                    type="button"
                                    className="buttonPrimary-danger"
                                    onClick={() => onUpdateCourse({ status: COURSE_STATUS.INACTIVE })}
                                >
                                    {translator('DEACTIVATE')}
                                </ButtonPrimary>
                            </div>
                        </div>
                    }
                />
            )}

            {isOpenActiveLearner &&
                <DialogComponent
                    title={translator('DEACTIVATE')}
                    isOpen={isOpenActiveLearner}
                    onCloseModal={() => setOpenActiveLearner(false)}
                    child={<>
                        <div className='text-sm'>
                            <p>{translator('COURSES.WARNING_DEACTIVE')}</p>
                            <p className='mt-2'>Click <a href={`/workspace/${params?.id}/management/courses/${data?.id}?tab=people&subtab=enrollment&status=active,enrolled`} target="_blank" className='text-primary-600 hover:underline'>here</a> to view the list of active enrollments.</p>
                        </div>
                    </>}
                />
            }

            {isOpenModalActive && (
                <DialogComponent
                    title={
                        <div className="flex gap-1 items-center text-orange-500">
                            <WarningIcon width="24" height="24" />{' '}
                            <div className="text-base text-gray-800">{translator("COURSES.CONFIRM_ACTIVATE")}</div>
                        </div>
                    }
                    isOpen={isOpenModalActive}
                    onCloseModal={() => setOpenModalActive(false)}
                    child={
                        <div className="flex flex-col">
                            <div className="mt-2 text-sm text-gray-800">
                                {/* @ts-ignore */}
                                <Trans
                                    i18nKey={
                                        'COURSES.ACTIVATE_COURSE'
                                    }
                                    components={{
                                        1: (
                                            <strong className="text-gray-800 font-semibold" />
                                        ),
                                    }}
                                />
                            </div>
                            <div className="flex justify-center gap-4 mt-6 w-full">
                                <ButtonOutline type="button" onClick={() => setOpenModalActive(false)}>
                                    {translator('CANCEL')}
                                </ButtonOutline>
                                <ButtonPrimary type="button" onClick={() => onUpdateCourse({ status: COURSE_STATUS.ACTIVE })}>
                                    {translator('CONFIRM')}
                                </ButtonPrimary>
                            </div>
                        </div>
                    }
                />
            )}
        </>
    );
};

export default ActionMenu;
