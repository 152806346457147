import dayjs from "dayjs";
import { colorByType, colorExpiredSession } from "../constants";
import { useContext, useEffect, useState } from "react";
import LockIcon from "assets/icon/LockIcon";
import './AloneSession.scss'
import { UserContext } from "contexts/User/UserContext";

interface Props {
  startTime: string;
  endTime: string;
  id: string;
  tz: string;
  title: string
}

export default function AloneSession({startTime, endTime, id, title}: Props) {
  const {
    userState: { result },
} = useContext(UserContext);

const [isExpriredSession, setExpriredSession] = useState(false);

useEffect(() => {
  setExpriredSession(dayjs().tz(result?.time_zone).isAfter(dayjs(endTime).tz(result?.time_zone)))
},[endTime])

  return (
      <div className={`AloneSession ${ isExpriredSession ?  colorExpiredSession : colorByType.scheduled }`}>
          <div className="title">{title || ''}</div>
          <div className="date-time">{dayjs.utc(startTime).tz(result.time_zone)?.format('HH:mm')} - {dayjs.utc(endTime).tz(result.time_zone)?.format('HH:mm')}</div>
          {isExpriredSession && (
              <div className="icon">
                <LockIcon />
              </div>
            )
          }
      </div>
  );
};