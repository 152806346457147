export const PaymentGatewaysType = {
    paypal: 'paypal',
    stripe: 'stripe'
}

export const PaymentGatewaysState = {
    connected: 'connected',
    disconnected: 'disconnected',
    pending: 'pending',
    failed: 'failed'
}