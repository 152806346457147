import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import TableRewardDashBoard from './RewardComponents/TableRewardDashBoard';
import { ResponseData } from 'types/ApiData.type';
import rewardService from 'services/reward.service';
import TablePagination from 'components/Pagination';

const RewardDashBoard: React.FC = ({ children }) => {
    const params: { id: string } = useParams()
    const [dataList, setDataList] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [rewardResponse, setRewardResponse] = useState<ResponseData>();

    useEffect(() => {
        getList({ page: 1, per_page: 20 });
    }, []);

    useEffect(() => {
        if (rewardResponse?.items?.length > 0) {
            setDataList([...dataList, ...rewardResponse?.items]);
        }
    }, [rewardResponse]);

    const getList = async (payload) => {
        setLoading(true);
        try {
            const response = await rewardService.getAllRewardsClaimHistories(params.id, payload);
            if (response?.data) {
                setRewardResponse(response.data);
            }
            setLoading(false);

        } catch {
            setLoading(false);
        }

    }

    const handleChangePagination = (page: number) => {
        getList({ page, per_page: 20 });
    };

    return (
        <div className='RewardDashBoard'>
            <div className="z-1 h-full outline-none">
                <>
                    <div className="h-[calc(100vh-175px)] max-w-full overflow-x-scroll overflow-y-scroll">
                        <TableRewardDashBoard data={rewardResponse?.items} loading={loading} onReload={() => {
                            getList({ page: rewardResponse.page, per_page: rewardResponse.per_page });
                        }} />
                    </div>
                    {rewardResponse?.total > 0 && <div className="flex justify-between opacity-100 border-t ">
                        <div className="">
                            <p className="text-ooolab_dark_2 text-xs font-semibold leading-ooolab_24px pt-2">
                                Showing{' '}
                                {rewardResponse.page * rewardResponse.per_page -
                                    rewardResponse.per_page +
                                    1}
                                -
                                {rewardResponse?.items?.length +
                                    rewardResponse.per_page *
                                    (rewardResponse.page - 1)}{' '}
                                of {rewardResponse.total}
                            </p>
                        </div>
                        <div className="">
                            <TablePagination
                                onClickPagination={handleChangePagination}
                                perPage={rewardResponse?.per_page || 1}
                                total={rewardResponse?.total || 1}
                                forcePage={(rewardResponse?.page || 1) - 1}
                            />
                        </div>
                    </div>}
                </>
            </div>
        </div>
    );
};

export default RewardDashBoard;
