export const RestoreIcon = ({ width = '15', height = '18' }: { width?: string; height?: string }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.17251 0.91107C7.49794 1.23651 7.49794 1.76414 7.17251 2.08958L6.0951 3.16699H7.41659C11.2935 3.16699 14.4999 6.37342 14.4999 10.2503C14.4999 14.1272 11.2935 17.3337 7.41659 17.3337C3.53968 17.3337 0.333252 14.1272 0.333252 10.2503C0.333252 9.79009 0.706348 9.41699 1.16659 9.41699C1.62682 9.41699 1.99992 9.79009 1.99992 10.2503C1.99992 13.2068 4.46016 15.667 7.41659 15.667C10.373 15.667 12.8333 13.2068 12.8333 10.2503C12.8333 7.2939 10.373 4.83366 7.41659 4.83366H6.0951L7.17251 5.91107C7.49794 6.23651 7.49794 6.76414 7.17251 7.08958C6.84707 7.41502 6.31943 7.41502 5.994 7.08958L3.494 4.58958C3.16856 4.26414 3.16856 3.73651 3.494 3.41107L5.994 0.91107C6.31943 0.585633 6.84707 0.585633 7.17251 0.91107Z"
                fill="currentColor"
            />
        </svg>
    );
};
