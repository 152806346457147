import { StylesConfig } from 'react-select';

export const customSelectStyle: StylesConfig<any, true> = {
  clearIndicator: (base) => ({ ...base, display: 'none' }),
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  dropdownIndicator: (base) => ({ ...base, padding: 4 }),
  control: (base) => ({
      ...base,
      borderColor: '#e6e6e6',
      minHeight: '2rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      fontSize: 14
  }),
};

export const optionGoldAchieved = [
  {
      label: "Yes",
      value: 2,
  },
  {
      label: "No",
      value: 1,
  },
  {
      label: "N/A",
      value: null,
  }
]

export const optionFailedGoal = [
  {
    label: "Comprehension",
    value: 1
  },
  {
    label: "Grammar",
    value: 2
  },
  {
    label: "Pronunciation",
    value: 3
  },
  {
    label: "Vocabulary",
    value: 4
  },
  {
    label: "Fluency",
    value: 5
  }
]