import { Switch } from '@headlessui/react';
import { PencilAltIcon, TrashIcon, XIcon } from '@heroicons/react/outline';
import { PencilIcon, PlusIcon } from '@heroicons/react/solid';
import dotIcon from 'assets/SVG/dotIcon.svg';
import h5pIcon from 'assets/SVG/h5p.svg';
import ActionsCircleButton from 'components/ActionsCircleButton';
import ListH5PModal from 'components/H5P/ListH5PModal';
import Modal from 'components/Modal';
import { H5P_LIBRARY } from 'constant/h5p.const';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ContentService } from 'services/h5p/ContentService';
import { IH5PContentList } from 'types/H5P.type';
import { SectionFile, SectionState } from 'types/Lesson.type';

interface SectionH5PProps {
    section?: SectionState;
    onEdit: (sec: SectionState) => void;
    onDelete: () => void;
    isActive: boolean;
    isEditable?: boolean;
}

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const H5PItem: React.FC<{
    h5p_id: string | number;
    id: string;
    d: SectionFile;
    index: number;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    handleRemoveSection: (section: SectionFile) => void;
    isEditable: boolean;
}> = ({
    h5p_id,
    id,
    d,
    index,
    moveCard,
    handleRemoveSection,
    isEditable = true,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const params: { id: string } = useParams();
    const history = useHistory();
    const [{ handlerId }, drop] = useDrop({
        accept: 'card',
        collect: (monitor) => {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover: (item: DragItem, monitor: DropTargetMonitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY =
                (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
        canDrop: () => isEditable,
    });
    const [{ isDragging }, drag, preview] = useDrag({
        type: 'card',
        item: () => {
            return { id, index };
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: isEditable,
    });
    drag(drop(ref));
    const opacity = isDragging ? 0 : 1;

    return (
        <div
            style={{ opacity }}
            ref={preview}
            data-handler-id={handlerId}
            className="flex items-center justify-between border border-ooolab_dark_1 rounded-sub_tab group hover:bg-ooolab_bg_bar cursor-pointer   duration-300 mb-2"
        >
            <div className="">
                <div className=" flex items-center w-full">
                    <div className=" w-10 h-10  m-1" ref={ref}>
                        <img
                            className="w-20 group-hover:hidden"
                            alt=""
                            src={h5pIcon}
                        />
                        <div className="hidden w-10 h-10 items-center justify-center group-hover:flex rounded-ooolab_circle bg-white group ">
                            <img
                                className=" w-5 h-5 group-hover:flex  hover:fill-item_bar_hover"
                                alt=""
                                src={dotIcon}
                            />
                        </div>
                    </div>
                    <div className=" text-base text-ooolab_text_username">
                        <p>{d.file_name}</p>
                    </div>
                </div>
            </div>

            <div className=" flex  justify-between items-center text-ooolab_dark_2 mr-2">
                <p className="text-sm truncate">
                    {d?.main_library &&
                        H5P_LIBRARY[d?.main_library?.split('.')[1]]}
                </p>
                {/* {(isEditable && (
                    <div className="">
                        <XIcon
                            onClick={() => handleRemoveSection(d)}
                            className=" justify-center w-5 h-5 group-hover:visible hover:text-ooolab_blue_4 cursor-pointer "
                        />
                    </div>
                )) ||
                    null} */}
                <div className="flex justify-between">
                    <PencilIcon
                        onClick={() =>
                            window.open(
                                `/workspace/${params.id}/h5p-content/${h5p_id}`
                            )
                        }
                        className=" justify-center w-5 h-5 group-hover:visible hover:text-ooolab_blue_4 cursor-pointer "
                    />
                    <XIcon
                        onClick={() => handleRemoveSection(d)}
                        className=" justify-center w-5 h-5 group-hover:visible hover:text-ooolab_blue_4 cursor-pointer "
                    />
                </div>
            </div>
        </div>
    );
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const SectionH5P: React.FC<SectionH5PProps> = ({
    section,
    onEdit,
    isActive,
    isEditable = true,
    onDelete,
}) => {
    const { t: translator } = useTranslation();
    const [assignmentErr, setAssignmentErr] = useState(false);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [sectionState, setSectionState] = useState<SectionState>({
        description: '',
        files: [],
        title: '',
        type: section.type,
    });

    const [contentList, setContentList] = useState<IH5PContentList>({
        items: [],
        order: undefined,
        page: undefined,
        per_page: undefined,
        sort_by: undefined,
        total: undefined,
    });

    const [
        filteredContentList,
        setFilteredContentList,
    ] = useState<IH5PContentList>(contentList);

    const params: { id: string } = useParams();

    const contentService: any = new ContentService(
        `/h5p/workspaces/${params.id}`
    );
    const contentServiceQuiz: any = new ContentService(
        `/h5p/workspaces/${params.id}?content_type=H5P.QuestionSet`
    );

    async function updateList() {
        // if (sectionState.type != 'assignment') {
        await contentService.list().then((result: any) => {
            setContentList(result);
        });
        // } else {
        //     await contentServiceQuiz.list().then((result: any) => {
        //         setContentList(result);
        //     });
        // }
    }

    useEffect(() => {
        if (section) {
            const newSection = {
                title: section.title,
                files: (section.files || []).map((file: any) => ({
                    ...file,
                    uid:
                        file.uid ||
                        Math.ceil(Math.random() * 100000000).toString(),
                })),
                description: section.description,
                type: section.type,
            };
            setSectionState(newSection);
        }
        updateList();
    }, [section]);
    useEffect(() => {
        updateList();
    }, [sectionState.type]);
    function toggle() {
        setFilteredContentList({
            ...contentList,
            // items: contentList.items.filter((item: any) =>
            //     (sectionState?.files || []).every((file: SectionFile) => {
            //         const uid = file.uid.match(
            //             /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/g
            //         );
            //         console.log('file', uid[0] !== item.uid);
            //         if (!uid || uid.length === 0) {
            //             return true;
            //         }
            //         return uid[0] !== item.uid;
            //     })
            // ),
        });
        setOpen(true);
    }

    const handleRemoveSection = (section: SectionFile) => {
        const sectionRemove: SectionState = {
            title: sectionState.title,
            description: sectionState.description,
            files: sectionState?.files?.filter((c) => c !== section),
        };
        onEdit(sectionRemove);
        setSectionState(sectionRemove);
    };

    const moveCard = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const files = sectionState.files || [];
            const card = files[dragIndex];
            let tmp;
            if (dragIndex < hoverIndex) {
                tmp = {
                    ...sectionState,
                    files: [
                        ...files.slice(0, dragIndex),
                        ...files.slice(dragIndex + 1, hoverIndex + 1),
                        card,
                        ...files.slice(hoverIndex + 1),
                    ],
                };
            } else {
                tmp = {
                    ...sectionState,
                    files: [
                        ...files.slice(0, hoverIndex),
                        card,
                        ...files.slice(hoverIndex, dragIndex),
                        ...files.slice(dragIndex + 1),
                    ],
                };
            }
            setSectionState(tmp);
            onEdit(tmp);
        },
        [sectionState]
    );

    function handleAddH5p(sec: SectionState) {
        setSectionState(sec);
        onEdit(sec);
    }
    return (
        <div className="h-full">
            {isActive &&
                ((
                    <>
                        <div className="flex w-full items-center">
                            <div className="w-full lg:1/2">
                                <h3 className="text-ooolab_dark_1 text-ooolab_lg">
                                    <input
                                        disabled={!isActive || !isEditable}
                                        onChange={(e) => {
                                            const tmp = { ...sectionState };
                                            tmp.title = e.target.value;
                                            onEdit(tmp);
                                            console.log(tmp);
                                            setSectionState(tmp);
                                        }}
                                        id="section-h5p-input"
                                        className="focus:outline-none w-20 focus:w-1/2 transition-all duration-100 ease-out bg-white border-b border-white focus:border-ooolab_gray_2"
                                        value={sectionState.title}
                                        placeholder={''}
                                    />
                                    <span className="text-ooolab_dark_2 text-ooolab_sm pl-ooolab_p_4">
                                        {sectionState?.files &&
                                        sectionState?.files?.length > 0
                                            ? `${
                                                  sectionState.files.length
                                              } ${translator('FILE')}`
                                            : `  0 ${translator('FILE')}`}
                                    </span>
                                </h3>
                            </div>
                            <div className="w-full lg:1/2 flex lg:justify-end lg:mt-0 mt-2 items-center">
                                {(isEditable && (
                                    <div className="text-ooolab_dark_2 flex">
                                        <ActionsCircleButton
                                            onclick={() => {
                                                const ele = document.getElementById(
                                                    'section-h5p-input'
                                                );
                                                if (ele) {
                                                    ele.focus();
                                                }
                                            }}
                                            icon={
                                                <PencilAltIcon className="w-4 h-4 cursor-pointer" />
                                            }
                                        />
                                        <ActionsCircleButton
                                            onclick={() => onDelete()}
                                            icon={
                                                <TrashIcon className="w-4 h-4 cursor-pointer" />
                                            }
                                        />
                                    </div>
                                )) ||
                                    null}
                                <Modal
                                    isOpen={assignmentErr}
                                    onClose={() => setAssignmentErr(false)}
                                    title={translator(
                                        'ASSIGNMENT.ERROR.MUST_BE_QUIZ'
                                    )}
                                    mainBtn={
                                        <button
                                            onClick={() =>
                                                setAssignmentErr(false)
                                            }
                                            className="bg-red-500 text-white py-2 px-4 rounded"
                                        >
                                            Close
                                        </button>
                                    }
                                />
                                <Switch.Group
                                    as="div"
                                    className="flex items-center ml6"
                                >
                                    <Switch.Label as="span" className="ml-3">
                                        <span className="text-sm font-medium text-gray-900 mr-2">
                                            Assignment{' '}
                                        </span>
                                        {/* <span className="text-sm text-gray-500">(Save 10%)</span> */}
                                    </Switch.Label>

                                    <Switch
                                        checked={
                                            sectionState.type == 'assignment'
                                        }
                                        onChange={() => {
                                            const tmp = { ...sectionState };
                                            // const listContent = tmp.files.filter(
                                            //     (i) =>
                                            //         i.main_library !==
                                            //         'H5P.QuestionSet'
                                            // );
                                            // if (listContent.length === 0) {
                                            if (tmp.type != 'assignment') {
                                                tmp.type = 'assignment';
                                            } else {
                                                tmp.type = undefined;
                                            }
                                            onEdit(tmp);
                                            setSectionState(tmp);
                                            // } else setAssignmentErr(true);
                                        }}
                                        className={classNames(
                                            sectionState.type == 'assignment'
                                                ? 'bg-indigo-600'
                                                : 'bg-gray-200',
                                            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                        )}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={classNames(
                                                sectionState.type ==
                                                    'assignment'
                                                    ? 'translate-x-5'
                                                    : 'translate-x-0',
                                                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                            )}
                                        />
                                    </Switch>
                                </Switch.Group>
                            </div>
                        </div>
                        <div className="h-auto max-h-full overflow-y-scroll custom-scrollbar my-4 pb-10">
                            {sectionState?.files &&
                                sectionState.files.map((d, index) => (
                                    <H5PItem
                                        h5p_id={d.id}
                                        id={d.uid}
                                        d={d}
                                        index={index}
                                        moveCard={moveCard}
                                        key={d.uid}
                                        handleRemoveSection={
                                            handleRemoveSection
                                        }
                                        isEditable={isEditable}
                                    />
                                ))}
                            {isEditable && (
                                <div className=" flex justify-center items-center border-dashed border border-ooolab_dark_1  rounded-sub_tab h-12  group group-hover:border-ooolab_selected_bar_item ">
                                    <button
                                        className="flex justify-center items-center bg-ooolab_bg_btn rounded-ooolab_card p-ooolab_p_3 h-ooolab_h_8 focus:outline-none"
                                        onClick={toggle}
                                    >
                                        <PlusIcon className="   w-ooolab_w_6 h-ooolab_h_6 text-ooolab_dark_1 group-hover:text-primary-500" />
                                        <p className="text-ooolab_sm text-ooolab_dark_1 group-hover:text-primary-500">
                                            {translator(
                                                'DASHBOARD.LESSONS.ADD_H5P'
                                            )}
                                        </p>
                                    </button>
                                </div>
                            )}
                        </div>
                    </>
                ) ||
                    null)}
            {isOpen && (
                <ListH5PModal
                    contentList={filteredContentList}
                    isOpen={isOpen}
                    setOpen={setOpen}
                    workspaceID={params.id}
                    setSectionState={handleAddH5p}
                    sectionState={sectionState}
                />
            )}
        </div>
    );
};

export default SectionH5P;
