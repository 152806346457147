import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './../store';
import { UploadState } from 'types/Library.type';

interface ConfigPageState {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    expandedMenu: boolean
}
const initialState: ConfigPageState = {
    loading: 'idle',
    expandedMenu: true,
};

export const configPageSlice = createSlice({
    name: 'configPage',
    initialState,
    reducers: {
        changeExpandedMenu: (state, action: PayloadAction<boolean>) => {
            state.expandedMenu = action.payload;
        },
    },
    extraReducers: (builder) => {},
});

export const { changeExpandedMenu } = configPageSlice.actions;

export const selectConfigPageSlice = (state: RootState) => state.configPage;

export default configPageSlice.reducer;
