export interface ActivitiesOverviewModel {
    total_member: number;
    total_active_member: number;
    total_deactived_member: number;
}

export const PlatFormConst ={
    clms: "CLMS",
    learner_portal: "Learner Portal",
    instructor_portal: "Instructor Portal"
}