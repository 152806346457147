import { CheckIcon, XIcon } from '@heroicons/react/outline';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { InputField } from 'components/InputForm/InputForm';
import courseService from 'services/course.service';
import { useAppDispatch } from 'hooks/hooks';
import { updatePeopleNumber } from 'store/features/courseSlice';
import SelectBox from '_shared/components/Select';
import { useDebouncedState } from 'hooks/useDebounce';
import { EditIcon } from 'assets/icon';
import { NoContentWithAction } from 'components/V2/NoContentWithAction/NoContentWithAction';
import NO_LEARNER from 'assets/img/empty-state/student.png';

export default function ModalAddMemberWithCredit({ enrollmentSelected, onCloseModal, refreshData }) {
    const dispatch = useAppDispatch();
    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
        setValue,
        watch,
        register,
    } = useForm({ mode: 'onChange' });
    const { t: translator } = useTranslation();
    const { fields, append, remove, replace, update} = useFieldArray({
        control,
        name: 'credits',
    });

    const [learners, setLearners] = useState([]);
    const [inputSearchLearner, setInputSearchLearner] = useDebouncedState('', 0);
    const [paginationLearner, setPaginationLearner] = useState({ page: 1, per_page: 10, total: 10 });
    const [loadinglearner, setLoadingLearner] = useState(false);

    const [preventFocus, setPreventFocus] = useState(false);

    const params: any = useParams();
    const workspaceId = params.id;

    const getLearners = (page: number) => {
        setLoadingLearner(true);
        let payload = {
            page: page ? page : paginationLearner.page,
            per_page: paginationLearner.per_page,
            q: inputSearchLearner,
            status: 'active'
        };
        if (!payload?.q) delete payload?.q
        courseService.getMemberCanAddToEnrollment(params?.id, params?.courseId, enrollmentSelected?.id, payload)
        .then((res) => {
            const members = res?.data?.items?.map((item) => {
              return {
                ...item,
                name: item?.display_name,
                value: item?.membership_id,
              }
            })
            if (page === 1) setLearners(members);
            else setLearners([...learners, ...members]);
            setPaginationLearner({ ...paginationLearner, total: res?.data?.total, page });
        })
        .finally(() => setLoadingLearner(false));
    };

    const onSubmit = (value) => {
        const members = value?.credits
            ?.filter((item) => item?.amount > 0)
            ?.map((item) => {
                return {
                    membership_id: item?.value,
                    credit_amount: +item?.amount,
                    note: item?.note,
                };
            });
        const enrollData = {
            type: "learning_group",
            learning_group_id: enrollmentSelected?.learning_group_id,
            start_date: enrollmentSelected?.start_date,
            end_date: enrollmentSelected?.end_date,
            members,
            action: "add_extra"
        };

        courseService
            .enrollPeople(workspaceId, params?.courseId, enrollData)
            .then((res) => {
                if (res?.data?.error?.code == 400) {
                    return Toastify.error(
                        {
                            title: translator("COURSES.ENROLL_LEARNER_ERROR"),
                            message: res?.data?.error?.description,
                        },
                        { autoClose: 5000 }
                    );
                }
                if (res?.data?.error?.code == 500) {
                    return Toastify.error();
                }
                const numOfMember = enrollData?.members?.length;
                Toastify.success(
                    {
                        title: numOfMember === 1 ? translator("COURSES.ADD_1_LEARNER_TO_ENROLLMET_SUCCESS") : translator("COURSES.ADD_LEARNERS_TO_ENROLLMET_SUCCESS"),
                        message:
                            numOfMember === 1
                                ? translator("COURSES.ADD_1_LEARNER_TO_ENROLLMET_SUCCESS_CONTENT", {learningGroupName: enrollmentSelected?.learning_group?.name})
                                : translator("COURSES.ADD_LEARNERS_TO_ENROLLMET_SUCCESS_CONTENT", {amount: enrollData?.members?.length, learningGroupName: enrollmentSelected?.learning_group?.name}),
                    },
                    { autoClose: 5000 }
                );
                courseService.getCountPeople(params.id, params.courseId).then((response: any) => {
                    dispatch(updatePeopleNumber(response?.data));
                });
                onCloseModal(true);
                refreshData();
            })
            .catch(() => Toastify.error());
    };

    useEffect(() => {
        getLearners(1);
    }, [inputSearchLearner]);

    const getCreditOfAccount = (item) => {
        setPreventFocus(true);
        clearInterval(intervalPreventFocus);
        var intervalPreventFocus = setInterval(() => {
            setPreventFocus(false);
        }, 1000);
        append(item);
    };

    const onRemove = (value, index) => {
        const credits = watch('credits')?.filter((item: any) => item?.value !== value);
        setValue('learner', credits);
        remove(index);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="ModalNewEnrollment">
            <div className="bg-gray-50 rounded-lg px-4 py-2 !mt-5">
                <div className="field !mt-0">
                    <SelectBox
                        control={control}
                        label={translator('COURSES.LEARNER')}
                        name="learner"
                        placeholder={translator('COURSES.SELECT_LEARNERS')}
                        isRequired
                        errors={errors}
                        getData={getLearners}
                        isMulti={true}
                        onSearch={(value) => setInputSearchLearner(value)}
                        data={learners}
                        pagination={paginationLearner}
                        value={watch('learner')}
                        numberOfItemDisplayed={4}
                        loading={loadinglearner}
                        actionAfterRemoveItemSelected={(item, index) => remove(index)}
                        actionAfterSelectItem={(item) => {
                            getCreditOfAccount(item);
                        }}
                        actionAfterRemoveAllItem={() => replace([])}
                        emptyState={{
                            message: translator('COURSES.NO_LEARNER'),
                            img: NO_LEARNER,
                        }}
                        isShowSearchIcon={true}
                    />
                </div>
                <div className="flex justify-center">
                    {learners?.length === 0 && <NoContentWithAction styleNoContent="!text-xs" content={translator('COURSES.NO_LEARNER')} img={NO_LEARNER} />}
                    {learners?.length > 0 && fields?.length === 0 && (
                        <NoContentWithAction styleNoContent="!text-xs" content={translator("COURSES.NO_LEARNER_SELECTED")} img={NO_LEARNER} />
                    )}
                </div>

                {fields?.length > 0 && (
                    <div className="field !mt-5 min-h-[90px] max-h-[300px] overflow-x-auto relative ">
                        <div className="grid grid-cols-8 text-xs font-semibold border-b border-gray-300 pb-2 sticky top-0 bg-gray-50 z-1 mb-2">
                            <div className="col-span-2 px-1">{translator("NAME")}</div>
                            <div className="col-span-2 px-1 truncate text-ellipsis">
                                {translator("CREDITS")} <span className="text-red-500">*</span>
                            </div>
                            <div className="col-span-4 px-1">{translator("NOTES")}</div>
                        </div>

                        {fields
                            ?.map((item: any, index: number) => {
                                if (!item?.credit_balance) return;
                                return (
                                    <div className="grid grid-cols-8 text-xs py-2.5 relative " key={index}>
                                        <div className="col-span-2 text-ellipsis truncate px-1 w-full mt-0.5">{item?.name}</div>
                                        <div className="col-span-2 px-1 flex items-center gap-0.5 relative !text-gray-800 ">
                                            <InputField
                                                name={`credits.${index}.amount`}
                                                register={register(`credits.${index}.amount`, {
                                                    required: {
                                                        value: item?.credit_balance > 0,
                                                        message: translator('FORM_CONST.REQUIRED_FIELD'),
                                                    },
                                                    validate: {
                                                        number: (value) => {
                                                            return +value > 0 && +value <= item?.credit_balance
                                                                ? true
                                                                : `Input number 1 - ${item?.credit_balance}`;
                                                        },
                                                    },
                                                })}
                                                type="number"
                                                className="!gap-0 "
                                                classNameInput="h-6 disabled:cursor-not-allowed !w-[60px]"
                                                disabled={item?.credit_balance === 0 || preventFocus}
                                                errors={errors}
                                                classError="whitespace-nowrap !text-[10px] !font-normal absolute -bottom-4"
                                                labelHorizontal={`/${item?.credit_balance}`}
                                                classLabelHorizontal="!text-gray-800 -ml-1"
                                            />
                                        </div>

                                        <div className="col-span-4 px-1 flex items-center pr-6">
                                            <div className="flex space-x-2 w-full">
                                                {!watch(`credits.${index}.note`) && !watch(`credits.${index}.isEditing`) && (
                                                    <div
                                                        className="text-primary-500 flex items-center gap-1 cursor-pointer"
                                                        onClick={() => setValue(`credits.${index}.isEditing`, true)}
                                                    >
                                                        <EditIcon fill="#0071ce" /> {translator("COURSES.ADD_NOTE")}
                                                    </div>
                                                )}
                                                {watch(`credits.${index}.isEditing`) && (
                                                    <div
                                                        className="relative"
                                                        onClick={() => setValue(`credits.${index}.isEditing`, true)}
                                                    >
                                                        <InputField
                                                            placeholder={'Enter note here'}
                                                            name={`credits.${index}.note`}
                                                            errors={errors}
                                                            classNameInput="h-6 text-xs"
                                                            register={register(`credits.${index}.note`, {
                                                                maxLength: {
                                                                    value: 1000,
                                                                    message: translator('FORM_CONST.MAX_LENGTH_TEXT', {
                                                                        maxLength: 1000,
                                                                    }),
                                                                },
                                                            })}
                                                            classError="whitespace-nowrap !text-[10px] !font-normal absolute -bottom-4"
                                                            inputProps={{ autoFocus: true }}
                                                        />
                                                    </div>
                                                )}

                                                {watch(`credits.${index}.note`) && !watch(`credits.${index}.isEditing`) && (
                                                    <div
                                                        onClick={() => setValue(`credits.${index}.isEditing`, true)}
                                                        className="cursor-pointer text-ellipsis line-clamp-2 text-xs"
                                                    >
                                                        {' '}
                                                        {watch(`credits.${index}.note`)}{' '}
                                                    </div>
                                                )}

                                                {watch(`credits.${index}.isEditing`) &&
                                                    watch(`credits.${index}.note`)?.length <= 1000 && (
                                                        <span
                                                            className={`mt-0.5 ${
                                                                !!errors[`credits.${index}.note`]
                                                                    ? 'cursor-not-allowed'
                                                                    : 'cursor-pointer'
                                                            }`}
                                                            onClick={() => {
                                                                setValue(`credits.${index}.note`, watch(`credits.${index}.note`));
                                                                setValue(`credits.${index}.isEditing`, false);
                                                            }}
                                                        >
                                                            <CheckIcon className="w-4 h-4 text-blue-500 bg-white" />
                                                        </span>
                                                    )}
                                                {watch(`credits.${index}.isEditing`) && (
                                                    <span
                                                        className="mt-0.5 cursor-pointer"
                                                        onClick={() => {
                                                            setValue(`credits.${index}.note`, item?.note);
                                                            setValue(`credits.${index}.isEditing`, false);
                                                        }}
                                                    >
                                                        <XIcon className="w-4 h-4 bg-white" />
                                                    </span>
                                                )}
                                            </div>
                                        </div>

                                        <div
                                            onClick={() => onRemove(item?.value, index)}
                                            className="absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer"
                                        >
                                            <XIcon className="text-red-500 w-5 h-5" />
                                        </div>
                                    </div>
                                );
                            })}

                        {fields?.filter((item: any) => item?.credit_balance === 0)?.length > 0 && (
                            <div className="text-xs text-gray-500 p-1">
                                {translator("COURSES.MEMBER_WITH_NO_CREDIT")}
                            </div>
                        )}
                        {fields
                            ?.map((item: any, index: number) => {
                                if (item?.credit_balance > 0) return;
                                return (
                                    <div className="grid grid-cols-8 text-xs py-2 relative" key={index}>
                                        <div className="col-span-2 text-ellipsis truncate px-1 mt-0.5">{item?.name}</div>
                                        <div className="col-span-2 px-1 flex items-center gap-0.5">
                                            <InputField
                                                name={`credits.${index}.amount_nocredit`}
                                                className="!gap-0 text-gray-800"
                                                classNameInput="h-6 disabled:cursor-not-allowed !w-[60px]"
                                                disabled={item?.credit_balance === 0}
                                                labelHorizontal={`/${item?.credit_balance}`}
                                                classLabelHorizontal="!text-gray-800 -ml-1"
                                            />
                                        </div>
                                        <div
                                            onClick={() => onRemove(item?.value, index)}
                                            className="absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer"
                                        >
                                            <XIcon className="text-red-500 w-5 h-5" />
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                )}
            </div>

            <div className="field group-button">
                <ButtonOutline type="Button" onClick={() => onCloseModal()}>
                    {translator('CANCEL')}
                </ButtonOutline>
                <ButtonPrimary
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={!isValid || watch('credits')?.filter((credit) => credit?.amount > 0)?.length === 0}
                >
                    {translator('ADD')}
                </ButtonPrimary>
            </div>
        </form>
    );
}
