import { MessageIcon } from 'assets/icon/MessageIcon';
import { COLORS } from 'constant/performance.const';
import { useTranslation } from 'react-i18next';
import { randomIntFromInterval } from 'utils/math';

type CommentsProps = {
    comments?: [];
    isEnglish?: boolean;
    className?: string;
};

const Comments = ({
    comments,
    isEnglish = false,
    className = undefined,
}: CommentsProps) => {
    const { t: translator } = useTranslation();

    return (
        <>
            {comments?.map(
                (item: {
                    id: string;
                    display_name: string;
                    comment: string;
                    comment_vn?: string;
                }) => {
                    const radomColor = randomIntFromInterval(1, 6);
                    const color = COLORS[radomColor];
                    return (
                        <>
                            {(item?.comment || item?.comment_vn) && (
                                <div className={`${className && className}`}>
                                    {item?.comment && (
                                        <div key={item.id}>
                                            <div className="text-[#009FB2] text-sm flex space-x-2 items-center">
                                                <MessageIcon fill={color} />
                                                <span style={{ color: color }}>
                                                    {translator(
                                                        'MY_REPORTS.TEACHER_COMMENT_REPORT'
                                                    ).replace(
                                                        '(Name)',
                                                        item.display_name
                                                    )}{' '}
                                                    {isEnglish && ' (EN)'}
                                                </span>
                                            </div>
                                            <p className="text-sm text-[#2E3A59] mt-1 mb-4">
                                                {item.comment}
                                            </p>
                                        </div>
                                    )}
                                    {item?.comment_vn && (
                                        <div key={'vn_' + item.id}>
                                            <div className="text-[#009FB2] text-sm flex space-x-2 items-center">
                                                <MessageIcon fill={color} />
                                                <span style={{ color: color }}>
                                                    {translator(
                                                        'MY_REPORTS.TEACHER_COMMENT_REPORT'
                                                    ).replace(
                                                        '(Name)',
                                                        item.display_name
                                                    )}{' '}
                                                    {isEnglish && ' (VI)'}
                                                </span>
                                            </div>
                                            <p className="text-sm text-[#2E3A59] mt-1 mb-4">
                                                {item.comment_vn}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    );
                }
            )}
        </>
    );
};

export default Comments;
