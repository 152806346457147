import React, { useContext, useEffect, useState } from 'react';
import {
    ArrowDownIcon,
    ArrowUpIcon,
} from '@heroicons/react/solid';
import {
    IContentListEntry,
    IH5PContentList,
    ParamsH5P,
} from 'types/H5P.type';
import { H5PFilterBar, H5POptions, SelectView } from '../H5PComponents';
import { H5PContext } from 'contexts/H5P/H5PContext';
import h5pMiddlware from 'middleware/h5p.middlware';
import { updateList } from '../H5PFN';
import { useHistory, useParams } from 'react-router';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useTranslation } from 'react-i18next';
import { ColumnWithLooseAccessor } from 'react-table';
import Table from 'components/Table';
import dayJs from 'dayjs';
import { H5P_LIBRARY } from 'constant/h5p.const';
import Tooltip from 'components/Tooltip';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { NoContentWithImage } from 'components/NoContent/NoContent';

interface H5PTableViewProps {
    contentList: IH5PContentList | undefined;
    handlePagination(page: number): void;
    setContentList: React.Dispatch<React.SetStateAction<IH5PContentList>>;
}

const H5PTableView: React.FC<H5PTableViewProps> = ({
    contentList,
    handlePagination,
    setContentList,
}) => {
    const { t: translator } = useTranslation();
    const [selected, setSelected] = useState<IContentListEntry>();
    const h5PCtx = React.useContext(H5PContext);
    const paramUrl: { id: string } = useParams();
    const {
        dispatch: h5pDispatch,
        H5PState: { params, isLoading },
    } = h5PCtx;

    const history = useHistory();

    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);

    const { result: workspaceDetailInformation } = getWorkspaceDetailState;

    const columns: ColumnWithLooseAccessor[] = React.useMemo(
        () => [
            {
                Header: () => (
                    <p className="text-left pl-4">{translator('NAME')}</p>
                ),
                accessor: 'title', // accessor is the "key" in the data
                Cell: (d: any) => {
                    return (
                        <Tooltip title={d?.value} mlClass="ml-0">
                            <span className="flex items-center text-xs  font-normal group-hover:text-primary-500 pl-3">
                                {d?.value?.length < 56
                                    ? d?.value
                                    : `${d?.value?.slice(0, 57)}...`}
                            </span>
                        </Tooltip>
                    );
                },
            },
            {
                Header: () => (
                    <div
                        className="col-span-3 text-primary-500 flex  items-center  cursor-pointer "
                        onClick={handleSort}
                    >
                        <p className="text-sm ">
                            {translator('LAST_MODIFIED')}
                        </p>
                        {params.order === 'desc' ? (
                            <ArrowDownIcon className="w-4 h-4 ml-1 " />
                        ) : (
                            <ArrowUpIcon className="w-4 h-4 ml-1 " />
                        )}
                    </div>
                ),
                accessor: 'updated_at',
                Cell: (d: any) => {
                    return (
                        <p className="text-ooolab_text_bar_inactive">
                            {dayJs.utc(d.value).tz().locale('en-gb').fromNow()}
                        </p>
                    );
                },
            },
            {
                Header: () => (
                    <p className="text-sm">
                        {translator('DASHBOARD.H5P_CONTENTS.CONTENT_TYPE')}
                    </p>
                ),
                accessor: 'mainLibrary',
                Cell: (d: any) => {
                    return (
                        <p className="text-xs ">
                            {d?.value?.split('.')[1] &&
                                H5P_LIBRARY[d?.value?.split('.')[1]]}
                        </p>
                    );
                },
            },
            {
                accessor: 'contentId',
                Cell: (d: any) => {
                    return (
                        <div className='flex justify-center'>
                            <H5POptions
                            canDelete={true}
                            id={d?.value}
                            workspace={paramUrl.id}
                            dataRowSelected={d?.row?.values || {}}
                            setSelected={setSelected}
                            contentList={contentList}
                            setContentList={setContentList}
                        />
                        </div>
                    );
                },
            },
        ],
        [contentList, workspaceDetailInformation]
    );

    function handleSort() {
        const searchH5p: ParamsH5P = {
            ...params,
            order: params.order === 'desc' ? 'asc' : 'desc',
        };
        h5pMiddlware.h5pParamsContent(h5pDispatch, searchH5p);
        updateList(h5pDispatch, paramUrl.id, searchH5p);
    }
    return (
        <div>
            <div className="">
                {selected ? (
                    <SelectView
                        canDelete={true}
                        data={selected}
                        setSelected={setSelected}
                        contentList={contentList}
                        setContentList={setContentList}
                    />
                ) : (
                    <H5PFilterBar />
                )}
            </div>
            <div className="overflow-x-auto overflow-y-hidden">
                <div className=" align-middle inline-block min-w-full  ">
                    {isLoading && <div className='flex items-center justify-center'>
                        <CircleSpin color='text-primary-500' />
                    </div>}
                    {contentList?.items?.length > 0 && !isLoading && <Table
                        columns={columns}
                        data={contentList.items}
                        rowProps={{
                            className:
                                'bg-white hover:bg-gray-50 cursor-pointer',
                        }}
                        headerProps={{
                            className: 'bg-gray-50',
                        }}
                        className="min-w-full divide-y divide-gray-200"
                        onClickRow={(e) => setSelected(e)}
                        onDoubleClickRow={(e) =>
                            history.push(
                                `/workspace/${paramUrl.id}/h5p-content/${e}`
                            )
                        }
                    />}
                    {contentList?.items?.length <= 0 && !isLoading &&
                        <NoContentWithImage content='No Content' />
                    }
                </div>
            </div>
        </div>
    );
};

export default H5PTableView;
