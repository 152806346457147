import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import HeaderPage from 'components/HeaderPage';
import SearchBox from 'components/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import ButtonComponent from 'components/Button/ButtonComponent';
import { ClockIcon, DuplicateIcon, RefreshIcon, SearchIcon } from '@heroicons/react/outline';
import DatePicker from 'components/DatePicker/DatePicker';
import Spinner from 'components/Spinner';
import TableSalesforce from './TableSalesforce';
import FooterPage from 'components/Footer/FooterPage';
import { copyClipboard, getIndexPage } from 'utils/commonFun';
import { useBoolean } from 'hooks/custom';
import DialogComponent from 'components/Dialog/DialogComponent';
import { WarningSVG } from 'assets/icon';
import ModalSetting from './ModalSetting';
import { TooltipCustom } from 'components/Tooltip/Tooltip';
import { SalesforceService } from 'services';
import dayjs from 'dayjs';
import { UserContext } from 'contexts/User/UserContext';
import { addHoursAndMins } from 'constant/util.const';
import { Toastify } from '_shared';

const index: FC = ({ children }) => {
    const { t } = useTranslation();
    const params: any = useParams();
    const {
        userState: { result: userInformation },
    } = useContext(UserContext);

    const { booleanValue: modalViewLog, toggleBooleanValue: toggleModalViewLog } = useBoolean();
    const { booleanValue: modalSetting, toggleBooleanValue: toggleModalSetting } = useBoolean();
    const [keyword, setKeyword] = useDebouncedState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selected, setSelected] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [loadingSync, setLoadingSync] = useState<boolean>(false);
    const [indexPage, setIndexPage] = useState('0-0');
    const [dataTable, setDataTable] = useState([]);
    const [pagination, setPagination] = React.useState({
        page: 1,
        per_page: 20,
        order: 'desc',
        total: 0,
        sort_by: 'updated_at',
    });

    const onChangeEndDate = (date) => {
        const hours = dayjs(date)?.hour();
        const minutes = dayjs(date)?.minute();
        if (hours === 0 && minutes === 0) setEndDate(addHoursAndMins(date, 23, 59));
        else setEndDate(date);
    };

    const workspaceId = params.id;

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`SALESFORCE.${str}`, opt));

    const fetchSalesforceList = async (page: number | string = 1) => {
        setLoading(true);
        const params = {
            ...pagination,
            page,
            q: keyword,
            start_time: startDate ? dayjs(startDate).format('YYYY-MM-DDTHH:mm') : null,
            end_time: endDate ? dayjs(endDate).format('YYYY-MM-DDTHH:mm') : null,
        };
        if (!params.q) delete params.q;
        if (!params.start_time) delete params.start_time;
        if (!params.end_time) delete params.end_time;
        await SalesforceService.getListSalesforce(workspaceId, params).then((res) => {
            const { items, page, per_page, order, sort_by, total } = res?.data;
            setDataTable(items);
            setPagination({ page, per_page, order, sort_by, total });
            setIndexPage(getIndexPage(page, per_page, items.length));
        });
        setLoading(false);
    };

    const handlePagination = (page: number) => {
        fetchSalesforceList(page);
    };

    useEffect(() => {
        fetchSalesforceList();
        return () => {
            setDataTable([]);
        };
    }, [keyword]);

    const onSync = (date?: any) => {
        if (!date) setLoadingSync(true);
        SalesforceService.salesforceSync(workspaceId, {
            d: date ? dayjs(date).format('YYYY-MM-DD') : dayjs(new Date()).format('YYYY-MM-DD'),
        })
            .then((res) => {
                if (res.status === 200) {
                    Toastify.success(
                        `${
                            date
                                ? `${translator('PROCESS_AGAIN')}: ${dayjs(date)
                                      .tz(userInformation?.time_zone)
                                      .format('DD/MM/YYYY, HH:mm:ss')}`
                                : `${translator('LASTED_UPDATE')} ${dayjs(new Date())
                                      .tz(userInformation?.time_zone)
                                      .format('DD/MM/YYYY, HH:mm:ss')} `
                        }`
                    );

                    fetchSalesforceList(1);
                }
            })
            .catch((error) => Toastify.error())
            .finally(() => setLoadingSync(false));
    };

    const onProcessAgain = (idProcess?: any) => {
        SalesforceService.salesforceSync(workspaceId, {
            id: idProcess,
        })
            .then((res) => {
                if (res.status === 200) {
                    Toastify.success(`${idProcess} ${translator('IS_PROCESSED_DATA_IS_UPDATED')}`);

                    fetchSalesforceList(1);
                }
            })
            .catch((error) => Toastify.error());
    };
    return (
        <div className="w-full h-screen">
            <HeaderPage label={translator('DASHBOARD.SIDEBAR.SALESFORCE_INTEGRATION', true)} />
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-3 p-4 border-y border-ooolab_bar_color">
                <div className="flex items-center space-x-3 lg:col-span-3">
                    <SearchBox onSubmit={(e: any) => setKeyword(e)} placeholder={translator('BY_NAME_OPPORTUNITY_ID_CONTACT_ID')} />
                    <div className="relative custom-date-picker-session">
                        <DatePicker
                            selected={startDate}
                            onChange={setStartDate}
                            placeholder={translator('FROM')}
                            containerClassName="w-48"
                            showTimeSelect={true}
                            dateFormat="yyyy/MM/dd - HH:mm"
                            inputProps={{
                                filterDate: (date) => (endDate ? dayjs(date)?.isBefore(dayjs(endDate)) : true),
                            }}
                        />
                    </div>
                    <div className="relative custom-date-picker-session">
                        <DatePicker
                            selected={endDate}
                            onChange={onChangeEndDate}
                            placeholder={translator('TO')}
                            containerClassName="w-48"
                            showTimeSelect={true}
                            dateFormat="yyyy/MM/dd - HH:mm"
                            inputProps={{
                                filterDate: (date) =>
                                    startDate
                                        ? dayjs(date).isSame(startDate, 'day') || dayjs(date)?.isAfter(dayjs(startDate))
                                        : true,
                            }}
                        />
                    </div>
                    <ButtonComponent
                        title={'Search'}
                        classStyle="primary-button !px-3 !disable:bg-dark-100 "
                        disable={!endDate}
                        onClickButton={() => fetchSalesforceList()}
                        icon={<SearchIcon className="w-4 h-4 mr-2" />}
                    />
                </div>
                <div className="lg:flex lg:justify-end space-x-3 items-center ">
                    <div className="flex items-center">
                        <span className="text-xs text-dark-300 mr-2">
                            {dayjs(dataTable[0]?.updated_at).tz(userInformation?.time_zone).format('DD/MM/YYYY, HH:mm:ss')}
                        </span>
                        <ClockIcon
                            className="w-5 h-5"
                            data-for="tooltipsClock"
                            data-tip={`${translator('LAST_SYNCED')} ${dayjs(dataTable[0]?.updated_at)
                                .tz(userInformation?.time_zone)
                                .format('DD/MM/YYYY, HH:mm:ss')} `}
                        />
                        <TooltipCustom
                            id="tooltipsClock"
                            content={`${translator('LAST_SYNCED')} ${dayjs(dataTable[0]?.updated_at)
                                .tz(userInformation?.time_zone)
                                .format('DD/MM/YYYY, HH:mm:ss')} `}
                            place="top"
                            type="light"
                        />
                    </div>
                    <ButtonComponent
                        title={translator('SYNC_NOW')}
                        classStyle="xs:p-2 xs:text-sm primary-button !rounded-lg"
                        onClickButton={async () => {
                            onSync();
                        }}
                        icon={<RefreshIcon className={`w-4 h-4 mr-1 ${loadingSync ? ' animate-spin' : ''}`} />}
                    />
                </div>
            </div>
            <div className="px-2">
                <div className="h-[calc(100vh-175px)] custom-scrollbar overflow-auto">
                    {loading ? (
                        <Spinner />
                    ) : (
                        <TableSalesforce
                            workspaceId={workspaceId}
                            dataTable={dataTable}
                            translator={translator}
                            rowSelected={setSelected}
                            viewLog={toggleModalViewLog}
                            loadProcess={onProcessAgain}
                        />
                    )}
                </div>
                <FooterPage
                    indexPage={indexPage}
                    total={pagination.total}
                    per_page={pagination.per_page}
                    page={pagination.page}
                    callback={handlePagination}
                />
            </div>
            <DialogComponent
                isOpen={modalViewLog}
                title={''}
                onCloseModal={toggleModalViewLog}
                maskClosable={true}
                child={
                    <div className="text-center">
                        <WarningSVG className="w-12 h-12 m-auto" />
                        <p className="mt-3 text-gray-900 text-sm font-semibold">{translator('AUTHENTICATION_ERROR')}</p>
                        <div className="flex justify-end mt-1">
                            <button
                                type="button"
                                title={''}
                                className="z-2  p-1 bg-white border border-gray-200 rounded "
                                onClick={() => copyClipboard({ id: 'logCopyClipboard' })}
                            >
                                <DuplicateIcon className="w-4 h-4 text-gray-500" />
                            </button>
                        </div>
                        <div className="mt-2 rounded border border-gray-200 bg-gray-50 px-2.5 py-1.5  min-h-[40px] max-h-[320px] overflow-auto">
                            <p className="text-left z-1 text-gray-500 text-sm font-normal" id="logCopyClipboard">
                                <pre> {(selected?.log && JSON.stringify(JSON.parse(selected?.log), null, 2)) || ''}</pre>
                            </p>
                        </div>
                    </div>
                }
            />
            <DialogComponent
                isOpen={modalSetting}
                title={translator('SETTING_SALESFORCE_INTEGRATION')}
                onCloseModal={toggleModalSetting}
                maskClosable={true}
                child={<ModalSetting translator={translator} onClose={toggleModalSetting} />}
            />
        </div>
    );
};

export default index;
