import React, { FC, useEffect, useState } from 'react';
import DialogComponent from 'components/Dialog/DialogComponent';
import { useTranslation } from 'react-i18next';
import { copyClipboard } from 'utils/commonFun';
import { ButtonOutline } from '_shared';
import resourcesService from 'services/resources.service';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

type ModalGetLinkProps = {
    isOpen: boolean;
    onClose: () => void;
    data: any;
};

export const ModalGetLink: FC<ModalGetLinkProps> = ({ isOpen, onClose, data }) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const [linkSource, setLinkSource] = useState<string>('');
    const [error, setError] = useState<any>(null);

    const fetchLinkVideo = async () => {
        setLoading(true);
        try {
            const response = await resourcesService.getDownloadUrl(data?.workspaceId, data?.object_id);
            if (response?.url) setLinkSource(response?.url);
        } catch (err) {
            setError(err?.error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLinkVideo();
    }, []);

    return (
        <DialogComponent
            title={t('GET_URL_FOR_H5P')}
            isOpen={isOpen}
            onCloseModal={onClose}
            stylesTitle="text-base"
            child={
                <>
                    <div className="mt-4 flex space-x-2 items-center text-sm">
                        {loading ? (
                            <div className="flex-1 animate-pulse flex space-x-2">
                                <div className="flex-1 h-9 bg-gray-200 rounded dark:bg-gray-700"></div>
                                <div className="w-24 h-9 bg-gray-200 rounded dark:bg-gray-700"></div>
                            </div>
                        ) : !!error ? (
                            <p className="text-red-500 flex items-center space-x-1 text-sm">
                                <ExclamationCircleIcon className="w-5 h-5" />
                                <span>{t('UNABLE_TO_GET_AN_URL_FROM_THIS_FILE')}</span>
                            </p>
                        ) : (
                            <>
                                <p className="flex-1 rounded px-3 py-2 min-h-[36px] bg-gray-100 border border-gray-200 text-[#8F90A6] truncate">
                                    {linkSource}
                                </p>
                                {isCopied ? (
                                    <span className="text-enterprise px-3">{t('LINK_COPIED')}</span>
                                ) : (
                                    <ButtonOutline
                                        type="button"
                                        disabled={!linkSource}
                                        onClick={() => {
                                            copyClipboard({ text: linkSource });
                                            setIsCopied(true);
                                        }}
                                        className="min-h-[36px] buttonOutline--isPrimary"
                                    >
                                        {t('COPY_LINK')}
                                    </ButtonOutline>
                                )}
                            </>
                        )}
                    </div>
                    <div className="mt-6 flex justify-center">
                        <ButtonOutline type="button" onClick={onClose}>
                            {t('MODALS.CLOSE')}
                        </ButtonOutline>
                    </div>
                </>
            }
        />
    );
};
