import AvailabilityDashboard from 'components/Management/AvailabilityDashboard';
import ManagementMasterPage from 'pages/RouteMasterPage';
import ManagePermissionAdmin from 'pages/Management/ManagePermissionAdmin';

const AvailabilityDashboardPage = () => {
    return (
        <ManagePermissionAdmin>
            <AvailabilityDashboard>
                <ManagementMasterPage />
            </AvailabilityDashboard>
        </ManagePermissionAdmin>
    );
};

export default AvailabilityDashboardPage;
