import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { ButtonIcon } from "_shared";
import "./BookingDetails.scss";

export const BookingLearnerGroup = ({ data, total }) => {
    const [openUser, setOpenUser] = useState(true);

    return (<div className="w-full">
        <div className="flex w-full items-center">
            <div className="flex gap-2">
                <div className="bookingDetails-learner-avatar bg-cover bg-gray-300"
                    style={{ backgroundImage: `url(${data?.avatar_url})` }}>
                </div>
                <div className="bookingDetails-learner-info flex items-center">
                    <div className="bookingDetails-name">
                        {data?.name}
                    </div>
                </div>
            </div>
            <ButtonIcon className="ml-auto h-8" type="button" onClick={() => setOpenUser(!openUser)}>
                {!openUser ? <ChevronDownIcon className="w-6 h-6" /> : <ChevronUpIcon className="w-6 h-6" />}
            </ButtonIcon>
        </div>
        {openUser && 
            <div className="mt-2.5">
                <div className="flex justify-between">
                    <div className="text-sm font-semibold">Learners</div>
                    <div className="text-sm font-semibold">Available Credits</div>
                </div>
                <div className="bookingDetails-learners mt-1.5">
                    {data?.learners?.map(item => (
                        <div className="flex gap-2 items-center justify-between" key={item.id}>
                            <div className="flex gap-1 w-1/2">
                                <div className="flex-none w-6 h-6 bg-cover rounded-full" style={{ backgroundImage: `url(${item?.avatar_url})` }}></div>
                                <div className="text-sm text-gray-900 text-ellipsis truncate w-[calc(100%-30px)]">{item?.name}</div>
                            </div>
                            <div className="text-sm text-green-500 min-w-[110px] text-center">{item?.credit_balance?.available_credit}</div>
                        </div>
                    ))}
                </div>
            </div>
        }
    </div>)
}