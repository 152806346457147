import React from "react";
import { StudentAcademicsEnrollment } from "./components/StudentAcademics/StudentAcademicsEnrollment";
import { StudentAcademicsOverview } from "./components/StudentAcademics/StudentAcademicsOverview";

export const StudentDashboardAcademics = () => {
    return<>
        <StudentAcademicsOverview />
        <div className="mt-4">
            <StudentAcademicsEnrollment />
        </div>
    </>
}