import { FC, useRef, useEffect } from 'react';
import { CalendarIcon } from '@heroicons/react/outline';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePickerInput from 'components/Management/components/Form/DatePicker';
import dayjs from 'dayjs';
import './styles.css';

interface ModalProps {
    onClose: () => void;
    title: string;
    endDate: any;
    tz: any;
    onConfirm: (name: string, endDate: any) => void;
    startDate?: string;
    sessionId?: number;
}

const ModalEditAssignment: FC<ModalProps> = ({ onClose, title, onConfirm, endDate, tz, startDate, sessionId }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        reset,
        clearErrors,
        control,
        setValue,
    } = useForm({
        defaultValues: {
            title: title,
            end_date: undefined,
            start_time: undefined,
        },
    });

    const { t: translator } = useTranslation();

    useEffect(() => {
        setValue('end_date', new Date(dayjs(endDate, 'YYYY-MM-DDTHH:mm:SSZ').tz(tz).format('YYYY-MM-DD HH:mm')));
        setValue('start_time', new Date(dayjs(startDate, 'YYYY-MM-DDTHH:mm:SSZ').tz(tz).format('YYYY-MM-DD HH:mm')));
    }, []);

    const submit = () => {
        const val = getValues();
        onConfirm(val.title, val.end_date);
        onClose();
    };

    const isOpeningCalendar = useRef(false);

    const onCloseModal = () => {
        if (isOpeningCalendar.current !== true) {
            reset();
            clearErrors();
            onClose();
        }
    };

    return (
        <form onSubmit={handleSubmit(submit)}>
            <div className="mt-5 w-full block">
                <div className="text-xs font-semibold text-dark-300">Assignment Name</div>
                <input
                    type="text"
                    defaultValue={title}
                    className="text-ooolab_text_username border border-[#EBEBF0] rounded-lg p-2 my-2 w-full"
                    {...register('title', {
                        required: {
                            value: true,
                            message: translator('FORM_CONST.REQUIRED_FIELD'),
                        },
                    })}
                />
            </div>
            {errors.title && errors.title.type === 'required' && (
                <span className="text-red-500 block font-light text-ooolab_xs">{errors.title.message}</span>
            )}
            <div className="mt-5 w-full block input-assign">
                <div className="text-xs font-semibold text-dark-300">Session ID</div>
                <input
                    disabled
                    type="text"
                    defaultValue={sessionId}
                    className=" text-ooolab_text_username border border-[#EBEBF0] rounded-lg p-2 my-2 w-full"
                />
            </div>
            <div className="mt-5 w-full block">
                <div className="text-xs font-semibold text-dark-300">Start Datetime</div>
                <div className="flex justify-between items-center  border border-[#EBEBF0] rounded-lg p-2 my-2 w-full bg-[#C7C9D9] input-assign assignment">
                    <CalendarIcon className="w-5 h-5 text-ooolab_text_username  mr-2" />

                    <DatePickerInput
                        disabled
                        labelCls="text-ooolab_text_username"
                        isOriginal={false}
                        onCalendarOpen={() => {
                            isOpeningCalendar.current = true;
                        }}
                        onCalendarClose={() => {
                            isOpeningCalendar.current = false;
                        }}
                        portal={{ id: 'custom-picker', className: 'top-level' }}
                        control={control}
                        name="start_time"
                        isRequired
                        paddingCls="py-3"
                        formatDate="yyyy-MM-dd HH:mm"
                        showTimeSelect={true}
                    />
                </div>
            </div>

            <div className="mt-5 w-full block relative">
                <div className="text-xs font-semibold text-dark-300">Due Date</div>
                <div className="flex justify-between items-center  border border-[#EBEBF0] rounded-lg p-2 my-2 w-full assignment">
                    <CalendarIcon className="w-5 h-5 text-ooolab_text_username mr-2" />

                    <DatePickerInput
                        labelCls="text-ooolab_text_username flex" 
                        isOriginal={false}
                        onCalendarOpen={() => {
                            isOpeningCalendar.current = true;
                        }}
                        onCalendarClose={() => {
                            isOpeningCalendar.current = false;
                        }}
                        control={control}
                        name="end_date"
                        isRequired
                        paddingCls="py-3"
                        formatDate="yyyy-MM-dd HH:mm"
                        showTimeSelect={true}
                    />
                </div>
            </div>
            {errors.end_date && errors.end_date.type === 'required' && (
                <span className="text-red-500 block font-light text-ooolab_xs">{errors.end_date.message}</span>
            )}
            <div></div>
            <div className="flex gap-6 justify-center items-center mt-5">
                <button
                    onClick={() => onClose()}
                    style={{ display: 'block', borderColor: '#2E3A59' }}
                    className="secondary-button block !text-primary-500 !border-enterprise w-ooolab_w_30 py-ooolab_p_2 text-ooolab_sm rounded"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="w-ooolab_w_30 bg-enterprise text-white py-ooolab_p_2 text-ooolab_sm rounded disabled:bg-ooolab_dark_50"
                >
                    Save
                </button>
            </div>
        </form>
    );
};

export default ModalEditAssignment;
