interface CurrencyType {
    id: number,
    name: string,
    code: string,
    symbol: string,
    decimals: number,
    position: string
}
export const PriceFormat = ({ value, currency, className = '' }: { value: string | number, currency: CurrencyType, className?: string}) => {
    const numberFormatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: currency?.decimals,
        maximumFractionDigits: currency?.decimals
    });
    return <div className={className}> 
        {currency?.position === 'front' && currency?.symbol}
        {numberFormatter.format(+Number(value))}
        {currency?.position !== 'front' && currency?.symbol}
    </div>
}