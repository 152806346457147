import DatePickerInput from 'components/Management/components/Form/DatePicker';
import TimePickerInput from 'components/Management/components/Form/TimePicker';
import { customSelectType, scheduleType } from 'components/Management/Notification/NotificationComponents/ConstantNotications';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select, { OnChangeValue } from 'react-select';

interface PublishScheduleType {
    control: any;
    register: UseFormRegister<any>;
    setValue: UseFormSetValue<any>;
    trigger: UseFormTrigger<any>;
    reportId: any;
    nameFields: string;
    onPublishReport: (reportId: any) => void;
    getValues: UseFormGetValues<any>;
}

const PublishSchedule: React.FC<PublishScheduleType> = ({
    onPublishReport,
    nameFields,
    control,
    register,
    setValue,
    trigger,
    reportId,
    getValues,
}) => {
    const { t: translator } = useTranslation();

    const [scheduleSelected, setScheduleSelected] = useState<number>(-1);

    const handleOnchangeSchedule = async (newValue: OnChangeValue<any, any>, actionMeta: any) => {
        setScheduleSelected(newValue?.value);
        setValue(nameFields, newValue);
        trigger(nameFields);
    };

    const filterPassedTime = (time) => {
        const values = getValues();

        const dateForm = new Date(values.reportDate);
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return dateForm.getDate() === currentDate.getDate() ? currentDate.getTime() < selectedDate.getTime() : selectedDate.getTime();
    };

    return (
        <div className="">
            <div className="grid grid-cols-2 gap-8">
                <div className="col-span-2 text-xs">
                    <label htmlFor="noti-schedule" className="text-ooolab_dark_1 font-semibold leading-8 block">
                        Schedule
                    </label>
                    <Controller
                        control={control}
                        name={nameFields}
                        {...register(nameFields, {
                            required: {
                                value: true,
                                message: translator('FORM_CONST.REQUIRED_FIELD'),
                            },
                        })}
                        render={() => {
                            return (
                                <Select
                                    styles={customSelectType}
                                    className="w-full"
                                    options={scheduleType(translator)}
                                    onChange={handleOnchangeSchedule}
                                    placeholder={'Select'}
                                    menuPlacement="top"
                                />
                            );
                        }}
                    />
                </div>
                {scheduleSelected === 2 && (
                    <>
                        <div className="col-span-1 text-xs ">
                            <label htmlFor="reportDate" className="text-ooolab_dark_1 font-semibold leading-8 block">
                                Date
                            </label>
                            <DatePickerInput
                                control={control}
                                name="reportDate"
                                placeholderText={'Date'}
                                formatDate="MM/dd/yyyy"
                                minDate={new Date()}
                                isRequired
                            />
                        </div>
                        <div className="col-span-1 text-xs ">
                            <label htmlFor="reportTime" className="text-ooolab_dark_1 font-semibold leading-8 block">
                                Time
                            </label>
                            <TimePickerInput control={control} name="reportTime" placeholderText={'Time'} filter={filterPassedTime} />
                        </div>
                    </>
                )}
            </div>
            <div className="flex justify-center my-7">
                <button
                    onClick={() => onPublishReport(reportId)}
                    className={`${
                        scheduleSelected === -1 ? 'bg-[#C7C9D9]' : 'bg-[#2576B9] '
                    } text-xs font-semibold px-3 py-2 rounded  text-white mx-1`}
                    type="button"
                    disabled={scheduleSelected === -1}
                >
                    Publish
                </button>
            </div>
        </div>
    );
};
export default PublishSchedule;
