import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DownloadIcon, ExclamationCircleIcon, PlusIcon } from '@heroicons/react/outline';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import ButtonComponent from 'components/Button/ButtonComponent';
import SearchBox from 'components/V2/SearchBox';
import Pagination from 'components/V2/Pagination';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import { useDebouncedState } from 'hooks/useDebounce';
import { useBoolean } from 'hooks/custom';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import TableCertificates from './TableCertificates';
import certificateService from 'services/certificate.service';
import DialogComponent from 'components/Dialog/DialogComponent';
import { NewCertificateTemplate } from '../NewCertificateTemplate';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import imgCertificateTemplate from 'assets/img/certificate-template-layout.png';
import { ModalConfirmCourseUseCertificateTemplate } from '../components-certificate/ModalConfirmCourseUseCertificateTemplate';

export const CertificateList = ({ children }) => {
    const params: any = useParams();
    const { t: translator } = useTranslation();

    const { booleanValue: modalCreate, toggleBooleanValue: toggleModalCreate } = useBoolean();
    const { booleanValue: confirmDelete, toggleBooleanValue: toggleConfirmDelete } = useBoolean();

    const [keyword, setKeyword] = useDebouncedState('');

    const [loading, setLoading] = useState(false);
    const [indexPage, setIndexPage] = useState('1-50');
    const [certificateList, setCertificateList] = useState([]);
    const [certificateSelected, setCertificateSelected] = useState<any>(null);
    const [certificateUsedCourse, setCertificateUsedCourse] = useState<any[]>([]);

    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'update_at',
        total: 0,
    });

    const workspaceId = params.id;

    const getCertificateList = ({ page = 1, order }: { page?: number | string; order?: 'desc' | 'asc' } = {}) => {
        setLoading(true);
        const payload = {
            ...(keyword ? { name: keyword } : {}),
            page: page || pagination.page,
            per_page: pagination.per_page,
            order: order || pagination.order,
            // sort_by: pagination.sort_by,
        };

        certificateService
            .getCertificatesService(workspaceId, payload)
            .then((res) => {
                const { items, page, per_page, order, total } = res.data;
                setCertificateList(items);
                setPagination({ ...pagination, page, per_page, order, total });
                setIndexPage(getIndexPage(page, per_page, items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const onDeleteCertificate = () => {
        toggleConfirmDelete();
        certificateService
            .deleteCertificateByIdService(workspaceId, certificateSelected?.id)
            .then((res) => {
                setCertificateSelected(null);
                Toastify.success();
                getCertificateList({ page: pagination.page });
            })
            .catch((err) => {
                if (!!err?.response?.data?.courses?.length) {
                    setCertificateUsedCourse(err?.response?.data?.courses);
                } else Toastify.error();
            });
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = imgCertificateTemplate;
        link.download = 'certificate-template-layout.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        getCertificateList({ page: 1 });
    }, [keyword]);

    return (
        <div>
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT')}
                containerClassName=" z-10 sticky top-0"
                pages={[{ name: translator('DASHBOARD.SIDEBAR.CERTIFICATE_TEMPLATE'), href: '', className: '' }]}
            />
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 py-4 px-2 border-b border-gray-300 text-enterprise">
                <SearchBox
                    className="w-full max-w-xs"
                    placeholder={translator('CERTIFICATE_MANAGEMENT.SEARCH_BY_CERTIFICATE_NAME')}
                    onSubmit={(e: any) => setKeyword(e)}
                />
                <div className="lg:flex lg:justify-end space-x-2">
                    {checkPermission('cert', 'DL') && (
                        <ButtonComponent
                            title={translator('CERTIFICATE_MANAGEMENT.TEMPLATE_OUTLINE')}
                            classStyle="text-sm font-semibold !rounded bg-white text-enterprise flex gap-1 justify-center items-center px-3 py-1 border border-enterprise"
                            onClickButton={handleDownload}
                            icon={<DownloadIcon className="w-4 h-4" />}
                        />
                    )}
                    {checkPermission('cert', 'C') && (
                        <ButtonComponent
                            title={translator('NEW')}
                            classStyle="text-sm font-semibold !rounded bg-primary-500 text-white flex gap-1 justify-center items-center px-3 py-1"
                            onClickButton={toggleModalCreate}
                            icon={<PlusIcon className="w-4 h-4" />}
                        />
                    )}
                </div>
            </div>
            <div className="p-4 pb-0">
                <div className="h-[calc(100vh-195px)] custom-scrollbar overflow-y-auto">
                    {loading ? (
                        <div className="h-[calc(100vh-195px)] flex items-center justify-center mt-4">
                            <CircleSpin color="text-primary-500" />
                        </div>
                    ) : (
                        <TableCertificates
                            workspaceId={workspaceId}
                            dataTable={certificateList}
                            order={pagination.order}
                            onOder={() => getCertificateList({ order: pagination.order === 'desc' ? 'asc' : 'desc' })}
                            onEdit={(value) => {}}
                            onDelete={(value) => {
                                setCertificateSelected(value);
                                toggleConfirmDelete();
                            }}
                        />
                    )}
                </div>
                {!!certificateList?.length && (
                    <Pagination
                        callback={(page) => getCertificateList({ page })}
                        total={pagination?.total}
                        page={pagination?.page}
                        per_page={pagination?.per_page}
                        indexPage={indexPage}
                    />
                )}
            </div>
            <DialogComponent
                isOpen={confirmDelete}
                onCloseModal={toggleConfirmDelete}
                title={translator('DELETE')}
                child={
                    <div className="mt-4">
                        <div>{translator('CERTIFICATE_MANAGEMENT.CONFIRM_DELETE_CERTIFICATE')}</div>
                        <div className="mt-5 flex justify-center gap-5">
                            <ButtonOutline type="button" onClick={toggleConfirmDelete}>
                                {translator('CANCEL')}
                            </ButtonOutline>
                            <ButtonPrimary type="button" className="!border-red-500 !bg-red-500" onClick={onDeleteCertificate}>
                                {translator('DELETE')}
                            </ButtonPrimary>
                        </div>
                    </div>
                }
            />
            <DialogComponent
                isOpen={modalCreate}
                title={translator('CERTIFICATE_MANAGEMENT.New_Certificate_Template')}
                onCloseModal={toggleModalCreate}
                maskClosable={false}
                stylesTitle="text-base"
                styles="max-w-[648px]"
                child={
                    <NewCertificateTemplate
                        workspaceId={workspaceId}
                        onClose={toggleModalCreate}
                        onRefreshList={() => {
                            toggleModalCreate();
                            getCertificateList();
                        }}
                    />
                }
            />
            <DialogComponent
                isOpen={!!certificateUsedCourse?.length}
                title={
                    <>
                        <ExclamationCircleIcon className='w-5 h-5 text-orange-500 stroke-[2.5px]' />
                        <span className='ml-3'>{translator('CERTIFICATE_MANAGEMENT.TEMPLATE_IN_USE')}</span>
                    </>
                }
                onCloseModal={() => setCertificateUsedCourse([])}
                maskClosable={false}
                stylesTitle="text-base"
                styles="max-w-[500px]"
                child={
                    <ModalConfirmCourseUseCertificateTemplate
                        data={certificateUsedCourse}
                        onClose={() => setCertificateUsedCourse([])}
                    />
                }
            />
        </div>
    );
};
