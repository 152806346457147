import { getFileKey, hasCheckedStream } from 'constant/file.constant';
import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import uploadService from 'services/upload.service';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { ProgressFiles } from './ProgressFiles';

const VideoPlayer = ({ data, onReady = null, color = "text-white", isPrivate = true }) => {
    let TIME_INTERVAL;
    const { t: translator } = useTranslation();
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const [isStreaming, setIsStream] = useState(true);
    const [pctComplete, setPctComplete] = useState(2);

    useEffect(() => {
        const checkPlayVideo = () => {
            setIsStream(true);
            // Make sure Video.js player is only initialized once
            const url = data?.file || data?.file_url;
            if (hasCheckedStream(url)) {
                setPctComplete(2);
                TIME_INTERVAL = setInterval(async () => {
                    const key = data?.file_key || getFileKey(url);
                    if (!TIME_INTERVAL) {
                        return;
                    }
                    const resStream = await uploadService.getResourceStreamStatus({ key });
                    if (resStream?.data?.ready_to_stream) {
                        clearInterval(TIME_INTERVAL);
                        setTimeout(() => {
                            playVideo();
                        }, 2000);
                    }
                    if (resStream?.data?.status?.pct_complete > 5) {
                        setPctComplete(resStream?.data?.status?.pct_complete);
                    }
                }, 5000);
            } else {
                playVideo();
            }
        }
        const playVideo = () => {
            setIsStream(false);
            const options = {
                autoplay: false,
                controls: true,
                sources: [
                    {
                        src: data?.file || data?.file_url
                    }
                ]
            }
            if (!playerRef.current) {
                const videoElement = videoRef.current;
                if (!videoElement) return;
                const player = (playerRef.current = videojs(videoElement, options, () => {
                    onReady && onReady(player);
                }));

            } else {
                // Update player through Video.js API
                const player = playerRef.current;
                player.src(options.sources);
            }
        }
        if (isPrivate) {
            checkPlayVideo();
        } else {
            setTimeout(() => {
                playVideo();
            }, 2000);
        }

        // Cleanup
        return () => {
            setIsStream(true);
            clearInterval(TIME_INTERVAL);
        };
    }, [data]);

    useEffect(() => {
        return () => {
            if (playerRef.current) {
                playerRef.current.dispose();
                playerRef.current = null;
            }
        };
    }, [])

    return (
        <div className='flex justify-center items-center h-full'>
            {isStreaming ?
                <div className='flex flex-col items-center justify-center min-h-[200px] text-sm'>
                    <ProgressFiles pctComplete={pctComplete} />
                    <p className={`${color} mt-2`}>{translator('Processing_file')}</p>
                </div> : null}
            <div className={`w-full flex justify-center items-center ${isStreaming ? '!hidden' : ''}`}>
                <div data-vjs-player>
                    <video ref={videoRef} className="video-js vjs-big-play-centered min-w-[746px] min-h-[420px]"></video>
                </div>
            </div>
        </div>
    );
};

export default VideoPlayer;