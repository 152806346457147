import { ReactNode } from 'react';
import { classNames } from 'utils/commonFun';

export function BlockInfo({ children, containerClassName }: { children: ReactNode; containerClassName?: string }) {
    return (
        <div
            className={classNames(
                'shadow-ooolab_box_shadow_container rounded-lg relative flex items-center justify-center py-4',
                containerClassName
            )}
        >
            {children}
        </div>
    );
}

export const BlockName = ({ children }: { children: string }) => {
    return <div className=" text-dark-100 text-base font-normal mt-2 text-center">{children}</div>;
};

export const BlockValue = ({ children }: { children: string }) => {
    return <div className=" text-dark-300 text-[28px] font-bold text-center">{children}</div>;
};
