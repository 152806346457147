import { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom'; 
import Table from './Table'
import { UserContext } from 'contexts/User/UserContext';
import ClassService from 'services/class.service';
import { getIndexPage } from 'utils/commonFun';
import Pagination from 'components/V2/Pagination';

const RewardHistory = ({userData}) => {
  const {
    userState: { result },
  } = useContext(UserContext);

  const { t } = useTranslation();
  const params = useParams<{ id: string; userId: string }>();
  const [claimHistoryList, setClaimHistoryList] = useState([])
  const [loading, setLoading] = useState(false);
  const [indexPage, setIndexPage] = useState('1-50');
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 20,
    total: 1
  });

  const getListRewardHistory = (page: number = undefined) => {
    const bodyRequest = {
      ...pagination,
      page: page || pagination?.page,
    };
    setLoading(true);
    ClassService
      .getClaimHistoryListService(params.id, params.userId, bodyRequest)
      .then((res: any) => {
        if (res) {
          const { items, page, total } = res.data;
          setClaimHistoryList(items);
          setPagination({ ...bodyRequest, page, total });
          setIndexPage(getIndexPage(page, pagination.per_page, items?.length));
        }
      })
      .finally(() => setLoading(false))
  };

  useEffect(() => {
    getListRewardHistory();
  }, [pagination.page]);

  const handlePagination = (page) => {
    getListRewardHistory(page);
  }

  return (
    <div className='w-full h-full'>
      <div className='mt-5 h-[calc(100vh-350px)] overflow-auto'>
        <Table
          dataTable={claimHistoryList}
          t={t}
          tz={result.time_zone || 'America/New_York'}
          refreshData={getListRewardHistory}
          userData={userData}
        />
      </div>
      <Pagination
        indexPage={indexPage}
        page={pagination?.page}
        per_page={pagination?.per_page}
        total={pagination.total}
        callback={handlePagination}
      />
    </div>
  )
}

export default RewardHistory


