import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type SessionCardProps = {
    data: {
        id: number;
        start_time: Date;
        end_time: Date;
        lesson: any;
        type: string;
        classTitle: string;
        learning_path?: any;
    };
    handleModal: (isOpen: boolean) => void;
};

const SessionCard: React.FC<SessionCardProps> = ({ data, handleModal }: SessionCardProps) => { 
    const { t: translator } = useTranslation();

    const { start_time, end_time, type, classTitle, learning_path } = data;

    return (
        <div className="flex items-center shadow-ooolab_menu px-3 py-2 rounded-lg space-x-2">
            <div className="w-10 h-10 px-5 flex flex-col items-center justify-center rounded bg-gray-200 text-gray-900">
                <span className="text-sm font-semibold">{moment(start_time).format('DD')}</span>
                <span className="text-[10px] font-normal">{moment(start_time).format('ddd')}</span>
            </div>
            <div className="w-full truncate">
                <h3 className="text-sm font-semibold truncate">{classTitle}</h3>
                <div className="flex justify-between text-ooolab_dark_300 text-xs font-normal truncate">
                    <div className="flex items-center space-x-2 ">
                        <span>
                            {moment(start_time).format('HH:mm')}-{moment(end_time).format('HH:mm')}
                        </span>
                        <span className="mx-3 w-[2px] h-[13px] bg-ooolab_dark_300"></span>
                        <span>{translator(`SELF_BOOKING.${learning_path?.type_ || type}`)}</span>
                    </div>
                    <p className="text-primary-500 font-semibold cursor-pointer" onClick={() => handleModal?.(true)}>
                        {translator('SELF_BOOKING.DETAIL')}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SessionCard;
