import React  from 'react';
import { ColumnWithLooseAccessor, useTable, useRowSelect } from 'react-table';

interface CustomPropsGetter {
    className?: string;
    style?: React.CSSProperties;
}

interface TableProps {
    rowProps?: CustomPropsGetter;
    headerProps?: CustomPropsGetter;
    columns: ColumnWithLooseAccessor[];
    data: any[];
    onClickRow: (e: any) => void;
    rowClass?: (row: any) => string;
    onDoubleClickRow?: (e: any) => void;
    className?: string;
    selectedRemove?: any;
}

const Table: React.FC<TableProps> = ({
    rowProps,
    columns,
    data,
    headerProps,
    onClickRow,
    rowClass,
    onDoubleClickRow,
    className,
}) => {
    
    const { headerGroups, rows, prepareRow } = useTable(
        {
            /*@ts-ignore*/
            columns,
            data,
        },
        useRowSelect
    );

    return (
        <>
            <table className={className || 'w-full h-auto'}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps({
                                ...headerProps,
                            })}
                        >
                            {headerGroup.headers.map((column, idx) => {
                                return (
                                    <th
                                        id={`col-header-${idx}`}
                                        {...column.getHeaderProps()}
                                        scope="col"
                                        className="px-6 py-3 text-left text-sm font-semibold text-ooolab_dark_1"
                                    >
                                        {column.render('Header')}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {rows.map((row, idx) => {
                        prepareRow(row);
                        const tmp: any = { ...row.original };
                        return (
                            <tr
                                onClick={() => {
                                    onClickRow(row.original);
                                }}
                                onDoubleClick={() => {
                                    if (onDoubleClickRow) {
                                        onDoubleClickRow(tmp.id);
                                    }
                                }}
                                {...row.getRowProps({
                                    ...rowProps,
                                    ...(rowClass
                                        ? {
                                              className: rowClass(row.original),
                                          }
                                        : {}),
                                })}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};

export default Table;
