import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useLocation } from 'react-use';
import { ManagementCalendar } from './components/ManagementCalendar';
import { ManagementSession } from './components/ManagementSession';
import './index.scss';

export const SessionsLayout = () => {
    const { t: translator } = useTranslation();
    const params: any = useParams();
    const search = useLocation()?.search;

    const tab = new URLSearchParams(search).get('tab');

    return (
        <div className="sessions-page">
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT')}
                pages={[{ name: translator('SESSIONS_PAGE.Sessions'), href: ``, className: '' }]}
                containerClassName=" z-10"
            />
            <div className="sessions-tabs">
                <Link to={`/workspace/${params?.id}/management/sessions`} className={`${!tab ? 'active' : ''}`}>
                    {translator("SESSION.CALENDAR")}
                </Link>
                <Link
                    to={`/workspace/${params?.id}/management/sessions?tab=sessions-list`}
                    className={`${tab == 'sessions-list' ? 'active' : ''}`}
                >
                    {translator("SESSION.SESSION_LIST")}
                </Link>
            </div>
            <div className="sessions-content">
                <div className="sessions-container">{tab === 'sessions-list' ? <ManagementSession /> : <ManagementCalendar />}</div>
            </div>
        </div>
    );
};
