export const FolderOpen = ({ width = 20, height = 20 }) => {
    return (
        <>
            <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.3335 3.33329C3.11248 3.33329 2.90052 3.42109 2.74424 3.57737C2.58796 3.73365 2.50016 3.94561 2.50016 4.16663V15.8333C2.50016 16.0543 2.58796 16.2663 2.74424 16.4225C2.90052 16.5788 3.11248 16.6666 3.3335 16.6666H14.1668C14.2028 16.6666 14.2388 16.669 14.2745 16.6736C14.4391 16.6951 14.6064 16.6669 14.7548 16.5926C14.8872 16.5264 14.9993 16.4263 15.0799 16.3029L17.131 10.8333H7.23102L5.77389 14.4761C5.60297 14.9034 5.11799 15.1113 4.69067 14.9404C4.26335 14.7694 4.0555 14.2845 4.22643 13.8571L5.8931 9.69047C6.01965 9.37409 6.32608 9.16663 6.66683 9.16663H15.8335V6.66663C15.8335 6.44561 15.7457 6.23365 15.5894 6.07737C15.4331 5.92109 15.2212 5.83329 15.0002 5.83329H9.16683C8.8882 5.83329 8.62801 5.69404 8.47345 5.46221L7.05418 3.33329H3.3335ZM17.5002 9.16663V6.66663C17.5002 6.00358 17.2368 5.3677 16.7679 4.89886C16.2991 4.43002 15.6632 4.16663 15.0002 4.16663H9.61282L8.19354 2.03771C8.03898 1.80588 7.77879 1.66663 7.50016 1.66663H3.3335C2.67045 1.66663 2.03457 1.93002 1.56573 2.39886C1.09689 2.8677 0.833496 3.50358 0.833496 4.16663V15.8333C0.833496 16.4963 1.09689 17.1322 1.56573 17.6011C2.03457 18.0699 2.67045 18.3333 3.3335 18.3333H14.1183C14.5929 18.3837 15.0725 18.2972 15.5002 18.0833C15.9456 17.8606 16.3143 17.51 16.5592 17.0763C16.5804 17.0388 16.5986 16.9996 16.6138 16.9592L19.1138 10.2926C19.2097 10.0366 19.1741 9.74994 19.0184 9.5253C18.8628 9.30065 18.6068 9.16663 18.3335 9.16663H17.5002Z"
                    fill="currentColor"
                />
            </svg>
        </>
    );
};
