import React from 'react';

export default function StatusIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.05674 10C3.05674 7.75634 4.92404 5.9375 7.22746 5.9375H12.7884C14.9474 5.9375 16.7233 7.53542 16.9375 9.58333H18.3332C18.1147 6.78648 15.7157 4.58333 12.7884 4.58333H7.22746C4.15623 4.58333 1.6665 7.00846 1.6665 10C1.6665 12.9915 4.15623 15.4167 7.22746 15.4167H11.9329V14.0625H7.22746C4.92404 14.0625 3.05674 12.2437 3.05674 10ZM5.83736 10C5.83736 9.25215 6.45979 8.64587 7.2276 8.64587C7.99541 8.64587 8.61784 9.25215 8.61784 10C8.61784 10.7479 7.99541 11.3542 7.2276 11.3542C6.45979 11.3542 5.83736 10.7479 5.83736 10ZM7.2276 7.2917C5.69198 7.2917 4.44712 8.50426 4.44712 10C4.44712 11.4958 5.69198 12.7084 7.2276 12.7084C8.76322 12.7084 10.0081 11.4958 10.0081 10C10.0081 8.50426 8.76322 7.2917 7.2276 7.2917Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5427 11.8759C13.8217 11.5969 14.2739 11.5969 14.5529 11.8759L18.1243 15.4473C18.4032 15.7262 18.4032 16.1785 18.1243 16.4575C17.8453 16.7364 17.3931 16.7364 17.1141 16.4575L13.5427 12.886C13.2638 12.6071 13.2638 12.1548 13.5427 11.8759Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.1243 11.8759C18.4032 12.1548 18.4032 12.6071 18.1243 12.886L14.5529 16.4575C14.2739 16.7364 13.8217 16.7364 13.5427 16.4575C13.2638 16.1785 13.2638 15.7262 13.5427 15.4473L17.1141 11.8759C17.3931 11.5969 17.8453 11.5969 18.1243 11.8759Z"
                fill="currentColor"
            />
        </svg>
    );
}
