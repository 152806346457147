import { FC } from 'react';
import ButtonComponent from 'components/Button/ButtonComponent';
import { useTranslation } from 'react-i18next';
import DialogComponent from 'components/Dialog/DialogComponent';
import './Modal.scss'
import { ButtonOutline } from '_shared';

type ModalRevertToDraftProps = {
    onRevertToDraft: () => void;
    isOpen: boolean;
    onCloseModal: any;
};

const ModalRevertToDraft: FC<ModalRevertToDraftProps> = ({
    onRevertToDraft,
    isOpen,
    onCloseModal,
}) => {
    const { t: translator } = useTranslation();

    return (
        <DialogComponent
            isOpen={isOpen}
            title={translator('MODALS.LEARNING_PATH.REVERT_TITLE')}
            onCloseModal={onCloseModal}
            maskClosable={false}
            child={
                <div className='ModalAction'>
                    <div className="ModalAction-content">
                        {translator('MODALS.LEARNING_PATH.REVERT_MSG')}
                    </div>
                    <div className="ModalAction-groupButton">
                        <ButtonOutline
                            onClick={onCloseModal}
                            type={"button"}
                        >
                          {translator('MODALS.CANCEL')}
                        </ButtonOutline>
                        <ButtonComponent
                            title={translator('MODALS.REVERT')}
                            classStyle="primary-button revertBtn"
                            onClickButton={onRevertToDraft}
                        />
                    </div>
                </div>
            }
        />
    );
};

export default ModalRevertToDraft;
