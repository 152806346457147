import { Toastify } from '_shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import uploadService from 'services/upload.service';
import UploadAvartarImg from 'assets/img/uploadAvartar.png';
import workspaceService from 'services/workspace.service';
import { Link, useParams } from 'react-router-dom';
import { CalendarIcon, ClipboardCheckIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { ListOrder } from 'assets/icon';
import { CreditAccountIcon } from 'assets/icon/CreditIcon';
import RewardIcon from 'assets/icon/RewardIcon';
import { LEARNER_PORTAL, USER_ROLE } from '../constant';
import { checkPermission } from 'utils/commonFun';
import TeachingHistoryIcon from 'assets/icon/TeachingHistoryIcon';

export default function LeftMenu({ userData }) {
    const [file, setFile] = useState(undefined);
    const { t } = useTranslation();
    const params: { id; userId } = useParams();

    const handleUpload = (e) => {
        const file = e?.target?.files?.[0];
        if (file) {
            if (['image/png', 'image/jpe', 'image/jpeg'].indexOf(file.type) === -1) {
                return Toastify.error(t('FORM_CONST.FILE_TYPE_IS_NOT_ALLOWED'));
            }
            if (+(file?.size / 1024).toFixed(2) > 1024 * 100) {
                return (Toastify.error = t('FORM_CONST.FILE_SIZE_LESS_THAN', { size: '100MB' }));
            }
            setFile(e?.target?.files?.[0]);
            uploadService.uploadFile(
                e?.target?.files?.[0],
                (result) => {
                    workspaceService
                        .updateWorkspacePartialMember(params?.id, params?.userId, {
                            avatar_url: process.env.REACT_APP_HOST_CDN + result,
                        })
                        .then((res) => {
                            Toastify.success();
                        })
                        .catch(() => Toastify.error());
                },
                () => { }
            );
        }
    };

    return (
        <div className="px-5">
            <div className="py-4  border-b border-gray-300">
                <div
                    onClick={(e) => {
                        // fileInputRef.current.click();
                    }}
                    className="w-40 h-40 bg-gray-100 rounded-full mx-auto relative group cursor-pointer"
                >
                    {(file || userData?.avatar_url) && (
                        <img
                            className="w-40 h-40 object-cover bg-gray-100 rounded-full mx-auto"
                            src={file ? URL.createObjectURL(file) : userData?.avatar_url}
                            alt="avatar"
                        />
                    )}
                    <img
                        className={`m-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${file || userData?.avatar_url ? 'hidden group-hover:block' : 'block'
                            }`}
                        src={UploadAvartarImg}
                        alt="avatar-upload"
                    />
                    <input
                        accept={'image/png, image/jpe, image/jpeg, application/pdf'}
                        // ref={fileInputRef}
                        onChange={handleUpload}
                        className="hidden"
                        type="file"
                        placeholder={''}
                    />
                    <div className="absolute whitespace-nowrap flex -bottom-2 w-fit left-1/2 -translate-x-1/2 px-3 py-2 text-xs font-semibold text-primary-500 rounded-lg bg-primary-50 capitalize">
                        {userData?.membership?.type === 'student' && LEARNER_PORTAL.learner}
                        {userData?.membership?.type === 'parent' && LEARNER_PORTAL.parent}
                        {userData?.membership?.type !== 'student' &&
                            userData?.membership?.type !== 'parent' &&
                            (USER_ROLE[userData?.membership?.user_role?.name] || userData?.membership?.user_role?.name)}
                    </div>
                </div>
            </div>

            <div className="py-5 overflow-auto">
                {checkPermission('userDetailsInfomation', 'R') && <div className="courseSideLeft-item">
                    <InformationCircleIcon className="w-5 h-5 text-gray-500" />
                    <div className="courseSideLeft-item-content">
                        <div className="courseSideLeft-item-name">{t('USER.LEFT_MENU.INFORMATION')}</div>
                        <Link
                            to={`/workspace/${params.id}/management/users/${params.userId}?tab=information`}
                            className="courseSideLeft-item-link"
                        >
                            {t('USER.LEFT_MENU.SET_UP_DEATIL_INFORMATION')}
                        </Link>
                    </div>
                </div>
                }
                {userData?.membership?.type === 'instructor' && (
                    <div className="courseSideLeft-item">
                        <TeachingHistoryIcon className="w-5 h-5 text-gray-500" />
                        <div className="courseSideLeft-item-content">
                            <div className="courseSideLeft-item-name">{t('USER.LEFT_MENU.TEACHING_HISTORY')}</div>
                            <Link
                                to={`/workspace/${params.id}/management/users/${params.userId}?tab=teaching-history`}
                                className="courseSideLeft-item-link"
                            >
                                {t('USER.LEFT_MENU.VIEW_TEACHING_HISTORY_DEATIL')}
                            </Link>
                        </div>
                    </div>
                )
                }
                {userData?.membership?.type === 'student' && (
                    <>
                        {checkPermission('userDetailsEnrollment', 'R') && <div className="courseSideLeft-item">
                            <ListOrder className="w-5 h-5 text-gray-500" />
                            <div className="courseSideLeft-item-content">
                                <div className="courseSideLeft-item-name">{t('USER.LEFT_MENU.ENROLLMENT')}</div>
                                <Link
                                    to={`/workspace/${params.id}/management/users/${params.userId}?tab=enrollment`}
                                    className="courseSideLeft-item-link"
                                >
                                    {t('USER.LEFT_MENU.VIEW_ENROLLMENT_LIST')}
                                </Link>
                            </div>
                        </div>}
                        {checkPermission('userDetailsSchedule', 'R') && <div className="courseSideLeft-item">
                            <CalendarIcon className="w-5 h-5 text-gray-500" />
                            <div className="courseSideLeft-item-content">
                                <div className="courseSideLeft-item-name">{t('USER.LEFT_MENU.SCHEDULE')}</div>
                                <Link
                                    to={`/workspace/${params.id}/management/users/${params.userId}?tab=schedule`}
                                    className="courseSideLeft-item-link"
                                >
                                    {t('USER.LEFT_MENU.VIEW_SCHEDULE_DETAIL')}
                                </Link>
                            </div>
                        </div>}
                        {checkPermission('userDetailsCreditAccounts', 'R') && <div className="courseSideLeft-item">
                            <CreditAccountIcon className="w-5 h-5 text-gray-500" />
                            <div className="courseSideLeft-item-content">
                                <div className="courseSideLeft-item-name">{t('USER.LEFT_MENU.CREDIT_ACCOUNTS')}</div>
                                <Link
                                    to={`/workspace/${params.id}/management/users/${params.userId}?tab=credit-accounts`}
                                    className="courseSideLeft-item-link"
                                >
                                    {t('USER.LEFT_MENU.MANAGE_CREDIT_ACCOUNTS')}
                                </Link>
                            </div>
                        </div>}
                        {checkPermission('userDetailsProgressHistory', 'R') && <div className="courseSideLeft-item">
                            <ClipboardCheckIcon className="w-5 h-5 text-gray-500" />
                            <div className="courseSideLeft-item-content">
                                <div className="courseSideLeft-item-name">{t('USER.LEFT_MENU.PROGGESS_HISTORY')}</div>
                                <div className='flex flex-col gap-2 mt-2'>
                                    <Link
                                        to={`/workspace/${params.id}/management/users/${params.userId}?tab=gradebook`}
                                        className="courseSideLeft-item-link"
                                    >
                                        {t('USER.LEFT_MENU.VIEW_PROGRESS_HISTORY')}
                                    </Link>
                                    {checkPermission('user', 'R') &&
                                        <Link
                                            to={`/workspace/${params.id}/management/users/${params.userId}?tab=reports`}
                                            className="courseSideLeft-item-link"
                                        >
                                            {t('USER.LEFT_MENU.VIEW_REPORTS')}
                                        </Link>
                                    }
                                </div>
                            </div>
                        </div>}
                        {checkPermission('userDetailsAchievements', 'R') && <div className="courseSideLeft-item">
                            <RewardIcon className="w-5 h-5 text-gray-500" />
                            <div className="courseSideLeft-item-content">
                                <div className="courseSideLeft-item-name">{t('USER.LEFT_MENU.ACHIEVEMENTS')}</div>
                                <Link
                                    to={`/workspace/${params.id}/management/users/${params.userId}?tab=achievements`}
                                    className="courseSideLeft-item-link"
                                >
                                    {t('USER.LEFT_MENU.VIEW_ACHIEVEMENT_HISTORY')}
                                </Link>
                            </div>
                        </div>}

                    </>
                )}
            </div>
        </div>
    );
}
