import React from 'react'
import UndoIcon from './UndoIcon'

export default function StatusCheckIcon({className = 'w-4 h-4 text-white'}) {
  return (
    <svg className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6349_37004)">
      <rect width="20" height="20" rx="4" fill="#0071CE"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.33331 10C3.33331 9.53978 3.70641 9.16669 4.16665 9.16669H15.8333C16.2936 9.16669 16.6666 9.53978 16.6666 10C16.6666 10.4603 16.2936 10.8334 15.8333 10.8334H4.16665C3.70641 10.8334 3.33331 10.4603 3.33331 10Z" fill="currentColor"/>
      </g>
      <defs>
      <clipPath id="clip0_6349_37004">
      <rect width="20" height="20" rx="4" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}
