import { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import './SessionFilter.scss';
import { LearnerAndGroupSearch } from '_shared/components/LearnerAndGroupSearch/LearnerAndGroupSearch';

interface IFilter {
    onChangeFilter: (value: any) => void;
    isFirstLoad: boolean;
}

export const SessionFilter = ({
    onChangeFilter,
    isFirstLoad,
}: IFilter) => {
    const [learingGroup, setLearingGroup] = useState<any>();

    const updateFilter = useCallback(
        debounce((listFilter) => onChangeFilter(listFilter), 1500),
        []
    );

    useEffect(() => {
        if (!isFirstLoad) {
            let listFilter: any = {};
            if (learingGroup) {
                listFilter = {
                    ...listFilter,
                    ...learingGroup
                }
            };
            if (!listFilter?.learner_ids || listFilter?.learner_ids == '') delete listFilter?.learner_ids;
            if (!listFilter?.learning_group_ids || listFilter?.learning_group_ids == '') delete listFilter?.learning_group_ids;
            updateFilter(listFilter);
        }
    }, [ learingGroup]);

    return (
        <LearnerAndGroupSearch onChangeFilter={setLearingGroup} />
    );
};
