import React from 'react';
import CoursesList from 'components/Workspace/Course/CoursesList';
import RouteMasterPage from 'pages/RouteMasterPage';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';

const CoursesPage = () => {
    return (
        <ManagePermissionWrapper>
            <CoursesList>
                <RouteMasterPage />
            </CoursesList>
        </ManagePermissionWrapper>
    );
};

export default CoursesPage;
