import InstructorPortal from 'components/Dashboard/InstructorPortal';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';
import React from 'react';

const InstructorPortalPage = () => {
    return (
        <ManagePermissionWrapper>
            <InstructorPortal />
        </ManagePermissionWrapper>
    )
};

export default InstructorPortalPage;
