import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import AvatarList from 'components/V2/AvatarList';
import Table, { RenderTableHeader, RenderTableCell, LinkCustom } from 'components/V2/Table/TableCustom';
import SessionDetailsModal from 'components/V2/SessionDetailModal';
import EmptyImg from 'assets/img/empty-state/classes.png';

interface TableClassProps {
    data: any[];
    fetchData: () => void
}

const TableGroups: React.FC<TableClassProps> = ({ data, fetchData }) => {
    const { t: translator } = useTranslation();
    const [isOpenModalDetail, setOpenModalDetail] = useState<boolean>(false)
    const [sesionId, settSessionId] = useState<string>()
    const [classSelected, setClassSelected] = useState<any>({})

    const params: any = useParams()

    const columns: Column<any>[] = React.useMemo(
        () => [
            {
                Header: () => (
                    <div className="flex items-center justify-between border-ooolab_bar_color">
                        <RenderTableHeader value={translator('CLASSES.CLASS_ID')} />
                    </div>
                ),
                accessor: 'id',
                Cell: ({ row }: { row: Row }) => (
                    <LinkCustom
                        to={`/workspace/${params.id}/management/class/${row.values?.id}`}
                        className="text-primary-500 font-semibold cursor-pointer"
                        value={row.values?.id}
                    />
                ),

                width: 80,
            },
            {
                Header: () => <RenderTableHeader value={translator('CLASSES.CLASS_NAME')} />,
                accessor: 'name',
                Cell: ({ row }: { row: Row }) => {
                    return <RenderTableCell value={row.values?.name} />;
                },
            },
            {
                Header: () => <RenderTableHeader value={translator('CLASSES.STUDENTS')} />,
                accessor: 'students',
                Cell: ({ row }: { row: any }) => {
                    return <AvatarList list={row?.original?.students || []} />;
                },
            },
            {
                Header: () => <RenderTableHeader value={translator('CLASSES.INSTRUCTORS')} />,
                accessor: 'instructors',
                Cell: ({ row }: { row: any }) => {
                    return <AvatarList list={row?.original?.instructors || []} />;
                },
            },
            {
                Header: () => <RenderTableHeader value={translator('CLASSES.LAST_SESSION')} />,
                accessor: 'last_session',
                Cell: ({ row }: any) => {
                    return (
                        <RenderTableCell 
                            value={row?.values?.last_session?.id ? "Session " + row?.values?.last_session?.id : '-'}
                            className="!text-primary-500 !font-semibold cursor-pointer" 
                            disable={!row?.values?.last_session?.id}
                            onClick={() => {
                                settSessionId(row?.values?.last_session?.id); 
                                setOpenModalDetail(true);
                                setClassSelected(row?.original)
                            }}
                        />
                    );
                },
            },
            {
                Header: () => <RenderTableHeader value={translator('CLASSES.NEXT_SESSION')} />,
                accessor: 'next_session',
                Cell: ({ row }: any) => {
                    return (
                        <RenderTableCell 
                            value={row?.values?.next_session?.id ? "Session " + row?.values?.next_session?.id : '-'}
                            className="!text-primary-500 !font-semibold cursor-pointer" 
                            disable={!row?.values?.next_session?.id}
                            onClick={() => {
                                settSessionId(row?.values?.next_session?.id); 
                                setOpenModalDetail(true);
                                setClassSelected(row?.original)
                            }}
                        />
                    );
                },
            },
            {
                Header: () => <RenderTableHeader value={translator('CLASSES.CLASS_TYPE_LIST')} />,
                accessor: 'class_type',
                Cell: ({ row }: { row: any }) => {
                    return <RenderTableCell className='capitalize' value={row.original?.class_type} />
                },
            }
        ],
        []
    );

    return (
        <>
            <Table data={data} columns={columns} emptyContent={translator("CLASSES.NO_CLASSES")} emptyIcon={EmptyImg}/>
            {
                isOpenModalDetail && 
                    <SessionDetailsModal 
                        sessionId={sesionId}
                        classId={classSelected?.id}
                        title={classSelected?.name}
                        open={isOpenModalDetail}
                        onCloseModal={() => {
                            settSessionId('')
                            setOpenModalDetail(false)
                        }}
                    />
            }
        </>
    )
};

export default TableGroups;
