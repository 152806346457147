import { PlusIcon } from '@heroicons/react/outline';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useBoolean } from 'hooks/custom';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { IGetClassParams } from 'types/Class.type';
import CreateClassForm from './CreateClassForm';
import TableGroups from './TableClass';
import { initClasslistState } from './utils';
import { useTranslation } from 'react-i18next';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import ButtonComponent from 'components/Button/ButtonComponent';
import Pagination from 'components/V2/Pagination';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import DialogComponent from 'components/Dialog/DialogComponent';

const ClassList: React.FC = ({ children }) => {
    const { t: translator } = useTranslation();

    const params: { id: string } = useParams();
    const { booleanValue: modalCreate, toggleBooleanValue: toggleModalCreate } = useBoolean();

    const [shouldWarningBeforeClose, setShouldWarningBeforeClose] = useState(false);

    const [classParams, setClassParams] = useState<IGetClassParams>(initClasslistState);
    const [keyword, setKeyword] = useDebouncedState('');

    const { dispatch, getWorkspaceDetailState } = useContext(GetWorkspaceContext);

    const { class: classList, result } = getWorkspaceDetailState;
    const {
        membership: { role },
    } = result;

    const {
        list: { items, page, per_page, total, isLoading },
    } = classList;

    const getClassList = () => {
        workspaceMiddleware.getClassList(dispatch, { workspaceId: params.id, classParams :{...classParams, q: keyword}});
    }

    useEffect(() => {
        getClassList()
    }, [classParams, keyword]);

    const handleChangePagination = (e: number) =>
        setClassParams((prev) => ({
            ...prev,
            page: e,
        }));

    const handleCreateClassSuccess = () => {
        if (page === 1) {
            setTimeout(() => {
                getClassList()
                toggleModalCreate();
            }, 500);
        }
        setShouldWarningBeforeClose(false);
    };

    return (
        <div className="ClassList">
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT')}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    { name: translator('DASHBOARD.SIDEBAR.CLASSES'), href: '', className: '' },
                ]}
            />
            <div className="flex items-center justify-between px-2 py-4">
                <SearchBox className="max-w w-full max-w-xs" onSubmit={(e: any) => setKeyword(e)} />
                <div className="">
                    {role === 'admin' && (
                        <ButtonComponent 
                            classStyle='primary-button !bg-enterprise' 
                            onClickButton={toggleModalCreate}
                            icon={<PlusIcon className="w-5 h-5 mr-1" />}
                            title={translator('CLASSES.ADD_CLASS')}
                        />
                    )}
                </div>
            </div>
            <div className="w-full px-2">
                <div className="h-[calc(100vh-179px)] custom-scrollbar overflow-y-auto">
                    {isLoading ? 
                        <div className="flex items-center justify-center mt-4">
                            <CircleSpin color="text-primary-500" />
                        </div>
                        :
                        <TableGroups fetchData={getClassList} data={items} />
                    }
                </div>

                <Pagination
                    indexPage={`${page * per_page - per_page + 1}-${items.length + per_page * (page - 1)}`}
                    total={total}
                    per_page={per_page}
                    page={page}
                    callback={handleChangePagination}
                />
            </div>
            
            <DialogComponent 
                isOpen={modalCreate}
                onCloseModal={() => {
                    toggleModalCreate();
                    setShouldWarningBeforeClose(false);
                }}
                styles="w-[50%]"
                title={translator('CLASSES.ADD_CLASS')}
                stylesTitle="text-base"
                child={
                    <CreateClassForm
                        onSuccessCreate={() => {
                            handleCreateClassSuccess();
                        }}
                        closeModal={toggleModalCreate}
                        callbackWhenDirty={setShouldWarningBeforeClose}
                    />
                }
            />
        </div>
    );
};

export default ClassList;
