import React, { useState } from 'react';
import { Switch } from '@headlessui/react';
import { Controller } from 'react-hook-form';

type SwitchToggleProps = {
    name: string;
    control;
    disabled?: boolean;
    onClick?: () => void;
    enableColor?: boolean;
    checked?: boolean;
    onChange?: (value?: boolean) => void;
};

export const SwitchToggle = ({
    name,
    control,
    disabled,
    onClick,
    enableColor = false,
    checked = undefined,
    onChange,
}: SwitchToggleProps) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { ref, ...field } }) => {
                const checkedField = checked !== undefined ? checked : field.value;

                return (
                    <Switch
                        checked={checkedField}
                        disabled={disabled}
                        onChange={(value) => {
                            field.onChange(value);
                            onChange?.(value);
                        }}
                        onClick={onClick}
                        className={`${
                            checkedField ? 'bg-primary-500' : 'bg-gray-200'
                        } relative inline-flex h-6 w-11 items-center rounded-full ${disabled && !enableColor ? '!bg-gray-200' : ''}`}
                    >
                        <span className="sr-only">Enable notifications</span>
                        <span
                            className={`${
                                checkedField ? 'translate-x-6' : 'translate-x-1'
                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                        />
                    </Switch>
                );
            }}
        />
    );
};
