import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { filterClass, filterInstructors } from '../SelfBookingContants/SelfBookingContants';

const ItemsFilterRender = ({ value = '', active = false }: { value: string; active: boolean }) => {
    return (
        <div
            className={`rounded-md text-base font-medium px-2 py-1 ${
                active ? 'text-primary-500 bg-primary-50' : 'bg-white text-dark-300 border border-secondary-custom'
            }`}
        >
            {value}
        </div>
    );
};

type SelfBookingFilterProps = {
    setFiltered: React.Dispatch<React.SetStateAction<boolean>>;
    onCancel: () => void;
    activeClassFilter: any;
    activeInstrunstorFilter: any;
    setActiveClassFilter: React.Dispatch<React.SetStateAction<any>>;
    setActiveInstrunstorFilter: React.Dispatch<React.SetStateAction<any>>;
    onFilter: Function;
};

const SelfBookingFilter: React.FC<SelfBookingFilterProps> = ({
    setFiltered,
    onCancel,
    activeClassFilter,
    activeInstrunstorFilter,
    setActiveClassFilter,
    setActiveInstrunstorFilter,
    onFilter,
}: SelfBookingFilterProps) => {
    const { t } = useTranslation();
    const translator = (str: string) => t(`SELF_BOOKING.${str}`);

    const [selectedClassFilter, setSelectedClassFilter] = useState(activeClassFilter);
    const [selectedInstructor, setSelectedInstructor] = useState(activeInstrunstorFilter);

    const onFiltered = () => {
        if (selectedClassFilter === 0 && selectedInstructor === 0) {
            setFiltered(false);
        } else setFiltered(true);
        onCancel();
    };

    const onConfirm = () => {
        onFilter({
            prefer: selectedInstructor.value,
            type: selectedClassFilter.value,
        });
        setActiveInstrunstorFilter(selectedInstructor);
        setActiveClassFilter(selectedClassFilter);
        onFiltered();
    };

    return (
        <div className="max-h-[320px] pt-6">
            <div>
                <p className="text-xs font-medium text-dark-300">{translator('INSTRUCTOR_PREFERENCE')}</p>
                <div className="grid grid-cols-2 gap-4 py-2">
                    {filterInstructors.map((instructor, index) => {
                        return (
                            <div key={index} onClick={() => setSelectedInstructor(instructor)} className="cursor-pointer text-center">
                                <ItemsFilterRender
                                    value={translator(instructor.label)}
                                    active={selectedInstructor.value === instructor.value}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="flex gap-6 justify-center mt-6">
                <button
                    type="button"
                    className="rounded-ooolab_radius_20px text-primary-500 text-base font-medium border border-primary-500 py-3 w-full"
                    onClick={onCancel}
                >
                    {translator('CANCEL')}
                </button>
                <button
                    type="button"
                    className="rounded-ooolab_radius_20px bg-primary-500 text-base font-medium py-3 text-white w-full"
                    onClick={onConfirm}
                >
                    {translator('CONFIRM')}
                </button>
            </div>
        </div>
    );
};

export default SelfBookingFilter;
