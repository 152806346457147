import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { XIcon } from '@heroicons/react/outline';
import Select, { StylesConfig } from 'react-select';
import { classNames } from 'utils/commonFun';
import ButtonComponent from 'components/Button/ButtonComponent';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useForm, useFieldArray } from 'react-hook-form';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import Spinner from 'components/Spinner';
import TeachService from 'services/teach.services';
import Swal from 'sweetalert2';
import confirm_resetPNG from 'assets/confirm_reset.png';
import ErrorMessageForm from 'components/ErrorMessageForm';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { setEmptyOrStr } from 'constant/util.const';
import PedCard from './Pedcards';
import { get } from 'lodash';
import { optionGoldAchieved } from './constant';
import { ButtonPrimary, Toastify } from '_shared';
import courseService from 'services/course.service';
import { VALIDATE_FIELD } from 'utils/validate';

const customSelectStyle: StylesConfig = {
    indicatorSeparator: (base) => ({ ...base, display: 'none' }),
    control: (styles: any) => {
        return {
            ...styles,
            minHeight: 34,
            maxHeight: 34,
            height: 34,
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #9ca3af33',
            borderRadius: 4,
            outline: 'none',
            ":hover" : "none",
            boxShadow: "none" 
        };
    },
    valueContainer: (styles) => {
        return {
            ...styles,
            paddingBottom: 6,
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
        };
    },
};

interface GradeProps {}

const Grade: React.FC<GradeProps> = ({}) => {
    const location: any = useLocation();
    const params: any = useParams();
    const history = useHistory();
    const [enabled, setEnabled] = React.useState(true);
    const [isPresent, setIsPresent] = React.useState(false);
    const [isReset, setIsReset] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState(false);
    const [name, setName] = React.useState('');
    const { t } = useTranslation();
    const { getWorkspaceDetailState } = React.useContext(GetWorkspaceContext);
    const [isSubmit, setSubmit] = useState<boolean>(false);
    const [courseSetting, setCourseSetting] = useState<any>();

    const dataAttendance = [
        {
            id: 1,
            value: 'present',
            label: t('EVALUATE.PRESENT'),
        },
        {
            id: 2,
            value: 'late',
            label: t('EVALUATE.LATE'),
        },
        {
            id: 3,
            value: 'absent',
            label: t('EVALUATE.ABSENT'),
        },
    ];

    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isDirty },
        setValue,
        getValues,
        watch,
        clearErrors,
    } = useForm({
        defaultValues: {
            student_attendances: [],
        },
    });
    const { fields, replace } = useFieldArray({
        control,
        name: 'student_attendances',
    });

    const { id: workspaceId, sessionId, classId } = params;

    const { detail } = getWorkspaceDetailState.class;

    React.useEffect(() => {
        getListAttendance();
        const getCourseSettings = async () => {
            const response =  await courseService.getCourseSettings({workspaceId: workspaceId, id: classId});
            if (response?.data) {
                setCourseSetting(response?.data);
            }
        };
        getCourseSettings();
    }, []);

    React.useEffect(() => {
        if (location?.state?.nameclass) setName(location?.state?.nameclass);
    }, [location]);

    React.useEffect(() => {
        if (!!!location?.state?.nameclass) setName(detail?.name || '');
    }, [detail]);

    const getListAttendance = () => {
        setLoading(true);
        TeachService.getListAttendanceService({
            workspaceId,
            sessionId,
            classId,
        })
            .then((res) => {
                const student_attendances = res?.data?.items;
                replace(student_attendances);
            })
            .finally(() => setLoading(false));
    };

    const updateListAttendance = (data: any) => {
        setLoading(true);
        TeachService.updateListAttendanceService(
            {
                workspaceId,
                sessionId,
                classId,
            },
            data
        )
            .then((res) => {
                getListAttendance();
                Toastify.success();
            })
            .catch((err: any) => {
                if (get(err, 'response.data.error.name') === 'nothing_changed') return;
                const error =
                    t('EVALUATE.UPDATE_GRADE_ERROR');
                Toastify.error(error);
            })
            .finally(() => {
                setLoading(false);
                setSubmit(true);
            });
    };

    const checkAttendance = (index: number) => {
        return watch(`student_attendances.${index}.attendance_type`) === 'absent';
    };

    const handleAllPresent = () => {
        setValue(
            'student_attendances',
            getValues('student_attendances').map((item) => ({
                ...item,
                attendance_type: 'present',
                rewards_coin: item?.rewards_coin || undefined
            }))
        );
        setIsPresent(true);
    };

    const handleMaxGoalAchieved = () => {
        setValue(
            'student_attendances',
            getValues('student_attendances').map((item) => ({
                ...item,
                goal_achieved: 2,
                rewards_coin: item?.rewards_coin || undefined

            }))
        );
    }

    const handleAllMaxGrade = () => {
        setValue(
            'student_attendances',
            getValues('student_attendances').map((item) => ({
                ...item,
                grade: 100,
                rewards_coin: item?.rewards_coin || undefined

            }))
        );
    };

    const handleAllMaxPoint = () => {
        if (!!errors?.student_attendances?.length) {
            getValues('student_attendances')?.map((el, index) => {
                clearErrors(`student_attendances.${index}.rewards_coin`);
            });
        }
        setValue(
            'student_attendances',
            getValues('student_attendances').map((item) => ({
                ...item,
                rewards_coin: item.attendance_type !== 'absent' ? 5 : null,
            })),
            {shouldDirty: true}
        );
    };


    const getDefaultAttendance = (value: string) => {
        if (value) {
            return dataAttendance.find((i) => i.value === value);
        } else if (isPresent) {
            return {
                id: 1,
                value: 'present',
                label: t('EVALUATE.PRESENT'),
            };
        }
        return null;
    };

    const onReset = () => {
        Swal.fire({
            title: '<p>Confirm Reset</p>',
            iconHtml: `<img src="${confirm_resetPNG}" />`,
            html: '<p>Are you sure you want to reset this action?</p>',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: true,
            cancelButtonText: 'Yes, do it!',
            confirmButtonText: 'No, cancel',
            customClass: {
                title: 'swale2-title-popupconfirm',
                popup: 'z-9999 swale2-popup-popupconfirm',
                icon: 'swale2-icon-popupconfirm',
                confirmButton: 'swale2-cancelButton-popupconfirm',
                cancelButton: 'swale2-confirmButton-popupconfirm',
                container: 'swale2-container-popupconfirm',
                htmlContainer: 'swale2-htmlContainer-popupconfirm',
            },
        }).then((result) => {
            if (result.isDismissed) {
                setIsReset(true);
                getListAttendance();
            }
        });
    };

    const onSubmitForm = (values: any) => {
        setSubmit(true);
        updateListAttendance({
            student_attendances: values.student_attendances?.map((el) =>  {
                let payload: any = {
                    id: el.id,
                    instructor_comment: el.instructor_comment || '',
                    student_id: el?.user.id
                }
                if (courseSetting?.allow_instructor_grading) {
                    payload = {
                        ...payload,
                        attendance_type: el.attendance_type || null,
                        goal_achieved : el.goal_achieved || null,
                        rewards_coin: el.rewards_coin ? el.rewards_coin :  null                    
                    }
                }
                return payload;
            }),
        });
    };

    return (
        <div className="pb-8 bg-gray-50">
            <form className="h-auto w-full relative" onSubmit={handleSubmit(onSubmitForm)}>
                <div className="flex bg-white sticky z-1 top-0 justify-between items-center p-3 lg:px-5 py-3 shadow-ooolab_menu ">
                    <div></div>
                    <h1 className="text-gray-800 font-semibold text-lg absolute left-1/2 -translate-x-1/2">{t("EVALUATE.EVALUATE_SESSION")}</h1>
                    <div className="space-x-2 lg:space-x-4 flex items-center">
                        <button type="button" className='text-primary-500 font-semibold' onClick={onReset}>
                            {t('RESET_ALL')}
                        </button>
                        <div className='w-[1px] h-7 bg-gray-300'></div>
                        <ButtonPrimary
                            type="submit"
                            className='!h-8'
                        >
                            {t('CONFIRM')}
                        </ButtonPrimary>
                        
                        <button
                            title="icon"
                            type="button"
                            className={classNames('border rounded-full p-2')}
                            onClick={() =>
                                history.push(`/workspace/${workspaceId}/instructor-dashboard/instructor-schedule?tab=SESSION_LIST`, {
                                    tabSelected: location?.state?.tabSelect,
                                })
                            }
                        >
                            <XIcon className="w-5 h-5" />
                        </button>
                    </div>
                </div>

                <PedCard workspaceId={workspaceId} sessionId={sessionId} classId={classId} isSubmit={isSubmit} setSubmit={setSubmit} isReset={isReset} setIsReset={setIsReset}/>
                
                <div className="rounded-lg mx-5 overflow-x-auto bg-white p-5 mt-5">
                        <div className="font-semibold text-lg w-full">{t('EVALUATE.EVALUATE')}</div>
                        <div className="h-full w-full min-h-[calc(100vh-430px)]">
                            <div className={classNames('grid grid-cols-11', enabled ? 'gap-3' : 'gap-10')}>
                                <div className="col-span-2"></div>
                                {
                                    courseSetting?.allow_instructor_grading && 
                                        <>
                                            <div className="col-span-2 text-dark-300">
                                                <p className="text-sm font-semibold mb-3">{t('EVALUATE.ATTENDANCE')}</p>
                                                <ButtonComponent
                                                    title={t('EVALUATE.ALL_PRESENT')}
                                                    classStyle={classNames('bg-primary-500 text-base px-3 py-1 text-white !bg-enterprise')}
                                                    onClickButton={handleAllPresent}
                                                />
                                            </div>
                                            <div className="col-span-2 text-dark-300">
                                                <p className="text-sm font-semibold mb-3">{t('EVALUATE.GOAL_ACHIEVED')}</p>
                                                <ButtonComponent
                                                    title={t('EVALUATE.ALL_ACHIEVEMENT')}
                                                    classStyle={classNames('bg-primary-500 text-base px-2 py-1 text-white !bg-enterprise')}
                                                    onClickButton={handleMaxGoalAchieved}
                                                />
                                            </div>
                                            <div className="col-span-2 text-dark-300">
                                                <p className="text-sm font-semibold mb-3">{t('EVALUATE.REWARD_COINS')}</p>
                                            </div>
                                          
                                        </> 
                                }
                                <div className="col-span-2 text-dark-300">
                                    <p className="text-sm font-semibold">{t('EVALUATE.INSTRUCTOR_COMMENTS')}</p>
                                </div>
                                
                            </div>
                            {!loading && <div className="space-y-3 mt-4 pb-6">
                                {fields?.map((item, index) => (
                                    <div key={item.id} className={classNames('grid grid-cols-11', enabled ? 'gap-5' : 'gap-10')}>
                                        <div className="col-span-2 flex items-center space-x-2">
                                            <img src={item?.user?.avatar_url} alt="img" className="w-6 h-6 rounded-full" />
                                            <p className="font-semibold text-sm">
                                                {item?.user?.display_name || 'null'}
                                                {item?.user?.nick_name && ` / ${item?.user?.nick_name}`}
                                            </p>
                                        </div>
                                        
                                        {courseSetting?.allow_instructor_grading ?<><div className="col-span-2">
                                            {/* @ts-ignore */}
                                            <Select
                                                isSearchable={false}
                                                styles={customSelectStyle}
                                                placeholder={t('EVALUATE.SELECT')}
                                                options={dataAttendance}
                                                menuPlacement="auto"
                                                defaultValue={getDefaultAttendance(item.attendance_type)}
                                                name={`student_attendances.${index}.attendance_type`}
                                                {...register(`student_attendances.${index}.attendance_type`, {
                                                    required: {
                                                        value: true,
                                                        message: t('FORM_CONST.REQUIRED_FIELD'),
                                                    },
                                                })}
                                                className="!border-ooolab_gray_6"
                                                onChange={(item: any) => {
                                                    setValue(`student_attendances.${index}.attendance_type`, item.value);
                                                    clearErrors(`student_attendances.${index}.attendance_type`);
                                                    if (item.value !== 'present') setIsPresent(false);
                                                    if (checkAttendance(index)) {
                                                        clearErrors(`student_attendances.${index}.rewards_coin`);
                                                        setValue(`student_attendances.${index}.rewards_coin`, undefined);
                                                        let arr = [...getValues('student_attendances')];
                                                        arr[index] = {
                                                            ...arr[index],
                                                            attendance_type: 'absent'
                                                        };
                                                        replace(arr);
                                                    }
                                                }}
                                            />
                                            <ErrorMessageForm errors={errors} name={`student_attendances.${index}.attendance_type`} />
                                        </div> 
                                        <div className="col-span-2">
                                            {/* @ts-ignore */}
                                            <Select
                                                isSearchable={false}
                                                styles={customSelectStyle}
                                                placeholder={t('EVALUATE.SELECT')}
                                                options={optionGoldAchieved}
                                                menuPlacement="auto"
                                                defaultValue={optionGoldAchieved.find((el) => el.value === item.goal_achieved)}
                                                name={`student_attendances.${index}.goal_achieved`}
                                                {...register(`student_attendances.${index}.goal_achieved`)}
                                                className="!border-ooolab_gray_6"
                                                onChange={(item: any) => {
                                                    setValue(`student_attendances.${index}.goal_achieved`, !!item.value ? item.value : 0 );
                                                    clearErrors(`student_attendances.${index}.goal_achieved`);
                                                }}
                                            />
                                            <ErrorMessageForm errors={errors} name={`student_attendances.${index}.goal_achieved`} />
                                        </div>
                    
                                        <div className="col-span-2">
                                            <input
                                                name={`student_attendances.${index}.rewards_coin`}
                                                placeholder={t('EVALUATE.ENTER_NUMBER')}
                                                className="border border-ooolab_blue_4 rounded w-full py-1 px-2"
                                                maxLength={8}
                                                pattern="\d*"
                                                type="text"
                                                {...register(`student_attendances.${index}.rewards_coin`, {
                                                    pattern: {
                                                        value: VALIDATE_FIELD.NUMBER,
                                                        message: t('FORM_CONST.NUMBER_ONLY_AND_NUMBERS_GREATER_THAN_ZERO'),
                                                    },
                                                })}
                                                disabled={checkAttendance(index)}
                                            />
                                            <ErrorMessageForm errors={errors} name={`student_attendances.${index}.rewards_coin`} />
                                        </div></> : null}
                                        <div className="col-span-2">
                                            <input
                                                type="text"
                                                maxLength={300}
                                                name={`student_attendances.${index}.instructor_comment`}
                                                placeholder={t('EVALUATE.ENTER_COMMENT')}
                                                className="border border-ooolab_blue_4 rounded w-full py-1 px-2"
                                                {...register(`student_attendances.${index}.instructor_comment`, {
                                                    setValueAs: setEmptyOrStr,
                                                })}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>}
                            {loading && <Spinner />}
                            {!fields?.length && !loading && <NoContentWithImage />}
                        </div>
                    </div>
            </form>
        </div>
    );
};

export default Grade;
