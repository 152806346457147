import ProgressBar from './ProgressBar';

type AppReviewProps = {
    assignment_grade?: number;
    assignment_score?: number;
    assignment_completion?: number;
    assignment_grade_in_percent?: number;
    assignment_score_in_percent?: number;
    assignment_completion_in_percent?: number;
};

const AppReview: React.FC<AppReviewProps> = ({
    assignment_completion_in_percent = 0,
    assignment_grade_in_percent = 0,
    assignment_score_in_percent = 0
}: AppReviewProps) => {
    return (
        <>
            <label className="text-sm font-semibold">Assignment</label>
            <p className="text-[28px] font-bold mt-1">
                {Math.round(assignment_score_in_percent || 0)}%
            </p>
            <div className="mt-3 flex w-full items-center">
                <label className="flex-none w-[77px] text-xs">Completion</label>
                <div className="w-full">
                    <ProgressBar
                        percentage={assignment_completion_in_percent}
                        hideLabel={true}
                    />
                </div>
            </div>
            <div className="mt-2 flex w-full items-center">
                <label className="flex-none w-[77px] text-xs">
                    Average Grade
                </label>
                <div className="w-full">
                    <ProgressBar
                        percentage={assignment_grade_in_percent}
                        hideLabel={true}
                    />
                </div>
            </div>
        </>
    );
};

export default AppReview;
