export default function TrashIcon({
    width = '20px',
    height = '20px',
    fill = '#EF4444',
}: {
    width?: string;
    height?: string;
    fill?: string;
}) {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.83268 3.33334C5.83268 2.41286 6.57887 1.66667 7.49935 1.66667H12.4993C13.4198 1.66667 14.166 2.41286 14.166 3.33334V5.00001H15.8241C15.8292 4.99996 15.8344 4.99996 15.8396 5.00001H17.4993C17.9596 5.00001 18.3327 5.3731 18.3327 5.83334C18.3327 6.29358 17.9596 6.66667 17.4993 6.66667H16.6086L15.8858 16.7854C15.8236 17.6576 15.0978 18.3333 14.2234 18.3333H5.77528C4.90088 18.3333 4.17515 17.6576 4.11285 16.7854L3.39008 6.66667H2.49935C2.03911 6.66667 1.66602 6.29358 1.66602 5.83334C1.66602 5.3731 2.03911 5.00001 2.49935 5.00001H4.15914C4.16431 4.99996 4.16947 4.99996 4.17462 5.00001H5.83268V3.33334ZM7.49935 5.00001H12.4993V3.33334H7.49935V5.00001ZM5.061 6.66667L5.77528 16.6667H14.2234L14.9377 6.66667H5.061Z"
                fill={fill}
            />
        </svg>
    );
}