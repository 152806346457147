import { Tab } from '@headlessui/react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon, InformationCircleIcon } from '@heroicons/react/outline';
import NotificationForm from '../NotificationComponents/NotificationForm';
import { useForm } from 'react-hook-form';
import { useContext, useEffect, useState } from 'react';
import notificationsService from 'services/notifications.service';
import { SelectOptionType } from 'types/GetListOfWorkspace.type';
import dayjs from 'dayjs';
import {
    ReceiveOptions,
    SendToOptions
} from '../NotificationComponents/ConstantNotications';
import { useBoolean } from 'hooks/custom';
import DeleteNotification from '../NotificationComponents/DeleteNotification';
import { UserContext } from 'contexts/User/UserContext';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { Toastify } from '_shared';
import { checkPermission } from 'utils/commonFun';

const listTab = ['Information'];

const NotificationDetails = () => {
    const {
        userState: { result },
    } = useContext(UserContext);
    const { t: translator } = useTranslation();
    const history = useHistory();
    const params: { id: string; notificationId: string } = useParams();
    const [labelListPeople, setLabelListPeople] = useState<SelectOptionType[]>(
        []
    );
    const [labelListClasses, setLabelListClasses] = useState<
        SelectOptionType[]
    >([]);
    const [labelListGroups, setLabelListGroups] = useState<SelectOptionType[]>(
        []
    );
    const [imgPath, setImgPath] = useState<string>(null);


    const [dataNoti, setDataNoti] = useState<any>();
    const {
        booleanValue: modalDelete,
        toggleBooleanValue: toggleModalDelete,
    } = useBoolean();

    const {
        register,
        formState: { errors, dirtyFields, isDirty, touchedFields },
        getValues,
        handleSubmit,
        control,
        reset,
        trigger,
        setValue,
        watch,
        setError,
        clearErrors
    } = useForm();


    const submitForm = () => {
        const values = getValues();
        if (values) {
            const date = dayjs(values.notiDate).format('YYYY-MM-DD');
            const time = dayjs(values.notiTime).format('HH:mm:ss');
            const dateTime = date + 'T' + time;
            if (dayjs(dateTime)?.isAfter(dayjs(new Date)) || values?.schedule_type?.value === 'now') {
                const bodyData = {
                    title: values?.title,
                    description: values?.description,
                    schedule: dateTime,
                    schedule_type: values?.schedule_type?.value,
                    list_group_id: values?.list_group_id?.map(item => item?.value),
                    list_user_id: values?.list_user_id?.map(item => item?.value),
                    subtitle: values?.subtitle,
                    link: values?.link,
                    image: imgPath || dataNoti?.image,
                    receivers: {
                        receiver_type: values.receiver_type?.value,
                        send_to: values?.send_to?.value,
                        course_id: values?.course?.value
                    }
                };
                if (bodyData?.receivers?.receiver_type !== 'course') delete bodyData?.receivers?.course_id;
                if (values?.schedule?.value === 'now') delete bodyData?.schedule;
                if (!values?.course?.value) delete bodyData?.receivers?.course_id;
                if (bodyData?.receivers?.receiver_type === 'custom_group') delete bodyData?.receivers?.send_to;

                if (watch('type-desc') === 'msg') delete bodyData?.link;
                if (watch('type-desc') === 'link') delete bodyData?.description;
                if (!bodyData?.image) delete bodyData?.image;

                if (params.notificationId === 'new') {
                    notificationsService
                        .createNotification(params.id, bodyData)
                        .then((res) => {
                            Toastify.success();
                            setTimeout(() => {
                                history.push(
                                    `/workspace/${params.id}/management/notification`
                                );
                            }, 500);
                        })
                        .catch((err) => {
                            if (err?.response?.data?.error?.code === 400 && err?.response?.data?.error?.body_params[0]?.msg === 'DATETIME_IN_THE_PAST') {
                                Toastify.error(translator('NOTIFICATION_CUSTOM.MSG_ERROR_PAST_TIME'));
                            } else Toastify.error();
                        });
                } else {
                    notificationsService
                        .updateNotification(
                            params.id,
                            params.notificationId,
                            bodyData
                        )
                        .then((res) => {
                            Toastify.success();
                            setTimeout(() => {
                                history.push(
                                    `/workspace/${params.id}/management/notification`
                                );
                            }, 500);
                        })
                        .catch((err) => {
                            if (err?.response?.data?.error?.code === 400 && err?.response?.data?.error?.body_params[0]?.msg === 'DATETIME_IN_THE_PAST') {
                                Toastify.error(translator('NOTIFICATION_CUSTOM.MSG_ERROR_PAST_TIME'));
                            } else Toastify.error();
                        });
                }
            } else {
                Toastify.error(translator('NOTIFICATION_CUSTOM.MSG_ERROR_PAST_TIME'))
            }

        }
    };

    useEffect(() => {
        if (result.id) {
            if (params?.notificationId != 'new') {
                notificationsService
                    .getDetailsNotification(params.id, params.notificationId)
                    .then((res) => {
                        if (res?.data) {
                            setDataNoti(res?.data);
                            reset({
                                title: res?.data?.title,
                                description: res?.data?.description,
                                
                                notiDate: res?.data?.schedule
                                    ? new Date(
                                        dayjs.utc(res?.data?.schedule)
                                            .tz(result?.time_zone)
                                            .format('YYYY-MM-DD')
                                    )
                                    : new Date(
                                        dayjs.utc(res?.data?.created_at)
                                            .tz(result?.time_zone)
                                            .format('YYYY-MM-DD')
                                    ),
                                notiTime: res?.data?.schedule
                                    ? new Date(
                                        dayjs.utc(res?.data?.schedule)
                                            .tz(result?.time_zone)
                                            .format('YYYY/MM/DD HH:mm')
                                    )
                                    : new Date(
                                        dayjs.utc(res?.data?.created_at)
                                            .tz(result?.time_zone)
                                            .format('YYYY/MM/DD HH:mm')
                                    ),
                                schedule_type: { value: 'schedule', label: 'Schedule' },
                                typeDesc: res?.data?.link ? 'link' : 'msg',
                                subtitle: res?.data?.subtitle,
                                link: res?.data?.link,
                                receiver_type: ReceiveOptions(translator)?.find(item => item?.value === res?.data?.receivers?.receiver_type),
                                send_to: SendToOptions(translator)?.find(item => item?.value === res?.data?.receivers?.send_to),
                                course: {
                                    label: res?.data?.receivers?.course?.title,
                                    value: res?.data?.receivers?.course?.id
                                }
                            });
                        }
                    });
            } else {
                reset({
                    typeDesc: 'link',
                    receiver_type: ReceiveOptions(translator)?.find(item => item?.value === 'people'),
                    send_to: SendToOptions(translator)?.find(item => item?.value === 'all'),
                })
            }
        }
    }, [result]);

    return (
        <>
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.COMMUNICATION')}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    { name: translator('DASHBOARD.SIDEBAR.NOTIFICATIONS'), href: `/workspace/${params?.id}/management/notification`, className: '' },
                    { name: params?.notificationId != 'new' ? watch('title') : translator('NOTIFICATION_CUSTOM.NEW_NOTIFICATION'), href: '', className: '' },
                ]}
            />
            <div className="w-full">
                {params?.notificationId != 'new' ? (
                    <>
                        <div className=' flex justify-between border-b border-gray-300 px-5'>
                        <div className="text-gay-800 flex gap-1 items-center text-xl font-semibold pt-4 pb-6 ">
                                <div onClick={() => history.push(`/workspace/${params?.id}/management/notification`)} className='cursor-pointer bg-gray-100 rounded-full p-1.5'>
                                    <ChevronLeftIcon width={16} />
                                </div> 
                                {watch('title')}
                        </div>
                        {dataNoti?.status !== 'published' && (
                                <div className="flex border-b border-ooolab_bar_color mx-5 justify-end py-5">
                                    {checkPermission('customNotificationDetails', 'D') && <div
                                        onClick={toggleModalDelete}
                                        className="flex items-center  border border-[#E7443C] rounded px-3 py-2 cursor-pointer text-xs font-semibold text-[#E7443C] hover:bg-[#E7443C] hover:text-white mr-4"
                                    >
                                        {translator('DELETE')}
                                        
                                    </div>}
                                    {checkPermission('customNotificationDetails', 'U') && <button
                                        disabled={
                                            !(
                                                dirtyFields &&
                                                (Object.keys(dirtyFields)
                                                    .length ||
                                                    Object.keys(touchedFields)
                                                        ?.length) || imgPath
                                            )
                                        }
                                        onClick={handleSubmit(submitForm)}
                                        className={` flex items-center rounded px-3 py-2 cursor-pointer text-xs font-semibold text-white first:mr-4 ${dirtyFields &&
                                            (Object.keys(dirtyFields).length ||
                                                Object.keys(touchedFields)
                                                    ?.length) || imgPath
                                            ? 'bg-primary-500'
                                            : 'bg-[#8F90A6] '
                                            }`}
                                    >
                                        
                                        {translator('SAVE')}
                                    </button>}

                                </div>
                        )}
                        </div>


                        <NotificationForm
                            handleSubmit={handleSubmit}
                            register={register}
                            errors={errors}
                            control={control}
                            submitForm={submitForm}
                            setValue={setValue}
                            setLabelListPeople={setLabelListPeople}
                            setLabelListClasses={setLabelListClasses}
                            setLabelListGroups={setLabelListGroups}
                            labelListPeople={labelListPeople}
                            labelListClasses={labelListClasses}
                            labelListGroups={labelListGroups}

                            dataNoti={dataNoti}
                            trigger={trigger}
                            getValues={getValues}
                            watch={watch}
                            setImgPath={setImgPath}
                            imgPath={imgPath}
                            setError={setError}
                            clearErrors={clearErrors} 
                        />
                    </>
                ) : (
                    <>
                        <div className="flex justify-between items-center border-b border-ooolab_bar_color mx-5">
                            <div className="text-gay-800 flex gap-1 items-center text-xl font-semibold pt-4 pb-6 ">
                                <div onClick={() => history.push(`/workspace/${params?.id}/management/notification`)} className='cursor-pointer bg-gray-100 rounded-full p-1.5'>
                                    <ChevronLeftIcon width={16} />
                                </div> 
                                {translator('NOTIFICATION_CUSTOM.NEW_NOTIFICATION')}
                            </div>
                            <button
                                disabled={!isDirty}
                                onClick={handleSubmit(submitForm)}
                                className={` flex items-center rounded px-3 py-2 cursor-pointer text-xs font-semibold text-white first:mr-4 ${isDirty ? 'bg-primary-500' : 'bg-[#8F90A6] '
                                    }`}
                            >
                                {translator('NOTIFICATION_CUSTOM.CREATE')}
                            </button>
                        </div>
                        <div className="">
                            <NotificationForm
                                handleSubmit={handleSubmit}
                                register={register}
                                errors={errors}
                                control={control}
                                submitForm={submitForm}
                                setValue={setValue}
                                setLabelListPeople={setLabelListPeople}
                                setLabelListClasses={setLabelListClasses}
                                setLabelListGroups={setLabelListGroups}
                                labelListPeople={labelListPeople}
                                labelListClasses={labelListClasses}
                                labelListGroups={labelListGroups}
                                dataNoti={dataNoti}
                                trigger={trigger}
                                getValues={getValues}
                                watch={watch}
                                setImgPath={setImgPath}
                                imgPath={imgPath}
                                setError={setError}
                                clearErrors={clearErrors}
                            />
                        </div>
                    </>
                )}
            </div>
            <DeleteNotification
                open={modalDelete}
                onCloseModal={toggleModalDelete}
                data={dataNoti}
            />
        </>
    );
};

export default NotificationDetails;
