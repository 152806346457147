import { ButtonOutline, ButtonPrimary, Toastify } from '_shared'
import DialogComponent from 'components/Dialog/DialogComponent'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import courseService from 'services/course.service'
import { ENROLLMENT_STATUS } from '../../../constants';
import { useAppSelector } from 'hooks/hooks';
import { toast } from 'react-toastify';

export default function ModalChangeStatusMember({ isOpen, onClose, data, refreshData }) {
  const [content, setContent] = useState(undefined);
  const params: { courseId, id } = useParams();
  const { t: translator } = useTranslation();
  const search = useLocation()?.search;
  const enrollmentId = new URLSearchParams(search).get('enrollmentId');
  const { setting } = useAppSelector((state) => state.course);


  useEffect(() => {
    if (data?.display_status === ENROLLMENT_STATUS.DEACTIVATED) {
      setContent({
        title: translator('COURSES.ACTIVATE'),
        message: translator('COURSES.ACTIVATE_MEMBER'),
        button: translator('COURSES.ACTIVATE'),
        classBtn: ''
      })
    }
    else {
      setContent({
        title: translator('COURSES.DEACTIVATE'),
        message: setting?.enrollment_type === 'credit' ? translator('COURSES.DEACTIVATE_MEMBER_WITH_CREDIT_COURSE') : translator('COURSES.DEACTIVATE_MEMBER'),
        button: translator('Yes'),
        classBtn: '!bg-red-500 !border-red-500'
      })
    }
  }, [data, setting])

  const onChangeStatus = () => {
    const loadingToastId = Toastify.loading(
      translator('COURSES.DEACTIVATING_LEARNER'),
    );
    if (data?.isLeader) return;
    let status = ''
    if (data?.display_status === ENROLLMENT_STATUS.DEACTIVATED) {
      status = ENROLLMENT_STATUS.ENROLLED
    }
    else {
      status = ENROLLMENT_STATUS.DEACTIVATED
    }
    courseService.updateStatusOfEnrollmentMember(params?.id, params?.courseId, enrollmentId, {
      status,
      lg_member_enrollment_id: data?.lg_member_enrollment_id
    })
      .then(() => {
        toast.dismiss(loadingToastId)
        Toastify.success();
        refreshData();
      })

      .catch(() => {
        toast.dismiss(loadingToastId)
        Toastify.error()
      })
      .finally(() => onClose(false))
  }

  return (
    <DialogComponent
      title={content?.title}
      onCloseModal={onClose}
      isOpen={isOpen}
      child={
        <div>
          <div className='text-sm py-4'>{content?.message}</div>
          <div className='flex justify-center gap-4 mt-2'>
            <ButtonOutline type="button" onClick={onClose}>{translator("CANCEL")}</ButtonOutline>
            <ButtonPrimary className={content?.classBtn} type="button" onClick={onChangeStatus}>{content?.button}</ButtonPrimary>
          </div>
        </div>
      }
    />
  )
}
