import React from 'react';
import ManagementMasterPage from 'pages/RouteMasterPage';
import ClassDetail from 'components/Management/Class/ClassDetail';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';

const ClassDetailPage = () => {
    return (
        <ManagePermissionWrapper>
            <ClassDetail>
                <ManagementMasterPage  />
            </ClassDetail>
        </ManagePermissionWrapper>
    );
};

export default ClassDetailPage;
