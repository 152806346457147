import { FieldValues, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import React from "react";
import userMiddleware from "middleware/user.middleware";

export function handleNewPasswordChange(
    setValue: UseFormSetValue<FieldValues>,
    trigger: UseFormTrigger<FieldValues>
) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setValue('new_password', value);
        trigger('new_password');
    };
}

export function handleConfirmPasswordChange(
    setValue: UseFormSetValue<FieldValues>,
    trigger: UseFormTrigger<FieldValues>
) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setValue('confirm_password', value);
        trigger('confirm_password');
    };
}

export function onSubmit(
    newPassword: string | undefined,
    confirmPassword: string | undefined,
    dispatch: React.Dispatch<any>
) {
    if (newPassword && newPassword === confirmPassword) {
        userMiddleware.updatePassword(dispatch, {
            new_password: newPassword,
            confirm_password: confirmPassword
        });
    }
}
