import Select, { StylesConfig } from 'react-select';

const customSelectStyle: StylesConfig<any, true> = {
  clearIndicator: (base) => ({ ...base, display: 'none' }),
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  dropdownIndicator: (base) => ({ ...base, padding: 4 }),
  control: (base) => ({
      ...base,
      borderColor: '#e6e6e6',
      minHeight: '2rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    background: '#E5E7EB',
    borderRadius: '4px',
    textColor: '#000000',
    fontWeight: 600,
  }),
};

const errorMsg = {
  EXISTING_NAME: 'Duplicate banner name',
  NOTHING_CHANGED: 'Nothing changed'
}

  const optionBannerActivation = (translator) => [
    { value: 'active', label: <div className=' w-fit bg-ooolab_alert_success_50 px-3 py-1 rounded text-ooolab_dark_1 '>{translator("ACTIVE")}</div>},
    { value: 'inactive', label: <div className=' w-fit bg-[#FFCECC] px-3 py-1 rounded text-ooolab_dark_1 '>{translator("INACTIVE")} </div>}
  ]

  const labelOptionBannerActivation = {
    active: 'Active',
    inactive: 'Inactivated'
  }

  const BannerActivationItem = (status) => {
    return { value: status, label: <div className={`w-fit ${status === 'active'? 'bg-ooolab_alert_success_50': 'bg-ooolab_pink_2'} px-3 py-1 rounded text-ooolab_dark_1 `}>{labelOptionBannerActivation[status]}</div>}
  }

 const optionApply = [
  { value: 'web_student_view', label: 'Student View (Web)' },
  { value: 'mobile_student_view', label: 'Student View (Mobile)' },
  { value: 'web_parent_view', label: 'Parent View (Web)' }, 
  { value: 'mobile_parent_view', label: 'Parent View (Mobile)' }, 
  { value: 'web_instructor_dashboard', label: 'Instructor Dashboard (Web)' }, 
];

const labelOptions = {
  web_student_view: 'Student View (Web)',
  mobile_student_view: 'Student View (Mobile)',
  web_parent_view: 'Parent View (Web)',
  mobile_parent_view: 'Parent View (Mobile)',
  web_instructor_dashboard: 'Instructor Dashboard (Web)'
}

export {customSelectStyle, optionBannerActivation, optionApply, BannerActivationItem, labelOptions, labelOptionBannerActivation, errorMsg}