import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { useContext, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import workspaceService from 'services/workspace.service';
import LeftMenu from './LeftMenu';
import Status from '../Components/Status';
import { ButtonOutline, Toastify } from '_shared';
import Information from './Tab/Information/Information';
import ModalActivateMember from '../ModalAction/ModalActivateMember';
import ModalDeactivateMember from '../ModalAction/ModalDeactivateMember';
import ModalDeleteMember from '../ModalAction/ModalDeleteMember';
import { USER_STATUS } from '../constant';
import workspaceMiddleware from 'middleware/workspace.middleware';
import get from 'lodash/get';
import Enrollment from './Tab/Enrollment/Enrollment';
import Achievements from './Tab/Achievements';
import Scheduling from './Tab/Schedule/Scheduling';
import { CourseGradebook } from './Tab/ProgressAndHistory/CourseGradebook';
import { checkPermission } from 'utils/commonFun';
import TeachingHistory from '../UserDetail/Tab/TeachingHistory/TeachingHistory'
import { CreditAccounts } from './Tab/CreditAccounts';
import { useBoolean } from 'hooks/custom';
import DialogComponent from 'components/Dialog/DialogComponent';
import { defaultRole, roleType } from 'components/Management/RolesPermissions/RolesPermissionsContants';
import userService from 'services/user.service';
import ErrorIcon from 'assets/icon/ErrorIcon';
import { SESSION_STATUS } from 'constant/course.const';
import { Reports } from './Tab/Reports/Reports';

export default function UserDetail({ children }) {
    const { t: translator } = useTranslation();
    const params = useParams<{ id: string; userId: string }>();
    const search = useLocation()?.search;
    const history = useHistory();
    const userInformation = JSON.parse(localStorage.getItem('user-data'));

    const [userData, setUserData] = useState(undefined);

    const [isOpenModalDelete, setOpenModalDelete] = useState(false);
    const [isOpenModalInactive, setOpenModalInactive] = useState(false);
    const [isOpenModalActive, setOpenModalActive] = useState(false);
    const [isOpenModalCannotDeactivatedSuperAdmin, setIsOpenModalCannotDeactivatedSuperAdmin] = useState(false);
    const [isChangeRole, setChangeRole] = useState<boolean>(false);
    const [isOpenModalCannotDeactivatedInstructor, setIsOpenModalCannotDeactivatedInstructor] = useState(false);

    const location: any = useLocation();
    const tab = location?.state?.tab || new URLSearchParams(search).get('tab') || 'information';
    const { booleanValue: modalNotAlowChangeStatus, toggleBooleanValue: toggleModalNotAlowChangeStatus } = useBoolean();
    const workspaceState = useContext(GetWorkspaceContext);
    const userName = workspaceState?.getWorkspaceDetailState?.currentRouteDetail?.[0]?.name || '';


    const getUserDetail = () => {
        workspaceService.getMemberDetail(params?.id, params?.userId)
            .then((res) => {
                setUserData(res?.data);
            })
            .catch((err) => {
                if (err?.response?.status === 404) history.push(`/not-found`);
            });
    };

    useEffect(() => {
        if (!tab) history.replace(`/workspace/${params?.id}/management/users/${params.userId}?tab=information`);
    }, []);

    useEffect(() => {
        getUserDetail();
    }, [params?.userId]);


    const handleChangeStatusUser = () => {
        const status = userData?.membership?.status;
        if (status === USER_STATUS?.ACTIVE ||
            userData?.membership?.status === USER_STATUS.INVITE ||
            userData?.membership?.status === USER_STATUS.REGISTERED) {
            setOpenModalInactive(true);
        }
        if (status === USER_STATUS?.DEACTIVATE) {
            setOpenModalActive(true);
        }
    };

    const preCheckAllChangeStatus = async () => {
        let status = userData?.membership?.status;
        if (status === USER_STATUS?.ACTIVE ||
            userData?.membership?.status === USER_STATUS.INVITE ||
            userData?.membership?.status === USER_STATUS.REGISTERED) {
            status = USER_STATUS.DEACTIVATE
        }
        if (status === USER_STATUS?.DEACTIVATE) {
            status = USER_STATUS.ACTIVE
        }
        const { data: { allow_change_status = false } } = await workspaceService.preCheckBeforeChangeStatusOfUser(params?.id, params?.userId, {
            status
        })
        if (allow_change_status) {
            handleChangeStatusUser()
        }
        else {
            toggleModalNotAlowChangeStatus();
        }
    }

    const preCheckSuperAdminRole = () => {
        userService.preCheckUserWorkspace(params?.id, params?.userId).then((res) => {
            if (res?.data?.allow_change_status) {
                handleChangeStatusUser()
            }
            else {
                setIsOpenModalCannotDeactivatedSuperAdmin(true);
                setChangeRole(false)
            }
        }).catch(() => {
            Toastify.error()
        })

    }

    const preCheckDeactivatedInstructor = () => {
        userService.preCheckUserInstructor(params?.id, params?.userId)
            .then((res) => {
                if (res?.data?.has_on_up_session) {
                    setIsOpenModalCannotDeactivatedInstructor(true);
                } else {
                    handleChangeStatusUser();
                }
            }).catch(() => {
                Toastify.error();
            });
    }

    const handleDeactivatedUser = () => {
        if (userData?.membership?.user_role?.name === defaultRole.superAdmin && userData?.membership?.user_role?.role_type === roleType.default) {
            return preCheckSuperAdminRole();
        } else if (userData?.membership?.type === 'student' && userData?.membership?.status !== USER_STATUS.DEACTIVATE) {
            return preCheckAllChangeStatus();
        } else if (userData?.membership?.type === 'instructor' && userData?.membership?.status !== USER_STATUS.DEACTIVATE) {
            return preCheckDeactivatedInstructor();
        }
        return handleChangeStatusUser();
    }

    const onViewLearnerTab = () => {
        window.open(`/workspace/${params?.id}/management/users/${userData?.id}?tab=enrollment&statuses=active,enrolled`, '_blank')
    }

    const { dispatch: workspaceDispatch } = useContext(GetWorkspaceContext);

    useEffect(() => {
        if (userData) {
            let name = get(userData, 'display_name');
            if (userData?.nick_name) {
                name += ` / ${userData?.display_name}`;
            }
            workspaceMiddleware.setCurrentRouteDetail(workspaceDispatch, [
                {
                    name,
                    routeId: 'userId',
                },
            ]);
        }
    }, [userData]);

    const onChangeRoleSuperAdmin = () => {
        setIsOpenModalCannotDeactivatedSuperAdmin(true);
        setChangeRole(true)
    }
    return (
        <div>
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT')}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    { name: translator('DASHBOARD.SIDEBAR.USERS'), href: `/workspace/${params?.id}/management/users`, className: '' },
                    { name: userName, href: '', className: '' },
                ]}
            />
            <div>
                <div className="p-5 w-full flex justify-between border-b border-gray-300">
                    <div className="flex gap-3 ">
                        <div className="text-xl font-semibold">{userData?.display_name}</div>
                        <Status user={userData} status={userData?.membership?.status} />
                    </div>
                    <div className="flex gap-3">
                        {checkPermission('userDetails', 'DA') && userInformation?.id != userData?.id && (
                            <ButtonOutline
                                className="!border-primary-500 !text-primary-500"
                                type="button"
                                onClick={handleDeactivatedUser}
                            >
                                {userData?.membership?.status === USER_STATUS.ACTIVE ||
                                    userData?.membership?.status === USER_STATUS.INVITE ||
                                    userData?.membership?.status === USER_STATUS.REGISTERED
                                    ? translator('DEACTIVATE')
                                    : translator('ACTIVATE')}
                            </ButtonOutline>
                        )}
                        {checkPermission('userDetails', 'D') && userInformation?.id != userData?.id && (
                            <ButtonOutline
                                className="!border-red-500 !text-red-500 !hidden"
                                type="button"
                                onClick={() => setOpenModalDelete(true)}
                            >
                                {translator('USER.DELETE_ACCOUNT')}
                            </ButtonOutline>
                        )}
                    </div>
                </div>
                <div className="grid grid-cols-4 gap-5 p-5 bg-gray-50">
                    <div className="col-span-1 bg-white rounded-lg">
                        <LeftMenu userData={userData} />
                    </div>
                    <div className="col-span-3 bg-white rounded-lg px-5 py-4">
                        {tab === 'information' && (
                            <Information
                                refreshData={getUserDetail}
                                userData={userData}
                                onChangeRoleSuperAdmin={onChangeRoleSuperAdmin}
                            />
                        )}
                        {tab === 'enrollment' && <Enrollment />}
                        {tab === 'schedule' && <Scheduling userData={userData} />}
                        {/* {tab === 'credit-information' && <CreditInformation userData={userData}/>} */}
                        {tab === 'credit-accounts' && <CreditAccounts userData={userData} />}
                        {tab === 'achievements' && <Achievements children={undefined} userData={userData} />}
                        {tab === 'gradebook' && <CourseGradebook userData={userData} />}
                        {tab === 'teaching-history' && <TeachingHistory />}
                        {tab === 'reports' && <Reports />}
                    </div>
                </div>
            </div>

            {isOpenModalDelete && (
                <ModalDeleteMember
                    isOpen={isOpenModalDelete}
                    onClose={() => setOpenModalDelete(false)}
                    refreshData={() => history.push(`/workspace/${params?.id}/management/users`)}
                    data={userData}
                />
            )}

            {isOpenModalInactive && (
                <ModalDeactivateMember
                    isOpen={isOpenModalInactive}
                    onClose={() => setOpenModalInactive(false)}
                    refreshData={getUserDetail}
                    data={userData}
                />
            )}

            {isOpenModalActive && (
                <ModalActivateMember
                    isOpen={isOpenModalActive}
                    onClose={() => setOpenModalActive(false)}
                    refreshData={getUserDetail}
                    data={userData}
                />
            )}
            <DialogComponent
                isOpen={modalNotAlowChangeStatus}
                title={translator('DEACTIVATE')}
                onCloseModal={toggleModalNotAlowChangeStatus}
                maskClosable={false}
                child={
                    <div className="mt-2">
                        <div className="text-sm">{translator('USER.DEACTIVATE_MESSAGE')}</div>
                        <div className="text-sm mt-2">
                            {translator('USER.CLICK')}{' '}
                            <span onClick={onViewLearnerTab} className="cursor-pointer opacity-100 hover:opacity-75 text-primary-500">
                                {translator('USER.HERE')}
                            </span>{' '}
                            {translator('USER.TO_VIEW_ENROLLMENT_LIST')}
                        </div>
                    </div>
                }
            />
            {isOpenModalCannotDeactivatedSuperAdmin && (
                <DialogComponent
                    isOpen={isOpenModalCannotDeactivatedSuperAdmin}
                    title={
                        <div className="flex items-center gap-2 ">
                            <div className="flex-none w-5">
                                <ErrorIcon width={20} height={20} />
                            </div>
                            {isChangeRole
                                ? translator('USER.UNABLE_TO_CHANGE_SUPER_ADMIN')
                                : translator('USER.UNABLE_TO_DEACTIVATE_SUPER_ADMIN')}
                        </div>
                    }
                    onCloseModal={() => {
                        setIsOpenModalCannotDeactivatedSuperAdmin(false);
                        setChangeRole(false);
                    }}
                    width=" !rounded-lg"
                    maskClosable={false}
                    stylesTitle=" text-base"
                    child={
                        <div className="mt-2">
                            <div className="text-sm mt-2">{translator('USER.MSG_UNABLE_TO_DEACTIVATE')}</div>
                            <div className="text-sm mt-4 mb-2">{translator('USER.SUB_MSG_UNABLE_TO_DEACTIVATE')}</div>
                        </div>
                    }
                />
            )}
            {isOpenModalCannotDeactivatedInstructor && (
                <DialogComponent
                    isOpen={isOpenModalCannotDeactivatedInstructor}
                    title={
                        <div className="flex items-center gap-2">
                            <div className="flex-none w-5">
                                <ErrorIcon width={20} height={20} />
                            </div>
                            {translator('USER.UNABLE_TO_DEACTIVATE_INSTRUCTOR')}
                        </div>
                    }
                    onCloseModal={() => setIsOpenModalCannotDeactivatedInstructor(false)}
                    child={
                        <div className="mt-2 text-sm">
                            <div className="mt-2">{translator('USER.UNABLE_TO_DEACTIVATE_INSTRUCTOR_MSG')}</div>
                            <div className='mt-3'>
                                <Trans i18nKey="USER.UNABLE_TO_DEACTIVATE_INSTRUCTOR_CLICK_LINK">
                                    <span
                                        className='text-enterprise underline cursor-pointer'
                                        onClick={() => {
                                            history.push(`/workspace/${params.id}/management/sessions?tab=sessions-list`, {
                                                instructor_id: [
                                                    {
                                                        label: userData?.display_name,
                                                        name: userData?.display_name,
                                                        value: userData?.id,
                                                    },
                                                ],
                                                session_status: [SESSION_STATUS(translator)[0], SESSION_STATUS(translator)[1]],
                                            });
                                        }}
                                    ></span>
                                </Trans>
                            </div>
                        </div>
                    }
                />
            )}
        </div>
    );
}
