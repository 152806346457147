// @ts-nocheck
import type { IPlayerModel } from '../h5p-server';

import { mergeH5PIntegration, removeUnusedContent } from './h5p-utils';
import { addScripts, addStylesheets } from './dom-utils';
import { IH5PPlayerArgs } from 'types/H5P.type';
import { getLocalCookie } from 'utils/handleAuthorized';
declare global {
    interface Window {
        /**
         * The global H5P "class" of the H5P client core.
         */
        H5P: any;
        /**
         * Used by the H5P core to communicate settings between the server and
         * the H5P core client.
         */
        H5PIntegration: any;
    }
}

export interface IxAPIEvent {
    data: {
        statement: any;
    };
}

export interface IContext {
    contentId: string;
}

/**
 * A Web Component displaying H5P content.
 */
export class H5PAssignmentComponent extends HTMLElement {
    get argsId(): IH5PPlayerArgs {
        return JSON.parse(this.getAttribute('args-id'));
    }

    set argsId(argsId: IH5PPlayerArgs) {
        this.argsId('args-id', argsId);
    }
    // get contentId(): any {
    //     return this.getAttribute('content-id');
    // }

    // set contentId(contentId: string) {
    //     this.setAttribute('content-id', contentId);
    // }

    /**
     * Called when the component needs to load data about content. The endpoint
     * called in here should call H5PPlayer.render() and send back the player
     * model.
     *
     * Should throw an error with a message in the message property if something
     * goes wrong.
     */
    public get loadContentCallback(): (
        args: IH5PPlayerArgs
        // contentId: string
    ) => Promise<IPlayerModel> {
        return this.privateLoadContentCallback;
    }

    public set loadContentCallback(
        callback: (args: IH5PPlayerArgs) => Promise<IPlayerModel>
        // callback: (contentId: string) => Promise<IPlayerModel>
    ) {
        const mustRender = this.privateLoadContentCallback !== callback;
        this.privateLoadContentCallback = callback;
        if (mustRender) {
            this.render(this.argsId);
            // this.render(this.contentId);
        }
    }

    /**
     * Indicates changes to which attributes should trigger calls to
     * attributeChangedCallback.
     * @memberof H5PAssignmentComponent
     */
    static get observedAttributes(): string[] {
        return ['args-id'];
        // return ['content-id'];
    }
    constructor() {
        super();

        H5PAssignmentComponent.initTemplate();
    }

    private static template: HTMLTemplateElement;
    private playerModel: IPlayerModel;
    private privateLoadContentCallback: (
        argsId: IH5PPlayerArgs
        // contentId: string
    ) => Promise<IPlayerModel>;
    private resizeObserver: ResizeObserver;
    private root: HTMLElement;

    private static initTemplate(): void {
        const featureFlags = JSON.parse(process.env.REACT_APP_FEATURE_FLAGS);
        // We create the static template only once
        if (!H5PAssignmentComponent.template) {
            H5PAssignmentComponent.template = document.createElement(
                'template'
            );
            H5PAssignmentComponent.template.innerHTML = `
                <style>
                    .h5p-iframe {
                        font-family: Sans-Serif;
                        width: 100%;
                        margin: 0;
                        padding: 0;
                    }
                    .h5p-iframe .h5p-container {
                        overflow: hidden;
                    }
                    .h5p-iframe .h5p-content {
                        font-size: 16px;
                        line-height: 1.5em;
                        width: 100%;
                        height: auto;
                    }
                    .h5p-iframe .h5p-fullscreen .h5p-content,
                    .h5p-fullscreen .h5p-iframe,
                    .h5p-iframe .h5p-semi-fullscreen .h5p-content {
                        height: 100%;
                    }
                </style>
                ${!featureFlags?.DRM ? `
                    <style type="text/css" media="print">
                        body { visibility: hidden; display: none }
                    </style>
                `: ''}
                <div class="h5p-player-component-root"></div>
            `;
        }
    }

    /**
     * Called when one of the attributes in observedAttributes changes.
     */
    async attributeChangedCallback(
        name: string,
        oldVal: any,
        newVal: any
    ): Promise<void> {
        if (name === 'args-id') {
            // if (name === 'content-id') {
            if (oldVal) {
                removeUnusedContent(oldVal);
            }
            await this.render(newVal);
        }
    }

    /**
     * Called when the component is added to the DOM.
     */
    connectedCallback(): void {
        this.appendChild(
            H5PAssignmentComponent.template.content.cloneNode(true)
        );
        this.root = this.querySelector('.h5p-player-component-root');

        // We must notify the H5P content inside the player that the size of the
        // component has changed. Otherwise some content types won't resize
        // properly.
        this.resizeObserver = new ResizeObserver(() => {
            if (window.H5P?.instances) {
                window.H5P.instances.forEach((instance) => {
                    instance.trigger('resize');
                });
            }
        });
        this.resizeObserver.observe(this);
        //console.log(window.H5P?.instances);
    }

    private updateScript() {
        console.log('updateScript Assignment');
        if (document.getElementsByClassName('h5p-iframe').length === 0) {
            setTimeout(() => this.updateScript(), 20);
        } else {
            const cookieToken = getLocalCookie();
            const script = document.createElement('script');
            script.innerHTML = `
                const originalFetch = window.fetch;
                window.fetch = function () {
                    if (arguments[1].headers) {
                        arguments[1].headers = { 'X-CSRF-TOKEN': '${cookieToken}', ...arguments[1].headers };
                    } else {
                        arguments[1].headers = { 'X-CSRF-TOKEN': '${cookieToken}' };
                    }
                    return originalFetch.apply(this, arguments)
                };
            
                const open = XMLHttpRequest.prototype.open;
                const send = XMLHttpRequest.prototype.send;
                XMLHttpRequest.prototype.open = function (method, url, ...rest) {
                    var recall = open.call(this, method, url, ...rest);
                    this.setRequestHeader('X-CSRF-TOKEN', '${cookieToken}');
                    return recall;
                };
                function sendReplacement(data) {
                    if(this.onreadystatechange) {
                      this._onreadystatechange = this.onreadystatechange;
                    }
                    /**
                     * PLACE HERE YOUR CODE WHEN REQUEST IS SENT  
                     */
                    var xmlObj = this;
                    this.onload = function(data){  
                        console.log(data.target);
                        window.parent.flutter_inappwebview.callHandler('h5pAjaxResponse', {response:data.target.response,url:data.target.responseURL,status:data.target.status});
                    }           
                    return send.apply(this, arguments);
                }
                XMLHttpRequest.prototype.send = sendReplacement;
                const disabledCopyPast = () => {
                    /** 
                     * Disable right-click of mouse, F12 key, and save key combinations on page 
                     */ 
                    document.addEventListener("contextmenu", function(e){ 
                        e.preventDefault(); 
                    }, false); 
                    document.addEventListener("copy", (evt) => {
                        evt.preventDefault();
                    }, false);
                    document.addEventListener("cut", (evt) => {
                        evt.preventDefault();
                    }, false);
                    document.addEventListener("paste", (evt) => {
                        evt.preventDefault();
                    }, false);
                    document.addEventListener("keydown", function(e) { 
                        //document.onkeydown = function(e) { 
                        // "I" key 
                        if (e.ctrlKey && e.shiftKey && e.keyCode == 73) { 
                            disabledEvent(e); 
                        } 
                        // "J" key 
                        if (e.ctrlKey && e.shiftKey && e.keyCode == 74) { 
                            disabledEvent(e); 
                        } 
                        // "S" key + macOS 
                        if (e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) { 
                            disabledEvent(e); 
                        } 
                        // "U" key 
                        if (e.ctrlKey && e.keyCode == 85) { 
                            disabledEvent(e); 
                        } 
                        // "P" key 
                        if (e.keyCode == 91) { 
                            disabledEvent(e); 
                        }
                        if (e.keyCode == 91 && e.keyCode == 80) { 
                            disabledEvent(e); 
                        }
                        // "F12" key 
                        if (event.keyCode == 123) { 
                            disabledEvent(e); 
                        } 
                        // "C" key 
                        if (e.ctrlKey && e.keyCode == 67) { 
                            disabledEvent(e); 
                        } 
                        if (e.keyCode == 44) { 
                            disabledEvent(e); 
                        }
                    }, false); 
                    function disabledEvent(e){ 
                        if (e.stopPropagation){ 
                            e.stopPropagation(); 
                        } else if (window.event){ 
                            window.event.cancelBubble = true; 
                        } 
                        e.preventDefault(); 
                        return false; 
                    }
                }
             //  disabledCopyPast();
            `;
            const style = document.createElement('style');

            style.type = 'text/css';
            if (style.styleSheet)
                style.styleSheet.cssText = '.h5p-image-pair-images-paired .card-paired {' +
                    'top: -1em;' +
                    '} ' +
                    '.h5p-question-show-solution {' +
                    'display:none;' +
                    '}' +
                    '.h5p-question-try-again {' +
                    'display:none;' +
                    '}' +
                    '.qs-solutionbutton {' +
                    'display:none;' +
                    '}' +
                    '.qs-retrybutton {' +
                    'display:none;' +
                    '}' +
                    '.h5p-word-selectable-words [role="option"] {' +
                    'white-space: nowrap;' +
                    'padding: 0.15em;' +
                    'border-radius: 0.25em;' +
                    'cursor: pointer;' +
                    // 'position: inherit;' +
                    '}'
            else
                style.appendChild(
                    document.createTextNode(
                        '.h5p-image-pair-images-paired .card-paired {' +
                        'top: -1em;' +
                        '} ' +
                        '.h5p-question-show-solution {' +
                        'display:none;' +
                        '}' +
                        '.h5p-question-try-again {' +
                        'display:none;' +
                        '}' +
                        '.qs-solutionbutton {' +
                        'display:none;' +
                        '}' +
                        '.qs-retrybutton {' +
                        'display:none;' +
                        '}' +
                        '.h5p-word-selectable-words [role="option"] {' +
                        'white-space: nowrap;' +
                        'padding: 0.15em;' +
                        'border-radius: 0.25em;' +
                        'cursor: pointer;' +
                        // 'position: inherit;' +
                        '}'
                    )
                );
            //style.innerHTML = ;
            document
                .getElementsByClassName('h5p-iframe')[0]
                .contentWindow.document.getElementsByTagName('head')[0]
                .appendChild(script);
            document
                .getElementsByClassName('h5p-iframe')[0]
                .contentWindow.document.getElementsByTagName('head')[0]
                .appendChild(style);
            setTimeout(() => {
                // console.log(document
                //     .getElementsByClassName('h5p-iframe')[0]
                //     .contentWindow.H5P?.instances[0].getScore());
                var instances = document.getElementsByClassName('h5p-iframe')[0]
                    .contentWindow.H5P?.instances;
                console.log('instances', instances);
                if (instances && instances.length > 0) {
                    const instance = instances[0];
                    console.log('instance', instance);
                    if (instance.getMaxScore) {
                        console.log(instance.getMaxScore());
                    }
                    if (
                        (!instance.getMaxScore ||
                            instance.getMaxScore() == 0) &&
                        !instance.displayResult
                    ) {
                        //content type not need to submit
                        this.dispatchEvent(
                            new CustomEvent('xAPI', {
                                detail: {
                                    statement: {
                                        result: {
                                            completion: true,
                                            duration: '0',
                                            score: {
                                                min: 0,
                                                max: 0,
                                                raw: 0,
                                                scaled: 0,
                                            },
                                            success: false,
                                            fake_event: true,
                                        },
                                    },
                                    context: {
                                        name: 'Fake event',
                                        argId: this.argsId,
                                    },
                                    event: null,
                                },
                            })
                        );
                    }
                    document
                        .getElementsByClassName('h5p-iframe')[0]
                        .contentWindow.H5P?.trigger(instance, 'resize');
                }
            }, 2000);
        }
    }
    checkAvailableContentType() {
        var instances = document.getElementsByClassName('h5p-iframe')[0]
            .contentWindow.H5P?.instances;
        if (instances.length > 0) {
            return {
                libraryInfo: instances[0].libraryInfo,
                getScore: instances[0].getScore,
            };
        } else {
            return undefined;
        }
    }
    /**
     * Called when the component is removed from the DOM.
     */
    disconnectedCallback(): void {
        if (this.argsId) {
            removeUnusedContent(this.argsId);
        }
        // if (this.contentId) {
        //     removeUnusedContent(this.contentId);
        // }
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
            this.resizeObserver = null;
        }
        if (window.H5P.externalDispatcher) {
            window.H5P.externalDispatcher.off(
                'initialized',
                this.onContentInitialized
            );
            window.H5P.externalDispatcher.off('xAPI', this.onxAPI);
        }
    }

    /**
     * Called when any H5P content signals that it was initialized
     */
    private onContentInitialized = (): void => {
        if (
            this.playerModel.embedTypes.includes('div')
                ? window.H5P.instances[0]
                : (document.getElementById(
                    `h5p-iframe-${this.playerModel.argsId}`
                ) as HTMLIFrameElement).contentWindow.H5P?.instances
                    ?.length >= 1
            // : (document.getElementById(
            //       `h5p-iframe-${this.playerModel.contentId}`
            //   ) as HTMLIFrameElement).contentWindow.H5P?.instances
            //       ?.length >= 1
        ) {
            this.dispatchEvent(
                new CustomEvent('initialized', {
                    detail: { argsId: this.argsId + '' },
                    // detail: { contentId: this.contentId }
                })
            );
            if (window.H5P.externalDispatcher) {
                window.H5P.externalDispatcher.off(
                    'initialized',
                    this.onContentInitialized
                );
            }
        }
    };

    // TODO
    private onxAPI = (event: IxAPIEvent): void => {
        if (
            `${event.data?.statement?.object?.definition?.extensions['http://h5p.org/x-api/h5p-local-content-id']}` ===
            `${this.playerModel.contentId}`
            // `${event.data?.statement?.object?.definition?.extensions['http://h5p.org/x-api/h5p-local-content-id']}` ===
            // `${this.playerModel.contentId}`
        ) {
            const context: IContext = {
                argsId: this.playerModel.contentId,
                // contentId: this.playerModel.contentId
            };
            this.dispatchEvent(
                new CustomEvent('xAPI', {
                    detail: {
                        statement: event.data.statement,
                        context,
                        event,
                    },
                })
            );
        }
    };

    /**
     * Displays content.
     * @param {string} contentId
     */
    private async render(argsId: IH5PPlayerArgs): Promise<void> {
        // private async render(contentId: string): Promise<void> {
        if (!this.loadContentCallback) {
            return;
        }
        // Get data from H5P server
        try {
            this.playerModel = await this.loadContentCallback(argsId);
            this.dispatchEvent(
                new CustomEvent('xAPI', {
                    detail: {
                        statement: {
                            object: { id: this.playerModel.contentId },
                        },
                    },
                })
            );
            console.log(' this.playerModel');
            console.log(this.playerModel);
            // this.playerModel = await this.loadContentCallback(contentId);
        } catch (error) {
            this.root.innerHTML = `<p>Error loading H5P content from server: ${error.message}</p>`;
            return;
        }

        // Reset the component's DOM
        this.root.innerHTML = '';

        // We have to prevent H5P from initializing when the h5p.js file is
        // loaded.
        if (!window.H5P) {
            window.H5P = {};
        }
        window.H5P.preventInit = true;

        // We merge the H5P integration we received from the server with the one
        // that already exists in the window globally to allow for several H5P
        // content objects on a single page.
        mergeH5PIntegration(
            this.playerModel.integration,
            this.playerModel.argsId
            // this.playerModel.contentId
        );

        // The server has already told us which embed types are generally
        // acceptable for the content type, but we prefer div if possible to
        // avoid too many iframes.
        if (this.playerModel.embedTypes.includes('div')) {
            await this.renderDiv(this.playerModel);
        } else {
            await this.renderIframe(this.playerModel);
        }

        // Initialize H5P with the component as root
        window.H5P.preventInit = false;
        window.H5P.externalDispatcher.on(
            'initialized',
            this.onContentInitialized,
            this
        );
        window.H5P.preventInit = false;

        // detach xAPI listener first to avoid having multiple listeners on the
        // same content (can safely be done even if it hasn't been attached
        // before)
        window.H5P.externalDispatcher.off('xAPI', this.onxAPI);
        // attach xAPI listener
        window.H5P.externalDispatcher.on('xAPI', this.onxAPI);
        window.H5P.externalDispatcher.on('finish', (e) => {
            console.log(e);
        });
        console.log(window.H5P.externalDispatcher);
        window.H5P.init(this.root);
        this.updateScript();
    }

    /**
     * Creates a new DOM for the H5P using a div as container.
     */
    private async renderDiv(playerModel: IPlayerModel): Promise<void> {
        addStylesheets(
            playerModel.styles,
            document.getElementsByTagName('head')[0]
        );
        await addScripts(
            playerModel.scripts,
            document.getElementsByTagName('head')[0]
        );

        const h5pContainerDiv = document.createElement('div');
        h5pContainerDiv.className = 'h5p-iframe';
        this.root.appendChild(h5pContainerDiv);

        const h5pContentDiv = document.createElement('div');
        h5pContentDiv.className = 'h5p-content';
        h5pContentDiv.dataset.argsId = playerModel.argsId;
        // h5pContentDiv.dataset.contentId = playerModel.contentId;
        h5pContainerDiv.appendChild(h5pContentDiv);
    }

    /**
     * Creates a new DOM for the H5P using an iframe as container.
     * @param {IPlayerModel} playerModel
     */
    private async renderIframe(playerModel: IPlayerModel): Promise<void> {
        // We don't need to load styles, as they are all loaded within the
        // iframe.
        await addScripts(
            window.H5PIntegration.core.scripts,
            document.getElementsByTagName('head')[0]
        );

        const h5pIFrameWrapper = document.createElement('div');
        h5pIFrameWrapper.className = `h5p-iframe-wrapper`;
        h5pIFrameWrapper.innerHTML = `<iframe id="h5p-iframe-${playerModel.contentId}"
        class="h5p-iframe" data-content-id="${playerModel.contentId}"
        style="height:1px" allowfullscreen="allowfullscreen" src="about:blank" frameBorder="0" scrolling="no" allow="geolocation *; microphone *; camera *; midi *; encrypted-media *"
        title="H5P"></iframe>`;
        this.root.appendChild(h5pIFrameWrapper);
    }
}
