import { ReactNode, SetStateAction, useState, useEffect } from 'react';
import { useTable, useRowSelect, useFlexLayout, Row, Column } from 'react-table';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import './styleTableCustom.css';
import { classNames, UUID } from 'utils/commonFun';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import { ImageAvatar } from 'components/Image';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import ChevronUpDown from 'assets/icon/ChevronUpDown';

export const RenderTableHeader = ({ value, className, onClick, isOrder}: { value: any; className?: string, onClick?: any, isOrder?:boolean}) => (
    <p className={classNames('text-xs text-ooolab_dark_1 font-semibold flex items-center justify-start', className)}>
        {value}
        {isOrder &&  <span onClick={() => onClick?.()}><ChevronUpDown/></span>}
    </p>
);

export const RenderTableCell = ({
    value,
    className,
    isTooltip,
    disable,
    onClick,
    imageSrc,
}: {
    value: any;
    className?: string;
    isTooltip?: boolean;
    disable?: boolean;
    onClick?: () => void;
    imageSrc?: string;
}) => {
    const id = UUID();
    let element: ReactNode = <>{value}</>;
    if (isTooltip && !!imageSrc) {
        element = <ImageAvatar imageSrc={imageSrc} />;
    } else if (!isTooltip && !!imageSrc) {
        element = (
            <p className="flex items-center space-x-2">
                <ImageAvatar imageSrc={imageSrc} />
                <span>{value}</span>
            </p>
        );
    }
    return (
        <>
            <div
                className={classNames(
                    'pl-ooolab_p_1_e text-xs text-ooolab_dark_1 leading-ooolab_24px font-normal py-2 truncate ',
                    className,
                    !!onClick && 'cursor-pointer'
                )}
                onClick={() => onClick?.()}
                data-tip={value}
                data-for={id}
            >
                {element}
            </div>
            {isTooltip && (
                //@ts-ignore
                <ReactTooltip
                    place="bottom"
                    type="dark"
                    effect="float"
                    id={id}
                    disable={disable}
                    getContent={(content) => <div className="w-72 h-auto capitalize">{content}</div>}
                />
            )}
        </>
    );
};

export const LinkCustom = ({
    to,
    className,
    value,
    isTooltip,
    disable,
}: {
    to: any;
    className?: string;
    value: any;
    isTooltip?: boolean;
    disable?: boolean;
}) => {
    const id = UUID();
    return (
        <>
            <Link
                to={to}
                className={classNames(
                    'pl-ooolab_p_1_e text-xs text-primary-500 leading-ooolab_24px font-semibold py-2 truncate flex items-center justify-start',
                    className
                )}
                data-tip={value}
                data-for={id}
            >
                {value}
            </Link>
            {isTooltip && (
                //@ts-ignore
                <ReactTooltip
                    place="bottom"
                    type="dark"
                    effect="float"
                    id={id}
                    disable={disable}
                    getContent={(content) => <div className="w-56 h-auto">{content}</div>}
                />
            )}
        </>
    );
};

const EditableCell: any = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
}: {
    value: any;
    row: Row;
    column: Column;
    updateMyData: Function;
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);

    const onChange = (e: any) => {
        setValue(e.target.value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return <input value={value} onChange={onChange} onBlur={onBlur} placeholder={''} />;
};

type TableProps = {
    columns: Column<any>[];
    data: any;
    updateData?: any;
    initialState?: any; // hidden column
    noBorder?: boolean;
    isBorderFirstColum?: boolean;
    emptyContent?: string;
};

type HandleRowTableProps = {
    onClickRow?: (e?: any) => void;
    onDoubleClickRow?: (e?: any) => void;
    rowSelected?: any;
};

type InfiniteScrollTableProps = {
    isTableInfinity?: boolean;
    pagination?: any;
    setPagination?: Function;
};

const Table = ({
    columns,
    data,
    updateData,
    initialState,
    noBorder,
    isBorderFirstColum = true,
    emptyContent = 'No data',
    onClickRow,
    onDoubleClickRow,
    rowSelected,
    isTableInfinity,
    setPagination,
    pagination,
    emptyIcon
}: {
    columns: Column<any>[];
    data: any;
    updateData?: any;
    initialState?: any;
    noBorder?: boolean;
    isBorderFirstColum?: boolean;
    emptyContent?: string;
    onClickRow?: (e?: any) => void;
    onDoubleClickRow?: (e?: any) => void;
    rowSelected?: any;
    isTableInfinity?: boolean;
    setPagination?: SetStateAction<any>;
    pagination?: any;
    emptyIcon?: any
}) => {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        state: { selectedRowIds },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: {
                Cell: EditableCell,
            },
            updateData,
            initialState,
        },
        useRowSelect,
        useFlexLayout
    );

    const renderTbody = () => {
        if (!data.length) {
            return (
                <div className='m-auto h-full flex justify-center items-center'>
                    <NoContentWithImage content={emptyContent} img={emptyIcon}/>
                </div>
            ) ;
        }
        const element = rows.map((row) => {
            const id_tbody_tr = UUID();
            const tmp: any = { ...row.original };
            prepareRow(row);
            return (
                <tr
                    key={id_tbody_tr}
                    className={classNames('hover:bg-gray-50', rowSelected && rowSelected?.id === tmp.id ? 'bg-gray-50' : '')}
                    {...row.getRowProps()}
                    onClick={() => onClickRow?.(row.original)}
                    onDoubleClick={() => onDoubleClickRow(tmp.id)}
                >
                    {row.cells.map((cell, cellIndex) => {
                        const id_tbody_tr_td = UUID();
                        return (
                            <td
                                key={id_tbody_tr_td}
                                className={cellIndex == 0 && isBorderFirstColum && !noBorder ? 'border-r' : ''}
                                {...cell.getCellProps()}
                            >
                                {cell.render('Cell')}
                            </td>
                        );
                    })}
                </tr>
            );
        });
        if (isTableInfinity)
            return (
                <InfiniteScroll
                    dataLength={data.length}
                    next={() => setPagination({ ...pagination, page: pagination.page + 1 })}
                    hasMore={data.length != pagination.total}
                    loader={
                        data.length < pagination.total && (
                            <p className="w-full text-center flex items-center justify-center">
                                <CircleSpin color="text-primary-500" />
                            </p>
                        )
                    }
                >
                    {element}
                </InfiniteScroll>
            );
        return element;
    };

    return (
        <table className="w-full h-full" {...getTableProps()}>
            <thead>
                {headerGroups.map((headerGroup) => {
                    const id_thead_tr = UUID();
                    return (
                        <tr key={id_thead_tr} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, index) => {
                                const id_thead_tr_th = UUID();
                                return (
                                    <th
                                        key={id_thead_tr_th}
                                        className={
                                            index == 0 && isBorderFirstColum && !noBorder
                                                ? 'border-t-0 border-r border-ooolab_bar_color py-2'
                                                : 'border-t-0 border-ooolab_bar_color py-2'
                                        }
                                        {...column.getHeaderProps()}
                                    >
                                        {column.render('Header')}
                                    </th>
                                );
                            })}
                        </tr>
                    );
                })}
            </thead>
            <tbody {...getTableBodyProps()}>{renderTbody()}</tbody>
        </table>
    );
};

export default Table;
