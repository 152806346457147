import * as React from 'react';
// COMPONENTS
import H5PProvider from 'contexts/H5P/H5PProvider';

import AssignmentView from 'components/AssignmentView';

export interface AssignmentViewPageProps {
    emailParam?: string;
    otpParam?: string;
}
const H5PPage: React.FC<AssignmentViewPageProps> = ({ emailParam, otpParam }) => {
    return (
        <H5PProvider>
            <AssignmentView emailParam={emailParam} otpParam={otpParam} />
        </H5PProvider>
    );
};

export default H5PPage;
