import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipboardCheckIcon } from '@heroicons/react/outline';
import ActionsButton from 'components/ActionsButton';
import { EditLine } from 'assets/icon';
import DialogComponent from 'components/Dialog/DialogComponent';
import courseService from 'services/course.service';
import { useHistory, useParams } from 'react-router-dom';
import { Toastify } from '_shared';
import { ChangeDueDate } from './ChangeDueDate';
import { checkPermission } from 'utils/commonFun';

interface ActionMenuProp {
    refreshData?: Function,
    data?: any;
    hideAction?: string,
    status?: string,
    isDisable?: boolean;
}

const ActionMenu: FC<ActionMenuProp> = ({ data, refreshData, status, isDisable }) => {
    const { t: translator } = useTranslation();
    const [isOpenModalEdit, setOpenModalEdit] = useState(false);
    const params: { id: string, courseId: string } = useParams();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const handleUpdateDueDate = async (value) => {
        if (value) {
            try {
                setLoading(true);
                const response = await courseService.updateAssignment(params?.id, data?.courseId, {
                    id: data.id,
                    end_time: value
                });
                if (response?.data?.error) {
                    setLoading(false);
                    return Toastify.error();
                }
                setOpenModalEdit(false);
                setLoading(false);
                refreshData();
                Toastify.success('Update Due Date Successful!');
            } catch {
                Toastify.error();
            }
        } else {
            setOpenModalEdit(false);
        }
    }
    return (
        <>
            <ActionsButton
                styleOptions="top-0 right-[35px]"
                disable={isDisable}
                menus={[
                    {
                        label: translator("Grade"),
                        icon: <ClipboardCheckIcon className='w-5 h-5' />,
                        action: () => {
                            const url = `/workspace/${params.id}/instructor-dashboard/assignment-center/${data.id}/grade`;
                            const queryParams = new URLSearchParams({ courseId: data?.courseId }).toString();
                            window.open(`${url}?${queryParams}`, '_blank');
                        },
                        isDisabled: !checkPermission('instructorDashboard', 'R'),
                        hide: false
                    },
                    {
                        label: translator("COURSES.EDIT_DUE_DATE"),
                        icon: <EditLine size={20} />,
                        action: () => setOpenModalEdit(true),
                        isDisabled: !checkPermission('instructorDashboard', 'R'),
                        hide: false
                    }
                ]}
            />
            <DialogComponent
                title={'Change Due Date'}
                isOpen={isOpenModalEdit}
                onCloseModal={() => setOpenModalEdit(false)}
                isShowClose={true}
                styles="max-w-[400px] !py-1.5 !px-6"
                child={<ChangeDueDate data={data} onClose={handleUpdateDueDate} loading={loading} />}
            />
        </>
    );
};

export default ActionMenu;
