import { InputField, PhoneInputField } from 'components/InputForm/InputForm';
import { AsyncSelectField, CreatableAsyncSelectField } from 'components/Select';
import { FORM_CONST } from 'constant/form.const';
import { Controller, useForm } from 'react-hook-form';
import Select, { StylesConfig } from 'react-select';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';
import { WorkspaceService } from 'services';
import { ButtonPrimary, Toastify } from '_shared';
import moment from 'moment';
import { USER_ROLE, USER_STATUS, optionsGender } from '../../../constant';
import classService from 'services/class.service';
import Contracts from './Contracts/Contracts';
import { useEffect, useState } from 'react';
import { checkPermission, isEmail } from 'utils/commonFun';
import { ProfileDetail } from './ProfileDetail/ProfileDetail';
import { defaultRole, roleType } from 'components/Management/RolesPermissions/RolesPermissionsContants';
import userService from 'services/user.service';
import { Switch } from '@headlessui/react';
import Properties from '_shared/components/PropertyTypeComponent';
import DatePickerInput from 'components/Management/components/Form/DatePicker';
import { propertyType } from 'components/WorkspaceSetting/Property/components/propertyCommon';

export const customSelectStyle: StylesConfig<any, true> = {
    clearIndicator: (base) => ({ ...base, display: 'none' }),
    indicatorSeparator: (base) => ({ ...base, display: 'none' }),
    dropdownIndicator: (base) => ({ ...base, padding: 4 }),
    control: (base) => ({
        ...base,
        borderColor: '#e6e6e6',
        minHeight: '38px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    }),
};

const formatRole = (data: any) => {
    return (
        <div className="flex items-center text-dark-300">
            <p className="truncate capitalize">{data?.name?.replace('_', ' ')}</p>
        </div>
    );
};

export default function Information({ refreshData, userData, onChangeRoleSuperAdmin }) {
    const {
        handleSubmit,
        control,
        register,
        formState: { errors, isValid, isDirty },
        watch,
        reset,
        setError,
        setValue,
        clearErrors,
    } = useForm({ mode: 'onChange' });
    const { t } = useTranslation();
    const params: any = useParams();
    const workspaceId = params?.id;
    const userId = params?.userId;
    const [roleName, setRoleName] = useState<any>({});
    const [parentInfo, setParentInfo] = useState<any>(null);
    const [data, setData] = useState<any>([]);

    const getLearnerInformation = () => {
        workspaceService
        .getStudentDetailService(workspaceId, userId)
        .then((res) => {
            const formatParentMail = {
                label: res?.data?.parent?.email,
                name: res?.data?.parent?.email,
                value: res?.data?.parent?.email,
            }
            setParentInfo(formatParentMail);
            setData(res?.data);
            const userData = {
                email: res?.data?.email,
                first_name: res?.data?.first_name,
                last_name: res?.data?.last_name,
                dob: res?.data?.dob && new Date(res?.data.dob),
                address: res?.data?.address,
                mobile_phone: res?.data?.mobile_phone,
                parent_name: res?.data?.parent?.display_name,
                parent_email: res?.data?.parent?.email ? formatParentMail : null,
                gender: res?.data?.gender && optionsGender(t)?.find((item) => item?.value === res?.data?.gender),
                company: res?.data?.company && { label: res?.data?.company?.name, value: res?.data?.company?.id },
                parent_first_name: res?.data?.parent?.first_name || null,
                parent_last_name: res?.data?.parent?.last_name || null,
                properties: []
            }

            reset(userData);
        })
    }

    useEffect(() => {
        if (userData?.membership?.type === 'student') {
             getLearnerInformation();
             return;
        }
        reset({
            ...userData,
            user_role_name: { name: USER_ROLE?.[userData?.membership?.user_role?.name] || userData?.membership?.user_role?.name, value: userData?.membership?.user_role?.name },
            languages: userData?.membership?.instructor?.languages?.map(item => ({ label: item, value: item, })),
            certifications: userData?.membership?.instructor?.certifications?.map(item => ({ label: item, value: item, })),
            subjects: userData?.membership?.instructor?.subjects?.map(item => ({ label: item, value: item })),
            biography: userData?.membership?.instructor?.biography || '',
        });
        setRoleName({
            name: userData?.membership?.user_role?.name,
            type: userData?.membership?.user_role?.role_type
        })
    }, [userData])

    const onSubmit = (values) => {
        const propertiesValue = Object.keys(values?.properties)?.map((item: any) => {
            const value = values?.properties?.[item]?.value;
            if (Array.isArray(value)) {
                return {
                    value: value?.map((item) => item?.value),
                    property_id: values?.properties?.[item]?.property_id
                }
            }
            if (values?.properties?.[item]?.property_type === propertyType.date_picker?.value) {
                return {
                    value: moment(value).format('YYYY-MM-DD'),
                    property_id: values?.properties?.[item]?.property_id
                }
            }
            return ({
                value: values?.properties?.[item]?.value,
                property_id: values?.properties?.[item]?.property_id
            })
        })
        
        if (userData?.membership?.type === 'student') {
            const bodyLearner = {
                avatar_url: values?.avatar_url,
                first_name: values?.first_name || null,
                last_name: values?.last_name || null,
                email: values?.email || null,
                parent_name: values?.parent_name || null,
                parent_email: values?.parent_email?.value || null,
                gender: values?.gender?.value || null,
                address: values?.address || null,
                company_id: values?.company?.value || null,
                mobile_phone: values?.mobile_phone || null,
                dob: (values?.dob && moment(values?.dob).format('YYYY-MM-DD')) || null,
                parent_first_name: values?.parent_first_name || null,
                parent_last_name: values?.parent_last_name || null,
                values: propertiesValue?.filter(item => item?.value),
            }
            return classService
                .updateStudentDetailService(params?.id, userId, bodyLearner)
                .then((res) => {
                    Toastify.success();
                    refreshData();
                })
                .catch(() => Toastify.error())
        }

        const bodyUser = {
            first_name: values?.first_name || null,
            last_name: values?.last_name || null,
            email: values?.email || null,
            user_role_name: values?.user_role_name?.value || userData?.membership?.user_role?.name,
            status: userData?.membership?.status,
            mobile_phone: values?.mobile_phone || null,
            instructor: {
                languages: values?.languages?.map(item => item?.value),
                certifications: values?.certifications?.map(item => item?.value),
                subjects: values?.subjects?.map(item => item?.value),
                biography: values?.biography,
            }
        };

        workspaceService.updateWorkspaceMembers(params?.id, params?.userId, bodyUser)
            .then((res) => {
                Toastify.success();
                refreshData();
            })
            .catch((error) => {
                if (error?.body_params?.[0]?.msg === "DUPLICATE_EMAIL") {
                    setError(
                        'email',
                        {
                            message: t("USER.EMAIL_ALREADY_IN_USE")
                        },
                        {
                            shouldFocus: true
                        }
                    )
                    Toastify.error(t("USER.DUPLICATE_EMAIL"))
                }
                else Toastify.error(t("USER.CREATE_USER_FAIL"))
            })
        };

    const getRoles = async (bodyParams?: any) => {
        try {
            const res = await workspaceService.getRoles(workspaceId, bodyParams);
            return {
                items: res?.data?.items || [],
                total: res?.data?.total || 0,
            };
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleLoadRolesOptions = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional.page,
            per_page: 20,
            order: 'desc',
            name: inputValue,
        };
        if (payload?.q) delete payload.q;
        try {
            const { items, total } = await getRoles(payload);
            const newOptions = items?.map((el: any) => ({
                ...el,
                id: el.id,
                label: el.name,
                value: el.name,
            }));
            return {
                options: newOptions,
                hasMore: additional?.page < total / 20,
                additional: {
                    page: additional?.page + 1,
                },
            };
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        if (roleName?.name === defaultRole.superAdmin && roleName?.type === roleType.default && watch('user_role_name')?.value !== defaultRole.superAdmin) {
            userService.preCheckUserWorkspace(params?.id, params?.userId).then((res) => {
                if (res?.data?.allow_change_status) {
                    return;
                }
                else {
                    onChangeRoleSuperAdmin();
                    setValue('user_role_name', {
                        name: USER_ROLE?.[roleName?.name] || roleName?.name, value: roleName?.name
                    })
                }
            }).catch(() => {
                Toastify.error()
            })

        }
    }, [watch('user_role_name')]);

    const fetchParentMails = async (bodyParams?: any) => {
        const res = await WorkspaceService.getWorkspaceMembersService(params.id, bodyParams);
        return {
            items: res?.data?.items,
            total: res?.data?.total,
        };
    };

    const handleLoadParentMails = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional?.page,
            per_page: 20,
            q: inputValue || null,
            type: 'parent',
        };
        if (!payload?.q) delete payload?.q;

        const { items, total } = await fetchParentMails(payload);

        const newOptions = items?.map((el: any) => ({
            id: el?.id,
            label: el?.display_name + ` (${el?.email})`,
            name: el?.display_name,
            value: el?.email,
            firstName: el?.first_name,
            lastName: el?.last_name
        }));

        return {
            options: newOptions,
            hasMore: additional?.page < total / 20,
            additional: {
                page: additional?.page + 1,
            },
        };
    };

    const [isViewAllProperties, setIsViewAllProperties] = useState(false);
   

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} className="max-w-2xl flex flex-col">
                <div className="w-full flex justify-between max-w-2xl col-span-6 mt-3">
                    <h1 className='text-lg font-semibold'>{t("USER.INFORMATION")}</h1>
                    <div className='flex gap-3 items-center'>
                        {userData?.membership?.type === 'student' && (<div className='flex gap-1 items-center'>
                            <p className='text-gray-800 text-sm'>{t("View All Properties")}</p>
                            <Switch 
                                checked={isViewAllProperties}
                                onChange={setIsViewAllProperties}
                                className={`group inline-flex h-6 w-11 items-center rounded-full bg-gray-400 transition  ${isViewAllProperties ? 'bg-primary-500': ''}`}
                                >
                                <span className={`w-5 h-5 translate-x-0 rounded-full bg-white transition ${isViewAllProperties ? 'translate-x-6': ''}`} />
                            </Switch>
                        </div>)}
                        <ButtonPrimary disabled={!isValid || !isDirty || !checkPermission('userDetailsInfomation', 'U')} type="submit">{t("SAVE")}</ButtonPrimary>
                    </div>
                </div>
                
                <div className='flex gap-5'>
                    <InputField
                        name="first_name"
                        maxLength={255}
                        label={t("USER.FIRST_NAME")}
                        errors={errors}
                        className="!w-1/2 mt-5"
                        isRequired
                        placeholder={t("USER.ENTER_FIRST_NAME")}
                        register={register('first_name', {
                            required: t('FORM_CONST.REQUIRED_FIELD'),
                            validate: {
                                shouldNotContainSpace: (value) => (!!value?.trim() ? true : `${t('FORM_CONST.REQUIRED_FIELD')}`),
                            },
                            maxLength: {
                                value: 255,
                                message: t('FORM_CONST.VALIDATE_255_CHARACTERS'),
                            },
                        })}
                        disabled={userData?.membership?.status === USER_STATUS.DEACTIVATE || !checkPermission('userDetailsInfomation', 'U')}
                    />

                    <InputField
                        name="last_name"
                        maxLength={255}
                        label={t("USER.LAST_NAME")}
                        errors={errors}
                        className="!w-1/2 mt-5"
                        isRequired
                        placeholder={t("USER.ENTER_LAST_NAME")}
                        register={register('last_name', {
                            required: t('FORM_CONST.REQUIRED_FIELD'),
                            validate: {
                                shouldNotContainSpace: (value) => (!!value?.trim() ? true : `${t('FORM_CONST.REQUIRED_FIELD')}`),
                            },
                            maxLength: {
                                value: 255,
                                message: t('FORM_CONST.VALIDATE_255_CHARACTERS'),
                            },
                        })}
                        disabled={userData?.membership?.status === USER_STATUS.DEACTIVATE || !checkPermission('userDetailsInfomation', 'U')}
                    />
                </div>

                <div className="mt-5">
                    <InputField
                        label={'Email'}
                        name="email"
                        className="col-span-6"
                        placeholder={'Enter Email'}
                        isRequired={userData?.membership?.type !== 'student' || (userData?.membership?.type === 'student' && !watch('parent_email'))}
                        register={register('email', {
                            required: {
                                value: userData?.membership?.type !== 'student' || (userData?.membership?.type === 'student' && !watch('parent_email')),
                                message: t('FORM_CONST.REQUIRED_FIELD'),
                            },
                            pattern: {
                                value: FORM_CONST.EMAIL_REGEX,
                                message: t('FORM_CONST.EMAIL_VALIDATE'),
                            },
                            maxLength: {
                                value: 255,
                                message: t('FORM_CONST.VALIDATE_255_CHARACTERS'),
                            },
                        })}
                        errors={errors}
                        disabled={userData?.membership?.status === USER_STATUS.DEACTIVATE || !checkPermission('userDetailsInfomation', 'U')}
                    />
                </div>

                {userData?.membership?.type !== 'student' && userData?.membership?.type !== 'parent' && (
                    <div className='mt-5'>
                        <AsyncSelectField
                            control={control}
                            name="user_role_name"
                            placeholder={t("USER.SELECT_ROLE")}
                            isRequired
                            label={t("USER.USER_ROLE")}
                            hideSelectedOptions
                            closeMenuOnSelect={true}
                            errors={errors}
                            loadOptions={handleLoadRolesOptions}
                            formatOptionLabelCustom={formatRole}
                            className={`${watch('user_role_name')?.value === defaultRole.instructor ? 'col-span-3' : 'col-span-6'}  !w-full !border-gray-300`}
                            isDisabled={userData?.membership?.status === USER_STATUS.DEACTIVATE || !checkPermission('userDetailsInfomation', 'U')}
                        />
                        {watch('user_role_name')?.value === defaultRole.instructor && 
                            <div className="col-span-3 !w-full !border-gray-300 text-xs mt-5">
                                <PhoneInputField
                                    name="mobile_phone"
                                    control={control}
                                    label={t('USER.PHONE')}
                                    placeholder={t('USER.PHONE')}
                                    errors={errors}
                                    rules={{
                                        pattern: {
                                            value: FORM_CONST.PHONE_NUMBER,
                                            message: t('FORM_CONST.VALIDATE_PHONE'),
                                        }
                                    }}
                                />
                            </div>
                        }
                    </div>
                )}

                {userData?.membership?.type === 'student' && (
                    <InputField
                        name="address"
                        maxLength={255}
                        label={t("USER.ADDRESS")}
                        register={register('address')}
                        errors={errors}
                        className="col-span-6 !w-full mt-5"
                        placeholder={t("USER.ENTER_ADDRESS")}
                        disabled={userData?.membership?.status === USER_STATUS.DEACTIVATE || !checkPermission('userDetailsInfomation', 'U')}
                    />
                )}

                {userData?.membership?.type === 'student' && (
                    <div className='flex flex-col gap-5 mt-5'>
                        <div className=" col-span-2 text-xs">
                            <label htmlFor="dob" className="text-ooolab_dark_1 mb-1 font-semibold block">
                                {t('USER.DOB')}
                            </label>
                            <div className="w-full h-8 relative">
                                <div className="flex justify-between items-center">
                                    <DatePickerInput
                                        labelCls="text-ooolab_text_username"
                                        isOriginal={true}
                                        control={control}
                                        name="dob"
                                        paddingCls="py-2.5 px-4"
                                        iconCls="top-[10px]"
                                        placeholderText={t('USER.DOB')}
                                        disabled={userData?.membership?.status === USER_STATUS.DEACTIVATE || !checkPermission('userDetailsInfomation', 'U')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-span-2 text-xs">
                            <label htmlFor="gender" className="text-ooolab_dark_1 mb-1 font-semibold block">
                                {t('USER.GENDER')}
                            </label>
                            {/* @ts-ignore */}
                            <Controller
                                control={control}
                                name="gender"
                                {...register('gender')}
                                render={({ field, formState }) => {
                                    return (
                                        // @ts-ignore
                                        <Select
                                            {...field}
                                            {...formState}
                                            placeholder={t('USER.GENDER')}
                                            className="w-full text-ooolab_dark_2 rounded font-normal"
                                            styles={customSelectStyle}
                                            id="gender"
                                            options={optionsGender(t)}
                                            isDisabled={userData?.membership?.status === USER_STATUS.DEACTIVATE || !checkPermission('userDetailsInfomation', 'U')}
                                        />
                                    );
                                }}
                            />
                        </div>

                        <div className='col-span-2 text-xs'>
                            <PhoneInputField
                                name="mobile_phone"
                                control={control}
                                label={t('USER.PHONE')}
                                placeholder={t('USER.PHONE')}
                                errors={errors}
                                rules={{
                                    pattern: {
                                        value: FORM_CONST.PHONE_NUMBER,
                                        message: t('FORM_CONST.VALIDATE_PHONE'),
                                    }
                                }}
                                disabled={userData?.membership?.status === USER_STATUS.DEACTIVATE || !checkPermission('userDetailsInfomation', 'U')}
                            />
                        </div>

                        <Properties 
                            data={data} 
                            formState={{ errors, isValid, isDirty }}
                            control={control}
                            register={register}
                            watch={watch}
                            setValue={setValue}
                            isViewAllProperties={isViewAllProperties}
                        />

                        <div className="pt-5 border-t border-gray-300 col-span-6">
                            <div className="text-sm font-semibold">{t("USER.PARENT_INFORMATION")}</div>
                        </div>

                        <CreatableAsyncSelectField
                            control={control}
                            label={t('USER.EMAIL')}
                            name="parent_email"
                            placeholder={t('USER.ENTER_OR_SEARCH_EMAIL')}
                            closeMenuOnSelect={true}
                            errors={errors}
                            loadOptions={handleLoadParentMails}
                            className="col-span-6"
                            isRequired={!watch('email') ? true : false}
                            onChange={(newOptions) => {
                                setParentInfo(newOptions);
                                setValue('parent_first_name', newOptions?.firstName)
                                setValue('parent_last_name', newOptions?.lastName)
                                clearErrors();
                                const mailTemp = newOptions?.value;
                                if (!!mailTemp) {
                                    if (!isEmail(mailTemp))
                                        setError('parent_email', { message: t('FORM_CONST.EMAIL_VALIDATE') });
                                }
                            }}
                            isClearable={!watch('email') ? false : true}
                            valueAsLabel
                            isMulti={false}
                            isSearchIcon={false}
                            limitSearch={1000}
                            textOptionNew={t("USER.ADD_NEW_PARENT_EMAIL")}
                        />
                        <InputField
                            name="parent_first_name"
                            maxLength={255}
                            label={t("USER.FIRST_NAME")}
                            errors={errors}
                            className="col-span-3 !w-full"
                            placeholder={t('USER.ENTER_FIRST_NAME')}
                            isRequired={!parentInfo?.name ? true : false}
                            register={register('parent_first_name', {
                                required: (watch('parent_email') && !parentInfo?.name) ? t('FORM_CONST.REQUIRED_FIELD') : false,
                                maxLength: {
                                    value: 255,
                                    message: t('FORM_CONST.VALIDATE_255_CHARACTERS'),
                                },
                            })}
                            disabled={(userData?.membership?.status === USER_STATUS.DEACTIVATE || !checkPermission('userDetailsInfomation', 'U') || !!parentInfo?.name || !watch('parent_email'))}
                        />
                        <InputField
                            name="parent_last_name"
                            maxLength={255}
                            label={t("USER.LAST_NAME")}
                            errors={errors}
                            className="col-span-3 !w-full"
                            placeholder={t('USER.ENTER_LAST_NAME')}
                            isRequired={!parentInfo?.name ? true : false}
                            register={register('parent_last_name', {
                                required: (watch('parent_email') && !parentInfo?.name) ? t('FORM_CONST.REQUIRED_FIELD') : false,
                                maxLength: {
                                    value: 255,
                                    message: t('FORM_CONST.VALIDATE_255_CHARACTERS'),
                                },
                            })}
                            disabled={(userData?.membership?.status === USER_STATUS.DEACTIVATE || !checkPermission('userDetailsInfomation', 'U') || !!parentInfo?.name || !watch('parent_email'))}
                        />
                    </div>
                )}


                {(userData?.membership?.user_role?.name === USER_ROLE.instructor || userData?.membership?.user_role?.name === USER_ROLE.teaching_assistant) && (
                    <div className='mt-5'>
                        <ProfileDetail control={control} errors={errors} />
                    </div>
                )}
            </form>
            {(userData?.membership?.type === 'student' || userData?.membership?.type === 'instructor') && (
                <div className='mt-5'>
                    <Contracts userData={userData} />
                </div>
            )}
        </div>
    );
}
