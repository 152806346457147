import * as React from 'react';
// PROVIDER
import CreateWorkspaceProvider from 'contexts/Workspace/CreateWorkspaceProvider';
import GetListOfWorkspaceProvider from 'contexts/Workspace/GetListOfWorkspaceProvider';
import {
    CreateWorkspacePageProps
} from './MyWorkspacePageFn';
import MyWorkspaceForm from 'components/User/MyWorkspaceForm';

const MyWorkspacePage: React.FC<CreateWorkspacePageProps> = ({
    setAuthStorage,
    storageUserInfo,
}) => {
    React.useEffect(() => {
        return () => {
            localStorage.removeItem('google_auth');
        };
    }, []);

    return (
        <CreateWorkspaceProvider>
            <GetListOfWorkspaceProvider>
                <div className={'h-screen md:p-16'}>
                    <div className="flex flex-col items-center h-full bg-white shadow-ooolab_box_shadow_container_2 rounded-ooolab_radius_40px overflow-y-auto">
                        <MyWorkspaceForm
                            setAuthStorage={setAuthStorage}
                            storageUserInfo={storageUserInfo}
                        />
                    </div>
                </div>
            </GetListOfWorkspaceProvider>
        </CreateWorkspaceProvider>
    );
};

export default MyWorkspacePage;
