import { FC, Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { classNames } from 'utils/commonFun';
import { usePopper } from 'react-popper';

type ActionsButtonProps = {
    menus: any[];
    disable?: boolean;
    styleOptions?: string;
    onClick?: () => void;
    className?: any;
    classNameIcon?: any,
    position?: string
};

const ActionMenuResourceTable: FC<ActionsButtonProps> = ({ menus, disable, onClick, className, classNameIcon }) => {
    const isCheckMenu = () => {
        const isCheck = menus?.find((i) => !i?.isDisabled)
        return !isCheck ? true : false
    }

    const [popperElement, setPopperElement] = useState(null);
    const [referenceElement, setReferenceElement] = useState(null);

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom',
        modifiers: [
            {
                name: 'flip',
                options: {
                    allowedAutoPlacements: ['top', 'bottom'], // by default, all the placements are allowed
                },
            },
        ],
    });

    return (
        <Menu as="div" className=" relative inline-block text-left">
            {({ open }) => (
                <>
                    <Menu.Button
                        className={classNames(
                            'w-8 h-8 mr-1 p-2 rounded-full group outline-none',
                            disable || isCheckMenu()
                                ? 'cursor-not-allowed pointer-events-none'
                                : 'hover:bg-ooolab_light_blue_0 active:outline-none hover:text-primary-500',

                            className
                        )}
                        onClick={() => onClick?.()}
                        ref={setReferenceElement}
                    >
                        <DotsVerticalIcon className={`w-4 h-4 ${classNameIcon}`} />
                    </Menu.Button>
                    <Menu.Items
                            ref={setPopperElement}
                            style={styles.popper}
                            {...attributes.popper}
                            className={'!-translate-x-[200px] z-70 absolute !right-[100px] mt-2 w-[200px] divide-y divide-gray-100 rounded-lg bg-white shadow-lg  ring-black ring-opacity-5 focus:outline-none'}
                        >
                            <div className="">
                                {menus.map((item: any, index) => (
                                    !item?.hide ? <Menu.Item key={index}>
                                        {({ active }) => (
                                            <button
                                                type="button"
                                                className={classNames(
                                                    'w-full flex items-center space-x-2  px-4 py-2 text-ooolab_dark_1 text-sm disabled:cursor-not-allowed',
                                                    active ? 'bg-ooolab_blue_0' : '',
                                                    item.isDisabled ? 'hover:bg-ooolab_blue_0' : '',
                                                    item?.class
                                                )}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    item?.action?.();
                                                }}
                                                disabled={item.isDisabled}
                                            >
                                                {item?.icon}
                                                <span>{item?.label}</span>
                                            </button>
                                        )}
                                    </Menu.Item> : null
                                ))}
                            </div>
                        </Menu.Items>
                </>
            )}
        </Menu>
    );
};

export default ActionMenuResourceTable;
