import axios from './../restful.service';
import { REPORT } from 'constant/api.const';

const getOverviewEnrollment = (workspaceId: string, params) => {
    return axios.get(REPORT.getWorkspacesOverview(workspaceId) + '/enrollments', {params});
}

const getOverviewEnrollmentMonthly = (workspaceId: string, params) => {
    return axios.get(REPORT.getWorkspacesOverview(workspaceId) + '/monthly-enrollments', {params});
}
const getOverviewUser = (workspaceId: string, params) => {
    return axios.get(REPORT.getWorkspacesOverview(workspaceId) + '/users', {params});
}

const getAcademicsLearning = (workspaceId: string, params) => {
    return axios.get(REPORT.getWorkspacesAcademics(workspaceId) + '/learning', {params});
}

const getAcademicsResources = (workspaceId: string, params) => {
    return axios.get(REPORT.getWorkspacesAcademics(workspaceId) + '/resources', {params});
}

const getSessionsOverview = (workspaceId: string, params) => {
    return axios.get(REPORT.getWorkspacesSessions(workspaceId) + '/overview', {params});
}

const getSessionsBreakdown  = (workspaceId: string, params) => {
    return axios.get(REPORT.getWorkspacesSessions(workspaceId) + '/breakdown', {params});
}

const getScheduling = (workspaceId: string, params) => {
    return axios.get(REPORT.getWorkspacesSessions(workspaceId) + '/scheduling', {params});
}

export default {
    getOverviewEnrollment,
    getOverviewEnrollmentMonthly,
    getOverviewUser,
    getAcademicsLearning,
    getAcademicsResources,
    getSessionsOverview,
    getSessionsBreakdown,
    getScheduling
}