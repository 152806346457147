import React, { useState } from "react";
import DialogComponent from "components/Dialog/DialogComponent";
import { InputField, TextareaField } from "components/InputForm/InputForm";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SelectField } from "components/Select";
import { TemplateStatus } from "constant/courseTemplate.const";
import { ButtonOutline, ButtonPrimary, Toastify } from "_shared";
import "./index.scss";
import courseTemplateService from "services/courseTemplate.service";
import { useParams } from "react-router-dom";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { ICourse } from "types/Course.type";

export const DialogCourseSaveTemplate = ({course, onClose}: {course: ICourse, onClose: () => void}) => {
    const params: {id: string} = useParams();
    const { t: translator } = useTranslation();
    const [loading, setLoading] = useState(false);
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        reset,
    } = useForm({
        mode: "onSubmit",
    });

    const onSubmit = async (value) => {
        try {
            setLoading(true);
            const response = await courseTemplateService.createCourseASTemplates({
                ...course,
                ...value,
                workspaceId: params?.id,
                status: value?.status?.value
            });
            if (!response?.data?.error) {
                Toastify.success();
                onClose();
                reset();
            } else {
                Toastify.error();
            }
        } catch (error) {
            Toastify.error();
        } finally {
            setLoading(false);
        }
    }

    return <>
        <DialogComponent
            title={translator('COURSE_TEMPLATE.Save_as_Template')}
            isOpen={true}
            onCloseModal={onClose}
            child={
                <form className="saveAsTemplate" onSubmit={handleSubmit(onSubmit)} >
                    <div className="saveAsTemplate-item">
                        <InputField
                            label={translator('COURSE_TEMPLATE.Template_Name')}
                            name="template_name"
                            isRequired
                            maxLength={255}
                            placeholder={translator('COURSE_TEMPLATE.Enter_Template_Name')}
                            register={register('template_name', {
                                required: translator('FORM_CONST.REQUIRED_FIELD'),
                                validate: {
                                    shouldNotContainSpace: (value) => {
                                        return value.trim()?.length ? true : `${translator('FORM_CONST.REQUIRED_FIELD')}`;
                                    },
                                }
                            })}
                            errors={errors}
                            classNameInput="py-2"
                        />
                    </div>
                    <div className="saveAsTemplate-item">
                        <TextareaField
                            label={translator('COURSE_TEMPLATE.Template_Description')}
                            name="template_description"
                            className="col-span-4"
                            placeholder={translator('COURSE_TEMPLATE.Enter_Template_Description')}
                            register={register('template_description')}
                            textLength={getValues("template_description")?.length?.toString()}
                            maxLength={250}
                            isCountter
                        />
                    </div>
                    <div>
                        <SelectField
                            isRequired
                            control={control}
                            label={translator('COURSE_TEMPLATE.Template_Status')}
                            name="status"
                            className="col-span-6 relative justify-end"
                            placeholder={translator('COURSE_TEMPLATE.Select_Template_Status')}
                            closeMenuOnSelect={true}
                            errors={errors}
                            options={[
                                {
                                    label: translator('COURSE_TEMPLATE.' + TemplateStatus[0]),
                                    value: TemplateStatus[0]
                                },
                                {
                                    label: translator('COURSE_TEMPLATE.' + TemplateStatus[1]),
                                    value: TemplateStatus[1]
                                }
                            ]}
                        />
                    </div>
                    <div className="saveAsTemplate-actions">
                        <ButtonOutline type="button" size="M" onClick={() => {
                            reset();
                            onClose();
                        }} >
                            {translator('CANCEL')}
                        </ButtonOutline>
                        <ButtonPrimary type="submit" size="M" onClick={() => { }} disabled={loading} >
                            {loading ?<div className="flex justify-center"><CircleSpin color="text-primary-500" /></div> : 
                            translator('SAVE')}
                        </ButtonPrimary>
                    </div>
                </form>}
        />
    </>
}