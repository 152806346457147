import RouteMasterPage from 'pages/RouteMasterPage';
import LibraryComponent from 'components/Library';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';

export const LibraryPage = () => {
    return (
        <ManagePermissionWrapper>
            <LibraryComponent>
                <RouteMasterPage />
            </LibraryComponent>
        </ManagePermissionWrapper>
    );
};