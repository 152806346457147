
import React from "react";
import { COURSE_SETTING_TAB } from "constant/course.const";
import { useTranslation } from "react-i18next";
import "./SettingSideLeft.scss";

export const SettingSideLeft = ({ name, hasCourse = true, handleChangeTabs }: { name: string, hasCourse?: boolean, handleChangeTabs: (value: string) => void }) => {
    const { t } = useTranslation();
    return (
        <div className="settingSideLeft">
            <div
                className={`settingSideLeft-item ${name == COURSE_SETTING_TAB.course ? 'settingSideLeft-item--active' : ''}`}
                onClick={() => handleChangeTabs(COURSE_SETTING_TAB.course)}>
                {t('COURSES.COURSE')}
            </div>
            <div
                className={`settingSideLeft-item ${name == COURSE_SETTING_TAB.enrollmentType ? 'settingSideLeft-item--active' : ''}`}
                onClick={() => handleChangeTabs(COURSE_SETTING_TAB.enrollmentType)}>
                {t('COURSES.ENROLLMENT_TYPE')}
            </div>
            <div
                className={`settingSideLeft-item ${name == COURSE_SETTING_TAB.scheduling ? 'settingSideLeft-item--active' : ''}`}
                onClick={() => handleChangeTabs(COURSE_SETTING_TAB.scheduling)}>
                {t('COURSES.SESSION')}
            </div>
            <div
                className={`settingSideLeft-item ${name == COURSE_SETTING_TAB.assignment ? 'settingSideLeft-item--active' : ''}`}
                onClick={() => handleChangeTabs(COURSE_SETTING_TAB.assignment)}>
                {t('COURSES.ASSIGNMENT')}
            </div>
            <div
                className={`settingSideLeft-item ${name == COURSE_SETTING_TAB.gradebook ? 'settingSideLeft-item--active' : ''}`}
                onClick={() => handleChangeTabs(COURSE_SETTING_TAB.gradebook)}>
                {t('COURSES.PROGGESS_HISTORY')}
            </div>
            <div
                className={`settingSideLeft-item ${name == COURSE_SETTING_TAB.certificate ? 'settingSideLeft-item--active' : ''}`}
                onClick={() => handleChangeTabs(COURSE_SETTING_TAB.certificate)}>
                {t('COURSES.CERTIFICATE')}
            </div>
            {hasCourse ? <div
                className={`settingSideLeft-item ${name == COURSE_SETTING_TAB.reminder ? 'settingSideLeft-item--active' : ''}`}
                onClick={() => handleChangeTabs(COURSE_SETTING_TAB.reminder)}>
                {t('COURSES.REMINDER')}
            </div> : null}
        </div>
    )
}