import React, { FC } from "react";
import { Link } from "react-router-dom";

interface NavItem {
    name: string;
    url: string;
    tabName: string;
}

interface NavbarTop {
    data: NavItem[],
    actived: string
}

export const NavbarTop:FC<NavbarTop> = ({data, actived}: NavbarTop) => {
    return <>
        <div className="flex border-b border-gray-300 px-5">
            {data.map((item: NavItem) => (
                <Link key={item.tabName} to={item.url} className={`px-3  text-xs font-semibold py-2.5 cursor-pointer
                    ${actived == item.tabName ? 'border-b border-primary-500 text-primary-500' : 'border-transparent text-gray-500'}
                `}>{item.name}</Link>
            ))}
        </div>
    </>
}