export const SET_PASSWORD = {
    REQ_SET_PASSWORD: 'REQ_SET_PASSWORD',

    REQ_UPDATE_PASSWORD_SUCCESS: 'REQ_UPDATE_PASSWORD_SUCCESS',
    REQ_UPDATE_PASSWORD_FAIL: 'REQ_UPDATE_PASSWORD_FAIL',

    REQ_FORGOT_PASSWORD_SUCCESS: 'REQ_FORGOT_PASSWORD_SUCCESS',
    REQ_FORGOT_PASSWORD_FAIL: 'REQ_FORGOT_PASSWORD_FAIL',

    REQ_RESET_PASSWORD_SUCCESS: 'REQ_RESET_PASSWORD_SUCCESS',
    REQ_RESET_PASSWORD_FAIL: 'REQ_RESET_PASSWORD_FAIL',

    REQ_PASSWORD_RESET: 'REQ_PASSWORD_RESET',
};

export const SET_UPDATE_PASSWORD = {
    REQ_SET_PASSWORD: 'REQ_SET_PASSWORD',

    REQ_UPDATE_PASSWORD_SUCCESS: 'REQ_UPDATE_PASSWORD_SUCCESS',
    REQ_UPDATE_PASSWORD_FAIL: 'REQ_UPDATE_PASSWORD_FAIL',

    REQ_FORGOT_PASSWORD_SUCCESS: 'REQ_FORGOT_PASSWORD_SUCCESS',
    REQ_FORGOT_PASSWORD_FAIL: 'REQ_FORGOT_PASSWORD_FAIL',

    REQ_RESET_PASSWORD_SUCCESS: 'REQ_RESET_PASSWORD_SUCCESS',
    REQ_RESET_PASSWORD_FAIL: 'REQ_RESET_PASSWORD_FAIL',

    REQ_PASSWORD_RESET: 'REQ_PASSWORD_RESET',
    REQ_UPDATE_PASSWORD: 'REQ_UPDATE_PASSWORD',
};