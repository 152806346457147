export const SettingsIcon = ({
    width = '20px',
    height = '20px',
    fill = 'currentColor',
}: {
    width?: string;
    height?: string;
    fill?: string;
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.99984 3.33317C9.5396 3.33317 9.1665 3.70627 9.1665 4.1665C9.1665 5.57672 7.4615 6.28296 6.46432 5.28578C6.13889 4.96035 5.61125 4.96035 5.28581 5.28578C4.96038 5.61122 4.96038 6.13886 5.28581 6.46429C6.28302 7.46151 5.57669 9.1665 4.1665 9.1665C3.70627 9.1665 3.33317 9.5396 3.33317 9.99984C3.33317 10.4601 3.70627 10.8332 4.1665 10.8332C5.5768 10.8332 6.28287 12.5383 5.28576 13.5354C4.96032 13.8608 4.96032 14.3885 5.28576 14.7139C5.6112 15.0393 6.13883 15.0393 6.46427 14.7139C7.46142 13.7167 9.1665 14.4229 9.1665 15.8332C9.1665 16.2934 9.5396 16.6665 9.99984 16.6665C10.4601 16.6665 10.8332 16.2934 10.8332 15.8332C10.8332 14.4229 12.5382 13.7167 13.5354 14.7139C13.8608 15.0393 14.3885 15.0393 14.7139 14.7139C15.0393 14.3884 15.0393 13.8608 14.7139 13.5354C13.7168 12.5382 14.4229 10.8332 15.8332 10.8332C16.2934 10.8332 16.6665 10.4601 16.6665 9.99984C16.6665 9.5396 16.2934 9.1665 15.8332 9.1665C14.423 9.1665 13.7166 7.46155 14.7139 6.46431C15.0393 6.13887 15.0393 5.61124 14.7139 5.2858C14.3884 4.96036 13.8608 4.96036 13.5353 5.2858C12.5381 6.283 10.8332 5.57667 10.8332 4.1665C10.8332 3.70627 10.4601 3.33317 9.99984 3.33317ZM7.50664 3.98069C7.6017 2.68675 8.68162 1.6665 9.99984 1.6665C11.3181 1.6665 12.398 2.68676 12.493 3.9807C13.4752 3.13298 14.9602 3.17517 15.8924 4.10729C16.8245 5.03941 16.8667 6.52446 16.0189 7.50663C17.3129 7.60168 18.3332 8.68161 18.3332 9.99984C18.3332 11.3181 17.3129 12.398 16.019 12.493C16.8667 13.4752 16.8245 14.9603 15.8924 15.8924C14.9603 16.8245 13.4752 16.8667 12.493 16.0189C12.398 17.3129 11.3181 18.3332 9.99984 18.3332C8.68161 18.3332 7.60168 17.3129 7.50664 16.0189C6.52446 16.8667 5.03938 16.8245 4.10725 15.8924C3.17512 14.9603 3.13294 13.4752 3.98069 12.493C2.68675 12.398 1.6665 11.318 1.6665 9.99984C1.6665 8.68161 2.68678 7.60168 3.98074 7.50663C3.13299 6.52446 3.17518 5.0394 4.1073 4.10727C5.03942 3.17516 6.52446 3.13296 7.50664 3.98069Z"
                fill={fill}
            />
            <path
                d="M9.99984 8.33317C9.55781 8.33317 9.13389 8.50877 8.82133 8.82133C8.50877 9.13389 8.33317 9.55781 8.33317 9.99984C8.33317 10.4419 8.50877 10.8658 8.82133 11.1783C9.13389 11.4909 9.55781 11.6665 9.99984 11.6665C10.4419 11.6665 10.8658 11.4909 11.1783 11.1783C11.4909 10.8658 11.6665 10.4419 11.6665 9.99984C11.6665 9.55781 11.4909 9.13389 11.1783 8.82133C10.8658 8.50877 10.4419 8.33317 9.99984 8.33317ZM7.64281 7.64281C8.26794 7.01769 9.11578 6.6665 9.99984 6.6665C10.8839 6.6665 11.7317 7.01769 12.3569 7.64281C12.982 8.26794 13.3332 9.11578 13.3332 9.99984C13.3332 10.8839 12.982 11.7317 12.3569 12.3569C11.7317 12.982 10.8839 13.3332 9.99984 13.3332C9.11578 13.3332 8.26794 12.982 7.64281 12.3569C7.01769 11.7317 6.6665 10.8839 6.6665 9.99984C6.6665 9.11578 7.01769 8.26794 7.64281 7.64281Z"
                fill="currentColor"
            />
        </svg>
    );
};


