import React, { useEffect, useState } from "react";
import DialogComponent from "components/Dialog/DialogComponent";
import { useTranslation } from "react-i18next";
import SelectBox from "_shared/components/Select";
import { useForm } from "react-hook-form";
import absenceRequestService from "services/absenceRequest.service";
import { useParams } from "react-router-dom";
import { ButtonOutline, ButtonPrimary } from "_shared";
import workspaceService from "services/workspace.service";

export const AddRecipient = ({ ...props }) => {
    const params: { id: string } = useParams();
    const { hasOpen, onClose, onRefresh } = props;
    const { t: translator } = useTranslation();
    const [inputSearch, setInputSearch] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [paginationRecipients, setPaginationRecipients] = useState<any>();
    const [recipients, setRecipients] = useState<any[]>();
    const {
        control,
        watch,
        formState: { errors },
        getValues,
        setValue
    } = useForm();

    const getRecipients = async (page: number = 1) => {
        try {
            const param = {
                page: page,
                per_page: 20,
                q: inputSearch,
                workspace_id: params?.id,
                status: ['registered', 'invite', 'active'],
                location: 'clms',
                exclude_user_role_name: ['Instructor']
            };
            if (!param.q) delete param.q;
            const res: any = await workspaceService.getWorkspaceMembersService(params?.id, param);
            const listFormated = res?.data?.items?.map((item) => {
                return {
                    ...item,
                    value: item?.membership?.id,
                    name: item?.display_name
                }
            });
            if (page === 1) setRecipients(listFormated);
            if (page > 1) {
                setRecipients([...recipients, ...listFormated]);
            }
            setPaginationRecipients({ total: res?.data?.total, page: res?.data?.page});
        } catch (error) {

        }
    }

    const handleAdd = async () => {
        try {
            setLoading(true);
            await absenceRequestService.addAbsenceRequestRecipients(params?.id, {
                membership_ids: getValues('membership_ids')?.map(item => item.value)
            });
            onRefresh?.();
            onClose?.();
            setValue('membership_ids', []);
        } catch (error) {
            
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getRecipients(1);
    }, [inputSearch]);

    return <>
        <DialogComponent
            title={translator('Add Recipient(s)')}
            isOpen={hasOpen}
            onCloseModal={onClose}
            styles="max-w-lg"
            child={<>
                <label htmlFor="membership_ids" className="text-ooolab_dark_1 text-sm mb-1 font-semibold leading-ooolab_24px block mt-4">
                    {translator('Select Recipient(s)')} <span className="text-red-500">*</span>
                </label>
                <SelectBox
                    control={control}
                    name="membership_ids"
                    placeholder={translator('Select Recipient(s)')}
                    isRequired
                    placeholderSearchInput={translator('Search Name/Email')}
                    pagination={paginationRecipients}
                    onSearch={(value) => setInputSearch(value)}
                    getData={getRecipients}
                    data={recipients}
                    numberOfItemDisplayed={1}
                    isMulti={true}
                    errors={errors}
                    value={watch('membership_ids') || []}
                    loading={loading}
                    isShowSearchIcon={false}
                />
                <div className="flex justify-center gap-4 mt-5">
                    <ButtonOutline type="button" onClick={onClose}>
                        {translator('Cancel')}
                    </ButtonOutline>
                    <ButtonPrimary type="button" 
                        onClick={handleAdd}
                        disabled={!watch('membership_ids')}
                        loading={loading}
                    >
                        {translator('Add')}
                    </ButtonPrimary>
                </div>
            </>}>
        </DialogComponent>
    </>
}