
export default function AssignmentIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_760_18598)">
                <path
                    d="M16.6667 1.6665C17.1267 1.6665 17.5 2.03984 17.5 2.49984V5.63067L10.0008 13.1307L9.99583 16.6623L13.5342 16.6673L17.5 12.7015V17.4998C17.5 17.9598 17.1267 18.3332 16.6667 18.3332H3.33333C2.87333 18.3332 2.5 17.9598 2.5 17.4998V2.49984C2.5 2.03984 2.87333 1.6665 3.33333 1.6665H16.6667ZM18.1483 7.33984L19.3267 8.51817L12.845 14.9998L11.665 14.9982L11.6667 13.8215L18.1483 7.33984ZM10 9.99984H5.83333V11.6665H10V9.99984ZM12.5 6.6665H5.83333V8.33317H12.5V6.6665Z"
                    fill="#F64B7F"
                />
            </g>
            <defs>
                <clipPath id="clip0_760_18598">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
