import { FC, useMemo, useEffect, useState } from 'react';
import Table from 'components/Management/components/table';
import { classNames } from 'utils/commonFun';
import dayjs from 'dayjs';
import moment from 'moment';
import  { NoContentWithImage } from 'components/NoContent/NoContent';
import ModalCreate from 'components/Management/components/ModalCreateWrapper';
import Tooltip from 'components/Tooltip';
import TooltipInfo from 'components/UiComponents/TooltipInfo';

interface TableRecordOfWorkProps {
    dataTable: any;
    translator: (key: string) => string;
    handleUpdateRecord: (record: any) => void;
    tz: string;
}

const TableRecordOfWork: FC<TableRecordOfWorkProps> = ({
    dataTable,
    translator,
    handleUpdateRecord,
    tz
}) => {
    const [data, setData] = useState<any>([])

    const RenderTableHeader = ({
        label,
        classes,
    }: {
        label: string;
        classes?: string;
    }) => (
        <p
            className={classNames(
                'text-sm text-ooolab_dark_1 leading-6 font-semibold text-left px-2',
                classes
            )}
        >
            {translator(label)}
        </p>
    );

    const RenderTableCell = ({ value, classes }: { value: string; classes?: string }) => (
        !!value 
            ? <p className={classNames('text-sm text-ooolab_dark_1 leading-6 font-normal text-left px-2 my-4 truncate-2-lines' , classes)}>
                {value}
            </p>
            : null
    );

    const initialState = {
        hiddenColumns: ['lesson'],
    };

    useEffect(() => {
        const dateTemp = dataTable.map(el => ({
            ...el,
            display_datetime: {
                date: dayjs(el.end_time).tz(tz).format('MM/DD/YYYY'),
                time: dayjs(el.end_time).tz(tz).format('HH:mm'),
            }
        }))
        setData(dateTemp)
    }, [tz, dataTable])
    
    const columns = useMemo(
        () => [
            { accessor: 'lesson' },
            {
                Header: () => <RenderTableHeader label="SESSION_ID" />,
                Cell: ({ row }) => <RenderTableCell value={row.values.id} />,
                accessor: 'id',
                width: 100,
            },
            {
                Header: () => <RenderTableHeader label="LESSON_DATE" />,
                Cell: ({ row }) => {
                    return (
                        <div className=' flex p-2 py-4'>
                            <p className='text-sm text-ooolab_dark_1 leading-6 font-normal text-left'>{moment(row.values?.display_datetime?.date).lang('en').format('DD MMM  YYYY')}</p>
                            <p className='text-sm ml-1 text-ooolab_dark_1 leading-6 font-normal text-left'>{row.values?.display_datetime?.time}</p>
                        </div>
                    )
                    }
                ,
                accessor: 'display_datetime',
                width: 140,
            },
            {
                Header: () => <RenderTableHeader label="STUDENT_NAME" />,
                Cell: ({ row }) => (
                        <div className='flex items-center translate-x-3 px-2 py-4'>
                            {
                                row.values.students?.slice(0,6).map(student => {
                                    return (
                                        <div key={student.id} className="-ml-3 relative">
                                            <Tooltip mlClass='left-0' title={student?.display_name}>
                                                <img className='bg-dark-100 rounded-full border-2 border-white w-7 h-7 object-cover object-center z-0' src={student?.avatar_url} alt="" />
                                            </Tooltip>     
                                        </div>
                                    )
                                })
                            }
                            {
                                (row.values.students?.length > 6) && 
                                    <div onClick={() => handleShowPopup(row?.values?.students, "STUDENTS")} className='cursor-pointer w-7 h-7 rounded-full bg-dark-100 text-xs flex justify-center items-center -ml-3'>+{row?.values?.students?.length - 6}</div>
                            }
                        </div>
                ),
                accessor: 'students',
                width: 140,
            },
            {
                Header: () => <div className='pl-2 border-ooolab_bar_color'><RenderTableHeader label="INSTRUCTOR_NAME" /></div>,
                Cell: ({ row }) => (
                    <div className='flex items-center translate-x-3 px-2 py-4'>
                        {
                            row.values.instructors?.slice(0,6).map(instructor => {
                                return (
                                    <div key={instructor.id} className="-ml-3 relative">
                                        <Tooltip mlClass='left-0' title={instructor?.display_name}>
                                            <img className='bg-dark-100 rounded-full border-2 border-white w-7 h-7 object-cover object-center  z-0' src={instructor?.avatar_url} alt="" />
                                        </Tooltip>
                                    </div>
                                )
                            })
                        }
                        {
                            (row?.values?.instructors?.length > 6) && 
                                <div onClick={() => handleShowPopup(row?.values?.instructors, "INSTRUCTORS")} className='cursor-pointer min-w-[28px] h-7 rounded-full bg-dark-100 text-xs flex justify-center items-center -ml-3'>+{row?.values?.instructors?.length - 6}</div>
                        }
                    </div>
                ),
                accessor: 'instructors',
                width: 140,
            },
            {
                Header: () => <RenderTableHeader label="MATERIALS_COVERED" />,
                Cell: ({ row }) => (
                    <div className='relative'>
                        <TooltipInfo 
                            info={
                            <div className='whitespace-pre-wrap w-80 p-3 text-xs text-dark-100'>
                                {row.values?.learning_materials_used}
                            </div>
                        }>
                            <RenderTableCell value={row.values?.learning_materials_used} />
                        </TooltipInfo>
                    </div>
                ),
                accessor: 'learning_materials_used',
                width: 120,
            },

            {
                Header: () => <RenderTableHeader label="INSTRUCTOR_FEEDBACK" />,
                Cell: ({ row }) => (
                    <div className='relative'>
                        <TooltipInfo 
                        info={
                        <div className='whitespace-pre-wrap w-80 p-3 text-xs text-dark-100'>
                            {row.values?.ped_notes}
                        </div>
                        }>
                            <RenderTableCell value={row.values?.ped_notes} />
                        </TooltipInfo>
                    </div>
                ),
                accessor: 'ped_notes',
                width: 140,
            },
            {
                Header: () => <RenderTableHeader label="NOTE_FOR_INSTRUCTOR" />,
                Cell: ({ row }) => (
                    <div className='relative'>
                        <TooltipInfo 
                        info={
                        <div className='whitespace-pre-wrap w-80 p-3 text-xs text-dark-100'>
                            {row.values?.instructor_notes}
                        </div>
                        }>
                            <RenderTableCell value={row.values?.instructor_notes} />
                        </TooltipInfo>
                    </div>
                ),
                accessor: 'instructor_notes',
                width: 140,
            },
            {
                Header: () => <RenderTableHeader label="ACTION" />,
                Cell: ({ row }) => (
                    <div
                        className="text-sm text-primary-500 leading-6 font-normal text-left cursor-pointer px-2 py-4"
                        onClick={() => handleUpdateRecord(row?.original)}
                    >
                        Update
                    </div>
                ),
                accessor: 'action',
                width: 50,
            },
        ],
        [tz]
    );

    const [dataPopup, setDataPopup] = useState({
        list: [],
        title: ""
    })

    const [isOpenPopup, setOpenPopup] = useState<boolean>(false)

    const handleShowPopup = (list, title: string) => {
        setOpenPopup(true)
        setDataPopup({list, title})
    }

    if (data?.length === 0) {
        return (
            <div className='w-full h-full flex justify-center items-center'>
                <NoContentWithImage content='No PED Cards' />
            </div>
        )
    }

    return (
        <div>
            <Table data={data} columns={columns} initialState={initialState} />
            <ModalCreate 
                isOpen={isOpenPopup}
                onClose={() => setOpenPopup(false)}
                title={translator(dataPopup?.title)}
                styles='rounded-xl'
                children={ (
                    <div className=' px-4 max-h-ooolab_h_100 overflow-auto'>
                        <div className=' flex gap-2 mt-5 flex-col'>
                            {dataPopup?.list?.map((el) => {
                                return (
                                    <div key={el?.id} className="flex items-center ">
                                        <img className=' bg-dark-100 rounded-full border-2 border-white w-7 h-7 object-cover object-center -ml-3' src={el?.avatar_url} alt="" />
                                        <span className=' text-truncate'>{el?.display_name}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            />
                
        </div>
    );
};

export default TableRecordOfWork;
