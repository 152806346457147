import React, { useEffect, useState } from 'react';
import { PlusIcon } from '@heroicons/react/outline';
import { useHistory, useParams } from 'react-router-dom';
import { ButtonOutline, ButtonPrimary } from '_shared';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import { accountCreditType } from 'constant/workspace.const';
import workspaceService from 'services/workspace.service';
import DialogComponent from 'components/Dialog/DialogComponent';
import Spinner from 'components/Spinner';
import FooterPage from 'components/Footer/FooterPage';
import { TableCreditAccountDetail } from './TableCreditAccountDetail';
import { ModalDepositWithdraw } from './ModalDepositWithdraw';
import { ModalTransactionDetail } from './ModalTransactionDetail';
import { SubBreadcrumb } from 'components/Breadcrumb/SubBreadcrumb';

export const CreditAccountDetail = ({ userData, translator, onViewCreditDetail, creditId }) => {
    const history = useHistory();
    const params: any = useParams();

    const workspaceId = params?.id;
    const userId = params?.userId;

    const [loading, setLoading] = useState<boolean>(false);
    const [creditDetail, setCreditDetail] = useState(null);
    const [data, setData] = useState([]);
    const [indexPage, setIndexPage] = useState('0-0');
    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'updated_at',
        total: 0,
    });
    const [selectedDepositWithdraw, setSelectedDepositWithdraw] = useState<'deposit' | 'withdraw'>(null);
    const [selectedRow, setSelectedRow] = useState(null);

    const fetchCreditDetail = () => {
        workspaceService.getCreditAccountsById(workspaceId, creditId).then((res) => setCreditDetail(res?.data || null));
    };

    const fetchTransactions = (page: number | string = 1) => {
        setLoading(true);

        const payload = {
            ...pagination,
            page,
        };
        delete payload.total;

        workspaceService
            .getCreditTransactions(workspaceId, creditId, payload)
            .then((res) => {
                if (res?.data) {
                    const { items, page, per_page, order, total, sort_by } = res?.data;
                    setData(items);
                    setPagination({ page, per_page, order, total, sort_by });
                    setIndexPage(getIndexPage(page, per_page, items?.length));
                }
            })
            .catch()
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (creditId) {
            fetchCreditDetail();
            fetchTransactions();
        }
    }, [creditId]);

    return (
        <>
            <div>
                <SubBreadcrumb
                    pageNameDefault={translator('USER.LEFT_MENU.CREDIT_ACCOUNTS', true)}
                    onClickPageNameDefault={() => {
                        onViewCreditDetail();
                        history.push(`/workspace/${workspaceId}/management/users/${userId}?tab=credit-accounts`);
                    }}
                    pageClassName='font-semibold'
                    pages={[
                        {
                            name: creditDetail?.name,
                            href: '',
                            className: 'truncate',
                        },
                    ]}
                />
                <div className="-mx-5 border-b border-gray-300 p-5 flex items-center justify-between space-x-1 text-base">
                    <div className="flex items-center space-x-4">
                        <p>
                            <span className="text-gray-500 mr-1">{translator('ACCOUNT_NUMBER')}:</span>
                            <span>{creditDetail?.id}</span>
                        </p>
                        <p className="w-[1px] h-4 border-r border-gray-300" />
                        <p>
                            <span className="text-gray-500 mr-1">{translator('ACCOUNT_TYPE')}:</span>
                            <span>
                                {accountCreditType?.[creditDetail?.account_type]?.key
                                    ? translator(accountCreditType?.[creditDetail?.account_type]?.key)
                                    : ''}
                            </span>
                        </p>
                        <p className="w-[1px] h-4 border-r border-gray-300" />
                        <p>
                            <span className="text-gray-500 mr-1">{translator('CREDIT_BALANCE')}:</span>
                            <span>{creditDetail?.credit_balance}</span>
                        </p>
                    </div>
                    <div className="flex items-center space-x-2">
                        {checkPermission('userDetailsCreditAccounts', 'U') && (
                            <ButtonOutline
                                onClick={() => setSelectedDepositWithdraw('withdraw')}
                                type="button"
                                disabled={creditDetail?.credit_balance <= 0}
                                className="disabled:opacity-50"
                            >
                                {translator('WITHDRAW')}
                            </ButtonOutline>
                        )}
                        {checkPermission('userDetailsCreditAccounts', 'U') && (
                            <ButtonPrimary type="button" onClick={() => setSelectedDepositWithdraw('deposit')}>
                                <PlusIcon className='w-4 h-4 text-white' />
                                {translator('DEPOSIT')}
                            </ButtonPrimary>
                        )}
                    </div>
                </div>
            </div>
            <div className="pt-5">
                <div className="h-[calc(100vh-410px)] custom-scrollbar overflow-auto">
                    {loading ? (
                        <div className="h-[calc(100vh-417px)] flex justify-center items-center">
                            <Spinner />
                        </div>
                    ) : (
                        <TableCreditAccountDetail
                            workspaceId={workspaceId}
                            userId={userId}
                            dataTable={data}
                            translator={translator}
                            onViewDetail={(values) => setSelectedRow(values)}
                        />
                    )}
                </div>
                <FooterPage
                    indexPage={indexPage}
                    total={pagination.total}
                    per_page={pagination.per_page}
                    page={pagination.page}
                    callback={(page) => fetchTransactions(page)}
                />
            </div>
            <DialogComponent
                isOpen={!!selectedDepositWithdraw}
                onCloseModal={() => setSelectedDepositWithdraw(null)}
                title={translator(selectedDepositWithdraw === 'deposit' ? 'DEPOSIT_CREDITS' : 'WITHDRAW_CREDITS')}
                styles="max-w-md"
                child={
                    <ModalDepositWithdraw
                        userData={userData}
                        translator={translator}
                        type={selectedDepositWithdraw}
                        data={creditDetail}
                        onClose={() => setSelectedDepositWithdraw(null)}
                        onConfirm={() => {
                            setSelectedDepositWithdraw(null);
                            fetchCreditDetail();
                            fetchTransactions(pagination.page);
                        }}
                    />
                }
            />
            <DialogComponent
                isOpen={!!selectedRow}
                onCloseModal={() => setSelectedRow(null)}
                title={translator('TRANSACTED_DETAILS')}
                styles="max-w-lg"
                child={
                    <ModalTransactionDetail
                        userData={userData}
                        translator={translator}
                        workspaceId={workspaceId}
                        creditDetail={creditDetail}
                        transactionDetail={selectedRow}
                    />
                }
            />
        </>
    );
};
