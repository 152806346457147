import axios from './restful.service';
import { NOTIFICATIONS } from 'constant/api.const';

const getListNotifications = (
    workspaceId: string,
    params?: Record<any, any>
) => {
    if (workspaceId) {
        const url = NOTIFICATIONS.getListNotifications(workspaceId);
        if (params.q === '') delete params.q;
        return axios.get(url, { params });
    }
};

const getDetailsNotification = (
    workspaceId: string,
    notificationId: string
) => {
    if (workspaceId) {
        const url = NOTIFICATIONS.updateNotifications(
            workspaceId,
            notificationId
        );
        return axios.get(url);
    }
};

const updateNotification = (
    workspaceId: string,
    notificationId: string,
    bodyData: any
) => {
    if (workspaceId) {
        const url = NOTIFICATIONS.updateNotifications(
            workspaceId,
            notificationId
        );
        return axios.put(url, bodyData);
    }
};

const deleteNotification = (workspaceId: string, notificationId: string) => {
    if (workspaceId) {
        const url = NOTIFICATIONS.updateNotifications(
            workspaceId,
            notificationId
        );
        return axios.delete(url);
    }
};

const createNotification = (workspaceId: string, bodyData: any) => {
    if (workspaceId) {
        const url = NOTIFICATIONS.getListNotifications(workspaceId);
        return axios.post(url, bodyData);
    }
};

const getReceivers = (workspaceId: string, notificationId: string, params) => {
    const url = `/workspaces/${workspaceId}/custom-notifications/${notificationId}/receivers`
    return axios.get(url, { params });
}

export default {
    getListNotifications,
    getDetailsNotification,
    updateNotification,
    deleteNotification,
    createNotification,
    getReceivers
};
