import { FC, useState, useEffect, useMemo } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { ExternalLinkIcon } from '@heroicons/react/solid';
import { NavbarTop } from '../components/NavbarTop/NavbarTop';
import { StudentDashboardOveriew } from './StudentDashboardOveriew';
import { StudentDashboardAcademics } from './StudentDashboardAcademics';
import { StudentDashboardAssignments } from './StudentDashboardAssignments';
import { StudentDashboardSessions } from './StudentDashboardSessions';
import learnerReportService from 'services/reports/learner-report.service';

type SpecificStudentDashboardProps = {};

const SpecificStudentDashboard: FC<SpecificStudentDashboardProps> = () => {
    
    const params: { id: string; studentId: string } = useParams();
    const location: any = useLocation();
    const { t } = useTranslation();
    const [dataOverview, setDataOverview] = useState<any>();

    const workspaceId = params.id;
    const studentId = params.studentId;

    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);
    const [tabActive, setTabActive] = useState<string>('overview');

    const search = useLocation()?.search;
    const type = new URLSearchParams(search).get('type'); 
    const tab = new URLSearchParams(search).get('tab'); 

    const fetchOverviews = (params?: any) => {
        if (type === 'learning-group') {
            learnerReportService.getLearnerGroup(workspaceId, studentId)
                .then((res) => setDataOverview({
                    ...res.data,
                    display_name: res.data?.name
                }))
                .finally();
        } else {
            learnerReportService.getLearnerDetail(workspaceId, studentId)
            .then((res) => setDataOverview(res.data))
            .finally();
        }
        
    };
    const navsTop = useMemo(() => {
        return [
            {
                name: translator('Overview'),
                url: `/workspace/${params.id}/report/dashboard-learner/${params.studentId}?tab=overview${type === 'learning-group' ? '&type=learning-group': ''}`,
                tabName: 'overview'
            },
            {
                name: translator('Academics'),
                url: `/workspace/${params.id}/report/dashboard-learner/${params.studentId}?tab=academics${type === 'learning-group' ? '&type=learning-group': ''}`,
                tabName: 'academics'
            },
            {
                name: translator('Assignments'),
                url: `/workspace/${params.id}/report/dashboard-learner/${params.studentId}?tab=assignments${type === 'learning-group' ? '&type=learning-group': ''}`,
                tabName: 'assignments'
            },
            {
                name: t('SESSION.SESSIONS'),
                url: `/workspace/${params.id}/report/dashboard-learner/${params.studentId}?tab=sessions${type === 'learning-group' ? '&type=learning-group': ''}`,
                tabName: 'sessions'
            }
        ]
    },[params, t]);

    useEffect(() => {
        fetchOverviews();
    }, []);

    useEffect(() => {
        if (location?.search) {
            setTabActive(tab);
        } else {
            setTabActive('overview');
        }
    }, [location]);

    return (
        <div className="w-full">
            <PageBreadcrumb
                pageNameDefault={translator('REPORTS')}
                pages={[
                    { name: translator("Learner Dashboard"), href: `/workspace/${workspaceId}/report/dashboard-learner`, className: '' },
                    { name: location?.state?.student_name || dataOverview?.display_name, href: '', className: '' },
                ]}
                containerClassName=" z-10 sticky top-0 w-full"
            />
            <div>
                <div className='cursor-pointer'>
                    <h1 className="flex items-center p-5 text-xl text-gray-800 font-semibold">
                        {dataOverview?.display_name}
                        <Link to={`${type !== 'learning-group' ? `/workspace/${workspaceId}/management/users/${studentId}` : `/workspace/${workspaceId}/management/learning-groups/${studentId}`}`}>
                            <ExternalLinkIcon className='w-6 h-6 ml-2 text-primary-500' />
                        </Link>
                    </h1>
                    <NavbarTop data={navsTop} actived={tabActive} />
                </div>
                <div className='p-5 bg-gray-50 min-h-[calc(100vh-126px)]'>
                    {tabActive == 'overview' && <StudentDashboardOveriew />}
                    {tabActive == 'academics' && <StudentDashboardAcademics /> }
                    {tabActive == 'assignments' && <StudentDashboardAssignments /> }
                    {tabActive == 'sessions' && <StudentDashboardSessions />}
                </div>
            </div>
        </div>
    );
};

export default SpecificStudentDashboard;
