import { Dispatch, createContext } from 'react';
// STATES
// TYPES
import { initRewardsState, RewardsState } from 'types/Rewards.type';

export const RewardsContext = createContext<{
    rewardsState: RewardsState;
    dispatch: Dispatch<any>;
}>({
    rewardsState: initRewardsState,
    dispatch: () => null,
});
