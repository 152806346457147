import { Fragment, useRef, FC, useState } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import notificationsService from 'services/notifications.service';
import { useHistory } from 'react-router-dom';
import { Toastify } from '_shared';
import { useTranslation } from 'react-i18next';

type DeleteNotificationProps = {
    open: boolean;
    data?: any;
    onCloseModal: () => void;
};

const DeleteNotification: FC<DeleteNotificationProps> = ({
    open,
    data,
    onCloseModal,
}) => {
    const cancelButtonRef = useRef(null);
    const history = useHistory();
    const { t: translator } = useTranslation();

    const params: { id: string; notificationId: string } = useParams();
    const [loading, setLoading] = useState(false);

    const onDelete = async () => {
        setLoading(true);
        await notificationsService
            .deleteNotification(params.id, data?.id)
            .then((res) => {
                Toastify.error();
                setTimeout(() => {
                    history.push(
                        `/workspace/${params.id}/management/notification`
                    );
                }, 500);
            })
            .catch(() => Toastify.error());
        setLoading(false);
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-70 inset-0 overflow-y-hidden"
                initialFocus={cancelButtonRef}
                open={open}
                onClose={onCloseModal}
            >
                <div className="flex i justify-center min-h-screen p-4 pb-20 text-center items-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 dialog-background  bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <div className="max-w-[360px] inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle w-2/5 relative">
                            <div>
                                <div className="px-4 pt-3">
                                    <div className="flex w-full space-x-1 items-center">
                                        <span className="text-ooolab_dark_1 font-semibold text-sm w-full">
                                            {translator('NOTIFICATION_CUSTOM.DELETE_NOTICATION')}
                                        </span>
                                        <button
                                            className="w-8 h-8 ml-auto text-ooolab_text_bar_inactive hover:bg-ooolab_light_blue_0 hover:text-primary-500 rounded-full transition p-2"
                                            onClick={onCloseModal}
                                        >
                                            <XIcon fill="none" />
                                        </button>
                                    </div>
                                </div>
                                <div className="font-inter ">
                                    <div className="px-8 pt-2.5 pb-4 align-middle inline-block min-w-full text-ooolab_dark_1 w-full">
                                        <p className="text-sm whitespace-pre-wrap break-words font-semibold ">
                                        {translator('NOTIFICATION_CUSTOM.ARE_YOU_SURE_DELETE')} "{data?.title}" ?
                                        </p>
                                        <div className="flex justify-center items-center mt-8">
                                            <button
                                                type="button"
                                                onClick={onDelete}
                                                className="text-xs min-w-[90px] bg-ooolab_dark_300 text-white rounded-ooolab_radius_4px font-semibold flex justify-center items-center shadow-ooolab_sched_button text-ooolab_sm px-2 py-2"
                                            >
                                                {loading ? (
                                                    <CircleSpin
                                                        color="text-[#ffffff]"
                                                        className="mx-auto"
                                                    />
                                                ) : (
                                                    'Delete Notification'
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default DeleteNotification;
