import React, { FC } from 'react';
import { Row, Column } from 'react-table';
import Table, { RenderTableHeader, RenderTableCell, LinkCustom } from 'components/V2/Table/TableCustom';
import { checkPermission } from 'utils/commonFun';
import StatusCheckIcon from 'assets/icon/StatusCheckIcon';
import './table.scss'
import ActionMenu from './ActionMenu';

type TableMemberProps = {
    workspaceId: string;
    dataTable: any;
    translator: (key: string) => string;
    setMemberSelected: Function,
    memberSelected: any[],
    refreshData: Function,
    isDisableAction: boolean
};

const TableMember: FC<TableMemberProps> = ({ workspaceId, dataTable, translator, refreshData, setMemberSelected, memberSelected, isDisableAction }) => {
    const initialState = {
        hiddenColumns: ['id'],
    };
    const onSelectMember = (assignment) => {
        const hasCheck = memberSelected?.find((item) => item?.id === assignment?.id);
        if (hasCheck) {
            setMemberSelected(memberSelected.filter((item) => item?.id !== assignment.id));
        } else {
            setMemberSelected([...memberSelected, assignment]);
        }
    };

    const onSelectAllMenber = () => {
        if (!!memberSelected?.length) {
            setMemberSelected([]);
        } else setMemberSelected(dataTable);
    };


    const columns = React.useMemo(
        () => [
            { accessor: 'id' },
            checkPermission('learningGroupDetailsMembers', 'U')? 
            {
                Header: () => (
                    <div className="w-full px-2 flex">
                         {memberSelected?.length && memberSelected?.length !== dataTable?.length ? 
                         <div onClick={() => setMemberSelected([])}><StatusCheckIcon/></div>
                         :
                         <input
                                type="checkbox"
                                className='w-4 h-4 border-gray-300'
                                checked={!!memberSelected?.length}
                                onChange={onSelectAllMenber}
                            />
                        }
                    </div>
                ),
                Cell: ({ row }: any) => {
                    const hasCheck = memberSelected?.find((item) => item?.id === row?.original?.id);
                    return (
                        <div className="px-2 flex w-full">
                            <input 
                                type="checkbox" 
                                className='w-4 h-4 border-gray-300 disabled:cursor-not-allowed' 
                                checked={hasCheck} 
                                onChange={() => onSelectMember(row?.original)} 
                            />
                        </div>
                    );
                },
                accessor: 'assignmentSelected',
                width: '20',
            }:{
                Header: () => null,
                Cell: () => null,
                accessor: 'assignmentSelected',
                width: '0',
            },
            {
                Header: () => <RenderTableHeader value={translator('LEARNING_GROUP.MEMBERS')} />,
                Cell: ({ row }) => {
                    return (
                        <LinkCustom
                            to={`/workspace/${workspaceId}/management/users/${row.original.id}`}
                            isTooltip
                            value={row.values?.display_name}
                            disable={row.values.display_name?.length < 35}
                        />
                    );
                },
                accessor: 'display_name',
                width: 220,
            },
            {
                Header: () => <RenderTableHeader value={translator('LEARNING_GROUP.CREDIT_BALANCE')} />,
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return <RenderTableCell value={row.values?.credit_balance || '0'} />;
                },
                accessor: 'credit_balance',
                width: 60,
            },
            {
                Header: () => <RenderTableHeader value={translator('LEARNING_GROUP.STATUS')} />,
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    if (!row.values?.lg_member_display_status) return <RenderTableCell value={'-'} />;
                    return (
                        <div className="flex gap-1 items-center">
                            <div
                                className={`capitalize text-xs bg-red-50 text-red-500 border border-red-300 rounded py-[3px] px-2 w-fit
                            ${row.values?.lg_member_display_status === 'active' ? '!text-green-500 !bg-green-50 !border-green-300' : ''}
                        `}
                            >
                                {translator(row.values?.lg_member_display_status?.toUpperCase())}
                            </div>
                            
                        </div>
                    );
                },
                accessor: 'lg_member_display_status',
                width: 60,
            },
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }) => {
                    return (
                        <ActionMenu isDisableAction={isDisableAction || memberSelected?.length > 0} refreshData={refreshData} data={row?.original} />
                    );
                },
                accessor: 'action',
                width: 20,
            },
        ],
        [dataTable, memberSelected]
    );

    return (
        <div className='TableMembers'>
            <Table
                isBorderFirstColum={false}
                data={dataTable}
                columns={columns}
                initialState={initialState}
                emptyContent={translator('LEARNING_GROUP.NO_MEMBERS')}
            />
        </div>
       
    );
};

export default TableMember;
