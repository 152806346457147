import { ReactNode, useState, useEffect } from 'react';
import { useTable, useRowSelect, useFlexLayout, Row, Column } from 'react-table';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import './styleTableCustom.css';
import { classNames, UUID } from 'utils/commonFun';
import { ImageAvatar } from 'components/Image';
import ChevronUpDown from 'assets/icon/ChevronUpDown';
import { NoContentWithAction } from '../NoContentWithAction/NoContentWithAction';

export const RenderTableHeader = ({
    value,
    className,
    onClick,
    isOrder,
}: {
    value: any;
    className?: string;
    onClick?: any;
    isOrder?: boolean;
}) => (
    <p className={classNames('px-2 text-xs text-ooolab_dark_1 font-semibold flex items-center justify-start', className)}>
        {value}
        {isOrder && (
            <span onClick={() => onClick?.()}>
                <ChevronUpDown />
            </span>
        )}
    </p>
);

export const RenderTableCell = ({
    value,
    className,
    isTooltip,
    disable,
    onClick,
    imageSrc,
}: {
    value: any;
    className?: string;
    isTooltip?: boolean;
    disable?: boolean;
    onClick?: (e?: any) => void;
    imageSrc?: string;
}) => {
    const id = UUID();
    let element: ReactNode = <>{value || '-'}</>;
    if (isTooltip && !!imageSrc) {
        element = <ImageAvatar imageSrc={imageSrc} />;
    } else if (!isTooltip && !!imageSrc) {
        element = (
            <p className="flex items-center space-x-2">
                <ImageAvatar imageSrc={imageSrc} />
                <span>{value}</span>
            </p>
        );
    }
    return (
        <>
            <div
                className={classNames(
                    'px-2 text-xs text-ooolab_dark_1 leading-ooolab_24px font-normal py-2 truncate ',
                    className,
                    disable && 'pointer-events-none',
                    !!onClick && 'cursor-pointer'
                )}
                onClick={() => onClick?.()}
                data-tip={value}
                data-for={id}
            >
                {element}
            </div>
            {isTooltip && (
                //@ts-ignore
                <ReactTooltip
                    place="bottom"
                    type="dark"
                    effect="float"
                    id={id}
                    disable={disable}
                    getContent={(content) => <div className="w-72 h-auto capitalize">{content}</div>}
                />
            )}
        </>
    );
};

export const LinkCustom = ({
    to,
    className,
    value,
    isTooltip,
    disable,
}: {
    to: any;
    className?: string;
    value: any;
    isTooltip?: boolean;
    disable?: boolean;
}) => {
    const id = UUID();
    return (
        <>
            <Link
                to={to}
                className={classNames(
                    'pl-ooolab_p_1_e text-xs text-primary-500 leading-ooolab_24px font-semibold py-2 truncate flex items-center justify-start',
                    className
                )}
                data-tip={value}
                data-for={id}
            >
                {value}
            </Link>
            {isTooltip && (
                //@ts-ignore
                <ReactTooltip
                    place="bottom"
                    type="dark"
                    effect="float"
                    id={id}
                    disable={disable}
                    getContent={(content) => <div className="w-56 h-auto">{content}</div>}
                />
            )}
        </>
    );
};

const EditableCell: any = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
}: {
    value: any;
    row: Row;
    column: Column;
    updateMyData: Function;
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);

    const onChange = (e: any) => {
        setValue(e.target.value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return <input value={value} onChange={onChange} onBlur={onBlur} placeholder={''} />;
};

const Table = ({
    columns,
    data,
    initialState,
    isBorderFirstColum = false,
    emptyContent = 'No data',
    onClickRow,
    onDoubleClickRow,
    rowSelected,
    emptyIcon,
    isMultiSelected = false,
    listSelected,
    emptySubContent = '',
    actionNoContent,
    styleNoContent = '',
    isDisableEvent = false
}: {
    columns: Column<any>[];
    data: any;
    initialState?: any;
    noBorder?: boolean;
    isBorderFirstColum?: boolean;
    emptyContent?: string;
    onClickRow?: (e?: any, event?: any) => void;
    onDoubleClickRow?: (e?: any) => void;
    rowSelected?: any;
    isTableInfinity?: boolean;
    emptyIcon?: any;
    isMultiSelected?: boolean;
    listSelected?: any[];
    emptySubContent?: string;
    actionNoContent?: any;
    styleNoContent?: string;
    isDisableEvent?: boolean
}) => {
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Shift') {
                document.body.classList.add('disable-selection');
            }
        };

        const handleKeyUp = (e: KeyboardEvent) => {
            if (e.key === 'Shift') {
                document.body.classList.remove('disable-selection');
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
            defaultColumn: {
                Cell: EditableCell,
            },
            initialState,
        },
        useRowSelect,
        useFlexLayout
    );

    const renderTbody = () => {
        if (!data.length) {
            return (
                <div className="m-auto h-full flex justify-center items-center">
                    <NoContentWithAction
                        content={emptyContent}
                        img={emptyIcon}
                        subContent={emptySubContent}
                        iconContent={null}
                        child={actionNoContent}
                        styleNoContent={styleNoContent}
                    />
                </div>
            );
        }
        const element = rows.map((row) => {
            const id_tbody_tr = UUID();
            const tmp: any = { ...row.original };
            prepareRow(row);
            return (
                <tr
                    key={id_tbody_tr}
                    className={classNames(
                        'hover:bg-blue-50',
                        isMultiSelected
                            ? listSelected?.find((item) => item?.id === tmp?.id)
                                ? 'bg-blue-50'
                                : ''
                            : rowSelected && rowSelected?.id === tmp.id
                                ? 'bg-blue-50'
                                : ''
                    )}
                    {...row.getRowProps()}
                    onClick={(event) => {
                        if (!isDisableEvent) {
                            event.preventDefault();
                            event.stopPropagation();
                        }
                        onClickRow?.(row.original, event);
                    }}
                    onDoubleClick={(event) => {
                        if (!isDisableEvent) {
                            event.preventDefault();
                            event.stopPropagation();
                        }
                        onDoubleClickRow?.(row.original);
                    }}
                >
                    {row.cells.map((cell, cellIndex) => {
                        const id_tbody_tr_td = UUID();
                        return (
                            <td
                                key={id_tbody_tr_td}
                                className={cellIndex == 0 && isBorderFirstColum ? 'border-r' : ''}
                                {...cell.getCellProps()}
                            >
                                {cell.render('Cell')}
                            </td>
                        );
                    })}
                </tr>
            );
        });
        return element;
    };

    return (
        <table className="tableCustom w-full h-full " {...getTableProps()}>
            <thead className='sticky top-0'>
                {headerGroups.map((headerGroup) => {
                    const id_thead_tr = UUID();
                    return (
                        <tr className="bg-gray-50 border-t border-t-gray-300" key={id_thead_tr} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, index) => {
                                const id_thead_tr_th = UUID();
                                return (
                                    <th
                                        key={id_thead_tr_th}
                                        className={
                                            index == 0 && isBorderFirstColum
                                                ? 'border-t-0 border-r border-ooolab_bar_color py-2'
                                                : 'border-t-0 border-ooolab_bar_color py-2'
                                        }
                                        {...column.getHeaderProps()}
                                    >
                                        {column.render('Header')}
                                    </th>
                                );
                            })}
                        </tr>
                    );
                })}
            </thead>
            <tbody {...getTableBodyProps()}>{renderTbody()}</tbody>
        </table>
    );
};

export default Table;
