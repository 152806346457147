
import React, { useEffect, useState } from "react";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import "./GradebookDetails.scss";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { GradebookAssignment } from "./GradebookAssignment/GradebookAssignment";
import { StudyHistory } from "./StudyHistory/StudyHistory";
import courseService from "services/course.service";
import { useTranslation } from "react-i18next";
import { GradebookOverview } from "./GradebookOverview/GradebookOverview";
const TAB = {
    assignment: "assignment",
    studyHistory: "study-history",
    overview: "overview"
};

export const GradebookDetails = ({ hasUserName = true, enrollmentId = null }) => {
    const params: { id: string, courseId: string } = useParams();
    const history = useHistory();
    const search = useLocation()?.search;
    const tab = new URLSearchParams(search).get('tab');
    const learnerId = new URLSearchParams(search).get('learnerId');
    const isGroup: any = new URLSearchParams(search)?.get('isGroup') ?? false;
    const learnerGroupeId = new URLSearchParams(search).get('learnerGroupeId');
    const fromGroup = new URLSearchParams(search).get('fromGroup');
    const subtab = new URLSearchParams(search).get('subtab');
    const leaderTab = learnerGroupeId ? `learnerGroupeId=${learnerGroupeId}` : `learnerId=${learnerId}`;
    const [overview, setOverview] = useState<any>();
    const { location } = history;
    const { state }: { state: any } = location;
    const {t} = useTranslation();

    useEffect(() => {
        let payload;
        if (learnerId) {
            payload = {
                learners: `[${learnerId}]`
            }
        } else {
            payload = {
                learning_groups: `[${learnerGroupeId}]`
            }
        }
        const getOverview = async () => {
            const response = await courseService.getGradebooks(params?.id, params?.courseId, payload);
            if (response?.data?.items?.length > 0) {
                setOverview(response?.data?.items[0]);
            }
        }
        const getOverviewByGroup = async () => {
            const response = await courseService.getGradebooksByGroup(params?.id, params?.courseId, learnerId);
            if (response?.data) {
                setOverview({
                    ...response?.data,
                    learner: response?.data?.user
                });
            }
        }
        if (JSON.parse(isGroup)) {
            getOverviewByGroup();
        } else {
            getOverview();
        }

    }, [learnerId, learnerGroupeId, isGroup])

    const onGoBack = () => {
        if (state?.isLearningGroup) {
            history.push(`/workspace/${params?.id}/management/courses/${params?.courseId}?tab=${tab}&learnerGroupeId=${state?.isLearningGroup}&subtab=overview`);

        } else {
            history.push(`/workspace/${params?.id}/management/courses/${params?.courseId}?tab=${tab}`);
        }
    }
    return <>
        <div className="gradebookDetails">
            <div className="gradebookDetails-header">
                {hasUserName ? <h1 className="gradebookDetails-title">
                    <button onClick={() => onGoBack()}><ChevronLeftIcon className="w-5 h-5" /></button>
                    {overview?.learner?.display_name || overview?.learning_group?.name}
                </h1> : null}
                <div className="gradebookDetails-info">
                    <div className="gradebookDetails-list">
                        <div className="gradebookDetails-item">
                            <div className="gradebookDetails-label">{t("COURSES.COURSE_COMPLETION")}</div>
                            <div className="gradebookDetails-value">{overview?.course_completion == null ? '-' : `${overview?.course_completion}%`}</div>
                        </div>
                        <div className="gradebookDetails-line"></div>
                        <div className="gradebookDetails-item">
                            <div className="gradebookDetails-label">{t("COURSES.ASSIGNMENT_GRADE")}</div>
                            <div className="gradebookDetails-value">{overview?.assignment_score == null ? '-' : `${overview?.assignment_score}%`}</div>
                        </div>
                        <div className="gradebookDetails-line"></div>
                        <div className="gradebookDetails-item">
                            <div className="gradebookDetails-label">{t("COURSES.ATTENDANCE")}</div>
                            <div className="gradebookDetails-value">{overview?.attendance == null ? '-' : `${overview?.attendance}%`}</div>
                        </div>
                        <div className="gradebookDetails-line"></div>
                        <div className="gradebookDetails-item">
                            <div className="gradebookDetails-label">{t("COURSES.LESSON_GOAL_COMPLETION")}</div>
                            <div className="gradebookDetails-value">{overview?.lesson_goal == null ? '-' : `${overview?.lesson_goal}%`}</div>
                        </div>
                    </div>
                    <div className="gradebookDetails-overall">
                        {t("COURSES.OVERALL_GRADE")}: <span>{overview?.overall == null ? '-' : `${overview?.overall}%`}</span>
                    </div>
                </div>
            </div>
            <div className="gradebookDetails-content">
                <div className="gradebookDetailsTab">
                    {learnerGroupeId ? <div onClick={() => history.push(`/workspace/${params?.id}/management/courses/${params?.courseId}?tab=${tab}&${leaderTab}&subtab=overview${enrollmentId ? `&enrollmentId=${enrollmentId}` : ''}&view=gradebook`)}
                        className={`gradebookDetailsTab-item ${subtab === TAB.overview ? 'gradebookDetailsTab-item--actived' : null}`}>{t("COURSES.COURSE_OVERVIEW")}</div> : null}
                    <div onClick={() => history.push(`/workspace/${params?.id}/management/courses/${params?.courseId}?tab=${tab}&${leaderTab}&subtab=assignment&isGroup=${isGroup ?? false}${tab === 'people' ? `&enrollmentId=${enrollmentId}&view=gradebook` : ''}`, {
                        isLearningGroup: state?.isLearningGroup ? state?.isLearningGroup : null
                    })}
                        className={`gradebookDetailsTab-item ${subtab === TAB.assignment ? 'gradebookDetailsTab-item--actived' : null}`}>{t("COURSES.ASSIGNMENT")}</div>
                    <div onClick={() => history.push(`/workspace/${params?.id}/management/courses/${params?.courseId}?tab=${tab}&${leaderTab}&subtab=study-history&isGroup=${isGroup ?? false}${tab === 'people' ? `&enrollmentId=${enrollmentId}&view=gradebook` : ''}`, {
                        isLearningGroup: state?.isLearningGroup ? state?.isLearningGroup : null
                    })}
                        className={`gradebookDetailsTab-item ${subtab === TAB.studyHistory ? 'gradebookDetailsTab-item--actived' : null}`}>{t("COURSES.SESSION_HISTORY")}</div>
                </div>
                <div className="gradebookDetails-container">
                    {subtab === TAB.overview && learnerGroupeId ? <GradebookOverview learnerGroupeId={learnerGroupeId} /> : null}
                    {subtab === TAB.assignment ? <GradebookAssignment /> : null}
                    {subtab === TAB.studyHistory ? <StudyHistory /> : null}
                </div>
            </div>
        </div >
    </>
}