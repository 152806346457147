import { RestfulService } from './restful.service';

const getAbsenceRequestRecipients = (workspaceId: string, params: any) => {
    return RestfulService.getApi(`/workspaces/${workspaceId}/absence-request-recipients`, params);
};
const addAbsenceRequestRecipients = (workspaceId: string, params: any) => {
    return RestfulService.postApi(`/workspaces/${workspaceId}/absence-request-recipients/add`, params);
};
const removeAbsenceRequestRecipients = (workspaceId: string, params: any) => {
    return RestfulService.postApi(`/workspaces/${workspaceId}/absence-request-recipients/remove`, params);
};
const countAbsenceRequestRecipients = (workspaceId: string) => {
    return RestfulService.getApi(`/workspaces/${workspaceId}/absence-request-recipients/check`);
};

export default {
    getAbsenceRequestRecipients,
    addAbsenceRequestRecipients,
    removeAbsenceRequestRecipients,
    countAbsenceRequestRecipients
}