import React, { useContext, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ExclamationCircleIcon, PlusIcon } from '@heroicons/react/outline';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReportItem from './ReportItem';
import DialogComponent from 'components/Dialog/DialogComponent';
import CreateGradebookReport from './CreateGradebookReport';
import CreateProgressReport from './CreateProgressReport';
import { useTranslation } from 'react-i18next';
import teamwork1 from 'assets/img/teamwork1.png';
import {
    ClassOfReport,
    GradebookApoStudent,
    ReportType,
} from 'types/Class.type';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { useParams } from 'react-router-dom';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import classService from 'services/class.service';

interface StudentReportsProps {
    data: GradebookApoStudent;
}

const StudentReports: React.FC<StudentReportsProps> = ({ data }) => {
    const [isOpenModal, setOpenModal] = useState<boolean>(false);
    const [createReport, setCreateReport] = useState<1 | 2>(1); // 1: ProgressReport, 2: GradebookReport
    const { t: translator } = useTranslation();
    const handleCloseModal = () => setOpenModal(false);
    const params: { id: string; classId: string } = useParams();
    const { dispatch: workspaceDispatch, getWorkspaceDetailState } = useContext(
        GetWorkspaceContext
    );
    const [reloadData, setReloadData] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const [dataView, setDataView] = useState<ClassOfReport>();

    const reports = getWorkspaceDetailState.class.reports;
    const getData = async () => {
        setLoading(true);

        await workspaceMiddleware.getReportOfClass(
            workspaceDispatch,
            params.id,
            params.classId,
            {
                student_id: data?.student?.id,
            }
        );
        setLoading(false);
    };

    useEffect(() => {
        if (reloadData) {
            getData();
            setReloadData(false);
            handleCloseModal();
        }
    }, [reloadData]);

    useEffect(() => {
        getData();
    }, [data]);
    useEffect(() => {
        if (reports?.items) {
            setDataView(reports);
        }
    }, [reports]);

    const getMore = () => {
        classService
            .getReportOfClass(params.id, params.classId, {
                student_id: data?.student?.id,
                page: dataView.page + 1,
            })
            .then((res) => {
                if (res) {
                    setDataView({
                        items: dataView?.items?.concat(res?.items),
                        order: res?.order,
                        page: res?.page,
                        per_page: res?.per_page,
                        sort_by: res?.sort_by,
                        total: res?.total,
                    });
                }
            });
    };

    return (
        <div className="pb-5 relative">
            <div className="my-8 flex justify-end">
                <Menu as="div">
                    <Menu.Button className="bg-primary-500 text-white flex items-center rounded-lg px-3 py-2 text-base font-semibold focus:outline-none">
                        <PlusIcon className=" w-5 h-5 mr-2 " />
                        {translator('MY_REPORTS.CREATE_REPORT')}
                    </Menu.Button>
                    <Transition
                        as="div"
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute z-70 left-0 origin-top-left w-full p-2 mt-2 bg-white shadow-ooolab_box_shadow_container_2 rounded focus:outline-none">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={`${active
                                                ? 'bg-ooolab_blue_0'
                                                : 'text-gray-900'
                                            } group flex rounded-md items-center w-full px-2 py-2 text-xs`}
                                        onClick={() => {
                                            setOpenModal(true);
                                            setCreateReport(1);
                                        }}
                                    >
                                        {translator(
                                            'MY_REPORTS.PROGRESS_REPORT'
                                        )}
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={`${active
                                                ? 'bg-ooolab_blue_0'
                                                : 'text-gray-900'
                                            } group flex rounded-md items-center w-full px-2 py-2 text-xs`}
                                        onClick={() => {
                                            setOpenModal(true);
                                            setCreateReport(2);
                                        }}
                                    >
                                        {translator(
                                            'MY_REPORTS.GRADEBOOK_REPORT'
                                        )}
                                    </button>
                                )}
                            </Menu.Item>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
            <div className="rounded-btn_10 shadow-card_detail min-h-height_75vh">
                <div className="px-16 pt-14 pb-8 font-bold text-2xl text-ooolab_dark_1 flex items-center">
                    <img
                        src={data?.student?.avatar_url}
                        alt="ava"
                        className="w-10 h-10  rounded-full  mr-3"
                    />
                    {data?.student?.display_name}
                    {data?.student?.nick_name && ` / ${data?.student?.nick_name}`}
                </div>

                {dataView?.items?.length ? (
                    <div className="px-3 py-8 2xl:px-10">
                        <InfiniteScroll
                            dataLength={dataView?.items?.length}
                            next={() => getMore()}
                            hasMore={dataView?.items?.length !== dataView.total}
                            loader={
                                false && (
                                    <div>
                                        <CircleSpin color="text-[#00559C]" />
                                        Loading...
                                    </div>
                                )
                            }
                            height={'584px'}
                            scrollableTarget="scrollableDiv "
                            className="custom-style-scrollbar scrollData"
                        >
                            {loading ? (
                                <div className="flex items-center justify-center mt-6">
                                    <CircleSpin color="text-[#00559C]" />
                                    Loading...
                                </div>
                            ) : (
                                dataView?.items &&
                                dataView?.items.map((i) => (
                                    <ReportItem
                                        data={i}
                                        studentName={data?.student.display_name}
                                        setReloadData={setReloadData}
                                        dataDetailStudent={data}
                                    />
                                ))
                            )}
                        </InfiniteScroll>
                    </div>
                ) : (
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <img
                            src={teamwork1}
                            alt=""
                            style={{ filter: 'grayscale(100%)' }}
                        />
                        <div className="text-sm text-ooolab_dark_2 flex items-center mt-2">
                            <ExclamationCircleIcon className="w-6 h-6 mr-2" />
                            No Reports
                        </div>
                    </div>
                )}
            </div>
            <DialogComponent
                isOpen={isOpenModal}
                width={'max-w-xs lg:min-w-max'}
                stylesTitle={' !text-base text-gray-900'}
                title={
                    createReport === 1
                        ? translator(
                            'GRADEBOOK_APOLLO.CREATE_REPORT.CREATE_PROGRESS_REPORT'
                        )
                        : translator(
                            'GRADEBOOK_APOLLO.CREATE_REPORT.CREATE_GRADEBOOK_REPORT'
                        )
                }
                onCloseModal={handleCloseModal}
                child={
                    createReport === 1 ? (
                        <CreateProgressReport
                            detailStudent={data}
                            setReloadData={setReloadData}
                        />
                    ) : (
                        <CreateGradebookReport
                            detailStudent={data}
                            setReloadData={setReloadData}
                        />
                    )
                }
            />
        </div>
    );
};

export default StudentReports;
