import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import lessonService from 'services/lesson.service';
import { UUID, checkPermission } from 'utils/commonFun';
import { ItemSection } from './ItemSection';
import { NewSection } from './NewSection';
import './Section.scss';

export const ListSection = ({
    sections = [],
    setSections,
    sectionSelected,
    setSectionSelected,
    handleSelectContentToPreview,
    contentSelected,
    handleDeleteFileOfSection,
    handleDeleteSection,
    typeSelect,
    setOnSaving,
}: any) => {
    const [draggedItem, setDraggedItem] = useState<any>();
    const [isDraggingSection, setIsDraggingSection] = useState(false);
    const [isDraggingContent, setIsDraggingContent] = useState(false);
    const onDragStart = (e, index) => {
        setIsDraggingSection(true);
        setOnSaving(true);
        setDraggedItem(sections[index]);
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/html', e.target.parentNode);
        e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
    };

    const onDragOver = (index) => {
        const draggedOverItem = sections?.[index];
        if (!draggedOverItem?.id || draggedItem?.id === draggedOverItem?.id) {
            return;
        }
        let items = sections.filter((item) => item?.id !== draggedItem?.id);
        items?.splice(index, 0, draggedItem);
        setSections(items);
    };

    const onDragEnd = () => {
        setIsDraggingSection(false);
        const index = sections?.findIndex((item) => item?.id === draggedItem?.id);
        let newSections = sections?.filter((item) => item?.id !== draggedItem?.id);
        newSections?.splice(index, 0, draggedItem);
        lessonService
            .sortSection(params?.id, params?.lessonId, { ids: newSections?.map((section) => section?.id), type: typeSelect })
            .then(() => setOnSaving(false));
    };

    const params: any = useParams();

    const newSection = () => {
        setOnSaving(true);
        const newSection = {
            title: 'New Section ' + ((sections?.length || 0) + 1),
            order_number: (sections?.length || 0) + 1,
            type: typeSelect,
        };
        lessonService.createSection(params?.id, params?.lessonId, newSection).then((res) => {
            const sectionUpdated = { ...newSection, id: res?.id, uuid: UUID() };
            setSections([...sections, sectionUpdated]);
            setSectionSelected(sectionUpdated);
            handleSelectContentToPreview(undefined);
            setOnSaving(false);
        });
    };

    return (
        <div className="sectionListWrapper custom-scrollbar">
            <div className="section-list">
                {sections?.map((item, index) => (
                    <ItemSection
                        key={item?.id}
                        onDragStart={onDragStart}
                        onDragEnd={onDragEnd}
                        onDragOver={onDragOver}
                        sectionItem={item}
                        isActive={sectionSelected?.id === item?.id}
                        handleSelectContentToPreview={handleSelectContentToPreview}
                        contentSelected={contentSelected}
                        handleDeleteFileOfSection={handleDeleteFileOfSection}
                        handleDeleteSection={handleDeleteSection}
                        setSections={setSections}
                        sections={sections}
                        isDraggingSection={isDraggingSection}
                        setIsDraggingContent={setIsDraggingContent}
                        setOnSaving={setOnSaving}
                        index={index}
                        sectionSelected={sectionSelected}
                        setSectionSelected={setSectionSelected}
                        isDraggingContent={isDraggingContent}
                    />
                ))}
            </div>
            {checkPermission('libraryContentLessonsOrScorm', 'U') ? <div className="sticky-btnNewSection">
                <NewSection onClick={newSection} />
            </div> : ''}

        </div>
    );
};
