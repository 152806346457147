import React from 'react';
import WorkspaceDrive from 'components/Workspace/WorkspaceDrive';

export interface WorkspaceGoogleDriveViewProps {
    setAuthStorage: React.Dispatch<React.SetStateAction<boolean>>;
}

const WorkspaceDrivePage: React.FC<WorkspaceGoogleDriveViewProps> = ({
    setAuthStorage,
}) => {
    return (
        <WorkspaceDrive setAuthStorage={setAuthStorage} />
    );
};

export default WorkspaceDrivePage;
