import { FC } from 'react';
import ReactDatePicker from 'react-datepicker';
import { ClockIcon } from '@heroicons/react/outline';
import { classNames } from 'utils/commonFun';
import './react-datepicker.scss';

type TimePickerProps = {
    inputProps?: any;
    selected?: any;
    isClearable?: boolean;
    dateFormat?: string;
    timeFormat?: string;
    placeholder?: string;
    containerClassName?: string;
    onChange?: (date?: any) => void;
};

const TimePicker: FC<TimePickerProps> = ({
    inputProps,
    selected,
    isClearable,
    dateFormat = 'HH:mm',
    timeFormat = 'HH:mm',
    placeholder,
    containerClassName,
    onChange,
}) => {
    return (
        <div
            className={classNames(
                'react-datepicker__custom relative bg-white w-full border border-gray-300 rounded py-1 focus-within:border-blue-400',
                containerClassName
            )}
        >
            <ReactDatePicker
                selected={selected}
                isClearable={isClearable}
                showTimeSelect
                showTimeSelectOnly
                dateFormat={dateFormat}
                timeFormat={timeFormat}
                placeholderText={placeholder}
                className="w-full pl-8"
                onChange={onChange}
                {...inputProps}
            />
            <ClockIcon className="w-5 h-5 absolute top-[6px] left-2" />
        </div>
    );
};

export default TimePicker;
