import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ActionsButton from 'components/ActionsButton';
import { EyesIcon } from 'assets/icon/EyesIcon';
import UserCheckIcon from 'assets/icon/UserCheckIcon';
import { useAppSelector } from 'hooks/hooks';

interface ActionMenuProp {
    data: any;
    refreshData: Function;
    isAllowAssign: boolean;
    onAssignAssignment: Function;
    assignmentselected: any[];
    position?: string
}

const ActionMenu: FC<ActionMenuProp> = ({ data, onAssignAssignment, assignmentselected, position }) => {
    const { t: translator } = useTranslation();
    const params: { id: string; courseId: string } = useParams();
    const workspaceId = params?.id;
    const { data: {status}} = useAppSelector((state) => state.course);   

    return (
        <ActionsButton
            styleOptions={`${position ? position : 'top-0'} right-[35px]`}
            menus={[
                {
                    label: translator('PREVIEW'),
                    icon: <div className='text-ooolab_dark_1'><EyesIcon width='14' height='14' fill='currentColor'/></div>,
                    action: () => window.open(`/workspace/${workspaceId}/assignment/${data?.id}/preview`),
                    class: 'border-b border-gray-300',
                },
                {
                    label: translator('COURSES.ASSIGN'),
                    icon: <div className='text-ooolab_dark_1'><UserCheckIcon /></div>,
                    action: () => onAssignAssignment(),
                    class: 'border-b border-gray-300',
                    isDisabled: status === 'inactive' || assignmentselected?.length > 1
                }
            ]}
        />
    );
};

export default ActionMenu;
