import React, { Fragment, useContext } from 'react';
// PACKAGES
import { useHistory, useParams } from 'react-router-dom';
// COMPONENTS
import H5PPlayerContentComponent from './H5PPlayerContentComponent';
import H5PEditor from '../H5PEditor';
// MIDDLWARE
import h5pMiddleware from 'middleware/h5p.middlware';
// CONTEXT
import { H5PContext } from 'contexts/H5P/H5PContext';

import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
// TYPES
import { IContentListEntry, TagsType, TagIdType } from 'types/H5P.type';
import { SaveIcon } from '@heroicons/react/solid';
import { ChevronLeftIcon, TagIcon, TrashIcon } from '@heroicons/react/outline';
import { H5PEditorUI } from 'packages/h5p-react';
// UTILS

//SERVIES
import h5pServices from 'services/h5p.service';
import { H5PModal } from '../H5PComponents';
import { CANCEL_H5P, DELETE_H5P, SAVE_H5P } from 'constant/modal.const';
import { TagType } from 'types/GetListOfWorkspace.type';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { ToastContainer } from 'react-toastify';

import { getLocalStorageAuthData } from 'utils/handleLocalStorage';
import { errorNoti, successNoti } from 'components/H5P/H5PFN';
import { useTranslation } from 'react-i18next';
import lodash from 'lodash';
import Modal from 'components/Modal';
import { errorTitle } from 'components/Modal/Common';
import { Dialog, Transition } from '@headlessui/react';
import { checkPermission, downloadWithAxios, handleFail } from 'utils/commonFun';
import workspaceService from 'services/workspace.service';
import H5pInformation from '../H5pInformation/H5pInformation';
import { ButtonPrimary, Toastify } from '_shared';
import { CircleInformationIcon } from 'assets/icon/CircleInfomationIcon';

const H5PViewer: React.FC = ({ children }) => {
    const [entryState, setEntryState] = React.useState({
        editing: false,
        playing: false,
        loading: false,
        saved: false,
        saving: false,
        saveError: false,
        saveErrorMessage: '',
    });
    const [isShowInformation, setShowInformation] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState<boolean>(false);
    const [isModal, setIsModal] = React.useState<boolean>(false);

    const h5pEditorRef: React.RefObject<H5PEditorUI> = React.useRef(null);

    const [modalProps, setModalProps] = React.useState<{
        component: {
            type: string;
            title: string;
            subTitle: string;
            btnCancel: string;
            btnSubmit: string;
            color: string;
            img: string;
        };
        onFetch: () => Promise<void>;
    }>();

    const history = useHistory();
    const pathname = history.location.pathname;
    const pathArray = pathname.split('/');
    const workspacePos = pathArray.indexOf('workspace');
    const contentPos = pathArray.indexOf('h5p-content');
    const workspaceId = pathArray[workspacePos + 1];
    const contentId = pathArray[contentPos + 1];

    const argsId = {
        workspaceId,
        contentId,
    };

    const params: { id: string; contentId: string } = useParams();
    const { location:locationHistory } = history;
    const { state }: { state: any } = locationHistory;

    const [errMsg, setErrMsg] = React.useState<string>();
    const [h5pContentListEntryState, setH5PContentListEntryState] = React.useState<IContentListEntry>();
    const h5PCtx = React.useContext(H5PContext);

    const userInfo = getLocalStorageAuthData();

    const [h5pApprove, setH5pApprove] = React.useState<boolean>(false);
    const [h5pData, setH5PData] = React.useState<IContentListEntry>();
    const [taglist, setTaglist] = React.useState<TagType[]>([]);
    const [tagData, setTagData] = React.useState<TagType[]>([]);
    const [modalError, setModalError] = React.useState(false);
    const [statusError, setStatusError] = React.useState(0);
    const [progress, setProgress] = React.useState(false);
    const [customFields, setCustomFields] = React.useState<any>([]);

    // status = 1 => add fail, status = 2 delete fail , status = 3 both

    const saveButton = React.useRef(null);
    const { t: translator } = useTranslation();
    const {
        dispatch,
        H5PState: { h5PContentListResult, err, h5PApproveContentResult, currentH5P },
    } = h5PCtx;

    const { dispatch: dispatchWorkspace, getWorkspaceDetailState } = useContext(GetWorkspaceContext);

    const { result: workspaceDetailInformation } = getWorkspaceDetailState;

    const getH5pCustomField = () => {
        workspaceService.getH5pCustomFieldService(params.id, params.contentId).then((res: any) => {
            setCustomFields(res?.data);
        });
    };

    React.useEffect(() => {
        getH5pCustomField();
    }, []);

    React.useEffect(() => {
        if (getWorkspaceDetailState.tagList) {
            setTaglist(getWorkspaceDetailState.tagList.items);
        }
    }, [getWorkspaceDetailState.tagList]);

    React.useEffect(() => {
        h5pMiddleware.getCurentH5P(dispatch, argsId);
    }, [isEdit]);

    React.useEffect(() => {
        if (h5pData && h5PApproveContentResult) {
            setH5PData({
                argsId: h5pData?.argsId,
                contentId: h5pData?.contentId,
                mainLibrary: h5pData?.mainLibrary,
                title: h5pData?.title,
                status: 'public',
                created_by: h5pData?.created_by,
                created_at: h5pData?.created_at,
                path: h5pData?.path,
            });
        }
    }, [h5PApproveContentResult]);

    React.useEffect(() => {
        if (currentH5P) {
            setH5PContentListEntryState({
                argsId: argsId,
                contentId: argsId.contentId,
                mainLibrary: currentH5P?.metadata?.mainLibrary,
                title: currentH5P?.metadata?.title,
                status: currentH5P?.status,
                tags: currentH5P?.tags,
                uid: currentH5P?.uid,
                updated_at: currentH5P?.updated_at,
                created_at: currentH5P?.created_at,
                created_by: currentH5P?.created_by,
                path: currentH5P?.path,
            });
            workspaceMiddleware.setCurrentRouteDetail(dispatchWorkspace, [
                {
                    name: currentH5P?.metadata?.title,
                    routeId: 'contentId',
                },
            ]);
            setTagData(currentH5P?.tags);
        }
    }, [currentH5P]);

    React.useEffect(() => {
        if (h5pContentListEntryState) {
            setH5PData(h5pContentListEntryState);
            if (h5pContentListEntryState)
                if (h5pContentListEntryState.status === 'draft') {
                    if (currentH5P?.created_by?.email === userInfo.email || workspaceDetailInformation.membership.role === 'admin')
                        setH5pApprove(true);
                } else {
                    setH5pApprove(false);
                    setIsEdit(false);
                }
        }
    }, [h5pContentListEntryState]);

    React.useEffect(() => {
        setErrMsg(err);
    }, [err]);

    if (!h5pContentListEntryState) {
        return null;
    }

    async function save(customFieldValue = undefined) {
        setEntryState({ ...entryState, saving: true });
        const custom_fields = (customFieldValue || customFields).map((customField) => {
            return {
                custom_field_id: customField.id,
                data: customField.data,
            };
        });
        let saveCustomFieldSuccess = false;

        try {
            await workspaceService.upsertCustomfiledH5p(params.id, params.contentId, { custom_fields }).then((res) => {
                saveCustomFieldSuccess = true;
                workspaceMiddleware.setCurrentRouteDetail(dispatchWorkspace, [
                    {
                        name: currentH5P?.metadata?.title,
                        routeId: 'contentId',
                    },
                ]);
            });
            const returnData: any = await h5pEditorRef.current?.save();
            let tagsId: TagsType = {
                tags: [],
            };
            let deletedTags: number[] = [];
            let addedTags: number[] = [];

            //tags received from api
            const originListTags = h5pData?.tags?.length ? h5pData.tags.map((i) => i.id) : [];
            //tags user interacted before submitting
            if (tagData?.length) {
                const modifiedListTags = tagData.map((i) => i.id);
                if (!modifiedListTags.length && !originListTags.length) {
                    deletedTags = [];
                    addedTags = [];
                }

                if (!modifiedListTags.length && originListTags.length) {
                    addedTags = [];
                    deletedTags = originListTags;
                }

                if (modifiedListTags.length && !originListTags.length) {
                    deletedTags = [];
                    addedTags = modifiedListTags;
                }

                if (modifiedListTags.length && originListTags.length) {
                    addedTags = [...lodash.difference(modifiedListTags, originListTags)];
                    originListTags.forEach((i) => {
                        if (!modifiedListTags.includes(i)) {
                            deletedTags.push(i);
                        }
                    });
                }
            } else {
                deletedTags = originListTags;
            }

            const listRemoveTags: TagsType = {
                tags: [],
            };
            addedTags.map((t) => {
                const tagId: TagIdType = {
                    tag_id: t,
                };
                tagsId.tags.push(tagId);
            });
            if (deletedTags.length) {
                deletedTags.forEach((i) => {
                    const tagId: TagIdType = {
                        tag_id: i,
                    };
                    listRemoveTags.tags.push(tagId);
                });
            }
            let addStatus = 0;
            let deleteStatus = 0;

            await h5pServices
                .h5pAddTag(argsId, tagsId)
                .then(async (res) => {
                    addStatus = res;
                    await h5pServices
                        .h5pRemoveMultiTag(argsId, listRemoveTags)
                        .then((res) => {
                            deleteStatus = res;
                        })
                        .catch((error) => {
                            deleteStatus = error;
                        });
                })
                .catch(async (error) => {
                    addStatus = error;
                    await h5pServices
                        .h5pRemoveMultiTag(argsId, listRemoveTags)
                        .then((res) => {
                            deleteStatus = res;
                        })
                        .catch((error) => {
                            deleteStatus = error;
                        });
                });

            if (addStatus !== 0 || deleteStatus !== 0) {
                if (addStatus === 1 && deleteStatus === 1) {
                    successNoti(translator('DASHBOARD.H5P_CONTENTS.TAGS_SUCCESS'), <TagIcon />);
                } else if (addStatus === -1 || deleteStatus === -1) {
                    if (addStatus === -1 && deleteStatus === -1) {
                        setStatusError(3);
                    } else if (addStatus === -1) {
                        setStatusError(1);
                    } else if (deleteStatus === -1) {
                        setStatusError(2);
                    }
                    setModalError(true);
                    setTimeout(() => location.reload(), 1500);
                } else if ((addStatus === 1 && deleteStatus === 0) || (addStatus === 0 && deleteStatus === 1)) {
                    successNoti(translator('DASHBOARD.H5P_CONTENTS.TAGS_SUCCESS'), <TagIcon />);
                }
            }

            if (returnData) {
                if (returnData?.error) {
                    if (returnData?.error?.name === 'NOTHING_CHANGED' && !saveCustomFieldSuccess) {
                        errorNoti(translator('DASHBOARD.H5P_CONTENTS.H5P_CONTENT_NOT_CHANGED'), <SaveIcon />);
                        if (tagsId.tags.length || listRemoveTags.tags.length) {
                            setH5PData({
                                ...h5pData,
                                tags: tagData,
                            });
                        }
                    }
                }
                if (returnData?.contentId || saveCustomFieldSuccess) {
                    h5pMiddleware.getCurentH5P(dispatch, argsId);
                    setH5PData({
                        contentId: returnData?.contentId,
                        argsId: {
                            workspaceId: params.id,
                            contentId: returnData?.contentId,
                        },
                        mainLibrary: returnData?.metadata?.mainLibrary,
                        title: returnData?.metadata?.title || currentH5P?.metadata?.title,
                        status: h5pData?.status,
                        tags: tagData,
                        created_by: h5pData?.created_by,
                    });
                    Toastify.success(translator('DASHBOARD.H5P_CONTENTS.H5P_SAVE_CONTENT_SUCCESS'))
                    // successNoti(translator('DASHBOARD.H5P_CONTENTS.H5P_SAVE_CONTENT_SUCCESS'), <SaveIcon />);
                }
            }
            setIsEdit(false);
        } catch (error) {
            console.log(error);
        }
    }

    const changeCustomfield = (value) => {
        setCustomFields(value);
        !isEdit && save(value);
    };

    function handleEditH5P() {
        setIsEdit(true);
    }

    async function onDelete() {
        if (h5pData) {
            await h5pServices
                .h5pDeletePromise(h5pData.argsId)
                .then((res) =>
                    res
                        ? successNoti(translator('DASHBOARD.H5P_CONTENTS.H5P_MOVE_TO_TRASH'), <TrashIcon />)
                        : errorNoti(translator('DASHBOARD.H5P_CONTENTS.H5P_MOVE_TO_TRASH_FAILED'), <TrashIcon />)
                );
            setTimeout(() => {
                history.push(`/workspace/${params.id}/library`);
            }, 1000);
        }
    }

    async function onCancelNewH5P() {
        const folderId = state?.folderId;
        if (folderId) {
            history.push(`/workspace/${params?.id}/library/${folderId}`)
        } else {
            history.push(`/workspace/${params?.id}/library?tab=library`)
        }
        setIsEdit(false);
    }

    async function onCloseH5P() {
        if (!isEdit) {
            const folderId = state?.folderId;
            if (folderId) {
                history.push(`/workspace/${params?.id}/library/${folderId}`)
            } else {
                history.push(`/workspace/${params?.id}/library?tab=library`)
            }
        };
        setIsModal(true);
        const modalCancelProps = {
            component: CANCEL_H5P(translator),
            onFetch: onCancelNewH5P,
        };
        await setModalProps(modalCancelProps);
    }

    async function onDeleteH5P() {
        setIsModal(true);
        const modalProps = {
            component: DELETE_H5P(translator),
            onFetch: onDelete,
        };
        setModalProps(modalProps);
    }

    async function onSaveH5P() {
        setIsModal(true);
        const modalProps = {
            component: SAVE_H5P(translator),
            onFetch: save,
        };
        await setModalProps(modalProps);
    }

    const handleDownloadH5PContent = () => {
        setProgress(true);
        h5pMiddleware
            .downloadH5PContentMiddleware(params.id, params.contentId, h5pData?.title)
            .then((res) => {
                downloadWithAxios(res.data, h5pData?.title + '.h5p');
            })
            .catch(() => {
                handleFail('Download fail');
            })
            .finally(() => {
                setProgress(false);
            });
    };

    return (
        <>
            <div className="w-full h-screen relative">
                <ToastContainer />
                <div className={'h-16 w-full sticky top-0 justify-between '}>
                    <div
                        className={`flex h-12 group-hover:flex w-full bg-white border-b border-gray-300 items-center justify-between px-5`}
                    >
                        <div onClick={() => onCloseH5P()} className="flex items-center gap-2 text-sm cursor-pointer">
                            <div className="bg-gray-200 p-1 rounded-full w-fit">
                                <ChevronLeftIcon className="w-5 h-5" />
                            </div>
                            <span className="text-base font-semibold">{translator('BACK')}</span>
                        </div>
                        <div className="font-semibold text-base">{h5pData?.title}</div>
                        <div className="flex gap-4 items-center">
                            {checkPermission('libraryContentFile', 'R') ? <div
                                className="pr-4 border-r border-gray-300 hover:text-gray-900"
                                onClick={() => setShowInformation(true)}
                            >
                                <CircleInformationIcon fill="#1F2937" />
                            </div> : ''}

                            {(checkPermission('libraryContentFile', 'U') || checkPermission('libraryContentFile', 'C')) ?
                                isEdit ? (
                                    <ButtonPrimary
                                        type="button"
                                        ref={saveButton}
                                        className={`${entryState.saved && `bg-ooolab_gray_10`}`}
                                        onClick={onSaveH5P}
                                    >
                                        {translator('DASHBOARD.H5P_CONTENTS.SAVE')}
                                    </ButtonPrimary>
                                ) : (
                                    <ButtonPrimary onClick={handleEditH5P} type="button">
                                        {translator('DASHBOARD.H5P_CONTENTS.EDIT')}
                                    </ButtonPrimary>
                                ) : ''}

                        </div>
                    </div>
                </div>

                <div className=" w-full gap-3 ">
                    <div className="h-max w-full overflow-hidden p-5">
                        {isEdit ? (
                            <H5PEditor
                                h5pContentListEntryState={h5pContentListEntryState}
                                setH5PContentListEntryState={setH5PContentListEntryState}
                                entryState={entryState}
                                setEntryState={setEntryState}
                                h5pEditorRef={h5pEditorRef}
                            />
                        ) : (
                            !!argsId?.contentId && (
                                <H5PPlayerContentComponent argsId={argsId} entryState={entryState} setEntryState={setEntryState} />
                            )
                        )}
                    </div>
                </div>
            </div >
            {isModal && <H5PModal isModal={true} setIsModal={setIsModal} modalProps={modalProps} />
            }
            <Transition.Root show={progress} as={Fragment}>
                <Dialog as="div" static onClose={() => { }} className="fixed z-1 inset-0 overflow-y-auto" open={progress}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:items-start sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 dialog-background  bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <div className="mt-10 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all relative px-5 py-2 text-ooolab_blue_3">
                                {translator('WAITING_FOR_DOWNLOAD')}
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            <Modal
                isOpen={modalError}
                onClose={() => location.reload()}
                closable={false}
                title={
                    <div className="">
                        <div className="mb-6"> {errorTitle(statusError)}</div>
                        <p className="text-ooolab_dark_2 text-ooolab_xs mt-ooolab_m_2">
                            {translator('MODALS.ERRORS.REFRESH_THIS_PAGE')}
                        </p>
                    </div>
                }
            />

            {
                isShowInformation && (
                    <H5pInformation
                        type="edit"
                        isEdit={isEdit}
                        onEdit={() => setIsEdit(true)}
                        h5pData={h5pData}
                        onClose={() => setShowInformation(false)}
                        userInfo={userInfo}
                        customFields={customFields}
                        setCustomFields={changeCustomfield}
                        onDownload={handleDownloadH5PContent}
                        onDelete={onDeleteH5P}
                        refreshData={() => h5pMiddleware.getCurentH5P(dispatch, argsId)}
                    />
                )
            }
        </>
    );
};

export default H5PViewer;
