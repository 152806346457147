import { useState, useEffect, SetStateAction, Dispatch } from 'react';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import './CourseCertificate.scss';
import { useTranslation } from 'react-i18next';

interface CourseCertificateFilterProps {
    onChangeFilter: (value: any) => void;
    isFirstLoad: boolean;
    listSelected: any[];
    setIsMulti: Dispatch<SetStateAction<boolean>>;
    onClose: () => void;
}

const CourseCertificateFilter = ({
    onChangeFilter,
    isFirstLoad,
}: CourseCertificateFilterProps) => {
    const { t: translator } = useTranslation();
    const [keyword, setKeyword] = useDebouncedState('', 0);

    useEffect(() => {
        if (!isFirstLoad) {
            let listFilter: any = {
                q: keyword?.trim(),

            };
            if (!listFilter?.q) delete listFilter.q
            onChangeFilter(listFilter);
        }
    }, [keyword]);


    return (
        <div className="flex gap-3 flex-wrap">
            <SearchBox placeholder={translator('SEARCH')} className="max-w-xs" onSubmit={(value) => setKeyword(value)} />
        </div>
    );
};

export default CourseCertificateFilter;
