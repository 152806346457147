import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { UserContext } from 'contexts/User/UserContext';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TeacherDashboardService } from 'services';
import './NotificationDetail.scss'

export default function NotificationDetail() {
    const params: { id; notificationId } = useParams();
    const [data, setData] = useState(undefined);
    const {userState: {result}} = useContext(UserContext);
    const history = useHistory();

    const getNotificationDetail = () => {
        TeacherDashboardService.getNotificationDetail(params?.id, params?.notificationId).then((res) => {
            setData({created_at: res?.data?.created_at, ...res?.data?.data?.custom_notification});
        });
    };

    useEffect(() => {
        getNotificationDetail();
    }, []);

    return (
        <div className="bg-gray-50 h-[calc(100vh)]">
            <PageBreadcrumb
                isShowIconNotification={true}
                pageNameDefault={'Notifications'}
                containerClassName=" z-10 sticky top-0 "
                onClickPageNameDefault={() => history.push(`/workspace/${params?.id}/instructor-dashboard/notifications`)}
                pages={[
                    {
                        name: data?.title,
                        href: '',
                    },
                ]}
            />

            <div className="InstructorNotification">
                <div className="text-2xl font-semibold">{data?.title}</div>
                <p className="text-xs text-gray-500">{dayjs.utc(data?.created_at).tz(result.time_zone).fromNow()}</p>
                {data?.subtitle && <div className='text-sm my-2'>{data?.subtitle}</div>}
                <p className='InstructorNotification-details' dangerouslySetInnerHTML={{ __html: data?.description }}></p>
            </div>
        </div>
    );
}
