import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DotSVG } from 'assets/icon';

const classField = {
    field:
        'flex items-center justify-start space-x-2 text-xs font-semibold text-ooolab_8083A3',
    dotsvd: 'w-ooolab_w_6px',
};

interface MatchChangePasswordProps {
    textField: string;
}

const MatchChangePassword: React.FC<MatchChangePasswordProps> = ({
    textField,
}) => {
    const { t: translator } = useTranslation();
    const [validField, setValidField] = useState<{
        length: Boolean;
        number: Boolean;
        upper: Boolean;
        lower: Boolean;
        special: Boolean;
    }>({
        length: false,
        number: false,
        upper: false,
        lower: false,
        special: false,
    });

    const checkMatchPassword = (text: string) => {
        setValidField({
            length: text.length >= 10,
            number: /[0-9]/g.test(text),
            upper: /[A-Z]/g.test(text),
            lower: /[a-z]/g.test(text),
            special: /[!@#\$%\^\&*\)\(+=._-]/g.test(text),
        });
    };

    useEffect(() => {
        checkMatchPassword(textField);
    }, [textField]);

    return (
        <div className="mt-2">
            <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-4">
                <p className={classField.field}>
                    <DotSVG
                        className={classField.dotsvd}
                        fill={validField.length ? '#50B957' : '#8083A3'}
                    />
                    <span
                        className={
                            validField.length ? 'text-ooolab_282B5E' : ''
                        }
                    >
                        {translator(
                            'FORGOT_YOUR_PASSWORD.10_CHARACTERS_MINIMUM'
                        )}
                    </span>
                </p>
                <p className={classField.field}>
                    <DotSVG
                        className={classField.dotsvd}
                        fill={validField.number ? '#50B957' : '#8083A3'}
                    />
                    <span
                        className={
                            validField.number ? 'text-ooolab_282B5E' : ''
                        }
                    >
                        {translator('FORGOT_YOUR_PASSWORD.ONE_NUMBER')}
                    </span>
                </p>
                <p className={classField.field}>
                    <DotSVG
                        className={classField.dotsvd}
                        fill={validField.lower ? '#50B957' : '#8083A3'}
                    />
                    <span
                        className={validField.lower ? 'text-ooolab_282B5E' : ''}
                    >
                        {translator(
                            'FORGOT_YOUR_PASSWORD.ONE_LOWER_CASE_CHARACTER'
                        )}
                    </span>
                </p>
            </div>
            <div className="lg:mt-1 grid grid-cols-1 lg:grid-cols-3 lg:gap-4">
                <p className={classField.field}>
                    <DotSVG
                        className={classField.dotsvd}
                        fill={validField.upper ? '#50B957' : '#8083A3'}
                    />
                    <span
                        className={validField.upper ? 'text-ooolab_282B5E' : ''}
                    >
                        {translator(
                            'FORGOT_YOUR_PASSWORD.ONE_UPPERCASE_CHARACTER'
                        )}
                    </span>
                </p>
                <p className={`lg:col-span-2 ${classField.field}`}>
                    <DotSVG
                        className={classField.dotsvd}
                        fill={validField.special ? '#50B957' : '#8083A3'}
                    />
                    <span
                        className={
                            validField.special ? 'text-ooolab_282B5E' : ''
                        }
                    >
                        {translator(
                            'FORGOT_YOUR_PASSWORD.ONE_SPECIAL_CHARACTER'
                        )}
                    </span>
                </p>
            </div>
        </div>
    );
};

const areEqual = (prevProps: any, nextProps: any) => {
    return prevProps.textField === nextProps.textField;
};

export default React.memo(MatchChangePassword, areEqual);
