import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { ButtonOutline, ButtonPrimary } from "_shared";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import DatePickerInput from "components/Management/components/Form/DatePicker";

export const ChangeDueDate = ({ data, onClose, loading }: { data: any, onClose: (value?: string) => void, loading: boolean }) => {
    const { t: translator } = useTranslation();
    const { control, watch, formState: { errors, isDirty, isValid }, handleSubmit, setValue } = useForm();
    const onSubmit = (value) => {
        onClose(dayjs(value?.due_date).format('YYYY-MM-DDT23:59:59'));
    };
    useEffect(() => {
        setValue('due_date', new Date(data?.end_time));
    }, [data]);

    return (<div className="">
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="my-4">
                <DatePickerInput
                    control={control}
                    name="due_date"
                    formatDate="dd/MM/yyyy"
                    isRequired
                    positionIcon="right"
                    minDate={new Date()}
                />
            </div>
            <div className="flex justify-center mt-6 mb-4 gap-4">
                <ButtonOutline type="button" size="M" onClick={() => onClose()}>{translator("CANCEL")}</ButtonOutline>
                <ButtonPrimary type="submit" size="M" onClick={() => { }} disabled={!isDirty || !watch('due_date') || loading}>
                    {loading ? <CircleSpin color="text-primary-500" /> : null}
                    {translator("UPDATE")}
                </ButtonPrimary>
            </div>
        </form>
    </div>)
}